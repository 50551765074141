import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { LocalApiBaseUrl } from '../Shared/Constant';
import i18n from "i18next";
import { connect } from "react-redux";
import Header from '../Shared/Header';


 class DealPopUp extends React.Component {
    constructor(props) {
        super(props)
        this.state = { show: this.props.show, apivalue: "", selectValue: this.props.SelectValue, Display: false }

        this.handleHide = this.handleHide.bind(this);
        this.handleRejectHide = this.handleRejectHide.bind(this);
        this.handleApproveHide = this.handleApproveHide.bind(this);
        let offeringValue = '';
        let value1 = [];
    }
    componentWillMount() {
        

        if (this.props.filterDetails != undefined)
            if ((this.props.filterDetails.Client > 0) && (this.props.filterDetails.Locations > 0) && (parseInt(this.props.filterDetails.offering) > 0) && (this.props.filterDetails.process > 0)) {
                this.setState({ Display: true })
            }
        let apivalue = this.props.opsgrp;

        this.setState({ apivalue });
    }
    componentWillReceiveProps(nextProps) {

        if (nextProps.filterDetails != undefined)
            if ((nextProps.filterDetails.Client > 0) && (nextProps.filterDetails.Locations > 0) && (parseInt(nextProps.filterDetails.offering) > 0) && (nextProps.filterDetails.process > 0)) {
                this.setState({ Display: true })
            }
        // if(nextProps.opsgrp.length!=0)
        // this.setState({ apivalue:nextProps.opsgrp });
    }


    handleHide(e) {


        this.setState({ show: false });
        if (e != undefined) {

            if ((e.target.textContent != "×")) {
                this.props.onClose(e);
            }
            else {
                this.props.onClose(e);
                // this.props.onClose("from x");
            }
        } else {
            this.props.onClose(e);
        }

    }
    handleRejectHide(e) {


        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onRejectClose();
            }
        } else {
            this.props.onRejectClose();
        }

    }
    handleApproveHide(e) {

        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onApproveClose();
            }
        } else {
            this.props.onApproveClose();
        }

    }
    passToConfirmation(data) {
        this.props.enteredData(data.PoNumber)
    }



    render() {
        const { SelectedFilter } = this.props;
        const { apivalue } = this.state;
        const showPopup =
        SelectedFilter &&
        SelectedFilter.LocationId !== "All" &&
        SelectedFilter.OfferingId !== "All" &&
        SelectedFilter.ProcessId !== "All" &&
        SelectedFilter.LocationId &&
        SelectedFilter.OfferingId &&
        SelectedFilter.ProcessId &&
        SelectedFilter.Month
          ? true
          : false;
        return (
            <>
                {/* <Header /> */}
                <Modal show={this.state.show} onHide={this.handleHide} className="modal fade" size="medium" id="dealpopup">
                    <Modal.Header closeButton>
                        {/* <h4 className="modal-title" id="dealprofile">Deal Profile</h4> */}
                       <h4 className="modal-title"> <span>{i18n.t("Deal_Profile")}</span>   </h4>                                    

                    </Modal.Header>
                    <Modal.Body>
                        {this.state.Display && showPopup ? <>
                            {this.state.apivalue != null ? <form>
                                <div className="form-section">
                                    {/* <h3>Org Structure</h3> */}
                                   <h3> <span>{i18n.t("Org_Structure")}</span>  </h3>                                         

                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            {/* <label for="">Operating Group</label> */}
                                          <label>  <span>{i18n.t("Operating_Group")}</span>  </label>                                         
                                            {this.state.apivalue.length !== 0 ? <p>{this.state.apivalue[0].OperatingGroup || "-"}</p> : null}
                                            {/* <p>{this.state.apivalue[0].OperatingGroup || "-"}</p> */}
                                        </div>
                                        <div className="form-group col-lg-6">
                                            {/* <label for="">Industry</label> */}
                                            <label>  <span>{i18n.t("Industry")}</span> </label>                                          

                                            {this.state.apivalue.length !== 0 ? <p>{this.state.apivalue[0].Industry || "-"}</p> : null}
                                            {/* <p>{this.state.apivalue[0].Industry || "-"}</p> */}
                                        </div>
                                        <div className="form-group col-lg-6">
                                            {/* <label for="">Client Name</label> */}
                                            <label>  <span>{i18n.t("Client_Name")}</span>   </label>                                        

                                            {this.state.apivalue.length !== 0 ? <p>{this.state.apivalue[0].ClientName || "-"}</p> : null}
                                            {/* <p>{this.state.apivalue[0].ClientName || "-"}</p> */}
                                        </div>
                                        <div className="form-group col-lg-6">
                                            {/* <label for="">Program</label> */}
                                            <label>   <span>{i18n.t("Program")}</span>  </label>                                         

                                            {this.state.apivalue.length !== 0 ? <p>{this.state.apivalue[0].Program || "-"}</p> : null}
                                            {/* <p>{this.state.apivalue[0].Program || "-"}</p> */}
                                        </div>
                                        <div className="form-group col-lg-6">
                                            {/* <label for="">Project</label> */}
                                            <label>  <span>{i18n.t("My_Deal_Profile")}</span> </label>                                          

                                            {this.state.apivalue.length !== 0 ? <p>{this.state.apivalue[0].Project || "-"}</p> : null}
                                            {/* <p>{this.state.apivalue[0].Project || "-"}</p> */}
                                        </div>
                                        <div className="form-group col-lg-6">
                                            {/* <label for="">Deal Name</label> */}
                                            <label>  <span>{i18n.t("Deal_Name")}</span>   </label>                                        

                                            {this.state.apivalue.length !== 0 ? <p>{this.state.apivalue[0].DealName || "-"}</p> : null}
                                            {/* <p>{this.state.apivalue[0].DealName || "-"}</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-section">
                                    {/* <h3>Geo / Location</h3> */}
                                   <h3> <span>{i18n.t("Geo/Location")}</span>   </h3>                                        

                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            {/* <label for="">Client Region</label> */}
                                            <label>  <span>{i18n.t("Client_Region")}</span>    </label>                                       

                                            {this.state.apivalue.length !== 0 ? <p>{this.state.apivalue[0].ClientRegion || "-"}</p> : null}
                                            {/* <p>{this.state.apivalue[0].Region || "-"}</p> */}
                                        </div>
                                        <div className="form-group col-lg-6">
                                            {/* <label for="">Client Country</label> */}
                                            <label>   <span>{i18n.t("Client_Country")}</span>  </label>                                         

                                            {this.state.apivalue.length !== 0 ? <p>{this.state.apivalue[0].ClientCountry || "-"}</p> : null}
                                            {/* <p>{this.state.apivalue[0].Country || "-"}</p> */}
                                        </div>
                                        <div className="form-group col-lg-6">
                                            {/* <label for="">City / Deliver Center</label> */}
                                            <label> <span>{i18n.t("City/Deliver Center")}</span> </label>                                          

                                            {this.state.apivalue.length !== 0 ? <p>{this.state.apivalue[0].City || "-"}</p> : null}
                                            {/* <p>{this.state.apivalue[0].City || "-"}</p> */}

                                        </div>
                                    </div>
                                </div>
                                <div className="form-section">
                                    {/* <h3>Offering</h3> */}
                                    <h3> <span>{i18n.t("Offering")}</span> </h3>                                          

                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            {/* <label for="">Offering</label> */}
                                            <label> <span>{i18n.t("Offering")}</span>  </label>                                         

                                            {this.state.apivalue.length !== 0 ? <p>{this.state.apivalue[0].Offering || "-"}</p> : null}
                                            {/* <p>{this.state.apivalue[0].Offering || "-"}</p> */}
                                        </div>
                                        <div className="form-group col-lg-6">
                                            {/* <label for="">Process</label> */}
                                            <label>  <span>{i18n.t("Process")}</span>   </label>                                        

                                            {this.state.apivalue.length !== 0 ? <p>{this.state.apivalue[0].Process || "-"}</p> : null}
                                            {/* <p>{this.state.apivalue[0].Process || "-"}</p> */}
                                        </div>
                                        <div className="form-group col-lg-6">
                                            {/* <label for="">Sub Process</label> */}
                                            <label>  <span>{i18n.t("Sub_Process")}</span> </label>                                          

                                            {this.state.apivalue.length !== 0 ? <p>{this.state.apivalue[0].SubProcess || "-"}</p> : null}
                                            {/* <p>{this.state.apivalue[0].SubProcess || "-"}</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-section">
                                    {/* <h3>Stakeholders</h3> */}
                                    <h3> <span>{i18n.t("Stakeholders")}</span> </h3>                                     

                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            {/* <label for="">Accenture Delivery MD</label> */}
                                            <label>  <span>{i18n.t("Accenture_Delivery_MD")}</span>   </label>                                       

                                            {this.state.apivalue.length !== 0 ? <p>{this.state.apivalue[0].AccentureMD || "-"}</p> : null}
                                            {/* <p>{this.state.apivalue[0].StakeHolder1 || "-"}</p> */}
                                        </div>
                                        <div className="form-group col-lg-6">
                                            {/* <label for="">Delivery SDL</label> */}
                                            <label>  <span>{i18n.t("Delivery_SDL")}</span>   </label>                                        

                                            {this.state.apivalue.length !== 0 ? <p>{this.state.apivalue[0].AccentureSDL || "-"}</p> : null}
                                            {/* <p>{this.state.apivalue[0].StakeHolder2 || "-"}</p> */}
                                        </div>
                                        
                                    </div>
                                    <div className="row">
                                            <div className="form-group col-lg-6">
                                            <label>
                                                {" "}
                                                <span>{i18n.t("Client Experience Lead")}</span>{" "}
                                            </label>

                                            {apivalue.length !== 0 ? <p className="overflow-scroll">{apivalue[0].Cxl || "-"}</p> : null}
                                            </div>
                                            <div className="form-group col-lg-6">
                                            <label>
                                                {" "}
                                                <span>{i18n.t("OPS Account Lead")}</span>{" "}
                                            </label>

                                            {apivalue.length !== 0 ? <p className="overflow-scroll">{apivalue[0].Oal || "-"}</p> : null}
                                            </div>
                                            </div>
                                </div>
                            </form> : <p>{i18n.t("NO_DATA_/_Not_Selected_Client,Offering_and_Location")}</p>}


                        </> : <p>{i18n.t("Please_Select_Client,Offering_and_Location")}</p>}
                    </Modal.Body>
                    { /*<Modal.Footer>
                        </Modal.Footer> */ }
                </Modal>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
      SelectedFilter: { ...state.CurrentFilter.SyncFilter },
    };
  };
  export default connect(mapStateToProps)(DealPopUp);
  