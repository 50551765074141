import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Workbook from "react-excel-workbook";
import i18n from "i18next";
import Moment from 'moment';
import { addMonths } from "date-fns";
import { LocalApiBaseUrl } from '../Shared/Constant';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";

axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


const overrideStrings = {
    "selectSomeItems": "Select",
    "allItemsAreSelected": "All items selected.",
    "selectAll": "Select All ",
    "search": "Search"
}

export default class FeatureReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Download: false,
            filterDisplay: { cl: false, off: false, sub: false, loc: false },
            multiselect: { cl: false, off: false, sub: false, loc: false },
            isApiCalled: { cl: false, off: false, sub: false, loc: false },
            SourceClientOptions: [],
            selectedClientOption: [],
            selectedOfferingOption: [],
            SourceSubOfferingOptions: [],
            selectedSubOfferingOption: [],
            OneTimeSetupModel: {
                isActive: true,
                destinationClientId: 0, destinationClientName: "",
                destinationSubOfferingId: 0, destinationSubOfferingName: "",
                sourceSubOfferingId: 0, sourceSubOfferingName: [],
                sourceClientId: 0, sourceClientName: [],
            },
            changeStartDate: null,
            changeEndDate: null,
            active: false,
            OfferingSelected: false,
            errors: [],
            PenetrationData: [],
            formIsValid: false,
            temp: [],
            downloadUrl: null,
            viewmore: false,
            StartDate: null,
            EndDate: null,
            focusedInput: null,
            process: "", Client: "", MaturityPhase: this.props.MaturityPhase,
            processOptions: [], ClientOptions: [],
            OfferingOptions: [],
            selectedStatus: [],
            statusOptions: [
                {
                    value: 1,
                    label: "Not Started",
                },

                {
                    value: 2,
                    label: "Draft",
                },
                {
                    value: 5,
                    label: "In Review (Implementation)",
                },
                {
                    value: 3,
                    label: "Pending MD Approval",
                },
                {
                    value: 4,
                    label: "Data Published ",
                }
            ],
            Report: {
                ClientIds: [],
                OfferingIds: [],
                StartDate: new Date(),
                EndDate: new Date(),
                Status: null,
                StatusIds: [],
                includeDemoDeal: false,
            }
        }
        this.inputRef = React.createRef();

        this.generateExcelReport = this.generateExcelReport.bind(this);
    }

    generateExcelReport() {
        var that = this;
        if (this.validateForm()) {

            var StartDate = Moment(this.state.Report.StartDate).format('YYYY-MM-DD');
            var EndDate = Moment(this.state.Report.EndDate).format('YYYY-MM-DD');
            const includeDemoDeal = this.state.Report.includeDemoDeal;
            var data = {
                StartDate: new Date(StartDate),
                EndDate: new Date(EndDate),
                includeDemoDeal: includeDemoDeal
            };
            if (
                StartDate !== EndDate
            ) {
                this.setState({
                    active: true
                });
                axiosInstance
                    .post(`${LocalApiBaseUrl}Data/GetFeaturePenetrationData`, data)
                    .then((response) => {
                        this.setState({
                            PenetrationData: response.data
                        }, () => {
                            this.inputRef.current.click();
                            this.setState({
                                active: false,
                            });
                        });
                    })
                    .catch((error) => {
                        this.setState({
                            active: false,
                        });
                        trycatchAlertPopup(error);
                    });
            }
        }
    }


    onStartDateChange = (event) => {
        const { Report } = this.state;
        Report.EndDate = new Date();
        let errors = {};
        this.setState({
            errors: errors,
            Report,
            EndDate: null
        });
        Report.StartDate = event;
        this.setState({ StartDate: event });
        this.setState({
            Report: Report,
            changeStartDate: event,
            changeEndDate: ""
        })
    }
    onEndDateChange = (event) => {
        let errors = {};
        this.setState({
            errors: errors,
            EndDate: event
        });
        const Report = this.state.Report;
        Report.EndDate = event;
        this.setState({
            Report: Report,
            changeEndDate: event
        })
    }

    dealpopup(e, data) {

        if (this.validateForm()) {

            this.setState({ viewmore: true, rowSelected: data })
        }
    }

    componentDidMount() {
    }
    diff_months(dt2, dt1) {
        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= (60 * 60 * 24 * 7 * 4);
        return Math.abs(Math.round(diff));
    }
    validateForm() {
        let OneTimeSetupModel = this.state.OneTimeSetupModel;
        let errors = {};
        let Report = this.state.Report;
        let formIsValid = true;

        if (this.state.changeStartDate == null || this.state.changeStartDate == "") {
            errors["StartDate"] = i18n.t("Please Select Start Date");
            formIsValid = false;
        }

        if (this.state.changeEndDate == null || this.state.changeEndDate == "") {
            errors["EndDate"] = i18n.t("Please Select End Date");
            formIsValid = false;
        }

        this.setState({
            errors: errors,
        });
        if (formIsValid) {
            this.setState({
                formIsValid: true
            })
        }
        else {
            this.setState({
                formIsValid: false
            })
        }
        return formIsValid;
    }

    onIsDemoCheck = (e) => {
        const report = this.state.Report;
        report.includeDemoDeal = e.target.checked;

        this.setState({
            Report: report,
        });
    }

    render() {
        let currentdate = new Date();
        let formatDate = Moment(currentdate).format('YYYYMMDD');
        let formatTime = Moment(currentdate).format('HHmmss');
        let formatSheetName = `PenetrationReport_${formatDate}_${formatTime}`;
        const { PenetrationData } = this.state

        return (
            <div className="main-data">
                <LoadingOverlay
                    active={this.state.active}
                    spinner={<SynopsLoader />}
                ></LoadingOverlay>
                <div className="row">
                    <div className="form-group col-lg-4 block-picker">
                        <label className="datepicker-label" htmlFor="">Start Date <span className="required"></span></label>
                        <DatePicker className="form-control" selected={this.state.StartDate} autoComplete="off" id="StartDate" onChange={this.onStartDateChange.bind(this)} value={this.state.Report.StartDate} />
                        <span className="errorMsg">{this.state.errors.StartDate}</span>
                    </div>

                    <div className="form-group col-lg-4 block-picker">
                        <label className="datepicker-label" htmlFor="">End Date<span className="required"></span></label>
                        <DatePicker className="form-control" selected={this.state.EndDate} minDate={this.state.Report.StartDate} maxDate={addMonths(this.state.Report.StartDate, 5)} autoComplete="off" id="EndDate" onChange={this.onEndDateChange.bind(this)} value={this.state.Report.EndDate} />
                        <span className="errorMsg">{this.state.errors.EndDate}</span>
                    </div>

                </div>
                <div className="form-group col-lg-4">
                    <label className="form-check dealData-check-top">
                        <input className="form-check-input" type="checkbox"
                            onChange={(e) => this.onIsDemoCheck(e)}
                            defaultChecked={this.state.Report.includeDemoDeal} />
                        <span className="checkmark"></span>
                        <span className="form-check-label">Include Demo Deal</span>
                    </label>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <button className="btn btn-primary btn-sm" id="btnsave" disabled={this.state.active} onClick={this.generateExcelReport}> Generate Report</button>
                        <Workbook filename={`${formatSheetName}.xlsx`} element={<button style={{ display: 'none' }} ref={this.inputRef}></button>}>


                            <Workbook.Sheet data={PenetrationData.MetricDetailsTab && PenetrationData.MetricDetailsTab != null && PenetrationData.MetricDetailsTab.length > 0 ? PenetrationData.MetricDetailsTab : [{ Message: "Data Not available" }]} name={"MetricTab"}>
                                {Object.keys(PenetrationData.MetricDetailsTab && PenetrationData.MetricDetailsTab != null && PenetrationData.MetricDetailsTab.length > 0 && PenetrationData.MetricDetailsTab[0] ? PenetrationData.MetricDetailsTab[0] : [{ Message: "Data Not available" }][0]).map((key) => (
                                    <Workbook.Column label={key} value={key} />
                                ))
                                }
                            </Workbook.Sheet>
                            <Workbook.Sheet data={PenetrationData.FeatureTab && PenetrationData.FeatureTab != null && PenetrationData.FeatureTab.length > 0 ? PenetrationData.FeatureTab : [{ Message: "Data Not available" }]} name="FeatureTab">
                                {Object.keys(PenetrationData.FeatureTab && PenetrationData.FeatureTab != null && PenetrationData.FeatureTab.length > 0 && PenetrationData.FeatureTab[0] ? PenetrationData.FeatureTab[0] : [{ Message: "Data Not available" }][0]).map((key) => (
                                    <Workbook.Column label={key} value={key} />
                                ))
                                }
                            </Workbook.Sheet>
                            <Workbook.Sheet
                                data={
                                    PenetrationData.IOJ &&
                                        PenetrationData.IOJ != null &&
                                        PenetrationData.IOJ.length > 0
                                        ? PenetrationData.IOJ
                                        : [{ Message: "Data Not available" }]
                                }
                                name="IOJ"
                            >
                                {Object.keys(
                                    PenetrationData.IOJ &&
                                        PenetrationData.IOJ != null &&
                                        PenetrationData.IOJ.length > 0 &&
                                        PenetrationData.IOJ[0]
                                        ? PenetrationData.IOJ[0]
                                        : [{ Message: "Data Not available" }][0]
                                ).map((key) => (
                                    <Workbook.Column label={key} value={key} />
                                ))}
                            </Workbook.Sheet>
                        </Workbook>

                    </div>
                </div>
            </div>

        );
    }
}


