export let humanReducers = (state = {
    humanandmachine:[]
},action)=>{
switch(action.type){   
    case "INITIALISE" :{
            state = {...state}
            state["humanandmachine"] = action.payload
            state["humanandmachine"].map(eachDetails => {
                eachDetails.kpiData.sort((a, b) => (a.MetricSeq !== b.MetricSeq ? ((a.MetricSeq > b.MetricSeq) ? 1 : -1) : (a.MetricName.toLowerCase() > b.MetricName.toLowerCase() ? 1 : -1)))
            })
            return state
        }
    case "ADD_EDIT_HUMAN" :{
        
        state = {...state};           
        if(state["humanandmachine"].filter((x)=>x.SOCategoryID == action.payload.selectedOption)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID).length > 0){
        state["humanandmachine"].filter((x)=>x.SOCategoryID == action.payload.selectedOption)[0].kpiData = state["humanandmachine"].filter((x)=>x.SOCategoryID == action.payload.selectedOption)[0].kpiData.filter((data)=> data.KPIMappingID != action.payload.kpiData.KPIMappingID)
         
        }
        state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.selectedOption)[0].kpiData.push(action.payload.kpiData)
        
        state["humanandmachine"].filter((x) => x.SOCategoryID ==  action.payload.selectedOption)[0].kpiData.sort((a, b) => (a.MetricSeq !== b.MetricSeq ? ((a.MetricSeq > b.MetricSeq) ? 1 : -1) : (a.MetricName.toLowerCase() > b.MetricName.toLowerCase() ? 1 : -1)))

        return state
    }
    case "ADD_EDIT_MACHINE" :{
        state = {...state}
        if(state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.machineWorkforceCat)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID).length > 0 ){
        state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.machineWorkforceCat)[0].kpiData = state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.machineWorkforceCat)[0].kpiData.filter((data)=> data.KPIMappingID != action.payload.kpiData.KPIMappingID)
            }      
        state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.machineWorkforceCat)[0].kpiData.push(action.payload.kpiData)
        state["humanandmachine"].filter((x) => x.SOCategoryID == action.payload.machineWorkforceCat)[0].kpiData.sort((a, b) => (a.MetricSeq !== b.MetricSeq ? ((a.MetricSeq > b.MetricSeq) ? 1 : -1) : (a.MetricName.toLowerCase() > b.MetricName.toLowerCase() ? 1 : -1)))
        return state
    }
    case "REMOVE_HUMAN" :{
        state = {...state}            
        state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.selectedOption)[0].kpiData = state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.selectedOption)[0].kpiData.filter((data)=> data.KPIMappingID != action.payload.kpiData.KPIMappingID)
        
        return state
    }
    case "REMOVE_MACHINE" :{
        state = {...state}            
        state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.machineWorkforceCat)[0].kpiData = state["humanandmachine"].filter((x)=>x.SOCategoryID ==action.payload.machineWorkforceCat)[0].kpiData.filter((data)=> data.KPIMappingID != action.payload.kpiData.KPIMappingID)
        
        return state
    }
    default : return state
}
}
