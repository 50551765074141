
import React from "react";
import i18n from "i18next";
import ReactTooltip from "react-tooltip";
import $ from 'jquery';
import axiosInstance from "../Shared/interceptor";
import { LocalApiBaseUrl,trycatchAlertPopup,accessTokenstring } from "../Shared/Constant";
import { store } from "../reducers/configureStore";

export let UtcToLocalDate = (date) => {
    if (!date) return "";
    let newDateUtc = new Date(date);
    let newDate = newDateUtc.toISOString().replace(/Z\s*$/, "");
    newDate = new Date(newDate);
    return [newDate.getMonth() + 1,
    newDate.getDate(),
    newDate.getFullYear()].join('/') + ' ' +
        [newDate.getHours(),
        newDate.getMinutes(),
        newDate.getSeconds()].join(':');
}
export let forNum = (value,declimit) =>
{
  declimit=declimit==undefined?2:declimit;
  value = value * Math.pow(10,declimit); 
  value=Math.ceil(value);
  value=value/Math.pow(10,declimit);
  return value;
}
export let noRoundOff = (value,declimit) =>
{
    if(value<0)
    {
       value = Math.abs(value);
       declimit=declimit==undefined?2:declimit;
       value = value * Math.pow(10,declimit); 
       value=Math.floor(value);
       value=value/Math.pow(10,declimit);
       return value*-1;
    }
    else if(value>=0) {
         declimit=declimit==undefined?2:declimit;
         value = value * Math.pow(10,declimit); 
         value=Math.floor(value);
         value=value/Math.pow(10,declimit);
         return value;
    }
 
}
export let ValueUnitNoRoundOff = (labelValue) =>
{
  var num=noRoundOff(labelValue);
  var bilNum=(num/ 1000000000);
  var milNum=(num/ 1000000);
  var thouNum=(num/ 1000);
  if(labelValue>=0){
    // Nine Zeroes for Billions
    return (Number(labelValue)) >= 1.0e+9
 
    ? parseFloat(noRoundOff(bilNum))+ "B"
    // Six Zeroes for Millions 
    : (Number(labelValue)) >= 1.0e+6
 
    ? parseFloat(noRoundOff(milNum)) + "M"
    // Three Zeroes for Thousands
    : (Number(labelValue)) >= 1.0e+3
 
    ? parseFloat(noRoundOff(thouNum))+ "K"
 
    : parseFloat(num); 
    }
    else
    {
        labelValue=Math.abs(labelValue);
        return (Number(labelValue)) >= 1.0e+9
 
    ? parseFloat(noRoundOff(bilNum))+ "B"
    // Six Zeroes for Millions 
    : (Number(labelValue)) >= 1.0e+6
 
    ? parseFloat(noRoundOff(milNum)) + "M"
    // Three Zeroes for Thousands
    : (Number(labelValue)) >= 1.0e+3
 
    ? parseFloat(noRoundOff(thouNum))+ "K"
 
    : parseFloat(num); 
    }
}
export  let ValueUnit = (labelValue) =>
{
  var num=forNum2(labelValue);
  var bilNum=(num/ 1000000000);
  var milNum=(num/ 1000000);
  var thouNum=(num/ 1000);
  

    if(labelValue>=0){
    // Nine Zeroes for Billions
    return (Number(labelValue)) >= 1.0e+9
 
    ? parseFloat(forNum2(bilNum))+ "B"
    // Six Zeroes for Millions 
    : (Number(labelValue)) >= 1.0e+6
 
    ? parseFloat(forNum2(milNum)) + "M"
    // Three Zeroes for Thousands
    : (Number(labelValue)) >= 1.0e+3
  
    ? parseFloat(forNum2(thouNum))+ "K"
 
    : parseFloat(forNum2(num));
    }
    else
    {
        labelValue=Math.abs(labelValue);
        return (Number(labelValue)) >= 1.0e+9
 
    ? "-"+parseFloat(forNum2(bilNum))+ "B"
    // Six Zeroes for Millions 
    : (Number(labelValue)) >= 1.0e+6
 
    ? "-"+parseFloat(forNum2(milNum)) + "M"
    // Three Zeroes for Thousands
    : (Number(labelValue)) >= 1.0e+3
 
    ? "-"+parseFloat(forNum2(thouNum))+ "K"
 
    : "-"+parseFloat(forNum2(num));
    }
}

export let forNum2=(num)=>{
  num=Math.abs(num);
  var num_parts = num.toString().split(".");
  if(num_parts[1]!=undefined){
    return num_parts[1].length>2 ? 
           parseFloat(num.toFixed(2)) : num;
  }
  else{
    return num;
  }
}

export let thousandSeparatorFormat=(num)=>{
 
  let newNum = null;
  let dec = null;
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if(num_parts[1]!==undefined){
        if(num_parts[1].length<=2){
          dec=0
      }
      else if(num_parts[1].length===3){
          dec = 1;
      }
      else{
          dec = 2; 
      }
      if(num_parts[1][0] == '0'){
        num_parts[1] = (num_parts[1].substring(0,Math.max(2,num_parts[1].length-2)));
      }else{
        num_parts[1] = parseInt(num_parts[1]/Math.pow(10,dec));
      }
    }
    
    return num_parts.join(".");

}

export let MetricValueThousandFormat =(Unitvalue,AttributeValueDigit)=>{
  
  if(Unitvalue!=undefined){
  Unitvalue=Unitvalue.trim();
   }
  let returnValue="";
  if(AttributeValueDigit!=null|| AttributeValueDigit!=undefined || AttributeValueDigit<=0){
    if((Unitvalue === "$" || Unitvalue === "₹" || Unitvalue === "R$" || Unitvalue === "€" ||Unitvalue === "£" || Unitvalue === "SGD")){
      returnValue= <> <span>  {Unitvalue}</span> {(AttributeValueDigit!=null ? thousandSeparatorFormat(AttributeValueDigit):"")}</>

    }else if(Unitvalue === "$ Mn" || Unitvalue === "₹ Mn"||Unitvalue === "R$ Mn" || Unitvalue === "€$ Mn"||Unitvalue === "SGD Mn"|| Unitvalue === "£ Mn"|| Unitvalue === "€ Mn")
    {
      returnValue=<> <span>{Unitvalue.split(" ")[0]}</span>  {(AttributeValueDigit!=null ? thousandSeparatorFormat(AttributeValueDigit):"")}<span>{" Mn"}</span></>
    }else{
      returnValue=<>{thousandSeparatorFormat(AttributeValueDigit)} <span>{Unitvalue}</span></>
    }

  }else {
    returnValue = (
      Unitvalue === "$" ? 
      <><span>{Unitvalue + "&nbsp;"}</span><>{AttributeValueDigit != null ? thousandSeparatorFormat(AttributeValueDigit):null}</></> : 
      <>{AttributeValueDigit!=null ? <>{thousandSeparatorFormat(AttributeValueDigit)}</>:null}<span>{Unitvalue + "&nbsp;"}</span></>)
  }
  return returnValue;
  
}

export let MetricValueUnitFormat =(Unitvalue,AttributeValueDigit)=>{
    Unitvalue=Unitvalue.trim();
    let returnValue="";
    if(AttributeValueDigit!=null || AttributeValueDigit<=0){
      if((Unitvalue === "$" || Unitvalue === "₹" || Unitvalue === "R$" || Unitvalue === "€" ||Unitvalue === "£" || Unitvalue === "SGD")){
        returnValue= <> <span>  {Unitvalue}</span> {(AttributeValueDigit!=null ? (AttributeValueDigit):"")}</>

      }else if(Unitvalue === "$ Mn" || Unitvalue === "₹ Mn"||Unitvalue === "R$ Mn" || Unitvalue === "€$ Mn"||Unitvalue === "SGD Mn"|| Unitvalue === "£ Mn"|| Unitvalue === "€ Mn")
      {
        returnValue=<> <span>{Unitvalue.split(" ")[0]}</span>  {(AttributeValueDigit!=null ? (AttributeValueDigit):"")} <span>{" Mn"}</span></>
      }else{
        returnValue=<>{AttributeValueDigit} <span>{Unitvalue}</span></>
      }

    }else {
      returnValue = (
        Unitvalue === "$" ? 
        <><span>{Unitvalue + "&nbsp;"}</span><>{AttributeValueDigit != null ? {AttributeValueDigit}:null}</></> : 
        <>{AttributeValueDigit!=null ? <>{AttributeValueDigit}</>:null}<span>{Unitvalue + "&nbsp;"}</span></>)
    }
    return returnValue;
    
}

export let toolThreshold = (eachDetails) =>{
  let ToolTip = "";
  if(eachDetails.LowerThreshold!=null && eachDetails.UpperThreshold !=null ){
        
    ToolTip =i18n.t("Lower Threshold") +	": " + (eachDetails.LowerThreshold) +	", " + i18n.t("Upper Threshold") +	": " +(eachDetails.UpperThreshold);
  }else if(eachDetails.LowerThreshold!=null && eachDetails.UpperThreshold ===null)
  {
    ToolTip =i18n.t("Lower Threshold") +	": " + (eachDetails.LowerThreshold || i18n.t("NA"))
  }else if(eachDetails.LowerThreshold ===null && eachDetails.UpperThreshold !=null){
    ToolTip = i18n.t("Upper Threshold") +	": " +(eachDetails.UpperThreshold || i18n.t("NA"))
  }
  return ToolTip;
}

export class CommonToolTip extends React.Component{
  constructor(props) {
      super(props);
  this.state = {		
          id : this.props.id+"_"+(Math.random()*100).toFixed(0),
          text : this.props.text,
          elementText: this.props.text.slice(0,this.props.length),
          
  };  
}
componentDidUpdate(prevProps) {
  const { id, text } = this.props;
  if (prevProps.id !== id || prevProps.text !== text) {
    this.setState({
      id: id + "_" + (Math.random() * 100).toFixed(0),
      text: text,
      elementText: text.slice(0, this.props.length)
    });
  }
}
  render(){
    let element = <> </>;
    let classes = this.props.class;
    let stateElementText = this.state.elementText+"...";
    if(this.props.element == "span")
      element =<span className={classes} data-tip data-for={this.state.id}>{stateElementText}</span>
    else if(this.props.element == "p")
      element = <p className={classes} data-tip data-for={this.state.id}>{stateElementText}</p>
      else if(this.props.element == "b")
      element = <b className={classes} data-tip data-for={this.state.id}>{stateElementText}</b>
      return(   
        <>    
        <>{element}</>    
          <ReactTooltip place={this.props.place} effect="solid" id={this.state.id} className="tilecard-tooltip tilecardname-tooltip">
                  <span>{this.state.text}</span>
          </ReactTooltip>           
        </>
      );
  }
}

export let CommonMetriTileFix =()=> {
  
  $('div.dropdown.dropleft.ondropOpenColor').on('show.bs.dropdown', function (e) {
    
    $(e.target.offsetParent).addClass('toggle-on')
  })
  $('div.dropdown.dropleft.ondropOpenColor').on('hide.bs.dropdown', function (e) {
    $(e.target.offsetParent).removeClass('toggle-on')
  })

}

export let setUserFeatures = (clientID, offeringID, callback,IsMajorClick) => {
  clientID = clientID == undefined ? 0 : clientID;
  offeringID = offeringID == undefined ? 0 : offeringID;
  offeringID = offeringID !== "All" ? offeringID: 0   // IN CASE OF IOJ SCREEN IN ALL OFFERING SCENARIO

  axiosInstance
    .get(`${LocalApiBaseUrl}User/GetUserFeature`, {
      params: {
        clientID: clientID,
        offeringID: offeringID,
      },
    })
    .then((feature) => {
      if(callback !== undefined)
      {
        callback(feature.data)
      }
      if(IsMajorClick === undefined)
      {
        store.dispatch({
          type: "INITIALISEROLE",
          payload: feature.data,
        });
      }
      else
      {
        store.dispatch({
          type: "INITIALISEROLE",
          payload: { "Data": feature.data, IsMajorClick }
        });
      }
     
    }).catch(error=>{
      trycatchAlertPopup(error)
    })
}

export const getCurrentClientNameFromSession  = () =>
{
  let clientName = JSON.parse(sessionStorage.getItem("clientSelection")) !== undefined && JSON.parse(sessionStorage.getItem("clientSelection")) !== null ? JSON.parse(sessionStorage.getItem("clientSelection")).name : ""
return clientName;
}

export const TRACKING_MODULES = {
  EXPIRED_SESSION: 0,
  PULSE_HUMAN_MACHINE: 5,
  PULSE_WORK_ORCHESTRATION: 6,
  PULSE_INSIGHT_INTELLIGENCE: 7,
  PULSE_DIVERSE_DATA: 8,
  APPLIED_NOW_BUSINESS_OUTCOMES: 9,
  APPLIED_NOW_HUMAN_MACHINE_TALENT: 10,
  APPLIED_NOW_WORK_ORCHESTRATION: 11,
  APPLIED_NOW_INSIGHT_INTELLIGENCE: 12,
  APPLIED_NOW_DIVERSE_DATA: 13,
  APPLIED_NOW_JOURNEY_INITIATIVES: 14,
  APPLIED_NOW_VALUE_REALIZATION: 15,
  APPLIED_NOW_MATURITY_PLAN: 16,
  CONFIGARATOR_BUSINESS_OUTCOMES: 17,
  CONFIGARATOR_HUMAN_MACHINE_TALENT: 18,
  CONFIGARATOR_WORK_ORCHESTRATION: 19,
  CONFIGARATOR_INSIGHT_INTELLIGENCE: 20,
  CONFIGARATOR_DIVERSE_DATA: 21,
  CONFIGARATOR_JOURNEY_INITIATIVES: 22,
  CONFIGARATOR_MATURITY_PLAN: 23,
  DATA_AUTOMATION_ONE_TIME_SETUP: 24,
  DATA_AUTOMATION_METRIC_AUTOMATION: 25,
  DATA_AUTOMATION_HM_TOOL_CONFIGURATOR: 26,
  DATA_AUTOMATION_BUILD_QUERY: 27,
  BENCHMARK_ANALYTICS: 28,
  SYNOPS_FRONTDOOR_ANALYTICS: 29,
  CLIENT_STORY_OVERVIEW_KEY_MILESTONES: 30,
  CLIENT_STORY_BEFORE_AFTER_MIND_MAP: 31,
  CLIENT_STORY_BOI_MIND_MAP: 32,
  OPERATIONAL_REVIEWS: 33,
  CASE_STUDY: 34,
  DEAL_PROFILE: 35,
  LOGIN: 36,
};

export const PARAM_NAMES = {
  OFFERING: "offering",
  SUB_OFFERING: "subOffering",
  LOCATION: "location",
  MONTH: "month",
}

export function createParamObject(ParamName, ParamValue) {
  return {
    ParamName,
    ParamValue,
    ParamType: "UserActivity",
  };
}

export function userTracker(moduleId, end = false, data = {}, onLogout, ...params) {
  // new Promise((resolve, reject) => {
  // Avoid Seding Login Tracker if login tracker is already sent
  // const languageCode = sessionStorage.getItem("languageCode");
  let userId = 0;
  if(store
    .getState()
    .ClientAndFilterReducer.ClientData.find(
      (data) => data.UserName === sessionStorage.getItem("EnterpriseID")
    )) {
      userId = store
    .getState()
    .ClientAndFilterReducer.ClientData.find(
      (data) => data.UserName === sessionStorage.getItem("EnterpriseID")
    ).UserID;
    }

  if (
    ![TRACKING_MODULES.LOGIN, TRACKING_MODULES.EXPIRED_SESSION].includes(
      moduleId
    )
  ) {
    window.currentTrackingModule = moduleId;
  }

  let accessName = null;
  let accessValue = new Date();
  accessName = end ? "accessEndTime" : "accessStartTime";

  let isHomePage = false;
  const route = window.location ? window.location.hash : null;

  if (route && route.includes("homepage")) {
    isHomePage = true;
  }

  const selectedClient = JSON.parse(sessionStorage.getItem("clientSelection"));

  const defaultParam = {
    ParamName: "Client",
    ParamValue: !isHomePage && selectedClient ? selectedClient.name : "NA",
    ParamType: "UserActivity"
  }

  const payload = {
    ...data,
    token: sessionStorage.getItem(accessTokenstring) || sessionStorage.getItem("msal.idtoken"),
    clientId: !isHomePage && selectedClient ? selectedClient.id : 0,
    // userId: sessionStorage.getItem('EnterpriseID'),
    userId,
    moduleId,
    params: [defaultParam, ...params],
    source: "FD",
    [accessName]: accessValue,
    accessEndTime: new Date(),
    duration: new Date(),
    createdUser: sessionStorage.getItem('EnterpriseID'),
    createdTs: new Date(),
    updatedUser: sessionStorage.getItem('EnterpriseID'),
    updatedTs: new Date(),
  };

  axiosInstance
    .post(
      `${LocalApiBaseUrl}NavigationAnalytics/UserLog`,
      payload
    )
    .then(() => {
      if (onLogout) {
        onLogout();
      }
      // resolve(res.data);
    })
    .catch(() => {
      // reject(err);
    });
}