import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import i18n from "i18next";
import Success from "../Images/Success.svg";


class DeleteModal extends Component{
constructor(props){
    super(props);
    this.state={

    }
}
render(){
    const {deleteAssessment,deleteItemModalClose,deleteItem,title,Message} = this.props;
    return  <Modal
    show={deleteAssessment}
    onHide={deleteItemModalClose}
    className="deletemoritem-modal"
    backdrop="static"
    centered
>
    <Modal.Header closeButton>
        <Modal.Title className="deletemoritem-title">{i18n.t(title)}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="deletenotification-body">
            <div className="deletenotification-icon">
                <span>
                <i class="fal fa-exclamation-circle"></i>
                </span>
            </div>
            <div className="deletenotification-data">
                <p>
                {i18n.t(Message)}

                </p>
            </div>
        </div>
    </Modal.Body>
    <Modal.Footer>
        <div className="modal-btn deletemoritem-btns">
            <button
                onClick={deleteItemModalClose}
                className="btn btn-default btn-sm mr-3"
            >{i18n.t("Cancel")}
            </button>
            <button
                type="Button"
                className="btn btn-danger btn-sm"
                onClick={deleteItem}
            >
               {i18n.t("Delete")}
          </button>
        </div>
    </Modal.Footer>
</Modal>
}
}
export default DeleteModal;