import "@babel/plugin-proposal-object-rest-spread";
import cloneDeep from 'lodash/cloneDeep';
import {ADD_IIDATA_FOR_IOJ} from "../actions/JourneyActions";

export let intelligentInsightReducers = (state={
    insightandintelligence:[]
    },action)=>{
    switch(action.type){   
        case ADD_IIDATA_FOR_IOJ :{
            state = {...state}
            state["insightandintelligence"] = action.payload
            return state
        }

        case "INITIALISEINSIGHT" :{
                state = {...state}
                state["insightandintelligence"] = action.payload
            
                state["insightandintelligence"].sort((a, b) => (a.SOCategoryName > b.SOCategoryName) ? 1 : -1)
                state["insightandintelligence"]= state["insightandintelligence"].map(eachDetails => {
                    eachDetails.kpiData.sort((a, b) => (a.MetricSeq !== b.MetricSeq ? ((a.MetricSeq > b.MetricSeq) ? 1 : -1) : (a.MetricName.toLowerCase() > b.MetricName.toLowerCase() ? 1 : -1)))
                    return eachDetails;
                })
                return state
            }

        // case "ADD_APPADVISORS" :{
        //     
        //     state = {...state}            
        //     state["insightandintelligence"].push(action.payload)
            
        //     return state
        // }

        case "Left_Panel" :{
            
            state = {...state} 
            state["insightandintelligence"].filter((x)=>x.SOCategoryName == action.payload.SOCategoryName).length > 0 ? 
            state["insightandintelligence"].filter((data)=> data.SOCategoryName == action.payload.SOCategoryName)[0] = action.payload :
            state["insightandintelligence"].push(action.payload)
            return state
            } 

        case "Right_Panel" :{
            state = {...state} 
            state["insightandintelligence"].filter((x)=>x.SOCategoryName == action.payload.SOCategoryName).length > 0 ? 
            state["insightandintelligence"].filter((data)=> data.SOCategoryName == action.payload.SOCategoryName)[0] = action.payload :
            state["insightandintelligence"].push(action.payload)
            return state
        }

        // case "Remove_APPADVISORS" :{
        //     state = {...state}            
        //     state["insightandintelligence"].pop(action.payload)
            
        //     return state
        // }
        case "Remove_APPADVISORS" :{
            state = {...state}            
            state["insightandintelligence"] = state["insightandintelligence"].filter((x)=>x.SOCategoryName != action.payload.SOCategoryName)
            
                return state
        }

        case "REMOVE_KPI" :{
            state = {...state}            
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData = state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.MetricName != action.payload.MetricName)
            
            return state
        }

        case "ADD_EDIT_KPI" :{
            
            state = {...state};
            
            
            if(state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID).length > 0){
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID)[0].MetricName = action.payload.kpiData.MetricName
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID)[0].MetricSeq = action.payload.kpiData.MetricSeq
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID)[0].MetricUnit = action.payload.kpiData.MetricUnit
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID)[0].MetricValue = action.payload.kpiData.MetricValue
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID)[0].RAGStatus = action.payload.kpiData.RAGStatus
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID)[0].IsAccentureOwned = action.payload.kpiData.IsAccentureOwned
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID)[0].ConnectorID = action.payload.kpiData.ConnectorID
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID)[0].SourceFieldName = action.payload.kpiData.SourceFieldName
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID)[0].SourceName = action.payload.kpiData.SourceName
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID)[0].UpperThreshold = action.payload.kpiData.UpperThreshold
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID)[0].LowerThreshold = action.payload.kpiData.LowerThreshold
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID)[0].Month = action.payload.kpiData.Month
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID)[0].MetricDataDesp = action.payload.kpiData.MetricDataDesp
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID)[0].metDataAttr = action.payload.kpiData.metDataAttr
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID)[0].LeadingPractice = action.payload.kpiData.LeadingPractice
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID)[0].NumeratorDescription = action.payload.kpiData.NumeratorDescription
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID)[0].DenominatorDescription = action.payload.kpiData.DenominatorDescription
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID)[0].Comments = action.payload.kpiData.Comments
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID)[0].ApplicationMappingID = action.payload.kpiData.ApplicationMappingID;
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID)[0].ApplicationMappingName = action.payload.kpiData.ApplicationMappingName;
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID)[0].ProjectName = action.payload.kpiData.ProjectName;
            

        }
        else{
            state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.push(action.payload.kpiData)
        }


        state["insightandintelligence"]
          .filter(x => x.SOCategoryName == action.payload.SOCategoryName)[0]
            .kpiData.sort((a, b) => (a.MetricSeq !== b.MetricSeq ? ((a.MetricSeq > b.MetricSeq) ? 1 : -1) : (a.MetricName.toLowerCase() > b.MetricName.toLowerCase() ? 1 : -1)))
        
          return state
        }

        case "ADD_EDIT_KPI1" :{

            state = {...state}            
            if(state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID == action.payload.kpiData.KPIMappingID).length > 0){
                state["insightandintelligence"].filter((x)=>x.SOCategoryName == action.payload.SOCategoryName)[0].kpiData 
                    = state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.filter((data)=> data.KPIMappingID != action.payload.kpiData.KPIMappingID);
                }

                state["insightandintelligence"].filter((x)=>x.SOCategoryName ==action.payload.SOCategoryName)[0].kpiData.push(action.payload.kpiData);
                        
                state["insightandintelligence"]
                .filter(x => x.SOCategoryName == action.payload.SOCategoryName)[0]
                .kpiData.sort((a, b) => (a.MetricSeq !== b.MetricSeq ? ((a.MetricSeq > b.MetricSeq) ? 1 : -1) : (a.MetricName.toLowerCase() > b.MetricName.toLowerCase() ? 1 : -1)))
            return state
        }

        // case "Remove_PROCESSINSIGHTS" :{
        //     state = {...state}            
        //     state["insightandintelligence"].filter((x)=>x.ParentSOCategoryID ==20)[0] = state["insightandintelligence"].filter((x)=>x.ParentSOCategoryID == 20)[0].filter((data)=> data.SOCategoryID != action.payload.SOCategoryID)
            
        //     return state
        // }

        default : return state
    }
}
