import React, { Component } from 'react';
import { LocalApiBaseUrl, getMonthNumber, getPrevMonth } from '../Shared/Constant';
import { AgGridReact, Ag } from 'ag-grid-react';
import LocationApprovalPopup from './LocationApprovalPopup';
import i18n from "i18next";
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;



export default class LocationApprovalList extends Component {
    constructor() {
        super()


        this.state = {
            Approval: {
                LocationID: [],
                Location:'',
                RequestorRemarks:'',
                ApproverRemarks:'',
                Status:'',
                ApprovalAction:true,
                ApproveType: null,

            },
            CanApprove:false,CanReject:false,

            rowSelected: [],
            userList: [], roleList: [], clientList: [], viewmore: false, prevLength: "", flag: false,
            columnDefs: [



                {
                    headerName: "", resizable: true, suppressFilter: false, headerCheckboxSelection: false, checkboxSelection: true,headerCheckboxSelectionFilteredOnly:true,
                    width: 100, suppressFilter: true,suppressMovable: true,suppressSizeToFit: true,
                },


                { headerName: i18n.t("Location"), field: "City", resizable: true, suppressFilter: false,suppressMovable: true,suppressMovable: true,suppressSizeToFit: true, },
                // { headerName: " Requestor Remarks", field: "RequestorRemarks", resizable: true, suppressFilter: true },
                // { headerName: " Approver Remarks", field: "ApproverRemarks", resizable: true, suppressFilter: true },
                { headerName: i18n.t("Status"), field: "Status", resizable: true, suppressFilter: true,suppressMovable: true,suppressMovable: true,suppressSizeToFit: true,},

            ],
            // autoGroupColumnDef: {
            //     headerName: "hello",
            //     field: "DealName",
            //     width: 200,
            //     cellRenderer: "agGroupCellRenderer",
            //     cellRendererParams: { checkbox: true }
            // },

            rowData: [],
            gridOptions: {
                paginationPageSize: 50,
                rowHeight: 40,
                headerHeight: 40,
                animateRows: true,
                rowSelection: 'single',


                defaultColDef: {
                    sortable: true
                },
            }
        }
        this.onClose = this.onClose.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
        this.dealpopup = this.dealpopup.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
        this.onBtnExport = this.onBtnExport.bind(this);

    }



    onRowSelected(event) {

         if (event.node.selected) {
           
            let rowSelected =[];
           let newdata = this.state.rowData.filter((row) => row.locationId === event.data.locationId)
            rowSelected.push(event.data);
            this.setState({ rowSelected: rowSelected });
        }
        
        else {
            let rowSelected =[...this.state.rowSelected]
            rowSelected = rowSelected.filter((row) => row.locationId !== event.data.locationId)
            this.setState({ rowSelected: rowSelected });
        }
    }

    savePopupData = (data) =>{
        let rowData =[...this.state.rowData]
       let updatedData =  rowData.map(each=>{
            if(each.locationId === data.locationId){
                if(each.languageId === data.languageId){
                    each.City = data.City;
                    each.RequestorRemarks = data.RequestorRemarks;
                }
                each.translatedLocationApproval = each.translatedLocationApproval.map(eachval=>{
                    if(eachval.languageId === data.languageId ){
                        eachval.City = data.City;
                        eachval.RequestorRemarks = data.RequestorRemarks;
                    }
                    return eachval;
                })
               
            }
            return each;
        })
        this.setState({rowData:updatedData})
        this.state.gridOptions.api.setRowData(updatedData);

    }

    onCheckboxClicked(e, data) {
        
        if (e.target.checked) {


            this.state.rowSelected.push(data);
        }
        else {
            
            this.state.rowSelected = this.state.rowSelected.filter((row) => row.LocationID !== data.LocationID)
        }
    }

    onFilterTextBoxChanged(e) {
        this.state.gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
    }

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    dealpopup(e) {
        if(this.state.rowSelected.length > 0)
        {
        if (this.props.featureData.filter((data) => data.Submenu == "Location Approval" && data.Operations.includes("Approve")).length > 0) {
           
            e.preventDefault();
            this.setState({ viewmore: true })
            this.state.Approval.ApproveType='Approve'
        }
        else window.alert(i18n.t("Acces_Denied"))


    }
    else{
        window.alert(i18n.t("Select_Location_for_Approval"))
    }
}

    
    decline(e) {
        if(this.state.rowSelected.length > 0)
        {
        
        if (this.props.featureData.filter((data) => data.Submenu == "Location Approval" && data.Operations.includes("Reject")).length > 0) {
           
            e.preventDefault();
            this.setState({ viewmore: true,ApproveType:'Reject' })
            this.state.Approval.ApproveType='Reject'
        }
        else window.alert("Acces_Denied")
    }
    else{
        window.alert(i18n.t("Select_Location_for_Rejection"))
    }

    }

    onClose(e) {

        this.setState({ viewmore: false });
    }

    getApproval() {
        
        axiosInstance.get(`${LocalApiBaseUrl}LocationApproval/GetLocationForApproval`, {

            params: {}
        })
            .then((response) => {
                this.setState({
                    rowData: response.data
                })
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
    }

    componentDidMount() {



        if (this.props.featureData.filter((data) => data.Submenu == "Location Approval" && data.Operations.includes("Reject")).length > 0) 
        {

            this.setState({CanReject:true});
            // this.state.CanReject=true;
        }
        if (this.props.featureData.filter((data) => data.Submenu == "Location Approval" && data.Operations.includes("Approve")).length > 0) 
        {

            this.setState({CanApprove:true});
            // this.state.CanApprove=true;
        }

        var a = this.props;
        this.getApproval();
        this.setState({
            rowSelected: []

        })
    }

    componentWillMount() {



        this.setState({
            rowSelected: []

        })



        this.getApproval();
    }
    handleSave(data) {
        
        if(data!=undefined){
        
        axiosInstance.post(`${LocalApiBaseUrl}LocationApproval/PostLocationAfterApproval?languageId=${data.languageId}&status=${this.state.Approval.ApproveType}`, data)
            .then((response) => {
                window.alert(response.data.StatusMessage)
                this.getApproval();
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
            this.state.rowSelected = []
        }
        // else{

        //     this.state.Approval=[];
        //     this.state.rowSelected=[];

        // }
    }



    onActiveChange(event) {

        const user = this.state.user;
        user.IsActive = event.target.checked;
        this.setState({
            user: user
        })
    }

    onBtnExport() {
        

        let params = {
            columnKeys: ['City', 'RequestorRemarks', 'Status'],
            columnGroups: true,
            allColumns: false,
            skipHeader: false,
            fileName: 'Location',
            //   columnSeparator: document.querySelector("#columnSeparator").value
        };
        this.state.gridOptions.api.exportDataAsCsv(params);
    }




    render() {

        return (
            <div className="main-data">
                <h3 className="grid-heading">{i18n.t("Location")}</h3>
        
                <div className="tableFilter">
                    <div className="form-section">
                        <div className="form-row">
                            <div className="form-group col-lg-3">
                                <input type="text" className="form-control" id="filter-text-box" placeholder="Filter..." onChange={(e) => { this.onFilterTextBoxChanged(e) }} />
                            </div>
                            <div className="ml-auto mr-1">
                                {/* {this.props.featureData.filter((data) => data.Submenu == "Metric" && data.Operations.includes("Download")).length > 0 ? */}
                                    <span className="btn btn-primary btn-sm py-1" onClick={this.onBtnExport}>
                                        <FontAwesomeIcon icon={faFileDownload} className="mr-1" />{i18n.t("Download")} 
                                </span>
                                 {/* : null} */}
                                {/* <button className="btn btn-primary btn-sm" id="btnsave" onClick={this.onBtnExport} disabled={this.state.active}>Download</button> */}
                            </div>

                        </div>
                    </div>
                </div>
                        <div className="row">
                            <div className="col-lg-12">
                                            <div className="ag-theme-balham ag-table">
                                                    <AgGridReact
                                                        enableSorting={true}
                                                        enableFilter={true}
                                                        pagination={true}
                                                        paginationPageSize={50}
                                                        floatingFilter={true}
                                                        onRowSelected={this.onRowSelected.bind(this)}

                                                        gridOptions={this.state.gridOptions}
                                                        columnDefs={this.state.columnDefs}
                                                        rowData={this.state.rowData}
                                                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                                                    </AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                            {this.state.viewmore ? <LocationApprovalPopup data={this.state.rowSelected} show={this.state.viewmore} type={this.state.Approval.ApproveType} onClose={this.onClose} onSave={this.handleSave} savePopupData={this.savePopupData}/> : null}
                    {/* <input type="checkbox" onClick={(e) => { this.toggle(e,this.params.data) }} /> Select All<br />  */}
                    <div className="text-center mt-3 mb-2">
                                    {this.state.rowSelected.length > 0 && this.state.CanApprove ? <button className="btn btn-primary btn-sm mx-2" onClick={(e) => { this.dealpopup(e) }}>{i18n.t("Approve")}</button>
                                    : <button className="btn btn-primary btn-sm mx-2" disabled={true} onClick={(e) => { this.dealpopup(e) }}>{i18n.t("Approve")}</button>}
                                    {this.state.rowSelected.length > 0 && this.state.CanReject ? <button className="btn btn-primary btn-sm mx-2" onClick={(e) => { this.decline(e) }} >{i18n.t("Decline")}</button>
                                    : <button className="btn btn-primary btn-sm mx-2" disabled={true} onClick={(e) => { this.decline(e) }} >{i18n.t("Decline")}</button>}
                            </div>
            </div>

        )
    }
}