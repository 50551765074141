import React from "react";
import i18n from "i18next";
import { Modal, Button } from "react-bootstrap";
import { LocalApiBaseUrl } from "../Shared/Constant";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import axiosInstance from "../Shared/interceptor";
import {trycatchAlertPopup} from "../Shared/Constant";
import { regexForFields } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import { DisplayMessage,isValidText,InvalidDescription } from "../Shared/commonFunctions";
import SynopsLoader from "../Shared/SynopsLoader";

class IIMetricGroupPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      specialCharmessage:InvalidDescription(),
      SOCat: [],
      show: this.props.show,
      message: "",
      apivalue: "",
      Cattype: "",
      filter: { OrgEntityID: 0, OfferingID: 0, LocationID: 0, MonthDT: null },
      errors: {},
      formIsValid: false,
        isAPILoading: false,

      iiData: {
        //ParentSOCategoryID: this.props.type == "Analytics Apps & AI Advisors" ? 19 : 20,
        Remarks: null,
        SOCategoryDesp: null,
        SOCategoryID: 0,
        SOCategoryName: "",
        SOCategorySeq: null,
        SOCategoryTypeID: 1,
        SequenceID: null,
        SoCatDataAttr: [],
        SoCategoryImage: null,
        kpiData: [],
      },
    };
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.handleRejectHide = this.handleRejectHide.bind(this);
    this.handleApproveHide = this.handleApproveHide.bind(this);
    this.handleSave = this.handleSave.bind(this);
    let offeringValue = "";
    let value1 = [];
  }
  onNameChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const iiData = this.state.iiData;
    var a = this.props;
    iiData.SOCategoryName = event.target.value.trim();
    this.setState({
      iiData: iiData,
    });
  }

  onDescriptionChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const iiData = this.state.iiData;
    iiData.SOCategoryDesp = event.target.value.trim();
    this.setState({
      iiData: iiData,
    });
  }

  onTypeChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const iiData = this.state.iiData;
    this.state.Cattype = event.target.value;
    iiData.ParentSOCategoryID = event.target.value; //this.state.Cattype == "Analytics Apps & AI Advisors" ? 19 : 20;
    this.setState({
      iiData: iiData,
    });
  }

  handleSave(e) {
    if (this.validateForm()) {
      var check = this.props.AllCategoryData;
      let iiData = this.state.iiData;
      // iiData.SOCategoryID = iiData.SOCategoryID == 0 ? Math.random() : iiData.SOCategoryID;

      this.setState({
        iiData: iiData,
      });
      iiData.SOCategoryDescription = this.state.iiData.SOCategoryDesp;
      iiData.ParentSOCategoryID = 4;
      let filterProcess = this.props.filterDetails.process;
      this.setState({ isAPILoading: true });
      axiosInstance
        .post(`${LocalApiBaseUrl}Master/PostSOCategoryMasterTemplate`, iiData, {
          params: { offeringId: filterProcess,           
                    LanguageID: store.getState().CurrentFilter.languageData.id  
                   },
        })
        .then((response) => {
          store.dispatch({
            type: "ADD_PROCESSINSIGHTS_MASTER",
            payload: response.data.stateData,
          });
          
          store.dispatch({
            type: "ADD_APPADVISORS_MASTER",
            payload: response.data.stateData,
          });
          this.setState({ isAPILoading: false }, () => {
            if(response.data.IsSuccess){
              window.alert(i18n.t("New_So_Cat"));
              }

          this.handleHide();
          });
          // window.alert(response.data.StatusMessage)
        })
        .catch((error)=>{
trycatchAlertPopup(error);
          this.setState({ isAPILoading: false }, () => {
            
            DisplayMessage();
            this.handleHide(e);
          });
        });
        
      // iiData.SOCategoryID = this.state.message;

      //this.handleHide();
    }
  }

  handleHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
  }
  handleRejectHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }

  validateForm() {
    const iiData = this.state.iiData;
    const {specialCharmessage}=this.state
    let errors = {};
    let result=false;
    let formIsValid = true;
    
    if (iiData["SOCategoryName"])
      iiData["SOCategoryName"] = iiData["SOCategoryName"].trim();
    if (!iiData["SOCategoryName"]) {
      formIsValid = false;
      errors["SOCategoryName"] = "Name is required";
    } else {
      //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
      //var regex = /^[A-Za-z0-9 % > / & - , () ]+$/
      //Validate TextBox value against the Regex.
      var isValid = regexForFields.test(iiData["SOCategoryName"]);
      if (!isValid) {
        formIsValid = false;
        errors["SOCategoryName"] = "SOCategoryName contains Special characters";
      }
      else{
        if(this.props.insightandintelligence.length>0){
          result=this.props.insightandintelligence.some((a) => a.SOCategoryName.trim().toLowerCase() === iiData["SOCategoryName"].trim().toLowerCase());
         if(result){
           window.alert(i18n.t("So_CatExist"));
           formIsValid = false;
         }
         else{
          if(!isValidText(iiData["SOCategoryName"].trim())) {
            errors["SOCategoryName"] = specialCharmessage;
            formIsValid = false;
        }
         }
         }
      }
    }
    if (iiData["SOCategoryDesp"])
      iiData["SOCategoryDesp"] = iiData["SOCategoryDesp"].trim();
    if (!iiData["SOCategoryDesp"]) {
      formIsValid = false;
      errors["SOCategoryDesp"] = "Description is required";
    } else {
      //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
      //var regex = /^[A-Za-z0-9 % > / & - , () ]+$/
      //Validate TextBox value against the Regex.
      var isValid = regexForFields.test(iiData["SOCategoryDesp"]);
      if (!isValid) {
        formIsValid = false;
        errors["SOCategoryDesp"] = "SOCategoryDesp contains Special characters";
      }
      else{
        if(!isValidText(iiData["SOCategoryDesp"].trim())) {
          errors["SOCategoryDesp"] = specialCharmessage;
          formIsValid = false;
      }
       }
    }
    /*
    if (this.state.Cattype) this.state.Cattype = this.state.Cattype.trim();
    if (!this.state.Cattype) {
      formIsValid = false;
      errors["type"] = "Type is required";
    }*/

    this.setState({
      errors: errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
        errors: {},
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  componentDidMount() {
    let SOCatlist1 = [];

    let allData = this.props.AllCategoryData.filter(
      (IIDetails) => IIDetails.ParentSOCategoryID === 4
    );

    if (allData != undefined && allData.length > 0) {
      allData.map((row, index) => {
        SOCatlist1.push(
          <option value={row.SOCategoryID}>{row.SOCategoryName}</option>
        );
        //return (SOCatlist1);
      });
    }

    this.setState({
      SOCat: SOCatlist1,
    });
  }

  render() {
    const { isAPILoading } = this.state;
    return (
      <>
        {/* <Header /> */}
        {isAPILoading && (
      <LoadingOverlay
        fadeSpeed={0}
        spinner={<SynopsLoader />}
        active
        // text={i18n.t("Please_wait") + '...'}
      />
    )}
        <Modal
          show={this.props.showPop}
          onHide={this.handleHide}
          className="modal fade"
          size="medium"
          id="dealpopup"
          backdrop="static"
        >
          <Modal.Header closeButton>
            {/* <h4 className="modal-title" id="dealprofile">
              Add New Metric Group
            </h4> */}
            <h4 className="modal-title" id="dealprofile"> <span>{i18n.t("Add_New_Metric_Category")}</span> </h4>                                    

          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                {/*<div className="row">
                  <div className="form-group col-lg-12">
                    //<label for="">
                      //Type <span className="required">*</span>
                    //</label> 
                    <label for="">
                      {i18n.t("Type")}<span className="required">*</span>
                    </label>

                    <select
                      className="form-control"
                      onChange={this.onTypeChange}
                    >
                      <option value=""> </option>
                      <option value="4">{i18n.t("Add_Category")}</option>
                      {this.state.SOCat}
                    </select>
                    <span className="errorMsg">{this.state.errors.type}</span>
                  </div>
                </div>*/}
                <div className="row">
                  <div className="form-group col-lg-6">
                    {/* <label for="">
                      Name <span className="required">*</span>
                    </label> */}
                    <label for="">
                      {i18n.t("Name")}<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      maxLength="100"
                      className="form-control"
                      id="name"
                      onChange={this.onNameChange}
                    />
                    <span className="errorMsg">
                      {this.state.errors.SOCategoryName}
                    </span>
                  </div>
                  <div className="form-group col-lg-6">
                    {/* <label for="">
                      Description <span className="required">*</span>
                    </label> */}
                    <label for="">
                      {i18n.t("Description")} <span className="required">*</span>
                    </label>
                    <input
                      maxLength="50"
                      type="text"
                      className="form-control"
                      id=""
                      onChange={this.onDescriptionChange}
                    />
                    <span className="errorMsg">
                      {this.state.errors.SOCategoryDesp}
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">
                <input
                  type="Button"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.handleSave()}
                  value="Create"
                />
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    insightandintelligence: state.intelligentInsightMasterReducers.insightandintelligence
  };
};

export default connect(
  mapStateToProps,
  ""
)(IIMetricGroupPopUp);