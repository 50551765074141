import React from "react";
import { Modal, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _, { stubFalse } from "lodash";
import { regexForNumbersOnlyMTC, regexForFields } from "../Shared/Constant";
import Alert from "react-bootstrap/Alert";


import { store } from "../reducers/configureStore";
import Header from "../Shared/Header";
import classNames from "classnames";
import { faPlus, faTrashAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { regexForTextAreas } from "../Shared/Constant";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


const singleBenchmarkPair = {
  errMsgAttribute2ValueField: "",
  errMsgAttribute1ValueField: "",
  Attribute1Value: "",
  Attribute2Value: "",
  Industry: "",
  errMsgIndustryField: "",
};

export default class MetricDetailPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // ClientOptions: [],
      // RoleOptions: [],
      show: this.props.show,
      apivalue: "",
      selectValue: this.props.SelectValue,
      Display: false,
      errors: [],
      formIsValid: false,
      isVisibleDenominatorDefinition: false,
      showMetricDefinition: true,
      isNumeratorClicked: true,
      SOPageCategoryName: "",
      isNumeratorReqd: true,
      defaultIsRequiredLeadingPractice: true,
      isDenomReqd: true,
      isDisabledNumeratorRadioButton: false,
      DDattributeLabels: [],
      Approval: {
        ApproverRemarks: "",
        DealApprovalID: [],
        ApprovalType: null,
        ApprovalAction: false,
        LeadingPractice: this.props.data.length === 1 ? this.props.data[0].LeadingPractice : "",
        NumeratorDescription: this.props.data.length === 1 ? this.props.data[0].NumeratorDescription : "",
        DenominatorDescription: this.props.data.length === 1 ? this.props.data[0].DenominatorDescription : null,
        DealID: this.props.data.length === 1 ? this.props.data[0].DealID : null,
        OfferingID: this.props.data.length === 1 ? this.props.data[0].OfferingID : null,
      },

      benchmarkPair: [
        {
          ..._.cloneDeep(singleBenchmarkPair),
        },
      ],
      areAnyDuplicateIndustry: false,
      IndustryOptions: [],
      shouldBenchmarkDisplay: false,
      isDDpresentInMultiSelect: false,
      CustomSubStepMetric: false,
    };

    this.onDealIsActiveChange = this.onDealIsActiveChange.bind(this);
    this.onGlobalIsActiveChange = this.onGlobalIsActiveChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  handleRadioButtonChange = (event) => {
    
    if (event.target.value === "numeratorDefinition") {
      this.setState({
        isVisibleDenominatorDefinition: false,
        isNumeratorClicked: true,
      });
    }
    if (event.target.value === "numeratorAndDenominatorDefinition")
      this.setState({
        isVisibleDenominatorDefinition: true,
        isNumeratorClicked: false,
      });
  };

  validateForm() {
    debugger;
    const { benchmarkPair } = this.state;
    let Approval = this.state.Approval;
    let errors = {};
    let formIsValid = true;

    if (!Approval["ApprovalType"] && this.props.action == "Approve") {
      formIsValid = false;
      errors["error"] = "Choose one option";
    }

    if (!Approval["ApproverRemarks"].trim() && !(this.props.action == "Approve")) {
      formIsValid = false;
      errors["ApproverRemarks"] = "Approver Remarks are required";
    } else {
      var isValid = regexForTextAreas.test(Approval["ApproverRemarks"]);
      if (!isValid && !(this.props.action == "Approve")) {
        formIsValid = false;
        errors["ApproverRemarks"] = "Approver Remarks contains Special characters";
      }
    }

    //validation for leading practice
    if (this.state.showMetricDefinition) {
      if (this.state.defaultIsRequiredLeadingPractice && !Approval["LeadingPractice"]) {
        formIsValid = false;
        errors["LeadingPractice"] = "Please select a value for Leading Practice";
      }

      //Validation for metric definition

      //trimmimg metric def and metric unit before the business rules

      if (Approval["NumeratorDescription"]) {
        Approval["NumeratorDescription"] = Approval["NumeratorDescription"].trim();
      }
      if (Approval["DenominatorDescription"]) {
        Approval["DenominatorDescription"] = Approval["DenominatorDescription"].trim();
      }

      if (
        this.state.SOPageCategoryName == "Business Outcomes" ||
        this.state.SOPageCategoryName == "Insights & Intelligence" ||
        this.state.SOPageCategoryName == "Diverse Data"
      ) {
        let metricUnit = "";
        if (this.props.data && this.props.data.length > 0) {
          metricUnit = this.props.data[0].MetricUnit;
        }
        if ((!Approval["NumeratorDescription"] || !Approval["DenominatorDescription"]) && metricUnit === "%") {
          formIsValid = false;
          errors["NumeratorDenominator"] = "Please enter both numerator definition and denominator definition";
        }
        if (metricUnit !== "%") {
          if (this.state.isNumeratorClicked) {
            //numerator mandatory

            if (!Approval["NumeratorDescription"]) {
              formIsValid = false;
              errors["NumeratorDenominator"] = "Please enter numerator definition";
            }
          } else {
            //numerator and denom mandatory
            if (!Approval["NumeratorDescription"] || !Approval["DenominatorDescription"]) {
              formIsValid = false;
              errors["NumeratorDenominator"] = "Please enter numerator definition and denominator definition";
            }
          }
        }
      } else {
        //HM and WO

        if (this.state.isNumeratorClicked) {
          //numerator mandatory

          if (!Approval["NumeratorDescription"]) {
            formIsValid = false;
            errors["NumeratorDenominator"] = "Please enter numerator definition";
          }
        } else {
          //numerator and denom mandatory
          if (!Approval["NumeratorDescription"] || !Approval["DenominatorDescription"]) {
            formIsValid = false;
            errors["NumeratorDenominator"] = "Please enter numerator definition and denominator definition";
          }
        }
      }
    }
    // VALIDATE BENCHMARK
    if (this.state.shouldBenchmarkDisplay) {
      const modifiedBenchmark = _.map(benchmarkPair, (eachItem) => {
        eachItem["errMsgAttribute1ValueField"] = "";
        eachItem["errMsgAttribute2ValueField"] = "";
        eachItem["errMsgIndustryField"] = "";

        if (!eachItem["Industry"].trim()) {
          // Industry can never be left empty
          formIsValid = false;
          eachItem["errMsgIndustryField"] = "Industry is required";
        }

        if (!eachItem["Attribute1Value"].trim() && !eachItem["Attribute2Value"].trim()) {
          // attribute values can never be left empty - fill NA
          formIsValid = false;
          // errors["Attribute1Value"] ="Benchmark values are mandatory. If you don't intend to add values, please enter NA";
          // errors["Attribute2Value"] ="Benchmark values are mandatory. If you don't intend to add values, please enter NA";
          eachItem["errMsgAttribute1ValueField"] =
            "Benchmark values are mandatory. If you don't intend to add values, please enter NA";
          eachItem["errMsgAttribute2ValueField"] =
            "Benchmark values are mandatory. If you don't intend to add values, please enter NA";
        }

        if (eachItem["Attribute1Value"].trim()) {
          if (Number.isNaN(parseFloat(eachItem["Attribute1Value"].trim()))) {
            // it is a string

            if (eachItem["Attribute1Value"].toLowerCase().trim() !== "na") {
              formIsValid = false;
              // errors["Attribute1Value"] = "NA is the only permitted string value";
              eachItem["errMsgAttribute1ValueField"] = "NA is the only permitted string value";
            }
          } else {
            // it is a whole number or decimal value OR has alphabets and numbers ex. 123efg345

            if (!regexForNumbersOnlyMTC.test(eachItem["Attribute1Value"].trim())) {
              formIsValid = false;
              // errors["Attribute1Value"] = "NA is the only permitted string value";
              eachItem["errMsgAttribute1ValueField"] = "NA is the only permitted string value";
            }

            if (
              !(
                parseFloat(eachItem["Attribute1Value"].trim()) >= 0 &&
                parseFloat(eachItem["Attribute1Value"].trim()) <= 999999
              )
            ) {
              formIsValid = false;
              // errors["Attribute1Value"] =	`${this.props.data[0].AttributeName1}` +" value must be in between 0 to 999999";
              eachItem["errMsgAttribute1ValueField"] =
                `${this.state.DDattributeLabels.AttributeName1}` + " value must be in between 0 to 999999";
            }

            //0 to 999999 validation for attribute1value
          }

          if (!eachItem["Attribute2Value"].trim()) {
            // if attribute1 is filled, attribute2` cant be empty

            // errors["Attribute2Value"] =
            // 	"Please enter a value for " +
            // 	`${this.props.data[0].AttributeName2}`;
            eachItem["errMsgAttribute2ValueField"] =
              "Please enter a value for " + `${this.state.DDattributeLabels.AttributeName2}`;

            formIsValid = false;
          }
          // TODO: needs to be chnaged later when attribute labels are dynamic
        }

        if (eachItem["Attribute2Value"].trim()) {
          if (Number.isNaN(parseFloat(eachItem["Attribute2Value"]))) {
            // it is a string

            if (eachItem["Attribute2Value"].toLowerCase().trim() !== "na") {
              formIsValid = false;
              // errors["Attribute2Value"] = "NA is the only permitted string value";
              eachItem["errMsgAttribute2ValueField"] = "NA is the only permitted string value";
            }
          } else {
            //attribute 2 is a whole number or decimal OR has alphabets and numbers ex. 12efgh3456

            if (!regexForNumbersOnlyMTC.test(eachItem["Attribute2Value"].trim())) {
              formIsValid = false;
              // errors["Attribute2Value"] = "NA is the only permitted string value";
              eachItem["errMsgAttribute2ValueField"] = "NA is the only permitted string value";
            }

            //0 to 999999 validation for attribute2value
            if (
              !(
                parseFloat(eachItem["Attribute2Value"].trim()) >= 0 &&
                parseFloat(eachItem["Attribute2Value"].trim()) <= 999999
              )
            ) {
              formIsValid = false;

              // errors["Attribute2Value"] =	`${this.props.data[0].AttributeName2}` +" value must be in between 0 to 999999";
              eachItem["errMsgAttribute2ValueField"] =
                `${this.state.DDattributeLabels.AttributeName2}` + " " + " value must be in between 0 to 999999";
            }
          }
          if (!eachItem["Attribute1Value"].trim()) {
            // if attribute2 is filled, attribute1` cant be empty
            // errors["Attribute1Value"] =	"Please enter a value for " +`${this.props.data[0].AttributeName1}`;
            eachItem["errMsgAttribute1ValueField"] =
              "Please enter a value for " + " " + `${this.state.DDattributeLabels.AttributeName1}`;

            formIsValid = false;
          }
          // TODO: needs to be changed later when attribute labels are dynamic
        }

        return eachItem;
      });

      // CHECK FOR DUPLICATE BENCHMARK
      let areAnyDuplicateIndustry = false;
      let minCrossIndustryCheckFailed = false;
      let crossIndustryCount = 0;
      _.forEach(modifiedBenchmark, function (eachBenchmark, index) {
        const modifiedIndustry = String(eachBenchmark.Industry).trim();

        const eqTypeField = _.find(modifiedBenchmark, function (eachItem, indx) {
          const foundindustry = String(eachItem.Industry).trim();

          if (
            indx > index &&
            !_.isEmpty(modifiedIndustry) &&
            !_.isEmpty(foundindustry) &&
            modifiedIndustry === foundindustry
          ) {
            return true;
          }
        });
        if (eqTypeField) {
          areAnyDuplicateIndustry = true;
        }

        if (modifiedIndustry === "null") {
          crossIndustryCount++;
        }
      });

      if (areAnyDuplicateIndustry) {
        formIsValid = false;
      }

      // CHECK FOR REQUIRED ONE CROSS INDUSTRY FIELD
      if (crossIndustryCount === 0) {
        formIsValid = false;
        minCrossIndustryCheckFailed = true;
      }

      this.setState({
        areAnyDuplicateIndustry,
        minCrossIndustryCheckFailed,
      });
    }

    this.setState({
      errors: errors,
    });

    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  onDealIsActiveChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    if (event.target.checked) {
      const Approval = this.state.Approval;
      // Approval.DealIsActive = event.target.checked;
      Approval.ApprovalType = "Deal";
      this.setState({
        Approval: Approval,
      });
    }
    this.IndustryBenchmarkCheck();
  }

  onGlobalIsActiveChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    if (event.target.checked) {
      const Approval = this.state.Approval;
      // Approval.GlobalIsActive = event.target.checked;
      Approval.ApprovalType = "Global";
      this.setState({
        Approval: Approval,
      });
    }
    this.IndustryBenchmarkCheck();
  }

  onRemarksChange(event) {
    this.setState({ errors: {} });
    // const deal = this.props.dealData[0];
    const Approval = this.state.Approval;

    Approval.ApproverRemarks = event.target.value;
    this.setState({
      Approval: Approval,
    });
  }

  handleSave() {
    if (this.validateForm()) {
      const { benchmarkPair } = this.state;

      if (this.props.action == "Approve") {
        this.state.Approval.ApprovalAction = true;

        if (this.state.Approval.ApprovalType === "Global") {
          let approvalData = this.state.Approval;
          approvalData.DealID = null;

          const benchmarkPairForRequest = _.map(benchmarkPair, (eachItem) => {
            const benchmark = {
              Attribute1Value: eachItem.Attribute1Value,
              Attribute2Value: eachItem.Attribute2Value,
              IndustryID: eachItem.Industry === "null" ? null : eachItem.Industry,
            };
            return benchmark;
          });
          approvalData.MetricBenchmarkList = benchmarkPairForRequest;

          this.setState({ Approval: approvalData });
        }

        //clear out denominator if numerator def radio button selected
        if (this.state.isNumeratorClicked) {
          const approvaldta = this.state.Approval;
          approvaldta.DenominatorDescription = "";
          this.setState({
            Approval: approvaldta,
          });
        }
        this.props.onSave(this.state.Approval);
        this.handleHide();
      } else {
        this.state.Approval.ApprovalType = null;
        this.state.Approval.ApprovalAction = false;
        this.props.onSave(this.state.Approval);
        this.handleHide();
      }
    }
  }

  handleHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
  }
  handleRejectHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }
  passToConfirmation(data) {
    this.props.enteredData(data.PoNumber);
  }

  OnNumeratorChange = (event) => {
    const approvalData = this.state.Approval;
    approvalData.NumeratorDescription = event.target.value;
    this.setState({
      Approval: approvalData,
    });
  };

  OnDenominatorChange = (event) => {
    const approvalData = this.state.Approval;
    approvalData.DenominatorDescription = event.target.value;
    this.setState({
      Approval: approvalData,
    });
  };

  IndustryBenchmarkCheck() {
    if (
      this.state.Approval["ApprovalType"] === "Global" &&
      this.props.action === "Approve" &&
      this.state.SOPageCategoryName === "Diverse Data" &&
      this.props.data.length === 1
    ) {
      this.setState({
        shouldBenchmarkDisplay: true,
      });
    } else {
      this.setState({
        shouldBenchmarkDisplay: false,
      });
    }
  }

  componentDidMount() {
    this.getIndustries();

    if (this.props.data && this.props.data.length === 1) {
      let soCatTabName = this.props.data[0].SOPageCategoryName;
      if (
        soCatTabName == "Business Outcomes" ||
        soCatTabName == "Insights & Intelligence" ||
        soCatTabName == "Diverse Data"
      ) {
        let metricUnit = "";
        if (this.props.data && this.props.data.length > 0) {
          metricUnit = this.props.data[0].MetricUnit;
        }

        if (metricUnit && metricUnit.trim() === "%") {
          this.setState({ isDisabledNumeratorRadioButton: true });
        }
        if (metricUnit && metricUnit.trim() !== "%") {
          this.setState({ isDisabledNumeratorRadioButton: false });
        }

        let DDattributeLabels = this.props.data[0].attributeDeatils.filter(
          (each) => each.ApplicableForDD === true && each.IsActive === true
        );
        this.setState({ DDattributeLabels: DDattributeLabels[0] });
      }
      let CustomSubStepMetricFlag =
        this.props.data.filter((data) => data.SOPageCategoryName === "Work Orchestration" && data.IsStandard === false).length === 0
          ? false
          : true;
      this.setState({ CustomSubStepMetric: CustomSubStepMetricFlag })
    }

    if (this.props.data.length > 1) {
      let isDDpresentInMultiSelect =
        this.props.data.filter((data) => data.SOPageCategoryName === "Diverse Data").length === 0
          ? false
          : true;

      let CustomSubStepMetricFlag =
        this.props.data.filter((data) => data.SOPageCategoryName === "Work Orchestration" && data.IsStandard === false).length === 0
          ? false
          : true;
      this.setState({ CustomSubStepMetric: CustomSubStepMetricFlag })

      this.setState({ showMetricDefinition: false, isDDpresentInMultiSelect });
    }
    //Business Outcomes" || this.state.SOPageCategoryName=="Insights & Intelligence" || this.state.SOPageCategoryName=="Diverse Data"
    else {
      if (this.props.data[0].NumeratorDescription && this.props.data[0].DenominatorDescription) {
        this.setState({ isNumeratorClicked: false, isVisibleDenominatorDefinition: true });
      } else {
        this.setState({ isNumeratorClicked: true, isVisibleDenominatorDefinition: false });
      }
      this.setState({ SOPageCategoryName: this.props.data[0].SOPageCategoryName });

      if (
        this.props.data[0].SOPageCategoryName === "Business Outcomes" ||
        this.props.tabName === "Insights & Intelligence" ||
        this.props.tabName === "Diverse Data"
      )
        this.setState({ defaultIsRequiredLeadingPractice: true, isNumeratorReqd: true, isDenomReqd: true });
      else this.setState({ defaultIsRequiredLeadingPractice: false, isNumeratorReqd: false, isDenomReqd: false });
    }


    this.props.data.map((row) => {
      this.state.Approval.DealApprovalID.push(row.DealApprovalID);
    });
    this.IndustryBenchmarkCheck();
    // this.getClients();
    // this.getRoles();
  }

  componentWillMount() {
    this.setState({
      Approval: {
        ApproverRemarks: "",
        DealApprovalID: [],
        ApprovalType: null,
        ApprovalAction: false,
        LeadingPractice: this.props.data.length === 1 ? this.props.data[0].LeadingPractice : "",
        NumeratorDescription: this.props.data.length === 1 ? this.props.data[0].NumeratorDescription : "",
        DenominatorDescription: this.props.data.length === 1 ? this.props.data[0].DenominatorDescription : null,
        DealID: this.props.data.length === 1 ? this.props.data[0].DealID : null,
        OfferingID: this.props.data.length === 1 ? this.props.data[0].OfferingID : null,
      },
    });
  }

  handleChangeLeadingPractice = (e) => {
    const approvalData = this.state.Approval;
    approvalData.LeadingPractice = e.target.value;
    this.setState({
      Approval: approvalData,
    });
  };

  // DYNAMICALLY ADDS A NEW ROW FOR ADDING BENCHMARK
  addBenchMarksRow = () => {
    debugger;
    const { benchmarkPair } = this.state;
    const clonedSingleBenchmarkPair = _.cloneDeep(singleBenchmarkPair);

    const currentBenchmarkPair = _.cloneDeep(benchmarkPair);
    currentBenchmarkPair.push(clonedSingleBenchmarkPair);
    this.setState({
      benchmarkPair: currentBenchmarkPair,
    });
  };

  // REMOVES THE SELECTED DYNAMICALLY ADDED BENCHMARK SECTION
  removeBenchMarksRow = (rowIndex) => {
    debugger;

    const { benchmarkPair } = this.state;
    const remainingBenchmarkPair = _.filter(benchmarkPair, (eachItem, index) => {
      return index !== rowIndex;
    });

    this.setState({
      benchmarkPair: remainingBenchmarkPair,
    });
  };

  // EXECUTES WHEN ANY OF THE FIELD'S VALUE OF DYNAMICALLY ADDED BENCHMARK WOULD BE CHANGED
  handleChangeBenchMarksField = (e, rowIndex, fieldName) => {
    debugger;
    const { benchmarkPair } = this.state;
    let fieldValue = e.target.value;

    const modifiedBenchmarkPair = _.map(benchmarkPair, (eachItem, index) => {
      if (index === rowIndex) {
        eachItem[fieldName] = fieldValue;
      }
      return {
        ...eachItem,
      };
    });
    this.setState({
      benchmarkPair: modifiedBenchmarkPair,
    });
  };

  // INDUSTRY DROP DOWN FOR OG="OTHERS"
  getIndustries() {
    axiosInstance.get(`${LocalApiBaseUrl}Industry/GetIndustry`).then((response) => {
      let IndustrGroups = response.data.filter((data) => data.IsActive === true && data.Status === "Approved");

      let IndustryOptions = IndustrGroups.map((x) => (
        <option key={x.IndustryId} value={x.IndustryId}>
          {x.Industry}
        </option>
      ));

      this.setState({
        IndustryOptions: IndustryOptions,
      });
    })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  render() {
    const {
      areAnyDuplicateIndustry,
      isDDpresentInMultiSelect,
      minCrossIndustryCheckFailed,
      DDattributeLabels,
      CustomSubStepMetric
    } = this.state;
    return (
      <>
        <Modal
          show={this.state.show}
          onHide={this.handleHide.bind(this)}
          className="modal fade"
          size="medium"
          id="dealpopup"
        >
          <Modal.Header closeButton>
            {this.props.action == "Approve" ? (
              <h4 className="modal-title" id="dealprofile" flag>
                Approval
              </h4>
            ) : (
              <h4 className="modal-title" id="dealprofile" flag>
                Decline
              </h4>
            )}
          </Modal.Header>
          <Modal.Body>
            <form>
              {this.props.action == "Approve" ? (
                <div className="form-section">
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="foo"
                          value="bar2"
                          onChange={this.onDealIsActiveChange}
                        />
                        <span className="checkmark"></span>
                        <span className="form-check-label">Deal</span>
                      </label>
                    </div>
                    {!CustomSubStepMetric &&
                      <div className="form-group col-lg-6">
                        <label className="form-check info-tooltipdata">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="foo"
                            value="bar3"
                            disabled={isDDpresentInMultiSelect}
                            onChange={this.onGlobalIsActiveChange}
                          />
                          <span className="checkmark"></span>
                          <span className="form-check-label">Global</span>
                          {isDDpresentInMultiSelect && (
                            <OverlayTrigger
                              key="top"
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top">
                                  At Global level, custom Diverse Data metrics need to be approved one at a time
                                </Tooltip>
                              }
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </OverlayTrigger>
                          )}
                        </label>
                      </div>}
                  </div>
                  <span className="errorMsg">{this.state.errors.error}</span>
                </div>
              ) : null}

              {this.props.action == "Approve" ? (
                <div className="form-section">
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label for="">
                        Approver Remarks<span className="required"></span>
                      </label>
                      {/* <div><input maxLength="50" type="text" className="form-control" id="AccentureSDL" value={this.props.dealData[0].ApproverRemarks} onChange={this.onRemarksChange.bind(this)} /></div> */}
                      <textarea
                        maxLength="200"
                        className="form-control"
                        value={this.state.Approval.ApproverRemarks}
                        onChange={this.onRemarksChange.bind(this)}
                      />
                      <span className="errorMsg">{this.state.errors.ApproverRemarks}</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="form-section">
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label for="">
                        Approver Remarks<span className="required">*</span>
                      </label>
                      {/* <div><input maxLength="50" type="text" className="form-control" id="AccentureSDL" value={this.props.dealData[0].ApproverRemarks} onChange={this.onRemarksChange.bind(this)} /></div> */}
                      <textarea
                        maxLength="200"
                        className="form-control"
                        value={this.state.Approval.ApproverRemarks}
                        onChange={this.onRemarksChange.bind(this)}
                      />

                      <span className="errorMsg">{this.state.errors.ApproverRemarks}</span>
                    </div>
                  </div>
                </div>
              )}

              {/* New row for adding single metric definition and leading practice dropdown */}

              {/* Row for leading practice */}
              <div className="form-section">
                {this.state.showMetricDefinition && (
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label>
                        Leading Practice
                        {this.state.defaultIsRequiredLeadingPractice && <span className="required"> *</span>}
                        {/* <span className="required">{' '}*</span> */}
                        &nbsp;
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-top">
                              Set Maximum if larger value of metric is better or Minimum if smaller value of metric is
                              better
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </OverlayTrigger>
                      </label>
                      <select
                        value={this.state.Approval.LeadingPractice}
                        onChange={(e) => this.handleChangeLeadingPractice(e)}
                        className="form-control"
                      >
                        <option value="">Select Leading Practice</option>
                        <option value="Maximum">Maximum</option>
                        <option value="Minimum">Minimum</option>
                      </select>
                      <div className="errorMsg">{this.state.errors.LeadingPractice}</div>
                    </div>
                  </div>
                )}

                {/* START - INDUSTRY BENCHMARKING */}
                {areAnyDuplicateIndustry && this.state.shouldBenchmarkDisplay && (
                  <div className="row">
                    <div className="col">
                      <Alert variant="danger">Duplicate industry found, please correct them.</Alert>
                    </div>
                  </div>
                )}
                {minCrossIndustryCheckFailed && this.state.shouldBenchmarkDisplay && (
                  <div className="row">
                    <div className="col">
                      <Alert variant="danger">Cross Industry benchmarks are mandatory</Alert>
                    </div>
                  </div>
                )}
                {this.state.shouldBenchmarkDisplay && (
                  <div className="title-with-icon mt-3 mb-1">
                    <h3 className="border-0">Industry Benchmarks</h3>
                    <div className="list-icon">
                      <span id="rock" onClick={() => this.addBenchMarksRow()}>
                        <FontAwesomeIcon icon={faPlus} />
                      </span>
                    </div>
                  </div>
                )}

                {this.state.shouldBenchmarkDisplay && (
                  <div>
                    {_.map(this.state.benchmarkPair, (eachBenchmark, index) => {
                      return (
                        <div className="mb-3" key={`definition_${index}`}>
                          <div className="row delete-iconcss">
                            <div className="form-group col-lg-6">
                              <label>
                                Industry
                                <span className="required"> *</span>
                              </label>

                              <select
                                value={eachBenchmark.Industry}
                                onChange={(e) => this.handleChangeBenchMarksField(e, index, "Industry")}
                                disabled={store.getState().CurrentFilter.languageData.id !== 1}
                                className="form-control"
                              >
                                <option value="">Select Industry</option>
                                <option value="null">Cross Industry</option>
                                {this.state.IndustryOptions}
                              </select>
                              <div className="errorMsg">{eachBenchmark.errMsgIndustryField}</div>
                            </div>
                            <span>
                              {index !== 0 && (
                                <>
                                  <span
                                    className="del-row-icon list-icon"
                                    onClick={() => this.removeBenchMarksRow(index)}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </span>
                                </>
                              )}
                            </span>
                          </div>

                          <div className="row">
                            <div className="form-group col-lg-6">
                              <label>
                                {!_.isEmpty(DDattributeLabels) ? DDattributeLabels.AttributeName1 : ""}
                                <span className="required"> *</span>
                              </label>
                              <input
                                type="text"
                                value={eachBenchmark.Attribute1Value}
                                onChange={(e) => this.handleChangeBenchMarksField(e, index, "Attribute1Value")}
                                className="form-control"
                                disabled={store.getState().CurrentFilter.languageData.id !== 1}
                              />
                              <div className="errorMsg">{eachBenchmark.errMsgAttribute1ValueField}</div>
                            </div>
                            <div className="form-group col-lg-6">
                              <label>
                                {!_.isEmpty(DDattributeLabels) ? DDattributeLabels.AttributeName2 : ""}
                                <span className="required"> *</span>
                              </label>
                              <input
                                type="text"
                                value={eachBenchmark.Attribute2Value}
                                onChange={(e) => this.handleChangeBenchMarksField(e, index, "Attribute2Value")}
                                className="form-control"
                                disabled={store.getState().CurrentFilter.languageData.id !== 1}
                              />
                              <div className="errorMsg">{eachBenchmark.errMsgAttribute2ValueField}</div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {/* END - INDUSTRY BENCHMARKING */}

                {/* Row for single metric definition */}

                {this.state.showMetricDefinition && <h3 className="my-3">Metric Definition</h3>}
                {this.state.showMetricDefinition && (
                  <div>
                    <div className="mb-3 mt-1">
                      <div className="row">
                        <div className="form-group col-lg-12 mb-2">
                          <label className="form-check mr-4">
                            <input
                              type="radio"
                              name="numeratorDenominatorRadioButtons"
                              value="numeratorDefinition"
                              onChange={this.handleRadioButtonChange}
                              checked={this.state.isNumeratorClicked}
                              disabled={this.state.isDisabledNumeratorRadioButton}
                              className="form-check-input"
                            />
                            <span className="checkmark" />
                            <span className="form-check-label">Numerator Definition</span>
                          </label>
                          <label className="form-check">
                            <input
                              type="radio"
                              onChange={this.handleRadioButtonChange}
                              checked={!this.state.isNumeratorClicked}
                              name="numeratorDenominatorRadioButtons"
                              value="numeratorAndDenominatorDefinition"
                              className="form-check-input"
                            />
                            <span className="checkmark" />
                            <span className="form-check-label">Numerator &amp; Denominator Definition</span>
                          </label>
                        </div>
                      </div>
                      {/* End of radio buttons */}

                      <div className="row">
                        <div
                          className={classNames(
                            "form-group",
                            { "col-lg-6": this.state.isVisibleDenominatorDefinition },
                            { col: !this.state.isVisibleDenominatorDefinition }
                          )}
                        >
                          <label>
                            Numerator Definition
                            {/* <span className="required">{' '}*</span> */}
                            <span className="required"> *</span>
                          </label>
                          <input
                            type="text"
                            maxLength="500"
                            className="form-control"
                            value={this.state.Approval.NumeratorDescription}
                            onChange={this.OnNumeratorChange}
                          />
                        </div>

                        {this.state.isVisibleDenominatorDefinition && (
                          <div className="form-group col-lg-6">
                            <label>
                              Denominator Definition
                              <span className="required"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.Approval.DenominatorDescription}
                              onChange={this.OnDenominatorChange}
                              maxLength="500"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {this.state.showMetricDefinition && (
                  <div>
                    <span className="errorMsg">{this.state.errors.NumeratorDenominator}</span>
                  </div>
                )}

                {!this.state.showMetricDefinition && (
                  <div className="row">
                    <div className="col-lg-12">
                      <span>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        Please ensure 'Metric Definition' and 'Leading Practice' are reviewed. To view it, select one
                        metric at a time.
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">
                <input
                  type="Button"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.handleSave()}
                  value="Save"
                />
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}
