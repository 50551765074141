import React from "react";
import { Modal } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { LocalApiBaseUrl } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import  UserRow  from "./userRow";
import  CapabilityRow  from "./CapabilityRow";
import SynopsLoader from "../Shared/SynopsLoader";
import { regexForConfMasterFields } from "../Shared/Constant";
import { isValidText,InvalidDescription,getValidUserDetails,getErrorMessageByData} from "../Shared/commonFunctions"; 
import _ from "lodash";
import $ from "jquery";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class UserPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SourceClientOptions: [],
      capabilityprofiles_row: [1],
      OfferingOptions: [],
      profileOptions: [],
      capOfferingOptions: [],
      show: this.props.show,
      errors: {},
      user: {
        UserID: 0,
        UserName: "",
        UserEnterpiseID: null,
        UserEmailAddress: null,
        IsActive: false,
        HasSisenseAccess: false,
        IsGuest: false,
        IsAdmin: false,
        IsCapability: false,
        CreatedUser: null,
        CreatedTS: null,
        ProfileDetails: [
          {
            RoleID: "",
            Profile: {},

            OrgentityIDs: [],
            selectedClientOptions: [],
            clientOptions: [],

            offeringOptions: [],
            selectedOfferingOptions: [],

            subOfferingOptions: [],
            selectedSubOfferingOptions: [],
            SubOfferingNames: [],
            ClientNames: [],
            OfferingIDs: [],
            errors: {},
          },
        ],
      },
    };
  }

  onUserNameChange = (event) => {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const user = this.state.user;

    user.UserName = event.target.value.toLowerCase();
    user.UserEnterpiseID = event.target.value;
    user.UserEmailAddress = event.target.value + "@accenture.com";
    this.setState(
      {
        user: user,
      },
      () => {}
    );
  };

  onUserEnterpiseIDChange = (event) => {
    const user = this.state.user;
    user.UserEnterpiseID = event.target.value;

    this.setState({
      user: user,
    });
  };

  onUserEmailAddressChange = (event) => {
    const user = this.state.user;
    user.UserEmailAddress = event.target.value;
    this.setState({
      user: user,
    });
  };

  onActiveChange = (event) => {
    const user = this.state.user;
    user.IsActive = event.target.checked;
    this.setState({
      user: user,
    });
  };

  onHasSisenseAccessChange = (event) => {
    const user = this.state.user;
    user.HasSisenseAccess = event.target.checked;
    this.setState({
      user: user,
    });
  };

  onAdminChange = (event) => {
    $("#guest").prop("checked", false);
    $("#isCapability").prop("checked", false);
    const user = this.state.user;
    user.IsAdmin = event.target.checked;
    user.IsGuest = false;
    user.IsCapability = false;
    user.ProfileDetails = [
      {
        RoleID: "",
        Profile: {},

        OrgentityIDs: [],
        selectedClientOptions: [],
        clientOptions: [],

        offeringOptions: [],
        selectedOfferingOptions: [],

        subOfferingOptions: [],
        selectedSubOfferingOptions: [],
        SubOfferingNames: [],
        ClientNames: [],
        OfferingIDs: [],
        errors: {},
      },
    ];
    this.setState({
      user: user,
    });
  };
  onGuestChange = (event) => {
    $("#admin").prop("checked", false);
    $("#isCapability").prop("checked", false);
    const user = this.state.user;
    user.IsAdmin = false;
    user.IsGuest = event.target.checked;
    user.IsCapability = false;
    user.ProfileDetails = [
      {
        RoleID: "",
        Profile: {},

        OrgentityIDs: [],
        selectedClientOptions: [],
        clientOptions: [],

        offeringOptions: [],
        selectedOfferingOptions: [],

        subOfferingOptions: [],
        selectedSubOfferingOptions: [],
        SubOfferingNames: [],
        ClientNames: [],
        OfferingIDs: [],
        errors: {},
      },
    ];
    this.setState({
      user: user,
    });
  };

  onCapabilityChange = (event) => {
    $("#guest").prop("checked", false);
    $("#admin").prop("checked", false);
    let errors = {};
    const user = this.state.user;
    user.IsAdmin = false;
    user.IsGuest = false;
    user.IsCapability = event.target.checked;
    user.ProfileDetails = [
      {
        RoleID: "",
        Profile: {},

        OrgentityIDs: [],
        selectedClientOptions: [],
        clientOptions: [],

        offeringOptions: [],
        selectedOfferingOptions: [],

        subOfferingOptions: [],
        selectedSubOfferingOptions: [],
        SubOfferingNames: [],
        ClientNames: [],
        OfferingIDs: [],
        errors: {},
      },
    ];
    //clear the error messages
    errors["ClientIds"] = "";

    this.setState({
      user,
      errors: errors,
    });
  };

  checkSaveDisabled = () => {
    let { user } = this.state;
    let disableSave = false;

    if (user.UserName.trim() === "" || user.UserEmailAddress.trim() === "" || user.UserEmailAddress.trim() === "") {
      disableSave = true;
    }
    if (!user.IsGuest && !user.IsAdmin) {
      if (this.checkDataProfileRows()) {
        disableSave = true;
      }
    }
    return disableSave;
  };
  checkDataProfileRows = () => {
    const { user } = this.state;
    let formInvalid = false;
    for (let x of user.ProfileDetails) {
      if (
        !(
          x.RoleID !== "" &&
          x.OrgentityIDs.length > 0 &&
          x.selectedOfferingOptions.length > 0 &&
          x.OfferingIDs.length > 0
        )
      ) {
        formInvalid = true;
        break;
      }
    }
    return formInvalid;
  };

  async validateForm() {
    let{user}=this.state;
    let errors = {};
    let formIsValid = true;
    var data = this.props.rowData;

    const{UserName}=user;
    let userData=[...new Set([UserName])];
    let userValidData=await getValidUserDetails(userData,true);

    if (data.length > 0) {
      let oldData = data.map(function(el) {
        return el.UserName;
      });
      if (oldData.includes(user.UserName.trim()) && !this.props.data) {
        formIsValid = false;
        errors["UserName"] = "Name already exists";
      }
    }

    if (!user["UserName"].trim()) {
      formIsValid = false;
      errors["UserName"] = "Name is required";
    } else {
      const {formValid,errorMessage}=getErrorMessageByData(userValidData,[UserName]);
        errors["UserName"] = errorMessage;
        formIsValid=formValid;

    }
      if(errors["UserName"]===""){
        if(!isValidText(user["UserName"])){
          errors["UserName"]= InvalidDescription();
          formIsValid = false;
        }
      }

    if (!user["UserEnterpiseID"]) {
      formIsValid = false;
      errors["UserEnterpiseID"] = "Enterpise Id is required";
    }
    if (!user["UserEmailAddress"]) {
      formIsValid = false;
      errors["UserEmailAddress"] = "Email Address is required";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  validateProfileRows = () => {
    const { user } = this.state;
    let formvalid = true;
    let index = 0;    
    for (let x of user.ProfileDetails) {
      formvalid = this.validateUserProfileSave(x, index);
      index += 1;
      if (!formvalid) break;
    }
    return formvalid;
  };
  validateUserProfileAddition = (data, index,addStatus) => {
    let { user} = this.state;
    let errors = {}

    // if Error is there return false - All 4 condition
    // if no error return true
    let isUserProfileExists = false;
    let duplicateSub = "";
    let count = 0;
    for (let m of user.ProfileDetails) {
      if (count !== index) {
        let dupProfile = String(m.RoleID) === (String(data.RoleID)) ? true:false;
        let dupCombinations =
          m.OrgentityIDs.some((item) => data.OrgentityIDs.includes(item)) &&
          //m.OfferingNames.some((item) => data.OfferingNames.includes(item.label)) &&
          m.OfferingIDs.some((item) => data.OfferingIDs.includes(item));

        if (dupProfile === true) {
          errors["ProfileRow"] = "User Profile already exists";
          isUserProfileExists = true;
        } else if (dupCombinations === true) {
          duplicateSub = addStatus ? m.SubOfferingNames.join(", "): data.SubOfferingNames.join(", ");
          duplicateSub = duplicateSub.length > 92 ? duplicateSub.slice(0, 89) + "..." : duplicateSub;
          errors["ProfileRow"] = `User profile already exists for ${duplicateSub}`;
          isUserProfileExists = true;
        }
      }
      count += 1;
    }
    user.ProfileDetails = user.ProfileDetails.map((e,i)=>{
      if(String(index) === String(i))
      e.errors = errors
      return e;
    })
    this.setState({
      user,
    });
    return !isUserProfileExists;
  };
  validateUserProfileSave = (data, index) => {
    let { user} = this.state;
    let errors = {}

    // if Error is there return false - All 4 condition
    // if no error return true
    let isUserProfileExists = false;
    let duplicateSub = "";
    let count = 0;
    let errorIndex = [];
    for (let m of user.ProfileDetails) {
      if (count !== index) {
        let dupProfile = String(m.RoleID) === (String(data.RoleID)) ? true:false;
        let dupCombinations =
          m.OrgentityIDs.some((item) => data.OrgentityIDs.includes(item)) &&
          //m.OfferingNames.some((item) => data.OfferingNames.includes(item.label)) &&
          m.OfferingIDs.some((item) => data.OfferingIDs.includes(item));

        if (dupProfile === true) {
          errors["ProfileRow"] = "User Profile already exists";
          isUserProfileExists = true;
          errorIndex.push(count);
        } else if (dupCombinations === true) {
          duplicateSub =  data.SubOfferingNames.join(", ");
          duplicateSub = duplicateSub.length > 92 ? duplicateSub.slice(0, 89) + "..." : duplicateSub;
          errors["ProfileRow"] = `User profile already exists for ${duplicateSub}`;
          isUserProfileExists = true;
          errorIndex.push(count);
        }
      }
      count += 1;
    }
    user.ProfileDetails = user.ProfileDetails.map((e,i)=>{
      if(errorIndex.some(a => a === i))
      e.errors = errors
      return e;
    })
    this.setState({
      user,
    });
    return !isUserProfileExists;
  };

   handleSave = async() => {
    if (await this.validateForm()) {
      this.clearErrors()
      if (this.validateProfileRows()) {
        let requestData = this.state.user;
        requestData = {
          UserID: requestData.UserID,
          UserName: requestData.UserName,
          UserEnterpiseID: requestData.UserEnterpiseID,
          UserEmailAddress: requestData.UserEmailAddress,
          IsActive: requestData.IsActive,
          IsGuest: requestData.IsGuest,
          IsAdmin: requestData.IsAdmin,
          IsCapability: requestData.IsCapability,
          HasSisenseAccess: requestData.HasSisenseAccess,
          ProfileDetails: requestData.IsAdmin || requestData.IsGuest ? [] : requestData.ProfileDetails,
        };

        this.props.onSave(requestData);
        this.handleHide();
      }
    }
  };

  handleHide = (e) => {
    this.setState({ show: false });
    if (e !== undefined) {
      if (e.target.textContent !== "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
  };
  getInitialOfferings = () => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${LocalApiBaseUrl}Offering/GetOfferingsList`, {
          params: { FeatureName: "Users",IsCalledForFlatJSONStructure: true },
        })
        .then((response) => {
          let capLoadOfferingOptions = response.data.map((e) => ({ ...e, value: e.OfferingID, label: e.OfferingName })).sort((a,b)=>a.label.toLowerCase() > b.label.toLowerCase() ?1:-1);
          this.setState({ capLoadOfferingOptions });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    });
  };
  getRoles = () => {
    var EnterpriseID = sessionStorage.getItem("EnterpriseID");
    if (EnterpriseID !== undefined) {
      axiosInstance
        .get(`${LocalApiBaseUrl}user/GetRoles`, {
          params: {},
        })
        .then((response) => {
          let profileOptions = response.data.map((e) => ({ ...e, value: e.RoleID, label: e.RoleName })).sort((a,b)=>a.label.toLowerCase() > b.label.toLowerCase() ?1:-1);
          this.setState({ profileOptions });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };

  getClients = () => {
    let user = this.state.user;
    this.setState({ isApiLoading: true });
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${LocalApiBaseUrl}SO/GetOrgStructure`,{ 
        params: { FeatureName: "Users" },
      })
        .then((response) => {
          let SourceClientOptions = [];
          let selectedSubOfferingOption = this.state.selectedSubOfferingOption;
          for(let row of response.data){
            let dummy = {};
            dummy.label = row.OrgEntityName;
            dummy.value = row.OrgEntityID;
            SourceClientOptions.push(dummy);
          };
          SourceClientOptions = SourceClientOptions.sort((a,b)=>a.label.toLowerCase() > b.label.toLowerCase() ?1:-1)
          this.setState(
            {
              SourceClientOptions,
              selectedSubOfferingOption,
              user,
              isApiLoading: false,
            },
            () => {
              resolve();
            }
          );
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          reject();
        });
    });
  };

  handlePopulateEditDataNonCapability = async (data) => {
    let user = { ...data };
    let { profileOptions, SourceClientOptions } = this.state;
    this.setState({
      isApiLoading: true,
    });
    for (let x of user.ProfileDetails) {
      x.RoleID = x.RoleId;
      let profile = profileOptions.find((e) => String(e.value) === String(x.RoleID));
      x.Profile = profile !== undefined ? { label: profile.label, value: profile.value } : {};
      x.selectedClientOptions = SourceClientOptions.filter((a) =>
        x.OrgentityIDs.some((b) => String(b) === String(a.value))
      );
      let clientIds = x.OrgentityIDs;
      x.offeringOptions = await this.returnOfferings(clientIds);
      x.subOfferingOptions = this.getSubOfferingData(x.offeringOptions);
      x.selectedSubOfferingOptions = x.subOfferingOptions.filter((a) =>
        x.OfferingIDs.some((e) => String(e) === String(a.OfferingID))
      );
      x.SubOfferingNames = x.selectedSubOfferingOptions.map((e) => e.label);
      x.selectedOfferingOptions = x.offeringOptions.filter((e) =>
        x.selectedSubOfferingOptions.some((a) => a.ParentOfferingName.toLowerCase() === e.OfferingName.toLowerCase())
      );
      x.errors = {}
    }
    this.setState({
      user,
      isApiLoading: false,
    });
  };
  handlePopulateEditDataCapability = async (data) =>{
    let user = { ...data };
    let { profileOptions, capLoadOfferingOptions } = this.state;
    this.setState({
      isApiLoading: true,
    });
    for (let x of user.ProfileDetails) {
      //PROFILE
      x.RoleID = x.RoleId;
      let profile = profileOptions.find((e) => String(e.value) === String(x.RoleID));
      x.Profile = profile !== undefined ? { label: profile.label, value: profile.value } : {};
      
      //OFFERING 
      x.offeringOptions = capLoadOfferingOptions
      let subOfferingdata = this.getSubOfferingData(x.offeringOptions)
      //SUBOFFERING
      x.selectedSubOfferingOptions = subOfferingdata.filter(e=>x.OfferingIDs.some(a=>String(a) === String(e.OfferingID))) 
      x.selectedOfferingOptions = x.offeringOptions.filter(a=> x.selectedSubOfferingOptions.some((e)=>e.ParentOfferingName.toLowerCase() === a.OfferingName.toLowerCase()))
      x.subOfferingOptions = this.getSubOfferingData(x.selectedOfferingOptions) 
      //CLIENT
      x.clientOptions = await this.returnClients(x.OfferingIDs)
      x.selectedClientOptions = x.clientOptions.filter(a=>x.OrgentityIDs.some(e=>String(e)=== String(a.OrgEntityID)))
      x.errors={}
    }
    this.setState({
      user,
      isApiLoading: false,
    });
  }

  async componentDidMount() {
    this.getInitialOfferings();
    this.getRoles();
    await this.getClients();
    if (this.props.data) {
      this.setState({
        isApiLoading: true,
      });
      axiosInstance
        .get(`${LocalApiBaseUrl}UserProfile/GetUserProfileDetails?userID=${this.props.data.UserID}`)
        .then((res) => {
          this.setState({
            isApiLoading: false,
          });
          res.data.IsCapability ? this.handlePopulateEditDataCapability(res.data):
          this.handlePopulateEditDataNonCapability(res.data);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          this.setState({
            isApiLoading: false,
          });
        });
    }
  }

  // START OF NON CAPABILITY FUNCTIONS
  handleProfileSelect = (data, index) => {
    let profileDetail = this.state.user.ProfileDetails[index];
    let roleId = this.state.profileOptions.filter((e) => e.label.toLowerCase() === data.toLowerCase())[0].RoleID;
    profileDetail.Profile = { label: data, value: roleId };
    profileDetail.RoleID = roleId;
    this.handleDropDownChange(profileDetail, index);
  };
  handleSelectedClientOptions = (data, index) => {
    let profileDetail = this.state.user.ProfileDetails[index];
    profileDetail.selectedClientOptions = data;
    profileDetail.OrgentityIDs = [...data.map((e) => e.value)];
    let clientIds = data.map((e) => e.value);
    this.getOfferings(clientIds, profileDetail, index, this.handleDropDownChange);
  };

  handleSelectedOfferingOptions = (data, index) => {
    let profileDetail = this.state.user.ProfileDetails[index];
    profileDetail.selectedOfferingOptions = [...data];
    profileDetail.subOfferingOptions = this.getSubOfferingData([...data]);
    this.handleDropDownChange({ ...profileDetail }, index);
  };

  handleSelectedSubOfferingOptions = (data, index) => {
    let profileDetail = this.state.user.ProfileDetails[index];
    profileDetail.selectedSubOfferingOptions = data;
    profileDetail.OfferingIDs = [...data.map((e) => e.value)];
    profileDetail.SubOfferingNames = [...data.map((e) => e.label)];
    this.handleDropDownChange(profileDetail, index);
  };

  // END OF NON CAPABILITY FUNCTIONS

  //START OF CAPABILITY FUNCTIONS
  handleSelectedCapClientOptions = (data, index) => {
    let profileDetail = this.state.user.ProfileDetails[index];
    profileDetail.selectedClientOptions = data;
    profileDetail.OrgentityIDs = [...data.map((e) => e.value)];
    profileDetail.ClientNames = [...data.map((e) => e.label)];
    this.handleDropDownChange(profileDetail, index);
  };

  handleSelectedCapSubOfferingOptions = (data, index) => {
    let profileDetail = this.state.user.ProfileDetails[index];
    profileDetail.OfferingIDs = [...data.map((e) => e.value)];
    profileDetail.selectedSubOfferingOptions = [...data];
    profileDetail.SubOfferingNames = [...data.map((e) => e.label)];
    let OfferingIDs = data.map((e) => e.value);
    this.getCapClients(OfferingIDs, profileDetail, index, this.handleDropDownChange);
  };

  //END OF CAPABILITY FUNCTIONS

  getOfferings = (data, profileD, index, callback) => {
    let profileDetail = { ...profileD };
    if (data.length > 0) {
      let request = { OrgEntityID: data.toString(), FeatureName: "Users" }
      axiosInstance
        .post(`${LocalApiBaseUrl}Configurator/GetTowerLevelOffering`,request)
        .then((response) => {
          let offeringOptions = response.data;
          offeringOptions = offeringOptions.map((e) => ({ ...e, label: e.OfferingName, value: e.OfferingName }));
          profileDetail.offeringOptions = offeringOptions;
          callback(profileDetail, index);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    } else {
      profileDetail.offeringOptions = [];
      callback(profileDetail, index);
    }
  };

  getCapClients = (data, profileD, index, callback) => {
    let profileDetail = { ...profileD };
    if (data.length > 0) {
      axiosInstance
        .get(`${LocalApiBaseUrl}Configurator/GetTowerLevelClient`, {
          params: { OfferingID: data.toString(), FeatureName: "Users" },
        })
        .then((response) => {
          let clientOptions = response.data;
          clientOptions = clientOptions.map((e) => ({ ...e, label: e.OrgEntityName, value: e.OrgEntityID }));
          profileDetail.clientOptions = clientOptions;
          callback(profileDetail, index);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    } else {
      profileDetail.clientOptions = [];
      callback(profileDetail, index);
    }
  };

  returnOfferings = (data) => {
    return new Promise((resolve, reject) => {
      if (data.length > 0) {
        let request = { OrgEntityID: data.toString(), FeatureName: "Users" }
        axiosInstance
          .post(`${LocalApiBaseUrl}Configurator/GetTowerLevelOffering`, request)
          .then((response) => {
            let offeringOptions = response.data;
            offeringOptions = offeringOptions.map((e) => ({ ...e, label: e.OfferingName, value: e.OfferingName }));
            resolve(offeringOptions);
          })
          .catch((error) => {
            trycatchAlertPopup(error);
            reject([]);
          });
      } else {
        resolve([]);
      }
    });
  };

  returnClients = (data) => {
    return new Promise((resolve, reject) => {
      if (data.length > 0) {
        axiosInstance
          .get(`${LocalApiBaseUrl}Configurator/GetTowerLevelClient`, {
            params: {OfferingID: data.toString(), FeatureName: "Users" },
          })
          .then((response) => {
            let clientOptions = response.data;
            clientOptions = clientOptions.map((e) => ({ ...e, label: e.OrgEntityName, value: e.OrgEntityID }));
            resolve(clientOptions);
          })
          .catch((error) => {
            trycatchAlertPopup(error);
            reject([]);
          });
      } else {
        resolve([]);
      }
    });
  };
  //REUSABLE FUNCTIONS
  getSubOfferingData = (offerings) => {
    let temp = [...offerings].map((e) => e.ChildOfferings).flat(1);
    temp = _.uniqBy(temp, "OfferingID");
    let subOfferingOptions = temp.map((e) => ({ ...e, label: e.Process, value: e.OfferingID }));
    subOfferingOptions = subOfferingOptions.sort((a,b)=>a.label.toLowerCase() > b.label.toLowerCase() ?1:-1)
    return [...subOfferingOptions];
  };

  handleDropDownChange = (profDetail, index) => {
    let profileDetail = {...profDetail}
    let { user, errors } = this.state;
    user.ProfileDetails = [
      ...user.ProfileDetails.map((e, i) => {
        if (i === index) {
          profileDetail.errors = {}
          return profileDetail;
        } else {
          e.errors = {}
          return e;
        }
      }),
    ];
    errors["ProfileRow"] = "";
    this.setState({
      user,
      errors,
    });
  };
  clearErrors = () =>{
    let { user } = this.state
    user.ProfileDetails= user.ProfileDetails.map(a=>{
      a.errors = {}
      return a
    })
    this.setState({
      user,
    })
  }

  addProfileRow = (index,data) => {
    // e.stopPropagation();

    this.clearErrors()

    if (this.validateUserProfileAddition(data, index,true)) {
      let { user } = this.state;
      let empty = {
        RoleID: "",
        Profile: {},

        OrgentityIDs: [],
        selectedClientOptions: [],
        clientOptions: [],

        offeringOptions: [],
        selectedOfferingOptions: [],

        subOfferingOptions: [],
        selectedSubOfferingOptions: [],
        SubOfferingNames: [],
        ClientNames: [],
        OfferingIDs: [],
        errors: {},
      };

      user.ProfileDetails.push(empty);
      this.setState({
        user,
      });
    }
  };

  deleteProfileRow = (index) => {
    let { user } = this.state;
    user.ProfileDetails = [...user.ProfileDetails.filter((e, i) => String(i) !== String(index))];
    user.ProfileDetails = user.ProfileDetails.map(e=>({...e,errors:{}}))
    this.setState({
      user,
    });
  };

  render() {

    const {
      profileOptions,
      SourceClientOptions,
      user,
      errors,
      capLoadOfferingOptions,
      isApiLoading,
    } = this.state;
    let disableSave = this.checkSaveDisabled();

    return (
      <>
        {isApiLoading && <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />}
        {/* <Header /> */}

        <Modal
          show={this.state.show}
          onHide={this.handleHide.bind(this)}
          className="modal fade addadminModal"
          backdrop="static"
        >
          <Modal.Header closeButton>
            {!this.props.data ? (
              <h4 className="modal-title" id="dealprofile" flag>
                Add User
              </h4>
            ) : (
              <h4 className="modal-title" id="dealprofile" flag>
                Edit User
              </h4>
            )}
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="useractions-block">
                <div className="form-section">
                  <div className={!this.props.data ? "form-group border-group":"form-group border-group disabled"}>
                    <label for="name">User Name<span className="required">*</span></label>
                    <div class="input-group">
                      {!this.props.data ? (
                        <input
                          type="text"
                          maxlength="100"
                          className="form-control"
                          id="name"
                          placeholder="User Name"
                          onChange={this.onUserNameChange}
                          value={user.UserName}
                        />
                      ) : (
                        <input
                          type="text"
                          maxlength="100"
                          className="form-control"
                          id="name"
                          onChange={this.onUserNameChange}
                          value={user.UserName}
                        />
                      )}
                      <div class="input-group-append">
                        <div class="input-group-text">@accenture.com</div>
                      </div>
                    </div>
                  </div>
                  <span className="errorMsg">{errors.UserName}</span>
                </div>
                <div className="toggle-group form-group custom-toggleswitch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="IsActive"
                    id="IsActive"
                    onChange={this.onActiveChange}
                    checked={user.IsActive}
                  />
                  <label htmlFor="IsActive">Is Active</label>
                  <div className="onoffswitch" aria-hidden="true">
                    <div className="onoffswitch-label">
                      <div className="onoffswitch-inner"></div>
                      <div className="onoffswitch-switch"></div>
                    </div>
                  </div>
                  <span className="toggleswitch-text">Is Active</span>
                </div>
              </div>
              <div className="roleactions-block form-group">
                <span className="role-label">User Role</span>
                {this.props.featureData.filter(
                  (data) => data.Submenu === "Users" && data.Operations.includes("Can Create Admin Users")
                ).length > 0 ? (
                  <label className="form-check form-check-inline">
                    <input
                      id="admin"
                      className="form-check-input"
                      type="checkbox"
                      onChange={this.onAdminChange}
                      checked={user.IsAdmin}
                    />
                    <span className="checkmark"></span>
                    <span className="form-check-label">Is Admin </span>
                  </label>
                ) : null}
                {this.props.featureData.filter(
                  (data) => data.Submenu === "Users" && data.Operations.includes("Can Create Guest Users")
                ).length > 0 ? (
                  <label className="form-check form-check-inline">
                    <input
                      id="guest"
                      className="form-check-input"
                      type="checkbox"
                      onChange={this.onGuestChange}
                      checked={user.IsGuest}
                    />
                    <span className="checkmark"></span>
                    <span className="form-check-label">Is Guest </span>
                  </label>
                ) : null}
                {this.props.featureData.filter(
                  (data) => data.Submenu === "Users" && data.Operations.includes("Can Create Capability Users")
                ).length > 0 ? (
                  <label class="form-check form-check-inline">
                    <input
                      id="isCapability"
                      class="form-check-input"
                      type="checkbox"
                      onChange={this.onCapabilityChange}
                      checked={user.IsCapability}
                    />
                    <span class="checkmark"></span>
                    <span class="form-check-label">Is Capability </span>
                  </label>
                ) : null}
                <div className="col-sm-6">
                                <label className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    
                                    checked={this.state.user.HasSisenseAccess}
                                    onChange={(e) => this.onHasSisenseAccessChange(e)}
                                  />
                                  <span className="checkmark"></span>
                                  <span className="form-check-label">{"Has Sisense Access"}</span>
                                </label>
                              </div>
              </div>
              <>
                {!user.IsAdmin && !user.IsGuest ? (
                  <>
                    <span className="errorMsg">{errors.ProfileRow}</span>
                    <div className="formsection-fieldsection">
                      {!user.IsCapability
                        ? user.ProfileDetails.map((each, index) => {
                            return (
                              <>
                                <UserRow
                                  selectedprofile={each.Profile}
                                  sourceRoleOptions={profileOptions}
                                  handleProfileSelect={this.handleProfileSelect}
                                  clientOptions={SourceClientOptions}
                                  handleSelectedClientOptions={this.handleSelectedClientOptions}
                                  selectedClientOptions={each.selectedClientOptions}
                                  offeringOptions={each.offeringOptions}
                                  selectedOfferingOptions={each.selectedOfferingOptions}
                                  handleSelectedOfferingOptions={this.handleSelectedOfferingOptions}
                                  subOfferingOptions={each.subOfferingOptions}
                                  selectedSubOfferingOptions={each.selectedSubOfferingOptions}
                                  handleSelectedSubOfferingOptions={this.handleSelectedSubOfferingOptions}
                                  addProfileRow={this.addProfileRow}
                                  deleteProfileRow={this.deleteProfileRow}
                                  profileDetailprops={each}
                                  user={user}
                                  rowIndex={index}
                                />

                                <span className="errorMsg">{each.errors.ProfileRow}</span>
                              </>
                            );
                          })
                        : user.ProfileDetails.map((each, index) => {
                            return (
                              <>
                              <CapabilityRow
                                selectedprofile={each.Profile}
                                sourceRoleOptions={profileOptions}
                                handleProfileSelect={this.handleProfileSelect}
                                clientOptions={each.clientOptions}
                                handleSelectedClientOptions={this.handleSelectedCapClientOptions}
                                selectedClientOptions={each.selectedClientOptions}
                                offeringOptions={capLoadOfferingOptions}
                                selectedOfferingOptions={each.selectedOfferingOptions}
                                handleSelectedOfferingOptions={this.handleSelectedOfferingOptions}
                                subOfferingOptions={each.subOfferingOptions}
                                selectedSubOfferingOptions={each.selectedSubOfferingOptions}
                                handleSelectedSubOfferingOptions={this.handleSelectedCapSubOfferingOptions}
                                addProfileRow={this.addProfileRow}
                                deleteProfileRow={this.deleteProfileRow}
                                profileDetailprops={each}
                                user={user}
                                rowIndex={index}
                              />
                            <span className="errorMsg">{each.errors.ProfileRow}</span>
                              </>
                            );
                          })}
                    </div>
                  </>
                ) : null}
              </>
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn adminpopup-footer">
                <button className="btn btn-default btn-sm mr-2" onClick={this.handleHide.bind(this)}>
                  Cancel
                </button>
                {!this.props.data ? (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave()}
                    value="Save"
                    disabled={disableSave}
                  />
                ) : (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave()}
                    value="Save"
                    disabled={disableSave}
                  />
                )}
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}
