import React from "react";
import ReactTooltip from "react-tooltip";
import i18n from "i18next";
import { LocalApiBaseUrl } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import jquery from "jquery";
import { store } from "../reducers/configureStore";
import HumanPopUp from "../Configurator/Humanconf_popup";
import { connect } from "react-redux";
import { ValueUnit, ValueUnitNoRoundOff, MetricValueUnitFormat, toolThreshold, CommonMetriTileFix } from "../Shared/Common";
import MetricComments from "../Dashboard/MetricComments";
import $ from 'jquery';
import { v4 as uuidv4 } from "uuid";

window.$ = window.jQuery = jquery;

class HumanMetricDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      viewmore: false,
      kpiData: [],
      commentPopUp: false,
    };
    this.ellipsisTruncate = this.ellipsisTruncate.bind(this);
  }
  ellipsisTruncate(str) {
    return str.length > 55 ? str.substring(0, 55) + "..." : str;
  }
  dealpopup = (data, Id, e) => {
    e.preventDefault();
    this.setState({
      viewmore: Id,
      kpiData: data,
    }, () => {
      this.props.updateFlagTrigger();
    });
  };

  onClose = (e) => {
    this.setState({ viewmore: false }, () => {
      this.props.updateFlagTrigger();
    });
  };

  deleteKpi(data, e) {    
    this.props.updateFlagTrigger();
    if (window.confirm("Are you sure you want to delete metric detail?")) {
      e.stopPropagation();
      axiosInstance
        .delete(`${LocalApiBaseUrl}Configurator/DeleteDealMetrics`, {
          params: {
            DealMetricsId: data.ConfigurationDealMetricID,
          },
        })
        .then((response) => {
          if (response.data.IsSuccess) {
            axiosInstance
              .get(`${LocalApiBaseUrl}Configurator/GetHumanMachineTalentQualityScore`, {
                params: {
                  LocationID: this.props.filterDetails.Locations,
                  OrgEntityID: this.props.filterDetails.Client,
                  OfferingID: this.props.filterDetails.process,
                  MonthDT: this.props.filterDetails.Month,
                  LanguageID: store.getState().CurrentFilter.languageData.id,
                },
              })
              .then((response) => {
                if (response.data) {
                  store.dispatch({
                    type: "SAVE_QC_SCORE_OF_EACH_TAB",
                    payload: response.data,
                  });
                }
              })
              .catch((error) => {
                trycatchAlertPopup(error);
              });
              var human={
                kpiData:data,
                selectedOption:this.props.selectedOption
              }
              var machine={
                kpiData:data,
                machineWorkforceCat:this.props.machineWorkforceCat
              }
           
              if (this.props.Name == this.props.selectedOption) {
                store.dispatch({
                  type: "REMOVE_HUMAN",
                  payload: human,
                });
              }          
              else if(this.props.Name == this.props.machineWorkforceCat ) {
              store.dispatch({
                type: "REMOVE_MACHINE",
                payload: machine,
              });
            }
                                    
            this.props.updateFlagTrigger();
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });

      
    }
  }
  onCommentClick = (val, data) => {
   

    if (val === 1) {
      this.setState({
        commentPopUp: data,
      });
    } else {
      this.setState({
        commentPopUp: "",
      });
    }
  };
  componentDidMount(){
    CommonMetriTileFix();
  }
  render() {
    let details = [...this.props.Details];
    return details.map((eachDetails) => {
      let AttrValue = "";
      let metricName = "";
      let unit = "";
      let metricData = "";
      let ValueBlock = true;
      let MetricValueUnitFormatData = "";
      let Description = "";
      let viewmore = false;
      let info = "";
      let KPIMappingID = "";
      const uniqueIDForCurrentMetric = uuidv4();
      if (eachDetails != undefined) {
        metricName = eachDetails.MetricName;
        unit = eachDetails.MetricUnit;
      }
                let statusColor = "item gray-color";
                   
                        if(unit.includes('$') || unit.includes('€') || unit.includes('£') || unit.includes('₹') || unit.includes('SGD') || unit.includes('R$')) {
                          metricData = ValueUnitNoRoundOff(eachDetails.MetricValue);
                        } 
                        else {
                          metricData = ValueUnit(eachDetails.MetricValue);
                        }
                        MetricValueUnitFormatData = MetricValueUnitFormat(unit,metricData);
                        KPIMappingID = eachDetails.KPIMappingID;
                        Description = eachDetails.KPIDesp ? eachDetails.KPIDesp: "";
                        info =  eachDetails.Comments  ? eachDetails.Comments :"NA";
                        unit = eachDetails.MetricUnit;
                        MetricValueUnitFormatData = MetricValueUnitFormat(unit,metricData);
                        
                      
                       
                        if (eachDetails != undefined) {
                          
            
                        if (eachDetails.RAGStatus != undefined && eachDetails.RAGStatus != null) {
                            switch (eachDetails.RAGStatus.toLowerCase()) {
                              case "grey":
                                statusColor = "item gray-color";
                                break;
                              case "red":
                                statusColor = "item red-color";
                                break;
                              case "orange":
                                statusColor = "item amber-color";
                                break;
                              case "green":
                                statusColor = "item green-color";
                                break;
                              case "amber":
                                statusColor = "item amber-color";
                                break;
                              default:
                              // code block                        
                            }
                        }               
                    }
                       
                        const formattertwo=new Intl.NumberFormat('en',{
                          useGrouping:true
                        })
                        
                return (
                <React.Fragment key={uniqueIDForCurrentMetric}>
                 {eachDetails.KPIMappingID !=0 && 
                <div className={`${statusColor}`} data-place="bottom" data-for={'thresholdvalue_'+eachDetails.KPIMappingID}
				          data-tip={toolThreshold(eachDetails)}>


          <div className="item-left">
  
            {ValueBlock ? (
              <>
                <p className="tile-metric-value">{MetricValueUnitFormatData}</p>
              </>
            ) : null}
            <p className="tile-metric-desc" data-tip data-for={"customTooltip" + eachDetails.KPIMappingID}>
              
              {metricName}
             
            </p>
          </div>
          <div className="item-right">
            {Description ? (
              <p className="tile-metric-name" data-tip data-for={"customTooltip_desc" + eachDetails.KPIMappingID}>
               
                {Description}
               
              </p>
            ) : (
              <p className="tile-metric-name">&nbsp;</p>
            )}
          </div>
          {this.state.commentPopUp === KPIMappingID ? (
            <MetricComments info={eachDetails} show={true} isMachine={this.props.machineWorkforceCat!=undefined} onClose={this.onCommentClick.bind(this, 2, "null")} />
          ) : null}
          <div className="dropdown dropleft ondropOpenColor">
              <span type="button" className="dropdown-toggle" data-toggle="dropdown" id={`dropdownMenuLink-${uniqueIDForCurrentMetric}`}></span>
              <div className="dropdown-menu" aria-labelledby={`dropdownMenuLink-${uniqueIDForCurrentMetric}`}>
                <a href="javascript:void(0)" className="dropdown-item" onClick={this.onCommentClick.bind(this, 1, KPIMappingID)}>
                  <span className="info-icon"></span>{i18n.t("Info")}
                </a>
                {(this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") ||
                  (this.props.RecordStatus &&
                    this.props.RecordStatus[1] === "True" &&
                    !this.props.isManintencneWindowOpen) ||
                  (this.props.RecordStatus &&
                    this.props.RecordStatus[0] === "Publish" &&
                    !this.props.isManintencneWindowOpen) ||
                  this.props.roleReducers.filter((each) => {
                    if (each.FeatureName === "Configurator" && each.Operations.length) {
                      return each;
                    }
                  }).length === 0 ||
                  (this.props.RecordStatus &&
                    this.props.RecordStatus[0] === "Save" &&
                    this.props.roleReducers.filter((each) => {
                      if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Submit").length) {
                        return each;
                      }
                    }).length === 0) ||
                  (this.props.RecordStatus &&
                    this.props.RecordStatus[0] === "Submit" &&
                    this.props.roleReducers.filter((each) => {
                      if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Publish").length) {
                        return each;
                      }
                    }).length === 0) ? "" : 
                  <>
                    <a
                      href="javascript:void(0)"
                      className="dropdown-item"
                      onClick={this.deleteKpi.bind(this, eachDetails)}
                    >
                      <span className="delete-icon"></span>{i18n.t("Remove")}
                    </a>
                    <a
                      href="javascript:void(0)"
                      className="dropdown-item"
                      onClick={this.dealpopup.bind(
                        this,
                        eachDetails,
                        KPIMappingID
                    )}
                    >
                      <span className="edit-icon"></span>{i18n.t("Edit")}
                    </a>
                  </>
                }
              </div>
            </div>
            
          

					{this.state.viewmore == KPIMappingID ? (
						<HumanPopUp
              CatagoryName={this.props.CatagoryName}
							SOCategoryID={this.props.SOCategoryID}
							type={this.props.Name}
							kpiData={this.state.kpiData}
							show={this.state.viewmore}
							onClose={this.onClose.bind(this)}
							opsgrp={this.props.opsgrp}
							filterDetails={this.props.filterDetails}
              machineWorkforceCat={this.props.machineWorkforceCat}
              selectedOption={this.props.selectedOption}
              responseMetricData={this.props.responseMetricData}
						/>
					) : null}
				
          </div>}
				<ReactTooltip place="bottom" effect="solid" id={'thresholdvalue_'+eachDetails.KPIMappingID} className="tipcolor" /> 
        </React.Fragment>
			);
		});
	}
}
const mapStateToProps = (state) => {
  return {
    roleReducers: state.roleReducers.roleReducers,
  };
};

export default connect(
  mapStateToProps,
  ""
)(HumanMetricDetails);
