import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import i18n from 'i18next';
import ReactTooltip from 'react-tooltip';
import OwlCarousel from 'react-owl-carousel';
import _ from 'lodash';
import classNames from 'classnames';
import SynopsLoader from '../Shared/SynopsLoader';
import LandingPageAdmin from './landingPageAdmin';
import AssetsSummaryView from './assetsSummaryView';
import AssetsLinkURLModal from './assetsLinkURLModal';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup, LocalApiBaseUrl, LocalApiBaseUrlImage, procurementOfferingList } from '../Shared/Constant';
import * as common from "../Shared/Common";

// IMPORT STYLES/IMAGES
import './pulseStyle.css';
import synopsIcon from '../Images/Synops-Gradient-Transparent.svg';
import accentureLogo from '../Images/Accenture.png';
import noFilter from '../Images/funnel-icon.svg';
import emptyFeatureListIcon from '../Images/EmptyFeatureList.svg';
import ToolSummaryLogo from '../Images/tool-summary-gradient.svg';
import noClientLogo from '../Images/client-0.svg';
import defaultIcon from "../Images/assetsImages/defaultPulseToolImage.png"
import synopsAAAIcon from '../Images/SynOps_Gradient-Icon.jpg';
import MORCaseStudy from './MORCaseStudy';
import { GenerateExtensionIcon, createBreadCrumbs, openURLInNewWindow  } from '../Shared/commonFunctions';

const carouselOptions = {
  margin: 12,
  dots: false,
  autoWidth: true,
  autoplay: false,
  loop: false,
  nav: true,
  responsiveClass: true,
  slideBy: 3,
  responsive: {
    0: {
      items: 2,
    },
    400: {
      items: 2,
    },
    600: {
      items: 3,
    },
    700: {
      items: 3,
    },
    1000: {
      items: 3,
    },
  },
};

class LandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ismorpulseapiloading: true,
      ItemDataLevel: '',
      configureStatus: false,
      selectedsummaryViewAsset: '',
      selectedsummaryViewAssetURL: '',
      summaryStatus: false,
      pulsePageAdminData: [],
      showLoaderOnPage: false,
      selectedPulseDataCategory: '',
      enableAssetsLinkStatus: false,
      linkURL: '',
      maxSequences: [],
      MorItemsData: [],
      leverDescription: [],
      isIFrame: false,
      pulsemorModalStatus: false,
      addcsModalStatus: false,
      editcsModalStatus: false,
      OpriaURL: '',
      Period: ''
    };
  }

  componentDidMount(){
    this.fetchLeverDescription();
  }

  GetAllPulseMORItemData() {
    const {
      PulseOffering,
      PulseofferingId,
      clientData,
      languageData,
      InitializeMORData,
    } = this.props;

    const data = {
      OrgEntityID: clientData.id,
      Offering: PulseOffering,
      SubOfferingID: PulseofferingId,
      LanguageID: languageData.id,
      PageNo: 0,
      PageSize: 0,
      Searchquery: '',
    };
    this.setState({ showLoaderOnPage: true });
    axiosInstance
      .post(`${LocalApiBaseUrl}MOR/GetAllPulseMORItemData`, data, {
        params: {},
      })
      .then((response) => {
        let { MORItemsModel } = response.data;
        MORItemsModel = MORItemsModel.map((a, index) => ({
          ...a,
          ExtensionAndIcon:
              a.ItemLink !== '' && a.ItemLink != null
                ? GenerateExtensionIcon(a.ItemLink)
                : '',
        }));

        InitializeMORData(
          MORItemsModel,
          {
            Level: response.data.ItemDataLevel,
            CategoryID: response.data.CategoryID,
            ismorpulseapiloading: false,
          },
          response.data.isCategoryData,
        );

        this.setState({
          MorItemsData: MORItemsModel,
          showLoaderOnPage: false,

          ItemDataLevel: response.data.ItemDataLevel,
        });
      })
      .catch((error) => {
        InitializeMORData(
          [],
          { Level: '', CategoryID: 0, ismorpulseapiloading: false },
          false,
        );
        this.setState({ showLoaderOnPage: false });
      });
  }

  fetchLeverDescription = () => {
    const { TabName, languageData } = this.props;
    axiosInstance
      .get(
        `${LocalApiBaseUrl}so/GetSOCategories`,
        { params: { LanguageID: languageData.id } },
        // params: { OrgEntityID: filterClient, OfferingID: filterProcess, LocationID: filterLocations, MonthDT: todayDate }
      )
      .then((response) => {
        let tempData = [];
        let foundData = {};
        response.data.map((item) => {
          tempData.push({
            tab: item.SOCategoryName_En,
            description: item.SOCategoryDescription
          })
        })
        foundData = tempData.find((item) => item.tab === TabName)
        this.setState({
          leverDescription: tempData,
        })
      })
      .catch((err) => {
        trycatchAlertPopup(err);
      });
  }

  getCaseStudyLink = () => {
    const { InitializeCaseStudyData } = this.props;

    if (this.props.clientData.id) {
      axiosInstance
        .get(`${LocalApiBaseUrl}CaseStudy/GetClientCaseStudy`, {
          params: {
            OrgEntityID: this.props.clientData.id,
          },
        })
        .then((response) => {
          const responseData = response.data;
          InitializeCaseStudyData(responseData);
          this.setState({
            caseStudylinkData: responseData,
          });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };

  componentDidUpdate(previousProps) {
    ReactTooltip.rebuild();
    const {
      PulseSubOffering,
      PulseofferingId,
      PulseOffering,
      pulseReduxData,
    } = this.props;

    const { pulseData } = pulseReduxData;
    let selectedPulseDataCategory = '';

    if (
      PulseSubOffering !== previousProps.PulseSubOffering
      || PulseofferingId !== previousProps.PulseofferingId
      || PulseOffering !== previousProps.PulseOffering
    ) {
      if (PulseofferingId > 0) {
        this.getCaseStudyLink();
        this.GetAllPulseMORItemData();
        this.fetchLeverDescription();
      }
      this.setState({
        configureStatus: false,
        selectedPulseDataCategory,
      });
    }

    if (previousProps.pulseReduxData.pulseData !== pulseData) {
      if (!_.isEmpty(pulseData)) {
        selectedPulseDataCategory = _.get(pulseData, '0.TileName_En');
      }

      this.setState({
        selectedPulseDataCategory,
      });
    }
  }

  handleConfigure = () => {
    const {
      pulseReduxData,
      clientData,
      languageData,
      savePulseData,
      pulseFilterData,
      onSavePulsePageData,
      onSavePulseMaxSequences,
      history,
    } = this.props;

    this.setState({
      configureStatus: true,
      showLoaderOnPage: true,
    });

    let pulsePageData = [];
    let { offeringId } = pulseReduxData;
    const selectedOffering = pulseReduxData.Offering;
    const selectedSubOffering = pulseReduxData.SubOffering;
    axiosInstance

      .get(`${LocalApiBaseUrl}PulseDashBoard/GetPulseDashboardApplication`, {
        params: {
          OrgEntityID: clientData.id,
          isAdmin: 1,
          LanguageID: languageData.id,
          OfferingID: pulseReduxData.offeringId,
        },
      })
      .then((response) => {
        if (response.data.length) {
          savePulseData({
            offerings: pulseFilterData.offerings,
            data: response.data,
          });
          const pageData = response.data;
          const maxSequences = [];
          pageData.filter((each) => {
            if (
              each.OfferingName === selectedOffering
              && each.Process === selectedSubOffering
            ) {
              pulsePageData = each.tilemodel;
              offeringId = each.OfferingId;
              each.tilemodel.filter((a) => {
                a.SequenceNumber = a.pulseDashBoardApplicaionModels.map(
                  (a, b) => a.SequenceNumber,
                );
                a.SequenceNumber = a.SequenceNumber.length > 0
                  ? Math.max(...a.SequenceNumber)
                  : 0;
                maxSequences.push({
                  SOCategory: a.TileID,
                  maxSequence: a.SequenceNumber,
                });
              });
            }
          });

          const data = {
            SubOffering: selectedSubOffering,
            offeringId,
            Offering: selectedOffering,
            pulseData: pulsePageData,
          };

          this.setState({
            pulsePageAdminData: pulsePageData,
          });
          onSavePulsePageData(data);
          onSavePulseMaxSequences(maxSequences);
        }
        this.setState({ showLoaderOnPage: false });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        this.setState({ showLoaderOnPage: false });
        history.push({
          pathname: '/Dashboard',
          state: { pulseMenu: false },
        });
      });
  };

  handleExitPulsePage = () => {
    const {
      pulseReduxData,
      clientData,
      languageData,
      savePulseData,
      pulseFilterData,

      onSavePulsePageData,
      history,
    } = this.props;

    this.setState({
      configureStatus: false,
      showLoaderOnPage: true,
    });

    let pulsePageData = [];
    let { offeringId } = pulseReduxData;
    const selectedOffering = pulseReduxData.Offering;
    const selectedSubOffering = pulseReduxData.SubOffering;

    axiosInstance

      .get(`${LocalApiBaseUrl}PulseDashBoard/GetPulseDashboardApplication`, {
        params: {
          OrgEntityID: clientData.id,
          isAdmin: 0,
          LanguageID: languageData.id,
          OfferingID: pulseReduxData.offeringId,
        },
      })
      .then((response) => {
        if (response.data.length) {
          savePulseData({
            offerings: pulseFilterData.offerings,
            data: response.data,
          });
          const pageData = response.data;

          pageData.filter((each) => {
            if (
              each.OfferingName === selectedOffering
              && each.Process === selectedSubOffering
            ) {
              pulsePageData = each.tilemodel;
              offeringId = each.OfferingId;
            }
          });
          const data = {
            SubOffering: selectedSubOffering,
            offeringId,
            Offering: selectedOffering,
            pulseData: pulsePageData,
          };

          this.setState({
            pulsePageAdminData: pulsePageData,
          });
          onSavePulsePageData(data);
        }
        this.setState({ showLoaderOnPage: false });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        this.setState({ showLoaderOnPage: false });
        history.push({
          pathname: '/Dashboard',
          state: { pulseMenu: false },
        });
      });
  };

  summaryModalShow = (selectedAsset, URL, OpriaURL, Period, OpenInNewWindow) => {
    if(OpenInNewWindow) {
      openURLInNewWindow(OpriaURL);
      return;
    }
    this.setState({
      summaryStatus: true,
      selectedsummaryViewAsset: selectedAsset,
      selectedsummaryViewAssetURL: URL,
      OpriaURL,
      Period
    });
  };

  summaryModalClose = () => {
    this.setState({ summaryStatus: false });
  };

  handleClickPulseDataCategory = (categoryName) => {
    this.setState({
      selectedPulseDataCategory: categoryName,
    });
    let subModuleID;
    switch (categoryName) {
      case "Diverse Data":
        subModuleID = common.TRACKING_MODULES.PULSE_DIVERSE_DATA;
        break;    
      case "Work Orchestration":
        subModuleID = common.TRACKING_MODULES.PULSE_WORK_ORCHESTRATION;
        break;
      case "Insights & Intelligence":
        subModuleID = common.TRACKING_MODULES.PULSE_INSIGHT_INTELLIGENCE;
        break;
      case "Human + Machine Talent":
        subModuleID = common.TRACKING_MODULES.PULSE_HUMAN_MACHINE;
        break;
    }
    common.userTracker(
      subModuleID,
      false,
      null,
      null,
      common.createParamObject(
        common.PARAM_NAMES.OFFERING,
        this.props.pulseFilterData.data[0].OfferingName
      ),
      common.createParamObject(
        common.PARAM_NAMES.SUB_OFFERING,
        this.props.pulseFilterData.data[0].Process
      )
    );
  };

  checkIfPulsePageAccessAllowed = () => {
    const { roleData } = this.props;

    const foundPermissionObj = _.find(roleData, { FeatureName: 'PulsePage' });

    let hasAccessPermission = false;
    if (!_.isEmpty(foundPermissionObj)) {
      const allowedOperations = foundPermissionObj.Operations;

      // ACCESS WOULD ONLY BE ALLOWED IF THE USER HAS EITHER "VIEW" OR "EDIT" PERMISSION
      if (
        allowedOperations.indexOf('View')
        || allowedOperations.indexOf('Edit')
      ) {
        hasAccessPermission = true;
      }
    }

    return hasAccessPermission;
  };

  disabledAssetsLink = () => {
    this.setState({ enableAssetsLinkStatus: false });
  };

  getUrl = (reqdata) => {
    axiosInstance
      .post(`${LocalApiBaseUrl}Common/GetURL?key=${reqdata}`)
      .then((response) => {
        window.open(response.data, '_blank');
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  render() {
    const {
      pulseReduxData,
      roledata,
      filterDetails,
      history,
      selectedClientId,
      clientData,
      pulseMaxSequences,
      SelectedFilter,
      isorgapiloading,
      pulseFilterData,
    } = this.props;
    const {
      showLoaderOnPage,
      configureStatus,
      summaryStatus,
      selectedsummaryViewAsset,
      selectedsummaryViewAssetURL,
      selectedPulseDataCategory,
      enableAssetsLinkStatus,
      linkURL,
      isIFrame,
      ismorpulseapiloading,
      leverDescription,
      OpriaURL,
      Period
    } = this.state;

    const clientName = _.get(clientData, 'name', '');
    let clientLogo = _.get(clientData, 'imageName', '');
    if (!_.isEmpty(clientLogo)) {
      clientLogo = LocalApiBaseUrlImage + clientLogo;
    } else {
      clientLogo = noClientLogo;
    }

    const { pulseData } = pulseReduxData;

    const pulseDataCategories = _.map(pulseData, (eachPulseData) => ({
      TileName: eachPulseData.TileName,
      TileName_En: eachPulseData.TileName_En,
      TileName_En_lowerCase: _.toLower(eachPulseData.TileName_En),
    }));

    let selectedPulseDataCategoryDetails = {};
    let toolsArr = [];
    let accentureTools = [];
    let clientTools = [];

    // FILTER THE DATA BASED ON THE SELECTED CATEGORY
    if (!_.isEmpty(selectedPulseDataCategory)) {
      selectedPulseDataCategoryDetails = _.find(pulseData, {
        TileName_En: selectedPulseDataCategory,
      });
      toolsArr = _.sortBy(
        _.get(
          selectedPulseDataCategoryDetails,
          'pulseDashBoardApplicaionModels',
          [],
        ),
        ['SequenceNumber'],
      );
    }
    const toolsArrChunked = _.chunk(toolsArr, 2);

    if (!_.isEmpty(toolsArr)) {
      accentureTools = _.filter(toolsArr, { ApplicationCategory: 'Accenture' });
      clientTools = _.filter(toolsArr, { ApplicationCategory: 'Client' });
    }

    // IF THE PULSE PAGE ACCESS IS NOT ALLOWED THEN REDIRECT TO "DASHBOARD"
    const isPulsePageAccessAllowed = this.checkIfPulsePageAccessAllowed();

    if (!isPulsePageAccessAllowed) {
      return <Redirect to="/Dashboard" />;
    }
    let subOfferings = [];
    if (pulseFilterData && pulseFilterData.offerings && SelectedFilter) {
      pulseFilterData.offerings.forEach((each) => {
        if (parseInt(each.OfferingID, 10) === parseInt(SelectedFilter.OfferingId)) {
          subOfferings = each.ChildOfferings;
        }
      });
    }

    return (
      <>
        {showLoaderOnPage && (
          <LoadingOverlay
            fadeSpeed={0}
            spinner={<SynopsLoader />}
            active={showLoaderOnPage}
          />
        )}
        {!configureStatus && (
          <div className="pulse-dashboard push-pulse-filter">
            {SelectedFilter && SelectedFilter.OfferingId && SelectedFilter.ProcessId && subOfferings.length >0  ? createBreadCrumbs(subOfferings, SelectedFilter.OfferingId, SelectedFilter.ProcessId, pulseFilterData.offerings) : ''}
            {SelectedFilter
            && SelectedFilter.OfferingId
            && SelectedFilter.ProcessId
            && !_.isEmpty(pulseData) ? (
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="pulse-header-controls">
                      <div className="pulse-pagename">
                        {i18n.t('SynOps Assets')}
                      </div>

                      <div className="pulse-btns">
                        <ul>
                          {!_.isEmpty(roledata)
                            && roledata.Operations.indexOf('Edit') !== -1 && (
                              <li onClick={() => this.handleConfigure()}>
                                {i18n.t('Configure')}
                              </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    {/* START - PULSE DATA CATEGORIES SECTION */}
                    <div className="pulse-modules">
                      <ul>
                        {_.map(pulseDataCategories, (eachItem) => {
                          const { TileName_En } = eachItem;
                          const titleForDisplay = eachItem.TileName;
                          const { TileName_En_lowerCase } = eachItem;

                          const titleFirstPart = _.trim(
                            titleForDisplay.substring(
                              0,
                              titleForDisplay.lastIndexOf(' ') + 1,
                            ),
                          );
                          const titleLastPart = _.trim(
                            titleForDisplay.substring(
                              titleForDisplay.lastIndexOf(' ') + 1,
                              titleForDisplay.length,
                            ),
                          );

                          return (
                            <>
                            <li
                              key={TileName_En_lowerCase}
                              onClick={() => {
                                this.handleClickPulseDataCategory(TileName_En);
                                setTimeout(() => {
                                  this.handleClickPulseDataCategory(TileName_En);
                                }, 100);
                              }}
                              className={classNames({
                                active:
                                  _.toLower(selectedPulseDataCategory)
                                  === TileName_En_lowerCase,
                              })}
                            >
                              {titleFirstPart}
                              {!_.isEmpty(titleLastPart) && (
                                <>
                                  <br />
                                  {titleLastPart}
                                </>
                              )}

                              <span
                                className={classNames(
                                  {
                                    'human-machine':
                                      TileName_En_lowerCase
                                      === 'human + machine talent',
                                  },
                                  {
                                    'work-orch':
                                      TileName_En_lowerCase
                                      === 'work orchestration',
                                  },
                                  {
                                    'insights-inte':
                                      TileName_En_lowerCase
                                      === 'insights & intelligence',
                                  },
                                  {
                                    'diverse-data':
                                      TileName_En_lowerCase === 'diverse data',
                                  },
                                )}
                              />
                              <span className="lever-description" data-tip data-for={TileName_En_lowerCase}></span>                              
                            </li>
                            {
                              leverDescription.length > 0 &&
                              <div className="lever-description-wrapper">
                              <ReactTooltip place="right" effect="solid" id={TileName_En_lowerCase}> 
                                <span>{leverDescription.find((a) => a.tab === TileName_En).description}</span>
                              </ReactTooltip>
                            </div>
                            }                            
                            </>
                          );
                        })}
                      </ul>
                    </div>
                    {/* END - PULSE DATA CATEGORIES SECTION */}

                    {/* START - SECTION TO DISPLAY DETAILS OF CURRENTLY SELECTED PULSE CATEGORY */}
                    {!showLoaderOnPage && (
                      <div className="pulse-modules-wrapper">
                        <div className="selected-head">
                          <p
                            className={classNames(
                              {
                                'human-grey':
                                  _.toLower(selectedPulseDataCategory)
                                  === 'human + machine talent',
                              },
                              {
                                'worko-grey':
                                  _.toLower(selectedPulseDataCategory)
                                  === 'work orchestration',
                              },
                              { 
                                'insightsi-grey':
                                  _.toLower(selectedPulseDataCategory)
                                  === 'insights & intelligence',
                              },
                              {
                                'diversed-grey':
                                  _.toLower(selectedPulseDataCategory)
                                  === 'diverse data',
                              },
                            )}
                          >
                            {selectedPulseDataCategoryDetails.TileName}
                          </p>

                          <div className="assets-info">
                            {!_.isEmpty(toolsArr) && (
                              <>
                                <p>
                                  <img src={accentureLogo} alt="Accenture" />
                                  {i18n.t('Pulse_Accenture_Assets')}
&nbsp;
                                  <span>{_.size(accentureTools)}</span>
                                </p>
                                <p>
                                  <img src={clientLogo} alt={clientName} />
                                  {i18n.t('Pulse_Client_Assets')}
&nbsp;
                                  <span>{_.size(clientTools)}</span>
                                </p>
                              </>
                            )}
                          </div>
                        </div>

                        <div className="pulse-carousel-container">
                          {!_.isEmpty(toolsArr) ? (
                            <>
                              <OwlCarousel {...carouselOptions}>
                                {_.map(
                                  toolsArrChunked,
                                  (eachChunk, chunkIndex) => {
                                    const tools = eachChunk;
                                    return (
                                      <div className="item" key={chunkIndex}>
                                        {_.map(tools, (eachTool) => {
                                          const { ApplicationMappingID } = eachTool;
                                          const { ApplicationName } = eachTool;
                                          const { ApplicationDescription } = eachTool;
                                          const { ApplicationCategory,ApplicationCategoryID } = eachTool;
                                          const { OrgEntityID } = eachTool;
                                          const { IsAAA } = eachTool;
                                          const { OpriaURL } = eachTool;
                                          let url = !_.isEmpty(eachTool.URL)
                                            && eachTool.URL !== 'NA'
                                            ? eachTool.URL
                                            : '';
                                          if (!_.isEmpty(url)) {
                                            url = url.startsWith('www')
                                              ? `https://${url}`
                                              : url;
                                          }

                                          let moreInfoUrl = !_.isEmpty(eachTool.MoreInfoURL)
                                            && eachTool.MoreInfoURL !== 'NA'
                                            ? eachTool.MoreInfoURL
                                            : '';
                                          if (!_.isEmpty(moreInfoUrl)) {
                                            moreInfoUrl = moreInfoUrl.startsWith(
                                              'www',
                                            )
                                              ? `https://${moreInfoUrl}`
                                              : moreInfoUrl;
                                          }

                                          const icon = eachTool.Icon;
                                          return (
                                            <div
                                              className="item-details"
                                              key={ApplicationMappingID}
                                            >
                                              <div className="top-content">
                                                {icon && icon !== 'NA' ? (
                                                  <img
                                                    src={
                                                      `data:image/png;base64,${
                                                        icon}`
                                                    }
                                                    alt={ApplicationName}
                                                    width="34px"
                                                    height="34px"
                                                  />
                                                ) : (
                                                  <img
                                              src={
                                                defaultIcon
                                              }
                                              alt={"Default Image"}
                                              width="34px"
                                              height="34px"
                                            />
                                                )}
                                                {OrgEntityID !== null && 
                                                <span className="custom-tool top-right">
                                                  {"Custom"}
                                                </span>
                                                }
                                                <p className="item-name mb-0">
                                                  {ApplicationName}
                                                </p>
                                                <p className="item-desc mb-0">
                                                  {ApplicationDescription}
                                                </p>
                                              </div>

                                              <div className="bottom-content">
                                                <span className="d-flex">
                                                  <p className="mb-0">
                                                    {String(ApplicationCategoryID)
                                                      === '1' && (
                                                      <img
                                                        src={accentureLogo}
                                                        alt={
                                                          ApplicationCategory
                                                        }
                                                      />
                                                    )}
                                                    {String(ApplicationCategoryID)
                                                      === '2' && (
                                                      <img
                                                        src={clientLogo}
                                                        alt={clientName}
                                                      />
                                                    )}
                                                  </p>
                                                  {(eachTool.SummeryView || !_.isEmpty(OpriaURL))&& (
                                                    <p
                                                      className="mb-0 pulse-tool-summary"
                                                      data-tip
                                                      data-for="tool-summary-tooltip"
                                                    >
                                                      <img
                                                        src={ToolSummaryLogo}
                                                        alt="Tool-Summary"
                                                      />
                                                    </p>
                                                  )}
                                                  {IsAAA && (
                                                        <p className="mb-0 pulse-tool-summary">
                                                          <img
                                                          src={synopsAAAIcon}
                                                          alt="AAA"
                                                          />
                                                        </p>
                                                      )
                                                    }
                                                </span>

                                                {/* START - MENUS POPUP */}
                                                {(eachTool.SummeryView
                                                  || !_.isEmpty(url)
                                                  || !_.isEmpty(moreInfoUrl)
                                                  || !_.isEmpty(OpriaURL)) && (
                                                  <div className="dropdown dropup">
                                                    <span
                                                      type="button"
                                                      className="dropdown-toggle"
                                                      data-toggle="dropdown"
                                                    />
                                                    <div className="dropdown-menu">
                                                      {(eachTool.SummeryView || !_.isEmpty(OpriaURL)) && (
                                                        <a
                                                          className="dropdown-item"
                                                          href="javascript:void(0)"
                                                          onClick={() => {
                                                            this.summaryModalShow(
                                                              eachTool.ApplicationName,
                                                              eachTool.URL,
                                                              eachTool.OpriaURL,
                                                              eachTool.Period,
                                                              eachTool.OpenInNewWindow
                                                            );
                                                          }}
                                                        >
                                                          <span className="tool-summary-icon" />
                                                          {i18n.t(
                                                            'Pulse_Tool_Summary',
                                                          )}
                                                        </a>
                                                      )}

                                                      {!_.isEmpty(url) && (
                                                        <a
                                                          className="dropdown-item"
                                                          href={url}
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                        >
                                                          <span className="link-icon" />
                                                          {i18n.t('Link')}
                                                        </a>
                                                      )}

                                                      {String(ApplicationCategoryID) === '1'
                                                        && !_.isEmpty(
                                                          moreInfoUrl,
                                                        ) && (
                                                          <a
                                                            className="dropdown-item"
                                                            href={moreInfoUrl}
                                                            target="_blank"
                                                          >
                                                            <span className="summary-icon" />
                                                            {i18n.t(
                                                              'More_Info',
                                                            )}
                                                          </a>
                                                      )}
                                                    </div>
                                                  </div>
                                                )}
                                                {/* END - MENUS POPUP */}
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    );
                                  },
                                )}
                              </OwlCarousel>
                              <ReactTooltip
                                place="bottom"
                                effect="solid"
                                id="tool-summary-tooltip"
                              >
                                <span>
                                  {i18n.t(
                                    'Tool summary is available for this Asset',
                                  )}
                                </span>
                              </ReactTooltip>
                            </>
                          ) : (
                            <div className="no-data-found">
                              <img
                                src={emptyFeatureListIcon}
                                alt={i18n.t('Pulse_No_Assets_Available')}
                              />
                              <p className="nodata-name">
                                {i18n.t('Pulse_No_Assets_Available')}
                              </p>
                              <p className="nodata-info">
                                {i18n.t(
                                  'Pulse_Connect_With_Admin_To_Add_Asset',
                                )}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {/* END - SECTION TO DISPLAY DETAILS OF CURRENTLY SELECTED PULSE CATEGORY */}
                  </div>

                  {/* START - RIGHT SIDE SECTION */}
                  <div className="col-lg-3">
                    {/* {pulseFilterData.data[0]
                    && procurementOfferingList.findIndex(f => f ==  String(
                      pulseFilterData.data[0].OfferingName_En
                    )) > -1 ? (
                      <div className="pulsehub-wrapper mt-3">
                        <div className="synops-hub procurement-section">
                          <div className="hub-btnsection">
                            <h4>{i18n.t('Synops_for_Procurement')}</h4>
                            <span onClick={() => this.getUrl('Radix')}>
                              <a rel="noopener noreferrer">
                                {i18n.t('Visit_Portal')}
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                      ) : (
                        ''
                      )}
                    <div className="pulsehub-wrapper mt-3">
                      <div className="synops-hub">
                        <div className="hub-btnsection">
                          <h4>{i18n.t('SynOps_Hub')}</h4>
                          <span onClick={() => this.getUrl('SynOpsHub')}>
                            <a rel="noopener noreferrer">
                              {i18n.t('Visit_Hub')}
                            </a>
                          </span>
                        </div>
                        <div className="hub-imgsection">
                          <img
                            src={synopsIcon}
                            alt="SynOps HUB"
                            height="87px"
                            width="87px"
                          />
                        </div>
                      </div>
                    </div>*/}
                    {/* Operation Reviews Section Start */}
                    <MORCaseStudy
                      ismorpulseapiloading={ismorpulseapiloading}
                      configureStatus={this.state.configureStatus}
                      clientData={this.props.clientData}
                      userRole={this.props.userRole}
                      history={this.props.historyval}
                      filterDetails={this.props.filterValues}
                      ispulse
                      {...this.props}
                    />
                    {/* CaseStudy Section End */}
                  </div>
                  {/* END - RIGHT SIDE SECTION */}
                </div>
              </div>
              ) : SelectedFilter
              && SelectedFilter.OfferingId
              && SelectedFilter.ProcessId
              && _.isEmpty(pulseData) ? (
                <div />
                ) : (
                  !isorgapiloading && (
                  <div className="select-filter-msg">
                    <img
                      src={noFilter}
                      alt={i18n.t('Pulse_No_Offering_Suboffering_Selected')}
                    />
                    <p className="mb-0">
                      {i18n.t('Pulse_No_Offering_Suboffering_Selected')}
                    </p>
                  </div>
                  )
                )}
          </div>
        )}

        {configureStatus && (
          <LandingPageAdmin
            configureStatus={this.state.configureStatus}
            filterDetails={filterDetails}
            roledata={roledata}
            pulseData={pulseData}
            historyval={history}
            selectedClientId={selectedClientId}
            handleExit={this.handleExitPulsePage}
            maxSequences={pulseMaxSequences}
            showLoaderOnPage={showLoaderOnPage}
            leverDescription={leverDescription}
            {...this.props}
          />
        )}

        {summaryStatus && (
          <AssetsSummaryView
            selectedsummaryViewAsset={selectedsummaryViewAsset}
            selectedsummaryViewAssetURL={selectedsummaryViewAssetURL}
            OpriaURL={OpriaURL}
            Period={Period}
            summaryStatus={summaryStatus}
            filterDetails={filterDetails}
            summaryModalClose={() => this.summaryModalClose()}
          />
        )}
        {enableAssetsLinkStatus && (
          <AssetsLinkURLModal
            enableAssetsLinkStatus={enableAssetsLinkStatus}
            disabledAssetsLink={this.disabledAssetsLink}
            linkURL={linkURL}
            isIFrame={isIFrame}
          />
        )}
        {/* MOR Modal Popup Start */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  pulseReduxData: state.CurrentFilter.pulsePageData,
  roleData: state.roleReducers.roleReducers,
  languageData: state.CurrentFilter.languageData,
  clientData: state.CurrentFilter.ClientData,
  pulseFilterData: state.CurrentFilter.PulseData,
  pulseMaxSequences: state.CurrentFilter.pulseMaxSequences,
  SelectedFilter: { ...state.CurrentFilter.SyncFilter },
});

const mapDispatchToProps = (dispatch) => ({
  onSavePulsePageData: (data) => {
    dispatch({ type: 'PULSE_PAGEDATA', payload: data });
  },
  savePulseData: (data) => {
    dispatch({ type: 'PULSE_DATA', payload: data });
  },
  onSavePulseMaxSequences: (data) => {
    dispatch({ type: 'PULSE_MAX_SEQUENCE', payload: data });
  },
  InitializeCaseStudyData: (data) => {
    dispatch({ type: 'PULSE_CASESTUDY_DATA_INITIALIZE', payload: data });
  },
  InitializeMORData: (data, datalevel, isCategoryData) => {
    dispatch({
      type: 'PULSE_MOR_DATA_INITIALIZE',
      payload: {
        MorItemData: data,
        ItemDataLevel: datalevel,
        isCategoryData,
      },
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LandingPage);
