import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import BOMetricDetails from './BOMetricDetails';
import { getPrevMonth } from '../Shared/Constant';
import { store } from '../reducers/configureStore';
import axiosInstance from '../Shared/interceptor';
import emptycomponent from '../Images/Empty State.svg';
import References from '../Shared/References';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('msal.idtoken')}`;

export default class BOContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { boData: [] };
  }

  componentDidMount() {
    const { filterDetails } = this.props;
    this.getBOApiData(filterDetails);

    store.subscribe(() => {
      const data = store.getState();
      this.setState({ boData: data.BOReducers.BO });
    });
  }

  getBOApiData(offeringIdparam = {
    Client: 0, process: 0, Locations: 0, Month: getPrevMonth(),
  }) {
    let dateArray = '';
    if (String(offeringIdparam.Month) === String(0)) {
      dateArray = [];
    } else if ((String(offeringIdparam.Month) === String(undefined))) {
      dateArray = [];
    } else {
      dateArray = offeringIdparam.Month.split('-');
    }
    let todayDate = '';
    if (String(dateArray.length) === String(2)) {
      todayDate = `1-${dateArray[0]}-${dateArray[1]}`;
    } else {
      // eslint-disable-next-line no-unused-vars
      todayDate = offeringIdparam.Month;
    }

    if (store.getState().BOReducers.BO.length === 0 && store.getState().humanReducers.humanandmachine.length === 0 && store.getState().intelligentInsightReducers.insightandintelligence.length === 0 && store.getState().diverseReducers.diverse.length === 0 && store.getState().WOtileReducers.tile.length === 0 && store.getState().intelligentReducers.IOJ.length === 0) { // empty

    } else {
      const data = store.getState();
      this.setState({ boData: data.BOReducers.BO });
    }
  }

  render() {
    const { boData } = this.state;
    const BOData = [...boData];
    let count = 0;
    const TabDatalength = BOData.length;
    let i;
    for (i = 0; i < TabDatalength; i += 1) {
      if (BOData[i].kpiData.length === 0 || String(BOData[i].kpiData) === String(undefined)) {
        count += 1;
      }
    }
    const {
      RecordStatus, attributes, filterDetails, roleReducers, isManintencneWindowOpen, isRelatorBlock,
    } = this.props;
    return (

      <div className="row">
        <div className="col-lg-12 configurator-bo">
          <div className="category-references dashboard-category-references bo-config-references">
            <References
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...this.props}
              SOCatID={1}
              leverName="Business Outcomes"
              isConfig
              referenceScreen="BO"
            />
          </div>

          {BOData.map((SOData) => (
            <BOMetricDetails
              RecordStatus={RecordStatus}
              isManintencneWindowOpen={isManintencneWindowOpen}
              isRelatorBlock={isRelatorBlock}
              attributes={attributes}
              SODetails={SOData}
              filterDetails={filterDetails}
              roleReducers={roleReducers}
            />
          ))}

          {BOData.length === 0 ? (
            <div className="emptymetric-msg">
              <img alt="" src={emptycomponent} width="80px" height="80px" />
              <h6>{i18n.t('No category available')}</h6>
            </div>
          ) : null}

          {BOData.length > 0 && String(TabDatalength) === String(count)
            ? (
              <div className="emptymetric-msg">
                <img alt="" src={emptycomponent} width="80px" height="80px" />
                <h6>{i18n.t("Please click on '+' to add metrics")}</h6>
              </div>
            ) : null}

        </div>
      </div>
    );
  }
}
BOContainer.propTypes = {
  filterDetails: PropTypes.object.isRequired,
  RecordStatus: PropTypes.array.isRequired,
  roleReducers: PropTypes.array.isRequired,
  attributes: PropTypes.array.isRequired,
  isManintencneWindowOpen: PropTypes.bool.isRequired,
  isRelatorBlock: PropTypes.bool.isRequired,
};