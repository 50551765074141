import React, { Component } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import ContentHeader from './ContentHeader';
import BOContainer from './BO';
import HumanMachineTalent from './HumanMachine';
import WorkOrchestration from '../WorkOrchestration/WorkOrchestration_Common';
import DiverseDataContainer from './DiverseDataContainer';
import InsightsIntelligence from './InsightsIntelligence';
import { InitializeDatas } from '../actions/JourneyActions';
import SynopsLoader from '../Shared/SynopsLoader';
import axiosInstance from '../Shared/interceptor';
import { LocalApiBaseUrl } from '../Shared/Constant';
import { store } from '../reducers/configureStore';
import RejectPopUp from './RejectPopUp';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(
  'msal.idtoken',
)}`;

class DashboardCntContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: 'Configurator',
      params: {
        Status: null,
        MonthDt: null,
        DealID: null,
        activex: true,
      },
      active: false,
      apiLoadCompleted: false,
      filtername: null,
      IsAdmin: true,
      remarkStatus: false,
      dataStatus: '',
      getConfiguratorapicall: false,
      isManintencneWindowOpen: false,
      filter: {
        OrgEntityID: 0, OfferingID: 0, LocationID: 0, MonthDT: null,
      },
      postData: [],
      roleData: [],
      featureData: [],
      saveEnable: false,
      submitEnable: false,
      publishEnable: false,
      implementationTeamButtonsEnable: false,
      publishStatus: false,
      saveStatus: false,
      submitStatus: false,
      reviewImplementationStatus: false,
      rejectedByImplementationTeam: false,
      viewmore: false,
      kpiData: [],
      SoCatDataAttr: [],
      recordStatus: '',
      CountInsertData: [],
      CountData: [],
      tool: [],
      headerLabel: '',
      isApiSuccess: false,
      humanWorkforceData: 0,
      keyInsightsData: 0,
      machineWorkforceData: 0,
    };
    this.handlePost.bind(this);
    this.onClose = this.onClose.bind(this);
    this.getRecordStatus = this.getRecordStatus.bind(this);
    this.GetUserDetails = this.GetUserDetails.bind(this);
    this.callGetConfigurator = this.callGetConfigurator.bind(this);
  }

  SaveClick() {
    this.handlePost('Save');
  }

  SubmitClick = async () => {
    if (!this.props.isNewDeal || this.state.dataStatus === 'In Review')
    // if isNew false || data has already been submitted to MD before, keep existing implementation
    { this.handlePost('Submit'); }
    // else if isNewfalse and maintenancewindow is open, status has to go to draft
    else if (!this.props.isNewDeal && this.state.isManintencneWindowOpen) {
      this.handlePost('Submit');
    } else {
      this.handlePost('Review(Implementation)');
    }
  };

  PublishClick() {
    this.handlePost('Publish');
  }

  approveImplementationClick() {
    this.handlePost('Submit', 'approved by implementation team');
  }

  RejectImplementationClick() {
    this.setState({ rejectedByImplementationTeam: true });
    this.setState({ viewmore: true });
  }

  ResetClick() {
    this.handleReset();
  }

  LastMonthClick() {
    this.handleLastMonth();
  }

  getUom() {
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetAllUnits`, {
        params: { languageId: store.getState().CurrentFilter.languageData.id },
      })
      .then((response) => {
        store.dispatch({
          type: 'SAVE_STANDARD_UNITS',
          payload: response.data,
        });
      })
      .catch((error) => {
        window.alert(error.response.data.Message);
        trycatchAlertPopup(error);
      });
  }

  handleLastMonth() {
    const { filter } = this.state;
    filter.LocationID = this.props.filterDetails.Locations;
    filter.OrgEntityID = this.props.filterDetails.Client;
    filter.OfferingID = this.props.filterDetails.process;
    filter.MonthDT = this.props.filterDetails.Month;
    this.setState({
      active: true,
    });

    axiosInstance
      .get(`${LocalApiBaseUrl}Configurator/GetLastMonthData`, {
        params: {
          OrgEntityID: filter.OrgEntityID,
          LocationID: filter.LocationID,
          MonthDT: filter.MonthDT,
          OfferingID: filter.OfferingID,
        },
      })
      .then((response) => {
        if (response.status == '200') {
          this.setState({ active: false });
        }
      })
      .catch((error) => {
        window.alert(error.response.data.Message);
        trycatchAlertPopup(error);
      });
  }

  handleReset = () => {
    const { filter } = this.state;
    filter.LocationID = this.props.filterDetails.Locations;
    filter.OrgEntityID = this.props.filterDetails.Client;
    filter.OfferingID = this.props.filterDetails.process;
    filter.MonthDT = this.props.filterDetails.Month;
    this.setState({
      active: true,
    });
    store.dispatch(InitializeDatas({ isGetData: false }));

    if (filter.OrgEntityID && filter.LocationID && filter.MonthDT && filter.OfferingID) {
      axiosInstance
        .get(`${LocalApiBaseUrl}Configurator/GetConfiguratorData`, {
          params: {
            OrgEntityID: filter.OrgEntityID,
            LocationID: filter.LocationID,
            MonthDT: filter.MonthDT,
            OfferingID: filter.OfferingID,
          },
        })
        .then((response) => {
          store.dispatch({
            type: 'INITIALISE_BO',
            payload: response.data[0].TabData,
          });
          store.dispatch({
            type: 'INITIALISE',
            payload: response.data[1].TabData,
          });
          store.dispatch({
            type: 'INITIALISEINSIGHT',
            payload: response.data[3].TabData,
          });
          store.dispatch({
            type: 'INITIALISEDIVERSE',
            payload: response.data[4].TabData,
          });
          store.dispatch({
            type: 'INITIALISEIOJ',
            payload: response.data[5].IntelTabData == null ? [] : response.data[5].IntelTabData,
          });
          store.dispatch({
            type: 'INITIALISEWO',
            payload: response.data[2].TabData,
          });
          store.dispatch({
            type: 'LastPublishedDate',
            payload: response.data[0].LastPublishedDate,
          });
          store.dispatch({
            type: 'INITIALISEMaturity',
            payload: response.data[6].MaturityPlanTabData,
          });
          if (response.status == '200') {
            this.setState({
              active: false,
              apiLoadCompletedIndashboard: true,
            });
            store.dispatch({
              type: 'APILOADCOMPLETEDINDASHBOARDCONTAINER',
              payload: this.state.apiLoadCompletedIndashboard,
            });
          }
        })
        .catch((error) => {
          window.alert(error.response.data.Message);
          trycatchAlertPopup(error);
        });
    }
  };

  GetUserDetails() {
    const EnterpriseID = sessionStorage.getItem('EnterpriseID');
    if (EnterpriseID != undefined) {
      axiosInstance
        .get(`${LocalApiBaseUrl}User/GetUserDetails `, {
          params: {},
        })

        .then((response) => {
          if (response.data[0] === 'True') {
            this.setState({ IsAdmin: true });
          }
          if (response.data[0] === 'False') {
            this.setState({ IsAdmin: false });
          }
        })
        .catch((error) => {
          window.alert(error.response.data.Message);
          trycatchAlertPopup(error);
        });
    }
  }

  checkInActivewoTooldata = () => {
    const { postData } = this.state;
    const woData = postData[4];

    const ToolsData = woData.TabData.filter(
      (each) => each.ParentSOCategoryID !== 3 && each.WOStepTools.some((a) => a.IsActive === false),
    );
    if (ToolsData.length > 0) {
      return true;
    }
    return false;
  };

  handlePost = async (status, IsApprovedByImplementationTeamText = null) => {
    const { params } = this.state;
    params.Status = status;
    params.MonthDt = this.props.filterDetails.Month;
    params.DealID = this.props.filterDetails.Client;
    const { filter } = this.state;
    params.MonthDt = this.props.filterDetails.Month;
    filter.LocationID = this.props.filterDetails.Locations;
    filter.OrgEntityID = this.props.filterDetails.Client;
    filter.OfferingID = this.props.filterDetails.process;
    filter.MonthDT = this.props.filterDetails.Month;
    this.setState({
      params,
      active: true,
    });

    this.CreateSaveAndSubmitData();
    this.state.CountData = [];
    this.state.CountInsertData = [];
    const fetchFromBAT = store.getState().CurrentFilter.FetchFromBAT;

    if (status != 'Save') {
      if (this.isBODataHasValue()) {
        if (this.state.postData[0].TabData.length <= 0) {
          this.handleAlertCategory('Business Outcome');
        } else {
          this.handleAlertData('Business Outcome');
        }
      } else if (this.isHMTHasValue()) {
        if (this.state.postData[1].TabData.length <= 0) {
          this.handleAlertCategory('Human & Machine Talent');
        } else {
          this.handleAlertData('Human & Machine Talent');
        }
      } else if (this.isWOHasData()) {
        if (this.state.postData[4].TabData.filter((f) => f.SOCategoryID != 3).length <= 0) {
          this.handleAlertCategory('Work Orchestration', 'dashboardCntContainerErrorM6');
        } else {
          this.handleAlertData('Work Orchestration');
        }
      } else if (this.isWOHasSubStepData()) {
        this.handleAlertData('Work Orchestration Sub-Step');
      } else if (this.state.params.activex == false) {

      } else if (this.isIIHasValue()) {
        if (this.state.postData[2].TabData.length <= 0) {
          this.handleAlertCategory('Insights & Intelligencen');
        } else {
          this.handleAlertData('Insights & Intelligence');
        }
      } else if (this.isDDHasData() && !fetchFromBAT) {
        if (this.state.postData[3].TabData[1].kpiData.length <= 0) {
          this.handleAlertCategory('Diverse Data');
        } else {
          this.handleAlertData('Diverse Data');
        }
      } else if (this.validateDDConfWidgetData()) {
        if (this.state.postData[3].TabData.length <= 0) {
          this.handleAlertCategory('Diverse Data');
        } else {
          this.handleAlertData('Diverse Data');
        }
      } else if (this.isMaturityHasValue()) {
      } else if (this.checkInActivewoTooldata()) {
        this.handleAlertCategory('WOTools Data');
      } else {
        let tilename = '';
        if (this.state.postData.length == 7) {
          if (this.state.params.activex == true) {
            if (this.state.CountInsertData.length > 0) {
              for (let j = 0; j < this.state.CountInsertData.length; j++) {
                const tname = this.state.CountInsertData[j].name;
                const tcount = this.state.CountInsertData[j].count;
                tilename = `${tilename + tname}-${tcount}\n`;
              }
            }
            let hybridNumeratorValue = 0;
            let hybridNumerator = 0;
            let show = false;
            let hybridDenominator = 0;
            const data = store.getState();
            await data.humanReducers.humanandmachine.map((item) => {
              item.kpiData.map((i) => {
                if (i.MetricName == 'Total Resource' && i.MetricUnit == 'Number') {
                  hybridNumeratorValue = i.MetricValue;
                }
              });
            });
            await axiosInstance
              .get(`${LocalApiBaseUrl}SOFilter/GetHybridWorkDataRatio`, {
                params: {
                  OrgEntityID: parseInt(this.props.filterDetails.Client),
                  OfferingID: parseInt(this.props.filterDetails.process),
                  LocationID: parseInt(this.props.filterDetails.Locations),
                  PeriodDT: this.props.filterDetails.Month,
                },
              })
              .then((response) => {
                if (response.data.length > 0) {
                  show = response.data[0].IsShownonFD;
                  hybridDenominator = parseInt(response.data[0].MetricValue);
                  hybridNumerator = parseInt(response.data[0].NumeratorValue);
                }
              })
              .catch((error) => {
                window.alert(error.response.data.Message);
                trycatchAlertPopup(error);
              });

            let nameTab = '';
            if (this.state.humanWorkforceData === 0) nameTab = 'Human Workforce section';
            if (this.state.keyInsightsData === 0) { nameTab = nameTab == '' ? 'Key Insights section' : `${nameTab}, ` + 'Key Insights sections'; }
            if (this.state.machineWorkforceData === 0) {
              nameTab = nameTab == ''
                ? 'Machine Workforce section'
                : `${nameTab}, ` + 'Machine Workforce sections';
            }
            let isShowConfirm = true;
            let hmwarningMsg = '';
            let showWarning = false;
            if (
              this.state.humanWorkforceData == 0
              || this.state.keyInsightsData == 0
              || this.state.machineWorkforceData == 0
            ) {
              hmwarningMsg = `- There is no metric in ${nameTab}. Please confirm if you want to proceed`;
              showWarning = true;
            }
            if (hybridDenominator != 0 && hybridNumeratorValue == 0 && show === true) {
              hmwarningMsg = hmwarningMsg == '' ? '' : `${hmwarningMsg}\n`;
              hmwarningMsg
                += '- Total Resource’ metric under ‘H+M Talent - Key Insights’ section. Absence of this metric will impact Hybrid Ratio computation';
              showWarning = true;
            }
            if (showWarning) {
              const a = window.confirm(`Warning:\n${hmwarningMsg}`);
              if (a === false) {
                isShowConfirm = false;
                this.setState({
                  active: false,
                });
              }
            }

            if (isShowConfirm) {
              if (window.confirm(`Metrics Added:\n${tilename}\n Do you want to proceed?`)) {
                this.state.CountInsertData = [];
                this.handleData(filter, status, IsApprovedByImplementationTeamText);
              } else {
                this.setState({
                  active: false,
                });

                this.state.CountInsertData = [];
              }
            }
          } else {
            this.setState({
              active: false,
            });
          }
        }
      }
    } else {
      this.handleData(filter, status, IsApprovedByImplementationTeamText);
    }
  };

  // Function for WO Data
  isWOHasData() {
    this.state.params.activex = true;
    let bocount1 = 0;
    const bname = '';
    const bname1 = '';
    const insertParamCount = {
      name: '3. Work Orchestration',
      count: 0,
    };
    var dataParamCount = {
      tileName: null,
    };

    if (this.state.postData[4].TabData.length > 0) {
      if (
        this.state.postData[4].TabName === 'Work Orchestration'
        && this.state.postData[4].TabData.length > 0
      ) {
        const parentCat = this.state.postData[4].TabData.filter(parent => parent.ParentSOCategoryID === 3)
        let childList = [];
        parentCat.forEach(each => {
          childList = this.state.postData[4].TabData.filter(parent => parent.ParentSOCategoryID === each.SOCategoryID)
          if(childList.filter(child=> child.kpiData.length > 0 ).length == 0 && each.IsActive){
            var dataParamCount = {
              tileName: null,
            };

            dataParamCount.tileName = each.SOCategoryName;
            this.state.CountData.push(dataParamCount);
          }
        })
        for (let i = 0; i < this.state.postData[4].TabData.length; i++) {
          if (
            this.state.postData[4].TabData[i].ParentSOCategoryID != 0
            && this.state.postData[4].TabData[i].ParentSOCategoryID != 3
            && this.state.postData[4].TabData[i].ParentSOCategoryID != null
            && this.state.postData[4].TabData[i].ParentSOCategoryID != undefined
          ) {
            //if (
            //  this.state.postData[4].TabData[i].IsActive
            //  && this.state.postData[4].TabData[i].kpiData.length == 0
            //) {
            //  var dataParamCount = {
            //    tileName: null,
            //  };

            //  dataParamCount.tileName = this.state.postData[4].TabData[i].SOCategoryName;
            //  this.state.CountData.push(dataParamCount);
            //} else 
            if (
              this.state.postData[4].TabData[i].IsActive
              && this.state.postData[4].TabData[i].kpiData.length > 0
            ) {
              const bocount = this.state.postData[4].TabData[i].kpiData.length;
              bocount1 += bocount;
            }
          }
        }
        if (bocount1 > 0) {
          insertParamCount.count = bocount1;
          this.state.CountInsertData.push(insertParamCount);
          if (this.state.filtername != 'Finance and Accounting') {
            return false;
          }
        }

        if (this.state.CountData.length > 0) {
          if (String(this.state.filtername) === 'Finance and Accounting') {
            this.handleWOTitleAlertData(this.state.CountData);
            this.state.CountData = [];
          }
          if (this.state.CountData.length > 0 && bocount1 <= 0) {
            return true;
          }
        } else if (this.state.CountData.length == 0 && bocount1 == 0) {
          return true;
        }

        return false;
      }
      return true;
    }
    return true;
  }

  isWOHasSubStepData() {
    let count = 0;
    let stepWithNoSubStep = 0;
    if (this.state.postData[4].TabData.length > 0) {
      if (
        this.state.postData[4].TabName === 'Work Orchestration'
        && this.state.postData[4].TabData.length > 0
      ) {
        const WOData = this.state.postData[4].TabData.filter((each) => each.ParentSOCategoryID !== 0);
        const WOStepData = this.state.postData[4].TabData.filter(
          (each) => each.ParentSOCategoryID === 3 && each.IsActive,
        );
        const WOSubStepData = this.state.postData[4].TabData.filter(
          (each) => each.ParentSOCategoryID !== 3 && each.ParentSOCategoryID !== 0,
        );
        for (let i = 0; i < WOStepData.length; i++) {
          for (let j = 0; j < WOSubStepData.length; j++) {
            if (WOStepData[i].SOCategoryID === WOSubStepData[j].ParentSOCategoryID) {
              count += 1;
            }
          }
          if (count == 0) {
            stepWithNoSubStep += 1;
          }
          count = 0;
        }
        if (stepWithNoSubStep > 0) {
          return true;
        }
        return false;
      }
    }
  }

  isMaturityHasValue() {
    let Macount = 0;
    const insertParamCount = {
      name: '6. Maturity Plan ',
      count: 0,
    };
    if (
      this.state.postData[6].TabName === 'MaturityPlanData'
      && this.state.postData[6].MaturityPlanTabData.length >= 0
    ) {
      Macount = this.state.postData[6].MaturityPlanTabData
        ? this.state.postData[6].MaturityPlanTabData.filter((a) => a.IsChecked === true).length
        : 0;

      insertParamCount.count = Macount;
      this.state.CountInsertData.push(insertParamCount);
      return false;
    }
    return true;
  }

  // Function for IOJ Data
  isIOJHasValue() {
    const insertParamCount = {
      name: '6. Intelligent Operation Journey ',
      count: 0,
    };
    const { IOJData } = this.props;
    if (IOJData.length > 0) {
      let hasInitiativesData = false;
      let InitiativesCount = 0;
      IOJData.forEach((each) => {
        if (each.IOJInitiativeModels.length) {
          hasInitiativesData = true;
          InitiativesCount += each.IOJInitiativeModels.length;
        }
      });
      if (hasInitiativesData) {
        insertParamCount.count = InitiativesCount;
        this.state.CountInsertData.push(insertParamCount);
        return false;
      }
    }
    return true;
  }

  // Function for DD Data
  isDDHasData() {
    // GETTING THE  fetchFromBAT STATUS FOR CHECKING THE REMOVING THE VALIDATION FOR SUBMIT DATA IN DD LEVER IF IT IS SAVED AS BAT//
    const { fetchFromBAT } = this.props;
    let bocount1 = 0;
    const insertParamCount = {
      name: '5. Diverse Data ',
      count: 0,
    };

    if (this.state.postData[3].TabData.length > 0) {
      if (
        this.state.postData[3].TabName === 'Diverse Data'
        && this.state.postData[3].TabData.length > 0
      ) {
        for (let i = 0; i < this.state.postData[3].TabData.length; i++) {
          if (this.state.postData[3].TabData[i].kpiData.length > 0) {
            const bocount = this.state.postData[3].TabData[i].kpiData.length;
            bocount1 += bocount;
          }
        }
        if (bocount1 > 0 && !fetchFromBAT) {
          insertParamCount.count = !fetchFromBAT ? bocount1 : 0;
          this.state.CountInsertData.push(insertParamCount);
          return false;
        } if (bocount1 >= 0 && fetchFromBAT) {
          insertParamCount.count = !fetchFromBAT ? bocount1 : 0;
          this.state.CountInsertData.push(insertParamCount);
          return false;
        }
      }
      return true;
    }
    return true;
  }

  isDDHasDataProcessed() {
    const soCatAtt = this.state.postData[3].TabData[0].SoCatDataAttr.filter(
      (obj) => obj.AttributeValue == 'Yes',
    );
    if (soCatAtt.length > 0) {
      return false;
    }
    this.setState({
      active: false,
    });
    alert(i18n.t('dashboardCntContainerErrorM1'));
    return true;
  }

  // Function for II Data
  isIIHasValue() {
    let bocount1 = 0;
    const insertParamCount = {
      name: '4. Insight and Intelligence ',
      count: 0,
    };
    if (this.state.postData[2].TabData.length > 0) {
      if (
        this.state.postData[2].TabName === 'Insights & Intelligence'
        && this.state.postData[2].TabData.length > 0
      ) {
        for (let i = 0; i < this.state.postData[2].TabData.length; i++) {
          if (this.state.postData[2].TabData[i].kpiData.length > 0) {
            const bocount = this.state.postData[2].TabData[i].kpiData.length;
            bocount1 += bocount;
          }
        }
        if (bocount1 > 0) {
          insertParamCount.count = bocount1;
          this.state.CountInsertData.push(insertParamCount);
          return false;
        }
      }
      return true;
    }
    return true;
  }

  /** Start- Validating if a widget has any record or not */
  validateDDConfWidgetData() {
    let count = 0;
    if (this.state.postData[3].TabData.length > 0) {
      if (
        this.state.postData[3].TabName === 'Diverse Data'
        && this.state.postData[3].TabData.length > 0
      ) {
        if (this.state.postData[3].TabData[0].SOCategoryID === 5) {
          const wData = this.state.postData[3].TabData[0].Widget;
          if (wData && wData.length > 0) {
            for (let i = 0; i < wData.length; i++) {
              if (wData[i].WidgeFields.length > 0) {
                count = wData[i].WidgeFields.length + count;
              }
            }
            if (count > 0) {
              return false;
            }
          } else {
            return false;
          }
        }
      }
      return true;
    }
    return true;
  }
  /** End - Validating if a widget has any record or not */

  // Function for HMT Data
  isHMTHasValue() {
    const humanWorforceKpiData = [];
    const machineWorkforceKpiData = [];
    const keyInsightsKpiData = [];
    this.state.postData[1].TabData.map((Catagory) => {
      if (String(Catagory.SOCategoryName) === 'Human Workforce') {
        humanWorforceKpiData.push(Catagory.kpiData);
      } else if (String(Catagory.SOCategoryName) === 'Key Insights') {
        keyInsightsKpiData.push(Catagory.kpiData);
      }
      if (String(Catagory.SOCategoryName) === 'Machine Workforce') {
        machineWorkforceKpiData.push(Catagory.kpiData);
      }
    });

    if (this.state.postData[1].TabData.length > 0) {
      if (
        this.state.postData[1].TabName === 'Human & Machine Talent'
        && this.state.postData[1].TabData.length > 0
      ) {
        if (
          humanWorforceKpiData[0].length == 0
          && keyInsightsKpiData[0].length == 0
          && machineWorkforceKpiData[0].length == 0
        ) {
          return true;
        }
        this.setState({
          humanWorkforceData: humanWorforceKpiData[0].length,
          keyInsightsData: keyInsightsKpiData[0].length,
          machineWorkforceData: machineWorkforceKpiData[0].length,
        });
        const insertParamCount = {
          name: '2. Human and Machine ',
          count: 0,
        };

        insertParamCount.count = (this.state.postData[1].TabData.length > 0 ? humanWorforceKpiData[0].length : 0)
            + (this.state.postData[1].TabData.length > 1 ? keyInsightsKpiData[0].length : 0)
            + (this.state.postData[1].TabData.length > 2 ? machineWorkforceKpiData[0].length : 0);
        this.state.CountInsertData.push(insertParamCount);

        return false;
      }
    } else {
      return true;
    }
  }
  // Function for BO Data

  isBODataHasValue() {
    this.state.CountData = [];
    this.state.CountInsertData = [];

    let bocount1 = 0;
    if (this.state.postData[0].TabData.length > 0) {
      if (
        this.state.postData[0].TabName === 'Business Outcome'
        && this.state.postData[0].TabData.length > 0
      ) {
        for (let i = 0; i < this.state.postData[0].TabData.length; i++) {
          if (this.state.postData[0].TabData[i].kpiData.length > 0) {
            var insertParamCount = {
              name: '1. Business Outcome ',
              count: 0,
            };

            const bocount = this.state.postData[0].TabData[i].kpiData.length;

            bocount1 += bocount;
          }
        }
        if (bocount1 > 0) {
          insertParamCount.count = bocount1;
          this.state.CountInsertData.push(insertParamCount);
          return false;
        }
      }
      return true;
    }
    return true;
  }

  handleAlertData(name) {
    this.setState({
      active: false,
    });

    this.state.CountData = [];
    this.state.CountInsertData = [];

    if (String(name) === 'Diverse Data') {
      return alert(i18n.t('dashboardCntContainerErrorM7'));
    } if (String(name) === 'Work Orchestration Sub-Step') {
      return alert(
        i18n.t('At least one sub step should be added under each WO step to publish the deal.'),
      );
    }
    return alert(i18n.t('dashboardCntContainerErrorM2') + name);
  }

  handleAlertCategory(name, alertMsg) {
    let alertToolnames = '';
    if (name === 'Diverse Data') {
      alertMsg = alertMsg == undefined ? 'dashboardCntContainerErrorM2' : alertMsg;
    } else if (name === 'WOTools Data') {
      const { postData } = this.state;
      const { TabData } = postData[4];
      const ToolsData = TabData.filter(
        (each) => each.ParentSOCategoryID !== 3 && each.WOStepTools.some((a) => a.IsActive === false),
      );
      if (ToolsData.length > 0) {
        const toolLiteral = i18n.t('Tool_Cant_Submit');
        const toolsLiteral = i18n.t('Tools_Cant_Submit');
        const stepLiteral = i18n.t('Step');
        const substepLiteral = i18n.t('Sub_Step');
        let count = 0;
        ToolsData.forEach((a, index) => {
          const { SOCategoryName, WOStepTools } = a; // ToolsData[j];
          const ParentSOCategoryName = TabData.filter(
            (b) => b.SOCategoryID === a.ParentSOCategoryID,
          )[0].SOCategoryName;
          // let tcount = this.state.CountInsertData[j].count;
          alertToolnames = `${alertToolnames} ${stepLiteral}: ${ParentSOCategoryName} - ${substepLiteral}: ${SOCategoryName}\n`;

          WOStepTools.filter((a) => a.IsActive === false).forEach((b, index) => {
            alertToolnames = `${alertToolnames} --- ${b.ApplicationName} \n`;
            count += 1;
          });
        });
        alertToolnames = ` ${count > 1 ? toolsLiteral : toolLiteral}\n${alertToolnames}`;
      }
    } else {
      alertMsg = alertMsg == undefined ? 'dashboardCntContainerErrorM3' : alertMsg;
    }

    this.setState({
      active: false,
    });
    if (name !== 'WOTools Data') return alert(i18n.t(alertMsg) + name);
    return alert(alertToolnames);
  }

  handleIOJAlertData(name) {
    this.setState({
      active: false,
    });
    this.state.CountData = [];
    this.state.CountInsertData = [];
    return alert(i18n.t('dashboardCntContainerErrorM4'));
    // }
  }

  handleWOTitleAlertData(name) {
    this.setState({
      active: false,
    });
    this.state.params.activex = false;
    let tilename = '';
    for (let j = 0; j < name.length; j++) {
      const tname = name[j].tileName;
      tilename = `${tilename + tname}\n`;
      var fName = tilename;
    }
    this.state.CountData = [];
    this.state.CountInsertData = [];
    return alert(i18n.t('dashboardCntContainerErrorM5') + fName);
  }

  handleData(filter, status, IsApprovedByImplementationTeamText) {
    const data = {
      ConfiguratorDataModels: this.state.postData,
      ConfiguratorRequestParamsModel: this.state.params,
      FiltersIDsVM: filter,
    };

    axiosInstance
      .post(`${LocalApiBaseUrl}Configurator/PostConfiguratorData`, data)
      .then((response) => {
        if (response.data.IsSuccess) {
          switch (status) {
            case 'Save':
              alert(i18n.t('dashboardCntContainerSucM1'));
              this.setState({
                saveStatus: true,
                submitStatus: false,
                publishStatus: false,
                reviewImplementationStatus: false,
              });
              this.handleReset();
              this.getRecordStatus();
              break;

            case 'Review(Implementation)':
              alert(i18n.t('dashboardCntContainerSucM2'));
              this.setState({
                saveStatus: false,
                publishStatus: false,
                submitStatus: false,
                reviewImplementationStatus: true,
              });
              this.state.CountInsertData = [];
              this.handleReset();
              this.getRecordStatus();
              break;

            case 'Submit':
              if (IsApprovedByImplementationTeamText) {
                alert(i18n.t('dashboardCntContainerSucM3'));
              } else {
                alert(i18n.t('dashboardCntContainerSucM4'));
              }
              this.setState({
                saveStatus: false,
                publishStatus: false,
                submitStatus: true,
                reviewImplementationStatus: false,
              });
              this.state.CountInsertData = [];
              this.getRecordStatus();
              this.handleReset();
              break;
            case 'Publish':
              alert(i18n.t('dashboardCntContainerSucM5'));
              this.setState({
                publishStatus: true,
                saveStatus: false,
                submitStatus: false,
                reviewImplementationStatus: false,
              });
              this.getRecordStatus();
              this.handleReset();
            default:
              break;
          }
          this.setState({ active: false });
        } else {
          alert(i18n.t('dashboardCntContainerSucM6'));
        }
      })
      .catch((error) => {
        window.alert(error.response.data.Message);
        trycatchAlertPopup(error);
      });
  }

  CreateSaveAndSubmitData() {
    this.state.CountData = [];
    this.state.CountInsertData = [];

    this.state.postData = [];
    const data = store.getState();
    var dataParam = {
      TabName: null,
      TabData: [],
    };

    const BOData = data.BOReducers.BO;
    dataParam.TabName = 'Business Outcome';
    dataParam.TabData = [...BOData];
    this.state.postData.push(dataParam);

    var dataParam = {
      TabName: null,
      TabData: [],
    };
    const HMData = data.humanReducers.humanandmachine;
    dataParam.TabName = 'Human & Machine Talent';
    dataParam.TabData = [...HMData];
    this.state.postData.push(dataParam);

    var dataParam = {
      TabName: null,
      TabData: [],
    };
    const IIData = data.intelligentInsightReducers.insightandintelligence;
    dataParam.TabName = 'Insights & Intelligence';
    dataParam.TabData = [...IIData];
    this.state.postData.push(dataParam);

    var dataParam = {
      TabName: null,
      TabData: [],
    };
    const DiverseData = data.diverseReducers.diverse;
    dataParam.TabName = 'Diverse Data';
    dataParam.TabData = [...DiverseData];
    this.state.postData.push(dataParam);

    var dataParam = {
      TabName: null,
      TabData: [],
    };
    const WOData = data.WOtileReducers.tile;
    dataParam.TabName = 'Work Orchestration';
    dataParam.TabData = [...WOData];
    this.state.postData.push(dataParam);

    var dataParam = {
      TabName: null,
      TabData: [],
    };
    const IntelOpsData = data.intelligentReducers.IOJ;
    dataParam.TabName = 'IntelOps';
    dataParam.IntelTabData = [...IntelOpsData];
    this.state.postData.push(dataParam);
    var dataParam = {
      TabName: null,
      TabData: [],
    };
    const MaturityData = data.MaturityReducers.Maturity;
    dataParam.TabName = 'MaturityPlanData';
    dataParam.MaturityPlanTabData = [...MaturityData];
    this.state.postData.push(dataParam);
  }

  getRoleData() {
    axiosInstance
      .get(`${LocalApiBaseUrl}User/GetUserFeature`, {
        params: { clientID: this.props.filterDetails.Client },
      })
      .then((response) => {
        store.dispatch({
          type: 'INITIALISEROLE',

          payload: response.data,
        });
      })
      .catch((error) => {
        window.alert(error.response.data.Message);
      });
  }

  getRecordStatus() {
    let publishStatus = false;
    let saveStatus = false;
    let submitStatus = false;
    let reviewImplementationStatus = false;
    let remarkStatus = false;
    let dataStatus = '';
    const filterLocations = this.props.filterDetails.Locations;
    const filterClient = this.props.filterDetails.Client;
    const filterProcess = this.props.filterDetails.process;
    const filterDate = this.props.filterDetails.Month;

    if (filterClient && filterLocations && filterDate && filterProcess) {
      axiosInstance
        .get(`${LocalApiBaseUrl}Configurator/GetRecordStatus`, {
          params: {
            OrgEntityID: filterClient,
            LocationID: filterLocations,
            MonthDT: filterDate,
            OfferingID: filterProcess,
          },
        })
        .then((response) => {
          this.setState({ recordStatus: response.data });
          store.dispatch({
            type: 'SAVE_GET_RECORD_STATUS',
            payload: response.data,
          });

          if (response.data[0] == 'Publish') {
            publishStatus = true;
            dataStatus = 'Published';
          }

          if (response.data[0] == 'Save') {
            saveStatus = true;
            dataStatus = 'Draft';
          }

          if (response.data[0] == 'Submit') {
            submitStatus = true;
            dataStatus = 'In Review';
          }

          if (response.data[0] == 'Review(Implementation)') {
            submitStatus = true;
            reviewImplementationStatus = true;
            dataStatus = 'In Review(Implementation)';
          }
          if (response.data[0] == 'Rejected') {
            submitStatus = true;
            dataStatus = 'Rejected';
          }
          if (response.data[3] != undefined) {
            remarkStatus = true;
          }

          if (response.data[2] == 'False') {
            if (response.data[1] == 'True') {
              publishStatus = true;
              saveStatus = false;
              submitStatus = false;
            }
          }
          this.setState({
            publishStatus,
            saveStatus,
            submitStatus,
            remarkStatus,
            dataStatus,
            reviewImplementationStatus,
          });
        })
        .catch((error) => {
          window.alert(error.response.data.Message);
        });
    }
  }

  RejectClick(e) {
    e.preventDefault();
    this.setState({ rejectedByImplementationTeam: false });
    this.setState({ viewmore: true });
  }

  onClose() {
    this.setState({ viewmore: false });
    this.extradata = [];
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.compDetails.component != undefined
      && this.props.compDetails.component != nextProps.compDetails.component
    ) {
      this.getInIHeaderLabel(nextProps.compDetails.component);
      this.getToolsData(nextProps.compDetails.component);
    }
  }

  componentDidUpdate(prevState) {
    let saveEnable = false;
    let submitEnable = false;
    let publishEnable = false;
    let implementationTeamButtonsEnable = false;

    let publishStatus = false;
    let saveStatus = false;
    let submitStatus = false;
    let reviewImplementationStatus = false;
    let remarkStatus = false;
    let dataStatus = '';

    if (prevState.isNewDeal !== this.props.isNewDeal) {
      const featureDataForConf = store.getState().roleReducers.roleReducers.map((el) => el);
      const confOperations = [];
      const confData = featureDataForConf.filter((el) => el.FeatureName === 'Configurator');
      const configuratorOperations = confData.map((ele) => {
        if (ele.Operations != null && ele.Operations.count != 0) {
          confOperations.push(ele.Operations);
        }
      });

      const operation = [];
      const test = confOperations.map((ele) => {
        saveEnable = ele.includes('Save');
        submitEnable = ele.includes('Submit');
        publishEnable = ele.includes('Publish');

        implementationTeamButtonsEnable = ele.includes('Approve - Review Stage 1') && this.props.isNewDeal;
      });
      this.setState({
        implementationTeamButtonsEnable,
      });
    }
    if (this.state.recordStatus !== this.props.getRecordStatus) {
      this.setState({ recordStatus: this.props.getRecordStatus });

      if (this.props.getRecordStatus[0] == 'Publish') {
        publishStatus = true;
        dataStatus = 'Published';
      }

      if (this.props.getRecordStatus[0] == 'Save') {
        saveStatus = true;
        dataStatus = 'Draft';
      }

      if (this.props.getRecordStatus[0] == 'Submit') {
        submitStatus = true;
        dataStatus = 'In Review';
      }

      if (this.props.getRecordStatus[0] == 'Review(Implementation)') {
        submitStatus = true;
        reviewImplementationStatus = true;
        dataStatus = 'In Review(Implementation)';
      }
      if (this.props.getRecordStatus[0] == 'Rejected') {
        submitStatus = true;
        dataStatus = 'Rejected';
      }
      if (this.props.getRecordStatus[3] != undefined) {
        remarkStatus = true;
      }

      if (this.props.getRecordStatus[2] == 'False') {
        if (this.props.getRecordStatus[1] == 'True') {
          publishStatus = true;
          saveStatus = false;
          submitStatus = false;
        }
      }
      this.setState({
        publishStatus,
        saveStatus,
        submitStatus,
        remarkStatus,
        dataStatus,
        reviewImplementationStatus,
      });
    }
  }

  componentWillMount() {
    let saveEnable = false;
    let submitEnable = false;
    let publishEnable = false;
    let implementationTeamButtonsEnable = false;
    let publishStatus = false;
    let saveStatus = false;
    let submitStatus = false;
    let reviewImplementationStatus = false;
    let remarkStatus = false;
    let { dataStatus } = this.state;
    this.GetUserDetails();
    if (this.props.getRecordStatus.length) {
      this.setState({ recordStatus: this.props.getRecordStatus });

      if (this.props.getRecordStatus[0] == 'Publish') {
        publishStatus = true;
        dataStatus = 'Published';
      }

      if (this.props.getRecordStatus[0] == 'Save') {
        saveStatus = true;
        dataStatus = 'Draft';
      }

      if (this.props.getRecordStatus[0] == 'Submit') {
        submitStatus = true;
        dataStatus = 'In Review';
      }

      if (this.props.getRecordStatus[0] == 'Review(Implementation)') {
        submitStatus = true;
        reviewImplementationStatus = true;
        dataStatus = 'In Review(Implementation)';
      }
      if (this.props.getRecordStatus[0] == 'Rejected') {
        submitStatus = true;
        dataStatus = 'Rejected';
      }
      if (this.props.getRecordStatus[3] != undefined) {
        remarkStatus = true;
      }

      if (this.props.getRecordStatus[2] == 'False') {
        if (this.props.getRecordStatus[1] == 'True') {
          publishStatus = true;
          saveStatus = false;
          submitStatus = false;
        }
      }
      this.setState({
        publishStatus,
        saveStatus,
        submitStatus,
        remarkStatus,
        dataStatus,
        reviewImplementationStatus,
      });
    }

    const data = store.getState();

    this.setState({
      filtername: store.getState().CurrentFilter.Filter.offeringName,
    });
    // Create State Varaibles and use to provide rights

    const featureData = data.roleReducers.roleReducers.map((el) => el.FeatureName);

    const featureDataForConf = data.roleReducers.roleReducers.map((el) => el);
    const confOperations = [];
    const confData = featureDataForConf.filter((el) => el.FeatureName === 'Configurator');
    const configuratorOperations = confData.map((ele) => {
      if (ele.Operations != null && ele.Operations.count != 0) {
        confOperations.push(ele.Operations);
      }
    });

    const operation = [];
    const test = confOperations.map((ele) => {
      saveEnable = ele.includes('Save');
      submitEnable = ele.includes('Submit');
      publishEnable = ele.includes('Publish');
      implementationTeamButtonsEnable = ele.includes('Approve - Review Stage 1') && this.props.isNewDeal;
    });

    this.setState({
      saveEnable,
      submitEnable,
      publishEnable,
      implementationTeamButtonsEnable,
    });
    this.getMaintenanceWindowStatus();
    this.getToolsData(this.props.compDetails.component);
    this.getInIHeaderLabel(this.props.compDetails.component);
    this.getUom();
  }

  getInIHeaderLabel = (componentID) => {
    if (
      this.props.filterDetails != undefined
      && this.props.filterDetails.process != null
      && parseInt(this.props.filterDetails.process) > 0
    ) {
      const filterProcess = this.props.filterDetails.process;
      let soCategory = 0;
      switch (componentID) {
        case 'BO':
          soCategory = 1;
          break;
        case 'HumanMachineTalent':
          soCategory = 2;
          break;
        case 'WorkOrchestration':
          soCategory = 3;
          break;
        case 'InsightsIntelligence':
          soCategory = 4;
          break;
        case 'DiverseData':
          soCategory = 5;
          break;
      }

      if (filterProcess != 0 && soCategory != 0) {
        let headerData = soCategory == 4 ? i18n.t('Key_Metrics&SLA+') : '';

        axiosInstance
          .get(`${LocalApiBaseUrl}Configurator/GetHeaderLabelText`, {
            params: {
              offeringID: filterProcess,
              soCategoryID: soCategory,
              languageID: store.getState().CurrentFilter.languageData.id,
            },
          })
          .then((response) => {
            if (response.status == '200') {
              if (response.data.length > 0) headerData = response.data[0].Value;
            }
            this.setState({ headerLabel: headerData });
          })
          .catch((error) => {
            window.alert(error.response.data.Message);
            trycatchAlertPopup(error);
          });
      }
    }
  };

  getMaintenanceWindowStatus() {
    axiosInstance
      .get(`${LocalApiBaseUrl}Configurator/GetMaintenanceWindowStatus`)
      .then((response) => {
        this.setState({ isManintencneWindowOpen: response.data });
      })
      .catch((error) => {
        window.alert(error.response.data.Message);
        trycatchAlertPopup(error);
      });
  }

  getToolsData = (componentID) => {
    if (componentID != undefined && componentID != '') {
      let soCategory = 0;
      switch (componentID) {
        case 'BO':
          soCategory = 1;
          break;
        case 'HumanMachineTalent':
          soCategory = 2;
          break;
        case 'WorkOrchestration':
          soCategory = 3;
          break;
        case 'InsightsIntelligence':
          soCategory = 4;
          break;
        case 'DiverseData':
          soCategory = 5;
          break;
      }
      if (
        soCategory > 0
        && this.props.filterDetails
        && this.props.filterDetails.process != undefined
        && parseInt(this.props.filterDetails.process) > 0
      ) {
        const param = {
          OfferingID: this.props.filterDetails.process,
          SOCategoryID: soCategory,
          OrgEntityID:
            this.props.filterDetails.Client != undefined
              ? parseInt(this.props.filterDetails.Client)
              : 0,
          ActiveOnly: 1,
        };
        axiosInstance
          .post(`${LocalApiBaseUrl}ApplicationMapping/GetApplicationMappingByOfferingID`, param)
          .then((response) => {
            this.setState({ tool: response.data });
          })
          .catch((error) => {
            window.alert(error.response.data.Message);
            trycatchAlertPopup(error);
          });
      }
    }
  };

  callGetConfigurator() {
    const filter = this.props.filterDetails;
    filter.LocationID = this.props.filterDetails.Locations;
    filter.OrgEntityID = this.props.filterDetails.Client;
    filter.OfferingID = this.props.filterDetails.process;
    filter.MonthDT = this.props.filterDetails.Month;
    store.dispatch(InitializeDatas());
    if (filter.OrgEntityID && filter.LocationID && filter.MonthDT && filter.OfferingID) {
      axiosInstance
        .get(`${LocalApiBaseUrl}Configurator/GetConfiguratorData`, {
          params: {
            OrgEntityID: filter.OrgEntityID,
            LocationID: filter.LocationID,
            MonthDT: filter.MonthDT,
            OfferingID: filter.OfferingID,
          },
        })
        .then((response) => {
          store.dispatch({
            type: 'INITIALISE_BO',
            payload: response.data[0].TabData,
          });
          store.dispatch({
            type: 'INITIALISE',
            payload: response.data[1].TabData,
          });
          store.dispatch({
            type: 'INITIALISEINSIGHT',
            payload: response.data[3].TabData,
          });
          store.dispatch({
            type: 'INITIALISEDIVERSE',
            payload: response.data[4].TabData,
          });
          store.dispatch({
            type: 'INITIALISEIOJ',
            payload: response.data[5].IntelTabData == null ? [] : response.data[5].IntelTabData,
          });
          store.dispatch({
            type: 'INITIALISEWO',
            payload: response.data[2].TabData,
          });
          store.dispatch({
            type: 'LastPublishedDate',
            payload: response.data[0].LastPublishedDate,
          });
          this.setState({ isApiSuccess: true });
        })
        .catch((error) => {
          window.alert(error.response.data.Message);
          trycatchAlertPopup(error);
          this.setState({ isApiSuccess: false });
        });
    }
  }

  render() {
    return (
      <>
        <LoadingOverlay
          fadeSpeed={10}
          spinner={<SynopsLoader />}
          active={this.props.getConfiguratorapicall}
        >
          <LoadingOverlay
            classNamePrefix="_loading_overlay_overlay"
            active={this.state.active}
            // spinner
            text={`${i18n.t('Loading_SynOps')} …`}
          >
            {this.props.getConfiguratorapicall == false ? (
              <>
                {
                  <ContentHeader
                    recordStatus={this.state.recordStatus}
                    roleReducers={this.props.roleReducers}
                    isManintencneWindowOpen={this.state.isManintencneWindowOpen}
                    titleDescription={this.props.compDetails}
                    dataStatus={this.state.dataStatus}
                    SODetails={this.props.SODetails}
                    filterDetails={this.props.filterDetails}
                    enableremark={this.state.remarkStatus}
                    saveEnable={this.state.saveEnable}
                    publishStatus={this.state.publishStatus}
                    submitStatus={this.state.submitStatus}
                    SaveClick={this.SaveClick.bind(this)}
                    submitEnable={this.state.submitEnable}
                    IsAdmin={this.state.IsAdmin}
                    SubmitClick={this.SubmitClick.bind(this)}
                    reviewImplementationStatus={this.state.reviewImplementationStatus}
                    implementationTeamButtonsEnable={this.state.implementationTeamButtonsEnable}
                    approveImplementationClick={this.approveImplementationClick.bind(this)}
                    RejectImplementationClick={this.RejectImplementationClick.bind(this)}
                    publishEnable={this.state.publishEnable}
                    dataStatus={this.state.dataStatus}
                    PublishClick={this.PublishClick.bind(this)}
                    RejectClick={this.RejectClick.bind(this)}
                    ResetClick={this.ResetClick.bind(this)}
                    LastMonthClick={this.LastMonthClick.bind(this)}
                    onTabChange={this.props.onTabChange}
                    isBoLoaded={this.props.isBoLoaded}
                    isHMLoaded={this.props.isHMLoaded}
                    isWOLoaded={this.props.isWOLoaded}
                    isIILoaded={this.props.isIILoaded}
                    isDDLoaded={this.props.isDDLoaded}
                    isMPLoaded={this.props.isMPLoaded}
                  />
                }
                {this.props.compDetails.component === 'BO' ? (
                  <>
                    {/* {this.props.isBoLoaded ?():<LoadingOverlay
                  active={true}
                  spinner={<SynopsLoader />}
                  />} */}
                    <BOContainer
                      RecordStatus={this.props.getRecordStatus}
                      tool={this.state.tool}
                      isManintencneWindowOpen={this.state.isManintencneWindowOpen}
                      isRelatorBlock={this.props.isRelatorBlock}
                      attributes={this.props.attributes}
                      SODetails={this.props.SODetails}
                      filterDetails={this.props.filterDetails}
                      roleReducers={this.props.roleReducers}
                    />
                  </>
                ) : null}
                {this.props.compDetails.component === 'HumanMachineTalent' ? (
                  <>
                    {/* {this.props.isHMLoaded ?null:<LoadingOverlay
                  active={true}
                  spinner={<SynopsLoader />}
                  />} */}
                    <HumanMachineTalent
                      RecordStatus={this.props.getRecordStatus}
                      isManintencneWindowOpen={this.state.isManintencneWindowOpen}
                      tool={this.state.tool}
                      SODetails={this.props.SODetails}
                      filterDetails={this.props.filterDetails}
                    />
                  </>
                ) : null}
                {this.props.compDetails.component === 'WorkOrchestration' ? (
                  <>
                    {/* {this.props.isWOLoaded ?null:<LoadingOverlay
                  active={true}
                  spinner={<SynopsLoader />}
                  />} */}
                    <WorkOrchestration
                      RecordStatus={this.props.getRecordStatus}
                      isManintencneWindowOpen={this.state.isManintencneWindowOpen}
                      screen={this.state.screen}
                      tool={this.state.tool}
                      SODetails={this.props.SODetails}
                      filterDetails={this.props.filterDetails}
                    />
                  </>
                ) : null}

                {this.props.compDetails.component === 'InsightsIntelligence' ? (
                  <>
                    {/* {this.props.isIILoaded ?null:<LoadingOverlay
                   active={true}
                   spinner={<SynopsLoader />}
                   />} */}
                    <InsightsIntelligence
                      RecordStatus={this.props.getRecordStatus}
                      isManintencneWindowOpen={this.state.isManintencneWindowOpen}
                      tool={this.state.tool}
                      isRelatorBlock={this.props.isRelatorBlock}
                      SODetails={this.props.SODetails}
                      attributes={this.props.attributes}
                      headerLabel={this.state.headerLabel}
                      filterDetails={this.props.filterDetails}
                    />
                  </>
                ) : null}
                {this.props.compDetails.component === 'DiverseData' ? (
                  <>
                    {/* {this.props.isDDLoaded ?null:
                    <LoadingOverlay
                    active={true}
                    spinner={<SynopsLoader />}
                    />} */}
                    <DiverseDataContainer
                      RecordStatus={this.props.getRecordStatus}
                      isManintencneWindowOpen={this.state.isManintencneWindowOpen}
                      tool={this.state.tool}
                      SODetails={this.props.SODetails}
                      callGetConfigurator={this.callGetConfigurator}
                      isApiSuccess={this.state.isApiSuccess}
                      attributes={this.props.attributes}
                      filterDetails={this.props.filterDetails}
                    />
                  </>
                ) : null}
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="text-center my-3" />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </LoadingOverlay>
        </LoadingOverlay>
        {this.state.viewmore ? (
          <RejectPopUp
            show
            onClose={this.onClose}
            onStatus={this.getRecordStatus}
            filterDetails={this.props.filterDetails}
            rejectedByImplementationTeam={this.state.rejectedByImplementationTeam}
          />
        ) : null}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  getRecordStatus: state.CurrentFilter.getRecordStatus,
  roleReducers: state.roleReducers.roleReducers,
  IOJData: state.JourneyReducer.IOJData,
  fetchFromBAT: state.CurrentFilter.FetchFromBAT,
});

export default connect(mapStateToProps, '')(DashboardCntContainer);
