import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import {
  LocalApiBaseUrl, trycatchAlertPopup, regexForFields, regexForToolURL, commonToolRegex,
} from '../Shared/Constant';
import axiosInstance from '../Shared/interceptor';

import ToolsPopUP from './ToolsPopUp';

import { store } from '../reducers/configureStore';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('msal.idtoken')}`;

export default class ClickableToolPopup extends React.Component {
  constructor(props) {
    super(props);
    const { show } = this.props;
    this.state = {
      viewmore: false,
      viewcatdes: false,
      viewdesc: false,
      isEditable: false,
      leverName: '',
      Tooltype: '',
      toolLeversList: [],
      clientname: '',
      Action: 'Create',
      popupHeader: i18n.t('Add Tool Mapping'),
      popupSaveButton: i18n.t('Create'),
      offeringName: '',
      ApplicationID: '',
      subofferingname: '',
      selectedClientId: '',
      ClickableToolID: '',
      ToolCategory: '',
      ToolDescription: '',
      LocationOptions: [],
      processData: [],
      MappingDetails: {
        OfferingID: '',
        OfferingName: '',
        proID: '',
        ClickableTooLMappingID: '',
        locID: '',
        LocationName: '',
        url: '',
        description: '',
        BusinessBenefit: '',
        DefaultUserID: '',
      },
      MappingErrors: {
        ClientName: '',
        Offering: '',
        SubOffering: '',
        Location: '',
        Levers: '',
        toolname: '',
        BusinessBenefit: '',
        url: '',
      },
      ClientOptions: [],
      offeringData: [],
      RoleOptions: [],
      show,
      lever: {
        leverId: 0,
      },

      user: {
        UserID: 0,
        UserName: '',
        UserEnterpiseID: null,
        OrgEntityID: null,
        UserEmailAddress: null,
        IsActive: false,
        IsGuest: false,
        IsAdmin: false,
        IsCapability: false,
        SubOfferingOptions: [],
        RoleIds: [],
        ParentId: [],
        RoleNames: [
        ],
        ClientIds: [
        ],
        OfferingIds: [
        ],
        OfferingNames: [
        ],
        SubOfferingIds: [
        ],
        SubOfferingNames: [
        ],
        ClientNames: [
        ],
        CreatedUser: null,
        CreatedTS: null,
      },
    };
    this.OfferingValues = [];
    this.getnewOfferings = this.getnewOfferings.bind(this);
    this.leverselect = this.leverselect.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.onChange = this.onChange.bind(this);
    this.roleSelect = this.roleSelect.bind(this);
    this.locationSelect = this.locationSelect.bind(this);
    this.bindProcess = this.bindProcess.bind(this);
    this.selectedFilter = this.selectedFilter.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { data } = this.props;
    if (data) {
      const eachData = { ...data };
      this.setState({
        user: eachData,
      });
    }
  }

  componentDidMount() {
    const { rowData, tooldatatype } = this.props;
    this.getClients();

    if (String(rowData) !== String(undefined) && String(tooldatatype) === 'Edit') {
      const { data } = this.props;
      if (data.Tooltype === 'Custom') {
        this.setState({ viewdesc: false });
      } else {
        this.setState({ viewdesc: true });
      }
      this.setState({
        popupHeader: i18n.t('Edit Tool Mapping'),
        popupSaveButton: i18n.t('Update'),
        viewcatdes: true,
        ApplicationID: data.ApplicationMappingID,
        clientname: data.OrgEntityName,
        offeringName: data.Offering,
        subofferingname: data.Process,
        LocationName: data.City,
        SOCategoryName: data.SOCategoryName,
        ToolName: data.ApplicationName,
        Tooltype: data.ApplicationCategory,
        ToolDescription: data.ApplicationDescription,
        isEditable: true,
        Action: 'Edit',
        MappingDetails: {
          url: data.URL,
          ClickableTooLMappingID: data.ClickableToolMappingID,
          DefaultUserID: data.DefaultUserID,
          BusinessBenefit: data.BusinessBenefit,
        },
      });
    } else {
      this.setState({
        popupHeader: i18n.t('Add Tool Mapping'),
        popupSaveButton: i18n.t('Create'),
        clientname: '',
        offeringName: '',
        subofferingname: '',
        Action: 'Create',
        LocationName: '',
        SOCategoryName: '',
        ToolName: '',
        ToolCategory: '',
        ToolDescription: '',
        MappingDetails: {
          url: '',
        },
      });
    }
  }

  handleApproveHide(e) {
    const { onApproveClose } = this.props;
    this.setState({ show: false });
    if (String(e) !== String(undefined)) {
      if ((String(e.target.textContent) !== '×')) {
        onApproveClose();
      }
    } else {
      onApproveClose();
    }
  }

  handleRejectHide(e) {
    const { onRejectClose } = this.props;
    this.setState({ show: false });
    if (String(e) !== String(undefined)) {
      if ((String(e.target.textContent) !== '×')) {
        onRejectClose();
      }
    } else {
      onRejectClose();
    }
  }

  handleHide(e) {
    const { onClose } = this.props;
    this.setState({ show: false });
    if (String(e) !== String(undefined)) {
      if ((String(e.target.textContent) !== '×')) {
        onClose(e);
      } else {
        onClose(e);
      }
    } else {
      onClose(e);
    }
  }

  handleSave() {
    const {
      MappingDetails, lever, user, ApplicationMappingName, ToolCategory, ToolDescription, Action, ApplicationID,
    } = this.state;
    const { url } = MappingDetails;
    const { BusinessBenefit } = MappingDetails;
    const { DefaultUserID } = MappingDetails;
    if (this.validateForm()) {
      const data = {
        OfferingID: parseInt(MappingDetails.proID, 10),
        SOCategoryID: parseInt(lever.leverId, 8),
        OrgEntityID: parseInt(user.ClientIds, 6),
        LocationID: parseInt(MappingDetails.locID, 4),
        ApplicationName: ApplicationMappingName ? ApplicationMappingName.toString() : '',
        ApplicationMappingID: ApplicationID, // MAPPING APPLICATIONID
        ClickableToolMappingID: parseInt(MappingDetails.ClickableTooLMappingID, 2),
        URL: url ? url.trim() : '',
        ToolCategory: ToolCategory.trim(),
        ApplicationDescription: ToolDescription.toString().trim(),
        BusinessBenefit: BusinessBenefit ? BusinessBenefit.trim() : '',
        DefaultuserID: DefaultUserID ? DefaultUserID.trim() : '',
        Action: (Action && Action.trim()),
      };
      const { onSave } = this.props;
      onSave(data);
      this.handleHide();
    }
  }

  onClientChange(event) {
    const { user } = this.state;
    const client = document.getElementById('Client');
    const tempClient = [];
    const len = client.length;
    for (let i = 0; i < len; i += 1) {
      const opt = client.options[i];
      if (opt.selected) {
        tempClient.push(opt.value);
      }
    }
    user.ClientIds = tempClient;
    this.setState({
      user,
    });
    const ClientIdspass = user.ClientIds;
    if (String(event.target.value) === String(0)) {
      this.setState({
        viewcatdes: false,
        offeringData: [],
        processData: [],
        LocationOptions: [],
        toolLeversList: [],
        RoleOptions: [],
        MappingDetails: {
          OfferingID: 0,
          locID: 0,
          proID: 0,
          ApplicationMappingID: 0,
        },
        lever: {
          leverId: 0,
        },
        ApplicationMappingName: '',
        ToolCategory: '',
        ToolDescription: '',
      });
    } else {
      this.getnewOfferings(ClientIdspass);
    }
  }

  onClose(e) {
    const { onClose } = this.props;
    this.setState({ viewmore: false });
    onClose(e);
  }

  onChange(event) {
    const { user } = this.state;
    if (String(event.target.value) === String(7) && user.ClientIds != null) {
      this.setState({
      });
    } else {
      this.setState({
      });
    }
  }

  onCapClientChange() {
    const { user } = this.state;
    const client = document.getElementById('CapClient');
    const tempClient = [];
    const len = client.length;
    for (let i = 0; i < len; i += 1) {
      const opt = client.options[i];
      if (opt.selected) {
        tempClient.push(opt.value);
      }
    }

    user.ClientIds = tempClient;
    this.setState({
      user,
    });
  }

  getnewOfferings(ClientIdspass) {
    const { user, ClientOptions } = this.state;
    let offeringData = [];
    const Client = user.ClientIds;// PASSING STATES ID
    const clientname = Client !== '0' ? ClientOptions.filter((row) => String(row.OrgEntityID) === String(ClientIdspass))[0].OrgEntityName : [];
    this.setState({ clientname });
    this.setState({ selectedClientId: Client });
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
        params: { OrgEntityID: ClientIdspass, LanguageID: store.getState().CurrentFilter.languageData.id, featureName: 'ClickableTool' },
      })
      .then((response) => {
        /* For Token renewl end */
        offeringData = response.data;
        this.setState({
          offeringData,
          viewcatdes: false,
          processData: [],
          LocationOptions: [],
          toolLeversList: [],
          RoleOptions: [],
          MappingDetails: {
            OfferingID: 0,
            locID: 0,
            proID: 0,
            ApplicationMappingID: 0,
          },
          lever: {
            leverId: 0,
          },
          ApplicationMappingName: '',
          ToolCategory: '',
          ToolDescription: '',
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getToolMasterData = () => {
    axiosInstance.get(`${LocalApiBaseUrl}ApplicationMapping/GetMasterDataForApplicationMapping`, {
      params: { languageID: store.getState().CurrentFilter.languageData.id },
    })
      .then((response) => {
        const soCategoryList = response.data.SOCategoryList.filter((id) => id.ID !== 1);
        this.setState({
          toolLeversList: soCategoryList,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getSuboffering(OfferingIDpass) {
    const { offeringData } = this.state;
    const processData = (OfferingIDpass && String(OfferingIDpass[0]) !== '0') ? offeringData.filter(
      (eachValue) => String(eachValue.OfferingID) === String(OfferingIDpass[0]),
    )[0].ChildOfferings : [];/// SINGLE ROW

    this.setState({ processData });
  }

  getRoles() {
    const { user, MappingDetails, lever } = this.state;
    const EnterpriseID = sessionStorage.getItem('EnterpriseID');
    if (String(EnterpriseID) !== String(undefined)) {
      axiosInstance.get(`${LocalApiBaseUrl}ClickableTools/GetMergedTool`, {
        params: {
          OrgEntityID: user.ClientIds,
          OfferingID: MappingDetails.proID,
          LocationID: MappingDetails.locID,
          SOCategoryID: lever.leverId,
        },
      })

        .then((response) => {
          this.setState({
            RoleOptions: response.data,
            ApplicationMappingName: '',
            ToolCategory: '',
            ToolDescription: '',
          });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  }

  getLocations(Client, subofferingID) {
    let LocationData = [];
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetLocation`, {

        params: { OrgEntityID: Client, OfferingID: subofferingID, LanguageID: store.getState().CurrentFilter.languageData.id },
      })
      .then((response) => {
        LocationData = response.data;
        this.setState({ LocationOptions: LocationData });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getClients() {
    axiosInstance.get(`${LocalApiBaseUrl}SO/GetOrgStructure`, {
    })
      .then((response) => {
        this.setState({ ClientOptions: response.data });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

    bindToolFieldChange = (e) => {
      const { MappingDetails } = this.state;
      const { id } = e.target;
      const data = MappingDetails;
      switch (id) {
        case 'txtToolUrl':
          data.url = e.target.value;
          break;
        case 'txtToolbusinessreason':
          data.BusinessBenefit = e.target.value;
          break;
        case 'txtTooldefaultuserid':
          data.DefaultUserID = e.target.value;
          break;
        case 'textdesc':
          this.setState({ ToolDescription: e.target.value });
          break;
        default:
          break;
      }

      this.setState({
        MappingDetails: data,
      });
    }

    selectedFilter = () => {
      const { user } = this.state;
      const offering = document.getElementById('process');
      if (offering) {
        const tempOffering = [];
        const countOffering = [];
        const len = offering.length;
        for (let i = 0; i < len; i += 1) {
          const opt = offering.options[i];

          if (opt.selected) {
            tempOffering.push(opt);
            countOffering.push(opt.value);
          }
        }
        user.OfferingIds = countOffering;
        this.setState({
        });

        this.setState({
          user,
        });
      }

      // calling clients

      axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetTowerLevelClient`, {
        params: { OfferingID: user.OfferingIds.toString() },
      })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }

    roleSelect(event) {
      const { RoleOptions, MappingDetails } = this.state;
      const toolcheck = event.target.value;// toolcheck.id
      const statestore = RoleOptions.filter((row) => String(row.ClickableToolID) === String(event.target.value))[0];
      const ApplicationID = String(toolcheck) !== '-1' && String(toolcheck) !== '0' ? RoleOptions.filter((row) => String(row.ClickableToolID) === String(event.target.value))[0].ApplicationMappingID : [];
      const ApplicationName = String(toolcheck) !== '-1' && String(toolcheck) !== '0' ? RoleOptions.filter((row) => String(row.ClickableToolID) === String(event.target.value))[0].ApplicationName : [];
      const Tooltype = String(toolcheck) !== '-1' && String(toolcheck) !== '0' ? RoleOptions.filter((row) => String(row.ClickableToolID) === String(event.target.value))[0].ApplicationCategory : [];
      const ToolDescription = String(toolcheck) !== '-1' && String(toolcheck) !== '0' ? RoleOptions.filter((row) => String(row.ClickableToolID) === String(event.target.value))[0].ApplicationDescription : [];
      const BusinessBenefit = String(toolcheck) !== '-1' && String(toolcheck) !== '0' ? RoleOptions.filter((row) => String(row.ClickableToolID) === String(event.target.value))[0].BusinessBenefit : [];
      const DefaultUserID = String(toolcheck) !== '-1' && String(toolcheck) !== '0' ? RoleOptions.filter((row) => String(row.ClickableToolID) === String(event.target.value))[0].DefaultUserID : [];
      const url = String(toolcheck) !== '-1' && String(toolcheck) !== '0' ? RoleOptions.filter((row) => String(row.ClickableToolID) === String(event.target.value))[0].URL : [];
      const ToolCategory = String(toolcheck) !== '-1' && String(toolcheck) !== '0' && statestore.ToolCategory ? statestore.ToolCategory : [];

      this.setState({
        ApplicationID,
        Tooltype,
        ToolDescription,
        ApplicationMappingName: ApplicationName,
        ToolCategory,
        MappingDetails: {
          ...MappingDetails,
          BusinessBenefit,
          DefaultUserID,
          url,
        },
      });

      if (String(event.target.value) === '-1') {
        this.setState({ viewmore: true, show: false });
      }

      if (String(event.target.value) !== '-1' && String(event.target.value) !== '0') {
        this.setState({ viewcatdes: true });
      } else {
        this.setState({ viewcatdes: false });
      }
      if (String(ToolCategory) === 'Custom') {
        this.setState({ viewdesc: false });
      } else {
        this.setState({ viewdesc: true });
      }

      this.onChange(event);
      let { ClickableToolID } = this.state;

      const tool = document.getElementById('tool');
      const temptools = [];
      const len = tool.length;
      for (let i = 0; i < len; i += 1) {
        const opt = tool.options[i];
        if (opt.selected) {
          temptools.push(opt.value);
        }
      }

      ClickableToolID = temptools;

      this.setState({
        ClickableToolID,
      });
    }

    SubofferingSelect() {
      const { user } = this.state;
      const SubOffering = document.getElementById('SubOffering');
      const tempSubOff = [];
      const len = SubOffering.length;
      for (let i = 0; i < len; i += 1) {
        const opt = SubOffering.options[i];
        if (opt.selected) {
          tempSubOff.push(opt.value);
        }
      }
      user.SubOfferingIds = tempSubOff;
      this.setState({
        user,
      });
    }

    passToConfirmation(data) {
      const { enteredData } = this.props;
      enteredData(data.PoNumber);
    }

    OnnewOfferingChange() {
      const { MappingDetails, offeringData } = this.state;
      const offer = MappingDetails;
      const Offering = document.getElementById('Offering');
      const tempOffering = [];
      const len = Offering.length;
      for (let i = 0; i < len; i += 1) {
        const opt = Offering.options[i];
        if (opt.selected) {
          tempOffering.push(opt.value);
        }
      }
      offer.OfferingID = tempOffering;
      this.setState({
      });

      this.setState({
        MappingDetails: {
          ...MappingDetails,
          locID: 0,
          proID: 0,
          ApplicationMappingID: 0,
        },
        lever: {
          leverId: 0,
        },
        LocationOptions: [],
        toolLeversList: [],
        RoleOptions: [],
        viewcatdes: false,
        ApplicationMappingName: '',
        ToolCategory: '',
        ToolDescription: '',
      });

      const selectedOffering = MappingDetails;
      const OfferingIDpass = MappingDetails.OfferingID;

      const offeringName = String(selectedOffering.OfferingID) !== '0' ? offeringData.filter(
        (eachValue) => String(eachValue.OfferingID) === String(selectedOffering.OfferingID),
      )[0].OfferingName : [];
      this.setState({ offeringName });
      this.getSuboffering(OfferingIDpass);
    }

    OnOfferingChange() {
      const { MappingDetails, selectedClientId, processData } = this.state;
      const sub = MappingDetails;
      const offering = document.getElementById('SubOffering');
      if (offering) {
        const tempOffering = [];
        const countOffering = [];
        const len = offering.length;
        for (let i = 0; i < len; i += 1) {
          const opt = offering.options[i];

          if (opt.selected) {
            tempOffering.push(opt);
            countOffering.push(opt.value);
          }
        }
        sub.proID = countOffering;
      }

      this.setState({
        MappingDetails: {
          ...MappingDetails,
          locID: 0,
          ApplicationMappingID: 0,
        },
        lever: {
          leverId: 0,
        },
        LocationOptions: [],
        toolLeversList: [],
        RoleOptions: [],
        viewcatdes: false,
        ApplicationMappingName: '',
        ToolCategory: '',
        ToolDescription: '',
      });

      const subofferingID = MappingDetails.proID;
      const subofferingname = subofferingID.toString() !== '0' ? processData.filter((row) => String(row.OfferingID) === String(subofferingID))[0].Process : [];
      this.setState({ subofferingname });
      if (selectedClientId && String(subofferingID) !== 0) {
        this.getLocations(selectedClientId, subofferingID);
      }
    }

    locationSelect(event) {
      const { MappingDetails, LocationOptions } = this.state;
      this.setState({
        RoleOptions: [],
        toolLeversList: [],
        viewcatdes: false,
        lever: {
          leverId: 0,
        },
      });

      this.onChange(event);

      const location = MappingDetails;

      const loc = document.getElementById('location');
      const temploc = [];
      const len = loc.length;
      for (let i = 0; i < len; i += 1) {
        const opt = loc.options[i];
        if (opt.selected) {
          temploc.push(opt.value);
        }
      }
      location.locID = temploc;
      this.setState({
        MappingDetails: location,
      });
      const LocationIDs = MappingDetails.locID;

      const LocationName = LocationIDs.toString() !== '0' ? LocationOptions.filter((row) => String(row.LocationID) === String(LocationIDs))[0].City : [];

      this.setState({ LocationName });

      if (LocationIDs.toString() !== '0') {
        this.getToolMasterData();
      } else {
        this.setState({
          RoleOptions: [],
          toolLeversList: [],
          viewcatdes: false,
          lever: {
            leverId: 0,
          },
        });
      }
    }

    leverselect(event) {
      this.setState({
        viewcatdes: false,
        RoleOptions: [],
        ApplicationMappingName: '',
        ClickableToolID: '',

      });
      this.onChange(event);
      const { lever } = this.state;
      const lev = document.getElementById('lever');
      const templev = [];
      const len = lev.length;
      for (let i = 0; i < len; i += 1) {
        const opt = lev.options[i];
        if (opt.selected) {
          templev.push(opt.value);
        }
      }

      lever.leverId = event.target.value;
      this.setState({
        lever,
      });
      const { toolLeversList } = this.state;
      const SOCategoryID = lever.leverId;

      const SOCategoryName = SOCategoryID !== '0' ? toolLeversList.filter((a) => String(a.ID) === String(SOCategoryID))[0].Value : [];
      this.setState({ leverName: SOCategoryName });

      this.getRoles();
    }

    clickableclose(e) {
      const { onClose } = this.props;
      onClose(e);
    }

    bindProcess(event) {
      if (!event.Auto) {
        const { user } = this.state;
        const userOld = user;
        // clearing all fields
        userOld.ClientIds = [];
        userOld.OfferingIds = [];
        userOld.ClientNames = '';
        userOld.OfferingNames = '';
        // clearing all the suboffering from FilteredSuboffering
        this.setState({
          user: userOld,
        });
      }
      const selectedOffering = event.target.value;
      const tempOffering = [];
      const countOffering = [];
      let offering = document.getElementById('offerings');
      if (offering) {
        const len = offering.length;
        for (let i = 0; i < len; i += 1) {
          const opt = offering.options[i];

          if (opt.selected) {
            tempOffering.push(opt);
            countOffering.push(opt.value);
          }
        }
      }
      if (String(selectedOffering) !== '0') {
        const processData = this.OfferingValues.filter(
          (eachValue) => String(eachValue.OfferingID) === String(event.target.value),
        )[0].ChildOfferings;

        this.setState({ processData });
        offering = processData[0].OfferingID;
        const { user } = this.state;

        this.setState({
          user,
        });
      }
    }

    validateForm() {
      const {
        isEditable, MappingDetails, user, lever, ApplicationMappingName,
      } = this.state;
      let isValid = false;
      const errorJSON = [];
      if (!isEditable) {
        if (String(user.ClientIds) === String(undefined) || parseInt(user.ClientIds, 10) === 0) {
          errorJSON[0] = i18n.t('Client_Selection_Required');
        } else {
          errorJSON[0] = '';
        }
        if (String(MappingDetails.OfferingID) === String(undefined) || parseInt(MappingDetails.OfferingID, 8) === 0) {
          errorJSON[1] = i18n.t('Offering_Selection_Required');
        } else {
          errorJSON[1] = '';
        }
        if (String(MappingDetails.proID) === String(undefined) || parseInt(MappingDetails.proID, 6) === 0) {
          errorJSON[2] = i18n.t('Sub_Offering_Selection_Required');
        } else {
          errorJSON[2] = '';
        }
        if (String(MappingDetails.locID) === String(undefined) || parseInt(MappingDetails.locID, 4) === 0) {
          errorJSON[3] = i18n.t('Location_Selection_Required');
        } else {
          errorJSON[3] = '';
        }
        if (String(lever.leverId) === String(undefined) || parseInt(lever.leverId, 2) === 0) {
          errorJSON[4] = i18n.t('Lever_Selection_Required');
        } else {
          errorJSON[4] = '';
        }
        if (String(ApplicationMappingName) === String(undefined) || String(ApplicationMappingName) === '' || String(ApplicationMappingName) === '0') {
          errorJSON[5] = i18n.t('Tool_Selection_Required');
        } else {
          errorJSON[5] = '';
        }
        if (String(MappingDetails.BusinessBenefit) !== String(undefined) && MappingDetails.BusinessBenefit.toString().trim() !== '' && !regexForFields.test(MappingDetails.BusinessBenefit.toString().trim())) {
          errorJSON[6] = i18n.t('Business_benefit_Contains_Special_Character');
        } else {
          errorJSON[6] = '';
        }
        if (String(MappingDetails.url) && String(MappingDetails.url) !== String(undefined)) {
          if (!commonToolRegex.test(MappingDetails.url.trim().toLowerCase())) {
            if (String(MappingDetails.url) !== String(undefined) && MappingDetails.url.toString().trim() !== '' && !regexForToolURL.test(MappingDetails.url.toString().trim().toLowerCase())) {
              errorJSON[7] = i18n.t('Invalid_URL');
            } else {
              errorJSON[7] = '';
            }
          } else {
            errorJSON[7] = i18n.t('commonToolRegex_validatemessage');
          }
        } else {
          errorJSON[7] = '';
        }
        this.setState({
          MappingErrors: {
            ClientName: errorJSON[0], Offering: errorJSON[1], SubOffering: errorJSON[2], Location: errorJSON[3], Levers: errorJSON[4], toolname: errorJSON[5], BusinessBenefit: errorJSON[6], url: errorJSON[7],
          },
        });
        if (String(errorJSON[0]) === '' && String(errorJSON[1]) === '' && String(errorJSON[2]) === '' && String(errorJSON[3]) === '' && String(errorJSON[4]) === '' && String(errorJSON[5]) === '' && String(errorJSON[6]) === '' && String(errorJSON[7]) === '') { isValid = true; }
      } else {
        if (String(MappingDetails.url) && String(MappingDetails.url) !== String(undefined)) {
          if (!commonToolRegex.test(MappingDetails.url.trim().toLowerCase())) {
            if (String(MappingDetails.url) !== String(undefined) && MappingDetails.url.toString().trim() !== '' && !regexForToolURL.test(MappingDetails.url.toString().trim().toLowerCase())) {
              errorJSON[7] = i18n.t('Invalid_URL');
            } else {
              errorJSON[7] = '';
            }
          } else {
            errorJSON[7] = i18n.t('commonToolRegex_validatemessage');
          }
        } else {
          errorJSON[7] = '';
        }
        this.setState({ MappingErrors: { url: errorJSON[7] } });
        if (String(errorJSON[7]) === '') {
          isValid = true;
        } else {
          isValid = false;
        }
      }
      return isValid;
    }

    dealpopup() {
      this.setState({ viewmore: true });
    }

    render() {
      const {
        ClickableToolID, clientname, offeringName, parentofferingID, CapofferingData, ClientOptions, offeringData, MappingDetails, processData, subofferingname, LocationName, SOCategoryName, ToolName, show, popupHeader, isEditable, MappingErrors, LocationOptions, toolLeversList, RoleOptions, lever, viewcatdes, viewmore, leverName, user, ToolDescription, Tooltype, viewdesc, popupSaveButton,
      } = this.state;
      const { data, filterDetails } = this.props;
      const childoffering = [];
      const uniqueParent = [...new Set(parentofferingID)];
      if (data) {
        if (data.IsCapability === true) {
          if (CapofferingData) {
            CapofferingData.filter((eachValue) => uniqueParent.forEach((ele) => {
              if (String(eachValue.OfferingID) === String(ele)) childoffering.push(...eachValue.ChildOfferings);
            }));
          }
        }
      }

      const clientMultipleselectbar = (
        <>

          <select id="Client" className="form-control" onChange={this.onClientChange.bind(this)}>
            <option value="0" selected="">{i18n.t('Select Client')}</option>

            {
                        ClientOptions.map((row) => <option value={row.OrgEntityID} key={row.OrgEntityName}>{row.OrgEntityName}</option>)
                    }
          </select>
        </>
      );

      const offeringMultipleselectbar = (
        <>
          <select id="Offering" className="form-control" onChange={this.OnnewOfferingChange.bind(this)} value={MappingDetails.OfferingID}>
            <option value="0">{i18n.t('Select Offering')}</option>

            {offeringData.map((OD) => <option value={OD.OfferingID}>{OD.OfferingName}</option>)}
          </select>
        </>
      );
      const SubofferingMultipleselectbar = (
        <>
          <select
            id="SubOffering"
            className="form-control"
            onChange={this.OnOfferingChange.bind(this)}
            value={MappingDetails.proID}
          >
            <option value="0">{i18n.t('Select SubOfferings')}</option>
            {processData.map((eachProcess) => <option value={eachProcess.OfferingID} selected>{eachProcess.Process}</option>)}
          </select>

        </>
      );

      const clientOfferingDisabled = (
        <>
          <div className="row">
            <div className="form-group col-lg-6">
              <label htmlFor="">Client Name</label>
              <input type="text" maxLength="200" className="form-control" id="" value={clientname} disabled />
            </div>

            <div className="form-group col-lg-6">
              <label htmlFor="">Offering</label>
              <input type="text" maxLength="200" className="form-control" id="" value={offeringName} disabled />
            </div>

          </div>

        </>
      );

      const SubOfferingLocDisabled = (
        <>
          <div className="row">
            <div className="form-group col-lg-6">
              <label htmlFor="">Sub Offering</label>
              <input type="text" maxLength="200" className="form-control" id="" value={subofferingname} disabled />
            </div>

            <div className="form-group col-lg-6">
              <label htmlFor="">Delivery Center</label>
              <input type="text" maxLength="200" className="form-control" id="" value={LocationName} disabled />
            </div>

          </div>

        </>
      );
      const LeverstoolDisabled = (
        <>
          <div className="row">
            <div className="form-group col-lg-6">
              <label htmlFor="">SynOps Lever</label>
              <input type="text" maxLength="200" className="form-control" id="" value={SOCategoryName} disabled />
            </div>

            <div className="form-group col-lg-6">
              <label htmlFor="">Tool</label>
              <input type="text" maxLength="45" className="form-control" id="" value={ToolName} disabled />
            </div>

          </div>

        </>
      );

      return (

        <>
          {/* <Header /> */}

          <Modal show={show} onHide={this.handleHide} className="modal fade" size="medium" backdrop="static" id="dealpopup">
            <Modal.Header closeButton>
              <h4 className="modal-title">
                {popupHeader}
              </h4>
            </Modal.Header>
            <Modal.Body>

              <form>
                <div className="form-section">

                  {!isEditable ? (
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                          Client Name
                          <span className="required">*</span>
                        </label>
                        {clientMultipleselectbar}

                        <span className="errorMsg">
                          {MappingErrors.ClientName}
                        </span>

                      </div>

                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                          Offering
                          <span className="required">*</span>
                        </label>
                        {offeringMultipleselectbar}

                        <span className="errorMsg">
                          {MappingErrors.Offering}
                        </span>

                      </div>
                    </div>
                  ) : clientOfferingDisabled}

                  {!isEditable ? (
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                          Sub Offering
                          <span className="required">*</span>
                        </label>
                        {SubofferingMultipleselectbar}
                        <span className="errorMsg">
                          {MappingErrors.SubOffering}
                        </span>
                      </div>
                      <div className="form-group col-lg-6">

                        <label htmlFor="">
                          Delivery Center
                          <span className="required">*</span>
                        </label>
                        <select id="location" className="form-control" onChange={this.locationSelect.bind(this)} value={MappingDetails.locID}>
                          <option value="0">{i18n.t('Select Delivery Center')}</option>
                          {LocationOptions.map((loc) => <option value={loc.LocationID}>{loc.City}</option>)}
                        </select>
                        <span className="errorMsg">
                          {MappingErrors.Location}
                        </span>
                      </div>
                    </div>
                  ) : SubOfferingLocDisabled}

                  {!isEditable ? (
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                          {i18n.t('SynOps Lever')}
                          {' '}
                          <span className="required">*</span>
                        </label>
                        <select id="lever" className="form-control" onChange={this.leverselect.bind(this)} value={lever.leverId}>
                          <option value="0">{i18n.t('Select Lever')}</option>
                          {toolLeversList.map((eachLever) => <option value={eachLever.ID}>{eachLever.Value}</option>)}
                        </select>
                        <span className="errorMsg">
                          {MappingErrors.Levers}
                        </span>
                      </div>
                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                          Tool
                          <span className="required">*</span>
                        </label>
                        <select id="tool" className="form-control" onChange={this.roleSelect.bind(this)} value={ClickableToolID}>
                          <option value="0">{i18n.t('Select Tool')}</option>
                          {RoleOptions.map((role) => <option value={role.ClickableToolID}>{role.ApplicationName}</option>)}
                          {lever.leverId && lever.leverId !== '0' ? (
                            <option value="-1">
                              &lt;
                              {i18n.t('Add_New')}
                              &gt;
                            </option>
                          ) : null}
                        </select>
                        <span className="errorMsg">
                          {MappingErrors.toolname}
                        </span>

                      </div>
                    </div>
                  ) : LeverstoolDisabled}

                  {viewcatdes
                    ? (
                      <>
                        <div className="row">

                          <div className="form-group col-lg-6">
                            <label htmlFor="">Tool Category</label>
                            <input type="text" maxLength="200" className="form-control" id="" value={Tooltype} readOnly />
                          </div>

                          <div className="form-group col-lg-6">
                            <label htmlFor="">Tool Description</label>
                            <input id="textdesc" type="text" maxLength="70" className="form-control" onChange={(e) => { this.bindToolFieldChange(e); }} value={ToolDescription} readOnly={viewdesc} />
                          </div>

                        </div>

                        <div className="row">
                          <div className="form-group col-lg-6">
                            <label htmlFor="">Business Benefit</label>
                            <input id="txtToolbusinessreason" type="text" maxLength="255" className="form-control" onChange={(e) => { this.bindToolFieldChange(e); }} value={MappingDetails.BusinessBenefit} />
                            <span className="errorMsg">
                              {MappingErrors.BusinessBenefit}
                            </span>
                          </div>

                        </div>
                        <div className="row">
                          <div className="form-group col-lg-12">
                            <label htmlFor="">
                              {i18n.t('Default User ID')}
                              <span className="required" />
                            </label>
                            <div className="input-group">
                              <input id="txtTooldefaultuserid" type="text" maxLength="100" className="form-control" onChange={(e) => { this.bindToolFieldChange(e); }} value={MappingDetails.DefaultUserID} />
                            </div>

                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-lg-12">
                            <label htmlFor="">URL</label>
                            <input id="txtToolUrl" type="url" maxLength="5000" className="form-control" onChange={(e) => { this.bindToolFieldChange(e); }} value={MappingDetails.url} />
                            <span className="errorMsg">
                              {MappingErrors.url}
                            </span>
                          </div>

                        </div>
                      </>
                    ) : null}
                </div>

              </form>

            </Modal.Body>
            <Modal.Footer>
              <div className="modal-btn">
                <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value={popupSaveButton} />
              </div>
            </Modal.Footer>
          </Modal>
          {viewmore ? (
            <ToolsPopUP
              SOCategoryID={lever.leverId}
              SOCategoryName={leverName}
              show
              onClose={this.onClose}
              clickableclose={this.clickableclose}
              filterDetails={filterDetails}
              clientname={clientname}
              clientID={user.ClientIds}
              offeringName={offeringName}
              subofferingname={subofferingname}
              offeringID={MappingDetails.proID}
              LocationName={LocationName}
              LocationID={MappingDetails.locID}
            />
          ) : null}
        </>
      );
    }
}
ClickableToolPopup.defaultProps = {
  show: false,
  filterDetails: [],
  rowData: [],
  data: {},
  tooldatatype: '',
  onSave: () => {},
  onClose: () => {},
  onRejectClose: () => {},
  onApproveClose: () => {},
  enteredData: () => {},
};
ClickableToolPopup.propTypes = {
  show: PropTypes.bool,
  filterDetails: PropTypes.array,
  rowData: PropTypes.array,
  data: PropTypes.object,
  tooldatatype: PropTypes.string,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  onRejectClose: PropTypes.func,
  onApproveClose: PropTypes.func,
  enteredData: PropTypes.func,
};
