import React from "react";
import i18n from "i18next";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import {
  LocalApiBaseUrl
} from "../Shared/Constant";
import jquery from "jquery";
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import HumanPopUp from "../Configurator/Humanconf_popup";
import InsightsDataPopUp from "../Configurator/InsightsDataPopUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MetricComments from "../Dashboard/MetricComments";
import {ValueUnit, ValueUnitNoRoundOff, MetricValueUnitFormat, toolThreshold,CommonMetriTileFix}   from "../Shared/Common";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  faPlus,
  faTrashAlt,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import $ from 'jquery';


const options = {
  loop: true,
  margin: 24,
  responsiveClass: "",
  dots: false,
  responsive: {
    0: {
      items: 1,
      nav: true
    },
    600: {
      items: 2,
      nav: false
    },
    1000: {
      items: 3,
      nav: true,
      loop: false
    },
    1440: {
      items: 4,
      nav: true,
      loop: false
    }
  }
};
window.$ = window.jQuery = jquery;

class InsightIntelligenceDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      viewmore: false,
      selectedSocategoryId: 0,
      kpiData: [],
      SODetails: [],
      AllCategoryData: [],
      commentPopUp: null,
      Description: ""
    };
    this.ellipsisTruncate = this.ellipsisTruncate.bind(this);
  }
  ellipsisTruncate(str) {
    return str.length > 60 ? str.substring(0, 60) + "..." : str;
  }
  MetricValueUnitFormat =(Unitvalue,AttributeValueDigit)=>{
    debugger;
    Unitvalue=Unitvalue.trim();
    let returnValue="";
    if(AttributeValueDigit!=null || AttributeValueDigit!=null){
      if((Unitvalue === "$" || Unitvalue === "₹" || Unitvalue === "R$" || Unitvalue === "€" ||Unitvalue === "£" || Unitvalue === "SGD")){
        returnValue=  Unitvalue+" " + (AttributeValueDigit!=null ? AttributeValueDigit:"")
 
      }else if(Unitvalue === "$ Mn" || Unitvalue === "₹ Mn"||Unitvalue === "R$ Mn" || Unitvalue === "€$ Mn"||Unitvalue === "SGD Mn")
      {
        returnValue=Unitvalue.split(" ")[0]+" " + (AttributeValueDigit!=null ? AttributeValueDigit:"") + "Mn"
      }
      else{
        returnValue=AttributeValueDigit+" " + Unitvalue
      }
 
    }
    return returnValue;}
  dealpopup = (data, Id, SOCategoryID, e) => {
    e.preventDefault();
    this.setState({
      viewmore: Id,
      kpiData: data,
      selectedSocategoryId: SOCategoryID
    },()=>{
      this.props.triggerViewMore()
    });
  };

  onClose = (e) => {
    this.setState({ viewmore: false }, () => {
      this.props.onClose();
    });
  };
  onCommentClick = (val, data) => {
    //e.preventDefault();

    if (val === 1) {
      this.setState({
        commentPopUp: data,
      });
    } else {
      this.setState({
        commentPopUp: "",
      });
    }
  };
  deleteKpi(data, e) {
    this.props.triggerViewMore()
    if (window.confirm("Are you sure you want to delete metric detail?")) {
      data.SOCategoryName = this.props.SODetails.SOCategoryName;
      //e.stopPropagation();
      axiosInstance.delete(`${LocalApiBaseUrl}Configurator/DeleteDealMetrics?DealMetricsId=${data.ConfigurationDealMetricID}`
      )
        .then((response) => {
          if (response.data) {
            axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetInsightsIntelligenceQualityScore`, {
              params: {
                LocationID: this.props.filterDetails.Locations,
                OrgEntityID: this.props.filterDetails.Client,
                OfferingID: this.props.filterDetails.process,
                MonthDT: this.props.filterDetails.Month,
                LanguageID: store.getState().CurrentFilter.languageData.id
              }
            }
            )
              .then((response) => {
                if (response.data) {
                  store.dispatch({
                    type: "SAVE_QC_SCORE_OF_EACH_TAB",
                    payload: response.data
                  });
                }


              }).catch((error)=>{
trycatchAlertPopup(error);

              });
            store.dispatch({
              type: "REMOVE_KPI",
              payload: data,
            });
          }
        }).catch((error)=>{
trycatchAlertPopup(error);

        });

    }
  }

  componentDidMount(){
    CommonMetriTileFix();
    this.setState({
      SODetails: this.props.SODetails,
    });
  }
  render() {
    return this.props.Details.map((eachDetails) => {
      let AttrValue = "";
      let metricName = "";
      let unit = "";
      let metricData = "";
      //let Description = "";
      let viewmore = false;
      let leadingIndustryValue = "";
      let similarIndustryValue = "";
      let ValueBlock = true;
      //let ToolTip = "";
      let info = "";
      let KPIMappingID = "";
      let RelatorBlock = this.props.isRelatorBlock;
      let toolName = "";
  		let MetricValueUnitFormatData = "";
      let middleAlign = "";

      if (eachDetails != undefined) {
        metricName = eachDetails.MetricName;
        unit = eachDetails.MetricUnit;
        unit = unit.trim();
        //leadingIndustryValue = eachDetails.metDataAttr?eachDetails.metDataAttr[0].AttributeValue:null
        //similarIndustryValue = eachDetails.metDataAttr?eachDetails.metDataAttr[1].AttributeValue:null

        if (eachDetails.metDataAttr && eachDetails.metDataAttr.length > 0) {
          leadingIndustryValue = eachDetails.metDataAttr[0] ? eachDetails.metDataAttr[0].AttributeValue : "";

          similarIndustryValue = eachDetails.metDataAttr[1] ? eachDetails.metDataAttr[1].AttributeValue : "";

          //For old data which has Attribute name and attribute value as empty strings/ null
          if(eachDetails.metDataAttr[0]){
          eachDetails.metDataAttr[0].AttributeName =
            this.props.attributes && this.props.attributes.length > 0
              ? this.props.attributes.filter(z => z.ApplicableForDD === false).length>0 ? 
                this.props.attributes.filter(z => z.ApplicableForDD === false)[0].AttributeName1
                :""
              : eachDetails.metDataAttr[0].AttributeName;
          }

          if(eachDetails.metDataAttr[1]){
          eachDetails.metDataAttr[1].AttributeName =
            this.props.attributes && this.props.attributes.length > 0
              ? this.props.attributes.filter(z => z.ApplicableForDD === false).length>0 ?
                this.props.attributes.filter(z => z.ApplicableForDD === false)[0].AttributeName2
                :""
              : eachDetails.metDataAttr[1].AttributeName;
          }
        }
      }

      let statusColor = "item gray-color";

      if(unit.includes('$') || unit.includes('€') || unit.includes('£') || unit.includes('₹') || unit.includes('SGD') || unit.includes('R$')) {
        metricData = ValueUnitNoRoundOff(eachDetails.MetricValue);
        } 
        else {
          metricData = ValueUnit(eachDetails.MetricValue);
        }
			MetricValueUnitFormatData = MetricValueUnitFormat(unit,metricData);
      middleAlign = (!leadingIndustryValue || leadingIndustryValue.toUpperCase() == "NA") && (!similarIndustryValue || similarIndustryValue.toUpperCase() == "NA")|| !RelatorBlock?
        ' middle-align' :'';
      KPIMappingID = eachDetails.KPIMappingID;
      this.state.Description = eachDetails.KPIDesp ? eachDetails.KPIDesp : "";
      // info = eachDetails.Comments ? eachDetails.Comments : "NA";
      unit = eachDetails.MetricUnit;
      unit = unit.trim();
      toolName = (
        eachDetails.ApplicationMappingName != null && eachDetails.ApplicationMappingName.length > 0 ?
          eachDetails.ApplicationMappingName : (
            eachDetails.ProjectName != null && eachDetails.ProjectName.length > 0 ? eachDetails.ProjectName : ""
          )
      );
      if (eachDetails != undefined) {
    

      if (eachDetails.RAGStatus != undefined && eachDetails.RAGStatus != null) {
        switch (eachDetails.RAGStatus.toLowerCase()) {
          case "grey":
            statusColor = "item gray-color";
            break;
          case "red":
            statusColor = "item red-color";
            break;
          case "orange":
            statusColor = "item amber-color";
            break;
          case "green":
            statusColor = "item green-color";
            break;
          case "amber":
            statusColor = "item amber-color";
            break;
          default:
        }
      }
    }
      //   const formatter=new Intl.NumberFormat('en',{//CODE FOR ALL TYPE OF CURRENCY FORMAT
      //     style:'currency',
      //     currency:'USD',
      //     notation:'standard'
      // })
      const formattertwo = new Intl.NumberFormat('en', {
        useGrouping: true
      })
      // style={{fontWeight: 'normal'}}
      return (

        <div
          className={`${statusColor}`}
          data-place="bottom"
          data-tip={toolThreshold(eachDetails)}
        >


          <div className="item-left">

          {ValueBlock ? (
              <>
                 <p className="tile-metric-value">
                 {MetricValueUnitFormatData}

                 {/* {this.MetricValueUnitFormat(unit,metricData)}   */}
                </p>
              </>
            ) : null}
              {this.state.commentPopUp === KPIMappingID ? (
                <MetricComments
                  // kpiName={metricName}
                  isRelatorBlock={this.props.isRelatorBlock}
                  info={eachDetails}
                  show={true}
                  onClose={this.onCommentClick.bind(this, 2, "null")}
                />
              ) : null}

            <p className="tile-metric-desc" data-tip data-for={'customTooltip' + eachDetails.KPIMappingID}>
              {/* {metricName.length > 91 ? metricName.slice(0, 91) + "..." : metricName} */}
              {metricName}
            </p>
          </div>
         
          <div className={RelatorBlock?'item-right isRelatorBlock'+middleAlign:'item-right'+middleAlign}>
            {
            
              toolName.length == 0 ? <p className="mt-2 mb-4"></p> : <p
              
               className="tile-metric-name"><span className="toolname-span"> 
                 {/* {toolName.length > 20 ? toolName.slice(0,20) + "..." : toolName}  */}
                 <p>{toolName}</p>
                 </span>
               
               </p>
            }

            {RelatorBlock && (
              <div className="metric-lead-lagg">

                {leadingIndustryValue &&
                  leadingIndustryValue.toUpperCase() !== "NA" && (
                    <p>
                        <span className="lead-lagg-label">{`${this.props.attributes.filter(z => z.ApplicableForDD === false).length>0 ?
                                                           this.props.attributes.filter(z => z.ApplicableForDD === false)[0].AttributeName1
                                                          :""}`}</span>
                        <span className="lead-lagg-val">{`${leadingIndustryValue}`}</span>
                    </p>
                  )}

                {similarIndustryValue &&
                  similarIndustryValue.toUpperCase() !== "NA" && (
                    <p>
                      <span className="lead-lagg-label">{`${this.props.attributes.filter(z => z.ApplicableForDD === false).length>0 ?
                                                          this.props.attributes.filter(z => z.ApplicableForDD === false)[0].AttributeName2
                                                        :""}`}</span>
                      <span className="lead-lagg-val">{`${similarIndustryValue}`}</span>
                    </p>
                  )}
              </div>



            )}


           
            <div className="dropdown dropleft ondropOpenColor">
              <span type="button" className="dropdown-toggle" data-toggle="dropdown"></span>
              <div className="dropdown-menu">
                <a href="javascript:void(0)" className="dropdown-item" onClick={this.onCommentClick.bind(this, 1, KPIMappingID)}>
                  <span className="info-icon"></span>{i18n.t("Info")}
                </a>
                {(this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") ||
                  (this.props.RecordStatus &&
                    this.props.RecordStatus[1] === "True" &&
                    !this.props.isManintencneWindowOpen) ||
                  (this.props.RecordStatus &&
                    this.props.RecordStatus[0] === "Publish" &&
                    !this.props.isManintencneWindowOpen) ||
                  this.props.roleReducers.filter((each) => {
                    if (each.FeatureName === "Configurator" && each.Operations.length) {
                      return each;
                    }
                  }).length === 0 ||
                  (this.props.RecordStatus &&
                    this.props.RecordStatus[0] === "Save" &&
                    this.props.roleReducers.filter((each) => {
                      if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Submit").length) {
                        return each;
                      }
                    }).length === 0) ||
                  (this.props.RecordStatus &&
                    this.props.RecordStatus[0] === "Submit" &&
                    this.props.roleReducers.filter((each) => {
                      if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Publish").length) {
                        return each;
                      }
                    }).length === 0) ? (
                  ""
                ) : (
                  <>
                    <a
                      href="javascript:void(0)"
                      className="dropdown-item"
                      onClick={this.deleteKpi.bind(this, eachDetails)}
                    >
                      <span className="delete-icon"></span>{i18n.t("Remove")}
                    </a>
                    <a
                      href="javascript:void(0)"
                      className="dropdown-item"
                      onClick={this.dealpopup.bind(this, eachDetails, KPIMappingID, this.props.SODetails.SOCategoryID)}
                    >
                      <span className="edit-icon"></span>{i18n.t("Edit")}
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* <div className="dropdown dropleft">
                      <span type="button" className="dropdown-toggle" data-toggle="dropdown"></span>
                      <div className="dropdown-menu">
                          <a href="javascript:void(0)" className="dropdown-item">
                            <span className="info-icon"></span>Info
                          </a>
                          <a href="javascript:void(0)" className="dropdown-item">
                            <span className="delete-icon"></span>Remove
                          </a>
                          <a href="javascript:void(0)" className="dropdown-item">
                            <span className="edit-icon"></span>Edit
                          </a>
                          
                      </div>
                  </div> */}


          {this.state.viewmore === KPIMappingID ? (
            <InsightsDataPopUp
              tool={this.props.tool}
              SOCategoryID={this.state.selectedSocategoryId}
              type={this.props.Name}
              isRelatorBlock={this.props.isRelatorBlock}
              kpiData={this.state.kpiData}
              SODetails={this.state.SODetails}
              show={this.state.viewmore}
              onClose={this.onClose.bind(this)}
              opsgrp={this.props.opsgrp}
              filterDetails={this.props.filterDetails}
              attributes={this.props.attributes}
              setCategory={this.props.setCategory}
            />
          ) : null}
        </div>
      );
    });
  }
}
const mapStateToProps = (state) => {
  return {
    roleReducers: state.roleReducers.roleReducers,
  };
};


export default connect(mapStateToProps, "")(InsightIntelligenceDetails);