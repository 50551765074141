import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import { connect } from "react-redux";
import { Radar } from "react-chartjs-2";
import _ from "lodash";
import i18n from "i18next";
import './QCScore.css';
// import GPH from "../Images/gph.png";
import Supportimage from '../Images/support-image.svg';
import {
    LocalApiBaseUrl,
    DomainBaseUrl,
    ProfilePic,
    LocalApiBaseUrlImage,
    PeoplePage,
} from "../Shared/Constant";
import parse from 'react-html-parser';



class OverallQCScore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            summaryHelpStatus: false,
            QcOverallScore:{},

            newOverAllScore:0,
            chatData:{},
            hoverdValue:false,
            AutomationSummaryDatas:[],
            hoveredValueIndex:0,
            
            RandomOptions : {
                // legend: {
                //   display: false,
                // },
                maintainAspectRatio: false,
                scale: {
                  pointLabels: {
                    fontColor: "#3c4858",
                    fontFamily: "graphik",
                    fontSize: 12,
                    // fontStyle: "bold",
                  },
                  reverse: false,
                  ticks: {
                    align:'left',
                    beginAtZero: true,
                     //max:10,
                    // min: 0,
                    stepSize: 25,
                    fontColor: "#4a4a4a",
                    fontSize: 10,
                    fontStyle: "normal",
                    fontFamily: "graphik",
                    z: 0,
                    padding: 10,
                  },
                },
                plugins: {
                  datalabels: {
                    display: false,
                  },
                },
                tooltips: {
                    enabled: true,
                    backgroundColor: '#ffffff00',
                    custom : function(tooltipModel) 
                 {
                    if( tooltipModel.opacity > 0 ) {

                    } else {
                        this.clearHoverdValues()
                    }
                //   tooltipModel.opacity = 0;
                 }.bind(this),
                    callbacks: {
                        label: function (tooltipItems, data) {
                            this.onDataHover(tooltipItems,data);
                            return;
                        }.bind(this),
                        title: function(tooltipItem, data) {
                            return "";
                          },
                          labelColor: function(tooltipItem, chart) {
                            return {
                                backgroundColor: '#ffffff00'
                            }
                        },
                    }
                },
                
              }
        };
        this.summaryHelpModalShow = this.summaryHelpModalShow.bind(this);
        this.summaryHelpModalClose = this.summaryHelpModalClose.bind(this);
      
        this.QCScoreModalClose = this.QCScoreModalClose.bind(this);

    }
    summaryHelpModalShow() {
        this.setState({ summaryHelpStatus: true });
    }
    summaryHelpModalClose() {
        this.setState({ summaryHelpStatus: false });
    }
   
    QCScoreModalClose() {
        this.setState({ QCScoreModalStatus: false });
    }
    clearHoverdValues = ()=>{
        debugger
        this.setState({hoverdValue:false})
    }
    onDataHover =(tooltipItems,data)=>{
        let datasetIndex = tooltipItems.datasetIndex;
        let selectedIndex = tooltipItems.index;
        // let datasets = data.datasets[datasetIndex]
        
        if(datasetIndex === 0){
            // this.props.QcOverallScore.QualityScoreDatas.QualityScoreTabDatas[selectedIndex]
            let value = tooltipItems.value;
            let chatData = this.state.chatData;
              
                chatData.datasets[0].pointHoverBackgroundColor = value < 50 ? "#d5002f" : (value >= 50 && value <= 75) ? "#e6b019" : " #7ec344";
                chatData.datasets[0].pointHoverBorderColor = value < 50 ? "#d5002f" : (value >= 50 && value <= 75) ? "#e6b019" : " #7ec344";
                chatData.datasets[0].pointHoverColor = value < 50 ? "#d5002f" : (value >= 50 && value <= 75) ? "#e6b019" : " #7ec344";
                chatData.datasets[0].pointBackgroundColor = value < 50 ? "#d5002f" : (value >= 50 && value <= 75) ? "#e6b019" : " #7ec344";
                chatData.datasets[0].pointHighlightStroke = value < 50 ? "#d5002f" : (value >= 50 && value <= 75) ? "#e6b019" : " #7ec344";
            
            
            this.setState({chatData:chatData,hoverdValue:true,hoveredValueIndex:selectedIndex})
        }
        
    }
    getOverAllQcScore = () =>{
        axiosInstance
        .get(`${LocalApiBaseUrl}Configurator/GetQualityScoreAutomationSummaryData`, {
          params: {
            OrgEntityID: this.props.clientData.id,
            LocationID: this.props.currentFilter.LocationId,
            MonthDT: this.props.currentFilter.Month,
            OfferingID: this.props.currentFilter.ProcessId,
            LanguageID: store.getState().CurrentFilter.languageData.id
            
          },
        })
        .then((response) => {
          if (response.data.length){
            store.dispatch({
                type: "SAVE_QUALITY_SCORE_AUTOMATION_SUMMARY_DATA",
                payload: response.data,
              });
              
          }
            
        }).catch((error) => {
            trycatchAlertPopup(error);
          });
    }
    saveQcScoreData(){
        let sum  = 0;
        let avg =0;

        let DataDD = [];
            let lablesData = [];
          let  tragetSocreData = [];
          let  acheivedScoreData = [];
          if(this.props.QcOverallScore && this.props.QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.length){
           this.props.QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.map((each)=>{
            lablesData.push(each.LeverName);
            tragetSocreData.push(each.TargetScore);
            acheivedScoreData.push(each.AchievedScore);


            if(this.props.FetchFromBAT){
                if(each.LeverName !== 'Data Publish'){
                each.LeverName == 'Diverse Data' ? DataDD.push(each.AchievedScore_BAT): DataDD.push(each.AchievedScore);
                }
                for(var i in DataDD){
                  
            sum += DataDD[i];
            
                }
                avg = Math.round(sum/DataDD.length);
          
                sum = 0;
          
            
                }
          
                else{
                  if(each.LeverName !== 'Data Publish'){
          
                    DataDD.push(each.AchievedScore );
                  }
                    for(var i in DataDD){
                    sum += DataDD[i];
                    }  
                
                avg = Math.round(sum/DataDD.length);
          
                sum = 0;
                  }
                      
            
          

           })
           this.setState({newOverAllScore:avg});

        }
    
    
    
           let Data = {
            labels: lablesData,
            datasets: [
                {
                    label: "Achieved Score",
                    backgroundColor: "rgba(156, 106, 222, 0.2)",
                    pointBackgroundColor: "#9c6ade",
                    pointHighlightStroke: "#9c6ade",
                    borderColor: "#9c6ade",
                    data: acheivedScoreData,
                    borderWidth: 2,
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "#9c6ade",
                    pointHoverBorderColor: "#9c6ade",
                    pointHoverBorderWidth: 2,
                    pointRadius: 0,
                    pointHitRadius: 5
                  },
              {
                label: "Target Score",
                backgroundColor: "rgba(0, 186, 255, 0.2)",
                pointBackgroundColor: "#00baff",
                pointHighlightStroke: "#00baff",
                borderColor: "#00baff",
                data:tragetSocreData,
                borderWidth: 2,
                pointBorderWidth: 0,
                pointHoverRadius: 0,
                pointHoverBackgroundColor: "#00baff",
                pointHoverBorderColor:  "#00baff",
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 0
              },
              
            ],
          };
          let row = [];
          let rowdata = [];
          {this.props.QcOverallScore && this.props.QcOverallScore.AutomationSummaryDatas && this.props.QcOverallScore.AutomationSummaryDatas.map((each,index)=>{
           if( index === 0 || (index+1) % 3 ===0){
            if(index !== 0 ){
                row.push(rowdata);
            }
            rowdata=[]
              rowdata.push(<div className={each.ToolParametersData.length === 4 ? "automation-summary summary-large" : "automation-summary summary-small"}>
              <div className="automation-heading">
                  <img src={"data:image/png;base64," + each.ToolIconImage} alt="" width="48" height="48"></img>
          <h5>{each.ToolName}</h5>
              </div>
              <div className="automation-categories">
                  <ul className={each.ToolParametersData.length === 4 ? "two-columnlayout" : ""}>
                      {each.ToolParametersData.map((tool)=>{
                          return (
                              <li>
                              <p>
                                  <span className="automation-icon"><img src={"data:image/png;base64," + tool.ParameterIconImage} alt="" width="20" height="20"></img></span>
                                {tool.ParameterName}:
                                <span className={tool.ParameterValue === "Done" ? "automation-status done-status" : (tool.ParameterValue === "Not Done" ? "automation-status notdone-status" : (each.ToolParametersData.length === 4  && tool.ParameterName_En === "Integrated"  && tool.ParameterValue  < each.ToolParametersData.filter((val)=> val.ParameterName_En === "Identified")[0].ParameterValue ? "automation-status integrated-status" : "automation-status"))}> {tool.ParameterValue}</span></p>
                          </li>
                          )
                      })}
                  </ul>
              </div>
          </div>
          
        )
        if(index +1 === this.props.QcOverallScore.AutomationSummaryDatas.length ){
            row.push(rowdata);
        }
           }else{
           
               rowdata.push(<div className={each.ToolParametersData.length === 4 ? "automation-summary summary-large" : "automation-summary summary-small"}>
               <div className="automation-heading">
                   <img src={"data:image/png;base64," + each.ToolIconImage} alt="" width="48" height="48"></img>
           <h5>{each.ToolName}</h5>
               </div>
               <div className="automation-categories">
                   <ul className={each.ToolParametersData.length === 4 ? "two-columnlayout" : ""}>
                       {each.ToolParametersData.map((tool)=>{
                           return (
                               <li>
                               <p>
                                   <span className="automation-icon"><img src={"data:image/png;base64," + tool.ParameterIconImage} alt="" width="20" height="20"></img></span>
                                 {tool.ParameterName}:
                                 <span className={tool.ParameterValue === "Done" ? "automation-status done-status" : (tool.ParameterValue === "Not Done" ? "automation-status notdone-status" : (tool.ParameterValue === "Integrated" ? "automation-status integrated-status" : "automation-status"))}> {tool.ParameterValue}</span></p>
                           </li>
                           )
                       })}
                   </ul>
               </div>
           </div>
         )
         if(index +1 === this.props.QcOverallScore.AutomationSummaryDatas.length ){
            row.push(rowdata);
        }
           }
           
          })}
          
          this.setState({QcOverallScore:this.props.QcOverallScore,chatData:Data,AutomationSummaryDatas:row}) 
    }
        
    componentDidUpdate(prevProps){
        if((prevProps.currentFilter !== this.props.currentFilter || prevProps.clientData !== this.props.clientData) && this.props.clientData  && this.props.currentFilter && this.props.currentFilter.ProcessId  && this.props.clientData.id){
this.getOverAllQcScore()
        }
        if(prevProps.QcOverallScore !== this.props.QcOverallScore &&  this.props.QcOverallScore && this.props.QcOverallScore.QualityScoreDatas && this.props.QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.length){
            this.saveQcScoreData() 
        }
        
    }
    componentDidMount() {
        if(this.props.currentFilter && this.props.currentFilter && this.props.clientData && this.props.currentFilter.ProcessId  && this.props.clientData.id){
            this.getOverAllQcScore()
         }
         if(  this.props.QcOverallScore && this.props.QcOverallScore.QualityScoreDatas && this.props.QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.length){
        this.saveQcScoreData() 
        }
    }

    parseHTML(htmlString = ''){
        if(htmlString && typeof htmlString === 'string'){
            return <div>{parse(htmlString)}</div>
        }
        return null
    }



//   achscore =  this.state.QcOverallScore.QualityScoreDatas.map((eachItem)=>{
//     if
//         return eachItem.AchievedScore
//     })


    render() {
       
        return (
            this.state.QcOverallScore && this.state.QcOverallScore.QualityScoreDatas ? <>
           
                <div className="overallqcscore-section">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="overallqcscore-heading">
                                <h5>Overall QC Score</h5>
                                <div className="overallqcscore-close">
                                    <NavLink to="/admin/Configurator" className="close-icon"><i className="fas fa-times"></i>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="qcscore-accordions">
                                <div id="accordion" className="qcscore-accordion">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="card-link" data-toggle="collapse" href="#collapse1">
                                                <div><i class="fas fa-caret-right"></i></div>
                                                <div className="header-content">
                                                    <div className="header-link">
                                                        <h6>Quality Score</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="qcscore-status">
                                                <span className={ this.state.QcOverallScore.QualityScoreDatas.OverallScore< 50 ? "qcscore-percent red-status" : ((this.state.QcOverallScore.QualityScoreDatas.OverallScore >= 50 && this.state.QcOverallScore.QualityScoreDatas.OverallScore <= 75) ? "qcscore-percent orange-status":"qcscore-percent green-status")}>Overall Score {this.state.QcOverallScore.QualityScoreDatas ? this.state.newOverAllScore + this.state.QcOverallScore.QualityScoreDatas.Unit : "0%" } </span>
                                            </div>
                                        </div>
                                        <div id="collapse1" class="collapse show" data-parent="#accordion">
                                            <div class="card-body">
                                                <div className="qualityscore-section">
                                                    <div className="qualityscore-graph">
                                                    <Radar data={this.state.chatData} options={this.state.RandomOptions} width={435} height={281} />
                                                    </div>
                                                    <div className="qualityscore-data">
                                                        {this.state.QcOverallScore.QualityScoreDatas && this.state.QcOverallScore.QualityScoreDatas.OverallScore === 0 ?
                                                         <div className="empty-qualityscore-data">
                                                          <p>Please start configuring your deal 
                                                            to reach your target</p>
                                                      </div>  : <> {!this.state.hoverdValue ?
                                                         <div className="score-summary">
                                                          <div className="score-summary-heading">
                                                          <h5>Score Summary</h5>
                                                          <div>
                                                              <span onClick={this.summaryHelpModalShow}><i class="far fa-question-circle"></i></span>
                                                          </div>
                                                          </div>
                                                          <ul>
                                                          {this.props.QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.map(each =>{
                                                                 
                                                                 return  <li className={each.AchievedScore < 50 ? "red-percent" : ((each.AchievedScore >= 50 && each.AchievedScore <= 75) ? "orange-percent":"green-percent")}><span>{this.props.FetchFromBAT && each.LeverName ==='Diverse Data'? each.AchievedScore_BAT: each.AchievedScore}{each.Unit}</span> - {each.LeverName}</li>
                                                                 
                                                                })}    
                                                          </ul>
                                                            </div> :
                                                         <div className="qcscore-outputdata">
                                                         {this.props.QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.map((each,i) =>{
                                                                 if(i === this.state.hoveredValueIndex){
                                                        return <>
                                                            <div className="score-summary-heading">
                                                            <h5>{each.LeverName}</h5>
                                                                <div>
                                                                    <span onClick={this.summaryHelpModalShow}><i class="far fa-question-circle"></i></span>
                                                                </div>
                                                            </div>
                                                            <div className="qcscore-graphdata">
                                                                <span className={each.AchievedScore < 50 ? "qcscore-percent red-status" : ((each.AchievedScore >= 50 && each.AchievedScore <= 75) ? "qcscore-percent orange-status":"qcscore-percent green-status")}>
                                                                    <span>{each.AchievedScore}{each.Unit}</span> Achieved Score
             </span>
                                                                <span className="qcscore-percent blue-status">
                                                                    <span>{each.TargetScore}{each.Unit}</span> Target Score
             </span>
                                                            </div>
                                                            <div className="qcscore-graphhelp">
                                                            <h6>Help</h6>
                                                                 <div>{this.parseHTML(each.HelpText)}</div>
                                                           
                                                            </div></>}})}
                                                        </div> } </>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="card-link" data-toggle="collapse" href="#collapse2">
                                                <div><i class="fas fa-caret-right"></i></div>
                                                <div className="header-content">
                                                    <div className="header-link">
                                                        <h6>Automation Summary</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="qcscore-status">
                                                                 <span className="qcscore-percent">{this.state.QcOverallScore ? this.state.QcOverallScore.AutomationSummaryDatas.length+ " " : "0 "} Tools Integrated</span>
                                            </div>
                                        </div>
                                        <div id="collapse2" class="collapse" data-parent="#accordion">
                                            <div class="card-body">

                                            {this.state.AutomationSummaryDatas.length === 0  ?
                                                <div className="empty-qualityscore-section">
                                                          <p>Please integrate & configure your deal tools
                                                          to increase Automation</p>
                                                </div> :
                                                 
                                                 this.state.AutomationSummaryDatas.map((each,index)=>{
                                                    return (
                                                      <div className={"automation-summary-section"}>
                                                          {each.map((val)=>{
                                                              return val;
                                                          })}
                                                    
                                                </div>)})}
                                               
                                         
                                              

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.summaryHelpStatus}
                    onHide={this.summaryHelpModalClose}
                    className="modal fade helpsummary-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Help</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.QcOverallScore.QualityScoreDatas && 
                        Array.isArray(this.state.QcOverallScore.QualityScoreDatas.HelpText) && 
                        this.state.QcOverallScore.QualityScoreDatas.HelpText.length ?
                        <div className="helpsummary-list">
                            <ul className="main-helpsummary-list">

                                <li className="helpsummary-item">Business Outcome
                                    {this.parseHTML(this.state.QcOverallScore.QualityScoreDatas.HelpText[0])}
                                </li>
                                <li className="helpsummary-item">Human + Machine Talent
                                    {this.parseHTML(this.state.QcOverallScore.QualityScoreDatas.HelpText[1])}
                                </li>
                                <li className="helpsummary-item">Work Orchestration
                                    {this.parseHTML(this.state.QcOverallScore.QualityScoreDatas.HelpText[2])}
                                </li>
                                <li className="helpsummary-item">Insights & Intelligence
                                    {this.parseHTML(this.state.QcOverallScore.QualityScoreDatas.HelpText[3])}
                                </li>
                                <li className="helpsummary-item">Diverse Data
                                    {this.parseHTML(this.state.QcOverallScore.QualityScoreDatas.HelpText[4])}
                                </li>
                                <li className="helpsummary-item">Intelligent Operations Journey
                                {this.parseHTML(this.state.QcOverallScore.QualityScoreDatas.HelpText[5])}
                                </li>
                                <li className="helpsummary-item">Pulse Page
                                {this.parseHTML(this.state.QcOverallScore.QualityScoreDatas.HelpText[6])}
                                </li>
                                <li className="helpsummary-item">Data Publish
                                {this.parseHTML(this.state.QcOverallScore.QualityScoreDatas.HelpText[7])}
                                </li>
                            </ul>
                        </div> : null}
                    </Modal.Body>
                </Modal>
               
            </> : <></>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        QcOverallScore: state.CurrentFilter.QcOverallScore,
        currentFilter : state.CurrentFilter.SyncFilter,
        clientData: state.CurrentFilter.ClientData,
        FetchFromBAT: state.CurrentFilter.FetchFromBAT,
    };
  };
  
  
  export default connect(mapStateToProps,"")(OverallQCScore);
