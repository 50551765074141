import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { LocalApiBaseUrl, regexForTextAreas } from '../Shared/Constant';

import i18n from "i18next";
import axiosInstance from '../Shared/interceptor';
import { store } from "../reducers/configureStore"
import Header from '../Shared/Header';
import { regexForConfMasterFields } from '../Shared/Constant';


axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;




export default class LocationPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false, apivalue: "", selectValue: this.props.SelectValue, Display: false,
            location: {
                LocationId: "0",
                Region: null,
                Country: null,
                City: "",
                RequestorRemarks:"",
                ApproverRemarks:"",
                IsActive: false,
                CreatedUser: null,
                CreatedTS: null,
                UpdatedUser: null,
                UpdatedTS: null,
                DealInfoes: null,
                Status:"",
            },
            errors: [],
            formIsValid: false,


        }

        this.onLocationChange = this.onLocationChange.bind(this);
        this.onRemarkChange = this.onRemarkChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);

    }

    onLocationChange(event) {
        
        let errors = {};
        this.setState({
            errors: errors
        });
        var location = this.state.location;
        location.City = event.target.value;
        this.setState({
            location: location
        })
        // this.validateForm();
    }
    onRemarkChange(event) {
        
        let errors = {};
        this.setState({
            errors: errors
        });
        var location = this.state.location;
        location.RequestorRemarks = event.target.value;
        this.setState({
            location: location
        })
        // this.validateForm();
    }
    onStatusChange(event) {
        
        const location = this.state.location;
        location.IsActive = event.target.checked;
        this.setState({
            location: location
        })
    }
    handleSave(e) {
        
        if (this.validateForm()) {
            // axiosInstance.post(`${LocalApiBaseUrl}Location/AddEditLocation`, this.state.location)
            //     .then((response) => {
            //         window.alert(response.data.StatusMessage)
            //     },
            //     (error)=>{
            //         
            //        
            //     });
            this.props.onSave(this.state.location)

            this.handleHide();
        }
    }
    handleHide(e) {
        
        this.setState({ show: false });
        if (e != undefined) {

            if ((e.target.textContent != "×")) {
                this.props.onClose(e);
            }
            else {
                this.props.onClose(e);
                // this.props.onClose("from x");
            }
        } else {
            this.props.onClose(e);
        }
        this.props.onSave()

    }
    handleRejectHide(e) {
        

        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onRejectClose();
            }
        } else {
            this.props.onRejectClose();
        }

    }
    handleApproveHide(e) {

        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onApproveClose();
            }
        } else {
            this.props.onApproveClose();
        }

    }
    passToConfirmation(data) {
        

        this.props.enteredData(data.PoNumber)
    }


    componentDidMount() {
        
        // this.validateForm();
        this.setState({
            show: this.props.show
        })
    }

    validateForm() {
        const location = this.state.location;
        let errors = {};
        let formIsValid = true;
        var data = this.props.rowData;
        
         if (data.length > 0) 
         {
         
            let oldData = data.map(function (el) { return el.City });
             if (oldData.includes(location.City.trim()) && (!this.props.data)) {
                formIsValid = false;
                errors["Location"] = i18n.t("Name_already_exists");
             }
         }







        if (!location["City"].trim()) {
            formIsValid = false;
            errors["Location"] = i18n.t("Snapshot_Add_ReqLocation");

        }
        else {
            //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
            // var regex = /^[A-Za-z ]+$/
            //Validate TextBox value against the Regex.
            var isValid = regexForConfMasterFields.test(location["City"]);
            if (!isValid) {
                formIsValid = false;
                errors["Location"] = i18n.t("Location_contains_Special_characters");
            }
        }
        if(location["RequestorRemarks"]==null)
        {
            formIsValid = false;
            errors["RequestorRemarks"] = i18n.t("addWoErrorM8");

        }
      else  if (!location["RequestorRemarks"].trim()) {
            formIsValid = false;
            errors["RequestorRemarks"] = i18n.t("addWoErrorM8");

        }
        else {
            //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
            // var regex = /^[A-Za-z ]+$/
            //Validate TextBox value against the Regex.
            var isValid = regexForTextAreas.test(location["RequestorRemarks"]);
            if (!isValid) {
                formIsValid = false;
                errors["RequestorRemarks"] = i18n.t("addWoErrorM9");
            }
        }


        this.setState({
            errors: errors
        });
        if (formIsValid) {
            this.setState({
                formIsValid: true
            })
        }
        else {
            this.setState({
                formIsValid: false
            })
        }
        return formIsValid;

    }

    componentWillMount() {
        
        if (this.props.data) {
            this.setState({
                location: this.props.data,
            })
        }
    }

    render() {
        
        return (
            <>
                {/* <Header /> */}
                <Modal show={this.state.show} onHide={this.handleHide.bind(this)} className="modal fade" size="medium" id="dealpopup">
                    <Modal.Header closeButton>
                        {!this.props.data ?
                            <h4 className="modal-title" id="dealprofile">{i18n.t("Add_Location")}</h4>
                            : <h4 className="modal-title" id="dealprofile">{i18n.t("Edit_Location")}</h4>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        
                            <form>
                                <div className="form-section">

                                    <div className="row">
                                    {this.props.data ?
                                        <div className="form-group col-lg-6">
                                            <label for="">{i18n.t("Location_Name")}<span className="required">*</span></label>
                                            <input type="text" readOnly className="form-control" id="name" onChange={this.onLocationChange} value={this.state.location.City} />
                                            <span className="errorMsg">{this.state.errors.Location}</span>
                                        </div> 
                                        : <div className="form-group col-lg-6">
                                        <label for="">{i18n.t("Location_Name")}<span className="required">*</span></label>
                                        <input type="text" className="form-control" id="name" onChange={this.onLocationChange} value={this.state.location.City} />
                                        <span className="errorMsg">{this.state.errors.Location}</span>
                                    </div> }

                                    {this.props.data ?
                                          <div className="form-group col-lg-6">
                                          <label for="">{i18n.t("Requestor_Remarks")}<span className="required">*</span></label>
                                          <textarea maxLength="200" readOnly  className="form-control" id="remarks" onChange={this.onRemarkChange.bind(this)} value={this.state.location.RequestorRemarks} />
                                          <span className="errorMsg">{this.state.errors.RequestorRemarks}</span>
                                      </div>
                                        :    <div className="form-group col-lg-6">
                                        <label for="">{i18n.t("Requestor_Remarks")}<span className="required">*</span></label>
                                        <textarea maxLength="200"  className="form-control" id="remarks" onChange={this.onRemarkChange.bind(this)} value={this.state.location.RequestorRemarks} />
                                        <span className="errorMsg">{this.state.errors.RequestorRemarks}</span>
                                    </div>}

                                          {this.props.data ?
                                          this.props.data.Status==="Approved" || this.props.data.Status==="Rejected"?
                                            <div className="form-group col-lg-6">
                                                <label for="">{i18n.t("Approver_Remarks")}<span className="required"></span></label>
                                                <textarea maxLength="200"  className="form-control" id="ClientContact1" disabled={this.props.data || this.state.apivalue}  value={this.props.data.ApproverRemarks} />

                                            </div>
                                            :null
                                            :null}
                                              {this.props.data ?
                                                this.props.data.Status==="Approved"?
                                                <div className="form-group col-lg-6 label-adjust">
                                                <label className="form-check">
                                                    <input className="form-check-input" type="checkbox" onChange={this.onStatusChange} defaultChecked={this.state.location.IsActive} />
                                                    <span className="checkmark"></span>
                                                    <span className="form-check-label">{i18n.t("Is_Active")}</span>
                                                </label>
                                            </div>
                                                : <div className="form-group col-lg-6 label-adjust">
                                                <label className="form-check">
                                                    <input className="form-check-input" readOnly  type="checkbox" onChange={this.onStatusChange} defaultChecked={this.state.location.IsActive} />
                                                    <span className="checkmark"></span>
                                                    <span className="form-check-label">{i18n.t("Is_Active")}</span>
                                                </label>
                                            </div> 
                                                : <div className="form-group col-lg-6 label-adjust">
                                                <label className="form-check">
                                                    <input className="form-check-input" type="checkbox" onChange={this.onStatusChange} defaultChecked={this.state.location.IsActive} />
                                                    <span className="checkmark"></span>
                                                    <span className="form-check-label">{i18n.t("Is_Active")}</span>
                                                </label>
                                            </div>}

                                        {/* <div className="form-group col-lg-6">
                                            
                                            <label className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={this.onStatusChange} defaultChecked={this.state.location.IsActive} />
                                                <span className="checkmark"></span>
                                                <span className="form-check-label">Is Active</span>
                                            </label>
                                        </div> */}
                                        
                                    </div>

                                </div>


                            </form>
                        
                    </Modal.Body>
                    {<Modal.Footer>
                        <div className="modal-btn">
                                {!this.props.data ?
                                    <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value={i18n.t("Create")} />
                                    : this.props.data.Status==="Approved"? <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value={i18n.t("Update")} /> :null
                                }
                        </div>
                    </Modal.Footer>}
                </Modal>
            </>
        )
    }
}