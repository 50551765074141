import React from "react";
//import $ from 'jquery';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import jquery from "jquery";
import ReactTooltip from "react-tooltip";
import { store } from "../reducers/configureStore";
import BO2_popup from "../Configurator/BO2_popup";
import MetricView from "../Configurator/MetricView";
window.$ = window.jQuery = jquery;

export default class MetricDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewmore: false,
      data: [],
      boData: {
        SOCategoryID: 0,
        kpiData: [
          //metDataAttr:{},
        ]
      }
    };
  }

  dealpopup = (data, e) => {
    e.preventDefault();

    // 
    this.setState({
      data: data
    });


    this.setState({
      viewmore: true
    }); 

    e.preventDefault();
  };

  onClose = e => {
    this.setState({ viewmore: false });
  };

  deleteKpi(data, e) {
    if (window.confirm("Are you sure you want to delete metric detail?")) {
      // e.stopPropagation()
      const boData = this.state.boData;
      boData.SOCategoryID = this.props.SOCategoryID;
      e.stopPropagation();

      boData.kpiData = data;
      this.setState({
        boData: boData
      });
      store.dispatch({
        type: "DELETE_BO",
        payload: this.state.boData
      });
      // }
    }
  }

  render() {
    
    const options = {
      loop: true,
      margin: 16,
      responsiveClass: "",
      autoWidth: true,
      dots: false,
      responsive: {
        0: {
          items: 1,
          nav: true
        },
        600: {
          items: 2,
          nav: false
        },
        1000: {
            items: 3,
            nav: true,
            loop: false
        },
        1440: {
            items: 4,
            nav: true,
            loop: false
          },
          1600: {
              items: 4,
              nav: true,
              loop: false
          },
          1800: {
              items: 5,
              nav: true,
              loop: false
        }
      }
    };
    return ( 
      <>
      <div className="custom-carousel-padding">
        <OwlCarousel options={options} className="owl-carousel">
          {this.props.Details.map(eachDetails => (
            <MetricView
              isRelatorBlock={this.props.isRelatorBlock}
              data={eachDetails}
              SOCategoryID={this.props.SOCategoryID}
              type={this.props.SOCategoryName}
              kpiData={this.props.data}
              opsgrp={this.props.opsgrp}
              attributes={this.props.attributes}
              filterDetails={this.props.filterDetails}
              RecordStatus = {this.props.RecordStatus} isManintencneWindowOpen ={this.props.isManintencneWindowOpen} 
            ></MetricView>
          ))}
        </OwlCarousel>
        <ReactTooltip place="bottom" effect="solid" className="tipcolor" />
        </div>
      </>
    );
  }
}

// return <>
// <OwlCarousel options={options} className={`owl-carousel`}> {(this.props.Details.map((eachDetails) => {
//     let AttrValue = "";
//     let metricName = "";
//     let unit = "";
//     let metricData = "";
//     let Description = "";
//     let ToolTip = "";
//     //if (eachDetails.KpiMetrics != undefined) {
//         metricName = eachDetails.MetricName
//         unit = eachDetails.MetricUnit
//         //ToolTip='Lower Threshold:'+(eachDetails.KpiMetrics.LowerThreshold||'NA')+' , Upper Threshold:'+(eachDetails.KpiMetrics.UpperThreshold||'NA')
//     //}

//     let statusColor = "gray-clr"
//     if (eachDetails != undefined) {
//         ToolTip = 'Lower Threshold:' + (eachDetails.LowerThreshold || 'NA') + ' , Upper Threshold:' + (eachDetails.UpperThreshold || 'NA')

//         metricData = eachDetails.MetricValue;

//         Description = eachDetails.MetricDataDesp === "" ? eachDetails.KPIDesp : eachDetails.MetricDataDesp

//         if (eachDetails.RAGStatus != undefined && eachDetails.RAGStatus != null) {


//             switch (eachDetails.RAGStatus.toLowerCase()) {
//                 case "grey":
//                     statusColor = "gray-clr"
//                     break;
//                 case "red":
//                     statusColor = "red-clr"
//                     break

//                 case "green":
//                     statusColor = ""
//                     break
//                 case "amber":
//                     statusColor = "orange-clr"
//                     break
//                 default:
//                 // code block
//             }
//         }
//     }

//     return <div className={`item outcome-divider ${statusColor}`}  >
//     <a  onClick={this.deleteKpi.bind(this, eachDetails)}><span className="deleteMetricDetails"><u><FontAwesomeIcon icon={faTrashAlt} /> </u></span></a>
//        {/* <button  > edit </button> */}
//        <a href="" onClick={this.dealpopup.bind(this,eachDetails)}> <span>
//         <p className="minht">{metricName}</p>
//         <span>{Description}</span>
//         {/* <h3>{metricData != "" ? `${Number.isInteger(Number(metricData)) ? metricData : Number(metricData).toFixed(2)}` : ""}{unit != "%" ? <span>{`${unit}`}</span> : unit}</h3> */}
//         <h3 data-tip={ToolTip}>{`${Number.isInteger(Number(metricData)) ? metricData : Number(metricData).toFixed(2)}`}{unit != "%" ? <span>{`${unit}`}</span> : unit}</h3>
//         </span>
//         </a>
//     { this.state.viewmore ? <BO2_popup SOCategoryID={this.props.SOCategoryID} type={this.props.SOCategoryName} kpiData={this.state.data} show={this.state.viewmore } onClose={this.onClose.bind(this)} opsgrp={this.props.opsgrp}  filterDetails={this.props.filterDetails} /> : null }

//     </div>
// }))}

//  </OwlCarousel>

// <ReactTooltip place="top" className="tipcolor" />
// </>
// }
// }
