import React, { Component } from 'react';
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import i18n from "i18next";
import Modal from "react-bootstrap/Modal";
import { LocalApiBaseUrl } from "../Shared/Constant";
import LoadingOverlay from "react-loading-overlay";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import { regexForFields } from "../Shared/Constant";
import { DisplayMessage,isValidText,InvalidDescription } from "../Shared/commonFunctions";
import ConsolidatedViewModal from '../Bat/ConsolidatedViewModal';
import SynopsLoader from "../Shared/SynopsLoader";
import { connect } from "react-redux";
import { EditorState, convertFromHTML, convertFromRaw, ContentState, convertToRaw, createFromBlockArray  } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';

const MAX_LENGTH = 1500;
class AddWOStep_MTC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            showLoaderOnPage: false,
            formIsValid: false,
            isAPILoading: false,
            buttonDisable:false,
            milestonesEditorStates:[{
                "index":0,
                "value":'',
                "editorState":EditorState.createEmpty(),
              }],
              editorState: EditorState.createEmpty(),
              value: "",
              countDesp:0,
              
            woData: {
                SOCategoryID: this.props.Details ? this.props.Details.SOCategoryTypeID : 0,
                ParentSOCategoryID: this.props.Details ? this.props.Details.ParentSOCategoryID : 3,
                SOCategoryName: this.props.Details ? this.props.Details.SOCategoryName : "",
                SOCategoryDesp: this.props.Details ? this.props.Details.SOCategoryDesp : "",
                SoCategoryImage: this.props.Details ? this.props.Details.SoCategoryImage : "",
                SOCategoryTypeID: this.props.Details ? this.props.Details.SOCategoryTypeID : 2,
                SequenceID: 0,
                SOCategorySeq: this.props.Details ? this.props.Details.SOCategorySeq : "",
                SoCatDataAttr: [],
                kpiData: [],
                SOCategoryType: this.props.SOCategoryType
            }
        };

        this.onNameChange = this.onNameChange.bind(this);
    }

    componentDidMount() {
        if(this.props.Details){
        const blocksFromHTML = convertFromHTML(this.props.Details.SOCategoryDesp);
        const content = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        this.setState
        ({
            editorState: EditorState.createWithContent(content),
        })
    }

}

    onNameChange(event) {
        let errors = {};
        this.setState({
            errors: errors
        });

        const woData = this.state.woData;
        woData.SOCategoryName = event.target.value;
        this.setState({
            woData: woData
        });
    }

    onDescriptionChange(event) {
        const woData = this.state.woData;
        woData.SOCategoryDesp = event.target.value;
        this.setState({
            woData: woData
        });
    }

    onSequenceChange(event) {
        const woData = this.state.woData;
        woData.SOCategorySeq = event.target.value;
        this.setState({
            woData: woData
        });
    }

    handleSave = e => {
        if (this.validateForm()) {
            const woData = this.state.woData;
            this.setState({
                woData: woData,
                //showLoaderOnPage: true,
            });
            if(this.props.screen != "Configurator"){
                this.setState({showLoaderOnPage:true})
            }

            if (this.props.screen === "Configurator") {
                let ReqObj = {
                    "DealTemplateApprovalModel": {
                        "StepName": this.state.woData.SOCategoryName,
                        "StepDescription": this.state.woData.SOCategoryDesp,
                        "Sequence": this.state.woData.SOCategorySeq,
                        "ParentSOCategoryID": this.state.woData.ParentSOCategoryID
                    },
                    "FiltersIDsVM": {
                        "OrgEntityID": this.props.filter.Client,
                        "OfferingID": this.props.filter.process,
                        "LocationID": this.props.filter.Locations,
                        "MonthDT": this.props.filter.Month
                    }

                }
                this.setState({ isAPILoading: true });
                //http://localhost:51045/api/DealTemplateWOSteps/PostDealTemplateWOSteps?IsCallingForEdit=1
                let apiCallLink = this.props.mode === "UPDATE" ? `${LocalApiBaseUrl}DealTemplateWOSteps/PostDealTemplateWOSteps?IsCallingForEdit=1` : `${LocalApiBaseUrl}DealTemplateWOSteps/PostDealTemplateWOSteps`;
                if(this.props.screen === "Configurator"){
                    this.setState({
                        buttonDisable:true
                    })
                }
                else
                {
                    this.setState({
                        buttonDisable:false
                    })
                }

                axiosInstance
                    .post(apiCallLink,
                        ReqObj)
                    .then(response => {
                        this.setState({
                            isAPILoading: false,
                            showLoaderOnPage: false
                        },
                            () => {
                                window.alert(i18n.t(response.data.StatusMessage));
                                this.handlePopupClose();
                            });
                        //this.props.getOfferingAPICallback(true);
                        if(this.props.mode === "UPDATE")
                        {
                            store.dispatch({
                                type: "UPDATE_WOSTEP_SEQUENCE",
                                payload:this.state.woData
                            }); 
                           
                        }
                        this.setState({
                            buttonDisable:false
                        })

                    }).catch((error)=>{
trycatchAlertPopup(error);
                        this.setState({ isAPILoading: false, showLoaderOnPage: false }, () => {
                            
                            DisplayMessage();
                            this.handlePopupClose();
                        });
                    });
            }

            else {
                woData.SOCategoryDescription = this.state.woData.SOCategoryDesp;
                let filterProcess = this.props.filter.process;                
                let duplicate = 0;
                let WOMasterStepData = [...this.props.woTileData];
                WOMasterStepData = WOMasterStepData.filter((data) => 
                                    data.SOCategoryName_En !== 'Work Orchestration' && data.ParentSOCategoryID === 3);
                WOMasterStepData.forEach((data) => {
                    if(data.SOCategoryName.toLowerCase().trim() === woData.SOCategoryName.toLowerCase().trim()){
                        duplicate = duplicate + 1;
                    }
                })
                if (duplicate === 0 || this.props.mode == "UPDATE"){ 
                    this.setState({ isAPILoading: true });
                    axiosInstance
                        .post(`${LocalApiBaseUrl}Master/PostSOCategoryMasterTemplate`, woData, {
                            params: {
                                offeringId: filterProcess,
                                LanguageID: store.getState().CurrentFilter.languageData.id
                            }
                        })
                        .then(response => {
                            let woData = {
                                SOCategoryID: 0,
                                ParentSOCategoryID: 3,
                                SOCategoryName: "",
                                SOCategoryDesp: "",
                                SoCategoryImage: "",
                                SOCategoryTypeID: 2,
                                SequenceID: 0,
                                SOCategorySeq: "",
                                SoCatDataAttr: [],
                                kpiData: []
                            }
                            store.dispatch({
                                type: "ADD_EDhandlePopupCloseIT_TILE_MASTER",
                                payload: response.data.stateData
                            });
                            this.setState({
                                woData: woData,
                                isAPILoading: false,
                                showLoaderOnPage: false
                            },
                                () => {
                                    window.alert(i18n.t(response.data.StatusMessage));
                                    this.handlePopupClose();
                                });
                            this.props.getOfferingAPICallback(true);

                        }).catch((error)=>{
                            trycatchAlertPopup(error);
                            this.setState({ isAPILoading: false, showLoaderOnPage: false }, () => {
                                DisplayMessage();
                                this.handlePopupClose();
                            });
                        });
                }
                else{
                    this.setState({showLoaderOnPage:false});
                    window.alert(i18n.t('This Work Orchestration Step already exist.'));
                    this.handlePopupClose();
                }
                
            }
            /*if (this.validateForm()) {
              this.props.modalClose();
            }*/
        }
    };

    validateForm() {
        let woData = this.state.woData;
        let errors = {};
        let formIsValid = true;
        if (woData["SOCategoryName"])
            woData["SOCategoryName"] = woData["SOCategoryName"].trim();
        if (!woData["SOCategoryName"]) {
            formIsValid = false;
            errors["SOCategoryName"] = i18n.t("addWoErrorM1");

        } else {
            let isValid = regexForFields.test(woData["SOCategoryName"]);
            if (!isValid) {
                formIsValid = false;
                errors["SOCategoryName"] = "StepName value contains Special characters";
            }
        }
        // if (woData["SOCategoryDesp"].length > 1500)
        // errors["SOCategoryDesp"] = i18n.t("TMore than 1500 chars not allowed");
        // if (woData["SOCategoryDesp"])
        //   woData["SOCategoryDesp"] = woData["SOCategoryDesp"].trim();
        // if (!woData["SOCategoryDesp"].trim()) {
        //   formIsValid = false;
        //   errors["SOCategoryDesp"] = i18n.t("ToolDescription_Required");

        // }else {
        //   let isValid = regexForFields.test(woData["SOCategoryDesp"]);
        //   if (!isValid) {
        //     formIsValid = false;
        //     errors["SOCategoryDesp"] = "StepDescription contains Special characters";
        //   }
        // }

        if (woData["SOCategorySeq"]) {
            woData["SOCategorySeq"] = parseInt(woData["SOCategorySeq"], 10);
        }
         else{
         woData["SOCategorySeq"]=null}
        
        if (woData["SOCategorySeq"]==null ) {
            formIsValid = false;
            errors["SOCategorySeq"] = i18n.t("addWoErrorM5");

        } else if (
            !(woData["SOCategorySeq"] > 0 && woData["SOCategorySeq"] <= 999999)
        ) {
            formIsValid = false;
            errors["MetricSeqRange"] = i18n.t("addWoErrorM6");

        }
        if (!isValidText(woData["SOCategoryDesp"].trim())) {
            formIsValid = false;
            errors["SOCategoryDesp"] = InvalidDescription();
        }

        this.setState({
            errors: errors,
        });
        if (formIsValid) {
            this.setState({
                formIsValid: true,
                errors: {}
            });
        } else {
            this.setState({
                formIsValid: false
            });
        }
        return formIsValid;
    }

    handlePopupClose = () => {
        let woData = {
            SOCategoryID: 0,
            ParentSOCategoryID: 3,
            SOCategoryName: "",
            SOCategoryDesp: "",
            SoCategoryImage: "",
            SOCategoryTypeID: 2,
            SequenceID: 0,
            SOCategorySeq: "",
            SoCatDataAttr: [],
            kpiData: []
        }
        this.setState({
            woData: woData,
            editorState: null,

            errors:{}
        })
        this.props.modalClose();
    }

    onEditorStateChange = (editorState) => {
        const editorValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        const plainValue = editorState.getCurrentContent().getPlainText();
        if (plainValue.length <= 1500) {
    
          this.setState({
            woData: { ...this.state.woData, SOCategoryDesp: editorValue },
            countDesp: plainValue.length,
            editorState,
          });
        } else {
            const fText = String(plainValue).slice(0, MAX_LENGTH);
            this.setState({
                woData: { ...this.state.woData, SOCategoryDesp: editorValue },
                editorState: EditorState.createWithContent(ContentState.createFromText(fText)),
            })
        }
      };
        
      handleBeforeInput = (editorState) => {
        const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText("").length;
        
        let errors = this.state.errors;
        if (currentContentLength > MAX_LENGTH - 1) {
          errors["SOCategoryDesp"] = i18n.t("More than 1500 characters not allowed");

          this.setState({
            errors,
          });
          return "handled";
        } else {
          errors["SOCategoryDesp"] = "";
          this.setState({
            errors,
          });
        }
      };
    
    


    render() {
const {
    milestonesEditorStates,editorState,woData
} = this.state;
console.log("aa",this.props.screen,this.props.mode);

        return (
            <>
                <LoadingOverlay className="custom-loader" fadeSpeed={0}  spinner={<SynopsLoader />} active={this.state.showLoaderOnPage}>
                </LoadingOverlay>
                <Modal show={this.props.show} onHide={() => this.handlePopupClose()} className="tooldata-pop" backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.mode == "UPDATE" ? 'Edit Step' : 'Add step'} - Work Orchestration</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            {this.props.screen !== "Configurator" ?  // MTC Side
                            <div className="form-section">
                                <div className="row">
                                    <div className="form-group col-lg-9">
                                        <label for="name">Step Name<span className="required">*</span>
                                        </label>
                                        <input type="text" className="form-control" id="name" maxLength="100" onChange={this.onNameChange}
                                            value={this.state.woData.SOCategoryName} disabled={this.props.mode == "UPDATE" ? true : false} />
                                        <span className="errorMsg">
                                            {this.state.errors.SOCategoryName}
                                        </span>
                                    </div>
                                    <div className="form-group col-lg-3">
                                        <label for="unit">Sequence<span className="required">*</span>
                                        </label>
                                        <input type="number" min="1" className="form-control" id="unit" onChange={this.onSequenceChange.bind(this)} value={this.state.woData.SOCategorySeq} />
                                        <span className="errorMsg">
                                            {this.state.errors.SOCategorySeq}
                                        </span>
                                        <div className="errorMsg">
                                            {this.state.errors.MetricSeqRange}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <label for="description">Description
                                       
                                         </label>
                                         <div className="description-editor" style={{ position: 'relative' }}>
                        <Editor
                          readOnly={this.props.screen == "SuperAdmin"? false: this.props.mode == "UPDATE" ? true : false}
                          editorState={editorState}
                          stripPastedStyles={true}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName={
                            this.props.screen == "SuperAdmin"? null: this.props.mode == "UPDATE" ? "EditWoStepDescription 123" : null
                          }                          
                          handleBeforeInput={(e) => this.handleBeforeInput(editorState)}
                          onEditorStateChange={(e) => this.onEditorStateChange(e)}
                          onTab={() => true}
                          toolbar={{
                            options: ["list"],
                            list: { inDropdown: false, options: ["unordered"] },
                          }}
                        />
                           
                        <span className="suffix-text">
                          {this.state.countDesp}
                          /1500 Characters
                        </span>
                      
                      </div>
                     
                      <span className="errorMsg">{this.state.errors.SOCategoryDesp}</span>
                   
                                    </div>
                                    </div>
                                </div>
                                : // Configurator Side
                                <div className="form-section">
                                    <div className="row">
                                        <div className="form-group col-lg-9">
                                            <label for="name">Step Name<span className="required">*</span>
                                            </label>
                                            <input type="text" className="form-control" id="name" maxLength="100" onChange={this.onNameChange}
                                                value={this.state.woData.SOCategoryName} disabled={this.props.mode == "UPDATE" ? true : false} />
                                            <span className="errorMsg">
                                                {this.state.errors.SOCategoryName}
                                            </span>
                                        </div>
                                        {
                                            this.props.mode === "UPDATE" ? <div className="form-group col-lg-3">
                                                <label for="unit">Type
                                                </label>
                                                <input type="text" className="form-control" disabled={true} id="unit" value={this.state.woData.SOCategoryType} />
                                            </div>
                                                :
                                                <div className="form-group col-lg-3">
                                                    <label for="unit">Sequence<span className="required">*</span>
                                                    </label>
                                                    <input type="number" min="1" className="form-control" id="unit" onChange={this.onSequenceChange.bind(this)} value={this.state.woData.SOCategorySeq} />
                                                    <span className="errorMsg">
                                                        {this.state.errors.SOCategorySeq}
                                                    </span>
                                                    <div className="errorMsg">
                                                        {this.state.errors.MetricSeqRange}
                                                    </div>
                                                </div>
                                        }

                                    </div>
                                    <div className="row">
                                        <div className={this.props.mode === "UPDATE" ? "form-group col-lg-9" : "form-group col-lg-12"}>
                                            <label for="description">Description
                                         </label>
                                         <div className="description-editor" style={{ position: 'relative' }}>
                        <Editor
                          readOnly={this.props.mode == "UPDATE" ? true : false}
                          editorState={editorState}
                          stripPastedStyles={true}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName={
                            this.props.mode == "UPDATE" ? "EditWoStepDescription" : ""
                          }
                          handleBeforeInput={(e) => this.handleBeforeInput(editorState)}
                          onEditorStateChange={(e) => {
                            this.onEditorStateChange(e);
                          }}
                          onTab={() => true}
                          toolbar={{
                            options: ["list"],
                            list: { inDropdown: false, options: ["unordered"] },
                          }}
                        />
                          {this.props.mode != "UPDATE" ? (
                        <span className="suffix-text">
                          {this.state.countDesp}
                          /1500 Characters
                        </span>
                      ) : null}{" "}
                      </div>
                      <span className="errorMsg">{this.state.errors.SOCategoryDesp}</span>
                     </div>
                                        {
                                            this.props.mode === "UPDATE" && <div className="form-group col-lg-3">
                                                <label for="unit">Sequence<span className="required">*</span>
                                                </label>
                                                <input type="number" min="1" className="form-control" id="unit" onChange={this.onSequenceChange.bind(this)} value={this.state.woData.SOCategorySeq} />
                                                <span className="errorMsg">
                                                    {this.state.errors.SOCategorySeq}
                                                </span>
                                                <div className="errorMsg">
                                                    {this.state.errors.MetricSeqRange}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>}
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="modal-btn">
                            <button
                                onClick={() => this.handlePopupClose()}
                                className="btn btn-default brder-2 btn-sm mr-2"
                            >
                                {i18n.t("Cancel")}
                            </button>


                            {!this.props.Details ? (
                                <input
                                    type="Button"
                                    className="btn btn-primary btn-sm"
                                    onClick={() => this.handleSave()}
                                    value={i18n.t("Add")}
                                    disabled={this.state.buttonDisable}
                                />
                            ) : (
                                    <input
                                        type="Button"
                                        className="btn btn-primary btn-sm"
                                        onClick={() => this.handleSave()}
                                        value={i18n.t("Update")}
                                        disabled={this.state.buttonDisable}
                                    />
                                )}
                        </div>
                    </Modal.Footer>
                </Modal>
            </>

        )
    }

}  
const mapStateToProps = (state) => {
    return {
      woTileData: state.WOtileMasterReducers.tile,
    };
  };
  
export default connect(mapStateToProps)(AddWOStep_MTC);