/* eslint-disable no-script-url */
import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import i18n from 'i18next';
import { connect } from 'react-redux';
import axiosInstance from './interceptor';
import SubOffering from './SubOffering';

class OfferingSubOffering extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      offeringList: [],
      subofferingData: {},
      autoSelect: false,
    };
  }

  componentDidMount() {
    const { offeringsData } = this.props;
    if(this.props.path === '/ClientStory'){
      this.props.onOfferingChange({ target: { value: 'All' } })
    }
    else if (offeringsData) {
      this.setState({ offeringList: offeringsData });
       if (offeringsData.length === 1) {
         this.props.onOfferingChange({ target: { value: offeringsData[0].OfferingID } });
       }
    }
    
  }

  componentDidUpdate(prveProps) {
    const { offeringsData, path, selectedOffering } = this.props;
    if(path === '/Dashboard' && prveProps.path === '/ClientStory' && selectedOffering && selectedOffering === i18n.t('All_offering')){
      this.props.onOfferingChange({ target: { value: '' } })
    }
    if (prveProps.offeringsData !== offeringsData || (path && path!=prveProps.path)) {
      this.setState({ offeringList: offeringsData });
      if(path === '/ClientStory'){
        this.props.onOfferingChange({ target: { value: 'All' } })
      }
      else if (offeringsData) {        
        if (offeringsData.length === 1 && (this.props.compDetails !== 'Journey Initiatives' || this.props.compDetails === "IOJ Scorecard") ) {
          this.props.onOfferingChange({ target: { value: offeringsData[0].OfferingID } });
          this.setState({
            autoSelect: true
          })
        }
        else if ((offeringsData.length > 1 || offeringsData.length === 0) && (this.props.compDetails !== 'Journey Initiatives'|| this.props.compDetails === "IOJ Scorecard") && (this.props.snapshotClicked === undefined || (this.props.snapshotClicked !== undefined && this.props.snapshotClicked === false))) {
          if(this.state.autoSelect){
            this.props.onOfferingChange({ target: { value: 0 } });
            this.setState({
              autoSelect: false
            })
          }
        }
      }
    }
  }

  render() {
    const {
      offerings,
      compDetails,
      onSubOfferingChange,
      offeringsData,
      onOfferingChange,
      selectedOffering,
      selectedSubOffering,
      subOfferings,
      selectedSubOfferingId,
    } = this.props;

    const { offeringList } = this.state;

    return (
      <>
        <DropdownButton
          menuAlign="left"
          id="Offeringbutton"
          title={
            selectedOffering || i18n.t('Select_Offering')
          }
          disabled={!offerings.length}
          className={
            selectedOffering !== ''
            && selectedOffering !== i18n.t('Select_Offering')
              ? 'custom-headerdropdown selected mr-3'
              : 'custom-headerdropdown mr-3'
          }
        >
          <div className="offering-droplistwrapper">
            {this.props.path === '/ClientStory' && (
              <Dropdown.Item
              href="javascript:void(0);"
              onClick={() => (selectedOffering === 'All' ? '' : onOfferingChange({ target: { value: 'All' } }))}
              onKeyDown={() => (selectedOffering === 'All' ? '' : onOfferingChange({ target: { value: 'All' } }))}
            >
              <span
                className={
                    selectedOffering === 'All'
                      ? 'form-check-label active'
                      : 'form-check-label'

                  }
                role="button"
                tabIndex={0}

              >
                {i18n.t('All_offering')}
              </span>
            </Dropdown.Item>
            )}
            {compDetails !== undefined
              && (compDetails === 'Journey Initiatives' || compDetails === "IOJ Scorecard") && (
                <Dropdown.Item 
                  href="javascript:void(0);"
                  onClick={() => onOfferingChange({ target: { value: 'All' } })}
                  onKeyDown={() => onOfferingChange({ target: { value: 'All' } })}
                >
                  <span
                    className={
                        selectedOffering === 'All'
                          ? 'form-check-label active'
                          : 'form-check-label'

                      }
                    role="button"
                    tabIndex={0}
                   
                    
                  >
                    {i18n.t('All_offering')}
                  </span>
                </Dropdown.Item>
            )}
            {offeringList && offeringList.length
              ? offeringList.map((each, i) => (
                <Dropdown.Item 
                  className = {
                    String(each.OfferingName)
                            === String(selectedOffering)
                              ? 'active'
                              : ''
                    }
                    href="javascript:void(0);"
                    onClick={() => onOfferingChange({ target: { value: each.OfferingID } })}
                    onKeyDown={() => onOfferingChange({ target: { value: each.OfferingID } })}
                  >
                  <span
                    className='form-check-label'
                    role="button"
                    tabIndex={0}
                    >
                    {each.OfferingName}
                  </span>
                </Dropdown.Item>
              ))
              : ''}
          </div>
        </DropdownButton>
        <DropdownButton
          menuAlign="left"
          id="Subofferingbutton"
          title={
            selectedSubOffering || i18n.t('Select_Sub_Offerings')
          }
          className={
            selectedSubOffering !== ''
            && selectedSubOffering !== i18n.t('Select_Sub_Offerings')
              ? 'custom-headerdropdown nested-dropdown-wrapper selected mr-3'
              : 'custom-headerdropdown nested-dropdown-wrapper mr-3'
          }
          disabled={!subOfferings.length}
        >
          <div className="offering-droplistwrapper">
            {' '}
            {compDetails !== undefined
              && (compDetails === 'Journey Initiatives' || compDetails === "IOJ Scorecard") && (
                <Dropdown.Item 
                  href="javascript:void(0);"
                  onClick={() => onSubOfferingChange({ target: { value: 'All' } })}
                  onKeyDown={() => onSubOfferingChange({ target: { value: 'All' } })}
                >
                  <span
                    className={
                        selectedSubOffering === 'All'
                          ? 'form-check-label active'
                          : 'form-check-label'
                      }
                    role="button"
                    tabIndex={0}                    
                  >
                    {i18n.t('All_sub_offering')}
                  </span>
                </Dropdown.Item>
            )}
            {/* {subOfferings.length ?
                            subOfferings.map((each, i) => {
                                return (
                                    <Dropdown.Item href="javascript:void(0);">
                                        <label className="form-check mr-3">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                value={each.OfferingID}
                                                name={each.Process_En}
                                                ref="process"
                                                id={`offerings + ${i}`}
                                                onChange={onSubOfferingChange}
                                                checked={
                                                    String(each.Process) === String(selectedSubOffering) ? true : false
                                                }
                                            />
                                            <span className="checkmark"></span>
                                            <span
                                                className={
                                                    String(each.Process) === String(selectedSubOffering)
                                                        ? "form-check-label active"
                                                        : "form-check-label"
                                                }
                                            >
                                                {each.Process}
                                            </span>
                                        </label>
                                    </Dropdown.Item>
                                );
                            }) : ""} */}
            <SubOffering
              selectedSubOffering={selectedSubOffering}
              subOfferings={subOfferings}
              selectedSubOfferingId={selectedSubOfferingId}
              onSubOfferingChange={onSubOfferingChange}
            />
          </div>
        </DropdownButton>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  languageData: state.CurrentFilter.languageData,
});
export default connect(mapStateToProps)(OfferingSubOffering);
