import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import i18n from "i18next";
import Success from "../Images/Success.svg";
import Exclamation from "../Images/Exclamation.svg";
export class NotificationForAddEdit extends Component {

    constructor(props){
        super(props);
        this.state={
            showsuccessModal:false,
        }
    }
    componentDidMount(){
        this.setState({
            showsuccessModal:this.props.showsuccessModal,
        })
    }

    closeModal=()=>{
        this.setState({
            showsuccessModal:false,
        })
        this.props.successRemovalModalClose();
    }


    render() {
        const { showsuccessModal } = this.state;
        const { message, addEditOutcomeFormStatus, selectedTab, duplicateOutcomeExists,duplicateAssementExists } = this.props;
        
        return (
            <Modal
            show={showsuccessModal}
            onHide={this.closeModal}
            className="successNotification-modal"
            backdrop = "static"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>{i18n.t("Notification")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="successnotification-body">
                <div className="successnotification-icon">
                  <span className="mr-4">
                 {duplicateOutcomeExists || duplicateAssementExists?( <img src={Exclamation} width="40px" height="40px" />) : ( <img src={Success} width="40px" height="40px" />)}
                </span>
                </div>
                <div className="successnotification-data">
                  <p>{i18n.t(message)}</p>
                  
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )
    }
}

export default NotificationForAddEdit
