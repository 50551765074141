/* eslint-disable import/no-cycle */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { NavLink } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FormatDateNoTime, createBreadCrumbs } from '../Shared/commonFunctions';
import CustomDatePicker from './CustomDatePicker';
import axiosInstance from '../Shared/interceptor';
import { LocalApiBaseUrl, trycatchAlertPopup } from '../Shared/Constant';
import MetricGridView from '../Shared/MetricGridView';
import RemarksPopUp from './RemarksPopUp';
import TabQCScore from '../QCScore/TabQCScore';
import { store } from '../reducers/configureStore';
import Spinner from '../Images/ajax-loader.gif';

export class ContentHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leverDescription: [],
      currentTabDescription: '',
      showQcGraph: false,
      selectedTab: '',
      selectedDataType: store.getState().CurrentFilter.FetchFromBAT ? "BenchmarkAnalytics" : "frontDoorConfigData",
      // params: { unused state field
      //   Status: null,
      //   MonthDt: null,
      //   DealID: null,
      //   activex: true,
      // },
      // active: false,
      // remarkStatus: false,
      // dataStatus: '',
      // filter: {
      //   OrgEntityID: 0, OfferingID: 0, LocationID: 0, MonthDT: null,
      // },
      // postData: [],
      // roleData: [],
      // featureData: [],
      // saveEnable: false,
      // submitEnable: false,
      // publishEnable: false,
      // publishStatus: false,
      // saveStatus: false,
      // submitStatus: false,
      viewmore: false,
      // kpiData: [],
      // SoCatDataAttr: [],
      // recordStatus: '',
      // months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      // exploreLinkList: [],
      // exploreDrop: 'none',
      isVisibleDatepickerDropdownPopover: false,
      // datastatus: '',
      // selectedYear: new Date().getFullYear(),

      newHelpFeaturesCount: 0,
      isHelpCountLoaded: false,
      disableSubmit: true,
      isMetricTableStatus: false,
      sourceOptions: [],
    };
    this.onClose = this.onClose.bind(this);
    this.getRecordStatus = this.getRecordStatus.bind(this);
    this.datepickerDropdownPopoverTarget = React.createRef();
    this.GetHelpFeatureNotifications = this.GetHelpFeatureNotifications.bind(this);
  }


  UNSAFE_componentWillMount() {
    const lastPublishedDate = store.getState().CurrentFilter.LastPublishedDate;
    this.setState({ LastPublishedDate: lastPublishedDate,  
           //selectedDataType: this.props.FetchFromBAT ? "BenchmarkAnalytics" : "frontDoorConfigData",
  });
  }

  componentDidMount() {
    const { recordStatus } = this.props;
    if (recordStatus.length) {
      this.getRecordStatus(recordStatus);
    }
    this.setState({
   //selectedDataType: this.props.FetchFromBAT ? "BenchmarkAnalytics" : "frontDoorConfigData",
     });
    this.GetHelpFeatureNotifications();
    this.fetchLeverDescription();
    this.getGetConnectorMaster();
  }

  componentDidUpdate(prevProps) {
    const { recordStatus, TabName } = this.props;
    const { leverDescription } = this.state;
    if (prevProps.recordStatus !== recordStatus) {
      this.getRecordStatus(recordStatus);
      const lastPublishedDate = store.getState().CurrentFilter.LastPublishedDate;
      this.setState({ LastPublishedDate: lastPublishedDate, });
    }
    if (prevProps.TabName !== TabName) {
      if (leverDescription.length === 0) {
        this.fetchLeverDescription();
      } else {
        const foundData = leverDescription.find((item) => item.tab === TabName);
        this.setState({
          currentTabDescription: foundData.description,
          //selectedDataType: this.props.FetchFromBAT ? "BenchmarkAnalytics" : "frontDoorConfigData",
        });
      
      }

    }
    // this.setState({
    //   selectedDataType: this.props.FetchFromBAT ? "BenchmarkAnalytics" : "frontDoorConfigData",
    // });
  }

  onClose() {
    this.setState({ viewmore: false });
  }

  getRecordStatus(data) {
    let publishStatus = false;
    let saveStatus = false;
    let submitStatus = false;
    let remarkStatus = false;
    let reviewImplementationStatus = false;
    let dataStatu = '';
    const { filterDetails } = this.props;
    const filterLocations = filterDetails.Locations;
    const filterClient = filterDetails.Client;
    const filterProcess = filterDetails.process;
    const filterDate = filterDetails.Month;

    if (filterClient && filterLocations && filterDate && filterProcess) {
      this.setState();

      if (String(data[0]) === 'Publish') {
        publishStatus = true;
        dataStatu = 'Published';
      }
      if (String(data[0]) === 'Review(Implementation)') {
        submitStatus = true;
        reviewImplementationStatus = true;
        dataStatu = 'In Review(Implementation)';
      }
      if (String(data[0]) === 'Save') {
        saveStatus = true;
        dataStatu = 'Draft';
      }

      if (String(data[0]) === 'Submit') {
        submitStatus = true;
        dataStatu = 'In Review';
      }
      if (String(data[0]) === 'Rejected') {
        submitStatus = true;
        dataStatu = 'Rejected';
      }
      if (String(data[3]) !== String(undefined)) {
        remarkStatus = true;
      }

      if (String(data[2]) === 'False') {
        if (String(data[1]) === 'True') {
          publishStatus = true;
          saveStatus = false;
          submitStatus = false;
        }
      }
      this.setState({
        // publishStatus,
        // saveStatus,
        // submitStatus,
        // remarkStatus,
        // dataStatus: dataStatu,
      });
    }
  }

  getGetConnectorMaster = () => {
    let source = [];
    axiosInstance
      .get(`${LocalApiBaseUrl}ConnectorMapping/GetConnectorMaster`)
      .then((response) => {
        source = response.data;
        const sourceValues = source.map((x) => (
          {
            key: x.ConnectorID,
            value: x.ConnectorName,
          }
        ));
        sourceValues.push({ key: '-1', value: 'Others' });
        sourceValues.push({ key: '-2', value: 'Manual' });
        this.setState({
          sourceOptions: sourceValues,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  fetchLeverDescription = () => {
    const { TabName } = this.props;
    axiosInstance
      .get(
        `${LocalApiBaseUrl}so/GetSOCategories`,
        { params: { LanguageID: store.getState().CurrentFilter.languageData.id } },
        // params: { OrgEntityID: filterClient, OfferingID: filterProcess, LocationID: filterLocations, MonthDT: todayDate }
      )
      .then((response) => {
        const tempData = [];
        let foundData = {};
        response.data.map((item) => {
          tempData.push({
            tab: item.SOCategoryName_En,
            description: item.SOCategoryDescription,
          });
        });
        foundData = tempData.find((item) => item.tab === TabName);
        this.setState({
          leverDescription: tempData,
          currentTabDescription: foundData.description,
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        trycatchAlertPopup(err);
      });
  }

  GetHelpFeatureNotifications = () => {
    const { titleDescription } = this.props;
    const data = {
      LeverName: titleDescription.title,
    };
    axiosInstance
      .post(`${LocalApiBaseUrl}UserGuide/GetUserHelpTopicCount`, data)
      .then((response) => {
        this.setState({
          newHelpFeaturesCount: response.data.NewHelpTopicCount,
          isHelpCountLoaded: true,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

   onGetData = () => {
     const { isVisibleDatepickerDropdownPopover } = this.state;
     this.setState({ isVisibleDatepickerDropdownPopover: !isVisibleDatepickerDropdownPopover });
   };

  showQcGraph = (val1, val2) => {
    this.setState({ showQcGraph: val1, selectedTab: val2 });
  };

  viewMore(e) {
    e.preventDefault();

    this.setState({ viewmore: true });
  }

  isMetricTableShow(e) {
    this.setState({ isMetricTableStatus: true });
  }

  isMetricTableClose(e) {
    this.setState({ isMetricTableStatus: false });
  }

  isDataPresent() {
    let configData = [];
    const boTabData = this.props.boData.filter((e) => e.kpiData.length > 0);
    configData = configData.concat(boTabData);

    const hmTabData = this.props.hmData.filter((e) => e.kpiData.length > 0 && (e.ParentSOCategoryID == 9 || e.ParentSOCategoryID == 10));
    configData = configData.concat(hmTabData);

    const woTabData = this.props.woData.filter((e) => e.kpiData.length > 0);
    configData = configData.concat(woTabData);

    const iniTabData = this.props.iniData.filter((e) => e.kpiData.length > 0);
    configData = configData.concat(iniTabData);

    const ddTabData = this.props.ddData.filter((e) => e.kpiData.length > 0);
    configData = configData.concat(ddTabData);

    return configData.length > 0;
  }

  roleAccess =() => {
    /// //////////
    let hasAccess = false;

    (this.props.recordStatus && this.props.recordStatus[0] === 'Publish')
    || (this.props.recordStatus
      && this.props.recordStatus[1] === 'True'
      && !this.props.isManintencneWindowOpen)
    || (this.props.recordStatus
      && this.props.recordStatus[0] === 'Publish'
      && !this.props.isManintencneWindowOpen)
    || this.props.roleReducers.filter((each) => {
      if (each.FeatureName === 'Configurator' && each.Operations.length) {
        return each;
      }
    }).length === 0
    || (this.props.recordStatus
      && this.props.recordStatus[0] === 'Save'
      && this.props.roleReducers.filter((each) => {
        if (each.FeatureName === 'Configurator' && each.Operations.filter((op) => op === 'Submit').length) {
          return each;
        }
      }).length === 0)
    || (this.props.recordStatus
      && this.props.recordStatus[0] === 'Submit'
      && this.props.roleReducers.filter((each) => {
        if (each.FeatureName === 'Configurator' && each.Operations.filter((op) => op === 'Publish').length) {
          return each;
        }
      }).length === 0) ? (
        hasAccess = false
      ) : (
        hasAccess = true
      );

    return hasAccess;
  }



  render() {
    const {
      isVisibleDatepickerDropdownPopover, LastPublishedDate, viewmore, showQcGraph, selectedTab, currentTabDescription, newHelpFeaturesCount, isHelpCountLoaded, disableSubmit, isMetricTableStatus,
    } = this.state;
    const {
      onTabChange, isBoLoaded, isDDLoaded, isHMLoaded, isWOLoaded, isIILoaded, isMPLoaded, filterDetails, titleDescription, QcOverallScore, dataStatus, publishStatus, submitStatus, recordStatus, isManintencneWindowOpen, roleReducers, implementationTeamButtonsEnable, enableremark, submitEnable, IsAdmin, reviewImplementationStatus, SubmitClick, approveImplementationClick, RejectImplementationClick, publishEnable, saveStatus, PublishClick, RejectClick, ConfiguratorSubOfferingData, ConfiguratorOfferingData, TabName,
    } = this.props;
    let tabQcScore = 0;
    let tabUnit = '';
    let hasQcSocreData = false;
   let selectedDataType = store.getState().CurrentFilter.FetchFromBAT ? "BenchmarkAnalytics" : "frontDoorConfigData";



   if (
           QcOverallScore
           && QcOverallScore.QualityScoreDatas
           && QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.length
         ) {
           QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.filter((each) => {
             let returnValue = false;
        if (each.LeverName_En === titleDescription.title) {
    
              if (each.LeverName === 'Diverse Data') {

               if(selectedDataType && selectedDataType === 'BenchmarkAnalytics')
              {

                if(this.props.tabButton == "BenchmarkAnalytics" || this.props.tabButton == "" ){


               tabQcScore = each.AchievedScore_BAT;
               tabUnit = each.Unit;
               hasQcSocreData = true;
               returnValue = true;
             }
             else if( this.props.tabButton == "" || this.props.tabButton == "frontDoorConfigData" ){
              tabQcScore = each.AchievedScore;

              tabUnit = each.Unit;
              hasQcSocreData = true;
              returnValue = true;
             }
            }
            else{

              tabQcScore = each.AchievedScore;
              tabUnit = each.Unit;
              hasQcSocreData = true;
              returnValue = true;
            }
            }
             else{

                tabQcScore = each.AchievedScore;
                tabUnit = each.Unit;
                hasQcSocreData = true;
                returnValue = true;
              }
             
    
            }
            return returnValue;
          });
        }

// if(selectedDataType && selectedDataType === 'BenchmarkAnalytics')
// {
//     if(this.props.tabButton == "BenchmarkAnalytics" || this.props.tabButton == "" ){
//       if (
//        QcOverallScore
//        && QcOverallScore.QualityScoreDatas
//        && QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.length
//      ) {
//        QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.filter((each) => {
//          let returnValue = false;
//          if (each.LeverName_En === titleDescription.title) {

//          if (each.LeverName === 'Diverse Data') {
//            tabQcScore = each.AchievedScore_BAT;
//            tabUnit = each.Unit;
//            hasQcSocreData = true;
//            returnValue = true;
//          }
//          console.log('last22',this.props.tabButton,this.props.QcOverallScore,tabQcScore);

//         }
//          else{
//           if (each.LeverName_En === titleDescription.title) {

//           tabQcScore = each.AchievedScore;
//           tabUnit = each.Unit;
//           hasQcSocreData = true;
//           returnValue = true;
//          }
//          console.log('last',this.props.tabButton,this.props.QcOverallScore,tabQcScore);

//         }
//          console.log('lastconsole1',this.props.tabButton,this.props.QcOverallScore,tabQcScore);

//          return returnValue;
//        });
//      }}
    
//      else{
 
//     if( this.props.tabButton == "" || this.props.tabButton == "frontDoorConfigData" ){
//         if (
//          QcOverallScore
//          && QcOverallScore.QualityScoreDatas
//          && QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.length
//        ) {
//          QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.filter((each) => {
//            let returnValue = false;
//            if (each.LeverName_En === titleDescription.title) {
//              tabQcScore = each.AchievedScore;
//              tabUnit = each.Unit;
//              hasQcSocreData = true;
//              returnValue = true;
//            }
         
//            console.log('lastconsole2',this.props.tabButton,this.props.QcOverallScore,tabQcScore);

//            return returnValue;
//          });
//        }}
//       }}

     
      //  else {
      //   if (
      //     QcOverallScore
      //     && QcOverallScore.QualityScoreDatas
      //     && QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.length
      //   ) {
      //    QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.filter((each) => {
      //      let returnValue = false;
      //      if (each.LeverName_En === titleDescription.title) {
      //        tabQcScore = each.AchievedScore;
      //        tabUnit = each.Unit;
      //        hasQcSocreData = true;
      //        returnValue = true;
      //      }
      //      console.log('lastconsole3',this.props.tabButton,this.props.QcOverallScore,tabQcScore);

      //      return returnValue;

      //    });
      //    } }
      //    console.log('lastconsole4',tabQcScore);


       
    

    const enableConfigButtons = (isBoLoaded
    && isHMLoaded && isWOLoaded && isIILoaded && isDDLoaded
    && isMPLoaded) ? '' : 'disabled';
    if (enableConfigButtons === '' && disableSubmit) {
      setTimeout(() => {
        this.setState({
          disableSubmit: false,
        });
      }, 10000);
    }

    const isConfigDataPresent = this.isDataPresent();
    const hasAccess = this.roleAccess();
    return (
      <div className="row">
        <div className="col-lg-12">
          {createBreadCrumbs(ConfiguratorSubOfferingData, filterDetails.offering, filterDetails.process, ConfiguratorOfferingData) }
          {String(dataStatus) !== String(undefined) && (
            <div className="row">
              <div className="col-sm-7">
                {
                  <h3 className={TabName !== 'Business Outcomes' ? 'stage-heading lever-description-main mb-0' : 'stage-heading mb-0'}>
                    {
                    TabName !== 'Business Outcomes'
                    && <span className="lever-description" data-tip data-for="lever-description-tooltip" />
                  }

                    <div className="lever-description-wrapper">
                      <ReactTooltip place="right" effect="solid" id="lever-description-tooltip">
                        <span>{currentTabDescription}</span>
                      </ReactTooltip>
                    </div>
                    {`${titleDescription.tabName !== undefined ? titleDescription.tabName : TabName}  (${i18n.t('Stage')} - ${dataStatus}`}
                    )
                    <span className="p-l-20">
                      {!publishStatus && !submitStatus ? (
                        (recordStatus && recordStatus[0] === 'Publish')
                          || (recordStatus
                            && recordStatus[1] === 'True'
                            && !isManintencneWindowOpen)
                          || (recordStatus
                            && recordStatus[0] === 'Publish'
                            && !isManintencneWindowOpen)
                          || roleReducers.filter((each) => {
                            let returnValue = false;
                            if (each.FeatureName === 'Configurator' && each.Operations.length) {
                              returnValue = true;
                              return each;
                            }
                            return returnValue;
                          }).length === 0
                          || (recordStatus
                            && recordStatus[0] === 'Save'
                            && roleReducers.filter((each) => {
                              let returnValue = false;
                              if (
                                each.FeatureName === 'Configurator'
                                && each.Operations.filter((op) => op === 'Submit').length
                              ) {
                                returnValue = true;
                                return each;
                              }
                              return returnValue;
                            }).length === 0)
                          || (recordStatus
                            && recordStatus[0] === 'Submit'
                            && roleReducers.filter((each) => {
                              let returnValue = false;
                              if (
                                each.FeatureName === 'Configurator'
                                && each.Operations.filter((op) => op === 'Publish').length
                              ) {
                                returnValue = true;
                                return each;
                              }
                              return returnValue;
                            }).length === 0)
                          || (implementationTeamButtonsEnable
                            && recordStatus[0] === 'Review(Implementation)') ? (
                            ''
                          ) : (
                            <button
                              className="btn btn-primary btn-sm mr-2 button-get-data"
                              ref={this.datepickerDropdownPopoverTarget}
                              onClick={this.onGetData}
                              type="button"
                            >
                              {i18n.t('Get_Data')}
                              <span className="p-l-10">
                                <i className="fas fa-chevron-down" />
                              </span>
                            </button>
                          )
                      ) : null}
                    </span>
                  </h3>
                }

                {isVisibleDatepickerDropdownPopover ? (
                  <CustomDatePicker
                    datepickerDropdownPopoverTarget={this.datepickerDropdownPopoverTarget}
                    isVisibleDatepickerDropdownPopover={isVisibleDatepickerDropdownPopover}
                    onGetData={this.onGetData}
                    // onTabChange={onTabChange}
                  />
                ) : (
                  ''
                )}
                <span className="publishedOn">
                  {' '}
                  {String(dataStatus) !== 'Published' ? (
                    <span>
                      {LastPublishedDate ? (
                        <span>
                          {i18n.t('Last_Published_on')}
                          :
                          {FormatDateNoTime(LastPublishedDate)}
                        </span>
                      ) : (
                        ''
                      )}
                    </span>
                  ) : (
                    ''
                  )}
                </span>
              </div>
              <div className="col-sm-5 pl-0">
                <div className="float-right d-flex align-items-center">
                  {!disableSubmit && isConfigDataPresent && hasAccess && (
                  <span className="d-flex">
                    <span className="metric-lever-table" onClick={(e) => this.isMetricTableShow(e)}>
                      <span className="tableview" />
                    </span>
                    <span className="border-line-end publish-border" />
                  </span>
                  )}
                  {hasQcSocreData ? (
                    <span className="qcscore-status">
                      <span
                        onClick={this.showQcGraph.bind(this, true, titleDescription.title)}
                        onKeyDown={this.showQcGraph.bind(this, true, titleDescription.title)}
                        role="button"
                        tabIndex={0}
                        className={String(dataStatus) !== 'Published' ? (
                          tabQcScore < 50
                            ? 'qcscore-percent red-status'
                            : tabQcScore >= 50 && tabQcScore <= 75
                              ? 'qcscore-percent orange-status'
                              : 'qcscore-percent green-status'
                        ) : (tabQcScore < 50
                          ? 'mr-1 qcscore-percent red-status'
                          : tabQcScore >= 50 && tabQcScore <= 75
                            ? 'mr-1 qcscore-percent orange-status'
                            : 'mr-1 qcscore-percent green-status')}
                      >
                        QC Score
                        {' '}
                        {tabQcScore}
                        {tabUnit}
                      </span>
                    </span>
                  ) : (
                    ''
                  )}
                  {enableremark && (
                    <span className="p-r-20 colour-red" onClick={this.viewMore.bind(this)} role="button" tabIndex={0}>
                      <FontAwesomeIcon icon={faInfoCircle} title={i18n.t('Remarks')} />
                    </span>
                  )}
                  {viewmore && (
                    <RemarksPopUp show onClose={this.onClose} filterDetails={filterDetails} />
                  )}
                  {(submitEnable && !submitStatus && !publishStatus)
                    || (submitEnable && !publishStatus && String(IsAdmin) === String(true)) ? (
                      <>
                        <span className="border-line-end publish-border" />
                        {disableSubmit && <img className="userlistloader" src={Spinner} alt="Loading..." width="20" height="20" />}
                        <button
                          className="btn btn-primary btn-sm button-get-data mr-2"
                          id="btnsubmit"
                          type="button"
                          onClick={SubmitClick}
                          disabled={disableSubmit}
                        >
                          {i18n.t('Submit')}
                        </button>
                      </>
                    ) : null}

                  {/* New approve and reject buttons for implementation team */}

                  {(reviewImplementationStatus && implementationTeamButtonsEnable)
                    || (reviewImplementationStatus && String(IsAdmin) === String(true)) ? (
                      <button
                        type="button"
                        className="btn btn-primary btn-sm mr-2 button-get-data"
                        onClick={(e) => {
                          approveImplementationClick(e);
                        }}
                      >
                        {i18n.t('Approve')}
                      </button>
                    ) : null}

                  {(reviewImplementationStatus && implementationTeamButtonsEnable)
                    || (reviewImplementationStatus && String(IsAdmin) === String(true)) ? (
                      <button
                        type="button"
                        className="btn btn-primary btn-sm mr-2 button-get-data"
                        disabled={disableSubmit}
                        onClick={(e) => {
                          RejectImplementationClick(e);
                        }}
                      >
                        {i18n.t('Reject')}
                      </button>
                    ) : null}

                  {/* New approve and reject buttons for implementation team end */}
                  {publishEnable
                    && !publishStatus
                    && !saveStatus
                    && String(dataStatus) === 'In Review' ? (
                      <button
                        type="button"
                        className="btn btn-primary btn-sm button-get-data mr-2"
                        disabled={disableSubmit}
                        id="btnpublish"
                        onClick={PublishClick}

                      >
                        {i18n.t('Publish')}
                      </button>
                    ) : null}
                  {publishEnable
                    && !saveStatus
                    && !publishStatus
                    && String(dataStatus) === 'In Review' ? (
                      <button
                        type="button"
                        className="btn btn-primary btn-sm mr-2 button-get-data"
                        disabled={disableSubmit}
                        onClick={(e) => {
                          RejectClick(e);
                        }}
                      >
                        {i18n.t('Reject')}
                      </button>
                    ) : null}
                  <NavLink
                    to={{
                      pathname: '/helpfeatures',
                      state: {
                        name: titleDescription.title,
                      },
                    }}
                  >
                    <span className="help-notification">
                      {isHelpCountLoaded
                        && (
                        <a data-tip data-for="help-notification-tooltip" href="javascript:void(0)">
                          {newHelpFeaturesCount > 0
                            && (
                            <span>
                              {newHelpFeaturesCount > 9 ? i18n.t('9+') : newHelpFeaturesCount}
                            </span>
                            )}
                        </a>
                        )}
                    </span>
                    {isHelpCountLoaded
                      && (
                      <ReactTooltip id="help-notification-tooltip" effect="solid" place="left">
                        <span>
                          {newHelpFeaturesCount > 0
                            ? (`${newHelpFeaturesCount} ${i18n.t('New')} ${
                              newHelpFeaturesCount === 1 ? i18n.t('Feature') : i18n.t('Features')} ${
                              i18n.t('updated.')} ${i18n.t('Click here for details')}`)
                            : i18n.t('Click here for updates')}
                        </span>
                      </ReactTooltip>
                      )}
                  </NavLink>

                </div>
              </div>
            </div>
          )}
          {showQcGraph ? (
            <TabQCScore showQcGraph={this.showQcGraph} selectedTab={selectedTab} />
          ) : (
            ''
          )}
          {isMetricTableStatus ? (
            <Modal
              show={isMetricTableStatus}
              onHide={(e) => this.isMetricTableClose(e)}
              backdrop="static"
              dialogClassName="modal-95w"
              className="metric-grid-view"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Metric Grid View</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <MetricGridView
                  sourceOptions={this.state.sourceOptions}
                  onClose={() => this.isMetricTableClose()}
                  dataStatus={dataStatus}
                />
              </Modal.Body>
            </Modal>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}
ContentHeader.propTypes = {

  filterDetails: PropTypes.object.isRequired,
  titleDescription: PropTypes.object.isRequired,
  QcOverallScore: PropTypes.object.isRequired,
  dataStatus: PropTypes.string.isRequired,
  publishStatus: PropTypes.bool.isRequired,
  submitStatus: PropTypes.bool.isRequired,
  recordStatus: PropTypes.array.isRequired,
  isManintencneWindowOpen: PropTypes.bool.isRequired,
  roleReducers: PropTypes.array.isRequired,
  implementationTeamButtonsEnable: PropTypes.bool.isRequired,
  enableremark: PropTypes.bool.isRequired,
  submitEnable: PropTypes.bool.isRequired,
  IsAdmin: PropTypes.bool.isRequired,
  reviewImplementationStatus: PropTypes.bool.isRequired,
  SubmitClick: PropTypes.func.isRequired,
  approveImplementationClick: PropTypes.func.isRequired,
  RejectImplementationClick: PropTypes.func.isRequired,
  publishEnable: PropTypes.bool.isRequired,
  saveStatus: PropTypes.bool.isRequired,
  PublishClick: PropTypes.func.isRequired,
  RejectClick: PropTypes.func.isRequired,
  ConfiguratorOfferingData: PropTypes.array.isRequired,
  ConfiguratorSubOfferingData: PropTypes.array.isRequired,
  TabName: PropTypes.string.isRequired,
  isBoLoaded: PropTypes.bool.isRequired,
  isHMLoaded: PropTypes.bool.isRequired,
  isIILoaded: PropTypes.bool.isRequired,
  isMPLoaded: PropTypes.bool.isRequired,
  isWOLoaded: PropTypes.bool.isRequired,
  isDDLoaded: PropTypes.bool.isRequired,
  onTabChange: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  QcOverallScore: { ...state.CurrentFilter.QcOverallScore },
  currentFilter: state.CurrentFilter.SyncFilter,
  clientData: state.CurrentFilter.ClientData,
  TabName: state.CurrentFilter.Tab.title,
  ConfiguratorOfferingData: state.FilterReducer && state.FilterReducer.ConfiguratorOfferingData ? state.FilterReducer.ConfiguratorOfferingData : [],
  ConfiguratorSubOfferingData: state.FilterReducer && state.FilterReducer.ConfiguratorSubOfferingData ? state.FilterReducer.ConfiguratorSubOfferingData : [],
  boData: state.BOReducers.BO,
  hmData: state.humanReducers.humanandmachine,
  woData: state.WOtileReducers.tile,
  iniData: state.intelligentInsightReducers.insightandintelligence,
  ddData: state.diverseReducers.diverse,
  FetchFromBAT: state.CurrentFilter.FetchFromBAT,
  tabButton: state.CurrentFilter.selectedDataType,

});

export default connect(
  mapStateToProps,
  '',
)(ContentHeader);
