import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { LocalApiBaseUrl } from '../Shared/Constant';


import Axios from 'axios';
import { store } from "../reducers/configureStore"
import Header from '../Shared/Header';
import axiosInstance from '../Shared/interceptor'
import { regexForTextAreas } from "../Shared/Constant";
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;




export default class ApprovalPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // ClientOptions: [],
            // RoleOptions: [],
            show: this.props.show, apivalue: "", selectValue: this.props.SelectValue, Display: false,
            errors: [],
            formIsValid: false,
            Approval: {

                ApproverRemarks: "",
                DealMetricGroupID: [],
                ApprovalType: null,
                ApprovalAction: false,

            }
        }

        this.onDealIsActiveChange = this.onDealIsActiveChange.bind(this);
        this.onGlobalIsActiveChange = this.onGlobalIsActiveChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    validateForm() {
        debugger
        let Approval = this.state.Approval;
        let errors = {};
        let formIsValid = true;

        if (!(Approval["ApprovalType"]) && this.props.action == 'Approve') {
            formIsValid = false;
            errors["error"] = "Choose one option";
        }

        if (!(Approval["ApproverRemarks"]).trim() && !(this.props.action == 'Approve')) {
            formIsValid = false;
            errors["ApproverRemarks"] = "Approver Remarks are required";

        }
        else {
            var isValid = regexForTextAreas.test(Approval["ApproverRemarks"]);
            if (!isValid && !(this.props.action == 'Approve') ) {
                formIsValid = false;
                errors["ApproverRemarks"] =
                    "Approver Remarks contains Special characters";
            }
        }

        this.setState({
            errors: errors
        });
        if (formIsValid) {
            this.setState({
                formIsValid: true
            })
        }
        else {
            this.setState({
                formIsValid: false
            })
        }
        return formIsValid;

    }

    onDealIsActiveChange(event) {
        
        let errors = {};
        this.setState({
            errors: errors
        });
        if (event.target.checked) {
            const Approval = this.state.Approval;
            // Approval.DealIsActive = event.target.checked;
            Approval.ApprovalType = "Deal"
            this.setState({
                Approval: Approval
            })
        }

    }

    onGlobalIsActiveChange(event) {
        
        let errors = {};
        this.setState({
            errors: errors
        });
        if (event.target.checked) {
            const Approval = this.state.Approval;
            // Approval.GlobalIsActive = event.target.checked;
            Approval.ApprovalType = "Global";
            this.setState({
                Approval: Approval
            })
        }

    }



    onRemarksChange(event) {
        
        this.setState({ errors: {} });
        // const deal = this.props.dealData[0];
        const Approval = this.state.Approval;

        Approval.ApproverRemarks = event.target.value;
        this.setState({
            Approval: Approval
        })

    }

    handleSave() {
        
        if (this.validateForm()) {

            if (this.props.action == 'Approve') {
                this.state.Approval.ApprovalAction = true;
                this.props.onSave(this.state.Approval)
                this.handleHide();
            }
            else {

                this.state.Approval.ApprovalType = null
                this.state.Approval.ApprovalAction = false;
                this.props.onSave(this.state.Approval)
                this.handleHide();
            }
        }
    }



    handleHide(e) {
        this.setState({ show: false });
        if (e != undefined) {

            if ((e.target.textContent != "×")) {
                this.props.onClose(e);
            }
            else {
                this.props.onClose(e);
            }
        } else {
            this.props.onClose(e);
        }

    }
    handleRejectHide(e) {


        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onRejectClose();
            }
        } else {
            this.props.onRejectClose();
        }

    }
    handleApproveHide(e) {

        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onApproveClose();
            }
        } else {
            this.props.onApproveClose();
        }

    }
    passToConfirmation(data) {
        this.props.enteredData(data.PoNumber)
    }



    // getRoles() {
    //     let samp = {};
    //     var EnterpriseID = sessionStorage.getItem("EnterpriseID")
    //     if (EnterpriseID != undefined) {
    //         axiosInstance.get(`${LocalApiBaseUrl}user/GetRoles`, {
    //             params: {}
    //         })

    //             .then((response) => {
    //                 this.setState({ RoleOptions: response.data });
    //             });
    //     }
    // }

    // getClients() {
    //     axiosInstance.get(`${LocalApiBaseUrl}User/GetClients`)
    //         .then((response) => {
    //             this.setState({ ClientOptions: response.data });
    //         });
    // }

    componentDidMount() {
        
        this.props.data.map((row) => {
            this.state.Approval.DealMetricGroupID.push(row.DealMetricGroupID)
        })
        // this.getClients();
        // this.getRoles();
    }

    componentWillMount() {
        this.setState({
            Approval: {
                ApproverRemarks: "",
                DealMetricGroupID: [],
                ApprovalType: null,
                ApprovalAction: false,            },
        })
    }

    render() {

        return (

            <>
                <Modal show={this.state.show} onHide={this.handleHide.bind(this)} className="modal fade" size="medium" id="dealpopup">
                    <Modal.Header closeButton>
                        {this.props.action == 'Approve' ? <h4 className="modal-title" id="dealprofile" flag >Approval</h4>
                            : <h4 className="modal-title" id="dealprofile" flag >Decline</h4>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            {this.props.action == 'Approve' ?

                                <div className="form-section">

                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label className="form-check">
                                            <input className="form-check-input" type="radio" name="foo" value="bar2" onChange={this.onDealIsActiveChange} />
                                                <span className="checkmark"></span>
                                                <span className="form-check-label">Deal</span>
                                            </label>
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label className="form-check">
                                                <input className="form-check-input" type="radio" name="foo" value="bar3" onChange={this.onGlobalIsActiveChange} />
                                                <span className="checkmark"></span>
                                                <span className="form-check-label">Global</span>
                                            </label>
                                        </div>
                                    </div>
                                    <span className="errorMsg">{this.state.errors.error}</span>

                                </div>
                                : null}

                            {this.props.action == 'Approve' ?
                                <div className="form-section">
                                    <div className="row">
                                        {/* <div className="form-group col-lg-6">
                                        <label for="">Requestor Remarks<span className="required">*</span></label>
                                        <div><textarea maxLength="200" readOnly className="form-control" id="remark" value={this.props.data[0].BusinessReason} /></div>
                                    </div> */}
                                        <div className="form-group col-lg-6">
                                            <label for="">Approver Remarks<span className="required"></span></label>
                                            {/* <div><input maxLength="50" type="text" className="form-control" id="AccentureSDL" value={this.props.dealData[0].ApproverRemarks} onChange={this.onRemarksChange.bind(this)} /></div> */}
                                            <textarea maxLength="200" className="form-control" value={this.state.Approval.ApproverRemarks} onChange={this.onRemarksChange.bind(this)} />
                                            <span className="errorMsg">{this.state.errors.ApproverRemarks}</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="form-section">
                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label for="">Approver Remarks<span className="required">*</span></label>
                                            {/* <div><input maxLength="50" type="text" className="form-control" id="AccentureSDL" value={this.props.dealData[0].ApproverRemarks} onChange={this.onRemarksChange.bind(this)} /></div> */}
                                            <textarea maxLength="200" className="form-control" value={this.state.Approval.ApproverRemarks} onChange={this.onRemarksChange.bind(this)} />

                                            <span className="errorMsg">{this.state.errors.ApproverRemarks}</span>
                                        </div>
                                    </div>
                                </div>
                            }



                        </form>
                    </Modal.Body>
                    {<Modal.Footer>
                        <div className="modal-btn">
                                <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value="Save" />
                        </div>
                    </Modal.Footer>}
                </Modal>
            </>
        )
    }
}
