import React, { Component } from "react";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import ConnectorMasterList from "../ConnectorMaster/ConnectorMasterList";
import OneTimeList from "../ConnectorMaster/OneTimeList";
import DealConnectorList from "../DealConnector/DealConnectorList";
import DealFilterList from "../QueryBuilder/DealFilterList";
import { LocalApiBaseUrl } from "../Shared/Constant";
import ConnectorFieldMasterList from "../ConnectorFieldMaster/ConnectorFieldMasterList";
import ToolConfiguratorList from "../ToolConfigurator/ToolConfiguratorList";

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class ConnectorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: {},
      roleData: [],

      featureData: []
    };

    this.getRoleData = this.getRoleData.bind(this);
  }

  getRoleData() {
    var data = store.getState();
    let roleData1 = data.roleReducers["roleReducers"];
    this.setState({ featureData: roleData1 });

    store.subscribe(() => {
      var data = store.getState();
      this.setState({ roleData: data.roleReducers["roleReducers"] });
      let roleData1 = data.roleReducers["roleReducers"];
      this.setState({ featureData: roleData1 });
    });
  }
  GetUserAccessDetails() {
    axiosInstance
      .get(`${LocalApiBaseUrl}ConnectorMapping/GetUserAccessDetails`)
      .then((res) => {
        if (res.data) {

          this.setState({ userRole: res.data });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  componentWillMount() {
    this.getRoleData();
  }

  componentDidMount() {
    this.getRoleData();
    //this.GetUserAccessDetails();
  }
  componentDidUpdate(prevProps){
    const {
      ConnectorClicked,
      ConnectorFieldMasterDataClicked,
      DealConnectorClicked,
      DealFilterClicked,
      OneTimeSetupClicked,      
      ToolconfiguratorClicked
      } = this.props;
    if(ConnectorClicked !== prevProps.ConnectorClicked || ConnectorFieldMasterDataClicked !== prevProps.ConnectorFieldMasterDataClicked
        || DealConnectorClicked !== prevProps.DealConnectorClicked || DealFilterClicked !== prevProps.DealFilterClicked
        || OneTimeSetupClicked !== prevProps.OneTimeSetupClicked || ToolconfiguratorClicked!== prevProps.ToolconfiguratorClicked
      ){
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <>
        {
          <>
            {this.props.ConnectorClicked &&
              this.state.featureData.filter(
                (data) => data.Submenu == "Configure Source Parameter" && data.Operations.length != 0
              ).length > 0 ? (
              <ConnectorMasterList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.OneTimeSetupClicked &&
              this.state.featureData.filter((data) => data.Submenu == "One-Time Setup" && data.Operations.length != 0)
                .length > 0 ? (
              <OneTimeList
                filterDetails={this.props.filterDetails}
                featureData={this.state.featureData}
              //userRole={this.state.userRole}
              />
            ) : null}

            {this.props.DealFilterClicked &&
              this.state.featureData.filter((data) => data.Submenu == "Deal Filter" && data.Operations.length != 0)
                .length > 0 ? (
              <DealFilterList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}

            {this.props.DealConnectorClicked &&
              this.state.featureData.filter((data) => data.Submenu == "Build Query" && data.Operations.length != 0)
                .length > 0 ? (
              <DealConnectorList
                filterDetails={this.props.filterDetails}
                featureData={this.state.featureData}
                selectedClientId={this.props.selectedClientId}
              />
            ) : null}

            {this.props.ConnectorFieldMasterDataClicked &&
              this.state.featureData.filter(
                (data) => data.Submenu == "Connector Field Master Data" && data.Operations.length != 0
              ).length > 0 ? (
              <ConnectorFieldMasterList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}

            {this.props.ToolconfiguratorClicked &&
              this.state.featureData.filter((data) => data.Submenu == "Tool Configurator" && data.Operations.length != 0)
                .length > 0 ? (
              <ToolConfiguratorList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
          </>
        }
      </>
    );
  }
}
