import React from "react";
import _ from "lodash";
import classNames from "classnames";
import { Modal, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPlus,
	faTrashAlt,
	faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import i18n from "i18next";
import LoadingOverlay from "react-loading-overlay";

import { LocalApiBaseUrl } from "../Shared/Constant";
import * as humanActions from "../actions/humanActions";
import { store } from "../reducers/configureStore";
import Header from "../Shared/Header";
import axiosInstance from "../Shared/interceptor";
import {trycatchAlertPopup} from "../Shared/Constant";
import KpiPopUp from "../SuperAdmin/KpiPopUp";
import IIMetricGroupPopUp from "./IIMetricGroupPopUp";
import { regexForNumbersOnlyMTC, regexForFields } from "../Shared/Constant";
import { DisplayMessage } from "../Shared/commonFunctions";
import SynopsLoader from "../Shared/SynopsLoader";

// const ERR_MSG_METRIC_DEF_INVALID_VALUE = `Only alphabets, digits and @ $ % & () \n \- _ , . ? ‘ ” \' \"/ are allowed`;
// const ERR_MSG_METRIC_DEF_INVALID_VALUE = i18n.t("MultiDefErrMsg1") + " "+ `@ $ % & () \n \- _ , . ? ‘ ” \' \"/`+ " "+i18n.t("MultiDefErrMsg2")  ;


import { isValidText,InvalidDescription } from "../Shared/commonFunctions"; 
const maxLengthMetricDefinitionFields = 500;

function validateMetricDefinitionFieldValue(str) {
	return regexForFields.test(str);
}

// THE FIELDS FOR A SINGLE METRIC DEFINITION
const singleMetricDefinition = {
	isDisabledNumeratorRadioField: false,
	selectedDefinition: "numeratorDefinition",
	numeratorDefinition: "",
	isRequiredNumeratorDefinitionField: false,
	errMsgNumeratorDefinitionField: "",
	denominatorDefinition: "",
	isRequiredDenominatorDefinitionField: false,
	errMsgDenominatorDefinitionField: "",
	isEnabled: "yes",
};

let samp = {};
class InsightIntelligencePopUp extends React.Component {
	constructor(props) {
		super(props);
		this.ERR_MSG_METRIC_DEF_INVALID_VALUE = i18n.t("MultiDefErrMsg1") + " "+ `@ $ % & () \n \- _ , . ? ‘ ” \' \"/`+ " "+i18n.t("MultiDefErrMsg2")  ;

		this.state = {
			title: "",
			show: this.props.show,
			unitsData:[],
			apivalue: "",
			message: "",
			viewmore: false,
			selectValue: this.props.SelectValue,
			isCheck: true,
			Display: false,
			categoriesData: [],
			SOCat: [],
			fields: {},
			type: "",
			isKpi: "",
			addKpiData: [],
			kpiDetails: [],
			valueConnector: "",
			categoryVisible: false,

			errors: {},
			formIsValidMetric: false,
			addNewMetricGroup: false,
			MetricNameOptions: [],
			formHasValidName: true,
			formIsValidInsight: false,
			SOCategoryID: "",
			isMetricGroupNameChanged: false,
			iiData: {
				SOCategoryID: "",
				ParentSOCategoryID: "",
				SOCategoryName: "",
				SOCategoryDesp: "",
				SoCategoryImage: "",
				SOCategoryTypeID: "",
				SequenceID: "",
				SOCategorySeq: null,

				kpiData: {
					LeadingPractice: "",
				},
			},
			// MULTIPLE METRIC DEFINITIONS WHICH ARE DYNAMICALLY ADDED
			metricDefinitions: [
				{
					..._.cloneDeep(singleMetricDefinition),
					isRequiredNumeratorDefinitionField:
						props.defaultIsRequiredMetricDefinitionFields,
					isRequiredDenominatorDefinitionField:
						props.defaultIsRequiredMetricDefinitionFields,
				},
			],
			areAnyDuplicateMetricDefinitions: false,
			isAPILoading: false,
		};

		this.onNameChange = this.onNameChange.bind(this);
		this.onDescriptionChange = this.onDescriptionChange.bind(this);
		this.onSequenceChange = this.onSequenceChange.bind(this);
		this.onMetricGroupChange = this.onMetricGroupChange.bind(this);
		this.onMetricNameChange = this.onMetricNameChange.bind(this);
		this.onMetricSequenceChange = this.onMetricSequenceChange.bind(this);
		this.onMetricDescChange = this.onMetricDescChange.bind(this);
		this.onIconChange = this.onIconChange.bind(this);
		this.onUnitChange = this.onUnitChange.bind(this);
		this.onValueChange = this.onValueChange.bind(this);
		this.onValueDescriptionChange = this.onValueDescriptionChange.bind(this);
		this.onStatusChange = this.onStatusChange.bind(this);
		this.onUpperChange = this.onUpperChange.bind(this);
		this.onLowerChange = this.onLowerChange.bind(this);
		this.onRadioCheck = this.onRadioCheck.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleSaveInsight = this.handleSaveInsight.bind(this);
		this.isMetricGrpNameChanged = this.isMetricGrpNameChanged.bind(this);
		this.getKpiMAppingDetails = this.getKpiMAppingDetails.bind(this);
		this.onAttribute1ValueChange = this.onAttribute1ValueChange.bind(this);
		this.onAttribute2ValueChange = this.onAttribute2ValueChange.bind(this);
	}

	onClose(e) {
		this.setState({ viewmore: false });
		this.props.onClose(e);
	}

	onAttribute1ValueChange(event) {
		let errors = {};
		this.setState({
			errors: errors,
		});

		let iiData = this.state.iiData;
		iiData.kpiData.Attribute1Value = event.target.value;
		this.setState({
			iiData: iiData,
		});
	}

	onAttribute2ValueChange(event) {
		let errors = {};
		this.setState({
			errors: errors,
		});
		let iiData = this.state.iiData;
		iiData.kpiData.Attribute2Value = event.target.value;

		this.setState({
			iiData: iiData,
		});
	}

	componentWillMount() {
		if (this.props.filterDetails != undefined)
			if (
				this.props.filterDetails.Client != 0 &&
				this.props.filterDetails.Locations != 0 &&
				this.props.filterDetails.Offering != 0 &&
				this.props.filterDetails.Process != 0
			) {
				this.setState({ Display: true });
			}
		let apivalue = this.props.opsgrp;

		this.setState({ apivalue });
	}

	componentDidMount() {
		//this.getKpiMAppingDetails();
		if (this.props.Details) {
			this.setState({
				iiData: this.props.Details,
				categoriesData: this.props.AllCategoryData,
				errors: {},
				SOCat: SOCatlist1,
				formIsValidMetric: true,
				formIsValidInsight: true,
				//type: this.props.Details.ParentSOCategoryID == 19 ? "Analytics Apps & AI Advisors" : "Other Process Insights"
			});
		}
		let SOCatlist1 = [];

		let allData = this.props.AllCategoryData.filter(
			(IIDetails) => IIDetails.ParentSOCategoryID === 4
		);

		let AnalyticsAppsAIAdvisors =
			allData != undefined ? (allData.length > 0 ? allData[0] : {}) : {};
		//response.data.map((obj) => { return obj.ChildSOCategories.filter((child) => child.SOCategoryName === "Analytics Apps & AI Advisors") })
		let OtherProcessInsights =
			allData != undefined ? (allData.length > 1 ? allData[1] : {}) : {};
		let title = "";
		if (AnalyticsAppsAIAdvisors != undefined && this.props.type == "0") {
			title = AnalyticsAppsAIAdvisors.SOCategoryName;

			let AnalyticsAppsAIAdvisorsID = AnalyticsAppsAIAdvisors.SOCategoryID;

			let AnalyticsAppsAIAdvisorsChilds = this.props.AllCategoryData.filter(
				(IIDetails) =>
					IIDetails.ParentSOCategoryID === AnalyticsAppsAIAdvisorsID ||
					IIDetails.SOCategoryID === AnalyticsAppsAIAdvisorsID
			);
			AnalyticsAppsAIAdvisorsChilds.map((row, index) => {
				SOCatlist1.push(
					<option value={row.SOCategoryName}>{row.SOCategoryName}</option>
				);
				//return (SOCatlist1);
			});
		}
		if (OtherProcessInsights != undefined && this.props.type == "1") {
			//title = OtherProcessInsights.SOCategoryName;

			let OtherProcessInsightsID = OtherProcessInsights.SOCategoryID;
			let OtherProcessInsightsChilds = this.props.AllCategoryData.filter(
				(IIDetails) =>
					IIDetails.ParentSOCategoryID === OtherProcessInsightsID ||
					IIDetails.SOCategoryID === OtherProcessInsightsID
			);

			OtherProcessInsightsChilds.map((row, index) => {
				SOCatlist1.push(
					<option value={row.SOCategoryName}>{row.SOCategoryName}</option>
				);
				//return (SOCatlist1);
			});
		}

		if (this.props.setCategory != "") {
			let iiData = this.state.iiData;
			title = this.props.setCategory
			iiData.SOCategoryName = this.props.setCategory;
			this.getSOCategoryId({ target: { value: this.props.setCategory } });
			this.getKpiMAppingDetails();
			this.setState({
				iiData: iiData,
			});
		}

		this.setState({
			title: title,
			SOCat: SOCatlist1,
			isKpi: this.props.isKpi,
			addKpiData: this.props.Details.kpiData,
		});

		if (!this.props.Details) {
			this.setState({
				type: this.props.type,
			});
		}
		let unitsData = this.props.unitsData;
		this.setState({unitsData:unitsData})
	}
	componentDidUpdate(prevProps){
		if(prevProps.unitsData !== this.props.unitsData){
			this.setState({unitsData:this.props.unitsData})
		}
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.filterDetails != undefined)
			if (
				nextProps.filterDetails.Client != 0 &&
				nextProps.filterDetails.Locations != 0 &&
				nextProps.filterDetails.Offering != 0 &&
				nextProps.filterDetails.Process != 0
			) {
				this.setState({ Display: true });
			}
	}

	onNameChange(event) {
		let errors = {};
		this.setState({
			errors: errors,
		});

		let iiData = this.state.iiData;
		this.isMetricGrpNameChanged(event);

		iiData.SOCategoryName = event.target.value;
		this.setState({
			iiData: iiData,
		});
	}

	getSOCategoryId(event) {
		let iiData = this.state.iiData;
		let errors = {};
		let formIsValidMetric = true;

		var data = store.getState();
		let iiDetails =
			data.intelligentInsightMasterReducers["insightandintelligence"];
		this.state.isMetricGroupNameChanged = true;
		if (iiDetails.length > 0) {

			var a = iiDetails.filter(
				(data) => data.SOCategoryName == event.target.value
			)[0].SOCategoryID;
		}
		this.state.SOCategoryID = a;
	}

	isMetricGrpNameChanged(event) {
		let iiData = this.state.iiData;
		let errors = {};
		let formIsValidMetric = true;

		var data = store.getState();
		let iiDetails =
			data.intelligentInsightMasterReducers["insightandintelligence"];
		this.state.isMetricGroupNameChanged = true;
		if (iiDetails.length > 0) {
			if (this.state.isMetricGroupNameChanged && this.props.Details) {
				let oldData1 = iiDetails.map(function(el) {
					return el.SOCategoryName;
				});
				if (oldData1.includes(event.target.value)) {
					this.state.formHasValidName = false;
					errors["MetricGroupExists"] = "Name already exists";
				}
			}
		}
	}

	onDescriptionChange(event) {
		let errors = {};
		this.setState({
			errors: errors,
		});
		let iiData = this.state.iiData;
		iiData.SOCategoryDesp = event.target.value;
		this.setState({
			iiData: iiData,
		});
	}

	onSequenceChange(event) {
		let errors = {};
		this.setState({
			errors: errors,
		});
		let iiData = this.state.iiData;
		iiData.SequenceID = event.target.value;
		this.setState({
			iiData: iiData,
		});
	}

	onMetricGroupChange(event) {
		let errors = {};
		this.setState({
			errors: errors,
		});

		let iiData = this.state.iiData;
		// iiData.SOCategoryName = this.state.iiData.SOCategoryName;

		if (event.target.value !== "-1") {
			iiData.SOCategoryName = event.target.value;
			this.getSOCategoryId(event);
			this.getKpiMAppingDetails();
			this.setState({
				iiData: iiData,
			});
		} else {
			this.setState({
				addNewMetricGroup: true,
				show: false,
			});
		}
	}

	getKpiMAppingDetails() {
		let samp = {};
		let valueConnector = "";
		axiosInstance
			.get(`${LocalApiBaseUrl}Configurator/GetKpiMapping`, {
				params: {
					OrgEntityID: this.props.filterDetails.Client,
					LocationID: this.props.filterDetails.Locations,
					OfferingID: this.props.filterDetails.process,
					SOCategoryID: this.state.SOCategoryID,
				},
			})
			.then((response) => {
				if (response.data.length != 0) {
					if (
						response.data[0].IsConnector == true &&
						response.data[0].IsTransaction == false
					) {
						valueConnector = true;
					}
				}

				this.setState({
					MetricNameOptions: response.data,
					valueConnector: valueConnector,
				});
			})
			.catch((error) => {
				trycatchAlertPopup(error);
			  });
	  
	  
	}

	onMetricNameChange(event) {
		let errors = {};
		this.setState({
			errors: errors,
		});
		let iiData = this.state.iiData;
		iiData.kpiData.MetricName = event.target.value;
		this.setState({
			iiData: iiData,
		});
	}

	// onMetricNameChange(event) {
	//     let errors = {};
	//     this.setState({
	//         errors: errors
	//     });
	//     let iiData = this.state.iiData;
	//     iiData.SOCategoryName = this.state.iiData.SOCategoryName;
	//     iiData.kpiData.MetricName = event.target.value;
	//     this.setState({
	//         iiData: iiData
	//     })
	// }

	onMetricSequenceChange(event) {
		let errors = {};
		this.setState({
			errors: errors,
		});
		let iiData = this.state.iiData;
		iiData.kpiData.MetricSeq = event.target.value;
		this.setState({
			iiData: iiData,
		});
	}

	onMetricDescChange(event) {
		let errors = {};
		this.setState({
			errors: errors,
		});
		let iiData = this.state.iiData;
		iiData.kpiData.KPIDesp = event.target.value;
		this.setState({
			iiData: iiData,
		});
	}

	onIconChange(event) {}
	onValueDescriptionChange(event) {
		let iiData = this.state.iiData;
		iiData.MetricDataDesp = event.target.value;
		this.setState({
			iiData: iiData,
		});
	}
	onUnitChange(event) {
		let errors = {};
		this.setState({
			errors: errors,
		});
		let iiData = this.state.iiData;
		iiData.kpiData.MetricUnit = event.target.options[event.target.selectedIndex].value;
		this.setState(
			{
				iiData: iiData,
			},
			() => {
				this.toggleRequiredMetricDefinitionFields();
				this.clearMetricDefinitionFieldsErrors();
			}
		);
	}

	onValueChange(event) {
		let errors = {};
		this.setState({
			errors: errors,
		});
		let iiData = this.state.iiData;
		iiData.kpiData.MetricValue = event.target.value;
		this.setState({
			iiData: iiData,
		});
	}

	onStatusChange(event) {
		let errors = {};
		this.setState({
			errors: errors,
		});
		let iiData = this.state.iiData;
		iiData.kpiData.RAGStatus = event.target.value;
		this.setState({
			iiData: iiData,
		});
	}

	onUpperChange(event) {
		let iiData = this.state.iiData;
		iiData.kpiData.UpperThreshold = event.target.value;
		this.setState({
			iiData: iiData,
		});
	}

	onLowerChange(event) {
		let iiData = this.state.iiData;
		iiData.kpiData.LowerThreshold = event.target.value;
		this.setState({
			iiData: iiData,
		});
	}

	handleSave(e) {
		if (this.validateFormMetricGroup()) {
			let filterProcess = this.props.filterDetails.offering;

			var iiData = {
				SOCategoryID: this.state.iiData.SOCategoryID,
				//ParentSOCategoryID: this.state.type == "Analytics Apps & AI Advisors" ? 19 : 20,
				SOCategoryName: this.state.iiData.SOCategoryName,
				SOCategoryDesp: this.state.iiData.SOCategoryDesp,
				SoCategoryImage: null,
				SOCategoryTypeID: 1,
				SequenceID: null,
				SOCategorySeq: this.state.iiData.SOCategorySeq,
				kpiData: [
					// {
					//     KPIMappingID: this.state.iiData.kpiData.MetricDataID,
					//     MetricSeq: this.state.iiData.kpiData.MetricSeq,
					//     MetricID: 66,
					//     MetricName: this.state.iiData.kpiData.MetricName,
					//     FrequencyID: 3,
					//     MetricUnit: "%",
					//     MetricImage: "",
					//     KPIDesp: "Below the high risk cancellation range",
					//     MetricDataID: 2941,
					//     MetricDataDesp: this.state.iiData.kpiData.MetricDataDesp,
					//     MetricValue: this.state.iiData.kpiData.MetricValue,
					//     RAGStatus: this.state.iiData.kpiData.RAGStatus,
					//     UpperThreshold: this.state.iiData.kpiData.UpperThreshold,
					//     LowerThreshold: this.state.iiData.kpiData.LowerThreshold,
					//     Month: "2018-12-01T00:00:00",
					//     metDataAttr: null
					// }
				],
			};
			iiData.kpiData.SOCategoryID = iiData.SOCategoryID;
			axiosInstance
				.post(
					`${LocalApiBaseUrl}Master/PostKpiMetricMasterTemplate`,
					iiData.kpiData,
					{
						params: {
							OfferingID: filterProcess,
							LanguageID: store.getState().CurrentFilter.languageData.id,
						},
					}
				)
				.then((response) => {
					store.dispatch({
						type: "ADD_EDIT_KPI1",
						payload: response.data.kPIdata,
					});
					window.alert(response.data.StatusMessage);
				})
				.catch((error) => {
					trycatchAlertPopup(error);
					// if (error.response) {

					// 	// window.alert(error.response.data.Message)


					if (error.response) {
				
						// window.alert(error.response.data.Message)

						DisplayMessage();
					}
				});
			// iiData.kpiData.KPIMappingID = this.state.message;

			// this.setState({
			//     iiData: iiData
			// })

			if (
				this.state.type == "Analytics Apps & AI Advisors" &&
				this.state.isKpi != "add kpi"
			) {
				store.dispatch({
					type: "ADD_APPADVISORS_MASTER",
					payload: iiData,
				});
			} else if (
				this.state.type == "Other Process Insights" &&
				this.state.isKpi != "add kpi"
			) {
				store.dispatch({
					type: "ADD_PROCESSINSIGHTS_MASTER",
					payload: iiData,
				});
			} else if (this.state.isKpi == "add kpi") {
				let addKpiData = this.state.addKpiData;
				this.setState({
					addKpiData: iiData.kpiData,
				});
				// addKpiData.SOCategoryName = this.props.Details.SOCategoryName;
				// store.dispatch({

				//     type: 'ADD_EDIT_KPI1',
				//     payload: addKpiData

				// })
			}

			this.handleHide();
		}
	}

	handleSaveInsight(e) {
		const { metricDefinitions } = this.state;

		if (this.validateFormInsightDetails()) {
			let iiData = this.state.iiData;
			//iiData.kpiData.KPIMappingID = iiData.kpiData.KPIMappingID == 0 ? Math.random() : iiData.kpiData.KPIMappingID;;

			this.setState({
				iiData: iiData,
			});
			let filterProcess = this.props.filterDetails.process;
			iiData.kpiData.SOCategoryID = this.state.SOCategoryID;

			// const SUCC_MSG = "Metric Template added successfully";
			// const ERR_MSG = "There was some error while adding Metric Template";

			let SUCC_MSG = i18n.t("SUCC_MSG1");
			let ERR_MSG = i18n.t("ERR_MSG1");



			// ADD MetricDefinitions to the request parameters
			const metricDefinitionsForRequest = _.map(
				metricDefinitions,
				(eachItem) => {
					const metricDef = {
						NumeratorDescription: eachItem.numeratorDefinition,
						DenominatorDescription:
							eachItem.selectedDefinition === "numeratorDefinition"
								? ""
								: eachItem.denominatorDefinition,
						IsEnabled: eachItem.isEnabled === "yes" ? "true" : "false",
					};
					if (
						!_.isEmpty(String(_.get(eachItem, "KPIMetricDefinatoinID", "")))
					) {
						// IF THE METRIC DEFINITION IS BEING EDITED
						metricDef.KPIMetricDefinatoinID = eachItem.KPIMetricDefinatoinID;
					}
					return metricDef;
				}
			);

			iiData.kpiData.MetricDefination = metricDefinitionsForRequest;

			this.setState({ isAPILoading: true });
			axiosInstance
				.post(
					`${LocalApiBaseUrl}Master/PostKpiMetricMasterTemplate`,
					iiData.kpiData,
					{
						params: {
							OfferingID: filterProcess,
							LanguageID: store.getState().CurrentFilter.languageData.id,
						},
					}
				)
				.then((response) => {
					store.dispatch({
						type: "ADD_EDIT_KPI_MASTER",
						payload: response.data.kPIdata,
					});
					this.setState({ isAPILoading: false }, () => {
						alert(SUCC_MSG);
						this.handleHide();
					});
				})
				.catch((error) => {
					this.setState({ isAPILoading: false }, () => {
					trycatchAlertPopup(error);
						//alert(ERR_MSG + error);
					});
				});
		}
	}

	handleHide = (e) => {
		this.setState({ show: false });
		if (e != undefined) {
			if (e.target.textContent != "×") {
				this.props.onClose(e);
			} else {
				this.props.onClose(e);
				// this.props.onClose("from x");
			}
		} else {
			this.props.onClose(e);
		}
	};

	onRadioCheck() {
		if (document.getElementById("InsightDetailCheck").checked) {
			this.state.isCheck = false;
			document.getElementById("MetricGroupCheck").checked = false;
		} else {
			this.state.isCheck = true;
		}
		let iiData = this.state.iiData;
		let kpiData = this.state.iiData.kpiData;
		this.setState({
			iiData: iiData,
			kpiData: kpiData,
		});
	}

	validateFormMetricGroup() {
		let iiData = this.state.iiData;
		let errors = {};
		let formIsValidMetric = true;
		if (iiData["SOCategoryName"])
			iiData["SOCategoryName"] = iiData["SOCategoryName"].trim();
		if (!iiData["SOCategoryName"]) {
			formIsValidMetric = false;
			errors["SOCategoryName"] = "Name is required";
		}

		var data = store.getState();
		let iiDetails =
			data.intelligentInsightMasterReducers["insightandintelligence"];

		if (iiDetails.length > 0) {
			let oldData = iiDetails.map(function(el) {
				return el.SOCategoryName;
			});
			if (
				iiData.SOCategoryName &&
				oldData.includes(iiData.SOCategoryName.trim()) &&
				!this.props.Details
			) {
				formIsValidMetric = false;
				errors["MetricGroupExists"] = "Name already exists";
			}
		}

		if (formIsValidMetric) {
			this.setState({
				formIsValidMetric: true,
			});
		} else {
			this.setState({
				formIsValidMetric: false,
			});
		}
		if (!this.state.formHasValidName) {
			errors["MetricGroupExists"] = "Name already exists";
			formIsValidMetric = false;
			this.setState({
				formIsValidMetric: false,
			});
		}
		this.setState({
			errors: errors,
		});

		return formIsValidMetric;
	}

	validateFormInsightDetails() {
		const { metricDefinitions } = this.state;
		const { defaultIsRequiredLeadingPractice } = this.props;

		let kpiData = this.state.iiData.kpiData;
		let iiData = this.state.iiData;
		let errors = {};
		let formIsValidInsight = true;

		var data = store.getState();
		let iiDetails =
			data.intelligentInsightMasterReducers["insightandintelligence"];
		let kpiDetails = this.state.kpiDetails;
		if (kpiData["MetricName"]){
			kpiData["MetricName"] = kpiData["MetricName"].trim();
			if(!isValidText(kpiData["MetricName"])){
				errors["MetricName"] =  InvalidDescription();
				formIsValid = false;
			  }
		}
		if (!kpiData["MetricName"]) {
			formIsValidInsight = false;
			// errors["MetricName"] = "Name is required";
			errors["MetricName"] = i18n.t("addWoErrorM1");

		}
		if (kpiData["KPIDesp"]){
			kpiData["KPIDesp"] = kpiData["KPIDesp"].trim();
			if(!isValidText(kpiData["KPIDesp"])){
				errors["KPIDesp"] =  InvalidDescription();
				formIsValidInsight = false;
			  }
		}
		if (kpiData["SOCategoryName"])
			kpiData["SOCategoryName"] = kpiData["SOCategoryName"].trim();
		if (!iiData["SOCategoryName"] || iiData.SOCategoryName === 0) {
			formIsValidInsight = false;
			errors["select"] = "Select Metric Category";
		}
		if (kpiData["MetricUnit"])
			kpiData["MetricUnit"] = kpiData["MetricUnit"];
		if (!kpiData["MetricUnit"]) {
			formIsValidInsight = false;
			// errors["MetricUnit"] = "Unit is required";
			errors["MetricUnit"] = i18n.t("Unit_is_required");


		}

		// if(this.props.isRelatorBlockForOffering){

		if (!kpiData["Attribute1Value"] && !kpiData["Attribute2Value"]) {
			// attribute values can never be left empty - fill NA
			formIsValidInsight = false;
			// errors["Attribute1Value"] =	"Benchmark values are mandatory. If you don't intend to add values, please enter NA";
			// errors["Attribute2Value"] =	"Benchmark values are mandatory. If you don't intend to add values, please enter NA";
			errors["Attribute1Value"] =	i18n.t("AttributeValue_ErrMsg1");
			errors["Attribute2Value"] =	i18n.t("AttributeValue_ErrMsg1");

		}

		if (kpiData["Attribute1Value"] && kpiData["Attribute2Value"]) {
			if (
				!kpiData["Attribute1Value"].trim() &&
				!kpiData["Attribute2Value"].trim()
			) {
				// attribute values can never be left empty - fill NA
				formIsValidInsight = false;
				// errors["Attribute1Value"] ="Benchmark values are mandatory. If you don't intend to add values, please enter NA";
				// errors["Attribute2Value"] ="Benchmark values are mandatory. If you don't intend to add values, please enter NA";
				errors["Attribute1Value"] =	i18n.t("AttributeValue_ErrMsg1");
				errors["Attribute2Value"] =	i18n.t("AttributeValue_ErrMsg1");
			}
		}

		if (kpiData["Attribute1Value"]) {
			if (kpiData["Attribute1Value"].trim()) {
				if (Number.isNaN(parseFloat(kpiData["Attribute1Value"].trim()))) {
					// it is a string

					if (kpiData["Attribute1Value"].toLowerCase().trim() !== "na") {
						formIsValidInsight = false;
						// errors["Attribute1Value"] = "NA is the only permitted string value";
						errors["Attribute1Value"] = i18n.t("AttributeValue_ErrMsg2");

					}
				} else {
					//attribute 1 is a whole number or decimal OR has alphabets and numbers ex. 12efgh3456
					//if the string contains anything except nos,-- alphabets or spl chars, not allowed
					if (!regexForNumbersOnlyMTC.test(kpiData["Attribute1Value"].trim())) {
						formIsValidInsight = false;
						// errors["Attribute1Value"] = "NA is the only permitted string value";
						errors["Attribute1Value"] = i18n.t("AttributeValue_ErrMsg2");

					}

					if (
						!(
							parseFloat(kpiData["Attribute1Value"].trim()) >= 0 &&
							parseFloat(kpiData["Attribute1Value"].trim()) <= 999999
						)
					) {
						formIsValidInsight = false;

						// errors["Attribute1Value"] =	`${this.props.attributeLabels.AttributeName1}` +" value must be in between 0 to 999999";
						errors["Attribute1Value"] =`${this.props.attributeLabels.AttributeName1}`+ " " + i18n.t("addBoErrorM2");
					}

					//0 to 999999 validation for attribute1value
				}
				if (!kpiData["Attribute2Value"] || !kpiData["Attribute2Value"].trim()) {
					// errors["Attribute2Value"] ="Please enter a value for " + `${this.props.attributeLabels.AttributeName2}`;
					errors["Attribute2Value"] =i18n.t("AttributeValue_ErrMsg3")+ " " + `${this.props.attributeLabels.AttributeName2}`;

					formIsValidInsight = false;
				}
				// TODO: needs to be chnaged later when attribute labels are dynamic
			}
		}
		if (kpiData["Attribute2Value"]) {
			if (kpiData["Attribute2Value"].trim()) {
				if (Number.isNaN(parseFloat(kpiData["Attribute2Value"]))) {
					// it is a string

					if (kpiData["Attribute2Value"].toLowerCase().trim() !== "na") {
						formIsValidInsight = false;
						// errors["Attribute2Value"] = "NA is the only permitted string value";
						errors["Attribute2Value"] = i18n.t("AttributeValue_ErrMsg2");

					}
				} else {
					//attribute 2 is a whole number or decimal OR has alphabets and numbers ex. 12efgh3456
					if (!regexForNumbersOnlyMTC.test(kpiData["Attribute2Value"].trim())) {
						formIsValidInsight = false;
						// errors["Attribute2Value"] = "NA is the only permitted string value";
						errors["Attribute2Value"] = i18n.t("AttributeValue_ErrMsg2");

					}

					//0 to 999999 validation for attribute2value
					if (
						!(
							parseFloat(kpiData["Attribute2Value"].trim()) >= 0 &&
							parseFloat(kpiData["Attribute2Value"].trim()) <= 999999
						)
					) {
						formIsValidInsight = false;
						// errors["Attribute2Value"] =
						// 	`${this.props.attributeLabels.AttributeName2}` +
						// 	" value must be in between 0 to 999999";
						errors["Attribute2Value"] =`${this.props.attributeLabels.AttributeName2}`+ " " + i18n.t("addBoErrorM2");
					}
				}
				if (!kpiData["Attribute1Value"].trim()) {
					// errors["Attribute1Value"] =
					// 	"Please enter a value for " +
					// 	`${this.props.attributeLabels.AttributeName1}`;
						errors["Attribute1Value"] =
						i18n.t("AttributeValue_ErrMsg3") +" "+	`${this.props.attributeLabels.AttributeName1}`;
	

					formIsValidInsight = false;
				}
				// TODO: needs to be chnaged later when attribute labels are dynamic
			}
		}
		if (kpiData["MetricSeq"]) {
			kpiData["MetricSeq"] = parseInt(kpiData["MetricSeq"], 10);
		}

		if (!kpiData["MetricSeq"]) {
			formIsValidInsight = false;
			// errors["MetricSeq"] = "Sequence is required";
			errors["MetricSeq"] = i18n.t("addWoErrorM5");

		} else if (!(kpiData["MetricSeq"] >= 0 && kpiData["MetricSeq"] <= 999999)) {
			formIsValidInsight = false;
			// errors["MetricSeqRange"] ="Sequence Value must be in between 0 to 999999";
			errors["MetricSeqRange"] =	i18n.t("addWoErrorM6");

		}

		// VALIDATE "LEADING PRACTICE" FIELD
		const LeadingPractice = kpiData.LeadingPractice;
		if (defaultIsRequiredLeadingPractice && _.isEmpty(LeadingPractice)) {
			formIsValidInsight = false;
			// errors["LeadingPractice"] = "Leading Practice is required";
			errors["LeadingPractice"] = i18n.t("Leading_Practice_ErrMsg");

		}

		// VALIDATE "METRIC DEFINITIONS"
		const modifiedMetricDefinitions = _.map(metricDefinitions, (eachItem) => {
			const selectedDefinition = eachItem.selectedDefinition;
			let errMsgNumeratorDefinitionField = "";
			let errMsgDenominatorDefinitionField = "";

			const numeratorDefinition = String(eachItem.numeratorDefinition).trim();
			const denominatorDefinition = String(
				eachItem.denominatorDefinition
			).trim();

			// FOR `numeratorDefinitionField` CHECK IF THE FIELD IS REQUIRED AND ITS VALUE IS NOT EMPTY
			if (
				eachItem.isRequiredNumeratorDefinitionField &&
				_.isEmpty(numeratorDefinition)
			) {
				// errMsgNumeratorDefinitionField = "Numerator Definition is required";
				errMsgNumeratorDefinitionField = i18n.t("NumeratorDefReqd");

			} else if (
				!_.isEmpty(numeratorDefinition) &&
				!validateMetricDefinitionFieldValue(numeratorDefinition)
			) {
				errMsgNumeratorDefinitionField = this.ERR_MSG_METRIC_DEF_INVALID_VALUE;
			}

			if (selectedDefinition === "numeratorAndDenominatorDefinition") {
				// IF (FIELD IS REQUIRED AND VALUE IS EMPTY) OR (`numeratorDefinition` IS NOT EMPTY BUT `denominatorDefinition` IS EMPTY)
				if (
					(eachItem.isRequiredDenominatorDefinitionField &&
						_.isEmpty(denominatorDefinition)) ||
					(!_.isEmpty(numeratorDefinition) && _.isEmpty(denominatorDefinition))
				) {
					// errMsgDenominatorDefinitionField =	"Denominator Definition is required";
					errMsgDenominatorDefinitionField = i18n.t("DenominatorDefReqd");

				}
				// `denominatorDefinition` IS NOT EMPTY BUT `numeratorDefinition` IS EMPTY
				if (
					!_.isEmpty(denominatorDefinition) &&
					_.isEmpty(numeratorDefinition)
				) {
					// errMsgNumeratorDefinitionField = "Numerator Definition is required";
					errMsgNumeratorDefinitionField = i18n.t("NumeratorDefReqd");

				}
			}

			if (
				!_.isEmpty(denominatorDefinition) &&
				!validateMetricDefinitionFieldValue(denominatorDefinition)
			) {
				errMsgDenominatorDefinitionField = this.ERR_MSG_METRIC_DEF_INVALID_VALUE;
			}
			eachItem.errMsgNumeratorDefinitionField = errMsgNumeratorDefinitionField;
			eachItem.errMsgDenominatorDefinitionField = errMsgDenominatorDefinitionField;

			// IF THERE WAS SOME ERROR IN DYNAMICALLY ADDED METRIC DEFINITIONS THEN SET THE FORM TO INVALID
			if (
				!_.isEmpty(errMsgNumeratorDefinitionField) ||
				!_.isEmpty(errMsgDenominatorDefinitionField)
			) {
				formIsValidInsight = false;
			}

			eachItem.numeratorDefinition = numeratorDefinition;
			eachItem.denominatorDefinition = denominatorDefinition;
			return eachItem;
		});

		// CHECK FOR DUPLICATE METRIC DEFINITIONS
		let areAnyDuplicateMetricDefinitions = false;
		_.forEach(modifiedMetricDefinitions, function(eachMetDef, index) {
			const modifiedNumeratorDefinition = String(
				eachMetDef.numeratorDefinition
			).trim();
			const modifiedDenominatorDefinition = String(
				eachMetDef.denominatorDefinition
			).trim();

			// FOR ALL THE SELECTED RADIO OPTIONS "Numerator Definition", CHECK FOR DUPLICATE VALUES
			const eqNumField = _.find(modifiedMetricDefinitions, function(
				eachItem,
				indx
			) {
				const foundNumeratorDefinition = String(
					eachItem.numeratorDefinition
				).trim();

				if (
					indx > index &&
					eachMetDef.selectedDefinition === "numeratorDefinition" &&
					eachItem.selectedDefinition === "numeratorDefinition" &&
					!_.isEmpty(modifiedNumeratorDefinition) &&
					!_.isEmpty(foundNumeratorDefinition) &&
					modifiedNumeratorDefinition === foundNumeratorDefinition
				) {
					return true;
				}
			});
			if (eqNumField) {
				areAnyDuplicateMetricDefinitions = true;
			}

			// FOR ALL THE SELECTED RADIO OPTIONS "Numerator & Denominator Definition", CHECK FOR DUPLICATE VALUES
			const eqNumDenoField = _.find(modifiedMetricDefinitions, function(
				eachItem,
				indx
			) {
				const foundNumeratorDefinition = String(
					eachItem.numeratorDefinition
				).trim();
				const foundDenominatorDefinition = String(
					eachItem.denominatorDefinition
				).trim();

				if (
					indx > index &&
					eachMetDef.selectedDefinition ===
						"numeratorAndDenominatorDefinition" &&
					eachItem.selectedDefinition === "numeratorAndDenominatorDefinition" &&
					!_.isEmpty(modifiedNumeratorDefinition) &&
					!_.isEmpty(foundNumeratorDefinition) &&
					modifiedNumeratorDefinition === foundNumeratorDefinition &&
					!_.isEmpty(modifiedDenominatorDefinition) &&
					!_.isEmpty(foundDenominatorDefinition) &&
					modifiedDenominatorDefinition === foundDenominatorDefinition
				) {
					return true;
				}
			});
			if (eqNumDenoField) {
				areAnyDuplicateMetricDefinitions = true;
			}
		});

		// IF THERE WERE ANY DUPLICATE METRIC DEFINITIONS THEN SET THE FORM VALID STATUS TO FALSE
		if (areAnyDuplicateMetricDefinitions) {
			formIsValidInsight = false;
		}

		this.setState({
			errors: errors,
			metricDefinitions: modifiedMetricDefinitions,
			areAnyDuplicateMetricDefinitions,
			formIsValidInsight,
		});

		return formIsValidInsight;
	}

	// EXECUTED WHEN THE "LeadingPractice" FIELD VALUE WOULD BE CHANGED
	handleChangeLeadingPractice = (e) => {
		const LeadingPractice = e.target.value;
		this.setState((prevState) => ({
			iiData: {
				...prevState.iiData,
				kpiData: {
					...prevState.iiData.kpiData,
					LeadingPractice,
				},
			},
		}));
	};

	/**
	 * When the unit of metric is "%" definition of both numerator & denominator should be mandatory.
	 * Numerator and Denominator fields are mandatory fields for BO, II & DD page. For  H&M & WO page keep them optional.
	 * So Disable "numerator" (radio) selection based on above condition.
	 */
	toggleRequiredMetricDefinitionFields = () => {
		const { defaultIsRequiredMetricDefinitionFields } = this.props;
		const {
			iiData: { kpiData },
			metricDefinitions,
		} = this.state;
		const MetricUnit = kpiData.MetricUnit;

		let areRequiredMetricDefinitionFields = defaultIsRequiredMetricDefinitionFields;

		let isDisabledNumeratorDefinitionField = false;
		if (MetricUnit.trim() === _.trim("%")) {
			areRequiredMetricDefinitionFields = true;
			isDisabledNumeratorDefinitionField = true;
		}

		// FOR ALL THE DYNAMICALLY ADDED METRIC DEFINITIONS, TOGGLE DISABLE OF THE "NUMERATOR RADIO" FIELD
		const modifiedMetricDefinitions = _.map(
			metricDefinitions,
			(eachItem, index) => {
				eachItem.isDisabledNumeratorRadioField = isDisabledNumeratorDefinitionField;
				// IF THE "NUMERATOR RADIO" FIELD IS TO BE DISABLED, THEN SET SELECTED "NUMERATOR AND DENOMINATOR RADIO" FIELD
				if (isDisabledNumeratorDefinitionField) {
					eachItem.selectedDefinition = "numeratorAndDenominatorDefinition";
				}
				eachItem.isRequiredNumeratorDefinitionField = areRequiredMetricDefinitionFields;
				eachItem.isRequiredDenominatorDefinitionField = areRequiredMetricDefinitionFields;
				return {
					...eachItem,
				};
			}
		);


		this.setState({
			metricDefinitions: modifiedMetricDefinitions,
		});
	};

	// DYNAMICALLY ADDS A NEW ROW FOR ADDING METRIC DEFINITION
	addMetricDefinitionRow = () => {
		const { defaultIsRequiredMetricDefinitionFields } = this.props;
		const { metricDefinitions } = this.state;
		const clonedSingleMetricDefinition = _.cloneDeep(singleMetricDefinition);

		clonedSingleMetricDefinition.isRequiredNumeratorDefinitionField = defaultIsRequiredMetricDefinitionFields;
		clonedSingleMetricDefinition.isRequiredDenominatorDefinitionField = defaultIsRequiredMetricDefinitionFields;

		const currentMetricDefinitions = _.cloneDeep(metricDefinitions);
		currentMetricDefinitions.push(clonedSingleMetricDefinition);
		this.setState(
			{
				metricDefinitions: currentMetricDefinitions,
			},
			() => {
				this.toggleRequiredMetricDefinitionFields();
			}
		);
	};

	// REMOVES THE SELECTED DYNAMICALLY ADDED METRIC DEFINITION SECTION
	removeMetricDefinitionRow = (rowIndex) => {

		const { metricDefinitions } = this.state;
		const remainingMetricDefinitions = _.filter(
			metricDefinitions,
			(eachItem, index) => {
				return index !== rowIndex;
			}
		);

		this.setState({
			metricDefinitions: remainingMetricDefinitions,
		});
	};

	// EXECUTES WHEN THE ANY OF THE FIELD'S VALUE OF DYNAMICALLY ADDED METRIC DEFINITION WOULD BE CHANGED
	handleChangeMetricDefinitionField = (e, rowIndex, fieldName) => {
		const { metricDefinitions } = this.state;
		let fieldValue = e.target.value;
		if (fieldName === "isEnabled") {
			fieldValue = e.target.checked ? "yes" : "";
		}
		const modifiedMetricDefinitions = _.map(
			metricDefinitions,
			(eachItem, index) => {
				if (index === rowIndex) {
					eachItem[fieldName] = fieldValue;
				}
				return {
					...eachItem,
				};
			}
		);

		this.setState({
			metricDefinitions: modifiedMetricDefinitions,
		});
	};

	// REMOVES THE ERRORS OF DYNAMICALLY ADDED METRIC DEFINITIONS
	clearMetricDefinitionFieldsErrors = () => {
		const { metricDefinitions } = this.state;
		const modifiedMetricDefinitions = _.map(metricDefinitions, (eachItem) => {
			return {
				...eachItem,
				errMsgNumeratorDefinitionField: "",
				errMsgDenominatorDefinitionField: "",
			};
		});

		this.setState({
			metricDefinitions: modifiedMetricDefinitions,
		});
	};

	render() {
		const { defaultIsRequiredLeadingPractice } = this.props;
		const {
			metricDefinitions,
			areAnyDuplicateMetricDefinitions,
			iiData: { kpiData },
			isAPILoading,
		} = this.state;

		let isScreenInEditMode = false;
		if (!_.isEmpty(this.props.kpiData)) {
			isScreenInEditMode = true;
		}

		return (
			<>
				{/* <Header /> */}
				{/* IF API IS LOADING THEN SHOW THE LOADER */}
				{isAPILoading ? (
					<LoadingOverlay
						fadeSpeed={0}
						spinner={<SynopsLoader />}
						active
						// text={i18n.t("Please_wait") + "..."}
					/>
				) : <></>}
				<Modal
					show={this.state.show}
					onHide={this.handleHide}
					className="modal fade master-template-configurator"
					size="medium"
					id="dealpopup"
					backdrop="static"
				>
					<Modal.Header closeButton>
						<h4 className="modal-title" id="dealprofile">
							{i18n.t("Metric_Template")} - {this.state.title}{" "}
							{this.state.iiData.kpiData.MetricName_En
								? this.state.iiData.kpiData.MetricName_En
								: ""}
						</h4>
					</Modal.Header>
					<Modal.Body>
						<div className="form-section">
							{/* <h3>Insight Data</h3> */}

							<div
								className="row"
								style={{
									display: this.state.categoryVisible ? "block" : "none",
								}}
							>
								<div className="form-group col-lg-12">
									<label>
										{i18n.t("Metric_Category")}{" "}
										<span className="required">*</span>
									</label>
									<select
										className="form-control"
										onChange={this.onMetricGroupChange}
									>
										<option value="0">
											{i18n.t("Select_Metric_Category")}
										</option>
										{this.state.SOCat}
									</select>
									<div className="errorMsg">{this.state.errors.select}</div>
								</div>
							</div>

							<div className="row">
								<div className="form-group col-lg-6">
									<label>
										{i18n.t("Name")} <span className="required">*</span>
									</label>
									{!this.props.kpiData ? (
										<input
											type="text"
											className="form-control"
											id=""
											maxLength="100"
											onChange={this.onMetricNameChange}
											value={this.state.iiData.kpiData.MetricName}
										/>
									) : (
										<input
											type="text"
											className="form-control"
											id=""
											maxLength="50"
											disabled
											onChange={this.onMetricNameChange}
											value={this.state.iiData.kpiData.MetricName}
										/>
									)}

									<div className="errorMsg">{this.state.errors.MetricName}</div>
								</div>

								<div className="form-group col-lg-6">
									<label>
										{i18n.t("Unit_of_Measure")} <span className="required">*</span>
									</label>

									{!this.props.kpiData ? (
										
										<select className="form-control" value={this.state.iiData.kpiData.MetricUnit} onChange={this.onUnitChange} >
										<option value="">{i18n.t("Please_Select_Unit")}</option>
										{this.state.unitsData.map((each)=>{
											return <option value={each.MetricUnit_En}>{each.MetricUnit}</option>
										})}
									</select>
									) : (
										<select className="form-control" value={this.state.iiData.kpiData.MetricUnit}  disabled>
										<option value="">{i18n.t("Please_Select_Unit")}</option>
										{this.state.unitsData.map((each)=>{
											return <option value={each.MetricUnit_En}>{each.MetricUnit}</option>
										})}
									</select>
										
									)}
									<div className="errorMsg">{this.state.errors.MetricUnit}</div>
								</div>
							</div>

							<div className="row">
								<div className="form-group col-lg-6">
									<label>
										{i18n.t("Sequence")} <span className="required">*</span>
									</label>
									<input
										type="number"
										className="form-control"
										id=""
										onChange={this.onMetricSequenceChange}
										value={this.state.iiData.kpiData.MetricSeq}
									/>
									<div className="errorMsg">{this.state.errors.MetricSeq}</div>
									<div className="errorMsg">
										{this.state.errors.MetricSeqRange}
									</div>
								</div>

								<div className="form-group col-lg-6">
									<label>
										{i18n.t("Leading_Practice")}
										{defaultIsRequiredLeadingPractice && (
											<span className="required"> *</span>
										)}
										&nbsp;
										<OverlayTrigger
											key="top"
											placement="top"
											overlay={
												<Tooltip id="tooltip-top">
													{i18n.t("addBOMess2")}
												</Tooltip>
											}
										>
											<FontAwesomeIcon icon={faInfoCircle} />
										</OverlayTrigger>
									</label>
									<select
										value={kpiData.LeadingPractice}
										disabled={store.getState().CurrentFilter.languageData.id !== 1}
										onChange={(e) => this.handleChangeLeadingPractice(e)}
										className="form-control"
									>
										<option value="">
											{i18n.t("Select_Leading_Practice")}
										</option>
										{/* <option value="maximum">{i18n.t("Maximum")}</option>
                      <option value="minimum">{i18n.t("Minimum")}</option> */}
										<option value={i18n.t("Maximum")}>
											{i18n.t("Maximum")}
										</option>
										<option value={i18n.t("Minimum")}>
											{i18n.t("Minimum")}
										</option>
									</select>
									<div className="errorMsg">
										{this.state.errors.LeadingPractice}
									</div>
								</div>
							</div>

							<div className="row">
								<div className="form-group col-lg-12">
									<label>{i18n.t("Description")} </label>
									<input
										type="text"
										className="form-control"
										id=""
										maxLength="255"
										onChange={this.onMetricDescChange}
										value={this.state.iiData.kpiData.KPIDesp}
									/>
								</div>
							</div>

							<div className="row">
								<div className="form-group col-lg-6">
									<label>
										{this.props.attributeLabels.AttributeName1}{" "}
										<span className="required">*</span>
									</label>
									{this.state.iiData.kpiData.Attribute1Value ? (
										<input
											type="text"
											className="form-control"
											id=""
											disabled={store.getState().CurrentFilter.languageData.id !== 1}
											onChange={this.onAttribute1ValueChange}
											value={this.state.iiData.kpiData.Attribute1Value.trim()}
										/>
									) : (
										<input
											type="text"
											className="form-control"
											id=""
											disabled={store.getState().CurrentFilter.languageData.id !== 1}
											onChange={this.onAttribute1ValueChange}
											value={""}
										/>
									)}

									<div className="errorMsg">
										{this.state.errors.Attribute1Value}
									</div>
								</div>

								<div className="form-group col-lg-6">
									<label>
										{this.props.attributeLabels.AttributeName2}{" "}
										<span className="required">*</span>
									</label>

									{this.state.iiData.kpiData.Attribute2Value ? (
										<input
											type="text"
											className="form-control"
											id=""
											disabled={store.getState().CurrentFilter.languageData.id !== 1}
											onChange={this.onAttribute2ValueChange}
											value={this.state.iiData.kpiData.Attribute2Value.trim()}
										/>
									) : (
										<input
											type="text"
											className="form-control"
											id=""
											disabled={store.getState().CurrentFilter.languageData.id !== 1}
											onChange={this.onAttribute2ValueChange}
											value={""}
										/>
									)}

									<div className="errorMsg">
										{this.state.errors.Attribute2Value}
									</div>
								</div>
							</div>

							{/* START - METRIC DEFINITION */}
							{areAnyDuplicateMetricDefinitions && (
								<div className="row">
									<div className="col">
										<Alert variant="danger">
											{i18n.t("DuplicateMetricDefinitionAlert")}
										</Alert>
									</div>
								</div>
							)}

							<div className="title-with-icon mt-3 mb-1">
								<h3 className="border-0">{i18n.t("Metric_Definition")}
								{" "}
                                            <OverlayTrigger
                                                key="top"
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip-top">
                                                        {i18n.t("addBOMess3")}
                                                    </Tooltip>
                                                }
                                            >
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                            </OverlayTrigger>
								</h3>
								<div className="list-icon">
									<span id="rock" onClick={() => this.addMetricDefinitionRow()}>
										<FontAwesomeIcon icon={faPlus} />
									</span>
								</div>
							</div>

							<div>
								{_.map(metricDefinitions, (eachMetricDefinition, index) => {
									const selectedDefinition =
										eachMetricDefinition.selectedDefinition;
									const isVisibleDenominatorDefinition =
										selectedDefinition === "numeratorAndDenominatorDefinition";
									return (
										<div className="mb-3" key={`definition_${index}`}>
											<div className="row">
												<div className="form-group col-lg-12 mb-1">
													<label className="form-check mr-5">
														<input
															type="radio"
															name={`definition_${index}`}
															value="numeratorDefinition"
															onChange={(e) =>
																this.handleChangeMetricDefinitionField(
																	e,
																	index,
																	"selectedDefinition"
																)
															}
															checked={
																selectedDefinition === "numeratorDefinition"
															}
															disabled={	eachMetricDefinition.isDisabledNumeratorRadioField || store.getState().CurrentFilter.languageData.id !== 1}
															className="form-check-input"
														/>
														<span className="checkmark" />
														<span className="form-check-label">
															{i18n.t("Numerator_Definition")}
														</span>
													</label>

													<label className="form-check">
														<input
															type="radio"
															name={`definition_${index}`}
															value="numeratorAndDenominatorDefinition"
															onChange={(e) =>
																this.handleChangeMetricDefinitionField(
																	e,
																	index,
																	"selectedDefinition"
																)
															}
															checked={
																selectedDefinition ===
																"numeratorAndDenominatorDefinition"
															}
															disabled={store.getState().CurrentFilter.languageData.id !== 1	}

															className="form-check-input"
														/>
														<span className="checkmark" />
														<span className="form-check-label">
															{i18n.t("Numerator&Denominator_Definition")}
														</span>
													</label>

													{index !== 0 && (
														<>
															{_.isNil(
																_.get(
																	eachMetricDefinition,
																	"KPIMetricDefinatoinID",
																	null
																)
															) && (
																<span
																	className="del-row-icon list-icon"
																	onClick={() =>
																		this.removeMetricDefinitionRow(index)
																	}
																>
																	<FontAwesomeIcon icon={faTrashAlt} />
																</span>
															)}
														{ store.getState().CurrentFilter.languageData.id === 1 && (

															<div className="toggle-group">
																<input
																	type="checkbox"
																	value="yes"
																	checked={
																		String(eachMetricDefinition.isEnabled) ===
																		"yes"
																	}
																	onChange={(e) =>
																		this.handleChangeMetricDefinitionField(
																			e,
																			index,
																			"isEnabled"
																		)
																	}
																	id={`isEnabled_${index}`}
																/>
																<label htmlFor={`isEnabled_${index}`} />
																<div className="onoffswitch" aria-hidden="true">
																	<div className="onoffswitch-label">
																		<div className="onoffswitch-inner" />
																		<div className="onoffswitch-switch" />
																	</div>
																</div>
															</div>
														)}

														</>
													)}
												</div>
											</div>

											<div className="row">
												<div
													className={classNames(
														"form-group",
														{ "col-lg-6": isVisibleDenominatorDefinition },
														{ col: !isVisibleDenominatorDefinition }
													)}
												>
													<label>
														{i18n.t("Numerator_Definition")}
														{eachMetricDefinition.isRequiredNumeratorDefinitionField && (
															<span className="required"> *</span>
														)}
													</label>
													<input
														type="text"
														value={eachMetricDefinition.numeratorDefinition}
														onChange={(e) =>
															this.handleChangeMetricDefinitionField(
																e,
																index,
																"numeratorDefinition"
															)
														}
														className="form-control"
														maxLength={maxLengthMetricDefinitionFields}
													/>
													<div className="errorMsg">
														{
															eachMetricDefinition.errMsgNumeratorDefinitionField
														}
													</div>
												</div>

												{isVisibleDenominatorDefinition && (
													<div className="form-group col-lg-6">
														<label>
															{i18n.t("Denominator_Definition")}
															{eachMetricDefinition.isRequiredDenominatorDefinitionField && (
																<span className="required"> *</span>
															)}
														</label>
														<input
															type="text"
															value={eachMetricDefinition.denominatorDefinition}
															onChange={(e) =>
																this.handleChangeMetricDefinitionField(
																	e,
																	index,
																	"denominatorDefinition"
																)
															}
															className="form-control"
															maxLength={maxLengthMetricDefinitionFields}
														/>
														<div className="errorMsg">
															{
																eachMetricDefinition.errMsgDenominatorDefinitionField
															}
														</div>
													</div>
												)}
											</div>
										</div>
									);
								})}
							</div>
							{/* END - METRIC DEFINITION */}
						</div>
					</Modal.Body>
					{
						<Modal.Footer>
							<div className="modal-btn">
								<button
									onClick={this.handleHide}
									className="btn btn-default btn-sm mr-2"
								>
									{i18n.t("Cancel")}
								</button>

								{!this.props.Details ? (
									<input
										id="btn2"
										type="Button"
										className="btn btn-primary btn-sm"
										onClick={() => this.handleSaveInsight()}
										value={i18n.t("Create")}
									/>
								) : (
									<input
										id="btn2"
										type="Button"
										className="btn btn-primary btn-sm"
										onClick={() => this.handleSaveInsight()}
										value={i18n.t("Update")}
									/>
								)}
							</div>
						</Modal.Footer>
					}
				</Modal>
				{this.state.viewmore ? (
					<KpiPopUp
						SOCategoryID={this.state.SOCategoryID}
						show={true}
						onClose={this.onClose.bind(this)}
						filterDetails={this.props.filterDetails}
					/>
				) : null}
				{this.state.addNewMetricGroup ? (
					<IIMetricGroupPopUp
						SOCategoryID={this.state.SOCategoryID}
						type={this.state.type}
						showPop={
							this.props.roledata != undefined &&
							this.props.roledata.includes("Add")
						}
						onClose={this.onClose.bind(this)}
						filterDetails={this.props.filterDetails}
					/>
				) : null}
			</>
		);
	}
}

InsightIntelligencePopUp.defaultProps = {
	screenName: "InsightIntelligence",
	// Keep "Leading Practice" as non-mandatory for H&M and WO, mandatory for BO, II & DD
	defaultIsRequiredLeadingPractice: true,
	defaultIsRequiredMetricDefinitionFields: true,
};
const mapStateToProps = (state) => {
	return ({
	languageData: state.CurrentFilter.languageData,
	unitsData: state.BOMasterReducers.unitsData
	})
  }
  export default connect(mapStateToProps)(InsightIntelligencePopUp);

