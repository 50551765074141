import React, { Component } from "react";
import {LocalApiBaseUrl, regexForTextAreasStoryDemo,regexForAccentureDomainEmail} from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import "./story.css";
import $ from "jquery";
import i18n from "i18next";
import { filterSelectedSubOffering,getValidUserDetails,getErrorMessageByData  } from "../Shared/commonFunctions";
import SubOfferingDropdowns from "../Shared/SubOfferingDropdowns";

export default class AddContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsEdit: this.props.editContactStatus,
      data: {
        SubOffering: "",
        OfferingLeadName: "",
        OfferingLeadEmailId: "",
        CTOName: "",
        CTOEmailId: "",
        SMEName: "",
        SMEEmailId: "",
      },
      errors: {
        SubOffering: "",
        OfferingLeadName: "",
        OfferingLeadEmailId: "",
        CTOName: "",
        CTOEmailId: "",
        SMEName: "",
        SMEEmailId: "",
      },
      OfferingID: '',
      OfferingContacts: [],
    };
    this.isContactPresent = false;
    // this.validateEmail = this.validateEmail.bind(this);
    // this.validateName = this.validateName.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.updateContactData = this.updateContactData.bind(this);
    this.addContactData = this.addContactData.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentWillMount() {
    this.props.editContactStatus &&
      this.setState({ data: this.props.editData });
  }

  userValidation=async (user)=>{
    let userData=user;
    // if(!userData.includes("@accenture.com"))
    // userData=`${userData}@accenture.com`
    let userdetails={users:[userData]}
    let userValidData=await getValidUserDetails(userdetails);
    let errorMessageAndFormValidity=getErrorMessageByData(userValidData);
    return errorMessageAndFormValidity;
  }


  handleValidation = async () => {
    let formIsValid = true;
    this.isContactPresent = false;
    let errors = this.state.errors;
    let data = this.state.data;
    const{OfferingLeadEmailId,CTOEmailId,SMEEmailId}=data;
    let userData=[...new Set([OfferingLeadEmailId,CTOEmailId,SMEEmailId])];
    let userValidData=await getValidUserDetails(userData);

    await Promise.all( Object.keys(errors).map(async (key) => {
          let valueToCheck = data[key] ? data[key].trim() : data[key];
          if (valueToCheck === "") {
        switch (key) {
          case "SubOffering":
            errors[key] = "Please Select Sub Offering";
            formIsValid = false;
            break;
          case "OfferingLeadName":
            if (data["OfferingLeadEmailId"] !== "") {
              errors["OfferingLeadName"] = "Please Enter Lead Name";
              formIsValid = false;
            } else {
              errors["OfferingLeadEmailId"] = "";
            }
            break;
          case "CTOName":
            if (data["CTOEmailId"] !== "") {
              errors["CTOName"] = "Please Enter CTO Name";
              formIsValid = false;
            } else {
              errors["CTOEmailId"] = "";
            }
            break;
          case "SMEName":
            if (data["SMEEmailId"] !== "") {
              errors["SMEName"] = "Please Enter SME Name";
              formIsValid = false;
            } else {
              errors["SMEEmailId"] = "";
            }
            break;
          case "OfferingLeadEmailId":
            if (data["OfferingLeadName"] !== "") {
              errors[key] = "Please Enter Lead Email";
              formIsValid = false;
            } else {
              errors[key] = "";
              errors["OfferingLeadName"] = "";
            }
            break;
          case "CTOEmailId":
            if (data["CTOName"] !== "") {
              errors[key] = "Please Enter CTO Email";
              formIsValid = false;
            } else {
              errors[key] = "";
              errors["CTOName"] = "";
            }
            break;
          case "SMEEmailId":
            if (data["SMEName"] !== "") {
              errors[key] = "Please Enter SME Email";
              formIsValid = false;
            } else {
              errors[key] = "";
              errors["SMEName"] = "";
            }
            break;
          default:
            errors[key] = `${key} ` + "Cannot be empty";
        }
      } else {
          let value;
          if (key !== "SubOffering") {
              this.isContactPresent = true;
          }
          // const{OfferingLeadEmailId,CTOEmailId,SMEEmailId}=data;
          // let userData=[...new Set([OfferingLeadEmailId,CTOEmailId,SMEEmailId])];
          // let userValidData=await getValidUserDetails(userData);

                switch (key) {
                    case "SMEName":
                        if (!regexForTextAreasStoryDemo.test(data[key])) {
                            errors[key] = "SME Name contains special characters";
                            formIsValid = false;
                        }
                        else {
                            errors[key] = "";
                        }
                        break;
                    case "OfferingLeadName":
                        if (!regexForTextAreasStoryDemo.test(data[key])) {
                            errors[key] = "Offering Lead Name contains special characters";
                            formIsValid = false;
                        } else {
                            errors[key] = "";
                        }
                        break;
                    case "CTOName":
                        if (!regexForTextAreasStoryDemo.test(data[key])) {
                            errors[key] = "CTO Name contains special characters";
                            formIsValid = false;
                        } else {
                            errors[key] = "";
                        }
                        break;
                    case "OfferingLeadEmailId": 
                    case "CTOEmailId":
                      case "SMEEmailId": 
                        value = data[key] && data[key].toLowerCase();
                        if(errors[key]===""){
                        const {formValid,errorMessage}=getErrorMessageByData(userValidData,[value]);
                        if (!formValid) {
                          errors[key] = errorMessage;
                          formIsValid = false;
                        } else {
                          errors[key] = "";
                        }
                      }
                    break;
                  }
      }
    }));
    this.setState({ errors: errors });
    return formIsValid;
  }

   async addContactData() {
    this.setState({ showLoaderOnPage: true });
    let data = this.state.data;
    this.isContactPresent = false;
    let isSuccess =await this.handleValidation();
    if (!isSuccess) { return }
    if (!this.isContactPresent) {
        alert("Atleast one contact is required");
        this.setState({ showLoaderOnPage: false });
      return;
    }
    Object.keys(this.state.errors).map((key) => {
      if (this.state.errors[key] !== "") {
        isSuccess = false;
        return;
      }
    });
    if (isSuccess) {
      let offeringcontact = this.props.subofferingLeafNodes.filter(
        (off) => off.Process === data.SubOffering
      )[0].OfferingContacts;
      let contacts = offeringcontact.filter(
        (contact) => contact.IsActive == true
      );
      if (contacts.length > 0) {
          alert("You can't add more than one contact for a offering");
          this.setState({ showLoaderOnPage: false });
      } else {
        let offeringId = this.props.subofferingLeafNodes.filter(
          (off) => off.Process === data.SubOffering
        )[0].ProcessID;
        let params = {
          OfferingId: offeringId,
          ...data,
        };

          params.SMEEmailId = params.SMEEmailId && params.SMEEmailId.toLowerCase();
          params.CTOEmailId = params.CTOEmailId && params.CTOEmailId.toLowerCase();
          params.OfferingLeadEmailId = params.OfferingLeadEmailId && params.OfferingLeadEmailId.toLowerCase();

        // post call
      
        axiosInstance
          .post(
            `${LocalApiBaseUrl}SynopsStory/AddEditSynopsOfferingContact`,
            params
          )
          .then((response) => {
            this.setState({ showLoaderOnPage: false });

            this.props.onClose();
          })
          .catch((error) => {
            this.setState({ showLoaderOnPage: false });
            trycatchAlertPopup(error);
            this.props.onClose();
          });
      }
    }
  }

  async updateContactData() {
    let data = this.state.data;
    this.isContactPresent = false;

      let isSuccess =await this.handleValidation();
      if (!isSuccess) {return}
    if (!this.isContactPresent) {
      alert("Atleast one contact is required");
      return;
    }
    Object.keys(this.state.errors).map((key) => {
      if (this.state.errors[key] !== "") {
        isSuccess = false;
        return;
      }
    });
    if (isSuccess) {
      let offeringId = this.props.subofferingLeafNodes.filter(
        (off) => off.Process === data.SubOffering
      )[0].ProcessID;
      let params = {
        OfferingId: offeringId,
        ...data,
        };
        params.SMEEmailId = params.SMEEmailId && params.SMEEmailId.toLowerCase();
        params.CTOEmailId = params.CTOEmailId && params.CTOEmailId.toLowerCase();
        params.OfferingLeadEmailId = params.OfferingLeadEmailId && params.OfferingLeadEmailId.toLowerCase();
      // post call
      this.setState({ showLoaderOnPage: true });
      axiosInstance
        .post(
          `${LocalApiBaseUrl}SynopsStory/AddEditSynopsOfferingContact`,
          params
        )
        .then((response) => {
          this.setState({ showLoaderOnPage: false });
          this.props.onClose();
        })
        .catch((error) => {
          this.setState({ showLoaderOnPage: false });
          trycatchAlertPopup(error);
          this.props.onClose();
        });
    }
  }

  handleFieldChange(e) {
    let { name, value } = e.target;
    this.setState(
      {
        errors:{
          ...this.state.errors,
          [name]:"",
        }
      },
    );
    switch (name) {
      case "SubOffering":
        this.setState({
          data: {
            ...this.state.data,
            [name]: value,
          },
        });
        break;
      case "OfferingLeadName":
          this.setState(
            {
              data: {
                ...this.state.data,
                [name]: value,
              },
            },
          );
        

        break;
      case "CTOName":
       
     
          this.setState(
            {
              data: {
                ...this.state.data,
                [name]: value,
              },
            },
          );
        

        break;
      case "SMEName":
          this.setState(
            {
              data: {
                ...this.state.data,
                [name]: value,
              },
            },
          );
        

        break;
      case "OfferingLeadEmailId":
        this.setState(
          {
            data: {
              ...this.state.data,
              [name]: value,
            },
          },
        );

        break;
      case "CTOEmailId":
        this.setState(
          {
            data: {
              ...this.state.data,
              [name]: value,
            },
          },
        );

        break;
      case "SMEEmailId":
        this.setState(
          {
            data: {
              ...this.state.data,
              [name]: value,
            },
          },
        );

        break;
    }
  }

  handleSelect(e) {

    let { name, value } = e.target;
    let datatemp=[];
    let filtersSubofferingData = [];
    let selectedSuboffering = [];
    let OfferingID = '';
    this.restValidation();
    if(value !== 0){
      filtersSubofferingData = filterSelectedSubOffering([...this.props.subOfferingList],e.target.value);
      selectedSuboffering = filtersSubofferingData.subofferingData[0];
      datatemp =selectedSuboffering.OfferingContacts;   
      OfferingID =selectedSuboffering.ProcessID;
    }
   
    if (datatemp.length > 0) {
      let data = {
        SubOffering: selectedSuboffering.Process,
        OfferingLeadName: datatemp[0].OfferingLeadName,
        OfferingLeadEmailId: datatemp[0].OfferingLeadEmailId,
        CTOName: datatemp[0].CTOName,
        CTOEmailId: datatemp[0].CTOEmailId,
        SMEName: datatemp[0].SMEName,
        SMEEmailId: datatemp[0].SMEEmailId,
      };
      this.setState({ IsEdit: true, data: data, OfferingID ,OfferingContacts: datatemp});
    } else {
      this.setState({
        IsEdit: false,
        data: {
          SubOffering: value !==0 ? selectedSuboffering.Process : '',
          OfferingLeadName: "",
          OfferingLeadEmailId: "",
          CTOName: "",
          CTOEmailId: "",
          SMEName: "",
          SMEEmailId: "",
        },
        OfferingID,
        OfferingContacts: datatemp
      });
    }
  }

  restValidation() {
    let errors = {
      SubOffering: "",
      OfferingLeadName: "",
      OfferingLeadEmailId: "",
      CTOName: "",
      CTOEmailId: "",
      SMEName: "",
      SMEEmailId: "",
    };
    this.setState({ errors: errors });
  }
  render() {
      /* Add Contact */
    return this.props.addContactStatus || this.props.editContactStatus ? (
      <div className="addOfferingContacts">
        <h2 className="addContact">
          {" "}
          {this.state.IsEdit ? "Edit Contact" : "Add Contact"}
        </h2>
        <div className="addContactForm">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <SubOfferingDropdowns
                selectedSubOffering= {this.state.data.SubOffering}
                subOfferings= {this.props.subOfferingList}
                onSubOfferingChange= {(e) => this.handleSelect(e)}
                defaultToggleName= {i18n.t("Select_Sub_Offerings")}
                required= {false}
                labelfor= 'subOffering'
                labelClassName= ''
                disabledValue= {this.props.editContactStatus}
                dropdownItem= {true}
                labelpresent= {true}
                selectedSubOfferingId= {this.state.OfferingID}
                SubOfferingDropdownsClass= {true}
              />
              <span className="errorMsg">{this.state.errors.SubOffering}</span>
            </div>
            <div className="col-lg-6 col-md-6">
              <label for="lname">Offering Lead Name</label>
              <input
                type="text"
                name="OfferingLeadName"
                maxLength="50"
                onChange={(e) => this.handleFieldChange(e)}
                value={this.state.data.OfferingLeadName}
                className="form-control"
                id="lname"
              />
              <span className="errorMsg">
                {this.state.errors.OfferingLeadName}
              </span>
            </div>
            <div className="col-lg-6 col-md-6">
              <label for="lemail">Offering Lead Email ID</label>
              <input
                type="text"
                maxLength="100"
                name="OfferingLeadEmailId"
                onChange={(e) => this.handleFieldChange(e)}
                value={this.state.data.OfferingLeadEmailId}
                className="form-control"
                id="lemail"
              />
              <span className="errorMsg">
                {this.state.errors.OfferingLeadEmailId}
              </span>
            </div>
            <div className="col-lg-6 col-md-6">
              <label for="cname">CTO Name</label>
              <input
                type="text"
                name="CTOName"
                maxLength="50"
                onChange={(e) => this.handleFieldChange(e)}
                value={this.state.data.CTOName}
                className="form-control"
                id="cname"
              />
              <span className="errorMsg">{this.state.errors.CTOName}</span>
            </div>
            <div className="col-lg-6 col-md-6">
              <label for="cemail">CTO Email ID</label>
              <input
                type="text"
                name="CTOEmailId"
                maxLength="100"
                onChange={(e) => this.handleFieldChange(e)}
                value={this.state.data.CTOEmailId}
                className="form-control"
                id="cemail"
              />
              <span className="errorMsg">{this.state.errors.CTOEmailId}</span>
            </div>
            <div className="col-lg-6 col-md-6">
              <label for="sname">SME Name</label>
              <input
                type="text"
                name="SMEName"
                maxLength="50"
                onChange={(e) => this.handleFieldChange(e)}
                value={this.state.data.SMEName}
                className="form-control"
                id="sname"
              />
              <span className="errorMsg">{this.state.errors.SMEName}</span>
            </div>
            <div className="col-lg-6 col-md-6">
              <label for="semail">SME Email ID</label>
              <input
                type="text"
                name="SMEEmailId"
                maxLength="100"
                onChange={(e) => this.handleFieldChange(e)}
                value={this.state.data.SMEEmailId}
                className="form-control"
                id="semail"
              />
              <span className="errorMsg">{this.state.errors.SMEEmailId}</span>
            </div>
          </div>
          <div className="contactBtns text-right">
            <button
              className="btn cancel btn-default"
              onClick={() => this.props.onClose()}
            >
              Cancel
            </button>
            {this.state.IsEdit ? (
              <button
                className="btn add btn-primary"
                onClick={this.updateContactData}
              >
                Update
              </button>
            ) : (
              <button
                className="btn add btn-primary"
                onClick={this.addContactData}
              >
                Add
              </button>
            )}
          </div>
        </div>
      </div>
    ) : null;
  }
}
