import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';
import { NavLink } from 'react-router-dom';
import Client from '../Images/client-0.svg';
import SynopsLoader from '../Shared/SynopsLoader';
import ClientBoardingPopup from './ClientBoardingPopup';
import axiosInstance from '../Shared/interceptor';
import {
  trycatchAlertPopup, procurementOffering, LocalApiBaseUrl, LocalApiBaseUrlImage,
} from '../Shared/Constant';
import AddMappingForm from '../ConnectorMaster/AddMappingForm';

import Download from '../Shared/download';

class ViewProject extends Component {
  constructor(props) {
    super(props);
    this.childToParentForMapping = this.childToParentForMapping.bind(this);

    this.refreshPage = this.refreshPage.bind(this);
    this.state = {
      isprojectapiloading: false,
      showMapping: false,
      dealInfo: {
        OrgEntityID: 0,
        OrgEntityName: 'Force 10',
        OfferingId: 0,
        Offering: 'F&A',
        SubOfferingId: 0,
        SubOffering: procurementOffering,
        LocationId: 0,
        DeliveryCenter: 'Delhi',
      },
      KeyIDs: [
        'DealID',
        'OrgEntityID',
        'ClientRegionID',
        'MaturityPhaseID',
        'LocationID',
        'OfferingID',
        'IndustryID',
        'DeliveryRegionID',
      ],
      // isActive: false,
      isAll: true,
      isNew: false,
      isApp: false,
      isRej: false,
      isClientDropdownStatus: false,
      selectedClient: 'Select Client',
      query: '',
      Status: '',
      Projectquery: '',
      clientImage: '',
      ClientData: [],
      EditData: {},
      filterClient: [],
      ProjectData: [],
      ChangingData: [],
      show: false,
    };
  }

  componentDidMount() {
    this.fetchClientData();
  }

  onPropFetchClient(RefreshClientID) {
    // const updatedProjectData = [];
    const { ClientData } = this.state;

    const { OrgEntityName } = ClientData.filter((a) => a.OrgEntityID === RefreshClientID)[0];

    const clientImage = ClientData.filter((a) => a.OrgEntityID === RefreshClientID)[0].OrgEntityImage;

    this.fetchProjectData(RefreshClientID);

    this.setState({
      selectedClient: OrgEntityName,
      selectedClientID: RefreshClientID,
      clientImage,
    });
  }

  childToParent = (action, details) => {
    const { selectedClientID } = this.state;
    if (action === 'AddMapping') {
      const dealInfo = {
        OrgEntityID: details.OrgEntityID,
        OrgEntityName: details.OrgEntityName,
        OfferingId: details.OfferingID,
        Offering: details.Offering,
        SubOfferingId: 0,
        SubOffering: details.Process,
        LocationId: details.LocationID,
        DeliveryCenter: details.City,
      };

      this.setState({
        dealInfo,
        showMapping: true,
        show: false,
      });
    }
    if (action === 'addProject') this.setState({ show: false });
    if (action === 'cancel') {
      this.setState({ show: false });
    } else {
      this.fetchProjectData(selectedClientID);
    }
  };

  addClientProjectShow = () => {
    this.setState({
      show: true,
      EditData: {},
    });
  };

  isClientDropdown = () => {
    this.setState({ isClientDropdownStatus: true });
  };

  isClientDropdownOverlay = () => {
    const { ClientData } = this.state;
    this.setState({ isClientDropdownStatus: false, query: '', filterClient: ClientData });
  };

  downloadEmail = (dealIDParam) => {
    axiosInstance
      .get(`${LocalApiBaseUrl}DealOnboarding/DownloadEmailOnButtonClick`, {
        params: {
          dealID: dealIDParam,
        },
      })
      .then((response) => {
        if (response.data) {
          if (response.data.FileName && response.data.FileSize) {
            const hrefpart = 'data:application/octet-stream;base64,';
            const link = document.createElement('a');
            link.href = hrefpart + response.data.FileContents;
            link.download = response.data.FileName;
            Download(link.href, response.data.FileName);
          }
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  handleProjectChange = (e) => {
    const updatedProjectData = [];
    const { ProjectData, Status, KeyIDs } = this.state;
    // const { ChangingData } = this.state;

    if (!Status) {
      ProjectData.forEach((obj) => {
        Object.keys(obj).forEach((key) => {
          if (
            KeyIDs.includes(key)
            && obj[key]
            && obj[key]
              .toString()
              .toUpperCase()
              .includes(e.target.value.trim().toUpperCase())
          ) { // empty block

          } else if (
            obj[key] !== null
              && obj[key]
                .toString()
                .toUpperCase()
                .includes(e.target.value.trim().toUpperCase())
          ) {
            if (!updatedProjectData.includes(obj)) {
              updatedProjectData.push(obj);
            }
          }
        });
      });
      /* } */
    } else if (Status === 'ALL') {
      ProjectData.forEach((obj) => {
        Object.keys(obj).forEach((key) => {
          if (
            KeyIDs.includes(key)
              && obj[key]
              && obj[key]
                .toString()
                .toUpperCase()
                .includes(e.target.value.trim().toUpperCase())
          ) { // empty block

          } else if (
            obj[key] !== null
                && obj[key]
                  .toString()
                  .toUpperCase()
                  .includes(e.target.value.trim().toUpperCase())
          ) {
            if (!updatedProjectData.includes(obj)) {
              updatedProjectData.push(obj);
            }
          }
        });
      });
    } else {
      ProjectData.filter((a) => a.Status === Status).forEach((obj) => {
        Object.keys(obj).forEach((key) => {
          if (
            KeyIDs.includes(key)
              && obj[key]
              && obj[key]
                .toString()
                .toUpperCase()
                .includes(e.target.value.trim().toUpperCase())
          ) { // empty block
          } else if (
            obj[key] !== null
                && obj[key]
                  .toString()
                  .toUpperCase()
                  .includes(e.target.value.trim().toUpperCase())
          ) {
            if (!updatedProjectData.includes(obj)) {
              updatedProjectData.push(obj);
            }
          }
        });
      });
    }

    this.setState({
      ChangingData: updatedProjectData,
      Projectquery: e.target.value,
    });
  };

  handleInputChange = (event) => {
    const { ClientData } = this.state;
    const updatedData = [];
    // const assets =
    ClientData.forEach((each) => {
      if (each.OrgEntityName.toUpperCase().includes(event.target.value.toUpperCase())) {
        updatedData.push(each);
      }
    });
    this.setState({ query: event.target.value, filterClient: updatedData });
  };

  onClictFetchClient = (selectedData) => {
    const { ClientData } = this.state;
    const clientName = ClientData.filter((a) => a.OrgEntityID === selectedData)[0].OrgEntityName;
    const clientImage = ClientData.filter((a) => a.OrgEntityID === selectedData)[0].OrgEntityImage;
    this.setState({
      selectedClient: clientName,
      selectedClientID: selectedData,
      clientImage,
    });
    this.fetchProjectData(selectedData);
    setTimeout(() => {
      this.isClientDropdownOverlay();
    }, 100);
  };

  handlebuttonclick = (e) => {
    const { Projectquery, ProjectData } = this.state;
    let ChangingData = [];
    const updatedProjectData = [];
    if (e.target.value === 'ALL') {
      this.setState({
        isAll: true,
        isNew: false,
        isApp: false,
        isRej: false,
      });
    } else if (e.target.value === 'New') {
      this.setState({
        isAll: false,
        isNew: true,
        isApp: false,
        isRej: false,
      });
    } else if (e.target.value === 'Approved') {
      this.setState({
        isAll: false,
        isNew: false,
        isApp: true,
        isRej: false,
      });
    } else {
      this.setState({
        isAll: false,
        isNew: false,
        isApp: false,
        isRej: true,
      });
    }

    if (e.target.value !== 'ALL') {
      if (!Projectquery) {
        ChangingData = ProjectData && ProjectData.filter((a) => a.Status === e.target.value);
      } else if (ProjectData) {
        ProjectData.filter((a) => a.Status === e.target.value).forEach((obj) => {
          Object.keys(obj).forEach((key) => {
            if (
              obj[key] !== null
                && obj[key]
                  .toString()
                  .toUpperCase()
                  .includes(Projectquery.trim().toUpperCase())
            ) {
              if (!updatedProjectData.includes(obj)) {
                updatedProjectData.push(obj);
              }
            }
          });
        });
        ChangingData = updatedProjectData;
      }
    } else if (!Projectquery) {
      ChangingData = ProjectData;
    } else if (ProjectData) {
      ProjectData.forEach((obj) => {
        Object.keys(obj).forEach((key) => {
          if (
            obj[key] !== null
                && obj[key]
                  .toString()
                  .toUpperCase()
                  .includes(Projectquery.trim().toUpperCase())
          ) {
            if (!updatedProjectData.includes(obj)) {
              updatedProjectData.push(obj);
            }
          }
        });
      });
      ChangingData = updatedProjectData;
    }
    this.setState({ ChangingData, Status: e.target.value });
  };

  // handlelablechange = (event, details) => {
  // console.log('LABEL', event, 'DETAILS', details);
  // };

  bindEditChange = (details) => {
    this.setState({ show: true, EditData: details });
  };

  bindisActiveChange = (details) => {
    const { ChangingData } = this.state;
    const Changing = ChangingData.map((each, index) => {
      if (each.DealID === details.DealID) {
        ChangingData[index].IsActive = !each.IsActive;
        this.postisactivestatus(each.DealID, each.IsActive);
        return each;
      }
      return each;
    });
    this.setState({ ChangingData: Changing });
  };

  addMappingShow = (details) => {
    const dealInfo = {
      OrgEntityID: details.OrgEntityID,
      OrgEntityName: details.OrgEntityName,
      OfferingId: details.OfferingID,
      Offering: details.Offering,
      SubOfferingId: 0,
      SubOffering: details.Process,
      LocationId: details.LocationID,
      DeliveryCenter: details.City,
    };

    this.setState({
      showMapping: true,
      dealInfo,
    });
  };

    handleImageError = (e) => {
      e.target.src = Client;
    };

    childToParentForMapping(selectedTab) {
      if (selectedTab === 'AddMapping') this.setState({ showMapping: false });
    }

    postisactivestatus(DealID,IsActive) {
      const params = {
        DealId: parseInt(DealID),
        ActiveStatus: IsActive,
      };
      axiosInstance
        .post(`${LocalApiBaseUrl}DealOnboarding/IsActiveButton`, null, {
          params,
        })
        .then((response) => {
          window.alert(response.data.StatusMessage);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }

    fetchProjectData(selectedData) {
      this.setState({
        isprojectapiloading: true,
      });
      axiosInstance
        .get(`${LocalApiBaseUrl}DealOnboarding/GetProjectsByClientID`, {
          params: {
            orgEntityID: selectedData,
          },
        })
        .then((response) => {
          this.setState({
            isprojectapiloading: false,
          });
          if (response.data) {
            this.setState({
              ProjectData: response.data,
              ChangingData: response.data,
            });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          this.setState({
            isprojectapiloading: false,
          });
        });
    }

    fetchClientData() {
      axiosInstance
        .get(`${LocalApiBaseUrl}DealOnboarding/GetViewProjectsClientList`, {
          params: {isDemo:true},
        })
        .then((response) => {
          if (response.data) {
            this.setState(
              {
                ClientData: response.data,
                filterClient: response.data,
              },
              () => {
                if (response.data.length > 0) {
                  this.onClictFetchClient(response.data[0].OrgEntityID);
                }
              },
            );
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }

    propfetchClientData(RefreshClientID) {
      axiosInstance
        .get(`${LocalApiBaseUrl}DealOnboarding/GetViewProjectsClientList`, {
          params: {isDemo:true},
        })
        .then((response) => {
          if (response.data) {
            this.setState(
              {
                ClientData: response.data,
                filterClient: response.data,
              },
              () => {
                if (RefreshClientID !== 0) {
                // if duplicate project added, this will have 0
                  this.onPropFetchClient(RefreshClientID);
                }
              },
            );
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }

    refreshPage(e, RefreshClientID) {
      this.propfetchClientData(RefreshClientID);
    }

    render() {
      const editIconTooltip = 'Edit the deal profile';
      const {
        show, showMapping, isprojectapiloading, clientImage, selectedClient, isClientDropdownStatus, query, filterClient, Projectquery, isAll, ProjectData, isNew, isApp, isRej, ChangingData, dealInfo, showDrafts, selectedClientID, EditData,
      } = this.state;
      const { isorgapiloading } = this.props;
      const style = { cursor: 'pointer' };
      let selectedDataTag;
      if (selectedClient === 'Select Client') {
        selectedDataTag = (
          <p>
            {i18n.t('Welcome_to_SynOps')}
            !
            {' '}
            <br />
            {' '}
            {i18n.t('View_Client_Message')}
          </p>
        );
      } else if (ProjectData.length === 0) {
        selectedDataTag = (
          <p>
            {i18n.t('Welcome_to_SynOps')}
            !
            {' '}
            <br />
            {i18n.t('Start_Journey')}
            {' '}
            {selectedClient}
          </p>
        );
      } else {
        selectedDataTag = ('');
      }

      return (
        <>
          {isprojectapiloading ? <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active /> : <></>}
          <div className="view-projects">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="view-search">
                    <div className="back-section">
                      <NavLink to="/" className="navbar-brand">
                        <span className="back-btn">
                          <i className="fas fa-chevron-left" />
                          {i18n.t('Back')}
                          {' '}
                        </span>
                      </NavLink>
                      <div className="client-select-dropdown" onClick={this.isClientDropdown} role="button" tabIndex={0}>
                        <img
                          loading="lazy"
                          className="client-logo"
                          src={
                          clientImage !== '' && clientImage !== null ? `${LocalApiBaseUrlImage}${clientImage}` : Client
                        }
                          alt=""
                          width="40"
                          height="40"
                          onError={this.handleImageError}
                        />
                        <span className="client-name">{selectedClient}</span>
                        {isClientDropdownStatus ? (
                          <div className="client-select-list">
                            <div className="searchFilter">
                              <input
                                type="search"
                                value={query}
                                onChange={this.handleInputChange}
                                placeholder="Search ..."
                              />
                            </div>
                            <div className="clientresult-list">
                              {filterClient
                                ? filterClient.map((cli, index) => (
                                  <ul>
                                    <li key={index.id} onClick={() => this.onClictFetchClient(cli.OrgEntityID)} role="presentation">
                                      <img
                                        loading="lazy"
                                        src={
                                            cli.OrgEntityImage !== '' && cli.OrgEntityImage !== null
                                              ? `${LocalApiBaseUrlImage}${cli.OrgEntityImage}`
                                              : Client
                                          }
                                        alt=""
                                        width="16"
                                        height="16"
                                        onError={this.handleImageError}
                                      />
                                      {' '}
                                      <span>{cli.OrgEntityName}</span>
                                    </li>
                                  </ul>
                                ))
                                : ''}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      {isClientDropdownStatus ? (
                        <div className="client-select-list-overlay" onClick={this.isClientDropdownOverlay} role="presentation" />
                      ) : (
                        ''
                      )}
                    </div>
                    {selectedClient !== 'Select Client' ? (
                      <div className="searchFilter">
                        <input
                          type="search"
                          value={Projectquery}
                          onChange={this.handleProjectChange}
                          placeholder={i18n.t('Search...')}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  {selectedClient !== 'Select Client' ? (
                    <div className="viewproject-buttons">
                      <div className="btn-group" role="group" aria-label="Basic example">
                        <button
                          type="button"
                          value="ALL"
                          onClick={this.handlebuttonclick}
                          className={`btn ${isAll ? 'active' : ''}`}
                        >
                          {i18n.t('All')}
                          (
                          {ProjectData && ProjectData.length}
                          )
                        </button>
                        <button
                          type="button"
                          value="New"
                          onClick={this.handlebuttonclick}
                          className={`btn ${isNew ? 'active' : ''}`}
                        >
                          {i18n.t('Pending')}
                          (
                          {ProjectData && ProjectData.filter((a) => a.Status === 'New').length}
                          )
                        </button>
                        <button
                          type="button"
                          value="Approved"
                          onClick={this.handlebuttonclick}
                          className={`btn ${isApp ? 'active' : ''}`}
                        >
                          {i18n.t('Approved')}
                          (
                          {ProjectData && ProjectData.filter((a) => a.Status === 'Approved').length}
                          )
                        </button>
                        <button
                          type="button"
                          value="Rejected"
                          onClick={this.handlebuttonclick}
                          className={`btn ${isRej ? 'active' : ''}`}
                        >
                          {i18n.t('Rejected')}
                          (
                          {ProjectData && ProjectData.filter((a) => a.Status === 'Rejected').length}
                          )
                        </button>
                      </div>

                      <div className="addproject-btn">
                        <button type="button" className="btn btn-outline-primary" onClick={this.addClientProjectShow}>
                          {i18n.t('Add_Project')}
                        </button>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {!isprojectapiloading && !isorgapiloading && (
              <div className="view-project-accordion">
                <div id="accordion">
                  {ChangingData
                    ? ChangingData.map((each, index) => {
                      let deliveryTag;
                      if (each.DeliveryRegion) {
                        deliveryTag = (
                          <li>
                            {`${each.DeliveryRegion}-`}
                            {each.City}
                          </li>
                        );
                      } else if (each.OtherDeliveryRegion) {
                        deliveryTag = (<li>{`${each.OtherDeliveryRegion}-${each.City}`}</li>);
                      } else {
                        deliveryTag = (<li />);
                      }

                      let statusTag;

                      if (each.Status === 'Approved') {
                        statusTag = (<span className="status project-approved">{i18n.t('Project_Approved')}</span>

                        );
                      } else if (each.Status === 'New') {
                        statusTag = (<span className="status project-awaiting">{i18n.t('Awaiting_Approval')}</span>
                        );
                      } else {
                        statusTag = (<span className="status project-rejected">{i18n.t('Project_Rejected')}</span>);
                      }

                      return (
                        <div className="card">
                          <div className="card-header">
                            <div
                              className={`${index === 0 ? 'card-link' : 'collapsed card-link'}`}
                              data-toggle="collapse"
                              href={`${`#collapse${index}`}`}
                            >
                              <div className="header-content">
                                <div className="header-link">
                                  <h6>
                                    {each.OrgEntityName}
  &nbsp;
                                    {each.Project ? `| ${each.Project}` : ''}
                                  </h6>
                                  <ul>
                                    {each.OtherIndustry ? (
                                      <li>{`Other-${each.OtherIndustry}`}</li>
                                    ) : (
                                      each.Industry && <li>{each.Industry}</li>
                                    )}
                                    {each.OtherClientRegion ? (
                                      <li>{`Other-${each.OtherClientRegion}`}</li>
                                    ) : (
                                      each.ClientRegionName && <li>{each.ClientRegionName}</li>
                                    )}

                                    <li>{each.Offering}</li>

                                    <li>{each.Process}</li>

                                    {deliveryTag}

                                    <li>{each.MaturityPhase}</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="header-right-info">
                              { statusTag }

                              <span
                                className="header-rightinfo-icons selecttooltip"
                                onClick={() => this.downloadEmail(each.DealID)}
                                data-tip="Download Approval email"
                                role="button"
                                tabIndex="0"
                              >
                                <FontAwesomeIcon icon={faEnvelopeOpenText} />
                                <span className="selecttooltiptext">Download approval email</span>
                              </span>
                              {/* Only rejected and approved projects can be edited and resent for approval */}
                              {(each.Status === 'Rejected'
                                    && (each.userRoleDetails.isAdmin
                                      || each.CreatedUser === sessionStorage.getItem('EnterpriseID')))
                                  || (each.Status === 'Approved'
                                    && (each.userRoleDetails.isAdmin
                                      || each.userRoleDetails.isMD
                                      || each.userRoleDetails.isSDL)) ? (
                                        <span
                                          className="header-rightinfo-icons selecttooltip"
                                          data-tip={editIconTooltip}
                                          onClick={this.bindEditChange.bind(this, each)}
                                          role="button"
                                          tabIndex="0"
                                        >
                                          <FontAwesomeIcon icon={faEdit} />
                                          <span className="selecttooltiptext">Edit the deal profile</span>
                                        </span>
                                ) : null}

                              {(each.Status === 'New'
                                    && (each.userRoleDetails.isAdmin
                                      || each.CreatedUser === sessionStorage.getItem('EnterpriseID')))
                                  || (each.Status === 'Approved'
                                    && (each.userRoleDetails.isAdmin
                                      || each.userRoleDetails.isMD
                                      || each.userRoleDetails.isSDL)
                                    && each.IsActive) ? (
                                      <span
                                        className="header-rightinfo-icons selecttooltip"
                                        data-tip="Configure Source Application"
                                        onClick={this.addMappingShow.bind(this, each)}
                                        style={style}
                                        role="button"
                                        tabIndex="0"
                                      >
                                        <i className="fas fa-folder-plus" />
                                        <span className="selecttooltiptext">Configure Source Application</span>
                                      </span>
                                ) : null}

                              <span>
                                {/* only approved projects can be toggled active inactive - if user is an admin */}
                                {each.Status === 'Approved' && each.userRoleDetails.isAdmin ? (
                                  <div className="toggle-group selecttooltip">
                                    <input
                                      type="checkbox"
                                      name="viewproject"
                                      id="viewproject"
                                      checked={each.IsActive}
                                    />
                                    <label onClick={this.bindisActiveChange.bind(this, each)} htmlFor="viewproject" role="presentation">
                                      Connection Status
                                    </label>
                                    <div className="onoffswitch" aria-hidden="true">
                                      <div className="onoffswitch-label">
                                        <div className="onoffswitch-inner" />
                                        <div className="onoffswitch-switch" />
                                      </div>
                                    </div>
                                    <span className="selecttooltiptext">Disable/enable a deal</span>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </span>
                            </div>
                          </div>
                          <div
                            id={`${`collapse${index}`}`}
                            className={`${index === 0 ? 'collapse show' : 'collapse'}`}
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                              <div className="left-info">
                                <div className="roles-data">
                                  <p>
                                    {' '}
                                    {i18n.t('Prime_Lead')}
                                  </p>
                                  <div className="roledata-list">
                                    {each.AccentureMD
                                          && each.AccentureMD.split(',').map((MD) => (
                                            <ul>
                                              <li>
                                                <i className="fas fa-user-circle" />
                                                <span>{MD}</span>
                                              </li>
                                            </ul>
                                          ))}
                                  </div>
                                </div>
                                <div className="roles-data">
                                  <p>
                                    {' '}
                                    {i18n.t('Service_Delivery_Lead')}
                                    (s)
                                    {' '}
                                  </p>
                                  <div className="roledata-list">
                                    {each.AccentureSDL
                                          && each.AccentureSDL.split(',').map((SDL) => (
                                            <ul>
                                              <li>
                                                <i className="fas fa-user-circle" />
                                                <span>{SDL}</span>
                                              </li>
                                            </ul>
                                          ))}
                                  </div>
                                </div>
                                <div className="roles-data">
                                  <p>
                                    {' '}
                                    {i18n.t('Client Experience Lead')}
                                    (s)
                                    {' '}
                                  </p>
                                  <div className="roledata-list">
                                    {each.Cxl
                                          && each.Cxl.split(',').map((CXL) => (
                                            <ul>
                                              <li>
                                                <i className="fas fa-user-circle" />
                                                <span>{CXL}</span>
                                              </li>
                                            </ul>
                                          ))}
                                  </div>
                                </div>
                                <div className="roles-data">
                                  <p>
                                    {' '}
                                    {i18n.t('OPS Account Lead')}
                                    (s)
                                    {' '}
                                  </p>
                                  <div className="roledata-list">
                                    {each.Oal
                                          && each.Oal.split(',').map((OAL) => (
                                            <ul>
                                              <li>
                                                <i className="fas fa-user-circle" />
                                                <span>{OAL}</span>
                                              </li>
                                            </ul>
                                          ))}
                                  </div>
                                </div>
                                <div className="roles-data">
                                  <p>{i18n.t('Users_with_edit_access')}</p>
                                  <div className="roledata-list">
                                    {each.EditAccessUsers
                                          && each.EditAccessUsers.split(',').map((EAU) => (
                                            <ul>
                                              <li>
                                                <i className="fas fa-user-circle" />
                                                <span>{EAU}</span>
                                              </li>
                                            </ul>
                                          ))}
                                  </div>
                                </div>
                                <div className="roles-data">
                                  <p>
                                    {i18n.t('Users_with_view_access')}
                                    {' '}
                                  </p>
                                  <div className="roledata-list">
                                    {each.ViewAccessUsers
                                          && each.ViewAccessUsers.split(',').map((VAU) => (
                                            <ul>
                                              <li>
                                                <i className="fas fa-user-circle" />
                                                <span>{VAU}</span>
                                              </li>
                                            </ul>
                                          ))}
                                  </div>
                                </div>
                              </div>
                              <div className="right-info">
                                <h6>{i18n.t('Approver_Remark')}</h6>
                                <p>{each.ApproverRemarks}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                    : null}
                </div>
                <div className="no-data-found">
                  { selectedDataTag }
                </div>
              </div>
              )}
            </div>
          </div>
          {show && (
          <ClientBoardingPopup
            childToParent={this.childToParent}
            refreshPage={this.refreshPage}
            EditData={EditData}
            selectedClient={selectedClient}
            selectedClientID={selectedClientID}
          />
          )}
          {showMapping && (
          <AddMappingForm
            childToParent={this.childToParentForMapping}
            showMapping={showMapping}
            showDrafts={showDrafts}
            dealInfo={dealInfo}
          />
          )}
        </>
      );
    }
}
ViewProject.defaultProps = {
  isorgapiloading: false,
};
ViewProject.propTypes = {
  isorgapiloading: PropTypes.bool,
};
export default ViewProject;

// selectedClient will have orgentityname (alias). we need to make an api call on opening the popup- to get the corebeatclientname of that record. if nul,, dropdown will be empty. if not, prepopulate that
