import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes, faUser, faPaperPlane, faEdit, faTrashAlt, faArrowAltCircleLeft,
} from '@fortawesome/free-solid-svg-icons';
import i18n from 'i18next';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup, regexForFields, LocalApiBaseUrl } from '../Shared/Constant';

import { FormatDateWithoutUTC } from '../Shared/commonFunctions';
import SynopsLoader from '../Shared/SynopsLoader';

class ChatWindow extends Component {
  constructor() {
    super();
    this.state = {
      MetricName: '',
      CommentaryID: 0,
      KPIMappingID: '',
      displayAddComments: true,
      apiCallStatus: false,
      commentsData: [],
      editData: {},
      filteredCommentsData: [],
      Comment: '',
      sortby: true,
      CommentaryFeatures: [],
    };
  }

    onHistoryClick = () => {
      this.props.saveCommentHistoryId({ ...this.props.selectedMetricDetails, showHistory: true });
    }

    closeChat = () => {
      this.props.close();
    }

    showEdit = (data) => {
      this.setState({
        displayAddComments: false, editData: data, Comment: data.Comment, CommentaryID: data.CommentaryID,
      });
    }

    hideEdit = () => {
      this.setState({ displayAddComments: true, Comment: '', CommentaryID: 0 });
    }

    sortBy = () => {
      const filteredCommentsData = [...this.state.filteredCommentsData];
      if (!this.state.sortby) {
        filteredCommentsData.sort((a, b) => ((new Date(a.UpdatedTS) > new Date(b.UpdatedTS)) ? 1 : ((new Date(b.UpdatedTS) > new Date(a.UpdatedTS)) ? -1 : 0)));
      } else {
        filteredCommentsData.sort((a, b) => ((new Date(a.UpdatedTS) < new Date(b.UpdatedTS)) ? 1 : ((new Date(b.UpdatedTS) < new Date(a.UpdatedTS)) ? -1 : 0)));
      }
      this.setState({ sortby: !this.state.sortby, filteredCommentsData });
    }

    saveComment = () => {
      if (this.state.Comment.trim()) {
        const body = {
          KPIMappingID: this.state.KPIMappingID,
          CommentaryID: this.state.CommentaryID,
          PeriodDT: this.props.SyncFilter.Month,
          Comment: this.state.Comment,
        };
        this.setState({ apiCallStatus: true });
        axiosInstance.post(`${LocalApiBaseUrl}Commentary/AddEditComments?LanguageID=${parseInt(this.props.languageData.id)}`, body)
          .then((response) => {
            if (response.data) {
              if (response.data.CommentaryEntity) {
                const commentsData = this.state.commentsData.filter((each) => each.CommentaryID !== response.data.CommentaryEntity.CommentaryID);
                const filteredCommentsData = this.state.filteredCommentsData.filter((each) => each.CommentaryID !== response.data.CommentaryEntity.CommentaryID);
                commentsData.push(response.data.CommentaryEntity);
                filteredCommentsData.push(response.data.CommentaryEntity);
                if (this.state.sortby) {
                  commentsData.sort((a, b) => ((new Date(a.UpdatedTS) > new Date(b.UpdatedTS)) ? 1 : ((new Date(b.UpdatedTS) > new Date(a.UpdatedTS)) ? -1 : 0)));
                  filteredCommentsData.sort((a, b) => ((new Date(a.UpdatedTS) > new Date(b.UpdatedTS)) ? 1 : ((new Date(b.UpdatedTS) > new Date(a.UpdatedTS)) ? -1 : 0)));
                } else {
                  commentsData.sort((a, b) => ((new Date(a.UpdatedTS) > new Date(b.UpdatedTS)) ? 1 : ((new Date(b.UpdatedTS) > new Date(a.UpdatedTS)) ? -1 : 0)));
                  filteredCommentsData.sort((a, b) => ((new Date(a.UpdatedTS) < new Date(b.UpdatedTS)) ? 1 : ((new Date(b.UpdatedTS) < new Date(a.UpdatedTS)) ? -1 : 0)));
                }
                this.setState({
                  Comment: '', displayAddComments: true, commentsData, filteredCommentsData, apiCallStatus: false, editData: {}, CommentaryID: 0,
                });
              }
            }
          }).catch((error) => {
            trycatchAlertPopup(error);
            this.setState({ apiCallStatus: false });
          });
      }
      if (this.state.displayAddComments && this.state.Comment.trim()) {
        this.props.onCommentAdd(this.props.selectedMetricDetails);
      }
    }

    onCommentChange = (e) => {
      if (e.target.value) {
        if (regexForFields.test(e.target.value)) {
          this.setState({ Comment: e.target.value });
        }
      } else {
        this.setState({ Comment: e.target.value });
      }
    }

    onDelete = (id) => {
      this.setState({ apiCallStatus: true });
      axiosInstance.post(`${LocalApiBaseUrl}Commentary/DeleteComments?commentaryID=${id}`)
        .then((response) => {
          if (response.data.IsSuccess) {
            const commentsData = this.state.commentsData.filter((each) => each.CommentaryID !== id);
            const filteredCommentsData = this.state.filteredCommentsData.filter((each) => each.CommentaryID !== id);

            this.setState({ commentsData, filteredCommentsData, apiCallStatus: false });

            this.props.onDeleteComment(this.props.selectedMetricDetails);
          }
        }).catch((error) => {
          trycatchAlertPopup(error);
          this.setState({ apiCallStatus: false });
        });
    }

    componentDidMount() {
      let metricName = '';
      this.setState({ apiCallStatus: true });
      axiosInstance.get(`${LocalApiBaseUrl}Commentary/GetCommentariesByMetric`, { params: { KPIMappingID: this.props.selectedMetricDetails.KPIMappingID, PeriodDT: this.props.SyncFilter.Month, LanguageID: parseInt(this.props.languageData.id) } })
        .then((response) => {
          if (response.data) {
            const commentsData = response.data;
            const filteredCommentsData = [];
            response.data.map((each) => {
              if (each.IsDeleted === false) {
                filteredCommentsData.push(each);
              }
            });

            this.setState({ commentsData, filteredCommentsData, apiCallStatus: false });
          }
        }).catch((error) => {
          trycatchAlertPopup(error);
          this.setState({ apiCallStatus: false });
        });
      this.props.data.map((each, index) => {
        if (each.SOCategoryID === this.props.selectedMetricDetails.SOCategoryID) {
          each.kpiData.map((item) => {
            if (item.KPIMappingID === this.props.selectedMetricDetails.KPIMappingID) {
              metricName = item.MetricName;
            }
          });
        }
      });
      let CommentaryFeatures = [];
      this.props.commentryRole.map((item) => {
        if (item.FeatureName === 'Commentary') {
          CommentaryFeatures = item.Operations;
        }
      });
      this.setState({ MetricName: metricName, KPIMappingID: this.props.selectedMetricDetails.KPIMappingID, CommentaryFeatures });
    }

    render() {
      return (this.state.CommentaryFeatures.includes('View') || this.state.CommentaryFeatures.includes('Post') ? (
        <div className="comment-overlay">
          <LoadingOverlay
            fadeSpeed={0}
            spinner={<SynopsLoader />}
            active={this.state.apiCallStatus}
          >

            <div className="comment-window">

              <div className="comments-header">
                <div className="title-with-icon">
                  <h5>
                    {i18n.t('Comments')}
                    {' '}
                    <span>
                      (
                      {this.state.filteredCommentsData.length}
                      )
                    </span>
                  </h5>
                  <div className="list-icon">
                    <span onClick={this.closeChat}>
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  </div>
                </div>
                <div className="outcome-name">{this.state.MetricName}</div>
              </div>

              {this.state.displayAddComments ? (
                <div className="comment-section">
                  <div className="title-with-icon action-links">

                    <p onClick={this.sortBy} className={`float-right sortby  p-r-20 ${this.state.sortby ? 'sortby-icon-up' : 'sortby-icon-down'}`}>{i18n.t('Sort_By')}</p>
                    <p onClick={this.onHistoryClick}>
                      {i18n.t('View_History')}
                      {' '}
                    </p>
                  </div>
                  <div className="comments-body">
                    {this.state.filteredCommentsData.map((each) => {
                      const date = new Date(each.UpdatedTS);

                      return (
                        <div className="user-specific-comment">
                          <div className="user-info">
                            <div className="user-profile">
                            <span>
                <FontAwesomeIcon icon={faUser} />
              </span>
                          </div>
                            <div className="profile-info">
                            <h6>
                {each.CreatedUser}
                {' '}
              </h6>
                            <span className="time-info">
                <ul>
                  {each.IsEdited ? <li className="edited-label">{i18n.t('Edited')}</li> : ''}
                  <li>{FormatDateWithoutUTC(each.UpdatedTS)}</li>
                </ul>
              </span>
                          </div>
                            {sessionStorage.getItem('EnterpriseID') === each.CreatedUser
                            ? (
                <div className="user-actions">
                  {this.state.CommentaryFeatures.includes('Post') ? (
                                      <span onClick={this.showEdit.bind(this, each)}>
                                          <FontAwesomeIcon icon={faEdit} />
                                        </span>
                                    ) : ''}
                  {this.state.CommentaryFeatures.includes('Post') ? (
                                      <span onClick={this.onDelete.bind(this, each.CommentaryID)}>
                                          <FontAwesomeIcon icon={faTrashAlt} />
                                        </span>
                                    ) : ''}
                </div>
                            ) : ''}
                          </div>
                          <div className="user-comment">
                            <p>
                            {each.Comment}
                            {' '}
                          </p>
                          </div>
                        </div>
                      );
                    })}

                  </div>
                  {this.state.CommentaryFeatures.includes('Post')
                    ? (
                      <div className="comments-footer">
                        <div className="user-profile">
                          <span>
                            <FontAwesomeIcon icon={faUser} />
                          </span>
                        </div>
                        <div className="comment-area">
                          <textarea rows="1" maxLength="300" className="form-control" placeholder={i18n.t('Add_Comment')} value={this.state.Comment} onChange={this.onCommentChange} />
                          <span className={this.state.Comment ? 'enable-button' : ''} onClick={this.saveComment}>
                            <FontAwesomeIcon icon={faPaperPlane} />
                          </span>
                        </div>
                      </div>
                    ) : ''}

                </div>
              ) : (
                <div className="comment-editsection">
                  <div className="title-with-icon edit-links">
                    <h6>
                      <span onClick={this.hideEdit}>
                        <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                      </span>
                      {i18n.t('Edit_Comment')}
                    </h6>

                  </div>
                  <div className="comments-body">
                    <div className="user-specific-comment">
                      <div className="user-info">
                        <div className="user-profile">
                          <span>
                          <FontAwesomeIcon icon={faUser} />
                        </span>
                        </div>
                        <div className="profile-info">
                          <h6>
                          {this.state.editData.CreatedUser}
                          {' '}
                        </h6>
                          <span className="time-info">
                          <ul>
                <li>{FormatDateWithoutUTC(this.state.editData.UpdatedTS)}</li>
              </ul>
                        </span>
                        </div>
                      </div>
                      <div className="user-comment">
                        <div className="comment-area">
                          <textarea rows="4" className="form-control" placeholder={i18n.t('Edit_Comment')} value={this.state.Comment} onChange={this.onCommentChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="comments-footer radius-btn">
                    <button className="btn btn-outline-secondary" onClick={this.hideEdit}>{i18n.t('Cancel')}</button>
                    <button className="btn btn-primary" onClick={this.saveComment}>{i18n.t('Update')}</button>
                  </div>
                </div>
              )}

            </div>
          </LoadingOverlay>
        </div>
      ) : ''
      );
    }
}
const mapStateToProps = (state) => ({
  languageData: state.CurrentFilter.languageData,
  selectedMetricDetails: { ...state.BOReducers.selectedMetriCCommentHistory },
  commentryRole: [...state.roleReducers.roleReducers],
  SyncFilter: { ...state.CurrentFilter.SyncFilter },
});
const mapDispatchToProps = (dispatch) => ({
  close: () => { dispatch({ type: 'SELECTED_METRIC_COMMENT_HISTORY', payload: { SOCategoryID: '', KPIMappingID: '', showHistory: false } }); },
  saveCommentHistoryId: (data) => { dispatch({ type: 'SELECTED_METRIC_COMMENT_HISTORY', payload: data }); },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatWindow);
