//REACT IMPORTS
import React, { Component } from "react";
import _ from 'lodash';

//CUSTOM IMPORTS
import LayoutContainer from "../WidgetConfigContainer/LayoutFiles/LayoutContainer";
import LayoutType from "../WidgetConfigContainer/LayoutFiles/LayoutType";
import { LocalApiBaseUrl, regexForNumbersOnly } from "../../Shared/Constant";
import axiosInstance from "../../Shared/interceptor";
import {trycatchAlertPopup} from "../../Shared/Constant";
import { isValidText,InvalidDescription } from "../../Shared/commonFunctions";
//STYLING IMPORTS
import LoadingOverlay from "react-loading-overlay";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import i18n from "i18next";
import "../../CSS/DDstyle.css"
import SynopsLoader from "../../Shared/SynopsLoader";

export default class AddWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            getApiStatus: false,
            allWidgetdata: [],
            WidgetAttributeFieldType: [],
            WidgetAttributeDataType: [],
            widgetconfigData: "",
            widgetPostRes: "",
            attributeCount: 3,
            isDonutDisplay: true,
            errors: {
                TileHeader: "",
                OfferingID: "",
                WidgetConfigurationID: "",
            },
            data: {
                TileHeader: "",
                OfferingID: "",
                WidgetConfigurationID: 4,
            },
            editData: {},
        }
    }

    /* Getting Widget configuration data */
    componentWillMount() {
        this.getWidgetData();
    }


    //API call to get widget data/fieldtype and datatype dropdowns
    getWidgetData() {
        //ATTRIBUTE-DATATYPE GET CALL
        axiosInstance.get(`${LocalApiBaseUrl}MTCDDWidgetConfiguration/GETWidgetAttributeDataType`)
            .then((response) => {
                if (response.data) {
                    this.setState({ WidgetAttributeDataType: response.data });
                }
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      

        //ATTRIBUTE-FIELDTYPE GET CALL
        axiosInstance.get(`${LocalApiBaseUrl}MTCDDWidgetConfiguration/GETWidgetAttributeFieldType`)
            .then((response) => {
                if (response.data) {
                    this.setState({ WidgetAttributeFieldType: response.data });
                }
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      

        //WIDGETCONFIGURATION GET CALL
        axiosInstance.get(`${LocalApiBaseUrl}MTCDDWidgetConfiguration/GETWidgetConfiguration`)
            .then((response) => {
                if (response.data) {
                    this.setState({ allWidgetdata: response.data });
                    this.layoutChange(this.state.attributeCount, this.state.isDonutDisplay);
                    this.setPostdataObject();
                    this.setState({ getApiStatus: true });
                }
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
    }

    //CALLED FROM LAYOUT TYPE COMPONENT ON LAYOUT CHANGE
    layoutChange = (attributecount, isDonutDisplay) => {
        let ID = this.props.filterDetails.process;
        if (attributecount === 3 && isDonutDisplay === true) {
            this.setState({
                ...this.state,
                widgetconfigData: this.setWidgetconfigData(attributecount, isDonutDisplay),
                attributeCount: attributecount,
                isDonutDisplay: isDonutDisplay,
                data: {
                    ...this.state.data,
                    TileHeader: "",
                    OfferingID: ID,
                },
            }, () => this.setPostdataObject());
        }
        else if (attributecount === 3 && isDonutDisplay === false) {
            this.setState({
                ...this.state,
                widgetconfigData: this.setWidgetconfigData(attributecount, isDonutDisplay),
                attributeCount: attributecount,
                isDonutDisplay: isDonutDisplay,
                data: {
                    ...this.state.data,
                    TileHeader: "",
                    OfferingID: ID,
                },
            }, () => this.setPostdataObject());
        }
        else if (attributecount === 2 && isDonutDisplay === false) {
            this.setState({
                ...this.state,
                widgetconfigData: this.setWidgetconfigData(attributecount, isDonutDisplay),
                attributeCount: attributecount,
                isDonutDisplay: isDonutDisplay,
                data: {
                    ...this.state.data,
                    TileHeader: "",
                    OfferingID: ID,
                },
            }, () => this.setPostdataObject());
        }
        else if (attributecount === 4 && isDonutDisplay === false) {
            this.setState({
                ...this.state,
                widgetconfigData: this.setWidgetconfigData(attributecount, isDonutDisplay),
                attributeCount: attributecount,
                isDonutDisplay: isDonutDisplay,
                data: {
                    ...this.state.data,
                    TileHeader: "",
                    OfferingID: ID,
                },
            }, () => this.setPostdataObject());
        }
    };

    //DYANAMIC OBJECT CREATION FOR DYNAMIC FORM FIELDS
    setPostdataObject = () => {
        let Attributes = [];
        //CREATING DYNAMIC ARRAY BASED ON ATTRIBUTE COUNT
        if (this.props.add) {
            Attributes = Array(this.state.widgetconfigData.AttributeCount).fill(true)
                .map((value, index) => ({
                    AttributeName: "",
                    AttributeDataType: "",
                    AttributeFieldType: "",
                    SequenceNumber: index + 1,
                    ListSource: "",
                    ListItems: "",
                }));
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    Attributes: Attributes,
                },
                errors: {
                    ...this.state.errors,
                    TileHeader: "",
                    Attributes: Attributes,
                }
            });
        }
        else if (this.props.edit) {
            let AttributeData = this.props.widgetEditData.Attributes.filter((data) => data.ListItems)
            AttributeData.map((ele) => { ele.ListItems = "" });
            Attributes = Array(this.props.widgetEditData.Attributes.length).fill(true)
                .map((value, index) => ({
                    AttributeName: "",
                    AttributeDataType: "",
                    AttributeFieldType: "",
                    SequenceNumber: index + 1,
                    ListSource: "",
                    ListItems: "",
                }));
            this.setState({
                ...this.state,
                editData: this.props.widgetEditData,
                errors: {
                    ...this.state.errors,
                    TileHeader: "",
                    Attributes: Attributes
                }
            });
        }
    }


    //SETTING WIDGETCONFIG ID here,by manipulating data coming from GETWIDGETCONFIGURATION API
    setWidgetconfigData(attributecount, isDonutDisplay) {
        let tempdata = this.state.allWidgetdata;
        this.state.allWidgetdata = [];
        let donutlayout = tempdata.find((layout) => layout.IsDonutDisplay === true)
        this.state.allWidgetdata.push(donutlayout);
        tempdata.splice(tempdata.findIndex(layout => layout.IsDonutDisplay === true), 1)
        tempdata.sort((a, b) => a.AttributeCount - b.AttributeCount);
        this.state.allWidgetdata.push(...tempdata);
        let dataInfo = this.state.allWidgetdata.find((data) => data.AttributeCount === attributecount && data.IsDonutDisplay === isDonutDisplay);
        this.state.data.WidgetConfigurationID = dataInfo.ID;
        return dataInfo;

    }

    //SETTING FORM DATA- CALLED FROM LAYOUT CONTAINER 
    formDataHandler = (target, value, idx) => {
        if (this.props.edit) {
            if (target == 'TileHeader') {
                this.setState({
                    ...this.state,
                    editData: {
                        ...this.state.editData,
                        [target]: value
                    }
                });
            }
            else {
                let attributesEditDataClone = [...this.state.editData.Attributes];
                if (target == 'ListItems') {
                    attributesEditDataClone[idx] = { ...attributesEditDataClone[idx], ListSource: "Manual" };
                }
                attributesEditDataClone[idx] = { ...attributesEditDataClone[idx], [target]: value };
                this.setState({
                    ...this.state,
                    editData: {
                        ...this.state.editData,
                        Attributes: [...attributesEditDataClone],
                    }
                })
            }
        }
        else if (this.props.add) {
            if (target == 'TileHeader') {
                this.setState({
                    ...this.state,
                    data: {
                        ...this.state.data,
                        [target]: value,
                    }
                })
            }
            else {
                let attributesDataClone = [...this.state.data.Attributes];
                if (target == 'ListItems') {
                    attributesDataClone[idx] = { ...attributesDataClone[idx], ListSource: "Manual" };
                }
                attributesDataClone[idx] = { ...attributesDataClone[idx], [target]: value };
                this.setState({
                    data: {
                        ...this.state.data,
                        Attributes: [...attributesDataClone],
                    }
                })
            }
        }
    }


    //HANDLING LISTITEMS VALIDATIONS 
    listItemsValidation = (name, value, index, dataType) => {

        let errors = _.cloneDeep(this.state.errors);
        let flag = true, error = "";
        let split_string = value.split(',');
        if (dataType == 2) {
            split_string.forEach((val, i) => {
                let trimval = val.trim();
                if (!regexForNumbersOnly.test(trimval)) {
                    errors.Attributes[index][name] = "You can only add Numeric values here";
                    flag = false;
                    error = errors.Attributes[index][name];
                    return { error, flag }
                }
            })

        }
        if (this.props.edit) {
            let duplicate = [];
            let existinglistitems = this.state.editData.Attributes[index].ListItems_En.split(',');
            let checkcount = 50 - existinglistitems.length;
            split_string.map((val) => {
                let trimval = val.trim();
                let existingval = existinglistitems.filter(ele => ele.toLowerCase() == trimval.toLowerCase()).toString();
                let duplicateval = trimval && trimval.localeCompare(existingval, undefined, { sensitivity: 'base' });
                duplicate.push(duplicateval);
            });

            for (let i = 0; i < split_string.length; i++) {
                split_string[i] = split_string[i].trim();
                if (split_string.some((val, i) => split_string.indexOf(val) !== i)) {
                    errors.Attributes[index][name] = "List Items can't contain duplicate values or empty strings";
                    flag = false;
                    error = errors.Attributes[index][name]
                    return { error, flag }
                }
            }
            //VALIDATION FOR 50 LISTITEMS TO BE ENTERED.
            if (split_string.length > checkcount) {
                errors.Attributes[index][name] = "You can't enter more than 50 list items";
                flag = false;
                error = errors.Attributes[index][name];
                return { error, flag }
            }
            //VALIDATION FOR DUPLICATE VALUES.
            else if (duplicate.length > 0) {
                duplicate.map((val) => {
                    if (val === 0) {
                        errors.Attributes[index][name] = "This List Item is there in Existing List already";
                        flag = false;
                        error = errors.Attributes[index][name];
                        return { error, flag }
                    }
                    else if (!val) {
                        errors.Attributes[index][name] = "Empty values are not accepted,Check if you have added any extra comma";
                        flag = false;
                        error = errors.Attributes[index][name];
                        return { error, flag }
                    }
                })

            }

        }
        else {
            //TRIM WHITESPACES
            for (let i = 0; i < split_string.length; i++) {
                split_string[i] = split_string[i].trim();
                //VALIDATION FOR EMPTY VALUES
                if (split_string[i] == "") {
                    errors.Attributes[index][name] = "Empty values are not accepted,Check if you have added any extra comma";
                    flag = false;
                    error = errors.Attributes[index][name];
                    return { error, flag }
                }
                //VALIDATION FOR DUPLICATE VALUES
                else if (split_string.some((val, i) => split_string.indexOf(val) !== i)) {
                    errors.Attributes[index][name] = "List Items can't contain duplicate values or empty strings";
                    flag = false;
                    error = errors.Attributes[index][name]
                    return { error, flag }
                }
            }
            //VALIDATION FOR 50 LISTITEMS TO BE ENTERED.
            if (split_string.length > 50) {
                errors.Attributes[index][name] = "You can't enter more than 50 list items";
                flag = false;
                error = errors.Attributes[index][name]
                return { error, flag }
            }
        }

        return { error, flag }
    }

    //VALIDATING FORM ON ADD/EDIT
    validateForm = () => {
        let errors = _.cloneDeep(this.state.errors);
        let data = _.cloneDeep(this.state.data);
        let editdata = _.cloneDeep(this.state.editData);
        let specialCharValidation=InvalidDescription();
        let formIsValid = true;
        if (this.props.edit) {
            if (editdata['TileHeader'] === "") {
                errors['TileHeader'] = "Please Enter Widget Name";
                formIsValid = false;
            }
            else{
                errors['TileHeader'] = "";
                if(!isValidText(editdata['TileHeader'])){
                    errors['TileHeader'] = specialCharValidation;
                    formIsValid = false;
                }
            }
            let attrData = editdata['Attributes'];
            attrData.forEach((attr, index) => {
                Object.keys(attr).map((key) => {
                    if (key == 'AttributeName') {
                        if (attr[key] == "") {
                            switch (key) {
                                case 'AttributeName': errors['Attributes'][index][key] = "Please Enter Field Label"; break;
                                case 'AttributeDataType': errors['Attributes'][index][key] = "Please Select Data Type"; break;
                                case 'AttributeFieldType': errors['Attributes'][index][key] = "Please  Select Field Type"; break;
                                case 'ListItems': errors['Attributes'][index][key] = "Please Enter List Items"; break;
                                default: errors['Attributes'][index][key] = "Please Enter" + `${key}`; break;
                            }
                            formIsValid = false;
                        }
                        else 
                        {
                            errors['Attributes'][index][key] = ""
                            if(!isValidText(attr[key])){
                                errors['Attributes'][index][key] = specialCharValidation;
                                formIsValid = false;
                            }
                        }
                    }
                    else if (key == 'ListItems') {
                        if (attr['AttributeFieldType'] == 2 && attr['ListItems'].trim() != "") {
                            let { error, flag } = this.listItemsValidation(key, attr[key], index, attr['AttributeDataType']);
                            error != "" ? errors['Attributes'][index][key] = error : errors['Attributes'][index][key] = "";
                            formIsValid = flag;

                        }
                        else if (attr['AttributeFieldType'] == 2 && attr['ListItems'].trim() == "") {
                            if (errors['Attributes'][index][key] !== "") {
                                errors['Attributes'][index][key] = "";
                                this.state.editData.Attributes[index]['ListSource'] = null;
                            }
                        }
                    }
                    else if (key == 'SequenceNumber') errors['Attributes'][index][key] = "";
                })
            })
        }
        else if (this.props.add) {
            //HANDLING FIELDS VALIDATIONS ON ADD
            Object.keys(data).map((key) => {
                if (data[key] === "") {
                    switch (key) {
                        case 'TileHeader': errors[key] = "Please Enter Widget Name"; break;
                        default: errors[key] = "Please Enter" + `${key}`;
                    }
                    formIsValid = false;
                }
                else if(!isValidText(data[key])){
                    errors[key] = specialCharValidation;
                    formIsValid = false;
                }
                else if (data[key] instanceof Array) {
                    data[key].forEach((attr, index) => {
                        Object.keys(attr).map((val) => {
                            if (attr[val] === "") {
                                switch (val) {
                                    case 'AttributeName': errors[key][index][val] = "Please Enter Field Label"; break;
                                    case 'AttributeDataType': errors[key][index][val] = "Please Select Data Type"; break;
                                    case 'AttributeFieldType': errors[key][index][val] = "Please  Select Field Type"; break;
                                    case 'ListItems': errors[key][index][val] = "Please Enter List Items"; break;
                                    default: errors[key][index][val] = "Please Enter" + `${val}`; break;
                                }
                                formIsValid = false;
                                if (val == 'ListItems' || val == 'ListSource') {
                                    if (attr['AttributeFieldType'] == 1 || attr['AttributeFieldType'] == "") {
                                        errors[key][index][val] = "";
                                        formIsValid = true;
                                    }
                                }
                            }
                            else {
                                if (attr['AttributeFieldType'] == "2" && val == 'ListItems') {
                                    let { error, flag } = this.listItemsValidation(val, attr[val], index, attr['AttributeDataType']);
                                    error != "" ? errors[key][index][val] = error : errors[key][index][val] = "";
                                    formIsValid = flag;
                                }
                                else if(!isValidText(attr[val])){
                                        errors[key][index][val] = specialCharValidation;
                                        formIsValid = false;
                                    }
                                else{
                                    errors[key][index][val] = "" ;
                                }
                            }
                        })
                    })
                }
                else {
                    errors[key] = ""
                }
            })
        }
        this.setState({ errors: errors }, () => this.checknoerror(formIsValid));
    };

    //Check if errors are empty
    checknoerror = (isSuccess) => {
        let errors = _.cloneDeep(this.state.errors)
        Object.keys(errors).map((key) => {
            if (errors[key] !== "" && key != "Attributes") {
                isSuccess = false;
            }
            else if (errors[key] instanceof Array) {
                errors[key].forEach((err) => {
                    Object.keys(err).map((val) => {
                        if (err[val] !== "") {
                            isSuccess = false;
                        }
                    })
                })
            }
        });
        if (isSuccess) {
            this.props.edit ? this.props.onUpdate(isSuccess, this.state.editData) : this.props.onAdd(isSuccess, this.state.data);
        }
    }
    //ADD BUTTON FOR WIDGET CREATION
    addWidgetData = (e) => {
        e.preventDefault();
        this.validateForm();
    };

    //UPDATE HANDLING FOR EDIT
    editWidgetData = (e) => {
        e.preventDefault();
        this.validateForm();
    }

    //JSX
    render() {

        // Show Loading spinner when isApiLoading(when POST is in process for ADD/EDIT) is true
        return this.state.getApiStatus ? (
            <>
                {this.props.isApiLoading && (
                    <LoadingOverlay
                        fadeSpeed={0}
                        spinner={<SynopsLoader />}
                        active
                        // text={i18n.t("Please_wait") + "..."}
                    />
                )}
                <Modal
                    show={this.props.show}
                    onHide={this.props.onCancel}
                    size="xl"
                    className="widget-pop widget-popnew"
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.edit == true
                                ? "Edit Widget"
                                : "Add Widget"}
                            <span
                                style={{ margin: "0px 250px", display: "inline-block" }}
                                id="message"
                            ></span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <LayoutType
                                editActive={this.props.edit}
                                widgetEditData={this.state.editData}
                                onLayoutChange={this.layoutChange}
                                widgetconfigData={this.state.allWidgetdata}
                            />
                            <LayoutContainer
                                formData={this.formDataHandler}
                                editActive={this.props.edit}
                                widgetData={this.state.data}
                                widgetErrors={this.state.errors}
                                widgetEditData={this.state.editData}
                                fieldData={this.state.WidgetAttributeFieldType}
                                dataTypeData={this.state.WidgetAttributeDataType}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.props.onCancel.bind(this)}>
                            Cancel
                        </Button>
                        {this.props.edit == true ? (
                            <Button
                                variant="primary"
                                onClick={(e) => this.editWidgetData(e)}>
                                Update
                            </Button>
                        ) : (
                                <Button
                                    variant="primary"
                                    onClick={(e) => this.addWidgetData(e)}>
                                    Add
                                </Button>)}
                    </Modal.Footer>
                </Modal>
            </>
        ) : (
                <LoadingOverlay
                    fadeSpeed={0}
                    spinner={<SynopsLoader />}
                    active
                    // text={i18n.t("Loading Widget Configurator") + "..."}
                />
            );
    }
}
