import React, { Component } from "react";
import "./reference.css";
import { Modal, Button } from "react-bootstrap";
import $ from "jquery";
import i18n from "i18next";
import { connect } from 'react-redux';
import _ from 'lodash';
import LoadingOverlay from "react-loading-overlay";
import { LocalApiBaseUrl, regexForTextAreas, regexForToolURL,regexForFields,regexForReferenceDescription,commonToolRegex } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import SynopsLoader from "../Shared/SynopsLoader";
import { trycatchAlertPopup } from "../Shared/Constant";
import { validateIsAllowedDomainFromURL, fetchAllowedDomains,isValidText,InvalidDescription} from '../Shared/commonFunctions';

class AddEditReferenceModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addReferenceDetailsStatus: false,
            viewReferenceDetailsStatus: false,
            specialCharmessage:InvalidDescription(),
            buttonName: "Add",
            disabledAdd:true,
            addButtonDisable: true,
            desc: "",
            url: "",
            DocName:"",
            errors: {},
            addedReference: [{ID: 0,
                SOCategoryID: this.props.SOCatID == 0 ? null : this.props.SOCatID,
                IOJSectionHierarchyID: this.props.IOJSectionHierarchyID,
                DocURL: "",
                DocName:"",
                DocDescription: "",
                added: false,
                errors: {},
                formIsValid: false,}],
            manyErrors: [],
            addStatus: false,
            isAPILoading: false,
            editData: {},
            allowedDomainsArray: [],
        };

        this.addReferenceModalClose = this.addReferenceModalClose.bind(this);
    }
  
    
    handleValidation = () => {
        const { allowedDomainsArray,specialCharmessage } = this.state;
        let errors = {};
        let formIsValid = true;
        let data = this.state.editData;
        if (data.DocURL == "" || data.DocURL.trim() === "") {
            data.errors["DocURL"] = i18n.t("Url_Required");
            formIsValid = false;
        } else {
            if(!commonToolRegex.test(data.DocURL.toLowerCase().trim())){
                if (!regexForToolURL.test(data.DocURL.toLowerCase().trim())) {
                    data.DocURL = data.DocURL.toLowerCase();
                    data.errors["DocURL"] = i18n.t("Invalid_URL");
                    formIsValid = false;
                } else {
                    const isAllowed = validateIsAllowedDomainFromURL(data.DocURL.trim(), allowedDomainsArray);
                    if (!isAllowed) {
                        data.errors['DocURL'] = i18n.t('subdomain_or_domain_not_whitelisted');
                        formIsValid = false;
                    }
                }
            } else {
                data.errors["DocURL"] =i18n.t("commonToolRegex_validatemessage");
                formIsValid = false;
            }
        }
        if (data.DocName == "" || data.DocName.trim() === "" ) {
            data.errors["DocName"] = i18n.t("addWoErrorM1");
            formIsValid = false;
        } else {
            if (!regexForTextAreas.test(data.DocName.trim())) {
                data.DocName = data.DocName.toLowerCase();
                data.errors["DocName"] = i18n.t("Name_contains_Special_characters");
                formIsValid = false;
            }
            else{
                if(!isValidText(data.DocName.trim())) {
                    data.DocName = data.DocName.toLowerCase();
                    data.errors["DocName"] = specialCharmessage;
                    formIsValid = false;
                }
            }
        }
        if (data.DocDescription == "" || data.DocDescription.trim() === "") {
            data.errors["DocDescription"] = i18n.t("ToolDescription_Required");
            formIsValid = false;
        } 
        else {
            if (!regexForReferenceDescription.test(data.DocDescription.trim())) {
                data.DocDescription = data.DocDescription.toLowerCase();
                data.errors["DocDescription"] = i18n.t("ToolDescription_SpecialChar");
                formIsValid = false;
            }
            else{
                if(!isValidText(data.DocDescription.trim())) {
                    data.DocDescription = data.DocDescription.toLowerCase();
                data.errors["DocDescription"] = specialCharmessage;
                formIsValid = false;
                }
            }
        }
        this.setState({
            editData: data,
        });
        return formIsValid;
    };

    handleEachValidation = (data) => {
        const { allowedDomainsArray,specialCharmessage } = this.state;
        let errors = {};
        let formIsValid = true;
        if (data.DocURL == "" || data.DocURL.trim() === "") {
            errors["url"] = "Url is required";
            formIsValid = false;
        } else {
            if(!commonToolRegex.test(data.DocURL.toLowerCase().trim())){
                if (!regexForToolURL.test(data.DocURL.toLowerCase().trim())) {
                    errors["url"] = i18n.t("Invalid_URL");
                    formIsValid = false;
                } else {
                    const isAllowed = validateIsAllowedDomainFromURL(data.DocURL, allowedDomainsArray);
                    if (!isAllowed) {
                        errors['url'] = i18n.t('subdomain_or_domain_not_whitelisted');
                        formIsValid = false;
                    }
                }
            } else {
                errors["url"] =i18n.t("commonToolRegex_validatemessage");
                formIsValid = false;
            }
        }
        if (data.DocDescription == "" || data.DocDescription.trim() === "") {
            errors["desc"] = i18n.t("ToolDescription_Required");
            formIsValid = false;
        } 
        else {
            if (!regexForReferenceDescription.test(data.DocDescription.trim())) {
                errors["desc"] = i18n.t("ToolDescription_SpecialChar");
                formIsValid = false;
            }
            else{
                if(!isValidText(data.DocDescription.trim())) {
                    errors["desc"] = specialCharmessage;
                    formIsValid = false;
                }
            }
        }
        if (data.DocName == "" || data.DocName.trim() === "") {
            errors["DocName"] = i18n.t("addWoErrorM1");
            formIsValid = false;
        } else {
            if (!regexForTextAreas.test(data.DocName.trim())) {
                errors["DocName"] = i18n.t("Name_contains_Special_characters");
                formIsValid = false;
            }
            else{
                if(!isValidText(data.DocName.trim())) {
                    errors["DocName"] = specialCharmessage;
                    formIsValid = false;
                }
            }
        }

        let s = this.state;
        s.addedReference = s.addedReference.map((obj) => {
            if (obj.DocURL === data.DocURL && obj.DocDescription === data.DocDescription && obj.DocName === data.DocName) {
                obj.errors = errors;
                obj.DocURL = obj.DocURL.trim();
                obj.DocDescription = obj.DocDescription.trim();
                obj.DocName = obj.DocName.trim();
                obj.formIsValid = formIsValid;
                return obj;
            } else {
                obj.DocURL = obj.DocURL.trim();
                obj.DocDescription = obj.DocDescription.trim();
                obj.DocName = obj.DocName.trim();
                return obj;
            }
        });
        this.setState({
            addedReference: s.addedReference,
        });
    };

    handleManyValidation = () => {
        let formValid = true;
        this.state.addedReference.map((obj) => this.handleEachValidation(obj));
        let count = 0;
        this.state.addedReference.map((data) => {
            if (data.formIsValid) {
                count++;
            }
        });
        if (count != this.state.addedReference.length) {
            formValid = false;
        }
        return formValid;
    };

    handleManyUrlChange = (event, index) => {
        let s = this.state;
        let value = event.target.value;
        let disabledAdd = true;
        s.addedReference = s.addedReference.map((obj,i) => {
            if (i === index) {
                obj.DocURL = value;
                obj.errors = {};
                
            } 
            if(!obj.DocURL || !obj.DocDescription || !obj.DocName){
                 disabledAdd = true;
            }else{
                 disabledAdd = false;
            }
            return obj;
        });
        
        disabledAdd = s.addedReference.filter((obj,i) => {if(!obj.DocURL.trim() || !obj.DocDescription.trim()  || !obj.DocName.trim() ){return obj;}}).length === 0 ? false : true;
        this.setState({
            addedReference: s.addedReference,
            disabledAdd:disabledAdd
        });
    };

    handleManynameChange = (event, index) => {
        let s = this.state;
        let value = event.target.value;
        let disabledAdd = true;
        s.addedReference = s.addedReference.map((obj,i) => {
            if (i === index) {
                obj.DocName = value;
                obj.errors = {};
                
            } 
            
            return obj;
        });
        disabledAdd = s.addedReference.filter((obj,i) => {if(!obj.DocURL.trim()  || !obj.DocDescription.trim()  || !obj.DocName.trim() ){return obj;}}).length === 0 ? false : true;
        this.setState({
            addedReference: s.addedReference,
            disabledAdd:disabledAdd
        });
    };
    
    handleManyDescChange = (event, index) => {
        let s = this.state;
        let value = event.target.value;
        let disabledAdd = true;
        s.addedReference = s.addedReference.map((obj,i) => {
            if (i === index) {
                obj.DocDescription = value;
                obj.errors = {};
               
            } 
           return obj;
        });
        disabledAdd = s.addedReference.filter((obj,i) => {if(!obj.DocURL.trim()  || !obj.DocDescription.trim()  || !obj.DocName.trim() ){return obj;}}).length === 0 ? false : true;
        this.setState({
            addedReference: s.addedReference,
            disabledAdd:disabledAdd
        });
    };
    handleSubmit = () => {
        // let formIsValid = this.handleValidation();
        const { isClientStory } = this.props
        let manyFormValid = this.handleManyValidation();

        if ( manyFormValid) {
            this.setState({
                isAPILoading: true,
            });
            // let SOCategoryID = this.props.SOCatID;
            let data = this.state;
            // let obj = {
            //     ID: 0,
            //     SOCategoryID: SOCategoryID,
            //     DocURL: data.url,
            //     DocDescription: data.desc,
            //     added: true,
            //     errors: {},
            //     formIsValid: false,
            // };
            // let RefList=[];
            // if(data.addedReference.length >0)
            // {
            //     data.addedReference.push(obj);
            //     RefList=data.addedReference;;
            // }
            // else
            // {
            //     let arr=[];
            //     arr.push(obj);
            //     RefList=arr;
            // }
            
            // let filterClient = this.props.filterDetails.Client;
            // let filterProcess = this.props.filterDetails.process;
            // let filterLocations = this.props.filterDetails.Locations;
            let filterClient =
              this.props.filterDetails == undefined
                ? this.props.offeringIdParam.Client
                : this.props.filterDetails.Client;
            let filterProcess =
              this.props.filterDetails == undefined
                ? this.props.offeringIdParam.process
                : this.props.filterDetails.process;
            let filterLocations =
              this.props.filterDetails == undefined
                ? this.props.offeringIdParam.Locations
                : this.props.filterDetails.Locations;
            // let SOCatID = this.props.SOCatID;

            if(isClientStory){
                data.addedReference = data.addedReference.map(each=>({...each,isClientStory:true}));
            }
            
            let load = {
                OrgEntityId: filterClient,
                OfferingId: filterProcess,
                LocationId: filterLocations,
                RefList: data.addedReference,
            };
            axiosInstance
                .post(`${LocalApiBaseUrl}ReferenceDocument/AddEditReference`, load)
                .then((response) => {
                    this.setState({
                        isAPILoading: false,
                    });
                    window.alert(response.data.StatusMessage);
                    this.props.fetchInitialDetails();
                    this.props.close();
                })
                .catch((error) => {
                    this.setState({
                        isAPILoading: false,
                    });
                        trycatchAlertPopup(error);
              
                });
        }
    };
    handleUpdate = () => {
        let formIsValid = this.handleValidation();
        if (formIsValid) {
            this.setState({
              isAPILoading: true,
            });
            let SOCatID = this.props.SOCatID;
            let IOJSectionHierarchyID = this.props.IOJSectionHierarchyID;
            let data = this.state;
            let obj = {
              ID: data.editData.ID,
              SOCategoryID: SOCatID,
              IOJSectionHierarchyID: IOJSectionHierarchyID,
              DocURL: data.editData.DocURL.trim(),
              DocName: data.editData.DocName.trim(),
              DocDescription: data.editData.DocDescription.trim(),
            };
            data.addedReference = this.state.editData;
            // let RefList = data.addedReference;
            // let filterClient = this.props.filterDetails.Client;
            // let filterProcess = this.props.filterDetails.process;
            // let filterLocations = this.props.filterDetails.Locations;
            let filterClient =
              this.props.filterDetails == undefined
                ? this.props.offeringIdParam.Client
                : this.props.filterDetails.Client;
            let filterProcess =
              this.props.filterDetails == undefined
                ? this.props.offeringIdParam.process
                : this.props.filterDetails.process;
            let filterLocations =
              this.props.filterDetails == undefined
                ? this.props.offeringIdParam.Locations
                : this.props.filterDetails.Locations;
            let load = {
              OrgEntityId: filterClient,
              OfferingId: filterProcess,
              LocationId: filterLocations,
              RefList: [obj],
            };
            axiosInstance
                .post(`${LocalApiBaseUrl}ReferenceDocument/AddEditReference`, load)
                .then((response) => {
                    this.setState({
                        isAPILoading: false,
                    });
                    window.alert(response.data.StatusMessage);
                    this.props.fetchInitialDetails()
                    this.props.close();
                })
                .catch((error) => {
                    this.setState({
                        isAPILoading: false,
                    });
                    trycatchAlertPopup(error);
                    this.props.close();
              });
          }
};

    handleRemove = (data,index) => {
        let s = this.state;
        let addedReference = [];

        s.addedReference.map((d,ind) => {
            if (!(ind == index)) {
                addedReference.push(d);
            }
        });
        //Checking if all the references data is filled
        let disabledAdd = false;
        addedReference.map(each=>{
            if(!(each.DocDescription.trim() && each.DocName.trim() && each.DocURL.trim()))
            {
                disabledAdd=true;
            }
        })
        this.setState({
            addedReference,
            disabledAdd
        });
    };
    handleAdd = () => {
        // let SOCategoryID = this.props.SOCatID;
        let SOCategoryID = this.props.SOCatID == 0 ? null : this.props.SOCatID;	
        let IOJSectionHierarchyID = this.props.IOJSectionHierarchyID;
        let data = this.state;
        let obj = {
            ID: 0,
            SOCategoryID: SOCategoryID,
            IOJSectionHierarchyID: IOJSectionHierarchyID,
            DocURL: "",
            DocDescription: "",
            DocName:"",
            added: true,
            errors: {},
            formIsValid: false,
        };
        data.addedReference.unshift(obj);
        data.addedReference = data.addedReference.map(obj=>{
            let noErrorObj=obj;
            noErrorObj.errors = {};
            return noErrorObj
        })
        this.setState({
            addedReference: data.addedReference,
            errors:{},
            disabledAdd:true,
        });
    };

    addReferenceModalClose() {
        this.props.close();
    }
    async componentDidMount() {
        const { clientData } = this.props;
        const reqData = {
            OrgEntityID: clientData.id
        };
        this.setState({
            isAPILoading: true,
        });
        const allowedDomains = await fetchAllowedDomains(reqData);

        this.setState({
            allowedDomainsArray: allowedDomains,
            isAPILoading: false,
        });

        if (this.props != undefined) {
            if (this.props.status == "Edit") {
                const { desc, url, data } = this.props;
                data.errors = {DocURL:"",DocDescription:"",DocName:""};
                this.setState({
                    editData: data,
                    url: data.DocURL,
                    desc: data.DocDescription,
                    DocName:data.DocName,
                    buttonName: "Update",
                    addButtonDisable: false,
                });
            }
        }
    }

    handleUrlChange = (event) => {
        let editData = this.state.editData;
        editData.DocURL = event.target.value;
        editData.errors.DocURL = "";

        this.setState({editData:editData});
    };
    handleNameChange = (event) => {
        let editData = this.state.editData;
        editData.DocName = event.target.value;
        editData.errors.DocName = "";

        this.setState({editData:editData});
    };
    handleDescChange = (event) => {
        let editData = this.state.editData;
        editData.DocDescription = event.target.value;
        editData.errors.DocDescription = "";

        this.setState({editData:editData});
    };
    render() {
        const { leverName, show, status } = this.props;
        const {
            buttonName,
            desc,
            url,
            errors,
            addedReference,
            addButtonDisable,
            addStatus,
            disabledAdd,
            isAPILoading,
            editData,
        } = this.state;

        return (
            <>
                <LoadingOverlay
                    className="custom-loader"
                    fadeSpeed={0}
                    spinner={<SynopsLoader />}
                    active={isAPILoading}
                    // text="Loading SynOps …"
                ></LoadingOverlay>
                {/* Add Reference Details Modal */}
                <Modal show={show} onHide={this.addReferenceModalClose} className="refernce-modal" backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {status} {i18n.t("References")} - {leverName}
                            
                        </Modal.Title>
                        { status == "Add"  ? (!disabledAdd ?
                                                <a className="add-button-pos" onClick={this.handleAdd}>
                                                    <i class="fas fa-plus"></i> <span className="p-l-10">{i18n.t("Add_more")}</span>
                                                </a> :
                                                <a className="add-button-pos">
                                                    <i class="fas fa-plus disabled"></i>  <span className="p-l-10 disabled">{i18n.t("Add_more")}</span>
                                                </a>    
                                        ) : null}
                    </Modal.Header>
                    <Modal.Body>
                        <div className="reference-content">
                            <div className="reference-form-details">
                                {addedReference.length > 0 && status == "Add" 
                                    ? addedReference.map((data,index) => (
                                        <div className="ref-detail-section">
                                            <div className="ref-detail-heading">
                                                <h6 className="mb-0">{i18n.t("Reference_Details")}</h6>
                                               {addedReference.length > 1 ? <div>
                                                    <a className="delete-ref" onClick={() => this.handleRemove(data,index)}>
                                                        <i className="far fa-trash-alt"></i><span className="p-l-10">{i18n.t("Remove")}</span>
                                                    </a>
                                                </div> : ""}
                                                
                                            </div>
                                            <div className="ref-detail-fields">
                                                <div className="row">
                                                    <div className="form-group col-lg-12">
                                                        <label for="url">{i18n.t("URL_text")}<span className="required">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="url"
                                                            value={data.DocURL}
                                                            onChange={(e) => this.handleManyUrlChange(e, index)}
                                                            maxLength="1000"
                                                        />
                                                        <span className="errorMsg">{data.errors.url}</span>
                                                    </div>
                                                    <div className="form-group col-lg-12">
                                                    <label for="name">{i18n.t("Name")}<span className="required">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name"
                                                            value={data.DocName}
                                                            onChange={(e) => this.handleManynameChange(e, index)}
                                                            maxLength="50"
                                                        />
                                                        <span className="errorMsg">{data.errors.DocName}</span>
                                                    </div>
                                                    <div className="form-group col-lg-12">
                                                        <label for="description">{i18n.t("Description")}<span className="required">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="description"
                                                            value={data.DocDescription}
                                                            onChange={(e) => this.handleManyDescChange(e, index)}
                                                            maxLength="70"
                                                        />
                                                        <span className="errorMsg">{data.errors.desc}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    :  <div className="ref-detail-fields">
                                    <div className="row">
                                        <div className="form-group col-lg-12">
                                            <label for="url">{i18n.t("URL_text")}<span className="required">*</span></label>

                                            <input
                                                type="text"
                                                className="form-control"
                                                id="url"
                                                value={editData.DocURL}
                                                onChange={this.handleUrlChange}
                                                maxLength="1000"
                                            />
                                            <span className="errorMsg">{editData.errors ? editData.errors.DocURL: ""}</span>
                                        </div>
                                        <div className="form-group col-lg-12">
                                        <label for="name">{i18n.t("Name")}<span className="required">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name"
                                                            value={editData.DocName}
                                                            onChange={(e) => this.handleNameChange(e)}
                                                            maxLength="50"
                                                        />
                                                        <span className="errorMsg">{editData.errors ? editData.errors.DocName: ""}</span>
                                                    </div>
                                        <div className="form-group col-lg-12">
                                            <label for="description"> {i18n.t("Description")}<span className="required">*</span></label>

                                            <input
                                                type="text"
                                                className="form-control"
                                                id="desc"
                                                value={editData.DocDescription}
                                                onChange={this.handleDescChange}
                                                maxLength="70" //70 characters limit
                                            />
                                             <span className="errorMsg">{editData.errors ? editData.errors.DocDescription: ""}</span>
                                        </div>
                                    </div>
                                </div> }
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="modal-btn flex-btns addrefence-btns">
                            <button onClick={this.addReferenceModalClose} className="btn btn-default btn-sm mr-3">
                            {i18n.t("Cancel")}{" "}
                            </button>
                            {buttonName == "Add" ? (
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => this.handleSubmit()}
                                    disabled={disabledAdd}
                                >
                                  {i18n.t("Add")}  {addedReference.length > 0 ? "("+addedReference.length+")": "" }
                                </button>
                            ) : (
                                    <button className="btn btn-primary btn-sm" onClick={() => this.handleUpdate()}>
                                       {i18n.t("Update")}  
                                    </button>
                                )}
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    clientData: state.CurrentFilter.ClientData,
});
  
export default connect(mapStateToProps)(AddEditReferenceModal);