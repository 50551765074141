import React, { Component } from "react";
import SingleShareAssessment from "./SingleShareAssessment";
import { LocalApiBaseUrl } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import _ from "lodash";

class AccessManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managementData: [],
      filteredManagementData: [],
      shareAssessment: false,
      assessmentDetails: {},
      SharedUsers: [],
    };
  }
  
  componentDidMount() {
    this.getBATAssessmentAccessManagementData();
  }

  componentDidUpdate(prevProps) {
    const { searchFilterTextValue } = this.props;

    if (prevProps.searchFilterTextValue !== searchFilterTextValue) {
      this.filterListingData();
    }
  }

  shareAssessmentModalClose = () => {
    this.setState({ shareAssessment: false });
  };

  getBATAssessmentAccessManagementData = () => {
    axiosInstance
      .get(`${LocalApiBaseUrl}BATAssessment/GetBATAssessmentAccessManagement`)
      .then((response) => {
        if (response.data) {
          this.setState({ managementData: response.data, filteredManagementData: response.data });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        this.setState({ managementData: [] });
      });
  };

  handleCallbackShareModalSuccClose = () => {
    this.setState({ shareAssessment: false }, () => this.getBATAssessmentAccessManagementData());
  };

  editUserPermission = (assesment) => {
    this.setState({
      assessmentDetails: assesment,
      SharedUsers: assesment.SharedUsers,
      shareAssessment: true,
    });
  };

  filterListingData = () => {
    const { managementData } = this.state;
    const { searchFilterTextValue } = this.props;
    let filteredData = searchFilterTextValue
      ? managementData.filter((each) => {
          if (each.BATSubOfferingName.toUpperCase().includes(searchFilterTextValue.toUpperCase())) {
            return each;
          }
        })
      : managementData;
    this.setState({ filteredManagementData: filteredData });
  };
  
  render() {
    const { filteredManagementData, shareAssessment, assessmentDetails, SharedUsers } = this.state;

    return (
      <div className="notificationrequest-table">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Assessments Name</th>
              <th scope="col">User</th>
              <th scope="col">Access Permission</th>
            </tr>
          </thead>
          <tbody>
            {filteredManagementData.map((each) => {
              return (
                <tr>
                  <td>{each.BATSubOfferingName}</td>
                  <td>
                    <div className="assessment-usericons">
                      {each.SharedUsers.map((user, userIndex) => {
                        if (userIndex <= 2) {
                          return (
                            <span className=" bg-user userlist-tooltip assessment-tags">
                              <span className="userid-span">
                                {user.UserName.split(".").length >= 2
                                  ? user.UserName.split(".")[0]
                                      .charAt(0)
                                      .toUpperCase() +
                                    user.UserName.split(".")
                                      .pop()
                                      .charAt(0)
                                      .toUpperCase()
                                  : ""}
                              </span>
                              <span className="userlist-tooltiptext">
                                <ul>
                                  <li>
                                    <span className="list-user-name">{user.UserName}</span>
                                    <span className="list-user-permission">{user.AccessLevelName}</span>
                                  </li>
                                </ul>
                              </span>
                            </span>
                          );
                        } else return "";
                      })}
                      {each.SharedUsers.length > 3 ? (
                        <span className="assessment-excess excess-users userlist-tooltip">
                          +{each.SharedUsers.length - 3}
                          <span className="userlist-tooltiptext">
                            <ul>
                              {_.map(each.SharedUsers, (eachSharedUserDetails) => {
                                return (
                                  <li>
                                    <span className="list-user-name">{eachSharedUserDetails.UserName}</span>
                                    <span className="list-user-permission">
                                      {eachSharedUserDetails.AccessLevelName}
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </span>
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>

                  <td>
                    <span className="assessment-editbtn" onClick={this.editUserPermission.bind(this, each)}>
                      Edit
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {shareAssessment && (
          <SingleShareAssessment
            onShareModalCancelClose={this.shareAssessmentModalClose}
            ownerUserName={sessionStorage.getItem("EnterpriseID")}
            assessmentDetails={assessmentDetails}
            clientDetails={SharedUsers}
            mode="share"
            actionToBePerformed="approve"
            onShareModalSuccClose={() => this.handleCallbackShareModalSuccClose()}
          />
        )}
      </div>
    );
  }
}
export default AccessManagement;
