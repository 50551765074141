// This file having common functions for filters (Filter.js, PulseFilter and configuratorFilter.js common functions)

import { HasValue } from '../Shared/commonFunctions';
import { store } from "../reducers/configureStore";



/**
 * Method to set the default snap shot filter on Front-door and Configurator page
 * param {ssMData}  Snapshot object
 * param {parent} conext/scope(this) of the calling file 
 */
export let setDefaultSnapshot = (ssMData,history,parent) => {
    let ssData = ssMData.filter(function (f) { return f.IsDefault == 1; });
    let hasDefaultSnapShot = ssData != null && ssData.length == 1 && ssData[0].SnapshotID > 0;
    let syncFilters = store.getState().CurrentFilter.SyncFilter;
    if (!syncFilters) {
        let syncFilterCopy = { OfferingId: 0, ProcessId: 0, LocationId: 0, Month: null };
        let syncFilter = syncFilterCopy;
        updateSyncFilter(syncFilter);
    }
    // If Publish Data not available for the location then reset selection on front-door
    if (syncFilters.IsDataPublishForLocation === false && history.location.pathname !== "/admin/Configurator") {
        let syncFilters = store.getState().CurrentFilter.SyncFilter;
        syncFilters.LocationId = "0";
        syncFilters.Month = "";
        syncFilters.IsDataPublishForLocation = true;
        updateSyncFilter(syncFilters);
    }
    if (hasDefaultSnapShot) {
        let onlyOfferingExist = syncFilters.OfferingId == ssData[0].OfferingID && !HasValue(syncFilters.ProcessId) && !HasValue(syncFilters.LocationId) && !HasValue(syncFilters.Month);
        let offeringSubOffExist = syncFilters.OfferingId == ssData[0].OfferingID && syncFilters.ProcessId == ssData[0].ProcessID && !HasValue(syncFilters.LocationId) && !HasValue(syncFilters.Month);
        let locationFilter = syncFilters.OfferingId == ssData[0].OfferingID && syncFilters.ProcessId == ssData[0].ProcessID && syncFilters.LocationId == ssData[0].LocationID;
        if (onlyOfferingExist || offeringSubOffExist || locationFilter) {
            ssData[0].Month = HasValue(syncFilters.Month) ? syncFilters.Month : undefined;
            setSnapshot(ssData[0], parent);
        }
        else {
            handleNonDefaultSS(ssMData,parent);
        }
    }
    else {
        handleNonDefaultSS(ssMData,parent);
    }
} 
 
export let handleNonDefaultSS = async (ssMData, parent) => { 
    let syncFilters = store.getState().CurrentFilter.SyncFilter;
    let nonDefaultSS = ssMData.filter(function (f) { return f.OfferingID == syncFilters.OfferingId && f.ProcessID == syncFilters.ProcessId && f.LocationID == syncFilters.LocationId; });
    let filters = { OfferingID: syncFilters.OfferingId, ProcessID: syncFilters.ProcessId, Offering: "", LocationID: syncFilters.LocationId, Month: HasValue(syncFilters.Month) ? syncFilters.Month : undefined };
    if(nonDefaultSS != null && nonDefaultSS.length == 1 && nonDefaultSS[0].SnapshotID > 0){
        nonDefaultSS[0].Month = filters.Month;
        setSnapshot(nonDefaultSS[0], parent); 
    }else{
    setCurrentFilter(filters, parent);
    }
}


/**
 * Method to set the snap shot filter on Front-door and Configurator page
 * param {ssMData}  Snapshot object
 * param {parent} conext/scope(this) of the calling file
 * param {isSetCurrentFilter} : if setting filter based on current selection not on default snap shot
 */
export let setSnapshot = async (ssData, parent, isSetCurrentFilter) => {
    if (ssData != null) {

        if (HasValue(ssData.OfferingID)) {

            parent.setState({
                showLoaderOnPage: true
            });

            await parent.bindProcess({ target: { value: ssData.OfferingID, options: [ssData.Offering], selectedIndex: 0 } }, false);

            if (document.getElementById("offerings") != null)
                document.getElementById("offerings").value = ssData.OfferingID;
            parent.refs.offerings = document.getElementById("offerings");
            parent.refs.offerings.value = ssData.OfferingID;
            parent.refs.offering = document.getElementById("offerings");
            parent.refs.offering.value = ssData.OfferingID;

            if (HasValue(ssData.ProcessID)) {

                await parent.selectedFilter("process", { target: { value: ssData.ProcessID, options: [{ value: ssData.ProcessID }], selectedIndex: 0 } }, false);

                if (document.getElementById("process") != null)
                    document.getElementById("process").value = ssData.ProcessID;
                parent.refs.process = document.getElementById("process");
                parent.refs.process.value = ssData.ProcessID;
                if (HasValue(ssData.LocationID) && document.getElementById("Locations") != null) {
                    await parent.selectedFilter("Locations", { target: { value: ssData.LocationID, id: "Locations", options: [{ value: ssData.LocationID }], selectedIndex: 0 } }, false);

                    if (document.getElementById("Locations") != null)
                        document.getElementById("Locations").value = ssData.LocationID;
                    parent.refs.Locations = document.getElementById("Locations");
                    parent.refs.Locations.value = ssData.LocationID;

                    setTimeout(function () {
                        if (!isSetCurrentFilter) {
                            setMonth(parent);
                            parent.setState({
                                snapShotName: ssData.SnapShotName,
                                isDefaultSnapshotSet: ssData.IsDefault
                            });
                            parent.selectedSnapshotID = ssData.SnapshotID;
                        }
                        if (ssData.Month) {
                            setMonth(parent, ssData.Month);
                        }
                        setTimeout(function () {
                            if (document.getElementById("Locations") != null)
                                document.getElementById("Locations").value = ssData.LocationID;
                        }, 500);
                    }, 1500);
                }
            }
            parent.setState({
                showLoaderOnPage: false
            });
        }
    }
}

/**
 * Method to set the current selected filter on Front-door and Configurator page
 * param {filters}  Snapshot object filters (Offering, Sub-offering, Location, Month)
 * param {parent} conext/scope(this) of the calling file
 */ 
export let setCurrentFilter = async (filters, parent) => {
    setSnapshot(filters, parent, true);
}

/**
 * Method to set the month in filters (default set the last month) if having parameter then set based on passed parameter(Format: "MMM-YYYY")
 * param {parent} conext/scope(this) of the calling file
 * param {selectedMonth}  If there is value for parameter "selectedMonth" then it will set based on that value otherwise set last month
 */
export let setMonth = async (parent, selectedMonth) => {
    let monthVal = "0";
    if (document.getElementById("Month") != null && document.getElementById("Month").length > 1) {
        document.getElementById("Month").selectedIndex = "1";
        monthVal = document.getElementById("Month")[1].value;
    }
    await parent.selectedFilter("Month", { target: { value: selectedMonth ? selectedMonth : monthVal, id: "Month", options: [{ value: monthVal }], selectedIndex: 0 } }, false);

      
    setTimeout(function () {
        let availMonths = parent.state.monthValues;
        let isMonthAvail = availMonths.filter((x) => x.key == selectedMonth);
        if (isMonthAvail) {
            selectedMonth = isMonthAvail.length > 0 ? selectedMonth : (parent.state.monthValues[0] ? parent.state.monthValues[0].key : selectedMonth);
        }
        parent.selectedFilter("Month", { target: { value: selectedMonth ? selectedMonth : monthVal, id: "Month", options: [{ value: monthVal }], selectedIndex: 0 } }, false);
         
        if (document.getElementById("Month") != null && document.getElementById("Month").length > 1) {
            if (selectedMonth) {
                var options = document.getElementById('Month').options;
                for (var i = 0; i < options.length; i++) {
                    if (options[i].value === selectedMonth) {
                        options[i].selected = true;
                        break;
                    }
                }
            }
            else {
                document.getElementById("Month").selectedIndex = "1";
            }
            monthVal = document.getElementById("Month")[1].value;
        }
        if (document.getElementById("btnCloseChangeSnapshot") != null)
            document.getElementById("btnCloseChangeSnapshot").click();

        parent.setState({
            showLoaderOnPage: false
        });
    }, 1500);
}

/**
 * Method to update the FILTER values in REDUX
 * param {filters}  Snapshot object filters (Offering, Sub-offering, Location, Month)
 */
export let updateSyncFilter = (filter) => {
    store.dispatch({
        type: 'SYNC_CURRENTFILTER',
        payload: filter
    })
}
