import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { LocalApiBaseUrl } from '../Shared/Constant';


import axiosInstance from '../Shared/interceptor';
import { store } from "../reducers/configureStore"
import Header from '../Shared/Header';
import { regexForConfMasterFields } from '../Shared/Constant';
import {regexForTextAreas} from '../Shared/Constant';


axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;




export default class IndustryPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false, apivalue: "", selectValue: this.props.SelectValue, Display: false,
            Industry: {
                IndustryId: "0",
                Industry: "",
                RequestorRemarks:"",
                ApproverRemarks:"",
                OperatingGroup:"",
                ApprovalAction:true,
                ApproveType:'',
                IsActive: false,
                CreatedUser: null,
                CreatedTS: null,
                UpdatedUser: null,
                UpdatedTS: null,
                DealInfoes: null
            },
            errors: [],
            formIsValid: false,


        }

        this.onIndustryChange = this.onIndustryChange.bind(this);
        this.onApproverRemarkChange = this.onApproverRemarkChange.bind(this);
        this.onRequestorRemarksChange=this.onRequestorRemarksChange.bind(this);
        this.onOperatingGroupChange = this.onOperatingGroupChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);

    }

    onIndustryChange(event) {
        
        let errors = {};
        this.setState({
            errors: errors
        });
        var Industry = this.state.Industry;
        Industry.Industry = event.target.value;
        this.setState({
            Industry: Industry
        })

    }
   


    onApproverRemarkChange(event) {
        
        if(this.props.type=='Approve')
        {
        let soCatAtt = null;
        let errors = {};
        this.setState({
            errors: errors
        });
        var Industry = this.state.Industry;
        Industry.ApproverRemarks = event.target.value;
        Industry.ApprovalAction=true;
       // Client.ClientIDs=this.state.Client;
        this.setState({
            Industry: Industry
        });
      }
      else
      {
        let soCatAtt = null;
        let errors = {};
        this.setState({
            errors: errors
        });
        var Industry = this.state.Industry;
        Industry.ApproverRemarks = event.target.value;
        Industry.ApprovalAction=false;
       // Client.ClientIDs=this.state.Client;
        this.setState({
            Industry: Industry
        });
  
      }
    }


    onRequestorRemarksChange(event) {
        
        let errors = {};
        this.setState({
            errors: errors
        });
        var Industry = this.state.Industry;
        Industry.RequestorRemarks = event.target.value;
        this.setState({
            Industry: Industry
        })
       
    }

    
    onOperatingGroupChange(event) {
        
        let errors = {};
        this.setState({
            errors: errors
        });
        var Industry = this.state.Industry;
        Industry.OperatingGroup = event.target.value;
        this.setState({
            Industry: Industry
        })
      
    }

    

    onStatusChange(event) {
        
        const Industry = this.state.Industry;
        Industry.IsActive = event.target.checked;
        this.setState({
            Industry: Industry
        })
    }
    handleSave(e) {
        
        if (this.validateForm()) {
           
            this.props.onSave(this.state.Industry)

            this.handleHide();
        }
    }
    handleHide(e) {
        
        this.setState({ show: false });
        if (e != undefined) {

            if ((e.target.textContent != "×")) {
                this.props.onClose(e);
            }
            else {
                this.props.onClose(e);
                // this.props.onClose("from x");
            }
        } else {
            this.props.onClose(e);
        }
        this.props.onSave()

    }
    handleRejectHide(e) {
        

        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onRejectClose();
            }
        } else {
            this.props.onRejectClose();
        }
       

    }
    handleApproveHide(e) {

        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onApproveClose();
            }
        } else {
            this.props.onApproveClose();
        }

    }
    passToConfirmation(data) {
        

        this.props.enteredData(data.PoNumber)
    }


    componentDidMount() {
        
        // this.validateForm();
        this.setState({
            show: this.props.show
        })
    }

    validateForm() {
        const Industry = this.state.Industry;
        let errors = {};
        let formIsValid = true;
        var data = this.props.rowData;
    
        if(Industry["ApproverRemarks"]==null)
        {
            formIsValid = false;
            errors["ApproverRemarks"] = "Approver Remarks are required";
    
        }
        else if (!Industry["ApproverRemarks"].trim()) {
            formIsValid = false;
            errors["ApproverRemarks"] = "Approver Remarks are required";

        }
        else {
         
            var isValid = regexForTextAreas.test(Industry["ApproverRemarks"]);
            if (!isValid) {
                formIsValid = false;
                errors["ApproverRemarks"] = "Approver Remarks contains Special characters";
            }
        }

        

        this.setState({
            errors: errors
        });
        if (formIsValid) {
            this.setState({
                formIsValid: true
            })
        }
        else {
            this.setState({
                formIsValid: false
            })
        }
        return formIsValid;

    }

    componentWillMount() {
        if (this.props.data) {
            this.setState({
                Industry: this.props.data[0],
            })
        }
    }

    render() {
        
        return (
            <>
                {/* <Header /> */}
                <Modal show={this.state.show} onHide={this.handleHide.bind(this)} classIndustry="modal fade" size="medium" id="dealpopup">
                    <Modal.Header closeButton>
                    <h4 className="modal-title" id="dealprofile" flag >Approval</h4>
                    </Modal.Header>
                    <Modal.Body>
                            <form>
                                <div className="form-section">

                                    <div className="row">
                                    
                                        <div className="form-group col-lg-6">
                                            <label for="">IndustryName<span className="required">*</span></label>
                                            <input type="text" className="form-control" id="name" readOnly onChange={this.onIndustryChange} value={this.state.Industry.Industry} />
                                            <span className="errorMsg">{this.state.errors.Industry}</span>
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label for="">Requestor Remarks</label>
                                            <textarea maxLength="200" readOnly className="form-control" id="remark" onChange={this.onRequestorRemarksChange} value={this.state.Industry.RequestorRemarks} />
                                            <span className="errorMsg">{this.state.errors.RequestorRemarks}</span>
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label for="">Approver Remarks<span className="required">*</span></label>
                                            <textarea  maxLength="200" className="form-control" id="remark" onChange={this.onApproverRemarkChange} value={this.state.Industry.ApproverRemarks} />
                                            <span className="errorMsg">{this.state.errors.ApproverRemarks}</span>
                                        </div>
                                       
                                    </div>

                                </div>


                            </form>
                    </Modal.Body>
                    {<Modal.Footer>
                        <div className="modal-btn">
                                {!this.props.data ?
                                    <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value="Create" />
                                    : <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value="Update" />
                                }
                        </div>
                    </Modal.Footer>}
                </Modal>
            </>
        )
    }
}