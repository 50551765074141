import React, { Component } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "react-bootstrap/ProgressBar";
import EllipsesGrey from "../Images/ellipses-grey.svg";
import CheckCircle from "../Images/bat_icons/check-circle.svg";
import { Modal, Button } from "react-bootstrap";
import i18n from "i18next";
import { Multiselect } from "multiselect-react-dropdown";
import SingleShareAssessment from "./SingleShareAssessment";
import BulkShareAssessment from "./BulkShareAssessment";
import { LocalApiBaseUrl } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";

class ViewAssessmentDetails extends Component {
  constructor(props) {
    super(props);
    this.shareAssessmentModalShow = this.shareAssessmentModalShow.bind(this);
    this.shareAssessmentModalClose = this.shareAssessmentModalClose.bind(this);
    this.shareSingleAssessmentModalShow = this.shareSingleAssessmentModalShow.bind(this);
    this.shareSingleAssessmentModalClose = this.shareSingleAssessmentModalClose.bind(this);
    this.sharedNoticeModalShow = this.sharedNoticeModalShow.bind(this);
    this.sharedNoticeModalClose = this.sharedNoticeModalClose.bind(this);
    this.bulkSharedNoticeModalShow = this.bulkSharedNoticeModalShow.bind(this);
    this.bulkSharedNoticeModalClose = this.bulkSharedNoticeModalClose.bind(this);
    this.infoModalShow = this.infoModalShow.bind(this);
    this.infoModalClose = this.infoModalClose.bind(this);
    this.requestSentModalShow = this.requestSentModalShow(this);
    this.requestSentModalClose = this.requestSentModalClose(this);
    //  this.openClientDropdown = this.openClientDropdown(this);
    this.state = {
      assessmentDetails: {},
      clientDetails: {},
      shareAssessmentStatus: false,
      shareSingleAssessmentStatus: false,
      CreatedUserName: "",
      sharedNotice: false,
      bulkSharedNotice: false,
      infoModal: false,
      requestSentModal: false,
      searchTerm: "",
      isClientDropdownSearchable: false,
      selectedUser: "",
      selectedPermission: "",
      isPermissionDropdownStatus: false,
      allChecked: false,
      isClientDropdown: false,
      userData: [],
      userIdData: [],
      list: [],
      options: [{ name: "Srigar", id: 1 }, { name: "Sam", id: 2 }],
      selectedUserList: [],
    };
  }
  onSelect(selectedList, selectedItem) {

  }

  onRemove(selectedList, removedItem) {

  }
  isPermissionDropdown = () => {
    this.setState({ isPermissionDropdownStatus: true });
  };
  isPermissionDropdownOverlay = () => {
    this.setState({ isPermissionDropdownStatus: false });
  };
  onClickFetchPermission = (selectedData) => (evt) => {
    this.setState({ selectedPermission: selectedData });
    setTimeout(() => {
      this.isPermissionDropdownOverlay();
    }, 100);
  };

  shareAssessmentModalShow(clientInfo) {
    let list = [];
    let subofferingDetails = [];
    _.forEach(this.props.ClientInfo.OfferingDetails, (e) => {
      subofferingDetails.push(e.SubOfferingDetails);
    });
    subofferingDetails.map((sub) => {
      sub.map((data) => {
        if (data.CollectionStatus !== 0) {
          const obj = { id: data.BATAssessSubOfferingID, name: data.BATSubOfferingName };
          list.push(obj);
        }
      });
    });
    this.setState({ shareAssessmentStatus: true });
    this.setState({ list: list, clientDetails: clientInfo });
  }
  shareAssessmentModalClose() {
    this.setState({ shareAssessmentStatus: false });
  }
  shareSingleAssessmentModalShow(SubOfferingInfo, ClientInfo) {
    this.setState({ shareSingleAssessmentStatus: true, assessmentDetails: SubOfferingInfo, clientDetails: ClientInfo });
  }
  shareSingleAssessmentModalClose() {
    this.setState({ shareSingleAssessmentStatus: false });
  }
  handleCallbackShareModalSuccClose = () => {
    const { onShareModalSuccClose } = this.props;
    this.setState({ shareSingleAssessmentStatus: false }, () => onShareModalSuccClose());
  };

  BulkShareAssessmentModalClose = () => {
    this.setState({ shareAssessmentStatus: false });
  };

  handleCallbackBulkShareModalSuccClose = () => {
    const { onBulkShareModalSuccClose } = this.props;
    this.setState({ shareAssessmentStatus: false }, () => onBulkShareModalSuccClose());
  };
  sharedNoticeModalShow() {
    this.shareSingleAssessmentModalClose();
    this.setState({ sharedNotice: true });
  }
  sharedNoticeModalClose() {
    this.setState({ sharedNotice: false });
  }
  bulkSharedNoticeModalShow() {
    let createdUserName = this.props.ClientInfo.CreatedBy;
    let List = this.state.list;
    let SelectedUserList = this.state.selectedUserList;
    let values = [];
    let selectedPermission = this.state.selectedPermission;
    let userList = SelectedUserList.map((userListData) => {
      let listData = List.map((subOffering) => {
        const obj = {
          BATAssessSubOfferingID: subOffering.id,
          ShareUserName: userListData,
          AccessLevelID: selectedPermission,
          OwnerUserName: createdUserName,
        };
        values.push(obj);
      });
    });
    let requestParameters = {
      Status: "RequestAccess",
      RequestedUsers: values,
    };
    axiosInstance
      .post(`${LocalApiBaseUrl}BATAssessment/AddEditReShareBATAssessment`, requestParameters)
      .then((response) => {
        const responseData = response.data;
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

    this.shareSingleAssessmentModalClose();
    this.setState({ bulkSharedNotice: true });
  }
  bulkSharedNoticeModalClose() {
    this.setState({ bulkSharedNotice: false });
  }
  infoModalShow(val) {
    this.setState({ infoModal: true, CreatedUserName: val });
  }
  infoModalClose() {
    this.setState({ infoModal: false });
  }
  requestSentModalShow() {
    this.setState({ requestSentModal: true });
  }
  requestSentModalClose() {
    this.setState({ requestSentModal: false });
  }
  filterSearchableDropdown = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  onOpenSearchableDropdown = (e) => {
    var element = e.target || e.srcElement;
    switch (element.id) {
      case "clientDropdown":
        this.setState({
          isClientDropdownSearchable: !this.state.isClientDropdownSearchable,
          isPermissionDropdownSearchable: false,
        });
        break;
      case "permissionDropdown":
        this.setState({
          isPermissionDropdownSearchable: !this.state.isPermissionDropdownSearchable,
          isClientDropdownSearchable: false,
        });
        break;
    }
  };
  openClientDropdown = (e) => {
    let target = !this.state.isClientDropdown;
    this.setState({
      isClientDropdown: target,
    });
  };
  onClientChange(ClientObj) {
    
    this.setState({
      selectedUser: ClientObj.target.textContent,
      isClientDropdownSearchable: false,
    });
  }
  onMultipleClientChange(ClientObj) {

    if (this.state.selectedUserList.lenth <= 10) {
      if (!this.state.selectedUserList.includes(ClientObj.target.textContent)) {
        this.setState({
          selectedUserList: [...this.state.selectedUserList, ClientObj.target.textContent],
          isClientDropdownSearchable: false,
          isClientDropdown: false,
        });
      }
    } else {

    }
  }
  onDeleteClient(userlist, user) {
    let newlist = userlist.filter((item) => item !== user);
    this.setState({
      selectedUserList: newlist,
    });
  }
  onPermissionChange = (ClientObj) => {

    this.setState({
      selectedPermission: ClientObj.target.textContent,
      isPermissionDropdownSearchable: false,
    });
  };
  handleClickSubOffering = (clientDetails, offeringDetails, subOfferingDetails) => {
    const { onClickSubOffering, ClientInfo } = this.props;
    onClickSubOffering(clientDetails, offeringDetails, subOfferingDetails, ClientInfo.CreatedBy);
  };

  render() {
    const {
      shareSingleAssessmentStatus,
      assessmentDetails,
      shareAssessmentStatus,
      clientDetails,
      list,
      infoModal,
      CreatedUserName,
      requestSentModal,
    } = this.state;
    let AssessmentsCount = 0;
    const {
      ClientInfo,
      index,
      onEdit,
      onDelete,
      onAssessmentAdd,
      defaultclientName,
      tabName,
      sharingMode,
    } = this.props;
    let batclientname = _.toLower(ClientInfo.BATClientName);
    let defaultname = _.toLower(defaultclientName);
    return (
      <>
        <div className="card">
          <div className="card-header">
            <div
              className={(batclientname === defaultname ? "" : "collapsed ") + "card-link"}
              data-toggle="collapse"
              href={`#collapse${index}`}
            >
              <div className="header-content">
                {/* <span className="client-icon"></span> */}
                <span className={ClientInfo.ClientLogo ? "client-icon-img" : "client-icon"}>
                  {ClientInfo.ClientLogo ? (
                    <img src={"data:image/png;base64," + ClientInfo.ClientLogo} alt="Logo" />
                  ) : // height="32px" width="32px" right-margin="20px"
                  null}
                </span>
                <div className="header-link">
                  <h6>{ClientInfo.BATClientName}</h6>
                  <p>
                    {ClientInfo.BATIndustryName} |{" "}
                    {_.map(ClientInfo.OfferingDetails, (OfferingInfo, index) => {
                      if (index === ClientInfo.OfferingDetails.length - 1) {
                        return OfferingInfo.BATOfferingName;
                      } else return OfferingInfo.BATOfferingName + ", ";
                    })}
                  </p>
                </div>
              </div>
            </div>

            {/* Assessment info display and Edit/Delete client level operations start*/}
            <div className="header-right-info">
              {_.map(ClientInfo.OfferingDetails, (OfferingInfo, index) => {
                AssessmentsCount = AssessmentsCount + OfferingInfo.SubOfferingDetails.length;
              })}
              <span>
                <var>{AssessmentsCount}</var> Assessments
              </span>

              {/* <span onClick={(e) => onEdit(ClientInfo)}>
              <FontAwesomeIcon icon={faEdit} />
            </span>

            <span onClick={(e) => onDelete(e, ClientInfo, "Client")}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </span> */}
              <div className="dropdown shareassessment-dropdown">
                <a
                  className="infomore-icon"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span>
                    <img src={EllipsesGrey} />
                  </span>
                </a>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                
                  {ClientInfo.OfferingDetails.filter(
                    (offering) =>
                      offering.SubOfferingDetails.filter((suboff) => suboff.CollectionStatus !== 0).length !== 0
                  ).length !== 0 ? (
                    <a className="dropdown-item" onClick={this.shareAssessmentModalShow.bind(this, ClientInfo)}>
                      <i className="fal fa-link"></i> Share
                    </a>
                  ) : (
                    <a
                      className="dropdown-item"
                      onClick={() => alert("This assessments cannot be shared without data")}
                      info="Share is disabled"
                      disabled={true}
                    >
                      <i className="fal fa-link"></i> Share
                    </a>
                  )}
                  <a className="dropdown-item" onClick={this.infoModalShow.bind(this, ClientInfo.CreatedBy)}>
                    <i className="fal fa-info-circle"></i> Info
                  </a>
                  {tabName !== "collaboratedAssessment" ? (
                    <a className="dropdown-item" onClick={(e) => onEdit(ClientInfo)}>
                      <i className="fal fa-edit"></i> Edit
                    </a>
                  ) : (
                    ""
                  )}
                  <a className="dropdown-item" onClick={(e) => onDelete(e, ClientInfo, "Client")}>
                    <i class="fal fa-trash-alt delete-icon-fix"></i> Delete
                  </a>
                </div>
              </div>
            </div>
            {/* Assessment info display and Edit/Delete client level operations ends*/}
          </div>
          <div
            id={`collapse${index}`}
            className={"collapse " + (batclientname == defaultname ? "show" : "")}
            data-parent="#accordion"
          >
            {/* Offering and Assessments details starts */}
            <div className="card-body collab-card-body">
              {AssessmentsCount > 0
                ? _.map(ClientInfo.OfferingDetails, (OfferingInfo, index) => {
                    return (
                      <>
                        {!_.isEmpty(OfferingInfo) && !_.isEmpty(OfferingInfo.SubOfferingDetails) ? (
                          <div className="offerings-container">
                            <h5>{OfferingInfo.BATOfferingName}</h5>
                            <div className="offerings-list-group">
                              {_.map(OfferingInfo.SubOfferingDetails, (SubOfferingInfo, index) => {
                                return (
                                  <div className="wrapBox">
                                    <div
                                      disabled={
                                        SubOfferingInfo.CollectionStatus === 0 && tabName === "collaboratedAssessment"
                                      }
                                      className="list-item"
                                      onClick={() => {
                                        if (
                                          SubOfferingInfo.CollectionStatus === 0 &&
                                          tabName === "collaboratedAssessment"
                                        ) {
                                        } else {
                                          this.handleClickSubOffering(ClientInfo, OfferingInfo, SubOfferingInfo);
                                        }
                                      }}
                                    >
                                      <h4>{SubOfferingInfo.BATSubOfferingName}</h4>
                                      <p className="last-modified">Last Modified on {SubOfferingInfo.UpdatedTS}</p>
                                      <p className="data-capture">
                                        <span>Data capture</span>
                                        <span className="react-progress-bar">
                                          <ProgressBar
                                            className="greenGradient"
                                            now={SubOfferingInfo.CollectionStatus}
                                          />
                                          {/* <span className="progress-percent">{`${SubOfferingInfo.CollectionStatus}%`}</span> */}
                                        </span>
                                      </p>
                                      {/* <span
                                        className="hover-delete-icon"
                                        onClick={(e) => onDelete(e, SubOfferingInfo, "Assessment")}
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                      </span> */}
                                    </div>
                                    <div className="list-footer">
                                      <div className="footer-left">
                                        {!_.isEmpty(SubOfferingInfo.SharedUsers) ? (
                                          <>
                                            <span className="user-account-icon userlist-tooltip">
                                              <i class="fa fa-user" aria-hidden="true"></i>
                                              <span className="userlist-tooltiptext">
                                                <ul>
                                                  {_.map(SubOfferingInfo.SharedUsers, (SharedUsers, userIndex) => {
                                                    return (
                                                      <li>
                                                        <span className="list-user-name">{SharedUsers.UserName}</span>
                                                        <span className="list-user-permission">
                                                          {SharedUsers.AccessLevelName}
                                                        </span>
                                                      </li>
                                                    );
                                                  })}
                                                </ul>
                                              </span>
                                            </span>
                                            {_.map(SubOfferingInfo.SharedUsers, (SharedUsers, userIndex) => {
                                              if (userIndex < 2) {
                                                return (
                                                  <span className="bg-user userlist-tooltip">
                                                    <span className="userid-span">
                                                      {SharedUsers.UserName.split(".").length >= 2
                                                        ? SharedUsers.UserName.split(".")[0]
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                          SharedUsers.UserName.split(".")
                                                            .pop()
                                                            .charAt(0)
                                                            .toUpperCase()
                                                        : ""}
                                                    </span>
                                                    <span className="userlist-tooltiptext">
                                                      {/* <ul>
                                                        <li>
                                                          <span className="list-user-name">{SharedUsers.UserName}</span>
                                                          <span className="list-user-permission">
                                                            {SharedUsers.AccessLevelName}
                                                          </span>
                                                        </li>
                                                      </ul> */}
                                                      <ul>
                                                        {_.map(
                                                          SubOfferingInfo.SharedUsers,
                                                          (SharedUsers, userIndex) => {
                                                            return (
                                                              <li>
                                                                <span className="list-user-name">
                                                                  {SharedUsers.UserName}
                                                                </span>
                                                                <span className="list-user-permission">
                                                                  {SharedUsers.AccessLevelName}
                                                                </span>
                                                              </li>
                                                            );
                                                          }
                                                        )}
                                                      </ul>
                                                    </span>
                                                  </span>
                                                );
                                              }
                                            })}
                                            {SubOfferingInfo.SharedUsers.length > 2 ? (
                                              <span className="excess-users userlist-tooltip">
                                                +{SubOfferingInfo.SharedUsers.length - 2} More
                                                <span className="userlist-tooltiptext">
                                                  <ul>
                                                    {_.map(SubOfferingInfo.SharedUsers, (SharedUsers, userIndex) => {
                                                      return (
                                                        <li>
                                                          <span className="list-user-name">{SharedUsers.UserName}</span>
                                                          <span className="list-user-permission">
                                                            {SharedUsers.AccessLevelName}
                                                          </span>
                                                        </li>
                                                      );
                                                    })}
                                                  </ul>
                                                </span>
                                              </span>
                                            ) : (
                                              ""
                                            )}{" "}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="footer-right">
                                        <div className="dropdown shareassessment-dropdown">
                                          <a
                                            className="infomore-icon"
                                            href="#"
                                            role="button"
                                            id="dropdownMenuLink"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <span>
                                              <img src={EllipsesGrey} />
                                            </span>
                                          </a>
                                          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            {SubOfferingInfo.CollectionStatus == 0 ? (
                                              <a
                                                className="dropdown-item"
                                                info="Share is disabled"
                                                onClick={() => alert("This assessment cannot be shared without data")}
                                                disabled={true}
                                              >
                                                <i className="fal fa-link"></i> Share
                                              </a>
                                            ) : (
                                              <a
                                                className="dropdown-item"
                                                onClick={() =>
                                                  this.shareSingleAssessmentModalShow(SubOfferingInfo, ClientInfo)
                                                }
                                              >
                                                <i className="fal fa-link"></i> Share
                                              </a>
                                            )}

                                            <a
                                              className="dropdown-item"
                                              onClick={this.infoModalShow.bind(this, ClientInfo.CreatedBy)}
                                            >
                                              <i className="fal fa-info-circle"></i> Info
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              onClick={(e) => onDelete(e, SubOfferingInfo, "Assessment")}
                                            >
                                              <i className="fal fa-trash-alt delete-icon-fix"></i> Delete
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ) : null}
                      </>
                    );
                  })
                : null}
              {/* (
              <div className="no-assessment">
                <div className="addedyet">No Assessment added yet!</div>
                <div className="continue">
                  <li onClick={(e) => onAssessmentAdd(ClientInfo)}>Continue with Assessment</li>
                </div>
              </div>
            )} */}
              {ClientInfo.OfferingDetails.length < 1 || AssessmentsCount == 0 ? (
                <div className="no-assessment">
                  <div className="addedyet">No Assessment added yet!</div>
                  <div className="continue">
                    {/* <NavLink to={`/${ROUTE_CONSTANTS.BAT_OFFERING_BENCHMARK_DETAILS}`}> */}
                    <li onClick={(e) => onAssessmentAdd(ClientInfo)}>Continue with Assessment</li>
                    {/* </NavLink> */}
                  </div>
                </div>
              ) : null}
            </div>
            {/* Offering and Assessments details ends */}
          </div>
        </div>
        {/* Single Share Assessment Modal */}
        {shareSingleAssessmentStatus && (
          <SingleShareAssessment
            onShareModalCancelClose={this.shareSingleAssessmentModalClose}
            assessmentDetails={assessmentDetails}
            clientDetails={clientDetails}
            ownerUserName={clientDetails.CreatedBy}
            tabName={tabName}
            mode={tabName === "myAssessment" ? "share" : "reshare"}
            actionToBePerformed={tabName === "myAssessment" ? "approve" : "request"}
            onShareModalSuccClose={() => this.handleCallbackShareModalSuccClose()}
          />
        )}
        {/* Bulk Share Assessment Modal */}
        {shareAssessmentStatus && (
          <BulkShareAssessment
            mode={sharingMode === "share" ? "share" : "reshare"}
            actionToBePerformed={"request"}
            show={shareAssessmentStatus}
            onBulkShareModalCancelClose={() => this.BulkShareAssessmentModalClose()}
            clientDetails={clientDetails}
            ownerUserName={clientDetails.CreatedBy}
            list={list}
            sharing={this.props.sharingMode}
            actionToBePerformed="request"
            onBulkShareModalSuccClose={() => this.handleCallbackBulkShareModalSuccClose()}
          />
        )}
        {/* Info Modal  */}
        <Modal show={infoModal} className="modal fade singleSharedNotice-modal info-modal">
          <Modal.Header className="singleSharedNotice-modalHeader">
            <div className="singleSharedNotice-title">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <h4>Info</h4>
              <button
              type="button"
              class="close"
              onClick={() => {
                this.infoModalClose();
              }}
            >
            <span aria-hidden="true">×</span>
            <span class="sr-only">{i18n.t("Close")}</span>
          </button>
            </div>
            <div className="desc-line">Created by {CreatedUserName}</div>
          </Modal.Header>
        </Modal>
        {/* Request sent notification Modal  */}

        <Modal
          show={requestSentModal}
          className="modal fade singleSharedNotice-modal requestSent-modal"
        >
          <Modal.Header className="singleSharedNotice-modalHeader">
            <div className="singleSharedNotice-title">
              <i class="fa fa-check-circle" aria-hidden="true"></i>
              <h4>Request Sent</h4>
              <button
              type="button"
              class="close"
              onClick={() => {
                this.requestSentModalClose();
              }}
            >
            <span aria-hidden="true">×</span>
            <span class="sr-only">{i18n.t("Close")}</span>
          </button>
            </div>
            <div className="desc-line">
              Access Request raised successfully for share Finance & Accounting, Procure to Pay, Supply Chain (+3 ) to
              Anil Kumar for 10 users with View Only Permission
            </div>
            <div className="notePointCondition">
              Users will be able to access the assessement once the owner grant permission
            </div>
          </Modal.Header>
        </Modal>
      </>
    );
  }
}

export default ViewAssessmentDetails;
