import React from 'react';
import { LocalApiBaseUrl, getMonthNumber, getPrevMonth } from '../Shared/Constant';
import axiosInstance from '../Shared/interceptor';
import { regexForConfMasterFields } from '../Shared/Constant';
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";

axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


// export const PermissionDenied = () => {
//     return <div className="PermDenie"><b><u>Restricted access for select Accenture Operations leaders only.</u></b><br /> Access will be extended to Operations leaders in a future release. </div>
// }



export class PermissionDenied extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: [],
            errors: [],
            formIsValid: false,
        }

    }

    componentWillMount() {
        

        if (this.props.roleData.length != 0) {
            let show = this.state.show;
            show = this.props.roleData;

            this.setState({
                show: show
            });
        }
    }
    componentDidMount() {
        

        let show = this.state.show;
        show = this.props.roleData;

        this.setState({
            show: show
        });

    }
    render() {
        
        if (this.props.roleData.length == 0) {
            return (
                <LoadingOverlay
                    // classNamePrefix="_loading_overlay_overlay"
                    active={true}
                    spinner={<SynopsLoader />}
                    // text="Loading SynOps …"
                ></LoadingOverlay>
             ) }
        else {
            // export const PermissionDenied = () => {
            return <div className="PermDenie"><b><u>Restricted access for select Accenture Operations leaders only.</u></b><br /> Access will be extended to Operations leaders in a future release. </div>
            // }
        }
            
    }
}