import React from "react";
import { Modal,  Tooltip, OverlayTrigger } from "react-bootstrap";
import _ from 'lodash';
import { LocalApiBaseUrl,regexForNumbersOnlyMTC,regexForNegativeNumbers } from "../Shared/Constant";
import i18n from "i18next";
import { Form } from "react-bootstrap";
import { store } from "../reducers/configureStore";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import KpiPopUp from "../Configurator/KpiPopUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle,faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { regexForTextAreas } from "../Shared/Constant";
import { FormatDate,isEmptyObject } from "../Shared/commonFunctions";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import DatePicker from "react-datepicker";
import { MetricValueThousandFormat, thousandSeparatorFormat } from "../Shared/Common";
export default class WOKpiPopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoaderonPage:false,
            isConnector:false,
            show: this.props.show,
            MetricNameOptions: [],
            valueConnector: false,
            con: false,
            connectorName: "",
            valueConnectorEdit: false,
            apivalue: "",
            viewmore: false,
            errors: {},
            commentsError: '',
            formIsValid: false,
            KpiMetricConnectorID: false,
            BaselineMonthReadOnly: true,
            showCategory: false,
            allCategoryTypesAndNames:[],
            showApproval:false,
            numeratorVal: "",
            denomVal: "",
            CatTypeID:"0",
            CatNameID:"0",
            woData: {
                SOCategoryID: 0,
                kpiData: {
                    KPIMappingID: null,
                    MetricSeq: null,
                    MetricID: 0,
                    MetricName: "",
                    FrequencyID: 0,
                    MetricUnit: "",
                    MetricImage: "",
                    KPIDesp: "",
                    MetricDataID: 0,
                    MetricDataDesp: "",
                    MetricValue: null,
                    RAGStatus: "",
                    UpperThreshold: "",
                    LowerThreshold: "",
                    LeadingPractice: "",
                    BaselineValue:null,
                    BaseLineMonth:"",
                    TargetValue:null,
                    Comments: "",
                    Month: "",
                    metDataAttr: null,
                    ConnectorID: "",
                    ConnectorNames: "",
                    CriteriaID: "",
                    SourceFieldName: "",
                    SourceName: "",
                    IsAccentureOwned: false,
                    MetricDefination: [],
                    MetricDefinitionID: '',
                    CategoryDetails:[],
                    IsMultiLevel: false,
                    IsAggregation: false
                }
            },
            categories: [],
            CategoryDetails: { CategoryType: "", CategoryName: "" },
            isAddCatPopup: false,
            CategoryNameData: [],
            categoriesLimit:30, // Default
        };
        this.onNameChange = this.onNameChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
        this.onSequenceChange = this.onSequenceChange.bind(this);
        this.onUnitChange = this.onUnitChange.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.onValDescChange = this.onValDescChange.bind(this);
        this.onUpperChange = this.onUpperChange.bind(this);
        this.onLowerChange = this.onLowerChange.bind(this);
        this.onBaselineValueChange = this.onBaselineValueChange.bind(this);
        this.onBaselineMonthChange = this.onBaselineMonthChange.bind(this);
        this.onTargetValueChange = this.onTargetValueChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onSourceChange = this.onSourceChange.bind(this);
        this.onSourceSystemChange = this.onSourceSystemChange.bind(this);
        this.onOtherSystemChange = this.onOtherSystemChange.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.handleRejectHide = this.handleRejectHide.bind(this);
        this.handleApproveHide = this.handleApproveHide.bind(this);
        this.onToolOwnerChange = this.onToolOwnerChange.bind(this);
        this.getGetConnectorMaster = this.getGetConnectorMaster.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    /* Start of Multi Level Functions */

    callingFunction =  () => {
         this.getKpiMAppingDetails().then(()=>{

            if (this.props.kpiData) {
                let kpiData = _.cloneDeep(this.props.kpiData);
                let CatTypeID =
                  kpiData.CategoryDetails && kpiData.CategoryDetails.length > 0
                    ? kpiData.CategoryDetails[0].OfferingMultiLevelCategoryID
                    : 0;
          
                let CatType =
                  kpiData.CategoryDetails && kpiData.CategoryDetails.length > 0 ? kpiData.CategoryDetails[0].CategoryType : 0;
          
                let CategoryNameData =
                  CatType && CatType !== ""
                    ? this.state.allCategoryTypesAndNames.filter((eachValue) => eachValue.CategoryType === CatType)
                    : []; //FETCHING CATEGORY NAME
          
                let CategoryDetails = this.state.CategoryDetails;
                CategoryDetails.CategoryType = CatType;
                this.setState({
                  CategoryNameData: CategoryNameData,
                  CategoryDetails: CategoryDetails,
                  CatTypeID: CatTypeID,
                  iscatypedisable: true,
                },()=>{
                    this.settingKPIData()
                });
              }
         })

      };
  
  
      deleteCategoryName =(catNameToDelete) => {
        this.setState({ errors: {} });
        let CatTypeID = this.state.CatTypeID;
        let kpiData = this.state.woData.kpiData;
        var allCategoriesList = kpiData.CategoryDetails;
        let CategoryNameData = this.state.CategoryNameData;
        allCategoriesList.splice(allCategoriesList.findIndex((item) => item.CategoryName === catNameToDelete), 1);
        if (allCategoriesList.length === 0) {
          kpiData.MetricValue = "";
        }
    
        this.setState({
          CatNameID: 0,
          CategoryNameData: allCategoriesList.length === 0 ? [] : CategoryNameData,
          CatTypeID: CatTypeID,
          woData: {
            kpiData: kpiData,
          },
        });
        if (
          kpiData.IsAggregation &&
          !this.state.valueConnectorEdit &&
          !this.state.valueConnector &&
          !this.isSelectedMetricMappedToConnector
        ) {
          //calculate the metric value on the fly when new values added/ edited/deleted
          this.calculateAggregateValueByFormula();
        }
      }
  
      editCategoryName = (item) => {
        this.setState({
          showCategoryNameEditPopup: true,
        });
      }
  
      deleteCategoryType = () => {
        if(window.confirm(i18n.t("delete_Category_msg")))
        {
        let kpiData = this.state.woData.kpiData;
        kpiData.CategoryDetails = [];
        if (
          kpiData.IsAggregation &&
          !this.state.valueConnectorEdit &&
          !this.state.valueConnector &&
          !this.isSelectedMetricMappedToConnector
        ) {
          // if there are no categories, clear out the value calculated using the formula
          kpiData.MetricValue = "";
        }
    
        this.setState({
          CatNameID: 0,
          CatTypeID: 0,
          CategoryNameData: [],
          woData: {
            kpiData: kpiData,
          },
        });
      }
      }
    
      onClose =(e) => {
        this.setState({ viewmore: false });
        this.props.onClose(e);
      }
  
      getCategoriesLimit = () =>{
        let data = store.getState().CurrentFilter.multiLevelCategoriesLimit;
        this.setState({
          categoriesLimit:data
        })
    }
    bindToolFieldChange = (e) => {
      const id = e.target.id;
      let data = this.state.CategoryDetails;
      switch (id) {
        case "categoryType":
          data.CategoryType = e.target.value;
          break;
        case "categoryName":
          data.CategoryName = e.target.value;
          break;
          default:
            break;
      }
  
      this.setState({
        CategoryDetails: data,
      });
    };
  
      onNumeratorChange = (e) => {
        let errors = this.state.errors;
        errors.NumeratorValue = "";
        this.setState({ errors: errors });
        if (e.target.value.length <= 12) {
          this.setState({ numeratorVal: e.target.value });
        }
      };
  
      onDenomChange = (e) => {
        let errors = this.state.errors
        errors.Denominator = ''
        this.setState({ errors: errors })
        if(e.target.value.length <=12)
        {
                this.setState({ denomVal: e.target.value });
            }
        }
  
        bindAutoAggregationChange = (e) => {
          const kpiData = this.state.woData.kpiData;
      
          kpiData.IsAggregation = e.target.checked;
      
          this.setState({
            woData: {
              kpiData: kpiData,
            },
          });
      
          if (
            kpiData.IsAggregation &&
            !this.state.valueConnectorEdit &&
            !this.state.valueConnector &&
            !this.isSelectedMetricMappedToConnector
          ) {
            this.calculateAggregateValueByFormula();
          }
        };
      
        calculateAggregateValueByFormula = () => {
          const kpiData = _.cloneDeep(this.state.woData.kpiData);
          const metricUnit = kpiData.MetricUnit;
          let temp = 0;
          let formulaStr = "";
          if (!kpiData.FormulaList) kpiData.FormulaList = [];
          if (metricUnit.trim() === "%") {
            if (kpiData.FormulaList.length > 0) {
              formulaStr = kpiData.FormulaList.filter((z) => z.IsPercentageUnit === true)[0].Formula;
            }
          } else {
            if (kpiData.FormulaList.length > 0) {
              formulaStr = kpiData.FormulaList.filter((z) => z.IsPercentageUnit === false)[0].Formula;
            }
            kpiData.formulaStr = formulaStr;
            this.setState({
                woData:{
                kpiData: {...kpiData},
                }
            });
          }
          if (kpiData.CategoryDetails && kpiData.CategoryDetails.length > 0) {
            let total = 0;
            let numeratorSum = 0;
            let denominatorSum = 0;
      
            switch (formulaStr.toUpperCase().trim()) {
              case "SUM":
                kpiData.CategoryDetails.forEach(function(arrayItem) {
                  if (!arrayItem.Denominator) {
                    temp = 1;
                  } else {
                    temp = arrayItem.Denominator;
                  }
                  total = total + arrayItem.NumeratorValue / temp;
                });
      
                break;
              case "AVERAGE":
                kpiData.CategoryDetails.forEach(function(arrayItem) {
                  if (!arrayItem.Denominator) {
                    temp = 1;
                  } else {
                    temp = arrayItem.Denominator;
                  }
                  total = total + arrayItem.NumeratorValue / temp;
                });
      
                total = total / kpiData.CategoryDetails.length;
                break;
              case "WEIGHTED AVERAGE":
                kpiData.CategoryDetails.forEach(function(arrayItem) {
                  if (!arrayItem.Denominator) {
                    arrayItem.Denominator = 100;
                  }
                  // let individualPercentage = (arrayItem.NumeratorValue / arrayItem.Denominator) * 100;
                  // total = total + individualPercentage; //75+30
      
                  numeratorSum += parseFloat(arrayItem.NumeratorValue);
                  denominatorSum += parseFloat(arrayItem.Denominator);
                });
      
                total = (numeratorSum / denominatorSum) * 100;
                break;
              default:
                break;
            }
            if (formulaStr) {
              kpiData.MetricValue = total;
            }
      
            this.setState({
                woData:{
                kpiData: {...kpiData},
                }
            });
          }
        };
      
        onKeyPress = (event) => {
          return (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122);
        }
  
        handleClick = () => {
          const kpiData = this.state.woData.kpiData;
          kpiData.IsMultiLevel = !kpiData.IsMultiLevel;
      
          if (!kpiData.IsMultiLevel) {
            kpiData.IsAggregation = false;
          }
          this.setState({
            woData:{
              kpiData: kpiData,
            }
            });
        }
      
        handleCategoryClose = () => {
          this.setState({
            showCategory: false,
      
            errors: {},
          });
        }
      
        handleCategoryShow =() => {
          this.setState({ errors: {} });
          let CategoryDetails = this.state.CategoryDetails;
          CategoryDetails.CategoryType = "";
          CategoryDetails.CategoryName = "";
          this.setState({
            showCategory: true,
            iscatypedisable: false,
            CategoryDetails: CategoryDetails,
            isEdit: false,
            CategoryNameData: [],
            CatNameID: 0,
            CatTypeID: 0,
            numeratorVal: "",
            denomVal: "",
          });
        }
      
        handleCategoryAdd =() => {
          const kpiData = this.state.woData.kpiData;
          let error = {};
          if (!this.state.numeratorVal) {
            error["NumeratorValue"] = i18n.t("numeratorIsReqd");
          }
          else if (!(parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999)) {
            error["NumeratorValueRange"] = i18n.t("addBoErrorM6");
          }
          if (kpiData.MetricUnit.trim() === "%") {
            if (!this.state.denomVal) {
              error["Denominator"] = i18n.t("denominatorIsReqd");
            } else if (parseFloat(this.state.denomVal) === 0) {
              error["Denominator"] = i18n.t("denomCannotZero");
            } else if (!(parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) {
              error["DenominatorValueRange"] = i18n.t("addBoErrorM6");
            }
          } else {
            if (!Number.isNaN(parseFloat(this.state.denomVal))) {
              if (parseFloat(this.state.denomVal) === 0) {
      
                error["Denominator"] = i18n.t("denomCannotzero");
              } else if (!(parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) {
                error["DenominatorValueRange"] = i18n.t("addBoErrorM6");
              }
            }
          }
          if (this.state.CatTypeID == "0") {
            error["CategoryType"] = i18n.t("catTypeReqd");
          }
      
          if (this.state.CatNameID == "0") {
            error["CategoryName"] = i18n.t("catNameReqd");

          }
          this.setState({ errors: error });
          if (
            kpiData.MetricUnit.trim() === "%"
              ? this.state.CategoryDetails.CategoryType &&
                this.state.CatTypeID != "0" &&
                this.state.CategoryDetails.CategoryName &&
                this.state.CatNameID != "0" &&
                this.state.numeratorVal &&
                (parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999) &&
                this.state.denomVal &&
                parseFloat(this.state.denomVal) != 0 &&
                (parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)
              : this.state.CategoryDetails.CategoryType &&
                this.state.CatTypeID != "0" &&
                this.state.CategoryDetails.CategoryName &&
                this.state.CatNameID != "0" &&
                this.state.numeratorVal &&
                (parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999) &&
                (!Number.isNaN(parseFloat(this.state.denomVal))
                  ? parseFloat(this.state.denomVal) != 0 &&
                    parseFloat(this.state.denomVal) >= -999999999999 &&
                    parseFloat(this.state.denomVal) <= 999999999999
                  : true)
          ) {
            if (!kpiData.CategoryDetails) kpiData.CategoryDetails = [];
      
            if (
              kpiData.CategoryDetails.filter((cat) => cat.CategoryName === this.state.CategoryDetails.CategoryName).length ===
              0
            ) {
              let CategoryName = this.state.CategoryDetails.CategoryName;
      
              let Catid = CategoryName
                ? this.state.CategoryNameData.filter((cat) => cat.CategoryName === CategoryName)[0].ID
                : null;
      
              if (!kpiData.CategoryDetails) kpiData.CategoryDetails = [];
              if (kpiData.CategoryDetails && kpiData.CategoryDetails.length < this.state.categoriesLimit) {
                kpiData.CategoryDetails.push({
                  CategoryType: this.state.CategoryDetails.CategoryType,
                  CategoryName: this.state.CategoryDetails.CategoryName,
                  NumeratorValue: this.state.numeratorVal,
                  Denominator: this.state.denomVal,
                  ConfigurationDealMetricID: 8,
                  OfferingMultiLevelCategoryID: Catid,
                });
                this.setState({
                  showCategory: false,
                  woData:{
                    kpiData: kpiData,
                  }
                });
              } else {
                let error = {};
                error["CategoryName"] = `${i18n.t("A_maximum_of")} ${this.state.categoriesLimit} ${i18n.t("category_values_are")}` 
      
                this.setState({ showCategory: true, errors: error });
              }
      
              if (kpiData.IsAggregation && !this.state.valueConnectorEdit && !this.isSelectedMetricMappedToConnector) {
                //calculate the metric value on the fly when new values added/ edited/deleted
                this.calculateAggregateValueByFormula();
              }
            } else {
              let error = {};
              error["CategoryName"] = i18n.t("duplicateCatName");
      
              this.setState({ errors: error });
            }
          } else {
            this.setState({
              showCategory: true,
            });
          }
        }
        handleCategoryEditShow =(catNameToEdit) => {
          this.setState({ errors: {} });
          var allCategoriesList = [...this.state.woData.kpiData.CategoryDetails];
          var index = allCategoriesList.findIndex((item) => item.CategoryName === catNameToEdit);
          let catnameid = this.state.CategoryNameData.filter((row) => row.CategoryName === catNameToEdit)[0].ID;
          let CategoryName = this.state.CategoryNameData.filter((row) => row.ID === catnameid)[0].CategoryName;
          let CategoryDetails = this.state.CategoryDetails;
          CategoryDetails.CategoryName = CategoryName;
      
          let numeratorVal = allCategoriesList[index].NumeratorValue;
          let denomVal = allCategoriesList[index].Denominator;
          this.setState({
            EditIndex: index,
            isEdit: true,
            showCategory: true,
            CatNameID: catnameid,
            CategoryDetails: CategoryDetails,
            CatTypeID: this.state.CatTypeID,
            numeratorVal: numeratorVal,
            denomVal: denomVal,
          });
        }
      
        handleCategoryEdit = () => {
          let error = {};
          let kpiData =  _.cloneDeep(this.state.woData.kpiData)
      
          if (!this.state.numeratorVal) {
            error["NumeratorValue"] = i18n.t("numeratorIsReqd");
          }
          else if (!(parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999)) {
            error["NumeratorValueRange"] = i18n.t("addBoErrorM6");
          }
          if (kpiData.MetricUnit.trim() === "%") {
            if (!this.state.denomVal) {
              error["Denominator"] = i18n.t("denominatorIsReqd");
            } else if (parseFloat(this.state.denomVal) === 0) {
              error["Denominator"] = i18n.t("denomCannotZero");
            } else if (!(parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) {
              error["DenominatorValueRange"] = i18n.t("addBoErrorM6");
            }
          } else {
            if (!Number.isNaN(parseFloat(this.state.denomVal))) {
              if (parseFloat(this.state.denomVal) === 0) {
                error["Denominator"] = i18n.t("denomCannotzero");
              } else if (!(parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) {
                error["DenominatorValueRange"] = i18n.t("addBoErrorM6");
              }
            }
          }
      
          if (!this.state.CategoryDetails.CategoryType) {
            error["CategoryType"] = i18n.t("catTypeReqd");
          }
      
          if (this.state.CatNameID === "0") {
            error["CategoryName"] = i18n.t("catNameReqd");
          }
          this.setState({ errors: error });
          if (
            kpiData.MetricUnit === "%"
              ? this.state.CategoryDetails.CategoryType &&
                this.state.CatTypeID != "0" &&
                this.state.CategoryDetails.CategoryName &&
                this.state.CatNameID != "0" &&
                this.state.numeratorVal &&
                (parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999) &&
                this.state.denomVal &&
                parseFloat(this.state.denomVal) != 0 &&
                (parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)
              : this.state.CategoryDetails.CategoryType &&
                this.state.CatTypeID != "0" &&
                this.state.CategoryDetails.CategoryName &&
                this.state.CatNameID != "0" &&
                this.state.numeratorVal &&
                (parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999) &&
                (!Number.isNaN(parseFloat(this.state.denomVal))
                  ? parseFloat(this.state.denomVal) != 0 &&
                    parseFloat(this.state.denomVal) >= -999999999999 &&
                    parseFloat(this.state.denomVal) <= 999999999999
                  : true)
          ) {
            let index = this.state.EditIndex;
            if (
              kpiData.CategoryDetails.filter((cat) => cat.CategoryName === this.state.CategoryDetails.CategoryName).length ===
              0
            ) {
              kpiData.CategoryDetails[index].CategoryName = this.state.CategoryDetails.CategoryName;
              kpiData.CategoryDetails[index].NumeratorValue = this.state.numeratorVal;
              kpiData.CategoryDetails[index].Denominator = this.state.denomVal;
              let Catid =
                kpiData.CategoryDetails[index].CategoryName && kpiData.CategoryDetails[index].CategoryName !== ""
                  ? this.state.CategoryNameData.filter(
                      (cat) => cat.CategoryName === kpiData.CategoryDetails[index].CategoryName
                    )[0].ID
                  : null;
              kpiData.CategoryDetails[index].OfferingMultiLevelCategoryID = Catid;
              this.setState({
                woData: {
                  kpiData: {...kpiData},
                },
              });
              this.setState({
                showCategory: false,
              });
            } else if (
              kpiData.CategoryDetails.filter((cat) => cat.CategoryName === kpiData.CategoryDetails[index].CategoryName)
                .length === 1
            ) {
              if (
                kpiData.CategoryDetails[index].CategoryName.trim() === this.state.CategoryDetails.CategoryName.trim()
                  ? false
                  : kpiData.CategoryDetails.filter((cat) => cat.CategoryName === this.state.CategoryDetails.CategoryName)
                      .length === 1
              ) {
                let error = {};
                error["CategoryName"] = i18n.t("duplicateCatName");
      
                this.setState({ errors: error });
              } else {
                kpiData.CategoryDetails[index].NumeratorValue = this.state.numeratorVal;
                kpiData.CategoryDetails[index].Denominator = this.state.denomVal;
                this.setState({
                  woData:{
                    kpiData: {...kpiData},
                    },
                    showCategory: false,
                },()=>{
                  if (
                    kpiData.IsAggregation &&
                    !this.state.valueConnectorEdit &&
                    !this.state.valueConnector &&
                    !this.isSelectedMetricMappedToConnector
                  ) {
                    //calculate the metric value on the fly when new values added/ edited/deleted
                    this.calculateAggregateValueByFormula();
                  }
                });
              }
            } else {
              this.setState({
                showCategory: false,
              });
            }
          }
        }
        handleCategoryTypeAddShow =() => {
          this.setState({ errors: {} });
          let CatTypeID = this.state.CatTypeID;
          let CategoryDetails = this.state.CategoryDetails;
          CategoryDetails.CategoryName = "";
          this.setState({
            CategoryDetails: CategoryDetails,
            showCategory: true,
            isEdit: false,
            iscatypedisable: true,
            CatNameID: 0,
            CatTypeID: CatTypeID,
            numeratorVal: "",
            denomVal: "",
          });
        }
      
        handleaddCategoryNameClose = () => {
          this.setState({
            showaddCategory: false,
            errors: {},
          });
        }
        handleaddCategoryClose = () => {
          this.setState({
            showaddCategoryType: false,
            errors: {},
          });
        }
      
        handleaddCategoryShow =() => {
          this.setState({ showaddCategory: true });
        }
        removeDuplicates = (arr) => {
          /*NEW CODE WHICH REMOVES DUPLICATES DATA FROM API */
          const map = new Map();
          arr.forEach((v) => map.set(v.CategoryType, v));
          return [...map.values()];
        }
      
        CatTypeSelect = (e) => {
      
          let CatTypeID = e.target.value;
          this.setState({ CatTypeID: CatTypeID });
      
          setTimeout(() => {
            if (CatTypeID == "addnew") {
              CategoryDetails.CategoryType = "";
              CategoryDetails.CategoryName = "";
              this.setState({
                errors: {},
                showCategory: false,
                showaddCategoryType: true,
                CategoryDetails: CategoryDetails,
              });
            } else if (CatTypeID == "0") {
              CategoryDetails.CategoryType = "";
              CategoryDetails.CategoryName = "";
              this.setState({
                CatNameID: 0,
                showaddCategoryType: false,
                showaddCategory: false,
                showCategory: true,
                CategoryDetails: CategoryDetails,
              });
            } else if (CatTypeID == "addnewcatname") {
              this.setState({
                showCategory: false,
                showaddCategory: true,
              });
            } else {
              this.setState({
                showaddCategoryType: false,
                showaddCategory: false,
                showCategory: true,
              });
            }
          }, 500);
      
          let CatType =
            CatTypeID && CatTypeID != "0" && CatTypeID != "addnew"
              ? this.state.allCategoryTypesAndNames.filter((eachValue) => eachValue.ID == CatTypeID)[0].CategoryType
              : []; //FETCHING individual CATEGORY TYPE
      
          let CategoryDetails = this.state.CategoryDetails;
          CategoryDetails.CategoryType = CatType;
          this.setState({
            CategoryDetails: CategoryDetails,
          });
      
          let CategoryNameData =
            CatType && CatType != ""
              ? this.state.allCategoryTypesAndNames.filter((eachValue) => eachValue.CategoryType == CatType)
              : []; //FETCHING CATEGORY NAME
      
          this.setState({ CategoryNameData: CategoryNameData });
        }
        handleDropdownChange = (e) => {
          let CatNameID = e.target.value;
          this.setState({ errors: {}, CatNameID: CatNameID });
      
          setTimeout(() => {
            if (CatNameID == "addnew") {
              this.setState({
                showCategory: false,
                showaddCategoryType: true,
              });
            } else if (CatNameID == "addnewcatname") {
              if (!this.state.CategoryDetails.CategoryType) {
                //category type is empty
      
                let error = {};
                error["CategoryType"] = i18n.t("catTypeReqd");
      
                this.setState({ errors: error, CatNameID: 0 });
              } else {
                CategoryDetails.CategoryName = "";
                this.setState({
                  showCategory: false,
                  showaddCategory: true,
                  CategoryDetails: CategoryDetails,
                });
              }
            } else {
              this.setState({
                showaddCategoryType: false,
                showaddCategory: false,
                showCategory: true,
              });
            }
          }, 500);
          let CatName =
            CatNameID && CatNameID != "0" && CatNameID != "addnewcatname"
              ? this.state.allCategoryTypesAndNames.filter((eachValue) => eachValue.ID == CatNameID)[0].CategoryName
              : [];
      
          let CategoryDetails = this.state.CategoryDetails;
          CategoryDetails.CategoryName = CatName;
          this.setState({
            CategoryDetails: CategoryDetails,
          });
        }
      
        //called when new category name is added -- on clicking on the add button
      
        handleaddCategoryAdd = () => {
          const data = this.state.CategoryDetails;
          const param = {
            OfferingId: parseInt(this.props.filter.process),
            CatType: data.CategoryType.toString().trim(),
            CatName: data.CategoryName.toString().trim(),
            LocationID: this.props.filter.Locations,
            SOCategoryID: 3, //FOR WO
            OrgEntityID: this.props.filter.Client,
          };
          let error = {};
          if (!data.CategoryType.trim()) {
            error["CategoryType"] = i18n.t("catTypeReqd");
          }
          if (!data.CategoryName.trim()) {
            error["CategoryName"] = i18n.t("catNameReqd");
          }
      
          this.setState({ errors: error });
      
          if (data.CategoryType.trim() && data.CategoryName.trim()) {
            axiosInstance
              .post(`${LocalApiBaseUrl}MultiLevelApproval/SendCategoryTypeNameForApproval`, param, {})
              .then((response) => {
                if (response.data.IsSuccess && response.data.StatusMessage == "Sent For Approval") {
                  this.setState({
                    showaddCategory: false,
                    showaddCategoryType: false,
                    showApproval: true,
                  });
                } else {
                  window.alert(response.data.StatusMessage);
                  this.setState({ showLoaderOnPage: false });
                }
              })
              .catch((error) => {
                trycatchAlertPopup(error);
                window.alert(this.ssErrMsg + error);
              });
          }
        }
      
        handleApprovalClose =() => {
          this.setState({ showApproval: false });
        }
        getFormattedDate = (date) => {
          if (!date) return "";
          let newDate = new Date(date);
          return (
            [newDate.getMonth() + 1, newDate.getDate(), newDate.getFullYear()].join("/") +
            " " +
            [newDate.getHours(), newDate.getMinutes(), newDate.getSeconds()].join(":")
          );
        };
  
        /* End of Multi Level Functions */

    getGetConnectorMaster() {
        let source = [];
        this.setState({
            showLoaderonPage:true
        })
        axiosInstance
            .get(`${LocalApiBaseUrl}ConnectorMapping/GetConnectorMaster`)
            .then(response => {
                this.setState({
                    showLoaderonPage:false
                })
                source = response.data;
                let sourceValues = source.map(x => (
                    <option
                        key={x.ConnectorID}
            /*selected={index == 0 ? "" : ""}*/ value={x.ConnectorID}
                    >
                        {x.ConnectorName}
                    </option>
                ));
                this.setState({ sourceOptions: sourceValues });
            })
            .catch((error) => {
                this.setState({
                    showLoaderonPage:false
                })
                trycatchAlertPopup(error);
              });
      
      
    }

    getKpiMAppingDetails() {

        let mappedConnectorName
        let valueConnectorEdit = "";
        let allCategoryTypesAndNames=[]

        const{kpiData}=this.props;
        this.setState({
            showLoaderonPage:true
        })
        return new Promise((resolve,reject)=>{
            axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetKpiMapping`, {
                params: { OrgEntityID: this.props.filter.Client, LocationID: this.props.filter.Locations, OfferingID: this.props.filter.process, SOCategoryID: this.props.selectedSubStepID,
                    MonthDT: this.props.filter.Month }
            })
                .then((response) => {
                    let mappingData = response.data;
                    let isConnector=false;
                    if(mappingData.length>0 && kpiData && !isEmptyObject(kpiData)){
                        isConnector=mappingData.some(a=>a.KpiMappingID===kpiData.KPIMappingID && a.IsConnector);
                    }
                    if (this.props.kpiData) {
      
                         let mappedConnector = mappingData.filter((x) => x.KpiMappingID == this.props.kpiData.KPIMappingID)
                        allCategoryTypesAndNames = mappedConnector.length >0? mappedConnector[0].CategoryList:[]
    
                        if (this.props.kpiData.ConnectorID == 0) {
                            mappedConnectorName = mappedConnector[0].ConnectorName;
                        }
                        else if (mappedConnector.length > 0 && mappedConnector[0].ConnectorName) {
                         
                            mappedConnectorName = mappedConnector[0].ConnectorName;
                            this.isConnectorMetricDataFound = mappedConnector[0].IsConnectorMetricDataFound;
                            this.isSelectedMetricMappedToConnector = mappedConnector[0].IsConnector;
                            this.selectedMetricConnectorLastUpdated = mappedConnector[0].ConnectorUpdatedTS;
    
                            this.props.kpiData.ConnectorNames = mappedConnectorName;
                            let mappedConnectID = mappedConnector[0].ConnectorID;
                            if (this.state.woData) {
                                let kpiData = this.state.woData.kpiData;
                                kpiData.connectorNames = mappedConnectorName;
                                kpiData.ConnectorID = mappedConnectID
                                this.setState({
                                    woData: {
                                      kpiData:_.cloneDeep(kpiData)
                                    },
                                      KpiMetricConnectorID: mappedConnector[0].KpiMetricConnectorID != 0 ? true : false
                                  })
                            }
                        }
                        else {
                            mappedConnectorName = this.props.kpiData.ConnectorNames
                            this.props.kpiData.ConnectorNames = mappedConnectorName
                        }
    
                        if (mappedConnectorName != null) {
                            valueConnectorEdit = true;
                        }
                    }
                    this.setState({ 
                        MetricNameOptions: response.data, 
                        valueConnectorEdit,
                        isConnector,
                        allCategoryTypesAndNames,
                        showLoaderonPage:false
                     },()=>{
                        resolve()
                     });
                })
                .catch((error) => {
                    this.setState({
                        showLoaderonPage:false
                    })
                    trycatchAlertPopup(error);
                    reject()
                  });
        })

      
      
    }

    onClose(e) {
        this.setState({ viewmore: false });
        this.props.modalClose(e);
    }

    settingKPIData = () =>{
      let data = _.cloneDeep(this.props.kpiData);  
      if (this.props.kpiData) {
            let kpiData = this.props.kpiData;
          let connectedData=  this.state.MetricNameOptions.filter((x) => x.KpiMappingID === this.props.kpiData.KPIMappingID)
          kpiData.FormulaList = connectedData.length>0?connectedData[0].FormulaList:[]
            this.setState({
                woData: {
                    kpiData: _.cloneDeep(kpiData)
                },
                BaselineMonthReadOnly: data.BaselineValue ? false : true
            });
        }
    }

    componentDidMount() {
        this.callingFunction()
        this.getCategoriesLimit();
        this.getGetConnectorMaster();

    }
    onNameChange(event) {
        let kpiData = this.state.woData.kpiData;
        kpiData.MetricValue="";
        let errors = {};
            this.setState({
                errors: errors
            });

        kpiData.KPIMappingID = event.target.value;
        kpiData.SourceName = "";
        kpiData.SourceFieldName = "";
        this.isConnectorMetricDataFound = true;
        this.isSelectedMetricMappedToConnector = false;
        this.selectedMetricConnectorLastUpdated = undefined;
        let allCategoryTypesAndNames=[]
        if (kpiData.KPIMappingID !== "-1") {
            let selectedMetric = this.state.MetricNameOptions.filter(
                data => data.KpiMappingID == event.target.value
            )[0];
            if (selectedMetric) {
                this.isConnectorMetricDataFound = selectedMetric.IsConnectorMetricDataFound;
                this.isSelectedMetricMappedToConnector = selectedMetric.IsConnector;
                this.selectedMetricConnectorLastUpdated = selectedMetric.ConnectorUpdatedTS;
                kpiData.MetricValue=selectedMetric.IsConnector ?selectedMetric.MetricValue:"";
                kpiData.KPIDesp = this.state.MetricNameOptions.filter((data) => data.KpiMappingID == event.target.value)[0].MetricDescription;
                kpiData.MetricUnit = this.state.MetricNameOptions.filter((data) => data.KpiMappingID == event.target.value)[0].MetricUnit;
                kpiData.MetricName = this.state.MetricNameOptions.filter((data) => data.KpiMappingID == event.target.value)[0].MetricName;
                kpiData.LeadingPractice = this.state.MetricNameOptions.filter((data) => data.KpiMappingID == event.target.value)[0].LeadingPractice;
                kpiData.NumeratorDescription = this.state.MetricNameOptions.filter((data) => data.KpiMappingID == event.target.value)[0].NumeratorDescription;
                kpiData.DenominatorDescription = this.state.MetricNameOptions.filter((data) => data.KpiMappingID == event.target.value)[0].DenominatorDescription;
                kpiData.MetricSeq = this.state.MetricNameOptions.filter((data) => data.KpiMappingID == event.target.value)[0].MetricSeq;
                kpiData.BaselineValue = this.state.MetricNameOptions.filter((data) => data.KpiMappingID == event.target.value)[0].BaselineValue;
                kpiData.BaseLineMonth = this.state.MetricNameOptions.filter((data) => data.KpiMappingID == event.target.value)[0].BaseLineMonth;
                let BaselineMonthReadOnly=kpiData.BaselineValue ?false:true;
                this.setState({BaselineMonthReadOnly:BaselineMonthReadOnly})
                kpiData.TargetValue = this.state.MetricNameOptions.filter((data) => data.KpiMappingID == event.target.value)[0].TargetValue;
                kpiData.SOCategoryID = this.props.selectedSubStepID;
                kpiData.FormulaList = selectedMetric.FormulaList;

                // SET KPI MULTIPLE DEFINITIONS
                kpiData.MetricDefination = this.state.MetricNameOptions.filter(
                    data => data.KpiMappingID == event.target.value
                )[0].MetricDefination;

                // FILTER OUT THE METRIC DEFINITIONS THAT ARE "ENABLED"
                const kpiMetricDefinitions = _.get(this.state, 'woData.kpiData.MetricDefination', []);
                const enabledMetricDefinitions = _.filter(kpiMetricDefinitions, eachMetricDefinition => String(eachMetricDefinition.IsEnabled) === 'true');

                // RESET THE SELECTED METRIC DEFINITION
                kpiData.MetricDefinitionID = '';

                //GETTING CATEGORY TYPES AND CATEGORIES NAMES
                allCategoryTypesAndNames = selectedMetric.CategoryList


                // this.state.iiData.kpiData.MetricDefinitionID
                if (enabledMetricDefinitions.length === 0) {
                    //  this.state.woData.kpiData.MetricDefinitionID
                    const kpiDat = this.state.woData.kpiData;
                    kpiDat.MetricDefinitionID = -1;
                    this.setState({

                        woData: kpiDat

                    });

                }
            }

        } else {
            // RESET THE METRIC DEFINITION DROPDOWN OPTIONS AND SELECTED METRIC DEFINITION
            kpiData.MetricDefination = [];
            kpiData.MetricDefinitionID = '';
            kpiData.FormulaList = [];
            this.setState({
                viewmore: true,
                show: false
            });
        }
        let con = "";
        let valueConnector = "";
        let metriName = this.state.MetricNameOptions.filter(
            eachValue => eachValue.KpiMappingID == event.target.value
        );
        if (metriName.length > 0) {
            let connectorName = metriName[0].ConnectorName;
            let connectorId = metriName[0].ConnectorID;
            let criteria = metriName[0].Criteria;

            if (connectorName == null) {
                con = false;
                kpiData.ConnectorNames = connectorName;
                kpiData.ConnectorID = connectorId;
                kpiData.SourceFieldName = criteria;
            } else {
                con = true;
                kpiData.ConnectorNames = connectorName;
                kpiData.ConnectorID = connectorId;
                kpiData.SourceFieldName = criteria;
                if (metriName[0].KpiMetricConnectorID !== 0) {
                    valueConnector = true;
                }
            }
        }

        let connectorOptions = metriName.map(eachProcess => (
            <option key={eachProcess.ConnectorID} value={eachProcess.ConnectorID}>
                {eachProcess.ConnectorName}
            </option>
        ));
        let criteriaOptions = metriName.map((eachProcess, index) => (
            <option key={index} value={eachProcess.Criteria}>
                {eachProcess.Criteria}
            </option>
        ));
        this.setState(prevState => ({
            woData: {                   // object that we want to update
                ...prevState.woData,    // keep all other key-value pairs
                kpiData:{...kpiData}       // update the value of specific key
            },
            con,
            connectorOptions,
            criteriaOptions,
            valueConnector,
            allCategoryTypesAndNames    
        }))
        // this.validateForm();
    }
    onDescriptionChange(event) {
        const kpiData = this.state.woData.kpiData;
        kpiData.KPIDesp = event.target.value;
        this.setState({
            woData: {
                kpiData: kpiData
            }
        });
    }
    onSequenceChange(event) {
        let errors = {};
        this.setState({
            errors: errors
        });
        const kpiData = this.state.woData.kpiData;
        kpiData.MetricSeq = event.target.value;
        this.setState({
            woData: {
                kpiData: kpiData
            }
        });
        // this.validateForm();
    }
    onUnitChange(event) {
        let errors = {};
        this.setState({
            errors: errors
        });
        const kpiData = this.state.woData.kpiData;
        kpiData.MetricUnit = event.target.value;
        this.setState({
            woData: {
                kpiData: kpiData
            }
        });
        // this.validateForm();
    }
    onValueChange(event) {
        let errors = {};
        this.setState({
            errors: errors
        });
        const kpiData = this.state.woData.kpiData;
        if (event.target.value === "") {
            kpiData.MetricValue = event.target.value;
            this.setState({
                woData: {
                    kpiData: kpiData
                }
            });
        } else {
            if (regexForNegativeNumbers.test(event.target.value)) {
                if(event.target.value === "-."){
					kpiData.MetricValue  = "-0.";  
                }else{
                    kpiData.MetricValue = event.target.value;
                }
        this.setState({
            woData: {
                kpiData: kpiData
            }
        });
            }
        }
        
        // this.validateForm();
    }
    onValDescChange(event) {
        let str = event.target.value;
        let err = this.state.commentsError;
        if (event.target.value) {
            if (regexForTextAreas.test(str)) {
                if (str && str.length < 300) {
                    const kpiData = this.state.woData.kpiData;
                    kpiData.Comments = event.target.value;
                    err = "";
                    this.setState({
                        woData: {
                            kpiData: kpiData,
                        }, commentsError: err
                    });
                } else {
                    const kpiData = this.state.woData.kpiData;
                    kpiData.Comments = event.target.value;
                    err = i18n.t("commentsErrorM1");
                    this.setState({
                        woData: {
                            kpiData: kpiData,
                        }, commentsError: err
                    });
                }
            } else {
                const kpiData = this.state.woData.kpiData;
                kpiData.Comments = event.target.value;
                err = i18n.t("commentsErrorM2");
                this.setState({
                    woData: {
                        kpiData: kpiData,
                    }, commentsError: err
                });
            }
        } else {
            const kpiData = this.state.woData.kpiData;
            kpiData.Comments = event.target.value;
            err = "";
            this.setState({
                woData: {
                    kpiData: kpiData,
                }, commentsError: err
            });
        }
    }

    onToolOwnerChange(event) {
        const kpiData = this.state.woData.kpiData;
        kpiData.IsAccentureOwned = event.target.checked;
        this.setState({
            woData: {
                kpiData: kpiData
            }
        });
    }
    onUpperChange(event) {
        const kpiData = this.state.woData.kpiData;
        if (event.target.value === "") {
            kpiData.UpperThreshold = event.target.value;
            this.setState({
                woData: {
                    kpiData: kpiData
                }
            });
        } else {
            if (regexForNumbersOnlyMTC.test(event.target.value)) {
                kpiData.UpperThreshold = event.target.value;
                this.setState({
                    woData: {
                        kpiData: kpiData
                    }
                });
            }
        }

    }
    onLowerChange(event) {
        const kpiData = this.state.woData.kpiData;
        if (event.target.value === "") {
            kpiData.LowerThreshold = event.target.value;
            this.setState({
                woData: {
                    kpiData: kpiData
                }
            });
        } else {
            if (regexForNumbersOnlyMTC.test(event.target.value)) {
                kpiData.LowerThreshold = event.target.value;
                this.setState({
                    woData: {
                        kpiData: kpiData
                    }
                });
            }
        }

      }
        onBaselineValueChange(event){    
          const kpiData = this.state.woData.kpiData;
          if(event.target.value==="")
          {
              kpiData.BaselineValue = event.target.value;
              kpiData.BaseLineMonth = "";
              this.setState({
                  woData: {
                      kpiData : kpiData
                  },
                  BaselineMonthReadOnly: true
              })
          }
          else{
              if(regexForNegativeNumbers.test(event.target.value)){
                  if(event.target.value === "-."){
                      kpiData.BaselineValue  = "-0.";  
                  }else{
                      kpiData.BaselineValue = event.target.value;
                  }
                  this.setState({
                      woData: {
                          kpiData : kpiData
                      },
                      BaselineMonthReadOnly: false
                  })
              }
          }
      }
      onBaselineMonthChange = (date) => {
          const kpiData = this.state.woData.kpiData;
              kpiData.BaseLineMonth=date;
          this.setState({
              woData : {
                  kpiData:kpiData
              }
          })
      }
      onTargetValueChange(event){
          const kpiData = this.state.woData.kpiData;
          if(event.target.value==="")
          {
              kpiData.TargetValue = event.target.value;
              this.setState({
                  woData: {
                      kpiData : kpiData
                  }
              })
          }
          else{
              if(regexForNegativeNumbers.test(event.target.value)){
                  if(event.target.value === "-."){
                      kpiData.TargetValue  = "-0.";  
                  }else{
                      kpiData.TargetValue = event.target.value;
                  }
                  this.setState({
                      woData: {
                          kpiData : kpiData
                      },
                  })
              }
          }
    }
    onStatusChange(event) {
        let errors = {};
        this.setState({
            errors: errors
        });
        const kpiData = this.state.woData.kpiData;
        kpiData.RAGStatus = event.target.value;
        this.setState({
            woData: {
                kpiData: kpiData
            }
        });
        // this.validateForm();
    }

    onSourceChange(event) {
        const kpiData = this.state.woData.kpiData;
        kpiData.ConnectorID = event.target.value;
        var index = event.nativeEvent.target.selectedIndex;
        kpiData.ConnectorNames = event.nativeEvent.target[index].text;
        kpiData.SourceName = "";
        kpiData.SourceFieldName = "";
        this.setState({
            woData: {
                kpiData: kpiData
            }
        });

        // if (kpiData.ConnectorNames == "Manual") {
        //     document.getElementById('select02').disabled = true;
        // }
        // else {
        //     document.getElementById('select02').disabled = false;

        // }
    }

    onSourceSystemChange(event) {
        const kpiData = this.state.woData.kpiData;
        kpiData.SourceFieldName = event.target.value;
        this.setState({
            woData: {
                kpiData: kpiData
            }
        });
    }
    onOtherSystemChange(event) {
        const kpiData = this.state.woData.kpiData;
        kpiData.SourceName = event.target.value;
        this.setState({
            woData: {
                kpiData: kpiData
            }
        });
    }
    handleSave(e) {
        if (this.validateForm()) {
            const woData = this.state.woData;
            // woData.kpiData.KPIMappingID = woData.kpiData.KPIMappingID == 0 ? Math.random() : woData.kpiData.KPIMappingID;
            if(woData.kpiData.BaseLineMonth!=null && woData.kpiData.BaseLineMonth!=""){
                var baselinemonth= new Date(woData.kpiData.BaseLineMonth).getFullYear() +
                "-" +
                (new Date(woData.kpiData.BaseLineMonth).getMonth() + 1 < 10
                ? "0" + (new Date(woData.kpiData.BaseLineMonth).getMonth() + 1)
                : new Date(woData.kpiData.BaseLineMonth).getMonth() + 1);
                woData.kpiData.BaseLineMonth=baselinemonth;
                this.setState({woData:woData})
            }
            woData.SOCategoryID = this.props.selectedSubStepID;
            if(woData.kpiData.IsMultiLevel === false && woData.kpiData.CategoryDetails && woData.kpiData.CategoryDetails.length !== 0)
            {
             woData.kpiData.CategoryDetails = []
            }
            let currentData  = {"ConfiguratorDataModels":{SOCategoryID:this.props.selectedSubStepID,kpiData:woData.kpiData},"FiltersIDsVM": {
                "OrgEntityID": this.props.filter.Client,
                "OfferingID": this.props.filter.process,
                "LocationID": this.props.filter.Locations,
                "MonthDT": this.props.filter.Month
            }
        }
        this.setState({
            showLoaderonPage:true
        })
                // store.getState().diverseReducers.diverse
                axiosInstance.post(`${LocalApiBaseUrl}Configurator/SaveConfiguratorDealMetrics`, currentData).then((response) => {
                    if(response.data.ConfiguratorDataModels.kpiData){
                        axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetWorkOrchestrationQualityScore`,{params:{LocationID : this.props.filter.Locations,
							OrgEntityID : this.props.filter.Client,
							OfferingID : this.props.filter.process,
							MonthDT : this.props.filter.Month,
							LanguageID: store.getState().CurrentFilter.languageData.id
						}}
					) 
					.then((response) => {
                        this.setState({
                            showLoaderonPage:false
                        })
					 if(response.data){
						store.dispatch({
							type: "SAVE_QC_SCORE_OF_EACH_TAB",
							payload: response.data
						});
					 }
					 
                     this.handleHide(e); 
					}).catch((error)=>{
                        this.setState({
                            showLoaderonPage:false
                        })
trycatchAlertPopup(error);
                        this.handleHide(e); 
					});
                        woData.kpiData = response.data.ConfiguratorDataModels.kpiData;
                        this.setState({
                            woData: response.data.ConfiguratorDataModels
                        });
                        store.dispatch({
                            type: "ADD_EDIT_WO_METRIC",
                            payload: response.data.ConfiguratorDataModels
                        });
                        
                    }
                }).catch((error)=>{
                    this.setState({
                        showLoaderonPage:false
                    })
trycatchAlertPopup(error);
                    window.alert(this.ssErrMsg + error);
                    this.handleHide(e); 
                });
            
           
          
        }
    }

    handleHide(e) {
        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.modalClose(e);
            } else {
                this.props.onClose(e);
                // this.props.onClose("from x");
            }
        } else {
            this.props.modalClose(e);
        }
    }
    handleRejectHide(e) {
        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onRejectClose();
            }
        } else {
            this.props.onRejectClose();
        }
    }
    handleApproveHide(e) {
        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onApproveClose();
            }
        } else {
            this.props.onApproveClose();
        }
    }
    passToConfirmation(data) {
        this.props.enteredData(data.PoNumber);
    }

    validateForm() {
        const kpiData = this.state.woData.kpiData;
        let errors = this.state.errors;
        let formIsValid = true;
        let err="";
        if(kpiData.Comments && kpiData.Comments.length > 300){
            err = i18n.t("commentsErrorM1");
            this.setState({
             commentsError: err
             });
            formIsValid = false;
          }
        if (!kpiData["KPIMappingID"]) {
            formIsValid = false;
            errors["MetricName"] = i18n.t("addWoErrorM1");
        }
        // if (!kpiData["MetricUnit"]) {
        //     formIsValid = false;
        //     errors["MetricUnit"] = "Unit is required";
        // }
        if (this.state.commentsError) {
            formIsValid = false;
        }
        if (this.isSelectedMetricMappedToConnector && !this.isConnectorMetricDataFound) {
            formIsValid = false;
            errors["MetricConnectorDataNotFound"] = i18n.t("Data_not_available_in_source_system") + ".";
            errors["IntegratedMetricsMessage"] = i18n.t("Integrated_metrics_will_get_auto_populated") + ".";
            if (this.selectedMetricConnectorLastUpdated) {
                errors["MetricConnectorLastPull"] = i18n.t("Last_pull_occurred_on") + " " + FormatDate(this.selectedMetricConnectorLastUpdated) + ".";
            } else {
                errors["MetricConnectorLastPull"] = i18n.t("Last_pull_occurred") + ' "' + i18n.t("Date_Unavailable") + '”.';
            }
        }
        if (!this.isSelectedMetricMappedToConnector) {
            if (!kpiData["MetricValue"]) {
                formIsValid = false;
                errors["MetricValue"] = i18n.t("addBoErrorM1");
            } else if (
                !(kpiData["MetricValue"] >= -999999999999 && kpiData["MetricValue"] <= 999999999999)
            ) {
                formIsValid = false;
                errors["MetricValueRange"] = i18n.t("addBoErrorM6");
            }
        }
        if (!(kpiData["BaselineValue"] >= -99999999 && kpiData["BaselineValue"] <= 99999999)) {
          formIsValid = false;
          errors["BaselineValue"] = i18n.t("Baseline_validation");
        }
    
        if (!(kpiData["TargetValue"] >= -99999999 && kpiData["TargetValue"] <= 99999999)) {
          formIsValid = false;
          errors["TargetValue"] = i18n.t("target_validation");
        }
        if (kpiData["MetricSeq"])
            kpiData["MetricSeq"] = parseInt(kpiData["MetricSeq"], 10);
        if (!kpiData["MetricSeq"]) {
            formIsValid = false;
            errors["MetricSeq"] = i18n.t("addWoErrorM5");
        } else if (!(kpiData["MetricSeq"] >= 0 && kpiData["MetricSeq"] <= 999999)) {
            formIsValid = false;
            errors["MetricSeqRange"] = i18n.t("addWoErrorM6");
        }
        if (!kpiData["RAGStatus"]) {
            formIsValid = false;
            errors["RAGStatus"] = i18n.t("addBoErrorM3");
        }
        if (!kpiData["ConnectorID"]) {
            formIsValid = false;
            errors["ConnectorID"] = i18n.t("Source_is_required");
        }

        //         //********************** If disabled definition is selected, dont allow to update


        //  let obj= this.state.MetricNameOptions.filter(z=>z.KpiMappingID===this.state.woData.kpiData.KPIMappingID);
        //  if(obj.length>0){
        // if(obj[0].MetricDefination.filter(e => e.KPIMetricDefinatoinID === parseInt(this.state.woData.kpiData.MetricDefinitionID)).length > 0)
        // {
        // //dont do anything

        // }    
        // else{
        //   formIsValid = false;
        // errors["MetricDefinitionID"] = "Please choose an enabled definition";

        // }

        // }
        // //********************** If disabled definition is selected, dont allow to update end

        //Validate multi level categories
        if (kpiData["IsMultiLevel"]) {
            if (kpiData.CategoryDetails.length === 0) {
              errors["MultiLevel"] = i18n.t("noCategories");
              formIsValid = false;
            }
          }
            
        //end validate multi level categories

        this.setState({
            errors: errors
        });
        if (formIsValid) {
            this.setState({
                formIsValid: true,
                errors: {}
            });
        } else {
            this.setState({
                formIsValid: false
            });
        }
        return formIsValid;
    }

    // WOULD BE EXECUTED WHEN THE "METRIC DEFINITION" DROPDOWN WOULD BE CHANGED
    handleChangeMetricDefinition = e => {
        const MetricDefinitionID = e.target.value;
        this.setState((prevState) => ({
            woData: {
                ...prevState.woData,
                kpiData: {
                    ...prevState.woData.kpiData,
                    MetricDefinitionID,
                },
            }
        }));
    }
    maxLengthCheckThreshold = (object) => {
      if (object.target.value.length > object.target.maxLength) {
       object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    render() {
      const currentDate = new Date();
        let isScreenInEditMode = false;
        if (!_.isEmpty(this.props.kpiData)) {
            isScreenInEditMode = true;
        }

        const stateKpiData = this.state.woData.kpiData;
        let items = [];
        if (stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0) {
            items = stateKpiData.CategoryDetails.map((item, key) => (
              <li>
                <span className="contentName">{item.CategoryName}</span>
                {stateKpiData.MetricUnit.trim() !== "%" && (
                  <span className="contentValues">
                    <span>
                      {thousandSeparatorFormat(item.NumeratorValue) % 1 != 0
                        ? MetricValueThousandFormat(stateKpiData.MetricUnit,item.NumeratorValue,2)
                        : MetricValueThousandFormat(stateKpiData.MetricUnit,item.NumeratorValue,2)}
                    </span>
                    {item.Denominator && <span> | </span>}
                    <span>
                      {item.Denominator
                        ? thousandSeparatorFormat(item.Denominator) % 1 != 0
                          ? MetricValueThousandFormat(stateKpiData.MetricUnit,item.Denominator,2)
                          : MetricValueThousandFormat(stateKpiData.MetricUnit,item.Denominator,2)
                        : ""}
                    </span>{" "}
                  </span>
                )}
      
                {stateKpiData.MetricUnit.trim() === "%" && (
                  <span className="contentValues">
                    <span>
                      {thousandSeparatorFormat((item.NumeratorValue * 100) / item.Denominator) % 1 != 0
                        ? thousandSeparatorFormat(((item.NumeratorValue * 100) / item.Denominator),2)
                        : thousandSeparatorFormat(((item.NumeratorValue * 100) / item.Denominator),2)}
                    </span>{" "}
                    {stateKpiData.MetricUnit}
                  </span>
                )}
      
                <div className="item-list-hover">
                  <span>
                    <i onClick={() => this.deleteCategoryName(item.CategoryName)} class="far fa-times">
                      {" "}
                    </i>
                    <i onClick={() => this.handleCategoryEditShow(item.CategoryName)} class="fas fa-edit"></i>
                  </span>
                </div>
              </li>
            ));
          }

         const{isConnector,showLoaderonPage}=this.state
        // FILTER OUT THE METRIC DEFINITIONS THAT ARE "ENABLED"
        const kpiMetricDefinitions = _.get(this.state, 'woData.kpiData.MetricDefination', []);
        const enabledMetricDefinitions = _.filter(kpiMetricDefinitions, eachMetricDefinition => String(eachMetricDefinition.IsEnabled) === 'true');

        return (
            <>
            <LoadingOverlay 
            className="custom-loader" fadeSpeed={0} spinner={<SynopsLoader />} 
            active={showLoaderonPage}></LoadingOverlay>
                {/* <Header /> */}
                <Modal
                    show={this.state.show}
                    onHide={this.handleHide}
                    className="modal fade wo-configurator-modal"
                    bsSize="medium"
                    id="dealpopup"
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <h4 className="modal-title" id="dealprofile">
                            {/* {i18n.t("Configurator_Work_Orchestration")} */}
                            {isScreenInEditMode ? "Edit Metric" : "Add Metric"} - {this.props.metricDesp}
                        </h4>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="form-section">
                                {/* <h3>{i18n.t("Insight_Data")}</h3> */}
                                <div className="row">
                                <div className="form-group col-lg-7">
                                <div className="row">
                                    <div className="form-group col-lg-6">
                                        <label for="">
                                            {i18n.t("Name")}<span className="required"> *</span>
                                        </label>
                                        {/* <input type="text" maxlength="100" className="form-control" id="name" onChange={this.onNameChange} value={this.state.woData.kpiData.MetricName} /> */}
                                        <select
                                            id="name"
                                            disabled={isScreenInEditMode}
                                            className="form-control"
                                            ref="MetricNameDropdown"
                                            onChange={this.onNameChange.bind(this)}
                                            value={this.props.tooldatatype == "Edit" ? this.props.kpiData.KPIMappingID : this.state.woData.kpiData.KPIMappingID}
                                        >
                                            <option value="">{i18n.t("Select Metric Name")}</option>
                                            {this.props.tooldatatype == "Edit" ?
                                              <option value={ this.props.kpiData.KPIMappingID}>
                                               { this.props.kpiData.MetricName}
                                              </option>
                                            
                                            :this.state.MetricNameOptions.map(row => {
                                                return (
                                                    <option value={row.KpiMappingID}>
                                                        {row.MetricName}
                                                    </option>
                                                );
                                            })}
                                            <option value="-1">&lt;{i18n.t("Add New")}&gt;</option>
                                        </select>
                                        <span className="errorMsg">
                                            {this.state.errors.MetricName}
                                        </span>
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label for="">{i18n.t("Unit_of_Measure")}<span className="required"> *</span></label>
                                        <input
                                            readOnly
                                            type="text"
                                            maxlength="50"
                                            className="form-control"
                                            id=""
                                            value={this.state.woData.kpiData.MetricUnit}
                                        />
                                        <span className="errorMsg">
                                            {this.state.errors.MetricUnit}
                                        </span>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-lg-6">
                                        <label for="">
                                            {i18n.t("Sequence")}<span className="required"> *</span>
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id=""
                                            onChange={this.onSequenceChange}
                                            value={this.state.woData.kpiData.MetricSeq}
                                        />
                                        <span className="errorMsg">
                                            {this.state.errors.MetricSeq}
                                        </span>
                                        <div className="errorMsg">
                                            {this.state.errors.MetricSeqRange}
                                        </div>
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label>
                                            {i18n.t("Leading_Practice")}{" "}
                                            <OverlayTrigger
                                                key="top"
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip-top">
                                                        {i18n.t("addBOMess2")}
                                                    </Tooltip>
                                                }
                                            >
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                            </OverlayTrigger>
                                        </label>
                                        <input
                                            type="text"
                                            disabled
                                            maxLength="50"
                                            className="form-control"
                                            value={this.state.woData.kpiData.LeadingPractice}
                                        />
                                        <div className="errorMsg">
                                            {this.state.errors.LeadingPractice}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <label for="">{i18n.t("Description")}</label>
                                        <input
                                            maxLength="255"
                                            readOnly
                                            type="text"
                                            className="form-control"
                                            id=""
                                            value={this.state.woData.kpiData.KPIDesp}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <label>
                                            {i18n.t("Metric_Definition")}

                                            {" "}
                                            <OverlayTrigger
                                                key="top"
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip-top">
                                                        {i18n.t("addBOMess3")}
                                                    </Tooltip>
                                                }
                                            >
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                            </OverlayTrigger>
                                        </label>

                                        {enabledMetricDefinitions.length > 0 ?
                                            <select
                                                value={this.state.woData.kpiData.MetricDefinitionID}
                                                onChange={(e) => this.handleChangeMetricDefinition(e)}
                                                className="form-control"
                                            >
                                                <option value="">{i18n.t("Select_Metric_Definition")}</option>
                                                {_.map(enabledMetricDefinitions, eachMetricDefinition => {
                                                    return (
                                                        <option
                                                            value={eachMetricDefinition.KPIMetricDefinatoinID}
                                                            key={eachMetricDefinition.KPIMetricDefinatoinID}
                                                        >
                                                            {_.isEmpty(eachMetricDefinition.DenominatorDescription)
                                                                ? eachMetricDefinition.NumeratorDescription
                                                                : `${eachMetricDefinition.NumeratorDescription} / ${eachMetricDefinition.DenominatorDescription}`
                                                            }
                                                        </option>
                                                    );
                                                })}
                                            </select> : <input type="text"
                                                className="form-control" value={i18n.t("Definition_Not_Available")} disabled></input>}

                                        <div className="errorMsg">
                                            {this.state.errors.MetricDefinitionID}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-lg-6">
                                        <label for="">
                                            {i18n.t("Value")} <span className="required"> *</span>
                                        </label>
                                        {/* <input type="number" className="form-control" id="" onChange={this.onValueChange} value={this.state.woData.kpiData.MetricValue} /> */}
                                        <>
                                            {this.state.valueConnector ? (
                                                <input
                                                    type="text"
                                                    maxlength="12"
                                                    className="form-control"
                                                    id=""
                                                    onChange={this.onValueChange}
                                                    value={this.state.woData.kpiData.MetricValue}
                                                    readOnly
                                                />
                                            ) : this.state.KpiMetricConnectorID ? (
                                                <input
                                                    type="text"
                                                    
                                                    maxlength="12"
                                                    className="form-control"
                                                    id=""
                                                    onChange={this.onValueChange}
                                                    value={this.props.kpiData.MetricValue}
                                                    readOnly
                                                />
                                            ) : (
                                                        <input
                                                        readOnly={stateKpiData.IsAggregation}
                                                            type="text"
                                                            className="form-control"
                                                            maxlength="12"
                                                            id=""
                                                            onChange={this.onValueChange}
                                                            value={this.state.woData.kpiData.MetricValue}
                                                            disabled={this.state.KpiMetricConnectorID || isConnector}
                                                        />
                                                    )}
                                        </>
                                        <span className="errorMsg">
                                            {this.state.errors.MetricValue}
                                        </span>
                                        <div className="errorMsg">
                                            {this.state.errors.MetricConnectorDataNotFound}
                                            {this.state.errors.MetricConnectorLastPull ? <div> {this.state.errors.MetricConnectorLastPull} </div> : ""}
                                            {this.state.errors.MetricConnectorDataNotFound ? <div>{this.state.errors.IntegratedMetricsMessage}</div> : ""}
                                        </div>
                                        <span className="errorMsg">
                                            {this.state.errors.MetricValueRange}
                                        </span>
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label for="">
                                            {i18n.t("Status")}<span className="required"> *</span>
                                        </label>

                                        <select
                                            class="form-control"
                                            value={this.state.woData.kpiData.RAGStatus}
                                            onChange={this.onStatusChange}
                                        >
                                            <option value="">--{i18n.t("Select")}--</option>
                                            <option value="Grey"> {i18n.t("Grey")}</option>
                                            <option value="Green"> {i18n.t("Green")}</option>
                                            <option value="Red"> {i18n.t("Red")}</option>
                                            <option value="Amber"> {i18n.t("Amber")}</option>
                                        </select>
                                        <span className="errorMsg">
                                            {this.state.errors.RAGStatus}
                                        </span>
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label for="">{i18n.t("Upper_Threshold")}</label>
                                        <input
                                            type="number"
                                            maxLength="12"
                                            onInput={this.maxLengthCheckThreshold}
                                            className="form-control"
                                            id=""
                                            onChange={this.onUpperChange}
                                            value={this.state.woData.kpiData.UpperThreshold}
                                        />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label for="">{i18n.t("Lower_Threshold")}</label>
                                        <input
                                            type="number"
                                            maxLength="12"
                                            onInput={this.maxLengthCheckThreshold}
                                            className="form-control"
                                            id=""
                                            onChange={this.onLowerChange}
                                            value={this.state.woData.kpiData.LowerThreshold}
                                        />
                                    </div>

                                    <div className="form-group col-lg-4">
                                        <label for="">{i18n.t("Baseline_Value")}</label>
                                        <input
                                            type="text"
                                            maxLength="12"
                                            onInput={this.maxLengthCheckThreshold}
                                            className="form-control"
                                            id=""
                                            onChange={this.onBaselineValueChange}
                                            value={this.state.woData.kpiData.BaselineValue}
                                        />
                                        <span className="errorMsg">
                                            {this.state.errors.BaselineValue}
                                        </span>
                                    </div>

                                    <div className="form-group col-lg-4">
                                        <label for="">{i18n.t("Baseline_Month")}</label>
                                        <div className="datepicker-label-wrapper">
                                            <DatePicker
                                                className="form-control"
                                                id="BaseLineMonth"
                                                maxDate={currentDate}
                                                dateFormat="MM/yyyy"
                                                showMonthYearPicker
                                                selected={
                                                    Date.parse(this.state.woData.kpiData.BaseLineMonth)
                                                        ? new Date(Date.parse(this.state.woData.kpiData.BaseLineMonth))
                                                        : null
                                                }
                                                onSelect={(date) => {
                                                this.onBaselineMonthChange(date);
                                                }}
                                                readOnly={this.state.BaselineMonthReadOnly}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="form-group col-lg-4">
                                        <label for="">{i18n.t("Target_Value")}</label>
                                        <input
                                            type="text"
                                            maxLength="12"
                                            onInput={this.maxLengthCheckThreshold}
                                            className="form-control"
                                            id=""
                                            onChange={this.onTargetValueChange}
                                            value={this.state.woData.kpiData.TargetValue}
                                        />
                                        <span className="errorMsg">
                                            {this.state.errors.TargetValue}
                                        </span>
                                    </div>

                                    <div className="form-group col-lg-12">
                                        <label for="">{i18n.t("Comments")}</label>
                                        <textarea
                                            maxlength="300"
                                            rows="4"
                                            name="comment"
                                            className="form-control"
                                            onChange={this.onValDescChange}
                                            value={this.state.woData.kpiData.Comments}
                                        />
                                        <span className="errorMsg">
                                            {this.state.commentsError}
                                        </span>
                                    </div>
                                </div>
                                <div className="row border-box">
                                    <div className="form-group col-lg-4">
                                        <label for="" className="source-system-padding">
                                            {i18n.t("Source_System")}<span className="required "> *</span>
                                        </label>
                                        <>
                                            {this.state.con ? (
                                                <select
                                                    className="form-control"
                                                    readOnly
                                                    onChange={this.onSourceChange}
                                                    value={this.state.woData.kpiData.ConnectorID}
                                                >
                                                    {this.state.connectorOptions}
                                                </select>
                                            ) : this.state.valueConnectorEdit ? (
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    readOnly
                                                    onChange={this.onSourceChange}
                                                    value={this.props.kpiData.ConnectorNames}
                                                ></input>
                                            ) : (
                                                        <select
                                                            className="form-control"
                                                            onChange={this.onSourceChange}
                                                            value={this.state.woData.kpiData.ConnectorID}
                                                            disabled={this.state.KpiMetricConnectorID || isConnector}
                                                        >
                                                            <option value=""></option>
                                                            {this.state.sourceOptions}
                                                            <option value="-1">{i18n.t("Others")}</option>
                                                            <option value="-2">{i18n.t("Manual")}</option>
                                                        </select>
                                                    )}
                                        </>
                                        <div className="errorMsg">
                                            {this.state.errors.ConnectorID}
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label for="">
                                            {i18n.t("Source_System_Fields")}<span className="required"></span>
                                        </label>
                                        <>
                                            {this.state.con ||
                                                this.state.woData.kpiData.ConnectorNames == "Manual" ? (
                                                    <input
                                                        onChange={this.onSourceSystemChange}
                                                        type="text"
                                                        maxlength="50"
                                                        className="form-control"
                                                        id=""
                                                        readOnly
                                                        value={this.state.woData.kpiData.SourceFieldName}
                                                    />
                                                ) : this.state.KpiMetricConnectorID ? (
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        readOnly
                                                        onChange={this.onSourceChange}
                                                        value={this.props.kpiData.SourceFieldName}
                                                    ></input>
                                                ) : (
                                                        <input
                                                            onChange={this.onSourceSystemChange}
                                                            type="text"
                                                            maxlength="50"
                                                            className="form-control"
                                                            id=""
                                                            value={this.state.woData.kpiData.SourceFieldName}
                                                        />
                                                    )}
                                        </>
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label for="">{i18n.t("Other_Source_System")}</label>
                                        {this.state.woData.kpiData.ConnectorNames === "Others"  ? (
                                            <input
                                                onChange={this.onOtherSystemChange}
                                                type="text"
                                                maxlength="50"
                                                className="form-control"
                                                id=""
                                                value={this.state.woData.kpiData.SourceName}
                                            />
                                        ) : (
                                                <input
                                                    onChange={this.onOtherSystemChange}
                                                    type="text"
                                                    readOnly
                                                    maxlength="50"
                                                    className="form-control"
                                                    id=""
                                                    value={this.state.woData.kpiData.SourceName}
                                                />
                                            )}
                                        <div className="errorMsg">{this.state.errors.KPIDesp}</div>
                                    </div>
                                    <div className="form-group col-lg-12 mt-2">
                                        {/* <input type="checkbox" id="toolowner" defaultChecked={this.state.woData.kpiData.IsAccentureOwned} onChange ={this.onToolOwnerChange}/> Accenture Owned */}
                                        <label className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="toolowner"
                                                defaultChecked={
                                                    this.state.woData.kpiData.IsAccentureOwned
                                                }
                                                onChange={this.onToolOwnerChange}
                                                checked={
                                                    this.state.woData.kpiData.IsAccentureOwned
                                                }
                                            />
                                            <span className="checkmark"></span>
                                            <span className="form-check-label">{i18n.t("Accenture_Owned")}</span>
                                        </label>

                                        <small className="form-text text-muted">
                                            <b>{i18n.t("Note")}:</b> {i18n.t("NoteMessage1")}
                                        </small>
                                    </div>
                                    </div>
                                    </div>
                                    {/* start of multi level UI */}
                <div className="form-group col-lg-5">
                <div className="right-wrapper">
                <div className="catergory-metric-sub">
                        <div className="sub-level">
                          <i className="fas fa-sitemap"></i>
                          {i18n.t("Category_Metric_Sub_Levels")}
                        </div>
                        <div className="toggle-switch">
                          <Form>
                            <Form.Check
                              onClick={this.handleClick}
                              disabled={!stateKpiData.MetricName}
                              checked={stateKpiData.IsMultiLevel}
                              type="switch"
                              id="custom-switch"
                              label=""
                            />
                          </Form>
                        </div>
                      </div>
                      <div class="metric-sub-info">
                      <div className={stateKpiData.IsMultiLevel ? "disable-sub-level-none" : "disable-sub-level"}>
                          <i class="fas fa-sitemap"></i><p>{i18n.t("Enable_to_add_metric_categories_as_its_sub_level")}</p>
                        </div>
                        <div className={stateKpiData.IsMultiLevel ? "enable-sub-level" : "enable-sub-level-none"}>
                          <div class="check-box-info">
                            <div class="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={stateKpiData.IsAggregation}
                                onChange={(e) => {
                                  this.bindAutoAggregationChange(e);
                                }}
                                id="customCheck"
                                name="example1"
                              />
                              <label className="custom-control-label" for="customCheck">
                                {i18n.t("Aggregate_category_values_to_metric_value")}
                              </label>
                                </div>
                                </div>
                                { this.state.showCategory && 
                                <Modal
                            className="addCategoryModal"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={this.state.showCategory}
                            onHide={this.handleCategoryClose}
                            animation={false}
                            backdrop="static"
                          >
                            <Modal.Header>
                              <Modal.Title id="contained-modal-title-vcenter">
                              {this.state.isEdit ? i18n.t("Edit_Category_Details"):i18n.t("Add_Category_Details")}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                {!this.state.iscatypedisable ? (
                                  <div className="form-group col-lg-12">
                                    <label>
                                      {" "}
                                      {i18n.t("Category_Type")}
                                      <span className="required"> *</span>
                                    </label>
                                    <select
                                      id="name"
                                      className="form-control"
                                      disabled={this.state.isEdit}
                                      onChange={this.CatTypeSelect.bind(this)}
                                      value={this.state.CatTypeID}
                                    >
                                      <option value="0">{i18n.t("Select_CategoryType")}</option>
                                      {this.removeDuplicates(this.state.allCategoryTypesAndNames).map((CT) => {
                                        return (
                                          <option value={CT.ID} key={CT.ID}>
                                            {CT.CategoryType}
                                          </option>
                                        );
                                      })}
                                      <option value="addnew">{i18n.t("Add_New")}</option>
                                    </select>
                                    <div className="errorMsg">{this.state.errors.CategoryType}</div>
                                  </div>
                                ) : (
                                  <div className="form-group col-lg-12">
                                    <label>
                                      {i18n.t("Category_Type")}
                                      <span className="required"> *</span>
                                    </label>
                                    <select
                                      id="name"
                                      className="form-control"
                                      disabled={this.state.iscatypedisable}
                                      onChange={this.CatTypeSelect.bind(this)}
                                      value={this.state.CatTypeID}
                                    >
                                      <option value="0">{i18n.t("Select_CategoryType")}</option>
                                      {this.state.allCategoryTypesAndNames.map((CT) => {
                                        return <option value={CT.ID}>{CT.CategoryType}</option>;
                                      })}
                                      <option value="addnew">{i18n.t("Add_New")}</option>
                                    </select>

                                    <div className="errorMsg">{this.state.errors.CategoryType}</div>
                                  </div>
                                )}
                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t("Category_Name")}
                                    <span className="required"> *</span>
                                  </label>
                                  <select
                                    id="name"
                                    value={this.state.CatNameID}
                                    onChange={this.handleDropdownChange}
                                    className="form-control"
                                  >
                                    <option value="0">{i18n.t("Select_CategoryName")}</option>
                                    {this.state.CategoryNameData.map((CN) => {
                                      return <option value={CN.ID}>{CN.CategoryName}</option>;
                                    })}
                                    <option value="addnewcatname">{i18n.t("Add_New")}</option>
                                  </select>
                                  <div className="errorMsg">{this.state.errors.CategoryName}</div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-lg-6">
                                  <label>
                                    {i18n.t("Numerator_Value")}
                                    <span className="required"> *</span>
                                  </label>
                                  <input
                                    type="number"
                                    maxLength="12"
                                    value={this.state.numeratorVal}
                                    onChange={this.onNumeratorChange}
                                    className="form-control"
                                  />
                                  <span className="errorMsg">{this.state.errors.NumeratorValue}</span>
                                  <span className="errorMsg">{this.state.errors.NumeratorValueRange}</span>
                                </div>
                                <div className="form-group col-lg-6">
                                  <label>
                                    {i18n.t("Denominator_Value")}
                                    {stateKpiData.MetricUnit.trim() === "%" && <span className="required"> *</span>}
                                  </label>
                                  <input
                                    type="number"
                                    maxLength="12"
                                    value={this.state.denomVal}
                                    onChange={this.onDenomChange}
                                    className="form-control"
                                  />
                                  <span className="errorMsg">{this.state.errors.Denominator}</span>
                                  <span className="errorMsg">{this.state.errors.DenominatorValueRange}</span>
                                </div>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <button className="btn btn-default btn-sm mr-2" onClick={this.handleCategoryClose}>
                                {i18n.t("Cancel")}
                              </button>
                              {this.state.isEdit ? (
                                <button className="btn btn-primary" onClick={this.handleCategoryEdit}>
                                  {i18n.t("Update")}
                                </button>
                              ) : (
                                <button className="btn btn-primary" onClick={this.handleCategoryAdd}>
                                  {i18n.t("Add")}
                                </button>
                              )}
                            </Modal.Footer>
                          </Modal>
                          }

                                <div class="add-category-infor-here">
                                <div
                              className={
                                stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0
                                  ? "add-metric-head-none"
                                  : "add-metric-head"
                              }
                            >
                                     <div type="button" className="btn add-category-btn" onClick={this.handleCategoryShow}>
                                {i18n.t("Add_Category_Details")}
                              </div>
                                    <div class="add-sub-level-metrics">
                                      <i class="far fa-file-plus"></i>
                                      <p>
                                        <span>*</span>{i18n.t("Add_category_details_as_sub_level_metrics")}
                                        </p>
                                    </div>
                                    </div>
                                    {stateKpiData.CategoryDetails &&
                                stateKpiData.CategoryDetails.length > 0 &&
                            <div className="category-header">
                                  <span>
                                    {i18n.t("Category")} -{" "}
                                    {stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0
                                      ? stateKpiData.CategoryDetails[0].CategoryType
                                      : ""}
                                  </span>
                                  <div className="category-actions">
                                  <div className="category-add" onClick={this.handleCategoryTypeAddShow}>
                                  <i class="fal fa-plus-circle mr-3"></i>
                                  </div>
                                  <span onClick={() => this.deleteCategoryType()}>
                                    <i class="fas fa-trash-alt delete-icon-fix"></i>
                                  </span>
                                  </div>
                                </div>
                                }
                                    <div
                              className={
                                stateKpiData.CategoryDetails &&
                                stateKpiData.CategoryDetails.length > 0 &&
                                stateKpiData.IsMultiLevel
                                  ? "category-lists"
                                  : "category-lists-none"
                              }
                            >
                                      <ul>
                                        
                                          {items}
                                        
                                          
                                          </ul>
                                          </div>
                                          </div>
                                          {/* {Add Category Type} */}
                          {this.state.showaddCategoryType && 
                          <Modal
                            className="addCategoryModal"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={this.state.showaddCategoryType}
                            onHide={this.handleaddCategoryClose}
                            animation={false}
                            backdrop="static"
                          >
                            <Modal.Header>
                              <Modal.Title id="contained-modal-title-vcenter">
                                {i18n.t("Add_Category_Type")}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t("New_Category")}
                                    <span className="required"> *</span>
                                  </label>
                                  <input
                                    id="categoryType"
                                    type="text"
                                    maxLength="100"
                                    className="form-control"
                                    onChange={(e) => {
                                      this.bindToolFieldChange(e);
                                    }}
                                    value={this.state.CategoryDetails.CategoryType}
                                  />

                                  <span className="errorMsg">{this.state.errors.CategoryType}</span>
                                </div>
                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t("Name")}

                                    <span className="required"> *</span>
                                  </label>
                                  <input
                                    id="categoryName"
                                    type="text"
                                    maxLength="100"
                                    className="form-control"
                                    onChange={(e) => {
                                      this.bindToolFieldChange(e);
                                    }}
                                    value={this.state.CategoryDetails.CategoryName}
                                  />

                                  <span className="errorMsg">{this.state.errors.CategoryName}</span>
                                </div>
                                <p className="text-right newlyadded">
                                  {i18n.t("Newly_added_category_type_will_be_sent_for_approval")}
                                </p>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <button className="btn btn-default btn-sm mr-2" onClick={this.handleaddCategoryClose}>
                                {i18n.t("Cancel")}
                              </button>
                              <button className="btn btn-primary" onClick={this.handleaddCategoryAdd}>
                                {i18n.t("Add")}
                              </button>
                            </Modal.Footer>
                          </Modal>
                          }

                          {/* {Add Category Name} */}
                          {
                            this.state.showaddCategory && 
                          
                          <Modal
                            className="addCategoryModal"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={this.state.showaddCategory}
                            onHide={this.handleaddCategoryNameClose}
                            animation={false}
                            backdrop="static"
                          >
                            <Modal.Header>
                              <Modal.Title id="contained-modal-title-vcenter">
                                {" "}
                                {i18n.t("Add_Category_Name")}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t("Category_Type")}
                                    <span className="required"> *</span>
                                  </label>

                                  <input
                                    type="text"
                                    maxLength="100"
                                    className="form-control"
                                    id=""
                                    value={this.state.CategoryDetails.CategoryType}
                                    disabled
                                  />
                                </div>

                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t("Name")}

                                    <span className="required"> *</span>
                                  </label>

                                  <input
                                    id="categoryName"
                                    type="text"
                                    maxLength="100"
                                    className="form-control"
                                    onChange={(e) => {
                                      this.bindToolFieldChange(e);
                                    }}
                                    value={this.state.CategoryDetails.CategoryName}
                                  />

                                  <div className="errorMsg">{this.state.errors.CategoryName}</div>
                                </div>

                                <p className="text-right newlyadded">
                                  {" "}
                                  {i18n.t("Newly_added_category_name_will_be_sent_for_approval")}
                                </p>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <button
                                className="btn btn-secondary btn-sm mr-2"
                                onClick={this.handleaddCategoryNameClose}
                              >
                                {i18n.t("Cancel")}
                              </button>
                              <button className="btn btn-primary" onClick={this.handleaddCategoryAdd}>
                                {i18n.t("Add")}
                              </button>
                            </Modal.Footer>
                          </Modal>
                          }

                          {/* Approval Notification */}
                          {
                            this.state.showApproval && 
                    
                          <Modal
                            className="approvalModal"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={this.state.showApproval}
                            onHide={this.handleApprovalClose}
                            animation={false}
                            backdrop="static"
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>{i18n.t("Approval_Notification")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                {this.state.CatTypeID === "addnew" ? (
                                  <p className="approvalInfo">
                                    {i18n.t("Newly_added_category_type_is_sent_for_approval")}
                                  </p>
                                ) : (
                                  <p className="approvalInfo">
                                    {i18n.t("Newly_added_category_name_is_sent_for_approval")}
                                  </p>
                                )}
                              </div>
                            </Modal.Body>
                          </Modal>
                          }
                        


                                          </div></div>
                </div>
                <div className="errorMsg">{this.state.errors.MultiLevel}</div>
                </div>
                {/* End of multi level UI */}
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    {
                        <Modal.Footer>
                            <div className="modal-btn">
                                <button
                                    onClick={this.handleHide}
                                    className="btn btn-default btn-sm mr-2"
                                >{i18n.t("Cancel")}

                                </button>

                                {!this.props.editMetricConfStatus ? (
                                    <input
                                        type="Button"
                                        className="btn btn-primary btn-sm"
                                        onClick={() => this.handleSave()}
                                        value={i18n.t("Create")}
                                    />
                                ) : (
                                        <input
                                            type="Button"
                                            className="btn btn-primary btn-sm"
                                            onClick={() => this.handleSave()}
                                            value={i18n.t("Update")}
                                        />
                                    )}
                            </div>
                        </Modal.Footer>
                    }
                </Modal>
                {this.state.viewmore ? (
                    <KpiPopUp
                        SOCategoryID={this.props.selectedSubStepID}
                        show={this.state.viewmore}
                        onClose={this.onClose.bind(this)}
                        filterDetails={this.props.filter}
                        tabName="WO"
                    />
                ) : null}
            </>
        );
    }
}