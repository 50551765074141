import React, { Component } from 'react';
import SVM from './SVM';
import {idTokenstring} from '../../Shared/Constant';

export class SVMDashboardContainer extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      enterpriseID: null,
      isSuperAdmin: true,
      userProfiles: null,
      isInitialized: false,
      dashboardClientDetails: {},
      dashboardProgram: [],
      reportingYear: [],
      isStart: false,
      startButton: true,
      isKnowMore: false,
      selectedProgram: "",
      selectedYear: "",
      dashboardData: [],
      dimensionSubDimension: [],
      dbDimensionList: undefined,
      programID: "",
      programFiscalYearRange: {
        min: 0,
        max: 0,
      },
      dashboardCommentary: {},
      dashboardProgramDetails: {},
      selectAllOnFirstRender: true,
    };
  }

  updateSelectAllOnFirstRender = () => {
    this.setState({ selectAllOnFirstRender: false });
  };

  onStart = () => {
    this.setState({ isStart: true });
    const programID = this.state.programID;
    this.createLog({ programID });
  };

  createLog = (props) => {
    /* Optional function if  enableLogger set to false or not declared*/
    console.log(props);
  };

  onKnowMore = () => {
    this.setState({ isKnowMore: true });
  };
  onClickBackBtn = () => {
    this.setState({ isKnowMore: false }, () => {
      // this.props.panelOnClick(null, false);
    });
  };

  GetAuthObj = async () => {
    const data = {
      apiHttps: String(process.env.REACT_APP_SVM_NEW_URL),
      accessToken: sessionStorage.getItem(idTokenstring),
    };
    return data;
  };

  clearDashboard = async (key) => {
    try {
      switch (key) {
        case "reportingYear":
          this.setState({
            dimensionSubDimension: [],
            programID: "",
            // boiProjectDetails: [],
            dbDimensionList: undefined,
          });
          break;
        case "program":
          this.setState({
            dimensionSubDimension: [],
            programID: "",
            // boiProjectDetails: [],
            dbDimensionList: undefined,
          });
          break;
        default:
          this.setState({
            reportingYear: [],
            dashboardProgram: [],
            dimensionSubDimension: [],
            programID: "",
            // boiProjectDetails: [],
            dbDimensionList: undefined,
          });
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Callback functions
  fetchDashboardDataCallback = async (data) => {
    const { selectedYearProps,ClientNameSVM, programIdSVM } = this.props;
    let dashboardProgramSet = null;
    let dashboardDataSet = null;
    let programIDSet = null;
    console.log("dd",data)
    if (data) {
      if (Array.isArray(data.dashboardData) && data.dashboardData.length) {
        let selectedProgram = {
          id: '',
          label: '',
          value: '',
        };

        const programId = programIdSVM;

        if (programIdSVM !== null || programIdSVM !== undefined || programIdSVM !== ' ') {
          
          for (let i = 0; i < data.dashboardData.length; i++) {
            
            
            if ((data.dashboardData[i].id && data.dashboardData[i].id === programId)) {
              dashboardDataSet = data.dashboardData[i];
              programIDSet = data.dashboardData[i].id;
              break;
            }
          }
          for (let j = 0; j < data.dashboardProgram.length; j++) {
            if ((data.dashboardProgram[j].id === programId) && (data.dashboardProgram[j].id === programIDSet)) {
              dashboardProgramSet = data.dashboardProgram[j];
              break;
            }
          }
        } else {
          dashboardDataSet = {};
          programIDSet = '';
          dashboardProgramSet = {};
        }
        if (data.dashboardData !== undefined && dashboardDataSet !== null) {
          selectedProgram = {
            id: String(programIDSet),
            label: String(dashboardDataSet.programName),
            value: String(dashboardDataSet.programName),
          };
        } else {
          selectedProgram = {
            id: String(''),
            label: String(''),
            value: String(''),
          };
        }

        let selectedYear = {
          label: data.dashboardData.filter(item => String(item.id) === selectedProgram.id).map(item => item.ReportingYear),
          value: data.dashboardData.filter(item => String(item.id) === selectedProgram.id).map(item => item.ReportingYear)
        };
        let reportingYear = [selectedYear];

        this.setState({
          dashboardProgram: [dashboardProgramSet],
          dashboardData: [dashboardDataSet],
          selectedProgram,
          selectedYear,
          reportingYear,
          startButton: false,
          isStart: true,
        }, () => {
          const reportingYear = data.dashboardData.filter(item => String(item.id) === selectedProgram.id).map(item => ({
            label: [item.ReportingYear],
            value: [item.ReportingYear],
          }));
          this.setState({
            reportingYear,
          })
        });
      }
    } else {
      this.setState({
        dashboardProgram: [...data.dashboardProgram],
        dashboardData: data.dashboardData,
      });
    }
  };

  fetchClientDetailsCallback = async (data) => {
    if (data) {
      this.setState({
        dashboardClientDetails: data.dashboardClientDetails,
        isInitialized: true,
      });
    }
  };
  onSelectProgramCallback = async (data, selProgram) => {
    if (data) {
      // no action needed since there's only one program available in the dropdown 
    }
  };

  setSelectedReportingYearCallback = async (e) => {
    if (!this.state.startButton) {
      this.clearDashboard("reportingYear");
    }
    if (this.state.selectedProgram === "") {
      this.setState({
        selectedYear: e,
        startButton: false,
        isStart: false,
      });
    } else {
      this.setState(
        {
          selectedYear: e,
        },
        () => {
          if (this.state.isStart) {
            // this.props.setToasterParam({
            //   show: true,
            //   type: "success",
            //   message: "Program data loaded successfully.",
            // });
          }
        },
      );
    }
  };

  // Main Logic
  getDashboardDimensionsCallback = async (data) => {
    if (data) {
      this.setState({
        programFiscalYearRange: data.programFiscalYearRange,
        dbDimensionList: data.dbDimensionList,
        dimensionSubDimension: data.dimensionSubDimension,
        programID: data.programID,
        // panelClicked: data.panelClicked,
        // panelIsClicked: data.panelIsClicked,
        dashboardProgramDetails: data.dashboardProgramDetails,
        isStart: true,
      });
    }
  };
  setCommentaryRequiredDataCallback = async (data) => {
    if (data) {
      this.setState({
        dashboardCommentary: data.dashboardCommentary,
      });
    }
  };

  render() {
    const {
      enterpriseID,
      isSuperAdmin,
      userProfiles,
    } = this.state;
    const { SVMClientID, ClientNameSVM, programIdSVM } = this.props;
    const selectedClientSet = {
      value: parseFloat(SVMClientID),
      text: String(ClientNameSVM),
      label: String(ClientNameSVM),
    };

    return (
      <div>
        {(programIdSVM !== null) && SVMClientID ? (
          <SVM
            // Selected Client Initial Data
            selectedClient={selectedClientSet}
            isInitialized={this.state.isInitialized}
            dashboardClientDetails={this.state.dashboardClientDetails}
            // Dropdown data for Program and Reporting Year used in reusable components
            programOptions={this.state.dashboardProgram}
            yearOptions={this.state.reportingYear}
            // View conditions
            isStart={this.state.isStart}
            startButton={this.state.startButton}
            isKnowMore={this.state.isKnowMore}
            // Action for Viewing and Dropdown Filters
            onStart={this.onStart} // Dashboard Landing View Specific
            onKnowMore={this.onKnowMore} // Summary Wheel specific
            onClickBackBtn={this.onClickBackBtn}
            createLog={this.createLog} // Logger function for SVM, Optional, not required if  enableLogger set to false or not declared
            getAuthObj={this.GetAuthObj}
            // Dashboard Shared Data
            selectedProgram={this.state.selectedProgram}
            selectedYear={this.state.selectedYear}
            dashboardData={this.state.dashboardData}
            dimensionSubDimension={this.state.dimensionSubDimension}
            dbDimensionList={this.state.dbDimensionList}
            // Dasboard Detailed View Specific data
            programID={this.state.programID}
            reportingYear={this.state.reportingYear}
            programFiscalYearRange={this.state.programFiscalYearRange}
            dashboardCommentary={this.state.dashboardCommentary}
            enterpriseID={enterpriseID} // Optional for non-svm
            isSuperAdmin={isSuperAdmin} // Optional for non-svm
            userProfiles={userProfiles} // Optional for non-svm
            // Summary Wheel View Specific data
            dashboardProgramDetails={this.state.dashboardProgramDetails}
            // Callbacks
            fetchDashboardDataCallback={this.fetchDashboardDataCallback}
            fetchClientDetailsCallback={this.fetchClientDetailsCallback}
            onSelectProgramCallback={this.onSelectProgramCallback}
            setSelectedReportingYearCallback={
              this.setSelectedReportingYearCallback
            }
            getDashboardDimensionsCallback={this.getDashboardDimensionsCallback}
            setCommentaryRequiredDataCallback={
              this.setCommentaryRequiredDataCallback
            }
            selectAllOnFirstRender={this.state.selectAllOnFirstRender}
            updateSelectAllOnFirstRender={this.updateSelectAllOnFirstRender}
          />
        ) : (
          <div className="novalueContainer" style={{ display: programIdSVM ? 'none' : 'block' }}>
            <div className="novalue-component-fd">
              <div className="novalue-fd">
                Data for the selected deal is not available yet.
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SVMDashboardContainer;
