import React, { Component } from "react";
import DashboardCntContainer from "../Configurator/DashboardCntContainer";
import { LocalApiBaseUrl, getMonthNumber, getPrevMonth, displayApprovalFeatureData } from "../Shared/Constant";
import { AgGridReact, Ag } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { store } from "../reducers/configureStore";

import MasterContainer from "./MasterContainer";
import i18n from "i18next";
import user from "../Images/user.png";
import security from "../Images/security.png";
import offering from "../Images/offering.png";
import location from "../Images/location.png";
import config from "../Images/config.png";
import { Link, NavLink } from "react-router-dom";
import mydealprofile from "../Images/mydealprofile.png";
import axiosInstance from "../Shared/interceptor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faPencilAlt, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class MasterList extends Component {
  constructor() {
    super();
    this.state = {
      userList: [],
      roleList: [],
      clientList: [],
      viewmore: false,
      rowSelected: [],
      featureData: [],
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isDealClicked: false,
      isLocationClicked: false,
      isCategoryClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isClickableToolClicked: false,
      isClientRegionClicked: false,
      isOneTimeSetupClicked: false,
      isDeliveryRegionClicked: false,
      isToolClicked: false,
      pageHeader: "",
    };
    this.onClose = this.onClose.bind(this);
    this.onMDetailClick = this.onMDetailClick.bind(this);
    this.onIIClick = this.onIIClick.bind(this);
    this.onWOClick = this.onWOClick.bind(this);
    this.onDealClick = this.onDealClick.bind(this);
    this.dealpopup = this.dealpopup.bind(this);
    this.onClientClick = this.onClientClick.bind(this);
    this.onLocationClick = this.onLocationClick.bind(this);
    this.onIndustryClick = this.onIndustryClick.bind(this);
    this.onOfferingClick = this.onOfferingClick.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    this.getRoleData = this.getRoleData.bind(this);
    this.onCategoryClick = this.onCategoryClick.bind(this);
    this.setDefaultTabOnLoad = this.setDefaultTabOnLoad.bind(this);
    this.onClickableToolClick = this.onClickableToolClick.bind(this);
    this.onClientRegionClick = this.onClientRegionClick.bind(this);
    this.onDeliveryRegionClick = this.onDeliveryRegionClick.bind(this);
    this.onOneTimeSetupClick = this.onOneTimeSetupClick.bind(this);
  }

  onFilterTextBoxChanged(e) {
    this.state.gridOptions.api.setQuickFilter(document.getElementById("filter-text-box").value);
  }
  dealpopup(e, data) {
    e.preventDefault();
    this.setState({ viewmore: true, rowSelected: data });
  }

  onClose(e) {
    this.setState({ viewmore: false });
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onClientRegionClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isDealClicked: false,
      isClickableToolClicked: false,
      isCategoryClicked: false,
      isClientRegionClicked: true,
      isDeliveryRegionClicked: false,
      isToolClicked: false,
      isOneTimeSetupClicked: false,
    });
  }

  onDeliveryRegionClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isDealClicked: false,
      isClickableToolClicked: false,
      isCategoryClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: true,
      isToolClicked: false,
      isOneTimeSetupClicked: false,
    });
  }

  onMDetailClick(e) {
    this.setState({
      isMDetailClicked: true,
      isIIClicked: false,
      isWOClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isDealClicked: false,
      isClickableToolClicked: false,
      isToolClicked: false,
      isOneTimeSetupClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isCategoryClicked: false,
    });
  }

  onIIClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: true,
      isWOClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isDealClicked: false,
      isClickableToolClicked: false,
      isToolClicked: false,
      isOneTimeSetupClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isCategoryClicked: false,
    });
  }

  onLocationClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isLocationClicked: true,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isDealClicked: false,
      isCategoryClicked: false,
      isToolClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isClickableToolClicked: false,
      isOneTimeSetupClicked: false,
    });
  }

  onClientClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isLocationClicked: false,
      isClientClicked: true,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isDealClicked: false,
      isCategoryClicked: false,
      isToolClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isClickableToolClicked: false,
      isOneTimeSetupClicked: false,
    });
  }

  onIndustryClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: true,
      isOfferingClicked: false,
      isDealClicked: false,
      isClickableToolClicked: false,
      isToolClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isCategoryClicked: false,
      isOneTimeSetupClicked: false,
    });
  }

  onClickableToolClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isDealClicked: false,
      isClickableToolClicked: true,
      isToolClicked: false,
      isCategoryClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isOneTimeSetupClicked: false,
    });
  }

  onToolClick = (e) => {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isDealClicked: false,
      isClickableToolClicked: false,
      isToolClicked: true,
      isCategoryClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isOneTimeSetupClicked: false,
    });
  };
  onOfferingClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isCategoryClicked: false,
      isOfferingClicked: true,
      isDealClicked: false,
      isClickableToolClicked: false,
      isToolClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isOneTimeSetupClicked: false,
    });
  }

  onDealClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isDealClicked: true,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isClickableToolClicked: false,
      isToolClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isCategoryClicked: false,
      isOneTimeSetupClicked: false,
    });
  }

  onWOClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: true,
      isDealClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isClickableToolClicked: false,
      isCategoryClicked: false,
      isToolClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isOneTimeSetupClicked: false,
    });
  }
  onCategoryClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isDealClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isClickableToolClicked: false,
      isToolClicked: false,
      isCategoryClicked: true,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isOneTimeSetupClicked: false,
    });
  }
  onOneTimeSetupClick(e) {
    this.setState({
      isMDetailClicked: false,
      isIIClicked: false,
      isWOClicked: false,
      isDealClicked: false,
      isLocationClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isOfferingClicked: false,
      isClickableToolClicked: false,
      isToolClicked: false,
      isCategoryClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isOneTimeSetupClicked: true,
    });
  }

  getRoleData() {
    var data = store.getState();
    let roleData1 = data.roleReducers["roleReducers"];
    // roleData1 = roleData1.filter((data) => data.FeatureName == "Masters")

    var View = roleData1.filter((data) => data.FeatureName == "Masters" && data.Operations.includes("IOJ")).length > 0;

    this.setState({ featureData: roleData1 });
    store.subscribe(() => {
      var data = store.getState();
      this.setState({ roleData: data.roleReducers["roleReducers"] });
      let roleData1 = data.roleReducers["roleReducers"];
      this.setState({ featureData: roleData1 });
    });
  }

  setDefaultTabOnLoad() {
    if (this.state.featureData.filter((data) => data.Submenu == "Metric" && data.Operations.length != 0).length > 0) {
      this.setState({
        isMDetailClicked: true,
        isIIClicked: false,
        isWOClicked: false,
        isDealClicked: false,
        isLocationClicked: false,
        isClientClicked: false,
        isIndustryClicked: false,
        isOfferingClicked: false,
        isClickableToolClicked: false,
        isCategoryClicked: false,
        isOneTimeSetupClicked: false,
      });
    } else if (
      this.state.featureData.filter((data) => data.Submenu == "Metric Group" && data.Operations.length != 0).length > 0
    ) {
      this.setState({
        isMDetailClicked: false,
        isWOClicked: false,
        isDealClicked: false,
        isLocationClicked: false,
        isClientClicked: false,
        isIndustryClicked: false,
        isOfferingClicked: false,
        isClickableToolClicked: false,
        isCategoryClicked: false,
        isOneTimeSetupClicked: false,

        isIIClicked: true,
      });
    } else if (
      this.state.featureData.filter((data) => data.Submenu == "Work Orchestration Step" && data.Operations.length != 0)
        .length > 0
    ) {
      this.setState({
        isMDetailClicked: false,
        isIIClicked: false,
        isDealClicked: false,
        isLocationClicked: false,
        isClientClicked: false,
        isIndustryClicked: false,
        isOfferingClicked: false,
        isClickableToolClicked: false,
        isCategoryClicked: false,
        isOneTimeSetupClicked: false,

        isWOClicked: true,
      });
    } else if (
      this.state.featureData.filter((data) => data.Submenu == "Deal Approval" && data.Operations.length != 0).length > 0
    ) {
      this.setState({
        isMDetailClicked: false,
        isIIClicked: false,
        isWOClicked: false,
        isLocationClicked: false,
        isClientClicked: false,
        isIndustryClicked: false,
        isOfferingClicked: false,
        isClickableToolClicked: false,
        isCategoryClicked: false,
        isOneTimeSetupClicked: false,

        isDealClicked: true,
      });
    } else if (
      this.state.featureData.filter((data) => data.Submenu == "Location Approval" && data.Operations.length != 0)
        .length > 0
    ) {
      this.setState({
        isMDetailClicked: false,
        isIIClicked: false,
        isWOClicked: false,
        isDealClicked: false,
        isClientClicked: false,
        isIndustryClicked: false,
        isOfferingClicked: false,
        isClickableToolClicked: false,
        isCategoryClicked: false,
        isOneTimeSetupClicked: false,
        isLocationClicked: true,
      });
    } else if (
      this.state.featureData.filter((data) => data.Submenu == "Client Approval" && data.Operations.length != 0).length >
      0
    ) {
      this.setState({
        isMDetailClicked: false,
        isIIClicked: false,
        isWOClicked: false,
        isDealClicked: false,
        isLocationClicked: false,
        isIndustryClicked: false,
        isOfferingClicked: false,
        isClickableToolClicked: false,
        isCategoryClicked: false,
        isOneTimeSetupClicked: false,

        isClientClicked: true,
      });
    } else if (
      this.state.featureData.filter((data) => data.Submenu == "Industry Approval" && data.Operations.length != 0)
        .length > 0
    ) {
      this.setState({
        isMDetailClicked: false,
        isIIClicked: false,
        isWOClicked: false,
        isDealClicked: false,
        isLocationClicked: false,
        isClientClicked: false,
        isOfferingClicked: false,
        isClickableToolClicked: false,
        isCategoryClicked: false,
        isOneTimeSetupClicked: false,
        isIndustryClicked: true,
      });
    } else if (
      this.state.featureData.filter((data) => data.Submenu == "Offering Approval" && data.Operations.length != 0)
        .length > 0
    ) {
      this.setState({
        isMDetailClicked: false,
        isIIClicked: false,
        isWOClicked: false,
        isDealClicked: false,
        isLocationClicked: false,
        isClientClicked: false,
        isIndustryClicked: false,
        isClickableToolClicked: false,
        isCategoryClicked: false,
        isOneTimeSetupClicked: false,
        isOfferingClicked: true,
      });
    } else if (
      this.state.featureData.filter((data) => data.Submenu == "ClickableTool Approval" && data.Operations.length != 0)
        .length > 0
    ) {
      this.setState({
        isMDetailClicked: false,
        isIIClicked: false,
        isWOClicked: false,
        isDealClicked: false,
        isLocationClicked: false,
        isClientClicked: false,
        isIndustryClicked: false,
        isOfferingClicked: false,
        isCategoryClicked: false,
        isOneTimeSetupClicked: false,
        isClickableToolClicked: true,
      });
    } else if (
      this.state.featureData.filter((data) => data.Submenu == "CustomTool Approval" && data.Operations.length != 0)
        .length > 0
    ) {
      this.setState({
        isToolClicked: true,
      });
    } else if (
      this.state.featureData.filter((data) => data.Submenu == "Category Approval" && data.Operations.length != 0)
        .length > 0
    ) {
      this.setState({
        isMDetailClicked: false,
        isIIClicked: false,
        isWOClicked: false,
        isDealClicked: false,
        isLocationClicked: false,
        isClientClicked: false,
        isIndustryClicked: false,
        isOfferingClicked: false,
        isClickableToolClicked: false,
        isOneTimeSetupClicked: false,
        isCategoryClicked: true,
      });
    } else if (
      this.state.featureData.filter((data) => data.Submenu == "Client Region" && data.Operations.length != 0).length > 0
    ) {
      this.setState({
        isClientRegionClicked: true,
      });
    } else if (
      this.state.featureData.filter((data) => data.Submenu == "Delivery Region" && data.Operations.length != 0).length >
      0
    ) {
      this.setState({
        isDeliveryRegionClicked: true,
      });
    } else if (
      this.state.featureData.filter((data) => data.Submenu == "OneTimeSetup Approval" && data.Operations.length != 0)
        .length > 0
    ) {
      this.setState({
        isMDetailClicked: false,
        isIIClicked: false,
        isWOClicked: false,
        isDealClicked: false,
        isLocationClicked: false,
        isClientClicked: false,
        isIndustryClicked: false,
        isOfferingClicked: false,
        isClickableToolClicked: false,
        isCategoryClicked: false,
        isOneTimeSetupClicked: true,
      });
    }
  }

  setTabOnLoad = (tabName) => {
    let tabData = [];

    switch (tabName) {
      case "Category_Approval":
        tabData = this.state.featureData.filter((data) => data.Submenu == "Category Approval" && data.Operations.length != 0)
        if (
          tabData.length > 0
        ) {
          this.onCategoryClick();
        }
        break;
      case "Client_Approval":
        break;
      case "Client_Region":
        tabData = this.state.featureData.filter((data) => data.Submenu == "Client Region" && data.Operations.length != 0)
        if (
          tabData.length > 0
        ) {
          this.onClientRegionClick();
        }
        break;
      case "CustomTool_Approval":
        tabData = this.state.featureData.filter((data) => data.Submenu == "CustomTool Approval" && data.Operations.length != 0)
        if (
          tabData.length > 0
        ) {
          this.onToolClick();
        }
        break;
      case "Deal_Approval":
        tabData = this.state.featureData.filter((data) => data.Submenu == "Deal Approval" && data.Operations.length != 0)
        if (
          tabData.length > 0
        ) {
          this.onDealClick();
        }
        break;
      case "Delivery_Region":
        tabData = this.state.featureData.filter((data) => data.Submenu == "Delivery Region" && data.Operations.length != 0)
        if (
          tabData.length >
          0
        ) {
          this.onDeliveryRegionClick();
        }
        break;
      case "Industry_Approval":
        tabData = this.state.featureData.filter((data) => data.Submenu == "Industry Approval" && data.Operations.length != 0)
        if (
          tabData.length > 0
        ) {
          this.onIndustryClick();
        }
        break;
      case "Location_Approval":
        tabData = this.state.featureData.filter((data) => data.Submenu == "Location Approval" && data.Operations.length != 0)
        if (
          tabData.length > 0
        ) {
        }
        break;
      case "Metric":
        tabData = this.state.featureData.filter((data) => data.Submenu == "Metric" && data.Operations.length != 0)
        if (tabData.length > 0) {
          this.onMDetailClick();
        }
        break;
      case "Metric_Group":
        tabData = this.state.featureData.filter((data) => data.Submenu == "Metric Group" && data.Operations.length != 0)
        if (
          tabData.length > 0
        ) {
          this.onIIClick();
        }
        break;
      case "Offering_Approval":
        tabData = this.state.featureData.filter((data) => data.Submenu == "Offering Approval" && data.Operations.length != 0)
        if (
          tabData.length > 0
        ) {
          this.onOfferingClick();
        }
        break;
      case "OneTimeSetup_Approval":
        tabData = this.state.featureData.filter((data) => data.Submenu == "OneTimeSetup Approval" && data.Operations.length != 0)
        if (
          tabData.length > 0
        ) {
          this.onOneTimeSetupClick();
        }
        break;
      case "Work_Orchestration_Step":
        tabData = this.state.featureData.filter((data) => data.Submenu == "Work Orchestration Step" && data.Operations.length != 0)
        if (
          tabData.length > 0
        ) {
          this.onWOClick();
        }
        break;
    }

    let pageHeader = "";
    const pageHeaderStatic = (this.props.location.state ? this.props.location.state.detail : (tabData && tabData.length > 0 ? tabData[0].Submenu : ""));
    const titleData = displayApprovalFeatureData.filter(f => { return f.name == pageHeaderStatic });
    pageHeader = titleData.length > 0 && titleData[0].displayName != "" ? titleData[0].displayName : pageHeaderStatic

    this.setState({
      pageHeader
    })
  }

  loadTabBasedOnURL = () => {
    const reqPath = this.props.location.pathname;
    const reqPathArr = reqPath.split("/");
    if (reqPath.startsWith("/admin/Approval") && reqPathArr.length == 4) {
      this.setTabOnLoad(reqPathArr[3])
    }
  }

  componentWillMount() {
    this.getRoleData();
  }

  componentDidMount() {
    this.getRoleData();
    store.subscribe(() => {
      var data = store.getState();
      this.setState({ roleData: data.roleReducers["roleReducers"] });
      let roleData1 = data.roleReducers["roleReducers"];
      this.setState({ featureData: roleData1 });
      // this.setDefaultTabOnLoad();
      this.loadTabBasedOnURL();
    });
    // this.setDefaultTabOnLoad();
    this.loadTabBasedOnURL();
  }

  componentDidUpdate(nextProps) {
    if (this.props.location.pathname != nextProps.location.pathname)
      this.loadTabBasedOnURL();
  }

  render() {
    return (
      <div className="main-component">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="grid-heading">
                {i18n.t("Approval") + " > " + i18n.t(this.state.pageHeader)}
              </h3>
              <div className="tab-list">
                {/* <ul className="navbar-nav search-nav">
                  {this.state.featureData.filter((data) => data.Submenu == "Metric" && data.Operations.length != 0)
                    .length > 0 ? (
                    <li
                      selection="[object Object]"
                      className={this.state.isMDetailClicked ? "active nav-item" : "nav-item"}
                      data-toggle="tab"
                      onClick={this.onMDetailClick}
                    >
                      <a className="nav-link">{i18n.t("Metric_Details")}</a>
                    </li>
                  ) : null}
                  {this.state.featureData.filter(
                    (data) => data.Submenu == "Metric Group" && data.Operations.length != 0
                  ).length > 0 ? (
                    <li
                      selection="[object Object]"
                      className={this.state.isIIClicked ? "active nav-item" : "nav-item"}
                      data-toggle="tab"
                      onClick={this.onIIClick}
                    >
                      <a className="nav-link">{i18n.t("Metric_Group")}</a>
                    </li>
                  ) : null}
                  {this.state.featureData.filter(
                    (data) => data.Submenu == "Work Orchestration Step" && data.Operations.length != 0
                  ).length > 0 ? (
                    <li
                      selection="[object Object]"
                      className={this.state.isWOClicked ? "active nav-item" : "nav-item"}
                      data-toggle="tab"
                      onClick={this.onWOClick}
                    >
                      <a className="nav-link">{i18n.t("Work_Orchestration")}</a>
                    </li>
                  ) : null}
                  {this.state.featureData.filter(
                    (data) => data.Submenu == "Deal Approval" && data.Operations.length != 0
                  ).length > 0 ? (
                    <li
                      selection="[object Object]"
                      className={this.state.isDealClicked ? "active nav-item" : "nav-item"}
                      data-toggle="tab"
                      onClick={this.onDealClick}
                    >
                      <a className="nav-link">{i18n.t("Deal_Approval")}</a>
                    </li>
                  ) : null}

                  {/* {this.state.featureData.filter((data) => data.Submenu == "Metric"&& data.Operations.length!=0).length>0 ? <li selection="[object Object]" className={(this.state.isMDetailClicked ? 'active nav-item' : 'nav-item')} data-toggle="tab" onClick={this.onMDetailClick}><a className="nav-link">Metric Details</a></li>:null}
                          {this.state.featureData.filter((data) => data.Submenu == "Metric Group"&& data.Operations.length!=0).length>0 ? <li selection="[object Object]" className={(this.state.isIIClicked ? 'active nav-item' : 'nav-item')}data-toggle="tab" onClick={this.onIIClick}><a className="nav-link">Metric Group</a></li>:null}
                            {this.state.featureData.filter((data) => data.Submenu == "Work Orchestration Step"&& data.Operations.length!=0).length>0 ? <li selection="[object Object]" className={(this.state.isWOClicked ? 'active nav-item' : 'nav-item')}data-toggle="tab" onClick={this.onWOClick}><a className="nav-link">Work Orchestration</a></li>:null} */}
                {/* {this.state.featureData.filter(
                    (data) => data.Submenu == "Client Approval" && data.Operations.length != 0
                  ).length > 0 ? (
                    <li
                      selection="[object Object]"
                      className={this.state.isClientClicked ? "active nav-item" : "nav-item"}
                      data-toggle="tab"
                      onClick={this.onClientClick}
                    >
                      <a className="nav-link">{i18n.t("Client")}</a>
                    </li>
                  ) : null} */}
                {/* {this.state.featureData.filter(
                    (data) => data.Submenu == "Location Approval" && data.Operations.length != 0
                  ).length > 0 ? (
                    <li
                      selection="[object Object]"
                      className={this.state.isLocationClicked ? "active nav-item" : "nav-item"}
                      data-toggle="tab"
                      onClick={this.onLocationClick}
                    >
                      <a className="nav-link">{i18n.t("Location")}</a>
                    </li>
                  ) : null} */}
                {/*{this.state.featureData.filter(
                    (data) => data.Submenu == "Industry Approval" && data.Operations.length != 0
                  ).length > 0 ? (
                    <li
                      selection="[object Object]"
                      className={this.state.isIndustryClicked ? "active nav-item" : "nav-item"}
                      data-toggle="tab"
                      onClick={this.onIndustryClick}
                    >
                      <a className="nav-link">{i18n.t("Industry")}</a>
                    </li>
                  ) : null}
                  {this.state.featureData.filter(
                    (data) => data.Submenu == "Offering Approval" && data.Operations.length != 0
                  ).length > 0 ? (
                    <li
                      selection="[object Object]"
                      className={this.state.isOfferingClicked ? "active nav-item" : "nav-item"}
                      data-toggle="tab"
                      onClick={this.onOfferingClick}
                    >
                      <a className="nav-link">{i18n.t("Offering")}</a>
                    </li>
                  ) : null}
                  {this.state.featureData.filter(
                    (data) => data.Submenu == "ClickableTool Approval" && data.Operations.length != 0
                  ).length > 0 ? (
                    <li
                      selection="[object Object]"
                      className={this.state.isClickableToolClicked ? "active nav-item" : "nav-item"}
                      data-toggle="tab"
                      onClick={this.onClickableToolClick}
                    >
                      <a className="nav-link">{i18n.t("Clickable Tool")}</a>
                    </li>
                  ) : null}
                  {this.state.featureData.filter(
                    (data) => data.Submenu == "Category Approval" && data.Operations.length != 0
                  ).length > 0 ? (
                    <li
                      selection="[object Object]"
                      className={this.state.isCategoryClicked ? "active nav-item" : "nav-item"}
                      data-toggle="tab"
                      onClick={this.onCategoryClick}
                    >
                      <a className="nav-link">Multilevel Category</a>
                    </li>
                  ) : null}
                  {this.state.featureData.filter(
                    (data) => data.Submenu == "CustomTool Approval" && data.Operations.length != 0
                  ).length > 0 ? (
                    <li
                      selection="[object Object]"
                      className={this.state.isToolClicked ? "active nav-item" : "nav-item"}
                      data-toggle="tab"
                      onClick={this.onToolClick}
                    >
                      <a className="nav-link">Custom Tools</a>
                    </li>
                  ) : null}
                  {this.state.featureData.filter(
                    (data) => data.Submenu == "Delivery Region" && data.Operations.length != 0
                  ).length > 0 ? (
                    <li
                      selection="[object Object]"
                      className={this.state.isDeliveryRegionClicked ? "active nav-item" : "nav-item"}
                      data-toggle="tab"
                      onClick={this.onDeliveryRegionClick}
                    >
                      <a className="nav-link">Delivery Region</a>
                    </li>
                  ) : null}
                  {this.state.featureData.filter(
                    (data) => data.Submenu == "Client Region" && data.Operations.length != 0
                  ).length > 0 ? (
                    <li
                      selection="[object Object]"
                      className={this.state.isClientRegionClicked ? "active nav-item" : "nav-item"}
                      data-toggle="tab"
                      onClick={this.onClientRegionClick}
                    >
                      <a className="nav-link">Client Region</a>
                    </li>
                  ) : null}
                  {this.state.featureData.filter(
                    (data) => data.Submenu == "OneTimeSetup Approval" && data.Operations.length != 0
                  ).length > 0 ? (
                    <li
                      selection="[object Object]"
                      className={this.state.isOneTimeSetupClicked ? "active nav-item" : "nav-item"}
                      data-toggle="tab"
                      onClick={this.onOneTimeSetupClick}
                    >
                      <a className="nav-link">One Time Setup</a>
                    </li>
                  ) : null}
                </ul>*/}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {/* <MasterContainer filterDetails={this.props.filterDetails} detailClicked={this.state.isMDetailClicked} iiClicked={this.state.isIIClicked} woClicked={this.state.isWOClicked} DealClicked={this.state.isDealClicked} /> */}
              <MasterContainer
                filterDetails={this.props.filterDetails}
                detailClicked={this.state.isMDetailClicked}
                iiClicked={this.state.isIIClicked}
                woClicked={this.state.isWOClicked}
                LocationClicked={this.state.isLocationClicked}
                ClientClicked={this.state.isClientClicked}
                DealClicked={this.state.isDealClicked}
                IndustryClicked={this.state.isIndustryClicked}
                OfferingClicked={this.state.isOfferingClicked}
                ClickableToolClicked={this.state.isClickableToolClicked}
                CategoryClicked={this.state.isCategoryClicked}
                ClientRegionClicked={this.state.isClientRegionClicked}
                DeliveryRegionClicked={this.state.isDeliveryRegionClicked}
                ToolClicked={this.state.isToolClicked}
                OneTimeSetupClicked={this.state.isOneTimeSetupClicked}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
