
import Workbook from "react-excel-workbook";
import i18n from "i18next";
import React, { Component } from "react";
import { store } from "../reducers/configureStore";
import moment from 'moment';
import axiosInstance from "../Shared/interceptor";
import {trycatchAlertPopup} from "../Shared/Constant";
import { LocalApiBaseUrl, getPrevMonth } from "../Shared/Constant";

export default class ExportCsv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      excelData: [],
      active: false,
    };
    this.inputRef = React.createRef();
    this.getExcelData = this.getExcelData.bind(this);

  }

  getExcelData = () => {
    let filterProcess = this.props.csvProp;
    axiosInstance
      .get(`${LocalApiBaseUrl}Offering/GetMTCReport`, {
        params: {
          offeringID: filterProcess
        }
      })
      .then(response => {
        let data = response.data;
        this.setState({
          excelData: data,
        }, () => {
          this.inputRef.current.click();
        })
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });


  }

  render() {
    let currentdate = new Date();
    let formatDate = moment(currentdate).format('YYYYMMDD');
    let formatTime = moment(currentdate).format('HHmmss');
    let formatSheetName = `MTCReport_${formatDate}_${formatTime}`;
    return (
      <div className="mtcexport-download" >
        <button className="btn" onClick={this.getExcelData}><i class="far fa-download download-icon-fix"></i></button>
        <Workbook
          filename={`${formatSheetName}.xlsx`}
          element={<button style={{ display: 'none' }} ref={this.inputRef}></button>}
        >
          {this.state.excelData.map((sheet) => {
            let excelData =
              sheet.MTCMetricReport != null && sheet.MTCMetricReport.length > 0
                ? sheet.MTCMetricReport
                : sheet.MTCMetricDefinitionReport != null &&
                  sheet.MTCMetricDefinitionReport.length > 0
                  ? sheet.MTCMetricDefinitionReport
                  : sheet.MTCWOStepReport != null &&
                    sheet.MTCWOStepReport.length > 0
                    ? sheet.MTCWOStepReport
                    : sheet.MTCWOStepToolsReport != null &&
                      sheet.MTCWOStepToolsReport.length > 0
                      ? sheet.MTCWOStepToolsReport
                      : sheet.MTCDDBenchmarkReport != null &&
                        sheet.MTCDDBenchmarkReport.length > 0
                        ? sheet.MTCDDBenchmarkReport
                        : [{ Message: "Data Not available" }]


            let tempcolumnKeys = Object.keys(excelData[0]);
            
            if(sheet.TabName==="WO Metric"){
              excelData=excelData.map(each=>{
              const {ID,ParentSOCategoryName,SOCategoryName,Offering,SubOffering,MetricName,UnitOfMeasure
                ,Sequence,LeadingPractice,Description}=each;
              let obj= {
                ID,
                StepName:ParentSOCategoryName,
                SubStepName:SOCategoryName,
                Offering,
                SubOffering,
                MetricName,
                UnitOfMeasure,
                Sequence,
                LeadingPractice,
                Description
            }
            return obj;
              }
            )
            }
            if(tempcolumnKeys.includes("ParentSOCategoryName")){
              excelData = excelData.map(each => {
                  delete each.ParentSOCategoryName;
                  return each;
                })
            }
            if (tempcolumnKeys.includes("IsBenchmarkValueNeeded")) {
              if (excelData[0]["IsBenchmarkValueNeeded"] == 1 && excelData[0]["IsChangeInColumnName"] == 1) {
                excelData = excelData.map(each => {
                  if (each["IsBenchmarkValueNeeded"] == 1 && each["IsChangeInColumnName"] == 1) {
                    let newname1 = each["LeadingTo"]
                    let newname2 = each["SimilarIndustryTo"]
                    let obj1 = {
                      ...each,
                    }
                    let obj2 = {
                      ...obj1,
                      newname1,
                      newname2
                    }
                    obj2[newname1] = obj2["Leading"]
                    obj2[newname2] = obj2["SimilarIndustry"]
                    if (obj2.newname1 != 'Leading') {
                      delete obj2.Leading;
                    }
                    delete obj2.SimilarIndustry;
                    delete obj2.IsBenchmarkValueNeeded;
                    delete obj2.IsChangeInColumnName;
                    delete obj2.LeadingTo;
                    delete obj2.SimilarIndustryTo;
                    delete obj2.newname1;
                    delete obj2.newname2;
                    return obj2;
                  }
                  else {
                    return each;
                  }

                })
              }
              else {
                excelData = excelData.map(each => {
                  delete each.Leading;
                  delete each.SimilarIndustry;
                  delete each.IsBenchmarkValueNeeded;
                  delete each.IsChangeInColumnName;
                  delete each.LeadingTo;
                  delete each.SimilarIndustryTo
                  return each;
                })
              }
            }

            let columnKeys = Object.keys(excelData[0]);
            columnKeys = columnKeys.filter((e) => { return e != 'TabName' });

            return (
              <Workbook.Sheet data={excelData} name={sheet.TabName}>
                {columnKeys.map((column) => (
                  <Workbook.Column label={column} value={column} />
                ))}

              </Workbook.Sheet>
            );
          })}
        </Workbook>
      </div>
    )
  }
}



