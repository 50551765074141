import React, { Component } from "react";
import { Modal} from "react-bootstrap";
import { LocalApiBaseUrl} from "./Constant";
import i18n from "i18next";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup, regexForToolURL, commonToolRegex } from "../Shared/Constant";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import { validateIsAllowedDomainFromURL, fetchAllowedDomains } from '../Shared/commonFunctions';
import { connect } from 'react-redux';

class CaseStudyLinkPoup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      statusMsg: "",
      data:{},
      errors:{},
      isAPILoading: false,
      allowedDomainsArray: [],
    }
  }

  async componentDidMount() {
    const { clientData } = this.props;
    const reqData = {
      OrgEntityID: clientData.id
    };

    this.setState({
      isAPILoading: true,
    });
    const allowedDomains = await fetchAllowedDomains(reqData);
    this.setState({
      allowedDomainsArray: allowedDomains,
      isAPILoading: false,
    });

    let d = this.state;
    d.statusMsg = this.props.status == "Edit" ? `${i18n.t("Edit")} ${i18n.t("Case_Study")} ${i18n.t("Link")}` : `${i18n.t("Add")} ${i18n.t("Case_Study")} ${i18n.t("Link")}`;
    let propData = {...this.props.data};
    d.data = propData;
    let propOrgEntityId = this.props.OrgEntityID;
    d.data.OrgEntityID = propOrgEntityId;
    d.show = this.props.show;
    this.setState({
        statusMsg:d.statusMsg,
        show:d.show,
        data:d.data,
    })

  }


  handleValidation = ()=>{
    const { allowedDomainsArray } = this.state;
    let errors = {};
    let formIsValid = true;
    let tempState = this.state;
        // tempState.data.URL = tempState.data.URL == null?"":tempState.data.URL.trim();
        // if (!tempState.data.URL) {
        //     errors["URL"] = i18n.t("Case_Study_urlR");
        //     formIsValid = false;
        // } 
        // this.setState({
        //     errors: errors,
        // });
        // return formIsValid;


    if (tempState.data.URL == "" || tempState.data.URL.trim() === "") {
      errors["URL"] = i18n.t("Case_Study_urlR");
      formIsValid = false;
    } else {
      if(!commonToolRegex.test(tempState.data.URL.toLowerCase().trim())){
        if (!regexForToolURL.test(tempState.data.URL.toLowerCase().trim())) {
          tempState.data.URL = tempState.data.URL.toLowerCase();
          errors["URL"] = i18n.t("Invalid_URL");
          formIsValid = false;
        } else {
          const isAllowed = validateIsAllowedDomainFromURL(tempState.data.URL.trim(), allowedDomainsArray);
          if (!isAllowed) {
            errors["URL"] = i18n.t('subdomain_or_domain_not_whitelisted');
            formIsValid = false;
          }
        }
      } else {
        errors["URL"] = i18n.t("commonToolRegex_validatemessage");
        formIsValid = false;
      }
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  onClose = () => {
    this.setState({
      show: false,
    });
    this.props.closeCaseStudy();
  };
  onSave = () => {
   
    let formValid = this.handleValidation();
    if(formValid)
    {
      this.setState({
        show: false,
      });
      this.props.saveCaseStudy(this.state.data);

    }
    
  };
  handleDelete = () =>{
    let valid= window.confirm("Are you sure you want to delete this Case Study Link?");

    if(valid)
    {
      return new Promise((resolve, reject) => {
      axiosInstance
      .delete(`${LocalApiBaseUrl}CaseStudy/DeleteClientCaseStudy`,{
        params:{
          CaseID:this.state.data.ID
        }
      }).then(response=>{
      alert(response.data.StatusMessage)
      this.setState({
        show: false,
      }, ()=>
      {
        resolve(response);
        this.props.deleteCaseStudyLink()
      });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      })
    })
      }
    }
  onURLChange =(event)=>{
    let url=event.target.value;
    let d=this.state;
    d.data.URL=url;
    this.setState({
        data:d.data,
        errors:{}
    })
  }
  render() {
    const { statusMsg,show,data,errors, isAPILoading } = this.state;
    const { status,ispulse } = this.props;

    return (
      <>
        <LoadingOverlay
          className="custom-loader"
          fadeSpeed={0}
          spinner={<SynopsLoader />}
          active={isAPILoading}
        />

      {show &&
        <Modal show={show} onHide={this.onClose} className="casestudylink-modal" backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title className="casestudytitle"><div>{statusMsg}</div> {status == "Edit" && !ispulse && <a onClick={this.handleDelete} className="casestudydelete">
                                                        <i className="far fa-trash-alt"></i>
                                                    </a> }</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="casestudy-linkfields">
              <div className="row">
                <div className="col-sm-12">
                <label for="filepath" class="form-label">
                   {`${i18n.t("Case_Study")} ${i18n.t("Link")}` } 
                      <span class="required">*</span>
                    </label>
                    <input
                      id="filepath"
                      maxLength="5000"
                      type="text"
                      class="form-control"
                      value={data.URL}
                      placeholder="Case Study Link"
                      onChange={this.onURLChange}
                    />
                  <span className="errorMsg">{errors.URL}</span>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-btn casestudyfooter">
            {/* <button type="button" className="btn btn-outline-secondary mr-2" onClick={this.onClose}>
              {i18n.t("Delete")}
            </button> */}
            <button type="button" className="btn btn-primary" onClick={this.onSave}>
              {status == "Edit" ? `${i18n.t("Update")}` : `${i18n.t("Save")}`}
            </button>
          </Modal.Footer>
        </Modal>
      }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  clientData: state.CurrentFilter.ClientData,
});

export default connect(mapStateToProps)(CaseStudyLinkPoup);