import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import i18n from 'i18next';
import ReactTooltip from 'react-tooltip';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup, LocalApiBaseUrl, LocalApiBaseUrlImage } from '../Shared/Constant';
import { store } from '../reducers/configureStore';
import Client from '../Images/client-0.svg';
import { screeNamesForUserTracking, logUserTrackingData } from '../Shared/commonFunctions';
import ClientBoardingPopup from './ClientBoardingPopup';
import AddMappingForm from '../ConnectorMaster/AddMappingForm';
import EnglishLiterals from '../EnglishLiterals.json';
import JapaneseLiterals from '../JapaneseLiterals.json';
import './clientboarding.css';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('msal.idtoken')}`;

const samp = {};
class ClientSelect extends Component {
  constructor(props) {
    super(props);
    this.childToParent = this.childToParent.bind(this);
    this.childToParentForMapping = this.childToParentForMapping.bind(this);
    this.addClient = this.addClient.bind(this);
    this.filterClient = [];
    // To Group by Clients based on three parameters
    this.groupByFilterObject = {
      Industries: { Type: 'Industries', DefaultValue: 'All Industries', DisplayName: 'Industries' },
      Offerings: { Type: 'Offerings', DefaultValue: 'All Offerings', DisplayName: 'Offerings' },
      ClientRegion: { Type: 'ClientRegion', DefaultValue: 'All Client Region', DisplayName: 'Client_Region' },
    };

    this.state = {
      tooltipclientid: '',
      OfferingData: [],
      Nooffering: false,
      showtooltip: false,
      viewPopup: true,
      addClientStatus: false,
      viewClientProjectStatus: false,
      OfferingGroups: ['Functional Offering', 'Industrial Offering', ' Implementational Offering'],
      ClassNames: ['offering-dropdown', 'offering-dropdown', 'offering-dropdown'],

      // Default group by filter
      groupFilterType: this.groupByFilterObject.Offerings.Type,
      // All the Industries/Offerings/Region values based on selected group by filter
      groupFilterValues: [],
      // Default selected filter value
      groupSelectedFilterValues: this.groupByFilterObject.Offerings.DefaultValue,
      groupFilterDisplayName: this.groupByFilterObject.Offerings.DisplayName,
      CityOptions: [],
      ProfileSrc: '',
      selvalue: '',
      headerText: '',
      selvalueDdl: 'Select Client',
      search: '',
      clientDataGrid: this.props.OperatingGropus.filter((f) => f.IsEverPublished === true),
      //FavouriteClients: [],
      filterClient: [],
      isAllClientsClicked: false,
      isFavouritesClicked: true,
      show: false,
      showMapping: false,
      open: false,
      // AllClientshow: true,
      // Favshow: false,
    };
    this.OrgEntityImage = '';
    this.literals2 = {
      eng: { translation: EnglishLiterals },
      jpn: { translation: JapaneseLiterals },
    };
    // this.onTabClick = this.onTabClick.bind(this);
  }

  addClient() {
    this.setState({ addClientStatus: true });
  }

  GetProfilePic() {
    const EnterpriseID = sessionStorage.getItem("EnterpriseID")
    const profilePicToken = sessionStorage.getItem("profilePicToken")
    //if (EnterpriseID != undefined) {
    axiosInstance
      .post(`${LocalApiBaseUrl}User/DownloadPeopleImage`, { enterpriseId: EnterpriseID,profilePicToken:profilePicToken })
      .then((response) => {
        const ProfileSrc = `data:image/jpeg;base64,${response.data}`;
        this.setState({ ProfileSrc });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
    //}
  }

  addClientProjectShow = () => {
    this.setState({
      show: true,
    });
  };

  viewClientProject = () => {
    const { history } = this.props;
    const location = {
      pathname: '/viewclientproject',
    };
    history.push(location);
    this.setState({
      viewClientProjectStatus: true,
    });
  };

  childToParent(action, details) {
    if (action === 'AddMapping') {
      const dealInfo = {
        OrgEntityID: details.OrgEntityID,
        OrgEntityName: details.OrgEntityName,
        OfferingId: details.OfferingID,
        Offering: details.Offering,
        SubOfferingId: 0,
        SubOffering: details.Process,
        LocationId: details.LocationID,
        DeliveryCenter: details.City,
      };

      this.setState({
        dealInfo,
        showMapping: true,
        show: false,
      });
    }
    if (action === 'addProject') this.setState({ show: false });
    if (action === 'cancel') this.setState({ show: false });
  }

  childToParentForMapping(selectedTab, draftInfo) {
    if (selectedTab === 'AddMapping') this.setState({ showMapping: false, show: false });
  }

  componentDidMount() {
    // Reset the Synops Sync Filters
    window.scrollTo({
      top: 0,
      behaviour: 'smooth',
    });
    ReactTooltip.hide();
    // Reset the Synops Sync Filters
    store.dispatch({
      type: 'SYNC_CURRENTFILTER',
      payload: {},
    });
    const visited = localStorage.alreadyVisited;
    if (visited) {
      this.setState({ viewPopup: false });
      // do not view Popup
    } else {
      // this is the first time
      localStorage.alreadyVisited = true;
      this.setState({ viewPopup: true });
    }

    const FavouriteClients = this.props.OperatingGropus.filter((client) => {
      if (client.IsFavourite === true && client.IsEverPublished === true) {
        return client;
      }

      return null;
    });
    if (FavouriteClients.length === 0) {
      this.setState({
        isAllClientsClicked: true,
        isFavouritesClicked: false,
      });
    }
    if (FavouriteClients.length !== 0) {
      this.setState({
        isAllClientsClicked: false,
        isFavouritesClicked: true,
      });
    }

    const filterClient = this.props.OperatingGropus.filter((client) => {
      if (
        client.OrgEntityName.toLowerCase().indexOf(this.state.search.toLowerCase().trim()) !== -1
        && client.IsEverPublished === true
      ) {
        return client;
      }
      return null;
    });
    this.setState(
      {
        filterClient,
      },
      () => {
        this.groupByClient(this.groupByFilterObject.Offerings.Type, this.groupByFilterObject.Offerings.DefaultValue);
      },
    );
    // this.state.isAllClientsClicked= true;
    //alert(this.props.headerText);
    var a = this.props;
  }

  componentWillMount() {
    const filterClient = this.props.OperatingGropus.filter((client) => {
      if (
        client.OrgEntityName.toLowerCase().indexOf(this.state.search.toLowerCase().trim()) != -1
        && client.IsEverPublished === true
      ) {
        return client;
      }
      return null;
    });
    this.setState({
      filterClient,
    });

    if (this.props.prevSelectedClient != '' && this.props.prevSelectedClient != undefined) {
      this.setState({ selvalue: this.props.prevSelectedClient.id });
      this.setState({ selvalueDdl: this.props.prevSelectedClient.name });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.headerText === '') {
      if (nextProps.OperatingGropus.length != 0) {
        const CityOptions = [];
        const clientList = nextProps.OperatingGropus.map((client) => {
          samp[client.OrgEntityID] = client.OrgEntityImage;
          return CityOptions.push(<option value={client.OrgEntityID}>{client.OrgEntityName}</option>);
        });

        this.setState({ CityOptions });
      }
    } else {
      this.setState({ headerText: nextProps.headerText });
    }
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  searchchange = (e) => {
    ReactTooltip.hide();
    this.setState(
      {
        search: e.target.value,
        OfferingData: [],
      },
      () => {
        this.groupByClient(this.state.groupFilterType, this.state.groupSelectedFilterValues);
      },
    );
  };

  onSelectHandle = (val) => {
    this.props.saveCommentHistoryId({ SOCategoryID: '', MetricDataID: '', showHistory: false });

    const clientData = {
      name: val.OrgEntityName,
      id: val.OrgEntityID,
      abbr: val.OrgEntityName.substr(0, 2),
      imageName: val.OrgEntityImage,
    };
    this.props.saveClientData(clientData);

    sessionStorage.setItem('clientSelection', JSON.stringify(clientData));
    this.handleUserNavigation(val.OrgEntityName_En);
    // let literalsApiStatus = false;
    // axiosInstance
    //   .get(`${LocalApiBaseUrl}TranslatedLiterals/GetTranslatedText`, {
    //     params: { LanguageID: LanguageID, OrgEntityID: parseInt(val.OrgEntityID), setDefault: false },
    //   })
    //   .then((response) => {
    //     if (response.data) {
    //       let data = {
    //         id: parseInt(LanguageID),
    //         langCode: LanguageCode,
    //         messages: response.data,
    //         languageDropDown: languageDropDown,
    //       };
    //       let literals = {};
    //       literals[LanguageCode] = { translation: response.data };
    //       i18n.use(initReactI18next).init({
    //         resources: literals,
    //         lng: LanguageCode,
    //         fallbackLng: "en",

    //         interpolation: {
    //           escapeValue: false,
    //         },
    //       });

    //       this.props.saveLangData(data);
    //       this.continueHandle(clientData, val);
    //     }
    //   })
    //   .catch((err) => {
    //     let data = {
    //       id: parseInt(LanguageID),
    //       langCode: LanguageCode,
    //       messages: {},
    //       languageDropDown: languageDropDown,
    //     };
    //     i18n.use(initReactI18next).init({
    //       resources: this.literals2,
    //       lng: "en",
    //       fallbackLng: "en",

    //       interpolation: {
    //         escapeValue: false,
    //       },
    //     });
    //     this.props.saveLangData(data);
    //     this.continueHandle(clientData, val);
    //   });
    this.continueHandle(clientData, val);
  };

  continueHandle = (clientData, val) => {
    this.props.selectedFilter(clientData);

    const isPulsePageAccessAllowed = this.checkIfPulsePageAccessAllowed();

    // IF PULSE PAGE ACCESS IS NOT ALLOWED THEN REDIRECT TO "SynOps Applied Now" (Dashboard)
    if (!isPulsePageAccessAllowed) {
      this.props.history.push({
        pathname: '/Dashboard',
        state: { pulseMenu: false },
      });
    } else {
      this.props.history.push({
        pathname: '/landing',
        state: { pulseMenu: true },
      });
      // let pulsePageConfigure = false;
      // this.props.roleData.map((each) => {
      //   if (each.FeatureName === "PulsePage") {
      //     pulsePageConfigure = each.Operations.includes("Edit");
      //   }
      // });
      // if (!pulsePageConfigure) {
      //   axiosInstance
      //     .get(`${LocalApiBaseUrl}PulseDashBoard/GetPulseOfferings`, {
      //       params: { OrgEntityID: val.OrgEntityID, LanguageID: this.props.languageData.id },
      //     })
      //     .then((response) => {
      //       if (response.data.length) {
      //         this.props.savePulseData({ offerings: response.data, data: [] });
      //         this.props.history.push({
      //           pathname: "/landing",
      //           state: { pulseMenu: true },
      //         });
      //       } else {
      //         this.props.history.push({
      //           pathname: "/Dashboard",
      //           state: { pulseMenu: false },
      //         });
      //       }
      //     })
      //     .catch((error) => {
      //       this.props.history.push({
      //         pathname: "/Dashboard",
      //         state: { pulseMenu: false },
      //       });
      //     });
      // } else {
      //   axiosInstance
      //     .get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
      //       params: { OrgEntityID: val.OrgEntityID, LanguageID: this.props.languageData.id },
      //     })
      //     .then((response) => {
      //       if (response.data.length) {
      //         this.props.savePulseData({ offerings: response.data, data: [] });
      //         this.props.history.push({
      //           pathname: "/landing",
      //           state: { pulseMenu: true },
      //         });
      //       } else {
      //         this.props.history.push({
      //           pathname: "/Dashboard",
      //           state: { pulseMenu: false },
      //         });
      //       }
      //     })
      //     .catch((error) => {
      //       this.props.history.push({
      //         pathname: "/Dashboard",
      //         state: { pulseMenu: false },
      //       });
      //     });
      // }
    }
  };

  sortClients = (order) => {
    if (order === 'ascending') {
      this.state.clientDataGrid.sort((a, b) => {
        const OrgEntityNameA = a.OrgEntityName.toLowerCase();
        const OrgEntityNameB = b.OrgEntityName.toLowerCase();
        if (OrgEntityNameA < OrgEntityNameB)
        // sort string ascending
        { return -1; }
        if (OrgEntityNameA > OrgEntityNameB) return 1;
        return 0; // default return value (no sorting)
      });
    } else if (order === 'descending') {
      this.state.clientDataGrid.sort((a, b) => {
        const OrgEntityNameA = a.OrgEntityName.toLowerCase();
        const OrgEntityNameB = b.OrgEntityName.toLowerCase();
        if (OrgEntityNameA > OrgEntityNameB) return -1;
        if (OrgEntityNameA < OrgEntityNameB) return 1;
        return 0; // default return value (no sorting)
      });
    }

    this.setState({ clientDataGrid: this.state.clientDataGrid }, () => {
      this.groupByClient(this.state.groupFilterType, this.state.groupSelectedFilterValues);
    });
  };

  asc = () => {};

  desc = () => {};

  temp(val, e) {
    const ClientDataGrid = this.state.clientDataGrid;

    if (val.IsFavourite === false) {
      const value = val;
      value.IsFavourite = true;

      ClientDataGrid.map((client) => {
        if (client.OrgEntityID === val.OrgEntityID) {
          client.IsFavourite = true;
        }
      });

      axiosInstance
        .post(`${LocalApiBaseUrl}User/SetClientAsFavourite`, value)
        .then((response) => {})
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    } else {
      ClientDataGrid.map((client) => {
        if (client.OrgEntityID === val.OrgEntityID) {
          client.IsFavourite = false;
        }
      });

      // FavouriteClients =
      ClientDataGrid.filter((client) => {
        if (client.OrgEntityID === val.OrgEntityID) {
          const value = val;
          value.IsFavourite = false;

          axiosInstance
            .post(`${LocalApiBaseUrl}User/SetClientAsFavourite`, value)
            .then((response) => {})
            .catch((error) => {
              trycatchAlertPopup(error);
            });
          return null;
        }

        return client;
      });
    }

    this.setState(
      {
        // FavouriteClients: FavouriteClients,
        clientDataGrid: ClientDataGrid,
      },
      () => {
        this.groupByClient(this.state.groupFilterType, this.state.groupSelectedFilterValues);
      },
    );

    e.stopPropagation();
  }

  onTabClick(val) {
    ReactTooltip.hide();
    if (String(val) === 'All') {
      this.setState(
        {
          isAllClientsClicked: true,
          isFavouritesClicked: false,
        },
        () => {
          this.groupByClient(this.state.groupFilterType, this.getDefaultFilterValue());
        },
      );
    } else if (String(val) === 'Favourites') {
      this.setState(
        {
          isAllClientsClicked: false,
          isFavouritesClicked: true,
        },
        () => {
          this.groupByClient(this.state.groupFilterType, this.getDefaultFilterValue());
        },
      );
    }
  }

  // Method to get the default group by filter value based on selected filter (Pick selected filter from state)
  getDefaultFilterValue() {
    const filter = Object.entries(this.groupByFilterObject).filter((x) => {
      if (x[0] == this.state.groupFilterType) {
        return x;
      }
    });
    return filter[0][1].DefaultValue;
  }

  // Method to set the Display name (Set in state variable) of the filter based on selected filter
  setDefaultFilterDisplayName() {
    const filter = Object.entries(this.groupByFilterObject).filter((x) => {
      if (x[0] == this.state.groupFilterType) {
        return x;
      }
    });
    this.setState({ groupFilterDisplayName: filter[0][1].DisplayName });
  }

  // Method to Filter the clients based on selected group by filter ( Industries/Offerings/Region ) and it's value
  groupByClient(type, value) {
    ReactTooltip.hide();
    this.filterClient = this.state.clientDataGrid;
    // If Favourites filter is selected then keep favourite clients only
    this.filterClient = this.state.isFavouritesClicked
      ? this.filterClient.filter((client) => {
        if (client.IsFavourite === true) {
          return client;
        }
        return null;
      })
      : this.filterClient;

    if (String(type) !== '') {
      this.setState({ groupFilterType: type }, (x) => {
        this.setDefaultFilterDisplayName();
      });
      let offerings = [];
      const OfferingGroups = [];
      let industries = [];
      let clientRegions = [];
      //const offeringArray= [] ; -- uncomment later

      this.setState({ groupSelectedFilterValues: value });

      // To pull all the Industries/Offerings/Region values for each client
      this.filterClient.forEach((item) => {
        offerings = offerings.concat(item.Offerings);
        industries = industries.concat(item.Industries);
        clientRegions = clientRegions.concat(item.ClientRegions);
      });

      // To find the distinct value for  Industries/Offerings/Region and sort them
      industries = industries
        .filter((item, pos) => industries.indexOf(item) == pos)
        .sort();
      offerings = offerings
        .filter((item, pos) => offerings.indexOf(item) == pos)
        .sort();

      clientRegions = clientRegions
        .filter((item, pos) => clientRegions.indexOf(item) == pos)
        .sort();

      // Push the default value for each filter in the begining of array (Industries/Offerings/Region)
      // industries.unshift(this.groupByFilterObject.Industries.DefaultValue);
      // offerings.unshift(this.groupByFilterObject.Offerings.DefaultValue);
      // clientRegions.unshift(this.groupByFilterObject.ClientRegion.DefaultValue);

      // Set the groupFilterValues based on selection of filter (Industries/Offerings/Region)
      switch (type) {
        case this.groupByFilterObject.Industries.Type:
          this.setState({ groupFilterValues: industries });
          if (value !== this.groupByFilterObject.Industries.DefaultValue) {
            this.filterClient = this.filterClient.filter((c) => c.Industries.includes(value));
          }
          break;
        case this.groupByFilterObject.Offerings.Type:
          this.setState({
            groupFilterValues: offerings,
          });
          if (value !== this.groupByFilterObject.Offerings.DefaultValue) {
            this.filterClient = this.filterClient.filter((c) => c.Offerings.includes(value));
          }
          break;
        case this.groupByFilterObject.ClientRegion.Type:
          this.setState({ groupFilterValues: clientRegions });
          if (value !== this.groupByFilterObject.ClientRegion.DefaultValue) {
            this.filterClient = this.filterClient.filter((c) => c.ClientRegions.includes(value));
          }
          break;
        default:
        // code block
      }
    }

    // Perform the search on filtered recored
    this.filterClient = this.filterClient.filter((client) => {
      if (client.OrgEntityName.toLowerCase().indexOf(this.state.search.toLowerCase().trim()) !== -1) {
        return client;
      }
      return null;
    });

    //    if (event != undefined) {
    //        event.stopPropagation()
    //    }
    //}

    //dropdownControl(position) {

    //    let ClassNames = [...this.state.ClassNames]
    //    if (ClassNames[position] === "offering-dropdown")
    //        ClassNames[position] = "offering-dropdown open";

    //    else
    //        ClassNames[position] = "offering-dropdown";

    //    this.setState({
    //        ClassNames: ClassNames
    //    })
  }

  handleUserNavigation = (client) => {
    const { languageData } = this.props;
    const lang = String(languageData.langCode) === 'eng' ? 'en' : languageData.langCode;
    const data = {
      PageName: screeNamesForUserTracking.ClientSelect,
      Client: client,
      Language: lang,
    };
    logUserTrackingData(data);
  };

  onSelectHandleOffering = (e, val) => {
    e.stopPropagation();
    ReactTooltip.hide();
    if (val.Offeringcount > 0) {
      if (String(val.OrgEntityID) !== String(this.state.tooltipclientid)) {
        /// TO PREVENT EVENTBUBBLING
        this.setState(
          {
            tooltipclientid: val.OrgEntityID,
            OfferingData: [],
          },
          () => {
            axiosInstance
              .get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
                params: { OrgEntityID: val.OrgEntityID, LanguageID: this.props.languageData.id },
              })
              .then((response) => {
                console.log('OFFERING RESPONSE DATA:', response.data);
                this.setState({
                  Nooffering: false,
                  OfferingData: response.data,
                });
              })
              .catch((error) => {
                trycatchAlertPopup(error);
              });
          },
        );
      }
    } else {
      this.setState({
        tooltipclientid: val.OrgEntityID,
        OfferingData: [],
        Nooffering: true,
      });
    }
  };

  checkIfPulsePageAccessAllowed = () => {
    const { roleData } = this.props;
    const foundPermissionObj = _.find(roleData, { FeatureName: 'PulsePage' });

    let hasAccessPermission = false;
    if (!_.isEmpty(foundPermissionObj)) {
      const allowedOperations = foundPermissionObj.Operations;

      // ACCESS WOULD ONLY BE ALLOWED IF THE USER HAS EITHER "VIEW" OR "EDIT" PERMISSION
      if (allowedOperations.indexOf('View') || allowedOperations.indexOf('Edit')) {
        hasAccessPermission = true;
      }
    }

    return hasAccessPermission;
  };

  handleImageError = (e) => {
    e.target.src = Client;
  };

  render() {
    //let FavouriteClients = this.state.FavouriteClients.filter(client => {
    //  if (client.OrgEntityName.toLowerCase().indexOf(this.state.search.toLowerCase().trim()) !== -1) {
    //      return client;
    //  }
    //  return null;
    //});
    const { show, showMapping } = this.state;

    return (
      <>
        <div className="main-component">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="px-40">
                  {/* selectbar */}
                  {/* <h2><Trans i18nKey="welcomeMsg" /></h2>
 
                                <Trans i18nKey="nested.startMsg" /> */}
                  <nav className="navbar search-nav navbar-expand-lg p-0 mt-2">
                    <div className="collapse navbar-collapse">
                      <ul className="navbar-nav search-nav">
                        <li
                          selection="[object Object]"
                          className={this.state.isAllClientsClicked ? 'active nav-item' : 'nav-item'}
                          data-toggle="tab"
                          onClick={this.onTabClick.bind(this, 'All')}
                        >
                          <a className="nav-link">{i18n.t('All_Clients')}</a>
                        </li>
                        <li
                          selection="[object Object]"
                          className={this.state.isFavouritesClicked ? 'active nav-item' : 'nav-item'}
                          data-toggle="tab"
                          onClick={this.onTabClick.bind(this, 'Favourites')}
                        >
                          <a className="nav-link">{i18n.t('Favourites')}</a>
                        </li>
                      </ul>
                      <div className="ml-auto search-alpha">
                        {/* <div class="nav-item">
                                              <a onClick={this.handleShow} class="nav-link noAfter">SynOps Demo</a>
                                          </div>*/}

                        <div className="nav-item searchFilter">
                          <input
                            type="search"
                            value={this.state.search}
                            onChange={this.searchchange}
                            ref={this.inputRef}
                          />
                        </div>

                        <div className="nav-item dropdown mr-0 client-dropdown">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Client Projects
                          </a>

                          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <a className="dropdown-item" onClick={this.addClientProjectShow}>
                              <i className="far fa-plus-circle" />
                              {' '}
                              Add Client Project
                            </a>
                            <a className="dropdown-item" onClick={this.viewClientProject}>
                              <i className="fal fa-list-ul" />
                              {i18n.t('View Client Projects')}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </nav>

                  <div className="client-card">
                    <div className="clientgrouping-list">
                      <h5>{i18n.t(this.state.groupFilterDisplayName)}</h5>
                      <ul>
                        {this.state.groupFilterValues ? (
                          <div>
                            <li
                              className={
                                this.state.groupSelectedFilterValues == this.getDefaultFilterValue() ? 'active' : ''
                              }
                              onClick={(e) => this.groupByClient(this.state.groupFilterType, this.getDefaultFilterValue())}
                            >
                              {' '}
                              {i18n.t('All')}
                              {' '}
                              {i18n.t(this.state.groupFilterDisplayName)}
                            </li>
                            {this.state.groupFilterValues.map((clientval) => (
                              <li
                                className={this.state.groupSelectedFilterValues == clientval ? 'active' : ''}
                                onClick={(e) => this.groupByClient(this.state.groupFilterType, clientval)}
                              >
                                {clientval}
                              </li>
                            ))}
                          </div>
                        ) : (
                          ''
                        )}
                      </ul>
                    </div>
                    <div className="card-row client-listing client-list-offering">
                      {this.filterClient
                        ? this.filterClient.map((clientval, index) => (
                          <div key={index} className="card" onClick={(e) => this.onSelectHandle(clientval)}>
                            <LazyLoadComponent>
                              <div className="card-body">
                                <a>
                                  <div className="card-text">
                                    {String(clientval.OrgEntityImage) !== ''
                                        && !_.isNil(clientval.OrgEntityImage) ? (
                                          <img
                                            loading="lazy"
                                            src={`${LocalApiBaseUrlImage}${clientval.OrgEntityImage}`}
                                            width="72"
                                            height="72"
                                            alt=""
                                            onError={this.handleImageError}
                                          />
                                      ) : (
                                        <img src={Client} alt="client title" width="74" height="74" />
                                      )}
                                    <span className="card-title">{clientval.OrgEntityName}</span>
                                  </div>
                                  <div className="card-footer">
                                    <div className="total-offerings">
                                      <p>
                                        Offerings:
                                        {' '}
                                        <span
                                          className="offering-count"
                                          data-tip
                                          data-for="offering-tooltip"
                                          data-event="click"
                                          onMouseEnter={(e) => this.onSelectHandleOffering(e, clientval)}
                                        >
                                          <span>{clientval.Offeringcount}</span>
                                        </span>
                                      </p>
                                    </div>
                                    <button
                                      className="button favorite-button"
                                      data-action="favoriteToggle"
                                      onClick={this.temp.bind(this, clientval)}
                                    >
                                      {clientval.IsFavourite === true ? (
                                        <i className="fas fa-star active" aria-hidden="true" />
                                      ) : (
                                        <i className="fas fa-star inactive" aria-hidden="true" />
                                      )}
                                    </button>
                                  </div>
                                </a>
                              </div>
                            </LazyLoadComponent>
                          </div>
                        ))
                        : ''}

                      <ReactTooltip
                        delayShow={1000}
                        id="offering-tooltip"
                        type="light"
                        effect="solid"
                        globalEventOff="click"
                        clickable
                        place="right"
                      >
                        {/* <LoadingOverlay active={this.state.OfferingData.length===0} spinner text="Loading SynOps..."> */}
                        <div className="offering-tooltip">
                          <div className="offeringtooltip-heading">
                            <h6>Business</h6>
                          </div>
                          <div className="offeringtooltip-body">
                            {!this.state.Nooffering ? (
                              <ul>
                                {this.state.OfferingData.map((b, index) => (
                                  <li>
                                    <img
                                      src={`${LocalApiBaseUrlImage}${b.ChildOfferings[0].OfferingImage}`}
                                      alt=""
                                      width="20"
                                      height="20"
                                    />
                                    <div>
                                      <p>{b.OfferingName}</p>
                                      <span>
                                        <b>{b.ChildOfferings.length}</b>
                                        {' '}
                                        Sub-Offerings
                                      </span>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              'No Offering'
                            )}
                          </div>
                        </div>
                        {/* </LoadingOverlay> */}
                      </ReactTooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <Modal show={this.state.show} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Synops Demo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='demoPass text-center'>
                                <p>Click <a href='https://accentureoperations.invisionapp.com/share/SUX6TO1VR2P' target='_blank'>here</a> for Demo</p>
                                <p className='mb-2'><b>Password for Demo: </b>AccentureSynOps</p>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className='modal-btn'>
                                <Button variant="primary" size='sm' onClick={this.handleClose}>Ok</Button>
                            </div>
                        </Modal.Footer>
                    </Modal>*/}
        </div>

        {show && <ClientBoardingPopup childToParent={this.childToParent} />}

        {showMapping && (
          <AddMappingForm
            childToParent={this.childToParentForMapping}
            showMapping={this.state.showMapping}
            showDrafts={this.state.showDrafts}
            dealInfo={this.state.dealInfo}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  languageData: { ...state.CurrentFilter.languageData },
  roleData: state.roleReducers.roleReducers,
  CurrentFilter: { ...state.CurrentFilter },
});
const mapDispatchToProps = (dispatch) => ({
  savePulseData: (data) => {
    dispatch({ type: 'PULSE_DATA', payload: data });
  },
  saveLangData: (data) => {
    dispatch({ type: 'LANGUAGE_DATA', payload: data });
  },
  saveClientData: (data) => {
    dispatch({ type: 'SELECTED_CLIENT', payload: data });
  },
  saveCommentHistoryId: (data) => {
    dispatch({ type: 'SELECTED_METRIC_COMMENT_HISTORY', payload: data });
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientSelect);
