import React, { Component } from 'react';
import { LocalApiBaseUrl, getMonthNumber, getPrevMonth,LocalApiBaseUrlImage } from '../Shared/Constant';
import { AgGridReact, Ag } from 'ag-grid-react';
import { store } from "../reducers/configureStore"
import Axios from 'axios';
// import axiosInstance from '../Shared/interceptor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Biglogo from '../Images/SynOps-logo.png';
import logo from '../Images/logo-mb.png';
import Personeone from '../Images/photo-1.jpg';
import Persontwo from '../Images/photo-2.jpg';
import Personthree from '../Images/photo-3.jpg';
import logoone from '../Images/logo-1.jpg';
import logotwo from '../Images/logo-2.png';
import logothree from '../Images/logo-3.png';
import Thumbnail1 from '../Images/thumb-1.jfif';
import Thumbnail2 from '../Images/thumb-2.jfif';
import Thumbnail3 from '../Images/thumb-3.jfif';
import Thumbnail4 from '../Images/thumb-4.jfif';
//import 'jquery/dist/jquery.min.js'
// import Carousel from 'react-bootstrap/lib/Carousel';
//'bootstrap/dist/js/bootstrap.min.js'
//import Thumbnail5 from '../Images/thumb-5.jfif';
//import Thumbnail6 from '../Images/thumb-6.jfif';
// axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;
// 


export default class SynopsHomePage extends Component {
    constructor(props) {

        super(props)

        this.state = { clientHide:false
        }


    }
        
    render() {

        return (
            <div>

                <div className="business-filter padding-big">
                    <div className="container-fluid">

                        <section className="py-md-8">
                                <div className = "row">
                                <div className="col-md-8 col-lg-8">
                                    <h1 className="display-3">Welcome to <span className="text-primary">SynOps</span>.</h1>
                                    <p className="lead">
                                        People, Technology, Data and Intelligence, working together—like never imagined before.</p>
                                    <h2 className="display-4">WHAT IS IT?</h2>
                                    <p className="lead">
                                        <span className="text-primary">SynOps</span> is an innovative human-machine operating ‘engine’ that orchestrates the optimal synergy of data,
                                         applied intelligence and digital technologies, to power our exceptional industry and functional talent
                                          to help organizations transform business operations, create exceptional user experiences and deliver
                                           results previously unimaginable.</p>
                                    <div className="row">
                                        <div className="col-md-12 button-section">
                                            <a href="https://in.accenture.com/accentureoperations/SynOps/" className="btn btn-primary"  target="_blank">
                                                View More </a>
                                       </div>
                                    </div>
                                </div>
                                <div className=" col-md-4 col-lg-4">
                                    <div><img src={LocalApiBaseUrlImage+"/SOImages/812161_SynOps_Gradient-Clover.jpg"} alt="SynOps large logo" /></div>
                                </div>
                                </div>
                        </section>
                    </div>
                </div>
                <div className="business-filter padding-big">
                <div className="container-fluid">

    <section className="py-md-8">
    <div className="col-md-12">
    <h2 className="display-4">WHAT DOES IT DO?</h2>
                                    <p className="lead">
                                    For our clients, the ultimate destination is to run <a target="_blank" href="https://www.accenture.com/us-en/insight-intelligent-operations"><span className="text-primary">intelligent operations</span></a>, where the right set 
                                    of streamlined processes, automation and analytics are applied and orchestrated appropriately. <strong>SynOps</strong> enables Intelligent Operations. <strong>SynOps</strong> provides the functional architecture and core
                                      capabilities, to provide organizations with a fit-for-purpose and flexible roadmap for achieving
                                       higher levels of efficiency and effectiveness across the enterprise and accelerate their journey 
                                       to <a target="_blank" href="https://www.accenture.com/us-en/insight-intelligent-operations"><span className="text-primary">intelligent operations.</span></a>
                     
                       </p>
                       <p className="lead">
                       SynOps enables companies to make their organizations more flexible, agile and responsive, helping them generate 
                       additional value by driving growth and scaling operations quickly. SynOps’ creates compound intelligence from 
                       emerging technologies powered by human expertise to deliver better insights, which lead to better-informed decisions, stronger outcomes—and results previously unimaginable.
                       </p>

                       <p className="lead">
                       Here’s just a few examples of the transformational business outcomes SynOps is delivering for clients, today:
                       </p>

                       <ul>
                           <li>We’ve helped a global Energy Company reduce the time it takes to research new services from 6 weeks, to just 5 days.</li>
                           <li>We’ve helped a global hotel chain to reduce the time it takes to process an invoice from 15 days, to 1.</li>
                           <li>We’ve helped a high-tech company increase its revenue 11 times over, in just 4 months.
</li>
                       </ul>
                       <h2 className="display-4">HOW DOES IT WORK?</h2>
                       <p className="lead">
                       SynOps harnesses data and insights from more than 1,000 Accenture client engagements and hundreds of
                        years of cumulative expertise across business functions, industries and domains. It plugs into and 
                        synthesizes system data from clients’ existing systems, and draws on external data to provide benchmarks, 
                        to show where there is high impact opportunity for business process reinvention. It applies advanced AI,
                         analytics, workflow management intelligence and delivers insights that lead to better, faster decision-making
                          at scale.
                       </p>
                       <p className="lead">
                           <strong>The client outcome is a commitment from Accenture to monetize break-through business outcomes that are made possible by SynOps.</strong>
                       </p>
    </div>
        </section>

        </div>
                </div>
                {/* <div className="business-filter">
                    <div class="container-fluid">

                        <div class="col-md-8">

                            <section class="py-md-8">
                                <div class="row">
                                    <div class="section-heading col-md-12">
                                        <h2 class="display-4">Leadership Message</h2>
                                    </div>
                                </div>
                                <Carousel>
                                    <Carousel.Item>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="card">
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <img src={Personeone} alt="First slide" />
                                                        </div>
                                                        <div class=" col-md-7">
                                                            <div class="text-center meassge">
                                                                <p class="lead">"Accenture Operations is leading the industry agenda for Intelligent Automation.
                                                                    This requires us to constantly learn, lead and evolve in our thought leadership.
                                                                    RoboXchange is a platform for to connect, collaborate, and co-create leading RPA practices.
                                        Come join us!! Stay Tuned !!"</p>
                                                                <p class="blockquote-footer">
                                                                    <span class="text-uppercase">-Russ D'Sa</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div class="row">
                                            <div class="">
                                                <div class="card">
                                                    <div class="row">
                                                        <div class=" col-md-5">
                                                            <img src={Persontwo} alt="Second sLide" />
                                                        </div>
                                                        <div class="col-md-7">
                                                            <div class="text-center meassge">
                                                                <p class="lead">"Warm welcome to the Intelligent Workforce enabling clients achieve key business outcomes
                                                                by leveraging facets of the Intelligent Automation Continuum starting w/ RPA! Be an innovator
                                        and invest in delivering the future for our clients as well as shaping our vision for the future"</p>
                                                                <p class="blockquote-footer">
                                                                    <span class="text-uppercase">-Russ D'Sa</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div class="row">
                                            <div class="">
                                                <div class="card">
                                                    <div class="row">
                                                        <div class=" col-md-5">
                                                            <img src={Personthree} alt="Third slide" />
                                                        </div>
                                                        <div class="col-md-7">
                                                            <div class="text-center meassge">
                                                                <p class="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                                                                <p class="blockquote-footer">
                                                                    <span class="text-uppercase">-Russ D'Sa</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                </Carousel>


                            </section>

                            <section class="py-md-8">


                                <div class="row">
                                    <div class="col-md-12 section-heading">
                                        <h2 class="display-4">SynOps Key Contacts</h2>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-3 col-md-3">
                                        <div class="img-fluid contacts">
                                            <img src={Thumbnail1} alt="" />
                                            <span class="Name"><a href="#">Deepak Mathur</a></span>
                                            <span class="designation">NA - RPA Process Lead</span>
                                            <span class="mail-address"><a href="mailto:deepak.mathur@accenture.com">deepak.mathur@accenture.com</a></span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3 col-md-3">
                                        <div class="img-fluid contacts">
                                            <img src={Thumbnail2} alt="" />
                                            <span class="Name"><a href="#">Deepak Mathur</a></span>
                                            <span class="designation">NA - RPA Process Lead</span>
                                            <span class="mail-address"><a href="mailto:deepak.mathur@accenture.com">deepak.mathur@accenture.com</a></span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3 col-md-3">
                                        <div class="img-fluid contacts">
                                            <img src={Thumbnail3} alt="" />
                                            <span class="Name"><a href="#">Deepak Mathur</a></span>
                                            <span class="designation">NA - RPA Process Lead</span>
                                            <span class="mail-address"><a href="mailto:deepak.mathur@accenture.com">deepak.mathur@accenture.com</a></span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3 col-md-3">
                                        <div class="img-fluid contacts">
                                            <img src={Thumbnail4} alt="" />
                                            <span class="Name"><a href="#">Deepak Mathur</a></span>
                                            <span class="designation">NA - RPA Process Lead</span>
                                            <span class="mail-address"><a href="mailto:deepak.mathur@accenture.com">deepak.mathur@accenture.com</a></span>
                                        </div>
                                    </div>
                                </div>


                            </section>

                        </div>

                        <div class="col-md-4">

                            <section class="py-md-8">

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="highlight-section shadow-lg rounded-lg">
                                            <div class="py-md-8">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <p class="top-header">What's New</p>
                                                        <div class="d-block">
                                                            <h4 class="highlight-heading">FREE DRIVE- UiPath RPA Developer Advanced Certification</h4>
                                                            <p>Deadline Extended ! UiPath RPA developer Advanced Certification is
                                free of charge until 30th September, 2019. <a class="detail" href="#">Click Here</a> - <span class="red">FREE​</span></p>
                                                        </div>
                                                        <div class="d-block">
                                                            <h4 class="highlight-heading">FREE DRIVE- UiPath RPA Developer Advanced Certification</h4>
                                                            <p>Deadline Extended ! UiPath RPA developer Advanced Certification is
                                free of charge until 30th September, 2019. <a class="detail" href="#">Click Here</a> - <span class="red">FREE​</span></p>
                                                        </div>

                                                        <div class="d-block">
                                                            <h4 class="highlight-heading">FREE DRIVE- UiPath RPA Developer Advanced Certification</h4>
                                                            <p>Deadline Extended ! UiPath RPA developer Advanced Certification is
                                free of charge until 30th September, 2019. <a class="detail" href="#">Click Here</a> - <span class="red">FREE​</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </section>

                            <section class="">

                                <div class="row text-center">
                                    <div class="col-md-12">
                                        <div class="card upper-div shadow-lg rounded-lg">
                                            <div class="card-body py-md-8">

                                                <img src={logo} alt="SynOps logo" />

                                                <p class="top-header mb-4">Top Users</p>

                                                <div class="left-side">Madineni, V.</div>
                                                <div class="float-right pr-2">10</div>

                                                <div class="left-side">Berjame, Almarie N</div>
                                                <div class="float-right pr-2">10</div>

                                                <div class="left-side">Panaiyadian, Anusha</div>
                                                <div class="float-right pr-2">10</div>

                                                <div class="left-side">Madineni, V.</div>
                                                <div class="float-right pr-2">10</div>

                                                <div class="left-side">Berjame, Almarie N</div>
                                                <div class="float-right pr-2">10</div>


                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </section>

                            <section class="py-md-8">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card shadow-lg rounded-lg">
                                            <div class="card-body py-md-8">
                                                <div class="row">
                                                    <p class="text-center top-header mb-4">Our Connections</p>
                                                </div>
                                                <div class="row text-center">
                                                    <div class="col-md-4">
                                                        <img src={logoone} alt="connection logo" />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img src={logotwo} alt="connection logo" />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img src={logothree} alt="connection logo" />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>

                    </div>

                </div> */}
            </div>

        )
    }
}