import React from "react";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import _ from 'lodash';
import {
  LocalApiBaseUrl,
  regexForFields,
  regexForTextAreas,
  regexForNumbersOnlyMTC,
  regexForNegativeNumbers,
} from "../Shared/Constant";
import i18n from "i18next";
import { store } from "../reducers/configureStore";
import cloneDeep from "lodash/cloneDeep";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle,faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import AddToolPopUp from "../CommonComponents/AddToolPopUp";
import { MetricValueThousandFormat, thousandSeparatorFormat } from "../Shared/Common";
import DatePicker from "react-datepicker";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";

export default class InsightsDataPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoaderonPage:false,
      isApiLoading:true,
      showCategory: false,
      iscatypedisable: false,
      isEdit: false,
      showaddCategoryType: false,
      showAddToolPopup: false,
      toolsApi: [],
      toolsApiData: [],
      tempNameID: 0, 
      showApproval: false,
      selectDropValue: "",
      selectCattypeValue: "",
      isCheck: true,
      Display: false,
      EditIndex: 0,
      categories: [],
      categoriesData: [],
      CategoryDetails: { CategoryType: "", CategoryName: "" },
      CatNameID: 0,
      CatTypeID: 0,
      numeratorVal: 0,
      denomVal: 0,
      EditIndex: 0,
      isAddCatPopup: false,
      show: this.props.show,
      IsBatDealLevelForOffering: false,
        apivalue: "",

        isNameChanged: false,
        selectValue: this.props.SelectValue,
        Display: false,
        SODetails: [],
        valueConnectorEdit: false,
        allCategoryTypesAndNames: [],
        CategoryNameData: [],
        KpiMetricConnectorID: false,
        BaselineMonthReadOnly: true,
        commentsError: "",
        categoriesLimit:30, // Default,
        kpiData: {
            KPIMappingID: 0,
            FormulaList: [],
            formulaStr: '',
        isRelatorBlock: this.props.isRelatorBlock,
        MetricSeq: null,
        MetricID: 0,
        MetricName: "",
        FrequencyID: 0,
        MetricUnit: "",
        MetricImage: "",
        KPIDesp: null,
        MetricDataID: 0,
        MetricDataDesp: null,
        MetricValue: null,
        RAGStatus: "",
        ApplicationMappingID: 0,
        CustomToolID: 0, 
        ApplicationMappingName: "",
        ProjectName: "",
        UpperThreshold: null,
        LowerThreshold: null,
        BaselineValue:"",
        BaseLineMonth:"",
        TargetValue:"",
        Month: "",
            metDataAttr: [
                {
                    AttributeName:
                        this.props.attributes.length > 0
                            ? this.props.attributes.filter(z => z.ApplicableForDD === false)[0].AttributeName1
                            : "",
                    AttributeValue: ""
                },
                {
                    AttributeName:
                        this.props.attributes.length > 0
                            ? this.props.attributes.filter(z => z.ApplicableForDD === false)[0].AttributeName2
                            : "",
                    AttributeValue: ""
                }
            ],
            MetricDefination: [],
            MetricDefinitionID: '',
      },
      iiData: {
        kpiData: []
      },
      cloneDetails: [],
      cloneData: {},
      MetricNameOptions: [],
      SOCategoryID: "",

      errors: {},

            formIsValid: false,
            valueExist: true,
            metricDefinitionID: 0,
            PulseToolsConfigDetailsID:0,
            CategoryDetails: [],
            IsMultiLevel: false,
            IsAggregation: false,
            ApplicationMappingName: "",
            ProjectName: "",
            selectedApplication: 1// Default Tool is Selected
        };
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
        this.onSequenceChange = this.onSequenceChange.bind(this);
        this.onUnitChange = this.onUnitChange.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.onValDescChange = this.onValDescChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onLowerChange = this.onLowerChange.bind(this);
        this.onUpperChange = this.onUpperChange.bind(this);
        this.onBaselineValueChange = this.onBaselineValueChange.bind(this);
        this.onBaselineMonthChange = this.onBaselineMonthChange.bind(this);
        this.onTargetValueChange = this.onTargetValueChange.bind(this);
        this.maxLengthCheckThreshold = this.maxLengthCheckThreshold.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.onSourceChange = this.onSourceChange.bind(this);
        this.onSourceSystemChange = this.onSourceSystemChange.bind(this);
        this.onOtherSystemChange = this.onOtherSystemChange.bind(this);
        this.handleRejectHide = this.handleRejectHide.bind(this);
        this.handleApproveHide = this.handleApproveHide.bind(this);
        this.getSOCategoryId = this.getSOCategoryId.bind(this);
        this.getKpiMAppingDetails = this.getKpiMAppingDetails.bind(this);
        this.onToolOwnerChange = this.onToolOwnerChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.getGetConnectorMaster = this.getGetConnectorMaster.bind(this);
        this.onLeadingIndustryChange = this.onLeadingIndustryChange.bind(this);
        this.onSimilarIndustryChange = this.onSimilarIndustryChange.bind(this);
        this.maxLengthCheck = this.maxLengthCheck.bind(this);
        let offeringValue = "";
        let value1 = [];
        //MULTI LEVEL 
        this.handleCategoryShow = this.handleCategoryShow.bind(this);
        this.handleApprovalClose = this.handleApprovalClose.bind(this);
        this.handleCategoryClose = this.handleCategoryClose.bind(this);
        this.handleCategoryShow = this.handleCategoryShow.bind(this);
        this.handleCategoryClose = this.handleCategoryClose.bind(this);
        this.handleaddCategoryNameClose = this.handleaddCategoryNameClose.bind(this);
        this.handleaddCategoryClose = this.handleaddCategoryClose.bind(this);
        this.CatTypeSelect = this.CatTypeSelect.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleaddCategoryShow = this.handleaddCategoryShow.bind(this);
        this.handleaddCategoryAdd = this.handleaddCategoryAdd.bind(this);
        this.handleaddCategoryNameClose = this.handleaddCategoryNameClose.bind(this);
        this.handleCategoryEditShow = this.handleCategoryEditShow.bind(this);
        this.editCategoryName = this.editCategoryName.bind(this);
        this.handleCategoryEdit = this.handleCategoryEdit.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.deleteCategoryType = this.deleteCategoryType.bind(this);
        this.handleCategoryAdd = this.handleCategoryAdd.bind(this);
        this.handleCategoryTypeAddShow = this.handleCategoryTypeAddShow.bind(this);
        this.deleteCategoryName = this.deleteCategoryName.bind(this);
    }
   
  callingFunction = async () => {
    await this.getTools();
    await this.getKpiMAppingDetails();

    if (this.props.kpiData) {
      let kpiData = { ...this.props.kpiData };

      let CatTypeID =
        kpiData.CategoryDetails && kpiData.CategoryDetails.length > 0
          ? kpiData.CategoryDetails[0].OfferingMultiLevelCategoryID
          : 0;

      let CatType =
        kpiData.CategoryDetails && kpiData.CategoryDetails.length > 0 ? kpiData.CategoryDetails[0].CategoryType : 0;

      let CategoryNameData =
        CatType && CatType !== ""
          ? this.state.allCategoryTypesAndNames.filter((eachValue) => eachValue.CategoryType == CatType)
          : []; //FETCHING CATEGORY NAME

      let CategoryDetails = this.state.CategoryDetails;
      CategoryDetails.CategoryType = CatType;
      this.setState({
        CategoryNameData: CategoryNameData,
        CategoryDetails: CategoryDetails,
        CatTypeID: CatTypeID,
        iscatypedisable: true,
      });
      let toolnameID = this.state.tempNameID;
      toolnameID =
        this.state.toolsApiData[0] !== undefined
          ? this.props.kpiData.PulseToolsConfigDetailsID
            ? this.props.kpiData.PulseToolsConfigDetailsID
            : 0
          : 0;

      this.setState({
        tempNameID: toolnameID,
      });
    }
  };
async getKpiMAppingDetails() {
    let samp = {};
    let valueConnectorEdit = false;
    let mappedConnectorName;
    this.setState({
      showLoaderonPage:true
  })
    return new Promise((resolve, reject) => {
      axiosInstance
          .get(`${LocalApiBaseUrl}Configurator/GetKpiMapping`, {
              params: {
                  OrgEntityID: this.props.filterDetails.Client,
                  LocationID: this.props.filterDetails.Locations,
                  OfferingID: this.props.filterDetails.process,
                  SOCategoryID: this.state.SOCategoryID,
                  MonthDT: this.props.filterDetails.Month
              }
          })
          .then(response => {
          let mappingData = response.data;
          let cloneData = this.state.cloneData;
          let iskpiConnector = false;
          let isaccentureOwned = false;
          if (mappingData.length > 0) {
            cloneData.FormulaList = mappingData[0].FormulaList;
          }

          if (this.props.kpiData) {
            let mappedConnector = mappingData.filter((x) => x.KpiMappingID == this.props.kpiData.KPIMappingID);

            if (mappedConnector.length > 0) {
              iskpiConnector = mappedConnector[0].KpiMetricConnectorID != 0 ? true : false;
              isaccentureOwned = mappedConnector[0].IsAccentureOwned;
              this.isConnectorMetricDataFound = mappedConnector[0].IsConnectorMetricDataFound;
              this.isSelectedMetricMappedToConnector = mappedConnector[0].IsConnector;
              this.selectedMetricConnectorLastUpdated = mappedConnector[0].ConnectorUpdatedTS;

              if (
                this.props.kpiData &&
                (this.props.kpiData.ConnectorID == undefined ||
                  parseInt(this.props.kpiData.ConnectorID) === 0 ||
                  (this.props.kpiData.ConnectorNames == null || this.props.kpiData.ConnectorNames == undefined))
              ) {
                if (
                  mappedConnector.length > 0 &&
                  mappedConnector[0].ConnectorName != "" &&
                  mappedConnector[0].ConnectorName != null
                ) {
                  mappedConnectorName = mappedConnector[0].ConnectorName;
                  let mappedConnectID = mappedConnector[0].ConnectorID;

                  this.props.kpiData.ConnectorNames = mappedConnectorName;
                  this.props.kpiData.ConnectorID = mappedConnectorName;
                  if (this.state.cloneData) {
                    cloneData.ConnectorNames =
                      mappedConnectorName != null && mappedConnectorName != undefined
                        ? mappedConnectorName
                        : cloneData.ConnectorNames;
                    cloneData.ConnectorID = mappedConnectID > 0 ? mappedConnectID : cloneData.ConnectorID;
                  }
                }
              }
            }

            if (mappedConnectorName != null) {
              valueConnectorEdit = true;
            }
          }

          this.setState(
            {
              MetricNameOptions: response.data,
              valueConnectorEdit,
              allCategoryTypesAndNames: mappingData.length > 0 ? mappingData[0].CategoryList : [],
              cloneData: cloneData,
              KpiMetricConnectorID: iskpiConnector,
              IsAccentureOwned: isaccentureOwned,
              showLoaderonPage:false
            },
            () => {
              resolve();
            }
          );
        }
        ).catch(error=>{
            this.setState({
                showLoaderonPage:false
            })
        })
   
})
  }

  getTools = async () => {
    this.setState({
      isApiLoading:true
    })
    let source = [];
    let param = {
      offeringID: this.props.filterDetails.process,
      OrgEntityID: parseInt(this.props.filterDetails.Client),
      LocationID: parseInt(this.props.filterDetails.Locations),
      SOCategoryID: 4,
    };
    this.setState({
      showLoaderonPage:true
  })
    return new Promise((resolve, reject) => {
      axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetMergedCustomTools`, { params: param }).then((response) => {
        source = response.data;
        let sourceValues = source.map((x) => (
          <option key={x.PulseToolsConfigDetailsID} value={x.PulseToolsConfigDetailsID}>
            {x.ApplicationName}
          </option>
        ));
        this.setState(
          {
            toolsApi: sourceValues,
            toolsApiData: source,
            showLoaderonPage:false,
            isApiLoading:false
          },
          () => {
            resolve();
          }
        );
      })
      .catch((error) => {
        this.setState({
          showLoaderonPage:false,
          isApiLoading:false
        })
        trycatchAlertPopup(error);
        reject(error);
      });

    });
  };
  getCategoriesLimit = () =>{
    let data = store.getState().CurrentFilter.multiLevelCategoriesLimit;
    this.setState({
      categoriesLimit:data
    })
}

  componentDidMount() {
    var data = store.getState();

    this.getCategoriesLimit()

    this.setState({
      IsBatDealLevelForOffering: data.CurrentFilter.IsBatDealLevel,
    });
    let attributeLabelsForBO =
      this.props.attributes.length > 0 ? this.props.attributes.filter((z) => z.ApplicableForDD === false) : [];

    this.getGetConnectorMaster();
    this.getSOCategoryId();

    // this.getKpiMAppingDetails();

    if (this.props.tool) {
      this.setState({
        BaselineMonthReadOnly:this.props.kpiData.BaselineValue?false:true,
        toolOptions: this.props.tool.map((dropValue, index) => {
          return (
            <option key={dropValue.PulseToolsConfigDetailsID} selected="" value={dropValue.PulseToolsConfigDetailsID}>
              {dropValue.ApplicationName}
            </option>
          );
        }),
      });
    }

    this.getSOCategoryId();
    this.setState({ IsBatDealLevelForOffering: data.CurrentFilter.IsBatDealLevel }, () => {
      if (this.props.kpiData) {
        let cloneDetails = new Array();
        cloneDetails[0] = {
          KPIMappingID: this.props.kpiData.KPIMappingID,
          MetricSeq: this.props.kpiData.MetricSeq,
          MetricName: this.props.kpiData.MetricName,
          MetricUnit: this.props.kpiData.MetricUnit,
          MetricValue: this.props.kpiData.MetricValue,
          RAGStatus: this.props.kpiData.RAGStatus,
          UpperThreshold: this.props.kpiData.UpperThreshold,
          LowerThreshold: this.props.kpiData.LowerThreshold,
          LeadingPractice: this.props.kpiData.LeadingPractice,
          BaselineValue:this.props.kpiData.BaselineValue,
          BaseLineMonth:this.props.kpiData.BaseLineMonth,
          TargetValue:this.props.kpiData.TargetValue,
          NumeratorDescription: this.props.kpiData.NumeratorDescription,
          DenominatorDescription: this.props.kpiData.DenominatorDescription,
          Comments: this.props.kpiData.Comments,
          Month: this.props.kpiData.Month,
          KPIDesp: this.props.kpiData.KPIDesp,
          MetricDataDesp: this.props.kpiData.MetricDataDesp,
          ConnectorID: this.props.kpiData.ConnectorID,
          ConnectorNames: this.props.kpiData.ConnectorNames,
          SourceFieldName: this.props.kpiData.SourceFieldName,
          SourceName: this.props.kpiData.SourceName,
          IsAccentureOwned: this.props.kpiData.IsAccentureOwned,
          isRelatorBlock: this.props.isRelatorBlock,
          metDataAttr: this.props.kpiData.metDataAttr,
          MetricDefination: this.props.kpiData.MetricDefination,
          MetricDefinitionID: this.props.kpiData.MetricDefinitionID ? this.props.kpiData.MetricDefinitionID : null,
          ConfigurationDealMetricID: this.props.kpiData.ConfigurationDealMetricID,
          ApplicationMappingID: 0,
          PulseToolsConfigDetailsID:this.props.kpiData.PulseToolsConfigDetailsID ? this.props.kpiData.PulseToolsConfigDetailsID : 0,
          ApplicationMappingName: this.props.kpiData.ApplicationMappingName,
          CustomToolID: this.props.kpiData.CustomToolID ? this.props.kpiData.CustomToolID : 0,

          ProjectName: this.props.kpiData.ProjectName,
          CategoryDetails: this.props.kpiData.CategoryDetails,
          IsAggregation: this.props.kpiData.IsAggregation,
          IsMultiLevel:
            this.props.kpiData.CategoryDetails && this.props.kpiData.CategoryDetails.length === 0
              ? false
              : this.props.kpiData.IsMultiLevel,
          FormulaList: this.props.kpiData.FormulaList,
        };

        this.setState({
          selectedApplication:
            this.props.kpiData.PulseToolsConfigDetailsID > 0
              ? 1
              : this.props.kpiData.ProjectName != null && this.props.kpiData.ProjectName.length > 0
              ? 2
              : 1,
        });
        var valueExist = true;
        if (!cloneDetails[0].metDataAttr || cloneDetails[0].metDataAttr.length === 0) {
          cloneDetails[0].metDataAttr = [
            {
              AttributeName: attributeLabelsForBO.length > 0 ? attributeLabelsForBO[0].AttributeName1 : "",
              AttributeValue: null,
            },
            {
              AttributeName: attributeLabelsForBO.length > 0 ? attributeLabelsForBO[0].AttributeName2 : "",
              AttributeValue: null,
            },
          ];
          var valueExist = false;
        }

        let cloneData = cloneDeep(cloneDetails);

        this.setState({
          cloneData: cloneData[0],
          valueExist: valueExist,
        });

        this.setState({
          categoriesData: this.props.AllCategoryData,
          errors: {},
          formIsValid: true,
        });
      }
    });
    this.callingFunction();
  }

  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength);
    }
  };

  getSOCategoryId() {
    let iiData = this.state.iiData;
    let errors = {};
    let formIsValidMetric = true;

    var data = store.getState();
    let iiDetails = data.intelligentInsightReducers["insightandintelligence"];
    this.state.isMetricGroupNameChanged = true;
      if (iiDetails.length > 0) {
          var a = iiDetails.filter(
              data => data.SOCategoryName == this.props.SODetails.SOCategoryName
          ).length > 0 ? iiDetails.filter(
              data => data.SOCategoryName == this.props.SODetails.SOCategoryName
          )[0].SOCategoryID : 0;
      }
      this.state.SOCategoryID = a;

  }
    getGetConnectorMaster() {
        let source = [];
        axiosInstance
            .get(`${LocalApiBaseUrl}ConnectorMapping/GetConnectorMaster`)
            .then(response => {
                source = response.data;
                let sourceValues = source.map(x => (
                    <option
                        key={x.ConnectorID}
            /*selected={index == 0 ? "" : ""}*/ value={x.ConnectorID}
                    >
                        {x.ConnectorName}
                    </option>
                ));
                this.setState({ sourceOptions: sourceValues });
            });
    }
  bindAutoAggregationChange = (e) => {
    const cloneData = this.state.cloneData;

    cloneData.IsAggregation = e.target.checked;

    this.setState({
      cloneData: cloneData
    });

    if (cloneData.IsAggregation && !this.state.valueConnectorEdit) {
      this.calculateAggregateValueByFormula();
    }
  };
    calculateAggregateValueByFormula = () => {
        const cloneData = this.state.cloneData;
        const metricUnit = cloneData.MetricUnit;
        let temp = 0;
        let formulaStr = '';
        if (!cloneData.FormulaList)
            cloneData.FormulaList = [];
        if (metricUnit.trim() === '%') {
            if (cloneData.FormulaList.length > 0) {
                formulaStr = cloneData.FormulaList.filter(z => z.IsPercentageUnit === true)[0].Formula;


            }

        }
        else {

            if (cloneData.FormulaList.length > 0) {
                formulaStr = cloneData.FormulaList.filter(z => z.IsPercentageUnit === false)[0].Formula;


            }
            cloneData.formulaStr = formulaStr;
            this.setState({
                cloneData: cloneData
            });
        }
        if (cloneData.CategoryDetails && cloneData.CategoryDetails.length > 0) {
            let total = 0;
            let numeratorSum = 0;
            let denominatorSum = 0;

            switch (formulaStr.toUpperCase().trim()) {
                case 'SUM':

                    cloneData.CategoryDetails.forEach(function (arrayItem) {
                        if (!arrayItem.Denominator) {
                            temp = 1;

                            // arrayItem.Denominator = 1;
                            // temp = true;
                        }
                        else {
                            temp = arrayItem.Denominator;

                        }
                        total = total + (arrayItem.NumeratorValue / temp);
                    });

                    break;
                case 'AVERAGE':

                    cloneData.CategoryDetails.forEach(function (arrayItem) {
                        if (!arrayItem.Denominator) {
                            temp = 1;

                            // arrayItem.Denominator = 1;
                            // temp = true;
                        }
                        else {
                            temp = arrayItem.Denominator;

                        }
                        total = total + (arrayItem.NumeratorValue / temp);
                    });
                    total = total / (cloneData.CategoryDetails.length);
                    break;
                case 'WEIGHTED AVERAGE':

                    cloneData.CategoryDetails.forEach(function (arrayItem) {
                        if (!arrayItem.Denominator) {
                            arrayItem.Denominator = 100;
                        }
                        // let individualPercentage = (arrayItem.NumeratorValue / arrayItem.Denominator) * 100;
                        // total = total + individualPercentage; //75+30

                        numeratorSum += parseFloat(arrayItem.NumeratorValue);
                        denominatorSum += parseFloat(arrayItem.Denominator);



                    });

                    total = numeratorSum / denominatorSum * 100;
                    break;
                default:

            }
            if (formulaStr) {
                cloneData.MetricValue = total;
            }

            this.setState({
                cloneData: cloneData

            });

        }

    }
  onLeadingIndustryChange(event) {
    let cloneData1 = this.state.cloneData;
    if (cloneData1.metDataAttr && cloneData1.metDataAttr.length > 0) {
      cloneData1.metDataAttr[0].AttributeValue = event.target.value.trim();
      this.setState({
        cloneData: cloneData1
      });
    }
  }
  onSimilarIndustryChange(event) {
    let cloneData1 = this.state.cloneData;
    if (cloneData1.metDataAttr && cloneData1.metDataAttr.length > 0) {
      cloneData1.metDataAttr[1].AttributeValue = event.target.value.trim();
      this.setState({
        cloneData: cloneData1
      });
    }
  }

  onDescriptionChange(event) {
    let cloneData = this.state.cloneData;

    cloneData.KPIDesp = event.target.value;

    this.setState({
      cloneData: cloneData
    });
  }
  editCategoryName(item) {
    this.setState({
      showCategoryNameEditPopup: true
    });
  }
  removeDuplicates(arr) {
    /*NEW CODE WHICH REMOVES DUPLICATES DATA FROM API */
    const map = new Map();
    arr.forEach((v) => map.set(v.CategoryType, v));
    return [...map.values()];
  }
  //multi level codes BEGINING

    CatTypeSelect(e) {
        /* let errors={}
        errors.CategoryType=""
        this.setState({
            errors:errors
        }) */

        let CatTypeID = e.target.value
        this.setState({ CatTypeID: CatTypeID })

        setTimeout(() => {
            if (CatTypeID == 'addnew') {
                CategoryDetails.CategoryType = ""
                CategoryDetails.CategoryName = ""
                this.setState({
                    errors: {},
                    showCategory: false,
                    showaddCategoryType: true, CategoryDetails: CategoryDetails
                });
            }
            else if (CatTypeID == "0") {//CHANGE2
                CategoryDetails.CategoryType = ""
                CategoryDetails.CategoryName = ""
                this.setState({
                    CatNameID: 0,
                    showaddCategoryType: false,
                    showaddCategory: false,
                    showCategory: true,
                    CategoryDetails: CategoryDetails
                });
            }
            else if (CatTypeID == 'addnewcatname') {
                this.setState({
                    showCategory: false,
                    showaddCategory: true
                });
            }
            else {
                this.setState({
                    showaddCategoryType: false,
                    showaddCategory: false,
                    showCategory: true
                });
            }
        }, 500);


        let CatType = (CatTypeID && CatTypeID != "0" && CatTypeID != "addnew") ? this.state.allCategoryTypesAndNames.filter(
            eachValue => eachValue.ID == CatTypeID
        )[0].CategoryType : [];//FETCHING individual CATEGORY TYPE

        let CategoryDetails = this.state.CategoryDetails
        CategoryDetails.CategoryType = CatType
        this.setState({
            CategoryDetails: CategoryDetails
        });


        let CategoryNameData = (CatType && CatType != "") ? this.state.allCategoryTypesAndNames.filter(
            eachValue => eachValue.CategoryType == CatType
        ) : [];//FETCHING CATEGORY NAME

        this.setState({ CategoryNameData: CategoryNameData })


    }

  handleDropdownChange(e) {
    let CatNameID = e.target.value;
    this.setState({ errors: {}, CatNameID: CatNameID });

    setTimeout(() => {
      if (String(CatNameID) === "addnew") {
        this.setState({
          showCategory: false,
          showaddCategoryType: true,
        });
      } else if (String(CatNameID) === "addnewcatname") {
        if (!this.state.CategoryDetails.CategoryType) {
          //category type is empty

          let error = {};
          error["CategoryType"] = i18n.t("catTypeReqd");

          this.setState({ errors: error, CatNameID: 0 });
        } else {
          CategoryDetails.CategoryName = "";
          this.setState({
            showCategory: false,
            showaddCategory: true,
            CategoryDetails: CategoryDetails
          });
        }
      } else {
        this.setState({
          showaddCategoryType: false,
          showaddCategory: false,
          showCategory: true
        });
      }
    }, 500);
      let CatName = (CatNameID && CatNameID != "0" && CatNameID != "addnewcatname") ? this.state.allCategoryTypesAndNames.filter(
          eachValue => eachValue.ID == CatNameID
      )[0].CategoryName : [];


      let CategoryDetails = this.state.CategoryDetails
      CategoryDetails.CategoryName = CatName
      this.setState({
          CategoryDetails: CategoryDetails
      });
  }

  //called when new category name is added -- on clicking on the add button
  handleaddCategoryAdd() {
    const data = this.state.CategoryDetails;
      const param = {
          "OfferingId": parseInt(this.props.filterDetails.process),
          "CatType": data.CategoryType.toString().trim(),
          "CatName": data.CategoryName.toString().trim(),
          "LocationID": this.props.filterDetails.Locations,
          "SOCategoryID": 4,
          "OrgEntityID": this.props.filterDetails.Client
      };
    let error = {};
    if (!data.CategoryType.trim()) {
      error["CategoryType"] = i18n.t("catTypeReqd");
    }
    if (!data.CategoryName.trim()) {
      error["CategoryName"] = i18n.t("catNameReqd");
    }

    this.setState({ errors: error });

    if (data.CategoryType.trim() && data.CategoryName.trim()) {
      axiosInstance
        .post(`${LocalApiBaseUrl}MultiLevelApproval/SendCategoryTypeNameForApproval`, param, {})
        .then((response) => {
          if (response.data.IsSuccess && response.data.StatusMessage == "Sent For Approval") {
            this.setState({
              showaddCategory: false,
              showaddCategoryType: false,
              showApproval: true
            });
          } else {
            window.alert(response.data.StatusMessage);
            this.setState({ showLoaderOnPage: false });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  }

  handleCategoryAdd() {
    let CategoryDetails = [];
    const cloneData = this.state.cloneData;

    let error = {};

    cloneData.CategoryDetails = cloneData.CategoryDetails === null ? [] : cloneData.CategoryDetails;

    if (!this.state.numeratorVal) {
      error["NumeratorValue"] = i18n.t("numeratorIsReqd");
    }
    else if (!(parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999)) {
      error["NumeratorValueRange"] = i18n.t("addBoErrorM6");
    }
    if (cloneData.MetricUnit.trim() === "%") {
      if (!this.state.denomVal) {
        error["Denominator"] = i18n.t("denominatorIsReqd");
      } else if (parseFloat(this.state.denomVal) === 0) {
        error["Denominator"] = i18n.t("denomCannotZero");
      } else if (!(parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) {
        error["DenominatorValueRange"] = i18n.t("addBoErrorM6");
      }
    } else {
      if (!Number.isNaN(parseFloat(this.state.denomVal))) {
        if (parseFloat(this.state.denomVal) === 0) {
          error["Denominator"] = i18n.t("denomCannotzero");
        } else if (!(parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) {
          error["DenominatorValueRange"] = i18n.t("addBoErrorM6");
        }
      }
    }

    if (String(this.state.CatTypeID) === "0") {
      error["CategoryType"] = i18n.t("catTypeReqd");
    }

    if (String(this.state.CatNameID) === "0") {
      error["CategoryName"] = i18n.t("catNameReqd");
    }
    this.setState({ errors: error });

    if (
      cloneData.MetricUnit.trim() === "%"
        ? this.state.CategoryDetails.CategoryType &&
          this.state.CatTypeID != "0" &&
          this.state.CategoryDetails.CategoryName &&
          this.state.CatNameID != "0" &&
          this.state.numeratorVal &&
          (parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999) &&
          this.state.denomVal &&
          parseFloat(this.state.denomVal) != 0 &&
          (parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)
        : this.state.CategoryDetails.CategoryType &&
          this.state.CatTypeID != "0" &&
          this.state.CategoryDetails.CategoryName &&
          this.state.CatNameID != "0" &&
          this.state.numeratorVal &&
          (parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999) &&
          (!Number.isNaN(parseFloat(this.state.denomVal))
            ? parseFloat(this.state.denomVal) != 0 &&
              parseFloat(this.state.denomVal) >= -999999999999 &&
              parseFloat(this.state.denomVal) <= 999999999999
            : true)
    ) {
      if (
        cloneData.CategoryDetails.filter((cat) => cat.CategoryName === this.state.CategoryDetails.CategoryName)
          .length === 0
      ) {
        let CategoryName = this.state.CategoryDetails.CategoryName;

        let Catid =
          CategoryName && CategoryName !== ""
            ? this.state.CategoryNameData.filter((cat) => cat.CategoryName === CategoryName)[0].ID
            : null;

        if (cloneData.CategoryDetails && cloneData.CategoryDetails.length < this.state.categoriesLimit) {
            cloneData.CategoryDetails.push({
                'CategoryType': this.state.CategoryDetails.CategoryType,
                'CategoryName': this.state.CategoryDetails.CategoryName,
                'NumeratorValue': this.state.numeratorVal,
                'Denominator': this.state.denomVal,
                'ConfigurationDealMetricID': 8,
                'OfferingMultiLevelCategoryID': Catid
            });

          this.setState({
            showCategory: false,
            cloneData: cloneData,
          });
        } else {
          let error = {};
          error["CategoryName"] = `${i18n.t("A_maximum_of")} ${this.state.categoriesLimit} ${i18n.t("category_values_are")}` 

          this.setState({ showCategory: true, errors: error });
        }

        if (cloneData.IsAggregation && !this.state.valueConnectorEdit) {
          //calculate the metric value on the fly when new values added/ edited/deleted
          this.calculateAggregateValueByFormula();
        }
      } else {
        let error = {};
        error["CategoryName"] = i18n.t("duplicateCatName");

        this.setState({ errors: error });
      }
    } else {
      this.setState({
        showCategory: true,
      })
    }
  }
  handleCategoryEdit() {
    let error = {};
    let CategoryDetails = [];
    const cloneData = this.state.cloneData

    if (!this.state.numeratorVal) {
      error["NumeratorValue"] = i18n.t("numeratorIsReqd");
    }
    else if (!(parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999)) {
      error["NumeratorValueRange"] = i18n.t("addBoErrorM6");
    }
    if (cloneData.MetricUnit.trim() === "%") {
      if (!this.state.denomVal) {
        error["Denominator"] = i18n.t("denominatorIsReqd");
      } else if (parseFloat(this.state.denomVal) === 0) {
        error["Denominator"] = i18n.t("denomCannotZero");
      } else if (!(parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) {
        error["DenominatorValueRange"] = i18n.t("addBoErrorM6");
      }
      this.setState({ errors: error });
    } else {
      if (!Number.isNaN(parseFloat(this.state.denomVal))) {
        if (parseFloat(this.state.denomVal) === 0) {
          error["Denominator"] = i18n.t("denomCannotzero");
        } else if (!(parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) {
          error["DenominatorValueRange"] = i18n.t("addBoErrorM6");
        }
      }
    }

    if (!this.state.CategoryDetails.CategoryType) {
      error["CategoryType"] = i18n.t("catTypeReqd");
    }

    if (this.state.CatNameID === "0") {
      error["CategoryName"] = i18n.t("catNameReqd");
    }
           this.setState({ errors: error });

    if (
      cloneData.MetricUnit.trim() === "%"
        ? this.state.CategoryDetails.CategoryType &&
          this.state.CatTypeID != "0" &&
          this.state.CategoryDetails.CategoryName &&
          this.state.CatNameID != "0" &&
          this.state.numeratorVal &&
          (parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999) &&
          this.state.denomVal &&
          parseFloat(this.state.denomVal) != 0 &&
          (parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)
        : this.state.CategoryDetails.CategoryType &&
          this.state.CatTypeID != "0" &&
          this.state.CategoryDetails.CategoryName &&
          this.state.CatNameID != "0" &&
          this.state.numeratorVal &&
          (parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999) &&
          (!Number.isNaN(parseFloat(this.state.denomVal))
            ? parseFloat(this.state.denomVal) != 0 &&
              parseFloat(this.state.denomVal) >= -999999999999 &&
              parseFloat(this.state.denomVal) <= 999999999999
            : true)
    ) {
      let index = this.state.EditIndex;
      if (
        cloneData.CategoryDetails.filter((cat) => cat.CategoryName === this.state.CategoryDetails.CategoryName)
          .length === 0
      ) {
        cloneData.CategoryDetails[index].CategoryName = this.state.CategoryDetails.CategoryName;
        cloneData.CategoryDetails[index].NumeratorValue = this.state.numeratorVal;
        cloneData.CategoryDetails[index].Denominator = this.state.denomVal;
        cloneData.CategoryDetails[index].ConfigurationDealMetricID = 8;
        let Catid =
          cloneData.CategoryDetails[index].CategoryName && cloneData.CategoryDetails[index].CategoryName !== ""
            ? this.state.CategoryNameData.filter(
                (cat) => cat.CategoryName === cloneData.CategoryDetails[index].CategoryName
              )[0].ID
            : null;
        cloneData.CategoryDetails[index].OfferingMultiLevelCategoryID = Catid;

        this.setState({
          cloneData: cloneData
        });
        this.setState({
          showCategory: false
        });
      } else if (
        cloneData.CategoryDetails.filter((cat) => cat.CategoryName === cloneData.CategoryDetails[index].CategoryName)
          .length === 1
      ) {
        if (
          cloneData.CategoryDetails[index].CategoryName.trim() === this.state.CategoryDetails.CategoryName.trim()
            ? false
            : cloneData.CategoryDetails.filter((cat) => cat.CategoryName === this.state.CategoryDetails.CategoryName)
                .length === 1
        ) {
          let error = {};
          error["CategoryName"] = i18n.t("duplicateCatName");

          this.setState({ errors: error });
        } else {
          cloneData.CategoryDetails[index].CategoryName = cloneData.CategoryDetails[index].CategoryName;
          cloneData.CategoryDetails[index].NumeratorValue = this.state.numeratorVal;
          cloneData.CategoryDetails[index].Denominator = this.state.denomVal;
          cloneData.CategoryDetails[index].ConfigurationDealMetricID = 8;
          cloneData.CategoryDetails[index].OfferingMultiLevelCategoryID =
            cloneData.CategoryDetails[index].OfferingMultiLevelCategoryID;

          this.setState({
            cloneData: cloneData
          });
          if (cloneData.IsAggregation && !this.state.valueConnector) {
            //calculate the metric value on the fly when new values added/ edited/deleted
            this.calculateAggregateValueByFormula();
          }
          this.setState({
            showCategory: false
          });
        }
      } else {
        this.setState({
          showCategory: false
        });
      }
    }
  }
    handleCategoryTypeAddShow() {

        this.setState({ errors: {} })

        let CatTypeID = this.state.CatTypeID
        let CategoryDetails = this.state.CategoryDetails
        CategoryDetails.CategoryName = ""
        this.setState({
            CategoryDetails: CategoryDetails,
            showCategory: true,
            isEdit: false,
            iscatypedisable: true,
            CatNameID: 0,
            CatTypeID: CatTypeID,
            numeratorVal: '',
            denomVal: ''
        });

    }

  deleteCategoryType() {

    if(window.confirm(i18n.t("delete_Category_msg")))
    {
    let cloneData = this.state.cloneData;
    cloneData.CategoryDetails = [];
    if (cloneData.IsAggregation && !this.state.valueConnectorEdit) {
      // if there are no categories, clear out the value calculated using the formula
        cloneData.MetricValue = '';
    }

    this.setState({
      CatNameID: 0,
      CatTypeID: 0,
      CategoryNameData: [],
      cloneData: cloneData
    });
}
  }

    handleCategoryEditShow(catNameToEdit) {
        this.setState({ errors: {} })
        var allCategoriesList = this.state.cloneData.CategoryDetails;
        var index = allCategoriesList.findIndex(item => item.CategoryName === catNameToEdit)

        let catnameid = this.state.CategoryNameData.filter(row => row.CategoryName === catNameToEdit)[0].ID
        let CategoryName = this.state.CategoryNameData.filter(row => row.ID === catnameid)[0].CategoryName
        let CategoryDetails = this.state.CategoryDetails
        CategoryDetails.CategoryName = CategoryName
        /*     let numeratorVal=allCategoriesList.filter(item => item.CategoryName ===catNameToEdit)[0].NumeratorValue
        let denomVal=allCategoriesList.filter(item => item.CategoryName ===catNameToEdit)[0].Denominator */
        let numeratorVal = allCategoriesList[index].NumeratorValue
        let denomVal = allCategoriesList[index].Denominator

        this.setState({
      EditIndex: index,
      isEdit: true,
      showCategory: true,
      CatNameID: catnameid,
      CategoryDetails: CategoryDetails,
      CatTypeID: this.state.CatTypeID,
      numeratorVal: numeratorVal,
      denomVal: denomVal
    });
  }

  handleaddCategoryNameClose() {
    this.setState({
      showaddCategory: false,
      errors: {}
    });
  }
  handleApprovalClose() {
    this.setState({ showApproval: false });
  }
  handleaddCategoryClose() {
    this.setState({
      showaddCategoryType: false,
      errors: {}
    });
  }
  handleCategoryClose() {
    this.setState({
      showCategory: false,
      errors: {}
    });
  }
  handleaddCategoryShow() {
    this.setState({ showaddCategory: true });
  }

  bindToolFieldChange = (e) => {
    const id = e.target.id;
    let data = this.state.CategoryDetails;
    switch (id) {
      case "categoryType":
        data.CategoryType = e.target.value;
        break;
      case "categoryName":
        data.CategoryName = e.target.value;
        break;
    }

        this.setState({
            CategoryDetails: data
        });
    }
    onNumeratorChange = (e) => {
        let errors = this.state.errors
        errors.NumeratorValue = ''
        this.setState({ errors: errors })
        if (e.target.value.length <= 12) {
            this.setState({ numeratorVal: e.target.value });
        }
    }


    onDenomChange = (e) => {
        let errors = this.state.errors
        errors.Denominator = ''
        this.setState({ errors: errors })
        if (e.target.value.length <= 12) {
            this.setState({ denomVal: e.target.value });
        }
    }

    deleteCategoryName(catNameToDelete) {
        this.setState({ errors: {} })

        let cloneData = this.state.cloneData;
        var allCategoriesList = this.state.cloneData.CategoryDetails;
        let CatTypeID = this.state.CatTypeID
        let CategoryNameData = this.state.CategoryNameData
        allCategoriesList.splice(allCategoriesList.findIndex(item => item.CategoryName === catNameToDelete), 1)

        if (allCategoriesList.length === 0) {
            cloneData.MetricValue = '';
        }

        this.setState({
            CatNameID: 0,
            CategoryNameData: allCategoriesList.length === 0 ? [] : CategoryNameData,
            CatTypeID: CatTypeID,//CHANGED
            cloneData: cloneData
        });
    if (cloneData.IsAggregation && !this.state.valueConnectorEdit) {
      //calculate the metric value on the fly when new values added/ edited/deleted
      this.calculateAggregateValueByFormula();
    }
  }

  handleClick() {
    const cloneData = this.state.cloneData;
    cloneData.IsMultiLevel = !cloneData.IsMultiLevel;

    if (!cloneData.IsMultiLevel) {
      cloneData.IsAggregation = false;
    }

    this.setState({
      cloneData: cloneData
    });
  }

    handleCategoryShow() {
        this.setState({ errors: {} })
        let CategoryDetails = this.state.CategoryDetails
        CategoryDetails.CategoryType = ""
        CategoryDetails.CategoryName = ""
        this.setState({
            showCategory: true,
            iscatypedisable: false,
            CategoryDetails: CategoryDetails,
            isEdit: false,
            CategoryNameData: [],
            CatNameID: 0,
            CatTypeID: 0,
            numeratorVal: '',
            denomVal: ''
        });
    }

  //MULTI LEVEL END
  onSequenceChange(event) {
    let errors = {};

    this.setState({
      errors: errors
    });
    let cloneData = this.state.cloneData;

    cloneData.MetricSeq = event.target.value;
    this.setState({
      cloneData: cloneData
    });
  }

  onUnitChange(event) {
    let errors = {};

    this.setState({
      errors: errors
    });
    const cloneData = this.state.cloneData;
    cloneData.MetricUnit = event.target.value;
    this.setState({
      cloneData: cloneData
    });
  }
  onValueChange(event) {
    let errors = {};

    this.setState({
      errors: errors
    });
    const cloneData = this.state.cloneData;
    if (event.target.value === "") {
      cloneData.MetricValue = event.target.value;
      this.setState({
        cloneData: cloneData
      });
    } else {
      if (regexForNegativeNumbers.test(event.target.value)) {
        if (event.target.value === "-.") {
          cloneData.MetricValue = "-0.";
        } else {
          cloneData.MetricValue = event.target.value;
        }

        this.setState({
          cloneData: cloneData
        });
      }
    }
  }
  onValDescChange(event) {
    let str = event.target.value;
    let err = this.state.commentsError;
    if (event.target.value) {
      if (regexForTextAreas.test(str)) {        
        if (str && str.length < 300) {
          let cloneData = this.state.cloneData;
          cloneData.Comments = event.target.value;
          err = "";
          this.setState({
            cloneData: cloneData,
            commentsError: err
          });
        } else {
          let cloneData = this.state.cloneData;
          cloneData.Comments = event.target.value;
          err = i18n.t("commentsErrorM1");
          this.setState({
            cloneData: cloneData,
            commentsError: err
          });
        }
      } else {
        let cloneData = this.state.cloneData;
        cloneData.Comments = event.target.value;
        err = i18n.t("commentsErrorM2");
        this.setState({
          cloneData: cloneData,
          commentsError: err
        });
      }
    } else {
      let cloneData = this.state.cloneData;
      cloneData.Comments = event.target.value;
      err = "";
      this.setState({
        cloneData: cloneData,
        commentsError: err
      });
    }
  }

  onStatusChange(event) {
    let errors = {};

    this.setState({
      errors: errors
    });
    const cloneData = this.state.cloneData;
    cloneData.RAGStatus = event.target.value;
    this.setState({
      cloneData: cloneData
    });
  }
  onUpperChange(event) {
    const cloneData = this.state.cloneData;
    if (event.target.value === "") {
      cloneData.UpperThreshold = event.target.value;
      this.setState({
        cloneData: cloneData
      });
    } else {
      if (regexForNumbersOnlyMTC.test(event.target.value)) {
        cloneData.UpperThreshold = event.target.value;
        this.setState({
          cloneData: cloneData
        });
      }
    }
  }

    onLowerChange(event) {
        const cloneData = this.state.cloneData;
        if (event.target.value === "") {
            cloneData.LowerThreshold = event.target.value;
            this.setState({
                cloneData: cloneData
            });
        } else {
            if (regexForNumbersOnlyMTC.test(event.target.value)) {
                cloneData.LowerThreshold = event.target.value;
                this.setState({
                    cloneData: cloneData
                });
            }
        }

    }
    onBaselineValueChange(event){
      const cloneData = this.state.cloneData;
      if(event.target.value==="")
      {
        cloneData.BaselineValue = event.target.value;
        cloneData.BaseLineMonth = "";
          this.setState({
            cloneData: cloneData,
            BaselineMonthReadOnly:true
          })
      }
      else{
          if(regexForNegativeNumbers.test(event.target.value)){
            if(event.target.value === "-."){
                cloneData.BaselineValue  = "-0.";  
            }else{
              cloneData.BaselineValue = event.target.value;
            }
              this.setState({
                cloneData: cloneData,
                BaselineMonthReadOnly:false
              })
          }
      }
    }
    onBaselineMonthChange = (date) => {
      const cloneData = this.state.cloneData;  
       cloneData.BaseLineMonth=date;
      this.setState({
        cloneData: cloneData,
      })
    }
    onTargetValueChange(event){
        const cloneData = this.state.cloneData;
        if(event.target.value==="")
        {
          cloneData.TargetValue = event.target.value;
            this.setState({
              cloneData: cloneData
            })
        }
        else{
            if(regexForNegativeNumbers.test(event.target.value)){
                if(event.target.value === "-."){
                    cloneData.TargetValue  = "-0.";  
                }else{
                  cloneData.TargetValue = event.target.value;
                }
                this.setState({
                  cloneData: cloneData,
                })
            }
        }
    }
    maxLengthCheckThreshold = (object) => {
      if (object.target.value.length > object.target.maxLength) {
       object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
      }
    onSourceChange(event) {
        const cloneData = this.state.cloneData;
        cloneData.ConnectorID = event.target.value;
        var index = event.nativeEvent.target.selectedIndex;
        cloneData.ConnectorNames = event.nativeEvent.target[index].text;
        cloneData.SourceName = "";
        cloneData.SourceFieldName = "";
        this.setState({
            cloneData: cloneData
        });

        // if (cloneData.ConnectorNames == "Manual") {
        //     document.getElementById('select02').disabled = true;
        // }
        // else {
        //     document.getElementById('select02').disabled = false;

        // }
    }

    onSourceSystemChange(event) {
        const cloneData = this.state.cloneData;
        cloneData.SourceFieldName = event.target.value;
        this.setState({
            cloneData: cloneData
        });
    }
  onOtherSystemChange(event) {
    const cloneData = this.state.cloneData;
    cloneData.SourceName = event.target.value;
    this.setState({
      cloneData: cloneData
    });
  }
  onToolOwnerChange(event) {
    const cloneData = this.state.cloneData;
    cloneData.IsAccentureOwned = event.target.checked;
    this.setState({
      cloneData: cloneData
    });
  }

  bindMetricDefinationChange = (e) => {
    let id = e.target.id;
    let errors = {};

    this.setState({
      errors: errors
    });
    const cloneData = this.state.cloneData;
    let ClickableToolID = 0;
    switch (id) {
      case "chckTool":
      case "chckProject":
        this.setState({ selectedApplication: e.target.value });
        break;
      case "ddTool":
        if (e.target.value === "-1") {
          this.setState({
            showAddToolPopup: true,
          });
        } else {
          let statestore = this.state.toolsApiData;
          let customobj =
            e.target.value !== "-1" && e.target.value !== "0"
              ? statestore.filter((row) => row.PulseToolsConfigDetailsID ===parseInt(e.target.value))[0]
              : null;

          if (customobj != null) {
              cloneData.PulseToolsConfigDetailsID = customobj.PulseToolsConfigDetailsID;
              cloneData.ApplicationMappingID = 0;
           ClickableToolID = customobj.PulseToolsConfigDetailsID;

            this.setState({
              tempNameID: ClickableToolID,
            })
            cloneData.ApplicationMappingName = e.target.options[e.target.selectedIndex].text;
          }

          cloneData.ProjectName = "";
        }
        break;
      case "txtProject":
        cloneData.ProjectName = e.target.value;
        cloneData.ApplicationMappingID = 0;
        cloneData.PulseToolsConfigDetailsID = 0;
        cloneData.ApplicationMappingName = "";
        this.setState({
          tempNameID: 0,
        });
        break;
      default:
        break;
    }

    this.setState({
      cloneData: cloneData,
    });
  }

  handleSave(e) {
    if (this.validateForm()) {
      let cloneData = this.state.cloneData;

      cloneData.KPIMappingID = cloneData.KPIMappingID == 0 ? Math.random() : cloneData.KPIMappingID;
      cloneData.ProjectName = cloneData.ProjectName ? cloneData.ProjectName.trim() : "";
      if (this.props.SODetails) {
        this.state.iiData.SOCategoryName = this.props.SODetails.SOCategoryName;
      } else if (this.props.Details) {
      }
      if(cloneData.BaseLineMonth!=null && cloneData.BaseLineMonth!=""){
        var baselinemonth= new Date(cloneData.BaseLineMonth).getFullYear() +
      "-" +
      (new Date(cloneData.BaseLineMonth).getMonth() + 1 < 10
        ? "0" + (new Date(cloneData.BaseLineMonth).getMonth() + 1)
        : new Date(cloneData.BaseLineMonth).getMonth() + 1);
        cloneData.BaseLineMonth=baselinemonth;
        this.setState({cloneData:cloneData})
      }
        this.state.iiData.kpiData = this.state.cloneData;
        let currentData = {
            "ConfiguratorDataModels": { SOCategoryID: this.props.SOCategoryID, kpiData: cloneData }, "FiltersIDsVM": {
                "OrgEntityID": this.props.filterDetails.Client,
                "OfferingID": this.props.filterDetails.process,
                "LocationID": this.props.filterDetails.Locations,
                "MonthDT": this.props.filterDetails.Month
            }
        }
      axiosInstance
        .post(`${LocalApiBaseUrl}Configurator/SaveConfiguratorDealMetrics`, currentData)
        .then((response) => {
          if (response.data.ConfiguratorDataModels.kpiData) {
            axiosInstance
              .get(`${LocalApiBaseUrl}Configurator/GetInsightsIntelligenceQualityScore`, {
                params: {
                  LocationID: this.props.filterDetails.Locations,
                  OrgEntityID: this.props.filterDetails.Client,
                  OfferingID: this.props.filterDetails.process,
                  MonthDT: this.props.filterDetails.Month,
                  LanguageID: store.getState().CurrentFilter.languageData.id,
                },
              })
              .then((response) => {
                if (response.data) {
                  store.dispatch({
                    type: "SAVE_QC_SCORE_OF_EACH_TAB",
                    payload: response.data,
                  });
                }

                this.handleHide(e);
              })
              .catch((error) => {
                trycatchAlertPopup(error);
                this.handleHide(e);
              });
            let iiDataValue =this.state.iiData;

            iiDataValue.kpiData = response.data.ConfiguratorDataModels.kpiData;
            store.dispatch({
              type: "ADD_EDIT_KPI1",
              payload: iiDataValue
            });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error)
          this.handleHide(e);
        });
    }
  }
  handleHide(e) {
    this.setState({ show: false },()=>{
      if (e != undefined) {
        if (e.target.textContent != "×") {
          this.props.onClose(e);
        } else {
          this.props.onClose(e);
        }
      } else {
        this.props.onClose(e);
      }
    });
   
  }
  handleRejectHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }

  passToConfirmation(data) {
    this.props.enteredData(data.PoNumber);
  }

  validateForm() {
    const {cloneData,toolsApiData}=this.state;
    let attributeLabelsForBO = [];
    if (this.state.kpiData.isRelatorBlock) {
      attributeLabelsForBO = this.props.attributes.filter((z) => z.ApplicableForDD === false);
    }
    let errors = {};
    let formIsValid = true;
    let kpiData = this.state.cloneData;
    let err="";
    if(this.state.cloneData.Comments && this.state.cloneData.Comments.length > 300){
      err = i18n.t("commentsErrorM1");
      this.setState({
         commentsError: err
      });
      formIsValid = false;
    }

    let isLeading = kpiData.metDataAttr && kpiData.metDataAttr.length > 0 ? kpiData.metDataAttr[0].AttributeValue : "";
    let isSimilar = kpiData.metDataAttr && kpiData.metDataAttr.length > 0 ? kpiData.metDataAttr[1].AttributeValue : "";


    if (!cloneData["MetricName"]) {
      formIsValid = false;
      errors["MetricName"] = i18n.t("addWoErrorM1");
    }
    if (this.state.commentsError) {
      formIsValid = false;
    }

    if (!cloneData["MetricUnit"]) {
      formIsValid = false;
      errors["MetricUnit"] = i18n.t("Unit_is_required");
    }

    if (!cloneData["MetricValue"] && cloneData["MetricValue"] !== 0) {
      formIsValid = false;
      errors["MetricValue"] = i18n.t("addBoErrorM1");
    } else if (!(cloneData["MetricValue"] >= -999999999999 && cloneData["MetricValue"] <= 999999999999)) {
      formIsValid = false;
      errors["MetricValueRange"] = i18n.t("addBoErrorM6");
    }
    if (!(cloneData["BaselineValue"] >= -99999999 && cloneData["BaselineValue"] <= 99999999)) {
      formIsValid = false;
      errors["BaselineValue"] = i18n.t("Baseline_validation");
    }
    if (!(cloneData["TargetValue"] >= -99999999 && cloneData["TargetValue"] <= 99999999)) {
      formIsValid = false;
      errors["TargetValue"] = i18n.t("target_validation");
    }
    if (cloneData["MetricSeq"]) cloneData["MetricSeq"] = parseInt(cloneData["MetricSeq"], 10);
    if (!cloneData["MetricSeq"]) {
      formIsValid = false;
      errors["MetricSeq"] = i18n.t("addWoErrorM5");
    } else if (!(cloneData["MetricSeq"] >= 0 && cloneData["MetricSeq"] <= 999999)) {
      formIsValid = false;
      errors["MetricSeqRange"] = i18n.t("addWoErrorM6");
    }

    if (!cloneData["RAGStatus"]) {
      formIsValid = false;
      errors["RAGStatus"] = i18n.t("addBoErrorM3");
    }

    if (!cloneData["ConnectorID"]) {
      formIsValid = false;
      errors["ConnectorID"] = i18n.t("Source_is_required");
    }

    if (this.state.cloneData.metDataAttr != null && this.state.cloneData.metDataAttr != undefined) {
      if (!isLeading && this.state.kpiData.isRelatorBlock) {
        formIsValid = false;
        errors["LeadingIndustry"] = attributeLabelsForBO[0].AttributeName1 + " " + i18n.t("is_Required");
      }
      if (!isSimilar && this.state.kpiData.isRelatorBlock) {
        formIsValid = false;
        errors["SimilarIndustry"] = attributeLabelsForBO[0].AttributeName2 + " " + i18n.t("is_Required");
      }

      //start validation for benchmark fields
      // validation for attribute1value and attribute2value
      // if (this.props.isRelatorBlockForOffering) {
      if (this.state.kpiData.isRelatorBlock) {
        if (
          (isLeading && !isLeading.toString().trim() && (isSimilar && !isSimilar.toString().trim())) ||
          (!isLeading && !isSimilar)
        ) {
          // attribute values can never be left empty - fill NA
          formIsValid = false;
          errors["LeadingIndustry"] = i18n.t("AttributeValue_ErrMsg1");
          errors["SimilarIndustry"] = i18n.t("AttributeValue_ErrMsg1");
        }
        if (isLeading && isLeading.trim()) {
          if (Number.isNaN(parseFloat(isLeading.trim()))) {
            // it is a string
            if (isLeading.toLowerCase().trim() !== "na") {
              formIsValid = false;
              // errors["Attribute1Value"] = "NA is the only permitted string value";
              errors["LeadingIndustry"] = i18n.t("AttributeValue_ErrMsg2");
            }
          } else {
            //attribute 2 is a whole number or decimal OR has alphabets and numbers ex. 12efgh3456
            if (!regexForNumbersOnlyMTC.test(isLeading.trim())) {
              formIsValid = false;
              // errors["Attribute1Value"] = "NA is the only permitted string value";
              errors["LeadingIndustry"] = i18n.t("AttributeValue_ErrMsg2");
            }
            if (!(parseFloat(isLeading.trim()) >= 0 && parseFloat(isLeading.trim()) <= 999999)) {
              formIsValid = false;

              // errors["Attribute1Value"] =
              //               `${this.state.attributeLabels.AttributeName1}` +
              //           " value must be in between 0 to 999999";
              errors["LeadingIndustry"] = `${attributeLabelsForBO[0].AttributeName1}` + " " + i18n.t("addBoErrorM2");
            }
          }
        }

        if (isSimilar && isSimilar.trim()) {
          if (Number.isNaN(parseFloat(isSimilar.trim()))) {
            // it is a string
            if (isSimilar.toLowerCase().trim() !== "na") {
              formIsValid = false;
              // errors["Attribute1Value"] = "NA is the only permitted string value";
              errors["SimilarIndustry"] = i18n.t("AttributeValue_ErrMsg2");
            }
          } else {
            //attribute 2 is a whole number or decimal OR has alphabets and numbers ex. 12efgh3456
            if (!regexForNumbersOnlyMTC.test(isSimilar.trim())) {
              formIsValid = false;
              // errors["Attribute1Value"] = "NA is the only permitted string value";
              errors["SimilarIndustry"] = i18n.t("AttributeValue_ErrMsg2");
            }
            if (!(parseFloat(isSimilar.trim()) >= 0 && parseFloat(isSimilar.trim()) <= 999999)) {
              formIsValid = false;

              // errors["Attribute1Value"] =
              //               `${this.state.attributeLabels.AttributeName1}` +
              //           " value must be in between 0 to 999999";
              errors["SimilarIndustry"] = `${attributeLabelsForBO[0].AttributeName2}` + " " + i18n.t("addBoErrorM2");
            }
          }
        }
      }

      //end validation for benchmark fields

      // VALIDATE "METRIC DEFINITION" DROPDOWN FIELD
      if (cloneData.MetricDefination && cloneData.MetricDefination.length > 0 && !cloneData["MetricDefinitionID"]) {
        errors["MetricDefinitionID"] = i18n.t("addBoErrorM5");
        formIsValid = false;
      } 

      if (
        (this.state.selectedApplication == 1) &&
        (parseInt(cloneData["PulseToolsConfigDetailsID"],10) === 0 ||
        !toolsApiData.some(a=>a.PulseToolsConfigDetailsID==parseInt(cloneData["PulseToolsConfigDetailsID"]))
      )) {
        formIsValid = false;
        errors["Tool"] = i18n.t("Tool_is_Required");
      }

      if (
        parseInt(this.state.selectedApplication, 10) === 2 &&
        (_.isEmpty(String(cloneData["ProjectName"]).trim()) || _.isEmpty(cloneData["ProjectName"]))
      ) {
        formIsValid = false;
        errors["Project"] = i18n.t("Project_is_Required");
      }

      if (
        this.state.selectedApplication == 2 &&
        !_.isEmpty(String(cloneData["ProjectName"]).trim()) &&
        !regexForFields.test(String(cloneData["ProjectName"]).trim())
      ) {
        formIsValid = false;
        errors["Project"] = i18n.t("Project_Contains_Characters");
      }

      if (cloneData["IsMultiLevel"]) {
        if (cloneData.CategoryDetails.length === 0) {
          errors["MultiLevel"] = i18n.t("noCategories");
          formIsValid = false;
        }
      }

      if (!isSimilar && this.state.kpiData.isRelatorBlock) {
        formIsValid = false;
        errors["SimilarIndustry"] = attributeLabelsForBO[0].AttributeName2 + " " + i18n.t("is_Required");
      } 
    }
    this.setState({
      errors: errors
    });

    if (formIsValid) {
      this.setState({
        formIsValid: true
      });
    } else {
      this.setState({
        formIsValid: false
      });
    }
    return formIsValid;
  }

  // WOULD BE EXECUTED WHEN THE "METRIC DEFINITION" DROPDOWN WOULD BE CHANGED
    handleChangeMetricDefinition = e => {
        const MetricDefinitionID = e.target.value;
        this.setState((prevState) => ({
            cloneData: {
                ...prevState.cloneData,
                MetricDefinitionID,
            },
        }));
    }
    handleAddToolPopupClose = (e) => {
        this.setState({
            showAddToolPopup: false,
        });
        this.handleHide();
    }

  render() {
    const currentDate = new Date();
    //list of all category types and names for this offering - to be displayed in add/edit popup
    let{isApiLoading,toolsApi}=this.state;
    const allCatList = this.state.allCategoryTypesAndNames;
    const stateKpiData = this.state.cloneData;
    const attributeLabelsForBO = this.props.attributes.filter((z) => z.ApplicableForDD === false);
    let items = [];
    if (stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0) {
      items = stateKpiData.CategoryDetails.map((item, key) => (
        <li>
          <span className="contentName">{item.CategoryName}</span>
          {stateKpiData.MetricUnit.trim() !== "%" && (
            <span className="contentValues">
              <span>
              {thousandSeparatorFormat(item.NumeratorValue) % 1 != 0
                  ? MetricValueThousandFormat(stateKpiData.MetricUnit,item.NumeratorValue,2)
                  : MetricValueThousandFormat(stateKpiData.MetricUnit,item.NumeratorValue,2)}
              </span>
              {item.Denominator && <span> | </span>}
              <span>
              {item.Denominator
                  ? thousandSeparatorFormat(item.Denominator) % 1 != 0
                    ? MetricValueThousandFormat(stateKpiData.MetricUnit,item.Denominator,2)
                    : MetricValueThousandFormat(stateKpiData.MetricUnit,item.Denominator,2)
                  : ""}
              </span>{" "}
            </span>
          )}

          {stateKpiData.MetricUnit.trim() === "%" && (
            <span className="contentValues">
              <span>
              {thousandSeparatorFormat((item.NumeratorValue * 100) / item.Denominator) % 1 != 0
                  ? thousandSeparatorFormat(((item.NumeratorValue * 100) / item.Denominator),2)
                  : thousandSeparatorFormat(((item.NumeratorValue * 100) / item.Denominator),2)}
              </span>{" "}
              {stateKpiData.MetricUnit}
            </span>
          )}

              <div className="item-list-hover"><span><i
                  onClick={() => this.deleteCategoryName(item.CategoryName)}
                  class="far fa-times"> </i>
                  <i onClick={() => this.handleCategoryEditShow(item.CategoryName)}
                      class="fas fa-edit"></i>
              </span>
          </div>
        </li>
      ));
    }

      // FILTER OUT THE METRIC DEFINITIONS THAT ARE "ENABLED"
      const kpiMetricDefinitions = _.get(this.state, 'cloneData.MetricDefination', []);
      const enabledMetricDefinitions = _.filter(kpiMetricDefinitions, eachMetricDefinition => String(eachMetricDefinition.IsEnabled) === 'true');
      const { showLoaderonPage } = this.state
    return (
      <>
        {/* <Header /> */}
        <Modal
          show={this.state.show}
          onHide={this.handleHide}
          className="modal fade bo-configurator-modal enhance"
          size="medium"
          id="dealpopup"
          backdrop="static"
        >
          <LoadingOverlay 
            className="custom-loader" fadeSpeed={0} spinner={<SynopsLoader />} 
            active={showLoaderonPage}></LoadingOverlay>
          <Modal.Header closeButton>
            <h4 className="modal-title" id="dealprofile">
              {i18n.t("Configurator_Insights_Details")}
              {/*{this.props.setCategory}*/}
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <div className="row">
                  <div className="form-group col-lg-7">
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label for="">
                          {i18n.t("Name")} <span className="required"> *</span>
                        </label>

                        <select
                          id="name"
                          disabled={this.props.kpiData}
                          className="form-control"
                          ref="MetricNameDropdown"
                          value={this.state.cloneData.KPIMappingID}
                        >
                                                <option value=""> </option>
                                                {this.state.MetricNameOptions.map(row => {
                                                    return (
                                                        <option value={row.KpiMappingID}>
                                                            {row.MetricName}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                        <span className="errorMsg">{this.state.errors.MetricName}</span>
                      </div>

                                        <div className="form-group col-lg-6">
                                            <label for="">{i18n.t("Unit_of_Measure")}<span className="required"> *</span>  </label>
                                            <input
                          type="text"
                          maxlength="50"
                          readOnly
                          className="form-control"
                          id=""
                          onChange={this.onUnitChange}
                          value={this.state.cloneData.MetricUnit}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label for="">
                          {i18n.t("Sequence")} <span className="required"> *</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id=""
                          onChange={this.onSequenceChange}
                          value={this.state.cloneData.MetricSeq}
                        />
                        <div className="errorMsg">{this.state.errors.MetricSeq}</div>
                        <div className="errorMsg">{this.state.errors.MetricSeqRange}</div>
                      </div>

                      <div className="form-group col-lg-6">
                        <label>
                          {i18n.t("Leading_Practice")}{" "}
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={<Tooltip id="tooltip-top">{i18n.t("addBOMess2")}</Tooltip>}
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </OverlayTrigger>
                        </label>
                        <input
                          type="text"
                          disabled
                          maxLength="50"
                          className="form-control"
                          value={this.state.cloneData.LeadingPractice}
                        />
                        <div className="errorMsg">{this.state.errors.LeadingPractice}</div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-12">
                        <label for="">{i18n.t("Description")} </label>
                        <input
                          maxlength="255"
                          readOnly
                          type="text"
                          className="form-control"
                          id=""
                          onChange={this.onDescriptionChange}
                          value={this.state.cloneData.KPIDesp}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-12">
                        <label>
                          {i18n.t("Metric_Definition")} <span className="required"> *</span>{" "}
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={<Tooltip id="tooltip-top">{i18n.t("addBOMess3")}</Tooltip>}
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </OverlayTrigger>
                        </label>
                        {enabledMetricDefinitions.length > 0 ? (
                          <select
                            value={this.state.cloneData.MetricDefinitionID}
                            onChange={(e) => this.handleChangeMetricDefinition(e)}
                            className="form-control"
                          >
                            <option value="">{i18n.t("Select_Metric_Definition")}</option>
                            {_.map(enabledMetricDefinitions, (eachMetricDefinition) => {
                              return (
                                <option
                                  value={eachMetricDefinition.KPIMetricDefinatoinID}
                                  key={eachMetricDefinition.KPIMetricDefinatoinID}
                                >
                                  {_.isEmpty(eachMetricDefinition.DenominatorDescription)
                                    ? eachMetricDefinition.NumeratorDescription
                                    : `${eachMetricDefinition.NumeratorDescription} / ${eachMetricDefinition.DenominatorDescription}`}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            value={i18n.t("Definition_Not_Available")}
                            disabled
                          ></input>
                        )}

                        <div className="errorMsg">{this.state.errors.MetricDefinitionID}</div>
                      </div>
                    </div>

                    <div className="row border-box">
                      <div className="form-group col-lg-6">
                        <label for="">
                          {i18n.t("Value")} <span className="required"> *</span>
                        </label>
                        {this.state.KpiMetricConnectorID ? (
                          <input
                            type="text"
                            maxlength="12"
                            className="form-control"
                            id=""
                            onChange={this.onValueChange}
                            value={this.props.kpiData.MetricValue}
                            readOnly
                          />
                        ) : (
                          <input
                            type="text"
                            maxlength="12"
                            className="form-control"
                            id=""
                            onChange={this.onValueChange}
                            value={this.state.cloneData.MetricValue}
                            readOnly={stateKpiData.IsAggregation}
                            disabled={this.state.KpiMetricConnectorID}
                          />
                        )}
                        <div className="errorMsg">{this.state.errors.MetricValue}</div>
                        <span className="errorMsg">{this.state.errors.MetricValueRange}</span>
                      </div>
                      <div className="form-group col-lg-6">
                      <label for=""> {i18n.t("Status")}
                      
                      <span className="required"> *</span></label>

                        <select
                          class="form-control"
                          value={this.state.cloneData.RAGStatus}
                          onChange={this.onStatusChange}
                        >
                          <option value="">--{i18n.t("Select")}--</option>
                          <option value="Grey">{i18n.t("Grey")}</option>
                          <option value="Green">{i18n.t("Green")}</option>
                          <option value="Red">{i18n.t("Red")}</option>
                          <option value="Amber">{i18n.t("Amber")}</option>
                        </select>
                        <span className="errorMsg">{this.state.errors.RAGStatus}</span>
                      </div>

                                            <div className="form-group col-lg-6">
                                                <label for="">{i18n.t("Upper_Threshold")}</label>
                                                <input
                                                    type="number"
                                                    maxLength="12"
                                                    onInput={this.maxLengthCheckThreshold}
                                                    className="form-control"
                                                    id=""
                                                    onChange={this.onUpperChange}
                                                    value={this.state.cloneData.UpperThreshold}
                                                />
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <label for="">{i18n.t("Lower_Threshold")}</label>
                                                <input
                                                    type="number"
                                                    maxLength="12"
                                                    onInput={this.maxLengthCheckThreshold}
                                                    className="form-control"
                                                    id=""
                                                    onChange={this.onLowerChange}
                                                    value={this.state.cloneData.LowerThreshold}
                                                />
                                            </div>

                      <div className="form-group col-lg-4">
                        <label for="">{i18n.t("Baseline_Value")}</label>
                        <input
                            type="text"
                            maxLength="12"
                            onInput={this.maxLengthCheckThreshold}
                            className="form-control"
                            id=""
                            onChange={this.onBaselineValueChange}
                            value={this.state.cloneData.BaselineValue}
                         />
                         <div className="errorMsg">
                          {this.state.errors.BaselineValue}
                        </div>
                      </div>

                      <div className="form-group col-lg-4">
                          <label for="">{i18n.t("Baseline_Month")}</label>
                          <div className="datepicker-label-wrapper">
                            <DatePicker
                              className="form-control"
                              id="BaseLineMonth"
                              maxDate={currentDate}
                              dateFormat="MM/yyyy"
                              showMonthYearPicker
                              selected={Date.parse(this.state.cloneData.BaseLineMonth)
                                ? new Date(Date.parse(this.state.cloneData.BaseLineMonth))
                                : null
                              }
                                onSelect={(date) => {
                                  this.onBaselineMonthChange(date);
                                }}
                                readOnly={this.state.BaselineMonthReadOnly}
                                //value={this.state.woData.kpiData.BaseLineMonth}
                            />
                          </div>
                        </div>

                      <div className="form-group col-lg-4">
                        <label for="">{i18n.t("Target_Value")}</label>
                        <input
                            type="text"
                            maxLength="12"
                            onInput={this.maxLengthCheckThreshold}
                            className="form-control"
                            id=""
                            onChange={this.onTargetValueChange}
                            value={this.state.cloneData.TargetValue}
                         />
                         <div className="errorMsg">
                          {this.state.errors.TargetValue}
                        </div>
                      </div>

                      <div className="form-group col-lg-12">
                        <label for="">{i18n.t("Comments")}</label>
                        <textarea
                          rows="4"
                          name="comment"
                          maxlength="300"
                          className="form-control"
                          onChange={this.onValDescChange}
                          value={this.state.cloneData.Comments}
                        />
                        <span className="errorMsg">{this.state.commentsError}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-lg-12">
                        <div class="inline-inputs mt-1">
                          <label className="form-check form-check-inline">
                            <input
                              id="chckTool"
                              className="form-check-input"
                              value="1"
                              type="radio"
                              name="MetricGroup"
                              checked={this.state.selectedApplication == 1}
                              onChange={(e) => {
                                this.bindMetricDefinationChange(e);
                              }}
                            />
                            <span className="checkmark"></span>
                            <span className="form-check-label">{i18n.t("Tool")}</span>
                          </label>
                          <label className="form-check form-check-inline">
                            <input
                              id="chckProject"
                              className="form-check-input"
                              value="2"
                              type="radio"
                              name="MetricGroup"
                              checked={this.state.selectedApplication == 2}
                              onChange={(e) => {
                                this.bindMetricDefinationChange(e);
                              }}
                            />
                            <span className="checkmark"></span>
                            <span className="form-check-label">{i18n.t("Project")}</span>
                          </label>
                        </div>
                        <div class="errorMsg"></div>
                      </div>
                      {this.state.selectedApplication == 1 ? (
                        <div class="form-group col-lg-6">
                          <label for="">
                            {i18n.t("Tool")} <span class="required">*</span>
                            {!isApiLoading && toolsApi.length===0 && 
                            <OverlayTrigger
                              key="top"
                              placement="top"
                              overlay={<Tooltip id="tooltip-top">{i18n.t("Add_Tool_Pulse")}</Tooltip>}
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </OverlayTrigger>
                            }
                          </label>
                          <select
                            class="form-control"
                            id="ddTool"
                            value={this.state.tempNameID}
                            onChange={(e) => {
                              this.bindMetricDefinationChange(e);
                            }}
                          >
                            <option value="0"></option>
                            {toolsApi}

                          </select>
                          <div class="errorMsg">{this.state.errors.Tool}</div>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.selectedApplication == 2 ? (
                        <div className="form-group col-lg-6">
                          <label for="">
                            {i18n.t("Project")} <span class="required">*</span>{" "}
                            <OverlayTrigger
                              key="top"
                              placement="top"
                              overlay={<Tooltip id="tooltip-top">{i18n.t("addBOMess4")}</Tooltip>}
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </OverlayTrigger>
                          </label>
                          <input
                            type="text"
                            id="txtProject"
                            maxLength="100"
                            value={this.state.cloneData.ProjectName}
                            className="form-control"
                            onChange={(e) => {
                              this.bindMetricDefinationChange(e);
                            }}
                          />
                          <div class="errorMsg">{this.state.errors.Project}</div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {this.state.cloneData.isRelatorBlock ? (
                      <div className="row border-box">
                        <div className="form-group col-lg-6">
                                                <label for="">
                                                    {attributeLabelsForBO[0].AttributeName1}{" "}
                                                    <span className="required"> *</span>

                                                    {" "}
                                                    {this.state.IsBatDealLevelForOffering && <OverlayTrigger
                                                        key="top"
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-top">
                                                                {i18n.t("addBMtooltip")}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faInfoCircle} />
                                                    </OverlayTrigger>}

                                                </label>
                          <input
                            type="text"
                            maxLength="6"
                            disabled={!this.state.IsBatDealLevelForOffering}
                            className="form-control"
                            id="leadingIndustryTextBox"
                            onChange={this.onLeadingIndustryChange}
                            value={
                              this.state.cloneData.metDataAttr &&
                              this.state.cloneData.metDataAttr.length > 0 &&
                              this.state.cloneData.metDataAttr[0].AttributeValue
                            }
                          />
                          <div className="errorMsg">{this.state.errors.LeadingIndustry}</div>
                        </div>
                        <div className="form-group col-lg-6">
                                                <label for="">
                                                    {attributeLabelsForBO[0].AttributeName2}{" "}
                                                    <span className="required"> *</span>
                                                    {" "}
                                                    {this.state.IsBatDealLevelForOffering && <OverlayTrigger
                                                        key="top"
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-top">
                                                                {i18n.t("addBMtooltip")}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faInfoCircle} />
                                                    </OverlayTrigger>}

                                                </label>
                          <input
                            type="text"
                            maxLength="6"
                            disabled={!this.state.IsBatDealLevelForOffering}
                            className="form-control"
                            onChange={this.onSimilarIndustryChange}
                            value={
                              this.state.cloneData.metDataAttr &&
                              this.state.cloneData.metDataAttr.length > 0 &&
                              this.state.cloneData.metDataAttr[1].AttributeValue
                            }
                          />

                          <div className="errorMsg">{this.state.errors.SimilarIndustry}</div>
                        </div>
                      </div>
                    ) : null}
                    <div className="row border-box">
                      <div className="form-group col-lg-4">
                        <label for="" className="source-system-padding">
                          {i18n.t("Source_System")} <span className="required"> *</span>
                        </label>

                        {this.state.con ? (
                          <select
                            className="form-control"
                            readOnly
                            onChange={this.onSourceChange}
                            value={this.state.cloneData.ConnectorID}
                          >
                            {this.state.connectorOptions}
                          </select>
                        ) : this.state.KpiMetricConnectorID ? (
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            onChange={this.onSourceChange}
                            value={this.props.kpiData.ConnectorNames}
                          ></input>
                        ) : (
                          <select
                            className="form-control"
                            onChange={this.onSourceChange}
                            value={this.state.cloneData.ConnectorID}
                            disabled={this.state.KpiMetricConnectorID}
                          >
                            <option value=""></option>
                            {this.state.sourceOptions}
                            <option value="-1">{i18n.t("Others")}</option>
                            <option value="-2">{i18n.t("Manual")}</option>
                          </select>
                        )}
                        <div className="errorMsg">{this.state.errors.ConnectorID}</div>
                      </div>
                      <div className="form-group col-lg-4">
                        <label for="">
                          {i18n.t("Source_System_Fields")}
                          <span className="required"></span>
                        </label>

                                            {this.state.con ||
                                                this.state.cloneData.ConnectorNames == "Manual" ? (
                          <input
                            onChange={this.onSourceSystemChange}
                            type="text"
                            maxLength="50"
                            className="form-control"
                            id=""
                            readOnly
                            value={this.state.cloneData.SourceFieldName}
                          />
                        ) : this.state.valueConnectorEdit ? (
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            onChange={this.onSourceChange}
                            value={this.props.kpiData.SourceFieldName}
                          ></input>
                        ) : (
                          <input
                            onChange={this.onSourceSystemChange}
                            type="text"
                            maxLength="50"
                            className="form-control"
                            id=""
                            value={this.state.cloneData.SourceFieldName}
                          />
                        )}
                      </div>
                      <div className="form-group col-lg-4">
                        <label for=""> {i18n.t("Other_Source_System")}</label>
                        {this.state.cloneData.ConnectorNames == "Others" ? (
                          <input
                            onChange={this.onOtherSystemChange}
                            type="text"
                            maxLength="50"
                            className="form-control"
                            id=""
                            value={this.state.cloneData.SourceName}
                          />
                        ) : (
                          <input
                            onChange={this.onOtherSystemChange}
                            type="text"
                            value=""
                            readOnly
                            maxLength="50"
                            className="form-control"
                            id=""
                            value={this.state.cloneData.SourceName}
                          />
                        )}
                        <div className="errorMsg">{this.state.errors.KPIDesp}</div>
                      </div>
                      <div className="form-group col-lg-12 mt-2">
                        <label className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="toolowner"
                            defaultChecked={this.state.cloneData.IsAccentureOwned}
                            onChange={this.onToolOwnerChange}
                            checked={this.state.cloneData.IsAccentureOwned}
                          />
                          <span className="checkmark"></span>
                          <span className="form-check-label">{i18n.t("Accenture_Owned")}</span>
                        </label>
                        <small className="form-text text-muted">
                          <b>{i18n.t("Note")}:</b> {i18n.t("NoteMessage1")}
                        </small>
                      </div>
                    </div>
                  </div>
                  {/* start of multi level UI */}
                  <div className="form-group col-lg-5">
                    <div className="right-wrapper">
                      <div className="catergory-metric-sub">
                        <div className="sub-level">
                          <i className="fas fa-sitemap"></i>
                          {i18n.t("Category_Metric_Sub_Levels")}
                        </div>
                        <div className="toggle-switch">
                          <Form>
                            <Form.Check
                              onClick={this.handleClick}
                              disabled={!stateKpiData.MetricName}
                              checked={stateKpiData.IsMultiLevel}
                              type="switch"
                              id="custom-switch"
                              label=""
                            />
                          </Form>
                        </div>
                      </div>
                                        <div className="metric-sub-info">
                                            <div className={stateKpiData.IsMultiLevel ? 'disable-sub-level-none' : 'disable-sub-level'}>
                                                <i className="fas fa-sitemap"></i>
                                                <p>
                                                    {i18n.t("Enable_to_add_metric_categories_as_its_sub_level")}</p>
                                            </div>
                                            <div className={stateKpiData.IsMultiLevel ? 'enable-sub-level' : 'enable-sub-level-none'}>
                                                <div className="check-box-info">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" checked={stateKpiData.IsAggregation}
                                                            onChange={(e) => { this.bindAutoAggregationChange(e) }}
                                                            id="customCheck" name="example1" />
                                                        <label className="custom-control-label" for="customCheck">
                                                            {i18n.t("Aggregate_category_values_to_metric_value")}
                                                        </label>
                                                    </div>
                          </div>
                          <Modal
                            className="addCategoryModal"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={this.state.showCategory}
                            onHide={this.handleCategoryClose}
                            animation={false}
                            backdrop="static"
                          >
                            <Modal.Header>
                              <Modal.Title id="contained-modal-title-vcenter">
                                {this.state.isEdit ? i18n.t("Edit_Category_Details"):i18n.t("Add_Category_Details")}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                {!this.state.iscatypedisable ? (
                                  <div className="form-group col-lg-12">
                                    <label>
                                      {" "}
                                      {i18n.t("Category_Type")}
                                      <span className="required"> *</span>
                                    </label>

                                    <select
                                      id="name"
                                      className="form-control"
                                      disabled={this.state.isEdit}
                                      onChange={this.CatTypeSelect.bind(this)}
                                      value={this.state.CatTypeID}
                                    >
                                      <option value="0">{i18n.t("Select_CategoryType")}</option>
                                      {this.removeDuplicates(this.state.allCategoryTypesAndNames).map((CT) => {
                                        return (
                                          <option value={CT.ID} key={CT.ID}>
                                            {CT.CategoryType}
                                          </option>
                                        );
                                      })}
                                      <option value="addnew">{i18n.t("Add_New")}</option>
                                    </select>
                                    <div className="errorMsg">{this.state.errors.CategoryType}</div>
                                  </div>
                                ) : (
                                  <div className="form-group col-lg-12">
                                    <label>
                                      {i18n.t("Category_Type")}
                                      <span className="required"> *</span>
                                    </label>
                                    <select
                                      id="name"
                                      className="form-control"
                                      disabled={this.state.iscatypedisable}
                                      onChange={this.CatTypeSelect.bind(this)}
                                      value={this.state.CatTypeID}
                                    >
                                      <option value="0">{i18n.t("Select_CategoryType")}</option>
                                      {this.state.allCategoryTypesAndNames.map((CT) => {
                                        return <option value={CT.ID}>{CT.CategoryType}</option>;
                                      })}
                                      <option value="addnew">{i18n.t("Add_New")}</option>
                                    </select>

                                    <div className="errorMsg">{this.state.errors.CategoryType}</div>
                                  </div>
                                )}
                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t("Category_Name")}
                                    <span className="required"> *</span>
                                  </label>
                                  <select
                                    id="name"
                                    value={this.state.CatNameID}
                                    onChange={this.handleDropdownChange}
                                    className="form-control"
                                  >
                                    <option value="0">{i18n.t("Select_CategoryName")}</option>
                                    {this.state.CategoryNameData.map((CN) => {
                                      return <option value={CN.ID}>{CN.CategoryName}</option>;
                                    })}
                                    <option value="addnewcatname">{i18n.t("Add_New")}</option>
                                  </select>
                                  <div className="errorMsg">{this.state.errors.CategoryName}</div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-lg-6">
                                  <label>
                                    {i18n.t("Numerator_Value")}
                                    <span className="required"> *</span>
                                  </label>
                                  <input
                                    type="number"
                                    maxLength="12"
                                    value={this.state.numeratorVal}
                                    onChange={this.onNumeratorChange}
                                    className="form-control"
                                  />
                                  <span className="errorMsg">{this.state.errors.NumeratorValue}</span>
                                  <span className="errorMsg">{this.state.errors.NumeratorValueRange}</span>
                                </div>
                                <div className="form-group col-lg-6">
                                  <label>
                                    {i18n.t("Denominator_Value")}
                                    {stateKpiData.MetricUnit && stateKpiData.MetricUnit.trim() === "%" && (
                                      <span className="required"> *</span>
                                    )}
                                  </label>
                                  <input
                                    type="number"
                                    maxLength="12"
                                    value={this.state.denomVal}
                                    onChange={this.onDenomChange}
                                    className="form-control"
                                  />
                                  <span className="errorMsg">{this.state.errors.Denominator}</span>
                                  <span className="errorMsg">{this.state.errors.DenominatorValueRange}</span>
                                </div>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <button className="btn btn-default btn-sm mr-2" onClick={this.handleCategoryClose}>
                                {i18n.t("Cancel")}
                              </button>
                              {this.state.isEdit ? (
                                <button className="btn btn-primary" onClick={this.handleCategoryEdit}>
                                  {i18n.t("Update")}
                                </button>
                              ) : (
                                <button className="btn btn-primary" onClick={this.handleCategoryAdd}>
                                  {i18n.t("Add")}
                                </button>
                              )}
                            </Modal.Footer>
                          </Modal>

                          <div className="add-category-infor-here">
                            <div
                              className={
                                stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0
                                  ? "add-metric-head-none"
                                  : "add-metric-head"
                              }
                            >
                              <div type="button" className="btn add-category-btn" onClick={this.handleCategoryShow}>
                                {i18n.t("Add_Category_Details")}
                              </div>
                              <div className="add-sub-level-metrics">
                                <i class="far fa-file-plus"></i>
                                <p>
                                  <span>*</span>
                                  {i18n.t("Add_category_details_as_sub_level_metrics")}
                                </p>
                              </div>
                            </div>

                            {stateKpiData.CategoryDetails &&
                                stateKpiData.CategoryDetails.length > 0 &&
                            <div className="category-header">
                                  <span>
                                    {i18n.t("Category")} -{" "}
                                    {stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0
                                      ? stateKpiData.CategoryDetails[0].CategoryType
                                      : ""}
                                  </span>
                                  <div className="category-actions">
                                  <div className="category-add" onClick={this.handleCategoryTypeAddShow}>
                                  <i class="fal fa-plus-circle mr-3"></i>
                                  </div>
                                  <span onClick={() => this.deleteCategoryType()}>
                                    <i class="fas fa-trash-alt delete-icon-fix"></i>
                                  </span>
                                  </div>
                                </div>
                                }
                            <div
                              className={
                                stateKpiData.CategoryDetails &&
                                stateKpiData.CategoryDetails.length > 0 &&
                                stateKpiData.IsMultiLevel
                                  ? 'category-lists'
                                  : 'category-lists-none'
                              }
                            >
                              <ul>
                                {items}
                              </ul>
                            </div>
                          </div>

                          {/* {Add Category Type} */}
                          <Modal
                            className="addCategoryModal"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={this.state.showaddCategoryType}
                            onHide={this.handleaddCategoryClose}
                            animation={false}
                            backdrop="static"
                          >
                            <Modal.Header>
                              <Modal.Title id="contained-modal-title-vcenter">
                                {i18n.t("Add_Category_Type")}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t("New_Category")}
                                    <span className="required"> *</span>
                                  </label>
                                  <input
                                    id="categoryType"
                                    type="text"
                                    maxLength="100"
                                    className="form-control"
                                    onChange={(e) => {
                                      this.bindToolFieldChange(e);
                                    }}
                                    value={this.state.CategoryDetails.CategoryType}
                                  />

                                  <span className="errorMsg">{this.state.errors.CategoryType}</span>
                                </div>
                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t("Name")}

                                    <span className="required"> *</span>
                                  </label>
                                  <input
                                    id="categoryName"
                                    type="text"
                                    maxLength="100"
                                    className="form-control"
                                    onChange={(e) => {
                                      this.bindToolFieldChange(e);
                                    }}
                                    value={this.state.CategoryDetails.CategoryName}
                                  />

                                  <span className="errorMsg">{this.state.errors.CategoryName}</span>
                                </div>
                                <p className="text-right newlyadded">
                                  {i18n.t("Newly_added_category_type_will_be_sent_for_approval")}
                                </p>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <button className="btn btn-default btn-sm mr-2" onClick={this.handleaddCategoryClose}>
                                {i18n.t("Cancel")}
                              </button>
                              <button className="btn btn-primary" onClick={this.handleaddCategoryAdd}>
                                {i18n.t("Add")}
                              </button>
                            </Modal.Footer>
                          </Modal>

                          {/* {Add Category Name} */}
                          <Modal
                            className="addCategoryModal"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={this.state.showaddCategory}
                            onHide={this.handleaddCategoryNameClose}
                            animation={false}
                            backdrop="static"
                          >
                            <Modal.Header>
                              <Modal.Title id="contained-modal-title-vcenter">
                                {" "}
                                {i18n.t("Add_Category_Name")}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t("Category_Type")}
                                    <span className="required"> *</span>
                                  </label>

                                  <input
                                    type="text"
                                    maxLength="100"
                                    className="form-control"
                                    id=""
                                    value={this.state.CategoryDetails.CategoryType}
                                    disabled
                                  />
                                </div>

                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t("Name")}

                                    <span className="required"> *</span>
                                  </label>

                                  <input
                                    id="categoryName"
                                    type="text"
                                    maxLength="100"
                                    className="form-control"
                                    onChange={(e) => {
                                      this.bindToolFieldChange(e);
                                    }}
                                    value={this.state.CategoryDetails.CategoryName}
                                  />

                                  <div className="errorMsg">{this.state.errors.CategoryName}</div>
                                </div>

                                <p className="text-right newlyadded">
                                  {i18n.t("Newly_added_category_name_will_be_sent_for_approval")}
                                </p>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <button
                                className="btn btn-default btn-sm mr-2"
                                onClick={this.handleaddCategoryNameClose}
                              >
                                {i18n.t("Cancel")}
                              </button>
                              <button className="btn btn-primary" onClick={this.handleaddCategoryAdd}>
                                {i18n.t("Add")}
                              </button>
                            </Modal.Footer>
                          </Modal>

                          {/* Approval Notification */}
                          <Modal
                            className="approvalModal"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={this.state.showApproval}
                            onHide={this.handleApprovalClose}
                            animation={false}
                            backdrop="static"
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>{i18n.t("Approval_Notification")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                {this.state.CatTypeID === "addnew" ? (
                                  <p className="approvalInfo">
                                    {i18n.t("Newly_added_category_type_is_sent_for_approval")}
                                  </p>
                                ) : (
                                  <p className="approvalInfo">
                                    {i18n.t("Newly_added_category_name_is_sent_for_approval")}
                                  </p>
                                )}
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>

                    <div className="errorMsg">{this.state.errors.MultiLevel}</div>
                  </div>

                  {/* end of multi level UI */}
                </div>
              </div>
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">
                <button onClick={this.handleHide} className="btn btn-default btn-sm mr-2">
                  {i18n.t("Cancel")}
                </button>

                <input
                  type="Button"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.handleSave()}
                  value={i18n.t("Update")}
                />
              </div>
            </Modal.Footer>
          }
        </Modal>
        {this.state.showAddToolPopup ? (
          <AddToolPopUp
            show={this.state.showAddToolPopup}
            filter={this.props.filterDetails}
            SOCategoryID={4}
            onClose={this.handleAddToolPopupClose}
            screen={this.props.screen}
            lever={"I&I"}
            getOfferingAPICallback={this.props.getOfferingAPICallback}
            tooldata={this.props.toolDetails}
          />
        ):null}
      </>
    );
  }
}
