import React, {
  useCallback, useEffect, useMemo, useState, useRef,
} from 'react';
import _ from 'lodash';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import $ from 'jquery';
import { store } from '../reducers/configureStore';
import { LocalApiBaseUrl, regexForTextAreas, trycatchAlertPopup } from './Constant';
import axiosInstance from './interceptor';

import SynopsLoader from './SynopsLoader';
import CustomTooltipForAgGrid from './CustomTooltipForAgGrid';

const colors = ['Amber', 'Green', 'Red', 'Grey'];
const CommonKeyMapper = [
  'SOPage',
  'SOPageName',
  'KPIMappingID',
  'SOCategoryID',
  'SOCategoryName',
  'MetricName',
  'MetricUnit',
  'KPIDesp',
];

const commonMapper = [
// "ConfigurationDealMetricID",
// "MetricSeq",
// "MetricUnit_En",
// "MetricImage",
// "MetricDataID",
// "MessagesCount",
// "MetricDataDesp",
// "MetricDataDesp_En",
// "MetricValue",
// "MetricValue_En",
// "CalculatedValue",
// "RAGStatus",
// "Impact",
// "FTEReleased",
// "UpperThreshold",
// "UpperThreshold_En",
// "LowerThreshold",
// "LowerThreshold_En",
// "BaselineValue",
// "BaseLineMonth",
// "TargetValue",
// "Month",
// "Flag",
// "ConnectorID",
// "SourceName",
// "SourceFieldName",
// "OfferingAttribute1Value",
// "OfferingAttribute2Value",
// "Attribute1Value",
// "Attribute1Value_En",
// "Attribute2Value",
// "Attribute2Value_En",
// "IsAccentureOwned",
// "LeadingPractice",
// "LeadingPractice_En",
// "NumeratorDescription",
// "NumeratorDescription_En",
// "DenominatorDescription",
// "DenominatorDescription_En",
// "IndustryName",
// "Comments",
// "Comments_En",
// "MonthDT",
// "IntegrationStatus",
// "MetricDefinitionID",
// "ApplicationMappingID",
// "ToolName",
// "CustomToolID",
// "PulseToolsConfigDetailsID",
// "ApplicationMappingName",
// "CustomApplicationName",
// "PulseToolApplicationName",
// "ProjectName",
// "CreatedBy",
// "CreatedTS",
// "IsMultiLevel",
// "IsAggregation",
// "CategoryDetails",
// "FormulaList",
// "MetricBenchmarkList",
// "SOCategoryName",
// "SourceSystem",
// "ToolProject",
// "ToolProjectName",
/// /

  'Impact',
  'FTEReleased',
  'UpperThreshold',
  'LowerThreshold',
  'BaselineValue',
  'BaseLineMonth',
  'TargetValue',
  'LeadingPractice',
  'NumeratorDescription',
  'DenominatorDescription',
  'ConfigurationDealMetricID',
  'MetricDefinitionID',
  'MetricSeq',
  'SourceSystem',
  'SourceName',
  'SourceFieldName',
  'IntegrationStatus',
  'IsMultiLevel',
  'IsAggregation',
  'IsAccentureOwned',
  'ConnectorID',
  'ToolName',
  'ProjectName',
  'MonthDT',
  'MetricValue',
  'RAGStatus',
  'Comments',
  'ApplicationMappingName',
  'OfferingAttribute1Value',
  'OfferingAttribute2Value',
  'Attribute1Value',
  'Attribute2Value',
  'MetricDefination',
  'metDataAttr',
  'CategoryDetails', 
  'FormulaList',
];

const MetricGridView = (props) => {
  const defaultDefs = [
    {
      headerName: 'Lever',
      field: 'SOPage',
      filter: true,
      editable: false,
    },
    {
      headerName: 'Category Name',
      field: 'SOCategoryName',
      filter: true,
      editable: false,
    },
    {
      headerName: 'Sub Step ( only for WO)',
      field: 'SOSubCategoryName',
      filter: true,
      editable: false,
    },
    {
      headerName: 'Metric',
      field: 'MetricName',
      filter: true,
      editable: false,
    },
    {
      headerName: 'Unit',
      field: 'MetricUnit',
      filter: true,
      editable: false,
    },
    {
      headerName: 'Metric Definition * ',
      field: 'MetricDefinations',
      filter: true,
      editable: false,
    },
    {
      headerName: 'Sequence',
      field: 'MetricSeq_1',
      filter: true,
      editable: false,
    },
    {
      headerName: 'SourceSystem',
      field: 'SourceSystem_1',
      filter: true,
      editable: false,
    },
    {
      headerName: 'IntegrationStatus',
      field: 'IntegrationStatus_1',
      filter: true,
      editable: false,
    },
    {
      headerName: 'Tool(only for I&I)',
      field: 'ToolName_1',
      filter: true,
      editable: false,
    },
    {
      headerName: 'Project Name (only for I&I)',
      field: 'ProjectName_1',
      filter: true,
      editable: false,
    },
  ];

  const indexOfCurrentMonth = props.monthData.indexOf(props.CurrentFilter.SyncFilter.Month, 0);
  const emr = props.monthData.filter((m, i) => indexOfCurrentMonth >= i && indexOfCurrentMonth - i < 3);

  const gridRef = useRef();
  const fromMonthRef = useRef();
  const endMonthRef = useRef();

  const [rowData, setRowData] = useState();
  const [fromMonth, setFromMonth] = useState(props.CurrentFilter.SyncFilter.Month);
  const [endMonth, setEndMonth] = useState(props.CurrentFilter.SyncFilter.Month);
  const [endMonthArr, setEndMonthArr] = useState(emr);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [monthDiff, setMonthDiff] = useState(0);
  const [columnDefs, setColumnDefs] = useState([]);
  // const [gridApi, setGridApi] = useState(null);
  // const [isFormValid, setIsFormValid] = useState(true);
  const [selectedCol, setSelectedCol] = useState('');
  const [copiedValues, setCopiedValues] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [rowForPaste, setRowForPaste] = useState('');
  const [selectedColArr, setSelectedColArr] = useState([]);
  const [copiedMultipleCols, setCopiedMultipleCols] = useState({});

  const [userRoles, setUserRole] = useState(
    props.roleReducers.filter((each) => each.FeatureName === 'Configurator'),
  );

  const defaultColDef = useMemo(() => ({
    flex: 1,
    resizable: true,
    editable: true,
  }), []);

  useEffect(() => {
    // addColumnsToGrid(1);
    updateData();
  }, [updateData]);

  useEffect(() => {
    if ((fromMonthRef.current && fromMonthRef.current !== fromMonth) || endMonthRef.current !== undefined) {
      fromMonthRef.current = fromMonth;
      if (endMonthRef.current !== undefined) {
        endMonthRef.current = endMonth;
      }
      updateData();
    }
    if (fromMonthRef.current == undefined) {
      fromMonthRef.current = fromMonth;
    }
    if (endMonthRef.current == undefined) {
      endMonthRef.current = endMonth;
    }
  }, [fromMonth, columnDefs, endMonth, updateData]);

  

  const updateData = useCallback(async () => {
    const monthDifference = getMonthDiffrence(endMonth);

    let configData = [];

    configData = await getConfiguratorData();

    configData.forEach((kpi) => {
      let page = '';

      switch (kpi.SOPage) {
        case 1:
          page = 'Business Outcomes';
          break;
        case 2:
          page = 'Human + Machine Talent';
          break;
        case 3:
          page = 'Work Orchestration';
          break;
        case 4:
          page = 'Insights & Intelligence';
          break;
        case 5:
          page = 'Diverse Data';
          break;
        default:
          page = '';
      }
      kpi.SOPage = page;
      for (let i = 1; i <= monthDifference; i++) {
        const ssys = props.sourceOptions.filter((j) => (j.key == kpi[`ConnectorID_${i}`]));
       
        if (ssys && ssys[0]) { kpi[`SourceSystem_${i}`] = ssys[0].value; }
        
        let start  = props.monthData.indexOf(fromMonth);
        kpi[`MonthDT_${i}`] = props.monthData[start-i+1]
        kpi[`canBeAdded_${i}`] = kpi[`IntegrationStatus_${i}`] === "Yes" && kpi[`ConnectorID_${i}`] === null ? false : true;
      }
      let metricDef = [];
      let ii = 1;
      if (kpi.ConnectorID_1 != null) {
        metricDef = kpi.MetricDefination_1;
      } else if (kpi.ConnectorID_2 != null) {
        metricDef = kpi.MetricDefination_2;
        ii = 2;
      } else {
        metricDef = kpi.MetricDefination_3;
        ii = 3;
      }
      const currentDefinition = metricDef.filter((j) => j.KPIMetricDefinatoinID === kpi[`MetricDefinitionID_${ii}`]);
      const num = currentDefinition.length > 0 && currentDefinition[0].NumeratorDescription ? currentDefinition[0].NumeratorDescription : '';
      const deno = currentDefinition.length > 0 && currentDefinition[0].DenominatorDescription && currentDefinition[0].DenominatorDescription != '' ? `/${currentDefinition[0].DenominatorDescription}` : '';
      const desc = num + deno;
      kpi.MetricDefinations = desc;

      kpi.MetricSeq_1 = kpi[`MetricSeq_${ii}`]
      kpi.SourceSystem_1 = kpi[`SourceSystem_${ii}`]
      kpi.IntegrationStatus_1 = kpi.IntegrationStatus_1 != null ? kpi.IntegrationStatus_1 : "No"
      kpi.ToolName_1 = kpi[`ToolName_${ii}`]
      kpi.ProjectName_1 = kpi[`ProjectName_${ii}`]

    });

    setRowData(configData);
    addColumnsToGrid(monthDifference);
    setSelectedColArr([]);
    setSelectedCol('')
    setRowForPaste('')
  });

  const getMonthDiffrence = (month) => {
    const start = props.monthData.indexOf(fromMonth, 0);
    const end = props.monthData.indexOf(month, 0);

    return start - end + 1;
  };

  const addColumnsToGrid = (numberOfMonths) => {
    const cols = defaultDefs;
    let m = props.monthData.indexOf(fromMonth);
    gridRef.current.api.setColumnDefs([]);
    for (let i = 1; i <= numberOfMonths; i += 1, m -= 1) {
      const temp = {
        headerName: props.monthData[m],
        children: [
          {
            headerName: 'MetricValue',
            field: `MetricValue_${i}`,
            filter: true,
            editable: (params) => (params.data[`IntegrationStatus_${i}`] !== 'Yes' && params.data[`IsAggregation_${i}`] !== true && params.data[`status_${i}`] != false && params.data[`IsActive_${i}`] != false &&  params.data[`canBeAdded_${i}`]),
            cellClassRules: {
              'editable-style': function (params) {
                return params.data[`status_${i}`] != false && params.data[`IntegrationStatus_${i}`] !== 'Yes' && params.data[`IsAggregation_${i}`] !== true && params.data[`IsActive_${i}`] != false &&  params.data[`canBeAdded_${i}`];
              },
              'error-class': function (params) {
                const num = Number(params.data[`MetricValue_${i}`]);

                const isValueBig = params.data[`MetricValue_${i}`] !== null && params.data[`MetricValue_${i}`].length > 12;
 
                const ragPresent = params.data[`RAGStatus_${i}`] !== null && (params.data[`MetricValue_${i}`] === '' || params.data[`MetricValue_${i}`] === null);

                const commentsPresent = (params.data[`Comments_${i}`] !== null && params.data[`Comments_${i}`] !== '' ) && (params.data[`MetricValue_${i}`] === '' || params.data[`MetricValue_${i}`] === null) ;

                return Number.isNaN(num) || isValueBig || ragPresent || commentsPresent;
              },
            },
          },
          {
            headerName: 'RAGStatus',
            field: `RAGStatus_${i}`,
            cellEditor: 'agSelectCellEditor',
            filter: true,
            cellEditorParams: {
              values: colors,
            },
            editable: (params) => params.data[`status_${i}`] != false && params.data[`IsActive_${i}`] != false && params.data[`canBeAdded_${i}`],
            cellClassRules: {
              'editable-style drop-down-style': function (params) { return params.data[`status_${i}`] != false && params.data[`IsActive_${i}`] != false && params.data[`canBeAdded_${i}`]; },
              'error-class': function (params) {
                const clrs = ['Amber', 'Green', 'Red', 'Grey', null];
                const isMetricNull = params.data[`MetricValue_${i}`] === null || params.data[`MetricValue_${i}`] === '';
                const isRagInValid = !isMetricNull && params.data[`RAGStatus_${i}`] === null;
                return !clrs.includes(params.data[`RAGStatus_${i}`]) || isRagInValid;
              },
            },

          },
          {
            headerName: 'Comments',
            field: `Comments_${i}`,
            cellEditorPopup: (params) => params.data[`status_${i}`] != false && params.data[`IsActive_${i}`] != false && params.data[`canBeAdded_${i}`],
            cellEditor: 'agLargeTextCellEditor',
            editable: (params) => params.data[`status_${i}`] != false && params.data[`IsActive_${i}`] != false && params.data[`canBeAdded_${i}`],
            cellEditorParams: {
              maxLength: 300,
            },
            filter: true,
            cellClassRules: {
              'editable-style': function (params) { return params.data[`status_${i}`] != false && params.data[`IsActive_${i}`] != false && params.data[`canBeAdded_${i}`] },
              'error-class': function (params) {
                if (params.data[`Comments_${i}`] !== null && params.data[`Comments_${i}`] !== '') {
                  return !regexForTextAreas.test(params.data[`Comments_${i}`]);
                }
                return false;
              },
            },
          },
        ],
      };
      // end of temp
      cols.splice(i + 4, 0, temp);
    }

    gridRef.current.api.setColumnDefs(cols);
  };

  const getCurrentMonthConfiguratorData = async (month) => {
    setIsApiLoading(true);

    return new Promise((resolve, reject) => {
      axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetConfiguratorData`, {
        params: {
          OrgEntityID: props.CurrentFilter.ClientData.id,
          LocationID: props.CurrentFilter.Location.LocationId,
          MonthDT: month,
          OfferingID: props.CurrentFilter.SyncFilter.ProcessId,
        },
      })
        .then((response) => {
          setIsApiLoading(false);
          resolve(response.data);
        })
        .catch((error) => {
          setIsApiLoading(false);
          trycatchAlertPopup(error);
          reject();
        });
    });
  };
  const getConfiguratorData = async () => {
    setIsApiLoading(true);

    return new Promise((resolve, reject) => {
      axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetGridConfigData`, {
        params: {
          OrgEntityID: props.CurrentFilter.ClientData.id,
          LocationID: props.CurrentFilter.Location.LocationId,
          OfferingID: props.CurrentFilter.SyncFilter.ProcessId,
          StartDate: fromMonth,
          EndDate: endMonth,
          SOCategoryID: 1,
        },
      })
        .then(async (response) => {
          
          const resData = response.data;

          resData.forEach((each) => {
            for (let i = 1; i <= 3; i++) {
              if (each[`status_${i}`]) {
                const status = each[`status_${i}`][0];

                let ds = '';

                if (status == 'Publish') {
                  // publishStatus = true;
                  ds = 'Published';
                }

                if (status == 'Save') {
                  ds = 'Draft';
                }

                if (status == 'Submit') {
                  ds = 'In Review';
                }

                if (status == 'Review(Implementation)') {
                  ds = 'In Review(Implementation)';
                }

                const submit = userRoles[0].Operations.includes('Submit');
                const publish = userRoles[0].Operations.includes('Publish');
                const approve = userRoles[0].Operations.includes('Approve - Review Stage 1');
                let canEdit = [];
                if (submit) {
                  canEdit = [...canEdit, 'Draft'];
                }
                if (publish) {
                  canEdit = [...canEdit, 'In Review'];
                }
                if (approve) {
                  canEdit = [...canEdit, 'In review Implementation'];
                }

                each[`status_${i}`] = canEdit.includes(ds);
              }
              ///
            }
          });
          setIsApiLoading(false);

          resolve(resData);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          setIsApiLoading(false);
          reject();
        });
    });
  };

  const onFromMonthChange = async (month) => {
    const indexOfCurrentMonth = props.monthData.indexOf(month, 0);
    const endMonthArr = props.monthData.filter((m, i) => indexOfCurrentMonth >= i && indexOfCurrentMonth - i < 3);

    setFromMonth(month);
    setEndMonth(month);
    setEndMonthArr(endMonthArr);
  };

  const onEndMonthChange = (month) => {
    setEndMonth(month);
    const monthDifference = getMonthDiffrence(month);

    setMonthDiff(monthDifference);
  };
  // const onGridReady = async (params) => {
  //   setGridApi(params.api);
  // };
  // function displayNotificationForAllRowsDataValidationFailure() {
  //   alert('Please enter valid Metric Value');
  // }
  // function validateAllRowsDataOnSave() {
  //   const allRowsValidationData = [];
  //   gridApi.forEachNode((rowNode) => {
  //     const currentRowData = _.cloneDeep(rowNode.data);
  //     const fieldsArray = [];
  //     Object.entries(currentRowData).forEach(([key, value]) => {
  //       fieldsArray.push({ fieldName: key, fieldValue: value });
  //     });
  //     const { isValid, validationErrors } = validateFields(fieldsArray);
  //     allRowsValidationData.push({ isValid, validationErrors });
  //   });
  //   const areAllRowsValid = _.isEmpty(_.find(allRowsValidationData, { isValid: false }));
  //   return { areAllRowsValid, allRowsValidationData };
  // }
  // function validateFields(fieldsArray) {
  //   function validateMandatoryField(value) {
  //     let isValid = true;
  //     const num = Number(value);
  //     if ((value === '') || (value === null) || (Number.isNaN(num))  ) {
  //       isValid = false;
  //     }
  //     return { isValid };
  //   }
  //   let isValid = true;
  //   const validationErrors = {};
  //   const createdByEId = _.find(fieldsArray, { fieldName: 'MetricValue' });
  //   if (!_.isEmpty(createdByEId)) {
  //     const { isValid: createdByIsValid, errMsg } = validateMandatoryField(createdByEId.fieldValue);
  //     if (!createdByIsValid) {
  //       isValid = createdByIsValid;
  //     }
  //     _.set(validationErrors, 'MetricValue', errMsg);
  //   }
  //   return {isValid, validationErrors};
  // }

  const gridSaveMultiMonthTransformation = (items = [], months = 3) => items.flatMap((o) => {
    let tempArr = [];

    let index = 1;
    if(o.ConnectorID_1 ){
      index = 1;
    }else if(o.ConnectorID_2){
      index = 2;
    }else{
      index = 3;
    }

    for (let i = 1; i <= months; i++) {
      let mapper = {};

      CommonKeyMapper.forEach((o2) => {
        mapper = {
          ...mapper,
          [o2]: o.hasOwnProperty(o2)
            ? o[o2]
            : o.hasOwnProperty(`${o2}_1`)
              ? o[`${o2}_1`]
              : null,
        };
      });

      const MetricValueKey = i == 1
        ? o.hasOwnProperty('MetricValue')
          ? 'MetricValue'
          : `MetricValue_${i}`
        : `MetricValue_${i}`;
      const RAGStatusKey = i == 1
        ? o.hasOwnProperty('RAGStatus')
          ? 'RAGStatus'
          : `RAGStatus_${i}`
        : `RAGStatus_${i}`;
      const SourceSystemKey = i == 1
        ? o.hasOwnProperty('ConnectorID')
          ? 'ConnectorID'
          : `ConnectorID_${i}`
        : `ConnectorID_${i}`;
      const MonthDTKey = i == 1
        ? o.hasOwnProperty('MonthDT')
          ? 'MonthDT'
          : `MonthDT_${i}`
        : `MonthDT_${i}`;
      const CommentsKey = `Comments_${i}`;

      

      if (o[MetricValueKey] && o[RAGStatusKey] && o[SourceSystemKey]) {
        commonMapper.forEach((o2) => {
          const key = o2;
          const seqKey = `${key}_${i}`;

          mapper = {
            ...mapper,
            [key]:
                i == 1
                  ? o.hasOwnProperty(key)
                    ? o[key]
                    : o[seqKey]
                  : o.hasOwnProperty(seqKey)
                    ? o[seqKey]
                    : null,
          };
        });

        tempArr = [...tempArr, mapper];
      } else if (o[MetricValueKey] && o[RAGStatusKey] && !o[SourceSystemKey]) {
        // We are adding new metric here

        commonMapper.forEach((o2) => {
          const key = o2;
          const seqKey = `${key}_${index}`;
            mapper = {
              ...mapper,
              [key]:
                  i == 1
                    ? o.hasOwnProperty(key)
                      ? o[key]
                      : o[seqKey]
                    : o.hasOwnProperty(seqKey)
                      ? o[seqKey]
                      : null,
            };
        });

        mapper = {
          ...mapper,
          MetricValue: o[MetricValueKey],
          RAGStatus: o[RAGStatusKey],
          MonthDT: o[MonthDTKey],
          Comments: o[CommentsKey],
          IsAggregation: false,
        };

        tempArr = [...tempArr, mapper];
      }
    }
    return tempArr;
  });

   const saveGridData = async () => {
    const list = $('#grid').find('.error-class');
    if (list.length > 0) {
      alert('Please enter valid data in the cell highlighted with red');
      return false;
    }
    setIsApiLoading(true);
    const payload = [];

    gridRef.current.api.forEachNode((node) => {
      const nodeData = _.cloneDeep(node.data);
      let page = '';
      switch (nodeData.SOPage) {
        case 'Business Outcomes':
          page = 1;
          break;
        case 'Human + Machine Talent':
          page = 2;
          break;
        case 'Work Orchestration':
          page = 3;
          break;
        case 'Insights & Intelligence':
          page = 4;
          break;
        case 'Diverse Data':
          page = 5;
          break;
        default:
          page = '';
      }

      nodeData.SOPage = page;
      delete nodeData.MetricDefinations;
      payload.push(nodeData);
    });

    const kpiData = gridSaveMultiMonthTransformation(payload, monthDiff + 1);

    const updatedData = {
      KpiData: kpiData,
      FiltersIDsVM: {
        OrgEntityID: props.CurrentFilter.ClientData.id,
        OfferingID: props.CurrentFilter.SyncFilter.ProcessId,
        LocationID: props.CurrentFilter.Location.LocationId,
      },
    };
    await axiosInstance
      .post(
        `${LocalApiBaseUrl}Configurator/SaveMetricData`,
        updatedData,
      )
      .then(async (response) => {
        if (response.data.IsSuccess) {
          if (endMonthArr.includes(props.CurrentFilter.SyncFilter.Month)) {
            const updatedConfigData = await getCurrentMonthConfiguratorData(props.CurrentFilter.SyncFilter.Month);
            const bodata = updatedConfigData[0];
            const hmdata = updatedConfigData[1];
            const wodata = updatedConfigData[2];
            const iidata = updatedConfigData[3];
            const dddata = updatedConfigData[4];
            dispatchData(bodata, hmdata, wodata, iidata, dddata);
          }

          window.alert('Data saved Successfully');
          props.onClose();
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        setIsApiLoading(false);
      });
    setIsApiLoading(false);
  }

  const dispatchData = (bodata, hmData, wodata, iidata, dddata) => {
    store.dispatch({
      type: 'INITIALISE_BO',
      payload: bodata.TabData,
    });
    store.dispatch({
      type: 'INITIALISE',
      payload: hmData.TabData,
    });

    store.dispatch({
      type: 'INITIALISEWO',
      payload: wodata.TabData,
    });
    store.dispatch({
      type: 'INITIALISEINSIGHT',
      payload: iidata.TabData,
    });

    store.dispatch({
      type: 'INITIALISEDIVERSE',
      payload: dddata.TabData,
    });
  };

  const onFilterTextBoxChanged = useCallback((e) => {
    setFilterValue(e.target.value);
    gridRef.current.api.setQuickFilter(document.getElementById('filter-grid').value);
  }, []);

  const copyTextToClipboard = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    // const selectedNodes = gridRef.current.api.getSelectedNodes();

    const copyMultipleCols = {};
    if (selectedColArr.length == 0) {
      const copyVals = selectedRows.map((e) => e[`${selectedCol}`]);
      setCopiedValues(copyVals);
    } else {
      selectedColArr.forEach((col) => {
        copyMultipleCols[`${col}`] = selectedRows.map((e) => e[`${col}`]);
      });
      setCopiedMultipleCols(copyMultipleCols);
    }
  };

  const pasteFromClipboad = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const i = selectedCol.charAt(selectedCol.length - 1);

    _.forEach(copiedMultipleCols, (value, key) => {
      const col = key.substring(0, key.length - 1) + i;

      value.forEach((val, index) => {
        if (rowForPaste + index < rowData.length && rowData[rowForPaste + index][`status_${i}`] != false && rowData[rowForPaste + index][`IsActive_${i}`] != false && rowData[rowForPaste + index][`canBeAdded_${i}`]) {
          const isAggField = (rowData[rowForPaste + index][`IntegrationStatus_${i}`] === 'Yes' || rowData[rowForPaste + index][`IsAggregation_${i}`] === true) && col.includes('MetricValue');
          if (!isAggField) { rowData[rowForPaste + index][`${col}`] = val; }
        }
      });
    });

    setRowData(rowData);
    refreshGrid();
  };

  const refreshGrid = useCallback(() => {
    const params = {
      force: false,
      suppressFlash: true,
    };
    gridRef.current.api.refreshCells(params);
  }, []);

  const onCellClicked = (params) => {
    if (!params.event.target.attributes.getNamedItem('col-id')) {
      return;
    }
    const col = params.event.target.attributes.getNamedItem('col-id').value;
    const row = params.event.target.parentElement.attributes.getNamedItem('row-id').value;

    $('.ag-cell').removeClass('ag-cell-selected');
    const selectedColArray = [col];
    if (params.event.shiftKey && col !== selectedCol && selectedCol !== '') {
      const i = selectedCol.charAt(selectedCol.length - 1);
      selectedColArray.push(selectedCol);
      if (selectedCol == `MetricValue_${i}` && col == `Comments_${i}`) {
        selectedColArray.push(`RAGStatus_${i}`);
      }
    }

    selectedColArray.forEach((column) => {
      const list = $(`[col-id='${column}']`);

      const selectedNodes = gridRef.current.api.getSelectedNodes();
      const x1 = selectedNodes[0].childIndex + 1;
      const x2 = parseInt(row, 10) + 1;

      _.forEach(list, (value, key) => {
        if (key >= x1 && key <= x2) {
          $(value).addClass('ag-cell-selected');
        }
      });
    });

    setSelectedColArr(selectedColArray);
    setSelectedCol(col);
    setRowForPaste(parseInt(row, 10));
  };
  const onBodyScroll = () => {
    if (selectedColArr.length > 0) {
      selectedColArr.forEach((column) => {
        const list = $(`[col-id='${column}']`);

        const selectedNodes = gridRef.current.api.getSelectedNodes();
        if(selectedNodes[0]){
        const x1 = selectedNodes[0].childIndex + 1;
        const x2 = parseInt(rowForPaste, 10) + 1;

        _.forEach(list, (value, key) => {
          if (key >= x1 && key <= x2) {
            // const cid = value.attributes.getNamedItem('comp-id').value;
            $(value).addClass('ag-cell-selected');
          }
        });
      }
      });
    }
  };

  return (
    <div className="tableFilter">
      <div className="form-section">
        <div className="form-row d-flex align-items-center mb-3">
          <div className="form-group col-lg-3 m-0 mr-3">
            <input type="text" className="form-control" id="filter-grid" placeholder="Filter..." onChange={(e) => onFilterTextBoxChanged(e)} value={filterValue} />
          </div>
          <div className="example-header" />
          <div className="purpleborder-dropdown offeringlist-dropdownslist m-0">

            <div className="monthdrop-wrapper">
              <span>From Month: </span>
              <DropdownButton
                menuAlign="left"
                id="Monthsbutton"
                title={fromMonth}
                className="selected mr-3 ml-1"
              >
                <div className="offering-droplistwrapper">
                  {props.monthData.map((each) => (
                    <Dropdown.Item
                      href="javascript:void(0)"
                      className={each === fromMonth ? 'active' : ''}
                      onClick={() => onFromMonthChange(each)}
                    >
                      <span role="button">{each}</span>
                    </Dropdown.Item>
                  ))}

                </div>
              </DropdownButton>
            </div>
            <div className="monthdrop-wrapper">
              <span>End Month: </span>
              <DropdownButton
                menuAlign="left"
                id="Monthsbutton"
                title={endMonth}
                className="selected ml-1"
              >
                <div className="offering-droplistwrapper">
                  {endMonthArr.map((each) => (
                    <Dropdown.Item
                      href="javascript:void(0)"
                      className={each === endMonth ? 'active' : ''}
                      onClick={() => onEndMonthChange(each)}
                    >
                      <span role="button">{each}</span>
                    </Dropdown.Item>
                  ))}
                </div>
              </DropdownButton>
            </div>
            <div className="copy-paste-actions">
              <button onClick={copyTextToClipboard} disabled={filterValue != '' } className="btn btn-primary ml-2 mr-2">Copy</button>
              <button onClick={pasteFromClipboad} disabled={filterValue != ''} className="btn btn-success">Paste</button>
            </div>
          </div>
          <button className="btn btn-primary btn-sm button-get-data mr-2 ml-auto" id="btnsubmit" type="button" onClick={saveGridData}>Save</button>
        </div>
      </div>
      {isApiLoading && <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />}
      <div className="ag-grid-custom-wrapper">
        <div className="ag-theme-balham ag-table" id="grid">
          <AgGridReact
            rowData={rowData}
            ref={gridRef}
            columnDefs={columnDefs}
            //onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            stopEditingWhenGridLosesFocus
            enableRangeSelection
            rowSelection="multiple"
            enableCellChangeFlash
            onCellClicked={onCellClicked}
            ensureDomOrder
            onBodyScroll={onBodyScroll}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  monthData: state.FilterReducer.MonthsData,
  CurrentFilter: state.CurrentFilter,
  boData: state.BOReducers.BO,
  hmData: state.humanReducers.humanandmachine,
  woData: state.WOtileReducers.tile,
  iniData: state.intelligentInsightReducers.insightandintelligence,
  ddData: state.diverseReducers.diverse,
  maturityData: state.MaturityReducers.Maturity,
  roleReducers: state.roleReducers.roleReducers,
});

export default connect(
  mapStateToProps,
  '',
)(MetricGridView);
