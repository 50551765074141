import React, { Component } from "react";
import DatePicker from "react-datepicker";
import Moment from "moment";
import i18n from "i18next";
import "react-datepicker/dist/react-datepicker.css";
import {
  LocalApiBaseUrl
} from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";


axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class TopUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadUrl: null,
      formIsValid: false,
      active: false,
      viewmore: false,
      process: "",
      Client: "",
      MaturityPhase: this.props.MaturityPhase,
      processOptions: [],
      StartDate: null,
      EndDate: null,
      changeStartDate: null,
      changeEndDate: null,
      errors: [],
      Report: {
        StartDate: new Date(),
        EndDate: new Date()
      }
    };

    this.validateForm = this.validateForm.bind(this);
    this.QueryString = this.QueryString.bind(this);
    this.generateExcelReport = this.generateExcelReport.bind(this);
  }

  componentDidMount() { }

  generateExcelReport() {
    if (this.validateForm()) {
      var that = this;
      var startDate = Moment(this.state.Report.StartDate).format("YYYY-MM-DD");
      var endDate = Moment(this.state.Report.EndDate).format("YYYY-MM-DD");

      var data = {
        startDate: new Date(startDate),
        endDate: new Date(endDate)
      };

      if (startDate !== endDate) {
        let constructedURL = LocalApiBaseUrl + "Report/GetRegisteredTopUser";
        this.setState({
          active: true
        });
        axiosInstance
          .post(constructedURL, data, {
            responseType: "blob"
          })
          .then(response => {
            this.setState({ active: false });
            if (response.status !== 200) {
              alert("An error has occurred!");
            }
            let fileNameFromHeaders = response.headers.filename;
            if (!window.navigator.msSaveOrOpenBlob) {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;

              link.setAttribute("download", fileNameFromHeaders);

              document.body.appendChild(link);
              link.click();
            }
            else {
              // BLOB FOR EXPLORER 11
              const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), fileNameFromHeaders);
            }
          })
          .catch(function (error) {
            that.setState({ active: false });
            trycatchAlertPopup(error);
          });
      }
    }
  }

  QueryString() {
    if (this.validateForm()) {
      var A = "Report/GetRegisteredTopUser?";
      var B = LocalApiBaseUrl;
      var startDate = Moment(this.state.Report.StartDate).format("YYYY-MM-DD");
      var endDate = Moment(this.state.Report.EndDate).format("YYYY-MM-DD");
      let downloadUrl = this.state.downloadUrl;

      downloadUrl = B + A + "startdate=" + startDate + "&enddate=" + endDate;

      this.setState({
        downloadUrl: downloadUrl
      });
    }
  }

  onStartDateChange = event => {
    let errors = {};
    const { Report } = this.state;
    Report.EndDate = new Date();
    this.setState({
      errors: errors,
      Report,
      EndDate: null
    });
    Report.StartDate = event;

    this.setState({ StartDate: event });
    this.setState({
      Report: Report,
      changeStartDate: event,
      changeEndDate: ""
    });

  };
  onEndDateChange = event => {
    let errors = {};
    this.setState({
      errors: errors,
      EndDate: event
    });
    const Report = this.state.Report;
    Report.EndDate = event;
    this.setState({
      Report: Report,
      changeEndDate: event
    });


  };

  validateForm() {
    let Report = this.state.Report;
    let errors = {};
    let formIsValid = true;
    if (this.state.changeStartDate == null || this.state.changeStartDate == "") {
      errors["StartDate"] = i18n.t("Please Select Start Date");
      formIsValid = false;
    }

    if (this.state.changeEndDate == null || this.state.changeEndDate == "") {
      errors["EndDate"] = i18n.t("Please Select End Date");
      formIsValid = false;
    }

    this.setState({
      errors: errors
    });

    if (formIsValid) {
      this.setState({
        formIsValid: true
      });
    } else {
      this.setState({
        formIsValid: false
      });
    }
    return formIsValid;
  }

  render() {
    return (
      <div className="main-data">
        <div className="row">
          <div className="form-group col-lg-4 block-picker">
            <label className="datepicker-label" htmlFor="">Start Date<span className="required"></span></label>
            <DatePicker
              className="form-control"
              selected={this.state.StartDate}
              autoComplete="off"
              id="StartDate"
              onChange={this.onStartDateChange.bind(this)}
              value={this.state.Report.StartDate}
            />
            <span className="errorMsg">{this.state.errors.StartDate}</span>
          </div>
          <div className="form-group col-lg-4 block-picker">
            <label className="datepicker-label" htmlFor="">End Date<span className="required"></span></label>
            <DatePicker className="form-control"
              selected={this.state.EndDate}
              minDate={this.state.Report.StartDate}
              autoComplete="off"
              id="EndDate"
              onChange={this.onEndDateChange.bind(this)}
              value={this.state.Report.EndDate}
            />
            <span className="errorMsg">{this.state.errors.EndDate}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <button className="btn btn-primary btn-sm" id="btnsave" onClick={this.generateExcelReport} disabled={this.state.active}>Generate Report</button>
            <LoadingOverlay
              active={this.state.active}
              spinner={<SynopsLoader />}
            ></LoadingOverlay>
          </div>
        </div>
      </div>
    );
  }
}
