import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { LocalApiBaseUrl } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import _ from "lodash";
import i18n from "i18next";
import { trycatchAlertPopup } from "../Shared/Constant";
import SubOfferingDropdowns from '../Shared/SubOfferingDropdowns';
import { filterSelectedSubOffering } from "../Shared/commonFunctions";

export default class GetDataPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
      showButton: true,
      viewmore: false,
      errors: {},
      isApiLoading: false,
      CopyProfileDataModel: {
        SourceClientId: 0,
        SourceClientName: "",
        SourceOfferingId: 0,
        SourceOfferingName: "",
        SourceSubOfferingName: "",
        SourceLocationId: 0,
        SourceLocationName: "",
        SourceMonthDT: "",
        TargetClientId: 0,
        TargetClientName: "",
        TargetOfferingId: 0,
        TargetOfferingName: "",
        TargetSubOfferingName: "",
        TargetLocationId: 0,
        TargetLocationName: "",
        TargetMonthDT: "",
      },
      SourceClientOptions: [],
      SourceOfferingOptions: [],
      SourceOfferingId: 0,
      SourceSubOfferingOptions: [],
      SourceLocationOptions: [],
      SourceMonthOptions: [],

      TargetClientOptions: [],
      TargetOfferingOptions: [],
      TargetOfferingId: 0,
      TargetSubOfferingOptions: [],
      TargetLocationOptions: [],
      TargetMonthOptions: [],
    };
  }

  handleHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
  }

  getSourceClients() {
    axiosInstance
      .get(`${LocalApiBaseUrl}CopyProfileData/GetSourceClients`)
      .then((response) => {
        this.setState({ SourceClientOptions: response.data });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getTargetClients() {
    axiosInstance
      .get(`${LocalApiBaseUrl}CopyProfileData/GetTargetClients`)
      .then((response) => {
        this.setState({ TargetClientOptions: response.data });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getOffering(OrgEntityID, EndPoint) {
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
        params: { OrgEntityID: OrgEntityID, featureName: "Get Data" },
      })
      .then((response) => {
        if (String(EndPoint) === "SOURCE") {
          this.setState({
            SourceOfferingOptions: response.data,
          });
        }
        if (String(EndPoint) === "TARGET") {
          this.setState({
            TargetOfferingOptions: response.data,
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getLocation(orgEntityID, subOfferingID, EndPoint) {
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetLocation`, {
        params: { OrgEntityID: orgEntityID, OfferingID: subOfferingID },
      })
      .then((response) => {
        if (String(EndPoint) === "SOURCE") {
          this.setState({
            SourceLocationOptions: response.data,
          });
        }
        if (String(EndPoint) === "TARGET") {
          this.setState({
            TargetLocationOptions: response.data,
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getSourceMonth(orgEntityID, subOfferingID, locationID) {
    axiosInstance
      .get(`${LocalApiBaseUrl}CopyProfileData/GetPublishedMonths`, {
        params: { OrgEntityID: orgEntityID, OfferingID: subOfferingID, LocationID: locationID },
      })
      .then((response) => {
        this.setState({
          SourceMonthOptions: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getTargetMonth(orgEntityID, subOfferingID, locationID) {
    axiosInstance
      .get(`${LocalApiBaseUrl}Configurator/GetMonth`, {
        params: { OrgEntityID: orgEntityID, OfferingID: subOfferingID, LocationID: locationID },
      })
      .then((response) => {
        this.setState({
          TargetMonthOptions: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  onSourceClientNameChange() {
    let copyProfileData = this.state.CopyProfileDataModel;

    copyProfileData.SourceOfferingId = 0;
    copyProfileData.SourceOfferingName = "";
    copyProfileData.SourceSubOfferingName = "";
    copyProfileData.SourceLocationId = 0;
    copyProfileData.SourceLocationName = "";
    copyProfileData.SourceMonthDT = "";

    this.setState({
      errors: {},
      SourceOfferingOptions: [],
      SourceOfferingId: 0,
      SourceSubOfferingOptions: [],
      SourceLocationOptions: [],
      SourceMonthOptions: [],
      CopyProfileDataModel: copyProfileData,
    });

    var event = document.getElementById("SourceClient");

    copyProfileData.SourceClientId = parseInt(event.options[event.selectedIndex].value, 10);
    copyProfileData.SourceClientName =
      copyProfileData.SourceClientId > 0 ? event.options[event.selectedIndex].text : "";
    if (copyProfileData.SourceClientId > 0) {
      this.getOffering(copyProfileData.SourceClientId, "SOURCE");
      this.setState({
        CopyProfileDataModel: copyProfileData,
      });
    }
  }

  onSourceOfferingsChange() {
    let copyProfileData = this.state.CopyProfileDataModel;

    copyProfileData.SourceOfferingId = 0;
    copyProfileData.SourceOfferingName = "";
    copyProfileData.SourceSubOfferingName = "";
    copyProfileData.SourceLocationId = 0;
    copyProfileData.SourceLocationName = "";
    copyProfileData.SourceMonthDT = "";

    this.setState({
      errors: {},
      SourceOfferingId: 0,
      SourceSubOfferingOptions: [],
      SourceLocationOptions: [],
      SourceMonthOptions: [],
      CopyProfileDataModel: copyProfileData,
    });

    var event = document.getElementById("SourceOffering");

    let sourceOfferingId = parseInt(event.options[event.selectedIndex].value, 10);
    copyProfileData.SourceOfferingName = sourceOfferingId > 0 ? event.options[event.selectedIndex].text : "";

    let sourceSubOfferingOptions = sourceOfferingId
      ? this.state.SourceOfferingOptions.filter(
          (data) => String(data.OfferingID) === String(event.options[event.selectedIndex].value)
        )[0].ChildOfferings
      : [];

    this.setState({
      SourceOfferingId: sourceOfferingId,
      SourceSubOfferingOptions: sourceSubOfferingOptions,
      CopyProfileDataModel: copyProfileData,
    });
  }

  onSourceSubOfferingChange(e) {
    let copyProfileData = this.state.CopyProfileDataModel;

    copyProfileData.SourceOfferingId = 0;
    copyProfileData.SourceSubOfferingName = "";
    copyProfileData.SourceLocationId = 0;
    copyProfileData.SourceLocationName = "";
    copyProfileData.SourceMonthDT = "";

    this.setState({
      errors: {},
      SourceLocationOptions: [],
      SourceMonthOptions: [],
      CopyProfileDataModel: copyProfileData,
    });

    const filtersSubofferingData = filterSelectedSubOffering([...this.state.SourceSubOfferingOptions],e.target.value);
    const selectedSuboffering = filtersSubofferingData.subofferingData[0];

    //var event = document.getElementById("SourceSubOffering");

    copyProfileData.SourceOfferingId = parseInt(e.target.value, 10);;
    copyProfileData.SourceSubOfferingName =
      copyProfileData.SourceOfferingId > 0 ? selectedSuboffering.Process : "";

    if (copyProfileData.SourceClientId > 0 && copyProfileData.SourceOfferingId > 0) {
      this.getLocation(copyProfileData.SourceClientId, copyProfileData.SourceOfferingId, "SOURCE");

      this.setState({
        CopyProfileDataModel: copyProfileData,
      });
    }
  }

  onSourceLocationChange() {
    let copyProfileData = this.state.CopyProfileDataModel;

    copyProfileData.SourceLocationId = 0;
    copyProfileData.SourceLocationName = "";
    copyProfileData.SourceMonthDT = "";

    this.setState({
      errors: {},
      SourceMonthOptions: [],
      CopyProfileDataModel: copyProfileData,
    });

    var event = document.getElementById("SourceLocation");

    copyProfileData.SourceLocationId = parseInt(event.options[event.selectedIndex].value, 10);
    copyProfileData.SourceLocationName =
      copyProfileData.SourceLocationId > 0 ? event.options[event.selectedIndex].text : "";

    if (
      copyProfileData.SourceClientId > 0 &&
      copyProfileData.SourceOfferingId > 0 &&
      copyProfileData.SourceLocationId > 0
    ) {
      this.getSourceMonth(
        copyProfileData.SourceClientId,
        copyProfileData.SourceOfferingId,
        copyProfileData.SourceLocationId
      );

      this.setState({
        CopyProfileDataModel: copyProfileData,
      });
    }
  }

  onSourceMonthChange() {
    let copyProfileData = this.state.CopyProfileDataModel;

    copyProfileData.SourceMonthDT = "";

    this.setState({
      errors: {},
      CopyProfileDataModel: copyProfileData,
    });

    var event = document.getElementById("SourceMonth");

    copyProfileData.SourceMonthDT = event.options[event.selectedIndex].value;

    this.setState({
      CopyProfileDataModel: copyProfileData,
    });
  }

  onTargetClientNameChange() {
    let copyProfileData = this.state.CopyProfileDataModel;

    copyProfileData.TargetOfferingId = 0;
    copyProfileData.TargetOfferingName = "";
    copyProfileData.TargetSubOfferingName = "";
    copyProfileData.TargetLocationId = 0;
    copyProfileData.TargetLocationName = "";
    copyProfileData.TargetMonthDT = "";

    this.setState({
      errors: {},
      TargetOfferingOptions: [],
      TargetOfferingId: 0,
      TargetSubOfferingOptions: [],
      TargetLocationOptions: [],
      TargetMonthOptions: [],
      CopyProfileDataModel: copyProfileData,
    });

    var event = document.getElementById("TargetClient");

    copyProfileData.TargetClientId = parseInt(event.options[event.selectedIndex].value, 10);
    copyProfileData.TargetClientName =
      copyProfileData.TargetClientId > 0 ? event.options[event.selectedIndex].text : "";
    if (copyProfileData.TargetClientId > 0) {
      this.getOffering(copyProfileData.TargetClientId, "TARGET");
      this.setState({
        CopyProfileDataModel: copyProfileData,
      });
    }
  }

  onTargetOfferingsChange() {
    let copyProfileData = this.state.CopyProfileDataModel;

    copyProfileData.TargetOfferingId = 0;
    copyProfileData.TargetOfferingName = "";
    copyProfileData.TargetSubOfferingName = "";
    copyProfileData.TargetLocationId = 0;
    copyProfileData.TargetLocationName = "";
    copyProfileData.TargetMonthDT = "";

    this.setState({
      errors: {},
      TargetOfferingId: 0,
      TargetSubOfferingOptions: [],
      TargetLocationOptions: [],
      TargetMonthOptions: [],
      CopyProfileDataModel: copyProfileData,
    });

    var event = document.getElementById("TargetOffering");

    let targetOfferingId = parseInt(event.options[event.selectedIndex].value, 10);
    copyProfileData.TargetOfferingName = targetOfferingId > 0 ? event.options[event.selectedIndex].text : "";

    let targetSubOfferingOptions = targetOfferingId
      ? this.state.TargetOfferingOptions.filter(
          (data) => String(data.OfferingID) === String(event.options[event.selectedIndex].value)
        )[0].ChildOfferings
      : [];

    this.setState({
      TargetOfferingId: targetOfferingId,
      TargetSubOfferingOptions: targetSubOfferingOptions,
      CopyProfileDataModel: copyProfileData,
    });
  }

  onTargetSubOfferingChange(e) {
    let copyProfileData = this.state.CopyProfileDataModel;

    copyProfileData.TargetOfferingId = 0;
    copyProfileData.TargetSubOfferingName = "";
    copyProfileData.TargetLocationId = 0;
    copyProfileData.TargetLocationName = "";
    copyProfileData.TargetMonthDT = "";

    this.setState({
      errors: {},
      TargetLocationOptions: [],
      TargetMonthOptions: [],
      CopyProfileDataModel: copyProfileData,
    });

    const filtersSubofferingData = filterSelectedSubOffering([...this.state.TargetSubOfferingOptions],e.target.value);
    const selectedSuboffering = filtersSubofferingData.subofferingData[0];

    copyProfileData.TargetOfferingId = parseInt(e.target.value, 10);
    copyProfileData.TargetSubOfferingName =
      copyProfileData.TargetOfferingId > 0 ? selectedSuboffering.Process : "";

    if (copyProfileData.TargetClientId > 0 && copyProfileData.TargetOfferingId > 0) {
      this.getLocation(copyProfileData.TargetClientId, copyProfileData.TargetOfferingId, "TARGET");

      this.setState({
        CopyProfileDataModel: copyProfileData,
      });
    }
  }

  onTargetLocationChange() {
    let copyProfileData = this.state.CopyProfileDataModel;

    copyProfileData.TargetLocationId = 0;
    copyProfileData.TargetLocationName = "";
    copyProfileData.TargetMonthDT = "";

    this.setState({
      errors: {},
      TargetMonthOptions: [],
      CopyProfileDataModel: copyProfileData,
    });

    var event = document.getElementById("TargetLocation");

    copyProfileData.TargetLocationId = parseInt(event.options[event.selectedIndex].value, 10);
    copyProfileData.TargetLocationName =
      copyProfileData.TargetLocationId > 0 ? event.options[event.selectedIndex].text : "";

    if (
      copyProfileData.TargetClientId > 0 &&
      copyProfileData.TargetOfferingId > 0 &&
      copyProfileData.TargetLocationId > 0
    ) {
      this.getTargetMonth(
        copyProfileData.TargetClientId,
        copyProfileData.TargetOfferingId,
        copyProfileData.TargetLocationId
      );

      this.setState({
        CopyProfileDataModel: copyProfileData,
      });
    }
  }

  onTargetMonthChange() {
    let copyProfileData = this.state.CopyProfileDataModel;

    copyProfileData.TargetMonthDT = "";

    this.setState({
      errors: {},
      CopyProfileDataModel: copyProfileData,
    });

    var event = document.getElementById("TargetMonth");

    copyProfileData.TargetMonthDT = event.options[event.selectedIndex].value;

    this.setState({
      CopyProfileDataModel: copyProfileData,
    });
  }

  validateForm = async () => {
    let data = this.state.CopyProfileDataModel;
    let errors = this.state.errors;

    let copiedData = this.props.rowData;

    let formIsValid = true;

    Object.keys(data).map((key) => {
      switch (key) {
        case "SourceClientId":
          data[key] ? (errors[key] = "") : (errors[key] = "Client is required");
          if (errors[key]) formIsValid = false;
          break;

        case "SourceOfferingName":
          data[key] ? (errors[key] = "") : (errors[key] = "Offering is required");
          if (errors[key]) formIsValid = false;
          break;

        case "SourceOfferingId":
          data[key] ? (errors[key] = "") : (errors[key] = "Sub Offering is required");
          if (errors[key]) formIsValid = false;
          break;

        case "SourceLocationId":
          data[key] ? (errors[key] = "") : (errors[key] = "Location is required");
          if (errors[key]) formIsValid = false;
          break;

        case "SourceMonthDT":
          data[key] ? (errors[key] = "") : (errors[key] = "Month is required");
          if (errors[key]) formIsValid = false;
          break;

        case "TargetClientId":
          data[key] ? (errors[key] = "") : (errors[key] = "Client is required");
          if (errors[key]) formIsValid = false;
          break;

        case "TargetOfferingName":
          data[key] ? (errors[key] = "") : (errors[key] = "Offering is required");
          if (errors[key]) formIsValid = false;
          break;

        case "TargetOfferingId":
          data[key] ? (errors[key] = "") : (errors[key] = "Sub Offering is required");
          if (errors[key]) formIsValid = false;
          break;

        case "TargetLocationId":
          data[key] ? (errors[key] = "") : (errors[key] = "Location is required");
          if (errors[key]) formIsValid = false;
          break;

        case "TargetMonthDT":
          data[key] ? (errors[key] = "") : (errors[key] = "Month is required");
          if (errors[key]) formIsValid = false;
          break;

        default:
          break;
      }
    });

    if (
      formIsValid &&
      (String(data.SourceOfferingName) !== String(data.TargetOfferingName) ||
        String(data.SourceOfferingId) !== String(data.TargetOfferingId))
    ) {
      window.alert("Source and Destination profile offering-suboffering should be same.");
      formIsValid = false;
    }

    if (
      formIsValid &&
      (String(data.SourceClientId) === String(data.TargetClientId) &&
        String(data.SourceOfferingId) === String(data.TargetOfferingId) &&
        String(data.SourceLocationId) === String(data.TargetLocationId))
    ) {
      window.alert("Please select different Source and Destination profile.");
      formIsValid = false;
    }

    formIsValid &&
      Object.keys(copiedData).forEach((eachData) => {
        if (
          String(copiedData[eachData].TargetClientId) === String(data.TargetClientId) &&
          String(copiedData[eachData].TargetOfferingId) === String(data.TargetOfferingId) &&
          String(copiedData[eachData].TargetLocationId) === String(data.TargetLocationId)
        ) {
          window.alert("Data is already copied for the selected combination.");
          formIsValid = false;
        }
      });

    if (formIsValid) {
      formIsValid = await this.checkConfigData(
        data.TargetClientId,
        data.TargetOfferingId,
        data.TargetLocationId,
        data.TargetMonthDT
      );
    }

    this.setState({
      errors: errors,
    });

    return formIsValid;
  };

  async checkConfigData(OrgEntityID, OfferingID, LocationID, MonthDT) {
    let isConfigDataPresent = false;
    let returnValue = false;

    await axiosInstance
      .get(`${LocalApiBaseUrl}CopyProfileData/CheckConfiguratorData`, {
        params: {
          OrgEntityID: OrgEntityID,
          OfferingID: OfferingID,
          LocationID: LocationID,
          MonthDT: MonthDT,
        },
      })
      .then((response) => {
        isConfigDataPresent = response.data;

        if (isConfigDataPresent) {
          if (
            window.confirm(
              "Data already exist in destination profile. On confirming, the existing data shall be overridden with source profile data. If you still want to proceed, click on ‘OK’ confirm."
            )
          ) {
            returnValue = true;
          } else {
            returnValue = false;
          }
        } else {
          returnValue = true;
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

    return returnValue;
  }

  handleCopy = async () => {
    if (await this.validateForm()) {
      let CopyProfileDataModel = this.state.CopyProfileDataModel;

      this.props.onCopy(CopyProfileDataModel);
    }
  };

  componentDidMount() {
    this.getSourceClients();
    this.getTargetClients();
  }

  render() {
    return (
      <>
        <Modal
          show={this.state.show}
          onHide={this.handleHide.bind(this)}
          scrollable={true}
          className="modal fade"
          size="medium"
          id="dealpopup"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title" id="dealprofile">
              Profile Mapping
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-12">
                      <h3 className="grid-heading border-0 mt-2 mb-3">Source Details</h3>
                    </div>

                    <div className="form-group col-lg-12">
                      <label htmlFor="">
                        Client Name<span className="required">*</span>
                      </label>
                      <select
                        id="SourceClient"
                        className="form-control"
                        ref="SourceClientDropdown"
                        onChange={this.onSourceClientNameChange.bind(this)}
                        value={this.state.CopyProfileDataModel.SourceClientId}
                      >
                        <option value="0">Select Client</option>
                        {this.state.SourceClientOptions.map((row, index) => {
                          return (
                            <option key={row.ClientID} value={row.ClientID}>
                              {row.ClientName}
                            </option>
                          );
                        })}
                      </select>
                      {<div className="errorMsg">{this.state.errors.SourceClientId}</div>}
                    </div>

                    <div className="form-group col-lg-12">
                      <label htmlFor="">
                        Offering<span className="required">*</span>
                      </label>
                      <select
                        id="SourceOffering"
                        className="form-control"
                        ref="SourceOfferingDropDown"
                        onChange={this.onSourceOfferingsChange.bind(this)}
                        value={this.state.SourceOfferingId}
                      >
                        <option value="0">Select Offering</option>
                        {this.state.SourceOfferingOptions.map((row, index) => {
                          return (
                            <option key={row.OfferingID} value={row.OfferingID}>
                              {row.OfferingName}
                            </option>
                          );
                        })}
                      </select>
                      {<div className="errorMsg">{this.state.errors.SourceOfferingName}</div>}
                    </div>

                    <div className="form-group col-lg-12">
                      <SubOfferingDropdowns
                        selectedSubOffering={this.state.CopyProfileDataModel.SourceSubOfferingName}
                        subOfferings={this.state.SourceSubOfferingOptions}
                        onSubOfferingChange={(e)=>this.onSourceSubOfferingChange(e)}                    
                        defaultToggleName={i18n.t("Select_Sub_Offerings")}
                        selectedSubOfferingId={this.state.CopyProfileDataModel.SourceOfferingId}
                        required={true}
                        labelClassName=""
                        labelfor=""
                        disabledValue={false}
                        dropdownItem={true}
                        labelpresent={true}
                        SubOfferingDropdownsClass={true}
                      />
                      <span className="errorMsg">{this.state.errors.SourceOfferingId}</span>
                    </div>

                    <div className="form-group col-lg-12">
                      <label htmlFor="">
                        Location <span className="required">*</span>
                      </label>
                      <select
                        id="SourceLocation"
                        className="form-control"
                        ref="SourceLocationDropdown"
                        onChange={this.onSourceLocationChange.bind(this)}
                        value={this.state.CopyProfileDataModel.SourceLocationId}
                      >
                        <option value="0">Select Location</option>
                        {this.state.SourceLocationOptions.map((row, index) => {
                          if (row.IsActive) {
                            return (
                              <option key={row.LocationID} value={row.LocationID}>
                                {row.City}
                              </option>
                            );
                          }
                        })}
                      </select>
                      <span className="errorMsg">{this.state.errors.SourceLocationId}</span>
                    </div>

                    <div className="form-group col-lg-12">
                      <label htmlFor="">
                        Month <span className="required">*</span>
                      </label>
                      <select
                        id="SourceMonth"
                        className="form-control"
                        ref="SourceMonthDropdown"
                        onChange={this.onSourceMonthChange.bind(this)}
                        value={this.state.CopyProfileDataModel.SourceMonthDT}
                      >
                        <option value="">Select Month</option>
                        {this.state.SourceMonthOptions.map((row, index) => {
                          return (
                            <option key={row} value={row}>
                              {row}
                            </option>
                          );
                        })}
                      </select>
                      <span className="errorMsg">{this.state.errors.SourceMonthDT}</span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-12">
                      <h3 className="grid-heading border-0 mt-2 mb-3">Destination Details</h3>
                    </div>

                    <div className="form-group col-lg-12">
                      <label htmlFor="">
                        Client Name<span className="required">*</span>
                      </label>
                      <select
                        id="TargetClient"
                        className="form-control"
                        ref="TargetClientDropdown"
                        onChange={this.onTargetClientNameChange.bind(this)}
                        value={this.state.CopyProfileDataModel.TargetClientId}
                      >
                        <option value="0">Select Client</option>
                        {this.state.TargetClientOptions.map((row, index) => {
                          return (
                            <option key={row.ClientID} value={row.ClientID}>
                              {row.ClientName}
                            </option>
                          );
                        })}
                      </select>
                      {<div className="errorMsg">{this.state.errors.TargetClientId}</div>}
                    </div>

                    <div className="form-group col-lg-12">
                      <label htmlFor="">
                        Offering<span className="required">*</span>
                      </label>
                      <select
                        id="TargetOffering"
                        className="form-control"
                        ref="TargetOfferingDropDown"
                        onChange={this.onTargetOfferingsChange.bind(this)}
                        value={this.state.TargetOfferingId}
                      >
                        <option value="0">Select Offering</option>
                        {this.state.TargetOfferingOptions.map((row, index) => {
                          return (
                            <option key={row.OfferingID} value={row.OfferingID}>
                              {row.OfferingName}
                            </option>
                          );
                        })}
                      </select>
                      {<div className="errorMsg">{this.state.errors.TargetOfferingName}</div>}
                    </div>

                    <div className="form-group col-lg-12">
                    <SubOfferingDropdowns
                        selectedSubOffering={this.state.CopyProfileDataModel.TargetSubOfferingName}
                        subOfferings={this.state.TargetSubOfferingOptions}
                        onSubOfferingChange={(e)=>this.onTargetSubOfferingChange(e)}                    
                        defaultToggleName={i18n.t("Select_Sub_Offerings")}
                        selectedSubOfferingId={this.state.CopyProfileDataModel.TargetOfferingId}
                        required={true}
                        labelClassName=""
                        labelfor=""
                        disabledValue={false}
                        dropdownItem={true}
                        labelpresent={true}
                        SubOfferingDropdownsClass={true}
                      />
                      <span className="errorMsg">{this.state.errors.TargetOfferingId}</span>
                    </div>

                    <div className="form-group col-lg-12">
                      <label htmlFor="">
                        Location <span className="required">*</span>
                      </label>
                      <select
                        id="TargetLocation"
                        className="form-control"
                        ref="TargetLocationDropdown"
                        onChange={this.onTargetLocationChange.bind(this)}
                        value={this.state.CopyProfileDataModel.TargetLocationId}
                      >
                        <option value="0">Select Location</option>
                        {this.state.TargetLocationOptions.map((row, index) => {
                          if (row.IsActive) {
                            return (
                              <option key={row.LocationID} value={row.LocationID}>
                                {row.City}
                              </option>
                            );
                          }
                        })}
                      </select>
                      <span className="errorMsg">{this.state.errors.TargetLocationId}</span>
                    </div>

                    <div className="form-group col-lg-12">
                      <label htmlFor="">
                        Month <span className="required">*</span>
                      </label>
                      <select
                        id="TargetMonth"
                        className="form-control"
                        ref="TargetMonthDropdown"
                        onChange={this.onTargetMonthChange.bind(this)}
                        value={this.state.CopyProfileDataModel.TargetMonthDT}
                      >
                        <option value="">Select Month</option>
                        {this.state.TargetMonthOptions.map((row, index) => {
                          return (
                            <option key={row} value={row}>
                              {row}
                            </option>
                          );
                        })}
                      </select>
                      <span className="errorMsg">{this.state.errors.TargetMonthDT}</span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={!this.state.showButton}
                  onClick={() => this.handleCopy()}
                >
                  {"Copy"}
                </button>
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}
