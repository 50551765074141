import React, { Component } from 'react';
import DashboardCntContainer from '../Configurator/DashboardCntContainer';
import { LocalApiBaseUrl, getMonthNumber, getPrevMonth } from '../Shared/Constant';
import { AgGridReact, Ag } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-blue.css';
import { store } from "../reducers/configureStore"
import Axios from 'axios';
//import MasterContainer from './MasterContainer';

import user from '../Images/user.png';
import security from '../Images/security.png';
import offering from '../Images/offering.png';
import location from '../Images/location.png';
import config from '../Images/config.png';
import { Link, NavLink } from 'react-router-dom';
import mydealprofile from '../Images/mydealprofile.png';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import i18n from "i18next";
import UserTrendsList from './UserTrendsList';
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;






export default class UserTrendsContainer extends Component {
    constructor() {
        super()
        this.state = {

            roleData: [],
            userList: [], roleList: [], clientList: [], viewmore: false, rowSelected: [], featureData: [],
            isDayWiseClicked: false,
            isWeekWiseClicked: false,
            pageHeader: "",
        }
        this.onClose = this.onClose.bind(this);
        this.onDayWiseClick = this.onDayWiseClick.bind(this);
        this.onWeekWiseClick = this.onWeekWiseClick.bind(this);
        this.getRoleData = this.getRoleData.bind(this);
    }

    onClose(e) {

        this.setState({ viewmore: false });
    }

    getRoleData() {
        
        axiosInstance.get(`${LocalApiBaseUrl}User/GetUserFeature`, {
            params: {}
        })
            .then((response) => {


                store.dispatch({
                    type: 'INITIALISEROLE',

                    payload: response.data

                })
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      

        var data = store.getState();
        let roleData1 = data.roleReducers["roleReducers"];
        store.subscribe(() => {
            var data = store.getState();
            
            this.setState({ roleData: data.roleReducers["roleReducers"] })
            let featureDetails1 = data.roleReducers["roleReducers"];
            this.setState({ featureData: featureDetails1 }, () => {
                this.loadTabBasedOnURL();
            })

            /*
            if(this.state.roleData.filter((data) => data.FeatureName=="User Trends" && data.Submenu=="Day Wise" && data.Operations.includes("View")).length>0)
            {
                this.setState({
                    isDayWiseClicked: true,
                    isWeekWiseClicked: false,
                });
            }
            else if(this.state.roleData.filter((data) => data.FeatureName=="User Trends" && data.Submenu=="Week Wise" && data.Operations.includes("View")).length>0)
         
            {

                this.setState({
                    isWeekWiseClicked: true,
                    isDayWiseClicked: false,
                });
            }*/
        //     if((this.state.roleData.filter((data) => data.FeatureName=="User Trends" && data.Submenu=="Week Wise" && data.Operations.includes("View")).length>0) && (this.state.roleData.filter((data) => data.FeatureName=="User Trends" && data.Submenu=="Week Wise" && data.Operations.includes("View")).length>0))
        //   {
        //     this.state.isDayWiseClicked=true
        //     this.state.isWeekWiseClicked=true
        //   }
          
           

           
        })
    }

    componentWillMount() {

            this.getRoleData();

    }

    componentDidMount() {
               
        this.getRoleData();
      
        
    }
    
    componentDidUpdate(nextProps){
        if(this.props.location.pathname != nextProps.location.pathname)        
            this.loadTabBasedOnURL();
    }

    onDayWiseClick(e) {
        this.setState({
            isDayWiseClicked: true,
            isWeekWiseClicked: false,
        });
    }

    onWeekWiseClick(e) {

        this.setState({
            isWeekWiseClicked: true,
            isDayWiseClicked: false,
        });
    }

    setTabOnLoad(tabName){
        let tabData = [];
        switch(tabName){
            case "Day_Wise":
                tabData = this.state.roleData.filter((data) => data.FeatureName=="User Trends" && data.Submenu=="Day Wise" && data.Operations.includes("View"))
                if (tabData.length>0) {
                    this.onDayWiseClick();
                }
                break;
            case "Week_Wise":
                tabData = this.state.roleData.filter((data) => data.FeatureName=="User Trends" && data.Submenu=="Week Wise" && data.Operations.includes("View"))
                if (tabData.length>0) {
                    this.onWeekWiseClick();
                }
                break;
        }
            
        this.setState({
            pageHeader: tabData.length > 0 ? tabData[0].Submenu : ""
        })
    }
    
    loadTabBasedOnURL = () => {
        const reqPath = this.props.location.pathname;
        const reqPathArr = reqPath.split("/");
        if(reqPath.startsWith("/admin/UserTrends") && reqPathArr.length == 4){
            this.setTabOnLoad(reqPathArr[3])            
        }
    }


    render() {
        
        return (
            <div className="main-component">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                        <h3 className="grid-heading">              
                            {i18n.t("User_Trends") + " > " + (this.props.location.state ? i18n.t(this.props.location.state.detail) : i18n.t(this.state.pageHeader) )}
                        </h3>  
                        <div className="tab-list">
                            {/* <ul className="navbar-nav search-nav">

                              {this.state.roleData.filter((data) => data.FeatureName=="User Trends" && data.Submenu=="Day Wise" && data.Operations.includes("View")).length>0 ? <li selection="[object Object]" className={(this.state.isDayWiseClicked ? 'active nav-item' : 'nav-item')} data-toggle="tab" onClick={this.onDayWiseClick}><a className="nav-link">Day Wise</a></li>:null}
                              {this.state.roleData.filter((data) => data.FeatureName=="User Trends" && data.Submenu=="Week Wise" && data.Operations.includes("View")).length>0 ? <li selection="[object Object]" className={(this.state.isWeekWiseClicked ? 'active nav-item' : 'nav-item')} data-toggle="tab" onClick={this.onWeekWiseClick}><a className="nav-link">Week Wise</a></li>:null}
</ul> */}
</div>
</div>
</div>
<div className="row">
    <div className="col-lg-12">
                        <UserTrendsList filterDetails={this.props.filterDetails} isWeekWiseClicked={this.state.isWeekWiseClicked} isDayWiseClicked={this.state.isDayWiseClicked}></UserTrendsList>
                </div>
                </div>
                </div>
                </div>

        )
    }
}