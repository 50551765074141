import React from "react";
import TrendGraphInsight from "../Analytics/TrendGraphInsight";
import notification from "../Images/notification-icon.png";
import MetricComments from "./MetricComments";
import ReactTooltip from "react-tooltip";
import { ValueUnit, ValueUnitNoRoundOff, MetricValueUnitFormat, toolThreshold, CommonMetriTileFix } from "../Shared/Common";
import i18n from "i18next";
import $ from 'jquery';



class HumanMetricDetailsInsight extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			viewGraph: false,
			viewAnalytics: false,
			commentPopUp: false,
			eachDetails: this.props.data,
			Description: "",
			filterDetails: this.props.filterDetails,
		};
		this.handleGraph = this.handleGraph.bind(this);
		this.handleAnalytics = this.handleAnalytics.bind(this);
		this.ellipsisTruncate = this.ellipsisTruncate.bind(this);
	}
	ellipsisTruncate(str) {
		return str.length > 55 ? str.substring(0, 55) + "..." : str;
	}
	handleAnalytics(name, Id, mUnit, e) {
		let kpiMapping = Id;
		e.preventDefault(Id, e);
		this.setState({ viewAnalytics: true, kpiMapping, name, mUnit });
	}
	handleGraph(name, Id, mUnit, e) {
		let kpiMapping = Id;
		e.preventDefault(Id, e);
		this.setState({ viewGraph: true, kpiMapping, name, mUnit });
	}
	onClose = (e) => {
		this.setState({
			viewGraph: false,
			viewAnalytics: false,
			kpiMapping: 0,
			name: "",
		});
	};
	onCommentClick = (val) => {
		if (val === 1) {
			this.setState({
				commentPopUp: true,
			});
		} else {
			this.setState({
				commentPopUp: false,
			});
		}
	};
	componentDidMount(){
        CommonMetriTileFix();
  	}
	render() {
		let eachDetails = this.props.data;
		let BenchmarkName=eachDetails.metDataAttr.map((e)=>{
			return e.AttributeName
		}
		 );
	let BenchmarkValue=eachDetails.metDataAttr.map((e)=>{
		return e.AttributeValue
	}
	 );
		const options = {
      loop: true,
      margin: 24,
      responsiveClass: "",
      dots: false,
      responsive: {
        0: {
          items: 1,
          nav: true
        },
        600: {
          items: 2,
          nav: false
        },
        1000: {
            items: 3,
            nav: true,
            loop: false
        },
        1440: {
            items: 4,
            nav: true,
            loop: false
          },
          1600: {
              items: 4,
              nav: true,
              loop: false
          },
          1800: {
              items: 5,
              nav: true,
              loop: false
        }
      }
    };
		let AttrValue = "";
		let metricName = "";
		let unit = "";
		let metricData = "";
		let leadingIndustryValue = "";
		let similarIndustryValue = "";
		// let Description = "";
		let ValueBlock = true;
		let RelatorBlock = this.props.isRelatorBlock;
		let NotificationBlock = false;
		//let ToolTip = "";
		let info = "";
		let toolName = "";
		let MetricValueUnitFormatData = "";
		let middleAlign = "";

		metricName = eachDetails.MetricName;
		unit = eachDetails.MetricUnit;
		unit = unit.trim();
		// let unit_En = eachDetails.MetricUnit_En;
		// unit_En = unit_En.trim();

		if (eachDetails.metDataAttr != null) {
			if (eachDetails.metDataAttr && eachDetails.metDataAttr.length > 0) {
				leadingIndustryValue = eachDetails.metDataAttr[0] ? eachDetails.metDataAttr[0].AttributeValue : "";
				similarIndustryValue = eachDetails.metDataAttr[1] ? eachDetails.metDataAttr[1].AttributeValue : "";

				//For old data which has Attribute name and attribute value as empty strings/ null
				if(eachDetails.metDataAttr[0]){
				eachDetails.metDataAttr[0].AttributeName =
					this.props.attributes && this.props.attributes.length > 0
						? this.props.attributes.filter(z => z.ApplicableForDD === false)[0].AttributeName1
						: eachDetails.metDataAttr[0].AttributeName;
				}

				if(eachDetails.metDataAttr[1]){
				eachDetails.metDataAttr[1].AttributeName =
					this.props.attributes && this.props.attributes.length > 0
						? this.props.attributes.filter(z => z.ApplicableForDD === false)[0].AttributeName2
						: eachDetails.metDataAttr[1].AttributeName;
				}
			}

			
		}

		let mappingId = eachDetails.KPIMappingID;
		let statusColor = "item gray-color";
			
			if(unit.includes('$') || unit.includes('€') || unit.includes('£') || unit.includes('₹') || unit.includes('SGD') || unit.includes('R$')) {
				metricData = ValueUnitNoRoundOff(eachDetails.MetricValue);
			  } 
			  else {
				metricData = ValueUnit(eachDetails.MetricValue);
			  }
			MetricValueUnitFormatData = MetricValueUnitFormat(unit,metricData);
			
			middleAlign = (!leadingIndustryValue || leadingIndustryValue.toUpperCase() == "NA") && (!similarIndustryValue || similarIndustryValue.toUpperCase() == "NA")|| !RelatorBlock?
				' middle-align' :'';

			this.state.Description =
				/* eachDetails.MetricDataDesp === ""
					? eachDetails.KPIDesp
					: eachDetails.MetricDataDesp; */
				eachDetails.KPIDesp
					? eachDetails.KPIDesp
					: "";
			toolName = (
				eachDetails.ApplicationMappingName != null && eachDetails.ApplicationMappingName.length > 0 ?
					eachDetails.ApplicationMappingName : (
						eachDetails.ProjectName != null && eachDetails.ProjectName.length > 0 ? eachDetails.ProjectName : ""
					)
			);

			if (eachDetails != undefined) {
			// 	if((eachDetails.LowerThreshold !="0" && eachDetails.UpperThreshold !=null  && eachDetails.LowerThreshold !="")&& 
			// 	(eachDetails.LowerThreshold !="0" && eachDetails.LowerThreshold !=null &&eachDetails.LowerThreshold !="")){
			// 	ToolTip =
			// 	("Lower Threshold") +
			// 	  ":" +
			// 	  (eachDetails.LowerThreshold) +
			// 	  " ," +
			// 	  ("Upper Threshold") +
			// 	  ":" +
			// 	  (eachDetails.UpperThreshold);
			  
			  
			//   }
		
			//   if((eachDetails.LowerThreshold !="0" && eachDetails.LowerThreshold !="" && eachDetails.LowerThreshold !=null) &&
			//   (eachDetails.UpperThreshold =="0" ||eachDetails.UpperThreshold ==null ||eachDetails.UpperThreshold =="") ){
			// 	ToolTip =
			// 	("Lower Threshold") +
			// 	  ":" +
			// 	  (eachDetails.LowerThreshold) 
			// 	 ;
			  
			  
			//   }
			//   if((eachDetails.LowerThreshold =="0" || eachDetails.LowerThreshold ==null ||eachDetails.LowerThreshold =="") &&(eachDetails.UpperThreshold !="0" && eachDetails.UpperThreshold !="" && eachDetails.UpperThreshold!=null) )
			//   {
			// 	ToolTip =
			// 	("Upper Threshold") +
			// 	":" +
			// 	(eachDetails.UpperThreshold);
			
			  
			//   }

			if (eachDetails.RAGStatus != undefined && eachDetails.RAGStatus != null) {
				switch (eachDetails.RAGStatus.toLowerCase()) {
				  case "grey":
					statusColor = "item gray-color";
					break;
				  case "red":
					statusColor = "item red-color";
					break;
				  case "orange":
					statusColor = "item amber-color";
					break;
				  case "green":
					statusColor = "item green-color";
					break;
				  case "amber":
					statusColor = "item amber-color";
					break;
				  default:
				  // code block
				}
			  }
			}

		const formattertwo = new Intl.NumberFormat('en', {
			useGrouping: true
		})
		return (
			<div
				className={`${statusColor}`}
				data-place="bottom"
				data-tip={toolThreshold(eachDetails)}
			>
				
					{/*<div className="item-right">
						<div class="tile-metric-name">
							{
								toolName.length == 0 ? <span class="no-tool"></span> : <span>{toolName}</span>
							}
						</div>
						{RelatorBlock ? (
							<>
								<span className="parent-metric-details">
									{eachDetails.metDataAttr.map(
										arrayElt =>
											arrayElt.AttributeValue &&
											arrayElt.AttributeValue !== null &&
											arrayElt.AttributeValue.toUpperCase() !== "NA" && (
												<span className="metric-details">
													{`${arrayElt.AttributeName}: `}
													<b>{`${arrayElt.AttributeValue}`}</b>
												</span>
											)
									)}
								</span>
							</>
						) : null}
											</div>*/}
					

					<div className="item-left">
            {ValueBlock ? (
              <>
                <p className="tile-metric-value">
                 {MetricValueUnitFormatData}
                  
                {/* {`${
                    Number.isInteger(Number(metricData)) ?
										 (unit === "$" || unit === "₹" || unit === "R$" ||unit === "€" ||unit === "£" || unit === "SGD" ? unit + metricData : metricData)||
										 (unit === "$ Mn"  ? unit.substr(0,0) + metricData + "Mn" : metricData)||
                     (unit === "R$ Mn" || unit === "€$ Mn" ? unit.substr(0,1) + metricData + "Mn" : metricData )||
                     ( unit === "SGD Mn" ? unit.substr(0,2) + metricData + "Mn" : metricData)

                    : 
										(unit === "$" ? unit + metricData: metricData)
                    
                  }`}
                  {((unit !== "₹" && unit !== "$" && unit !== "R$" && unit !== "€" && unit !== "£" && unit !== "SGD") ||(unit === "$Mn" || unit === "R$ Mn" ||unit === "€$ Mn" || unit === "SGD Mn") ? <span>&nbsp;{unit}</span> : "")}
                */}
			   </p>
              </>
            ) : null}
            <p className="tile-metric-desc" data-tip data-for={'customTooltip' + eachDetails.KPIMappingID}>
				{/* {metricName.length > 91 ? metricName.slice(0, 91) + "..." : metricName} */}
				{metricName}
			{/* <ReactTooltip place="right" effect="solid" id={'customTooltip' + eachDetails.KPIMappingID} className="tilecard-tooltip">
                                <span>{metricName}</span>
                            </ReactTooltip> */}
            
            </p>
          </div>
						{/* {ArrowBlock?<><span><FontAwesomeIcon icon={faArrowDown} /></span></>:null} */}
						{this.state.viewGraph ? (
							<TrendGraphInsight
								show={true}
								onClose={this.onClose}
								kpiMappingId={this.state.kpiMapping}
								kpiName={this.state.name}
								kpiUnit={this.state.mUnit}
								Month={this.props.data.Month}
								dealFilter={this.props.dealFilter}
								info={eachDetails}
								tooltip={this.props.tooltip}
							/>
						) : null}
						          <div className={RelatorBlock?'item-right isRelatorBlock'+middleAlign:'item-right'+middleAlign}>
						{
            
			toolName.length == 0 ? <p className="mt-2 mb-4"></p> : <p className="tile-metric-name">
				<span className="toolname-span">     
				{/* {toolName.length > 20 ? toolName.slice(0,20) + "..." : toolName}   */}
				<p>{toolName}</p>
				</span></p>
		  }
		  
		  {RelatorBlock && (
            <div className="metric-lead-lagg">
            {BenchmarkValue[0] && BenchmarkName[0] && (BenchmarkValue.length>0 && BenchmarkValue[0].toUpperCase()!=='NA' &&
						(
              <p>
                <span className="lead-lagg-label">{BenchmarkName[0]}</span>
                <span className="lead-lagg-val">{BenchmarkValue[0]}</span>
              </p>
              ))}
              {BenchmarkValue[1] && BenchmarkName[1] && (
                BenchmarkValue.length>0 && BenchmarkValue[1].toUpperCase()!=='NA' &&
              (
              <p>
                <span className="lead-lagg-label">{BenchmarkName[1]}</span>
                <span className="lead-lagg-val">{BenchmarkValue[1]}</span>
              </p>))}
            </div>
              )}
          </div>
		  
						{/*{NotificationBlock ? (
							<>
								<span className="png-icons">
									<img src={notification} alt="" className="bell" />
									<span className="round-div">4</span>
								</span>
							</>
						) : null}*/}
					{this.state.commentPopUp ? (
						<MetricComments
							//	kpiName={metricName}
							isRelatorBlock={this.props.isRelatorBlock}
							info={eachDetails}
							show={this.state.commentPopUp}
							onClose={this.onCommentClick.bind(this, 2)}
						/>
					) : null}
					<div className="dropdown dropleft ondropOpenColor">
            <span type="button" className="dropdown-toggle" data-toggle="dropdown"></span>
            <div className="dropdown-menu">
              <a href="javascript:void(0)" className="dropdown-item" onClick={this.onCommentClick.bind(this, 1)}>
                <span className="info-icon"></span>{i18n.t("Info")}
              </a>
			  <a
                    href="javascript:void(0)"
                    className="dropdown-item"
                    onClick={(e) =>
                      this.handleGraph(metricName, mappingId, unit, e)
                    }
                  >
                    <span className="insights-new-icon"></span>{i18n.t("Insights")}
                  </a>
			</div>
		</div>

						{/*this.state.Description ? (

							<span className="metric-details"><b>Description: </b> {(this.ellipsisTruncate(this.state.Description))}</span>
						) : (
								<span className="metric-details">&nbsp;</span>
						)*/}
					</div>
				
				
			
		);
	}
}
export default HumanMetricDetailsInsight;
