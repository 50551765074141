import React from "react";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import _ from "lodash";
import i18n from "i18next";
import { LocalApiBaseUrl, regexForFields, regexForNumbersOnlyMTC,regexForPositiveInteger, regexForNegativeNumbers } from "../Shared/Constant";
import { Form } from "react-bootstrap";
import { store } from "../reducers/configureStore";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import KpiPopUp from "../Configurator/KpiPopUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { regexForTextAreas } from "../Shared/Constant";
import { FormatDate,isValidText,InvalidDescription } from "../Shared/commonFunctions";
import { MetricValueThousandFormat, thousandSeparatorFormat } from "../Shared/Common";
import DatePicker from "react-datepicker";
export default class HumanPopUp extends React.Component {
  constructor(props) {
    super(props);
    const { show, SelectValue } = this.props;
    this.state = {
      show: show,
      filter: { OrgEntityID: 0, OfferingID: 0, LocationID: 0, MonthDT: null },
      apivalue: "",
      selectValue: SelectValue,
      Display: false,
      viewmore: false,
      MetricNameOptions: [],
      valueConnector: false,
      con: false,
      connectorName: "",
      criteriaOptions: [],
      valueConnectorEdit: false,
      KpiMetricConnectorID: false,
      commentsError: "",
      BaselineMonthReadOnly: true,
      showCategory: false,
      allCategoryTypesAndNames: [],
      showApproval: false,
      numeratorVal: "",
      denomVal: "",
      CatTypeID: "0",
      CatNameID: "0",
      kpiData: {
        KPIMappingID: 0,
        MetricSeq: null,
        MetricID: 0,
        MetricName: "",
        FrequencyID: 0,
        MetricUnit: "",
        MetricImage: "",
        KPIDesp: "",
        MetricDataID: 0,
        MetricDataDesp: "",
        MetricValue: null,
        RAGStatus: "",
        Impact: "",
        FTEReleased: "",
        UpperThreshold: "",
        LowerThreshold: "",
        BaselineValue: "",
        BaseLineMonth: "",
        TargetValue: "",
        Month: "",
        LeadingPractice: "",
        Comments: "",
        metDataAttr: null,
        ConnectorID: "",
        ConnectorNames: "",
        CriteriaID: "",
        SourceFieldName: "",
        SourceName: "",
        IsAccentureOwned: false,
        MetricDefination: [],
        MetricDefinitionID: '',
        CategoryDetails: [],
        FormulaList: [],
      },
      errors: {},
      formIsValid: false,
      SOCatagoryId: "",
      data: {
        SOCategoryID: 0,
        kpiData: {
          KPIMappingID: 0,
          MetricSeq: null,
          MetricID: 0,
          MetricName: "",
          FrequencyID: 0,
          MetricUnit: "",
          MetricImage: "",
          KPIDesp: "",
          MetricDataID: 0,
          MetricDataDesp: "",
          MetricValue: null,
          RAGStatus: "",
          Impact: "",
          FTEReleased: "",
          UpperThreshold: "",
          LowerThreshold: "",
          BaselineValue: "",
          BaseLineMonth: "",
          TargetValue: "",
          Month: "",
          LeadingPractice: "",
          Comments: "",
          metDataAttr: null,
          ConnectorID: "",
          ConnectorNames: "",
          CriteriaID: "",
          SourceFieldName: "",
          SourceName: "",
          IsAccentureOwned: false,
          MetricDefination: [],
          MetricDefinitionID: '',
          ConfigurationDealMetricID: 0,
          CategoryDetails: [],
          IsMultiLevel: false,
          IsAggregation: false
        }
      },
      categories: [],
      CategoryDetails: { CategoryType: "", CategoryName: "" },
      isAddCatPopup: false,
      CategoryNameData: [],
      categoriesLimit: 30, // Default
    };
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onSequenceChange = this.onSequenceChange.bind(this);
    this.onUnitChange = this.onUnitChange.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onValDescChange = this.onValDescChange.bind(this);
    this.onUpperChange = this.onUpperChange.bind(this);
    this.onLowerChange = this.onLowerChange.bind(this);
    this.maxLengthCheckThreshold = this.maxLengthCheckThreshold.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.onSourceChange = this.onSourceChange.bind(this);
    this.onSourceSystemChange = this.onSourceSystemChange.bind(this);
    this.onOtherSystemChange = this.onOtherSystemChange.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.handleRejectHide = this.handleRejectHide.bind(this);
    this.onToolOwnerChange = this.onToolOwnerChange.bind(this);
    this.handleApproveHide = this.handleApproveHide.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onBaselineValueChange = this.onBaselineValueChange.bind(this);
    this.onBaselineMonthChange = this.onBaselineMonthChange.bind(this);
    this.onTargetValueChange = this.onTargetValueChange.bind(this);
    this.onFTEChange = this.onFTEChange.bind(this);
  }
  componentDidMount = () => {

    this.callingFunction()
    this.getGetConnectorMaster();
    this.getCategoriesLimit();
    let data = _.cloneDeep(this.props.kpiData);
    if (this.props.kpiData) {
      let kpiData = _.cloneDeep(this.props.kpiData);
      if (kpiData.CategoryDetails && kpiData.CategoryDetails.length === 0) {
        kpiData.IsMultiLevel = false;
      }
      let connectedData = this.props.responseMetricData.filter((x) => String(x.KpiMappingID) === String(this.props.kpiData.KPIMappingID))
      kpiData.FormulaList = connectedData.length > 0 ? connectedData[0].FormulaList : []
      let viewModelData = this.state.data;
      viewModelData.SOCategoryID = this.props.SOCategoryID;
      viewModelData.kpiData = _.cloneDeep(this.props.kpiData);
      this.setState({
        data: _.cloneDeep(this.props.kpiData),
        errors: {},
        formIsValid: true,
        kpiData,
        BaselineMonthReadOnly: data.BaselineValue ? false : true
      });
    }
  }

  /* Start of Multi Level Functions */

  callingFunction = async () => {

    await this.getKpiMAppingDetails();
    const { allCategoryTypesAndNames } = this.state;
    if (this.props.kpiData) {
      let kpiData = _.cloneDeep(this.props.kpiData);
      let CatTypeID =
        kpiData.CategoryDetails && kpiData.CategoryDetails.length > 0
          ? kpiData.CategoryDetails[0].OfferingMultiLevelCategoryID
          : 0;

      let CatType =
        kpiData.CategoryDetails && kpiData.CategoryDetails.length > 0 ? kpiData.CategoryDetails[0].CategoryType : 0;

      let CategoryNameData =
        CatType && String(CatType) !== ""
          ? allCategoryTypesAndNames.filter((eachValue) => String(eachValue.CategoryType) === String(CatType))
          : []; //FETCHING CATEGORY NAME

      let CategoryDetails = this.state.CategoryDetails;
      CategoryDetails.CategoryType = CatType;
      this.setState({
        CategoryNameData: CategoryNameData,
        CategoryDetails: CategoryDetails,
        CatTypeID: CatTypeID,
        iscatypedisable: true,
      });
    }
  };


  deleteCategoryName = (catNameToDelete) => {
    this.setState({ errors: {} });
    let CatTypeID = this.state.CatTypeID;
    let kpiData = this.state.kpiData;
    let allCategoriesList = this.state.kpiData.CategoryDetails;
    let CategoryNameData = this.state.CategoryNameData;
    allCategoriesList.splice(allCategoriesList.findIndex((item) => item.CategoryName === catNameToDelete), 1);
    if (allCategoriesList.length === 0) {
      kpiData.MetricValue = "";
    }

    this.setState({
      CatNameID: 0,
      CategoryNameData: allCategoriesList.length === 0 ? [] : CategoryNameData,
      CatTypeID: CatTypeID, //CHANGED
      kpiData: kpiData,
    });
    const { valueConnectorEdit, valueConnector } = this.state;
    if (
      kpiData.IsAggregation &&
      !valueConnectorEdit &&
      !valueConnector &&
      !this.isSelectedMetricMappedToConnector
    ) {
      //calculate the metric value on the fly when new values added/ edited/deleted
      this.calculateAggregateValueByFormula();
    }
  }

  editCategoryName = (item) => {
    this.setState({
      showCategoryNameEditPopup: true,
    });
  }

  deleteCategoryType = () => {
    if(window.confirm(i18n.t("delete_Category_msg")))
    {
    let kpiData = this.state.kpiData;
    kpiData.CategoryDetails = [];
    const { valueConnectorEdit, valueConnector } = this.state;
    if (
      kpiData.IsAggregation &&
      !valueConnectorEdit &&
      !valueConnector &&
      !this.isSelectedMetricMappedToConnector
    ) {
      // if there are no categories, clear out the value calculated using the formula
      kpiData.MetricValue = "";
    }

    this.setState({
      CatNameID: 0,
      CatTypeID: 0,
      CategoryNameData: [],
      data: {
        kpiData: kpiData,
      },
    });
  }
  }

  onClose = (e) => {
    this.setState({ viewmore: false });
    this.props.onClose(e);
  }

  getCategoriesLimit = () => {
    let data = store.getState().CurrentFilter.multiLevelCategoriesLimit;
    this.setState({
      categoriesLimit: data
    })
  }
  bindToolFieldChange = (e) => {
    const id = e.target.id;
    let data = this.state.CategoryDetails;
    switch (id) {
      case "categoryType":
        data.CategoryType = e.target.value;
        break;
      case "categoryName":
        data.CategoryName = e.target.value;
        break;
      default:
        break;
    }

    this.setState({
      CategoryDetails: data,
    });
  };

  onNumeratorChange = (e) => {
    let errors = this.state.errors;
    errors.NumeratorValue = "";
    this.setState({ errors: errors });
    if (e.target.value.length <= 12) {
      this.setState({ numeratorVal: e.target.value });
    }
  };

  onDenomChange = (e) => {
    let errors = this.state.errors
    errors.Denominator = ''
    this.setState({ errors: errors })
    if (e.target.value.length <= 12) {
      this.setState({ denomVal: e.target.value });
    }
  }

  bindAutoAggregationChange = (e) => {
    const kpiData = this.state.kpiData;

    kpiData.IsAggregation = e.target.checked;

    this.setState({
      kpiData: kpiData,
    });
    const { valueConnectorEdit, valueConnector } = this.state;
    if (
      kpiData.IsAggregation &&
      !valueConnectorEdit &&
      !valueConnector &&
      !this.isSelectedMetricMappedToConnector
    ) {
      this.calculateAggregateValueByFormula();
    }
  };

  calculateAggregateValueByFormula = () => {
    let kpiData = _.cloneDeep(this.state.kpiData);
    const metricUnit = kpiData.MetricUnit;
    let temp = 0;
    let formulaStr = "";
    if (!kpiData.FormulaList) kpiData.FormulaList = [];
    if (metricUnit.trim() === "%") {
      if (kpiData.FormulaList.length > 0) {
        formulaStr = kpiData.FormulaList.filter((z) => z.IsPercentageUnit === true)[0].Formula;
      }
    } else {
      if (kpiData.FormulaList.length > 0) {
        formulaStr = kpiData.FormulaList.filter((z) => z.IsPercentageUnit === false)[0].Formula;
      }
      kpiData.formulaStr = formulaStr;
      this.setState({
        kpiData: { ...kpiData },
      });
    }
    if (kpiData.CategoryDetails && kpiData.CategoryDetails.length > 0) {
      let total = 0;
      let numeratorSum = 0;
      let denominatorSum = 0;

      switch (formulaStr.toUpperCase().trim()) {
        case "SUM":
          kpiData.CategoryDetails.forEach(function (arrayItem) {
            if (!arrayItem.Denominator) {
              temp = 1;
            } else {
              temp = arrayItem.Denominator;
            }
            total = total + arrayItem.NumeratorValue / temp;
          });

          break;
        case "AVERAGE":
          kpiData.CategoryDetails.forEach(function (arrayItem) {
            if (!arrayItem.Denominator) {
              temp = 1;
            } else {
              temp = arrayItem.Denominator;
            }
            total = total + arrayItem.NumeratorValue / temp;
          });

          total = total / kpiData.CategoryDetails.length;
          break;
        case "WEIGHTED AVERAGE":
          kpiData.CategoryDetails.forEach(function (arrayItem) {
            if (!arrayItem.Denominator) {
              arrayItem.Denominator = 100;
            }
            // let individualPercentage = (arrayItem.NumeratorValue / arrayItem.Denominator) * 100;
            // total = total + individualPercentage; //75+30

            numeratorSum += parseFloat(arrayItem.NumeratorValue);
            denominatorSum += parseFloat(arrayItem.Denominator);
          });

          total = (numeratorSum / denominatorSum) * 100;
          break;
        default:
          break;
      }
      if (formulaStr) {
        kpiData.MetricValue = total;
      }

      this.setState({
        kpiData: { ...kpiData },
      });
    }
  };

  onKeyPress = (event) => {
    return (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122);
  }

  handleClick = () => {
    const kpiData = this.state.kpiData;
    kpiData.IsMultiLevel = !kpiData.IsMultiLevel;

    if (!kpiData.IsMultiLevel) {
      kpiData.IsAggregation = false;
    }
    this.setState({
      kpiData: kpiData,
    });
  }

  handleCategoryClose = () => {
    this.setState({
      showCategory: false,

      errors: {},
    });
  }

  handleCategoryShow = () => {
    this.setState({ errors: {} });
    let CategoryDetails = this.state.CategoryDetails;
    CategoryDetails.CategoryType = "";
    CategoryDetails.CategoryName = "";
    this.setState({
      showCategory: true,
      iscatypedisable: false,
      CategoryDetails: CategoryDetails,
      isEdit: false,
      CategoryNameData: [],
      CatNameID: 0,
      CatTypeID: 0,
      numeratorVal: "",
      denomVal: "",
    });
  }

  handleCategoryAdd = () => {
    const kpiData = this.state.kpiData;
    let error = {};
    const { numeratorVal, valueConnectorEdit, categoriesLimit, denomVal, CatNameID, CatTypeID, CategoryDetails, CategoryNameData } = this.state;
    if (!numeratorVal) {
      error["NumeratorValue"] = i18n.t("numeratorIsReqd");
    }
    else if (!(parseFloat(numeratorVal) >= -999999999999 && parseFloat(numeratorVal) <= 999999999999)) {
      error["NumeratorValueRange"] = i18n.t("addBoErrorM6");
    }
    if (kpiData.MetricUnit.trim() === "%") {
      if (!denomVal) {
        error["Denominator"] = i18n.t("denominatorIsReqd");
      } else if (parseFloat(denomVal) === 0) {
        error["Denominator"] = i18n.t("denomCannotZero");
      } else if (!(parseFloat(denomVal) >= -999999999999 && parseFloat(denomVal) <= 999999999999)) {
        error["DenominatorValueRange"] = i18n.t("addBoErrorM6");
      }
    } else {
      if (!Number.isNaN(parseFloat(denomVal))) {
        if (parseFloat(denomVal) === 0) {
          error["Denominator"] = i18n.t("denomCannotzero");
        } else if (!(parseFloat(denomVal) >= -999999999999 && parseFloat(denomVal) <= 999999999999)) {
          error["DenominatorValueRange"] = i18n.t("addBoErrorM6");
        }
      }
    }
    if (String(CatTypeID) === "0") {
      error["CategoryType"] = i18n.t("catTypeReqd");
    }

    if (String(CatNameID) === "0") {
      error["CategoryName"] = i18n.t("catNameReqd");
    }
    this.setState({ errors: error });
    if (
      kpiData.MetricUnit.trim() === "%"
        ? CategoryDetails.CategoryType &&
        String(CatTypeID) !== "0" &&
        CategoryDetails.CategoryName &&
        String(CatNameID) !== "0" &&
        numeratorVal &&
        (parseFloat(numeratorVal) >= -999999999999 && parseFloat(numeratorVal) <= 999999999999) &&
        denomVal &&
        parseFloat(denomVal) !== 0 &&
        (parseFloat(denomVal) >= -999999999999 && parseFloat(denomVal) <= 999999999999)
        : CategoryDetails.CategoryType &&
        String(CatTypeID) !== "0" &&
        CategoryDetails.CategoryName &&
        String(CatNameID) !== "0" &&
        numeratorVal &&
        (parseFloat(numeratorVal) >= -999999999999 && parseFloat(numeratorVal) <= 999999999999) &&
        (!Number.isNaN(parseFloat(denomVal))
          ? parseFloat(denomVal) !== 0 &&
          parseFloat(denomVal) >= -999999999999 &&
          parseFloat(denomVal) <= 999999999999
          : true)
    ) {
      if (!kpiData.CategoryDetails) kpiData.CategoryDetails = [];

      if (
        kpiData.CategoryDetails.filter((cat) => cat.CategoryName === CategoryDetails.CategoryName).length ===
        0
      ) {
        let CategoryName = CategoryDetails.CategoryName;


        let Catid = CategoryName
          ? CategoryNameData.filter((cat) => cat.CategoryName === CategoryName)[0].ID
          : null;


        if (!kpiData.CategoryDetails) kpiData.CategoryDetails = [];
        if (kpiData.CategoryDetails && kpiData.CategoryDetails.length < categoriesLimit) {
          kpiData.CategoryDetails.push({
            CategoryType: CategoryDetails.CategoryType,
            CategoryName: CategoryDetails.CategoryName,
            NumeratorValue: numeratorVal,
            Denominator: denomVal,
            ConfigurationDealMetricID: 8,
            OfferingMultiLevelCategoryID: Catid,
          });

          this.setState({
            showCategory: false,
            kpiData: kpiData,
          });
        } else {
          let error = {};
          error["CategoryName"] = `${i18n.t("A_maximum_of")} ${categoriesLimit} ${i18n.t("category_values_are")}`

          this.setState({ showCategory: true, errors: error });
        }

        if (kpiData.IsAggregation && !valueConnectorEdit && !this.isSelectedMetricMappedToConnector) {
          //calculate the metric value on the fly when new values added/ edited/deleted
          this.calculateAggregateValueByFormula();
        }
      } else {
        let error = {};
        error["CategoryName"] = i18n.t("duplicateCatName");

        this.setState({ errors: error });
      }
    } else {
      this.setState({
        showCategory: true,
      });
    }
  }
  handleCategoryEditShow = (catNameToEdit) => {
    const { kpiData, CategoryNameData, CatTypeID } = this.state
    this.setState({ errors: {} });
    let allCategoriesList = [...kpiData.CategoryDetails];
    let index = allCategoriesList.findIndex((item) => item.CategoryName === catNameToEdit);
    let catnameid = CategoryNameData.filter((row) => row.CategoryName === catNameToEdit)[0].ID;
    let CategoryName = CategoryNameData.filter((row) => row.ID === catnameid)[0].CategoryName;
    let CategoryDetails = this.state.CategoryDetails;
    CategoryDetails.CategoryName = CategoryName;

    let numeratorVal = allCategoriesList[index].NumeratorValue;
    let denomVal = allCategoriesList[index].Denominator;
    this.setState({
      EditIndex: index,
      isEdit: true,
      showCategory: true,
      CatNameID: catnameid,
      CategoryDetails: CategoryDetails,
      CatTypeID: CatTypeID,
      numeratorVal: numeratorVal,
      denomVal: denomVal,
    });
  }

  handleCategoryEdit = () => {
    let error = {};
    let kpiData = _.cloneDeep(this.state.kpiData)
    const { numeratorVal, valueConnectorEdit, EditIndex, valueConnector, denomVal, CatNameID, CatTypeID, CategoryDetails, CategoryNameData } = this.state;
    if (!numeratorVal) {
      error["NumeratorValue"] = i18n.t("numeratorIsReqd");
    }
    else if (!(parseFloat(numeratorVal) >= -999999999999 && parseFloat(numeratorVal) <= 999999999999)) {
      error["NumeratorValueRange"] = i18n.t("addBoErrorM6");
    }
    if (kpiData.MetricUnit.trim() === "%") {
      if (!denomVal) {
        error["Denominator"] = i18n.t("denominatorIsReqd");
      } else if (parseFloat(denomVal) === 0) {
        error["Denominator"] = i18n.t("denomCannotZero");
      } else if (!(parseFloat(denomVal) >= -999999999999 && parseFloat(denomVal) <= 999999999999)) {
        error["DenominatorValueRange"] = i18n.t("addBoErrorM6");
      }
    } else {
      if (!Number.isNaN(parseFloat(denomVal))) {
        if (parseFloat(denomVal) === 0) {
          error["Denominator"] = i18n.t("denomCannotzero");
        } else if (!(parseFloat(denomVal) >= -999999999999 && parseFloat(denomVal) <= 999999999999)) {
          error["DenominatorValueRange"] = i18n.t("addBoErrorM6");
        }
      }
    }

    if (!CategoryDetails.CategoryType) {
      error["CategoryType"] = i18n.t("catTypeReqd");
    }

    if (CatNameID === "0") {
      error["CategoryName"] = i18n.t("catNameReqd");
    }
    this.setState({ errors: error });
    if (
      kpiData.MetricUnit === "%"
        ? CategoryDetails.CategoryType &&
        String(CatTypeID) !== "0" &&
        CategoryDetails.CategoryName &&
        String(CatNameID) !== "0" &&
        numeratorVal &&
        (parseFloat(numeratorVal) >= -999999999999 && parseFloat(numeratorVal) <= 999999999999) &&
        denomVal &&
        parseFloat(denomVal) !== 0 &&
        (parseFloat(denomVal) >= -999999999999 && parseFloat(denomVal) <= 999999999999)
        : CategoryDetails.CategoryType &&
        String(CatTypeID) !== "0" &&
        CategoryDetails.CategoryName &&
        String(CatNameID) !== "0" &&
        numeratorVal &&
        (parseFloat(numeratorVal) >= -999999999999 && parseFloat(numeratorVal) <= 999999999999) &&
        (!Number.isNaN(parseFloat(denomVal))
          ? parseFloat(denomVal) !== 0 &&
          parseFloat(denomVal) >= -999999999999 &&
          parseFloat(denomVal) <= 999999999999
          : true)
    ) {
      let index = EditIndex;
      if (
        kpiData.CategoryDetails.filter((cat) => cat.CategoryName === CategoryDetails.CategoryName).length ===
        0
      ) {
        kpiData.CategoryDetails[index].CategoryName = CategoryDetails.CategoryName;
        kpiData.CategoryDetails[index].NumeratorValue = numeratorVal;
        kpiData.CategoryDetails[index].Denominator = denomVal;
        let Catid =
          kpiData.CategoryDetails[index].CategoryName && kpiData.CategoryDetails[index].CategoryName !== ""
            ? CategoryNameData.filter(
              (cat) => cat.CategoryName === kpiData.CategoryDetails[index].CategoryName
            )[0].ID
            : null;
        kpiData.CategoryDetails[index].OfferingMultiLevelCategoryID = Catid;
        this.setState({
          kpiData: { ...kpiData },
        });
        this.setState({
          showCategory: false,
        });
      } else if (
        kpiData.CategoryDetails.filter((cat) => cat.CategoryName === kpiData.CategoryDetails[index].CategoryName)
          .length === 1
      ) {
        if (
          kpiData.CategoryDetails[index].CategoryName.trim() === CategoryDetails.CategoryName.trim()
            ? false
            : kpiData.CategoryDetails.filter((cat) => cat.CategoryName === CategoryDetails.CategoryName)
              .length === 1
        ) {
          let error = {};
          error["CategoryName"] = i18n.t("duplicateCatName");

          this.setState({ errors: error });
        } else {
          kpiData.CategoryDetails[index].NumeratorValue = numeratorVal;
          kpiData.CategoryDetails[index].Denominator = denomVal;
          this.setState({
            kpiData: { ...kpiData },
            showCategory: false,
          }, () => {
            if (
              kpiData.IsAggregation &&
              !valueConnectorEdit &&
              !valueConnector &&
              !this.isSelectedMetricMappedToConnector
            ) {
              //calculate the metric value on the fly when new values added/ edited/deleted
              this.calculateAggregateValueByFormula();
            }
          });
        }
      } else {
        this.setState({
          showCategory: false,
        });
      }
    }
  }
  handleCategoryTypeAddShow = () => {
    this.setState({ errors: {} });
    let CatTypeID = this.state.CatTypeID;
    let CategoryDetails = this.state.CategoryDetails;
    CategoryDetails.CategoryName = "";
    this.setState({
      CategoryDetails: CategoryDetails,
      showCategory: true,
      isEdit: false,
      iscatypedisable: true,
      CatNameID: 0,
      CatTypeID: CatTypeID,
      numeratorVal: "",
      denomVal: "",
    });
  }

  handleaddCategoryNameClose = () => {
    this.setState({
      showaddCategory: false,
      errors: {},
    });
  }
  handleaddCategoryClose = () => {
    this.setState({
      showaddCategoryType: false,
      errors: {},
    });
  }

  handleaddCategoryShow = () => {
    this.setState({ showaddCategory: true });
  }
  removeDuplicates = (arr) => {
    /*NEW CODE WHICH REMOVES DUPLICATES DATA FROM API */
    const map = new Map();
    arr.forEach((v) => map.set(v.CategoryType, v));
    return [...map.values()];
  }

  CatTypeSelect = (e) => {
    let CatTypeID = e.target.value;
    this.setState({ CatTypeID: CatTypeID });

    setTimeout(() => {
      if (String(CatTypeID) === "addnew") {
        CategoryDetails.CategoryType = "";
        CategoryDetails.CategoryName = "";
        this.setState({
          errors: {},
          showCategory: false,
          showaddCategoryType: true,
          CategoryDetails: CategoryDetails,
        });
      } else if (String(CatTypeID) === "0") {
        CategoryDetails.CategoryType = "";
        CategoryDetails.CategoryName = "";
        this.setState({
          CatNameID: 0,
          showaddCategoryType: false,
          showaddCategory: false,
          showCategory: true,
          CategoryDetails: CategoryDetails,
        });
      } else if (String(CatTypeID) === "addnewcatname") {
        this.setState({
          showCategory: false,
          showaddCategory: true,
        });
      } else {
        this.setState({
          showaddCategoryType: false,
          showaddCategory: false,
          showCategory: true,
        });
      }
    }, 500);
    const { allCategoryTypesAndNames } = this.state;
    let CatType =
      CatTypeID && String(CatTypeID) !== "0" && String(CatTypeID) !== "addnew"
        ? allCategoryTypesAndNames.filter((eachValue) => String(eachValue.ID) === String(CatTypeID))[0].CategoryType
        : []; //FETCHING individual CATEGORY TYPE

    let CategoryDetails = this.state.CategoryDetails;
    CategoryDetails.CategoryType = CatType;
    this.setState({
      CategoryDetails: CategoryDetails,
    });

    let CategoryNameData =
      CatType && String(CatType) !== ""
        ? allCategoryTypesAndNames.filter((eachValue) => String(eachValue.CategoryType) === String(CatType))
        : []; //FETCHING CATEGORY NAME

    this.setState({ CategoryNameData: CategoryNameData });
  }
  handleDropdownChange = (e) => {
    let CatNameID = e.target.value;
    this.setState({ errors: {}, CatNameID: CatNameID });

    setTimeout(() => {
      if (String(CatNameID) === "addnew") {
        this.setState({
          showCategory: false,
          showaddCategoryType: true,
        });
      } else if (String(CatNameID) === "addnewcatname") {
        if (!this.state.CategoryDetails.CategoryType) {
          //category type is empty

          let error = {};
          error["CategoryType"] = i18n.t("catTypeReqd");

          this.setState({ errors: error, CatNameID: 0 });
        } else {
          CategoryDetails.CategoryName = "";
          this.setState({
            showCategory: false,
            showaddCategory: true,
            CategoryDetails: CategoryDetails,
          });
        }
      } else {
        this.setState({
          showaddCategoryType: false,
          showaddCategory: false,
          showCategory: true,
        });
      }
    }, 500);
    let CatName =
      CatNameID && String(CatNameID) !== "0" && String(CatNameID) !== "addnewcatname"
        ? this.state.allCategoryTypesAndNames.filter((eachValue) => String(eachValue.ID) === String(CatNameID))[0].CategoryName
        : [];

    let CategoryDetails = this.state.CategoryDetails;
    CategoryDetails.CategoryName = CatName;
    this.setState({
      CategoryDetails: CategoryDetails,
    });
  }

  //called when new category name is added -- on clicking on the add button

  handleaddCategoryAdd = () => {
    const data = this.state.CategoryDetails;
    const { filterDetails } = this.props;
    let errordescription=InvalidDescription();
    const param = {
      OfferingId: parseInt(filterDetails.process),
      CatType: data.CategoryType.toString().trim(),
      CatName: data.CategoryName.toString().trim(),
      LocationID: filterDetails.Locations,
      SOCategoryID: 2, // For H&M
      OrgEntityID: filterDetails.Client,
    };
    let error = {};
    if (!data.CategoryType.trim()) {
      error["CategoryType"] = i18n.t("catTypeReqd");
    }
    else if(!isValidText(data.CategoryType.trim())){
      error["CategoryType"] = errordescription;
    }

    if (!data.CategoryName.trim()) {
      error["CategoryName"] = i18n.t("catNameReqd");
    }
    else if(!isValidText(data.CategoryName.trim())){
      error["CategoryName"] = errordescription;
    }
    this.setState({ errors: error });

    if (data.CategoryType.trim() && data.CategoryName.trim()) {
      axiosInstance
        .post(`${LocalApiBaseUrl}MultiLevelApproval/SendCategoryTypeNameForApproval`, param, {})
        .then((response) => {
          if (response.data.IsSuccess && String(response.data.StatusMessage) === "Sent For Approval") {
            this.setState({
              showaddCategory: false,
              showaddCategoryType: false,
              showApproval: true,
            });
          } else {
            window.alert(response.data.StatusMessage);
            this.setState({ showLoaderOnPage: false });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          window.alert(this.ssErrMsg + error);
        });
    }
  }

  handleApprovalClose = () => {
    this.setState({ showApproval: false });
  }
  getFormattedDate = (date) => {
    if (!date) return "";
    let newDate = new Date(date);
    return (
      [newDate.getMonth() + 1, newDate.getDate(), newDate.getFullYear()].join("/") +
      " " +
      [newDate.getHours(), newDate.getMinutes(), newDate.getSeconds()].join(":")
    );
  };

  /* End of Multi Level Functions */

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.callingFunction();
    }
  }

  getKpiMAppingDetails = () => {
    let valueConnectorEdit = false;
    let mappedConnectorName;
    const { responseMetricData, kpiData } = this.props
    let mappingData = responseMetricData;
    let isaccentureOwned = false;
    let allCategoryTypesAndNames = []
    if (kpiData) {
      let mappedConnector = mappingData.filter((x) => String(x.KpiMappingID) === String(kpiData.KPIMappingID));

      if (mappedConnector.length > 0) {
        this.setState({
          KpiMetricConnectorID: parseInt(mappedConnector[0].KpiMetricConnectorID) !== 0 ? true : false,
          isaccentureOwned: mappedConnector[0].IsAccentureOwned,
        });
        this.isConnectorMetricDataFound = mappedConnector[0].IsConnectorMetricDataFound;
        this.isSelectedMetricMappedToConnector = mappedConnector[0].IsConnector;
        this.selectedMetricConnectorLastUpdated = mappedConnector[0].ConnectorUpdatedTS;
        allCategoryTypesAndNames = mappedConnector[0].CategoryList

        if (
          kpiData &&
          (String(kpiData.ConnectorID) === String(undefined) ||
            parseInt(kpiData.ConnectorID) === 0 ||
            (kpiData.ConnectorNames == null || String(kpiData.ConnectorNames) === String(undefined)))
        ) {
          if (
            mappedConnector.length > 0 &&
            String(mappedConnector[0].ConnectorName) !== "" &&
            mappedConnector[0].ConnectorName != null
          ) {
            mappedConnectorName = mappedConnector[0].ConnectorName;
            let mappedConnectID = mappedConnector[0].ConnectorID;

            kpiData.ConnectorNames = mappedConnectorName;
            kpiData.ConnectorID = mappedConnectID;
            const { data } = this.state;
            if (data) {
              let kpiData = data.kpiData;
              kpiData.ConnectorNames =
                mappedConnectorName != null && String(mappedConnectorName) !== String(undefined)
                  ? mappedConnectorName
                  : kpiData.ConnectorNames;
              kpiData.ConnectorID = mappedConnectID > 0 ? mappedConnectID : kpiData.ConnectorID;
              this.setState({
                kpiData: kpiData,
              });
            }
          }
        }
      }

      if (mappedConnectorName != null) {
        valueConnectorEdit = true;
      }
    }
    this.setState({ MetricNameOptions: responseMetricData, valueConnectorEdit, IsAccentureOwned: isaccentureOwned, allCategoryTypesAndNames });
  }

  onClose(e) {
    this.setState({ viewmore: false });
    this.props.onClose(e);
  }

  onNameChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const kpiData = this.state.kpiData;
    kpiData.KPIMappingID = event.target.value;
    kpiData.SourceName = "";
    kpiData.SourceFieldName = "";
    let allCategoryTypesAndNames = []
    kpiData.MetricValue = "";
    if (!event.target.value) {
      kpiData.KPIDesp = "";
      kpiData.MetricUnit = "";
      kpiData.MetricName = null;
      kpiData.MetricSeq = "";
      kpiData.KPIMappingID = "";
      kpiData.LeadingPractice = "";
      kpiData.FormulaList = [];
      this.isConnectorMetricDataFound = true;
      this.isSelectedMetricMappedToConnector = false;
      this.selectedMetricConnectorLastUpdated = undefined;

      // RESET THE METRIC DEFINITION DROPDOWN OPTIONS AND SELECTED METRIC DEFINITION
      kpiData.MetricDefination = [];
      kpiData.MetricDefinitionID = "";

      this.setState({
        kpiData: kpiData,
      });
    } else {
      const { MetricNameOptions } = this.state;
      if (kpiData.KPIMappingID !== "-1") {
        let selectedMetric = MetricNameOptions.filter((data) => String(data.KpiMappingID) === String(event.target.value))[0];
        this.isConnectorMetricDataFound = selectedMetric.IsConnectorMetricDataFound;
        this.isSelectedMetricMappedToConnector = selectedMetric.IsConnector;
        this.selectedMetricConnectorLastUpdated = selectedMetric.ConnectorUpdatedTS;
        kpiData.MetricValue = selectedMetric.IsConnector ? selectedMetric.MetricValue : "";
        kpiData.KPIDesp = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value)
        )[0].MetricDescription;
        kpiData.MetricUnit = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value)
        )[0].MetricUnit;
        kpiData.MetricName = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value)
        )[0].MetricName;
        kpiData.LeadingPractice = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value)
        )[0].LeadingPractice;
        kpiData.MetricSeq = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value)
        )[0].MetricSeq;
        kpiData.BaselineValue = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value)
        )[0].BaselineValue;
        kpiData.BaseLineMonth = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value)
        )[0].BaseLineMonth;
        let BaselineMonthReadOnly=kpiData.BaselineValue?false:true;
        this.setState({BaselineMonthReadOnly:BaselineMonthReadOnly})
        kpiData.TargetValue = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value)
        )[0].TargetValue;
        kpiData.SOCategoryID = this.props.SOCategoryID;
        kpiData.FormulaList = selectedMetric.FormulaList

        // SET KPI MULTIPLE DEFINITIONS
        kpiData.MetricDefination = MetricNameOptions.filter(
          (data) => String(data.KpiMappingID) === String(event.target.value)
        )[0].MetricDefination;

        const kpiMetricDefinitions = _.get(this.state, "kpiData.MetricDefination", []);

        const enabledMetricDefinitions = _.filter(
          kpiMetricDefinitions,
          (eachMetricDefinition) => String(eachMetricDefinition.IsEnabled) === "true"
        );

        // RESET THE SELECTED METRIC DEFINITION
        kpiData.MetricDefinitionID = "";

        //GETTING CATEGORY TYPES AND CATEGORIES NAMES
        allCategoryTypesAndNames = selectedMetric.CategoryList

        if (enabledMetricDefinitions.length === 0) {
          const kpiDat = this.state.kpiData;
          kpiDat.MetricDefinitionID = -1;
          this.setState({
            kpiData: kpiDat,
          });
        }
      } else {
        this.setState({
          viewmore: true,
          show: false,
        });
      }

      let con = "";
      let valueConnector = "";
      let metriName = this.state.MetricNameOptions.filter((eachValue) => String(eachValue.KpiMappingID) === String(event.target.value));
      if (metriName.length > 0) {
        let connectorName = metriName[0].ConnectorName;
        let connectorId = metriName[0].ConnectorID;
        let criteria = metriName[0].Criteria;
        let MetricValue = metriName[0].MetricValue;
        let IsAccentureOwned = metriName[0].IsAccentureOwned;
        let KpiMetricConnectorID = metriName[0].KpiMetricConnectorID;

        if (connectorName == null) {
          con = false;
          kpiData.ConnectorNames = connectorName;
          kpiData.ConnectorID = connectorId;
          kpiData.SourceFieldName = criteria;
          kpiData.MetricValue = "";
          kpiData.IsAccentureOwned = IsAccentureOwned;
          kpiData.KpiMetricConnectorID = KpiMetricConnectorID;
        } else {
          con = true;
          kpiData.ConnectorNames = connectorName;
          kpiData.ConnectorID = connectorId;
          kpiData.SourceFieldName = criteria;
          kpiData.MetricValue = MetricValue;
          kpiData.IsAccentureOwned = IsAccentureOwned;
          kpiData.KpiMetricConnectorID = KpiMetricConnectorID;
          if (String(metriName[0].IsConnector) === String(true) && String(metriName[0].IsTransaction) === String(false)) {
            valueConnector = true;
          }
        }
      }

      let connectorOptions = metriName.map((eachProcess) => (
        <option key={eachProcess.ConnectorID} value={eachProcess.ConnectorID}>
          {eachProcess.ConnectorName}
        </option>
      ));
      let criteriaOptions = metriName.map((eachProcess, index) => (
        <option key={index} value={eachProcess.Criteria}>
          {eachProcess.Criteria}
        </option>
      ));

      this.setState({
        kpiData: kpiData,
        con,
        connectorOptions,
        criteriaOptions,
        valueConnector,
        allCategoryTypesAndNames
      });
    }
  }
  onDescriptionChange(event) {
    const kpiData = this.state.kpiData;
    kpiData.KPIDesp = event.target.value;
    this.setState({
      kpiData: kpiData,
    });
  }
  onSequenceChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const kpiData = this.state.kpiData;
    kpiData.MetricSeq = event.target.value;
    this.setState({
      kpiData: kpiData,
    });
  }
  onUnitChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const kpiData = this.state.kpiData;
    kpiData.MetricUnit = event.target.value;
    this.setState({
      kpiData: kpiData,
    });
  }
  onValueChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const kpiData = this.state.kpiData;
    if (event.target.value === "") {
      kpiData.MetricValue = event.target.value;
      this.setState({
        kpiData: kpiData,
      });
    } else {
      if (regexForNegativeNumbers.test(event.target.value)) {
        if (event.target.value === "-.") {
          kpiData.MetricValue = "-0.";
        } else {
          kpiData.MetricValue = event.target.value;
        }

        this.setState({
          kpiData: kpiData,
        });
      }
    }

  }
  onValDescChange(event) {
    let str = event.target.value;
    let err = this.state.commentsError;
    if (event.target.value) {
      if (regexForTextAreas.test(str)) {
        if (str && str.length < 300) {
          const kpiData = this.state.kpiData;
          kpiData.Comments = event.target.value;
          err = "";
          this.setState({
            kpiData: kpiData,
            commentsError: err
          });
        } else {
          const kpiData = this.state.kpiData;
          kpiData.Comments = event.target.value;
          err = i18n.t("commentsErrorM1");
          this.setState({
            kpiData: kpiData,
            commentsError: err
          });
        }
      } else {
        const kpiData = this.state.kpiData;
        kpiData.Comments = event.target.value;
        err = i18n.t("commentsErrorM2");
        this.setState({
          kpiData: kpiData,
          commentsError: err
        });
      }
    } else {
      const kpiData = this.state.kpiData;
      kpiData.Comments = event.target.value;
      err = "";
      this.setState({
        kpiData: kpiData,
        commentsError: err
      });
    }
  }
  onUpperChange(event) {
    const kpiData = this.state.kpiData;
    if (event.target.value === "") {
      kpiData.UpperThreshold = event.target.value;
      this.setState({
        kpiData: kpiData
      });
    } else {
      if (regexForNumbersOnlyMTC.test(event.target.value)) {
        kpiData.UpperThreshold = event.target.value;
        this.setState({
          kpiData: kpiData
        });
      }
    }

  }
  onLowerChange(event) {
    const kpiData = this.state.kpiData;
    if (event.target.value === "") {
      kpiData.LowerThreshold = event.target.value;
      this.setState({
        kpiData: kpiData
      });
    } else {
      if (regexForNumbersOnlyMTC.test(event.target.value)) {
        kpiData.LowerThreshold = event.target.value;
        this.setState({
          kpiData: kpiData
        });
      }
    }

  }
  onBaselineValueChange(event) {
    const kpiData = this.state.kpiData;
    if (event.target.value === "") {
      kpiData.BaselineValue = event.target.value;
      kpiData.BaseLineMonth = "";
      this.setState({
        kpiData: kpiData,
        BaselineMonthReadOnly: true
      });
    } else {
      if (regexForNegativeNumbers.test(event.target.value)) {
        if (event.target.value === "-.") {
          kpiData.BaselineValue = "-0.";
        } else {
          kpiData.BaselineValue = event.target.value;
        }
        this.setState({
          kpiData: kpiData,
          BaselineMonthReadOnly: false
        });
      }
    }
  }
  onBaselineMonthChange = (date) => {
    const kpiData = this.state.kpiData;
    kpiData.BaseLineMonth=date;
    this.setState({
      kpiData: kpiData
    })
  }
  onTargetValueChange(event) {
    const kpiData = this.state.kpiData;
    if (event.target.value === "") {
      kpiData.TargetValue = event.target.value;
      this.setState({
        kpiData: kpiData
      });
    } else {
      if (regexForNegativeNumbers.test(event.target.value)) {
        if (event.target.value === "-.") {
          kpiData.TargetValue = "-0.";
        } else {
          kpiData.TargetValue = event.target.value;
        }
        this.setState({
          kpiData: kpiData
        });
      }
    }
  }
  maxLengthCheckThreshold = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
  }
  onStatusChange(event) {
    let errors = {};
    this.setState({
      errors: errors
    });
    const kpiData = this.state.kpiData;
    kpiData.RAGStatus = event.target.value;
    this.setState({
      kpiData: kpiData
    });
  }
  onImpactChange =(event)=> {
    // let errors = {};
    // this.setState({
    //   errors: errors
    // });
    const kpiData = this.state.kpiData;
    kpiData.Impact = event.target.value;
    this.setState({
      kpiData: kpiData
    });
  }
  onFTEChange(event){
    const kpiData = this.state.kpiData;
    if (event.target.value === "") {
      kpiData.FTEReleased = event.target.value;
      this.setState({
        kpiData: kpiData
      });
    } else if(regexForPositiveInteger.test(event.target.value)) {
        kpiData.FTEReleased = event.target.value;
        this.setState({
          kpiData: kpiData
        });
      
    }else{
      kpiData.FTEReleased = "";
        this.setState({
          kpiData: kpiData
        });
    }
  }
  onToolOwnerChange(event) {
    const kpiData = this.state.kpiData;
    kpiData.IsAccentureOwned = event.target.checked;
    this.setState({
      kpiData: kpiData
    });
  }
  onSourceChange(event) {
    const kpiData = this.state.kpiData;
    kpiData.ConnectorID = event.target.value;
    let index = event.nativeEvent.target.selectedIndex;
    kpiData.ConnectorNames = event.nativeEvent.target[index].text;
    kpiData.SourceName = "";
    kpiData.SourceFieldName = "";
    this.setState({
      kpiData: kpiData
    });
  }

  onSourceSystemChange(event) {
    const kpiData = this.state.kpiData;
    kpiData.SourceFieldName = event.target.value;
    this.setState({
      kpiData: kpiData,
    });
  }
  onOtherSystemChange(event) {
    const kpiData = this.state.kpiData;
    kpiData.SourceName = event.target.value;
    this.setState({
      kpiData: kpiData,
    });
  }

  getGetConnectorMaster = () => {
    let source = [];
    axiosInstance.get(`${LocalApiBaseUrl}ConnectorMapping/GetConnectorMaster`).then((response) => {
      source = response.data;
      let sourceValues = source.map((x) => (
        <option key={x.ConnectorID} value={x.ConnectorID}>
          {x.ConnectorName}
        </option>
      ));
      this.setState({ sourceOptions: sourceValues });
    })
      .catch((error) => {
        trycatchAlertPopup(error);
      });


  }
  handleSave(e) {
    if (this.validateForm()) {
      let filter = this.state.filter;
      const { filterDetails, updateFlag, type } = this.props
      filter.LocationID = filterDetails.Locations;
      filter.OrgEntityID = filterDetails.Client;
      filter.OfferingID = filterDetails.process;
      filter.MonthDT = filterDetails.Month;

      const data = { SOCategoryID: "", kpiData: "" };
      data.SOCategoryID = this.props.SOCategoryID;
      data.kpiData = this.state.kpiData;
      if (data.kpiData.IsMultiLevel === false && data.kpiData.CategoryDetails.length !== 0) {
        data.kpiData.CategoryDetails = []
      }
      if(data.kpiData.BaseLineMonth!=null && data.kpiData.BaseLineMonth!=""){
        var baselinemonth= new Date(data.kpiData.BaseLineMonth).getFullYear() +
        "-" +
        (new Date(data.kpiData.BaseLineMonth).getMonth() + 1 < 10
          ? "0" + (new Date(data.kpiData.BaseLineMonth).getMonth() + 1)
          : new Date(data.kpiData.BaseLineMonth).getMonth() + 1);
        data.kpiData.BaseLineMonth=baselinemonth;
      }
      this.setState({
        data: data,
      });

      let ConfiguratorSavedViewModel = {
        ConfiguratorDataModels: data,
        FiltersIDsVM: filter,
      };

      axiosInstance
        .post(`${LocalApiBaseUrl}Configurator/SaveConfiguratorDealMetrics`, ConfiguratorSavedViewModel)
        .then((response) => {
          if (response.data.IsSuccess) {
            axiosInstance
              .get(`${LocalApiBaseUrl}Configurator/GetHumanMachineTalentQualityScore`, {
                params: {
                  LocationID: filterDetails.Locations,
                  OrgEntityID: filterDetails.Client,
                  OfferingID: filterDetails.process,
                  MonthDT: filterDetails.Month,
                  LanguageID: store.getState().CurrentFilter.languageData.id,
                },
              })
              .then((response) => {
                if (response.data) {
                  updateFlag()
                  store.dispatch({
                    type: "SAVE_QC_SCORE_OF_EACH_TAB",
                    payload: response.data,
                  });
                }

                this.handleHide(e);
              })
              .catch((error) => {
                trycatchAlertPopup(error);
                this.handleHide(e);
              });

            let human = {
              kpiData: response.data.ConfiguratorDataModels.kpiData,
              selectedOption: this.props.selectedOption
            }

            let machine = {
              kpiData: response.data.ConfiguratorDataModels.kpiData,
              machineWorkforceCat: this.props.machineWorkforceCat
            }
            if (String(type) === String(this.props.selectedOption)) {
              store.dispatch({
                type: "ADD_EDIT_HUMAN",
                payload: human,
              });
            }

            else {
              store.dispatch({
                type: "ADD_EDIT_MACHINE",
                payload: machine,
              });
            }
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          this.handleHide(e);
        });


    }
  }

  handleHide(e) {
    const { onClose } = this.props;
    this.setState({ show: false });
    if (String(e) !== String(undefined)) {

      if ((String(e.target.textContent) !== "×")) {
        onClose(e);
      } else {
        onClose(e);
      }
    } else {
      onClose(e);
    }
  }
  handleRejectHide(e) {
    const { onRejectClose } = this.props;
    this.setState({ show: false });
    if (String(e) !== String(undefined)) {

      if ((String(e.target.textContent) !== "×")) {
        onRejectClose();
      }
    } else {
      onRejectClose();
    }
  }
  handleApproveHide(e) {
    const { onApproveClose } = this.props;
    this.setState({ show: false });
    if (String(e) !== String(undefined)) {

      if ((String(e.target.textContent) !== "×")) {
        onApproveClose();
      }
    } else {
      onApproveClose();
    }
  }
  passToConfirmation(data) {
    this.props.enteredData(data.PoNumber);
  }

  validateForm() {
    const kpiData = this.state.kpiData;
    let errordescription=InvalidDescription();
    let errors = {};
    let formIsValid = true;
    let err = "";
    if(kpiData.Comments){
      if(kpiData.Comments.length>300){
      err = i18n.t("commentsErrorM1");
      formIsValid  = false;
      }
      else{
        if(!isValidText(kpiData.Comments)){
          err = errordescription;
          formIsValid  = false;
        }
      }
      this.setState({
         commentsError: err
      });
    }

    if (this.isSelectedMetricMappedToConnector && !this.isConnectorMetricDataFound) {
      formIsValid = false;
      errors["MetricConnectorDataNotFound"] = i18n.t("Data_not_available_in_source_system") + ".";
      errors["IntegratedMetricsMessage"] = i18n.t("Integrated_metrics_will_get_auto_populated") + ".";
      if (this.selectedMetricConnectorLastUpdated) {
        errors["MetricConnectorLastPull"] =
          i18n.t("Last_pull_occurred_on") + " " + FormatDate(this.selectedMetricConnectorLastUpdated) + ".";
      } else {
        errors["MetricConnectorLastPull"] = i18n.t("Last_pull_occurred") + ' "' + i18n.t("Date_Unavailable") + "”.";
      }
    }
    if (!kpiData["MetricName"]) {
      formIsValid = false;
      errors["MetricName"] = i18n.t("addWoErrorM1") + ".";
    }
    if (kpiData.SourceFieldName) 
  {
    if(!isValidText(kpiData.SourceFieldName)){
      formIsValid = false;
      errors["SourceFieldName"] = errordescription;
    }
  }
    if (this.state.commentsError) {
      formIsValid = false;
    }
    if (!this.isSelectedMetricMappedToConnector) {
      if (!kpiData["MetricValue"]) {
        formIsValid = false;
        errors["MetricValue"] = i18n.t("addBoErrorM1") + ".";
      } else if (!(kpiData["MetricValue"] >= -999999999999 && kpiData["MetricValue"] <= 999999999999)) {
        formIsValid = false;
        errors["MetricValueRange"] = i18n.t("addBoErrorM6");
      }
    }
    if (!(kpiData["BaselineValue"] >= -99999999 && kpiData["BaselineValue"] <= 99999999)) {
      formIsValid = false;
      errors["BaselineValue"] = i18n.t("Baseline_validation");
    }

    if (!(kpiData["TargetValue"] >= -99999999 && kpiData["TargetValue"] <= 99999999)) {
      formIsValid = false;
      errors["TargetValue"] = i18n.t("target_validation");
    }
    if (kpiData["MetricSeq"]) kpiData["MetricSeq"] = parseInt(kpiData["MetricSeq"], 10);
    if (!kpiData["MetricSeq"]) {
      formIsValid = false;
      errors["MetricSeq"] = i18n.t("addWoErrorM5") + ".";
    } else if (!(kpiData["MetricSeq"] >= 0 && kpiData["MetricSeq"] <= 999999)) {
      formIsValid = false;
      errors["MetricSeqRange"] = i18n.t("addWoErrorM6");
    }
    if (!kpiData["RAGStatus"]) {
      formIsValid = false;
      errors["RAGStatus"] = i18n.t("addBoErrorM3") + ".";
    }
    if (!kpiData["ConnectorID"]) {
      formIsValid = false;
      errors["ConnectorID"] = i18n.t("Source_is_required");
    }
    if (kpiData["MetricDataDesp"]) {
      let isValid = regexForFields.test(kpiData["MetricDataDesp"]);
      if (!isValid) {
        formIsValid = false;
        errors["ValueDescription"] = i18n.t("addBoErrorM4");
      }
    }

    //Validate multi level categories
    if (kpiData["IsMultiLevel"]) {
      if (kpiData.CategoryDetails.length === 0) {
        errors["MultiLevel"] = i18n.t("noCategories");
        formIsValid = false;
      }
    }

    //end validate multi level categories

    this.setState({
      errors: errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  // WOULD BE EXECUTED WHEN THE "METRIC DEFINITION" DROPDOWN WOULD BE CHANGED
  handleChangeMetricDefinition = (e) => {
    const MetricDefinitionID = e.target.value;
    this.setState((prevState) => ({
      kpiData: {
        ...prevState.kpiData,
        MetricDefinitionID,
      },
    }));
  };

  render() {
    const currentDate = new Date();

    const stateKpiData = this.state.kpiData;
    let items = [];
    if (stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0) {
      items = stateKpiData.CategoryDetails.map((item, key) => (
        <li>
          <span className="contentName">{item.CategoryName}</span>
          {stateKpiData.MetricUnit.trim() !== "%" && (
            <span className="contentValues">
              <span>
                {parseInt(thousandSeparatorFormat(item.NumeratorValue) % 1) !== 0
                  ? MetricValueThousandFormat(stateKpiData.MetricUnit, item.NumeratorValue, 2)
                  : MetricValueThousandFormat(stateKpiData.MetricUnit, item.NumeratorValue, 2)}
              </span>
              {item.Denominator && <span> | </span>}
              <span>
                {item.Denominator
                  ? parseInt(thousandSeparatorFormat(item.Denominator) % 1) !== 0
                    ? MetricValueThousandFormat(stateKpiData.MetricUnit, item.Denominator, 2)
                    : MetricValueThousandFormat(stateKpiData.MetricUnit, item.Denominator, 2)
                  : ""}
              </span>{" "}
            </span>
          )}

          {stateKpiData.MetricUnit.trim() === "%" && (
            <span className="contentValues">
              <span>
                {parseInt(thousandSeparatorFormat((item.NumeratorValue * 100) / item.Denominator) % 1) !== 0
                  ? thousandSeparatorFormat(((item.NumeratorValue * 100) / item.Denominator), 2)
                  : thousandSeparatorFormat(((item.NumeratorValue * 100) / item.Denominator), 2)}
              </span>{" "}
              {stateKpiData.MetricUnit}
            </span>
          )}

          <div className="item-list-hover">
            <span>
              <i onClick={() => this.deleteCategoryName(item.CategoryName)} class="far fa-times">
                {" "}
              </i>
              <i onClick={() => this.handleCategoryEditShow(item.CategoryName)} class="fas fa-edit"></i>
            </span>
          </div>
        </li>
      ));
    }

    let isScreenInEditMode = false;
    if (!_.isEmpty(this.props.kpiData)) {
      isScreenInEditMode = true;
    }

    // FILTER OUT THE METRIC DEFINITIONS THAT ARE "ENABLED"
    const kpiMetricDefinitions = _.get(this.state, "kpiData.MetricDefination", []);
    const enabledMetricDefinitions = _.filter(
      kpiMetricDefinitions,
      (eachMetricDefinition) => String(eachMetricDefinition.IsEnabled) === "true"
    );
    const { CatNameID, show, kpiData, MetricNameOptions, errors, valueConnector, KpiMetricConnectorID, BaselineMonthReadOnly, commentsError, con, connectorOptions, sourceOptions, valueConnectorEdit, showCategory, isEdit, iscatypedisable, CatTypeID, allCategoryTypesAndNames, CategoryNameData, numeratorVal, denomVal, showaddCategoryType, CategoryDetails, showaddCategory, showApproval, viewmore } = this.state;
    const { CatagoryName, SOCategoryID, filterDetails } = this.props;
    return (
      <>
        {/* <Header /> */}

        <Modal
          show={show}
          onHide={this.handleHide}
          className="modal fade human-machine-configurator-modal"
          size="medium"
          id="dealpopup"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title" id="dealprofile">
              {i18n.t("Configurator_Human+Machine_Talent")}- {i18n.t(CatagoryName)}
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <div className="row">
                  <div className="form-group col-lg-7">
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label for="">
                          {i18n.t("Name")} <span className="required"> *</span>
                        </label>

                        <select
                          id="name"
                          disabled={isScreenInEditMode}
                          className="form-control"
                          ref="MetricNameDropdown"
                          onChange={this.onNameChange}
                          value={kpiData.KPIMappingID}
                        >
                          <option value="">{i18n.t("Select_Metric_Name")}</option>
                          {MetricNameOptions.map((row) => (

                            <option value={row.KpiMappingID}>{row.MetricName}</option>

                          ))}
                          <option value="-1">&lt;{i18n.t("Add_New")}&gt;</option>
                        </select>
                        <span className="errorMsg">{errors.MetricName}</span>
                      </div>

                      <div className="form-group col-lg-6">
                        <label for="">
                          {i18n.t("Unit_of_Measure")}
                          <span className="required"> *</span>
                        </label>
                        <input
                          readOnly
                          type="text"
                          maxlength="50"
                          className="form-control"
                          id=""
                          onChange={this.onUnitChange}
                          value={kpiData.MetricUnit}
                        />
                        <span className="errorMsg">{errors.MetricUnit}</span>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label for="">
                          {i18n.t("Sequence")}
                          <span className="required"> *</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id=""
                          onChange={this.onSequenceChange}
                          value={kpiData.MetricSeq}
                        />
                        <span className="errorMsg">{errors.MetricSeq}</span>
                        <div className="errorMsg">{errors.MetricSeqRange}</div>
                      </div>

                      <div className="form-group col-lg-6">
                        <label>
                          {i18n.t("Leading_Practice")}{" "}
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={<Tooltip id="tooltip-top">{i18n.t("addBOMess2")}</Tooltip>}
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </OverlayTrigger>
                        </label>
                        <input
                          type="text"
                          disabled
                          maxLength="50"
                          className="form-control"
                          value={kpiData.LeadingPractice}
                        />
                        <div className="errorMsg">{errors.LeadingPractice}</div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-12">
                        <label for="">{i18n.t("Description")}</label>
                        <input
                          readOnly
                          maxLength="255"
                          type="text"
                          className="form-control"
                          id=""
                          onChange={this.onDescriptionChange}
                          value={kpiData.KPIDesp}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-12">
                        <label>
                          {i18n.t("Metric_Definition")}{" "}
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={<Tooltip id="tooltip-top">{i18n.t("addBOMess3")}</Tooltip>}
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </OverlayTrigger>
                        </label>
                        {enabledMetricDefinitions.length > 0 ? (
                          <select
                            value={kpiData.MetricDefinitionID}
                            onChange={(e) => this.handleChangeMetricDefinition(e)}
                            className="form-control"
                          >
                            <option value="">{i18n.t("Select_Metric_Definition")}</option>
                            {_.map(enabledMetricDefinitions, (eachMetricDefinition) => {
                              return (
                                <option
                                  value={eachMetricDefinition.KPIMetricDefinatoinID}
                                  key={eachMetricDefinition.KPIMetricDefinatoinID}
                                >
                                  {_.isEmpty(eachMetricDefinition.DenominatorDescription)
                                    ? eachMetricDefinition.NumeratorDescription
                                    : `${eachMetricDefinition.NumeratorDescription} / ${eachMetricDefinition.DenominatorDescription}`}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            value={i18n.t("Definition_Not_Available")}
                            disabled
                          ></input>
                        )}
                        <div className="errorMsg">{errors.MetricDefinitionID}</div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label for="">
                          {i18n.t("Value")}
                          <span className="required"> *</span>
                        </label>
                        {valueConnector ? (
                          <input
                            type="text"
                            maxlength="12"
                            className="form-control"
                            id=""
                            onChange={this.onValueChange}
                            value={kpiData.MetricValue}
                            readOnly
                          />
                        ) : KpiMetricConnectorID ? (
                          <input
                            type="text"
                            maxlength="12"
                            className="form-control"
                            id=""
                            onChange={this.onValueChange}
                            value={kpiData.MetricValue}
                            readOnly
                          />
                        ) : (
                          <input
                            readOnly={stateKpiData.IsAggregation}
                            type="text"
                            className="form-control"
                            maxlength="12"
                            id=""
                            onChange={this.onValueChange}
                            value={kpiData.MetricValue}
                            disabled={KpiMetricConnectorID}
                          />
                        )}
                        <span className="errorMsg">{errors.MetricValue}</span>
                        <div className="errorMsg">
                          {errors.MetricConnectorDataNotFound}
                          {errors.MetricConnectorLastPull ? (
                            <div> {errors.MetricConnectorLastPull} </div>
                          ) : (
                            ""
                          )}
                          {errors.MetricConnectorDataNotFound ? (
                            <div>{errors.IntegratedMetricsMessage}</div>
                          ) : (
                            ""
                          )}
                        </div>
                        <span className="errorMsg">{errors.MetricValueRange}</span>
                      </div>
                      <div className="form-group col-lg-6">
                        <label for="">
                          {i18n.t("Status")} <span className="required"> *</span>
                        </label>

                        <select
                          class="form-control"
                          value={kpiData.RAGStatus}
                          onChange={this.onStatusChange}
                        >
                          <option value="">--{i18n.t("Select")}--</option>
                          <option value="Grey">{i18n.t("Grey")}</option>
                          <option value="Green">{i18n.t("Green")}</option>
                          <option value="Red">{i18n.t("Red")}</option>
                          <option value="Amber">{i18n.t("Amber")}</option>
                        </select>
                        <span className="errorMsg">
                          {errors.RAGStatus}
                        </span>
                      </div>
                    </div>
                    {this.props.machineWorkforceCat !== undefined &&
                    <div className="row">
                    <div className="form-group col-lg-6">
                        <label for="">{i18n.t("Impact")}</label>
                        <select
                          class="form-control"
                          value={kpiData.Impact}
                          onChange={(e) =>this.onImpactChange(e)}
                        >
                          <option value="">{i18n.t("Select Impact value")}</option>
                          <option value="Productivity">{i18n.t("Productivity")}</option>
                          <option value="Quality">{i18n.t("Quality")}</option>
                          <option value="Control/Compliance">{i18n.t("Control/Compliance")}</option>
                          <option value="Sales/Revenue/Growth">{i18n.t("Sales/Revenue/Growth ")}</option>
                        </select>
                      </div>
                      <div className="form-group col-lg-6">
                        <label for="">{i18n.t("FTE Released")}</label>
                        <input
                          type="text"
                          maxLength="9"
                          className="form-control"
                          onInput={this.maxLengthCheckThreshold}
                          onChange={this.onFTEChange}
                          value={kpiData.FTEReleased}
                        />
                      </div>
                    </div>
                    }
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label for="">{i18n.t("Upper_Threshold")}</label>
                        <input
                          type="number"
                          maxLength="12"
                          onInput={this.maxLengthCheckThreshold}
                          className="form-control"
                          id=""
                          onChange={this.onUpperChange}
                          value={kpiData.UpperThreshold}
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <label for="">{i18n.t("Lower_Threshold")}</label>
                        <input
                          type="number"
                          maxLength="12"
                          onInput={this.maxLengthCheckThreshold}
                          className="form-control"
                          id=""
                          onChange={this.onLowerChange}
                          value={kpiData.LowerThreshold}
                        />
                      </div>
                    </div>
                    <div className="row border-box">
                      <div className="form-group col-lg-4">
                        <label>{i18n.t("Baseline_Value")}</label>
                        <input
                          type="text"
                          maxLength="12"
                          onInput={this.maxLengthCheckThreshold}
                          className="form-control"
                          onChange={this.onBaselineValueChange}
                          value={kpiData.BaselineValue}
                        />
                        <div className="errorMsg">
                          {errors.BaselineValue}
                        </div>
                      </div>
                      <div className="form-group col-lg-4">
                        <label for="">{i18n.t("Baseline_Month")}</label>
                        <div className="datepicker-label-wrapper">
                          <DatePicker
                            className="form-control"
                            id="BaseLineMonth"
                            maxDate={currentDate}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            selected={Date.parse(this.state.kpiData.BaseLineMonth)
                              ? new Date(Date.parse(this.state.kpiData.BaseLineMonth))
                              : null
                            }
                            onSelect={(date) => {
                            this.onBaselineMonthChange(date);
                            }}
                            readOnly={this.state.BaselineMonthReadOnly}
                          />
                        </div>
                      </div>

                      <div className="form-group col-lg-4">
                        <label>{i18n.t("Target_Value")}</label>
                        <input
                          type="text"
                          maxLength="12"
                          onInput={this.maxLengthCheckThreshold}
                          className="form-control"
                          onChange={this.onTargetValueChange}
                          value={kpiData.TargetValue}
                        />
                        <div className="errorMsg">
                          {errors.TargetValue}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-lg-12">
                        <label for="">{i18n.t("Comments")}</label>
                        <textarea
                          rows="4"
                          name="comment"
                          maxlength="300"
                          className="form-control"
                          onChange={this.onValDescChange}
                          value={kpiData.Comments}
                        />
                        <span className="errorMsg">
                          {commentsError}
                        </span>
                      </div>
                    </div>
                    <div className="row border-box">
                      <div className="form-group col-lg-4">
                        <label for="" className="source-system-padding">
                          {i18n.t("Source_System")}<span className="required "> *</span>
                        </label>
                        {con ? (
                          <select
                            className="form-control"
                            readOnly
                            onChange={this.onSourceChange}
                            value={kpiData.ConnectorID}
                          >
                            {connectorOptions}
                          </select>
                        ) : KpiMetricConnectorID ? (
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            onChange={this.onSourceChange}
                            value={this.props.kpiData.ConnectorNames}
                          ></input>
                        ) : (
                          <select
                            className="form-control"
                            onChange={this.onSourceChange}
                            value={kpiData.ConnectorID}
                            disabled={KpiMetricConnectorID}
                          >
                            <option value=""></option>
                            {sourceOptions}
                            <option value="-1">{i18n.t("Others")}</option>
                            <option value="-2">{i18n.t("Manual")}</option>
                          </select>
                        )}
                        <span className="errorMsg">
                          {errors.ConnectorID}
                        </span>
                      </div>
                      <div className="form-group col-lg-4">
                        <label for="">
                          {i18n.t("Source_System_Fields")}<span className="required"></span>
                        </label>
                        {con ||
                          String(kpiData.ConnectorNames) === "Manual" ? (
                          <input
                            onChange={this.onSourceSystemChange}
                            type="text"
                            maxlength="50"
                            className="form-control"
                            id=""
                            readOnly
                            value={kpiData.SourceFieldName}
                          />
                        ) : valueConnectorEdit ? (
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            onChange={this.onSourceChange}
                            value={this.props.kpiData.SourceFieldName}
                          ></input>
                        ) : (
                          <input
                            onChange={this.onSourceSystemChange}
                            type="text"
                            maxlength="50"
                            className="form-control"
                            id=""
                            value={kpiData.SourceFieldName}
                          />
                        )}
                        <div className="errorMsg">{errors.SourceFieldName}</div>
                      </div>
                      <div className="form-group col-lg-4">
                        <label for="">{i18n.t("Other_Source_System")}</label>
                        {String(kpiData.ConnectorNames) === "Others" ? (
                          <input
                            onChange={this.onOtherSystemChange}
                            type="text"
                            maxlength="50"
                            className="form-control"
                            id=""
                            value={kpiData.SourceName}
                          />
                        ) : (
                          <input
                            onChange={this.onOtherSystemChange}
                            type="text"
                            readOnly
                            maxlength="50"
                            className="form-control"
                            id=""
                            value={kpiData.SourceName}
                          />
                        )}
                        <div className="errorMsg">{errors.KPIDesp}</div>
                      </div>
                      <div className="form-group col-lg-12 mt-2">
                        <label className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="toolowner"
                            defaultChecked={
                              kpiData.IsAccentureOwned
                            }
                            onChange={this.onToolOwnerChange}
                            checked={
                              kpiData.IsAccentureOwned
                            }
                          />
                          <span className="checkmark"></span>
                          <span className="form-check-label">{i18n.t("Accenture_Owned")}</span>
                        </label>
                        <small className="form-text text-muted">
                          <b>{i18n.t("Note")}:</b>{i18n.t("NoteMessage1")}
                        </small>
                      </div>
                    </div>
                  </div>
                  {/* start of multi level UI */}
                  <div className="form-group col-lg-5">
                    <div className="right-wrapper">
                      <div className="catergory-metric-sub">
                        <div className="sub-level">
                          <i className="fas fa-sitemap"></i>
                          {i18n.t("Category_Metric_Sub_Levels")}
                        </div>
                        <div className="toggle-switch">
                          <Form>
                            <Form.Check
                              onClick={this.handleClick}
                              disabled={!stateKpiData.MetricName}
                              checked={stateKpiData.IsMultiLevel}
                              type="switch"
                              id="custom-switch"
                              label=""
                            />
                          </Form>
                        </div>
                      </div>
                      <div class="metric-sub-info">
                        <div className={stateKpiData.IsMultiLevel ? "disable-sub-level-none" : "disable-sub-level"}>
                          <i class="fas fa-sitemap"></i><p>{i18n.t("Enable_to_add_metric_categories_as_its_sub_level")}</p>
                        </div>
                        <div className={stateKpiData.IsMultiLevel ? "enable-sub-level" : "enable-sub-level-none"}>
                          <div class="check-box-info">
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={stateKpiData.IsAggregation}
                                onChange={(e) => {
                                  this.bindAutoAggregationChange(e);
                                }}
                                id="customCheck"
                                name="example1"
                              />
                              <label className="custom-control-label" for="customCheck">
                                {i18n.t("Aggregate_category_values_to_metric_value")}
                              </label>
                            </div>
                          </div>
                          {showCategory &&
                            <Modal
                              className="addCategoryModal"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              show={showCategory}
                              onHide={this.handleCategoryClose}
                              animation={false}
                              backdrop="static"
                            >
                              <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter">
                                  {isEdit ? i18n.t("Edit_Category_Details") : i18n.t("Add_Category_Details")}
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="row">
                                  {!iscatypedisable ? (
                                    <div className="form-group col-lg-12">
                                      <label>
                                        {" "}
                                        {i18n.t("Category_Type")}
                                        <span className="required"> *</span>
                                      </label>
                                      <select
                                        id="name"
                                        className="form-control"
                                        disabled={isEdit}
                                        onChange={this.CatTypeSelect.bind(this)}
                                        value={CatTypeID}
                                      >
                                        <option value="0">{i18n.t("Select_CategoryType")}</option>
                                        {this.removeDuplicates(allCategoryTypesAndNames).map((CT) => {
                                          return (
                                            <option value={CT.ID} key={CT.ID}>
                                              {CT.CategoryType}
                                            </option>
                                          );
                                        })}
                                        <option value="addnew">{i18n.t("Add_New")}</option>
                                      </select>
                                      <div className="errorMsg">{errors.CategoryType}</div>
                                    </div>
                                  ) : (
                                    <div className="form-group col-lg-12">
                                      <label>
                                        {i18n.t("Category_Type")}
                                        <span className="required"> *</span>
                                      </label>
                                      <select
                                        id="name"
                                        className="form-control"
                                        disabled={iscatypedisable}
                                        onChange={this.CatTypeSelect.bind(this)}
                                        value={CatTypeID}
                                      >
                                        <option value="0">{i18n.t("Select_CategoryType")}</option>
                                        {allCategoryTypesAndNames.map((CT) => {
                                          return <option value={CT.ID}>{CT.CategoryType}</option>;
                                        })}
                                        <option value="addnew">{i18n.t("Add_New")}</option>
                                      </select>

                                      <div className="errorMsg">{errors.CategoryType}</div>
                                    </div>
                                  )}
                                  <div className="form-group col-lg-12">
                                    <label>
                                      {i18n.t("Category_Name")}
                                      <span className="required"> *</span>
                                    </label>
                                    <select
                                      id="name"
                                      value={CatNameID}
                                      onChange={this.handleDropdownChange}
                                      className="form-control"
                                    >
                                      <option value="0">{i18n.t("Select_CategoryName")}</option>
                                      {CategoryNameData.map((CN) => {
                                        return <option value={CN.ID}>{CN.CategoryName}</option>;
                                      })}
                                      <option value="addnewcatname">{i18n.t("Add_New")}</option>
                                    </select>
                                    <div className="errorMsg">{errors.CategoryName}</div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="form-group col-lg-6">
                                    <label>
                                      {i18n.t("Numerator_Value")}
                                      <span className="required"> *</span>
                                    </label>
                                    <input
                                      type="number"
                                      maxLength="12"
                                      value={numeratorVal}
                                      onChange={this.onNumeratorChange}
                                      className="form-control"
                                    />
                                    <span className="errorMsg">{errors.NumeratorValue}</span>
                                    <span className="errorMsg">{errors.NumeratorValueRange}</span>
                                  </div>
                                  <div className="form-group col-lg-6">
                                    <label>
                                      {i18n.t("Denominator_Value")}
                                      {stateKpiData.MetricUnit.trim() === "%" && <span className="required"> *</span>}
                                    </label>
                                    <input
                                      type="number"
                                      maxLength="12"
                                      value={denomVal}
                                      onChange={this.onDenomChange}
                                      className="form-control"
                                    />
                                    <span className="errorMsg">{errors.Denominator}</span>
                                    <span className="errorMsg">{errors.DenominatorValueRange}</span>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button className="btn btn-default btn-sm mr-2" onClick={this.handleCategoryClose}>
                                  {i18n.t("Cancel")}
                                </button>
                                {isEdit ? (
                                  <button className="btn btn-primary" onClick={this.handleCategoryEdit}>
                                    {i18n.t("Update")}
                                  </button>
                                ) : (
                                  <button className="btn btn-primary" onClick={this.handleCategoryAdd}>
                                    {i18n.t("Add")}
                                  </button>
                                )}
                              </Modal.Footer>
                            </Modal>
                          }

                          <div class="add-category-infor-here">
                            <div
                              className={
                                stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0
                                  ? "add-metric-head-none"
                                  : "add-metric-head"
                              }
                            >
                              <div type="button" className="btn add-category-btn" onClick={this.handleCategoryShow}>
                                {i18n.t("Add_Category_Details")}
                              </div>
                              <div class="add-sub-level-metrics">
                                <i class="far fa-file-plus"></i>
                                <p>
                                  <span>*</span>{i18n.t("Add_category_details_as_sub_level_metrics")}
                                </p>
                              </div>
                            </div>
                            {stateKpiData.CategoryDetails &&
                              stateKpiData.CategoryDetails.length > 0 &&
                              <div className="category-header">
                                <span>
                                  {i18n.t("Category")} -{" "}
                                  {stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0
                                    ? stateKpiData.CategoryDetails[0].CategoryType
                                    : ""}
                                </span>
                                <div className="category-actions">
                                  <div className="category-add" onClick={this.handleCategoryTypeAddShow}>
                                    <i class="fal fa-plus-circle mr-3"></i>
                                  </div>
                                  <span onClick={() => this.deleteCategoryType()}>
                                    <i class="fas fa-trash-alt delete-icon-fix"></i>
                                  </span>
                                </div>
                              </div>
                            }
                            <div
                              className={
                                stateKpiData.CategoryDetails &&
                                  stateKpiData.CategoryDetails.length > 0 &&
                                  stateKpiData.IsMultiLevel
                                  ? "category-lists"
                                  : "category-lists-none"
                              }
                            >
                              <ul>

                                {items}


                              </ul>
                            </div>
                          </div>
                          {/* {Add Category Type} */}
                          {showaddCategoryType &&
                            <Modal
                              className="addCategoryModal"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              show={showaddCategoryType}
                              onHide={this.handleaddCategoryClose}
                              animation={false}
                              backdrop="static"
                            >
                              <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter">
                                  {i18n.t("Add_Category_Type")}
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="row">
                                  <div className="form-group col-lg-12">
                                    <label>
                                      {i18n.t("New_Category")}
                                      <span className="required"> *</span>
                                    </label>
                                    <input
                                      id="categoryType"
                                      type="text"
                                      maxLength="100"
                                      className="form-control"
                                      onChange={(e) => {
                                        this.bindToolFieldChange(e);
                                      }}
                                      value={CategoryDetails.CategoryType}
                                    />

                                    <span className="errorMsg">{errors.CategoryType}</span>
                                  </div>
                                  <div className="form-group col-lg-12">
                                    <label>
                                      {i18n.t("Name")}

                                      <span className="required"> *</span>
                                    </label>
                                    <input
                                      id="categoryName"
                                      type="text"
                                      maxLength="100"
                                      className="form-control"
                                      onChange={(e) => {
                                        this.bindToolFieldChange(e);
                                      }}
                                      value={CategoryDetails.CategoryName}
                                    />

                                    <span className="errorMsg">{errors.CategoryName}</span>
                                  </div>
                                  <p className="text-right newlyadded">
                                    {i18n.t("Newly_added_category_type_will_be_sent_for_approval")}
                                  </p>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button className="btn btn-default btn-sm mr-2" onClick={this.handleaddCategoryClose}>
                                  {i18n.t("Cancel")}
                                </button>
                                <button className="btn btn-primary" onClick={this.handleaddCategoryAdd}>
                                  {i18n.t("Add")}
                                </button>
                              </Modal.Footer>
                            </Modal>
                          }

                          {/* {Add Category Name} */}
                          {
                            showaddCategory &&

                            <Modal
                              className="addCategoryModal"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              show={showaddCategory}
                              onHide={this.handleaddCategoryNameClose}
                              animation={false}
                              backdrop="static"
                            >
                              <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter">
                                  {" "}
                                  {i18n.t("Add_Category_Name")}
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="row">
                                  <div className="form-group col-lg-12">
                                    <label>
                                      {i18n.t("Category_Type")}
                                      <span className="required"> *</span>
                                    </label>

                                    <input
                                      type="text"
                                      maxLength="100"
                                      className="form-control"
                                      id=""
                                      value={CategoryDetails.CategoryType}
                                      disabled
                                    />
                                  </div>

                                  <div className="form-group col-lg-12">
                                    <label>
                                      {i18n.t("Name")}

                                      <span className="required"> *</span>
                                    </label>

                                    <input
                                      id="categoryName"
                                      type="text"
                                      maxLength="100"
                                      className="form-control"
                                      onChange={(e) => {
                                        this.bindToolFieldChange(e);
                                      }}
                                      value={CategoryDetails.CategoryName}
                                    />

                                    <div className="errorMsg">{errors.CategoryName}</div>
                                  </div>

                                  <p className="text-right newlyadded">
                                    {" "}
                                    {i18n.t("Newly_added_category_name_will_be_sent_for_approval")}
                                  </p>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn btn-default btn-sm mr-2"
                                  onClick={this.handleaddCategoryNameClose}
                                >
                                  {i18n.t("Cancel")}
                                </button>
                                <button className="btn btn-primary" onClick={this.handleaddCategoryAdd}>
                                  {i18n.t("Add")}
                                </button>
                              </Modal.Footer>
                            </Modal>
                          }

                          {/* Approval Notification */}
                          {
                            showApproval &&

                            <Modal
                              className="approvalModal"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              show={showApproval}
                              onHide={this.handleApprovalClose}
                              animation={false}
                              backdrop="static"
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>{i18n.t("Approval_Notification")}</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="row">
                                  {CatTypeID === "addnew" ? (
                                    <p className="approvalInfo">
                                      {i18n.t("Newly_added_category_type_is_sent_for_approval")}
                                    </p>
                                  ) : (
                                    <p className="approvalInfo">
                                      {i18n.t("Newly_added_category_name_is_sent_for_approval")}
                                    </p>
                                  )}
                                </div>
                              </Modal.Body>
                            </Modal>
                          }



                        </div></div>
                    </div>
                    <div className="errorMsg">{errors.MultiLevel}</div>
                  </div>
                  {/* End of multi level UI */}

                </div>
              </div>
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">
                <button onClick={this.handleHide} className="btn btn-default btn-sm mr-2">
                  {i18n.t("Cancel")}
                </button>

                {!this.props.kpiData ? (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave()}
                    value={i18n.t("Create")}
                  />
                ) : (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave()}
                    value={i18n.t("Update")}
                  />
                )}
              </div>
            </Modal.Footer>
          }
        </Modal>
        {viewmore ? (
          <KpiPopUp
            SOCategoryID={SOCategoryID}
            show={true}
            onClose={this.onClose.bind(this)}
            filterDetails={filterDetails}
            tabName="HM"
          />
        ) : null}
      </>
    );
  }
}
