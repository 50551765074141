import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import _ from 'lodash';
import i18n from 'i18next';
import { LocalApiBaseUrl, regexForFields, trycatchAlertPopup } from '../Shared/Constant';

import axiosInstance from '../Shared/interceptor';

import SubOfferingDropdowns from '../Shared/SubOfferingDropdowns';
import {
  filterSelectedSubOffering, isValidText, InvalidDescription, getValidUserDetails, getErrorMessageByData,
} from '../Shared/commonFunctions';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('msal.idtoken')}`;

class DealPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      LocationOptions: [],
      IndustryOptions: [],
      offeringValueID: '',
      processValueID: '',
      processOptions: [],
      OperatingGroupOptions: [],
      OfferingOptions: [],
      ClientOptions: [],
      show: false,
      apivalue: '',
      selectValue: this.props.SelectValue,
      Display: false,
      errors: [],
      formIsValid: false,
      deal: {
        RequestorRemarks: '',
        DealID: 0,
        OrgEntityID: null,
        OfferingID: null,
        ClientName: null,
        Program: null,
        Project: null,
        DealName: null,
        Offering: null,
        Process: null,
        SubProcess: null,
        ClientRegion: '',
        ClientRegionID: 0,
        ClientCountry: '',
        MaturityPhaseID: 0,
        MaturityPhase: null,
        AccentureMD: '',
        ProcessID: null,
        AccentureSDL: '',
        ClientContact1: null,
        ClientContact2: null,
        CreatedUser: null,
        UpdatedUser: null,
        LocationID: null,
        IndustryID: null,
        IndustryName: null,
        City: null,
        Status: null,
        OperatingGroup: null,
        Cxl:null,
        Oal:null,
      },
    };
  }

  handleSave = async (e) => {
    if (await this.validateForm()) {
      const { deal } = this.state;
      deal.CreatedUser = sessionStorage.getItem('EnterpriseID');
      if (this.props.profile) {
        // let processValueID = this.state.processOptions.filter((data) => data.Process === this.state.deal.Process)[0]
        //   .OfferingID;

     
        deal.ProcessID = this.state.processValueID;
      }
      // deal.OfferingID = deal.ProcessID;
      this.setState({
        deal,
      });
      if (await this.validateForm()) {
        if (!this.props.profile) {
          this.props.onSave(this.state.deal);
        } else {
          const newDeal = [];
          newDeal.push(this.state.deal);

          axiosInstance
            .post(`${LocalApiBaseUrl}Deal/AddEditDealInfo`, newDeal, {
              params: { Action: 'Create' },
            })
            .then((response) => {
              window.alert(response.data.StatusMessage);
            })
            .catch((error) => {
              trycatchAlertPopup(error);
            });
        }
      }
      this.handleHide();
    }
  }

  handleHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== '×') {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
  }

  handleRejectHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== '×') {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }

  handleApproveHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== '×') {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }

  getLocation() {
    axiosInstance.get(`${LocalApiBaseUrl}Location/GetLocations`).then((response) => {
      this.setState({ LocationOptions: response.data });
    })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getIndustry() {
    let source = [];
    axiosInstance
      .get(`${LocalApiBaseUrl}Industry/GetIndustryList`)
      .then((response) => {
        source = response.data;
        const sourceValues = source.map((x) => (
          <option
            key={x.IndustryID}
            value={x.IndustryID}
          >
            {x.IndustryName}
          </option>
        ));
        this.setState({ IndustryOptions: sourceValues });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getOffering() {
    axiosInstance.get(`${LocalApiBaseUrl}Offering/GetOfferingsList`, {
      params: { FeatureName: 'Deals' },
    }).then((response) => {
      this.setState({ OfferingOptions: response.data });
      if (this.state.deal.OfferingID) {
        const processOptions = response.data
          .filter((data) => data.OfferingName === this.state.deal.Offering)[0]
          .ChildOfferings.filter((suboff) => suboff.IsActive === true);
        let offeringValueID = response.data.filter((data) => data.OfferingName === this.state.deal.Offering)[0]
          .OfferingID;          
        // let processValueID = processOptions.filter((data) => data.Process === this.state.deal.Process)[0].OfferingID;
        let processValueID = null;
        processOptions.forEach((each)=>{
          each.SubLevel.forEach(data => {
              if(data.Process === this.state.deal.Process){
                processValueID = data.OfferingID
              }
          })
          
        })
        this.setState({
          processOptions,
          offeringValueID,
          processValueID,
        });
      }
    })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getClients() {
    axiosInstance.get(`${LocalApiBaseUrl}User/GetClients`).then((response) => {
      this.setState({ ClientOptions: response.data });
    })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  componentDidMount() {
    if (this.props.data || this.props.opsgrp) {
      this.setState({
        deal: this.props.data ? this.props.data : this.props.opsgrp[0],
      });
    }

    if (this.props.opsgrp) {
      if (this.props.opsgrp.length !== 0) {
        this.getLocation();
        this.getIndustry();
        this.getOffering();
        this.getClients();
      }
    }
    if (this.props.isDealPop) {
      this.getLocation();
      this.getIndustry();
      this.getOffering();
      this.getClients();
    }

    this.setState({
      show: this.props.show,
    });
  }

  componentWillMount() {
    if (this.props.profile) {
      if (!_.isNil(this.props.filterDetails)) {
        if (
          String(this.props.filterDetails.Client) !== String(0)
          && String(this.props.filterDetails.Locations) !== String(0)
          && String(this.props.filterDetails.offering) !== String(0)
          && String(this.props.filterDetails.process) !== 0
        ) {
          this.setState({ Display: true });
        } else this.setState({ Display: false });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.profile) {
      if (!_.isNil(nextProps.filterDetails)) {
        if (
          String(nextProps.filterDetails.Client) !== String(0)
          && String(nextProps.filterDetails.Locations) !== String(0)
          && String(nextProps.filterDetails.Offering) !== String(0)
          && String(nextProps.filterDetails.Process) !== String(0)
        ) {
          this.setState({ Display: true });
        } else this.setState({ Display: false });
      }
    }
  }

  onClientNameChange() {
    this.setState({ errors: {} });
    const { deal } = this.state;
    const event = document.getElementById('Client');

    deal.OrgEntityID = event.options[event.selectedIndex].value;
    deal.ClientName = event.options[event.selectedIndex].text;
    this.setState({
      deal,
    });
  }

  onOtherClientChange(event) {
    this.setState({ errors: {} });
    const { deal } = this.state;
    deal.ClientName = event.target.value;
    this.setState({
      deal,
    });
  }

  onProgramChange(event) {
    this.setState({ errors: {} });
    const { deal } = this.state;
    deal.Program = event.target.value;
    this.setState({
      deal,
    });
  }

  onRemarksChange(event) {
    this.setState({ errors: {} });
    const { deal } = this.state;
    deal.RequestorRemarks = event.target.value;
    this.setState({
      deal,
    });
  }

  onProjectChange(event) {
    this.setState({ errors: {} });
    const { deal } = this.state;
    deal.Project = event.target.value;
    this.setState({
      deal,
    });
  }

  onDealNameChange(event) {
    const { deal } = this.state;
    deal.DealName = event.target.value;
    this.setState({
      deal,
    });
  }

  onSubProcessChange(event) {
    const { deal } = this.state;
    deal.SubProcess = event.target.value;
    this.setState({
      deal,
    });
  }

  onClientRegionChange(event) {
    this.setState({ errors: {} });
    const { deal } = this.state;
    const index = event.nativeEvent.target.selectedIndex;
    deal.ClientRegion = event.nativeEvent.target[index].text;
    deal.ClientRegionID = event.target.value;
    this.setState({
      deal,
    });
  }

  onClientCountryChange(event) {
    this.setState({ errors: {} });
    const { deal } = this.state;
    deal.ClientCountry = event.target.value;
    this.setState({
      deal,
    });
  }

  onMaturityPhaseChange(event) {
    this.setState({ errors: {} });
    const { deal } = this.state;
    const index = event.nativeEvent.target.selectedIndex;
    deal.MaturityPhase = event.nativeEvent.target[index].text;
    deal.MaturityPhaseID = event.target.value;
    this.setState({
      deal,
    });
  }

  onAccentureMDChange(event) {
    this.setState({ errors: {} });
    const { deal } = this.state;
    deal.AccentureMD = event.target.value;
    this.setState({
      deal,
    });
  }

  onAccentureSDLChange(event) {
    this.setState({ errors: {} });
    const { deal } = this.state;
    deal.AccentureSDL = event.target.value;
    this.setState({
      deal,
    });
  }

  onCxlChange=(event)=> {
    this.setState({ errors: {} });
    const deal = this.state.deal;
    deal.Cxl = event.target.value;
    this.setState({
      deal: deal,
    });
  }

  onOalChange=(event)=> {
    this.setState({ errors: {} });
    const deal = this.state.deal;
    deal.Oal = event.target.value;
    this.setState({
      deal: deal,
    });
  }

  onClientContact1Change(event) {
    const { deal } = this.state;
    deal.ClientContact1 = event.target.value;
    this.setState({
      deal,
    });
  }

  onClientContact2Change(event) {
    const { deal } = this.state;
    deal.ClientContact2 = event.target.value;
    this.setState({
      deal,
    });
  }

  onLocationChange() {
    this.setState({ errors: {} });
    const { deal } = this.state;
    const event = document.getElementById('Location');
    deal.LocationID = event.options[event.selectedIndex].value;
    this.setState({
      deal,
    });
  }

  onIndustryChange() {
    this.setState({ errors: {} });
    const { deal } = this.state;
    const event = document.getElementById('IndustryName');
    deal.IndustryID = event.options[event.selectedIndex].value;
    deal.IndustryName = event.options[event.selectedIndex].label;
    this.setState({
      deal,
    });
  }

  onOfferingsChange(event) {
    this.setState({ errors: {} });
    const { deal } = this.state;
    deal.OfferingID = event.target.value;
    deal.Process = '';
    deal.ProcessID = 0;
    const offeringValueID = event.target.value;
    const processOptions = event.target.value
      ? this.state.OfferingOptions.filter((data) => String(data.OfferingID) === String(event.target.value))[0].ChildOfferings.filter(
        (suboff) => suboff.IsActive === true,
      )
      : [];
    if (processOptions.length) {
      deal.Offering = processOptions[0].ParentOfferingName;
    }
    this.setState({
      processOptions,
      deal,
      offeringValueID,
    });
  }

  onOfferingsProcessChange(e) {
    this.setState({ errors: {} });
    const { deal } = this.state;
    const filtersSubofferingData = filterSelectedSubOffering([...this.state.processOptions], e.target.value);
    const selectedSuboffering = filtersSubofferingData.subofferingData[0];

    deal.ProcessID = selectedSuboffering.OfferingID;
    deal.Process = selectedSuboffering.Process;
    const processValueID = selectedSuboffering.OfferingID;
    this.setState({
      deal,
      processValueID,
    });
  }

  userValidation=async (user) => {
    const userData = user;
    // if(!userData.includes("@accenture.com"))
    // userData=`${userData}@accenture.com`
    const userdetails = { users: [userData] };
    const userValidData = await getValidUserDetails(userdetails);
    const errorMessageAndFormValidity = getErrorMessageByData(userValidData);
    return errorMessageAndFormValidity;
  }

  validateForm =async () => {
    const { deal } = this.state;
    const errors = {};
    let formIsValid = true;
    const specialCharcterValidation = InvalidDescription();

    const { AccentureMD, AccentureSDL, Cxl, Oal } = deal;
    const cxlArr = Cxl && Cxl.length ? Cxl.split(",") : [];
    const oalArr = Oal && Oal.length ? Oal.split(",") : [];
    const combinedCXLOALData = [...new Set([...cxlArr, ...oalArr])].filter(emailId=>emailId.length); 
    const userData = [...new Set([AccentureMD, AccentureSDL, ...combinedCXLOALData ])];
    const userValidData = await getValidUserDetails(userData);

    if (!deal.OrgEntityID) {
      formIsValid = false;
      errors.ClientName = 'Client Name is required';
    } else if (deal.OrgEntityID === '-1' && !deal.ClientName) {
      formIsValid = false;
      errors.ClientName = 'Client Name is required';
    } else {
      var isValid = regexForFields.test(deal.OrgEntityID);
      if (!isValid) {
        formIsValid = false;
        errors.ClientName = 'Client Name contains Special characters';
      } else if (!isValidText(deal.OrgEntityID)) {
        formIsValid = false;
        errors.ClientName = specialCharcterValidation;
      }
    }

    if (deal.ClientContact1) {
      isValid = regexForFields.test(deal.ClientContact1);
      if (!isValid) {
        formIsValid = false;
        errors.ClientContact1 = 'ClientContact1 contains Special characters';
      }
    }
    if (deal.ClientContact2) {
      isValid = regexForFields.test(deal.ClientContact2);
      if (!isValid) {
        formIsValid = false;
        errors.ClientContact2 = 'ClientContact2 contains Special characters';
      }
    }
    if (deal.Program) {
      isValid = regexForFields.test(deal.Program);
      if (!isValid) {
        formIsValid = false;
        errors.Program = 'Program contains Special characters';
      } else if (!isValidText(deal.Program)) {
        formIsValid = false;
        errors.Program = specialCharcterValidation;
      }
    }

    if (deal.Project) {
      isValid = regexForFields.test(deal.Project);
      if (!isValid) {
        formIsValid = false;
        errors.Project = 'Project contains Special characters';
      } else if (!isValidText(deal.Project)) {
        formIsValid = false;
        errors.Project = specialCharcterValidation;
      }
    }

    if (deal.DealName) {
      isValid = regexForFields.test(deal.DealName);
      if (!isValid) {
        formIsValid = false;
        errors.DealName = 'Deal Name contains Special characters';
      } else if (!isValidText(deal.DealName)) {
        formIsValid = false;
        errors.DealName = specialCharcterValidation;
      }
    }

    if (!deal.Offering || deal.OfferingID === '') {
      formIsValid = false;
      errors.Offering = 'Offering is required';
    }
    if (!deal.Process) {
      formIsValid = false;
      errors.Process = 'Sub Offering is required';
    }
    if (!deal.ClientRegion.trim() || deal.ClientRegion.trim() === '--Select--') {
      formIsValid = false;
      errors.ClientRegion = 'Client Region is required';
    } else {
      isValid = regexForFields.test(deal.ClientRegion);
      if (!isValid) {
        formIsValid = false;
        errors.ClientRegion = 'Client Region contains Special characters';
      }
    }
    if (!deal.ClientCountry.trim()) {
      formIsValid = false;
      errors.ClientCountry = 'Client Country is required';
    } else {
      isValid = regexForFields.test(deal.ClientCountry);
      if (!isValid) {
        formIsValid = false;
        errors.ClientCountry = 'Client Country contains Special characters';
      }
    }
    if (!deal.AccentureMD.trim()) {
      formIsValid = false;
      errors.AccentureMD = 'Accenture Delivery MD is required';
    } else if (_.isNil(errors.AccentureMD)) {
      const value = deal.AccentureMD.trim();
      const { formValid, errorMessage } = getErrorMessageByData(userValidData, [value]);
      if (!formValid) {
        errors.AccentureMD = errorMessage;
        formIsValid = false;
      } else {
        errors.AccentureMD = '';
      }
    }

    if (!deal.AccentureSDL.trim()) {
      formIsValid = false;
      errors.AccentureSDL = 'Delivery SDL is required';
    } else if (_.isNil(errors.AccentureSDL)) {
      const value = deal.AccentureSDL.trim();
      const { formValid, errorMessage } = getErrorMessageByData(userValidData, [value]);
      if (!formValid) {
        errors.AccentureSDL = errorMessage;
        formIsValid = false;
      } else {
        errors.AccentureSDL = '';
      }
    }
    if (!deal["Cxl"].trim()) {
      formIsValid = false;
      errors["Cxl"] = "Client Experience Lead is required";
    } else {
       isValid = regexForFields.test(deal["Cxl"]);
      if (!isValid) {
        formIsValid = false;
        errors["Cxl"] = "Client Experience Lead contains Special characters";
      } else {
        if(cxlArr.includes('') || cxlArr.length > 4){
          formIsValid = false;
          errors.Cxl = "Client Experience Lead contains more than four users or extra comma at the end.";
        } else {
          const {formValid,errorMessage} = getErrorMessageByData(userValidData,cxlArr);
          formIsValid=formValid;
          errors.Cxl = errorMessage    
        }
      }
    }
    if (!deal["Oal"].trim()) {
      formIsValid = false;
      errors["Oal"] = "OPS Account Lead is required";
    } else {
       isValid = regexForFields.test(deal["Oal"]);
      if (!isValid) {
        formIsValid = false;
        errors["Oal"] = "OPS Account Lead contains Special characters";
      }else if(oalArr.includes('') || oalArr.length > 4) {
        formIsValid = false;
        errors.Oal = "OPS Account Lead contains more than four users or extra comma at the end.";
      } else {
        const {formValid,errorMessage} = getErrorMessageByData(userValidData,oalArr);
        formIsValid= formValid;
        errors.Oal = errorMessage;    
      }
    }
    if (!deal["IndustryID"]) {
      formIsValid = false;
      errors.Industry = 'Industry is required';
    }

    if (!deal.LocationID) {
      formIsValid = false;
      errors.City = 'City is required';
    }
    if (!deal.MaturityPhase || deal.MaturityPhase === '--Select--') {
      formIsValid = false;
      errors.MaturityPhase = 'Maturity Phase is required';
    }
    if (!deal.RequestorRemarks.trim() || _.isNil(deal.RequestorRemarks.trim())) {
      formIsValid = false;
      errors.RequestorRemarks = 'Requestor Remarks are required';
    }
    this.setState({
      errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return (errors.Oal || errors.Cxl) ? false : formIsValid;
  }

  render() {
    const { SelectedFilter } = this.props;
    const showPopup = !!(SelectedFilter
      && SelectedFilter.LocationId !== 'All'
      && SelectedFilter.OfferingId !== 'All'
      && SelectedFilter.ProcessId !== 'All'
      && SelectedFilter.LocationId
      && SelectedFilter.OfferingId
      && SelectedFilter.ProcessId
      && SelectedFilter.Month);
    return (
      <>
        {/* <Header /> */}
        <Modal
          show={this.state.show}
          onHide={this.handleHide.bind(this)}
          backdrop="static"
          className="modal fade"
          size="medium"
          id="dealpopup"
        >
          <Modal.Header closeButton>
            {this.props.profile ? (
              <h4 className="modal-title" id="dealprofile">
                Deal Profile
              </h4>
            ) : (
              <>
                {!this.props.data ? (
                  <h4 className="modal-title" id="dealprofile">
                    Add Deal 
                  </h4>
                ) : (
                  <h4 className="modal-title" id="dealprofile">
                  </h4>
                )}
              </>
            )}
          </Modal.Header>
          <Modal.Body>
            {((this.state.Display && this.props.opsgrp && this.props.opsgrp.length !== 0 && showPopup) || this.props.isDealPop) ? (
              <form>
                <div className="form-section">
                  <h3>Org Structure</h3>
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label htmlFor="">
                        Industry
                        <span className="required">*</span>
                      </label>

                      <select
                        id="IndustryName"
                        className="form-control"
                        disabled={this.props.data || this.props.opsgrp}
                        ref="IndustryDropDown"
                        onChange={this.onIndustryChange.bind(this)}
                        value={this.state.deal.IndustryID}
                      >
                        <option value="" />
                        {this.state.IndustryOptions}
                      </select>
                      <span className="errorMsg">{this.state.errors.Industry}</span>
                    </div>

                    <div className="form-group col-lg-6">
                      <label htmlFor="">
                        Client Name
                        <span className="required">*</span>
                      </label>
                      <select
                        id="Client"
                        className="form-control"
                        disabled={this.props.data || this.props.opsgrp}
                        ref="ClientDropdown"
                        onChange={this.onClientNameChange.bind(this)}
                        value={this.state.deal.OrgEntityID}
                      >
                        <option value="" />
                        {this.state.ClientOptions.map((row) => <option value={row.ClientID}>{row.ClientName}</option>)}
                      </select>
                      {this.state.deal.OrgEntityID !== '-1' ? (
                        <span className="errorMsg">{this.state.errors.ClientName}</span>
                      ) : null}
                    </div>
                  </div>
                  {this.state.deal.OrgEntityID === '-1' ? (
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <input
                          maxLength="50"
                          onChange={this.onOtherClientChange.bind(this)}
                          type="text"
                          className="form-control"
                          id="clientname"
                        />
                        <span className="errorMsg">{this.state.errors.ClientName}</span>
                      </div>
                    </div>
                  ) : null}
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label htmlFor="">Program</label>
                      <input
                        maxLength="50"
                        type="text"
                        className="form-control"
                        id="program"
                        onChange={this.onProgramChange.bind(this)}
                        value={this.state.deal.Program}
                      />
                      <span className="errorMsg">{this.state.errors.Program}</span>
                    </div>
                    <div className="form-group col-lg-6">
                      <label htmlFor="">Project</label>
                      <input
                        maxLength="50"
                        type="text"
                        className="form-control"
                        id="project"
                        onChange={this.onProjectChange.bind(this)}
                        value={this.state.deal.Project}
                      />
                      <span className="errorMsg">{this.state.errors.Project}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label htmlFor="">Deal Name</label>
                      <input
                        maxLength="50"
                        type="text"
                        className="form-control"
                        id="dealname"
                        onChange={this.onDealNameChange.bind(this)}
                        value={this.state.deal.DealName}
                      />
                      <span className="errorMsg">{this.state.errors.DealName}</span>
                    </div>
                    <div className="form-group col-lg-6">
                      <label htmlFor="">
                        Maturity Phase
                        <span className="required">*</span>
                      </label>
                      <select
                        id="MaturityPhases"
                        className="form-control"
                        value={this.state.deal.MaturityPhaseID}
                        onChange={this.onMaturityPhaseChange.bind(this)}
                      >
                        <option value="0">--Select--</option>
                        {this.props.MaturityPhase
                          && this.props.MaturityPhase.map((row) => <option value={row.ID}>{row.PhaseName}</option>)}
                      </select>
                      <span className="errorMsg">{this.state.errors.MaturityPhase}</span>
                    </div>
                  </div>
                </div>
                <div className="form-section">
                  <h3>Geo / Location</h3>
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label htmlFor="">
                        Client Region
                        <span className="required">*</span>
                      </label>
                      <select
                        id="ClientRegion"
                        className="form-control"
                        disabled={this.props.data || this.props.opsgrp}
                        ref="ClientRegionDropdown"
                        onChange={this.onClientRegionChange.bind(this)}
                        value={this.state.deal.ClientRegionID}
                      >
                        <option value="0">--Select--</option>
                        {this.props.ClientRegions
                          && this.props.ClientRegions.map((row) => {
                            if (row.IsActive && row.Status === 'Approved') {
                              return <option value={row.ID}>{row.ClientRegionName}</option>;
                            }
                          })}
                      </select>
                      <span className="errorMsg">{this.state.errors.ClientRegion}</span>
                    </div>
                    <div className="form-group col-lg-6">
                      <label htmlFor="">
                        Client Country
                        <span className="required">*</span>
                      </label>
                      <input
                        maxLength="50"
                        type="text"
                        className="form-control"
                        id="ClientCountries"
                        onChange={this.onClientCountryChange.bind(this)}
                        value={this.state.deal.ClientCountry}
                      />
                      <span className="errorMsg">{this.state.errors.ClientCountry}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label htmlFor="">
                        City / Deliver Center
                        <span className="required">*</span>
                      </label>
                      <select
                        id="Location"
                        className="form-control"
                        disabled={this.props.data || this.props.opsgrp}
                        ref="LocationDropdown"
                        onChange={this.onLocationChange.bind(this)}
                        value={this.state.deal.LocationID}
                      >
                        <option value="" />
                        {this.state.LocationOptions.map((row) => {
                          if (row.IsActive) {
                            return <option value={row.LocationId}>{row.City}</option>;
                          }
                        })}
                      </select>
                      <span className="errorMsg">{this.state.errors.City}</span>
                    </div>
                  </div>
                </div>
                <div className="form-section">
                  <h3>Offering</h3>
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label htmlFor="">
                        Offering
                        <span className="required">*</span>
                      </label>
                      <select
                        id="Offering"
                        className="form-control"
                        ref="OfferingDropDown"
                        disabled={this.props.data || this.props.opsgrp}
                        onChange={this.onOfferingsChange.bind(this)}
                        value={this.state.offeringValueID}
                      >
                        <option value="">Select Offering</option>
                        {this.state.OfferingOptions.map((row) => <option value={row.OfferingID}>{row.OfferingName}</option>)}
                      </select>
                      <span className="errorMsg">{this.state.errors.Offering}</span>
                    </div>
                    <div className="form-group col-lg-6">
                      <SubOfferingDropdowns
                        selectedSubOffering={this.state.deal.Process}
                        subOfferings={this.state.processOptions}
                        onSubOfferingChange={(e) => this.onOfferingsProcessChange(e)}
                        defaultToggleName={i18n.t('Select_Sub_Offerings')}
                        required
                        labelfor=""
                        labelClassName=""
                        disabledValue={this.props.data || this.props.opsgrp}
                        dropdownItem={false}
                        labelpresent
                        selectedSubOfferingId={this.state.deal.ProcessID}
                        SubOfferingDropdownsClass
                      />
                      <span className="errorMsg">{this.state.errors.Process}</span>
                    </div>
                  </div>
                </div>
                <div className="form-section">
                  <h3>Stakeholders</h3>
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label htmlFor="">
                        Accenture Delivery MD
                        <span className="required">*</span>
                      </label>
                      <input
                        maxLength="50"
                        type="text"
                        className="form-control"
                        id="AccentureMD"
                        onChange={this.onAccentureMDChange.bind(this)}
                        value={this.state.deal.AccentureMD}
                      />
                      <span className="errorMsg">{this.state.errors.AccentureMD}</span>
                    </div>
                    <div className="form-group col-lg-6">
                      <label htmlFor="">
                        Delivery SDL
                        <span className="required">*</span>
                      </label>
                      <input
                        maxLength="50"
                        type="text"
                        className="form-control"
                        id="AccentureSDL"
                        onChange={this.onAccentureSDLChange.bind(this)}
                        value={this.state.deal.AccentureSDL}
                      />
                      <span className="errorMsg">{this.state.errors.AccentureSDL}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-lg-6">
                    <label for="">Client Experience Lead<span className="required">*</span></label>
                      <input
                        maxLength="200"
                        type="text"
                        className="form-control"
                        id="cxl"
                        onChange={this.onCxlChange}
                        value={this.state.deal.Cxl}
                      />
                      <span className="errorMsg error-word-break">{this.state.errors.Cxl}</span>
                    </div>
                    <div className="form-group col-lg-6">
                      <label for="">OPS Account Lead<span className="required">*</span></label>
                      <input
                        maxLength="200"
                        type="text"
                        className="form-control"
                        id="oal"
                        onChange={this.onOalChange}
                        value={this.state.deal.Oal}
                      />
                      <span className="errorMsg error-word-break">{this.state.errors.Oal}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label for="">Client Contact 1</label>
                      <input
                        maxLength="50"
                        type="text"
                        className="form-control"
                        id="ClientContact1"
                        onChange={this.onClientContact1Change.bind(this)}
                        value={this.state.deal.ClientContact1}
                      />
                      <span className="errorMsg">{this.state.errors.ClientContact1}</span>
                    </div>
                    <div className="form-group col-lg-6">
                      <label htmlFor="">Client Contact 2</label>
                      <input
                        maxLength="50"
                        type="text"
                        className="form-control"
                        id="Client Contact 2"
                        onChange={this.onClientContact2Change.bind(this)}
                        value={this.state.deal.ClientContact2}
                      />
                      <span className="errorMsg">{this.state.errors.ClientContact2}</span>
                    </div>
                  </div>
                </div>

                {String(this.state.deal.OrgEntityID) === String(-1) ? (
                  <div className="form-section">
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label htmlFor="">RequestorRemarks</label>
                        <input
                          maxLength="50"
                          type="text"
                          className="form-control"
                          id="ClientContact1"
                          onChange={this.onRemarksChange.bind(this)}
                          value={this.state.deal.RequestorRemarks}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="form-section">
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label htmlFor="">
                        Requestor Remarks
                        <span className="required">*</span>
                      </label>
                      <textarea
                        maxLength="200"
                        className="form-control"
                        value={this.state.deal.RequestorRemarks}
                        disabled={this.props.data || this.props.opsgrp}
                        onChange={this.onRemarksChange.bind(this)}
                      />
                      <span className="errorMsg">{this.state.errors.RequestorRemarks}</span>
                    </div>
                    {this.props.data ? (
                      <div className="form-group col-lg-6">
                        <label htmlFor="">
                          Approver Remarks
                          <span className="required" />
                        </label>
                        <textarea
                          maxLength="200"
                          className="form-control"
                          value={this.state.deal.ApproverRemarks}
                          disabled={this.props.data || this.props.opsgrp}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </form>
            ) : (
              <p className="form-text text-muted m-0">Please Select Client, Offering and Location</p>
            )}
          </Modal.Body>
          {(this.state.Display && this.props.opsgrp && this.props.opsgrp.length !== 0) || this.props.isDealPop ? (
            <Modal.Footer>
              <div className="modal-btn">
                {!this.props.profile ? (
                  <>
                    {!this.props.data ? (
                      <input
                        type="Button"
                        className="btn btn-primary btn-sm"
                        onClick={(e) => this.handleSave(e)}
                        value="Create"
                      />
                    ) : (
                      <>
                        {String(this.props.data.Status) === 'Approved' ? (
                          <input
                            type="Button"
                            className="btn btn-primary btn-sm"
                            onClick={(e) => this.handleSave(e)}
                            value="Update"
                          />
                        ) : null}
                      </>
                    )}
                  </>
                ) : (showPopup
                  ? (
                    <input
                      type="Button"
                      className="btn btn-primary btn-sm"
                      onClick={(e) => this.handleSave(e)}
                      value="Update"
                    />
                  ) : ''
                )}
              </div>
            </Modal.Footer>
          ) : null}
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  SelectedFilter: { ...state.CurrentFilter.SyncFilter },
});
export default connect(mapStateToProps)(DealPopUp);
