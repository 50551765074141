//REACT IMPORTS
import React, { Component } from "react";

//CUSTOM IMPORTS
import WidgetDisplay from "./WidgetDataDisplay/WidgetDisplay";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../../Shared/SynopsLoader";
import AddWidget from "./AddWidget";
import { LocalApiBaseUrl } from "../../Shared/Constant";
import axiosInstance from "../../Shared/interceptor";
import {trycatchAlertPopup} from "../../Shared/Constant";

//STYLING IMPORTS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import $ from "jquery";

export default class DDWidgetContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWidget: false,
      addModalStatus: false,
      editModalStatus: false,
      getEditApiStatus: false,
      postApiStatus: false,
      IsApiLoading:false,
      isActive: false,
      widgetDataPostRes: "",
      allEditWidgetData: "",
      widgetEditData:""
    };
  }

  /*This is called after render so all tasks that we want to perform after
   a component renders, we perform here*/
  componentDidMount() {
    this.getEditWidgetData();
  }

  //GET CALL FOR EDIT WIDGET DATA
  getEditWidgetData = () => {
    axiosInstance.get(`${LocalApiBaseUrl}MTCDDWidgetConfiguration/GetDDWidgetData?OfferingId=${this.props.filterDetails.process}`)
      .then((response) => {
        if (response.data) {
          this.setState({allEditWidgetData: response.data});
          this.setState({ getEditApiStatus: true });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };
 
  //HANDLING ADD WIDGET
  addwidgetList = (isSuccess,data)=>{
    if (isSuccess) {
      this.setState({IsApiLoading:true})
      //CALLING POST API
      axiosInstance.post(`${LocalApiBaseUrl}MTCDDWidgetConfiguration/PostDDWidgetConfiguration`,data)
        .then((response) => {
          if (response) {
            this.setState({
            widgetDataPostRes: data,
            postApiStatus: true,
            addModalStatus: false,
            showWidget:false,
            IsApiLoading:false
            })
            this.getEditWidgetData();
            this.handleWidgetClose();
          }
        })
        .catch((error) => {
          this.setState({IsApiLoading:false})
          if(isSuccess){
           let message = error;
           $("#message").addClass("errorMsg").html(message).show();
           setTimeout(() => $("#message").hide(), 5000);
          }
          trycatchAlertPopup(error);

        });
      } 
    else 
    {
        this.setState({ IsApiLoading: false })
        $("#message").addClass("errorMsg").html("Please fill the complete form!").show();
        setTimeout(() => $("#message").hide(), 5000);
        this.setState({ formIsValid: false });
    }
  }

  //HANDLING UPDATES IN WIDGET DATA- EDIT
  editWidgetList(isSuccess,data) {
    //CALLING POST API
    if(isSuccess){
      this.setState({IsApiLoading:true})
      axiosInstance.post(`${LocalApiBaseUrl}MTCDDWidgetConfiguration/PostDDWidgetConfiguration`,data)
       .then((response) => {
        if (response) {
          this.setState({
            widgetDataPostRes: data,
            postApiStatus: true,
            showWidget:false,
            editModalStatus: false,
            IsApiLoading:false
          })
          this.getEditWidgetData();
          this.handleWidgetClose();
        }
      })
      .catch((error) => {
        this.setState({IsApiLoading:false})
        let message = error;
        $("#message").addClass("errorMsg").html(message).show()
        setTimeout(() =>$("#message").hide(), 5000);
        trycatchAlertPopup(error);

      }) 
    }
    else{
      this.setState({IsApiLoading:false})
      $("#message").addClass("errorMsg").html('Please fill the complete form').show()
      setTimeout(() => $("#message").hide(), 5000);
    }
  }
  

  //HANDLING DELETE WIDGET
  deleteWidget = (id) => {
    this.setState({IsApiLoading:true})
    if (window.confirm("Are you sure you want to delete item?")){
      axiosInstance.post(`${LocalApiBaseUrl}MTCDDWidgetConfiguration/Delete_DDWidgetConfiguration?OfferingWidgetMappingID=${id}`)
      .then((response) => {
        this.getEditWidgetData();
        this.setState({IsApiLoading:false})

      })
      .catch((error) => {
        this.setState({IsApiLoading:false})
        trycatchAlertPopup(error);

      });
    }
    else{
      this.setState({IsApiLoading:false})
    }
  };

  //CONNECTION CHANGE HANDLER(SLIDER)
  connectionChangeHandler = (id, isActive) => {
    this.setState({IsApiLoading:true})
    let msg = isActive ? "enable" : "disable";
    if (window.confirm(`Are you sure you want to ${msg} this widget?`)){
     axiosInstance.post(`${LocalApiBaseUrl}MTCDDWidgetConfiguration/InActive_DDWidgetConfiguration?OfferingWidgetMappingID=${id}&Active=${isActive}`)
     .then((response) => {
      this.getEditWidgetData();
      this.setState({ isActive: false,IsApiLoading:false });

     })
     .catch((error) => {
      this.setState({ IsApiLoading:false });
      trycatchAlertPopup(error);

     });
    }
    else{
     this.setState({ IsApiLoading:false });
    }
  };

  //HANDLING WIDGET-SHOW
  handleShow = () => {
    this.setState({
      ...this.state,
      showWidget: true,
      addModalStatus: true,
    });
  };

  //HANDLING WIDGET CLOSE
  handleWidgetClose = () => {
    if (this.state.addModalStatus){
      this.setState({
        ...this.state,
        showWidget: false,
        addModalStatus: false,
      });
    } 
    else if (this.state.editModalStatus){
      this.setState({
        ...this.state,
        showWidget: false,
        editModalStatus: false,
      });
    } 
    else {
      this.setState({
        showWidget: false,
      });
    }
  };

  //HANDLING EDIT MODAL SHOW
  handleEditShow = () => {
    this.setState({
      ...this.state,
      showWidget: true,
      editModalStatus: true,
    });
  };

  //HANDLING WIDGET CANCEL
  handleWidgetCancel = () => {
    if (this.state.addModalStatus) {
      this.setState({
        ...this.state,
        showWidget: false,
        addModalStatus: false,
      });
    } 
    else if (this.state.editModalStatus) {
      this.setState({
        ...this.state,
        showWidget: false,
        editModalStatus: false,
      });
    }
  };

  //HANDLING EDIT WIDGET SHOW/HIDE OPERATIONS
  editWidgetshow = (widgetData) => {
    this.setState({
      ...this.state,
      editModalStatus: true,
      widgetEditData:widgetData
    },()=>this.handleEditShow());
  };

 
  render() {
    return (
      <>
      {this.state.IsApiLoading && (
					<LoadingOverlay
						fadeSpeed={0}
						spinner={<SynopsLoader />}
						active
					/>
				)}
        {this.props.roledata.includes("Add") &&
        <div className="modal-btn">
          <Button className="show-pop" onClick={this.handleShow.bind(this)}>
            <span>
              {" "}
              <FontAwesomeIcon icon={faPlus} />
              Add Widget
            </span>
          </Button>
        </div>
        }
        {this.state.getEditApiStatus === false || this.state.allEditWidgetData.length === 0 ? (
          <div className="widgetconfig-data">
            <div className="modal-data">
              {this.props.roledata.includes("Add") ?
              <Button className="show-pop" onClick={this.handleShow.bind(this)}>
                <span>
                  {" "}
                  <FontAwesomeIcon icon={faPlus} />
                </span>
              </Button>
              :
              <Button className="show-pop" disabled>
                <span style={{pointerEvents: "none"}}>
                  {" "}
                  <FontAwesomeIcon icon={faPlus} />
                </span>
              </Button>
              }
              <h6>Add widget for the diverse data</h6>
              <p>You can add & manage widgets for each offering</p>
            </div>
          </div>
        ) : (
          <div className="custom-tilecard dd-insights-card customtilecard-mtc">
            <div className="tilecard-wrapper">
              {this.state.allEditWidgetData.map((WidgetData, index) => {
                return (
                  <WidgetDisplay
                    key={index}
                    index={index}
                    widgetData={WidgetData}
                    delete={this.deleteWidget}
                    connectionChange={this.connectionChangeHandler}
                    editWidgetPopup={this.editWidgetshow}
                    roledata={this.props.roledata}
                  />
                );
              })}
            </div>
          </div>
        )}

        {this.state.showWidget ? (
          <AddWidget
            show={this.state.showWidget}
            add={this.state.addModalStatus}
            edit={this.state.editModalStatus}
            onCancel={this.handleWidgetCancel.bind(this)}
            onUpdate={this.editWidgetList.bind(this)}
            onAdd={this.addwidgetList.bind(this)}
            widgetEditData={this.state.widgetEditData}
            filterDetails={this.props.filterDetails}
            roledata={this.props.operationArray}
            isApiLoading={this.state.IsApiLoading}
          />
        ) : null}
      </>
    );
  }
}
