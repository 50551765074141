import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import _ from "lodash";

// IMPORT CUSTOM COMPONENTS/FUNCTIONS
import NotificationRequestsListing from "./NotificationRequestsListing";
import AccessManagement from "./AccessManagement";

class BatAccessManagement extends Component {
  constructor(props) {
    super(props);

    let defaultActiveTab = "accessManagement";
    const activeSectionFromProps = _.get(props, "location.state.activeSection", "");
    if (!_.isEmpty(activeSectionFromProps)) {
      defaultActiveTab = activeSectionFromProps;
    }

    this.state = {
      activeTab: defaultActiveTab, // "accessManagement" OR "accessRequest"
      assessmentDetails: {},
      searchFilterTextValue: "",
    };
  }

  componentDidUpdate(prevProps) {
    const prevActiveSection = _.get(prevProps, "location.state.activeSection", "");
    const activeSection = _.get(this.props, "location.state.activeSection", "");

    if (prevActiveSection !== activeSection && !_.isEmpty(activeSection)) {
      this.setState({
        activeTab: activeSection,
        searchFilterTextValue: "",
      });
    }
  }

  handleChangeTab(selectedTab) {
    this.setState({
      activeTab: selectedTab,
      searchFilterTextValue: "",
    });
  }

  handleChangeSearchFilterText = (e) => {
    this.setState({
      searchFilterTextValue: e.target.value,
    });
  };

  render() {
    const { activeTab, searchFilterTextValue } = this.state;
    
    return (
      <div className="access-management-screen">
        <div className="notificationrequest-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="notificationrequest-header">
                  <div className="notificationrequest-heading">
                    <NavLink to="/bat" className="navbar-brand">
                      <span className="back-btn">
                        <i class="fas fa-chevron-left"></i>
                        Back
                      </span>
                    </NavLink>
                    <h4>{activeTab === "accessManagement" ? "Access Management" : "Notification"}</h4>
                  </div>

                  <div className="searchFilter">
                    <input
                      type="search"
                      placeholder="Search..."
                      value={searchFilterTextValue}
                      onChange={(e) => this.handleChangeSearchFilterText(e)}
                    />
                  </div>
                </div>
                {/* <div className="accessrequest-heading">
                  <span>Approved Request</span>
                </div> */}

                <div className="assessment-tabs-section">
                  <Tabs
                    id="controlled-assessment-tabs"
                    activeKey={activeTab}
                    onSelect={(selectedTab) => this.handleChangeTab(selectedTab)}
                  >
                    <Tab eventKey="accessManagement" title="Access Management">
                      <AccessManagement searchFilterTextValue={searchFilterTextValue} />
                    </Tab>

                    <Tab eventKey="accessRequest" title="Access Request">
                      <NotificationRequestsListing searchFilterTextValue={searchFilterTextValue} />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BatAccessManagement);
