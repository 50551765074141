import React, { Component } from 'react';
import axiosInstance from '../Shared/interceptor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tabs, Tab, Table, Modal, Button } from 'react-bootstrap';
import { faPencilAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { store } from "../reducers/configureStore";
import { LocalApiBaseUrl } from '../Shared/Constant';
import QueryBuilderPopUp from './QueryBuilderPopUp';
import ReactTooltip from "react-tooltip";
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


//BUild FOr UAT Deployment
//BUild QB
export default class ReviewMetricStep extends Component {

    constructor(props) {
        super(props);
        this.state = {
            place: "top",
            type: "dark",
            effect: "float",
            condition: false
        };
        this.formatOperandDefinition = this.formatOperandDefinition.bind(this);
    }
 changePlace(place) {
        this.setState({
            place: place
        });
    }

    changeType(type) {
        this.setState({
            type: type
        });
    }

    changeEffect(effect) {
        this.setState({
            effect: effect
        });
    }

    _onClick() {
        this.setState({
            condition: true
        });
    }

    formatOperandDefinition(str) {
        let maxLength = 40, useWordBoundary = true;if (str.length <= maxLength) 
        { return str; }const trimmedString = str.substring(0, maxLength);
        return trimmedString + "...";}

    render() {
        //QB Changes
        let formulaBoxArr = this.props.reviewStepObject.Formulavariables;
        let metricValue = "";
        const metricName = this.props.metricType === "UPDATE" ? this.props.selectedConfMetricObj.MetricDefinition: this.props.metricSelecObj.metricDescription;
        const unit = this.props.metricType === "UPDATE"? this.props.KPIUnit: this.props.metricSelecObj.metricUnit
        if(unit.includes('$') || unit.includes('€') || unit.includes('£') || unit.includes('₹') || unit.includes('SGD') || unit.includes('R$')){
            metricValue = unit + this.props.reviewStepObject.FormulaValue;
        }else{
            metricValue = this.props.reviewStepObject.FormulaValue + unit;
        }
        return (
          <div className="reviewBar px-3">
            {this.props.reviewStepObject.IsFormula ? (
              <>
                <h1 className="ht-title">Formula</h1>
                <div className="formulaGroup list-group list-group-horizontal mb-5">
                  {formulaBoxArr.length > 0 &&
                    formulaBoxArr.map((formObj, idx) => {
                      let operatorSymbol;
                      if (formObj.Operators === "-") {
                        operatorSymbol = " minus-icon";
                      } else if (formObj.Operators === "*") {
                        operatorSymbol = " multiply-icon";
                      } else if (formObj.Operators === "/") {
                        operatorSymbol = " division-icon";
                      } else {
                        operatorSymbol = " addition-icon";
                      }
                      return (
                        <div
                          className={
                            idx > 0 ? "list-group-item " + operatorSymbol : "list-group-item"
                          }
                          key={"variables_" + idx}
                        >
                          <h6>
                            <span></span> {formObj.OperandText}
                          </h6>
                          <div className="customtooltip-hover">
                            <p className="formulaListInfo line-clamp-ellipsis">
                              {/* {formObj.OperandValue} */}
                              {this.formatOperandDefinition(formObj.OperandValue)}
                            </p>
                            <div className="custom-tooltip-query">{formObj.OperandValue}</div>
                          </div>
                          {formObj.OperandName !== "Constant" && (
                            <div className="customtooltip-hover formulaDays">
                              <p>
                                <var className="line-clamp-ellipsis queryvalue-tooltip">
                                  {formObj.ResultQueryValue}
                                </var>
                                {this.props.metricType === "UPDATE"
                                  ? this.props.KPIUnit
                                  : this.props.metricSelecObj.metricUnit}
                              </p>
                              <div className="custom-tooltip-query">{formObj.ResultQueryValue}</div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </>
            ) : null}
            <>
              <h1 className="ht-title">Metric Data</h1>
              <div className="custom-tilecard ddtile-wrapper">
                <div className="tilecard-wrapper no-carousel">
                  <div className="item green-color" data-place="bottom">
                    <div className="item-left">
                      <p className="tile-metric-value">
                        {metricValue}
                      </p>
                      <p className="tile-metric-desc">
                        {this.props.metricType === "UPDATE"
                          ? this.props.selectedConfMetricObj.MetricName
                          : this.props.metricSelecObj.metricName}
                      </p>
                    </div>
                    <div className={"item-right middle-align"}>
                      <p className="tile-metric-name" data-tip data-for='customTooltip'>                        
                            {metricName}
                        <ReactTooltip place="right" effect="solid" id='customTooltip' className="tilecard-tooltip">
                            {metricName}
                        </ReactTooltip>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        );
    }

}


