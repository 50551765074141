export const JOURNEY_INITIATIVE_DATA_INITIALIZE = 'JOURNEY_INITIATIVE_DATA_INITIALIZE';
export const JOURNEY_INITIATIVE_DATA_ADD = 'JOURNEY_INITIATIVE_DATA_ADD';
export const JOURNEY_INITIATIVE_DATA_REMOVE = 'JOURNEY_INITIATIVE_DATA_REMOVE';
export const JOURNEY_INITIATIVE_BOI_LIST_INITIALIZE = 'JOURNEY_INITIATIVE_BOI_LIST_INITIALIZE';
export const JOURNEY_INITIATIVE_II_METRICS_INITIALIZE = 'JOURNEY_INITIATIVE_II_METRICS_INITIALIZE';
export const JOURNEY_INITIATIVE_DATAS = 'JOURNEY_INITIATIVE_DATAS';
export const JOURNEY_INITIATIVE_DATA_REMOVE_FROM_INITATIVEDETAIL = 'JOURNEY_INITIATIVE_DATA_REMOVE_FROM_INITATIVEDETAIL';
export const JOURNEY_INITIATIVE_DATA_IOJCATEGORY = 'JOURNEY_INITIATIVE_DATA_IOJCATEGORY';
export const JOURNEY_INITIATIVE_DATA_HIDDEN = 'JOURNEY_INITIATIVE_DATA_HIDDEN';
export const JOURNEY_INITIALIZE_IOJ_DATA = 'JOURNEY_INITIALIZE_IOJ_DATA';
export const JOURNEY_CLEAR_DATAS = 'JOURNEY_CLEAR_DATAS';
export const JOURNEY_CLEAR_BOIIDATAS = 'JOURNEY_CLEAR_BOIIDATAS';
export const SAVE_JOURNEY_TAB_DATA = 'SAVE_JOURNEY_TAB_DATA';
export const INCREMENT_GET_DATA_COUNTER_VALUE = 'INCREMENT_GET_DATA_COUNTER_VALUE';
export const ADD_BODATA_FOR_IOJ = 'ADD_BODATA_FOR_IOJ';
export const ADD_IIDATA_FOR_IOJ = 'ADD_IIDATA_FOR_IOJ';
export const STORE_ACCESS = 'STORE_ACCESS';
export const JOURNEY_INITIALIZE_IOJOM_RATING_SCORE = 'JOURNEY_INITIALIZE_IOJOM_RATING_SCORE';
export const JOURNEY_SET_OMIDSTATUS = 'JOURNEY_SET_OMIDSTATUS';
export const JOURNEY_INITIALIZE_OMIDLIST = 'JOURNEY_INITIALIZE_OMIDLIST';
export const JOURNEY_ALL_SELECTION = 'JOURNEY_ALL_SELECTION';
export const API_LOAD = 'API_LOAD';
export const JOURNEY_SET_SUBOFFERING_NAME = 'JOURNEY_SET_SUBOFFERING_NAME';
export const JOURNEY_SET_ISACTION = 'JOURNEY_SET_ISACTION';
export const JOURNEY_STATUS_DATA_INITIALIZE = 'JOURNEY_STATUS_DATA_INITIALIZE';

export function apiload(data) {
  return {
    type: API_LOAD,
    payload: data,
  };
}

export function selectedSubofferingName(data) {
  return {
    type: JOURNEY_SET_SUBOFFERING_NAME,
    payload: data,
  };
}

export function allSelected(data) {
  return {
    type: JOURNEY_ALL_SELECTION,
    payload: data,
  };
}

export function addAccess(isAccess) {
  return {
    type: STORE_ACCESS,
    payload: isAccess,
  };
}
export function actionStatus(isAction) {
  return {
    type: JOURNEY_SET_ISACTION,
    payload: isAction,
  };
}
export function setStatusData(statusData) {
  return {
    type: JOURNEY_STATUS_DATA_INITIALIZE,
    payload: statusData,
  };
}

export function addBOdata(BOData) {
  return {
    type: ADD_BODATA_FOR_IOJ,
    payload: BOData,
  };
}

export function addIIdata(IIData) {
  return {
    type: ADD_IIDATA_FOR_IOJ,
    payload: IIData,
  };
}

export function InitializeInitiativedatas(initiativeData) {
  return {
    type: JOURNEY_INITIATIVE_DATAS,
    payload: initiativeData,
  };
}
export function saveJourneyData(data) {
  return {
    type: SAVE_JOURNEY_TAB_DATA,
    payload: data,
  };
}
export function IncrementGetDataCounter(data) {
  return {
    type: INCREMENT_GET_DATA_COUNTER_VALUE,
    payload: data,
  };
}

export function InitializeInitiative(initiativeData) {
  return {
    type: JOURNEY_INITIATIVE_DATA_INITIALIZE,
    payload: initiativeData,
  };
}

export function AddEditInitiative(updatedInitiative) {
  return {
    type: JOURNEY_INITIATIVE_DATA_ADD,
    payload: updatedInitiative,
  };
}

export function DeleteInitiative(deletedInitiative) {
  return {
    type: JOURNEY_INITIATIVE_DATA_REMOVE,
    payload: { deletedInitiative },
  };
}
export function deleteInitiativeDetail(deletedInitiative) {
  return {
    type: JOURNEY_INITIATIVE_DATA_REMOVE_FROM_INITATIVEDETAIL,
    payload: { deletedInitiative },
  };
}

export function InitializeBOIList(BOList) {
  return {
    type: JOURNEY_INITIATIVE_BOI_LIST_INITIALIZE,
    payload: BOList,
  };
}
export function InitializeIIMetric(IIList) {
  return {
    type: JOURNEY_INITIATIVE_II_METRICS_INITIALIZE,
    payload: IIList,
  };
}

export function InitializeIOCategory(data) {
  return {
    type: JOURNEY_INITIATIVE_DATA_IOJCATEGORY,
    payload: data,
  };
}

export function SetInitiativeHidden(data) {
  return {
    type: JOURNEY_INITIATIVE_DATA_HIDDEN,
    payload: data,
  };
}
export function InitializeIOJData(data) {
  return {
    type: JOURNEY_INITIALIZE_IOJ_DATA,
    payload: data,
  };
}

export function ClearDatas_ViewDataStageData(data) {
  return {
    type: JOURNEY_CLEAR_DATAS,
    payload: data,
  };
}
export function InitializeDatas(data) {
  return {
    type: JOURNEY_CLEAR_BOIIDATAS,
    payload: data,
  };
}

export function InitializeIOJOM_RatingScore(data) {
  return {
    type: JOURNEY_INITIALIZE_IOJOM_RATING_SCORE,
    payload: data,
  };
}
export function SetOMIDStatus(data) {
  return {
    type: JOURNEY_SET_OMIDSTATUS,
    payload: data,
  };
}

export function InitializeOMIDListData(data) {
  return {
    type: JOURNEY_INITIALIZE_OMIDLIST,
    payload: data,
  };
}
