import React from "react";
import { Modal, Button } from "react-bootstrap";
import {
  LocalApiBaseUrl,
  regexForTextAreas,
  regexForFields,
  regexForURL,
  regexForToolURL,
  commonToolRegex,
  ImageFormats
} from "../Shared/Constant";
import LoadingOverlay from "react-loading-overlay";
import { store } from "../reducers/configureStore";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { connect } from "react-redux";
import SynopsLoader from "../Shared/SynopsLoader";
import i18n from "i18next";

class AddToolPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoaderOnPage:false,
      isAPILoading:false,
      popupHeader: i18n.t("Add_Tool"),
      popupSaveButton: this.props.tooldatatype
        ? this.props.tooldatatype
        : i18n.t("Create"),
      selectedfile: "",
      IconBase64: "",
      toolDetails: {
        id: 0,
        toolName: "",
        toolCategory: 0,
        toolCategoryName: "",
        synopsLevers: 4,
        url: "",
        description: "",
        isActive: true,
        isFieldDisable: false,
        businessBenefit: "",
        icon: "",
        defaultUserID: "",
        fileName: "",
        toolType: "",
      },
      toolErrors: {
        toolName: "",
        toolCategory: "",
        synopsLevers: "",
        url: "",
        description: "",
        businessBenefit: "",
        icon: "",
        defaultUserID: "",
        toolType: "",
      },
      toolLeversList: [],
      toolCategoryList: [],
    };
  }

  componentDidMount() {
    if (
      this.props.tooldata != undefined &&
      this.props.tooldatatype == "Edit" &&
      this.props.screen === "SuperAdmin"
    ) {
      let data = this.props.tooldata;
      this.setState({
        popupHeader: i18n.t("Edit_Tool"),
        popupSaveButton: i18n.t("Update"),
        toolDetails: {
          id: data.ApplicationMappingID,
          toolName: data.ApplicationName,
          toolCategory: data.ApplicationCategoryID,
          synopsLevers: data.SOCategoryID,
          url: data.URL,
          businessBenefit: data.BusinessBenefit,
          description: data.ApplicationDescription,
          icon: data.Icon,
          fileName: "",
          toolCategoryName: data.ApplicationCategory,
          defaultUserID: data.DefaultUserID,
          isActive: data.IsActive,
          isFieldDisable: true,
          toolType: data.ToolType,
        },
      });
    } else {
      this.setState({
        popupHeader: i18n.t("Add_Tool"),
        popupSaveButton: i18n.t("Create"),
        toolDetails: {
          id: 0,
          toolName: "",
          toolCategory: 0,
          synopsLevers: 0,
          url: "",
          description: "",
          isActive: true,
          isFieldDisable: false,
          toolType: "",
        },
      });
    }
  }

  onClose = () => {
    this.props.onClose();
  };

  bindToolFieldChange = (e) => {
    const id = e.target.id;
    let data = this.state.toolDetails;
    let errors = this.state.toolErrors;
    switch (id) {
      case "txtToolName":
        data.toolName = e.target.value;
        errors["toolName"] = "";
        break;
      case "ddToolCategrory":
        data.toolCategory = e.target.value;
        data.toolCategoryName = e.target.options[e.target.selectedIndex].text;
        errors["toolCategory"] = "";
        break;
      /* case "ddSynopsLevers":
        data.synopsLevers = e.target.value;
        break; */
      case "txtToolUrl":
        data.url = e.target.value;
        errors["url"] = "";
        break;
      case "txtToolDescription":
        data.description = e.target.value;
        errors["description"] = "";
        break;
      case "chkIsActive":
        data.isActive = e.target.checked;
        break;
      case "businessBenefit":
        data.businessBenefit = e.target.value;
        errors["businessBenefit"] = "";
        break;
      case "defaultUserID":
        data.defaultUserID = e.target.value;
        errors["defaultUserID"] = "";
        break;
      case "toolTypes":
        data.toolType = e.target.value;
        errors["toolType"] = "";
        break;
    }

    this.setState({
      toolDetails: data,
      toolErrors: errors,
    });
  };
  validateToolField = () => {
    let isValid = true;
    let errorJSON = [];
    const data = this.state.toolDetails;

    if (data.toolName == undefined || data.toolName.trim() == "") {
      errorJSON[0] = i18n.t("ToolName_Required");
      isValid = false;
    } else {
      if (regexForFields.test(data.toolName.trim())) {
        errorJSON[0] = "";
      } else {
        errorJSON[0] = i18n.t("ToolName_SpecialChar");
        isValid = false;
      }
    }

    if (data.toolCategory == undefined || parseInt(data.toolCategory) == 0) {
      errorJSON[1] = i18n.t("ToolCategory_Required");
      isValid = false;
    } else {
      errorJSON[1] = "";
    }

    /* if (data.synopsLevers == undefined || parseInt(data.synopsLevers) == 0) {
      errorJSON[2] = i18n.t("ToolSynopsLevers_Required");
    }
    else {
      errorJSON[2] = "";
    } */

    if (data.description == undefined || data.description.trim() == "") {
      errorJSON[3] = i18n.t("ToolDescription_Required");
      isValid = false;
    } else {
      if (regexForTextAreas.test(data.description.trim())) {
        errorJSON[3] = "";
      } else {
        errorJSON[3] = i18n.t("ToolDescription_SpecialChar");
        isValid = false;
      }
    }
    if (
      regexForTextAreas.test(
        data.businessBenefit ? data.businessBenefit.trim() : ""
      )
    ) {
      errorJSON[5] = "";
    } else {
      if (!data.businessBenefit) {
        errorJSON[5] = "";
      } else {
        errorJSON[5] = i18n.t("BusinessBenefit_SpecialChar");
        isValid = false;
      }
    }
    if (
      regexForFields.test(data.defaultUserID ? data.defaultUserID.trim() : "")
    ) {
      errorJSON[6] = "";
    } else {
      if (!data.defaultUserID) {
        errorJSON[6] = "";
      } else {
        errorJSON[6] = i18n.t("defaultUserID_SpecialChar");
        isValid = false;
      }
    }
    //S - Code for WO
      if (this.props.screen === "SuperAdmin" || this.props.screen === "Configurator") {
          if (data.fileName  && ImageFormats.includes(data.fileName ? data.fileName.split(".").pop() : "")) {
              if(parseFloat(this.state.selectedfile.size / 1024).toFixed(2) <= 10){
                  errorJSON[7] = "";
              }else{
                  errorJSON[7] = "Icon size should be less than 10KB";
                  isValid = false;

              }

          }
          else {
              if(!data.fileName){
                  errorJSON[7] = "";
              }else{
                  errorJSON[7] = i18n.t("Icon_format_Error");
                  isValid = false;
              }

          }
      if (
        data.toolType == undefined ||
        parseInt(data.toolType) == 0 ||
        data.toolType == ""
      ) {
        errorJSON[8] = "Select Tool Type";
        isValid = false;
      } else {
        errorJSON[8] = "";
      }
    }
    // E- Code for WO
    if(!commonToolRegex.test(data.url.trim().toLowerCase())){
    if (
      data.url != undefined &&
      data.url.trim() != "" &&
      !regexForToolURL.test(data.url.trim().toLowerCase())
    ) {
      errorJSON[4] = i18n.t("Invalid_URL");
      isValid = false;
    } else {
      errorJSON[4] = "";
    }
  }
  else{
    errorJSON[4] =i18n.t("commonToolRegex_validatemessage");
    isValid = false;
  }
    //Validation for Tool Type

    this.setState({
      toolErrors: {
        toolName: errorJSON[0],
        toolCategory: errorJSON[1],
        synopsLevers: errorJSON[2],
        description: errorJSON[3],
        url: errorJSON[4],
        businessBenefit: errorJSON[5],
        defaultUserID: errorJSON[6],
        icon: errorJSON[7],
        toolType: errorJSON[8],
      },
    });

    /* if (errorJSON[0] == "" && errorJSON[1] == "" && errorJSON[3] == "" && errorJSON[4] == "" && errorJSON[5] == "" && errorJSON[6] == "" && errorJSON[7] == "")
      isValid = true; */
   
    return isValid;
  };

  saveTool = () => {
   
    if (this.validateToolField()) {
      const data = this.state.toolDetails;
      let CategoryId;
        if (data.toolCategory === "Accenture") {
        CategoryId = 1;
      } else {
        CategoryId = 2;
      }

      const paramConf = {
        ApplicationName: data.toolName.trim(),
        OfferingID:
          this.props.lever === "WO"
            ? this.props.filter.process
            : parseInt(this.props.filter.process),
        SOCategoryID: this.props.SOCategoryID,
        OrgEntityID:
          this.props.lever === "WO"
            ? this.props.filter.Client
            : parseInt(this.props.filter.Client),
        LocationID:
          this.props.lever === "WO"
            ? this.props.filter.Locations
            : parseInt(this.props.filter.Locations),
        ApplicationCategoryID: CategoryId,
        ApplicationDescription: data.description.trim(),
        URL: data.url.trim(),
        DefaultUserID: data.defaultUserID ? data.defaultUserID : "",
        BusinessBenefit: data.businessBenefit ? data.businessBenefit : "",
        Tooltype: data.toolType,
      };



      if (this.props.screen === "SuperAdmin" && this.props.lever === "WO") {
          const tooldetails = this.state.toolDetails;
          const data = new FormData()
          if(tooldetails.fileName){

              data.append('icon', this.state.selectedfile, tooldetails.fileName)
          }
          else{
              if(tooldetails.icon){
                data.append('icon', null)
                data.append('IconBase64', tooldetails.icon)
              }else{
                  data.append('icon', null)
                  data.append('IconBase64',"")
              }

          }
          data.append( "ApplicationMappingID", this.props.tooldatatype == "Edit" ? this.props.tooldata.ApplicationMappingID : "0");
          data.append( "OfferingID",  this.props.lever === "WO" && this.props.filter.process);
          data.append( "ApplicationName", tooldetails.toolName.trim());
          data.append( "ApplicationName_En", tooldetails.toolName.trim());
          data.append( "ApplicationCategory", tooldetails.toolCategoryName.trim());
          data.append( "ApplicationCategoryID", CategoryId);
          data.append( "SOCategoryID", 3);
          data.append( "URL", tooldetails.url.trim());
          data.append( "ApplicationDescription", tooldetails.description.trim());
          data.append( "ApplicationDescription_En", tooldetails.description.trim());
          data.append( "IsActive", true);
          data.append( "businessBenefit", tooldetails.businessBenefit ? tooldetails.businessBenefit : "");
          data.append("defaultUserID", tooldetails.defaultUserID ? tooldetails.defaultUserID : "");
          data.append( "ClientID",0);
          data.append("ToolType", tooldetails.toolType);
          data.append("BusinessBenefit_En", null);
          data.append("OrgEntityID", 0);

          axiosInstance.post(`${LocalApiBaseUrl}Master/AddEditMasterTool`, data)
              .then((response) => {
             if (response.data.IsSuccess) {
              window.alert(response.data.StatusMessage);
              this.props.getOfferingAPICallback(true);
              this.onClose();
             } 
            else {
             window.alert(response.data.StatusMessage);
             this.setState({ showLoaderOnPage: false });
            }
         })
         .catch((error) => {
          trycatchAlertPopup(error);
        });
      }
      else {
        if (data.fileName) {
          paramConf.icon = this.state.selectedfile + data.fileName;
        } else {
          if (data.icon) {
            paramConf.icon = [0];
            paramConf.IconBase64 = data.icon;
          } else {
            paramConf.icon = [0];
            paramConf.IconBase64 = "";
          }
        }
        this.setState({ showLoaderOnPage: true,
        isAPILoading:true, });
        axiosInstance.post(`${LocalApiBaseUrl}Configurator/SaveNewCustomToolApi`, paramConf)
        .then((response) => {
          this.setState({ showLoaderOnPage: false,
          isAPILoading:false, });
          window.alert(response.data.StatusMessage);
          if (response.data.IsSuccess) {
            this.onClose();
          } 
          else {
            this.setState({ showLoaderOnPage: false });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
      }
     

    
    }
  };
  onIconSelect = (event) => {
      let data = this.state.toolDetails;
      let error = this.state.toolErrors;
      error.icon = "";
    data.icon = event.target.files[0];
      let fileName = event.target.value.split("\\").pop();
      data.fileName = fileName;
      this.setState({ asset: data,selectedfile: event.target.files[0],IconBase64:null,toolErrors:error});
  }

  render() {

    return (
      <>
       {this.props.lever === "WO" && <LoadingOverlay 
       className="custom-loader" 
       fadeSpeed={0} 
       spinner={<SynopsLoader />}
       active={this.state.showLoaderOnPage} 
      //  text='Loading SynOps …'
       ></LoadingOverlay> }

        {/* <Header /> */}
        <Modal
          show={this.props.show}
          className="modal fade"
          size="medium"
          id="dealpopup"
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <Modal.Header>
            {" "}
            {/*closeButton*/}
            <h4 className="modal-title">{this.props.lever === "WO" ? `${this.state.popupHeader} - Work Orchestration` : this.state.popupHeader }</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            
            {this.props.lever === "I&I" &&  <LoadingOverlay className="custom-loader" 
            fadeSpeed={0} 
            spinner={<SynopsLoader />} 
            active={this.state.isAPILoading} 
            // text='Loading SynOps …'
            ></LoadingOverlay>}
              <div className="form-section">
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label for="">
                      {i18n.t("Tool_Name")} <span className="required">*</span>
                    </label>
                    <input
                      id="txtToolName"
                      type="text"
                      maxlength="45"
                      className="form-control"
                      onChange={(e) => {
                        this.bindToolFieldChange(e);
                      }}
                      value={this.state.toolDetails.toolName}
                      disabled={this.state.toolDetails.isFieldDisable}
                    />
                    <span className="errorMsg">
                      {this.state.toolErrors.toolName}
                    </span>
                  </div>

                  <div className="form-group col-lg-6">
                    <label for="">
                      {i18n.t("Tool_Category")}{" "}
                      <span className="required">*</span>
                    </label>
                    <select
                      id="ddToolCategrory"
                      name="toolCategory"
                      className="form-control"
                      onChange={(e) => {
                        this.bindToolFieldChange(e);
                      }}
                      value={
                        this.props.tooldatatype == "Edit"
                          ? this.state.toolDetails.toolCategoryName
                          : this.state.toolDetails.toolCategory
                      }
                      disabled={this.state.toolDetails.isFieldDisable}
                    >
                      <option value="0">
                        {i18n.t("Select_Tool_Category")}
                      </option>
                      <option value="Accenture">Accenture</option>
                      <option value="Client">Client</option>
                    </select>
                    <span className="errorMsg">
                      {this.state.toolErrors.toolCategory}
                    </span>
                  </div>
                  {/* <div className="form-group col-lg-6">
                    <label for="">
                      {i18n.t("Synops_Levers")} <span className="required">*</span>
                    </label>
                    <select id="ddSynopsLevers" name="synopsLevers" className="form-control" disabled={this.state.toolDetails.isFieldDisable} onChange={(e) => { this.bindToolFieldChange(e) }} value={this.state.toolDetails.synopsLevers}>
                      <option value="0">{i18n.t("Select_Synops_Levers")}</option>
                      {this.state.toolLeversList}
                    </select>
                    <span className="errorMsg">
                      {this.state.toolErrors.synopsLevers}
                    </span>
                  </div> */}
                  {/* <div className="form-group col-lg-6">
                    <label class="form-check"><input type="checkbox" class="form-check-input" id="chkIsActive" checked={this.state.toolDetails.isActive} value={this.state.toolDetails.isActive} onChange={(e) => { this.bindToolFieldChange(e) }} />
                      <span class="checkmark"></span>
                      <span class="form-check-label">{i18n.t("Active")}</span>
                    </label>
                  </div> */}

                  {/**Adding fields for WO lever */}
                  {(this.props.screen === "SuperAdmin" ||
                    this.props.screen === "Configurator") &&
                  this.props.lever === "WO" ? (
                    <>
                      <div className="form-group col-lg-6">
                        <label for="level">Maturity Level</label>
                        <select className="form-control" disabled>
                          <option value="1">Maturity Level 1</option>
                          <option value="2">Maturity Level 2</option>
                          <option value="3">Maturity Level 3</option>
                        </select>
                      </div>
                      <div className="form-group col-lg-6">
                        <label for="type">
                          Tool Type<span className="required">*</span>
                        </label>
                        <select
                          id="toolTypes"
                          name="toolTypes"
                          className="form-control"
                          onChange={(e) => {
                            this.bindToolFieldChange(e);
                          }}
                          value={this.state.toolDetails.toolType}
                          disabled={this.state.toolDetails.isFieldDisable}
                        >
                          <option value="0">Select Type</option>
                          <option value="AA">Advanced Analytics</option>
                          <option value="AI">Artificial Intelligence</option>
                          <option value="AT">Automation</option>
                          <option value="WF">Workflow</option>
                        </select>
                        <span className="errorMsg">
                          {this.state.toolErrors.toolType}
                        </span>
                      </div>
                    </>
                  ) : null}
                  {/**Adding fields for WO lever */}
                  <div className="form-group col-lg-12">
                    <label for="">URL</label>
                    <input
                      id="txtToolUrl"
                      type="url"
                      maxlength="5000"
                      className="form-control"
                      onChange={(e) => {
                        this.bindToolFieldChange(e);
                      }}
                      value={this.state.toolDetails.url}
                    />
                    <span className="errorMsg">
                      {this.state.toolErrors.url}
                    </span>
                  </div>
                  <div className="form-group col-lg-12">
                    <label for="">
                      {i18n.t("Description")}{" "}
                      <span className="required">*</span>
                    </label>
                    <textarea
                      id="txtToolDescription"
                      rows="2"
                      name="Description"
                      maxLength="70"
                      className="form-control"
                      onChange={(e) => {
                        this.bindToolFieldChange(e);
                      }}
                      value={this.state.toolDetails.description}
                    />
                    <span className="errorMsg">
                      {this.state.toolErrors.description}
                    </span>
                  </div>

                  <div className="form-group col-lg-12">
                    <label for="">{i18n.t("Business_Benefit")}</label>
                    <textarea
                      id="businessBenefit"
                      rows="2"
                      name="Business_Benefit"
                      maxLength="255"
                      className="form-control"
                      onChange={(e) => {
                        this.bindToolFieldChange(e);
                      }}
                      value={this.state.toolDetails.businessBenefit}
                    />
                    <span className="errorMsg">
                      {this.state.toolErrors.businessBenefit}
                    </span>
                  </div>
                  <div className="form-group col-lg-6">
                    <label for="">{i18n.t("Deafult_User_ID")}</label>
                    <input
                      id="defaultUserID"
                      type="text"
                      maxlength="100"
                      className="form-control"
                      onChange={(e) => {
                        this.bindToolFieldChange(e);
                      }}
                      value={this.state.toolDetails.defaultUserID}
                    />
                    <span className="errorMsg">
                      {this.state.toolErrors.defaultUserID}
                    </span>
                  </div>
                  {/**Adding fields for WO lever */}
                  {this.props.screen === "SuperAdmin" &&
                  this.props.lever === "WO" ? (
                    <div className="form-group col-lg-6">
                      <label for="practice">Tool Icon</label>
                      <div class="upload-btn-wrapper form-control">
                        <button class="btn">Browse</button>
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          accept="image/*"
                          onChange={this.onIconSelect}
                          name="filename"
                        />
                        <label className="custom-file-label noborder-custom-filelabel" for="customFile">
                          {this.state.toolDetails.fileName
                            ? this.state.toolDetails.fileName
                            : "Choose Icon"}
                        </label>
                       
                        {this.state.popupSaveButton !== "ADD" &&
                        !this.state.toolDetails.fileName &&
                        this.state.toolDetails.icon ? (
                          <div className="form-group custom-file-css col-md-6 mt-4">
                            <img
                              src={
                                "data:image/png;base64," +
                                this.state.toolDetails.icon
                              }
                              width="30px"
                              height="30px"
                            ></img>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <span className="errorMsg">
                          {this.state.toolErrors.icon}
                        </span>
                    </div>
                  ) : null}
                  {/**Adding fields for WO lever */}
                </div>
              </div>
            
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">
                {this.props.lever === "WO" ? (
                  <button
                    onClick={this.onClose}
                    className="btn btn-secondary btn-sm mr-2"
                  >
                    {i18n.t("Cancel")}
                  </button>
                ) : null}
                <input
                  type="Button"
                  className="btn btn-primary btn-sm"
                  onClick={this.saveTool}
                  value={this.state.popupSaveButton}
                />
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languageData: state.CurrentFilter.languageData,
  };
};
export default connect(mapStateToProps)(AddToolPopUp);
