import React, { Component } from "react";
import emptycomponent from "../Images/Empty State.svg";
import logo from "../Images/bat_icons/Bill.png";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import { Modal, Button, Overlay, Popover, Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import Chart from "chart.js";
import _ from "lodash";
import i18n from "i18next";
// import MultiSelect from "react-multi-select-component";
import { Multiselect } from "multiselect-react-dropdown";
import $ from "jquery";
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  Label,
  ResponsiveContainer,
  LabelList,
  CartesianGrid,
} from "recharts";
import { indexOf } from "lodash";

const pieColor = ["#9c6ade", "#018eff", "#bd3aff"];

const pieColor2 = ["#9c6ade", "#bd3aff", "#018eff", "#018eff"];

const pieColor3 = ["#01bbee", "#fe9b00", "#8e6635", "#548235", "#7030a0"];

const RADIAN = Math.PI / 180;

const tempYOYCVD = {};

class CustomTooltip extends Component {
  constructor() {
    super();
    this.state = {
      data: {
        label: "",
        fill: "",
      },
      bar3Data: [],
    };
  }

  componentWillReceiveProps = (nextProps) => {
    let tempLabel = "";
    let tempFill = "";
    let tempData = [];
    if (nextProps.active && nextProps.payload!=undefined) {
      if (nextProps.origin == "bar 1") {
        tempLabel = nextProps.payload[0].payload.label;
        tempFill = nextProps.payload[0].fill;
      } else if (nextProps.origin == "bar 2") {
        //
        tempLabel = nextProps.payload[0].payload.name;
        tempFill = nextProps.payload[0].fill;
      } else if (nextProps.origin == "bar 3") {
        tempData = nextProps.payload;
        tempLabel = nextProps.label;
      } else {
        //pie
        tempLabel = nextProps.payload[0].name;
        tempFill = nextProps.payload[0].payload.fill;
      }

      this.setState({
        data: {
          ...this.state.data,
          label: tempLabel,
          fill: tempFill,
        },
        bar3Data: tempData,
      });
    }
  };

  render() {
    if (this.props.active && this.props.payload!=undefined) {
      return (
        <div
          className={
            this.props.origin == "bar 3" ? "custom-tooltip-main-container cust-bar3" : "custom-tooltip-main-container"
          }
        >
          {this.props.origin == "bar 3" ? (
            <div>
              <div className="custom-tooltip-lbl-container">
                <div className="custom-tooltip-label-bar3">{this.state.data.label}</div>
              </div>
              <div className="custom-tooltip-val-container-bar3">
                {this.state.bar3Data.map((item, index) => {
                  return (
                    <Row lg={1} md={1} sm={1} xs={1} className="tool-tip-data">
                      <div className="custom-tooltip-bar3-subcontainer">
                        <div className="custom-tooltip-box3" style={{ backgroundColor: item.fill }} />
                        <div className="custom-tooltip-label">{item.name}</div>
                        <div className="custom-tooltip-label">${parseFloat(item.value).toFixed(1)}</div>
                      </div>
                    </Row>
                  );
                })}
              </div>
            </div>
          ) : (
            <div>
              <div className="custom-tooltip-lbl-container">
                <div className="custom-tooltip-box" style={{ backgroundColor: this.state.data.fill }} />
                <div className="custom-tooltip-label">{this.state.data.label}</div>
              </div>
              <div className="custom-tooltip-val-container">
                {this.props.payload.map((item, index) => {
                  return (
                    <Row lg={1} md={1} sm={1} xs={1} className="tool-tip-data">
                      <div>
                        <div className="custom-tooltip-label">${parseFloat(item.value).toFixed(1)}</div>
                      </div>
                    </Row>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

class ValueRealizationGraphs extends Component {
  constructor() {
    super();
    this.state = {
      boiBarHeight: "",
      byDeliveryLocCBVSettings: {
        ticks: 5,
        maxVal: 0,
      },
      byDeliveryLocNPSettings: {
        ticks: 5,
        maxVal: 0,
      },
      byDeliveryLocNCSettings: {
        ticks: 5,
        maxVal: 0,
      },
      byClientSettings: {
        ticks: 5,
        maxVal: 0,
      },
      byOfferingSettings: {
        ticks: 5,
        maxVal: 0,
      },
      byOutcomeSettings: {
        ticks: 5,
        maxVal: 0,
      },
      businessOutcomePnL: [
        {
          value: 0,
          name: "",
        },
      ],
      businessValueNav: [
        {
          value: 0,
          name: "",
        },
      ],
      businessOutcomesSDO: [
        {
          value: 0,
          name: "",
        },
      ],
      yoyTrendPnL: [
        {
          name: "",
          northAmerica: 0,
          europe: 0,
          growthMarket: 0,
          value: "",
          yoyTotal: 0,
        },
      ],
      yoyTrendBusinessValue: [
        {
          name: "",
          marketUnits: [
            {
              MarketUnitName: "",
              MarketUnitValue: 0,
            },
          ],
          yoyTotal: 0,
        },
      ],
      offeringPnL: [
        {
          value: 0,
          name: "",
          offeringTotal: 0,
        },
      ],
      businessOutcomesInd: [
        {
          value: 0,
          name: "",
          boiTotal: 0,
        },
      ],
      clientPnL: [
        {
          value: 0,
          name: "",
          clientTotal: 0,
        },
      ],
      keyDiffMetrics: {
        annualized: [],
        numberofProject: [],
        clientCount: [],
      },
      businessOutcomePnLSum: 0,
      businessValueNavSum: 0,
      businessOutcomesSDOSum: 0,
      yoyTrendPnLSum: 0,
      yoyTrendBusinessValueSum: 0,
      offeringPnLSum: 0,
      businessOutcomesIndSum: 0,
      clientPnLSum: 0,
      keyDiffMetricsSum: 0,
      yoyCVDconvertedData: [],
      yoyCVDlabels: [],
      yoyVICconvertedData: [],
      yoyVIClabels: [],
    };
  }

  componentDidMount() {
    let dataset = this.props.dataset;
    this.getClientValueDelivered(dataset.ClientValueDelivereds);
    this.getClientValueDeliveredByMarkets(dataset.ClientValueDeliveredByMarkets);
    this.getValueImpactingClient(dataset.ValueImpactingClient);
    this.getYoyClientValueDelivered(dataset.YOYClientValueDelivered);
    this.getYoyValueImpactingClient(dataset.YOYValueImpactingClient);
    // this.getByOffering(dataset.ByOfferingData);
    this.getByOutcomeLever(dataset.ByOutcomeLeversData);
    // this.getByClient(dataset.ByClientData);
    this.getByDeliveryLocation(dataset.ByDeliveryLocationData);
  }

  componentWillReceiveProps(nextProps) {
    let dataset = nextProps.dataset;
    this.getClientValueDelivered(dataset.ClientValueDelivereds);
    this.getClientValueDeliveredByMarkets(dataset.ClientValueDeliveredByMarkets);
    this.getValueImpactingClient(dataset.ValueImpactingClient);
    this.getYoyClientValueDelivered(dataset.YOYClientValueDelivered);
    this.getYoyValueImpactingClient(dataset.YOYValueImpactingClient);
    // this.getByOffering(dataset.ByOfferingData);
    this.getByOutcomeLever(dataset.ByOutcomeLeversData);
    // this.getByClient(dataset.ByClientData);
    this.getByDeliveryLocation(dataset.ByDeliveryLocationData);
  }

  getClientValueDelivered = (data) => {
    // let data = [
    //   { BusinessValueTypeCode: "Cash Optimization", BusinessValueNavMnTotal: 3 },
    //   {
    //     BusinessValueTypeCode: "Operating Cost Optimization",
    //     BusinessValueNavMnTotal: 0.000046,
    //   },
    //   { BusinessValueTypeCode: "Top Line Growth", BusinessValueNavMnTotal: 0.000046 },
    // ];
    let list = [];
    let finList = [];
    if (data !== null) {
      list = data
        .filter((x) => x.BusinessValueNavMnTotal !== 0)
        .map((obj) => {
          return {
            value: this.convertToParseFloat(obj.BusinessValueNavMnTotal),
            name: obj.BusinessValueTypeCode.toLowerCase(),
          };
        });
      finList = this.fixDashboardDataPosition("donut a", list);

      let total =
        finList.length !== 0
          ? finList
              .map((obj) => {
                return obj.value;
              })
              .reduce((total, n) => {
                return total + n;
              })
              .toFixed(1)
          : 0;

      this.setState({
        businessValueNav: finList,
        businessValueNavSum: "$" + total,
      });
    } else {
      this.setState({
        businessValueNav: [],
      });
    }
  };

  getClientValueDeliveredByMarkets = (data) => {
    // let data = [
    //   { GeoAreaCode: "Europe", GeoAreaID: 35, Total: 5581.0 },
    //   { GeoAreaCode: "Growth Markets", GeoAreaID: 36, Total: 2851.1 },
    //   { GeoAreaCode: "North America", GeoAreaID: 5, Total: 25049.1 },
    // ];
    let list = [];
    let finList = [];
    if (data !== null) {
      list = data
        .filter((x) => x.Total !== 0)
        .map((obj) => {
          return {
            value: this.convertToParseFloat(obj.Total),
            name: obj.GeoAreaCode.toLowerCase(),
          };
        });

      finList = this.fixDashboardDataPosition("donut b", list);

      let total =
        finList.length !== 0
          ? finList
              .map((obj) => {
                return obj.value;
              })
              .reduce((total, n) => {
                return total + n;
              })
              .toFixed(1)
          : 0;

      this.setState({
        businessOutcomesSDO: finList,
        businessOutcomesSDOSum: "$" + total,
      });
    } else {
      this.setState({
        businessOutcomesSDO: [],
      });
    }
  };

  getValueImpactingClient = (data) => {
    // let data = [
    //   { BusinessValueTypeID: 2, BusinessValueTypeCode: "Cash Optimization", Total: 2247.2891 },
    //   { BusinessValueTypeID: 1, BusinessValueTypeCode: "Operating Cost Optimization", Total: 11732.3751 },
    //   { BusinessValueTypeID: 3, BusinessValueTypeCode: "Top Line Growth", Total: 137.4543 },
    // ];
    let list = [];
    let finList = [];

    if (data !== null) {
      list = data
        .filter((x) => x.Total !== 0)
        .map((obj) => {
          return {
            value: this.convertToParseFloat(obj.Total),
            name: obj.BusinessValueTypeCode.toLowerCase(),
          };
        });

      finList = this.fixDashboardDataPosition("donut a", list);
      let total =
        finList.length !== 0
          ? finList
              .map((obj) => {
                return obj.value;
              })
              .reduce((total, n) => {
                return total + n;
              })
              .toFixed(1)
          : 0;

      this.setState({
        businessOutcomePnL: finList,
        businessOutcomePnLSum: "$" + total,
      });
    } else {
      this.setState({
        businessOutcomePnL: [],
      });
    }
  };

  convertData(data) {
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      let obj = { FiscalYear: data[i].FiscalYear, Total: "$" + data[i].Total.toFixed(1) };
      // loop throgh comodities
      for (let j = 0; j < data[i].MarketUnit.length; j++) {
        let commodity = data[i].MarketUnit[j];
        obj[commodity.MarketUnitName] = commodity.MarketUnitValue.toFixed(1);
      }
      arr.push(obj);
    }
    return arr;
  }

  getLabels(data) {
    let arr = [];
    _.each(data, (obj) => {
      arr = arr.concat(obj.MarketUnit);
    });
    let grouped = _.groupBy(arr, "MarketUnitName");
    return Object.keys(grouped);
    //return Object.keys(_.groupby(arr.name));
  }

  getYoyClientValueDelivered = (data) => {
    if (data !== null) {
      var convertedData = this.convertData(data);
      var labels = this.getLabels(data);
      this.setState({
        yoyCVDconvertedData: convertedData,
        yoyCVDlabels: labels,
      });
    } else {
      this.setState({
        yoyCVDconvertedData: [],
      });
    }
  };

  getYoyValueImpactingClient = (data) => {
    if (data !== null) {
      var convertedData = this.convertData(data);
      var labels = this.getLabels(data);
      this.setState({
        yoyVICconvertedData: convertedData,
        yoyVIClabels: labels,
      });
    } else {
      this.setState({
        yoyVICconvertedData: [],
      });
    }
  };

  getByOffering = (data) => {
    // let data = [
    //   { OfferingID: 7, Description: "Finance and Accounting", Total: 10814.3 },
    //   { OfferingID: 14, Description: "Network", Total: 9270.2 },
    //   { OfferingID: 3, Description: "Banking", Total: 5698.8 },
    //   { OfferingID: 49, Description: "Sourcing and Procurement", Total: 3367.0 },
    //   { OfferingID: 13, Description: "Marketing", Total: 3207.5 },
    //   { OfferingID: 17, Description: "Supply Chain and IX.0", Total: 551.3 },
    //   { OfferingID: 8, Description: "Health", Total: 237.5 },
    //   { OfferingID: 68, Description: "Talent and HR", Total: 131.1 },
    //   { OfferingID: 65, Description: "Sales & Customer", Total: 51.0 },
    //   { OfferingID: 18, Description: "Utilities", Total: 43.2 },
    //   { OfferingID: 10, Description: "Insurance", Total: 38.6 },
    //   { OfferingID: 5, Description: "Miscellaneous Services", Total: 38.0 },
    //   { OfferingID: 15, Description: "Life Sciences", Total: 32.4 },
    //   { OfferingID: 42, Description: "Capital Markets", Total: 0.2 },
    // ];
    let list = [];
    let maxValArr = [];
    let maxVal = 0;
    let ticksArr = [];
    let testData = 0;
    if (data !== null) {
      for (var counter = 0; counter < data.length; counter++) {
        var item = data[counter];
        list.push({
          value: item.Total.toFixed(1),
          name: item.Description,
          offeringTotal: "$" + item.Total.toFixed(1),
        });
        maxValArr = maxValArr.concat(parseFloat(item.Total.toFixed(1)));
      }
      maxVal = Math.max(...maxValArr);
      for (var i = 0; i <= Math.round(maxVal / 500) + 1; i++) {
        ticksArr.push(testData);
        testData = testData + 500;
      }

      this.setState({
        offeringPnL: list,
        byOfferingSettings: {
          ...this.state.byOfferingSettings,
          ticks: ticksArr,
          maxVal: testData - 500,
        },
      });
    } else {
      this.setState({
        offeringPnL: [],
      });
    }
  };

  getByOutcomeLever = (data) => {
    // let data = [
    //   { BusinessOutcomesIndicator: "Others", Total: 17683.8 },
    //   { BusinessOutcomesIndicator: "Days Sales Outstanding", Total: 3830.1 },
    //   { BusinessOutcomesIndicator: "Improved targeting", Total: 2583.9 },
    //   {
    //     BusinessOutcomesIndicator: "Spend reduction/compliance from ZBB closed loop (demand, spec, price)",
    //     Total: 1907.0,
    //   },
    //   { BusinessOutcomesIndicator: "Reduced payment leakage", Total: 1280.8 },
    //   { BusinessOutcomesIndicator: "Enhanced Balance Sheet Integrity", Total: 932.3 },
    //   { BusinessOutcomesIndicator: "Optimize DPO", Total: 645.5 },
    //   { BusinessOutcomesIndicator: "Payment Terms Deviation (% AR non standard)", Total: 583.3 },
    //   { BusinessOutcomesIndicator: "Spend under management", Total: 372.7 },
    //   { BusinessOutcomesIndicator: "Extend the value of digital investments", Total: 314.4 },
    //   { BusinessOutcomesIndicator: "Ensure On-time deployment of new network", Total: 309.9 },
    //   { BusinessOutcomesIndicator: "Grooming & Decommisioning Network elements", Total: 272.8 },
    //   { BusinessOutcomesIndicator: "Improve Service Uptime", Total: 249.5 },
    //   { BusinessOutcomesIndicator: "Revenue leakage %", Total: 221.9 },
    //   { BusinessOutcomesIndicator: "Early payment discount capture", Total: 213.4 },
    //   { BusinessOutcomesIndicator: "Inventory Investment", Total: 176.6 },
    //   { BusinessOutcomesIndicator: "Improve auto-match percentage in balance sheet reconciliations", Total: 172.9 },
    //   { BusinessOutcomesIndicator: "Invoice Dispute %", Total: 168.1 },
    //   { BusinessOutcomesIndicator: "Control framework and continuous compliance assessment", Total: 165.9 },
    // ];
    let list = [];
    let maxValArr = [];
    let maxVal = 0;
    let ticksArr = [];
    let testData = 0;
    if (data !== null) {
      for (var counter = 0; counter < data.length; counter++) {
        var item = data[counter];
        list.push({
          value: item.Total.toFixed(1),
          name: item.BusinessOutcomesIndicator,
          boiTotal: "$" + item.Total.toFixed(1),
        });
        maxValArr = maxValArr.concat(parseFloat(item.Total.toFixed(1)));
      }
      maxVal = Math.max(...maxValArr);
      for (var i = 0; i <= Math.round(maxVal / 500) + 1; i++) {
        ticksArr.push(testData);
        testData = testData + 500;
      }
      this.setState({
        businessOutcomesInd: list,
        boiBarHeight: data.length,
        byOutcomeSettings: {
          ...this.state.byOutcomeSettings,
          ticks: ticksArr,
          maxVal: testData - 500,
        },
      });
    } else {
      this.setState({
        businessOutcomesInd: [],
      });
    }
  };

  getByClient = (data) => {
    // let data = [
    //   { ClientID: "212aeadc-4e7d-41ab-a292-982e85652f1a", Client: "scrambled.ClientName1212", Total: 34340.0 },
    //   { ClientID: "ea512edb-10bd-448b-9449-6ec06002a6b2", Client: "scrambled.ClientName883", Total: 9120.5 },
    //   { ClientID: "114bd551-a0a5-442e-a764-a5f48eb434d5", Client: "scrambled.ClientName1336", Total: 8586.5 },
    //   { ClientID: "e6fa6d83-cce5-4de2-9d38-014a405cae9d", Client: "scrambled.ClientName10", Total: 5729.9 },
    //   { ClientID: "50746e0a-00ce-41e0-a51a-d0dd810e9118", Client: "scrambled.ClientName1681", Total: 5592.2 },
    //   { ClientID: "5d59194d-de76-49f9-8578-0018fd94aed5", Client: "scrambled.ClientName1", Total: 3171.4 },
    //   { ClientID: "5c5625cb-ccb2-46c9-9187-0c54df157c19", Client: "scrambled.ClientName100", Total: 2240.3 },
    //   { ClientID: "79adb04d-e1f0-4d6d-ab39-65548c789740", Client: "scrambled.ClientName800", Total: 941.4 },
    //   { ClientID: "f0e5fb7b-2e10-4f35-9743-9660455957ec", Client: "scrambled.ClientName1204", Total: 707.9 },
    //   { ClientID: "e0f85a49-c681-40ab-b30e-852065d2c63e", Client: "scrambled.ClientName1070", Total: 653.4 },
    //   { ClientID: "fc665130-63e0-4465-8312-e2e4fa47baae", Client: "scrambled.ClientName1843", Total: 597.2 },
    //   { ClientID: "68979ef1-03f0-4aae-95ee-fe601c558643", Client: "scrambled.ClientName2071", Total: 516.2 },
    //   { ClientID: "7da0605a-01fe-4443-8dff-d84ac9222939", Client: "scrambled.ClientName1746", Total: 372.5 },
    //   { ClientID: "a05b2a25-8333-42a3-80ab-6c7ee4fe00d4", Client: "scrambled.ClientName868", Total: 362.9 },
    //   { ClientID: "28869c1f-ca34-4ead-8b11-9068c9c14583", Client: "scrambled.ClientName1157", Total: 334.3 },
    // ];
    let list = [];
    let maxValArr = [];
    let maxVal = 0;
    let ticksArr = [];
    let testData = 0;
    if (data !== null) {
      for (var counter = 0; counter < data.length; counter++) {
        var item = data[counter];
        list.push({
          value: item.Total.toFixed(1),
          name: item.Client,
          clientTotal: "$" + item.Total.toFixed(1),
        });
        maxValArr = maxValArr.concat(parseFloat(item.Total.toFixed(1)));
      }
      maxVal = Math.max(...maxValArr);
      for (var i = 0; i <= Math.round(maxVal / 500) + 1; i++) {
        ticksArr.push(testData);
        testData = testData + 500;
      }

      this.setState({
        clientPnL: list,
        byClientSettings: {
          ...this.state.byClientSettings,
          ticks: ticksArr,
          maxVal: testData - 500,
        },
      });
    } else {
      this.setState({
        clientPnL: [],
      });
    }
  };

  getByDeliveryLocation = (data) => {
    // let data = [
    //   {
    //     DeliveryLocationID: 3,
    //     Country: "CEE",
    //     BusinessValueNAV: 40929.9,
    //     Annualized: 40929.9496,
    //     NumberofProject: 474,
    //     ClientCount: 66,
    //   },
    //   {
    //     DeliveryLocationID: 7,
    //     Country: "India Chennai",
    //     BusinessValueNAV: 10641.2,
    //     Annualized: 10641.2272,
    //     NumberofProject: 1116,
    //     ClientCount: 67,
    //   },
    //   {
    //     DeliveryLocationID: 11,
    //     Country: "LATAM",
    //     BusinessValueNAV: 8987.6,
    //     Annualized: 8987.6405,
    //     NumberofProject: 213,
    //     ClientCount: 35,
    //   },
    //   {
    //     DeliveryLocationID: 12,
    //     Country: "Philippines",
    //     BusinessValueNAV: 6042.2,
    //     Annualized: 6042.2329,
    //     NumberofProject: 1615,
    //     ClientCount: 66,
    //   },
    //   {
    //     DeliveryLocationID: 6,
    //     Country: "India Bangalore",
    //     BusinessValueNAV: 2983.3,
    //     Annualized: 2983.3101,
    //     NumberofProject: 1437,
    //     ClientCount: 89,
    //   },
    //   {
    //     DeliveryLocationID: 9,
    //     Country: "India Mumbai",
    //     BusinessValueNAV: 2435.4,
    //     Annualized: 2435.3853,
    //     NumberofProject: 838,
    //     ClientCount: 55,
    //   },
    //   {
    //     DeliveryLocationID: 1,
    //     Country: "Rest of Americas",
    //     BusinessValueNAV: 1770.0,
    //     Annualized: 1769.9994,
    //     NumberofProject: 557,
    //     ClientCount: 98,
    //   },
    //   {
    //     DeliveryLocationID: 8,
    //     Country: "India Delhi",
    //     BusinessValueNAV: 866.8,
    //     Annualized: 866.8447,
    //     NumberofProject: 655,
    //     ClientCount: 35,
    //   },
    //   {
    //     DeliveryLocationID: 14,
    //     Country: "Rest of APAC",
    //     BusinessValueNAV: 699.0,
    //     Annualized: 698.9636,
    //     NumberofProject: 1074,
    //     ClientCount: 82,
    //   },
    //   {
    //     DeliveryLocationID: 18,
    //     Country: "India Hyderabad",
    //     BusinessValueNAV: 664.0,
    //     Annualized: 663.9746,
    //     NumberofProject: 11,
    //     ClientCount: 4,
    //   },
    //   {
    //     DeliveryLocationID: 23,
    //     Country: "India Pune",
    //     BusinessValueNAV: 260.0,
    //     Annualized: 260.0002,
    //     NumberofProject: 6,
    //     ClientCount: 2,
    //   },
    //   {
    //     DeliveryLocationID: 10,
    //     Country: "Italy + Mauritius",
    //     BusinessValueNAV: 41.8,
    //     Annualized: 41.8231,
    //     NumberofProject: 215,
    //     ClientCount: 24,
    //   },
    //   {
    //     DeliveryLocationID: 16,
    //     Country: "SPAI",
    //     BusinessValueNAV: 16.0,
    //     Annualized: 16.018,
    //     NumberofProject: 74,
    //     ClientCount: 15,
    //   },
    //   {
    //     DeliveryLocationID: 2,
    //     Country: "Canada",
    //     BusinessValueNAV: 0.1,
    //     Annualized: 0.0782,
    //     NumberofProject: 1,
    //     ClientCount: 1,
    //   },
    //   {
    //     DeliveryLocationID: 4,
    //     Country: "Client Site",
    //     BusinessValueNAV: 0.0,
    //     Annualized: 0.0,
    //     NumberofProject: 1,
    //     ClientCount: 1,
    //   },
    // ];

    let annualized = [];
    let numberofProject = [];
    let clientCount = [];

    let maxValAnnArr = [];
    let maxValAnn = 0;
    let ticksArrAnn = [];
    let testDataAnn = 0;

    let maxValNPArr = [];
    let maxValNP = 0;
    let ticksArrNP = [];
    let testDataNP = 0;

    let maxValCCArr = [];
    let maxValCC = 0;
    let ticksArrCC = [];
    let testDataCC = 0;

    if (data !== null) {
      data.forEach(function(obj) {
        annualized.push({
          value: obj.Annualized.toFixed(1),
          label: obj.Country,
          total: "$" + obj.Annualized.toFixed(1),
        });
        maxValAnnArr = maxValAnnArr.concat(parseFloat(obj.Annualized.toFixed(1)));
      });
      maxValAnn = Math.max(...maxValAnnArr);
      for (var i = 0; i <= Math.round(maxValAnn / 500) + 1; i++) {
        ticksArrAnn.push(testDataAnn);
        testDataAnn = testDataAnn + 500;
      }

      data.forEach(function(obj) {
        numberofProject.push({
          value: obj.NumberofProject,
          label: obj.Country,
        });
        maxValNPArr = maxValNPArr.concat(parseFloat(obj.NumberofProject));
      });
      maxValNP = Math.max(...maxValNPArr);
      for (var i = 0; i <= Math.round(maxValNP / 100) + 1; i++) {
        ticksArrNP.push(testDataNP);
        testDataNP = testDataNP + 100;
      }

      data.forEach(function(obj) {
        clientCount.push({
          value: obj.ClientCount,
          label: obj.Country,
        });
        maxValCCArr = maxValCCArr.concat(parseFloat(obj.ClientCount));
      });
      maxValCC = Math.max(...maxValCCArr);
      for (var i = 0; i <= Math.round(maxValCC / 20) + 1; i++) {
        ticksArrCC.push(testDataCC);
        testDataCC = testDataCC + 20;
      }

      let keyDiffMetrics = {
        annualized: annualized,
        numberofProject: numberofProject,
        clientCount: clientCount,
      };
      this.setState({
        keyDiffMetrics: keyDiffMetrics,
        byDeliveryLocCBVSettings: {
          ...this.state.byDeliveryLocCBVSettings,
          ticks: ticksArrAnn,
          maxVal: testDataAnn - 500,
        },
        byDeliveryLocNPSettings: {
          ...this.state.byDeliveryLocNPSettings,
          ticks: ticksArrNP,
          maxVal: testDataNP - 100,
        },
        byDeliveryLocNCSettings: {
          ...this.state.byDeliveryLocNCSettings,
          ticks: ticksArrCC,
          maxVal: testDataCC - 20,
        },
      });
    } else {
      this.setState({
        keyDiffMetrics: {
          annualized: [],
          numberofProject: [],
          clientCount: [],
        },
      });
    }
  };

  renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
    const x1 = cx + radius * Math.cos(-midAngle * RADIAN);
    const y1 = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x1} y={y1} className="customLabel" textAnchor={x1 > cx ? "start" : "end"} dominantBaseline="central">
        {"$" + value.toFixed(1)}
      </text>
    );
  };

  convertToParseFloat = (value) => {
    let tempValue = (value / 1).toFixed(1) == "0.0" ? value / 1 : (value / 1).toFixed(1);
    tempValue = parseFloat(tempValue);
    return tempValue;
  };

  fixDashboardDataPosition = (origin, list) => {
    let finList = [];
    switch (origin) {
      case "donut a":
        if (list.findIndex((x) => x.name == "cash optimization") != -1) {
          finList.push({
            value: list[list.findIndex((x) => x.name == "cash optimization")].value,
            name: list[list.findIndex((x) => x.name == "cash optimization")].name,
          });
        }
        if (list.findIndex((x) => x.name == "top line growth") != -1) {
          finList.push({
            value: list[list.findIndex((x) => x.name == "top line growth")].value,
            name: list[list.findIndex((x) => x.name == "top line growth")].name,
          });
        }
        if (list.findIndex((x) => x.name == "operating cost optimization") != -1) {
          finList.push({
            value: list[list.findIndex((x) => x.name == "operating cost optimization")].value,
            name: list[list.findIndex((x) => x.name == "operating cost optimization")].name,
          });
        }
        return finList;
      case "donut b":
        if (list.findIndex((x) => x.name == "north america") != -1) {
          finList.push({
            value: list[list.findIndex((x) => x.name == "north america")].value,
            name: list[list.findIndex((x) => x.name == "north america")].name,
          });
        }
        if (list.findIndex((x) => x.name == "europe") != -1) {
          finList.push({
            value: list[list.findIndex((x) => x.name == "europe")].value,
            name: list[list.findIndex((x) => x.name == "europe")].name,
          });
        }
        if (list.findIndex((x) => x.name == "growth markets") != -1) {
          finList.push({
            value: list[list.findIndex((x) => x.name == "growth markets")].value,
            name: list[list.findIndex((x) => x.name == "growth markets")].name,
          });
        }
        return finList;
    }
  };

  collapseChart = (chartName) => {
    // let tempByOffering = this.state == undefined ? this.collapsible.byOffering : this.state.collapsible.byOffering;
    // let tempByOutcome = this.state == undefined ? this.collapsible.byOutcome : this.state.collapsible.byOutcome;
    // let tempByClient = this.state == undefined ? this.collapsible.byClient : this.state.collapsible.byClient;
    // let tempByDeliveryLoc = this.state == undefined ? this.collapsible.byDeliveryLoc : this.state.collapsible.byDeliveryLoc;

    let tempByOffering = false;
    let tempByOutcome = false;
    let tempByClient = false;
    let tempByDeliveryLoc = false;

    switch (chartName) {
      case "byOffering":
        tempByOffering = !(this.state == undefined ? this.collapsible.byOffering : this.state.collapsible.byOffering);
        break;
      case "byOutcome":
        tempByOutcome = !(this.state == undefined ? this.collapsible.byOutcome : this.state.collapsible.byOutcome);
        break;
      case "byClient":
        tempByClient = !(this.state == undefined ? this.collapsible.byClient : this.state.collapsible.byClient);
        break;
      case "byDeliveryLoc":
        tempByDeliveryLoc = !(this.state == undefined
          ? this.collapsible.byDeliveryLoc
          : this.state.collapsible.byDeliveryLoc);
        break;
    }
    this.setState({
      collapsible: {
        ...this.state.collapsible,
        byOffering: tempByOffering,
        byOutcome: tempByOutcome,
        byClient: tempByClient,
        byDeliveryLoc: tempByDeliveryLoc,
      },
    });
  };

  render() {
    const { isVisibleDatepickerDropdownPopover, startDate } = this.state;
    const yearOptions = [
      { label: "2012", value: "2012" },
      { label: "2013", value: "2013" },
      { label: "2014", value: "2014" },
      { label: "2015", value: "2015" },
      { label: "2016", value: "2016" },
      { label: "2017", value: "2017" },
      { label: "2018", value: "2018" },
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
    ];
    const multiselectYearPicker = (
      <React.Fragment>
        {/* <MultiSelect
        options={yearOptions}
        //value={selected}
        //onChange={setSelected}
        labelledBy={"Select"}
        isOpen={true}
      /> */}
        <Multiselect
          options={yearOptions}
          isObject={true}
          displayValue="label"
          showCheckbox={true}
          closeOnSelect={false}
          selectAll={true}
          showSelectAll={true}
          //   selectedValues={this.state.selectedDays}
          //onSelect={this.onMultiClientSelect.bind(this)}
        />
      </React.Fragment>
    );
    return (
      <>
        <div className="valueRealization-container">
          {/* Donut Charts */}
          <div className="db-donuts-main-container">
            <Row className="db-graphs-container">
              <Col md={4} sm={4} className="db-sub-container cvd">
                <div className="db-header-container">
                  <label className="db-header-label">{i18n.t("Client_Value_Delivered_$Mn")}</label>
                </div>
                <div className="">
                  <div className="bo-dashboard-donut-first-ul">
                    <ResponsiveContainer height={400} width="100%">
                      {this.state.businessValueNav.length !== 0 ? (
                        <PieChart margin={{ top: 10, right: 0, left: 0, bottom: 90 }}>
                          <Pie
                            data={this.state.businessValueNav}
                            dataKey="value"
                            innerRadius={60}
                            outerRadius={90}
                            fill="#82ca9d"
                            label={this.renderCustomizedLabel}
                            isAnimationActive={false}
                            labelLine={false}
                            minAngle={7}
                          >
                            {this.state.businessValueNav.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={
                                  entry.name == "cash optimization"
                                    ? pieColor[0]
                                    : entry.name == "operating cost optimization"
                                    ? pieColor[2]
                                    : pieColor[1]
                                }
                              />
                            ))}
                            <Label
                              className="db-donut-label"
                              value={this.state.businessValueNavSum}
                              position="center"
                            />
                          </Pie>
                          <Tooltip
                            content={<CustomTooltip origin={"pie"} />}
                            offset={0}
                            allowEscapeViewBox={{ x: false, y: false }}
                          />
                          <Legend verticalAlign="bottom" iconSize={12} iconType="square" />
                        </PieChart>
                      ) : (
                        <div class="bo-dashboard-container-no-records">{i18n.t("No_Records_Found")}</div>
                      )}
                    </ResponsiveContainer>
                  </div>
                </div>
              </Col>
              <Col md={4} sm={4} className="db-sub-container cvd-markets">
                <div className="db-header-container">
                  <label className="db-header-label">{i18n.t("Client_Value_Delivered_by_Markets_$Mn")}</label>
                </div>
                <div className="">
                  <div className="bo-dashboard-donut-first-ul">
                    <ResponsiveContainer height={400} width="100%">
                      {this.state.businessOutcomesSDO.length !== 0 ? (
                        <PieChart margin={{ top: 10, right: 0, left: 0, bottom: 90 }}>
                          <Pie
                            data={this.state.businessOutcomesSDO}
                            dataKey="value"
                            innerRadius={60}
                            outerRadius={90}
                            fill="#82ca9d"
                            label={this.renderCustomizedLabel}
                            isAnimationActive={false}
                            labelLine={false}
                            minAngle={7}
                          >
                            {this.state.businessOutcomesSDO.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={
                                  entry.name == "europe"
                                    ? pieColor2[2]
                                    : entry.name == "north america"
                                    ? pieColor2[0]
                                    : pieColor2[1]
                                }
                              />
                            ))}
                            <Label
                              className="db-donut-label"
                              value={this.state.businessOutcomesSDOSum}
                              position="center"
                            />
                          </Pie>
                          <Tooltip
                            content={<CustomTooltip origin={"pie"} />}
                            offset={0}
                            allowEscapeViewBox={{ x: false, y: false }}
                          />
                          <Legend verticalAlign="bottom" iconSize={12} iconType="square" />
                        </PieChart>
                      ) : (
                        <div class="bo-dashboard-container-no-records">{i18n.t("No_Records_Found")}</div>
                      )}
                    </ResponsiveContainer>
                  </div>
                </div>
              </Col>
              <Col md={4} sm={4} className="db-sub-container vic">
                <div className="db-header-container">
                  <label className="db-header-label">{i18n.t("Value_Impacting_Client_P&L_$Mn")}</label>
                </div>
                <div className="">
                  <div className="bo-dashboard-donut-first-ul">
                    <ResponsiveContainer height={400} width="100%">
                      {this.state.businessOutcomePnL.length !== 0 ? (
                        <PieChart margin={{ top: 10, right: 0, left: 0, bottom: 90 }}>
                          <Pie
                            data={this.state.businessOutcomePnL}
                            dataKey="value"
                            innerRadius={60}
                            outerRadius={90}
                            fill="#8884d8"
                            label={this.renderCustomizedLabel}
                            isAnimationActive={false}
                            labelLine={false}
                            minAngle={7}
                          >
                            {this.state.businessOutcomePnL.map((entry, index) => (
                              <Cell
                                // onMouseEnter={(e) => { this.mouseCoordinates(e) }}
                                key={`cell-${index}`}
                                fill={
                                  entry.name == "cash optimization"
                                    ? pieColor[0]
                                    : entry.name == "operating cost optimization"
                                    ? pieColor[2]
                                    : pieColor[1]
                                }
                              />
                            ))}
                            <Label
                              className="db-donut-label"
                              value={this.state.businessOutcomePnLSum}
                              position="center"
                            />
                          </Pie>
                          <Tooltip
                            content={<CustomTooltip origin={"pie"} />}
                            offset={0}
                            allowEscapeViewBox={{ x: false, y: false }}
                          />
                          <Legend verticalAlign="bottom" iconSize={12} iconType="square" />
                        </PieChart>
                      ) : (
                        <div class="bo-dashboard-container-no-records">{i18n.t("No_Records_Found")}</div>
                      )}
                    </ResponsiveContainer>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          {/* YOY Stack Charts */}
          <div className="db-donuts-main-container">
            <Row className="db-graphs-container">
              <Col md={6} sm={6} className="db-yoy-sub-container yoy-cvd">
                <div className="db-header-container">
                  <label className="db-header-label">{i18n.t("YOY_Client_Value_Delivered_$Mn")}</label>
                </div>
                <div className="">
                  <div className="bo-dashboard-bar-third yoy-bar">
                    {this.state.yoyCVDconvertedData.length !== 0 ? (
                      <ResponsiveContainer height={260} width="100%">
                        <BarChart
                          layout="vertical"
                          data={this.state.yoyCVDconvertedData}
                          margin={{ top: 25, right: 50, left: -160, bottom: 0 }}
                          barSize={15}
                          barGap={0}
                          barCategoryGap={"1%"}
                        >
                          <XAxis type="number" tickLine={false} axisLine={false} allowDecimals={false} />
                          <YAxis
                            type="category"
                            dataKey="FiscalYear"
                            width={200}
                            interval={0}
                            tickLine={true}
                            axisLine={true}
                          />
                          <CartesianGrid strokeDasharray="0" horizontal={false} />
                          <Legend verticalAlign="bottom" iconSize={12} iconType="square" />
                          <Tooltip
                            content={<CustomTooltip origin={"bar 3"} />}
                            offset={0}
                            allowEscapeViewBox={{ x: false, y: false }}
                          />

                          {this.state.yoyCVDlabels.map((entry, index) => (
                            <Bar key={index} dataKey={entry} stackId="a" fill={pieColor2[index]} stroke="#fff">
                              {index === this.state.yoyCVDlabels.length - 1 ? (
                                <LabelList dataKey="Total" position="right" />
                              ) : null}
                            </Bar>
                          ))}
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <div class="bo-dashboard-container-no-records">{i18n.t("No_Records_Found")}</div>
                    )}
                  </div>
                </div>
              </Col>

              <Col md={6} sm={6} className="db-yoy-sub-container yoy-vic">
                <div className="db-header-container">
                  <label className="db-header-label">{i18n.t("YOY_Value_Impacting_Client_P&L_$Mn")}</label>
                </div>
                <div className="">
                  <div className="bo-dashboard-bar-third yoy-bar">
                    {this.state.yoyVICconvertedData.length !== 0 ? (
                      <ResponsiveContainer height={280} width="100%">
                        <BarChart
                          layout="vertical"
                          data={this.state.yoyVICconvertedData}
                          margin={{ top: 25, right: 50, left: -15, bottom: 15 }}
                          barSize={15}
                          barGap={0}
                          barCategoryGap={"1%"}
                        >
                          <XAxis type="number" tickLine={false} axisLine={false} allowDecimals={false} />
                          <YAxis type="category" dataKey="FiscalYear" interval={0} tickLine={true} axisLine={true} />
                          <CartesianGrid strokeDasharray="0" horizontal={false} />
                          <Legend verticalAlign="bottom" iconSize={12} iconType="square" />
                          <Tooltip
                            content={<CustomTooltip origin={"bar 3"} />}
                            offset={0}
                            allowEscapeViewBox={{ x: false, y: false }}
                          />
                          {this.state.yoyVIClabels.map((entry, index) => (
                            <Bar key={index} dataKey={entry} stackId="a" fill={pieColor2[index]} stroke="#fff">
                              {index === this.state.yoyVIClabels.length - 1 ? (
                                <LabelList dataKey="Total" position="right" />
                              ) : null}
                            </Bar>
                          ))}
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <div class="bo-dashboard-container-no-records">{i18n.t("No_Records_Found")}</div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          {/* Collapsible Charts  */}
          <div className="valuerealization-dashboard">
            <div className="valuerealization-accordions">
              <div id="accordion" className="valuerealization-accordion">
                {/* <div class="card">
                  <div class="card-header">
                    <div class="card-link collapsed" data-toggle="collapse" href="#collapse1">
                      <div className="card-link-icon">
                        <span>
                          <i class="fas fa-chevron-down"></i>
                        </span>
                      </div>
                      <div className="header-content">
                        <div className="header-link">
                          <h6>By offering $Mn</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="collapse1" class="collapse" data-parent="#accordion">
                    <div class="card-body">
                      <div>
                        <Col md={12} sm={12}>
                          <div className="">
                            <div className="bo-dashboard-bar-second">
                              {this.state.offeringPnL.length !== 0 && this.state.offeringPnL[0].value != "" ? (
                                <ResponsiveContainer height={300} width="100%">
                                  <BarChart
                                    layout="vertical"
                                    data={this.state.offeringPnL}
                                    margin={{
                                      top: 15,
                                      right: 50,
                                      left: 0,
                                      bottom: 10,
                                    }}
                                    barSize={15}
                                  >
                                    <XAxis
                                      type="number"
                                      tickLine={false}
                                      axisLine={false}
                                      domain={[0, this.state.byOfferingSettings.maxVal]}
                                      allowDecimals={false}
                                      ticks={this.state.byOfferingSettings.ticks}
                                    />
                                    <YAxis
                                      type="category"
                                      dataKey="name"
                                      width={200}
                                      interval={0}
                                      tickLine={true}
                                      axisLine={true}
                                    />
                                    <CartesianGrid strokeDasharray="0" horizontal={false} />
                                    <Tooltip
                                      content={<CustomTooltip origin={"bar 2"} />}
                                      offset={0}
                                      allowEscapeViewBox={{ x: false, y: false }}
                                    />
                                    <Bar name="value" dataKey="value" stackId="a" fill="#9c6ade">
                                      <LabelList dataKey="offeringTotal" position="right" />
                                    </Bar>
                                  </BarChart>
                                </ResponsiveContainer>
                              ) : (
                                  <div class="bo-dashboard-container-no-records">No Records Found</div>
                                )}
                            </div>
                          </div>
                        </Col>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div class="card">
                  <div class="card-header">
                    <div class="card-link" data-toggle="collapse" href="#collapse2">
                      <div className="card-link-icon">
                        <span>
                          <i class="fas fa-chevron-down"></i>
                        </span>
                      </div>
                      <div className="header-content">
                        <div className="header-link">
                          <h6>{i18n.t("By_Outcome_Levers_$Mn")}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="collapse2" class="collapse" data-parent="#accordion">
                    <div class="card-body">
                      <div>
                        <Col md={12} sm={12}>
                          <div className="">
                            <div className="bo-dashboard-bar-second" id="boi">
                              {this.state.businessOutcomesInd.length !== 0 &&
                              this.state.businessOutcomesInd[0].value != "" ? (
                                <ResponsiveContainer
                                  height={this.state.boiBarHeight >= 10 ? 33 * this.state.boiBarHeight : 330}
                                  // height={300}
                                  width="100%"
                                >
                                  <BarChart
                                    layout="vertical"
                                    data={this.state.businessOutcomesInd}
                                    margin={{
                                      top: 15,
                                      right: 50,
                                      left: -10,
                                      // left: 0,
                                      bottom: 10,
                                    }}
                                    barSize={15}
                                    barCategoryGap={50}
                                  >
                                    <XAxis
                                      type="number"
                                      tickLine={false}
                                      axisLine={false}
                                      domain={[0, this.state.byOutcomeSettings.maxVal]}
                                      allowDecimals={false}
                                      ticks={this.state.byOutcomeSettings.ticks}
                                    />
                                    <YAxis
                                      type="category"
                                      dataKey="name"
                                      width={270}
                                      // width={200}
                                      interval={0}
                                      tickLine={true}
                                      axisLine={true}
                                    />
                                    <CartesianGrid strokeDasharray="0" horizontal={false} />
                                    <Tooltip
                                      content={<CustomTooltip origin={"bar 2"} />}
                                      offset={0}
                                      allowEscapeViewBox={{ x: false, y: false }}
                                    />
                                    <Bar name="value" dataKey="value" stackId="a" fill="#9c6ade">
                                      <LabelList dataKey="boiTotal" position="right" />
                                    </Bar>
                                  </BarChart>
                                </ResponsiveContainer>
                              ) : (
                                <div class="bo-dashboard-container-no-records">{i18n.t("No_Records_Found")}</div>
                              )}
                            </div>
                          </div>
                        </Col>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div class="card">
                  <div class="card-header">
                    <div class="card-link collapsed" data-toggle="collapse" href="#collapse3">
                      <div className="card-link-icon">
                        <span>
                          <i class="fas fa-chevron-down"></i>
                        </span>
                      </div>
                      <div className="header-content">
                        <div className="header-link">
                          <h6>By Client $Mn</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="collapse3" class="collapse" data-parent="#accordion">
                    <div class="card-body">
                      <div>
                        <Col md={12} sm={12}>
                          <div className="">
                            <div className="bo-dashboard-bar-second">
                              {this.state.clientPnL.length !== 0 && this.state.clientPnL[0].value != "" ? (
                                <ResponsiveContainer height={330} width="100%">
                                  <BarChart
                                    layout="vertical"
                                    data={this.state.clientPnL}
                                    margin={{
                                      top: 15,
                                      right: 50,
                                      left: 10,
                                      bottom: 10,
                                    }}
                                    barSize={15}
                                  >
                                    <XAxis
                                      type="number"
                                      tickLine={false}
                                      axisLine={false}
                                      domain={[0, this.state.byClientSettings.maxVal]}
                                      allowDecimals={false}
                                      ticks={this.state.byClientSettings.ticks}
                                    />
                                    <YAxis
                                      type="category"
                                      dataKey="name"
                                      width={180}
                                      interval={0}
                                      tickLine={true}
                                      axisLine={true}
                                    />
                                    <CartesianGrid strokeDasharray="0" horizontal={false} />
                                    <Tooltip
                                      content={<CustomTooltip origin={"bar 2"} />}
                                      offset={0}
                                      allowEscapeViewBox={{ x: false, y: false }}
                                    />
                                    <Bar name="value" dataKey="value" stackId="a" fill="#9c6ade">
                                      <LabelList dataKey="clientTotal" position="right" />
                                    </Bar>
                                  </BarChart>
                                </ResponsiveContainer>
                              ) : (
                                  <div class="bo-dashboard-container-no-records">No Records Found</div>
                                )}
                            </div>
                          </div>
                        </Col>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div class="card">
                  <div class="card-header">
                    <div class="card-link collapsed" data-toggle="collapse" href="#collapse4">
                      <div className="card-link-icon">
                        <span>
                          <i class="fas fa-chevron-down"></i>
                        </span>
                      </div>
                      <div className="header-content">
                        <div className="header-link">
                          <h6>{i18n.t("By_Delivery_Location_$Mn")}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="collapse4" class="collapse" data-parent="#accordion">
                    <div class="card-body">
                      <div>
                        <Col md={12} sm={12}>
                          <div className="">
                            <div className="bo-dashboard-bar-first">
                              {this.state.keyDiffMetrics.annualized.length !== 0 &&
                              this.state.keyDiffMetrics.annualized[0].value != "" ? (
                                <ResponsiveContainer className="coll-responsive-container" height={330} width="100%">
                                  <BarChart
                                    layout="vertical"
                                    data={this.state.keyDiffMetrics.annualized}
                                    margin={{
                                      top: 15,
                                      right: 50,
                                      left: 10,
                                      bottom: 10,
                                    }}
                                    barSize={15}
                                  >
                                    <XAxis
                                      type="number"
                                      tickLine={false}
                                      axisLine={false}
                                      domain={[0, this.state.byDeliveryLocCBVSettings.maxVal]}
                                      allowDecimals={false}
                                      ticks={this.state.byDeliveryLocCBVSettings.ticks}
                                    />
                                    <YAxis
                                      type="category"
                                      dataKey="label"
                                      width={120}
                                      interval={0}
                                      tickLine={true}
                                      axisLine={true}
                                    />
                                    <CartesianGrid strokeDasharray="0" horizontal={false} />
                                    <Tooltip
                                      content={<CustomTooltip origin={"bar 1"} />}
                                      offset={0}
                                      allowEscapeViewBox={{ x: false, y: false }}
                                    />
                                    <Bar name="" dataKey="value" stackId="a" fill="#9c6ade">
                                      <LabelList dataKey="total" position="right" />
                                    </Bar>
                                  </BarChart>
                                </ResponsiveContainer>
                              ) : (
                                <div class="bo-dashboard-container-no-records">{i18n.t("No_Records_Found")}</div>
                              )}
                              <label>{i18n.t("Client_Business_Value_Impact_$ Mn")}</label>
                            </div>
                            <div className="bo-dashboard-bar-first">
                              {this.state.keyDiffMetrics.numberofProject.length !== 0 &&
                              this.state.keyDiffMetrics.numberofProject[0].value != "" ? (
                                <ResponsiveContainer className="coll-responsive-container" height={330} width="100%">
                                  <BarChart
                                    layout="vertical"
                                    data={this.state.keyDiffMetrics.numberofProject}
                                    margin={{
                                      top: 15,
                                      right: 50,
                                      left: 10,
                                      bottom: 10,
                                    }}
                                    barSize={15}
                                  >
                                    <XAxis
                                      type="number"
                                      tickLine={false}
                                      axisLine={false}
                                      domain={[0, this.state.byDeliveryLocNPSettings.maxVal]}
                                      allowDecimals={false}
                                      ticks={this.state.byDeliveryLocNPSettings.ticks}
                                    />
                                    <YAxis
                                      type="category"
                                      dataKey="label"
                                      width={120}
                                      interval={0}
                                      tickLine={true}
                                      axisLine={true}
                                    />
                                    <CartesianGrid strokeDasharray="0" horizontal={false} />
                                    <Tooltip
                                      content={<CustomTooltip origin={"bar 1"} />}
                                      offset={0}
                                      allowEscapeViewBox={{ x: false, y: false }}
                                    />
                                    <Bar
                                      name=""
                                      dataKey="value"
                                      stackId="a"
                                      fill="#9c6ade"
                                      label={{ position: "right" }}
                                    />
                                  </BarChart>
                                </ResponsiveContainer>
                              ) : (
                                <div class="bo-dashboard-container-no-records">{i18n.t("No_Records_Found")}</div>
                              )}
                              <label>{i18n.t("Number_of_Projects")}</label>
                            </div>
                            {/* <div className="bo-dashboard-bar-first">
                              {this.state.keyDiffMetrics.clientCount.length !== 0 &&
                                this.state.keyDiffMetrics.clientCount[0].value != "" ? (
                                  <ResponsiveContainer height={330} width="100%">
                                    <BarChart
                                      layout="vertical"
                                      data={this.state.keyDiffMetrics.clientCount}
                                      margin={{
                                        top: 15,
                                        right: 50,
                                        left: 10,
                                        bottom: 10,
                                      }}
                                      barSize={15}
                                    >
                                      <XAxis
                                        type="number"
                                        tickLine={false}
                                        axisLine={false}
                                        domain={[0, this.state.byDeliveryLocNCSettings.maxVal]}
                                        allowDecimals={false}
                                        ticks={this.state.byDeliveryLocNCSettings.ticks}
                                      />
                                      <YAxis
                                        type="category"
                                        dataKey="label"
                                        width={120}
                                        interval={0}
                                        tickLine={true}
                                        axisLine={true}
                                      />
                                      <CartesianGrid strokeDasharray="0" horizontal={false} />
                                      <Tooltip
                                        content={<CustomTooltip origin={"bar 1"} />}
                                        offset={0}
                                        allowEscapeViewBox={{ x: false, y: false }}
                                      />
                                      <Bar
                                        name=""
                                        dataKey="value"
                                        stackId="a"
                                        fill="#9c6ade"
                                        label={{ position: "right" }}
                                      />
                                    </BarChart>
                                  </ResponsiveContainer>
                                ) : (
                                  <div class="bo-dashboard-container-no-records">No Records Found</div>
                                )}
                              <label>{"Number of Clients"}</label>
                            </div> */}
                          </div>
                        </Col>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div class="vr-summary-main-container">
            <div class="vr-container row">
              <div class="vr-summary-header-container col-md-12 col-xs-12">
                <label>Executive Summary</label>
              </div>
              <div class="vr-summary-value-container col-md-12 col-xs-12">
                Client Stories Key Highlights:
                <ul>
                  <li>FY20 Client Value Delivered - $10.96 Bn</li>
                  <li>FY20 Value Impacting Client P&amp;L - $4317 Mn</li>
                  <li>930 projects driven by Business Advisors worth $4304 Mn</li>
                  <li>
                    Sourcing and Procurement, Finance and Accounting and Supply Chain and IX.0 are the top 3 key
                    contributors reporting 70% of total Value worth $3083 Mn
                  </li>
                </ul>
                <br />
                <div>
                  Action required:
                  <br />* Client Stories/ Savings needs to be reported on CVT by 31st Oct 2020 for FY21 Q1 reporting.
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </>
    );
  }
}
export default ValueRealizationGraphs;
