import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import {
  Tabs, Tab, TabPanel, TabList,
} from 'react-web-tabs';
import DatePicker from 'react-datepicker';
import { regexForNegativeNumbers } from '../Shared/Constant';

const PastAndFutureTrendsConfigration = ({ boiTrendsDates, ...props }) => {
  const { pastTrend: pastTrendFromProps, futureTrend: futureTrendFromProps } = props;
  const [pastTrend, setPastTrends] = useState([]);
  const [futureTrend, setFutureTrend] = useState([]);
  const [boiTrendsApiError, setBoiTrendsApiError] = useState(false);

  useEffect(() => {
    setPastTrends(() => props.pastTrend);
    setFutureTrend(() => props.futureTrend);
  }, [futureTrendFromProps, pastTrendFromProps]);
  const boiTrendsPastDateChange = (date, indexVal) => {
    const dates = [];
    const datesTwo = [];
    let updatedTrends = [...pastTrend];
    updatedTrends = updatedTrends.map((eachpast, index) => {
      const cloneEachPast = eachpast;
      cloneEachPast.error = '';
      if (eachpast.ValueDate && index > indexVal) {
        datesTwo.push(eachpast.ValueDate);
      }
      return cloneEachPast;
    });
    updatedTrends = updatedTrends.map((eachpast, index) => {
      const cloneEachPast = eachpast;
      if (index < indexVal) {
        dates.push(eachpast.ValueDate);
      } else if (index === indexVal) {
        if (dates.length) {
          dates.forEach((eachDate) => {
            if (new Date(eachDate) <= new Date(date)) {
              cloneEachPast.error = i18n.t('Boi_trend_past_date_error_message_2');
            }
          });
        }
        if (new Date(date) >= new Date(boiTrendsDates.startDate)) {
          cloneEachPast.error = `${i18n.t('Boi_trend_past_date_error_message')} ${boiTrendsDates.startDateValue} ${i18n.t('Month')}`;
        }
        cloneEachPast.ValueDate = date;
        cloneEachPast.showPicker = false;
      }
      return cloneEachPast;
    });
    updatedTrends = updatedTrends.map((eachpast, index) => {
      const cloneEachPast = eachpast;
      if (index === indexVal) {
        if (datesTwo.length) {
          datesTwo.forEach((eachDate) => {
            if (new Date(eachDate) >= new Date(date)) {
              cloneEachPast.error = i18n.t('Boi_trend_past_date_error_message_3');
            }
          });
        }
        if (new Date(date) >= new Date(boiTrendsDates.startDate)) {
          cloneEachPast.error = `${i18n.t('Boi_trend_past_date_error_message')} ${boiTrendsDates.startDateValue} ${i18n.t('Month')}`;
        }
        cloneEachPast.ValueDate = date;
        cloneEachPast.showPicker = false;
      }
      return cloneEachPast;
    });
    setPastTrends(() => updatedTrends);
    setBoiTrendsApiError('');
  };
  const openDatePicker = (index, Trendsfor, pickerValue) => {
    const clonedPastTrend = [...pastTrend];
    const clonedFutureTrend = [...futureTrend];
    if (Trendsfor === 'future') {
      clonedFutureTrend.map((each, eachIndex) => {
        const clonedFuture = each;
        if (eachIndex === index) {
          clonedFuture.showPicker = pickerValue;
        }
        return clonedFuture;
      });
    } else {
      clonedPastTrend.map((each, eachIndex) => {
        const clonedPast = each;
        if (eachIndex === index) {
          clonedPast.showPicker = pickerValue;
        }
        return clonedPast;
      });
    }

    setPastTrends(() => clonedPastTrend);
    setFutureTrend(() => clonedFutureTrend);
  };
  const boiTrendsPastValueChange = (event, indexVal) => {
    let clonedPastTrend = [...pastTrend];
    clonedPastTrend = clonedPastTrend.map((eachpast) => {
      const clonedPast = eachpast;
      clonedPast.error = '';
      clonedPast.valueError = '';
      return clonedPast;
    });

    clonedPastTrend = clonedPastTrend.map((eachpast, index) => {
      const clonedPast = eachpast;
      if (index === indexVal) {
        if (event.target.value === '') {
          clonedPast.MetricValue = event.target.value;
          clonedPast.valueError = '';
        } else if (regexForNegativeNumbers.test(event.target.value)) {
          if (event.target.value === '-.') {
            clonedPast.MetricValue = '-0.';
            clonedPast.valueError = '';
          } else if ((event.target.value >= -99999999 && event.target.value <= 99999999)) {
            clonedPast.MetricValue = event.target.value;
            clonedPast.valueError = '';
          } else {
            clonedPast.MetricValue = event.target.value;
            clonedPast.valueError = i18n.t('addBoErrorM6');
          }
        } else {
          clonedPast.MetricValue = event.target.value;
          clonedPast.valueError = i18n.t('addBoErrorM6');
        }
        clonedPast.MetricValue = event.target.value;
      }
      return clonedPast;
    });
    setPastTrends(() => clonedPastTrend);
    setBoiTrendsApiError('');
  };

  const boiTrendsFutureDateChange = (date, indexVal) => {
    let clonedFutureTrend = [...futureTrend];
    const dates = [];
    const datesTwo = [];
    clonedFutureTrend = clonedFutureTrend.map((eachFuture, index) => {
      const clonedEachFutu = eachFuture;
      clonedEachFutu.error = '';
      if (eachFuture.ValueDate && index > indexVal) {
        datesTwo.push(eachFuture.ValueDate);
      }
      return clonedEachFutu;
    });
    clonedFutureTrend = clonedFutureTrend.map((eachFuture, index) => {
      const clonedEachFutu = eachFuture;
      if (index < indexVal) {
        dates.push(eachFuture.ValueDate);
      } else if (index === indexVal) {
        if (dates.length) {
          dates.forEach((eachDate) => {
            if (new Date(eachDate) >= new Date(date)) {
              clonedEachFutu.error = i18n.t('Boi_trend_future_date_error_message_2');
            }
          });
        }
        if (new Date(date) <= new Date(boiTrendsDates.endDate)) {
          clonedEachFutu.error = `${i18n.t('Boi_trend_future_date_error_message')} ${boiTrendsDates.endDateValue} ${i18n.t('Month')}`;
        }
        clonedEachFutu.ValueDate = date;
        clonedEachFutu.showPicker = false;
      }
      return clonedEachFutu;
    });
    clonedFutureTrend = clonedFutureTrend.map((eachFuture, index) => {
      const clonedEachFutu = eachFuture;
      if (index === indexVal) {
        if (datesTwo.length) {
          datesTwo.forEach((eachDate) => {
            if (new Date(eachDate) <= new Date(date)) {
              clonedEachFutu.error = i18n.t(
                'Boi_trend_future_date_error_message_3',
              );
            }
          });
        }
        if (new Date(date) <= new Date(boiTrendsDates.endDate)) {
          clonedEachFutu.error = `${i18n.t('Boi_trend_future_date_error_message')} ${boiTrendsDates.endDateValue} ${i18n.t('Month')}`;
        }

        clonedEachFutu.ValueDate = date;
        clonedEachFutu.showPicker = false;
      }
      return clonedEachFutu;
    });
    setFutureTrend(() => clonedFutureTrend);
    setBoiTrendsApiError('');
  };
  const boiTrendsFutureValueChange = (event, indexVal) => {
    let clonedFutureTrend = [...futureTrend];
    clonedFutureTrend = clonedFutureTrend.map((eachFuture) => {
      const clonedEachFutu = eachFuture;
      clonedEachFutu.error = '';
      clonedEachFutu.valueError = '';
      return clonedEachFutu;
    });
    clonedFutureTrend = clonedFutureTrend.map((eachFuture, index) => {
      const clonedEachFutu = eachFuture;
      if (index === indexVal) {
        if (event.target.value === '') {
          clonedEachFutu.MetricValue = event.target.value;
          clonedEachFutu.valueError = '';
        } else if (regexForNegativeNumbers.test(event.target.value)) {
          if (event.target.value === '-.') {
            clonedEachFutu.MetricValue = '-0.';
            clonedEachFutu.valueError = '';
          } else if ((event.target.value >= -99999999 && event.target.value <= 99999999)) {
            clonedEachFutu.MetricValue = event.target.value;
            clonedEachFutu.valueError = '';
          } else {
            clonedEachFutu.MetricValue = event.target.value;
            clonedEachFutu.valueError = '';
            clonedEachFutu.valueError = i18n.t('addBoErrorM6');
          }
        } else {
          clonedEachFutu.MetricValue = event.target.value;
          clonedEachFutu.valueError = i18n.t('addBoErrorM6');
        }
        clonedEachFutu.MetricValue = event.target.value;
      }
      return clonedEachFutu;
    });
    setFutureTrend(() => clonedFutureTrend);
    setBoiTrendsApiError('');
  };
  const validateBoITrends = () => {
    let formValid = true;
    let hasFuturevalues = false;
    let hasPastvalues = false;
    let clonedFutureTrend = [...futureTrend];
    let clonedPastTrend = [...pastTrend];
    clonedFutureTrend = clonedFutureTrend.map((eachFuture, index) => {
      const clonedEachFutu = eachFuture;
      if (eachFuture.ValueDate && eachFuture.MetricValue) {
        hasFuturevalues = true;
      }
      const MonthsOne = [];
      const MonthsTwo = [];
      clonedFutureTrend.forEach((future, indexTwo) => {
        if (indexTwo < index) {
          if (future.month) {
            MonthsOne.push(future.month);
          }
        }
        if (indexTwo > index) {
          if (future.month) {
            MonthsTwo.push(future.month);
          }
        }
      });
      if (MonthsOne.length && eachFuture.ValueDate) {
        MonthsOne.forEach((eachMonthsOne) => {
          if (new Date(eachMonthsOne) >= new Date(eachFuture.ValueDate)) {
            clonedEachFutu.error = i18n.t('Boi_trend_future_date_error_message_2');
            formValid = false;
          }
        });
      }
      if (MonthsTwo.length && eachFuture.ValueDate) {
        MonthsTwo.forEach((eachMonthsTwo) => {
          if (new Date(eachMonthsTwo) <= new Date(eachFuture.ValueDate)) {
            clonedEachFutu.error = i18n.t('Boi_trend_future_date_error_message_3');
            formValid = false;
          }
        });
      }

      if (eachFuture.ValueDate) {
        if (!eachFuture.MetricValue) {
          clonedEachFutu.valueError = i18n.t('boi_trend_required_graph_value');
          formValid = false;
        }
        if (
          new Date(eachFuture.ValueDate) <= new Date(boiTrendsDates.endDate)
        ) {
          clonedEachFutu.error = `${i18n.t('Boi_trend_future_date_error_message')} ${boiTrendsDates.endDateValue} ${i18n.t('Month')}`;
          formValid = false;
        }
      }
      if (eachFuture.MetricValue) {
        if (!eachFuture.ValueDate) {
          clonedEachFutu.error = i18n.t('boi_trend_required_graph_month');
          formValid = false;
        }
        if (!regexForNegativeNumbers.test(eachFuture.MetricValue)) {
          clonedEachFutu.valueError = i18n.t('addBoErrorM6');
          formValid = false;
        } else if (!(eachFuture.MetricValue >= -99999999 && eachFuture.MetricValue <= 99999999)) {
          clonedEachFutu.valueError = i18n.t('addBoErrorM6');
          formValid = false;
        }
      }
      return clonedEachFutu;
    });
    clonedPastTrend = clonedPastTrend.map((eachPast, index) => {
      const clonedPast = eachPast;
      if (eachPast.ValueDate && eachPast.MetricValue) {
        hasPastvalues = true;
      }
      const MonthsOne = [];
      const MonthsTwo = [];
      clonedPastTrend.forEach((past, indexTwo) => {
        if (indexTwo < index) {
          if (past.month) {
            MonthsOne.push(past.month);
          }
        }
        if (indexTwo > index) {
          if (past.month) {
            MonthsTwo.push(past.month);
          }
        }
      });
      if (MonthsOne.length && eachPast.ValueDate) {
        MonthsOne.forEach((eachMonthsOne) => {
          if (new Date(eachMonthsOne) <= new Date(eachPast.ValueDate)) {
            clonedPast.error = i18n.t('Boi_trend_past_date_error_message_2');
            formValid = false;
          }
        });
      }
      if (MonthsTwo.length && eachPast.ValueDate) {
        MonthsTwo.forEach((eachMonthsTwo) => {
          if (new Date(eachMonthsTwo) >= new Date(eachPast.ValueDate)) {
            clonedPast.error = i18n.t('Boi_trend_past_date_error_message_3');
            formValid = false;
          }
        });
      }

      if (eachPast.ValueDate) {
        if (!eachPast.MetricValue) {
          clonedPast.valueError = i18n.t('boi_trend_required_graph_value');
          formValid = false;
        }
        if (
          new Date(eachPast.ValueDate) >= new Date(boiTrendsDates.startDate)
        ) {
          clonedPast.error = `${i18n.t('Boi_trend_past_date_error_message')} ${boiTrendsDates.startDateValue} ${i18n.t('Month')}`;
          formValid = false;
        }
      }
      if (eachPast.MetricValue) {
        if (!eachPast.ValueDate) {
          clonedPast.error = i18n.t('boi_trend_required_graph_month');
          formValid = false;
        }
        if (!regexForNegativeNumbers.test(eachPast.MetricValue)) {
          clonedPast.valueError = i18n.t('addBoErrorM6');
          formValid = false;
        } else if (!(eachPast.MetricValue >= -99999999 && eachPast.MetricValue <= 99999999)) {
          clonedPast.valueError = i18n.t('addBoErrorM6');
          formValid = false;
        }
      }
      return clonedPast;
    });
    if (!hasPastvalues && !hasFuturevalues) {
      formValid = false;
    }
    setPastTrends(() => clonedPastTrend);
    setFutureTrend(() => clonedFutureTrend);
    // setBoiTrendsApiError(() => ((!hasPastvalues && !hasFuturevalues)
    //   ? i18n.t('boi_trends_required_error')
    //   : (!formValid ? i18n.t('boi_trends_submit_error') : '')));
    if (!hasPastvalues && !hasFuturevalues) {
      setBoiTrendsApiError(() => (i18n.t('boi_trends_required_error')));
    } else if (!formValid) {
      setBoiTrendsApiError(() => (i18n.t('boi_trends_submit_error')));
    } else {
      setBoiTrendsApiError(() => (''));
    }
    return formValid;
  };
  const submitBoiTrends = () => {
    const { postBoiTrends } = props;
    if (validateBoITrends()) {
      postBoiTrends(pastTrend, futureTrend);
    }
  };
  const clearDateandValue = (index, value) => {
    let clonedPastTrend = [...pastTrend];
    let clonedFutureTrend = [...futureTrend];
    if (value === 'past') {
      clonedPastTrend = clonedPastTrend.map((eachPast, pastIndex) => {
        const clonedEachPast = eachPast;
        if (index === pastIndex) {
          clonedEachPast.error = '';
          clonedEachPast.valueError = '';
          clonedEachPast.ValueDate = '';
          clonedEachPast.MetricValue = '';
        }

        return clonedEachPast;
      });
      setPastTrends(clonedPastTrend);
    } else {
      clonedFutureTrend = clonedFutureTrend.map((eachFuture, pastFuture) => {
        const clonedEachFuture = eachFuture;
        if (index === pastFuture) {
          clonedEachFuture.error = '';
          clonedEachFuture.valueError = '';
          clonedEachFuture.ValueDate = '';
          clonedEachFuture.MetricValue = '';
        }
        return clonedEachFuture;
      });
      setFutureTrend(clonedFutureTrend);
    }
  };
  const { onShowEditDataClick } = props;
  return (
    <>
      <div className="commentrychat-division">
        <div className="editBOITrend">
          <Tabs defaultActiveKey="PastTrend">
            <TabList>
              <Tab tabFor="PastTrend">{i18n.t('Past_Trend')}</Tab>
              <Tab tabFor="FutureTrend">{i18n.t('Future_Trend')}</Tab>
            </TabList>
            <TabPanel tabId="PastTrend">
              {pastTrend.map((eachPast, index) => (
                <div className="editBOITrendform common-formgrouplabel">
                  <div className="form-group border-group">
                    <label
                      htmlFor="StartDate"
                      className="form-label"
                    >
                      {i18n.t('Period')}
                    </label>
                    <div className="initiative-datepicker form-control">
                      <div className="datepicker-label-wrapper">
                        <div className="close-icon-wrapper">
                          <span className="close-icon" onClick={() => clearDateandValue(index, 'past')} role="button" tabIndex="0" aria-label="Clear Date" />
                          <DatePicker
                            className="form-control"
                            dateFormat="MMM yyyy"
                            placeholderText="(MMM/YYYY)"
                            showMonthYearPicker
                            value={eachPast.ValueDate}
                            selected={eachPast.ValueDate}
                            disabled
                            onSelect={(event) => boiTrendsPastDateChange(
                              event,
                              index,
                            )}
                            onClickOutside={() => openDatePicker(
                              index,
                              'past',
                              false,
                            )}
                            open={eachPast.showPicker}
                          />
                        </div>
                        <span
                          onClick={() => openDatePicker(
                            index,
                            'past',
                            true,
                          )}
                          className="calender-placment"
                          role="button"
                          tabIndex="0"
                        >
                          <i className="fal fa-calendar-alt" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group border-group">
                    <label
                      htmlFor="fieldlabel1"
                      className="form-label"
                    >
                      {i18n.t('Metric_Value')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Value"
                      value={eachPast.MetricValue}
                      onChange={(event) => boiTrendsPastValueChange(
                        event,
                        index,
                      )}
                    />
                  </div>
                  <div className="errorMsg">{eachPast.error}</div>
                  <div className="errorMsg">
                    {eachPast.valueError}
                  </div>
                </div>
              ))}
               <div className="errorMsg">
                {boiTrendsApiError}
              </div>
            </TabPanel>
            <TabPanel tabId="FutureTrend">
              {futureTrend.map((eachFuture, index) => (
                <div className="editBOITrendform common-formgrouplabel">
                  <div className="form-group border-group">
                    <label
                      htmlFor="StartDate"
                      className="form-label"
                    >
                      {i18n.t('Period')}
                    </label>
                    <div className="initiative-datepicker form-control">
                      <div className="datepicker-label-wrapper">
                        <div className="close-icon-wrapper">
                          <span className="close-icon" onClick={() => clearDateandValue(index, 'future')} role="button" tabIndex="0" aria-label="close" />
                          <DatePicker
                            className="form-control IOJDatepicker"
                            dateFormat="MMM yyyy"
                            showMonthYearPicker
                            value={eachFuture.ValueDate}
                            placeholderText="(MMM/YYYY)"
                            disabled
                            selected={eachFuture.ValueDate}
                            onSelect={(event) => boiTrendsFutureDateChange(event, index)}
                            onClickOutside={() => openDatePicker(
                              index,
                              'future',
                              false,
                            )}
                            open={eachFuture.showPicker}
                          />
                        </div>
                        <span
                          onClick={() => openDatePicker(
                            index,
                            'future',
                            true,
                          )}
                          className="calender-placment"
                          role="button"
                          tabIndex="0"
                        >
                          <i className="fal fa-calendar-alt" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group border-group">
                    <label
                      htmlFor="fieldlabel1"
                      className="form-label"
                    >
                      {i18n.t('Metric_Value')}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={eachFuture.MetricValue}
                      placeholder="Value"
                      onChange={(event) => boiTrendsFutureValueChange(
                        event,
                        index,
                      )}
                    />
                  </div>
                  <div className="errorMsg">
                    {eachFuture.error}
                  </div>
                  <div className="errorMsg">
                    {eachFuture.valueError}
                  </div>
                </div>
              ))}
            <div className="errorMsg">
              {boiTrendsApiError}
            </div>          
          </TabPanel>
          </Tabs>
          <div className="editBOI-btns">
            <button
              type="button"
              className="btn btn-default mr-2"
              onClick={() => onShowEditDataClick(true)}
            >
              {i18n.t('Cancel')}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => submitBoiTrends()}
            >
              {i18n.t('Submit')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
// BatView.propTypes = {
//   history: PropTypes.shape({
//     push: PropTypes.func.isRequired,
//   }).isRequired,
//   clientData: PropTypes.object.isRequired,
//   languageData: PropTypes.shape({
//     langCode: PropTypes.string.isRequired,
//   }).isRequired,
// };
PastAndFutureTrendsConfigration.propTypes = {
  boiTrendsDates: PropTypes.object.isRequired,
  pastTrend: PropTypes.array.isRequired,
  futureTrend: PropTypes.array.isRequired,
  onShowEditDataClick: PropTypes.func.isRequired,
  postBoiTrends: PropTypes.func.isRequired,
};
export default PastAndFutureTrendsConfigration;
