import React, { Component } from "react";
import "../../CSS/valueRealization.css";
import { LocalApiBaseUrl } from "../../Shared/Constant";
import MultiSelect from "react-multi-select-component";
import LoadingOverlay from "react-loading-overlay";
import axiosInstance from "../../Shared/interceptor";
import { trycatchAlertPopup } from "../../Shared/Constant";
import ValueRealizationGraphs from "../../Shared/ValueRealizationGraphs";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import SynopsLoader from "../../Shared/SynopsLoader";
import { store } from "../../reducers/configureStore";
import { Modal, Button, Overlay, Popover } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import i18n from "i18next";
import { connect } from "react-redux";
import References from "../../Shared/References";
import _ from "lodash";

const defaultStyle = {
  color: "rgb(177, 177, 177)",
  "font-family": "Graphik",
  "font-size": "14px",
  "font-weight": "normal",
  height: "16px",
  "letter-spacing": "-0.25px",
  "line-height": "16px",
  width: "59px",
  "margin-left": "10px",
};

const defaultActiveStyle = {
  background: "rgb(1, 142, 255)",
  "border-radius": "3px",
  color: "#fff",
};

const createNewViewModelStructure = {
  dealId: null,
  towers: [],
  deliveryLocation: [],
  fiscalYear: [],
  viewName: [],
};

class ValueRealization extends Component {
  constructor() {
    super();
    this.state = {
      showNotification: false,
      showNotificationViewExist: false,
      showConfirmation: false,
      showSavedViewPopup: false,
      showInitiativePopup: false,
      showFiscalYearPopup: false,
      selectInitiativeStatus: false,
      totalSavedViews: [],
      totalMyViews: [],
      showSelectedViewGraph: false,
      selectedViewDataset: {},
      graphDataSet: {},
      showCVTgraph: false,

      fiscalYearsOptions: [],
      selectedFiscalYear: [],
      selectedFiscalYearTemp: [],
      initiativeOptions: [],
      selectedInitiativeType: [],
      selectedInitiativeTypeTemp: [],

      LastSelectedSection: "showSavedViewsTiles",
      createNewViewModel: { ..._.cloneDeep(createNewViewModelStructure) },
      isValidName: true,
      isViewValid: true,
      isApiLoading: false,
      postApiStatusMessage: "",
      showPostApiStatus: false,
      showDeleteAlertModal: false,
      showFiscalYearValidation: false,
    };
    this.datepickerDropdownPopoverTarget = React.createRef();
    this.handleFiscalYearValidation = this.handleFiscalYearValidation.bind(this);
  }

  hideSavedViewPopup = () => {
    this.setState({ showSavedViewPopup: false });
  };
  showSavedViewsPopup = () => {
    this.setState({ showSavedViewPopup: true });
  };

  handleConfirmationModalShow = () => {
    this.setState({
      showConfirmation: true,
    });
  };

  handlePostApiStatusModalHide = () => {
    this.setState({ showPostApiStatus: false, postApiStatusMessage: "" });
  };

  handleConfirmationModalHide = (action) => {
    let LastSelectedSection = _.cloneDeep(this.state.LastSelectedSection);
    let { selectedViewDataset, createNewViewModel } = this.state;

    if (action === "cancel") {
      this.setState({
        showConfirmation: false,
        isValidName: true,
        createNewViewModel: { ..._.cloneDeep(createNewViewModelStructure) },
      });
    } else {
      let fiscalYear = this.displaySelectedFiscalYear();
      let initiative = this.displaySelectedInitiative();
      this.setState({
        isApiLoading: true,
      });

      let data = {
        ViewName: createNewViewModel.viewName,
        FiscalYears: fiscalYear,
        InitiativeType: initiative,
        CVTViewID:
          selectedViewDataset.CVTViewID !== undefined
            ? selectedViewDataset.CVTViewID
            : selectedViewDataset.ID,
      };

      axiosInstance
        .post(`${LocalApiBaseUrl}Value/PostViewFrontDoor`, data)
        .then(
          (response) => {
            this.setState({
              postApiStatusMessage: response.data.StatusMessage,
              showPostApiStatus: true,
              isApiLoading: false,
            });
            this.generateViewsData(this.props.offeringIdParam);
          },
          (error) => {
            trycatchAlertPopup(error)
            this.setState({
              isApiLoading: false,
            });
          }
        )
        .catch((error) => {
          trycatchAlertPopup(error);
        });

      this.setState({
        showConfirmation: false,
        showSavedViewsTiles:
          LastSelectedSection === "showSavedViewsTiles" ? true : false,
        showSelectedViewGraph:
          LastSelectedSection === "showSelectedViewGraph" ? true : false,
        selectedTabIndex: LastSelectedSection,
        createNewViewModel: { ..._.cloneDeep(createNewViewModelStructure) },
      });
    }
  };

  componentDidMount() {
    this.generateViewsData(this.props.offeringIdParam);
    this.handleFiscalYearValidation();
  }

  componentDidUpdate(prevProps){    
    if(prevProps.syncFilterData.Month!==this.props.syncFilterData.Month){
      this.handleFiscalYearValidation();
    }
  }

  getFiscalYears = (eachView, type, selectedMyView) => {
    // use this option for fiscal years
    if (type === "get") {
      if (selectedMyView.CVTViewID !== undefined) {
        this.setState({
          fiscalYearsOptions: this.generateFiscalYearOptions(eachView.ExtraFiscalYearFrontDoor, "setOption"),
          selectedFiscalYear: this.generateFiscalYearOptions(selectedMyView.FiscalYears, "setValue"),
          selectedFiscalYearTemp: this.generateFiscalYearOptions(selectedMyView.FiscalYears, "setValue"),
        }, () => {
          this.getInitiativeTypes(selectedMyView, this.props.offeringIdParam)
        });
      } else {
        this.setState({
          fiscalYearsOptions: this.generateFiscalYearOptions(eachView.ExtraFiscalYearFrontDoor, "setOption"),
          selectedFiscalYear: this.generateFiscalYearOptions(eachView.FiscalYears, "setValue"),
          selectedFiscalYearTemp: this.generateFiscalYearOptions(eachView.FiscalYears, "setValue"),
        }, () => {
          this.getInitiativeTypes(eachView, this.props.offeringIdParam)
        });
      }
    } else if (type === "confirm") {
      if (selectedMyView.CVTViewID !== undefined) {
        this.getInitiativeTypes(selectedMyView, this.props.offeringIdParam)
      } else {
        this.getInitiativeTypes(eachView, this.props.offeringIdParam)
      }
    }

  };

  getInitiativeTypes = (eachView, params) => {
    // call the api for initiative type list
    // api GetCVTAllInitiativeFrontDoor
    // input params OrgEntityID, OfferingID, LocationID, FiscalFilter
    let fiscalYear = this.displaySelectedFiscalYear();
    axiosInstance
      .post(
        `${LocalApiBaseUrl}Value/GetCVTAllInitiativeFrontDoor`,
        {
          OrgEntityID: parseInt(params.Client),
          OfferingID: parseInt(params.process),
          LocationID: parseInt(params.Locations),
          FiscalFilter: fiscalYear == "" ? null : fiscalYear,
          CVTViewID: eachView.CVTViewID === undefined ? eachView.ID : eachView.CVTViewID
        }
      )
      .then(
        (response) => {
          this.setState({
            isApiLoading: false,
            initiativeOptions: this.generateInitiativeOptions(response.data.InitiativeTypeList, "setOption"),
            selectedInitiativeType: this.generateInitiativeOptions(eachView.InitiativeTypeList, "setValue"),
            selectedInitiativeTypeTemp: this.generateInitiativeOptions(eachView.InitiativeTypeList, "setValue")
          })
          if (eachView.CVTViewID === undefined) {
            this.showGraphsSection(eachView.ID, 0);
          } else {
            this.showGraphsSection(eachView.CVTViewID, eachView.ID);
          }
        },
        (error) => {
          trycatchAlertPopup(error)
          this.setState({
            isApiLoading: true,
          });
        }
      )
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  selectInitiativeModalShow() {
    this.setState({ selectInitiativeStatus: true });
  }

  selectInitiativeModalClose() {
    this.setState({ selectInitiativeStatus: false });
  }

  displaySelectedInitiative = () => {
    let { selectedInitiativeType } = this.state;

    let selectedInitiativeTypeValue = [];

    selectedInitiativeType.forEach((each) => {
      selectedInitiativeTypeValue.push(each.value);
    });

    return selectedInitiativeTypeValue.toString();
  };

  displaySelectedFiscalYear = () => {
    let { selectedFiscalYear } = this.state;

    let selectedFiscalYearValue = [];

    selectedFiscalYear.forEach((each) => {
      selectedFiscalYearValue.push(each.value);
    });

    return selectedFiscalYearValue.toString();
  };

  handleInitiativePopup = (type) => {
    let { selectedInitiativeType, selectedInitiativeTypeTemp } = this.state;

    if (type === "show") {
      this.setState({
        showInitiativePopup: true,
        selectedInitiativeTypeTemp: selectedInitiativeType,
      });
    } else if (type === "confirm") {
      this.setState(
        {
          showInitiativePopup: false,
          selectedInitiativeType: selectedInitiativeTypeTemp,
        },
        () => {
          if (this.state.selectedViewDataset.CVTViewID === undefined) {
            this.showGraphsSection(this.state.selectedViewDataset.ID, 0);
          } else {
            this.showGraphsSection(
              this.state.selectedViewDataset.CVTViewID,
              this.state.selectedViewDataset.ID
            );
          }
        }
      );
    } else if (type === "cancel") {
      this.setState({
        selectedInitiativeTypeTemp: [],
        showInitiativePopup: false,
      });
    }
  };

  handleFiscalYearValidation(){
    var fullmonthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
  ];
    const { syncFilterData } = this.props;
    const splitfisYear=syncFilterData.Month.split("-");
    const fisMonth=splitfisYear[0];
    const fisYear=splitfisYear[1];
    let monthIndex=fullmonthNames.indexOf(fisMonth);
    if(monthIndex>=8 && fisYear>=2021){
      this.setState({
        showFiscalYearValidation: true,
      });
    }
    else{
      this.setState({
        showFiscalYearValidation: false,
      });
    }
  }

  handleFiscalYearPopup = (type) => {
    let { selectedFiscalYear, selectedFiscalYearTemp } = this.state;
    let fiscalYearParam = [];
    if (type === "show") {
      this.setState({
        showFiscalYearPopup: true,
        selectedFiscalYearTemp: selectedFiscalYear,
      });
    } else if (type === "confirm") {
      this.setState(
        {
          showFiscalYearPopup: false,
          selectedFiscalYear: selectedFiscalYearTemp,
        },
        () => {
          if (this.state.selectedViewDataset.CVTViewID !== undefined) {
            fiscalYearParam = this.state.totalSavedViews.filter(
              (x) => x.ID === this.state.selectedViewDataset.CVTViewID
            );
          } else {
            fiscalYearParam = this.state.selectedViewDataset;
          }
          this.getFiscalYears(
            fiscalYearParam,
            type,
            this.state.selectedViewDataset
          );
        }
      );
    } else if (type === "cancel") {
      this.setState({ selectedFiscalYearTemp: [], showFiscalYearPopup: false });
    }
  };

  showGraphsSection = (CVTViewID, ID) => {
    // api call will be made here to obtain the graphs
    // view id and initiative will be the inputs. 
    let initiative = this.displaySelectedInitiative();
    let fiscalYear = this.displaySelectedFiscalYear();

    this.setState({
      isApiLoading: true,
    });
    axiosInstance
      .post(`${LocalApiBaseUrl}Value/GetAllGraphData`, {
        InitiativeFilter: String(initiative) === "" ? null : initiative,
        CVTViewID: CVTViewID,
        CVTUserViewsFrontDoorID: ID,
        FiscalFilter: String(fiscalYear) === "" ? null : fiscalYear,
      })
      .then(
        (response) => {
          this.setState({
            graphDataSet: response.data,
            showSelectedViewGraph: true,
            showNotification: false,
            showNotificationViewExist: false,
            showSavedViewsTiles: false,
            showSavedViewPopup: false,
            LastSelectedSection: "showSelectedViewGraph",
            isApiLoading: false,
            showCVTgraph: true,
          });
        },
        (error) => {
          trycatchAlertPopup(error)
          this.setState({
            isApiLoading: true,
          });
        }
      )
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  GenerateSelectedViewDataset = (eachView) => {
    let fiscalYearParam = [];
    if (eachView.CVTViewID !== undefined) {
      fiscalYearParam = this.state.totalSavedViews.filter(
        (x) => x.ID === eachView.CVTViewID
      )[0];
    } else {
      fiscalYearParam = eachView;
    }
    this.setState(
      {
        selectedViewDataset: eachView,
      },
      () => {
        this.getFiscalYears(fiscalYearParam, "get", eachView);
      }
    );
  };

  initiativeChange = (name) => {
    let { selectedInitiativeTypeTemp } = this.state;
    let checkboxes = document.getElementsByName(name);
    let checkAll = document.getElementsByName("allInitiative");
    let selected = [];
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        selected.push(checkboxes[i]);
      }
    }
    if (this.state.initiativeOptions.length === selected.length) {
      checkAll[0].checked = true;
    } else {
      checkAll[0].checked = false;
    }
    selectedInitiativeTypeTemp = selected;
    this.setState({ selectedInitiativeTypeTemp: selectedInitiativeTypeTemp });
  };

  selectAllInitiative = (e) => {
    let check = e.target.checked;
    let checkboxes = document.getElementsByName("initiativeType");
    let selected = [];
    if (check) {
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        selected.push(checkboxes[i]);
      }
    } else {
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
    }
    this.setState({ selectedInitiativeTypeTemp: selected });
  };

  fiscalYearChange = (selectedList) => {
    // alert("initiative type selected");
    let { selectedFiscalYearTemp } = this.state;
    let temp = [];
    let tempValue = [];
    var len = selectedList.length;
    for (var i = 0; i < len; i++) {
      temp.push(selectedList[i]);
      tempValue.push(selectedList[i].value);
    }
    selectedFiscalYearTemp = temp;
    this.setState({ selectedFiscalYearTemp: selectedFiscalYearTemp });
  };

  showSavedViewsSection = () => {
    this.setState({
      showSavedViewsTiles: true,
      showSelectedViewGraph: false,
      showSavedViewPopup: false,
      LastSelectedSection: "showSavedViewsTiles",
    });
  };

  //To Get the total Saved Views data
  generateViewsData = (params) => {
    let defaultMyView = [];
    let defaultSavedView = [];
    // make an api call here to get all the views
    // input params: orgId, offId, locId,
    this.setState({
      isApiLoading: true,
    });
    axiosInstance
      .post(`${LocalApiBaseUrl}Value/GetCVTAllViewandFilterInfoFrontDoor`, {
        OrgEntityID: parseInt(params.Client),
        OfferingID: parseInt(params.process),
        LocationID: parseInt(params.Locations),
        LanguageID: store.getState().CurrentFilter.languageData.id,
      })
      .then(
        (response) => {
          if (response.data.SavedViews !== null) {
            this.setState({
              totalSavedViews: response.data.SavedViews,
              totalMyViews: response.data.MyViews,
              isApiLoading: true,
            });
            defaultMyView =
              response.data.MyViews !== null
                ? response.data.MyViews.filter(
                    (data) => data.IsDefault === true
                  )[0]
                : undefined;
            defaultSavedView =
              response.data.SavedViews !== null
                ? response.data.SavedViews.filter(
                    (data) => data.IsDefault === true
                  )[0]
                : undefined;

            this.GenerateSelectedViewDataset(
              defaultMyView !== undefined ? defaultMyView : defaultSavedView
            );
          } else {
            this.setState({
              totalSavedViews: response.data.SavedViews,
              totalMyViews: response.data.MyViews,
              isApiLoading: false,
            });
          }
        },
        (error) => {
          trycatchAlertPopup(error)
          this.setState({
            isApiLoading: false,
          });
        }
      )
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  handleNotificationModalShow = () => {
    this.setState({
      showNotification: true,
    });
  };
  handleNotificationModalHide = () => {
    this.setState({
      showNotification: false,
    });
  };

  handleNotificationViewExistModalShow = () => {
    this.setState({
      showNotificationViewExist: true,
    });
  };
  handleNotificationViewExistModalHide = () => {
    this.setState({
      showNotificationViewExist: false,
    });
  };

  handleRemoveModalHide = (e, payload) => {
    e.stopPropagation();

    if (payload.type === "show") {
      this.setState({ showDeleteAlertModal: true, deletePayload: payload });
    } else if (payload.type === "cancel") {
      this.setState({ showDeleteAlertModal: false, deletePayload: {} });
    } else if (payload.type === "remove") {
      this.setState({ showDeleteAlertModal: false, deletePayload: {} });
      this.deleteView(this.state.deletePayload.eachView);
    }
  };

  generateInitiativeOptions = (InitiativeTypeList, type) => {
    if (type === "setOption") {
      let initiativeOptions = [];
      InitiativeTypeList.forEach((row, i) => {
        let dummy = [];
        dummy.label = row.InitiativeType;
        dummy.value = row.InitiativeType;
        initiativeOptions.push(dummy);
      });

      return initiativeOptions;
    } else if (type === "setValue") {
      let initiativeOptions = [];
      InitiativeTypeList.split(",").forEach((row, i) => {
        let dummy = [];
        dummy.label = row;
        dummy.value = row;
        initiativeOptions.push(dummy);
      });

      return initiativeOptions;
    }
  };

  generateFiscalYearOptions = (FiscalYearList, type) => {
    if (type === "setOption") {
      let fiscalYearOptions = [];
      FiscalYearList.forEach((row, i) => {
        let dummy = [];
        dummy.label = row.fiscalYear;
        dummy.value = row.fiscalYear;
        fiscalYearOptions.push(dummy);
      });

      return fiscalYearOptions;
    } else if (type === "setValue") {
      let fiscalYearOptions = [];
      FiscalYearList.split(",").forEach((row, i) => {
        let dummy = [];
        dummy.label = row;
        dummy.value = row;
        fiscalYearOptions.push(dummy);
      });

      return fiscalYearOptions;
    }
  };

  checkViewExist = () => {
    let { totalSavedViews, totalMyViews, isViewValid } = this.state;
    let initiative = this.displaySelectedInitiative();
    let fiscalYear = this.displaySelectedFiscalYear();
    isViewValid =
      totalMyViews !== null
        ? !totalMyViews.filter(
            (data) =>
              data.FiscalYears === fiscalYear &&
              data.InitiativeTypeList === initiative
          ).length > 0 &&
          !totalSavedViews.filter(
            (data) =>
              data.FiscalYears === fiscalYear &&
              data.InitiativeTypeList === initiative
          ).length > 0
        : !totalSavedViews.filter(
            (data) =>
              data.FiscalYears === fiscalYear &&
              data.InitiativeTypeList === initiative
          ).length > 0;

    if (!isViewValid) {
      this.handleNotificationViewExistModalShow();
    } else {
      this.handleConfirmationModalShow();
    }
  };

  setFavouriteView = (e, ID, CVTViewID, eachView) => {
    e.stopPropagation();
    if (!eachView.IsDefault) {
      this.setState({
        isApiLoading: true,
      });
      axiosInstance
        .post(
          `${LocalApiBaseUrl}Value/PostSetDefaultViewFrontDoor?CVTViewID=${CVTViewID}&CVTUserViewsFrontDoorID=${ID}`
        )
        .then(
          (response) => {
            this.setState({
              isApiLoading: false,
            });
            this.generateViewsData(this.props.offeringIdParam);
          },
          (error) => {
            trycatchAlertPopup(error)
            this.setState({
              isApiLoading: false,
            });
          }
        )
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };

  deleteView = (eachView) => {
    this.setState({
      isApiLoading: true,
    });
    axiosInstance
      .delete(`${LocalApiBaseUrl}Value/DeleteViewFrontDoor`, {
        params: {
          CVTUserViewsFrontDoorID: eachView.ID,
          CVTViewID: eachView.CVTViewID,
        },
      })
      .then(
        (response) => {
          this.setState({
            postApiStatusMessage: response.data.StatusMessage,
            showPostApiStatus: true,
            isApiLoading: false,
          });
          this.generateViewsData(this.props.offeringIdParam);
        },
        (error) => {
          trycatchAlertPopup(error)
          this.setState({
            isApiLoading: false,
          });
        }
      )
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  generateDefaultViewName = () => {
    let createNewViewModel = { ...this.state.createNewViewModel };
    let selectedViewDataset = { ...this.state.selectedViewDataset };
    let fiscalYear = this.displaySelectedFiscalYear();
    createNewViewModel.viewName = `MyView-${selectedViewDataset.Towers.toString()}-${selectedViewDataset.DeliveryLocations.toString()}-${fiscalYear.toString()}`;
    this.setState({ createNewViewModel: createNewViewModel });
  };

  setNewViewName(event) {
    let {
      createNewViewModel,
      isValidName,
      totalSavedViews,
      totalMyViews,
    } = this.state;
    createNewViewModel.viewName = event.target.value;
    isValidName =
      totalMyViews !== null
        ? !totalMyViews.filter((data) => data.ViewName === event.target.value)
            .length > 0 &&
          !totalSavedViews.filter(
            (data) => data.ViewName === event.target.value
          ).length > 0
        : !totalSavedViews.filter(
            (data) => data.ViewName === event.target.value
          ).length > 0;
    this.setState({
      createNewViewModel: createNewViewModel,
      isValidName: isValidName,
    });
  }
  hideFiscalYearDropdownPopover = () => {
    this.setState({
      showFiscalYearPopup: false,
    });
  };

  render() {
    const {
      fiscalYearsOptions,
      showSelectedViewGraph,
      totalSavedViews,
      totalMyViews,
      createNewViewModel,
      selectedViewDataset,
      initiativeOptions,
      selectedInitiativeType,
      selectedInitiativeTypeTemp,
      selectedFiscalYearTemp,
      showFiscalYearValidation
    } = this.state;
    const multiselectYearPicker = (
      <React.Fragment>
        <MultiSelect
          options={fiscalYearsOptions}
          value={selectedFiscalYearTemp}
          onChange={this.fiscalYearChange.bind(this)}
          labelledBy="OrgEntityName"
          shouldToggleOnHover={false}
          overrideStrings={{
            selectSomeItems: "Select",
            allItemsAreSelected: "All items selected.",
            selectAll: "Select All ",
            search: "Search",
          }}
          hasSelectAll={true}
        />
      </React.Fragment>
    );

    const initiative = this.displaySelectedInitiative();
    const fiscalYear = this.displaySelectedFiscalYear();
    return (
      <>
        <LoadingOverlay
          active={this.state.isApiLoading}
          spinner={<SynopsLoader />}
        >
          <div className="valueRealization-container">
            {this.state.showSavedViewPopup ? (
              <div
                className="vr-list-overlay"
                onClick={this.hideSavedViewPopup}
              ></div>
            ) : (
              ""
            )}

            {totalSavedViews === null ? (
              /* LANDING PAGE NO VIEWS DEFAULT MESSAGE STARTS HERE*/
              <div className="novalueContainer">
                <div className="novalue-component-fd">
                  <div className="novalue-fd">
                    Note: There is currently no data available for this deal.
                  </div>
                </div>
              </div>
            ) : (
              /* LANDING PAGE NO VIEWS DEFAULT MESSAGE ENDS HERE*/
              showSelectedViewGraph === true && (
                <>
                <div className="d-flex justify-content-between">
                  <div className="novalue-fd tilldate-msg">
                    {showFiscalYearValidation? "Note: Data displayed is till August 2021 because starting from FY22, value delivered data will be reported under SVM":""}
                  </div>
                  <div className="btn-container">
                    <div className="btn-container-buttons">
                      <button
                        type="button"
                        className="btn createView"
                        onClick={() =>
                          this.state.totalMyViews !== null
                            ? this.state.totalMyViews.length === 5
                              ? this.handleNotificationModalShow()
                              : this.checkViewExist()
                            : this.checkViewExist()
                        }
                      >
                        {i18n.t("Add to My View")}
                      </button>

                      <button
                        type="button"
                        className="btn savedView"
                        onClick={() => this.showSavedViewsPopup()}
                      >
                        {i18n.t("Saved Views")}
                      </button>
                      {this.state.showSavedViewPopup ? (
                        <div className="saved-view-list-fd">
                          <div className="seeall-section">
                            <span onClick={() => this.showSavedViewsSection()}>
                              {" "}
                              {i18n.t("See All")}
                            </span>
                          </div>
                          <Tabs defaultTab={this.state.currentlySelectedTab}>
                            <TabList>
                              <Tab className={"visited"} tabFor="SavedViews">
                                {i18n.t("Saved Views")}
                              </Tab>
                              <Tab className={""} tabFor="MyViews">
                                {i18n.t("My Views")}
                              </Tab>
                            </TabList>
                            <TabPanel tabId="SavedViews">
                              <div className="savedviews-list">
                                <ul>
                                  {totalSavedViews.map((eachView) => {
                                    return (
                                      <li
                                        style={
                                          selectedViewDataset.CVTViewID !==
                                          undefined
                                            ? {}
                                            : selectedViewDataset.ID ===
                                              eachView.ID
                                            ? defaultActiveStyle
                                            : {}
                                        }
                                        onClick={() =>
                                          this.GenerateSelectedViewDataset(
                                            eachView
                                          )
                                        }
                                      >
                                        {eachView.ViewName}
                                        {eachView.IsDefault && (
                                          <span style={defaultStyle}>
                                            (Default)
                                          </span>
                                        )}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </TabPanel>
                            <TabPanel tabId="MyViews">
                              <div className="savedviews-list">
                                <ul>
                                  {totalMyViews !== null
                                    ? totalMyViews.map((eachView) => {
                                        return (
                                          <li
                                            style={
                                              selectedViewDataset.CVTViewID !==
                                              undefined
                                                ? selectedViewDataset.ID ===
                                                  eachView.ID
                                                  ? defaultActiveStyle
                                                  : {}
                                                : {}
                                            }
                                            onClick={() =>
                                              this.GenerateSelectedViewDataset(
                                                eachView
                                              )
                                            }
                                          >
                                            {eachView.ViewName}
                                            {eachView.IsDefault && (
                                              <span style={defaultStyle}>
                                                (Default)
                                              </span>
                                            )}
                                          </li>
                                        );
                                      })
                                    : ""}
                                </ul>
                              </div>
                            </TabPanel>
                          </Tabs>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  </div>
                  {/* GRAPH SECTION  STARTS HERE*/}
                  <div className="valuerealization-optionbuttons-fd">
                    <div>
                      <span>Tower/ {selectedViewDataset.Towers}</span>
                    </div>
                    <div>
                      <span>
                        Delivery Location/{" "}
                        {selectedViewDataset.DeliveryLocations}
                      </span>
                    </div>
                    <div>
                      <Overlay
                        target={this.datepickerDropdownPopoverTarget.current}
                        show={this.state.showFiscalYearPopup}
                        placement="bottom"
                        rootClose
                        rootCloseEvent="click"
                        onHide={this.hideFiscalYearDropdownPopover}
                      >
                        <Popover
                          id="popover-basic4"
                          className="calenderPopover multiselectYearPicker"
                        >
                          <Popover.Content>
                            <div className="popContent">
                              <div>{multiselectYearPicker}</div>
                            </div>
                          </Popover.Content>
                          <div className="popover-footer">
                            <div className="modal-btn temp">
                              <Button
                                variant="default"
                                className="btn-sm mr-auto"
                                onClick={() =>
                                  this.handleFiscalYearPopup("cancel")
                                }
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="primary"
                                className={
                                  selectedFiscalYearTemp.length !== 0
                                    ? "btn-sm"
                                    : "btn-sm disabled"
                                }
                                onClick={() =>
                                  this.handleFiscalYearPopup("confirm")
                                }
                              >
                                Confirm
                              </Button>
                            </div>
                          </div>
                        </Popover>
                      </Overlay>
                      <div>
                        <span
                          ref={this.datepickerDropdownPopoverTarget}
                          onClick={() => this.handleFiscalYearPopup("show")}
                        >
                          Fiscal Year/ {fiscalYear}
                          <i class="fal fa-calendar-alt"></i>
                        </span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <span
                          onClick={() => this.handleInitiativePopup("show")}
                        >
                          Initiative Type/{" "}
                          {selectedInitiativeType.length ===
                          initiativeOptions.length
                            ? "All"
                            : initiative}
                        </span>
                      </div>
                    </div>
                  </div>
                  {this.state.showCVTgraph && (
                    <div className="charts-container">
                      <ValueRealizationGraphs
                        dataset={this.state.graphDataSet}
                      />
                    </div>
                  )}
                  {/* GRAPH SECTION  ENDS HERE*/}
                </>
              )
            )}
          </div>

          {/*  VIEW TILES SECTION STARTS HERE*/}
          {this.state.showSavedViewsTiles && (
            <>
              <div className="savedviews-container">
                <div className="myView-section">
                  {totalMyViews !== null ? (
                    <div>
                      <div className="savedViews-header">My Views</div>
                      <div className="row">
                        {totalMyViews.map((eachView) => {
                          return (
                            <div
                              className="view-card-size"
                              onClick={() =>
                                this.GenerateSelectedViewDataset(eachView)
                              }
                            >
                              <div className="view-card">
                                <div className="view-title">
                                  {eachView.ViewName.replace(/,[s]*/g, ", ")}
                                </div>

                                <div className="form-group">
                                  <div className="field-name">Tower</div>
                                  {/* <div className="field-value">{eachView.Towers.replace(/,[s]/g, ", ")}</div> */}
                                  <div className="field-valueblock">
                                    <span className="field-value">
                                      {eachView.Towers.replace(/,[s]*/g, ", ")
                                        .length > 42
                                        ? eachView.Towers.replace(
                                            /,[s]*/g,
                                            ", "
                                          ).slice(0, 42) + "..."
                                        : eachView.Towers.replace(
                                            /,[s]*/g,
                                            ", "
                                          )}
                                    </span>
                                    {eachView.Towers.replace(/,[s]*/g, ", ")
                                      .length > 42 ? (
                                      <>
                                        <span
                                          className="fieldvalue-count"
                                          data-tip
                                          data-for={"fieldvalue_" + eachView.ID}
                                        >
                                          <span>
                                            +
                                            {
                                              eachView.Towers.replace(
                                                /,[s]*/g,
                                                ", "
                                              )
                                                .slice(43)
                                                .split(",").length
                                            }
                                          </span>
                                        </span>
                                        <ReactTooltip
                                          place="right"
                                          type="dark"
                                          effect="solid"
                                          id={"fieldvalue_" + eachView.ID}
                                          className="fieldvalue-tooltip"
                                        >
                                          <span>
                                            {eachView.Towers.replace(
                                              /,[s]*/g,
                                              ", "
                                            )}
                                          </span>
                                        </ReactTooltip>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                <div className="form-group">
                                  <div className="field-name">
                                    Delivery Location
                                  </div>
                                  <div className="field-valueblock">
                                    <span className="field-value">
                                      {eachView.DeliveryLocations.replace(
                                        /,[s]*/g,
                                        ", "
                                      ).length > 42
                                        ? eachView.DeliveryLocations.replace(
                                            /,[s]*/g,
                                            ", "
                                          ).slice(0, 42) + "..."
                                        : eachView.DeliveryLocations.replace(
                                            /,[s]*/g,
                                            ", "
                                          )}
                                    </span>
                                    {eachView.DeliveryLocations.replace(
                                      /,[s]*/g,
                                      ", "
                                    ).length > 42 ? (
                                      <>
                                        <span
                                          className="fieldvalue-count"
                                          data-tip
                                          data-for={
                                            "fieldvalueforlocation_" +
                                            eachView.ID
                                          }
                                        >
                                          <span>
                                            +
                                            {
                                              eachView.DeliveryLocations.replace(
                                                /,[s]*/g,
                                                ", "
                                              )
                                                .slice(43)
                                                .split(",").length
                                            }
                                          </span>
                                        </span>
                                        <ReactTooltip
                                          place="right"
                                          type="dark"
                                          effect="solid"
                                          id={
                                            "fieldvalueforlocation_" +
                                            eachView.ID
                                          }
                                          className="fieldvalue-tooltip"
                                        >
                                          <span>
                                            {eachView.DeliveryLocations.replace(
                                              /,[s]*/g,
                                              ", "
                                            )}
                                          </span>
                                        </ReactTooltip>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                <div className="form-group">
                                  <div className="field-name">Fiscal Year</div>
                                  <div className="field-valueblock">
                                    <span className="field-value">
                                      {eachView.FiscalYears.replace(
                                        /,[s]*/g,
                                        ", "
                                      ).length > 42
                                        ? eachView.FiscalYears.replace(
                                            /,[s]*/g,
                                            ", "
                                          ).slice(0, 42) + "..."
                                        : eachView.FiscalYears.replace(
                                            /,[s]*/g,
                                            ", "
                                          )}
                                    </span>
                                    {eachView.FiscalYears.replace(
                                      /,[s]*/g,
                                      ", "
                                    ).length > 42 ? (
                                      <>
                                        <span
                                          className="fieldvalue-count"
                                          data-tip
                                          data-for={
                                            "fieldvalueforfiscalyear_" +
                                            eachView.ID
                                          }
                                        >
                                          <span>
                                            +
                                            {
                                              eachView.FiscalYears.replace(
                                                /,[s]*/g,
                                                ", "
                                              )
                                                .slice(43)
                                                .split(",").length
                                            }
                                          </span>
                                        </span>
                                        <ReactTooltip
                                          place="right"
                                          type="dark"
                                          effect="solid"
                                          id={
                                            "fieldvalueforfiscalyear_" +
                                            eachView.ID
                                          }
                                          className="fieldvalue-tooltip"
                                        >
                                          <span>
                                            {eachView.FiscalYears.replace(
                                              /,[s]*/g,
                                              ", "
                                            )}
                                          </span>
                                        </ReactTooltip>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="view-card-footer">
                                <div
                                  className="favourite_tooltip"
                                  onClick={(e) =>
                                    this.setFavouriteView(
                                      e,
                                      eachView.ID,
                                      eachView.CVTViewID,
                                      eachView
                                    )
                                  }
                                >
                                  {/* <i className={"fas fa-star " + (eachView.IsDefault ? "active" : "")} aria-hidden="true"></i> */}
                                  <span
                                    className={
                                      "defaultmark-icon " +
                                      (eachView.IsDefault ? "active" : "")
                                    }
                                    data-tip
                                    data-for="markdefault"
                                  ></span>
                                  <ReactTooltip
                                    place="bottom"
                                    type="dark"
                                    effect="solid"
                                    id="markdefault"
                                  >
                                    <span>Mark as Default</span>
                                  </ReactTooltip>
                                </div>
                                <div
                                  className="favourite_tooltip"
                                  onClick={(e) =>
                                    this.handleRemoveModalHide(e, {
                                      eachView: eachView,
                                      type: "show",
                                    })
                                  }
                                >
                                  <span
                                    className="delete-btnicon"
                                    data-tip
                                    data-for="deleteicon"
                                  ></span>
                                  <ReactTooltip
                                    place="bottom"
                                    type="dark"
                                    effect="solid"
                                    id="deleteicon"
                                  >
                                    <span>Delete</span>
                                  </ReactTooltip>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="savedviews-container">
                <div className="myView-section">
                  <div className="savedViews-header">Saved Views</div>
                  <div className="row">
                    {totalSavedViews.map((eachView) => {
                      return (
                        <div
                          className="view-card-size"
                          onClick={() =>
                            this.GenerateSelectedViewDataset(eachView)
                          }
                        >
                          <div className="view-card">
                            <div className="view-title">
                              {eachView.ViewName.replace(/,[s]*/g, ", ")}
                            </div>

                            <div className="form-group">
                              <div className="field-name">Tower</div>

                              <div className="field-valueblock">
                                <span className="field-value">
                                  {eachView.Towers.replace(/,[s]*/g, ", ")
                                    .length > 42
                                    ? eachView.Towers.replace(
                                        /,[s]*/g,
                                        ", "
                                      ).slice(0, 42) + "..."
                                    : eachView.Towers.replace(/,[s]*/g, ", ")}
                                </span>
                                {eachView.Towers.replace(/,[s]*/g, ", ")
                                  .length > 42 ? (
                                  <>
                                    <span
                                      className="fieldvalue-count"
                                      data-tip
                                      data-for={"fieldvalue_" + eachView.ID}
                                    >
                                      <span>
                                        +
                                        {
                                          eachView.Towers.replace(
                                            /,[s]*/g,
                                            ", "
                                          )
                                            .slice(43)
                                            .split(",").length
                                        }
                                      </span>
                                    </span>
                                    <ReactTooltip
                                      place="right"
                                      type="dark"
                                      effect="solid"
                                      id={"fieldvalue_" + eachView.ID}
                                      className="fieldvalue-tooltip"
                                    >
                                      <span>
                                        {eachView.Towers.replace(
                                          /,[s]*/g,
                                          ", "
                                        )}
                                      </span>
                                    </ReactTooltip>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="field-name">
                                Delivery Location
                              </div>
                              <div className="field-valueblock">
                                <span className="field-value">
                                  {eachView.DeliveryLocations.replace(
                                    /,[s]*/g,
                                    ", "
                                  ).length > 42
                                    ? eachView.DeliveryLocations.replace(
                                        /,[s]*/g,
                                        ", "
                                      ).slice(0, 42) + "..."
                                    : eachView.DeliveryLocations.replace(
                                        /,[s]*/g,
                                        ", "
                                      )}
                                </span>
                                {eachView.DeliveryLocations.replace(
                                  /,[s]*/g,
                                  ", "
                                ).length > 42 ? (
                                  <>
                                    <span
                                      className="fieldvalue-count"
                                      data-tip
                                      data-for={
                                        "fieldvalueforlocation_" + eachView.ID
                                      }
                                    >
                                      <span>
                                        +
                                        {
                                          eachView.DeliveryLocations.replace(
                                            /,[s]*/g,
                                            ", "
                                          )
                                            .slice(43)
                                            .split(",").length
                                        }
                                      </span>
                                    </span>
                                    <ReactTooltip
                                      place="right"
                                      type="dark"
                                      effect="solid"
                                      id={
                                        "fieldvalueforlocation_" + eachView.ID
                                      }
                                      className="fieldvalue-tooltip"
                                    >
                                      <span>
                                        {eachView.DeliveryLocations.replace(
                                          /,[s]*/g,
                                          ", "
                                        )}
                                      </span>
                                    </ReactTooltip>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="field-name">Fiscal Year</div>
                              <div className="field-valueblock">
                                <span className="field-value">
                                  {eachView.FiscalYears.replace(/,[s]*/g, ", ")
                                    .length > 42
                                    ? eachView.FiscalYears.replace(
                                        /,[s]*/g,
                                        ", "
                                      ).slice(0, 42) + "..."
                                    : eachView.FiscalYears.replace(
                                        /,[s]*/g,
                                        ", "
                                      )}
                                </span>
                                {eachView.FiscalYears.replace(/,[s]*/g, ", ")
                                  .length > 42 ? (
                                  <>
                                    <span
                                      className="fieldvalue-count"
                                      data-tip
                                      data-for={
                                        "fieldvalueforfiscalyear_" + eachView.ID
                                      }
                                    >
                                      <span>
                                        +
                                        {
                                          eachView.FiscalYears.replace(
                                            /,[s]*/g,
                                            ", "
                                          )
                                            .slice(43)
                                            .split(",").length
                                        }
                                      </span>
                                    </span>
                                    <ReactTooltip
                                      place="right"
                                      type="dark"
                                      effect="solid"
                                      id={
                                        "fieldvalueforfiscalyear_" + eachView.ID
                                      }
                                      className="fieldvalue-tooltip"
                                    >
                                      <span>
                                        {eachView.FiscalYears.replace(
                                          /,[s]*/g,
                                          ", "
                                        )}
                                      </span>
                                    </ReactTooltip>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="view-card-footer">
                            <div
                              className="favourite_tooltip"
                              onClick={(e) =>
                                this.setFavouriteView(
                                  e,
                                  0,
                                  eachView.ID,
                                  eachView
                                )
                              }
                            >
                              <span
                                className={
                                  "defaultmark-icon " +
                                  (eachView.IsDefault ? "active" : "")
                                }
                                data-tip
                                data-for="markdefault"
                              ></span>
                              <ReactTooltip
                                place="bottom"
                                type="dark"
                                effect="solid"
                                id="markdefault"
                              >
                                <span>Mark as Default</span>
                              </ReactTooltip>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          )}
          {/*  VIEW TILES SECTION ENDS HERE*/}

          {/*  MAXIMUM FIVE VIEW WARNING STARTS HERE*/}
          {this.state.showNotification && (
            <Modal
              show
              centered
              onHide={() => this.handleNotificationModalHide()}
              backdrop="static"
              dialogClassName="valueRealization-Notification-modal"
            >
              <Modal.Header closeButton className="">
                <Modal.Title>Notification</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="notification-body">
                  <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  <p>
                    Maximum five Views can be created. Please delete a saved My
                    view to Create New.
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.handleNotificationModalHide()}
                >
                  {/* onClick={() => this.handleCreateNewViewsClick()}> */}
                  OK
                </button>
              </Modal.Footer>
            </Modal>
          )}
          {/*  MAXIMUM FIVE WARNING ENDS HERE*/}

          {/* VIEW EXIST WARNING STARTS HERE*/}
          {this.state.showNotificationViewExist && (
            <Modal
              show
              centered
              onHide={() => this.handleNotificationViewExistModalHide()}
              backdrop="static"
              dialogClassName="valueRealization-Notification-modal"
            >
              <Modal.Header closeButton className="">
                <Modal.Title>Notification</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="notification-body">
                  <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  <p>This view already exist. Please change your selection.</p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.handleNotificationViewExistModalHide()}
                >
                  {/* onClick={() => this.handleCreateNewViewsClick()}> */}
                  OK
                </button>
              </Modal.Footer>
            </Modal>
          )}
          {/*  VIEW EXIST WARNING ENDS HERE*/}

          {/*  VIEW CREATION CONFIRMATION STARTS HERE*/}
          {this.state.showConfirmation && (
            <Modal
              show
              centered
              onHide={() => this.handleConfirmationModalHide("cancel")}
              backdrop="static"
              dialogClassName="valueRealization-Confirmation-modal-fd"
            >
              <Modal.Header className="valueRealization-modalHeader-fd">
                <Modal.Title>Create New View</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="Confirmation-body-fd">
                  <div className="form-group">
                    <label className="" for="fiscalYear"></label>
                    <div
                      className={
                        !this.state.isValidName ? "validation-errmsg" : ""
                      }
                    >
                      <div className="input-text-value">
                        <label className="" for="viewName">
                          Name your View
                        </label>
                        <input
                          type="text"
                          className=""
                          id="viewName"
                          name="viewName"
                          maxLength="100"
                          onChange={this.setNewViewName.bind(this)}
                          value={createNewViewModel.viewName}
                        />
                      </div>
                      {!this.state.isValidName && (
                        <div className="guidline-msg">
                          This view name already exists. Please enter a new
                          name.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn cancel-btn"
                  onClick={() => this.handleConfirmationModalHide("cancel")}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn confirm-btn"
                  onClick={() => this.handleConfirmationModalHide("confirm")}
                  disabled={
                    createNewViewModel.viewName.length > 0
                      ? !this.state.isValidName &&
                        createNewViewModel.viewName !== ""
                      : true
                  }
                >
                  Save
                </button>
              </Modal.Footer>
            </Modal>
          )}
          {/*  VIEW CREATION CONFIRMATION ENDS HERE*/}

          {/* POST API STATUS MESSAGE STARTS HERE */}
          <Modal
            show={this.state.showPostApiStatus}
            centered
            // onHide={() => this.handlePostApiStatusModalHide()}
            backdrop="static"
            dialogClassName="valueRealization-Notification-modal"
          >
            <Modal.Header className="">
              <Modal.Title>Notification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="notification-body">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <p>{this.state.postApiStatusMessage}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.handlePostApiStatusModalHide()}
              >
                OK
              </button>
            </Modal.Footer>
          </Modal>
          {/* POST API STATUS MESSAGE ENDS HERE */}

          {/* DELETE CONFIRMATION BOX STARTS HERE */}
          <Modal
            show={this.state.showDeleteAlertModal}
            centered
            backdrop="static"
            dialogClassName="valueRealization-remove-modal"
          >
            <Modal.Header className="">
              <Modal.Title>Remove Asset</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="notification-body">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <p>
                  Are you sure you want to delete this asset? <br />
                  This action can not be undone.
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn cancel-btn"
                onClick={(e) =>
                  this.handleRemoveModalHide(e, { type: "cancel" })
                }
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn confirm-btn"
                onClick={(e) =>
                  this.handleRemoveModalHide(e, { type: "remove" })
                }
              >
                {" "}
                Remove
              </button>
            </Modal.Footer>
          </Modal>
          {/* DELETE CONFIRMATION BOX STARTS HERE */}
          {/* REFERENCE POPUP */}

          {/* Select Initiative Popup Start*/}
          <Modal
            show={this.state.showInitiativePopup}
            centered
            onHide={() => this.handleInitiativePopup("cancel")}
            className="selectInitiative-modal"
            backdrop="static"
          >
            <Modal.Header>
              <Modal.Title>Select Initiative Type</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="initiatives-list">
                <ul>
                  <li>
                    <label className="form-check">
                      <input
                        className="form-check-input"
                        defaultChecked={
                          selectedInitiativeTypeTemp.length ===
                          initiativeOptions.length
                        }
                        type="checkbox"
                        onChange={(e) => this.selectAllInitiative(e)}
                        value="all"
                        name="allInitiative"
                      />
                      <span className="checkmark"></span>
                      <div className="form-check-label">
                        <h6>All</h6>
                      </div>
                    </label>
                  </li>
                  {initiativeOptions.map((x) => (
                    <li>
                      <label className="form-check">
                        <input
                          className="form-check-input"
                          defaultChecked={
                            x.value ===
                            initiative
                              .split(",")
                              .filter((y) => y === x.value)[0]
                          }
                          type="checkbox"
                          name="initiativeType"
                          onChange={(e) => this.initiativeChange(e.target.name)}
                          value={x.value}
                        />
                        <span className="checkmark"></span>
                        <div className="form-check-label">
                          <h6>{x.label}</h6>
                        </div>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </Modal.Body>
            <Modal.Footer className="modal-btn">
              <button
                type="button"
                className="btn btn-outline-secondary mr-2"
                onClick={() => this.handleInitiativePopup("cancel")}
              >
                Cancel
              </button>
              <button
                type="button"
                className={
                  selectedInitiativeTypeTemp.length !== 0
                    ? "btn btn-primary"
                    : "btn btn-primary disabled"
                }
                onClick={() => this.handleInitiativePopup("confirm")}
              >
                Add
              </button>
            </Modal.Footer>
          </Modal>
          {/* Select Initiative Popup End*/}
        </LoadingOverlay>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return ({
      syncFilterData: state.CurrentFilter.SyncFilter,
  })
}

export default connect(mapStateToProps)(ValueRealization);
