import React from "react";
import _ from "lodash";
import classNames from "classnames";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import axiosInstance from "../Shared/interceptor";
import {trycatchAlertPopup} from "../Shared/Constant";
import KpiPopUp from "../SuperAdmin/KpiPopUp";
import { regexForNumbersOnlyMTC, regexForFields } from "../Shared/Constant";
import i18n from "i18next";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import { DisplayMessage,isValidText,InvalidDescription } from "../Shared/commonFunctions";

const maxLengthMetricDefinitionFields = 500;

function validateMetricDefinitionFieldValue(str) {
  return regexForFields.test(str);
}

// THE FIELDS FOR A SINGLE METRIC DEFINITION
const singleMetricDefinition = {
  isDisabledNumeratorRadioField: false,
  selectedDefinition: "numeratorDefinition",
  numeratorDefinition: "",
  isRequiredNumeratorDefinitionField: false,
  errMsgNumeratorDefinitionField: "",
  denominatorDefinition: "",
  isRequiredDenominatorDefinitionField: false,
  errMsgDenominatorDefinitionField: "",
  isEnabled: "yes",
};

const singleBenchmarkPair = {
  errMsgAttribute2ValueField: "",
  errMsgAttribute1ValueField: "",
  Attribute1Value: "",
  Attribute2Value: "",
  Industry: "",
  errMsgIndustryField: "",
};

class DiversePopUp extends React.Component {
  constructor(props) {
    super(props);
    this.ERR_MSG_METRIC_DEF_INVALID_VALUE =
      i18n.t("MultiDefErrMsg1") + " " + `@ $ % & () \n \- _ , . ? ‘ ” \' \"/` + " " + i18n.t("MultiDefErrMsg2");

    this.state = {
      show: this.props.show,
      apivalue: "",
      message: "",
      unitsData: [],
      selectValue: this.props.SelectValue,
      Display: false,
      MetricNameOptions: [],
      viewmore: false,
      checkFlag : false,
      flag : true,
      seqValue:"",
      diverseData: {
        KPIMappingID: "",
        MetricSeq: "",
        MetricID: "",
        MetricName: "",
        FrequencyID: "",
        MetricUnit: "",
        MetricImage: "",
        KPIDesp: "",
        MetricDataID: "",
        MetricDataDesp: "",
        MetricValue: "",
        RAGStatus: "",
        UpperThreshold: "",
        LowerThreshold: "",
        Month: "",
        Attribute1Value: "",
        Attribute2Value: "",
        LeadingPractice: "",
      },

      formIsValid: false,
      errors: {},
      // MULTIPLE METRIC DEFINITIONS WHICH ARE DYNAMICALLY ADDED
      metricDefinitions: [
        {
          ..._.cloneDeep(singleMetricDefinition),
          isRequiredNumeratorDefinitionField: props.defaultIsRequiredMetricDefinitionFields,
          isRequiredDenominatorDefinitionField: props.defaultIsRequiredMetricDefinitionFields,
        },
      ],

      areAnyDuplicateMetricDefinitions: false,
      benchmarkPair: [
        {
          ..._.cloneDeep(singleBenchmarkPair),
        },
      ],
      areAnyDuplicateIndustry: false,
      IndustryOptions: [],

      isAPILoading: false,
    };

    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onSequenceChange = this.onSequenceChange.bind(this);
    this.onUnitChange = this.onUnitChange.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.handleRejectHide = this.handleRejectHide.bind(this);
    this.handleApproveHide = this.handleApproveHide.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onAttribute1ValueChange = this.onAttribute1ValueChange.bind(this);
    this.onAttribute2ValueChange = this.onAttribute2ValueChange.bind(this);
    let offeringValue = "";
    let value1 = [];
  }

  onClose(e) {
    this.setState({ viewmore: false });
    this.props.onClose(e);
  }

  onAttribute1ValueChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const diverseData = this.state.diverseData;

    diverseData.Attribute1Value = event.target.value;
    this.setState({
      diverseData: diverseData,
    });
  }

  onAttribute2ValueChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const diverseData = this.state.diverseData;
    diverseData.Attribute2Value = event.target.value;
    this.setState({
      diverseData: diverseData,
    });
  }

  componentWillMount() {
    if (this.props.filterDetails != undefined)
      if (
        this.props.filterDetails.Client != 0 &&
        this.props.filterDetails.Locations != 0 &&
        this.props.filterDetails.Offering != 0 &&
        this.props.filterDetails.Process != 0
      ) {
        this.setState({ Display: true });
      }
    let apivalue = this.props.opsgrp;

    this.setState({ apivalue });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.filterDetails != undefined)
      if (
        nextProps.filterDetails.Client != 0 &&
        nextProps.filterDetails.Locations != 0 &&
        nextProps.filterDetails.Offering != 0 &&
        nextProps.filterDetails.Process != 0
      ) {
        this.setState({ Display: true });
      }
  }

  componentDidMount() {
    this.getIndustries();
    if (this.props.editDiverse) {
      let diverseEdit = this.props.editDiverse.filter((x) => x.ParentSOCategoryID == "5")[0].kpiData;
      let diverseData = diverseEdit.filter((x) => x.KPIMappingID == this.props.kpiID)[0];

      const kpiData = diverseData;
      const { defaultIsRequiredMetricDefinitionFields } = this.props;

      let metricDefinitions = [];
      const metricDefinitionsFromProps = _.get(kpiData, "MetricDefination", []);

      if (!_.isEmpty(metricDefinitionsFromProps)) {
        metricDefinitions = _.map(metricDefinitionsFromProps, (eachItem) => {
          const metricDef = {
            ..._.cloneDeep(singleMetricDefinition),
            isDisabledNumeratorRadioField: kpiData.MetricUnit.trim() === "%",
            selectedDefinition: !_.isEmpty(eachItem.DenominatorDescription)
              ? "numeratorAndDenominatorDefinition"
              : "numeratorDefinition",
            numeratorDefinition: eachItem.NumeratorDescription,
            isRequiredNumeratorDefinitionField: defaultIsRequiredMetricDefinitionFields,
            denominatorDefinition: eachItem.DenominatorDescription,
            isRequiredDenominatorDefinitionField: defaultIsRequiredMetricDefinitionFields,
            isEnabled: eachItem.IsEnabled ? "yes" : "",
            KPIMetricDefinatoinID: _.get(eachItem, "KPIMetricDefinatoinID", null),
          };
          return metricDef;
        });
      } else {
        metricDefinitions = [{ ..._.cloneDeep(this.state.metricDefinitions[0]) }];
      }

      let benchmarkPair = [];
      const benchmarkPairFromProps = _.get(kpiData, "MetricBenchmarkList", []);

      if (!_.isEmpty(benchmarkPairFromProps)) {
        benchmarkPair = _.map(benchmarkPairFromProps, (eachItem) => {
          const benchmark = {
            ..._.cloneDeep(singleBenchmarkPair),
            Attribute1Value: eachItem.Attribute1Value,
            Attribute2Value: eachItem.Attribute2Value,
            Industry: eachItem.IndustryID == null ? "null" : eachItem.IndustryID.toString(),
          };
          return benchmark;
        });
      } else {
        benchmarkPair = [{ ..._.cloneDeep(this.state.benchmarkPair[0]) }];
      }

      this.setState(
        {
          diverseData,
          errors: {},
          formIsValid: true,
          metricDefinitions,
          benchmarkPair,
        },
        () => {
          if (kpiData.MetricUnit.trim() === "%") {
            this.toggleRequiredMetricDefinitionFields();
          }
        }
      );
    }
    let unitsData = this.props.unitsData;
    this.setState({ unitsData: unitsData });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.unitsData !== this.props.unitsData) {
      this.setState({ unitsData: this.props.unitsData });
    }
  }
  getKpiMAppingDetails() {
    let samp = {};
    axiosInstance
      .get(`${LocalApiBaseUrl}Configurator/GetKpiMapping`, {
        params: {
          OrgEntityID: this.props.filterDetails.Client,
          LocationID: this.props.filterDetails.Locations,
          OfferingID: this.props.filterDetails.process,
          SOCategoryID: this.props.SOCategoryID,
        },
      })
      .then((response) => {
        this.setState({ MetricNameOptions: response.data });
      });
  }

  handleValidation() {
    const { metricDefinitions } = this.state;
    const { benchmarkPair } = this.state;

    const { defaultIsRequiredLeadingPractice } = this.props;

    let diverseData = this.state.diverseData;
    let errors = {};
    let formIsValid = true;
    if (diverseData["MetricName"]) 
    {
      diverseData["MetricName"] = diverseData["MetricName"].trim();
      if(!isValidText(diverseData["MetricName"])){
				errors["MetricName"] =  InvalidDescription();
				formIsValid = false;
			  }
    }
    if (!diverseData["MetricName"]) {
      formIsValid = false;
      // errors["MetricName"] = "Name is required";
      errors["MetricName"] = i18n.t("addWoErrorM1");
    }

    if (diverseData["KPIDesp"]){
			diverseData["KPIDesp"] = diverseData["KPIDesp"].trim();
			if(!isValidText(diverseData["KPIDesp"])){
				errors["KPIDesp"] =  InvalidDescription();
				formIsValid = false;
			  }
		}

    if (diverseData["MetricSeq"]) {
      diverseData["MetricSeq"] = parseInt(diverseData["MetricSeq"], 10);
    }
    if (!diverseData["MetricSeq"]) {
      formIsValid = false;
      // errors["MetricSeq"] = "Sequence is required";
      errors["MetricSeq"] = i18n.t("addWoErrorM5");
    } else if (!(diverseData["MetricSeq"] >= 0 && diverseData["MetricSeq"] <= 999999)) {
      formIsValid = false;
      // errors["MetricSeqRange"] ="Sequence Value must be in between 0 to 999999";
      errors["MetricSeqRange"] = i18n.t("addWoErrorM6");
    }
      if (diverseData["MetricUnit"])
          diverseData["MetricUnit"] = diverseData["MetricUnit"].trim();
    if (!diverseData["MetricUnit"]) {
      formIsValid = false;
      // errors["MetricUnit"] = "Unit is required";
      errors["MetricUnit"] = i18n.t("Unit_is_required");
    }

    // VALIDATE "LEADING PRACTICE" FIELD
    const LeadingPractice = diverseData.LeadingPractice;
    if (defaultIsRequiredLeadingPractice && _.isEmpty(LeadingPractice)) {
      formIsValid = false;
      // errors["LeadingPractice"] = "Leading Practice is required";
      errors["LeadingPractice"] = i18n.t("Leading_Practice_ErrMsg");
    }

    // VALIDATE BENCHMARK
    const modifiedBenchmark = _.map(benchmarkPair, (eachItem) => {
      eachItem["errMsgAttribute1ValueField"] = "";
      eachItem["errMsgAttribute2ValueField"] = "";
      eachItem["errMsgIndustryField"] = "";

      if (!eachItem["Industry"].trim()) {
        // Industry can never be left empty
        formIsValid = false;
        eachItem["errMsgIndustryField"] = i18n.t("Industry_is_required");
      }

      if (!eachItem["Attribute1Value"].trim() && !eachItem["Attribute2Value"].trim()) {
        // attribute values can never be left empty - fill NA
        formIsValid = false;
        // errors["Attribute1Value"] ="Benchmark values are mandatory. If you don't intend to add values, please enter NA";
        // errors["Attribute2Value"] ="Benchmark values are mandatory. If you don't intend to add values, please enter NA";
        eachItem["errMsgAttribute1ValueField"] = i18n.t("AttributeValue_ErrMsg1");
        eachItem["errMsgAttribute2ValueField"] = i18n.t("AttributeValue_ErrMsg1");
      }

      if (eachItem["Attribute1Value"].trim()) {
        if (Number.isNaN(parseFloat(eachItem["Attribute1Value"].trim()))) {
          // it is a string

          if (eachItem["Attribute1Value"].toLowerCase().trim() !== "na") {
            formIsValid = false;
            // errors["Attribute1Value"] = "NA is the only permitted string value";
            eachItem["errMsgAttribute1ValueField"] = i18n.t("AttributeValue_ErrMsg2");
          }
        } else {
          // it is a whole number or decimal value OR has alphabets and numbers ex. 123efg345

          if (!regexForNumbersOnlyMTC.test(eachItem["Attribute1Value"].trim())) {
            formIsValid = false;
            // errors["Attribute1Value"] = "NA is the only permitted string value";
            eachItem["errMsgAttribute1ValueField"] = i18n.t("AttributeValue_ErrMsg2");
          }

          if (
            !(
              parseFloat(eachItem["Attribute1Value"].trim()) >= 0 &&
              parseFloat(eachItem["Attribute1Value"].trim()) <= 999999
            )
          ) {
            formIsValid = false;
            // errors["Attribute1Value"] =	`${this.props.attributeLabels.AttributeName1}` +" value must be in between 0 to 999999";
            eachItem["errMsgAttribute1ValueField"] =
              `${this.props.attributeLabels.AttributeName1}` + " " + i18n.t("addBoErrorM2");
          }

          //0 to 999999 validation for attribute1value
        }

        if (!eachItem["Attribute2Value"].trim()) {
          // if attribute1 is filled, attribute2` cant be empty
          eachItem["errMsgAttribute2ValueField"] =
            i18n.t("AttributeValue_ErrMsg3") + " " + `${this.props.attributeLabels.AttributeName2}`;

          formIsValid = false;
        }
        // TODO: needs to be chnaged later when attribute labels are dynamic
      }

      if (eachItem["Attribute2Value"].trim()) {
        if (Number.isNaN(parseFloat(eachItem["Attribute2Value"]))) {
          // it is a string

          if (eachItem["Attribute2Value"].toLowerCase().trim() !== "na") {
            formIsValid = false;
            // errors["Attribute2Value"] = "NA is the only permitted string value";
            eachItem["errMsgAttribute2ValueField"] = i18n.t("AttributeValue_ErrMsg2");
          }
        } else {
          //attribute 2 is a whole number or decimal OR has alphabets and numbers ex. 12efgh3456

          if (!regexForNumbersOnlyMTC.test(eachItem["Attribute2Value"].trim())) {
            formIsValid = false;
            // errors["Attribute2Value"] = "NA is the only permitted string value";
            eachItem["errMsgAttribute2ValueField"] = i18n.t("AttributeValue_ErrMsg2");
          }

          //0 to 999999 validation for attribute2value
          if (
            !(
              parseFloat(eachItem["Attribute2Value"].trim()) >= 0 &&
              parseFloat(eachItem["Attribute2Value"].trim()) <= 999999
            )
          ) {
            formIsValid = false;
            eachItem["errMsgAttribute2ValueField"] =
              `${this.props.attributeLabels.AttributeName2}` + " " + i18n.t("addBoErrorM2");
          }
        }
        if (!eachItem["Attribute1Value"].trim()) {
          // if attribute2 is filled, attribute1` cant be empty
          // errors["Attribute1Value"] =	"Please enter a value for " +`${this.props.attributeLabels.AttributeName1}`;
          eachItem["errMsgAttribute1ValueField"] =
            i18n.t("AttributeValue_ErrMsg3") + " " + `${this.props.attributeLabels.AttributeName1}`;

          formIsValid = false;
        }
        // TODO: needs to be changed later when attribute labels are dynamic
      }

      return eachItem;
    });

    // CHECK FOR DUPLICATE BENCHMARK
    let areAnyDuplicateIndustry = false;
    let minCrossIndustryCheckFailed = false;
    let crossIndustryCount = 0;
    _.forEach(modifiedBenchmark, function(eachBenchmark, index) {
      const modifiedIndustry = String(eachBenchmark.Industry).trim();

      const eqTypeField = _.find(modifiedBenchmark, function(eachItem, indx) {
        const foundindustry = String(eachItem.Industry).trim();

        if (
          indx > index &&
          !_.isEmpty(modifiedIndustry) &&
          !_.isEmpty(foundindustry) &&
          modifiedIndustry === foundindustry
        ) {
          return true;
        }
      });
      if (eqTypeField) {
        areAnyDuplicateIndustry = true;
      }

      if (modifiedIndustry === "null") {
        crossIndustryCount++;
      }
    });

    if (areAnyDuplicateIndustry) {
      formIsValid = false;
    }

    // CHECK FOR REQUIRED ONE CROSS INDUSTRY FIELD
    if (crossIndustryCount === 0) {
      formIsValid = false;
      minCrossIndustryCheckFailed = true;
    }

    // VALIDATE "METRIC DEFINITIONS"
    const modifiedMetricDefinitions = _.map(metricDefinitions, (eachItem) => {
      const selectedDefinition = eachItem.selectedDefinition;
      let errMsgNumeratorDefinitionField = "";
      let errMsgDenominatorDefinitionField = "";

      const numeratorDefinition = String(eachItem.numeratorDefinition).trim();
      const denominatorDefinition = String(eachItem.denominatorDefinition).trim();

      // FOR `numeratorDefinitionField` CHECK IF THE FIELD IS REQUIRED AND ITS VALUE IS NOT EMPTY
      if (eachItem.isRequiredNumeratorDefinitionField && _.isEmpty(numeratorDefinition)) {
        // errMsgNumeratorDefinitionField = "Numerator Definition is required";
        errMsgNumeratorDefinitionField = i18n.t("NumeratorDefReqd");
      } else if (!_.isEmpty(numeratorDefinition) && !validateMetricDefinitionFieldValue(numeratorDefinition)) {
        errMsgNumeratorDefinitionField = this.ERR_MSG_METRIC_DEF_INVALID_VALUE;
      }

      if (selectedDefinition === "numeratorAndDenominatorDefinition") {
        // IF (FIELD IS REQUIRED AND VALUE IS EMPTY) OR (`numeratorDefinition` IS NOT EMPTY BUT `denominatorDefinition` IS EMPTY)
        if (
          (eachItem.isRequiredDenominatorDefinitionField && _.isEmpty(denominatorDefinition)) ||
          (!_.isEmpty(numeratorDefinition) && _.isEmpty(denominatorDefinition))
        ) {
          // errMsgDenominatorDefinitionField ="Denominator Definition is required";
          errMsgDenominatorDefinitionField = i18n.t("DenominatorDefReqd");
        }
        // `denominatorDefinition` IS NOT EMPTY BUT `numeratorDefinition` IS EMPTY
        if (!_.isEmpty(denominatorDefinition) && _.isEmpty(numeratorDefinition)) {
          // errMsgNumeratorDefinitionField = "Numerator Definition is required";
          errMsgNumeratorDefinitionField = i18n.t("NumeratorDefReqd");
        }
      }

      if (!_.isEmpty(denominatorDefinition) && !validateMetricDefinitionFieldValue(denominatorDefinition)) {
        errMsgDenominatorDefinitionField = this.ERR_MSG_METRIC_DEF_INVALID_VALUE;
      }
      eachItem.errMsgNumeratorDefinitionField = errMsgNumeratorDefinitionField;
      eachItem.errMsgDenominatorDefinitionField = errMsgDenominatorDefinitionField;

      // IF THERE WAS SOME ERROR IN DYNAMICALLY ADDED METRIC DEFINITIONS THEN SET THE FORM TO INVALID
      if (!_.isEmpty(errMsgNumeratorDefinitionField) || !_.isEmpty(errMsgDenominatorDefinitionField)) {
        formIsValid = false;
      }

      eachItem.numeratorDefinition = numeratorDefinition;
      eachItem.denominatorDefinition = denominatorDefinition;
      return eachItem;
    });

    // CHECK FOR DUPLICATE METRIC DEFINITIONS
    let areAnyDuplicateMetricDefinitions = false;
    _.forEach(modifiedMetricDefinitions, function(eachMetDef, index) {
      const modifiedNumeratorDefinition = String(eachMetDef.numeratorDefinition).trim();
      const modifiedDenominatorDefinition = String(eachMetDef.denominatorDefinition).trim();

      // FOR ALL THE SELECTED RADIO OPTIONS "Numerator Definition", CHECK FOR DUPLICATE VALUES
      const eqNumField = _.find(modifiedMetricDefinitions, function(eachItem, indx) {
        const foundNumeratorDefinition = String(eachItem.numeratorDefinition).trim();

        if (
          indx > index &&
          eachMetDef.selectedDefinition === "numeratorDefinition" &&
          eachItem.selectedDefinition === "numeratorDefinition" &&
          !_.isEmpty(modifiedNumeratorDefinition) &&
          !_.isEmpty(foundNumeratorDefinition) &&
          modifiedNumeratorDefinition === foundNumeratorDefinition
        ) {
          return true;
        }
      });
      if (eqNumField) {
        areAnyDuplicateMetricDefinitions = true;
      }

      // FOR ALL THE SELECTED RADIO OPTIONS "Numerator & Denominator Definition", CHECK FOR DUPLICATE VALUES
      const eqNumDenoField = _.find(modifiedMetricDefinitions, function(eachItem, indx) {
        const foundNumeratorDefinition = String(eachItem.numeratorDefinition).trim();
        const foundDenominatorDefinition = String(eachItem.denominatorDefinition).trim();

        if (
          indx > index &&
          eachMetDef.selectedDefinition === "numeratorAndDenominatorDefinition" &&
          eachItem.selectedDefinition === "numeratorAndDenominatorDefinition" &&
          !_.isEmpty(modifiedNumeratorDefinition) &&
          !_.isEmpty(foundNumeratorDefinition) &&
          modifiedNumeratorDefinition === foundNumeratorDefinition &&
          !_.isEmpty(modifiedDenominatorDefinition) &&
          !_.isEmpty(foundDenominatorDefinition) &&
          modifiedDenominatorDefinition === foundDenominatorDefinition
        ) {
          return true;
        }
      });
      if (eqNumDenoField) {
        areAnyDuplicateMetricDefinitions = true;
      }
    });
    // IF THERE WERE ANY DUPLICATE METRIC DEFINITIONS THEN SET THE FORM VALID STATUS TO FALSE
    if (areAnyDuplicateMetricDefinitions) {
      formIsValid = false;
    }

    this.setState({
      errors: errors,
      metricDefinitions: modifiedMetricDefinitions,
      areAnyDuplicateMetricDefinitions,
      areAnyDuplicateIndustry,
      minCrossIndustryCheckFailed,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  onNameChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const diverseData = this.state.diverseData;
    diverseData.MetricName = event.target.value;
    this.setState({
      diverseData: diverseData,
    });
  }

  onSequenceChange(event) {
    if(this.state.flag){
      this.setState({
        seqValue:this.state.diverseData.MetricSeq,
        flag : false,
        checkFlag : true,
      });
    }
    let errors = {};
    this.setState({
      errors: errors,
    });
    const diverseData = this.state.diverseData;
    diverseData.MetricSeq = event.target.value;
    this.setState({
      diverseData: diverseData,
    });
  }

  onDescriptionChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const diverseData = this.state.diverseData;
    diverseData.KPIDesp = event.target.value;
    this.setState({
      diverseData: diverseData,
    });
  }

  onUnitChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const diverseData = this.state.diverseData;
    diverseData.MetricUnit = event.target.options[event.target.selectedIndex].value;
    this.setState(
      {
        diverseData: diverseData,
      },
      () => {
        this.toggleRequiredMetricDefinitionFields();
        this.clearMetricDefinitionFieldsErrors();
      }
    );
  }

  handleSave(e) {
    debugger;
    const { metricDefinitions, benchmarkPair } = this.state;

    if (this.handleValidation()) {
      let diverseData = this.state.diverseData;
      diverseData.SOCategoryID = this.props.SOCategoryID;

      let filterProcess = this.props.filterDetails.process;

      // let SUCC_MSG = "Metric Template added successfully";
      let SUCC_MSG = i18n.t("SUCC_MSG1");

      // let ERR_MSG = "There was some error while adding Metric Template";
      let ERR_MSG = i18n.t("ERR_MSG1");

      if (!_.isEmpty(this.props.editDiverse)) {
        // SUCC_MSG = "Metric Template updated successfully";
        SUCC_MSG = i18n.t("SUCC_MSG2");

        // ERR_MSG = "There was some error while updating Metric Template";
        ERR_MSG = i18n.t("ERR_MSG2");
      }

      // ADD MetricDefinitions to the request parameters
      const metricDefinitionsForRequest = _.map(metricDefinitions, (eachItem) => {
        const metricDef = {
          NumeratorDescription: eachItem.numeratorDefinition,
          DenominatorDescription:
            eachItem.selectedDefinition === "numeratorDefinition" ? "" : eachItem.denominatorDefinition,
          IsEnabled: eachItem.isEnabled === "yes" ? "true" : "false",
        };
        if (!_.isEmpty(String(_.get(eachItem, "KPIMetricDefinatoinID", "")))) {
          // IF THE METRIC DEFINITION IS BEING EDITED
          metricDef.KPIMetricDefinatoinID = eachItem.KPIMetricDefinatoinID;
        }
        return metricDef;
      });

      const benchmarkPairForRequest = _.map(benchmarkPair, (eachItem) => {
        const benchmark = {
          Attribute1Value: eachItem.Attribute1Value,
          Attribute2Value: eachItem.Attribute2Value,
          IndustryID: eachItem.Industry === "null" ? null : eachItem.Industry,
        };
        return benchmark;
      });

      diverseData.MetricDefination = metricDefinitionsForRequest;
      diverseData.MetricBenchmarkList = benchmarkPairForRequest;

      this.setState({ isAPILoading: true });
      axiosInstance
        .post(`${LocalApiBaseUrl}Master/PostKpiMetricMasterTemplate`, diverseData, {
          params: {
            offeringId: filterProcess,
            LanguageID: store.getState().CurrentFilter.languageData.id,
          },
        })
        .then((response) => {
          store.dispatch({
            type: "ADD_DIVERSE_MASTER",
            payload: response.data.kPIdata,
          });
          this.setState({ isAPILoading: false }, () => {
            alert(SUCC_MSG);
            this.handleHide();
          });
        })
        .catch((error) => {
          this.setState({ isAPILoading: false }, () => {
            console.log(ERR_MSG, error);
             DisplayMessage();
          });
          trycatchAlertPopup(error);

        });
    }
  }

  handleHide(e) {
    this.setState({ 
      show: false,
      flag : true
     });
     if(this.state.checkFlag){
      this.state.diverseData.MetricSeq = this.state.seqValue
      this.setState({
        checkFlag : false
      })
     }
     
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
  }
  handleRejectHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }
  passToConfirmation(data) {
    this.props.enteredData(data.PoNumber);
  }

  // EXECUTED WHEN THE "LeadingPractice" FIELD VALUE WOULD BE CHANGED
  handleChangeLeadingPractice = (e) => {
    const LeadingPractice = e.target.value;
    this.setState((prevState) => ({
      diverseData: {
        ...prevState.diverseData,
        LeadingPractice,
      },
    }));
  };

  /**
   * When the unit of metric is "%" definition of both numerator & denominator should be mandatory.
   * Numerator and Denominator fields are mandatory fields for BO, II & DD page. For  H&M & WO page keep them optional.
   * So Disable "numerator" (radio) selection based on above condition.
   */
  toggleRequiredMetricDefinitionFields = () => {
    const { defaultIsRequiredMetricDefinitionFields } = this.props;
    const { diverseData, metricDefinitions } = this.state;
    const MetricUnit = diverseData.MetricUnit;

    let areRequiredMetricDefinitionFields = defaultIsRequiredMetricDefinitionFields;

    let isDisabledNumeratorDefinitionField = false;
    if (MetricUnit.trim() === _.trim("%")) {
      areRequiredMetricDefinitionFields = true;
      isDisabledNumeratorDefinitionField = true;
    }

    // FOR ALL THE DYNAMICALLY ADDED METRIC DEFINITIONS, TOGGLE DISABLE OF THE "NUMERATOR RADIO" FIELD
    const modifiedMetricDefinitions = _.map(metricDefinitions, (eachItem, index) => {
      eachItem.isDisabledNumeratorRadioField = isDisabledNumeratorDefinitionField;
      // IF THE "NUMERATOR RADIO" FIELD IS TO BE DISABLED, THEN SET SELECTED "NUMERATOR AND DENOMINATOR RADIO" FIELD
      if (isDisabledNumeratorDefinitionField) {
        eachItem.selectedDefinition = "numeratorAndDenominatorDefinition";
      }
      eachItem.isRequiredNumeratorDefinitionField = areRequiredMetricDefinitionFields;
      eachItem.isRequiredDenominatorDefinitionField = areRequiredMetricDefinitionFields;
      return {
        ...eachItem,
      };
    });
    this.setState({
      metricDefinitions: modifiedMetricDefinitions,
    });
  };

  // DYNAMICALLY ADDS A NEW ROW FOR ADDING METRIC DEFINITION
  addMetricDefinitionRow = () => {
    const { defaultIsRequiredMetricDefinitionFields } = this.props;
    const { metricDefinitions } = this.state;
    const clonedSingleMetricDefinition = _.cloneDeep(singleMetricDefinition);

    clonedSingleMetricDefinition.isRequiredNumeratorDefinitionField = defaultIsRequiredMetricDefinitionFields;
    clonedSingleMetricDefinition.isRequiredDenominatorDefinitionField = defaultIsRequiredMetricDefinitionFields;

    const currentMetricDefinitions = _.cloneDeep(metricDefinitions);
    currentMetricDefinitions.push(clonedSingleMetricDefinition);
    this.setState(
      {
        metricDefinitions: currentMetricDefinitions,
      },
      () => {
        this.toggleRequiredMetricDefinitionFields();
      }
    );
  };

  // REMOVES THE SELECTED DYNAMICALLY ADDED METRIC DEFINITION SECTION
  removeMetricDefinitionRow = (rowIndex) => {
    const { metricDefinitions } = this.state;
    const remainingMetricDefinitions = _.filter(metricDefinitions, (eachItem, index) => {
      return index !== rowIndex;
    });
    this.setState({
      metricDefinitions: remainingMetricDefinitions,
    });
  };

  // EXECUTES WHEN THE ANY OF THE FIELD'S VALUE OF DYNAMICALLY ADDED METRIC DEFINITION WOULD BE CHANGED
  handleChangeMetricDefinitionField = (e, rowIndex, fieldName) => {
    const { metricDefinitions } = this.state;
    let fieldValue = e.target.value;
    if (fieldName === "isEnabled") {
      fieldValue = e.target.checked ? "yes" : "";
    }
    const modifiedMetricDefinitions = _.map(metricDefinitions, (eachItem, index) => {
      if (index === rowIndex) {
        eachItem[fieldName] = fieldValue;
      }
      return {
        ...eachItem,
      };
    });
    this.setState({
      metricDefinitions: modifiedMetricDefinitions,
    });
  };

  // REMOVES THE ERRORS OF DYNAMICALLY ADDED METRIC DEFINITIONS
  clearMetricDefinitionFieldsErrors = () => {
    const { metricDefinitions } = this.state;
    const modifiedMetricDefinitions = _.map(metricDefinitions, (eachItem) => {
      return {
        ...eachItem,
        errMsgNumeratorDefinitionField: "",
        errMsgDenominatorDefinitionField: "",
      };
    });

    this.setState({
      metricDefinitions: modifiedMetricDefinitions,
    });
  };

  // DYNAMICALLY ADDS A NEW ROW FOR ADDING BENCHMARK
  addBenchMarksRow = () => {
    debugger;
    const { benchmarkPair } = this.state;
    const clonedSingleBenchmarkPair = _.cloneDeep(singleBenchmarkPair);

    const currentBenchmarkPair = _.cloneDeep(benchmarkPair);
    currentBenchmarkPair.push(clonedSingleBenchmarkPair);
    this.setState({
      benchmarkPair: currentBenchmarkPair,
    });
  };

  // REMOVES THE SELECTED DYNAMICALLY ADDED BENCHMARK SECTION
  removeBenchMarksRow = (rowIndex) => {
    const { benchmarkPair } = this.state;
    const remainingBenchmarkPair = _.filter(benchmarkPair, (eachItem, index) => {
      return index !== rowIndex;
    });
    this.setState({
      benchmarkPair: remainingBenchmarkPair,
    });
  };

  // EXECUTES WHEN ANY OF THE FIELD'S VALUE OF DYNAMICALLY ADDED BENCHMARK WOULD BE CHANGED
  handleChangeBenchMarksField = (e, rowIndex, fieldName) => {
    const { benchmarkPair } = this.state;
    let fieldValue = e.target.value;

    const modifiedBenchmarkPair = _.map(benchmarkPair, (eachItem, index) => {
      if (index === rowIndex) {
        eachItem[fieldName] = fieldValue;
      }
      return {
        ...eachItem,
      };
    });
    this.setState({
      benchmarkPair: modifiedBenchmarkPair,
    });
  };

  // INDUSTRY DROP DOWN FOR OG="OTHERS"
  getIndustries() {
    axiosInstance.get(`${LocalApiBaseUrl}Industry/GetIndustry`).then((response) => {
      let IndustrGroups = response.data.filter((data) => data.IsActive === true && data.Status === "Approved");

      let IndustryOptions = IndustrGroups.map((x) => (
        <option key={x.IndustryId} value={x.IndustryId}>
          {x.Industry}
        </option>
      ));

      this.setState({
        IndustryOptions: IndustryOptions,
      });
    })
    .catch((error) => {
      trycatchAlertPopup(error);
    });

  }

  render() {
    const { defaultIsRequiredLeadingPractice } = this.props;
    const {
      metricDefinitions,
      areAnyDuplicateMetricDefinitions,
      areAnyDuplicateIndustry,
      minCrossIndustryCheckFailed,
      diverseData,
      isAPILoading,
    } = this.state;
    const kpiData = diverseData;
    let isScreenInEditMode = false;
    if (!_.isEmpty(this.props.editDiverse)) {
      isScreenInEditMode = true;
    }

    return (
      <>
        {/* <Header /> */}
        {/* IF API IS LOADING THEN SHOW THE LOADER */}
        {isAPILoading && <LoadingOverlay 
        fadeSpeed={0} 
        spinner={<SynopsLoader />}
        active 
        />}
        <Modal
          show={
            this.props.roledata != undefined &&
            (this.props.roledata.includes("Edit") || this.props.roledata.includes("Add"))
          }
          onHide={this.handleHide}
          className="modal fade master-template-configurator"
          size="medium"
          id="dealpopup"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title" id="dealprofile">
              <span>
                {i18n.t("Metric_Template")} - {i18n.t("DiverseData-Performance_against_Benchmarks")}
              </span>
            </h4>
          </Modal.Header>
          <Modal.Body>
            <div className="form-section">
              <h3>
                <span>
                  {i18n.t("Insight_Data")}
                  {" - "}
                  {this.state.diverseData.MetricName}
                </span>
              </h3>

              <div className="row">
                <div className="form-group col-lg-6">
                  <label>
                    {i18n.t("Name")} <span className="required">*</span>
                  </label>

                  <input
                    type="text"
                    maxLength="100"
                    className="form-control"
                    onChange={this.onNameChange}
                    value={this.state.diverseData.MetricName}
                    disabled={isScreenInEditMode && store.getState().CurrentFilter.languageData.id === 1}
                  />
                  <span className="errorMsg">{this.state.errors.MetricName}</span>
                </div>

                <div className="form-group col-lg-6">
                  <label>
                    {i18n.t("Unit_of_Measure")} <span className="required">*</span>
                  </label>
                  {isScreenInEditMode ? (
                    <input
                      type="text"
                      maxLength="100"
                      className="form-control"
                      value={this.state.diverseData.MetricUnit}
                      disabled={isScreenInEditMode}
                    />
                  ) : (
                    <select
                      className="form-control"
                      value={this.state.diverseData.MetricUnit}
                      onChange={this.onUnitChange}
                      disabled={isScreenInEditMode}
                    >
                      <option value="">{i18n.t("Please_Select_Unit")}</option>
                      {this.state.unitsData.map((each) => {
                        return <option value={each.MetricUnit_En}>{each.MetricUnit}</option>;
                      })}
                    </select>
                  )}
                  <div className="errorMsg">{this.state.errors.MetricUnit}</div>
                </div>
         
		      </div>

              <div className="row">
                <div className="form-group col-lg-6">
                  <label>
                    {i18n.t("Sequence")} <span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={this.onSequenceChange}
                    value={this.state.diverseData.MetricSeq}
                  />
                  <div className="errorMsg">{this.state.errors.MetricSeq}</div>
                  <div className="errorMsg">{this.state.errors.MetricSeqRange}</div>
                </div>

                <div className="form-group col-lg-6">
                  <label>
                    {i18n.t("Leading_Practice")}
                    {defaultIsRequiredLeadingPractice && <span className="required"> *</span>}
                    &nbsp;
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip id="tooltip-top">{i18n.t("addBOMess2")}</Tooltip>}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </OverlayTrigger>
                  </label>
                  <select
                    value={kpiData.LeadingPractice}
                    disabled={store.getState().CurrentFilter.languageData.id !== 1}
                    onChange={(e) => this.handleChangeLeadingPractice(e)}
                    className="form-control"
                  >
                    <option value="">{i18n.t("Select_Leading_Practice")}</option>
                    <option value={i18n.t("Maximum")}>{i18n.t("Maximum")}</option>
                    <option value={i18n.t("Minimum")}>{i18n.t("Minimum")}</option>
                  </select>
                  <div className="errorMsg">{this.state.errors.LeadingPractice}</div>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-lg-12">
                  <label>{i18n.t("Description")} </label>
                  <input
                    type="text"
                    maxLength="255"
                    className="form-control"
                    onChange={this.onDescriptionChange}
                    value={this.state.diverseData.KPIDesp}
                  />
                </div>
              </div>
              {/* START - INDUSTRY BENCHMARKING */}

              {areAnyDuplicateIndustry && (
                <div className="row">
                  <div className="col">
                    <Alert variant="danger">{i18n.t("DuplicateIndustryAlert")}</Alert>
                  </div>
                </div>
              )}
              {minCrossIndustryCheckFailed && (
                <div className="row">
                  <div className="col">
                    <Alert variant="danger">{i18n.t("CrossIndustryAlert")}</Alert>
                  </div>
                </div>
              )}
              <div className="title-with-icon mt-3 mb-1">
                <h3 className="border-0">{i18n.t("IndustryBenchmarks")}</h3>
                <div className="list-icon">
                  {store.getState().CurrentFilter.languageData.id === 1 ? (
                    <span id="rock" onClick={() => this.addBenchMarksRow()}>
                      <FontAwesomeIcon icon={faPlus} />
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div>
                {_.map(this.state.benchmarkPair, (eachBenchmark, index) => {
                  return (
                    <div className="mb-3" key={`definition_${index}`}>
                      <div className="row delete-iconcss">
                        <div className="form-group col-lg-6">
                          <label>
                            {i18n.t("Industry")}
                            <span className="required"> *</span>
                          </label>

                          <select
                            value={eachBenchmark.Industry}
                            onChange={(e) => this.handleChangeBenchMarksField(e, index, "Industry")}
                            disabled={store.getState().CurrentFilter.languageData.id !== 1}
                            className="form-control"
                          >
                            <option value="">{i18n.t("Select_Industry")}</option>
                            <option value="null">{i18n.t("Cross_Industry")}</option>
                            {this.state.IndustryOptions}
                          </select>
                          <div className="errorMsg">{eachBenchmark.errMsgIndustryField}</div>
                        </div>
                        <span>
                          {index !== 0 && store.getState().CurrentFilter.languageData.id === 1 && (
                            <>
                              <span className="del-row-icon list-icon" onClick={() => this.removeBenchMarksRow(index)}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </span>
                            </>
                          )}
                        </span>
                      </div>

                      <div className="row">
                        <div className="form-group col-lg-6">
                          <label>
                            {this.props.attributeLabels.AttributeName1}
                            <span className="required"> *</span>
                          </label>
                          <input
                            type="text"
                            value={eachBenchmark.Attribute1Value}
                            onChange={(e) => this.handleChangeBenchMarksField(e, index, "Attribute1Value")}
                            className="form-control"
                            disabled={store.getState().CurrentFilter.languageData.id !== 1}
                          />
                          <div className="errorMsg">{eachBenchmark.errMsgAttribute1ValueField}</div>
                        </div>
                        <div className="form-group col-lg-6">
                          <label>
                            {this.props.attributeLabels.AttributeName2}
                            <span className="required"> *</span>
                          </label>
                          <input
                            type="text"
                            value={eachBenchmark.Attribute2Value}
                            onChange={(e) => this.handleChangeBenchMarksField(e, index, "Attribute2Value")}
                            className="form-control"
                            disabled={store.getState().CurrentFilter.languageData.id !== 1}
                          />
                          <div className="errorMsg">{eachBenchmark.errMsgAttribute2ValueField}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* END - INDUSTRY BENCHMARKING */}

              {/* START - METRIC DEFINITION */}
              {areAnyDuplicateMetricDefinitions && (
                <div className="row">
                  <div className="col">
                    <Alert variant="danger">{i18n.t("DuplicateMetricDefinitionAlert")}</Alert>
                  </div>
                </div>
              )}

              <div className="title-with-icon mt-3 mb-1">
                <h3 className="border-0">
                  {i18n.t("Metric_Definition")}{" "}
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{i18n.t("addBOMess3")}</Tooltip>}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </OverlayTrigger>
                </h3>
                <div className="list-icon">
                  {store.getState().CurrentFilter.languageData.id === 1 ? (
                    <span id="rock" onClick={() => this.addMetricDefinitionRow()}>
                      <FontAwesomeIcon icon={faPlus} />
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div>
                {_.map(metricDefinitions, (eachMetricDefinition, index) => {
                  const selectedDefinition = eachMetricDefinition.selectedDefinition;
                  const isVisibleDenominatorDefinition = selectedDefinition === "numeratorAndDenominatorDefinition";
                  return (
                    <div className="mb-3" key={`definition_${index}`}>
                      <div className="row">
                        <div className="form-group col-lg-12 mb-1">
                          <label className="form-check mr-5">
                            <input
                              type="radio"
                              name={`definition_${index}`}
                              value="numeratorDefinition"
                              onChange={(e) => this.handleChangeMetricDefinitionField(e, index, "selectedDefinition")}
                              checked={selectedDefinition === "numeratorDefinition"}
                              disabled={
                                eachMetricDefinition.isDisabledNumeratorRadioField ||
                                store.getState().CurrentFilter.languageData.id !== 1
                              }
                              className="form-check-input"
                            />
                            <span className="checkmark" />
                            <span className="form-check-label">{i18n.t("Numerator_Definition")}</span>
                          </label>

                          <label className="form-check">
                            <input
                              type="radio"
                              name={`definition_${index}`}
                              value="numeratorAndDenominatorDefinition"
                              onChange={(e) => this.handleChangeMetricDefinitionField(e, index, "selectedDefinition")}
                              checked={selectedDefinition === "numeratorAndDenominatorDefinition"}
                              disabled={store.getState().CurrentFilter.languageData.id !== 1}
                              className="form-check-input"
                            />
                            <span className="checkmark" />
                            <span className="form-check-label">{i18n.t("Numerator&Denominator_Definition")}</span>
                          </label>

                          {index !== 0 && (
                            <>
                              {_.isNil(_.get(eachMetricDefinition, "KPIMetricDefinatoinID", null)) &&
                                (store.getState().CurrentFilter.languageData.id === 1 ? (
                                  <span
                                    className="del-row-icon list-icon"
                                    onClick={() => this.removeMetricDefinitionRow(index)}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </span>
                                ) : (
                                  ""
                                ))}
                              {store.getState().CurrentFilter.languageData.id === 1 && (
                                <div className="toggle-group">
                                  <input
                                    type="checkbox"
                                    value="yes"
                                    checked={String(eachMetricDefinition.isEnabled) === "yes"}
                                    onChange={(e) => this.handleChangeMetricDefinitionField(e, index, "isEnabled")}
                                    id={`isEnabled_${index}`}
                                  />
                                  <label htmlFor={`isEnabled_${index}`} />
                                  <div className="onoffswitch" aria-hidden="true">
                                    <div className="onoffswitch-label">
                                      <div className="onoffswitch-inner" />
                                      <div className="onoffswitch-switch" />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div
                          className={classNames(
                            "form-group",
                            { "col-lg-6": isVisibleDenominatorDefinition },
                            { col: !isVisibleDenominatorDefinition }
                          )}
                        >
                          <label>
                            {i18n.t("Numerator_Definition")}
                            {eachMetricDefinition.isRequiredNumeratorDefinitionField && (
                              <span className="required"> *</span>
                            )}
                          </label>
                          <input
                            type="text"
                            value={eachMetricDefinition.numeratorDefinition}
                            onChange={(e) => this.handleChangeMetricDefinitionField(e, index, "numeratorDefinition")}
                            className="form-control"
                            maxLength={maxLengthMetricDefinitionFields}
                          />
                          <div className="errorMsg">{eachMetricDefinition.errMsgNumeratorDefinitionField}</div>
                        </div>

                        {isVisibleDenominatorDefinition && (
                          <div className="form-group col-lg-6">
                            <label>
                              {i18n.t("Denominator_Definition")}
                              {eachMetricDefinition.isRequiredDenominatorDefinitionField && (
                                <span className="required"> *</span>
                              )}
                            </label>
                            <input
                              type="text"
                              value={eachMetricDefinition.denominatorDefinition}
                              onChange={(e) =>
                                this.handleChangeMetricDefinitionField(e, index, "denominatorDefinition")
                              }
                              className="form-control"
                              maxLength={maxLengthMetricDefinitionFields}
                            />
                            <div className="errorMsg">{eachMetricDefinition.errMsgDenominatorDefinitionField}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* END - METRIC DEFINITION */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-btn">
              <button onClick={this.handleHide} className="btn btn-default btn-sm mr-2">
                {i18n.t("Cancel")}
              </button>

              <input
                type="Button"
                className="btn btn-primary btn-sm"
                onClick={() => this.handleSave()}
                value={isScreenInEditMode ? i18n.t("Update") : i18n.t("Create")}
              />
            </div>
          </Modal.Footer>
        </Modal>
        {this.state.viewmore ? (
          <KpiPopUp
            SOCategoryID={this.props.SOCategoryID}
            show={true}
            onClose={this.onClose.bind(this)}
            filterDetails={this.props.filterDetails}
          />
        ) : null}
      </>
    );
  }
}

DiversePopUp.defaultProps = {
  screenName: "DiverseData",
  // Keep "Leading Practice" as non-mandatory for H&M and WO, mandatory for BO, II & DD
  defaultIsRequiredLeadingPractice: true,
  defaultIsRequiredMetricDefinitionFields: true,
};
const mapStateToProps = (state) => {
  return {
    languageData: state.CurrentFilter.languageData,
    unitsData: state.BOMasterReducers.unitsData,
  };
};
export default connect(mapStateToProps)(DiversePopUp);
