import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import i18n from 'i18next';
import { store } from '../reducers/configureStore';

// IMPORT CUSTOM COMPONENTS/FUNCTIONS
import { LocalApiBaseUrl, ROUTE_CONSTANTS } from './Constant';
import axiosInstance from './interceptor';
import { trycatchAlertPopup } from './Constant';
import { getInitialsFromUserEID, getDayStatusFromTimestamp } from './commonFunctions';
import CloseIcon from '../Images/close-icon.svg';

// IMPORT IMAGES/STYLES
import Exclamation from '../Images/Exclamation.svg';
import parse from 'react-html-parser';

class Notification extends Component {
  constructor(props) {

    super(props);

    this.state = {
      notifications: [],
      show: false,
      ShowNoNewMsg: false,
      showMore: true,
      isLoading: false,
    };
  }

  componentDidMount() {
    const { notificationData, show } = this.props;
    if (notificationData && notificationData.notifications) {
      this.setState({
        notifications: notificationData.notifications,
        show,
      });
    }
    // this.getNotifications(false, false);
  }

  componentDidUpdate(prevProps) {
    const { show, notificationData } = this.props;
    const { notifications } = this.state;

    if (prevProps.show !== show) {
      this.setState(
        {
          show,
        },
        () => {
          // if (show) {
          //   this.getNotifications();
          // }
        },
      );
    }
    if (notificationData && notificationData.notifications && notifications !== notificationData.notifications) {
      this.setState({
        notifications: notificationData.notifications,
        ShowNoNewMsg: notificationData.notifications && !notificationData.notifications.length,
      });
    }
  }

  componentWillUnmount() {
    this.getNotifications(false, true);
  }

  getNotifications(showMore, isRead) {
    const { languageData } = this.props;
    if (showMore) {
      this.setState({ isLoading: !!showMore });
    }
    axiosInstance
      .get(`${LocalApiBaseUrl}Notification/GetNotifications?IsRead=${isRead}${showMore ? '&IsMore=true' : ''}`, {
        params: {
          LanguageID: languageData.id,
        },
      })
      .then((response) => {
        const responseData = response.data;
        const newNotifiactionList = _.filter(responseData, ['IsRead', false]);

        const notifications = _.map(responseData, (eachNotification) => ({
          ...eachNotification,
          receivedDayText: getDayStatusFromTimestamp(eachNotification.UpdatedTS),
        }));
        const payloadData = { notifications, newNotificationsData: { hasNewNotification: !!newNotifiactionList.length, notificationCount: newNotifiactionList.length } };
        store.dispatch({
          type: 'NOTIFICATION_DATA',
          payload: payloadData,
        });
        if (showMore) {
          this.setState({ isLoading: !showMore });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        this.setState(
          {
            notifications: [],
            ShowNoNewMsg: true,
          },
        );
        if (showMore) {
          this.setState({ isLoading: !showMore });
        }
      });
  }

  handleClose = () => {
    this.setState({
      notifications: [],
      show: false,
      ShowNoNewMsg: false,
    });
    // this.getNotifications(false, true);
    this.props.onHide();
  };

  // WHEN BAT NOTIFICATION IS CLICKED THEN REDIRECT TO "BAT ACCESS MANAGEMENT" SCREEN
  handleClickViewBATAssessmentNotificationRequest = () => {
    const { history } = this.props;
    const location = {
      pathname: `/${ROUTE_CONSTANTS.BAT_ACCESS_MANAGEMENT}`,
      state: { activeSection: 'accessRequest' },
    };
    history.push(location);
    this.handleClose();
  };

  showMoreNotifications = () => {
    this.setState({
      showMore: false,
    });
    this.getNotifications(true, false);
  }

  render() {
    const {
      show, ShowNoNewMsg, showMore, notifications, isLoading,
    } = this.state;

    const parseBATAssessmentNotification = (notificationContent) => {
      const contentArr = _.map(notificationContent.split(/\r?\n/g), (eachItem) => _.replace(_.replace(eachItem, '<p>', ''), '</p>', ''));
      const countSharedUsersToDisplay = 3;
      // THE SECOND PARAGRAPH OF THE CONTENT WOULD CONTAIN THE COMMA SEPARATED ENTERPRISE IDs OF THE USERS
      const sharedUsersArr = contentArr[1].split(',');

      const sharedUsersArrForDisplay = _.size(sharedUsersArr) > countSharedUsersToDisplay
        ? _.take(sharedUsersArr, countSharedUsersToDisplay)
        : sharedUsersArr;



      return (
        <>
          <p>{contentArr[0]}</p>

          <div className="assessment-usericons">
            {_.map(sharedUsersArrForDisplay, (eachSharedUserEID) => (
              <span className="assessment-tags">
                <span>{getInitialsFromUserEID(eachSharedUserEID)}</span>
              </span>
            ))}
            {_.size(sharedUsersArr) > countSharedUsersToDisplay && (
              <span className="assessment-excess">
                +
                {_.size(sharedUsersArr) - countSharedUsersToDisplay}
                {' '}
                more
              </span>
            )}
          </div>

          {/* THE NOTIFICATION CONTENT WOULD THREE PARAGRAPHS IF IT IS A "RE-SHARE/BULK RE-SHARE" REQUEST NOTIFICATION AND THE THIRD PARAGRAPH WOULD CONTAIN THE "View Request" TEXT */}
          {!_.isEmpty(_.get(contentArr, '2', '')) && (
            <span
              className="assessment-notificationlink"
              onClick={() => this.handleClickViewBATAssessmentNotificationRequest()}
            >
              {contentArr[2]}
            </span>
          )}
        </>
      );
    };

    return (
      <>
        {show && (
          <Modal
            show={show}
            onHide={this.handleClose}
            className="notification-modal assessment-notification-modal"
            animation={false}
          >
            <Modal.Body>
              <button
                type="button"
                className="close"
                aria-label="Close"
                data-dismiss="modal"
                onClick={this.handleClose}
              >
                <img src={CloseIcon} />
              </button>
              {ShowNoNewMsg ? (
                <div className="empty-notification">
                  <span>
                    <img src={Exclamation} alt="" width="90" height="59" />
                  </span>
                  <p>{i18n.t('No Notifications!')}</p>
                </div>
              ) : (
                <div className="notifications-list">
                  <div className="notifications-list assessment-notifylinks">
                    <h6>
                      {i18n.t('Notifications')}
                    </h6>
                    <ul>
                      {notifications.map((eachNotification, index) => {
                        const notificationDescription = eachNotification.Description;
                        // BAT ASSESSMENT NOTIFICATION WOULD ALWAYS CONTAIN THE `UserName` AS THEY ARE MEANT TO BE DISPLAYED TO THE CURRENT USER. ALSO THE NOTIFICATIONS THAT WOULD SPECIAL FORMATTING (NEED TO BE PARSED) WOULD ALWAYS CONTAIN MORE THAN ONE `<p>` IN THE DESCRIPTION CONTENT
                        const isBATAssessmentNotification = !_.isEmpty(eachNotification.UserName)
                            && _.size(notificationDescription.split(/\r?\n/g)) >= 2;
                        if ((showMore && index <= 5) || !showMore) {
                          return (
                            <li className={eachNotification.IsRead ? '' : 'new-notification'} key={eachNotification.ID}>
                              <div className="today-notification">
                                <span>{eachNotification.receivedDayText}</span>
                              </div>
                              <h4>
                                {eachNotification.Link ? <a href={eachNotification.Link} target={eachNotification.LinkType && eachNotification.LinkType === 'I' ? '_self' : '_blank'}>{eachNotification.Header}</a> : eachNotification.Header}
                                {!eachNotification.IsRead && <span>New</span>}
                                {/* <sup>{eachNotification.IsRead ? "" : "New"}</sup> */}
                              </h4>
                              {isBATAssessmentNotification ? (
                                parseBATAssessmentNotification(notificationDescription)
                              ) : ( eachNotification.Description && typeof eachNotification.Description === 'string' ?
                                <div>{parse(eachNotification.Description)} </div> : null
                              )}
                            </li>
                          );
                        }
                      })}
                    </ul>
                    {isLoading
                    && <p style={{ textAlign: 'center' }}>Loading...</p>}

                    {showMore && <p className="seeAll pb-0" onClick={this.showMoreNotifications}><span>{i18n.t('See_All')}</span></p> }
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  languageData: state.CurrentFilter.languageData,
  clientData: state.CurrentFilter.ClientData,
  notificationData: { ...state.NotificationReducer },
});

export default withRouter(
  connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true },
  )(Notification),
);
