import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import axiosInstance from "../../Shared/interceptor";
import { store } from "../../reducers/configureStore";
import { Tabs, Tab } from "react-bootstrap";
import _ from "lodash";
import moment from "moment";
import DOMPurify from 'dompurify';
import i18n from "i18next";
import "./HelpFeatures.css";
import ReactPlayer from "react-player";
import parse from 'react-html-parser';

const ExtractSrcLink = (Link) => {
  const DocsearchTerm = 'src="';
  const VideoImagesearchTerm = "?";
  const Extensions = [".mp4", ".mov", ".webm", ".mkv", ".ogv", ".3g2", ".jpeg", ".jpg", ".giff", ".png"];
  let isDoc;
  if(Link) 
  {
  if (Extensions.some((v) => Link.includes(v))) {
    isDoc = false;
    return `${Link.split(VideoImagesearchTerm)[0]}`;
  } else {
    if (Link.includes("<iframe")) {
      isDoc = true;
      return `${Link.split(DocsearchTerm)[1].split('"')[0]}`;
    } else {
      return Link;
    }
  }
  }
  return "";
};
export default class HelpFeatureCardViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      minimize: false,
      currentData: {},
      DocLink: "",
      videoLink: "",
      ImageExtensions: [".jpeg", ".jpg", ".giff", ".png"],
      currentTab: "UserGuide",
      pdfLinkAdded: false,
      defaultTab: "",
    };
  }
  componentDidMount() {
    let pdf = false;
    let data = ExtractSrcLink(this.props.Data.DocLink);
    let d =
      this.props.Data.DocLink && this.props.Data.VideoLink
        ? "UserGuide"
        : this.props.Data.DocLink
        ? "UserGuide"
        : "Deal";
    if (data &&  (data.includes(".pdf") || this.props.Data.DocLink.includes(".pdf"))) {
      pdf = true;
    }
    this.setState({
      show: this.props.show,
      currentData: this.props.Data,
      videoLink: ExtractSrcLink(this.props.Data.VideoLink),
      DocLink: ExtractSrcLink(this.props.Data.DocLink),
      pdfLinkAdded: pdf,
      defaultTab: d,
    });
  }
  handleClose = () => {
    this.setState({
      show: false,
    });
    this.props.onClose();
  };
  minimize = () => {
    const { ImageExtensions, DocLink } = this.state;
    if (DocLink != "") {
      if (ImageExtensions.some((v) => DocLink.includes(v.toUpperCase()))) {
        document.getElementsByTagName("img")[0].className = "";
      } else {
        document.getElementsByTagName("iframe")[0].className = "";
      }
    }

    this.setState({ minshow: false });
  };
  Maximize = () => {
    const { ImageExtensions, DocLink } = this.state;
    if (DocLink != "") {
      if (ImageExtensions.some((v) => DocLink.includes(v.toUpperCase()))) {
        document.getElementsByClassName("maxminimage")[0].className = "fullScreen";
      } else {
        document.getElementsByTagName("iframe")[0].className = "fullScreen";
      }
    }

    this.setState({ minshow: true });
  };
  handleTabChange = (tab) => {
    this.setState({ currentTab: tab });
  };

  render() {
    const { show, currentData, videoLink, DocLink, currentTab, pdfLinkAdded, ImageExtensions, defaultTab } = this.state;
   

    const IframeDoc = `<iframe src=${DocLink} width="100%" 
  height="500px" frameborder="0"></iframe>`;

    return (
      <Modal show={show} onHide={this.handleClose} className="helpfeature-modal" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className="helpfeaturemodal-title">{currentData.TopicName} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="helpfeaturemodal-content">
            <p>{currentData.TopicDescription}</p>
            <div className="maximize-btn">
              {currentTab == "UserGuide" &&
                DocLink &&
                pdfLinkAdded == false &&
                !ImageExtensions.some((v) => DocLink.toLowerCase().includes(v.toLowerCase())) && (
                  <button type="button" className="download-dropdownbtn" onClick={this.Maximize}>
                    {i18n.t("Maximize")}
                    <i class="fas fa-compress-arrows-alt"></i>
                  </button>
                )}
            </div>
            <div className="helpfeaturemodal-tabs">
              {/* <div className="featuresview-morelink dropdown">
                            <a className="morelink-icon" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span> <img src={EllipsesGrey} width="30px" height="30px" /></span>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                <a class="dropdown-item"><i class="far fa-download download-icon-fix"></i> Download User Guide</a>
                                <a class="dropdown-item disabled-item"><i class="far fa-star"></i> Add to Collection</a>
                            </div>
                        </div> */}
              <Tabs
                defaultActiveKey={defaultTab}
                onSelect={(t) => {
                  this.handleTabChange(t);
                }}
              >
                {DocLink && (
                  <Tab eventKey="UserGuide" title="User Guide">
                    <div className="helpfeature-modalimage">
                      {/* <img src={helpfeature} alt="client title" /> */}
                      {this.state.minshow ? (
                        <div class="close-excel" onClick={this.minimize}>
                          <i class="far fa-window-minimize"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {DocLink &&
                        this.state.pdfLinkAdded == false &&
                        !ImageExtensions.some((v) => DocLink.toLowerCase().includes(v.toLowerCase())) && (
                          <div>{parse(IframeDoc)}</div>
                        )}
                      {DocLink &&
                        this.state.pdfLinkAdded == false &&
                        ImageExtensions.some((v) => DocLink.toLowerCase().includes(v.toLowerCase())) && (
                          <img className="maxminimage" src={DocLink} alt="client title" width="100%" height="500px" />
                        )}

                      {pdfLinkAdded ? (
                        <div className="pdflinkadded">
                          <a target="_blank" href={DocLink}>
                            {i18n.t("Click_here_UG")}
                          </a>
                        </div>
                      ) : null}
                    </div>
                  </Tab>
                )}

                {videoLink && (
                  <Tab eventKey="Deal" title="Demo">
                    <div className="helpfeature-modalimage">
                      {/* <img src={helpfeature} alt="client title" /> */}
                      {/* <div dangerouslySetInnerHTML={{ __html: IframeVideo }}></div> */}
                      <ReactPlayer width="100%" height="500px" controls url={videoLink} />
                    </div>
                  </Tab>
                )}
              </Tabs>
            </div>
            <div className="csupdated-info">
              <p>
                {i18n.t("Last_updated_on")}{" "}
                {moment
                  .utc(currentData.UpdatedTS)
                  .local()
                  .format("MMMM DD, YYYY")}
              </p>
            </div>
            <div className="uploaded-copyrights">
              {(DocLink || videoLink) && (
                <p>
                  {i18n.t("File_uploaded_by")} {currentData.CreatedBy}@accenture.com
                </p>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
