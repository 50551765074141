import React, { Component } from 'react';
import Chart from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { pieData } from '../Shared/Constant';
import { Tabs, Tab, TabList } from 'react-web-tabs';
import 'chartjs-plugin-datalabels';
import { IOJ_card_Data_new, getMonthNumber, getPrevMonth } from '../Shared/Constant';
import { ViewInsights_Grid } from './IOJ_ViewInsights_grid';
import ViewinsightNavigation from './ViewInsightNavigation';
import { LocalApiBaseUrl } from '../Shared/Constant';
import axios from 'axios';
import i18n from "i18next";
import { Link, withRouter } from 'react-router-dom';
import null_chart from '../Images/chart-null.png';
import axiosInstance from '../Shared/interceptor';
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


let charts = [];
class ViewInsights extends Component {
    constructor(props) {
        super(props)
        this.state = { MaturityPhase: "FOUNDATION",selectedCategory:"All",selectedTab:"All", UniqueCategories:[],IOJMainAll: [], IOJMain: [], IOJIntStatus: [], IOJSegr: [] ,data: []};
        // this.insightSelection = this.insightSelection.bind(this)
        // this.insightSelectionNav = this.insightSelectionNav.bind(this)
        // this.generateDetails = this.generateDetails.bind(this)
        // this.resetDdl=this.resetDdl.bind(this);
        this.selectedIndex = 0
    }
    // getIntelOps(offeringIdparam = { Client: 0, process: 0, Locations: 0, Month: getPrevMonth() }) {

    //     let filterProcess = offeringIdparam.process == undefined ? 0 : offeringIdparam.process;
    //     let filterLocations = offeringIdparam.Locations == undefined ? 0 : offeringIdparam.Locations;
    //     let filterClient = offeringIdparam.Client == undefined ? 0 : offeringIdparam.Client
    //     let dateArray = offeringIdparam.Month == 0 ? [] : offeringIdparam.Month.split("-")
    //     let todayDate = ""
    //     if (dateArray.length == 2) {
    //         // todayDate = new Date(dateArray[1], getMonthNumber(dateArray[0]) + 1, 1).toISOString().slice(0, 10);
    //         todayDate = "1-" + dateArray[0] + "-" + dateArray[1]
    //     }
    //     else {

    //         todayDate = offeringIdparam.Month;
    //     }
    //     //todayDate = "2019-01-01";


    //     if (filterProcess != 0 && filterLocations != 0 && filterClient != 0 && todayDate != 0) {
    //         axiosInstance.get(`${LocalApiBaseUrl}so/GetIntelOpsData`, {
    //             params: { OrgEntityID: filterClient, OfferingID: filterProcess, LocationID: filterLocations, MonthDT: todayDate }
    //         })
    //             .then((response) => {
    //                 this.generateDetails(response.data)
    //             })
    //             .catch((error) => {
    //             })
    //     }
    // }

    // generateDetails(Data) {

    //     let IOJMainAll = [];
    //     let IOJ_Category = ["Foundation", "Automated", "Insights", "Intelligent"]

    //     let IOJSegregation = []

    //     for (let i = 0; i < 4; i++) {
    //         IOJSegregation = Data.filter((obj) => obj.MaturityPhase === IOJ_Category[i])
    //         // if (IOJSegregation.length) {
    //         IOJMainAll.push(IOJSegregation);
    //         // }
    //     }
    //     let MaturityPhase = this.props.location.MaturityPhase === undefined ? this.state.MaturityPhase : this.props.location.MaturityPhase
    //     this.setState({ IOJMainAll })
    //     this.insightSelection(MaturityPhase, IOJMainAll,this.props.location.iojCategorySelected)
    //     // this.GetPieData(this.props.location.IOJMain);
    // }
    componentWillReceiveProps(nextProps) {

        //this.getIntelOps(nextProps.filterDetails);
        // if (this.props.location.MaturityPhase === undefined) {
        //     this.getIntelOps(this.props.filterDetails);
        // }
        // else {
        //     this.setState({ MaturityPhase: this.props.location.MaturityPhase,IOJSegr:this.props.location.IOJSegr })
        //     this.generateDetails(this.props.location.IOJMain)
        // }
    }
    componentWillMount() {

        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera 
        // if (this.props.location.MaturityPhase === undefined) {
        //     this.getIntelOps(this.props.filterDetails);
        // }
        // else {
            this.setState({ MaturityPhase: this.props.location.MaturityPhase, IOJSegr: this.props.location.IOJSegr ? this.props.location.IOJSegr: [],IOJMain:this.props.location.IOJMain ? this.props.location.IOJMain : [],selectedCategory: this.props.location.iojCategorySelected ? this.props.location.iojCategorySelected : "All"})
            //this.generateDetails(this.props.location.IOJMain)
        // }

    }
    componentDidMount(){
        // 
        // isIojBlock
        this.getUniqueCategories();
    }
  

    // insightSelectionNav(e) {      
    //     this.insightSelection(e, this.state.IOJMainAll,"All");
    // }
//     insightSelection(Type, Data,Ioj) {

//         let IOJMain = [];
//         let IOJMainCategoryData = [];
//         let category;
//         this.setState({ IOJIntStatus: [] })

     
//         if(Ioj==="All" || Ioj===undefined )// stage changed and called from VIewinsight link
//         {
//             category=Ioj;
//         }
//         else if(Ioj===null) //DDL changed
//         {
//          category= this.state.selectedCategory;
//         }
//         else if(Ioj!=undefined)
//         {
//             this.setState({selectedCategory:this.props.location.iojCategorySelected});
//             category= this.props.location.iojCategorySelected;
//         }

//         if (Type.toUpperCase() === "FOUNDATION" && (Data[0] != undefined)) 
//         {        
          
//             if(category!=null && category !="All" && category!="")
//             {
//                 let  filteredGrid =[];                  
//                 filteredGrid= Data[0].filter(ioj => ioj.IntelOpsCategoryName===category);
//                 IOJMain = filteredGrid != undefined ? filteredGrid : []
//             }
//             else 
//             {
//                IOJMain = Data[0] != undefined ? Data[0] : []
              
//             }
//             IOJMainCategoryData=this.state.IOJMainAll[0];
//             this.selectedIndex = 0
//         }
//          if ((Type.toUpperCase() === "AUTOMATED") && (Data[1] != undefined)) {

          
//             if(category!=null && category !="All" && category!="")
//             {
          
//                 let  filteredGrid =[];                  
//                 filteredGrid= Data[1].filter(ioj => ioj.IntelOpsCategoryName===category);
//                 IOJMain = filteredGrid != undefined ? filteredGrid : []
//             }
//             else
//             {
//                IOJMain = Data[1] != undefined ? Data[1] : []
              
//             }
//             IOJMainCategoryData=this.state.IOJMainAll[1];
//             this.selectedIndex = 1

//         }
//         if ((Type.toUpperCase() === "INSIGHTS") && (Data[2] != undefined)) 
//         {
          
//             if(category!=null && category !="All" && category!="")
//             {
              
//                 let  filteredGrid =[];                  
//                 filteredGrid= Data[2].filter(ioj => ioj.IntelOpsCategoryName===category);
//                 IOJMain = filteredGrid != undefined ? filteredGrid : []
//             }
//             else
//             {
//                IOJMain = Data[2] != undefined ? Data[2] : []
              
//             }
//             IOJMainCategoryData=this.state.IOJMainAll[2];
//             this.selectedIndex = 2
//         }
//         if ((Type.toUpperCase() === "INTELLIGENT") && (Data[3] != undefined) ) 
//         {
      
//             if(category!=null && category !="All" && category!="")
//             {
               
//                 let  filteredGrid =[];                  
//                 filteredGrid= Data[3].filter(ioj => ioj.IntelOpsCategoryName===category);
//                 IOJMain = filteredGrid != undefined ? filteredGrid : []
//             }
//             else
//             {
//                IOJMain = Data[3] != undefined ? Data[3] : []
              
//             }
//             IOJMainCategoryData=this.state.IOJMainAll[3];
//             this.selectedIndex = 3
//         }
    
//             this.generateInitiateStatusDetails(IOJMain);
//             //this.GetPieData(IOJMain);
//             this.setState({ IOJMain });
//             this.setState({data:IOJMainCategoryData});
           
    

//     }
//     GetPieData = (Data) => {


//         var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
//         Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
//             draw: function () {
//                 originalDoughnutDraw.apply(this, arguments);

//                 var chart = this.chart.chart;
//                 var ctx = chart.ctx;
//                 var width = chart.width;
//                 var height = chart.height;

//                 var fontSize = (height / 113).toFixed(2);
//                 ctx.font = fontSize + "em Verdana";
//                 ctx.textBaseline = "middle";


//                 var text = chart.config.data.text,
//                     textX = Math.round((width - ctx.measureText(text).width) / 2),
//                     textY = height / 2;

//                 ctx.fillText(text, textX, textY);

//             }
//         });

//         //let initStatus = [... new Set(Data.map((eachvalue) => eachvalue.InitiativeStatus))];
//         let initStatus = ["Concept", "InFlight", "Delivered", "OnHold"]
//         //let SoCat = [... new Set(Data.map((eachValue) => eachValue.SOCategoryID))];
//         let SoCat = ["2", "3", "4", "5"]    //Socategory Id for Human + Machine Talent, Work Orchestration, Insights & Intelligence, Diverse Data
//         let chartValues = {};
//         initStatus.map(eachValue => {
//             let resultArray = Data.filter(rowData => rowData.InitiativeStatus.toUpperCase() == eachValue.toUpperCase()); let chartObj = {};
//             SoCat.map((soID) => { let sumCount = resultArray.filter(row => row.SOCategoryID == soID).length; chartObj[soID] = sumCount }); return chartValues[eachValue] = chartObj
//         });
//         charts = []
//         for (let data in chartValues) {
//             let dataArray = []
//             for (let buildData in chartValues[data]) {
//                 dataArray.push(chartValues[data][buildData])
//             }
//             let finalData = {
//                 labels: ["Human + Machine Talent", "Work Orchestration", "Insights & Intelligence", "Diverse Data"],
//                 datasets: [{
//                     data: dataArray, backgroundColor: ['#1B92ED', '#1DBDFF', '#555555', '#054DFF']
//                 }],
//                 text: dataArray.reduce((a, b) => a + b, 0)
//             };
//             Chart.defaults.global.defaultFontColor = 'white';
//             charts.push(<div className="insight-graph col-lg-3 col-md-3 col-sm-6 col-xs-6 col-xxs"><h4>{data}</h4>{finalData.text == 0 ? <img src={null_chart} alt="" /> : <Doughnut ref='chart' data={finalData} options={{ maintainAspectRatio: true, responsive: true, legend: { display: false, position: 'bottom', labels: { boxWidth: 20 } } }} />}</div>)
//             //    charts.push(<div className="insight-graph col-lg-3"><h4>{data}</h4> <Doughnut ref='chart' data={finalData} options={{ maintainAspectRatio: true, responsive: true, legend: { display: false, position: 'bottom', labels: { boxWidth: 20 } } }} /></div>)

//         }
//         return charts
//     }
//     gridTableData(IOJIntStatus) {
//         return <>{IOJIntStatus.map((row, index) => { return })}</>
//     }
//     generateInitiateStatusDetails(Data) 
//     {
//         let IOJ_Category = ["Human + Machine Talent", "Work Orchestration", "Insights & Intelligence", "Diverse Data"]
//         let IOJSegregation = []
//         let IOJIntStatus = []
//         for (let i = 0; i < 4; i++) {
//             IOJSegregation = Data.filter((obj) => obj.SOCategoryName.toUpperCase() === IOJ_Category[i].toUpperCase())
//             if (IOJSegregation.length) {
//                 IOJIntStatus.push(IOJSegregation);
//             }
//         }
//         this.setState({ IOJIntStatus })
//     }
//     dropdownCategory=()=>
// {

   
//     let chartValues;
//     let arr=[];
//     let chartObj = {};
//     arr= Object.keys(this.state.IOJIntStatus).map(el => { 
    
//        this.state.IOJIntStatus[el].map(sub_el => {chartObj = sub_el.MaturityPhase});
//        return chartValues = chartObj
//      });   
  
//      if(this.state.selectedCategory!=null)
//      {
//          if(chartValues===undefined)
//          {  
//              this.insightSelection(this.state.selectedTab, this.state.IOJMainAll,null) }
//          else
//          {       
//             this.setState({selectedTab: chartValues}); 
            
//         }
      
//      }
 

     

// }
initiativesData = () =>{
let selectedCat = this.state.selectedCategory;
let phase =this.state.MaturityPhase;
let AllData = this.state.IOJMain;
let tab = this.state.selectedTab;
let initiativeData = [];
if(AllData.length){
if(selectedCat ==="All"){
if(tab==="All"){
    initiativeData =  AllData.filter((each)=>{
       if(each.MaturityPhase_En=== phase){
           return each;
       }
   })
   this.setState({IOJIntStatus:initiativeData})
}else{
     initiativeData =  AllData.filter((each)=>{
        if(each.MaturityPhase_En=== phase && each.SOCategoryName_En === tab){
            return each;
        }
    })
    this.setState({IOJIntStatus:initiativeData})
}
}else{
    if(tab==="All"){
         initiativeData =  AllData.filter((each)=>{
            if(each.MaturityPhase_En=== phase && each.IntelOpsCategoryName_En === selectedCat){
                return each;
            }
        })
        this.setState({IOJIntStatus:initiativeData})
     }else{
          initiativeData =  AllData.filter((each)=>{
             if(each.MaturityPhase_En=== phase && each.SOCategoryName_En === tab && each.IntelOpsCategoryName_En === selectedCat){
                 return each;
             }
         })
         this.setState({IOJIntStatus:initiativeData})
     }
}
}
}
getUniqueCategories = ()=>{

let currentData = this.state.IOJMain;
let currentPhase = this.state.MaturityPhase;
let data = [];
if(currentData.length && this.state.IOJSegr.length ===4){
    if(currentPhase.toUpperCase()==="FOUNDATION")
    {
            data=  this.state.IOJSegr[0];
    }
    else if(currentPhase.toUpperCase()==="AUTOMATED"){
        data=  this.state.IOJSegr[1];
    }
    else if(currentPhase.toUpperCase()==="INSIGHTS"){
        data=  this.state.IOJSegr[2];
    }
    else if(currentPhase.toUpperCase()==="INTELLIGENT"){
        data=  this.state.IOJSegr[3];
    }
}
let UniqueCategories = []
if(data.length){
    data.map((each)=>{
        let uniqeness =  UniqueCategories.filter(e =>{
              if(e.IntelOpsCategoryName_En === each.IntelOpsCategoryName_En){
                  return e
              }
          })
      if(uniqeness.length === 0){
          UniqueCategories.push({IntelOpsCategoryName:each.IntelOpsCategoryName,IntelOpsCategoryName_En:each.IntelOpsCategoryName_En}) 
      }
      })
}
         

//     let iojCategory = [];
//     let arrIoj=[];
//     let chartObjIoj = [];
  
//     let data=[];
//     if(this.state.data!=undefined)
//     {
//     arrIoj= (this.state.data).map(el => 
//         {   
//      {chartObjIoj.push(el.IntelOpsCategoryName)}   
//         return iojCategory = chartObjIoj
//      });   
//     }
//     else if(this.state.IOJIntStatus!=undefined)
//     {

//         if(this.props.location.MaturityPhase.toUpperCase()==="FOUNDATION")
//         {
//                 data=  this.state.IOJMainAll[0];
//         }
//         else if(this.props.location.MaturityPhase.toUpperCase()==="AUTOMATED"){
//             data=  this.state.IOJMainAll[1];
//         }
//         else if(this.props.location.MaturityPhase.toUpperCase()==="INSIGHTS"){
//             data=  this.state.IOJMainAll[2];
//         }
//         else if(this.props.location.MaturityPhase.toUpperCase()==="INTELLIGENT"){
//             data=  this.state.IOJMainAll[3];
//         }
    
//         arrIoj=data.map(row=>{{chartObjIoj.push(row.IntelOpsCategoryName)}                         
//                         return iojCategory = chartObjIoj     
//                   })

//     }

//    let uniqueIojCate=[];
//    let arrayMerge=["All"];
//      if(iojCategory!=null)
//      {
//     uniqueIojCate =iojCategory.filter(function(item, pos)
//     {
//         return iojCategory.indexOf(item)== pos;
//         });   
//       }      

//       var filtered = uniqueIojCate.filter(function (el) {
//         return el != null;
//       });
      
      this.setState({UniqueCategories:UniqueCategories},()=>{
        this.initiativesData();
      })
    // arrayMerge.push(...filtered);

}
onCategoryChange = (e) =>{
    this.setState({selectedCategory: e.target.options[e.target.selectedIndex].value},()=>{
        //this.insightSelection(this.state.MaturityPhase, this.state.IOJMainAll,null) 
        this.initiativesData();
    });
  
}
setMaturityPhase = (val) =>{
    this.setState({MaturityPhase:val,selectedCategory:"All"},()=>{
        this.getUniqueCategories()
    })
}
onTabChange = (val) =>{
    this.setState({selectedTab:val},()=>{
        this.initiativesData();
    })
}
// resetDdl=()=>
// {
   
//     this.setState({selectedCategory:"All"});
// }


    render() {


        
        return (
       
            <div className="main-component ioj-component">
                <div className="container-fluid">
                     <div className="row" >
                        <div className="col-lg-12">
                            <div className="big-tab-container">
                                <h3 className="mb-3"><Link className="round-back" to={"/Dashboard"} onClick={this.props.redirectLinkPass}></Link> {i18n.t("Intelligent_Automation_Journey")}!</h3>
                                <div className="big-tab">
                                    {this.state.IOJMain.length != 0 ? <ViewinsightNavigation  nav={this.state.IOJMain} IOjSeg={this.state.IOJSegr} phase = {this.state.MaturityPhase} selected={this.selectedIndex} setMaturityPhase={this.setMaturityPhase}  /> : null}
                                    <div className="row mb-4">
                               <div className="col-md-3">
                                    {/* <label ></label> */}
                                    {this.props.location.isIojBlock===true && this.state.UniqueCategories.length >= 1 && this.state.UniqueCategories[0].IntelOpsCategoryName!=null?  <select className="form-control" 
                                        onChange={this.onCategoryChange} value={this.state.selectedCategory} >
                                            <option value="All">{i18n.t("All")}</option>
                                 {this.state.UniqueCategories.map((name,i) => <option key={i} value={name.IntelOpsCategoryName_En}>{name.IntelOpsCategoryName}</option>) }
                                                             
                                          </select>:null}
                                    </div>
                                    </div>
                                    <div className="big-tab-content">
                                        <div className="tab-content-pane">
                                            {/* <div className="insight-graph-list row">
                                               
                                                {this.state.IOJMain != 0 ? this.GetPieData(this.state.IOJMain) : null}
                                            </div>
                                            <div className="insight-graph-spot">
                                                <ul>
                                                    <li><span className="graph-icon red-clr"></span> Human + Machine</li>
                                                    <li><span className="graph-icon green-clr"></span> Work Orchestration</li>
                                                    <li><span className="graph-icon grey-clr"></span> Insights &amp; Intelligence</li>
                                                    <li><span className="graph-icon blue-clr"></span> Diverse Data</li>
                                                </ul>
                                            </div> */}
                                            <Tabs defaultTab="All" className="p-0 mt-2">
                                                <TabList>
                                                    <Tab tabFor="All" onClick={this.onTabChange.bind(this,"All")}><span>{i18n.t("All")}</span></Tab>
                                                    <Tab tabFor="Human + Machine Talent" onClick={this.onTabChange.bind(this,"Human + Machine Talent")}><span>{i18n.t("Human_plus_Machine_Talent")}</span></Tab>
                                                    <Tab tabFor="Work Orchestration" onClick={this.onTabChange.bind(this,"Work Orchestration")}><span>{i18n.t("Work_Orchestration")}</span></Tab>
                                                    <Tab tabFor="Insights & Intelligence" onClick={this.onTabChange.bind(this,"Insights & Intelligence")}><span>{i18n.t("Insights")} &amp; {i18n.t("Intelligence")}</span></Tab>
                                                    <Tab tabFor="Diverse Data" onClick={this.onTabChange.bind(this,"Diverse Data")}><span>{i18n.t("Diverse_Data")}</span></Tab>
                                                </TabList>
                                                {this.state.IOJIntStatus.length != 0 ? <ViewInsights_Grid Details={this.state.IOJIntStatus}  tab ={this.state.selectedTab} /> : null}
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        )
    }
}
export default withRouter(ViewInsights)