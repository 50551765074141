import React, { Component } from "react";
import { Modal, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Tabs, Tab, Form } from "react-bootstrap";
import UploadClientLogo from "../Images/UploadClientLogo.svg";
import UploadClientLogoUpdated from "../Images/UploadClientLogoUpdated.png";
import colorpicker from "../Images/colorpicker.png";
import i18n from "i18next";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Download from "../Shared/download.js";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import { regexForAccentureDomainEmail, regexForConfMasterFields } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { LocalApiBaseUrl, LocalApiBaseUrlImage } from "../Shared/Constant";
import SubOffering from "../Shared/SubOffering";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
  filterSelectedSubOffering,
  isValidText,
  InvalidDescription,
  getValidUserDetails,
  getErrorMessageByData,
} from "../Shared/commonFunctions";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem(
  "msal.idtoken"
)}`;

export default class ClientBoardingPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ColorCode: "",
      leafNodes: [],
      searchTerm: "",
      disableSubmitButton: false,
      isScreenOneMandatoryFieldEmpty: false,
      isApiLoading: false,
      show: true,
      decode: null,
      noerrors: [],
      errors: {},
      userRole: {},
      logoerror: [],
      emailerror: [],
      selectedEmailFileSizeInKB: 0,
      selectedEmailFileName: "",
      isEmailFileSelected: false,
      primeerrorshow: false,
      deliveryerrorshow: false,
      editacessusererrorshow: false,
      viewacessusererrorshow: false,
      Fprimeerrorshow: false,
      Fdeliveryerrorshow: false,
      Feditacessusererrorshow: false,
      Fviewacessusererrorshow: false,
      Noerrorshow: false,
      Noprimeerrorshow: false,
      Nodeliveryerrorshow: false,
      NoApprovalEmailErrorShow: false,
      Noeditacessusererrorshow: false,
      selectedTabIndex: 1,
      primeerrors: [],
      Deliveryerrors: [],
      EditAccessUsererrors: [],
      ViewAccessUsererrors: [],
      errors: [],
      notificationStatus: false,
      OrgStructureData: [],
      ClientData: [],
      OfferingData: [],
      SubOfferingData: [],
      ClientRegionData: [],
      DeliveryRegionData: [],
      IndustryData: [],
      MaturityPhaseData: [],
      DeliveryCenterData: [],
      EditData: {},
      configKeys: {
        emailFileMaxSizeInKB: 0,
        clientLogoFileMaxSizeInKB: 0,
      },
      isEditViewAuthorise: {
        EditAccessUsers: false,
        ViewAccessUsers: false,
      },
      isAuthorise: {
        ClientAliasName: false, //disable=false
        OtherClientRegion: true,
        OtherDeliveryRegion: true,
        DeliveryCentreAlias: false,
        OtherIndustry: true,
        City: false,
        ClientName: true,
        ClientRegion: false,
        MarketUnit: false,
        OtherMarketUnit: true,

        DeliveryRegion: false,
        Industry: false,
        MaturityPhase: false,
        Offering: false,
        Process: false,
        Project: false,
        RequestorRemarks: false,
        AccentureSDL: false,
        AccentureMD: false,
      },
      ClientBoardingPopupData: {
        OrgEntityName: "",
        OtherClientRegion: "",
        OtherDeliveryRegion: "",
        selectedFile: null,
        selectedEmailfile: null,
        fileName: "",
        emailFileName: "",
        OrgEntityImage: "",
        DeliveryCentreAlias: "",
        OtherIndustry: "",
        City: null,
        ClientName: "",
        CoreBeatClientName: "",
        CoreBeatLocation: "",
        MarketUnit: "",
        OtherMarketUnit: "",
        ClientRegion: "",
        ClientRegionID: "",
        MarketUnitID: "",
        CreatedUser: "",
        DealID: 0,
        DealName: null,
        DeliveryRegion: "",
        DeliveryRegionID: "",
        Industry: "",
        IndustryID: "",
        LocationID: "",
        MaturityPhase: "",
        MaturityPhaseID: "",
        Offering: "",
        OfferingID: "",
        OperatingGroup: "",
        OrgEntityID: "",
        RefreshClientID: "",
        Process: "",
        ProcessID: "",
        Program: "",
        Project: "",
        Status: "",
        SubProcess: "",
        UpdatedUser: "",
        AccentureMD: "",
        AccentureSDL: "",
        EditAccessUsers: "",
        RequestorRemarks: "",
        ViewAccessUsers: sessionStorage.getItem("EnterpriseID") + "@accenture.com",
        EmailBase64: null,
        IsDemo: false,
        Cxl: "",
        Oal: "",
        IsAlias: false,
        ColorCode: "",
      },
      isNewClientPopupOpen: false,
      NewClientName: "",
      NewClientError: null,
      showAddNewClient: false,
      isClientLoaded: false,
      IsAdmin: false,
    };
    this.notificationModalShow = this.notificationModalShow.bind(this);
    this.onClientChange = this.onClientChange.bind(this);
    this.notificationModalClose = this.notificationModalClose.bind(this);
    this.prevTabChangeEvent = this.prevTabChangeEvent.bind(this);
    this.nextTabChangeEvent = this.nextTabChangeEvent.bind(this);
    this.getAllData = this.getAllData.bind(this);
    this.onIsAliasChange = this.onIsAliasChange.bind(this);
  }
  componentWillMount() {
    this.GetUserDetails();
    this.getAllData();
    if (!_.isEmpty(this.props.EditData)) {
      let dealId = this.props.EditData.DealID;
      //Get User Profiles
      axiosInstance
        .get(`${LocalApiBaseUrl}DealOnboarding/FetchCurrentUserRole`, {
          params: {
            dealId: dealId,
          },
        })
        .then((response) => {
          this.setState(
            {
              ...this.state,
              userRole: response.data,
            },
            () => this.disableFields()
          );
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });

      let EditData = { ...this.props.EditData };
      EditData.ClientName = EditData.OrgEntityName;
      let ClientBoardingPopupData = this.state.ClientBoardingPopupData;
      ClientBoardingPopupData.AccentureMD = EditData.AccentureMD;
      ClientBoardingPopupData.AccentureSDL = EditData.AccentureSDL;
      ClientBoardingPopupData.EditAccessUsers = EditData.EditAccessUsers;
      ClientBoardingPopupData.ViewAccessUsers = EditData.ViewAccessUsers;
      ClientBoardingPopupData.RequestorRemarks = EditData.RequestorRemarks;
      ClientBoardingPopupData.OrgEntityImage = EditData.OrgEntityImage;
      ClientBoardingPopupData.ColorCode = EditData.ColorCode;
      ClientBoardingPopupData.Status = EditData.Status;
      ClientBoardingPopupData.Oal = EditData.Oal;
      ClientBoardingPopupData.Cxl = EditData.Cxl;
      EditData.MarketUnit = EditData.MarketUnitID === -1 ? "Others" : EditData.MarketUnit;

      EditData.ClientRegionName =
        EditData.ClientRegionID === -1 ? "Others" : EditData.ClientRegionName;

      EditData.DeliveryRegion =
        EditData.DeliveryRegionID === -1 ? "Others" : EditData.DeliveryRegion;
      if (
        this.state.ClientBoardingPopupData.City &&
        this.state.ClientBoardingPopupData.City.toLowerCase().trim() ===
          this.state.ClientBoardingPopupData.CoreBeatLocation.toLowerCase().trim()
      ) {
        //add case delivery center alias
        this.state.ClientBoardingPopupData.City = "";
      }
      if (
        EditData.City &&
        EditData.City.toLowerCase().trim() === EditData.CoreBeatLocation.toLowerCase().trim()
      ) {
        //edit case delivery center alias

        EditData.City = "";
      }

      if (
        this.state.ClientBoardingPopupData.ClientName &&
        this.state.ClientBoardingPopupData.ClientName.toLowerCase().trim() ===
          this.state.ClientBoardingPopupData.CoreBeatClientName.toLowerCase().trim()
      ) {
        //add case client alias
        this.state.ClientBoardingPopupData.ClientName = "";
      }
      if (
        EditData.ClientName &&
        EditData.ClientName.toLowerCase().trim() ===
          EditData.CoreBeatClientName.toLowerCase().trim()
      ) {
        EditData.ClientName = "";
      }

      this.setState({
        ...this.state,
        isEmailFileSelected: true,
        EditData: EditData,
        ClientBoardingPopupData: ClientBoardingPopupData,
      });
    }
  }

  // onChange = (e) =>{
  //   this.setState
  //   ({
  //     IsAlias: e.target.IsAlias,
  //   });
  // };

  downloadEmail = (dealIDParam) => {
    axiosInstance
      .get(`${LocalApiBaseUrl}DealOnboarding/DownloadEmailOnButtonClick`, {
        params: {
          dealID: dealIDParam,
        },
      })
      .then((response) => {
        if (response.data) {
          if (response.data.FileName && response.data.FileSize) {
            let hrefpart = "data:application/octet-stream;base64,";
            let link = document.createElement("a");
            link.href = hrefpart + response.data.FileContents;
            link.download = response.data.FileName;
            Download(link.href, response.data.FileName);
          }
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  getAllData() {
    this.setState({ showLoader: true });
    //Org Structure Data & Delivery Center Data
    axiosInstance
      .get(`${LocalApiBaseUrl}DealOnboarding/GetMasterDataClient`, {
        params: {
          IsDemo: true,
        },
      })
      .then((response) => {
        let ClientData, DeliveryCenter;
        let editCaseCorebeatClientName = !_.isEmpty(this.props.EditData)
          ? this.props.EditData.CoreBeatClientName
          : "";
        if (_.isEmpty(this.props.EditData)) {
          const filterData = response.data.filter((ind) => !_.isNil(ind.ClientName));
          const datarender = this.state.ClientBoardingPopupData.IsDemo
            ? filterData
            : filterData.filter((ind) => ind.IsDemo === false);
          ClientData = [...new Set(datarender.map((item) => item.ClientName))];
          ClientData.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));

          let ClientBoardingPopupData = { ...this.state.ClientBoardingPopupData };
          if (this.props.selectedClientID && this.state.IsAdmin) {
            let clientPPData =
              filterData.filter((org) => org.OrgEntityID == this.props.selectedClientID).length > 0
                ? filterData.filter((org) => org.OrgEntityID == this.props.selectedClientID)[0]
                : {};

            ClientBoardingPopupData.IsDemo = clientPPData.IsDemo;
            ClientBoardingPopupData.CoreBeatClientName = _.isNil(clientPPData.CoreBeatName)
              ? _.isNil(clientPPData.OrgEntityName)
                ? clientPPData.ClientName
                : clientPPData.OrgEntityName
              : clientPPData.CoreBeatName;
            if (!_.isNil(clientPPData.CoreBeatName) && !_.isNil(clientPPData.OrgEntityName)) {
              ClientBoardingPopupData.ClientName =
                clientPPData.CoreBeatName.toLowerCase() == clientPPData.OrgEntityName.toLowerCase()
                  ? ""
                  : clientPPData.OrgEntityName;
            }

            DeliveryCenter = filterData.filter(
              (f) => String(f.ClientName) === String(clientPPData.ClientName)
            );
          }
          this.setState(
            {
              OrgStructureData: filterData,
              ClientData: ClientData,
              ClientBoardingPopupData: ClientBoardingPopupData,
              DeliveryCenterData: DeliveryCenter,
            },
            () => {
              // //When opened from view projects, prepopulate client name in the dropdown
              if (this.props.selectedClientID && this.state.IsAdmin) {
                if (this.state.ClientBoardingPopupData.CoreBeatClientName) {
                  this.clientlogoaliasfetch(this.state.ClientBoardingPopupData.CoreBeatClientName);
                }
              }
              this.setState({ isClientLoaded: true });
            }
          );

          this.setState({
            DeliveryCenterData: DeliveryCenter,
          });
        } else {
          ClientData = [...new Set(response.data.map((item) => item.ClientName))];
          DeliveryCenter = response.data.filter(
            (org) => String(org.ClientName) === String(editCaseCorebeatClientName)
          );
          this.setState({
            OrgStructureData: response.data,
            ClientData: ClientData,
            DeliveryCenterData: DeliveryCenter,
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

    //Offering & SubOffering Data
    axiosInstance
      .get(`${LocalApiBaseUrl}Offering/GetOfferingsListDealOnboarding`, {
        params: {
          LanguageID: 1,
          IsDemo: this.state.ClientBoardingPopupData.IsDemo,
        },
      })
      .then((response) => {
        if (_.isEmpty(this.props.EditData)) {
          this.setState({
            OfferingData: response.data,
          });
        } else {
          let SubOffering = response.data.find(
            (offering) => String(offering.OfferingName) === String(this.props.EditData.Offering)
          ).ChildOfferings;
          this.setState({
            OfferingData: response.data,
            SubOfferingData: SubOffering,
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

    //Client Region Data
    axiosInstance
      .get(`${LocalApiBaseUrl}DealOnboarding/GetAllClientRegion`)
      .then((response) => {
        this.setState({
          ClientRegionData: response.data.filter(
            (x) => x.IsActive === true && x.Status === "Approved"
          ),
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

    //Delivery Region Data
    axiosInstance
      .get(`${LocalApiBaseUrl}DealOnboarding/GetAllDeliveryRegion`)
      .then((response) => {
        this.setState({
          DeliveryRegionData: response.data.filter(
            (x) => x.IsActive === true && x.Status === "Approved"
          ),
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

    //Industry Data
    axiosInstance.get(`${LocalApiBaseUrl}Industry/GetIndustry`).then((response) => {
      this.setState({
        IndustryData: response.data.filter((x) => x.IsActive === true && x.Status === "Approved"),
        showLoader: false,
      });
    });
    //Maturity Phase Data
    axiosInstance
      .get(`${LocalApiBaseUrl}DealOnboarding/GetMaturityPhase`)
      .then((response) => {
        this.setState({
          MaturityPhaseData: response.data,
        });

        this.setState({
          MaturityPhaseData: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

    //Config keys from web config for icon and log size
    axiosInstance
      .get(`${LocalApiBaseUrl}DealOnboarding/ReturnMaxFileSizeConfigKeys`)
      .then((response) => {
        let configKeys = this.state.configKeys;
        configKeys.clientLogoFileMaxSizeInKB = response.data.clientLogoFileMaxSizeInKB;
        configKeys.emailFileMaxSizeInKB = response.data.emailFileMaxSizeInKB;

        this.setState({
          configKeys: configKeys,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  disableFields() {
    let userRole = this.state.userRole;
    if (this.props.EditData.Status === "Approved") {
      if (userRole.isAdmin) {
        this.setState({
          ...this.state,
          isAuthorise: {
            ...this.state.isAuthorise,
            ClientName: false,
            ClientAliasName: true,
            DeliveryCentreAlias: true,
            Offering: true,
            ClientRegion: false,
            MarketUnit: false,
            DeliveryRegion: false,
            RequestorRemarks: true,
            City: true,
            Industry: false,
            Process: true,
            MaturityPhase: false,
            Project: false,
            RequestorRemarks: true,
            AccentureSDL: false,
            AccentureMD: false,
          },
          isEditViewAuthorise: {
            ...this.state.isEditViewAuthorise,
            EditAccessUsers: false,
            ViewAccessUsers: false,
          },
        });
      } else if (userRole.isMD && this.props.EditData.Status === "Approved") {
        let isAuthoriseTemp = this.state.isAuthorise;
        let isEditViewAuthorisetemp = this.state.isEditViewAuthorise;
        Object.entries(isAuthoriseTemp).forEach(
          ([key, val]) => (this.state.isAuthorise[key] = true)
        );
        Object.entries(isEditViewAuthorisetemp).forEach(
          ([key, val]) => (this.state.isEditViewAuthorise[key] = false)
        );
        this.setState({
          ...this.state,
          isAuthorise: isAuthoriseTemp,
          isEditViewAuthorise: isEditViewAuthorisetemp,
        });
      } else if (userRole.isSDL && this.props.EditData.Status === "Approved") {
        let isAuthoriseTemp = this.state.isAuthorise;
        let isEditViewAuthorisetemp = this.state.isEditViewAuthorise;
        Object.entries(isAuthoriseTemp).forEach(
          ([key, val]) => (this.state.isAuthorise[key] = true)
        );
        Object.entries(isEditViewAuthorisetemp).forEach(
          ([key, val]) => (this.state.isEditViewAuthorise[key] = false)
        );
        this.setState({
          ...this.state,
          isAuthorise: isAuthoriseTemp,
          isEditViewAuthorise: isEditViewAuthorisetemp,
        });
      }
    } else if (this.props.EditData.Status === "Rejected") {
      let isAuthorise = this.state.isAuthorise;
      isAuthorise.ClientName = false;
      isAuthorise.ClientAliasName = true;

      this.setState({ isAuthorise: isAuthorise });
    }
  }

  notificationModalShow() {
    this.setState({ notificationStatus: true });
  }
  notificationModalClose(action) {
    this.props.childToParent(action, this.state.clientBoardedApiResponse);
    if (this.props.refreshPage) {
      this.props.refreshPage(action, this.state.RefreshClientID); //PUT STATE CLIENTID
    }
    this.setState({ notificationStatus: false });
  }
  addProjectClose = (iscancel) => {
    if (iscancel) {
      this.props.childToParent("cancel");
    } else {
      this.props.childToParent("addProject");
    }
    this.setState({
      show: false,
    });
  };

  removeEmailFile = () => {
    if (_.isEmpty(this.props.EditData)) {
      const popupData = this.state.ClientBoardingPopupData;
      popupData.selectedEmailfile = null;
      popupData.selectedEmailFileName = "";
      popupData.selectedEmailFileSizeInKB = 0;

      this.setState({
        isEmailFileSelected: false,
        ClientBoardingPopupData: popupData,
        emailerror: [],
      });
    } else {
      const popupData = this.state.EditData;
      popupData.selectedEmailfile = null;
      popupData.ApprovalEmailFileName = "";
      popupData.ApprovalEmailFileSize = 0;

      this.setState({
        isEmailFileSelected: false,
        EditData: popupData,
        emailerror: [],
      });
    }
  };

  addProjectShow = () => {
    this.setState({
      show: true,
    });
  };

  clientboardingclose(e) {
    this.props.refreshPage(e);
  }
  prevTabChangeEvent() {
    let currentVal = this.state.selectedTabIndex;
    currentVal--;
    if (currentVal !== 0) {
      this.setState({ selectedTabIndex: currentVal });
    }
  }
  async nextTabChangeEvent() {
    if (this.state.selectedTabIndex < 2) {
      let currentVal = this.state.selectedTabIndex;
      currentVal++;
      this.setState({ selectedTabIndex: currentVal });
    }

    if (this.state.selectedTabIndex === 2) {
      if (await this.validateForm()) {
        this.setState({ disableSubmitButton: true });
        let ClientBoardingPopupData;
        if (_.isEmpty(this.props.EditData)) {
          ClientBoardingPopupData = this.state.ClientBoardingPopupData;
        } else {
          this.state.EditData.ClientName = this.state.EditData.OrgEntityName;
          let emailineditcase = this.state.ClientBoardingPopupData.selectedEmailfile;
          let logoineditcase = this.state.ClientBoardingPopupData.selectedFile;

          ClientBoardingPopupData = this.state.EditData;
          ClientBoardingPopupData.selectedEmailfile = emailineditcase;
          ClientBoardingPopupData.selectedFile = logoineditcase;
        }
        if (ClientBoardingPopupData.ProcessID)
          ClientBoardingPopupData.OfferingID = ClientBoardingPopupData.ProcessID;
        if (ClientBoardingPopupData.OrgEntityImage != null) {
          if (ClientBoardingPopupData.OrgEntityImage.includes("SOImages")) {
            ClientBoardingPopupData.OrgEntityImage = ClientBoardingPopupData.OrgEntityImage.replace(
              "/SOImages/",
              ""
            );
          }
        }

        let form_data = new FormData();

        for (let key in ClientBoardingPopupData) {
          form_data.append(key, ClientBoardingPopupData[key]);
        }
        if (ClientBoardingPopupData.selectedFile) {
          form_data.append(
            "selectedFile",
            this.state.ClientBoardingPopupData.selectedFile,
            this.state.ClientBoardingPopupData.selectedFile.name
          );
        }

        if (ClientBoardingPopupData.selectedEmailfile) {
          form_data.append(
            "selectedEmailfile",
            this.state.ClientBoardingPopupData.selectedEmailfile,
            this.state.ClientBoardingPopupData.selectedEmailfile.name
          );
        }

        //Post API Call
        axiosInstance
          .post(`${LocalApiBaseUrl}DealOnboarding/AddEditClientProject`, form_data)
          .then((response) => {
            this.setState({ disableSubmitButton: false });
            if (response.data.OrgEntityID !== 0) {
              this.setState({
                notificationStatus: true,
                show: false,
                RefreshClientID: response.data.OrgEntityID,
                clientBoardedApiResponse: response.data,
              });
              this.getAllData();
            } else {
              window.alert(response.data.StatusMessage);
              this.props.childToParent("addProject");
              this.setState(
                {
                  notificationStatus: false,
                  show: false,
                },
                () => {}
              );
            }
          })
          .catch((error) => {
            this.setState({ showLoader: false, show: false, notificationStatus: false }, () => {
              trycatchAlertPopup(error);
              this.props.childToParent("addProject");
            });
          });
      }
    }
  }

  /* custom methods */
  async validateForm() {
    let data = _.isEmpty(this.props.EditData)
      ? _.cloneDeep(this.state.ClientBoardingPopupData)
      : _.cloneDeep(this.state.EditData);
    let { primeerrors, Deliveryerrors, EditAccessUsererrors, ViewAccessUsererrors } = this.state;
    let InvalidDescriptionText = InvalidDescription();
    let errors = this.state.errors;
    let isValid = true;
    let isScreenOneMandatoryFieldEmpty = false;
    let ShowClientDropdownErrorMsg = false;
    let ShowProjectErrorMsg = false;
    let ShowCityErrorMsg = false;
    let ShowClientNameErrorMsg = false;
    let ShowClienRegionErrorMsg = false;
    let ShowMarketUnitErrorMsg = false;
    let ShowMaturityPhaseErrorMsg = false;
    let ShowOtherIndustryErrorMsg = false;
    let ShowIndustryErrorMsg = false;
    let ShowOtherDeliveryRegionErrorMsg = false;
    let ShowDeliveryRegionErrorMsg = false;
    let ShowOtherClientRegionErrorMsg = false;
    let ShowOtherMarketUnitErrorMsg = false;

    let ShowOfferingErrorMsg = false;
    let ShowSubOfferingErrorMsg = false;
    let ShowLocationErrorMsg = false;
    let ShowOffering;
    let Noprimeerrorshow = false,
      Noerrorshow = false,
      Nodeliveryerrorshow = false,
      NoApprovalEmailErrorShow = false,
      Noeditacessusererrorshow = false,
      Fprimeerrorshow = !_.isEmpty(primeerrors),
      Fdeliveryerrorshow = !_.isEmpty(Deliveryerrors),
      Feditacessusererrorshow = !_.isEmpty(EditAccessUsererrors),
      Fviewacessusererrorshow = !_.isEmpty(ViewAccessUsererrors),
      noerrors = [];

    if (
      _.isNil(this.state.ClientBoardingPopupData.AccentureMD) ||
      !this.state.ClientBoardingPopupData.AccentureMD
    ) {
      noerrors["NoAccentureMD"] = i18n.t("Prime_Lead_is_required");
      Noprimeerrorshow = true;
    }
    if (
      _.isNil(this.state.ClientBoardingPopupData.AccentureSDL) ||
      !this.state.ClientBoardingPopupData.AccentureSDL
    ) {
      noerrors["NoAccentureSDL"] = i18n.t("Delivery_Lead_is_required");
      Nodeliveryerrorshow = true;
    }
    if (
      _.isNil(this.state.ClientBoardingPopupData.Cxl) ||
      !this.state.ClientBoardingPopupData.Cxl
    ) {
      errors["Cxl"] = i18n.t("Cxl is required");
    }
    if (
      _.isNil(this.state.ClientBoardingPopupData.Oal) ||
      !this.state.ClientBoardingPopupData.Oal
    ) {
      errors["Oal"] = i18n.t("Oal is required");
    }
    if (
      _.isNil(this.state.ClientBoardingPopupData.EditAccessUsers) ||
      !this.state.ClientBoardingPopupData.EditAccessUsers
    ) {
      noerrors["NoEditAccessUsers"] = i18n.t("Users_with_Edit_Access_is_required");
      Noeditacessusererrorshow = true;
    }
    if (!this.state.ClientBoardingPopupData.selectedEmailfile && _.isEmpty(this.props.EditData)) {
      noerrors["NoApprovalEmailErrorShow"] = i18n.t("Approval_Confirmation_Email_is_required");
      NoApprovalEmailErrorShow = true;
    }

    let filePath = this.state.ClientBoardingPopupData.emailfileName;
    if (filePath) {
      let fileExtension =
        filePath.substring(filePath.lastIndexOf(".") + 1, filePath.length) || filePath;
      if (String(fileExtension) !== "msg") {
        NoApprovalEmailErrorShow = true;
      }
    }

    const { AccentureMD, AccentureSDL, EditAccessUsers, ViewAccessUsers, Cxl, Oal } =
      this.state.ClientBoardingPopupData;
    let MDdata = AccentureMD.split(",").filter((a) => a != "");
    let SDLdata = AccentureSDL.split(",").filter((a) => a != "");
    let EditAccesdata = EditAccessUsers.split(",").filter((a) => a != "");
    let ViewAccessdata = ViewAccessUsers.split(",").filter((a) => a != "");
    const CXLData = Cxl.split(",").filter((a) => a != "");
    const OALData = Oal.split(",").filter((a) => a != "");
    let userData = [
      ...new Set([
        ...MDdata,
        ...SDLdata,
        ...CXLData,
        ...OALData,
        ...EditAccesdata,
        ...ViewAccessdata,
      ]),
    ];
    let userValidData = await getValidUserDetails(userData);

    if (!this.state.primeerrorshow) {
      Fprimeerrorshow = true;
      if (!_.isNil(MDdata) && MDdata.length > 0) {
        const { formValid, errorMessage } = getErrorMessageByData(userValidData, MDdata);
        primeerrors["AccentureMDEmail"] = errorMessage;
        isValid = formValid;
        Fprimeerrorshow = !formValid;
      }
    }
    if (!errors["Cxl"]) {
      if (!_.isNil(CXLData) && CXLData.length > 0) {
        const { formValid, errorMessage } = getErrorMessageByData(userValidData, CXLData);
        errors["Cxl"] = errorMessage;
        isValid = formValid;
      }
    }
    if (!errors["Oal"]) {
      if (!_.isNil(OALData) && OALData.length > 0) {
        const { formValid, errorMessage } = getErrorMessageByData(userValidData, OALData);
        errors["Oal"] = errorMessage;
        isValid = formValid;
      }
    }
    if (!this.state.deliveryerrorshow) {
      Fdeliveryerrorshow = true;

      if (!_.isNil(SDLdata) && SDLdata.length > 0) {
        const { formValid, errorMessage } = getErrorMessageByData(userValidData, SDLdata);

        Deliveryerrors["AccentureSDLEmail"] = errorMessage;
        isValid = formValid;
        Fdeliveryerrorshow = !formValid;
      }
    }
    if (!this.state.editacessusererrorshow) {
      Feditacessusererrorshow = true;
      if (!_.isNil(EditAccesdata) && EditAccesdata.length > 0) {
        const { formValid, errorMessage } = getErrorMessageByData(userValidData, EditAccesdata);
        //EditAccessUsererrors["EditAccessUsersUnique"]="";
        EditAccessUsererrors["EditAccessUsersEmail"] = errorMessage;
        isValid = formValid;
        Feditacessusererrorshow = !formValid;
      }
    }
    if (!this.state.viewacessusererrorshow) {
      Fviewacessusererrorshow = true;
      if (!_.isNil(ViewAccessdata) && ViewAccessdata.length > 0) {
        const { formValid, errorMessage } = getErrorMessageByData(userValidData, ViewAccessdata);
        //ViewAccessUsererrors["ViewAccessUsersUnique"] = ""
        ViewAccessUsererrors["ViewAccessUsersEmail"] = errorMessage;
        isValid = formValid;
        Fviewacessusererrorshow = !formValid;
      }
    }
    if (
      _.isNil(this.state.ClientBoardingPopupData.RequestorRemarks) ||
      !this.state.ClientBoardingPopupData.RequestorRemarks
    ) {
      noerrors["NoRequestorRemarks"] = i18n.t("Requestor_Remarks_is_Required");
      Noerrorshow = true;
      isValid = false;
    }
    if (
      Fprimeerrorshow ||
      Fviewacessusererrorshow ||
      Fdeliveryerrorshow ||
      Feditacessusererrorshow ||
      Noprimeerrorshow ||
      Nodeliveryerrorshow ||
      NoApprovalEmailErrorShow ||
      Noeditacessusererrorshow ||
      Noerrorshow ||
      this.state.primeerrorshow ||
      this.state.deliveryerrorshow ||
      this.state.editacessusererrorshow ||
      this.state.viewacessusererrorshow ||
      errors["Cxl"] ||
      errors["Oal"]
    ) {
      isValid = false;
    }

    //Screen One Validations

    Object.keys(data).map((key) => {
      switch (key) {
        case "ClientName":
          if (data[key] && !isValidText(data[key])) {
            errors[key] = InvalidDescriptionText;
          } else {
            errors[key] = "";
          }
          if (errors[key]) {
            isValid = false;
            isScreenOneMandatoryFieldEmpty = true;
            ShowClientNameErrorMsg = true;
          }
          break;

        case "City":
          if (data[key] && !isValidText(data[key])) {
            errors[key] = InvalidDescriptionText;
          } else {
            errors[key] = "";
          }
          if (errors[key]) {
            isValid = false;
            isScreenOneMandatoryFieldEmpty = true;
            ShowCityErrorMsg = true;
          }
          break;

        case "Project":
          if (data[key] && !isValidText(data[key])) {
            errors[key] = InvalidDescriptionText;
          } else {
            errors[key] = "";
          }
          if (errors[key]) {
            isValid = false;
            isScreenOneMandatoryFieldEmpty = true;
            ShowProjectErrorMsg = true;
          }
          break;

        case "CoreBeatClientName":
          data[key] ? (errors[key] = "") : (errors[key] = i18n.t("Client_Name_is_required"));
          if (errors[key]) {
            isValid = false;
            isScreenOneMandatoryFieldEmpty = true;
            ShowClientDropdownErrorMsg = true;
          }
          break;

        case "ClientRegion":
          if (data[key]) {
            if (data[key] == "Others") {
              if (data["OtherClientRegion"]) {
                errors["OtherClientRegion"] = "";

                if (!isValidText(data["OtherClientRegion"])) {
                  errors["OtherClientRegion"] = InvalidDescriptionText;
                  isValid = false;
                  isScreenOneMandatoryFieldEmpty = true;
                  ShowOtherClientRegionErrorMsg = true;
                }
              } else {
                errors["OtherClientRegion"] = i18n.t("Other Market Region is required");
                isValid = false;
                isScreenOneMandatoryFieldEmpty = true;
                ShowOtherClientRegionErrorMsg = true;
              }
            }
            errors[key] = "";
          } else {
            errors[key] = i18n.t("Market Region is required");
            isValid = false;
            isScreenOneMandatoryFieldEmpty = true;
            ShowClienRegionErrorMsg = true;
          }

          break;
        case "MarketUnit":
          if (data[key]) {
            if (data[key] == "Others") {
              if (data["OtherMarketUnit"]) {
                errors["OtherMarketUnit"] = "";

                if (!isValidText(data["OtherMarketUnit"])) {
                  errors["OtherMarketUnit"] = InvalidDescriptionText;
                  isValid = false;
                  isScreenOneMandatoryFieldEmpty = true;
                  ShowOtherMarketUnitErrorMsg = true;
                }
              } else {
                errors["OtherMarketUnit"] = i18n.t("Other Market Unit is required");
                isValid = false;
                isScreenOneMandatoryFieldEmpty = true;
                ShowOtherMarketUnitErrorMsg = true;
              }
            }
            errors[key] = "";
          } else {
            errors[key] = i18n.t("Market Unit is required");
            isValid = false;
            isScreenOneMandatoryFieldEmpty = true;
            ShowMarketUnitErrorMsg = true;
          }

          break;

        case "ClientRegionName":
          if (data[key]) {
            if (data[key] == "Others") {
              if (data["OtherClientRegion"]) {
                errors["OtherClientRegion"] = "";
              } else {
                errors["OtherClientRegion"] = i18n.t("Other Market Region is required");
                isValid = false;
                isScreenOneMandatoryFieldEmpty = true;
                ShowOtherClientRegionErrorMsg = true;
              }
            }
            errors[key] = "";
          } else {
            errors[key] = i18n.t("Market Region is required");
            isValid = false;
            isScreenOneMandatoryFieldEmpty = true;
            ShowClienRegionErrorMsg = true;
          }

          break;

        case "MarketUnitName":
          if (data[key]) {
            if (data[key] == "Others") {
              if (data["OtherMarketUnit"]) {
                errors["OtherMarketUnit"] = "";
              } else {
                errors["OtherMarketUnit"] = i18n.t("Other Market Unit is required");
                isValid = false;
                isScreenOneMandatoryFieldEmpty = true;
                ShowOtherMarketUnitErrorMsg = true;
              }
            }
            errors[key] = "";
          } else {
            errors[key] = i18n.t("Market Unit is required");
            isValid = false;
            isScreenOneMandatoryFieldEmpty = true;
            ShowMarketUnitErrorMsg = true;
          }
        case "DeliveryRegion":
          data[key] ? (errors[key] = "") : (errors[key] = i18n.t("Delivery_Region_is_required"));
          if (errors[key]) {
            isValid = false;
            isScreenOneMandatoryFieldEmpty = true;
            ShowDeliveryRegionErrorMsg = true;
          }
          break;
        case "Offering":
          data[key] ? (errors[key] = "") : (errors[key] = i18n.t("Offering_is_required"));
          if (errors[key]) {
            isValid = false;
            isScreenOneMandatoryFieldEmpty = true;
            ShowOfferingErrorMsg = true;
          }
          break;
        case "Process":
          data[key] ? (errors[key] = "") : (errors[key] = i18n.t("Sub_Offering_is_required"));
          if (errors[key]) {
            isValid = false;
            isScreenOneMandatoryFieldEmpty = true;
            ShowSubOfferingErrorMsg = true;
          }
          break;
        case "LocationID":
          data[key] ? (errors[key] = "") : (errors[key] = i18n.t("Delivery_Center_is_required"));
          if (errors[key]) {
            isValid = false;
            isScreenOneMandatoryFieldEmpty = true;
            ShowLocationErrorMsg = true;
          }
          break;
        case "MaturityPhaseID":
          data[key] ? (errors[key] = "") : (errors[key] = i18n.t("Maturity_Phase_is_required"));
          if (errors[key]) {
            isValid = false;
            isScreenOneMandatoryFieldEmpty = true;
            ShowMaturityPhaseErrorMsg = true;
          }
          break;
        case "Industry":
          if (data[key]) {
            if (data[key] == "Others") {
              if (data["OtherIndustry"]) {
                errors["OtherIndustry"] = "";

                if (!isValidText(data["OtherIndustry"])) {
                  errors["OtherIndustry"] = InvalidDescriptionText;
                  isValid = false;
                  isScreenOneMandatoryFieldEmpty = true;
                  ShowOtherIndustryErrorMsg = true;
                }
              } else {
                errors["OtherIndustry"] = i18n.t("Other_Industry_is_required");
                isValid = false;
                isScreenOneMandatoryFieldEmpty = true;
                ShowOtherIndustryErrorMsg = true;
              }
            }
            //errors[key] = "";
          } else {
            errors[key] = i18n.t("Industry_is_required");
            isValid = false;
            isScreenOneMandatoryFieldEmpty = true;
            ShowIndustryErrorMsg = true;
          }
          break;

        case "DeliveryRegionID":
          if (data[key] == -1) {
            if (data["OtherDeliveryRegion"]) {
              errors["OtherDeliveryRegion"] = "";

              if (!isValidText(data["OtherDeliveryRegion"])) {
                errors["OtherDeliveryRegion"] = InvalidDescriptionText;
                isValid = false;
                isScreenOneMandatoryFieldEmpty = true;
                ShowOtherDeliveryRegionErrorMsg = true;
              }
            } else {
              errors["OtherDeliveryRegion"] = i18n.t("Other_Delivery_Region_is_required");
              isValid = false;
              isScreenOneMandatoryFieldEmpty = true;
              ShowOtherDeliveryRegionErrorMsg = true;
            }
          }
          if (!data[key]) {
            errors["DeliveryRegion"] = i18n.t("Delivery_Region_is_required");
            isScreenOneMandatoryFieldEmpty = true;
            ShowDeliveryRegionErrorMsg = true;
          }

          break;
      }
    });
    this.setState({
      ...this.state,
      Noerrorshow: Noerrorshow,
      primeerrors,
      Deliveryerrors,
      EditAccessUsererrors,
      ViewAccessUsererrors,
      Noprimeerrorshow: Noprimeerrorshow,
      noerrors: noerrors,
      Nodeliveryerrorshow: Nodeliveryerrorshow,
      NoApprovalEmailErrorShow: NoApprovalEmailErrorShow,
      Noeditacessusererrorshow: Noeditacessusererrorshow,
      Fprimeerrorshow: Fprimeerrorshow,
      Fdeliveryerrorshow: Fdeliveryerrorshow,
      Feditacessusererrorshow: Feditacessusererrorshow,
      Fviewacessusererrorshow: Fviewacessusererrorshow,
      errors: errors,
      isScreenOneMandatoryFieldEmpty: isScreenOneMandatoryFieldEmpty,
      ShowClientDropdownErrorMsg: ShowClientDropdownErrorMsg,
      ShowProjectErrorMsg: ShowProjectErrorMsg,
      ShowCityErrorMsg: ShowCityErrorMsg,
      ShowClientNameErrorMsg: ShowClientNameErrorMsg,
      ShowClienRegionErrorMsg: ShowClienRegionErrorMsg,
      ShowMarketUnitErrorMsg: ShowMarketUnitErrorMsg,

      ShowMaturityPhaseErrorMsg: ShowMaturityPhaseErrorMsg,
      ShowOtherIndustryErrorMsg: ShowOtherIndustryErrorMsg,

      ShowIndustryErrorMsg: ShowIndustryErrorMsg,

      ShowOtherDeliveryRegionErrorMsg: ShowOtherDeliveryRegionErrorMsg,
      ShowDeliveryRegionErrorMsg: ShowDeliveryRegionErrorMsg,
      ShowOtherClientRegionErrorMsg: ShowOtherClientRegionErrorMsg,
      ShowOtherMarketUnitErrorMsg: ShowOtherMarketUnitErrorMsg,
      ShowOfferingErrorMsg: ShowOfferingErrorMsg,
      ShowSubOfferingErrorMsg: ShowSubOfferingErrorMsg,
      ShowLocationErrorMsg: ShowLocationErrorMsg,
    });
    return isValid;
  }
  bindTextFieldChange = (e) => {
    this.setState({ noerrors: [] });
    //client dropdown has no ID- just label and value
    const id = e.target ? e.target.id : "clientDropdown";
    const errorsObj = this.state.errors;
    const noerrorsObj = this.state.noerrors;
    let data = this.state.ClientBoardingPopupData;
    let datatwo = this.state.EditData;

    switch (id) {
      case "Prime-Lead":
        data.AccentureMD = e.target.value;
        datatwo.AccentureMD = e.target.value;
        noerrorsObj.NoAccentureMD = "";
        this.setState({
          Noprimeerrorshow: true,
          noerrors: noerrorsObj,
        });

        break;
      case "Delivery-Lead":
        data.AccentureSDL = e.target.value;
        datatwo.AccentureSDL = e.target.value;
        noerrorsObj.NoAccentureSDL = "";
        this.setState({
          Noprimeerrorshow: true,
          noerrors: noerrorsObj,
        });
        //}
        break;
      case "Cxl":
        data.Cxl = e.target.value;
        datatwo.Cxl = e.target.value;
        errorsObj.Cxl = "";
        this.setState({
          errors: errorsObj,
        });

        break;
      case "Oal":
        data.Oal = e.target.value;
        datatwo.Oal = e.target.value;
        errorsObj.Oal = "";
        this.setState({
          errors: errorsObj,
        });

        break;
      case "Edit-Access":
        data.EditAccessUsers = e.target.value;
        datatwo.EditAccessUsers = e.target.value;
        noerrorsObj.NoEditAccessUsers = "";
        this.setState({
          Noprimeerrorshow: true,
          noerrors: noerrorsObj,
        });
        break;
      case "View-Access":
        data.ViewAccessUsers = e.target.value;
        datatwo.ViewAccessUsers = e.target.value;

        //view access is an optional field
        break;
      case "ReqRem":
        data.RequestorRemarks = e.target.value;
        datatwo.RequestorRemarks = e.target.value;
        noerrorsObj.NoRequestorRemarks = "";

        this.setState({ noerrors: noerrorsObj, Noerrorshow: false });
        break;

      case "clientDropdown":
        data.CoreBeatClientName = e.value;

        errorsObj.ClientName = "";
        this.setState({ errors: errorsObj });
        break;

      case "market":
        data.ClientRegion = e.target.value;
        errorsObj.ClientRegion = "";
        this.setState({ errors: errorsObj });
        break;

      case "marketUnit":
        data.ClientRegion = e.target.value;
        errorsObj.ClientRegion = "";
        this.setState({ errors: errorsObj });
        break;

      case "OtherMarketUnit":
        data.ClientRegion = e.target.value;
        errorsObj.ClientRegion = "";
        this.setState({ errors: errorsObj });
        break;

      case "OtherClientRegion":
        data.OtherClientRegion = e.target.value;
        errorsObj.OtherClientRegion = "";
        this.setState({ errors: errorsObj });
        break;

      case "offering":
        data.Offering = e.target.value;

        errorsObj.Offering = "";
        this.setState({ errors: errorsObj });
        break;

      case "suboffering":
        data.Process = e.target.value;
        errorsObj.Process = "";
        this.setState({ errors: errorsObj });
        break;

      case "region":
        data.DeliveryRegionID = e.target.value;

        errorsObj.DeliveryRegion = "";

        this.setState({ errors: errorsObj });
        break;

      case "center":
        data.CoreBeatLocation = e.target.value;

        errorsObj.City = "";
        this.setState({ errors: errorsObj });
        break;

      case "industry":
        data.Industry = e.target.value;

        errorsObj.Industry = "";
        this.setState({ errors: errorsObj });
        break;

      case "maturity":
        data.MaturityPhaseID = e.target.value;

        errorsObj.MaturityPhase = "";
        this.setState({ errors: errorsObj });

        break;
    }
    this.setState({
      ClientBoardingPopupData: data,
      EditData: datatwo,
    });
  };
  blurAccentureMD = () => {
    let primeerrors = {};
    let AccentureMDvalueb = [];
    let self = this;
    let primeerrorshow = false;
    this.state.ClientBoardingPopupData.AccentureMD &&
      this.state.ClientBoardingPopupData.AccentureMD.split(",").forEach(function (obj) {
        /*  if (!self.isEmail(obj.trim())) {
          primeerrors["AccentureMDEmail"] = i18n.t("Please_correct_the_Enterprise_ID");
          primeerrorshow = true;
        } */
        if (AccentureMDvalueb.indexOf(obj.trim()) === -1) {
          AccentureMDvalueb.push(obj.trim());
        } else {
          primeerrors["AccentureMDUnique"] = i18n.t("Please_enter_unique_Enterprise_ID");
          primeerrorshow = true;
        }
      });
    this.setState({ primeerrors, primeerrorshow });
  };
  handleAccentureMDKeyDown = (evt) => {
    if (["Enter", "Tab", ""].includes(evt.key)) {
      evt.preventDefault();
      let primeerrors = {};
      let self = this;
      let AccentureMDvaluehp = [];
      let primeerrorshow = false;
      this.state.ClientBoardingPopupData.AccentureMD &&
        this.state.ClientBoardingPopupData.AccentureMD.split(",").forEach(function (obj) {
          /* if (!self.isEmail(obj.trim())) {
            primeerrors["AccentureMDEmail"] = i18n.t("Please_correct_the_Enterprise_ID");
            primeerrorshow = true;
          } */
          if (AccentureMDvaluehp.indexOf(obj.trim()) === -1) {
            AccentureMDvaluehp.push(obj.trim());
            primeerrorshow = false;
          } else {
            primeerrors["AccentureMDUnique"] = i18n.t("Please_enter_unique_Enterprise_ID");
            primeerrorshow = true;
          }
        });
      this.setState({
        primeerrors: primeerrors,
        primeerrorshow: primeerrorshow,
      });
    }
  };
  blurCxlOal = (e) => {
    const { ClientBoardingPopupData } = this.state;
    const { errors } = this.state;
    let uniqueEmailArr = [];

    if (e.target.id == "Cxl") {
      ClientBoardingPopupData.Cxl &&
        ClientBoardingPopupData.Cxl.split(",").forEach(function (obj) {
          if (uniqueEmailArr.indexOf(obj.trim()) === -1) {
            uniqueEmailArr.push(obj.trim());
          } else {
            errors["Cxl"] = i18n.t("Please_enter_unique_Enterprise_ID");
          }
          if (uniqueEmailArr.length > 4) {
            errors["Cxl"] = i18n.t("Only 4 unique Enterprise ID are allowed");
          }
        });
    } else {
      ClientBoardingPopupData.Oal &&
        ClientBoardingPopupData.Oal.split(",").forEach(function (obj) {
          if (uniqueEmailArr.indexOf(obj.trim()) === -1) {
            uniqueEmailArr.push(obj.trim());
          } else {
            errors["Oal"] = i18n.t("Please_enter_unique_Enterprise_ID");
          }
          if (uniqueEmailArr.length > 4) {
            errors["Oal"] = i18n.t("Only 4 unique Enterprise ID are allowed");
          }
        });
    }
    this.setState({
      errors,
    });
  };

  blurAccentureSDL = () => {
    let Deliveryerrors = {};
    let AccentureSDLvalueb = [];
    let self = this;
    let deliveryerrorshow = false;
    this.state.ClientBoardingPopupData.AccentureSDL &&
      this.state.ClientBoardingPopupData.AccentureSDL.split(",").forEach(function (obj) {
        /* if (!self.isEmail(obj.trim())) {
          Deliveryerrors["AccentureSDLEmail"] = i18n.t("Please_correct_the_Enterprise_ID");
          deliveryerrorshow = true;
        } */
        if (AccentureSDLvalueb.indexOf(obj.trim()) === -1) {
          AccentureSDLvalueb.push(obj.trim());
        } else {
          Deliveryerrors["AccentureSDLUnique"] = i18n.t("Please_enter_unique_Enterprise_ID");
          deliveryerrorshow = true;
        }
      });
    this.setState({
      Deliveryerrors: Deliveryerrors,
      deliveryerrorshow: deliveryerrorshow,
    });
  };
  handleAccentureSDLKeyDown = (evt) => {
    if (["Enter", "Tab", ""].includes(evt.key)) {
      evt.preventDefault();
      let Deliveryerrors = {};
      let self = this;
      let deliveryerrorshow = false;
      let AccentureSDLvaluehp = [];
      this.state.ClientBoardingPopupData.AccentureSDL &&
        this.state.ClientBoardingPopupData.AccentureSDL.split(",").forEach(function (obj) {
          /* if (!self.isEmail(obj.trim())) {
            Deliveryerrors["AccentureSDLEmail"] = i18n.t("Please_correct_the_Enterprise_ID");
            deliveryerrorshow = true;
          } */
          if (AccentureSDLvaluehp.indexOf(obj.trim()) === -1) {
            AccentureSDLvaluehp.push(obj.trim());
          } else {
            Deliveryerrors["AccentureSDLUnique"] = i18n.t("Please_enter_unique_Enterprise_ID");
            deliveryerrorshow = true;
          }
        });
      this.setState({ Deliveryerrors: Deliveryerrors });
    }
  };

  blurEditAccessUsers = () => {
    let EditAccessUsererrors = {};
    let EditAccessUsersb = [];
    let self = this;
    let editacessusererrorshow = false;
    this.state.ClientBoardingPopupData.EditAccessUsers &&
      this.state.ClientBoardingPopupData.EditAccessUsers.split(",").forEach(function (obj) {
        /* if (!self.isEmail(obj.trim())) {
          EditAccessUsererrors["EditAccessUsersEmail"] = i18n.t("Please_correct_the_Enterprise_ID");
          editacessusererrorshow = true;
        } */
        if (EditAccessUsersb.indexOf(obj.trim()) === -1) {
          EditAccessUsersb.push(obj.trim());
        } else {
          EditAccessUsererrors["EditAccessUsersUnique"] = i18n.t(
            "Please_enter_unique_Enterprise_ID"
          );
          editacessusererrorshow = true;
        }
      });
    this.setState({
      EditAccessUsererrors: EditAccessUsererrors,
      editacessusererrorshow: editacessusererrorshow,
    });
  };

  handleEditAccessUsersDown = (evt) => {
    let editacessusererrorshow = false;
    if (["Enter", "Tab", ""].includes(evt.key)) {
      evt.preventDefault();
      let EditAccessUsererrors = {};
      let self = this;
      let EditAccessUsershp = [];
      this.state.ClientBoardingPopupData.EditAccessUsers &&
        this.state.ClientBoardingPopupData.EditAccessUsers.split(",").forEach(function (obj) {
          /*  if (!self.isEmail(obj.trim())) {
            EditAccessUsererrors["EditAccessUsersEmail"] = i18n.t("Please_correct_the_Enterprise_ID");
            editacessusererrorshow = true;
          } */
          if (EditAccessUsershp.indexOf(obj.trim()) === -1) {
            EditAccessUsershp.push(obj.trim());
          } else {
            EditAccessUsererrors["EditAccessUsersUnique"] = i18n.t(
              "Please_enter_unique_Enterprise_ID"
            );
            editacessusererrorshow = true;
          }
        });
      this.setState({
        EditAccessUsererrors: EditAccessUsererrors,
        editacessusererrorshow: editacessusererrorshow,
      });
    }
  };

  blurViewAccessUsers = () => {
    let ViewAccessUsererrors = {};
    let ViewAccessUsersb = [];
    let viewacessusererrorshow = false;
    let self = this;
    this.state.ClientBoardingPopupData.ViewAccessUsers &&
      this.state.ClientBoardingPopupData.ViewAccessUsers.split(",").forEach(function (obj) {
        /*  if (!self.isEmail(obj.trim())) {
          ViewAccessUsererrors["ViewAccessUsersEmail"] = i18n.t("Please_correct_the_Enterprise_ID");
          viewacessusererrorshow = true;
        } */
        if (ViewAccessUsersb.indexOf(obj.trim()) === -1) {
          ViewAccessUsersb.push(obj.trim());
        } else {
          ViewAccessUsererrors["ViewAccessUsersUnique"] = i18n.t(
            "Please_enter_unique_Enterprise_ID"
          );
          viewacessusererrorshow = true;
        }
      });
    this.setState({
      ViewAccessUsererrors: ViewAccessUsererrors,
      viewacessusererrorshow: viewacessusererrorshow,
    });
  };

  handleViewAccessUsersDown = (evt) => {
    if (["Enter", "Tab", ""].includes(evt.key)) {
      evt.preventDefault();
      let ViewAccessUsererrors = {};
      let self = this;
      let viewacessusererrorshow = false;
      let ViewAccessUsershp = [];
      this.state.ClientBoardingPopupData.ViewAccessUsers &&
        this.state.ClientBoardingPopupData.ViewAccessUsers.split(",").forEach(function (obj) {
          /* if (!self.isEmail(obj.trim())) {
            ViewAccessUsererrors["ViewAccessUsersEmail"] = i18n.t("Please_correct_the_Enterprise_ID");
            viewacessusererrorshow = true;
          } */
          if (ViewAccessUsershp.indexOf(obj.trim()) === -1) {
            ViewAccessUsershp.push(obj.trim());
          } else {
            ViewAccessUsererrors["ViewAccessUsersUnique"] = i18n.t(
              "Please_enter_unique_Enterprise_ID"
            );
            viewacessusererrorshow = true;
          }
        });
      this.setState({
        ViewAccessUsererrors: ViewAccessUsererrors,
        viewacessusererrorshow: viewacessusererrorshow,
      });
    }
  };

  isEmail(email) {
    return regexForAccentureDomainEmail.test(email);
  }
  emailFileValidation = (e) => {
    let errors = {};
    let emlerror = [];
    let EmailInput = document.getElementById("approvalEmailFile");
    let selectedemlFile = e.target.files[0];
    let fileSizeInKB = parseFloat(selectedemlFile.size / 1000).toFixed(2);

    this.setState({
      selectedEmailFileSizeInKB: fileSizeInKB,
      selectedEmailFileName: selectedemlFile.name,
      isEmailFileSelected: true,
      NoApprovalEmailErrorShow: false,
    });
    let filePath = EmailInput.value;
    // Allowing file type
    let fileExtension =
      filePath.substring(filePath.lastIndexOf(".") + 1, filePath.length) || filePath;
    if (String(fileExtension) !== "msg") emlerror["email"] = i18n.t("Invalid_Email_Message");
    let ClientBoardingPopupData = this.state.ClientBoardingPopupData;
    ClientBoardingPopupData.selectedEmailfile = selectedemlFile;
    let emlfileName = e.target.value.split("\\").pop();
    ClientBoardingPopupData.emailfileName = emlfileName;

    if (
      parseFloat(selectedemlFile.size / 1000).toFixed(2) <=
      this.state.configKeys.emailFileMaxSizeInKB
    ) {
      //only if there was no error for file extension clear out the message here
      if (!emlerror["email"])
        //(2 * 1024) for 20kb
        emlerror["email"] = "";
    } else {
      emlerror["email"] =
        i18n.t("Email_error_msg") + this.state.configKeys.emailFileMaxSizeInKB + " KB";
    }

    this.setState({
      ClientBoardingPopupData: ClientBoardingPopupData,
      emailerror: emlerror,
      selectedEmailfile: e.target.files[0],
    });
  };

  clientlogoaliasfetch(clientname) {
    let ClientBoardingPopupData = this.state.ClientBoardingPopupData;
    ClientBoardingPopupData.OrgEntityName = "";
    ClientBoardingPopupData.OrgEntityImage = "";
    this.setState({
      ClientBoardingPopupData: ClientBoardingPopupData,
      decode: null,
    });
    axiosInstance
      .get(`${LocalApiBaseUrl}DealOnboarding/GetLogoAndAliasNameByOrgEntityID`, {
        params: {
          OrgEntityName: clientname.trim(),
        },
      })
      .then((res) => {
        let row = res.data;
        // ClientBoardingPopupData.ClientAliasName = row.ClientAliasName;
        //no need to prepopulate alias name for now - hema's suggestion not taken

        if (row.OrgEntityImage == null) {
          ClientBoardingPopupData.OrgEntityImage = row.OrgEntityImage;
        } else {
          ClientBoardingPopupData.OrgEntityImage = "/SOImages/" + row.OrgEntityImage;
        }

        ClientBoardingPopupData.OrgEntityID = row.OrgEntityID;
        this.setState({ ClientBoardingPopupData: ClientBoardingPopupData });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  onClientChange(ClientObj) {
    //, clientData
    let ClientBoardingPopupData = this.state.ClientBoardingPopupData;
    ClientBoardingPopupData.CoreBeatLocation = "";
    ClientBoardingPopupData.ClientName = "";

    this.setState({ ClientBoardingPopupData });

    if (ClientObj.target.textContent) {
      let OrgId = 0;
      if (
        this.state.OrgStructureData.find(
          (org) => String(org.ClientName) === String(ClientObj.target.textContent)
        )
      ) {
        OrgId = this.state.OrgStructureData.find(
          (org) => String(org.ClientName) === String(ClientObj.target.textContent)
        ).ID;
      }

      let DeliveryCenter = this.state.OrgStructureData.filter(
        (org) => String(org.ClientName) === String(ClientObj.target.textContent)
      );

      setTimeout(() => {
        this.closeCustomDropdown();
      }, 100);
      this.setState(
        {
          ...this.state,
          DeliveryCenterData: DeliveryCenter,
          ShowClientDropdownErrorMsg: false,

          ClientBoardingPopupData: {
            ...this.state.ClientBoardingPopupData,
            OrgEntityID: OrgId,
            CoreBeatClientName: ClientObj.target.textContent,
          },
        },

        () => {
          this.clientlogoaliasfetch(this.state.ClientBoardingPopupData.CoreBeatClientName);
        }
      );
    } else {
      this.setState({
        ...this.state,
        ShowClientDropdownErrorMsg: true,
        ClientBoardingPopupData: {
          ...this.state.ClientBoardingPopupData,
          OrgEntityID: "",
          ClientName: "",
          CoreBeatClientName: "",
        },
      });
    }
  }

  onClientAliasChange(e) {
    _.isEmpty(this.props.EditData)
      ? this.setState({
          ...this.state,
          ClientBoardingPopupData: {
            ...this.state.ClientBoardingPopupData,
            ClientName: e.target.value,
          },
        })
      : this.setState({
          ...this.state,
          EditData: {
            ...this.state.EditData,
            ClientName: e.target.value,
          },
        });
  }

  onMarketUnitChange(value) {
    if (_.isEmpty(this.props.EditData)) {
      if (value) {
        if (String(value) === "Others") {
          this.setState({
            ShowMarketUnitErrorMsg: false,
            ClientBoardingPopupData: {
              ...this.state.ClientBoardingPopupData,
              MarketUnit: value,
              MarketUnitID: -1,
            },
          });
        } else {
          const index = this.state.ClientRegionData.findIndex(
            (client) => client.ClientRegionName == value
          );
          if (index > -1) {
            let MarketUnitId = this.state.ClientRegionData[index].ID;
            this.setState({
              ShowMarketUnitErrorMsg: false,
              ClientBoardingPopupData: {
                ...this.state.ClientBoardingPopupData,
                MarketUnit: value,
                MarketUnitID: MarketUnitId,
                OtherMarketUnit: "",
              },
            });
          }
        }
      } else {
        this.setState({
          ...this.state,
          ShowMarketUnitErrorMsg: false,
          ClientBoardingPopupData: {
            ...this.state.ClientBoardingPopupData,
            MarketUnit: "",
            MarketUnitID: -1,
            OtherMarketUnit: "",
          },
        });
      }
    } else {
      if (value) {
        if (String(value) === "Others") {
          this.setState({
            ...this.state,
            ShowMarketUnitErrorMsg: false,
            EditData: {
              ...this.state.EditData,
              MarketUnit: value,
              MarketUnitID: -1,
            },
          });
        } else {
          const index = this.state.ClientRegionData.findIndex(
            (client) => client.ClientRegionName == value
          );
          if (index > -1) {
            let MarketUnitId = this.state.ClientRegionData[index].ID;
            this.setState({
              ...this.state,
              ShowMarketUnitErrorMsg: false,
              EditData: {
                ...this.state.EditData,
                MarketUnit: value,
                MarketUnitID: MarketUnitId,
                OtherMarketUnit: "",
              },
            });
          }
        }
      } else {
        this.setState({
          ...this.state,
          ShowClienRegionErrorMsg: false,
          EditData: {
            ...this.state.EditData,
            MarketUnit: "",
            MarketUnitID: -1,
            OtherMarketUnit: "",
          },
        });
      }
    }
    setTimeout(() => {
      this.closeCustomDropdown();
    }, 100);
  }

  onClientRegionChange(e) {
    if (_.isEmpty(this.props.EditData)) {
      if (e.target.textContent) {
        if (String(e.target.textContent) === "Others") {
          this.setState({
            ...this.state,
            ShowClienRegionErrorMsg: false,
            ClientBoardingPopupData: {
              ...this.state.ClientBoardingPopupData,
              ClientRegion: e.target.textContent,
              ClientRegionID: -1,
            },
          });
        } else {
          let ClientRegionId = this.state.ClientRegionData.find(
            (client) => client.ClientRegionName == e.target.textContent
          ).ID;

          this.setState({
            ...this.state,
            ShowClienRegionErrorMsg: false,
            ClientBoardingPopupData: {
              ...this.state.ClientBoardingPopupData,
              ClientRegion: e.target.textContent,
              ClientRegionID: ClientRegionId,
              OtherClientRegion: "",
            },
          });
        }
      } else {
        this.setState({
          ...this.state,
          ShowClienRegionErrorMsg: false,
          ClientBoardingPopupData: {
            ...this.state.ClientBoardingPopupData,
            ClientRegion: "",
            ClientRegionID: -1,
            OtherClientRegion: "",
          },
        });
      }
    } else {
      if (e.target.textContent) {
        if (String(e.target.textContent) === "Others") {
          this.setState({
            ...this.state,
            ShowClienRegionErrorMsg: false,
            EditData: {
              ...this.state.EditData,
              ClientRegionName: e.target.textContent,
              ClientRegionID: -1,
            },
          });
        } else {
          let ClientRegionId = this.state.ClientRegionData.find(
            (client) => client.ClientRegionName == e.target.textContent
          ).ID;
          this.setState({
            ...this.state,
            ShowClienRegionErrorMsg: false,
            EditData: {
              ...this.state.EditData,
              ClientRegionName: e.target.textContent,
              ClientRegionID: ClientRegionId,
              OtherClientRegion: "",
            },
          });
        }
      } else {
        this.setState({
          ...this.state,
          ShowClienRegionErrorMsg: false,
          EditData: {
            ...this.state.EditData,
            ClientRegionName: "",
            ClientRegionID: -1,
            OtherClientRegion: "",
          },
        });
      }
    }
    setTimeout(() => {
      this.closeCustomDropdown();
    }, 100);
  }

  onOtherClientRegionChange(e) {
    _.isEmpty(this.props.EditData)
      ? this.setState({
          ...this.state,
          ShowOtherClientRegionErrorMsg: false,
          ClientBoardingPopupData: {
            ...this.state.ClientBoardingPopupData,
            OtherClientRegion: e.target.value,
          },
        })
      : this.setState({
          ...this.state,
          ShowOtherClientRegionErrorMsg: false,
          EditData: {
            ...this.state.EditData,
            OtherClientRegion: e.target.value,
          },
        });
  }

  onOtherMarketUnitChange(e) {
    _.isEmpty(this.props.EditData)
      ? this.setState({
          ...this.state,
          ShowOtherMarketUnitErrorMsg: false,
          ClientBoardingPopupData: {
            ...this.state.ClientBoardingPopupData,
            OtherMarketUnit: e.target.value,
          },
        })
      : this.setState({
          ...this.state,
          ShowOtherMarketUnitErrorMsg: false,
          EditData: {
            ...this.state.EditData,
            OtherMarketUnit: e.target.value,
          },
        });
  }
  oncolourchange(e) {
    _.isEmpty(this.props.EditData)
      ? this.setState({
          ...this.state,
          ClientBoardingPopupData: {
            ...this.state.ClientBoardingPopupData,
            ColorCode: e.target.value,
          },
        })
      : this.setState({
          ...this.state,
          EditData: {
            ...this.state.EditData,
            ColorCode: e.target.value,
          },
        });
  }

  onOtherDeliveryRegionChange(e) {
    _.isEmpty(this.props.EditData)
      ? this.setState({
          ...this.state,
          ShowOtherDeliveryRegionErrorMsg: false,
          ClientBoardingPopupData: {
            ...this.state.ClientBoardingPopupData,
            OtherDeliveryRegion: e.target.value,
          },
        })
      : this.setState({
          ...this.state,
          ShowOtherDeliveryRegionErrorMsg: false,
          EditData: {
            ...this.state.EditData,
            OtherDeliveryRegion: e.target.value,
          },
        });
  }

  onProjectNameChange(e) {
    _.isEmpty(this.props.EditData)
      ? this.setState({
          ...this.state,
          ClientBoardingPopupData: {
            ...this.state.ClientBoardingPopupData,
            Project: e.target.value,
          },
        })
      : this.setState({
          ...this.state,
          EditData: {
            ...this.state.EditData,
            Project: e.target.value,
          },
        });
  }

  onDeliveryRegionChange(e) {
    if (_.isEmpty(this.props.EditData)) {
      if (e.target.textContent) {
        if (String(e.target.id) === "-1") {
          this.setState({
            ...this.state,
            ShowDeliveryRegionErrorMsg: false,
            ClientBoardingPopupData: {
              ...this.state.ClientBoardingPopupData,
              DeliveryRegion: "Others",
              DeliveryRegionID: e.target.id,
            },
          });
        } else {
          let DeliveryRegion = this.state.DeliveryRegionData.find(
            (delivery) => delivery.ID == e.target.id
          ).DeliveryRegionName;
          this.setState({
            ...this.state,
            ShowDeliveryRegionErrorMsg: false,
            ClientBoardingPopupData: {
              ...this.state.ClientBoardingPopupData,
              DeliveryRegion: DeliveryRegion,
              DeliveryRegionID: e.target.id,
              OtherDeliveryRegion: "",
            },
          });
        }
      } else {
        this.setState({
          ...this.state,
          ShowDeliveryRegionErrorMsg: true,
          ClientBoardingPopupData: {
            ...this.state.ClientBoardingPopupData,
            DeliveryRegion: "",
            DeliveryRegionID: "",
          },
        });
      }
    } else {
      if (e.target.textContent) {
        if (String(e.target.id) === "-1") {
          this.setState({
            ...this.state,
            ShowDeliveryRegionErrorMsg: false,
            EditData: {
              ...this.state.EditData,
              DeliveryRegion: "Others",
              DeliveryRegionID: e.target.id,
            },
          });
        } else {
          let DeliveryRegion = this.state.DeliveryRegionData.find(
            (delivery) => delivery.ID == e.target.id
          ).DeliveryRegionName;
          this.setState({
            ...this.state,
            ShowDeliveryRegionErrorMsg: false,
            EditData: {
              ...this.state.EditData,
              DeliveryRegion: DeliveryRegion,
              DeliveryRegionID: e.target.id,
              OtherDeliveryRegion: "",
            },
          });
        }
      } else {
        this.setState({
          ...this.state,
          ShowDeliveryRegionErrorMsg: true,
          EditData: {
            ...this.state.EditData,
            DeliveryRegion: "",
            DeliveryRegionID: "",
          },
        });
      }
    }

    setTimeout(() => {
      this.closeCustomDropdown();
    }, 100);
  }

  onOfferingChange(e) {
    if (_.isEmpty(this.props.EditData)) {
      if (e.target.textContent) {
        let OfferingId = this.state.OfferingData.find(
          (offering) => offering.OfferingName == e.target.textContent
        ).OfferingID;
        let SubOffering = this.state.OfferingData.find(
          (offering) => String(offering.OfferingName) === String(e.target.textContent)
        ).ChildOfferings.filter((suboff) => suboff.IsActive === true);
        const filtersSubofferingData = filterSelectedSubOffering(
          [...this.state.SubOfferingData],
          0
        );
        const allLeafNodes = filtersSubofferingData.allLeafNodes;
        this.setState({
          ...this.state,
          ShowOfferingErrorMsg: false,
          SubOfferingData: SubOffering,
          leafNodes: allLeafNodes,
          ClientBoardingPopupData: {
            ...this.state.ClientBoardingPopupData,
            Offering: e.target.textContent,
            OfferingID: OfferingId,
            Process: "",
            ProcessID: "",
          },
        });
      } else {
        this.setState({
          ...this.state,
          ShowOfferingErrorMsg: true,
          ClientBoardingPopupData: {
            ...this.state.ClientBoardingPopupData,

            Offering: "",
            OfferingID: "",
          },
        });
      }
    } else {
      if (e.target.textContent) {
        let OfferingId = this.state.OfferingData.find(
          (offering) => offering.OfferingName == e.target.textContent
        ).OfferingID;
        let SubOffering = this.state.OfferingData.find(
          (offering) => String(offering.OfferingName) === String(e.target.textContent)
        ).ChildOfferings.filter((suboff) => suboff.IsActive === true);
        const filtersSubofferingData = filterSelectedSubOffering(
          [...this.state.SubOfferingData],
          0
        );
        const allLeafNodes = filtersSubofferingData.allLeafNodes;

        this.setState({
          ...this.state,
          ShowOfferingErrorMsg: false,
          SubOfferingData: SubOffering,
          leafNodes: allLeafNodes,
          EditData: {
            ...this.state.EditData,
            Process: "",
            Offering: e.target.textContent,
            OfferingID: OfferingId,
          },
        });
      } else {
        this.setState({
          ...this.state,
          ShowOfferingErrorMsg: true,
          EditData: {
            ...this.state.EditData,
            Process: "",
            Offering: "",
            OfferingID: "",
          },
        });
      }
    }

    setTimeout(() => {
      this.closeCustomDropdown();
    }, 100);
  }

  onSubOfferingChange(e) {
    if (_.isEmpty(this.props.EditData)) {
      if (e.target.value) {
        const filtersSubofferingData = filterSelectedSubOffering(
          [...this.state.SubOfferingData],
          e.target.value
        );
        const selectedSuboffering = filtersSubofferingData.subofferingData[0];

        this.setState({
          ...this.state,
          ShowSubOfferingErrorMsg: false,
          ClientBoardingPopupData: {
            ...this.state.ClientBoardingPopupData,
            Process: selectedSuboffering.Process,
            ProcessID: e.target.value,
          },
        });
      } else {
        this.setState({
          ...this.state,
          ShowSubOfferingErrorMsg: true,
          ClientBoardingPopupData: {
            ...this.state.ClientBoardingPopupData,
            Process: "",
            ProcessID: "",
          },
        });
      }
    } else {
      if (e.target.value) {
        const filtersSubofferingData = filterSelectedSubOffering(
          [...this.state.SubOfferingData],
          e.target.value
        );
        const selectedSuboffering = filtersSubofferingData.subofferingData[0];

        this.setState({
          ...this.state,
          ShowSubOfferingErrorMsg: false,
          EditData: {
            ...this.state.EditData,
            Process: selectedSuboffering.Process,
            ProcessID: e.target.value,
          },
        });
      } else {
        this.setState({
          ...this.state,
          ShowSubOfferingErrorMsg: true,
          EditData: {
            ...this.state.EditData,
            Process: "",
            ProcessID: "",
          },
        });
      }
    }

    setTimeout(() => {
      this.closeCustomDropdown();
    }, 100);
  }

  onDeliveryCenterChange(e) {
    if (_.isEmpty(this.props.EditData)) {
      if (e.target.textContent) {
        let DeliveryCenterId = -1;

        if (
          this.state.DeliveryCenterData.find(
            (deliverycenter) => deliverycenter.DeliveryCentre == e.target.textContent
          )
        ) {
          DeliveryCenterId = this.state.DeliveryCenterData.find(
            (deliverycenter) => deliverycenter.DeliveryCentre == e.target.textContent
          ).ID;
        }

        let deliveryCentre = e.target.textContent;

        this.setState((prevState) => ({
          ...prevState,
          ShowLocationErrorMsg: false,
          ClientBoardingPopupData: {
            ...this.state.ClientBoardingPopupData,

            CoreBeatLocation: deliveryCentre,
            LocationID: DeliveryCenterId,
          },
        }));
      } else {
        this.setState({
          ...this.state,
          ShowLocationErrorMsg: true,
          ClientBoardingPopupData: {
            ...this.state.ClientBoardingPopupData,
            CoreBeatLocation: "",

            LocationID: "",
          },
        });
      }
    } else {
      if (e.target.textContent) {
        let DeliveryCenterId = -1;

        if (
          this.state.DeliveryCenterData.find(
            (deliverycenter) => deliverycenter.DeliveryCentre == e.target.textContent
          )
        ) {
          DeliveryCenterId = this.state.DeliveryCenterData.find(
            (deliverycenter) => deliverycenter.DeliveryCentre == e.target.textContent
          ).ID;
        }
        this.setState({
          ...this.state,
          ShowLocationErrorMsg: false,
          EditData: {
            ...this.state.EditData,
            CoreBeatLocation: e.target.textContent,
            LocationID: DeliveryCenterId,
          },
        });
      } else {
        this.setState({
          ...this.state,
          ShowLocationErrorMsg: true,
          EditData: {
            ...this.state.EditData,
            CoreBeatLocation: "",
            LocationID: "",
          },
        });
      }
    }
    setTimeout(() => {
      this.closeCustomDropdown();
    }, 100);
  }

  onDeliveryCentreAliasChange(e) {
    if (_.isEmpty(this.props.EditData)) {
      this.setState({
        ...this.state,
        ClientBoardingPopupData: {
          ...this.state.ClientBoardingPopupData,
          City: e.target.value,
        },
      });
    } else {
      this.setState({
        ...this.state,
        EditData: {
          ...this.state.EditData,
          City: e.target.value,
        },
      });
    }
  }

  onMaturityChange(e) {
    if (_.isEmpty(this.props.EditData)) {
      if (e.target.value) {
        let MaturityPhase = this.state.MaturityPhaseData.find(
          (maturityphase) => String(maturityphase.ID) === String(e.target.value)
        ).PhaseName;
        this.setState({
          ...this.state,
          ShowMaturityPhaseErrorMsg: false,
          ClientBoardingPopupData: {
            ...this.state.ClientBoardingPopupData,
            MaturityPhase: MaturityPhase,
            MaturityPhaseID: e.target.value,
          },
        });
      } else {
        this.setState({
          ...this.state,
          ShowMaturityPhaseErrorMsg: true,
          ClientBoardingPopupData: {
            ...this.state.ClientBoardingPopupData,
            MaturityPhase: "",
            MaturityPhaseID: e.target.value,
          },
        });
      }
    } else {
      if (e.target.value) {
        let MaturityPhase = this.state.MaturityPhaseData.find(
          (maturityphase) => maturityphase.ID == e.target.value
        ).PhaseName;
        this.setState({
          ...this.state,
          ShowMaturityPhaseErrorMsg: false,
          EditData: {
            ...this.state.EditData,
            MaturityPhase: MaturityPhase,
            MaturityPhaseID: e.target.value,
          },
        });
      } else {
        this.setState({
          ...this.state,
          ShowMaturityPhaseErrorMsg: true,
          EditData: {
            ...this.state.EditData,
            MaturityPhase: "",
            MaturityPhaseID: e.target.value,
          },
        });
      }
    }
  }

  onIndustryChange(e) {
    if (_.isEmpty(this.props.EditData)) {
      if (e.target.textContent) {
        let IndustryId = this.state.IndustryData.find(
          (industry) => industry.Industry == e.target.textContent
        ).IndustryId;
        this.setState({
          ...this.state,
          ShowIndustryErrorMsg: false,
          ClientBoardingPopupData: {
            ...this.state.ClientBoardingPopupData,
            Industry: e.target.textContent,
            IndustryID: IndustryId,
          },
        });
      } else {
        this.setState({
          ...this.state,
          ClientBoardingPopupData: {
            ...this.state.ClientBoardingPopupData,
            ShowIndustryErrorMsg: true,
            Industry: "",
            IndustryID: "",
          },
        });
      }
    } else {
      if (e.target.textContent) {
        if (String(e.target.id) === "Others") {
          this.setState({
            ...this.state,
            ShowIndustryErrorMsg: false,
            EditData: {
              ...this.state.EditData,
              OtherIndustry: "",
              Industry: e.target.textContent,
              IndustryID: -1,
            },
          });
        } else {
          let IndustryId = this.state.IndustryData.find(
            (industry) => industry.Industry == e.target.textContent
          ).IndustryId;
          this.setState({
            ...this.state,
            ShowIndustryErrorMsg: false,
            EditData: {
              ...this.state.EditData,
              OtherIndustry: "",
              Industry: e.target.textContent,
              IndustryID: IndustryId,
            },
          });
        }
      } else {
        this.setState({
          ...this.state,
          ShowIndustryErrorMsg: true,
          EditData: {
            ...this.state.EditData,
            Industry: "",
            IndustryID: "",
          },
        });
      }
    }

    setTimeout(() => {
      this.closeCustomDropdown();
    }, 100);
  }

  onOtherIndustryChange(e) {
    _.isEmpty(this.props.EditData)
      ? this.setState({
          ...this.state,
          ShowOtherIndustryErrorMsg: false,
          ClientBoardingPopupData: {
            ...this.state.ClientBoardingPopupData,
            OtherIndustry: e.target.value,
          },
        })
      : this.setState({
          ...this.state,
          ShowOtherIndustryErrorMsg: false,
          EditData: {
            ...this.state.EditData,
            OtherIndustry: e.target.value,
          },
        });
  }

  onOpenSearchableDropdown = (e) => {
    var element = e.target || e.srcElement;
    switch (element.id) {
      case "clientDropdown":
        this.setState({
          isClientDropdownSearchable: !this.state.isClientDropdownSearchable,
          isClientRegionDropdownSearchable: false,
          isOfferingDropdownSearchable: false,
          isDeliveryRegionDropdownSearchable: false,
          isLocationDropdownSearchable: false,
          isSubOfferingDropdownSearchable: false,
          searchTerm: "",
          isIndustryDropdownSearchable: false,
        });
        break;

      case "market":
        this.setState({
          isClientDropdownSearchable: false,
          isClientRegionDropdownSearchable: !this.state.isClientRegionDropdownSearchable,
          isOfferingDropdownSearchable: false,
          isDeliveryRegionDropdownSearchable: false,
          isLocationDropdownSearchable: false,
          isSubOfferingDropdownSearchable: false,
          searchTerm: "",
          isIndustryDropdownSearchable: false,
          isMarketUnitSearchable: false,
        });
        break;

      case "marketUnit":
        this.setState({
          isOfferingDropdownSearchable: false,
          isMarketUnitSearchable: !this.state.isMarketUnitSearchable,
          isClientDropdownSearchable: false,
          isDeliveryRegionDropdownSearchable: false,
          isLocationDropdownSearchable: false,
          isSubOfferingDropdownSearchable: false,
          searchTerm: "",
          isIndustryDropdownSearchable: false,
          isClientRegionDropdownSearchable: false,
        });
        break;
      case "offering":
        this.setState({
          isClientDropdownSearchable: false,
          isClientRegionDropdownSearchable: false,
          isOfferingDropdownSearchable: !this.state.isOfferingDropdownSearchable,
          isDeliveryRegionDropdownSearchable: false,
          isLocationDropdownSearchable: false,
          isSubOfferingDropdownSearchable: false,
          searchTerm: "",
          isIndustryDropdownSearchable: false,
        });
        break;
      case "region":
        this.setState({
          isClientDropdownSearchable: false,
          isClientRegionDropdownSearchable: false,
          isOfferingDropdownSearchable: false,
          isDeliveryRegionDropdownSearchable: !this.state.isDeliveryRegionDropdownSearchable,
          isLocationDropdownSearchable: false,
          isSubOfferingDropdownSearchable: false,
          searchTerm: "",
          isIndustryDropdownSearchable: false,
        });
        break;

      case "center":
        this.setState({
          isClientDropdownSearchable: false,
          isClientRegionDropdownSearchable: false,
          isOfferingDropdownSearchable: false,
          isDeliveryRegionDropdownSearchable: false,
          isLocationDropdownSearchable: !this.state.isLocationDropdownSearchable,
          isSubOfferingDropdownSearchable: false,
          searchTerm: "",
          isIndustryDropdownSearchable: false,
        });
        break;

      case "suboffering":
        this.setState({
          isClientDropdownSearchable: false,
          isClientRegionDropdownSearchable: false,
          isOfferingDropdownSearchable: false,
          isDeliveryRegionDropdownSearchable: false,
          isLocationDropdownSearchable: false,
          isSubOfferingDropdownSearchable: !this.state.isSubOfferingDropdownSearchable,
          searchTerm: "",
          isIndustryDropdownSearchable: false,
        });
        break;

      case "industry":
        this.setState({
          isClientDropdownSearchable: false,
          isClientRegionDropdownSearchable: false,
          isOfferingDropdownSearchable: false,
          isDeliveryRegionDropdownSearchable: false,
          isLocationDropdownSearchable: false,
          isSubOfferingDropdownSearchable: false,
          isIndustryDropdownSearchable: !this.state.isIndustryDropdownSearchable,
          searchTerm: "",
        });
        break;
    }
  };

  closeCustomDropdown = () => {
    this.setState({
      isClientDropdownSearchable: false,
      isClientRegionDropdownSearchable: false,
      isOfferingDropdownSearchable: false,
      isDeliveryRegionDropdownSearchable: false,
      isLocationDropdownSearchable: false,
      isSubOfferingDropdownSearchable: false,
      isIndustryDropdownSearchable: false,
      searchTerm: "",
      isMarketUnitSearchable: false,
    });
  };

  filterSearchableDropdown = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  LogoUpload(selectedFile) {
    if (selectedFile != null) {
      const data = new FormData();
      data.append("file", selectedFile, selectedFile.name);
    }
  }
  LogoDisplay = (event, editState = false) => {
    let selectedFile = event.target.files[0];
    const fileType = selectedFile["type"];
    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    let ClientBoardingPopupData = this.state.ClientBoardingPopupData;
    let cli;
    let logoerror = [];
    let file = null;
    var self = this;

    let fileName = event.target.value.split("\\").pop();
    if (!_.isNil(selectedFile)) {
      if (
        validImageTypes.includes(fileType) &&
        parseFloat(selectedFile.size / 1024).toFixed(2) <=
          this.state.configKeys.clientLogoFileMaxSizeInKB
      ) {
        ClientBoardingPopupData.fileName = fileName;
        ClientBoardingPopupData.selectedFile = selectedFile;
        logoerror["logo"] = "";

        let fileToLoad = selectedFile;
        fileName = fileToLoad.name;
        let fileReader = new FileReader();

        fileReader.onload = function (fileLoadedEvent) {
          file = fileLoadedEvent.target.result;

          self.setState({
            decode: file,
            logoerror: logoerror,
            ClientBoardingPopupData: ClientBoardingPopupData,
          });

          if (editState) {
            self.setState({
              uploadedimage: file,
            });
          }
        };
        fileReader.readAsDataURL(fileToLoad);
      } else {
        if (!validImageTypes.includes(fileType)) {
          logoerror["logo"] = i18n.t(".jpeg and .png files only");
        } else {
          logoerror["logo"] =
            i18n.t("Logo_size") + this.state.configKeys.clientLogoFileMaxSizeInKB + "KB";
        }
        this.setState({ logoerror: logoerror });
      }
    }
    this.setState({
      decode: this.state.decode !== null ? this.state.decode : null,
    });
  };

  onIsAliasChange = (event) => {
    const ClientBoardingPopupData = this.state.ClientBoardingPopupData;
    ClientBoardingPopupData.IsAlias != event.target.checked;

    this.setState({
      ClientBoardingPopupData: {
        ...this.state.ClientBoardingPopupData,
        IsAlias: event.target.checked,
        ClientName: "",
      },
    });
  };

  onIsDemoCheck = (event) => {
    const ClientBoardingPopupData = this.state.ClientBoardingPopupData;
    ClientBoardingPopupData.IsDemo = event.target.checked;

    axiosInstance
      .get(`${LocalApiBaseUrl}Offering/GetOfferingsListDealOnboarding`, {
        params: {
          LanguageID: 1,
          IsDemo: event.target.checked,
        },
      })
      .then((response) => {
        if (_.isEmpty(this.props.EditData)) {
          this.setState({
            OfferingData: response.data,
          });
        } else {
          let SubOffering = response.data.find(
            (offering) => offering.OfferingName == this.props.EditData.Offering
          ).ChildOfferings;
          this.setState({
            OfferingData: response.data,
            SubOfferingData: SubOffering,
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
    if (!ClientBoardingPopupData.IsDemo) {
      ClientBoardingPopupData.OrgEntityID = "";
      ClientBoardingPopupData.ClientName = "";
      ClientBoardingPopupData.Offering = "";
      ClientBoardingPopupData.Process = "";
      ClientBoardingPopupData.CoreBeatClientName = "";
      ClientBoardingPopupData.OrgEntityImage = "";
    }

    const data = this.state.OrgStructureData;
    const ClientData = event.target.checked
      ? [...new Set(data.map((item) => item.ClientName))]
      : [...new Set(data.filter((ind) => ind.IsDemo === false).map((item) => item.ClientName))];

    this.setState({
      ClientBoardingPopupData: ClientBoardingPopupData,
      ClientData: ClientData.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)),
      showAddNewClient: event.target.checked,
    });
  };

  showAddnewClientPopup = () => {
    this.setState({
      isNewClientPopupOpen: true,
      NewClientName: "",
      NewClientError: null,
    });
  };

  handleNewClient = (event) => {
    this.setState({
      NewClientName: event.target.value,
    });
  };

  handleUnitHide = () => {
    this.setState({
      isNewClientPopupOpen: false,
    });
  };

  SaveDemoClient = () => {
    this.setState({
      showLoader: true,
    });

    const ClientName = this.state.NewClientName;
    let data = this.state.OrgStructureData;

    if (ClientName != null && ClientName.length > 0) {
      if (
        data.filter((ind) => ind.ClientName.toLowerCase() === ClientName.toLowerCase()).length > 0
      ) {
        this.setState({
          NewClientError: i18n.t("Client Name already exists with same name"),
          showLoader: false,
        });
      } else {
        var isValid = regexForConfMasterFields.test(ClientName);
        if (!isValid) {
          this.setState({
            NewClientError: i18n.t("Client Name contains special characters"),
            showLoader: false,
          });
        } else {
          const clientName = encodeURIComponent(ClientName);

          axiosInstance
            .post(`${LocalApiBaseUrl}DealOnboarding/AddDemoClient?clientName=${clientName}`)
            .then((response) => {
              window.alert(i18n.t(response.data.StatusMessage));
              if (response.data.IsSuccess) {
                data.push({
                  ClientName: ClientName,
                  CreatedBy: "",
                  CreatedTS: "",
                  DeliveryCentre: "",
                  ID: 0,
                  IsActive: false,
                  IsDemo: true,
                  UpdatedTS: "",
                  UpdatedUser: "",
                });
                data.sort((a, b) =>
                  a.ClientName.toLowerCase() > b.ClientName.toLowerCase() ? 1 : -1
                );

                let clientData = this.state.ClientData;
                clientData.push(ClientName);
                clientData.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));

                this.setState({
                  showLoader: false,
                  OrgStructureData: data,
                  ClientData: clientData,
                  isNewClientPopupOpen: false,
                });
              } else {
                this.setState({
                  showLoader: false,
                });
              }
            })
            .catch((error) => {
              this.setState({ showLoader: false }, () => {
                trycatchAlertPopup(error);
              });
            });
        }
      }
    } else {
      this.setState({
        NewClientError: i18n.t("Client Name is required"),
        showLoader: false,
      });
    }
  };

  GetUserDetails = () => {
    var EnterpriseID = sessionStorage.getItem("EnterpriseID");
    if (EnterpriseID != undefined) {
      axiosInstance
        .get(`${LocalApiBaseUrl}User/GetUserDetails `, {
          params: {},
        })
        .then((response) => {
          if (response.data[0] === "True") {
            this.setState({ IsAdmin: true });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };

  render() {
    let {
      IsAlias,
      searchTerm,
      ClientData,
      ClientRegionData,
      OfferingData,
      DeliveryRegionData,
      SubOfferingData,
      DeliveryCenterData,
      IndustryData,
      showLoader,
      leafNodes,
    } = this.state;
    let editApprovalEmailFilename =
      !_.isEmpty(this.props.EditData) &&
      this.props.EditData.ApprovalEmailFileName !== "" &&
      !this.state.selectedEmailFileName
        ? this.props.EditData.ApprovalEmailFileName
        : this.state.selectedEmailFileName;

    let editApprovalEmailFileSize =
      !_.isEmpty(this.props.EditData) && this.props.EditData.ApprovalEmailFileSize !== 0
        ? this.props.EditData.ApprovalEmailFileSize
        : this.state.selectedEmailFileSizeInKB * 1000;

    //if any of the properties is null or undefined .tolowercase fails below so explicitly setting to empty string
    if (!this.state.ClientBoardingPopupData.CoreBeatLocation)
      this.state.ClientBoardingPopupData.CoreBeatLocation = "";
    if (!this.state.EditData.CoreBeatClientName) this.state.EditData.CoreBeatClientName = "";

    if (!this.state.EditData.City) this.state.EditData.City = "";
    if (!this.state.EditData.ClientName) this.state.EditData.ClientName = "";

    const lowercasedFilter = searchTerm.toLowerCase();

    const filteredClientData = lowercasedFilter
      ? ClientData.filter((item) => {
          return Object.keys(item).some((key) => item.toLowerCase().includes(lowercasedFilter));
        })
      : ClientData;

    ClientRegionData = [...ClientRegionData];
    const filteredClientRegionData = lowercasedFilter
      ? ClientRegionData.filter((item) => {
          return Object.keys(item).some((key) =>
            item.ClientRegionName.toLowerCase().includes(lowercasedFilter)
          );
        })
      : ClientRegionData;

    const filteredIndustryData = lowercasedFilter
      ? IndustryData.filter((industry) => {
          return Object.keys(industry).some((key) =>
            industry.Industry.toLowerCase().includes(lowercasedFilter)
          );
        })
      : IndustryData;

    const filteredOfferingData = lowercasedFilter
      ? OfferingData.filter((item) => {
          return Object.keys(item).some((key) =>
            item.OfferingName.toLowerCase().includes(lowercasedFilter)
          );
        })
      : OfferingData;

    const filteredSubOfferingData = lowercasedFilter
      ? leafNodes.filter((item) => {
          return Object.keys(item).some((key) =>
            item.Process.toLowerCase().includes(lowercasedFilter)
          );
        })
      : SubOfferingData;

    DeliveryRegionData = [...DeliveryRegionData];
    const filteredDeliveryRegionData = lowercasedFilter
      ? DeliveryRegionData.filter((item) => {
          return Object.keys(item).some((key) =>
            item.DeliveryRegionName.toLowerCase().includes(lowercasedFilter)
          );
        })
      : DeliveryRegionData;

    const globalObjDeliveryRegion = {
      ClientName: "AB INBEV",
      CreatedBy: "admin",
      CreatedTS: "2020-10-25T23:36:08.8889918-06:00",
      DeliveryCentre: "Global",
      ID: -1,
      IsActive: true,
      UpdatedTS: "2020-10-25T23:36:08.8889918-06:00",
      UpdatedUser: "admin",
    };

    if (DeliveryCenterData && DeliveryCenterData.length > 0) {
      DeliveryCenterData = [...DeliveryCenterData, globalObjDeliveryRegion];

      let deliveryCdata = [];
      DeliveryCenterData.forEach((item) => {
        if (deliveryCdata.findIndex((f) => f.DeliveryCentre == item.DeliveryCentre) == -1)
          deliveryCdata.push({ DeliveryCentre: item.DeliveryCentre });
      });
      DeliveryCenterData = [...new Set(deliveryCdata)];
    }

    const filteredDeliveryCenterData = lowercasedFilter
      ? DeliveryCenterData &&
        DeliveryCenterData.filter((item) => {
          return Object.keys(item).some(
            (key) =>
              item.DeliveryCentre && item.DeliveryCentre.toLowerCase().includes(lowercasedFilter)
          );
        })
      : DeliveryCenterData;

    let clientSelect = filteredClientData.map((client) => (
      <li id={client} onClick={(e) => this.onClientChange(e)}>
        {client}
      </li>
    ));
    clientSelect.push(
      <>
        {this.state.showAddNewClient && this.state.isClientLoaded ? (
          <li onClick={this.showAddnewClientPopup}>{i18n.t("<Add New>")}</li>
        ) : (
          <></>
        )}
      </>
    );

    const clientRegion = filteredClientRegionData.map((clientregion) => (
      <li id={clientregion.ClientRegionName} onClick={(e) => this.onClientRegionChange(e)}>
        {clientregion.ClientRegionName}
      </li>
    ));

    const marketunit = filteredClientRegionData.map((m) => (
      <li id={m.ClientRegionName} onClick={(e) => this.onMarketUnitChange(m.ClientRegionName)}>
        {m.ClientRegionName}
      </li>
    ));
    const offeringData = filteredOfferingData.map((offering) => {
      if (offering.ChildOfferings.length > 0) {
        return (
          <li id={offering.OfferingName} onClick={(e) => this.onOfferingChange(e)}>
            {offering.OfferingName}
          </li>
        );
      }
    });

    const subOfferings = filteredSubOfferingData.map((subOff) => (
      <li id={subOff.Process} onClick={(e) => this.onSubOfferingChange(e)}>
        {subOff.Process}
      </li>
    ));

    const deliveryRegion = filteredDeliveryRegionData.map((deliveryregion) => (
      <li id={deliveryregion.ID} onClick={(e) => this.onDeliveryRegionChange(e)}>
        {deliveryregion.DeliveryRegionName}
      </li>
    ));

    const deliveryCenters =
      filteredDeliveryCenterData &&
      filteredDeliveryCenterData.map((deliverycenter) =>
        deliverycenter.DeliveryCentre != null && deliverycenter.DeliveryCentre.length > 0 ? (
          <li id={deliverycenter.DeliveryCentre} onClick={(e) => this.onDeliveryCenterChange(e)}>
            {deliverycenter.DeliveryCentre}
          </li>
        ) : null
      );

    const industrydropdown =
      filteredIndustryData &&
      filteredIndustryData
        .filter((ind) => ind.IsActive === true)
        .map((ind) => (
          <li id={ind.Industry} onClick={(e) => this.onIndustryChange(e)}>
            {ind.Industry}
          </li>
        ));

    const Industry = this.state.IndustryData.map((industry) => {
      return { label: industry.Industry, value: industry.Industry };
    });

    return (
      <>
        {/* IF API IS LOADING THEN SHOW THE LOADER */}
        {showLoader ? <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active /> : <></>}

        <Modal
          show={this.state.show}
          onHide={() => {
            this.addProjectClose(true);
          }}
          className="modal fade clientprojectModal"
          size="medium"
          backdrop="static"
        >
          <Modal.Body className="px-3 pt-4">
            <div className="toggle-form tabsSmall">
              <Tabs
                defaultActiveKey={this.state.selectedTabIndex}
                className={this.state.selectedTabIndex == 1 ? "" : "previousActive"}
                activeKey={this.state.selectedTabIndex}
              >
                <Tab eventKey={1} title={i18n.t("Project_Details")}>
                  <div className="clientproject-tabcontent">
                    <div className="upload-section">
                      <div className="choose-file">
                        <div
                          className={
                            "choose-image " +
                            (!this.state.ClientBoardingPopupData.OrgEntityImage &&
                            !this.state.ClientBoardingPopupData.selectedFile
                              ? "hideLogoHover"
                              : "")
                          }
                        >
                          {!this.state.oneditclientlogo ? (
                            <img
                              onMouseOver={() => {
                                if (this.state.ClientBoardingPopupData.OrgEntityImage)
                                  this.setState({ oneditclientlogo: true });
                              }}
                              src={
                                !this.state.uploadedimage
                                  ? //   <img
                                    //     src={
                                    this.state.ClientBoardingPopupData.OrgEntityImage
                                    ? `${LocalApiBaseUrlImage}${this.state.ClientBoardingPopupData.OrgEntityImage}`
                                    : this.state.decode !== null
                                    ? this.state.decode
                                    : UploadClientLogoUpdated
                                  : this.state.uploadedimage
                              }
                              alt="client title"
                            />
                          ) : (
                            <div
                              class="upload-logowrapper"
                              onMouseOut={() => this.setState({ oneditclientlogo: false })}
                            >
                              <button class="btn">
                                <img src={UploadClientLogo} />
                                <div>{i18n.t("Change_Client_Logo")}</div>
                                <div className="upload-logowrapper-max-size-text">
                                  Max file size: 20KB
                                </div>
                                <div className="upload-logowrapper-max-size-text">
                                  .jpeg & .png files <br />
                                  only
                                </div>
                              </button>

                              <input
                                type="file"
                                id="logofile"
                                name="myfile"
                                accept=".jpeg, .jpg, .png"
                                onChange={(event) => {
                                  this.LogoDisplay(event, true);
                                }}
                              />
                            </div>
                          )}
                          {!this.state.ClientBoardingPopupData.OrgEntityImage &&
                          this.state.ClientBoardingPopupData.selectedFile ? (
                            <div class="upload-logowrapper">
                              <button class="btn">
                                <img src={UploadClientLogo} />
                                <div>{i18n.t("Change_Client_Logo")}</div>
                                <div className="upload-logowrapper-max-size-text">
                                  Max file size: 20KB
                                </div>
                                <div className="upload-logowrapper-max-size-text">
                                  .jpeg & .png files <br />
                                  only
                                </div>
                              </button>

                              <input
                                type="file"
                                id="logofile"
                                name="myfile"
                                accept=".jpeg, .jpg, .png"
                                onChange={(event) => this.LogoDisplay(event)}
                              />
                            </div>
                          ) : (
                            ""
                          )}

                          {!this.state.ClientBoardingPopupData.OrgEntityImage &&
                          !this.state.ClientBoardingPopupData.selectedFile ? (
                            <div class="upload-logowrapper">
                              <button class="btn">
                                <img src={UploadClientLogoUpdated} />
                                {i18n.t("Upload_Client_Logo")}
                              </button>

                              <input
                                type="file"
                                id="logofile"
                                name="myfile"
                                accept=".jpeg, .jpg, .png"
                                onChange={(event) => this.LogoDisplay(event)}
                              />
                            </div>
                          ) : (
                            ""
                          )}

                          <span className="errorMsg">{this.state.logoerror.logo}</span>
                        </div>
                      </div>
                      {/* -----------------------------------------------------------------------          */}

                      <div className="choose-file justify-content-center">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {!this.state.ClientBoardingPopupData.ColorCode ||
                          !this.state.EditData.ColorCode ? (
                            <div>
                              <input
                                type="color"
                                id="hiddencolor"
                                style={{ visibility: "hidden" }}
                                title="select color"
                                className="ColorpickerforClientProfile"
                                value={
                                  (_.isEmpty(this.props.EditData)
                                    ? this.state.ClientBoardingPopupData.ColorCode
                                    : this.state.EditData.ColorCode) || "#8000CC"
                                }
                                onChange={(e) => this.oncolourchange(e)}
                              />
                              <label htmlFor="hiddencolor">
                                {" "}
                                <img src={colorpicker} className="imagecolorpicker" />
                              </label>
                            </div>
                          ) : (
                            <input
                              type="color"
                              title="select color"
                              className="ColorpickerforClientProfile noimage"
                              value={
                                (_.isEmpty(this.props.EditData)
                                  ? this.state.ClientBoardingPopupData.ColorCode
                                  : this.state.EditData.ColorCode) || "#8000CC"
                              }
                              onChange={(e) => this.oncolourchange(e)}
                            />
                          )}
                        </div>
                        <div className="Choosecoloroptions">
                          <p>Custom Banner Color</p>
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-top">
                                The custom banner color is applicable only for Client Cockpit
                                application
                              </Tooltip>
                            }
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                    {!showLoader && (
                      <div className="clientfields-section">
                        <form>
                          {!_.isEmpty(this.props.EditData) ? (
                            <div className="row">
                              <div className="col-sm-6">
                                <label className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    disabled
                                    defaultChecked={this.state.EditData.IsDemo}
                                  />
                                  <span className="checkmark"></span>
                                  <span className="form-check-label">{i18n.t("Demo")}</span>
                                </label>
                              </div>
                              <div className="col-sm-6">
                                <label className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    disabled
                                    checked={this.state.ClientBoardingPopupData.IsAlias}
                                    onChange={(e) => this.onIsAliasChange(e)}
                                  />
                                  <span className="checkmark"></span>
                                  <span className="form-check-label">{i18n.t("Alias")}</span>
                                </label>
                              </div>
                            </div>
                          ) : this.state.IsAdmin ? (
                            <div className="row">
                              <div className="col-sm-6">
                                <label className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={(e) => this.onIsDemoCheck(e)}
                                    defaultChecked={this.state.ClientBoardingPopupData.IsDemo}
                                    checked={this.state.ClientBoardingPopupData.IsDemo}
                                  />
                                  <span className="checkmark"></span>
                                  <span className="form-check-label">{i18n.t("Demo")}</span>
                                </label>
                              </div>
                              <div className="col-sm-6">
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-section">
                                <div
                                  className={
                                    "form-group border-group " +
                                    (!_.isEmpty(this.props.EditData) ? "disabled" : "")
                                  }
                                >
                                  <label for="client" class="form-label">
                                    {i18n.t("Client_Name")}
                                    <span class="required">*</span>
                                  </label>
                                  <div
                                    className="custom-dropdown-search"
                                    onClick={this.onOpenSearchableDropdown}
                                  >
                                    <div className="custom-dropdownname" id="clientDropdown">
                                      {_.isEmpty(this.props.EditData)
                                        ? this.state.ClientBoardingPopupData.CoreBeatClientName
                                          ? this.state.ClientBoardingPopupData.CoreBeatClientName
                                          : "Select Client Name"
                                        : this.state.EditData.CoreBeatClientName
                                        ? this.state.EditData.CoreBeatClientName
                                        : "Select Client Name"}
                                    </div>
                                    {this.state.isClientDropdownSearchable ? (
                                      <div className="search-select-list">
                                        <div className="searchFilter">
                                          <input
                                            type="search"
                                            value={this.state.searchTerm}
                                            onChange={this.filterSearchableDropdown}
                                            placeholder="Search Client..."
                                          />
                                        </div>
                                        {clientSelect.length > 0 ? <ul>{clientSelect}</ul> : ""}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                {this.state.ShowClientDropdownErrorMsg ? (
                                  <span className="errorMsg">
                                    {this.state.errors.CoreBeatClientName}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              {!_.isEmpty(this.props.EditData) && <div className="form-section">
                                <div
                                  className="form-group border-group  disabled"
                                >
                                  <label for="client" class="form-label">
                                    {i18n.t("Alias_Client_Name")}{" "}
                                    <OverlayTrigger
                                      key="top"
                                      placement="top"
                                      overlay={
                                        <Tooltip id="tooltip-top">
                                          Use Alias ONLY if client name cannot be used due to
                                          contract restrictions
                                        </Tooltip>
                                      }
                                    >
                                      <FontAwesomeIcon icon={faInfoCircle} />
                                    </OverlayTrigger>
                                  </label>
                                  <input
                                    className="form-control"
                                    maxLength="50"
                                    disabled
                                    value={
                                      _.isEmpty(this.props.EditData)
                                        ? this.state.ClientBoardingPopupData.ClientName
                                        : this.state.EditData.ClientName
                                    }
                                    onChange={(e) => this.onClientAliasChange(e)}
                                  />
                                </div>
                              </div>}
                              {this.state.ShowClientDropdownErrorMsg ? (
                                <span className="errorMsg">{this.state.errors.ClientName}</span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-section">
                                <div
                                  className={`${
                                    this.state.isAuthorise.ClientRegion
                                      ? "form-group border-group disabled"
                                      : "form-group border-group"
                                  }`}
                                >
                                  <label for="market" class="form-label">
                                    {i18n.t("Market Region")}
                                    <span class="required">*</span>
                                  </label>
                                  <div
                                    className="custom-dropdown-search"
                                    onClick={this.onOpenSearchableDropdown}
                                  >
                                    <div
                                      className="custom-dropdownname"
                                      id="market"
                                      disabled={!this.state.isAuthorise.ClientRegion}
                                    >
                                      {_.isEmpty(this.props.EditData)
                                        ? this.state.ClientBoardingPopupData.ClientRegion
                                          ? this.state.ClientBoardingPopupData.ClientRegion
                                          : "Select Market Region"
                                        : this.state.EditData.ClientRegionName
                                        ? this.state.EditData.ClientRegionName
                                        : "Select Market Region"}
                                    </div>
                                    {this.state.isClientRegionDropdownSearchable ? (
                                      <div className="search-select-list">
                                        <div className="searchFilter">
                                          <input
                                            type="search"
                                            value={this.state.searchTerm}
                                            onChange={this.filterSearchableDropdown}
                                            placeholder="Search Market Region..."
                                          />
                                        </div>
                                        {clientRegion.length > 0 ? <ul>{clientRegion}</ul> : ""}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                {this.state.ShowClienRegionErrorMsg ? (
                                  <span className="errorMsg">
                                    {this.state.errors.ClientRegion
                                      ? this.state.errors.ClientRegion
                                      : this.state.errors.ClientRegionName}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              {!_.isEmpty(this.props.EditData) && <div className="form-section">
                                <div
                                  className="form-group border-group disabled"
                                >
                                  <label for="market" class="form-label">
                                    {i18n.t("Other Market Region")}{" "}
                                    {this.state.ClientBoardingPopupData.ClientRegionID === -1 ||
                                    this.state.EditData.ClientRegionID === -1 ? (
                                      <span class="required"> * </span>
                                    ) : (
                                      ""
                                    )}
                                  </label>
                                  <input
                                    className="form-control"
                                    name="OtherClientRegion"
                                    id="OtherClientRegion"
                                    maxLength="100"
                                    value={
                                      _.isEmpty(this.props.EditData)
                                        ? this.state.ClientBoardingPopupData.OtherClientRegion
                                        : this.state.EditData.OtherClientRegion
                                    }
                                    onChange={(e) => this.onOtherClientRegionChange(e)}
                                    disabled
                                  />
                                </div>
                                {this.state.ShowOtherClientRegionErrorMsg ? (
                                  <span className="errorMsg">
                                    {this.state.errors.OtherClientRegion}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-section">
                                <div
                                  className={`${
                                    this.state.isAuthorise.MarketUnit
                                      ? "form-group border-group disabled"
                                      : "form-group border-group"
                                  }`}
                                >
                                  <label for="market-unit" class="form-label">
                                    {i18n.t("Market Unit")}
                                    <span class="required">*</span>
                                  </label>
                                  <div
                                    className="custom-dropdown-search"
                                    onClick={this.onOpenSearchableDropdown}
                                  >
                                    <div
                                      className="custom-dropdownname"
                                      id="marketUnit"
                                      disabled={!this.state.isAuthorise.MarketUnit}
                                    >
                                      {_.isEmpty(this.props.EditData)
                                        ? this.state.ClientBoardingPopupData.MarketUnit
                                          ? this.state.ClientBoardingPopupData.MarketUnit
                                          : "Select Market Unit"
                                        : this.state.EditData.MarketUnit
                                        ? this.state.EditData.MarketUnit
                                        : "Select Market Unit"}
                                    </div>
                                    {this.state.isMarketUnitSearchable ? (
                                      <div className="search-select-list">
                                        <div className="searchFilter">
                                          <input
                                            type="search"
                                            value={this.state.searchTerm}
                                            onChange={this.filterSearchableDropdown}
                                            placeholder="Search Market Unit..."
                                          />
                                        </div>
                                        {marketunit.length > 0 ? <ul>{marketunit}</ul> : ""}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                {this.state.ShowMarketUnitErrorMsg ? (
                                  <span className="errorMsg">
                                    {this.state.errors.MarketUnit
                                      ? this.state.errors.MarketUnit
                                      : this.state.errors.MarketUnitName}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              {!_.isEmpty(this.props.EditData) && <div className="form-section">
                                <div
                                  className="form-group border-group disabled"
                                >
                                  <label for="market-unit" class="form-label">
                                    {i18n.t("Other Market Unit")}{" "}
                                    {this.state.ClientBoardingPopupData.MarketUnitID === -1 ||
                                    this.state.EditData.MarketUnitID === -1 ? (
                                      <span class="required"> * </span>
                                    ) : (
                                      ""
                                    )}
                                  </label>
                                  <input
                                    className="form-control"
                                    name="OtherMarketUnit"
                                    id="OtherMarketUnit"
                                    maxLength="100"
                                    value={
                                      _.isEmpty(this.props.EditData)
                                        ? this.state.ClientBoardingPopupData.OtherMarketUnit
                                        : this.state.EditData.OtherMarketUnit
                                    }
                                    onChange={(e) => this.onOtherMarketUnitChange(e)}
                                    disabled
                                  />
                                </div>
                                {this.state.ShowOtherMarketUnitErrorMsg ? (
                                  <span className="errorMsg">
                                    {this.state.errors.OtherMarketUnit}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12">
                              <h4>{i18n.t("Client_Project")} </h4>
                              <p>{i18n.t("Project_Message")}</p>
                              <div className="form-section">
                                <div
                                  className={`${
                                    this.state.isAuthorise.Project
                                      ? "form-group border-group disabled"
                                      : "form-group border-group"
                                  }`}
                                >
                                  <label for="name" class="form-label">
                                    {i18n.t("Project_Name")}
                                  </label>
                                  <input
                                    maxLength="50"
                                    type="text"
                                    class="form-control"
                                    name="Project"
                                    value={
                                      _.isEmpty(this.props.EditData)
                                        ? this.state.ClientBoardingPopupData.Project
                                        : this.state.EditData.Project
                                    }
                                    onChange={(e) => this.onProjectNameChange(e)}
                                  />
                                </div>
                              </div>
                              {this.state.ShowProjectErrorMsg ? (
                                <span className="errorMsg">{this.state.errors.Project}</span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-section">
                                <div
                                  className={`${
                                    this.state.isAuthorise.Offering
                                      ? "form-group border-group disabled"
                                      : "form-group border-group"
                                  }`}
                                >
                                  <label for="offering" class="form-label">
                                    {i18n.t("Offering")}
                                    <span class="required">*</span>
                                  </label>
                                  <div
                                    className="custom-dropdown-search"
                                    onClick={this.onOpenSearchableDropdown}
                                  >
                                    <div className="custom-dropdownname" id="offering">
                                      {_.isEmpty(this.props.EditData)
                                        ? this.state.ClientBoardingPopupData.Offering
                                          ? this.state.ClientBoardingPopupData.Offering
                                          : "Select Offering"
                                        : this.state.EditData.Offering
                                        ? this.state.EditData.Offering
                                        : "Select Offering"}
                                    </div>
                                    {this.state.isOfferingDropdownSearchable ? (
                                      <div className="search-select-list">
                                        <div className="searchFilter">
                                          <input
                                            type="search"
                                            value={this.state.searchTerm}
                                            onChange={this.filterSearchableDropdown}
                                            placeholder="Search Offering..."
                                          />
                                        </div>
                                        {offeringData.length > 0 ? <ul>{offeringData}</ul> : ""}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                {this.state.ShowOfferingErrorMsg ? (
                                  <span className="errorMsg">{this.state.errors.Offering}</span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-section">
                                <div
                                  className={`${
                                    this.state.isAuthorise.Process &&
                                    !this.state.ClientBoardingPopupData.OfferingID
                                      ? "form-group border-group disabled"
                                      : "form-group border-group"
                                  }`}
                                >
                                  <label for="suboffering" class="form-label">
                                    {i18n.t("Sub_Offering")}
                                    <span class="required">*</span>
                                  </label>

                                  <DropdownButton
                                    className="selectsuboffering-dropdown nested-dropdown-wrapper"
                                    title={
                                      _.isEmpty(this.props.EditData)
                                        ? this.state.ClientBoardingPopupData.Process
                                          ? this.state.ClientBoardingPopupData.Process
                                          : "Select Sub Offering / Tower"
                                        : this.state.EditData.Process
                                        ? this.state.EditData.Process
                                        : "Select Sub Offering / Tower"
                                    }
                                  >
                                    <div className="offering-droplistwrapper">
                                      <SubOffering
                                        subOfferings={this.state.SubOfferingData}
                                        onSubOfferingChange={(e) => this.onSubOfferingChange(e)}
                                        dropdownItem={false}
                                        selectedSubOfferingId={
                                          this.state.ClientBoardingPopupData.ProcessID
                                        }
                                      />
                                    </div>
                                  </DropdownButton>
                                </div>

                                {this.state.ShowSubOfferingErrorMsg ? (
                                  <span className="errorMsg">{this.state.errors.Process}</span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-section">
                                <div
                                  className={`${
                                    this.state.isAuthorise.DeliveryRegion
                                      ? "form-group border-group disabled"
                                      : "form-group border-group"
                                  }`}
                                >
                                  <label for="region" class="form-label">
                                    {i18n.t("Delivery_Region")}
                                    <span class="required">*</span>
                                  </label>
                                  <div
                                    className="custom-dropdown-search"
                                    onClick={this.onOpenSearchableDropdown}
                                  >
                                    <div
                                      className="custom-dropdownname"
                                      id="region"
                                      disabled={!this.state.isAuthorise.DeliveryRegion}
                                    >
                                      {_.isEmpty(this.props.EditData)
                                        ? this.state.ClientBoardingPopupData.DeliveryRegion
                                          ? this.state.ClientBoardingPopupData.DeliveryRegion
                                          : "Select Delivery Region"
                                        : this.state.EditData.DeliveryRegionID
                                        ? this.state.EditData.DeliveryRegionID !== -1
                                          ? this.state.EditData.DeliveryRegion
                                          : "Others"
                                        : "Select Delivery Region"}
                                    </div>
                                    {this.state.isDeliveryRegionDropdownSearchable ? (
                                      <div className="search-select-list">
                                        <div className="searchFilter">
                                          <input
                                            type="search"
                                            value={this.state.searchTerm}
                                            onChange={this.filterSearchableDropdown}
                                            placeholder="Search Delivery Region..."
                                          />
                                        </div>
                                        {deliveryRegion.length > 0 ? <ul>{deliveryRegion}</ul> : ""}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>{" "}
                                </div>

                                {this.state.ShowDeliveryRegionErrorMsg ? (
                                  <span className="errorMsg">
                                    {this.state.errors.DeliveryRegion}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              {!_.isEmpty(this.props.EditData) && <div className="form-section">
                                <div
                                  className="form-group border-group disabled"
                                >
                                  <label for="client" class="form-label">
                                    {i18n.t("Other_Delivery_Region")}{" "}
                                    {this.state.ClientBoardingPopupData.DeliveryRegionID === "-1" ||
                                    this.state.EditData.DeliveryRegionID == "-1" ? (
                                      <span class="required"> * </span>
                                    ) : (
                                      ""
                                    )}
                                  </label>
                                  <input
                                    className="form-control"
                                    name="OtherDeliveryRegion"
                                    maxLength="100"
                                    value={
                                      _.isEmpty(this.props.EditData)
                                        ? this.state.ClientBoardingPopupData.OtherDeliveryRegion
                                        : this.state.EditData.OtherDeliveryRegion
                                    }
                                    onChange={(e) => this.onOtherDeliveryRegionChange(e)}
                                    disabled
                                  />
                                </div>
                                {this.state.ShowOtherDeliveryRegionErrorMsg ? (
                                  <span className="errorMsg">
                                    {this.state.errors.OtherDeliveryRegion}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>}
                            </div>
                            <div className="col-sm-6">
                              <div className="form-section">
                                <div
                                  className={`${
                                    this.state.isAuthorise.City
                                      ? "form-group border-group disabled"
                                      : "form-group border-group"
                                  }`}
                                >
                                  <label for="center" class="form-label">
                                    {i18n.t("Delivery_Center")}
                                    <span class="required">*</span>
                                  </label>

                                  <div
                                    className="custom-dropdown-search"
                                    onClick={this.onOpenSearchableDropdown}
                                  >
                                    <div className="custom-dropdownname" id="center">
                                      {_.isEmpty(this.props.EditData)
                                        ? this.state.ClientBoardingPopupData.CoreBeatLocation
                                          ? this.state.ClientBoardingPopupData.CoreBeatLocation
                                          : "Select Delivery Center"
                                        : this.state.EditData.CoreBeatLocation
                                        ? this.state.EditData.CoreBeatLocation
                                        : "Select Delivery Center"}
                                    </div>
                                    {this.state.isLocationDropdownSearchable ? (
                                      <div className="search-select-list">
                                        <div className="searchFilter">
                                          <input
                                            type="search"
                                            value={this.state.searchTerm}
                                            onChange={this.filterSearchableDropdown}
                                            placeholder="Search Delivery Center..."
                                          />
                                        </div>
                                        {deliveryCenters && deliveryCenters.length > 0 ? (
                                          <ul>{deliveryCenters}</ul>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                {this.state.ShowLocationErrorMsg ? (
                                  <span className="errorMsg">{this.state.errors.LocationID}</span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              {!_.isEmpty(this.props.EditData) && <div className="form-section">
                                <div
                                  className="form-group border-group disabled"
                                >
                                  <label for="client" class="form-label">
                                    {i18n.t("Delivery_Center_Alias")}{" "}
                                    <OverlayTrigger
                                      key="top"
                                      placement="top"
                                      overlay={
                                        <Tooltip id="tooltip-top">
                                          User Alias ONLY if Delivery Center is not standard
                                        </Tooltip>
                                      }
                                    >
                                      <FontAwesomeIcon icon={faInfoCircle} />
                                    </OverlayTrigger>
                                  </label>
                                  <input
                                    class="form-control"
                                    maxLength="100"
                                    name="DeliveryCentreAlias"
                                    value={
                                      _.isEmpty(this.props.EditData)
                                        ? this.state.ClientBoardingPopupData.City
                                        : this.state.EditData.City
                                    }
                                    onChange={(e) => this.onDeliveryCentreAliasChange(e)}
                                    disabled
                                  />
                                </div>
                                {this.state.ShowCityErrorMsg ? (
                                  <span className="errorMsg">{this.state.errors.City}</span>
                                ) : (
                                  ""
                                )}
                              </div>}
                            </div>
                            <div className="col-sm-6">
                              <div className="form-section">
                                <div
                                  className={`${
                                    this.state.isAuthorise.Industry
                                      ? "form-group border-group disabled"
                                      : "form-group border-group"
                                  }`}
                                >
                                  <label for="industry" class="form-label">
                                    {i18n.t("Industry")}
                                    <span class="required">*</span>
                                  </label>

                                  <div
                                    className="custom-dropdown-search"
                                    onClick={this.onOpenSearchableDropdown}
                                  >
                                    <div className="custom-dropdownname" id="industry">
                                      {_.isEmpty(this.props.EditData)
                                        ? this.state.ClientBoardingPopupData.Industry
                                          ? this.state.ClientBoardingPopupData.Industry
                                          : "Select Industry"
                                        : this.state.EditData.Industry
                                        ? this.state.EditData.Industry
                                        : "Select Industry"}
                                    </div>
                                    {this.state.isIndustryDropdownSearchable ? (
                                      <div className="search-select-list">
                                        <div className="searchFilter">
                                          <input
                                            type="search"
                                            value={this.state.searchTerm}
                                            onChange={this.filterSearchableDropdown}
                                            placeholder="Search Industry..."
                                          />
                                        </div>
                                        {industrydropdown.length > 0 ? (
                                          <ul>{industrydropdown}</ul>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                {this.state.ShowIndustryErrorMsg ? (
                                  <span className="errorMsg">{this.state.errors.Industry}</span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              {!_.isEmpty(this.props.EditData) && <div className="form-section">
                                <div
                                  className="form-group border-group disabled"
                                >
                                  <label for="client" class="form-label">
                                    {i18n.t("Other Industry")}{" "}
                                    {this.state.ClientBoardingPopupData.Industry === "Others" ||
                                    String(this.state.EditData.OtherIndustry) === "Others" ? (
                                      <span class="required"> * </span>
                                    ) : (
                                      ""
                                    )}
                                  </label>
                                  <input
                                    className="form-control"
                                    maxLength="100"
                                    name="OtherIndustry"
                                    value={
                                      _.isEmpty(this.props.EditData)
                                        ? this.state.ClientBoardingPopupData.OtherIndustry
                                        : this.state.EditData.OtherIndustry
                                    }
                                    onChange={(e) => this.onOtherIndustryChange(e)}
                                    disabled
                                  />
                                </div>
                                {this.state.ShowOtherIndustryErrorMsg ? (
                                  <span className="errorMsg">
                                    {this.state.errors.OtherIndustry}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>}
                            </div>
                            <div className="col-sm-6">
                              <div className="form-section">
                                <div
                                  className={`${
                                    this.state.isAuthorise.MaturityPhase
                                      ? "form-group border-group disabled"
                                      : "form-group border-group"
                                  }`}
                                >
                                  <label for="maturity" class="form-label">
                                    {i18n.t("Maturity_Phase")}
                                    <span class="required">*</span>
                                  </label>
                                  <select
                                    id="maturity"
                                    class="form-control"
                                    name="MaturityPhase"
                                    value={
                                      _.isEmpty(this.props.EditData)
                                        ? this.state.ClientBoardingPopupData.MaturityPhaseID
                                        : this.state.EditData.MaturityPhaseID
                                    }
                                    onChange={(e) => this.onMaturityChange(e)}
                                  >
                                    <option value="">Select Maturity Phase</option>
                                    {this.state.MaturityPhaseData.length > 0 &&
                                      this.state.MaturityPhaseData.map((maturityphase) => (
                                        <option value={maturityphase.ID}>
                                          {maturityphase.PhaseName}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                {this.state.ShowMaturityPhaseErrorMsg ? (
                                  <span className="errorMsg">
                                    {this.state.errors.MaturityPhaseID}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </Tab>
                {/* access control */}
                <Tab eventKey={2} title={i18n.t("Access_Control")}>
                  <div className="clientproject-tabcontent">
                    <div className="access-details">
                      {this.state.isScreenOneMandatoryFieldEmpty ? (
                        <div className="row form-group">
                          <div className="col-sm-12">
                            <span className="errorMsg">{i18n.t("Mandatory_Fields")}</span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="label-group">
                            <h4>{i18n.t("Assign_project_managers")}</h4>
                            <p>Example: user.id@accenture.com</p>
                          </div>
                          <div className="form-section">
                            <div
                              className={`${
                                this.state.isAuthorise.AccentureMD
                                  ? "form-group border-group disabled"
                                  : "form-group border-group"
                              }`}
                            >
                              <label for="prime-lead" className="form-label">
                                {i18n.t("Prime_Lead")} ({i18n.t("Publish_Access")})
                                <span className="required">*</span>
                              </label>

                              <input
                                id="Prime-Lead"
                                type="text"
                                className="form-control"
                                value={this.state.ClientBoardingPopupData.AccentureMD}
                                placeholder="userid1@accenture.com, userid2@accenture.com"
                                onChange={(e) => {
                                  this.bindTextFieldChange(e);
                                }}
                                onKeyDown={this.handleAccentureMDKeyDown}
                                onBlur={this.blurAccentureMD}
                              />
                            </div>

                            {this.state.Fprimeerrorshow ? (
                              <>
                                <span className="errorMsg">
                                  {this.state.primeerrors.AccentureMDUnique}
                                </span>
                                <span className="errorMsg">
                                  {this.state.primeerrors.AccentureMDEmail}
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                            {this.state.Noprimeerrorshow ? (
                              <span className="errorMsg">{this.state.noerrors.NoAccentureMD}</span>
                            ) : (
                              ""
                            )}
                            <div
                              className={`${
                                this.state.isAuthorise.AccentureSDL
                                  ? "form-group border-group disabled"
                                  : "form-group border-group"
                              }`}
                            >
                              <label for="delivery-lead" className="form-label">
                                {i18n.t("Service_Delivery_Lead")}(s) ({i18n.t("Edit_Access")})
                                <span className="required">*</span>
                              </label>
                              <input
                                id="Delivery-Lead"
                                type="text"
                                className="form-control"
                                placeholder="userid1@accenture.com, userid2@accenture.com"
                                value={this.state.ClientBoardingPopupData.AccentureSDL}
                                onChange={(e) => {
                                  this.bindTextFieldChange(e);
                                }}
                                onKeyDown={this.handleAccentureSDLKeyDown}
                                onBlur={this.blurAccentureSDL}
                              />
                            </div>
                            {this.state.Fdeliveryerrorshow ? (
                              <>
                                <span className="errorMsg">
                                  {this.state.Deliveryerrors.AccentureSDLUnique}
                                </span>
                                <span className="errorMsg">
                                  {this.state.Deliveryerrors.AccentureSDLEmail}
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                            {this.state.Nodeliveryerrorshow ? (
                              <span className="errorMsg">{this.state.noerrors.NoAccentureSDL}</span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className={`${"form-group border-group"}`}>
                            <label for="delivery-lead" className="form-label">
                              {i18n.t("Client Experience Lead")} ({i18n.t("Viewer_Access")})
                              <span className="required">*</span>
                            </label>
                            <input
                              id="Cxl"
                              type="text"
                              className="form-control"
                              placeholder="userid@accenture.com"
                              value={this.state.ClientBoardingPopupData.Cxl}
                              onChange={(e) => {
                                this.bindTextFieldChange(e);
                              }}
                              onBlur={(e) => this.blurCxlOal(e)}
                            />
                          </div>
                          {this.state.errors["Cxl"] && (
                            <span className="errorMsg">{this.state.errors.Cxl}</span>
                          )}
                          <div className={`${"form-group border-group"}`}>
                            <label for="OAL" className="form-label">
                              {i18n.t("OPS Account Lead")} ({i18n.t("Viewer_Access")})
                              <span className="required">*</span>
                            </label>
                            <input
                              id="Oal"
                              type="text"
                              className="form-control"
                              placeholder="userid@accenture.com"
                              value={this.state.ClientBoardingPopupData.Oal}
                              onChange={(e) => {
                                this.bindTextFieldChange(e);
                              }}
                              onBlur={(e) => this.blurCxlOal(e)}
                            />
                          </div>
                          {this.state.errors["Oal"] && (
                            <span className="errorMsg">{this.state.errors.Oal}</span>
                          )}
                        </div>
                        <div className="col-sm-12">
                          <div className="label-group">
                            <h4>{i18n.t("Assign_access_to_project_users")}</h4>
                            <p>Example: user.id@accenture.com</p>
                          </div>
                          <div className="form-section">
                            <div
                              className={`${
                                this.state.isEditViewAuthorise.EditAccessUsers
                                  ? "form-group border-group disabled"
                                  : "form-group border-group"
                              }`}
                            >
                              <label for="edit-access" className="form-label">
                                {i18n.t("Users_with_edit_access")}
                                <span className="required">*</span>
                              </label>
                              <input
                                id="Edit-Access"
                                placeholder="userid1@accenture.com, userid2@accenture.com"
                                type="text"
                                className="form-control"
                                value={this.state.ClientBoardingPopupData.EditAccessUsers}
                                onChange={(e) => {
                                  this.bindTextFieldChange(e);
                                }}
                                onKeyDown={this.handleEditAccessUsersDown}
                                onBlur={this.blurEditAccessUsers}
                              />
                            </div>

                            {this.state.Feditacessusererrorshow ? (
                              <>
                                <span className="errorMsg">
                                  {this.state.EditAccessUsererrors.EditAccessUsersUnique}
                                </span>
                                <span className="errorMsg">
                                  {this.state.EditAccessUsererrors.EditAccessUsersEmail}
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                            {this.state.Noeditacessusererrorshow ? (
                              <span className="errorMsg">
                                {this.state.noerrors.NoEditAccessUsers}
                              </span>
                            ) : (
                              ""
                            )}

                            <div className="form-group border-group">
                              <label for="view-access" className="form-label">
                                {i18n.t("Users_with_view_access")}
                              </label>
                              <input
                                id="View-Access"
                                placeholder="userid1@accenture.com, userid2@accenture.com"
                                type="text"
                                className="form-control"
                                value={this.state.ClientBoardingPopupData.ViewAccessUsers}
                                disabled={this.state.isEditViewAuthorise.ViewAccessUsers}
                                onChange={(e) => {
                                  this.bindTextFieldChange(e);
                                }}
                                onKeyDown={this.handleViewAccessUsersDown}
                                onBlur={this.blurViewAccessUsers}
                              />
                            </div>
                            {this.state.Fviewacessusererrorshow ? (
                              <>
                                <span className="errorMsg">
                                  {this.state.ViewAccessUsererrors.ViewAccessUsersUnique}
                                </span>
                                <span className="errorMsg">
                                  {this.state.ViewAccessUsererrors.ViewAccessUsersEmail}
                                </span>
                              </>
                            ) : null}
                          </div>
                        </div>
                        {/* Approval confirmation start */}
                        <div className="col-sm-12">
                          <h4>
                            {i18n.t("Approval_Confirmation")} <span className="required">*</span>
                          </h4>
                          <p>{i18n.t("Approval_Confirmation_msg")}</p>
                          {_.isEmpty(this.props.EditData) && !this.state.isEmailFileSelected && (
                            <div className="upload-btn-wrapper">
                              <div className="btn">
                                <div>
                                  <i class="fas fa-upload"></i>
                                </div>
                              </div>
                              <div className="fileupload-browse">
                                <input
                                  type="file"
                                  accept=".msg"
                                  id="approvalEmailFile"
                                  onChange={this.emailFileValidation.bind(this)}
                                />
                                <input
                                  className="approvalFileBtn"
                                  type="button"
                                  onClick={() =>
                                    document.getElementById("approvalEmailFile").click()
                                  }
                                  value="Browse"
                                />
                                <span className="approval-attach">to attach</span>
                                <div className="filedrop-msg">
                                  <p>
                                    (Individual file upload size limit{" "}
                                    {this.state.configKeys.emailFileMaxSizeInKB} KB)
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                          {!_.isEmpty(this.props.EditData) &&
                            this.props.EditData.Status === "Approved" && (
                              <div className="upload-btn-wrapper upload-file-wrapper downloadEmail">
                                <div
                                  className="file-details"
                                  onClick={() => this.downloadEmail(this.props.EditData.DealID)}
                                >
                                  <h5>
                                    {this.props.EditData &&
                                      this.props.EditData.ApprovalEmailFileName}
                                  </h5>
                                  <p>
                                    {this.props.EditData &&
                                      this.props.EditData.ApprovalEmailFileSize / 1000}{" "}
                                    KB
                                  </p>
                                </div>
                                <div></div>
                              </div>
                            )}
                          {/* Edit case rejected projects */}
                          {this.state.isEmailFileSelected === true &&
                            !_.isEmpty(this.props.EditData) &&
                            this.props.EditData.Status === "Rejected" && (
                              <div className="upload-btn-wrapper upload-file-wrapper downloadEmail">
                                <div
                                  className="file-details"
                                  onClick={() => this.downloadEmail(this.props.EditData.DealID)}
                                >
                                  <h5>{editApprovalEmailFilename}</h5>
                                  <p>{editApprovalEmailFileSize / 1000} KB</p>
                                </div>
                                <div>
                                  {/* selectedEmailfile */}
                                  <button
                                    onClick={this.removeEmailFile}
                                    type="button"
                                    class="close"
                                  ></button>
                                </div>
                              </div>
                            )}
                          {/* Edit case rejected - can reupload */}
                          {this.state.isEmailFileSelected === false &&
                            !_.isEmpty(this.props.EditData) &&
                            this.props.EditData.Status === "Rejected" && (
                              <div className="upload-btn-wrapper">
                                <div className="btn">
                                  <div>
                                    <i class="fas fa-upload"></i>
                                  </div>
                                </div>
                                <div className="fileupload-browse">
                                  <input
                                    type="file"
                                    accept=".msg"
                                    id="approvalEmailFile"
                                    onChange={this.emailFileValidation.bind(this)}
                                  />
                                  <input
                                    className="approvalFileBtn"
                                    type="button"
                                    onClick={() =>
                                      document.getElementById("approvalEmailFile").click()
                                    }
                                    value="Browse"
                                  />
                                  <span className="approval-attach">to attach</span>
                                  <div className="filedrop-msg">
                                    <p>
                                      (Individual file upload size limit{" "}
                                      {this.state.configKeys.emailFileMaxSizeInKB} KB)
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}

                          {/* Add case */}

                          {_.isEmpty(this.props.EditData) &&
                          this.state.selectedEmailFileName &&
                          this.state.selectedEmailFileSizeInKB &&
                          this.state.isEmailFileSelected ? (
                            <div className="upload-btn-wrapper upload-file-wrapper">
                              <div className="file-details">
                                <h5>{this.state.selectedEmailFileName}</h5>
                                <p>{this.state.selectedEmailFileSizeInKB} KB</p>
                              </div>
                              <div>
                                <button
                                  onClick={this.removeEmailFile}
                                  type="button"
                                  class="close"
                                ></button>
                              </div>
                            </div>
                          ) : null}

                          {/* Approval confirmation end */}
                          <span className="errorMsg">{this.state.emailerror.email}</span>
                          {this.state.NoApprovalEmailErrorShow ? (
                            <span className="errorMsg">
                              {this.state.noerrors.NoApprovalEmailErrorShow}
                            </span>
                          ) : (
                            ""
                          )}
                          <div className="form-section">
                            <div
                              className={`${
                                this.state.isAuthorise.RequestorRemarks
                                  ? "form-group border-group disabled"
                                  : "form-group border-group"
                              }`}
                            >
                              <label for="remarks" class="form-label">
                                {i18n.t("Requestor_Remarks")}
                                <span className="required">*</span>
                              </label>
                              <input
                                id="ReqRem"
                                type="text"
                                maxLength="200"
                                className="form-control"
                                onChange={(e) => {
                                  this.bindTextFieldChange(e);
                                }}
                                value={this.state.ClientBoardingPopupData.RequestorRemarks}
                              />
                              {this.state.Noerrorshow && (
                                <span className="errorMsg">
                                  {this.state.noerrors.NoRequestorRemarks}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="onboard-btns">
              <Button
                variant="default"
                className="btn-sm mr-auto"
                onClick={(e) => this.addProjectClose(true)}
              >
                {i18n.t("Cancel")}
              </Button>
              <Button
                variant="outline-secondary mr-2"
                disabled={this.state.selectedTabIndex === 1}
                onClick={this.prevTabChangeEvent}
              >
                {i18n.t("Previous")}
              </Button>
              <Button
                variant="primary"
                disabled={this.state.disableSubmitButton}
                onClick={(e) => this.nextTabChangeEvent()}
              >
                {this.state.selectedTabIndex === 1 ? "Next" : "Submit"}
              </Button>
            </div>
          </Modal.Footer>
          {this.state.isClientDropdownSearchable ? (
            <div
              className="custom-dropdown-search-overlay"
              onClick={this.closeCustomDropdown}
            ></div>
          ) : (
            ""
          )}
          {this.state.isClientRegionDropdownSearchable ? (
            <div
              className="custom-dropdown-search-overlay"
              onClick={this.closeCustomDropdown}
            ></div>
          ) : (
            ""
          )}
          {this.state.isMarketUnitSearchable ? (
            <div
              className="custom-dropdown-search-overlay"
              onClick={this.closeCustomDropdown}
            ></div>
          ) : (
            ""
          )}

          {this.state.isOfferingDropdownSearchable ? (
            <div
              className="custom-dropdown-search-overlay"
              onClick={this.closeCustomDropdown}
            ></div>
          ) : (
            ""
          )}
          {this.state.isSubOfferingDropdownSearchable ? (
            <div
              className="custom-dropdown-search-overlay"
              onClick={this.closeCustomDropdown}
            ></div>
          ) : (
            ""
          )}
          {this.state.isDeliveryRegionDropdownSearchable ? (
            <div
              className="custom-dropdown-search-overlay"
              onClick={this.closeCustomDropdown}
            ></div>
          ) : (
            ""
          )}
          {this.state.isLocationDropdownSearchable ? (
            <div
              className="custom-dropdown-search-overlay"
              onClick={this.closeCustomDropdown}
            ></div>
          ) : (
            ""
          )}
          {this.state.isIndustryDropdownSearchable ? (
            <div
              className="custom-dropdown-search-overlay"
              onClick={this.closeCustomDropdown}
            ></div>
          ) : (
            ""
          )}
        </Modal>

        {/*ClientOnboarded Confirmation Popup*/}
        <Modal
          show={this.state.notificationStatus}
          onHide={(e) => this.notificationModalClose("addProject")}
          className="tooldata-pop"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>Client Notification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="notification-content">
              <div className="notification-status">
                <span>
                  <i class="far fa-check"></i>
                </span>
              </div>
              {this.state.ClientBoardingPopupData.Status !== "Approved" ? (
                <div className="notification-data">
                  <h3>{i18n.t("Client - Project sent for approval")}!</h3>
                  <p>{i18n.t("Approval_Confirmation_message")}.</p>
                  <br></br>
                  <p>
                    Please proceed with Tool Setup for Data Automation.
                    <br />
                  </p>
                </div>
              ) : (
                <div className="notification-data">
                  <p>{i18n.t("Client Project details updated successfully")}</p>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="onboard-btn">
              <button
                onClick={(e) =>
                  this.state.ClientBoardingPopupData.Status !== "Approved"
                    ? this.notificationModalClose("AddMapping")
                    : this.notificationModalClose("addProject")
                }
                className="btn btn-default btn-sm notification-btn"
              >
                OK
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.isNewClientPopupOpen}
          onHide={this.handleUnitHide}
          className="modal fade dataprocessed-modal"
          size="medium"
          id="addNewClient"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">{i18n.t("Client Name")}</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="listSourceSec">
              <div className="row">
                <div className="form-group col-sm-11">
                  <input
                    className="form-control animated-input"
                    id="newClient"
                    placeholder={i18n.t("New Client")}
                    type="text"
                    maxLength="50"
                    onChange={(e) => this.handleNewClient(e)}
                    value={this.state.NewClientName}
                    tabIndex="1"
                  />
                </div>
                <div className="form-group col-sm-11">
                  {this.state.NewClientError ? (
                    <div className="errorMsg">{this.state.NewClientError}</div>
                  ) : null}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-btn">
              <Button variant="default" className="mr-2 footer-btn" onClick={this.handleUnitHide}>
                {i18n.t("Cancel")}
              </Button>
              <Button variant="primary" className="footer-btn" onClick={this.SaveDemoClient}>
                {i18n.t("Add Client")}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
