import React, { Component } from 'react';
import { store } from "../reducers/configureStore"
import MasterContainer from './MasterContainer';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import '../CSS/administration.css';
import axiosInstance from '../Shared/interceptor';
import '../CSS/administration.css';
import i18n from "i18next";
import { trycatchAlertPopup, LocalApiBaseUrl } from '../Shared/Constant';
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class MasterList extends Component {
  constructor() {
    super();
    this.state = {
      Submenu: [],
      roleData: [],
      userList: [],
      roleList: [],
      clientList: [],
      viewmore: false,
      rowSelected: [],
      featureData: [],
      featureData1: [],
      isDealClicked: false,
      isOfferingClicked: false,
      isUserClicked: false,
      isLocationClicked: false,
      isDEIClicked: false,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: false,
      isWhiteListURLClicked: false,
      isAttributeClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isClickableToolClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isUnitOfMeasurementClicked: false,
      isLandingPageClicked: false,
      isNotificationClicked: false,
      isMessageBoardClicked: false,
      isHelpFeaturesClicked: false,
      isLandingPageQuicklinkClicked: false,
      isGetDataClicked: false,
      pageHeader: "",
      isAdmin: false,
    };
    this.onDealClick = this.onDealClick.bind(this);
    this.onOfferingClick = this.onOfferingClick.bind(this);
    this.onLocationClick = this.onLocationClick.bind(this);
    this.onUserClick = this.onUserClick.bind(this);
    this.onDEIClick = this.onDEIClick.bind(this);
    this.onIojClick = this.onIojClick.bind(this);

    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.onUserProfileClick = this.onUserProfileClick.bind(this);
    this.getRoleData = this.getRoleData.bind(this);
    this.setDefaultTabOnLoad = this.setDefaultTabOnLoad.bind(this);
    this.onAttributeClick = this.onAttributeClick.bind(this);
    this.onClientClick = this.onClientClick.bind(this);
    this.onIndustryClick = this.onIndustryClick.bind(this);
    this.onClickableToolClick = this.onClickableToolClick.bind(this);
    this.onClientRegionClick = this.onClientRegionClick.bind(this);
    this.onDeliveryRegionClick = this.onDeliveryRegionClick.bind(this);
    this.onLandingPage = this.onLandingPage.bind(this);
    this.onLandingPageQuicklink = this.onLandingPageQuicklink.bind(this);

    this.onGetDataClick = this.onGetDataClick.bind(this);
    this.onWhiteListURLClick = this.onWhiteListURLClick.bind(this);
    this.getUserData = this.getUserData.bind(this);
  }

  getRoleData() {
    var data = store.getState();
    let roleData1 = data.roleReducers["roleReducers"];

    this.setState({ featureData: roleData1 });

    store.subscribe(() => {
      var data = store.getState();
      this.setState({ roleData: data.roleReducers["roleReducers"] });
      let roleData1 = data.roleReducers["roleReducers"];
      this.setState({ featureData: roleData1 });
    });
  }
  getUserData(){
    this.setState({ isApiLoading: true });
    axiosInstance
      .get(`${LocalApiBaseUrl}User/GetUserDetails`)
      .then((res) => {
        this.setState({ isApiLoading: false });
        if (res.data) {
          this.setState({ 
            isAdmin: res.data[0] === "True"
           });
        }
      })
      .catch((error) => {
        this.setState({ isApiLoading: false });
        trycatchAlertPopup(error);
      });
  }

  setDefaultTabOnLoad(selectedTab) {
    let tabData = [];
    switch (selectedTab) {
      case "Url_WhilteList":
        if(this.state.isAdmin){
          this.onWhiteListURLClick();
        }
        break;
      case "Users":
        tabData = this.state.featureData.filter(
          (data) =>
            String(data.Submenu) === "Users" && data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onUserClick();
        }
        break;
      case "Clients":
        tabData = this.state.featureData.filter(
          (data) =>
            String(data.Submenu) === "Clients" && data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onClientClick();
        }
        break;
      case "Delivery_Center":
        tabData = this.state.featureData.filter(
          (data) =>
            String(data.Submenu) === "Delivery Center" &&
            data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onLocationClick();
        }
        break;
      case "Offerings":
        tabData = this.state.featureData.filter(
          (data) =>
            String(data.Submenu) === "Offerings" && data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onOfferingClick();
        }
        break;
      case "Deals":
        tabData = this.state.featureData.filter(
          (data) =>
            String(data.Submenu) === "Deals" && data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onDealClick();
        }
        break;
      case "DEI":
        tabData = this.state.featureData.filter(
          (data) =>
            String(data.Submenu) === "DEI" && data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onDEIClick();
        }
        break;
      case "IOJ_Category":
        tabData = this.state.featureData.filter(
          (data) =>
            String(data.Submenu) === "IOJ Category" &&
            data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onIojClick();
        }
        break;
      case "Settings":
        tabData = this.state.featureData.filter(
          (data) =>
            String(data.Submenu) === "Settings" && data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onDetailsClick();
        }
        break;
      case "User_Profile":
        tabData = this.state.featureData.filter(
          (data) =>
            String(data.Submenu) === "User Profile" &&
            data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onUserProfileClick();
        }
        break;
      case "Attribute_Master":
        tabData = this.state.featureData.filter(
          (data) =>
            String(data.Submenu) === "Attribute Master" &&
            data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onAttributeClick();
        }
        break;
      case "Help_and_Feedback":
        tabData = this.state.featureData.filter(
          (data) =>
            String(data.Submenu) === "Help and Feedback" &&
            data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onHelpFeaturesClick();
        }
        break;
      case "Industry":
        tabData = this.state.featureData.filter(
          (data) =>
            String(data.Submenu) === "Industry" && data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onIndustryClick();
        }
        break;
      case "Client_Region":
        tabData = this.state.featureData.filter(
          (data) =>
            String(data.Submenu) === "Client Region" &&
            data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onClientRegionClick();
        }
        break;
      case "Delivery_Region":
        tabData = this.state.featureData.filter(
          (data) =>
            String(data.Submenu) === "Delivery Region" &&
            data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onDeliveryRegionClick();
        }
        break;
      case "Landing_Page":
        tabData = this.state.featureData.filter(
          (data) =>
            String(data.Submenu) === "Landing Page" &&
            data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onLandingPage();
        }
        break;
      case "Landing_Page_Quick_Links":
        tabData = this.state.featureData.filter(
          (data) =>
            String(data.Submenu) === "Landing Page Quick Links" &&
            data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onLandingPageQuicklink();
        }
        break;
      case "Get_Data":
        tabData = this.state.featureData.filter(
          (data) =>
            String(data.Submenu) === "Get Data" && data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onGetDataClick();
        }
        break;
      case "Notification":
        tabData = [{ Submenu: "Notifications" }];
        this.onNotification();
        // tabData = this.state.featureData.filter((data) => data.Submenu == "Notification" && data.Operations.length != 0);
        // if (tabData.length > 0) {
        //     this.onNotification();
        // }
        break;
      case "ClickableTool":
        tabData = this.state.featureData.filter(
          (data) =>
            String(data.Submenu) === "ClickableTool" &&
            data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onClickableToolClick();
        }
        break;
      case "Unit_of_Measurement":
        tabData = this.state.featureData.filter(
          (data) =>
            String(data.Submenu) === "Unit of Measurement" &&
            data.Operations.length !== 0
        );
        if (tabData.length > 0) {
          this.onUnitOfMeasurement();
        }
        break;
      case "Message_Board":
        tabData = [{ Submenu: "Message Board" }];
        this.onMessageBoard();

        break;
      default:
        break;
    }

    this.setState({
      pageHeader: tabData.length > 0 ? tabData[0].Submenu : "",
    });
  }

  loadTabBasedOnURL = () => {
    const reqPath = this.props.location.pathname;
    const reqPathArr = reqPath.split("/");
    if (reqPath.startsWith("/admin/Master") && reqPathArr.length === 4) {
      this.setDefaultTabOnLoad(reqPathArr[3]);
    }
  };

  componentWillMount() {
    this.getRoleData();
    this.getUserData();
    store.subscribe(() => {
      var data = store.getState();
      this.setState({ roleData: data.roleReducers["roleReducers"] });
      let roleData1 = data.roleReducers["roleReducers"];
      this.setState({ featureData: roleData1 });
    });

    this.loadTabBasedOnURL();
  }

  componentDidMount() {
    this.getRoleData();
    this.getUserData();
    store.subscribe(() => {
      var data = store.getState();
      this.setState({ roleData: data.roleReducers["roleReducers"] });
      let roleData1 = data.roleReducers["roleReducers"];
      this.setState({ featureData: roleData1 });

      this.loadTabBasedOnURL();
    });
  }

  componentDidUpdate(nextProps) {
    if (
      String(this.props.location.pathname) !==
      String(nextProps.location.pathname)
    )
      this.loadTabBasedOnURL();
  }

  onClientRegionClick(e) {
    this.setState({
      isDealClicked: false,
      isOfferingClicked: false,
      isLocationClicked: false,
      isUserClicked: false,
      isDEIClicked: false,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: false,
      isWhiteListURLClicked:false,
      isAttributeClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isClickableToolClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isClientRegionClicked: true,
      isDeliveryRegionClicked: false,
      isUnitOfMeasurementClicked: false,
      isNotificationClicked: false,
      isMessageBoardClicked: false,
      isLandingPageClicked: false,
      isHelpFeaturesClicked: false,
      isLandingPageQuicklinkClicked: false,
      isGetDataClicked: false,
    });
  }

  onDeliveryRegionClick(e) {
    this.setState({
      isDealClicked: false,
      isOfferingClicked: false,
      isLocationClicked: false,
      isUserClicked: false,
      isDEIClicked: false,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: false,
      isWhiteListURLClicked: false,
      isAttributeClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isClickableToolClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: true,
      isUnitOfMeasurementClicked: false,
      isNotificationClicked: false,
      isMessageBoardClicked: false,
      isLandingPageClicked: false,
      isHelpFeaturesClicked: false,
      isLandingPageQuicklinkClicked: false,
      isGetDataClicked: false,
    });
  }

  onDealClick(e) {
    this.setState({
      isDealClicked: true,
      isOfferingClicked: false,
      isLocationClicked: false,
      isUserClicked: false,
      isDEIClicked: false,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: false,
      isWhiteListURLClicked: false,
      isAttributeClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isClickableToolClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isUnitOfMeasurementClicked: false,
      isNotificationClicked: false,
      isMessageBoardClicked: false,
      isLandingPageClicked: false,
      isHelpFeaturesClicked: false,
      isLandingPageQuicklinkClicked: false,
      isGetDataClicked: false,
    });
  }
  onAttributeClick(e) {
    this.setState({
      isDealClicked: false,
      isOfferingClicked: false,
      isLocationClicked: false,
      isUserClicked: false,
      isDEIClicked: false,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: false,
      isWhiteListURLClicked: false,
      isAttributeClicked: true,
      isClientClicked: false,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isClickableToolClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isUnitOfMeasurementClicked: false,
      isNotificationClicked: false,
      isMessageBoardClicked: false,
      isLandingPageClicked: false,
      isHelpFeaturesClicked: false,
      isGetDataClicked: false,
    });
  }
  onHelpFeaturesClick = () => {
    this.setState({
      isDealClicked: false,
      isOfferingClicked: false,
      isLocationClicked: false,
      isUserClicked: false,
      isDEIClicked: false,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: false,
      isWhiteListURLClicked: false,
      isAttributeClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isClickableToolClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isUnitOfMeasurementClicked: false,
      isNotificationClicked: false,
      isMessageBoardClicked: false,
      isLandingPageClicked: false,
      isHelpFeaturesClicked: true,
      isLandingPageQuicklinkClicked: false,
      isGetDataClicked: false,
    });
  };

  onOfferingClick(e) {
    this.setState({
      isDealClicked: false,
      isOfferingClicked: true,
      isLocationClicked: false,
      isUserClicked: false,
      isDEIClicked: false,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: false,
      isWhiteListURLClicked: false,
      isAttributeClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isClickableToolClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isUnitOfMeasurementClicked: false,
      isNotificationClicked: false,
      isMessageBoardClicked: false,

      isLandingPageClicked: false,
      isHelpFeaturesClicked: false,

      isLandingPageQuicklinkClicked: false,
      isGetDataClicked: false,
    });
  }

  onLocationClick(e) {
    this.setState({
      isDealClicked: false,
      isOfferingClicked: false,
      isLocationClicked: true,
      isUserClicked: false,
      isDEIClicked: false,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: false,
      isWhiteListURLClicked: false,
      isAttributeClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isClickableToolClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isUnitOfMeasurementClicked: false,
      isNotificationClicked: false,
      isMessageBoardClicked: false,

      isLandingPageClicked: false,
      isHelpFeaturesClicked: false,

      isLandingPageQuicklinkClicked: false,
      isGetDataClicked: false,
    });
  }

  onUserClick(e) {
    this.setState({
      isDealClicked: false,
      isOfferingClicked: false,
      isLocationClicked: false,
      isUserClicked: true,
      isDEIClicked: false,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: false,
      isWhiteListURLClicked: false,
      isAttributeClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isClickableToolClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isUnitOfMeasurementClicked: false,
      isNotificationClicked: false,
      isMessageBoardClicked: false,

      isLandingPageClicked: false,
      isHelpFeaturesClicked: false,
      isLandingPageQuicklinkClicked: false,
      isGetDataClicked: false,
    });
  }
  onDEIClick(e) {
    this.setState({
      isDealClicked: false,
      isOfferingClicked: false,
      isLocationClicked: false,
      isUserClicked: false,
      isDEIClicked: true,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: false,
      isWhiteListURLClicked: false,
      isAttributeClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isClickableToolClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isUnitOfMeasurementClicked: false,
      isNotificationClicked: false,
      isMessageBoardClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isLandingPageClicked: false,
      isHelpFeaturesClicked: false,
      isLandingPageQuicklinkClicked: false,
      isGetDataClicked: false,
    });
  }
  onIojClick(e) {
    this.setState({
      isDealClicked: false,
      isOfferingClicked: false,
      isLocationClicked: false,
      isUserClicked: false,
      isDEIClicked: false,
      isIojClicked: true,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: false,
      isWhiteListURLClicked: false,
      isAttributeClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isClickableToolClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isUnitOfMeasurementClicked: false,
      isNotificationClicked: false,
      isMessageBoardClicked: false,
      isLandingPageClicked: false,
      isHelpFeaturesClicked: false,
      isLandingPageQuicklinkClicked: false,
      isGetDataClicked: false,
    });
  }

  onDetailsClick(e) {
    this.setState({
      isDealClicked: false,
      isOfferingClicked: false,
      isLocationClicked: false,
      isUserClicked: false,
      isDEIClicked: false,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: true,
      isUserProfileClicked: false,
      isWhiteListURLClicked: false,
      isAttributeClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isClickableToolClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,

      isUnitOfMeasurementClicked: false,
      isNotificationClicked: false,
      isMessageBoardClicked: false,
      isLandingPageClicked: false,
      isHelpFeaturesClicked: false,
      isLandingPageQuicklinkClicked: false,
      isGetDataClicked: false,
    });
  }

  onUserProfileClick(e) {
    this.setState({
      isDealClicked: false,
      isOfferingClicked: false,
      isLocationClicked: false,
      isUserClicked: false,
      isDEIClicked: false,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: true,
      isWhiteListURLClicked: false,
      isAttributeClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isClickableToolClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isUnitOfMeasurementClicked: false,
      isNotificationClicked: false,
      isMessageBoardClicked: false,
      isLandingPageClicked: false,
      isHelpFeaturesClicked: false,

      isLandingPageQuicklinkClicked: false,
      isGetDataClicked: false,
    });
  }
  onClientClick(e) {
    this.setState({
      isDealClicked: false,
      isOfferingClicked: false,
      isLocationClicked: false,
      isUserClicked: false,
      isDEIClicked: false,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: false,
      isWhiteListURLClicked: false,
      isAttributeClicked: false,
      isClientClicked: true,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isClickableToolClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isUnitOfMeasurementClicked: false,
      isNotificationClicked: false,
      isMessageBoardClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isLandingPageClicked: false,
      isHelpFeaturesClicked: false,
      isLandingPageQuicklinkClicked: false,
      isGetDataClicked: false,
    });
  }

  onIndustryClick(e) {
    this.setState({
      isDealClicked: false,
      isOfferingClicked: false,
      isLocationClicked: false,
      isUserClicked: false,
      isDEIClicked: false,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: false,
      isWhiteListURLClicked: false,
      isAttributeClicked: false,
      isClientClicked: false,
      isIndustryClicked: true,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isClickableToolClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isUnitOfMeasurementClicked: false,
      isNotificationClicked: false,
      isMessageBoardClicked: false,
      isClientRegionClicked: false,
      isDeliveryRegionClicked: false,
      isLandingPageClicked: false,
      isHelpFeaturesClicked: false,
      isLandingPageQuicklinkClicked: false,
      isGetDataClicked: false,
    });
  }
  onClickableToolClick(e) {
    this.setState({
      isDealClicked: false,
      isOfferingClicked: false,
      isLocationClicked: false,
      isUserClicked: false,
      isDEIClicked: false,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: false,
      isWhiteListURLClicked: false,
      isAttributeClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isClickableToolClicked: true,
      isUnitOfMeasurementClicked: false,
      isNotificationClicked: false,
      isMessageBoardClicked: false,
      isLandingPageClicked: false,
      isHelpFeaturesClicked: false,
      isLandingPageQuicklinkClicked: false,
      isGetDataClicked: false,
      isDeliveryRegionClicked: false,
    });
  }
  onUnitOfMeasurement = (e) => {
    this.setState({
      isDealClicked: false,
      isOfferingClicked: false,
      isLocationClicked: false,
      isUserClicked: false,
      isDEIClicked: false,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: false,
      isWhiteListURLClicked: false,
      isAttributeClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isClickableToolClicked: false,
      isUnitOfMeasurementClicked: true,
      isNotificationClicked: false,
      isMessageBoardClicked: false,
      isLandingPageClicked: false,
      isHelpFeaturesClicked: false,
      isLandingPageQuicklinkClicked: false,
      isGetDataClicked: false,
      isDeliveryRegionClicked: false,
    });
  };

  onNotification = (e) => {
    this.setState({
      isDealClicked: false,
      isOfferingClicked: false,
      isLocationClicked: false,
      isUserClicked: false,
      isDEIClicked: false,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: false,
      isWhiteListURLClicked: false,
      isAttributeClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isClickableToolClicked: false,
      isUnitOfMeasurementClicked: false,
      isNotificationClicked: true,
      isMessageBoardClicked: false,
      isLandingPageClicked: false,
      isHelpFeaturesClicked: false,
      isLandingPageQuicklinkClicked: false,
      isGetDataClicked: false,
      isDeliveryRegionClicked: false,
    });
  };
  onMessageBoard = (e) => {
    this.setState({
      isDealClicked: false,
      isOfferingClicked: false,
      isLocationClicked: false,
      isUserClicked: false,
      isDEIClicked: false,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: false,
      isWhiteListURLClicked: false,
      isAttributeClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isClickableToolClicked: false,
      isUnitOfMeasurementClicked: false,
      isNotificationClicked: false,
      isMessageBoardClicked: true,
      isLandingPageClicked: false,
      isHelpFeaturesClicked: false,
      isLandingPageQuicklinkClicked: false,
      isGetDataClicked: false,
      isDeliveryRegionClicked: false,
    });
  };
  onLandingPage = (e) => {
    this.setState({
      isDealClicked: false,
      isOfferingClicked: false,
      isLocationClicked: false,
      isUserClicked: false,
      isDEIClicked: false,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: false,
      isWhiteListURLClicked: false,
      isAttributeClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isClickableToolClicked: false,
      isUnitOfMeasurementClicked: false,
      isNotificationClicked: false,
      isMessageBoardClicked: false,
      isLandingPageClicked: true,
      isHelpFeaturesClicked: false,
      isLandingPageQuicklinkClicked: false,
      isGetDataClicked: false,
      isDeliveryRegionClicked: false,
    });
  };

  onLandingPageQuicklink = (e) => {
    this.setState({
      isDealClicked: false,
      isOfferingClicked: false,
      isLocationClicked: false,
      isUserClicked: false,
      isDEIClicked: false,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: false,
      isWhiteListURLClicked: false,
      isAttributeClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isClickableToolClicked: false,
      isUnitOfMeasurementClicked: false,
      isNotificationClicked: false,
      isMessageBoardClicked: false,
      isLandingPageClicked: false,
      isLandingPageQuicklinkClicked: true,
      isDeliveryRegionClicked: false,
      isHelpFeaturesClicked: false,
      isClientRegionClicked: false,
      isGetDataClicked: false,
    });
  };

  onGetDataClick = (e) => {
    this.setState({
      isDealClicked: false,
      isOfferingClicked: false,
      isLocationClicked: false,
      isUserClicked: false,
      isDEIClicked: false,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: false,
      isWhiteListURLClicked: false,
      isAttributeClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isClickableToolClicked: false,
      isUnitOfMeasurementClicked: false,
      isNotificationClicked: false,
      isMessageBoardClicked: false,
      isLandingPageClicked: false,
      isLandingPageQuicklinkClicked: false,
      isDeliveryRegionClicked: false,
      isHelpFeaturesClicked: false,
      isClientRegionClicked: false,
      isGetDataClicked: true,
    });
  };

  onWhiteListURLClick = (e) => {
    this.setState({
      isDealClicked: false,
      isOfferingClicked: false,
      isLocationClicked: false,
      isUserClicked: false,
      isDEIClicked: false,
      isIojClicked: false,
      isConnnectorClicked: false,
      isDetailsClicked: false,
      isUserProfileClicked: false,
      isWhiteListURLClicked: true,
      isAttributeClicked: false,
      isClientClicked: false,
      isIndustryClicked: false,
      isDealFilterClicked: false,
      isDealConnectorClicked: false,
      isConnectorFieldMasterDataClicked: false,
      isClickableToolClicked: false,
      isUnitOfMeasurementClicked: false,
      isNotificationClicked: false,
      isMessageBoardClicked: false,
      isLandingPageClicked: false,
      isLandingPageQuicklinkClicked: false,
      isDeliveryRegionClicked: false,
      isHelpFeaturesClicked: false,
      isClientRegionClicked: false,
      isGetDataClicked: false,
    });
  };

  render() {
    return (
      <div className="main-component">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="tab-list"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <h3 className="grid-heading">
                {i18n.t("Master") +
                  " > " +
                  (this.props.location.state
                    ? i18n.t(this.props.location.state.detail)
                    : i18n.t(this.state.pageHeader))}
              </h3>
              <MasterContainer
                filterDetails={this.props.filterDetails}
                isAdmin ={this.state.isAdmin}
                ConnectorClicked={this.state.isConnnectorClicked}
                dealClicked={this.state.isDealClicked}
                offeringClicked={this.state.isOfferingClicked}
                locationClicked={this.state.isLocationClicked}
                userClicked={this.state.isUserClicked}
                DEIClicked={this.state.isDEIClicked}
                IojClicked={this.state.isIojClicked}
                DetailsClicked={this.state.isDetailsClicked}
                UserProfileClicked={this.state.isUserProfileClicked}
                WhiteListURLClicked ={this.state.isWhiteListURLClicked}
                AttributeClicked={this.state.isAttributeClicked}
                ClientClicked={this.state.isClientClicked}
                IndustryClicked={this.state.isIndustryClicked}
                DealFilterClicked={this.state.isDealFilterClicked}
                DealConnectorClicked={this.state.isDealConnectorClicked}
                ConnectorFieldMasterDataClicked={
                  this.state.isConnectorFieldMasterDataClicked
                }
                ClickableToolClicked={this.state.isClickableToolClicked}
                ClientRegionClicked={this.state.isClientRegionClicked}
                DeliveryRegionClicked={this.state.isDeliveryRegionClicked}
                NotificationClicked={this.state.isNotificationClicked}
                MessageBoardClicked={this.state.isMessageBoardClicked}
                LandingPageClicked={this.state.isLandingPageClicked}
                HelpFeaturesClicked={this.state.isHelpFeaturesClicked}
                LandingPageQuicklinkClicked={
                  this.state.isLandingPageQuicklinkClicked
                }
                GetDataClicked={this.state.isGetDataClicked}
                UnitOfMeasurementClicked={this.state.isUnitOfMeasurementClicked}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
