import React, { useEffect, useState} from "react";
import MultiSelect from "react-multi-select-component";
import BookGrey from "../Images/book-grey.svg";
import BookBlue from "../Images/book-blue.svg";
import "./JourneyInitiative.css";
import { trycatchAlertPopup } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import axiosInstance from "../Shared/interceptor";
import { LocalApiBaseUrl} from "../Shared/Constant";
import { parseInt, set } from "lodash";
import i18n from "i18next";
import _ from "lodash";
import classNames from "classnames";
import ClientStoryNotification from "./ClientStoryNotification";
import ClientStoryViewer from "./ClientStoryViewer";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
function IOJClientStory(props) {

    const{
        formData,       
        ClientStoryData,
        initiativesDropDownData,
        outcomeDropDownData,
        isAccess,
        CurrentFilter,
        clientData,
        filterDetails,
       SelectedFilter,
       handleChange,
       generateVersion,
       submitStoryForm,
       getSingleStoryData,
       showForm,
       closeform,
       isChanged,
       showformmethod,
       showConfiguratorUser,
       GetIOJClientStories,
       body,
       callClientStoryAPI,
       isApiLoading
    }=props

    const overrideStrings = {
        "selectSomeItems": "Select",
        "allItemsAreSelected": "All items selected.",
        "selectAll": "Select All ",
        "search": "Search"
      }

let[showIOJForm,setshowIOJForm]=useState(false);
let[isEdit,setisEdit]=useState(false);
let[errormessage,seterrormessage]=useState("");
let[storynameerror,setstorynameerror]=useState("");
let[challengeserror,setchallengeserror]=useState("");
let[outcomeserror,setoutcomeserror]=useState("");
let[solutionerror,setsolutionerror]=useState("");
let[whatnexterror,setwhatnexterror]=useState("");
let[isInitiativeOutcomesDropdownStatus,setisInitiativeOutcomesDropdownStatus]= useState(false);
let[isInitiativeOutcomesDropdownStatusDiverseData,setisInitiativeOutcomesDropdownStatusDiverseData]= useState(false);
let[isInitiativeOutcomesDropdownStatusWO,setisInitiativeOutcomesDropdownStatusWO]= useState(false);
let[isInitiativeOutcomesDropdownStatusHM,setisInitiativeOutcomesDropdownStatusHM]= useState(false);
let[isInitiativeOutcomesDropdownStatusII,setisInitiativeOutcomesDropdownStatusII]= useState(false);
let[deleteDemoStatus,setdeleteDemoStatus]=useState(false);
let[viewClientStoryModalStatus,setviewClientStoryModalStatus]=useState(false);
let[savedStoryStatus,setsavedStoryStatus]=useState(false);
let[deleteStoryId,setdeleteStoryId]=useState(0);
let[storyviewdata,setstoryviewdata]=useState({});
let [showCreateStoryData,setShowCreateStoryData] = useState(true);
let [data,setData] = useState(formData);
const [isloading, setisloading] = useState(false);

// MAINTAINS THE LIST OF "MULTI-SELECTS" IN THE FORM WITH THE CORRESPONDING "FORM" FIELD NAMES
const [formMultiSelectsProps, setFormMultiSelectsProps] = useState({
    initiativeOutcomes: {
      isVisible: false,
      tempSelectedValues:formData.InitiativesandOutComes,
      selectedValues:formData.InitiativesandOutComes,
      formfieldName:"InitiativesandOutComes"
    },
    DDInitiative: {
      isVisible: false,
      tempSelectedValues:formData.ddInitiatives,
      selectedValues: formData.ddInitiatives,
      formfieldName:"ddInitiatives"
    },
    WOInitiative: {
        isVisible: false,
        tempSelectedValues:formData.woInitiatives,
        selectedValues: formData.woInitiatives,
        formfieldName:"woInitiatives"
      },
    HMInitiative: {
        isVisible: false,
        tempSelectedValues:formData.hmInitiatives,
        selectedValues: formData.hmInitiatives,
        formfieldName:"hmInitiatives"
      },
    IIInitiative: {
        isVisible: false,
        tempSelectedValues:formData.iiInitiatives,
        selectedValues: formData.iiInitiatives,
        formfieldName:"iiInitiatives"
      },
  });

  let Number=6;
let message="You can select only 6 items";
const checkMultiselectValid=()=>{
    let isValid=true;
    if(formData.InitiativesandOutComes.length>Number ||formData.ddInitiatives.length>Number 
        ||formData.iiInitiatives.length>Number || formData.woInitiatives.length>Number
        ||formData.hmInitiatives.length>Number
        ){
            isValid=false;
    }
    return isValid;
}
useEffect(() => {
  callClientStoryAPI();
},[])

  useEffect(() => {
    setshowIOJForm(showForm);
    setFormMultiSelectsProps((prev) => {
        const modified = _.cloneDeep(prev);

        _.set(modified, "initiativeOutcomes.selectedValues", formData.InitiativesandOutComes);
        _.set(modified, "initiativeOutcomes.tempSelectedValues", formData.InitiativesandOutComes);

        _.set(modified, "DDInitiative.selectedValues", formData.ddInitiatives);
        _.set(modified, "DDInitiative.tempSelectedValues", formData.ddInitiatives);
  
        _.set(modified, "IIInitiative.selectedValues", formData.iiInitiatives);
        _.set(modified, "IIInitiative.tempSelectedValues", formData.iiInitiatives);

        _.set(modified, "WOInitiative.selectedValues", formData.woInitiatives);
        _.set(modified, "WOInitiative.tempSelectedValues", formData.woInitiatives);

        _.set(modified, "HMInitiative.selectedValues", formData.hmInitiatives);
        _.set(modified, "HMInitiative.tempSelectedValues", formData.hmInitiatives);
        return modified;
      });
}, [formData.StoryName,showForm,formData.ddInitiatives,formData.iiInitiatives,formData.woInitiatives,formData.hmInitiatives,formData.InitiativesandOutComes])


  const limitNoOfLabelsForDisplay = 3;
// BELOW BLOCK OF CODE IS FOR "DD INITIATIVES" MULTI SELECT
const isVisibleinitiativeOutcomesMultiSelect =
!_.isEmpty(initiativesDropDownData) && _.get(formMultiSelectsProps, "initiativeOutcomes.isVisible");
const tempSelectedValuesinitiativeOutcomesMultiSelect = _.get(
formMultiSelectsProps,
"initiativeOutcomes.tempSelectedValues"
);
const selectedValuesinitiativeOutcomesMultiSelect = _.get(
formMultiSelectsProps,
"initiativeOutcomes.selectedValues"
);

const areAllSelectedinitiativeOutcomesMultiSelect =
!_.isEmpty(outcomeDropDownData) &&
_.size(selectedValuesinitiativeOutcomesMultiSelect) === _.size(outcomeDropDownData);

let displayLabelFromselectedValuesinitiativeOutcomesMultiSelect = !_.isEmpty(outcomeDropDownData)
? i18n.t("Initiative_Outcomes")
: i18n.t("No_Data_Is_Available");
// WHEN ALL OPTIONS ARE SELECTED
if (areAllSelectedinitiativeOutcomesMultiSelect) {
displayLabelFromselectedValuesinitiativeOutcomesMultiSelect = i18n.t("All_Selected");
} else if (!_.isEmpty(selectedValuesinitiativeOutcomesMultiSelect)) {
// WHEN SOME OPTIONS ARE SELECTED
let selectedinitiativeOutcomesLabelsArray = [];

_.forEach(outcomeDropDownData, (eachItem) => {
  if (_.indexOf(selectedValuesinitiativeOutcomesMultiSelect, eachItem.value) !== -1) {
   
    selectedinitiativeOutcomesLabelsArray.push(eachItem.label);
  }
});


const selectedinitiativeOutcomesLabelsArrayLength = _.size(selectedinitiativeOutcomesLabelsArray);
if (selectedinitiativeOutcomesLabelsArrayLength > limitNoOfLabelsForDisplay) {
  const firstChunkOfSelectedLabels = _.chunk(selectedinitiativeOutcomesLabelsArray, limitNoOfLabelsForDisplay)[0];
  displayLabelFromselectedValuesinitiativeOutcomesMultiSelect = `${_.join(
    firstChunkOfSelectedLabels,
    ", "
  )} +${selectedinitiativeOutcomesLabelsArrayLength - limitNoOfLabelsForDisplay}`;
} else {
  displayLabelFromselectedValuesinitiativeOutcomesMultiSelect = _.join(selectedinitiativeOutcomesLabelsArray, ", ");
}
}
///////////////////////////////////////////////////////////////////////////////////////////

 // BELOW BLOCK OF CODE IS FOR "DD INITIATIVES" MULTI SELECT
 const isVisibleDDInitiativesMultiSelect =
 !_.isEmpty(initiativesDropDownData) && _.get(formMultiSelectsProps, "DDInitiative.isVisible");
const tempSelectedValuesDDInitiativesMultiSelect = _.get(
 formMultiSelectsProps,
 "DDInitiative.tempSelectedValues"
);
const selectedValuesDDInitiativesMultiSelect = _.get(
 formMultiSelectsProps,
 "DDInitiative.selectedValues"
);

const areAllSelectedDDInitiativesMultiSelect =
 !_.isEmpty(initiativesDropDownData) &&
 _.size(selectedValuesDDInitiativesMultiSelect) === _.size(initiativesDropDownData);

let displayLabelFromselectedValuesDDInitiativesMultiSelect = !_.isEmpty(initiativesDropDownData)
&& !_.isEmpty(initiativesDropDownData.filter(a=>parseInt(a.SOCategoryID)===5))
 ? i18n.t("Diverse_Data_Initiative")
 : i18n.t("No_Data_Is_Available");
// WHEN ALL OPTIONS ARE SELECTED
if (areAllSelectedDDInitiativesMultiSelect) {
 displayLabelFromselectedValuesDDInitiativesMultiSelect = i18n.t("All_Selected");
} else if (!_.isEmpty(selectedValuesDDInitiativesMultiSelect)) {
 // WHEN SOME OPTIONS ARE SELECTED
 let selectedDDLabelsArray = [];
 _.forEach(initiativesDropDownData, (eachItem) => {
   if (_.indexOf(selectedValuesDDInitiativesMultiSelect, eachItem.value) !== -1) {
     selectedDDLabelsArray.push(eachItem.label);
   }
 });

 const selectedDDLabelsArrayLength = _.size(selectedDDLabelsArray);
 if (selectedDDLabelsArrayLength > limitNoOfLabelsForDisplay) {
   const firstChunkOfSelectedLabels = _.chunk(selectedDDLabelsArray, limitNoOfLabelsForDisplay)[0];
   displayLabelFromselectedValuesDDInitiativesMultiSelect = `${_.join(
     firstChunkOfSelectedLabels,
     ", "
   )} +${selectedDDLabelsArrayLength - limitNoOfLabelsForDisplay}`;
 } else {
   displayLabelFromselectedValuesDDInitiativesMultiSelect = _.join(selectedDDLabelsArray, ", ");
 }
}
///////////////////////////////////////////////////////////////////////////////////////////

// BELOW BLOCK OF CODE IS FOR "WO INITIATIVES" MULTI SELECT
const isVisibleWOInitiativesMultiSelect =
!_.isEmpty(initiativesDropDownData) && _.get(formMultiSelectsProps, "WOInitiative.isVisible");
const tempSelectedValuesWOInitiativesMultiSelect = _.get(
formMultiSelectsProps,
"WOInitiative.tempSelectedValues"
);
const selectedValuesWOInitiativesMultiSelect = _.get(
formMultiSelectsProps,
"WOInitiative.selectedValues"
);

const areAllSelectedWOInitiativesMultiSelect =
!_.isEmpty(initiativesDropDownData) &&
_.size(selectedValuesDDInitiativesMultiSelect) === _.size(initiativesDropDownData);

let displayLabelFromselectedValuesWOInitiativesMultiSelect = !_.isEmpty(initiativesDropDownData)
&& !_.isEmpty(initiativesDropDownData.filter(a=>parseInt(a.SOCategoryID)===3))
? i18n.t("Work_Orchestration_Initiative")
: i18n.t("No_Data_Is_Available");
// WHEN ALL OPTIONS ARE SELECTED
if (areAllSelectedWOInitiativesMultiSelect) {
displayLabelFromselectedValuesWOInitiativesMultiSelect = i18n.t("All_Selected");
} else if (!_.isEmpty(selectedValuesWOInitiativesMultiSelect)) {
// WHEN SOME OPTIONS ARE SELECTED
let selectedWOLabelsArray = [];
_.forEach(initiativesDropDownData, (eachItem) => {
  if (_.indexOf(selectedValuesWOInitiativesMultiSelect, eachItem.value) !== -1) {
    selectedWOLabelsArray.push(eachItem.label);
  }
});

const selectedWOLabelsArrayLength = _.size(selectedWOLabelsArray);
if (selectedWOLabelsArrayLength > limitNoOfLabelsForDisplay) {
  const firstChunkOfSelectedLabels = _.chunk(selectedWOLabelsArray, limitNoOfLabelsForDisplay)[0];
  displayLabelFromselectedValuesWOInitiativesMultiSelect = `${_.join(
    firstChunkOfSelectedLabels,
    ", "
  )} +${selectedWOLabelsArrayLength - limitNoOfLabelsForDisplay}`;
} else {
  displayLabelFromselectedValuesWOInitiativesMultiSelect = _.join(selectedWOLabelsArray, ", ");
}
}
///////////////////////////////////////////////////////////////////////////////////////////

// BELOW BLOCK OF CODE IS FOR "II INITIATIVES" MULTI SELECT
const isVisibleIIInitiativesMultiSelect =
!_.isEmpty(initiativesDropDownData) && _.get(formMultiSelectsProps, "IIInitiative.isVisible");
const tempSelectedValuesIIInitiativesMultiSelect = _.get(
formMultiSelectsProps,
"IIInitiative.tempSelectedValues"
);
const selectedValuesIIInitiativesMultiSelect = _.get(
formMultiSelectsProps,
"IIInitiative.selectedValues"
);

const areAllSelectedIIInitiativesMultiSelect =
!_.isEmpty(initiativesDropDownData) &&
_.size(selectedValuesIIInitiativesMultiSelect) === _.size(initiativesDropDownData);

let displayLabelFromselectedValuesIIInitiativesMultiSelect = !_.isEmpty(initiativesDropDownData)
&& !_.isEmpty(initiativesDropDownData.filter(a=>parseInt(a.SOCategoryID)===4))
? i18n.t("Insights_Intelligence_Initiative")
: i18n.t("No_Data_Is_Available");
// WHEN ALL OPTIONS ARE SELECTED
if (areAllSelectedIIInitiativesMultiSelect) {
displayLabelFromselectedValuesIIInitiativesMultiSelect = i18n.t("All_Selected");
} else if (!_.isEmpty(selectedValuesIIInitiativesMultiSelect)) {
// WHEN SOME OPTIONS ARE SELECTED
let selectedIILabelsArray = [];
_.forEach(initiativesDropDownData, (eachItem) => {
  if (_.indexOf(selectedValuesIIInitiativesMultiSelect, eachItem.value) !== -1) {
    selectedIILabelsArray.push(eachItem.label);
  }
});

const selectedIILabelsArrayLength = _.size(selectedIILabelsArray);
if (selectedIILabelsArrayLength > limitNoOfLabelsForDisplay) {
  const firstChunkOfSelectedLabels = _.chunk(selectedIILabelsArray, limitNoOfLabelsForDisplay)[0];
  displayLabelFromselectedValuesIIInitiativesMultiSelect = `${_.join(
    firstChunkOfSelectedLabels,
    ", "
  )} +${selectedIILabelsArrayLength - limitNoOfLabelsForDisplay}`;
} else {
  displayLabelFromselectedValuesIIInitiativesMultiSelect = _.join(selectedIILabelsArray, ", ");
}
}
///////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////

// BELOW BLOCK OF CODE IS FOR "HM INITIATIVES" MULTI SELECT
const isVisibleHMInitiativesMultiSelect =
!_.isEmpty(initiativesDropDownData) && _.get(formMultiSelectsProps, "HMInitiative.isVisible");
const tempSelectedValuesHMInitiativesMultiSelect = _.get(
formMultiSelectsProps,
"HMInitiative.tempSelectedValues"
);
const selectedValuesHMInitiativesMultiSelect = _.get(
formMultiSelectsProps,
"HMInitiative.selectedValues"
);

const areAllSelectedHMInitiativesMultiSelect =
!_.isEmpty(initiativesDropDownData) &&
_.size(selectedValuesHMInitiativesMultiSelect) === _.size(initiativesDropDownData);

let displayLabelFromselectedValuesHMInitiativesMultiSelect = !_.isEmpty(initiativesDropDownData)
&& !_.isEmpty(initiativesDropDownData.filter(a=>parseInt(a.SOCategoryID)===2))
? i18n.t("Human_Machine_Initiative")
: i18n.t("No_Data_Is_Available");
// WHEN ALL OPTIONS ARE SELECTED
if (areAllSelectedHMInitiativesMultiSelect) {
displayLabelFromselectedValuesHMInitiativesMultiSelect = i18n.t("All_Selected");
} else if (!_.isEmpty(selectedValuesHMInitiativesMultiSelect)) {
// WHEN SOME OPTIONS ARE SELECTED
let selectedHMLabelsArray = [];
_.forEach(initiativesDropDownData, (eachItem) => {
  if (_.indexOf(selectedValuesHMInitiativesMultiSelect, eachItem.value) !== -1) {
    selectedHMLabelsArray.push(eachItem.label);
  }
});

const selectedHMLabelsArrayLength = _.size(selectedHMLabelsArray);
if (selectedHMLabelsArrayLength > limitNoOfLabelsForDisplay) {
  const firstChunkOfSelectedLabels = _.chunk(selectedHMLabelsArray, limitNoOfLabelsForDisplay)[0];
  displayLabelFromselectedValuesHMInitiativesMultiSelect = `${_.join(
    firstChunkOfSelectedLabels,
    ", "
  )} +${selectedHMLabelsArrayLength - limitNoOfLabelsForDisplay}`;
} else {
  displayLabelFromselectedValuesHMInitiativesMultiSelect = _.join(selectedHMLabelsArray, ", ");
}
}
///////////////////////////////////////////////////////////////////////////////////////////

      const handleForm = () =>{
        setData({});
          setisEdit(false);
          showformmethod();
          formData.StoryName="";
          formData.Challenges="";
          formData.Outcomes="";
          formData.Solution="";
          formData.DiverseData="";
          formData.WorkOrchestration="";
          formData.HumanMachine="";
          formData.InsightsIntelligence="";
          formData.WhatNext="";
          formData.Version="";
          formData.hmInitiatives=[];
          formData.woInitiatives=[];
          formData.ddInitiatives=[];
          formData.iiInitiatives=[];
          formData.InitiativesandOutComes=[];
      }


const validateformData=()=>{
    let error="";
    let isValid=true;
    let{StoryName,Challenges,Outcomes,Solution,WhatNext}=formData 

    if(!isEdit && !_.isEmpty(ClientStoryData) && ClientStoryData.some(a=>a.StoryName.trim().toUpperCase()===StoryName.trim().toUpperCase()))
    {
        isValid=false;
        error=i18n.t("Story_Already_Exist");
      setstorynameerror(error);
    }
    
    if(!StoryName.trim() || _.isEmpty(StoryName) || _.isEmpty(Challenges) || !Challenges.trim()|| _.isEmpty(Outcomes) ||!Outcomes.trim()|| _.isEmpty(Solution) || !Solution.trim() ||
     _.isEmpty(WhatNext) || !WhatNext.trim()){
        isValid=false;
        error=i18n.t("Insufficient_data_to_create_client_story");
        seterrormessage(error);
    }
    else{
      seterrormessage("");
    }

    if (!isEdit && _.isEmpty(StoryName)) {
      error = i18n.t("Story Name is required");
      isValid = false;
      setstorynameerror(error);
    }
    else{
      setstorynameerror("");
    }

    if (_.isEmpty(Challenges)) {
      error = i18n.t("Challenges is required");
      isValid = false;
      setchallengeserror(error);
    }
    else{
      setchallengeserror("");
    }

    if (_.isEmpty(Outcomes)) {
      error = i18n.t("Outcomes is required");
      isValid = false;
      setoutcomeserror(error);
    }
    else{
      setoutcomeserror("");
    }

    if (_.isEmpty(Solution)) {
      error = i18n.t("Solution is required");
      isValid = false;
      setsolutionerror(error);
    }
    else{
      setsolutionerror("");
    }

    if (_.isEmpty(WhatNext)) {
      error = i18n.t("What's Next is required");
      isValid = false;
      setwhatnexterror(error);
    }
    else{
      setwhatnexterror("");
    }

    if(!checkMultiselectValid()){
        isValid=false;
        alert(message);
    }
        return isValid;
}
const handleSubmit=()=>{
    if(validateformData()){
    submitStoryForm();
    }

}


const handleUpdate = (data) => {

       getSingleStoryData(data);   
       setisEdit(true); 
       //setshowIOJForm(true);
  }
  const deleteStory=()=>{
    handleDelete();
  }
  const handleDelete = () =>{
    setisloading(true);
       axiosInstance
         .delete(`${LocalApiBaseUrl}IOJJourneyInitiative/DeleteIOJClientStory`, {
           params:{
            ID:deleteStoryId
           }
         })
         .then((response) => {
          setisloading(false);
            GetIOJClientStories(body);
           alert(response.data.StatusMessage);
          setdeleteDemoStatus(false)
         })
       .catch((error) => {
           trycatchAlertPopup(error);
        });
    }
    const closedeleteNotification=()=>{
        setdeleteDemoStatus(false);
        setdeleteStoryId(0);
    }
  const deleteDemoModalShow = (id) => {
    setdeleteDemoStatus(true);
    setdeleteStoryId(id);
  };
const handleFormChange = e =>{
    handleChange(e);
  }
const viewClientStoryModalClose=()=>{
  setviewClientStoryModalStatus(false);
}

  const viewClientStoryModalShow= (data) => {
    setstoryviewdata(data); 
    setviewClientStoryModalStatus(true);
  }
//InitiativeOtcomes
const isInitiativeOutcomesDropdown = () => {
    setisInitiativeOutcomesDropdownStatus(true);
  };
  const isInitiativeOutcomesDropdownOverlay = () => {
    setisInitiativeOutcomesDropdownStatus(false);
  };
  //DiverseData
  const isInitiativeOutcomesDropdownDiverseData = () => {
    setisInitiativeOutcomesDropdownStatusDiverseData(true);
  };
  const isInitiativeOutcomesDropdownOverlayDiverseData = () => {
    setisInitiativeOutcomesDropdownStatusDiverseData(false);
  };
  //WorkOrchestation
  const isInitiativeOutcomesDropdownWO = () => {
    setisInitiativeOutcomesDropdownStatusWO(true);
  };
  const isInitiativeOutcomesDropdownOverlayWO = () => {
    setisInitiativeOutcomesDropdownStatusWO(false);
  };
  //Human&Machine
  const  isInitiativeOutcomesDropdownHM = () => {
    setisInitiativeOutcomesDropdownStatusHM(true);
  };
  const isInitiativeOutcomesDropdownOverlayHM = () => {
    setisInitiativeOutcomesDropdownStatusHM(false);
  };
  //Insights&Intelligence
  const isInitiativeOutcomesDropdownII = () => {
    setisInitiativeOutcomesDropdownStatusII(true);
  };
  const isInitiativeOutcomesDropdownOverlayII = () => {
    setisInitiativeOutcomesDropdownStatusII(false);
  };
  
// EXECUTES WHEN THE MULTI-SELECT OPTION IS CLICKED
const handleClickMultiSelectOption=(e, fieldName, optionValue)=> {
    const clickedElementId = _.get(e, "currentTarget.id", "");

    // PUTTING THIS CHECK FOR VALIDATING THE EMPTY ID OF THE ELEMENT WOULD PREVENT THE STATE BEING UPDATED FROM THE CLICK EVENT THAT IS TRIGGERED FROM OTHER ELEMENTS EXCEPT THE `<li>` AND `checkbox`
    if (!_.isEmpty(clickedElementId)) {
      let selectedValues = [];
      setFormMultiSelectsProps((prev) => {
        const fieldPrevProps = prev[fieldName];
        const clonedTempSelectedValues = _.cloneDeep(fieldPrevProps.tempSelectedValues);
        if (_.indexOf(clonedTempSelectedValues, optionValue) !== -1) {
          _.pull(clonedTempSelectedValues, optionValue);
        } else {
          clonedTempSelectedValues.push(optionValue);
        }
        const modified = _.cloneDeep(prev);
        if(clonedTempSelectedValues.length>Number){
            alert(message);
        }
        else{
        _.set(modified, `${fieldName}.tempSelectedValues`, clonedTempSelectedValues);
        _.set(modified, `${fieldName}.selectedValues`, clonedTempSelectedValues);
        selectedValues = clonedTempSelectedValues;
        let target={name:_.get(formMultiSelectsProps, `${fieldName}.formfieldName`),value:clonedTempSelectedValues}
        handleChange(e={target:target});
        }
        return modified;
      });
    }
  }
  const getVersion=()=>{
    let{StoryName}=formData 
    let error="";
    let isValid=true;
    if(!isEdit && !_.isEmpty(ClientStoryData) && ClientStoryData.some(a=>a.StoryName.trim().toUpperCase()===StoryName.trim().toUpperCase()))
    {
        isValid=false;
        error=i18n.t("Story_Already_Exist");
      setstorynameerror(error);
    }
      if(!isEdit && isValid){
        setstorynameerror("");
        if(!_.isEmpty(StoryName)){
       generateVersion();
      }
      }
  }
  const cancelForm=()=>{
      seterrormessage("");
      setstorynameerror("");
      setchallengeserror("");
      setoutcomeserror("");
      setsolutionerror("");
      setwhatnexterror("");
      closeform();
      formData.StoryName="";
      formData.Challenges="";
      formData.Outcomes="";
      formData.Solution="";
      formData.DiverseData="";
      formData.WorkOrchestration="";
      formData.HumanMachine="";
      formData.InsightsIntelligence="";
      formData.WhatNext="";
      formData.Version="";
      formData.hmInitiatives=[];
      formData.woInitiatives=[];
      formData.ddInitiatives=[];
      formData.iiInitiatives=[];
      formData.InitiativesandOutComes=[];
  }
 
 if(formData.Version === "V1")
 formData.Version = "V1.0";
    return (
      <>
        <LoadingOverlay
          spinner={<SynopsLoader />}
          active={isApiLoading || isloading}
        ></LoadingOverlay>
        <div className="storytelling-container">
        {/* {isAccess && */}
        {showIOJForm && showConfiguratorUser &&(

        <div className="createstory-btn cancel" style={{right:"129px"}}>
            <button onClick={()=>cancelForm()} className="btn btn-purplebg">
            Cancel
            </button>
          </div>
        )}
          <div className="createstory-btn">
          {!showIOJForm && showConfiguratorUser && 
            
            <button onClick={()=>handleForm()} className="btn btn-purplebg">
            {i18n.t("Create_Story_Data")}
            </button>}
            
            {showIOJForm && showConfiguratorUser &&(
            <button onClick={()=>handleSubmit()} className="btn btn-purplebg" disabled={isEdit && !isChanged}>
            {isEdit?`${i18n.t("Update")}`:`${i18n.t("Submit")}`}
            </button>
            )}
          </div>
          
          {/* } */}
          {/* Empty Client Story Condition Start */}
       
         {!showIOJForm && ClientStoryData.length===0 &&  <div className="emptycriteria-block">
              <img src={BookGrey}/>
              <h4>{i18n.t("Create_Story_Data_to_Begin")}</h4>
            </div>  } 
        
          {/* Empty Client Story Condition End */}
          {/* Client Story Form Start */} 
          {showIOJForm && (
          <div className="error-message" style={{color:"red",fontWeight:"bold"}}>
              {errormessage}
          </div>
          )}
  {showIOJForm &&
   <div className="createstory-formsection">
      {/* <span className="createform-errormsg">Insufficient data to create Client Story</span> */}
      <div className="createstory-form">
        {isEdit ? <h5>{i18n.t("Update_Story")}</h5> :<h5>{i18n.t("Create_Story")}</h5>}
        <div className="row">
          {isEdit ? 
           <div className="col-sm-6">
          <div className="form-section">
              <div className="form-group disabled border-group">
                <label htmlFor="StoryName" className="form-label">
                {i18n.t("Story_Name")}
                  <span className="required">&nbsp;*&nbsp;</span> 
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Story Name"
                  maxLength="25"
                  onChange={(e)=>handleFormChange(e)}
                  value={formData.StoryName}
                  name="StoryName"
                  readOnly={isEdit}
                  onBlur={()=>getVersion()}
                />
                <span className="charcount-label">{formData.StoryName.length}/{i18n.t("25_Characters")}</span>
               </div>
              </div>
            </div>:
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="StoryName" className="form-label">
                {i18n.t("Story_Name")}
                  <span className="required">&nbsp;*&nbsp;</span> 
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Story Name"
                  maxLength="25"
                  onChange={(e)=>handleFormChange(e)}
                  value={formData.StoryName}
                  name="StoryName"
                  readOnly={isEdit}
                  onBlur={()=>getVersion()}
                />
                <span className="charcount-label">{formData.StoryName.length}/{i18n.t("25_Characters")}</span>
              
               </div>
              </div>
              <div className="errorMsg">{storynameerror}</div>
          </div>}

          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group disabled border-group">
                <label htmlFor="Versions" className="form-label">
                {i18n.t("version")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Versions"
                  value = {formData.Version}
                  name="Version"
                  readOnly
                  disabled
                /> 
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="Challenges" className="form-label">
                {i18n.t("challenges")}
                  <span className="required">&nbsp;*&nbsp;</span> 
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Challenges"
                  onChange={(e)=>handleFormChange(e)}
                  value={formData.Challenges}
                  name="Challenges"
                  maxLength="650"
                />
                <span className="charcount-label">{formData.Challenges.length}/{i18n.t("650_Characters")}</span>
              </div>
            </div>
            <div className="errorMsg">{challengeserror}</div>
          </div>
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="Outcomes" className="form-label">
                {i18n.t("outcomes")}
                  <span className="required">&nbsp;*&nbsp;</span> 
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Outcomes"
                  onChange={(e)=>handleFormChange(e)}
                  value={formData.Outcomes}
                  name="Outcomes"
                  maxLength="350"
                />
                <span className="charcount-label">{formData.Outcomes.length}/{i18n.t("350_Characters")}</span>
              </div>
            </div>
            <div className="errorMsg">{outcomeserror}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="InitiativeOutcomes" className="form-label">
                {i18n.t("Initiative_Outcomes")}
                </label>
                <div className="customform-multidropdown" onClick={()=>isInitiativeOutcomesDropdown()}>
                        <div className="custom-dropdownname">
                          {displayLabelFromselectedValuesinitiativeOutcomesMultiSelect}
                          </div>
                      {isInitiativeOutcomesDropdownStatus ? (

                        <div className="search-select-list">
                          <ul>
                          {_.map(outcomeDropDownData, (eachItem) => {
                          
                          const optionValue = eachItem.value;
                          const isSelected =
                            _.indexOf(tempSelectedValuesinitiativeOutcomesMultiSelect, optionValue) !== -1;
                          const idPrefixText = `initiativeOutcomesinitiatives_${optionValue}`;
                          return (
                            <li
                              id={`${idPrefixText}_li`}
                              key={optionValue}
                            //   onClick={(e) => {
                            //     e.stopPropagation();
                            //     handleClickMultiSelectOption(e, "DDInitiative", optionValue);
                            //   }}
                              className={classNames({
                                active: isSelected,
                              })}
                            >
                              <label className="form-check">
                                <input
                                  id={`${idPrefixText}_checkbox`}
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={isSelected}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleClickMultiSelectOption(e, "initiativeOutcomes", optionValue);
                                  }}
                                />
                                <span className="checkmark"></span>
                                <span className="form-check-label">{eachItem.label}</span>
                              </label>
                            </li>
                          );
                        })}

                          </ul>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                {isInitiativeOutcomesDropdownStatus ? (
                  <div className="custom-dropdown-search-overlay" onClick={()=>isInitiativeOutcomesDropdownOverlay()}></div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="optionselect-notify">({i18n.t("Please_select_top_6_Initiatives")})</div>
          </div>
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="Solution" className="form-label">
                {i18n.t("solution")}
                  <span className="required">&nbsp;*&nbsp;</span> 
                </label>
               
                <input
                  type="text"
                  className="form-control"
                  placeholder="Solution"
                  onChange={(e)=>handleFormChange(e)}
                  value={formData.Solution}
                  name="Solution"
                  maxLength="650"
                />
                <span className="charcount-label">{formData.Solution.length}/{i18n.t("650_Characters")}</span>
              </div>
            </div>
            <div className="errorMsg">{solutionerror}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="DiverseData" className="form-label">
                {i18n.t("Diverse_Data")}
                </label>
                
                <input
                  type="text"
                  className="form-control"
                  placeholder={i18n.t("Diverse_Data")}
                  onChange={(e)=>handleFormChange(e)}
                  value={formData.DiverseData}
                  name="DiverseData"
                  maxLength="350"
                />
                <span className="charcount-label">{formData.DiverseData.length}/{i18n.t("350_Characters")}</span>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="DDInitiative" className="form-label">
                {i18n.t("Diverse_Data_Initiative")}
                </label>
                    <div className="customform-multidropdown" onClick={()=>isInitiativeOutcomesDropdownDiverseData()}>
                        <div className="custom-dropdownname">
                          {displayLabelFromselectedValuesDDInitiativesMultiSelect}
                          </div>
                      {isInitiativeOutcomesDropdownStatusDiverseData ? (

                        <div className="search-select-list">
                          <ul>
                          {_.map(initiativesDropDownData.filter(a=>parseInt(a.SOCategoryID)===5), (eachItem) => {
                          const optionValue = eachItem.value;
                          const isSelected =
                            _.indexOf(tempSelectedValuesDDInitiativesMultiSelect, optionValue) !== -1;
                          const idPrefixText = `DDinitiatives_${optionValue}`;
                          return (
                            <li
                              id={`${idPrefixText}_li`}
                              key={optionValue}
                            //   onClick={(e) => {
                            //     e.stopPropagation();
                            //     handleClickMultiSelectOption(e, "DDInitiative", optionValue);
                            //   }}
                              className={classNames({
                                active: isSelected,
                              })}
                            >
                              <label className="form-check">
                                <input
                                  id={`${idPrefixText}_checkbox`}
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={isSelected}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleClickMultiSelectOption(e, "DDInitiative", optionValue);
                                  }}
                                />
                                <span className="checkmark"></span>
                                <span className="form-check-label">{eachItem.label}</span>
                              </label>
                            </li>
                          );
                        })}

                          </ul>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {isInitiativeOutcomesDropdownStatusDiverseData ? (
                      <div className="custom-dropdown-search-overlay" onClick={()=>isInitiativeOutcomesDropdownOverlayDiverseData()}></div>
                    ) : (
                      ""
                    )}
                  </div>
            </div>
            <div className="optionselect-notify">({i18n.t("Please_select_top_6_Initiatives")})</div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="WO" className="form-label">
                {i18n.t("Work_Orchestration")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={i18n.t("Work_Orchestration")}
                  onChange={(e)=>handleFormChange(e)}
                  value={formData.WorkOrchestration}
                  name="WorkOrchestration"
                  maxLength="350"
                />
                <span className="charcount-label">{formData.WorkOrchestration.length}/{i18n.t("350_Characters")}</span>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="WOInitiative" className="form-label">
                {i18n.t("Work_Orchestration_Initiative")}
                </label>
                <div className="customform-multidropdown" onClick={()=>isInitiativeOutcomesDropdownWO()}>
                <div className="custom-dropdownname">
                          {displayLabelFromselectedValuesWOInitiativesMultiSelect}
                          </div>
                  {isInitiativeOutcomesDropdownStatusWO ? (
                    <div className="search-select-list">
                          <ul>
                          {_.map(initiativesDropDownData.filter(a=>parseInt(a.SOCategoryID)===3), (eachItem) => {
                          const optionValue = eachItem.value;
                          const isSelected =
                            _.indexOf(tempSelectedValuesWOInitiativesMultiSelect, optionValue) !== -1;
                          const idPrefixText = `WOinitiatives_${optionValue}`;
                          return (
                            <li
                              id={`${idPrefixText}_li`}
                              key={optionValue}
                            //   onClick={(e) => {
                            //     e.stopPropagation();
                            //     handleClickMultiSelectOption(e, "DDInitiative", optionValue);
                            //   }}
                              className={classNames({
                                active: isSelected,
                              })}
                            >
                              <label className="form-check">
                                <input
                                  id={`${idPrefixText}_checkbox`}
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={isSelected}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleClickMultiSelectOption(e, "WOInitiative", optionValue);
                                  }}
                                />
                                <span className="checkmark"></span>
                                <span className="form-check-label">{eachItem.label}</span>
                              </label>
                            </li>
                          );
                        })}

                          </ul>
                        </div>
                  ) : (
                    ""
                  )}
                </div>
                {isInitiativeOutcomesDropdownStatusWO ? (
                  <div className="custom-dropdown-search-overlay" onClick={()=>isInitiativeOutcomesDropdownOverlayWO()}></div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="optionselect-notify">({i18n.t("Please_select_top_6_Initiatives")})</div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="HM" className="form-label">
                {i18n.t("Human_Machine")}
                </label>

                <input
                  type="text"
                  className="form-control"
                  placeholder= {i18n.t("Human_Machine")}
                  onChange={(e)=>handleFormChange(e)}
                  value={formData.HumanMachine}
                  name="HumanMachine"
                  maxLength="350"
                />
                <span className="charcount-label">{formData.HumanMachine.length}/{i18n.t("350_Characters")}</span>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="WOInitiative" className="form-label">
                {i18n.t("Human_Machine_Initiative")}
                </label>
                <div className="customform-multidropdown" onClick={()=>isInitiativeOutcomesDropdownHM()}>
                <div className="custom-dropdownname">
                          {displayLabelFromselectedValuesHMInitiativesMultiSelect}
                          </div>
                  {isInitiativeOutcomesDropdownStatusHM ? (
                    <div className="search-select-list">
                          <ul>
                          {_.map(initiativesDropDownData.filter(a=>parseInt(a.SOCategoryID)===2), (eachItem) => {
                          const optionValue = eachItem.value;
                          const isSelected =
                            _.indexOf(tempSelectedValuesHMInitiativesMultiSelect, optionValue) !== -1;
                          const idPrefixText = `HMinitiatives_${optionValue}`;
                          return (
                            <li
                              id={`${idPrefixText}_li`}
                              key={optionValue}
                            //   onClick={(e) => {
                            //     e.stopPropagation();
                            //     handleClickMultiSelectOption(e, "DDInitiative", optionValue);
                            //   }}
                              className={classNames({
                                active: isSelected,
                              })}
                            >
                              <label className="form-check">
                                <input
                                  id={`${idPrefixText}_checkbox`}
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={isSelected}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleClickMultiSelectOption(e, "HMInitiative", optionValue);
                                  }}
                                />
                                <span className="checkmark"></span>
                                <span className="form-check-label">{eachItem.label}</span>
                              </label>
                            </li>
                          );
                        })}

                          </ul>
                        </div>
                  ) : (
                    ""
                  )}
                </div>
                {isInitiativeOutcomesDropdownStatusHM ? (
                  <div className="custom-dropdown-search-overlay" onClick={()=>isInitiativeOutcomesDropdownOverlayHM()}></div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="optionselect-notify">({i18n.t("Please_select_top_6_Initiatives")})</div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="II" className="form-label">
                {i18n.t("Insights_Intelligence")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder= {i18n.t("Insights_Intelligence")}
                  onChange={(e)=>handleFormChange(e)}
                  value={formData.InsightsIntelligence}
                  name="InsightsIntelligence"
                  maxLength="350"
                />
                <span className="charcount-label">{formData.InsightsIntelligence.length}/{i18n.t("350_Characters")}</span>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="WOInitiative" className="form-label">
                {i18n.t("Insights_Intelligence_Initiative")}
                </label>
                <div className="customform-multidropdown" onClick={()=>isInitiativeOutcomesDropdownII()}>
                <div className="custom-dropdownname">
                          {displayLabelFromselectedValuesIIInitiativesMultiSelect}
                          </div>
                  {isInitiativeOutcomesDropdownStatusII ? (
                    <div className="search-select-list">
                          <ul>
                          {_.map(initiativesDropDownData.filter(a=>parseInt(a.SOCategoryID)===4), (eachItem) => {
                          const optionValue = eachItem.value;
                          const isSelected =
                            _.indexOf(tempSelectedValuesIIInitiativesMultiSelect, optionValue) !== -1;
                          const idPrefixText = `IIinitiatives_${optionValue}`;
                          return (
                            <li
                              id={`${idPrefixText}_li`}
                              key={optionValue}
                            //   onClick={(e) => {
                            //     e.stopPropagation();
                            //     handleClickMultiSelectOption(e, "DDInitiative", optionValue);
                            //   }}
                              className={classNames({
                                active: isSelected,
                              })}
                            >
                              <label className="form-check">
                                <input
                                  id={`${idPrefixText}_checkbox`}
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={isSelected}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleClickMultiSelectOption(e, "IIInitiative", optionValue);
                                  }}
                                />
                                <span className="checkmark"></span>
                                <span className="form-check-label">{eachItem.label}</span>
                              </label>
                            </li>
                          );
                        })}

                          </ul>
                        </div>
                  ) : (
                    ""
                  )}
                </div>
                {isInitiativeOutcomesDropdownStatusII ? (
                  <div className="custom-dropdown-search-overlay" onClick={()=>isInitiativeOutcomesDropdownOverlayII()}></div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="optionselect-notify">({i18n.t("Please_select_top_6_Initiatives")})</div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="WhatNext" className="form-label">
                {i18n.t("What_Next")}
                <span className="required">&nbsp;*&nbsp;</span> 
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="What’s Next"
                  onChange={(e)=>handleFormChange(e)}
                  value={formData.WhatNext}
                  name="WhatNext"
                  maxLength="350"
                />
                <span className="charcount-label">{formData.WhatNext.length}/{i18n.t("350_Characters")}</span>
              </div>
            </div>
            <div className="errorMsg">{whatnexterror}</div>
          </div>

        </div>
      </div>
      <div className="createstory-submit">
      {showIOJForm && showConfiguratorUser &&(
        <div className="createstory-btn cancel" style={{right:"129px"}}>
            <button onClick={()=>cancelForm()} className="btn btn-purplebg">
            {i18n.t("Cancel")}
            </button>
          </div>
        )}
        {showIOJForm && showConfiguratorUser &&(
            <button onClick={()=>handleSubmit()} className="btn btn-purplebg" disabled={isEdit && !isChanged}>
            {isEdit?`${i18n.t("Update")}`:`${i18n.t("Submit")}`}
            </button>
            )
        }
      </div>
    </div> }
          {/* Client Story Form End */}
          {/* Client Story Cards Section Start */}

          {!showIOJForm && ClientStoryData.length>0 &&
          <div className="clientstory-cardsection">
            <span className="lateststory-updates">{i18n.t("These_are_the_latest_Stories")}</span>
           
            <div className="clientstory-cardlist">
            {ClientStoryData.map((story,index)=>(
    
              <div key={index} className="clientstory-card">
               
                   <div className="clientstory-cardbody">
                   <img src={BookBlue} />
                   <h6>{story.StoryName}</h6>
                   <p>{i18n.t("Date")}: {(story.CreatedTS).substring(8,10)+"-"+(story.CreatedTS).substring(5,7)+"-"+(story.CreatedTS).substring(0,4)}</p>

                   {story.Version == "1.0" ? <p style={{marginTop:"-8px"}}>{i18n.t("Version")}: V{story.Version}.0</p> : <p style={{marginTop:"-8px"}}>{i18n.t("Version")}: V{story.Version}</p>}
                 </div> 
                <div className="clientstory-cardfooter common-ellipsis-dropdown">
                  <div className="dropdown dropleft">
                    <span type="button" className="dropdown-toggle" data-toggle="dropdown"></span>
                    <div className="dropdown-menu">
                        <a href="javascript:void(0)" className="dropdown-item" onClick={()=>viewClientStoryModalShow(story)}>
                         <span className="eye-icon"></span>{i18n.t("View")}
                       </a>
                      {showConfiguratorUser && (
                          <>
                      <a href="javascript:void(0)" className="dropdown-item" onClick={()=>handleUpdate(story)}>
                        <span className="edit-icon"></span>{i18n.t("Update")}
                      </a>
                        <a href="javascript:void(0)" className="dropdown-item" onClick={()=>deleteDemoModalShow(story.ID)}>
                        <span className="delete-icon"></span>{i18n.t("Delete")}
                      </a>
                      </>
                      )}
                    </div>
                  </div>
                </div>
              </div> 
              ))}
            </div> 
          </div>}
          {deleteDemoStatus && (
          <ClientStoryNotification
         
          isshow={false}
          formdata={[]}
          isdeleteshow={deleteDemoStatus}
          closedeleteNotification={closedeleteNotification}
          deleteStory={deleteStory}
          ClientStoryData={ClientStoryData}
          {...props}
          />
        )
        }
        {viewClientStoryModalStatus && (
          <ClientStoryViewer
          viewClientStoryModalStatus={viewClientStoryModalStatus}
          viewClientStoryModalClose={viewClientStoryModalClose}
          storyviewdata={storyviewdata}
          ClientStoryData={ClientStoryData}
          {...props}
          />
        )
        }
          {/* Client Story Cards Section End */}
        </div>
      </>
    )
}

export default React.memo(IOJClientStory);

