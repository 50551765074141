export let WOtileMasterReducers = (state = {
    tile: []
}, action) => {
    switch (action.type) {
        case "INITIALISEWO_MASTER": {
            state = { ...state }
            state["tile"] = action.payload
         //   state["tile"].sort((a, b) => (a.SOCategorySeq > b.SOCategorySeq) ? 1 : -1)
         state["tile"].sort((a, b) =>a.SOCategorySeq !== b.SOCategorySeq? a.SOCategorySeq > b.SOCategorySeq? 1: -1: a.SOCategoryName.toLowerCase() > b.SOCategoryName.toLowerCase()? 1: -1);  
         state["tile"].map((eachDetails) => {	
                eachDetails.kpiData.sort((a, b) =>	
                a.MetricSeq !== b.MetricSeq ? a.MetricSeq > b.MetricSeq ? 1 : -1 : a.MetricName.toLowerCase() > b.MetricName.toLowerCase() ? 1: -1	
                );	
              });
            return state
        }
        case "ADD_EDIT_TILE_MASTER": {
            
            state = { ...state }
            state["tile"].filter((data) => data.SOCategoryID == action.payload.SOCategoryID).length > 0 ?
                state["tile"].filter((data) => data.SOCategoryID == action.payload.SOCategoryID)[0] = action.payload :
                state["tile"].push(action.payload)
            state["tile"].sort((a, b) =>a.SOCategorySeq !== b.SOCategorySeq? a.SOCategorySeq > b.SOCategorySeq? 1: -1: a.SOCategoryName.toLowerCase() > b.SOCategoryName.toLowerCase()? 1: -1);

            return state
        }
        case "Remove_TILE_MASTER": {
            state = { ...state }
            state["tile"] = state["tile"].filter((data) => data.SOCategoryID != action.payload.SOCategoryID)
     
            return state;
        }
        case "ADD_EDIT_WO_METRIC_MASTER": {
            
            state = { ...state }
            if(state["tile"].filter((x) => x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData.filter((data) => data.KPIMappingID == action.payload.KPIMappingID).length > 0){
                state["tile"].filter((x) => x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData = state["tile"].filter((x) => x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData.filter((data) => data.KPIMappingID != action.payload.KPIMappingID);
            }
            state["tile"].filter((x) => x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData.push(action.payload)

            state["tile"].filter((x) => x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData.sort((a, b) => a.MetricSeq !== b.MetricSeq ? a.MetricSeq > b.MetricSeq ? 1 : -1 : a.MetricName.toLowerCase() > b.MetricName.toLowerCase() ? 1: -1);


            return state
        }
        case "REMOVE_WO_METRIC_MASTER": {
            
            state = { ...state }
            state["tile"].filter((x) => x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData = state["tile"].filter((x) => x.SOCategoryID == action.payload.SOCategoryID)[0].kpiData.filter((data) => data.KPIMappingID != action.payload.kpiData.KPIMappingID)

            return state
        }
        case "REMOVE_WO_TOOL_MASTER": {
            
            state = { ...state }
            state["tile"].filter((x) => x.SOCategoryID == action.payload.SOCategoryID)[0].WOStepTools = state["tile"].filter((x) => x.SOCategoryID == action.payload.SOCategoryID)[0].WOStepTools.filter((data) => data.ToolID != action.payload.WOStepTools.ToolID)

            return state
        }
        default: return state
    }
}
