import React, { Component } from "react";
import { LocalApiBaseUrl } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import _ from "lodash";
import QueryBuilderContainer from "../FormulaBuilder/QueryBuilderContainer";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;



export default class DealConnectorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      connection: false,

      Fields: [],
      userList: [],
      roleList: [],
      clientList: [],
      viewmore: false,
      errors: {},
      rowSelected: [],
      sourceOptions: [],
      DEIDealOptions: [],

      MasterField: {
        MasterValues: [],
        IsMaster: false,
      },

      Connector: {
        DealID: null,
        OrgEntityID: null,
        OfferingID: null,
        LocationID: null,
        ConnectorID: null,
      },
      columnDefs: [
        {
          headerName: "Edit",
          suppressMovable: true,
          width: 60,
          cellRenderer: (params) => {
            var link = document.createElement("span");
            link.className = "tbledit";
            link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
            link.addEventListener("click", (e) => {
              this.dealpopup(e, params.data);
            });
            return link;
          },
          suppressFilter: true,
        },
        {
          headerName: "Delete",
          suppressMovable: true,
          width: 80,
          cellRenderer: (params) => {
            var link = document.createElement("span");
            link.className = "tbldel";
            link.innerHTML = '<i class="fas fa-trash-alt delete-icon-fix"></i>';
            link.addEventListener("click", (e) => {
              this.deleteLocation(e, params.data);
            });
            return link;
          },
          suppressFilter: true,
        },
        { headerName: "Metric Name", field: "MetricName", minWidth: 300, resizable: true, suppressMovable: true },
        {
          headerName: "Metric Description",
          field: "MetricDescription",
          suppressMovable: true,
          minWidth: 300,
          resizable: true,
          autoSizeColumns: true,
        },
        {
          headerName: "Is Active",
          field: "IsActive",
          resizable: false,
          suppressFilter: true,
          suppressSizeToFit: true,
          suppressMovable: true,

          cellRenderer: (params) => {
            return `<label class="form-check">
                    <input class="form-check-input" disabled type="checkbox" ${params.value ? "checked" : ""}>
                    <span class="checkmark"></span>
                     </label>`;
          },
        },
      ],
      rowData: [],
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        rowSelection: "multiple",
        defaultColDef: {
          sortable: true,
        },
      },
    };
    this.onClose = this.onClose.bind(this);
    this.dealpopup = this.dealpopup.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onSourceChange = this.onSourceChange.bind(this);
    //this.onDEIDealChange = this.onDEIDealChange.bind(this);
    this.getMasterValues = this.getMasterValues.bind(this);
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(e) {
    this.state.gridOptions.api.setQuickFilter(document.getElementById("filter-text-box").value);
  }
  dealpopup(e, data) {
    this.setState({ viewmore: false });

    if (this.state.Connector.ConnectorID > 0 && this.state.Connector.DealID > 0) {
      this.getFields();
      // edit condition
      e.preventDefault();
      this.setState({ viewmore: true, rowSelected: data });
    }
  }

  onClose(e) {
    this.setState({ viewmore: false });
  }

  deleteLocation(e, data) {
    if (
      this.props.featureData.filter(
        (data) => String(data.Submenu) === "Locations" && data.Operations.includes("Delete")
      ).length > 0
    ) {
      if (window.confirm("Are you sure you want to delete the item?")) {
        axiosInstance
          .delete(`${LocalApiBaseUrl}QueryBuilder/DealMetricDelete`, {
            params: {
              DealMetricQueryID: data.DealMetricQueryID,
            },
          })
          .then((response) => {
            window.alert(response.data.StatusMessage);
            this.getDealConnector();
          })
          .catch((error) => {
            trycatchAlertPopup(error);
          });
      }
    } else window.alert("Access Denied !!");
  }

  getMasterValues(ConnectorName, FieldName) {
    let MasterValues = this.state.MasterField.MasterValues;
    let IsMaster = this.state.MasterField.IsMaster;

    axiosInstance
      .get(`${LocalApiBaseUrl}QueryBuilder/GetMasterKeyData`, {
        params: {
          ConnectorID: ConnectorName,
          FieldName: FieldName,
        },
      })
      .then((response) => {
        response.data[0].BATMasters.forEach((row) => {
          MasterValues.push(row.MasterValue);
          IsMaster = response.data[0].HasMaster;
        });

        this.setState({
          MasterField: {
            MasterValues: MasterValues,
            IsMaster: IsMaster,
          },
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

    return this.state.MasterField;
  }

  getDealConnector() {
    //shows the data in the main grid

    axiosInstance
      .get(`${LocalApiBaseUrl}QueryBuilder/GetMetricFilterConnectorMaster?`, {
        params: {
          DealID: this.state.Connector.DealID,
          ConnectorID: this.state.Connector.ConnectorID,
        },
      })
      .then((response) => {
        this.setState({
          rowData: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getGetConnectorMaster() {
    // connector dropdown

    let source = [];
    axiosInstance
      .get(`${LocalApiBaseUrl}ConnectorMapping/GetConnectorMaster`)
      .then((response) => {
        source = response.data;
        let sourceValues = source.map((x) => (
          <option key={x.ConnectorID} /*selected={index == 0 ? "" : ""}*/ value={x.ConnectorID}>
            {x.ConnectorName}
          </option>
        ));
        this.setState({ sourceOptions: sourceValues });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  // GetDEIDeal() {
  //   //Deal dropdown

  //   var EnterpriseID = sessionStorage.getItem("EnterpriseID");
  //   if (!_.isNil(EnterpriseID)) {
  //     axiosInstance
  //       .get(`${LocalApiBaseUrl}Deal/GetDeals`, {
  //         params: {FeatureName: "Build Query"},
  //       })

  //       .then((response) => {
  //         this.setState({ DEIDealOptions: response.data.filter((f) => f.IsActive === true) });
  //       })
  //       .catch((error) => {
  //         trycatchAlertPopup(error);
  //       });
  //   }
  // }

  getFields() {
    this.setState({
      Fields: [""],
    });

    axiosInstance
      .get(`${LocalApiBaseUrl}QueryBuilder/GetFieldNamesByConnectorId?`, {
        params: {
          ConnectorId: this.state.Connector.ConnectorID,
        },
      })
      .then((response) => {
        response.data.forEach((row) => {
          this.state.Fields.push(row.FieldName);
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  onSourceChange(event) {
    this.setState({ viewmore: false });
    let Connector = this.state.Connector;
    Connector.ConnectorID = event.target.value;
    this.setState({
      Connector: Connector,
    });
    this.getDealConnector();
  }

  // onDEIDealChange(event) {
  //   let soCatAtt = null;
  //   this.setState({ errors: {} });
  //   this.setState({ viewmore: false });

  //   const Connector = this.state.Connector;
  //   Connector.DealID = event.target.value;
  //   if (this.state.DEIDealOptions.filter((obj) => String(obj.DealID) === String(this.state.Connector.DealID)))
  //     soCatAtt = this.state.DEIDealOptions.filter((obj) => String(obj.DealID) === String(this.state.Connector.DealID));

  //   if (soCatAtt.length > 0) {
  //     this.State({
  //       Connector: {
  //         OrgEntityID: soCatAtt[0].OrgEntityID,
  //         OfferingID: soCatAtt[0].OfferingID,
  //         LocationID: soCatAtt[0].LocationID,
  //       },
  //     });
  //   }

  //   this.setState({
  //     Connector: Connector,
  //   });
  //   this.getDealConnector();
  // }

  componentWillMount() {

    //    this.GetDEIDeal();
    this.getFields();

    this.getDealConnector();
    this.getGetConnectorMaster();
  }

  componentDidMount() {
    let columnDefs = this.state.columnDefs;

    // this.GetDEIDeal();
    this.getFields();

    this.getDealConnector();
    this.getGetConnectorMaster();

    if (
      this.props.featureData.filter(
        (data) => String(data.Submenu) === "Build Query" && data.Operations.includes("Edit")
      ).length === 0
    ) {
      columnDefs = columnDefs.filter((data) => String(data.headerName) !== "Edit");
      this.setState({
        columnDefs: columnDefs,
      });
    }

    if (
      this.props.featureData.filter(
        (data) => String(data.Submenu) === "Build Query" && data.Operations.includes("Delete")
      ).length === 0
    ) {
      columnDefs = columnDefs.filter((data) => String(data.headerName) !== "Delete");
      this.setState({
        columnDefs: columnDefs,
      });
    }
  }

  handleSave(data) {
    axiosInstance
      .post(`${LocalApiBaseUrl}QueryBuilder/PostQueryCreatedMetric`, data)
      .then(
        (response) => {
          window.alert(response.data.StatusMessage);
          this.getDealConnector();
        },
        (error) => {
          console.log(error);
        }
      )
      .catch((error) => {
        trycatchAlertPopup(error);
      });

    this.getDealConnector();
    this.setState({ viewmore: false });
  }
  handleShow = () => {
    this.setState({
      show: true,
    });
  };
  handleClose = () => {
    this.setState({
      show: false,
    });
  };
  showConnection = () => {
    this.setState({
      connection: true,
    });
  };
  closeConnection = () => {
    this.setState({
      connection: false,
    });
  };

  render() {
    const {
      filterDetails,
      featureData,
      selectedClientId
    } = this.props;
    const {
      sourceOptions
    } = this.state;
    //QueryBuilder New Screen

    return (
      <>
        <div className="queryContent mt-3 mb-5">
          <QueryBuilderContainer
            filterDetails={filterDetails}
            featureData={featureData}
            selectedClientId={selectedClientId}
            sourceOptions={sourceOptions}
          />
        </div>
      </>
    );
  }
}
