import React, { Component } from "react";
import _ from "lodash";
import {
  LocalApiBaseUrl,
  getMonthNumber,
  getPrevMonth,
  LocalApiBaseUrlImage,
} from "../Shared/Constant";
import { AgGridReact, Ag } from "ag-grid-react";
import draftToHtml from "draftjs-to-html";
import i18n from "i18next";
import { connect } from "react-redux";
import { store } from "../reducers/configureStore";

import ClientPopup from "../Client/ClientPopup";
import { Modal, Button } from "react-bootstrap";
import axiosInstance from "../Shared/interceptor";
// import { DatePicker } from "@y0c/react-datepicker";
import Moment from "moment";
import $ from "jquery";
import "timepicker/jquery.timepicker.min.js";
import "timepicker/jquery.timepicker.css";
import "bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./messageboard.css";
import {
  faPencilAlt,
  faPlusCircle,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import FrontField from "./FrontField"
import ConfField from "./ConfField";
axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;



class MessageBoard extends Component {
  constructor(props) {
    super(props);
    this.addMessageModalShow = this.addMessageModalShow.bind(this);
    this.addMessageModalClose = this.addMessageModalClose.bind(this);
    this.successNotificationModalShow = this.successNotificationModalShow.bind(this);
    this.successNotificationModalClose = this.successNotificationModalClose.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.edit = i18n.t("Edit");
    this.Delete = i18n.t("Delete");
    this.Header = i18n.t("Title");
    this.Description = i18n.t("Message");
    this.DisplayStartDateTime = i18n.t("Start Date - Time");
    this.DisplayEndDateTime = i18n.t("End Date - Time");
    this.MessageType = i18n.t("Message Type");
    this.isActive = i18n.t("Is Active");

    this.state = {
      userList: [],
      roleList: [],
      clientList: [],
      viewmore: false,
      rowSelected: [],
      isTimeDropdownStatus: false,
      successNotificationStatus: false,
      selectedTime: "7.00 pm",
      messageTypeId: 0,
      isAct: false,
      name: "",
      editorState: EditorState.createEmpty(),
      startDate: null,
      endDate: null,
      startTime: null,
      expireTime: null,
      disableInputBox: false,
      disableStartTime: true,
      disableEndTime: true,
      errors: [],
      formIsValid: false,
      messageSuccessType: "",
      SequenceChangeCondition: false,

      columnDefs: [
        {
          headerName: this.edit,
          suppressMovable: true,
          width: 60,
          cellRenderer: (params) => {
            // if(params.data.Status==="Approved"){
            var link = document.createElement("span");
            link.className = "tbledit";
            link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
            link.addEventListener("click", (e) => {
              this.editMessageModalShow(e, params.data);
            });
            return link;
            // }
          },
          suppressFilter: true,
          resizable: true,
          suppressSizeToFit: true
        },
        {
          headerName: this.Delete,
          suppressMovable: true,
          width: 80,
          cellRenderer: (params) => {
            var link = document.createElement("span");
            link.className = "tbldel";
            link.innerHTML = '<i class="fas fa-trash-alt delete-icon-fix"></i>';
            link.addEventListener("click", (e) => {
              this.deleteNotification(e, params.data);
            });
            return link;
          },
          suppressFilter: true,
          resizable: true,
          suppressSizeToFit: true
        },
        {
          headerName: this.MessageType,
          field: "SynOpsFuncAreaID",
          suppressMovable: true,
          resizable: false,
          suppressFilter: true,
          width: 100,
          cellRenderer: (params) => {
            return `<span>${params.value == 1 ? "Front Door" : params.value == 2 ? "Configurator" : "Both"}</span>`;
          },
          suppressSizeToFit: true
        },
        {
          headerName: this.isActive,
          field: "IsActive",
          resizable: false,
          suppressMovable: true,
          suppressFilter: true,
          width: 80,
          cellRenderer: (params) => {
            return `<span>${params.value ? "Yes" : "No"}</span>`;
          },
          suppressSizeToFit: true
        },
        {
          headerName: this.Header,
          field: "Header",
          suppressMovable: true,
          resizable: false,
          suppressSizeToFit: true
        },
        {
          headerName: this.Description,
          field: "Description",
          suppressMovable: true,
          resizable: false,
          cellRenderer: (params) => {
            var link = document.createElement("span");
            link.innerHTML = params.data.Description;
            return link;
          },
          suppressSizeToFit: true
        },
        {
          headerName: "Frontdoor", field: "FrontSequence",
          suppressFilter: true,
          cellRenderer: 'FrontField',
          suppressSizeToFit: true

        }
        ,
        {
          headerName: "Configurator", field: "ConfSequence",
          cellRenderer: 'ConfField',
          suppressSizeToFit: true,


          suppressFilter: true,
        },
        {
          headerName: this.DisplayStartDateTime,
          field: "ActivatedTime",
          /* suppressMovable: true, */
          /* resizable: false, */
          cellRenderer: (params) => {
            return params.value != null ? `<span>${Moment(params.value).format("MM/DD/YYYY")} - ${Moment(params.value).format('hh:mm A')}</span>` : "";
          },
          suppressSizeToFit: true
        },
        {
          headerName: this.DisplayEndDateTime,
          field: "ExpiryDate",
          /* suppressMovable: true, */
          /* resizable: false, */
          cellRenderer: (params) => {
            return params.value != null ? `<span>${Moment(params.value).format("MM/DD/YYYY")} - ${Moment(params.value).format('hh:mm A')}</span>` : "";
          },
          suppressSizeToFit: true
        },

      ],
      rowData: [],
      frameworkComponents: {
        FrontField: FrontField,
        ConfField: ConfField
      },
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        rowSelection: "multiple",
        defaultColDef: {
          sortable: true,
        },
      },
      confSequence: "",
      frontSequence: ""
    };
    this.onClose = this.onClose.bind(this);
    this.dealpopup = this.dealpopup.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    // this.handleSave = this.handleSave.bind(this);
  }

  handleModelChange(e) {
    // this.setState({
    //   model: model
    // });

  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  deleteNotification(e, data) {
    if (window.confirm(i18n.t("delete_item_confirm"))) {
      axiosInstance
        .delete(
          `${LocalApiBaseUrl}MessageBoard/DisableNotification`,
          {
            params: {
              NotificationID: data.ID,
            },
          }
        )
        .then(
          (response) => {
            window.alert(response.data.StatusMessage);
            this.getAllStaticMessages();
          },
          (error) => {
            trycatchAlertPopup(error)
          }
        );
    }
  }

  editMessageModalShow(e, data) {
    const blocksFromHTML = convertFromHTML(data.Description);
    const content = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    this.setState({
      name: data.Header,
      ID: data.ID,
      editorState: EditorState.createWithContent(content),
      isAct: data.IsActive,
      frontSequence: data.FrontSequence,
      confSequence: data.ConfSequence,
      messageTypeId: data.SynOpsFuncAreaID == null ? 3 : data.SynOpsFuncAreaID,
      endDate: data.ExpiryDate == null ? null : Moment(data.ExpiryDate).format("MM/DD/YYYY"),
      expireTime: data.ExpiryDate == null ? null : Moment(data.ExpiryDate).format('hh:mm A'),
      startDate: data.ActivatedTime == null ? null : Moment(data.ActivatedTime).format("MM/DD/YYYY"),
      startTime: data.ActivatedTime == null ? null : Moment(data.ActivatedTime).format('hh:mm A'),
      disableInputBox: data.IsActive,
      SequenceChangeCondition: false,
    });
    this.setState({ addnotificationStatus: true, EditNotication: true });

    if (this.state.startDate != null) {
      this.setState({ disableStartTime: false })
    }
    if (this.state.endDate != null) {
      this.setState({ disableEndTime: false })
    }
  }

  addMessageModalShow() {
    this.setState({
      editorState: EditorState.createEmpty(),
      isAct: false,
      name: "",
      startDate: null,
      startTime: null,
      endDate: null,
      expireTime: null,
      messageTypeId: 0,
      disableStartTime: true,
      disableEndTime: true,
      confSequence: "",
      frontSequence: "",
      SequenceChangeCondition: false,
    });
    this.setState({
      addnotificationStatus: true,
      EditNotication: false,
      disableInputBox: false,
    });
  }

  addMessageModalClose() {
    this.setState({ addnotificationStatus: false, errors: [] });
  }


  handleSaveNotification() {
    if (this.ValidateForm()) {
      this.saveStaticMessage();
      this.onClose();
    }
  }

  saveStaticMessage() {
    if (this.state.EditNotication) {
      this.UpdateStaticMessage();
    } else {
      this.AddStaticMessage();
    }

  }

  UpdateStaticMessage() {
    let selectedStartDate = new Date(this.state.startDate);
    let selectedEndDate = new Date(this.state.endDate);
    let fullStartDate = (selectedStartDate.getMonth() + 1) + "/" + selectedStartDate.getDate() + "/" + selectedStartDate.getFullYear() + " " +
      (this.state.startTime == 0 ? Moment("12:00 AM", "h:mm:ss A").format("HH:mm:ss") : Moment(this.state.startTime, "h:mm:ss A").format("HH:mm:ss"));
    let fullExpiryDate = (selectedEndDate.getMonth() + 1) + "/" + selectedEndDate.getDate() + "/" + selectedEndDate.getFullYear() + " " +
      (this.state.expireTime == 0 ? Moment("12:00 AM", "h:mm:ss A").format("HH:mm:ss") : Moment(this.state.expireTime, "h:mm:ss A").format("HH:mm:ss"));
    let StartDate = null;
    let EndDate = null;
    let selectedTime = "";
    let newDate = new Date();
    let newFullDate = (newDate.getMonth() + 1) + "/" + newDate.getDate() + "/" + newDate.getFullYear() + " " + Moment(newDate).format('hh:mm A')
    let startTime = this.state.startTime == 0 ? Moment("12:00 AM", "h:mm:ss A").format("HH:mm:ss") : Moment(this.state.startTime, "h:mm:ss A").format("HH:mm:ss");
    let expireTime = this.state.expireTime == 0 ? Moment("12:00 AM", "h:mm:ss A").format("HH:mm:ss") : Moment(this.state.expireTime, "h:mm:ss A").format("HH:mm:ss");
    StartDate = this.state.startDate ? new Date(fullStartDate) : newDate;
    EndDate = new Date(fullExpiryDate);

    setTimeout(() => {
      if ($("#startTimePicker").val() === "0") {
        selectedTime = "12:00 AM";
        $("#startTimePicker").val(selectedTime);
      }
      if ($("#endTimePicker").val() === "0") {
        selectedTime = "12:00 AM";
        $("#endTimePicker").val(selectedTime);
      }
    });

    if (this.state.startTime != null || this.state.expireTime != null) {
      let isValidStartTime = this.state.startTime === null ? true : startTime.match(/(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/)
      let isValidExpireTime = this.state.expireTime === null ? true : expireTime.match(/(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/)
      if (!isValidStartTime) {
        return alert(i18n.t("Please_enter_valid") + " " + i18n.t("Start") + " " + i18n.t("Time"));
      } else if (!isValidExpireTime) {
        return alert(i18n.t("Please_enter_valid") + " " + i18n.t("Expire") + " " + i18n.t("Time"));
      }
    }
    let dd = this.state.startDate != null ? this.state.startDate.split("/") : [];
    let sStartTime = this.state.startTime === 0 ? "12:00 AM" : this.state.startTime;
    let fulldate = dd.length > 0 ? (dd[2] + "/" + dd[0] + "/" + dd[1]) : "";
    let sfulldate = fulldate + " " + sStartTime;

    let sdate = new Date(this.state.startDate);
    let edate = new Date(this.state.endDate)

    if ((this.state.startDate == Moment(new Date()).format("MM/DD/YYYY") && StartDate.getTime() <= new Date().getTime() ||
      new Date(sfulldate) < new Date()) && !this.state.EditNotication) {
      alert(i18n.t("Future/current") + " " + i18n.t("start") + " " + i18n.t("date") + " " + i18n.t("time") + " " + i18n.t("is") + " " + i18n.t("required"));
    } else if (StartDate >= EndDate) {
      alert(i18n.t("Future") + " " + i18n.t("end") + " " + i18n.t("date") + " " + i18n.t("time") + " " + i18n.t("is") + " " + i18n.t("required"));
    } else if (sdate == "Invalid Date" && this.state.startDate != null) {
      alert(i18n.t("Start") + " " + i18n.t("date") + " " + i18n.t("time") + " " + i18n.t("is") + " " + i18n.t("invalid"));
    } else if (edate == "Invalid Date" && this.state.endDate != null) {
      alert(i18n.t("End") + " " + i18n.t("date") + " " + i18n.t("time") + " " + i18n.t("is") + " " + i18n.t("invalid"));
    } else {

      this.setState({ addBtnDisabled: true })
      axiosInstance
        .post(
          `${LocalApiBaseUrl}MessageBoard/UpdateStaticMessages`,
          {
            ID: this.state.ID,
            Header: this.state.name,
            Description: draftToHtml(
              convertToRaw(this.state.editorState.getCurrentContent())
            ),
            IsActive: this.state.isAct,
            ExpiryDate: this.state.endDate == null ? null : fullExpiryDate,
            ActivatedTime: this.state.startDate == null ? newFullDate : fullStartDate,
            SynOpsFuncAreaID: this.state.messageTypeId == 3 ? null : this.state.messageTypeId,
            FrontSequence: this.state.frontSequence != "" ? parseInt(this.state.frontSequence) : 0,
            ConfSequence: this.state.confSequence != "" ? parseInt(this.state.confSequence) : 0,
          },
          {
            params: {
              LanguageID: store.getState().CurrentFilter.languageData.id,
            },
          }
        )
        .then(
          (response) => {
            this.successNotificationModalShow(this.state.messageTypeId)
            this.getAllStaticMessages();
            this.setState({ addBtnDisabled: false })
          },
          (error) => {
            trycatchAlertPopup(error)
            this.setState({ addBtnDisabled: false })
          }
        );
    }
  }

  AddStaticMessage() {
    let selectedStartDate = new Date(this.state.startDate);
    let selectedEndDate = new Date(this.state.endDate);
    let fullStartDate = (selectedStartDate.getMonth() + 1) + "/" + selectedStartDate.getDate() + "/" + selectedStartDate.getFullYear() + " " +
      (this.state.startTime == 0 ? Moment("12:00 AM", "h:mm:ss A").format("HH:mm:ss") : Moment(this.state.startTime, "h:mm:ss A").format("HH:mm:ss"));
    let fullExpiryDate = (selectedEndDate.getMonth() + 1) + "/" + selectedEndDate.getDate() + "/" + selectedEndDate.getFullYear() + " " +
      (this.state.expireTime == 0 ? Moment("12:00 AM", "h:mm:ss A").format("HH:mm:ss") : Moment(this.state.expireTime, "h:mm:ss A").format("HH:mm:ss"));
    let StartDate = null;
    let EndDate = null;
    let selectedTime = "";
    let newDate = new Date();
    let newFullDate = (newDate.getMonth() + 1) + "/" + newDate.getDate() + "/" + newDate.getFullYear() + " " + Moment(newDate, "h:mm:ss A").format("HH:mm:ss")
    let startTime = this.state.startTime == 0 ? Moment("12:00 AM", "h:mm:ss A").format("HH:mm:ss") : Moment(this.state.startTime, "h:mm:ss A").format("HH:mm:ss");
    let expireTime = this.state.expireTime == 0 ? Moment("12:00 AM", "h:mm:ss A").format("HH:mm:ss") : Moment(this.state.expireTime, "h:mm:ss A").format("HH:mm:ss");

    StartDate = this.state.startDate ? new Date(fullStartDate) : newDate;
    EndDate = new Date(fullExpiryDate);
    setTimeout(() => {
      if ($("#startTimePicker").val() === "0") {
        selectedTime = "12:00 AM";
        $("#startTimePicker").val(selectedTime);
      }
      if ($("#endTimePicker").val() === "0") {
        selectedTime = "12:00 AM";
        $("#endTimePicker").val(selectedTime);
      }
    });

    if (this.state.startTime != null || this.state.expireTime != null) {
      let isValidStartTime = this.state.startTime === null ? true : startTime.match(/(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/)
      let isValidExpireTime = this.state.expireTime === null ? true : expireTime.match(/(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/)
      if (!isValidStartTime) {
        return alert(i18n.t("Please_enter_valid") + " " + i18n.t("Start") + " " + i18n.t("Time"));
      } else if (!isValidExpireTime) {
        return alert(i18n.t("Please_enter_valid") + " " + i18n.t("Expire") + " " + i18n.t("Time"));
      }
    }
    let sdate = new Date(this.state.startDate);
    let edate = new Date(this.state.endDate)

    if (this.state.startDate == Moment(new Date()).format("MM/DD/YYYY") && StartDate.getTime() <= new Date().getTime()) {
      alert(i18n.t("Future/current") + " " + i18n.t("start") + " " + i18n.t("date") + " " + i18n.t("time") + " " + i18n.t("is") + " " + i18n.t("required"));
    } else if (StartDate >= EndDate) {
      alert(i18n.t("Future") + " " + i18n.t("end") + " " + i18n.t("date") + " " + i18n.t("time") + " " + i18n.t("is") + " " + i18n.t("required"));
    } else if (sdate == "Invalid Date" && this.state.startDate != null) {
      alert(i18n.t("Start") + " " + i18n.t("date") + " " + i18n.t("time") + " " + i18n.t("is") + " " + i18n.t("invalid"));
    } else if (edate == "Invalid Date" && this.state.endDate != null) {
      alert(i18n.t("End") + " " + i18n.t("date") + " " + i18n.t("time") + " " + i18n.t("is") + " " + i18n.t("invalid"));
    } else {
      this.setState({ addBtnDisabled: true })
      axiosInstance
        .post(
          `${LocalApiBaseUrl}MessageBoard/AddStaticMessages`,
          {
            Header: this.state.name,
            Description: draftToHtml(
              convertToRaw(this.state.editorState.getCurrentContent())
            ),
            IsActive: this.state.isAct,
            ExpiryDate: this.state.endDate == null ? null : fullExpiryDate,
            ActivatedTime: this.state.startDate == null ? newFullDate : fullStartDate,
            SynOpsFuncAreaID: this.state.messageTypeId == 3 ? null : this.state.messageTypeId,
            FrontSequence: this.state.frontSequence != "" ? parseInt(this.state.frontSequence) : 0,
            ConfSequence: this.state.confSequence != "" ? parseInt(this.state.confSequence) : 0,
          },
          {
            params: {
              LanguageID: store.getState().CurrentFilter.languageData.id,
            },
          }
        )
        .then(
          (response) => {
            this.successNotificationModalShow(this.state.messageTypeId)
            this.getAllStaticMessages();
            this.setState({ addBtnDisabled: false })
          },
          (error) => {
            trycatchAlertPopup(error)
            this.setState({ addBtnDisabled: false })
          }
        );
    }
  }

  successNotificationModalShow(messageTypeId) {
    this.setState({
      successNotificationStatus: true,
      messageSuccessType: messageTypeId == 1 ? "Front Door" : messageTypeId == 2 ? "Configurator" : "Both Front Door & Configurator",
      errors: [],
      addnotificationStatus: false
    });
  }

  ValidateForm() {
    let errors = {};
    let formIsValid = true;
    if (!/\S/.test(this.state.name)) {
      formIsValid = false;
      errors["name"] = i18n.t("Title") + " " + i18n.t("is_Required");
    }
    let EditorText = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    if (EditorText.trim() == "<p></p>") {
      formIsValid = false;
      errors["editorState"] = i18n.t("Message") + " " + i18n.t("is_Required");
    }
    if (EditorText.trim().includes("<li></li>")) {
      formIsValid = false;
      errors["editorState"] = i18n.t("Please_enter_message");
    }

    if (this.state.messageTypeId == 0) {
      formIsValid = false;
      errors["messageTypeId"] = i18n.t("Message Type") + " " + i18n.t("is_Required");
    }


    // FOR Conf Sequence and Front Sequence
    let activeRecords = this.state.rowData.filter(each => each.IsActive == true);
    let countRecordsConf = activeRecords.filter(each => each.SynOpsFuncAreaID == null || each.SynOpsFuncAreaID == 2)
    let countRecordsFront = activeRecords.filter(each => each.SynOpsFuncAreaID == null || each.SynOpsFuncAreaID == 1);

    let countConf = countRecordsConf.map(each => each.ConfSequence);
    if ((this.state.confSequence != 0 || this.state.confSequence != "") && !(countRecordsConf.filter(each => each.ID == this.state.ID && each.ConfSequence == this.state.confSequence).length > 0)) {
      countConf.push(this.state.confSequence)
    }


    let countFront = countRecordsFront.map(each => each.FrontSequence)
    if ((this.state.frontSequence != 0 || this.state.frontSequence != "") && !(countRecordsFront.filter(each => each.ID == this.state.ID && each.FrontSequence == this.state.frontSequence).length > 0)) {
      countFront.push(this.state.frontSequence)
    }


    if ((this.state.messageTypeId == 1 || this.state.messageTypeId == 3) && this.state.isAct) {
      if (this.state.frontSequence < 0) {

        formIsValid = false;
        errors["frontSequence"] = "Sequence cannot be Negative";
      }
      if (this.state.frontSequence > 999999 || this.state.frontSequence == 0) {
        formIsValid = false;
        errors["frontSequence"] = "Sequence Value must be in between 1 to 999999"
      }


      if (this.state.frontSequence != 0 && countFront.filter(each => each == this.state.frontSequence).length >= 2) {
        formIsValid = false;
        errors["frontSequence"] = "Sequence already exists";
      }
      if (this.state.frontSequence === "" || this.state.frontSequence === null) {
        formIsValid = false;
        errors["frontSequence"] = "Sequence is Required";
      }
      if (this.state.frontSequence != null && this.state.frontSequence !== "" && parseFloat(this.state.frontSequence) % 1 !== 0) {
        formIsValid = false;
        errors["frontSequence"] = "Sequence Value must be an integer";

      }
    }
    if ((this.state.messageTypeId == 2 || this.state.messageTypeId == 3) && this.state.isAct) {
      if (this.state.confSequence < 0) {
        formIsValid = false;
        errors["confSequence"] = "Sequence cannot be Negative";
      }
      if (this.state.confSequence > 999999 || this.state.confSequence == 0) {
        formIsValid = false;
        errors["confSequence"] = "Sequence Value must be in between 1 to 999999"
      }

      if (this.state.confSequence != 0 && countConf.filter(each => each == this.state.confSequence).length >= 2) {
        formIsValid = false;
        errors["confSequence"] = "Sequence already exists";
      }
      if (this.state.confSequence === "" || this.state.confSequence === null) {
        formIsValid = false;
        errors["confSequence"] = "Sequence is Required";
      }
      if (this.state.confSequence != null && this.state.confSequence !== "" && parseFloat(this.state.confSequence) % 1 !== 0) {
        formIsValid = false;
        errors["confSequence"] = "Sequence Value must be an integer";

      }
    }



    if (formIsValid) {
      this.setState({
        formIsValid: true,
        errors: errors,
      });
    } else {
      this.setState({
        formIsValid: false,
        errors: errors,
      });
    }
    return formIsValid;
  }

  successNotificationModalClose() {
    this.setState({ successNotificationStatus: false, errors: [] });
    this.getAllStaticMessages()
    this.state.gridOptions.api.setFilterModel(null);
    this.state.gridOptions.api.onFilterChanged();

  }

  handleChange = (name, value) => {
    let errors = {};
    let selectedTime = "";
    let dateToday = new Date()
    setTimeout(() => {
      if ($("#startTimePicker").val() === "0") {
        selectedTime = "12:00 AM";
        $("#startTimePicker").val(selectedTime);
      }
      if ($("#endTimePicker").val() === "0") {
        selectedTime = "12:00 AM";
        $("#endTimePicker").val(selectedTime);
      }
    });

    if (name == "startDate") {
      if (value == Moment(new Date()).format("MM/DD/YYYY")) {
        this.setState({
          disableStartTime: false,
          startTime: dateToday.getMinutes() >= 0 && dateToday.getMinutes() <= 14 ? this.handleTimeString(dateToday.getHours(), "15") :
            dateToday.getMinutes() >= 15 && dateToday.getMinutes() <= 29 ? this.handleTimeString(dateToday.getHours(), "30") :
              dateToday.getMinutes() >= 30 && dateToday.getMinutes() <= 44 ? this.handleTimeString(dateToday.getHours(), "45") :
                this.handleTimeString(dateToday.getHours() + 1, "00"),
          [name]: value,
        })
      } else if (value == "") {
        $('.ui-timepicker-wrapper.custom-timepicker.starttime').remove();
        $("#startTimePicker").next().remove()
        $("#startTimePicker").val("");
        $('#start-date-daily').val("");
        $('#startTimePicker').attr({ "class": "", "style": "" })

        this.setState({
          disableStartTime: true,
          startTime: null,
          [name]: null
        })



      } else {
        this.setState({
          disableStartTime: false,
          startTime: 0,
          [name]: value
        })
      }
    } else if (name == "endDate") {
      if (value == "") {
        this.setState({
          disableEndTime: true,
          expireTime: null,
          [name]: null
        })
        $("#endTimePicker").val("");
        $('#end-date-daily').val("");
      } else {
        this.setState({
          disableEndTime: false,
          expireTime: 0,
          [name]: value
        })
      }
    } else {
      if (name === "isAct") {
        if (value === false) {
          this.setState({
            frontSequence: 0,
            confSequence: 0
          })
        }
      }
      this.setState({
        [name]: value,
        errors: errors,
      });
    }
  };

  handleTimeString(hours, minutes) {
    const timeString = hours.toString() + ":" + minutes + ":00"
    const timeString12hr = new Date('1970-01-01T' + timeString + 'Z')
      .toLocaleTimeString({},
        { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }
      );

    return timeString12hr;
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(e) {
    this.state.gridOptions.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  }

  dealpopup(e, data) {
    e.preventDefault();
    this.setState({ viewmore: true, rowSelected: data });
  }

  onClose(e) {
    this.setState({ viewmore: false });
  }

  getAllStaticMessages() {
    let newDate = new Date();
    let newFullDate = newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate() + " " + Moment(newDate).format('hh:mm A')
    let presentDateTime = new Date(newFullDate)

    axiosInstance
      .get(`${LocalApiBaseUrl}MessageBoard/GetAllStaticMessages`, {
        params: {
          LanguageID: store.getState().CurrentFilter.languageData.id,
          presentDateTime: newFullDate
        },
      })
      .then((response) => {
        this.setState({
          rowData: response.data
        });
      });
  }

  componentWillMount() {
    this.getAllStaticMessages();
    let columnDefs = this.state.columnDefs;
    if (
      this.props.featureData.filter(
        (data) => data.Submenu == "Clients" && data.Operations.includes("Edit")
      ).length == 0
    ) {
      columnDefs = columnDefs.filter((data) => data.headerName != "Edit");
      this.setState({
        columnDefs: columnDefs,
      });
    }

    if (
      this.props.featureData.filter(
        (data) =>
          data.Submenu == "Clients" && data.Operations.includes("Delete")
      ).length == 0
    ) {
      columnDefs = columnDefs.filter((data) => data.headerName != "Delete");
      this.setState({
        columnDefs: columnDefs,
      });
    }
  }

  onFocusEventStartDate() {
    let that = this;
    $("#dp3").datepicker({
      startDate: new Date()
    })
    $('#dp3').datepicker().on('changeDate', function (ev) {
      that.handleChange("startDate", $('#start-date-daily').val())

    });
  }

  onFocusEventEndDate() {
    let that = this;
    $("#dp4").datepicker({
      startDate: new Date()
    })
    $('#dp4').datepicker().on('changeDate', function (ev) {
      that.handleChange("endDate", $('#end-date-daily').val())

    });
  }


  onFocusEventStartTime() {
    let selectedTime;
    $('.ui-timepicker-wrapper.custom-timepicker.starttime').remove();
    $("#startTimePicker").timepicker({
      step: 15,
      className: "custom-timepicker starttime",
      listWidth: 0.84,
      appendTo: $("#startTime"),
      timeFormat: 'h:i A',
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      useSelect: true,
    });
    setTimeout(() => {
      if ($("#startTimePicker").val()) {
        $('.starttime .ui-timepicker-list li').each(function () {
          if ($(this).text() === $("#startTimePicker").val()) {
            $(this).addClass('ui-timepicker-selected');
            $('#startTime .starttime .ui-timepicker-selected')[0].scrollIntoView({ behavior: 'smooth' });
          }
          else {
            $(this).removeClass('ui-timepicker-selected')
          }
        });
      }
    }, 100);
    $("#startTimePicker").on("changeTime", (e) => {
      $("#startTimePicker").val(e.target.value);
      this.handleChange("startTime", e.target.value);

      let selectedVal = e.target.value;
      $('.starttime .ui-timepicker-list li').each(function () {
        if ($(this).text() === selectedVal) {
          $(this).addClass('ui-timepicker-selected')
          $('#startTime .starttime .ui-timepicker-selected')[0].scrollIntoView({ behavior: 'smooth' });
        }
        else {
          $(this).removeClass('ui-timepicker-selected')
        }
      });
    });

    setTimeout(() => {
      if ($("#startTimePicker").val() === "0") {
        selectedTime = "12:00 AM";
        $("#startTimePicker").val(selectedTime);
      }
      if ($("#endTimePicker").val() === "0") {
        selectedTime = "12:00 AM";
        $("#endTimePicker").val(selectedTime);
      }
    });
  }

  onFocusEventEndTime() {
    let selectedTime;
    $('.ui-timepicker-wrapper.custom-timepicker.endtime').remove();
    $("#endTimePicker").timepicker({
      step: 15,
      className: "custom-timepicker endtime",
      listWidth: 0.84,
      appendTo: $("#endTime"),
      timeFormat: 'h:i A',
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      useSelect: true,
    });
    setTimeout(() => {
      if ($("#endTimePicker").val()) {
        $('.endtime .ui-timepicker-list li').each(function () {
          // alert($("#timePicker").val())
          if ($(this).text() === $("#endTimePicker").val()) {
            $(this).addClass('ui-timepicker-selected');
            $('#endTime .endtime .ui-timepicker-selected')[0].scrollIntoView({ behavior: 'smooth' });
          }
          else {
            $(this).removeClass('ui-timepicker-selected')
          }
        });
      }
    }, 100);
    $("#endTimePicker").on("changeTime", (e) => {
      $("#endTimePicker").val(e.target.value);
      this.handleChange("expireTime", e.target.value);

      let selectedVal = e.target.value;
      $(".endtime .ui-timepicker-list li").each(function () {
        if ($(this).text() === selectedVal) {
          $(this).addClass('ui-timepicker-selected');
          $('#endTime .endtime .ui-timepicker-selected')[0].scrollIntoView({ behavior: 'smooth' });
        }
        else {
          $(this).removeClass('ui-timepicker-selected')
        }
      });
    });

    setTimeout(() => {
      if ($("#startTimePicker").val() === "0") {
        selectedTime = "12:00 AM";
        $("#startTimePicker").val(selectedTime);
      }
      if ($("#endTimePicker").val() === "0") {
        selectedTime = "12:00 AM";
        $("#endTimePicker").val(selectedTime);
      }
    });
  }
  handleConfSequenceChange = (event) => {
    let value = event.target.value;
    if (this.state.messageTypeId == 2 || this.state.messageTypeId == 3) {

      let errors = this.state.errors;
      errors.confSequence = "";

      this.setState({
        confSequence: value,
        SequenceChangeCondition: true,
        errors,
      })
    }

    else {
      this.setState({
        confSequence: ""
      })
    }
  }
  handleFrontSequenceChange = (event) => {
    let value = event.target.value;
    if (this.state.messageTypeId == 1 || this.state.messageTypeId == 3) {

      let errors = this.state.errors;
      errors.frontSequence = "";
      this.setState({
        frontSequence: value,
        SequenceChangeCondition: true,
        errors,
      })
    }
    else {
      this.setState({
        frontSequence: ""
      })
    }
  }
  onEditorStateChange = (editorState) => {
    let errors = {};
    this.setState({
      editorState,
      errors: errors,
    });
  };

  render() {

    return (
      <>
        <div className="main-data">
          {/* <h3 className="grid-heading">Message Board</h3> */}
          <div className="tableFilter">
            <div className="form-section">
              <div className="form-row messageboard-header">
                <div className="form-group searchFilter">
                  <input
                    type="search"
                    className="form-control"
                    id="filter-text-box"
                    placeholder="Search input value"
                    onChange={(e) => {
                      this.onFilterTextBoxChanged(e);
                    }}
                  />
                </div>
                {this.props.featureData.filter(
                  (data) =>
                    data.Submenu == "Clients" && data.Operations.includes("Add")
                ).length > 0 && (
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={this.addMessageModalShow}
                      >
                        <span>
                          <FontAwesomeIcon icon={faPlusCircle} />
                        </span>{" "}
                        Add New Message
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="ag-theme-balham ag-table">
                <AgGridReact
                  suppressMovable={true}
                  enableSorting={true}
                  enableFilter={true}
                  pagination={true}
                  paginationPageSize={50}
                  floatingFilter={true}
                  gridOptions={this.state.gridOptions}
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                  frameworkComponents={this.state.frameworkComponents}
                /* onGridReady={this.onGridReady} */
                ></AgGridReact>
              </div>
            </div>
          </div>
          {this.state.viewmore ? (
            <ClientPopup
              rowData={this.state.rowData}
              data={this.state.rowSelected}
              show={this.state.viewmore}
              onClose={this.onClose}
              onSave={this.handleSave}
            />
          ) : null}
        </div>
        <Modal
          show={this.state.addnotificationStatus}
          onHide={this.addMessageModalClose}
          className="modal fade addMessageModal"
          size="medium"
          centered
          backdrop={false}
        >
          <Modal.Header closeButton>
            <h4 className="modal-title" id="dealprofile">
              {this.state.EditNotication
                ? "Edit Message"
                : "Add New Message"}
            </h4>
          </Modal.Header>
          <Modal.Body>
            <div className="messageboard-fields">
              <div className="message-typestatus">
                <div className="message-type">
                  <h6>Message Type: <span className="required">*</span></h6>
                  <div className="message-radiobtn">
                    <label className="form-check form-check-inline" for="front-door">
                      <input
                        className="form-check-input" id="front-door" name="messageTypeId"
                        type="radio" value="1" checked={this.state.messageTypeId == 1 ? true : false} onChange={(e) =>
                          this.handleChange(e.target.name, e.target.value)
                        }
                      />
                      <span className="checkmark"></span>
                      <span className="form-check-label">Front Door</span>
                    </label>
                    <label className="form-check form-check-inline" for="configurator">
                      <input
                        className="form-check-input" id="configurator" name="messageTypeId"
                        type="radio" value="2" checked={this.state.messageTypeId == 2 ? true : false} onChange={(e) =>
                          this.handleChange(e.target.name, e.target.value)
                        }
                      />
                      <span className="checkmark"></span>
                      <span className="form-check-label">Configurator</span>
                    </label>
                    <label className="form-check form-check-inline" for="both">
                      <input
                        className="form-check-input" id="both" name="messageTypeId"
                        type="radio" value="3" checked={this.state.messageTypeId == 3 ? true : false} onChange={(e) =>
                          this.handleChange(e.target.name, e.target.value)
                        }
                      />
                      <span className="checkmark"></span>
                      <span className="form-check-label">Both</span>
                    </label>
                  </div>
                </div>
                <div className="message-status">
                  <h6>Message Status:</h6>
                  <div class="toggle-group toggle-tick">
                    <input
                      type="checkbox"
                      name="isAct"
                      defaultChecked={this.state.isAct}
                      id="isActive"
                      onChange={(e) =>
                        this.handleChange(e.target.name, e.target.checked)
                      }
                    />
                    <label htmlFor="isActive">Active Status</label>
                    <div class="onoffswitch" aria-hidden="true">
                      <div class="onoffswitch-label">
                        <div class="onoffswitch-inner"></div>
                        <div class="onoffswitch-switch"></div>
                      </div>
                    </div>
                  </div>
                  <span className="active-status">Active</span>
                </div>
              </div>
              <div>
                <span className="errorMsg">
                  {this.state.errors.messageTypeId}
                </span>
              </div>
              <div className="row">
                <div className="form-group col-lg-12">
                  <label for="Title">
                    Title <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Title"
                    name="name"
                    maxLength="70"
                    value={this.state.name}
                    disabled={this.state.disableInputBox}
                    onChange={(e) =>
                      this.handleChange(e.target.name, e.target.value)
                    }
                  />
                  <span className="errorMsg">{this.state.errors.name}</span>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-12">
                  <label for="message">
                    Message <span className="required">*</span>
                  </label>

                  {/* <div className="description-editor">
                    <textarea
                      maxLength="700"
                      rows="4"
                      className="form-control"
                      id="message"
                      name="editorState"
                      disabled={this.state.disableInputBox}
                      value={this.state.editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onChange={(e) =>
                        this.handleChange(e.target.name, e.target.value)
                      }
                    /> */}
                  <div className={
                    this.state.disableInputBox ? "description-editor  disabled-editor" : "description-editor"
                  }>
                    <Editor
                      readOnly={this.state.disableInputBox}
                      editorState={this.state.editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={this.onEditorStateChange}
                      onTab={() => true}
                      toolbar={{
                        options: ["list"],
                        list: { inDropdown: false, options: ["unordered"] },
                      }}
                    />
                    <span className="errorMsg">
                      {this.state.errors.editorState}
                    </span>
                  </div>

                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-6">
                  <div className="date-fields-wrapper">
                    <label for="Date">Start Date</label>
                    <div className="date-wrapper">
                      <div class="input-append date" id="dp3" data-date="2012-01-02" data-date-format="mm/dd/yyyy">
                        <input type="text" id="start-date-daily" name="startDate" value={this.state.startDate} onFocus={this.onFocusEventStartDate.bind(this)} onChange={(e) => {
                          this.handleChange(e.target.name, e.target.value);
                        }} />
                        <label class="input-group-addon btn" for="start-date-daily">
                          <span class="fal fa-calendar-alt"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="date-fields-wrapper">
                    <label for="Date">End Date</label>
                    <div className="date-wrapper">
                      <div class="input-append date" id="dp4" data-date="2012-01-02" data-date-format="mm/dd/yyyy">
                        <input type="text" id="end-date-daily" name="endDate" value={this.state.endDate} onFocus={this.onFocusEventEndDate.bind(this)} onChange={(e) => {
                          this.handleChange(e.target.name, e.target.value);
                        }} />
                        <label class="input-group-addon btn" for="end-date-daily">
                          <span class="fal fa-calendar-alt"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group col-lg-6">
                  <div className="time-fields-wrapper">
                    <label htmlFor="Time">Start Time</label>
                    <div id="startTime" className="time-picker">
                      <input
                        type="text"
                        id="startTimePicker"
                        name="startTime"
                        onChange={(e) => {
                          this.handleChange(e.target.name, e.target.value);
                        }}
                        value={this.state.startTime == 0 ? "12:00 AM" : this.state.startTime}
                        onFocus={this.onFocusEventStartTime.bind(this)}
                        disabled={this.state.disableStartTime}
                      />
                    </div>
                  </div>
                  <div className="time-fields-wrapper">
                    <label htmlFor="Time">End Time</label>
                    <div id="endTime" className="time-picker">
                      <input
                        type="text"
                        id="endTimePicker"
                        name="expireTime"
                        onChange={(e) => {
                          this.handleChange(e.target.name, e.target.value);
                        }}
                        value={this.state.expireTime == 0 ? "12:00 AM" : this.state.expireTime}
                        onFocus={this.onFocusEventEndTime.bind(this)}
                        disabled={this.state.disableEndTime}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {(this.state.messageTypeId == 1 || this.state.messageTypeId == 3) && this.state.isAct && <div className="form-group col-lg-6">
                  <div className="">
                    <label htmlFor="Time">Front Door Sequence<span className="required">*</span></label>
                    <div >
                      <input
                        type="number"
                        className="form-control"
                        min="1"
                        value={this.state.frontSequence}
                        name="frontSequence"
                        onChange={(e) => { this.handleFrontSequenceChange(e) }}
                      />
                    </div>
                    <span className="errorMsg">{this.state.errors.frontSequence}</span>
                  </div>
                </div>
                }
                {
                  (this.state.messageTypeId == 2 || this.state.messageTypeId == 3) && this.state.isAct &&

                  <div className="form-group col-lg-6">
                    <div className="">
                      <label htmlFor="Time">Configurator Sequence<span className="required">*</span></label>
                      <div >
                        <input
                          type="number"
                          className="form-control"
                          min="1"
                          value={this.state.confSequence}
                          onChange={(e) => { this.handleConfSequenceChange(e) }}
                          name="confSequence"
                        />
                      </div>
                      <span className="errorMsg">{this.state.errors.confSequence}</span>
                    </div>
                  </div>
                }
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-btn messageboard-btns">
              <button
                onClick={this.addMessageModalClose}
                className="btn btn-default btn-sm mr-2"
              >
                Cancel{" "}
              </button>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.handleSaveNotification()}
                disabled={this.state.addBtnDisabled}
              >
                {this.state.EditNotication ? "Update Message" : "Add Message"}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.successNotificationStatus}
          onHide={this.successNotificationModalClose}
          className="successnotification-modal"
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <div className="notification-content">
              <div className="notification-status">
                <span>
                  <i class="far fa-check"></i>
                </span>
              </div>
              <div className="notification-data">
                {this.state.EditNotication ?
                  <div>
                    <h6>
                      Message updated successfully!
                    </h6>
                    <p>
                      Message for {this.state.messageSuccessType} is successfully updated.
                    </p>
                  </div>
                  :
                  <div>
                    <h6>
                      New Message added successfully!
                    </h6>
                    <p>
                      1 new message for {this.state.messageSuccessType} is successfully added.
                    </p>
                  </div>
                }
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Welcome to Message Board Start */}
        {/* <Modal
          show={this.state.addnotificationStatus}
          onHide={this.addMessageModalClose}
          className="modal fade welcomeMessageModal"
          size="medium"
        >
          <Modal.Body>
            <div className="message-board-header">
              <img
                src={LocalApiBaseUrlImage + "/SOImages/812161_SynOps_Gradient-Clover.jpg"}
                alt="Synops-logo"
                width="40"
                height="40"
              />
              <h3>Welcome to the Message Board.</h3>
            </div>
            <div className="message-board-messages">
              <ul>
                <li>
                  <h4>New Release</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et cursus urna, a pellentesque ipsum. Suspendisse potenti. Sed augue turpis, egestas sit amet consectetur a, pulvinar ac nibh.
                  </p>
                </li>
                <li>
                  <h4>New Release</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et cursus urna, a pellentesque ipsum. Suspendisse potenti. Sed augue turpis, egestas sit amet consectetur a, pulvinar ac nibh.
                  </p>
                </li>
              </ul>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-btn message-checkbox">
              <div>
                <label className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                  />
                  <span className="checkmark"></span>
                  <span className="form-check-label">Do not show until new message arrives</span>
                </label>
              </div>
              <button
                className="btn btn-default btn-sm mr-2"
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </Modal> */}
        {/* Welcome to Message Board End */}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    languageData: state.CurrentFilter.languageData,
  };
};
export default connect(mapStateToProps)(MessageBoard);
