import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { LocalApiBaseUrl, trycatchAlertPopup } from '../Shared/Constant';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-blue.css';
import AttributePopUp from './AttributePopUp';
import axiosInstance from '../Shared/interceptor';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(
  'msal.idtoken',
)}`;

export const onFirstDataRendered = (params) => {
  params.api.sizeColumnsToFit();
};
export default class AttributeMasterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowSelected: [],
      applicableForDD: false,
      viewmore: false,
      AttributeOptions: [],
      Attribute: {
        OfferingId: null,
      },

      columnDefs: [
        {
          headerName: 'Edit',
          suppressMovable: true,
          width: 50,
          cellRenderer: (params) => {
            const link = document.createElement('span');
            link.className = 'tbledit';
            link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
            link.addEventListener('click', (e) => {
              this.attributepopup(e, params.data);
            });

            return link;
          },
          suppressFilter: true,
        },
        {
          headerName: 'Delete',
          suppressMovable: true,
          width: 80,
          cellRenderer: (params) => {
            const link = document.createElement('span');
            link.className = 'tbldel';
            link.innerHTML = '<i class="fas fa-trash-alt delete-icon-fix"></i>';
            link.addEventListener('click', (e) => {
              this.deleteAttribute(e, params.data);
            });
            return link;
          },
          suppressFilter: true,
        },

        {
          headerName: 'Attribute Name 1',
          field: 'AttributeName1',
          suppressMovable: true,
          resizable: true,
        },
        {
          headerName: 'Attribute Name 2',
          field: 'AttributeName2',
          suppressMovable: true,
          resizable: true,
        },
        {
          headerName: 'Offering',
          field: 'Offering',
          resizable: true,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: 'Is Active',
          field: 'IsActive',
          resizable: false,
          suppressMovable: true,
          suppressFilter: true,
          suppressSizeToFit: true,
          cellRenderer: (params) => ` <label className="form-check">
                        <input className="form-check-input" type="checkbox" disabled ${
  params.value ? 'checked' : ''
}>
                        <span className="checkmark"></span>
                         </label>`,
          cellStyle() {
            return { 'text-align': 'left' };
          },
        },
      ],
      rowData: [],
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        rowSelection: 'multiple',
        defaultColDef: {
          sortable: true,
        },
      },
    };
    this.onClose = this.onClose.bind(this);
    this.onDEIDealChange = this.onDEIDealChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.attributepopup = this.attributepopup.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    this.handleApplicableForDDChange = this.handleApplicableForDDChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getAttribute();
  }

  componentDidMount() {
    const { featureData } = this.props;
    this.GetDEIDeal();
    this.getAttribute();
    let { columnDefs } = this.state;

    if (
      featureData.filter(
        (data) => String(data.Submenu) === 'Attribute Master' && data.Operations.includes('Edit'),
      ).length === 0
    ) {
      columnDefs = columnDefs.filter((data) => String(data.headerName) !== 'Edit');
    }
    if (
      featureData.filter(
        (data) => String(data.Submenu) === 'Attribute Master' && data.Operations.includes('Delete'),
      ).length === 0
    ) {
      columnDefs = columnDefs.filter((data) => String(data.headerName) !== 'Delete');
    }
    this.setState({
      columnDefs,
    });
  }

  handleApplicableForDDChange(e) {
    const { rowData } = this.state;
    let filteredData = [];

    if (e.target.value === 'ApplicableForDD') {
      filteredData = rowData.filter((x) => x.ApplicableForDD === true);
      this.setState({ applicableForDD: true });
    } else {
      filteredData = rowData.filter((x) => x.ApplicableForDD === false);
      this.setState({ applicableForDD: false });
    }

    this.setState({
      filteredResults: filteredData,
    });
  }

  handleSave(data) {
    if (data) {
      axiosInstance
        .post(`${LocalApiBaseUrl}Attribute/AddEditAttributeCategory`, data)
        .then((response) => {
          window.alert(response.data.StatusMessage);
          this.getAttribute();
        });
    }
    this.getAttribute();
  }

  onDEIDealChange(event) {
    this.setState();
    const { Attribute } = this.state;
    Attribute.OfferingId = event.target.value;
    this.setState({
      Attribute,
    });
    this.getAttribute();
  }

  onClose() {
    this.setState({ viewmore: false });
  }

  onFilterTextBoxChanged() {
    const { gridOptions } = this.state;
    gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
  }

  getAttribute() {
    const { Attribute, applicableForDD } = this.state;

    axiosInstance
      .get(`${LocalApiBaseUrl}Attribute/GetAttributeCategory`, {
        params: { OfferingID: Attribute.OfferingId ? Attribute.OfferingId : 0 },
      })
      .then((response) => {
        const sortedDataFromAPI = response.data.sort((a, b) => (a.UserName > b.UserName ? 1 : -1));
        let filteredData = [];
        if (applicableForDD) {
          filteredData = sortedDataFromAPI.filter((x) => x.ApplicableForDD === true);
        } else {
          filteredData = sortedDataFromAPI.filter((x) => x.ApplicableForDD === false);
        }

        this.setState({
          filteredResults: filteredData,
          rowData: sortedDataFromAPI,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  deleteAttribute(e, data) {
    const { featureData } = this.props;

    if (
      featureData.filter(
        (eachData) => String(eachData.Submenu) === 'Attribute Master' && eachData.Operations.includes('Delete'),
      ).length > 0
    ) {
      if (window.confirm('Are you sure you want to delete the item?')) {
        axiosInstance
          .delete(`${LocalApiBaseUrl}Attribute/AttributeDeleteCategory`, {
            params: {
              AttributeId: data.AttributeId,
            },
          })
          .then((response) => {
            window.alert(response.data.StatusMessage);
            this.getAttribute();
          })
          .catch((error) => {
            trycatchAlertPopup(error);
          });
      }
    } else window.alert(' UnAuthorised Access Denied !!');
  }

  attributepopup(e, data) {
    const { featureData } = this.props;
    if (String(data) !== String(undefined)) {
      // edit condition
      if (
        featureData.filter(
          (eachData) => String(eachData.Submenu) === 'Attribute Master' && eachData.Operations.includes('Edit'),
        ).length > 0
      ) {
        e.preventDefault();
        this.setState({ viewmore: true, rowSelected: data });
        this.getAttribute();
      } else window.alert(' UnAuthorised Access Denied !!');
    } else if (
      featureData.filter(
        (eachData) => String(eachData.Submenu) === 'Attribute Master' && eachData.Operations.includes('Add'),
      ).length > 0
    ) {
      e.preventDefault();
      this.setState({ viewmore: true, rowSelected: data });
      this.getAttribute();
    } else { window.alert(' UnAuthorised Access Denied !!'); }
  }

  GetDEIDeal() {
    const EnterpriseID = sessionStorage.getItem('EnterpriseID');
    if (String(EnterpriseID) !== String(undefined)) {
      axiosInstance
        .get(`${LocalApiBaseUrl}Offering/GetOfferingsList`, {
          params: {
            FeatureName: 'Attribute Master',
            IsCalledForFlatJSONStructure: true,
          },
        })

        .then((response) => {
          const AttributeOptions = [];
          response.data.forEach((row) => {
            row.ChildOfferings.map((SubRow) => AttributeOptions.push(SubRow));
          });

          // show all offerings in the dropdown - whether isRelatorBlock is true or false

          this.setState({ AttributeOptions });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  }

  render() {
    const {
      AttributeOptions,
      applicableForDD,
      Attribute,
      gridOptions,
      columnDefs,
      filteredResults,
      viewmore,
      rowSelected,
    } = this.state;
    const { data, filterDetails } = this.props;
    return (
      <div className="main-data">
        <div className="tableFilter">
          <div className="form-section">
            <div className="form-row attribute-formrow">
              <div className="form-group col-lg-3 mt-10">
                <select
                  id="OperatingGroup"
                  className="form-control"
                  disabled={data}
                  // ref="OperatingGroupDropDown"
                  onChange={this.onDEIDealChange.bind(this)}
                  value={Attribute.OfferingId}
                >
                  <option value="0">Select Offering</option>
                  {AttributeOptions.map((row) => (
                    <option value={row.OfferingID}>
                      {`${row.ParentOfferingName}  -  ${row.Process}`}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group col-lg-6 applicable-radio">
                <label className="form-check mr-4">
                  <input
                    type="radio"
                    value="ApplicableForDD"
                    onChange={this.handleApplicableForDDChange}
                    checked={applicableForDD}
                    name="applicableForDDradioButtons"
                    className="form-check-input"
                  />
                  <span className="checkmark" />
                  <span className="form-check-label">Applicable for Diverse Data&apos;</span>
                </label>
                <label className="form-check mr-4">
                  <input
                    type="radio"
                    value="NotApplicableForDD"
                    onChange={this.handleApplicableForDDChange}
                    checked={!applicableForDD}
                    name="applicableForDDradioButtons"
                    className="form-check-input"
                  />
                  <span className="checkmark" />
                  <span className="form-check-label">
                    Applicable for Business Outcome&apos; &amp; Insights &amp; Intelligence&apos;
                  </span>
                </label>
              </div>
              {/* <div className="form-group col-lg-3 mt-10">
                {rowData.length > 0 ? null : (
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={(e) => {
                      this.attributepopup(e);
                    }}
                  >
                    <span>
                      <FontAwesomeIcon icon={faPlusCircle} />
                    </span>
                    {' '}
                    Add Attribute
                  </button>
                )}
              </div> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            {Attribute.OfferingId > 0 ? (
              <div className="ag-theme-balham ag-table">
                <AgGridReact
                  enableSorting
                  enableFilter
                  pagination
                  paginationPageSize={50}
                  floatingFilter
                  gridOptions={gridOptions}
                  columnDefs={columnDefs}
                  rowData={filteredResults}
                  onFirstDataRendered={this.onFirstDataRendered}
                />
              </div>
            ) : null}
          </div>
        </div>
        {viewmore ? (
          <AttributePopUp
            data={rowSelected}
            OfferingId={Attribute.OfferingId}
            show={viewmore}
            onClose={this.onClose}
            filterDetails={filterDetails}
            onSave={this.handleSave}
          />
        ) : null}
      </div>
    );
  }
}

AttributeMasterList.propTypes = {
  featureData: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  filterDetails: PropTypes.object.isRequired,
};