import React, { Component } from "react";
import BOContainer from "../Configurator/BO";
import HumanMachineTalent from "../Configurator/HumanMachine";
//import WorkOrchestration from '../Configurator/WorkOrchestration';

import InsightsIntelligence from "../Configurator/InsightsIntelligence";


import axiosInstance from "../Shared/interceptor";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import LoadingOverlay from "react-loading-overlay";
import CategoryApprovalList from "./CategoryApprovalList";
import OneTimeSetupApprovalList from "./OneTimeSetupApprovalList";

import ClientRegionApprovalList from "./ClientRegionApprovalList";
import DeliveryRegionApprovalList from "./DeliveryRegionApprovalList";
import ClickableToolApproval from "./ClickableToolApprovalList";
import ToolApprovalList from "./ToolApprovalList";

//import BounceLoader from 'react-spinners/BounceLoader';

import MetricDetail from "../Approval/MetricDetailList";
import WorkOrchestation from "./WorkOrchestationList";
import InsightsAndIntellegence from "./InsightAndIntellegenceList";
import ClientApproval from "./ClientApprovalList";
import IndustryApproval from "./IndustryApprovalList";
import LocationApproval from "./LocationApprovalList";
import OfferingApproval from "./OfferingApprovalList";
import DealApprovalList from "./DealApprovalList";

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;

//const active = false;

export default class MasterContainer extends Component {
  constructor() {
    super();
    this.state = {
      roleData: [],

      featureData: [],
    };

    this.getRoleData = this.getRoleData.bind(this);
  }

  getRoleData() {
    var data = store.getState();
    let roleData1 = data.roleReducers["roleReducers"];
    this.setState({ featureData: roleData1 });

    store.subscribe(() => {
      var data = store.getState();
      this.setState({ roleData: data.roleReducers["roleReducers"] });
      let roleData1 = data.roleReducers["roleReducers"];
      this.setState({ featureData: roleData1 });
    });
  }

  componentWillMount() {
    this.getRoleData();
  }

  componentDidMount() {
    this.getRoleData();
  }
  componentDidUpdate(prevProps){
    const {
      filterDetails,
      detailClicked,
      iiClicked,
      woClicked,
      LocationClicked,
      ClientClicked,
      DealClicked,
      IndustryClicked,
      OfferingClicked,
      ClickableToolClicked,
      CategoryClicked,
      ClientRegionClicked,
      DeliveryRegionClicked,
      ToolClicked,
      OneTimeSetupClicked,
      } = this.props;
    if(
      filterDetails !== prevProps.filterDetails ||
      detailClicked !== prevProps.detailClicked ||
      iiClicked !== prevProps.iiClicked ||
      woClicked !== prevProps.woClicked ||
      LocationClicked !== prevProps.LocationClicked ||
      ClientClicked !== prevProps.ClientClicked ||
      DealClicked !== prevProps.DealClicked ||
      IndustryClicked !== prevProps.IndustryClicked ||
      OfferingClicked !== prevProps.OfferingClicked ||
      ClickableToolClicked !== prevProps.ClickableToolClicked ||
      CategoryClicked !== prevProps.CategoryClicked ||
      ClientRegionClicked !== prevProps.ClientRegionClicked ||
      DeliveryRegionClicked !== prevProps.DeliveryRegionClicked ||
      ToolClicked !== prevProps.ToolClicked ||
      OneTimeSetupClicked !== prevProps.OneTimeSetupClicked 
      ){
      window.scrollTo(0, 0);
    }
  }

  render() {
    var a = this.state;
    var b = this.props.dealClicked;
    return (
      <>
        {
          <>
            {this.props.detailClicked &&
              this.state.featureData.filter((data) => data.Submenu == "Metric" && data.Operations.length != 0).length >
              0 ? (
              <MetricDetail filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.iiClicked &&
              this.state.featureData.filter((data) => data.Submenu == "Metric Group" && data.Operations.length != 0)
                .length > 0 ? (
              <InsightsAndIntellegence filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.woClicked &&
              this.state.featureData.filter(
                (data) => data.Submenu == "Work Orchestration Step" && data.Operations.length != 0
              ).length > 0 ? (
              <WorkOrchestation filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.DealClicked &&
              this.state.featureData.filter((data) => data.Submenu == "Deal Approval" && data.Operations.length != 0)
                .length > 0 ? (
              <DealApprovalList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {/* {this.props.ClientClicked &&
            this.state.featureData.filter((data) => data.Submenu == "Client Approval" && data.Operations.length != 0)
              .length > 0 ? (
              <ClientApproval filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null} */}
            {this.props.IndustryClicked &&
              this.state.featureData.filter((data) => data.Submenu == "Industry Approval" && data.Operations.length != 0)
                .length > 0 ? (
              <IndustryApproval filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {/* {this.props.LocationClicked &&
            this.state.featureData.filter((data) => data.Submenu == "Location Approval" && data.Operations.length != 0)
              .length > 0 ? (
              <LocationApproval filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null} */}
            {this.props.OfferingClicked &&
              this.state.featureData.filter((data) => data.Submenu == "Offering Approval" && data.Operations.length != 0)
                .length > 0 ? (
              <OfferingApproval filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.ClickableToolClicked &&
              this.state.featureData.filter(
                (data) => data.Submenu == "ClickableTool Approval" && data.Operations.length != 0
              ).length > 0 ? (
              <ClickableToolApproval filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.CategoryClicked &&
              this.state.featureData.filter((data) => data.Submenu == "Category Approval" && data.Operations.length != 0)
                .length > 0 ? (
              <CategoryApprovalList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.OneTimeSetupClicked &&
              this.state.featureData.filter(
                (data) => data.Submenu == "OneTimeSetup Approval" && data.Operations.length != 0
              ).length > 0 ? (
              <OneTimeSetupApprovalList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.ToolClicked &&
              this.state.featureData.filter(
                (data) => data.Submenu == "CustomTool Approval" && data.Operations.length != 0
              ).length > 0 ? (
              <ToolApprovalList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}{" "}
            {/* new code */}
            {this.props.ClientRegionClicked &&
              this.state.featureData.filter((data) => data.Submenu == "Client Region" && data.Operations.length != 0)
                .length > 0 ? (
              <ClientRegionApprovalList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.DeliveryRegionClicked &&
              this.state.featureData.filter((data) => data.Submenu == "Delivery Region" && data.Operations.length != 0)
                .length > 0 ? (
              <DeliveryRegionApprovalList
                filterDetails={this.props.filterDetails}
                featureData={this.state.featureData}
              />
            ) : null}
          </>
        }
      </>
    );
  }
}
