import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import { connect } from "react-redux";
import i18n from "i18next";
import {addIIdata} from "../actions/JourneyActions" 
import { LocalApiBaseUrl, getPrevMonth } from '../Shared/Constant';
import HumanMetricDetailsInsight from './HumanMetricDetailsInsight';
import References from "../Shared/References"
import _ from 'lodash';
import "./reference.css";
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import ReactTooltip from "react-tooltip";
import SisenseDashboardIframe from "../Shared/SisenseDashboardIframe";
import { setClientOpriaSisenseDetails } from "../actions/appActions";
import { executeAPIGetClientOpriaSisenseDetailsAndSaveToRedux } from "../Shared/commonFunctions";

axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


const options = {
    loop: true,
    margin: 16,
    responsiveClass: "",
    dots: false,
    responsive: {
        0: {
            items: 1,
            nav: true
        },
        600: {
            items: 2,
            nav: false
        },
        1000: {
            items: 3,
            nav: true,
            loop: false
        },
        1440: {
            items: 4,
            nav: true,
            loop: false
        }
        }
};
class InsightsIntelligence extends Component {
    constructor() {
        super()
        this.OtherProcess = []
        this.Analytics = []
        this.OtherProcessWithCategory = []
        this.AnalyticsWithCategory = []
        this.state = {
            isFD: window.location.pathname === "/Dashboard",
            exploreLinkList: [],
            exploreDrop: "none",
            addReferenceStatus: false,
            AnalyticsAppsAIAdvisorsData: [],
            OtherProcessInsightsData: [],
            AnalyticsmetricDetails: "",
            AnalyticsmetricDetailsCategory: "",
            otherMetricDetailsCategory: "",
            metricDetails: "",
            machineMetricDetails: "",
            new: "",
            pageHeader: "",
            isVisibleSisenseDashboardIframe: false,
            sisenseDashboardData: {},
        }
    }

    getKpiMetrics = (status, SODetails = { SOCategoryID: 0 }, offeringIdparam = { Client: 0, process: 0, Locations: 0, Month: getPrevMonth() }) => {

        let list = [];
        let rowTheList = [];
        let tooltip = `${this.props.filter.SyncFilter.OfferingName} - ${this.props.filter.Common.SubOfferingName}`
        if (SODetails.kpiData != undefined && SODetails.kpiData.length > 0) {
            SODetails.kpiData = SODetails.kpiData.sort((a, b) => (a.MetricSeq !== b.MetricSeq ? ((a.MetricSeq > b.MetricSeq) ? 1 : -1) : (a.MetricName.toLowerCase() > b.MetricName.toLowerCase() ? 1 : -1)))
            SODetails.kpiData.map((data, index) => {
                if (index == 0 && status == "Category")
                    list.push(<h2 className="mb-3">{/* <img src={Project} alt="" /> */}{SODetails.SOCategoryName}</h2>)
                //rowTheList = index % 2 == 0 ? [] : rowTheList;
                rowTheList.push(<HumanMetricDetailsInsight Tab={"Insights"} tooltip={tooltip} isRelatorBlock={this.props.isRelatorBlock} data={data} dealFilter={this.props.filterDetails} />);
                if (rowTheList.length == SODetails.kpiData.length) {
                    return list.push(<div className="custom-tilecard"><div className="tilecard-wrapper"><div className="custom-carousel-padding"><OwlCarousel options={options} className={`owl-carousel`}>{rowTheList}</OwlCarousel><ReactTooltip place="bottom" effect="solid" className="tipcolor" /></div></div></div>)
                } //{rowTheList.map((row) => { return  })}
            })
        }
        else{
            list.push(<div className="outcome-table"><div className="outcome-row"></div></div>);
        }
        return list;
    }



    getInsightsIntelligenceDetails() {
        let{isFD}=this.state
        let parsedResponse = this.props.SODetails;
        let filterClient = this.props.filterDetails.Client;
        let filterProcess = this.props.filterDetails.process;
        let filterLocations = this.props.filterDetails.Locations;
        let filterDate = this.props.filterDetails.Month;
        if (filterProcess != 0 && filterLocations != 0 && filterClient != 0 && filterDate != 0) {
            axiosInstance.get(`${LocalApiBaseUrl}SOFilter/GetInsightsAndIntelligence`, {

                params: { OrgEntityID: filterClient, LocationID: filterLocations, MonthDT: filterDate, OfferingID: filterProcess, LanguageID: parseInt(this.props.languageData.id) }
            })
                .then((response) => {
                    if(isFD){
                        this.props.addIIdataForIOJ(response.data);
                       }

                    let leftCategoryGroup = [];
                    let leftCategoryChilds = response.data.filter((IIDetails) => IIDetails.ParentSOCategoryID === 4);
                    leftCategoryChilds.map((row, index) => {
                        leftCategoryGroup.push(this.getKpiMetrics("Category", row, this.props.filterDetails))
                        this.setState({ AnalyticsmetricDetailsCategory: leftCategoryGroup })
                    });
                    /*
                    let leftCategoryGroup = []
                    let RightCategoryGroup = []
                    let CategoryData = response.data.filter((IIDetails) => IIDetails.ParentSOCategoryID == 4);
                    let leftCategory = CategoryData[0];
                    let RightCategory = CategoryData[1];
                    this.setState({ AnalyticsAppsAIAdvisorsData: leftCategory, OtherProcessInsightsData: RightCategory })

                    if (leftCategory) {
                        
                        this.setState({ AnalyticsmetricDetails: this.getKpiMetrics("", leftCategory, this.props.filterDetails) })


                        let leftCategoryID = leftCategory.SOCategoryID;
                        let leftCategoryChilds = response.data.filter((IIDetails) => IIDetails.ParentSOCategoryID === leftCategoryID);
                        leftCategoryChilds.map((row, index) => {
                            leftCategoryGroup.push(this.getKpiMetrics("Category", row, this.props.filterDetails))
                            this.setState({ AnalyticsmetricDetailsCategory: leftCategoryGroup })
                        })
                    }
                    if (RightCategory) {
                        
                        this.setState({ otherMetricDetails: this.getKpiMetrics("", RightCategory, this.props.filterDetails) })

                        let RightCategoryID = RightCategory.SOCategoryID;
                        let RightCategoryChilds = response.data.filter((IIDetails) => IIDetails.ParentSOCategoryID === RightCategoryID);

                        RightCategoryChilds.map((row, index) => {
                            RightCategoryGroup.push(this.getKpiMetrics("Category", row, this.props.filterDetails))
                            this.setState({ otherMetricDetailsCategory: RightCategoryGroup })

                        })
                    }*/
                })
                .catch((error) => {
                    trycatchAlertPopup(error);
                  });
          
          
              
                
        }
    }

    componentWillMount() {
        this.setState({ pageHeader: this.props.headerLabel });
        this.getInsightsIntelligenceDetails();
        this.fetchClientOpriaSisenseDetails();
    }

    componentDidUpdate(prevProps) {
        if (!_.isEmpty(this.props.headerLabel) && !_.isNil(prevProps.headerLabel) && this.props.headerLabel != prevProps.headerLabel) {
            let pageHeader = this.props.headerLabel;
            this.setState({ pageHeader });
        }
    }
    componentDidMount(){

    }


    viewMore() {
       
        let filterClient = this.props.filterDetails.Client;
        let filterProcess = this.props.filterDetails.process;
        let filterLocations = this.props.filterDetails.Locations;
        //let SOCategoryID = this.props.SODetails[0].SOCategoryID;
        let SOCategoryID = 4



        let displayStatus = this.state.exploreDrop;
        if (displayStatus == "none") {
           
            axiosInstance
            .get(`${LocalApiBaseUrl}ClickableTools/GetExploreTools`, {
              params: {
                OrgEntityID:filterClient,
            OfferingID:filterProcess,
            LocationID:filterLocations,
            SOCategoryID: SOCategoryID,
            IsAllTool:false,
              },
            })
            .then((res) => {

              let exploreLinkList = res.data.map((eachLinks) => {
                return (
                  <a
                    className={ eachLinks.URL ?"dropdown-item":"dropdown-item explore_tools_noCursor"}
                    href={
                        eachLinks.URL? (eachLinks.URL !== "NA"&&eachLinks.URL !== "https://"?eachLinks.URL:eachLinks.URL.substr):"javascript:void(0)"
                    }
                    target={eachLinks.URL && "_blank"}
                  >
                    {eachLinks.ApplicationName}
                  </a>
                );
              });

              this.setState({ exploreLinkList,exploreDrop1: res.data.length==0?"No tools available":"", });
              this.onClose();
                        })
                        .catch((error)=>{
                            trycatchAlertPopup(error)});      
        }
        else {
            this.setState({ exploreDrop: "none" });
        }
    }

    fetchClientOpriaSisenseDetails = () => {
        const { clientData, syncFilterData, reduxSetClientOpriaSisenseDetails } = this.props;
        // IF SELECTED CLIENT ID IS NOT EMPTY THEN EXECUTE THE API TO GET THE CLIENT DETAILS AND SAVE THE DATA IN THE REDUX STORE
        const data = {
            clientId: clientData.id,
            offeringName: syncFilterData.OfferingName,
        };
        executeAPIGetClientOpriaSisenseDetailsAndSaveToRedux(data, reduxSetClientOpriaSisenseDetails);
    }

    handleClickOpsInsightBtn = () => {
        const { clientOpriaSisenseDetailsFromRedux, clientData, syncFilterData } = this.props;
        let opriaSisenseDashboardURL = _.get(clientOpriaSisenseDetailsFromRedux, "DashboardURL", "");

        this.setState({
            isVisibleSisenseDashboardIframe: true,
            sisenseDashboardData: {
                clientName: clientData.name,
                offeringName: syncFilterData.OfferingName,
                dashboardURL: opriaSisenseDashboardURL,
            },
        });
    }

    handleCloseSisenseDashboardIframe = () => {
        this.setState({
            isVisibleSisenseDashboardIframe: false,
            sisenseDashboardData: {},
        });
    }

    render() {
        const { clientOpriaSisenseDetailsFromRedux } = this.props;

        const { referencesExist,adminReferences, exploreLinkList, isVisibleSisenseDashboardIframe, sisenseDashboardData } = this.state;

        return (
            <>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-title clearfix references-dropdown dashboard-references-dropdown">
                            <div className="dropdown">
                            <a  className="dropdown-toggle"
                                    data-toggle="dropdown"
                                    onClick={() => { this.viewMore() }}>
                                    <span className="exploreTools">
                                    {/* <i class="fal fa-info-circle"></i> */}{i18n.t("Explore_Tools_Advisor")}
                                    <span>{i18n.t("Click on Explore Tools/Advisor to view Tools")}</span>
                                    </span>
                                <span className="caret"></span>
                                </a>

                                <ul className="dropdown-menu explore_tools_root explore-tools-menu dropdown-menu-right custom-dropdown-menu">
                                {exploreLinkList.length > 0 ?
                                    this.state.exploreLinkList:(<div><p>
                                    {this.state.exploreDrop1}
                                    </p></div>)}

                                </ul>

                            </div>
                            <div className="category-references dashboard-category-references">
                            {<References 
                            {...this.props} 
                            SOCatID={4}
                            leverName="Insights & Intelligence"
                            referenceScreen="II"
                            />
                        }
                        </div>
                  </div>
                    </div>
                    <div className="col-lg-12">
                    <div className="ops-insightsbtn">
                    {!_.isEmpty(_.get(clientOpriaSisenseDetailsFromRedux, "DashboardURL", "")) &&
                            _.get(clientOpriaSisenseDetailsFromRedux, "IsActive", false) && (
                                <button
                                    onClick={() => this.handleClickOpsInsightBtn()}
                                    className="btn btn-gradient-common mr-4"
                                >
                                    Ops Insights
                                </button>
                            )}
                            {isVisibleSisenseDashboardIframe && (
                                <SisenseDashboardIframe
                                    data={sisenseDashboardData}
                                    onClose={() => this.handleCloseSisenseDashboardIframe()}
                                />
                            )}
                    </div>
                    </div>
                       <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        {this.state.AnalyticsAppsAIAdvisorsData ?
                            <>
                                <div className="outcome-highlight insightsNew tilecard-new iiwidth no-whiteShadow no-table-layout">
                                    <h1 className="mb-3">{this.props.headerLabel}
                                        {/*this.state.AnalyticsAppsAIAdvisorsData.SOCategoryName*/}</h1>
                                    {this.state.AnalyticsmetricDetails}

                                </div>
                                <>
                                    {this.state.AnalyticsmetricDetailsCategory.length != 0 ? (
                                        this.state.AnalyticsmetricDetailsCategory.map((data) => {
                                            return <div className="outcome-highlight insightsNew tilecard-new iiwidth no-whiteShadow no-table-layout">{data}</div>;
                                        })
                                    ) : this.state.AnalyticsmetricDetailsCategory
                                    }
                                </>
                            </> : <></>}
                        {/*{this.state.OtherProcessInsightsData ?
                            <div className="outcome-highlight insightsNew no-whiteShadow no-table-layout">
                                <h1>{this.state.OtherProcessInsightsData.SOCategoryName}</h1>
                                {this.state.otherMetricDetails}
                                {this.state.otherMetricDetailsCategory.length != 0 ? (
                                    this.state.otherMetricDetailsCategory.map((data) => {
                                        return data
                                    })
                                ) : this.state.otherMetricDetailsCategory
                                }
                            </div>
                            : null}*/}
                    </div>
                </div>
             </>
        )
    }
}
const mapStateToProps = (state) => {
    return ({
        languageData: state.CurrentFilter.languageData,
        clientOpriaSisenseDetailsFromRedux: state.app.clientOpriaSisenseDetails,
        clientData: state.CurrentFilter.ClientData,
        syncFilterData: state.CurrentFilter.SyncFilter,
        filter: state.CurrentFilter
    })
}
const mapDispatchToProps = (dispatch) => {
    return {
      addIIdataForIOJ: (payload) => dispatch(addIIdata(payload)),
      reduxSetClientOpriaSisenseDetails: (clientOpriaSisenseDetails) => dispatch(setClientOpriaSisenseDetails(clientOpriaSisenseDetails)),
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(InsightsIntelligence);
