import React, { Component } from 'react';
import axiosInstance from '../Shared/interceptor';
import { store } from "../reducers/configureStore";

//import BounceLoader from 'react-spinners/BounceLoader';

import GOCForm from './GOCForm';

axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;

//const active = false;

export default class GOCList extends Component {

    constructor() {
        super();
        this.state = {
            roleData: [],

        featureData: [],

        }

    this.getRoleData = this.getRoleData.bind(this);

}
getRoleData() {
    
    var data = store.getState();
    let roleData1 = data.roleReducers["roleReducers"];
    store.subscribe(() => {
     
        var data = store.getState();
        this.setState({ roleData: data.roleReducers["roleReducers"] })
        let featureDetails1 = data.roleReducers["roleReducers"];
        //let featureData = featureDetails1.map(function (el) { return el.FeatureName; });
        this.setState({ featureData: featureDetails1 })
      
     
    })
}

    componentWillMount() {
        this.getRoleData();

    }



    componentDidMount() {
        this.getRoleData();

    }

    render() {
        
        return <>
            {
                <>
                   
                    {this.props.isGOCClicked && this.state.featureData.filter((data) => data.Operations.includes("View")).length>0? <GOCForm filterDetails={this.props.filterDetails} /> : null}
                    {/* {this.props.isGOCClicked? <GOCForm filterDetails={this.props.filterDetails} /> : null} */}
                 
                </>
            }
        </>

    }
}