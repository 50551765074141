import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { LocalApiBaseUrl } from '../Shared/Constant';
import ClientPopup from './ClientPopup';
import axiosInstance from '../Shared/interceptor';
import { store } from '../reducers/configureStore';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('msal.idtoken')}`;


export const removeDuplicates = (arr) => { /* NEW CODE WHICH REMOVES DUPLICATES DATA FROM API */
  const map = new Map();
  arr.forEach((v) => map.set(v.ClientName, v));
  return [...map.values()];
};
export const onFirstDataRendered = (params) => {
  params.api.sizeColumnsToFit();
};

export default class ClientList extends Component {
  constructor(props) {
    super(props);
    this.edit = i18n.t('Edit');
    this.Delete = i18n.t('Delete');
    this.ClientName = i18n.t('Client_Name');
    this.Status = i18n.t('Status');
    this.isActive = i18n.t('Is_Active');

    this.state = {
      viewmore: false,
      rowSelected: [],

      columnDefs: [

        {
          headerName: 'Client Name', field: 'ClientName', suppressMovable: true, resizable: false,
        },

      ],
      rowData: [],
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        rowSelection: 'multiple',
        defaultColDef: {
          sortable: true,
        },
      },
    };
    this.onClose = this.onClose.bind(this);
    this.dealpopup = this.dealpopup.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    this.handleSave = this.handleSave.bind(this);

    this.onBtnExport = this.onBtnExport.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getClients();
    let { columnDefs } = this.state;
    const { featureData } = this.props;
    if (featureData.filter((data) => String(data.Submenu) === 'Clients' && data.Operations.includes('Edit')).length === 0) {
      columnDefs = columnDefs.filter((data) => String(data.headerName) !== 'Edit');
      this.setState({
        columnDefs,
      });
    }

    if (featureData.filter((data) => String(data.Submenu) === 'Clients' && data.Operations.includes('Delete')).length === 0) {
      columnDefs = columnDefs.filter((data) => String(data.headerName) !== 'Delete');
      this.setState({
        columnDefs,
      });
    }
  }

  handleSave(data) {
    // the client state from clientPopup is taken as a parameter.
    //  if approval task has to be achieved then replace this api with approval api and the data trnsfered form here should be received from GET api in client approval.
    if (data != null) {
      const data1 = data;
      data1.CreatedTS = new Date();
      data1.UpdatedTS = new Date();
      data1.CreatedUser = 'admin';
      data1.UpdatedUser = 'admin';

      axiosInstance.post(`${LocalApiBaseUrl}Client/AddEditClient`, data, {
        params: {
          LanguageID: store.getState().CurrentFilter.languageData.id,
        },
      })
        .then((response) => {
          window.alert(response.data.StatusMessage);
          this.getClients();
        });
    }
  }

  onBtnExport() {
    const params = {

      columnKeys: ['ClientName'],
      columnGroups: true,
      allColumns: false,
      skipHeader: false,
      fileName: 'ClientList',

    };
    const { gridOptions: gridOptions1 } = this.state;
    gridOptions1.api.exportDataAsCsv(params);
  }

  onClose() {
    this.setState({ viewmore: false });
  }

  onFilterTextBoxChanged() {
    const { gridOptions: gridOptions1 } = this.state;
    gridOptions1.api.setQuickFilter(document.getElementById('filter-text-box').value);
  }

  getClients() {
    // This dummy api will fill the contents of the grid.
    axiosInstance.get(`${LocalApiBaseUrl}DealOnboarding/GetMasterDataClient`, {
      params: {
      },
    })
      .then((response) => {
        const rowData = removeDuplicates(response.data);
        this.setState({
          rowData,
        });
      });
  }

  deleteLocation(e, data) {
    const { featureData } = this.props;
    if (featureData.filter((eachdata) => String(eachdata.Submenu) === 'Clients' && eachdata.Operations.includes('Delete')).length > 0) {
      if (window.confirm(i18n.t('delete_item_confirm'))) {
        axiosInstance.delete(`${LocalApiBaseUrl}Client/DeleteClient`, {
          params: {
            ClientID: data.OrgEntityID,

          },
        }).then((response) => {
          window.alert(response.data.StatusMessage);
          this.getClients();
        });
      }
    } else window.alert('Access Denied !!');
  }

  dealpopup(e, data) {
    e.preventDefault();
    this.setState({ viewmore: true, rowSelected: data });
  }

  render() {
    const {
      gridOptions, columnDefs, rowData, viewmore, rowSelected,
    } = this.state;
    const { featureData } = this.props;
    return (
      <div className="main-data">
        <div className="tableFilter">
          <div className="form-section">
            <div className="form-row">
              <div className="form-group col-lg-3">
                <input type="text" className="form-control" id="filter-text-box" placeholder={`${i18n.t('Filter')}...`} onChange={(e) => { this.onFilterTextBoxChanged(e); }} />
              </div>
              <div className="ml-auto mr-1">
                {featureData.filter((data) => String(data.Submenu) === 'Clients' && data.Operations.includes('Download')).length > 0
                  ? (
                    <span className="btn btn-primary btn-sm py-1" onClick={this.onBtnExport} role="button" tabIndex="0">
                      <FontAwesomeIcon icon={faFileDownload} className="mr-1" />
                      Download
                    </span>
                  ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ag-theme-balham ag-table">
              <AgGridReact
                enableSorting
                enableFilter
                pagination
                paginationPageSize={50}
                floatingFilter
                gridOptions={gridOptions}
                columnDefs={columnDefs}
                rowData={rowData}
                onFirstDataRendered={onFirstDataRendered}
              />
            </div>
          </div>
        </div>
        {viewmore ? <ClientPopup rowData={rowData} data={rowSelected} show={viewmore} onClose={this.onClose} onSave={this.handleSave} /> : null}
      </div>
    );
  }
}
ClientList.defaultProps = {
  featureData: {},
};
ClientList.propTypes = {
  featureData: PropTypes.object,
};
