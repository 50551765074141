import React from "react";
import i18n from "i18next";
import TrendGraphHnMJourney from "../Analytics/TrendGraphHnMJourney";

class HMJourneyMetricView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewGraph: false,
      viewAnalytics: false,
      commentPopUp: false,
      eachDetails: this.props.data,
      filterDetails: this.props.filterDetails,
      KpiID: 0
    };
  }

  handleGraph = (val, Id, e) => {
    if (val === 1) {
      e.preventDefault(Id, e);
      this.setState({
        KpiID: Id,
        viewGraph: true
      })
    }
    else {
      this.setState({
        KpiID: 0,
        viewGraph: false
      })
    }
  }

  onClose = (e) => {
    this.setState({
      viewGraph: false,
      viewAnalytics: false,
      kpiMapping: 0,
      name: "",
    });
  };
  onCommentClick = (val) => {
    if (val === 1) {
      this.setState({
        commentPopUp: true,
      });
    } else {
      this.setState({
        commentPopUp: false,
      });
    }
  };
  render() {
    let eachDetails = this.props.data;
    let AttrValue = "";
    let metricName = "";
    let unit = "";
    let metricData = "";
    let leadingIndustryValue = "";
    let similarIndustryValue = "";
    let Description = "";
    let ValueBlock = true;
    let RelatorBlock = this.props.isRelatorBlock;
    let NotificationBlock = false;
    let ToolTip = "";
    let info = " test test test test test";

    metricName = eachDetails.MetricName;
    unit = eachDetails.MetricUnit;
    let unit_En = eachDetails.MetricUnit_En;

    if (eachDetails.metDataAttr != null) {
      if (eachDetails.metDataAttr.length > 0) {
        // if (eachDetails.metDataAttr[0].AttributeName === "Leading") {
        leadingIndustryValue = eachDetails.metDataAttr[0] ? eachDetails.metDataAttr[0].AttributeValue : "";
        // } else {
        //   leadingIndustryValue = 0;
        // }
        // if (eachDetails.metDataAttr[1].AttributeName === "Similar") {
        similarIndustryValue = eachDetails.metDataAttr[1] ? eachDetails.metDataAttr[1].AttributeValue : "";
        // } else {
        //   similarIndustryValue = 0;
        // }
      }
      // else {
      //   RelatorBlock = false;
      // }
    }
    info = eachDetails.Comments ? eachDetails.Comments : "NA";
    let mappingId = eachDetails.KPIMappingID;
    let statusColor = "gray-clr";
    if (eachDetails != undefined) {
      ToolTip =
        i18n.t("Lower_Threshold") +
        ":" +
        (eachDetails.LowerThreshold || "NA") +
        " ," +
        i18n.t("Upper_Threshold") + +
        ":" +
        (eachDetails.UpperThreshold || "NA");

      metricData = eachDetails.MetricValue;

      /* Description =
        eachDetails.MetricDataDesp === ""
          ? eachDetails.KPIDesp
          : eachDetails.MetricDataDesp; */
      Description =
        eachDetails.KPIDesp
          ? eachDetails.KPIDesp
          : "";
      if (eachDetails.RAGStatus != undefined && eachDetails.RAGStatus != null) {
        switch (eachDetails.RAGStatus.toLowerCase()) {
          case "grey":
            statusColor = "gray-clr";
            break;
          case "red":
            statusColor = "red-clr";
            break;
          case "orange":
            statusColor = "orange-clr";
            break;
          case "green":
            statusColor = "";
            break;
          case "amber":
            statusColor = "orange-clr";
            break;
          default:
          // code block
        }
      }
    }

    return (
      <>
        <div
          className={`no-whiteShadow item outcome-divider ${statusColor}`}
          data-place="bottom"
          data-tip={ToolTip}
        >
          <div className="outcome-indicate">
            <p className="minht">{metricName}</p>
            <div className="outcome-bottom">
              {Description ? (
                <span className="metric-details">{Description}</span>
              ) : (
                  <span className="metric-details">&nbsp;</span>
                )}
              <div class="d-flex lag-sim align-items-end">
                <span className="metric-digit">
                  {`${Number.isInteger(Number(metricData))
                    ? metricData
                    : Number(metricData).toFixed(2)
                    }`}
                  {/* {unit != "%" ? (
                  <span className="metric-digit-text">&nbsp;{unit}</span>
                ) : (
                  unit
                )} */}

                  {unit_En != "%" ? (
                    <span className="metric-digit-text">&nbsp;{unit}</span>
                  ) : (
                      unit
                    )}
                </span>
                {eachDetails.SOCategoryID == 1697 ?
                  <div className="card-overlay">
                    <div className="d-flex">
                      <button
                        type="button"
                        className="card-chart"
                        onClick={(e) =>
                          this.handleGraph(1, mappingId, e)
                        }
                      >
                        <span>{i18n.t("INSIGHTS")}</span>
                      </button>
                    </div>
                  </div>
                  : null
                }
                {RelatorBlock ? (
                  <>
                    <span className="parent-metric-details">
                      {eachDetails.metDataAttr.map(
                        (arrayElt) =>
                          arrayElt.AttributeValue &&
                          arrayElt.AttributeValue !== null &&
                          arrayElt.AttributeValue.toUpperCase() !== "NA" && (
                            <span className="metric-details">
                              {`${arrayElt.AttributeName}: ` +
                                `${arrayElt.AttributeValue}`}
                            </span>
                          )
                      )}
                    </span>
                  </>
                ) : null}
              </div>
              {/* {ArrowBlock?<><span><FontAwesomeIcon icon={faArrowDown} /></span></>:null} */}
            </div>
          </div>
        </div>
        {this.state.KpiID === mappingId ? (
          <TrendGraphHnMJourney
            show={true}
            onClose={() => this.handleGraph(2, mappingId)}
            kpiMappingId={mappingId}
            kpiName={metricName}
            kpiUnit={unit}
          />
        ) : null}
      </>
    );
  }
}
export default HMJourneyMetricView;
