import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  LocalApiBaseUrl,
  getMonthNumber,
  getPrevMonth,
} from "../Shared/Constant";
import MORDocViewer from "./MORDocViewer";
import { Modal, Button } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { AgGridReact, Ag } from "ag-grid-react";
import i18n from "i18next";
import CommentsPopOver from "./CommentsPopover";
import MORCommentsHistory from "./MORCommentsHistory";
import _ from "lodash";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-blue.css";
import { store } from "../reducers/configureStore";

import axiosInstance from "../Shared/interceptor";
import MORcard from "../Images/morcard.jpg";
import MORXlsx from "../Images/MORxlsx.svg";
import MORDocx from "../Images/MORdocx.svg";
import MORDocument from "../Images/folder-icon.svg";
import MORMp4 from "../Images/MORmp4.svg";
import EllipsesWhite from "../Images/EllipsesWhite.svg";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Overlay, Popover, OverlayTrigger } from "react-bootstrap";
import Success from "../Images/Success.svg";
import MorEye from "../Images/morcard-eye.svg";
import MorfileImage from "../Images/morfile-image.svg";
import MorfilePPT from "../Images/morfile-ppt.svg";
import MorfilePDF from "../Images/morfile-pdf.svg";
import MorFavourite from "../Images/morfavourite.svg";
import MorFavouriteActive from "../Images/morfavourite-active.svg";
import Client from "../Images/client-0.svg";
import ReactTooltip from "react-tooltip";
import "./MOR.css";
import SubOfferingDropdowns from '../Shared/SubOfferingDropdowns';
import { filterSelectedSubOffering, isValidEmbedLink, validateIsAllowedDomainFromURL, fetchAllowedDomains } from "../Shared/commonFunctions";
import SynopsLoader from "../Shared/SynopsLoader";
import extractUrls from 'extract-urls';

axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


const allmonth = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function AddFileFolderPopup({
  DocDetails,
  Document,
  categoriesprops,
  offeringValuesprops,
  createnewModalStatus,
  isFolderAdd,
  closePopup,
  monthList,
  handleSavePopup,
  OrgEntityID,
  typePopup,
  ParentFolderID,
  MORFolderList,
  MORItemList,
  EditData,
  ErrorMessage,
  makeErrorMessageEmpty
}) {
  const [FolderDetails, setFolderDetails] = useState({
    FolderId: 0,
    FolderName: "",
  });
  const [ItemDetails, setItemDetails] = useState({
    ItemId: 0,
    ItemName: "",
    ItemLink: "",
  });

  const [errors, seterrors] = useState({});
  const [selectedMonth, setselectedMonth] = useState("Select Month");

  const [selectedOfferingName, setselectedOfferingName] = useState(
    "Select Offering"
  );
  const [selectedSubOfferingID, setselectedSubOfferingID] = useState(0);
  const [selectedSubOfferingName, setselectedSubOfferingName] = useState(
    "Select Sub Offering"
  );
  const [selectedcategoriesList, setselectedcategoriesList] = useState([]);
  const [selectedYearMonth, setselectedYearMonth] = useState({
    Year: "",
    Month: "",
  });

  const [offeringValues, setofferingValues] = useState([
    ...offeringValuesprops,
  ]);
  const [subOfferingValues, setsubOfferingValues] = useState([]);
  const [isshowDocDropDown, setisshowDocDropDown] = useState(false);

  const [tempcategoriesList, settempcategoriesList] = useState([
    ...categoriesprops.map((d) => ({
      ...d,
      checked: false,
    })),
  ]);

  const [categoryDropdown, setCategoryDropdown] = useState(false);
  const [tempallCategory, settempallCategory] = useState(false);
  const [allCategory, setallCategory] = useState(false);

  const [isMORShowDocViewer, setisMORShowDocViewer] = useState(false);

  const [ViewerItemDeatils, setViewerItemDeatils] = useState({
    ItemId: 0,
    ItemName: "",
    ItemLink: "",
    UpdatedTS: "",
    CreatedBy: "",
  });
  const [disableAddUpdate, setdisableAddUpdate] = useState(false)
  const [allowedDomainsArray, setAllowedDomainsArray] = useState([]);
  const [isAPILoading, setAPILoading] = useState(false);

  useEffect(async () => {
    setAPILoading(true);
    const reqData = {
      OrgEntityID,
    };
    const allowedDomains = await fetchAllowedDomains(reqData);
    setAllowedDomainsArray(allowedDomains);
    setAPILoading(false);

    if (EditData.Type !== "") {
      let data = EditData.Data;
      if (EditData.Type === "Folder") {
        setFolderDetails((prev) => ({
          ...prev,
          FolderId: data.MORFolderID,
          FolderName: data.FolderName,
        }));
      }
      if (EditData.Type === "Item") {
        setItemDetails((prev) => ({
          ...prev,
          ItemId: data.MORItemID,
          ItemLink: data.ItemLink,
          ItemName: data.ItemName,
        }));
        let selCategroies = tempcategoriesList.filter((d) =>
          data.CategoryIDs.filter((a) => a === d.ID).length > 0 ? true : false
        );
        setselectedcategoriesList(selCategroies);
        settempcategoriesList((prev) => {
          let data = [...prev].map((each) => {
            if (
              selCategroies.filter((d) => d.ID === each.ID).length >
              0
            ) {
              each.checked = true;
            } else {
              each.checked = false;
            }
            return each;
          });
          return [...data];
        });
      }
      setselectedYearMonth({
        Year: data.Year ? data.Year.toString() : "",
        Month: data.Month ? allmonth[parseInt(data.Month) - 1] : "",
      });
      let yearmonth = data.Year
        ? (
          allmonth[parseInt(data.Month) - 1] +
          " " +
          data.Year.toString()
        ).toString()
        : "Select Month";
      setselectedMonth(yearmonth);
      if (data.Offering) {
        setselectedOfferingName(data.Offering);
        setselectedSubOfferingName(data.SubOffering)
        setselectedSubOfferingID(data.SubOfferingID)
        let selectedOfferingData = offeringValues.filter(
          (d) => d.OfferingName_En.toLowerCase() === data.Offering_En.toLowerCase()
        );
        if (selectedOfferingData.length > 0) {
          let suboff = [...selectedOfferingData[0].ChildOfferings];
          if (suboff.length > 0) {
            setsubOfferingValues(suboff);
            let suboffering = suboff.filter(
              (d) => d.OfferingID === data.SubOfferingID
            );
            if (suboffering.length > 0) {
              setselectedSubOfferingName(suboffering[0].Process_En);
              setselectedSubOfferingID(data.SubOfferingID);
            }
          }
        }
        //selectOfferingOption(data.Offering)
        //selectSubOfferingOption(data.SubOfferingID)
      }
    }
    else {
      setdisableAddUpdate(true)
    }
  }, []);

  useEffect(() => {
    if (ErrorMessage !== "" && ErrorMessage !== "Close") {
      window.alert(ErrorMessage);
      makeErrorMessageEmpty()
      setdisableAddUpdate(false)
    }
    if (ErrorMessage === "Close") {
      setdisableAddUpdate(false)
      closePopup();
    }
  }, [ErrorMessage])

  useEffect(() => {
    handleCategoryApply()
  }, [tempallCategory, JSON.stringify(tempcategoriesList)])

  const closeViewer = () => {
    setisMORShowDocViewer(false);
  };

  const showDocDropDown = () => {
    setisshowDocDropDown(true);
  };

  const handleDocumentClose = (e) => {
    setisshowDocDropDown(false);
  };
  const handleView = () => {
    setisMORShowDocViewer(true);
    setisshowDocDropDown(false);
  };
  const handleValidation = () => {
    let formValid = true;
    if (isFolderAdd) {
      if (!FolderDetails.FolderName.trim()) {
        seterrors((prev) => ({
          ...prev,
          FolderName: "Folder Name is required",
        }));
        formValid = false;
      }

    } else {
      if (!ItemDetails.ItemName.trim()) {
        seterrors((prev) => ({
          ...prev,
          FileName: "Item Name is required",
        }));
        formValid = false;
      }

      if (!ItemDetails.ItemLink.trim()) {
        seterrors((prev) => ({
          ...prev,
          ItemLink: "Item Link is required",
        }));
        formValid = false;
      } else {
        let errorMessage = '';
        const itemLink = ItemDetails.ItemLink.trim();
        const { isValid, errMsg } = isValidEmbedLink(itemLink);
        errorMessage = errMsg;
        // IF THE EMBED LINK IS VALID, THE EXTRACT THE URL AND VALIDATE IT AGAINST WHITELISTED DOMAINS
        if (isValid) {
          const urls = extractUrls(itemLink);
          const isAllowed = validateIsAllowedDomainFromURL(urls[0], allowedDomainsArray);
          if (!isAllowed) {
            errorMessage = i18n.t('subdomain_or_domain_not_whitelisted');
          }
        }

        if (!_.isEmpty(errorMessage)) {
          seterrors((prev) => ({
            ...prev,
            ItemLink: errorMessage,
          }));
          formValid = false;
        }
      }
    }
    return formValid;
  };
  const [Doctype, setDoctype] = useState({
    Id: 0,
    DocName: "",
    DocLink: "",
    DocDescription: "",
  });
  const ExtractSrcLink = (Link) => {
    const DocsearchTerm = 'src="';
    const VideoImagesearchTerm = "?";
    const Extensions = [
      ".mp4",
      ".mov",
      ".webm",
      ".mkv",
      ".ogv",
      ".3g2",
      ".jpeg",
      ".jpg",
      ".giff",
      ".png",
    ];
    let isDoc;
    if (Extensions.some((v) => Link.toUpperCase().includes(v.toUpperCase()))) {
      isDoc = false;
      return `${Link.split(VideoImagesearchTerm)[0]}`;
    } else {
      if (Link.includes("<iframe")) {
        isDoc = true;
        return `${Link.split(DocsearchTerm)[1].split('"')[0]}`;
      } else {
        return Link;
      }
    }
  };
  const handleSave = () => {
    if (handleValidation()) {
      let data = {
        OrgEntityID: OrgEntityID,
        Offering:
          selectedOfferingName !== "Select Offering"
            ? selectedOfferingName
            : "",
        SubOfferingID: selectedSubOfferingID,
        Year: selectedYearMonth.Year,
        Month: parseInt(allmonth.indexOf(selectedYearMonth.Month) + 1),
        ParentMORFolderID: ParentFolderID,
      };
      if (isFolderAdd) {
        data["FolderName"] = FolderDetails.FolderName.trim();
        data["MORFolderID"] = FolderDetails.FolderId;
      } else {
        data["ItemName"] = ItemDetails.ItemName.trim();
        data["ItemLink"] = ExtractSrcLink(ItemDetails.ItemLink.trim());
        data["CategoryIDs"] = selectedcategoriesList.map((d) => d.ID);
        data["MORItemID"] = ItemDetails.ItemId;
      }
      setdisableAddUpdate(true)
      handleSavePopup(typePopup, isFolderAdd, data);
    }
  };

  const categoryDropdownOverlay = () => {
    setCategoryDropdown(false);
  };
  const handlecategoryDropdown = () => {
    setCategoryDropdown(true);
  };
  const handleCategoryApply = () => {

    let data = tempcategoriesList.filter((d) => d.checked === true);
    setselectedcategoriesList(data);
    if (data.length === tempcategoriesList.length) {
      setallCategory(true);
    } else {
      setallCategory(false);
    }
  };
  const handleCategoryChange = (index) => {
    settempcategoriesList((prev) => {
      prev[index].checked = !prev[index].checked;
      return [...prev];
    });
  };
  const handleAllCategory = () => {
    let data = !tempallCategory;
    settempallCategory(data);
    if (data) {
      settempcategoriesList((prev) => {
        let data = [...prev];
        data = data.map((d) => ({ ...d, checked: true }));
        return [...data];
      });
    } else {
      settempcategoriesList((prev) => {
        let data = [...prev];
        data = data.map((d) => ({ ...d, checked: false }));
        return [...data];
      });
    }
  };
  const selectOfferingOption = (Offering) => {
    let selectedOffering = offeringValues.filter(
      (d) => d.OfferingName_En.toLowerCase() === Offering.toLowerCase()
    );
    if (Offering !== "") {
      setselectedOfferingName(selectedOffering[0].OfferingName_En);
      setsubOfferingValues(selectedOffering[0].ChildOfferings);
    } else {
      setselectedOfferingName("Select Offering");
      setsubOfferingValues([]);
    }
    setselectedSubOfferingID(0);
    setselectedSubOfferingName("Select Sub Offering");
  };
  const selectSubOfferingOption = (e) => {
    if (e.target.value !== 0) {
      const filtersSubofferingData = filterSelectedSubOffering([...subOfferingValues], e.target.value);
      const selectedSuboffering = filtersSubofferingData.subofferingData[0];

      setselectedSubOfferingName(selectedSuboffering.Process_En);
      setselectedSubOfferingID(e.target.value);
    } else {
      setselectedSubOfferingName("Select Sub Offering");
      setselectedSubOfferingID(0);
    }
  };

  const selectMonthOption = (data) => {
    if (data !== "") {
      setselectedMonth(data);
      let monthyear = data.split(" ");
      setselectedYearMonth({
        Month: monthyear[0],
        Year: monthyear[1],
      });
    } else {
      setselectedMonth("Select Month");
      setselectedYearMonth({
        Month: "",
        Year: "",
      });
    }
  };

  useEffect(() => {
    if (
      tempcategoriesList.filter((d) => d.checked === true).length ===
      tempcategoriesList.length ||
      tempcategoriesList.length === 0
    ) {
      settempallCategory(true);
    } else {
      settempallCategory(false);
    }
  }, [tempcategoriesList]);

  useEffect(() => {
    if (categoryDropdown) {
      settempcategoriesList((prev) => {
        let data = [...prev].map((each) => {
          if (
            [...selectedcategoriesList].filter((d) => d.ID === each.ID).length >
            0
          ) {
            each.checked = true;
          } else {
            each.checked = false;
          }
          return each;
        });
        return [...data];
      });
    }
  }, [categoryDropdown]);

  const popupModalClose = () => {
    closePopup();
  };
  const bindTextFieldChange = (e) => {
    const id = e.target.id;
    switch (id) {
      case "ItemName":
        setItemDetails({ ...ItemDetails, ItemName: e.target.value });
        seterrors({ ...errors, FileName: "" });
        if (!e.target.value || !ItemDetails.ItemLink) {
          setdisableAddUpdate(true)
        }
        else {
          setdisableAddUpdate(false)
        }
        //data.ItemName = e.target.value;
        break;
      case "ItemLink":
        setItemDetails({ ...ItemDetails, ItemLink: e.target.value });
        seterrors({ ...errors, ItemLink: "" });
        if (!e.target.value || !ItemDetails.ItemName) {
          setdisableAddUpdate(true)
        }
        else {
          setdisableAddUpdate(false)
        }
        break;
      case "FolderName":
        setFolderDetails({ ...FolderDetails, FolderName: e.target.value });
        seterrors({ ...errors, FolderName: "" });
        if (e.target.value === "") {
          setdisableAddUpdate(true)
        }
        else {
          setdisableAddUpdate(false)
        }
        break;
      case "Document":
        var index = e.nativeEvent.target.selectedIndex;
        setDoctype({
          ...Doctype,
          Id: e.target.value,
          DocName: e.nativeEvent.target[index].text,
          DocLink: Document.filter((a) => a.Id === parseInt(e.target.value))[0]
            .ConfigValue,
          DocDescription: Document.filter(
            (a) => a.Id === parseInt(e.target.value)
          )[0].Description,
        });
        break;
      default:
        return;
    }
    //setItemDetails(data);
  };

  const handleTitlePopup = () => {
    if (isFolderAdd) {
      return typePopup === "Add" ? "Create New Folder" : "Edit Folder";
    } else {
      return typePopup === "Add" ? "Add New File" : "Edit File";
    }
  };


  return (
    <>
      {/* IF API IS LOADING THEN SHOW THE LOADER */}
      {isAPILoading && (
        <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />
      )}

      <Modal
        show={createnewModalStatus}
        onHide={popupModalClose}
        backdrop="static"
        className="createfolder-modal addmoritem-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{handleTitlePopup()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-section">
            {isFolderAdd ? (
              <>
                <div className="form-group border-group">
                  <label htmlFor="name" class="form-label">
                    {i18n.t("Enter_Folder_Name")}<span className="required">*</span>
                  </label>

                  <input
                    id="FolderName"
                    maxLength="65"
                    type="text"
                    value={FolderDetails.FolderName}
                    onChange={(e) => bindTextFieldChange(e)}
                    class="form-control"
                    placeholder={i18n.t("Enter_Folder_Name")}
                  />
                </div>
                <span className="errorMsg">{errors.FolderName}</span>
              </>
            ) : (
              <>
                <div className="form-group border-group">
                  <label htmlFor="name" class="form-label">
                    {i18n.t("Enter_File_Name")}<span className="required">*</span>
                  </label>

                  <input
                    id="ItemName"
                    maxLength="65"
                    type="text"
                    value={ItemDetails.ItemName}
                    onChange={(e) => bindTextFieldChange(e)}
                    class="form-control"
                    name="ItemName"
                    placeholder={i18n.t("Enter_File_Name")}
                  />
                </div>
                <span className="errorMsg">{errors.FileName}</span>
                {!isFolderAdd && (
                  <>
                    <div className="fileupload-linkicon mor-help">
                      <div className="form-group border-group">
                        <label htmlFor="name" class="form-label">
                          {i18n.t("Enter_Embedded_Link")}<span className="required">*</span>
                        </label>

                        <input
                          id="ItemLink"
                          maxLength="5000"
                          type="text"
                          value={ItemDetails.ItemLink}
                          onChange={(e) => bindTextFieldChange(e)}
                          class="form-control"
                          name="UserGuide"
                          placeholder={i18n.t("Enter_Embedded_Link")}
                        />
                        <span
                          onClick={showDocDropDown}
                          className="embed-info"
                          data-tip
                          data-for="embedded-info"
                        ></span>
                      </div>
                      <ReactTooltip
                        id="embedded-info"
                        place="bottom"
                        type="dark"
                        effect="solid"
                      ><span> {i18n.t("How_to_embed_link")}</span></ReactTooltip>
                    </div>
                    <span className="errorMsg">{errors.ItemLink}</span>
                  </>
                )}
              </>
            )}

            <div className="form-group border-group">
              <label htmlFor="name" class="form-label">
                {i18n.t("Select_Offering")}
              </label>

              <DropdownButton
                id="dropdown-offering"
                title={selectedOfferingName}
                className="form-control"
              >
                <Dropdown.Item
                  key={"Select_Offering"}
                  onClick={(e) => selectOfferingOption("")}
                  active={selectedOfferingName === "Select Offering"}
                >
                  {i18n.t("Select_Offering")}
                </Dropdown.Item>
                {offeringValues.map((each) => (
                  <Dropdown.Item
                    key={each.OfferingName_En}
                    onClick={() => {
                      selectOfferingOption(each.OfferingName_En);
                    }}
                    active={
                      selectedOfferingName === each.OfferingName_En ? true : false
                    }
                  >
                    {each.OfferingName}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
            <div className="form-group border-group">
              <SubOfferingDropdowns
                selectedSubOffering={selectedSubOfferingName}
                subOfferings={subOfferingValues}
                onSubOfferingChange={(e) => selectSubOfferingOption(e)}
                defaultToggleName={i18n.t('Select_Sub_Offering')}
                selectedSubOfferingId={selectedSubOfferingID}
                required={false}
                labelClassName="form-label"
                labelfor="SubOffering"
                disabledValue={false}
                dropdownItem={true}
                labelpresent={true}
                SubOfferingDropdownsClass={false}
              />
            </div>
            {!isFolderAdd && (
              <div className="form-group border-group morcategory-dropdown">
                <label htmlFor="Name" class="form-label">
                  {i18n.t("Select_Category")}
                </label>
                <div className="featuresearch-dropdown">
                  <div
                    className="featuresearch-dropdownname"
                    onClick={() => {
                      handlecategoryDropdown();
                    }}
                  >
                    {allCategory ||
                      selectedcategoriesList.length ===
                      tempcategoriesList.length ? (
                      <span>{i18n.t("All_Selected")}</span>
                    ) : selectedcategoriesList.length !== 0 ? (
                      <span>
                        {selectedcategoriesList
                          .map((each) => each.Name)
                          .join(", ")}
                      </span>
                    ) : (
                      <span>{i18n.t("Select_Category")}</span>
                    )}
                  </div>
                  {categoryDropdown ? (
                    <div className="featuresearch-list">
                      <ul>
                        <li>
                          <label className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              Name="checkAll"
                              checked={tempallCategory}
                              onChange={(e) => {
                                handleAllCategory(e);
                              }}
                            />
                            <span className="checkmark"></span>
                            <span className="form-check-label">{i18n.t("Select_All")}</span>
                          </label>
                        </li>
                        {tempcategoriesList.map((each, index) => (
                          <li>
                            <label className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                Name="checkAll"
                                checked={each.checked}
                                onChange={() => {
                                  handleCategoryChange(index);
                                }}
                              />
                              <span className="checkmark"></span>
                              <span className="form-check-label">
                                {each.Name}
                              </span>
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {categoryDropdown ? (
                  <div
                    className="viewby-dropdown-overlay"
                    onClick={() => {
                      categoryDropdownOverlay();
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
            )}

            <div className="form-group border-group">
              <label htmlFor="name" class="form-label">
                {i18n.t("Select_Month")}
              </label>

              <DropdownButton
                id="dropdown-suboffering"
                title={selectedMonth}
                className="form-control"
              >
                <Dropdown.Item
                  key={"Select Month"}
                  id={"Select Month"}
                  active={selectedMonth === "Select Month" ? true : false}
                  onClick={(e) => selectMonthOption("")}
                >
                  {i18n.t("Select_Month")}
                </Dropdown.Item>
                {monthList.map((x) => {
                  return (
                    <Dropdown.Item
                      key={x}
                      id={x}
                      active={selectedMonth === x ? true : false}
                      onClick={(e) => selectMonthOption(x)}
                    >
                      {x}
                    </Dropdown.Item>
                  );
                })}
              </DropdownButton>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-btn">
            <button
              className="btn btn-default btn-sm mr-3"
              onClick={() => popupModalClose()}
            >
              {i18n.t("Cancel")}
            </button>
            <button
              type="Button"
              className="btn btn-primary btn-sm"
              disabled={disableAddUpdate}
              onClick={() => handleSave()}
            >
              {typePopup}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {isshowDocDropDown && (
        <Modal
          show={isshowDocDropDown}
          onHide={handleDocumentClose}
          className="helpfeatureNotification-modal centered"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>{i18n.t("Document")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="helpfeaturenotification-body">
              <div className="helpfeaturenotification-data">
                <select
                  id="Document"
                  className="form-control"
                  onChange={(e) => bindTextFieldChange(e)}
                  value={Doctype.Id}
                >
                  {Document.map((Doc) => {
                    return <option value={Doc.Id}>{Doc.ConfigKey}</option>;
                    // }
                  })}
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-btn addmoritem-btns">
              <button
                onClick={handleDocumentClose}
                className="btn btn-default btn-sm"
              >
                {i18n.t("Cancel")}
              </button>
              <button
                type="Button"
                className="btn btn-primary btn-sm"
                onClick={() => handleView()}
              >
                {i18n.t("View")}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {isMORShowDocViewer ? (
        <MORDocViewer
          isMORShowDocViewer={isMORShowDocViewer}
          ViewerItemDeatils={ViewerItemDeatils}
          DocDetails={DocDetails}
          isMORDoc={false}
          allData={Document}
          closeViewer={closeViewer}
        // AllViewerItemDeatils={ClonedMORItemsModel}
        />
      ) : (
        ""
      )}
    </>
  );
}
