import React, { Component } from 'react';
import { LocalApiBaseUrl } from '../Shared/Constant';
import { AgGridReact } from 'ag-grid-react';
import ConnectorForm from './ConnectorForm';
import { connect } from "react-redux";
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import { store } from '../reducers/configureStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import UserAccessDeniedScreen from "../Shared/UserAccessDeniedScreen"
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;





 class ConnectorMasterList extends Component {
    constructor() {
        super()
        this.state = {
            rowSelected: [],
            userAccessDenied:false,
            userList: [], roleList: [], clientList: [], offeringList: [], viewmore: false, DEIDealOptions: [], errors: {}, formIsValid: false,
            Connector: {
                DealID: null,
                ConnectorID: null,
                ParamName1: "",
                ParamName2: "",
                ParamName3: "",
                ParamName4: "",
                ParamName5: "",
                ParamName6: ""
            },
            columnDefs: [

                {
                    headerName: "Edit", suppressMovable: true,
                    width: 80,
                    cellRenderer: (params) => {
                        let link = document.createElement('span');
                        link.className = 'tbledit';
                        link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
                        link.addEventListener('click', (e) => {
                            this.connectorpopup(e, params.data);
                        });
                        return link;
                    },
                    suppressFilter: true,
                    suppressSizeToFit: true
                },
                {
                    headerName: "Delete", suppressMovable: true,
                    width: 70,

                    cellRenderer: (params) => {
                        let link = document.createElement('span');
                        link.className = 'tbldel';
                        link.innerHTML = '<i class="fas fa-trash-alt delete-icon-fix"></i>';
                        link.addEventListener('click', (e) => {
                            this.deleteProfile(e, params.data);
                        });
                        return link;
                    },
                    suppressFilter: true,
                    suppressSizeToFit: true
                },

                { headerName: "SO Category", field: "SOCategory", resizable: true, suppressMovable: true, suppressSizeToFit: true },
                { headerName: "Sub SOCategory", field: "SubSOCategory", resizable: true, suppressMovable: true, suppressSizeToFit: true },
                { headerName: "Client", field: "Client", resizable: true, suppressMovable: true, suppressSizeToFit: true },
                { headerName: "Location", field: "Location", resizable: true, suppressMovable: true, suppressSizeToFit: true },
                { headerName: "Offering", field: "Offering", resizable: true, suppressMovable: true, suppressSizeToFit: true },
                { headerName: "Sub-Offering", field: "SubOffering", resizable: true, suppressMovable: true, suppressSizeToFit: true },
                { headerName: "Metric", field: "SynOpsMetricName", resizable: true, suppressMovable: true, suppressSizeToFit: true },


            ],
            rowData: [],
            gridOptions: {
                paginationPageSize: 50,
                rowHeight: 40,
                headerHeight: 40,
                animateRows: true,
                rowSelection: 'multiple',
                defaultColDef: {
                    sortable: true
                },

            }

        }



        this.onClose = this.onClose.bind(this);
        this.onDEIDealChange = this.onDEIDealChange.bind(this);

        this.connectorpopup = this.connectorpopup.bind(this);
        this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
        this.onSourceChange = this.onSourceChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handleSave = this.handleSave.bind(this);

    }

    onFilterTextBoxChanged(e) {
        this.state.gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
    }




    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    onSourceChange(event) {

        const Connector = this.state.Connector;
        Connector.ConnectorID = event.target.value;
        let index = event.nativeEvent.target.selectedIndex;
        Connector.ConnectorName = event.nativeEvent.target[index].text
        Connector.SourceName = "";
        Connector.SourceFieldName = "";
        this.setState({
            data: {
                Connector: Connector,
            }
        })
        this.getConnector()

    }

    GetDEIDeal() {

        let samp = {};
        let EnterpriseID = sessionStorage.getItem("EnterpriseID")
        if (EnterpriseID != undefined) {
            axiosInstance.get(`${LocalApiBaseUrl}Deal/GetDeals`, {
                params: {FeatureName: "Configure Source Parameter"
                ,OrgEntityID:store.getState().CurrentFilter.ClientData.id}
            })

                .then((response) => {
                    this.setState({ DEIDealOptions: response.data });
                })
                .catch((error) => {

                })
                .catch((error) => {
                    trycatchAlertPopup(error);
                  });
          
          


        }
    }

    getConnector() {
        axiosInstance.get(`${LocalApiBaseUrl}ConnectorMapping/GetConnectorCategory`, {
            params: { DealID: this.state.Connector.DealID, ConnectorID: this.state.Connector.ConnectorID }
        })
            .then((response) => {
                response.data.forEach(obj => {
                    obj.MetricParam1 = obj.MetricParam1.toUpperCase()
                    obj.MetricParam2 = obj.MetricParam2.toUpperCase()                    
                })
                
                this.setState({
                    rowData: response.data.sort((a, b) => (a.ConnectorName > b.ConnectorNam) ? 1 : -1)
                })
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });      
    }


    deleteProfile(e, data) {

        if (this.props.featureData.filter((data) => String(data.Submenu) === "Configure Source Parameter" && data.Operations.includes("Delete")).length > 0) {

            if (window.confirm('Are you sure you want to delete the item?')) {
                axiosInstance.delete(`${LocalApiBaseUrl}ConnectorMapping/ConnectorDeleteCategory`, {
                    params: {
                        KpiMetricConnectorId: data.KpiMetricConnectorID
                    }
                }).then((response) => {
                    window.alert(response.data.StatusMessage)
                    this.getConnector();

                })
                .catch((error) => {
                    trycatchAlertPopup(error);
                  });
          
          
            }
        }
        else window.alert(" UnAuthorised Access Denied !!")
    }

    connectorpopup(e, data) {
        if (data != undefined) {
            // edit condition
            if (this.props.featureData.filter((data) => String(data.Submenu) === "Configure Source Parameter" && data.Operations.includes("Edit")).length > 0) {
                e.preventDefault();
                this.setState({ viewmore: true, rowSelected: data })
                this.getConnector();
            }
            else window.alert("Access Denied !!")
        }
        else {
            // add condition
            if (this.props.featureData.filter((data) => String(data.Submenu) === "Configure Source Parameter" && data.Operations.includes("Add")).length > 0) {

                e.preventDefault();
                this.setState({ viewmore: true, rowSelected: data })
                this.getConnector();
            }
            else window.alert("Access Denied !!")

        }

    }



    handleSave(data) {

        axiosInstance.post(`${LocalApiBaseUrl}ConnectorMapping/AddEditConnectorMaster`, data)
            .then((response) => {
                window.alert(response.data.StatusMessage)
                this.getConnector();
            },
                (error) => {


                })
                .catch((error) => {
                    trycatchAlertPopup(error);
                  });
          
        this.getConnector();

    }


    onClose(e) {

        this.setState({ viewmore: false });
    }
    onDEIDealChange(event) {

        let soCatAtt = null;
        this.setState({ errors: {} });
        const kpiData = this.state.Connector;
        kpiData.DealID = event.target.value;
        if (this.state.DEIDealOptions.filter((obj) => obj.DealID == this.state.Connector.DealID))
            soCatAtt = this.state.DEIDealOptions.filter((obj) => obj.DealID == this.state.Connector.DealID)
        {

        }


        this.setState({
            kpiData: kpiData,

        })

        this.getConnector()
    }




    componentDidMount() {
        if(this.props.userAccessDeniedScreen ==="Configure Source Parameter")
        {
          this.setState({
            userAccessDenied:true
          })
        }
        else
        {

        this.GetDEIDeal();
        this.getGetConnectorMaster();
        let columnDefs = this.state.columnDefs;
        let columnDefsAdd = [];
        let columnDefsDelete = [];


        if (this.props.featureData.filter((data) => String(data.Submenu) === "Configure Source Parameter" && data.Operations.includes("Edit")).length == 0) {
            columnDefs = columnDefs.filter((data) => data.headerName != "Edit");


        }
        if (this.props.featureData.filter((data) => String(data.Submenu) === "Configure Source Parameter" && data.Operations.includes("Delete")).length == 0) {
            columnDefs = columnDefs.filter((data) => data.headerName != "Delete");


        }
        this.setState({
            columnDefs: columnDefs
        })
    }





    }





    getGetConnectorMaster() {

        let source = [];
        axiosInstance.get(`${LocalApiBaseUrl}ConnectorMapping/GetConnectorMaster`)
            .then((response) => {
                source = response.data;
                let sourceValues = source.map((x) => <option key={x.ConnectorID} /*selected={index == 0 ? "" : ""}*/ value={x.ConnectorID}>{x.ConnectorName}</option>)
                // Query Builder
                store.dispatch({
                    type: 'FETCH_SOURCE_CONNECTION_LIST',
                    payload: response.data
                })
                this.setState({ sourceOptions: sourceValues });
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
      

    }

    componentWillMount() {

        this.getGetConnectorMaster();
    }


    validateForm() {

        let kpiData = this.state.Connector;
        let errors = {};
        let formIsValid = true;

        if (!kpiData["ConnectorID"]) {
            formIsValid = false;
            errors["ConnectorID"] = "Source is required";

        }
        if (!kpiData["DealID"]) {
            formIsValid = false;
            errors["DealID"] = "Deal is required";

        }
        this.setState({
            errors: errors
        });


        if (formIsValid) {
            this.setState({
                formIsValid: true
            })
        }
        else {
            this.setState({
                formIsValid: false
            })
        }
        return formIsValid;


    }
    componentDidUpdate(prevProps){
        if(prevProps.userAccessDeniedScreen !== this.props.userAccessDeniedScreen)
        {
          if(this.props.userAccessDeniedScreen ==="Configure Source Parameter")
          {
            this.setState({
              userAccessDenied:true
            })
          }
          else
          {
            this.setState({
              userAccessDenied:false
            })
          }
        }
      }

    render() {

        return (
            <>
            {
                !this.state.userAccessDenied ?
            <div className="main-data">
                <div style={{ display: "inline-block", width: "100%" }}>
                    <div style={{ float: "right" }}>
                        <button type="button" className="btn btn-primary btn-sm" onClick={(e) => { this.connectorpopup(e) }}><span><FontAwesomeIcon icon={faPlusCircle} /></span> Add Mapping</button>
                    </div>
                </div>

                <div className="tableFilter">
                    <div className="form-section">
                        <div className="form-row">
                            <div className="form-group col-lg-3">
                                <label>Source System<span className="required ">*</span></label>
                                {this.state.con ? <select className="form-control" readOnly onChange={this.onSourceChange} value={this.state.data.kpiData.ConnectorID}>
                                    {this.state.connectorOptions}
                                </select> : this.state.valueConnectorEdit ? <input type="text" className="form-control" readOnly onChange={this.onSourceChange} value={this.state.Connector.ConnectorNames} />
                                        : <select className="form-control" onChange={this.onSourceChange} value={this.state.Connector.ConnectorID}>
                                            <option value="0">Select Source</option>
                                            {this.state.sourceOptions}
                                           
                                        </select>}
                                <div className="errorMsg">{this.state.errors.ConnectorID}</div>
                            </div>
                            <div className="form-group col-lg-3">
                                <label>Deal <span className="required">*</span></label>
                                <select id="OperatingGroup" className="form-control" disabled={this.props.data} ref="OperatingGroupDropDown" onChange={this.onDEIDealChange.bind(this)} value={this.state.Connector.DealID}>
                                    <option value="0">Select Deal</option>
                                    {this.state.DEIDealOptions.map((row) => {
                                        return <option value={row.DealID}>{row.ClientName + "  -  " + row.Process + "  -  " + row.City}</option>;
                                    })}
                                </select>
                                <div className="errorMsg">{this.state.errors.DealID}</div>
                            </div>
                            {this.props.featureData.filter((data) => String(data.Submenu) === "Configure Source Parameter" && data.Operations.includes("Add")).length > 0 &&
                                <div className="form-group col-lg-3 label-adjust">
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ag-theme-balham ag-table">
                            <AgGridReact
                                enableSorting={true}
                                enableFilter={true}
                                pagination={true}
                                paginationPageSize={50}
                                floatingFilter={true}
                                gridOptions={this.state.gridOptions}
                                columnDefs={this.state.columnDefs}
                                rowData={this.state.rowData}
                                onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                            </AgGridReact>
                        </div>
                    </div>
                </div>
                {this.state.viewmore ? <ConnectorForm data={this.state.rowSelected} OfferingId={this.state.Connector.OfferingId} show={this.state.viewmore} onClose={this.onClose} filterDetails={this.props.filterDetails} onSave={this.handleSave} /> : null}
            </div>
            :<UserAccessDeniedScreen />
                        }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      userAccessDeniedScreen: state.roleReducers.userDeniedScreen,
    };
  };
  export default connect(mapStateToProps)(ConnectorMasterList);