import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { Tabs, Tab, Modal } from "react-bootstrap";
import emptyFeatureListIcon from "../Images/EmptyFeatureList.svg";
import { Overlay, Popover } from "react-bootstrap";
import { LocalApiBaseUrl } from "../Shared/Constant";
import HumanMachineJourney from "../HumanMachineJourney";
import HumanMetricDetails from "./HumanMetricDetails";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { connect } from "react-redux";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import i18n from "i18next";
import "./reference.css";
import LiveFeedIcon from "../Images/LiveFeed.svg"
import PyramidImage from '../Images/Pyramid.svg';
import CloseIcon from "../Images/close-icon.svg";
import _ from "lodash";
import References from "../Shared/References"
import { store } from "../reducers/configureStore";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


const options = {
  loop: true,
  margin: 8,
  responsiveClass: "",
  dots: false,
  responsive: {
    0: {
      items: 1,
      nav: true,
    },
    600: {
      items: 2,
      nav: false,
    },
    1000: {
      items: 3,
      nav: true,
      loop: false,
    },
  },
};
const optionsTwo = {
  loop: true,
  margin: 8,
  responsiveClass: "",
  dots: false,
  responsive: {
    0: {
      items: 1,
      nav: true,
    },
    600: {
      items: 2,
      nav: false,
    },
    1000: {
      items: 2,
      nav: true,
      loop: false,
    },
  },
};
class HumanMachineTalent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyResource: 0,
      HumanData: [],
      MachineData: [],
      metricDetails: "",
      exploreLinkList: [],
      exploreDrop: "none",
      machineMetricDetails: "",
      offeringName: "",
      doesKPIMappingRecordsExistsForDeal: false,
      humanDataForHMJ: [],
      humanMetricsSum: 0,
      liveFeedModalStatus: false,
      hybridDenominator: 0,
      isShownFD: true,
      selectedOption: 0,
      humanandMachinedata: [],
      filterdata: [],
      machineName: ""
    };
    this.liveFeedModalShow = this.liveFeedModalShow.bind(this);
    this.liveFeedModalClose = this.liveFeedModalClose.bind(this);
    this.isHybridWorkflowPopoverClose = this.isHybridWorkflowPopoverClose.bind(this);
    this.isHybridWorkflowPopoverShow = this.isHybridWorkflowPopoverShow.bind(this);
    this.HybridWorkflowPopoverTarget = React.createRef();
    this.getToggleDetails = this.getToggleDetails.bind(this);
  }
  liveFeedModalShow() {
    this.setState({ liveFeedModalStatus: true });
  }
  liveFeedModalClose() {
    this.setState({ liveFeedModalStatus: false });
  }
  isHybridWorkflowPopoverClose() {
    this.setState({ isHybridWorkflowPopoverStatus: false });
  }
  isHybridWorkflowPopoverShow() {
    this.setState({ isHybridWorkflowPopoverStatus: true });
  }



  doesKPIMappingRecordsExistForDeal() {
    let filterClient = this.props.filterDetails.Client;
    let filterProcess = this.props.filterDetails.process;
    let filterLocations = this.props.filterDetails.Locations;

    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/DoesKPIMappingRecordsExistForDeal`, {
        params: {
          OrgEntityID: filterClient,
          LocationID: filterLocations,
          OfferingID: filterProcess,
        },
      })
      .then((response) => {
        this.setState({ doesKPIMappingRecordsExistsForDeal: response.data });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

  }
  getToggleDetails = () => {

    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetHybridWorkDataRatio`, {
        params: {
          OrgEntityID: parseInt(this.props.filterDetails.Client),
          OfferingID: parseInt(this.props.filterDetails.process),
          LocationID: parseInt(this.props.filterDetails.Locations),
          PeriodDT: this.props.filterDetails.Month,
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
          this.setState({
            isShownFD: response.data[0].IsShownonFD,
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

  }
  handleChange = event => {
    this.setState({
      selectedOption: event.target.value
    });
  };

  getHumanAndMachineDetails() {

    let filterClient = this.props.filterDetails.Client;
    let filterProcess = this.props.filterDetails.process;
    let filterLocations = this.props.filterDetails.Locations;
    let filterDate = this.props.filterDetails.Month;
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetHumanAndMachineTalent`, {
        params: {
          OrgEntityID: filterClient,
          LocationID: filterLocations,
          MonthDT: filterDate,
          OfferingID: filterProcess,
          LanguageID: parseInt(this.props.languageData.id),
        },
      })
      .then((response) => {

        //human part....
        let human = [];
        let Human = response.data.filter((x) => x.SOCategoryName === "Human" || x.SOCategoryName_En === "Human");
        human = (response.data.filter((x) => x.ParentSOCategoryID == Human[0].SOCategoryID))
        this.setState({ humanandMachinedata: human, filterdata: response.data, selectedOption: human[0].SOCategoryID }, () => {
          this.filterData();
        });


        //Machine part....
        let machineData1 = [];
        let Machine = response.data.filter((x) => x.SOCategoryName === "Machine" || x.SOCategoryName_En === "Machine")
        let machineCatagory = 0;

        Machine.map(item => {
          if (item.SOCategoryID != undefined) {
            machineCatagory = item.SOCategoryID;
          }
        });
        response.data.map((data) => {
          if (machineCatagory == data.ParentSOCategoryID) {
            machineData1.push(data);
          }
        });
        machineData1.map(item => {
          if (item.SOCategoryName != undefined) {
            this.setState({ machineName: item.SOCategoryName });
          }
        });
        let machineData = [];
        let machineDataObj = {
          ...machineData1[0],
          kpiData:
            machineData1.length > 0
              ? machineData1[0].kpiData.sort((a, b) =>
                a.MetricSeq !== b.MetricSeq
                  ? a.MetricSeq > b.MetricSeq
                    ? 1
                    : -1
                  : a.MetricName.toLowerCase() > b.MetricName.toLowerCase()
                    ? 1
                    : -1
              )
              : [],
        };
        machineData.push(machineDataObj);
        let machinelist = [];
        let machinerowTheList = [];
        let machinelistSample = machineData[0] != undefined ?
          machinelist.push(
            <div className="custom-tilecard hm-tilecard">
              <div className="tilecard-wrapper no-carousel">

                <HumanMetricDetails dealFilter={this.props.filterDetails} Tab={machineData[0].SOCategoryName} isRelatorBlock={false} Details={machineData[0].kpiData} />
                <ReactTooltip place="bottom" effect="solid" className="tipcolor" />
              </div>
            </div>
          )

          : null
        let machinelistEmpty = (machineData[0] == undefined || machineData[0].kpiData.length == 0) ?
          machinelist.push(<div className="emptycriteria-block">
            <img
              src={emptyFeatureListIcon}
              alt={i18n.t("Pulse_No_Assets_Available")}
            />
            <h4>No metrics available</h4>
          </div>
          ) : null
        this.setState({ machinelist });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

  }
  getHybridRatio = () => {
    //calculate the sum of human tile metricvalues here - ratio numerator 
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetHybridWorkDataRatio`, {
        params: {
          OrgEntityID: parseInt(this.props.filterDetails.Client),
          OfferingID: parseInt(this.props.filterDetails.process),
          LocationID: parseInt(this.props.filterDetails.Locations),
          PeriodDT: this.props.filterDetails.Month,
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
          this.setState({
            humanMetricsSum: response.data[0].NumeratorValue?parseInt(response.data[0].NumeratorValue):0,
            hybridDenominator: parseInt(response.data[0].MetricValue),
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

  }
  handleShowlivefeedButton() {
    axiosInstance.get(`${LocalApiBaseUrl}SOFilter/GetSOCategoryforHumanJourney`, {
      params: {
        SOCategory: "Human Journey",
        OrgEntityID: parseInt(this.props.filterDetails.Client),
        OfferingID: parseInt(this.props.filterDetails.process),
        LocationID: parseInt(this.props.filterDetails.Locations),
        LanguageID: this.props.languageData.id
      },
    })
      .then((response) => {
        this.setState({
          human: response.data
        })
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetToolTypeforMachineJourney`, {
        params: {
          LanguageID: this.props.languageData.id,
          OrgEntityID: parseInt(this.props.filterDetails.Client),
          OfferingID: parseInt(this.props.filterDetails.process),
          LocationID: parseInt(this.props.filterDetails.Locations),
        },
      })
      .then((response) => {
        this.setState({
          machine: response.data
        })
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }
  filterData = () => {
    let Human = this.state.filterdata.filter((x) => x.SOCategoryName == "Human" || x.SOCategoryName_En === "Human");
    let human = (this.state.filterdata.filter((x) => x.ParentSOCategoryID == Human[0].SOCategoryID))
    let humanData1 = human.filter((x) => this.state.selectedOption == x.SOCategoryID);

    let humanData = [];
    let humanDataObj = {
      ...humanData1[0],
      kpiData:
        humanData1.length > 0
          ? humanData1[0].kpiData.sort((a, b) =>
            a.MetricSeq !== b.MetricSeq
              ? a.MetricSeq > b.MetricSeq
                ? 1
                : -1
              : a.MetricName.toLowerCase() > b.MetricName.toLowerCase()
                ? 1
                : -1
          )
          : [],
    };
    humanData.push(humanDataObj);

    let humanlist = [];
    let rowTheList = [];
    let listSample = humanData[0] != undefined ?
      humanlist.push(
        <div className="custom-tilecard hm-tilecard">
          <div className="tilecard-wrapper no-carousel">

            <HumanMetricDetails
              Tab={this.state.selectedOption}
              isRelatorBlock={this.props.isRelatorBlock}
              Details={humanData[0].kpiData}
              dealFilter={this.props.filterDetails}
            /><ReactTooltip place="bottom" effect="solid" className="tipcolor" />
          </div>
        </div>
      )
      : null
    let listEmpty = (humanData[0] == undefined || humanData[0].kpiData.length == 0) ?
      humanlist.push(<div className="emptycriteria-block">
        <img
          src={emptyFeatureListIcon}
          alt={i18n.t("Pulse_No_Assets_Available")}
        />
        <h4>No metrics available</h4>
      </div>) : null

    this.setState({ humanlist });
  }

  componentDidMount() {
    this.setState({ offeringName: this.props.offerName });
    this.getHumanAndMachineDetails();
    this.getToggleDetails();
    this.doesKPIMappingRecordsExistForDeal();

    this.getHybridRatio();
    this.handleShowlivefeedButton();
    let filterClient = this.props.filterDetails.Client;
    let filterProcess = this.props.filterDetails.process;
    let filterLocations = this.props.filterDetails.Locations;
    let filterDate = this.props.filterDetails.Month;
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetHumanAndMachineTalent`, {
        params: {
          OrgEntityID: filterClient,
          LocationID: filterLocations,
          MonthDT: filterDate,
          OfferingID: filterProcess,
          LanguageID: parseInt(this.props.languageData.id),
        },
      })
      .then((response) => {

        let Human = response.data.filter((x) => x.SOCategoryName == "Human" || x.SOCategoryName_En === "Human");
        let human = (response.data.filter((x) => x.ParentSOCategoryID === Human[0].SOCategoryID))
        var keyInsight = {};
        keyInsight = human[1];

        keyInsight.kpiData.map(i => {
          if (i.MetricName == "Total Resource" && i.MetricUnit == "Number") {
            this.state.keyResource = i.MetricValue;

            let sumOfMetricValues = 0;

            if (parseInt(this.state.humanMetricsSum) != 0 && !isNaN(this.state.humanMetricsSum)) {
              sumOfMetricValues = parseInt(this.state.humanMetricsSum);
            }
            else {
              sumOfMetricValues = this.state.keyResource
            }
            this.setState({ humanMetricsSum: sumOfMetricValues });

          }
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

  }

  componentDidUpdate(prevProp, prevState) {
    if (prevState.selectedOption !== this.state.selectedOption)
      this.filterData();
  }

  componentWillReceiveProps() {
    this.getHumanAndMachineDetails();
  }
  viewMore() {

    let filterClient = this.props.filterDetails.Client;
    let filterProcess = this.props.filterDetails.process;
    let filterLocations = this.props.filterDetails.Locations;
    let SOCategoryID = 2



    let displayStatus = this.state.exploreDrop;
    if (displayStatus == "none") {

      axiosInstance
        .get(`${LocalApiBaseUrl}ClickableTools/GetExploreTools`, {
          params: {
            OrgEntityID: filterClient,
            OfferingID: filterProcess,
            LocationID: filterLocations,
            SOCategoryID: SOCategoryID,
            IsAllTool: false,
          },
        })
        .then((res) => {

          let exploreLinkList = res.data.map((eachLinks) => {
            return (
              <a
                className={eachLinks.URL ? "dropdown-item" : "dropdown-item explore_tools_noCursor"}
                href={
                  eachLinks.URL ? (eachLinks.URL !== "NA" && eachLinks.URL !== "https://" ? eachLinks.URL : eachLinks.URL.substr) : "javascript:void(0)"
                }
                target={eachLinks.URL && "_blank"}
              >
                {eachLinks.ApplicationName}
              </a>
            );
          });

          this.setState({ exploreLinkList, exploreDrop1: res.data.length == 0 ? i18n.t("No tools available") : "", });
          this.onClose();
        })
        .catch((error) => {
          trycatchAlertPopup(error)
        });
    } else {
      this.setState({ exploreDrop: "none" });
    }
  }

  componentWillReceiveProps() {
    this.getHumanAndMachineDetails();
  }
  render() {

    const { exploreLinkList } = this.state;
    var numeratorHeight = (this.state.humanMetricsSum * 100 / (this.state.humanMetricsSum + this.state.hybridDenominator));
    var denomenatorHeight = (this.state.hybridDenominator * 100 / (this.state.humanMetricsSum + this.state.hybridDenominator));
    return (
      <>
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-title clearfix references-dropdown dashboard-references-dropdown">
              <div className="dropdown">
                <a className="dropdown-toggle"
                  data-toggle="dropdown"
                  onClick={() => { this.viewMore() }}>
                  <span className="exploreTools">
                    {/* <i class="fal fa-info-circle"></i> */}{i18n.t("Explore_Tools_Advisor")}
                    <span>{i18n.t("Click on Explore Tools/Advisor to view Tools")}</span>
                  </span>
                  <span className="caret"></span>
                </a>

                <ul className="dropdown-menu explore_tools_root explore-tools-menu dropdown-menu-right custom-dropdown-menu">{exploreLinkList.length > 0 ?
                  this.state.exploreLinkList : (<div><p>
                    {this.state.exploreDrop1}
                  </p></div>)}</ul>
              </div>
              <div className="category-references dashboard-category-references">
                {<References
                  {...this.props}
                  SOCatID={2}
                  leverName="Human + Machine Talent"
                  referenceScreen="HM"
                />
                }
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="hmtab-layout">
              <div className="hmtab-btns">
                {this.state.human && this.state.machine && this.state.doesKPIMappingRecordsExistsForDeal &&
                  <button className="btn btn-gradient mr-3" onClick={this.liveFeedModalShow}><img src={LiveFeedIcon} className="mr-2" /> Live Feed</button>}
                <div className="hybrid-link" ref={this.HybridWorkflowPopoverTarget}>
                  {(this.state.isShownFD && (this.state.hybridDenominator !== 0)) ? (<span className={this.state.isHybridWorkflowPopoverStatus ? 'active' : ''} onClick={this.isHybridWorkflowPopoverShow}><img src={PyramidImage} width="23px" height="23px" className="mr-1" /> Hybrid Workforce</span>) : null}
                  <Overlay
                    target={this.HybridWorkflowPopoverTarget.current}
                    show={this.state.isHybridWorkflowPopoverStatus}
                    onHide={this.isHybridWorkflowPopoverClose}
                    placement="bottom-end"
                    container={this.HybridWorkflowPopoverTarget.current}
                    rootClose
                    rootCloseEvent="click"
                  >
                    <Popover className="hybridworkflow-popover">
                      <Popover.Content>
                        <button
                          type="button"
                          className="close"
                          aria-label="Close"
                          onClick={this.isHybridWorkflowPopoverClose}
                        >
                          <img src={CloseIcon} />
                        </button>
                        <h4>Hybrid Workforce</h4>
                        <div className="hm-ratio-display">
                          <p>Human Machine Ratio</p>
                          {this.state.hybridDenominator !== 0 ? (
                            <span className="ratio-value mr-4">
                              {this.state.humanMetricsSum == 0? 'NA':this.state.humanMetricsSum}:{this.state.hybridDenominator}
                            </span>
                          ) : null}
                        </div>
                        {/* Pyramid Chart start */}
                        {this.state.hybridDenominator &&
                          <div class="human-machine-labels">
                            <div class="human-label" style={{ height: numeratorHeight + "%" }}><span>Human <br /><span> {this.state.humanMetricsSum == 0 ?'NA':this.state.humanMetricsSum}</span></span></div>
                            <div class="machine-label" style={{ height: denomenatorHeight + "%" }}><span>Machine <br /> <span>{this.state.hybridDenominator}</span></span></div>
                          </div>}
                        {this.state.hybridDenominator &&
                          <div id="pyramid-hybrid" class="pyramid-chart">
                            <div id="level1" style={{ height: numeratorHeight + "%" }}>
                            </div>
                            <div id="level2" style={{ height: denomenatorHeight + "%" }}>
                            </div>
                          </div>}


                      </Popover.Content>

                    </Popover>
                  </Overlay>

                </div>
              </div>
              <Tabs defaultActiveKey="Human">
                <Tab eventKey="Human" title="Human">
                  <div className="title-with-icon">
                    <div className="hmradio-btns">
                      {this.state.humanandMachinedata.map((Catagory) => {
                        return <>
                          {this.state.selectedOption == parseInt(Catagory.SOCategoryID) ?
                            <label className="form-check mr-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                id={Catagory.SOCategoryID}
                                name={Catagory.SOCategoryName}
                                value={Catagory.SOCategoryID}
                                checked={this.state.selectedOption == parseInt(Catagory.SOCategoryID)}
                                onChange={this.handleChange}
                              />
                              <span className="checkmark"></span>
                              <span className="form-check-label active">{Catagory.SOCategoryName}</span>
                            </label> :
                            <label className="form-check mr-3 ">
                              <input
                                className="form-check-input"
                                type="radio"
                                id={Catagory.SOCategoryID}
                                name={Catagory.SOCategoryName}
                                value={Catagory.SOCategoryID}
                                checked={this.state.selectedOption == parseInt(Catagory.SOCategoryID)}
                                onChange={this.handleChange}
                              />
                              <span className="checkmark"></span>
                              <span className="form-check-label">{Catagory.SOCategoryName}</span>
                            </label>}
                        </>

                      })}
                    </div>
                  </div>
                  {this.state.humanlist}

                </Tab>
                <Tab eventKey="Machine" title="Machine">
                  <div className="hmradio-btns">
                    <h5>{this.state.machineName}</h5>
                  </div>
                  {this.state.machinelist}
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            {/* New H&M Journey tile to be shown only for those deals whose kpimappingrecord exists mapped to Human Journey, Machine Journey and hybrid */}
            {this.state.doesKPIMappingRecordsExistsForDeal && (
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                </div>
              </div>
            )}
          </div>
        </div>
        {/* LiveFeed Modal Start */}
        <Modal show={this.state.liveFeedModalStatus} backdrop="static" onHide={this.liveFeedModalClose} className="modal fade livefeed-modal">
          <HumanMachineJourney
            filterDetails={this.props.filterDetails}
            humanMetricsSum={this.state.humanMetricsSum}
          />
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    languageData: state.CurrentFilter.languageData,
    offerName: state.CurrentFilter.Filter.offeringName,
    clientData: state.CurrentFilter.ClientData,
  };
};
export default connect(mapStateToProps)(HumanMachineTalent);
