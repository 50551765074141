import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import i18n from "i18next";
import { LocalApiBaseUrl, regexForToolURL, regexForTextAreas } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import moment from "moment";
const currentDate = new Date();
const today = new Date().toJSON().substring(0, 10);

export default class AddToolModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoaderonPage: false,
      toolslist: [],
      selectedTool: {
        ApplicationMappingID: "",
        ApplicationName: "",
        ApplicationCategory: "",
        ApplicationDescription: "",
        DeploymentDate: "",
        ExpectedDeploymentDate: "",
          BusinessBenefit: '',
        ToolType: "",
        URL: "",
        DefaultUserID: "",
        CustomToolID: "",
        ToolID: "",
      },
      toolErrors: { toolname: "", url: "", deploymentDate: "", BusinessBenefit: "" },
      selectedOption: "",
      isEditable: false,
      expectedDateEdit: false,
      deployDateEdit: false,
      deploymentDateError: false,
      popupHeader: i18n.t("Add Tool"),
      popupSaveButton: i18n.t("Add"),
    };
    this.handleSelectToolEvent = this.handleSelectToolEvent.bind(this);
    this.handleDeploymentDateChange = this.handleDeploymentDateChange.bind(this);
    this.handleExpectedDeploymentDateChange = this.handleExpectedDeploymentDateChange.bind(this);
    this.handleRadioOptionChange = this.handleRadioOptionChange.bind(this);
    this.handleAddToolClick = this.handleAddToolClick.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleToolFieldChange = this.handleToolFieldChange.bind(this);
  }

  componentDidMount() {
    //Edit Scenario of Popup
    if (this.props.tooldata && this.props.tooldatatype == "Edit") {
      let data = { ...this.props.tooldata };
      this.setState({
        popupHeader: i18n.t("Edit Tool"),
        popupSaveButton: i18n.t("Update"),
        selectedTool: data,
        isEditable: this.handleEdit(data.DeploymentDate, data.ExpectedDeploymentDate),
        expectedDateEdit: !data.DeploymentDate ? true : false,
        deployDateEdit:
          (!data.DeploymentDate || data.DeploymentDate > new Date(data.ExpectedDeploymentDate)) &&
          data.ExpectedDeploymentDate
            ? true
            : data.DeploymentDate
            ? true
            : false,
      });
    } else {
      this.setState({
        popupHeader: i18n.t("Add Tool"),
        popupSaveButton: i18n.t("Add"),
      });
    }
  }

  handleSelectToolEvent = (e) => {
    if (e.target.value == "AddNewTool") {
      this.props.addNewToolToggler();
      this.props.modalClose();
    } else {
      let toolslist = this.state.toolslist;
      let selecttool = toolslist.filter((t) => t.ApplicationMappingID == e.target.value)[0];
      this.setState({
        selectedTool: selecttool,
        isEditable: this.handleEdit(selecttool.DeploymentDate, selecttool.ExpectedDeploymentDate),
        expectedDateEdit: !selecttool.DeploymentDate ? true : false,
        deployDateEdit:
          (!selecttool.DeploymentDate || selecttool.DeploymentDate > new Date(selecttool.ExpectedDeploymentDate)) &&
          selecttool.ExpectedDeploymentDate
            ? true
            : false,
      });
    }
  };

  handleEdit = (deploydate, expecteddate) => {
    if (!deploydate && expecteddate) {
      let exceptedDdate = expecteddate.substring(0, 10);
      let isEditable = exceptedDdate >= today ? true : false;
      return isEditable;
    } else if (!deploydate && !expecteddate) {
      return true;
    } else if (deploydate && !expecteddate) {
      return true;
    } else if (deploydate && expecteddate) {
      return true;
    } else if (deploydate === "Invalid date") {
      return true;
    } else {
      return false;
    }
  };

  handleDeploymentDateChange = (date) => {
    const data = this.state.selectedTool;
    if (
      moment(date)
        .format()
        .includes("+")
    ) {
      data.DeploymentDate = moment(date)
        .format()
        .split("+")[0];
    } else {
      data.DeploymentDate = moment(date).format();
    }

    this.setState({
      selectedTool: data,
    });
  };

  handleExpectedDeploymentDateChange = (date) => {
    const data = this.state.selectedTool;
    if (
      moment(date)
        .format()
        .includes("+")
    ) {
      data.ExpectedDeploymentDate = moment(date)
        .format()
        .split("+")[0];
    } else {
      data.ExpectedDeploymentDate = moment(date).format();
    }

    this.setState({
      selectedTool: data,
      isEditable: true,
    });
  };

  handleRadioOptionChange = (e) => {
    this.setState({
      selectedOption: e.target.value,
    });
  };
  handleToolFieldChange = (e) => {
    const id = e.target.id;
    let data = this.state.selectedTool;
    switch (id) {
      case "url":
        data.URL = e.target.value;
        break;
      case "description":
        data.ApplicationDescription = e.target.value;
        break;
      case "defaultname":
        data.DefaultUserID = e.target.value;
        break;
      case "BusinessBenefit":
        data.BusinessBenefit = e.target.value;
        break;
      case "Category":
        data.ApplicationCategory = e.target.value;
        break;
      case "ToolType":
        data.ToolType = e.target.value;
    }

    this.setState({
      selectedTool: data,
    });
  };
  handleAddToolClick = (event) => {
    event.preventDefault();
    /* if (this.validateForm()) { */
      this.setState({
showLoaderonPage:true
      });
      const data = this.state.selectedTool;
       data.DeploymentDate = data.DeploymentDate==="Invalid date"?null:data.DeploymentDate;
       data.ExpectedDeploymentDate = data.ExpectedDeploymentDate==="Invalid date"?null:data.ExpectedDeploymentDate;
      data.SOCategoryID = this.props.selectedSubStepID;
        let WO=[]
          WO.push(data)
      let currentData = {
        WO: WO,
        FilterParams: {
          OrgEntityID: this.props.filter.Client,
          OfferingID: this.props.filter.process,
          LocationID: this.props.filter.Locations,
          MonthDT: this.props.filter.Month,
        },
      };
      axiosInstance
        .post(`${LocalApiBaseUrl}Configurator/InsertUpdateWOToolsOnSteps`, currentData)
        .then((response) => {
          if (response.data) {
            this.setState({
              showLoaderonPage:false
            });
            axiosInstance
              .get(`${LocalApiBaseUrl}Configurator/GetWorkOrchestrationQualityScore`, {
                params: {
                  LocationID: this.props.filter.Locations,
                  OrgEntityID: this.props.filter.Client,
                  OfferingID: this.props.filter.process,
                  MonthDT: this.props.filter.Month,
                  LanguageID: store.getState().CurrentFilter.languageData.id,
                },
              })
              .then((response) => {
                if (response.data) {
                  store.dispatch({
                    type: "SAVE_QC_SCORE_OF_EACH_TAB",
                    payload: response.data,
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  showLoaderonPage:false
                });
                trycatchAlertPopup(error);
              });
            store.dispatch({ type: "EDIT_WO_TOOLS", payload: 
            {
              wostepdata:data,
              SOCategoryID:this.props.selectedSubStepID 
            }
           });
            this.props.modalClose();
          }
        })
        .catch((error) => {
          this.setState({
            showLoaderonPage:false
          });
          trycatchAlertPopup(error);
          this.props.modalClose();
        });
    /* } */
  };
  validateForm = () => {
    let isValid = false;
    let errorArr = [];
    const data = this.state.selectedTool;

    if (data.ApplicationName == undefined || data.ApplicationName.trim() == "") {
      errorArr[0] = i18n.t("ToolName_Required");
    } else {
      errorArr[0] = "";
    }
    if (data.URL != undefined && data.URL.trim() != "" && !regexForToolURL.test(data.URL.trim().toLowerCase())) {
      errorArr[1] = i18n.t("Invalid_URL");
    } else {
      errorArr[1] = "";
    }
    if (
      data.DeploymentDate != null &&
      data.DeploymentDate > new Date(data.ExpectedDeploymentDate) &&
      data.DeploymentDate != new Date(data.ExpectedDeploymentDate)
    ) {
      errorArr[2] = i18n.t("Deployment date cannot be greater than Expected deplyment date");

    } else {
      errorArr[2] = "";
    }
    if (regexForTextAreas.test(data.BusinessBenefit ? data.BusinessBenefit.trim() : "")) {
      errorArr[3] = "";
    } else {
      if (!data.BusinessBenefit) {
        errorArr[3] = "";
      } else {
        errorArr[3] = i18n.t("BusinessBenefit_SpecialChar");
        isValid = false;
      }
    }
    this.setState({
      toolErrors: {
        toolName: errorArr[0],
        url: errorArr[1],
        deploymentDate: errorArr[2],
        BusinessBenefit: errorArr[3],
      },
    });

    if (errorArr[0] == "" && errorArr[1] == "" && errorArr[2] == "" && errorArr[3] == "") {
      isValid = true;
    }
    return isValid;
  };

  render() {

    return (
      <>
        <LoadingOverlay
          className="custom-loader"
          fadeSpeed={0}
          spinner={<SynopsLoader />}
          active={this.state.showLoaderonPage}
        ></LoadingOverlay>

                <Modal
                    show={this.props.show}
                    onHide={this.props.modalClose}
                    className="tooldata-pop wo-tooldata-popup"
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.popupHeader} - {this.props.metricDesp}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="form-section tool-popup">
                                <div className="row">
                                    <div className="form-group col-lg-6">
                                        <label for="name">
                                            Tool Name<span className="required">*</span>
                                        </label>
                                        {this.props.tooldata != undefined &&
                                            this.props.tooldatatype == "Edit" ? (
                                                <input
                                                    className="form-control"
                                                    id="toolName"
                                                    value={this.state.selectedTool.ApplicationName}
                                                    disabled
                                                />
                                            ) : (
                                              {/*   <select
                                                    className="form-control"
                                                    value={
                                                        this.state.selectedTool
                                                            ? this.state.selectedTool.ApplicationMappingID
                                                            : null
                                                    }
                                                    onChange={this.handleSelectToolEvent}
                                                >
                                                    {this.state.toolslist.length > 0 ?
                                                        this.state.toolslist.map((tool) => (
                                                            <option
                                                                key={tool.ApplicationMappingID}
                                                                value={tool.ApplicationMappingID}
                                                            >
                                                                {tool.ApplicationName}
                                                            </option>
                                                        )) :
                                                        <option key="" value="-1">
                                                            {"<Select a Tool>"}
                                                        </option>
                                                    }
                                                    <option key="" value="AddNewTool">
                                                        {"<Add New>"}
                                                    </option>
                                                </select>
                                            */}
                                            )}
                                        <span className="errorMsg">
                                            {this.state.toolErrors.toolName}
                                        </span>
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label for="type">Tool Type</label>
                                        <select
                                            type="text"
                                            className="form-control"
                                            id="ToolType"
                                            value={this.state.selectedTool.ToolType}
                                            disabled={true}
                                            //disabled={this.props.tooldatatype == "Edit" && this.state.selectedTool.CustomToolID ? false : true}	
                                            onChange={(e)=>this.handleToolFieldChange(e)}	
                                        >	
                                        <option value="">{"<Select a Tooltype>"}</option>	
                                        <option value="AI">AI</option>	
                                        <option value="WF">WF</option>	
                                        <option value="AT">AT</option>	
                                        <option value="AA">AA</option>	
                                        </select>
                                    
                                    </div>
                                </div>
                                <div className="row">
                                    {/* <div className="form-group col-lg-6">
                                        <label for="level">Maturity Level</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="maturityLevel"
                                            disabled
                                        />
                                    </div> */}
                                    <div className="form-group col-lg-6">
                                        <label for="category">Category</label>
                                        <select	
                                            type="text"	
                                            className="form-control"	
                                            id="Category"	
                                            value={this.state.selectedTool.ApplicationCategory}	
                                            disabled={true}
                                            //disabled={this.props.tooldatatype == "Edit" && this.state.selectedTool.CustomToolID ? false : true}	
                                            onChange={(e)=>this.handleToolFieldChange(e)}	
                                        >	
                                        <option value="">{"<Select a Tool Category>"}</option>	
                                        <option value="Accenture">Accenture</option>	
                                        <option value="Client">Client</option>	
                                        </select>
                                    </div>
                                </div>

                <div className="row">
                  <div className="form-group col-lg-12">
                    <label for="url">Tool URL</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.selectedTool.URL}
                      id="url"
                      onChange={(e) => {
                        this.handleToolFieldChange(e);
                      }}
                      disabled={true}
                    />
                    <span className="errorMsg">{this.state.toolErrors.url}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-lg-12">
                    <label for="description">Description of the tool</label>
                    <input
                      type="text"
                      className="form-control"
                      id="description"
                      value={this.state.selectedTool.ApplicationDescription}
                      onChange={(e) => {
                        this.handleToolFieldChange(e);
                      }}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-lg-12">
                    <label for="">{i18n.t("Business_Benefit")}</label>
                    <textarea
                      id="BusinessBenefit"
                      rows="2"
                      name="Business_Benefit"
                      maxLength="255"
                      className="form-control"
                      onChange={(e) => {
                        this.handleToolFieldChange(e);
                      }}
                      disabled={true}
                      value={this.state.selectedTool.BusinessBenefit}
                    />
                    {/* <span className="errorMsg">{this.state.toolErrors.BusinessBenefit}</span> */}
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-lg-6">
                    <label className="form-check mr-4">
                      <input
                        type="radio"
                        value="DeploymentDate"
                        className="form-check-input"
                        checked={
                          this.state.selectedOption === "DeploymentDate" ||
                          (this.state.deployDateEdit && this.state.expectedDateEdit === false)
                        }
                        onChange={this.handleRadioOptionChange}
                      />
                      <span className="checkmark" />
                      <span className="form-check-label">Deployment Date</span>
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">Date when tool is deployed to the account</Tooltip>}
                      >
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </OverlayTrigger>
                    </label>
                    <label className="datepicker-label-wrapper">
                      <DatePicker
                        className="form-control"
                        id="DeploymentDate"
                        maxDate={currentDate}
                        dateFormat="dd/MM/yyyy"
                        selected={
                          Date.parse(this.state.selectedTool.DeploymentDate)
                            ? new Date(Date.parse(this.state.selectedTool.DeploymentDate))
                            : null
                        }
                        onChange={(date) => {
                          this.handleDeploymentDateChange(date);
                        }}
                        readOnly={this.state.deployDateEdit ? false : this.state.selectedOption !== "DeploymentDate"}
                      />
                      <FontAwesomeIcon className="calender-placment" icon={faCalendarAlt} />
                    </label>
                  </div>
                  <div className="form-group col-lg-6">
                    <label className="form-check mr-4">
                      <input
                        type="radio"
                        className="form-check-input"
                        value="ExpectedDate"
                        disabled={!this.state.expectedDateEdit}
                        checked={
                          this.state.selectedOption === "ExpectedDate" ||
                          (this.state.expectedDateEdit && this.state.selectedOption !== "DeploymentDate")
                        }
                        onChange={this.handleRadioOptionChange}
                      />
                      <span className="checkmark" />
                      <span className="form-check-label">Expected Deployment date</span>
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">Tool deployment planned date</Tooltip>}
                      >
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </OverlayTrigger>
                    </label>
                    <label className="datepicker-label-wrapper">
                      <DatePicker
                        className="form-control"
                        id="ExpectedDeploymentDate"
                        minDate={currentDate}
                        dateFormat="dd/MM/yyyy"
                        selected={
                          Date.parse(this.state.selectedTool.ExpectedDeploymentDate)
                            ? new Date(Date.parse(this.state.selectedTool.ExpectedDeploymentDate))
                            : null
                        }
                        onChange={(date) => {
                          this.handleExpectedDeploymentDateChange(date);
                        }}
                        readOnly={
                          (this.state.selectedOption !== "ExpectedDate" && !this.state.expectedDateEdit) ||
                          this.state.selectedOption === "DeploymentDate"
                        }
                      />
                      <FontAwesomeIcon className="calender-placment" icon={faCalendarAlt} />
                    </label>
                  </div>
                </div>
                <span className="errorMsg">{this.state.toolErrors.deploymentDate}</span>
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label for="defaultname">Default User ID</label>
                    <input
                      type="text"
                      className="form-control"
                      id="defaultname"
                      disabled={true}
                      value={this.state.selectedTool.DefaultUserID}
                      onChange={(e) => {
                        this.handleToolFieldChange(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-btn">
              <button onClick={this.props.modalClose} className="btn btn-default brder-2 btn-sm mr-2">
                {i18n.t("Cancel")}
              </button>

              <input
                type="Button"
                className="btn btn-primary btn-sm"
                value={this.state.popupSaveButton}
                onClick={(e) => this.handleAddToolClick(e)}
              />
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
