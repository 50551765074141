import { loginRequest,profilePicloginRequest,loginRequestPowerBi  } from "../msalConfig";
import { msalInstance } from "../index";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import axiosInstance from '../Shared/interceptor';
import {accessTokenstring,idTokenstring} from '../Shared/Constant';

//for demo purposes. 
//import jwt_decode from "jwt-decode";

export async function acquireNewTokenSilent(forceRefresh) {
    
let UserData={};
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    

    //always call the acquiretokensilent first before calling any API
    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        forceRefresh,
        account: account
    }).then((response)=>{
        UserData={...response};
        sessionStorage.setItem('EnterpriseID', response.account.username.split("@")[0]); /* For Local below code */
          sessionStorage.setItem(idTokenstring,response.idToken);
          sessionStorage.setItem(accessTokenstring,response.accessToken);
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${response.idToken}`;
    }).catch(err => {
        console.log("API CALL ERROR:",err);
        //as a fallback so that user does not go away from the page when acquiretokensilent fails, use acquireTokenPopup method
        //always check if the error is something that requires auth login interaction 
        if (err instanceof InteractionRequiredAuthError) {
            return msalInstance.acquireTokenPopup({
                ...loginRequest,
                account: msalInstance.getActiveAccount()
            }).catch(err => {
                console.log('Error getting popup token: ' + JSON.stringify(err))
            });
        }
    });

    await msalInstance.acquireTokenSilent({
        ...profilePicloginRequest,
        forceRefresh,
        account: account
    }).then((response)=>{
        sessionStorage.setItem("profilePicToken",response.accessToken);
    }).catch(err => {
        console.log("API CALL ERROR:",err);
        //as a fallback so that user does not go away from the page when acquiretokensilent fails, use acquireTokenPopup method
        //always check if the error is something that requires auth login interaction 
        if (err instanceof InteractionRequiredAuthError) {
            return msalInstance.acquireTokenPopup({
                ...profilePicloginRequest,
                account: msalInstance.getActiveAccount()
            }).catch(err => {
                console.log('Error getting popup token: ' + JSON.stringify(err))
            });
        }
    });

    //const headers = new Headers();
    //const bearer = `Bearer ${response.accessToken}`;

    //headers.append("Authorization", bearer);


    //return the access token for demo purposes. Do not include this on the release.Tokens should always be decoded in the API.
    //var tokendata = jwt_decode(bearer);

    return UserData;


}

export async function refreshPBI() {
    const account = msalInstance.getActiveAccount();

    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    msalInstance.acquireTokenSilent(loginRequestPowerBi).then((response) => {
    //    console.log("PowerBi token renewed with popup:" + JSON.stringify(response));
        localStorage.removeItem("access_token_powerbi");
        localStorage.setItem("access_token_powerbi", response.accessToken);
        // this.updatePBToken();
      }).catch(err => {
        //as a fallback so that user does not go away from the page when acquiretokensilent fails, use acquireTokenPopup method
        //always check if the error is something that requires auth login interaction 
        if (err instanceof InteractionRequiredAuthError) {
          return msalInstance.acquireTokenPopup(loginRequestPowerBi).then((response) => {
        //    console.log("PowerBi token renewed with popup:" + JSON.stringify(response));
            localStorage.removeItem("access_token_powerbi");
            if (isValidToken(response.accessToken)){
                localStorage.setItem("access_token_powerbi", response.accessToken);
            };
            // this.updatePBToken();
          }).catch(err => {
        //    console.log('Error getting popup token: ' + JSON.stringify(err))
          });
        }
      })
}