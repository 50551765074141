import React, { Component } from "react";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { Tabs, Tab, Modal, Button } from "react-bootstrap";
import EmptyCriteria from "../Images/EmptyCriteria.svg";
import AssessmentCriteria from "./AssessmentCriteria";
import Outcomes from "./Outcome";
import i18n from "i18next";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import InitiativeList from "./InitiativeList";
import InitiativeDraftList from "./InitiativeDraftList";
import AddEditInitiative from "./AddEditInitiative";
import IOJInitiativeModal from "./IOJInitiativeModal";
import SynopsLoader from "../Shared/SynopsLoader";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { InitializeInitiative, allSelected } from "../actions/JourneyActions";
import { store } from "../reducers/configureStore";
import _ from "lodash";
import $ from "jquery";
import { faClosedCaptioning } from "@fortawesome/free-solid-svg-icons";
import NotificationForAddEdit from "./NotificationForAddEdit";
import { isValidText,InvalidDescription } from "../Shared/commonFunctions";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


const addEditInitiativeFormEmptyProps = {
  isVisible: false,
  initiativeFormSubmittedAs: "", // "save" OR "saveDraft"

  formMode: "", // "add" or "edit"
  initiativeId: null, // is needed when the form is in "edit" mode to fetch the data from `initiativeId`
};
class JourneyInitiativeTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      specialChardescription:InvalidDescription(),
      isInitiaveDropdownStatus: false,
      maxOutcomeSequence: "",
      maxInitiativeSequence: "",
      initiativeStageId: 1,
      selectedTab: this.props.isInitativeManagement ? "initiative" : "assessment",
      initiativeStage: "Foundation",
      addAssessmentCriteriaStatus: true,
      selectedAssessmentCriteriaValue: undefined,
      addCancelCriteriaStatus: false,
      addAssessmentFormStatus: false,
      assessmentCriteriaListStatus: false,
      initiativeListStatus: this.props.isInitativeManagement,
      nodataFoundStatus: true,
      AssessmentData: [],
      InitiativeData: [],
      isEditAssessment: false,
      isApiLoading: false,
      isApiLoadingExpandedView: false,
      isInitativeApiloading: true,
      successNotificationModalStatus: false,
      closeAsssssmentTab: false,
      selectedAssessmentCriteria: "",
      editableAssessmentCriteriaValue: undefined,
      editableStatusCriteriaValue: "",
      editableAssessmentCriteria: "",
      configuratorStatus: false,
      IOJStageDATA: this.props.IOJGlidePathAllModels,
      IOJStageDesc: "",
      outComeListData: [],
      outComeSequenceData: [],
      initiativesSequenceData: [],
      editAssessmentData: {},
      outcomeErrorMessage: {},
      addOutcomeStatus: true,
      addEditOutcomeFormStatus: "",
      selectedOutcome: {
        OutcomeDesc_En: "",
        ID: 0,
      },
      errorMessageOutcome: "",
      showNotificationModal: false,
      message: "",
      errorMessageAssessment: "",
      errorMessageCustom: "",
      errorMessageStatus: "",
      enableDraftModalStatus: false,
      duplicateOutcomeExists: false,
      duplicateAssementExists: false,
      addEditInitiativeFormProps: _.cloneDeep(addEditInitiativeFormEmptyProps),
      filterChanged: false,
      formType: "",
    };
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.setState({
      closeAsssssmentTab: true,
      addCancelCriteriaStatus: false,
      addAssessmentCriteriaStatus: true,
      addAssessmentFormStatus: false,
      isApiLoading: false,
      errorMessageAssessment: "",
      errorMessageCustom: "",
      errorMessageStatus: "",
      isEditAssessment: false,
    });
  }

  addAssessmentCriteria = () => {
    this.setState({
      addAssessmentCriteriaStatus: true,
      addCancelCriteriaStatus: false,
      addAssessmentFormStatus: true,
      nodataFoundStatus: false,
      isEditAssessment: false,
      assessmentCriteriaListStatus: false,
      addEditInitiativeFormProps: _.cloneDeep(addEditInitiativeFormEmptyProps),
      editAssessmentData: {},
      selectedAssessmentCriteriaValue: "",
      selectedAssessmentCriteria: "",
      selectedStatusCriteriaValue: "",
    });
  };
  editAssessmentCriteria = (data) => {
    this.setState({
      addAssessmentCriteriaStatus: false,
      addCancelCriteriaStatus: true,
      addAssessmentFormStatus: true,
      nodataFoundStatus: false,
      assessmentCriteriaListStatus: false,
      isEditAssessment: true,
      editAssessmentData: data,
      updatedAssessmentCriteriaValue: data.CriteriaName,
      updatedAssessmentCriteriaId: data.IntelOpsAssessmentCriteriaID,
      updatedStatusAsessment: data.RAGStatus,
      addNewOptionStatus: data.addNewOptionStatus,
    });
  };

  async wrapperPostAssessmentApi(criteriaName) {
    let filterData = [this.props.currentFilter, this.props.languageData, this.props.clientData];
    let CriteriaName = criteriaName;
    let requestParameters = {
      CriteriaName: CriteriaName,
      OrgEntityID: filterData[2].id,
      LocationId: filterData[0].LocationId,
      OfferingId: filterData[0].ProcessId,
    };
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`${LocalApiBaseUrl}IOJJourneyInitiative/AddAssessmentCriteriaMaster`, requestParameters)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          reject(error);
        });
    });
  }

  // api call to update assessments
  async updatedAssessmentCriteriaList(criteriaName) {
    let duplicateAssementExists;
    const { editAssessmentData, initiativeStageId } = this.state;
    const { ConfiguratorID, clientData, currentFilter, isInitativeManagement } = this.props;

    let isWrapperResponseCalled = false;
    let wrapperResponse = {};

    this.setState({
      isApiLoading: true,
    });

    if (!editAssessmentData.IntelOpsAssessmentCriteriaID) {
      wrapperResponse = await this.wrapperPostAssessmentApi(criteriaName);
      isWrapperResponseCalled = true;
    }

    let requestParameters = {
      journeyData: {
        configuratorID: ConfiguratorID,
        iojStageID: initiativeStageId,
      },
      assessmentData: {
        id: editAssessmentData.ID, //ID
        intelOpsAssessmentCriteriaID: isWrapperResponseCalled
          ? wrapperResponse.ResponseID
          : editAssessmentData.IntelOpsAssessmentCriteriaID,
        ragStatus: editAssessmentData.RAGStatus,
      },
      filters: {
        orgEntityID: clientData.id,
        offeringID: currentFilter.ProcessId,
        offeringName: currentFilter.offeringName,
        locationID: currentFilter.LocationId,
        omid: parseInt(currentFilter.LocationOMID),

      },
    };

    axiosInstance
      .post(`${LocalApiBaseUrl}IOJJourneyInitiative/AddEditAssessmentCriteria`, requestParameters)
      .then((response) => {
        if (response.data.StatusMessage === "Assessment criteria already exists") {
          duplicateAssementExists = true;
        }
        this.setState({
          assessmentCriteriaListStatus: true,
          addCancelCriteriaStatus: false,
          addAssessmentCriteriaStatus: true,
          addAssessmentFormStatus: false,
          isApiLoading: false,
          isEditAssessment: false,
          editAssessmentData: {},
          selectedAssessmentCriteriaValue: "",
          selectedAssessmentCriteria: "",
          selectedStatusCriteriaValue: "",
          duplicateAssementExists: duplicateAssementExists,
        });

        this.showNotificationPopup(response.data.StatusMessage);
        if (!isInitativeManagement) {
          this.getAssessmentCriteria();
        }
      })
      .catch((error) => {
        this.setState({ isApiLoading: false });
        trycatchAlertPopup(error);
      });

  }

  // api call to add assessments
  async assessmentCriteriaList(criteriaName) {
    let duplicateAssementExists;
    const { initiativeStageId } = this.state;
    const { ConfiguratorID, clientData, currentFilter, isInitativeManagement } = this.props;

    let isWrapperResponseCalled = false;
    let wrapperResponse = {};

    this.setState({
      isApiLoading: true,
    });
    if (!this.state.selectedAssessmentCriteriaValue) {
      wrapperResponse = await this.wrapperPostAssessmentApi(criteriaName);
      isWrapperResponseCalled = true;
    }

    let requestParameters = {
      journeyData: {

        configuratorID: ConfiguratorID,
        iojStageID: initiativeStageId,

      },
      assessmentData: {
        id: 0,
        intelOpsAssessmentCriteriaID: isWrapperResponseCalled
          ? wrapperResponse.ResponseID
          : this.state.selectedAssessmentCriteriaValue, //Hard coded value
        ragStatus: this.state.selectedStatusCriteriaValue,
      },
      filters: {
        orgEntityID: clientData.id,
        offeringID: currentFilter.ProcessId,
        offeringName: currentFilter.offeringName,
        locationID: currentFilter.LocationId,
        omid: parseInt(currentFilter.LocationOMID),

      },
    };

    axiosInstance
      .post(`${LocalApiBaseUrl}IOJJourneyInitiative/AddEditAssessmentCriteria`, requestParameters)
      .then((response) => {
        if (response.data.StatusMessage === "Assessment criteria already exists") {
          duplicateAssementExists = true;
        }
        this.setState({
          assessmentCriteriaListStatus: true,
          intelOpsAssessmentCriteriaID: "",
          selectedStatusCriteriaValue: "",
          addCancelCriteriaStatus: false,
          addAssessmentCriteriaStatus: true,
          addAssessmentFormStatus: false,
          isApiLoading: false,
          editAssessmentData: {},
          selectedAssessmentCriteriaValue: "",
          selectedAssessmentCriteria: "",
          duplicateAssementExists: duplicateAssementExists,
        });

        this.showNotificationPopup(response.data.StatusMessage);
        if (!isInitativeManagement) {
          this.getAssessmentCriteria();
        }
      })
      .catch((error) => {
        this.setState({ isApiLoading: false });
        trycatchAlertPopup(error);
      });

  }

  validateModal = () => {
    const {
      isEditAssessment,
      editAssessmentData,
      selectedStatusCriteriaValue,
      selectedAssessmentCriteria,
    } = this.state;
    if (isEditAssessment) {
      if (
        !editAssessmentData.CriteriaName ||
        editAssessmentData.CriteriaName == "<Add New>" ||
        !editAssessmentData.RAGStatus
      ) {
        if (!editAssessmentData.CriteriaName) {
          this.setState({
            errorMessageAssessment: "Assessment Criteria is Required",
          });
        }
        if (!editAssessmentData.addNewOptionStatus) {
          this.setState({
            errorMessageCustom: "Custom Assessment is Required",
          });
        }
        if (!editAssessmentData.RAGStatus) {
          this.setState({
            errorMessageStatus: "Assessment Criteria Status is Required",
          });
        }
      } else {
        this.updatedAssessmentCriteriaList(editAssessmentData.CriteriaName);
      }
    } else {
      if (
        selectedAssessmentCriteria == "" ||
        selectedAssessmentCriteria == "<Add New>" ||
        selectedStatusCriteriaValue == ""
      ) {
        if (selectedAssessmentCriteria == "") {
          this.setState({
            errorMessageAssessment: "Assessment Criteria is Required",
          });
        }
        if (selectedAssessmentCriteria == "<Add New>") {
          this.setState({
            errorMessageCustom: "Custom Assessment is Required",
          });
        }
        if (selectedStatusCriteriaValue == "") {
          this.setState({
            errorMessageStatus: "Assessment Criteria Status is Required",
          });
        }
      } else {


        this.assessmentCriteriaList(this.state.selectedAssessmentCriteria);
      }
    }
  };

  handleChangeSelectedStatusCriteria = (selectedData) => {
    let { isEditAssessment, editAssessmentData } = this.state;
    if (isEditAssessment) {
      editAssessmentData.RAGStatus = selectedData;
      this.setState({
        editAssessmentData: editAssessmentData,
      });
      if (selectedData.length) {
        this.setState({
          errorMessageStatus: "",
        });
      }
    } else {
      this.setState({
        selectedStatusCriteriaValue: selectedData,
      });
      if (selectedData.length) {
        this.setState({
          errorMessageStatus: "",
        });
      }
    }
  };

  handleChangeSelectedAssesmentCriteria = (assessmentId, assessmentValue) => {
    let { isEditAssessment, editAssessmentData, errorMessageCustom } = this.state;
    if (isEditAssessment) {
      editAssessmentData.IntelOpsAssessmentCriteriaID = assessmentId;
      editAssessmentData.CriteriaName = assessmentValue;
      this.setState({ editAssessmentData: editAssessmentData, errorMessageAssessment: "", errorMessageCustom: "" });
    } else {
      this.setState({
        selectedAssessmentCriteriaValue: assessmentId,
        selectedAssessmentCriteria: assessmentValue,
        errorMessageAssessment: "",
        errorMessageCustom: "",
      });
    }
  };
  addCancelCriteria = () => {
    this.setState({ addAssessmentCriteriaStatus: false, addCancelCriteriaStatus: true });
  };
  isInitiaveDropdown = () => {
    this.setState({ isInitiaveDropdownStatus: true });
  };
  isInitiaveDropdownOverlay = () => {
    this.setState({ isInitiaveDropdownStatus: false });
  };
  onClickFetchInitiative = (selectedData) => {
    const { isInitativeManagement } = this.props
    if (this.state.initiativeStage != selectedData.IOJStageName_En) {
      this.setState(
        {
          initiativeStage: selectedData.IOJStageName_En,
          initiativeStageId: selectedData.IOJStageID,
          IOJStageDesc: selectedData.IOJStageDesc,
          addAssessmentFormStatus: false, // Grid View for Assessment Criteria
          addAssessmentCriteriaStatus: true,
          addOutcomeStatus: true, // Grid View for Outcome
          addEditOutcomeFormStatus: "",
          errorMessageAssessment: "",
          errorMessageCustom: "",
          errorMessageStatus: "",
          errorMessageOutcome: "",
        },
        () => {
          if (!isInitativeManagement) {
            this.getAssessmentCriteria();
            this.getOutcomeList();
          }
          this.fetchJourneyInitiativeData();
          if (selectedData.IOJStageName_En === "All") {
            store.dispatch(allSelected(true));
          } else {
            store.dispatch(allSelected(false));
          }
        }
      );
    }

    setTimeout(() => {
      this.isInitiaveDropdownOverlay();
    }, 100);
  };
  setKey = (k) => {
    let listInitiative = false;
    let addAssessmentCriteriaStatus = false;
    let addOutcomeStatus = false;
    let addEditOutcomeFormStatus = this.state.addEditOutcomeFormStatus;
    let selectedOutcome = this.state.selectedOutcome;
    let duplicateOutcomeExists;
    if (k == "initiative") {
      listInitiative = true;
    }
    if (k == "assessment") {
      addAssessmentCriteriaStatus = true;
      duplicateOutcomeExists = false;
    }
    if (k == "outcome") {
      addOutcomeStatus = true;
      addEditOutcomeFormStatus = "";
      selectedOutcome = {
        OutcomeDesc_En: "",
        ID: 0,
      };
    }
    this.setState({
      selectedTab: k,
      initiativeListStatus: listInitiative,
      addEditInitiativeFormProps: _.cloneDeep(addEditInitiativeFormEmptyProps),
      addAssessmentCriteriaStatus,
      addOutcomeStatus: addOutcomeStatus,
      addEditOutcomeFormStatus,
      selectedOutcome,
      addAssessmentFormStatus: false,
      duplicateOutcomeExists: duplicateOutcomeExists,
    });
  };

  settingfilterChangedState = () => {
    this.setState({
      filterChanged: false,
    });
  };
  settingFilterChangedTrue = () => {
    this.setState({
      filterChanged: true,
    });
  };
  isEmptyObject = (objectData) => {
    return Object.entries(objectData).length === 0
  }
  componentDidUpdate(prevProps, prevState) {
    const { dataStatus, currentFilter, filter, isAutoMigrateAPILoading, isInitativeManagement, Datas } = this.props;
    const { selectedTab, initiativeStage, initiativeStageId, addEditInitiativeFormProps } = this.state;
    let filterDetails = Datas.filterDetails;

    if (isInitativeManagement && filterDetails && !this.isEmptyObject(filterDetails) && !addEditInitiativeFormProps.isVisible) {
      let stageFilter = filterDetails.stageFilter;
      let modifiedStageData = stageFilter.modifiedStageData;
      let Stage = modifiedStageData.filter(a => a.checked)[0];
      if (Stage !== undefined && Stage.ID !== initiativeStageId) {
        this.setState({
          initiativeStage: Stage.Name,
          initiativeStageId: Stage.ID
        })
      }
      /* if(modifiedStageData!==undefined && modifiedStageData.length>0){
        const isDisableAdd=modifiedStageData.filter(a=>a.checked).length!==1;
        this.disableAddInitiative(isDisableAdd);
      } */
    }

    if (prevProps.dataStatus !== dataStatus) {
      this.setState({
        addEditInitiativeFormProps: _.cloneDeep(addEditInitiativeFormEmptyProps),
        addAssessmentFormStatus: false,
        addEditOutcomeFormStatus: "",
        addOutcomeStatus: true,
        filterChanged: false,
      });
    }


    // New Method- After calling Auto Migrate API is called, Outcome,Initatives,AssessmentCriteria is called
    if (prevProps.isAutoMigrateAPILoading !== isAutoMigrateAPILoading) {
      if (!isAutoMigrateAPILoading) {

        this.setState(
          {
            addOutcomeStatus: true,
            addAssessmentFormStatus: false,
            initiativeListStatus: true,
            addEditInitiativeFormProps: _.cloneDeep(addEditInitiativeFormEmptyProps),
            filterChanged: true,
          },
          () => {
            if (!isInitativeManagement) {
              this.getOutcomeList();
              this.getAssessmentCriteria();
            }
            this.fetchJourneyInitiativeData();
          }
        );

      }
    }

    // WHEN THE TAB SELECTION IS CHANGED OR JOURNEY STAGE IS CHANGED OR FILTERS ARE CHANGED THEN RESET ALL THE PROPS OF THE ADD/EDIT INITIATIVE FORM AND SHOW THE INITIATIVE LISTING IF THE `selectedTab` = "initiative"
    if (isInitativeManagement) {
      if (
        prevState.selectedTab !== selectedTab ||
        prevProps.currentFilter !== currentFilter ||
        prevProps.filter !== filter
      ) {
        this.setState({
          addEditInitiativeFormProps: _.cloneDeep(addEditInitiativeFormEmptyProps),
          initiativeListStatus: selectedTab === "initiative",
          filterChanged: true,
        });
      }
    }
    else {
      if (
        prevState.selectedTab !== selectedTab ||
        prevState.initiativeStage !== initiativeStage ||
        prevProps.currentFilter !== currentFilter ||
        prevProps.filter !== filter
      ) {
        this.setState({
          addEditInitiativeFormProps: _.cloneDeep(addEditInitiativeFormEmptyProps),
          initiativeListStatus: selectedTab === "initiative",
          filterChanged: true,
        });
      }
    }

    if (prevProps.currentFilter !== currentFilter || prevProps.filter !== filter) {
      this.setState({
        filterChanged: true,
      });
    }

    if (prevState.selectedTab !== selectedTab) {
      this.setState(
        {
          errorMessageOutcome: "",
          filterChanged: true,
        },
        () => {
          if (selectedTab === "outcome") {
            this.getOutcomeList();
          }

          // this.fetchJourneyInitiativeData();
        }
      );
    }
  }

  fetchJourneyInitiativeData = () => {
    const { Client, Locations, Month, offering, process } = this.props.filterDetails;

    let configuratorStatus = this.props.history.location.pathname === "/admin/Configurator" ? true : false;
    const { languageData, currentFilter, clientData, isInitativeManagement } = this.props;
    let { initiativeStageId } = this.state;
    initiativeStageId = isInitativeManagement ? 5 : initiativeStageId;
    this.setState({
      isApiLoadingExpandedView: true,
    });
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(
          `${LocalApiBaseUrl}IOJJourneyInitiative/GetExpandedViewData?IOJStageID=${initiativeStageId}&LanguageId=${languageData.id}`,
          {
            OrgEntityID: clientData.id,
            OfferingID: currentFilter.ProcessId === "All" ? 0 : currentFilter.ProcessId,
            LocationID: currentFilter.LocationId === "All" ? 0 : currentFilter.LocationId,
            OMID: currentFilter.LocationOMID[0] === "All" ? 0 : currentFilter.LocationOMID[0],
            OfferingName: currentFilter.OfferingName ? currentFilter.OfferingName : "All",
            IsConfigurator: configuratorStatus,
          }
        )
        .then((response) => {
          store.dispatch(InitializeInitiative(response.data));
          this.setState({
            isApiLoadingExpandedView: false,
            isInitativeApiloading: false,
            isApiLoading: false,
          });
          resolve();
        })
        .catch((error) => {
          this.setState({
            isApiLoadingExpandedView: false,
            isInitativeApiloading: false,
            isApiLoading: false,
          });
          trycatchAlertPopup(error);
          reject(error);
        });
    });
  };

  getOutcomeList = () => {
    const { currentFilter, languageData, clientData, history, filter } = this.props;
    const { initiativeStageId } = this.state;
    this.setState({
      isApiLoading: true,
    });

    const { Client, Locations, Month, offering, process } = this.props.filterDetails;
    let requestParameters = {
      orgEntityID: clientData.id,
      offeringID: currentFilter.ProcessId === "All" ? 0 : currentFilter.ProcessId, // offeringName: currentFilter.OfferingId === "All"? "All": filter.offeringName,
      offeringName: currentFilter.OfferingName ? currentFilter.OfferingName : "All",
      locationID: currentFilter.LocationId === "All" ? 0 : currentFilter.LocationId,
      omid: currentFilter.LocationOMID[0] === "All" ? 0 : currentFilter.LocationOMID[0],
    };
    history.location.pathname === "/admin/Configurator"
      ? axiosInstance
        .post(
          `${LocalApiBaseUrl}IOJJourneyInitiative/GetConfiguratorOutcomes?IOJStageID=${initiativeStageId}&LanguageId=${languageData.id}`,
          requestParameters
        )
        .then((response) => {
          this.setState({
            outComeListData: response.data,
            isApiLoading: false,
          });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        })
      : axiosInstance
        .post(
          `${LocalApiBaseUrl}IOJJourneyInitiative/GetFrontDoorOutcomes?IOJStageID=${initiativeStageId}&languageId=${languageData.id}`,
          requestParameters
        )
        .then((response) => {
          this.setState({
            outComeListData: response.data,
            isApiLoading: false,
          });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    let { initiativeStage, selectedTab } = this.state;
    let { Datas } = nextProps;
    let addEditOutcomeFormStatus = !Datas.isAccess ? this.state.addEditOutcomeFormStatus : "";

    this.setState({
      isAccess: Datas.isAccess,
      // addEditOutcomeFormStatus,
      //addAssessmentFormStatus
    });
    if (!nextProps.isStage) {
      let stage =
        Object.keys(nextProps.previousSelectedInitiative).length != 0
          ? nextProps.previousSelectedInitiative.MaturityPhase
          : nextProps.selectedPhase;

      let IOJStageDesc =
        nextProps.IOJGlidePathAllModels.filter((each) => each.IOJStageName_En == initiativeStage).length > 0
          ? nextProps.IOJGlidePathAllModels.filter((each) => each.IOJStageName_En == initiativeStage)[0].IOJStageDesc
          : "";
      let IOJStatgeID =
        nextProps.IOJGlidePathAllModels.filter((each) => each.IOJStageName_En == initiativeStage).length > 0
          ? nextProps.IOJGlidePathAllModels.filter((each) => each.IOJStageName_En == initiativeStage)[0].IOJStageID
          : "";

      this.setState({
        IOJStageDATA: nextProps.IOJGlidePathAllModels,
        initiativeStage: initiativeStage,
        InitiativeData:
          IOJStatgeID === 5
            ? nextProps.allInitiativeData
            : nextProps.allInitiativeData.filter((a) => a.IOJStageID === IOJStatgeID),
        IOJStageDesc,
        initiativeStageId: IOJStatgeID,
        selectedTab,
      });
    }
  }
  componentDidMount() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    const { selectedPhase, selectedTab, isStage, Datas, isInitativeManagement } = this.props;
    let stage =
      Object.keys(this.props.previousSelectedInitiative).length != 0
        ? this.props.previousSelectedInitiative.MaturityPhase_En
        : selectedPhase;

    let IOJStageDesc =
      this.props.IOJGlidePathAllModels.filter((each) => each.IOJStageName_En == stage).length > 0
        ? this.props.IOJGlidePathAllModels.filter((each) => each.IOJStageName_En == stage)[0].IOJStageDesc
        : "";
    let IOJStatgeID =
      this.props.IOJGlidePathAllModels.filter((each) => each.IOJStageName_En == stage).length > 0
        ? this.props.IOJGlidePathAllModels.filter((each) => each.IOJStageName_En == stage)[0].IOJStageID
        : "";

    this.setState({
      IOJStageDATA: this.props.IOJGlidePathAllModels,
      initiativeStage: stage,
      IOJStageDesc,
      isAccess: Datas.isAccess,
    });
    let defaultTab = "";
    let initiativeData = [];
    let configuratorStatus = this.props.history.location.pathname === "/admin/Configurator" ? true : false;

    if (isStage !== null && isStage !== undefined) {
      if (isStage) {
        this.setState({
          initiativeListStatus: true,
        });
      }
    }
    if (configuratorStatus) {
      initiativeData = this.props.allInitiativeData;
    } else {
      initiativeData = this.props.allInitiativeData;
    }

    if (selectedTab === "Assesment Criteria") {
      defaultTab = "assessment";
    } else if (selectedTab === "Initiatives") {
      defaultTab = "initiative";
    } else {
      defaultTab = "outcome";
    }

    this.setState(
      {
        //initiativeStage: stage,
        selectedTab: isInitativeManagement ? "initiative" : defaultTab,
        initiativeListStatus: defaultTab === "initiative" || isInitativeManagement ? true : false,
        IOJStageDesc,
        InitiativeData: initiativeData,
        configuratorStatus,
        initiativeStageId: isInitativeManagement ? 5 : IOJStatgeID,
      },
      () => {
        if (!isInitativeManagement) {
          this.getAssessmentCriteria();
          this.getOutcomeList();
        }
        this.fetchJourneyInitiativeData();
      }
    );
  }
  getAssessmentCriteria = () => {
    const { currentFilter, languageData, clientData, history, IOJGlidePathAllModels, filter } = this.props;
    const { initiativeStageId } = this.state;
    this.setState({
      isApiLoading: true,
    });
    if (history.location.pathname === "/admin/Configurator") {
      let requestParameters = {
        orgEntityID: clientData.id,
        offeringID: currentFilter.ProcessId === "All" ? 0 : currentFilter.ProcessId,
        offeringName: filter.offeringName ? filter.offeringName : "All",
        locationID: currentFilter.LocationId === "All" ? 0 : currentFilter.LocationId,
        omid: currentFilter.LocationOMID[0] === "All" ? 0 : parseInt(currentFilter.LocationOMID),
      };
      axiosInstance
        .post(
          `${LocalApiBaseUrl}IOJJourneyInitiative/GetAllAssessmentCriteriaR2New?LanguageId=${languageData.id}&IOJStageID=${initiativeStageId}`,
          requestParameters
        )
        .then((response) => {
          if (response.data) {
            this.setState({
              AssessmentData: response.data,
              isApiLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({ isApiLoading: false });
          trycatchAlertPopup(error);
        });
    } else {
      let requestParameters = {
        orgEntityID: clientData.id,
        offeringID: currentFilter.ProcessId === "All" ? 0 : currentFilter.ProcessId,
        offeringName: filter.offeringName ? filter.offeringName : "All",
        locationID: currentFilter.LocationId === "All" ? 0 : currentFilter.LocationId,
        omid: currentFilter.LocationOMID[0] === "All" ? 0 : parseInt(currentFilter.LocationOMID),
      };
      axiosInstance
        .post(
          `${LocalApiBaseUrl}IOJJourneyInitiative/GetAllAssessmentCriteriaR2FrontDoor?LanguageId=${languageData.id}&IOJStageID=${initiativeStageId}`,
          requestParameters
        )
        .then((response) => {
          if (response.data) {
            this.setState({
              AssessmentData: response.data,
              isApiLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({ isApiLoading: false });
          trycatchAlertPopup(error);
        });
    }
  };

  submitAdd = () => {
    const { initiativeStageId, selectedOutcome } = this.state;
    const { ConfiguratorID, currentFilter, languageData, clientData, filter } = this.props;
    let duplicateOutcomeExists;
    if (this.validateOutComeForm()) {
      this.setState({
        isApiLoading: true,
      });
      let requestParameters = {
        journeyData: {
          ConfiguratorID: ConfiguratorID,
          IOJStageID: initiativeStageId,
        },
        outcomeData: {
          ID: selectedOutcome.ID,
          OutcomeDesc: selectedOutcome.OutcomeDesc_En,
          Sequence: selectedOutcome.Sequence
        },
        filters: {
          orgEntityID: clientData.id,
          offeringID: currentFilter.ProcessId === "All" ? 0 : currentFilter.ProcessId,

          offeringName: currentFilter.OfferingName ? currentFilter.OfferingName : "All",
          locationID: currentFilter.LocationId === "All" ? 0 : currentFilter.LocationId,
          OMID: currentFilter.LocationOMID[0] === "All" ? 0 : currentFilter.LocationOMID[0],
        },
      };
      axiosInstance
        .post(`${LocalApiBaseUrl}IOJJourneyInitiative/AddEditIOJOutcomes`, requestParameters)
        .then((response) => {
          if (response.data.StatusMessage === "Outcome with same name already exists") {
            duplicateOutcomeExists = true;
          }
          this.setState({
            addOutcomeStatus: false,
            isApiLoading: false,
            duplicateOutcomeExists: duplicateOutcomeExists,
          });
          if (response.data.IsSuccess) {
            this.getOutcomeList();
            this.onCloseOutcome();
          }


          this.showNotificationPopup(response.data.StatusMessage);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };
  submitEdit = () => {
    const { initiativeStageId, selectedOutcome } = this.state;
    const { ConfiguratorID, currentFilter, languageData, clientData, filter } = this.props;
    let duplicateOutcomeExists;
    if (this.validateOutComeForm()) {
      this.setState({
        isApiLoading: true,
      });
      let requestParameters = {
        journeyData: {
          ConfiguratorID: ConfiguratorID,
          IOJStageID: initiativeStageId,
        },
        outcomeData: {
          ID: selectedOutcome.OutComeID,
          OutcomeDesc: selectedOutcome.OutcomeDesc_En,
          Sequence: selectedOutcome.Sequence
        },
        filters: {
          orgEntityID: clientData.id,
          offeringID: currentFilter.ProcessId === "All" ? 0 : currentFilter.ProcessId,

          offeringName: currentFilter.OfferingName ? currentFilter.OfferingName : "All",
          locationID: currentFilter.LocationId === "All" ? 0 : currentFilter.LocationId,
          OMID: currentFilter.LocationOMID[0] === "All" ? 0 : currentFilter.LocationOMID[0],
        },
      };
      axiosInstance
        .post(`${LocalApiBaseUrl}IOJJourneyInitiative/AddEditIOJOutcomes`, requestParameters)
        .then((response) => {
          if (response.data.StatusMessage === "Outcome with same name already exists") {
            duplicateOutcomeExists = true;
          }
          this.setState({
            addOutcomeStatus: false,
            isApiLoading: false,
            duplicateOutcomeExists: duplicateOutcomeExists,
          });
          if (response.data.IsSuccess) {
            this.getOutcomeList();
            this.onCloseOutcome();
          }


          this.showNotificationPopup(response.data.StatusMessage);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };

  validateOutComeForm = () => {
    let formValid = true;
    const{specialChardescription}=this.state;
    let data = this.state;
    let outcomeTrimData = data.selectedOutcome.OutcomeDesc_En;
    if (!_.isEmpty(data.selectedOutcome.OutcomeDesc_En)) {
      outcomeTrimData = data.selectedOutcome.OutcomeDesc_En.trim();
    }
    if (outcomeTrimData == "" || outcomeTrimData == null) {
      formValid = false;
      data.errorMessageOutcome = "Outcome is required";
    }
    else{
  if(!isValidText(outcomeTrimData.trim())){
      formValid = false;
      data.errorMessageOutcome = specialChardescription;
}
    }
    this.setState({
      errorMessageOutcome: data.errorMessageOutcome,
    });


    return formValid;
  };
  GetMaxSequence = async (isInitiative) => {
    let maxSequence = "";
    const { initiativeStageId } = this.state;
    const { JourneyData } = this.props
    let ConfigurationIntelOpsRoadmapID = JourneyData.ConfigurationIntelOpsRoadmapID;
    
    if (ConfigurationIntelOpsRoadmapID !== undefined && ConfigurationIntelOpsRoadmapID !== 0
      && initiativeStageId != 0) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`${LocalApiBaseUrl}IOJJourneyInitiative/GetMaxSequence`, {
            params: {
              RoadmapId: ConfigurationIntelOpsRoadmapID,
              StageID: parseInt(initiativeStageId),
              isInitiative: isInitiative,
            }
          })
          .then((response) => {
            if (response.data) {
              resolve(parseInt(response.data));
            }
          })
          .catch((error) => {
            reject(error);
            trycatchAlertPopup(error);
          });
      });
    }
  };
  genarateArrayRange = (start, end) => {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
  }
  addOutcomes = async () => {
    let maxSequence = await this.GetMaxSequence(false);
    let outComeSequenceData = this.genarateArrayRange(1, maxSequence);
    outComeSequenceData = _.map(outComeSequenceData, (eachItem) => ({
      label: eachItem,
      label_en: eachItem,
      value: String(eachItem),
    }));
    this.setState({
      addOutcomeStatus: false,
      addEditOutcomeFormStatus: "Add",
      maxOutcomeSequence: maxSequence,
      outComeSequenceData: outComeSequenceData
    });
  };
  editOutcome = async (data) => {
    this.setState({ isApiLoading: true })
    let newData = { ...data };
    let newDAT = { ...data };
    let maxSequence = await this.GetMaxSequence(false);
    let outComeSequenceData = this.genarateArrayRange(1, maxSequence);
    outComeSequenceData = _.map(outComeSequenceData, (eachItem) => ({
      label: eachItem,
      label_en: eachItem,
      value: String(eachItem),
    }));
    this.setState({
      outComeSequenceData: outComeSequenceData,
      selectedOutcome: newData,
      addEditOutcomeFormStatus: "Edit",
      addOutcomeStatus: false,
      isApiLoading: false
    });
  };
  onCloseOutcome = () => {
    this.setState({
      selectedOutcome: {},
      addEditOutcomeFormStatus: "",
      addOutcomeStatus: true,
      errorMessageOutcome: "",
    });
  };
  handleSelectSequenceDropdownOption = (value) => {
    let data = this.state;
    data.selectedOutcome.Sequence = String(value);
    this.setState({
      selectedOutcome: data.selectedOutcome
    })
  }
  addOutcomeDataChange = (name) => {
    let data = this.state;
    data.selectedOutcome.OutcomeDesc_En = name;
    this.setState({
      selectedOutcome: data.selectedOutcome,
      errorMessageOutcome: "",
    });
  };
  addOutcomeDataChange = (name) => {
    let data = this.state;
    data.selectedOutcome.OutcomeDesc_En = name;
    this.setState({
      selectedOutcome: data.selectedOutcome,
      errorMessageOutcome: "",
    });
  };
  closeNotificationModal = () => {
    this.setState(
      {
        showNotificationModal: false,
        message: "",
      },
      () => {

      }
    );
  };
  showNotificationPopup = (message) => {
    this.setState({
      showNotificationModal: true,
      message,
    });
  };
  enableDraftModal = () => {
    this.setState({
      enableDraftModalStatus: true,
    });
  };
  disableDraftModalStatus = () => {
    this.setState({
      enableDraftModalStatus: false,
    });
  };
  ///////////////////////////////////////////////////////////////////
  showInitiativeForm = async ({ formMode, initiativeId, formType, stageName, IOJStageID }) => {
    const { initiativeStage, initiativeStageId } = this.state
    let initiativesSequenceData = [];
    if (initiativeStageId !== 0 && !_.isEmpty(String(initiativeStageId))) {
      let maxSequence = await this.GetMaxSequence(true);
      initiativesSequenceData = this.genarateArrayRange(1, maxSequence);
      initiativesSequenceData = _.map(initiativesSequenceData, (eachItem) => ({
        label: eachItem,
        label_en: eachItem,
        value: String(eachItem),
      }));
    }
    this.setState({
      initiativesSequenceData: initiativesSequenceData,
      initiativeStage: stageName !== undefined ? stageName : initiativeStage,
      initiativeStageId: IOJStageID !== undefined ? IOJStageID : initiativeStageId,
      addEditInitiativeFormProps: {
        isVisible: true,
        initiativeFormSubmittedAs: "",

        formMode,
        initiativeId,
      },
      initiativeListStatus: false,
      enableDraftModalStatus: false,
      formType,
    });
  };

  // HIDE THE ADD/EDIT INITIATIVE FORM AND DISPLAY THE LISTING
  handleClickCancelInitiativeForm = () => {
    this.setState({
      addEditInitiativeFormProps: _.cloneDeep(addEditInitiativeFormEmptyProps),
      initiativeListStatus: true,
    });
  };

  GetIOJQcscore = () => {
    axiosInstance
      .get(`${LocalApiBaseUrl}Configurator/GetIntelligentOperationsJourneyQualityScore`, {
        params: {
          LocationID: this.props.filterDetails.Locations,
          OrgEntityID: this.props.filterDetails.Client,
          OfferingID: this.props.filterDetails.process,
          OMID:
            this.props.filterDetails.LocationOMID.length === 0 || this.props.filterDetails.LocationOMID[0] === "All"
              ? 0
              : this.props.filterDetails.LocationOMID[0],
          LanguageID: store.getState().CurrentFilter.languageData.id,
        },
      })
      .then((response) => {
        if (response.data) {
          store.dispatch({
            type: "SAVE_QC_SCORE_OF_EACH_TAB",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  handleClickSubmitInitiativeForm = (formSubmitMode) => {

    const { addEditInitiativeFormProps } = this.state;
    this.setState({
      addEditInitiativeFormProps: {
        ...addEditInitiativeFormProps,
        initiativeFormSubmittedAs: formSubmitMode,
      },
    });
  };

  resetInitiativeFormSubmittedAs = () => {
    this.handleClickSubmitInitiativeForm("");
  };

  handleAfterSubmittedInitiativeForm = () => {
    this.setState(
      {
        addEditInitiativeFormProps: _.cloneDeep(addEditInitiativeFormEmptyProps),
      },
      () => {
        this.setState(
          {
            initiativeListStatus: true,
          },
          () => {
            this.fetchJourneyInitiativeData();
            this.props.getGlidePathData();
          }
        );
      }
    );
  };

  disableRoadMapView = () => {
    if (this.state.IOJStageDATA.find((a) => a.IOJStageDesc === "All")) {
      this.state.IOJStageDATA.pop();
    }
    store.dispatch(allSelected(false));
    this.props.disableRoadMapView();
  };
  disableAddInitiative = ({ isDisable, StageData }) => {
    const { disableInitiative, initiativeStageId } = this.state;
    const { isInitativeManagement } = this.props;
    if (isInitativeManagement) {
      if (disableInitiative !== isDisable) {
        if (StageData.length > 0) {
          let Stage = StageData.filter(a => a.checked)[0];
          if (Stage !== undefined && Stage.ID !== initiativeStageId) {
            this.setState({
              initiativeStage: isDisable ? "" : Stage.Name,
              initiativeStageId: isDisable ? 0 : Stage.ID
            })
          }
        }
        else {
          this.setState({
            initiativeStage: "",
            initiativeStageId: 0
          })
        }
        this.setState({
          disableInitiative: isDisable
        });
      }
    }
  }
  SequenceChanged = (outComeList) => {
    this.setState({
      outComeListData: outComeList,
    });
  }

  render() {
    const {
      IOJStageDATA,
      IOJStageDesc,
      configuratorStatus,
      AssessmentData,
      outComeListData,
      initiativeStage,
      addAssessmentFormStatus,
      InitiativeData,
      initiativeListStatus,
      isApiLoading,
      isApiLoadingExpandedView,
      isInitativeApiloading,
      selectedTab,
      addAssessmentCriteriaStatus,
      addOutcomeStatus,
      addEditOutcomeFormStatus,
      selectedOutcome,
      errorMessageOutcome,
      showNotificationModal,
      message,
      errorMessageCustom,
      errorMessageAssessment,
      errorMessageStatus,
      enableDraftModalStatus,
      addEditInitiativeFormProps,
      initiativeStageId,
      duplicateOutcomeExists,
      duplicateAssementExists,
      formType,
      disableInitiative,
      maxOutcomeSequence,
      maxInitiativeSequence,
      outComeSequenceData,
      initiativesSequenceData
    } = this.state;
    const { history, disableRoadMapView, isAccess, Suboffering, currentFilter, OMIDEnabled, isInitativeManagement } = this.props;
    const draftList =
      initiativeStage != undefined
        ? this.props.allInitiativeData.filter((each) => each.StageName_En == initiativeStage && each.IsDraft === true)
        : this.props.allInitiativeData.filter(
          (each) => each.StageName_En == this.props.Datas.viewData.MaturityPhase_En && each.IsDraft === true
        );

    if (window.location.pathname === "/Dashboard") {
      if (!IOJStageDATA.find((a) => a.IOJStageDesc === "All")) {
        IOJStageDATA.push({
          IOJStageDesc: "All",
          IOJStageDesc_En: "All",
          IOJStageID: 5,
          IOJStageName: "All",
          IOJStageName_En: "All",
        });
      }
    } else {
      if (IOJStageDATA.find((a) => a.IOJStageDesc === "All")) {
        IOJStageDATA.pop();
      }
    }

    return (
      <>
        {isApiLoading || isApiLoadingExpandedView ? (
          <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />
        ) : (
          ""
        )}
        <div className="initiative-heading">
          {!isInitativeManagement && (
            <>
              <div className="back-navigation" onClick={this.disableRoadMapView}>
                <i class="fal fa-arrow-circle-left"></i>
              </div>
              <div className="initiativeheading-dropdown">
                <span className="initiativeheading-dropdownvalue" onClick={this.isInitiaveDropdown}>
                  {i18n.t(initiativeStage)}
                </span>
                {this.state.isInitiaveDropdownStatus ? (
                  <div className="initiativeheading-dropdownlist stage-drop">
                    <ul>
                      {IOJStageDATA.map((each) => {
                        return (
                          <li
                            onClick={() => {
                              this.onClickFetchInitiative(each);
                            }}
                            className={initiativeStage == each.IOJStageName_En ? "selected-droplist" : ""}
                          >
                            {each.IOJStageName}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
          {this.state.isInitiaveDropdownStatus ? (
            <div className="initiative-dropdown-overlay" onClick={this.isInitiaveDropdownOverlay}></div>
          ) : (
            ""
          )}
          {enableDraftModalStatus && (
            <InitiativeDraftList
              filterDetails={this.props.filterDetails}
              enableDraftModalStatus={enableDraftModalStatus}
              disableDraftModalStatus={this.disableDraftModalStatus}
              draftList={draftList}
              fetchJourneyInitiativeData={this.fetchJourneyInitiativeData}
              onEditInitiative={(initiativeId) =>
                this.showInitiativeForm({ formMode: "edit", initiativeId: initiativeId, formType: "draftEdit" })
              }
              initiativeStageId={this.state.initiativeStageId}
              checkifOMIDisAvailable={this.checkifOMIDisAvailable}
            />
          )}
        </div>

        <div className={`journeyinitiative-tabs ${this.props.isInitativeManagement ? "initiativemanegement-tab" : ""}`}>
          {isAccess && history.location.pathname === "/admin/Configurator" ? (
            <>
              {selectedTab == "assessment" &&
                this.state.addAssessmentCriteriaStatus &&
                !addAssessmentFormStatus &&
                currentFilter.ProcessId != "All" &&
                currentFilter.OfferingId != "All" &&
                currentFilter.isOMIDAvailable == false ? (
                <div className="addcriteria-btn" onClick={this.addAssessmentCriteria}>
                  {i18n.t("Add_Assessment_Criteria")}
                </div>
              ) : (
                isAccess &&
                selectedTab == "assessment" &&
                currentFilter.ProcessId != "All" &&
                currentFilter.OfferingId != "All" &&
                currentFilter.isOMIDAvailable == false && (
                  <div className="addcancel-btns">
                    <button className="btn addcritetia-outline mr-3" onClick={this.onClose}>
                      {i18n.t("Cancel")}
                    </button>
                    <button className="btn addcritetia-primary" onClick={this.validateModal}>
                      {" "}

                      {this.state.isEditAssessment === true ? i18n.t("Update") : i18n.t("Add")}
                    </button>
                  </div>
                )
              )}
              {isAccess && selectedTab == "initiative" && initiativeListStatus && OMIDEnabled && (
                <div class="dropdown addInitiative-dropdown">
                  <div
                    class={`addcriteria-btn dropdown-toggle`}
                    onClick={() =>
                      this.showInitiativeForm({ formMode: "add", initiativeId: null, formType: "nonDraftAdd" })
                    }
                  >
                    {i18n.t("Add_Initiative")}
                  </div>

                </div>
              )}

              {/* ACTION BUTTONS FOR ADD/EDIT INITIATIVE FORM */}
              {(this.state.selectedTab === "initiative" && addEditInitiativeFormProps.isVisible) && (
                <div className="addcancel-btns">
                  <button
                    className="btn addcritetia-outline mr-3"
                    onClick={() => this.handleClickCancelInitiativeForm()}
                  >
                    {i18n.t("Cancel")}
                  </button>
                  {formType !== "nonDraftEdit" && (
                    <button
                      className="btn addcritetia-outline mr-3"
                      onClick={() => this.handleClickSubmitInitiativeForm("saveDraft")}
                    >
                      {i18n.t("Save_as_Draft")}
                    </button>
                  )}
                  <button
                    className="btn addcritetia-primary"
                    onClick={() => this.handleClickSubmitInitiativeForm("save")}
                  >
                    {addEditInitiativeFormProps.formMode === "add" ? i18n.t("Add") : i18n.t("Update")}
                  </button>
                </div>
              )}
            </>
          ) : null}
          {/* for Outcomes */}
          {isAccess &&
            history.location.pathname === "/admin/Configurator" &&
            selectedTab == "outcome" &&
            OMIDEnabled ? (
            <>
              {addEditOutcomeFormStatus == "" ? (
                <div className="addcriteria-btn" onClick={this.addOutcomes}>
                  {i18n.t("Add_Outcomes")}
                </div>
              ) : (
                <div className="addcancel-btns">
                  <button className="btn addcritetia-outline mr-3" onClick={this.onCloseOutcome}>
                    {i18n.t("Cancel")}
                  </button>
                  {isAccess && addEditOutcomeFormStatus == "Edit" && (
                    <button className="btn addcritetia-primary" onClick={this.submitEdit}>
                      {" "}
                      {i18n.t("Update")}
                    </button>
                  )}
                  {isAccess && addEditOutcomeFormStatus == "Add" && (
                    <button className="btn addcritetia-primary" onClick={this.submitAdd}>
                      {" "}
                      {i18n.t("Add")}
                    </button>
                  )}
                </div>
              )}
            </>
          ) : null}

          <Tabs defaultActiveKey={this.props.isInitativeManagement ? "initiative" : selectedTab} activeKey={this.props.isInitativeManagement ? "initiative" : selectedTab} onSelect={(k) => this.setKey(k)}>
            {!isApiLoading && !isInitativeManagement && (
              <Tab eventKey="assessment" title={i18n.t("Assessment_Criteria")}>
                <AssessmentCriteria
                  AssessmentData={AssessmentData}
                  history={history}
                  RecordStatus={this.props.getRecordStatus}
                  isManintencneWindowOpen={this.state.isManintencneWindowOpen}
                  roleReducers={this.props.roleReducers}
                  initiativeStage={initiativeStage}
                  addAssessmentFormStatus={addAssessmentFormStatus}
                  getAssessments={this.getAssessmentCriteria}
                  SelectedAssesmentCriteria={this.handleChangeSelectedAssesmentCriteria}
                  SelectedStatusCriteria={this.handleChangeSelectedStatusCriteria}
                  errMsg={[errorMessageAssessment, errorMessageCustom, errorMessageStatus]}
                  addFormFunctionality={[this.state.addAssessmentFormStatus, this.state.assessmentCriteriaListStatus]}
                  filterData={[this.props.currentFilter, this.props.languageData, this.props.clientData]}
                  addAssessmentCriteria={this.addAssessmentCriteria}
                  editAssessmentCriteria={this.editAssessmentCriteria}
                  isEditAssessment={this.state.isEditAssessment}
                  isApiLoading={isApiLoading}
                  initiativeStageId={initiativeStageId}
                  {...this.props}
                  filterChanged={this.state.filterChanged}
                  settingfilterChangedState={this.settingfilterChangedState}
                  settingFilterChangedTrue={this.settingFilterChangedTrue}
                />
              </Tab>
            )}
            {!isApiLoading && !isInitativeApiloading && (
              <Tab eventKey="initiative" title={i18n.t("Initiatives")}>
                <>
                  {initiativeListStatus && (
                    <InitiativeList
                      iskpiLoading={this.state.iskpiLoading}
                      configuratorStatus={configuratorStatus}
                      ShowInitativeDetails={this.props.ShowInitativeDetails}
                      initiativeStage={"All"}//{initiativeStage}
                      disableAddInitiative={(isDisable) => {
                        this.disableAddInitiative(isDisable);
                      }}
                      {...this.props}
                      IOJStageDesc={IOJStageDesc}
                      isIOJCategory={this.props.isIOJCategory}
                      onEditInitiative={this.showInitiativeForm}
                      fetchJourneyInitiativeData={this.fetchJourneyInitiativeData}
                      checkifOMIDisAvailable={this.props.checkifOMIDisAvailable}
                      filterChanged={this.state.filterChanged}
                      settingfilterChangedState={this.settingfilterChangedState}
                      settingFilterChangedTrue={this.settingFilterChangedTrue}
                      initiativeStageId={this.state.initiativeStageId}
                    />
                  )}

                  {/* ADD/EDIT INITIATIVE FORM */}
                  {!initiativeListStatus && addEditInitiativeFormProps.isVisible && (
                    <AddEditInitiative
                      initiativeFormSubmittedAs={addEditInitiativeFormProps.initiativeFormSubmittedAs}
                      resetInitiativeFormSubmittedAs={() => this.resetInitiativeFormSubmittedAs()}
                      handleClickSubmitInitiativeForm={(formSubmittedAs) =>
                        this.handleClickSubmitInitiativeForm(formSubmittedAs)
                      }
                      initiativesSequenceData={initiativesSequenceData}
                      isInitativeManagement={isInitativeManagement}
                      handleAfterSubmittedInitiativeForm={() => this.handleAfterSubmittedInitiativeForm()}
                      formMode={addEditInitiativeFormProps.formMode}
                      initiativeId={addEditInitiativeFormProps.initiativeId}
                      journeyStageId={addEditInitiativeFormProps.formMode === "add" && isInitativeManagement ? "" : initiativeStageId}
                      GetIOJQcscore={this.GetIOJQcscore}
                      formType={formType}
                      {...this.props}
                    />
                  )}
                </>
              </Tab>
            )}
            {!isApiLoading && !isInitativeManagement && (
              <Tab eventKey="outcome" title={i18n.t("Outcome")}>
                <Outcomes
                  RecordStatus={this.props.getRecordStatus}
                  roleReducers={this.props.roleReducers}
                  isManintencneWindowOpen={this.state.isManintencneWindowOpen}
                  history={history}
                  initiativeStage={initiativeStage}
                  {...this.props}
                  errMsg={this.state.outcomeErrorMessage}
                  getOutcomes={this.getOutcomeList}
                  maxOutcomeSequence={maxOutcomeSequence}
                  addEditOutcomeFormStatus={addEditOutcomeFormStatus} // new
                  filterData={[this.props.currentFilter, this.props.languageData, this.props.clientData]}
                  {...this.props}
                  outComeListData={outComeListData}
                  outComeSequenceData={outComeSequenceData}
                  editOutcome={this.editOutcome} // new
                  addOutcomeDataChange={this.addOutcomeDataChange} // new
                  handleSelectSequenceDropdownOption={this.handleSelectSequenceDropdownOption}
                  selectedOutcome={selectedOutcome}
                  errorMessageOutcome={errorMessageOutcome}
                  filterChanged={this.state.filterChanged}
                  settingfilterChangedState={this.settingfilterChangedState}
                  settingFilterChangedTrue={this.settingFilterChangedTrue}
                  SequenceChanged={this.SequenceChanged}
                />
              </Tab>
            )}
          </Tabs>
          {showNotificationModal && (
            <NotificationForAddEdit
              showsuccessModal={showNotificationModal}
              message={message}
              duplicateOutcomeExists={duplicateOutcomeExists}
              duplicateAssementExists={duplicateAssementExists}
              successRemovalModalClose={this.closeNotificationModal}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languageData: state.CurrentFilter.languageData,
    currentFilter: state.CurrentFilter.SyncFilter,
    filter: state.CurrentFilter.Filter,
    clientData: state.CurrentFilter.ClientData,
    allInitiativeData: state.JourneyReducer.JourneyInitiatives,
    IOJGlidePathAllModels: state.JourneyReducer.IOJData,
    previousSelectedInitiative: state.JourneyReducer.Datas.viewData,
    Datas: state.JourneyReducer.Datas,
    Suboffering: state.CurrentFilter.Common,
    OMIDEnabled: state.JourneyReducer.OMIDEnabled,
  };
};

export default connect(
  mapStateToProps,
  ""
)(JourneyInitiativeTabs);
