import React, { Component } from 'react';
import axiosInstance from '../Shared/interceptor';
import { store } from "../reducers/configureStore";

//import BounceLoader from 'react-spinners/BounceLoader';


import DealList from '../Deal/dealList';
import OfferingList from '../Offering/offeringList';
import DayWiseTrends from '../DayWiseTrends/DayWiseTrends';
import WeekWiseTrends from './WeekWiseTrends';

axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;

//const active = false;

export default class UserTrendsList extends Component {

    constructor() {
        super();
        this.state = {
            roleData: [],

        featureData: [],

        }

    this.getRoleData = this.getRoleData.bind(this);

}

    getRoleData() {
        
        var data = store.getState();
        let roleData1 = data.roleReducers["roleReducers"];
        store.subscribe(() => {
         
            var data = store.getState();
            this.setState({ roleData: data.roleReducers["roleReducers"] })
            let featureDetails1 = data.roleReducers["roleReducers"];
            //let featureData = featureDetails1.map(function (el) { return el.FeatureName; });
            this.setState({ featureData: featureDetails1 })
         
         
        })
    }


    componentWillMount() {
        this.getRoleData();

    }



    componentDidMount() {
        this.getRoleData();

    }

    render() {
        return <>
            {
                <>
                    {this.props.isDayWiseClicked && this.state.featureData.filter((data) => data.Submenu == "Day Wise"&& data.Operations.includes("View")).length>0? <DayWiseTrends filterDetails={this.props.filterDetails} /> : null}
                    {this.props.isWeekWiseClicked && this.state.featureData.filter((data) => data.Submenu == "Week Wise"&& data.Operations.includes("View")).length>0?  <WeekWiseTrends filterDetails={this.props.filterDetails} /> : null}
                   
                 
                </>
            }
        </>

    }
}