/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BOMetricDetails from './BOMetricDetails';
import { LocalApiBaseUrl, trycatchAlertPopup } from '../Shared/Constant';
import axiosInstance from '../Shared/interceptor';

import ChatWindow from './ChatWindow';
import { addBOdata } from '../actions/JourneyActions';
import { store } from '../reducers/configureStore';
import References from '../Shared/References';
import './reference.css';
import CommentsHistory from './CommentsHistory';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('msal.idtoken')}`;

class BOContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFD: window.location.pathname === '/Dashboard',
      boData: [],
      roledata: [],
    };
  }

  componentDidMount() {
    this.getBOApiData();
    this.getFeatureDetails();

  }

  componentWillUnmount() {
    const { close } = this.props;
    close();
  }

  getBOApiData() {
    const { isFD } = this.state;
    const { addBOdataForIOJ, filterDetails, languageData } = this.props;
    const filterClient = filterDetails.Client;
    const filterProcess = filterDetails.process;
    const filterLocations = filterDetails.Locations;
    const filterDate = filterDetails.Month;
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetBusinessOutcomes`, {
        params: {
          OrgEntityID: filterClient,
          LocationID: filterLocations,
          MonthDT: filterDate,
          OfferingID: filterProcess,
          LanguageID: parseInt(languageData.id, 10),
        },
      })
      .then((response) => {
        if (isFD) {
          addBOdataForIOJ(response.data);
        }
        store.dispatch({
          type: 'INITIALISE_BO',
          payload: response.data,
        });

        this.setState({ boData: response.data });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  getFeatureDetails() {
    const { roledata } = this.props;
    const featureDetails1 = roledata;
    let operationArray;
    // eslint-disable-next-line consistent-return
    featureDetails1.forEach((i) => {
      if (i.FeatureName === 'Dashboard') {
        operationArray = i.Operations;
        return operationArray;
      }
    });

        this.setState({ roledata: operationArray });
    }

  onCommentAdd = (data) => {
    const { boData } = this.state;
    const newBOData = [...boData];
    newBOData.map((each) => {
      if (each.SOCategoryID === data.SOCategoryID) {
        each.kpiData.map((item) => {
          if (item.KPIMappingID === data.KPIMappingID) {
            // eslint-disable-next-line no-param-reassign
            item.MessagesCount += 1;
          }
          return item;
        });
      }
      return each;
    });
    this.setState({ boData: newBOData });
  };

  onDeleteComment = (data) => {
    const { boData } = this.state;
    const newBOData = [...boData];
    newBOData.map((each) => {
      if (each.SOCategoryID === data.SOCategoryID) {
        each.kpiData.map((item) => {
          if (item.KPIMappingID === data.KPIMappingID) {
            // eslint-disable-next-line no-param-reassign
            item.MessagesCount -= 1;
          }
          return item;
        });
      }
      return each;
    });
    this.setState({ boData: newBOData });
  };

  render() {
    const { selectedMetricDetails, isRelatorBlock, filterDetails } = this.props;
    const { boData, roledata } = this.state;
    return (
      <>
        <div className="row">
          <div className="col-lg-12">
            <div className="category-references dashboard-category-references bo-references">
              <References {...this.props} SOCatID={1} leverName="Business Outcomes" referenceScreen="BO" />
            </div>

            {!selectedMetricDetails.showHistory === true
              && boData.map((SOData, index) => (
                <BOMetricDetails
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  isRelatorBlock={isRelatorBlock}
                  SODetails={SOData}
                  filterDetails={filterDetails}
                  roledata={roledata}
                />
              ))}
            {selectedMetricDetails.SOCategoryID
              && selectedMetricDetails.KPIMappingID
              && selectedMetricDetails.showHistory === false && (
                <ChatWindow
                  data={boData}
                  onCommentAdd={this.onCommentAdd}
                  onDeleteComment={this.onDeleteComment}
                />
            )}
            {selectedMetricDetails.SOCategoryID
              && selectedMetricDetails.KPIMappingID
              && selectedMetricDetails.showHistory === true
              && <CommentsHistory data={boData} />}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  languageData: state.CurrentFilter.languageData,
  clientData: state.CurrentFilter.ClientData,
  selectedMetricDetails: { ...state.BOReducers.selectedMetriCCommentHistory },

});
const mapDispatchToProps = (dispatch) => ({
  addBOdataForIOJ: (payload) => dispatch(addBOdata(payload)),
  close: () => {
    dispatch({
      type: 'SELECTED_METRIC_COMMENT_HISTORY',
      payload: { SOCategoryID: '', KPIMappingID: '', showHistory: false },
    });
  },
});
BOContainer.propTypes = {
  roledata: PropTypes.array.isRequired,
  languageData: PropTypes.object.isRequired,
  addBOdataForIOJ: PropTypes.object.isRequired,
  filterDetails: PropTypes.object.isRequired,
  selectedMetricDetails: PropTypes.object.isRequired,
  isRelatorBlock: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BOContainer);
