import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { LocalApiBaseUrl, getMonthNumber, getPrevMonth } from "../Shared/Constant";

import Iframe from "react-iframe";
import MORDocViewer from "./MORDocViewer";
import AddFileFolderPopup from "./AddFileFolderPopup";
import ReactTooltip from "react-tooltip";
import { Modal, Button } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { AgGridReact, Ag } from "ag-grid-react";
import i18n from "i18next";
import SynopsLoader from "../Shared/SynopsLoader";
import CommentsPopOver from "./CommentsPopover";
import MORCommentsHistory from "./MORCommentsHistory";
import _, { set } from "lodash";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-blue.css";
import { store } from "../reducers/configureStore";

import axiosInstance from "../Shared/interceptor";
import MORcard from "../Images/morcard.jpg";
import MORXlsx from "../Images/MORxlsx.svg";
import MORDocx from "../Images/MORdocx.svg";
import MORDocument from "../Images/folder-icon.svg";
import MORMp4 from "../Images/MORmp4.svg";
import EllipsesWhite from "../Images/EllipsesWhite.svg";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Overlay, Popover, OverlayTrigger } from "react-bootstrap";
import Success from "../Images/Success.svg";
import MorEye from "../Images/morcard-eye.svg";
import MorfileImage from "../Images/morfile-image.svg";
import MorfilePPT from "../Images/morfile-ppt.svg";
import MorfilePDF from "../Images/morfile-pdf.svg";
import MorFavourite from "../Images/morfavourite.svg";
import MorFavouriteActive from "../Images/morfavourite-active.svg";
import Client from "../Images/client-0.svg";
import "./MOR.css";
//import ".././IOJ/ValueRealization/valueRealization.css";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClosedCaptioning,
  faPencilAlt,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { truncateSync } from "fs-extra";
import MORNotification from "./MORNotification";
import { TemplateService } from "ag-grid-community";
import { boolean } from "yup/lib/locale";
import { isNull } from "lodash";
import { KeyboardReturnOutlined } from "@material-ui/icons";
import MORfolderImage from "../Images/newfolder-normal.svg";
import { MorDocInfo } from "../Shared/Constant";
import { NavLink } from "react-router-dom";
import MorListfolder from "../Images/MORListIcons/MorListfolder.svg";
import MorTilefolder from "../Images/MORTileIcons/MorTilefolder.svg";
import noFilter from "../Images/funnel-icon.svg";
import { filterSelectedSubOffering, filterAllSelectedSubOfferings } from '../Shared/commonFunctions';
import SubOffering from '../Shared/SubOffering';

axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


const tooltipbreadcrumb = 20; // Bread Crumb Tool Tip
const listOffChar = 23 // For List View Off,SubOff,Categories
const tileChar = 20 // For Document Name in Tile View
const listDocChar = 19 // For Document Name in List View
const breadcrummbDropChar = 23 // For BreadCrumbDropDown

function MORAliasContainer(props) {
  const nameRef = useRef(null);
  const [isApply, setisApply] = useState(true);
  const [Category, setCategory] = useState("All Categories");
  const [isshowDeleteAction, setisshowDeleteAction] = useState(false);
  const [isshowArchiveAction, setisshowArchiveAction] = useState(false);
  const [isshowFavouriteAction, setisshowFavouriteAction] = useState(false);
  const [isshowEditAction, setisshowEditAction] = useState(false);
  const [totalSelectedCount, settotalSelectedCount] = useState(0);
  const [isShowDeSelect, setisShowDeSelect] = useState(false);
  const [isSelectAll, setisSelectAll] = useState(false);
  const [PageNo, setPageNo] = useState(0);
  const [PageSize, setPageSize] = useState(0);
  const [IsFavouriteMode, setIsFavouriteMode] = useState(false);
  const [IsArchiveMode, setIsArchiveMode] = useState(false);
  const [CategoryIDs, setCategoryIDs] = useState([]);
  const [TotalDocumentsCount, setTotalDocumentsCount] = useState(0);
  const [TotalResultCount, setTotalResultCount] = useState(null);

  const [isBasicApiLoading, setisBasicApiLoading] = useState(true);

  const [normModeofferingName, setnormModeofferingName] = useState(
    "All Offerings"
  );
  const [normModesubofferingName, setnormModesubofferingName] = useState(
    "All Sub Offerings"
  );
  const [normModeOfferingID, setnormModeOfferingID] = useState(0);
  const [normModeMonth, setnormModeMonth] = useState("All Months");
  const [normModeCategory, setnormModeCategory] = useState("All Categories");
  const [normModeCategoryIDs, setnormModeCategoryIDs] = useState([]);

  const [favModeofferingName, setfavModeofferingName] = useState(
    "All Offerings"
  );
  const [favModesubofferingName, setfavModesubofferingName] = useState(
    "All Sub Offerings"
  );
  const [favModeOfferingID, setfavModeOfferingID] = useState(0);
  const [favModecommondefaultMonth, setfavModecommondefaultMonth] = useState(
    "All Months"
  );
  const [favModeCategory, setfavModeCategory] = useState("All Categories");
  const [favModeCategoryIDs, setfavModeCategoryIDs] = useState([]);

  const [archModeofferingName, setarchModeofferingName] = useState(
    "All Offerings"
  );
  const [archModesubofferingName, setarchModesubofferingName] = useState(
    "All Sub Offerings"
  );
  const [archModeOfferingID, setarchModeOfferingID] = useState(0);
  const [archModecommondefaultMonth, setarchModecommondefaultMonth] = useState(
    "All Months"
  );
  const [archModeCategory, setarchModeCategory] = useState("All Categories");
  const [archModeCategoryIDs, setarchModeCategoryIDs] = useState([]);

  const [clearofferingName, setclearofferingName] = useState("All Offerings");
  const [clearsubofferingName, setclearsubofferingName] = useState(
    "All Sub Offerings"
  );
  const [clearOfferingID, setclearOfferingID] = useState(0);
  const [clearcommondefaultMonth, setclearcommondefaultMonth] = useState(
    "All Months"
  );
  const [clearCategory, setclearCategory] = useState("All Categories");
  const [clearCategoryIDs, setclearCategoryIDs] = useState([]);

  const liRef = useRef([]);
  const ParentRef = useRef();
  const OpRef = useRef([]);
  const freezefilter = {
    opacity: "0.7",
    pointerEvents: "none",
  };
  let [PageDropDown, setPageDropDown] = useState([]);
  let [PageCount, setPageCount] = useState([]);
  let [perpage, setperpage] = useState(0);
  let [Configperpage, setConfigperpage] = useState(0);
  let [MorBreadCrumb, setMorBreadCrumb] = useState(0);
  let [currentPage, setcurrentPage] = useState(1);
  let [showSelectall, setshowSelectall] = useState(false);
  let [sortHeading, setsortHeading] = useState("");
  let [SortBytype, setSortBytype] = useState("");
  let [isSortingMode, setisSortingMode] = useState(false);
  let [isShowNoDatamessage, setisShowNoDatamessage] = useState(false);
  const [isMultipleDelete, setisMultipleDelete] = useState(false);
  const [FavouriteAction, setFavouriteAction] = useState(false);
  const [isallFavourite, setisallFavourite] = useState(false);
  const [isNoFavourite, setisNoFavourite] = useState(false);
  const [isParentFavourite, setisParentFavourite] = useState(false);
  const [Freezefilter, setFreezefilter] = useState(false);
  const [filterApplied, setfilterApplied] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  let [Singledata, setSingledata] = useState("");
  let [isFilterActive, setisFilterActive] = useState(false);
  let [CategoryApiloaded, setCategoryApiloaded] = useState(true);
  let [ToolTipdata, setToolTipdata] = useState("");
  let fontawsstyle = {
    position: 'absolute',
    right: "7px"
  }
  let Cursorstyle = {
    cursor: 'pointer'
  }
  let [GetOffingData, setGetOffingData] = useState([]);
  let [GetsubOffingData, setGetsubOffingData] = useState([]);
  let [GetMonthData, setGetMonthData] = useState([]);
  let [GetCategoryIDs, setGetCategoryIDs] = useState([]);

  let [isShowNoffering, setisShowNoffering] = useState(false);
  let [isShowNoSubffering, setisShowNoSubffering] = useState(false);
  let [isShowNoMonth, setisShowNoMonth] = useState(false);
  let [isShowNoCategory, setisShowNoCategory] = useState(false);
  let [languageID, setlanguageID] = useState(1);
  let [isParentDataSet, setisParentDataSet] = useState(false);

  //Recent added blcok above
  ///rd
  let [OrginalData, setOrginalData] = useState([]);
  let [allLevelData, setallLevelData] = useState([]);
  let [ClonedData, setClonedData] = useState([]);
  let [ClonedNewData, setClonedNewData] = useState([]);
  const [dataToDelete, setdataToDelete] = useState([]);

  /////
  const [isShowNotification, setisShowNotification] = useState(false);

  const [isFolderAdded, setisFolderAdded] = useState(false);

  const [isItemAdded, setisItemAdded] = useState(false);

  const [isFolderEdited, setisFolderEdited] = useState(false);

  const [isItemEdited, setisItemEdited] = useState(false);

  const [folderItemMessage, setfolderItemMessage] = useState("");

  const [isMovedToArchieve, setisMovedToArchieve] = useState(false);

  const [isMovedToFavourite, setisMovedToFavourite] = useState(false);

  const [isReMovedFromArchieve, setisReMovedFromArchieve] = useState(false);

  const [isRemovedFromFavourite, setisRemovedFromFavourite] = useState(false);

  const [isDeleted, setisDeleted] = useState(false);

  const [isDummyCardBlurFired, setisDummyCardBlurFired] = useState(false);
  const [isDummyCardKDFired, setisDummyCardKDFired] = useState(false);

  const [isEditFolderBlurFired, setisEditFolderBlurFired] = useState(false);
  const [isEditFolderKDFired, setisEditFolderKDFired] = useState(false);

  const [BackParentID, setBackParentID] = useState(null);
  const [archiveLayoutStatus, setarchiveLayoutStatus] = useState(false);
  const FolderRef = useRef([]);
  const FolderAliasRef = useRef([]);
  const Popovertarget = useRef(null);
  const [AllItemName, setAllItemName] = useState([]);
  const [commonmonthValues, setcommonmonthValues] = useState([]);
  const [NewcommonmonthValues, setNewcommonmonthValues] = useState([]);
  const [commondefaultMonth, setcommondefaultMonth] = useState("All Months");

  const [PrevFolderName, setPrevFolderName] = useState("");
  const [archivemonthValues, setarchivemonthValues] = useState([]);
  const [archivedefaultMonth, setarchivedefaultMonth] = useState("All Months");
  const [IsExist, setIsExist] = useState(false);
  const [isClearDisable, setisClearDisable] = useState(true);
  const [isAddDisable, setisAddDisable] = useState(true);

  const [isloading, setisloading] = useState(false);
  const [isGetLoading, setisGetLoading] = useState(false);
  const [isDisable, setisDisable] = useState(true);
  const [isMORDoc, setisMORDoc] = useState(false);
  const [Path, setPath] = useState("");
  const [ViewerItemDeatils, setViewerItemDeatils] = useState({
    ItemId: 0,
    ItemName: "",
    ItemLink: "",
    UpdatedTS: "",
    CreatedBy: "",
  });
  const [successRemovalModalStatus, setsuccessRemovalModalStatus] = useState(
    false
  );
  const [offeringName, setofferingName] = useState("All Offerings");

  const [formofferingName, setformofferingName] = useState("All Offerings");

  const [isshowDocDropDown, setisshowDocDropDown] = useState(false);
  const [AllFolderName, setAllFolderName] = useState([]);
  const [isFolderAdd, setisFolderAdd] = useState(false);
  const [isFolderEdit, setisFolderEdit] = useState(false);
  const [BackParentIDArray, setBackParentIDArray] = useState([]);
  let [MORFolders, setMORFolders] = useState([]);
  let [MORItemsModel, setMORItemsModel] = useState([]);
  const [isItemEdit, setisItemEdit] = useState(false);
  const [isShowListCard, setisShowListCard] = useState(false);
  let [ClonedMORFolders, setClonedMORFolders] = useState([]);
  let [ClonedMORItemsModel, setClonedMORItemsModel] = useState([]);
  const [Iframextension, setIframextension] = useState([
    "WDAR",
    "WDINCONFIGURATOR",
    "POWERBI",
  ]);

  const [ImageExtensions, setImageExtensions] = useState([
    ".jpeg",
    ".jpg",
    ".giff",
    ".png",
  ]);
  const [VideoExtensions, setVideoExtensions] = useState([
    ".mp4",
    ".mov",
    ".webm",
    ".mkv",
    ".ogv",
    ".3g2",
  ]);

  const [DeleteFolderDeatils, setDeleteFolderDeatils] = useState({
    FolderId: 0,
    FolderName: "",
  });
  const [IsFolderDelete, setIsFolderDelete] = useState(false);
  const [deleteItemModalStatus, setdeleteItemModalStatus] = useState(false);
  const [FolderIndex, setFolderIndex] = useState(null);
  const [isMORShowDocViewer, setisMORShowDocViewer] = useState(false);
  const [Doctype, setDoctype] = useState({
    Id: 0,
    DocName: "",
    DocLink: "",
    DocDescription: "",
  });

  const [typePopup, settypePopup] = useState("Add");

  const [morcommentwindowStatus, setmorcommentwindowStatus] = useState(false);
  const [morcommentHistory, setMorcommentHistory] = useState(false);
  const [filterProps, setFilterProps] = useState({});
  const [addEditCommentsProp, setaddEditCommentsProp] = useState({});
  const [morCommentsData, setMorCommentsData] = useState([]);

  const [ParentFolderID, setParentFolderID] = useState(0);
  const [OrgEntityID, setOrgEntityID] = useState(0);
  const [ItemDeatils, setItemDeatils] = useState({
    ItemId: 0,
    ItemName: "",
    ItemLink: "",
  });
  const [FolderDeatils, setFolderDeatils] = useState({
    FolderId: 0,
    FolderName: "",
  });

  const [DeleteItemDeatils, setDeleteItemDeatils] = useState({
    ItemId: 0,
    ItemName: "",
    ItemLink: "",
  });
  const [DummyFolderName, setDummyFolderName] = useState("New Folder");
  const [NewFolderName, setNewFolderName] = useState("");
  const [NotifyFolderName, setNotifyFolderName] = useState("");
  const [isShowDummyCard, setisShowDummyCard] = useState(false);
  const [subofferingName, setsubofferingName] = useState("All Sub Offerings");

  const [formsubofferingName, setformsubofferingName] = useState(
    "All Sub Offerings"
  );

  const [OfferingID, setOfferingID] = useState(0);

  const [formOfferingID, setformOfferingID] = useState(0);

  const [Document, setDocument] = useState([]);
  const [allmonth, setallmonth] = useState([
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]);
  const [monthValues, setmonthValues] = useState([]);
  const [monthList, setmonthList] = useState([]);
  const [defaultMonth, setdefaultMonth] = useState("All Months");
  const [roleData, setroleData] = useState([]);
  const [offeringOptions, setofferingOptions] = useState([]);
  const [NewofferingOptions, setNewofferingOptions] = useState([]);
  const [NewofferingOptionsPopUp, setNewofferingOptionsPopUp] = useState([]);

  const [formofferingOptions, setformofferingOptions] = useState([]);

  const [processOptions, setprocessOptions] = useState([]);
  const [NewprocessOptions, setNewprocessOptions] = useState([]);

  const [formprocessOptions, setformprocessOptions] = useState([]);

  const [MORData, setMORData] = useState([]);
  const [ClonedMORData, setClonedMORData] = useState([]);

  //const [offeringData, setofferingData] = useState([]);

  const [formofferingData, setformofferingData] = useState([]);

  const [userList, setuserList] = useState([]);
  const [clientList, setclientList] = useState([]);
  const [rowSelected, setrowSelected] = useState([]);
  const [featureData, setfeatureData] = useState([]);

  const [listGridLayoutStatus, setlistGridLayoutStatus] = useState(true);
  const [
    addfeatureNotificationStatus,
    setaddfeatureNotificationStatus,
  ] = useState(false);
  const [
    addfeatureNotificationModalClose,
    setaddfeatureNotificationModalClose,
  ] = useState(false);
  const [viewmore, setviewmore] = useState(false);

  const [gridLayoutStatus, setgridLayoutStatus] = useState(false);
  const [
    isMOROfferingDropdownStatus,
    setisMOROfferingDropdownStatus,
  ] = useState(false);
  const [
    isMORSubOfferingDropdownStatus,
    setisMORSubOfferingDropdownStatus,
  ] = useState(false);
  const [
    isMORMonthOfferingDropdownStatus,
    setisMORMonthOfferingDropdownStatus,
  ] = useState(false);
  const [addItemModalStatus, setaddItemModalStatus] = useState(false);
  const [createnewModalStatus, setcreatenewModalStatus] = useState(false);
  const [filterPopoverStatus, setfilterPopoverStatus] = useState(false);
  const [
    successNotificationModalStatus,
    setsuccessNotificationModalStatus,
  ] = useState(false);
  const [selectedMOROffering, setselectedMOROffering] = useState("All");

  const [query, setquery] = useState("");
  const [KeyIDs, setKeyIDs] = useState([
    "CreatedBy",
    "CreatedTS",
    "IsActive",
    "IsFolder",
    //"ItemCount",
    "MORFolderID",
    "Month",
    "Offering",
    "OrgEntityID",
    "ParentMORFolderID",
    "SubOfferingID",
    "UpdatedBy",
    "UpdatedTS",
    "Year",
    "ItemLink",
    "MORItemID",
  ]);

  const [categoriesList, setCategoriesList] = useState([]);
  const [NewCategoriesList, setNewCategoriesList] = useState([]);
  const [EditData, setEditData] = useState({
    Type: "",
    Data: {},
  });

  const [accessControl, setaccessControl] = useState("View"); //Default Access For Guest User

  const [offeringValues, setofferingValues] = useState([]);

  const handleCloseFileFolder = () => {
    setcreatenewModalStatus(false);
    setErrorMessage("");
  };
  const handleCommentsPopup = () => {
    morcommentwindowShow();
  };
  const handleViewerEdit = (itemDetails) => {
    setEditData({
      Type: "Item",
      Data: itemDetails,
    });
    handleEdit();
  };



  const GetAllPulseMORItemData = () => {
    const {
      pulseOfferingData,
      pulsePageData,
      clientData,
      languageData,
      InitializeMORData,
    } = props;
    InitializeMORData([], { Level: "", CategoryID: 0, ismorpulseapiloading: false }, true);
    let data = {
      OrgEntityID: clientData.id,
      Offering: pulsePageData.Offering,
      SubOfferingID: pulsePageData.offeringId,
      LanguageID: languageData.id,
      PageNo: 0,
      PageSize: 0,
      Searchquery: "",
    };
    axiosInstance
      .post(`${LocalApiBaseUrl}MOR/GetAllPulseMORItemData`, data, {
        //.post(`http://localhost:51045/api/MOR/GetAllPulseMORItemData`, data, {
        params: {},
      })
      .then((response) => {
        let MORItemsModelpulse = response.data.MORItemsModel;
        MORItemsModelpulse = MORItemsModelpulse.map((a, index) => {
          return {
            ...a,
            ExtensionAndIcon:
              a.ItemLink !== "" && a.ItemLink != null
                ? GenerateExtensionIcon(a.ItemLink)
                : "",
          };
        });

        InitializeMORData(MORItemsModelpulse,
          {
            Level: response.data.ItemDataLevel,
            CategoryID: response.data.CategoryID,
            ismorpulseapiloading: false,
          }, response.data.isCategoryData);
      })
      .catch((error) => {
        InitializeMORData([], { Level: "", CategoryID: 0, ismorpulseapiloading: false }, false);
      });
  };
  const setFilterDataTwo = () => {
    let CheckedOfferingData =
      [...new Set(OrginalData.filter(a => a.IsChecked && !isNull(a.Offering)).map((a) => a.Offering))]
    let UnCheckedOfferingData =
      [...new Set(OrginalData.filter(a => a.IsChecked === false && !isNull(a.Offering)).map((a) => a.Offering))]
    let CheckedMonthData =
      [...new Set(OrginalData.filter((a) => a.IsChecked && a.MonthYear !== "").map((a) => a.MonthYear))]
    let UnCheckedMonthData =
      [...new Set(OrginalData.filter((a) => a.IsChecked === false && a.MonthYear !== "")
        .map((a) => a.MonthYear))]

    let CheckedCategoryIDs = [], UnCheckedCategoryIDs = [];
    OrginalData.filter((a) => a.IsChecked).map((a) =>
      a.CategoryIDs != null && a.CategoryIDs.map((a) => (CheckedCategoryIDs = CheckedCategoryIDs.concat(a)))
    );
    OrginalData.filter((a) => a.IsChecked === false).map((a) =>
      a.CategoryIDs != null && a.CategoryIDs.map((a) => (UnCheckedCategoryIDs = UnCheckedCategoryIDs.concat(a)))
    );
    UnCheckedCategoryIDs = [...new Set(UnCheckedCategoryIDs)];
    UnCheckedCategoryIDs = [...new Set(UnCheckedCategoryIDs)];

    let OfferingData = [...offeringOptions];
    CheckedOfferingData.map(a => {
      if (a !== "" && !UnCheckedOfferingData.some(b => b == a)) {
        OfferingData = OfferingData.filter(c => c.OfferingName_En !== a);
      }
    })
    setofferingOptions(OfferingData);

    let MonthData = [...commonmonthValues];
    CheckedMonthData.map(a => {
      if (a !== "" && !UnCheckedMonthData.some(b => b == a)) {
        MonthData = MonthData.filter(c => c !== a);
      }
    })
    setcommonmonthValues(MonthData);


    let CategoriesList = [...categoriesList];
    CheckedCategoryIDs.map(a => {
      if (a !== "" && !UnCheckedCategoryIDs.some(b => b == a)) {
        CategoriesList = categoriesList.filter(c => c.ID !== a);
      }
    })
    setCategoriesList(CategoriesList);



  }



  const setFilterDataOne = (Data) => {
    if (Data.Offering !== "") {
      let OffOptions = [...offeringOptions];
      if (!offeringOptions.some(a => a.OfferingName_En === Data.Offering)) {
        let offering = NewofferingOptions.filter(b => b.OfferingName_En === Data.Offering)[0];
        OffOptions = OffOptions.concat(offering);
        setofferingOptions(OffOptions);
      }
    }
    if (Data.Month !== 0 && Data.Year !== "") {
      let MonthValues = [...commonmonthValues];
      let MonthYear = allmonth[parseInt(Data.Month) - 1] + " " + Data.Year;
      if (!commonmonthValues.some(a => a.trim().toUpperCase() === MonthYear.trim().toUpperCase())) {
        let newMonthyear = NewcommonmonthValues.filter(b => b === Data.Offering)[0];
        MonthValues = MonthValues.concat(newMonthyear);
        setcommonmonthValues(MonthValues);
      }
    }
    if (Data.CategoryIDs !== undefined && Data.CategoryIDs !== null && Data.CategoryIDs.length > 0) {
      let Categories = [...categoriesList];
      Data.CategoryIDs.map(a => {
        if (!categoriesList.some(b => b.ID === a)) {
          let newCatData = NewCategoriesList.filter(c => c.ID === a)[0]
          Categories = Categories.concat(newCatData)
        }
      });
      setCategoriesList(Categories);
    }
    /* if(Data.SubOfferingID!==""){
      let OffOptions=[...offeringOptions];
      if(!offeringOptions.some(a=>a.a.OfferingName_En===Data.Offering)){
        let offering=NewofferingOptions.filter(a=>a.OfferingName_En===Data.Offering)[0];
        OffOptions=OffOptions.concat(offering);
        setofferingOptions(OffOptions);
      }
    } */
  }
  const handleSavePopup = (type, isFolderAddEdit, data) => {
    let Data = { ...data, isParentFavourite: isParentFavourite };

    if (isFolderAddEdit) {
      let apiUrl =
        type === "Add" ? "MOR/AddNewMORFolder" : "MOR/EditNewMORFolder";
      axiosInstance
        .post(`${LocalApiBaseUrl}${apiUrl}`, Data)
        .then((response) => {
          // window.alert(response.data.StatusMessage)
          if (!response.data.IsSuccess) {
            setErrorMessage(
              `This Destination Already Contains a Folder Named ${data.FolderName}`
            );
          } else {
            GetBaseFilterByMode();
            //setFilterDataOne(Data);
            setErrorMessage("Close");
            setisShowNotification(true);
            let message = "";

            if (type === "Add") {
              let BackParentDataArray = [];
              message = `${props.clientData.name}/${Data.FolderName} added successfully`;
              setisFolderAdded(true);
              setisFolderEdited(false);
              setisItemAdded(false);
              setisItemEdited(false);

              if (BackParentIDArray.length > 0) {


                BackParentDataArray = BackParentIDArray.map((a, index) => {
                  if (index === BackParentIDArray.length - 1) {
                    a.ItemCount = a.ItemCount + 1;
                    return a;
                  } else {
                    return a;
                  }
                });
                setBackParentIDArray(BackParentDataArray);
              }
            } else {
              message = `${Data.FolderName} updated successfully`;
              setisFolderAdded(false);
              setisFolderEdited(true);
              setisItemAdded(false);
              setisItemEdited(false);
            }
            setfolderItemMessage(message);
          }
        })
        .catch((err) => setisShowNotification(false));
    } else {
      let apiUrl = type === "Add" ? "MOR/AddNewMORItem" : "MOR/EditNewMORItem";
      axiosInstance
        .post(`${LocalApiBaseUrl}${apiUrl}`, Data)
        .then((response) => {
          // window.alert(response.data.StatusMessage)
          if (!response.data.IsSuccess) {
            setErrorMessage(
              `This Destination Already Contains a File Named ${data.ItemName}`
            );
          } else {
            if (
              props.pulsePageData.offeringId !== undefined &&
              props.pulsePageData.offeringId !== 0
            ) {
              GetAllPulseMORItemData();
            }

            GetBaseFilterByMode();
            //setFilterDataOne(Data);
            setErrorMessage("Close");
            setisShowNotification(true);
            let message = "";
            if (type === "Add") {
              let BackParentDataArray = [];
              message = `${props.clientData.name}/${Data.ItemName} added successfully`;
              setisFolderAdded(false);
              setisFolderEdited(false);
              setisItemAdded(true);
              setisItemEdited(false);
              if (BackParentIDArray.length > 0) {

                BackParentDataArray = BackParentIDArray.map((a, index) => {
                  if (index === BackParentIDArray.length - 1) {
                    a.ItemCount = a.ItemCount + 1;
                    return a;
                  } else {
                    return a;
                  }
                });
                setBackParentIDArray(BackParentDataArray);
              }
            } else {
              message = `${Data.ItemName} updated successfully`;
              setisFolderAdded(false);
              setisFolderEdited(false);
              setisItemAdded(false);
              setisItemEdited(true);
            }
            setfolderItemMessage(message);
          }
        })
        .catch((err) => setisShowNotification(false));
    }
  };

  useEffect(() => {
    //debugger;
    if (
      ParentRef.current &&
      ParentRef.current !== undefined &&
      ParentRef.current !== null &&
      BackParentIDArray.length > 0
    ) {
      ParentRef.current.addEventListener("click", () => {
        goBack(0, BackParentIDArray[0].MORFolderID);
      });
    }
  }, [ParentRef, BackParentIDArray]);

  useEffect(() => {
    if (!isBasicApiLoading && allLevelData.length > 0) {

      let OffingData = [
        ...new Set(
          allLevelData.filter((a) => !isNull(a.Offering_En)).map((a) => a.Offering_En)
        ),
      ];
      setGetOffingData(OffingData);

      let selectedoffering =
        offeringName !== "All Offerings" && offeringName !== "Blank"
          ? OffingData.length > 0 &&
            OffingData.some(
              (a) =>
                a.trim().toUpperCase() === offeringName.trim().toUpperCase()
            )
            ? offeringName
            : "All Offerings"
          : offeringName;
      if (offeringName !== selectedoffering) setofferingName(selectedoffering);


      let subOffingData1 = [
        ...new Set(
          allLevelData.filter((a) => !isNull(a.SubOffering_En)).map(
            (a) => a.SubOfferingID
          )
        ),
      ];
      let data = filterAllSelectedSubOfferings(NewofferingOptions, subOffingData1)
      let subOffingData = [];
      data.updatedList.map(each => {
        each.ChildOfferings.map(eachChild => {
          subOffingData.push(eachChild)
        })
      })
      setGetsubOffingData(subOffingData);
      let NewofferingOptionsPopUp = [];
      const subDupOffingData = _.cloneDeep(subOffingData)
      subDupOffingData.map(eachData => {
        var data = filterSelectedSubOffering([{ ...eachData }])
        NewofferingOptionsPopUp.push(...data.allLeafNodes);
      })
      setNewofferingOptionsPopUp(NewofferingOptionsPopUp);

      let selectedsubofferingName =
        subofferingName !== "All Sub Offerings" && subofferingName !== "Blank"
          ? NewofferingOptionsPopUp.length > 0 &&
            NewofferingOptionsPopUp.some(
              (a) =>
                a.Process.trim().toUpperCase() === subofferingName.trim().toUpperCase()
            )
            ? subofferingName
            : "All Sub Offerings"
          : subofferingName;

      if (subofferingName !== selectedsubofferingName) {
        setsubofferingName(selectedsubofferingName);
        if (selectedsubofferingName === "All Sub Offerings") {
          setOfferingID(0);
        }
        if (selectedsubofferingName === "Blank") {
          setOfferingID(null);
        }
      }

      let MonthData = [
        ...new Set(
          allLevelData.filter((a) => a.MonthYear !== "").map((a) => a.MonthYear)
        ),
      ];
      setGetMonthData(MonthData);
      let selectedcommondefaultMonth =
        commondefaultMonth !== "All Months" && commondefaultMonth !== "Blank"
          ? MonthData.length > 0 &&
            MonthData.some(
              (a) =>
                a.trim().toUpperCase() ===
                commondefaultMonth.trim().toUpperCase()
            )
            ? commondefaultMonth
            : "All Months"
          : commondefaultMonth;
      if (commondefaultMonth !== selectedcommondefaultMonth)
        setcommondefaultMonth(selectedcommondefaultMonth);

      let CategoryIDs = [];
      let CategoriesList = [];
      allLevelData.filter((a) => a.IsFolder === false).map((a) =>
        a.CategoryIDs.map((a) => (CategoryIDs = CategoryIDs.concat(a)))
      );
      CategoryIDs = [...new Set(CategoryIDs)];

      setGetCategoryIDs(CategoryIDs);

      let selectedCategory =
        Category !== "All Categories" && Category !== "Blank"
          ? CategoryIDs.length > 0 &&
            CategoryIDs.some((a) => a === CategoryIDs[0])
            ? Category
            : "All Categories"
          : Category;
      if (Category !== selectedCategory) {
        setCategory(selectedCategory);
        if (selectedCategory === "All Categories") {
          setCategoryIDs([]);
        }
      }

      OffingData = NewofferingOptions.filter((a) => {
        if (
          a.OfferingName_En !== "" &&
          OffingData.some(
            (b) =>
              b.trim().toUpperCase() === a.OfferingName_En.trim().toUpperCase()
          )
        ) {
          return a;
        }
      });
      setisShowNoffering(allLevelData.some(a => a.Offering === "" || a.Offering === null));
      setofferingOptions(OffingData);

      let ChildOfferings = [], OfferingIds = []

      OffingData.length > 0 && OffingData.map(a => a.ChildOfferings.map(b =>
        ChildOfferings = ChildOfferings.concat(
          { offeringName: a.OfferingName_En, offeringID: b.OfferingID })
      ));

      if (OfferingID !== 0 && OfferingID !== null) {
        if (NewofferingOptionsPopUp.some(a => a.OfferingID === OfferingID)) {
          let parentName = NewofferingOptionsPopUp.length > 0 && NewofferingOptionsPopUp.filter(a => a.OfferingID
            === OfferingID)[0].ParentOfferingName
          if (parentName !== offeringName) {
            //setofferingName(parentName);
            setofferingName("All Offerings");
          }
        }
        else {
          setofferingName("All Offerings");
          setsubofferingName("All Sub Offerings")
        }
      }


      setisShowNoSubffering(allLevelData.some(a => a.SubOffering === "" || a.SubOffering === null));


      setprocessOptions(subOffingData);

      if (offeringName === "Blank") {
        /* setsubofferingName("Blank");
        setOfferingID(null); */

        /* setsubofferingName("All Sub Offerings");
        setOfferingID(null); */
        setisShowNoSubffering(allLevelData.some(a => a.SubOffering === "" || a.SubOffering === null));
      }
      let monthdatas = []
      if (IsArchiveMode) {
        monthdatas = [...archivemonthValues]
      }
      else {
        monthdatas = [...NewcommonmonthValues]
      }

      // MonthData = MonthData.length > 0 && monthdatas.length > 0 ? monthdatas.filter((a) => {
      //   if (
      //     MonthData.some(
      //       (b) => b.trim().toUpperCase() === a.trim().toUpperCase()
      //     )
      //   ) {
      //     return a;
      //   }
      // }) : [];
      setisShowNoMonth(allLevelData.some(a => a.MonthYear === "" || a.MonthYear === null));
      const FormattedMonth = MonthData.filter((o) => !isNull(o))
      .sort((a, b) => moment(b) - moment(a));
       setcommonmonthValues(FormattedMonth);

      CategoriesList = NewCategoriesList.filter((a) => {
        if (CategoryIDs.some((b) => b === a.ID)) {
          return a;
        }
      });
      setCategoriesList(CategoriesList);
      setisShowNoCategory(allLevelData.some(a => a.Categorys === "" || a.Categorys === null));





      setisParentDataSet(false);
    }
  }, [isBasicApiLoading, allLevelData, offeringName,
    OfferingID,
    commondefaultMonth,
    Category]);

  useEffect(() => {
    if (OrginalData.length > 0) {
      let isallFavourite = OrginalData.filter((d) => d.IsChecked).every(
        (a) => a.IsFavourite
      );
      let isNoFavourite = OrginalData.filter((d) => d.IsChecked).every(
        (a) => !a.IsFavourite
      );

      let isParentfav =
        BackParentIDArray.length > 0
          ? BackParentIDArray[BackParentIDArray.length - 1].IsFavourite
          : false;
      setisParentFavourite(isParentfav);
      setisallFavourite(isallFavourite);
      setisNoFavourite(isNoFavourite);
      setFavouriteAction(isallFavourite || isNoFavourite);
    } else {
      setisNoFavourite(false);
      setisshowFavouriteAction(false);
      setFavouriteAction(false);
      /*  setofferingOptions([]);
       setprocessOptions([]);
       setcommonmonthValues([]);
       setCategoriesList([]); */
    }
  }, [OrginalData]);

  useEffect(() => {
    //debugger;
    if (liRef.current.length > 0 && BackParentIDArray.length > 0) {
      liRef.current = [...new Set(liRef.current.filter((a) => a !== null))];
      liRef.current.map((a, index) =>
        a.addEventListener("click", () => {
          goBack(index + 1, BackParentIDArray[index + 1].MORFolderID);

        })
      );

    }

  }, [liRef, BackParentIDArray]);

  const handlePageClickshotcut = (type) => {
    //"Begin","Decrement"
    let Page = 0;
    switch (type) {
      case "Begin":
        Page = 1;
        break;
      case "Decrement":
        if (currentPage !== 1) Page = currentPage - 1;
        else Page = currentPage;
        break;
      case "Increement":
        if (currentPage !== PageCount.length) Page = currentPage + 1;
        else Page = currentPage;
        break;
      case "End":
        Page = PageCount.length;
        break;

      default:
        return;
    }
    setcurrentPage(Page);
  };
  const handlePageClick = (Page) => {
    setcurrentPage(Page);
  };
  const setcurrentPageData = (AllData) => {
    //let dividepage=Math.ceil(perpage/2);

    let CopyMORFoldersItems = [...AllData];

    let offset = (currentPage - 1) * perpage; //0 *10=0

    CopyMORFoldersItems = CopyMORFoldersItems.slice(offset, offset + perpage); //0,0+10=10

    /* let slicedMORFolder = CopyMORFoldersItems.filter((a) => a.IsFolder); //5
    let slicedMORItems = CopyMORFoldersItems.filter((a) => !a.IsFolder); ////5 */

    setClonedData(CopyMORFoldersItems);
  };
  /* const setcurrentPageData=()=>{
      let dividepage=Math.ceil(perpage/2);
      let CopyMORFolders=[...MORFolders];
      let CopyMORItems=[...MORItemsModel];
      let offset=(currentPage-1) * dividepage;//1 *5=5
   
     let slicedMORFolder = CopyMORFolders.slice(offset,offset+dividepage)//5,10
     let slicedMORItems = CopyMORItems.slice(offset,offset+dividepage)////5,10
   
     if((slicedMORItems.length-dividepage)!==0){
       slicedMORFolder=[...slicedMORFolder,
         CopyMORFolders.slice((offset+dividepage),
         ((offset+dividepage)+
         ((slicedMORItems.length-dividepage))))]
     }
     if((slicedMORFolder.length-dividepage)!==0){
       slicedMORItems=[...slicedMORItems,CopyMORItems.slice((offset+dividepage),((offset+dividepage)+((slicedMORFolder.length-dividepage))))]
     }
   
     setClonedMORFolders(slicedMORFolder);
     setClonedMORItemsModel(slicedMORItems);
    } */
  useEffect(() => {
    /* if(isSelectAll && !gridLayoutStatus){
      multiSelectOrDeselect(isSelectAll);
    } */
    if (totalSelectedCount > 0 || (query !== "" && query !== null)) {
      setFreezefilter(true);
    } else {
      setFreezefilter(false);
    }

    if (gridLayoutStatus) {
      if (query !== "" && query !== null) {
        let queryData = commonsearchFilter(query);
        if (isSelectAll) {
          queryData = queryData.map((a, index) => ({ ...a, IsChecked: true }));
        }
        if (!isSelectAll) {
          queryData = queryData.map((a, index) => ({ ...a, IsChecked: false }));
        }
        setClonedData(queryData);
        setOrginalData(queryData);
        setTotalResultCount(queryData.length);
      } else {
        setClonedData(ClonedNewData);
        setOrginalData(ClonedNewData);
        setTotalResultCount(OrginalData.length);
      }
    } else {
      if (ClonedNewData.length > 0) {
        if (query !== "" && query !== null) {
          let queryData = commonsearchFilter(query);
          if (isSortingMode) {
            queryData = SortDataByHeading(sortHeading, SortBytype, queryData);
          }
          if (isSelectAll) {
            queryData = queryData.map((a, index) => ({
              ...a,
              IsChecked: true,
            }));
          }
          if (!isSelectAll) {
            queryData = queryData.map((a, index) => ({
              ...a,
              IsChecked: a.IsChecked ? a.IsChecked : false,
            }));
          }
          setOrginalData(queryData);
          setcurrentPageData(queryData);
          setTotalResultCount(queryData.length);
        } else {
          let SortData = [];
          if (isSortingMode) {
            SortData = SortDataByHeading(
              sortHeading,
              SortBytype,
              ClonedNewData
            );
          } else {
            SortData = [...ClonedNewData];
          }
          setOrginalData(SortData);
          setcurrentPageData(SortData);
          setTotalResultCount(SortData.length);
        }
      }
    }
  }, [
    currentPage,
    gridLayoutStatus,
    isSortingMode,
    sortHeading,
    SortBytype,
    isloading,
    query,
    totalSelectedCount,
    perpage,
    isSelectAll,
  ]);

  const getCategory = () => {
    axiosInstance
      .get(`${LocalApiBaseUrl}MOR/GetAllMORCategories`)
      .then((response) => {
        let newData = response.data.map((d) => ({
          ...d,
          checked: true,
        }));
        setCategoriesList(newData);
        setNewCategoriesList(newData);
        setCategoryApiloaded(false);

      })
      .catch(() => {
        setCategoryApiloaded(false);
      });
  };

  const getConfigDetails = () => {
    axiosInstance
      .get(`${LocalApiBaseUrl}Configurator/GetAppConfig`)
      .then((response) => {
        let data = response.data;
        let perpage = parseInt(
          data.filter((a) => a.ConfigKey === "MorPageSizeDifference")[0]
            .ConfigValue
        );
        setConfigperpage(perpage);
        setperpage(perpage);
        setMorBreadCrumb(
          parseInt(
            data.filter((a) => a.ConfigKey === "MorBreadCrumb")[0].ConfigValue
          )
        );
      });
  };
  const setpulseofferingdata = () => {
    const {
      pulseOfferingData,
      PulseOffering,
      PulseofferingId,
      PulseSubOffering,
      ItemDataLevel,
      isCategoryData,
    } = props;

    let offeringValues = pulseOfferingData;
    let offeringOptions = pulseOfferingData;
    let offeringName = PulseOffering;
    let OfferingID = PulseofferingId;
    let subofferingName = PulseSubOffering;
    let CategoryID = ItemDataLevel.CategoryID;
    let ChildOfferings = []

    offeringOptions.length > 0 && offeringOptions.map(a => a.ChildOfferings.map(b =>
      ChildOfferings = ChildOfferings.concat(b)
    ));
    setNewprocessOptions(ChildOfferings);
    setprocessOptions(ChildOfferings);
    if (ItemDataLevel.Level === "offeringLevel") {
      setofferingValues(offeringValues);
      setofferingOptions(offeringOptions);
      setNewofferingOptions(offeringOptions);
      setofferingName(offeringName);
      setOfferingID(0);
      setsubofferingName("All Sub Offerings");
    }
    if (ItemDataLevel.Level === "SubofferingLevel") {
      setofferingValues(offeringValues);
      setofferingOptions(offeringOptions);
      setNewofferingOptions(offeringOptions);
      setofferingName(offeringName);
      setOfferingID(OfferingID);
      setsubofferingName(subofferingName);
    }
    if (ItemDataLevel.Level === "ClientLevel") {
      setofferingValues(offeringValues);
      setofferingOptions(offeringOptions);
      setNewofferingOptions(offeringOptions);
      setofferingName("All Offerings");
      setOfferingID(0);
      setsubofferingName("All Sub Offerings");
      if (isCategoryData && categoriesList.length > 0) {
        let CategoryData = categoriesList.filter(
          (a) => a.ID === parseInt(CategoryID)
        );
        if (CategoryData.length > 0) {
          setCategory(CategoryData[0].Name);
          setCategoryIDs([parseInt(CategoryID)]);
        }
      } else {
        setCategory("All Categories");
        setCategoryIDs([]);
      }
    }

    setisBasicApiLoading(false);
  };
  useEffect(() => {
    if (props.ispulse && !CategoryApiloaded) {
      setpulseofferingdata();
    }
  }, [props.ispulse, CategoryApiloaded]);
  useEffect(() => {
    if (
      props.clientData !== undefined &&
      Object.entries(props.clientData).length !== 0 &&
      (props.history.location.pathname === "/OperationalReviews" ||
        props.ispulse)
    ) {
      //setisBasicApiLoading(true);
      setlanguageID(store.getState().CurrentFilter.languageData.id);
      Promise.all([
        UserGuideData(),
        displayMonths(),
        getArchieveMonths(),
        //getRoleData(),
        //getOfferings(props.clientData.id),
        !props.ispulse && getOfferings(props.clientData.id),
        getCategory(),
        getConfigDetails(),
      ])
        .then(() => {
          if (!props.ispulse) {
            setisBasicApiLoading(false);
          }
          //setisBasicApiLoading(false);
        })
        .catch(() => {
          setisBasicApiLoading(false);
        });

      setOrgEntityID(props.clientData.id);
      if (
        props.roleReducers.filter((d) => d.FeatureName === "Operational Review")
          .length === 1
      ) {
        let data = props.roleReducers
          .filter((d) => d.FeatureName === "Operational Review")[0]
          .Operations.includes("Edit")
          ? "Edit"
          : "View";
        setaccessControl(data);
      }
    }

    return () => { };
  }, [props.clientData]);
  const displayMonths = () => {
    axiosInstance
      .get(`${LocalApiBaseUrl}MOR/GetMORMonths`)
      //.get(`http://localhost:51045/api/MOR/GetMORMonths`)
      .then((response) => {
        let FormattedMonth = response.data.map((a) => {
          return moment(a).format("MMM YYYY");
        });
        setmonthList(FormattedMonth);

        /*  let monthValues = FormattedMonth.map((x) => {
          return (
            <Dropdown.Item
              key={x}
              id={x}
              onClick={(e) => onClickFetchMORMonthOffering(e, x, false)}
            >
              {x}
            </Dropdown.Item>
          );
        });
        let formmonthValues = FormattedMonth.map((x) => {
          return (
            <Dropdown.Item
              key={x}
              id={x}
              onClick={(e) => onClickFetchMORMonthOffering(e, x, true)}
            >
              {x}
            </Dropdown.Item>
          );
        }); */

        //setcommonmonthValues(monthValues);
        setcommonmonthValues(FormattedMonth);
        setNewcommonmonthValues(FormattedMonth);
        if (FormattedMonth.length <= 0) {
          setcommondefaultMonth("No Month");
          setdefaultMonth("No Month");
        }
        /* setcommondefaultMonth(
          FormattedMonth.length !== 0 ? FormattedMonth[0] : "No Month"
        ); */
        //setmonthValues(formmonthValues);
        setmonthValues(FormattedMonth);
        /*  setdefaultMonth(
          FormattedMonth.length !== 0 ? FormattedMonth[0] : "No Month"
        ); */
      });
  };
  const UserGuideData = () => {
    axiosInstance
      /* .get(`${LocalApiBaseUrl}Configurator/GetMonth`) */
      .get(`${LocalApiBaseUrl}MOR/GetUserGuideData`)
      .then((response) => {
        let UserGuideData = response.data;
        setDocument(UserGuideData);
        setDoctype({
          ...Doctype,
          Id: UserGuideData[0].Id,
          DocName: UserGuideData[0].ConfigKey,
          DocLink: UserGuideData[0].ConfigValue,
          DocDescription: UserGuideData[0].Description,
        });
      });
  };
  const onPageSizeChange = (e) => {
    setcurrentPage(1);
    setPageCount([]);
    setperpage(parseInt(e.target.value));
  };
  useEffect(() => {


    if (OrginalData.length > 0) {
      if (!gridLayoutStatus)
        setcurrentPageData(OrginalData);

      let ResultCount = OrginalData.length;
      let FolderItemData = [],
        TotalCount = 0;

      if (OrginalData.length > 0) {
        FolderItemData = OrginalData.filter((a) => a.IsChecked);
      }

      TotalCount = FolderItemData.length;

      let index = 0;
      let pagesizesdata = [];
      let PageCounts = [];
      let TotalDatacount = OrginalData.length;
      while (index < TotalDatacount) {
        //2<5
        index += Configperpage; //0+2//2+2
        pagesizesdata = pagesizesdata.concat(index);
      }
      for (let i = 1; i <= Math.ceil(TotalDatacount / perpage); i++) {
        PageCounts.push(i);
      }
      PageCounts = [...new Set(PageCounts)];

      if (TotalCount === 1) {
        setisshowDeleteAction(true);
        setisshowArchiveAction(true);
        setisshowFavouriteAction(true);
        setisshowEditAction(true);
        setisShowDeSelect(true);

        setSingledata(OrginalData.filter((a) => a.IsChecked)[0].DocName);

        if (FolderItemData.filter((a) => a.IsFolder).length === 1) {
          setEditData({
            Type: "Folder",
            Data: FolderItemData.filter((a) => a.IsFolder)[0],
          });
        }
        if (FolderItemData.filter((a) => !a.IsFolder).length === 1) {
          setEditData({
            Type: "Item",
            Data: FolderItemData.filter((a) => !a.IsFolder)[0],
          });
        }
      } else if (TotalCount > 1) {
        setSingledata("");
        setisshowDeleteAction(true);
        setisshowArchiveAction(true);
        setisshowFavouriteAction(true);
        setisshowEditAction(false);
        setisShowDeSelect(true);
        setEditData({
          Type: "",
          Data: {},
        });
      } else {
        setisshowDeleteAction(false);
        setisshowArchiveAction(false);
        setisshowFavouriteAction(false);
        setisshowEditAction(false);
        setisShowDeSelect(false);
        setEditData({
          Type: "",
          Data: {},
        });
      }

      setisSelectAll(OrginalData.every((a) => a.IsChecked));
      setshowSelectall(OrginalData.some((a) => a.IsChecked));
      settotalSelectedCount(TotalCount);
      //setTotalResultCount(ResultCount);
      setPageDropDown(pagesizesdata);
      setPageCount(PageCounts);
    }
  }, [OrginalData, perpage]);

  /*   useEffect(() => {
    if (archiveLayoutStatus) {
      setcommonmonthValues(archivemonthValues);
      setcommondefaultMonth(archivedefaultMonth);
      setParentFolderID(null);
      setBackParentIDArray([]);
    } else {
      setcommonmonthValues(monthValues);
      setcommondefaultMonth(defaultMonth);
      setParentFolderID(null);
      setBackParentIDArray([]);
    }
  }, [archiveLayoutStatus]); */
  const morcloseNotication = () => {
    setSingledata("");
    setisShowNotification(false);
    setisMovedToArchieve(false);
    setisMovedToFavourite(false);
    setisReMovedFromArchieve(false);
    setisRemovedFromFavourite(false);
    setisFolderAdd(false);
    setisFolderAdded(false);
    setisFolderEdited(false);
    setisItemAdded(false);
    setisItemEdited(false);
    setisDeleted(false);
    setdataToDelete([]);
    if (!IsArchiveMode) getArchieveMonths();


    if (IsArchiveMode) {
      let month = [
        ...commonmonthValues.map((a) => a.toLowerCase().replace(" ", "-")),
      ];
      if (month.includes(commondefaultMonth.toLowerCase())) {
        GetAllMORData();
      } else {
        if (commondefaultMonth !== "All Months") {
          setcommondefaultMonth("All Months");
          setarchivedefaultMonth("All Months");
        } else {
          GetAllMORData();
        }
      }
    } else {
      GetAllMORData();
    }
  };
  const onClickFetchMORCategory = (e, each, Name) => {
    setisParentDataSet(true);
    setCategory(Name);
    if (Name === "All Categories") {
      setCategoryIDs([]);
      if (IsFavouriteMode) {
        setfavModeCategory(Name);
        setfavModeCategoryIDs([]);
      }
      if (IsArchiveMode) {
        setarchModeCategory(Name);
        setarchModeCategoryIDs([]);
      }
    } else if (Name === "Blank") {
      setCategoryIDs(null);
      if (IsFavouriteMode) {
        setfavModeCategory(Name);
        setfavModeCategoryIDs(null);
      }
      if (IsArchiveMode) {
        setarchModeCategory(Name);
        setarchModeCategoryIDs(null);
      }
    } else {
      setCategoryIDs([each.ID]);
      if (IsFavouriteMode) {
        setfavModeCategory(Name);
        setfavModeCategoryIDs([each.ID]);
      }
      if (IsArchiveMode) {
        setarchModeCategory(Name);
        setarchModeCategoryIDs([each.ID]);
      }
    }
  };
  useEffect(() => {
    if (!isSortingMode) {
      setsortHeading("");
      setSortBytype("");
    }
  }, [isSortingMode]);

  const setHeadingandSorttype = (HeadingType, SortBy) => {
    if (
      (HeadingType !== "" &&
        SortBy !== "" &&
        (HeadingType === sortHeading && SortBy !== SortBytype)) ||
      HeadingType !== sortHeading
    ) {
      setsortHeading(HeadingType);
      setSortBytype(SortBy);
      setisSortingMode(true);
    } else {
      setisSortingMode(false);
    }
  };

  const SortDataByHeading = (HeadingType, SortBy, Data) => {
    let OriginalFolderItemData = [...Data];
    switch (HeadingType) {
      case "Name":
        OriginalFolderItemData = _.orderBy(
          OriginalFolderItemData,
          [(item) => item.DocName.toLowerCase()],
          [SortBy]
        );
        break;
      case "Offerings":
        OriginalFolderItemData = _.orderBy(
          OriginalFolderItemData,
          [
            (item) =>
              item.Offering === null
                ? "".toLowerCase()
                : item.Offering.toLowerCase(),
          ],
          [SortBy]
        );
        break;
      case "SubOfferings":
        OriginalFolderItemData = _.orderBy(
          OriginalFolderItemData,
          [
            (item) =>
              item.SubOffering === null
                ? "".toLowerCase()
                : item.SubOffering.toLowerCase(),
          ],
          [SortBy]
        );
        break;
      case "Categories":
        OriginalFolderItemData = _.orderBy(
          OriginalFolderItemData,
          [(item) => item.Categorys.toLowerCase()],
          [SortBy]
        );
        break;
      case "Months":
        let NullData = [...OriginalFolderItemData].filter((a) =>
          isNull(a.Month)
        );

        if (SortBy === "asc") {
          OriginalFolderItemData = _.orderBy(
            OriginalFolderItemData.filter((a) => !isNull(a.Month)),
            [(datas) => datas.Year, (user) => user.Month],
            [SortBy, SortBy]
          );
          OriginalFolderItemData = [...NullData, ...OriginalFolderItemData];
        } else {
          OriginalFolderItemData = _.orderBy(
            OriginalFolderItemData.filter((a) => !isNull(a.Month)),
            [(datas) => datas.Year, (user) => user.Month],
            [SortBy, SortBy]
          );
          OriginalFolderItemData = [...OriginalFolderItemData, ...NullData];
        }
        break;
      default:
        return;
    }
    return OriginalFolderItemData;
  };
  const markorunmarkfavouriteTileview = (data) => {
    /* if(IsFavouriteMode && gridLayoutStatus){
       let docdata=[];
            setisMovedToFavourite(false);
        setisRemovedFromFavourite(true);
        if(data.IsFolder){
          docdata=[...ClonedData.filter((fold)=>fold.IsFolder && fold.MORFolderID === data.MORFolderID)]
        }
        else{
          docdata=[...ClonedData.filter((Item)=>!Item.IsFolder && Item.MORItemID === data.MORItemID)]
        }
        setdataToDelete(docdata);
      } */

    if (data.IsFolder) {
      if (data.IsFavourite) {
        moveandremove([data.MORFolderID], [], false, false, false, false);
      } else {
        moveandremove([data.MORFolderID], [], false, false, true, false);
      }
      ClonedData = ClonedData.map((fold) => {
        if (fold.IsFolder && fold.MORFolderID === data.MORFolderID) {
          fold.IsFavourite = !fold.IsFavourite;
        }
        return fold;
      });
    } else {
      if (data.IsFavourite) {
        moveandremove([], [data.MORItemID], false, false, false, false);
      } else {
        moveandremove([], [data.MORItemID], false, false, true, false);
      }
      ClonedData = ClonedData.map((Item) => {
        if (!Item.IsFolder && Item.MORItemID === data.MORItemID) {
          Item.IsFavourite = !Item.IsFavourite;
        }
        return Item;
      });
    }
    /* if(gridLayoutStatus && IsFavouriteMode)
      {
        ClonedData = ClonedData.filter(e=>e.IsFavourite)
      } */
    setClonedData(ClonedData);
  };
  const setdeleteData = (isMultiple, data) => {
    setisMultipleDelete(isMultiple);
    setdeleteItemModalStatus(true);
    if (data.length === 0)
      setdataToDelete([...OrginalData.filter((a) => a.IsChecked)]);
    else {
      setdataToDelete([...data]);
    }
  };
  const handleViewerDelete = (itemDetails) => {
    setdeleteData(false, [itemDetails]);
    setItemDeatils(itemDetails);
  };
  const deleteItemModalClose = () => {
    setdeleteItemModalStatus(false);
  };
  const deleteData = () => {
    if (isMultipleDelete) {
      DeleteSelectedItemOrFolder({});
    } else {
      DeleteSelectedItemOrFolder(ViewerItemDeatils);
    }
  };

  const DeleteSelectedItemOrFolder = (ItemData) => {
    setisDeleted(true);
    let FolderIds = [];
    let ItemIds = [];
    if (ItemData && Object.entries(ItemData).length !== 0) {
      ItemIds = [ItemData.MORItemID];
    } else {
      FolderIds = OrginalData.filter((a) => a.IsChecked && a.IsFolder).map(
        (a) => a.MORFolderID
      );
      ItemIds = OrginalData.filter((a) => a.IsChecked && !a.IsFolder).map(
        (a) => a.MORItemID
      );
    }
    let data = {
      FolderIds: FolderIds,
      ItemIds: ItemIds,
      IsArchiveMode: IsArchiveMode,
    };

    setisloading(true);
    axiosInstance
      .post(`${LocalApiBaseUrl}MOR/DeleteMORFolderItemData`, data, {
        //.post(`http://localhost:51045/api/MOR/DeleteMORFolderItemData`, data, {
        //.post(`http://localhost:51045/api/MOR/GetAllMORDataByFilter`, data, {
        params: {},
      })
      .then((response) => {
        setisloading(false);
        if (response.data.IsSuccess) {
          if (
            props.pulsePageData.offeringId !== undefined &&
            props.pulsePageData.offeringId !== 0
          ) {
            GetAllPulseMORItemData();
          }
          //setFilterDataTwo();
          GetBaseFilterByMode();
          getArchieveMonths();
          setdeleteItemModalStatus(false);
          setisShowNotification(true);
          closeViewer();
        } else {
          setdeleteItemModalStatus(false);
          closeViewer();
          alert(response.data.StatusMessage);
        }
      })
      .catch((error) => {
        setisloading(false);
      });
  };
  const moveandremove = (
    FolderIds,
    ItemIds,
    isArchiveAction,
    IsArchive,
    IsFavourite,
    isShowNotification
  ) => {
    /* if(FolderIds.length>0){
            ClonedMORFolders=ClonedMORFolders.map((fold) => {
              if (FolderIds.includes(fold.MORFolderID)) {
                if(isArchiveAction){
                  fold.IsArchive=IsArchive
                }
                else{
                  fold.isFavourite=IsFavourite
                }      
              }
              return fold;
            });
          }
          if(ItemIds.length>0){
            ClonedMORItemsModel=ClonedMORItemsModel.map((Item) => {       
              if (ItemIds.includes(Item.MORItemID)) {
                if(isArchiveAction){
                  Item.IsArchive=IsArchive
                }
                else{
                  Item.isFavourite=IsFavourite
                }      
              }
              return Item;
            });
          }
          setClonedMORFolders(ClonedMORFolders.filter(a=>!a.IsArchive));
          setClonedMORItemsModel(ClonedMORItemsModel.filter(a=>!a.IsArchive)); */
    let data = {
      FolderIds: FolderIds,
      ItemIds: ItemIds,
      isArchiveAction: isArchiveAction,
      IsArchive: IsArchive,
      IsFavourite: IsFavourite,
    };

    setisloading(true);
    axiosInstance
      .post(`${LocalApiBaseUrl}MOR/MarkOrUnMarkAsFaviouriteOrArchieve`, data, {
        //.post(`http://localhost:51045/api/MOR/MarkOrUnMarkAsFaviouriteOrArchieve`, data, {
        //.post(`http://localhost:51045/api/MOR/GetAllMORDataByFilter`, data, {
        params: {},
      })
      .then((response) => {
        setisloading(false);
        if (response.data.IsSuccess) {
          if (
            props.pulsePageData.offeringId !== undefined &&
            props.pulsePageData.offeringId !== 0
          ) {
            GetAllPulseMORItemData();
          }
          //setFilterDataTwo();
          GetBaseFilterByMode();
          if (IsArchiveMode) {
            getArchieveMonths();
            goBack(0, null);
          }
          if (isShowNotification || (gridLayoutStatus && IsFavouriteMode)) {
            setisShowNotification(true);
          }
        } else {
          alert(response.data.StatusMessage);
        }
      })
      .catch((error) => {
        setisloading(false);
      });
  };
  const MarkOrUnMarkAsFaviouriteOrArchieve = (IsArchive, IsMove) => {
    let FolderIds = OrginalData.filter((a) => a.IsChecked && a.IsFolder).map(
      (a) => a.MORFolderID
    );
    let ItemIds = OrginalData.filter((a) => a.IsChecked && !a.IsFolder).map(
      (a) => a.MORItemID
    );

    if (IsArchive) {
      if (IsMove) {
        setisMovedToArchieve(true);
        setisReMovedFromArchieve(false);
        moveandremove(FolderIds, ItemIds, true, true, false, true);
      } else {
        setisMovedToArchieve(false);
        setisReMovedFromArchieve(true);
        moveandremove(FolderIds, ItemIds, true, false, false, true);
      }
    } else {
      if (IsMove) {
        setisMovedToFavourite(true);
        setisRemovedFromFavourite(false);
        moveandremove(FolderIds, ItemIds, false, false, true, true);
      } else {
        setisMovedToFavourite(false);
        setisRemovedFromFavourite(true);
        moveandremove(FolderIds, ItemIds, false, false, false, true);
      }
    }
  };
  const gotoNormalMode = () => {
    setIsFavouriteMode(false);
    setIsArchiveMode(false);
    setParentFolderID(null);
    setBackParentIDArray([]);
    setofferingName(normModeofferingName);
    setOfferingID(normModeOfferingID);
    setcommondefaultMonth(normModeMonth);
    setcommonmonthValues(monthValues);
    setsubofferingName(normModesubofferingName);
    setCategory(normModeCategory);
    setCategoryIDs(normModeCategoryIDs);
    /* if (gridLayoutStatus) {
      setisApply(true);
    } */
  };
  const setMode = (isFavourite) => {
    setnormModeofferingName(offeringName);
    setnormModeOfferingID(OfferingID);
    setnormModesubofferingName(subofferingName);
    setnormModeMonth(commondefaultMonth);
    setnormModeCategory(Category);
    setnormModeCategoryIDs(CategoryIDs);
    setParentFolderID(null);
    setBackParentIDArray([]);
    //setNormModeCategory(Category);
    /*  if (gridLayoutStatus) {
      setisApply(true);
    } */
    if (isFavourite) {
      setIsFavouriteMode(true);
      setofferingName(favModeofferingName);
      setOfferingID(favModeOfferingID);
      setcommondefaultMonth(favModecommondefaultMonth);
      setsubofferingName(favModesubofferingName);
      setCategory(favModeCategory);
      setCategoryIDs(favModeCategoryIDs);
      //setCategory(favModeCategory);
      setIsArchiveMode(false);
    } else {
      setofferingName(archModeofferingName);
      setOfferingID(archModeOfferingID);
      setcommondefaultMonth(archModecommondefaultMonth);
      setsubofferingName(archModesubofferingName);
      setCategory(archModeCategory);
      setCategoryIDs(archModeCategoryIDs);
      //setCategory(archModeCategory);
      getArchieveMonths();
      setIsFavouriteMode(false);
      setIsArchiveMode(true);
    }
  };
  const getArchieveMonths = () => {
    axiosInstance
      .get(`${LocalApiBaseUrl}MOR/GetMORArchiveMonths`, {
        params: {
          OrgEntityID: props.clientData.id,
        },
      })
      //.get(`http://localhost:51045/api/MOR/GetMORArchiveMonths`)
      .then((response) => {
        const FormattedMonth = response.data.filter((o) => !isNull(o))
        .sort((a, b) => moment(b) - moment(a)).map((o)=> moment(o).format("MMM YYYY"));

        /* let monthValues = FormattedMonth.map((x) => {
          return (
            <Dropdown.Item
              key={x}
              id={x}
              onClick={(e) => onClickFetchMORMonthOffering(e, x)}
              active={commondefaultMonth === x}
            >
              {x}
            </Dropdown.Item>
          );
        }); */

        if (IsArchiveMode) {
          //setcommonmonthValues(monthValues);
          setcommonmonthValues(FormattedMonth);
        }

        setarchivemonthValues(FormattedMonth);
        setarchivedefaultMonth(
          FormattedMonth.length !== 0 ? FormattedMonth[0] : "No Month"
        );
      });
  };

  const handleEdit = () => {
    settypePopup("Update");

    if (EditData.Type === "Folder") {
      setisFolderAdd(true);
    } else if (EditData.Type === "Item") {
      setisFolderAdd(false);
    }

    setcreatenewModalStatus(true);
  };
  const GenerateExtensionIcon = (link) => {
    var matches = MorDocInfo.filter((a, index) => {
      if (
        link
          .trim()
          .toUpperCase()
          .includes(a.key.trim().toUpperCase())
      ) {
        return a;
      } else {
        return;
      }
    });

    if (matches.length > 0) {
      return matches[0];
    } else {
      if (
        link
          .trim()
          .toUpperCase()
          .includes("Doc.aspx".trim().toUpperCase()) &&
        !MorDocInfo.map((a) => a.key.toUpperCase()).includes(
          link.trim().toUpperCase()
        )
      ) {
        return MorDocInfo.filter((a) => a.key === "Word")[0];
      }
      return MorDocInfo.filter((a) => a.key === "Invalid")[0];
    }
  };
  const SetBaseFilterByMode = (OrginalData) => {
    let OffingData = [
      ...new Set(
        OrginalData.filter((a) => !isNull(a.Offering_En)).map((a) => a.Offering_En)
      ),
    ];
    setGetOffingData(OffingData);

    let selectedoffering =
      offeringName !== "All Offerings" && offeringName !== "Blank"
        ? OffingData.length > 0 &&
          OffingData.some(
            (a) =>
              a.trim().toUpperCase() === offeringName.trim().toUpperCase()
          )
          ? offeringName
          : "All Offerings"
        : offeringName;
    if (offeringName !== selectedoffering) setofferingName(selectedoffering);




    let subOffingData1 = [
      ...new Set(
        OrginalData.filter((a) => !isNull(a.SubOffering_En)).map(
          (a) => a.SubOfferingID
        )
      ),
    ];
    let data = filterAllSelectedSubOfferings(NewofferingOptions, subOffingData1)
    let subOffingData = [];
    data.updatedList.map(each => {
      each.ChildOfferings.map(eachChild => {
        subOffingData.push(eachChild)
      })
    })
    setGetsubOffingData(subOffingData);
    let NewofferingOptionsPopUp = [];
    const subDupOffingData = _.cloneDeep(subOffingData)
    subDupOffingData.map(eachData => {
      var data = filterSelectedSubOffering([{ ...eachData }])
      NewofferingOptionsPopUp.push(...data.allLeafNodes);
    })
    setNewofferingOptionsPopUp(NewofferingOptionsPopUp);
    let selectedsubofferingName =
      subofferingName !== "All Sub Offerings" && subofferingName !== "Blank"
        ? NewofferingOptionsPopUp.length > 0 &&
          NewofferingOptionsPopUp.some(
            (a) =>
              a.Process.trim().toUpperCase() === subofferingName.trim().toUpperCase()
          )
          ? subofferingName
          : "All Sub Offerings"
        : subofferingName;

    if (subofferingName !== selectedsubofferingName) {
      setsubofferingName(selectedsubofferingName);
      if (selectedsubofferingName === "All Sub Offerings") {
        setOfferingID(0);
      }
      if (selectedsubofferingName === "Blank") {
        setOfferingID(null);
      }
    }

    let MonthData = [
      ...new Set(
        OrginalData.filter((a) => a.MonthYear !== "").map((a) => a.MonthYear)
      ),
    ];
    setGetMonthData(MonthData);
    let selectedcommondefaultMonth =
      commondefaultMonth !== "All Months" && commondefaultMonth !== "Blank"
        ? MonthData.length > 0 &&
          MonthData.some(
            (a) =>
              a.trim().toUpperCase() ===
              commondefaultMonth.trim().toUpperCase()
          )
          ? commondefaultMonth
          : "All Months"
        : commondefaultMonth;
    if (commondefaultMonth !== selectedcommondefaultMonth)
      setcommondefaultMonth(selectedcommondefaultMonth);

    let CategoryIDs = [];
    let CategoriesList = [];
    OrginalData.filter((a) => a.IsFolder === false).map((a) =>
      a.CategoryIDs.map((a) => (CategoryIDs = CategoryIDs.concat(a)))
    );
    CategoryIDs = [...new Set(CategoryIDs)];

    setGetCategoryIDs(CategoryIDs);

    let selectedCategory =
      Category !== "All Categories" && Category !== "Blank"
        ? CategoryIDs.length > 0 &&
          CategoryIDs.some((a) => a === CategoryIDs[0])
          ? Category
          : "All Categories"
        : Category;
    if (Category !== selectedCategory) {
      setCategory(selectedCategory);
      if (selectedCategory === "All Categories") {
        setCategoryIDs([]);
      }
    }

    OffingData = NewofferingOptions.filter((a) => {
      if (
        a.OfferingName_En !== "" &&
        OffingData.some(
          (b) =>
            b.trim().toUpperCase() === a.OfferingName_En.trim().toUpperCase()
        )
      ) {
        return a;
      }
    });
    setofferingOptions(OffingData);

    let ChildOfferings = []

    OffingData.length > 0 && OffingData.map(a => a.ChildOfferings.map(b =>
      ChildOfferings = ChildOfferings.concat(b)
    ));
    setNewprocessOptions(ChildOfferings);

    setprocessOptions(subOffingData);
    let monthdatas = []
    if (IsArchiveMode) {
      monthdatas = [...archivemonthValues]
    }
    else {
      monthdatas = [...NewcommonmonthValues]
    }
    // MonthData = MonthData.length > 0 ? monthdatas.filter((a) => {
    //   if (
    //     MonthData.some(
    //       (b) => b.trim().toUpperCase() === a.trim().toUpperCase()
    //     )
    //   ) {
    //     return a;
    //   }
    // }) : [];
    const FormattedMonth = MonthData.filter((o) => !isNull(o))
      .sort((a, b) => moment(b) - moment(a));
      setcommonmonthValues(FormattedMonth);

    CategoriesList = NewCategoriesList.filter((a) => {
      if (CategoryIDs.some((b) => b === a.ID)) {
        return a;
      }
    });
    setCategoriesList(CategoriesList);

    setisShowNoffering(OrginalData.some(a => a.Offering === "" || a.Offering === null));
    setisShowNoSubffering(OrginalData.some(a => a.SubOffering === "" || a.SubOffering === null));
    setisShowNoMonth(OrginalData.some(a => a.MonthYear === "" || a.MonthYear === null));
    setisShowNoCategory(OrginalData.some(a => a.Categorys === "" || a.Categorys === null));

  }

  const GetBaseFilterByMode = () => {
    let data = {
      OrgEntityID: OrgEntityID,
      Offering: "All",
      SubOfferingID: 0,
      Year: 0,
      Month: 0,
      ParentMORFolderID: ParentFolderID,
      LanguageID: store.getState().CurrentFilter.languageData.id,
      PageNo: PageNo,
      PageSize: PageSize,
      Searchquery: "",
      IsFavourite: IsFavouriteMode,
      IsArchive: IsArchiveMode,
      CategoryIDs: [],
    };
    setisloading(true);
    axiosInstance
      .post(`${LocalApiBaseUrl}MOR/GetAllMORDataByFilter`, data, {
        //.post(`http://localhost:51045/api/MOR/GetAllMORDataByFilter`, data, {
        params: {},
      })
      .then((response) => {
        let BackParentDataArray = [];

        let MORFolders = response.data.MORFolders.sort((a, b) =>
          a.FolderName.toLowerCase() > b.FolderName.toLowerCase() ? 1 : -1
        );

        let MORItemsModel = response.data.MORItemsModel.sort((a, b) =>
          a.ItemName.toLowerCase() > b.ItemName.toLowerCase() ? 1 : -1
        );

        MORFolders = MORFolders.map((a) => {
          return {
            ...a,
            DocName: a.FolderName,
            DocName_En: a.FolderName_En,
            /* ItemCount:
              a.ItemCount > 0 && a.ItemCount < 9
                ? "0" + a.ItemCount
                : a.ItemCount, */
            MonthYear:
              a.Month != null && a.Year != null
                ? allmonth[parseInt(a.Month) - 1] + " " + a.Year
                : "",
            IsChecked: false,
          };
        });
        MORItemsModel = MORItemsModel.map((a, index) => {
          return {
            ...a,
            DocName: a.ItemName,
            DocName_En: a.ItemName_En,
            IsChecked: false,
            //Month:a.Month!=null ?allmonth[parseInt(a.Month) - 1]:"",
            MonthYear:
              a.Month != null && a.Year != null
                ? allmonth[parseInt(a.Month) - 1] + " " + a.Year
                : "",
            ExtensionAndIcon:
              a.ItemLink !== "" && a.ItemLink != null
                ? GenerateExtensionIcon(a.ItemLink)
                : "",
          };
        });

        let mergedData = _.orderBy(
          [...MORFolders, ...MORItemsModel],
          function (o) {
            return new moment(o.UpdatedTS);
          },
          ["desc"]
        );
        setallLevelData(mergedData);

        SetBaseFilterByMode(mergedData);
        setisloading(false);
      })
      .catch((error) => {
        setisloading(false);
      });
  }
  useEffect(() => {
    if (IsArchiveMode)
      SetBaseFilterByMode(allLevelData);
  }, [archivemonthValues, allLevelData])

  useEffect(() => {
    if (OrgEntityID > 0 && NewofferingOptions.length > 0) {
      GetBaseFilterByMode();
    }

  }, [IsArchiveMode, IsFavouriteMode, OrgEntityID, ParentFolderID, NewofferingOptions])

  const GetAllMORData = () => {
    setTotalDocumentsCount(0);
    setClonedData([]);
    setOrginalData([]);
    setPageCount([]);
    setPageDropDown([]);
    setquery("");
    setisSortingMode(false);
    //setisSelectAll(false);
    setsortHeading("");
    setSortBytype("");
    setisShowDummyCard(false);
    setisShowListCard(false);
    setIsExist(false);
    setisDummyCardBlurFired(false);
    setisDummyCardKDFired(false);
    setisShowDeSelect(false);
    setisSelectAll(false);

    let dynamicName = "";
    // FolderIndex!==null && (document.getElementById(FolderIndex).disabled = true);
    setFolderIndex(null);
    FolderRef.current = [];
    FolderAliasRef.current = [];

    let Offering = "",
      SubOfferingID = 0,
      Year = null,
      Month = null;
    //CategoryIDs = null;

    if (offeringName === "All Offerings") {
      Offering = "All";
    } else if (offeringName === "Blank") {
      Offering = null;
    } else {
      Offering = offeringName;
    }

    if (OfferingID === 0) {
      SubOfferingID = 0;
    } else if (OfferingID === null) {
      SubOfferingID = null;
    } else {
      SubOfferingID = OfferingID;
    }
    if (commondefaultMonth === "All Months") {
      Year = 0;
      Month = 0;
    } else if (commondefaultMonth === "Blank") {
      Year = null;
      Month = null;
    } else {
      Year = commondefaultMonth.split(" ")[1];
      Month = parseInt(allmonth.indexOf(commondefaultMonth.split(" ")[0]) + 1);
    }

    let data = {
      OrgEntityID: OrgEntityID,
      Offering: Offering,
      SubOfferingID: SubOfferingID,
      Year: Year,
      Month: Month,
      ParentMORFolderID: ParentFolderID,
      LanguageID: store.getState().CurrentFilter.languageData.id,
      PageNo: PageNo,
      PageSize: PageSize,
      Searchquery: "",
      IsFavourite: IsFavouriteMode,
      IsArchive: IsArchiveMode,
      CategoryIDs: CategoryIDs,
    };

    setisloading(true);
    setisGetLoading(true);
    axiosInstance
      .post(`${LocalApiBaseUrl}MOR/GetAllMORDataByFilter`, data, {
        //.post(`http://localhost:51045/api/MOR/GetAllMORDataByFilter`, data, {
        params: {},
      })
      .then((response) => {
        let BackParentDataArray = [];

        let MORFolders = response.data.MORFolders.sort((a, b) =>
          a.FolderName.toLowerCase() > b.FolderName.toLowerCase() ? 1 : -1
        );

        let MORItemsModel = response.data.MORItemsModel.sort((a, b) =>
          a.ItemName.toLowerCase() > b.ItemName.toLowerCase() ? 1 : -1
        );

        MORFolders = MORFolders.map((a) => {
          return {
            ...a,
            DocName: a.FolderName,
            DocName_En: a.FolderName_En,
            /* ItemCount:
              a.ItemCount > 0 && a.ItemCount < 9
                ? "0" + a.ItemCount
                : a.ItemCount, */
            MonthYear:
              a.Month != null && a.Year != null
                ? allmonth[parseInt(a.Month) - 1] + " " + a.Year
                : "",
            IsChecked: false,
          };
        });
        MORItemsModel = MORItemsModel.map((a, index) => {
          return {
            ...a,
            DocName: a.ItemName,
            DocName_En: a.ItemName_En,
            IsChecked: false,
            //Month:a.Month!=null ?allmonth[parseInt(a.Month) - 1]:"",
            MonthYear:
              a.Month != null && a.Year != null
                ? allmonth[parseInt(a.Month) - 1] + " " + a.Year
                : "",
            ExtensionAndIcon:
              a.ItemLink !== "" && a.ItemLink != null
                ? GenerateExtensionIcon(a.ItemLink)
                : "",
          };
        });

        let mergedData = _.orderBy(
          [...MORFolders, ...MORItemsModel],
          function (o) {
            return new moment(o.UpdatedTS);
          },
          ["desc"]
        );

        if (BackParentIDArray.length > 0) {

          BackParentDataArray = BackParentIDArray.map((a, index) => {
            if (index === BackParentIDArray.length - 1) {
              a.ItemCount = mergedData.length;
              return a;
            } else {
              return a;
            }
          });
          setBackParentIDArray(BackParentDataArray);
        }

        setTotalDocumentsCount(response.data.TotalDocumentsCount);
        setTotalResultCount(response.data.TotalDocumentsCount);

        if (response.data.TotalDocumentsCount <= 0) {
          setisShowNoDatamessage(true);
        } else {
          setisShowNoDatamessage(false);
        }

        //setisSelectAll(false);
        setOrginalData(mergedData);
        setClonedData(mergedData);
        setClonedNewData(mergedData);
        settotalSelectedCount(0);
        setcurrentPage(1);
        setDummyFolderName(dynamicName);
        setNewFolderName(dynamicName);
        setisloading(false);
        setisGetLoading(false);
      })
      .catch((error) => {
        setisloading(false);
        setisGetLoading(false);
      });
  };
  const ApplyFilter = () => {
    setisApply(true);
    setfilterPopoverStatus(false);
  };
  const ClearFilter = () => {
    setofferingName("All Offerings");
    setOfferingID(0);
    setcommondefaultMonth("All Months");
    setsubofferingName("All Sub Offerings");
    setCategory("All Categories");
    setCategoryIDs([]);
  };
  useEffect(() => {
    if (TotalDocumentsCount === 0) {
      setisSelectAll(false);
    }
  }, [TotalDocumentsCount, ClonedNewData]);
  //consider category
  /*  useEffect(()=>{

  },[isFilterActive]) */

  useEffect(() => {
    if (
      offeringName !== "All Offerings" ||
      subofferingName !== "All Sub Offerings" ||
      commondefaultMonth !== "All Months" ||
      Category !== "All Categories"
    ) {
      setisFilterActive(true);
    } else {
      setisFilterActive(false);
    }
  }, [offeringName, subofferingName, commondefaultMonth, Category]);

  useEffect(() => {
    if (
      OrgEntityID !== 0 &&
      !isBasicApiLoading /* ||
        commondefaultMonth !== "All Months" || As Client is only mandotory now
        commondefaultMonth !== "") ||
      (offeringName !== "All Offerings" || OfferingID !== null */
    ) {
      if (IsFavouriteMode) {
        setfavModeofferingName(offeringName);
        setfavModesubofferingName(subofferingName);
        setfavModeOfferingID(OfferingID);
        setfavModecommondefaultMonth(commondefaultMonth);
        setfavModeCategory(Category);
        setfavModeCategoryIDs(CategoryIDs);
      }
      if (IsArchiveMode) {
        //setcommonmonthValues(archivemonthValues);
        setarchModeofferingName(offeringName);
        setarchModesubofferingName(subofferingName);
        setarchModeOfferingID(OfferingID);
        setarchModecommondefaultMonth(commondefaultMonth);
      }
      /* if (gridLayoutStatus) {
        if (isApply) {
          setisApply(false);
          setclearofferingName(offeringName);
          setclearsubofferingName(subofferingName);
          setclearOfferingID(OfferingID);
          setclearCategory(Category);
          setclearCategoryIDs(CategoryIDs);
          setclearcommondefaultMonth(commondefaultMonth);
          GetAllMORData();
         }
      } else { */
      if (!isParentDataSet)
        GetAllMORData();
      /* } */
    }
  }, [
    OrgEntityID,
    offeringName,
    OfferingID,
    commondefaultMonth,
    Category,
    //commonmonthValues,
    ParentFolderID,
    // isApply,
    IsFavouriteMode,
    isBasicApiLoading,
    IsArchiveMode,
    isParentDataSet
    /*   gridLayoutStatus,
      listGridLayoutStatus, */
  ]);

  useEffect(() => {
    var path = "/";
    if (
      props.clientData !== undefined &&
      props.clientData.name &&
      offeringName !== "All Offerings" &&
      subofferingName !== "All Sub Offerings" &&
      OfferingID !== null
    ) {
      setPath(
        props.clientData.name +
        path +
        offeringName +
        path +
        subofferingName +
        path
      );
    } else if (
      props.clientData !== undefined &&
      props.clientData.name &&
      offeringName !== "All Offerings"
    ) {
      setPath(props.clientData.name + path + offeringName + path);
    } else if (props.clientData !== undefined && props.clientData.name) {
      setPath(props.clientData.name + path);
    }
  }, [props.clientData, offeringName, subofferingName]);

  useEffect(() => {
    let disableStatus = true;
    if (
      ItemDeatils.ItemName &&
      ItemDeatils.ItemName.trim() !== "" &&
      ItemDeatils.ItemLink &&
      ItemDeatils.ItemLink.trim() !== ""
    ) {
      disableStatus = false;
    }
    setisClearDisable(disableStatus);
    setisAddDisable(disableStatus);
  }, [ItemDeatils]);

  useEffect(() => {
    if (isShowDummyCard || isShowListCard) {
      nameRef.current.focus();
    }
  }, [isShowDummyCard, isShowListCard]);

  //Filter Applied
  useEffect(() => {
    if (
      offeringName === "All Offerings" &&
      subofferingName === "All Sub Offerings" &&
      commondefaultMonth === "All Months" &&
      Category === "All Category"
    ) {
      setfilterApplied(false);
    } else {
      setfilterApplied(true);
    }
  }, [offeringName, subofferingName, commondefaultMonth, Category]);

  const morcommentwindowShow = () => {
    let filterProps = {
      OrgEntityID: OrgEntityID,
      OrgEntityName: props.clientData.name,
      Offering:
        offeringName === "All Offerings"
          ? "All"
          : offeringName === "Blank"
            ? null
            : offeringName,
      SubOfferingID: OfferingID,
      SubofferingName:
        subofferingName === "All Sub Offerings"
          ? "All"
          : subofferingName === "Blank"
            ? null
            : subofferingName,
      Year:
        commondefaultMonth !== undefined &&
          commondefaultMonth !== "All Months" &&
          commondefaultMonth !== "Blank"
          ? commondefaultMonth.split(" ")[1]
          : commondefaultMonth === "Blank"
            ? null
            : 0,
      Month:
        commondefaultMonth !== undefined &&
          commondefaultMonth !== "All Months" &&
          commondefaultMonth !== "Blank"
          ? parseInt(allmonth.indexOf(commondefaultMonth.split(" ")[0]) + 1)
          : commondefaultMonth === "Blank"
            ? null
            : 0,
      ParentMORFolderID: ParentFolderID,
      LanguageID: store.getState().CurrentFilter.languageData.id,
      selectedMonth: commondefaultMonth,
    };

    let AddEditdata = {
      ...filterProps,
      Offering:
        offeringName === "All Offerings"
          ? ""
          : offeringName === "Blank"
            ? null
            : offeringName,
      SubofferingName:
        subofferingName === "All Sub Offerings"
          ? ""
          : subofferingName === "Blank"
            ? null
            : subofferingName,
      Year:
        commondefaultMonth !== undefined &&
          commondefaultMonth !== "All Months" &&
          commondefaultMonth !== "Blank"
          ? commondefaultMonth.split(" ")[1]
          : 0,
      Month:
        commondefaultMonth !== undefined &&
          commondefaultMonth !== "All Months" &&
          commondefaultMonth !== "Blank"
          ? parseInt(allmonth.indexOf(commondefaultMonth.split(" ")[0]) + 1)
          : 0,
    };
    setmorcommentwindowStatus(true);
    setFilterProps(filterProps);
    setaddEditCommentsProp(AddEditdata);
  };
  const morcommentwindowClose = () => {
    setmorcommentwindowStatus(false);
    setFilterProps(filterProps);
    setaddEditCommentsProp(addEditCommentsProp);
  };
  const morcommentHistoryShow = (commentsData) => {
    setMorcommentHistory(true);
    setmorcommentwindowStatus(false);
    setMorCommentsData(commentsData);
  };
  const morcommentHistoryClose = () => {
    setMorcommentHistory(false);
    setmorcommentwindowStatus(false);
  };

  /*  const deleteItemModalClose = () => {
    setDeleteItemDeatils({ ItemId: 0, ItemName: "", ItemLink: "" });
    setDeleteFolderDeatils({ FolderId: 0, FolderName: "" });
    setIsFolderDelete(false);
    setdeleteItemModalStatus(false);
  }; */

  const getOfferings = async (Client) => {
    return new Promise((resolve, reject) => {
      let offeringOptions = "";
      let offeringData = [];

      if (Client != undefined && Client > 0 && Client != "") {
        //axiosInstance.get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
        axiosInstance
          .get(`${LocalApiBaseUrl}MOR/GetMOROfferings`, {
            params: {
              OrgEntityID: Client,
              LanguageID: store.getState().CurrentFilter.languageData.id,
            },
          })
          .then((response) => {
            offeringData =
              response.data; /* .filter(function(e) {
              return !e.IsNew;
            }); */
            setofferingValues(offeringData);

            /*             let offeringOptions = offeringData.map((dropValue, index) => (
              <Dropdown.Item
                id={dropValue.OfferingID}
                onClick={(e) =>
                  onClickFetchMOROffering(
                    e,
                    offeringData,
                    dropValue.OfferingID,
                    false
                  )
                }
                active={offeringName === dropValue.OfferingName}
              >
                {dropValue.OfferingName}
              </Dropdown.Item>
            )) */ let formofferingOptions = offeringData.map(
              (dropValue, index) => (
                <Dropdown.Item
                  id={dropValue.OfferingID}
                  onClick={(e) =>
                    onClickFetchMOROffering(
                      e,
                      offeringData,
                      dropValue.OfferingID,
                      true
                    )
                  }
                >
                  {dropValue.OfferingName}
                </Dropdown.Item>
              )
            );

            setofferingOptions(offeringData);
            setNewofferingOptions(offeringData);
            setformofferingOptions(formofferingOptions);
          });
        resolve();
      }
    });
  };
  const onClickFetchMORSubOffering = (e) => {
    // if(BackParentIDArray.length>0){
    //setParentFolderID(null);
    //setBackParentIDArray([]);
    //    }
    setisParentDataSet(true);
    if (e.target.value === "All Sub Offerings") {
      setsubofferingName("All Sub Offerings");
      setOfferingID(0);

      if (IsFavouriteMode) {
        setfavModesubofferingName("All Sub Offerings");
        setfavModeOfferingID(0);
      }
      if (IsArchiveMode) {
        setarchModesubofferingName("All Sub Offerings");
        setarchModeOfferingID(0);
      }
    } else if (e.target.value === "Blank") {
      setsubofferingName("Blank");
      setOfferingID(null);

      if (IsFavouriteMode) {
        setfavModesubofferingName("Blank");
        setfavModeOfferingID(null);
      }
      if (IsArchiveMode) {
        setarchModesubofferingName("Blank");
        setarchModeOfferingID(null);
      }
    } else {
      const filtersSubofferingData = filterSelectedSubOffering([...processOptions], e.target.value)
      const selectedSuboffering = filtersSubofferingData.subofferingData[0];

      setsubofferingName(selectedSuboffering.Process);
      setOfferingID(selectedSuboffering.OfferingID);

      if (IsFavouriteMode) {
        setfavModesubofferingName(selectedSuboffering.Process);
        setfavModeOfferingID(selectedSuboffering.OfferingID);
      }
      if (IsArchiveMode) {
        setarchModesubofferingName(selectedSuboffering.Process);
        setarchModeOfferingID(selectedSuboffering.OfferingID);
      }
    }

    setTimeout(() => {
      isMORSubOfferingDropdownOverlay();
    }, 100);
  };

  const onClickFetchMOROffering = (e, offeringData, OfferingID, isForm) => {
    // if(BackParentIDArray.length>0){
    //setParentFolderID(null);
    //setBackParentIDArray([]);
    //}
    setisParentDataSet(true);
    if (OfferingID === "All Offerings") {
      setofferingName("All Offerings");
      //setprocessOptions([]);
      setsubofferingName("All Sub Offerings");
      setOfferingID(0);

      if (IsFavouriteMode) {
        setfavModeofferingName("All Offerings");
        setfavModesubofferingName("All Sub Offerings");
        setfavModeOfferingID(0);
      }
      if (IsArchiveMode) {
        setarchModeofferingName("All Offerings");
        setarchModesubofferingName("All Sub Offerings");
        setarchModeOfferingID(0);
      }
    } else if (OfferingID === "Blank") {
      setofferingName("Blank");
      //setprocessOptions([]);
      setsubofferingName("Blank");
      setOfferingID(null);

      if (IsFavouriteMode) {
        setfavModeofferingName("Blank");
        setfavModesubofferingName("Blank");
        setfavModeOfferingID(null);
      }
      if (IsArchiveMode) {
        setarchModeofferingName("Blank");
        setarchModesubofferingName("Blank");
        setarchModeOfferingID(null);
      }
    } else {
      let offeringDetails = offeringData.filter(
        (a) => a.OfferingID === OfferingID
      )[0];

      //setprocessOptions([]);
      setsubofferingName("All Sub Offerings");
      setOfferingID(0);

      //setofferingData(offeringData);
      setofferingName(offeringDetails.OfferingName_En);

      if (IsFavouriteMode) {
        setfavModeofferingName(offeringDetails.OfferingName_En);
        setfavModesubofferingName("All Sub Offerings");
        setfavModeOfferingID(0);
      }
      if (IsArchiveMode) {
        setarchModeofferingName(offeringDetails.OfferingName_En);
        setarchModesubofferingName("All Sub Offerings");
        setarchModeOfferingID(0);
      }

      let ChildOfferings = offeringDetails.ChildOfferings;

      //setprocessOptions(ChildOfferings);
      //setNewprocessOptions(ChildOfferings);
    }

    setTimeout(() => {
      isMOROfferingDropdownOverlay();
    }, 100);
  };

  const getRoleData = () => {
    var data = store.getState();
    let roleData1 = data.roleReducers["roleReducers"];
    setfeatureData(roleData1, () => {

    });

    store.subscribe(() => {
      var data = store.getState();
      setfeatureData(data.roleReducers["roleReducers"], () => {

      });
      let roleData1 = data.roleReducers["roleReducers"];

      setfeatureData(roleData1, () => {

      });
    });
  };
  const handleDocumentClose = (e) => {
    setisshowDocDropDown(false);
  };

  const commoneditFolder = (editdata, index) => {
    setisFolderAdd(false);
    setisFolderEdit(true);
    setisItemEdit(false);

    let Exist = MORFolders.some(
      (a) =>
        a.MORFolderID !== editdata.MORFolderID &&
        a.FolderName.toLowerCase().trim() ===
        editdata.FolderName.toLowerCase().trim()
    );
    if (Exist) {
      alert(
        "This Destination Already Contains a Folder Named " +
        editdata.FolderName
      );
      setIsExist(true);
      setisFolderEdit(false);
      if (gridLayoutStatus) {
        //FolderIndex !== null && (document.getElementById("s" + FolderIndex).style.display = "block");
        //FolderIndex !== null && (document.getElementById("p" + FolderIndex).style.display = "none");
        /* const newClonedMORFolders = [...ClonedMORFolders];
      newClonedMORFolders[FolderIndex].FolderName = PrevFolderName;
      newClonedMORFolders[FolderIndex].FolderName_En = PrevFolderName;
      setClonedMORFolders(newClonedMORFolders);
      setMORFolders(newClonedMORFolders); */
        FolderIndex !== null &&
          document.getElementById("p" + FolderIndex).focus();
        //setIsExist(false);
      } else {
        /*   const newClonedMORFolders = [...ClonedMORFolders];
          newClonedMORFolders[FolderIndex].FolderName = PrevFolderName;
          newClonedMORFolders[FolderIndex].FolderName_En = PrevFolderName;
        setClonedMORFolders(newClonedMORFolders);
        setMORFolders(newClonedMORFolders);
         */
        FolderIndex !== null &&
          document.getElementById("p1" + FolderIndex).focus();
        //setIsExist(false);
      }
    } else {
      setIsExist(false);
      if (!Exist) {
        let data = {
          FolderName: editdata.FolderName.trim(),
          MORFolderID: editdata.MORFolderID,
        };
        axiosInstance
          .post(`${LocalApiBaseUrl}MOR/EditMORFolder`, data, {
            params: {},
          })
          .then(
            (response) => {
              if (response.data.IsSuccess) {
                setFolderIndex(null);
                setaddfeatureNotificationStatus(true);
                // window.alert(response.data.StatusMessage);
                GetAllMORData();
              } else {
                window.alert(response.data.StatusMessage);
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
    }
  };
  const bindTextFieldChange = (e) => {
    const id = e.target.id;
    switch (id) {
      case "ItemName":
        setItemDeatils({ ...ItemDeatils, ItemName: e.target.value });
        //data.ItemName = e.target.value;
        break;
      case "ItemLink":
        setItemDeatils({ ...ItemDeatils, ItemLink: e.target.value });
        //data.ItemLink = e.target.value;
        break;
      case "FolderName":
        setFolderDeatils({ ...FolderDeatils, FolderName: e.target.value });
        //data.ItemLink = e.target.value;
        break;
      case "Document":
        var index = e.nativeEvent.target.selectedIndex;
        setDoctype({
          ...Doctype,
          Id: e.target.value,
          DocName: e.nativeEvent.target[index].text,
          DocLink: Document.filter((a) => a.Id === parseInt(e.target.value))[0]
            .ConfigValue,
          DocDescription: Document.filter(
            (a) => a.Id === parseInt(e.target.value)
          )[0].Description,
        });
        break;
      default:
        return;
    }
    //setItemDeatils(data);
  };
  const validateForm = () => {
    //debugger;
    let data = ItemDeatils;
    let errors = this.state.errors;
    let isValid = true;
    Object.keys(data).map((key) => {
      switch (key) {
        case "ItemName":
          data[key]
            ? (errors[key] = "")
            : (errors[key] = i18n.t("ItemName_is_Required"));
          if (errors[key]) isValid = false;
          break;
        case "ItemLink":
          data[key]
            ? (errors[key] = "")
            : (errors[key] = i18n.t("ItemLink_is_Required"));
          if (errors[key]) isValid = false;
          break;
      }
    });
    return isValid;
  };
  const handleView = () => {
    setisMORShowDocViewer(true);
    setisshowDocDropDown(false);
  };

  const multiSelectOrDeselect = (isSelectAll) => {
    if (isSelectAll) {
      ClonedNewData = ClonedNewData.map((a, index) => ({
        ...a,
        IsChecked: true,
      }));
      setisSelectAll(isSelectAll);
    } else {
      ClonedNewData = ClonedNewData.map((a, index) => ({
        ...a,
        IsChecked: false,
      }));
      setisSelectAll(false);
      setisShowDeSelect(false);
    }
    setOrginalData(ClonedNewData);
    setClonedNewData(ClonedNewData);
    setClonedData(ClonedNewData);
  };

  const handleCheckbox = (e, data, isFolder) => {
    e.stopPropagation();

    let isChecked = e.target.checked;
    if (isFolder) {
      //let MORFolders=[];
      ClonedNewData = ClonedNewData.map((a, index) =>
        a.IsFolder && a.MORFolderID === data.MORFolderID
          ? { ...a, IsChecked: isChecked }
          : a
      );
      setClonedNewData(ClonedNewData);
      setOrginalData(ClonedNewData);
      setClonedData(ClonedNewData);
    } else {
      //let MORItemsModel=[];
      ClonedNewData = ClonedNewData.map((a, index) =>
        !a.IsFolder && a.MORItemID === data.MORItemID
          ? { ...a, IsChecked: isChecked }
          : a
      );
      setClonedNewData(ClonedNewData);
      setOrginalData(ClonedNewData);
      setClonedData(ClonedNewData);
    }
  };

  const createnewFolderItem = (isFolder) => {
    if (isFolder) {
      setisFolderAdd(true);
    } else {
      setisFolderAdd(false);
    }
    setcreatenewModalStatus(true);
    settypePopup("Add");
    setEditData({
      Type: "",
      Data: {},
    });
  };
  const filterPopoverShow = () => {
    setfilterPopoverStatus(true);
  };
  const filterPopoverClose = () => {
    setfilterPopoverStatus(false);
    /*  if (!isApply) {
      setofferingName(clearofferingName);
      setsubofferingName(clearsubofferingName);
      setOfferingID(clearOfferingID);
      setcommondefaultMonth(clearcommondefaultMonth);
      setCategory(clearCategory);
      setCategoryIDs(clearCategoryIDs);
    } */
  };

  const ExtractSrcLink = (Link) => {
    const DocsearchTerm = 'src="';
    const VideoImagesearchTerm = "?";
    const Extensions = [
      ".mp4",
      ".mov",
      ".webm",
      ".mkv",
      ".ogv",
      ".3g2",
      ".jpeg",
      ".jpg",
      ".giff",
      ".png",
    ];
    let isDoc;
    if (Extensions.some((v) => Link.includes(v))) {
      isDoc = false;
      return `${Link.split(VideoImagesearchTerm)[0]}`;
    } else {
      if (Link.includes("<iframe")) {
        isDoc = true;
        return `${Link.split(DocsearchTerm)[1].split('"')[0]}`;
      } else {
        return Link;
      }
    }
  };

  const handleClose = () => {
    setaddfeatureNotificationStatus(false);
    setisFolderAdd(false);
    setisFolderEdit(false);
  };
  const gridLayout = () => {
    setgridLayoutStatus(true);
    setlistGridLayoutStatus(false);
    filterPopoverClose();
    //FolderIndex !== null && (document.getElementById(FolderIndex).disabled = true);
    setFolderIndex(null);
  };
  const listGridLayout = () => {
    setgridLayoutStatus(false);
    setlistGridLayoutStatus(true);
    setFolderIndex(null);
  };
  const isMOROfferingDropdownOverlay = () => {
    //debugger;
    setisMOROfferingDropdownStatus(false);
  };

  const isMORSubOfferingDropdownOverlay = () => {
    setisMORSubOfferingDropdownStatus(false);
  };
  const isMORMonthOfferingDropdownOverlay = () => {
    setisMORMonthOfferingDropdownStatus(false);
  };
  const onClickFetchMORMonthOffering = (e, Month, isForm) => {
    //document.getElementById(FolderIndex).disabled = true;
    //setFolderIndex(null);
    // if(BackParentIDArray.length>0){
    //setParentFolderID(null);
    //setBackParentIDArray([]);
    //    }
    setisParentDataSet(true);
    FolderRef.current = [];
    if (isForm) {
      setcommondefaultMonth(Month);
    } else {
      setcommondefaultMonth(Month);
      if (IsFavouriteMode) {
        setfavModecommondefaultMonth(Month);
      }
      if (IsArchiveMode) {
        setarchModecommondefaultMonth(Month);
      }
    }
    setTimeout(() => {
      isMORMonthOfferingDropdownOverlay();
    }, 100);
  };

  const closeViewer = () => {
    setisMORShowDocViewer(false);
  };
  const goBack = (index, ParentID) => {

    ClearFilter();
    if (isNull(ParentID)) {
      setBackParentIDArray([]);
    } else {
      if (BackParentIDArray.length > 0) {
        let data = [...BackParentIDArray];
        data.splice(index + 1, BackParentIDArray.length - 1);
        //BackParentIDArray.splice(index+1,BackParentIDArray[BackParentIDArray.length-1])
        setBackParentIDArray(data);
        liRef.current = [];
      }
    }
    if (!isNull(ParentFolderID)) {
      setParentFolderID(ParentID);
    }

    /*  if (gridLayoutStatus) {
      setisApply(true);
    } */
    //setParentFolderID(BackParentIDArray[BackParentIDArray.length - 1]);
    //BackParentIDArray.pop();
  };

  const onFolderClick = (e, FolderDetails, index) => {
    // if (!isFolderEdit && !IsFolderDelete && !IsExist) {
    e.stopPropagation();
    setParentFolderID(FolderDetails.MORFolderID);

    setBackParentIDArray(BackParentIDArray.concat(FolderDetails));
    liRef.current = [];
    /* if (gridLayoutStatus) {
      setisApply(true);
    } */
    //   //setBackParentID(MORFolders[0].ParentMORFolderID)
    // }
  };
  const onItemCardClick = (e, ItemData, index) => {
    setisMORDoc(true);
    setViewerItemDeatils({
      ...ViewerItemDeatils,
      ItemId: ItemData.MORItemID,
      ...ItemData,
    });
    setisMORShowDocViewer(true);
  };

  const successRemovalModalClose = () => {
    setsuccessRemovalModalStatus(false);
  };
  const deleteItemApiCall = () => {
    axiosInstance
      .post(`${LocalApiBaseUrl}MOR/DeleteMORItem`, null, {
        params: {
          MORItemID: DeleteItemDeatils.ItemId,
        },
      })
      .then(
        (response) => {
          if (response.data.IsSuccess) {
            if (
              props.pulsePageData.offeringId !== undefined &&
              props.pulsePageData.offeringId !== 0
            ) {
              GetAllPulseMORItemData();
            }
            setdeleteItemModalStatus(false);
            setsuccessRemovalModalStatus(true);
            setisShowDummyCard(false);
            setisShowListCard(false);
            GetAllMORData();
          } else {
            window.alert(response.data.StatusMessage);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };
  const deleteFolderApiCall = () => {
    setdeleteItemModalStatus(false);
    axiosInstance
      .post(`${LocalApiBaseUrl}MOR/DeleteMORFolder`, null, {
        params: {
          MORFolderID: DeleteFolderDeatils.FolderId,
        },
      })
      .then(
        (response) => {
          if (response.data.IsSuccess) {
            setsuccessRemovalModalStatus(true);
            setisShowDummyCard(false);
            setisShowListCard(false);
            GetAllMORData();
          } else {
            window.alert(response.data.StatusMessage);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };
  const successRemovalModalShow = () => {
    IsFolderDelete ? deleteFolderApiCall() : deleteItemApiCall();
  };

  const commonsearchFilter = (query) => {
    let updatedData = [];

    if (gridLayoutStatus) {
      ClonedNewData.filter((obj) => {
        Object.keys(obj).forEach((key) => {
          if (
            key === "DocName" &&
            obj[key] !== null &&
            obj[key]
              .toString()
              .toUpperCase()
              .includes(query.trim().toUpperCase())
          ) {
            if (!updatedData.includes(obj)) {
              updatedData.push(obj);
            }
          }
        });
      });
    }
    if (listGridLayoutStatus) {
      ClonedNewData.filter((obj) => {
        Object.keys(obj).forEach((key) => {
          if (
            key === "DocName" &&
            obj[key] !== null &&
            obj[key]
              .toString()
              .toUpperCase()
              .includes(query.trim().toUpperCase())
          ) {
            if (!updatedData.includes(obj)) {
              updatedData.push(obj);
            }
          }
        });
      });
    }
    return updatedData;
  };

  const handleSearchQuery = (e) => {
    setisSortingMode(false);
    setsortHeading("");
    setSortBytype("");
    setcurrentPage(1);
    //commonsearchFilter(e.target.value);
    setquery(e.target.value);
  };

  const dataforTooltip = (e, Tipdata) => {
    setToolTipdata(
      Tipdata.Path !== "" ? Tipdata.Path.slice(1) + "/" + Tipdata.DocName : ""
    );
    ReactTooltip.rebuild();
  };

  if (
    props.clientData !== undefined &&
    Object.entries(props.clientData).length > 0
  )
    return (
      <>
        <LoadingOverlay
          spinner={<SynopsLoader />}
          active={isloading || isBasicApiLoading}
        ></LoadingOverlay>

        {(accessControl === "View" || accessControl === "Edit") && (
          <div className="main-component">
            <div className="container-fluid">
              <div className="row">
                {morcommentHistory && (
                  <MORCommentsHistory
                    morcommentHistoryClose={morcommentHistoryClose}
                    filterProps={filterProps}
                    addEditCommentsProp={addEditCommentsProp}
                    morCommentsData={morCommentsData}
                  // archiveLayoutStatus={archiveLayoutStatus}
                  />
                )}
                {!morcommentHistory && (
                  <div className="container-section col-lg-12">
                    <div className="mor-header">
                      <h4>
                        <span>{i18n.t("Operational_Reviews")}</span> {props.ispulse && (
                          <NavLink
                            className="mor-view-more"
                            to="/OperationalReviews"
                          >
                            {i18n.t("MORView_more")}
                          </NavLink>
                        )}
                      </h4>
                      <div className="morsearch-section">
                        <div className="searchFilter mr-4">
                          <input
                            value={query}
                            onChange={(e) => handleSearchQuery(e)}
                            type="search"
                            placeholder={i18n.t("Search") + " ..."}
                          />
                        </div>
                        <div
                          className="additem-btn"
                          style={
                            totalSelectedCount > 0 ||
                              IsArchiveMode ||
                              IsFavouriteMode ||
                              props.clientData === undefined ||
                              Object.entries(props.clientData).length === 0
                              ? freezefilter
                              : {}
                          }
                        >
                          {!props.ispulse &&
                            accessControl === "Edit" ? (
                            <div className="additem-btn dropdown">
                              <a
                                className="btn btn-doublegradient"
                                href="#"
                                role="button"
                                id="dropdownMenuLink"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span>
                                  <i className="fal fa-plus"></i>{i18n.t("New")}
                                </span>
                              </a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <a
                                  class="dropdown-item newfolder-icon"
                                  onClick={() => {
                                    createnewFolderItem(true);
                                  }}
                                >
                                  {i18n.t("Create_New_Folder")}
                                </a>
                                <a
                                  class="dropdown-item newitem-icon"
                                  onClick={() => {
                                    createnewFolderItem(false);
                                  }}
                                >
                                  {i18n.t("Add_New_File")}
                                </a>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    {IsArchiveMode && (
                      <div className="mor-doc-count">
                        <span
                          className="backtoMain"
                          onClick={() => gotoNormalMode()}
                        ></span>
                        {i18n.t("Archive") + ` (${TotalDocumentsCount})`}
                      </div>
                    )}
                    {IsFavouriteMode && (
                      <div className="mor-doc-count">
                        <span
                          className="backtoMain"
                          onClick={() => gotoNormalMode()}
                        ></span>
                        {`${i18n.t("Favourites")} (${TotalDocumentsCount})`}
                      </div>
                    )}

                    <div className="mor-doc-count">
                      {/* {!IsArchiveMode && !IsFavouriteMode && ( */}
                      <span
                        style={
                          BackParentIDArray.length === 0
                            ? {}
                            : { color: "rgb(1, 142, 255)" }
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          goBack(null, null);
                        }}
                        className={
                          BackParentIDArray.length > 0 &&
                          `${"mor-doc-nav hover-tooltip"}`
                        }
                      >
                        {!IsArchiveMode &&
                          !IsFavouriteMode &&
                          (BackParentIDArray.length > 0
                            ? `${i18n.t("My_Documents")}`
                            : `${i18n.t("My_Documents")} (${TotalDocumentsCount})`)}

                        {IsArchiveMode &&
                          !IsFavouriteMode &&
                          (BackParentIDArray.length > 0
                            ? `${i18n.t("My_Archived")}`
                            : `${i18n.t("My_Archived")}`)}

                        {!IsArchiveMode &&
                          IsFavouriteMode &&
                          (BackParentIDArray.length > 0
                            ? `${i18n.t("My_Favourites")}`
                            : `${i18n.t("My_Favourites")}`)}
                      </span>
                      {/* )} */}

                      {BackParentIDArray.length <= 4 &&
                        BackParentIDArray.map((a, index) => {
                          if (
                            index !== BackParentIDArray.length - 1 &&
                            index !== BackParentIDArray.length - 2
                          ) {
                            return (
                              <span
                                key={index}
                                className="mor-doc-nav hover-tooltip"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  goBack(index, a.MORFolderID);
                                }}
                              >
                                {a.FolderName.length > 20
                                  ? a.FolderName.slice(0, 17) + "..."
                                  : a.FolderName}
                                {a.FolderName.length > 20 && (
                                  <span>{a.FolderName}</span>
                                )}
                              </span>
                            );
                          }
                        })}

                      {BackParentIDArray.length > MorBreadCrumb && (
                        <span className="mor-doc-nav ellipses">
                          <div
                            className="dropdown"
                            data-toggle="dropdown"
                            id="dropdownMenuLink"
                          >
                            <span className="ellipses-blue"></span>
                            <div
                              className="dropdown-menu dropdown-menu-left"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <h2
                                key={0}
                                ref={ParentRef}
                                /*   onClick={(e) => {
                                                    e.stopPropagation();
                                                    goBack(e,0,BackParentIDArray[0].MORFolderID)}} */
                                data-tip
                                data-for="customTooltip"
                              >
                                {BackParentIDArray[0].FolderName.length > breadcrummbDropChar
                                  ? BackParentIDArray[0].FolderName.slice(
                                    0,
                                    (breadcrummbDropChar - 3)
                                  ) + "..."
                                  : BackParentIDArray[0].FolderName}
                              </h2>

                              <ul
                              //ref={liRef}
                              >
                                {BackParentIDArray.length > 0 &&
                                  _.map(BackParentIDArray, (a, index) => {
                                    if (
                                      index !== BackParentIDArray.length - 1 &&
                                      index !== BackParentIDArray.length - 2 &&
                                      index !== 0
                                    ) {
                                      return (
                                        <>
                                          <li
                                            key={index}
                                            id={index}
                                            ref={(ref) =>
                                              liRef.current.push(ref)
                                            }
                                            /* onClick={(e) =>
                                                                          //e.stopPropagation();
                                                                          goBack(e, index, a.MORFolderID)
                                                                        } */
                                            data-tip
                                            data-for={`customTooltipchild${index}`}
                                            className="hover-tooltip"
                                          >
                                            {a.FolderName.length > breadcrummbDropChar
                                              ? a.FolderName.slice(0, (breadcrummbDropChar - 3)) +
                                              "..."
                                              : a.FolderName}
                                            {index ===
                                              BackParentIDArray.length - 2
                                              ? " (" + a.ItemCount + ")"
                                              : ""}
                                            {/* {a.FolderName.length>20 &&   <span>
                                            {a.FolderName}   
                              </span>} */}
                                          </li>
                                          {/*  {
                                          a.FolderName.length>20 && 
                                        
                                        <ReactTooltip place="right" effect="solid" id={`customTooltipchild${index}`} className="tilecard-tooltip">
                                      <span>{a.FolderName}</span>
                                  </ReactTooltip> 
                                        } */}
                                        </>
                                      );
                                    }
                                  })

                                  /* 
                                {BackParentIDArray.length > 0 &&
                                  BackParentIDArray.map((a, index)=> {
                                    if (
                                      index !== BackParentIDArray.length - 1 &&
                                      index !== 0
                                    ) {
                                      return <li key={index}
                                      onClick={(e)=>
                                        //e.stopPropagation();
                                        goBack(e,index,a.MORFolderID)
                                      }
                                      >{a.FolderName}</li>;
                                    }
                                    return (null);
                                  }
                                  )} */

                                  /* <li>Folder 1</li>
                              <li>Folder 1.1</li>
                              <li className="hover-tooltip">
                                Lorem ipsum amet…{" "}
                                <span>
                                  Lorem ipsum amet Lorem ipsum amet Lorem ipsum
                                  amet Lorem ipsum amet
                                </span>
                              </li> */
                                }
                              </ul>
                            </div>
                          </div>
                          {BackParentIDArray[0].FolderName.length > breadcrummbDropChar && (
                            <ReactTooltip
                              place="right"
                              effect="solid"
                              id={"customTooltip"}
                              className="tilecard-tooltip"
                            >
                              <span>{BackParentIDArray[0].FolderName}</span>
                            </ReactTooltip>
                          )}
                          {_.map(BackParentIDArray, (a, index) => {
                            if (
                              index !== BackParentIDArray.length - 1 &&
                              index !== BackParentIDArray.length - 2 &&
                              index !== 0
                            ) {
                              return (
                                a.FolderName.length > breadcrummbDropChar && (
                                  <ReactTooltip
                                    place="right"
                                    effect="solid"
                                    id={`customTooltipchild${index}`}
                                    className="tilecard-tooltip"
                                  >
                                    <span>{a.FolderName}</span>
                                  </ReactTooltip>
                                )
                              );
                            }
                          })}
                        </span>
                      )}

                      {/*    <span className="mor-doc-nav">
                      {BackParentIDArray.length>3 && BackParentIDArray[BackParentIDArray.length-2].FolderName} 
                      </span> */}
                      {/* <span className="hover-tooltip"> */}
                      {/* Lorem ipsum amet… */}

                      {BackParentIDArray.length > 1 && (
                        <span
                          className="mor-doc-nav hover-tooltip"
                          style={{ color: "rgb(1, 142, 255)" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            goBack(
                              BackParentIDArray.length - 2,
                              BackParentIDArray[BackParentIDArray.length - 2]
                                .MORFolderID
                            );
                          }}
                        >
                          {BackParentIDArray[BackParentIDArray.length - 2]
                            .FolderName &&
                            BackParentIDArray[BackParentIDArray.length - 2]
                              .FolderName.length > 20
                            ? `${BackParentIDArray[
                              BackParentIDArray.length - 2
                            ].FolderName.slice(0, 17) + "..."}`
                            : `${BackParentIDArray[BackParentIDArray.length - 2].FolderName}`}
                          {BackParentIDArray[BackParentIDArray.length - 2]
                            .FolderName.length > 20 && (
                              <span>
                                {
                                  BackParentIDArray[BackParentIDArray.length - 2]
                                    .FolderName
                                }
                              </span>
                            )}
                        </span>
                      )}
                      {BackParentIDArray.length > 0 && (
                        <span className=" mor-doc-nav hover-tooltip last-breadcrummb">
                          {BackParentIDArray[BackParentIDArray.length - 1]
                            .FolderName &&
                            BackParentIDArray[BackParentIDArray.length - 1]
                              .FolderName.length > 20
                            ? `${BackParentIDArray[
                              BackParentIDArray.length - 1
                            ].FolderName.slice(0, 17) + "..."} (${BackParentIDArray[BackParentIDArray.length - 1]
                              .ItemCount
                            })`
                            : `${BackParentIDArray[BackParentIDArray.length - 1].FolderName} (${BackParentIDArray[BackParentIDArray.length - 1].ItemCount})`}
                          {BackParentIDArray[BackParentIDArray.length - 1]
                            .FolderName &&
                            BackParentIDArray[BackParentIDArray.length - 1]
                              .FolderName.length > 20 && (
                              <span>{`${BackParentIDArray[BackParentIDArray.length - 1].FolderName}`}</span>
                            )}
                        </span>
                      )}
                    </div>
                    <div className="morfilter-section">
                      <div className="mor-leftfilters">
                        {/* Delete Edit Move to Archive and Add to Favourites start*/}

                        <div className="mor-gridactions">
                          {!props.ispulse &&
                            accessControl === "Edit" &&
                            isshowDeleteAction &&
                            TotalDocumentsCount > 0 && (
                              <span
                                className="delete-doc"
                                onClick={() => {
                                  setdeleteData(true, []);
                                }}
                              >{`Delete (${totalSelectedCount})`}</span>
                            )}
                          {gridLayoutStatus && (accessControl === "Edit" || IsFavouriteMode) && TotalDocumentsCount > 0 && (
                            <>
                              {!isSelectAll && showSelectall && (
                                <span
                                  className="select-all-doc"
                                  onClick={() => multiSelectOrDeselect(true)}
                                >
                                  {i18n.t("Select_All")}
                                </span>
                              )}
                              {isShowDeSelect && (accessControl === "Edit" || IsFavouriteMode) && (
                                <span
                                  className="deselect-all-doc"
                                  onClick={() => multiSelectOrDeselect(false)}
                                >
                                  {i18n.t("Deselect_All")}
                                </span>
                              )}
                            </>
                          )}

                          {!props.ispulse &&
                            accessControl === "Edit" &&
                            isshowEditAction &&
                            !IsArchiveMode &&
                            TotalDocumentsCount > 0 && (
                              <span
                                className="edit-doc"
                                onClick={() => {
                                  handleEdit();
                                }}
                              >
                                {i18n.t("Edit")}
                              </span>
                            )}
                          {!props.ispulse &&
                            !IsFavouriteMode &&
                            ClonedData.filter((a) => a.IsChecked).length ===
                            0 &&
                            !IsArchiveMode &&
                            BackParentIDArray.length == 0 && (
                              <span
                                className="comments move-archive"
                                onClick={() => {
                                  handleCommentsPopup();
                                }}
                              >
                                {i18n.t("Comments")}
                              </span>
                            )}
                          {!props.ispulse &&
                            !IsFavouriteMode &&
                            !IsArchiveMode &&
                            (accessControl === "Edit" &&
                              isshowArchiveAction &&
                              TotalDocumentsCount > 0 ? (
                              <span
                                className="archive move-archive"
                                onClick={() => {
                                  MarkOrUnMarkAsFaviouriteOrArchieve(
                                    true,
                                    true
                                  );
                                }}
                              >
                                {i18n.t("Move_to_Archive")}
                              </span>
                            ) : (
                              OrginalData != null && OrginalData.filter(e => e.IsChecked).length === 0 &&
                              <span
                                className="archive move-archive"
                                onClick={() => {
                                  setMode(false);
                                }}
                              >
                                {i18n.t("Archive")}
                              </span>
                            )
                            )
                          }
                          {IsArchiveMode &&
                            totalSelectedCount > 0 &&
                            TotalDocumentsCount > 0 &&
                            accessControl === "Edit" &&
                            (
                              <span
                                className="archive move-archive"
                                onClick={() => {
                                  MarkOrUnMarkAsFaviouriteOrArchieve(
                                    true,
                                    false
                                  );
                                }}
                              >
                                {i18n.t("Unarchive")}
                              </span>
                            )}

                          {/* IsFavouriteMode && */
                            isallFavourite &&
                            totalSelectedCount > 0 &&
                            TotalDocumentsCount > 0 &&
                            (!gridLayoutStatus ||
                              (gridLayoutStatus && IsFavouriteMode)) &&
                            FavouriteAction &&
                            !IsArchiveMode && (
                              <span
                                className="favourite move-archive"
                                onClick={() => {
                                  MarkOrUnMarkAsFaviouriteOrArchieve(
                                    false,
                                    false
                                  );
                                }}
                              >
                                {i18n.t("Remove_from_Favourites")}
                              </span>
                            )}

                          {!IsFavouriteMode &&
                            !IsArchiveMode &&
                            (isshowFavouriteAction ? (
                              isNoFavourite &&
                              !gridLayoutStatus &&
                              FavouriteAction && (
                                <span
                                  className="favourite move-archive"
                                  onClick={() => {
                                    MarkOrUnMarkAsFaviouriteOrArchieve(
                                      false,
                                      true
                                    );
                                  }}
                                >
                                  {i18n.t("Add_to_Favourites")}
                                </span>
                              )
                            ) : (
                              <span
                                className="favourite move-archive"
                                onClick={() => {
                                  setMode(true);
                                }}
                              >
                                {i18n.t("Favourites")}
                              </span>
                            ))}
                        </div>
                      </div>

                      <div className="mor-rightfilters">
                        {gridLayoutStatus && (
                          <>
                            <div
                              style={Freezefilter ? freezefilter : {}}
                              className={
                                isFilterActive
                                  ? "mor-filter-drodown filter-applied"
                                  : `mor-filter-drodown ${filterPopoverStatus === true
                                    ? "active"
                                    : ""
                                  }`
                              }
                              onClick={filterPopoverShow}
                              ref={Popovertarget}
                            ></div>
                            <Overlay
                              target={Popovertarget.current}
                              show={filterPopoverStatus}
                              onHide={filterPopoverClose}
                              placement="bottom-end"
                              container={Popovertarget.current}
                              rootClose
                              rootCloseEvent="click"
                            >
                              <Popover className="filter-popover">
                                <Popover.Content>
                                  <div className="filter-popoverheading">
                                    <h6>{i18n.t("Filter By")}:</h6>
                                    <button
                                      type="button"
                                      class="close"
                                      aria-label="Close"
                                      onClick={filterPopoverClose}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="filter-popovercontent">
                                    <DropdownButton
                                      id="dropdown-offering"
                                      title={
                                        offeringName === "Blank"
                                          ? i18n.t("No_Offering")
                                          : offeringName !== "All Offerings"
                                            ? offeringOptions.length > 0
                                              ? offeringOptions.filter(
                                                a => a.OfferingName_En.trim().toUpperCase()
                                                  === offeringName.trim().toUpperCase()
                                              )[0].OfferingName :
                                              offeringName
                                            : i18n.t("All_offering")
                                      }
                                      >
                                      <Dropdown.Item
                                        key={"All Offerings"}
                                        onClick={(e) =>
                                          onClickFetchMOROffering(
                                            e,
                                            [],
                                            "All Offerings"
                                          )
                                        }
                                        active={
                                          offeringName === "All Offerings"
                                        }
                                      >
                                        {i18n.t("All Offerings")}
                                      </Dropdown.Item>

                                      {offeringOptions.map(
                                        (dropValue, index) => (
                                          <Dropdown.Item
                                            id={dropValue.OfferingID}
                                            onClick={(e) =>
                                              onClickFetchMOROffering(
                                                e,
                                                offeringOptions,
                                                dropValue.OfferingID,
                                                false
                                              )
                                            }
                                            active={
                                              offeringName ===
                                              dropValue.OfferingName
                                            }
                                          >
                                            {dropValue.OfferingName}
                                          </Dropdown.Item>
                                        )
                                      )}
                                      {isShowNoffering && (
                                        <Dropdown.Item
                                          key={"Blank"}
                                          onClick={(e) =>
                                            onClickFetchMOROffering(
                                              e,
                                              [],
                                              "Blank"
                                            )
                                          }
                                          active={offeringName === "Blank"}
                                        >
                                          {i18n.t("No_Offering")}
                                        </Dropdown.Item>
                                      )}
                                    </DropdownButton>

                                    <DropdownButton
                                      className="selectsuboffering-dropdown nested-dropdown-wrapper"
                                      id="dropdown-suboffering"
                                      title={
                                        subofferingName === "Blank"
                                          ? i18n.t("No_Sub_Offering")
                                          : subofferingName !== "All Sub Offerings"
                                            ? subofferingName
                                            : i18n.t("All_Sub_Offerings")
                                      }
                                    >
                                      <div className="offering-droplistwrapper">
                                        <SubOffering
                                          subOfferings={processOptions}
                                          onSubOfferingChange={(e) => onClickFetchMORSubOffering(e)}
                                          dropdownItem={false}
                                          selectedSubOfferingId={OfferingID}
                                          selectedSubOffering={subofferingName}
                                          AllSubOffering={true}
                                          NoSubOffering={isShowNoSubffering}
                                        />
                                      </div>
                                    </DropdownButton>
                                    <DropdownButton
                                      id="dropdown-category"
                                      title={
                                        Category === "Blank"
                                          ? i18n.t("No_Category")
                                          : Category !== "All Categories"
                                            ? Category
                                            : i18n.t("All_Categories")
                                      }
                                    >
                                      <Dropdown.Item
                                        key={"All Categories"}
                                        onClick={(e) =>
                                          onClickFetchMORCategory(
                                            e,
                                            {},
                                            "All Categories"
                                          )
                                        }
                                        active={Category === "All Categories"}
                                      >
                                        {i18n.t("All_Categories")}
                                      </Dropdown.Item>

                                      {categoriesList.length > 0 && categoriesList.map((each, index) => {
                                        return (
                                          <Dropdown.Item
                                            key={index}
                                            id={index}
                                            onClick={(e) =>
                                              onClickFetchMORCategory(
                                                e,
                                                each,
                                                each.Name
                                              )
                                            }
                                            active={Category === each.Nmae}
                                          >
                                            {each.Name}
                                          </Dropdown.Item>
                                        );
                                      })}
                                      {isShowNoCategory && (
                                        <Dropdown.Item
                                          key={"Blank"}
                                          onClick={(e) =>
                                            onClickFetchMORCategory(
                                              e,
                                              {},
                                              "Blank"
                                            )
                                          }
                                          active={Category === "Blank"}
                                        >
                                          {i18n.t("No_Category")}
                                        </Dropdown.Item>
                                      )}
                                    </DropdownButton>

                                    <DropdownButton
                                      id="dropdown-suboffering"
                                      title={
                                        commondefaultMonth === "Blank"
                                          ? "No Month"
                                          :  commondefaultMonth !== "All Months"
                                            ? commondefaultMonth
                                            : i18n.t("All_Months")
                                      }
                                    >
                                      <Dropdown.Item
                                        key={"All Months"}
                                        id={"All Months"}
                                        onClick={(e) =>
                                          onClickFetchMORMonthOffering(
                                            e,
                                            "All Months",
                                            true
                                          )
                                        }
                                        active={
                                          commondefaultMonth === "All Months"
                                        }
                                      >
                                        {i18n.t("All_Months")}
                                      </Dropdown.Item>
                                      {commonmonthValues.length > 0 && commonmonthValues.map((x) => {
                                        return (
                                          <Dropdown.Item
                                            key={x}
                                            id={x}
                                            active={commondefaultMonth === x}
                                            onClick={(e) =>
                                              onClickFetchMORMonthOffering(
                                                e,
                                                x,
                                                false
                                              )
                                            }
                                          >
                                            {x}
                                          </Dropdown.Item>
                                        );
                                      })}
                                      {isShowNoMonth && (
                                        <Dropdown.Item
                                          key={"Blank"}
                                          id={"Blank"}
                                          onClick={(e) =>
                                            onClickFetchMORMonthOffering(
                                              e,
                                              "Blank",
                                              true
                                            )
                                          }
                                          active={commondefaultMonth === "Blank"}
                                        >
                                          {i18n.t("No_Month")}
                                        </Dropdown.Item>
                                      )}
                                    </DropdownButton>
                                  </div>
                                </Popover.Content>
                                <div className="popover-footer">
                                  <div className="morfooter-popover">
                                    <Button
                                      variant="default"
                                      className="btn-sm mr-auto"
                                      onClick={() => {
                                        ClearFilter();
                                      }}
                                    >
                                      {i18n.t("Clear")}
                                    </Button>
                                    {/* <Button
                                    variant="outline-primary"
                                    className="btn-sm"
                                    onClick={() => {
                                      ApplyFilter();
                                    }}
                                  >
                                    Apply
                                  </Button> */}
                                  </div>
                                </div>
                              </Popover>
                            </Overlay>
                          </>
                        )}
                        <div className="mor-sortby">
                          <span
                            className={
                              listGridLayoutStatus
                                ? "tableview active"
                                : "tableview"
                            }
                            onClick={listGridLayout}
                          ></span>
                          <span
                            className={
                              gridLayoutStatus ? "gridview active" : "gridview"
                            }
                            onClick={gridLayout}
                          ></span>
                        </div>
                      </div>
                    </div>
                    {/* {archiveLayoutStatus ? (
                    <div className="archive-section">
                      <h2>{i18n.t("Archive")}</h2>
                    </div>
                  ) : (
                    ""
                  )} */}
                    {/* {BackParentIDArray.length !== 0 ? (
                    <button
                      className="backButton inline"
                      name="back"
                      value="back"
                      onClick={(e) => goBack(e)}
                    >
                      <span className="p-r-10">
                        <i className="fas fa-chevron-left"></i>
                      </span>
                      {i18n.t("Back")}
                    </button>
                  ) : (
                    ""
                  )} */}

                    {gridLayoutStatus ? (
                      <div className="morcards-gridlist">
                        {ClonedData.length !== 0
                          ? _.map(ClonedData, (each, index) => {
                            return each.IsFolder ? (
                              <div key={index} className="morcard-grid">
                                <div
                                  className="morcard-gridbody folder-color"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onFolderClick(e, each, index);
                                  }}
                                >
                                  <div className="morcard-image">
                                    <img src={MorTilefolder} alt="MORcard" />
                                  </div>

                                  {/* <div
                                    className="morcard-eyeicon"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onFolderClick(e, each, index);
                                    }}
                                  >
                                    <img src={MorEye} alt="FolderEye" />
                                  </div> */}
                                </div>
                                <div className="morcard-gridfooter">
                                  <div className="d-flex align-items-center mr-1">
                                    <img
                                      src={MORfolderImage}
                                      className="mr-2"
                                      alt={"Folder"}
                                    />
                                    <p className="tooltipellipsis-text">
                                      {each.FolderName &&
                                        each.FolderName.length > tileChar
                                        ? `${each.FolderName.slice(0, (tileChar - 3)) +
                                        "..."} (${each.ItemCount})`
                                        : `${each.FolderName} (${each.ItemCount})`}
                                      {each.FolderName &&
                                        each.FolderName.length > tileChar && (
                                          <span className="initiativetooltip-text">{`${each.FolderName}`}</span>
                                        )}
                                    </p>
                                  </div>
                                  {each.Path !== "" &&
                                    BackParentIDArray.length === 0 &&
                                    !isNull(each.Path) &&
                                    (IsArchiveMode || IsFavouriteMode) && (
                                      <FontAwesomeIcon
                                        data-tip
                                        data-for="Mor-tooltip"
                                        onMouseEnter={(e) => {
                                          dataforTooltip(e, each);
                                        }}
                                        onMouseLeave={() => {
                                          ReactTooltip.hide();
                                        }}
                                        icon={faInfoCircle}
                                      />
                                    )}
                                  {!IsFavouriteMode && !IsArchiveMode && (
                                    <div
                                      className="favouritebtn-toggle"
                                      onClick={() => {
                                        markorunmarkfavouriteTileview(each);
                                      }}
                                    >
                                      {each.IsFavourite ? (
                                        <i
                                          style={Cursorstyle}
                                          className="fas fa-star active"
                                          aria-hidden="true"
                                        ></i>
                                      ) : (
                                        <i
                                          style={Cursorstyle}
                                          className="fas fa-star inactive"
                                          aria-hidden="true"
                                        ></i>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="morgridview-select active">
                                  {
                                    (accessControl === "Edit" || IsFavouriteMode) &&
                                    <label className="form-check">
                                      <input
                                        //key={index}
                                        //id={`#fold${index}`}
                                        className="form-check-input"
                                        type="checkbox"
                                        onChange={(e) => {
                                          e.stopPropagation();
                                          handleCheckbox(e, each, true);
                                        }}
                                        checked={each.IsChecked}
                                      //disabled={false}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  }

                                </div>
                              </div>
                            ) : (
                              <div key={index} className="morcard-grid">
                                <div
                                  className={`morcard-gridbody ${each.ExtensionAndIcon.Style}`}
                                  onClick={(e) =>
                                    onItemCardClick(e, each, index)
                                  }
                                >
                                  {/* add colour */}
                                  <div className="morcard-image">
                                    <img
                                      src={each.ExtensionAndIcon.TileIcon}
                                      alt="MORcard"
                                    />
                                  </div>
                                  {/* <div
                                id={index}
                                  dangerouslySetInnerHTML={{
                                    __html: `<iframe src=${each.ItemLink} width="248px" 
                                 height="149px" frameborder="0"
                                 loading="eager"
                                 ></iframe>`,
                                  }}
                                ></div> */}
                                  {/* <div
                                id={index}
                                  dangerouslySetInnerHTML={{
                                    __html: `<iframe src=${"https://ts.accenture.com/sites/SynOpsDevelopmentTeam/_layouts/15/Doc.aspx?sourcedoc={846367ab-98e8-4ef6-b989-23d6ffe1eed1}&amp;action=embedview&amp;wdAr=1.7777777777777777"} width="248px" 
                                 height="149px" frameborder="0"
                                 loading="eager"
                                 ></iframe>`,
                                  }}
                                ></div> */}

                                  {/* <iframe src="https://ts.accenture.com/sites/SynOpsDevelopmentTeam/_layouts/15/Doc.aspx?sourcedoc={846367ab-98e8-4ef6-b989-23d6ffe1eed1}&amp;action=embedview&amp;wdAr=1.7777777777777777"
                                id={index}
                                title="name"
                                 width="248px" 
                                 onLoad={()=>{

                                 }}
                                  onerror={()=>{

                                 }}

                                 height="149px" frameborder="0"></iframe> */}

                                  {/* <Iframe
                                   url="https://ts.accenture.com/sites/SynOpsDevelopmentTeam/_layouts/15/Doc.aspx?sourcedoc={846367ab-98e8-4ef6-b989-23d6ffe1eed1}&amp;action=embedview&amp;wdAr=1.7777777777777777"
                            width="248px"
                            height="149px"
                            id={index}
                            // left="10px"
                            // top="100px"
                            // className="chat-box-alignment"
                            // display="initial"
                            // position="fixed"
                          /> */}
                                </div>
                                <div className="morcard-gridfooter">
                                  <div className="d-flex align-items-center mr-1">
                                    {/*  <img
                                    alt={each.ItemLink}
                                    src={
                                      each.ItemLink.toUpperCase().includes(
                                        "PPT"
                                      )
                                        ? MorfilePPT
                                        : MorfilePDF
                                    }
                                    className="mr-2"
                                  /> */}
                                    <img
                                      alt={"File"}
                                      src={each.ExtensionAndIcon.SmallIcon}
                                      className="mr-2"
                                    />
                                    <p className="tooltipellipsis-text">
                                      {each.ItemName.length > tileChar
                                        ? each.ItemName.slice(0, (tileChar - 3)) + "..."
                                        : each.ItemName}

                                      {each.ExtensionAndIcon.Extension}
                                      {each.ItemName.length > tileChar && (
                                        <span className="initiativetooltip-text">
                                          {each.ItemName}
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                  {each.Path !== "" &&
                                    BackParentIDArray.length === 0 &&
                                    !isNull(each.Path) &&
                                    (IsArchiveMode || IsFavouriteMode) && (
                                      <FontAwesomeIcon
                                        data-tip
                                        data-for="Mor-tooltip"
                                        onMouseEnter={(e) => {
                                          dataforTooltip(e, each);
                                        }}
                                        onMouseLeave={() => {
                                          ReactTooltip.hide();
                                        }}
                                        icon={faInfoCircle}
                                      />
                                    )}
                                  {!IsFavouriteMode && !IsArchiveMode && (
                                    <div
                                      className="favouritebtn-toggle"
                                      onClick={() => {
                                        markorunmarkfavouriteTileview(each);
                                      }}
                                    >
                                      {each.Path !== "" &&
                                        !isNull(each.Path) &&
                                        BackParentIDArray.length === 0 &&
                                        (IsArchiveMode ||
                                          IsFavouriteMode) && (
                                          <FontAwesomeIcon
                                            data-tip
                                            data-for="Mor-tooltip"
                                            onMouseEnter={(e) => {
                                              dataforTooltip(e, each);
                                            }}
                                            onMouseLeave={() => {
                                              ReactTooltip.hide();
                                            }}
                                            icon={faInfoCircle}
                                          />
                                        )}
                                      {each.IsFavourite ? (
                                        <i
                                          style={Cursorstyle}
                                          className="fas fa-star active"
                                          aria-hidden="true"
                                        ></i>
                                      ) : (
                                        <i
                                          style={Cursorstyle}
                                          className="fas fa-star inactive"
                                          aria-hidden="true"
                                        ></i>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="morgridview-select active">
                                  {
                                    (accessControl === "Edit" || IsFavouriteMode) && <label className="form-check">
                                      <input
                                        //key={index}
                                        //id={`#item${index}`}
                                        className="form-check-input"
                                        type="checkbox"
                                        onChange={(e) => {
                                          handleCheckbox(e, each, false);
                                        }}
                                        checked={each.IsChecked}
                                      //disabled={false}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  }

                                </div>
                              </div>
                            );
                          })
                          : ""}
                      </div>
                    ) : null}
                    {listGridLayoutStatus ? (
                      <div className="morcards-listgrid">
                        <table className="table table-responsive-sm table-responsive-md table-responsive-lg mor-gridtable">
                          <thead>
                            <tr>
                              <th>
                                {
                                  (accessControl === "Edit" || !IsArchiveMode) && OrginalData !== null && OrginalData.length > 0 && <label className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={(e) =>
                                        multiSelectOrDeselect(!isSelectAll)
                                      }
                                      checked={isSelectAll}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                }

                              </th>
                              <th style={Freezefilter ? freezefilter : {}}>
                                {i18n.t("Document_Name")}
                                <span className="sortupdown-icons">
                                  <span
                                    //style={isSortingMode && sortHeading==="Name" && SortBytype==="asc"?{color:"blue"}:{}}
                                    className={
                                      isSortingMode &&
                                        sortHeading === "Name" &&
                                        SortBytype === "asc"
                                        ? "increment-arrow-up active"
                                        : "increment-arrow-up"
                                    }
                                    onClick={() => {
                                      setHeadingandSorttype("Name", "asc");
                                    }}
                                  ></span>
                                  <span
                                    //style={isSortingMode && sortHeading==="Name" && SortBytype==="desc"?{color:"blue"}:{}}
                                    class={
                                      isSortingMode &&
                                        sortHeading === "Name" &&
                                        SortBytype === "desc"
                                        ? "increment-arrow-down active"
                                        : "increment-arrow-down"
                                    }
                                    onClick={() => {
                                      setHeadingandSorttype("Name", "desc");
                                    }}
                                  ></span>
                                </span>
                              </th>

                              <th style={Freezefilter ? freezefilter : {}}>
                                <DropdownButton
                                  id="dropdown-offering"
                                  title={
                                    offeringName === "Blank"
                                      ? i18n.t("No_Offering")
                                      : languageID !== 1 && offeringName !== "All Offerings"
                                        ? offeringOptions.length > 0
                                          ? offeringOptions.filter(
                                            a => a.OfferingName_En.trim().toUpperCase()
                                              === offeringName.trim().toUpperCase()
                                          )[0].OfferingName :
                                          offeringName
                                        : i18n.t("All_offering")
                                  }
                                >
                                  <Dropdown.Item
                                    key={"All Offerings"}
                                    onClick={(e) =>
                                      onClickFetchMOROffering(
                                        e,
                                        [],
                                        "All Offerings"
                                      )
                                    }
                                    active={offeringName === "All Offerings"}
                                  >
                                    {i18n.t("All Offerings")}
                                  </Dropdown.Item>

                                  {offeringOptions.map((dropValue, index) => (
                                    <Dropdown.Item
                                      id={dropValue.OfferingID}
                                      onClick={(e) =>
                                        onClickFetchMOROffering(
                                          e,
                                          offeringOptions,
                                          dropValue.OfferingID,
                                          false
                                        )
                                      }
                                      active={
                                        offeringName === dropValue.OfferingName
                                      }
                                    >
                                      {dropValue.OfferingName}
                                    </Dropdown.Item>
                                  ))}
                                  {isShowNoffering && (
                                    <Dropdown.Item
                                      key={"Blank"}
                                      onClick={(e) =>
                                        onClickFetchMOROffering(e, [], "Blank")
                                      }
                                      active={offeringName === "Blank"}
                                    >
                                      {i18n.t("No_Offering")}
                                    </Dropdown.Item>

                                  )}
                                </DropdownButton>
                                <span className="sortupdown-icons">
                                  <span
                                    //style={isSortingMode && sortHeading==="Offerings" && SortBytype==="asc"?{color:"blue"}:{}}
                                    className={
                                      isSortingMode &&
                                        sortHeading === "Offerings" &&
                                        SortBytype === "asc"
                                        ? "increment-arrow-up active"
                                        : "increment-arrow-up"
                                    }
                                    onClick={() => {
                                      setHeadingandSorttype("Offerings", "asc");
                                    }}
                                  ></span>
                                  <span
                                    //style={isSortingMode && sortHeading==="Offerings" && SortBytype==="desc"?{color:"blue"}:{}}
                                    className={
                                      isSortingMode &&
                                        sortHeading === "Offerings" &&
                                        SortBytype === "desc"
                                        ? "increment-arrow-down active"
                                        : "increment-arrow-down"
                                    }
                                    onClick={() => {
                                      setHeadingandSorttype(
                                        "Offerings",
                                        "desc"
                                      );
                                    }}
                                  ></span>
                                </span>
                              </th>
                              <th style={Freezefilter ? freezefilter : {}}>
                                <DropdownButton
                                  className="selectsuboffering-dropdown nested-dropdown-wrapper"
                                  id="dropdown-suboffering"
                                  title={
                                    subofferingName === "Blank"
                                      ? i18n.t("No_Sub_Offering")
                                      : languageID !== 1 && subofferingName !== "All Sub Offerings"
                                        ? subofferingName
                                        : i18n.t("All_Sub_Offerings")
                                  }
                                >
                                  <div className="offering-droplistwrapper">
                                    <SubOffering
                                      subOfferings={processOptions}
                                      onSubOfferingChange={(e) => onClickFetchMORSubOffering(e)}
                                      dropdownItem={false}
                                      selectedSubOfferingId={OfferingID}
                                      selectedSubOffering={subofferingName}
                                      AllSubOffering={true}
                                      NoSubOffering={isShowNoSubffering}
                                    />
                                  </div>
                                </DropdownButton>

                                <span className="sortupdown-icons">
                                  <span
                                    style={
                                      isSortingMode &&
                                        sortHeading === "SubOfferings" &&
                                        SortBytype === "asc"
                                        ? { color: "blue" }
                                        : {}
                                    }
                                    class={
                                      isSortingMode &&
                                        sortHeading === "SubOfferings" &&
                                        SortBytype === "asc"
                                        ? "increment-arrow-up active"
                                        : "increment-arrow-up"
                                    }
                                    onClick={() => {
                                      setHeadingandSorttype(
                                        "SubOfferings",
                                        "asc"
                                      );
                                    }}
                                  ></span>
                                  <span
                                    //style={isSortingMode && sortHeading==="SubOfferings" && SortBytype==="desc"?{color:"blue"}:{}}
                                    class={
                                      isSortingMode &&
                                        sortHeading === "SubOfferings" &&
                                        SortBytype === "desc"
                                        ? "increment-arrow-down active"
                                        : "increment-arrow-down"
                                    }
                                    onClick={() => {
                                      setHeadingandSorttype(
                                        "SubOfferings",
                                        "desc"
                                      );
                                    }}
                                  ></span>
                                </span>
                              </th>
                              <th
                                className="allMonths"
                                style={Freezefilter ? freezefilter : {}}
                              >
                                <DropdownButton
                                  id="dropdown-suboffering"
                                  title={
                                    commondefaultMonth === "Blank"
                                      ? i18n.t("No_Month")
                                      : languageID !== 1 && commondefaultMonth !== "All Months"
                                            ? commondefaultMonth
                                            : i18n.t("All_Months")
                                  }
                                >
                                  <Dropdown.Item
                                    key={"All Months"}
                                    id={"All Months"}
                                    onClick={(e) =>
                                      onClickFetchMORMonthOffering(
                                        e,
                                        "All Months",
                                        true
                                      )
                                    }
                                    active={commondefaultMonth === "All Months"}
                                  >
                                    {"All Months"}
                                  </Dropdown.Item>
                                  {commonmonthValues.length > 0 && commonmonthValues.map((x) => {
                                    return (
                                      <Dropdown.Item
                                        key={x}
                                        id={x}
                                        active={commondefaultMonth === x}
                                        onClick={(e) =>
                                          onClickFetchMORMonthOffering(
                                            e,
                                            x,
                                            false
                                          )
                                        }
                                      >
                                        {x}
                                      </Dropdown.Item>
                                    );
                                  })}

                                  {isShowNoMonth && (
                                    <Dropdown.Item
                                      key={"Blank"}
                                      id={"Blank"}
                                      onClick={(e) =>
                                        onClickFetchMORMonthOffering(
                                          e,
                                          "Blank",
                                          true
                                        )
                                      }
                                      active={commondefaultMonth === "Blank"}
                                    >
                                      {i18n.t("No_Month")}
                                    </Dropdown.Item>
                                  )}
                                </DropdownButton>
                                <span className="sortupdown-icons">
                                  <span
                                    //style={isSortingMode && sortHeading==="Months" && SortBytype==="asc"?{color:"blue"}:{}}
                                    className={
                                      isSortingMode &&
                                        sortHeading === "Months" &&
                                        SortBytype === "asc"
                                        ? "increment-arrow-up active"
                                        : "increment-arrow-up"
                                    }
                                    onClick={() => {
                                      setHeadingandSorttype("Months", "asc");
                                    }}
                                  ></span>
                                  <span
                                    // style={isSortingMode && sortHeading==="Months" && SortBytype==="desc"?{color:"blue"}:{}}
                                    className={
                                      isSortingMode &&
                                        sortHeading === "Months" &&
                                        SortBytype === "desc"
                                        ? "increment-arrow-down active"
                                        : "increment-arrow-down"
                                    }
                                    onClick={() => {
                                      setHeadingandSorttype("Months", "desc");
                                    }}
                                  ></span>
                                </span>
                              </th>
                              <th style={Freezefilter ? freezefilter : {}}>
                                <DropdownButton
                                  menuAlign="right"
                                  id="dropdown-category"
                                  title={
                                    Category === "Blank"
                                      ? i18n.t("No Category")
                                      : languageID !== 1 && Category !== "All Categories"
                                            ? Category
                                            : i18n.t("All_Categories")
                                  }
                                >
                                  <Dropdown.Item
                                    key={"All Categories"}
                                    onClick={(e) =>
                                      onClickFetchMORCategory(
                                        e,
                                        {},
                                        "All Categories"
                                      )
                                    }
                                    active={Category === "All Categories"}
                                  >
                                    {i18n.t("All_Categories")}
                                  </Dropdown.Item>

                                  {categoriesList.length > 0 && categoriesList.map((each, index) => {
                                    return (
                                      <Dropdown.Item
                                        key={index}
                                        id={index}
                                        onClick={(e) =>
                                          onClickFetchMORCategory(
                                            e,
                                            each,
                                            each.Name
                                          )
                                        }
                                        active={Category === each.Name}
                                      >
                                        {each.Name}
                                      </Dropdown.Item>
                                    );
                                  })}
                                  {isShowNoCategory && (
                                    <Dropdown.Item
                                      key={"Blank"}
                                      onClick={(e) =>
                                        onClickFetchMORCategory(e, {}, "Blank")
                                      }
                                      active={Category === "Blank"}
                                    >
                                      {i18n.t("No_Category")}
                                    </Dropdown.Item>
                                  )}
                                </DropdownButton>
                                <span className="sortupdown-icons">
                                  <span
                                    //style={isSortingMode && sortHeading==="Name" && SortBytype==="asc"?{color:"blue"}:{}}
                                    className={
                                      isSortingMode &&
                                        sortHeading === "Categories" &&
                                        SortBytype === "asc"
                                        ? "increment-arrow-up active"
                                        : "increment-arrow-up"
                                    }
                                    onClick={() => {
                                      setHeadingandSorttype(
                                        "Categories",
                                        "asc"
                                      );
                                    }}
                                  ></span>
                                  <span
                                    //style={isSortingMode && sortHeading==="Name" && SortBytype==="desc"?{color:"blue"}:{}}

                                    className={
                                      isSortingMode &&
                                        sortHeading === "Categories" &&
                                        SortBytype === "desc"
                                        ? "increment-arrow-down active"
                                        : "increment-arrow-down"
                                    }
                                    onClick={() => {
                                      setHeadingandSorttype(
                                        "Categories",
                                        "desc"
                                      );
                                    }}
                                  ></span>
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {ClonedData.length !== 0
                              ? _.map(ClonedData, (each, index) => {
                                return each.IsFolder ? (
                                  <tr key={index}>
                                    <td
                                      className={
                                        (IsArchiveMode || IsFavouriteMode) ? "archiveFav-info" : ""
                                      }
                                    >
                                      {
                                        (accessControl === "Edit" || !IsArchiveMode) &&
                                        <label className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={(e) => {
                                              handleCheckbox(e, each, true);
                                            }}
                                            checked={each.IsChecked}
                                          />
                                          <span className="checkmark"></span>
                                        </label>
                                      }
                                      {each.Path !== "" &&
                                        !isNull(each.Path) &&
                                        BackParentIDArray.length === 0 &&
                                        (IsArchiveMode ||
                                          IsFavouriteMode) && (
                                          <FontAwesomeIcon
                                            style={fontawsstyle}
                                            //className="mor-docinfo"
                                            data-tip
                                            data-for="Mor-tooltip"
                                            onMouseEnter={(e) => {
                                              dataforTooltip(e, each);
                                            }}
                                            onMouseLeave={() => {
                                              ReactTooltip.hide();
                                            }}
                                            icon={faInfoCircle}
                                          />
                                        )}
                                    </td>
                                    <td
                                      className="mor-docname tooltipellipsis-text"
                                      onClick={(e) =>
                                        onFolderClick(e, each, index)
                                      }
                                    >
                                      <span className="doc-img-align folder-color">
                                        <img
                                          src={MorListfolder}
                                          alt="MORdoc"
                                          width="16px"
                                          height="24px"
                                          className="mr-4"
                                        />
                                        {`${each.FolderName.length > listDocChar
                                            ? each.FolderName.slice(0, (listDocChar - 3)) +
                                            "..."
                                            : each.FolderName
                                          } (${each.ItemCount})`}
                                        {each.IsFavourite &&
                                          !IsFavouriteMode &&
                                          !IsArchiveMode && (
                                            <i
                                              className="fas fa-star active"
                                              style={{ color: "#ffc82c" }}
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                      </span>
                                      {each.FolderName &&
                                        each.FolderName.length >
                                        listDocChar && (
                                          <div className="initiativetooltip-text">
                                            {each.FolderName}
                                          </div>
                                        )}
                                    </td>
                                    <td className="tooltipellipsis-text">
                                      {each.Offering ? <span>{each.Offering.length > listOffChar ? each.Offering.slice(0, (listOffChar - 3)) + "..." : each.Offering}</span> : <span className="mor-emptyCell">-</span>}
                                      {each.Offering &&
                                        each.Offering.length >
                                        listOffChar && (
                                          <div className="initiativetooltip-text">
                                            {each.Offering}
                                          </div>
                                        )}
                                    </td>
                                    <td className="tooltipellipsis-text">
                                      {each.SubOffering ? (
                                        <span>
                                          {each.SubOffering.length > listOffChar
                                            ? each.SubOffering.slice(
                                              0,
                                              listOffChar - 3
                                            ) + "..."
                                            : each.SubOffering}
                                        </span>
                                      ) : (
                                        <span className="mor-emptyCell">
                                          -
                                        </span>
                                      )}
                                      {each.SubOffering &&
                                        each.SubOffering.length >
                                        listOffChar && (
                                          <div className="initiativetooltip-text">
                                            {each.SubOffering}
                                          </div>
                                        )}
                                    </td>
                                    <td className="allMonths">
                                      {each.Month !== null &&
                                        each.Year !== null ? (
                                        <span>{`${allmonth[parseInt(each.Month) - 1]
                                          } ${each.Year}`}</span>
                                      ) : (
                                        <span className="mor-emptyCell">
                                          -
                                        </span>
                                      )}
                                    </td>
                                    <td className="tooltipellipsis-text">
                                      {each.Categorys ? <span>
                                        {each.Categorys.length >
                                          tooltipbreadcrumb
                                          ? each.Categorys.slice(0, (tooltipbreadcrumb - 3)) +
                                          "..."
                                          : each.Categorys}
                                      </span> : <span className="mor-emptyCell">-</span>}

                                      {each.Categorys &&
                                        each.Categorys.length >
                                        tooltipbreadcrumb && (
                                          <div className="initiativetooltip-text">
                                            {each.Categorys}
                                          </div>
                                        )}
                                    </td>
                                  </tr>
                                ) : (
                                  <tr key={index}>
                                    <td
                                      className={
                                        (IsArchiveMode || IsFavouriteMode) ? "archiveFav-info" : ""
                                      }
                                    >
                                      {
                                        (accessControl === "Edit" || !IsArchiveMode) &&
                                        <label className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={(e) => {
                                              handleCheckbox(e, each, false);
                                            }}
                                            checked={each.IsChecked}
                                          />
                                          <span className="checkmark"></span>
                                        </label>
                                      }
                                      {each.Path !== "" &&
                                        !isNull(each.Path) &&
                                        BackParentIDArray.length === 0 &&
                                        (IsArchiveMode ||
                                          IsFavouriteMode) && (
                                          <FontAwesomeIcon
                                            style={fontawsstyle}
                                            //className="mor-docinfo"
                                            data-tip
                                            data-for="Mor-tooltip"
                                            onMouseEnter={(e) => {
                                              dataforTooltip(e, each);
                                            }}
                                            onMouseLeave={() => {
                                              ReactTooltip.hide();
                                            }}
                                            icon={faInfoCircle}
                                          />
                                        )}
                                    </td>
                                    <td
                                      className="mor-docname tooltipellipsis-text"
                                      // className="mor-docname tooltipellipsis-text"

                                      onClick={(e) =>
                                        onItemCardClick(e, each, index)
                                      }
                                    >
                                      <span
                                        className={`doc-img-align ${each.ExtensionAndIcon.Style}`}
                                      >
                                        <img
                                          src={each.ExtensionAndIcon.ListIcon}
                                          alt="MORdoc"
                                          width="16px"
                                          height="20px"
                                          className="mr-4"
                                        />
                                        {/* {each.ItemName} */}
                                        {`${(
                                          each.ItemName

                                        ).length > listDocChar
                                          ? (
                                            each.ItemName
                                          ).slice(0, (listDocChar - 3)) + "..."
                                          : (each.ItemName)
                                          }`}
                                        {each.ExtensionAndIcon.Extension}
                                        {each.ItemName &&
                                          (
                                            each.ItemName

                                          ).length > listDocChar && (
                                            <div className="initiativetooltip-text">
                                              {each.ItemName
                                              }
                                            </div>
                                          )}
                                        {/* {each.Extension} */}

                                        {each.IsFavourite &&
                                          !IsFavouriteMode &&
                                          !IsArchiveMode && (
                                            <i
                                              className="fas fa-star active"
                                              style={{ color: "#ffc82c" }}
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                      </span>
                                    </td>
                                    <td className="tooltipellipsis-text">
                                      {each.Offering ? <span>{each.Offering.length > listOffChar ? each.Offering.slice(0, (listOffChar - 3)) + "..." : each.Offering}</span> : <span className="mor-emptyCell">-</span>}
                                      {each.Offering &&
                                        each.Offering.length >
                                        listOffChar && (
                                          <div className="initiativetooltip-text">
                                            {each.Offering}
                                          </div>
                                        )}
                                    </td>
                                    <td className="tooltipellipsis-text">
                                      {each.SubOffering ? (
                                        <span>
                                          {each.SubOffering.length > listOffChar
                                            ? each.SubOffering.slice(
                                              0,
                                              listOffChar - 3
                                            ) + "..."
                                            : each.SubOffering}
                                        </span>
                                      ) : (
                                        <span className="mor-emptyCell">
                                          -
                                        </span>
                                      )}
                                      {each.SubOffering &&
                                        each.SubOffering.length >
                                        listOffChar && (
                                          <div className="initiativetooltip-text">
                                            {each.SubOffering}
                                          </div>
                                        )}
                                    </td>
                                    <td className="allMonths">
                                      {each.Month !== null &&
                                        each.Year !== null ? (
                                        <span>{`${allmonth[parseInt(each.Month) - 1]
                                          } ${each.Year}`}</span>
                                      ) : (
                                        <span className="mor-emptyCell">
                                          -
                                        </span>
                                      )}
                                    </td>
                                    <td className="tooltipellipsis-text">
                                      {each.Categorys ?
                                        <span>
                                          {each.Categorys &&
                                            each.Categorys.length >
                                            tooltipbreadcrumb
                                            ? each.Categorys.slice(0, (tooltipbreadcrumb - 3)) +
                                            "..."
                                            : each.Categorys ? each.Categorys : "-"}
                                        </span> : <span className="mor-emptyCell">-</span>
                                      }

                                      {each.Categorys &&
                                        each.Categorys.length >
                                        tooltipbreadcrumb && (
                                          <div className="initiativetooltip-text">
                                            {each.Categorys}
                                          </div>
                                        )}
                                    </td>
                                  </tr>
                                );
                              })
                              : ""}
                          </tbody>
                        </table>
                        {isShowNoDatamessage && !(isloading) &&
                          (!IsArchiveMode && !IsFavouriteMode && accessControl === "Edit" ? (
                            <div class="no-data-message">
                              {i18n.t("MOR_No_DataAvailable")}
                            </div>
                          ) : (
                            <div class="no-data-message">
                              {i18n.t("MOR_No_DataAvailable_Favourite_Archieve")}
                            </div>
                          ))}
                        {TotalResultCount !== null &&
                          TotalResultCount >= Configperpage && (
                            <div className="pagination-list">
                              <span>{`${i18n.t("Page")}  ${currentPage} of`}&nbsp;</span>
                              <span>{PageCount.length}</span>
                              &nbsp;&nbsp;
                              <div className="select-custom">
                                <select
                                  className="dropdown"
                                  value={perpage}
                                  onChange={(e) => onPageSizeChange(e)}
                                >
                                  {PageDropDown.map((a, index) => (
                                    <option
                                      id={index}
                                      key={index}
                                      value={a}
                                    //ref={(ref) => OpRef.current.push(ref)}
                                    >
                                      {a}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <ul className="pagination">
                                {PageCount.length > 1 && (
                                  <>
                                    <li>
                                      <span
                                        className="page-link pagination-icon"
                                        onClick={() =>
                                          handlePageClickshotcut("Begin")
                                        }
                                      >
                                        <i class="fas fa-arrow-alt-to-left"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span
                                        className="page-link pagination-icon"
                                        onClick={() =>
                                          handlePageClickshotcut("Decrement")
                                        }
                                      >
                                        <i class="fas fa-arrow-alt-left"></i>
                                      </span>
                                    </li>
                                  </>
                                )}
                                {PageCount.map((a, index) => (
                                  <li key={index} value={index}>
                                    <span
                                      key={index}
                                      value={index}
                                      className={
                                        currentPage === a
                                          ? "page-link active"
                                          : "page-link"
                                      }
                                      onClick={() => handlePageClick(a)}
                                    >
                                      {a}
                                    </span>
                                  </li>
                                ))}
                                {PageCount.length > 1 && (
                                  <>
                                    <li>
                                      <span
                                        className="page-link pagination-icon"
                                        onClick={() =>
                                          handlePageClickshotcut("Increement")
                                        }
                                      >
                                        <i class="fas fa-arrow-alt-right"></i>
                                      </span>
                                    </li>
                                    <li>
                                      <span
                                        className="page-link pagination-icon"
                                        onClick={() =>
                                          handlePageClickshotcut("End")
                                        }
                                      >
                                        <i class="fas fa-arrow-alt-to-right"></i>
                                      </span>
                                    </li>
                                  </>
                                )}
                              </ul>
                            </div>
                          )}
                      </div>
                    ) : null}
                    {(isShowNotification || deleteItemModalStatus) && (
                      <MORNotification
                        isShowNotification={isShowNotification}
                        closeNotication={morcloseNotication}
                        TotalSelectedCount={totalSelectedCount}
                        isMovedToArchieve={isMovedToArchieve}
                        isMovedToFavourite={isMovedToFavourite}
                        isReMovedFromArchieve={isReMovedFromArchieve}
                        isRemovedFromFavourite={isRemovedFromFavourite}
                        isFolderAdded={isFolderAdded}
                        Singledata={Singledata}
                        isFolderEdited={isFolderEdited}
                        isItemAdded={isItemAdded}
                        isItemEdited={isItemEdited}
                        isDeleted={isDeleted}
                        folderItemMessage={folderItemMessage}
                        dataToDelete={dataToDelete}
                        deleteData={deleteData}
                        deleteItemModalStatus={deleteItemModalStatus}
                        deleteItemModalClose={deleteItemModalClose}
                      />
                    )}
                    {morcommentwindowStatus && (
                      <CommentsPopOver
                        morcommentwindowClose={morcommentwindowClose}
                        morcommentHistoryShow={morcommentHistoryShow}
                        filterProps={filterProps}
                        addEditCommentsProp={addEditCommentsProp}
                      //archiveLayoutStatus={archiveLayoutStatus}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {ToolTipdata !== "" && (
          <ReactTooltip
            id="Mor-tooltip"
            place="bottom"
            type="dark"
            effect="solid"
            className="infoiconmor-tooltip"
          >
            <span>{ToolTipdata}</span>
          </ReactTooltip>
        )}

        {createnewModalStatus && (
          <AddFileFolderPopup
            //showDocDropDown={showDocDropDown}
            DocDetails={Doctype}
            Document={Document}
            categoriesprops={NewCategoriesList}
            offeringValuesprops={NewofferingOptions}
            isFolderAdd={isFolderAdd}
            closePopup={handleCloseFileFolder}
            createnewModalStatus={createnewModalStatus}
            monthList={NewcommonmonthValues}
            ParentFolderID={ParentFolderID}
            handleSavePopup={handleSavePopup}
            OrgEntityID={OrgEntityID}
            typePopup={typePopup}
            MORFolderList={OrginalData.filter((a) => a.IsFolder)}
            MORItemList={OrginalData.filter((a) => !a.IsFolder)}
            EditData={EditData}
            ErrorMessage={ErrorMessage}
            makeErrorMessageEmpty={() => {
              setErrorMessage("");
            }}
          />
        )}

        <Modal
          show={isshowDocDropDown}
          onHide={handleDocumentClose}
          className="helpfeatureNotification-modal centered"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>{i18n.t("Document")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="helpfeaturenotification-body">
              <div className="helpfeaturenotification-data">
                <select
                  id="Document"
                  className="form-control"
                  onChange={(e) => bindTextFieldChange(e)}
                  value={Doctype.Id}
                >
                  {Document.map((Doc) => {
                    return <option value={Doc.Id}>{Doc.ConfigKey}</option>;
                    // }
                  })}
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-btn addmoritem-btns">
              <button
                onClick={handleDocumentClose}
                className="btn btn-outline-primary btn-sm"
              >
                {i18n.t("Cancel")}
              </button>
              <button
                type="Button"
                className="btn btn-primary btn-sm"
                onClick={() => handleView()}
              >
                {i18n.t("View")}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={addfeatureNotificationStatus}
          onHide={handleClose}
          className="successNotification-modal"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>{i18n.t("Notification")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="successnotification-body">
              <div className="successnotification-icon">
                <span>
                  <img src={Success} width="40px" height="40px" />
                </span>
              </div>
              <div className="successnotification-data">
                {isFolderEdit ? (
                  <p>
                    <b>{i18n.t("Success")}!</b>&nbsp;
                    {i18n.t("Folder_Edited_SucessFully")}
                  </p>
                ) : isItemEdit ? (
                  <p>
                    <b>{i18n.t("Success")}!</b>&nbsp;
                    {i18n.t("Item_Edited_SucessFully")}
                  </p>
                ) : isFolderAdd ? (
                  <p>
                    <b>{i18n.t("Success")}!&nbsp;</b>
                    {Path}
                    <b>
                      {NotifyFolderName && NotifyFolderName != ""
                        ? NotifyFolderName
                        : DummyFolderName}
                    </b>
                    &nbsp;
                    {i18n.t("added_SucessFully")}
                  </p>
                ) : (
                  //!isFolderEdit && !isItemEdit &&
                  <p>
                    <b>{i18n.t("Success")}!&nbsp;</b>
                    {Path}
                    {ItemDeatils.ItemName}&nbsp;{i18n.t("added_SucessFully")}
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Delete Notification Modal Popup Start*/}
        <Modal
          show={successRemovalModalStatus}
          onHide={successRemovalModalClose}
          className="successNotification-modal"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>{i18n.t("Notification")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="successnotification-body">
              <div className="successnotification-icon">
                <span className="mr-4">
                  <i class="fal fa-trash"></i>
                </span>
              </div>
              <div className="successnotification-data">
                <p>
                  <b>{i18n.t("Success")}!&nbsp;</b>
                  {Path}
                  <b>
                    {IsFolderDelete
                      ? DeleteFolderDeatils.FolderName
                      : DeleteItemDeatils.ItemName}
                  </b>
                  &nbsp;{i18n.t("deleted_successfully")}
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Delete Notification Modal Popup End*/}
        {isMORShowDocViewer ? (
          <MORDocViewer
            isMORShowDocViewer={isMORShowDocViewer}
            ViewerItemDeatils={ViewerItemDeatils}
            DocDetails={Doctype}
            isMORDoc={isMORDoc}
            allData={Document}
            closeViewer={closeViewer}
            AllViewerItemDeatils={ClonedData.filter((a) => !a.IsFolder)}
            handleViewerEdit={handleViewerEdit}
            handleViewerDelete={handleViewerDelete}
            accessControl={accessControl}
            IsArchiveMode={IsArchiveMode}
            {...props}
          />
        ) : (
          ""
        )}
      </>
    );
  else
    return (
      <>
        <div className="pulse-dashboard push-pulse-filter">
          <div className="container-fluid">
            <div className="valueRealization-container">
              <div className="novalueContainer">
                {
                  !(isloading || props.isorgapiloading || props.isTabChanged) &&

                  <div className="select-filter-msg">
                    <img
                      src={noFilter}
                      alt={i18n.t("mor_empty_client_message")}
                    />
                    <p className="mb-0">
                      {i18n.t("mor_empty_client_message")}
                    </p>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

const mapStateToProps = (state) => {
  return {
    languageData: state.CurrentFilter.languageData,
    clientData: state.CurrentFilter.ClientData,
    roleReducers: state.roleReducers.roleReducers,
    pulseOfferingData: state.CurrentFilter.PulseData.offerings,
    SelectedFilter: { ...state.CurrentFilter.SyncFilter },
    ItemDataLevel: state.PulseMORCaseStudyReducers.ItemDataLevel,
    isCategoryData: state.PulseMORCaseStudyReducers.isCategoryData,
    pulsePageData: state.CurrentFilter.pulsePageData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    InitializeMORData: (data, datalevel, isCategoryData) => {
      dispatch({
        type: "PULSE_MOR_DATA_INITIALIZE",
        payload: {
          MorItemData: data,
          ItemDataLevel: datalevel,
          isCategoryData: isCategoryData,
        },
      });
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MORAliasContainer);
