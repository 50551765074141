import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useFormik } from "formik";
import * as yup from "yup";
import Dropdown from "react-bootstrap/Dropdown";
import i18n from "i18next";

// IMPORT FUNCTIONS/CONSTANTS
import { sleep } from "../../Shared/commonFunctions";
import { validateBATFieldValue } from "../../Shared/Constant";
import { thousandSeparatorFormat } from "../../Shared/Common";


const MetricsImpacted = ({
  metricsImpacted,
  onModifyMetricsImpacted,
  getOptionObjFromDropdownValue,
  metricTypeImpactedDropdownData,
  unitDropdownData,
  parentFormErrors,
  mappedBOIMetrics,
  mappedKPIAndSLAMetrics,
  iimetrics,
  boiMetrics
}) => {
  const formFieldsWithEmptyInitialValues = {
    metricTypeImpacted: "", // API request field: MetricTypeImpacted
    impactedMetricId: "", // API request field: ImpactedMetricId
    baseLineValue: "", // API request field: BaseLineValue
    targetValue: "", // API request field: TargetValue
    currentValue: "", // API request field: CurrentValue
    unit: "", // API request field: Unit
  };

  const [formInitialValues, setFormInitialValues] = useState(formFieldsWithEmptyInitialValues);
  //////////////////////////////    STATES   //////////////////////////////////////////////

  const [impactedMetricsDropdownProps, setImpactedMetricDropdownProps] = useState({
    label: "",
    isRequired: false,
    options: [],
  });

  const [selectedMetric, setSelectedMetric] = useState({});

  /////////// BELOW BLOCK OF CODE IS FOR ADDING SOME CUSTOM VALIDATION METHODS TO "YUP" //////////////
  function checkCustomNumberValidation(args) {
    const { fieldLabel, message } = args;

    return this.test("custom-number-validation", message, function(value) {
      const { path, createError } = this;

      if (!_.isEmpty(value)) {
        if (!validateBATFieldValue(value)) {
          return createError({ path, message: message || `${fieldLabel} ${i18n.t("is_not_a_valid_number")}` });
        }

        const numberValue = _.toNumber(value);
        if (numberValue > 999999999999) {
          return createError({
            path,
            message: message || `${i18n.t("Value_must_be_in_between")} -999999999999 ${i18n.t("to")} 999999999999`,
          });
        }
        if (numberValue < -999999999999) {
          return createError({
            path,
            message: message || `${i18n.t("Value_must_be_in_between")} -999999999999 ${i18n.t("to")} 999999999999`,
          });
        }
      }

      return true;
    });
  }
  yup.addMethod(yup.string, "customNumberValidation", checkCustomNumberValidation);

  function checkRequiredWhenMetricTypeImpactedNotOther(args) {
    const { fieldLabel } = args;
    return this.when("metricTypeImpacted", {
      is: (metricTypeImpactedValue) =>
        !_.isEmpty(metricTypeImpactedValue) &&
        _.get(
          getOptionObjFromDropdownValue(metricTypeImpactedDropdownData, metricTypeImpactedValue),
          "label_en",
          ""
        ) !== "Other",
      then: yup.string().required(`${fieldLabel} ${i18n.t("is_Required")}`),
    });
  }
  yup.addMethod(yup.string, "requiredWhenMetricTypeImpactedNotOther", checkRequiredWhenMetricTypeImpactedNotOther);
  ///////////////////////////////////////////////////////////////////////////////////////////////////

  function getMetricImpactedDataFromObj(metricObj) {
    const metricTypeImpactedLabel = _.get(
      getOptionObjFromDropdownValue(metricTypeImpactedDropdownData, metricObj.metricTypeImpacted),
      "label",
      ""
    );

    const metricTypeImpactedLabel_en = _.get(
      getOptionObjFromDropdownValue(metricTypeImpactedDropdownData, metricObj.metricTypeImpacted),
      "label_en",
      ""
    );

    let impactedMetricsDropdownData = [];

    if (metricTypeImpactedLabel_en === "BOIs") {
      impactedMetricsDropdownData = mappedBOIMetrics;
    } else if (metricTypeImpactedLabel_en === "KPI/SLA/SLA+") {
      impactedMetricsDropdownData = mappedKPIAndSLAMetrics;
    }

    const impactedMetricLabel = !_.isEmpty(metricObj.impactedMetricId)
      ? _.get(getOptionObjFromDropdownValue(impactedMetricsDropdownData, metricObj.impactedMetricId), "label", "")
      : "";
    const metricTileDisplayLabel = !_.isEmpty(impactedMetricLabel) ? impactedMetricLabel : metricTypeImpactedLabel;
    const unitLabel = _.get(getOptionObjFromDropdownValue(unitDropdownData, metricObj.unit), "label", "");


    return {
      metricTypeImpactedLabel,
      impactedMetricLabel,
      metricTileDisplayLabel,
      unitLabel,
    };
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object().shape({
      metricTypeImpacted: yup
        .string()
        .required(`${i18n.t("Metric_Type_Impacted")} ${i18n.t("is_Required")}`)
        /* eslint-disable-next-line */
        .test(
          "is-exceed-then-total-allowed",
          `${i18n.t("Maximum")} 10 BOI/KPI/SLA/SLA+ ${i18n.t("can_be_added")}`,
          (metricTypeImpactedValue) => {
            let isValid = true;
            // WHEN `metricTypeImpactedValue` IS NOT EMPTY AND NOT "Other"
            if (!_.isEmpty(metricTypeImpactedValue) && metricTypeImpactedValue !== "3") {
              const filteredMetrics = _.filter(metricsImpacted, (eachMetric) => eachMetric.metricTypeImpacted !== "3");
              isValid = _.size(filteredMetrics) < 10;

              // WHEN THE METRIC IS IN "EDIT" MODE
              if (!isValid && !_.isEmpty(selectedMetric)) {
                // IF WHILE EDITING THE METRIC, THE VALUE OF `metricTypeImpacted` IS NOT CHANGED - OR - THE METRIC BEING EDITED has `metricTypeImpactedValue` NOT "Other", THEN SET `isValid` TO `true`
                if (
                  metricTypeImpactedValue === selectedMetric.metricTypeImpacted ||
                  selectedMetric.metricTypeImpacted !== "3"
                ) {
                  isValid = true;
                }
              }
            }
            return isValid;
          }
        )
        .test(
          "is-metricTypeImpacted-other-exists",
          `"${i18n.t("Other")}" ${i18n.t("Metric_Type_Impacted")} ${i18n.t("already_exists")}`,
          (metricTypeImpactedValue) => {
            let isValid = true;
            if (!_.isEmpty(metricTypeImpactedValue)) {
              const metricTypeImpactedLabel = _.get(
                getOptionObjFromDropdownValue(metricTypeImpactedDropdownData, metricTypeImpactedValue),
                "label_en",
                ""
              );

              if (metricTypeImpactedLabel === "Other") {
                const foundMatchingMetric = _.find(metricsImpacted, ["metricTypeImpacted", metricTypeImpactedValue]);
                let isMetricExists = !_.isEmpty(foundMatchingMetric);

                // WHEN THE METRIC IS IN "EDIT" MODE
                if (!_.isEmpty(foundMatchingMetric) && !_.isEmpty(selectedMetric)) {
                  isMetricExists = selectedMetric.uniqueId !== foundMatchingMetric.uniqueId;
                }
                isValid = !isMetricExists;
              }
            }

            return isValid;
          }
        ),

      impactedMetricId: yup
        .string()
        .requiredWhenMetricTypeImpactedNotOther({ fieldLabel: impactedMetricsDropdownProps.label })
        .test("is-same-impactedMetricId-exists", "", function(impactedMetricIdValue) {
          const { path, createError } = this;

          let isValid = true;
          const metricTypeImpacted = this.parent.metricTypeImpacted;

          if (!_.isEmpty(metricTypeImpacted) && !_.isEmpty(impactedMetricIdValue)) {
            const foundMatchingMetric = _.find(metricsImpacted, {
              metricTypeImpacted: metricTypeImpacted,
              impactedMetricId: impactedMetricIdValue,
            });
            let isMetricExists = !_.isEmpty(foundMatchingMetric);

            if (!_.isEmpty(foundMatchingMetric) && !_.isEmpty(selectedMetric)) {
              isMetricExists = selectedMetric.uniqueId !== foundMatchingMetric.uniqueId;
            }
            isValid = !isMetricExists;
          }
          if (!isValid) {
            const impactedMetricLabel = _.get(
              getOptionObjFromDropdownValue(impactedMetricsDropdownProps.options, impactedMetricIdValue),
              "label",
              ""
            );
            return createError({ path, message: `"${impactedMetricLabel}" ${i18n.t("already_exists")}` });
          }
          return isValid;
        }),

      baseLineValue: yup
        .string()
        .customNumberValidation({ fieldLabel: i18n.t("Metric_Baseline_Value") })
        .requiredWhenMetricTypeImpactedNotOther({ fieldLabel: i18n.t("Metric_Baseline_Value") }),

      targetValue: yup
        .string()
        .customNumberValidation({ fieldLabel: i18n.t("Metric_Target_Value") })
        .requiredWhenMetricTypeImpactedNotOther({ fieldLabel: i18n.t("Metric_Target_Value") }),

      currentValue: yup
        .string()
        .customNumberValidation({ fieldLabel: i18n.t("Metric_Current_Value") })
        .requiredWhenMetricTypeImpactedNotOther({ fieldLabel: i18n.t("Metric_Current_Value") }),

      unit: yup.string().requiredWhenMetricTypeImpactedNotOther({ fieldLabel: i18n.t("Unit") }),
    }),
    onSubmit: async (values, formikFunctions) => {

      // await new Promise((r) => setTimeout(r, 1500));
      formikFunctions.setSubmitting(false);

      handleClickCancelButton();

      let action = "add";
      const metricObj = {
        ...selectedMetric,
        ...values,
      };

      // WHEN IN "EDIT" MODE
      if (!_.isEmpty(selectedMetric)) {
        action = "edit";
      }
      onModifyMetricsImpacted(action, metricObj);
    },
  });

  const {
    values,
    errors,
    isValidating,
    isSubmitting,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    validateField,
    setErrors,
  } = formik;

  //////////////////////////////    EFFECTS   //////////////////////////////////////////////

  //  EXECUTES WHEN THE VALUE OF THE FIELD `metricTypeImpacted` AND DYNAMICALLY UPDATES THE LABEL, VISIBILITY AND DROPDOWN OPTIONS OF THE `metricTypeImpacted` FIELD
  useEffect(() => {
    let label = "";
    let isRequired = false;
    let options = [];

    const metricTypeImpactedLabel_en = _.get(
      getOptionObjFromDropdownValue(metricTypeImpactedDropdownData, values.metricTypeImpacted),
      "label_en",
      ""
    );

    if (metricTypeImpactedLabel_en === "BOIs") {
      label = "Business Outcome Indicator";
      isRequired = true;
      options = mappedBOIMetrics;
    } else if (metricTypeImpactedLabel_en === "KPI/SLA/SLA+") {
      label = `KPI/SLA/SLA+ ${i18n.t("Impacted")}`;
      isRequired = true;
      options = mappedKPIAndSLAMetrics;
    }

    setImpactedMetricDropdownProps({
      label,
      isRequired,
      options,
    });

    // REMOVE THE ERROR MESSAGES FOR ALL THE FIELDS
    setErrors({});
  }, [values.metricTypeImpacted]);

  // SUBMIT THE FORM ONCE ALL THE REQUIRED CONDITIONS ARE MET
  // useEffect(() => {
  //   // FORM IS VALID ONLY WHEN THERE ARE NO ERRORS (formik.isValid = true) AND THE TOUCHED FIELDS ARE NOT EMPTY
  //   if (!_.isEmpty(initiativeFormSubmittedAs) && !formik.isValidating && formik.isValid && !_.isEmpty(formik.touched)) {

  //     alert("NOW SUBMIT");
  //     formik.submitForm();
  //   }
  // }, [initiativeFormSubmittedAs, formik.isValid, formik.touched]);

  //////////////////////////////    EVENT HANDLERS   //////////////////////////////////////////////

  async function handleSelectDropdownOption(fieldName, selectedOptionEventKey) {
    setFieldValue(fieldName, selectedOptionEventKey);
    if (fieldName === "metricTypeImpacted") {
      setFieldValue("impactedMetricId", "");
      setFieldValue("currentValue", "");
    }
    if(fieldName === "impactedMetricId"){
      const arr = [...new Set([...iimetrics, ...boiMetrics])]
      const data = arr.find((item) => item.KpiMappingID == selectedOptionEventKey || item.MetricID == selectedOptionEventKey);
      if(data && data.LastPublishedValue){
        setFieldValue("currentValue", data.LastPublishedValue);
      }
    }
    await sleep(50);
    validateField(fieldName);
  }


  function handleBlurField(event) {

    handleBlur(event);
    validateField(event.target.id);
  }

  // VALIDATE THE FORM WHEN THE "ADD/UPDATE" BUTTON IS CLICKED
  function handleClickAddButton() {
    formik.validateForm().then(() => {
      const isFormValid = formik.isValid;

      if (isFormValid) {
        formik.submitForm();
      }
    });

  }

  function handleClickCancelButton() {
    setFormInitialValues(formFieldsWithEmptyInitialValues);
    setSelectedMetric({});
    formik.resetForm();
  }

  function handleClickEditMetric(selectedMetric) {
    handleClickCancelButton();

    const formFieldsWithValues = {};
    for (const formFieldName in formFieldsWithEmptyInitialValues) {
      if (formFieldsWithEmptyInitialValues.hasOwnProperty(formFieldName)) {
        formFieldsWithValues[formFieldName] = selectedMetric[formFieldName];
      }
    }
    setFormInitialValues(formFieldsWithValues);
    // formik.setValues(formFieldsWithValues, false);
    setSelectedMetric(selectedMetric);
  }

  function handleClickDeleteMetric(selectedMetric) {

    onModifyMetricsImpacted("delete", selectedMetric);
    handleClickCancelButton();
  }


  const impactedMetricsDropdownData = _.sortBy(impactedMetricsDropdownProps.options, ["label"]);
  const selectedMetricTypeImpactedOption = getOptionObjFromDropdownValue(
    metricTypeImpactedDropdownData,
    values.metricTypeImpacted
  );
  const areDependentFieldsRequired =
    !_.isEmpty(values.metricTypeImpacted) && _.get(selectedMetricTypeImpactedOption, "label_en", "") !== "Other";

  const metricsImpactedArray = _.map(metricsImpacted, (eachMetric) => {
    const metricObj = {
      ...eachMetric,
      ...getMetricImpactedDataFromObj(eachMetric),
    };
    return metricObj;
  });

  return (
    <div className="initiative-formtabcontent">
      <div className="initiative-formfields d-flex">
        {/* START - LEFT SIDE FORM SECTION */}
        <div className="leftinitiative-formfields">
          {/* IF THERE ARE NO METRICS IMPACTED ADDED WHILE SAVE THEN DISPLAY THE ERROR */}
          {parentFormErrors.metricsImpacted && <div class="errorMsg">{parentFormErrors.metricsImpacted}</div>}

          <form onSubmit={handleSubmit}>
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="metricTypeImpacted" className="form-label">
                  {i18n.t("Metric_Type_Impacted")}
                  <span className="required">&nbsp;*</span>
                </label>
                <div className="initiative-dropdown">
                  <Dropdown
                    id="metricTypeImpacted"
                    name="metricTypeImpacted"
                    onSelect={(selectedOptionEventKey) =>
                      handleSelectDropdownOption("metricTypeImpacted", selectedOptionEventKey)
                    }
                    onBlur={handleBlur}
                  >
                    <Dropdown.Toggle id="metricTypeImpactedToggle">
                      {_.isEmpty(values.metricTypeImpacted)
                        ? i18n.t("Metric_Type_Impacted")
                        : _.get(selectedMetricTypeImpactedOption, "label")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {_.map(metricTypeImpactedDropdownData, (eachItem) => (
                        <Dropdown.Item
                          eventKey={eachItem.value}
                          active={values.metricTypeImpacted === eachItem.value}
                          // key={eachItem.value} // COMMENTED OUT THE `key` ATTRIBUTE AS IT IS CAUSING SOME CACHING ISSUES IN THE DROPDOWN COMPONENT THAT LEADS TO BUGS
                        >
                          {eachItem.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              {errors.metricTypeImpacted && <div className="errorMsg">{errors.metricTypeImpacted}</div>}
            </div>

            {impactedMetricsDropdownProps.isRequired && (
              <>
                <div className="form-group border-group">
                  <label htmlFor="impactedMetricId" className="form-label">
                    {impactedMetricsDropdownProps.label} <span className="required">*</span>
                  </label>
                  <div className="initiative-dropdown">
                    <Dropdown
                      id="impactedMetricId"
                      name="impactedMetricId"
                      onSelect={(selectedOptionEventKey) =>
                        handleSelectDropdownOption("impactedMetricId", selectedOptionEventKey)
                      }
                      onBlur={handleBlur}
                    >
                      <Dropdown.Toggle id="impactedMetricIdToggle">
                        {_.isEmpty(values.impactedMetricId)
                          ? impactedMetricsDropdownProps.label
                          : _.get(
                              getOptionObjFromDropdownValue(impactedMetricsDropdownData, values.impactedMetricId),
                              "label",
                              ""
                            )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {_.map(impactedMetricsDropdownData, (eachItem) => (
                          <Dropdown.Item
                            eventKey={eachItem.value}
                            active={values.impactedMetricId === eachItem.value}
                            // key={eachItem.value} // COMMENTED OUT THE `key` ATTRIBUTE AS IT IS CAUSING SOME CACHING ISSUES IN THE DROPDOWN COMPONENT THAT LEADS TO BUGS
                          >
                            {eachItem.label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                {errors.impactedMetricId && <div className="errorMsg">{errors.impactedMetricId}</div>}
              </>
            )}

            <div className="form-group border-group">
              <label htmlFor="baseLineValue" className="form-label">
                {i18n.t("Metric_Baseline_Value")}
                {areDependentFieldsRequired && <span className="required">&nbsp;*</span>}
              </label>
              <input
                type="text"
                id="baseLineValue"
                name="baseLineValue"
                value={values.baseLineValue}
                onChange={handleChange}
                onBlur={handleBlurField}
                className="form-control"
                placeholder={i18n.t("Metric_Baseline_Value")}
                maxLength="12"
              />
            </div>
            {errors.baseLineValue && <div className="errorMsg">{errors.baseLineValue}</div>}

            <div className="form-group border-group">
              <label htmlFor="targetValue" className="form-label">
                {i18n.t("Metric_Target_Value")}
                {areDependentFieldsRequired && <span className="required">&nbsp;*</span>}
              </label>
              <input
                type="text"
                id="targetValue"
                name="targetValue"
                value={values.targetValue}
                onChange={handleChange}
                onBlur={handleBlurField}
                className="form-control"
                placeholder={i18n.t("Metric_Target_Value")}
                maxLength="12"
              />
            </div>
            {errors.targetValue && <div className="errorMsg">{errors.targetValue}</div>}

            <div className="form-group border-group">
              <label htmlFor="currentValue" className="form-label">
                {i18n.t("Metric_Current_Value")}
                {areDependentFieldsRequired && <span className="required">&nbsp;*</span>}
              </label>
              <input
                type="text"
                id="currentValue"
                name="currentValue"
                value={values.currentValue}
                onChange={handleChange}
                onBlur={handleBlurField}
                className="form-control"
                placeholder={i18n.t("Metric_Current_Value")}
                maxLength="12"
              />
            </div>
            {errors.currentValue && <div className="errorMsg">{errors.currentValue}</div>}

            <div className="form-group border-group">
              <label htmlFor="UnitDropdown" className="form-label">
                {i18n.t("Unit")} ({i18n.t("Baseline")}, {i18n.t("Target")}, {i18n.t("Current")}){" "}
                {areDependentFieldsRequired && <span className="required">*</span>}
              </label>
              <div className="initiative-dropdown">
                <Dropdown
                  id="unit"
                  name="unit"
                  onSelect={(selectedOptionEventKey) => handleSelectDropdownOption("unit", selectedOptionEventKey)}
                  onBlur={handleBlur}
                >
                  <Dropdown.Toggle id="unitDropdownToggle">
                    {_.isEmpty(values.unit)
                      ? i18n.t("Unit")
                      : _.get(getOptionObjFromDropdownValue(unitDropdownData, values.unit), "label", "")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {_.map(unitDropdownData, (eachItem) => (
                      <Dropdown.Item
                        eventKey={eachItem.value}
                        active={values.unit === eachItem.value}
                        // key={eachItem.value} // COMMENTED OUT THE `key` ATTRIBUTE AS IT IS CAUSING SOME CACHING ISSUES IN THE DROPDOWN COMPONENT THAT LEADS TO BUGS
                      >
                        {eachItem.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {errors.unit && <div className="errorMsg">{errors.unit}</div>}
          </form>
          <div className="initiative-addcancel-btns">
            <button
              type="button"
              onClick={() => handleClickCancelButton()}
              disabled={isValidating || isSubmitting || (_.isEmpty(selectedMetric) && !dirty)}
              className="btn btn-outline-primary mr-2"
            >
              {i18n.t("Cancel")}
            </button>
            <button
              type="button"
              onClick={() => handleClickAddButton()}
              disabled={isValidating || isSubmitting || (!_.isEmpty(selectedMetric) && !dirty)}
              className="btn btn-primary"
            >
              {_.isEmpty(selectedMetric) ? i18n.t("Add") : i18n.t("Update")}
            </button>
          </div>
        </div>
        {/* END - LEFT SIDE FORM SECTION */}

        {/* START - RIGHT SIDE SECTION */}
        <div className="rightinitiative-formfields">
          <h6>BOIs & KPI / SLA / SLA+ {i18n.t("Added")}</h6>
          <div className="customscroll-form">
            {/* LOOP OVER METRICS IMPACTED */}
            {_.map(_.sortBy(metricsImpactedArray, ["metricTileDisplayLabel"]), (eachMetric, index) => {
              return (
                <div className="form-group border-group" key={eachMetric.uniqueId}>
                  <div className="mectricimpacted-section">
                    <label className="form-label">{eachMetric.metricTileDisplayLabel}</label>
                    <ul className="metricvalues-list">
                      <li>{!_.isEmpty(String(eachMetric.baseLineValue)) ? eachMetric.baseLineValue : " - "}</li>
                      <li>{!_.isEmpty(String(eachMetric.targetValue)) ? eachMetric.targetValue : " - "}</li>
                      <li>{!_.isEmpty(String(eachMetric.currentValue)) ? thousandSeparatorFormat(eachMetric.currentValue) : " - "}</li>
                      <li>{!_.isEmpty(eachMetric.unitLabel) ? eachMetric.unitLabel : " - "}</li>
                    </ul>
                  </div>

                  <div className="mectricimpacted-morelink">
                    <a
                      href="javascript:void(0)"
                      id={`dropdownMenuLink${index}`}
                      className="morelink-icon"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span>
                        <i className="fal fa-ellipsis-v"></i>
                      </span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby={`dropdownMenuLink${index}`}>
                      <a
                        href="javascript:void(0)"
                        onClick={() => handleClickEditMetric(eachMetric)}
                        className="dropdown-item edit-icon"
                      >
                        {i18n.t("Edit")}
                      </a>
                      <a
                        href="javascript:void(0)"
                        onClick={() => handleClickDeleteMetric(eachMetric)}
                        className="dropdown-item delete-icon"
                      >
                        {i18n.t("Delete")}
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* END - RIGHT SIDE SECTION */}
      </div>
    </div>
  );
};

export default MetricsImpacted;
