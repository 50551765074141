import React, { Component } from 'react';
import axiosInstance from '../Shared/interceptor';
import {trycatchAlertPopup} from "../Shared/Constant";
import { LocalApiBaseUrl } from '../Shared/Constant';
import { store } from "../reducers/configureStore";
import { Tabs, Tab } from 'react-bootstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import LoadingOverlay from "react-loading-overlay";
import Chart from 'chart.js';
import 'chartjs-plugin-datalabels';
import i18n from "i18next";
import $ from "jquery";
import SynopsLoader from "../Shared/SynopsLoader";
import { dropRight } from 'lodash';
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;



class JourneyTrendGraphsBO extends Component {
    constructor() {
        super()
        this.state = {
            temp: [],
            isApiLoading: false,
            dsKpiData: []
        };
    }

    PlotTrendGraph(kpiMappingId) {
        let UpperThresholdMean = "";
        let LowerThresholdMean = "";
        let UpperThresholdMeanArray = [];
        let LowerThresholdMeanArray = [];
        let trendAvgArray = [];
        this.setState({
            isApiLoading: true
        })
        axiosInstance
            .get(`${LocalApiBaseUrl}Analytics/GetKpiData`, {
                params: { kpiMappingID: kpiMappingId },
            })
            .then((res) => {
                this.setState({
                    isApiLoading: false
                })
                let MontnDt = [""];
                let ValueDt = [];
                res.data.forEach((element) => {
                    MontnDt.push(element.PeriodDt);
                    ValueDt.push(parseFloat(element.MetricValue));
                });

                for (let i = res.data.length - 1; i >= 0; i--) {
                    if (
                        (res.data[i].UpperThrehold !== null && res.data[i].UpperThrehold.trim() != "") ||
                        (res.data[i].LowerThrehold !== null && res.data[i].LowerThrehold.trim() != "")
                    ) {
                        UpperThresholdMean = res.data[i].UpperThrehold;
                        LowerThresholdMean = res.data[i].LowerThrehold;
                        break;
                    }
                }

                let sum = ValueDt.reduce((previous, current) => (current += previous));
                let avg = sum / ValueDt.length;

                res.data.forEach((element) => {
                    trendAvgArray.push(avg);
                    UpperThresholdMeanArray.push(parseFloat(UpperThresholdMean));
                    LowerThresholdMeanArray.push(parseFloat(LowerThresholdMean));
                });

                this.setState(
                    {
                        temp: res.data,
                        trendAvg: avg,
                        UpperThresholdMean: UpperThresholdMean,
                        LowerThresholdMean: LowerThresholdMean,
                        dsKpiData: {
                            labels: MontnDt,
                            datasets: [
                                {
                                    label: i18n.t("Metric"),
                                    data: [null, ...ValueDt],
                                    fill: true,
                                    lineTension: 0.1,
                                    backgroundColor: "rgba(75,192,192,0.4)",
                                    borderColor: "rgba(75,192,192,1)",
                                    borderCapStyle: "butt",
                                    borderDash: [],
                                    borderDashOffset: 0.0,
                                    borderJoinStyle: "miter",
                                    pointBorderColor: "rgba(75,192,192,1)",
                                    pointBackgroundColor: "rgba(75,192,192,1)",
                                    pointBorderWidth: 1,
                                    pointHoverRadius: 8,
                                    pointHoverBackgroundColor: "rgba(75,192,192,1)",
                                    pointHoverBorderColor: "rgba(220,220,220,1)",
                                    pointHoverBorderWidth: 2,
                                    pointRadius: 3,
                                    pointHitRadius: 10,
                                },

                                {
                                    label: i18n.t("Mean"),
                                    fill: false,
                                    // data: [this.state.trendAvg],
                                    data: [avg, ...trendAvgArray],
                                    backgroundColor: "#f99245",
                                    lineTension: 0.1,
                                    borderColor: "#f99245",
                                    borderCapStyle: "butt",
                                    borderWidth: 1.5,
                                    borderDash: [5, 3],
                                    borderDashOffset: 0.0,
                                    borderJoinStyle: "miter",
                                    pointBorderColor: "#f99245",
                                    pointBackgroundColor: "#f99245",
                                    pointBorderWidth: 1,
                                    pointHoverRadius: 0,
                                    pointHoverBackgroundColor: "#f99245",
                                    pointHoverBorderColor: "#f99245",
                                    pointHoverBorderWidth: 0,
                                    pointRadius: 0,
                                    pointHitRadius: 0,
                                },
                                {
                                    label: i18n.t("Upper_Threshold"),
                                    fill: false,
                                    // data: [this.state.UpperThresholdMean],
                                    data: [UpperThresholdMean, ...UpperThresholdMeanArray],

                                    backgroundColor: "#ff0000",
                                    lineTension: 0.1,
                                    borderColor: "#ff0000",
                                    borderCapStyle: "butt",
                                    borderWidth: 1.5,
                                    borderDash: [5, 3],
                                    borderDashOffset: 0.0,
                                    borderJoinStyle: "miter",
                                    pointBorderColor: "#ff0000",
                                    pointBackgroundColor: "#ff0000",
                                    pointBorderWidth: 1,
                                    pointHoverRadius: 0,
                                    pointHoverBackgroundColor: "#ff0000",
                                    pointHoverBorderColor: "#ff0000",
                                    pointHoverBorderWidth: 0,
                                    pointRadius: 0,
                                    pointHitRadius: 0,
                                },
                                {
                                    label: i18n.t("Lower_Threshold"),
                                    fill: false,
                                    // data: [this.state.LowerThresholdMean],
                                    data: [LowerThresholdMean, ...LowerThresholdMeanArray],

                                    backgroundColor: "#6a0dad",
                                    lineTension: 0.1,
                                    borderColor: "#6a0dad",
                                    borderCapStyle: "butt",
                                    borderDash: [5, 3],
                                    borderWidth: 1.5,
                                    borderDashOffset: 0.0,
                                    borderJoinStyle: "miter",
                                    pointBorderColor: "#6a0dad",
                                    pointBackgroundColor: "#6a0dad",
                                    pointBorderWidth: 1,
                                    pointHoverRadius: 0,
                                    pointHoverBackgroundColor: "#6a0dad",
                                    pointHoverBorderColor: "#6a0dad",
                                    pointHoverBorderWidth: 0,
                                    pointRadius: 0,
                                    pointHitRadius: 0,
                                },
                            ],
                        },
                    },
                    () => {
                        
                    }
                );
            }).catch((error) => {
                trycatchAlertPopup(error);
                this.setState({
                    isApiLoading: false
                })
            });
    }
    componentDidMount() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        this.PlotTrendGraph(this.props.kpiMappingId)
    }

    render() {
        Chart.defaults.global.defaultFontColor = 'black';
        const style = {
            cursor: "default"
        }
        const formattertwo = new Intl.NumberFormat('en', {
            useGrouping: true
        })
        const { isApiLoading } = this.state
        return (
            <>
                {isApiLoading && <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active  />}

                <Line
                    data={this.state.dsKpiData}
                    redraw="true"
                    options={{
                        maintainAspectRatio: true,
                        responsive: true,

                        tooltips: {
                            enabled: true,
                            // mode: "index",
                            mode: "nearest",
                            intersect: true,

                            callbacks: {
                                title: function (tooltipItem) {
                                    let currentIndex = tooltipItem[0].index;
                                    if (currentIndex) {
                                        return this.state.temp[currentIndex - 1].Comments
                                            ? this.state.temp[currentIndex - 1].Comments
                                            : i18n.t("NA");
                                    }
                                }.bind(this),
                                // label: function (tooltipItems, data) {
                                //     let currentLable = data.datasets[tooltipItems.datasetIndex].label;

                                // //    let actualValues =  [...this.state.actualValues];
                                // //      return  currentLable + ':' +  actualValues[tooltipItems.datasetIndex].data[tooltipItems.index];

                                // }.bind(this)
                            },
                        },
                        hover: {
                            mode: "index",
                            intersect: true,
                        },
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        precision: 1,
                                    },
                                    gridLines: {
                                        drawOnChartArea: false,
                                    },

                                    scaleLabel: {
                                        display: true,
                                        labelString: this.props.kpiUnit,
                                    },
                                },
                            ],
                            xAxes: [
                                {
                                    gridLines: {
                                        drawOnChartArea: false,
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: i18n.t("Month"),
                                    },
                                },
                            ],
                        },
                        plugins: {
                            datalabels: {
                                display: function (context) {
                                    if (context.dataset.label === "Metric") return true;
                                    else return false;
                                },
                            },
                        },
                        legend: {
                            fontSize: 10,
                            display: true,
                            position: "bottom",
                            fullWidth: false,
                        },
                        annotation: {
                            drawTime: "afterDraw",
                            annotations: [],
                        },
                    }}
                />

            </>
        )
    }
}
export default withRouter(JourneyTrendGraphsBO)