import React from "react";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import _ from 'lodash';
import { LocalApiBaseUrl, regexForNumbersOnlyMTC, regexForNegativeNumbers } from "../Shared/Constant";
import { regexForFields } from "../Shared/Constant";
import i18n from "i18next";
import { Form} from "react-bootstrap";
import { store } from "../reducers/configureStore";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import KpiPopUp from "../Configurator/KpiPopUp";
import { regexForTextAreas } from "../Shared/Constant";
import { FormatDate,isValidText,InvalidDescription } from "../Shared/commonFunctions";
import { MetricValueThousandFormat, thousandSeparatorFormat } from "../Shared/Common";
import DatePicker from "react-datepicker";
export default class BO2_popup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: { OrgEntityID: 0, OfferingID: 0, LocationID: 0, MonthDT: null },
            show: this.props.show,
            showCategory: false,
            showaddCategory: false,
            iscatypedisable: false,
            isEdit: false,
            showaddCategoryType: false,
            showApproval: false,
            categories: [],
            CategoryDetails: { CategoryType: "", CategoryName: "" },
            apivalue: "",
            selectDropValue: "",
            selectCattypeValue: "",
            selectValue: this.props.SelectValue,
            Display: false,
            viewmore: false,
            CatNameID: 0,
            CatTypeID: 0,
            numeratorVal: 0,
            denomVal: 0,
            EditIndex: 0,
            isAddCatPopup: false,
            MetricNameOptions: [],
            criteriaOptions: [],
            valueConnector: false,
            con: false,
            valueEdit: false,
            valueConnectorEdit: false,
            errors: {},
            commentsError: "",
            formIsValid: false,
            IsBatDealLevelForOffering: false,
            KpiMetricConnectorID: false,
            allCategoryTypesAndNames: [],
            CategoryNameData: [],
            BaselineMonthReadOnly: true,
            categoriesLimit:30, // Default
            data: {
                SOCategoryID: 0,
                kpiData: {
                    KPIMappingID: 0,
                    isRelatorBlock: this.props.isRelatorBlock,
                    FormulaList: [],
                    formulaStr: '',
                    MetricSeq: null,
                    MetricID: null,
                    valueExist: false,
                    LeadingAndSimilarCheck: false,
                    MetricName: "",
                    FrequencyID: null,
                    MetricUnit: "",
                    MetricImage: "",
                    KPIDesp: "",
                    MetricDataID: null,
                    Comments: "",
                    MetricValue: "",
                    RAGStatus: "",
                    BaselineValue: "",
                    BaseLineMonth: "",
                    TargetValue: "",
                    UpperThreshold: "",
                    LeadingIndustry: "",
                    SimilarIndustry: "",
                    LowerThreshold: "",
                    LeadingPractice: "",
                    
                    Month: "",
                    metDataAttr: [
                        {
                            AttributeName:
                                this.props.attributes.filter(z => z.ApplicableForDD === false).length > 0
                                    ? this.props.attributes.filter(z => z.ApplicableForDD === false)[0].AttributeName1
                                    : "",
                            AttributeValue: ""
                        },
                        {
                            AttributeName:
                                this.props.attributes.filter(z => z.ApplicableForDD === false).length > 0
                                    ? this.props.attributes.filter(z => z.ApplicableForDD === false)[0].AttributeName2
                                    : "",
                            AttributeValue: ""
                        }
                    ],
                    ConnectorID: "",
                    ConnectorNames: "",
                    CriteriaID: "",
                    SourceFieldName: "",
                    SourceName: "",
                    IsAccentureOwned: false,
                    MetricDefination: [],
                    MetricDefinitionID: '',
                    CategoryDetails: [],
                    IsMultiLevel: false,
                    IsAggregation: false
                }
            }
        };
        this.validateForm = this.validateForm.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
        this.onSequenceChange = this.onSequenceChange.bind(this);
        this.onUnitChange = this.onUnitChange.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.onValDescChange = this.onValDescChange.bind(this);
        this.onUpperChange = this.onUpperChange.bind(this);
        this.onLowerChange = this.onLowerChange.bind(this);
        this.maxLengthCheckThreshold = this.maxLengthCheckThreshold.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onSourceChange = this.onSourceChange.bind(this);
        this.onSourceSystemChange = this.onSourceSystemChange.bind(this);
        this.onOtherSystemChange = this.onOtherSystemChange.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.handleRejectHide = this.handleRejectHide.bind(this);
        this.handleApproveHide = this.handleApproveHide.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.onToolOwnerChange = this.onToolOwnerChange.bind(this);
        this.getGetConnectorMaster = this.getGetConnectorMaster.bind(this);
        this.onLeadingIndustryChange = this.onLeadingIndustryChange.bind(this);
        this.onSimilarIndustryChange = this.onSimilarIndustryChange.bind(this);
        this.handleCategoryTypeAddShow = this.handleCategoryTypeAddShow.bind(this);
        let offeringValue = "";
        let value1 = [];
        let isConnectorMetricDataFound = true;
        let isSelectedMetricMappedToConnector = false;
        let selectedMetricConnectorLastUpdated;
        this.handleClick = this.handleClick.bind(this);
        this.handleCategoryShow = this.handleCategoryShow.bind(this);
        this.handleCategoryEditShow = this.handleCategoryEditShow.bind(this);
        this.handleCategoryEdit = this.handleCategoryEdit.bind(this);
        this.handleCategoryClose = this.handleCategoryClose.bind(this);
        this.handleCategoryAdd = this.handleCategoryAdd.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.handleaddCategoryShow = this.handleaddCategoryShow.bind(this);
        this.handleaddCategoryClose = this.handleaddCategoryClose.bind(this);
        this.deleteCategoryType = this.deleteCategoryType.bind(this);
        this.editCategoryName = this.editCategoryName.bind(this);
        this.deleteCategoryName = this.deleteCategoryName.bind(this);
        this.handleaddCategoryNameClose = this.handleaddCategoryNameClose.bind(this);
        this.handleaddCategoryAdd = this.handleaddCategoryAdd.bind(this);
        this.handleApprovalClose = this.handleApprovalClose.bind(this);
        this.CatTypeSelect = this.CatTypeSelect.bind(this);
        this.getKpiMAppingDetails = this.getKpiMAppingDetails.bind(this);
        this.onBaselineValueChange = this.onBaselineValueChange.bind(this);
        this.onBaselineMonthChange = this.onBaselineMonthChange.bind(this);
        this.onTargetValueChange = this.onTargetValueChange.bind(this);
    }

    deleteCategoryName(catNameToDelete) {
        this.setState({ errors: {} })
        let CatTypeID = this.state.CatTypeID
        let kpiData = this.state.data.kpiData;
        var allCategoriesList = this.state.data.kpiData.CategoryDetails;
        let CategoryNameData=this.state.CategoryNameData
        allCategoriesList.splice(allCategoriesList.findIndex(item => item.CategoryName === catNameToDelete), 1)
        
        if (allCategoriesList.length===0) {
            kpiData.MetricValue = '';
        }

        this.setState({
            CatNameID: 0,
             CategoryNameData:allCategoriesList.length===0?[]:CategoryNameData,
            CatTypeID: CatTypeID,//CHANGED
            data: {
                kpiData: kpiData
            }
        });
        if (kpiData.IsAggregation && !this.state.valueConnectorEdit && !this.state.valueConnector && !this.isSelectedMetricMappedToConnector) {
            //calculate the metric value on the fly when new values added/ edited/deleted
            this.calculateAggregateValueByFormula();
        }


    }

    editCategoryName(item) {
        this.setState({
            showCategoryNameEditPopup: true
        });

    }

    deleteCategoryType() {

        if(window.confirm(i18n.t("delete_Category_msg")))
        {
            let kpiData = this.state.data.kpiData;
            kpiData.CategoryDetails = [];
            if (kpiData.IsAggregation && !this.state.valueConnectorEdit && !this.state.valueConnector && !this.isSelectedMetricMappedToConnector) {
                // if there are no categories, clear out the value calculated using the formula
                kpiData.MetricValue = '';
            }
    
            this.setState({
                CatNameID: 0,
                CatTypeID: 0,
                CategoryNameData:[],
                data: {
                    kpiData: kpiData
                }
            });
        }

    }

    onClose(e) {
        this.setState({ viewmore: false });
        this.props.onClose(e);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
        //   this.getKpiMAppingDetails();
        this.callingFunction();
        }

    }

     callingFunction=async()=>{

await this.getKpiMAppingDetails();
if(this.props.kpiData){
let kpiData = { ...this.props.kpiData };
let CatTypeID = kpiData.CategoryDetails && kpiData.CategoryDetails.length > 0 ? kpiData.CategoryDetails[0].OfferingMultiLevelCategoryID : 0;

let CatType = kpiData.CategoryDetails && kpiData.CategoryDetails.length > 0 ? kpiData.CategoryDetails[0].CategoryType : 0;
// let OfferingMultiLevelCategoryID=kpiData.CategoryDetails[0].OfferingMultiLevelCategoryID;



let CategoryNameData = (CatType && CatType != "") ? this.state.allCategoryTypesAndNames.filter(
    eachValue => eachValue.CategoryType == CatType
) : [];//FETCHING CATEGORY NAME


let CategoryDetails = this.state.CategoryDetails
CategoryDetails.CategoryType = CatType
this.setState({
    CategoryNameData: CategoryNameData,
    CategoryDetails: CategoryDetails,
    CatTypeID: CatTypeID,
    iscatypedisable: true
})

}

    }
    getCategoriesLimit = () =>{
        let data = store.getState().CurrentFilter.multiLevelCategoriesLimit;
        this.setState({
          categoriesLimit:data
        })
    }

     componentDidMount() {


         this.getGetConnectorMaster();
         this.getKpiMAppingDetails();
         this.getCategoriesLimit();
         

        var data = store.getState();


        // var data = store.getState();

      


        this.setState({ IsBatDealLevelForOffering: data.CurrentFilter.IsBatDealLevel }, () => {

            if (this.props.kpiData) {
                let attributeLabelsForBO = this.props.attributes.length > 0 ? this.props.attributes.filter(z => z.ApplicableForDD === false) : []

                //this.getKpiMAppingDetails(),;

               
                    let kpiData = _.cloneDeep({...this.props.kpiData});


                
               if(kpiData.CategoryDetails && kpiData.CategoryDetails.length===0){
            kpiData.IsMultiLevel=false;
                }
                kpiData.isRelatorBlock = this.props.isRelatorBlock;
                let valueExist = false;
                if (!kpiData.metDataAttr || kpiData.metDataAttr.length === 0) {
                    kpiData.metDataAttr = [
                        {
                            AttributeName:
                                attributeLabelsForBO.length > 0
                                    ? attributeLabelsForBO[0].AttributeName1
                                    : "",
                            AttributeValue: null
                        },
                        {
                            AttributeName:
                                attributeLabelsForBO.length > 0
                                    ? attributeLabelsForBO[0].AttributeName2
                                    : "",
                            AttributeValue: null
                        }
                    ];
                } else {
                    if (kpiData.metDataAttr[0].AttributeValue) {
                        valueExist = true;
                    }
                }
                // if (!kpiData.MetricDefination || kpiData.MetricDefination.length === 0) {
                 //   kpiData.MetricDefinitionID = -1;

                //}

                if (kpiData.MetricDefination && kpiData.MetricDefination.length > 0 &&
                    kpiData.MetricDefination.filter((x) => String(x.KPIMetricDefinatoinID) === String(kpiData.MetricDefinitionID)).length === 0) {
                    kpiData.MetricDefinitionID = 0;

                }

                this.setState({
                  
                    data: {
                        kpiData: kpiData
                    },
                    errors: {},
                    formIsValid: true,
                    valueExist: valueExist,
                    BaselineMonthReadOnly: kpiData.BaselineValue ? false : true
                });
            }

        }
        );
         this.callingFunction();
     
    }
    bindToolFieldChange = (e) => {
        const id = e.target.id;
        let data = this.state.CategoryDetails;
        switch (id) {
            case "categoryType":
                data.CategoryType = e.target.value;
                break;
            case "categoryName":
                data.CategoryName = e.target.value;
                break;
        }

        this.setState({
            CategoryDetails: data
        });
    }

    onNumeratorChange = (e) => {
        let errors = this.state.errors
        errors.NumeratorValue = ''
        this.setState({ errors: errors })
        if(e.target.value.length <=12)
        {
                this.setState({ numeratorVal: e.target.value });
            }
        }


    onDenomChange = (e) => {
        let errors = this.state.errors
        errors.Denominator = ''
        this.setState({ errors: errors })
        if(e.target.value.length <=12)
        {
                this.setState({ denomVal: e.target.value });
            }
        }
    
  async  getKpiMAppingDetails() {
        let samp = {};
        let valueConnectorEdit = false;
        let mappedConnectorName;
        return new Promise((resolve, reject) => {
        axiosInstance
            .get(`${LocalApiBaseUrl}Configurator/GetKpiMapping`, {
                params: {
                    OrgEntityID: this.props.filterDetails.Client,
                    LocationID: this.props.filterDetails.Locations,
                    OfferingID: this.props.filterDetails.process,
                    SOCategoryID: this.props.SOCategoryID,
                    MonthDT: this.props.filterDetails.Month
                }
            })
            .then(response => {
                let mappingData = response.data;
                let kpiData = this.state.data.kpiData;
                let iskpiConnector = false;
                let isaccentureOwned = false;
                if (mappingData.length > 0) {
                    // let  kpiData = this.state.data.kpiData;
                      kpiData.FormulaList = mappingData[0].FormulaList;

                }                    



                if (this.props.kpiData) {

                    let mappedConnector = mappingData.filter(
                        x => x.KpiMappingID == this.props.kpiData.KPIMappingID
                    );

                    if (mappedConnector.length > 0) {

                        iskpiConnector = mappedConnector[0].KpiMetricConnectorID != 0 ? true : false;
                        isaccentureOwned = mappedConnector[0].IsAccentureOwned;

                            // this.setState({

                            //     KpiMetricConnectorID: mappedConnector[0].KpiMetricConnectorID != 0 ? true : false

                            // })
                        this.isConnectorMetricDataFound = mappedConnector[0].IsConnectorMetricDataFound;
                        this.isSelectedMetricMappedToConnector = mappedConnector[0].IsConnector;
                        this.selectedMetricConnectorLastUpdated = mappedConnector[0].ConnectorUpdatedTS;

                        if (this.props.kpiData && ((this.props.kpiData.ConnectorID == undefined || parseInt(this.props.kpiData.ConnectorID) === 0)
                            || (this.props.kpiData.ConnectorNames == null || this.props.kpiData.ConnectorNames == undefined))) {
                            if (mappedConnector.length > 0 && mappedConnector[0].ConnectorName != "" && mappedConnector[0].ConnectorName !=null) {
                                mappedConnectorName = mappedConnector[0].ConnectorName;
                                let mappedConnectID = mappedConnector[0].ConnectorID;


                                this.props.kpiData.ConnectorNames = mappedConnectorName;
                                this.props.kpiData.ConnectorID = mappedConnectID;

                                if (this.state.data) {

                                    kpiData.ConnectorNames = (mappedConnectorName != null && mappedConnectorName != undefined ? mappedConnectorName : kpiData.ConnectorNames);
                                    kpiData.ConnectorID = (mappedConnectID > 0 ? mappedConnectID : kpiData.ConnectorID);


                                }
                            }
                        }
                    }

                    if (mappedConnectorName != null) {
                        valueConnectorEdit = true;
                    }
                }
                this.setState({
                    MetricNameOptions: response.data, valueConnectorEdit, allCategoryTypesAndNames: mappingData.length > 0 ? mappingData[0].CategoryList : [],
                    KpiMetricConnectorID: iskpiConnector, IsAccentureOwned: isaccentureOwned,

                    data: {
                        kpiData: kpiData
                    }
                
                }, () => {
                                     resolve();
                                     }
                                     );                
            });
        })
    }

    getGetConnectorMaster() {
        let source = [];
        axiosInstance
            .get(`${LocalApiBaseUrl}ConnectorMapping/GetConnectorMaster`)
            .then(response => {
                source = response.data;
                let sourceValues = source.map(x => (
                    <option
                        key={x.ConnectorID}
            /*selected={index == 0 ? "" : ""}*/ value={x.ConnectorID}
                    >
                        {x.ConnectorName}
                    </option>
                ));
                this.setState({ sourceOptions: sourceValues });
            });
    }
    // componentWillReceiveProps(nextProps) {

    //     if (nextProps.filterDetails != undefined)
    //         if ((nextProps.filterDetails.Client != 0) && (nextProps.filterDetails.Locations != 0) && (nextProps.filterDetails.Offering != 0) && (nextProps.filterDetails.Process != 0)) {
    //             this.setState({ Display: true })
    //         }
    // }
    onNameChange(event) {
        if (!event.target.value) {
            const kpiData = this.state.data.kpiData;
            kpiData.KPIDesp = "";
            kpiData.MetricUnit = "";
            kpiData.MetricName = null;
            kpiData.MetricSeq = "";
            kpiData.KPIMappingID = "";
            kpiData.LeadingPractice = "";
            kpiData.MetricValue="";
            //kpiData.MetricValue= "";//CHANGED
            kpiData.IsMultiLevel = false;
            kpiData.IsAggregation = false;
            this.isConnectorMetricDataFound = true;
            this.isSelectedMetricMappedToConnector = false;
            this.selectedMetricConnectorLastUpdated = undefined;

            kpiData.LeadingAndSimilarCheck = false;
            if (kpiData.metDataAttr && kpiData.metDataAttr.length > 0) {
                kpiData.metDataAttr[0].AttributeValue = null;
                kpiData.metDataAttr[1].AttributeValue = null;
            }

            // RESET THE METRIC DEFINITION DROPDOWN OPTIONS AND SELECTED METRIC DEFINITION
            kpiData.MetricDefination = [];
            kpiData.MetricDefinitionID = '';

            this.setState({
                data: {
                    kpiData: kpiData
                }
            });
        } else {

            let errors = {};
            this.setState({
                errors: errors
            });
            const kpiData = this.state.data.kpiData;
            kpiData.IsMultiLevel = false;
            kpiData.IsAggregation = false;
            kpiData.CategoryDetails = [];
            kpiData.KPIMappingID = event.target.value;
            kpiData.SourceName = "";
            kpiData.SourceFieldName = "";
            kpiData.MetricValue = "";//CHANGED
            if (kpiData.KPIMappingID !== "-1") {
                let selectedMetric = this.state.MetricNameOptions.filter(
                    data => data.KpiMappingID == event.target.value
                )[0];
                this.isConnectorMetricDataFound = selectedMetric.IsConnectorMetricDataFound;
                this.isSelectedMetricMappedToConnector = selectedMetric.IsConnector;
                this.selectedMetricConnectorLastUpdated = selectedMetric.ConnectorUpdatedTS;

                kpiData.KPIDesp = this.state.MetricNameOptions.filter(
                    data => data.KpiMappingID == event.target.value
                )[0].MetricDescription;
                kpiData.MetricUnit = this.state.MetricNameOptions.filter(
                    data => data.KpiMappingID == event.target.value
                )[0].MetricUnit;
                kpiData.MetricName = this.state.MetricNameOptions.filter(
                    data => data.KpiMappingID == event.target.value
                )[0].MetricName;
                kpiData.MetricSeq = this.state.MetricNameOptions.filter(
                    data => data.KpiMappingID == event.target.value
                )[0].MetricSeq;
                kpiData.BaselineValue=this.state.MetricNameOptions.filter(
                    data => data.KpiMappingID == event.target.value
                )[0].BaselineValue;
                kpiData.BaseLineMonth=this.state.MetricNameOptions.filter(
                    data => data.KpiMappingID == event.target.value
                )[0].BaseLineMonth;
                let BaselineMonthReadOnly=kpiData.BaselineValue?false:true;
                this.setState({BaselineMonthReadOnly:BaselineMonthReadOnly})
                kpiData.TargetValue=this.state.MetricNameOptions.filter(
                    data => data.KpiMappingID == event.target.value
                )[0].TargetValue;
                kpiData.LeadingPractice = this.state.MetricNameOptions.filter(
                    data => data.KpiMappingID == event.target.value
                )[0].LeadingPractice;
                // kpiData.NumeratorDescription = this.state.MetricNameOptions.filter(
                //   data => data.KpiMappingID == event.target.value
                // )[0].NumeratorDescription;
                // kpiData.DenominatorDescription = this.state.MetricNameOptions.filter(
                //   data => data.KpiMappingID == event.target.value
                // )[0].DenominatorDescription;
           kpiData.MetricValue=selectedMetric.IsConnector ?selectedMetric.MetricValue:"";
                kpiData.SOCategoryID = this.props.SOCategoryID;
                kpiData.LeadingAndSimilarCheck = false;
                if (kpiData.metDataAttr && kpiData.metDataAttr.length > 0) {
                    kpiData.metDataAttr[0].AttributeValue = this.state.MetricNameOptions.filter(
                        data => data.KpiMappingID == event.target.value
                    )[0].Attribute1Value;
                    kpiData.metDataAttr[1].AttributeValue = this.state.MetricNameOptions.filter(
                        data => data.KpiMappingID == event.target.value
                    )[0].Attribute2Value;
                    if (kpiData.metDataAttr[0].AttributeValue) {
                        kpiData.LeadingAndSimilarCheck = true;
                    }
                }

                // SET KPI MULTIPLE DEFINITIONS
                kpiData.MetricDefination = this.state.MetricNameOptions.filter(
                    data => data.KpiMappingID == event.target.value
                )[0].MetricDefination;

                const kpiMetricDefinitions = _.get(this.state, 'data.kpiData.MetricDefination', []);

                const enabledMetricDefinitions = _.filter(kpiMetricDefinitions, eachMetricDefinition => String(eachMetricDefinition.IsEnabled) === 'true');



                // RESET THE SELECTED METRIC DEFINITION
                kpiData.MetricDefinitionID = '';


                if (enabledMetricDefinitions.length === 0) {
                    // this.setState({kpiData["MetricDefinitionID"]:-1});
                    const kpiDat = this.state.data.kpiData;
                    kpiDat.MetricDefinitionID = -1;
                    this.setState({
                        data: {
                            kpiData: kpiDat
                        }
                    });

                }


            } else {
                this.setState({
                    viewmore: true,
                    show: false
                });
            }
            let con = "";
            let valueConnector = false;

            let metriName = this.state.MetricNameOptions.filter(
                eachValue => eachValue.KpiMappingID == event.target.value
            );
            if (metriName.length > 0) {
                let connectorName = metriName[0].ConnectorName;
                let connectorId = metriName[0].ConnectorID;
                let criteria = metriName[0].Criteria;
                let MetricValue = metriName[0].MetricValue;
                let IsAccentureOwned = metriName[0].IsAccentureOwned;
                let KpiMetricConnectorID = metriName[0].KpiMetricConnectorID;
                if (connectorName == null) {
                    con = false;
                    kpiData.ConnectorNames = connectorName;
                    kpiData.ConnectorID = connectorId;
                    kpiData.SourceFieldName = criteria;
                    kpiData.MetricValue = "";
                    kpiData.IsAccentureOwned = IsAccentureOwned;
                    kpiData.KpiMetricConnectorID = KpiMetricConnectorID;
                } else {
                    con = true;
                    kpiData.ConnectorNames = connectorName;
                    kpiData.ConnectorID = connectorId;
                    kpiData.SourceFieldName = criteria;
                    kpiData.MetricValue = MetricValue;
                    kpiData.IsAccentureOwned = IsAccentureOwned;
                    kpiData.KpiMetricConnectorID = KpiMetricConnectorID;

                    if (metriName[0].KpiMetricConnectorID !== 0) {
                        valueConnector = true;
                    }
                }
            }

            let connectorOptions = metriName.map(eachProcess => (
                <option key={eachProcess.ConnectorID} value={eachProcess.ConnectorID}>
                    {eachProcess.ConnectorName}
                </option>
            ));
            let criteriaOptions = metriName.map((eachProcess, index) => (
                <option key={index} value={eachProcess.Criteria}>
                    {eachProcess.Criteria}
                </option>
            ));

            this.setState({
                data: {
                    kpiData: kpiData
                },
                con,
                connectorOptions,
                criteriaOptions,
                valueConnector
            });
        }
    }
    onDescriptionChange(event) {
        const kpiData = this.state.data.kpiData;
        kpiData.KPIDesp = event.target.value;
        this.setState({
            data: {
                kpiData: kpiData
            }
        });
    }
    onSequenceChange(event) {
        let errors = {};
        this.setState({
            errors: errors
        });
        const kpiData = this.state.data.kpiData;
        kpiData.MetricSeq = event.target.value;
        this.setState({
            data: {
                kpiData: kpiData
            }
        });
    }
    onUnitChange(event) {
        let errors = {};
        this.setState({
            errors: errors
        });
        const kpiData = this.state.data.kpiData;
        kpiData.MetricUnit = event.target.value;
        this.setState({
            data: {
                kpiData: kpiData
            }
        });
    }
    onValueChange(event) {
        let errors = {};
        this.setState({
            errors: errors
        });
        const kpiData = this.state.data.kpiData;
        if (event.target.value === "") {
            kpiData.MetricValue = event.target.value;
            this.setState({
                data: {
                    kpiData: kpiData
                }
            });
        } else {
            if (regexForNegativeNumbers.test(event.target.value)) {
                if(event.target.value === "-."){
                    kpiData.MetricValue = "-0.";  
                }else{
                    kpiData.MetricValue = event.target.value;
                }
                this.setState({
                    data: {
                        kpiData: kpiData
                    }
                });
            }
        }


    }
    onValDescChange(event) {
        const { commentsError, data } = this.state;
        let str = event.target.value;
        let err = commentsError;
        if (event.target.value) {
            if (regexForTextAreas.test(str)) {
                let kpiData = data.kpiData;
                kpiData.Comments = event.target.value;
                if (str && str.length < 300) {
                    err = "";
                    this.setState({
                        data: {
                            kpiData: kpiData

                        }, commentsError: err

                    });
                } else {
                    err = i18n.t("commentsErrorM1");
                    this.setState({
                        data: {
                            kpiData: kpiData,

                        }, commentsError: err
                    });
                }
            } else {
                let newdata = data.kpiData;
                newdata.Comments = event.target.value;
                err = i18n.t("commentsErrorM2");
                this.setState({
                    data: {
                        kpiData: newdata,

                    }, commentsError: err
                });
            }
        } else {
            let kpiData = data.kpiData;
            kpiData.Comments = event.target.value;
            err = "";
            this.setState({
                data: {
                    kpiData: kpiData,

                }, commentsError: err
            });
        }
    }
    onUpperChange(event) {
        const kpiData = this.state.data.kpiData;
        if (event.target.value === "") {
            kpiData.UpperThreshold = event.target.value;
            this.setState({
                data: {
                    kpiData: kpiData
                }
            });
        } else {
            if (regexForNumbersOnlyMTC.test(event.target.value)) {
                kpiData.UpperThreshold = event.target.value;
                this.setState({
                    data: {
                        kpiData: kpiData
                    }
                });
            }
        }
    }
    onLowerChange(event) {
        const kpiData = this.state.data.kpiData;
        if (event.target.value === "") {
            kpiData.LowerThreshold = event.target.value;
            this.setState({
                data: {
                    kpiData: kpiData
                }
            });
        } else {
            if (regexForNumbersOnlyMTC.test(event.target.value)) {
                kpiData.LowerThreshold = event.target.value;
                this.setState({
                    data: {
                        kpiData: kpiData
                    }
                });
            }
        }
    }
    onBaselineValueChange(event) {
        const { data } = this.state;
        const kpiData = data.kpiData;
        if (event.target.value === "") {
          kpiData.BaselineValue = event.target.value;
          kpiData.BaseLineMonth = "";
          this.setState({
            data: {
              kpiData: kpiData
            },
            BaselineMonthReadOnly: true
          });
        } else {
          if (regexForNegativeNumbers.test(event.target.value)) {
            if (event.target.value === "-.") {
              kpiData.BaselineValue = "-0.";
            } else {
              kpiData.BaselineValue = event.target.value;
            }
            this.setState({
              data: {
                kpiData: kpiData
              },
              BaselineMonthReadOnly: false
            });
          }
        }
      }
      onBaselineMonthChange = (date) => {
        const kpiData = this.state.data.kpiData;    
          kpiData.BaseLineMonth=date;
        this.setState({
          data: {
            kpiData: kpiData
          }          
        })
      }
      onTargetValueChange(event) {
        const { data } = this.state;
        const kpiData = data.kpiData;
        if (event.target.value === "") {
          kpiData.TargetValue = event.target.value;
          this.setState({
            data: {
              kpiData: kpiData
            }
          });
        } else {
          if (regexForNegativeNumbers.test(event.target.value)) {
            if (event.target.value === "-.") {
              kpiData.TargetValue = "-0.";
            } else {
              kpiData.TargetValue = event.target.value;
            }
            this.setState({
              data: {
                kpiData: kpiData
              }
            });
          }
        }
      }
    maxLengthCheckThreshold = (object) => {
      if (object.target.value.length > object.target.maxLength) {
       object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
      }
    onLeadingIndustryChange(event) {

        let kpiData = this.state.data.kpiData;
        kpiData.metDataAttr[0].AttributeValue = event.target.value.trim();
        this.setState({
            data: {
                kpiData: kpiData
            }
        });
    }
    onSimilarIndustryChange(event) {
        let kpiData = this.state.data.kpiData;
        kpiData.metDataAttr[1].AttributeValue = event.target.value.trim();
        this.setState({
            data: {
                kpiData: kpiData
            }
        });
    }

    onStatusChange(event) {
        let errors = {};
        this.setState({
            errors: errors
        });
        const kpiData = this.state.data.kpiData;
        kpiData.RAGStatus = event.target.value;
        this.setState({
            data: {
                kpiData: kpiData
            }
        });
    }

    onSourceChange(event) {
        const kpiData = this.state.data.kpiData;
        kpiData.ConnectorID = event.target.value;
        var index = event.nativeEvent.target.selectedIndex;
        kpiData.ConnectorNames = event.nativeEvent.target[index].text;
        kpiData.SourceName = "";
        kpiData.SourceFieldName = "";
        this.setState({
            data: {
                kpiData: kpiData
            }
        });

        // if (kpiData.ConnectorNames == "Manual") {
        //     document.getElementById('select02').disabled = true;
        // }
        // else {
        //     document.getElementById('select02').disabled = false;

        // }
    }
    onToolOwnerChange(event) {
        const kpiData = this.state.data.kpiData;
        kpiData.IsAccentureOwned = event.target.checked;
        this.setState({
            data: {
                kpiData: kpiData
            }
        });
    }
    onSourceSystemChange(event) {
        const kpiData = this.state.data.kpiData;
        kpiData.SourceFieldName = event.target.value;
        this.setState({
            data: {
                kpiData: kpiData
            }
        });
    }
    onOtherSystemChange(event) {
        const kpiData = this.state.data.kpiData;
        kpiData.SourceName = event.target.value;
        this.setState({
            data: {
                kpiData: kpiData
            }
        });
    }

    bindAutoAggregationChange = (e) => {
        const kpiData = this.state.data.kpiData;

        kpiData.IsAggregation = e.target.checked;

        this.setState({
            data: {
                kpiData: kpiData
            }
        });

        if (kpiData.IsAggregation && !this.state.valueConnectorEdit && !this.state.valueConnector && !this.isSelectedMetricMappedToConnector) {

            this.calculateAggregateValueByFormula();
        }


    }

    calculateAggregateValueByFormula = () => {
        const kpiData = this.state.data.kpiData;
        const metricUnit = kpiData.MetricUnit;
        let temp = 0;
        let formulaStr = '';
        if (!kpiData.FormulaList)
            kpiData.FormulaList = [];
        if (metricUnit.trim() === '%') {
            if (kpiData.FormulaList.length > 0) {
                formulaStr = kpiData.FormulaList.filter(z => z.IsPercentageUnit === true)[0].Formula;


            }

        }
        else {

            if (kpiData.FormulaList.length > 0) {
                formulaStr = kpiData.FormulaList.filter(z => z.IsPercentageUnit === false)[0].Formula;


            }
            kpiData.formulaStr = formulaStr;
            this.setState({
                data: {
                    kpiData: kpiData
                }
            });
        }
        if (kpiData.CategoryDetails && kpiData.CategoryDetails.length > 0) {
            let total = 0;
            let numeratorSum = 0;
            let denominatorSum = 0;


            switch (formulaStr.toUpperCase().trim()) {
                case 'SUM':

                    kpiData.CategoryDetails.forEach(function (arrayItem) {


                        if (!arrayItem.Denominator) {
                            temp = 1;

                            // arrayItem.Denominator = 1;
                            // temp = true;
                        }
                        else {
                            temp = arrayItem.Denominator;

                        }
                        total = total + (arrayItem.NumeratorValue / temp);

                    });

                    break;
                case 'AVERAGE':

                    kpiData.CategoryDetails.forEach(function (arrayItem) {
                        if (!arrayItem.Denominator) {
                            temp = 1;

                            //  arrayItem.Denominator = 1;
                            // temp = true;
                        }
                        else {
                            temp = arrayItem.Denominator;

                        }
                        total = total + (arrayItem.NumeratorValue / temp);
                    });

                    total = total / (kpiData.CategoryDetails.length);
                    break;
                case 'WEIGHTED AVERAGE':

                    kpiData.CategoryDetails.forEach(function (arrayItem) {
                        if (!arrayItem.Denominator) {
                            arrayItem.Denominator = 100;
                        }
                        // let individualPercentage = (arrayItem.NumeratorValue / arrayItem.Denominator) * 100;
                        // total = total + individualPercentage; //75+30

                        numeratorSum += parseFloat(arrayItem.NumeratorValue);
                        denominatorSum += parseFloat(arrayItem.Denominator);



                    });

                    total = numeratorSum / denominatorSum * 100;
                    break;
                default:

            }
            if (formulaStr) {
                kpiData.MetricValue = total;
            }

            this.setState({
                data: {
                    kpiData: kpiData
                }
            });

        }

    }


    onKeyPress(event) {
        return (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122);
    }
    handleSave(e) {
        if (this.validateForm()) {

            const data = this.state.data;
            var filter = this.state.filter;
            filter.LocationID = this.props.filterDetails.Locations;
            filter.OrgEntityID = this.props.filterDetails.Client;
            filter.OfferingID = this.props.filterDetails.process;
            filter.MonthDT = this.props.filterDetails.Month;

 


            data.SOCategoryID = this.props.SOCategoryID;
            if(data.kpiData.BaseLineMonth!=null && data.kpiData.BaseLineMonth!="")  {    
                var baselinemonth= new Date(data.kpiData.BaseLineMonth).getFullYear() +
                "-" +
                (new Date(data.kpiData.BaseLineMonth).getMonth() + 1 < 10
                  ? "0" + (new Date(data.kpiData.BaseLineMonth).getMonth() + 1)
                  : new Date(data.kpiData.BaseLineMonth).getMonth() + 1);
                  data.kpiData.BaseLineMonth=baselinemonth;
            }
            this.setState({
                data: data
            });



            var ConfiguratorSavedViewModel = {
                ConfiguratorDataModels: this.state.data,
                FiltersIDsVM: filter,
            };

            //Calling Auto Save Deal metrics service

            axiosInstance.post(`${LocalApiBaseUrl}Configurator/SaveConfiguratorDealMetrics`, ConfiguratorSavedViewModel
            )
                .then((response) => {
                    if (response.data.IsSuccess) {
                        store.dispatch({
                            type: "ADD_BO",
                            payload: response.data.ConfiguratorDataModels
                        });
                        axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetBusinessOutcomesQualityScore`, {
                            params: {
                                LocationID: this.props.filterDetails.Locations,
                                OrgEntityID: this.props.filterDetails.Client,
                                OfferingID: this.props.filterDetails.process,
                                MonthDT: this.props.filterDetails.Month,
                                LanguageID: store.getState().CurrentFilter.languageData.id
                            }
                        }
                        )
                            .then((response) => {
                                if (response.data) {
                                    store.dispatch({
                                        type: "SAVE_QC_SCORE_OF_EACH_TAB",
                                        payload: response.data
                                    });
                                }

                                this.handleHide(e);
                            }).catch(error => {
                                this.handleHide(e);
                                trycatchAlertPopup(error)
                            });
                    }


                }).catch(error => {
                    this.handleHide(e);
                    trycatchAlertPopup(error)
                });






                        // store.dispatch({
                        //     type: "ADD_BO",
                        //     payload: data
            // });



        }
    }

    handleHide(e) {
        const { onClose } = this.props;
        this.setState({ show: false });

        if (e != undefined) {
            if (e.target.textContent != "×") {
                onClose(e);
            } else {
                onClose(e);
            }
        } else {
            onClose(encodeURIComponent);
        }
    }
    handleRejectHide(e) {
        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onRejectClose();
            }
        } else {
            this.props.onRejectClose();
        }
    }
    handleApproveHide(e) {
        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onApproveClose();
            }
        } else {
            this.props.onApproveClose();
        }
    }
    passToConfirmation(data) {
        this.props.enteredData(data.PoNumber);
    }

    validateForm() {
        const { data, commentsError } = this.state;
        const { attributes } = this.props;
        let kpiData = data.kpiData;
        let attributeLabelsForBO = [];
        if (kpiData.isRelatorBlock) {
            attributeLabelsForBO = attributes.filter(z => z.ApplicableForDD === false);
        }

    let isLeading = kpiData.metDataAttr && kpiData.metDataAttr.length > 0 ? kpiData.metDataAttr[0].AttributeValue : "";
    let isSimilar = kpiData.metDataAttr && kpiData.metDataAttr.length > 0 ? kpiData.metDataAttr[1].AttributeValue : "";
    let errors = {};
    let formIsValid = true;
    let err="";
    if (kpiData.Comments) 
   {  
    if(!isValidText(kpiData.Comments)){
      err = errordescription;
      formIsValid = false;
    }
    else if(kpiData.Comments.length > 300) {
      err = i18n.t("commentsErrorM1");
      formIsValid = false;
    }
    this.setState({
      commentsError: err
    });
  }
  if (kpiData.SourceFieldName) 
  {
    if(!isValidText(kpiData.SourceFieldName)){
      formIsValid = false;
      errors["SourceFieldName"] = errordescription;
    }
  }

    if (kpiData["MetricSeq"]) kpiData["MetricSeq"] = parseInt(kpiData["MetricSeq"], 10);
    if (!kpiData["MetricSeq"]) {
      formIsValid = false;
      errors["MetricSeq"] = i18n.t("addWoErrorM5");
    } else if (!(kpiData["MetricSeq"] >= 0 && kpiData["MetricSeq"] <= 999999)) {
      formIsValid = false;
      errors["MetricSeqRange"] = i18n.t("addWoErrorM6");
    }
    if (!kpiData["MetricName"]) {
      formIsValid = false;
      errors["MetricName"] = i18n.t("addWoErrorM1");
    }
    if (commentsError) {
      formIsValid = false;
    }


        if (this.isSelectedMetricMappedToConnector && !this.isConnectorMetricDataFound) {
            formIsValid = false;
            errors["MetricConnectorDataNotFound"] = i18n.t("Data_not_available_in_source_system") + ".";
            errors["IntegratedMetricsMessage"] = i18n.t("Integrated_metrics_will_get_auto_populated") + ".";
            if (this.selectedMetricConnectorLastUpdated) {
                errors["MetricConnectorLastPull"] = i18n.t("Last_pull_occurred_on") + " " + FormatDate(this.selectedMetricConnectorLastUpdated) + ".";;
            } else {
                errors["MetricConnectorLastPull"] = i18n.t("Last_pull_occurred") + ' "' + i18n.t("Date_Unavailable") + '”.';
            }
        }

        if (!this.isSelectedMetricMappedToConnector) {
            if (!kpiData["MetricValue"] && kpiData["MetricValue"]!==0 ) {
                formIsValid = false;
                errors["MetricValue"] = i18n.t("addBoErrorM1");
            } else if (!(kpiData["MetricValue"] >= -999999999999 && kpiData["MetricValue"] <= 999999999999)
            ) {
                formIsValid = false;
                errors["MetricValueRange"] = i18n.t("addBoErrorM6");
            }
        }

        if (!(kpiData["BaselineValue"] >= -99999999 && kpiData["BaselineValue"] <= 99999999)) {
            formIsValid = false;
            errors["BaselineValue"] = i18n.t("Baseline_validation");
          }
      
          if (!(kpiData["TargetValue"] >= -99999999 && kpiData["TargetValue"] <= 99999999)) {
            formIsValid = false;
            errors["TargetValue"] = i18n.t("target_validation");
          }
      
        if (!kpiData["RAGStatus"]) {
            formIsValid = false;
            errors["RAGStatus"] = i18n.t("addBoErrorM3");
        }
        if (!isLeading && this.state.data.kpiData.isRelatorBlock) {

            formIsValid = false;
            errors["LeadingIndustry"] =
            attributeLabelsForBO.length>0 ? attributeLabelsForBO[0].AttributeName1 + " " + i18n.t("is_Required") : "";
        }


        else {

        }

        if (!isSimilar && this.state.data.kpiData.isRelatorBlock) {

            formIsValid = false;
            errors["SimilarIndustry"] =
            attributeLabelsForBO.length>0 ? attributeLabelsForBO[0].AttributeName2 + " " + i18n.t("is_Required") : "";
        }

        else {

        }

        if (!kpiData["ConnectorID"]) {
            formIsValid = false;
            errors["ConnectorID"] = i18n.t("Source_is_required");
        }
        if (kpiData["MetricDataDesp"]) {
            var isValid = regexForFields.test(kpiData["MetricDataDesp"]);
            if (!isValid) {
                formIsValid = false;
                errors["ValueDescription"] = i18n.t("addBoErrorM4");

            }
        }

        //start validation for benchmark fields

        if (this.state.data.kpiData.isRelatorBlock) {
            // validation for attribute1value and attribute2value
            // if (this.props.isRelatorBlockForOffering) {
            if (
                (isLeading && !isLeading.toString().trim()) &&
                (isSimilar && !isSimilar.toString().trim())
                || (!isLeading && !isSimilar)

            ) {
                // attribute values can never be left empty - fill NA
                formIsValid = false;
                errors["LeadingIndustry"] = i18n.t("AttributeValue_ErrMsg1");
                errors["SimilarIndustry"] = i18n.t("AttributeValue_ErrMsg1");
            }
            if (isLeading && isLeading.trim()) {
                if (Number.isNaN(parseFloat(isLeading.trim()))) {
                    // it is a string
                    if (isLeading.toLowerCase().trim() !== "na") {
                        formIsValid = false;
                        // errors["Attribute1Value"] = "NA is the only permitted string value";
                        errors["LeadingIndustry"] = i18n.t("AttributeValue_ErrMsg2");
                    }
                } else {
                    //attribute 2 is a whole number or decimal OR has alphabets and numbers ex. 12efgh3456
                    if (!regexForNumbersOnlyMTC.test(isLeading.trim())) {
                        formIsValid = false;
                        // errors["Attribute1Value"] = "NA is the only permitted string value";
                        errors["LeadingIndustry"] = i18n.t("AttributeValue_ErrMsg2");
                    }

                    if (!(
                        parseFloat(isLeading.trim()) >= 0 &&
                        parseFloat(isLeading.trim()) <= 999999)) {
                        formIsValid = false;

                        // errors["Attribute1Value"] =
                        //               `${this.state.attributeLabels.AttributeName1}` +
                        //           " value must be in between 0 to 999999";
                        errors["LeadingIndustry"] = `${attributeLabelsForBO.length > 0 ? attributeLabelsForBO[0].AttributeName1 : ""}` + " " + i18n.t("addBoErrorM2");
                    }
                }

            }
            if (isSimilar && isSimilar.trim()) {
                if (Number.isNaN(parseFloat(isSimilar.trim()))) {
                    // it is a string
                    if (isSimilar.toLowerCase().trim() !== "na") {
                        formIsValid = false;
                        // errors["Attribute1Value"] = "NA is the only permitted string value";
                        errors["SimilarIndustry"] = i18n.t("AttributeValue_ErrMsg2");
                    }
                } else {
                    //attribute 2 is a whole number or decimal OR has alphabets and numbers ex. 12efgh3456
                    if (!regexForNumbersOnlyMTC.test(isSimilar.trim())) {
                        formIsValid = false;
                        // errors["Attribute1Value"] = "NA is the only permitted string value";
                        errors["SimilarIndustry"] = i18n.t("AttributeValue_ErrMsg2");
                    }

                    if (!(
                        parseFloat(isSimilar.trim()) >= 0 &&
                        parseFloat(isSimilar.trim()) <= 999999)) {
                        formIsValid = false;

                        // errors["Attribute1Value"] =
                        //               `${this.state.attributeLabels.AttributeName1}` +
                        //           " value must be in between 0 to 999999";
                        errors["SimilarIndustry"] = `${attributeLabelsForBO.length > 0 ? attributeLabelsForBO[0].AttributeName2 : ""}` + " " + i18n.t("addBoErrorM2");
                    }
                }
            }
        }
        //end validation for benchmark fields



        // VALIDATE "METRIC DEFINITION" DROPDOWN FIELD
        if (
            kpiData.MetricDefination && kpiData.MetricDefination.length > 0 &&
            !(kpiData["MetricDefinitionID"])) {
            errors["MetricDefinitionID"] = i18n.t("addBoErrorM5");
            formIsValid = false;
        }

        //Validate multi level categories
        if (kpiData["IsMultiLevel"]) {
            if (kpiData.CategoryDetails.length === 0) {
                errors["MultiLevel"] = i18n.t("noCategories");
                formIsValid = false;

            }


        }

        //end validate multi level categories


        this.setState({
            errors: errors
        });
        if (formIsValid) {
            this.setState({
                formIsValid: true
            });
        } else {
            this.setState({
                formIsValid: false
            });
        }
        return formIsValid;
    }

    // WOULD BE EXECUTED WHEN THE "METRIC DEFINITION" DROPDOWN WOULD BE CHANGED
    handleChangeMetricDefinition = e => {
        const MetricDefinitionID = e.target.value;
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                kpiData: {
                    ...prevState.data.kpiData,
                    MetricDefinitionID,
                },
            }
        }));
    }

    handleClick() {

        const kpiData = this.state.data.kpiData;
        kpiData.IsMultiLevel = !kpiData.IsMultiLevel;
       

        if (!kpiData.IsMultiLevel)
        {
            kpiData.IsAggregation = false;
            
        }
        this.setState({
            data: {
                kpiData: kpiData
            }
        });

    }

    handleCategoryClose() {
        this.setState({
            showCategory: false,
            
            errors: {}
        });
    }

    handleCategoryShow() {
        this.setState({ errors: {} })
        let CategoryDetails = this.state.CategoryDetails
        CategoryDetails.CategoryType = ""
        CategoryDetails.CategoryName = ""
        this.setState({
            showCategory: true,
            iscatypedisable: false,
            CategoryDetails: CategoryDetails,
            isEdit: false,
            CategoryNameData:[],
            CatNameID: 0,
            CatTypeID: 0,
            numeratorVal: '',
            denomVal: ''
        });
    }


    handleCategoryAdd() {


        let CategoryDetails = [];
        const kpiData = this.state.data.kpiData

        let error = {};



        if (!this.state.numeratorVal) {

            error["NumeratorValue"] = i18n.t("numeratorIsReqd");

            // this.setState({ errors: error });
        }
        // else if (!(kpiData["MetricValue"] >= -99999999 && kpiData["MetricValue"] <= 99999999)
        else if (!(parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999)) {
            error["NumeratorValueRange"] = i18n.t("addBoErrorM6");

            // this.setState({ errors: error });
        }
        if (kpiData.MetricUnit.trim() === '%') {

            if (!this.state.denomVal) {


                error["Denominator"] = i18n.t("denominatorIsReqd");

                // this.setState({ errors: error });
            }
            else if (parseFloat(this.state.denomVal) === 0) {
                error["Denominator"] = i18n.t("denomCannotZero");
                // this.setState({ errors: error });
            }

            else if (!(parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) {
                error["DenominatorValueRange"] = i18n.t("addBoErrorM6");

            }
        }
        else {
            if (!Number.isNaN(parseFloat(this.state.denomVal))) {
                if (parseFloat(this.state.denomVal) === 0) {

                    error["Denominator"] = i18n.t("denomCannotzero");
                    // this.setState({ errors: error });


                }
                else if (!(parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) {
                    error["DenominatorValueRange"] = i18n.t("addBoErrorM6");
                    //this.setState({ errors: error });

                }
            }



        }
        if (this.state.CatTypeID == "0") {
            error["CategoryType"] = i18n.t("catTypeReqd");
            // this.setState({ errors: error });
        }

        if (this.state.CatNameID == "0") {
            error["CategoryName"] = i18n.t("catNameReqd");
            //this.setState({ errors: error });
        }
        this.setState({ errors: error });
        if (kpiData.MetricUnit.trim() === '%' ? (this.state.CategoryDetails.CategoryType && this.state.CatTypeID != "0" && this.state.CategoryDetails.CategoryName && this.state.CatNameID != "0" && this.state.numeratorVal && (parseFloat(this.state.numeratorVal) >= -9999999999999999 && parseFloat(this.state.numeratorVal) <= 9999999999999999) && this.state.denomVal && parseFloat(this.state.denomVal) != 0 && (parseFloat(this.state.denomVal) >= -9999999999999999 && parseFloat(this.state.denomVal) <= 9999999999999999)) :
            this.state.CategoryDetails.CategoryType && this.state.CatTypeID != "0" && this.state.CategoryDetails.CategoryName && this.state.CatNameID != "0" && this.state.numeratorVal && (parseFloat(this.state.numeratorVal) >= -9999999999999999 && parseFloat(this.state.numeratorVal) <= 9999999999999999) && (!Number.isNaN(parseFloat(this.state.denomVal)) ? (parseFloat(this.state.denomVal) != 0 && parseFloat(this.state.denomVal) >= -9999999999999999 && parseFloat(this.state.denomVal) <= 9999999999999999) : true)) {
            if (!kpiData.CategoryDetails)
                kpiData.CategoryDetails = [];

            if (kpiData.CategoryDetails.filter(cat => cat.CategoryName === this.state.CategoryDetails.CategoryName).length === 0) {

                let CategoryName = this.state.CategoryDetails.CategoryName;


                let Catid = CategoryName ? this.state.CategoryNameData.filter(cat => cat.CategoryName === CategoryName)[0].ID : null;


                if (!kpiData.CategoryDetails)
                    kpiData.CategoryDetails = [];
                    if (kpiData.CategoryDetails && kpiData.CategoryDetails.length < this.state.categoriesLimit) {
                kpiData.CategoryDetails.push({
                    'CategoryType': this.state.CategoryDetails.CategoryType,
                    'CategoryName': this.state.CategoryDetails.CategoryName,
                    'NumeratorValue': this.state.numeratorVal,
                    'Denominator': this.state.denomVal,
                    'ConfigurationDealMetricID': 8,
                    'OfferingMultiLevelCategoryID': Catid
                });


                // kpiData.IsMultiLevel= !kpiData.IsMultiLevel;
                this.setState({
                    showCategory: false,
                    data: {
                        kpiData: kpiData
                    }
                })
                }
                else {
                    let error = {};
                    error["CategoryName"] = `${i18n.t("A_maximum_of")} ${this.state.categoriesLimit} ${i18n.t("category_values_are")}` 

                    this.setState({ showCategory: true, errors: error });


                }


                if (kpiData.IsAggregation && !this.state.valueConnectorEdit && !this.isSelectedMetricMappedToConnector) {
                    //calculate the metric value on the fly when new values added/ edited/deleted
                    this.calculateAggregateValueByFormula();
                }

            }
            else {
                let error = {};
                error["CategoryName"] = i18n.t("duplicateCatName");

                this.setState({ errors: error });
            }
        }
        else {
            this.setState({
                showCategory: true
            })
        }
    }
    handleCategoryEditShow(catNameToEdit) {
        this.setState({ errors: {} })
        var allCategoriesList = this.state.data.kpiData.CategoryDetails;
        var index = allCategoriesList.findIndex(item => item.CategoryName === catNameToEdit)
       
        let catnameid = this.state.CategoryNameData.filter(row => row.CategoryName === catNameToEdit)[0].ID
        let CategoryName = this.state.CategoryNameData.filter(row => row.ID === catnameid)[0].CategoryName
        let CategoryDetails = this.state.CategoryDetails
        CategoryDetails.CategoryName = CategoryName

        /*     let numeratorVal=allCategoriesList.filter(item => item.CategoryName ===catNameToEdit)[0].NumeratorValue
            let denomVal=allCategoriesList.filter(item => item.CategoryName ===catNameToEdit)[0].Denominator */
        let numeratorVal = allCategoriesList[index].NumeratorValue
        let denomVal = allCategoriesList[index].Denominator

        this.setState({
            EditIndex: index,
            isEdit: true,
            showCategory: true,
            CatNameID: catnameid,
            CategoryDetails: CategoryDetails,
            CatTypeID: this.state.CatTypeID,
            numeratorVal: numeratorVal,
            denomVal: denomVal
        });
    }

    handleCategoryEdit() {
        let error = {};
        let CategoryDetails = [];
        const kpiData = this.state.data.kpiData
        let isCatnameError=false;

        if (!this.state.numeratorVal) {

            error["NumeratorValue"] = i18n.t("numeratorIsReqd");

            // this.setState({ errors: error });
        }
        // else if (!(kpiData["MetricValue"] >= -99999999 && kpiData["MetricValue"] <= 99999999)
        else if (!(parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999)) {
            error["NumeratorValueRange"] = i18n.t("addBoErrorM6");
            // this.setState({ errors: error });

        }
        if (kpiData.MetricUnit.trim() === '%') {

            if (!this.state.denomVal) {



                error["Denominator"] = i18n.t("denominatorIsReqd");

                //this.setState({ errors: error });
            }
            else if (parseFloat(this.state.denomVal) === 0) {
                error["Denominator"] = i18n.t("denomCannotZero");
                // this.setState({ errors: error });
            }
            else if (!(parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) {
                error["DenominatorValueRange"] = i18n.t("addBoErrorM6");

                //this.setState({ errors: error });
            }
        }
        else {
            if (!Number.isNaN(parseFloat(this.state.denomVal))) {
                if (parseFloat(this.state.denomVal) === 0) {

                    error["Denominator"] = i18n.t("denomCannotzero");
                    //this.setState({ errors: error });

                }
                else if (!(parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) {
                    error["DenominatorValueRange"] = i18n.t("addBoErrorM6");
                    //this.setState({ errors: error });

                }
            }

        }

        if (!this.state.CategoryDetails.CategoryType) {
            error["CategoryType"] = i18n.t("catTypeReqd");
            // this.setState({ errors: error });
        }

        if (this.state.CatNameID === "0") {
            error["CategoryName"] = i18n.t("catNameReqd");
            //this.setState({ errors: error });
        }

        this.setState({ errors: error });
        if (kpiData.MetricUnit === '%' ? (this.state.CategoryDetails.CategoryType && this.state.CatTypeID != "0" && this.state.CategoryDetails.CategoryName && this.state.CatNameID != "0" && this.state.numeratorVal && (parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999) && this.state.denomVal && parseFloat(this.state.denomVal) != 0 && (parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) :
            this.state.CategoryDetails.CategoryType && this.state.CatTypeID != "0" && this.state.CategoryDetails.CategoryName && this.state.CatNameID != "0" && this.state.numeratorVal && (parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999) && (!Number.isNaN(parseFloat(this.state.denomVal)) ? (parseFloat(this.state.denomVal) != 0 && parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999) : true)) {

            let index = this.state.EditIndex
            if (kpiData.CategoryDetails.filter(cat => cat.CategoryName === this.state.CategoryDetails.CategoryName).length === 0) {

                
                kpiData.CategoryDetails[index].CategoryName = this.state.CategoryDetails.CategoryName
                kpiData.CategoryDetails[index].NumeratorValue = this.state.numeratorVal
                kpiData.CategoryDetails[index].Denominator = this.state.denomVal
                kpiData.CategoryDetails[index].ConfigurationDealMetricID = 8
                let Catid = kpiData.CategoryDetails[index].CategoryName && kpiData.CategoryDetails[index].CategoryName !== "" ? this.state.CategoryNameData.filter(cat => cat.CategoryName === kpiData.CategoryDetails[index].CategoryName)[0].ID : null;
                // kpiData.CategoryDetails[index].OfferingMultiLevelCategoryID = this.state.CategoryDetails.OfferingMultiLevelCategoryID
                //diverseData.CategoryDetails[index].OfferingMultiLevelCategoryID = diverseData.CategoryDetails[index].OfferingMultiLevelCategoryID
                kpiData.CategoryDetails[index].OfferingMultiLevelCategoryID = Catid
                this.setState({
                    data: {
                        kpiData: kpiData
                    }
                });
                this.setState({
                    showCategory: false
                });
            }
            else if (kpiData.CategoryDetails.filter(cat => cat.CategoryName === kpiData.CategoryDetails[index].CategoryName).length === 1) {

                if (kpiData.CategoryDetails[index].CategoryName.trim() === this.state.CategoryDetails.CategoryName.trim() ? false : kpiData.CategoryDetails.filter(cat => cat.CategoryName === this.state.CategoryDetails.CategoryName).length === 1) {
                    let error = {};
                    error["CategoryName"] = i18n.t("duplicateCatName");
                    isCatnameError=true;
                    this.setState({ errors: error });
                }
                else {

                    kpiData.CategoryDetails[index].CategoryName = kpiData.CategoryDetails[index].CategoryName
                    kpiData.CategoryDetails[index].NumeratorValue = this.state.numeratorVal
                    kpiData.CategoryDetails[index].Denominator = this.state.denomVal
                    kpiData.CategoryDetails[index].ConfigurationDealMetricID = 8
                    kpiData.CategoryDetails[index].OfferingMultiLevelCategoryID = kpiData.CategoryDetails[index].OfferingMultiLevelCategoryID

                    this.setState({
                        data: {
                            kpiData: kpiData
                        }
                    });
                    this.setState({
                        showCategory: false
                    });
                }

            }
            else {

                this.setState({
                    showCategory: false
                });
            }
            if ( !isCatnameError && kpiData.IsAggregation && !this.state.valueConnectorEdit && !this.state.valueConnector && !this.isSelectedMetricMappedToConnector) {
                //calculate the metric value on the fly when new values added/ edited/deleted
                this.calculateAggregateValueByFormula();
            }

        }


    }
    handleCategoryTypeAddShow() {

        this.setState({ errors: {} })

        let CatTypeID = this.state.CatTypeID
        let CategoryDetails = this.state.CategoryDetails
        CategoryDetails.CategoryName = ""
        this.setState({
            CategoryDetails: CategoryDetails,
            showCategory: true,
            isEdit: false,
            iscatypedisable: true,
            CatNameID: 0,
            CatTypeID: CatTypeID,
            numeratorVal: '',
            denomVal: ''
        });

    }


    handleaddCategoryNameClose() {
        this.setState({
            showaddCategory: false,
            errors: {}
        });
    }
    handleaddCategoryClose() {
        this.setState({
            showaddCategoryType: false,
            errors: {}
        });
    }

    handleaddCategoryShow() {
        this.setState({ showaddCategory: true });
    }
    /*  handleDropdownCategoryTypeChange(e) {
        this.setState({ selectDropValue: e.target.value });
       setTimeout(() => {
         if(this.state.selectDropValue == 'addnew'){
            this.setState({ showCategory : false,
                           showaddCategory: true });
         }
         else {
           this.setState({ showaddCategory: false,
                           showCategory: true});
         }
       }, 500);
     } */
    removeDuplicates(arr) {/*NEW CODE WHICH REMOVES DUPLICATES DATA FROM API */
        const map = new Map();
        arr.forEach(v => map.set(v.CategoryType, v))
        return [...map.values()];
    }

    CatTypeSelect(e) {
        /* let errors={}
        errors.CategoryType=""
        this.setState({
            errors:errors
        }) */

        let CatTypeID = e.target.value
        this.setState({ CatTypeID: CatTypeID })

        setTimeout(() => {
            if (CatTypeID == 'addnew') {
                CategoryDetails.CategoryType = ""
                CategoryDetails.CategoryName = ""
                this.setState({
                    errors: {},
                    showCategory: false,
                    showaddCategoryType: true, CategoryDetails: CategoryDetails
                });
            }
            else if (CatTypeID == "0") {//CHANGE2
                CategoryDetails.CategoryType = ""
                CategoryDetails.CategoryName = ""
                this.setState({
                    CatNameID: 0,
                    showaddCategoryType: false,
                    showaddCategory: false,
                    showCategory: true,
                    CategoryDetails: CategoryDetails
                });
            }
            else if (CatTypeID == 'addnewcatname') {
                this.setState({
                    showCategory: false,
                    showaddCategory: true
                });
            }
            else {
                this.setState({
                    showaddCategoryType: false,
                    showaddCategory: false,
                    showCategory: true
                });
            }
        }, 500);


        let CatType = (CatTypeID && CatTypeID != "0" && CatTypeID != "addnew") ? this.state.allCategoryTypesAndNames.filter(
            eachValue => eachValue.ID == CatTypeID
        )[0].CategoryType : [];//FETCHING individual CATEGORY TYPE

        let CategoryDetails = this.state.CategoryDetails
        CategoryDetails.CategoryType = CatType
        this.setState({
            CategoryDetails: CategoryDetails
        });


        let CategoryNameData = (CatType && CatType != "") ? this.state.allCategoryTypesAndNames.filter(
            eachValue => eachValue.CategoryType == CatType
        ) : [];//FETCHING CATEGORY NAME

        this.setState({ CategoryNameData: CategoryNameData })


    }
    handleDropdownChange(e) {

        let CatNameID = e.target.value
        this.setState({ errors: {}, CatNameID: CatNameID })

        setTimeout(() => {
            if (CatNameID == 'addnew') {
                this.setState({
                    showCategory: false,
                    showaddCategoryType: true
                });
            }
            else if (CatNameID == 'addnewcatname') {
                if (!this.state.CategoryDetails.CategoryType) {
                    //category type is empty

                    let error = {};
                    error["CategoryType"] = i18n.t("catTypeReqd");

                    this.setState({ errors: error, CatNameID: 0 });
                }


                else {

                    CategoryDetails.CategoryName = ""
                    this.setState({
                        showCategory: false,
                        showaddCategory: true, CategoryDetails: CategoryDetails
                    });
                }
            }
            else {
                this.setState({
                    showaddCategoryType: false,
                    showaddCategory: false,
                    showCategory: true
                });
            }
        }, 500);
        let CatName = (CatNameID && CatNameID != "0" && CatNameID != "addnewcatname") ? this.state.allCategoryTypesAndNames.filter(
            eachValue => eachValue.ID == CatNameID
        )[0].CategoryName : [];


        let CategoryDetails = this.state.CategoryDetails
        CategoryDetails.CategoryName = CatName
        this.setState({
            CategoryDetails: CategoryDetails
        });
    }


    //called when new category name is added -- on clicking on the add button

  handleaddCategoryAdd() {
    const { CategoryDetails } = this.state;
    const { filterDetails } = this.props;
    const data = CategoryDetails;
    let errordescription=InvalidDescription();
    const param = {
      OfferingId: parseInt(filterDetails.process),
      CatType: data.CategoryType.toString().trim(),
      CatName: data.CategoryName.toString().trim(),
      LocationID: filterDetails.Locations,
      SOCategoryID: 1,
      OrgEntityID: filterDetails.Client,
    };
    let error = {};
    if (!data.CategoryType.trim()) {
      error["CategoryType"] = i18n.t("catTypeReqd");
    }
    else if(!isValidText(data.CategoryType.trim())){
      error["CategoryType"] = errordescription;
    }

    if (!data.CategoryName.trim()) {
      error["CategoryName"] = i18n.t("catNameReqd");
    }
    else if(!isValidText(data.CategoryName.trim())){
      error["CategoryName"] = errordescription;
    }

        this.setState({ errors: error });




        if (data.CategoryType.trim() && data.CategoryName.trim()) {
            axiosInstance.post(`${LocalApiBaseUrl}MultiLevelApproval/SendCategoryTypeNameForApproval`, param,
                {
                }
            ).then((response) => {
                //window.alert(response.data.StatusMessage);
                if (response.data.IsSuccess && response.data.StatusMessage == "Sent For Approval") {
                    // this.onClose();
                    this.setState({
                        showaddCategory: false,
                        showaddCategoryType: false,
                        showApproval: true
                    });
                }
                else {
                    window.alert(response.data.StatusMessage);
                    this.setState({ showLoaderOnPage: false });
                }
            }).catch(error => {
                //window.alert(this.ssErrMsg + error);
                trycatchAlertPopup(error)
            });
        }

    }

    handleApprovalClose() {
        this.setState({ showApproval: false });
    }

    getFormattedDate = (date) => {
        if (!date) return "";
        let newDate = new Date(date);
        return [newDate.getMonth() + 1,
        newDate.getDate(),
        newDate.getFullYear()].join('/') + ' ' +
            [newDate.getHours(),
            newDate.getMinutes(),
            newDate.getSeconds()].join(':');
    }
    render() {
        const { data, commentsError } = this.state;

        const currentDate = new Date();

    //list of all category types and names for this offering - to be displayed in add/edit popup
    const allCatList = this.state.allCategoryTypesAndNames;
    const stateKpiData = this.state.data.kpiData;
    let items = [];
    if (stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0) {
      items = stateKpiData.CategoryDetails.map((item, key) => (
        <li>
          <span className="contentName">{item.CategoryName}</span>
          {stateKpiData.MetricUnit.trim() !== "%" && (
            <span className="contentValues">
              <span>
                {thousandSeparatorFormat(item.NumeratorValue) % 1 != 0
                  ? MetricValueThousandFormat(stateKpiData.MetricUnit,item.NumeratorValue)
                  : MetricValueThousandFormat(stateKpiData.MetricUnit,item.NumeratorValue)}
              </span>
              {item.Denominator && <span> | </span>}
              <span>
                {item.Denominator
                  ? thousandSeparatorFormat(item.Denominator) % 1 != 0
                    ? MetricValueThousandFormat(stateKpiData.MetricUnit,item.Denominator)
                    : MetricValueThousandFormat(stateKpiData.MetricUnit,item.Denominator)
                  : ""}
              </span>{" "}
              {/* {stateKpiData.MetricUnit} */}
            </span>
          )}

          {stateKpiData.MetricUnit.trim() === "%" && (
            <span className="contentValues">
              <span>
              {thousandSeparatorFormat((item.NumeratorValue * 100) / item.Denominator) % 1 != 0
                  ? thousandSeparatorFormat(((item.NumeratorValue * 100) / item.Denominator))
                  : thousandSeparatorFormat(((item.NumeratorValue * 100) / item.Denominator))}
              </span>{" "}
              {stateKpiData.MetricUnit}
            </span>
          )}

                    <div className="item-list-hover"><span><i
                        onClick={() => this.deleteCategoryName(item.CategoryName)}
                        class="far fa-times"> </i>
                        <i onClick={() => this.handleCategoryEditShow(item.CategoryName)}
                            class="fas fa-edit"></i>
                    </span>
                    </div>
                </li>
             ));
        }
        let isScreenInEditMode = false;
        if (!_.isEmpty(this.props.kpiData)) {
            isScreenInEditMode = true;
        }

        // FILTER OUT THE METRIC DEFINITIONS THAT ARE "ENABLED"
        const kpiMetricDefinitions = _.get(this.state, 'data.kpiData.MetricDefination', []);

        const enabledMetricDefinitions = _.filter(kpiMetricDefinitions, eachMetricDefinition => String(eachMetricDefinition.IsEnabled) === 'true');
        const attributeLabelsForBO = this.props.attributes.filter(z => z.ApplicableForDD === false);


        return (
            <>
                {/* <Header /> */}
                <Modal
                    show={this.state.show}
                    onHide={this.handleHide}
                    className="modal fade bo-configurator-modal enhance"
                    size="medium"
                    id="dealpopup"
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <h4 className="modal-title" id="dealprofile">
                            {i18n.t("addBOMess1")}
                        </h4>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="form-section">
                                <div className="row">
                                    <div className="form-group col-lg-7">
                                        <div className="row">
                                            <div className="form-group col-lg-6">
                                                <label>
                                                    {i18n.t("Name")}  <span className="required"> *</span>
                                                </label>
                                                {/* <input type="text" maxlength="100" className="form-control" id="name" onChange={this.onNameChange} value={this.state.kpiData.MetricName} /><span className="required-field"></span> */}
                                                <select
                                                    id="name"
                                                    disabled={isScreenInEditMode}
                                                    className="form-control"
                                                    ref="MetricNameDropdown"
                                                    onChange={this.onNameChange}
                                                    value={this.state.data.kpiData.KPIMappingID}
                                                >
                                                    <option value="">{i18n.t("Select_Metric_Name")}</option>
                                                    {this.state.MetricNameOptions.map(row => {
                                                        return (
                                                            <option value={row.KpiMappingID}>
                                                                {row.MetricName}
                                                            </option>
                                                        );
                                                    })}
                                                    <option value="-1">&lt;{i18n.t("Add_New")}&gt;</option>
                                                </select>
                                                <span className="errorMsg">
                                                    {this.state.errors.MetricName}
                                                </span>
                                            </div>

                                            <div className="form-group col-lg-6">
                                                <label>{i18n.t("Unit_of_Measure")}<span className="required"> *</span> </label>
                                                <input
                                                    type="text"
                                                    disabled
                                                    maxLength="50"
                                                    className="form-control"
                                                    value={this.state.data.kpiData.MetricUnit}
                                                />
                                                <div className="errorMsg">
                                                    {this.state.errors.MetricUnit}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-lg-6">
                                                <label>
                                                    {i18n.t("Sequence")} <span className="required"> *</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    onChange={this.onSequenceChange}
                                                    value={this.state.data.kpiData.MetricSeq}
                                                />
                                                <div className="errorMsg">
                                                    {this.state.errors.MetricSeq}
                                                </div>
                                                <div className="errorMsg">
                                                    {this.state.errors.MetricSeqRange}
                                                </div>
                                            </div>

                                            <div className="form-group col-lg-6">
                                                <label>
                                                    {i18n.t("Leading_Practice")} {" "}
                                                    <OverlayTrigger
                                                        key="top"
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-top">
                                                                {i18n.t("addBOMess2")}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faInfoCircle} />
                                                    </OverlayTrigger>
                                                </label>
                                                <input
                                                    type="text"
                                                    disabled
                                                    maxLength="50"
                                                    className="form-control"
                                                    value={this.state.data.kpiData.LeadingPractice}
                                                />
                                                <div className="errorMsg">
                                                    {this.state.errors.LeadingPractice}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-lg-12">
                                                <label> {i18n.t("Description")} </label>
                                                <input
                                                    type="text"
                                                    disabled
                                                    maxLength="255"
                                                    className="form-control"
                                                    value={this.state.data.kpiData.KPIDesp}
                                                />
                                                <div className="errorMsg">{this.state.errors.KPIDesp}</div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-lg-12">
                                                <label>
                                                    {i18n.t("Metric_Definition")} <span className="required"> *</span>
                                                    {" "}
                                                    <OverlayTrigger
                                                        key="top"
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-top">
                                                                {i18n.t("addBOMess3")}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faInfoCircle} />
                                                    </OverlayTrigger>
                                                </label>
                                                {enabledMetricDefinitions.length > 0 ? <select
                                                    value={this.state.data.kpiData.MetricDefinitionID}
                                                    onChange={(e) => this.handleChangeMetricDefinition(e)}
                                                    className="form-control"
                                                >
                                                    <option value="">{i18n.t("Select_Metric_Definition")} </option>
                                                    {_.map(enabledMetricDefinitions, eachMetricDefinition => {
                                                        return (
                                                            <option
                                                                value={eachMetricDefinition.KPIMetricDefinatoinID}
                                                                key={eachMetricDefinition.KPIMetricDefinatoinID}
                                                            >
                                                                {_.isEmpty(eachMetricDefinition.DenominatorDescription)
                                                                    ? eachMetricDefinition.NumeratorDescription
                                                                    : `${eachMetricDefinition.NumeratorDescription} / ${eachMetricDefinition.DenominatorDescription}`
                                                                }
                                                            </option>
                                                        );
                                                    })}
                                                </select> : <input type="text"
                                                    className="form-control"
                                                    value={i18n.t("Definition_Not_Available")}

                                                    disabled></input>}
                                                <div className="errorMsg">
                                                    {this.state.errors.MetricDefinitionID}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row border-box">
                                            <div className="form-group col-lg-6">
                                                <label>
                                                    {i18n.t("Value")}  <span className="required"> *</span>
                                                </label>
                                                {/* <input type="number" xpattern="\d*" maxlength="6" className='form-control'  onChange={this.onValueChange} value={this.state.data.kpiData.MetricValue} /> */}
                                                {this.state.valueConnector ? (
                                                    <input
                                                        type="text"
                                                       
                                                        maxLength="12"
                                                        className="form-control"
                                                        onChange={this.onValueChange}
                                                        value={this.state.data.kpiData.MetricValue}
                                                        readOnly
                                                    />
                                                ) : this.state.KpiMetricConnectorID ? (
                                                    <input
                                                        type="text"
                                                       
                                                        maxLength="12"
                                                        className="form-control"
                                                        onChange={this.onValueChange}
                                                        value={this.props.kpiData.MetricValue}
                                                        readOnly
                                                    />
                                                ) : (
                                                            <input
                                                                readOnly={stateKpiData.IsAggregation}
                                                                type="text"
                                                                
                                                                maxLength="12"
                                                                className="form-control"
                                                                onChange={this.onValueChange}
                                                                value={this.state.data.kpiData.MetricValue}
                                                                disabled={this.state.KpiMetricConnectorID}

                                                            />
                                                        )}
                                                <div className="errorMsg">
                                                    {this.state.errors.MetricValue}
                                                </div>
                                                <div className="errorMsg">
                                                    {this.state.errors.MetricConnectorDataNotFound}
                                                    {this.state.errors.MetricConnectorLastPull ? <div> {this.state.errors.MetricConnectorLastPull} </div> : ""}
                                                    {this.state.errors.MetricConnectorDataNotFound ? <div>{this.state.errors.IntegratedMetricsMessage}</div> : ""}
                                                </div>
                                                <div className="errorMsg">
                                                    {this.state.errors.MetricValueRange}
                                                </div>
                                            </div>

                                            <div className="form-group col-lg-6">
                                                <label>
                                                    {i18n.t("Status")}   <span className="required"> *</span>
                                                </label>
                                                {/* <div className="errorMsg">{this.state.errors.RAGStatus}</div> */}

                                                <select
                                                    className="form-control"
                                                    value={this.state.data.kpiData.RAGStatus}
                                                    onChange={this.onStatusChange}
                                                >
                                                    <option value="">--{i18n.t("Select")}--</option>
                                                    <option value="Grey">{i18n.t("Grey")} </option>
                                                    <option value="Green">{i18n.t("Green")} </option>
                                                    <option value="Red">{i18n.t("Red")} </option>
                                                    {/* <option value="Orange">Orange</option> */}
                                                    <option value="Amber">{i18n.t("Amber")} </option>
                                                </select>
                                                <div className="errorMsg">
                                                    {this.state.errors.RAGStatus}
                                                </div>
                                            </div>
                                          
                                            <div className="form-group col-lg-6">
                                                <label>{i18n.t("Upper_Threshold")} </label>
                                                <input
                                                    type="number"
                                                    maxLength="12"
                                                    onInput={this.maxLengthCheckThreshold}
                                                    className="form-control"
                                                    onChange={this.onUpperChange}
                                                    value={this.state.data.kpiData.UpperThreshold}
                                                />
                                            </div>

                                            <div className="form-group col-lg-6">
                                                <label>{i18n.t("Lower_Threshold")}</label>
                                                <input
                                                    type="number"
                                                    maxLength="12"
                                                    onInput={this.maxLengthCheckThreshold}
                                                    className="form-control"
                                                    onChange={this.onLowerChange}
                                                    value={this.state.data.kpiData.LowerThreshold}
                                                />
                                            </div>

                                            <div className="form-group col-lg-4">
                                                <label>{i18n.t("Baseline_Value")}</label>
                                                <input
                                                type="text"
                                                maxLength="12"
                                                onInput={this.maxLengthCheckThreshold}
                                                className="form-control"
                                                onChange={this.onBaselineValueChange}
                                                value={this.state.data.kpiData.BaselineValue}
                                                />
                                                <div className="errorMsg">
                                                {this.state.errors.BaselineValue}
                                                </div>
                                            </div>                                                                                        
                                            <div className="form-group col-lg-4">
                                                <label for="">{i18n.t("Baseline_Month")}</label>
                                                <div className="datepicker-label-wrapper">
                                                <DatePicker
                                                    className="form-control"
                                                    id="BaseLineMonth"
                                                    maxDate={currentDate}
                                                    dateFormat="MM/yyyy"
                                                    showMonthYearPicker
                                                    selected={
                                                    Date.parse(this.state.data.kpiData.BaseLineMonth)
                                                        ? new Date(Date.parse(this.state.data.kpiData.BaseLineMonth))
                                                        : null
                                                    }
                                                    onSelect={(date) => {
                                                    this.onBaselineMonthChange(date);
                                                    }}
                                                    readOnly={this.state.BaselineMonthReadOnly}
                                                />                                                
                                                </div>
                                            </div>

                                            <div className="form-group col-lg-4">
                                                <label>{i18n.t("Target_Value")}</label>
                                                <input
                                                type="text"
                                                maxLength="12"
                                                onInput={this.maxLengthCheckThreshold}
                                                className="form-control"
                                                onChange={this.onTargetValueChange}
                                                value={this.state.data.kpiData.TargetValue}
                                                />
                                                <div className="errorMsg">
                                                {this.state.errors.TargetValue}
                                                </div>
                                            </div>

                                            <div className="form-group col-lg-12">
                                                <label>{i18n.t("Comments")}</label>
                                                <textarea
                                                    maxlength="300"
                                                    rows="4"
                                                    name="comment"
                                                    className="form-control"
                                                    onChange={this.onValDescChange}
                                                    value={data.kpiData.Comments}
                                                />
                                                <div className="errorMsg">{commentsError}</div>
                                            </div>
                                        </div>

                                        {this.state.data.kpiData.isRelatorBlock ? (
                                            <div className="row border-box">
                                                <div className="form-group col-lg-6">
                                                    <label>
                                                        {attributeLabelsForBO.length>0 ? 
                                                        attributeLabelsForBO[0].AttributeName1 : ""}{" "}
                                                        <span className="required"> *</span>
                                                        {" "}
                                                        {this.state.IsBatDealLevelForOffering && <OverlayTrigger
                                                            key="top"
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip-top">
                                                                    {i18n.t("addBMtooltip")}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon={faInfoCircle} />
                                                        </OverlayTrigger>}

                                                    </label>
                                                    <input
                                                        type="text"
                                                        xpattern="\d*"
                                                        maxLength="6"
                                                        className="form-control"

                                                        onChange={this.onLeadingIndustryChange}
                                                        value={
                                                            this.state.data.kpiData.metDataAttr &&
                                                            this.state.data.kpiData.metDataAttr.length > 0 &&
                                                            this.state.data.kpiData.metDataAttr[0].AttributeValue
                                                        }
                                                        disabled={!this.state.IsBatDealLevelForOffering}
                                                    />
                                                    <div className="errorMsg">
                                                        {this.state.errors.LeadingIndustry}
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label>
                                                        {attributeLabelsForBO.length>0 ? 
                                                        attributeLabelsForBO[0].AttributeName2 : ""}{" "}
                                                        <span className="required"> *</span>

                                                        {" "}
                                                        {this.state.IsBatDealLevelForOffering && <OverlayTrigger
                                                            key="top"
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip-top">
                                                                    {i18n.t("addBMtooltip")}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon={faInfoCircle} />
                                                        </OverlayTrigger>}

                                                    </label>
                                                    <input
                                                        type="text"
                                                        xpattern="\d*"
                                                        maxLength="6"
                                                        className="form-control"

                                                        onChange={this.onSimilarIndustryChange}
                                                        value={
                                                            this.state.data.kpiData.metDataAttr &&
                                                            this.state.data.kpiData.metDataAttr.length > 0 &&
                                                            this.state.data.kpiData.metDataAttr[1].AttributeValue
                                                        }
                                                        disabled={!this.state.IsBatDealLevelForOffering}
                                                    />
                                                    <div className="errorMsg">
                                                        {this.state.errors.SimilarIndustry}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                        <div className="row border-box">
                                            <div className="form-group col-lg-4">
                                                <label className="source-system-padding">
                                                    {i18n.t("Source_System")} <span className="required"> *</span>
                                                </label>
                                                {this.state.con ? (
                                                    <select
                                                        className="form-control"
                                                        readOnly
                                                        onChange={this.onSourceChange}
                                                        value={this.state.data.kpiData.ConnectorID}
                                                    >
                                                        {this.state.connectorOptions}
                                                    </select>
                                                ) : this.state.KpiMetricConnectorID ? (
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        readOnly
                                                        onChange={this.onSourceChange}
                                                        value={this.props.kpiData.ConnectorNames}
                                                    ></input>
                                                ) : (
                                                            <select
                                                                className="form-control"
                                                                onChange={this.onSourceChange}
                                                                value={this.state.data.kpiData.ConnectorID}
                                                                disabled={this.state.KpiMetricConnectorID}
                                                            >
                                                                <option value=""></option>
                                                                {this.state.sourceOptions}
                                                                <option value="-1">{i18n.t("Others")}</option>
                                                                <option value="-2">{i18n.t("Manual")}</option>
                                                            </select>
                                                        )}
                                                <div className="errorMsg">
                                                    {this.state.errors.ConnectorID}
                                                </div>
                                            </div>
                                            <div className="form-group col-lg-4">
                                                <label>
                                                    {i18n.t("Source_System_Fields")} <span className="required"></span>
                                                </label>
                                                {this.state.con ||
                                                    this.state.data.kpiData.ConnectorNames == "Manual" ? (
                                                        <input
                                                            onChange={this.onSourceSystemChange}
                                                            type="text"
                                                            maxLength="50"
                                                            className="form-control"

                                                            readOnly
                                                            value={this.state.data.kpiData.SourceFieldName}
                                                        />
                                                    ) : this.state.valueConnectorEdit ? (
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            readOnly
                                                            onChange={this.onSourceChange}
                                                            value={this.props.kpiData.SourceFieldName}
                                                        ></input>
                                                    ) : (
                                                            <input
                                                                onChange={this.onSourceSystemChange}
                                                                type="text"
                                                                maxLength="50"
                                                                className="form-control"

                                                                value={this.state.data.kpiData.SourceFieldName}
                                                            />
                                                        )}
                                                {/* <div className="errorMsg">{this.state.errors.SourceDesc}</div> */}
                                            </div>
                                            <div className="form-group col-lg-4">
                                                <label>{i18n.t("Other_Source_System")}</label>
                                                {this.state.data.kpiData.ConnectorNames == "Others" ? (
                                                    <input
                                                        onChange={this.onOtherSystemChange}
                                                        type="text"
                                                        maxLength="50"
                                                        className="form-control"

                                                        value={this.state.data.kpiData.SourceName}
                                                    />
                                                ) : (
                                                        <input
                                                            onChange={this.onOtherSystemChange}
                                                            type="text"
                                                            value=""
                                                            readOnly
                                                            maxLength="50"
                                                            className="form-control"

                                                            value={this.state.data.kpiData.SourceName}
                                                        />
                                                    )}
                                                <div className="errorMsg">{this.state.errors.KPIDesp}</div>
                                            </div>
                                            <div className="form-group col-lg-12 mt-2">
                                                <label className="form-check">
                                                   
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="toolowner"
                                                            defaultChecked={
                                                                this.state.data.kpiData.IsAccentureOwned
                                                            }
                                                            onChange={this.onToolOwnerChange}
                                                            checked={
                                                                this.state.data.kpiData.IsAccentureOwned
                                                            }
                                                            
                                                        />
                                                   
                                                            
                                                    <span className="checkmark"></span>
                                                    <span className="form-check-label">{i18n.t("Accenture_Owned")}</span>
                                                </label>
                                                <small className="form-text text-muted">
                                                    <b>{i18n.t("Note")}:</b> {i18n.t("NoteMessage1")}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    {/* start of multi level UI */}
                                    <div className="form-group col-lg-5">
                                        <div className="right-wrapper">
                                            <div className="catergory-metric-sub">
                                                <div className="sub-level"><i className="fas fa-sitemap"></i>{i18n.t("Category_Metric_Sub_Levels")}</div>
                                                <div className="toggle-switch">
                                                    <Form>
                                                        <Form.Check
                                                            onClick={this.handleClick}
                                                            disabled={!stateKpiData.MetricName}
                                                            checked={stateKpiData.IsMultiLevel}
                                                            type="switch"
                                                            id="custom-switch"
                                                            label=""
                                                        />
                                                    </Form>
                                                </div>
                                            </div>
                                            <div className="metric-sub-info">
                                                <div className={stateKpiData.IsMultiLevel ? 'disable-sub-level-none' : 'disable-sub-level'}>
                                                    <i className="fas fa-sitemap"></i>
                                                    <p>
                                                        {i18n.t("Enable_to_add_metric_categories_as_its_sub_level")}</p>
                                                </div>
                                                <div className={stateKpiData.IsMultiLevel ? 'enable-sub-level' : 'enable-sub-level-none'}>
                                                    <div className="check-box-info">
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" checked={stateKpiData.IsAggregation}
                                                                onChange={(e) => { this.bindAutoAggregationChange(e) }}
                                                                id="customCheck" name="example1" />
                                                            <label className="custom-control-label" for="customCheck">
                                                                {i18n.t("Aggregate_category_values_to_metric_value")}
                                                            </label>
                                                        </div>


                                                    </div>
                                                    <Modal className="addCategoryModal" aria-labelledby="contained-modal-title-vcenter" centered show={this.state.showCategory} onHide={this.handleCategoryClose} animation={false} backdrop="static">
                                                        <Modal.Header>
                                                            <Modal.Title id="contained-modal-title-vcenter">
                                                            {this.state.isEdit ? i18n.t("Edit_Category_Details"):i18n.t("Add_Category_Details")}
                                                            </Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <div className="row">

                                                                {!this.state.iscatypedisable ?

                                                                    <div className="form-group col-lg-12">
                                                                        <label> {i18n.t("Category_Type")}
                                                                            <span className="required"> *</span>

                                                                        </label>

                                                                        <select id="name" className="form-control" disabled={this.state.isEdit} onChange={this.CatTypeSelect.bind(this)} value={this.state.CatTypeID}>
                                                                            <option value="0">{i18n.t("Select_CategoryType")}</option>
                                                                            {this.removeDuplicates(this.state.allCategoryTypesAndNames).map((CT) => {
                                                                                return <option value={CT.ID} key={CT.ID}>{CT.CategoryType}</option>
                                                                            })}
                                                                            <option value="addnew">{i18n.t("Add_New")}</option>
                                                                        </select>
                                                                        <div className="errorMsg">
                                                                            {this.state.errors.CategoryType}
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="form-group col-lg-12">
                                                                        <label>{i18n.t("Category_Type")}
                                                                            <span className="required"> *</span>

                                                                        </label>
                                                                        <select id="name" className="form-control" disabled={this.state.iscatypedisable} onChange={this.CatTypeSelect.bind(this)} value={this.state.CatTypeID}>
                                                                            <option value="0">{i18n.t("Select_CategoryType")}</option>
                                                                            {this.state.allCategoryTypesAndNames.map((CT) => {
                                                                                return <option value={CT.ID} >{CT.CategoryType}</option>
                                                                            })}
                                                                            <option value="addnew">{i18n.t("Add_New")}</option>
                                                                        </select>

                                                                        <div className="errorMsg">
                                                                            {this.state.errors.CategoryType}
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className="form-group col-lg-12">
                                                                    <label>{i18n.t("Category_Name")}
                                                                        <span className="required"> *</span>

                                                                    </label>
                                                                    <select id="name" value={this.state.CatNameID} onChange={this.handleDropdownChange} className="form-control">
                                                                        <option value="0">{i18n.t("Select_CategoryName")}</option>
                                                                        {this.state.CategoryNameData.map((CN) => {
                                                                            return <option value={CN.ID} >{CN.CategoryName}</option>
                                                                        })
                                                                        }
                                                                        <option value="addnewcatname">{i18n.t("Add_New")}</option>
                                                                    </select>
                                                                    <div className="errorMsg">
                                                                        {this.state.errors.CategoryName}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group col-lg-6">
                                                                    <label>{i18n.t("Numerator_Value")}<span className="required"> *</span></label>
                                                                    <input type="number" 
                                                                    maxLength="12"
                                                                    value={this.state.numeratorVal}
                                                                    onChange={this.onNumeratorChange}
                                                                        
                                                                        className="form-control" />
                                                                    <span className="errorMsg">
                                                                        {this.state.errors.NumeratorValue}
                                                                    </span>
                                                                    <span className="errorMsg">
                                                                        {this.state.errors.NumeratorValueRange}
                                                                    </span>
                                                                </div>
                                                                <div className="form-group col-lg-6">
                                                                    <label>{i18n.t("Denominator_Value")}
                                                                        {stateKpiData.MetricUnit.trim() === '%' && <span className="required"> *</span>}
                                                                    </label>
                                                                    <input type="number"
                                                                    maxLength="12" 
                                                                    value={this.state.denomVal}
                                                                        onChange={this.onDenomChange}
                                                                        className="form-control" />
                                                                    <span className="errorMsg">
                                                                        {this.state.errors.Denominator}
                                                                    </span>
                                                                    <span className="errorMsg">
                                                                        {this.state.errors.DenominatorValueRange}
                                                                    </span>


                                                                </div>
                                                            </div>
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <button className="btn btn-default btn-sm mr-2" onClick={this.handleCategoryClose}>
                                                                {i18n.t("Cancel")}
                                                            </button>
                                                            {this.state.isEdit ?
                                                                <button
                                                                    className="btn btn-primary" onClick={this.handleCategoryEdit}>
                                                                    {i18n.t("Update")}
                                                                </button>
                                                                :
                                                                <button
                                                                    className="btn btn-primary" onClick={this.handleCategoryAdd}>
                                                                    {i18n.t("Add")}
                                                                </button>
                                                            }

                                                        </Modal.Footer>
                                                    </Modal>



                                                    <div className="add-category-infor-here">
                                                        <div className={stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0 ? 'add-metric-head-none' : 'add-metric-head'}>
                                                            <div type="button" className="btn add-category-btn" onClick={this.handleCategoryShow}>{i18n.t("Add_Category_Details")}</div>
                                                            <div className="add-sub-level-metrics">
                                                                <i class="far fa-file-plus"></i>
                                                                <p><span>*</span>
                                                                    {i18n.t("Add_category_details_as_sub_level_metrics")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {stateKpiData.CategoryDetails &&
                                stateKpiData.CategoryDetails.length > 0 &&
                            <div className="category-header">
                                  <span>
                                    {i18n.t("Category")} -{" "}
                                    {stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0
                                      ? stateKpiData.CategoryDetails[0].CategoryType
                                      : ""}
                                  </span>
                                  <div className="category-actions">
                                  <div className="category-add" onClick={this.handleCategoryTypeAddShow}>
                                  <i class="fal fa-plus-circle mr-3"></i>
                                  </div>
                                  <span onClick={() => this.deleteCategoryType()}>
                                    <i class="fas fa-trash-alt delete-icon-fix"></i>
                                  </span>
                                  </div>
                                </div>
                                }

                                                        <div className={stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0 && stateKpiData.IsMultiLevel ? 'category-lists' : 'category-lists-none'}>
                                                            <ul>
                                                                {items}
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    {/* {Add Category Type} */}
                                                    <Modal className="addCategoryModal" aria-labelledby="contained-modal-title-vcenter" centered show={this.state.showaddCategoryType} onHide={this.handleaddCategoryClose} animation={false} backdrop="static">
                                                        <Modal.Header>
                                                            <Modal.Title id="contained-modal-title-vcenter">{i18n.t("Add_Category_Type")}</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <div className="row">
                                                                <div className="form-group col-lg-12">
                                                                    <label>{i18n.t("New_Category")}
                                                                        <span className="required"> *</span>

                                                                    </label>
                                                                    <input id="categoryType" type="text" maxLength="100" className="form-control" onChange={(e) => { this.bindToolFieldChange(e) }} value={this.state.CategoryDetails.CategoryType} />

                                                                    <span className="errorMsg">
                                                                        {this.state.errors.CategoryType}
                                                                    </span>

                                                                </div>
                                                                <div className="form-group col-lg-12">
                                                                    <label>{i18n.t("Name")}

                                                                        <span className="required"> *</span>

                                                                    </label>
                                                                    <input id="categoryName" type="text" maxLength="100" className="form-control" onChange={(e) => { this.bindToolFieldChange(e) }} value={this.state.CategoryDetails.CategoryName} />


                                                                    <span className="errorMsg">
                                                                        {this.state.errors.CategoryName}
                                                                    </span>

                                                                </div>
                                                                <p className="text-right newlyadded">
                                                                    {i18n.t("Newly_added_category_type_will_be_sent_for_approval")}
                                                                </p>
                                                            </div>
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <button className="btn btn-default btn-sm mr-2" onClick={this.handleaddCategoryClose}>
                                                                {i18n.t("Cancel")}
                                                            </button>
                                                            <button className="btn btn-primary" onClick={this.handleaddCategoryAdd}>
                                                                {i18n.t("Add")}
                                                            </button>
                                                        </Modal.Footer>
                                                    </Modal>

                                                    {/* {Add Category Name} */}
                                                    <Modal className="addCategoryModal" aria-labelledby="contained-modal-title-vcenter" centered show={this.state.showaddCategory} onHide={this.handleaddCategoryNameClose} animation={false} backdrop="static">
                                                        <Modal.Header>
                                                            <Modal.Title id="contained-modal-title-vcenter"> {i18n.t("Add_Category_Name")}</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <div className="row">
                                                                <div className="form-group col-lg-12">
                                                                    <label>{i18n.t("Category_Type")}
                                                                        <span className="required"> *</span>

                                                                    </label>

                                                                    <input type="text" maxLength="100" className="form-control" id="" value={this.state.CategoryDetails.CategoryType} disabled />

                                                                </div>

                                                                <div className="form-group col-lg-12">
                                                                    <label>{i18n.t("Name")}

                                                                        <span className="required"> *</span>
                                                                    </label>

                                                                    <input id="categoryName" type="text" maxLength="100" className="form-control" onChange={(e) => { this.bindToolFieldChange(e) }} value={this.state.CategoryDetails.CategoryName} />

                                                                    <div className="errorMsg">
                                                                        {this.state.errors.CategoryName}
                                                                    </div>


                                                                </div>

                                                                <p className="text-right newlyadded">  {i18n.t("Newly_added_category_name_will_be_sent_for_approval")}</p>
                                                            </div>
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <button className="btn btn-default btn-sm mr-2" onClick={this.handleaddCategoryNameClose}>
                                                                {i18n.t("Cancel")}
                                                            </button>
                                                            <button className="btn btn-primary" onClick={this.handleaddCategoryAdd}>
                                                                {i18n.t("Add")}
                                                            </button>
                                                        </Modal.Footer>
                                                    </Modal>


                                                    {/* Approval Notification */}
                                                    <Modal className="approvalModal" aria-labelledby="contained-modal-title-vcenter" centered show={this.state.showApproval} onHide={this.handleApprovalClose} animation={false} backdrop="static">
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>
                                                                {i18n.t("Approval_Notification")}
                                                            </Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <div className="row">
                                                             {  this.state.CatTypeID==="addnew" ?
                                                             <p className="approvalInfo">
                                                                    {i18n.t("Newly_added_category_type_is_sent_for_approval")}
                                                                </p>:
                                                                    <p className="approvalInfo">
                                                                    {i18n.t("Newly_added_category_name_is_sent_for_approval")}
                                                                </p>
                                                                  }
                                                            </div>
                                                        </Modal.Body>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="errorMsg">
                                            {this.state.errors.MultiLevel}
                                        </div>

                                    </div>

                                    {/* end of multi level UI */}
                                </div>
                            </div>
                        </form>

                    </Modal.Body>
                    {
                        <Modal.Footer>
                            <div className="modal-btn">
                                <button
                                    onClick={this.handleHide}
                                    className="btn btn-default btn-sm mr-2"
                                > {i18n.t("Cancel")}

                                </button>
                                {!this.props.kpiData ? (
                                    <input
                                        type="Button"
                                        className="btn btn-primary btn-sm"
                                        onClick={() => this.handleSave()}
                                        value={i18n.t("Create")}
                                    />
                                ) : (
                                        <input
                                            type="Button"
                                            className="btn btn-primary btn-sm"
                                            onClick={() => this.handleSave()}
                                            value={i18n.t("Update")}
                                        />
                                    )}
                            </div>
                        </Modal.Footer>
                    }
                </Modal>
                {this.state.viewmore ? (
                    <KpiPopUp
                        SOCategoryID={this.props.SOCategoryID}
                        show={true}
                        onClose={this.onClose.bind(this)}
                        filterDetails={this.props.filterDetails}
                        tabName="BO"
                    />
                ) : null}
            </>
        );
    }
}

// function mapStateToProps(state){
//     return{
//         kpiDatas:state.kpiDatas
//     }
// }
// //converts actions from redux to props in view
// function mapDispatchToProps(dispatch){
//     return{
//         addHuman:()=>dispatch(humanActions.addHuman())
//     }
// }

// export default connect(mapStateToProps,mapDispatchToProps)(HumanPopUp);
