import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { LocalApiBaseUrl, enabledOfferingsBATForAssessment, BATOfferingIcons } from "../Shared/Constant";
import _ from "lodash";
import SynopsLoader from "../Shared/SynopsLoader";
import LoadingOverlay from "react-loading-overlay";

class AssessmentSelectionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientDetails: {
        name: _.get(props, "clientName", ""),
        industry: _.get(props, "clientIndustry", ""),
        marketUnit: _.get(props, "clientMarketUnit", ""),
      },
      offeringDetails: {
        name: _.get(props, "offeringName", ""),
        type: _.get(props, "offeringType", ""),
      },
      subOfferingDetails: {
        id: _.get(props, "subOfferingId", ""),
        name: _.get(props, "subOfferingName", ""),
      },
      BATAssessClientID: _.get(props, "BATAssessClientID", ""),
      includeAllForTVE: true,
      isAPILoading: false,
      clientCount: _.get(props, "clientCount", ""),
      hoveroff: "",
      batOfferingSummaryResponse: [],
      modifiedOfferingSummary: [],
      synopsOfferingSummaryResponse: [],
      selectedOffering: [],
      offeringName: "",
    };
  }

  componentDidMount() {
    // FETCH THE DATA FROM THE APIs ON SCREEN LOAD
    this.fetchScreenData();
  }

  fetchScreenData() {
    this.setState({ isAPILoading: true });
    // FETCH THE DATA FROM THE API
    Promise.all([this.fetchOfferingSummary(false), this.fetchClientAssessmentDetails(false)])
      .then(() => {
        this.setState({ isAPILoading: false }, () => {
          this.getModifiedOfferingSummary();
        });
      })
      .catch(() => {
        this.setState({ isAPILoading: false });
      });
  }

  // Api to FETCH BAT OFFERING SUMMARY DATA
  fetchOfferingSummary(showHideLoader = true) {
    const { clientCount, includeAllForTVE } = this.state;
    const requestData = {
      clientCount,
      includeAllForTVE,
    };

    showHideLoader && this.setState({ isAPILoading: true });
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`${LocalApiBaseUrl}BAT/GetAssessmentOfferingList`, requestData)
        .then((response) => {
          const responseData = response.data;

          this.setState(
            {
              batOfferingSummaryResponse: responseData,
            },
            () => {
              showHideLoader && this.setState({ isAPILoading: false });
              resolve(responseData);
            }
          );
        })
        .catch((error) => {
          this.setState({ isAPILoading: false }, () => reject(error));
          trycatchAlertPopup(error);
          reject(error);
        });
    });
  }

  // EXECUTE API TO FETCH CLIENT ASSESSMENT DETAILS
  fetchClientAssessmentDetails(showHideLoader = true) {
    const { clientName, BATAssessClientID } = this.props;

    showHideLoader && this.setState({ isAPILoading: true });
    return new Promise((resolve, reject) => {
      // FETCH THE DATA FROM THE API
      axiosInstance
        .get(
          `${LocalApiBaseUrl}BATAssessment/GetClientAssessmentDetails?BATAssessClientID=` +
            BATAssessClientID +
            `&BATClientName=` +
            clientName,
          {
            params: {},
          }
        )
        .then((response) => {
          const responseData = response.data;

          this.setState(
            {
              synopsOfferingSummaryResponse: responseData,
            },
            () => {
              showHideLoader && this.setState({ isAPILoading: false });
              resolve(responseData);
            }
          );
          showHideLoader && this.getModifiedOfferingSummary();
        })
        .catch((error) => {
          this.setState({ isAPILoading: false });
          trycatchAlertPopup(error);
          reject(error);
        });
    });
  }

  // Method to OBTAIN MERGED OFFERING DATA
  // Takes GetOfferingSummary response as input parameter
  getModifiedOfferingSummary() {
    const { batOfferingSummaryResponse: response } = this.state;

    const OfferingSummaryData = _.map(response, (eachRecord) => {
      let SubOfferingDetails = eachRecord.towerNames;
      if (!_.isEmpty(SubOfferingDetails)) {
        SubOfferingDetails = _.map(SubOfferingDetails, (eachItem, index) => {
          const subOfferingName = eachItem.tower;
          const subOfferingId = eachItem.towerID;
          return {
            BATAssesmentClientOfferingMappingID: null,
            BATSubOfferingID: subOfferingId,
            BATSubOfferingName: subOfferingName,
            hasTVEEnabledMetrics: eachItem.hasTVEEnabledMetrics,
            CollectionStatus: null,
            ParentBATOfferingID: eachRecord.offeringId,
            ParentBATOfferingName: eachRecord.offering,
            ParentBATOfferingType: eachRecord.offeringType,
          };
        });
      }
      return {
        ...eachRecord,
        SubOfferingDetails,
      };
    });

    var that = this;
    const modifiedOfferingSummary = _.map(OfferingSummaryData, (eachRecord) => {
      let BATOfferingID = 0;
      let BATOfferingName = "";
      let BATOfferingType = "";
      let logo = eachRecord.logo;
      let OfferingForAssessment = false;
      let result =
        that.state.synopsOfferingSummaryResponse.length > 0 ? that.state.synopsOfferingSummaryResponse[0] : [];
      if (Object.keys(result).length > 0) {
        var TempOffering = _.find(result.OfferingDetails, function(clientDetails) {
          return clientDetails.BATOfferingName === eachRecord.offering;
        });
      }

      BATOfferingID = TempOffering != undefined ? TempOffering.BATOfferingID : eachRecord.offeringId;
      BATOfferingName = TempOffering != undefined ? TempOffering.BATOfferingName : eachRecord.offering;
      BATOfferingType = TempOffering != undefined ? TempOffering.BATOfferingType : "";
      OfferingForAssessment = TempOffering != undefined ? true : false;

      let SubOfferingDetails = eachRecord.SubOfferingDetails;

      if (!_.isEmpty(SubOfferingDetails)) {
        if (TempOffering != undefined) {
          SubOfferingDetails = _.map(SubOfferingDetails, (eachSubOfferingDetails, index) => {
            let TempSubOffering = _.find(TempOffering.SubOfferingDetails, function(clientDetails) {
              return clientDetails.BATSubOfferingName === eachSubOfferingDetails.BATSubOfferingName;
            });

            if (TempSubOffering != undefined) {
              return {
                ...eachSubOfferingDetails,
                BATAssesmentClientOfferingMappingID: TempSubOffering.BATAssesmentClientOfferingMappingID,
                BATSubOfferingID: TempSubOffering.BATSubOfferingID,
                BATSubOfferingName: TempSubOffering.BATSubOfferingName,
                CollectionStatus: TempSubOffering.CollectionStatus,
                hasTVEEnabledMetrics: TempSubOffering.IsTVEEnabled,
                ParentBATOfferingID: TempOffering.BATOfferingID,
                ParentBATOfferingName: TempOffering.BATOfferingName,
                ParentBATOfferingType: TempOffering.BATOfferingType,
              };
            } else {
              return {
                ...eachSubOfferingDetails,
              };
            }
          });
        } else {
          SubOfferingDetails = _.map(SubOfferingDetails, (eachSubOfferingDetails, index) => {
            return {
              ...eachSubOfferingDetails,
            };
          });
        }
      }

      return {
        SubOfferingDetails,
        BATOfferingID,
        BATOfferingName,
        BATOfferingType,
        logo,
        OfferingForAssessment,
      };
    });

    let currentOfferingName = this.state.offeringDetails.name;
    let selectedOffering = modifiedOfferingSummary.filter(
      (obj) => obj.BATOfferingName.toLowerCase() == currentOfferingName.toLowerCase()
    )[0];
    this.setState({
      offeringSummaryData: OfferingSummaryData,
      modifiedOfferingSummary: modifiedOfferingSummary,
      selectedOffering: selectedOffering ? selectedOffering : modifiedOfferingSummary[0],
      isAPILoading: false,
    });
  }

  // HANDLING IMAGE CHANGE ON HOVER
  handleHover = (off) => {
    this.setState({
      hoveroff: off,
    });
  };

  //HANDLING IMAGE CHANGE ON BLUR
  handleBlur = () => {
    this.setState({
      hoveroff: "",
    });
  };

  // Triggers on Offering change
  onOfferingClick = (offeringDetails, type) => {
    // debugger;
    this.setState({
      selectedOffering: offeringDetails,
    });
  };

  // Triggers on Add Assessment click event
  handleClickAddToAssessment = (subOfferingDetails) => {
    const { BATAssessClientID } = this.props;

    let requestData = {
      BATAssessClientID,
      OfferingDetails: [
        {
          BATOfferingID: subOfferingDetails.ParentBATOfferingID,
          BATOfferingName: subOfferingDetails.ParentBATOfferingName,
          BATOfferingType: subOfferingDetails.ParentBATOfferingType
            ? subOfferingDetails.ParentBATOfferingType
            : "Functional",
        },
      ],
      SubOfferingDetails: [
        {
          BATAssesmentClientOfferingMappingID: subOfferingDetails.ParentBATOfferingID,
          BATSubOfferingID: subOfferingDetails.BATSubOfferingID ? subOfferingDetails.BATSubOfferingID : "",
          BATSubOfferingName: subOfferingDetails.BATSubOfferingName,
          IsTVEEnabled: subOfferingDetails.hasTVEEnabledMetrics,
        },
      ],
    };

    // Currently Selected Offering
    this.setState({
      isAPILoading: true,
      offeringName: subOfferingDetails.ParentBATOfferingName,
      offeringDetails: {
        name: subOfferingDetails.ParentBATOfferingName,
        type: subOfferingDetails.ParentBATOfferingType,
      },
    });

    return new Promise((resolve, reject) => {
      // FETCH THE DATA FROM THE API
      axiosInstance
        .post(`${LocalApiBaseUrl}BATAssessment/AddOfferingSubOfferingAsssessment`, requestData)
        .then((response) => {
          const responseData = response.data;

          this.setState({}, () => {
            resolve(responseData);
          });
          this.fetchClientAssessmentDetails();
        })
        .catch((error) => {
          this.setState({ isAPILoading: false });
          trycatchAlertPopup(error);
          reject(error);
        });
    });
  };

  // EVENT HANDLER FOR THE CLICK EVENT OF THE "Remove" BUTTON BUTTON OF SUB-OFFERING TO REMOVE IT FROM THE ASSESSMENT
  handleClickRemoveFromAssessment = (subOfferingDetails) => {
    if (window.confirm("Do you really want to remove this sub-offering from assessment?")) {
      let { synopsOfferingSummaryResponse } = this.state;
      let offerselecte = synopsOfferingSummaryResponse[0].OfferingDetails.filter(function(eachOffering) {
        return eachOffering.BATOfferingID == subOfferingDetails.ParentBATOfferingID;
      });
      let subOfferingClicked = offerselecte[0].SubOfferingDetails.filter(function(eachItem) {
        return eachItem.BATSubOfferingID == subOfferingDetails.BATSubOfferingID;
      });

      this.setState({
        isAPILoading: true,
      });

      axiosInstance
        .delete(`${LocalApiBaseUrl}BATAssessment/DisableAssessmentSubOfferingLevel`, {
          params: {
            BatAssessSubOfferingId: subOfferingClicked[0].BATAssessSubOfferingID,
          },
        })
        .then((response) => {
          window.alert("The sub-offering was successfully removed from assessment");
          this.setState(
            {
              isAPILoading: false,
            },
            () => {
              this.fetchClientAssessmentDetails();
            }
          );
        })
        .catch((error) => {
          this.setState({ isAPILoading: false });
          trycatchAlertPopup(error);
        });
    }
  };

  // Triggers on "Start Assessment" button click event
  handleClickStartAssessment = (subOfferingDetails) => {
    let { synopsOfferingSummaryResponse } = this.state;
    let offerselecte = synopsOfferingSummaryResponse[0].OfferingDetails.filter(function(eachOffering) {
      return eachOffering.BATOfferingID == subOfferingDetails.ParentBATOfferingID;
    });
    let subOfferingClicked = offerselecte[0].SubOfferingDetails.filter(function(eachItem) {
      return eachItem.BATSubOfferingID == subOfferingDetails.BATSubOfferingID;
    });
    this.setState(
      {
        showOfferingSubOfferingModal: false,
        dataToSend: {
          offeringDetails: {
            name: subOfferingDetails.ParentBATOfferingName,
            type: subOfferingDetails.ParentBATOfferingType,
            id: subOfferingDetails.ParentBATOfferingID,
          },
          subOfferingDetails: {
            id: subOfferingDetails.BATSubOfferingID,
            name: subOfferingDetails.BATSubOfferingName,
            isSubOfferingTVEEnabled: subOfferingDetails.hasTVEEnabledMetrics,
          },
          clientDetails: {
            name: synopsOfferingSummaryResponse[0].BATClientName,
            BATIndustryName: synopsOfferingSummaryResponse[0].BATIndustryName,
            BATMarketUnitName: synopsOfferingSummaryResponse[0].BATGeographyName,
            logo: synopsOfferingSummaryResponse[0].ClientLogo ? synopsOfferingSummaryResponse[0].ClientLogo : null,
            id: synopsOfferingSummaryResponse[0].BATAssessClientID,
          },
          BATAssessSubOfferingID: subOfferingClicked[0].BATAssessSubOfferingID,
          startDate: subOfferingClicked[0].StartDate,
          endDate: subOfferingClicked[0].EndDate,
          BATAssessClientID: synopsOfferingSummaryResponse[0].BATAssessClientID,
          clientCount: this.state.clientCount,
          CollectionStatus: subOfferingDetails.CollectionStatus,
        },
      },
      () => {
        this.props.callbackHeaderStateChange(this.state.dataToSend);
      }
    );
  };

  handleModalHide = () => {
    this.setState(
      {
        dataToSend: {
          showOfferingSubOfferingModal: false,
        },
      },
      () => {
        this.props.callbackHeaderStateChange(this.state.dataToSend);
      }
    );
  };

  render() {
    const { isAPILoading, hoveroff, modifiedOfferingSummary, selectedOffering } = this.state;
    return (
      <div className="">
            
        <Modal
          show
          centered
          onHide={this.handleModalHide}
          backdrop="static"
          dialogClassName="modal-90w"
          className="offeringCategoryModal assessmentOfferingModal"
        >
          {this.props.screenfrom != undefined && this.props.screenfrom == "takeAssessment" ? (
            <Modal.Header className="justify-content-center">
              <span className="offering-assessment">Select a Sub Offering …</span>
            </Modal.Header>
          ) : (
            <Modal.Header closeButton className="justify-content-center">
              <span className="offering-assessment">Select a Sub Offering …</span>
            </Modal.Header>
          )}

          {/* IF API IS LOADING THEN SHOW THE LOADER */}
          {isAPILoading && <LoadingOverlay className="custom-loader" spinner={<SynopsLoader />} active />}

          <Modal.Body>
            <div className="row">
              <div className="col-lg-3 offeringName">
                <div className="category-name">Offerings for Assessment</div>
                <div>
                  <ul className="subOffering-list">
                    {!_.isEmpty(modifiedOfferingSummary) &&
                      _.map(modifiedOfferingSummary, (eachOfferingDetails, index) => {
                        if (eachOfferingDetails.OfferingForAssessment) {
                          const Image = BATOfferingIcons.filter(
                            (obj) => obj.key.toLowerCase() == eachOfferingDetails.logo.toLowerCase()
                          )[0];
                          return (
                            <li
                              className={
                                selectedOffering.BATOfferingName === eachOfferingDetails.BATOfferingName
                                  ? "active"
                                  : null
                              }
                              onClick={(e) => this.onOfferingClick(eachOfferingDetails, "Offerings for Assessment")}
                              onMouseOver={() => {
                                this.handleHover(eachOfferingDetails.BATOfferingName);
                              }}
                              onMouseOut={() => {
                                this.handleBlur();
                              }}
                            >
                              <span className="logo">
                                {Image ? (
                                  <img
                                    src={
                                      hoveroff === eachOfferingDetails.BATOfferingName ||
                                      selectedOffering.BATOfferingName === eachOfferingDetails.BATOfferingName
                                        ? Image.iconWhite
                                        : Image.iconBlack
                                    }
                                    alt={""}
                                  />
                                ) : (
                                  <img alt={""} />
                                )}
                              </span>
                              <span className="label-name">{eachOfferingDetails.BATOfferingName}</span>
                            </li>
                          );
                        }
                      })}
                  </ul>
                </div>

                <div className="category-name">Other Available Offerings</div>
                <div>
                  <ul className="subOffering-list">
                    {!_.isEmpty(modifiedOfferingSummary) &&
                      _.map(modifiedOfferingSummary, (eachOfferingDetails, index) => {
                        if (!eachOfferingDetails.OfferingForAssessment) {
                          const Image = BATOfferingIcons.filter(
                            (obj) => obj.key.toLowerCase() == eachOfferingDetails.logo.toLowerCase()
                          )[0];
                          // CHECK IF THE OFFERING IS ENABLED FOR ASSESSMENT
                          const isEnabledForAssessment =
                            _.indexOf(enabledOfferingsBATForAssessment, eachOfferingDetails.BATOfferingName) !== -1;
                          return (
                            <li
                              className={
                                !isEnabledForAssessment
                                  ? "disabled"
                                  : selectedOffering.BATOfferingName === eachOfferingDetails.BATOfferingName
                                  ? "active"
                                  : ""
                              }
                              onClick={(e) => {
                                if (isEnabledForAssessment)
                                  this.onOfferingClick(eachOfferingDetails, "Other Available Offerings");
                              }}
                              disabled={!isEnabledForAssessment}
                              onMouseOver={() => {
                                if (isEnabledForAssessment) this.handleHover(eachOfferingDetails.BATOfferingName);
                              }}
                              onMouseOut={() => {
                                this.handleBlur();
                              }}
                            >
                              <span className="logo">
                                {Image ? (
                                  <img
                                    src={
                                      hoveroff === eachOfferingDetails.BATOfferingName ||
                                      selectedOffering.BATOfferingName === eachOfferingDetails.BATOfferingName
                                        ? Image.iconWhite
                                        : Image.iconBlack
                                    }
                                    alt={""}
                                  />
                                ) : (
                                  <img alt={""} />
                                )}
                              </span>
                              <span className="label-name">{eachOfferingDetails.BATOfferingName}</span>
                            </li>
                          );
                        }
                      })}
                  </ul>
                </div>
              </div>

              <div className="col-lg-9 subOfferingName">
                {!_.isEmpty(selectedOffering)
                  ? _.map(selectedOffering.SubOfferingDetails, (eachSubOffering, index) => {
                      return (
                        <div>
                          <div className={"assessment-suboffering"}>
                            <div>
                              {eachSubOffering.CollectionStatus == null ? (
                                <div className="assessment-suboffering-subname">
                                  {eachSubOffering.BATSubOfferingName}
                                </div>
                              ) : (
                                <div className="assessment-suboffering-name">{eachSubOffering.BATSubOfferingName}</div>
                              )}

                              <div className="assessment-suboffering-percent">
                                {eachSubOffering.CollectionStatus != null ? (
                                  <>
                                    <div className="percent-label">Data Collection</div>
                                    <div class="progress">
                                      <div
                                        class="progress-bar"
                                        role="progressbar"
                                        aria-valuenow={"10"}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{ width: `${eachSubOffering.CollectionStatus}%` }}
                                      ></div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <span className="percent-sublabel">
                                      Add this sub offering to perform assessment
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>

                            {eachSubOffering.CollectionStatus != null ? (
                              <div className="added-subOffering d-flex">
                                <Button
                                  className="btn-purple-outline mr-3"
                                  onClick={() => {
                                    this.handleClickRemoveFromAssessment(eachSubOffering);
                                  }}
                                >
                                  Remove
                                </Button>

                                <Button
                                  className="btn-purple"
                                  onClick={() => {
                                    this.handleClickStartAssessment(eachSubOffering);
                                  }}
                                >
                                  {!_.isNil(eachSubOffering.CollectionStatus) &&
                                  _.toNumber(eachSubOffering.CollectionStatus) > 0
                                    ? "View Assessment"
                                    : "Start Assessment"}
                                </Button>
                              </div>
                            ) : (
                              <div className="add-subOffering-button">
                                <Button
                                  className="btn-purple"
                                  onClick={(e) => this.handleClickAddToAssessment(eachSubOffering)}
                                >
                                  Add to Assessment
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withRouter(AssessmentSelectionModal);
