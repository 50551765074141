import React, { Component } from 'react';
import { LocalApiBaseUrl} from '../Shared/Constant';
import { AgGridReact, Ag } from 'ag-grid-react';
import ClientRegionPopup from './ClientRegionPopup';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;



export default class ClientRegionList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewmore: false, 
            rowData: [],
            rowSelected: [],
            columnDefs: [
                {
                    headerName: "Edit", 
                    suppressMovable: true,
                    width: 60,
                    cellRenderer: (params) => {
                        var link = document.createElement('span');
                        link.className = 'tbledit';
                        link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
                        link.addEventListener('click', (e) => {
                            this.dealpopup(e, params.data);
                        });
                        return link;
                    },
                    suppressFilter: true,
                },
                {
                    headerName: "Delete", suppressMovable: true,
                    width: 80,
                    cellRenderer: (params) => {
                        var link = document.createElement('span');
                        link.className = 'tbldel';
                        link.innerHTML = '<i class="fas fa-trash-alt delete-icon-fix"></i>';
                        link.addEventListener('click', (e) => {
                            this.deleteClientRegion(e, params.data);
                        });
                        return link;

                    },
                    suppressFilter: true
                },
                { 
                headerName: "Client Region", 
                width: 350,
                field: "ClientRegionName", 
                suppressMovable: true, 
                resizable: false },
                { headerName: "Status", field: "Status", suppressMovable: true, resizable: false },
                {
                    headerName: "Is Active", field: "IsActive", resizable: false, suppressMovable: true, suppressFilter: true,

                    cellRenderer: params => {
                        return `<label class="form-check">
                    <input class="form-check-input" disabled type="checkbox" ${params.value ? 'checked' : ''}>
                    <span class="checkmark"></span>
                     </label>`;
                    }
                },

            ],
            gridOptions: {
                paginationPageSize: 50,
                rowHeight: 40,
                headerHeight: 40,
                animateRows: true,
                rowSelection: 'single',
                defaultColDef: {
                    sortable: true
                },
            }
        } 
        this.onClose = this.onClose.bind(this);
        this.dealpopup = this.dealpopup.bind(this);
        this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.deleteClientRegion = this.deleteClientRegion.bind(this);
        this.onBtnExport = this.onBtnExport.bind(this);

    }

    deleteClientRegion(e, data) {
        if (this.props.featureData.filter((data) => data.Submenu == "Client Region" 
        && data.Operations.includes("Delete")).length > 0) {
            if (window.confirm('Are you sure you want to delete the item?')) {
                axiosInstance.delete(`${LocalApiBaseUrl}ClientRegionApproval/DeleteClientRegion`, {
                    params: {
                        ClientRegionId : data.ID
                    }
                }).then((response) => {
                    window.alert(response.data.StatusMessage)
                    this.getClientRegion();

                })
                .catch((error) => {
                    trycatchAlertPopup(error);
                  });
          
          
            }
        }
        else window.alert("Access Denied !!")

    }

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    onFilterTextBoxChanged(e) {
        this.state.gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
    }
    
    dealpopup(e, data) {

        if (data != undefined) {
            // edit condition
            if (this.props.featureData.filter((data) => data.Submenu == "Client Region" && data.Operations.includes("Edit")).length > 0) {
                this.setState({ viewmore: true, rowSelected: data })
            }
            else window.alert("Access Denied !!")

        }
        else {
            // add condition
            if (this.props.featureData.filter((data) => data.Submenu == "Client Region" && data.Operations.includes("Add")).length > 0) {
                this.setState({ viewmore: true, rowSelected: data })
            }
            else window.alert("Access Denied !!")

        }



    }

    onClose() {
        this.setState({ viewmore: false });
    }

    getClientRegion() {
        //API call
        axiosInstance.get(`${LocalApiBaseUrl}DealOnboarding/GetAllClientRegion`)
            .then((response) => {
                this.setState({
                    rowData: response.data 
                })                
                this.gridApi.setRowData(response.data);
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
    }

    componentWillMount() {

        this.getClientRegion();
        let columnDefs = this.state.columnDefs;
        if (this.props.featureData.filter((data) => data.Submenu == "Client Region" && data.Operations.includes("Edit")).length == 0) {
            columnDefs = columnDefs.filter((data) => data.headerName != "Edit");
            this.setState({
                columnDefs: columnDefs
            })

        }
    
    }
    onBtnExport() { 

        let params = {
            columnKeys: ['ClientRegionName','Status','IsActive'],
            columnGroups: true,
            allColumns: false,
            skipHeader: false,
            fileName: 'ClientRegionList',
            //   columnSeparator: document.querySelector("#columnSeparator").value
        };
        this.state.gridOptions.api.exportDataAsCsv(params);
    }

    handleSave(data) {
        let params = {
            ClientRegionName: data.ClientRegionName,
            IsActive:data.IsActive,
            RequestorRemarks:data.RequestorRemarks
        }
        //Post API Call
        axiosInstance.post(`${LocalApiBaseUrl}ClientRegionApproval/AddEditClientRegion`, params)
            .then((response) => {
                window.alert(response.data.StatusMessage)
                this.getClientRegion();
        })
        .catch((error) => {
            trycatchAlertPopup(error);
          });
    }
    onGridReadyRefresh = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
      };

    render() {

        return (
            <div className="main-data">
                {/* <h3 className="grid-heading">Client Region List</h3> */}
                <div className="tableFilter">
                    <div className="form-section">
                        <div className="form-row">
                            <div className="form-group col-lg-3">
                                <input type="text" className="form-control" id="filter-text-box" placeholder="Filter..." onChange={(e) => this.onFilterTextBoxChanged(e) } />
                            </div>
                            {this.props.featureData.filter((data) => data.Submenu == "Client Region" && data.Operations.includes("Add")).length > 0 &&
                                <div className="form-group col-lg-3">
                                    <button type="button" className="btn btn-primary btn-sm" onClick={(e) => { this.dealpopup(e) }}><span><FontAwesomeIcon icon={faPlusCircle} /></span> Add Client Region</button>
                                </div>
                            }
                            <div className="ml-auto mr-1">
                                {this.props.featureData.filter((data) => data.Submenu == "Client Region" && data.Operations.includes("Download")).length > 0 ?
                                    <span className="btn btn-primary btn-sm py-1" onClick={this.onBtnExport}>
                                        <FontAwesomeIcon icon={faFileDownload} className="mr-1" /> Download
                                </span> : null}
                                {/* <button className="btn btn-primary btn-sm" id="btnsave" onClick={this.onBtnExport} disabled={this.state.active}>Download</button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ag-theme-balham ag-table">
                            <AgGridReact
                                enableSorting={true}
                                enableFilter={true}
                                pagination={true}
                                paginationPageSize={50}
                                floatingFilter={true}
                                gridOptions={this.state.gridOptions}
                                columnDefs={this.state.columnDefs}
                                rowData={this.state.rowData}
                                onGridReady={this.onGridReadyRefresh}
                                onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                            </AgGridReact>
                        </div>
                    </div>
                </div>
                {this.state.viewmore ? <ClientRegionPopup rowData={this.state.rowData} data={this.state.rowSelected} show={this.state.viewmore} onClose={this.onClose} onSave={this.handleSave} /> : null}

            </div>
        )
    }
}