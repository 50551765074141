import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import _ from 'lodash';
import LoadingOverlay from 'react-loading-overlay';
import { LocalApiBaseUrl, trycatchAlertPopup } from './Constant';
import { store } from '../reducers/configureStore';
import axiosInstance from './interceptor';

import SynopsLoader from './SynopsLoader';
import { updateSyncFilter } from './FiltersHelper';
import {
  apiload,
} from '../actions/JourneyActions';
import OfferingSubOffering from './OfferingSubOffering';
import { filterSelectedSubOffering } from './commonFunctions';
import * as common from "./Common"

class PulseFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offerings: [],
      act: false,
      data: [],
      subOfferings: [],
      offeringsData: [],
      selectedOffering: '',
      selectedSubOffering: '',
      offeringID: 0,
      ssSelectClient : "Select Client",
      snapshotCalled:false
    };
  }

  async componentDidMount() {
    let offerings = [];
    if (this.props.pulseFilterData.offerings !== undefined &&  this.props.pulseFilterData.offerings.length >0) {
      this.props.pulseFilterData.offerings.map((each) => {
        offerings.push(each.OfferingName);
      });

    }
    this.setFiltersOncomponentDidMount();
    this.setState({
      offerings,
      data: this.props.pulseFilterData.data,
      offeringsData: this.props.pulseFilterData.offerings,
    });
  }

 async componentDidUpdate(previousProps) {
    if (previousProps.clientData.id !== this.props.clientData.id) {
      if (this.props.clientData.id) {
        this.setState({ offeringsData: [], offerings: [], subOfferings: [] });
      }
      const data = { SubOffering: '', Offering: '', pulseData: [] };
      this.props.getPulseData([]);
      this.props.onSavePulsePageData(data);
      this.setState({
        selectedSubOffering: i18n.t('Select_Sub_Offerings'),
        selectedOffering: i18n.t('Select_Offering'),
        subOfferings: [],
      });
        // Set the Filter values n REDUX to sync the other pages filters
     /*  let emptySyncFilter = {
        OfferingId: "",
                ProcessId: "",
                LocationId: "",
                Month: '',
                LocationOMID: [],
                IsDataPublishForLocation: false,
                isOMIDAvailable: false,
                OfferingName: ""
        }
        store.dispatch({
          type: 'SYNC_CURRENTFILTER',
          payload: {...emptySyncFilter},
        }); */
        this.setState({
          snapshotCalled:false
        })
    }
    if (this.props.pulseFilterData.data !== this.state.data) {
      let offerings = [];
      if (this.props.pulseFilterData.offerings) {
        this.props.pulseFilterData.offerings.map((each) => {
          offerings.push(each.OfferingName);
        });
        if(!this.state.snapshotCalled && this.props.pulseFilterData.offerings !== undefined && this.props.pulseFilterData.offerings.length>0 && 
          (previousProps.pulseFilterData.offerings ===undefined || 
            (previousProps.pulseFilterData.offerings !==undefined && 
              JSON.stringify(previousProps.pulseFilterData.offerings) !== JSON.stringify(this.props.pulseFilterData.offerings) ) ) 
          )
          {
            if(this.props.snapshotProfile.length ===0)
            {
              await this.getSnapshot(true);            //When PULSE Screen Hard Refresh
            }
            else
            {
              await this.getSnapshot(false);  
            }
           
          }
      }
      this.setState({
        offerings,
        data: this.props.pulseFilterData.data,
        offeringsData: this.props.pulseFilterData.offerings,
      });
    }
  }

  onOfferingChange = (event) => {
    const selectedValue = event.target.value;
    const { offeringsData } = this.state;
    const { getPulseData, onSavePulsePageData } = this.props;
    let offeringName = '';
    let subofferings = [];
    const syncFilter = {
      OfferingId: 0, ProcessId: 0, ProcessName: null, LocationId: 0, Month: null,
    };
    offeringsData.forEach((each) => {
      if (parseInt(each.OfferingID, 10) === parseInt(selectedValue, 10)) {
        subofferings = each.ChildOfferings;
        offeringName = each.OfferingName;
        syncFilter.OfferingId = each.OfferingID;
      }
    });
    // Set the Filter values n REDUX to sync the other pages filters
    updateSyncFilter(syncFilter);
    this.setState(
      {
        subOfferings: subofferings,
        selectedOffering: offeringName,
        selectedSubOffering: i18n.t('Select_Sub_Offerings'),
        offeringID: '0',
      },
      () => {
        const data = { SubOffering: '', Offering: '', pulseData: [] };
        getPulseData([]);
        onSavePulsePageData(data);
        this.setState({ selectedSubOffering: i18n.t('Select_Sub_Offerings') });
        if (subofferings.length > 0) {
          const { SelectedFilter } = this.props;
          const filtersSubofferingData = filterSelectedSubOffering(_.cloneDeep(subofferings), SelectedFilter.ProcessId);
          const { firstSuboffering } = filtersSubofferingData;
          if (SelectedFilter) {
            this.onSubOfferingChange({
              target: {
                value: firstSuboffering[0].OfferingID,
              },
            });
          }
          syncFilter.ProcessId = firstSuboffering[0].OfferingID;
          updateSyncFilter(syncFilter);
        }
      },
    );
  };

  onSubOfferingChange = (event) => {
    const processID = event.target.value;
    const { subOfferings } = this.state;
    const { SelectedFilter } = this.props;
    const filtersSubofferingData = filterSelectedSubOffering(_.cloneDeep(subOfferings), processID);
    const selectedSuboffering = filtersSubofferingData.subofferingData[0];
    const syncFilter = {
      OfferingId: SelectedFilter.OfferingId,
      ProcessId: selectedSuboffering.OfferingID,
      ProcessName: null,
      LocationId: 0,
      Month: null,
    };

    // Set the Filter values n REDUX to sync the other pages filters
    updateSyncFilter(syncFilter);

    this.setState({ selectedSubOffering: selectedSuboffering.Process, offeringID: processID }, () => {
      this.offeringFilter();
    });

     //track user
     common.userTracker(
      common.TRACKING_MODULES.PULSE_HUMAN_MACHINE,
      false,
      null,
      null,
      common.createParamObject(
        common.PARAM_NAMES.OFFERING,
        this.state.selectedOffering
      ),
      common.createParamObject(
        common.PARAM_NAMES.SUB_OFFERING,
        selectedSuboffering.Process
      )
    );
  };

  offeringFilter = () => {
    let pulsePageConfigure = false;
    this.setState({ showLoaderOnPage: true });
    this.props.roleReducerData.map((each) => {
      if (each.FeatureName === 'PulsePage') {
        pulsePageConfigure = each.Operations.includes('Edit');
      }
    });
    axiosInstance
      .get(`${LocalApiBaseUrl}PulseDashBoard/GetPulseDashboardApplication`, {
        params: {
          OrgEntityID: this.props.clientData.id,
          isAdmin: 0,
          LanguageID: this.props.languageData.id,
          OfferingID: this.state.offeringID,
        },
      })
      .then((response) => {
        if (response.data.length) {
          this.props.savePulseData({ offerings: this.state.offeringsData, data: response.data });
          const pageData = response.data;
          let pulsePageData = [];
          let offeringId = 0;
          pageData.filter((each) => {
            if (each.OfferingName === this.state.selectedOffering && each.Process === this.state.selectedSubOffering) {
              pulsePageData = each.tilemodel;
              offeringId = each.OfferingId;
            }
          });
          const data = {
            SubOffering: this.state.selectedSubOffering,
            offeringId,
            Offering: this.state.selectedOffering,
            pulseData: pulsePageData,
          };
          this.props.getPulseData(data);
          this.props.onSavePulsePageData(data);
        }
        this.setState({ showLoaderOnPage: false });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        this.setState({ showLoaderOnPage: false });
        this.props.history.push({
          pathname: '/Dashboard',
          state: { pulseMenu: false },
        });
      });
  };

  /**
   * Method Set the pulse page filters (Offering and Sub-offering)
   * If Default snap shot exist set the pulse filters based on that otherwise check for the REDUX if last selection exist
   */
  setFiltersOncomponentDidMount = () => {
    const { clientData, pulseFilterData } = this.props;
    if (clientData.id && pulseFilterData.offerings !== undefined && pulseFilterData.offerings.length>0) {
      const syncFilters = store.getState().CurrentFilter.SyncFilter;
      if (syncFilters && syncFilters.OfferingId !== 0) {
        let subofferings = [];
        let selectedOffering = '';
        let offering = [];
        offering = pulseFilterData.offerings.filter((each) => parseInt(each.OfferingID, 10) === parseInt(syncFilters.OfferingId, 10));
        if (offering.length > 0) {
          selectedOffering = offering[0].OfferingName;
          pulseFilterData.offerings.forEach((each) => {
            if (each.OfferingName === selectedOffering) {
              subofferings = each.ChildOfferings;
            }
          });
          const filtersSubofferingData = filterSelectedSubOffering([...subofferings], syncFilters.ProcessId);
          const selectedSubOffering = filtersSubofferingData.subofferingData;
          this.setState({ subOfferings: subofferings, selectedOffering });
          if (selectedSubOffering && selectedSubOffering.length > 0) {
            this.setState(
              { selectedSubOffering: selectedSubOffering[0].Process, offeringID: selectedSubOffering[0].OfferingID },
              () => {
                this.offeringFilter();
              },
            );
          } else {
            syncFilters.ProcessId = 0;
            syncFilters.LocationId = 0;
            syncFilters.Month = '';
            updateSyncFilter(syncFilters);
          }
        } else {
          syncFilters.OfferingId = 0;
          syncFilters.ProcessId = 0;
          syncFilters.LocationId = 0;
          syncFilters.Month = '';
          updateSyncFilter(syncFilters);
        }
      }
    }
  };
  getSnapshot = (refresh = false) => {
    const {
      SelectedFilter, languageData, clientData, pulseFilterData,history
    } = this.props;
    const { ssSelectClient } = this.state;
    const client = clientData.id;
    let loadDefaultSnapshot = false;
    this.setState({
      snapshotCalled:true
    })
    store.dispatch(apiload(true));
    return new Promise((resolve,reject)=>{
      if (client !== undefined && client > 0 && client !== '') {
        axiosInstance
          .get(`${LocalApiBaseUrl}Snapshot/GetSnapshotsByUserID`, {
            params: { OrgEntityID: client, LanguageID: languageData.id },
          })
          .then((response) => {
           
            store.dispatch(apiload(false));
            store.dispatch({
              type: 'SNAPSHOT',
              payload: response.data,
            });
            if (response.data != null && response.data.length > 0) {
              const defaultSS = response.data.filter((f) => f.IsDefault === true);
              if (defaultSS.length > 0) {
                loadDefaultSnapshot =
                SelectedFilter &&
                  (SelectedFilter.OfferingId &&
                    SelectedFilter.ProcessId)
                  ? true
                  : false;
                  if (pulseFilterData.offerings.length >0 && ( loadDefaultSnapshot || refresh)) {
                  store.dispatch({
                    type: 'SYNC_CURRENTFILTER',
                    payload: {
                      OfferingId: pulseFilterData.offerings.filter((o) => o.OfferingName_En === defaultSS[0].Offering_En)[0].OfferingID,
                      ProcessId: defaultSS[0].OfferingID,
                      LocationId: defaultSS[0].LocationID,
                      Month: '',
                      LocationOMID: defaultSS[0].PageName === "Journey Initiatives" ? ["All"]:[],
                      IsDataPublishForLocation: false,
                      isOMIDAvailable: false,
                      OfferingName: defaultSS[0].Offering_En,
                    },
                  });
                  if(defaultSS[0].PageName === "Pulse")
                  {
                    this.setFiltersOncomponentDidMount();
                    //onTabChange(defaultSS[0].PageName) 
                  }
                  resolve()
               
              }
            }
              
            }
            resolve()
          })
          .catch((err) => {
            reject()
           });
      } else {
        resolve()
      }

    })
  
  };

  /**
   * Method Set the snapshots (Filter snap shots that can be set from the Front door screen)
   * If Default snap shot exist set the pulse filters based on that
   */
  subHeaderOpen = (event) => {
    const {
      subHeaderOpenCallback,
    } = this.props;
    subHeaderOpenCallback(true);
    event.preventDefault();
  }

  subHeaderClose = (event) => {
    const {
      subHeaderCloseCallback,
    } = this.props;
    subHeaderCloseCallback(false);
    event.preventDefault();
  }

  render() {
    const {
      SelectedFilter, subHeaderOpen, subHeaderClose,
    } = this.props;
    const {
      selectedOffering, offerings, offeringsData, selectedSubOffering, subOfferings, showLoaderOnPage,
    } = this.state;
    return (
      <div id="subnav-header-id" className="subnav-header" onMouseEnter={subHeaderOpen} onMouseLeave={subHeaderClose}>
        <div className="container-fluid p-0">
          <LoadingOverlay
            fadeSpeed={0}
            spinner={<SynopsLoader />}
            active={showLoaderOnPage}
          />
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="d-flex align-items-center">
                <div className="offeringfilter-dropdowns d-flex align-items-center">
                  <span className="mr-4">Filter By</span>
                  <OfferingSubOffering
                    selectedOffering={selectedOffering}
                    offerings={offerings}
                    offeringsData={offeringsData}
                    selectedSubOffering={selectedSubOffering}
                    selectedSubOfferingId={SelectedFilter.ProcessId}
                    subOfferings={subOfferings}
                    onOfferingChange={this.onOfferingChange}
                    onSubOfferingChange={this.onSubOfferingChange}
                    subHeaderOpen={this.subHeaderOpen}
                    subHeaderClose={this.subHeaderClose}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  pulseFilterData: { ...state.CurrentFilter.PulseData },
  clientData: { ...state.CurrentFilter.ClientData },
  roleReducerData: state.roleReducers.roleReducers,
  languageData: { ...state.CurrentFilter.languageData },
  SelectedFilter: { ...state.CurrentFilter.SyncFilter },
  snapshotProfile : [...state.CurrentFilter.snapshotProfile],

});
const mapDispatchToProps = (dispatch) => ({
  onSavePulsePageData: (data) => {
    dispatch({ type: 'PULSE_PAGEDATA', payload: data });
  },
  savePulseData: (data) => {
    dispatch({ type: 'PULSE_DATA', payload: data });
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PulseFilter);
