import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import axiosInstance from '../Shared/interceptor';
import { regexForConfMasterFields } from '../Shared/Constant';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('msal.idtoken')}`;

export default class AttributePopUp extends React.Component {
  constructor(props) {
    super(props);
    // const { SelectValue } = this.props;
    this.state = {
      show: false,
      // apivalue: '',
      // selectValue: SelectValue,
      // Display: false,
      Attribute: {
        OfferingId: null,
        AttributeName1: '',
        AttributeName2: '',
        IsActive: false,
      },
      errors: [],
      // formIsValid: false,

    };

    this.onAttributeName1Change = this.onAttributeName1Change.bind(this);
    this.onAttributeName2Change = this.onAttributeName2Change.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { data, OfferingId, show } = this.props;

    if (data) {
      this.setState({
        Attribute: data,
      });
    } else {
      const { Attribute } = this.state;
      Attribute.OfferingId = OfferingId;
      this.setState({
        Attribute,
        show,
      });
    }
  }

  componentDidMount() {
    const { show } = this.props;
    this.setState({

      show,
    });
  }

  handleApproveHide(e) {
    const { onApproveClose } = this.props;
    this.setState({ show: false });
    if (String(e) !== String(undefined)) {
      if (String(e.target.textContent) !== '×') {
        onApproveClose();
      }
    } else {
      onApproveClose();
    }
  }

  handleRejectHide(e) {
    const { onRejectClose } = this.props;
    this.setState({ show: false });
    if (String(e) !== String(undefined)) {
      if (String(e.target.textContent) !== '×') {
        onRejectClose();
      }
    } else {
      onRejectClose();
    }
  }

  handleHide(e) {
    const { onClose, onSave } = this.props;
    this.setState({ show: false });
    if (String(e) !== String(undefined)) {
      if (String(e.target.textContent) !== '×') {
        onClose(e);
      } else {
        onClose(e);
      }
    } else {
      onClose(e);
    }
    onSave();
  }

  handleSave() {
    const { Attribute } = this.state;
    const { onSave } = this.props;
    if (this.validateForm()) {
      onSave(Attribute);

      this.handleHide();
    }
  }

  onAttributeName2Change(event) {
    const errors = {};
    this.setState({
      errors,
    });
    const { Attribute } = this.state;
    Attribute.AttributeName2 = event.target.value;
    this.setState({
      Attribute,
    });
  }

  onStatusChange(event) {
    const { Attribute } = this.state;
    Attribute.IsActive = event.target.checked;
    this.setState({
      Attribute,
    });
  }

  onAttributeName1Change(event) {
    const errors = {};
    this.setState({
      errors,
    });
    const { Attribute } = this.state;
    Attribute.AttributeName1 = event.target.value;
    this.setState({
      Attribute,
    });
  }

  passToConfirmation(data) {
    const { enteredData } = this.props;
    enteredData(data.PoNumber);
  }

  validateForm() {
    const { Attribute } = this.state;
    const errors = {};
    let formIsValid = true;

    if (!Attribute.AttributeName1.trim()) {
      formIsValid = false;
      errors.AttributeName1 = 'Attribute Name 1 is required';
    } else {
      const isValid = regexForConfMasterFields.test(Attribute.AttributeName1);
      if (!isValid) {
        formIsValid = false;
        errors.AttributeName1 = 'Attribute Name 1 contains Special characters';
      }
    }

    if (!Attribute.AttributeName2.trim()) {
      formIsValid = false;
      errors.AttributeName2 = 'Attribute Name 2 is required';
    } else {
      const isValid = regexForConfMasterFields.test(Attribute.AttributeName2);
      if (!isValid) {
        formIsValid = false;
        errors.AttributeName2 = 'Attribute Name 2 contains Special characters';
      }
    }

    if (!Attribute.OfferingId) {
      formIsValid = false;
      errors.OfferingId = 'Id is required';
    }

    this.setState({
      errors,
    });
    if (formIsValid) {
      this.setState({
        // formIsValid: true,
      });
    } else {
      this.setState({
        // formIsValid: false,
      });
    }
    return formIsValid;
  }

  render() {
    const { show, errors, Attribute } = this.state;
    const { data } = this.props;
    return (
      <>
        {/* <Header /> */}
        <Modal show={show} onHide={() => this.handleHide()} backdrop="static" className="modal fade" size="medium" id="dealpopup">
          <Modal.Header closeButton>
            {!data
              ? <h4 className="modal-title" id="dealprofile">Add Attribute</h4>
              : <h4 className="modal-title" id="dealprofile">Edit Attribute</h4>}
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label htmlFor="">
                      Attribute Name 1
                      <span className="required">*</span>
                    </label>
                    <input type="text" maxLength="20" className="form-control" id="name" onChange={this.onAttributeName1Change} value={Attribute.AttributeName1} />
                    <span className="errorMsg">{errors.AttributeName1}</span>
                  </div>
                  <div className="form-group col-lg-6">
                    <label htmlFor="">
                      Attribute Name 2
                      <span className="required">*</span>
                    </label>
                    <input type="text" maxLength="20" className="form-control" id="name" onChange={this.onAttributeName2Change} value={Attribute.AttributeName2} />
                    <span className="errorMsg">{errors.AttributeName2}</span>
                  </div>
                </div>
              </div>

              <div className="form-section">
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label className="form-check">
                      <input className="form-check-input" type="checkbox" onChange={this.onStatusChange} defaultChecked={Attribute.IsActive} />
                      <span className="checkmark" />
                      <span className="form-check-label">Is Active</span>
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-btn">
              {!data
                ? <input type="Button" className="btn btn-primary btn-sm" onClick={(e) => this.handleSave(e)} value="Create" />
                : <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value="Update" />}
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

AttributePopUp.propTypes = {
  data: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  onApproveClose: PropTypes.func.isRequired,
  onRejectClose: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  enteredData: PropTypes.number.isRequired,
  OfferingId: PropTypes.number.isRequired,
};