import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
import i18n from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { LocalApiBaseUrl, compDetails, getPrevMonth } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";

export default class DraftForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMapping: this.props.showMapping,
      showDrafts: this.props.showDrafts,
      draftInfo: [],
      isApiLoading: false,
    };
  }
  GetDraftData() {
    this.setState({
      isApiLoading: true,
    });
    axiosInstance
      .get(`${LocalApiBaseUrl}ConnectorMapping/GetOneTimeSetupDraftsData`, {
        params: { OrgEntityID: store.getState().CurrentFilter.ClientData.id },
      })

      .then((response) => {

        this.setState({
          draftInfo: response.data,
          isApiLoading: false,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
    // this.setState({
    //   draftInfo: MappingJSON,
    // });
  }
  componentDidMount() {
    this.GetDraftData();
  }

  removeDraft = (mapping, event) => {

    if (window.confirm("Are you sure you want to delete the item?")) {
      axiosInstance
        .delete(`${LocalApiBaseUrl}ConnectorMapping/DisableOneTimeSetupDraftData`, {
          params: { DealID: mapping.DealID },
        })
        .then((response) => {
          window.alert(response.data.StatusMessage);
          this.GetDraftData();
          //this.handleHide();
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };

  editDraft = (draftInfo, event) => {
    let data = this.props.childToParent("Draft", draftInfo);

    /* User will be directed to the add mapping screen with 
    synops side information pre elected as per the item sent from here*/
  };

  handleHide(e) {
    this.props.childToParent("Draft");
    this.setState({ showDrafts: false });
  }

  render() {
    const { showMapping, showDrafts } = this.state;

    return (
      <>
        <LoadingOverlay 
        active={this.state.isApiLoading} 
        spinner={<SynopsLoader />}
        // text="Please Wait..."
        >
          <Modal
            show={showDrafts}
            onHide={this.handleHide.bind(this)}
            className="modal fade draftModal"
            size="medium"
            backdrop="static"
          >
            <Modal.Header>
              <Modal.Title>Drafts</Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              {this.state.draftInfo.length !== 0 ? (
                _.map(this.state.draftInfo, (each, index) => {
                  return (
                    <div className="draftBody">
                      <div className="draftHeader">
                        <h6>{each.Project}</h6>
                        <ul>
                          <li>{each.Industry}</li>
                          <li>{each.ClientRegion}</li>
                          <li>{each.Offering}</li>
                          <li>{each.SubOffering}</li>
                          <li>{each.DeliveryRegion+"-"+each.DeliveryCenter}</li>
                        </ul>
                      </div>
                      <div className="header-info-right edit-delete-icons">
                        <span>
                          <FontAwesomeIcon icon={faEdit} onClick={this.editDraft.bind(this, each)} />
                        </span>
                        <span>
                          <FontAwesomeIcon icon={faTrashAlt} onClick={this.removeDraft.bind(this, each)} />
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="title-card">
                  <small className="form-text text-muted">
                    <b>{i18n.t("No Deals In Drafts State")}</b>
                  </small>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="default" className="btn-sm" onClick={this.handleHide.bind(this)}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </LoadingOverlay>
      </>
    );
  }
}
