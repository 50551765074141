import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import Moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import _ from 'lodash';
import i18n from 'i18next';
import $ from 'jquery';
import axiosInstance from './interceptor';
import {
  trycatchAlertPopup,
  LocalApiBaseUrl,
  DomainBaseUrl,
  ProfilePic,
  LocalApiBaseUrlImage,
  PeoplePage,
  ROUTE_CONSTANTS,
} from './Constant';
import { store } from '../reducers/configureStore';

import navbarLogo from '../Images/Synops-navbar-logo.svg';
import SynopsLoader from './SynopsLoader';
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import { screeNamesForUserTracking, logUserTrackingData } from './commonFunctions';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { acquireNewTokenSilent } from "../Authorization/AcquireTokenApi";
import Notification from './Notification';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('msal.idtoken')}`;


$(document).ready(() => {
  const trigger = $('.hamburger');
  const overlay = $('.overlay');
  let isClosed = false;
  function buttonSwitch() {
    if (isClosed === true) {
      trigger.removeClass('is-open').addClass('is-closed');
      isClosed = false;
    } else {
      trigger.removeClass('is-closed').addClass('is-open');
      isClosed = true;
    }
  }
  trigger.click(() => {
    buttonSwitch();
  });
  $('[data-toggle="offcanvas"]').click(() => {
    $('.wrapper').toggleClass('toggled');
  });
  $(document).on('click', (e) => {
    if ($(e.target).closest(".main-sidebar, [data-toggle='offcanvas']").length === 0) {
      trigger.removeClass('is-open').addClass('is-closed');
      $('.wrapper').removeClass('toggled');
      isClosed = false;
    }
  });
});

const samp = {};
class LandingHeader extends Component {
  constructor() {
    super();
    this.state = {
      CityOptions: [],
      ProfileSrc: '',
      newNotificationCount: 0,
      ShowMessage: true,
      userName: '',
      date: null,
      isVisibleNotification: false,
      isAnyNewNotification: false,
    };
    this.OrgEntityImage = '';
  }

  GetProfilePic() {
    const EnterpriseID = sessionStorage.getItem("EnterpriseID")
    const profilePicToken = sessionStorage.getItem("profilePicToken")

    axiosInstance.post(`${LocalApiBaseUrl}User/DownloadPeopleImage`, { enterpriseId: EnterpriseID,profilePicToken:profilePicToken })
    .then((response) => {
      const ProfileSrc = `data:image/jpeg;base64,${response.data}`;
      this.setState({ ProfileSrc });
    })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  componentDidMount() {
    this.GetProfilePic();
    const { notificationData } = this.props;
    this.setState({
      isAnyNewNotification: notificationData.newNotificationsData.hasNewNotification,
      newNotificationCount: notificationData.newNotificationsData.notificationCount,
    });
  }

  componentDidUpdate(prevProps) {
    const { notificationData } = this.props;
    if (prevProps.notificationData !== notificationData) {
      this.setState({
        isAnyNewNotification: notificationData.newNotificationsData.hasNewNotification,
        newNotificationCount: notificationData.newNotificationsData.notificationCount,
      });
    }
  }

  GetOffering() {
    const samp = {};
    axiosInstance
      .get(`${LocalApiBaseUrl}Offering/GetSynopsStoryOfferings`, {
        params: { LanguageID: store.getState().CurrentFilter.languageData.id },
      })
      .then((response) => {
        const responseData = response.data;

        const groupOfferingOptions = [];
        _.each(responseData, (item) => {
          const newArray = {
            Index: '',
            Offering: '',
            Logo: '',
            IsDemo: false,
            SubOfferingCount: '',
            DemoSubOfferingCount: '',
            NonDemoSubOfferingCount: '',
            Data: [],
          };
          newArray.Index = item.OfferingID;
          newArray.Offering = item.OfferingName;
          newArray.Logo = _.first(
            item.ChildOfferings.map((data) => data.OfferingImage).filter(Boolean),
          );
          newArray.IsDemo = Array.isArray(
            item.ChildOfferings.map((data) => data.InvisionAppLink).filter(Boolean),
          )
            && item.ChildOfferings.map((data) => data.InvisionAppLink).filter(Boolean).length;
          newArray.SubOfferingCount = item.ChildOfferings.length;
          newArray.DemoSubOfferingCount = item.ChildOfferings.map((data) => data.InvisionAppLink).filter(Boolean).length;
          newArray.NonDemoSubOfferingCount = item.ChildOfferings.length
            - item.ChildOfferings.map((data) => data.InvisionAppLink).filter(Boolean).length;
          newArray.Data = item.ChildOfferings;
          groupOfferingOptions.push(newArray);
        });
        this.setState({ OfferingOptions: response.data, GroupOfferingOptions: groupOfferingOptions });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  onClientClick = async() => {
    if (this.state.CityOptions.length == 0) {
      var oldToken = sessionStorage.getItem(idTokenstring);
      var oldBase64Url = oldToken.split('.')[1];
      var oldBase64 = oldBase64Url.replace('-', '+').replace('_', '/');
      var tokendetails = JSON.parse(window.atob(oldBase64));
    var expiry = tokendetails["exp"];
    var expTime = new Date(expiry*1000);
    var currentTime = new Date();
    if (currentTime > expTime) {  
      if(isDevEnvironment){
        alert("Token Expired!! please get the new token")//this message will throw only in case of Local Dev Environment
        }
        else{
        await acquireNewTokenSilent(true);
        }//Acquiring new token and setting to the authorization headers
    }
    }
    // Reset the Synops Sync Filters
  }


  handleClose = () => {
    this.setState({
      ShowMessage: false,
    });
  };

  // NOTIFICATION HANDLE CHANGE //

  handleCallbackNotificationHide = () => {
    this.setState({
      isVisibleNotification: false,
    });
  };

  showNotification = () => {
    this.setState({ isVisibleNotification: true });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.headerText === '') {
      if (nextProps.OperatingGropus.length != 0) {
        const CityOptions = [];
        const clientList = nextProps.OperatingGropus.map((client) => {
          samp[client.OrgEntityID] = client.OrgEntityImage;
          return CityOptions.push(<option value={client.OrgEntityID}>{client.OrgEntityName}</option>);
        });

        if (this.props.OperatingGropus.length == 0) {
          const userName = nextProps.OperatingGropus[0].UserName;
          let date = null;
          if (nextProps.OperatingGropus[0].lastLoginTime != '0001-01-01T00:00:00') {
            date = nextProps.OperatingGropus[0].lastLoginTime;
          }
          this.setState({ userName, date });
        }
        setTimeout(() => this.setState({ ShowMessage: false }), 15000);
        this.setState({ CityOptions });
      }
    } else {
      this.setState({ headerText: nextProps.headerText });
    }
  }

  matchCleintsToTerm(state, value) {
    return (
      state.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      || state.abbr.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  sortClients(a, b, value) {
    const aLower = a.name.toLowerCase();
    const bLower = b.name.toLowerCase();
    const valueLower = value.toLowerCase();
    const queryPosA = aLower.indexOf(valueLower);
    const queryPosB = bLower.indexOf(valueLower);
    if (queryPosA !== queryPosB) {
      return queryPosA - queryPosB;
    }
    return aLower < bLower ? -1 : 1;
  }

  onSelectHandle = (event, value) => {
    this.props.selectedFilter(value);
    this.OrgEntityImage = value.id === '0' ? '' : samp[value.id] || '';
    this.setState({ selvalue: value.id });
    store.dispatch({
      type: 'ADDHEADER',
      payload: event,
    });

    const data = store.getState();
    const iiDetails = data.intelligentInsightReducers.insightandintelligence;
  };

  onChangeHandle = (event, value) => {
    if (value != null) {
      const cleintId = 0;
      this.props.selectedFilter(event);
      this.OrgEntityImage = cleintId === '0' ? '' : samp[cleintId] || '';
      this.setState({ selvalue: cleintId });
    }
  };

  redirectbase() {
    window.location.href = DomainBaseUrl;
  }

  // EVENT HANDLER FOR "Benchmark Analytics (BAT)" MENU LINK CLICK. WILL EXECUTE THE API TO LOG USER TRACKING DATA
  handleClickBATMenuLink = () => {
    const { languageData } = this.props;
    const data = {
      PageName: screeNamesForUserTracking.BAT_LANDING_SCREEN,
      Language: languageData.langCode,
    };
    logUserTrackingData(data);
  };

  render() {
    const { isVisibleNotification, isAnyNewNotification, newNotificationCount } = this.state;
    const arr = [];
    const s = this.state.CityOptions.map((cl) => {
      arr.push({ name: cl.props.children, id: cl.props.value, abbr: cl.props.children.substr(0, 2) });
    });
    return (
      <>
        <LoadingOverlay active={this.props.isorgapiloading} spinner={<SynopsLoader />}>
          {this.state.ShowMessage && this.state.userName != '' && this.state.date != null ? (
            <div className="welcome-message">
              <div className="message-data">
                <p>
                  <span>
                    Welcome
                    {' '}
                    {this.state.userName}
                    ,
                  </span>
                  {' '}
                  Last login:
                  {' '}
                  {''}
                  {Moment.utc(this.state.date)
                    .local()
                    .format('MMMM Do YYYY, h:mm A')}
                </p>
                <div className="message-close">
                  <span>
                    <i className="far fa-times" onClick={this.handleClose}>
                      {' '}
                    </i>
                  </span>
                </div>
              </div>
            </div>
          ) : null}
          <nav className="navbar navbar-expand-lg fixed-top navbar-default landingNav navbarBat newBanner">
            <NavLink to="/HomePage" className="navbar-brand">
              <img
                src={navbarLogo}
                alt="Synops-logo"
                width="24"
                height="24"
              />
              <h1>SynOps</h1>
            </NavLink>
            <ul className="navbar-nav search-nav mr-auto">
              <li className="nav-item">
                <NavLink to="/clientselect" className="nav-link">
                  Clients
                </NavLink>
              </li>
              <li className="nav-item" onClick={() => this.handleClickBATMenuLink()}>
                <NavLink to={`/${ROUTE_CONSTANTS.BAT_LANDING_PAGE}`} className="nav-link">
                  {i18n.t('Benchmark_Analytics')}
                </NavLink>
              </li>
              <li className="nav-item"><a className="nav-link" href="https://in.accenture.com/accentureoperations/sell-business-process-services/" target="_blank">{i18n.t('About_SynOps')}</a></li>
            </ul>
            <ul className="navbar-nav flex-row search-nav story-nav">
              <li className="nav-item mr-4">
                <a onClick={this.showNotification} className="nav-link notification-link">
                  <span className={isAnyNewNotification ? '' : 'active-notification'}>
                    {isAnyNewNotification && <span className="notification-count">{newNotificationCount > 9 ? '9+' : newNotificationCount}</span>}
                    <i className="fal fa-bell" /></span>
                </a>

              </li>
              <li className="nav-item logged-img" style={{ marginRight: '20px' }}>
                <Link to={`${PeoplePage}${sessionStorage.getItem('EnterpriseID')}`} target="_blank" className="nav-link">
                  <span className="profileImg">
                    <img src={this.state.ProfileSrc} alt="" width="24" height="24" />
                  </span>
                </Link>
              </li>
            </ul>
          </nav>
          {isVisibleNotification ? (
            <Notification
              show={isVisibleNotification}
              onHide={() => this.handleCallbackNotificationHide()}
            />
          ) : null}
        </LoadingOverlay>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  languageData: state.CurrentFilter.languageData,
  clientData: state.CurrentFilter.ClientData,
  notificationData: { ...state.NotificationReducer },
});

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true },
)(LandingHeader);
