import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { Line } from "react-chartjs-2";
import InputRange from "react-input-range";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import Chart from "chart.js";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Modal } from "react-bootstrap";
import { faArrowDown, faArrowUp, faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  LocalApiBaseUrl,
  regexForNegativeNumbers,
  trycatchAlertPopup,
  formatDate,
  BATDateFormat,
} from "../Shared/Constant";
import "chartjs-plugin-datalabels";
import { store } from "../reducers/configureStore";
import "chartjs-plugin-annotation";
import axiosInstance from "../Shared/interceptor";

import CrossMetricAnalysis from "./CrossMetricAnalysis";
import MetricDeepDive from "./MetricDeepDive";
import Autocomplete from "react-autocomplete";
import i18n from "i18next";
import InsightsChatWindow from "./InsightsChatWindow";
import HideChat from "../Images/hide-chat.svg";
import ShowChat from "../Images/show-chat.svg";
import MetricValueIcon from "../Images/MetricValue-Border.svg";
import MetricValueIconPurple from "../Images/MetricValue-Purple.svg";
import LowerQuartileIcon from "../Images/LowerQuartile-Border.svg";
import MedianIcon from "../Images/Median-Border.svg";
import UpperQuartileIcon from "../Images/UpperQuartile-Border.svg";
import BaselineIcon from "../Images/Baseline-Border.svg";
import TargetIcon from "../Images/Target-Border.svg";
import LoadingOverlay from "react-loading-overlay";
import MindMap from "./MindMap";
import "../CSS/mindmap.css";
import "../CSS/rangeslider.css";
import SynopsLoader from "../Shared/SynopsLoader";
import { dateFormatMethod2, truncateTwoDecimal } from "../Shared/commonFunctions";
import { forNum } from "../Shared/Common";
import _, { forEach } from "lodash";
import moment from "moment";
import BoiTrendsWrapper from "./BoiTrendsWrapper";

import { MindmapClick } from "../actions/mindMapAction";
import { event } from "jquery";
import { ClientAndFilterReducer } from "../reducers/ClientAndFilterReducer";

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(
  "msal.idtoken"
)}`;

const Loader = () => <>Loading...</>;
const randomItem = [
  "BlueViolet",
  "#FFD700",
  "DarkTurquoise",
  "DodgerBlue",
  "DarkOrange",
  "#7ec344",
  "#ff6384",
  "#8B0000",
  "#228B22",
  "#ADD8E6",
  "#20B2AA",
  "#008080",
];
class TrendGraphs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      responseClone: [],
      compareToggleList: [],
      rangeValue: null,
      rangeValues: {
        min: null,
        max: null,
      },
      sliderValues: {
        min: null,
        max: null,
      },
      isToggleOn: false,
      showErrorMsg: false,
      newUpdatedDsKpiData2: [],
      monthComment: [""],
      UpperThresholdMean: [],
      LowerThresholdMean: [],
      IndustryOptions: [],
      BaselineMean: [],
      TargetMean: [],
      show: this.props.show,
      temp: [],
      dsKpiData: [],
      dsKpiData2: [],
      newDsKpiData2: [],
      labels: [],
      trendAvg: 0,
      similarAvg: 0,
      maxMetricValue: 0,
      minMetrixValue: 0,
      OfferingName: "",
      EntityName: [],
      DealValue: [],
      value: 0,
      ProcessName: "",
      dealOnelabel: "",
      dealOnevalue: "",
      dealTwolabel: "",
      dealTwovalue: "",
      stoldDealOneValue: 0,
      stoldDealTwoValue: 0,
      percentageChangeDealOne: 0,
      percentageChangeDealTwo: 0,
      diffdeal1: 0,
      diffdeal2: 0,
      autoCompleteDdlClientsList: [],
      selValueDdl: "",
      dealTwoDataPoints: [],
      dealOneDataPoints: [],
      numMonths: 0,
      IndustryData: {
        IndustryID: null,
        IndustryName: null,
      },
      dealComparisonGraphData: {
        labels: [],
        datasets: [],
      },
      operatingGroup: null,
      IndustryGroup: null,
      clientRegion: null,
      deliveryLocation: null,
      maturityPhase: null,
      filter: {
        og: "Cross Industry",
        dl: "All",
        mp: "All",
        io: "All",
      },
      startDate: "",
      endDate: "",

      period: { from: "", to: "" },
      wholedata: null,
      filtereddata: null,
      loading: false,
      ShowDeals: false,
      isDisabled: false,
      showComments: true,
      strikedLegentData: [],
      isApiLoading: false,
      isMetricOn: true,
      isLowerOn: true,
      isMedianOn: true,
      isUpperOn: true,
      isBaselineOn: true,
      isTargetOn: true,
      backgroundColorValues: [""],
      baselineMonthValue: [],
      metricBorderColorValues: [""],
      newNotificationList: [],
      fullmonthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      showEditData: false,
      hasEditAcess: true,
    };

    //   percentage increase or decrease calculation will always happen keeping the first data point(s) of each line graph as the reference- hardcoding stoldDealOneValue and stoldDealTwoValue for now

    // TO DO: Try to access the state object inside the tooltip's label callback without binding the callback to the global execution context using .bind(this) - line 551
    // OR write JavaScript code inside the afterDraw plugin function where we have written the code to show the dotted black vertical line on hovering on the data points - line 441

    this.handleHide = this.handleHide.bind(this);
    this.matchClientsToSearchTerm = this.matchClientsToSearchTerm.bind(this);
    this.sortClients = this.sortClients.bind(this);
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.onSelectHandle = this.onSelectHandle.bind(this);
    this.ConvertFormat = this.ConvertFormat.bind(this);
    this.DoesDateFallWithin = this.DoesDateFallWithin.bind(this);
    this.setNumMonths = this.setNumMonths.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.applayFilterone = this.applayFilterone.bind(this);
    this.applayFiltertwo = this.applayFiltertwo.bind(this);
    // this.applayFilterthree = this.applayFilterthree.bind(this);
    this.applayFilter = this.applayFilter.bind(this);
    this.onShowDealChange = this.onShowDealChange.bind(this);
    this.onToggleCompareChange = this.onToggleCompareChange.bind(this);
    this.onShowHideButtonClick = this.onShowHideButtonClick.bind(this);
    this.getKpiData = this.getKpiData.bind(this);
    this.onLegendClick = this.onLegendClick.bind(this);
    this.MetricClick = this.MetricClick.bind(this);
    this.LowerClick = this.LowerClick.bind(this);
    this.MedianClick = this.MedianClick.bind(this);
    this.UpperClick = this.UpperClick.bind(this);
    this.BaselineClick = this.BaselineClick.bind(this);
    this.TargetClick = this.TargetClick.bind(this);
    this.GetNotifications = this.GetNotifications.bind(this);

    this.dataFirst = {
      label: "", // this.state.dealOnelabel,
      data: [],
      lineTension: 0,
      fill: false,
      borderColor: "#1d2b36",
    };
    this.GetDealComparisonAPIResponse = [];
    this.dataSecond = {
      label: "", // this.state.dealTwolabel,
      data: [],
      lineTension: 0,
      fill: false,
      borderColor: "#00baff",
    };
  }

  setFilter(type, e) {
    const { filter } = this.state;
    if (type === "og") {
      this.setState(
        {
          ...this.state,
          loading: true,
          filter: { ...filter, og: e.target.value },
        },
        () => this.applayFilterone()
      );
    }
    if (type === "dl") {
      this.setState(
        {
          ...this.state,
          loading: true,
          filter: { ...filter, dl: e.target.value },
        },
        () => this.applayFilterone()
      );
    }
    if (type === "mp") {
      this.setState(
        {
          ...this.state,
          loading: true,
          filter: { ...filter, mp: e.target.value },
        },
        () => this.applayFilterone()
      );
    }
  }

  applayFilterone() {
    const { filter, wholedata, responseClone } = this.state;
    const ogValue = filter.og;
    let newwholedata = wholedata;
    let newResponseClone = [];
    let responseLength = 0;
    if (ogValue === "Cross Industry") {
      for (let j = 1; j < newwholedata.length; j++) {
        newwholedata[j].DealName = `Client ${j}`;
      }
      this.setState({ ...this.state, filtereddata: newwholedata }, () => this.applayFiltertwo());
    } else {
      newwholedata = responseClone.filter((element) => {
        let returnValue = false;

        if (element.Industry === ogValue) {
          returnValue = true;
          return element;
        }
        return returnValue;
      });

      if (newwholedata.length > 6) {
        responseLength = 6;
      } else {
        responseLength = newwholedata.length;
      }
      newwholedata = newwholedata.splice(0, responseLength);

      for (let k = 1; k < newwholedata.length; k++) {
        newwholedata[k].DealName = `Client ${k}`;
      }

      this.setState({ ...this.state, filtereddata: newwholedata }, () => this.applayFiltertwo());
    }
  }

  applayFiltertwo() {
    const { filter, filtereddata } = this.state;
    const mpValue = filter.mp;
    let wholedata = filtereddata;
    if (mpValue === "All") {
      this.setState({ ...this.state, filtereddata: wholedata }, () => this.applayFilter());
    } else {
      wholedata = wholedata.filter((element) => {
        let returnValue = false;
        if (element.MaturityPhase === mpValue) {
          returnValue = true;
          return element;
        }
        return returnValue;
      });
      this.setState({ ...this.state, filtereddata: wholedata }, () => this.applayFilter());
    }
  }

  applayFilter() {
    const { filtereddata, labels, ShowDeals } = this.state;
    const wholedata = filtereddata;
    const datasets = [];

    if (ShowDeals) {
      wholedata.forEach((element, i) => {
        datasets.push({
          label: element.EntityName,
          lineTension: 0.4,
          borderColor: randomItem[i],
          backgroundColor: randomItem[i],
          fill: false,
          borderCapStyle: "square",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          City: element.City,
          DealID: element.DealID,
          EntityName: element.EntityName,
          Offering: element.Offering,
          Industry: element.Industry,
          ProcessName: element.Process,
          pointBorderColor: randomItem[i],
          pointBackgroundColor: "white",
          pointBorderWidth: 3,
          pointHoverRadius: 8,
          pointHoverBackgroundColor: randomItem[i],
          pointHoverBorderColor: randomItem[i],
          pointHoverBorderWidth: 2,
          pointRadius: 5,
          pointHitRadius: 2,
          data: [null, ...element.MetricValue],
          spanGaps: true,
          showLineValues: false,
        });
      });
    } else {
      wholedata.forEach((element, i) => {
        datasets.push({
          label: element.DealName,
          lineTension: 0.4,
          borderColor: randomItem[i],
          backgroundColor: randomItem[i],
          fill: false,
          borderCapStyle: "square",
          borderDash: [], // try [5, 15] for instance
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          City: element.City,
          DealID: element.DealID,
          EntityName: element.EntityName,
          Offering: element.Offering,
          Industry: element.Industry,
          ProcessName: element.Process,
          pointBorderColor: randomItem[i],
          pointBackgroundColor: "white",
          pointBorderWidth: 3,
          pointHoverRadius: 8,
          pointHoverBackgroundColor: randomItem[i],
          pointHoverBorderColor: randomItem[i],
          pointHoverBorderWidth: 2,
          pointRadius: 5,
          pointHitRadius: 2,
          data: [null, ...element.MetricValue],
          spanGaps: true,
          showLineValues: false,
        });
      });
    }
   

    this.setState({
      loading: false,
      dsKpiData2: {
        labels, // we will get months from the api
        datasets,
      },
      newDsKpiData2: {
        labels,
        datasets,
      },
    });
  }

  getMaturityPhase() {
    let sourceMaturityPhaseValues = [];
    axiosInstance
      .get(`${LocalApiBaseUrl}DealOnboarding/GetMaturityPhase`, null, {
        params: {},
      })
      .then((res) => {
        res.data.forEach((element) => {
          sourceMaturityPhaseValues.push(element.PhaseName);
        });
        sourceMaturityPhaseValues = [...new Set(sourceMaturityPhaseValues)];
        this.setState({ maturityPhase: sourceMaturityPhaseValues });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  onPeriodChange = (val, e) => {

   
    const { filter } = this.state;
    filter.og = "Cross Industry";
    // filter.cr = 'All';
    filter.mp = "All";
    const industryUpdate = filter.og;
    // const crUpdate = filter.cr;
    const mpUpdate = filter.mp;
    if (val === "From" && e) {
      let from = new Date(e);
      let fromdate =
        from.getFullYear() +
        "-" +
        (from.getMonth() + 1 < 10 ? "0" + (from.getMonth() + 1) : from.getMonth() + 1);
      let to = from.setMonth(from.getMonth() + 5);
      let toDate =
        new Date(to).getFullYear() +
        "-" +
        (new Date(to).getMonth() + 1 < 10
          ? "0" + (new Date(to).getMonth() + 1)
          : new Date(to).getMonth() + 1);
      this.setState({
        og: industryUpdate,
        // cr: crUpdate,
        mp: mpUpdate,
        startDate: fromdate,
        endDate: toDate,
      });
      this.forceUpdate();

      this.GetAnalyticsApi(fromdate, toDate);

    } else if (val === "To" && e) {
      const to = new Date(e);
      const toDate = `${to.getFullYear()}-${
        to.getMonth() + 1 < 10 ? `0${to.getMonth() + 1}` : to.getMonth() + 1
      }`;
      const From = to.setMonth(to.getMonth() - 5);
      const fromDate = `${new Date(From).getFullYear()}-${
        new Date(From).getMonth() + 1 < 10
          ? `0${new Date(From).getMonth() + 1}`
          : new Date(From).getMonth() + 1
      }`;
    
      this.setState({

        og: industryUpdate,
        // cr: crUpdate,
        mp: mpUpdate,
        startDate: fromDate,
        endDate: toDate,
      });

      this.forceUpdate();

      this.GetAnalyticsApi(fromDate, toDate);
      
    }
      let disable = this.state.dsKpiData2.length>0? false: true;
  
      this.setState({
        isDisabled:disable,
        dsKpiData2:this.state.dsKpiData2,
      });

  };

  setNumMonths(parameter) {
    const { numMonths } = this.state;
    let numericPartOfTabId = Number(parameter[0] + parameter[1]);

    if (isNaN(numericPartOfTabId)) {
      numericPartOfTabId = Number(parameter[0]);
      if (isNaN(numericPartOfTabId)) {
        // setting default value 0 for max tab
        numericPartOfTabId = -1;
      }
    }

    this.setState({ numMonths: numericPartOfTabId }, () => {
      const SomeMonthsAgo = this.SomeMonthsPrior(new Date(), numMonths || 3);
      const GetDealComparisonAPIResponseCopy = this.GetDealComparisonAPIResponse;

      const labelArray = [];
      const thisCopy = this;
      const todaysDate = new Date(); // today's date as date obj
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      for (let index = 0; index < this.GetDealComparisonAPIResponse.length; index++) {
        const dataPointsArr = [];

        GetDealComparisonAPIResponseCopy[index].Data.forEach((arrayItem) => {
          const x = Number(arrayItem.MetricValue);

          const dateFromDbAsDateObj = new Date(arrayItem.MonthDT);

          if (SomeMonthsAgo > new Date()) {
            // max tab has been chosen. All the api response metric values need to be shown
            dataPointsArr.push(x);
            const arElt = monthNames[dateFromDbAsDateObj.getMonth()];
            labelArray.push(arElt);
          } else {
            const isDateWithinRange = thisCopy.DoesDateFallWithin(
              SomeMonthsAgo,
              todaysDate,
              dateFromDbAsDateObj
            );
            const monthName = monthNames[dateFromDbAsDateObj.getMonth()];
            const yearName = dateFromDbAsDateObj.getFullYear();
            if (isDateWithinRange) {
              dataPointsArr.push(x);
              if (labelArray.indexOf(monthName) === -1) labelArray.push(`${monthName} ${yearName}`);
            }
          }

          if (index === 0) {
            thisCopy.dataFirst.data = dataPointsArr;
            if (dataPointsArr.length > 0) {
              thisCopy.setState({
                stoldDealOneValue: dataPointsArr[0],
              });
            }

            thisCopy.dataFirst.label = thisCopy.GetDealComparisonAPIResponse[0].ClientName;
          } else {
            thisCopy.dataSecond.data = dataPointsArr;
            if (dataPointsArr.length > 0) {
              thisCopy.setState({
                stoldDealTwoValue: dataPointsArr[0],
              });
            }

            thisCopy.dataSecond.label = thisCopy.GetDealComparisonAPIResponse[1].ClientName;
          }
        });
      }

      thisCopy.setState({
        dealComparisonGraphData: {
          datasets: [thisCopy.dataFirst, thisCopy.dataSecond],
          labels: labelArray,
        },
        dealOnelabel: thisCopy.GetDealComparisonAPIResponse[0]
          ? thisCopy.GetDealComparisonAPIResponse[0].ClientName
          : "",
        dealTwolabel: thisCopy.GetDealComparisonAPIResponse[1]
          ? thisCopy.GetDealComparisonAPIResponse[1].ClientName
          : "",
      });
    }); // end of the setstate async call
  }

  handleHide() {
    this.setState({ show: false });
    this.props.onClose();
  }

  matchClientsToSearchTerm(state, value) {
    return (
      state.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
      state.abbr.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  sortClients(a, b, value) {
    const aLower = a.name.toLowerCase();
    const bLower = b.name.toLowerCase();
    const valueLower = value.toLowerCase();
    const queryPosA = aLower.indexOf(valueLower);
    const queryPosB = bLower.indexOf(valueLower);
    if (queryPosA !== queryPosB) {
      return queryPosA - queryPosB;
    }
    return aLower < bLower ? -1 : 1;
  }

  onChangeHandle = (event, value, selValueDdlParam) => {
    const { subOfferingIdSelected } = this.props;
    this.setState({ isApiLoading: true });
    axiosInstance
      .get(`${LocalApiBaseUrl}Report/GetAutoDeal`, {
        params: {
          OfferingID: subOfferingIdSelected,
          clientname: selValueDdlParam,
        },
      })
      .then((response) => {
        const mappingData = response.data;
        this.setState({
          isApiLoading: false,
          autoCompleteDdlClientsList: mappingData,
        });
      })
      .catch((error) => {
        this.setState({ isApiLoading: false });
        trycatchAlertPopup(error);
      });
  };

  DoesDateFallWithin(FromdateSomeMonthsAgo, dateToCurrentDate, dateFromDbToCheck) {
    if (
      (FromdateSomeMonthsAgo <= dateFromDbToCheck ||
        (String(FromdateSomeMonthsAgo.getMonth()) === String(dateFromDbToCheck.getMonth()) &&
          String(FromdateSomeMonthsAgo.getYear()) === String(dateFromDbToCheck.getYear()))) &&
      dateFromDbToCheck < dateToCurrentDate &&
      dateFromDbToCheck.getMonth() < dateToCurrentDate.getMonth()
    )
      return true;
    return false;
  }

  ConvertFormat(datefromDb) {
    const d1 = datefromDb.split("-");

    const dateObj = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11

    return dateObj;
  }

  SomeMonthsPrior(theorginalDate, numMonths) {
    if (numMonths < 0) {
      return theorginalDate.setDate(theorginalDate.getDate() + 1);
    }
    const someMonthsAgo = new Date(theorginalDate);
    const originalMonth = theorginalDate.getMonth();

    someMonthsAgo.setMonth(theorginalDate.getMonth() - numMonths);
    const diff = (originalMonth + 12 - someMonthsAgo.getMonth()) % 12;
    if (diff < numMonths) someMonthsAgo.setDate(0);

    return someMonthsAgo;
    // returns the some months prior date in Date object format
  }

  onSelectHandle = (event, value) => {
    const { IdOfclientNameSelected, kpiMappingId, subOfferingIdSelected } = this.props;
    const { numMonths } = this.state;
    const SomeMonthsAgo = this.SomeMonthsPrior(new Date(), numMonths || 3); // x months ago date as Date obj

    const todaysDate = new Date(); // today's date as date obj

    this.setState({
      selValueDdl: value.name,
      isApiLoading: true,
    });

    const that = this;

    const selectedDeal = value.id;
    const orgEntityIdsStringArray = [];
    orgEntityIdsStringArray.push(IdOfclientNameSelected.toString());

    orgEntityIdsStringArray.push(selectedDeal.toString());

    axiosInstance
      .get(`${LocalApiBaseUrl}Analytics/GetDealComparisionData`, {
        params: {
          kpiMappingID: kpiMappingId,
          offeringID: subOfferingIdSelected,
          orgEntityIDs: orgEntityIdsStringArray.toString(),
        },
      })
      .then((response) => {
        that.GetDealComparisonAPIResponse = response.data;
        const APIresponse = response.data;

        const labelArray = [];
        for (let index = 0; index < APIresponse.length; index++) {
          const filteredDataPoints = [];

          const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          APIresponse[index].Data.forEach((arrayItem) => {
            const x = Number(arrayItem.MetricValue);

            const dateFromDbAsDateObj = new Date(arrayItem.MonthDT);
            if (SomeMonthsAgo > new Date()) {
              // max tab has been chosen. All the api response metric values need to be shown
              filteredDataPoints.push(x);
              const arElt = monthNames[dateFromDbAsDateObj.getMonth()];
              var yearName = dateFromDbAsDateObj.getFullYear();

              labelArray.push(`${arElt} ${yearName}`);
            } else {
              // filter and find the metric values which have MonthDT within 3,6 or 12 months
              const isDateWithinRange = that.DoesDateFallWithin(
                SomeMonthsAgo,
                todaysDate,
                dateFromDbAsDateObj
              );

              if (isDateWithinRange) {
                filteredDataPoints.push(x);
                const arElt = monthNames[dateFromDbAsDateObj.getMonth()];
                labelArray.push(`${arElt} ${yearName}`);
              }
            }

            if (index === 0) {
              that.dataFirst.data = filteredDataPoints;
              that.dataFirst.label = APIresponse[0].ClientName;
            } else {
              that.dataSecond.data = filteredDataPoints;
              that.dataSecond.label = APIresponse[1].ClientName;
            }
          });
        }

        that.setState({
          dealComparisonGraphData: {
            datasets: [that.dataFirst, that.dataSecond],
            labels: labelArray,
          },
          dealOnelabel: APIresponse[0].ClientName,
          dealTwolabel: APIresponse[1] ? APIresponse[1].ClientName : "",
          isApiLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ isApiLoading: false });
        trycatchAlertPopup(error);
      });
  };

  GetAnalyticsApi(Start, end) {
    const { ShowDeals } = this.state;
    const { FilterDetails } = this.props;
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthNamesNumbers = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    const splitfisYear = FilterDetails.Month.split("-");
    const fisMonth = splitfisYear[0];
    const fisYear = splitfisYear[1];
    const monthIndexLessThanTen = monthNames.indexOf(fisMonth.substring(0, 3)) + 1;
    const monthIndex =
      monthIndexLessThanTen < 10 ? `0${monthIndexLessThanTen}` : monthIndexLessThanTen;
    const nullArray = [];
    let sortedNullArray = [];
    let minVal = 0;
    let maxVal = 0;
    let minClone = 0;
    let maxClone = 0;
    let arrDealClone = [];
    let arrDealClone_Avg = [];

    const arrDeal = [];
    let metricCompareValues = [];
    let responseIndustryClone = [];
    let optionone = [];
    let sortedCompareValues = 0;
    let Value = [];
    let sum = 0;
    let avg1 = 0;
    let avg = [];

    let ind = 0;
    let ind7 = 1;
    let newInd = 0;
    let compareToggleSlice = [];
    this.setState({ isApiLoading: true });
    axiosInstance
      // .get(`${LocalApiBaseUrl}Analytics/GetAnalyticComparisonData`, {
      .get(`${LocalApiBaseUrl}Analytics/GetCrossDeal`, {
        params: {
          kpiMappingID: this.props.kpiMappingId,
          MetricID: this.props.info.MetricID, // New param for CrossDeal
          StartDate: Start,
          EndDate: end,
          SelectedDate: `${fisYear}-${monthIndex}`, // New param for CrossDeal
          LanguageID: store.getState().CurrentFilter.languageData.id,
        },
      })
      .then((res) => {

        if (res.data.length <= 0) {
          this.setState({
            newDsKpiData2: {
              labels:[],
              datasets:[],
            },
            dsKpiData2: {
              labels:[],
              datasets:[],
            },
            showErrorMsg: true,
          });

        }
        var newList = [];
        const len = res.data.length;
        //To find the min and max metric values among all the clients
        for (let i = 0; i < len; i++) {
          for (let j = 0; j < res.data[i].MetricValue.length; j++) {
            {
              nullArray[i * 6 + j] = res.data[i].MetricValue[j];
            }
          }
        }

        sortedNullArray = nullArray.filter(function (e) {
          return e !== null;
        });

        for (let i = 0; i < sortedNullArray.length - 1; i++) {
          for (let z = 0; z < sortedNullArray.length - 1; z++) {
            if (parseFloat(sortedNullArray[z]) > parseFloat(sortedNullArray[z + 1])) {
              sortedCompareValues = sortedNullArray[z];
              sortedNullArray[z] = sortedNullArray[z + 1];
              sortedNullArray[z + 1] = sortedCompareValues;
            }
          }
        }

        minVal = sortedNullArray[0];
        maxVal = sortedNullArray[sortedNullArray.length - 1];
        for (let i = 0; i < len; i++) {
          if (res.data[i].DealName.includes("Deal")) {
            arrDeal[ind] = res.data[i];
            ind++;
          }
        }

        //cloned res.data into arrDeal >> arrDealClone
        arrDealClone = _.cloneDeep(arrDeal);
        arrDealClone_Avg = _.cloneDeep(arrDeal);

        for (let i = 0; i < len; i++) {
          if (res.data[i].DealName === res.data[i].EntityName) {
            res.data[0] = res.data[i];
          }
        }

        let count = 0;

        for (let i = 0; i < arrDealClone_Avg.length; i++) {
          for (let j = 0; j < arrDealClone_Avg[i].MetricValue.length; j++) {
            sum += Number(arrDealClone_Avg[i].MetricValue[j]);
            if (arrDealClone_Avg[i].MetricValue[j] !== null) count++;
          }

          avg1 = sum / count;
          avg.push(avg1);
          sum = 0;
          count = 0;
          arrDealClone_Avg[i].MetricValue = avg[i];
        }

        arrDealClone_Avg.sort((a, b) => {
          return a.MetricValue - b.MetricValue;
        });

        arrDealClone_Avg = arrDealClone_Avg.map((a) => {
          return {
            ...a,
            MetricValue: arrDeal.find((x) => x.DealID === a.DealID).MetricValue,
          };
        });

        for (let i = 1; i < len; i++) {
          for (let y = 0; y < arrDealClone_Avg.length; y++) {
            if (arrDealClone_Avg[i - 1].DealID === arrDeal[y].DealID) {
              res.data[i] = arrDeal[y];
              res.data[i].DealName = `Client ${i}`;
            }
          }
        }
        responseIndustryClone[0] = res.data[0];

        for (let yy = 1; yy < res.data.length; yy++) {
          if (res.data[0].Industry === res.data[yy].Industry) {
            responseIndustryClone[ind7] = res.data[yy];
            // responseIndustryClone[ind7].DealName = `Client ${ind7}`;
            ind7++;
          }
        }
        if (arrDealClone_Avg.length > 6) {
          for (let gg = 6; gg < arrDealClone_Avg.length; gg++) {
            if (res.data[0].DealName !== res.data[gg].EntityName) {
              compareToggleSlice[newInd] = res.data[gg];
              newInd++;
            }
          }
        }

        if (res.data[0].Practice == "Maximum") {
          if (arrDealClone_Avg.length >= 6) {
            res.data1 = arrDealClone_Avg.reverse();
            res.data2 = res.data1.slice(0, 5);

            for (let y = 0; y < res.data2.length; y++) {
              res.data2[y].DealName = `Client ${y + 1}`;
            }

            res.data2.reverse();
            res.data = res.data2.concat(res.data[0]);
            res.data.reverse();
          } else {
            res.data = res.data.splice(0, arrDealClone_Avg.length + 1);
          }
        } else {
          if (arrDealClone_Avg.length >= 6) {
            res.data1 = arrDealClone_Avg.slice(0, 5);
            for (let y = 0; y < res.data1.length; y++) {
              res.data1[y].DealName = `Client ${y}`;
            }
            res.data1.reverse();

            res.data = [res.data[0], ...res.data1];
          } else {
            res.data = res.data.slice(0, arrDealClone_Avg.length + 1);
          }
        }

        let labels = [];
        const datasets = [];
        const DealName = [];
        const EntityName = [];
        const MetricValue = [];
        const value = [];
        //const CumulativeValue = [];
        let OfferingName = "";
        let ProcessName = "";
        let optionthree = [];

        if (ShowDeals) {
          res.data.forEach((element, i) => {
            labels = ["", ...res.data[0].PeriodDT];

            DealName.push(element.DealName);
            EntityName.push(element.EntityName);
            MetricValue.push(parseFloat(element.MetricValue));
            value.push(parseFloat(element.MetricValue));
            OfferingName = element.Offering;
            ProcessName = element.Process;
            optionone.push(element.Industry);
            optionthree.push(element.City);

            datasets.push({
              label: element.EntityName,
              lineTension: 0.4,
              borderColor: randomItem[i],
              backgroundColor: randomItem[i],
              fill: false,
              borderCapStyle: "square",
              borderDash: [], // try [5, 15] for instance
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              City: element.City,
              DealID: element.DealID,
              EntityName: element.EntityName,
              Offering: element.Offering,
              Industry: element.Industry,
              ProcessName: element.Process,
              pointBorderColor: randomItem[i],
              pointBackgroundColor: "white",
              pointBorderWidth: 3,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: randomItem[i],
              pointHoverBorderColor: randomItem[i],
              pointHoverBorderWidth: 2,
              pointRadius: 5,
              pointHitRadius: 2,
              data: [null, ...element.MetricValue],
              spanGaps: true,
              showLineValues: false,
            });
          });
        } else {
          res.data.forEach((element, i) => {
            labels = ["", ...res.data[0].PeriodDT];

            DealName.push(element.DealName);
            EntityName.push(element.EntityName);
            MetricValue.push(parseFloat(element.MetricValue));
            value.push(parseFloat(element.MetricValue));
            OfferingName = element.Offering;
            ProcessName = element.Process;
            optionone.push(element.Industry);
            optionthree.push(element.City);
            datasets.push({
              label: element.DealName.includes("Deal")
                ? `${i18n.t("Deal")} ${element.DealName.split(" ")[1]}`
                : element.DealName,
              lineTension: 0.4,
              borderColor: randomItem[i],
              backgroundColor: randomItem[i],
              fill: false,
              borderCapStyle: "square",
              borderDash: [], // try [5, 15] for instance
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              City: element.City,
              DealID: element.DealID,
              EntityName: element.EntityName,
              Offering: element.Offering,
              Industry: element.Industry,
              ProcessName: element.Process,
              pointBorderColor: randomItem[i],
              pointBackgroundColor: "white",
              pointBorderWidth: 3,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: randomItem[i],
              pointHoverBorderColor: randomItem[i],
              pointHoverBorderWidth: 2,
              pointRadius: 5,
              pointHitRadius: 2,
              data: [null, ...element.MetricValue],
              spanGaps: true,
              showLineValues: false,
            });
          });
        }
        optionone = [...new Set(optionone)];
        optionone = optionone.splice(0, 1);
        optionthree = [...new Set(optionthree)];
        this.setState({
          ...this.state,
          labels,
          OfferingName,
          EntityName,
          DealValue: DealName,
          ProcessName,
          value,
          rangeValues: {
            min: minVal,
            max: maxVal,
          },
          sliderValues: {
            min: minVal,
            max: maxVal,
          },
          dsKpiData2: {
            labels,
            datasets,
          },
          newDsKpiData2: {
            labels,
            datasets,
          },
          responseClone: responseIndustryClone,
          compareToggleList: compareToggleSlice,
          IndustryGroup: optionone,
          deliveryLocation: optionthree,
          wholedata: res.data,
          filtereddata: res.data,
          isApiLoading: false,
        });
        let disable = this.state.dsKpiData2.datasets.length>0? false: true;

    this.setState({
      dsKpiData2: this.state.dsKpiData2,
      isDisabled:disable
    });
      })

      .catch((error) => {
        this.setState({ isApiLoading: false });
        trycatchAlertPopup(error);
      });
  }

  componentDidUpdate(prevProps) {
    const backgroundColorValues = [""];
    if (this.props.SelectedCommentMonth !== prevProps.SelectedCommentMonth) {
      if (this.state.temp.length > 0 && this.state.dsKpiData.datasets.length > 0) {
        this.state.temp.map((each) => {
          if (each.PeriodDt == this.props.SelectedCommentMonth) {
            backgroundColorValues.push("rgb(0, 186, 255)");
          } else {
            backgroundColorValues.push("white");
          }
        });
        const { labels } = this.state.dsKpiData;
        const colorChange = this.state.dsKpiData.datasets;
        const metricColor = colorChange[0];
        metricColor.pointBackgroundColor = backgroundColorValues;
        colorChange[0] = metricColor;
       
    
        this.setState({
          dsKpiData: {
            labels,
            datasets: colorChange,
          },
          backgroundColorValues,
          isMetricOn: !!this.props.CommentMonthChange,
          isLowerOn: !!this.props.CommentMonthChange,
          isMedianOn: !!this.props.CommentMonthChange,
          isUpperOn: !!this.props.CommentMonthChange,
          isBaselineOn: !!this.props.CommentMonthChange,
          isTargetOn: !!this.props.CommentMonthChange,
          strikedLegentData: [],
        });
      }
    }
  }

  componentDidMount() {

    this.getKpiData();
    let disable = this.state.dsKpiData2.length>0? false: true;

    this.setState({
      isDisabled:disable
    });
    store.dispatch({
      type: "SYNC_COMMENTFILTER",
      payload: null,
    });
  }

  GetNotifications() {
    axiosInstance
      .get(
        `${LocalApiBaseUrl}Analytics/GetNotifications?IsRead=true&LanguageID=${parseInt(
          store.getState().CurrentFilter.languageData.id
        )}`
      )
      .then((response) => {
        response.data &&
          response.data.map((each) => {
            const borderColor = [];
            const date = dateFormatMethod2(each.PeriodDT).split(",")[0];
            const date2 = date.split(" ");
            const formatteddate = `${date2[1]}-${date2[2]}`;
            this.state.dsKpiData.labels.map((each1) => {
              if (
                each1 == formatteddate &&
                sessionStorage.getItem("EnterpriseID") !== each.CreatedBy &&
                this.props.kpiMappingId == each.KPIMappingID
              ) {
                borderColor.push("rgb(220, 6, 6)");
              } else {
                borderColor.push("rgb(0, 186, 255)");
              }
            });
            const { labels } = this.state.dsKpiData;
            const colorChange = this.state.dsKpiData.datasets;
            const metricColor = colorChange[0];
            metricColor.pointBorderColor = borderColor;
            colorChange[0] = metricColor;
            this.setState({
              dsKpiData: {
                labels,
                datasets: colorChange,
              },
              metricBorderColorValues: borderColor,
            });
          });
        this.setState({
          newNotificationList: response.data,
        });
      });
  }

  getKpiData() {
    const { kpiMappingId } = this.props;
    const months = this.props.Month;
    let UpperThresholdMean = "";
    let LowerThresholdMean = "";
    const UpperThresholdMeanArray = [];
    const LowerThresholdMeanArray = [];
    let baselineMean = "";
    let targetMean = "";
    const baselineMeanArray = [];
    const targetMeanArray = [];
    const trendAvgArray = [];
    const today = new Date();
    const date1 = `${today.getFullYear()}-${
      today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
    }`;
    const date2 = today.setMonth(today.getMonth() - 5);
    const updatedDate2 = `${new Date(date2).getFullYear()}-${
      new Date(date2).getMonth() + 1 < 10
        ? `0${new Date(date2).getMonth() + 1}`
        : new Date(date2).getMonth() + 1
    }`;
    this.setState({
      endDate: date1,
      startDate: updatedDate2,
      isApiLoading: true,
    });
    const languageId = store.getState().CurrentFilter.languageData.id;
    this.setState({ isApiLoading: true });
    axiosInstance
      .get(`${LocalApiBaseUrl}Analytics/GetKpiData`, {
        params: { kpiMappingID: kpiMappingId, LanguageID: languageId },
      })
      .then((res) => {
        const MontnDt = [""];
        const ValueDt = [];
        const baselineMonthValue = [];
        res.data.forEach((element) => {
          MontnDt.push(element.PeriodDt);
          ValueDt.push(parseFloat(element.MetricValue));
          baselineMonthValue.push(element.BaseLineMonth);
        });

        for (let i = res.data.length - 1; i >= 0; i--) {
          if (
            (res.data[i].UpperThrehold !== null &&
              String(res.data[i].UpperThrehold.trim()) !== "") ||
            (res.data[i].LowerThrehold !== null && String(res.data[i].LowerThrehold.trim()) !== "")
          ) {
            UpperThresholdMean = res.data[i].UpperThrehold;
            LowerThresholdMean = res.data[i].LowerThrehold;
            break;
          }
        }
        for (let i = res.data.length - 1; i >= 0; i--) {
          if (
            (res.data[i].BaselineValue !== null && res.data[i].BaselineValue.trim() != "") ||
            (res.data[i].TargetValue !== null && res.data[i].TargetValue.trim() != "")
          ) {
            baselineMean = res.data[i].BaselineValue;
            targetMean = res.data[i].TargetValue;

            break;
          }
        }

        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        if (res.data.length > 0) {
          res.data.map((each) => {
            const objDate = new Date(each.PeriodDt);
            each.PeriodDt = `${monthNames[objDate.getMonth()]} 20${objDate.getDate()}`;
          });
        }
        if (baselineMonthValue.length > 0) {
          for (let i = 0; i < baselineMonthValue.length; i++) {
            if (baselineMonthValue[i] != null && baselineMonthValue[i] != "") {
              const objDate = new Date(baselineMonthValue[i]);
              baselineMonthValue[i] = `${
                this.state.fullmonthNames[objDate.getMonth()]
              } ${objDate.getFullYear()}`;
            }
          }
        }

        const sum = ValueDt.reduce((previous, current) => (current += previous));
        const avg = sum / ValueDt.length;

        res.data.forEach((element) => {
          trendAvgArray.push(avg);
          UpperThresholdMeanArray.push(parseFloat(UpperThresholdMean));
          LowerThresholdMeanArray.push(parseFloat(LowerThresholdMean));
          baselineMeanArray.push(parseFloat(baselineMean));
          targetMeanArray.push(parseFloat(targetMean));
        });
        const ctx = document.getElementById("LineChart").getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, 0, 450);
        gradient.addColorStop(0, "rgba(0, 186, 255,0.2)");
        // gradient.addColorStop(0.5, '#20f08b');
        gradient.addColorStop(1, "rgba(255, 255, 255,0)");
        this.setState(
          {
            baselineMonthValue,
            isApiLoading: false,
            temp: res.data,
            trendAvg: avg,
            UpperThresholdMean,
            LowerThresholdMean,
            BaselineMean: baselineMean,
            TargetMean: targetMean,
            isMetricOn: !!this.props.CommentMonthChange,
            isLowerOn: !!this.props.CommentMonthChange,
            isMedianOn: !!this.props.CommentMonthChange,
            isUpperOn: !!this.props.CommentMonthChange,
            isBaselineOn: !!this.props.CommentMonthChange,
            isTargetOn: !!this.props.CommentMonthChange,
            strikedLegentData: [],
            dsKpiData: {
              labels: MontnDt,
              datasets: [
                {
                  label: i18n.t("Metric_Value"),
                  data: [null, ...ValueDt],
                  fill: true,
                  lineTension: 0,
                  backgroundColor: gradient,
                  borderColor: "rgb(0, 186, 255)",
                  borderCapStyle: "butt",
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: "miter",
                  floating: true,
                  pointDotRadius: 8, // 6
                  datalabels: {
                    align: "top",
                    enabled: true,
                  },
                  pointBorderColor: this.state.metricBorderColorValues,
                  pointBackgroundColor: this.state.backgroundColorValues,
                  pointBorderWidth: 2,
                  pointHoverRadius: 8,
                  pointHoverBackgroundColor: "white",
                  pointHoverBorderColor: "rgb(0, 186, 255)",
                  pointHoverBorderWidth: 2,
                  pointRadius: 6,
                  pointHitRadius: 20,
                  datalabels: {
                    display: false,
                  },
                },

                {
                  label: i18n.t("Median"),
                  data: [avg, ...trendAvgArray],
                  fill: false,
                  lineTension: 0.1,
                  backgroundColor: "rgba(255, 200, 44, 0.1)",
                  borderColor: "rgb(255, 200, 44)",
                  borderCapStyle: "butt",
                  borderDashOffset: 0.0,
                  borderJoinStyle: "miter",
                  pointBorderColor: "rgb(255, 200, 44)",
                  pointBackgroundColor: ["#fff"],
                  pointBorderWidth: 1,
                  pointStyle: "circle",
                  pointHoverRadius: 0,
                  pointHoverBackgroundColor: "rgb(255, 255, 255)",
                  pointHoverBorderColor: "rgb(255, 200, 44)",
                  pointHoverBorderWidth: 0,
                  pointRadius: 0,
                  pointHitRadius: 0,
                  borderWidth: 1.5,
                  borderDash: [5, 3],
                  datalabels: {
                    backgroundColor: "rgb(255, 200, 44)",
                    borderColor: "rgb(255, 200, 44)",
                  },
                },
                {
                  label: i18n.t("Upper_Threshold"),
                  data: [UpperThresholdMean, ...UpperThresholdMeanArray],
                  fill: false,
                  lineTension: 0.1,
                  backgroundColor: "rgba(126, 195, 68, 0.1)",
                  borderColor: "rgb(126, 195, 68)",
                  borderCapStyle: "butt",
                  borderDashOffset: 0.0,
                  borderJoinStyle: "miter",
                  pointBorderColor: "rgb(126, 195, 68)",
                  pointBackgroundColor: ["#fff"],
                  pointBorderWidth: 1,
                  pointStyle: "circle",
                  pointHoverRadius: 0,
                  pointHoverBackgroundColor: "rgb(255, 255, 255)",
                  pointHoverBorderColor: "rgb(126, 195, 68)",
                  pointHoverBorderWidth: 0,
                  pointRadius: 0,
                  pointHitRadius: 0,
                  borderWidth: 1.5,
                  borderDash: [5, 3],
                  datalabels: {
                    backgroundColor: "rgb(126, 195, 68)",
                    borderColor: "rgb(126, 195, 68)",
                  },
                },
                {
                  label: i18n.t("Lower_Threshold"),
                  data: [LowerThresholdMean, ...LowerThresholdMeanArray],
                  fill: false,
                  lineTension: 0.1,
                  backgroundColor: "rgba(213, 0, 47, 0.1)",
                  borderColor: "rgb(213, 0, 47)",
                  borderCapStyle: "butt",
                  borderDashOffset: 0.0,
                  borderJoinStyle: "miter",
                  pointBorderColor: "rgb(213, 0, 47)",
                  pointBackgroundColor: ["#fff"],
                  pointBorderWidth: 1,
                  pointStyle: "circle",
                  pointHoverRadius: 0,
                  pointHoverBackgroundColor: "rgb(255, 255, 255)",
                  pointHoverBorderColor: "rgb(213, 0, 47)",
                  pointHoverBorderWidth: 0,
                  pointRadius: 0,
                  pointHitRadius: 0,
                  borderWidth: 1.5,
                  borderDash: [5, 3],
                  datalabels: {
                    backgroundColor: "rgb(213, 0, 47)",
                    borderColor: "rgb(213, 0, 47)",
                  },
                },
                {
                  label: i18n.t("Baseline"),
                  data: [baselineMean, ...baselineMeanArray],
                  fill: false,
                  lineTension: 0.1,
                  backgroundColor: "rgba(181, 192, 202, 0.1)",
                  borderColor: "rgb(181, 192, 202)",
                  borderCapStyle: "butt",
                  borderDashOffset: 0.0,
                  borderJoinStyle: "miter",
                  pointBorderColor: "rgb(181, 192, 202)",
                  pointBackgroundColor: ["#fff"],
                  pointBorderWidth: 1,
                  pointStyle: "circle",
                  pointHoverRadius: 0,
                  pointHoverBackgroundColor: "rgb(255, 255, 255)",
                  pointHoverBorderColor: "rgb(181, 192, 202)",
                  pointHoverBorderWidth: 0,
                  pointRadius: 0,
                  pointHitRadius: 0,
                  borderWidth: 1.5,
                  borderDash: [5, 3],
                  datalabels: {
                    backgroundColor: "rgb(181, 192, 202)",
                    borderColor: "rgb(181, 192, 202)",
                  },
                },
                {
                  label: i18n.t("Target"),
                  data: [targetMean, ...targetMeanArray],
                  fill: false,
                  lineTension: 0.1,
                  backgroundColor: "rgba(160, 85, 245, 0.1)",
                  borderColor: "rgb(160, 85, 245)",
                  borderCapStyle: "butt",
                  borderDashOffset: 0.0,
                  borderJoinStyle: "miter",
                  pointBorderColor: "rgb(160, 85, 245)",
                  pointBackgroundColor: ["#fff"],
                  pointBorderWidth: 1,
                  pointStyle: "circle",
                  pointHoverRadius: 0,
                  pointHoverBackgroundColor: "rgb(255, 255, 255)",
                  pointHoverBorderColor: "rgb(160, 85, 245)",
                  pointHoverBorderWidth: 0,
                  pointRadius: 0,
                  pointHitRadius: 0,
                  borderWidth: 1.5,
                  borderDash: [5, 3],
                  datalabels: {
                    backgroundColor: "rgb(160, 85, 245)",
                    borderColor: "rgb(160, 85, 245)",
                  },
                },
              ],
            },
          },
          () => {
            this.GetNotifications();
          }
        );
      })
      .catch((error) => {
        this.setState({ isApiLoading: false });
        trycatchAlertPopup(error);
      });

    this.GetAnalyticsApi(updatedDate2, date1);
    this.getMaturityPhase();
  }

  onShowHideButtonClick = () => {
    const { showComments } = this.state;
    const dataset = this.state.dsKpiData.datasets;
    const label = this.state.dsKpiData.labels;
    this.setState({
      showComments: !showComments,
      dsKpiData: {
        labels: label,
        datasets: dataset,
      },
    });
  };

  MetricClick() {
    const { isMetricOn } = this.state;
    this.setState({
      isMetricOn: !isMetricOn,
    });
  }

  LowerClick() {
    const { isLowerOn } = this.state;
    this.setState({
      isLowerOn: !isLowerOn,
    });
  }

  MedianClick() {
    const { isMedianOn } = this.state;
    this.setState({
      isMedianOn: !isMedianOn,
    });
  }

  UpperClick() {
    const { isUpperOn } = this.state;
    this.setState({
      isUpperOn: !isUpperOn,
    });
  }

  BaselineClick() {
    const { isBaselineOn } = this.state;
    this.setState({
      isBaselineOn: !isBaselineOn,
    });
  }

  TargetClick() {
    const { isTargetOn } = this.state;
    this.setState({
      isTargetOn: !isTargetOn,
    });
  }

  onLegendClick = (data) => {
    const kpiData = this.state.dsKpiData.datasets;
    const { labels } = this.state.dsKpiData;
    let newkpiData = this.state.dsKpiData.datasets;
    const { strikedLegentData } = this.state;
    let strikedlegend = false;
    strikedLegentData.map((each) => {
      if (each.label == data) {
        strikedlegend = true;
      }
    });
    if (strikedlegend) {
      let index;
      strikedLegentData.map((each, ind) => {
        if (each.label == data) {
          newkpiData.push(each);
          index = ind;
        }
      });
      strikedLegentData[index] = [];
    } else {
      newkpiData = [];
      kpiData.map((each) => {
        if (each.label != data) {
          newkpiData.push(each);
        } else {
          strikedLegentData.push(each);
        }
      });
    }
    this.setState({
      dsKpiData: {
        labels,
        datasets: newkpiData,
      },
      strikedLegentData,
    });
  };

  handleSelect = (key) => {
    if (key === "MindMap") {
      store.dispatch(MindmapClick(true));
    }
  };
  sliderValueFilter(e) {
    this.setState({ rangeValues: e }, () => {
      this.chartFilterLogic();
    });
  }

  onShowDealChange(event) {
    this.setState({ ShowDeals: event.target.checked }, () => {
      this.showClientandToggleLogic();
    });
  }

  onToggleCompareChange(evt) {
    this.setState({ isToggleOn: evt.target.checked }, () => {
      this.showClientandToggleLogic();
    });
  }

  showClientandToggleLogic(){
    const { dsKpiData2, labels, isToggleOn, ShowDeals, filtereddata, rangeValues, sliderValues, newUpdatedDsKpiData2, compareToggleList } = this.state;
      let newDsKpiData2 = _.cloneDeep(dsKpiData2);
      const finalCompareList = [];
      let ind3 = 0;
      let ind4 = 0;
      let ind5 = 0;
      let newInd2 = 0; 
      let toggleLength = 0;
      let ListLength = 0;
      let toggleOnArray = [];
      let toggleOnArrayRemoved = [];
      let selectedClient = [];
      if( ShowDeals === false && isToggleOn === false ) {
          //Check Slider Range
          if(parseFloat(rangeValues.min)!==parseFloat(sliderValues.min) || parseFloat(rangeValues.max)!==parseFloat(sliderValues.max)){
              newDsKpiData2.datasets = _.cloneDeep(newUpdatedDsKpiData2);
            }
            if(isToggleOn === false) {
              selectedClient = dsKpiData2.datasets[0].DealID;
        
              if (newDsKpiData2.datasets[0].label.includes('Deal')) {
                for(let c = 0; c < newDsKpiData2.datasets.length; c++) {
                  if(dsKpiData2.datasets[0].EntityName!==newDsKpiData2.datasets[c].EntityName || dsKpiData2.datasets[0].EntityName===newDsKpiData2.datasets[c].EntityName ){
                    toggleOnArray[ind3] = newDsKpiData2.datasets[c];
                    ind3++;
                  }
                }
         
                newDsKpiData2.datasets = [];
         
                for (let n = 0; n < toggleOnArray.length; n++) {
                  newDsKpiData2.datasets[n] = toggleOnArray[n];
                }
              }
              else if (selectedClient === newDsKpiData2.datasets[0].DealID) {
                  
                  for(let c = 0; c < newDsKpiData2.datasets.length; c++) {
                    if(newDsKpiData2.datasets[0].EntityName!==newDsKpiData2.datasets[c].EntityName || dsKpiData2.datasets[0].EntityName===newDsKpiData2.datasets[c].EntityName ){
                      toggleOnArray[ind4] = newDsKpiData2.datasets[c];
                      ind4++;
                    }
                  }
                  
                  for (let n = 0; n < toggleOnArray.length; n++) {
                    newDsKpiData2.datasets[n] = toggleOnArray[n];
                  }
         
                  newDsKpiData2.datasets = newDsKpiData2.datasets.splice(0, toggleOnArray.length + 1);
                
              } else if (newDsKpiData2.datasets[0].DealID !== selectedClient && !newDsKpiData2.datasets[0].label.includes('Deal')) {
                for(let c = 0; c < newDsKpiData2.datasets.length; c++) {
                  if(dsKpiData2.datasets[0].EntityName!==newDsKpiData2.datasets[c].EntityName || dsKpiData2.datasets[0].EntityName===newDsKpiData2.datasets[c].EntityName ){
                    toggleOnArray[ind5] = newDsKpiData2.datasets[c];
                    ind5++;
                  }
                }
         
                newDsKpiData2.datasets = [];
         
                for (let n = 0; n < toggleOnArray.length; n++) {
                  newDsKpiData2.datasets[n] = toggleOnArray[n];
                }
              }
            }
            //Check if first dataset is Selected or Client
            if ( newDsKpiData2.datasets[0].label.includes('Client') ) {
                //Mask all Clients
              for ( let j = 1; j < newDsKpiData2.datasets.length; j++ ) {
                  newDsKpiData2.datasets[j].label = `Client ${j}`;
                  }
            }else{
              for ( let j = 1; j < newDsKpiData2.datasets.length; j++ ) {
                newDsKpiData2.datasets[j].label = `Client ${j}`;
                }
            }
            
      }else if( ShowDeals === false && isToggleOn === true ) {
          if(parseFloat(rangeValues.min)!==parseFloat(sliderValues.min) || parseFloat(rangeValues.max)!==parseFloat(sliderValues.max)){
              newDsKpiData2.datasets = _.cloneDeep(newUpdatedDsKpiData2);
            }
              if(isToggleOn === true) {
                selectedClient = dsKpiData2.datasets[0].DealID;
          
                if (newDsKpiData2.datasets[0].label.includes('Deal')) {
                  for(let c = 0; c < newDsKpiData2.datasets.length; c++) {
                    if(dsKpiData2.datasets[0].EntityName!==newDsKpiData2.datasets[c].EntityName ){
                      toggleOnArray[ind3] = newDsKpiData2.datasets[c];
                      ind3++;
                    }else if(dsKpiData2.datasets[0].DealID!==newDsKpiData2.datasets[c].DealID && dsKpiData2.datasets[0].EntityName===newDsKpiData2.datasets[c].EntityName){
                      toggleOnArrayRemoved[newInd2] = newDsKpiData2.datasets[c];
                      newInd2++;
                    }
                  }
                  newDsKpiData2.datasets = [];
           
                  for (let n = 0; n < toggleOnArray.length; n++) {
                    newDsKpiData2.datasets[n] = toggleOnArray[n];
                  }
                }
                else if (selectedClient === newDsKpiData2.datasets[0].DealID) {
                    
                    for(let c = 0; c < newDsKpiData2.datasets.length; c++) {
                      if(newDsKpiData2.datasets[0].EntityName!==newDsKpiData2.datasets[c].EntityName ){
                        toggleOnArray[ind4] = newDsKpiData2.datasets[c];
                        ind4++;
                      }else if(dsKpiData2.datasets[0].DealID!==newDsKpiData2.datasets[c].DealID && dsKpiData2.datasets[0].EntityName===newDsKpiData2.datasets[c].EntityName){
                        toggleOnArrayRemoved[newInd2] = newDsKpiData2.datasets[c];
                        newInd2++;
                      }
                    }
                    //  toggDsKpiData2.datasets[0] = newDsKpiData2.datasets[0];
                    for (let n = 1; n <= toggleOnArray.length; n++) {
                      newDsKpiData2.datasets[n] = toggleOnArray[n - 1];
                    }
           
                    newDsKpiData2.datasets = newDsKpiData2.datasets.splice(0, toggleOnArray.length + 1);
                  
                } else if (newDsKpiData2.datasets[0].DealID !== selectedClient && !newDsKpiData2.datasets[0].label.includes('Deal')) {
                  for(let c = 0; c < newDsKpiData2.datasets.length; c++) {
                    if(dsKpiData2.datasets[0].EntityName!==newDsKpiData2.datasets[c].EntityName ){
                      toggleOnArray[ind5] = newDsKpiData2.datasets[c];
                      ind5++;
                    }else if(dsKpiData2.datasets[0].DealID!==newDsKpiData2.datasets[c].DealID && dsKpiData2.datasets[0].EntityName===newDsKpiData2.datasets[c].EntityName){
                      toggleOnArrayRemoved[newInd2] = newDsKpiData2.datasets[c];
                      newInd2++;
                    }
                  }
           
                  newDsKpiData2.datasets = [];
           
                  for (let n = 0; n < toggleOnArray.length; n++) {
                    newDsKpiData2.datasets[n] = toggleOnArray[n];
                  }
                }
              }
              toggleLength = toggleOnArrayRemoved.length;
            ListLength = newDsKpiData2.datasets.length;
            if(toggleLength > 0) {
              compareToggleList.forEach((element, i) => {
                finalCompareList.push({
                  label: element.EntityName,
                  lineTension: 0.4,
                  borderColor: randomItem[i+6],
                  backgroundColor: randomItem[i+6],
                  fill: false,
                  borderCapStyle: 'square',
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: 'miter',
                  City: element.City,
                  DealID: element.DealID,
                  EntityName: element.EntityName,
                  Offering: element.Offering,
                  Industry: element.Industry,
                  ProcessName: element.Process,
                  pointBorderColor: randomItem[i+6],
                  pointBackgroundColor: 'white',
                  pointBorderWidth: 3,
                  pointHoverRadius: 8,
                  pointHoverBackgroundColor: randomItem[i+6],
                  pointHoverBorderColor: randomItem[i+6],
                  pointHoverBorderWidth: 2,
                  pointRadius: 5,
                  pointHitRadius: 2,
                  data: [null, ...element.MetricValue],
                  spanGaps: true,
                  showLineValues: false,
                });
              });
              if(finalCompareList.length > 0){
                for( let yy = 0; yy < toggleLength; yy++ ) {
                  newDsKpiData2.datasets[ListLength + yy] = finalCompareList[yy];
                }
              }
            }
              for(let j = 1; j < newDsKpiData2.datasets.length; j++){
                newDsKpiData2.datasets[j].label = `Client ${j}`;
                }
      } else if( ShowDeals === true && isToggleOn === false){
          if(parseFloat(rangeValues.min)!==parseFloat(sliderValues.min) || parseFloat(rangeValues.max)!==parseFloat(sliderValues.max)){
              newDsKpiData2.datasets = _.cloneDeep(newUpdatedDsKpiData2);
            }
            //Check Toggle Condition for false
            if(isToggleOn === false) {
              selectedClient = dsKpiData2.datasets[0].DealID;
        
              if (newDsKpiData2.datasets[0].label.includes('Deal')) {
                for(let c = 0; c < newDsKpiData2.datasets.length; c++) {
                  if(dsKpiData2.datasets[0].EntityName!==newDsKpiData2.datasets[c].EntityName || dsKpiData2.datasets[0].EntityName===newDsKpiData2.datasets[c].EntityName ){
                    toggleOnArray[ind3] = newDsKpiData2.datasets[c];
                    ind3++;
                  }
                }
         
                newDsKpiData2.datasets = [];
         
                for (let n = 0; n < toggleOnArray.length; n++) {
                  newDsKpiData2.datasets[n] = toggleOnArray[n];
                }
              }
              else if (selectedClient === newDsKpiData2.datasets[0].DealID) {
                  
                  for(let c = 0; c < newDsKpiData2.datasets.length; c++) {
                    if(newDsKpiData2.datasets[0].EntityName!==newDsKpiData2.datasets[c].EntityName || dsKpiData2.datasets[0].EntityName===newDsKpiData2.datasets[c].EntityName ){
                      toggleOnArray[ind4] = newDsKpiData2.datasets[c];
                      ind4++;
                    }
                  }
                  
                  for (let n = 0; n < toggleOnArray.length; n++) {
                    newDsKpiData2.datasets[n] = toggleOnArray[n];
                  }
         
                  newDsKpiData2.datasets = newDsKpiData2.datasets.splice(0, toggleOnArray.length + 1);
                
              } else if (newDsKpiData2.datasets[0].DealID !== selectedClient && !newDsKpiData2.datasets[0].label.includes('Deal')) {
                for(let c = 0; c < newDsKpiData2.datasets.length; c++) {
                  if(dsKpiData2.datasets[0].EntityName!==newDsKpiData2.datasets[c].EntityName || dsKpiData2.datasets[0].EntityName===newDsKpiData2.datasets[c].EntityName ){
                    toggleOnArray[ind5] = newDsKpiData2.datasets[c];
                    ind5++;
                  }
                }
         
                newDsKpiData2.datasets = [];
         
                for (let n = 0; n < toggleOnArray.length; n++) {
                  newDsKpiData2.datasets[n] = toggleOnArray[n];
                }
              }
            }
            //Check Show Client Condition for true
            for (let i = 0; i < newDsKpiData2.datasets.length; i++) {
              for (let h = 0; h < filtereddata.length; h++) {
              if(newDsKpiData2.datasets[i].DealID === filtereddata[h].DealID ) {
                newDsKpiData2.datasets[i].label = filtereddata[h].EntityName;
              }
             }
            }
      }
      else if( ShowDeals === true && isToggleOn === true){
        
          if(parseFloat(rangeValues.min)!==parseFloat(sliderValues.min) || parseFloat(rangeValues.max)!==parseFloat(sliderValues.max)){
              newDsKpiData2.datasets = _.cloneDeep(newUpdatedDsKpiData2);
            }
            //Check Toggle Condition for true
            if(isToggleOn === true) {
              selectedClient = dsKpiData2.datasets[0].DealID;
        
              if (newDsKpiData2.datasets[0].label.includes('Deal')) {
                for(let c = 0; c < newDsKpiData2.datasets.length; c++) {
                  if(dsKpiData2.datasets[0].EntityName!==newDsKpiData2.datasets[c].EntityName ){
                    toggleOnArray[ind3] = newDsKpiData2.datasets[c];
                    ind3++;
                  }else if(dsKpiData2.datasets[0].DealID!==newDsKpiData2.datasets[c].DealID && dsKpiData2.datasets[0].EntityName===newDsKpiData2.datasets[c].EntityName){
                    toggleOnArrayRemoved[newInd2] = newDsKpiData2.datasets[c];
                    newInd2++;
                  }
                }
         
                newDsKpiData2.datasets = [];
         
                for (let n = 0; n < toggleOnArray.length; n++) {
                  newDsKpiData2.datasets[n] = toggleOnArray[n];
                }
              }
              else if (selectedClient === newDsKpiData2.datasets[0].DealID) {
                  
                  for(let c = 0; c < newDsKpiData2.datasets.length; c++) {
                    if(newDsKpiData2.datasets[0].EntityName!==newDsKpiData2.datasets[c].EntityName ){
                      toggleOnArray[ind4] = newDsKpiData2.datasets[c];
                      ind4++;
                    }else if(dsKpiData2.datasets[0].DealID!==newDsKpiData2.datasets[c].DealID && dsKpiData2.datasets[0].EntityName===newDsKpiData2.datasets[c].EntityName){
                      toggleOnArrayRemoved[newInd2] = newDsKpiData2.datasets[c];
                      newInd2++;
                    }
                  }
                  //  toggDsKpiData2.datasets[0] = newDsKpiData2.datasets[0];
                  for (let n = 1; n <= toggleOnArray.length; n++) {
                    newDsKpiData2.datasets[n] = toggleOnArray[n - 1];
                  }
         
                  newDsKpiData2.datasets = newDsKpiData2.datasets.splice(0, toggleOnArray.length + 1);
                
              } else if (newDsKpiData2.datasets[0].DealID !== selectedClient && !newDsKpiData2.datasets[0].label.includes('Deal')) {
                for(let c = 0; c < newDsKpiData2.datasets.length; c++) {
                  if(dsKpiData2.datasets[0].EntityName!==newDsKpiData2.datasets[c].EntityName ){
                    toggleOnArray[ind5] = newDsKpiData2.datasets[c];
                    ind5++;
                  }else if(dsKpiData2.datasets[0].DealID!==newDsKpiData2.datasets[c].DealID && dsKpiData2.datasets[0].EntityName===newDsKpiData2.datasets[c].EntityName){
                    toggleOnArrayRemoved[newInd2] = newDsKpiData2.datasets[c];
                    newInd2++;
                  }
                }
         
                newDsKpiData2.datasets = [];
         
                for (let n = 0; n < toggleOnArray.length; n++) {
                  newDsKpiData2.datasets[n] = toggleOnArray[n];
                }
              }
            }
            toggleLength = toggleOnArrayRemoved.length;
            ListLength = newDsKpiData2.datasets.length;
            if(toggleLength > 0) {
              compareToggleList.forEach((element, i) => {
                finalCompareList.push({
                  label: element.EntityName,
                  lineTension: 0.4,
                  borderColor: randomItem[i+6],
                  backgroundColor: randomItem[i+6],
                  fill: false,
                  borderCapStyle: 'square',
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: 'miter',
                  City: element.City,
                  DealID: element.DealID,
                  EntityName: element.EntityName,
                  Offering: element.Offering,
                  Industry: element.Industry,
                  ProcessName: element.Process,
                  pointBorderColor: randomItem[i+6],
                  pointBackgroundColor: 'white',
                  pointBorderWidth: 3,
                  pointHoverRadius: 8,
                  pointHoverBackgroundColor: randomItem[i+6],
                  pointHoverBorderColor: randomItem[i+6],
                  pointHoverBorderWidth: 2,
                  pointRadius: 5,
                  pointHitRadius: 2,
                  data: [null, ...element.MetricValue],
                  spanGaps: true,
                  showLineValues: false,
                });
              });
              if(finalCompareList.length > 0){
                for( let yy = 0; yy < toggleLength; yy++ ) {
                  newDsKpiData2.datasets[ListLength + yy] = finalCompareList[yy];
                }
              }
            }
            //Check Show Client Condition for true
            for (let i = 0; i < newDsKpiData2.datasets.length; i++) {
              for (let h = 0; h < filtereddata.length; h++) {
              if(newDsKpiData2.datasets[i].DealID === filtereddata[h].DealID ) {
                newDsKpiData2.datasets[i].label = filtereddata[h].EntityName;
              }
             }
            }
      }
    this.setState({
      newDsKpiData2: {
        labels,
        datasets: newDsKpiData2.datasets,
      },
    }, ()=>{
      });
      this.forceUpdate();
  }
  

  chartFilterLogic = () => {
    const {
      dsKpiData2,
      labels,
      isToggleOn,
      ShowDeals,
      filtereddata,
      rangeValues,
      sliderValues,
      compareToggleList,
    } = this.state;
    let newDsKpiData2 = _.cloneDeep(dsKpiData2);
    const len = newDsKpiData2.datasets.length;
    const flag = [];
    const finalCompareList = [];
    let ind = 0;
    let ind2 = 0;
    let ind3 = 0;
    let ind4 = 0;
    let ind5 = 0;
    let newInd2 = 0;
    let toggleLength = 0;
    let ListLength = 0;
    let nullDataClients = [];
    let notNullDataClients = [];
    let toggleOnArray = [];
    let toggleOnArrayRemoved = [];
    let selectedClient = [];

    if (
      parseFloat(rangeValues.min) === parseFloat(sliderValues.min) &&
      parseFloat(rangeValues.max) === parseFloat(sliderValues.max)
    ) {
      if (ShowDeals === true && isToggleOn === true) {
        for (let i = 0; i < newDsKpiData2.datasets.length; i++) {
          for (let h = 0; h < filtereddata.length; h++) {
            if (newDsKpiData2.datasets[i].DealID === filtereddata[h].DealID) {
              newDsKpiData2.datasets[i].label = filtereddata[h].EntityName;
            }
          }
        }

        selectedClient = dsKpiData2.datasets[0].DealID;

        if (newDsKpiData2.datasets[0].label.includes("Deal")) {
          for (let c = 0; c < newDsKpiData2.datasets.length; c++) {
            if (dsKpiData2.datasets[0].EntityName !== newDsKpiData2.datasets[c].EntityName) {
              toggleOnArray[ind3] = newDsKpiData2.datasets[c];
              ind3++;
            } else if (
              dsKpiData2.datasets[0].DealID !== newDsKpiData2.datasets[c].DealID &&
              dsKpiData2.datasets[0].EntityName === newDsKpiData2.datasets[c].EntityName
            ) {
              toggleOnArrayRemoved[newInd2] = newDsKpiData2.datasets[c];
              newInd2++;
            }
          }

          newDsKpiData2.datasets = [];

          for (let n = 0; n < toggleOnArray.length; n++) {
            newDsKpiData2.datasets[n] = toggleOnArray[n];
          }
        } else if (selectedClient === newDsKpiData2.datasets[0].DealID) {
          for (let c = 0; c < newDsKpiData2.datasets.length; c++) {
            if (newDsKpiData2.datasets[0].EntityName !== newDsKpiData2.datasets[c].EntityName) {
              toggleOnArray[ind4] = newDsKpiData2.datasets[c];
              ind4++;
            } else if (
              dsKpiData2.datasets[0].DealID !== newDsKpiData2.datasets[c].DealID &&
              dsKpiData2.datasets[0].EntityName === newDsKpiData2.datasets[c].EntityName
            ) {
              toggleOnArrayRemoved[newInd2] = newDsKpiData2.datasets[c];
              newInd2++;
            }
          }
          //  toggDsKpiData2.datasets[0] = newDsKpiData2.datasets[0];
          for (let n = 1; n <= toggleOnArray.length; n++) {
            newDsKpiData2.datasets[n] = toggleOnArray[n - 1];
          }

          newDsKpiData2.datasets = newDsKpiData2.datasets.splice(0, toggleOnArray.length + 1);
        } else if (
          newDsKpiData2.datasets[0].DealID !== selectedClient &&
          !newDsKpiData2.datasets[0].label.includes("Deal")
        ) {
          for (let c = 0; c < newDsKpiData2.datasets.length; c++) {
            if (dsKpiData2.datasets[0].EntityName !== newDsKpiData2.datasets[c].EntityName) {
              toggleOnArray[ind5] = newDsKpiData2.datasets[c];
              ind5++;
            } else if (
              dsKpiData2.datasets[0].DealID !== newDsKpiData2.datasets[c].DealID &&
              dsKpiData2.datasets[0].EntityName === newDsKpiData2.datasets[c].EntityName
            ) {
              toggleOnArrayRemoved[newInd2] = newDsKpiData2.datasets[c];
              newInd2++;
            }
          }

          newDsKpiData2.datasets = [];

          for (let n = 0; n < toggleOnArray.length; n++) {
            newDsKpiData2.datasets[n] = toggleOnArray[n];
          }
        }
        toggleLength = toggleOnArrayRemoved.length;
        ListLength = newDsKpiData2.datasets.length;
        if (toggleLength > 0) {
          compareToggleList.forEach((element, i) => {
            finalCompareList.push({
              label: element.EntityName,
              lineTension: 0.4,
              borderColor: randomItem[i + 6],
              backgroundColor: randomItem[i + 6],
              fill: false,
              borderCapStyle: "square",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              City: element.City,
              DealID: element.DealID,
              EntityName: element.EntityName,
              Offering: element.Offering,
              Industry: element.Industry,
              ProcessName: element.Process,
              pointBorderColor: randomItem[i + 6],
              pointBackgroundColor: "white",
              pointBorderWidth: 3,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: randomItem[i + 6],
              pointHoverBorderColor: randomItem[i + 6],
              pointHoverBorderWidth: 2,
              pointRadius: 5,
              pointHitRadius: 2,
              data: [null, ...element.MetricValue],
              spanGaps: true,
              showLineValues: false,
            });
          });
          if (finalCompareList.length > 0) {
            for (let yy = 0; yy < toggleLength; yy++) {
              newDsKpiData2.datasets[ListLength + yy] = finalCompareList[yy];
            }
          }
        }
      }

      if (isToggleOn === true && ShowDeals === false) {
        for (let j = 1; j < newDsKpiData2.length; j++) {
          newDsKpiData2[j].DealName = `Client ${j}`;
        }
        selectedClient = dsKpiData2.datasets[0].DealID;

        if (newDsKpiData2.datasets[0].label.includes("Deal")) {
          for (let c = 0; c < newDsKpiData2.datasets.length; c++) {
            if (dsKpiData2.datasets[0].EntityName !== newDsKpiData2.datasets[c].EntityName) {
              toggleOnArray[ind3] = newDsKpiData2.datasets[c];
              ind3++;
            } else if (
              dsKpiData2.datasets[0].DealID !== newDsKpiData2.datasets[c].DealID &&
              dsKpiData2.datasets[0].EntityName === newDsKpiData2.datasets[c].EntityName
            ) {
              toggleOnArrayRemoved[newInd2] = newDsKpiData2.datasets[c];
              newInd2++;
            }
          }

          newDsKpiData2.datasets = [];

          for (let n = 0; n < toggleOnArray.length; n++) {
            newDsKpiData2.datasets[n] = toggleOnArray[n];
          }
        } else if (selectedClient === newDsKpiData2.datasets[0].DealID) {
          for (let c = 0; c < newDsKpiData2.datasets.length; c++) {
            if (newDsKpiData2.datasets[0].EntityName !== newDsKpiData2.datasets[c].EntityName) {
              toggleOnArray[ind4] = newDsKpiData2.datasets[c];
              ind4++;
            } else if (
              dsKpiData2.datasets[0].DealID !== newDsKpiData2.datasets[c].DealID &&
              dsKpiData2.datasets[0].EntityName === newDsKpiData2.datasets[c].EntityName
            ) {
              toggleOnArrayRemoved[newInd2] = newDsKpiData2.datasets[c];
              newInd2++;
            }
          }
          //  toggDsKpiData2.datasets[0] = newDsKpiData2.datasets[0];
          for (let n = 1; n <= toggleOnArray.length; n++) {
            newDsKpiData2.datasets[n] = toggleOnArray[n - 1];
          }

          newDsKpiData2.datasets = newDsKpiData2.datasets.splice(0, toggleOnArray.length + 1);
        } else if (
          newDsKpiData2.datasets[0].DealID !== selectedClient &&
          !newDsKpiData2.datasets[0].label.includes("Deal")
        ) {
          for (let c = 0; c < newDsKpiData2.datasets.length; c++) {
            if (dsKpiData2.datasets[0].EntityName !== newDsKpiData2.datasets[c].EntityName) {
              toggleOnArray[ind5] = newDsKpiData2.datasets[c];
              ind5++;
            } else if (
              dsKpiData2.datasets[0].DealID !== newDsKpiData2.datasets[c].DealID &&
              dsKpiData2.datasets[0].EntityName === newDsKpiData2.datasets[c].EntityName
            ) {
              toggleOnArrayRemoved[newInd2] = newDsKpiData2.datasets[c];
              newInd2++;
            }
          }

          newDsKpiData2.datasets = [];

          for (let n = 0; n < toggleOnArray.length; n++) {
            newDsKpiData2.datasets[n] = toggleOnArray[n];
          }
        }
        toggleLength = toggleOnArrayRemoved.length;
        ListLength = newDsKpiData2.datasets.length;
        if (toggleLength > 0) {
          compareToggleList.forEach((element, i) => {
            finalCompareList.push({
              label: element.EntityName,
              lineTension: 0.4,
              borderColor: randomItem[i + 6],
              backgroundColor: randomItem[i + 6],
              fill: false,
              borderCapStyle: "square",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              City: element.City,
              DealID: element.DealID,
              EntityName: element.EntityName,
              Offering: element.Offering,
              Industry: element.Industry,
              ProcessName: element.Process,
              pointBorderColor: randomItem[i + 6],
              pointBackgroundColor: "white",
              pointBorderWidth: 3,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: randomItem[i + 6],
              pointHoverBorderColor: randomItem[i + 6],
              pointHoverBorderWidth: 2,
              pointRadius: 5,
              pointHitRadius: 2,
              data: [null, ...element.MetricValue],
              spanGaps: true,
              showLineValues: false,
            });
          });
          if (finalCompareList.length > 0) {
            for (let yy = 0; yy < toggleLength; yy++) {
              newDsKpiData2.datasets[ListLength + yy] = finalCompareList[yy];
            }
          }
        }
      }

      if (isToggleOn === false && ShowDeals === true) {
        for (let i = 0; i < newDsKpiData2.datasets.length; i++) {
          for (let h = 0; h < filtereddata.length; h++) {
            if (newDsKpiData2.datasets[i].DealID === filtereddata[h].DealID) {
              newDsKpiData2.datasets[i].label = filtereddata[h].EntityName;
            }
          }
        }
      }
      //Slider Logic

      for (let s = 0; s < newDsKpiData2.datasets.length; s++) {
        if (newDsKpiData2.datasets[s] === undefined) {
          break;
        }
        for (let j = 0; j < newDsKpiData2.datasets[s].data.length; j++) {
          if (
            parseFloat(rangeValues.min) <= parseFloat(dsKpiData2.datasets[s].data[j]) &&
            parseFloat(rangeValues.max) >= parseFloat(dsKpiData2.datasets[s].data[j])
          ) {
            newDsKpiData2.datasets[s].data[j] = parseFloat(dsKpiData2.datasets[s].data[j]);
          } else {
            newDsKpiData2.datasets[s].data[j] = null;
          }
        }
      }
      for (let ss = 0; ss < newDsKpiData2.datasets.length; ss++) {
        for (let k = 0; k < newDsKpiData2.datasets[ss].data.length; k++) {
          if (newDsKpiData2.datasets[ss].data[k] === null) {
            flag[ss] = 1;
          } else if (newDsKpiData2.datasets[ss].data[k] !== null) {
            flag[ss] = 0;
            break;
          }
        }
      }
      for (let m = 0; m < newDsKpiData2.datasets.length; m++) {
        if (flag[m] === 1) {
          nullDataClients[ind] = newDsKpiData2.datasets[m];
          ind++;
        } else if (flag[m] === 0) {
          notNullDataClients[ind2] = newDsKpiData2.datasets[m];
          ind2++;
        }
      }

      newDsKpiData2.datasets = [];

      for (let n = 0; n < notNullDataClients.length; n++) {
        newDsKpiData2.datasets.push(notNullDataClients[n]);
      }
    }
    if (
      parseFloat(rangeValues.min) !== parseFloat(sliderValues.min) ||
      parseFloat(rangeValues.max) !== parseFloat(sliderValues.max)
    ) {
      // if(newUpdatedDsKpiData2!==null || (newUpdatedDsKpiData2.datasets!==null||undefined)){
      //   newDsKpiData2.datasets = newUpdatedDsKpiData2;
      // }
      if (ShowDeals === true && isToggleOn === true) {
        for (let i = 0; i < newDsKpiData2.datasets.length; i++) {
          for (let h = 0; h < filtereddata.length; h++) {
            if (newDsKpiData2.datasets[i].DealID === filtereddata[h].DealID) {
              newDsKpiData2.datasets[i].label = filtereddata[h].EntityName;
            }
          }
        }

        selectedClient = dsKpiData2.datasets[0].DealID;

        if (newDsKpiData2.datasets[0].label.includes("Deal")) {
          for (let c = 0; c < newDsKpiData2.datasets.length; c++) {
            if (dsKpiData2.datasets[0].EntityName !== newDsKpiData2.datasets[c].EntityName) {
              toggleOnArray[ind3] = newDsKpiData2.datasets[c];
              ind3++;
            } else if (
              dsKpiData2.datasets[0].DealID !== newDsKpiData2.datasets[c].DealID &&
              dsKpiData2.datasets[0].EntityName === newDsKpiData2.datasets[c].EntityName
            ) {
              toggleOnArrayRemoved[newInd2] = newDsKpiData2.datasets[c];
              newInd2++;
            }
          }

          newDsKpiData2.datasets = [];

          for (let n = 0; n < toggleOnArray.length; n++) {
            newDsKpiData2.datasets[n] = toggleOnArray[n];
          }
        } else if (selectedClient === newDsKpiData2.datasets[0].DealID) {
          for (let c = 0; c < newDsKpiData2.datasets.length; c++) {
            if (newDsKpiData2.datasets[0].EntityName !== newDsKpiData2.datasets[c].EntityName) {
              toggleOnArray[ind4] = newDsKpiData2.datasets[c];
              ind4++;
            } else if (
              dsKpiData2.datasets[0].DealID !== newDsKpiData2.datasets[c].DealID &&
              dsKpiData2.datasets[0].EntityName === newDsKpiData2.datasets[c].EntityName
            ) {
              toggleOnArrayRemoved[newInd2] = newDsKpiData2.datasets[c];
              newInd2++;
            }
          }
          //  toggDsKpiData2.datasets[0] = newDsKpiData2.datasets[0];
          for (let n = 1; n <= toggleOnArray.length; n++) {
            newDsKpiData2.datasets[n] = toggleOnArray[n - 1];
          }

          newDsKpiData2.datasets = newDsKpiData2.datasets.splice(0, toggleOnArray.length + 1);
        } else if (
          newDsKpiData2.datasets[0].DealID !== selectedClient &&
          !newDsKpiData2.datasets[0].label.includes("Deal")
        ) {
          for (let c = 0; c < newDsKpiData2.datasets.length; c++) {
            if (dsKpiData2.datasets[0].EntityName !== newDsKpiData2.datasets[c].EntityName) {
              toggleOnArray[ind5] = newDsKpiData2.datasets[c];
              ind5++;
            } else if (
              dsKpiData2.datasets[0].DealID !== newDsKpiData2.datasets[c].DealID &&
              dsKpiData2.datasets[0].EntityName === newDsKpiData2.datasets[c].EntityName
            ) {
              toggleOnArrayRemoved[newInd2] = newDsKpiData2.datasets[c];
              newInd2++;
            }
          }

          newDsKpiData2.datasets = [];

          for (let n = 0; n < toggleOnArray.length; n++) {
            newDsKpiData2.datasets[n] = toggleOnArray[n];
          }
        }
        toggleLength = toggleOnArrayRemoved.length;
        ListLength = newDsKpiData2.datasets.length;
        if (toggleLength > 0) {
          compareToggleList.forEach((element, i) => {
            finalCompareList.push({
              label: element.EntityName,
              lineTension: 0.4,
              borderColor: randomItem[i + 6],
              backgroundColor: randomItem[i + 6],
              fill: false,
              borderCapStyle: "square",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              City: element.City,
              DealID: element.DealID,
              EntityName: element.EntityName,
              Offering: element.Offering,
              Industry: element.Industry,
              ProcessName: element.Process,
              pointBorderColor: randomItem[i + 6],
              pointBackgroundColor: "white",
              pointBorderWidth: 3,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: randomItem[i + 6],
              pointHoverBorderColor: randomItem[i + 6],
              pointHoverBorderWidth: 2,
              pointRadius: 5,
              pointHitRadius: 2,
              data: [null, ...element.MetricValue],
              spanGaps: true,
              showLineValues: false,
            });
          });
          if (finalCompareList.length > 0) {
            for (let yy = 0; yy < toggleLength; yy++) {
              newDsKpiData2.datasets[ListLength + yy] = finalCompareList[yy];
            }
          }
        }
      }

      if (isToggleOn === true && ShowDeals === false) {
        selectedClient = dsKpiData2.datasets[0].DealID;

        if (newDsKpiData2.datasets[0].label.includes("Deal")) {
          for (let c = 0; c < newDsKpiData2.datasets.length; c++) {
            if (dsKpiData2.datasets[0].EntityName !== newDsKpiData2.datasets[c].EntityName) {
              toggleOnArray[ind3] = newDsKpiData2.datasets[c];
              ind3++;
            } else if (
              dsKpiData2.datasets[0].DealID !== newDsKpiData2.datasets[c].DealID &&
              dsKpiData2.datasets[0].EntityName === newDsKpiData2.datasets[c].EntityName
            ) {
              toggleOnArrayRemoved[newInd2] = newDsKpiData2.datasets[c];
              newInd2++;
            }
          }

          newDsKpiData2.datasets = [];

          for (let n = 0; n < toggleOnArray.length; n++) {
            newDsKpiData2.datasets[n] = toggleOnArray[n];
          }
        } else if (selectedClient === newDsKpiData2.datasets[0].DealID) {
          for (let c = 0; c < newDsKpiData2.datasets.length; c++) {
            if (newDsKpiData2.datasets[0].EntityName !== newDsKpiData2.datasets[c].EntityName) {
              toggleOnArray[ind4] = newDsKpiData2.datasets[c];
              ind4++;
            } else if (
              dsKpiData2.datasets[0].DealID !== newDsKpiData2.datasets[c].DealID &&
              dsKpiData2.datasets[0].EntityName === newDsKpiData2.datasets[c].EntityName
            ) {
              toggleOnArrayRemoved[newInd2] = newDsKpiData2.datasets[c];
              newInd2++;
            }
          }
          //  toggDsKpiData2.datasets[0] = newDsKpiData2.datasets[0];
          for (let n = 1; n <= toggleOnArray.length; n++) {
            newDsKpiData2.datasets[n] = toggleOnArray[n - 1];
          }

          newDsKpiData2.datasets = newDsKpiData2.datasets.splice(0, toggleOnArray.length + 1);
        } else if (
          newDsKpiData2.datasets[0].DealID !== selectedClient &&
          !newDsKpiData2.datasets[0].label.includes("Deal")
        ) {
          for (let c = 0; c < newDsKpiData2.datasets.length; c++) {
            if (dsKpiData2.datasets[0].EntityName !== newDsKpiData2.datasets[c].EntityName) {
              toggleOnArray[ind5] = newDsKpiData2.datasets[c];
              ind5++;
            } else if (
              dsKpiData2.datasets[0].DealID !== newDsKpiData2.datasets[c].DealID &&
              dsKpiData2.datasets[0].EntityName === newDsKpiData2.datasets[c].EntityName
            ) {
              toggleOnArrayRemoved[newInd2] = newDsKpiData2.datasets[c];
              newInd2++;
            }
          }

          newDsKpiData2.datasets = [];

          for (let n = 0; n < toggleOnArray.length; n++) {
            newDsKpiData2.datasets[n] = toggleOnArray[n];
          }
        }
        toggleLength = toggleOnArrayRemoved.length;
        ListLength = newDsKpiData2.datasets.length;
        if (toggleLength > 0) {
          compareToggleList.forEach((element, i) => {
            finalCompareList.push({
              label: element.EntityName,
              lineTension: 0.4,
              borderColor: randomItem[i + 6],
              backgroundColor: randomItem[i + 6],
              fill: false,
              borderCapStyle: "square",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              City: element.City,
              DealID: element.DealID,
              EntityName: element.EntityName,
              Offering: element.Offering,
              Industry: element.Industry,
              ProcessName: element.Process,
              pointBorderColor: randomItem[i + 6],
              pointBackgroundColor: "white",
              pointBorderWidth: 3,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: randomItem[i + 6],
              pointHoverBorderColor: randomItem[i + 6],
              pointHoverBorderWidth: 2,
              pointRadius: 5,
              pointHitRadius: 2,
              data: [null, ...element.MetricValue],
              spanGaps: true,
              showLineValues: false,
            });
          });
          if (finalCompareList.length > 0) {
            for (let yy = 0; yy < toggleLength; yy++) {
              newDsKpiData2.datasets[ListLength + yy] = finalCompareList[yy];
            }
          }
        }
      }

      if (isToggleOn === false && ShowDeals === true) {
        for (let i = 0; i < newDsKpiData2.datasets.length; i++) {
          for (let h = 0; h < filtereddata.length; h++) {
            if (newDsKpiData2.datasets[i].DealID === filtereddata[h].DealID) {
              newDsKpiData2.datasets[i].label = filtereddata[h].EntityName;
            }
          }
        }
      }
      //Slider Logic

      for (let s = 0; s < newDsKpiData2.datasets.length; s++) {
        if (newDsKpiData2.datasets[s] === undefined) {
          break;
        }
        for (let j = 0; j < newDsKpiData2.datasets[s].data.length; j++) {
          if (
            parseFloat(rangeValues.min) <= parseFloat(dsKpiData2.datasets[s].data[j]) &&
            parseFloat(rangeValues.max) >= parseFloat(dsKpiData2.datasets[s].data[j])
          ) {
            newDsKpiData2.datasets[s].data[j] = parseFloat(dsKpiData2.datasets[s].data[j]);
          } else {
            newDsKpiData2.datasets[s].data[j] = null;
          }
        }
      }
      for (let ss = 0; ss < newDsKpiData2.datasets.length; ss++) {
        for (let k = 0; k < newDsKpiData2.datasets[ss].data.length; k++) {
          if (newDsKpiData2.datasets[ss].data[k] === null) {
            flag[ss] = 1;
          } else if (newDsKpiData2.datasets[ss].data[k] !== null) {
            flag[ss] = 0;
            break;
          }
        }
      }
      for (let m = 0; m < newDsKpiData2.datasets.length; m++) {
        if (flag[m] === 1) {
          nullDataClients[ind] = newDsKpiData2.datasets[m];
          ind++;
        } else if (flag[m] === 0) {
          notNullDataClients[ind2] = newDsKpiData2.datasets[m];
          ind2++;
        }
      }

      newDsKpiData2.datasets = [];

      for (let n = 0; n < notNullDataClients.length; n++) {
        newDsKpiData2.datasets.push(notNullDataClients[n]);
      }
    }
    this.setState(
      {
        newUpdatedDsKpiData2: newDsKpiData2.datasets,
        newDsKpiData2: {
          labels,
          datasets: newDsKpiData2.datasets,
        },
      },
      () => {}
    );
    this.forceUpdate();
  };
 

  render() {
    const {
      showErrorMsg,
      info,
      kpiUnit,
      dealFilter,
      roledata,
      kpiMappingId,
      kpiName,
      roleReducers,
      ClientData,
      FilterDetails,
      Month,
    } = this.props;
    const {
      autoCompleteDdlClientsList,
      OfferingName,
      ProcessName,
      show,
      dsKpiData,
      temp,
      filter,
      IndustryGroup,
      clientRegion,
      maturityPhase,
      period,
      startDate,
      endDate,
      ShowDeals,
      isDisabled,
      isToggleOn,
      loading,
      dsKpiData2,
      newDsKpiData2,
      selValueDdl,
      dealOnelabel,
      dealOnevalue,
      diffdeal1,
      percentageChangeDealOne,
      dealTwolabel,
      dealTwovalue,
      percentageChangeDealTwo,
      diffdeal2,
      dealComparisonGraphData,
      stoldDealOneValue,
      stoldDealTwoValue,
      trendAvg,
      sliderValues,
    } = this.state;
    const isMultiLevel = info.IsMultiLevel;
    const metricID = info.MetricID;
    const dealListarr = [];

    autoCompleteDdlClientsList.forEach((arrayItem) => {
      dealListarr.push({
        name: arrayItem.OrgEntityName,
        id: arrayItem.OrgEntityID,
        abbr: arrayItem.OrgEntityName.substr(0, 2),
      });
    });

    Chart.defaults.global.defaultFontColor = "black";
    let ToolTip = "";
    ToolTip = `${OfferingName} - ${ProcessName}`;

    const kpiMUnit = kpiUnit;
    const sliderMin = _.cloneDeep(sliderValues.min);
    const sliderMax = _.cloneDeep(sliderValues.max);
    const sliderMinClone = Math.floor(parseFloat(_.cloneDeep(sliderMin)));

    const sliderMaxClone = Math.ceil(parseFloat(_.cloneDeep(sliderMax)));

    return (
      <Modal
        show={show}
        onHide={this.handleHide}
        backdrop="static"
        dialogClassName="trendGraphModal"
        size="xl"
        className="modal fade v-center trendGraph-comments"
        id="dealpopup"
      >
        <Modal.Header closeButton className="no-border">
          <h4 className="modal-title" id="dealprofile">
            {" "}
            {kpiName}
          </h4>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultTab="one"
            onChange={(tabId) => {
              this.handleSelect(tabId);
            }}
          >
            <TabList>
              <Tab tabFor="one">
                <span data-tip={ToolTip} data-for={"tooltip"}>
                  {i18n.t("Monthly_Trend_12_Months")}
                </span>
              </Tab>
              <Tab tabFor="five">
                <span data-tip={ToolTip} data-for={"tooltip"}>
                  {i18n.t("Metric_Deep_Dive")}
                </span>
              </Tab>
              <Tab tabFor="two">
                <span data-tip={ToolTip} data-for={"tooltip"}>
                  {i18n.t("Cross_Client_Analysis")}
                </span>
              </Tab>
              <Tab tabFor="three">
                <span data-tip={ToolTip} data-for={"tooltip"}>
                  {i18n.t("Cross_Metric_Analysis")}
                </span>
              </Tab>
              <Tab tabFor="BOITrends">
                <span data-tip={ToolTip} data-for={"tooltip"}>
                  {i18n.t("BOI_Trends")}
                </span>
              </Tab>
              <Tab eventKey={1} tabFor="MindMap">
                <span data-tip={ToolTip} data-for={"tooltip"}>
                  Mind Map
                </span>
              </Tab>
            </TabList>
            <TabPanel tabId="one">
              {this.state.showComments ? (
                <div className="showcomments-tab">
                  <img src={HideChat} className="mr-1" />
                  <span onClick={this.onShowHideButtonClick}>{i18n.t("Hide Comments")}</span>
                </div>
              ) : (
                <div className="showcomments-tab">
                  <img src={ShowChat} className="mr-1" />
                  <span onClick={this.onShowHideButtonClick}>{i18n.t("Show Comments")}</span>
                </div>
              )}
              {this.state.isApiLoading ? (
                <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />
              ) : (
                ""
              )}
              <div className="linechart-commentry">
                <div
                  className={
                    this.state.showComments
                      ? "linechart-division"
                      : "linechart-division nocommentary-section"
                  }
                >
                  <div className="custom-chartlegend">
                    <ul>
                      <li
                        onClick={() => {
                          this.onLegendClick(i18n.t("Metric_Value"));
                          this.MetricClick();
                        }}
                      >
                        <span className="metric-legendvalue">
                          <img src={MetricValueIcon} />
                        </span>
                        {this.state.isMetricOn ? (
                          i18n.t("Metric_Value")
                        ) : (
                          <s>{i18n.t("Metric_Value")}</s>
                        )}
                      </li>

                      {this.state.LowerThresholdMean && (
                        <li
                          onClick={() => {
                            this.onLegendClick(i18n.t("Lower_Threshold"));
                            this.LowerClick();
                          }}
                        >
                          <span className="lowerquartile-legendvalue crossed-line">
                            <img src={LowerQuartileIcon} />
                          </span>
                          {this.state.isLowerOn ? (
                            i18n.t("Lower_Threshold")
                          ) : (
                            <s>{i18n.t("Lower_Threshold")}</s>
                          )}
                        </li>
                      )}

                      <li
                        onClick={() => {
                          this.onLegendClick(i18n.t("Median"));
                          this.MedianClick();
                        }}
                      >
                        <span className="median-legendvalue">
                          <img src={MedianIcon} />
                        </span>
                        {this.state.isMedianOn ? i18n.t("Median") : <s>{i18n.t("Median")}</s>}
                      </li>

                      {this.state.UpperThresholdMean && (
                        <li
                          onClick={() => {
                            this.onLegendClick(i18n.t("Upper_Threshold"));
                            this.UpperClick();
                          }}
                        >
                          <span className="upperquartile-legendvalue crossed-line">
                            <img src={UpperQuartileIcon} />
                          </span>
                          {this.state.isUpperOn ? (
                            i18n.t("Upper_Threshold")
                          ) : (
                            <s>{i18n.t("Upper_Threshold")}</s>
                          )}
                        </li>
                      )}

                      {this.state.BaselineMean && (
                        <li
                          onClick={() => {
                            this.onLegendClick(i18n.t("Baseline"));
                            this.BaselineClick();
                          }}
                        >
                          <span className="baseline-legendvalue crossed-line">
                            <img src={BaselineIcon} />
                          </span>
                          {this.state.isBaselineOn ? (
                            i18n.t("Baseline")
                          ) : (
                            <s>{i18n.t("Baseline")}</s>
                          )}
                        </li>
                      )}

                      {this.state.TargetMean && (
                        <li
                          onClick={() => {
                            this.onLegendClick(i18n.t("Target"));
                            this.TargetClick();
                          }}
                        >
                          <span className="target-legendvalue crossed-line">
                            <img src={TargetIcon} />
                          </span>
                          {this.state.isTargetOn ? i18n.t("Target") : <s>{i18n.t("Target")}</s>}
                        </li>
                      )}
                    </ul>
                  </div>
                  {this.state.baselineMonthValue[0] && (
                    <h6 className="Baseline-month">
                      {i18n.t("Baseline_Month")}:{this.state.baselineMonthValue[0]}
                    </h6>
                  )}
                  <Line
                    id="LineChart"
                  
                    data={this.state.dsKpiData}
                    redraw="true"
                    options={{
                      maintainAspectRatio: false,
                      legendCallback() {
                        const lengendHTML = `
                           <div className="custom-chartlegend">
                               <ul>
                                   <li>Metric Value</li>
                                   <li>Lower_Threshold</li>
                                   <li>Median</li>
                                   <li>Upper_Threshold</li>
                                   <li>Baseline</li>
                                   <li>Target</li>
                               </ul>
                           </div>`;
                        return lengendHTML;
                      },
                      responsive: true,
                      layout: {
                        padding: {
                          top: -40,
                          left: 0,
                          right: 40,
                          bottom: 40,
                        },
                      },
                      onClick: (e, element) => {
                        if (element.length > 0) {
                          const monthNames = [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            "Jul",
                            "Aug",
                            "Sep",
                            "Oct",
                            "Nov",
                            "Dec",
                          ];
                          let monYear;
                          if (element.length > 0) {
                            const ind = element[0]._index;
                            const tempMonYear = this.state.dsKpiData.labels[ind];
                            const objDate = new Date(tempMonYear);
                            monYear = `${monthNames[objDate.getMonth()]} 20${objDate.getDate()}`;
                            store.dispatch({
                              type: "SYNC_COMMENTFILTER",
                              payload: monYear,
                            });
                          }
                          const backgroundColorValues = [""];
                          this.state.temp.map((each) => {
                            if (each.PeriodDt == monYear) {
                              backgroundColorValues.push("rgb(0, 186, 255)");
                            } else {
                              backgroundColorValues.push("white");
                            }
                          });
                          this.setState({
                            backgroundColorValues,
                          });
                        }
                      },
                      tooltips: {
                        enabled: true,
                        mode: "nearest",
                        intersect: true,

                        callbacks: {
                          title: function (tooltipItem) {
                            const currentIndex = tooltipItem[0].index;
                            if (currentIndex) {
                              return this.state.temp[currentIndex - 1].Comments
                                ? this.state.temp[currentIndex - 1].Comments
                                : i18n.t("NA");
                            }
                          }.bind(this),
                        },
                      },
                      hover: {
                        mode: "index",
                        intersect: true,
                      },
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              precision: 1,
                              fontColor: "#a3a3a3",
                              fontFamily: "Graphik-Medium",
                              fontSize: 10,
                              fontWeight: 500,
                            },
                            gridLines: {
                              drawOnChartArea: true,
                            },

                            scaleLabel: {
                              display: true,
                              labelString: kpiMUnit,
                              color: "#637381",
                              fontSize: 12,
                              fontFamily: "Graphik-Medium",
                              fontWeight: 500,
                            },
                          },
                        ],
                        xAxes: [
                          {
                            ticks: {
                              fontColor: "#a3a3a3",
                              fontFamily: "Graphik-Medium",
                              fontSize: 10,
                              fontWeight: 500,
                            },
                            gridLines: {
                              drawOnChartArea: true,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: i18n.t("Month"),
                              color: "#637381",
                              fontSize: 12,
                              fontFamily: "Graphik-Medium",
                              fontWeight: 500,
                            },
                          },
                        ],
                      },
                      plugins: {
                        datalabels: {
                          formatter(value) {
                            return forNum(value, 2);
                          },
                          align: "right",
                          offset: 1,
                          display(context) {
                            if (
                              context.dataIndex === context.dataset.data.length - 1 &&
                              context.dataset.label === i18n.t("Lower_Threshold")
                            )
                              return true;
                            if (
                              context.dataIndex === context.dataset.data.length - 1 &&
                              context.dataset.label === i18n.t("Median")
                            )
                              return true;
                            if (
                              context.dataIndex === context.dataset.data.length - 1 &&
                              context.dataset.label === i18n.t("Upper_Threshold")
                            )
                              return true;
                            if (
                              context.dataIndex === context.dataset.data.length - 1 &&
                              context.dataset.label === i18n.t("Baseline")
                            )
                              return true;
                            if (
                              context.dataIndex === context.dataset.data.length - 1 &&
                              context.dataset.label === i18n.t("Target")
                            )
                              return true;
                            return false;
                          },
                          borderRadius: 50,
                          color: "white",
                          font: {
                            weight: 500,
                            size: 11,
                            family: "Graphik-Medium",
                          },

                          padding: {
                            left: 4,
                            right: 4,
                            top: 4,
                            bottom: 4,
                          },
                        },
                      },
                      legend: {
                        display: false,
                      },
                      annotation: {
                        drawTime: "afterDraw",
                        annotations: [],
                      },
                    }}
                  />
                </div>
                {this.props.kpiMappingId && this.state.showComments && (
                  <>
                    <div className="commentrychat-division">
                      <InsightsChatWindow
                        GetKpiData={this.getKpiData}
                        monthComment={this.state.dsKpiData.labels}
                        commentData={this.state.temp}
                        kpiMappingId={this.props.kpiMappingId}
                        kpiName={this.props.kpiName}
                        newNotificationList={this.state.newNotificationList}
                      />
                    </div>
                  </>
                )}
              </div>
            </TabPanel>

            <TabPanel tabId="five">
              <MetricDeepDive
                IsMultiLevel={isMultiLevel}
                CategoryInfo={info.CategoryDetails}
                kpiUnit={kpiUnit}
                currentMonth={dealFilter.Month}
                dealFilter={dealFilter}
                metricID={metricID}
                kpiMappingId={this.props.kpiMappingId}
              />
            </TabPanel>
            <TabPanel tabId="two" className="cross-client-analysis-tabcontent">
              <div className="form-section mb-3 cross-analysisfields common-formgrouplabel">
                <div className="cross-analysisfields-row">
                  <div className="cross-analysisfields-col">
                    <div className="form-group border-group">
                      <label htmlFor="operatingGroup" className="form-label">
                        {i18n.t("Industry")}
                      </label>

                      <select
                        name="operatingGroup"
                        className="form-control"
                        value={filter.og}
                        onChange={this.setFilter.bind(this, "og")}
                      >
                        <option value="Cross Industry">Cross Industry</option>
                        {IndustryGroup &&
                          IndustryGroup.map((data) => <option value={data}>{data}</option>)}
                      </select>
                    </div>
                    <div className="form-group border-group">
                      <label htmlFor="maturityPhase" className="form-label">
                        {i18n.t("Maturity_Phase")}
                      </label>
                      <select
                        name="maturityPhase"
                        className="form-control"
                        value={filter.mp}
                        onChange={this.setFilter.bind(this, "mp")}
                      >
                        <option value="All">{i18n.t("All")}</option>
                        {maturityPhase &&
                          maturityPhase.map((data) => <option value={data}>{data}</option>)}
                      </select>
                    </div>
                  </div>
                  <div className="cross-analysisfields-col">
                    <h6>
                      Period (6 Months) <span className="required">*</span>
                    </h6>
                    <div className="form-group border-group">
                      <label id="period" htmlFor="period" className="form-label">
                        Start Date
                      </label>
                      <div className="initiative-datepicker form-control">
                        <label className="datepicker-label-wrapper">
                          <DatePicker
                            className="form-control"
                            selected={period.from}
                            id="StartDate"
                            onSelect={this.onPeriodChange.bind(this, "From")}
                            value={startDate}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            maxDate={new Date()}

                          />
                          <span className="calender-placment">
                            <i className="fal fa-calendar-alt" />
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="form-group border-group">
                      <label id="period" htmlFor="period" className="form-label">
                        End Date
                      </label>
                      <div className="initiative-datepicker form-control">
                        <label className="datepicker-label-wrapper">
                          <DatePicker
                            className="form-control"
                            selected={period.to}
                            id="EndDate"
                            onSelect={this.onPeriodChange.bind(this, "To")}
                            value={endDate}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            maxDate={new Date()}
                          />
                          <span className="calender-placment">
                            <i className="fal fa-calendar-alt" />
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="cross-analysis-slider">
                    <h3>
                      Value Range{" "}
                      <span>
                        {parseFloat(this.state.rangeValues.min).toFixed(2)} -{" "}
                        {parseFloat(this.state.rangeValues.max).toFixed(2)}
                      </span>
                    </h3>
                    <InputRange
                      draggableTrack
                      step={0.01}
                      maxValue={sliderMaxClone}
                      minValue={sliderMinClone}
                      onChange={(rangeValue) => {
                        this.setState({ rangeValues: rangeValue }, () => {
                          this.sliderValueFilter(rangeValue);
                        });
                      }}
                      value={this.state.rangeValues}
                    />
                  </div>
                </div>
                <div className="showcompare-row">

                  {roledata && roledata.includes("Show Deals") ? (
                    <div className="form-group mr-3">
                      <label className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          disabled ={this.state.isDisabled}
                          defaultChecked={ShowDeals}
                          onChange={this.onShowDealChange}
                        />
                        <span className="checkmark" />
                        <span className="form-check-label">{i18n.t("Show_Clients")}</span>
                      </label>
                    </div>
                  ) : null}
                  <div className="form-group toggle-group custom-toggleswitch mr-5">
                    <span
                      className={
                        isToggleOn
                          ? "toggleswitch-text toggleswitchactive mr-1"
                          : "toggleswitch-text mr-1"
                      }
                    >
                      Compare with only other Client
                    </span>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="isAct"
                      id="isActive"
                      defaultChecked={isToggleOn}
                      disabled ={this.state.isDisabled}

                      onChange={this.onToggleCompareChange}
                      disabled = {this.state.dsKpiData2.length> 0? false: true}

                    />
                    <label htmlFor="isActive">Is Active</label>
                    <div className="onoffswitch" aria-hidden="true">
                      <div className="onoffswitch-label">
                        <div className="onoffswitch-inner"></div>
                        <div className="onoffswitch-switch"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.isApiLoading ? (
                <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />
              ) : (
                ""
              )}
              <Line
                title=""
                data={newDsKpiData2}
                redraw="true"
                height={"90vh"}
                type="line"
                options={{
                  responsive: true,
                  maintainAspectRatio: true,
                  layout: {
                    padding: {
                      bottom: 40,
                      right: 40,
                    },
                  },
                  tooltips: {
                    enabled: true,
                    mode: "single",
                    intersect: true,
                    custom(tooltip) {
                      if (!tooltip) return;
                      // disable displaying the color box;
                      tooltip.displayColors = false;
                    },

                    callbacks: {
                      title() {
                        return "";
                      },
                      label(tooltipItem, newDsKpiData2) {
                        const DealName = `${
                          newDsKpiData2.datasets[tooltipItem.datasetIndex].label
                        }`;
                        const label = `Value : ${tooltipItem.value}`;
                        const SubOffering = `Sub-Offering : ${
                          newDsKpiData2.datasets[tooltipItem.datasetIndex].ProcessName
                        }`;
                        const Industry = `Industry : ${
                          newDsKpiData2.datasets[tooltipItem.datasetIndex].Industry
                        }`;
                        const Location = `Location : ${
                          newDsKpiData2.datasets[tooltipItem.datasetIndex].City
                        }`;
                        if (roledata && roledata.includes("Show Deals")) {
                          return [DealName, label, SubOffering, Industry, Location];
                        } else {
                          return [DealName, label, Industry];
                        }
                      },
                    },
                  },
                  hover: {
                    mode: "single",
                    intersect: true,
                  },
                  scales: {
                    xAxes: [
                      {
                        barThickness: 50,
                        stacked: false,

                        ticks: {
                          beginAtZero: true,
                          suggestedMin: 5,
                          fontColor: "#919eab",
                          fontFamily: "graphik",
                          fontSize: 11,
                        },

                        scaleLabel: {
                          display: true,
                          labelString: i18n.t("Timeframe"),
                          crosshair: true,
                          fontColor: "#637381",
                          fontFamily: "Graphik-Medium",
                          fontSize: 12,
                        },
                        marker: {
                          enabled: false,
                        },

                        gridLines: {
                          display: false,
                        },
                      },
                    ],

                    yAxes: [
                      {
                        gridLines: {
                          display: true,
                          drawBorder: false,
                        },
                        ticks: {
                          min: 0,
                          precision: 1,
                          fontColor: "#919eab",
                          fontFamily: "graphik",
                          fontSize: 11,
                          beginAtZero: true,
                        },
                        scaleLabel: {
                          display: true,
                          labelString: kpiMUnit,
                          fontColor: "black",
                        },
                        marker: {
                          enabled: false,
                        },
                      },
                    ],
                  },
                  plugins: {
                    datalabels: {
                      display: false,
                    },
                  },
                  legend: {
                    fontSize: 11,
                    fontColor: "rgb(33,43,54)",
                    fontFamily: "graphik",
                    display: true,
                    itemWidth: 50,
                    align: "start",
                    position: "top",
                    fullWidth: false,
                    labels: {
                      borderRadius: 2,
                      boxHeight: 12,
                      boxWidth: 12,
                    },
                  },
                }}
                plugins={{
                  beforeInit: (chart, legend, options) => {
                    const fitValue = chart.legend.fit;
                    chart.legend.fit = function fit() {
                      fitValue.bind(chart.legend)();
                      return (this.height -= 40);
                    };
                  },
                }}
              />
              <div className="disclaimer">
                Disclaimer : Only those deals are used for comparison which have at least 3 months
                data published for selected date range.
              </div>
            </TabPanel>

            <TabPanel tabId="three">
              <CrossMetricAnalysis
                kpiMappingId={kpiMappingId}
                kpiName={kpiName}
                kpiUnit={kpiUnit}
                dealFilter={dealFilter}
              />
            </TabPanel>
            <TabPanel tabId="four">
              {/* row for searchable dropdown */}
              <div className="row p-b-10 p-t-5">
                <div className="col-md-12 select-data" id="dealSearchAutocomplete">
                  <Autocomplete
                    value={selValueDdl}
                    inputProps={{ placeholder: "Select a client..." }}
                    items={dealListarr}
                    getItemValue={(item) => item.name}
                    shouldItemRender={this.matchClientsToSearchTerm}
                    sortItems={this.sortClients}
                    onChange={(event, value) => {
                      this.setState({ selValueDdl: value });
                      const selValueDdlVar = value;

                      this.onChangeHandle(event, value, selValueDdlVar);

                      this.setState({
                        selValueDdl: selValueDdlVar,
                      });
                    }}
                    onSelect={(val, id) => {
                      this.onSelectHandle(val, id);
                    }}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${isHighlighted ? "item-highlighted" : ""}`}
                        key={item.abbr}
                      >
                        {item.name}
                      </div>
                    )}
                  />
                </div>
              </div>

              {/* rows for deal names and % change  */}
              <div className="row p-b-10 p-t-5">
                <div className="col-sm-1">
                  <span className="dot darkest-gray-background" />
                </div>
                <div className="col-sm-7 goc-image-title"> {dealOnelabel}</div>
                <div className="form-group col-lg-4">{dealOnevalue}</div>
                <div
                  className="col-sm-2"
                  className={
                    diffdeal1 < 0 ? "red-font" : diffdeal1 === 0 ? "light-grey-font" : "green-font"
                  }
                >
                  {percentageChangeDealOne}%
                  <span>
                    <u className={percentageChangeDealOne === 0 ? "hidden" : ""}>
                      <FontAwesomeIcon icon={diffdeal1 < 0 ? faArrowDown : faArrowUp} />{" "}
                    </u>
                  </span>
                </div>
              </div>
              <div className="row p-b-10 border-bottom-gray">
                <div className="col-sm-1">
                  <span className="dot light-blue-background" />
                </div>
                <div className="col-sm-7 goc-image-title">{dealTwolabel}</div>
                <div className="form-group col-lg-4">{dealTwovalue}</div>
                <div
                  className="col-sm-2 "
                  className={
                    diffdeal2 < 0 ? "red-font" : diffdeal2 === 0 ? "light-grey-font" : "green-font"
                  }
                >
                  {percentageChangeDealTwo}%
                  <span>
                    <u className={percentageChangeDealTwo === 0 ? "hidden" : ""}>
                      <FontAwesomeIcon icon={diffdeal2 < 0 ? faArrowDown : faArrowUp} />{" "}
                    </u>
                  </span>
                </div>
              </div>

              {/* Empty space for sub tabs */}
              <div className="row">
                <div className="col-lg-12">
                  <Tabs
                    defaultTab="3months"
                    onChange={(parameter) => {
                      this.setNumMonths(parameter);
                    }}
                  >
                    <TabList>
                      <Tab tabFor="3months">3 months</Tab>
                      <Tab tabFor="6months">6 months</Tab>
                      <Tab tabFor="12months">1 year</Tab>
                      <Tab tabFor="max">Max</Tab>
                    </TabList>
                    <TabPanel tabId="3months">
                      {/*
                          Line graph content - Deal comparison tab */}
                      <Line
                        data={dealComparisonGraphData}
                        id="lineChart"
                        redraw="true"
                        plugins={[
                          {
                            beforeEvent(chart, e) {
                              if (
                                e.type === "mousemove" &&
                                e.x >= e.chart.chartArea.left &&
                                e.x <= e.chart.chartArea.right
                              ) {
                                chart.options.customLine.x = e.x;
                              }
                            },

                            afterDraw(chart, easing) {
                              if (chart.tooltip._active && chart.tooltip._active.length) {
                                const activePoint = chart.tooltip._active[0];
                                const { ctx } = chart;
                                const { x } = activePoint.tooltipPosition();
                                const topY = chart.scales["y-axis-0"].top;
                                const bottomY = chart.scales["y-axis-0"].bottom;

                                // draw line
                                ctx.save();
                                ctx.beginPath();
                                ctx.moveTo(x, topY);
                                ctx.lineTo(x, bottomY);
                                ctx.lineWidth = 2;
                                ctx.strokeStyle = "#000";
                                ctx.setLineDash([5, 15]);
                                ctx.stroke();
                                ctx.restore();
                              }
                            },
                          },
                        ]}
                        options={{
                          maintainAspectRatio: true,
                          responsive: true,
                          layout: {
                            padding: {
                              top: 20,
                            },
                          },
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  precision: 20,
                                },
                                gridLines: {
                                  drawOnChartArea: true,
                                },

                                scaleLabel: {
                                  display: true,
                                  labelString: kpiMUnit,
                                },
                              },
                            ],
                            xAxes: [
                              {
                                gridLines: {
                                  drawOnChartArea: true,
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: "Month",
                                },
                              },
                            ],
                          },

                          customLine: {
                            color: "black",
                          },
                          tooltips: {
                            intersect: false,
                            mode: "index",

                            callbacks: {
                              afterLabel: function (tooltipItem, data) {
                                let label = data.datasets[tooltipItem.datasetIndex].label || "";
                                if (tooltipItem.datasetIndex === 0) {
                                  const dpts = data.datasets[tooltipItem.datasetIndex].data;

                                  const indexFound = dpts.findIndex(
                                    (k) => k === tooltipItem.yLabel
                                  );
                                  if (indexFound > 0) {
                                    var stOldNew = dpts[indexFound - 1];
                                  } else {
                                    stOldNew = dpts[0];
                                  }
                                  this.setState({
                                    stoldDealOneValue: stOldNew,
                                    dealOnevalue: tooltipItem.yLabel,
                                  });
                                } else {
                                  stOldNew = 0;
                                  const dpts = data.datasets[tooltipItem.datasetIndex].data;

                                  const indexFound = dpts.findIndex(
                                    (k) => k === tooltipItem.yLabel
                                  );
                                  if (indexFound > 0) {
                                    stOldNew = dpts[indexFound - 1];
                                  } else {
                                    stOldNew = dpts[0];
                                  }

                                  this.setState({
                                    stoldDealTwoValue: stOldNew,
                                    dealTwovalue: tooltipItem.yLabel,
                                  });
                                }

                                this.setState({
                                  diffdeal1: dealOnevalue - stoldDealOneValue,
                                  diffdeal2: dealTwovalue - stoldDealTwoValue,
                                });

                                if (stoldDealOneValue !== 0) {
                                  this.setState({
                                    percentageChangeDealTwo:
                                      Math.round((diffdeal1 / stoldDealOneValue) * 100 * 100) / 100,
                                  });
                                } else {
                                  this.setState({
                                    percentageChangeDealOne: 100,
                                  });
                                }

                                if (stoldDealTwoValue !== 0) {
                                  this.setState({
                                    percentageChangeDealTwo:
                                      Math.round((diffdeal2 / stoldDealTwoValue) * 100 * 100) / 100,
                                  });
                                } else {
                                  this.setState({
                                    percentageChangeDealTwo: 100,
                                  });
                                }

                                if (label) {
                                  label += ": ";
                                }
                                label += Math.round(tooltipItem.yLabel * 100) / 100;

                                return "";
                              }.bind(this),

                              label(tooltipItem, data) {
                                let label = data.datasets[tooltipItem.datasetIndex].label || "";
                                if (tooltipItem.datasetIndex === 0) {
                                } else {
                                }

                                if (label) {
                                  label += ": ";
                                }
                                label += Math.round(tooltipItem.yLabel * 100) / 100;

                                return label;
                              },
                            },
                          },

                          hover: {
                            mode: "index",
                            intersect: false,
                          },

                          annotation: {
                            drawTime: "afterDraw",
                            annotations: [
                              {
                                id: "a-line-1",
                                drawTime: "afterDraw",
                                type: "line",
                                mode: "horizontal",
                                scaleID: "y-axis-0",
                                value: trendAvg,
                                borderColor: "#f99245 ",
                                borderWidth: 2,
                                borderDash: [2, 2],
                                borderDashOffset: 5,
                                label: {
                                  enabled: false,
                                  content: "Test label",
                                },
                              },
                            ],
                          },
                        }}
                      />
                    </TabPanel>
                    <TabPanel tabId="6months">
                      {/*
                          Line graph content - Deal comparison tab */}
                      <Line
                        data={dealComparisonGraphData}
                        id="lineChart"
                        redraw="true"
                        plugins={[
                          {
                            beforeEvent(chart, e) {
                              if (
                                e.type === "mousemove" &&
                                e.x >= e.chart.chartArea.left &&
                                e.x <= e.chart.chartArea.right
                              ) {
                                chart.options.customLine.x = e.x;
                              }
                            },

                            afterDraw(chart, easing) {
                              if (chart.tooltip._active && chart.tooltip._active.length) {
                                const activePoint = chart.tooltip._active[0];
                                const { ctx } = chart;
                                const { x } = activePoint.tooltipPosition();
                                const topY = chart.scales["y-axis-0"].top;
                                const bottomY = chart.scales["y-axis-0"].bottom;

                                // draw line
                                ctx.save();
                                ctx.beginPath();
                                ctx.moveTo(x, topY);
                                ctx.lineTo(x, bottomY);
                                ctx.lineWidth = 2;
                                ctx.strokeStyle = "#000";
                                ctx.setLineDash([5, 15]);
                                ctx.stroke();
                                ctx.restore();
                              }
                            },
                          },
                        ]}
                        options={{
                          maintainAspectRatio: true,
                          responsive: true,
                          layout: {
                            padding: {
                              top: 20,
                            },
                          },
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  precision: 20,
                                },
                                gridLines: {
                                  drawOnChartArea: true,
                                },

                                scaleLabel: {
                                  display: true,
                                  labelString: kpiMUnit,
                                },
                              },
                            ],
                            xAxes: [
                              {
                                gridLines: {
                                  drawOnChartArea: true,
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: "Month",
                                },
                              },
                            ],
                          },

                          customLine: {
                            color: "black",
                          },
                          tooltips: {
                            intersect: false,
                            mode: "index",

                            callbacks: {
                              afterLabel: function (tooltipItem, data) {
                                let label = data.datasets[tooltipItem.datasetIndex].label || "";
                                if (tooltipItem.datasetIndex === 0) {
                                  const dpts = data.datasets[tooltipItem.datasetIndex].data;

                                  const indexFound = dpts.findIndex(
                                    (k) => k === tooltipItem.yLabel
                                  );
                                  if (indexFound > 0) {
                                    var stOldNew = dpts[indexFound - 1];
                                  } else {
                                    stOldNew = dpts[0];
                                  }
                                  this.setState({
                                    stoldDealOneValue: stOldNew,
                                    dealOnevalue: tooltipItem.yLabel,
                                  });
                                } else {
                                  stOldNew = 0;
                                  const dpts = data.datasets[tooltipItem.datasetIndex].data;

                                  const indexFound = dpts.findIndex(
                                    (k) => k === tooltipItem.yLabel
                                  );
                                  if (indexFound > 0) {
                                    stOldNew = dpts[indexFound - 1];
                                  } else {
                                    stOldNew = dpts[0];
                                  }

                                  this.setState({
                                    stoldDealTwoValue: stOldNew,
                                    dealTwovalue: tooltipItem.yLabel,
                                  });
                                }

                                this.setState({
                                  diffdeal1: dealOnevalue - stoldDealOneValue,
                                  diffdeal2: dealTwovalue - stoldDealTwoValue,
                                });

                                if (stoldDealOneValue !== 0) {
                                  this.setState({
                                    percentageChangeDealTwo:
                                      Math.round((diffdeal1 / stoldDealOneValue) * 100 * 100) / 100,
                                  });
                                } else {
                                  this.setState({
                                    percentageChangeDealOne: 100,
                                  });
                                }

                                if (stoldDealTwoValue !== 0) {
                                  this.setState({
                                    percentageChangeDealTwo:
                                      Math.round((diffdeal2 / stoldDealTwoValue) * 100 * 100) / 100,
                                  });
                                } else {
                                  this.setState({
                                    percentageChangeDealTwo: 100,
                                  });
                                }

                                if (label) {
                                  label += ": ";
                                }
                                label += Math.round(tooltipItem.yLabel * 100) / 100;

                                return "";
                              }.bind(this),

                              label(tooltipItem, data) {
                                let label = data.datasets[tooltipItem.datasetIndex].label || "";
                                if (tooltipItem.datasetIndex === 0) {
                                } else {
                                }

                                if (label) {
                                  label += ": ";
                                }
                                label += Math.round(tooltipItem.yLabel * 100) / 100;

                                return label;
                              },
                            },
                          },

                          hover: {
                            mode: "index",
                            intersect: false,
                          },

                          annotation: {
                            drawTime: "afterDraw",
                            annotations: [
                              {
                                id: "a-line-1",
                                drawTime: "afterDraw",
                                type: "line",
                                mode: "horizontal",
                                scaleID: "y-axis-0",
                                value: trendAvg,
                                borderColor: "#f99245 ",
                                borderWidth: 1,
                                borderDash: [2, 2],
                                borderDashOffset: 5,
                                label: {
                                  enabled: false,
                                  content: "Test label",
                                },
                              },
                            ],
                          },
                        }}
                      />
                    </TabPanel>

                    <TabPanel tabId="12months">
                      {/*
                          Line graph content - Deal comparison tab */}

                      <Line
                        data={dealComparisonGraphData}
                        id="lineChart"
                        redraw="true"
                        plugins={[
                          {
                            beforeEvent(chart, e) {
                              if (
                                e.type === "mousemove" &&
                                e.x >= e.chart.chartArea.left &&
                                e.x <= e.chart.chartArea.right
                              ) {
                                chart.options.customLine.x = e.x;
                              }
                            },

                            afterDraw(chart, easing) {
                              if (chart.tooltip._active && chart.tooltip._active.length) {
                                const activePoint = chart.tooltip._active[0];
                                const { ctx } = chart;
                                const { x } = activePoint.tooltipPosition();
                                const topY = chart.scales["y-axis-0"].top;
                                const bottomY = chart.scales["y-axis-0"].bottom;

                                // draw line
                                ctx.save();
                                ctx.beginPath();
                                ctx.moveTo(x, topY);
                                ctx.lineTo(x, bottomY);
                                ctx.lineWidth = 2;
                                ctx.strokeStyle = "#000";
                                ctx.setLineDash([5, 15]);
                                ctx.stroke();
                                ctx.restore();
                              }
                            },
                          },
                        ]}
                        options={{
                          maintainAspectRatio: true,
                          responsive: true,
                          layout: {
                            padding: {
                              top: 20,
                            },
                          },
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  precision: 20,
                                },
                                gridLines: {
                                  drawOnChartArea: true,
                                },

                                scaleLabel: {
                                  display: true,
                                  labelString: kpiMUnit,
                                },
                              },
                            ],
                            xAxes: [
                              {
                                gridLines: {
                                  drawOnChartArea: true,
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: "Month",
                                },
                              },
                            ],
                          },

                          customLine: {
                            color: "black",
                          },
                          tooltips: {
                            intersect: false,
                            mode: "index",

                            callbacks: {
                              afterLabel: function (tooltipItem, data) {
                                let label = data.datasets[tooltipItem.datasetIndex].label || "";
                                if (tooltipItem.datasetIndex === 0) {
                                  const dpts = data.datasets[tooltipItem.datasetIndex].data;

                                  const indexFound = dpts.findIndex(
                                    (k) => k === tooltipItem.yLabel
                                  );
                                  if (indexFound > 0) {
                                    var stOldNew = dpts[indexFound - 1];
                                  } else {
                                    stOldNew = dpts[0];
                                  }
                                  this.setState({
                                    stoldDealOneValue: stOldNew,
                                    dealOnevalue: tooltipItem.yLabel,
                                  });
                                } else {
                                  stOldNew = 0;
                                  const dpts = data.datasets[tooltipItem.datasetIndex].data;

                                  const indexFound = dpts.findIndex(
                                    (k) => k === tooltipItem.yLabel
                                  );
                                  if (indexFound > 0) {
                                    stOldNew = dpts[indexFound - 1];
                                  } else {
                                    stOldNew = dpts[0];
                                  }

                                  this.setState({
                                    stoldDealTwoValue: stOldNew,
                                    dealTwovalue: tooltipItem.yLabel,
                                  });
                                }

                                this.setState({
                                  diffdeal1: dealOnevalue - stoldDealOneValue,
                                  diffdeal2: dealTwovalue - stoldDealTwoValue,
                                });

                                if (stoldDealOneValue !== 0) {
                                  this.setState({
                                    percentageChangeDealTwo:
                                      Math.round((diffdeal1 / stoldDealOneValue) * 100 * 100) / 100,
                                  });
                                } else {
                                  this.setState({
                                    percentageChangeDealOne: 100,
                                  });
                                }

                                if (stoldDealTwoValue !== 0) {
                                  this.setState({
                                    percentageChangeDealTwo:
                                      Math.round((diffdeal2 / stoldDealTwoValue) * 100 * 100) / 100,
                                  });
                                } else {
                                  this.setState({
                                    percentageChangeDealTwo: 100,
                                  });
                                }

                                if (label) {
                                  label += ": ";
                                }
                                label += Math.round(tooltipItem.yLabel * 100) / 100;

                                return "";
                              }.bind(this),

                              label(tooltipItem, data) {
                                let label = data.datasets[tooltipItem.datasetIndex].label || "";
                                if (tooltipItem.datasetIndex === 0) {
                                } else {
                                }

                                if (label) {
                                  label += ": ";
                                }
                                label += Math.round(tooltipItem.yLabel * 100) / 100;

                                return label;
                              },
                            },
                          },

                          hover: {
                            mode: "index",
                            intersect: false,
                          },

                          annotation: {
                            drawTime: "afterDraw",
                            annotations: [
                              {
                                id: "a-line-1",
                                drawTime: "afterDraw",
                                type: "line",
                                mode: "horizontal",
                                scaleID: "y-axis-0",
                                value: trendAvg,
                                borderColor: "#f99245 ",
                                borderWidth: 1,
                                borderDash: [2, 2],
                                borderDashOffset: 5,
                                label: {
                                  enabled: false,
                                  content: "Test label",
                                },
                              },
                            ],
                          },
                        }}
                      />
                    </TabPanel>
                    <TabPanel tabId="max">
                      {/*
                          Line graph content - Deal comparison tab */}
                      <Line
                        data={dealComparisonGraphData}
                        id="lineChart"
                        redraw="true"
                        plugins={[
                          {
                            beforeEvent(chart, e) {
                              if (
                                e.type === "mousemove" &&
                                e.x >= e.chart.chartArea.left &&
                                e.x <= e.chart.chartArea.right
                              ) {
                                chart.options.customLine.x = e.x;
                              }
                            },

                            afterDraw(chart, easing) {
                              if (chart.tooltip._active && chart.tooltip._active.length) {
                                const activePoint = chart.tooltip._active[0];
                                const { ctx } = chart;
                                const { x } = activePoint.tooltipPosition();
                                const topY = chart.scales["y-axis-0"].top;
                                const bottomY = chart.scales["y-axis-0"].bottom;

                                // draw line
                                ctx.save();
                                ctx.beginPath();
                                ctx.moveTo(x, topY);
                                ctx.lineTo(x, bottomY);
                                ctx.lineWidth = 2;
                                ctx.strokeStyle = "#000";
                                ctx.setLineDash([5, 15]);
                                ctx.stroke();
                                ctx.restore();
                              }
                            },
                          },
                        ]}
                        options={{
                          maintainAspectRatio: true,
                          responsive: true,
                          layout: {
                            padding: {
                              top: 20,
                            },
                          },
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  precision: 20,
                                },
                                gridLines: {
                                  drawOnChartArea: true,
                                },

                                scaleLabel: {
                                  display: true,
                                  labelString: kpiMUnit,
                                },
                              },
                            ],
                            xAxes: [
                              {
                                gridLines: {
                                  drawOnChartArea: true,
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: "Month",
                                },
                              },
                            ],
                          },

                          customLine: {
                            color: "black",
                          },
                          tooltips: {
                            intersect: false,
                            mode: "index",

                            callbacks: {
                              afterLabel: function (tooltipItem, data) {
                                let label = data.datasets[tooltipItem.datasetIndex].label || "";
                                if (tooltipItem.datasetIndex === 0) {
                                  const dpts = data.datasets[tooltipItem.datasetIndex].data;

                                  const indexFound = dpts.findIndex(
                                    (k) => k === tooltipItem.yLabel
                                  );
                                  if (indexFound > 0) {
                                    var stOldNew = dpts[indexFound - 1];
                                  } else {
                                    stOldNew = dpts[0];
                                  }
                                  this.setState({
                                    stoldDealOneValue: stOldNew,
                                    dealOnevalue: tooltipItem.yLabel,
                                  });
                                } else {
                                  stOldNew = 0;
                                  const dpts = data.datasets[tooltipItem.datasetIndex].data;

                                  const indexFound = dpts.findIndex(
                                    (k) => k === tooltipItem.yLabel
                                  );
                                  if (indexFound > 0) {
                                    stOldNew = dpts[indexFound - 1];
                                  } else {
                                    stOldNew = dpts[0];
                                  }

                                  this.setState({
                                    stoldDealTwoValue: stOldNew,
                                    dealTwovalue: tooltipItem.yLabel,
                                  });
                                }

                                this.setState({
                                  diffdeal1: dealOnevalue - stoldDealOneValue,
                                  diffdeal2: dealTwovalue - stoldDealTwoValue,
                                });

                                if (stoldDealOneValue !== 0) {
                                  this.setState({
                                    percentageChangeDealTwo:
                                      Math.round((diffdeal1 / stoldDealOneValue) * 100 * 100) / 100,
                                  });
                                } else {
                                  this.setState({
                                    percentageChangeDealOne: 100,
                                  });
                                }

                                if (stoldDealTwoValue !== 0) {
                                  this.setState({
                                    percentageChangeDealTwo:
                                      Math.round((diffdeal2 / stoldDealTwoValue) * 100 * 100) / 100,
                                  });
                                } else {
                                  this.setState({
                                    percentageChangeDealTwo: 100,
                                  });
                                }

                                if (label) {
                                  label += ": ";
                                }
                                label += Math.round(tooltipItem.yLabel * 100) / 100;

                                return "";
                              }.bind(this),

                              label(tooltipItem, data) {
                                let label = data.datasets[tooltipItem.datasetIndex].label || "";
                                if (tooltipItem.datasetIndex === 0) {
                                } else {
                                }

                                if (label) {
                                  label += ": ";
                                }
                                label += Math.round(tooltipItem.yLabel * 100) / 100;

                                return label;
                              },
                            },
                          },

                          hover: {
                            mode: "index",
                            intersect: false,
                          },

                          annotation: {
                            drawTime: "afterDraw",
                            annotations: [
                              {
                                id: "a-line-1",
                                drawTime: "afterDraw",
                                type: "line",
                                mode: "horizontal",
                                scaleID: "y-axis-0",
                                value: trendAvg,
                                borderColor: "#f99245 ",
                                borderWidth: 1,
                                borderDash: [2, 2],
                                borderDashOffset: 5,
                                label: {
                                  enabled: false,
                                  content: "Test label",
                                },
                              },
                            ],
                          },
                        }}
                      />
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </TabPanel>
            <TabPanel tabId="BOITrends">
              <div className="linechart-commentry">
                <BoiTrendsWrapper
                  ClientData={ClientData}
                  kpiMappingId={kpiMappingId}
                  Month={Month}
                  FilterDetails={FilterDetails}
                  roleReducers={roleReducers}
                />
              </div>
            </TabPanel>
            <TabPanel tabId="MindMap">
              <MindMap
                kpiMappingId={this.props.kpiMappingId}
                kpiName={this.props.kpiName}
                dealFilter={this.props.dealFilter}
              />
            </TabPanel>
          </Tabs>
          <ReactTooltip place="top" className="tipcolor" id={"tooltip"} />
        </Modal.Body>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  SelectedCommentMonth: state.CurrentFilter.SelectedCommentMonth,
  CommentMonthChange: state.CurrentFilter.CommentMonthChange,
  roleData: state.roleReducers.roleReducers,
});
export default connect(mapStateToProps)(TrendGraphs);
