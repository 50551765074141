import React, { Component } from "react";
import BOMetricDetails from "../SuperAdmin/BOMetricDetails";
import { LocalApiBaseUrl, getPrevMonth } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from "i18next";
import {
    faPlus,
    faTrashAlt,
    faPencilAlt,
    faQuestionCircle
} from "@fortawesome/free-solid-svg-icons";
import BOCategoryPopUp from "../SuperAdmin/BOCategoryPopUp";
import ExportCsv from './ExportCsv';
import emptycomponent from "../Images/Empty State.svg";

axiosInstance.defaults.headers.common[
    "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class BOContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            boData: [],
            attributeLabels: [],
            viewmore: false,
            type: null,
            isRelatorBlockForOffering: false
        };
        this.onClose = this.onClose.bind(this);
    }

    dealpopup(type, e) {
        // e.currentTarget.className = "active"
        e.preventDefault();
        this.setState({ viewmore: true, type: type });
    }

    onClose() {
        this.setState({ viewmore: false });
    }

    getBoDetails(offeringId) { }

    /*
    getBOApiData(
        offeringIdparam = {
            Client: 0,
            process: 0,
            Locations: 0,
            Month: getPrevMonth()
        }
    ) {
        let filterProcess =
            this.props.filterDetails == undefined
                ? 0
                : this.props.filterDetails.offering;
        let todayDate = "";

        if (
            store.getState().BOMasterReducers["BO"].length == 0 &&
            store.getState().humanMasterReducers["humanandmachine"].length == 0 &&
            store.getState().intelligentInsightMasterReducers[
                "insightandintelligence"
            ].length == 0 &&
            store.getState().diverseMasterReducers["diverse"].length == 0 &&
            store.getState().WOtileMasterReducers["tile"].length == 0
        ) {
            axiosInstance
                .get(`${LocalApiBaseUrl}Offering/GetOfferingTemplate`, {
                    params: { OfferingId: offeringIdparam.process, LanguageID: store.getState().CurrentFilter.languageData.id }
                })
                .then(response => {
                    if (response.data != null) {
                        store.dispatch({
                            type: "OFFERING_ISRELATORBLOCK",
                            payload: response.data[0].IsRelatorBlock
                        });
                        store.dispatch({
                            type: "INITIALISE_BO_MASTER",
                            payload: response.data[0].TabData
                        });
                        store.dispatch({
                            type: "INITIALISE_MASTER",
                            payload: response.data[1].TabData
                        });
                        store.dispatch({
                            type: "INITIALISEINSIGHT_MASTER",
                            payload: response.data[2].TabData
                        });
                        store.dispatch({
                            type: "INITIALISEDIVERSE_MASTER",
                            payload: response.data[3].TabData
                        });
                        store.dispatch({
                            type: "INITIALISEWO_MASTER",
                            payload: response.data[4].TabData
                        });
                        // store.dispatch({
                        //     type: "INITIALISE_TOOL_MASTER",
                        //     payload: response.data[5].TabData
                        // });
                        this.setState({
                            boData: response.data[0].TabData,
                            isRelatorBlockForOffering: response.data[0].IsRelatorBlock
                        });
                    }
                });
        } else {
            var data = store.getState();
            this.setState({ boData: data.BOMasterReducers["BO"] });
        }
    }*/

    componentDidMount() {
        //this.getBOApiData(this.props.filterDetails);
        store.subscribe(() => {
            var data = store.getState();

            this.setState({
                boData: data.BOMasterReducers["BO"],
                isRelatorBlockForOffering: data.CurrentFilter.IsRelatorBlock
            });
        });

        if (this.props.filterDetails) {
            if (this.props.filterDetails.process !== 0)
                this.getAttributes(this.props.filterDetails.process);
        }


    }
    componentDidUpdate(prevProps,prevState) {
            if(this.props.filterDetails.process === prevProps.filterDetails.process){                
                if(this.state.boData.length === 0 && prevState.boData.length>0){
                    this.setState({
                        boData: prevState.boData
                      });
                }
                
            }
        }

    
    getAttributes(selectedOffering) {
        axiosInstance
            .get(`${LocalApiBaseUrl}Attribute/GetAttributeCategory`, {
                params: { OfferingID: selectedOffering }
            })

            .then(response => {
                let mappingData = response.data;
                //If IsActive==false, we should not show the attribute names in the UI so adding a filter on the api reponse
                let finalMappingData = mappingData.filter(
                    attr => attr.IsActive == true
                );

                this.setState({ attributeLabels: finalMappingData }, () => {
                    //add the labels into the store

                    store.dispatch({
                        type: "INITIALISE_ATTRIBUTE_LABELSMTC",
                        payload: finalMappingData

                    });

                    //All offerings will have attribute labels
                });
            })
            .catch((error) => {
                trycatchAlertPopup(error);
            });


    }

    render() {
        return (
            <div className="row">
                <ExportCsv csvProp={this.props.filterDetails.process} />
                <div className="col-lg-12">
                    <div className="title-with-icon d-flex align-items-center mb-4">
                    {this.props.roledata != undefined && store.getState().CurrentFilter.languageData.id == 1 &&
                        this.props.roledata.includes("Add") ? (
                            <div className="list-icon mtc-list-icon">
                                <span
                                    onClick={e => {
                                        this.dealpopup("Add SO Category", e);
                                    }}
                                >
                                    <i className="far fa-plus plus-icon-fix"></i>
                                </span>
                            </div>
                            ) : null}
                        </div>
                    {(this.state.boData.length !== 0 &&
                        this.state.boData.length > 0) ? (
                        this.state.boData.map(SOData => (
                            <BOMetricDetails
                                SODetails={SOData}
                                filterDetails={this.props.filterDetails}
                                roledata={this.props.roledata}
                                isRelatorBlockForOffering={this.state.isRelatorBlockForOffering}
                                attributeLabels={this.state.attributeLabels}
                            />
                        ))) : (!this.props.active && <div className="emptymetric-msg">
                            <img alt="" src={emptycomponent} width="80px" height="80px" />
                            <h6>{i18n.t("Please click on '+' to add category")}</h6>
                        </div>)}
                    {this.state.viewmore ? (
                        <BOCategoryPopUp
                            show={this.state.viewmore}
                            onClose={this.onClose}
                            filterDetails={this.props.filterDetails}
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}
