import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import { AgGridReact } from 'ag-grid-react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import {
  EditorState, convertToRaw, convertFromHTML, ContentState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import Moment from 'moment';
import $ from 'jquery';
import 'timepicker/jquery.timepicker.min.js';
import 'timepicker/jquery.timepicker.css';
import axiosInstance from '../Shared/interceptor';
import { store } from '../reducers/configureStore';
import {
  LocalApiBaseUrl, trycatchAlertPopup, regexForTextAreas,
  regexForToolURL,
  commonToolRegex,
} from '../Shared/Constant';
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import { validateIsAllowedDomainFromURL, fetchAllowedDomains } from '../Shared/commonFunctions';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('msal.idtoken')}`;

function validateURLForWhitelisting(urlStr, allowedDomainsArray) {
  const isAllowed = validateIsAllowedDomainFromURL(urlStr, allowedDomainsArray);
  return isAllowed;
}

class NotificationList extends Component {
  constructor(props) {
    super(props);
    this.addnotificationModalShow = this.addnotificationModalShow.bind(this);
    this.addnotificationModalClose = this.addnotificationModalClose.bind(this);
    this.edit = i18n.t('Edit');
    this.Delete = i18n.t('Delete');
    this.Header = i18n.t('Name');
    this.Description = i18n.t('Description');
    this.DisplayUntilDate = `${i18n.t('DisplayUntil')} - ${i18n.t('Date')}`;
    this.DisplayUntilTime = `${i18n.t('DisplayUntil')} - ${i18n.t('Time')}`;

    this.isActive = i18n.t('Is_Active');

    this.state = {
      isTimeDropdownStatus: false,
      editorState: EditorState.createEmpty(),
      isAct: false,
      name: '',
      date: null,
      expireTime: null,
      EditNotication: false,
      disableInputBox: false,
      errors: [],
      link: '',
      linkType: '',
      linkErrors: { link: '', linkType: '' },
      columnDefs: [
        {
          headerName: this.edit,
          suppressMovable: true,
          width: 60,
          cellRenderer: (params) => {
            const link = document.createElement('span');
            link.className = 'tbledit';
            link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
            link.addEventListener('click', (e) => {
              this.editNotificationModalShow(e, params.data);
            });
            return link;
            // }
          },
          suppressFilter: true,
        },
        {
          headerName: this.Delete,
          suppressMovable: true,
          width: 80,
          cellRenderer: (params) => {
            const link = document.createElement('span');
            link.className = 'tbldel';
            link.innerHTML = '<i class="fas fa-trash-alt delete-icon-fix"></i>';
            link.addEventListener('click', (e) => {
              this.deleteNotification(e, params.data);
            });
            return link;
          },
          suppressFilter: true,
        },
        {
          headerName: this.Header,
          field: 'Header',
          suppressMovable: true,
          resizable: false,
        },
        {
          headerName: i18n.t('Link'),
          field: 'Link',
          suppressMovable: true,
          resizable: false,
          cellRenderer: (params) => params.data.Link,
        },
        {
          headerName: i18n.t('Link Type'),
          field: 'LinkType',
          suppressMovable: true,
          resizable: false,
          cellRenderer: (params) => {
            if (params.data.LinkType === 'I') {
              return i18n.t('Internal');
            }
            if (params.data.LinkType === 'E') {
              return i18n.t('External');
            }

            return '';
          },
        },
        {
          headerName: this.Description,
          field: 'Description',
          suppressMovable: true,
          resizable: false,
          cellRenderer: (params) => {
            const link = document.createElement('span');
            link.innerHTML = params.data.Description;
            return link;
          },
        },
        {
          headerName: this.DisplayUntilDate,
          field: 'ExpiryDate',
          suppressMovable: true,
          resizable: false,
          suppressFilter: true,
          cellRenderer: (params) => {
            if (params.value !== null) {
              return Moment.utc(params.value)
                .local()
                .format('DD/MM/YYYY');
            }
            return '';
          },
        },
        {
          headerName: this.DisplayUntilTime,
          field: 'ExpiryDate',
          suppressMovable: true,
          resizable: false,
          suppressFilter: true,
          cellRenderer: (params) => {
            if (params.value !== null) {
              return Moment.utc(params.value)
                .local()
                .format('hh:mm A');
            }
            return '';
          },
        },
        {
          headerName: this.isActive,
          field: 'IsActive',
          resizable: false,
          suppressMovable: true,
          suppressFilter: true,

          cellRenderer: (params) => `<label class="form-check">
                    <input class="form-check-input" disabled type="checkbox" ${params.value ? 'checked' : ''}>
                    <span class="checkmark"></span>
                     </label>`,
        },
      ],
      rowData: [],
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        rowSelection: 'multiple',
        defaultColDef: {
          sortable: true,
        },
      },
      isAPILoading: false,
      allowedDomainsArray: [],
    };
    this.onClose = this.onClose.bind(this);
    this.dealpopup = this.dealpopup.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
  }

  async componentDidMount() {
    this.setState({
      isAPILoading: true,
    });
    const allowedDomains = await fetchAllowedDomains();
    this.setState({
      allowedDomainsArray: allowedDomains,
      isAPILoading: false,
    });
  }

  addnotificationModalShow() {
    this.setState({
      editorState: EditorState.createEmpty(),
      isAct: false,
      name: '',
      date: null,
      expireTime: null,
    });
    this.setState({
      addnotificationStatus: true,
      EditNotication: false,
      disableInputBox: false,
    });
  }

  ValidateForm() {
    const { allowedDomainsArray } = this.state;

    const errors = {};
    let formIsValid = true;
    let breakcondition = false;
    let textAvailable = false;
    const { link, linkType, linkErrors } = this.state;
    if (link.trim()) {
      if (!commonToolRegex.test(link.trim().toLowerCase())) {
        if (regexForToolURL.test(link.trim().toLowerCase())) {
          if (!validateURLForWhitelisting(link.trim(), allowedDomainsArray)) {
            linkErrors.link = i18n.t('subdomain_or_domain_not_whitelisted');
            formIsValid = false;
          } else {
            linkErrors.link = '';
          }
        } else {
          linkErrors.link = i18n.t('Please_valid_url');
          formIsValid = false;
        }
      } else {
        linkErrors.link = i18n.t('commonToolRegex_validatemessage');
        formIsValid = false;
      }
      if (!linkType) {
        linkErrors.linkType = i18n.t('link_type_is_required');
        formIsValid = false;
      }
    } else {
      linkErrors.link = '';
      linkErrors.linkType = '';
    }

    if (String(this.state.name) === '') {
      formIsValid = false;
      errors.name = `${i18n.t('Name')} ${i18n.t('is_Required')}`;
    }

    const contentRaw = convertToRaw(this.state.editorState.getCurrentContent());
    contentRaw.blocks.map((each) => {
      if (String(each.text) !== '') {
        textAvailable = true;
      }
    });
    if (!textAvailable) {
      formIsValid = false;
      errors.editorState = `${i18n.t('Description')} ${i18n.t('is_Required')}`;
    }

    if (formIsValid) {
      this.setState({
        linkErrors,
        errors,
      });
    } else {
      this.setState({
        linkErrors,
        errors,
      });
    }
    return formIsValid;
  }

  handleSaveNotification() {
    if (this.ValidateForm()) {
      this.addnotificationModalClose();
      this.SaveNotification();
      this.onClose();
    }
  }

  SaveNotification() {
    if (this.state.date != null) {
      const checkSelectedDate = this.state.date.split('/');
      const expireTime = this.state.expireTime === 0 ? '12:00 AM' : this.state.expireTime;
      const Checkfulldate = `${checkSelectedDate[2]}-${checkSelectedDate[1]}-${checkSelectedDate[0]} ${expireTime}`;

      if (new Date(Checkfulldate) < new Date()) {
        if (this.state.date != null) {
          alert(`${i18n.t('Please_enter')} ${i18n.t('DisplayUntil')} ${i18n.t('Date')}/${i18n.t('Time')}`);
        }
      } else if (this.state.EditNotication) {
        this.UpdateNotification();
      } else {
        this.AddNotification();
      }
    } else if (this.state.EditNotication) {
      this.UpdateNotification();
    } else {
      this.AddNotification();
    }
  }

  editNotificationModalShow(e, data) {
    const blocksFromHTML = convertFromHTML(data.Description);
    const content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

    this.setState({
      name: data.Header,
      ID: data.ID,
      editorState: EditorState.createWithContent(content),
      isAct: data.IsActive,
      link: data.Link ? data.Link : '',
      linkType: data.LinkType ? data.LinkType : '',
      date:
        data.ExpiryDate == null
          ? null
          : Moment.utc(data.ExpiryDate)
            .local()
            .format('DD/MM/YYYY'), // (data.ExpiryDate,"DD/MM/YYYY") //MM/dd/yyyy
      expireTime:
        data.ExpiryDate == null
          ? null
          : Moment.utc(data.ExpiryDate)
            .local()
            .format('hh:mm A'),
      disableInputBox: data.IsActive,
    });

    this.setState({ addnotificationStatus: true, EditNotication: true });
  }

  UpdateNotification = () => {
    const {
      expireTime, date, name, editorState, isAct, link, linkType, ID,
    } = this.state;
    let expireTimeDate = '';
    if (date == null && expireTime != null) {
      alert(`${i18n.t('Please_enter')} ${i18n.t('DisplayUntil')} ${i18n.t('Date')}`);
      return;
    }
    if (date != null && expireTime == null) {
      alert(`${i18n.t('Please_enter')} ${i18n.t('DisplayUntil')} ${i18n.t('Time')}`);
      return;
    }
    if (expireTime != null) {
      expireTimeDate = String(expireTime) === String(0) ? '12:00 AM' : expireTime;
      const isValid = expireTimeDate.match(/^(0?[1-9]|1[012])(:[0-5]\d) [APap][mM]$/);
      if (!isValid) {
        return alert(`${i18n.t('Please_enter_valid')} ${i18n.t('DisplayUntil')} ${i18n.t('Time')}`);
      }
    }
    let datedd = null;
    if (date != null) {
      const dd = date.split('/');
      const fulldate = `${dd[2]}/${dd[1]}/${dd[0]}`;
      const expirydate = `${fulldate} ${expireTimeDate}`;
      datedd = new Date(expirydate);
      const today = new Date();
      if (datedd < today) {
        return alert(
          `${i18n.t('Please_enter')} ${i18n.t('DisplayUntil')} ${i18n.t('Date')}/${i18n.t('Time')}`,
        );
      }
      datedd = datedd.toUTCString();
    }

    this.setState({
      addnotificationStatus: false,
    });
    axiosInstance
      .post(
        `${LocalApiBaseUrl}Notification/EditNotifications`,
        {
          ID,
          Header: name,
          Description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          IsActive: isAct,
          ExpiryDate: datedd,
          Link: link,
          LinkType: linkType,
        },
        {
          params: {
            LanguageID: store.getState().CurrentFilter.languageData.id,
          },
        },
      )
      .then(
        (response) => {
          window.alert(response.data.StatusMessage);
          this.setState({
            editorState: EditorState.createEmpty(),
            isAct: false,
            name: '',
            date: null,
            expireTime: null,
            addnotificationStatus: false,
            errors: [],
            link: '',
            linkType: '',
          });
          this.getNotifications();
        },
        (error) => {
          console.log(error);
        },
      )
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  addnotificationModalClose() {
    this.setState({ addnotificationStatus: false, errors: [], linkErrors: { link: '', linkType: '' }, linkType: '', link: '' });
  }

  isTimeDropdown = () => {
    this.setState({ isTimeDropdownStatus: true });
  };

  isTimeDropdownOverlay = () => {
    this.setState({ isTimeDropdownStatus: false });
  };

  onClictFetchClient = (selectedData) => (evt) => {
    this.setState({ selectedTime: selectedData });
    setTimeout(() => {
      this.isTimeDropdownOverlay();
    }, 100);
  };

  handleChange = (name, value) => {
    const errors = {};
    let selectedTime = '';
    const dateToday = new Date();

    setTimeout(() => {
      if ($('#timePicker').val() === '0') {
        selectedTime = '12:00 AM';
        $('#timePicker').val(selectedTime);
      }
    });

    if (String(name) === 'date') {
      if (String(value) === String(Moment(new Date()).format('DD/MM/YYYY'))) {
        const expireTime = dateToday.getMinutes() >= 0 && dateToday.getMinutes() <= 14
          ? this.handleTimeString(dateToday.getHours(), '15')
          : dateToday.getMinutes() >= 15 && dateToday.getMinutes() <= 29
            ? this.handleTimeString(dateToday.getHours(), '30')
            : dateToday.getMinutes() >= 30 && dateToday.getMinutes() <= 44
              ? this.handleTimeString(dateToday.getHours(), '45')
              : this.handleTimeString(dateToday.getHours() + 1, '00');
        this.setState({
          expireTime,
          [name]: value,
        });
        $('#timePicker').val(expireTime);
      } else if (String(value) === '') {
        this.setState({
          expireTime: null,
          [name]: null,
        });
        $('#timePicker').val('');
        $('#date-daily').val('');
      } else {
        this.setState({
          expireTime: 0,
          [name]: value,
        });
      }
    } else {
      this.setState(
        {
          [name]: value,
          errors,
        },
        () => {

        },
      );
    }
  };

  handleTimeString(hours, minutes) {
    const timeString = `${hours.toString()}:${minutes}:00`;
    const timeString12hr = new Date(`1970-01-01T${timeString}Z`).toLocaleTimeString(
      {},
      {
        timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric',
      },
    );

    return timeString12hr;
  }

  handleDateChange = (date) => {
    const errors = {};
    this.setState({
      date,
      errors,
    });
  };

  onEditorStateChange = (editorState) => {
    const errors = {};
    this.setState({
      editorState,
      errors,
    });
  };

  AddNotification = () => {
    const {
      expireTime, date, name, editorState, isAct, link, linkType,
    } = this.state;

    const expireTimeDate = String(expireTime) === String(0) ? '12:00 AM' : expireTime;
    if (date == null && expireTime != null) {
      alert(`${i18n.t('Please_enter')} ${i18n.t('DisplayUntil')} ${i18n.t('Date')}`);
      return;
    }
    if (date != null && expireTime == null) {
      alert(`${i18n.t('Please_enter')} ${i18n.t('DisplayUntil')} ${i18n.t('Time')}`);
      return;
    }
    if (expireTime != null) {
      const isValid = expireTimeDate.match(/^(0?[1-9]|1[012])(:[0-5]\d) [APap][mM]$/);
      if (!isValid) {
        return alert(`${i18n.t('Please_enter_valid')} ${i18n.t('DisplayUntil')} ${i18n.t('Time')}`);
      }
    }
    let datedd = null;
    if (date != null) {
      const dd = date.split('/');
      const fulldate = `${dd[2]}/${dd[1]}/${dd[0]}`;
      const expirydate = `${fulldate} ${expireTimeDate}`;
      datedd = new Date(expirydate);
      const today = new Date();
      if (datedd != null) {
        if (datedd < today) {
          return alert(
            `${i18n.t('Please_enter')} ${i18n.t('DisplayUntil')} ${i18n.t('Date')}/${i18n.t('Time')}`,
          );
        }
        datedd = datedd.toUTCString();
      }
    }
    this.setState({
      addnotificationStatus: false,
    });
    axiosInstance
      .post(
        `${LocalApiBaseUrl}Notification/AddNotifications`,
        {
          Header: name,
          Description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          IsActive: isAct,
          ExpiryDate: date == null ? null : datedd,
          Link: link,
          LinkType: linkType,
        },
        {
          params: {
            LanguageID: store.getState().CurrentFilter.languageData.id,
          },
        },
      )
      .then(
        (response) => {
          window.alert(response.data.StatusMessage);

          this.setState({
            editorState: EditorState.createEmpty(),
            isAct: false,
            name: '',
            date: null,
            expireTime: null,
            addnotificationStatus: false,
            errors: [],
            link: '',
            linkType: '',
          });

          this.getNotifications();
        },
        (error) => {
          console.log(error);
        },
      )
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(e) {
    this.state.gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
  }

  dealpopup(e, data) {
    e.preventDefault();
    this.setState({ viewmore: true, rowSelected: data });
  }

  onClose(e) {
    this.setState({ viewmore: false });
  }

  deleteNotification(e, data) {
    if (
      this.props.featureData.filter((data) => String(data.Submenu) === 'Clients' && data.Operations.includes('Delete'))
        .length > 0
    ) {
      if (window.confirm(i18n.t('delete_item_confirm_notification'))) {
        axiosInstance
          .delete(`${LocalApiBaseUrl}Notification/DeleteNotification`, {
            params: {
              NotificationID: data.ID,
            },
          })
          .then((response) => {
            window.alert(response.data.StatusMessage);
            this.getNotifications();
          })
          .catch((error) => {
            trycatchAlertPopup(error);
          });
      }
    } else window.alert('Access Denied !!');
  }

  getNotifications() {
    axiosInstance
      .get(`${LocalApiBaseUrl}Notification/GetAllNotifications`, {
        params: {
          LanguageID: store.getState().CurrentFilter.languageData.id,
        },
      })
      .then((response) => {
        this.setState({
          rowData: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  componentWillMount() {
    this.getNotifications();
    let { columnDefs } = this.state;

    if (
      this.props.featureData.filter((data) => String(data.Submenu) === 'Clients' && data.Operations.includes('Edit'))
        .length === 0
    ) {
      columnDefs = columnDefs.filter((data) => String(data.headerName) !== 'Edit');
      this.setState({
        columnDefs,
      });
    }

    if (
      this.props.featureData.filter((data) => String(data.Submenu) === 'Clients' && data.Operations.includes('Delete'))
        .length === 0
    ) {
      columnDefs = columnDefs.filter((data) => String(data.headerName) !== 'Delete');
      this.setState({
        columnDefs,
      });
    }
  }

  componentDidUpdate() {
    const _this = this;
    $(() => {
      $('#dp3').datepicker({
        startDate: new Date(),
      });

      $('#dp3')
        .datepicker()
        .on('changeDate', (ev) => {
          _this.handleChange('date', $('#date-daily').val());
        });
    });
    this.edit = i18n.t('Edit');
    this.Delete = i18n.t('Delete');
    this.Header = i18n.t('Name');
    this.Description = i18n.t('Description');
    this.DisplayUntilDate = `${i18n.t('DisplayUntil')} - ${i18n.t('Date')}`;
    this.DisplayUntilTime = `${i18n.t('DisplayUntil')} - ${i18n.t('Time')}`;
    this.isActive = i18n.t('Is_Active');

    if (!this.state.columnDefs[0].headerName && this.edit) {
      this.setState({
        columnDefs: [
          {
            headerName: this.edit,
            suppressMovable: true,
            width: 60,
            cellRenderer: (params) => {
              const link = document.createElement('span');
              link.className = 'tbledit';
              link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
              link.addEventListener('click', (e) => {
                this.editNotificationModalShow(e, params.data);
              });
              return link;
            },
            suppressFilter: true,
          },
          {
            headerName: this.Delete,
            suppressMovable: true,
            width: 80,
            cellRenderer: (params) => {
              const link = document.createElement('span');
              link.className = 'tbldel';
              link.innerHTML = '<i class="fas fa-trash-alt delete-icon-fix"></i>';
              link.addEventListener('click', (e) => {
                this.deleteNotification(e, params.data);
              });
              return link;
            },
            suppressFilter: true,
          },
          {
            headerName: this.Header,
            field: 'Header',
            suppressMovable: true,
            resizable: false,
          },
          {
            headerName: this.Description,
            field: 'Description',
            suppressMovable: true,
            resizable: false,
            cellRenderer: (params) => {
              const link = document.createElement('span');
              link.innerHTML = params.data.Description;
              return link;
            },
          },
          {
            headerName: i18n.t('Link'),
            field: 'Link',
            suppressMovable: true,
            resizable: false,
            cellRenderer: (params) => params.data.Link,
          },
          {
            headerName: i18n.t('Link_Type'),
            field: 'LinkType',
            suppressMovable: true,
            resizable: false,
            cellRenderer: (params) => {
              if (params.data.LinkType === 'I') {
                return i18n.t('Internal');
              }
              if (params.data.LinkType === 'E') {
                return i18n.t('External');
              }

              return '';
            },
          },
          {
            headerName: this.DisplayUntilDate,
            field: 'ExpiryDate',
            suppressMovable: true,
            resizable: false,
            suppressFilter: true,
            cellRenderer: (params) => {
              if (params.value !== null) {
                return Moment.utc(params.value)
                  .local()
                  .format('DD/MM/YYYY');
              }
              return '';
            },
          },
          {
            headerName: this.DisplayUntilTime,
            field: 'ExpiryDate',
            suppressMovable: true,
            resizable: false,
            suppressFilter: true,
            cellRenderer: (params) => {
              if (params.value !== null) {
                return Moment.utc(params.value)
                  .local()
                  .format('hh:mm A');
              }
              return '';
            },
          },
          {
            headerName: this.isActive,
            field: 'IsActive',
            resizable: false,
            suppressMovable: true,
            suppressFilter: true,

            cellRenderer: (params) => `<label class="form-check">
                      <input class="form-check-input" disabled type="checkbox" ${params.value ? 'checked' : ''}>
                      <span class="checkmark"></span>
                       </label>`,
          },
        ],
      });
    }
  }

  onFocusEventTime() {
    let selectedTime;
    $('.ui-timepicker-wrapper.custom-timepicker').remove();
    $('#timePicker').timepicker({
      step: 15,
      className: 'custom-timepicker',
      listWidth: 0.84,
      appendTo: $('.time-picker'),
      timeFormat: 'h:i A',
      dynamic: false,
      dropdown: true,
      scrollbar: true,
    });
    setTimeout(() => {
      if ($('#timePicker').val()) {
        $('.ui-timepicker-list li').each(function () {
          if ($(this).text() === $('#timePicker').val()) {
            $(this).addClass('ui-timepicker-selected');
            $('.ui-timepicker-selected')[0].scrollIntoView({ behavior: 'smooth' });
          } else {
            $(this).removeClass('ui-timepicker-selected');
          }
        });
      }
    }, 100);
    $('#timePicker').on('changeTime', (e) => {
      $('#timePicker').val(e.target.value);
      this.handleChange('expireTime', e.target.value);
      const selectedVal = e.target.value;
      $('.ui-timepicker-list li').each(function () {
        if ($(this).text() === selectedVal) {
          $(this).addClass('ui-timepicker-selected');
          $('.ui-timepicker-selected')[0].scrollIntoView({ behavior: 'smooth' });
        } else {
          $(this).removeClass('ui-timepicker-selected');
        }
      });
    });
    setTimeout(() => {
      if ($('#timePicker').val() === '0') {
        selectedTime = '12:00 AM';
        $('#timePicker').val(selectedTime);
      }
    });
  }

  onLinkTypeChange = (event) => {
    const SelectedValue = event.currentTarget.value;
    this.setState({ linkType: SelectedValue, linkErrors: { link: '', linkType: '' } });
  }

  onLinkChange = (event) => {
    const SelectedValue = event.target.value;
    const { linkErrors, allowedDomainsArray } = this.state;
    linkErrors.link = '';
    if (SelectedValue) {
      if (!commonToolRegex.test(SelectedValue.trim().toLowerCase())) {
        if (regexForToolURL.test(SelectedValue.trim().toLowerCase())) {
          if (!validateURLForWhitelisting(SelectedValue.trim(), allowedDomainsArray)) {
            linkErrors.link = i18n.t('subdomain_or_domain_not_whitelisted');
          } else {
            linkErrors.link = '';
          }
        } else {
          linkErrors.link = i18n.t('Please_valid_url');
        }
      } else {
        linkErrors.link = i18n.t('commonToolRegex_validatemessage');
      }
    }
    this.setState({ linkErrors, link: SelectedValue });
  }

  render() {
    const { featureData } = this.props;
    const {
      gridOptions,
      columnDefs,
      rowData,
      addnotificationStatus,
      EditNotication,
      disableInputBox,
      errors,
      editorState,
      expireTime,
      isTimeDropdownStatus,
      isAct,
      date,
      name,
      link,
      linkType,
      linkErrors,
      isAPILoading,
    } = this.state;

    return (
      <>
        <LoadingOverlay
          className="custom-loader"
          fadeSpeed={0}
          spinner={<SynopsLoader />}
          active={isAPILoading}
        />

        <div className="main-data">
          <div className="tableFilter">
            <div>
              <div className="form-row">
                <div className="form-group col-lg-3">
                  <input
                    type="text"
                    className="form-control"
                    id="filter-text-box"
                    placeholder={`${i18n.t('Filter')}...`}
                    onChange={(e) => {
                      this.onFilterTextBoxChanged(e);
                    }}
                  />
                </div>
                {featureData.filter((data) => String(data.Submenu) === 'Clients' && data.Operations.includes('Add'))
                  .length > 0 && (
                    <div className="form-group col-lg-3">
                      <button type="button" className="btn btn-primary btn-sm" onClick={this.addnotificationModalShow}>
                        <span>
                          <FontAwesomeIcon icon={faPlusCircle} />
                        </span>
                        {' '}
                        {i18n.t('Add_Notification')}
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="ag-theme-balham ag-table">
                <AgGridReact
                  enableSorting
                  enableFilter
                  pagination
                  paginationPageSize={50}
                  floatingFilter
                  gridOptions={gridOptions}
                  columnDefs={columnDefs}
                  rowData={rowData}
                  onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={addnotificationStatus}
          onHide={this.addnotificationModalClose}
          className="modal fade addUserModal"
          size="medium"
          backdrop="static"
        >
          <Modal.Header className="pt-3" closeButton>
            <h4 className="modal-title" id="dealprofile">
              {EditNotication ? 'Edit Notification' : 'Add Notification'}
            </h4>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <div>
              <div className="row justify-content-end">
                <div className="form-group col-sm-2 p-0 m-0">
                  <div className="toggle-group toggle-tick">
                    <input
                      type="checkbox"
                      name="isAct"
                      id="isActive"
                      defaultChecked={isAct}
                      onChange={(e) => this.handleChange(e.target.name, e.target.checked)}
                    />
                    <label htmlFor="isActive">Is Active</label>
                    <div className="onoffswitch" aria-hidden="true">
                      <div className="onoffswitch-label">
                        <div className="onoffswitch-inner" />
                        <div className="onoffswitch-switch" />
                      </div>
                    </div>
                    <span className="switch-status">{i18n.t('Active')}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-12">
                  <label htmlFor="Name">
                    {i18n.t('Name')}
                    {' '}
                    <span className="required">*</span>
                  </label>
                  <input
                    maxLength="100"
                    type="text"
                    className="form-control"
                    id="Name"
                    name="name"
                    value={name}
                    onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                    disabled={disableInputBox}
                  />
                  <span className="errorMsg">{errors.name}</span>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-12">
                  <label htmlFor="description">
                    {i18n.t('Description')}
                    {' '}
                    <span className="required">*</span>
                  </label>
                  <div className={disableInputBox ? 'description-editor  disabled-editor' : 'description-editor'}>
                    <Editor
                      readOnly={disableInputBox}
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={this.onEditorStateChange}
                      onTab={() => true}
                      toolbar={{
                        options: ['list'],
                        list: { inDropdown: false, options: ['unordered'] },
                      }}
                    />
                    <span className="errorMsg">{errors.editorState}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-sm-6  date-fields-wrapper">
                  <label htmlFor="Datee">{i18n.t('Display_until_date')}</label>
                  <div className="date-wrapper">
                    <div className="input-append date" id="dp3" data-date="25/11/2020" data-date-format="dd/mm/yyyy">
                      <input
                        type="text"
                        id="date-daily"
                        name="date"
                        value={date}
                        onChange={(e) => this.handleChange('date', e.target.value)}
                      />
                      <label className="input-group-addon btn">
                        <span className="fal fa-calendar-alt" />
                      </label>
                    </div>
                  </div>
                  {' '}
                </div>
                <div className="form-group col-sm-6 ">
                  <label htmlFor="Time">
                    {i18n.t('Display_until_time')}
                  </label>
                  <div className="time-picker">
                    <input
                      type="text"
                      id="timePicker"
                      name="expireTime"
                      value={String(expireTime) === String(0) ? '12:00 AM' : expireTime}
                      onChange={(e) => {
                        this.handleChange(e.target.name, e.target.value);
                      }}
                      onFocus={this.onFocusEventTime.bind(this)}
                    />
                  </div>
                </div>
                <div className="form-group col-lg-6">
                  <label htmlFor="Link">
                    {' '}
                    {i18n.t('Link')}
                  </label>
                  <input
                    maxLength="50"
                    type="text"
                    className="form-control"
                    id="Link"
                    onChange={this.onLinkChange.bind(this)}
                    value={link}
                  />
                  <span className="errorMsg">{linkErrors.link}</span>
                </div>
                <div className="form-group col-lg-6">
                  <label htmlFor="LinkType">
                    {i18n.t('Link_Type')}
                    {link.length ? <span className="required">*</span> : ''}
                  </label>

                  <select
                    id="LinkType"
                    className={`form-control ${!link.length ? 'disabled' : ''}`}
                    // disabled={this.props.data || this.props.opsgrp}
                    onChange={!link.length ? '' : this.onLinkTypeChange.bind(this)}
                    value={linkType}
                  >
                    <option value="">
                      {i18n.t('Select_Link_Type')}
                      {' '}
                    </option>
                    <option value="I">
                      {i18n.t('Internal')}
                      {' '}
                    </option>
                    <option value="E">
                      {i18n.t('External')}
                      {' '}
                    </option>
                  </select>
                  <span className="errorMsg">{linkErrors.linkType}</span>
                </div>

                {isTimeDropdownStatus ? (
                  <div className="time-list-overlay" onClick={this.isTimeDropdownOverlay} />
                ) : (
                  ''
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-btn flex-btns">
              <button onClick={this.addnotificationModalClose} type="button" className="btn btn-default btn-sm">
                {i18n.t('Cancel')}
                {' '}
              </button>
              <button onClick={() => this.handleSaveNotification()} type="button" className="btn btn-primary btn-sm">
                {' '}
                {EditNotication ? i18n.t('Update') : i18n.t('Add')}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  languageData: state.CurrentFilter.languageData,
});
export default connect(mapStateToProps)(NotificationList);
