import React, { Component } from 'react';
import { LocalApiBaseUrl, getMonthNumber, getPrevMonth } from '../Shared/Constant';
//import DataPopUp from '../SuperAdmin/data_popup';
import { store } from "../reducers/configureStore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faPlus, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
//axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class DiverseInsights extends Component {
    constructor() {
        super()
        this.state = { metrics: [], viewMore2: false, viewMore3: false, edit: false, metrics1: [] }
        this.onClose = this.onClose.bind(this);
        this.displayProcess = this.displayProcess.bind(this);
        this.displayDataProcessed = this.displayDataProcessed.bind(this);
    }

    dealpopup(group, e) {

        // e.currentTarget.className = "active"
        e.preventDefault();
        if (group == "Data Processed") {
            this.setState({ viewMore2: true });
        }
        else {
            this.setState({ viewMore3: true });
        }


        //
    }

    deleteData = (group, id, name, e) => {
        if (window.confirm("Are you sure you want to delete item?")) {
            e.preventDefault();

            store.dispatch({
                type: 'REMOVE_DATA',
                payload: { id: id, group: group, name: name }
            })
        }
        e.preventDefault();
    }

    editData(group, id, e) {

        e.preventDefault();
        this.setState({ edit: true, id: id, group: group });

    }


    onClose() {
        this.setState({ viewMore2: false, viewMore3: false, edit: false });

    }
    getSoCategoryDataAttribute = (offeringIdparam = { Client: 11, process: 2, Locations: 5, Month: getPrevMonth() }) => {

        let offeringId = this.props.filterDetails != undefined ? Number(this.props.filterDetails) : 2;
        let filterProcess = offeringIdparam.process == undefined ? 3 : offeringIdparam.process;
        let filterLocations = offeringIdparam.Locations == undefined ? 5 : offeringIdparam.Locations;
        let filterClient = offeringIdparam.Client == undefined ? 11 : offeringIdparam.Client;
        let dateArray = offeringIdparam.Month == 0 ? [] : offeringIdparam.Month.split("-")
        let todayDate = ""
        if (dateArray.length == 2) {
            //todayDate = new Date(dateArray[1], getMonthNumber(dateArray[0]) + 1, 1).toISOString().slice(0, 10);
            todayDate = "1-" + dateArray[0] + "-" + dateArray[1]
        }
        else {
            todayDate = offeringIdparam.Month;
        }
        // axiosInstance.get(`${LocalApiBaseUrl}SOFilter/GetDiverseData`, {
        //     params: {
        //         OrgEntityID: filterClient,
        //         OfferingID: filterProcess,
        //         LocationID: filterLocations,
        //         MonthDT: todayDate
        //     }
        // })
        // .then((response) => {
        //     

        //     store.dispatch({
        //             type:'INITIALISEDIVERSE',
        //             payload:response.data
        //         })

        //     this.setState({apiData:response.data})

        // })


        //  return metrics;
    }
    displayProcess() {

        let rowTheList = [];
        let dataProcess = this.state.dataProcessedData;
        if (dataProcess != undefined) {
            let result = dataProcess[0].SoCatDataAttr;
            return (<div className="outcome-highlight">

                <h2>Data Processed</h2>
                <a href="" onClick={(e) => { this.dealpopup("Data Processed", e) }}><span><FontAwesomeIcon icon={faPlus} /></span></a>

                {dataProcess[0] != undefined ? <div className="outcome-table">
                    {result.filter((eachData) => eachData.AttributeGroup == "Data Processed").map((data, index, orgArray) => {
                        rowTheList = index % 3 == 0 ? [] : rowTheList;;
                        rowTheList.push(data);
                        if (rowTheList.length >= 3 || orgArray.length - 1 == index) {
                            return <div className="outcome-row flexbox-3"> {rowTheList.map((row) => {
                                // let datatypr = (group == "Data Types Processed") ?
                                //     row.AttributeValue.toString().toLowerCase() == "yes" ? "active-data" : "deactive-data" : "";

                                return <div className={`outcome-divider gray-clr `}>
                                    {/* <> <p>{row.AttributeName}</p>
                                    <span><a href="" onClick={(e) => { this.deleteData("Data Processed", row.AttributeID, e) }}><span>-</span></a> <a href="" onClick={(e) => { this.editData("Data Processed", row.AttributeID, e) }}><span>edit</span></a></span> 

                                </> */}

                                    <>

                                        {this.props.roledata != undefined && this.props.roledata.includes("Edit") ? <a href="" onClick={(e) => { this.editData("Data Processed", row.AttributeID, e) }}><span><p>{row.AttributeName}</p></span></a> : null}


                                        {this.props.roledata != undefined && store.getState().CurrentFilter.languageData.id === 1 && this.props.roledata.includes("Delete") ? <span><a href="" onClick={(e) => { this.deleteData("Data Processed", row.AttributeID, row.AttributeName, e) }}><span className="deletehumanmachine"><FontAwesomeIcon icon={faTrashAlt} /></span></a> </span> : null}



                                    </>

                                    <span>{row.AttributeValue}</span>
                                </div>

                            })}</div>
                        }
                    })}

                </div>
                    : null}
            </div>
            )

        }



    }

    displayDataProcessed() {

        let dataProcess = this.state.dataProcessedData
        if (dataProcess != undefined) {
            let result = dataProcess[0].SoCatDataAttr;


            let groupValues = [... new Set(dataProcess[0].SoCatDataAttr.map((eachGroup) => { return eachGroup.AttributeGroup }))]

            // let metricsValue = groupValues.map((group, index) => {
            let datapr = "Data Types Processed" ? "data-presence" : "";
            let rowTheList = [];
            return <div className={`outcome-highlight ${datapr}`}>
                <h2>Data Types Processed</h2>
                <a href="" onClick={(e) => { this.dealpopup("Data Types Processed", e) }}><span><FontAwesomeIcon icon={faPencilAlt} /></span></a>
                <div className="outcome-table">

                    {result.filter((eachData) => eachData.AttributeGroup == "Data Types Processed").map((data, index, orgArray) => {
                        rowTheList = index % 3 == 0 ? [] : rowTheList;;
                        rowTheList.push(data);
                        if (rowTheList.length >= 3 || orgArray.length - 1 == index) {
                            return <div className="outcome-row flexbox-3"> {rowTheList.map((row) => {
                                let datatypr = "Data Types Processed" ?
                                    row.AttributeValue.toString().toLowerCase() == "yes" ? "active-data" : "deactive-data" : "";

                                return <div className={`outcome-divider gray-clr ${datatypr}`}>
                                    <p>{row.AttributeName}</p>
                                </div>

                            })}</div>
                        }
                    })}

                </div>

            </div>
        }


        // })

        //this.setState({ metrics })
        // metrics = metricsValue;

        //this.setState({ metrics: metricsValue })

        // }

    }
    componentWillMount() {

        this.getSoCategoryDataAttribute(this.props.filterDetails);

    }
    componentDidMount() {


        this.getSoCategoryDataAttribute(this.props.filterDetails);
        var data = store.getState();
        //this.setState({  dataProcessedData: data.diverseReducers["diverse"]}) 
        this.state.dataProcessedData = data.diverseMasterReducers["diverse"];
        this.displayProcess();
        this.displayDataProcessed();
        store.subscribe(() => {

            var data = store.getState();
            this.setState({ dataProcessedData: data.diverseMasterReducers["diverse"] })
            this.displayDataProcessed();
        })
    }
    componentWillReceiveProps(nextProps) {

        if (this.props.filterDetails !== nextProps.filterDetails) {
            this.getSoCategoryDataAttribute(nextProps.filterDetails)
        }
    }
    render() {
        return (
            <>
                {this.displayProcess()}
                {this.displayDataProcessed()}
                {/* {this.state.viewMore2 ? <DataPopUp show={this.state.viewMore2} onClose={this.onClose} type={this.state.type} /> : null} */}
                {/* {this.state.edit ? <DataPopUp show={this.state.edit} onClose={this.onClose} type={this.state.type} api={this.state.dataProcessedData} id={this.state.id} edit={this.state.edit}/> : null} */}
                {/* {this.state.viewMore3 ? <DataTypePopUp show={this.state.viewMore3} onClose={this.onClose} type={this.state.type} api={this.state.dataProcessedData} /> : null} */}
            </>
        )

    }
}