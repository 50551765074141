import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { Carousel } from "react-bootstrap";
import i18n from "i18next";
import AddMetricDetails_MTC from "./AddMetricDetails_MTC";
import MetricComments from "../Dashboard/MetricComments";
import TrendGraphsWO from "../Analytics/TrendGraphsWO";
import { store } from "../reducers/configureStore";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";

import { LocalApiBaseUrl } from "../Shared/Constant";
import WOKpiPopUp from "../Configurator/woKpiPopup";
import emptycomponent from "../Images/Empty State.svg";
import { connect } from "react-redux";
import { ValueUnit, ValueUnitNoRoundOff, MetricValueUnitFormat, toolThreshold, CommonMetriTileFix } from "../Shared/Common";
import $ from 'jquery';
import emptyFeatureListIcon from "../Images/EmptyFeatureList.svg";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


class MetricListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentPopUp: false,
      woData: {
        SOCategoryID: 0,
        kpiData: {},
        viewGraph: false,
      },
      info: {},
      kpiMappingResponse: [],
      viewAllModalStatus: false,
    };
    this.MetricName = this.MetricName.bind(this);
    this.handleGraph = this.handleGraph.bind(this);
  }

  handleGraph(name, Id, mUnit, info, e) {
    let kpiMapping = Id;
    e.preventDefault(Id, e);
    this.setState({ viewGraph: true, kpiMapping, name, mUnit, info });
  }

  onClose = () => {
    this.setState({
      viewGraph: false,
    });
  };

  onCommentClick = (kpiID) => {
    this.state.commentPopUp ? this.setState({ commentPopUp: false }) : this.setState({ commentPopUp: true });
    this.props.metricData.filter((kpi) => kpi.KPIMappingID === kpiID)[0] &&
      this.setState({
        woData: {
          ...this.state.woData, kpiData: this.props.metricData.filter((kpi) => kpi.KPIMappingID === kpiID)[0],
        },
      });

  };

  deletekpi(data, e) {
    if (window.confirm("Are you sure you want to delete metric detail?")) {
      const woData = this.state.woData;
      woData.SOCategoryID = this.props.selectedSubStepID;
      woData.kpiData = data;
      this.setState({ woData: woData });

      this.props.screen === "SuperAdmin"
        ? axiosInstance
          .post(`${LocalApiBaseUrl}Master/DeleteKpiMetricMasterTemplate?kpiMappingId=${woData.kpiData.KPIMappingID}`)
          .then((response) => {
            window.alert(response.data.StatusMessage);
            this.props.getOfferingAPICallback(true);
            store.dispatch({
              type: "REMOVE_WO_METRIC_MASTER",
              payload: this.state.woData,
            });
          })
        : axiosInstance
          .delete(
            `${LocalApiBaseUrl}Configurator/DeleteDealMetrics?DealMetricsId=${woData.kpiData.ConfigurationDealMetricID}`
          )
          .then((response) => {
            if (response.data) {
              axiosInstance
                .get(`${LocalApiBaseUrl}Configurator/GetWorkOrchestrationQualityScore`, {
                  params: {
                    LocationID: this.props.filter.Locations,
                    OrgEntityID: this.props.filter.Client,
                    OfferingID: this.props.filter.process,
                    MonthDT: this.props.filter.Month,
                    LanguageID: store.getState().CurrentFilter.languageData.id,
                  },
                })
                .then((response) => {
                  if (response.data) {
                    store.dispatch({
                      type: "SAVE_QC_SCORE_OF_EACH_TAB",
                      payload: response.data,
                    });
                  }
                })
                .catch((error) => {
                  trycatchAlertPopup(error);
                });
              store.dispatch({
                type: "REMOVE_WO_METRIC",
                payload: this.state.woData,
              });
            }
          })
          .catch((error) => {
            trycatchAlertPopup(error);
          });



    }
  }

  componentDidMount() {
    let woData = this.state.woData;
    woData.kpiData = this.props.metricData[0];
    CommonMetriTileFix();
    this.setState({ woData: woData });
    this.getData();
  }
  MetricName(str) {
    let maxLength = 50;
    if (str.length <= maxLength) {
      return str;
    }
    const trimmedString = str.substring(0, maxLength);
    return trimmedString + "...";
  }

  viewAllModalShow = () => {
    this.setState({ viewAllModalStatus: true });
  };
  viewAllModalClose = () => {
    this.setState({ viewAllModalStatus: false });
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.metricData !== prevProps.metricData) {
      this.getData();
    }
  }

  getData = () => {
    let { metricData } = this.props;
    let metricDataDup = [];
    let MetricValue = "";
    let MetricValueUnitFormatData = "";
    let statusColor = "item gray-color";
    let tileName = "custom-tilecard";
    if (metricData.length != 0) {
      metricData.map((row, index) => {
        if (row != undefined) {
          if (row.RAGStatus != undefined && row.RAGStatus != null) {
            switch (row.RAGStatus.toLowerCase()) {
              case "grey":
                statusColor = "item gray-color";
                break;
              case "red":
                statusColor = "item red-color";
                break;
              case "orange":
                statusColor = "item amber-color";
                break;
              case "green":
                statusColor = "item green-color";
                break;
              case "amber":
                statusColor = "item amber-color";
                break;
              default:
            }
          }
          if (this.props.screen !== "SuperAdmin") {
            tileName = "custom-tilecard";
          }
          else {
            tileName = "custom-tilecard mtclayout-tilecard";
            statusColor = "item gray-color";
          }
        }
        if(row.MetricUnit.includes('$') || row.MetricUnit.includes('€') || row.MetricUnit.includes('£') || row.MetricUnit.includes('₹') || row.MetricUnit.includes('SGD') || row.MetricUnit.includes('R$')) {
          MetricValue = ValueUnitNoRoundOff(row.MetricValue);
          } 
          else {
            MetricValue = ValueUnit(row.MetricValue);
          }
        MetricValueUnitFormatData = MetricValueUnitFormat(row.MetricUnit.trim(), MetricValue);
        metricDataDup.push({ ...row, statusColor: statusColor, tileName: tileName, MetricValueUnitFormatData: MetricValueUnitFormatData })
      });
      this.setState({ metricDataDup });
    }
  };

  getMetricData(row) {
    let editDeleteRole = this.props.roleReducers.filter(f => {
      return (f.FeatureName == "Master Template Configurator" && (f.Operations.includes("Edit") || f.Operations.includes("Delete"))) //&& !f.Operations.includes("Add") && f.Operations.includes("View")
    })
    let accessValue = (this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") ||
      (this.props.RecordStatus &&
        this.props.RecordStatus[1] === "True" &&
        !this.props.isManintencneWindowOpen) ||
      (this.props.RecordStatus &&
        this.props.RecordStatus[0] === "Publish" &&
        !this.props.isManintencneWindowOpen) ||
      this.props.roleReducers.filter((each) => {
        if (each.FeatureName === "Configurator" && each.Operations.length) {
          return each;
        }
      }).length === 0 ||
      (this.props.RecordStatus &&
        this.props.RecordStatus[0] === "Save" &&
        this.props.roleReducers.filter((each) => {
          if (
            each.FeatureName === "Configurator" &&
            each.Operations.filter((op) => op === "Submit").length
          ) {
            return each;
          }
        }).length === 0) ||
      (this.props.RecordStatus &&
        this.props.RecordStatus[0] === "Submit" &&
        this.props.roleReducers.filter((each) => {
          if (
            each.FeatureName === "Configurator" &&
            each.Operations.filter((op) => op === "Publish").length
          ) {
            return each;
          }
        }).length === 0) ? true : false
    const {
      editMetricModalToggler,
      selectedSubStepID } = this.props;
    let allMetricData =
      <>
        <div className={` ${row.statusColor}`} data-for={"metrilToolTip_" + row.KPIMappingID} data-place="bottom" data-tip={toolThreshold(row)}>
          {this.props.screen !== "SuperAdmin" ? (
            <div className="item-left">
              <p className="tile-metric-value">
                {row.MetricValueUnitFormatData}
              </p>
              <p className="tile-metric-desc" data-tip data-for={"tilecardtooltip" + row.KPIMappingID}>
                {this.MetricName(row.MetricName)}
              </p>
            </div>
          ) : (
            <div className="item-left">
              <p className="tile-metric-value">{row.MetricUnit}</p>
              <p className="tile-metric-desc" data-tip data-for={"tilecardtooltip" + row.KPIMappingID}>
                {this.MetricName(row.MetricName)}
              </p>
            </div>
          )}
          {this.props.screen !== "SuperAdmin" ? (
            <div className="item-right middle-align">
              <p className="tile-metric-name" data-tip data-for={"customTooltip_desc" + row.KPIMappingID}>
                {row.KPIDesp}
              </p>
            </div>
          ) : (
            <div className="item-right">
              <p className="tile-metric-name" data-tip data-for={"customTooltip_desc" + row.KPIMappingID}>
                {row.KPIDesp}
              </p>
            </div>
          )}
          {this.props.screen !== "Dashboard" ? (
            this.props.screen === "Configurator" ||
              (this.props.screen === "SuperAdmin" && editDeleteRole.length > 0) ? (

              this.props.screen === "Configurator" ? (
                <div className="dropdown dropleft  ondropOpenColor">
                  <span type="button" className="dropdown-toggle" data-toggle="dropdown"></span>
                  <div className="dropdown-menu">
                    <a
                      href="javascript:void(0)"
                      className="dropdown-item"
                      onClick={this.onCommentClick.bind(this, row.KPIMappingID)}
                    >
                      <span className="info-icon"></span>Info
                    </a>

                    {accessValue ? (
                      ""
                    ) : (selectedSubStepID === 0 ? '' :
                      <>
                        {this.props.screen === "Configurator" ||
                          (this.props.screen === "SuperAdmin"
                            && this.props.roleReducers.filter((e) => { return e.FeatureName === "Master Template Configurator" && e.Operations.includes("Delete") }).length > 0) ?

                          <a
                            href="javascript:void(0)"
                            className="dropdown-item"
                            onClick={this.deletekpi.bind(this, row)}
                          >
                            <span className="delete-icon"></span>Remove
                          </a> : null
                        }
                        {this.props.screen === "Configurator" ||
                          (this.props.screen === "SuperAdmin"
                            && this.props.roleReducers.filter((e) => { return e.FeatureName === "Master Template Configurator" && e.Operations.includes("Edit") }).length > 0) ?
                          <a
                            href="javascript:void(0)"
                            className="dropdown-item"
                            onClick={() => editMetricModalToggler(row)}
                          >
                            <span className="edit-icon"></span>Edit
                          </a> : null
                        }
                      </>
                    )}
                  </div>
                </div>
              ) : (accessValue ? (
                ""
              ) : (selectedSubStepID === 0 ? '' :
                <div className="dropdown dropleft  ondropOpenColor">
                  <span type="button" className="dropdown-toggle" data-toggle="dropdown"></span>
                  <div className="dropdown-menu">
                    {this.props.screen === "Configurator" ||
                      (this.props.screen === "SuperAdmin"
                        && this.props.roleReducers.filter((e) => { return e.FeatureName === "Master Template Configurator" && e.Operations.includes("Delete") }).length > 0) ?

                      <a
                        href="javascript:void(0)"
                        className="dropdown-item"
                        onClick={this.deletekpi.bind(this, row)}
                      >
                        <span className="delete-icon"></span>Remove
                      </a> : null
                    }
                    {this.props.screen === "Configurator" ||
                      (this.props.screen === "SuperAdmin"
                        && this.props.roleReducers.filter((e) => { return e.FeatureName === "Master Template Configurator" && e.Operations.includes("Edit") }).length > 0) ?
                      <a
                        href="javascript:void(0)"
                        className="dropdown-item"
                        onClick={() => editMetricModalToggler(row)}
                      >
                        <span className="edit-icon"></span>Edit
                      </a> : null
                    }
                  </div>
                </div>
              ))
            ) : null
          ) : (
            <div className="dropdown dropleft  ondropOpenColor">
              <span type="button" className="dropdown-toggle" data-toggle="dropdown"></span>
              <div className="dropdown-menu">
                <a
                  href="javascript:void(0)"
                  className="dropdown-item"
                  onClick={this.onCommentClick.bind(this, row.KPIMappingID)}
                >
                  <span className="info-icon"></span>Info
                </a>
                <a
                  href="javascript:void(0)"
                  className="dropdown-item"
                  onClick={(e) => this.handleGraph(row.MetricName, row.KPIMappingID, row.MetricUnit, row, e)}
                >
                  <span className="insights-new-icon"></span>Insights
                </a>
              </div>
            </div>
          )}
        </div>
      </>
    return allMetricData;
  }

  render() {

    let {
      addMetricModalToggler,
      addMetricStatus,
      addMetricConfStatus,
      editMetricConfStatus,
      editMetricModalToggler,
      editMetricStatus,
      metricData,
      filter,
      SOCategoryID,
      screen,
      selectedSubStepID
    } = this.props;
    let { metricDataDup } = this.state;
    const numberFormatter = new Intl.NumberFormat("en", {
      useGrouping: true,
    });
    let allMetricsData = ((metricDataDup && metricDataDup.length > 12) ? metricDataDup.slice(0, 12) : metricDataDup);
    return (
      <>
        {metricData.length == 0 ? (
          <div className="tool-data">
            <div className="add-toolicon">
              {screen !== "Dashboard" &&
                ((this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") ||
                  (this.props.RecordStatus &&
                    this.props.RecordStatus[1] === "True" &&
                    !this.props.isManintencneWindowOpen) ||
                  (this.props.RecordStatus &&
                    this.props.RecordStatus[0] === "Publish" &&
                    !this.props.isManintencneWindowOpen) ||
                  this.props.roleReducers.filter((each) => {
                    if (each.FeatureName === "Configurator" && each.Operations.length) {
                      return each;
                    }
                  }).length === 0 ||
                  (this.props.RecordStatus &&
                    this.props.RecordStatus[0] === "Save" &&
                    this.props.roleReducers.filter((each) => {
                      if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Submit").length) {
                        return each;
                      }
                    }).length === 0) ||
                  (this.props.RecordStatus &&
                    this.props.RecordStatus[0] === "Submit" &&
                    this.props.roleReducers.filter((each) => {
                      if (
                        each.FeatureName === "Configurator" &&
                        each.Operations.filter((op) => op === "Publish").length
                      ) {
                        return each;
                      }
                    }).length === 0) ? (
                  ""
                ) : (
                  <Button className="show-pop" onClick={() => addMetricModalToggler()} disabled={selectedSubStepID === 0 ? true : false}>
                    <img src={emptycomponent} alt="" width="80" height="80" />
                  </Button>
                ))}
              {this.props.screen === 'Dashboard'
                ? (<h6 className="tool-metric-empty text-center">
                  <img src={emptyFeatureListIcon}
                    className="mb-1"
                    width="40"
                    height="40"
                    alt={i18n.t("No_metrics_available")}
                  /><br />
                  {selectedSubStepID === 0
                    ? i18n.t("There is no metric available for this Step")
                    : i18n.t("There is no metric available for this sub-step")}
                </h6>
                )
                : (<h6>
                  {selectedSubStepID === 0
                    ? i18n.t("Please select the sub step and click on '+' to add metrics.")
                    : i18n.t("Please click on '+' to add step metrics")}
                </h6>)
              }
            </div>
          </div>
        ) : (
          <div className={metricDataDup && metricDataDup.length > 1 ? "metric-datalist" : "metric-datalist  single-item-carousel"}>
            <div className="or-carousel-container">
              <Carousel>
                {metricDataDup && allMetricsData && allMetricsData.map((row, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <div className={` ${row.tileName}`}>
                        <div className="tilecard-wrapper no-carousel">
                          {this.getMetricData(row)}
                        </div>
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
              {metricDataDup && metricDataDup.map((row, index) => {
                return (
                  <>
                    <ReactTooltip place="bottom" effect="solid" className="tipcolor wo-metrictooltip" id={"metrilToolTip_" + row.KPIMappingID} />
                  </>);
              })}
              {metricDataDup && metricDataDup.length > 12 &&
                <div className="oritems-seeall" onClick={() => this.viewAllModalShow()}>
                  {i18n.t('View All')}
                </div>
              }
              <Modal
                show={this.state.viewAllModalStatus}
                onHide={() => this.viewAllModalClose()}
                className="wo-viewAll-modal"
                backdrop="static"
              >
                <Modal.Header closeButton>
                  <Modal.Title>{i18n.t('All Metrics')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="modal-tilecard-wrapper">
                    <div className="custom-tilecard">
                      <div className="tilecard-wrapper no-carousel">
                        {metricDataDup && metricDataDup.map((row, index) => {
                          return (
                            <>
                              {this.getMetricData(row)}
                              <ReactTooltip place="bottom" effect="solid" className="tipcolor wo-metrictooltip" id={"metrilToolTip_" + row.KPIMappingID} />
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

            </div>
          </div>
        )}
        {addMetricStatus ? (
          <AddMetricDetails_MTC
            show={addMetricStatus}
            modalClose={addMetricModalToggler}
            kpiData={[]}
            tooldatatype={"ADD"}
            filter={filter}
            metricDesp={this.props.metricDesp}
            SOCategoryID={SOCategoryID}
            getOfferingAPICallback={this.props.getOfferingAPICallback}
            selectedSubStepID={selectedSubStepID}
          />
        ) : (
          editMetricStatus && (
            <AddMetricDetails_MTC
              show={editMetricStatus}
              modalClose={editMetricModalToggler}
              isScreenInEditMode={editMetricStatus}
              kpiData={this.props.kpiData}
              metricDesp={this.props.metricDesp}
              tooldatatype={"Edit"}
              filter={filter}
              SOCategoryID={SOCategoryID}
              getOfferingAPICallback={this.props.getOfferingAPICallback}
              selectedSubStepID={selectedSubStepID}
            />
          )
        )}
        {addMetricConfStatus ? (
          <WOKpiPopUp
            filter={filter}
            metricDesp={this.props.metricDesp}
            SOCategoryID={SOCategoryID}
            show={addMetricConfStatus}
            tooldatatype={"Add"}
            modalClose={addMetricModalToggler}
            kpiData={[]}
            kpiMappingResponse={this.props.kpiMappingResponse}
            selectedSubStepID={selectedSubStepID}
          />
        ) : null}
        {editMetricConfStatus ? (
          <WOKpiPopUp
            filter={filter}
            metricDesp={this.props.metricDesp}
            SOCategoryID={SOCategoryID}
            show={editMetricConfStatus}
            editMetricConfStatus={editMetricConfStatus}
            kpiData={this.props.kpiData}
            tooldatatype={"Edit"}
            modalClose={editMetricModalToggler}
            kpiMappingResponse={this.props.kpiMappingResponse}
            selectedSubStepID={selectedSubStepID}
          />
        ) : null}
        {this.state.viewGraph ? (
          <TrendGraphsWO
            show={true}
            onClose={() => this.onClose()}
            kpiMappingId={this.state.kpiMapping}
            kpiName={this.state.name}
            kpiUnit={this.state.mUnit}
            info={this.state.info}
            dealFilter={this.props.dealFilter}
          />
        ) : null}
        {this.state.commentPopUp ? (
          <MetricComments
            kpiName={this.state.woData.kpiData.metricName}
            info={this.state.woData.kpiData}
            show={this.state.commentPopUp}
            onClose={this.onCommentClick.bind(this, 2, "null")}
          />
        ) : null}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    roleReducers: state.roleReducers.roleReducers,
  };
};

export default connect(
  mapStateToProps,
  ""
)(MetricListContainer);
