import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { LocalApiBaseUrl } from '../Shared/Constant';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import { regexForGOCFields } from '../Shared/Constant';
import Moment from 'moment';
import { store } from "../reducers/configureStore"
import { months } from 'moment';
import { isThisQuarter } from 'date-fns';


export default class GOCForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            //PeriodDT: null,


            apivalue: "", selectValue: this.props.SelectValue, Display: false, viewmore: false,
            MetricNameOptions: [], criteriaOptions: [], valueConnector: false, con: false, valueEdit: false, valueConnectorEdit: false,
            errors: {}, formIsValid: false,featureData:[],
            data1: [],
            data: {
                PeriodDT: "",
                SOCategoryID: 0,
                startDate: "",
                kpiData: {
                    GOCID: 0,
                    //PeriodDT :"",
                    startDate: "",
                    IOJ_Foundation: "",
                    IOJ_Automated: "",
                    IOJ_Inslights: "",
                    IOJ_Intelligent: "",
                    IOT_Foundation: "",
                    IOT_Automated: "",
                    IOT_Inslights: "",
                    IOT_Intelligent: "",
                    BO_TotalBussinessValue: "",
                    BO_YTD: "",
                    BO_DeliveredProjects: "",
                    BO_ActiveProjects: "",
                    BO_Clients: "",
                    HMT_FoundationBOTs: "",
                    HMT_AutomationsDeployed: "",
                    HMT_RPABOTs: "",
                    HMT_GPHUsers: "",
                    WO_ACNApplications: "",
                    WO_TicketsProcessed_Permonth: "",
                    WO_DocumentsScanned_Permonth: "",
                    WO_TransactionsProcessedbyAutomations_Permonth: "",
                    II_AIAdvisor: "",
                    II_AnalyticsApps: "",
                    II_ClientsCovered: "",
                    II_BusinessOutcomesDelivered: "",
                    DD_InternalBenchmarksDeveloped: "",
                    DD_DataPointersToTrainAIEngines: "",
                    DD_ExternalBenchmarksTracked: "",
                    DD_ExpertsAugmentedwithAppliedIntelligence: "",
                    TF_Training_R2TNCompletiond: "",
                    TF_Training_TFTrained: "",
                    TF_Training_TrainingHours: "",
                    TF_NicheSkills_Analytics: "",
                    TF_NicheSkills_BusinessExcellence: "",
                    TF_NicheSkills_OverallBusinessAdvisors: "",
                    TF_NicheSkills_ArtificialIntelligence: "",
                    TF_NicheSkills_IntelligentAutomation: "",
                    TF_NicheSkills_BusinessAdvisorforFA: "",
                    TF_NicheSkills_IndustryCertifiedResources: "",
                    TF_NicheSkills_TechMasters: "",
                    TF_NicheSkills_BusinessAdvisorforProcurement: "",
                    CreatedUser: ""
                    //IsAccentureOwned: false

                }
            }
        }
         this.getRoleData = this.getRoleData.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.onIOJFoundationChange = this.onIOJFoundationChange.bind(this);
        this.onIOJAutomatedChange = this.onIOJAutomatedChange.bind(this);
        this.onIOJInslightsChange = this.onIOJInslightsChange.bind(this);
        this.onIOJIntelligentChange = this.onIOJIntelligentChange.bind(this);
        this.onIOTFoundationChange = this.onIOTFoundationChange.bind(this);
        this.onIOTAutomatedChange = this.onIOTAutomatedChange.bind(this);
        this.onIOTInslightsChange = this.onIOTInslightsChange.bind(this);
        this.onIOTIntelligentChange = this.onIOTIntelligentChange.bind(this);

        this.onBOTotalBussinessValueChange = this.onBOTotalBussinessValueChange.bind(this);
        this.onBOYTDedChange = this.onBOYTDedChange.bind(this);
        this.onBODeliveredProjectsChange = this.onBODeliveredProjectsChange.bind(this);
        this.onBOActiveProjectsChange = this.onBOActiveProjectsChange.bind(this);
        this.onBOClientsChange = this.onBOClientsChange.bind(this);

        this.onHMTAutomationsDeployedChange = this.onHMTAutomationsDeployedChange.bind(this);
        this.onHMTFoundationBOTsChange = this.onHMTFoundationBOTsChange.bind(this);
        this.onHMTRPABOTsChange = this.onHMTRPABOTsChange.bind(this);
        this.onHMTGPHUsersChange = this.onHMTGPHUsersChange.bind(this);
        this.onWOACNApplicationsChange = this.onWOACNApplicationsChange.bind(this);
        this.onWOTicketsProcessedPermonthChange = this.onWOTicketsProcessedPermonthChange.bind(this);
        this.onWODocumentsScannedPermonthChange = this.onWODocumentsScannedPermonthChange.bind(this);
        this.onWOTransactionsProcessedbyAutomationsPermonthChange = this.onWOTransactionsProcessedbyAutomationsPermonthChange.bind(this);

        this.onIIAIAdvisorChange = this.onIIAIAdvisorChange.bind(this);
        this.onIIAnalyticsAppsChange = this.onIIAnalyticsAppsChange.bind(this);
        this.onIIClientsCoveredChange = this.onIIClientsCoveredChange.bind(this);
        this.onIIBusinessOutcomesDeliveredChange = this.onIIBusinessOutcomesDeliveredChange.bind(this);
        this.onDDInternalBenchmarksDevelopedChange = this.onDDInternalBenchmarksDevelopedChange.bind(this);
        this.onDDDataPointersToTrainAIEnginesChange = this.onDDDataPointersToTrainAIEnginesChange.bind(this);
        this.onDDExternalBenchmarksTrackedChange = this.onDDExternalBenchmarksTrackedChange.bind(this);
        this.onDDExpertsAugmentedwithAppliedIntelligenceChange = this.onDDExpertsAugmentedwithAppliedIntelligenceChange.bind(this);


        this.onTFTrainingR2TNCompletiondChange = this.onTFTrainingR2TNCompletiondChange.bind(this);
        this.onTFTrainingTFTrainedChange = this.onTFTrainingTFTrainedChange.bind(this);
        this.onTFTrainingTrainingHoursChange = this.onTFTrainingTrainingHoursChange.bind(this);
        this.onTFNicheSkillsAnalyticsChange = this.onTFNicheSkillsAnalyticsChange.bind(this);
        this.onTFNicheSkillsBusinessExcellenceChange = this.onTFNicheSkillsBusinessExcellenceChange.bind(this);
        this.onTFNicheSkillsOverallBusinessAdvisorsChange = this.onTFNicheSkillsOverallBusinessAdvisorsChange.bind(this);
        this.onTFNicheSkillsArtificialIntelligenceChange = this.onTFNicheSkillsArtificialIntelligenceChange.bind(this);
        this.onTFNicheSkillsIntelligentAutomationChange = this.onTFNicheSkillsIntelligentAutomationChange.bind(this);
        this.onTFNicheSkillsBusinessAdvisorforFAChange = this.onTFNicheSkillsBusinessAdvisorforFAChange.bind(this);
        this.onTFNicheSkillsIndustryCertifiedResourcesChange = this.onTFNicheSkillsIndustryCertifiedResourcesChange.bind(this);
        this.onTFNicheSkillsTechMastersChange = this.onTFNicheSkillsTechMastersChange.bind(this);
        this.onTFNicheSkillsBusinessAdvisorforProcurementChange = this.onTFNicheSkillsBusinessAdvisorforProcurementChange.bind(this);
        this.handleSave = this.handleSave.bind(this);


    }

    handleReset() {

        this.state.data.kpiData.GOCID = 0;
        this.state.data.kpiData.IOJ_Foundation = "";
        this.state.data.kpiData.IOJ_Automated = "";
        this.state.data.kpiData.IOJ_Inslights = "";
        this.state.data.kpiData.IOJ_Intelligent = "";
        this.state.data.kpiData.IOT_Foundation = "";
        this.state.data.kpiData.IOT_Automated = "";
        this.state.data.kpiData.IOT_Inslights = "";
        this.state.data.kpiData.IOT_Intelligent = "";
        this.state.data.kpiData.BO_TotalBussinessValue = "";
        this.state.data.kpiData.BO_YTD = "";
        this.state.data.kpiData.BO_DeliveredProjects = "";
        this.state.data.kpiData.BO_ActiveProjects = "";
        this.state.data.kpiData.BO_Clients = "";
        this.state.data.kpiData.HMT_FoundationBOTs = "";
        this.state.data.kpiData.HMT_AutomationsDeployed = "";
        this.state.data.kpiData.HMT_RPABOTs = "";
        this.state.data.kpiData.HMT_GPHUsers = "";
        this.state.data.kpiData.WO_ACNApplications = "";
        this.state.data.kpiData.WO_TicketsProcessed_Permonth = "";
        this.state.data.kpiData.WO_DocumentsScanned_Permonth = "";
        this.state.data.kpiData.WO_TransactionsProcessedbyAutomations_Permonth = "";
        this.state.data.kpiData.II_AIAdvisor = "";
        this.state.data.kpiData.II_AnalyticsApps = "";
        this.state.data.kpiData.II_ClientsCovered = "";
        this.state.data.kpiData.II_BusinessOutcomesDelivered = "";
        this.state.data.kpiData.DD_InternalBenchmarksDeveloped = "";
        this.state.data.kpiData.DD_DataPointersToTrainAIEngines = "";
        this.state.data.kpiData.DD_ExternalBenchmarksTracked = "";
        this.state.data.kpiData.DD_ExpertsAugmentedwithAppliedIntelligence = "";
        this.state.data.kpiData.TF_Training_R2TNCompletiond = "";
        this.state.data.kpiData.TF_Training_TFTrained = "";
        this.state.data.kpiData.TF_Training_TrainingHours = "";
        this.state.data.kpiData.TF_NicheSkills_Analytics = "";
        this.state.data.kpiData.TF_NicheSkills_BusinessExcellence = "";
        this.state.data.kpiData.TF_NicheSkills_OverallBusinessAdvisors = "";
        this.state.data.kpiData.TF_NicheSkills_ArtificialIntelligence = "";
        this.state.data.kpiData.TF_NicheSkills_IntelligentAutomation = "";
        this.state.data.kpiData.TF_NicheSkills_BusinessAdvisorforFA = "";
        this.state.data.kpiData.TF_NicheSkills_IndustryCertifiedResources = "";
        this.state.data.kpiData.TF_NicheSkills_TechMasters = "";
        this.state.data.kpiData.TF_NicheSkills_BusinessAdvisorforProcurement = "";

    }

    componentDidMount() {
        
        this.getRoleData();
        this.getKpiMAppingDetails();
        this.getGetConnectorMaster();
        
        if (this.props.kpiData) {

            this.setState({
                data: {
                    kpiData: this.props.kpiData,
                },
                errors: {},
                formIsValid: true
            })


        }

    }

    getRoleData() {
        
        axiosInstance.get(`${LocalApiBaseUrl}User/GetUserFeature`, {
            params: {}
        })
            .then((response) => {


                store.dispatch({
                    type: 'INITIALISEROLE',

                    payload: response.data

                })
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
      

        var data = store.getState();
        let roleData1 = data.roleReducers["roleReducers"];
        store.subscribe(() => {
            var data = store.getState();
            this.setState({ roleData: data.roleReducers["roleReducers"] })
            let featureDetails1 = data.roleReducers["roleReducers"];
            this.setState({ featureData: featureDetails1 })

        })
    }



    componentWillMount() {
        
    this.getRoleData();
        this.getKpiMAppingDetails();
        this.getGetConnectorMaster();
        
        if (this.props.kpiData) {

            this.setState({
                data: {
                    kpiData: this.props.kpiData,
                },
                errors: {},
                formIsValid: true
            })


        }
    }

    getKpiMAppingDetails() {
        ;
        let samp = {};
        let valueConnectorEdit = false;
        axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetKpiMapping`, {
            // params: { OrgEntityID: this.props.filterDetails.Client, LocationID: this.props.filterDetails.Locations, OfferingID: this.props.filterDetails.process, SOCategoryID: this.props.SOCategoryID }
        })
            .then((response) => {
                let mappingData = response.data;
                if (this.props.kpiData) {
                    let mappedConnector = mappingData.filter((x) => x.MetricName == this.props.kpiData.MetricName)
                    let mappedConnectorName = mappedConnector[0].ConnectorName;
                    if (mappedConnectorName != null) {
                        valueConnectorEdit = true;
                    }
                }
                this.setState({ MetricNameOptions: response.data, valueConnectorEdit });
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
      
    }

    getGetConnectorMaster() {
        ;
        let source = [];
        axiosInstance.get(`${LocalApiBaseUrl}Connector/GetConnectorMaster`)
            .then((response) => {
                source = response.data;
                let sourceValues = source.map((x) => <option key={x.ConnectorID} /*selected={index == 0 ? "" : ""}*/ value={x.ConnectorID}>{x.ConnectorName}</option>)
                this.setState({ sourceOptions: sourceValues });
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
      

    }

    onStartDateChange = (event) => {
        
        let errors = {};
        this.setState({
            errors: errors
        });
        const data = this.state.data;
        data.PeriodDT = event;
        data.startDate = event;
        data.kpiData.startDate = event;
        data.kpiData.PeriodDT = event;

        // this.setState({ PeriodDT: event });
        this.setState({
            data: data
        })
        this.QueryString();
    }

    QueryString() {
        
        var StartDate = Moment(this.state.data.PeriodDT).format('MM/DD/YYYY')
        const data = this.state.data;
        data.PeriodDT = StartDate;
        this.setState({
            data: data,
        })

        this.getData()
    }

    getData() {

        
        axiosInstance.get(`${LocalApiBaseUrl}Value/GetGocInfoByMonth`, {
            params: { PeriodDT: this.state.data.PeriodDT }
        })
            .then((response) => {
                
                let data = this.state.data

                if (response.data === "Data of this month is not availbale") {
                    //alert("Data of this month is not Available")
                    //data.kpiData=data

                    data.PeriodDT = (this.state.data.PeriodDT)
                    data.kpiData.PeriodDT = (this.state.data.PeriodDT)

                    this.handleReset();
                    this.setState({
                        data: data

                    })


                }
                else {
                    data.kpiData = response.data


                    this.setState({
                        data: data

                    })

                }
            })
            .catch((error) => {
                trycatchAlertPopup(error);
            })
    }


    onIOJFoundationChange(event) {
        
        let errors = {};
        this.setState({
            errors: errors
        });
        const kpiData = this.state.data.kpiData;
        kpiData.IOJ_Foundation = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onIOJAutomatedChange(event) {
        
        let errors = {};
        this.setState({
            errors: errors
        });
        const kpiData = this.state.data.kpiData;
        kpiData.IOJ_Automated = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })

    }
    onIOJInslightsChange(event) {
        
        let errors = {};
        this.setState({
            errors: errors
        });
        const kpiData = this.state.data.kpiData;
        kpiData.IOJ_Inslights = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })

    }
    onIOJIntelligentChange(event) {
        
        let errors = {};
        this.setState({
            errors: errors
        });
        const kpiData = this.state.data.kpiData;
        kpiData.IOJ_Intelligent = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })

    }

    onIOTFoundationChange(event) {
        
        let errors = {};
        this.setState({
            errors: errors
        });
        const kpiData = this.state.data.kpiData;
        kpiData.IOT_Foundation = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }

    onIOTAutomatedChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.IOT_Automated = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onIOTInslightsChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.IOT_Inslights = event.target.value;
        kpiData.startDate = this.state.data.startDate
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onIOTIntelligentChange(event) {

        
        const kpiData = this.state.data.kpiData;
        kpiData.IOT_Intelligent = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onBOTotalBussinessValueChange(event) {
        

        const kpiData = this.state.data.kpiData;
        kpiData.BO_TotalBussinessValue = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })


    }
    onBOYTDedChange(event) {
        
        
        const kpiData = this.state.data.kpiData;
        kpiData.BO_YTD = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onBODeliveredProjectsChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.BO_DeliveredProjects = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onBOActiveProjectsChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.BO_ActiveProjects = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }

    onBOClientsChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.BO_Clients = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onHMTAutomationsDeployedChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.HMT_AutomationsDeployed = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onHMTFoundationBOTsChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.HMT_FoundationBOTs = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onHMTRPABOTsChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.HMT_RPABOTs = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onHMTGPHUsersChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.HMT_GPHUsers = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onWOACNApplicationsChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.WO_ACNApplications = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onWOTicketsProcessedPermonthChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.WO_TicketsProcessed_Permonth = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onWODocumentsScannedPermonthChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.WO_DocumentsScanned_Permonth = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onWOTransactionsProcessedbyAutomationsPermonthChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.WO_TransactionsProcessedbyAutomations_Permonth = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onIIAIAdvisorChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.II_AIAdvisor = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onIIAnalyticsAppsChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.II_AnalyticsApps = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onIIClientsCoveredChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.II_ClientsCovered = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onIIBusinessOutcomesDeliveredChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.II_BusinessOutcomesDelivered = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate

            }
        })
    }
    onDDInternalBenchmarksDevelopedChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.DD_InternalBenchmarksDeveloped = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onDDDataPointersToTrainAIEnginesChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.DD_DataPointersToTrainAIEngines = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onDDExternalBenchmarksTrackedChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.DD_ExternalBenchmarksTracked = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onDDExpertsAugmentedwithAppliedIntelligenceChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.DD_ExpertsAugmentedwithAppliedIntelligence = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onTFTrainingR2TNCompletiondChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.TF_Training_R2TNCompletiond = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onTFTrainingTFTrainedChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.TF_Training_TFTrained = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }
    onTFTrainingTrainingHoursChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.TF_Training_TrainingHours = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }

    onTFNicheSkillsAnalyticsChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.TF_NicheSkills_Analytics = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }

    onTFNicheSkillsBusinessExcellenceChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.TF_NicheSkills_BusinessExcellence = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }

    onTFNicheSkillsOverallBusinessAdvisorsChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.TF_NicheSkills_OverallBusinessAdvisors = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }

    onTFNicheSkillsArtificialIntelligenceChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.TF_NicheSkills_ArtificialIntelligence = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }

    onTFNicheSkillsIntelligentAutomationChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.TF_NicheSkills_IntelligentAutomation = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }

    onTFNicheSkillsBusinessAdvisorforFAChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.TF_NicheSkills_BusinessAdvisorforFA = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }

    onTFNicheSkillsIndustryCertifiedResourcesChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.TF_NicheSkills_IndustryCertifiedResources = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }

    onTFNicheSkillsTechMastersChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.TF_NicheSkills_TechMasters = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate
            }
        })
    }

    onTFNicheSkillsBusinessAdvisorforProcurementChange(event) {
        
        const kpiData = this.state.data.kpiData;
        kpiData.TF_NicheSkills_BusinessAdvisorforProcurement = event.target.value;
        kpiData.startDate = this.state.data.startDate;
        //kpiData.PeriodDT=this.state.data.PeriodDT
        this.setState({
            data: {
                kpiData: kpiData,
                startDate: this.state.data.startDate,

            }
        })
    }




    //handleSave(data) {
    //    

    //    if (data != undefined) {
    //        // edit condition
    //        if (this.state.featureData.filter((data) => data.Operations.includes("Edit")).length > 0) {
               
    //            if (this.validateForm()) {

    //                
                   
    //                axiosInstance.post(`${LocalApiBaseUrl}Value/AddEditGOC`, data)
    //                    .then((response) => {
    //                        window.alert(response.data.StatusMessage)
    //                        window.location.reload();
                            
    //                    });
    //            }
    //        }
    //    }
    //    else {
    //        // add condition
    //        if (this.state.featureData.filter((data) =>data.Operations.includes("Add")).length > 0) {

             
    //            if (this.validateForm()) {

    //                
                  
    //                axiosInstance.post(`${LocalApiBaseUrl}Value/AddEditGOC`, data)
    //                    .then((response) => {
    //                        window.alert(response.data.StatusMessage)
    //                        window.location.reload();
                           
    //                    });
    //            }
    //        }

    //    }
        
    //}


    handleSave(data) {
        

        if (data != undefined) {

            if (this.validateForm()) {

                

                axiosInstance.post(`${LocalApiBaseUrl}Value/AddEditGOC`, data)
                    .then((response) => {
                        window.alert(response.data.StatusMessage)
                        window.location.reload();

                    })
                    .catch((error) => {
                        trycatchAlertPopup(error);
                      });
              
            }

        }


    }
  

    validateForm() {
        

        let kpiData = this.state.data.kpiData;
        let errors = {};
        let formIsValid = true;
        if (!kpiData["PeriodDT"]) {
            formIsValid = false;
            errors["PeriodDT"] = "Month is required";

        }

        if (!kpiData["IOJ_Foundation"].trim()) {
            formIsValid = false;
            errors["IOJ_Foundation"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["IOJ_Foundation"]);
            if (!isValid) {
                formIsValid = false;
                errors["IOJ_Foundation"] = "Value contains Special characters";
            }
        }
        if (!kpiData["IOJ_Automated"].trim()) {
            formIsValid = false;
            errors["IOJ_Automated"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["IOJ_Automated"]);
            if (!isValid) {
                formIsValid = false;
                errors["IOJ_Automated"] = "Value contains Special characters";
            }
        }
        if (!kpiData["IOJ_Inslights"].trim()) {
            formIsValid = false;
            errors["IOJ_Inslights"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["IOJ_Inslights"]);
            if (!isValid) {
                formIsValid = false;
                errors["IOJ_Inslights"] = "Value contains Special characters";
            }
        }
        if (!kpiData["IOJ_Intelligent"].trim()) {
            formIsValid = false;
            errors["IOJ_Intelligent"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["IOJ_Intelligent"]);
            if (!isValid) {
                formIsValid = false;
                errors["IOJ_Intelligent"] = "Value contains Special characters";
            }
        }
        if (!kpiData["IOT_Foundation"].trim()) {
            formIsValid = false;
            errors["IOT_Foundation"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["IOT_Foundation"]);
            if (!isValid) {
                formIsValid = false;
                errors["IOT_Foundation"] = "Value contains Special characters";
            }
        }
        if (!kpiData["IOT_Automated"].trim()) {
            formIsValid = false;
            errors["IOT_Automated"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["IOT_Automated"]);
            if (!isValid) {
                formIsValid = false;
                errors["IOT_Automated"] = "Value contains Special characters";
            }
        }
        if (!kpiData["IOT_Inslights"].trim()) {
            formIsValid = false;
            errors["IOT_Inslights"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["IOT_Inslights"]);
            if (!isValid) {
                formIsValid = false;
                errors["IOT_Inslights"] = "Value contains Special characters";
            }
        }
        if (!kpiData["IOT_Intelligent"].trim()) {
            formIsValid = false;
            errors["IOT_Intelligent"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["IOT_Intelligent"]);
            if (!isValid) {
                formIsValid = false;
                errors["IOT_Intelligent"] = "Value contains Special characters";
            }
        }
        if (!kpiData["BO_TotalBussinessValue"].trim()) {
            formIsValid = false;
            errors["BO_TotalBussinessValue"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["BO_TotalBussinessValue"]);
            if (!isValid) {
                formIsValid = false;
                errors["BO_TotalBussinessValue"] = "Value contains Special characters";
            }
        }
        if (!kpiData["BO_YTD"].trim()) {
            formIsValid = false;
            errors["BO_YTD"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["BO_YTD"]);
            if (!isValid) {
                formIsValid = false;
                errors["BO_YTD"] = "Value contains Special characters";
            }
        }

        if (!kpiData["BO_DeliveredProjects"].trim()) {
            formIsValid = false;
            errors["BO_DeliveredProjects"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["BO_DeliveredProjects"]);
            if (!isValid) {
                formIsValid = false;
                errors["BO_DeliveredProjects"] = "Value contains Special characters";
            }
        }

        if (!kpiData["BO_ActiveProjects"].trim()) {
            formIsValid = false;
            errors["BO_ActiveProjects"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["BO_ActiveProjects"]);
            if (!isValid) {
                formIsValid = false;
                errors["BO_ActiveProjects"] = "Value contains Special characters";
            }
        }

        if (!kpiData["BO_Clients"].trim()) {
            formIsValid = false;
            errors["BO_Clients"] = "Value is Required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["BO_Clients"]);
            if (!isValid) {
                formIsValid = false;
                errors["BO_Clients"] = "Value contains Special characters";
            }
        }
        if (!kpiData["HMT_FoundationBOTs"].trim()) {
            formIsValid = false;
            errors["HMT_FoundationBOTs"] = "Value is Required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["HMT_FoundationBOTs"]);
            if (!isValid) {
                formIsValid = false;
                errors["HMT_FoundationBOTs"] = "Value contains Special characters";
            }
        } if (!kpiData["HMT_AutomationsDeployed"].trim()) {
            formIsValid = false;
            errors["HMT_AutomationsDeployed"] = "Value is Required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["HMT_AutomationsDeployed"]);
            if (!isValid) {
                formIsValid = false;
                errors["HMT_AutomationsDeployed"] = "Value contains Special characters";
            }
        }

        if (!kpiData["HMT_RPABOTs"].trim()) {
            formIsValid = false;
            errors["HMT_RPABOTs"] = "Value is Required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["HMT_RPABOTs"]);
            if (!isValid) {
                formIsValid = false;
                errors["HMT_RPABOTs"] = "Value contains Special characters";
            }
        }
        if (!kpiData["HMT_GPHUsers"].trim()) {
            formIsValid = false;
            errors["HMT_GPHUsers"] = "Value is Required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["HMT_GPHUsers"]);
            if (!isValid) {
                formIsValid = false;
                errors["HMT_GPHUsers"] = "Value contains Special characters";
            }
        }

        if (!kpiData["WO_ACNApplications"].trim()) {
            formIsValid = false;
            errors["WO_ACNApplications"] = "Value is Required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["WO_ACNApplications"]);
            if (!isValid) {
                formIsValid = false;
                errors["WO_ACNApplications"] = "Value contains Special characters";
            }
        }
        if (!kpiData["WO_TicketsProcessed_Permonth"].trim()) {
            formIsValid = false;
            errors["WO_TicketsProcessed_Permonth"] = "Value is Required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["WO_TicketsProcessed_Permonth"]);
            if (!isValid) {
                formIsValid = false;
                errors["WO_TicketsProcessed_Permonth"] = "Value contains Special characters";
            }
        }
        if (!kpiData["WO_DocumentsScanned_Permonth"].trim()) {
            formIsValid = false;
            errors["WO_DocumentsScanned_Permonth"] = "Value is Required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["WO_DocumentsScanned_Permonth"]);
            if (!isValid) {
                formIsValid = false;
                errors["WO_DocumentsScanned_Permonth"] = "Value contains Special characters";
            }
        }
        if (!kpiData["WO_TransactionsProcessedbyAutomations_Permonth"].trim()) {
            formIsValid = false;
            errors["WO_TransactionsProcessedbyAutomations_Permonth"] = "Value is Required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["WO_TransactionsProcessedbyAutomations_Permonth"]);
            if (!isValid) {
                formIsValid = false;
                errors["WO_TransactionsProcessedbyAutomations_Permonth"] = "Value contains Special characters";
            }
        }

        if (!kpiData["II_AIAdvisor"].trim()) {
            formIsValid = false;
            errors["II_AIAdvisor"] = "Value is Required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["II_AIAdvisor"]);
            if (!isValid) {
                formIsValid = false;
                errors["II_AIAdvisor"] = "Value contains Special characters";
            }
        }

        if (!kpiData["II_AnalyticsApps"].trim()) {
            formIsValid = false;
            errors["II_AnalyticsApps"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["II_AnalyticsApps"]);
            if (!isValid) {
                formIsValid = false;
                errors["II_AnalyticsApps"] = "Value contains Special characters";
            }
        }
        if (!kpiData["II_ClientsCovered"].trim()) {
            formIsValid = false;
            errors["II_ClientsCovered"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["II_ClientsCovered"]);
            if (!isValid) {
                formIsValid = false;
                errors["II_ClientsCovered"] = "Value contains Special characters";
            }
        }
        if (!kpiData["II_BusinessOutcomesDelivered"].trim()) {
            formIsValid = false;
            errors["II_BusinessOutcomesDelivered"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["II_BusinessOutcomesDelivered"]);
            if (!isValid) {
                formIsValid = false;
                errors["II_BusinessOutcomesDelivered"] = "Value contains Special characters";
            }
        }
        if (!kpiData["DD_InternalBenchmarksDeveloped"].trim()) {
            formIsValid = false;
            errors["DD_InternalBenchmarksDeveloped"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["DD_InternalBenchmarksDeveloped"]);
            if (!isValid) {
                formIsValid = false;
                errors["DD_InternalBenchmarksDeveloped"] = "Value contains Special characters";
            }
        }
        if (!kpiData["DD_DataPointersToTrainAIEngines"].trim()) {
            formIsValid = false;
            errors["DD_DataPointersToTrainAIEngines"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["DD_DataPointersToTrainAIEngines"]);
            if (!isValid) {
                formIsValid = false;
                errors["DD_DataPointersToTrainAIEngines"] = "Value contains Special characters";
            }
        }
        if (!kpiData["DD_ExternalBenchmarksTracked"].trim()) {
            formIsValid = false;
            errors["DD_ExternalBenchmarksTracked"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["DD_ExternalBenchmarksTracked"]);
            if (!isValid) {
                formIsValid = false;
                errors["DD_ExternalBenchmarksTracked"] = "Value contains Special characters";
            }
        }
        if (!kpiData["DD_ExpertsAugmentedwithAppliedIntelligence"].trim()) {
            formIsValid = false;
            errors["DD_ExpertsAugmentedwithAppliedIntelligence"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["DD_ExpertsAugmentedwithAppliedIntelligence"]);
            if (!isValid) {
                formIsValid = false;
                errors["DD_ExpertsAugmentedwithAppliedIntelligence"] = "Value contains Special characters";
            }
        }
        if (!kpiData["TF_Training_R2TNCompletiond"].trim()) {
            formIsValid = false;
            errors["TF_Training_R2TNCompletiond"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["TF_Training_R2TNCompletiond"]);
            if (!isValid) {
                formIsValid = false;
                errors["TF_Training_R2TNCompletiond"] = "Value contains Special characters";
            }
        }
        if (!kpiData["TF_Training_TFTrained"].trim()) {
            formIsValid = false;
            errors["TF_Training_TFTrained"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["TF_Training_TFTrained"]);
            if (!isValid) {
                formIsValid = false;
                errors["TF_Training_TFTrained"] = "Value contains Special characters";
            }
        }
        if (!kpiData["TF_Training_TrainingHours"].trim()) {
            formIsValid = false;
            errors["TF_Training_TrainingHours"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["TF_Training_TrainingHours"]);
            if (!isValid) {
                formIsValid = false;
                errors["TF_Training_TrainingHours"] = "Value contains Special characters";
            }
        }
        if (!kpiData["TF_NicheSkills_Analytics"].trim()) {
            formIsValid = false;
            errors["TF_NicheSkills_Analytics"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["TF_NicheSkills_Analytics"]);
            if (!isValid) {
                formIsValid = false;
                errors["TF_NicheSkills_Analytics"] = "Value contains Special characters";
            }
        }
        if (!kpiData["TF_NicheSkills_BusinessExcellence"].trim()) {
            formIsValid = false;
            errors["TF_NicheSkills_BusinessExcellence"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["TF_NicheSkills_BusinessExcellence"]);
            if (!isValid) {
                formIsValid = false;
                errors["TF_NicheSkills_BusinessExcellence"] = "Value contains Special characters";
            }
        }
        if (!kpiData["TF_NicheSkills_OverallBusinessAdvisors"].trim()) {
            formIsValid = false;
            errors["TF_NicheSkills_OverallBusinessAdvisors"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["TF_NicheSkills_OverallBusinessAdvisors"]);
            if (!isValid) {
                formIsValid = false;
                errors["TF_NicheSkills_OverallBusinessAdvisors"] = "Value contains Special characters";
            }
        }
        if (!kpiData["TF_NicheSkills_ArtificialIntelligence"].trim()) {
            formIsValid = false;
            errors["TF_NicheSkills_ArtificialIntelligence"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["TF_NicheSkills_ArtificialIntelligence"]);
            if (!isValid) {
                formIsValid = false;
                errors["TF_NicheSkills_ArtificialIntelligence"] = "Value contains Special characters";
            }
        }
        if (!kpiData["TF_NicheSkills_IntelligentAutomation"].trim()) {
            formIsValid = false;
            errors["TF_NicheSkills_IntelligentAutomation"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["TF_NicheSkills_IntelligentAutomation"]);
            if (!isValid) {
                formIsValid = false;
                errors["TF_NicheSkills_IntelligentAutomation"] = "Value contains Special characters";
            }
        }
        if (!kpiData["TF_NicheSkills_BusinessAdvisorforFA"].trim()) {
            formIsValid = false;
            errors["TF_NicheSkills_BusinessAdvisorforFA"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["TF_NicheSkills_BusinessAdvisorforFA"]);
            if (!isValid) {
                formIsValid = false;
                errors["TF_NicheSkills_BusinessAdvisorforFA"] = "Value contains Special characters";
            }
        }
        if (!kpiData["TF_NicheSkills_IndustryCertifiedResources"].trim()) {
            formIsValid = false;
            errors["TF_NicheSkills_IndustryCertifiedResources"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["TF_NicheSkills_IndustryCertifiedResources"]);
            if (!isValid) {
                formIsValid = false;
                errors["TF_NicheSkills_IndustryCertifiedResources"] = "Value contains Special characters";
            }
        }
        if (!kpiData["TF_NicheSkills_TechMasters"].trim()) {
            formIsValid = false;
            errors["TF_NicheSkills_TechMasters"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["TF_NicheSkills_TechMasters"]);
            if (!isValid) {
                formIsValid = false;
                errors["TF_NicheSkills_TechMasters"] = "Value contains Special characters";
            }
        }
        if (!kpiData["TF_NicheSkills_BusinessAdvisorforProcurement"].trim()) {
            formIsValid = false;
            errors["TF_NicheSkills_BusinessAdvisorforProcurement"] = "Value is required";

        }
        else {
            var isValid = regexForGOCFields.test(kpiData["TF_NicheSkills_BusinessAdvisorforProcurement"]);
            if (!isValid) {
                formIsValid = false;
                errors["TF_NicheSkills_BusinessAdvisorforProcurement"] = "Value contains Special characters";
            }
        }



        this.setState({
            errors: errors
        });
        if (formIsValid) {
            this.setState({
                formIsValid: true
            })
        }
        else {
            this.setState({
                formIsValid: false
            })
        }
        return formIsValid;


    }

    



    render() {

        return (
            <div className="main-component">
                <div className="container-fluid gocForm">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="main-data mt-0 mb-3">
                                <h3 className="grid-heading bg-heading">Intelligent Operations Journey</h3>
                                <div className="row">
                                    <div className="form-group col-lg-4 block-picker">
                                        <label className="datepicker-label" for="">Start Date<span className="required">*</span></label>
                                        {/* <input type="text" className="form-control" id="" onChange={this.onStartDateChange}
                                                            value={this.state.data.PeriodDT} /> */}
                                        <DatePicker className="form-control" selected={this.state.data.startDate} id="StartDate"
                                            onChange={this.onStartDateChange.bind(this)} dateFormat="MM/yyyy" showMonthYearPicker
                                            id="StartDate" />
                                        <span className="errorMsg">{this.state.errors.PeriodDT}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="main-data mt-2 mb-3">
                                <h3 className="grid-heading bg-heading">Current View</h3>
                                <div className="row">
                                    <div className="form-group col-lg-4">
                                        <label for="">Foundation<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onIOJFoundationChange}
                                            value={this.state.data.kpiData.IOJ_Foundation === "" ? "" :
                                                this.state.data.kpiData.IOJ_Foundation} />
                                        <span className="errorMsg">{this.state.errors.IOJ_Foundation}</span>
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label for="">Automated<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onIOJAutomatedChange}
                                            value={this.state.data.kpiData.IOJ_Automated} />
                                        <span className="errorMsg">{this.state.errors.IOJ_Automated}</span>
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label for="">Insights<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onIOJInslightsChange}
                                            value={this.state.data.kpiData.IOJ_Inslights} />
                                        <div className="errorMsg">{this.state.errors.IOJ_Inslights}</div>
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label for="">Intelligent<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onIOJIntelligentChange}
                                            value={this.state.data.kpiData.IOJ_Intelligent} />
                                        <div className="errorMsg">{this.state.errors.IOJ_Intelligent}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-data mt-2 mb-3">
                                <h3 className="grid-heading bg-heading">Year End View</h3>
                                <div className="row">
                                    <div className="form-group col-lg-4">
                                        <label for="">Foundation<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onIOTFoundationChange}
                                            value={this.state.data.kpiData.IOT_Foundation} />
                                        <div className="errorMsg">{this.state.errors.IOT_Foundation}</div>
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label for="">Automated<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onIOTAutomatedChange}
                                            value={this.state.data.kpiData.IOT_Automated} />
                                        <div className="errorMsg">{this.state.errors.IOT_Automated}</div>
                                    </div>

                                    <div className="form-group col-lg-4">
                                        <label for="">Insights<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onIOTInslightsChange}
                                            value={this.state.data.kpiData.IOT_Inslights} />
                                        <div className="errorMsg">{this.state.errors.IOT_Inslights}</div>
                                    </div>

                                    <div className="form-group col-lg-4">
                                        <label for="">Intelligent<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onIOTIntelligentChange}
                                            value={this.state.data.kpiData.IOT_Intelligent} />
                                        <div className="errorMsg">{this.state.errors.IOT_Intelligent}</div>
                                    </div>

                                </div>
                            </div>

                            <div className="main-data mt-2 mb-3">

                                <h3 className="grid-heading bg-heading">Business Outcomes</h3>
                                <div className="row">
                                    <div className="form-group col-lg-4">
                                        <label for="">TotalBussinessValue<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onBOTotalBussinessValueChange}
                                            value={this.state.data.kpiData.BO_TotalBussinessValue} />
                                        <div className="errorMsg">{this.state.errors.BO_TotalBussinessValue}</div>
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label for="">YTD<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onBOYTDedChange} value={this.state.data.kpiData.BO_YTD} />
                                        <div className="errorMsg">{this.state.errors.BO_YTD}</div>
                                    </div>

                                    <div className="form-group col-lg-4">
                                        <label for="">DeliveredProjects<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onBODeliveredProjectsChange}
                                            value={this.state.data.kpiData.BO_DeliveredProjects} />
                                        <div className="errorMsg">{this.state.errors.BO_DeliveredProjects}</div>
                                    </div>

                                    <div className="form-group col-lg-4">
                                        <label for="">ActiveProjects<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onBOActiveProjectsChange}
                                            value={this.state.data.kpiData.BO_ActiveProjects} />
                                        <div className="errorMsg">{this.state.errors.BO_ActiveProjects}</div>
                                    </div>
                                        <div className="form-group col-lg-4">
                                            <label for="">Clients<span className="required">*</span></label>
                                            <input type="text" maxLength="50" className="form-control" id=""
                                                onChange={this.onBOClientsChange} value={this.state.data.kpiData.BO_Clients} />
                                            <div className="errorMsg">{this.state.errors.BO_Clients}</div>
                                        </div>
                                    </div>
                            </div>



                            <div className="main-data mt-2 mb-3">
                                <h3 className="grid-heading bg-heading">SynOps Applied Now</h3>

                                <h3 className="grid-heading">Human + Machine Talent</h3>
                                <div className="row">
                                    <div className="form-group col-lg-4">
                                        <label for=""># of Automations Deployed<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onHMTAutomationsDeployedChange}
                                            value={this.state.data.kpiData.HMT_AutomationsDeployed} />
                                        <div className="errorMsg">{this.state.errors.HMT_AutomationsDeployed}</div>
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label for=""># of Foundation BOTs<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onHMTFoundationBOTsChange}
                                            value={this.state.data.kpiData.HMT_FoundationBOTs} />
                                        <div className="errorMsg">{this.state.errors.HMT_FoundationBOTs}</div>
                                    </div>

                                    <div className="form-group col-lg-4">
                                        <label for=""># of RPA BOTs<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onHMTRPABOTsChange} value={this.state.data.kpiData.HMT_RPABOTs} />
                                        <div className="errorMsg">{this.state.errors.HMT_RPABOTs}</div>
                                    </div>

                                    <div className="form-group col-lg-4">
                                        <label for=""># of GPH Users<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onHMTGPHUsersChange} value={this.state.data.kpiData.HMT_GPHUsers} />
                                        <div className="errorMsg">{this.state.errors.HMT_GPHUsers}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="main-data mt-2 mb-3">
                                <h3 className="grid-heading bg-heading">Work Orchestration</h3>
                                <div className="row">
                                    <div className="form-group col-lg-4">
                                        <label for=""># of ACN Applications<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onWOACNApplicationsChange}
                                            value={this.state.data.kpiData.WO_ACNApplications} />
                                        <div className="errorMsg">{this.state.errors.WO_ACNApplications}</div>
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label for=""># of Tickets Processed (per month)<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onWOTicketsProcessedPermonthChange}
                                            value={this.state.data.kpiData.WO_TicketsProcessed_Permonth} />
                                        <div className="errorMsg">{this.state.errors.WO_TicketsProcessed_Permonth}</div>
                                    </div>

                                    <div className="form-group col-lg-4">
                                        <label for=""># of Documents Scanned (per month)<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onWODocumentsScannedPermonthChange}
                                            value={this.state.data.kpiData.WO_DocumentsScanned_Permonth} />
                                        <div className="errorMsg">{this.state.errors.WO_DocumentsScanned_Permonth}</div>
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label for=""># of Trxn Processed by Automations (per month)<span
                                            className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onWOTransactionsProcessedbyAutomationsPermonthChange}
                                            value={this.state.data.kpiData.WO_TransactionsProcessedbyAutomations_Permonth} />
                                        <div className="errorMsg">{this.state.errors.WO_TransactionsProcessedbyAutomations_Permonth}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="main-data mt-2 mb-3">
                                <h3 className="grid-heading bg-heading">Insights &amp; Intelligence</h3>
                                <div className="row">
                                    <div className="form-group col-lg-4">
                                        <label for=""># of AI Advisor<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onIIAIAdvisorChange} value={this.state.data.kpiData.II_AIAdvisor} />
                                        <div className="errorMsg">{this.state.errors.II_AIAdvisor}</div>
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label for=""># of Analytics Apps<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onIIAnalyticsAppsChange}
                                            value={this.state.data.kpiData.II_AnalyticsApps} />
                                        <div className="errorMsg">{this.state.errors.II_AnalyticsApps}</div>
                                    </div>

                                    <div className="form-group col-lg-4">
                                        <label for=""># of Clients Covered<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onIIClientsCoveredChange}
                                            value={this.state.data.kpiData.II_ClientsCovered} />
                                        <div className="errorMsg">{this.state.errors.II_ClientsCovered}</div>
                                    </div>

                                    <div className="form-group col-lg-4">
                                        <label for="">Business Outcomes Delivered<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onIIBusinessOutcomesDeliveredChange}
                                            value={this.state.data.kpiData.II_BusinessOutcomesDelivered} />
                                        <div className="errorMsg">{this.state.errors.II_BusinessOutcomesDelivered}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="main-data mt-2 mb-3">
                                <h3 className="grid-heading bg-heading">Diverse Data</h3>
                                <div className="row">
                                    <div className="form-group col-lg-4">
                                        <label for=""># of Internal Benchmarks Developed<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onDDInternalBenchmarksDevelopedChange}
                                            value={this.state.data.kpiData.DD_InternalBenchmarksDeveloped} />
                                        <div className="errorMsg">{this.state.errors.DD_InternalBenchmarksDeveloped}</div>
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label for=""># of Data Pointers To Train AI Engines<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onDDDataPointersToTrainAIEnginesChange}
                                            value={this.state.data.kpiData.DD_DataPointersToTrainAIEngines} />
                                        <div className="errorMsg">{this.state.errors.DD_DataPointersToTrainAIEngines}</div>
                                    </div>

                                    <div className="form-group col-lg-4">
                                        <label for=""># of External Benchmarks Tracked<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onDDExternalBenchmarksTrackedChange}
                                            value={this.state.data.kpiData.DD_ExternalBenchmarksTracked} />
                                        <div className="errorMsg">{this.state.errors.DD_ExternalBenchmarksTracked}</div>
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label for=""># of experts augmented with Applied Intelligence<span
                                            className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onDDExpertsAugmentedwithAppliedIntelligenceChange}
                                            value={this.state.data.kpiData.DD_ExpertsAugmentedwithAppliedIntelligence} />
                                        <div className="errorMsg">{this.state.errors.DD_ExpertsAugmentedwithAppliedIntelligence}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="main-data mt-2 mb-3">
                                <h3 className="grid-heading bg-heading">Talent of the Future</h3>
                                <h3 className="grid-heading">Training</h3>
                                <div className="row">
                                    <div className="form-group col-lg-4">
                                        <label for="">% of R2TN Completion<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onTFTrainingR2TNCompletiondChange}
                                            value={this.state.data.kpiData.TF_Training_R2TNCompletiond} />
                                        <div className="errorMsg">{this.state.errors.TF_Training_R2TNCompletiond}</div>
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label for="">% of Talent of the Future Trained<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onTFTrainingTFTrainedChange}
                                            value={this.state.data.kpiData.TF_Training_TFTrained} />
                                        <div className="errorMsg">{this.state.errors.TF_Training_TFTrained}</div>
                                    </div>

                                    <div className="form-group col-lg-4">
                                        <label for="">Training Hours<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onTFTrainingTrainingHoursChange}
                                            value={this.state.data.kpiData.TF_Training_TrainingHours} />
                                        <div className="errorMsg">{this.state.errors.TF_Training_TrainingHours}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="main-data mt-2 mb-3">
                                <h3 className="grid-heading bg-heading">Niche Skills</h3>
                                <div className="row">
                                    <div className="form-group col-lg-4">
                                        <label for=""># of Analytics<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onTFNicheSkillsAnalyticsChange}
                                            value={this.state.data.kpiData.TF_NicheSkills_Analytics} />
                                        <div className="errorMsg">{this.state.errors.TF_NicheSkills_Analytics}</div>
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label for=""># of Artificial Intelligence<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onTFNicheSkillsArtificialIntelligenceChange}
                                            value={this.state.data.kpiData.TF_NicheSkills_ArtificialIntelligence} />
                                        <div className="errorMsg">{this.state.errors.TF_NicheSkills_ArtificialIntelligence}
                                        </div>
                                    </div>

                                    <div className="form-group col-lg-4">
                                        <label for=""># of Industry Certified Resources<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onTFNicheSkillsIndustryCertifiedResourcesChange}
                                            value={this.state.data.kpiData.TF_NicheSkills_IndustryCertifiedResources} />
                                    </div>
                                    <div className="errorMsg">{this.state.errors.TF_NicheSkills_IndustryCertifiedResources}
                                    </div>
                                <div className="form-group col-lg-4">
                                    <label for=""># of Business Excellence<span className="required">*</span></label>
                                    <input type="text" maxLength="50" className="form-control" id=""
                                        onChange={this.onTFNicheSkillsBusinessExcellenceChange}
                                        value={this.state.data.kpiData.TF_NicheSkills_BusinessExcellence} />
                                    <div className="errorMsg">{this.state.errors.TF_NicheSkills_BusinessExcellence}</div>
                                </div>
                                    <div className="form-group col-lg-4">
                                        <label for=""># of Intelligent Automation<span className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onTFNicheSkillsIntelligentAutomationChange}
                                            value={this.state.data.kpiData.TF_NicheSkills_IntelligentAutomation} />
                                        <div className="errorMsg">{this.state.errors.TF_NicheSkills_IntelligentAutomation}</div>
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label for=""># of Business Advisor for Procurement<span
                                            className="required">*</span></label>
                                        <input type="text" maxLength="50" className="form-control" id=""
                                            onChange={this.onTFNicheSkillsBusinessAdvisorforProcurementChange}
                                            value={this.state.data.kpiData.TF_NicheSkills_BusinessAdvisorforProcurement} />
                                    <div className="errorMsg">
                                        {this.state.errors.TF_NicheSkills_BusinessAdvisorforProcurement}</div>
                                </div>
                                <div className="form-group col-lg-4">
                                    <label for=""># of Tech Masters<span className="required">*</span></label>
                                    <input type="text" maxLength="50" className="form-control" id=""
                                        onChange={this.onTFNicheSkillsTechMastersChange}
                                        value={this.state.data.kpiData.TF_NicheSkills_TechMasters} />
                                    <div className="errorMsg">{this.state.errors.TF_NicheSkills_TechMasters}</div>
                                </div>
                                <div className="form-group col-lg-4">
                                    <label for=""># of Overall Business Advisors<span className="required">*</span></label>
                                    <input type="text" maxLength="50" className="form-control" id=""
                                        onChange={this.onTFNicheSkillsOverallBusinessAdvisorsChange}
                                        value={this.state.data.kpiData.TF_NicheSkills_OverallBusinessAdvisors} />
                                    <div className="errorMsg">{this.state.errors.TF_NicheSkills_OverallBusinessAdvisors}
                                    </div>
                                </div>
                                        <div className="form-group col-lg-4">
                                            <label for=""># of Business Advisor for FandA<span className="required">*</span></label>
                                            <input type="text" maxLength="50" className="form-control" id=""
                                                onChange={this.onTFNicheSkillsBusinessAdvisorforFAChange}
                                                value={this.state.data.kpiData.TF_NicheSkills_BusinessAdvisorforFA} />
                                            <div className="errorMsg">{this.state.errors.TF_NicheSkills_BusinessAdvisorforFA}</div>
                                        </div>
                            </div>
                                </div>


                            <div className="mt-3">
                                        {this.state.data.kpiData.GOCID === 0 || this.state.data.kpiData.GOCID === "undefined" ?
                                            this.state.featureData.filter((data) => data.FeatureName == "GOC Form" &&
                                                data.Operations.includes("Add")).length > 0
                                                ? <input type="Button" className="btn btn-primary btn-sm" onClick={() =>
                                                    this.handleSave(this.state.data.kpiData)} value="Create" />
                                                : null
                                            : this.state.featureData.filter((data) => data.FeatureName == "GOC Form" &&
                                                data.Operations.includes("Edit")).length > 0
                                                ? <input type="Button" className="btn btn-primary btn-sm" onClick={() =>
                                                    this.handleSave(this.state.data.kpiData)} value="Update" />
                                                : null
                                        }

                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}