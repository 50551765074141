import React from "react";
import { Modal, Button } from "react-bootstrap";
import i18n from "i18next";

import axiosInstance from "../Shared/interceptor";

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class MORMasterPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      apivalue: "",
      selectValue: this.props.SelectValue,
      Display: false,
      MORData: {
        ID: 0,
        Name: "",
        Description: "",
        IsActive: false,
        Sequence: "",
        SequenceForHelpSection: ""
      },
      errors: [],
      formIsValid: false,
      title: "",
      filterDetails: {},
      rowData: [],
    };

    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
  }

  onNameChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    let MORData = this.state.MORData;
    MORData.Name = event.target.value;
    this.setState({
      MORData: MORData,
    });
  }
  onDescriptionChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    let MORData = this.state.MORData;
    MORData.Description = event.target.value;
    this.setState({
      MORData: MORData,
    });
  }

  handleSave(e) {
    if (this.validateForm()) {
      let data = {};
      data.ID = this.state.MORData.ID;
      data.Name = this.state.MORData.Name;
      data.Description = this.state.MORData.Description;
      data.CategoryName = this.state.filterDetails.value;
      data.SequenceNumber = this.state.MORData.IsActive ? parseInt(this.state.MORData.Sequence) : 0;
      data.IsActive = this.state.MORData.IsActive;
      this.props.onSave(data);

      this.handleHide();



    }
  }
  handleHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
        // this.props.onClose("from x");
      }
    } else {
      this.props.onClose(e);
    }
    this.props.onSave();
  }
  handleRejectHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }
  passToConfirmation(data) {
    this.props.enteredData(data.PoNumber);
  }

  componentDidMount() {
    this.setState({
      show: this.props.show,
      filterDetails: { ...this.props.filterDetails },
      rowData: [...this.props.rowData],
    });
  }

  validateForm() {
    const MORData = this.state.MORData;
    let errors = {};
    let formIsValid = true;

    if (!MORData["Name"].trim()) {
      formIsValid = false;
      errors["Name"] = "Name is required";
    }


    let rowData = [...this.state.rowData];
    rowData = rowData.map(each => {
      if (each.ID == parseInt(MORData["ID"])) {
        return { ...MORData };
      }
      else {
        return each
      }
    })
    if (MORData["ID"] == 0 && MORData["IsActive"] == true) {
      rowData.push(MORData);
    }
    let countActive = rowData.filter(each => each.IsActive == true);
    if (MORData["Sequence"] && MORData["IsActive"] == true) {
      if (countActive.filter(each => parseInt(each.SequenceForHelpSection) == parseInt(MORData["Sequence"])).length >= 2) {
        formIsValid = false;
        errors["SequenceNumber"] = "Sequence already exist";
      }
      if (MORData["Sequence"] < 0) {
        formIsValid = false;
        errors["SequenceNumber"] = "Sequence cannot be Negative";
      }
      if (MORData["Sequence"] > 999999 || MORData["Sequence"] == 0) {
        formIsValid = false;
        errors["SequenceNumber"] = "Sequence Value must be in between 1 to 999999";
      }
      if (MORData["Sequence"] != null && MORData["Sequence"] != "" && parseFloat(MORData["Sequence"]) % 1 != 0) {
        formIsValid = false;
        errors["SequenceNumber"] = "Sequence Value must be an integer";
      }

    }
    if ((MORData["Sequence"] == "" || MORData["Sequence"] == null) && MORData["IsActive"] == true) {
      formIsValid = false;
      errors["SequenceNumber"] = "Sequence is required";
    }


    this.setState({
      errors: errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  componentWillMount() {
    if (this.props.data) {
      this.setState({
        MORData: { ...this.props.data, Sequence: this.props.data.SequenceForHelpSection },
      });
    } else {
      const MORData = this.state.MORData;
      this.setState({
        MORData: MORData,
        show: this.props.show,
      });
    }
  }
  handleSequence = (e) => {
    let { MORData } = this.state;
    MORData.Sequence = e.target.value;
    MORData.SequenceForHelpSection = e.target.value
    this.setState({
      MORData
    })
  }
  onStatusChange = () => {
    let { MORData } = this.state;
    MORData.IsActive = !MORData.IsActive;
    this.setState({
      MORData
    })
  }

  render() {
    const { filterDetails } = this.state;
    return (
      <>
        {/* <Header /> */}
        <Modal
          show={this.state.show}
          onHide={this.handleHide.bind(this)}
          className="modal fade"
          size="medium"
          id="dealpopup"
          backdrop="static"
        >
          <Modal.Header closeButton>
            {!this.props.data ? (
              <h4 className="modal-title" id="dealprofile">
                Add {filterDetails.Name}
              </h4>
            ) : (
              <h4 className="modal-title" id="dealprofile">
                Edit {filterDetails.Name}
              </h4>
            )}
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label for="">
                      Name<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      maxLength="50"
                      className="form-control"
                      id="name"
                      onChange={this.onNameChange}
                      value={this.state.MORData.Name}
                    />
                    <span className="errorMsg">{this.state.errors.Name}</span>
                  </div>
                  <div className="form-group col-lg-6">
                    <label for="">
                      Description
                    </label>
                    <input
                      type="text"
                      maxLength="100"
                      className="form-control"
                      id="name"
                      onChange={this.onDescriptionChange}
                      value={this.state.MORData.Description}
                    />
                    <span className="errorMsg">{this.state.errors.Description}</span>
                  </div>
                </div>
              </div>

              <div className="form-section">
                <div className="row">
                  {this.state.MORData.IsActive && <div className="form-group col-lg-6">
                    <label for="">
                      {i18n.t("Sequence")} <span className="required">*</span>
                    </label>
                    <input
                      id="ButtonSequence"
                      type="number"
                      min="1"
                      maxlength="50"
                      className="form-control"
                      onChange={(e) => { this.handleSequence(e) }}
                      value={this.state.MORData.Sequence}
                      disabled={!this.state.MORData.IsActive}
                    />
                    <span className="errorMsg">{this.state.errors.SequenceNumber}</span>
                  </div>}

                  <div className="form-group col-lg-6">
                    <label className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={this.onStatusChange}
                        defaultChecked={this.state.MORData.IsActive}
                      />
                      <span className="checkmark"></span>
                      <span className="form-check-label">Is Active</span>
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">
                {!this.props.data ? (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave()}
                    value="Create"
                  />
                ) : (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave()}
                    value="Update"
                  />
                )}
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}
