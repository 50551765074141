import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js';
import { connect } from 'react-redux';
import {
  Tabs, Tab, TabPanel, TabList,
} from 'react-web-tabs';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import i18n from 'i18next';
import LoadingOverlay from 'react-loading-overlay';
import _ from 'lodash';
import { LocalApiBaseUrl, trycatchAlertPopup } from '../Shared/Constant';
import 'chartjs-plugin-datalabels';
import { store } from '../reducers/configureStore';
import axiosInstance from '../Shared/interceptor';

import MetricDeepDive from './MetricDeepDive';
import InsightsChatWindow from './InsightsChatWindow';
import HideChat from '../Images/hide-chat.svg';
import ShowChat from '../Images/show-chat.svg';
import BaselineIcon from '../Images/Baseline-Border.svg';
import TargetIcon from '../Images/Target-Border.svg';
import SynopsLoader from '../Shared/SynopsLoader';

import { forNum } from '../Shared/Common';
import { dateFormatMethod2 } from '../Shared/commonFunctions';
import MetricValueIcon from '../Images/MetricValue-Border.svg';
import LowerQuartileIcon from '../Images/LowerQuartile-Border.svg';
import UpperQuartileIcon from '../Images/UpperQuartile-Border.svg';
import BoiTrendsWrapper from './BoiTrendsWrapper';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('msal.idtoken')}`;


class TrendGraphsDiverse extends Component {
  constructor(props) {
    super(props);
    const { show } = this.props;
    this.state = {
      show,
      dsKpiData: [],
      showComments: true,
      isApiLoading: false,
      temp: [],
      BaselineMean: [],
      TargetMean: [],
      strikedLegentData: [],
      isActualOn: true,
      isSimilarIndustryOn: true,
      isLeadingOn: true,
      isBaselineOn: true,
      isTargetOn: true,
      backgroundColorValues: [''],
      baselineMonthValue: [],
      metricBorderColorValues: [''],
      newNotificationList: [],
      fullmonthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    };

    this.handleHide = this.handleHide.bind(this);
    this.onShowHideButtonClick = this.onShowHideButtonClick.bind(this);
    this.getKpiData = this.getKpiData.bind(this);
    this.onLegendClick = this.onLegendClick.bind(this);
    this.ActualClick = this.ActualClick.bind(this);
    this.SimilarIndustryClick = this.SimilarIndustryClick.bind(this);
    this.LeadingClick = this.LeadingClick.bind(this);
    this.BaselineClick = this.BaselineClick.bind(this);
    this.TargetClick = this.TargetClick.bind(this);
    this.GetNotifications = this.GetNotifications.bind(this);
  }

  componentDidMount() {
    this.getKpiData();
    store.dispatch({
      type: 'SYNC_COMMENTFILTER',
      payload: null,
    });
  }

  componentDidUpdate(prevProps) {
    const { dsKpiData, temp } = this.state;
    const { SelectedCommentMonth, CommentMonthChange } = this.props;
    const backgroundColorValues = [''];
    if (SelectedCommentMonth !== prevProps.SelectedCommentMonth) {
      if (temp.length > 0 && dsKpiData.datasets.length > 0) {
        temp.forEach((each) => {
          if (each.PeriodDt === SelectedCommentMonth) {
            backgroundColorValues.push('rgb(0, 186, 255)');
          } else {
            backgroundColorValues.push('white');
          }
        });
        const { labels } = dsKpiData;
        const colorChange = dsKpiData.datasets;
        const metricColor = colorChange[0];
        metricColor.pointBackgroundColor = backgroundColorValues;
        colorChange[0] = metricColor;
        this.setState({
          dsKpiData: {
            labels,
            datasets: colorChange,
          },
          backgroundColorValues,
          isActualOn: !!CommentMonthChange,
          isSimilarIndustryOn: !!CommentMonthChange,
          isLeadingOn: !!CommentMonthChange,
          isBaselineOn: !!CommentMonthChange,
          isTargetOn: !!CommentMonthChange,
          strikedLegentData: [],
        });
      }
    }
  }

  handleHide(e) {
    const { onClose } = this.props;
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (String(e.target.textContent) !== String('×')) {
        onClose(e);
      } else {
        onClose(e);
      }
    } else {
      onClose(e);
    }
  }

  getKpiData() {
    const {
      kpiMappingId,
      info,
      Details,
      CommentMonthChange,
    } = this.props;
    const { metricBorderColorValues, backgroundColorValues, fullmonthNames } = this.state;
    let UpperThresholdMean = '';
    let LowerThresholdMean = '';
    const UpperThresholdMeanArray = [];
    const LowerThresholdMeanArray = [];
    let baselineMean = '';
    let targetMean = '';
    const baselineMeanArray = [];
    const targetMeanArray = [];
    this.setState({ isApiLoading: true });
    const languageId = store.getState().CurrentFilter.languageData.id;
    axiosInstance.get(`${LocalApiBaseUrl}Analytics/GetKpiData`, {
      params: { kpiMappingID: kpiMappingId, LanguageID: languageId },
    })
      .then((res) => {
        const MontnDt = [''];
        const ValueDt = [];
        // const SimilarValue = [];       // unused variable
        // const LeadingValue = [];       // unused variable
        const baselineMonthValue = [];
        res.data.forEach((element) => {
          MontnDt.push(element.PeriodDt);
          ValueDt.push(element.MetricValue);
          baselineMonthValue.push(element.BaseLineMonth);
        });

        const monthNames = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        if (res.data.length > 0) {
          res.data.forEach((each, index) => {
            const objDate = new Date(each.PeriodDt);
            res.data[index].PeriodDt = `${monthNames[objDate.getMonth()]} 20${objDate.getDate()}`;
          });
        }
        if (baselineMonthValue.length > 0) {
          for (let i = 0; i < baselineMonthValue.length; i += 1) {
            if (baselineMonthValue[i] != null && baselineMonthValue[i] !== '') {
              const objDate = new Date(baselineMonthValue[i]);
              baselineMonthValue[i] = `${fullmonthNames[objDate.getMonth()]} ${objDate.getFullYear()}`;
            }
          }
        }

        for (let i = res.data.length - 1; i >= 0; i -= 1) {
          if (
            (res.data[i].BaselineValue !== null && res.data[i].BaselineValue.trim() !== '')
            || (res.data[i].TargetValue !== null && res.data[i].TargetValue.trim() !== '')
          ) {
            baselineMean = res.data[i].BaselineValue;
            targetMean = res.data[i].TargetValue;

            break;
          }
        }
        UpperThresholdMean = info.metDataAttr[1].AttributeValue;
        LowerThresholdMean = info.metDataAttr[0].AttributeValue;

        res.data.forEach(() => {
          UpperThresholdMeanArray.push(parseFloat(UpperThresholdMean));
          LowerThresholdMeanArray.push(parseFloat(LowerThresholdMean));
          baselineMeanArray.push(parseFloat(baselineMean));
          targetMeanArray.push(parseFloat(targetMean));
        });
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 450);
        gradient.addColorStop(0, 'rgba(0, 186, 255,0.2)');
        // gradient.addColorStop(0.5, '#20f08b');
        gradient.addColorStop(1, 'rgba(255, 255, 255,0)');
        let label1;
        //  ?  : ( ?  : )
        if (Details.length) {
          label1 = Details[0].AttributeName;
        } else if (store.getState().CurrentFilter.Filter.offeringName === 'Procurement') {
          label1 = i18n.t('Lower_Threshold');
        } else {
          label1 = i18n.t('Similar');
        }
        let label2;
        if (Details.length > 1) {
          label2 = Details[1].AttributeName;
        } else if (store.getState().CurrentFilter.Filter.offeringName === 'Procurement') {
          label2 = i18n.t('Upper_Threshold');
        } else {
          label2 = i18n.t('Leading');
        }
        this.setState({
          baselineMonthValue,
          temp: res.data,
          isApiLoading: false,
          BaselineMean: baselineMean,
          TargetMean: targetMean,
          isActualOn: !!CommentMonthChange,
          isSimilarIndustryOn: !!CommentMonthChange,
          isLeadingOn: !!CommentMonthChange,
          isBaselineOn: !!CommentMonthChange,
          isTargetOn: !!CommentMonthChange,
          strikedLegentData: [],
          dsKpiData: {
            labels: MontnDt,
            datasets: [
              {
                label: i18n.t('Actual'),
                data: [null, ...ValueDt],
                fill: true,
                lineTension: 0,
                backgroundColor: gradient,
                borderColor: 'rgb(0, 186, 255)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: metricBorderColorValues,
                pointBackgroundColor: backgroundColorValues,
                pointStyle: 'circle',
                pointBorderWidth: 2,
                pointHoverRadius: 8,
                pointHoverBackgroundColor: 'white',
                pointHoverBorderColor: 'rgb(0, 186, 255)',
                pointHoverBorderWidth: 2,
                pointRadius: 6,
                pointHitRadius: 20,
                datalabels: {
                  display: false,
                },
              },
              {
                label: label1,
                data: [LowerThresholdMean, ...LowerThresholdMeanArray],
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(126, 195, 68, 0.1)',
                borderColor: 'rgb(126, 195, 68)',
                borderCapStyle: 'butt',
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgb(126, 195, 68)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointStyle: 'circle',
                pointHoverRadius: 0,
                pointHoverBackgroundColor: 'rgb(255, 255, 255)',
                pointHoverBorderColor: 'rgb(126, 195, 68)',
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 0,
                borderWidth: 1.5,
                borderDash: [5, 3],
                datalabels: {
                  backgroundColor: 'rgb(126, 195, 68)',
                  borderColor: 'rgb(126, 195, 68)',
                },
              },
              {
                label: label2,
                data: [UpperThresholdMean, ...UpperThresholdMeanArray],
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(213, 0, 47, 0.1)',
                borderColor: 'rgb(213, 0, 47)',
                borderCapStyle: 'butt',
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgb(213, 0, 47)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointStyle: 'circle',
                pointHoverRadius: 0,
                pointHoverBackgroundColor: 'rgb(255, 255, 255)',
                pointHoverBorderColor: 'rgb(213, 0, 47)',
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 0,
                borderWidth: 1.5,
                borderDash: [5, 3],
                datalabels: {
                  backgroundColor: 'rgb(213, 0, 47)',
                  borderColor: 'rgb(213, 0, 47)',
                },
              },
              {
                label: i18n.t('Baseline'),
                data: [baselineMean, ...baselineMeanArray],
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(181, 192, 202, 0.1)',
                borderColor: 'rgb(181, 192, 202)', // "rgba(196, 205, 213, 0.9)",
                borderCapStyle: 'butt',
                borderDash: [5, 3],
                borderWidth: 1.5,
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgb(181, 192, 202)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1, // 1
                pointHoverRadius: 0, // 5
                pointHoverBackgroundColor: 'rgb(255, 255, 255)',
                pointHoverBorderColor: 'rgb(181, 192, 202)',
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 0,
                datalabels: {
                  backgroundColor: 'rgb(181, 192, 202)',
                  borderColor: 'rgb(181, 192, 202)',
                },
              },
              {
                label: i18n.t('Target'),
                data: [targetMean, ...targetMeanArray],
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(160, 85, 245, 0.1)',
                borderColor: 'rgb(160, 85, 245)', // "rgba(196, 205, 213, 0.9)",
                borderCapStyle: 'butt',
                borderDash: [5, 3],
                borderWidth: 1.5,
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgb(160, 85, 245)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1, // 1
                pointHoverRadius: 0, // 5
                pointHoverBackgroundColor: 'rgb(255, 255, 255)',
                pointHoverBorderColor: 'rgb(160, 85, 245)',
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 0,
                datalabels: {
                  backgroundColor: 'rgb(160, 85, 245)',
                  borderColor: 'rgb(160, 85, 245)',
                },
              },
            ],

          },
        },
          () => {
            this.GetNotifications();
          });
      })
      .catch((error) => {
        this.setState({ isApiLoading: false });
        trycatchAlertPopup(error);
      });
  }

  onShowHideButtonClick = () => {
    const { showComments, dsKpiData } = this.state;
    const dataset = dsKpiData.datasets;
    const label = dsKpiData.labels;
    this.setState({
      showComments: !showComments,
      dsKpiData: {
        labels: label,
        datasets: dataset,
      },
    });
  }

  onLegendClick = (data) => {
    const { dsKpiData } = this.state;
    const kpiData = dsKpiData.datasets;
    const { labels } = dsKpiData;
    let newkpiData = dsKpiData.datasets;
    const { strikedLegentData } = this.state;
    let strikedlegend = false;
    strikedLegentData.forEach((each) => {
      if (each.label === data) {
        strikedlegend = true;
      }
    });
    if (strikedlegend) {
      let index;
      strikedLegentData.forEach((each, ind) => {
        if (each.label === data) {
          newkpiData.push(each);
          index = ind;
        }
      });
      strikedLegentData[index] = [];
    } else {
      newkpiData = [];
      kpiData.forEach((each) => {
        if (each.label !== data) {
          newkpiData.push(each);
        } else {
          strikedLegentData.push(each);
        }
      });
    }
    this.setState({
      dsKpiData: {
        labels,
        datasets: newkpiData,
      },
      strikedLegentData,
    });
  }

  GetNotifications() {
    axiosInstance.get(`${LocalApiBaseUrl}Analytics/GetNotifications?IsRead=true&LanguageID=${parseInt(store.getState().CurrentFilter.languageData.id, 10)}`)
      .then((response) => {
        if (response.data) {
          response.data.forEach((each) => {
            const { dsKpiData } = this.state;
            const { kpiMappingId } = this.props;
            const borderColor = [];
            const date = dateFormatMethod2(each.PeriodDT).split(',')[0];
            const date2 = date.split(' ');
            const formatteddate = `${date2[1]}-${date2[2]}`;
            dsKpiData.labels.forEach((each1) => {
              if (each1 === formatteddate && sessionStorage.getItem('EnterpriseID') !== each.CreatedBy && kpiMappingId === each.KPIMappingID) {
                borderColor.push('rgb(220, 6, 6)');
              } else {
                borderColor.push('rgb(0, 186, 255)');
              }
            });
            const { labels } = dsKpiData;
            const colorChange = dsKpiData.datasets;
            const metricColor = colorChange[0];
            metricColor.pointBorderColor = borderColor;
            colorChange[0] = metricColor;
            this.setState({
              dsKpiData: {
                labels,
                datasets: colorChange,
              },
              metricBorderColorValues: borderColor,
            });
          });
        }
        this.setState({
          newNotificationList: response.data,
        });
      });
  }

  ActualClick() {
    this.setState((prevState) => ({
      isActualOn: !prevState.isActualOn,
    }));
  }

  SimilarIndustryClick() {
    this.setState((prevState) => ({
      isSimilarIndustryOn: !prevState.isSimilarIndustryOn,
    }));
  }

  LeadingClick() {
    this.setState((prevState) => ({
      isLeadingOn: !prevState.isLeadingOn,
    }));
  }

  BaselineClick() {
    this.setState((prevState) => ({
      isBaselineOn: !prevState.isBaselineOn,
    }));
  }

  TargetClick() {
    this.setState((prevState) => ({
      isTargetOn: !prevState.isTargetOn,
    }));
  }

  render() {
    const {
      info, kpiUnit, kpiName, dealFilter, kpiMappingId,
      ClientData,
      SyncFilter,
      roleData,
    } = this.props;
    const {
      show,
      dsKpiData,
      showComments,
      isApiLoading,
      isActualOn,
      newNotificationList,
      baselineMonthValue,
      isTargetOn,
      TargetMean,
      BaselineMean,
      isSimilarIndustryOn,
      isLeadingOn,
      temp,
      OfferingName,
      ProcessName,
      isBaselineOn,
    } = this.state;
    Chart.defaults.global.defaultFontColor = 'black';
    const metricID = info.MetricID;
    const isMultiLevel = info.IsMultiLevel;
    let ToolTip = '';
    ToolTip = `${this.props.SyncFilter.OfferingName} - ${this.props.SubOfferingName.SubOfferingName}`;
    const kpiMUnit = kpiUnit;

    return (
      <Modal
        show={show}
        onHide={this.handleHide}
        className="modal fade v-center trendGraph-comments"
        id="dealpopup"
        dialogClassName="trendGraphModal"
        size="XL"
        backdrop="static"
      >
        <Modal.Header closeButton className="trendGraph-title">
          <h4 className="modal-title" id="dealprofile">
            {i18n.t('Trend_Graph')}
            -
            {kpiName}
          </h4>
        </Modal.Header>
        <Modal.Body>

          <Tabs
            defaultTab="one"
          >
            <TabList>
              <Tab tabFor="one">
                <span data-tip={ToolTip}>{i18n.t('Trend_Graph')}</span>
              </Tab>
              <Tab tabFor="two">
                <span data-tip={ToolTip}>{i18n.t('Metric_Deep_Dive')}</span>
              </Tab>
              <Tab tabFor="DDTrends">
                <span data-tip={ToolTip}>{i18n.t('DD_Trends')}</span>
              </Tab>
            </TabList>

            <TabPanel tabId="one">
              {showComments
                ? (
                  <div className="showcomments-tab">
                    <img src={HideChat} alt="" className="mr-1" />
                    <span
                      onClick={this.onShowHideButtonClick}
                      aria-hidden="true"
                    >
                      {i18n.t('Hide Comments')}

                    </span>
                  </div>
                )
                : (
                  <div className="showcomments-tab">
                    <img src={ShowChat} alt="" className="mr-1" />
                    <span
                      onClick={this.onShowHideButtonClick}
                      aria-hidden="true"
                    >
                      {i18n.t('Show Comments')}

                    </span>
                  </div>
                )}
              {isApiLoading ? <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active /> : ''}
              <div className="linechart-commentry">
                <div className={showComments
                  ? 'linechart-division'
                  : 'linechart-division nocommentary-section'}
                >
                  <div className="custom-chartlegend">
                    <ul>
                      <li
                        onClick={() => {
                          this.onLegendClick(i18n.t('Actual'));
                          this.ActualClick();
                        }}
                        aria-hidden="true"
                      >
                        <span className="metric-legendvalue">
                          <img src={MetricValueIcon} alt="" />
                        </span>
                        {isActualOn ? i18n.t('Actual') : (<s className="legend-strike-through">{i18n.t('Actual')}</s>)}
                      </li>

                      {info.metDataAttr[1].AttributeValue === 'NA' ? null
                        : (
                          <li
                            onClick={() => {
                              this.onLegendClick(info.metDataAttr[1].AttributeName);
                              this.SimilarIndustryClick();
                            }}
                            aria-hidden="true"
                          >
                            <span className="lowerquartile-legendvalue crossed-line">
                              <img src={LowerQuartileIcon} alt="" />
                            </span>
                            {isSimilarIndustryOn ? info.metDataAttr[1].AttributeName : (<s className="legend-strike-through">{info.metDataAttr[1].AttributeName}</s>)}
                          </li>
                        )}

                      {info.metDataAttr[0].AttributeValue === 'NA' ? null
                        : (
                          <li
                            onClick={() => {
                              this.onLegendClick(info.metDataAttr[0].AttributeName);
                              this.LeadingClick();
                            }}
                            aria-hidden="true"
                          >
                            <span className="upperquartile-legendvalue crossed-line">
                              <img src={UpperQuartileIcon} alt="" />
                            </span>
                            {isLeadingOn ? info.metDataAttr[0].AttributeName : (<s className="legend-strike-through">{info.metDataAttr[0].AttributeName}</s>)}
                          </li>
                        )}

                      {BaselineMean && (
                        <li
                          onClick={() => {
                            this.onLegendClick(i18n.t('Baseline'));
                            this.BaselineClick();
                          }}
                          aria-hidden="true"
                        >
                          <span className="baseline-legendvalue crossed-line">
                            <img src={BaselineIcon} alt="" />
                          </span>
                          {isBaselineOn ? i18n.t('Baseline') : (<s className="legend-strike-through">{i18n.t('Baseline')}</s>)}
                        </li>
                      )}

                      {TargetMean && (
                        <li
                          onClick={() => {
                            this.onLegendClick(i18n.t('Target'));
                            this.TargetClick();
                          }}
                          aria-hidden="true"
                        >
                          <span className="target-legendvalue crossed-line">
                            <img src={TargetIcon} alt="" />
                          </span>
                          {isTargetOn ? i18n.t('Target') : (<s className="legend-strike-through">{i18n.t('Target')}</s>)}
                        </li>
                      )}
                    </ul>
                  </div>
                  {baselineMonthValue[0] && (
                    <h6 className="Baseline-month">
                      {i18n.t('Baseline_Month')}
                      :
                      {baselineMonthValue[0]}
                    </h6>
                  )}
                  <Line
                    data={dsKpiData}
                    options={{
                      maintainAspectRatio: false,
                      onClick: (e, element) => {
                        if (element.length > 0) {
                          const monthNames = [
                            'Jan',
                            'Feb',
                            'Mar',
                            'Apr',
                            'May',
                            'Jun',
                            'Jul',
                            'Aug',
                            'Sep',
                            'Oct',
                            'Nov',
                            'Dec',
                          ];
                          let monYear;
                          if (element.length > 0) {
                            const ind = element[0]._index;
                            const tempMonYear = dsKpiData.labels[ind];
                            const objDate = new Date(tempMonYear);
                            monYear = `${monthNames[objDate.getMonth()]} 20${objDate.getDate()}`;
                            store.dispatch({
                              type: 'SYNC_COMMENTFILTER',
                              payload: monYear,
                            });
                          }
                          const backgroundColorValues = [''];
                          temp.forEach((each) => {
                            if (each.PeriodDt === monYear) {
                              backgroundColorValues.push('rgb(0, 186, 255)');
                            } else {
                              backgroundColorValues.push('white');
                            }
                          });
                          this.setState({
                            backgroundColorValues,
                          });
                        }
                      },
                      legendCallback() {
                        const lengendHTML = `
                                        <div className="dd-chartlegend">
                                            <ul>
                                                <li>Actual</li>
                                            </ul>
                                        </div>`;
                        return lengendHTML;
                      },
                      layout: {
                        padding: {
                          top: -40,
                          left: 0,
                          right: 40,
                          bottom: 40,
                        },
                      },
                      scales: {
                        yAxes: [{
                          ticks: {
                            precision: 1,
                            fontColor: '#637381',
                            fontFamily: 'graphik',
                            fontSize: 14,
                            fontWeight: 400,
                            padding: 15,
                          },

                          gridLines: {
                            drawOnChartArea: true,
                          },
                          scaleLabel: {
                            display: true,
                            fontColor: '#637381',
                            fontFamily: 'Graphik-Medium',
                            fontSize: 12,
                            fontWeight: 500,
                            labelString: `${i18n.t('Value')}(${kpiMUnit})`,
                          },
                        }],
                        xAxes: [{
                          ticks: {
                            precision: 1,
                            fontColor: '#637381',
                            fontFamily: 'graphik',
                            fontSize: 14,
                            fontWeight: 400,
                          },
                          gridLines: {
                            drawOnChartArea: true,
                          },
                          scaleLabel: {
                            display: true,
                            fontColor: '#637381',
                            fontFamily: 'Graphik-Medium',
                            fontSize: 12,
                            fontWeight: 500,
                            labelString: i18n.t('Month'),
                          },
                        }],
                      },
                      responsive: true,
                      legend: {
                        display: false,
                      },
                      plugins: {
                        datalabels: {
                          align: 'right',
                          offset: 1,
                          formatter(value) {
                            return forNum(value, 2);
                          },
                          display(context) {
                            if (context.dataset.label === info.metDataAttr[1].AttributeName && context.dataIndex === context.dataset.data.length - 1) return true;
                            if ((context.dataset.label === info.metDataAttr[0].AttributeName) && context.dataIndex === context.dataset.data.length - 1) return true;
                            if (context.dataset.label === i18n.t('Baseline') && context.dataIndex === context.dataset.data.length - 1) return true;
                            if (context.dataset.label === i18n.t('Target') && context.dataIndex === context.dataset.data.length - 1) return true;
                            return false;
                          },
                          borderRadius: 50,
                          color: 'white',
                          font: {
                            weight: 500,
                            size: 11,
                            family: 'Graphik-Medium',
                          },

                          padding: {
                            left: 4,
                            right: 4,
                            top: 4,
                            bottom: 4,
                          },
                        },
                      },
                    }}
                    redraw="true"
                  />
                </div>
                {kpiMappingId && showComments && (
                  <>
                    <div className="commentrychat-division">
                      <InsightsChatWindow
                        GetKpiData={this.getKpiData}
                        monthComment={dsKpiData.labels}
                        commentData={temp}
                        kpiMappingId={kpiMappingId}
                        kpiName={kpiName}
                        newNotificationList={newNotificationList}
                      />
                    </div>
                  </>
                )}
              </div>

            </TabPanel>

            <TabPanel tabId="two">

              <MetricDeepDive
                IsMultiLevel={isMultiLevel}
                CategoryInfo={info.CategoryDetails}
                kpiUnit={kpiUnit}
                currentMonth={dealFilter.Month}
                dealFilter={dealFilter}
                metricID={metricID}
                kpiMappingId={this.props.kpiMappingId}
              />

            </TabPanel>
            <TabPanel tabId="DDTrends">
              <div className="linechart-commentry">
                <BoiTrendsWrapper ClientData={ClientData} kpiMappingId={kpiMappingId} Month={SyncFilter.Month} FilterDetails={SyncFilter} roleReducers={roleData} />
              </div>
            </TabPanel>
          </Tabs>
          <ReactTooltip place="top" className="tipcolor" />
        </Modal.Body>

      </Modal>

    );
  }
}
const mapStateToProps = (state) => ({
  SelectedCommentMonth: state.CurrentFilter.SelectedCommentMonth,
  CommentMonthChange: state.CurrentFilter.CommentMonthChange,
  SyncFilter: { ...state.CurrentFilter.SyncFilter },
  roleData: state.roleReducers.roleReducers,
  ClientData: state.CurrentFilter.ClientData,
  SubOfferingName: { ...state.CurrentFilter.Common },
});

TrendGraphsDiverse.propTypes = {
  info: PropTypes.string.isRequired,
  kpiName: PropTypes.string.isRequired,
  kpiMappingId: PropTypes.number.isRequired,
  Details: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  dealFilter: PropTypes.array.isRequired,
  kpiUnit: PropTypes.number.isRequired,
  SelectedCommentMonth: PropTypes.string.isRequired,
  CommentMonthChange: PropTypes.string.isRequired,
  ClientData: PropTypes.object.isRequired,
  roleData: PropTypes.array.isRequired,
  SyncFilter: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(TrendGraphsDiverse);
