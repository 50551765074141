import React, { Component } from "react";
import emptycomponent from "../../Images/Empty State.svg";
import logo from "../../Images/bat_icons/Bill.png";
import axiosInstance from "../../Shared/interceptor";
import {trycatchAlertPopup} from "../../Shared/Constant";
import { LocalApiBaseUrl } from "../../Shared/Constant";
import ReactTooltip from 'react-tooltip';
import SynopsLoader from "../../Shared/SynopsLoader";
// import { Multiselect } from "multiselect-react-dropdown";
import MultiSelect from "react-multi-select-component";
import "../../CSS/valueRealization.css";
import ValueRealizationGraphs from "../../Shared/ValueRealizationGraphs";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import { Modal, Button, Overlay, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import LoadingOverlay from "react-loading-overlay";
import $ from "jquery";
import _ from "lodash";
import i18n from "i18next";

const defaultStyle = {
  color: "rgb(83, 83, 83)",
  "font-family": "Graphik-Regular",
  "font-size": "14px",
  "font-weight": "normal",
  height: "16px",
  "letter-spacing": "-0.25px",
  "line-height": "16px",
  width: "59px",
  "margin-left": "10px",
};
const defaultActiveStyle = { background: "rgb(1, 142, 255)", "border-radius": "3px", color: "#fff" };

const createNewViewModelStructure = {
  dealId: null,
  towers: [],
  deliveryLocation: [],
  fiscalYear: [],
  viewName: "",
};

class ValueRealization extends Component {
  constructor(props) {
    super(props);
    this.filterDetails = {
      OrgEntityID: this.props.filterDetails.Client,
      OfferingID: this.props.filterDetails.process,
      LocationID: this.props.filterDetails.Locations,
      LanguageID: 1,
    };

    this.state = {
      showNotification: false,
      showCreateNewViewSection: false,
      showConfirmation: false,
      showSavedViewPopup: false,
      totalSavedViews: [],
      doesMappingExist: false,
      showSelectedViewGraph: false,
      selectedViewDataset: {},
      graphDataSet: {},
      showCVTgraph: false,

      towerOfferingOptions: [],
      locationOptions: [],
      fiscalYearsOptions: [],
      initiativeOptions: [],
      selectedInitiativeType: [],
      selectedInitiativeTypeTemp: [],
      LastSelectedSection: "showSelectedViewGraph",
      createNewViewModel: { ..._.cloneDeep(createNewViewModelStructure) },
      isValidName: true,
      showInitiativePopup: false,
      postApiStatusMessage: "",
      showPostApiStatus: false,
      showDeleteAlertModal: false,
      isApiLoading: false,
    };

  }

  hideSavedViewPopup = () => {
    this.setState({ showSavedViewPopup: false });
  };
  showSavedViewsPopup = () => {
    this.setState({ showSavedViewPopup: true });
  };

  handleConfirmationModalShow = () => {
    this.setState({
      showConfirmation: true,
    });
  };
  handleConfirmationModalHide = (action) => {
    let LastSelectedSection = _.cloneDeep(this.state.LastSelectedSection);

    if (action === "cancel") {
      const { createNewViewModel } = this.state;
      createNewViewModel.viewName = "";
      this.setState({
        showConfirmation: false,
        isValidName: true,
        createNewViewModel,
      });
    } else {
      this.tabEvent();
      this.postViewApi();
      this.setState({
        showConfirmation: false,
        showCreateNewViewSection: false,
        showSavedViewsTiles: LastSelectedSection === "showSavedViewsTiles" ? true : false,
        showSelectedViewGraph: LastSelectedSection === "showSelectedViewGraph" ? true : false,
        selectedTabIndex: LastSelectedSection,
        createNewViewModel: { ..._.cloneDeep(createNewViewModelStructure) },
      });
    }
  };

  postViewApi = () => {
    let { createNewViewModel } = this.state;
    let data = {
      ...this.filterDetails,
      Towers: createNewViewModel.towers.toString(),
      DeliveryLocations: createNewViewModel.deliveryLocation.toString(),
      FiscalYears: createNewViewModel.fiscalYear.toString(),
      ViewName: createNewViewModel.viewName,
    };
    this.setState({
      isApiLoading: true,
    });
    axiosInstance
      .post(`${LocalApiBaseUrl}Value/PostViewConfigurator`, data)
      .then((response) => {
        // alert(response.data.StatusMessage);
        this.setState({
          postApiStatusMessage: response.data.StatusMessage,
          showPostApiStatus: true,
          isApiLoading: false,
        });
        this.generateSavedViewsData();
      })
      .catch((error) => {
        this.setState({
          isApiLoading: false,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  componentDidMount() {
    this.checkOneTimeSetupMapping();
  }

  checkOneTimeSetupMapping = () => {
    //api call to be done
    let { createNewViewModel } = this.state;
    // axiosInstance
    //   .get(`${LocalApiBaseUrl}Value/GetOTSStatusandTower`, {
    let data = {
      ...this.filterDetails,
    };
    this.setState({
      isApiLoading: true,
    });
    axiosInstance
      .post(`${LocalApiBaseUrl}Value/GetOTSStatusandTower`, data)
      .then((response) => {
        this.setState(
          {
            doesMappingExist: response.data.OTSStatus,
            isApiLoading: false,
          },
          () => {
            if (response.data.OTSStatus) {
              this.generateSavedViewsData();
              this.setState({
                towerOfferingOptions: response.data.TowerList,
              });
            }
          }
        );
      })
      .catch((error) => {
        this.setState({
          isApiLoading: false,
        });
        trycatchAlertPopup(error);

      });
  };

  //To Get the total Saved Views data
  generateSavedViewsData = () => {
    // make an api call here to get all the views
    // input params: orgId, offId, locId,
    // axiosInstance
    //   .get(`${LocalApiBaseUrl}Value/GetSavedViews`, {

    let data = {
      ...this.filterDetails,
    };
    this.setState({
      isApiLoading: true,
    });
    axiosInstance
      .post(`${LocalApiBaseUrl}Value/GetSavedViews`, data)
      .then((response) => {
        this.setState(
          {
            totalSavedViews: response.data,
            isApiLoading: false,
          },
          () => {
            if (response.data.length > 0) {
              let defaultView = response.data.filter((data) => data.IsDefault === true)[0];
              // alert(`default View ${response.data.ViewName}`);

              this.getAllGraphData(defaultView);
              this.setState({
                selectedViewDataset: defaultView,
                initiativeOptions: this.generateInitiativeOptions(defaultView),
                selectedInitiativeType: this.generateInitiativeOptions(defaultView),
                selectedInitiativeTypeTemp: this.generateInitiativeOptions(defaultView),
              });
            }
          }
        );
      })
      .catch((error) => {
        this.setState({
          isApiLoading: false,
        })
        trycatchAlertPopup(error);

      });
  };

  generateFilterOptions = (type) => {
    let { createNewViewModel } = this.state;

    //api call to get the filters value

    // axiosInstance
    //   .get(`${LocalApiBaseUrl}Value/GetCVTFilterInfoConfigurator`, {

    let data = {
      ...this.filterDetails,
      TowerFilter: createNewViewModel.towers.toString(),
      LocationFilter: createNewViewModel.deliveryLocation.toString(),
      FiscalYears: createNewViewModel.fiscalYear.toString(),
    };
    this.setState({
      isApiLoading: true,
    });
    axiosInstance
      .post(`${LocalApiBaseUrl}Value/GetCVTFilterInfoConfigurator`, data)
      .then((response) => {
        if (type === "towers") {
          this.setState({
            locationOptions: response.data.DeliveryLocationList,
            fiscalYearsOptions: [],
          });
        }
        if (type === "deliveryLocation") {
          this.setState({
            fiscalYearsOptions: response.data.FiscalYearList,
          });
        }
        this.setState({
          isApiLoading: false,
        });

      })
      .catch((error) => {
        this.setState({
          isApiLoading: false,
        });
        trycatchAlertPopup(error);

      });
  };

  showGraphsSection = (eachView) => {
    this.setState({
      showSelectedViewGraph: true,
      showNotification: false,
      showCreateNewViewSection: false,
      showSavedViewsTiles: false,
      showSavedViewPopup: false,
      LastSelectedSection: "showSelectedViewGraph",
    });
  };

  getAllGraphData = (eachView) => {
    // alert(`graph api call for ${eachView.ViewName}`);
    let initiative = this.displaySelectedInitiative();

    // api call will be made here to obtain the graphs
    // view id (eachView.ID) and initiative(initiative) will be the inputs.

    this.setState({
      isApiLoading: true,
    });
    axiosInstance
      .post(
        // `${LocalApiBaseUrl}Value/GetAllGraphData`,
        `${LocalApiBaseUrl}Value/GetAllGraphData`,
        {
          InitiativeFilter: initiative == "" ? null : initiative,
          CVTViewID: eachView.ID,
          CVTUserViewsFrontDoorID: 0,
          FiscalFilter: eachView.FiscalYears,
        }
      )
      .then(
        (response) => {
          this.setState({
            graphDataSet: response.data,
            selectedViewDataset: eachView,
            isApiLoading: false,
            showSelectedViewGraph: true,
            showNotification: false,
            showCreateNewViewSection: false,
            showSavedViewsTiles: false,
            showSavedViewPopup: false,
            LastSelectedSection: "showSelectedViewGraph",
            showCVTgraph: true,
          });
        },
        (error) => {

          this.setState({
            isApiLoading: true,
          });
        }
      )
      .catch((error) => {
        trycatchAlertPopup(error);
      });

  };

  GenerateSelectedViewDataset = (eachView) => {
    // alert(`selected view ${eachView.ViewName}`);

    this.setState(
      {
        selectedViewDataset: eachView,
        initiativeOptions: this.generateInitiativeOptions(eachView),
        selectedInitiativeType: this.generateInitiativeOptions(eachView),
        selectedInitiativeTypeTemp: this.generateInitiativeOptions(eachView),
      },
      () => {
        this.getAllGraphData(this.state.selectedViewDataset);
      }
    );
  };
  showSavedViewsSection = () => {
    this.setState({
      showCreateNewViewSection: false,
      showSavedViewsTiles: true,
      showSelectedViewGraph: false,
      showSavedViewPopup: false,
      LastSelectedSection: "showSavedViewsTiles",
    });
  };

  showCreateNewViewsSection = () => {
    if (!this.state.showCreateNewViewSection) {
      this.setState({
        showNotification: false,
        showCreateNewViewSection: true,
        showSavedViewsTiles: false,
        showSelectedViewGraph: false,
        showSavedViewPopup: false,
        selectedTabIndex: "towers",
        createNewViewModel: { ..._.cloneDeep(createNewViewModelStructure) },
      });
    }
  };

  setCreateNewViewFilter(type, e) {
    debugger;
    let createNewViewModel = { ...this.state.createNewViewModel };

    if (type === "towers") {
      createNewViewModel.towers = [];
      createNewViewModel.deliveryLocation = [];
      createNewViewModel.fiscalYear = [];

      $("#createFiscalYears input:checkbox").each(function () {
        $(this).prop("checked", false);
      });
      $("#createLocationGroups input:checkbox").each(function () {
        $(this).prop("checked", false);
      });

      let y = $("#createTowerGroups input:checkbox:checked");
      $.each(y, function (key, each) {
        createNewViewModel.towers.push(each.value);
      });
    }

    if (type === "deliveryLocation") {
      createNewViewModel.deliveryLocation = [];
      createNewViewModel.fiscalYear = [];
      $("#createFiscalYears input:checkbox").each(function () {
        $(this).prop("checked", false);
      });
      let y = $("#createLocationGroups input:checkbox:checked");
      $.each(y, function (key, each) {
        createNewViewModel.deliveryLocation.push(each.value);
      });
    }
    if (type === "fiscalYear") {
      createNewViewModel.fiscalYear = [];
      let y = $("#createFiscalYears input:checkbox:checked");
      $.each(y, function (key, each) {
        createNewViewModel.fiscalYear.push(each.value);
      });
    }
    this.setState(
      {
        createNewViewModel: createNewViewModel,
      },
      () => {
        if (type !== "fiscalYear") {
          this.generateFilterOptions(type);

        }
      }
    );
  }

  handleNotificationModalShow = () => {
    this.setState({
      showNotification: true,
      showSelectedViewGraph: false,
    });
  };
  handleNotificationModalHide = () => {
    this.setState({
      showNotification: false,
    });
  };

  setFavouriteView = (e, eachView) => {
    e.stopPropagation();
    // alert(`set favourite ${eachView.ViewName}`);

    if (!eachView.IsDefault) {
      this.setState({
        isApiLoading: true,
      });
      axiosInstance
        .post(
          `${LocalApiBaseUrl}Value/PostSetDefaultViewConfigurator?ViewID=${eachView.ID}&OrgEntityID=${this.filterDetails.OrgEntityID}&LocationID=${this.filterDetails.LocationID}&OfferingID=${this.filterDetails.OfferingID}`
        )
        .then((response) => {
          this.generateSavedViewsData();
          this.setState({
            isApiLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoading: false,
          });
            trycatchAlertPopup(error);
  
        });
    }
  };

  nextTabChangeEvent = (prevTab, action) => {
    let LastSelectedSection = _.cloneDeep(this.state.LastSelectedSection);

    if (prevTab === "towers") {
      if (action === "previous") {
        this.setState({
          showCreateNewViewSection: false,
          showSavedViewsTiles: LastSelectedSection === "showSavedViewsTiles" ? true : false,
          showSelectedViewGraph: LastSelectedSection === "showSelectedViewGraph" ? true : false,
          createNewViewModel: { ..._.cloneDeep(createNewViewModelStructure) },
        });
      } else {
        if (this.validateDestinationModel(prevTab)) {
          this.setState({ selectedTabIndex: "deliveryLocation" });
          this.tabEvent("deliveryLocation");
        }
      }
    }

    if (prevTab === "deliveryLocation") {
      if (action === "previous") {
        this.setState({ selectedTabIndex: "towers" });
        this.tabEvent("towers");
      } else {
        if (this.validateDestinationModel(prevTab)) {
          this.setState({ selectedTabIndex: "fiscalYear" });
          this.tabEvent("fiscalYear");
        }
      }
    }

    if (prevTab === "fiscalYear") {
      if (action === "previous") {
        this.setState({ selectedTabIndex: "deliveryLocation" });
        this.tabEvent("deliveryLocation");
      } else {
        if (this.validateDestinationModel(prevTab)) {
          this.handleConfirmationModalShow();
          // this.generateDefaultViewName();
        }
      }
    }
  };

  validateDestinationModel = (tabIndex) => {
    let createNewViewModel = { ...this.state.createNewViewModel };
    return createNewViewModel[tabIndex].length > 0 ? true : false;
  };

  generateDefaultViewName = () => {
    let createNewViewModel = { ...this.state.createNewViewModel };
    createNewViewModel.viewName = `View-${createNewViewModel.towers.toString()}_${createNewViewModel.deliveryLocation.toString()}_${createNewViewModel.fiscalYear.toString()}`;
    this.setState({ createNewViewModel: createNewViewModel, isValidName: true });
  };

  setNewViewName(event) {
    let { createNewViewModel, isValidName, totalSavedViews } = this.state;
    createNewViewModel.viewName = event.target.value;
    isValidName = !totalSavedViews.filter((data) => data.ViewName === event.target.value).length > 0;
    this.setState({ createNewViewModel: createNewViewModel, isValidName: isValidName });
  }

  tabEvent = (id) => {
    let token = id != undefined ? true : false;

    $("#" + "towers").attr("hidden", token);
    $("#" + "deliveryLocation").attr("hidden", token);
    $("#" + "fiscalYear").attr("hidden", token);

    if (id != undefined) $("#" + id).attr("hidden", false);
  };

  onInitiativeSelect = () => {
    let { createNewViewModel } = this.state;
  };

  generateInitiativeOptions = (selectedViewDataset) => {
    let initiativeOptions = [];
    selectedViewDataset.InitiativeTypeList.split(",").map((row, i) => {
      if (row.name != "") {
        let dummy = [];
        dummy.label = row;
        dummy.value = row;
        initiativeOptions.push(dummy);
      }
    });
    return initiativeOptions;
  };

  initiativeChange = (name) => {
    let { selectedInitiativeTypeTemp } = this.state;
    var checkboxes = document.getElementsByName(name);
    var checkAll = document.getElementsByName("allInitiative");
    var selected = [];
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        selected.push(checkboxes[i]);
      }
    }
    if (this.state.initiativeOptions.length === selected.length) {
      checkAll[0].checked = true;
    } else {
      checkAll[0].checked = false;
    }
    selectedInitiativeTypeTemp = selected;
    this.setState({ selectedInitiativeTypeTemp: selectedInitiativeTypeTemp });
  };

  selectAllInitiative = (e) => {
    let check = e.target.checked;
    let checkboxes = document.getElementsByName("initiativeType");
    let selected = [];
    if (check) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        selected.push(checkboxes[i]);
      }
    } else {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
    }
    this.setState({ selectedInitiativeTypeTemp: selected });
  }

  handleInitiativePopup = (type) => {
    let { selectedInitiativeType, selectedInitiativeTypeTemp } = this.state;

    if (type === "show") {
      this.setState({ showInitiativePopup: true, selectedInitiativeTypeTemp: selectedInitiativeType });
    } else if (type === "confirm") {
      this.setState({ showInitiativePopup: false, selectedInitiativeType: selectedInitiativeTypeTemp }, () => {
        this.getAllGraphData(this.state.selectedViewDataset);
      });
    } else if (type === "cancel") {
      this.setState({ selectedInitiativeTypeTemp: [], showInitiativePopup: false });
    }
  };

  handlePostApiStatusModalHide = () => {
    this.setState({ showPostApiStatus: false, postApiStatusMessage: "" });
  };

  handleRemoveModalHide = (e, payload) => {
    e.stopPropagation();

    if (payload.type === "show") {
      this.setState({ showDeleteAlertModal: true, deletePayload: payload });
    } else if (payload.type === "cancel") {
      this.setState({ showDeleteAlertModal: false, deletePayload: {} });
    } else if (payload.type === "remove") {
      this.setState({ showDeleteAlertModal: false, deletePayload: {} });
      this.deleteView(this.state.deletePayload.eachView);
    }
  };

  deleteView = (eachView) => {
    // e.stopPropagation();
    // alert(`delete  ${eachView.ViewName}`);
    this.setState({
      isApiLoading: true,
    });
    axiosInstance
      .delete(`${LocalApiBaseUrl}Value/DeleteViewConfigurator`, {
        params: { ViewID: eachView.ID },
      })
      .then((response) => {
        this.setState({
          postApiStatusMessage: response.data.StatusMessage,
          showPostApiStatus: true,
          isApiLoading: true,
        });
        this.generateSavedViewsData();
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        this.setState({
          isApiLoading: false,
        });
      });
  };

  displaySelectedInitiative = () => {
    let { selectedInitiativeType } = this.state;

    let selectedInitiativeTypeValue = [];

    selectedInitiativeType.forEach((each) => {
      selectedInitiativeTypeValue.push(each.value);
    });

    return selectedInitiativeTypeValue.toString();
  };

  render() {
    const {
      fiscalYearsOptions,
      towerOfferingOptions,
      locationOptions,
      showSelectedViewGraph,
      totalSavedViews,
      createNewViewModel,
      selectedViewDataset,
      showCreateNewViewSection,
      showSavedViewsTiles,
      initiativeOptions,
      selectedInitiativeType,
      selectedInitiativeTypeTemp,
    } = this.state;

    const multiselectYearPicker = (
      <React.Fragment>
        <MultiSelect
          options={initiativeOptions}
          value={selectedInitiativeTypeTemp}
          onChange={this.initiativeChange.bind(this)}
          labelledBy="OrgEntityName"
          shouldToggleOnHover={false}
          overrideStrings={{
            selectSomeItems: "Select",
            allItemsAreSelected: "All items selected.",
            selectAll: "Select All ",
            search: "Search",
          }}
          hasSelectAll={true}
        />
      </React.Fragment>
    );
    const initiative = this.displaySelectedInitiative();
    return (
      <>
        <LoadingOverlay active={this.state.isApiLoading} spinner={<SynopsLoader />}>
          <div className="valueRealization-container">
            <div className="btn-container">
              <div className="btn-container-buttons">
                <button
                  type="button"
                  className="btn createView"
                  onClick={() =>
                    !this.state.doesMappingExist ? this.handleNotificationModalShow() : this.showCreateNewViewsSection()
                  }
                >
                 {i18n.t("Create New View")}
                </button>

                <button
                  type="button"
                  className="btn savedView"
                  onClick={() =>
                    !this.state.doesMappingExist ? this.handleNotificationModalShow() : this.showSavedViewsPopup()
                  }
                >
                 {i18n.t("Saved Views")}
                </button>
                {this.state.showSavedViewPopup ? (
                  <div className="saved-view-list">
                    <div className="seeall-section">
                      {totalSavedViews.length > 0 && <span onClick={() => this.showSavedViewsSection()}> {i18n.t("See All")}</span>}
                    </div>
                    <Tabs defaultTab={this.state.currentlySelectedTab}>
                      <TabList>
                        <Tab className={"visited"} tabFor="SavedViews">
                        {i18n.t("Saved Views")}
                        </Tab>
                      </TabList>
                      <TabPanel tabId="SavedViews">
                        <div className="savedviews-list">
                          <ul>
                            {totalSavedViews.map((eachView) => {
                              return (
                                <li
                                  style={selectedViewDataset.ID === eachView.ID ? defaultActiveStyle : {}}
                                  onClick={() => this.GenerateSelectedViewDataset(eachView)}
                                >
                                  {eachView.ViewName}
                                  {eachView.IsDefault && <span style={defaultStyle}>(Default)</span>}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                ) : (
                    ""
                  )}
              </div>
            </div>
            {this.state.showSavedViewPopup ? (
              <div className="vr-list-overlay" onClick={this.hideSavedViewPopup}></div>
            ) : (
                ""
              )}

            {totalSavedViews.length === 0 && !(showCreateNewViewSection || showSavedViewsTiles) ? (
              /* LANDING PAGE NO VIEWS DEFAULT MESSAGE STARTS HERE*/
              !this.state.isApiLoading && 
              (<div className="novalueContainer">
                <div className="novalue-component">
                  <img src={emptycomponent} alt="" width="130" height="130" />
                  <h6>Configure Your Client Value Dashboard</h6>
                  <div className="novalue-action">Click on 'Create New View' to create your client value dashboard</div>
                  <div className="novalue-desc">
                    One time setup is pre-requisite for this configuration. Please ensure one time setup is completed.
                  </div>
                </div>
              </div>)
            ) : (
                /* LANDING PAGE NO VIEWS DEFAULT MESSAGE ENDS HERE*/

                showSelectedViewGraph === true && (
                  <>
                    {/* GRAPH SECTION  STARTS HERE*/}
                    <div className="valuerealization-optionbuttons">
                      <div>
                        <span>Tower/ {selectedViewDataset.Towers}</span>
                      </div>
                      <div>
                        <span>Delivery Location/ {selectedViewDataset.DeliveryLocations}</span>
                      </div>
                      <div>
                        <span>
                          Fiscal Year/ {selectedViewDataset.FiscalYears}
                          <i class="fal fa-calendar-alt"></i>
                        </span>
                      </div>
                      <div>
                        {/* <Overlay
                        // target={this.datepickerDropdownPopoverTarget.current}
                        show={this.state.showInitiativePopup}
                        placement="bottom"
                        rootClose
                        rootCloseEvent="click"
                      >
                        <Popover id="popover-basic4" className="calenderPopover multiselectYearPicker">
                          <Popover.Content>
                            <div className="popContent">
                              <div>{multiselectYearPicker}</div>
                            </div>
                          </Popover.Content>
                          <div className="popover-footer">
                            <div className="modal-btn temp">
                              <Button
                                variant="outline-secondary"
                                className="btn-sm mr-auto"
                                onClick={() => this.handleInitiativePopup("cancel")}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="primary"
                                className="btn-sm"
                                onClick={() => this.handleInitiativePopup("confirm")}
                              >
                                Confirm
                              </Button>
                            </div>
                          </div>
                        </Popover>
                      </Overlay> */}
                        <div>
                          <span onClick={() => this.handleInitiativePopup("show")}>
                            {/* Initiative Type/ {selectedViewDataset.InitiativeTypeList} */}
                          Initiative Type/ {selectedInitiativeType.length === initiativeOptions.length ? "All" : initiative}
                          </span>
                        </div>
                      </div>
                    </div>
                    { this.state.showCVTgraph && (
                      <div className="charts-container">
                        <ValueRealizationGraphs dataset={this.state.graphDataSet} />
                      </div>
                    )}
                    {/* GRAPH SECTION  ENDS HERE*/}
                  </>
                )
              )}

            {/* CREATE NEW VIEW SECTION STARTS HERE*/}
            {this.state.showCreateNewViewSection && (
              <div className="showCreateNewView-container">
                <Tabs defaultTab={this.state.selectedTabIndex}>
                  <TabList>
                    <Tab
                      className={
                        "cvt-tab cvtBar " + (this.state.selectedTabIndex === "towers" ? "active" : "") + "visited"
                      }
                      aria-selected="false"
                      tabFor="towers"
                      disabled
                    >
                      {i18n.t("Towers")}
                    </Tab>

                    <Tab
                      className={
                        "cvt-tab cvtBar " +
                        (this.state.selectedTabIndex === "deliveryLocation" ? "active" : "") +
                        (this.state.selectedTabIndex === "fiscalYear" ? "visited" : "")
                      }
                      aria-selected="true"
                      tabFor="deliveryLocation"
                      disabled
                    >
                      {i18n.t("Delivery Location")}
                    </Tab>

                    <Tab
                      className={"cvt-tab cvtBar " + (this.state.selectedTabIndex === "fiscalYear" ? "active" : "")}
                      aria-selected="false"
                      tabFor="fiscalYear"
                      disabled
                    >
                      {i18n.t("Fiscal Year")}
                    </Tab>
                  </TabList>
                  <TabPanel tabId="towers">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="cvt-offering-list" id="createTowerGroups">
                          {towerOfferingOptions.map((eachGroup) => {
                            return (
                              <div className="col-lg-4 cvt-rightMargin">
                                <div class="cvt-analysisFull ">
                                  <div>
                                    <div className="cvt-analysisFull-header">
                                      <div class="cvt-analysisIcon">
                                        <img src={logo} height="40px" width="40px" alt="offering" />
                                      </div>
                                      <div class="cvt-analysisTitle">
                                        <h4>{eachGroup.Offering}</h4>
                                        <p>{eachGroup.Towers.length} Sub Offerings</p>
                                      </div>
                                    </div>
                                    <div className="cvt-subOffering-list">
                                      {eachGroup.Towers.map((eachTower) => {
                                        return (
                                          <div>
                                            <div className={classNames("cvt-check-group")}>
                                              <label className="form-check">
                                                <input
                                                  type="checkbox"
                                                  name={eachTower.name}
                                                  value={eachTower.name}
                                                  onChange={this.setCreateNewViewFilter.bind(this, "towers")}
                                                  className="form-check-input"
                                                />
                                                <span className="checkmark"></span>
                                                <span>{eachTower.name}</span>
                                              </label>
                                            </div>
                                            <br />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="btn-space">
                          <button
                            className="flowBtn"
                            onClick={(e) => {
                              this.nextTabChangeEvent("towers", "previous");
                            }}
                          >
                            <FontAwesomeIcon icon={faChevronLeft} />
                            &nbsp; {i18n.t("Previous")}
                          </button>
                          <button
                            className={this.validateDestinationModel("towers") ? "flowBtn nextBtn" : "flowBtn nextBtn disabled"}
                            onClick={(e) => {
                              this.nextTabChangeEvent("towers", "next");
                            }}
                          >
                            {" "}
                           {i18n.t("Next")}&nbsp;
                            <FontAwesomeIcon icon={faChevronRight} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel tabId="deliveryLocation">
                    <div className="deliveryLocation-container">
                      <div className="row">
                        <div className="col-lg-12 ">
                          <div className="region-container" id="createLocationGroups">
                            {locationOptions.map((eachGroup) => {
                              return (
                                <div className="col-lg-4">
                                  <div className="region-title">
                                    <div className={classNames("cvt-check-group")}>
                                      <label className="">
                                        {/* <input
                                                  type="checkbox"
                                                  name={eachTower.name}
                                                  value={eachTower.name}
                                                  onChange={this.setCreateNewViewFilter.bind(this, "towers")}
                                                  className="form-check-input"
                                                />
                                                <span className="checkmark"></span> */}
                                        <span>{eachGroup.Region}</span>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="country-list">
                                    {eachGroup.DeliveryLocations.map((eachLocation) => {
                                      return (
                                        <div>
                                          <div className={classNames("cvt-check-group")}>
                                            <label className="form-check">
                                              <input
                                                type="checkbox"
                                                name={eachLocation.name}
                                                value={eachLocation.name}
                                                onChange={this.setCreateNewViewFilter.bind(this, "deliveryLocation")}
                                                className="form-check-input"
                                              />
                                              <span className="checkmark"></span>
                                              <span>{eachLocation.name}</span>
                                            </label>
                                          </div>
                                          <br />
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="btn-space">
                            <button
                              className="flowBtn"
                              onClick={(e) => {
                                this.nextTabChangeEvent("deliveryLocation", "previous");
                              }}
                            >
                              <FontAwesomeIcon icon={faChevronLeft} />
                              &nbsp;Previous
                            </button>
                            <button
                              className={this.validateDestinationModel("deliveryLocation") ? "flowBtn nextBtn" : "flowBtn nextBtn disabled"}
                              onClick={(e) => {
                                this.nextTabChangeEvent("deliveryLocation", "next");
                              }}
                            >
                              {" "}
                              Next&nbsp;
                              <FontAwesomeIcon icon={faChevronRight} />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel tabId="fiscalYear">
                    <div className="fiscalYear-container">
                      <div className="row">
                        <div className="col-lg-12 ">
                          <div className="yearlist-container" id="createFiscalYears">
                            {fiscalYearsOptions.map((each) => {
                              return (
                                <div className="col-lg-3">
                                  <div className="year-list">
                                    <div>
                                      <div className={classNames("cvt-check-group")}>
                                        <label className="form-check">
                                          <input
                                            type="checkbox"
                                            name={each.fiscalYear}
                                            value={each.fiscalYear}
                                            onChange={this.setCreateNewViewFilter.bind(this, "fiscalYear")}
                                            className="form-check-input"
                                          />
                                          <span className="checkmark"></span>
                                          <span>{each.fiscalYear}</span>
                                        </label>
                                      </div>
                                      <br />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="btn-space">
                            <button
                              className="flowBtn"
                              onClick={(e) => {
                                this.nextTabChangeEvent("fiscalYear", "previous");
                              }}
                            >
                              <FontAwesomeIcon icon={faChevronLeft} />
                              &nbsp;Previous
                            </button>
                            <button
                              className={this.validateDestinationModel("fiscalYear") ? "flowBtn nextBtn" : "flowBtn nextBtn disabled"}
                              onClick={() => this.nextTabChangeEvent("fiscalYear", "next")}
                            >
                              {" "}
                              Submit&nbsp;
                              <FontAwesomeIcon icon={faChevronRight} />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            )}
            {/* CREATE NEW VIEW SECTION ENDS HERE*/}
          </div>

          {/*  VIEW TILES SECTION STARTS HERE*/}
          {this.state.showSavedViewsTiles && (
            <div className="savedviews-container">
              <div className="myView-section">
                <div className="savedViews-header">Saved Views</div>
                <div className="row">
                  {totalSavedViews.map((eachView) => {
                    return (
                      // <div className="view-card-size">
                      //   <div className="view-card-wrap" onClick={() => this.GenerateSelectedViewDataset(eachView)}>
                      //     <div className="view-card">
                      //       <div className="view-title">{eachView.ViewName.replace(/,[s]*/g, ", ")}</div>
                      //       <div className="form-group">
                      //         <div className="field-name">Tower</div>
                      //         <div className="field-value">{eachView.Towers.replace(/,[s]*/g, ", ")}</div>
                      //       </div>
                      //       <div className="form-group">
                      //         <div className="field-name">Delivery Location</div>
                      //         <div className="field-value">{eachView.DeliveryLocations.replace(/,[s]*/g, ", ")}</div>
                      //       </div>
                      //       <div className="form-group">
                      //         <div className="field-name">Fiscal Year</div>
                      //         <div className="field-value">{eachView.FiscalYears.replace(/,[s]*/g, ", ")}</div>
                      //       </div>
                      //     </div>
                      //     <div className="view-card-footer">
                      //       <div className="favourite_tooltip" onClick={(e) => this.setFavouriteView(e, eachView)}>
                      //         <i
                      //           className={"fas fa-star " + (eachView.IsDefault ? "active" : "")}
                      //           aria-hidden="true"
                      //         ></i>
                      //         <span class="favourite_tooltiptext">
                      //           <span>Mark as Default</span>
                      //         </span>
                      //       </div>
                      //       <div
                      //         className="delete-btn"
                      //         onClick={(e) => this.handleRemoveModalHide(e, { eachView: eachView, type: "show" })}
                      //       >
                      //         <i class="far fa-trash-alt" aria-hidden="true"></i>
                      //       </div>
                      //     </div>
                      //   </div>
                      // </div>
                    
                      <div className="view-card-size" onClick={() => this.GenerateSelectedViewDataset(eachView)}>
                        <div className="view-card">
                          <div className="view-title">{eachView.ViewName.replace(/,[s]*/g, ", ")}</div>  

                          <div className="form-group">
                            <div className="field-name">Tower</div>
                            {/* <div className="field-value">{eachView.Towers.replace(/,[s]g, ", ")}</div> */}
                            <div className="field-valueblock">
                              <span className="field-value">
                                {eachView.Towers.replace(/,[s]*/g, ", ").length>42 ?
                                eachView.Towers.replace(/,[s]*/g, ", ").slice(0,42) + "..." :
                                eachView.Towers.replace(/,[s]*/g, ", ")}
                              </span>                               
                              {eachView.Towers.replace(/,[s]*/g, ", ").length>42 ?
                              <>
                                <span className="fieldvalue-count" data-tip data-for={'fieldvalue_'+eachView.ID}>
                                  <span>+{eachView.Towers.replace(/,[s]*/g, ", ").slice(43).split(",").length}</span>
                                </span>
                                <ReactTooltip place="right" type="dark" effect="solid" id={'fieldvalue_'+eachView.ID} className="fieldvalue-tooltip">
                                    <span>{eachView.Towers.replace(/,[s]*/g, ", ")}</span>
                                </ReactTooltip>
                              </>
                              :""}                              
                            </div>
                          </div>
                        
                          <div className="form-group">
                            <div className="field-name">Delivery Location</div>
                            <div className="field-valueblock">
                              <span className="field-value">
                                {eachView.DeliveryLocations.replace(/,[s]*/g, ", ").length>42 ?
                                eachView.DeliveryLocations.replace(/,[s]*/g, ", ").slice(0,42) + "..." :
                                eachView.DeliveryLocations.replace(/,[s]*/g, ", ")}
                              </span>
                              {eachView.DeliveryLocations.replace(/,[s]*/g, ", ").length>42 ?
                              <>
                                <span className="fieldvalue-count" data-tip data-for={'fieldvalueforlocation_'+eachView.ID}>
                                  <span>+{eachView.DeliveryLocations.replace(/,[s]*/g, ", ").slice(43).split(",").length}</span>
                                </span>
                                <ReactTooltip place="right" type="dark" effect="solid" id={'fieldvalueforlocation_'+eachView.ID} className="fieldvalue-tooltip">
                                    <span>{eachView.DeliveryLocations.replace(/,[s]*/g, ", ")}</span>
                                </ReactTooltip>
                              </>
                              :""}
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="field-name">Fiscal Year</div>
                            <div className="field-valueblock">
                              <span className="field-value">
                                {eachView.FiscalYears.replace(/,[s]*/g, ", ").length>42 ?
                                eachView.FiscalYears.replace(/,[s]*/g, ", ").slice(0,42) + "..." :
                                eachView.FiscalYears.replace(/,[s]*/g, ", ")}
                              </span>
                              {eachView.FiscalYears.replace(/,[s]*/g, ", ").length>42 ?
                              <>
                                <span className="fieldvalue-count" data-tip data-for={'fieldvalueforfiscalyear_'+eachView.ID}>
                                  <span>+{eachView.FiscalYears.replace(/,[s]*/g, ", ").slice(43).split(",").length}</span>
                                </span>
                                <ReactTooltip place="right" type="dark" effect="solid" id={'fieldvalueforfiscalyear_'+eachView.ID} className="fieldvalue-tooltip">
                                    <span>{eachView.FiscalYears.replace(/,[s]*/g, ", ")}</span>
                                </ReactTooltip>
                              </>
                              :""}
                            </div>
                          </div>
                        </div>
                        <div className="view-card-footer">
                          <div className="favourite_tooltip" onClick={(e) => this.setFavouriteView(e, eachView)}>
                            <span
                              className={"defaultmark-icon " + (eachView.IsDefault ? "active" : "")} data-tip data-for='markdefault'
                            ></span>
                             <ReactTooltip place="bottom" type="dark" effect="solid" id="markdefault">
                                <span>Mark as Default</span>
                            </ReactTooltip>
                            {/* <span class="favourite_tooltiptext">
                              <span>Mark as Default</span>
                            </span> */}
                          </div>
                          <div
                            className="favourite_tooltip"
                            onClick={(e) => this.handleRemoveModalHide(e, { eachView: eachView, type: "show" })}
                          >
                            <span className="delete-btnicon" data-tip data-for='deleteicon'></span>
                            <ReactTooltip place="bottom" type="dark" effect="solid" id="deleteicon">
                                <span>Delete</span>
                            </ReactTooltip>
                            {/* <i class="far fa-trash-alt" aria-hidden="true"></i> */}
                          </div>
                        </div>
                      </div>
                   
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          {/*  VIEW TILES SECTION ENDS HERE*/}

          {/*  OTS WARNING STARTS HERE*/}
          {this.state.showNotification && (
            <Modal
              show
              centered
              onHide={() => this.handleNotificationModalHide()}
              backdrop="static"
              dialogClassName="valueRealization-Notification-modal"
            >
              <Modal.Header className="">
                <Modal.Title>Notification</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="notification-body">
                  <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  <p>Please complete one time setup to create views</p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button type="button" className="btn btn-primary" onClick={() => this.handleNotificationModalHide()}>
                  {/* onClick={() => this.handleCreateNewViewsClick()}> */}
                  OK
                </button>
              </Modal.Footer>
            </Modal>
          )}
          {/*  OTS WARNING ENDS HERE*/}

          {/*  VIEW CREATION CONFIRMATION STARTS HERE*/}
          {this.state.showConfirmation && (
            <Modal
              show
              centered
              onHide={() => this.handleConfirmationModalHide("cancel")}
              backdrop="static"
              dialogClassName="valueRealization-Confirmation-modal"
            >
              <Modal.Header className="">
                <Modal.Title>Confirmation</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="Confirmation-body">
                  <div className="alert-msg">
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                    <p>Please confirm your selection</p>
                  </div>
                  <div className="form-group">
                    <label className="" for="tower">
                      Tower
                    </label>
                    <div className="text-value">{createNewViewModel.towers.toString().replace(/,[s]*/g, "/ ")}</div>
                  </div>
                  <div className="form-group">
                    <label className="" for="deliveryLocation">
                      Delivery Location
                    </label>
                    <div className="text-value">
                      {createNewViewModel.deliveryLocation.toString().replace(/,[s]*/g, "/ ")}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="" for="fiscalYear">
                      Fiscal Year
                    </label>
                    <div className="text-value">{createNewViewModel.fiscalYear.toString().replace(/,[s]*/g, "/ ")}</div>
                  </div>
                  <div className="form-group">
                    <label className="" for="fiscalYear"></label>
                    <div className={!this.state.isValidName ? "validation-errmsg" : ""}>
                      <div className="input-text-value">
                        <label className="" for="viewName">
                          View Name
                        </label>
                        <input
                          type="text"
                          className=""
                          id="viewName"
                          maxLength="100"
                          name="viewName"
                          onChange={this.setNewViewName.bind(this)}
                          value={createNewViewModel.viewName}
                        />
                      </div>
                      {!this.state.isValidName && (
                        <div className="guidline-msg">This view name already exists. Please enter a new name.</div>
                      )}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn cancel-btn"
                  onClick={() => this.handleConfirmationModalHide("cancel")}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn confirm-btn"
                  onClick={() => this.handleConfirmationModalHide("confirm")}
                  disabled={!this.state.isValidName || createNewViewModel.viewName.trim() === ""}
                >
                  Confirm
                </button>
              </Modal.Footer>
            </Modal>
          )}
          {/*  VIEW CREATION CONFIRMATION ENDS HERE*/}

          {/* POST API STATUS MESSAGE STARTS HERE */}
          <Modal
            show={this.state.showPostApiStatus}
            centered
            // onHide={() => this.handlePostApiStatusModalHide()}
            backdrop="static"
            dialogClassName="valueRealization-Notification-modal"
          >
            <Modal.Header className="">
              <Modal.Title>Notification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="notification-body">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <p>{this.state.postApiStatusMessage}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-primary" onClick={() => this.handlePostApiStatusModalHide()}>
                OK
              </button>
            </Modal.Footer>
          </Modal>
          {/* POST API STATUS MESSAGE ENDS HERE */}

          {/* DELETE CONFIRMATION BOX STARTS HERE */}
          <Modal
            show={this.state.showDeleteAlertModal}
            centered
            backdrop="static"
            dialogClassName="valueRealization-remove-modal"
          >
            <Modal.Header className="">
              <Modal.Title>Remove Asset</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="notification-body">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <p>
                  Are you sure you want to delete this asset? <br />
                  This action can not be undone.
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn cancel-btn"
                onClick={(e) => this.handleRemoveModalHide(e, { type: "cancel" })}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn confirm-btn"
                onClick={(e) => this.handleRemoveModalHide(e, { type: "remove" })}
              >
                {" "}
                Remove
              </button>
            </Modal.Footer>
          </Modal>
          {/* DELETE CONFIRMATION BOX STARTS HERE */}


          {/* Select Initiative Popup Start*/}
          <Modal
            show={this.state.showInitiativePopup}
            centered
            onHide={() => this.handleInitiativePopup("cancel")}
            className="selectInitiative-modal"
          >
            <Modal.Header>
              <Modal.Title>Select Initiative Type</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="initiatives-list">
                <ul>
                  <li>
                    <label className="form-check">
                      <input
                        className="form-check-input"
                        defaultChecked={selectedInitiativeTypeTemp.length === initiativeOptions.length}
                        type="checkbox"
                        onChange={(e) => this.selectAllInitiative(e)}
                        value="all"
                        name="allInitiative"
                      />
                      <span className="checkmark"></span>
                      <div className="form-check-label">
                        <h6>All</h6>
                      </div>
                    </label>
                  </li>
                  {initiativeOptions.map(x => (
                    <li>
                      <label className="form-check">
                        <input
                          className="form-check-input"
                          defaultChecked={x.value === initiative.split(",").filter(y => y === x.value)[0]}
                          type="checkbox"
                          name="initiativeType"
                          onChange={(e) => this.initiativeChange(e.target.name)}
                          value={x.value}
                        />
                        <span className="checkmark"></span>
                        <div className="form-check-label">
                          <h6>{x.label}</h6>
                        </div>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </Modal.Body>
            <Modal.Footer className="modal-btn">
              <button type="button" className="btn btn-outline-secondary mr-2" onClick={() => this.handleInitiativePopup("cancel")}>
                Cancel
              </button>
              <button type="button" className={selectedInitiativeTypeTemp.length != 0 ? "btn btn-primary" : "btn btn-primary disabled"} onClick={() => this.handleInitiativePopup("confirm")}>
                Add
              </button>
            </Modal.Footer>
          </Modal>
          {/* Select Initiative Popup End*/}
        </LoadingOverlay>
      </>
    );
  }
}
export default ValueRealization;
