import React, { Component } from "react";
import { connect } from "react-redux";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import ToolConfiguratorPopup from "./ToolConfiguratorPopup";
import { store } from "../reducers/configureStore";
import { Tabs, Tab } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { LocalApiBaseUrl, compDetails, getPrevMonth } from "../Shared/Constant";
import SynopsLoader from "../Shared/SynopsLoader";
import UserAccessDeniedScreen from "../Shared/UserAccessDeniedScreen"

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


class ToolConfiguratorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedTab: "source",
      viewmore: false,
      userAccessDenied: false,
      rowSelected: [],
      rowData: [],
      DEIDealOptions: [],
      isAPILoading: false,

      ConnectorData: {
        sourceConnectorID: 12,
        sourceConnectorName: "SQF",
        DealID: null,
      },

      OneTimeSetupModel: {
        isActive: true,
        destinationClientId: 0,
        destinationClientName: "",

        destinationOfferingId: 0,
        destinationOfferingName: "",

        destinationSubOfferingId: 0,
        destinationSubOfferingName: "",

        destinationLocationId: 0,
        destinationLocationName: "",

        sourceConnectorID: 0,
        sourceConnectorName: "",

        sourceDealID: 0,
        sourceDealName: "",

        sourceClientId: 0,
        sourceClientName: [],

        sourceOfferingId: 0,
        sourceOfferingName: [],

        sourceSubOfferingId: 0,
        sourceSubOfferingName: [],

        sourceLocationId: 0,
        sourceLocationName: [],
      },
    };
    this.onSourceChange = this.onSourceChange.bind(this);
    this.onSourceClick = this.onSourceClick.bind(this);
    this.connectorpopup = this.connectorpopup.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }
  componentDidMount() {
    if (this.props.userAccessDeniedScreen === "Tool Configurator") {
      this.setState({
        userAccessDenied: true
      })
    }
    else {
      this.GetDEIDeal();
      // this.getOneTimeData();
      this.setState({
        errors: {},
        formIsValid: true,
      });
    }


  }
  componentDidUpdate(prevProps) {
    if (prevProps.userAccessDeniedScreen !== this.props.userAccessDeniedScreen) {
      if (this.props.userAccessDeniedScreen === "Tool Configurator") {
        this.setState({
          userAccessDenied: true
        })
      }
      else {
        this.setState({
          userAccessDenied: false
        })
      }
    }
  }
  GetDEIDeal() {
    let samp = {};
    var EnterpriseID = sessionStorage.getItem("EnterpriseID");
    if (EnterpriseID != undefined && store.getState().CurrentFilter.ClientData != undefined) {
      axiosInstance
        .get(`${LocalApiBaseUrl}Deal/GetDeals`, {
          params: { FeatureName: "Tool Configurator", OrgEntityID: store.getState().CurrentFilter.ClientData.id },
        })
        .then((response) => {
          let ConnectorData = this.state.ConnectorData;
          ConnectorData.DealID = response.data.filter(
            (f) => f.IsActive == true && f.OrgEntityID == store.getState().CurrentFilter.ClientData.id
          )[0].DealID;

          this.setState({
            DEIDealOptions: response.data,
            ConnectorData: ConnectorData,
          });
          this.getOneTimeData();
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  }

  onDEIDealChange(event) {
    this.setState({ errors: {}, rowSelected: [], rowData: [] });
    let ConnectorData = this.state.ConnectorData;
    ConnectorData.DealID = event.target.value;
    this.setState({
      ConnectorData: ConnectorData,
    });

    if (this.state.ConnectorData.DealID > 0 && this.state.ConnectorData.sourceConnectorID > 0) {
      this.getOneTimeData();
    }
  }
  getOneTimeData() {
    if (this.state.ConnectorData.DealID > 0 && this.state.ConnectorData.sourceConnectorID > 0) {
      this.setState({ isAPILoading: true });

      axiosInstance
        .get(`${LocalApiBaseUrl}ConnectorMapping/GetHMData`, {
          params: { dealId: this.state.ConnectorData.DealID, sourceId: this.state.ConnectorData.sourceConnectorID },
        })
        .then((response) => {
          this.setState({
            rowData: response.data,
            isAPILoading: false,
          });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          this.setState({ isAPILoading: false });
        });
    }
  }
  handleSave(data) {
    axiosInstance.post(`${LocalApiBaseUrl}ConnectorMapping/AddEditHMSourceDetails`, data).then(
      (response) => {
        window.alert(response.data.StatusMessage);
        if (this.state.ConnectorData.DealID > 0 && this.state.ConnectorData.sourceConnectorID > 0) {
          this.getOneTimeData();
        }
      },
      (error) => {
        trycatchAlertPopup(error)
      }
    )
      .catch((error) => {
        trycatchAlertPopup(error);
      });

  }

  onClose(e) {
    this.setState({ viewmore: false });
  }
  deleteProfile(e, data) {
    if (
      this.props.featureData.filter((data) => data.Submenu == "Tool Configurator" && data.Operations.includes("Delete"))
        .length > 0
    ) {
      if (window.confirm("Are you sure you want to delete the item?")) {
        axiosInstance
          .delete(`${LocalApiBaseUrl}ConnectorMapping/DisableHMData`, {
            params: { dealId: this.state.ConnectorData.DealID, sourceId: this.state.ConnectorData.sourceConnectorID },
          })
          .then((response) => {
            window.alert(response.data.StatusMessage);
            this.getOneTimeData();
          })
          .catch((error) => {
            trycatchAlertPopup(error);
          });
      }
    } else window.alert(" UnAuthorised Access Denied !!");
  }
  connectorpopup(e, data) {
    if (data != undefined) {
      // edit condition
      if (store.getState().CurrentFilter.ClientData != undefined) {
        e.preventDefault();
        let rowSelected = JSON.parse(JSON.stringify(data));
        rowSelected.sourceClientName = rowSelected.sourceClientName.split("|");
        rowSelected.sourceOfferingName = rowSelected.sourceOfferingName.split("|");
        rowSelected.sourceSubOfferingName = rowSelected.sourceSubOfferingName.split("|");
        rowSelected.sourceLocationName = rowSelected.sourceLocationName.split("|");
        this.setState({ viewmore: true, rowSelected: rowSelected });
      } else window.alert("Client selection is mandatory !!");
    } else {
      // add condition
      if (store.getState().CurrentFilter.ClientData != undefined) {
        e.preventDefault();
        this.setState({ viewmore: true, rowSelected: data });
      } else window.alert("Client selection is mandatory !!");
    }
  }
  onSourceChange(event) {
    debugger;
    let ConnectorData = this.state.ConnectorData;
    ConnectorData.sourceConnectorID = event.target.value;
    var index = event.nativeEvent.target.selectedIndex;
    ConnectorData.sourceConnectorName = event.nativeEvent.target[index].text;

    this.setState({
      ConnectorData: ConnectorData,
    });
    if (this.state.ConnectorData.DealID > 0 && this.state.ConnectorData.sourceConnectorID > 0) {
      this.getOneTimeData();
    }
  }
  onSourceClick(type, event) {
    debugger;
    let ConnectorData = this.state.ConnectorData;
    ConnectorData.sourceConnectorID = event.target.id;
    ConnectorData.sourceConnectorName = event.target.innerHTML;
    this.setState({
      ConnectorData: ConnectorData,
    });
    this.setKey("parameter");
    // if (this.state.ConnectorData.DealID > 0 && this.state.ConnectorData.sourceConnectorID > 0) {
    //     this.getOneTimeData();
    // }
  }
  setKey(SelectedTab) {
    let ConnectorData = this.state.ConnectorData;
    // ConnectorData.DealID = 0;

    if (SelectedTab == "source") {
      ConnectorData.sourceConnectorID = 12;
      ConnectorData.sourceConnectorName = "SQF";
      this.setState({
        rowSelected: [],
        rowData: [],
        ConnectorData: ConnectorData,
        SelectedTab: SelectedTab,
      });
    }

    if (SelectedTab == "parameter") {
      this.setState({
        rowSelected: [],
        rowData: [],
        SelectedTab: SelectedTab,
      });
      if (this.state.ConnectorData.DealID > 0 && this.state.ConnectorData.sourceConnectorID > 0) {
        this.getOneTimeData();
      }
    }
  }

  render() {
    const { isAPILoading } = this.state;

    return (
      <div className="main-data">
        {
          !this.state.userAccessDenied ?
            <div className="hmConfigList">
              {isAPILoading && <LoadingOverlay fadeSpeed={0}
                spinner={<SynopsLoader />}
                active
              // text="Please wait..." 
              />}
              <Tabs
                defaultActiveKey="source"
                activeKey={this.state.SelectedTab}
                onSelect={(k) => this.setKey(k)}
                transition={false}
                id="noanim-tab-example"
              >
                <Tab eventKey="source" title="Source">
                  <div className="hmConfigTab">
                    <div className="hmConfigHead">Configure Source Parameter </div>
                    <ul>
                      {/* <li>
                        <p id="13" onClick={(e) => this.onSourceClick(this.id, e)}>
                        IWGPHDataLake{" "}
                        </p>
                      </li> */}
                      <li>
                        <p id="12" onClick={(e) => this.onSourceClick(this.id, e)}>
                          SQF
                        </p>
                      </li>
                      <li>
                        <p id="3" onClick={(e) => this.onSourceClick(this.id, e)}>
                          Digitracker
                        </p>
                      </li>
                      <li>
                        <p id="11" onClick={(e) => this.onSourceClick(this.id, e)}>
                          ACC
                        </p>
                      </li>
                    </ul>
                  </div>
                </Tab>
                <Tab eventKey="parameter" title="Parameter">
                  <div className="hmContent">
                    <div className="form-group col-lg-3">
                      <label>
                        Deal <span className="required">*</span>
                      </label>
                      <select
                        id="OperatingGroup"
                        className="form-control"
                        ref="OperatingGroupDropDown"
                        onChange={this.onDEIDealChange.bind(this)}
                        value={this.state.ConnectorData.DealID}
                      >
                        <option value="0">Select Deal</option>
                        {this.state.DEIDealOptions.map((row) => {
                          return (
                            <option value={row.DealID}>
                              {row.ClientName + "  -  " + row.Process + "  -  " + row.City}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="hmParameter">
                      <div className="parameterSelect">
                        <select
                          onChange={this.onSourceChange.bind(this)}
                          value={this.state.ConnectorData.sourceConnectorID}
                        >
                         {/*  <option value="13">IWGPHDataLake</option> */}
                          <option value="12">SQF</option>
                          <option value="3">Digitracker</option>
                          <option value="11">ACC</option>
                        </select>
                      </div>
                      {this.props.featureData.filter(
                        (data) => data.Submenu == "Tool Configurator" && data.Operations.includes("Add")
                      ).length > 0 && (
                          <div className="parameterIcon">
                            <span>
                              <a
                                onClick={(e) => {
                                  this.connectorpopup(e);
                                }}
                              >
                                {" "}
                                <FontAwesomeIcon className="plusIcon" icon={faPlus} />
                                Add Parameter Mapping
                              </a>
                            </span>
                          </div>
                        )}
                    </div>

                    <div>
                      {this.state.rowData.map((each, index) => {
                        return (
                          <div class="parametersList">
                            <div class="list-params">
                              <ul class="main-list">
                                <li>{each.destinationLocationName}</li>
                                <li>{each.destinationOfferingName} </li>
                                <li>{each.destinationSubOfferingName} </li>
                              </ul>
                              <ul class="sub-list">
                                <li>
                                  {each.sourceConnectorName} -{" "}
                                  {each.sourceLocationName
                                    ? each.sourceLocationName.replace(/,[s]*/g, ", ")
                                    : each.destinationLocationName}{" "}
                                  |{" "}
                                  {each.sourceOfferingName
                                    ? each.sourceOfferingName.replace(/,[s]*/g, ", ")
                                    : each.destinationOfferingName}{" "}
                                  |{" "}
                                  {each.sourceSubOfferingName
                                    ? each.sourceSubOfferingName.replace(/,[s]*/g, ", ")
                                    : each.destinationSubOfferingName}{" "}
                                </li>
                              </ul>
                            </div>
                            <div class="params-edit-delete">
                              {this.props.featureData.filter(
                                (data) => data.Submenu == "Tool Configurator" && data.Operations.includes("Edit")
                              ).length > 0 && (
                                  <span
                                    onClick={(e) => {
                                      this.connectorpopup(e, each);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </span>
                                )}
                              {this.props.featureData.filter(
                                (data) => data.Submenu == "Tool Configurator" && data.Operations.includes("Delete")
                              ).length > 0 && (
                                  <span
                                    onClick={(e) => {
                                      this.deleteProfile(e, each);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </span>
                                )}
                            </div>
                          </div>
                        );
                      })}

                      {this.state.rowData.length == 0 && this.state.ConnectorData.DealID > 0 && (
                        <div className="hmNoParameter">
                          <div>
                            <b>There is no parameter mapping yet</b>
                          </div>
                          <div>
                            <span>
                              Map {this.state.ConnectorData.sourceConnectorName} parameters with associated SynOps
                              parameters for automating metric data
                            </span>
                          </div>
                          {this.props.featureData.filter(
                            (data) => data.Submenu == "Tool Configurator" && data.Operations.includes("Add")
                          ).length > 0 && (
                              <div>
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    this.connectorpopup(e);
                                  }}
                                >
                                  Add Parameter Mapping
                                </a>
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
            :
            <UserAccessDeniedScreen />
        }
        {this.state.viewmore ? (
          <ToolConfiguratorPopup
            data={this.state.rowSelected}
            ConnectorData={this.state.ConnectorData}
            show={this.state.viewmore}
            onClose={this.onClose}
            filterDetails={this.props.filterDetails}
            onSave={this.handleSave}
          />
        ) : null}
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    userAccessDeniedScreen: state.roleReducers.userDeniedScreen,
  };
};
export default connect(mapStateToProps)(ToolConfiguratorList);