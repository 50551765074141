import React from "react";
import { Modal, Button } from "react-bootstrap";
import { LocalApiBaseUrl } from '../Shared/Constant';
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { regexForConfMasterFields } from "../Shared/Constant";
import { regexForTextAreas } from "../Shared/Constant";
import i18n from "i18next";
import { connect } from "react-redux";

axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


class ClientPopup extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      show: false,
      apivalue: "",
      selectValue: this.props.SelectValue,
      Display: false,
      LanguageDropDown: [],
      disableSave: true,
      disableApprove: false,
      Language: "",
      LocationData: [],     //change the name 


      Client: {
        ClientIDs: [],
        OrgEntityID: "0",
        OrgEntityName: "",
        OrgEntityImage: null,
        RequestorRemarks: "",
        ApproverRemarks: "",
        ApprovalAction: true,
        ApproveType: "",
        IsActive: false,
        CreatedUser: "admin",
        CreatedTS: new Date(),
        UpdatedUser: "admin",
        UpdatedTS: new Date()
      },
      errors: [],
      formIsValid: false
    };

    this.onClientChange = this.onClientChange.bind(this);
    this.onApproverRemarksChange = this.onApproverRemarksChange.bind(this);
    this.onRequestorRemarksChange = this.onRequestorRemarksChange.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
  }

  onClientChange(event) {
    let errors = {};
    this.setState({
      errors: errors
    });
    var Client = { ...this.state.Client };
    // var Client = this.state.Client;
    Client.OrgEntityName = event.target.value;
    let loc = this.state.LocationData.translatedClientApproval.filter((e) => { if (e.languageId === parseInt(this.state.Language)) return e })

    this.setState({
      Client: Client,
      disableApprove: loc[0].OrgEntityName === event.target.value && loc[0].RequestorRemarks === Client.RequestorRemarks ? false : true,
      disableSave: loc[0].OrgEntityName !== event.target.value || loc[0].RequestorRemarks !== Client.RequestorRemarks ? false : true
    })


    // this.validateForm();
  }
  onApproverRemarksChange(event) {

    if (this.props.type == "Approve") {
      let soCatAtt = null;
      let errors = {};
      this.setState({
        errors: errors
      });
      var Client = this.state.Client;
      Client.ApproverRemarks = event.target.value;
      Client.ApprovalAction = true;
      // Client.ClientIDs=this.state.Client;
      this.setState({
        Client: Client
      });
    } else {
      let soCatAtt = null;
      let errors = {};
      this.setState({
        errors: errors
      });
      var Client = this.state.Client;
      Client.ApproverRemarks = event.target.value;
      Client.ApprovalAction = false;
      // Client.ClientIDs=this.state.Client;
      this.setState({
        Client: Client
      });
    }
  }
  onRequestorRemarksChange(event) {


    let errors = {};
    this.setState({
      errors: errors
    });
    var Client = { ...this.state.Client };
    Client.RequestorRemarks = event.target.value;
    let loc = this.state.LocationData.translatedClientApproval.filter((e) => { if (e.languageId === parseInt(this.state.Language)) return e })

    this.setState({
      Client: Client,
      disableApprove: loc[0].City === Client.City && loc[0].RequestorRemarks === event.target.value ? false : true,
      disableSave: loc[0].City !== Client.City || loc[0].RequestorRemarks !== event.target.value ? false : true
    })


  }
  onStatusChange(event) {

    const Client = this.state.Client;
    Client.IsActive = event.target.checked;
    this.setState({
      Client: Client
    });
  }
  handleSave(e) {

    if (this.validateForm()) {
      this.props.onSave(this.state.Client);

      this.handleHide();
    }
  }
  handleHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
        // this.props.onClose("from x");
      }
    } else {
      this.props.onClose(e);
    }
    this.props.onSave();
  }
  handleRejectHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }
  passToConfirmation(data) {
    this.props.enteredData(data.PoNumber);
  }

  componentDidMount() {

    this.setState({
      show: this.props.show
    });
  }

  validateForm() {
    const Client = this.state.Client;
    let errors = {};
    let formIsValid = true;

    if (Client["ApproverRemarks"] == null) {
      formIsValid = false;
      errors["ApproverRemarks"] = i18n.t("Approver_Remarks_are_required");
    } else if (!Client["ApproverRemarks"].trim()) {
      formIsValid = false;
      errors["ApproverRemarks"] = i18n.t("Approver_Remarks_are_required");
    } else {
      var isValid = regexForTextAreas.test(Client["ApproverRemarks"]);
      if (!isValid) {
        formIsValid = false;
        errors["ApproverRemarks"] = i18n.t("Approver_Remarks_contains_Special_characters");
      }
    }

    this.setState({
      errors: errors
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true
      });
    } else {
      this.setState({
        formIsValid: false
      });
    }
    return formIsValid;
  }

  componentWillMount() {
    let langID = this.props.languageData.id;

    if (this.props.data) {
      let data = this.props.data[0];
      let loc = data.translatedClientApproval.filter((e) => { if (e.languageId === langID) return e })
      if (loc.length === 0) {
        loc = data.translatedClientApproval.filter((e) => { if (e.languageId === 1) return e })
        langID = 1;
      }
      let languageData = data.languageMaster;
      this.setState({
        LanguageDropDown: languageData,
        LocationData: data,
        Language: langID,
        Client: loc[0],
      })
    }
  }

  onLanguageChange = (event) => {
    let value = event.target.options[event.target.selectedIndex].value;
    let data = this.state.LocationData;
    let loc = data.translatedClientApproval.filter((e) => { if (e.languageId === parseInt(value)) return e })
    this.setState({ Language: value, Client: loc[0], disableApprove: false, disableSave: true })
  }

  saveData = () => {
    let data = this.state.Client;
    axiosInstance.post(`${LocalApiBaseUrl}ClientApproval/PostClientAfterApproval?languageId=${this.state.Language}&status=Save`, data)
      .then((response) => {
        if (response.data.IsSuccess) {
          window.alert(i18n.t("Data_updated_successfully"));
          this.props.savePopupData(data)
          this.setState({ disableApprove: false, disableSave: true })
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

  }



  render() {
    return (
      <>
        {/* <Header /> */}
        <Modal
          show={this.state.show}
          onHide={this.handleHide.bind(this)}
          className="modal fade"
          size="medium"
          id="dealpopup"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title" id="dealprofile" flag >{i18n.t("Approval")}</h4>
          </Modal.Header>


          <Modal.Body>
            <form>
              <div className="form-section">
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label for="">{i18n.t("Client_Name")}<span className="required">*</span></label>

                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      onChange={this.onClientChange}
                      value={this.state.Client.OrgEntityName}
                    />
                    <span className="errorMsg">
                      {this.state.errors.OrgEntityName}
                    </span>
                  </div>

                  <div className="form-group col-lg-6">
                    <label for="">{i18n.t("Language")}<span className="required">*</span></label>
                    <select name="language" className="form-control" value={this.state.Language} onChange={this.onLanguageChange}>
                      {this.state.LanguageDropDown.length ? this.state.LanguageDropDown.map((each) => {
                        return <option key={each.languageId} value={each.languageId}>{each.languageDescription}</option>
                      }) : ""}
                    </select>
                    <span className="errorMsg">{this.state.errors.City}</span>
                  </div>


                  <div className="form-group col-lg-6">
                    <label for="">{i18n.t("Requestor_Remarks")}<span className="required">*</span>
                    </label>

                    <textarea
                      className="form-control"
                      id="name"
                      onChange={this.onRequestorRemarksChange}
                      value={this.state.Client.RequestorRemarks}

                    />
                    <span className="errorMsg">
                      {this.state.errors.RequestorRemarks}
                    </span>
                  </div>
                  <div className="form-group col-lg-6">
                    <label for="">
                      {i18n.t("Approver_Remarks")}<span className="required">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      id="name"
                      onChange={this.onApproverRemarksChange}
                      value={this.state.Client.ApproverRemarks}
                    />
                    <span className="errorMsg">
                      {this.state.errors.ApproverRemarks}
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">

                {this.props.data ?
                  <input type="Button" disabled={this.state.disableSave} className="btn btn-primary btn-sm" onClick={() => this.saveData()} value={i18n.t("Save")} /> : ""
                }

                <span className="pr-3"></span>
                {!this.props.data ?
                  <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value={i18n.t("Create")} />
                  : <input type="Button" disabled={this.state.disableApprove} className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value={this.props.type === "Approve" ? i18n.t("Approve") : i18n.t("Decline")} />
                }
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return ({
    languageData: state.CurrentFilter.languageData
  })
}

export default connect(mapStateToProps)(ClientPopup);