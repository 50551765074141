/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState, useEffect, useRef, useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import {
  Dropdown, Button, Overlay, Popover,
} from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import ReactTooltip from 'react-tooltip';
import i18n from 'i18next';
import moment from 'moment';
import classNames from 'classnames';
import $ from 'jquery';

// IMPORT CUSTOM COMPONENTS
import SynopsLoader from '../Shared/SynopsLoader';
import CustomMultiSelect from '../CommonComponents/CustomMultiSelect';
import InitiativeDetails from './InitiativeDetails';

// IMPORT CONSTANTS/FUNCTIONS
import { trycatchAlertPopup, LocalApiBaseUrl } from '../Shared/Constant';
import axiosInstance from '../Shared/interceptor';
import { InitializeInitiative, InitializeInitiativedatas } from '../actions/JourneyActions';
import { store } from '../reducers/configureStore';

function JourneyGanttChart(props) {
  const {
    onHide, languageId, clientData, syncFilter, history,
  } = props;

  const dateFormat = 'DD MMM, YYYY';
  const dateFormatForDatepickers = 'MMM, YYYY';
  const monthFormatForChartTimeline = 'MMM-YY';
  const yearFormatForChartTimeline = 'YYYY';
  const pxAdjustmentForEndDateIcon = 13; // THE END DATE ICON HAS TO BE SHIFTED BY THESE MANY PIXELS TO BRING IT INSIDE THE BAR
  const barMinWidth = 28; // THIS IS THE MINIMUM WIDTH OF THE BAR THAT HAS TO BE PLOTTED IF THE ACTUAL CALCULATED WIDTH IS TOO SMALL
  const chartConfig = [
    {
      minResolution: 1,
      maxResolution: 1279,
      maxLabelsToShowInView: 7,
    },
    {
      minResolution: 1280,
      maxResolution: 1366,
      maxLabelsToShowInView: 7,
    },
    {
      minResolution: 1367,
      maxResolution: 1440,
      maxLabelsToShowInView: 8,
    },
    {
      minResolution: 1441,
      maxResolution: 1600,
      maxLabelsToShowInView: 9,
    },
  ];

  const ganttChartMiddleContentRef = useRef(null);
  const startDatepickerRef = useRef(null);
  const endDatepickerRef = useRef(null);
  const datepickerDropdownPopoverTarget = useRef(null);

  const OrgEntityID = clientData.id;
  const OfferingID = syncFilter.ProcessId === 'All' ? 0 : syncFilter.ProcessId;
  const OfferingName = _.isEmpty(syncFilter.OfferingName) ? 'All' : syncFilter.OfferingName;
  const LocationID = syncFilter.LocationId === 'All' ? 0 : syncFilter.LocationId;
  const OMID = syncFilter.LocationOMID[0] === 'All' ? 0 : syncFilter.LocationOMID[0];
  const IsConfigurator = history.location.pathname === '/admin/Configurator';

  const [isAPILoading, setAPILoading] = useState(false);

  const [selectedYearlyOrMonthlyFilterValue, setSelectedYearlyOrMonthlyFilterValue] = useState('yearly');// 'yearly' or 'monthly'

  // "Stage" DROPDOWN DATA
  const [
    stageDropdownData,
    setStageDropdownData,
  ] = useState([]);

  const [isVisibleStageDropdown, setVisibleStageDropdown] = useState(false);

  // "Status" DROPDOWN DATA
  const [
    statusDropdownData,
    setStatusDropdownData,
  ] = useState([]);

  const [isVisibleStatusDropdown, setVisibleStatusDropdown] = useState(false);

  // "Initiatives" DATA ARRAY
  const [
    initiativesArray,
    setInitiativesArray,
  ] = useState([]);

  const [timelineLabelsArray, setTimelineLabelsArray] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [formattedStartDate, setFormattedStartDate] = useState('');
  const [tempStartDate, setTempStartDate] = useState(null);
  const [formattedTempStartDate, setFormattedTempStartDate] = useState('');
  const [endDate, setEndDate] = useState(null);
  const [formattedEndDate, setFormattedEndDate] = useState('');
  const [tempEndDate, setTempEndDate] = useState(null);
  const [formattedTempEndDate, setFormattedTempEndDate] = useState('');
  const [isVisibleDatepickerPopover, setVisibleDatepickerPopover] = useState(false);
  const [dateSectionVisibleInDatepickerPopover, setDateSectionVisibleInDatepickerPopover] = useState('startDate');
  const [isEnabledUpdateButtonInDatepickerPopover, setEnabledUpdateButtonInDatepickerPopover] = useState(false);
  const [selectedInitiative, setSelectedInitiative] = useState({});

  const [chartMiddleContentWidth, setChartMiddleContentWidth] = useState(0);
  const emptyConfigForCalculation = {
    wrapperWidth: 0,
    pxWidthBetweenTwoTimelineLabelsOnChart: 78,
    classNameForHeadRow: '',
  };
  const [configForCalculation, setConfigForCalculation] = useState(emptyConfigForCalculation);

  useEffect(() => {
    $(window).on('resize', handleResize);
    return (() => {
      $(window).off('resize');
    });
  }, []);

  useLayoutEffect(() => {
    showHideLeftAndRightArrows();
  });

  useEffect(() => {
    fetchAll();
  }, [syncFilter]);

  // PREPARE THE ARRAY FOR TIMELINE LABELS BASED ON THE 'YEARLY/MONTHLY' DROPDOWN VALUE SELECTED
  useEffect(() => {
    if (!_.isEmpty(initiativesArray)) {
      let filteredInitiativesArray = initiativesArray;
      if (!_.isEmpty(startDate) && !_.isEmpty(endDate)) {
        filteredInitiativesArray = getFilteredInitiativesBasedOnStartAndEndDate(
          filteredInitiativesArray,
          startDate,
          endDate,
        );
      }

      const allDatesMomentArray = [];
      _.forEach(filteredInitiativesArray, (eachInitiative) => {
        if (!_.isEmpty(eachInitiative.startDateMoment)) {
          allDatesMomentArray.push(eachInitiative.startDateMoment);
        }

        if (!_.isEmpty(eachInitiative.endDateMoment)) {
          allDatesMomentArray.push(eachInitiative.endDateMoment);
        }

        if (!_.isEmpty(eachInitiative.revisedEndDateMoment)) {
          allDatesMomentArray.push(eachInitiative.revisedEndDateMoment);
        }
      });

      if (!_.isEmpty(allDatesMomentArray)) {
        let timelineArr = [];
        const timelineYearsArray = [];
        const timelineMonthsArray = [];

        // SORT THE ARRAY OF MOMENTS IN ASCENDING ORDER
        const sortedAllDatesMomentArray = allDatesMomentArray.sort((a, b) => a.diff(b));

        const minDateMoment = _.head(sortedAllDatesMomentArray);
        const maxDateMoment = _.last(sortedAllDatesMomentArray);

        if (selectedYearlyOrMonthlyFilterValue === 'yearly') {
          const minYear = parseInt(minDateMoment.format(yearFormatForChartTimeline), 10);
          const maxYear = parseInt(maxDateMoment.format(yearFormatForChartTimeline), 10);
          for (let i = minYear; i <= maxYear + 1; i += 1) {
            timelineYearsArray.push(i);
          }

          timelineArr = timelineYearsArray;
        } else if (selectedYearlyOrMonthlyFilterValue === 'monthly') {
          const clonedMinDateMoment = _.cloneDeep(minDateMoment);
          const clonedMaxDateMoment = _.cloneDeep(maxDateMoment).add(1, 'month');

          // PREPARE AN ARRAY OF MONTHS STARTING FROM THE START MONTH TO THE END MONTH
          while (clonedMinDateMoment.isSameOrBefore(clonedMaxDateMoment)) {
            timelineMonthsArray.push(clonedMinDateMoment.format(monthFormatForChartTimeline));
            clonedMinDateMoment.add(1, 'month');
          }

          timelineArr = timelineMonthsArray;
        }
        setTimelineLabelsArray(timelineArr);
      }
      // RESET THE SCROLL POSITION OF THE CHART MIDDLE CONTENT AREA (WHERE THE BARS ARE PLOTTED)
      resetScrollPositionOfChartMiddleContent();
    }
  }, [initiativesArray, selectedYearlyOrMonthlyFilterValue, startDate, endDate]);

  useEffect(() => {
    let isEnabled = false;

    if (!_.isEmpty(tempStartDate) && !_.isEmpty(tempEndDate)) {
      isEnabled = true;
    }
    setEnabledUpdateButtonInDatepickerPopover(isEnabled);
  }, [tempStartDate, tempEndDate]);

  useEffect(() => {
    handleResize();
    $('#gantt-middle-content').scroll(() => showHideLeftAndRightArrows());
  }, [ganttChartMiddleContentRef.current]);

  useEffect(() => {
    if (chartMiddleContentWidth > 0 && !_.isEmpty(timelineLabelsArray)) {
      const chartConfigForCurrentResolution = _.find(
        chartConfig,
        (eachConfig) => screen.width >= eachConfig.minResolution && screen.width <= eachConfig.maxResolution,
      );

      const maxTimelineLabelsToShowForCurrentResolution = chartConfigForCurrentResolution.maxLabelsToShowInView;
      const countOfTimelineLabels = _.size(timelineLabelsArray);
      let calcConfig = {
        ...emptyConfigForCalculation,
        wrapperWidth: countOfTimelineLabels * emptyConfigForCalculation.pxWidthBetweenTwoTimelineLabelsOnChart,
      };

      // WHEN THE COUNT OF TOTAL TIMELINE LABELS IS LESS THAN THE NO. OF LABELS TO SHOW FOR THE CURRENT SCREEN RESOLUTION THEN DO THE CALCULATION TO STRETCH THE LABELS WIDTH TO OCCUPY THE SPACE EVENLY
      if (countOfTimelineLabels <= maxTimelineLabelsToShowForCurrentResolution) {
        calcConfig = {
          wrapperWidth: chartMiddleContentWidth,
          pxWidthBetweenTwoTimelineLabelsOnChart: _.toNumber(
            parseFloat((chartMiddleContentWidth) / countOfTimelineLabels),
          ),
          classNameForHeadRow: 'flexible-width',
        };
      }
      setConfigForCalculation(calcConfig);
    }
  }, [chartMiddleContentWidth, timelineLabelsArray]);

  function showHideLeftAndRightArrows() {
    let scrollPosition = 0;
    if (!_.isNil($('#gantt-middle-content'))) {
      scrollPosition = $('#gantt-middle-content').scrollLeft();
    }

    let scrollableAreaWidth = 0;
    if (!_.isNil($('#gantt-middle-wrapper'))) {
      scrollableAreaWidth = $('#gantt-middle-wrapper').width();
    }
    let middleContentWidth = 0;

    if (!_.isNil(ganttChartMiddleContentRef.current)) {
      middleContentWidth = ganttChartMiddleContentRef.current.offsetWidth;
    }



    // THIS IS THE LOGIC FOR SHOW/HIDE OF LEFT ARROW
    if (scrollPosition <= 16) {
      $('#slideLeft').hide();
    } else {
      $('#slideLeft').show();
    }

    // THIS IS THE LOGIC FOR SHOW/HIDE OF LEFT ARROW
    if ((scrollPosition + middleContentWidth) >= scrollableAreaWidth) {
      $('#slideRight').hide();
    } else {
      $('#slideRight').show();
    }
  }

  function resetScrollPositionOfChartMiddleContent() {
    $('#gantt-middle-content').scrollLeft(0);
  }

  function getDimensions() {

    return {
      screenWidth: screen.width,
      ganttChartMiddleContentWidth: ganttChartMiddleContentRef.current ? ganttChartMiddleContentRef.current.offsetWidth : 0,
    };
  }

  function handleResize() {
    const { ganttChartMiddleContentWidth } = getDimensions();
    setChartMiddleContentWidth(ganttChartMiddleContentWidth);
  }

  function slideRight() {
    ganttChartMiddleContentRef.current.scrollLeft += configForCalculation.pxWidthBetweenTwoTimelineLabelsOnChart;
  }

  function slideLeft() {
    ganttChartMiddleContentRef.current.scrollLeft -= configForCalculation.pxWidthBetweenTwoTimelineLabelsOnChart;
  }

  // //////////////////////////   FUNCTIONS FOR API CALLING   //////////////////////////////////////////////
  function fetchAll() {
    setAPILoading(true);
    Promise.all([
      fetchStageDropdownData(false),
      fetchStatusDropdownData(false),
      fetchInitiatives(false),
    ])
      .then(() => {
        setAPILoading(false);
      })
      .catch((error) => {
        setAPILoading(false);
        trycatchAlertPopup(error);
      });
  }

  // FETCH "Stage" DROPDOWN DATA
  function fetchStageDropdownData(manipulateAPILoader = false) {
    if (manipulateAPILoader) {
      setAPILoading(true);
    }

    return axiosInstance
      .get(
        `${LocalApiBaseUrl}IOJJourneyInitiative/GetJourneyStages?LanguageId=${languageId}`,
      )
      .then((response) => {
        if (manipulateAPILoader) {
          setAPILoading(false);
        }
        const responseData = response.data;

        if (!_.isEmpty(responseData)) {
          const mappedDataForDropdown = _.map(responseData, (eachItem) => ({
            label: eachItem.IOJStageName,
            label_en: eachItem.IOJStageName_En,
            value: String(eachItem.IOJStageID),
            checked: true,
          }));
          setStageDropdownData(mappedDataForDropdown);
        }
      })
      .catch((error) => {
        if (manipulateAPILoader) {
          setAPILoading(false);
        }
        trycatchAlertPopup(error);
      });
  }

  // FETCH "Initiative Status" DROPDOWN DATA DISPLAYED IN "initiativeDetails" STEP
  function fetchStatusDropdownData(manipulateAPILoader = false) {
    if (manipulateAPILoader) {
      setAPILoading(true);
    }

    return axiosInstance
      .get(
        `${LocalApiBaseUrl}IOJJourneyInitiative/GetInitiativeStatusList?LanguageId=${languageId}`,
      )
      .then((response) => {
        if (manipulateAPILoader) {
          setAPILoading(false);
        }
        const responseData = response.data;

        if (!_.isEmpty(responseData)) {
          const mappedDataForDropdown = _.map(responseData, (eachItem) => ({
            label: eachItem.StatusName,
            label_en: eachItem.StatusName_En,
            value: String(eachItem.ID),
            checked: true,
          }));
          setStatusDropdownData(mappedDataForDropdown);
        }
      })
      .catch((error) => {
        if (manipulateAPILoader) {
          setAPILoading(false);
        }
        trycatchAlertPopup(error);
      });
  }

  // FETCH "Initiatives" DATA
  function fetchInitiatives(manipulateAPILoader = false) {
    if (manipulateAPILoader) {
      setAPILoading(true);
    }

    const reqData = {
      OrgEntityID,
      OfferingID,
      OfferingName,
      LocationID,
      OMID,
      IsConfigurator,
    };

    return axiosInstance
      .post(
        `${LocalApiBaseUrl}IOJJourneyInitiative/GetGanttChartDetails`, reqData,
      )
      .then((response) => {
        if (manipulateAPILoader) {
          setAPILoading(false);
        }

        const responseData = response.data;

        if (!_.isEmpty(responseData)) {
          let mappedInitiativesArray = _.filter(
            responseData,
            (eachItem) => !eachItem.IsHidden && !eachItem.IsDraft,
          );
          mappedInitiativesArray = _.map(mappedInitiativesArray, (eachItem) => {
            const plannedStartDateMoment = !_.isEmpty(eachItem.PlannedStartDate) ? moment(eachItem.PlannedStartDate) : null;
            const formattedPlannedStartDate = !_.isEmpty(plannedStartDateMoment)
              ? moment(plannedStartDateMoment).format(dateFormat)
              : '';

            const actualStartDateMoment = !_.isEmpty(eachItem.ActualStartDate) ? moment(eachItem.ActualStartDate) : null;
            const formattedActualStartDate = !_.isEmpty(actualStartDateMoment)
              ? moment(actualStartDateMoment).format(dateFormat)
              : '';

            const plannedEndDateMoment = !_.isEmpty(eachItem.PlannedEndDate) ? moment(eachItem.PlannedEndDate) : null;
            const formattedPlannedEndDate = !_.isEmpty(plannedEndDateMoment)
              ? moment(plannedEndDateMoment).format(dateFormat)
              : '';

            const revisedEndDateMoment = !_.isEmpty(eachItem.RevisedEndDate) ? moment(eachItem.RevisedEndDate) : null;
            const formattedRevisedEndDate = !_.isEmpty(revisedEndDateMoment) ? moment(revisedEndDateMoment).format(dateFormat) : '';

            const actualEndDateMoment = !_.isEmpty(eachItem.ActualEndDate) ? moment(eachItem.ActualEndDate) : null;
            const formattedActualEndDate = !_.isEmpty(actualEndDateMoment) ? moment(actualEndDateMoment).format(dateFormat) : '';

            // THE DEFAULT START DATE TO BE CONSIDERED FOR THE INITIATIVE IS 'PLANNED START DATE'
            let startDateLabel = i18n.t('Planned_Start_Date');
            let startDateMoment = plannedStartDateMoment;
            let formattedStartDateOfInitiative = formattedPlannedStartDate;
            // IF THE 'ACTUAL START DATE' IS NOT EMPTY THEN CONSIDER THE 'ACTUAL START DATE'
            if (!_.isEmpty(actualStartDateMoment)) {
              startDateLabel = 'Actual Start Date';
              startDateMoment = actualStartDateMoment;
              formattedStartDateOfInitiative = formattedActualStartDate;
            }

            // THE DEFAULT END DATE TO BE CONSIDERED FOR THE INITIATIVE IS 'PLANNED END DATE'
            let endDateLabel = i18n.t('Planned_End_Date');
            let endDateMoment = plannedEndDateMoment;
            let formattedEndDateOfInitiative = formattedPlannedEndDate;
            // IF THE 'ACTUAL END DATE' IS NOT EMPTY THEN CONSIDER THE 'ACTUAL END DATE'
            if (!_.isEmpty(actualEndDateMoment)) {
              endDateLabel = i18n.t('Actual_End_Date');
              endDateMoment = actualEndDateMoment;
              formattedEndDateOfInitiative = formattedActualEndDate;
            }

            const initiativeName = eachItem.Name;
            const maxCharLimitForTruncateForName = 28;
            const truncatedName = (initiativeName.length > maxCharLimitForTruncateForName)
              ? _.truncate(initiativeName, { length: maxCharLimitForTruncateForName }) : '';
            return {
              ...eachItem,
              IntelOpsInitiativesID: eachItem.ID,
              id: eachItem.ID,
              name: initiativeName,
              truncatedName,
              rag: eachItem.RAG,
              initiativeStatusId: eachItem.InitiativeStatusID,
              initiativeStatusLabel: eachItem.InitiativeStatusLabel,
              sequence: eachItem.Sequence,
              fteBenefit: eachItem.FTEBenefit,
              costBenefit: eachItem.CostBenefit,
              iojStageId: eachItem.IOJStageID,
              iojStageLabel: eachItem.IOJStageLabel,
              plannedStartDateMoment,
              formattedPlannedStartDate,
              actualStartDateMoment,
              formattedActualStartDate,
              plannedEndDateMoment,
              formattedPlannedEndDate,
              revisedEndDateMoment,
              formattedRevisedEndDate,
              actualEndDateMoment,
              formattedActualEndDate,
              startDateMoment,
              startDateLabel,
              endDateMoment,
              endDateLabel,
              formattedStartDate: formattedStartDateOfInitiative,
              formattedEndDate: formattedEndDateOfInitiative,
              updatedTSUnix: moment(eachItem.UpdatedTS).utc().valueOf(),
            };
          });

          mappedInitiativesArray = _.orderBy(mappedInitiativesArray, ['sequence', 'updatedTSUnix'], ['asc', 'desc']);

          setInitiativesArray(mappedInitiativesArray);
        }
      })
      .catch((error) => {
        if (manipulateAPILoader) {
          setAPILoading(false);
        }
        trycatchAlertPopup(error);
      });
  }

  function handleChangeTimelineDropdown(selectedOptionValue) {
    setSelectedYearlyOrMonthlyFilterValue(selectedOptionValue);
  }

  function handleChangeAllCheckboxInStatusDropdown(e) {
    const isChecked = e.target.checked;
    const clonedStatusDropdownData = _.cloneDeep(statusDropdownData);
    _.forEach(clonedStatusDropdownData, (eachItem, index) => {
      clonedStatusDropdownData[index].checked = isChecked;
    });
    setStatusDropdownData(clonedStatusDropdownData);
  }

  function handleChangeStatusDropdown(e, indx, selectedOptionObj) {
    const isSelectedOptionChecked = selectedOptionObj.checked;
    const clonedStatusDropdownData = _.cloneDeep(statusDropdownData);
    _.forEach(clonedStatusDropdownData, (eachItem, index) => {
      if (eachItem.value === selectedOptionObj.value) {
        clonedStatusDropdownData[index].checked = !isSelectedOptionChecked;
      }
    });
    setStatusDropdownData(clonedStatusDropdownData);
  }

  function handleChangeAllCheckboxInStageDropdown(e) {
    const isChecked = e.target.checked;
    const clonedStageDropdownData = _.cloneDeep(stageDropdownData);
    _.forEach(clonedStageDropdownData, (eachItem, index) => {
      clonedStageDropdownData[index].checked = isChecked;
    });
    setStageDropdownData(clonedStageDropdownData);
  }

  function handleChangeStageDropdown(e, indx, selectedOptionObj) {
    const isSelectedOptionChecked = selectedOptionObj.checked;
    const clonedStageDropdownData = _.cloneDeep(stageDropdownData);
    _.forEach(clonedStageDropdownData, (eachItem, index) => {
      if (eachItem.value === selectedOptionObj.value) {
        clonedStageDropdownData[index].checked = !isSelectedOptionChecked;
      }
    });
    setStageDropdownData(clonedStageDropdownData);
  }

  function handleChangeDateField(date, fieldName) {
    const dateInMoment = moment(date);
    const formattedDate = dateInMoment.format(dateFormatForDatepickers);

    if (fieldName === 'startDate') {
      setTempStartDate(dateInMoment);
      setFormattedTempStartDate(formattedDate);
      setDateSectionVisibleInDatepickerPopover('endDate');
    } else {
      const daysInMonth = _.toNumber(dateInMoment.daysInMonth());
      // FOR THE END DATE, MAKE IT AS THE END DATE OF THE SELECTED MONTH
      const newMoment = _.cloneDeep(dateInMoment).add(daysInMonth - 1, 'day');
      setTempEndDate(newMoment);
      setFormattedTempEndDate(formattedDate);
    }
  }

  function handleShowDatepickerPopover() {
    setTempStartDate(startDate);
    setTempEndDate(endDate);
    setFormattedTempStartDate(formattedStartDate);
    setFormattedTempEndDate(formattedEndDate);
    setDateSectionVisibleInDatepickerPopover('startDate');
  }

  function handleHideDatepickerPopover() {
    setVisibleDatepickerPopover(false);
  }

  function toggleDatepickerDropdownPopover() {
    setVisibleDatepickerPopover(!isVisibleDatepickerPopover);
  }

  function handleClearDates() {
    setStartDate(null);
    setEndDate(null);
    setFormattedStartDate('');
    setFormattedEndDate('');
    setTempStartDate(null);
    setTempEndDate(null);
    setFormattedTempStartDate('');
    setFormattedTempEndDate('');
    handleHideDatepickerPopover();
  }

  function handleUpdateDates() {
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
    setFormattedStartDate(formattedTempStartDate);
    setFormattedEndDate(formattedTempEndDate);
    handleHideDatepickerPopover();
  }

  function handleClickInitiative(selectedInitiativeObj) {
    const { IOJStageID } = selectedInitiativeObj;
    const reqData = {
      OrgEntityID,
      OfferingID,
      OfferingName,
      LocationID,
      OMID,
      IsConfigurator,
    };

    setAPILoading(true);
    axiosInstance
      .post(`${LocalApiBaseUrl}IOJJourneyInitiative/GetExpandedViewData?IOJStageID=${IOJStageID}&LanguageId=${languageId}`, reqData)
      .then((response) => {
        const responseData = response.data;
        if (!_.isEmpty(responseData)) {
          const foundInitiative = _.find(responseData, { IntelOpsInitiativesID: selectedInitiativeObj.id });
          store.dispatch(InitializeInitiative(responseData));
          const Datas = {
            viewData: foundInitiative,
            StageData: responseData,
          };
          store.dispatch(InitializeInitiativedatas(Datas));
          setAPILoading(false);
          setSelectedInitiative(selectedInitiativeObj);
        }
      })
      .catch((error) => {
        setAPILoading(false);
        trycatchAlertPopup(error);
      });
  }

  function getFilteredInitiativesBasedOnStartAndEndDate(initiativesArr, startDateMoment, endDateMoment) {
    const clonedEndDate = moment(_.cloneDeep(endDateMoment)).add(1, 'day');
    const filteredInitiativesArray = _.filter(
      initiativesArr,
      (eachInitiative) => moment(eachInitiative.plannedStartDateMoment).isSameOrAfter(startDateMoment)
      && moment(eachInitiative.plannedEndDateMoment).isBefore(clonedEndDate),
    );

    return filteredInitiativesArray;
  }

  // FOR THE THE TIMELINE LABELS, MARK THE START PX POSITION SO THAT THIS WOULD HELP IN PLOTTING THE BARS ON THE CHART
  const modifiedTimelineLabelsArr = _.map(timelineLabelsArray, (eachItem, index) => {
    const pxStartPosition = index * configForCalculation.pxWidthBetweenTwoTimelineLabelsOnChart;
    return {
      label: String(eachItem),
      pxStartPosition,
    };
  });

  let filteredInitiativesArray = initiativesArray;

  // FOR THE DEFAULT 'YEARLY' WISE TIMELINE, DIVIDE THE WIDTH BETWEEN EACH TIMELINE LABEL BY TOTAL NUMBER OF DAYS IN THE YEAR TO GET THE PIXEL WIDTH FOR A SINGLE DAY
  let valDayNumberDivider = 365;

  // DATE FORMAT TO BE USED TO FORMAT THE TIMELINE LABELS
  let dateFormatToBeUsed = yearFormatForChartTimeline;
  if (selectedYearlyOrMonthlyFilterValue === 'monthly') {
    dateFormatToBeUsed = monthFormatForChartTimeline;
  }

  const arrSelectedStatus = _.filter(statusDropdownData, { checked: true });
  const areAllSelectedStatusDropdownOptions = !_.isEmpty(statusDropdownData)
    && _.size(arrSelectedStatus) === _.size(statusDropdownData);

  let displayLabelStatusDropdown = i18n.t('Status');
  // LOGIC TO DISPLAY LABEL WHEN ALL OR SOME OPTIONS IN THE STATUS DROPDOWN ARE SELECTED
  if (areAllSelectedStatusDropdownOptions) {
    displayLabelStatusDropdown = i18n.t('All_Selected');
  } else if (!_.isEmpty(arrSelectedStatus)) {
    // WHEN SOME OPTIONS ARE SELECTED
    const selectedLabelsArray = _.map(arrSelectedStatus, 'label');

    const limitNoOfLabelsForDisplay = 2;
    const selectedLabelsArrayLength = _.size(selectedLabelsArray);
    if (selectedLabelsArrayLength > limitNoOfLabelsForDisplay) {
      const firstChunkOfSelectedLabels = _.chunk(selectedLabelsArray, limitNoOfLabelsForDisplay)[0];
      displayLabelStatusDropdown = `${_.join(
        firstChunkOfSelectedLabels,
        ', ',
      )},  +${selectedLabelsArrayLength - limitNoOfLabelsForDisplay}`;
    } else {
      displayLabelStatusDropdown = _.join(selectedLabelsArray, ', ');
    }
  }

  const arrSelectedStage = _.filter(stageDropdownData, { checked: true });
  const areAllSelectedStageDropdownOptions = !_.isEmpty(stageDropdownData)
  && _.size(arrSelectedStage) === _.size(stageDropdownData);

  let displayLabelStageDropdown = i18n.t('Stage');
  // LOGIC TO DISPLAY LABEL WHEN ALL OR SOME OPTIONS IN THE STATUS DROPDOWN ARE SELECTED
  if (areAllSelectedStageDropdownOptions) {
    displayLabelStageDropdown = i18n.t('All_Selected');
  } else if (!_.isEmpty(arrSelectedStage)) {
    // WHEN SOME OPTIONS ARE SELECTED
    const selectedLabelsArray = _.map(arrSelectedStage, 'label');

    const limitNoOfLabelsForDisplay = 2;
    const selectedLabelsArrayLength = _.size(selectedLabelsArray);
    if (selectedLabelsArrayLength > limitNoOfLabelsForDisplay) {
      const firstChunkOfSelectedLabels = _.chunk(selectedLabelsArray, limitNoOfLabelsForDisplay)[0];
      displayLabelStageDropdown = `${_.join(
        firstChunkOfSelectedLabels,
        ', ',
      )},  +${selectedLabelsArrayLength - limitNoOfLabelsForDisplay}`;
    } else {
      displayLabelStageDropdown = _.join(selectedLabelsArray, ', ');
    }
  }

  // FILTER INITIATIVES BASED ON SELECTED STATUS
  if (!_.isEmpty(arrSelectedStatus)) {
    const arrSelectedIds = _.map(arrSelectedStatus, 'value');
    filteredInitiativesArray = _.filter(
      filteredInitiativesArray,
      (eachInitiative) => _.indexOf(
        arrSelectedIds,
        String(eachInitiative.initiativeStatusId),
      ) !== -1,
    );
  } else {
    const arrUnSelectedIds = _.map(statusDropdownData, 'value');
    filteredInitiativesArray = _.filter(
      filteredInitiativesArray,
      (eachInitiative) => _.indexOf(
        arrUnSelectedIds,
        String(eachInitiative.initiativeStatusId),
      ) === -1,
    );
  }

  // FILTER INITIATIVES BASED ON SELECTED STAGES
  if (!_.isEmpty(arrSelectedStage)) {
    const arrSelectedIds = _.map(arrSelectedStage, 'value');
    filteredInitiativesArray = _.filter(
      filteredInitiativesArray,
      (eachInitiative) => _.indexOf(
        arrSelectedIds,
        String(eachInitiative.iojStageId),
      ) !== -1,
    );
  } else {
    const arrUnSelectedIds = _.map(stageDropdownData, 'value');
    filteredInitiativesArray = _.filter(
      filteredInitiativesArray,
      (eachInitiative) => _.indexOf(
        arrUnSelectedIds,
        String(eachInitiative.iojStageId),
      ) === -1,
    );
  }

  // FILTER INITIATIVES BASED ON START AND END DATES
  if (!_.isEmpty(startDate) && !_.isEmpty(endDate)) {
    filteredInitiativesArray = getFilteredInitiativesBasedOnStartAndEndDate(filteredInitiativesArray, startDate, endDate);
  }

  /// /////////////////////////////
  const todayMoment = moment();
  let todayDayNumber = parseInt(todayMoment.format('DDD'), 10);
  if (selectedYearlyOrMonthlyFilterValue === 'monthly') {
    todayDayNumber = parseInt(todayMoment.format('D'), 10);
    valDayNumberDivider = todayMoment.daysInMonth();
  }
  const pxWidthForEachDay = configForCalculation.pxWidthBetweenTwoTimelineLabelsOnChart / valDayNumberDivider;

  const foundTodayEndDateTimelineLabelObj = _.find(
    modifiedTimelineLabelsArr,
    { label: todayMoment.format(dateFormatToBeUsed) },
  );
  const pxStartPositionOfTodayTimelineLabel = _.get(
    foundTodayEndDateTimelineLabelObj,
    'pxStartPosition',
    null,
  );

  // CALCULATE THE PIXEL POSITION TO PLOT THE 'TODAY' VERTICAL DASHED LINE ON THE CHART
  const todayLeft = (todayDayNumber * pxWidthForEachDay);
  const calcTodayLeft = pxStartPositionOfTodayTimelineLabel + todayLeft;
  // calcTodayLeft = calcTodayLeft - 16;

  const styleForTimelineLabel = {
    width: configForCalculation.pxWidthBetweenTwoTimelineLabelsOnChart,
  };

  let labelForDatePickerFilter = `${i18n.t('Start_Date')} - ${i18n.t('End_Date')}`;
  if (!_.isEmpty(startDate) && !_.isEmpty(endDate)) {
    const formattedStartDt = moment(startDate).format(dateFormatForDatepickers);
    const formattedEndDt = moment(endDate).format(dateFormatForDatepickers);

    labelForDatePickerFilter = `${formattedStartDt} - ${formattedEndDt}`;

    if (formattedStartDt === formattedEndDt) {
      labelForDatePickerFilter = formattedStartDt;
    }
  }



  // WHEN AN INITIATIVE IS SELECTED THEN SHOW THE 'INITIATIVE DETAILS' SCREEN
  if (!_.isEmpty(selectedInitiative)) {
    return (
      <InitiativeDetails
        renderedFromScreen="journeyGanttChart"
        handleClickBackToGanttChartButton={() => setSelectedInitiative({})}
        handleClickBreadcrumbItem={(screenName) => onHide(screenName)}
      />
    );
  }

  return (
    <>
      {isAPILoading && (
        <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />
      )}

      <div className="breadcrumb initiative-breadcrumb">
        <ul>
          <li className="initiative-heading back-navigation">
            <i
              className="fal fa-arrow-circle-left"
              role="button"
              tabIndex={0}
              onClick={() => onHide('roadMapJourney')}
              onKeyDown={() => onHide('roadMapJourney')}
            />
          </li>
          <li>
            <span
              role="button"
              tabIndex={0}
              onClick={() => onHide('roadMapJourney')}
              onKeyDown={() => onHide('roadMapJourney')}
            >
              {i18n.t('Roadmap_Journey')}
            </span>
          </li>
          <li className="active">{i18n.t('Initiative_timelines_Gantt_Chart')}</li>
        </ul>

        {!_.isEmpty(filteredInitiativesArray) && (
          <div className="ganttchart-points-color ml-auto">
            <div className="points-color">
              <span className="red-point-color">{i18n.t('Delayed')}</span>
              <span className="amber-point-color">{i18n.t('At_Risk')}</span>
              <span className="green-point-color">{i18n.t('On_Track')}</span>
              <span className="grey-point-color">{i18n.t('Not_a_Time_bound')}</span>
            </div>
          </div>
        )}
      </div>

      {/* WHEN THERE ARE NO INITIATIVES THEN DISPLAY THE MESSAGE */}
      {_.isEmpty(initiativesArray) && (
        <div className="ganttchart-wrapper">
          <div className="ganttchart-heading">
            {i18n.t('no_initiatives_msg')}
          </div>
        </div>
      )}

      {!_.isEmpty(initiativesArray) && (
        <div className="ganttchart-wrapper">
          <div className="ganttchart-heading mb-3">
            <div className="ganttchart-dropdowns purpleborder-dropdown">
              {/* START - YEARLY/MONTHLY SELECTION DROPDOWN */}
              <Dropdown
                onSelect={(selectedOptionValue) => handleChangeTimelineDropdown(selectedOptionValue)}
                className={classNames({ disabled: _.isEmpty(modifiedTimelineLabelsArr) })}
              >
                <Dropdown.Toggle className="metrictoggle-btn">
                  {_.upperFirst(selectedYearlyOrMonthlyFilterValue)}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="dropdownlist-wrapper">
                    <Dropdown.Item
                      eventKey="yearly"
                      className={classNames({ active: selectedYearlyOrMonthlyFilterValue === 'yearly' })}
                    >
                      {i18n.t('Yearly')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="monthly"
                      className={classNames({ active: selectedYearlyOrMonthlyFilterValue === 'monthly' })}
                    >
                      {i18n.t('Monthly')}
                    </Dropdown.Item>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              {/* END - YEARLY/MONTHLY SELECTION DROPDOWN */}

              {/* START - STATUS DROPDOWN */}
              <div className="dropdown">
                <button
                  type="button"
                  className="metrictoggle-btn dropdown-toggle btn btn-primary"
                  onClick={() => setVisibleStatusDropdown(true)}
                >
                  {displayLabelStatusDropdown}
                </button>

                {isVisibleStatusDropdown && (
                  <>
                    <div className="initiativeheading-dropdownlist criteriastatus-list">
                      <CustomMultiSelect
                        selectAllChecked={arrSelectedStatus.length === statusDropdownData.length}
                        hadleSelectAllChange={(e) => {
                          handleChangeAllCheckboxInStatusDropdown(e);
                        }}
                        options={statusDropdownData}
                        handleOptionChange={(e, index, selectedOptionObj) => {
                          handleChangeStatusDropdown(e, index, selectedOptionObj);
                        }}
                        dispalySelectAll
                      />
                    </div>

                    <div
                      className="initiative-dropdown-overlay"
                      onClick={() => setVisibleStatusDropdown(false)}
                      onKeyDown={() => setVisibleStatusDropdown(false)}
                      role="button"
                      tabIndex="0"
                    />
                  </>
                )}
              </div>
              {/* END - STATUS DROPDOWN */}

              {/* START - STAGE DROPDOWN */}
              <div className="dropdown">
                <button
                  type="button"
                  className="metrictoggle-btn dropdown-toggle btn btn-primary"
                  onClick={() => setVisibleStageDropdown(true)}
                >
                  {displayLabelStageDropdown}
                </button>

                {isVisibleStageDropdown && (
                  <>
                    <div className="initiativeheading-dropdownlist criteriastatus-list">
                      <CustomMultiSelect
                        selectAllChecked={arrSelectedStage.length === stageDropdownData.length}
                        hadleSelectAllChange={(e) => {
                          handleChangeAllCheckboxInStageDropdown(e);
                        }}
                        options={stageDropdownData}
                        handleOptionChange={(e, index, selectedOptionObj) => {
                          handleChangeStageDropdown(e, index, selectedOptionObj);
                        }}
                        dispalySelectAll
                      />
                    </div>

                    <div
                      className="initiative-dropdown-overlay"
                      onClick={() => setVisibleStageDropdown(false)}
                      onKeyDown={() => setVisibleStageDropdown(false)}
                      role="button"
                      tabIndex="0"
                    />
                  </>
                )}
              </div>
              {/* END - STAGE DROPDOWN */}

              {/* START - DATEPICKER POPOVER */}
              <div className="chooseOption calenderChooseOption purpleborder-datepicker">
                <Overlay
                  target={datepickerDropdownPopoverTarget.current}
                  show={isVisibleDatepickerPopover}
                  placement="bottom"
                  rootClose
                  rootCloseEvent="click"
                  onEnter={() => handleShowDatepickerPopover()}
                  onHide={() => handleHideDatepickerPopover()}
                >
                  <Popover id="popover-basic4" className="calenderPopover ganttchart-calender">
                    <Popover.Content>
                      <div className="popContent">
                        <div className="popContentDisplay">
                          <div
                            className={
                              dateSectionVisibleInDatepickerPopover === 'startDate'
                                ? 'calenderPopoverSpanActive'
                                : 'calenderPopoverSpan'
                            }
                            target={startDatepickerRef}
                            onClick={() => setDateSectionVisibleInDatepickerPopover('startDate')}
                            onKeyDown={() => setDateSectionVisibleInDatepickerPopover('startDate')}
                            role="button"
                            tabIndex={0}
                          >
                            <div className="CalenderDatepickerLabel">{i18n.t('Start_Month')}</div>
                            <div className="CalenderDatepickerDate">
                              {!_.isEmpty(formattedTempStartDate) ? formattedTempStartDate : i18n.t('NA')}
                            </div>
                          </div>

                          <div
                            className={
                              classNames(
                                { calenderPopoverSpanActive: dateSectionVisibleInDatepickerPopover === 'endDate' },
                                { calenderPopoverSpan: dateSectionVisibleInDatepickerPopover !== 'endDate' },
                                { disabled: _.isEmpty(tempStartDate) },
                              )
                            }
                            target={endDatepickerRef}
                            onClick={() => setDateSectionVisibleInDatepickerPopover('endDate')}
                            onKeyDown={() => setDateSectionVisibleInDatepickerPopover('endDate')}
                            role="button"
                            tabIndex={0}
                          >
                            <div className="CalenderDatepickerLabel">{i18n.t('End_Month')}</div>
                            <div className="CalenderDatepickerDate">
                              {!_.isEmpty(formattedTempEndDate) ? formattedTempEndDate : i18n.t('NA')}
                            </div>
                          </div>
                        </div>

                        {dateSectionVisibleInDatepickerPopover === 'startDate' ? (
                          <div>
                            <DatePicker
                              selected={!_.isEmpty(tempStartDate) ? moment(tempStartDate).toDate() : null}
                              onChange={(date) => handleChangeDateField(date, 'startDate')}
                              className="calenderDatepicker"
                              maxDate={!_.isEmpty(tempEndDate) ? moment(tempEndDate).toDate() : undefined}
                              ref={startDatepickerRef}
                              showMonthYearPicker
                              inline
                            />
                          </div>
                        ) : (
                          <DatePicker
                            selected={!_.isEmpty(tempEndDate) ? moment(tempEndDate).toDate() : null}
                            onChange={(date) => handleChangeDateField(date, 'endDate')}
                            className="calenderDatepicker"
                            minDate={!_.isEmpty(tempStartDate) ? moment(tempStartDate).toDate() : undefined}
                            ref={endDatepickerRef}
                            showMonthYearPicker
                            inline
                          />
                        )}
                      </div>
                    </Popover.Content>

                    <div className="popover-footer">
                      <div className="modal-btn temp">
                        <Button
                          variant="default"
                          className="btn-sm mr-auto"
                          onClick={() => handleClearDates()}
                        >
                          {i18n.t('Clear')}
                        </Button>
                        <Button
                          variant="primary"
                          className="btn-sm"
                          onClick={() => handleUpdateDates()}
                          disabled={!isEnabledUpdateButtonInDatepickerPopover}
                        >
                          {i18n.t('Update')}
                        </Button>
                      </div>
                    </div>
                  </Popover>
                </Overlay>

                <div className={classNames('calenderSpan', { disabled: _.isEmpty(modifiedTimelineLabelsArr) })}>
                  <span
                    className="datepicker-purpledropdown"
                    role="button"
                    tabIndex="0"
                    ref={datepickerDropdownPopoverTarget}
                    onClick={() => toggleDatepickerDropdownPopover()}
                    onKeyDown={() => toggleDatepickerDropdownPopover()}
                  >
                    {labelForDatePickerFilter}
                    <i className="far fa-calendar-day" />
                  </span>
                </div>
              </div>
              {/* END - DATEPICKER POPOVER */}
            </div>

            {!_.isEmpty(filteredInitiativesArray) && (
              <div className="ganttchart-points-color ml-auto">
                <div className="actions-points">
                  <span className="actual-start-date">{i18n.t('Actual_Start_Date')}</span>
                  <span className="revised-end-date">{i18n.t('Revised_End_Date')}</span>
                  <span className="actual-end-date">{i18n.t('Actual_End_Date')}</span>
                </div>
              </div>
            )}
          </div>

          {_.isEmpty(filteredInitiativesArray) && (
            <div className="ganttchart-heading mb-3">
              {i18n.t('no_initiatives_for_selected_filters')}
            </div>
          )}

          {/* START - GANTT CHART */}
          {!_.isEmpty(filteredInitiativesArray) && (
            <div id="table-gantt-chart">

              {/* START - LEFT CONTENT */}
              <div id="gantt-left-content">
                <div className="head-row">
                  <div>{i18n.t('Initiatives')}</div>
                  <div>{i18n.t('Status')}</div>
                  <div>{i18n.t('Stage')}</div>
                  <div>{i18n.t('Planned_Start_Date')}</div>
                </div>

                <div className="metrics-row">
                  {_.map(filteredInitiativesArray, (eachInitiative) => {
                    const {
                      id,
                      name,
                      truncatedName,
                      formattedPlannedStartDate,
                      initiativeStatusLabel,
                      iojStageLabel,
                    } = eachInitiative;

                    return (
                      <div className="row-list" key={id}>
                        <div
                          className="initiative-name"
                          role="button"
                          key={id}
                          onClick={() => handleClickInitiative(eachInitiative)}
                          onKeyDown={() => handleClickInitiative(eachInitiative)}
                          tabIndex={0}
                        >
                          <span
                            data-tip
                            data-for={`initiative-name-tooltip-${id}`}
                          >
                            {!_.isEmpty(truncatedName) ? truncatedName : name}
                          </span>
                          {/* INITIATIVE NAME TOOLTIP IF TEXT IS LONGER */}
                          {!_.isEmpty(truncatedName) && (
                            <ReactTooltip
                              id={`initiative-name-tooltip-${id}`}
                              className="gantt-point-tooltip"
                              effect="solid"
                              place="bottom"
                            >
                              <span>{name}</span>
                            </ReactTooltip>
                          )}
                        </div>
                        <div>{initiativeStatusLabel}</div>
                        <div>{iojStageLabel}</div>
                        <div>{!_.isEmpty(formattedPlannedStartDate) ? formattedPlannedStartDate : i18n.t('NA')}</div>
                      </div>
                    );
                  })}
                </div>

                {_.isEmpty(configForCalculation.classNameForHeadRow) && (
                  <span
                    id="slideLeft"
                    role="button"
                    tabIndex="0"
                    onClick={() => slideLeft()}
                    onKeyDown={() => slideLeft()}
                    className={classNames({ disabled: _.isEmpty(modifiedTimelineLabelsArr) })}
                  />
                )}
              </div>
              {/* END - LEFT CONTENT */}

              {/* START - MIDDLE CONTENT */}
              <div
                id="gantt-middle-content"
                ref={ganttChartMiddleContentRef}
              >
                <div
                  id="gantt-middle-wrapper"
                  style={{
                    width: !_.isEmpty(modifiedTimelineLabelsArr) ? configForCalculation.wrapperWidth : 'auto',
                  }}
                >
                  {/* PLOT TIMELINE LABELS */}
                  <div
                    className={classNames('head-row', [configForCalculation.classNameForHeadRow])}
                  >
                    {!_.isEmpty(modifiedTimelineLabelsArr) && _.map(modifiedTimelineLabelsArr, (eachItem) => (
                      <div style={styleForTimelineLabel}>{eachItem.label}</div>
                    ))}

                    {_.isEmpty(modifiedTimelineLabelsArr) && <div>&nbsp;</div>}
                  </div>

                  {/* PLOT THE BARS */}
                  <div className="metrics-row">
                    {_.map(filteredInitiativesArray, (eachInitiative) => {
                      const {
                        id,
                        startDateMoment,
                        formattedStartDate: formattedStartDateOfInitiative,
                        revisedEndDateMoment,
                        formattedRevisedEndDate,
                        endDateMoment,
                        formattedEndDate: formattedEndDateOfInitiative,
                        fteBenefit,
                        costBenefit,
                        rag,
                        startDateLabel,
                        endDateLabel,
                      } = eachInitiative;

                      // WHEN THE TIMELINE IS SELECTED AS 'YEARLY' THEN GET DAY OF YEAR
                      let startDateDayNumber = !_.isEmpty(startDateMoment)
                        ? parseInt(startDateMoment.format('DDD'), 10)
                        : 0;
                      let endDateDayNumber = !_.isEmpty(endDateMoment)
                        ? parseInt(endDateMoment.format('DDD'), 10)
                        : 0;
                      let revisedEndDateDayNumber = !_.isEmpty(revisedEndDateMoment)
                        ? parseInt(revisedEndDateMoment.format('DDD'), 10)
                        : 0;

                      let valDayNumberDividerForStartDate = valDayNumberDivider;
                      let valDayNumberDividerForEndDate = valDayNumberDivider;
                      let valDayNumberDividerForRevisedEndDate = valDayNumberDivider;
                      // WHEN THE TIMELINE IS SELECTED AS 'MONTHLY' THEN GET DAY OF MONTH WHICH WOULD BE USED TO CALCULATE THE PIXEL POSITION OF THAT DAY ON THE CHART
                      if (
                        selectedYearlyOrMonthlyFilterValue === 'monthly'
                        && !_.isEmpty(startDateMoment)
                        && !_.isEmpty(endDateMoment)
                      ) {
                        valDayNumberDividerForStartDate = startDateMoment.daysInMonth();
                        valDayNumberDividerForEndDate = endDateMoment.daysInMonth();
                        valDayNumberDividerForRevisedEndDate = !_.isEmpty(revisedEndDateMoment) ? revisedEndDateMoment.daysInMonth() : 1;
                        // GET DAY OF MONTH
                        startDateDayNumber = parseInt(startDateMoment.format('D'), 10);
                        endDateDayNumber = parseInt(endDateMoment.format('D'), 10);
                        revisedEndDateDayNumber = !_.isEmpty(revisedEndDateMoment)
                          ? parseInt(revisedEndDateMoment.format('D'), 10)
                          : 0;
                      }

                      // CALCULATE THE PIXEL POSITION TO PLOT THE 'PLANNED/ACTUAL START DATE' ON THE CHART
                      const pxWidthForEachDayStartDate = _.toNumber(parseFloat(configForCalculation.pxWidthBetweenTwoTimelineLabelsOnChart / valDayNumberDividerForStartDate));

                      const foundStartDateTimelineLabelObj = !_.isEmpty(startDateMoment)
                        ? _.find(
                          modifiedTimelineLabelsArr, { label: startDateMoment.format(dateFormatToBeUsed) },
                        ) : {};
                      const pxStartPositionOfStartDateTimelineLabel = _.get(
                        foundStartDateTimelineLabelObj,
                        'pxStartPosition',
                      );

                      const startDateLeft = (startDateDayNumber * pxWidthForEachDayStartDate);
                      const calcStartDateLeft = pxStartPositionOfStartDateTimelineLabel + startDateLeft;

                      // CALCULATE THE PIXEL POSITION TO PLOT THE 'PLANNED/ACTUAL END DATE' ON THE CHART
                      const pxWidthForEachDayEndDate = _.toNumber(parseFloat(configForCalculation.pxWidthBetweenTwoTimelineLabelsOnChart / valDayNumberDividerForEndDate));

                      const foundEndDateTimelineLabelObj = !_.isEmpty(endDateMoment)
                        ? _.find(
                          modifiedTimelineLabelsArr, { label: endDateMoment.format(dateFormatToBeUsed) },
                        ) : {};
                      const pxStartPositionOfEndDateTimelineLabel = _.get(
                        foundEndDateTimelineLabelObj,
                        'pxStartPosition',
                      );

                      const endDateLeft = (endDateDayNumber * pxWidthForEachDayEndDate);
                      const calcEndDateLeft = pxStartPositionOfEndDateTimelineLabel + endDateLeft;
                      let calcEndDateLeftForPlotting = calcEndDateLeft - pxAdjustmentForEndDateIcon;

                      // CALCULATE THE PIXEL POSITION TO PLOT THE 'REVISED END DATE' ON THE CHART
                      const pxWidthForEachDayRevisedEndDate = _.toNumber(parseFloat(configForCalculation.pxWidthBetweenTwoTimelineLabelsOnChart / valDayNumberDividerForRevisedEndDate));

                      const foundRevisedEndDateTimelineLabelObj = !_.isEmpty(revisedEndDateMoment)
                        ? _.find(
                          modifiedTimelineLabelsArr, { label: revisedEndDateMoment.format(dateFormatToBeUsed) },
                        ) : {};
                      const pxStartPositionOfRevisedEndDateTimelineLabel = _.get(
                        foundRevisedEndDateTimelineLabelObj,
                        'pxStartPosition',
                      );

                      const revisedEndDateLeft = (revisedEndDateDayNumber * pxWidthForEachDayRevisedEndDate);
                      const calcRevisedEndDateLeft = pxStartPositionOfRevisedEndDateTimelineLabel + revisedEndDateLeft;
                      let calcRevisedEndDateLeftForPlotting = calcRevisedEndDateLeft - pxAdjustmentForEndDateIcon;

                      // CALCULATE THE WIDTH OF THE BAR BASED ON THE PIXEL POSITIONS OF THE START AND END DATE
                      let barWidth = calcEndDateLeft - calcStartDateLeft;

                      if (!_.isEmpty(revisedEndDateMoment) && revisedEndDateMoment.isAfter(endDateMoment)) {
                        barWidth = calcRevisedEndDateLeft - calcStartDateLeft;
                      }

                      // IF THE CALCULATED WIDTH OF THE BAR IS SMALLER THAN THE MIN-WIDTH THEN PLOT THE BAR WITH THE MIN-WIDTH AND ADJUST THE END DATE POSITION ACCORDINGLY
                      if (barWidth < barMinWidth) {
                        barWidth = barMinWidth;

                        if (!_.isEmpty(endDateMoment)) {
                          calcEndDateLeftForPlotting = (calcStartDateLeft + barMinWidth) - pxAdjustmentForEndDateIcon;
                          if (!_.isEmpty(revisedEndDateMoment) && endDateMoment.isBefore(revisedEndDateMoment)) {
                            calcEndDateLeftForPlotting = calcStartDateLeft + ((calcEndDateLeftForPlotting - calcStartDateLeft) / 2);
                          }
                        }

                        // IF 'REVISED END DATE' IS NON-EMPTY AND IT IS GREATER THAN 'PLANNED/ACTUAL END DATE' THEN END THE BAR AT THIS DATE
                        if (!_.isEmpty(revisedEndDateMoment)) {
                          calcRevisedEndDateLeftForPlotting = (calcStartDateLeft + barMinWidth) - pxAdjustmentForEndDateIcon;
                          if (!_.isEmpty(endDateMoment) && revisedEndDateMoment.isBefore(endDateMoment)) {
                            calcRevisedEndDateLeftForPlotting = calcStartDateLeft + ((calcRevisedEndDateLeftForPlotting - calcStartDateLeft) / 2);
                          }
                        }
                      }

                      // LOGIC TO ALIGN THE 'FTE AND COST BENEFITS' TO THE LEFT BASED ON THE END DATE LEFT POSITION
                      const maxEndDateValLeft = _.max([calcEndDateLeftForPlotting, calcRevisedEndDateLeftForPlotting]);
                      let styleForBenefits = {};
                      if (maxEndDateValLeft < 200) {
                        styleForBenefits = {
                          left: 0,
                          right: 'unset',
                        };
                      }

                      return (
                        <div
                          key={id}
                          className={classNames(
                            'row-list',
                            { 'not-available-row': _.isEmpty(startDateMoment) || _.isEmpty(endDateMoment) },
                          )}
                        >
                          <div
                            className={classNames(
                              'row-list-content',
                            )}
                          >
                            {/* START DATE - ICON AND TOOLTIP */}
                            {!_.isEmpty(formattedStartDateOfInitiative) && (
                              <>
                                <span
                                  data-tip
                                  data-for={`start-date-tooltip-${id}`}
                                  className="actual-start-point"
                                  style={{ left: calcStartDateLeft }}
                                />
                                <ReactTooltip
                                  id={`start-date-tooltip-${id}`}
                                  className="gantt-point-tooltip"
                                  effect="solid"
                                  place="bottom"
                                >
                                  <span>
                                    {startDateLabel}
                                    {' '}
                                    <span>{formattedStartDateOfInitiative}</span>
                                  </span>
                                </ReactTooltip>
                              </>
                            )}

                            {/* REVISED END DATE - ICON AND TOOLTIP */}
                            {!_.isEmpty(formattedRevisedEndDate) && (
                              <>
                                <span
                                  data-tip
                                  data-for={`revised-end-date-tooltip-${id}`}
                                  className="revised-end-point"
                                  style={{ left: calcRevisedEndDateLeftForPlotting }}
                                />
                                <ReactTooltip
                                  className="gantt-point-tooltip"
                                  id={`revised-end-date-tooltip-${id}`}
                                  effect="solid"
                                  place="bottom"
                                >
                                  <span>
                                    {i18n.t('Revised_End_Date')}
                                    {' '}
                                    <span>{formattedRevisedEndDate}</span>
                                  </span>
                                </ReactTooltip>
                              </>
                            )}

                            {/* END DATE - ICON AND TOOLTIP */}
                            {!_.isEmpty(formattedEndDateOfInitiative) && (
                              <>
                                <span
                                  data-tip
                                  data-for={`end-date-tooltip-${id}`}
                                  className="actual-end-point"
                                  style={{ left: calcEndDateLeftForPlotting }}
                                />
                                <ReactTooltip
                                  className="gantt-point-tooltip"
                                  id={`end-date-tooltip-${id}`}
                                  effect="solid"
                                  place="bottom"
                                >
                                  <span>
                                    {endDateLabel}
                                    {' '}
                                    <span>{formattedEndDateOfInitiative}</span>
                                  </span>
                                </ReactTooltip>
                              </>
                            )}

                            {/* PLOT THE BAR ONLY WHEN THE START AND END DATES ARE NON-EMPTY */}
                            {!_.isEmpty(startDateMoment) && !_.isEmpty(endDateMoment) ? (
                              <div
                                className={classNames(
                                  'filled-bar',
                                  { 'green-color': rag === 'Green' },
                                  { 'amber-color': rag === 'Amber' },
                                  { 'red-color': rag === 'Red' },
                                  { 'gray-color': rag === 'Grey' },
                                )}
                                style={{ marginLeft: calcStartDateLeft, width: barWidth }}
                              >
                                {(!_.isEmpty(fteBenefit) || !_.isEmpty(costBenefit)) && (
                                  <div className="benefits" style={styleForBenefits}>
                                    {!_.isEmpty(fteBenefit) && (
                                      <span>
                                        {i18n.t('FTE_Benefit')}
                                        {' '}
                                        -
                                        {' '}
                                        <span>
                                          {fteBenefit}
                                          &nbsp;
                                        </span>
                                      </span>
                                    )}

                                    {!_.isEmpty(costBenefit) && (
                                      <span>
                                        {!_.isEmpty(fteBenefit) && ' / '}
                                        {i18n.t('Cost_Benefit')}
                                        {' '}
                                        -
                                        {' '}
                                        <span>{costBenefit}</span>
                                      </span>
                                    )}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <>{i18n.t('Initiative_Timeline_is_not_available')}</>
                            )}
                          </div>
                        </div>
                      );
                    })}

                    {/* TODAY - HORIZONTAL DASHED LINE */}
                    {!_.isEmpty(foundTodayEndDateTimelineLabelObj) && (
                      <span className="today-point" style={{ left: calcTodayLeft }}>
                        <span className="before-after-bordername">
                          <span>{i18n.t('Today')}</span>
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {/* END - MIDDLE CONTENT */}

              {/* START - RIGHT CONTENT */}
              <div id="gantt-right-content">
                <div className="head-row">
                  <div>{i18n.t('Planned_End_Date')}</div>
                </div>
                <div className="metrics-row">
                  {_.map(filteredInitiativesArray, (eachInitiative) => {
                    const { formattedPlannedEndDate } = eachInitiative;
                    return (
                      <div className="row-list">
                        <div>{!_.isEmpty(formattedPlannedEndDate) ? formattedPlannedEndDate : i18n.t('NA')}</div>
                      </div>
                    );
                  })}
                </div>

                {_.isEmpty(configForCalculation.classNameForHeadRow) && (
                  <span
                    id="slideRight"
                    role="button"
                    tabIndex={0}
                    onClick={() => slideRight()}
                    onKeyDown={() => slideRight()}
                    className={classNames({ disabled: _.isEmpty(modifiedTimelineLabelsArr) })}
                  />
                )}
              </div>
              {/* END - RIGHT CONTENT */}
            </div>
          )}
          {/* END - GANTT CHART */}
        </div>
      )}
    </>
  );
}

JourneyGanttChart.propTypes = {
  onHide: PropTypes.func.isRequired,
  languageId: PropTypes.number.isRequired,
  clientData: PropTypes.object.isRequired,
  syncFilter: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  languageId: state.CurrentFilter.languageData.id,
  clientData: state.CurrentFilter.ClientData,
  syncFilter: state.CurrentFilter.SyncFilter,
});

export default withRouter(connect(mapStateToProps)(JourneyGanttChart));
