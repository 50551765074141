import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import LoadingOverlay from 'react-loading-overlay';
import classNames from 'classnames';
import {
  Modal, Button, Overlay, Popover, OverlayTrigger,
} from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Chart from 'chart.js';
import moment from 'moment';
import $ from 'jquery';

// IMPORT CUSTOM COMPONENTS/FUNCTIONS
import PeerToPeerComparisonGraph from './PeerToPeerComparisonGraph';
import SynopsLoader from '../Shared/SynopsLoader';
import axiosInstance from '../Shared/interceptor';
import {
  trycatchAlertPopup,
  LocalApiBaseUrl,
  formatDate,
  BATAllowedMinDateMoment,
  BATDateFormat,
  BATDefaultStartDateMoment,
  BATDefaultEndDateMoment,
} from '../Shared/Constant';
import parse from 'react-html-parser';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(
  'msal.idtoken',
)}`;


// THE LABEL FOR "MARKET UNIT" HAS TO BE SHOWN DIFFERENT WHEN IN AGNOSTIC VIEW AND SIMULATION VIEW
const replacedLabelForMUInAgnosticViewSingular = 'Market';
const replacedLabelForMUInAgnosticViewPlural = 'Markets';
const replacedLabelForMUInSimulationViewSingular = 'Geography';
const replacedLabelForMUInSimulationViewPlural = 'Geographies';

const greenColorForGradient = 'rgba(150, 205, 103, 0.2)';
const yellowColorForGradient = 'rgba(255, 221, 123, 0.2)';
const redColorForGradient = 'rgba(213, 0, 47, 0.07)';
const greenColorForSimulatedClientBubble = '#528626';
const yellowColorForSimulatedClientBubble = '#E6B019';
const redColorForSimulatedClientBubble = '#AA0025';

function uniqueFromArrayWithMultipleAttributes(arr, keyProps) {
  return Object.values(
    arr.reduce((uniqueMap, entry) => {
      const clonedUniqueMap = uniqueMap;
      const key = keyProps.map((k) => entry[k]).join('|');
      if (!(key in uniqueMap)) clonedUniqueMap[key] = entry;
      return clonedUniqueMap;
    }, {}),
  );
}

// REMOVE THE DYNAMICALLY CREATED CUSTOM TOOLTIP ELEMENT FROM THE DOM
function removeChartCustomTooltip() {
  const { batMetricBenchmarkChart } = window;
  if (!_.isEmpty(batMetricBenchmarkChart)) {
    // SET EMPTY ALL THE ACTIVE POINTS ON THE CHART SO THAT THE CLICKED CLIENT BUBBLE(S) WOULD BE MADE INACTIVE (UN-HIGHLIGHTED)
    batMetricBenchmarkChart.active = [];
    batMetricBenchmarkChart.update();
  }

  const tooltipEl = document.getElementById('chartjs-tooltip');
  if (tooltipEl) {
    $(tooltipEl).remove();
  }
}

// GET THE INDICATOR VALUE FOR THE GIVEN METRIC VALUE WHETHER IT IS "better", "worse" OR "worst"
function getIndicatorValueForMetricValueBasedOnCriteria(
  metricValue,
  betterValue,
  worstValue,
  metricLeadingPractice,
) {
  let indicator = '';

  if (metricLeadingPractice === 'minimum') {
    if (metricValue <= betterValue) {
      indicator = 'better';
    } else if (metricValue >= worstValue) {
      indicator = 'worst';
    } else {
      indicator = 'worse';
    }
  } else if (metricLeadingPractice === 'maximum') {
    if (metricValue >= betterValue) {
      indicator = 'better';
    } else if (metricValue <= worstValue) {
      indicator = 'worst';
    } else {
      indicator = 'worse';
    }
  }

  return indicator;
}

function modifyClientDetails(clientDetails) {
  const modifiedClientDetails = _.map(clientDetails, (eachClientDetail) => ({
    ...eachClientDetail,
    medianMetricValueFloatVal: !_.isEmpty(String(eachClientDetail.medianMetricValue))
      ? parseFloat(eachClientDetail.medianMetricValue)
      : null,
    monthFormatted: moment(eachClientDetail.month).format('MMM YY'), // format ISO timestamp for ex: "2019-05-01T00:00:00" to "Apr 19"
  }));

  return modifiedClientDetails;
}

function modifyBenchmarkValues(dataObj) {
  const internalLowerQuartile = _.get(dataObj, 'internalLowerQuartile', '');
  const internalMedian = _.get(dataObj, 'internalMedian', '');
  const internalUpperQuartile = _.get(dataObj, 'internalUpperQuartile', '');
  const externalLowerQuartile = _.get(dataObj, 'externalLowerQuartile', '');
  const externalMedian = _.get(dataObj, 'externalMedian', '');
  const externalUpperQuartile = _.get(dataObj, 'externalUpperQuartile', '');

  const isEmptyInternalLowerQuartile = _.isEmpty(internalLowerQuartile);
  const isEmptyInternalMedian = _.isEmpty(internalMedian);
  const isEmptyInternalUpperQuartile = _.isEmpty(internalUpperQuartile);
  const isEmptyExternalLowerQuartile = _.isEmpty(externalLowerQuartile);
  const isEmptyExternalMedian = _.isEmpty(externalMedian);
  const isEmptyExternalUpperQuartile = _.isEmpty(externalUpperQuartile);

  let areEmptyInternalBenchmarks = false;
  if (isEmptyInternalLowerQuartile && isEmptyInternalMedian && isEmptyInternalUpperQuartile) {
    areEmptyInternalBenchmarks = true;
  }

  let areEmptyExternalBenchmarks = false;
  if (isEmptyExternalLowerQuartile && isEmptyExternalMedian && isEmptyExternalUpperQuartile) {
    areEmptyExternalBenchmarks = true;
  }

  const returnObj = {
    ...dataObj,
    areEmptyInternalBenchmarks,
    areEmptyExternalBenchmarks,
    internalLowerQuartile,
    internalMedian,
    internalUpperQuartile,
    externalLowerQuartile,
    externalMedian,
    externalUpperQuartile,
    internalLowerQuartileFloatVal: !isEmptyInternalLowerQuartile
      ? parseFloat(dataObj.internalLowerQuartile)
      : '',
    internalMedianFloatVal: !isEmptyInternalMedian ? parseFloat(dataObj.internalMedian) : '',
    internalUpperQuartileFloatVal: !isEmptyInternalUpperQuartile
      ? parseFloat(dataObj.internalUpperQuartile)
      : '',
    externalLowerQuartileFloatVal: !isEmptyExternalLowerQuartile
      ? parseFloat(dataObj.externalLowerQuartile)
      : '',
    externalMedianFloatVal: !isEmptyExternalMedian ? parseFloat(dataObj.externalMedian) : '',
    externalUpperQuartileFloatVal: !isEmptyExternalUpperQuartile
      ? parseFloat(dataObj.externalUpperQuartile)
      : '',
  };

  return returnObj;
}
class MetricInsightDetails extends Component {
  constructor(props) {
    super(props);

    const {
      metricData, offeringType, existingBATClientName, isExistingClient,
    } = this.props;

    // CHECK IF THE USER HAS THE PERMISSION TO ACCESS "Display Client Names" SWITCH TOGGLE ELEMENT SO THAT HE CAN VIEW REAL CLIENT NAMES
    const permissionElement = _.find(
      props.userPermissions,
      (eachPermission) => eachPermission.FeatureName === 'Dashboard',
    );
    const permittedOperationsArray = _.get(permissionElement, 'Operations', []);
    const hasUserPermShowClientNames = _.indexOf(permittedOperationsArray, 'Show Deals') !== -1;

    // SET DEFAULT TIME FRAME OF 12 MONTHS
    const defaultStartDateMoment = moment(props.startDate, BATDateFormat);
    const defaultEndDateMoment = moment(props.endDate, BATDateFormat);
    const industryAndMarketTrendViewBenchmarkOptions = [
      {
        benchmarkType: 'internal',
        benchmarkBy: 'IndustryWise',
        value: 'internal_industry',
        label: 'Industry',
        isDisabled: false,
      },
      {
        benchmarkType: 'external',
        benchmarkBy: 'IndustryWise',
        value: 'external_industry',
        label: 'Industry',
        isDisabled: false,
      },
      {
        benchmarkType: 'internal',
        benchmarkBy: 'MarketUnit',
        value: 'internal_market',
        label: 'Market',
        isDisabled: false,
      },
      {
        benchmarkType: 'external',
        benchmarkBy: 'MarketUnit',
        value: 'external_market',
        label: 'Market',
        isDisabled: false,
      },
    ];

    const crossIndustryTrendViewBenchmarkOptions = [
      {
        benchmarkType: 'internal',
        benchmarkBy: 'CrossIndustry',
        value: 'internal_crossIndustry',
        label: 'Cross Industry',
        isDisabled: false,
      },
      {
        benchmarkType: 'external',
        benchmarkBy: 'CrossIndustry',
        value: 'external_crossIndustry',
        label: 'Cross Industry',
        isDisabled: false,
      },
    ];

    const trendViewBenchmarkOptions = offeringType === 'industry'
      ? industryAndMarketTrendViewBenchmarkOptions
      : [
        ...industryAndMarketTrendViewBenchmarkOptions,
        ...crossIndustryTrendViewBenchmarkOptions,
      ];

    const selectedTrendViewBenchmarkOption = _.find(industryAndMarketTrendViewBenchmarkOptions, {
      value: 'internal_industry',
    });

    // WHEN IN EXISTING CLIENT MODE (FOR EX: IF THE GRAPHS ARE OPENED FOR DIVERSE DATA FUNCTIONALITY FOR SynOps CLIENT)
    let isClientExisting = false;
    if (isExistingClient && !_.isEmpty(existingBATClientName)) {
      isClientExisting = true;
    }

    this.state = {
      hasUserPermShowClientNames,
      isAPILoading: false,
      metricUnit: metricData.metricUnitFormatted,
      metricBenchmarkDetails: {},
      isEmptyMetricBenchmarkDetails: false, // will be set to `true` if `clientDetails` is received empty from the `GetMetricBenchmarkDetails` API or API returns some error
      clientMetricData: {},
      thresholdData: [],
      isEmptyThresholdData: false, // will be set to `true` if empty data is received from the `GetThresholds` API or API returns some error
      clientMarketUnits: [], // are set from Threshold API
      clientIndustries: [], // are set from Threshold API
      selectedClientIndustries: [],
      tempSelectedClientIndustries: [],
      isSelectedAllClientIndustries: false,
      tempIsSelectedAllClientIndustries: false,
      isVisibleClientIndustriesPopover: false,
      selectedClientMarketUnits: [],
      tempSelectedClientMarketUnits: [],
      isSelectedAllClientMarketUnits: false,
      tempIsSelectedAllClientMarketUnits: false,
      clientSubUnits: [],
      isVisibleClientMarketUnitsPopover: false,
      selectedInsightsViewBenchmark: 'internal', // OR external
      tempSelectedInsightsViewBenchmark: '',
      selectedTrendViewBenchmark: '',
      tempSelectedTrendViewBenchmark: '',
      selectedThreshold: 'IndustryWise', // OR "MarketUnit", default to "IndustryWise"
      tempSelectedThresholdForInsightsView: '',
      displayClientNames: false,
      displayQuartiles: false,
      startDate: defaultStartDateMoment.toDate(),
      endDate: defaultEndDateMoment.toDate(),
      formattedStartDate: formatDate(defaultStartDateMoment),
      formattedEndDate: formatDate(defaultEndDateMoment),
      tempPreviousFormattedStartDate: formatDate(defaultStartDateMoment),
      tempPreviousFormattedEndDate: formatDate(defaultEndDateMoment),
      isStartEndDateSelected: true,
      tempStartDate: defaultStartDateMoment.toDate(),
      tempEndDate: defaultEndDateMoment.toDate(),
      formattedTempStartDate: formatDate(defaultStartDateMoment),
      formattedTempEndDate: formatDate(defaultEndDateMoment),
      // THESE TEMPORARY VALUES WILL BE SAVED WHEN SWITCHING TO THE "Benchmark Comparison" TREND CHART BY CLICKING THE "View Details" LINK OF THE CLIENT BUBBLE. THE VALUES FOR THE DATEPICKER WILL BE RESTORED FROM THESE TEMP SAVED VALUES ONCE SWITCHED BACK TO THE "Insights" VIEW
      tempStartDateSavedForInsightsView: null,
      tempEndDateSavedForInsightsView: null,
      tempFormattedStartDateSavedForInsightsView: '',
      tempFormattedEndDateSavedForInsightsView: '',
      currentLeadingPracticeForMetric: '',
      currentView: 'metricBenchmarkDetailsView', // "metricBenchmarkDetailsView" (insights (bar) chart view for Client Agnostic and Client Simulation) OR "trendBenchmarkComparisonView" (trends (line) chart "Benchmark Comparison" view) OR "trendDCView" (trends comparison for Delivery Centers) OR "trendPeerComparisonView" (trends Peer to Peer Comparison view)
      currentlySelectedClientDetails: {},
      // "External Benchmarks" RADIO BUTTON HAS TO BE DISABLED IF BOTH THE "INDUSTRY/MARKET UNITS" EXTERNAL BENCHMARKS AND CROSS-INDUSTRY (OR CROSS-MARKET (BOTH ARE SAME)) ARE EMPTY TO PREVENT THE USER TO VIEW EXTERNAL BENCHMARKS DUE TO UNAVAILABILITY OF DATA
      isDisabledExternalBenchmarksRadio: true,
      isDisabledInternalBenchmarks: false,
      barChartDefaultLegendContent: '',
      barChartCrossIndustryLegendData: [],
      legendHTMLContentForBenchmarkOrDCTrendChart: '',
      isVisibleTrendViewBenchmarkPopover: false,
      // RADIO OPTIONS FOR BENCHMARK SELECTION POPOVER THAT WOULD BE VISIBLE ONLY IN TRENDS VIEW
      // FOR "INDUSTRY" OFFERINGS CROSS-INDUSTRY BENCHMARKS WOULD NO TBE PRESENT SO DO NOT DISPLAY RADIO BUTTON OPTIONS FOR CROSS-INDUSTRY
      trendViewBenchmarkOptions,
      trendViewBenchmarkOptionsInitial: trendViewBenchmarkOptions,
      selectedTrendViewBenchmarkOption,
      tempSelectedTrendViewBenchmarkOption: selectedTrendViewBenchmarkOption,
      selectedTrendViewBenchmarkMarketUnitOption: {},
      tempSelectedTrendViewBenchmarkMarketUnitOption: {},
      isThresholdUnitNameCustomSet: false, // WOULD BE SET TO `true` TO INDICATE THAT A CUSTOM NAME HAS BEEN SET IN THE `unitName` ATTRIBUTE OF THE THRESHOLD DATA WHEN THERE IS ONLY ONE DATA AND THE UNCONSOLIDATED GROUP WOULD BE EMPTY AND CONSOLIDATED GROUP WOULD BE NON-EMPTY
      isConsolidationForced: false, // THIS WOULD BE SET TO `true` WHEN IN CLIENT SIMULATION VIEW, THE SIMULATED CLIENT'S IN/MU DOES NOT EXIST IN THE BENCHMARKS DATA AND NEITHER THE CONSOLIDATED GROUP ("Other Industries/MU") EXITS IN THE THRESHOLD DATA. THEN FORCE THE CONSOLIDATION AND AGAIN EXECUTE THE API REQUESTS TO LOAD BENCHMARKS DATA FOR CONSOLIDATED VIEW. WHEN CONSOLIDATION WOULD BE FORCED, THE CHECKBOXES SELECTION FOR IN/MU WOULD BE SET TO `checked` BY DEFAULT AND SET TO `disabled`
      nameOfVisibleTrendViewBenchmarkMarketUnitRadioSection: '', // "internal" or "external"
      nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection: '',
      marketUnitsRadioOptionsForTrendsViewBenchmarkSelection: [],
      isClientExisting,
      deliveryCenters: [],
      selectedDeliveryCenters: [],
      tempSelectedDeliveryCenters: [],
      isVisibleDeliveryCentersPopover: false,
      selectedDeliveryCenterForTrendComparison: '',
    };

    this.metricBenchmarkChartRef = React.createRef();
    this.benchmarkComparisonTrendChartRef = React.createRef();
    this.dcWiseComparisonTrendChartRef = React.createRef();
    this.clientIndustriesPopoverTarget = React.createRef();
    this.clientMarketUnitsPopoverTarget = React.createRef();
    this.startDatepickerRef = React.createRef();
    this.endDatepickerRef = React.createRef();
    this.benchmarkDropdownPopoverTarget = React.createRef();
    this.datepickerDropdownPopoverTarget = React.createRef();
    this.selectDCPopoverTarget = React.createRef();
  }

  componentDidMount() {
    // FETCH THE DATA FROM THE APIs ON SCREEN LOAD
    this.fetchMetricBenchmarkDetails();
    const { offeringName } = this.props;
    if(offeringName === "Marketing Ops Value Solutioning"){
      this.setState({
        isDisabledInternalBenchmarks : true,
        selectedInsightsViewBenchmark: "external",
      });
    }

    // BIND THE CLICK EVENT SO THAT IF THE MOUSE IS CLICKED ELSEWHERE OTHER THAN THE BAR CHART THEN REMOVE THE CUSTOM TOOLTIP
    $(window).click(() => {
      removeChartCustomTooltip();
    });

    $('#horizontalBarChartCanvas').click((event) => {
      event.stopPropagation();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      tempSelectedTrendViewBenchmarkOption, currentView, startDate, endDate,
    } = this.state;
    let {
      nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection,
      tempSelectedTrendViewBenchmarkMarketUnitOption,
    } = this.state;

    const { benchmarkBy } = tempSelectedTrendViewBenchmarkOption; // "MarketUnit", "IndustryWise" or "CrossIndustry"

    if (tempSelectedTrendViewBenchmarkOption !== prevState.tempSelectedTrendViewBenchmarkOption) {
      if (benchmarkBy !== 'MarketUnit') {
        nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection = '';
        tempSelectedTrendViewBenchmarkMarketUnitOption = {};
      }

      this.setState({
        nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection,
        tempSelectedTrendViewBenchmarkMarketUnitOption,
      });
    }

    // WHEN THE VIEW IS CHANGED TO EITHER OF THE "BenchmarkComparison" CHART OR "DC-Wise Comparison" CHART OR "Peer-Comparison" CHART, THEN FETCH THE DETAILS FROM THE API AND THEN RENDER THE CHART
    if (prevState.currentView !== currentView) {
      if (currentView === 'trendPeerComparisonView') {
        const startDateMoment = moment(startDate);
        const endDateMoment = moment(endDate);
        const diffInMonths = endDateMoment.diff(startDateMoment, 'months');

        if (diffInMonths < 5) {
          this.setState(
            {
              startDate: moment(BATDefaultStartDateMoment).toDate(),
              endDate: moment(BATDefaultEndDateMoment).toDate(),
              tempStartDate: moment(BATDefaultStartDateMoment).toDate(),
              tempEndDate: moment(BATDefaultEndDateMoment).toDate(),
              formattedStartDate: formatDate(BATDefaultStartDateMoment),
              formattedEndDate: formatDate(BATDefaultEndDateMoment),
            },
            () => this.fetchClientMetricDetails(),
          );
        } else {
          this.fetchClientMetricDetails();
        }
      }
      if (currentView === 'trendBenchmarkComparisonView' || currentView === 'trendDCView') {
        this.fetchClientMetricDetails();
      }
    }
  }

  componentWillUnmount() {
    // UNBIND BIND THE CLICK EVENTS
    $(window).off('click');
    $('#horizontalBarChartCanvas').off('click');
    window.batMetricBenchmarkChart = undefined;
    window.funViewClientDetails = undefined;
    window.funSelectDeliveryCenterForComparison = undefined;
    window.createClickableLabel = undefined;
  }

  // EXECUTES THE API REQUEST TO FETCH METRIC BENCHMARK DETAILS
  fetchMetricBenchmarkDetails = () => new Promise((resolve, reject) => {
    const {
      parentScreen,
      metricData,
      offeringName,
      subOfferingName,
      clientSimulationData,
      existingBATClientName,
      existingSynOpsClientName,
    } = this.props;
    const {
      formattedStartDate,
      formattedEndDate,
      selectedThreshold,
      isSelectedAllClientIndustries,
      isSelectedAllClientMarketUnits,
      isClientExisting,
    } = this.state;

    // WHEN "ALL INDUSTRIES" OR "ALL MARKET UNITS" CHECKBOX IS SELECTED, THEN FETCH THE DATA FOR THE CONSOLIDATED VIEW
    const isConsolidated = selectedThreshold === 'IndustryWise'
      ? isSelectedAllClientIndustries
      : isSelectedAllClientMarketUnits;
    const requestData = {
      offeringName,
      towerName: subOfferingName,
      metricName: metricData.metricName,
      timeFrameStartDate: formattedStartDate,
      timeFrameEndDate: formattedEndDate,
      benchmarkType: selectedThreshold,
      isConsolidated,
    };

    this.setState({ isAPILoading: true });
    // FETCH THE DATA FROM THE API
    let apiCall;
    if (parentScreen === 'diverseData') {
      apiCall = axiosInstance.post(
        `${LocalApiBaseUrl}BAT/GetSynOpsMetricBenchmarkDetails`,
        requestData,
      );
    } else {
      apiCall = axiosInstance.post(
        `${LocalApiBaseUrl}BAT/GetMetricBenchmarkDetails`,
        requestData,
      );
    }

    apiCall
      .then((response) => {
        const responseData = response.data;

        let clientDetails = _.get(responseData, 'clientDetails', []);

        if (_.isEmpty(clientDetails)) {
          window.alert('No data is available for the selected time frame');
          this.setState({
            isAPILoading: false,
            isEmptyMetricBenchmarkDetails: true,
          });
        } else {
          // WHEN IN CLIENT SIMULATION MODE, PREPEND THE SIMULATED CLIENT DATA WITH THE CLIENTS RETRIEVED FROM THE RESPONSE DATA
          if (!_.isEmpty(clientSimulationData)) {
            const simulatedClientData = {
              aliasName: clientSimulationData.clientName,
              clientName: clientSimulationData.clientName,
              industry: clientSimulationData.industry,
              marketUnit: clientSimulationData.marketUnit,
              medianMetricValue: clientSimulationData.metricValue,
              isSimulated: true,
            };
            clientDetails = _.concat([simulatedClientData], clientDetails);
          }

          // WHEN IN EXISTING CLIENT MODE (FOR EX: IF THE GRAPHS ARE OPENED FOR DIVERSE DATA FUNCTIONALITY FOR SynOps CLIENT)
          if (isClientExisting) {
            // IF IT IS AN EXISTING CLIENT, THEN FIND THAT CLIENT IN THE `allClients` ARRAY
            const clonedAllClients = _.cloneDeep(clientDetails);
            // AN ARRAY HERE BECAUSE A CLIENT MAY EXIST IN MULTIPLE MARKET UNITS SO MAY HAVE MULTIPLE ENTRIES
            const foundExistingClientArr = _.remove(
              clonedAllClients,
              (eachClient) => _.toLower(eachClient.clientName) === _.toLower(existingBATClientName),
            );

            if (!_.isEmpty(foundExistingClientArr)) {
              const modifiedFoundExistingClientArr = _.map(
                foundExistingClientArr,
                (eachExistingClient) => ({
                  ...eachExistingClient,
                  // FOR THE EXISTING CLIENT IN SynOps, OVERWRITE THE BAT NAMES AND SET THE REAL NAME AND ALIAS NAME AS PER THE NAME IN SynOps
                  clientName: existingSynOpsClientName,
                  // SET THE ALIAS NAME OF THE CLIENT TO THE REAL NAME
                  aliasName: existingSynOpsClientName,
                  // SET THE FLAG `isExisting` TO TRUE
                  isExisting: true,
                }),
              );

              clientDetails = _.concat(modifiedFoundExistingClientArr, clonedAllClients);
            }
          }

          // FILTER OUT DUPLICATE CLIENTS. WHEN `selectedThreshold` IS "IndustryWise", THEN CHECK FOR THE COMBINATION OF THE CLIENT NAME AND ITS INDUSTRY TO FILTER OUT THE DUPLICATES AND WHEN "MarketUnit" THEN CHECK FOR THE COMBINATION OF THE CLIENT NAME AND ITS MARKET UNIT TO FILTER OUT THE DUPLICATES
          if (selectedThreshold === 'IndustryWise') {
            clientDetails = uniqueFromArrayWithMultipleAttributes(clientDetails, [
              'clientName',
              'industry',
            ]);
          } else if (selectedThreshold === 'MarketUnit') {
            clientDetails = uniqueFromArrayWithMultipleAttributes(clientDetails, [
              'clientName',
              'marketUnit',
            ]);
          }

          // FROM THE ATTRIBUTE "medianMetricValue" WHICH HAS VALUE LIKE "36 days", PARSE TO FLOAT TO GET ITS VALUE AND ADD A NEW ATTRIBUTE "medianMetricValueFloatVal" CONTAINING THE FLOAT VALUE
          clientDetails = _.map(clientDetails, (eachClientDetail) => ({
            ...eachClientDetail,
            medianMetricValueFloatVal: parseFloat(eachClientDetail.medianMetricValue),
            isSimulated: _.get(eachClientDetail, 'isSimulated', false),
            isExisting: _.get(eachClientDetail, 'isExisting', false),
          }));

          const internalLowerQuartileFloatVal = !_.isEmpty(
            _.get(responseData, 'internalLowerQuartile', ''),
          )
            ? parseFloat(responseData.internalLowerQuartile)
            : '';
          const internalMedianFloatVal = !_.isEmpty(_.get(responseData, 'internalMedian', ''))
            ? parseFloat(responseData.internalMedian)
            : '';
          const internalUpperQuartileFloatVal = !_.isEmpty(
            _.get(responseData, 'internalUpperQuartile', ''),
          )
            ? parseFloat(responseData.internalUpperQuartile)
            : '';
          const externalLowerQuartileFloatVal = !_.isEmpty(
            _.get(responseData, 'externalLowerQuartile', ''),
          )
            ? parseFloat(responseData.externalLowerQuartile)
            : '';
          const externalMedianFloatVal = !_.isEmpty(_.get(responseData, 'externalMedian', ''))
            ? parseFloat(responseData.externalMedian)
            : '';
          const externalUpperQuartileFloatVal = !_.isEmpty(
            _.get(responseData, 'externalUpperQuartile', ''),
          )
            ? parseFloat(responseData.externalUpperQuartile)
            : '';

          const modifiedMetricBenchmarkDetails = {
            ...responseData,
            clientDetails,
            internalLowerQuartile: !_.isEmpty(_.get(responseData, 'internalLowerQuartile', ''))
              ? responseData.internalLowerQuartile
              : '',
            internalMedian: !_.isEmpty(_.get(responseData, 'internalMedian', ''))
              ? responseData.internalMedian
              : '',
            internalUpperQuartile: !_.isEmpty(_.get(responseData, 'internalUpperQuartile', ''))
              ? responseData.internalUpperQuartile
              : '',
            externalLowerQuartile: !_.isEmpty(_.get(responseData, 'externalLowerQuartile', ''))
              ? responseData.externalLowerQuartile
              : '',
            externalMedian: !_.isEmpty(_.get(responseData, 'externalMedian', ''))
              ? responseData.externalMedian
              : '',
            externalUpperQuartile: !_.isEmpty(_.get(responseData, 'externalUpperQuartile', ''))
              ? responseData.externalUpperQuartile
              : '',
            internalLowerQuartileFloatVal,
            internalMedianFloatVal,
            internalUpperQuartileFloatVal,
            externalLowerQuartileFloatVal,
            externalMedianFloatVal,
            externalUpperQuartileFloatVal,
          };

          this.setState(
            {
              isAPILoading: false,
              isEmptyMetricBenchmarkDetails: false,
              metricBenchmarkDetails: modifiedMetricBenchmarkDetails,
              currentView: 'metricBenchmarkDetailsView',
            },
            () => {
              // ONCE THE METRIC BENCHMARK DETAILS ARE FETCHED, FETCH THE DATA BASED ON THE "THRESHOLD" WHICH WILL BE UTILIZED TO POPULATED THE METRIC BENCHMARK CHART
              this.fetchThresholds();
            },
          );
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        reject(error);
        this.setState(
          {
            isAPILoading: false,
          },
          () => {
            this.handleModalHide();
          },
        );
      });
  });

  // EXECUTES THE API REQUEST TO FETCH THRESHOLDS
  fetchThresholds = () => new Promise((resolve, reject) => {
    const {
      parentScreen,
      metricData,
      offeringName,
      subOfferingName,
      clientSimulationData,
      offeringType,
    } = this.props;
    const {
      formattedStartDate,
      formattedEndDate,
      selectedThreshold,
      isSelectedAllClientIndustries,
      isSelectedAllClientMarketUnits,
      isConsolidationForced,
    } = this.state;

    // WHEN "ALL INDUSTRIES" OR "ALL MARKET UNITS" CHECKBOX IS SELECTED, THEN FETCH THE DATA FOR THE CONSOLIDATED VIEW
    const isConsolidated = selectedThreshold === 'IndustryWise'
      ? isSelectedAllClientIndustries
      : isSelectedAllClientMarketUnits;

    const requestData = {
      offeringName,
      towerName: subOfferingName,
      metricName: metricData.metricName,
      timeFrameStartDate: formattedStartDate,
      timeFrameEndDate: formattedEndDate,
      thresholdType: selectedThreshold,
      isConsolidated,
    };

    this.setState({ isAPILoading: true });
    // FETCH THE DATA FROM THE API
    let apiCall;
    if (parentScreen === 'diverseData') {
      apiCall = axiosInstance.post(`${LocalApiBaseUrl}BAT/GetSynOpsThresholds`, requestData);
    } else {
      apiCall = axiosInstance.post(`${LocalApiBaseUrl}BAT/GetThresholds`, requestData);
    }

    apiCall
      .then((response) => {
        let isAPILoading = false;

        const { metricBenchmarkDetails } = this.state;
        const responseData = response.data;

        let clientIndustries = [];
        let clientMarketUnits = [];

        if (!_.isEmpty(responseData)) {
          const consolidatedGroupCustomName = selectedThreshold === 'IndustryWise' ? 'All Industries' : 'All Market Units';

          // REMOVE DUPLICATES
          const uniqueThresholdData = _.uniqBy(responseData, (eachItem) => eachItem.unitName);

          // WHEN IN CLIENT SIMULATION VIEW
          if (!_.isEmpty(clientSimulationData)) {
            let simulatedClientUnitName = selectedThreshold === 'IndustryWise'
              ? clientSimulationData.industry
              : clientSimulationData.marketUnit;

            const unitNamesArray = _.map(uniqueThresholdData, 'unitName');

            // CHECK IF THE SIMULATED CLIENT INDUSTRY/MU EXISTS IN THE `unitNamesArray` OR NOT, IF NOT THEN CHANGE THE SIMULATED CLIENT `unitName` to "Other Industries/MUs" SO THAT ON THE GRAPH SIMULATED CLIENT BUBBLE IS DISPLAYED IN THAT GROUP
            if (_.indexOf(unitNamesArray, simulatedClientUnitName) === -1) {
              // FOR "INDUSTRY" TYPE OFFERINGS, ALWAYS SET THE SIMULATED CLIENT UNIT NAME TO FIRST UNIT NAME BECAUSE FOR "INDUSTRY" TYPE OFFERINGS, THRESHOLD DATA  ARRAY LENGTH WOULD BE EQUAL TO ONE
              if (offeringType === 'industry' && selectedThreshold === 'IndustryWise') {
                simulatedClientUnitName = _.first(unitNamesArray);
              } else {
                // FIND THE THRESHOLD DATA OBJ WITH CONSOLIDATED GROUP (EX: "OTHER INDUSTRIES/ OTHER MUs") SO THAT THE SIMULATED CLIENT'S UNIT NAME CAN BE INCLUDED UNDER THE CONSOLIDATED GROUP
                const foundConsolidatedThresholdObj = _.find(
                  uniqueThresholdData,
                  (eachThresholdObj) => (
                    !_.isEmpty(_.get(eachThresholdObj, 'groupType.normalGroup', []))
                    || !_.isEmpty(_.get(eachThresholdObj, 'groupType.consolidatedGroup', []))
                  ),
                );

                // IF CONSOLIDATED GROUP EXISTS IN THRESHOLD DATA THEN CHANGE THE `simulatedClientUnitName` TO THE CONSOLIDATED GROUP NAME SO THAT THE SIMULATED CLIENT IS DISPLAYED UNDER THE CONSOLIDATED GROUP
                if (!_.isEmpty(foundConsolidatedThresholdObj)) {
                  const consolidatedGroup = _.get(
                    foundConsolidatedThresholdObj,
                    'groupType.consolidatedGroup',
                    [],
                  );
                  const normalGroup = _.get(
                    foundConsolidatedThresholdObj,
                    'groupType.normalGroup',
                    [],
                  );

                  // FILTER OUT UNIQUE VALUES AFTER CONCATENATING SIMULATED CLIENT'S UNIT NAME WITH THE CONSOLIDATED GROUP. SO THAT IF THE CLIENT'S UNIT NAME ALREADY EXISTS IN THE `consolidatedGroup` THEN IT IS NOT DUPLICATED
                  if (
                    !_.isEmpty(consolidatedGroup)
                    && _.indexOf(
                      _.concat(consolidatedGroup, normalGroup),
                      simulatedClientUnitName,
                    ) === -1
                  ) {
                    const uniqueConsolidatedGroup = _.uniq(
                      _.concat(consolidatedGroup, [simulatedClientUnitName]),
                    );
                    _.set(
                      foundConsolidatedThresholdObj,
                      'groupType.consolidatedGroup',
                      uniqueConsolidatedGroup,
                    );
                  } else if (
                    !_.isEmpty(normalGroup)
                    && _.indexOf(
                      _.concat(consolidatedGroup, normalGroup),
                      simulatedClientUnitName,
                    ) === -1
                  ) {
                    const uniqueNormalGroup = _.uniq(
                      _.concat(normalGroup, [simulatedClientUnitName]),
                    );
                    _.set(
                      foundConsolidatedThresholdObj,
                      'groupType.normalGroup',
                      uniqueNormalGroup,
                    );
                  }

                  simulatedClientUnitName = foundConsolidatedThresholdObj.unitName;
                } else if (_.isEmpty(foundConsolidatedThresholdObj)) {
                  // IF CONSOLIDATED GROUP DOES NOT EXITS IN THE THRESHOLD DATA THEN FORCE THE CONSOLIDATION AND AGAIN EXECUTE THE API REQUESTS TO LOAD BENCHMARKS DATA FOR CONSOLIDATED VIEW
                  if (!isConsolidationForced) {
                    isAPILoading = true;
                    this.setState(
                      {
                        isConsolidationForced: true,
                        isSelectedAllClientIndustries: selectedThreshold === 'IndustryWise',
                        isSelectedAllClientMarketUnits: selectedThreshold === 'MarketUnit',
                      },
                      () => {
                        this.fetchMetricBenchmarkDetails();
                      },
                    );
                  }
                }

                // WHEN IN CONSOLIDATED VIEW, SET THE SIMULATED CLIENT UNIT NAME TO `consolidatedGroupCustomName`
                if (isConsolidated) {
                  simulatedClientUnitName = consolidatedGroupCustomName;
                }
              }

              // FOR THE SIMULATED CLIENT, SET THE INDUSTRY/MU
              const foundSimulatedClient = _.find(metricBenchmarkDetails.clientDetails, [
                'isSimulated',
                true,
              ]);
              if (selectedThreshold === 'IndustryWise') {
                foundSimulatedClient.industry = simulatedClientUnitName;
              } else {
                foundSimulatedClient.marketUnit = simulatedClientUnitName;
              }
            }
          }
          // BELOW IS THE CODE TO CHANGE THE NAME OF THE `unitName` AND SET CUSTOM VALUE TO EITHER "All Industries" OR "All Market Units" IN THRESHOLD DATA WHEN THE THERE IS JUST ONE DATA IN THRESHOLD DATA AND NORMAL UNCONSOLIDATED (`groupType.normalGroup`) GROUP IS EMPTY AND THE CONSOLIDATED GROUP (`groupType.consolidatedGroup`) IS NOT EMPTY
          let isThresholdUnitNameCustomSet = false;
          if (
            uniqueThresholdData.length === 1
            && _.isEmpty(_.get(uniqueThresholdData, '0.groupType.normalGroup'))
            && !_.isEmpty(_.get(uniqueThresholdData, '0.groupType.consolidatedGroup'))
          ) {
            _.set(uniqueThresholdData, '0.unitName', consolidatedGroupCustomName);

            _.forEach(metricBenchmarkDetails.clientDetails, (eachClient) => {
              if (selectedThreshold === 'IndustryWise') {
                _.set(eachClient, 'industry', consolidatedGroupCustomName);
              } else {
                _.set(eachClient, 'marketUnit', consolidatedGroupCustomName);
              }
            });

            isThresholdUnitNameCustomSet = true;
          }

          const clientsAllIndustries = _.map(
            metricBenchmarkDetails.clientDetails,
            (eachClient) => eachClient.industry,
          );
          const clientsAllMarketUnits = _.map(
            metricBenchmarkDetails.clientDetails,
            (eachClient) => eachClient.marketUnit,
          );

          const thresholdData = _.map(uniqueThresholdData, (eachThresholdData) => {
            const internalLowerQuartile = _.get(eachThresholdData, 'internalLowerQuartile', '');
            const internalMedian = _.get(eachThresholdData, 'internalMedian', '');
            const internalUpperQuartile = _.get(eachThresholdData, 'internalUpperQuartile', '');
            const externalLowerQuartile = _.get(eachThresholdData, 'externalLowerQuartile', '');
            const externalMedian = _.get(eachThresholdData, 'externalMedian', '');
            const externalUpperQuartile = _.get(eachThresholdData, 'externalUpperQuartile', '');

            let internalLowerQuartileFloatVal = '';
            let internalMedianFloatVal = '';
            let internalUpperQuartileFloatVal = '';
            let externalLowerQuartileFloatVal = '';
            let externalMedianFloatVal = '';
            let externalUpperQuartileFloatVal = '';

            if (!_.isEmpty(internalLowerQuartile)) {
              internalLowerQuartileFloatVal = parseFloat(internalLowerQuartile);
            }

            if (!_.isEmpty(internalMedian)) {
              internalMedianFloatVal = parseFloat(internalMedian);
            }

            if (!_.isEmpty(internalUpperQuartile)) {
              internalUpperQuartileFloatVal = parseFloat(internalUpperQuartile);
            }

            if (!_.isEmpty(externalLowerQuartile)) {
              externalLowerQuartileFloatVal = parseFloat(externalLowerQuartile);
            }

            if (!_.isEmpty(externalMedian)) {
              externalMedianFloatVal = parseFloat(externalMedian);
            }

            if (!_.isEmpty(externalUpperQuartile)) {
              externalUpperQuartileFloatVal = parseFloat(externalUpperQuartile);
            }

            return {
              ...eachThresholdData,
              internalLowerQuartile,
              internalLowerQuartileFloatVal,
              internalMedian,
              internalMedianFloatVal,
              internalUpperQuartile,
              internalUpperQuartileFloatVal,
              externalLowerQuartile,
              externalLowerQuartileFloatVal,
              externalMedian,
              externalMedianFloatVal,
              externalUpperQuartile,
              externalUpperQuartileFloatVal,
            };
          }).filter((eachThreshold) => {
            // REMOVE THE INDUSTRIES/MARKET UNITS FOR WHICH THERE ARE NO CLIENTS SO THAT THEY ARE EXCLUDED FROM THE FILTERS SELECTION CHECKBOXES AND ARE NOT DISPLAYED ON THE BAR CHART
            const { unitName } = eachThreshold;
            let isClientExistsForThreshold;
            if (selectedThreshold === 'IndustryWise') {
              isClientExistsForThreshold = _.indexOf(clientsAllIndustries, unitName) !== -1;
            } else {
              isClientExistsForThreshold = _.indexOf(clientsAllMarketUnits, unitName) !== -1;
            }
            return isClientExistsForThreshold;
          });

          const unitNames = _.map(thresholdData, (eachThreshold) => eachThreshold.unitName);

          // FILTER OUT UNIQUE CLIENT INDUSTRIES/MARKET UNITS
          let uniqueUnitNames = unitNames.filter((value, index, self) => self.indexOf(value) === index);

          uniqueUnitNames.sort();

          // FIND OUT THE THRESHOLD DATA (IF THERE IS ANY) WHICH HAS DATA FOR THE CONSOLIDATED INDUSTRIES/MARKET UNITS
          const thresholdObjWithConsolidatedGroup = _.head(
            _.remove(thresholdData, (eachItem) => {
              const normalGroup = _.get(eachItem, 'groupType.normalGroup', []);
              const consolidatedGroup = _.get(eachItem, 'groupType.consolidatedGroup', []);
              return normalGroup.length > 0 || consolidatedGroup.length > 0;
            }),
          );

          // IF THERE WAS ANY SUCH THRESHOLD DATA CONTAINING THE CONSOLIDATED INDUSTRIES/MARKET UNITS, THEN REMOVE IT FROM THE `uniqueUnitNames` AND THEN AGAIN APPEND IT TO THE END OF THE SAME ARRAY SO THAT ON THE GRAPH THE CONSOLIDATED GROUP IS ALWAYS DISPLAYED AT THE BOTTOM
          if (!_.isEmpty(thresholdObjWithConsolidatedGroup)) {
            const unitNameWithConsolidatedGroup = _.head(
              _.remove(
                uniqueUnitNames,
                (eachItem) => eachItem === _.get(thresholdObjWithConsolidatedGroup, 'unitName'),
              ),
            );

            uniqueUnitNames.push(unitNameWithConsolidatedGroup);
            thresholdData.push(thresholdObjWithConsolidatedGroup);
          }

          // PREPARE SEPARATE ARRAY CONTAINING THE CONSOLIDATED UNITS, WHICH WOULD BE USED TO DISPLAY THE CONSOLIDATED INDUSTRIES/MARKET UNIT NAMES IN A SEPARATE SECTION IN THE CHECKBOXES SELECTION POPOVER
          const clientSubUnits = [];
          _.forEach(uniqueUnitNames, (eachUnit) => {
            const foundThresholdDataItem = _.find(
              thresholdData,
              (eachItem) => eachItem.unitName === eachUnit,
            );
            const groupType = _.get(foundThresholdDataItem, 'groupType', {});
            const consolidatedGroup = _.get(groupType, 'consolidatedGroup', []);

            clientSubUnits[eachUnit] = [];
            if (!isConsolidated && consolidatedGroup.length > 0) {
              clientSubUnits[eachUnit] = consolidatedGroup;
            }
          });

          // WHEN IN "CONSOLIDATED VIEW", SEPARATE OUT THE NORMAL (UNCONSOLIDATED INDUSTRIES/MARKET UNITS) CONTAINING UNDER `groupType.normalGroup` FROM THE CONSOLIDATED ONES CONTAINING UNDER `groupType.consolidatedGroup` SO THAT THEY CAN BE DISPLAYED SEPARATELY IN THE CHECKBOXES SELECTION POPOVER. ALSO SET A LABEL FOR THE CONSOLIDATED ONES
          if (isConsolidated) {
            uniqueUnitNames = _.cloneDeep(_.get(thresholdData, '0.groupType.normalGroup', []));
            const consolidatedGroup = _.get(thresholdData, '0.groupType.consolidatedGroup', []);
            if (!_.isEmpty(consolidatedGroup)) {
              let consolidatedGroupName = _.get(thresholdData, '0.unitName');
              if (!isThresholdUnitNameCustomSet) {
                consolidatedGroupName = selectedThreshold === 'IndustryWise'
                  ? 'Other Industries'
                  : 'Other Market Units';
              }
              uniqueUnitNames.push(consolidatedGroupName);
              clientSubUnits[consolidatedGroupName] = consolidatedGroup;
            }
          }

          // if the `selectedThreshold` is "MarketUnit", then the `unitName` attribute would contain the names of market units else it will contain the names of the industries
          if (selectedThreshold === 'IndustryWise') {
            clientIndustries = uniqueUnitNames;
          } else {
            clientMarketUnits = uniqueUnitNames;
          }

          // CROSS INDUSTRY BENCHMARK DATA ARE BUILT UP FROM `metricBenchmarkDetails` DATA
          const crossIndustryBenchmarkDetails = {
            internalLowerQuartile: metricBenchmarkDetails.internalLowerQuartile,
            internalMedian: metricBenchmarkDetails.internalMedian,
            internalUpperQuartile: metricBenchmarkDetails.internalUpperQuartile,

            externalLowerQuartile: metricBenchmarkDetails.externalLowerQuartile,
            externalMedian: metricBenchmarkDetails.externalMedian,
            externalUpperQuartile: metricBenchmarkDetails.externalUpperQuartile,

            internalLowerQuartileFloatVal: metricBenchmarkDetails.internalLowerQuartileFloatVal,
            internalMedianFloatVal: metricBenchmarkDetails.internalMedianFloatVal,
            internalUpperQuartileFloatVal: metricBenchmarkDetails.internalUpperQuartileFloatVal,

            externalLowerQuartileFloatVal: metricBenchmarkDetails.externalLowerQuartileFloatVal,
            externalMedianFloatVal: metricBenchmarkDetails.externalMedianFloatVal,
            externalUpperQuartileFloatVal: metricBenchmarkDetails.externalUpperQuartileFloatVal,
          };

          // CHECK WHETHER "INDUSTRY/MARKET UNITS" EXTERNAL BENCHMARKS AND "CROSS-INDUSTRY (OR CROSS-MARKET (BOTH ARE SAME))" ARE AVAILABLE, IF NOT THEN DISABLE THE "External Benchmarks" RADIO BUTTON TO PREVENT THE USER TO VIEW EXTERNAL BENCHMARKS DUE TO UNAVAILABILITY OF DATA
          let areAllIndustryExternalBenchmarksEmpty = true;

          // LOOP OVER `thresholdData` TO CHECK WHETHER "INDUSTRY/MARKET UNITS" EXTERNAL BENCHMARKS ARE EMPTY
          _.forEach(thresholdData, (eachThreshold) => {
            const { externalLowerQuartile } = eachThreshold;
            const { externalMedian } = eachThreshold;
            const { externalUpperQuartile } = eachThreshold;
            if (
              !_.isEmpty(String(externalLowerQuartile))
              || !_.isEmpty(String(externalMedian))
              || !_.isEmpty(String(externalUpperQuartile))
            ) {
              areAllIndustryExternalBenchmarksEmpty = false;
            }
          });

          // CHECK WHETHER "CROSS-INDUSTRY (OR CROSS-MARKET (BOTH ARE SAME))" EXTERNAL BENCHMARKS ARE EMPTY
          let areAllCrossIndustryExternalBenchmarksEmpty = true;
          if (
            !_.isEmpty(String(crossIndustryBenchmarkDetails.externalLowerQuartile))
            || !_.isEmpty(String(crossIndustryBenchmarkDetails.externalMedian))
            || !_.isEmpty(String(crossIndustryBenchmarkDetails.externalUpperQuartile))
          ) {
            areAllCrossIndustryExternalBenchmarksEmpty = false;
          }

          // IF BOTH ARE EMPTY THEN DISABLE THE "External Benchmarks" RADIO BUTTON
          let isDisabledExternalBenchmarksRadio = false;
          if (
            areAllIndustryExternalBenchmarksEmpty
            && areAllCrossIndustryExternalBenchmarksEmpty
          ) {
            isDisabledExternalBenchmarksRadio = true;
          }

          this.setState(
            {
              isAPILoading,
              isEmptyThresholdData: false,
              isDisabledExternalBenchmarksRadio,
              thresholdData,
              currentLeadingPracticeForMetric: !_.isEmpty(
                _.get(thresholdData, '0.leadingPracticeIndicator', ''),
              )
                ? _.toLower(_.get(thresholdData, '0.leadingPracticeIndicator', ''))
                : '',
              clientIndustries,
              clientMarketUnits,
              clientSubUnits,
              isThresholdUnitNameCustomSet,
            },
            () => {
              this.prepareDataForRenderingInsightsBarChart();
            },
          );
        } else {
          this.setState({
            isAPILoading: false,
            isEmptyThresholdData: true,
          });
          window.alert('Received empty Threshold data from the API for the selected time frame');
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        reject(error);
        this.setState(
          {
            isAPILoading: false,
          },
          () => {
            this.handleModalHide();
          },
        );
      });
  });

  getReplacedLabelForMU = (wordForm = 'singular') => {
    const { clientSimulationData } = this.props;

    let labelToReturn;
    if (_.isEmpty(clientSimulationData)) {
      labelToReturn = wordForm === 'plural'
        ? replacedLabelForMUInAgnosticViewPlural
        : replacedLabelForMUInAgnosticViewSingular;
    } else {
      labelToReturn = wordForm === 'plural'
        ? replacedLabelForMUInSimulationViewPlural
        : replacedLabelForMUInSimulationViewSingular;
    }
    return labelToReturn;
  };

  // EVENT HANDLER FOR THRESHOLD SELECTION CHANGE. ON CHANGE OF THRESHOLD RE-EXECUTE THE "fetchThreshold" API BASED ON THE SELECTED VALUE OF THE THRESHOLD
  handleChangeThresholdSelectionInInsightsView = (selectedThreshold) => {
    this.setState(
      {
        selectedThreshold,
      },
      () => {
        this.fetchMetricBenchmarkDetails();
      },
    );
  };

  handleModalHide = () => {
    const { onModalClose } = this.props;
    onModalClose();
  };

  // EVENT HANDLER FOR CALENDAR ICON IN DATEPICKER FIELDS, OPENS UP THE PICKER WHEN CLICKED
  handleUpdateDateClickField = (tempStart, tempEnd) => {
    const formattedStartDate = !_.isNil(tempStart) ? formatDate(moment(tempStart)) : '';
    const formattedEndDate = !_.isNil(tempEnd) ? formatDate(moment(tempEnd)) : '';

    this.setState({
      startDate: tempStart,
      formattedStartDate,
      endDate: tempEnd,
      formattedEndDate,
      isVisibleDatepickerDropdownPopover: false,
    });
    const {
      currentView,
      tempPreviousFormattedStartDate,
      tempPreviousFormattedEndDate,
    } = this.state;

    // IF EITHER THE START DATE OR END DATE HAS CHANGED FROM THE PREVIOUS VALUES OR THEIR VALUES ARE EMPTY THEN VALIDATE THE VALUES AND IF VALUES ARE FILLED THEN ONLY EXECUTE THE API CALL
    if (
      formattedStartDate !== tempPreviousFormattedStartDate
      || formattedEndDate !== tempPreviousFormattedEndDate
      || _.isEmpty(formattedStartDate)
      || _.isEmpty(formattedEndDate)
    ) {
      const That = this;
      this.setState(
        {
          tempPreviousFormattedStartDate: formattedStartDate,
          tempPreviousFormattedEndDate: formattedEndDate,
        },
        () => {
          setTimeout(() => {
            let errMsg = '';
            if (_.isEmpty(formattedStartDate) && _.isEmpty(formattedEndDate)) {
              errMsg = 'Both "Start Month" and "End Month" should be filled to retrieve the results';
            } else if (_.isEmpty(formattedStartDate) && !_.isEmpty(formattedEndDate)) {
              errMsg = '"Start Month" should be filled to retrieve the results';
            } else if (!_.isEmpty(formattedStartDate) && _.isEmpty(formattedEndDate)) {
              errMsg = '"End Month" should be filled to retrieve the results';
            }

            if (!_.isEmpty(errMsg)) {
              window.alert(errMsg);
            } else if (currentView === 'metricBenchmarkDetailsView') {
              That.fetchMetricBenchmarkDetails();
            } else {
              That.fetchClientMetricDetails();
            }
          }, 50);
        },
      );
    }
  };

  handleChangeDateField = (date, isStartEndDateSelected) => {
    this.setState({
      [isStartEndDateSelected ? 'tempStartDate' : 'tempEndDate']: date,
      [isStartEndDateSelected ? 'formattedTempStartDate' : 'formattedTempEndDate']: formatDate(
        moment(date),
      ),
    });
  };

  handleClearDate = () => {
    const {
      startDate,
      endDate,
      formattedStartDate,
      formattedEndDate,
    } = this.state;

    this.setState({
      tempStartDate: startDate,
      tempEndDate: endDate,
      formattedTempStartDate: formattedStartDate,
      formattedTempEndDate: formattedEndDate,
    });
  };

  // HIDES THE "DATE SELECTION DROPDOWN" WHEN CLICKED OUTSIDE THE POPOVER
  hideDatepickerDropdownPopover = () => {
    this.setState({
      isVisibleDatepickerDropdownPopover: false,
    });
  };

  toggleDatepickerDropdownPopover = () => {
    const { isVisibleDatepickerDropdownPopover } = this.state;
    this.setState(
      {
        isVisibleDatepickerDropdownPopover: !isVisibleDatepickerDropdownPopover,
        isStartEndDateSelected: true,
      },
      () => {
        if (!isVisibleDatepickerDropdownPopover) {
          const {
            startDate,
            endDate,
            formattedStartDate,
            formattedEndDate,
          } = this.state;

          this.setState({
            tempStartDate: startDate,
            tempEndDate: endDate,
            formattedTempStartDate: formattedStartDate,
            formattedTempEndDate: formattedEndDate,
          });
        }
      },
    );
  };

  // SELECTS "START DATE" OR "END DATE"
  selectStartDate = () => {
    this.setState({
      isStartEndDateSelected: true,
    });
  };

  selectEndDate = () => {
    this.setState({
      isStartEndDateSelected: false,
    });
  };

  // EVENT HANDLER FOR CLIENT INDUSTRY CHECKBOX
  handleChangeClientIndustryCheckbox = (event) => {
    const { offeringType } = this.props;
    const { clientIndustries, tempSelectedClientIndustries } = this.state;

    const isChecked = event.target.checked;
    const industry = event.target.value;
    const clone = _.cloneDeep(tempSelectedClientIndustries);

    // IF CHECKED THEN ADD IT TO THE SELECTION ARRAY, ELSE REMOVE IT FROM THE ARRAY
    if (isChecked) {
      clone.push(industry);
    } else {
      _.remove(clone, (eachItem) => eachItem === industry);
    }

    let tempIsSelectedAllClientIndustries = false;

    // IF OFFERING TYPE IS NOT "INDUSTRY" (FOR SUCH OFFERINGS THE "ALL INDUSTRIES" CHECKBOX WOULD NOT BE DISPLAYED)
    // AND ALL THE INDUSTRIES ARE SELECTED THEN SET "CHECKED" FOR "ALL INDUSTRIES" CHECKBOX
    if (offeringType !== 'industry' && clone.length === clientIndustries.length) {
      tempIsSelectedAllClientIndustries = true;
    }
    this.setState({
      tempSelectedClientIndustries: clone,
      tempIsSelectedAllClientIndustries,
    });
  };

  toggleClientIndustriesPopover = () => {
    const { isVisibleClientIndustriesPopover } = this.state;
    this.setState({
      isVisibleClientIndustriesPopover: !isVisibleClientIndustriesPopover,
    });
  };

  onShowClientIndustriesPopover = () => {
    const { selectedClientIndustries, isSelectedAllClientIndustries } = this.state;
    this.setState({
      tempSelectedClientIndustries: selectedClientIndustries,
      tempIsSelectedAllClientIndustries: isSelectedAllClientIndustries,
    });
  };

  // EVENT HANDLER FOR "CLEAR" BUTTON IN "CLIENT INDUSTRIES CHECKBOXES SELECTION POPOVER"
  handleClickClearSelectedClientIndustries = () => {
    this.setState({
      tempSelectedClientIndustries: [],
      tempIsSelectedAllClientIndustries: false,
    });
  };

  // EVENT HANDLER FOR "UPDATE" BUTTON IN "CLIENT INDUSTRIES CHECKBOXES SELECTION POPOVER"
  handleClickUpdateSelectedClientIndustries = () => {
    const {
      tempSelectedClientIndustries,
      tempIsSelectedAllClientIndustries,
      isSelectedAllClientIndustries,
    } = this.state;

    this.setState(
      {
        selectedClientIndustries: tempSelectedClientIndustries,
        isSelectedAllClientIndustries: tempIsSelectedAllClientIndustries,
        isVisibleClientIndustriesPopover: false,
      },
      () => {
        if (isSelectedAllClientIndustries || tempIsSelectedAllClientIndustries) {
          this.fetchMetricBenchmarkDetails();
        } else {
          this.prepareDataForRenderingInsightsBarChart();
        }
      },
    );
  };

  // HIDES THE "CLIENT INDUSTRIES CHECKBOXES SELECTION POPOVER" WHEN CLICKED OUTSIDE THE POPOVER
  hideClientIndustriesPopover = () => {
    this.setState({
      isVisibleClientIndustriesPopover: false,
    });
  };

  // EVENT HANDLER FOR CLIENT MARKET UNIT CHECKBOX
  handleChangeClientMarketUnitCheckbox = (event) => {
    const { clientMarketUnits, tempSelectedClientMarketUnits } = this.state;

    const isChecked = event.target.checked;
    const marketUnit = event.target.value;
    const clone = _.cloneDeep(tempSelectedClientMarketUnits);

    // IF CHECKED THEN ADD IT TO THE SELECTION ARRAY, ELSE REMOVE IT FROM THE ARRAY
    if (isChecked) {
      clone.push(marketUnit);
    } else {
      _.remove(clone, (eachItem) => eachItem === marketUnit);
    }

    let tempIsSelectedAllClientMarketUnits = false;

    // IF ALL THE MARKET UNITS ARE SELECTED THEN SET "CHECKED" FOR "ALL MARKET UNITS" CHECKBOX
    if (clone.length === clientMarketUnits.length) {
      tempIsSelectedAllClientMarketUnits = true;
    }
    this.setState({
      tempSelectedClientMarketUnits: clone,
      tempIsSelectedAllClientMarketUnits,
    });
  };

  toggleClientMarketUnitsPopover = () => {
    const { isVisibleClientMarketUnitsPopover } = this.state;
    this.setState({
      isVisibleClientMarketUnitsPopover: !isVisibleClientMarketUnitsPopover,
    });
  };

  // HIDES THE "CLIENT MARKET UNITS CHECKBOXES SELECTION POPOVER" WHEN CLICKED OUTSIDE THE POPOVER
  hideClientMarketUnitsPopover = () => {
    this.setState({
      isVisibleClientMarketUnitsPopover: false,
    });
  };

  onShowClientMarketUnitsPopover = () => {
    const { selectedClientMarketUnits, isSelectedAllClientMarketUnits } = this.state;
    this.setState({
      tempSelectedClientMarketUnits: selectedClientMarketUnits,
      tempIsSelectedAllClientMarketUnits: isSelectedAllClientMarketUnits,
    });
  };

  // EVENT HANDLER FOR "CLEAR" BUTTON IN "CLIENT MARKET UNITS CHECKBOXES SELECTION POPOVER"
  handleClickClearSelectedClientMarketUnits = () => {
    this.setState({
      tempSelectedClientMarketUnits: [],
      tempIsSelectedAllClientMarketUnits: false,
    });
  };

  // EVENT HANDLER FOR "UPDATE" BUTTON IN "CLIENT MARKET UNITS CHECKBOXES SELECTION POPOVER"
  handleClickUpdateSelectedClientMarketUnits = () => {
    const {
      tempSelectedClientMarketUnits,
      tempIsSelectedAllClientMarketUnits,
      isSelectedAllClientMarketUnits,
    } = this.state;

    this.setState(
      {
        selectedClientMarketUnits: tempSelectedClientMarketUnits,
        isSelectedAllClientMarketUnits: tempIsSelectedAllClientMarketUnits,
        isVisibleClientMarketUnitsPopover: false,
      },
      () => {
        if (isSelectedAllClientMarketUnits || tempIsSelectedAllClientMarketUnits) {
          this.fetchMetricBenchmarkDetails();
        } else {
          this.prepareDataForRenderingInsightsBarChart();
        }
      },
    );
  };

  // EVENT HANDLER FOR THE "ALL INDUSTRIES" CHECKBOX ON THE INDUSTRIES CHECKBOXES SELECTION POPOVER (TO CONSOLIDATE THE VIEW)
  handleChangeAllClientIndustriesCheckbox = (event) => {
    const { clientIndustries } = this.state;

    const isChecked = event.target.checked;
    let tempSelectedClientIndustries = [];

    // IF CHECKED THEN ADD IT TO THE SELECTION ARRAY, ELSE REMOVE IT FROM THE ARRAY
    if (isChecked) {
      tempSelectedClientIndustries = clientIndustries;
    }

    this.setState({
      tempIsSelectedAllClientIndustries: isChecked,
      tempSelectedClientIndustries,
    });
  };

  // EVENT HANDLER FOR THE "ALL MARKET UNITS" CHECKBOX ON THE MARKET UNITS CHECKBOXES SELECTION POPOVER (TO CONSOLIDATE THE VIEW)
  handleChangeAllClientMarketUnitsCheckbox = (event) => {
    const { clientMarketUnits } = this.state;

    const isChecked = event.target.checked;
    let tempSelectedClientMarketUnits = [];

    // IF CHECKED THEN ADD IT TO THE SELECTION ARRAY, ELSE REMOVE IT FROM THE ARRAY
    if (isChecked) {
      tempSelectedClientMarketUnits = clientMarketUnits;
    }

    this.setState({
      tempIsSelectedAllClientMarketUnits: isChecked,
      tempSelectedClientMarketUnits,
    });
  };

  // EVENT HANDLER FOR "DISPLAY CLIENT NAMES" SWITCH TOGGLE ELEMENT
  handleChangeDisplayQuartiles = (event) => {
    removeChartCustomTooltip();
    const isChecked = event.target.checked;
    this.setState(
      {
        displayQuartiles: isChecked,
      },
      () => {
        this.prepareDataForRenderingInsightsBarChart();
      },
    );
  };

  // EVENT HANDLER FOR "DISPLAY CLIENT NAMES" SWITCH TOGGLE ELEMENT
  handleChangeDisplayClientNames = (event) => {
    const { currentView } = this.state;

    const isChecked = event.target.checked;
    this.setState(
      {
        displayClientNames: isChecked,
      },
      () => {
        if (currentView === 'metricBenchmarkDetailsView') {
          removeChartCustomTooltip();
          this.prepareDataForRenderingInsightsBarChart();
        }
      },
    );
  };

  // EVENT HANDLER FOR "BENCHMARKS" DROPDOWN FOR THE METRIC BENCHMARK CHART
  handleChangeInsightsViewBenchmarkDropdown = (selectedInsightsViewBenchmark) => {
    const { currentView } = this.state;

    this.setState(
      {
        selectedInsightsViewBenchmark,
      },
      () => {
        if (currentView === 'metricBenchmarkDetailsView') {
          // ON CHANGE OF BENCHMARK RADIO OPTION RE-RENDER THE "BENCHMARK METRIC DETAILS" CHART
          this.prepareDataForRenderingInsightsBarChart();
        }
      },
    );
  };

  // RENDERS METRIC BENCHMARK CHART (HORIZONTAL BAR CHART)
  prepareDataForRenderingInsightsBarChart = () => {
    const { clientSimulationData } = this.props;
    const {
      metricBenchmarkDetails,
      selectedClientIndustries,
      selectedClientMarketUnits,
      selectedThreshold,
      thresholdData,
      selectedInsightsViewBenchmark,
      currentLeadingPracticeForMetric,
      displayClientNames,
      displayQuartiles,
      isSelectedAllClientIndustries,
      isSelectedAllClientMarketUnits,
      isClientExisting,
    } = this.state;

    // BACKGROUND COLORS FOR STACKED BARS
    let greenColorForStackBar = 'rgba(150, 205, 103, 0.7)';
    let yellowColorForStackBar = 'rgba(255, 221, 123, 0.7)';
    let redColorForStackBar = 'rgba(213, 0, 47, 0.5)';

    // COLORS FOR VERTICAL DASHED LINES AND CLIENT BUBBLES BORDER
    let green = '#56A911';
    let yellow = '#FFC82C';
    let red = '#D5002F';

    // FOR THE SIMULATION CHART OR IF IT IS THE EXISTING CLIENT, THE COLOR OF THE STACK BARS AND CLIENT BUBBLES ARE DIFFERENT
    if (!_.isEmpty(clientSimulationData) || isClientExisting) {
      // BACKGROUND COLORS FOR STACKED BARS
      greenColorForStackBar = 'rgba(108, 209, 0, 0.2)';
      yellowColorForStackBar = 'rgba(255, 139, 0, 0.2)';
      redColorForStackBar = 'rgba(255, 0, 0, 0.2)';

      // COLORS FOR VERTICAL DASHED LINES AND CLIENT BUBBLES BORDER
      green = '#7ec344';
      yellow = '#ffc82c';
      red = '#e64747';
    }

    // WHEN "ALL INDUSTRIES" OR "ALL MARKET UNITS" CHECKBOX IS SELECTED, THEN FETCH THE DATA FOR THE CONSOLIDATED VIEW
    const isConsolidated = selectedThreshold === 'IndustryWise'
      ? isSelectedAllClientIndustries
      : isSelectedAllClientMarketUnits;
    let filteredThresholdData = thresholdData;
    let allClients = metricBenchmarkDetails.clientDetails;
    let arrClientUnitNameToHighlight = [];
    // FILTER OUT THE EXISTING CLIENT ENTRIES
    const foundExistingClientArr = _.filter(allClients, ['isExisting', true]);

    // FIND THE ADDED SIMULATED CLIENT
    const foundSimulatedClient = _.find(allClients, ['isSimulated', true]);

    if (!_.isEmpty(foundExistingClientArr)) {
      arrClientUnitNameToHighlight = _.map(foundExistingClientArr, (eachExistingClient) => (selectedThreshold === 'IndustryWise'
        ? eachExistingClient.industry
        : eachExistingClient.marketUnit));
    }
    if (!_.isEmpty(foundSimulatedClient)) {
      arrClientUnitNameToHighlight = selectedThreshold === 'IndustryWise'
        ? [foundSimulatedClient.industry]
        : [foundSimulatedClient.marketUnit];
    }

    // WHEN NOT IN "CONSOLIDATED" VIEW, FILTER THE CLIENT DATA BASED ON SELECTED "THRESHOLD" AND SEE IF THERE IS ANY "INDUSTRY" OR "MARKET UNIT" CHECKBOXES ARE SELECTED, SO ONLY FILTER OUT THE SELECTED CLIENTS SO THAT ONLY SELECTED ONES ARE DISPLAYED ON CHARTS (AS BUBBLES)
    if (
      selectedThreshold === 'IndustryWise'
      && selectedClientIndustries.length > 0
      && !isConsolidated
    ) {
      allClients = _.filter(
        allClients,
        (eachClient) => selectedClientIndustries.indexOf(eachClient.industry) !== -1,
      );

      // WHEN NOT IN "CONSOLIDATED" VIEW, FILTER OUT THE `thresholdData` (BENCHMARKS) BASED ON THE `selectedClientIndustries` SO THAT ONLY REQUIRED BENCHMARKS ARE PROCESSED AND DISPLAYED ON THE CHART
      filteredThresholdData = _.filter(
        thresholdData,
        (eachItem) => selectedClientIndustries.indexOf(eachItem.unitName) !== -1,
      );
    } else if (
      selectedThreshold === 'MarketUnit'
      && selectedClientMarketUnits.length > 0
      && !isConsolidated
    ) {
      allClients = _.filter(
        allClients,
        (eachClient) => selectedClientMarketUnits.indexOf(eachClient.marketUnit) !== -1,
      );

      // FILTER OUT THE `thresholdData` (BENCHMARKS) BASED ON THE `selectedClientMarketUnits` SO THAT ONLY REQUIRED BENCHMARKS ARE PROCESSED AND DISPLAYED ON THE CHART
      filteredThresholdData = _.filter(
        thresholdData,
        (eachItem) => selectedClientMarketUnits.indexOf(eachItem.unitName) !== -1,
      );
    }

    // PREPARE DATA ARRAY FOR Y-AXIS LABELS

    // PREPARE Y-AXIS LABELS FROM THE `unitName` ATTRIBUTE FROM THE EACH `filteredThresholdData` OBJECT
    const yAxisLabels = _.map(filteredThresholdData, (eachItem) => eachItem.unitName);

    // *** BELOW IS THE EXPLANATION OF CODE LOGIC OF VERTICAL DASHED LINES WHICH INDICATE "CROSS-INDUSTRY" OR "CROSS-MARKET" (BOTH ARE SAME) BENCHMARK VALUES *************** //
    // "CROSS-INDUSTRY" OR "CROSS-MARKET" (BOTH ARE SAME) BENCHMARKS ARE OBTAINED FROM `metricBenchmarkDetails`

    /**
     * When Leading Practice = 'minimum', it means minimum of the lower or upper quartile values is better (`betterComparatorVal`).
     * So all the metric values which are less than or equal to `betterComparatorVal` are better.
     *
     * Similarly,
     * When Leading Practice = 'maximum', it means maximum of the lower or upper quartile values is better (`betterComparatorVal`).
     * So all the metric values which are greater than or equal to `betterComparatorVal` are better.
     */

    // BY DEFAULT GET "internal" VALUES FOR COMPARISON BECAUSE DEFAULT SELECTED BENCHMARK (RADIO) WOULD BE "internal"
    let crossIndustryLowerQuartileValue = _.get(
      metricBenchmarkDetails,
      'internalLowerQuartileFloatVal',
    );
    let crossIndustryMedianValue = _.get(metricBenchmarkDetails, 'internalMedianFloatVal');
    let crossIndustryUpperQuartileValue = _.get(
      metricBenchmarkDetails,
      'internalUpperQuartileFloatVal',
    );

    // IF THE BENCHMARK RADIO IS SELECTED TO "external" THEN SELECT "external" VALUES FOR COMPARISON
    if (selectedInsightsViewBenchmark === 'external') {
      crossIndustryLowerQuartileValue = _.get(
        metricBenchmarkDetails,
        'externalLowerQuartileFloatVal',
      );
      crossIndustryMedianValue = _.get(metricBenchmarkDetails, 'externalMedianFloatVal');
      crossIndustryUpperQuartileValue = _.get(
        metricBenchmarkDetails,
        'externalUpperQuartileFloatVal',
      );
    }

    const isEmptyCrossIndustryLowerQuartileValue = _.isEmpty(
      String(crossIndustryLowerQuartileValue),
    );
    const isEmptyCrossIndustryMedianValue = _.isEmpty(String(crossIndustryMedianValue));
    const isEmptyCrossIndustryUpperQuartileValue = _.isEmpty(
      String(crossIndustryUpperQuartileValue),
    );

    // PREPARE DATASET FOR CROSS-INDUSTRY BENCHMARKS (DASHED VERTICAL LINES AND CIRCLES)
    let verticalLinesDataset = [];
    // IF ANY OF THE BENCHMARK VALUE IS NOT EMPTY
    if (
      !isEmptyCrossIndustryLowerQuartileValue
      || !isEmptyCrossIndustryMedianValue
      || !isEmptyCrossIndustryUpperQuartileValue
    ) {
      let betterComparatorVal = crossIndustryUpperQuartileValue;
      let worstComparatorVal = crossIndustryLowerQuartileValue;
      const worseComparatorVal = crossIndustryMedianValue;

      // BELOW SEQUENCES ARE IMPORTANT FOR DISPLAYING THE PROPER BACKGROUND COLORS IN PROPER SEQUENCE
      let betterDataSequence = 0;
      const worseDataSequence = 1;
      let worstDataSequence = 2;

      const betterDataObj = {
        color: green,
        backgroundColor: greenColorForGradient,
        sequence: betterDataSequence,
      };

      const worseDataObj = {
        color: yellow,
        backgroundColor: yellowColorForGradient,
        sequence: worseDataSequence,
      };

      const worstDataObj = {
        color: red,
        backgroundColor: redColorForGradient,
        sequence: worstDataSequence,
      };

      if (currentLeadingPracticeForMetric === 'minimum') {
        if (!isEmptyCrossIndustryLowerQuartileValue && !isEmptyCrossIndustryUpperQuartileValue) {
          betterComparatorVal = _.min([
            crossIndustryLowerQuartileValue,
            crossIndustryUpperQuartileValue,
          ]);
          worstComparatorVal = _.max([
            crossIndustryLowerQuartileValue,
            crossIndustryUpperQuartileValue,
          ]);
        }
      } else if (currentLeadingPracticeForMetric === 'maximum') {
        if (!isEmptyCrossIndustryLowerQuartileValue && !isEmptyCrossIndustryUpperQuartileValue) {
          betterComparatorVal = _.max([
            crossIndustryLowerQuartileValue,
            crossIndustryUpperQuartileValue,
          ]);
          worstComparatorVal = _.min([
            crossIndustryLowerQuartileValue,
            crossIndustryUpperQuartileValue,
          ]);
        }

        worstDataSequence = 0;
        betterDataSequence = 2;
      }

      if (!_.isEmpty(String(betterComparatorVal))) {
        verticalLinesDataset.push({
          ...betterDataObj,
          label: 'Upper Quartile',
          value: betterComparatorVal,
          sequence: betterDataSequence,
        });
      } else {
        verticalLinesDataset.push({
          ...betterDataObj,
          sequence: betterDataSequence,
        });
      }

      if (!_.isEmpty(String(worseComparatorVal))) {
        verticalLinesDataset.push({
          ...worseDataObj,
          label: 'Median',
          value: worseComparatorVal,
          sequence: worseDataSequence,
        });
      } else {
        verticalLinesDataset.push({
          ...worseDataObj,
          sequence: worseDataSequence,
        });
      }

      if (!_.isEmpty(String(worstComparatorVal))) {
        verticalLinesDataset.push({
          ...worstDataObj,
          label: 'Lower Quartile',
          value: worstComparatorVal,
          sequence: worstDataSequence,
        });
      } else {
        verticalLinesDataset.push({
          ...worstDataObj,
          sequence: worstDataSequence,
        });
      }

      verticalLinesDataset = _.sortBy(verticalLinesDataset, ['sequence']);
    }

    /**
     * BELOW IS THE LOGIC FOR COLOR CODING OF CLIENT BUBBLES
     * LOOP OVER EACH CLIENT AND FILTER OUT THE BENCHMARK VALUES (FROM `filteredThresholdData`) FOR THE CLIENT INDUSTRY OR MARKET UNIT (BASED ON `selectedThreshold`)
     * BASED ON `selectedInsightsViewBenchmark` WHETHER "internal" OR "external", DECIDE WHICH BENCHMARK VALUES ARE TO PICKED UP FOR COMPARISON WITH EACH CLIENT'S `medianMetricValueFloatVal`
     * BASED ON `currentLeadingPracticeForMetric` WHETHER "minimum" OR "maximum", DECIDE WHETHER "LOWER QUARTILE" VALUE IS BETTER OR "UPPER QUARTILE" VALUE IS BETTER. IF LEADING PRACTICE IS "minimum" LOWER VALUE AMONG BOTH IS BETTER AND IF IT IS "maximum" THEN HIGHER VALUE AMONG BOTH IS BETTER
     * COMPARE `clientMedianMetricValueFloatVal` AGAINST THE BETTER AND WORST VALUES AND BASED ON THE COMPARISON SET AN ATTRIBUTE `indicator` CONTAINING THE VALUE EITHER FROM "better", "worse" OR "worst"
     * Indicator=better - color=green
     * Indicator=worse - color=yellow
     * Indicator=worst - color=red
     */

    // LOOP OVER EACH CLIENT
    const modifiedAllClients = _.map(allClients, (eachClient) => {
      const clientIndustry = eachClient.industry;
      const clientMarketUnit = eachClient.marketUnit;
      const clientMedianMetricValueFloatVal = eachClient.medianMetricValueFloatVal;

      // FIND OUT THE INDUSTRY/MU BENCHMARK VALUES (FROM `filteredThresholdData`) FOR THE CLIENT INDUSTRY OR MARKET UNIT (BASED ON `selectedThreshold`)
      let thresholdDataForClient = {};
      if (selectedThreshold === 'IndustryWise') {
        thresholdDataForClient = _.find(
          filteredThresholdData,
          (eachItem) => eachItem.unitName === clientIndustry,
        );
      } else if (selectedThreshold === 'MarketUnit') {
        thresholdDataForClient = _.find(
          filteredThresholdData,
          (eachItem) => eachItem.unitName === clientMarketUnit,
        );
      }

      // BY DEFAULT GET "internal" VALUES FOR COMPARISON BECAUSE DEFAULT SELECTED BENCHMARK (RADIO) WOULD BE "internal"
      let lowerQuartileValue = _.get(thresholdDataForClient, 'internalLowerQuartileFloatVal');
      let medianValue = _.get(thresholdDataForClient, 'internalMedianFloatVal');
      let upperQuartileValue = _.get(thresholdDataForClient, 'internalUpperQuartileFloatVal');

      // IF THE BENCHMARK RADIO IS SELECTED TO "external" THEN SELECT "external" VALUES FOR COMPARISON
      if (selectedInsightsViewBenchmark === 'external') {
        lowerQuartileValue = _.get(thresholdDataForClient, 'externalLowerQuartileFloatVal');
        medianValue = _.get(thresholdDataForClient, 'externalMedianFloatVal');
        upperQuartileValue = _.get(thresholdDataForClient, 'externalUpperQuartileFloatVal');
      }

      // IF INDUSTRY (OR MARKET UNIT) BENCHMARK VALUES ARE EMPTY THEN COLOR CODE THE CLIENT BUBBLES BASED ON THE CROSS-INDUSTRY (OR CROSS-MARKET UNIT (BOTH ARE SAME)) BENCHMARK VALUES
      if (
        _.isEmpty(String(lowerQuartileValue))
        && _.isEmpty(String(medianValue))
        && _.isEmpty(String(upperQuartileValue))
      ) {
        lowerQuartileValue = crossIndustryLowerQuartileValue;
        medianValue = crossIndustryMedianValue;
        upperQuartileValue = crossIndustryUpperQuartileValue;
      }

      const isEmptyLowerQuartileValue = _.isEmpty(String(lowerQuartileValue));
      const isEmptyMedianValue = _.isEmpty(String(medianValue));
      const isEmptyUpperQuartileValue = _.isEmpty(String(upperQuartileValue));

      // IF LEADING PRACTICE IS "minimum", LOWER VALUE AMONG `lowerQuartileValue` AND `upperQuartileValue` IS BETTER AND IF IT IS "maximum" THEN HIGHER VALUE IS BETTER
      let betterComparatorVal;
      let worstComparatorVal;

      // INDICATES WHETHER THE CLIENT VALUES IS "better (green)", "worst (red)" OR "worse (yellow)"
      let indicator = '';

      if (currentLeadingPracticeForMetric === 'minimum') {
        if (
          (!isEmptyLowerQuartileValue && !isEmptyMedianValue && !isEmptyUpperQuartileValue)
          || (!isEmptyLowerQuartileValue && isEmptyMedianValue && !isEmptyUpperQuartileValue)
        ) {
          // WHEN (ALL THE BENCHMARK VALUES i.e lowerQuartile, median & upperQuartile ARE NON-EMPTY) OR
          // (median IS EMPTY, lowerQuartile & upperQuartile ARE NON-EMPTY)
          // THEN, LOWER VALUE AMONG `lowerQuartileValue` AND `upperQuartileValue` IS BETTER
          betterComparatorVal = _.min([lowerQuartileValue, upperQuartileValue]);
          worstComparatorVal = _.max([lowerQuartileValue, upperQuartileValue]);
        } else if (!isEmptyLowerQuartileValue && isEmptyMedianValue && isEmptyUpperQuartileValue) {
          // WHEN lowerQuartile IS NON-EMPTY, median & upperQuartile ARE EMPTY
          betterComparatorVal = lowerQuartileValue - 0.01;
          worstComparatorVal = lowerQuartileValue;
        } else if (isEmptyLowerQuartileValue && !isEmptyMedianValue && isEmptyUpperQuartileValue) {
          // WHEN median IS NON-EMPTY, lowerQuartile & upperQuartile ARE EMPTY
          betterComparatorVal = medianValue - 0.01;
          worstComparatorVal = medianValue + 0.01;
        } else if (isEmptyLowerQuartileValue && isEmptyMedianValue && !isEmptyUpperQuartileValue) {
          // WHEN upperQuartile IS NON-EMPTY, lowerQuartile & median ARE EMPTY
          betterComparatorVal = upperQuartileValue;
          worstComparatorVal = upperQuartileValue + 0.01;
        } else if (!isEmptyLowerQuartileValue && !isEmptyMedianValue && isEmptyUpperQuartileValue) {
          // WHEN lowerQuartile & median ARE NON-EMPTY, upperQuartile IS EMPTY
          betterComparatorVal = medianValue - 0.01;
          worstComparatorVal = lowerQuartileValue;
        } else if (isEmptyLowerQuartileValue && !isEmptyMedianValue && !isEmptyUpperQuartileValue) {
          // WHEN lowerQuartile IS EMPTY, median & upperQuartile ARE NON-EMPTY
          betterComparatorVal = upperQuartileValue;
          worstComparatorVal = medianValue + 0.01;
        }

        indicator = getIndicatorValueForMetricValueBasedOnCriteria(
          clientMedianMetricValueFloatVal,
          betterComparatorVal,
          worstComparatorVal,
          currentLeadingPracticeForMetric,
        );
      } else if (currentLeadingPracticeForMetric === 'maximum') {
        if (
          (!isEmptyLowerQuartileValue && !isEmptyMedianValue && !isEmptyUpperQuartileValue)
          || (!isEmptyLowerQuartileValue && isEmptyMedianValue && !isEmptyUpperQuartileValue)
        ) {
          // WHEN (ALL THE BENCHMARK VALUES i.e lowerQuartile, median & upperQuartile ARE NON-EMPTY) OR
          // (median IS EMPTY, lowerQuartile & upperQuartile ARE NON-EMPTY)
          // THEN, GREATER VALUE AMONG `lowerQuartileValue` AND `upperQuartileValue` IS BETTER
          betterComparatorVal = _.max([lowerQuartileValue, upperQuartileValue]);
          worstComparatorVal = _.min([lowerQuartileValue, upperQuartileValue]);
        } else if (!isEmptyLowerQuartileValue && isEmptyMedianValue && isEmptyUpperQuartileValue) {
          // WHEN lowerQuartile IS NON-EMPTY, median & upperQuartile ARE EMPTY
          betterComparatorVal = lowerQuartileValue + 0.01;
          worstComparatorVal = lowerQuartileValue;
        } else if (isEmptyLowerQuartileValue && !isEmptyMedianValue && isEmptyUpperQuartileValue) {
          // WHEN median IS NON-EMPTY, lowerQuartile & upperQuartile ARE EMPTY
          betterComparatorVal = medianValue + 0.01;
          worstComparatorVal = medianValue - 0.01;
        } else if (isEmptyLowerQuartileValue && isEmptyMedianValue && !isEmptyUpperQuartileValue) {
          // WHEN upperQuartile IS NON-EMPTY, lowerQuartile & median ARE EMPTY
          betterComparatorVal = upperQuartileValue;
          worstComparatorVal = upperQuartileValue - 0.01;
        } else if (!isEmptyLowerQuartileValue && !isEmptyMedianValue && isEmptyUpperQuartileValue) {
          // WHEN lowerQuartile & median ARE NON-EMPTY, upperQuartile IS EMPTY
          betterComparatorVal = medianValue + 0.01;
          worstComparatorVal = lowerQuartileValue;
        } else if (isEmptyLowerQuartileValue && !isEmptyMedianValue && !isEmptyUpperQuartileValue) {
          // WHEN lowerQuartile IS EMPTY, median & upperQuartile ARE NON-EMPTY
          betterComparatorVal = upperQuartileValue;
          worstComparatorVal = medianValue - 0.01;
        }

        indicator = getIndicatorValueForMetricValueBasedOnCriteria(
          clientMedianMetricValueFloatVal,
          betterComparatorVal,
          worstComparatorVal,
          currentLeadingPracticeForMetric,
        );
      }

      // WHEN ALL THE BENCHMARK VALUES ARE EMPTY, THEN DON'T COLOR CODE THE CLIENT BUBBLE
      if (isEmptyLowerQuartileValue && isEmptyMedianValue && isEmptyUpperQuartileValue) {
        indicator = 'none';
      }

      return {
        ...eachClient,
        indicator,
      };
    });

    const arrColorHighlightForClientUnitName = [];

    // PREPARE DATA TO DISPLAY CLIENT BUBBLES
    const bubbleChartDatasets = _.map(modifiedAllClients, (eachClient) => {
      const currentClientMedianMetricValue = eachClient.medianMetricValue;
      const currentClientIndustry = eachClient.industry;
      const currentClientMarketUnit = eachClient.marketUnit;

      // FOR THE CURRENT CLIENT THAT IS BEING LOOPED OVER, FIND THE NUMBER OF OTHER CLIENTS THAT HAVE THE SAME `medianMetricValue` VALUE
      const clientCountWithSameValues = _.filter(modifiedAllClients, (eachItem) => {
        let isClientWithSameValue = false;

        if (selectedThreshold === 'IndustryWise') {
          isClientWithSameValue = eachItem.medianMetricValue === currentClientMedianMetricValue
            && eachItem.industry === currentClientIndustry;
        } else if (selectedThreshold === 'MarketUnit') {
          isClientWithSameValue = eachItem.medianMetricValue === currentClientMedianMetricValue
            && eachItem.marketUnit === currentClientMarketUnit;
        }
        return isClientWithSameValue;
      }).length;

      let pointColor = '';
      const isCurrentClientSimulated = eachClient.isSimulated;
      const isCurrentClientExisting = eachClient.isExisting;
      // IF LEADING PRACTICE IS "minimum" LOWER VALUE IS BETTER AND IF IT IS "maximum" THEN HIGHER VALUE IS BETTER
      if (eachClient.indicator === 'better') {
        pointColor = green;
        if (isCurrentClientSimulated || isCurrentClientExisting) {
          pointColor = greenColorForSimulatedClientBubble;
          arrColorHighlightForClientUnitName.push(greenColorForSimulatedClientBubble);
        }
      } else if (eachClient.indicator === 'worst') {
        pointColor = red;
        if (isCurrentClientSimulated || isCurrentClientExisting) {
          pointColor = redColorForSimulatedClientBubble;
          arrColorHighlightForClientUnitName.push(redColorForSimulatedClientBubble);
        }
      } else if (eachClient.indicator === 'none') {
        // WHEN ALL THE BENCHMARK VALUES ARE EMPTY, THEN DON'T COLOR CODE THE CLIENT BUBBLE
        pointColor = '#ABABAB';
        arrColorHighlightForClientUnitName.push('');
      } else {
        pointColor = yellow;
        if (isCurrentClientSimulated || isCurrentClientExisting) {
          pointColor = yellowColorForSimulatedClientBubble;
          arrColorHighlightForClientUnitName.push(yellowColorForSimulatedClientBubble);
        }
      }

      let bubbleRadius = 8;
      // FOR THE SIMULATED OR EXISTING CLIENT BUBBLE, DISPLAY IT IN A DIFFERENT COLOR AND WITH BIGGER SIZE TO HIGHLIGHT IT
      if (isCurrentClientSimulated || isCurrentClientExisting) {
        bubbleRadius = 12;
      }

      let bubbleData = [
        {
          x: eachClient.medianMetricValueFloatVal,
          y: selectedThreshold === 'IndustryWise' ? eachClient.industry : eachClient.marketUnit,
          r: bubbleRadius, // radius of bubble
        },
      ];
      let bubbleBorderColor = pointColor;
      let bubbleBackgroundColor = '#ffffff';

      // CLIENT SIMULATION (OR EXISTING CLIENT) IS BAR CHART AND NOT A HORIZONTAL BAR CHART, SO SET APPROPRIATE x & y VALUES FOR BUBBLES
      if (!_.isEmpty(foundSimulatedClient) || !_.isEmpty(foundExistingClientArr)) {
        bubbleData = [
          {
            x: selectedThreshold === 'IndustryWise' ? eachClient.industry : eachClient.marketUnit,
            y: eachClient.medianMetricValueFloatVal,
            r: bubbleRadius, // radius of bubble
          },
        ];
        bubbleBorderColor = 'white';
        bubbleBackgroundColor = pointColor;
      }

      return {
        type: 'bubble',
        label: displayClientNames ? eachClient.clientName : eachClient.aliasName,
        datalabels: {
          formatter() {
            // IF THERE ARE MULTIPLE CLIENT BUBBLES THAT ARE SUPER IMPOSED ON EACH OTHER BECAUSE OF HAVING THE SAME VALUE, THEN DISPLAY THE LABEL ON THE BUBBLE WITH THE COUNT
            return clientCountWithSameValues > 1 ? `+${clientCountWithSameValues}` : '';
          },
          font: {
            size: 10,
            weight: 600,
          },
        },
        // CLIENTS ARE DISPLAYED AS BUBBLES
        data: bubbleData,
        borderColor: bubbleBorderColor,
        backgroundColor: bubbleBackgroundColor,
        fill: false,
        showLine: false,
        borderWidth: 2,
        hoverRadius: 2,
        hitRadius: 2,
      };
    });

    // FIND THE MIN & MAX VALUES FROM CLIENTS ARRAY
    const minFromClient = _.get(
      _.minBy(allClients, (eachClient) => eachClient.medianMetricValueFloatVal),
      'medianMetricValueFloatVal',
    );
    const maxFromClient = _.get(
      _.maxBy(allClients, (eachClient) => eachClient.medianMetricValueFloatVal),
      'medianMetricValueFloatVal',
    );

    // FIND OUT THE MINIMUM & MAXIMUM VALUE FROM `verticalLinesDataset` (CROSS-INDUSTRY BENCHMARKS)
    const minFromCrossIndustry = _.min(_.map(verticalLinesDataset, (eachItem) => eachItem.value));
    const maxFromCrossIndustry = _.max(_.map(verticalLinesDataset, (eachItem) => eachItem.value));

    // FIND OUT THE MINIMUM & MAXIMUM VALUE FROM IN/MU BENCHMARK VALUES BY CREATING AN ARRAY CONTAINING ALL BENCHMARK VALUES SO THAT MIM AND MAX VALUES CAN BE FOUND OUT FROM THE ARRAY
    const industryBenchmarksValuesArray = [];
    _.forEach(filteredThresholdData, (eachThresholdObj) => {
      let lowerQuartile = eachThresholdObj.internalLowerQuartileFloatVal;
      let median = eachThresholdObj.internalMedianFloatVal;
      let upperQuartile = eachThresholdObj.internalUpperQuartileFloatVal;

      if (selectedInsightsViewBenchmark === 'external') {
        lowerQuartile = eachThresholdObj.externalLowerQuartileFloatVal;
        median = eachThresholdObj.externalMedianFloatVal;
        upperQuartile = eachThresholdObj.externalUpperQuartileFloatVal;
      }

      if (!_.isEmpty(String(lowerQuartile))) {
        industryBenchmarksValuesArray.push(lowerQuartile);
      }
      if (!_.isEmpty(String(median))) {
        industryBenchmarksValuesArray.push(median);
      }
      if (!_.isEmpty(String(upperQuartile))) {
        industryBenchmarksValuesArray.push(upperQuartile);
      }
    });

    const minFromIndustryBenchmark = !_.isEmpty(industryBenchmarksValuesArray)
      ? _.min(industryBenchmarksValuesArray)
      : '';
    const maxFromIndustryBenchmark = !_.isEmpty(industryBenchmarksValuesArray)
      ? _.max(industryBenchmarksValuesArray)
      : '';

    // FIND OUT MIN & MAX FROM ALL THE CLIENTS VALUES, INDUSTRY (OR MARKET UNIT) BENCHMARKS AND CROSS-INDUSTRY BENCHMARKS SO THAT THE MIN & MAX VALUES FOR X-AXIS CAN BE SET
    const minFromAll = _.min(
      _.filter(
        [minFromIndustryBenchmark, minFromClient, minFromCrossIndustry],
        (eachItem) => !_.isEmpty(String(eachItem)),
      ),
    );
    const maxFromAll = _.max(
      _.filter(
        [maxFromIndustryBenchmark, maxFromClient, maxFromCrossIndustry],
        (eachItem) => !_.isEmpty(String(eachItem)),
      ),
    );

    // CALCULATE THE MAX VALUE OF THE POINT TO BE PLOTTED ON THE X-AXIS. THIS VALUE WOULD FURTHER HELP IN DYNAMICALLY CALCULATING THE VALUE FOR THE LAST STACK BAR
    const xAxisMinVal = parseInt(minFromAll, 10) - 2;
    const xAxisMaxVal = parseInt(maxFromAll, 10) + 2;

    /// ///////////////////////////////////////////////////////////
    // PREPARE DATA ARRAYS THAT WILL USED TO POPULATE BAR STACK DATASETS
    let firstStackDataset = [];
    let secondStackDataset = [];
    let thirdStackDataset = [];
    _.forEach(filteredThresholdData, (eachThreshold) => {
      let lowerQuartileValue = selectedInsightsViewBenchmark === 'internal'
        ? eachThreshold.internalLowerQuartileFloatVal
        : eachThreshold.externalLowerQuartileFloatVal;
      const medianValue = selectedInsightsViewBenchmark === 'internal'
        ? eachThreshold.internalMedianFloatVal
        : eachThreshold.externalMedianFloatVal;
      let upperQuartileValue = selectedInsightsViewBenchmark === 'internal'
        ? eachThreshold.internalUpperQuartileFloatVal
        : eachThreshold.externalUpperQuartileFloatVal;

      const isEmptyLowerQuartileValue = _.isEmpty(String(lowerQuartileValue));
      const isEmptyMedianValue = _.isEmpty(String(medianValue));
      const isEmptyUpperQuartileValue = _.isEmpty(String(upperQuartileValue));

      // BELOW FLAGS THAT INDICATE WHETHER THE BENCHMARK VALUES ARE MANUALLY CALCULATED AND SUBSTITUTED WHEN PARTIAL BENCHMARK VALUES ARE AVAILABLE. SUBSTITUTED VALUES ARE NOT THE ACTUAL VALUES SO FOR THE SUBSTITUTED BENCHMARK VALUES A MESSAGE WOULD BE SHOW IN THE TOOLTIP THAT "BENCHMARK VALUE DOES NOT EXIST"
      let isLowerQuartileValueSubstituted = false;
      let isUpperQuartileValueSubstituted = false;
      let isMedianValueSubstituted = false;

      if (!isEmptyLowerQuartileValue && isEmptyMedianValue && isEmptyUpperQuartileValue) {
        // WHEN lowerQuartile IS NON-EMPTY, median & upperQuartile ARE EMPTY
        upperQuartileValue = (currentLeadingPracticeForMetric === 'minimum'
          ? lowerQuartileValue - 0.01
          : lowerQuartileValue + 0.01
        ).toFixed(2);
        isUpperQuartileValueSubstituted = true;
      } else if (isEmptyLowerQuartileValue && !isEmptyMedianValue && isEmptyUpperQuartileValue) {
        // WHEN median IS NON-EMPTY, lowerQuartile & upperQuartile ARE EMPTY
        lowerQuartileValue = (currentLeadingPracticeForMetric === 'minimum'
          ? medianValue + 0.01
          : medianValue - 0.01
        ).toFixed(2);
        upperQuartileValue = (currentLeadingPracticeForMetric === 'minimum'
          ? medianValue - 0.01
          : medianValue + 0.01
        ).toFixed(2);

        isLowerQuartileValueSubstituted = true;
        isUpperQuartileValueSubstituted = true;
      } else if (isEmptyLowerQuartileValue && isEmptyMedianValue && !isEmptyUpperQuartileValue) {
        // WHEN upperQuartile IS NON-EMPTY, lowerQuartile & median ARE EMPTY
        lowerQuartileValue = (currentLeadingPracticeForMetric === 'minimum'
          ? upperQuartileValue + 0.01
          : upperQuartileValue - 0.01
        ).toFixed(2);
        isLowerQuartileValueSubstituted = true;
      } else if (!isEmptyLowerQuartileValue && !isEmptyMedianValue && isEmptyUpperQuartileValue) {
        // WHEN lowerQuartile & median ARE NON-EMPTY, upperQuartile IS EMPTY
        upperQuartileValue = (currentLeadingPracticeForMetric === 'minimum'
          ? medianValue - 0.01
          : medianValue + 0.01
        ).toFixed(2);
        isUpperQuartileValueSubstituted = true;
      } else if (isEmptyLowerQuartileValue && !isEmptyMedianValue && !isEmptyUpperQuartileValue) {
        // WHEN lowerQuartile IS EMPTY, median & upperQuartile ARE NON-EMPTY
        lowerQuartileValue = (currentLeadingPracticeForMetric === 'minimum'
          ? medianValue + 0.01
          : medianValue - 0.01
        ).toFixed(2);
        isLowerQuartileValueSubstituted = true;
      } else if (!isEmptyLowerQuartileValue && isEmptyMedianValue && !isEmptyUpperQuartileValue) {
        // WHEN lowerQuartile & upperQuartile ARE NON-EMPTY, median IS EMPTY
        isMedianValueSubstituted = true;
      }

      const lowerQuartileData = {
        label: 'Lower Quartile',
        value: lowerQuartileValue,
        isValueSubstituted: isLowerQuartileValueSubstituted,
      };
      const upperQuartileData = {
        label: 'Upper Quartile',
        value: upperQuartileValue,
        isValueSubstituted: isUpperQuartileValueSubstituted,
      };
      const array = [lowerQuartileData, upperQuartileData];

      // FIND OUT MINIMUM
      const minData = _.minBy(array, (eachItem) => eachItem.value);
      // FIND OUT MAXIMUM
      const maxData = _.maxBy(array, (eachItem) => eachItem.value);

      const smallerValueDataValue = minData.value;
      let smallerValueLabel = minData.label;

      const greaterValueDataValue = maxData.value;
      let greaterValueLabel = maxData.label;

      const mediumValueDataValue = medianValue;

      // IF BOTH THE `smaller` AND `upper` VALUES ARE SAME (IDEALLY THEY SHOULD NOT BE THE SAME WHEN NON-ZERO), THEN BASED ON THE "LEADING PRACTICE" SET THE LABELS OF THE DATASETS SO THAT BOTH THE DATASETS DO NOT HAVE THE SAME LABELS
      if (smallerValueDataValue === greaterValueDataValue) {
        if (currentLeadingPracticeForMetric === 'minimum') {
          smallerValueLabel = 'Upper Quartile';
          greaterValueLabel = 'Lower Quartile';
        } else if (currentLeadingPracticeForMetric === 'maximum') {
          smallerValueLabel = 'Lower Quartile';
          greaterValueLabel = 'Upper Quartile';
        }
      }

      const smallerValueDataset = {
        id: 'first_stack',
        label: smallerValueLabel,
        unitName: eachThreshold.unitName, // Industry or MarketUnit name
        originalValue: smallerValueDataValue,
        isValueSubstituted: minData.isValueSubstituted,
      };

      const greaterValueDataset = {
        id: 'third_stack',
        label: greaterValueLabel,
        unitName: eachThreshold.unitName,
        originalValue: greaterValueDataValue,
        isValueSubstituted: maxData.isValueSubstituted,
      };

      const mediumValueDataset = {
        id: 'second_stack',
        label: 'Median',
        unitName: eachThreshold.unitName,
        originalValue: mediumValueDataValue,
        isValueSubstituted: isMedianValueSubstituted,
      };

      firstStackDataset.push(smallerValueDataset);
      secondStackDataset.push(mediumValueDataset);
      thirdStackDataset.push(greaterValueDataset);
    });

    // RETURNS `true` (MEANS THE STACK BAR IS TO BE PLOTTED) IF ANY OF THE BENCHMARK VALUE IS NOT EMPTY
    function checkIfStackBarIsToBePlotted(index) {
      const firstStackBarOriginalVal = _.get(firstStackDataset, `${index}.originalValue`);
      const secondStackBarOriginalVal = _.get(secondStackDataset, `${index}.originalValue`);
      const thirdStackBarOriginalVal = _.get(thirdStackDataset, `${index}.originalValue`);

      let isStackBarToBePlotted = true;
      // DO NOT PLOT THE STACK BAR IF ALL THE THREE BENCHMARK VALUES ARE EMPTY
      if (
        _.isEmpty(String(firstStackBarOriginalVal))
        && _.isEmpty(String(secondStackBarOriginalVal))
        && _.isEmpty(String(thirdStackBarOriginalVal))
      ) {
        isStackBarToBePlotted = false;
      }

      return isStackBarToBePlotted;
    }

    const emptyVal = ['', ''];

    firstStackDataset = _.map(firstStackDataset, (eachItem, index) => {
      let isStackBarToBePlotted = checkIfStackBarIsToBePlotted(index);
      if (
        (!_.isEmpty(foundSimulatedClient) || !_.isEmpty(foundExistingClientArr))
        && !displayQuartiles
        && _.indexOf(arrClientUnitNameToHighlight, eachItem.unitName) === -1
      ) {
        isStackBarToBePlotted = false;
      }
      return {
        ...eachItem,
        value: isStackBarToBePlotted ? [xAxisMinVal, eachItem.originalValue] : emptyVal,
      };
    });

    secondStackDataset = _.map(secondStackDataset, (eachItem, index) => {
      // ALL THE AREA OF THE SECOND STACK BAR FROM THE END OF THE FIRST STACK BAR TILL THE START OF THE THIRD STACK BAR WILL BE INCLUDED IN THE MEDIAN (YELLOW COLORED) ZONE
      const firstStackBarOriginalVal = _.get(firstStackDataset, `${index}.originalValue`);
      const thirdStackBarOriginalVal = _.get(thirdStackDataset, `${index}.originalValue`);

      let isStackBarToBePlotted = checkIfStackBarIsToBePlotted(index);

      if (
        (!_.isEmpty(foundSimulatedClient) || !_.isEmpty(foundExistingClientArr))
        && !displayQuartiles
        && _.indexOf(arrClientUnitNameToHighlight, eachItem.unitName) === -1
      ) {
        isStackBarToBePlotted = false;
      }
      return {
        ...eachItem,
        value: isStackBarToBePlotted
          ? [firstStackBarOriginalVal, thirdStackBarOriginalVal]
          : emptyVal,
      };
    });

    thirdStackDataset = _.map(thirdStackDataset, (eachItem, index) => {
      let isStackBarToBePlotted = checkIfStackBarIsToBePlotted(index);
      if (
        (!_.isEmpty(foundSimulatedClient) || !_.isEmpty(foundExistingClientArr))
        && !displayQuartiles
        && _.indexOf(arrClientUnitNameToHighlight, eachItem.unitName) === -1
      ) {
        isStackBarToBePlotted = false;
      }
      return {
        ...eachItem,
        value: isStackBarToBePlotted ? [eachItem.originalValue, xAxisMaxVal] : emptyVal,
      };
    });

    // DATASET FOR HORIZONTAL STACK BARS, IF LEADING PRACTICE IS "MAXIMUM" THEN START THE "BACKGROUND COLOR" OF THE STACKED BARS FROM RED TILL GREEN TO INDICATE MAXIMUM VALUES ARE BETTER AND IF LEADING PRACTICE IS "MINIMUM" THEN START THE "BACKGROUND COLOR" OF THE STACKED BARS FROM GREEN TILL RED TO INDICATE MINIMUM VALUES ARE BETTER
    const firstStackBarChartDataset = {
      label: 'first_stack',
      backgroundColor:
        currentLeadingPracticeForMetric === 'maximum' ? redColorForStackBar : greenColorForStackBar,
      data: _.map(firstStackDataset, (eachItem) => eachItem.value),
      datalabels: {
        display: false,
      },
    };

    const secondStackBarChartDataset = {
      label: 'second_stack',
      backgroundColor: yellowColorForStackBar,
      data: _.map(secondStackDataset, (eachItem) => eachItem.value),
      datalabels: {
        display: false,
      },
    };

    const thirdStackBarChartDataset = {
      label: 'third_stack',
      backgroundColor:
        currentLeadingPracticeForMetric === 'maximum' ? greenColorForStackBar : redColorForStackBar,
      data: _.map(thirdStackDataset, (eachItem) => eachItem.value),
      datalabels: {
        display: false,
      },
    };

    // ARRAY FOR STACK BARS DATASETS
    const stackDatasets = [
      firstStackBarChartDataset,
      secondStackBarChartDataset,
      thirdStackBarChartDataset,
    ];
    const chartDatasets = _.concat(bubbleChartDatasets, stackDatasets);

    if (!_.isEmpty(foundSimulatedClient) || isClientExisting) {
      this.renderClientSimulationInsightsBarChart({
        chartDatasets,
        yAxisLabels,
        verticalLinesDataset,
        firstStackDataset,
        secondStackDataset,
        thirdStackDataset,
        modifiedAllClients,
        filteredThresholdData,
        xAxisMinVal,
        xAxisMaxVal,
        arrColorHighlightForClientUnitName,
        arrClientUnitNameToHighlight,
        isConsolidated,
      });
    } else {
      this.renderClientAgnosticInsightsHorizontalBarChart({
        chartDatasets,
        yAxisLabels,
        verticalLinesDataset,
        firstStackDataset,
        secondStackDataset,
        thirdStackDataset,
        modifiedAllClients,
        filteredThresholdData,
        xAxisMinVal,
        xAxisMaxVal,
        isConsolidated,
      });
    }

    const legendContent = this.chart.generateLegend();
    this.setState({
      barChartDefaultLegendContent: legendContent,
      barChartCrossIndustryLegendData: verticalLinesDataset,
    });
  };

  // CLIENT SIMULATION BAR CHART
  renderClientSimulationInsightsBarChart = (chartProps) => {
    const { metricUnit, selectedThreshold } = this.state;
    const {
      chartDatasets,
      yAxisLabels,
      verticalLinesDataset,
      firstStackDataset,
      secondStackDataset,
      thirdStackDataset,
      modifiedAllClients,
      filteredThresholdData,
      xAxisMinVal,
      xAxisMaxVal,
      arrClientUnitNameToHighlight,
      isConsolidated,
    } = chartProps;

    this.ctx = this.metricBenchmarkChartRef.current.getContext('2d');

    // IF THE CHART WAS ALREADY RENDERED BEFORE THEN DESTROY IT
    if (!_.isNil(this.chart)) {
      this.chart.destroy();
    }
    const That = this;
    // BAR CHART FOR CLIENT SIMULATION
    this.chart = new Chart(this.ctx, {
      type: 'bar',
      data: {
        datasets: chartDatasets,
        labels: yAxisLabels,
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 15,
          },
        },
        events: ['click'],
        // DATA FOR PLOTTING VERTICAL DASHED LINES AND RECTANGLES FOR CROSS-INDUSTRY BENCHMARKS
        benchmarkData: verticalLinesDataset,
        legend: {
          display: false,
          labels: {
            generateLabels(chart) {
              const { data } = chart;

              if (data.datasets.length) {
                const legendElementsArray = data.datasets.map((eachDataset, i) => {
                  const datasetType = eachDataset.type;
                  const datasetLabel = eachDataset.label;
                  let legendObj = {};

                  // DON'T RENDER LEGEND FOR CLIENT BUBBLES
                  if (datasetType !== 'bubble') {
                    let legendLabel = datasetLabel;
                    const legendFillColor = eachDataset.backgroundColor;

                    // find the first dataset object from the array where the value is not zero
                    let firstObjFromStackDataset = {};
                    if (datasetLabel === 'first_stack') {
                      firstObjFromStackDataset = _.find(
                        firstStackDataset,
                        (eachItem) => !_.isEmpty(String(eachItem.originalValue)),
                      );
                    } else if (datasetLabel === 'second_stack') {
                      firstObjFromStackDataset = _.find(secondStackDataset, (eachItem, index) => {
                        const firstStackDatasetVal = _.get(
                          firstStackDataset,
                          `${index}.originalValue`,
                        );
                        const thirdStackDatasetVal = _.get(
                          thirdStackDataset,
                          `${index}.originalValue`,
                        );

                        // DON'T DISPLAY THE LEGEND FOR MEDIAN IF BOTH THE UPPER QUARTILE AND LOWER QUARTILE VALUES ARE ZERO
                        let showLegendForMedian = true;
                        if (firstStackDatasetVal === 0 && thirdStackDatasetVal === 0) {
                          showLegendForMedian = false;
                        } else if (
                          _.isEmpty(String(firstStackDatasetVal))
                          && _.isEmpty(String(thirdStackDatasetVal))
                        ) {
                          showLegendForMedian = false;
                        }

                        return showLegendForMedian;
                      });
                    } else if (datasetLabel === 'third_stack') {
                      firstObjFromStackDataset = _.find(
                        thirdStackDataset,
                        (eachItem) => !_.isEmpty(String(eachItem.originalValue)),
                      );
                    }

                    legendLabel = _.get(firstObjFromStackDataset, 'label', '');

                    // IF NO OBJ FROM ARRAY WAS FOUND WITH NON-ZERO VALUE THEN RETURN EMPTY OBJECT SO THE LEGEND WOULD NOT BE RENDERED FOR THIS DATASET
                    if (_.isEmpty(firstObjFromStackDataset)) {
                      return {};
                    }
                    const meta = chart.getDatasetMeta(0);
                    const ds = data.datasets[0];
                    const arc = meta.data[i];
                    const custom = (arc && arc.custom) || {};
                    const { getValueAtIndexOrDefault } = Chart.helpers;
                    const arcOpts = chart.options.elements.arc;
                    const bw = custom.borderWidth
                      ? custom.borderWidth
                      : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

                    legendObj = {
                      text: legendLabel,
                      fillStyle: legendFillColor,
                      strokeStyle: legendFillColor,
                      lineWidth: bw,
                      hidden: false,
                      index: i,
                    };
                  }
                  return legendObj;
                });

                // ONLY RENDER THE LEGENDS FOR WHICH THE LEGEND OBJECT IS NOT EMPTY
                const filteredLegendElementsArray = _.filter(
                  legendElementsArray,
                  (eachLegendObj) => !_.isEmpty(eachLegendObj),
                );
                return filteredLegendElementsArray;
              }
              return [];
            },
          },
        },
        // CALLBACK FUNCTION FOR CUSTOM HTML RENDERING OF LEGENDS. WILL BE EXECUTED WHEN `chart.generateLegend()` FUNCTION WILL BE CALLED. WILL RETURN THE HTML STRING THAT COULD BE RENDERED IN THE INNER HTML OF ANY HTML ELEMENT
        legendCallback(chart) {
          // THE FILTERED LEGEND ITEMS HERE ARE RECEIVED FROM `legend.labels.generateLabels` FUNCTION
          const filteredLegendItems = chart.legend.legendItems;

          const legendTitle = selectedThreshold === 'IndustryWise' ? 'Industry' : That.getReplacedLabelForMU();
          let legendHTML = '';
          if (filteredLegendItems.length > 0) {
            legendHTML = `
            <ul>
              <li><b>${legendTitle}:</b></li>
              <li>
                <div class="chart-legend">
                  <ul>`;

            filteredLegendItems.forEach((eachLegendItem) => {
              const item = `
                <li>
                  <span style="background-color: ${eachLegendItem.fillStyle
                }"></span>
                  ${eachLegendItem.text
                }
                </li>  
              `;
              legendHTML += item;
            });

            legendHTML
              += `
                    </ul>
                  </div>
                </li>
              </ul>
            `;
          }
          return legendHTML;
        },
        hover: {
          mode: 'point',
        },
        tooltips: {
          mode: 'point',
          intersect: true,
          enabled: false,
          position: 'nearest',
          // ALWAYS KEEP THIS AS ARROW FUNCTION AND DO NOT CHANGE THIS TO A NORMAL FUNCTION BECAUSE THE REFERENCE TO `this` OBJECT WOULD CHANGE
          custom: (tooltip) => {
            const tooltipDataPoints = tooltip.dataPoints;

            // `tooltipDataPoints` would be `undefined` if clicked anywhere else on chart other than the datasets
            if (!_.isNil(tooltipDataPoints)) {
              // get the first dataPoint from the `tooltipDataPoints` array
              const currentDataPoint = _.first(tooltipDataPoints);

              // `currentDataPoint` WOULD BE `undefined` FOR THE LAST STACK BAR DATASET AS THE TOOLTIP IS DISABLED FOR IT
              if (!_.isNil(currentDataPoint)) {
                const currentDatasetIndex = currentDataPoint.datasetIndex;
                const currentDataset = chartDatasets[currentDatasetIndex];
                const datasetLabel = currentDataset.label;

                const datasetMeta = this.chart.getDatasetMeta(currentDataPoint.datasetIndex);
                const datasetType = datasetMeta.type;

                let tooltipEl = document.getElementById('chartjs-tooltip');
                if (!tooltipEl) {
                  tooltipEl = document.createElement('div');
                  tooltipEl.id = 'chartjs-tooltip';
                  tooltipEl.innerHTML = "<div class='tooltip-body'></div>";
                  this.chart.canvas.parentNode.appendChild(tooltipEl);
                }

                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltip.yAlign) {
                  tooltipEl.classList.add(tooltip.yAlign);
                } else {
                  tooltipEl.classList.add('no-transform');
                }

                // TOOLTIP DEFAULT POSITIONING
                let tooltipCoordinateX = tooltip.caretX;
                let tooltipCoordinateY = tooltip.caretY;

                if (tooltip.body) {
                  let tooltipLabel = datasetLabel;
                  let tooltipVal = currentDataPoint.yLabel; // lastObj.xLabel;

                  let isExistBenchmarkValue = true;
                  if (datasetType === 'bar') {
                    // IF THE DATASET IF OF TYPE "horizontalBar" (STACK BAR) THEN SHOW THE TOOLTIP AT A CUSTOM POSITION BY PERFORMING CERTAIN CALCULATIONS
                    let currentSelectedStackBarData = {};
                    if (tooltipLabel === 'first_stack') {
                      currentSelectedStackBarData = _.get(
                        firstStackDataset,
                        `${currentDataPoint.index}`,
                      );
                    } else if (tooltipLabel === 'second_stack') {
                      currentSelectedStackBarData = _.get(
                        secondStackDataset,
                        `${currentDataPoint.index}`,
                      );
                    } else if (tooltipLabel === 'third_stack') {
                      currentSelectedStackBarData = _.get(
                        thirdStackDataset,
                        `${currentDataPoint.index}`,
                      );
                    }

                    tooltipLabel = currentSelectedStackBarData.label;
                    tooltipVal = currentSelectedStackBarData.originalValue;
                    // BENCHMARK VALUE EXISTS ONLY IF ITS VALUE IS NOT SUBSTITUTED
                    isExistBenchmarkValue = !_.get(
                      currentSelectedStackBarData,
                      'isValueSubstituted',
                      false,
                    );

                    const allPointsForDataset = datasetMeta.data;
                    const currentDataPointIndex = currentDataPoint.index;
                    const currentPointForDataset = allPointsForDataset[currentDataPointIndex];
                    const barStartCoordinateY = currentPointForDataset._model.base;
                    const barEndCoordinateY = currentPointForDataset._model.y;

                    // THIS CALCULATION IS DONE TO SHOW THE TOOLTIP AT THE MIDDLE OF THE STACK BAR
                    const barTooltipCoordinateX = currentPointForDataset._model.x;
                    const barTooltipCoordinateY = barStartCoordinateY + (barEndCoordinateY - barStartCoordinateY) / 2;

                    tooltipCoordinateX = barTooltipCoordinateX;
                    tooltipCoordinateY = barTooltipCoordinateY;
                  }

                  let tooltipValHTML = `
                    <p>
                      ${tooltipVal}
                      <small>${metricUnit}</small>
                    </p>
                  `;
                  if (!isExistBenchmarkValue) {
                    tooltipValHTML = '<div class=\'no-benchmark-value\'>Benchmark value does not exist</div>';
                  }
                  let tooltipContent = `
                    <div class='tooltip-content'>
                      <div class='tooltip-title'>
                        ${tooltipLabel}
                      </div>
                      <div class='tooltip-data'>
                        ${tooltipValHTML}
                      </div>
                    </div>
                  `;

                  // const That = this;
                  // WOULD BE EXECUTED WHEN THE "View Details" WOULD BE CLICKED TO FETCH SELECTED CLIENT DETAILS
                  window.funViewClientDetails = function executeHandleClickViewClientDetails(
                    clientName,
                    clientIndustry,
                    clientMarketUnit,
                  ) {
                    // EXECUTE THE API CALL TO FETCH THE CLIENT DETAILS
                    That.handleClickViewClientDetails(clientName, clientIndustry, clientMarketUnit);
                  };

                  // IF CLICKED ON CLIENT BUBBLE
                  if (datasetType === 'bubble') {
                    let clientsArray = [];

                    // LOOP OVER ALL THE DATA POINTS TO JUST INCLUDE THE CLIENT "bubble" TYPE DATASET
                    _.forEach(tooltipDataPoints, (eachDataPoint) => {
                      const value = eachDataPoint.yLabel;
                      const { datasetIndex } = eachDataPoint;
                      const currentDataPointDataset = chartDatasets[datasetIndex];
                      const currentDataPointDatasetLabel = currentDataPointDataset.label;

                      const currentDataPointDatasetMeta = this.chart.getDatasetMeta(datasetIndex);
                      const currentDataPointDatasetType = currentDataPointDatasetMeta.type;

                      // ONLY INCLUDE CLIENT BUBBLES DATASET
                      if (currentDataPointDatasetType === 'bubble') {
                        // RETRIEVE THE CLIENT DATA FROM THE ARRAY WHICH CONTAINS ALL THE CLIENTS TO BE DISPLAYED ON THE CHART
                        const clientData = _.get(modifiedAllClients, `${datasetIndex}`, {});
                        const industry = _.get(clientData, 'industry', '');
                        const marketUnit = _.get(clientData, 'marketUnit', '');

                        const eachClient = {
                          name: currentDataPointDatasetLabel,
                          industry,
                          marketUnit,
                          isSimulated: clientData.isSimulated,
                          isExisting: clientData.isExisting,
                          value,
                        };
                        clientsArray.push(eachClient);
                      }
                    });
                    clientsArray = _.sortBy(clientsArray, (eachItem) => eachItem.value);

                    // WHEN THERE IS ONLY ONE CLIENT AT THE SELECTED POINT, THEN DYNAMICALLY APPEND THE "View Details" LINK IN THE TOOLTIP CONTENT
                    if (clientsArray.length === 1) {
                      const industry = _.get(clientsArray, '0.industry', '');
                      const marketUnit = _.get(clientsArray, '0.marketUnit', '');

                      // ONLY DISPLAY "VIEW DETAILS" LINKS FOR THE "EXISTING CLIENT" (IN SynOps AND NOT THE ONE THAT WAS CREATED BY TAKING THE ASSESSMENT) AND NOT FOR OTHER CLIENTS' BUBBLES
                      if (_.get(clientsArray, '0.isExisting')) {
                        // FOR EXISTING CLIENT, THE `clientName` WILL CONTAIN THE NAME OF THE CLIENT IN SynOps, SO CHANGE TO BAT CLIENT NAME ELSE THE API WILL RETURN THE EMPTY DATA
                        setTimeout(() => {
                          const spanDetails = document.createElement('span');
                          spanDetails.className = 'viewDetails';
                          spanDetails.textContent = 'View Details';
                          spanDetails.style.cursor = 'pointer';

                          document.querySelector('.tooltip-data').appendChild(spanDetails);

                          $(spanDetails).bind('click', () => {
                            window.funViewClientDetails(datasetLabel, industry, marketUnit);
                          });
                        }, 100);
                      }
                    } else {
                      const firstClientVal = _.get(clientsArray, '0.value');
                      // CHECK WHETHER ALL THE CLIENTS HAVE SAME VALUE OR NOT. (ALL THE DATAPOINTS WILL NOT HAVE SAME VALUE IN THE CASE WHEN NEARBY CLIENT BUBBLE ARE OVERLAPPING)
                      const isAllClientsHaveSameValue = _.isEmpty(
                        _.find(clientsArray, (eachItem) => eachItem.value !== firstClientVal),
                      );

                      const clientNameMaxLengthForTruncation = 32;

                      // WHEN THERE ARE MULTIPLE CLIENT BUBBLES AT THE SAME POINT THEN CREATE A CONSOLIDATED TOOLTIP WITH EACH CLIENT HAVING ITS INDIVIDUAL "View Details" LINK
                      if (isAllClientsHaveSameValue) {
                        let clients = '';
                        _.forEach(clientsArray, (eachClient) => {
                          const clientName = eachClient.name;
                          let clientNameHTML = `<div class="col client-name">${clientName}</div>`;
                          if (clientName.length > clientNameMaxLengthForTruncation) {
                            const clientNameTruncated = _.truncate(clientName, {
                              length: clientNameMaxLengthForTruncation,
                              omission: '...',
                            });
                            clientNameHTML = `
                              <div class="col client-name" title="${clientName}">
                                ${clientNameTruncated}
                                <!--<span class='tooltip-label-text'>${clientName}</span>-->
                              </div>
                            `;
                          }

                          let viewDetailsLabelHTML = '';

                          // ONLY DISPLAY "VIEW DETAILS" LINKS FOR THE "EXISTING CLIENT" (IN SynOps AND NOT THE ONE THAT WAS CREATED BY TAKING THE ASSESSMENT) AND NOT FOR OTHER CLIENTS
                          if (_.get(eachClient, 'isExisting')) {
                            viewDetailsLabelHTML = `
                              <div class="col-4 viewDetails" onclick="window.funViewClientDetails('${clientName}', '${eachClient.industry}', '${eachClient.marketUnit}')">
                                View Details
                              </div>
                            `;
                          }

                          clients += `
                            <div class="row">
                              ${clientNameHTML}
                              ${viewDetailsLabelHTML}
                            </div>
                          `;
                        });

                        tooltipContent = `
                          <div class='tooltip-content tooltip-multiple-same-clients'>
                            <div class='tooltip-data'>
                              <div class="row">
                                <div class="col">
                                  <p>
                                    ${tooltipVal}
                                    <small>${metricUnit}</small>
                                  </p>
                                </div>
                              </div>
                              <div class="multiple-same-data">
                                ${clients}
                              </div>
                            </div>
                          </div>
                        `;
                      } else {
                        // CREATE TOOLTIP WHEN THERE ARE MULTIPLE NEARBY CLIENT BUBBLES OVERLAPPED WITH DIFFERENT VALUES
                        let clients = '';

                        // GROUP THE CLIENT BY METRIC VALUES SO THAT THE CLIENTS HAVE SAME VALUES ARE GROUPED
                        const groupedArr = _.groupBy(clientsArray, (eachItem) => eachItem.value);

                        _.forEach(groupedArr, (eachGroup, key) => {
                          const metricValue = key;
                          let groupContent = `
                            <div class="row">
                              <div class="col">
                                <p>
                                  ${metricValue}
                                  <small>${metricUnit}</small>
                                </p>
                              </div>
                            </div>
                          `;
                          _.forEach(eachGroup, (eachClient) => {
                            const clientName = eachClient.name;
                            let clientNameHTML = `<div class="col client-name">${clientName}</div>`;
                            if (clientName.length > clientNameMaxLengthForTruncation) {
                              const clientNameTruncated = _.truncate(clientName, {
                                length: clientNameMaxLengthForTruncation,
                                omission: '...',
                              });
                              clientNameHTML = `
                                <div class="col client-name" title="${clientName}">
                                  ${clientNameTruncated}
                                  <!--<span class='tooltip-label-text'>${clientName}</span>-->
                                </div>
                              `;
                            }

                            let viewDetailsLabelHTML = '';

                            // ONLY DISPLAY "VIEW DETAILS" LINKS FOR THE "EXISTING CLIENT" (IN SynOps AND NOT THE ONE THAT WAS CREATED BY TAKING THE ASSESSMENT) AND NOT FOR OTHER CLIENTS
                            if (_.get(eachClient, 'isExisting')) {
                              viewDetailsLabelHTML = `
                                <div class="col-4 viewDetails" onclick="window.funViewClientDetails('${clientName}', '${eachClient.industry}', '${eachClient.marketUnit}')">
                                  View Details
                                </div>
                              `;
                            }

                            groupContent += `
                              <div class="row">
                                ${clientNameHTML}
                                ${viewDetailsLabelHTML}
                              </div>
                            `;
                          });
                          const groupHTML = `<div class="clients-group">${groupContent}</div>`;
                          clients += groupHTML;
                        });

                        tooltipContent = `
                          <div class='tooltip-content tooltip-multiple-different-clients row'>
                            <div class='tooltip-data col-12'>${clients}</div>
                          </div>
                        `;
                      }
                    }
                  }

                  const tableRoot = tooltipEl.querySelector('.tooltip-body');
                  tableRoot.innerHTML = tooltipContent;
                }

                // GET CHART CANVAS COORDINATES
                const chartCanvasCoordinateX = this.chart.canvas.offsetLeft;
                const chartCanvasCoordinateY = this.chart.canvas.offsetTop;

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;

                // SET TOOLTIP POSITION
                tooltipEl.className = tooltipEl.className.replace(/\btooltip-left-align\b/g, '');
                tooltipEl.className = tooltipEl.className.replace(/\btooltip-right-align\b/g, '');
                tooltipEl.style.left = `${chartCanvasCoordinateX + tooltipCoordinateX}px`;
                tooltipEl.style.top = `${chartCanvasCoordinateY + tooltipCoordinateY}px`;

                // SET TOOLTIP POSITION RIGHT/LEFT WHEN CLICKED ON BUBBLE
                if (datasetType === 'bubble') {
                  const varTop = 38 + tooltipEl.offsetHeight / 2;
                  const varLeft = 20 + tooltipEl.offsetWidth / 2;
                  tooltipEl.style.left = `${chartCanvasCoordinateX + varLeft + tooltipCoordinateX}px`;
                  tooltipEl.style.top = `${chartCanvasCoordinateY + varTop + tooltipCoordinateY}px`;
                  const multipleDetailD = tooltipEl.querySelectorAll('.clients-group');
                  const multipleDetailS = tooltipEl.querySelectorAll('.client-name');
                  if (tooltip.dataPoints.length < 2) {
                    tooltipEl.style.top = `${chartCanvasCoordinateY + varTop - 20 + tooltipCoordinateY}px`;
                  }
                  if (multipleDetailD.length > 0 || multipleDetailS.length > 0) {
                    tooltipEl.style.top = `${chartCanvasCoordinateY + varTop - 12 + tooltipCoordinateY}px`;
                  }

                  if (tooltip.xAlign === 'left' || tooltip.xAlign === 'center') {
                    tooltipEl.className = tooltipEl.className.replace(
                      /\btooltip-right-align\b/g,
                      '',
                    );
                    const name = 'tooltip-left-align';
                    const arr = tooltipEl.className.split(' ');
                    if (arr.indexOf(name) === -1) {
                      tooltipEl.className += ` ${name}`;
                    }
                  }
                  if (tooltip.xAlign === 'right') {
                    tooltipEl.style.left = `${chartCanvasCoordinateX - varLeft + tooltipCoordinateX}px`;
                    tooltipEl.className = tooltipEl.className.replace(
                      /\btooltip-left-align\b/g,
                      '',
                    );
                    const name = 'tooltip-right-align';
                    const arr = tooltipEl.className.split(' ');
                    if (arr.indexOf(name) === -1) {
                      tooltipEl.className += ` ${name}`;
                    }
                  }
                }
              } else {
                removeChartCustomTooltip();
              }
            } else {
              removeChartCustomTooltip();
            }
          },
        },
        responsive: true,
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
                maxRotation: 0,
                minRotation: 0,
                fontStyle: 'bold',
                fontFamily: "'Open Sans Bold', sans-serif",
                fontSize: 11,
                padding: 15,
                callback(tickLabel) {
                  // FOR EACH INDUSTRY/MARKET UNIT (Y-AXIS TICK LABEL), CHECK IF CONSOLIDATED SUB-INDUSTRIES/SUB-MARKET UNITS (`clientSubUnits`) ARE PRESENT. IF YES THEN DISPLAY THE COUNT OF SUB-UNITS IN NEXT LINE OF THE THE AXIS LABEL TEXT
                  const thresholdObj = _.find(
                    filteredThresholdData,
                    (eachItem) => eachItem.unitName === tickLabel,
                  );
                  const normalGroup = _.get(thresholdObj, 'groupType.normalGroup', []);
                  const consolidatedGroup = _.get(thresholdObj, 'groupType.consolidatedGroup', []);

                  let modifiedTickLabel = tickLabel;

                  // FOR THE CONSOLIDATED GROUP TICK LABEL
                  if (normalGroup.length > 0 || consolidatedGroup.length > 0) {
                    const totalLength = normalGroup.length + consolidatedGroup.length;
                    const newTickLabel = isConsolidated
                      ? `All ${That.getReplacedLabelForMU('plural')}`
                      : `Other ${That.getReplacedLabelForMU('plural')}`;
                    modifiedTickLabel = [
                      newTickLabel,
                      `(${totalLength} ${That.getReplacedLabelForMU('plural')})`,
                    ];
                    if (selectedThreshold === 'IndustryWise') {
                      modifiedTickLabel = [tickLabel, `(${totalLength} Industries)`];
                    }
                  } else {
                    modifiedTickLabel = tickLabel.length > 10 ? tickLabel.split(' ') : tickLabel;
                  }

                  return modifiedTickLabel;
                },
              },
              scaleLabel: {
                display: true,
                labelString:
                  selectedThreshold === 'IndustryWise'
                    ? 'Industries'
                    : this.getReplacedLabelForMU('plural'),
                color: '#637381',
                fontSize: 18,
                fontStyle: 'bold',
              },
              gridLines: {
                display: true,
                borderDash: [3, 5],
                lineWidth: 1.5,
                color: 'rgb(185, 185, 185)',
                drawBorder: false,
                drawTicks: false,
                offsetGridLines: false,
              },
              stacked: true,
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: false,
                labelString: !_.isEmpty(metricUnit) ? `Metric Unit (${metricUnit})` : '',
                color: '#637381',
                fontSize: 18,
                fontStyle: 'bold',
              },
              gridLines: {
                display: true,
                color: 'rgb(216, 224, 230)',
                drawTicks: false,
              },
              ticks: {
                min: xAxisMinVal,
                max: xAxisMaxVal,
                beginAtZero: false,
                fontFamily: "'Open Sans Bold', sans-serif",
                fontSize: 11,
                padding: 50,
                callback(value) {
                  let valWithUnit = value;
                  if (!_.isEmpty(metricUnit)) {
                    valWithUnit = metricUnit === '%' ? `${value}%` : `${value} ${metricUnit}`;
                  }

                  return valWithUnit;
                },
              },
              stacked: false,
            },
          ],
        },
      },
      plugins: [
        {
          beforeDraw(chart) {
            // TO HIGHLIGHT THE SIMULATED CLIENT INDUSTRY/MARKET UNIT SET THE SELECTED LABEL ON X-AXIS AS "MAJOR" SO THAT IT CAN BE HIGHLIGHTED
            if (!_.isEmpty(arrClientUnitNameToHighlight)) {
              const xAxisTickLabels = chart.boxes.find((box) => box.id === 'x-axis-0')._ticks;
              const foundTick = _.find(
                xAxisTickLabels,
                (eachTick) => _.indexOf(arrClientUnitNameToHighlight, eachTick.value) !== -1,
              );
              if (!_.isEmpty(foundTick)) {
                foundTick.major = true;
              }
            }
          },
          afterDraw(chart) {
            // DRAW VERTICAL DASHED LINES AND RECTANGLES

            const { ctx } = chart.chart;

            const xAxis = chart.scales['x-axis-0'];
            const yAxis = chart.scales['y-axis-0'];

            let rectangleWidth = 35;
            const rectangleHeight = 18;
            const { benchmarkData } = chart.config.options;

            if (
              !_.isEmpty(benchmarkData)
              && !_.isEmpty(
                _.find(benchmarkData, (eachItem) => !_.isEmpty(String(_.get(eachItem, 'value', '')))),
              )
            ) {
              // const chartAreaTop = yAxis.top;
              // const chartAreaBottom = yAxis.bottom;
              // const chartAreaHeight = chartAreaTop - chartAreaBottom;
              const chartAreaLeft = xAxis.left;
              const chartAreaRight = xAxis.right;
              // const chartWidth = chartAreaRight - chartAreaLeft;

              ctx.font = "10px 'Helvetica Neue', Helvetica, Arial, sans-serif";
              ctx.fillStyle = chart.options.defaultFontColor;
              ctx.textAlign = 'center';

              benchmarkData.forEach((eachItem) => {
                const datasetValue = eachItem.value;
                const { color } = eachItem;

                const x1 = chartAreaLeft;
                const y1 = yAxis.getPixelForValue(datasetValue);
                const x2 = chartAreaRight;
                const y2 = y1;

                if (String(datasetValue).length > 7) {
                  rectangleWidth = 50;
                } else if (String(datasetValue).length > 6) {
                  rectangleWidth = 46;
                }
                const xpadding = 5;

                // HORIZONTAL DASHED LINE STARTING FROM LEFT TO RIGHT
                ctx.save();
                ctx.lineWidth = '1.5';
                ctx.beginPath();
                ctx.moveTo(x1 - xpadding, y1);
                ctx.lineTo(x2, y2);
                ctx.strokeStyle = color;
                ctx.setLineDash([5, 5]);
                ctx.stroke();
                ctx.restore();

                // RECTANGLE DRAWN ON TOP X-AXIS
                ctx.save();
                ctx.lineWidth = '1';
                ctx.beginPath();
                ctx.strokeStyle = color;
                const rectX1 = chartAreaLeft - rectangleWidth - xpadding;
                const rectY1 = y1 - rectangleHeight / 2;
                ctx.rect(rectX1, rectY1, rectangleWidth, rectangleHeight);
                ctx.stroke();

                // LABEL TEXT INSIDE THE RECTANGLE DRAWN ON TOP X-AXIS
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.fillText(datasetValue, rectX1 + rectangleWidth / 2, y1);

                ctx.restore();
              });
            }
          },
        },
      ],
    });
    window.batMetricBenchmarkChart = this.chart;
  };

  // HORIZONTAL BAR CHART FOR CLIENT AGNOSTIC VIEW
  renderClientAgnosticInsightsHorizontalBarChart = (chartProps) => {
    const { metricUnit, selectedThreshold } = this.state;
    const {
      chartDatasets,
      yAxisLabels,
      verticalLinesDataset,
      firstStackDataset,
      secondStackDataset,
      thirdStackDataset,
      modifiedAllClients,
      filteredThresholdData,
      xAxisMinVal,
      xAxisMaxVal,
      isConsolidated,
    } = chartProps;

    this.ctx = this.metricBenchmarkChartRef.current.getContext('2d');

    // IF THE CHART WAS ALREADY RENDERED BEFORE THEN DESTROY IT
    if (!_.isNil(this.chart)) {
      this.chart.destroy();
    }
    const That = this;
    // HORIZONTAL BAR GRAPH WITH BENCHMARKS STACK BARS AND CLIENT BUBBLES
    this.chart = new Chart(this.ctx, {
      type: 'horizontalBar',
      borderColor: '#fff',
      data: {
        datasets: chartDatasets,
        labels: yAxisLabels,
      },
      options: {
        maintainAspectRatio: false,
        events: ['click'],
        // DATA FOR PLOTTING VERTICAL DASHED LINES AND CIRCLES FOR CROSS-INDUSTRY BENCHMARKS
        customVerticalLinesData: verticalLinesDataset,
        legend: {
          display: false,
          position: 'top',
          labels: {
            generateLabels(chart) {
              const { data } = chart;

              if (data.datasets.length) {
                const legendElementsArray = data.datasets.map((eachDataset, i) => {
                  const datasetType = eachDataset.type;
                  const datasetLabel = eachDataset.label;
                  let legendObj = {};

                  // DON'T RENDER LEGEND FOR CLIENT BUBBLES
                  if (datasetType !== 'bubble') {
                    let legendLabel = datasetLabel;
                    const legendFillColor = eachDataset.backgroundColor;

                    // find the first dataset object from the array where the value is not zero
                    let firstObjFromStackDataset = {};
                    if (datasetLabel === 'first_stack') {
                      firstObjFromStackDataset = _.find(
                        firstStackDataset,
                        (eachItem) => !_.isEmpty(String(eachItem.originalValue)),
                      );
                    } else if (datasetLabel === 'second_stack') {
                      firstObjFromStackDataset = _.find(secondStackDataset, (eachItem, index) => {
                        const firstStackDatasetVal = _.get(
                          firstStackDataset,
                          `${index}.originalValue`,
                        );
                        const thirdStackDatasetVal = _.get(
                          thirdStackDataset,
                          `${index}.originalValue`,
                        );

                        // DON'T DISPLAY THE LEGEND FOR MEDIAN IF BOTH THE UPPER QUARTILE AND LOWER QUARTILE VALUES ARE ZERO
                        let showLegendForMedian = true;
                        if (firstStackDatasetVal === 0 && thirdStackDatasetVal === 0) {
                          showLegendForMedian = false;
                        } else if (
                          _.isEmpty(String(firstStackDatasetVal))
                          && _.isEmpty(String(thirdStackDatasetVal))
                        ) {
                          showLegendForMedian = false;
                        }

                        return showLegendForMedian;
                      });
                    } else if (datasetLabel === 'third_stack') {
                      firstObjFromStackDataset = _.find(
                        thirdStackDataset,
                        (eachItem) => !_.isEmpty(String(eachItem.originalValue)),
                      );
                    }

                    legendLabel = _.get(firstObjFromStackDataset, 'label', '');

                    // IF NO OBJ FROM ARRAY WAS FOUND WITH NON-ZERO VALUE THEN RETURN EMPTY OBJECT SO THE LEGEND WOULD NOT BE RENDERED FOR THIS DATASET
                    if (_.isEmpty(firstObjFromStackDataset)) {
                      return {};
                    }
                    const meta = chart.getDatasetMeta(0);
                    const ds = data.datasets[0];
                    const arc = meta.data[i];
                    const custom = (arc && arc.custom) || {};
                    const { getValueAtIndexOrDefault } = Chart.helpers;
                    const arcOpts = chart.options.elements.arc;
                    const bw = custom.borderWidth
                      ? custom.borderWidth
                      : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

                    legendObj = {
                      text: legendLabel,
                      fillStyle: legendFillColor,
                      strokeStyle: legendFillColor,
                      lineWidth: bw,
                      hidden: false,
                      index: i,
                    };
                  }
                  return legendObj;
                });

                // ONLY RENDER THE LEGENDS FOR WHICH THE LEGEND OBJECT IS NOT EMPTY
                const filteredLegendElementsArray = _.filter(
                  legendElementsArray,
                  (eachLegendObj) => !_.isEmpty(eachLegendObj),
                );
                return filteredLegendElementsArray;
              }
              return [];
            },
          },
        },
        // CALLBACK FUNCTION FOR CUSTOM HTML RENDERING OF LEGENDS. WILL BE EXECUTED WHEN `chart.generateLegend()` FUNCTION WILL BE CALLED. WILL RETURN THE HTML STRING THAT COULD BE RENDERED IN THE INNER HTML OF ANY HTML ELEMENT
        legendCallback(chart) {
          // THE FILTERED LEGEND ITEMS HERE ARE RECEIVED FROM `legend.labels.generateLabels` FUNCTION
          const filteredLegendItems = chart.legend.legendItems;

          const legendTitle = selectedThreshold === 'IndustryWise' ? 'Industry' : That.getReplacedLabelForMU();
          let legendHTML = '';
          if (filteredLegendItems.length > 0) {
            legendHTML = `
            <ul>
              <li><b>${legendTitle}:</b></li>
              <li>
                <div class="chart-legend">
                  <ul>`;

            filteredLegendItems.forEach((eachLegendItem) => {
              const item = `
                <li>
                  <span style="background-color: ${eachLegendItem.fillStyle
                }"></span>
                  ${eachLegendItem.text
                }
                </li>  
              `;
              legendHTML += item;
            });

            legendHTML
              += `
                    </ul>
                  </div>
                </li>
              </ul>
            `;
          }
          return legendHTML;
        },
        hover: {
          mode: 'nearest',
        },
        tooltips: {
          mode: 'point',
          intersect: true,
          enabled: false,
          position: 'nearest',
          // ALWAYS KEEP THIS AS ARROW FUNCTION AND DO NOT CHANGE THIS TO A NORMAL FUNCTION BECAUSE THE REFERENCE TO `this` OBJECT WOULD CHANGE
          custom: (tooltip) => {
            const tooltipDataPoints = tooltip.dataPoints;

            // `tooltipDataPoints` would be `undefined` if clicked anywhere else on chart other than the datasets
            if (!_.isNil(tooltipDataPoints)) {
              // get the first dataPoint from the `tooltipDataPoints` array
              const currentDataPoint = _.first(tooltipDataPoints);

              // `currentDataPoint` WOULD BE `undefined` FOR THE LAST STACK BAR DATASET AS THE TOOLTIP IS DISABLED FOR IT
              if (!_.isNil(currentDataPoint)) {
                const currentDatasetIndex = currentDataPoint.datasetIndex;
                const currentDataset = chartDatasets[currentDatasetIndex];
                const datasetLabel = currentDataset.label;

                const datasetMeta = this.chart.getDatasetMeta(currentDataPoint.datasetIndex);
                const datasetType = datasetMeta.type;

                let tooltipEl = document.getElementById('chartjs-tooltip');
                if (!tooltipEl) {
                  tooltipEl = document.createElement('div');
                  tooltipEl.id = 'chartjs-tooltip';
                  tooltipEl.innerHTML = "<div class='tooltip-body'></div>";
                  this.chart.canvas.parentNode.appendChild(tooltipEl);
                }

                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltip.yAlign) {
                  tooltipEl.classList.add(tooltip.yAlign);
                } else {
                  tooltipEl.classList.add('no-transform');
                }

                // TOOLTIP DEFAULT POSITIONING
                let tooltipCoordinateX = tooltip.caretX;
                let tooltipCoordinateY = tooltip.caretY;

                if (tooltip.body) {
                  let tooltipLabel = datasetLabel;
                  let tooltipVal = currentDataPoint.xLabel;

                  let isExistBenchmarkValue = true;
                  if (datasetType === 'horizontalBar') {
                    // IF THE DATASET IF OF TYPE "horizontalBar" (STACK BAR) THEN SHOW THE TOOLTIP AT A CUSTOM POSITION BY PERFORMING CERTAIN CALCULATIONS
                    let currentSelectedStackBarData = {};
                    if (tooltipLabel === 'first_stack') {
                      currentSelectedStackBarData = _.get(
                        firstStackDataset,
                        `${currentDataPoint.index}`,
                      );
                    } else if (tooltipLabel === 'second_stack') {
                      currentSelectedStackBarData = _.get(
                        secondStackDataset,
                        `${currentDataPoint.index}`,
                      );
                    } else if (tooltipLabel === 'third_stack') {
                      currentSelectedStackBarData = _.get(
                        thirdStackDataset,
                        `${currentDataPoint.index}`,
                      );
                    }

                    tooltipLabel = currentSelectedStackBarData.label;
                    tooltipVal = currentSelectedStackBarData.originalValue;
                    // BENCHMARK VALUE EXISTS ONLY IF ITS VALUE IS NOT SUBSTITUTED
                    isExistBenchmarkValue = !_.get(
                      currentSelectedStackBarData,
                      'isValueSubstituted',
                      false,
                    );

                    const allPointsForDataset = datasetMeta.data;
                    const currentDataPointIndex = currentDataPoint.index;
                    const currentPointForDataset = allPointsForDataset[currentDataPointIndex];
                    const barStartCoordinateX = currentPointForDataset._model.base;
                    const barEndCoordinateX = currentPointForDataset._model.x;

                    // THIS CALCULATION IS DONE TO SHOW THE TOOLTIP AT THE MIDDLE OF THE STACK BAR
                    const barTooltipCoordinateX = barStartCoordinateX + (barEndCoordinateX - barStartCoordinateX) / 2;
                    const barTooltipCoordinateY = currentPointForDataset._model.y;
                    tooltipCoordinateX = barTooltipCoordinateX;
                    tooltipCoordinateY = barTooltipCoordinateY;
                  }

                  let tooltipValHTML = `
                    <p>
                      ${tooltipVal}
                      <small>${metricUnit}</small>
                    </p>
                  `;
                  if (!isExistBenchmarkValue) {
                    tooltipValHTML = '<div class=\'no-benchmark-value\'>Benchmark value does not exist</div>';
                  }
                  let tooltipContent = `
                    <div class='tooltip-content'>
                      <div class='tooltip-title'>
                        ${tooltipLabel}
                      </div>
                      <div class='tooltip-data'>
                        ${tooltipValHTML}
                      </div>
                    </div>
                  `;

                  // WOULD BE EXECUTED WHEN THE "View Details" WOULD BE CLICKED TO FETCH SELECTED CLIENT DETAILS
                  window.funViewClientDetails = function executeHandleClickViewClientDetails(
                    clientName,
                    clientIndustry,
                    clientMarketUnit,
                  ) {
                    // EXECUTE THE API CALL TO FETCH THE CLIENT DETAILS
                    That.handleClickViewClientDetails(
                      clientName,
                      clientIndustry,
                      clientMarketUnit,
                    );
                  };

                  // IF CLICKED ON CLIENT BUBBLE
                  if (datasetType === 'bubble') {
                    let clientsArray = [];

                    // LOOP OVER ALL THE DATA POINTS TO JUST INCLUDE THE CLIENT "bubble" TYPE DATASET
                    _.forEach(tooltipDataPoints, (eachDataPoint) => {
                      const value = eachDataPoint.xLabel;
                      const { datasetIndex } = eachDataPoint;
                      const currentDataPointDataset = chartDatasets[datasetIndex];
                      const currentDataPointDatasetLabel = currentDataPointDataset.label;
                      // const datasetType = _.get(currentDataPointDataset, "type", "");

                      const currentDataPointDatasetMeta = this.chart.getDatasetMeta(datasetIndex);
                      const currentDataPointDatasetType = currentDataPointDatasetMeta.type;

                      // ONLY INCLUDE CLIENT BUBBLES DATASET
                      if (currentDataPointDatasetType === 'bubble') {
                        // RETRIEVE THE CLIENT DATA FROM THE ARRAY WHICH CONTAINS ALL THE CLIENTS TO BE DISPLAYED ON THE CHART
                        const clientData = _.get(modifiedAllClients, `${datasetIndex}`, {});
                        const industry = _.get(clientData, 'industry', '');
                        const marketUnit = _.get(clientData, 'marketUnit', '');

                        const eachClient = {
                          name: currentDataPointDatasetLabel,
                          industry,
                          marketUnit,
                          value,
                        };
                        clientsArray.push(eachClient);
                      }
                    });
                    clientsArray = _.sortBy(clientsArray, (eachItem) => eachItem.value);

                    // WHEN THERE IS ONLY ONE CLIENT AT THE SELECTED POINT, THEN DYNAMICALLY APPEND THE "View Details" LINK IN THE TOOLTIP CONTENT
                    if (clientsArray.length === 1) {
                      const industry = _.get(clientsArray, '0.industry', '');
                      const marketUnit = _.get(clientsArray, '0.marketUnit', '');
                      setTimeout(() => {
                        const spanDetails = document.createElement('span');
                        spanDetails.className = 'viewDetails';
                        spanDetails.textContent = 'View Details';
                        spanDetails.style.cursor = 'pointer';

                        document.querySelector('.tooltip-data').appendChild(spanDetails);

                        $(spanDetails).bind('click', () => {
                          window.funViewClientDetails(datasetLabel, industry, marketUnit);
                        });
                      }, 100);
                    } else {
                      const firstClientVal = _.get(clientsArray, '0.value');
                      // CHECK WHETHER ALL THE CLIENTS HAVE SAME VALUE OR NOT. (ALL THE DATAPOINTS WILL NOT HAVE SAME VALUE IN THE CASE WHEN NEARBY CLIENT BUBBLE ARE OVERLAPPING)
                      const isAllClientsHaveSameValue = _.isEmpty(
                        _.find(clientsArray, (eachItem) => eachItem.value !== firstClientVal),
                      );

                      const clientNameMaxLengthForTruncation = 20;

                      // WHEN THERE ARE MULTIPLE CLIENT BUBBLES AT THE SAME POINT THEN CREATE A CONSOLIDATED TOOLTIP WITH EACH CLIENT HAVING ITS INDIVIDUAL "View Details" LINK
                      if (isAllClientsHaveSameValue) {
                        let clients = '';
                        _.forEach(clientsArray, (eachClient) => {
                          const clientName = eachClient.name;
                          let clientNameHTML = `<div class="col-8 client-name">${clientName}</div>`;
                          if (clientName.length > clientNameMaxLengthForTruncation) {
                            const clientNameTruncated = _.truncate(clientName, {
                              length: clientNameMaxLengthForTruncation,
                              omission: '...',
                            });
                            clientNameHTML = `
                              <div class="col-8 client-name" title="${clientName}">
                                ${clientNameTruncated}
                                <!--<span class='tooltip-label-text'>${clientName}</span>-->
                              </div>
                            `;
                          }
                          clients += `
                            <div class="row">
                              ${clientNameHTML}
                              <div class="col-4 viewDetails" onclick="window.funViewClientDetails('${clientName}', '${eachClient.industry}', '${eachClient.marketUnit}')">
                                View Details
                              </div>
                            </div>
                          `;
                        });

                        tooltipContent = `
                          <div class='tooltip-content tooltip-multiple-same-clients'>
                            <div class='tooltip-data'>
                              <div class="row">
                                <div class="col">
                                  <p>
                                    ${tooltipVal}
                                    <small>${metricUnit}</small>
                                  </p>
                                </div>
                              </div>
                              <div class="multiple-same-data">
                                ${clients}
                              </div>
                            </div>
                          </div>
                        `;
                      } else {
                        // CREATE TOOLTIP WHEN THERE ARE MULTIPLE NEARBY CLIENT BUBBLES OVERLAPPED WITH DIFFERENT VALUES
                        let clients = '';

                        // GROUP THE CLIENT BY METRIC VALUES SO THAT THE CLIENTS HAVE SAME VALUES ARE GROUPED
                        const groupedArr = _.groupBy(clientsArray, (eachItem) => eachItem.value);

                        _.forEach(groupedArr, (eachGroup, key) => {
                          const metricValue = key;
                          let groupContent = `
                            <div class="row">
                              <div class="col">
                                <p>
                                  ${metricValue}
                                  <small>${metricUnit}</small>
                                </p>
                              </div>
                            </div>
                          `;
                          _.forEach(eachGroup, (eachClient) => {
                            const clientName = eachClient.name;
                            let clientNameHTML = `<div class="col-8 client-name">${clientName}</div>`;
                            if (clientName.length > clientNameMaxLengthForTruncation) {
                              const clientNameTruncated = _.truncate(clientName, {
                                length: clientNameMaxLengthForTruncation,
                                omission: '...',
                              });
                              clientNameHTML = `
                                <div class="col-8 client-name" title="${clientName}">
                                  ${clientNameTruncated}
                                  <!--<span class='tooltip-label-text'>${clientName}</span>-->
                                </div>
                              `;
                            }
                            groupContent += `
                              <div class="row">
                                ${clientNameHTML}
                                <div class="col-4 viewDetails" onclick="window.funViewClientDetails('${clientName}', '${eachClient.industry}', '${eachClient.marketUnit}')">
                                  View Details
                                </div>
                              </div>
                            `;
                          });
                          const groupHTML = `<div class="clients-group">${groupContent}</div>`;
                          clients += groupHTML;
                        });

                        tooltipContent = `
                          <div class='tooltip-content tooltip-multiple-different-clients row'>
                            <div class='tooltip-data col-12'>${clients}</div>
                          </div>
                        `;
                      }
                    }
                  }

                  const tableRoot = tooltipEl.querySelector('.tooltip-body');
                  tableRoot.innerHTML = tooltipContent;
                }

                // GET CHART CANVAS COORDINATES
                const chartCanvasCoordinateX = this.chart.canvas.offsetLeft;
                const chartCanvasCoordinateY = this.chart.canvas.offsetTop;

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;

                // SET TOOLTIP POSITION
                tooltipEl.className = tooltipEl.className.replace(/\btooltip-left-align\b/g, '');
                tooltipEl.className = tooltipEl.className.replace(/\btooltip-right-align\b/g, '');
                tooltipEl.style.left = `${chartCanvasCoordinateX + tooltipCoordinateX}px`;
                tooltipEl.style.top = `${chartCanvasCoordinateY + tooltipCoordinateY}px`;

                // SET TOOLTIP POSITION RIGHT/LEFT WHEN CLICKED ON BUBBLE
                if (datasetType === 'bubble') {
                  const varTop = 38 + tooltipEl.offsetHeight / 2;
                  const varLeft = 20 + tooltipEl.offsetWidth / 2;
                  tooltipEl.style.left = `${chartCanvasCoordinateX + varLeft + tooltipCoordinateX}px`;
                  tooltipEl.style.top = `${chartCanvasCoordinateY + varTop + tooltipCoordinateY}px`;
                  const multipleDetailD = tooltipEl.querySelectorAll('.clients-group');
                  const multipleDetailS = tooltipEl.querySelectorAll('.client-name');
                  if (tooltip.dataPoints.length < 2) {
                    tooltipEl.style.top = `${chartCanvasCoordinateY + varTop - 20 + tooltipCoordinateY}px`;
                  }
                  if (multipleDetailD.length > 0 || multipleDetailS.length > 0) {
                    tooltipEl.style.top = `${chartCanvasCoordinateY + varTop - 12 + tooltipCoordinateY}px`;
                  }

                  if (tooltip.xAlign === 'left' || tooltip.xAlign === 'center') {
                    tooltipEl.className = tooltipEl.className.replace(
                      /\btooltip-right-align\b/g,
                      '',
                    );
                    const name = 'tooltip-left-align';
                    const arr = tooltipEl.className.split(' ');
                    if (arr.indexOf(name) === -1) {
                      tooltipEl.className += ` ${name}`;
                    }
                  }
                  if (tooltip.xAlign === 'right') {
                    tooltipEl.style.left = `${chartCanvasCoordinateX - varLeft + tooltipCoordinateX}px`;
                    tooltipEl.className = tooltipEl.className.replace(
                      /\btooltip-left-align\b/g,
                      '',
                    );
                    const name = 'tooltip-right-align';
                    const arr = tooltipEl.className.split(' ');
                    if (arr.indexOf(name) === -1) {
                      tooltipEl.className += ` ${name}`;
                    }
                  }
                }
              } else {
                removeChartCustomTooltip();
              }
            } else {
              removeChartCustomTooltip();
            }
          },
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: false,
                labelString:
                  selectedThreshold === 'IndustryWise'
                    ? 'Industries'
                    : this.getReplacedLabelForMU('plural'),
                color: '#637381',
                fontSize: 18,
                fontStyle: 'bold',
              },
              gridLines: {
                display: true,
                color: 'rgba(196, 205, 213, 0.5)',
                offsetGridLines: false,
              },
              barPercentage: 0.7,
              stacked: true,
              ticks: {
                fontStyle: 'bold',
                callback(tickLabel) {
                  // FOR EACH INDUSTRY/MARKET UNIT (Y-AXIS TICK LABEL), CHECK IF CONSOLIDATED SUB-INDUSTRIES/SUB-MARKET UNITS (`clientSubUnits`) ARE PRESENT. IF YES THEN DISPLAY THE COUNT OF SUB-UNITS IN NEXT LINE OF THE THE AXIS LABEL TEXT
                  const thresholdObj = _.find(
                    filteredThresholdData,
                    (eachItem) => eachItem.unitName === tickLabel,
                  );
                  const normalGroup = _.get(thresholdObj, 'groupType.normalGroup', []);
                  const consolidatedGroup = _.get(thresholdObj, 'groupType.consolidatedGroup', []);

                  let returnTickLabel = tickLabel;
                  if (normalGroup.length > 0 || consolidatedGroup.length > 0) {
                    const newTickLabel = isConsolidated
                      ? `All ${That.getReplacedLabelForMU('plural')}`
                      : `Other ${That.getReplacedLabelForMU('plural')}`;
                    const totalLength = normalGroup.length + consolidatedGroup.length;
                    returnTickLabel = [
                      newTickLabel,
                      `(${totalLength} ${That.getReplacedLabelForMU('plural')})`,
                    ];
                    if (selectedThreshold === 'IndustryWise') {
                      returnTickLabel = [tickLabel, `(${totalLength} Industries)`];
                    }
                  }

                  return returnTickLabel;
                },
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: !_.isEmpty(metricUnit) ? `Metric Unit (${metricUnit})` : '',
                color: '#637381',
                fontSize: 18,
                fontStyle: 'bold',
              },
              gridLines: {
                display: true,
                color: 'rgba(196, 205, 213, 0.5)',
                offsetGridLines: true,
              },
              stacked: false,
              ticks: {
                // IF CROSS-INDUSTRY BENCHMARK VALUES ARE NOT EMPTY (VERTICAL DASHED LINES AND CIRCLES) ARE DRAWN ON CHART, THEN APPLY PADDING TO THE AXIS LABELS SO THAT THEY DO NOT OVERLAP WITH THE CIRCLES
                padding: !_.isEmpty(verticalLinesDataset) ? 20 : 0,
                beginAtZero: false,
                min: xAxisMinVal,
                max: xAxisMaxVal,
                callback(value) {
                  let valWithUnit = value;
                  if (!_.isEmpty(metricUnit)) {
                    valWithUnit = metricUnit === '%' ? `${value}%` : `${value} ${metricUnit}`;
                  }

                  return valWithUnit;
                },
              },
              display: true,
            },
            // CONFIGURATION FOR THE X-AXIS THAT IS DISPLAYED AT THE TOP POSITION ON THE CHART
            {
              id: 'top-x-axis',
              type: 'linear',
              position: 'top',
              scaleLabel: {
                display: false,
              },
              gridLines: {
                display: true,
                drawBorder: true,
              },
              stacked: false,
              ticks: {
                beginAtZero: false,
                min: xAxisMinVal,
                max: xAxisMaxVal,
                callback(value) {
                  let valWithUnit = value;
                  if (!_.isEmpty(metricUnit)) {
                    valWithUnit = metricUnit === '%' ? `${value}%` : `${value} ${metricUnit}`;
                  }

                  return valWithUnit;
                },
                // IF CROSS-INDUSTRY BENCHMARK VALUES ARE NOT EMPTY (VERTICAL DASHED LINES AND CIRCLES) ARE DRAWN ON CHART, THEN APPLY PADDING TO THE AXIS LABELS SO THAT THEY DO NOT OVERLAP WITH THE CIRCLES
                padding: !_.isEmpty(verticalLinesDataset) ? 20 : 0,
              },
            },
          ],
        },
      },
      plugins: [
        {
          beforeDraw(chart) {
            // DRAW BACKGROUND COLORS DEPICTING DIFFERENT COLORED ZONES BASED ON CROSS-INDUSTRY BENCHMARKS

            const { ctx } = chart.chart;

            const xAxis = chart.scales['x-axis-0'];
            const yAxis = chart.scales['y-axis-0'];

            const verticalLinesBenchmarksDataset = chart.config.options.customVerticalLinesData;

            if (
              !_.isEmpty(verticalLinesBenchmarksDataset)
              && !_.isEmpty(
                _.find(
                  verticalLinesBenchmarksDataset,
                  (eachItem) => !_.isEmpty(String(_.get(eachItem, 'value', ''))),
                ),
              )
            ) {
              const firstZoneColor = _.get(verticalLinesBenchmarksDataset, '0.backgroundColor');
              const secondZoneColor = _.get(verticalLinesBenchmarksDataset, '1.backgroundColor');
              const thirdZoneColor = _.get(verticalLinesBenchmarksDataset, '2.backgroundColor');

              const firstVal = _.get(verticalLinesBenchmarksDataset, '0.value', '');
              const secondVal = _.get(verticalLinesBenchmarksDataset, '1.value', '');
              const thirdVal = _.get(verticalLinesBenchmarksDataset, '2.value', '');

              const isEmptyFirstVal = _.isEmpty(String(firstVal));
              const isEmptySecondVal = _.isEmpty(String(secondVal));
              const isEmptyThirdVal = _.isEmpty(String(thirdVal));

              const chartTop = yAxis.top;
              const chartAreaHeight = yAxis.bottom - yAxis.top;
              const chartAreaLeft = xAxis.left;
              const chartAreaRight = xAxis.getPixelForValue(xAxis.max);

              if (
                (!isEmptyFirstVal && !isEmptySecondVal && !isEmptyThirdVal)
                || (!isEmptyFirstVal && isEmptySecondVal && !isEmptyThirdVal)
              ) {
                const firstValXCoordPixelVal = xAxis.getPixelForValue(firstVal);
                const thirdValXCoordPixelVal = xAxis.getPixelForValue(thirdVal);

                // Rect for zone-1
                const gradientRect1 = ctx.createLinearGradient(100, 100, 300, 200);
                gradientRect1.addColorStop(0, firstZoneColor);
                ctx.fillStyle = gradientRect1;
                const gradientRect1Width = firstValXCoordPixelVal - chartAreaLeft;
                ctx.fillRect(chartAreaLeft, chartTop, gradientRect1Width, chartAreaHeight);

                // Rect zone-2
                const gradientRect2 = ctx.createLinearGradient(100, 100, 300, 200);
                gradientRect2.addColorStop(0, secondZoneColor);
                ctx.fillStyle = gradientRect2;
                const gradientRect2Width = thirdValXCoordPixelVal - firstValXCoordPixelVal;
                ctx.fillRect(firstValXCoordPixelVal, chartTop, gradientRect2Width, chartAreaHeight);

                // Rect zone-3
                const gradientRect3 = ctx.createLinearGradient(100, 100, 300, 200);
                gradientRect3.addColorStop(0, thirdZoneColor);
                ctx.fillStyle = gradientRect3;
                const gradientRect3Width = chartAreaRight - thirdValXCoordPixelVal;
                ctx.fillRect(thirdValXCoordPixelVal, chartTop, gradientRect3Width, chartAreaHeight);
              } else if (!isEmptyFirstVal && isEmptySecondVal && isEmptyThirdVal) {
                const firstValXCoordPixelVal = xAxis.getPixelForValue(firstVal);

                // Rect for zone-1
                const gradientRect1 = ctx.createLinearGradient(100, 100, 300, 200);
                gradientRect1.addColorStop(0, firstZoneColor);
                ctx.fillStyle = gradientRect1;
                const gradientRect1Width = firstValXCoordPixelVal - chartAreaLeft;
                ctx.fillRect(chartAreaLeft, chartTop, gradientRect1Width, chartAreaHeight);

                // Rect zone-2
                const gradientRect2 = ctx.createLinearGradient(100, 100, 300, 200);
                gradientRect2.addColorStop(0, thirdZoneColor);
                ctx.fillStyle = gradientRect2;
                const gradientRect2Width = chartAreaRight - firstValXCoordPixelVal;
                ctx.fillRect(firstValXCoordPixelVal, chartTop, gradientRect2Width, chartAreaHeight);
              } else if (isEmptyFirstVal && !isEmptySecondVal && isEmptyThirdVal) {
                const secondValXCoordPixelVal = xAxis.getPixelForValue(secondVal);

                // Rect for zone-1
                const gradientRect1 = ctx.createLinearGradient(100, 100, 300, 200);
                gradientRect1.addColorStop(0, firstZoneColor);
                ctx.fillStyle = gradientRect1;
                const gradientRect1Width = secondValXCoordPixelVal - chartAreaLeft;
                ctx.fillRect(chartAreaLeft, chartTop, gradientRect1Width, chartAreaHeight);

                // Rect zone-2
                const gradientRect2 = ctx.createLinearGradient(100, 100, 300, 200);
                gradientRect2.addColorStop(0, thirdZoneColor);
                ctx.fillStyle = gradientRect2;
                const gradientRect2Width = chartAreaRight - secondValXCoordPixelVal;
                ctx.fillRect(
                  secondValXCoordPixelVal,
                  chartTop,
                  gradientRect2Width,
                  chartAreaHeight,
                );
              } else if (isEmptyFirstVal && isEmptySecondVal && !isEmptyThirdVal) {
                const thirdValXCoordPixelVal = xAxis.getPixelForValue(thirdVal);

                // Rect for zone-1
                const gradientRect1 = ctx.createLinearGradient(100, 100, 300, 200);
                gradientRect1.addColorStop(0, firstZoneColor);
                ctx.fillStyle = gradientRect1;
                const gradientRect1Width = thirdValXCoordPixelVal - chartAreaLeft;
                ctx.fillRect(chartAreaLeft, chartTop, gradientRect1Width, chartAreaHeight);

                // Rect zone-2
                const gradientRect2 = ctx.createLinearGradient(100, 100, 300, 200);
                gradientRect2.addColorStop(0, thirdZoneColor);
                ctx.fillStyle = gradientRect2;
                const gradientRect2Width = chartAreaRight - thirdValXCoordPixelVal;
                ctx.fillRect(thirdValXCoordPixelVal, chartTop, gradientRect2Width, chartAreaHeight);
              } else if (!isEmptyFirstVal && !isEmptySecondVal && isEmptyThirdVal) {
                const firstValXCoordPixelVal = xAxis.getPixelForValue(firstVal);
                const secondValXCoordPixelVal = xAxis.getPixelForValue(secondVal);

                // Rect for zone-1
                const gradientRect1 = ctx.createLinearGradient(100, 100, 300, 200);
                gradientRect1.addColorStop(0, firstZoneColor);
                ctx.fillStyle = gradientRect1;
                const gradientRect1Width = firstValXCoordPixelVal - chartAreaLeft;
                ctx.fillRect(chartAreaLeft, chartTop, gradientRect1Width, chartAreaHeight);

                // Rect zone-2
                const gradientRect2 = ctx.createLinearGradient(100, 100, 300, 200);
                gradientRect2.addColorStop(0, secondZoneColor);
                ctx.fillStyle = gradientRect2;
                const gradientRect2Width = secondValXCoordPixelVal - firstValXCoordPixelVal;
                ctx.fillRect(firstValXCoordPixelVal, chartTop, gradientRect2Width, chartAreaHeight);

                // Rect zone-3
                const gradientRect3 = ctx.createLinearGradient(100, 100, 300, 200);
                gradientRect3.addColorStop(0, thirdZoneColor);
                ctx.fillStyle = gradientRect3;
                const gradientRect3Width = chartAreaRight - secondValXCoordPixelVal;
                ctx.fillRect(
                  secondValXCoordPixelVal,
                  chartTop,
                  gradientRect3Width,
                  chartAreaHeight,
                );
              } else if (isEmptyFirstVal && !isEmptySecondVal && !isEmptyThirdVal) {
                const secondValXCoordPixelVal = xAxis.getPixelForValue(secondVal);
                const thirdValXCoordPixelVal = xAxis.getPixelForValue(thirdVal);

                // Rect for zone-1
                const gradientRect1 = ctx.createLinearGradient(100, 100, 300, 200);
                gradientRect1.addColorStop(0, firstZoneColor);
                ctx.fillStyle = gradientRect1;
                const gradientRect1Width = secondValXCoordPixelVal - chartAreaLeft;
                ctx.fillRect(chartAreaLeft, chartTop, gradientRect1Width, chartAreaHeight);

                // Rect zone-2
                const gradientRect2 = ctx.createLinearGradient(100, 100, 300, 200);
                gradientRect2.addColorStop(0, secondZoneColor);
                ctx.fillStyle = gradientRect2;
                const gradientRect2Width = thirdValXCoordPixelVal - secondValXCoordPixelVal;
                ctx.fillRect(
                  secondValXCoordPixelVal,
                  chartTop,
                  gradientRect2Width,
                  chartAreaHeight,
                );

                // Rect zone-3
                const gradientRect3 = ctx.createLinearGradient(100, 100, 300, 200);
                gradientRect3.addColorStop(0, thirdZoneColor);
                ctx.fillStyle = gradientRect3;
                const gradientRect3Width = chartAreaRight - thirdValXCoordPixelVal;
                ctx.fillRect(thirdValXCoordPixelVal, chartTop, gradientRect3Width, chartAreaHeight);
              }
            }
          },
          afterDraw(chart) {
            // DRAW VERTICAL DASHED LINES AND RECTANGLES FOR CROSS INDUSTRY BENCHMARKS

            const { ctx } = chart.chart;

            const xAxis = chart.scales['x-axis-0'];
            const yAxis = chart.scales['y-axis-0'];

            let rectangleWidth = 35;
            const rectangleHeight = 16;
            const verticalLinesBenchmarksDataset = chart.config.options.customVerticalLinesData;

            if (
              !_.isEmpty(verticalLinesBenchmarksDataset)
              && !_.isEmpty(
                _.find(
                  verticalLinesBenchmarksDataset,
                  (eachItem) => !_.isEmpty(String(_.get(eachItem, 'value', ''))),
                ),
              )
            ) {
              ctx.font = "10px 'Helvetica Neue', Helvetica, Arial, sans-serif";
              ctx.fillStyle = chart.options.defaultFontColor;
              ctx.textAlign = 'center';

              verticalLinesBenchmarksDataset.forEach((eachItem) => {
                const datasetValue = eachItem.value;
                const { color } = eachItem;

                const x1 = xAxis.getPixelForValue(datasetValue);
                const y1 = yAxis.top - 14;
                const x2 = x1;
                const y2 = yAxis.bottom + 14; // yAxis.height;

                if (String(datasetValue).length > 7) {
                  rectangleWidth = 50;
                } else if (String(datasetValue).length > 6) {
                  rectangleWidth = 46;
                }

                ctx.save();
                ctx.lineWidth = '1';
                ctx.beginPath();
                // VERTICAL DASHED LINE STARTING FROM TOP TO BOTTOM
                ctx.moveTo(x1, y1 + rectangleHeight / 2);
                ctx.lineTo(x2, y2 - rectangleHeight / 2);
                ctx.strokeStyle = color;
                ctx.setLineDash([5, 5]);
                ctx.stroke();
                ctx.restore();

                ctx.save();
                ctx.lineWidth = '1';

                // RECTANGLE DRAWN ON TOP X-AXIS
                ctx.beginPath();
                ctx.lineWidth = '1';
                ctx.strokeStyle = color;
                const rectX1 = x1 - rectangleWidth / 2;
                const rectY1 = y1 - 10;
                ctx.rect(rectX1, rectY1, rectangleWidth, rectangleHeight);
                ctx.stroke();

                // LABEL TEXT INSIDE THE RECTANGLE DRAWN ON TOP X-AXIS
                ctx.textAlign = 'center';
                ctx.fillText(datasetValue, x1, y1 + 2);

                // RECTANGLE DRAWN ON BOTTOM X-AXIS
                ctx.beginPath();
                ctx.lineWidth = '1';
                ctx.strokeStyle = color;
                const rect2X1 = x2 - rectangleWidth / 2;
                const rect2Y1 = y2 - 8;
                ctx.rect(rect2X1, rect2Y1, rectangleWidth, rectangleHeight);
                ctx.stroke();

                // LABEL TEXT INSIDE THE RECTANGLE DRAWN ON BOTTOM X-AXIS
                ctx.textAlign = 'center';
                ctx.fillText(datasetValue, x2, y2 + 4);

                ctx.restore();
              });
            }
          },
        },
      ],
    });
    window.batMetricBenchmarkChart = this.chart;
  };

  // WILL BE EXECUTED WHEN THE "VIEW DETAILS" LINK WOULD BE CLICKED IN CLIENT BUBBLES TOOLTIP. WILL EXECUTE THE API TO FETCH CLIENT METRIC DATA
  handleClickViewClientDetails = (
    selectedClientName,
    selectedClientIndustry,
    selectedClientMarketUnit,
  ) => {
    const { existingBATClientName, existingSynOpsClientName } = this.props;
    const {
      metricBenchmarkDetails,
      displayClientNames,
      selectedInsightsViewBenchmark,
      startDate,
      endDate,
      formattedStartDate,
      formattedEndDate,
      isClientExisting,
      selectedThreshold,
      thresholdData,
      selectedClientIndustries,
      selectedClientMarketUnits,
      trendViewBenchmarkOptions,
    } = this.state;

    let isSelectedClientExistingClient = false;
    if (isClientExisting && selectedClientName === existingSynOpsClientName) {
      isSelectedClientExistingClient = true;
    }

    removeChartCustomTooltip();

    // FIND THE SELECTED CLIENT FROM ALL CLIENTS ARRAY
    const currentlySelectedClientDetails = _.find(
      metricBenchmarkDetails.clientDetails,
      (eachClient) => {
        // IF `displayClientNames` TOGGLE RADIO VALUE IS `true` THEN GET THE CLIENT BASED ON THE REAL CLIENT NAME ELSE USE ALIAS NAME
        let currentClientNameForComparison = eachClient.clientName;
        if (!displayClientNames) {
          currentClientNameForComparison = eachClient.aliasName;
        }

        return (
          currentClientNameForComparison === selectedClientName
          && eachClient.industry === selectedClientIndustry
          && eachClient.marketUnit === selectedClientMarketUnit
        );
      },
    );

    if (isSelectedClientExistingClient) {
      currentlySelectedClientDetails.clientName = existingBATClientName;
    }

    // FOR THE SELECTED CLIENT BUBBLE
    const clientIndustry = currentlySelectedClientDetails.industry;
    const clientMarketUnit = currentlySelectedClientDetails.marketUnit;

    const selectedUnit = selectedThreshold === 'IndustryWise' ? clientIndustry : clientMarketUnit;
    const thresholdObj = _.find(thresholdData, (eachItem) => eachItem.unitName === selectedUnit);
    const normalGroup = _.get(thresholdObj, 'groupType.normalGroup', []);
    const consolidatedGroup = _.get(thresholdObj, 'groupType.consolidatedGroup', []);
    let isUnitConsolidated = false;
    if (normalGroup.length > 0 || consolidatedGroup.length > 0) {
      isUnitConsolidated = true;
    }

    // SET THE DEFAULT TO "CrossIndustry". FOR BOTH "IndustryWise" AND "MarketUnit", IF FILTER IS ENGAGED AND ONLY ONE INDUSTRY OR MARKET UNIT IS SELECTED (OR THERE ONLY JUST ONE INDUSTRY OR MARKET UNIT) AND THE RESPECTIVE BENCHMARKS (internal OR external) ARE NOT EMPTY (THIS VIEW WILL BE TERMED AS "INDUSTRY/MARKET UNIT" SPECIFIC VIEW AS THERE IS JUST ONE INDUSTRY OR MARKET UNIT CURRENTLY VISIBLE IN THE VIEW), THEN SET THE RADIO BUTTON TO BE SELECTED BY EITHER "IndustryWise" OR "MarketUnit"
    let benchmarkBy = 'CrossIndustry';
    if (
      selectedThreshold === 'IndustryWise'
      && (selectedClientIndustries.length === 1 || thresholdData.length === 1)
      && !isUnitConsolidated
    ) {
      benchmarkBy = 'IndustryWise';
    } else if (
      selectedThreshold === 'MarketUnit'
      && (selectedClientMarketUnits.length === 1 || thresholdData.length === 1)
      && !isUnitConsolidated
    ) {
      benchmarkBy = 'MarketUnit';
    }

    const selectedTrendViewBenchmarkOption = _.find(trendViewBenchmarkOptions, (eachOption) => (
      eachOption.benchmarkType === selectedInsightsViewBenchmark
      && eachOption.benchmarkBy === benchmarkBy
    ));

    // SET THE DEFAULT TIME FRAME FOR DATE PICKERS WHEN SWITCHING TO "trendBenchmarkComparisonView" AND SET THE VALUES OF DATE FIELDS AND SELECTED BENCHMARK IN TEMPORARY FIELDS
    this.setState({
      currentlySelectedClientDetails,
      selectedThreshold: benchmarkBy,
      tempSelectedThresholdForInsightsView: selectedThreshold,
      tempSelectedInsightsViewBenchmark: selectedInsightsViewBenchmark,
      selectedTrendViewBenchmark: selectedInsightsViewBenchmark,
      tempSelectedTrendViewBenchmark: selectedInsightsViewBenchmark,
      selectedTrendViewBenchmarkOption,
      tempStartDate: startDate,
      tempEndDate: endDate,
      tempStartDateSavedForInsightsView: startDate,
      tempEndDateSavedForInsightsView: endDate,
      tempFormattedStartDateSavedForInsightsView: formattedStartDate,
      tempFormattedEndDateSavedForInsightsView: formattedEndDate,
      currentView: 'trendBenchmarkComparisonView',
    });
  };

  handleClickBackFromTrendsView = () => {
    const {
      tempSelectedThresholdForInsightsView,
      tempSelectedInsightsViewBenchmark,
      tempStartDateSavedForInsightsView,
      tempEndDateSavedForInsightsView,
      tempFormattedStartDateSavedForInsightsView,
      tempFormattedEndDateSavedForInsightsView,
    } = this.state;
    // RETRIEVE BACK THE TIME FRAME VALUES FOR DATE PICKERS NAD SELECTED BENCHMARK FROM TEMPORARY FIELDS WHEN SWITCHING BACK TO "metricBenchmarkDetailsView" AND EMPTY THE TEMPORARY FIELD VALUES
    this.setState({
      currentView: 'metricBenchmarkDetailsView',
      selectedInsightsViewBenchmark: tempSelectedInsightsViewBenchmark,
      selectedThreshold: tempSelectedThresholdForInsightsView,
      startDate: tempStartDateSavedForInsightsView,
      endDate: tempEndDateSavedForInsightsView,
      formattedStartDate: tempFormattedStartDateSavedForInsightsView,
      formattedEndDate: tempFormattedEndDateSavedForInsightsView,
      tempStartDate: null,
      tempEndDate: null,
      tempStartDateSavedForInsightsView: null,
      tempEndDateSavedForInsightsView: null,
      tempFormattedStartDateSavedForInsightsView: '',
      tempFormattedEndDateSavedForInsightsView: '',
      isVisibleTrendViewBenchmarkPopover: false,
      tempSelectedInsightsViewBenchmark: '',
      selectedTrendViewBenchmark: '',
      tempSelectedTrendViewBenchmark: '',
      selectedTrendViewBenchmarkOption: {},
      tempSelectedTrendViewBenchmarkOption: {},
      marketUnitsRadioOptionsForTrendsViewBenchmarkSelection: [],
      selectedTrendViewBenchmarkMarketUnitOption: {},
      tempSelectedTrendViewBenchmarkMarketUnitOption: {},
      nameOfVisibleTrendViewBenchmarkMarketUnitRadioSection: '',
      nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection: '',
    });
  };

  // EXECUTES THE API REQUEST TO FETCH CLIENT METRIC DETAILS
  fetchClientMetricDetails = () => new Promise((resolve, reject) => {
    const {
      parentScreen, metricData, offeringName, offeringType, subOfferingName,
    } = this.props;
    const {
      formattedStartDate,
      formattedEndDate,
      currentlySelectedClientDetails,
      // trendViewBenchmarkOptions,
      trendViewBenchmarkOptionsInitial,
      selectedThreshold: selectedInsightsViewThreshold,
      currentView,
    } = this.state;

    let {
      selectedTrendViewBenchmark, // "internal" OR "external"
      selectedTrendViewBenchmarkOption,
      selectedTrendViewBenchmarkMarketUnitOption,
    } = this.state;

    const clientOriginalIndustry = currentlySelectedClientDetails.originalIndustry;
    const clientOriginalMarketUnit = currentlySelectedClientDetails.originalMarketUnit;

    const clientIndustry = currentlySelectedClientDetails.industry;
    const clientMarketUnit = currentlySelectedClientDetails.marketUnit;

    const marketUnitOfClient = !_.isEmpty(clientOriginalMarketUnit)
      ? clientOriginalMarketUnit
      : clientMarketUnit;
    const requestData = {
      offeringName,
      towerName: subOfferingName,
      metricName: metricData.metricName,
      timeFrameStartDate: formattedStartDate,
      timeFrameEndDate: formattedEndDate,
      clientName: currentlySelectedClientDetails.clientName,
      industryName: !_.isEmpty(clientOriginalIndustry) ? clientOriginalIndustry : clientIndustry,
      thresholdType: 'CrossIndustry,IndustryWise,MarketUnit',
      // marketUnit: marketUnitOfClient,
      // corporateEntityId: '',
    };

    this.setState({ isAPILoading: true });
    let clientMetricData = {};
    const modifiedTrendViewBenchmarkOptions = _.cloneDeep(trendViewBenchmarkOptionsInitial);

    // FETCH THE DATA FROM THE API
    let apiEndPoint;
    if (currentView === 'trendBenchmarkComparisonView') {
      apiEndPoint = parentScreen === 'diverseData'
        ? 'GetSynOpsClientMetricDetails'
        : 'GetClientMetricDetails';
    } else if (currentView === 'trendDCView') {
      apiEndPoint = parentScreen === 'diverseData'
        ? 'GetSynOpsClientMetricDetailsForDCView'
        : 'GetClientMetricDetailsForDCView';
    } else if (currentView === 'trendPeerComparisonView') {
      apiEndPoint = parentScreen === 'diverseData'
        ? 'GetSynOpsClientMetricDetailsForPeerView'
        : 'GetClientMetricDetailsForPeerView';
    }

    axiosInstance
      .post(`${LocalApiBaseUrl}BAT/${apiEndPoint}`, requestData)
      .then((response) => {
        const responseData = response.data;

        let nameOfVisibleTrendViewBenchmarkMarketUnitRadioSection = '';
        const marketUnitsRadioOptionsForTrendsViewBenchmarkSelection = [];
        const deliveryCenters = [];

        if (!_.isEmpty(responseData) && !_.isEmpty(_.get(responseData, 'clientDetails', {}))) {
          const originalClientDetailsArr = responseData.clientDetails;
          const originalThresholdsArr = responseData.thresholds;
          const originalMarketUnitsArr = responseData.marketUnits;

          // LOOP OVER "THRESHOLD" DATA RECEIVED FROM THE API
          const modifiedThresholdsArr = _.map(originalThresholdsArr, (eachThresholdObj) => {
            const { thresholdType } = eachThresholdObj;
            const modifiedEachThresholdObj = modifyBenchmarkValues(eachThresholdObj);

            // FOR THE THRESHOLD TYPE "INDUSTRY" OR "CROSS-INDUSTRY" DISABLE THE RADIO OPTIONS IN BENCHMARK SELECTION POPOVER IF THE CORRESPONDING BENCHMARKS ARE EMPTY
            const indexFilteredRadioOptionForInternal = _.findIndex(
              modifiedTrendViewBenchmarkOptions,
              (eachItem) => eachItem.benchmarkType === 'internal' && eachItem.benchmarkBy === thresholdType,
            );
            _.set(
              modifiedTrendViewBenchmarkOptions,
              `${indexFilteredRadioOptionForInternal}.isDisabled`,
              modifiedEachThresholdObj.areEmptyInternalBenchmarks,
            );

            const indexFilteredRadioOptionForExternal = _.findIndex(
              modifiedTrendViewBenchmarkOptions,
              (eachItem) => eachItem.benchmarkType === 'external' && eachItem.benchmarkBy === thresholdType,
            );
            _.set(
              modifiedTrendViewBenchmarkOptions,
              `${indexFilteredRadioOptionForExternal}.isDisabled`,
              modifiedEachThresholdObj.areEmptyExternalBenchmarks,
            );

            return { ...modifiedEachThresholdObj };
          });

          // LOOP OVER "MARKET UNIT" DATA RECEIVED FROM THE API
          const modifiedMarketUnitsArr = _.map(originalMarketUnitsArr, (eachItem) => {
            const modifiedEachItem = modifyBenchmarkValues(eachItem);
            const { marketUnitName } = eachItem;
            let areClientDetailsValuesEmpty = false;
            const clientDetails = _.get(eachItem, 'clientDetails', []);
            if (!_.isEmpty(clientDetails)) {
              // CHECK IF ALL THE `medianMetricValue` ARE EMPTY
              areClientDetailsValuesEmpty = _.isEmpty(
                _.filter(
                  clientDetails,
                  (eachClientDetailObj) => !_.isEmpty(String(eachClientDetailObj.medianMetricValue)),
                ),
              );
            }

            // GENERATE "MARKET UNIT" RADIO OPTIONS INDIVIDUALLY FOR "Internal" and "External" BENCHMARKS
            marketUnitsRadioOptionsForTrendsViewBenchmarkSelection.push({
              label: marketUnitName,
              benchmarkType: 'internal',
              value: `internal_${marketUnitName}`,
              // DISABLE THE MARKET UNIT RADIO OPTIONS IF THE BENCHMARKS ARE EMPTY OR THE VALUES IN `clientDetails` ARE EMPTY
              isDisabled:
                modifiedEachItem.areEmptyInternalBenchmarks || areClientDetailsValuesEmpty,
            });
            marketUnitsRadioOptionsForTrendsViewBenchmarkSelection.push({
              label: marketUnitName,
              benchmarkType: 'external',
              value: `external_${marketUnitName}`,
              // DISABLE THE MARKET UNIT RADIO OPTIONS IF THE BENCHMARKS ARE EMPTY OR THE VALUES IN `clientDetails` ARE EMPTY
              isDisabled:
                modifiedEachItem.areEmptyExternalBenchmarks || areClientDetailsValuesEmpty,
            });

            const returnObj = {
              ...modifiedEachItem,
            };

            // FOR THE BENCHMARK LINE CHART EACH "MARKET UNIT" OBJECT WILL CONTAIN `clientDetails`. SO MODIFY THE DETAILS AND ADD IT TO THE `returnObj`. THIS WILL NOT BE THE CASE FOR "DC WISE" CHART API RESPONSE
            if (currentView === 'trendBenchmarkComparisonView') {
              returnObj.clientDetails = modifyClientDetails(eachItem.clientDetails);
            }
            return { ...returnObj };
          });

          let modifiedDeliveryCentersArr = [];
          let modifiedPeersArr = [];
          // FOR "DC WISE" CHART, LOOP OVER `deliveryCenters` DATA RECEIVED FROM THE API
          if (currentView === 'trendDCView') {
            const originalDeliveryCentersArr = responseData.deliveryCenters;
            modifiedDeliveryCentersArr = _.map(
              originalDeliveryCentersArr,
              (eachDeliveryCenterObj) => {
                const { deliveryCenterName } = eachDeliveryCenterObj;
                if (_.toLower(deliveryCenterName).indexOf('across') === -1) {
                  deliveryCenters.push(deliveryCenterName);
                }

                return {
                  ...eachDeliveryCenterObj,
                  clientDetails: modifyClientDetails(eachDeliveryCenterObj.clientDetails),
                };
              },
            );
          } else if (currentView === 'trendPeerComparisonView') {
            const originalPeersArr = responseData.peers;
            modifiedPeersArr = _.map(originalPeersArr, (eachPeerObj) => ({
              ...eachPeerObj,
              peerDetails: modifyClientDetails(eachPeerObj.peerDetails),
            }));
          }

          // CHECK IF ALL THE "INTERNAL" "MARKET UNIT" RADIO OPTIONS ARE DISABLED
          const areAllInternalMarketUnitRadioOptionsDisabled = _.isEmpty(
            _.find(
              marketUnitsRadioOptionsForTrendsViewBenchmarkSelection,
              (eachRadioOption) => eachRadioOption.benchmarkType === 'internal'
                && eachRadioOption.isDisabled === false,
            ),
          );

          // CHECK IF ALL THE "EXTERNAL" "MARKET UNIT" RADIO OPTIONS ARE DISABLED
          const areAllExternalMarketUnitRadioOptionsDisabled = _.isEmpty(
            _.find(
              marketUnitsRadioOptionsForTrendsViewBenchmarkSelection,
              (eachRadioOption) => eachRadioOption.benchmarkType === 'external'
                && eachRadioOption.isDisabled === false,
            ),
          );

          // LOOP OVER THE TREND VIEW BENCHMARK RADIO OPTIONS AND SET THE "MARKET UNIT" RADIO OPTIONS TO DISABLED IF ALL THE CORRESPONDING BENCHMARKS FOR THOSE MARKET UNITS ARE EMPTY
          _.forEach(modifiedTrendViewBenchmarkOptions, (eachBenchmarkOption, index) => {
            if (eachBenchmarkOption.benchmarkBy === 'MarketUnit') {
              if (
                eachBenchmarkOption.benchmarkType === 'internal'
                && areAllInternalMarketUnitRadioOptionsDisabled
              ) {
                modifiedTrendViewBenchmarkOptions[index].isDisabled = true;
              } else if (
                eachBenchmarkOption.benchmarkType === 'external'
                && areAllExternalMarketUnitRadioOptionsDisabled
              ) {
                modifiedTrendViewBenchmarkOptions[index].isDisabled = true;
              }
            }
          });

          // PREPARE THE `clientMetricData` OBJECT WITH ALL THE MODIFIED PROPERTIES AND VALUES
          clientMetricData = {
            aliasName: responseData.aliasName,
            clientDetails: modifyClientDetails(originalClientDetailsArr),
            thresholds: modifiedThresholdsArr,
            marketUnits: modifiedMarketUnitsArr,
          };

          // FOR THE "DC WISE" CHART ADD THE DATA OBJ FOR DELIVERY CENTERS
          if (currentView === 'trendDCView') {
            clientMetricData.deliveryCenters = modifiedDeliveryCentersArr;
          } else if (currentView === 'trendPeerComparisonView') {
            // FOR THE "PEER COMPARISON" CHART ADD THE DATA OBJ FOR PEERS
            clientMetricData.peers = modifiedPeersArr;
          }

          const selectedThreshold = _.get(
            selectedTrendViewBenchmarkOption,
            'benchmarkBy',
            selectedInsightsViewThreshold,
          ); // "IndustryWise", "MarketUnit" OR "CrossIndustry"
          const marketUnitNameForFilter = _.get(
            selectedTrendViewBenchmarkMarketUnitOption,
            'label',
            marketUnitOfClient,
          );

          // BELOW IS THE LOGIC TO DECIDE THAT OUT OF ALL THE BENCHMARKS DROPDOWN RADIO OPTION WHICH RADIO BUTTON IS TO BE KEPT AS SELECTED
          let filteredClientMetricData = {};
          if (selectedThreshold === 'IndustryWise') {
            filteredClientMetricData = _.find(
              clientMetricData.thresholds,
              (eachItem) => eachItem.thresholdType === 'IndustryWise',
            );
          } else if (selectedThreshold === 'MarketUnit') {
            filteredClientMetricData = _.find(
              clientMetricData.marketUnits,
              (eachItem) => eachItem.marketUnitName === marketUnitNameForFilter,
            );
          } else {
            filteredClientMetricData = _.find(
              clientMetricData.thresholds,
              (eachItem) => eachItem.thresholdType === 'CrossIndustry',
            );
          }

          let areBenchmarksEmpty = false;
          if (selectedTrendViewBenchmark === 'internal') {
            areBenchmarksEmpty = _.get(filteredClientMetricData, 'areEmptyInternalBenchmarks', true);
          } else if (selectedTrendViewBenchmark === 'external') {
            areBenchmarksEmpty = _.get(filteredClientMetricData, 'areEmptyExternalBenchmarks', true);
          }

          // SET THE DEFAULT TO "CrossIndustry". FOR BOTH "IndustryWise" AND "MarketUnit", IF FILTER IS ENGAGED AND ONLY ONE INDUSTRY OR MARKET UNIT IS SELECTED (OR THERE ONLY JUST ONE INDUSTRY OR MARKET UNIT) AND THE RESPECTIVE BENCHMARKS (internal OR external) ARE NOT EMPTY (THIS VIEW WILL BE TERMED AS "INDUSTRY/MARKET UNIT" SPECIFIC VIEW AS THERE IS JUST ONE INDUSTRY OR MARKET UNIT CURRENTLY VISIBLE IN THE VIEW), THEN SET THE RADIO BUTTON TO BE SELECTED BY EITHER "IndustryWise" OR "MarketUnit"
          let benchmarkBy = 'CrossIndustry';
          selectedTrendViewBenchmarkMarketUnitOption = {};
          if (selectedThreshold === 'IndustryWise' && !areBenchmarksEmpty) {
            benchmarkBy = 'IndustryWise';
          } else if (selectedThreshold === 'MarketUnit' && !areBenchmarksEmpty) {
            benchmarkBy = 'MarketUnit';
            selectedTrendViewBenchmarkMarketUnitOption = _.find(
              marketUnitsRadioOptionsForTrendsViewBenchmarkSelection,
              { benchmarkType: selectedTrendViewBenchmark, label: marketUnitNameForFilter },
            );
            nameOfVisibleTrendViewBenchmarkMarketUnitRadioSection = selectedTrendViewBenchmark;
          }

          // FOR THE "INDUSTRY" OFFERINGS, THERE WOULD BE NO CROSS-INDUSTRY BENCHMARKS RADIO OPTIONS PRESENT SO SET THE SELECTED BENCHMARK RADIO TO `selectedThreshold`
          if (offeringType === 'industry') {
            benchmarkBy = selectedThreshold;
            // FOR THE "INDUSTRY" OFFERINGS, IF "MarketUnit" BENCHMARKS ARE EMPTY THEN SET THE BENCHMARKS SELECTION TO "IndustryWise". NOTE FOR "FUNCTIONAL" OFFERINGS THIS IS SET TO "CrossIndustry" BY DEFAULT
            if (selectedThreshold === 'MarketUnit' && areBenchmarksEmpty) {
              benchmarkBy = 'IndustryWise';
            }
          }

          // SET THE SELECTED "TREND VIEW BENCHMARK" OPTION
          const selectedBenchmarkForFilter = selectedTrendViewBenchmark;
          const foundTrendViewBenchmarkOption = _.find(
            trendViewBenchmarkOptionsInitial,
            (eachItem) => eachItem.benchmarkType === selectedBenchmarkForFilter
              && eachItem.benchmarkBy === benchmarkBy,
          );
          selectedTrendViewBenchmarkOption = foundTrendViewBenchmarkOption;

          selectedTrendViewBenchmark = selectedTrendViewBenchmarkOption.benchmarkType;
        } else {
          this.setState({ isAPILoading: false, clientMetricData: {} });
          window.alert('Data is not available for the selected time frame');
        }

        this.setState(
          {
            isAPILoading: false,
            clientMetricData,
            selectedTrendViewBenchmark,
            tempSelectedTrendViewBenchmark: selectedTrendViewBenchmark,
            trendViewBenchmarkOptions: modifiedTrendViewBenchmarkOptions,
            selectedTrendViewBenchmarkOption,
            tempSelectedTrendViewBenchmarkOption: selectedTrendViewBenchmarkOption,
            marketUnitsRadioOptionsForTrendsViewBenchmarkSelection,
            selectedTrendViewBenchmarkMarketUnitOption,
            tempSelectedTrendViewBenchmarkMarketUnitOption: selectedTrendViewBenchmarkMarketUnitOption,
            nameOfVisibleTrendViewBenchmarkMarketUnitRadioSection,
            nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection: nameOfVisibleTrendViewBenchmarkMarketUnitRadioSection,
            deliveryCenters,
            selectedDeliveryCenters: [],
            tempSelectedDeliveryCenters: [],
            selectedDeliveryCenterForTrendComparison: '',
          },
          () => {
            if (!_.isEmpty(clientMetricData)) {
              if (
                currentView === 'trendBenchmarkComparisonView'
                || currentView === 'trendDCView'
              ) {
                this.prepareDataForBenchmarkOrDCTrendChart();
              }
            }
          },
        );
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        reject(error);
        this.setState({ isAPILoading: false, clientMetricData: {} });
      });
  });

  // RENDERS LINE (TREND) CHART FOR BENCHMARK COMPARISON VIEW
  prepareDataForBenchmarkOrDCTrendChart = (isChartAnimationEnabled = true) => {
    const {
      clientMetricData,
      metricUnit,
      currentLeadingPracticeForMetric,
      selectedTrendViewBenchmark,
      selectedTrendViewBenchmarkOption,
      selectedTrendViewBenchmarkMarketUnitOption,
      currentView,
      selectedDeliveryCenters,
      selectedDeliveryCenterForTrendComparison,
    } = this.state;
    const green = 'rgb(126, 195, 68)';
    const yellow = 'rgb(255, 200, 44)';
    const red = 'rgb(213, 0, 47)';

    const selectedBenchmark = selectedTrendViewBenchmark; // "internal" OR "external"
    const selectedThresholdType = selectedTrendViewBenchmarkOption.benchmarkBy; // "IndustryWise", "MarketUnit" OR "CrossIndustry"

    let benchmarkValuesDataObj = {};
    let trendLinesDetailsArr = [
      {
        label: '',
        clientDetails: clientMetricData.clientDetails,
      },
    ];

    if (selectedThresholdType === 'IndustryWise' || selectedThresholdType === 'CrossIndustry') {
      benchmarkValuesDataObj = _.find(
        clientMetricData.thresholds,
        (eachItem) => eachItem.thresholdType === selectedThresholdType,
      );
    } else if (selectedThresholdType === 'MarketUnit') {
      benchmarkValuesDataObj = _.find(
        clientMetricData.marketUnits,
        (eachItem) => eachItem.marketUnitName === selectedTrendViewBenchmarkMarketUnitOption.label,
      );

      if (currentView === 'trendBenchmarkComparisonView') {
        trendLinesDetailsArr = [
          {
            label: '',
            clientDetails: benchmarkValuesDataObj.clientDetails,
          },
        ];
      }
    }

    // FOR "DC-WISE" TREND CHART
    if (currentView === 'trendDCView') {
      trendLinesDetailsArr = _.map(clientMetricData.deliveryCenters, (eachDeliveryCenterDetails) => ({
        label: eachDeliveryCenterDetails.deliveryCenterName,
        clientDetails: eachDeliveryCenterDetails.clientDetails,
      }));

      // IN THE ARRAY OF ALL THE TRENDS DATA, BRING THE DATA ARRAY OF "ACROSS ALL DELIVERY CENTERS" IN THE FRONT SO THAT THEN TREND OF THIS PLOTTED ON TOP OF (Z-INDEX WISE) THE OTHER TRENDS
      const trendDataArrForAcrossDeliveryCenters = _.remove(
        trendLinesDetailsArr,
        (eachItem) => _.toLower(eachItem.label).indexOf('across') !== -1,
      );
      trendLinesDetailsArr = _.concat(trendDataArrForAcrossDeliveryCenters, trendLinesDetailsArr);

      // IF THE DELIVERY CENTERS ARE SELECTED IN THE "DC CHECKBOXES POPOVER", THEN ONLY SHOW THE SELECTED DC TRENDS AND THE TREND FOR "ACROSS ALL DELIVERY CENTERS"
      if (!_.isEmpty(selectedDeliveryCenters)) {
        trendLinesDetailsArr = _.filter(
          trendLinesDetailsArr,
          (eachItem) => _.indexOf(selectedDeliveryCenters, eachItem.label) !== -1
            || _.toLower(eachItem.label).indexOf('across') !== -1,
        );
      }
    }

    const totalDataValues = trendLinesDetailsArr[0].clientDetails.length;

    // ******************************BELOW IS THE EXPLANATION THE BELOW IMPLEMENTED CODE LOGIC OF HOW THE COLOR IS ASSIGNED TO CLIENT METRIC VALUE AND THE LOGIC OF THE DASHED LINES ********************************* //

    /**
     * Indicator=better - color=green
     * Indicator=worse - color=yellow
     * Indicator=worst - color=red
     *
     * When Leading Practice = 'minimum', it means minimum of the lower or upper quartile values is better (`betterComparatorVal`).
     * So all the metric values which are less than or equal to `betterComparatorVal` are better.
     *
     * Similarly,
     * When Leading Practice = 'maximum', it means maximum of the lower or upper quartile values is better (`betterComparatorVal`).
     * So all the metric values which are greater than or equal to `betterComparatorVal` are better.
     */

    // BY DEFAULT GET "internal" VALUES FOR COMPARISON BECAUSE DEFAULT SELECTED BENCHMARK (RADIO) WOULD BE "internal"
    let lowerQuartileValue = _.get(benchmarkValuesDataObj, 'internalLowerQuartileFloatVal', '');
    let medianValue = _.get(benchmarkValuesDataObj, 'internalMedianFloatVal', '');
    let upperQuartileValue = _.get(benchmarkValuesDataObj, 'internalUpperQuartileFloatVal', '');

    // IF THE BENCHMARK RADIO IS SELECTED TO "external" THEN SELECT "external" VALUES FOR COMPARISON
    if (selectedBenchmark === 'external') {
      lowerQuartileValue = _.get(benchmarkValuesDataObj, 'externalLowerQuartileFloatVal', '');
      medianValue = _.get(benchmarkValuesDataObj, 'externalMedianFloatVal', '');
      upperQuartileValue = _.get(benchmarkValuesDataObj, 'externalUpperQuartileFloatVal', '');
    }

    const isEmptyLowerQuartileValue = _.isEmpty(String(lowerQuartileValue));
    const isEmptyMedianValue = _.isEmpty(String(medianValue));
    const isEmptyUpperQuartileValue = _.isEmpty(String(upperQuartileValue));

    let areEmptyBenchmarkValues = false;

    // CHECK IF ALL THE BENCHMARK VALUES ARE EMPTY
    if (isEmptyLowerQuartileValue && isEmptyMedianValue && isEmptyUpperQuartileValue) {
      areEmptyBenchmarkValues = true;
    }

    // IF LEADING PRACTICE IS "minimum" LOWER VALUE IS BETTER AND IF IT IS "maximum" THEN HIGHER VALUE IS BETTER
    let betterComparatorVal;
    let worstComparatorVal;
    const worseComparatorVal = medianValue;

    // BELOW FLAGS THAT INDICATE WHETHER THE BENCHMARK VALUES ARE MANUALLY CALCULATED (WHEN EMPTY) AND SUBSTITUTED WHEN PARTIAL BENCHMARK VALUES ARE AVAILABLE. SUBSTITUTED VALUES ARE NOT THE ACTUAL VALUES SO THE BENCHMARKS WITH SUBSTITUTED VALUES WOULD NOT BE DISPLAYED ON THE GRAPH. THE MISSING BENCHMARK VALUES ARE MANUALLY CALCULATED AND SUBSTITUTED FOR THE COLOR CODING OF THE CHART DATA POINTS
    let isBetterComparatorValSubstituted = false;
    const isWorseComparatorValSubstituted = isEmptyMedianValue;
    let isWorstComparatorValSubstituted = false;

    // BELOW SEQUENCES ARE IMPORTANT FOR DISPLAYING THE PROPER BACKGROUND COLORS IN PROPER SEQUENCE
    let betterDataSequence = 0;
    const worseDataSequence = 1;
    let worstDataSequence = 2;

    const betterComparatorData = {
      label: 'Upper Quartile',
      value: '',
      isValueSubstituted: false,
      color: green,
      backgroundColor: greenColorForGradient,
      sequence: betterDataSequence,
    };

    const worseComparatorData = {
      label: 'Median',
      value: worseComparatorVal,
      isValueSubstituted: isWorseComparatorValSubstituted,
      color: yellow,
      backgroundColor: yellowColorForGradient,
      sequence: worseDataSequence,
    };

    const worstComparatorData = {
      label: 'Lower Quartile',
      value: '',
      isValueSubstituted: false,
      color: red,
      backgroundColor: redColorForGradient,
      sequence: worstDataSequence,
    };

    if (currentLeadingPracticeForMetric === 'minimum') {
      if (
        (!isEmptyLowerQuartileValue && !isEmptyMedianValue && !isEmptyUpperQuartileValue)
        || (!isEmptyLowerQuartileValue && isEmptyMedianValue && !isEmptyUpperQuartileValue)
      ) {
        // WHEN (ALL THE BENCHMARK VALUES i.e lowerQuartile, median & upperQuartile ARE NON-EMPTY) OR
        // (median IS EMPTY, lowerQuartile & upperQuartile ARE NON-EMPTY)
        // THEN, LOWER VALUE AMONG `lowerQuartileValue` AND `upperQuartileValue` IS BETTER
        betterComparatorVal = _.min([lowerQuartileValue, upperQuartileValue]);
        worstComparatorVal = _.max([lowerQuartileValue, upperQuartileValue]);
      } else if (!isEmptyLowerQuartileValue && isEmptyMedianValue && isEmptyUpperQuartileValue) {
        // WHEN lowerQuartile IS NON-EMPTY, median & upperQuartile ARE EMPTY
        betterComparatorVal = lowerQuartileValue - 0.01;
        worstComparatorVal = lowerQuartileValue;

        isBetterComparatorValSubstituted = true;
      } else if (isEmptyLowerQuartileValue && !isEmptyMedianValue && isEmptyUpperQuartileValue) {
        // WHEN median IS NON-EMPTY, lowerQuartile & upperQuartile ARE EMPTY
        betterComparatorVal = medianValue - 0.01;
        worstComparatorVal = medianValue + 0.01;

        isBetterComparatorValSubstituted = true;
        isWorstComparatorValSubstituted = true;
      } else if (isEmptyLowerQuartileValue && isEmptyMedianValue && !isEmptyUpperQuartileValue) {
        // WHEN upperQuartile IS NON-EMPTY, lowerQuartile & median ARE EMPTY
        betterComparatorVal = upperQuartileValue;
        worstComparatorVal = upperQuartileValue + 0.01;

        isWorstComparatorValSubstituted = true;
      } else if (!isEmptyLowerQuartileValue && !isEmptyMedianValue && isEmptyUpperQuartileValue) {
        // WHEN lowerQuartile & median ARE NON-EMPTY, upperQuartile IS EMPTY
        betterComparatorVal = medianValue - 0.01;
        worstComparatorVal = lowerQuartileValue;

        isBetterComparatorValSubstituted = true;
      } else if (isEmptyLowerQuartileValue && !isEmptyMedianValue && !isEmptyUpperQuartileValue) {
        // WHEN lowerQuartile IS EMPTY, median & upperQuartile ARE NON-EMPTY
        betterComparatorVal = upperQuartileValue;
        worstComparatorVal = medianValue + 0.01;

        isWorstComparatorValSubstituted = true;
      }
    } else if (currentLeadingPracticeForMetric === 'maximum') {
      if (
        (!isEmptyLowerQuartileValue && !isEmptyMedianValue && !isEmptyUpperQuartileValue)
        || (!isEmptyLowerQuartileValue && isEmptyMedianValue && !isEmptyUpperQuartileValue)
      ) {
        // WHEN (ALL THE BENCHMARK VALUES i.e lowerQuartile, median & upperQuartile ARE NON-EMPTY) OR
        // (median IS EMPTY, lowerQuartile & upperQuartile ARE NON-EMPTY)
        // THEN, GREATER VALUE AMONG `lowerQuartileValue` AND `upperQuartileValue` IS BETTER
        betterComparatorVal = _.max([lowerQuartileValue, upperQuartileValue]);
        worstComparatorVal = _.min([lowerQuartileValue, upperQuartileValue]);
      } else if (!isEmptyLowerQuartileValue && isEmptyMedianValue && isEmptyUpperQuartileValue) {
        // WHEN lowerQuartile IS NON-EMPTY, median & upperQuartile ARE EMPTY
        betterComparatorVal = lowerQuartileValue + 0.01;
        worstComparatorVal = lowerQuartileValue;

        isBetterComparatorValSubstituted = true;
      } else if (isEmptyLowerQuartileValue && !isEmptyMedianValue && isEmptyUpperQuartileValue) {
        // WHEN median IS NON-EMPTY, lowerQuartile & upperQuartile ARE EMPTY
        betterComparatorVal = medianValue + 0.01;
        worstComparatorVal = medianValue - 0.01;

        isBetterComparatorValSubstituted = true;
        isWorstComparatorValSubstituted = true;
      } else if (isEmptyLowerQuartileValue && isEmptyMedianValue && !isEmptyUpperQuartileValue) {
        // WHEN upperQuartile IS NON-EMPTY, lowerQuartile & median ARE EMPTY
        betterComparatorVal = upperQuartileValue;
        worstComparatorVal = upperQuartileValue - 0.01;

        isWorstComparatorValSubstituted = true;
      } else if (!isEmptyLowerQuartileValue && !isEmptyMedianValue && isEmptyUpperQuartileValue) {
        // WHEN lowerQuartile & median ARE NON-EMPTY, upperQuartile IS EMPTY
        betterComparatorVal = medianValue + 0.01;
        worstComparatorVal = lowerQuartileValue;

        isBetterComparatorValSubstituted = true;
      } else if (isEmptyLowerQuartileValue && !isEmptyMedianValue && !isEmptyUpperQuartileValue) {
        // WHEN lowerQuartile IS EMPTY, median & upperQuartile ARE NON-EMPTY
        betterComparatorVal = upperQuartileValue;
        worstComparatorVal = medianValue - 0.01;

        isWorstComparatorValSubstituted = true;
      }

      worstDataSequence = 0;
      betterDataSequence = 2;
    }

    betterComparatorData.value = betterComparatorVal;
    betterComparatorData.isValueSubstituted = isBetterComparatorValSubstituted;
    betterComparatorData.sequence = betterDataSequence;

    worstComparatorData.value = worstComparatorVal;
    worstComparatorData.isValueSubstituted = isWorstComparatorValSubstituted;
    worstComparatorData.sequence = worstDataSequence;

    // SORT THE BENCHMARK DATA ARRAY SO TO PLOT THE COLORED ZONES IN PROPER SEQUENCE
    let benchmarkDataArr = [];
    benchmarkDataArr.push({
      ...betterComparatorData,
      value: isBetterComparatorValSubstituted ? '' : betterComparatorVal,
    });
    benchmarkDataArr.push({
      ...worseComparatorData,
      value: isWorseComparatorValSubstituted ? '' : worseComparatorVal,
    });
    benchmarkDataArr.push({
      ...worstComparatorData,
      value: isWorstComparatorValSubstituted ? '' : worstComparatorVal,
    });
    benchmarkDataArr = _.sortBy(benchmarkDataArr, 'sequence');

    // LOOP OVER THE `trendLinesDetailsArr`
    const modifiedTrendLinesDetailsArr = _.map(trendLinesDetailsArr, (eachTrendLineDetail) => {
      const currentClientDetails = eachTrendLineDetail.clientDetails;

      const modifiedClientDetails = _.map(currentClientDetails, (eachClientDetail) => {
        // APPEND THE INDICATOR VALUE WHETHER THE METRIC VALUE IS "better", "worse" OR "worst"
        const clientMedianMetricValueFloatVal = eachClientDetail.medianMetricValueFloatVal;
        const indicator = getIndicatorValueForMetricValueBasedOnCriteria(
          clientMedianMetricValueFloatVal,
          betterComparatorVal,
          worstComparatorVal,
          currentLeadingPracticeForMetric,
        );

        // SET THE COLOR CODE FOR THE POINT AS PER THE INDICATOR VALUE
        let pointColor = '';
        if (!areEmptyBenchmarkValues) {
          if (indicator === 'better') {
            pointColor = green;
          } else if (indicator === 'worst') {
            pointColor = red;
          } else {
            pointColor = yellow;
          }
        }

        return {
          ...eachClientDetail,
          indicator,
          pointColor,
        };
      });

      return {
        ...eachTrendLineDetail,
        clientDetails: modifiedClientDetails,
      };
    });

    // PREPARE ARRAYS OF DATA SETS FOR PLOTTING "better = green", "worse = yellow" AND "worst = red" DASHED LINES
    // IF THE BENCHMARK VALUES WERE MANUALLY CALCULATED AND SUBSTITUTED (WHEN EMPTY), THEN DON'T PLOT THEM ON THE CHART BECAUSE THOSE ARE NOT THE REAL (ORIGINAL) BENCHMARK VALUES
    let betterComparatorDataset = [];
    if (!betterComparatorData.isValueSubstituted) {
      betterComparatorDataset = _.fill(Array(totalDataValues), betterComparatorVal);
    }

    let worseComparatorDataset = [];
    if (!isWorseComparatorValSubstituted) {
      worseComparatorDataset = _.fill(Array(totalDataValues), worseComparatorVal);
    }

    let worstComparatorDataset = [];
    if (!worstComparatorData.isValueSubstituted) {
      worstComparatorDataset = _.fill(Array(totalDataValues), worstComparatorVal);
    }
    // *************************************************************** //

    const getBenchmarkBubbleDatasetsCommonProps = ({
      label, value, color, dataLabelColor,
    }) => ({
      type: 'bubble',
      hoverRadius: 2,
      hitRadius: 2,
      fill: true,
      label,
      data: [{ x: 0, y: value, r: 10 }],
      backgroundColor: color,
      datalabels: {
        display: true,
        color: dataLabelColor,
        font: {
          size: '8',
        },
        formatter(valueObj) {
          return valueObj.y;
        },
      },
    });

    const getBenchmarkLineDatasetsCommonProps = ({ label, data, color }) => ({
      type: 'line',
      fill: false,
      borderWidth: 1,
      borderDash: [5, 5],
      lineTension: 0,
      pointStyle: 'line',
      borderColor: color,
      pointRadius: 0,
      pointHitRadius: 0,
      data,
      label,
    });

    // ---------------------- "better" - "green" AREA INDICATORS ----------------------//

    const betterDatasets = _.isEmpty(betterComparatorDataset)
      ? []
      : [
        // "GREEN" BUBBLE
        {
          ...getBenchmarkBubbleDatasetsCommonProps({
            label: betterComparatorData.label,
            value: betterComparatorVal,
            color: green,
            dataLabelColor: '#ffffff',
          }),
        },
        // DASHED "GREEN" LINE
        {
          ...getBenchmarkLineDatasetsCommonProps({
            label: betterComparatorData.label,
            data: betterComparatorDataset,
            color: green,
          }),
        },
      ];

    // ---------------------- "worse" - "yellow" AREA INDICATORS ----------------------//
    const worseDatasets = _.isEmpty(worseComparatorDataset)
      ? []
      : [
        // "YELLOW" BUBBLE
        {
          ...getBenchmarkBubbleDatasetsCommonProps({
            label: 'Median',
            value: worseComparatorVal,
            color: yellow,
            dataLabelColor: 'black',
          }),
        },
        // DASHED "YELLOW" LINE
        {
          ...getBenchmarkLineDatasetsCommonProps({
            label: 'Median',
            data: worseComparatorDataset,
            color: yellow,
          }),
        },
      ];

    // ---------------------- "worst" - "red" AREA INDICATORS ----------------------//
    const worstDatasets = _.isEmpty(worstComparatorDataset)
      ? []
      : [
        // "RED" BUBBLE
        {
          ...getBenchmarkBubbleDatasetsCommonProps({
            label: worstComparatorData.label,
            value: worstComparatorVal,
            color: red,
            dataLabelColor: '#ffffff',
          }),
        },
        // DASHED "RED" LINE
        {
          ...getBenchmarkLineDatasetsCommonProps({
            label: worstComparatorData.label,
            data: worstComparatorDataset,
            color: red,
          }),
        },
      ];

    // PREPARE DATA FOR MAIN LINE CHART DATASET
    const xAxisLabels = _.map(trendLinesDetailsArr[0].clientDetails, 'monthFormatted');

    // PREPARE DATASETS FOR TREND LINES
    const mainLineChartDataset = _.map(modifiedTrendLinesDetailsArr, (eachTrendLineDetail) => {
      const { label } = eachTrendLineDetail;
      const currentClientDetails = eachTrendLineDetail.clientDetails;
      const dataValuesArr = _.map(currentClientDetails, 'medianMetricValueFloatVal');
      const pointColorArr = _.map(currentClientDetails, 'pointColor');

      let borderWidth = 1.5;
      let borderColor = 'rgba(196, 205, 213, 0.9)';
      let pointBorderColor = pointColorArr;
      let pointHoverBorderColor = pointColorArr;
      let pointBackgroundColor = pointColorArr;
      let pointHoverBackgroundColor = pointColorArr;
      let pointRadius = 6;
      let pointHitRadius = 6;
      let pointHoverRadius = 10;
      let pointBorderWidth = 2;
      if (currentView === 'trendDCView') {
        borderWidth = 1;
        borderColor = '#c1ccce';
        pointBackgroundColor = 'transparent';
        pointHoverBorderColor = 'transparent';
        pointHoverBackgroundColor = 'transparent';
        pointRadius = 0.5;
        pointHitRadius = 0;
        pointHoverRadius = 0;
        pointBorderWidth = 0;

        // FORMAT SOME PROPERTIES OF THE TREND FOR "ACROSS DELIVERY CENTERS" DATA
        if (_.toLower(label).indexOf('across') !== -1) {
          borderWidth = 2;
          borderColor = '#a100ff';
          pointRadius = 6;
          pointHitRadius = 6;
          pointHoverRadius = 8;
          pointBorderWidth = 2;
          pointBackgroundColor = 'white';
          pointHoverBorderColor = pointColorArr;
          pointHoverBackgroundColor = pointColorArr;
        } else if (label === selectedDeliveryCenterForTrendComparison) {
          // FORMAT SOME PROPERTIES OF THE TREND FOR SELECTED "DC" FOR COMPARISON
          borderWidth = 1.5;
          borderColor = '#018eff';
          pointRadius = 5;
          pointHitRadius = 5;
          pointHoverRadius = 7;
          pointBorderWidth = 2;
          pointBackgroundColor = 'white';
          pointHoverBorderColor = pointColorArr;
          pointHoverBackgroundColor = pointColorArr;
        }
        pointBorderColor = borderColor;
      }

      return {
        label,
        borderWidth,
        borderColor,
        pointRadius,
        pointHitRadius,
        pointBorderWidth,
        pointHoverRadius,
        pointBorderColor,
        pointHoverBorderColor,
        pointBackgroundColor,
        pointHoverBackgroundColor,
        data: dataValuesArr,
        lineTension: 0,
        fill: true,
        backgroundColor: 'transparent',
        datalabels: {
          align: 'top',
          display: false,
        },
        spanGaps: true,
      };
    });

    let chartDatasets = _.concat(mainLineChartDataset);

    // IF BENCHMARK VALUES ARE NOT EMPTY THEN ONLY ADD THE BENCHMARK BUBBLE AND LINE DATASETS
    if (!areEmptyBenchmarkValues) {
      chartDatasets = _.concat(chartDatasets, betterDatasets, worseDatasets, worstDatasets);
    }

    const dataForCharts = {
      xAxisLabels,
      chartDatasets,
      metricUnit,
      benchmarkDataArr,
      betterComparatorVal,
      worstComparatorVal,
      currentLeadingPracticeForMetric,
      isChartAnimationEnabled,
    };

    if (currentView === 'trendBenchmarkComparisonView') {
      this.renderBenchmarkComparisonTrendChart(dataForCharts);
    } else {
      this.renderDCWiseComparisonTrendChart(dataForCharts);
    }
  };

  renderDCWiseComparisonTrendChart = ({
    xAxisLabels,
    chartDatasets,
    metricUnit,
    benchmarkDataArr,
    betterComparatorVal,
    worstComparatorVal,
    currentLeadingPracticeForMetric,
    isChartAnimationEnabled,
  }) => {
    const { selectedDeliveryCenterForTrendComparison } = this.state;

    this.dcWiseComparisonTrendChartContext = this.dcWiseComparisonTrendChartRef.current.getContext(
      '2d',
    );

    // IF THE CHART WAS ALREADY RENDERED BEFORE THEN DESTROY IT
    if (!_.isNil(this.dcWiseComparisonTrendChart)) {
      this.dcWiseComparisonTrendChart.destroy();
    }

    const That = this;
    const clickableLabelsArr = [];
    // LINE (TREND) CHART FOR DELIVERY CENTER WISE COMPARISON
    this.dcWiseComparisonTrendChart = new Chart(this.dcWiseComparisonTrendChartContext, {
      type: 'line',
      data: {
        labels: xAxisLabels,
        datasets: chartDatasets,
      },
      options: {
        maintainAspectRatio: false,
        animation: {
          duration: isChartAnimationEnabled ? 1000 : 0,
        },
        layout: {
          padding: {
            top: 25,
            right: 70,
          },
        },
        legend: {
          display: false,
          labels: {
            usePointStyle: true,
            padding: 30,
            filter(legendItem) {
              // ONLY SHOW LEGENDS FOR DASHED LINES
              return _.get(legendItem, 'pointStyle', '') === 'line';
            },
          },
          // DISABLE LEGENDS CLICK
          onClick: (e) => e.stopPropagation(),
        },
        // CALLBACK FUNCTION FOR CUSTOM HTML RENDERING OF LEGENDS. WILL BE EXECUTED WHEN `chart.generateLegend()` FUNCTION WILL BE CALLED. WILL RETURN THE HTML STRING THAT COULD BE RENDERED IN THE INNER HTML OF ANY HTML ELEMENT
        legendCallback(chart) {
          // THE FILTERED LEGEND ITEMS HERE ARE RECEIVED FROM `legend.labels.generateLabels` FUNCTION
          const filteredLegendItems = chart.legend.legendItems;

          let legendHTML = '';
          if (filteredLegendItems.length > 0) {
            legendHTML = `
            <ul>
              <li>
                <div class="industry-legend peerToPeer-legends-list">
                  <ul>
                  <li>
                    <span class="hr-solid-line" style="border-color: #a100ff"></span>
                    <span>Overall Trend</span>
                  </li>
                  `;

            filteredLegendItems.forEach((eachLegendItem) => {
              const item = `
                <li>
                  <span class="hr-dashed-line" style="border-color: ${eachLegendItem.strokeStyle}"></span>
                  <span>${eachLegendItem.text}</span>
                </li>
              `;
              legendHTML += item;
            });

            legendHTML
              += `
                    </ul>
                  </div>
                </li>
              </ul>
            `;
          }
          return legendHTML;
        },
        hover: {
          mode: 'nearest',
        },
        tooltips: {
          enabled: false,
          mode: 'nearest',
          intersect: true,
          filter(tooltipItem) {
            const tooltipDatasetIndex = tooltipItem.datasetIndex;
            const currentDataset = _.get(chartDatasets, `${tooltipDatasetIndex}`);

            const datasetType = _.get(currentDataset, 'type', '');
            const datasetLabel = _.toLower(_.get(currentDataset, 'label', ''));

            let showTooltipForCurrentDataset = false;
            // HIDE THE TOOLTIP FOR THE DASHED LINES (FOR DISPLAYING BENCHMARKS) DATASET (for these the `datasetType` = "line")
            if (
              datasetType === 'bubble'
              || (datasetType === '' && datasetLabel.indexOf('across') !== -1)
              || (datasetType === ''
                && datasetLabel === _.toLower(selectedDeliveryCenterForTrendComparison))
            ) {
              showTooltipForCurrentDataset = true;
            }

            return showTooltipForCurrentDataset;
          },
          custom(tooltip) {
            let dataPointsArr = _.get(tooltip, 'dataPoints', []);

            // IF THERE ARE NO DATA POINTS OR THE OPACITY OF THE `tooltip` IS "0" THEN REMOVE THE TOOLTIP ELEMENT
            if (_.isEmpty(dataPointsArr) || tooltip.opacity === 0) {
              $('#chartjs-tooltip').remove();
              return;
            }

            // Tooltip Element
            let tooltipEl = document.getElementById('chartjs-tooltip');
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = "<div class='tooltip-body'></div>";
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltip.yAlign) {
              tooltipEl.classList.add(tooltip.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            // THE CLASS NAME TO BE ADDED TO THE TOOLTIP ELEMENT BASED ON THE INDICATOR VALUE OF THE POINT WHETHER "BETTER", "WORSE" OR "WORST"
            let indicatorClassNameForTooltip = '';

            // GENERATE DYNAMIC CONTENT FOR TOOLTIP BODY
            if (tooltip.body) {
              let innerHtml = '';

              if (!_.isEmpty(dataPointsArr)) {
                // IN THE ARRAY OF ALL THE DATA POINTS, FIRST REMOVE AND THEN APPEND AT END THE DATA ARRAY OF "ACROSS ALL DELIVERY CENTERS" SO THAT IN THE COMBINED TOOLTIP (MULTIPLE POINTS WITH SAME VALUE), THE CONTENT OF THE SELECTED DELIVERY CENTER IS DISPLAYED BEFORE THE CONTENT OF "ACROSS ALL DELIVERY CENTERS"
                const trendDataPointArrForAcrossDeliveryCenters = _.remove(
                  dataPointsArr,
                  (eachDataPoint) => {
                    const { datasetIndex } = eachDataPoint;
                    const currentDataset = chartDatasets[datasetIndex];
                    return _.toLower(currentDataset.label).indexOf('across') !== -1;
                  },
                );
                dataPointsArr = _.concat(dataPointsArr, trendDataPointArrForAcrossDeliveryCenters);

                _.forEach(dataPointsArr, (eachDataPoint) => {
                  const { datasetIndex } = eachDataPoint;
                  const datasetMeta = this._chart.getDatasetMeta(datasetIndex);
                  const currentDataset = chartDatasets[datasetIndex];

                  let tooltipTitle = currentDataset.label;
                  const tooltipValue = eachDataPoint.value;
                  let tooltipFooterLabel = '';
                  if (_.toLower(tooltipTitle).indexOf('across') !== -1) {
                    tooltipTitle = 'Overall Trend';
                  }

                  if (datasetMeta.type !== 'bubble') {
                    tooltipFooterLabel = eachDataPoint.xLabel;
                  }

                  indicatorClassNameForTooltip = getIndicatorValueForMetricValueBasedOnCriteria(
                    tooltipValue,
                    betterComparatorVal,
                    worstComparatorVal,
                    currentLeadingPracticeForMetric,
                  );

                  innerHtml += `
                    <div class="benchmark-tooltip">
                      <div class="benchmark-tooltip-title">
                        ${tooltipTitle}
                      </div>
                      <div>
                        <span class="day-count">${tooltipValue}</span>
                        <span class="day-label">${metricUnit}</span>
                      </div>
                  `;

                  if (!_.isEmpty(tooltipFooterLabel)) {
                    innerHtml += `<div class="benchmark-tooltip-footer-label">${tooltipFooterLabel}</div>`;
                  }
                  innerHtml += '</div>';
                });
                innerHtml = `<div class="ddbenchmark-tooltip-body">${innerHtml}</div>`;

                // WHEN THE NUMBER OF DATAPOINTS IS GREATER THAN 2 THEN APPLY A CSS CLASS TO THE TOOLTIP ELEMENT SO THAT THE LAYOUT OF THE TOOLTIP DOES NOT BREAK
                if (_.size(dataPointsArr) > 2) {
                  tooltipEl.classList.add('more-than-two-points');
                }
              }
              const tooltipBody = tooltipEl.querySelector('.tooltip-body');
              tooltipBody.innerHTML = innerHtml;
            }

            const positionY = this._chart.canvas.offsetTop;
            const positionX = this._chart.canvas.offsetLeft;

            if (!_.isEmpty(indicatorClassNameForTooltip)) {
              // REMOVE THE PREVIOUSLY ADDED INDICATOR CLASSES
              tooltipEl.classList.remove('better');
              tooltipEl.classList.remove('worse');
              tooltipEl.classList.remove('worst');

              // ADD THE CURRENT INDICATOR CLASS
              tooltipEl.classList.add(indicatorClassNameForTooltip);
            }

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
            tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
            tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
            tooltipEl.style.fontSize = `${tooltip.bodyFontSize}px`;
            tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
            tooltipEl.style.padding = `${tooltip.yPadding}px ${tooltip.xPadding}px`;

            // APPEND THE DYNAMICALLY CREATED TOOLTIP ELEMENT TO THE CHART CANVAS
            this._chart.canvas.parentNode.appendChild(tooltipEl);
          },
        },
        responsive: true,
        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Timeframe',
                color: '#637381',
                fontSize: 18,
                fontStyle: 'bold',
              },
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
                color: 'rgba(196, 205, 213, 0.5)',
                offsetGridLines: false,
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: false,
                labelString: !_.isEmpty(metricUnit) ? `Metric Unit (${metricUnit})` : '',
                color: '#637381',
                fontSize: 18,
                fontStyle: 'bold',
              },
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
                color: 'rgba(196, 205, 213, 0.5)',
                offsetGridLines: false,
              },
              ticks: {
                beginAtZero: false,
                callback(value) {
                  let valWithUnit = value;
                  if (!_.isEmpty(metricUnit)) {
                    valWithUnit = metricUnit === '%' ? `${value}%` : `${value} ${metricUnit}`;
                  }

                  return valWithUnit;
                },
              },
            },
          ],
        },
        benchmarkData: benchmarkDataArr,
      },
      plugins: [
        {
          afterDraw(chart) {
            // THIS WHOLE CODE BLOCK IS FOR DRAWING CUSTOM CLICKABLE HTML LABELS ON THE CHART
            const xAxis = chart.scales['x-axis-0'];
            const chartAreaRight = xAxis.right;
            const { datasets } = chart.chart.config.data;
            const filteredDatasets = _.filter(
              datasets,
              (eachDataset) => _.get(eachDataset, 'type', '') === '',
            );

            _.forEach(filteredDatasets, (eachDataset) => {
              const datasetMeta = eachDataset._meta;
              const datasetMetaDataObj = _.get(datasetMeta, _.first(_.keys(datasetMeta)));
              // FIND THE LAST DATA POINT IN THE TREND LINE DATASET WITH NON-EMPTY STRING VALUE
              const lastDataPointMeta = _.findLast(datasetMetaDataObj.data, (eachData) => !_.isNaN(eachData._model.y));
              const datasetLabel = eachDataset.label;
              // DON'T ADD THE DATA OF THE TREND FOR "ACROSS ALL DELIVERY CENTERS" TO THE CLICKABLE LABELS
              if (_.toLower(datasetLabel).indexOf('across') === -1 && !_.isEmpty(lastDataPointMeta)) {
                clickableLabelsArr.push({
                  datasetLabel,
                  lastPointPosition: {
                    x: chartAreaRight,
                    y: lastDataPointMeta._model.y,
                  },
                });
              }
            });

            window.funSelectDeliveryCenterForComparison = function (selectedDC) {
              // EXECUTE THE API CALL TO FETCH THE CLIENT DETAILS
              That.handleClickDeliveryCenterLabelOnChart(selectedDC);
            };

            function getClickableLabelHTML(labelName, isSelected) {
              const labelId = `clickable-label-${labelName}`;
              let labelHTML = `
              <span
                id="${labelId}"
                class="dc-clickable-label"
                onclick="window.funSelectDeliveryCenterForComparison('${labelName}')"
              >${labelName}</span>`;

              if (isSelected) {
                labelHTML = `
                <span
                  id="${labelId}"
                  class="dc-clickable-label selected"
                  onclick="window.funSelectDeliveryCenterForComparison('${labelName}')"
                >${labelName}</span>`;
              }

              return labelHTML;
            }

            window.createClickableLabel = function (name, xPos, yPos, allClickableLabelsArr) {
              const linkId = `clickable-label-${name}`;
              if (!document.getElementById(linkId)) {
                // FROM ALL THE CLICKABLE LABELS ARRAY FILTER OUT THE LABELS WITH SAME (CURRENT) POSITION TO CHECK IF THERE ARE MULTIPLE POINT WITH THE SAME POSITION
                const filteredClickableLabelsObjWithSamePosition = _.filter(
                  allClickableLabelsArr,
                  (eachClickableLabel) => eachClickableLabel.lastPointPosition.x === xPos
                    && eachClickableLabel.lastPointPosition.y === yPos,
                );

                let labelsHTML = '';
                // IF THERE ARE MULTIPLE LABELS WITH THE SAME POSITION THEN COMBINE THEM, ELSE DISPLAY IT INDIVIDUALLY
                if (_.size(filteredClickableLabelsObjWithSamePosition) > 1) {
                  labelsHTML = _.join(
                    _.map(filteredClickableLabelsObjWithSamePosition, (eachFilteredObj) => {
                      const label = eachFilteredObj.datasetLabel;
                      let linkElem = getClickableLabelHTML(label, false);

                      if (label === selectedDeliveryCenterForTrendComparison) {
                        linkElem = getClickableLabelHTML(label, true);
                      }
                      return linkElem;
                    }),
                    ' | ',
                  );
                } else {
                  labelsHTML = getClickableLabelHTML(name, false);
                  if (name === selectedDeliveryCenterForTrendComparison) {
                    labelsHTML = getClickableLabelHTML(name, true);
                  }
                }

                const labelsReducePaddingTop = 15;
                const labelsExtraPaddingLeft = 10;
                const clickableLabelHTML = `<div
                      class="dc-clickable-label-container"
                      style="
                      position: absolute;
                      margin-top: ${yPos - labelsReducePaddingTop}px;
                      margin-left: ${xPos + labelsExtraPaddingLeft}px;
                    "
                  >
                    ${labelsHTML}
                  </div>`;
                $('#dcWiseComparisonTrendChartContainer').prepend($(clickableLabelHTML));
              }
            };

            // FILTER OUT THE UNIQUE LABELS BY POSITION
            const uniqueClickableLabelsArr = _.uniqBy(
              clickableLabelsArr,
              (eachLabel) => eachLabel.lastPointPosition.x && eachLabel.lastPointPosition.y,
            );

            // LOOP OVER UNIQUE CLICKABLE LABEL ARRAY TO DISPLAY THEM ON THE CHART
            _.forEach(uniqueClickableLabelsArr, (eachItem) => {
              window.createClickableLabel(
                eachItem.datasetLabel,
                eachItem.lastPointPosition.x,
                eachItem.lastPointPosition.y,
                clickableLabelsArr,
              );
            });
          },
        },
      ],
    });

    const legendContent = this.dcWiseComparisonTrendChart.generateLegend();

    this.setState({
      legendHTMLContentForBenchmarkOrDCTrendChart: legendContent,
    });
  };

  renderBenchmarkComparisonTrendChart = ({
    xAxisLabels,
    chartDatasets,
    metricUnit,
    benchmarkDataArr,
    betterComparatorVal,
    worstComparatorVal,
    currentLeadingPracticeForMetric,
  }) => {
    this.benchmarkComparisonTrendChartContext = this.benchmarkComparisonTrendChartRef.current.getContext(
      '2d',
    );

    // IF THE CHART WAS ALREADY RENDERED BEFORE THEN DESTROY IT
    if (!_.isNil(this.benchmarkComparisonTrendChart)) {
      this.benchmarkComparisonTrendChart.destroy();
    }
    // LINE (TREND) CHART FOR BENCHMARK COMPARISON
    this.benchmarkComparisonTrendChart = new Chart(this.benchmarkComparisonTrendChartContext, {
      type: 'line',
      data: {
        labels: xAxisLabels,
        datasets: chartDatasets,
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 25,
          },
        },
        legend: {
          display: false,
          labels: {
            usePointStyle: true,
            padding: 30,
            filter(legendItem) {
              // ONLY SHOW LEGENDS FOR DASHED LINES
              return _.get(legendItem, 'pointStyle', '') === 'line';
            },
          },
          // DISABLE LEGENDS CLICK
          onClick: (e) => e.stopPropagation(),
        },
        // CALLBACK FUNCTION FOR CUSTOM HTML RENDERING OF LEGENDS. WILL BE EXECUTED WHEN `chart.generateLegend()` FUNCTION WILL BE CALLED. WILL RETURN THE HTML STRING THAT COULD BE RENDERED IN THE INNER HTML OF ANY HTML ELEMENT
        legendCallback(chart) {
          // THE FILTERED LEGEND ITEMS HERE ARE RECEIVED FROM `legend.labels.generateLabels` FUNCTION
          const filteredLegendItems = chart.legend.legendItems;

          let legendHTML = '';
          if (filteredLegendItems.length > 0) {
            legendHTML = `
            <ul>
              <li>
                <div class="industry-legend peerToPeer-legends-list">
                  <ul>`;

            filteredLegendItems.forEach((eachLegendItem) => {
              const item = `
                <li>
                  <span class="hr-dashed-line" style="border-color: ${eachLegendItem.strokeStyle}"></span>
                  <span>${eachLegendItem.text}</span>
                </li>
              `;
              legendHTML += item;
            });

            legendHTML
              += `
                    </ul>
                  </div>
                </li>
              </ul>
            `;
          }
          return legendHTML;
        },
        hover: {
          mode: 'nearest',
        },
        tooltips: {
          enabled: false,
          mode: 'nearest',
          intersect: true,
          filter(tooltipItem) {
            const tooltipDatasetIndex = tooltipItem.datasetIndex;
            const currentDataset = _.get(chartDatasets, `${tooltipDatasetIndex}`);

            // HIDE THE TOOLTIP FOR THE DASHED LINE (FOR DISPLAYING BENCHMARKS) DATASET
            return _.get(currentDataset, 'type', '') !== 'line';
          },
          custom(tooltip) {
            const dataPointsArr = _.get(tooltip, 'dataPoints', []);

            // IF THERE ARE NO DATA POINTS OR THE OPACITY OF THE `tooltip` IS "0" THEN REMOVE THE TOOLTIP ELEMENT
            if (_.isEmpty(dataPointsArr) || tooltip.opacity === 0) {
              $('#chartjs-tooltip').remove();
              return;
            }

            // Tooltip Element
            let tooltipEl = document.getElementById('chartjs-tooltip');

            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = "<div class='tooltip-body'></div>";
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltip.yAlign) {
              tooltipEl.classList.add(tooltip.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            // THE CLASS NAME TO BE ADDED TO THE TOOLTIP ELEMENT BASED ON THE INDICATOR VALUE OF THE POINT WHETHER "BETTER", "WORSE" OR "WORST"
            let indicatorClassNameForTooltip = '';

            // GENERATE DYNAMIC CONTENT FOR TOOLTIP BODY
            if (tooltip.body) {
              let innerHtml = '';

              if (!_.isEmpty(dataPointsArr)) {
                _.forEach(dataPointsArr, (eachDataPoint) => {
                  const { datasetIndex } = eachDataPoint;
                  const datasetMeta = this._chart.getDatasetMeta(datasetIndex);
                  let tooltipTitle = '';
                  if (datasetMeta.type === 'bubble') {
                    const currentDataset = chartDatasets[datasetIndex];
                    tooltipTitle = currentDataset.label;
                  } else {
                    tooltipTitle = eachDataPoint.xLabel;
                  }
                  const tooltipValue = eachDataPoint.value;

                  indicatorClassNameForTooltip = getIndicatorValueForMetricValueBasedOnCriteria(
                    tooltipValue,
                    betterComparatorVal,
                    worstComparatorVal,
                    currentLeadingPracticeForMetric,
                  );

                  innerHtml += `
                    <div class="benchmark-tooltip">
                      <div class="benchmark-tooltip-title">
                        ${tooltipTitle}
                      </div>
                      <div>
                        <span class="day-count">${tooltipValue}</span>
                        <span class="day-label">${metricUnit}</span>
                      </div>
                    </div>
                  `;
                });
                innerHtml = `<div class="ddbenchmark-tooltip-body">${innerHtml}</div>`;

                // WHEN THE NUMBER OF DATAPOINTS IS GREATER THAN 2 THEN APPLY A CSS CLASS TO THE TOOLTIP ELEMENT SO THAT THE LAYOUT OF THE TOOLTIP DOES NOT BREAK
                if (_.size(dataPointsArr) > 2) {
                  tooltipEl.classList.add('more-than-two-points');
                }
              }

              const tooltipBody = tooltipEl.querySelector('.tooltip-body');
              tooltipBody.innerHTML = innerHtml;
            }

            const positionY = this._chart.canvas.offsetTop;
            const positionX = this._chart.canvas.offsetLeft;

            if (!_.isEmpty(indicatorClassNameForTooltip)) {
              // REMOVE THE PREVIOUSLY ADDED INDICATOR CLASSES
              tooltipEl.classList.remove('better');
              tooltipEl.classList.remove('worse');
              tooltipEl.classList.remove('worst');

              // ADD THE CURRENT INDICATOR CLASS
              tooltipEl.classList.add(indicatorClassNameForTooltip);
            }
            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
            tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
            tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
            tooltipEl.style.fontSize = `${tooltip.bodyFontSize}px`;
            tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
            tooltipEl.style.padding = `${tooltip.yPadding}px ${tooltip.xPadding}px`;

            // APPEND THE DYNAMICALLY CREATED TOOLTIP ELEMENT TO THE CHART CANVAS
            this._chart.canvas.parentNode.appendChild(tooltipEl);
          },
        },
        responsive: true,
        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Timeframe',
                color: '#637381',
                fontSize: 18,
                fontStyle: 'bold',
              },
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
                color: 'rgba(196, 205, 213, 0.5)',
                offsetGridLines: false,
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: false,
                labelString: !_.isEmpty(metricUnit) ? `Metric Unit (${metricUnit})` : '',
                color: '#637381',
                fontSize: 18,
                fontStyle: 'bold',
              },
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
                color: 'rgba(196, 205, 213, 0.5)',
                offsetGridLines: false,
              },
              ticks: {
                beginAtZero: false,
                callback(value) {
                  let valWithUnit = value;
                  if (!_.isEmpty(metricUnit)) {
                    valWithUnit = metricUnit === '%' ? `${value}%` : `${value} ${metricUnit}`;
                  }

                  return valWithUnit;
                },
              },
            },
          ],
        },
        benchmarkData: benchmarkDataArr,
      },
    });

    const legendContent = this.benchmarkComparisonTrendChart.generateLegend();
    this.setState({
      legendHTMLContentForBenchmarkOrDCTrendChart: legendContent,
    });
  };

  // EVENT HANDLER FOR "Internal/External" BENCHMARK LABELS IN THE TREND VIEW BENCHMARK SELECTION POPOVER
  handleClickBenchmarkForTrendsView = (selectedBenchmark) => {
    const {
      tempSelectedTrendViewBenchmarkMarketUnitOption,
    } = this.state;
    let {
      nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection,
    } = this.state;

    // IF THE BENCHMARK IS CHANGED (FOR EX: FROM "internal" TO "external"), THEN HIDE THE MARKET UNIT RADIO SECTION
    if (nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection !== selectedBenchmark) {
      nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection = '';
    }

    // IF THE MARKET UNIT RADIO OPTION IS SELECTED AND BENCHMARK SELECTION IS CHANGED BACK (FOR EX: FROM "internal" TO "external" AND BACK TO "internal" WITHOUT MAKING ANY CHANGE IN RADIO OPTION SELECTION FOR "external"), THEN SHOW THE MARKET UNIT RADIO SECTION CORRESPONDING TO THE CURRENTLY SELECTED MARKET UNIT RADIO OPTION
    if (
      !_.isEmpty(tempSelectedTrendViewBenchmarkMarketUnitOption)
      && _.get(tempSelectedTrendViewBenchmarkMarketUnitOption, 'benchmarkType') === selectedBenchmark
    ) {
      nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection = selectedBenchmark;
    }

    this.setState({
      tempSelectedTrendViewBenchmark: selectedBenchmark,
      nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection,
    });
  };

  // EVENT HANDLER FOR THE "BACK" ARROW ICON FOR HIDING THE MARKET UNITS RADIO OPTIONS LIST IN THE TREND VIEW BENCHMARK SELECTION POPOVER
  handleClickHideMarketUnitBenchmarkDropdownRadioSection = (sectionName) => {
    let { nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection } = this.state;
    if (sectionName === nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection) {
      nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection = '';
    }

    this.setState({
      nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection,
    });
  };

  // EVENT HANDLER FOR BENCHMARK RADIO OPTION SELECTION THAT IS VISIBLE ONLY IN TRENDS VIEW
  handleClickTrendViewBenchmarkDropdownRadio = (selectedOption) => {
    let {
      nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection,
      tempSelectedTrendViewBenchmarkMarketUnitOption,
    } = this.state;

    if (selectedOption.benchmarkBy === 'MarketUnit') {
      if (nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection === '') {
        nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection = selectedOption.benchmarkType;
      }

      // IF "MARKET" OPTION IS SELECTED BUT THE IF THE SELECTED SPECIFIC MARKET UNIT OPTION IS NOT OF THE `benchmarkType` OF THE "MARKET" OPTION, THEN EMPTY THE `tempSelectedTrendViewBenchmarkMarketUnitOption`
      if (
        !_.isEmpty(tempSelectedTrendViewBenchmarkMarketUnitOption)
        && _.get(tempSelectedTrendViewBenchmarkMarketUnitOption, 'benchmarkType', '')
        !== selectedOption.benchmarkType
      ) {
        tempSelectedTrendViewBenchmarkMarketUnitOption = {};
      }
    }

    this.setState({
      tempSelectedTrendViewBenchmarkOption: selectedOption,
      nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection,
      tempSelectedTrendViewBenchmarkMarketUnitOption,
    });
  };

  // EVENT HANDLER FOR MARKET UNITS BENCHMARK RADIO OPTIONS SELECTION THAT IS VISIBLE ONLY IN TRENDS VIEW
  handleChangeMarketUnitBenchmarkDropdownRadio = (selectedOption) => {
    this.setState({
      tempSelectedTrendViewBenchmarkMarketUnitOption: selectedOption,
    });
  };

  // HIDES THE "BENCHMARK POPOVER" WHEN CLICKED OUTSIDE THE POPOVER
  hideTrendViewBenchmarkPopover = () => {
    this.setState({
      isVisibleTrendViewBenchmarkPopover: false,
    });
  };

  toggleTrendViewBenchmarkPopover = () => {
    const { isVisibleTrendViewBenchmarkPopover } = this.state;
    this.setState({
      isVisibleTrendViewBenchmarkPopover: !isVisibleTrendViewBenchmarkPopover,
    });
  };

  onShowTrendViewBenchmarkPopover = () => {
    this.handleResetTrendViewBenchmarkSelection();
  };

  // EVENT HANDLER FOR THE "UPDATE" BUTTON IN BENCHMARK SELECTION POPOVER FOR THE TREND VIEW
  handleUpdateTrendViewBenchmarkSelection = () => {
    const {
      currentView,
      tempSelectedTrendViewBenchmarkOption,
      tempSelectedTrendViewBenchmarkMarketUnitOption,
      nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection,
    } = this.state;

    // IF THE "MARKET" OPTION IS SELECTED BUT THE CORRESPONDING "ONE SPECIFIC MARKET" IS NOT SELECTED THEN SHOW THE ALERT, ELSE UPDATE THE STATES AND RE-RENDER THE CHART
    if (
      tempSelectedTrendViewBenchmarkOption.benchmarkBy === 'MarketUnit'
      && _.isEmpty(tempSelectedTrendViewBenchmarkMarketUnitOption)
    ) {
      this.setState(
        {
          tempSelectedTrendViewBenchmark: tempSelectedTrendViewBenchmarkOption.benchmarkType,
          nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection:
            tempSelectedTrendViewBenchmarkOption.benchmarkType,
        },
        () => {
          setTimeout(
            () => window.alert('Please select a specific Market to view the benchmarks'),
            100,
          );
        },
      );
    } else {
      this.setState(
        {
          selectedTrendViewBenchmark: tempSelectedTrendViewBenchmarkOption.benchmarkType,
          selectedTrendViewBenchmarkOption: tempSelectedTrendViewBenchmarkOption,
          selectedTrendViewBenchmarkMarketUnitOption: tempSelectedTrendViewBenchmarkMarketUnitOption,
          nameOfVisibleTrendViewBenchmarkMarketUnitRadioSection: nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection,
          isVisibleTrendViewBenchmarkPopover: false,
        },
        () => {
          if (currentView === 'trendBenchmarkComparisonView' || currentView === 'trendDCView') {
            this.prepareDataForBenchmarkOrDCTrendChart();
          }
        },
      );
    }
  };

  // EVENT HANDLER FOR THE "CLEAR" BUTTON IN BENCHMARK SELECTION POPOVER FOR THE TREND VIEW
  handleResetTrendViewBenchmarkSelection = () => {
    const {
      selectedTrendViewBenchmarkOption,
      selectedTrendViewBenchmark,
      selectedTrendViewBenchmarkMarketUnitOption,
      nameOfVisibleTrendViewBenchmarkMarketUnitRadioSection,
    } = this.state;

    this.setState({
      tempSelectedTrendViewBenchmark: selectedTrendViewBenchmark,
      tempSelectedTrendViewBenchmarkOption: selectedTrendViewBenchmarkOption,
      tempSelectedTrendViewBenchmarkMarketUnitOption: selectedTrendViewBenchmarkMarketUnitOption,
      nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection: nameOfVisibleTrendViewBenchmarkMarketUnitRadioSection,
    });
  };

  // EVENT HANDLER FOR THE CHANGE EVENT OF THE DROPDOWN FOR CHANGING THE TREND VIEW
  handleChangeCurrentView = (selectedView) => {
    this.setState({
      currentView: selectedView,
    });
  };

  handleClickDeliveryCenterLabelOnChart = (selectedDC) => {
    const { selectedDeliveryCenterForTrendComparison } = this.state;

    this.setState(
      {
        selectedDeliveryCenterForTrendComparison:
          selectedDeliveryCenterForTrendComparison !== selectedDC ? selectedDC : '',
      },
      () => {
        // REMOVE THE DOM ELEMENTS OF THE CLICKABLE DC LABELS ADDED ON CHART
        $('div.dc-clickable-label-container').each(function iteration() {
          $(this).remove();
        });
        this.prepareDataForBenchmarkOrDCTrendChart(false);
      },
    );
  };

  // EVENT HANDLER FOR "DELIVERY CENTER" CHECKBOX IN DELIVERY CENTERS SELECTION POPOVER VISIBLE FOR "DC-WISE COMPARISON" CHART
  handleChangeDeliveryCenterCheckbox = (event) => {
    const { tempSelectedDeliveryCenters } = this.state;

    const isChecked = event.target.checked;
    const deliveryCenter = event.target.value;
    const clone = _.cloneDeep(tempSelectedDeliveryCenters);

    // IF CHECKED THEN ADD IT TO THE SELECTION ARRAY, ELSE REMOVE IT FROM THE ARRAY
    if (isChecked) {
      clone.push(deliveryCenter);
    } else {
      _.remove(clone, (eachItem) => eachItem === deliveryCenter);
    }

    this.setState({
      tempSelectedDeliveryCenters: clone,
    });
  };

  hideDeliveryCentersPopover = () => {
    this.setState({
      isVisibleDeliveryCentersPopover: false,
    });
  };

  toggleDeliveryCentersPopover = () => {
    const { isVisibleDeliveryCentersPopover } = this.state;
    this.setState({
      isVisibleDeliveryCentersPopover: !isVisibleDeliveryCentersPopover,
    });
  };

  onShowDeliveryCentersPopover = () => {
    const { selectedDeliveryCenters } = this.state;
    this.setState({
      tempSelectedDeliveryCenters: selectedDeliveryCenters,
    });
  };

  // EVENT HANDLER FOR "CLEAR" BUTTON IN "CLIENT MARKET UNITS CHECKBOXES SELECTION POPOVER"
  handleClickClearSelectedDeliveryCenters = () => {
    this.setState({
      tempSelectedDeliveryCenters: [],
    });
  };

  // EVENT HANDLER FOR "UPDATE" BUTTON IN "CLIENT MARKET UNITS CHECKBOXES SELECTION POPOVER"
  handleClickUpdateSelectedDeliveryCenters = () => {
    const { tempSelectedDeliveryCenters } = this.state;

    this.setState(
      {
        selectedDeliveryCenters: tempSelectedDeliveryCenters,
        isVisibleDeliveryCentersPopover: false,
      },
      () => {
        // REMOVE THE DOM ELEMENTS OF THE CLICKABLE DC LABELS ADDED ON CHART
        $('div.dc-clickable-label-container').each(function iteration() {
          $(this).remove();
        });
        this.prepareDataForBenchmarkOrDCTrendChart();
      },
    );
  };

  setAPILoading = (stateToSet) => {
    this.setState({ isAPILoading: stateToSet });
  };

  render() {
    const {
      metricData,
      offeringName,
      subOfferingName,
      offeringType,
      clientSimulationData,
      existingSynOpsClientName,
      existingBATClientName,
      parentScreen,
    } = this.props;

    const {
      hasUserPermShowClientNames,
      isAPILoading,
      clientIndustries,
      selectedClientIndustries,
      tempSelectedClientIndustries,
      isVisibleClientIndustriesPopover,
      clientMarketUnits,
      selectedClientMarketUnits,
      tempSelectedClientMarketUnits,
      clientSubUnits,
      isVisibleClientMarketUnitsPopover,
      displayClientNames,
      displayQuartiles,
      selectedInsightsViewBenchmark,
      startDate,
      endDate,
      formattedStartDate,
      formattedEndDate,
      isVisibleDatepickerDropdownPopover,
      isStartEndDateSelected,
      tempStartDate,
      tempEndDate,
      formattedTempStartDate,
      formattedTempEndDate,
      currentView,
      currentlySelectedClientDetails,
      selectedThreshold,
      clientMetricData,
      isEmptyMetricBenchmarkDetails,
      isEmptyThresholdData,
      isDisabledExternalBenchmarksRadio,
      isDisabledInternalBenchmarks,
      barChartDefaultLegendContent,
      barChartCrossIndustryLegendData,
      legendHTMLContentForBenchmarkOrDCTrendChart,
      selectedTrendViewBenchmark,
      tempSelectedTrendViewBenchmark,
      isVisibleTrendViewBenchmarkPopover,
      trendViewBenchmarkOptions,
      marketUnitsRadioOptionsForTrendsViewBenchmarkSelection,
      selectedTrendViewBenchmarkOption,
      tempSelectedTrendViewBenchmarkOption,
      selectedTrendViewBenchmarkMarketUnitOption,
      tempSelectedTrendViewBenchmarkMarketUnitOption,
      nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection,
      tempIsSelectedAllClientIndustries,
      tempIsSelectedAllClientMarketUnits,
      thresholdData,
      isThresholdUnitNameCustomSet,
      isConsolidationForced,
      isClientExisting,
      deliveryCenters,
      selectedDeliveryCenters,
      tempSelectedDeliveryCenters,
      isVisibleDeliveryCentersPopover,
    } = this.state;

    const chartHeight = 550;

    let clientNameForDisplay = currentlySelectedClientDetails.clientName;
    if (!displayClientNames) {
      clientNameForDisplay = isClientExisting
        ? existingSynOpsClientName
        : currentlySelectedClientDetails.aliasName;
    }

    let modalTitle = isClientExisting
      ? `${clientNameForDisplay} - ${metricData.metricName}`
      : metricData.metricName;
    if (currentView !== 'metricBenchmarkDetailsView') {
      modalTitle = `${clientNameForDisplay} - ${metricData.metricName}`;
    }

    const normalUnitNames = []; // ARRAY OF NORMAL (UNCONSOLIDATED IN/MUs)
    let consolidatedUnitName = ''; // LABEL FOR THE CONSOLIDATED IN/MUs
    let consolidatedSubUnits = []; // ARRAY OF CONSOLIDATED IN/MUs

    // LOOP OVER IN/MUs TO SEPARATE OUT THE NORMAL (UNCONSOLIDATED IN/MUs) AND CONSOLIDATED IN/MUs
    const unitNamesArray = selectedThreshold === 'IndustryWise' ? clientIndustries : clientMarketUnits;
    _.forEach(unitNamesArray, (eachUnit) => {
      const subUnits = !_.isEmpty(clientSubUnits[eachUnit]) ? clientSubUnits[eachUnit] : [];
      if (subUnits.length > 0) {
        consolidatedUnitName = eachUnit;
        consolidatedSubUnits = subUnits;
      } else {
        normalUnitNames.push(eachUnit);
      }
    });

    // FOR TRENDS VIEW
    let currentlySelectedBenchmarkDropdownBenchmarkBy = '';
    if (selectedTrendViewBenchmarkOption.benchmarkBy === 'IndustryWise') {
      currentlySelectedBenchmarkDropdownBenchmarkBy = 'Industry';
    } else if (selectedTrendViewBenchmarkOption.benchmarkBy === 'MarketUnit') {
      currentlySelectedBenchmarkDropdownBenchmarkBy = 'Market';
    } else if (selectedTrendViewBenchmarkOption.benchmarkBy === 'CrossIndustry') {
      currentlySelectedBenchmarkDropdownBenchmarkBy = 'Cross Industry';
    }

    let tempSelectedBenchmarkDropdownBenchmarkBy = '';
    if (tempSelectedTrendViewBenchmarkOption.benchmarkBy === 'IndustryWise') {
      tempSelectedBenchmarkDropdownBenchmarkBy = 'Industry';
    } else if (tempSelectedTrendViewBenchmarkOption.benchmarkBy === 'MarketUnit') {
      tempSelectedBenchmarkDropdownBenchmarkBy = 'Market';
    } else if (tempSelectedTrendViewBenchmarkOption.benchmarkBy === 'CrossIndustry') {
      tempSelectedBenchmarkDropdownBenchmarkBy = 'Cross Industry';
    }

    // FOR TRENDS VIEW, BASED ON THE CURRENTLY SELECTED BENCHMARK TYPE ("Internal OR External"), FILTER THE RADIO OPTIONS TO BE DISPLAYED IN THE BENCHMARK SELECTION POPOVER
    const marketUnitsRadioOptionsForTrendsViewBenchmarkSelectionForDisplay = _.filter(
      marketUnitsRadioOptionsForTrendsViewBenchmarkSelection,
      ['benchmarkType', tempSelectedTrendViewBenchmark],
    );
    const trendViewBenchmarkOptionsForDisplay = _.filter(trendViewBenchmarkOptions, [
      'benchmarkType',
      tempSelectedTrendViewBenchmark,
    ]);

    // DISABLE THE OPTION LABEL TO SELECT THE "INTERNAL BENCHMARKS" IN TREND VIEW IF ALL THE OPTIONS ARE DISABLED FOR THIS CATEGORY
    const isDisabledInternalBenchmarkOptionForTrendView = _.isEmpty(
      _.find(
        trendViewBenchmarkOptions,
        (eachOption) => eachOption.benchmarkType === 'internal' && !eachOption.isDisabled,
      ),
    );

    // DISABLE THE OPTION LABEL TO SELECT THE "EXTERNAL BENCHMARKS" IN TREND VIEW IF ALL THE OPTIONS ARE DISABLED FOR THIS CATEGORY
    const isDisabledExternalBenchmarkOptionForTrendView = _.isEmpty(
      _.find(
        trendViewBenchmarkOptions,
        (eachOption) => eachOption.benchmarkType === 'external' && !eachOption.isDisabled,
      ),
    );

    const tempStartDateMoment = moment(tempStartDate);
    const tempEndDateMoment = moment(tempEndDate);
    const diffInMonths = tempEndDateMoment.diff(tempStartDateMoment, 'months');
    let showRangeErrorInDatePicker = false;
    if (currentView === 'trendPeerComparisonView' && diffInMonths < 5) {
      showRangeErrorInDatePicker = true;
    }

    const renderDisplayClientNamesSwitchToggle = () => (
      <div className="toggle-group">
        <input
          type="checkbox"
          value="yes"
          id="displayClientNames"
          onChange={(e) => this.handleChangeDisplayClientNames(e)}
          checked={displayClientNames}
        />
        <div className="onoffswitch" aria-hidden="true">
          <div className="onoffswitch-label">
            <div className="onoffswitch-inner" />
            <div className="onoffswitch-switch" />
          </div>
        </div>
        <label htmlFor="displayClientNames">Display Client Names</label>
      </div>
    );

    return (
      <>
        {/* IF API IS LOADING THEN SHOW THE LOADER */}
        {isAPILoading && <LoadingOverlay spinner={<SynopsLoader />} active />}

        <Modal
          show
          centered
          onHide={() => this.handleModalHide()}
          dialogClassName="modal-95w overflowInherit bat-chart-modal align-dialog-up"
          backdrop="static"
        >
          <Modal.Header
            closeButton={currentView === 'metricBenchmarkDetailsView'}
            className="justify-content-center"
          >
            <Modal.Title>
              <>
                {currentView !== 'metricBenchmarkDetailsView' && (
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    onClick={() => this.handleClickBackFromTrendsView()}
                  />
                )}
                {modalTitle}
                &nbsp;
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="bottom"
                  overlay={(
                    <Popover>
                      <Popover.Content className="smallPop">
                        {metricData.metricDescription}
                      </Popover.Content>
                    </Popover>
                  )}
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </OverlayTrigger>
              </>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="analyticsFilter no-whiteShadow px-0 py-2">
              {currentView === 'metricBenchmarkDetailsView' && (
                <div className="chooseOption p-0 mr-3">
                  {/* START SECTION - CLIENT INDUSTRIES CHECKBOXES SELECTION POPOVER */}
                  <Overlay
                    target={this.clientIndustriesPopoverTarget.current}
                    show={isVisibleClientIndustriesPopover}
                    placement="bottom"
                    onEnter={() => this.onShowClientIndustriesPopover()}
                    rootClose
                    rootCloseEvent="click"
                    onHide={() => this.hideClientIndustriesPopover()}
                  >
                    <Popover id="popover-basic">
                      <Popover.Content>
                        <div className="popContent">
                          {/* "All Industries" CHECKBOX - WOULD BE DISPLAYED ONLY WHEN `unitName` IS NOT CUSTOM SET AND OFFERING TYPE IS NOT AN "INDUSTRY" OFFERING */}
                          {!isThresholdUnitNameCustomSet && offeringType !== 'industry' && (
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-check">
                                    <input
                                      type="checkbox"
                                      name="allClientIndustries"
                                      value=""
                                      onChange={(e) => this.handleChangeAllClientIndustriesCheckbox(e)}
                                      checked={
                                        tempIsSelectedAllClientIndustries || isConsolidationForced
                                      }
                                      disabled={isConsolidationForced}
                                      className="form-check-input"
                                    />
                                    <span className="checkmark" />
                                    <span className="form-check-label">All Industries</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="row">
                            {normalUnitNames.length > 0 && (
                              <div
                                className={classNames(
                                  { col: _.isEmpty(consolidatedUnitName) },
                                  { col: !_.isEmpty(consolidatedUnitName) },
                                )}
                              >
                                <div className="row">
                                  {_.map(normalUnitNames, (eachItem) => {
                                    const industry = eachItem;
                                    return (
                                      <div className="col-lg-3" key={industry}>
                                        <div className="form-group">
                                          <label className="form-check">
                                            <input
                                              type="checkbox"
                                              name="clientIndustries"
                                              value={industry}
                                              onChange={(e) => this.handleChangeClientIndustryCheckbox(e)}
                                              checked={
                                                _.indexOf(
                                                  tempSelectedClientIndustries,
                                                  industry,
                                                ) !== -1 || isConsolidationForced
                                              }
                                              disabled={isConsolidationForced}
                                              className="form-check-input"
                                            />
                                            <span className="checkmark" />
                                            <span className="form-check-label">{industry}</span>
                                          </label>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}

                            {!_.isEmpty(consolidatedUnitName) && (
                              <div className="col-3">
                                <div className="row">
                                  <div className="col">
                                    <div className="row">
                                      <div className="col">
                                        <label className="form-check">
                                          <input
                                            type="checkbox"
                                            name="clientIndustries"
                                            value={consolidatedUnitName}
                                            onChange={(e) => this.handleChangeClientIndustryCheckbox(e)}
                                            checked={
                                              _.indexOf(
                                                tempSelectedClientIndustries,
                                                consolidatedUnitName,
                                              ) !== -1 || isConsolidationForced
                                            }
                                            disabled={isConsolidationForced}
                                            className="form-check-input"
                                          />
                                          <span className="checkmark" />
                                          <span className="form-check-label">
                                            {consolidatedUnitName}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                    {_.map(consolidatedSubUnits, (eachSubUnit) => (
                                      <div className="row" key={eachSubUnit}>
                                        <div className="col-1">&nbsp;</div>
                                        <div className="col">
                                          <span
                                            className={classNames('form-check-label', {
                                              disabled: isConsolidationForced,
                                            })}
                                          >
                                            {eachSubUnit}
                                          </span>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Popover.Content>
                      <div className="popover-footer">
                        <div className="modal-btn temp">
                          <Button
                            variant="default"
                            className="btn-sm mr-auto"
                            onClick={() => this.handleClickClearSelectedClientIndustries()}
                            disabled={isConsolidationForced}
                          >
                            Clear
                          </Button>
                          <Button
                            variant="primary"
                            className="btn-sm"
                            onClick={() => this.handleClickUpdateSelectedClientIndustries()}
                            disabled={isConsolidationForced}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    </Popover>
                  </Overlay>

                  <span
                    role="button"
                    tabIndex={0}
                    ref={this.clientIndustriesPopoverTarget}
                    onClick={() => {
                      // IF CURRENTLY `selectedThreshold` IS NOT 'IndustryWise', THEN SET IT AS SELECTED, ELSE TOGGLE DISPLAY THE INDUSTRY SELECTION POPOVER
                      if (selectedThreshold !== 'IndustryWise') {
                        // RESET SELECTION OF CHECKBOXES ON CHANGE OF THRESHOLD
                        this.setState(
                          {
                            tempSelectedClientIndustries: [],
                            selectedClientIndustries: [],
                            tempIsSelectedAllClientIndustries: false,
                            isSelectedAllClientIndustries: false,
                            tempSelectedClientMarketUnits: [],
                            selectedClientMarketUnits: [],
                            tempIsSelectedAllClientMarketUnits: false,
                            isSelectedAllClientMarketUnits: false,
                            isConsolidationForced: false,
                          },
                          () => {
                            this.handleChangeThresholdSelectionInInsightsView('IndustryWise');
                          },
                        );
                      } else {
                        this.toggleClientIndustriesPopover();
                      }
                    }}
                    className={classNames({
                      active: selectedThreshold === 'IndustryWise',
                    })}
                  >
                    Industry
                    {/* display count of selected industries checkboxes */}
                    {selectedClientIndustries.length > 0 && ` (${selectedClientIndustries.length})`}
                  </span>
                  {/* END SECTION - CLIENT INDUSTRIES CHECKBOXES SELECTION POPOVER */}

                  {/* START SECTION - CLIENT MARKET UNITS CHECKBOXES SELECTION POPOVER */}
                  <Overlay
                    target={this.clientMarketUnitsPopoverTarget.current}
                    show={isVisibleClientMarketUnitsPopover}
                    placement="bottom"
                    onEnter={() => this.onShowClientMarketUnitsPopover()}
                    rootClose
                    rootCloseEvent="click"
                    onHide={() => this.hideClientMarketUnitsPopover()}
                  >
                    <Popover id="popover-basic2">
                      <Popover.Content>
                        <div className="popContent">
                          {/* "All Market Units" CHECKBOX - WOULD BE DISPLAYED ONLY WHEN `unitName` IS NOT CUSTOM SET */}
                          {!isThresholdUnitNameCustomSet && (
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-check">
                                    <input
                                      type="checkbox"
                                      name="allClientMarketUnits"
                                      value=""
                                      onChange={(e) => this.handleChangeAllClientMarketUnitsCheckbox(e)}
                                      checked={
                                        tempIsSelectedAllClientMarketUnits || isConsolidationForced
                                      }
                                      disabled={isConsolidationForced}
                                      className="form-check-input"
                                    />
                                    <span className="checkmark" />
                                    <span className="form-check-label">
                                      All
                                      {' '}
                                      {this.getReplacedLabelForMU('plural')}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="row">
                            {normalUnitNames.length > 0 && (
                              <div
                                className={classNames(
                                  { col: _.isEmpty(consolidatedUnitName) },
                                  { col: !_.isEmpty(consolidatedUnitName) },
                                )}
                              >
                                <div className="row">
                                  {_.map(normalUnitNames, (eachItem) => {
                                    const marketUnit = eachItem;
                                    return (
                                      <div className="col-lg-3" key={marketUnit}>
                                        <div className="form-group">
                                          <label className="form-check">
                                            <input
                                              type="checkbox"
                                              name="clientIndustries"
                                              value={marketUnit}
                                              onChange={(e) => this.handleChangeClientMarketUnitCheckbox(e)}
                                              checked={
                                                _.indexOf(
                                                  tempSelectedClientMarketUnits,
                                                  marketUnit,
                                                ) !== -1 || isConsolidationForced
                                              }
                                              disabled={isConsolidationForced}
                                              className="form-check-input"
                                            />
                                            <span className="checkmark" />
                                            <span className="form-check-label">{marketUnit}</span>
                                          </label>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}

                            {!_.isEmpty(consolidatedUnitName) && (
                              <div className="col-3">
                                <div className="row">
                                  <div className="col">
                                    <div className="row">
                                      <div className="col">
                                        <label className="form-check">
                                          <input
                                            type="checkbox"
                                            name="clientMarketUnits"
                                            value={consolidatedUnitName}
                                            onChange={(e) => this.handleChangeClientMarketUnitCheckbox(e)}
                                            checked={
                                              _.indexOf(
                                                tempSelectedClientMarketUnits,
                                                consolidatedUnitName,
                                              ) !== -1 || isConsolidationForced
                                            }
                                            disabled={isConsolidationForced}
                                            className="form-check-input"
                                          />
                                          <span className="checkmark" />
                                          <span className="form-check-label">
                                            {`Other ${this.getReplacedLabelForMU(
                                              'plural',
                                            )}`}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                    {_.map(consolidatedSubUnits, (eachSubUnit) => (
                                      <div className="row" key={eachSubUnit}>
                                        <div className="col-1">&nbsp;</div>
                                        <div className="col">
                                          <span
                                            className={classNames('form-check-label', {
                                              disabled: isConsolidationForced,
                                            })}
                                          >
                                            {eachSubUnit}
                                          </span>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Popover.Content>
                      <div className="popover-footer">
                        <div className="modal-btn temp">
                          <Button
                            variant="default"
                            className="btn-sm mr-auto"
                            onClick={() => this.handleClickClearSelectedClientMarketUnits()}
                            disabled={isConsolidationForced}
                          >
                            Clear
                          </Button>
                          <Button
                            variant="primary"
                            className="btn-sm"
                            onClick={() => this.handleClickUpdateSelectedClientMarketUnits()}
                            disabled={isConsolidationForced}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    </Popover>
                  </Overlay>

                  <span
                    role="button"
                    tabIndex={0}
                    ref={this.clientMarketUnitsPopoverTarget}
                    onClick={() => {
                      // IF CURRENTLY `selectedThreshold` IS NOT 'MarketUnit', THEN SET IT AS SELECTED, ELSE TOGGLE DISPLAY THE MARKET UNITS SELECTION POPOVER
                      if (selectedThreshold !== 'MarketUnit') {
                        // RESET SELECTION OF CHECKBOXES ON CHANGE OF THRESHOLD
                        this.setState(
                          {
                            tempSelectedClientIndustries: [],
                            selectedClientIndustries: [],
                            tempIsSelectedAllClientIndustries: false,
                            isSelectedAllClientIndustries: false,
                            tempSelectedClientMarketUnits: [],
                            selectedClientMarketUnits: [],
                            tempIsSelectedAllClientMarketUnits: false,
                            isSelectedAllClientMarketUnits: false,
                            isConsolidationForced: false,
                          },
                          () => {
                            this.handleChangeThresholdSelectionInInsightsView('MarketUnit');
                          },
                        );
                      } else {
                        this.toggleClientMarketUnitsPopover();
                      }
                    }}
                    className={classNames({
                      active: selectedThreshold === 'MarketUnit',
                    })}
                  >
                    {this.getReplacedLabelForMU()}
                    {/* display count of selected industries checkboxes */}
                    {selectedClientMarketUnits.length > 0
                      && ` (${selectedClientMarketUnits.length})`}
                  </span>
                  {/* END SECTION - CLIENT INDUSTRIES MARKET UNITS SELECTION POPOVER */}
                </div>
              )}

              <div className={classNames('chooseOption')}>
                {/* START - DATEPICKER POPOVER */}
                <Overlay
                  target={this.datepickerDropdownPopoverTarget.current}
                  show={isVisibleDatepickerDropdownPopover}
                  placement="bottom"
                  rootClose
                  rootCloseEvent="click"
                  onHide={this.hideDatepickerDropdownPopover}
                >
                  <Popover id="popover-basic4" className="calenderPopover CalenderWithError">
                    <Popover.Content>
                      <div className="popContent">
                        <div className="popContentDisplay">
                          <div
                            className={
                              isStartEndDateSelected
                                ? 'calenderPopoverSpanActive'
                                : 'calenderPopoverSpan'
                            }
                            target={this.startDatepickerRef}
                            onClick={this.selectStartDate}
                            role="button"
                            tabIndex={0}
                          >
                            <div className="CalenderDatepickerLabel">Start Date</div>
                            <div className="CalenderDatepickerDate">{formattedTempStartDate}</div>
                          </div>
                          <div
                            className={
                              isStartEndDateSelected
                                ? 'calenderPopoverSpan'
                                : 'calenderPopoverSpanActive'
                            }
                            target={this.endDatepickerRef}
                            onClick={this.selectEndDate}
                            role="button"
                            tabIndex={0}
                          >
                            <div className="CalenderDatepickerLabel">End Date</div>
                            <div className="CalenderDatepickerDate">{formattedTempEndDate}</div>
                          </div>
                        </div>

                        {showRangeErrorInDatePicker && (
                          <div className="peerCalenderError">
                            <i className="fa fa-exclamation-circle" aria-hidden="true" />
                            Peer comparison cannot be viewed for a date range of less than 6 months
                          </div>
                        )}

                        {isStartEndDateSelected ? (
                          <div>
                            <DatePicker
                              selected={tempStartDate || startDate}
                              onChange={(date) => this.handleChangeDateField(date, isStartEndDateSelected)}
                              dateFormat="MMM yyyy"
                              showMonthYearPicker
                              strictParsing
                              inline
                              className="calenderDatepicker"
                              minDate={BATAllowedMinDateMoment.toDate()}
                              maxDate={
                                !_.isNil(tempEndDate || endDate)
                                  ? moment(tempEndDate || endDate).toDate()
                                  : moment(new Date()).toDate()
                              }
                              placeholderText="Start Month"
                              disabled={selectedInsightsViewBenchmark === 'external'}
                              ref={this.startDatepickerRef}
                            />
                          </div>
                        ) : (
                          <DatePicker
                            selected={tempEndDate || endDate}
                            onChange={(date) => this.handleChangeDateField(date, isStartEndDateSelected)}
                            dateFormat="MMM yyyy"
                            showMonthYearPicker
                            strictParsing
                            inline
                            className="calenderDatepicker"
                            minDate={
                              !_.isNil(tempStartDate || startDate)
                                ? moment(tempStartDate || startDate).toDate()
                                : null
                            }
                            maxDate={new Date()}
                            placeholderText="End Month"
                            disabled={selectedInsightsViewBenchmark === 'external'}
                            ref={this.endDatepickerRef}
                          />
                        )}
                      </div>
                    </Popover.Content>
                    <div className="popover-footer">
                      <div className="modal-btn temp">
                        <Button
                          variant="default"
                          className="btn-sm mr-auto"
                          onClick={() => {
                            this.handleClearDate();
                          }}
                        >
                          Clear
                        </Button>
                        <Button
                          variant="primary"
                          className="btn-sm"
                          onClick={() => this.handleUpdateDateClickField(tempStartDate, tempEndDate)}
                          disabled={showRangeErrorInDatePicker}
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  </Popover>
                </Overlay>
                <span
                  className={classNames(
                    {
                      active: isVisibleDatepickerDropdownPopover,
                    },
                    { disabled: parentScreen === 'diverseData' },
                  )}
                  ref={this.datepickerDropdownPopoverTarget}
                  onClick={parentScreen !== 'diverseData' && this.toggleDatepickerDropdownPopover}
                  role="button"
                  tabIndex={0}
                >
                  {formattedStartDate === formattedEndDate
                    ? formattedStartDate
                    : `${formattedStartDate} - ${formattedEndDate}`}
                  <i className="fal fa-calendar-alt" style={{ marginLeft: '10px' }} />
                </span>
                {/* END - DATEPICKER POPOVER */}

                {/* START - DROPDOWN FOR CHANGING THE SELECTION OF TRENDS VIEW */}
                {currentView !== 'metricBenchmarkDetailsView'
                  && (
                    <div className="view-dropdown">
                      <Dropdown
                        onSelect={(selectedOption) => this.handleChangeCurrentView(selectedOption)}
                      >
                        <Dropdown.Toggle id="view-dropdown">
                          {currentView === 'trendBenchmarkComparisonView' && 'Benchmark Comparison'}
                          {currentView === 'trendDCView' && 'DC View'}
                          {currentView === 'trendPeerComparisonView' && 'Peer to Peer Comparison'}
                          <div className="down-arrow-icon" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            eventKey="trendBenchmarkComparisonView"
                            active={currentView === 'trendBenchmarkComparisonView'}
                          >
                            Benchmark Comparison
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="trendDCView"
                            active={currentView === 'trendDCView'}
                          >
                            DC View
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="trendPeerComparisonView"
                            active={currentView === 'trendPeerComparisonView'}
                          >
                            Peer to Peer Comparison
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                {/* END - DROPDOWN FOR CHANGING THE SELECTION OF TRENDS VIEW */}
              </div>

              {!isEmptyMetricBenchmarkDetails && !isEmptyThresholdData && (
                <div
                  className={classNames('d-flex mt-1 ml-3 ml-auto', {
                    peerAlignCenter: currentView === 'trendPeerComparisonView',
                  })}
                >
                  {/* START - "BENCHMARKS" SELECTION DROPDOWN FOR INSIGHTS CHART (BAR CHART) */}
                  {currentView === 'metricBenchmarkDetailsView' && (
                    <div className="benchmark-dropdown">
                      <Dropdown
                        onSelect={(selectedOption) => this.handleChangeInsightsViewBenchmarkDropdown(selectedOption)}
                      >
                        <Dropdown.Toggle id="benchmark-dropdown">
                          {`${_.upperFirst(selectedInsightsViewBenchmark)} Benchmark`}
                          {' '}
                          -
                          {' '}
                          {selectedThreshold === 'IndustryWise'
                            ? 'Industry'
                            : this.getReplacedLabelForMU()}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            eventKey="internal"
                            active={selectedInsightsViewBenchmark === 'internal'}
                            disabled = {isDisabledInternalBenchmarks}
                          >
                            Internal Benchmark
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="external"
                            active={selectedInsightsViewBenchmark === 'external'}
                            disabled={isDisabledExternalBenchmarksRadio}
                          >
                            External Benchmark
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                  {/* END - "BENCHMARKS" SELECTION DROPDOWN FOR INSIGHTS CHART (BAR CHART) */}

                  {/* "Display Client Names" TOGGLE BUTTON VISIBLE IN PEER TO PEER COMPARISON VIEW */}
                  {hasUserPermShowClientNames
                    && !isEmptyMetricBenchmarkDetails
                    && !isEmptyThresholdData
                    && parentScreen !== 'diverseData'
                    && currentView === 'trendPeerComparisonView'
                    && !_.isEmpty(clientMetricData)
                    && (
                      <>
                        <div className="peerToggleGroup">
                          <div className="withLabel multipleToggle">
                            {renderDisplayClientNamesSwitchToggle()}
                          </div>
                        </div>
                        <div className="vline" />
                      </>
                    )}

                  {/* START - TRENDS VIEW - BENCHMARK SELECTION RADIO OPTIONS POPOVER */}
                  {(currentView === 'trendBenchmarkComparisonView'
                    || currentView === 'trendDCView'
                    || currentView === 'trendPeerComparisonView')
                    && !_.isEmpty(clientMetricData) && (
                      <div className="chooseOption ">
                        {/* START - TREND DC VIEW - DELIVERY CENTER SELECTION DROPDOWN */}
                        <Overlay
                          target={this.selectDCPopoverTarget.current}
                          show={isVisibleDeliveryCentersPopover}
                          placement="bottom"
                          rootClose
                          rootCloseEvent="click"
                          onEnter={() => this.onShowDeliveryCentersPopover()}
                          onHide={() => this.hideDeliveryCentersPopover()}
                        >
                          <Popover className="SelectDCDropdown">
                            <Popover.Content>
                              <div className="popContent">
                                <div className="row">
                                  {_.map(deliveryCenters, (eachItem) => {
                                    const deliveryCenterName = eachItem;
                                    return (
                                      <div className="col-lg-6" key={deliveryCenterName}>
                                        <div className="check-group">
                                          <label className="form-check">
                                            <input
                                              type="checkbox"
                                              name="deliveryCenters"
                                              value={deliveryCenterName}
                                              onChange={(e) => this.handleChangeDeliveryCenterCheckbox(e)}
                                              checked={
                                                _.indexOf(
                                                  tempSelectedDeliveryCenters,
                                                  deliveryCenterName,
                                                ) !== -1
                                              }
                                              className="form-check-input"
                                            />
                                            <span className="checkmark" />
                                            <span>{deliveryCenterName}</span>
                                          </label>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </Popover.Content>

                            <div className="popover-footer">
                              <div className="modal-btn temp">
                                <Button
                                  variant="default"
                                  className="btn-sm mr-auto clear-btn"
                                  onClick={() => this.handleClickClearSelectedDeliveryCenters()}
                                >
                                  Clear
                                </Button>
                                <Button
                                  variant="primary"
                                  className="btn-sm update-btn"
                                  onClick={() => this.handleClickUpdateSelectedDeliveryCenters()}
                                >
                                  Update
                                </Button>
                              </div>
                            </div>
                          </Popover>
                        </Overlay>

                        {currentView === 'trendDCView' && (
                          <span
                            ref={this.selectDCPopoverTarget}
                            className="selectDC-span"
                            onClick={this.toggleDeliveryCentersPopover}
                            role="button"
                            tabIndex={0}
                          >
                            Select DC
                            {/* display count of selected delivery centers checkboxes */}
                            {' '}
                            {selectedDeliveryCenters.length > 0
                              && ` (${selectedDeliveryCenters.length})`}
                            {' '}
                            <div className="down-arrow-icon" />
                          </span>
                        )}
                        {/* END - TREND DC VIEW - DELIVERY CENTER SELECTION DROPDOWN */}

                        {/* START - TREND (LINE) CHART VIEW - BENCHMARK SELECTION POPOVER */}
                        <Overlay
                          target={this.benchmarkDropdownPopoverTarget.current}
                          show={isVisibleTrendViewBenchmarkPopover}
                          placement="bottom-end"
                          rootClose
                          rootCloseEvent="click"
                          onEnter={() => this.onShowTrendViewBenchmarkPopover()}
                          onHide={() => this.hideTrendViewBenchmarkPopover()}
                        >
                          <Popover className="benchmarkCategoryTooltipDropdown">
                            <Popover.Content>
                              <div className="popContent">
                                <div className="row">
                                  <div className="col-lg-5 benchmark-column">
                                    <div
                                      className={classNames(
                                        'benchmark-category-label',
                                        {
                                          active:
                                            _.toLower(tempSelectedTrendViewBenchmark) === 'internal',
                                        },
                                        {
                                          disabled: isDisabledInternalBenchmarkOptionForTrendView || isDisabledInternalBenchmarks,
                                        },
                                      )}
                                      onClick={() => this.handleClickBenchmarkForTrendsView('internal')}
                                      role="button"
                                      tabIndex={0}
                                    >
                                      <label>Internal Benchmark</label>
                                    </div>

                                    <div
                                      className={classNames(
                                        'benchmark-category-label',
                                        {
                                          active:
                                            _.toLower(tempSelectedTrendViewBenchmark) === 'external',
                                        },
                                        {
                                          disabled: isDisabledExternalBenchmarkOptionForTrendView,
                                        },
                                      )}
                                      onClick={() => this.handleClickBenchmarkForTrendsView('external')}
                                      role="button"
                                      tabIndex={0}
                                    >
                                      <label>External Benchmark</label>
                                    </div>
                                  </div>

                                  <div className="col-lg-7 benchmark-column">
                                    <div className="benchmark-category-name">
                                      {tempSelectedBenchmarkDropdownBenchmarkBy === 'Market'
                                        && nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection
                                        === tempSelectedTrendViewBenchmark && (
                                          <FontAwesomeIcon
                                            icon={faChevronLeft}
                                            onClick={() => this.handleClickHideMarketUnitBenchmarkDropdownRadioSection(
                                              tempSelectedTrendViewBenchmark,
                                            )}
                                          />
                                        )}
                                      {_.upperFirst(tempSelectedTrendViewBenchmark)}
                                      {' '}
                                      Benchmark
                                      {tempSelectedBenchmarkDropdownBenchmarkBy === 'Market'
                                        && ' - Market'}
                                    </div>

                                    {/* DISPLAY TREND VIEW BENCHMARK RADIO OPTIONS BASED ON SELECTED TYPE "INTERNAL/EXTERNAL" */}
                                    {nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection
                                      === ''
                                      && _.map(trendViewBenchmarkOptionsForDisplay, (eachOption) => (
                                        <div className="form-group" key={eachOption.value}>
                                          <label
                                            className={classNames('form-check', {
                                              disabled: eachOption.isDisabled,
                                            })}
                                          >
                                            <input
                                              type="radio"
                                              name="trendViewBenchmarkOption"
                                              value={eachOption.value}
                                              checked={
                                                tempSelectedTrendViewBenchmarkOption.value
                                                === eachOption.value
                                              }
                                              onClick={() => {
                                                if (!eachOption.isDisabled) {
                                                  this.handleClickTrendViewBenchmarkDropdownRadio(
                                                    eachOption,
                                                  );
                                                }
                                              }}
                                              disabled={eachOption.isDisabled}
                                              className="form-check-input"
                                            />
                                            <span className="checkmark" />
                                            <span className="form-check-label">
                                              {eachOption.label}
                                            </span>
                                          </label>
                                        </div>
                                      ))}

                                    {/* LIST OF MARKET UNITS RADIOS - WOULD BE VISIBLE WHEN THE `BenchmarkBy === "Market"` */}
                                    {tempSelectedBenchmarkDropdownBenchmarkBy === 'Market'
                                      && nameOfTempVisibleTrendViewBenchmarkMarketUnitRadioSection
                                      === tempSelectedTrendViewBenchmark
                                      && _.map(
                                        marketUnitsRadioOptionsForTrendsViewBenchmarkSelectionForDisplay,
                                        (eachOption) => (
                                          <div className="form-group" key={eachOption.value}>
                                            <label
                                              className={classNames('form-check', {
                                                disabled: eachOption.isDisabled,
                                              })}
                                            >
                                              <input
                                                type="radio"
                                                name="marketUnitsRadioOption"
                                                value={eachOption.value}
                                                checked={
                                                  tempSelectedTrendViewBenchmarkMarketUnitOption.value
                                                  === eachOption.value
                                                }
                                                onChange={() => this.handleChangeMarketUnitBenchmarkDropdownRadio(
                                                  eachOption,
                                                )}
                                                disabled={eachOption.isDisabled}
                                                className="form-check-input"
                                              />
                                              <span className="checkmark" />
                                              <span className="form-check-label">
                                                {eachOption.label}
                                              </span>
                                            </label>
                                          </div>
                                        ),
                                      )}
                                  </div>
                                </div>
                              </div>
                            </Popover.Content>

                            <div className="popover-footer">
                              <div className="modal-btn temp">
                                <Button
                                  variant="default"
                                  className="btn-sm mr-auto clear-btn"
                                  onClick={() => this.handleResetTrendViewBenchmarkSelection()}
                                >
                                  Clear
                                </Button>
                                <Button
                                  variant="primary"
                                  className="btn-sm update-btn"
                                  onClick={() => this.handleUpdateTrendViewBenchmarkSelection()}
                                >
                                  Update
                                </Button>
                              </div>
                            </div>
                          </Popover>
                        </Overlay>

                        <span
                          ref={this.benchmarkDropdownPopoverTarget}
                          onClick={this.toggleTrendViewBenchmarkPopover}
                          role="button"
                          tabIndex={0}
                        >
                          {`${_.upperFirst(
                            selectedTrendViewBenchmark,
                          )} Benchmark - ${currentlySelectedBenchmarkDropdownBenchmarkBy}`}
                          {!_.isEmpty(selectedTrendViewBenchmarkMarketUnitOption)
                            && ` (${selectedTrendViewBenchmarkMarketUnitOption.label})`}
                        </span>
                        {/* END - TREND (LINE) CHART VIEW - BENCHMARK SELECTION POPOVER */}
                      </div>
                    )}
                  {/* END - TRENDS VIEW - BENCHMARK SELECTION RADIO OPTIONS POPOVER */}
                </div>
              )}
            </div>

            {currentView === 'metricBenchmarkDetailsView' && (
              <div className="multipleToggleGroup">
                {/* START - DISPLAY BENCHMARKS (QUARTILES) SWITCH TOGGLE FOR CLIENT SIMULATION CHART */}
                {!isEmptyMetricBenchmarkDetails
                  && !isEmptyThresholdData
                  && currentView === 'metricBenchmarkDetailsView'
                  && (!_.isEmpty(clientSimulationData) || isClientExisting) && (
                    <div className="withLabel multipleToggle">
                      <div className="toggle-group">
                        <input
                          type="checkbox"
                          value="yes"
                          id="displayQuartiles"
                          onChange={(e) => this.handleChangeDisplayQuartiles(e)}
                          checked={displayQuartiles}
                        />
                        <div className="onoffswitch" aria-hidden="true">
                          <div className="onoffswitch-label">
                            <div className="onoffswitch-inner" />
                            <div className="onoffswitch-switch" />
                          </div>
                        </div>
                        <label htmlFor="displayQuartiles">Display Quartiles</label>
                      </div>
                    </div>
                  )}
                {/* END - DISPLAY BENCHMARKS (QUARTILES) SWITCH TOGGLE FOR CLIENT SIMULATION CHART */}

                {/* "DISPLAY CLIENT NAMES" SWITCH TOGGLE ELEMENT - THIS WOULD ONLY BE VISIBLE TO THE USER WHICH HAS THE REQUIRED PERMISSION. ALSO THE TOGGLE IS NOT TO BE SHOWN WHEN THE MODAL IS OPENED UP FROM "diverseData" SCREEN */}
                {hasUserPermShowClientNames
                  && !isEmptyMetricBenchmarkDetails
                  && !isEmptyThresholdData
                  && parentScreen !== 'diverseData'
                  && currentView === 'metricBenchmarkDetailsView' && (
                    <>
                      <div className="withLabel multipleToggle">
                        {renderDisplayClientNamesSwitchToggle()}
                      </div>
                    </>
                  )}
              </div>
            )}

            {/* LEGENDS (CUSTOM RENDERED) FOR BOTH FOR THE HORIZONTAL BAR CHART IN AGNOSTIC VIEW BAR CHART IN CLIENT SIMULATION VIEW */}
            {currentView === 'metricBenchmarkDetailsView'
              && !isEmptyMetricBenchmarkDetails
              && !isEmptyThresholdData && (
                <div className="industry-legend">
                  {/* CROSS-INDUSTRY LEGEND METRIC BENCHMARK CHART - HORIZONTAL BAR CHART */}
                  {!_.isEmpty(barChartCrossIndustryLegendData) && (
                    <ul>
                      <li>
                        <b>
                          {selectedThreshold === 'IndustryWise'
                            ? 'Cross Industry'
                            : `Cross ${this.getReplacedLabelForMU()}`}
                          :
                        </b>
                      </li>

                      {!_.isEmpty(_.get(barChartCrossIndustryLegendData, '0.label', '')) && (
                        <li className="dashsize">
                          <span
                            className="dashspan"
                            style={{
                              color: _.get(barChartCrossIndustryLegendData, '0.color'),
                            }}
                          >
                            {!_.isEmpty(clientSimulationData) || isClientExisting ? (
                              '- -'
                            ) : (
                              <span className="legend-dash" />
                            )}
                          </span>
                          <span>{_.get(barChartCrossIndustryLegendData, '0.label')}</span>
                        </li>
                      )}

                      {!_.isEmpty(_.get(barChartCrossIndustryLegendData, '1.label', '')) && (
                        <li className="dashsize">
                          <span
                            className="dashspan"
                            style={{
                              color: _.get(barChartCrossIndustryLegendData, '1.color'),
                            }}
                          >
                            {!_.isEmpty(clientSimulationData) || isClientExisting ? (
                              '- -'
                            ) : (
                              <span className="legend-dash" />
                            )}
                          </span>
                          <span>{_.get(barChartCrossIndustryLegendData, '1.label')}</span>
                        </li>
                      )}

                      {!_.isEmpty(_.get(barChartCrossIndustryLegendData, '2.label', '')) && (
                        <li className="dashsize">
                          <span
                            className="dashspan"
                            style={{
                              color: _.get(barChartCrossIndustryLegendData, '2.color'),
                            }}
                          >
                            {!_.isEmpty(clientSimulationData) || isClientExisting ? (
                              '- -'
                            ) : (
                              <span className="legend-dash" />
                            )}
                          </span>
                          <span>{_.get(barChartCrossIndustryLegendData, '2.label')}</span>
                        </li>
                      )}
                    </ul>
                  )}

                  {/* INDUSTRY LEGEND FOR METRIC BENCHMARK CHART - HORIZONTAL BAR CHART */}
                  {!_.isEmpty(barChartDefaultLegendContent) && !_.isEmpty(thresholdData) && (
                    <div
                      className={classNames({
                        vline: !_.isEmpty(barChartCrossIndustryLegendData),
                      })}
                    >
                      {parse(barChartDefaultLegendContent)}
                    </div>
                  )}
                </div>
              )}

            {/* METRIC BENCHMARK CHART - HORIZONTAL BAR CHART &  BAR CHART FOR CLIENT SIMULATION */}
            <div
              className="position-relative mt-4 mb-5"
              style={{
                height: chartHeight,
                display:
                  currentView === 'metricBenchmarkDetailsView'
                    && !isEmptyMetricBenchmarkDetails
                    && !isEmptyThresholdData
                    ? 'block'
                    : 'none',
              }}
            >
              <canvas id="horizontalBarChartCanvas" ref={this.metricBenchmarkChartRef} />
            </div>

            {!_.isEmpty(clientMetricData)
              && (currentView === 'trendBenchmarkComparisonView' || currentView === 'trendDCView') && (
                <div className="position-relative">
                  {/* CUSTOM HTML LEGENDS FOR BENCHMARKS */}
                  {!_.isEmpty(legendHTMLContentForBenchmarkOrDCTrendChart) && (
                    <div
                      className="industry-legend-container"
                    >{parse(legendHTMLContentForBenchmarkOrDCTrendChart)}</div>
                  )}

                  {/* BENCHMARK COMPARISON TREND (LINE) CHART */}
                  {currentView === 'trendBenchmarkComparisonView' && (
                    <div
                      style={{ position: 'relative', height: chartHeight }}
                      className="mt-4 mb-5"
                    >
                      <canvas
                        id="benchmarkComparisonTrendChart"
                        ref={this.benchmarkComparisonTrendChartRef}
                      />
                    </div>
                  )}

                  {/* TRENDS - DC WISE COMPARISON LINE CHART */}
                  {currentView === 'trendDCView' && (
                    <div
                      id="dcWiseComparisonTrendChartContainer"
                      style={{ position: 'relative', height: chartHeight }}
                      className="mt-4 mb-5"
                    >
                      <canvas
                        id="dcWiseComparisonTrendChart"
                        ref={this.dcWiseComparisonTrendChartRef}
                      />
                    </div>
                  )}
                </div>
              )}

            {/* PEER COMPARISON CHARTS */}
            {currentView === 'trendPeerComparisonView' && !_.isEmpty(clientMetricData) && (
              <div className="position-relative">
                <PeerToPeerComparisonGraph
                  processedAPIData={clientMetricData}
                  offeringName={offeringName}
                  offeringType={offeringType}
                  subOfferingName={subOfferingName}
                  metricData={metricData}
                  parentScreen={parentScreen}
                  isClientExisting={isClientExisting}
                  existingBATClientName={existingBATClientName}
                  existingSynOpsClientName={existingSynOpsClientName}
                  currentlySelectedClientDetails={currentlySelectedClientDetails}
                  displayClientNames={displayClientNames}
                  selectedTrendViewBenchmarkOption={selectedTrendViewBenchmarkOption}
                  selectedTrendViewBenchmarkMarketUnitOption={
                    selectedTrendViewBenchmarkMarketUnitOption
                  }
                  startDateMoment={startDate}
                  endDateMoment={endDate}
                  formattedStartDate={formattedStartDate}
                  formattedEndDate={formattedEndDate}
                />
              </div>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

MetricInsightDetails.defaultProps = {
  clientSimulationData: {},
  parentScreen: '',
  existingBATClientName: '',
  existingSynOpsClientName: '',
  isExistingClient: false,
};

MetricInsightDetails.propTypes = {
  offeringName: PropTypes.string.isRequired,
  offeringType: PropTypes.string.isRequired,
  subOfferingName: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired,
  metricData: PropTypes.object.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  userPermissions: PropTypes.array.isRequired,
  existingBATClientName: PropTypes.string,
  parentScreen: PropTypes.string,
  clientSimulationData: PropTypes.object,
  existingSynOpsClientName: PropTypes.string,
  isExistingClient: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  userPermissions: state.roleReducers.roleReducers,
});

export default connect(mapStateToProps)(MetricInsightDetails);
