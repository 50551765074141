import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import parse from 'react-html-parser';

class MetricInfoModal extends Component {
  handleModalHide = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { title, description } = this.props;

    return (
      <Modal
        show
        centered
        onHide={() => this.handleModalHide()}
        backdrop="static"
        dialogClassName="align-dialog-up"
      >
        <Modal.Header closeButton className="justify-content-center">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        {description && typeof description === 'string' ? 
          <p>
            {parse(description)}
          </p> : null}
        </Modal.Body>
      </Modal>
    );
  }
}

MetricInfoModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MetricInfoModal;
