import React, { Component } from 'react';
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import DatePicker from 'react-datepicker';
import _ from "lodash";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { EditorState, convertFromHTML, convertFromRaw, ContentState, convertToRaw, createFromBlockArray  } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import LoadingOverlay from "react-loading-overlay";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import { arrayMoveImmutable } from 'array-move';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { LocalApiBaseUrl, trycatchAlertPopup } from "../Shared/Constant";
import axiosInstance from '../Shared/interceptor';
import './clientstory.css';
import BeforeAfterUI from './BeforeAfterUI';
import BeforeAfterDetail from './BeforeAfterDetail';
import SynopsLoader from '../Shared/SynopsLoader';
import * as BeforeAfterActions from '../actions/BeforAfterMindMapActions';
import ClientStoryMindMap from './ClientStoryMindMap';
import ClientStoryReferences from './ClientStoryReferences';
import ReactTooltip from "react-tooltip";
import i18n from 'i18next';
import OverviewAndKeyMileStones from './OverivewAndKeyMilestones';

import KeyOutcomesForm from "./KeyOutcomesForm";
import keyoutcomesDownArrow from "../Images/ClientStoryIcons/arrow-circle-down color.svg";
import keyoutcomesUpGrey from "../Images/ClientStoryIcons/arrow-circle-up-grey.svg";
import { v4 as uuidv4 } from "uuid";
import { store } from '../reducers/configureStore';
import {TRACKING_MODULES, PARAM_NAMES, userTracker, createParamObject} from "../Shared/Common";

const characterLimit = 1001;

const DragHandle = sortableHandle(() => (
  <span className="dragg-icon">
    <i className="fas fa-ellipsis-v" />
    <i className="fas fa-ellipsis-v" />
  </span>
));

const SortableItem = sortableElement(({ value, sortIndex, length, handleAdd, handleDelete, handleDescChange, handleDate, startDate, handleSubmit, errors, editorState, onEditorStateChange,handleBeforeInput }) => (
  <div className="dragg-wrapper">
    <DragHandle />
    <div className="row dragg-list">
      <div className="col-lg-4 dragg-list-left">
      <div className="form-section common-formgrouplabel">
          <div className="form-group border-group disabled">
            <label htmlFor="name" className="form-label">
              Milestone
              <span className="required">&nbsp;*</span>
            </label>
            <input type="text" readOnly id="name" name="name" className="form-control" value={`${value.name} ` + `${++sortIndex}`} />
          </div>
        </div>
        <div className="form-section common-formgrouplabel">
          <div className="form-group border-group">
            <label htmlFor="year" className="form-label">
              Year
              <span className="required">&nbsp;*</span>
            </label>
            <div className="initiative-datepicker form-control">
              <div className="datepicker-label-wrapper">
                <label>
                <DatePicker
                  className="form-control IOJDatepicker"
                  selected={value.year}
                  onChange={(date) => handleDate(date)}
                  showMonthYearPicker
                  dateFormat="MMM yyyy"
                  yearItemNumber={9}
                  placeholder="(MMM/YYYY)"
                  required
                />
                <span className="calender-placment">
                  <i className="fal fa-calendar-alt" />
                </span>
                </label>
              </div>
            </div>
          </div>
          <span className="errorMsg">{errors[sortIndex - 1].year}</span>
        </div>
      </div>
      <div className="col-lg-8 dragg-list-right">
      <div className="form-section">
          <div className="form-group border-group">
            <label htmlFor="description" className="form-label">
              Milestone Description
              <span className="required">&nbsp;*</span>
            </label>
            <div className="description-editor">
            {/* <textarea id="description" name="description" rows="4" className="form-control" placeholder="Milestone Description" maxLength="200" value={value.desc} onChange={handleDescChange} required /> */}
            <Editor
              readOnly={false}
              editorState={editorState}
              stripPastedStyles={true}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              handleBeforeInput = {(e) => handleBeforeInput(editorState)}
              onEditorStateChange={(e) => { onEditorStateChange(e,sortIndex)}}
              onTab={() => true}
              toolbar={{
                options: ["list"],
                list: { inDropdown: false, options: ["unordered"] },
              }}
            />
            </div>
            <span className="suffix-text">
              {value.text.length}
              /200 Characters
            </span>
          </div>
          <span className="errorMsg">{errors[sortIndex - 1].desc}</span>
        </div>
      </div>
    </div>
    <span className={((length) === sortIndex) ? "add-delete-actions" : "add-delete-actions single-action"}>
      {' '}
      {/* add className="add-delete-actions single-action" when we are showing one action */}
      {(length) === sortIndex && length === 7
        ? <span className="add-icon" id="disabled" disabled data-tip data-for="timelines-tooltip"/> 
        : length === sortIndex
          ? <span className="add-icon" onClick={handleAdd} /> : ''}
          <ReactTooltip className="menulist-tooltips" id="timelines-tooltip" effect="solid" place="bottom">
            <span> 
              {i18n.t('maximum_milestones_alert_message')}
            </span>
          </ReactTooltip>
      <span className="delete-icon" onClick={handleDelete} />
    </span>
  </div>
));

const SortableContainer = sortableContainer(({ children }) => <div className="draggdrop-container">{children}</div>);
class ClientStory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      errors: [],
      country: [],
      storyMapData: [],
      formIsValid: false,
      formIsValidValidStory: false,
      postBeforeAfterApi: false,
      flag: true,
      configureStory: false,
      selectedTab: "StoryMap",
      storyErrors: [],
      isApiLoading: false,
      journeyURL: '',
      storyMapURL: '',
      storyConfigureButton : true,
      journeyConfigureButton : true,
      showStoryDefaultMessage : '',
      showJourneyDefaultMessage: '',
      hasClientSelected: false,
      showReport: true,
      showMilestone: true,
      BeforeAfterMindMap: { hasConfigureAccess: false, showConfigureButton: true, showConfigureMode: false },
      deletedOne : [],
      checkDelete :  false,
      deletedStoryMapData: [],
      isVisibleConfigureBOIMindMapButton: false,
      isVisibleCancelAndSaveBOIMindMapButtons: false,
      isEnabledSaveBOIMindMapButton: false,
      doSaveBOIMindMap: false,
      isLoadedBOIMindMap: false,
      milestonesEditorStates:[{
        "index":0,
        "value":'',
        "editorState":EditorState.createEmpty(),
      }],
      configureKeyOutcomes: false,
      keyOutcomesDelivered: [],
      keyOutcomesDeliveredOriginal: [],
      showKeyOutcomes: false,
      currentPage: 1,
      maxPageNo: 0,
      clientstoryReferencesConfig: false,
      isStorySubmitDisabled: false,
      // tabClicked:{
      //   BeforeAfterMindMap:false,
      //   BOIMindMap:false,
      // }
    };
  }

  componentDidMount() {
    this.getAllDeliveryRegion();
    this.getJourneyTimelinesURL();
    this.getStoryMapURL();
    this.hasConfigureAccess();
    this.getAllKeyOutcomesDelivered();
    $("html, body").animate({ scrollTop: 0 }, "slow");
    
    userTracker(
      TRACKING_MODULES.CLIENT_STORY_OVERVIEW_KEY_MILESTONES,
      false,
      null,
      null,
      createParamObject(
        PARAM_NAMES.OFFERING,
        store.getState().CurrentFilter.SyncFilter.OfferingName
      ),
      createParamObject(
        PARAM_NAMES.SUB_OFFERING,
        store.getState().CurrentFilter.Common.SubOfferingName
      ),
      createParamObject(
        PARAM_NAMES.LOCATION,
        store.getState().CurrentFilter.Location.locationName
      ),
    );
  }

  componentDidUpdate(prevProps) {
    const { roleData, clientData, dealId } = this.props;
    const { storyConfigureButton } = this.state;

    if (prevProps.dealId !== dealId) {
      let configbutton = storyConfigureButton;
      this.getAllDeliveryRegion();
      this.getJourneyTimelinesURL();
      this.getStoryMapURL();
      //this.hasConfigureAccess();
      this.getAllKeyOutcomesDelivered();
      if (this.state.selectedTab === "StoryMap") {
        configbutton = true;
      }
      this.setState({
        configureStory: false,
        flag: true,
        storyConfigureButton: configbutton
      })
    }
    if (clientData != prevProps.clientData) {
      this.getStoryMapURL();
      this.getJourneyTimelinesURL();
      this.getAllKeyOutcomesDelivered();
    }
    if (prevProps.roleData !== roleData) {
      this.hasConfigureAccess();
    }
  }

  formatKeyOutcomes = (data) => {
    const deleted = [...data].filter((a) => a.IsDeleted);
    const notDeleted = data.filter((a) => a.IsDeleted === false);
    return [...notDeleted, ...deleted];
  };
  getKeyOutcomesDeliveredAPICall = () => {
    this.setState({
      isApiLoading: true,
    });
    const { clientData, languageData } = this.props;
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${LocalApiBaseUrl}ClientStory/GetClientOutcomesDeliveredData`, {
          params: {
            OrgEntityId: clientData.id,
            LanguageID: languageData.id,
          },
        })
        .then((response) => {
          this.setState({
            isApiLoading: false,
          });
          resolve(response.data);
        })
        .catch((error) => {
          this.setState({
            isApiLoading: false,
          });
          reject(error);
          
        });
    });
  };
  postKeyOutcomesDeliveredAPICall = (data) => {
    this.setState({
      isApiLoading: true,
    });
    const { clientData } = this.props;
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`${LocalApiBaseUrl}ClientStory/PostClientOutcomesDeliveredData`, data)
        .then((response) => {
          this.setState({
            isApiLoading: false,
          });
          resolve(response.data);
        })
        .catch((error) => {
          this.setState({
            isApiLoading: false,
          });
          reject(error);
          
        });
    });
  };

  getAllKeyOutcomesDelivered = () => {
    this.getKeyOutcomesDeliveredAPICall()
      .then((response) => {
        let data = response.map((a) => ({ ...a, error: "", uniqueId: uuidv4() }));
        data = data.sort((a, b) => (a.Sequence > b.Sequence ? 1 : -1));
        let pageNo = 1;
        let totalcount = data[0].OutcomeDescription_En.length;
        data[0].pageNo = 1;

        for (let i = 1; i < data.length; i++) {
          totalcount = totalcount + data[i].OutcomeDescription_En.length;
          if (parseInt(totalcount / characterLimit) === 0) {
            data[i].pageNo = pageNo;
          } else {
            pageNo = pageNo + 1;
            totalcount = data[i].OutcomeDescription_En.length;
            data[i].pageNo = pageNo;
          }
        }
        let maxPageNo = pageNo;

        this.setState({
          keyOutcomesDelivered: _.cloneDeep(data),
          keyOutcomesDeliveredOriginal: _.cloneDeep(data),
          maxPageNo,
          currentPage: 1,
          showKeyOutcomes: true,
        });
      })
      .catch(() => {
        this.setState({
          keyOutcomesDelivered: [],
          keyOutcomesDeliveredOriginal: [],
        });
      });
  };

  addKeyOutcomesDelivered = () => {
    let { keyOutcomesDelivered } = this.state;
    const { clientData } = this.props;
    const obj = {
      uniqueId: uuidv4(),
      OutcomeID: 0,
      OrgEntityId: clientData.id,
      OutcomeDescription_En: "",
      OutcomeDescription: "",
      Sequence: keyOutcomesDelivered.filter((a) => a.IsDeleted === false).length + 1,
      IsDeleted: false,
      CreatedBy: "",
      CreatedTS: "",
      UpdatedBy: "",
      UpdatedTS: "",
    };
    keyOutcomesDelivered.push(obj);
    keyOutcomesDelivered = this.formatKeyOutcomes(keyOutcomesDelivered);
    this.setState({
      keyOutcomesDelivered,
    });
  };

  deleteKeyOutcomesDelivered = (uniqueId) => {
    let { keyOutcomesDelivered } = this.state;
    let deletedSequence = 0;
    keyOutcomesDelivered = keyOutcomesDelivered.filter((each) => {
      if (each.uniqueId === uniqueId) {
        if (parseInt(each.OutcomeID) !== 0) {
          each.IsDeleted = true;
          deletedSequence = each.Sequence;
          each.Sequence = 0;
          return each;
        }
      } else {
        return each;
      }
    });
    keyOutcomesDelivered = this.formatKeyOutcomes(keyOutcomesDelivered);
    keyOutcomesDelivered = keyOutcomesDelivered.map((each) => {
      if (parseInt(each.Sequence) !== 0 && each.Sequence > deletedSequence) {
        each.Sequence = each.Sequence - 1;
      }
      return each;
    });
    this.setState({
      keyOutcomesDelivered,
    });
  };
  outcomeDescriptionChange = (event, uniqueId) => {
    let { keyOutcomesDelivered } = this.state;
    keyOutcomesDelivered = keyOutcomesDelivered.map((each) => {
      if (each.uniqueId === uniqueId) {
        each.OutcomeDescription_En = event.target.value;
        each.error = "";
      }
      return each;
    });
    this.setState({
      keyOutcomesDelivered,
    });
  };

  hasConfigureAccess = () => {
    const { BeforeAfterMindMap } = this.state;
    const hasAccess = this.hasEditAccess();
    BeforeAfterMindMap.hasConfigureAccess = hasAccess;
    this.setState({ BeforeAfterMindMap });
  }

  hasEditAccess = () => {
    const { roleData } = this.props;
    const foundPermissionObj = _.find(roleData, { FeatureName: 'Client Story' });

    let hasAccessPermission = false;
    if (!_.isEmpty(foundPermissionObj)) {
      const allowedOperations = foundPermissionObj.Operations;

        // ACCESS WOULD ONLY BE ALLOWED IF THE USER HAS EITHER "VIEW" OR "EDIT" PERMISSION
      if (allowedOperations.indexOf('Edit') !== -1) {
        hasAccessPermission = true;
      }
    }
    return hasAccessPermission;
  };

  getJourneyTimelinesURL = async () => {
    const { clientData } = this.props;
    if(clientData.id){
      this.setState({
        hasClientSelected: true
      })
      this.getMileStoneData();
      axiosInstance
      .get(`${LocalApiBaseUrl}ClientStory/JourneyPBIUrl`, {
        params: {
          OrgEntityID: clientData.id,
        },
      })
      .then((response) => {
        this.setState({
          journeyURL: response.data
        });
      });
    }
    else{
      this.setState({
        showJourneyDefaultMessage : 'Please select a client to continue',
        hasClientSelected: false,
        showMilestone: false
      });
    }
  }

  getStoryMapURL = () => {
    const { clientData } = this.props;
    if(clientData.id){
      this.setState({
        hasClientSelected: true,
      });
      this.getStoryMap();
      axiosInstance
      .get(`${LocalApiBaseUrl}ClientStory/StoryPBIUrl`, {
        params: {
          OrgEntityID: clientData.id,
        },
      })
      .then((response) => {
        this.setState({
          storyMapURL: response.data
        });
      });
    }
    else{
      this.setState({
        showStoryDefaultMessage : 'Please select a client to continue',
        hasClientSelected: false,
        showReport: false,
      })   
    }
  }

  getStoryMap = () => {
    const { clientData, dealId } = this.props;
    if (clientData.id) {
      this.setState({
        isApiLoading: true,
      });
      axiosInstance
        .get(`${LocalApiBaseUrl}ClientStory/GetStoryMap?DealId=${dealId}`, {
          params: {
            OrgEntityID: clientData.id,
          },
        })
        .then((response) => {
          let tempArr = [];
          let tempError = [];
          if (this.state.configureStory || response.data.length > 0) {
            response.data.map((temp) => {
              let region = this.state.country.filter(
                (regionID) => regionID.ID === temp.DeliveryRegionID
              );
              const blocksFromHTML = temp.AdditionalInfo ? convertFromHTML(temp.AdditionalInfo) : convertFromHTML("<p></p>\n");
              const htmlTextState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
              );
              if (region.length !== 0) {
                tempArr.push({
                  region: region[0].name,
                  scope: temp.ScopeOfWork,
                  process: temp.KeyProcesses,
                  comments: temp.Comments,
                  active: true,
                  HeadCount: temp.HeadCount != null ? temp.HeadCount : "",
                  StoryMapId: temp.StoryMapId,
                  additionalInfoEditorState: EditorState.createWithContent(htmlTextState),
                });
                tempError.push({
                  region: "",
                  scope: "",
                  process: "",
                  HeadCount: "",
                });
              }
            });
            this.setState({
              storyMapData: tempArr,
              storyErrors: tempError,
              isApiLoading: false,
              showReport: true,
              showStoryDefaultMessage: "",
            });
          } else {
            this.setState({
              showStoryDefaultMessage: i18n.t("overview_Milestones_not_configured"),
              storyMapData: tempArr,
              storyErrors: tempError,
              hasClientSelected: true,
              showReport: false,
              isApiLoading: false,
            });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          this.setState({
            isApiLoading: false,
          });
        });
    }
  };

  getMileStoneData = () => {
    this.setState({
      isApiLoading: true,
    });
    const { clientData, dealId } = this.props;
    axiosInstance
      .get(`${LocalApiBaseUrl}ClientStory/GetJouneyMilestoneData?DealId=${dealId}`, {
        params: {
          OrgEntityID: clientData.id,
        }
      })
      .then((response) => {
        let tempArr = [];
        let tempError = [];

        if(!this.state.flag || response.data.length > 0){
          response.data.map((item => {
            const blocksFromHTML = convertFromHTML(item.Description);
            const htmlTextState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks,blocksFromHTML.entityMap,);
            const plainValue = htmlTextState.getPlainText('\u0001')
            const temp = new Date(item.Year)
            tempArr.push({
              "name": "Milestone ",
              "desc": item.Description,
              "text": plainValue,
              "year": temp,
              "MilestoneId": item.MilestoneId,
              "Sequence": item.Sequence,
              "active": true
            })
            tempError.push({
              "year": "",
              "desc": ""
            })
          }
          ))
          let newObj = _.sortBy(tempArr, 'Sequence')
          this.setState({
            items: newObj,
            errors: tempError,
            isApiLoading: false,
            showMilestone: true,
            showJourneyDefaultMessage: '',           
          })
          this.initialEditorStateChange();
        }
        else{
          this.setState({
            showJourneyDefaultMessage : 'There are no milestones mapped to this client',
            hasClientSelected: true,
            showMilestone: false,
            isApiLoading: false,
            items: [],
            errors: [],
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        this.setState({
          isApiLoading: false
        })
      });
  }

  getAllDeliveryRegion = () => {
    let regionName = ["Latin America","North America","Europe","AAPAC"]
    this.setState({
      isApiLoading: true
    })
    axiosInstance
      .get(`${LocalApiBaseUrl}DealOnboarding/GetAllDeliveryRegion`)
      .then((response) => {
        let tempArr = [];
        const filteredRegion = response.data.filter((item)=> item.IsActive === true)
        filteredRegion.filter((item) => regionName.includes(item.DeliveryRegionName_En))
          .map((item) => tempArr.push({
            "name": item.DeliveryRegionName,
            "ID": item.ID
          }))
        this.setState({
          country: tempArr,
          isApiLoading: false
        },() => this.getStoryMap())
      })
      .catch((error) => {
        this.setState({
          isApiLoading: false
        })
        trycatchAlertPopup(error);
      });
  }

  validateForm() {
    let items = this.state.items;
    let temperrors = [];
    let formIsValid = true;

    items.map((temp, index) => {
      let errors = {}
      if (!temp["year"]) {
        formIsValid = false;
        errors["year"] = "Year is required";
      }
      else if (index > 0) {
        for(let i = 1; i <= index; i++){
          if (temp["year"] <= items[i - 1]["year"]) {
            formIsValid = false;
            errors["year"] = "Timeline should be greater than previous milestone"
          }
        }
      }
      else {
        errors["year"] = ""
      }
      if (!temp["text"].trim()) {
        formIsValid = false;
        errors["desc"] = "Description is required";
      }else if (temp["desc"].trim().includes("></li>")) {
        formIsValid = false;
        errors["desc"] = i18n.t("Please_enter_message");
      }
      // else if (!regexForDescription.test(temp["desc"].trim())){
      //           errors["desc"] = i18n.t("ToolDescription_SpecialChar");
      //           formIsValid = false;
      // }
      else {
        errors["desc"] = ""
      }
      temperrors.push(errors)
    })
    this.setState({
      errors: temperrors
    });

    this.setState({
      formIsValid: formIsValid,
      isApiLoading: false
    })
    return formIsValid;
  }

  validateStoryForm() {
    let storyMap = this.state.storyMapData;
    let temperrors = [];
    let formIsValidValidStory = true;

    storyMap.map((temp) => {
      let storyErrors = {}
      if (!temp["region"].trim()) {
        formIsValidValidStory = false;
        storyErrors["region"] = "Region is required";
      }
      else {
        storyErrors["region"] = ""
      }
      if (!temp["scope"].trim()) {
        formIsValidValidStory = false;
        storyErrors["scope"] = "Scope is required";
      }
      else {
        storyErrors["scope"] = ""
      }
      if (!temp["process"].trim()) {
        formIsValidValidStory = false;
        storyErrors["process"] = "Process is required";
      }
      else {
        storyErrors["scope"] = ""
      }
      temperrors.push(storyErrors)
    })
    this.setState({
      storyErrors: temperrors,
      isApiLoading: false
    });

    if (formIsValidValidStory) {
      this.setState({
        formIsValidValidStory: true
      });
    } else {
      this.setState({
        formIsValidValidStory: false
      });
    }
    return formIsValidValidStory;
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const {items} = this.state;
    let temp = items[oldIndex].Sequence;
    items[oldIndex].Sequence = items[newIndex].Sequence;
    items[newIndex].Sequence = temp
    const arr1 = arrayMoveImmutable(this.state.items, oldIndex, newIndex)
    this.setState({
      items: arr1,
    })
    this.validateForm();
  };

  onSortKeyOutcomes = ({ oldIndex, newIndex }) => {
    let { keyOutcomesDelivered } = this.state;
    let notDeleted = [...keyOutcomesDelivered]
      .filter((a) => a.IsDeleted === false)
      .sort((a, b) => (a.Sequence > b.Sequence ? 1 : -1));
    let deleted = [...keyOutcomesDelivered].filter((a) => a.IsDeleted === true);
    let newList = arrayMove(notDeleted, oldIndex, newIndex);
    newList = newList.map((each, index) => ({ ...each, Sequence: index + 1 }));
    const keyOutcomesDeliveredFiltered = [...newList, ...deleted];
    this.setState({
      keyOutcomesDelivered: keyOutcomesDeliveredFiltered,
    });
  };

  handleAdd = () => {
    const cloneItems = _.cloneDeep(this.state.items)
    const cloneError = _.cloneDeep(this.state.errors);
    const clonedMilestonesEditorStates = _.cloneDeep(this.state.milestonesEditorStates);
    if (this.state.items.length <= 7) {
      cloneItems.push({
        "name": "Milestone ",
        "year": "",
        "desc": "",
        "text": "",
        "MilestoneId": 0,
        "Sequence": this.state.items.length + 1,
        "active": false
      })
      cloneError.push({
        "year": "",
        "desc": ""
      })
      clonedMilestonesEditorStates.push({
        "index":cloneItems.length +1,
        "editorState":EditorState.createEmpty(),
        "value":''
      })
      this.setState({
        items: cloneItems,
        errors: cloneError,
        milestonesEditorStates:clonedMilestonesEditorStates,
      })
    }
  }

  handleDelete = index => event => {
    const cloneItems = _.cloneDeep(this.state.items)
    const cloneError = _.cloneDeep(this.state.errors)
    const clonedMilestonesEditorStates = _.cloneDeep(this.state.milestonesEditorStates);
    cloneError.splice(index,1)
    const DeletedItem = cloneItems.splice(index,1)
    clonedMilestonesEditorStates.splice(index,1)
    if(DeletedItem[0].MilestoneId > 0){
      this.state.deletedOne.push(DeletedItem[0].MilestoneId)
    }
      this.setState({
        items: cloneItems,
        errors: cloneError,
        milestonesEditorStates: clonedMilestonesEditorStates,
        checkDelete: true
      })
    }

  handleDescChange = (index) => event => {
    let cloneItems = [...this.state.items]
    cloneItems[index].desc = event.target.value;
    this.setState({
      items: cloneItems
    }, () => {
      cloneItems = []
    })
  }

  handleDate = index => date => {
    let cloneItems = [...this.state.items]
    if (index === 0) {
      cloneItems[index].year = date;
      this.setState({
        items: cloneItems
      })
    }
    else {
      cloneItems[index].year = date;
      this.setState({
        items: cloneItems
      })
    }
  }

  configureBeforeAfterMindClick = (val) => {
    const { BeforeAfterMindMap } = this.state;
    const { setConfigureMode, saveConfigData, viewData } = this.props;
    BeforeAfterMindMap.showConfigureButton = !val;
    BeforeAfterMindMap.showConfigureMode = val;
    setConfigureMode(val);
    saveConfigData(viewData);
    this.setState({
      BeforeAfterMindMap: { ...BeforeAfterMindMap },
      clientstoryReferencesConfig: val,
    });
  }

  overviewConfigureClick = (val) =>{
      this.setState({
        selectedTab: val
      },() => {this.configureClick()})
  }

  milestoneConfigureClick = (val) =>{
      this.setState({
        selectedTab: val
      },() => {this.configureClick()})
  }

  keyOutcomesDeliveredClick = (val) => {
    this.setState(
      {
        selectedTab: val,
      },
      () => {
        this.configureClick();
      }
    );
  };

  configureClick = () => {
    const { selectedTab, keyOutcomesDeliveredOriginal } = this.state;
    if (selectedTab === "StoryMap") {
      this.getStoryMap();
      this.setState({
        configureStory: true,
        storyConfigureButton: false,
        configureKeyOutcomes: false,
        clientstoryReferencesConfig: true,
      });
    } else if (selectedTab === "JourneyTimelines") {
      this.getMileStoneData();
      this.setState({
        flag: !this.state.flag,
        journeyConfigureButton: false,
        configureKeyOutcomes: false,
        clientstoryReferencesConfig: true,
      });
    } else if (selectedTab === "BOIMindMap") {
      if (this.hasEditAccess()) {
        this.setState({
          isVisibleConfigureBOIMindMapButton: false,
          isVisibleCancelAndSaveBOIMindMapButtons: true,
          configureKeyOutcomes: false,
          clientstoryReferencesConfig: true,
        });
      }
    } else if (selectedTab === "KeyOutcomes") {
      this.setState(
        {
          configureKeyOutcomes: true,
          journeyConfigureButton: false,
          showKeyOutcomes: false,
          flag: !this.state.flag,
          keyOutcomesDelivered: _.cloneDeep(keyOutcomesDeliveredOriginal),
          clientstoryReferencesConfig: true,
        },
        () => {
          if (this.state.keyOutcomesDeliveredOriginal.length === 0) {
            this.addKeyOutcomesDelivered();
          }
        }
      );
    }
  };

  handleCancel = () => {
    const cloneError = _.cloneDeep(this.state.errors);
    cloneError.map((item) => {
      item.year = "";
      item.desc = "";
    });
    this.setState({
      errors: cloneError,
      flag: true,
      journeyConfigureButton: true,
      deletedOne: [],
      configureKeyOutcomes: false,
      clientstoryReferencesConfig: false,
    });
    this.getJourneyTimelinesURL();
  };

  stroyMapAddClick = () => {
    const cloneItems = _.cloneDeep(this.state.storyMapData)
    const cloneError = _.cloneDeep(this.state.storyErrors)
    cloneItems.push({
      region: "",
      scope: "",
      process: "",
      HeadCount: "",
      comments: "",
      additionalInfoEditorState: EditorState.createEmpty(),
      active: false,
      StoryMapId: 0,
    });
    cloneError.push({
      'region': '',
      'scope': '',
      'HeadCount':'',
      'process': ''
    })
    this.setState({
      storyMapData: cloneItems,
      storyErrors: cloneError
    })
  }

  onRegionSelect = (name, index) => {
    // let tempArr = this.state.storyMapData.filter((item) => item.region === name);
    // if (tempArr.length != 0) {
    //   window.alert("You have already selected this region");
    // } else {
    const cloneItems = _.cloneDeep(this.state.storyMapData);
    cloneItems[index].region = name;
    this.setState({
      storyMapData: cloneItems,
    });
    // }
  };

  onStoryDelete = index => event => {
    const cloneItems = _.cloneDeep(this.state.storyMapData)
    const cloneError = _.cloneDeep(this.state.storyErrors)    
    if (cloneItems[index].active) {
      const DeletedItem = cloneItems.splice(index,1)
      if(DeletedItem[0].StoryMapId > 0){
        this.state.deletedStoryMapData.push(DeletedItem[0].StoryMapId)
      }
      this.setState({
        storyMapData: cloneItems,
        storyErrors: cloneError
      })
    }
    else {
      cloneItems.splice(index, 1)
      this.setState({
        storyMapData: cloneItems,
      })
    }

  }
  onHeadCountChange= (index) => (event) => {
    const cloneItems = _.cloneDeep(this.state.storyMapData);
    cloneItems[index].HeadCount = event.target.value;
    this.setState({
      storyMapData: cloneItems,
    });
  };

  onScopeChange = index => event => {
    const cloneItems = _.cloneDeep(this.state.storyMapData)
    cloneItems[index].scope = event.target.value;
    this.setState({
      storyMapData: cloneItems
    })
  }

  onProcessChange = index => event => {
    const cloneItems = _.cloneDeep(this.state.storyMapData)
    cloneItems[index].process = event.target.value;
    this.setState({
      storyMapData: cloneItems
    })
  }

  onCommentChange = index => event => {
    const cloneItems = _.cloneDeep(this.state.storyMapData)
    cloneItems[index].comments = event.target.value;
    this.setState({
      storyMapData: cloneItems,
    });
  };

  onAdditionalInfoEditorStateChange = (index, editorState) => {
    const cloneItems = _.cloneDeep(this.state.storyMapData);
    const plainValue = editorState.getCurrentContent().getPlainText();
    if (plainValue.length <= 150) {
      cloneItems[index].additionalInfoEditorState = editorState;      
    }
    
    this.setState({
      storyMapData: cloneItems,
    });
  };

  validateKeyOutcomesForm = () => {
    let formValid = true;
    let errorMessage = "Description is required";
    let { keyOutcomesDelivered } = this.state;
    keyOutcomesDelivered = keyOutcomesDelivered.map((each) => {
      if (!each.OutcomeDescription_En.trim()) {
        each.error = errorMessage;
        formValid = false;
      }
      return each;
    });
    this.setState({
      keyOutcomesDelivered,
    });
    return formValid;
  };

  keyOutcomesSubmit = async () => {
    try {
      if (this.validateKeyOutcomesForm()) {
        let { keyOutcomesDelivered } = this.state;
        keyOutcomesDelivered = keyOutcomesDelivered.map((a) => ({
          ...a,
          OutcomeDescription_En: a.OutcomeDescription_En.trim(),
        }));
        const response = await this.postKeyOutcomesDeliveredAPICall(keyOutcomesDelivered);
        this.setState(
          {
            configureKeyOutcomes: false,
            journeyConfigureButton: true,
            flag: true,
            currentPage: 1,
            keyOutcomesDelivered: [],
            clientstoryReferencesConfig: false,
          },
          () => {
            this.getAllKeyOutcomesDelivered();
            window.alert(response.StatusMessage);
          }
        );
      }
    } catch (error) {
      
    }
  };

  keyOutcomesCancel = () => {
    const { keyOutcomesDeliveredOriginal } = this.state;
    this.setState({
      configureKeyOutcomes: false,
      journeyConfigureButton: true,
      flag: true,
      keyOutcomesDelivered: [],
      currentPage: 1,
      clientstoryReferencesConfig: false,
    });
  };

  handleSubmit = async () => {
    const { items } = this.state;
    let cloneItems = _.cloneDeep(this.state.items)
    if (this.validateForm()) {
    this.setState({
      isApiLoading: true
    })
      let data = [];
      let a = [];
      
      for(let i = 0; i< this.state.deletedOne.length; i++){
        this.state.deletedOne.forEach((item)=>{
          a[i] = axiosInstance
          .delete(`${LocalApiBaseUrl}ClientStory/DeleteJourneyMilestoneData`, {
            params: {
              MilestoneId: item
            }          
        })
        .then((response) => {
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          this.setState({
            isApiLoading: false
          })
        });
      })
      }

      for(let i = 0; i< this.state.deletedOne.length; i++){
        await a[i]
      }  
       
      let count = 1;
      items.map((temp, index) => {
        var yearSelected = new Date(temp.year).getFullYear() +
          "-" +
          (new Date(temp.year).getMonth() + 1 < 10
            ? "0" + (new Date(temp.year).getMonth() + 1)
            : new Date(temp.year).getMonth() + 1);
        data.push({
          MilestoneId: temp.MilestoneId,
          OrgEntityId: this.props.clientData.id,
          Sequence: count++,
          Year: yearSelected,
          Description: temp.desc,
          EnterpriseID: sessionStorage.getItem("EnterpriseID"),
          DealId: this.props.dealId,
        });
      });
      axiosInstance
        .post(`${LocalApiBaseUrl}ClientStory/AddEditJourneyMilestone`, data)
        .then((response) => {
          window.alert(response.data.StatusMessage)
          this.getMileStoneData();
          this.setState({
            flag: true,
            journeyConfigureButton : true,
            isApiLoading: false,
            checkDelete: false,
            deletedOne: [],
            clientstoryReferencesConfig: false,
          })
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          this.setState({
            isApiLoading: false,
            clientstoryReferencesConfig: false,
          })
        });

    }
  }

  storySubmit = async () => {
    const { storyMapData, country } = this.state;    
    const cloneError = _.cloneDeep(this.state.storyErrors)
    this.setState({
      isApiLoading: true,
      isStorySubmitDisabled: true,
    });
    if (this.validateStoryForm()) {
      let data = [];
      let a = [];
      for(let i = 0; i< this.state.deletedStoryMapData.length; i++){
        this.state.deletedStoryMapData.forEach((item)=>{
          a[i] = axiosInstance
            .delete(`${LocalApiBaseUrl}ClientStory/DeleteStoryMap`, {
              params: {
                StoryMapId: item,
              },
            })
            .then((response) => {
              this.getStoryMap();
              this.setState({
                storyErrors: cloneError,
              });
            })
            .catch((error) => {
              trycatchAlertPopup(error);
              this.setState({
                isApiLoading: false,
                isStorySubmitDisabled: false,
              });
            });
        });
      }
      for(let i = 0; i< this.state.deletedStoryMapData.length; i++){
        await a[i]
      }  
      storyMapData.map((item) => {
        let temp = country.filter((region) => region.name === item.region)
        data.push({
          StoryMapId: item.StoryMapId,
          OrgEntityID: this.props.clientData.id,
          DeliveryRegionID: temp[0].ID,
          ScopeOfWork: item.scope,
          KeyProcesses: item.process,
          Comments: item.comments,
          HeadCount: item.HeadCount,
          AdditionalInfo: draftToHtml(
            convertToRaw(item.additionalInfoEditorState.getCurrentContent())
          ),
          EnterpriseID: sessionStorage.getItem("EnterpriseID"),
          DealId: this.props.dealId,
        });
      });
      axiosInstance
        .post(`${LocalApiBaseUrl}ClientStory/AddEditStoryMap`, data)
        .then((response) => {
          window.alert(response.data.StatusMessage)
          this.getStoryMap();
          this.setState({
            configureStory: false,
            storyConfigureButton : true,
            isApiLoading: false,
            deletedStoryMapData: [],
            clientstoryReferencesConfig: false,
            isStorySubmitDisabled: false,
          });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          this.setState({
            isApiLoading: false,
            clientstoryReferencesConfig: false,
            isStorySubmitDisabled: false,
          });
        });
    }
    else{
      this.setState({        
        isStorySubmitDisabled: false,
      });
    }
  };

  handleStoryCancel = () => {
    const cloneError = _.cloneDeep(this.state.storyErrors)
    cloneError.map((item) => {
      item.region = '';
      item.scope = '';
      item.process = ''
    })
    this.setState({
      storyErrors: cloneError,
      configureStory: false,
      storyConfigureButton : true,
      deletedStoryMapData: [],
      clientstoryReferencesConfig: false,
    })
    this.getStoryMapURL();
  }

  onSelectTab = (event) => {
    if (event !== "BeforeAfterMindMap") {
      this.configureBeforeAfterMindClick(false);
    }
    if (event == "StoryMap") {
      this.getStoryMapURL();
      this.getJourneyTimelinesURL();
    }
    
    // if((event === "BeforeAfterMindMap" || event === "BOIMindMap" ) && !tabClicked[`${event}`]){
    //   tabClicked[`${event}`] = true;
    //    
    // }
    
    this.setState({
      // tabClicked,
      selectedTab: event,
      configureStory: false,
      flag: true,
      storyConfigureButton: true,
      journeyConfigureButton: true,
      configureKeyOutcomes: false,
      deletedOne: [],
      deletedStoryMapData: [],
      keyOutcomesDelivered: [],
      currentPage: 1,
      clientstoryReferencesConfig: false,
    });
    let PageName = "";

    switch (event) {
      case "BeforeAfterMindMap":
        PageName = TRACKING_MODULES.CLIENT_STORY_BEFORE_AFTER_MIND_MAP;
        break;
      case "BOIMindMap":
        PageName = TRACKING_MODULES.CLIENT_STORY_BOI_MIND_MAP;
        break;
      case "StoryMap":
        PageName = TRACKING_MODULES.CLIENT_STORY_OVERVIEW_KEY_MILESTONES;
        break;
    }

    userTracker(
      PageName,
      false,
      null,
      null,
      createParamObject(
        PARAM_NAMES.OFFERING,
        store.getState().CurrentFilter.SyncFilter.OfferingName
      ),
      createParamObject(
        PARAM_NAMES.SUB_OFFERING,
        store.getState().CurrentFilter.Common.SubOfferingName
      ),
      createParamObject(
        PARAM_NAMES.LOCATION,
        store.getState().CurrentFilter.Location.locationName
      ),
    );
  };


  checkStory = () => {
      if(this.state.selectedTab === "StoryMap"){
          return this.state.storyConfigureButton
      }
      else{
          return this.state.journeyConfigureButton
      }
  }

  // CALL BACK EVENT HANDLER FOR 'CANCEL' AND 'SAVE' MIND MAP BUTTONS. THIS WILL TRIGGER A STATE CHANGE TO EXECUTE APPROPRIATE ACTION IN THE 'MIND MAP' COMPONENT
  performActionOnBOIMindMap = (action) => {
    if (action === 'cancel') {
      this.setState({
        isVisibleConfigureBOIMindMapButton: true,
        isVisibleCancelAndSaveBOIMindMapButtons: false,
        clientstoryReferencesConfig: false,
      });
    } else {
      this.setState({
        doSaveBOIMindMap: action === 'save',
        clientstoryReferencesConfig: false,
      });
    }
  }

  // CALL BACK EVENT HANDLER BASED ON WHETHER THE MIND MAP IS LOADED OR NOT
  handleCallbackLoadStatusOfBOIMindMap = (isLoaded) => {
    this.setState({
      isLoadedBOIMindMap: isLoaded,
    }, () => {
      if (!isLoaded) {
        this.setState({
          isVisibleConfigureBOIMindMapButton: true,
          isVisibleCancelAndSaveBOIMindMapButtons: false,
          doSaveBOIMindMap: false,
        });
      }
    });
  }

  // CALLBACK HANDLER TO ENABLE/DISABLE THE 'SAVE' MIND MAP BUTTON BASED ON THE FUNCTIONAL CONDITION IN 'MIND MAP' COMPONENT
  handleCallbackEnableDisableSaveBOIMindMapButton = (isEnabled) => {
    this.setState({
      isEnabledSaveBOIMindMapButton: isEnabled,
    });
  };

  // CALLBACK AFTER THE 'MIND MAP' GETS SAVED
  handleCallbackAfterSaveBOIMindMap = (isSaveExecutionSuccessful) => {
    let isVisibleConfigureBOIMindMapButton = true;
    let isVisibleCancelAndSaveBOIMindMapButtons = false;

    // IF MIND MAP WAS NOT SAVED SUCCESSFULLY, THEN DISPLAY THE APPROPRIATE BUTTONS
    if (!isSaveExecutionSuccessful) {
      isVisibleConfigureBOIMindMapButton = false;
      isVisibleCancelAndSaveBOIMindMapButtons = true;
    }

    this.setState({
      isVisibleConfigureBOIMindMapButton,
      isVisibleCancelAndSaveBOIMindMapButtons,
      doSaveBOIMindMap: false,
    });
  }
  setBeforAfterPostApiStatus = (val) => {
    this.setState({ postBeforeAfterApi: val, clientstoryReferencesConfig: false, });
  }
  initialEditorStateChange = () =>{
    const { items } = this.state;
    const initialMilestonesEditorStates = [] ;

    items.forEach((item)=> {
      
      const blocksFromHTML = convertFromHTML(item.desc);
      const content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
      const val = item.desc;
      const index = item.Sequence;      
      initialMilestonesEditorStates.push({"value":val,"index":index, "editorState":EditorState.createWithContent(content)});
    });   
    this.setState({
      milestonesEditorStates:initialMilestonesEditorStates,     
    });
  }
  onEditorStateChange = (editorState,index) => {

    const { milestonesEditorStates } = this.state;
    const clonedMilestonesEditorStates = milestonesEditorStates;
    const cloneItems = [...this.state.items];
    const editorValue = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    const plainValue = editorState.getCurrentContent().getPlainText()
    if(plainValue.length<=200){
      clonedMilestonesEditorStates[index-1].editorState = editorState;
      clonedMilestonesEditorStates[index-1].value = editorValue
      clonedMilestonesEditorStates[index-1].index = index;  
      cloneItems[index-1].desc = editorValue;
      cloneItems[index-1].text = plainValue;   

    }

    this.setState({
      milestonesEditorStates:clonedMilestonesEditorStates,   
      items: cloneItems
    });
    
    
  };

  handleBeforeInput = (editorState) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const MAX_LENGTH = 200;
    if (currentContentLength > MAX_LENGTH - 1) {
      return "handled";
    }
  };

  handleAdditionalInfoBeforeInput = (editorState) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const MAX_LENGTH = 150;
    if (currentContentLength > MAX_LENGTH - 1) {
      return "handled";
    }
  };

  handleBack = () => {
    let currentPage = this.state.currentPage;
    currentPage = currentPage - 1;
    this.setState({
      currentPage,
    });
  };
  handleNext = () => {
    let currentPage = this.state.currentPage;
    currentPage = currentPage + 1;
    this.setState({
      currentPage,
    });
  };

  render() {
    const options = {
      loop: true,
      margin: 16,
      responsiveClass: "",
      autoWidth: true,
      dots: false,
      responsive: {
        0: {
          items: 1,
          nav: true
        },
        600: {
          items: 2,
          nav: true
        },
        1000: {
            items: 2,
            nav: true,
            loop: false
        },
        1440: {
            items: 3,
            nav: true,
            loop: false
          },
          1600: {
              items: 4,
              nav: true,
              loop: false
          },
          1800: {
              items: 5,
              nav: true,
              loop: false
        }
      }
    };

    const {
      BeforeAfterMindMap,
      postBeforeAfterApi,
      isApiLoading,
      items,
      storyMapData,
      storyErrors,
      selectedTab,
      hasClientSelected,
      isVisibleConfigureBOIMindMapButton,
      isVisibleCancelAndSaveBOIMindMapButtons,
      isEnabledSaveBOIMindMapButton,
      doSaveBOIMindMap,
      isLoadedBOIMindMap,
      configureKeyOutcomes,
      keyOutcomesDelivered,
      currentPage,
      maxPageNo,
      clientstoryReferencesConfig,
      configureStory,
      flag,
      keyOutcomesDeliveredOriginal,
    } = this.state;

    const { clientData } = this.props;
    const keyOutcomesDeliveredOriginalRender = keyOutcomesDeliveredOriginal
      .filter((a) => a.IsDeleted === false)
      .filter((a) => a.pageNo === currentPage);
    const keyOutcomesDeliveredFiltered = keyOutcomesDelivered.filter((a) => a.IsDeleted === false);

    const filterDetails = {
      Client: clientData.id,
      process: 0,
      Locations: 0,
    };

    return (
      <>
        {isApiLoading && <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active={isApiLoading} />}
        <div className="noleftmenupage clientstory">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="pagename-heading">
                  <h5>Client Story</h5>
                  <div className='client-story-references'>
                  <div className={`category-references dashboard-category-references ${(configureStory || configureKeyOutcomes || !flag) ? `mr-1`:`mr-3`}`}>
                 {
                   clientData.id &&  <ClientStoryReferences 
                   SOCatID={0}
                   leverName="Client Story"
                   isConfig={clientstoryReferencesConfig}
                   referenceScreen="ClientStory" 
                   Client={filterDetails.Client}
                   process={filterDetails.process}
                   Locations={filterDetails.Locations}
                   isClientStory = {true}
                   />
                 }
                 </div>
                  {selectedTab !== "BOIMindMap" &&
                    selectedTab !== "BeforeAfterMindMap" &&
                    this.hasEditAccess() &&
                    this.checkStory() &&
                    hasClientSelected && (
                    
                      <div className="dropdown custom-icon-dropdowns">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          data-toggle="dropdown"
                        >
                          Configure
                        </button>
                        <ul className="dropdown-menu dropdown-menu-right outcomes-delivered">
                          <li onClick={() => this.overviewConfigureClick("StoryMap")}>
                            <NavLink className="client-overview" to="#">
                              Client Overview{" "}
                            </NavLink>
                          </li>
                          <li onClick={() => this.milestoneConfigureClick("JourneyTimelines")}>
                            <NavLink className="key-milestones" to="#">
                              Key Milestones
                            </NavLink>
                          </li>
                          {/* {this.hasEditAccess() && (
                            <li onClick={() => this.keyOutcomesDeliveredClick("KeyOutcomes")}>
                              <NavLink className="key-outcomes" to="#">
                                Key Outcomes
                              </NavLink>
                            </li>
                          )} */}
                          </ul>
                        </div>
                      )}

                    {/* CONFIGURE BUTTON FOR 'BOI MIND MAP' TAB */}
                    {hasClientSelected &&
                      selectedTab === "BOIMindMap" &&
                      this.hasEditAccess() &&
                      isVisibleConfigureBOIMindMapButton && (
                        <button
                          type="button"
                          className="btn btn-gradientbg"
                          onClick={this.configureClick}
                          disabled={!isLoadedBOIMindMap}
                        >
                          Configure
                        </button>
                      )}

                    {/* 'CANCEL' AND 'SAVE' BUTTONS FOR BOI MIND MAP */}
                    {hasClientSelected &&
                      selectedTab === "BOIMindMap" &&
                      this.hasEditAccess() &&
                      isVisibleCancelAndSaveBOIMindMapButtons &&
                      isLoadedBOIMindMap && (
                        <div className="save-mindmap">
                          <button
                            type="button"
                            className="btn btn-default mr-2"
                            onClick={() => this.performActionOnBOIMindMap("cancel")}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => this.performActionOnBOIMindMap("save")}
                            disabled={!isEnabledSaveBOIMindMapButton}
                          >
                            Save
                          </button>
                        </div>
                      )}
                    {BeforeAfterMindMap.hasConfigureAccess &&
                      BeforeAfterMindMap.showConfigureButton &&
                      selectedTab === "BeforeAfterMindMap" &&
                      hasClientSelected && (
                        <button
                          type="button"
                          className="btn-gradientbg"
                          onClick={() => this.configureBeforeAfterMindClick(true)}
                        >
                          Configure
                        </button>
                      )}
                    {BeforeAfterMindMap.hasConfigureAccess &&
                      !BeforeAfterMindMap.showConfigureButton &&
                      selectedTab === "BeforeAfterMindMap" &&
                      hasClientSelected && (
                        <div className="save-mindmap">
                          <button
                            type="button"
                            className="btn btn-default mr-2"
                            onClick={() => this.configureBeforeAfterMindClick(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              this.setBeforAfterPostApiStatus(true);
                            }}
                          >
                            Save
                          </button>
                        </div>
                      )}
                  </div>
                </div>
          
                <div className="clientstory-section common-tablayout">
                  <Tabs defaultActiveKey="StoryMap" onSelect={this.onSelectTab}>
                    <Tab eventKey="StoryMap" title="Overview & Key Milestones">
                      <div
                        className={
                          this.state.configureStory || !this.state.flag
                            ? "tablayout-content"
                            : "tablayout-content overview-key-milestones"
                        }
                      >
                        {(this.state.configureStory || !this.state.flag) && (
                          <h6>
                            {this.state.configureStory && "Client Overview"}
                            {!configureKeyOutcomes && !this.state.flag && "Key Milestone"}
                            {configureKeyOutcomes && "Key Outcomes"}
                          </h6>
                        )}

                        {/* No Map Configured Section Start*/}
                        {/* <div className="map-bgimage">
                              <div className="mapconfiguration-message">
                                <h5><i className="fas fa-exclamation-circle "></i>The map is not Configured. Please add the data</h5>
                              </div>
                              </div> */}
                        {/* No Map Configured Section End*/}
                        {this.state.showReport ||
                        this.state.showMilestone ||
                        configureKeyOutcomes ? (
                          !this.state.configureStory && !configureKeyOutcomes && this.state.flag ? (
                            <div className="overview-milestone-wrapper">
                              <OverviewAndKeyMileStones
                                mileStones={items}
                                overView={storyMapData}
                                clientId={clientData.id}
                              />
                              {/* <div className="outcomes-delivered-wrapper">
                                <h2>Key Outcomes: </h2>
                                {keyOutcomesDeliveredOriginalRender.length > 0 ? (
                                  <>
                                    <ul>
                                      {keyOutcomesDeliveredOriginalRender.map((each) => (
                                        <li key={each.OutcomeID}>{each.OutcomeDescription}</li>
                                      ))}
                                    </ul>
                                    {maxPageNo !== 1 && (
                                      <span className="view-more">
                                        <span>
                                          <img
                                            width="20"
                                            height="20"
                                            src={
                                              currentPage === 1
                                                ? keyoutcomesUpGrey
                                                : keyoutcomesDownArrow
                                            }
                                            className={`previous-up ${
                                              currentPage === 1 ? `disabled` : ``
                                            }`}
                                            onClick={
                                              currentPage !== 1
                                                ? this.handleBack
                                                : "javascript:void(0);"
                                            }
                                          />
                                          <img
                                            width="20"
                                            height="20"
                                            src={
                                              maxPageNo === currentPage
                                                ? keyoutcomesUpGrey
                                                : keyoutcomesDownArrow
                                            }
                                            className={`next-down ${
                                              maxPageNo === currentPage ? `disabled` : ``
                                            } ml-1`}
                                            onClick={
                                              maxPageNo !== currentPage
                                                ? this.handleNext
                                                : "javascript:void(0);"
                                            }
                                          />
                                        </span>
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  <p> {i18n.t("Key_Outcomes_not_available")}</p>
                                )}
                              </div> */}
                            </div>
                          ) : (
                            <>
                              {this.state.configureStory &&
                                (this.state.storyMapData.length === 0 ? (
                                  <>{this.stroyMapAddClick()}</>
                                ) : (
                                  <>
                                    <div className="draggdrop-container">
                                      {this.state.storyMapData.map((temp, index) => {
                                        return (
                                          <>
                                            <div className="dragg-wrapper">
                                              <div className="row dragg-list">
                                                <div className="col-lg-6 dragg-list-left">
                                                  <div class="form-section common-formgrouplabel">
                                                    <div class="form-group border-group">
                                                      <label for="name" class="form-label">
                                                        Region
                                                        <span class="required">&nbsp;*</span>
                                                      </label>
                                                      <div className="initiative-dropdown">
                                                        <Dropdown>
                                                          <Dropdown.Toggle
                                                            className={
                                                              temp.region != ""
                                                                ? "btntoggle-itemactive"
                                                                : ""
                                                            }
                                                          >
                                                            {temp.region != ""
                                                              ? temp.region
                                                              : "Region"}
                                                          </Dropdown.Toggle>
                                                          <Dropdown.Menu>
                                                            {this.state.country.map((item, key) => {
                                                              return (
                                                                <DropdownItem
                                                                  key={key}
                                                                  value={item.name}
                                                                  onClick={() => {
                                                                    this.onRegionSelect(
                                                                      item.name,
                                                                      index
                                                                    );
                                                                  }}
                                                                >
                                                                  {item.name}
                                                                </DropdownItem>
                                                              );
                                                            })}
                                                          </Dropdown.Menu>
                                                        </Dropdown>
                                                      </div>
                                                    </div>
                                                    <span className="errorMsg">
                                                      {storyErrors[index].region}
                                                    </span>
                                                  </div>
                                                  <div class="form-section common-formgrouplabel">
                                                    <div class="form-group border-group">
                                                      <label for="KeyProcesses" class="form-label">
                                                        Key Processes
                                                        <span class="required">&nbsp;*</span>
                                                      </label>
                                                      <input
                                                        type="text"
                                                        id="KeyProcesses"
                                                        class="form-control"
                                                        placeholder="Key Processes"
                                                        name="process"
                                                        value={temp.process}
                                                        onChange={this.onProcessChange(index)}
                                                        maxLength="150"
                                                      />
                                                      <span class="suffix-text">
                                                        {temp.process.trim().length}/150 Characters
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <span className="errorMsg">
                                                    {storyErrors[index].process}
                                                  </span>
                                                </div>
                                                <div className="col-lg-6 dragg-list-right">
                                                  <div class="form-section common-formgrouplabel">
                                                    <div class="form-group border-group">
                                                      <label for="ScopeofWork" class="form-label">
                                                        Scope of Work
                                                        <span class="required">&nbsp;*</span>
                                                      </label>
                                                      <input
                                                        type="text"
                                                        id="ScopeofWork"
                                                        class="form-control"
                                                        placeholder="Scope of Work"
                                                        value={temp.scope}
                                                        name="scope"
                                                        onChange={this.onScopeChange(index)}
                                                        maxLength="150"
                                                      />
                                                      <span class="suffix-text">
                                                        {temp.scope.trim().length}/150 Characters
                                                      </span>
                                                    </div>
                                                    <span className="errorMsg">
                                                      {storyErrors[index].scope}
                                                    </span>
                                                  </div>
                                                  <div class="form-section common-formgrouplabel">
                                                    <div class="form-group border-group">
                                                      <label for="OtherComments" class="form-label">
                                                        Other Comments
                                                      </label>
                                                      <input
                                                        type="text"
                                                        id="OtherComments"
                                                        class="form-control"
                                                        placeholder="Other Comments"
                                                        name="comments"
                                                        value={temp.comments}
                                                        onChange={this.onCommentChange(index)}
                                                        maxLength="150"
                                                      />
                                                      <span class="suffix-text">
                                                        {temp.comments.trim().length}/150 Characters
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                                {/* Head count start */}
<div className="col-lg-6 dragg-list-right">
                                                  <div class="form-section common-formgrouplabel">
                                                    <div class="form-group border-group">
                                                      <label for="HeadCount" class="form-label">
                                                        Head Count
                                                        <span class="required"></span>
                                                      </label>
                                                      <input
                                                        type="text"
                                                        id="HeadCount"
                                                        class="form-control"
                                                        placeholder="Head Count"
                                                        value={temp.HeadCount}
                                                        name="scope"
                                                        onChange={this.onHeadCountChange(index)}
                                                        maxLength="100"
                                                      />
                                                      <span class="suffix-text">
                                                        {temp.HeadCount.trim().length}/100 Characters
                                                      </span>
                                                    </div>
                                                    <span className="errorMsg">
                                                      {storyErrors[index].HeadCount}
                                                    </span>
                                                  </div>
                                                </div>

                                                {/* Head count end  */}
                                                {/* Additional info start */}
                                                <div className="col-lg-6 dragg-list-right">
                                                  <div class="form-section common-formgrouplabel">
                                                    <div class="form-group border-group">
                                                      <label for="additionalInfo" class="form-label">
                                                        Additional Information
                                                        <span class="required"></span>
                                                      </label>
                                                      <div className="description-editor">
                                                        <Editor
                                                          readOnly={false}
                                                          editorState={temp.additionalInfoEditorState}
                                                          stripPastedStyles={true}
                                                          toolbarClassName="toolbarClassName"
                                                          wrapperClassName="wrapperClassName"
                                                          editorClassName="editorClassName"
                                                          handleBeforeInput={(e) => this.handleAdditionalInfoBeforeInput(temp.additionalInfoEditorState)}
                                                          onEditorStateChange={(e) => {
                                                            this.onAdditionalInfoEditorStateChange(index, e);
                                                          }}
                                                          onTab={() => true}
                                                          toolbar={{
                                                            options: ["list"],
                                                            list: { inDropdown: false, options: ["unordered"] },
                                                          }}
                                                        />
                                                      </div>
                                                      <span class="suffix-text">
                                                        {temp.additionalInfoEditorState.getCurrentContent().getPlainText().trim().length}/150 Characters
                                                      </span>
                                                    </div>
                                                    {/* <span className="errorMsg">
                                                      {storyErrors[index].additionalInfoEditorState}
                                                    </span> */}
                                                  </div>
                                                </div>

                                                {/* Additional Info end  */}
                                              </div>
                                              <span
                                                className={
                                                  this.state.storyMapData.length - 1 === index
                                                    ? "add-delete-actions"
                                                    : "add-delete-actions single-action"
                                                }
                                              >
                                                {" "}
                                                {/*add className="add-delete-actions single-action" when we are showing one action */}
                                                {this.state.storyMapData.length - 1 === index ? (
                                                  this.state.country.length - 1 === index ? (
                                                    // <span
                                                    //   className="add-icon"
                                                    //   id="disabled"
                                                    //   disabled
                                                    //   data-tip
                                                    //   data-for="storymap-tooltip"
                                                    // ></span>
                                                    <span
                                                      className="add-icon"
                                                      onClick={this.stroyMapAddClick}
                                                    ></span>
                                                  ) : (
                                                    <span
                                                      className="add-icon"
                                                      onClick={this.stroyMapAddClick}
                                                    ></span>
                                                  )
                                                ) : (
                                                  ""
                                                )}
                                                {/* <ReactTooltip
                                                  className="menulist-tooltips"
                                                  id="storymap-tooltip"
                                                  effect="solid"
                                                  place="bottom"
                                                >
                                                  <span>
                                                    {i18n.t("maximum_regions_alert_message")}                                                   
                                                  </span>
                                                </ReactTooltip> */}
                                                <span
                                                  className="delete-icon"
                                                  onClick={this.onStoryDelete(index)}
                                                ></span>
                                              </span>
                                            </div>
                                            <div className="empty-space"></div>
                                          </>
                                        );
                                      })}
                                    </div>
                                    <div className="dragdrop-footer editBOI-btns">
                                      <button
                                        className="btn btn-default mr-2"
                                        onClick={this.handleStoryCancel}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        className="btn btn-primary"
                                        onClick={this.storySubmit}
                                        disabled={this.state.isStorySubmitDisabled}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </>
                                ))}
                              {!this.state.flag && !configureKeyOutcomes && (
                                <>
                                  {this.state.items.length === 0 ? (
                                    <>{this.handleAdd()}</>
                                  ) : (
                                    <>
                                      <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
                                        {items.map((value, index) => {
                                          return (
                                            <SortableItem
                                              key={`item-${index}`}
                                              index={index}
                                              value={value}
                                              sortIndex={index}
                                              length={this.state.items.length}
                                              handleAdd={this.handleAdd}
                                              handleDelete={this.handleDelete(index)}
                                              handleDescChange={this.handleDescChange(index)}
                                              handleDate={this.handleDate(index)}
                                              startDate={value.year}
                                              errors={this.state.errors}
                                              editorState={
                                                this.state.milestonesEditorStates[index].editorState
                                              }
                                              onEditorStateChange={this.onEditorStateChange}
                                              handleBeforeInput={this.handleBeforeInput}
                                            />
                                          );
                                        })}
                                      </SortableContainer>
                                      {/* Key milestone */}
                                      <div className="dragdrop-footer editBOI-btns">
                                        <button
                                          className="btn btn-default mr-2"
                                          onClick={this.handleCancel}
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          className="btn btn-primary"
                                          onClick={this.handleSubmit}
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                              {configureKeyOutcomes && (
                                <>
                                  <SortableContainer
                                    onSortEnd={this.onSortKeyOutcomes}
                                    useDragHandle
                                  >
                                    {keyOutcomesDeliveredFiltered.length > 0 &&
                                      keyOutcomesDeliveredFiltered.map((each, index) => (
                                        <KeyOutcomesForm
                                          key={`item-${index}`}
                                          desc={each.OutcomeDescription_En}
                                          index={index}
                                          sortIndex={index}
                                          uniqueId={each.uniqueId}
                                          dataLength={keyOutcomesDeliveredFiltered.length}
                                          addKeyOutcomes={this.addKeyOutcomesDelivered}
                                          deleteKeyOutcomes={this.deleteKeyOutcomesDelivered}
                                          outcomeDescriptionChange={this.outcomeDescriptionChange}
                                          error={each.error}
                                        />
                                      ))}
                                  </SortableContainer>
                                  <div className="dragdrop-footer editBOI-btns">
                                    <button
                                      className="btn btn-default mr-2"
                                      onClick={this.keyOutcomesCancel}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      className="btn btn-primary"
                                      onClick={this.keyOutcomesSubmit}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </>
                              )}
                            </>
                          )
                        ) : (
                          <>
                            {!isApiLoading ? (
                              keyOutcomesDeliveredOriginalRender.length == 0 ? (
                                <div className="overview-milestone-wrapper">
                                <div className="default-msg no-keyOutcomes-wrapper">
                                  {this.state.showStoryDefaultMessage}
                                </div>
                                </div>
                              ) : (
                                <div className="overview-milestone-wrapper">
                                  <div className="default-msg">
                                    {this.state.showStoryDefaultMessage}
                                  </div>
                                  {/*  <div className="outcomes-delivered-wrapper">
                                    <h2>Key Outcomes: </h2>
                                    {keyOutcomesDeliveredOriginalRender.length > 0 ? (
                                      <>
                                        <ul>
                                          {keyOutcomesDeliveredOriginalRender.map((each) => (
                                            <li key={each.OutcomeID}>{each.OutcomeDescription}</li>
                                          ))}
                                        </ul>
                                        {maxPageNo !== 1 && (
                                          <span className="view-more">
                                            <span>
                                              <img
                                                width="20"
                                                height="20"
                                                src={
                                                  currentPage === 1
                                                    ? keyoutcomesUpGrey
                                                    : keyoutcomesDownArrow
                                                }
                                                className={`previous-up ${
                                                  currentPage === 1 ? `disabled` : ``
                                                }`}
                                                onClick={
                                                  currentPage !== 1
                                                    ? this.handleBack
                                                    : "javascript:void(0);"
                                                }
                                              />
                                              <img
                                                width="20"
                                                height="20"
                                                src={
                                                  maxPageNo === currentPage
                                                    ? keyoutcomesUpGrey
                                                    : keyoutcomesDownArrow
                                                }
                                                className={`next-down ${
                                                  maxPageNo === currentPage ? `disabled` : ``
                                                } ml-1`}
                                                onClick={
                                                  maxPageNo !== currentPage
                                                    ? this.handleNext
                                                    : "javascript:void(0);"
                                                }
                                              />
                                            </span>
                                          </span>
                                        )}
                                      </>
                                    ) : (
                                      <p> {i18n.t("Key_Outcomes_not_available")}</p>
                                    )}
                                  </div> */}
                                </div>
                              )
                            ) : null}
                          </>
                        )}
                      </div>
                    </Tab>
                   
                    <Tab eventKey="BeforeAfterMindMap" title="Before After Mind Map">
                      <div className="tablayout-content">
                        {clientData.id ? (
                          <BeforeAfterUI
                            configureBeforeAfterMindClick={this.configureBeforeAfterMindClick}
                            BeforeAfterMindMap={BeforeAfterMindMap}
                            setBeforAfterPostApiStatus={this.setBeforAfterPostApiStatus}
                            postBeforeAfterApi={postBeforeAfterApi}
                          />
                        ) : (
                          ""
                        )}
                        {!clientData.id && !isApiLoading ? (
                          <div className="default-msg">{"Please select a client to continue"}</div>
                        ) : (
                          ""
                        )}
                        {/* <BeforeAfterDetail /> */}
                      </div>
                    </Tab>
                    <Tab eventKey="BOIMindMap" title="BOI Mind Map">
                      <div className="tablayout-content boi-tablayoutspace">
                        {/* {selectedTab === 'BOIMindMap' && ( */}
                        <ClientStoryMindMap
                          userHasEditAccess={this.hasEditAccess()}
                          isInEditMode={isVisibleCancelAndSaveBOIMindMapButtons}
                          doSaveBOIMindMap={doSaveBOIMindMap}
                          onCallbackLoadStatusOfMindMap={(status) =>
                            this.handleCallbackLoadStatusOfBOIMindMap(status)
                          }
                          onCallbackAfterSaveMindMap={(isSaveExecutionSuccessful) =>
                            this.handleCallbackAfterSaveBOIMindMap(isSaveExecutionSuccessful)
                          }
                          onCallbackEnableDisableSaveMindMapButton={(isEnabled) =>
                            this.handleCallbackEnableDisableSaveBOIMindMapButton(isEnabled)
                          }
                        />
                        {/* )} */}
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  languageData: state.CurrentFilter.languageData,
  clientData: state.CurrentFilter.ClientData,
  roleData: state.roleReducers.roleReducers,
  viewData: state.BeforeAfterMindMapReducer.viewData,
  dealId: state.CurrentFilter.DealID,
});

const mapDispatchToProps = (dispatch) => ({
  setConfigureMode: (data) => dispatch(BeforeAfterActions.setConfigureMode(data)),
  saveConfigData: (data) => dispatch(BeforeAfterActions.saveConfigData(data)),
});
ClientStory.propTypes = {
  setConfigureMode: PropTypes.func.isRequired,
  saveConfigData: PropTypes.func.isRequired,
  viewData: PropTypes.object.isRequired,
  mindMapPostData: PropTypes.object.isRequired,
  clientData: PropTypes.object.isRequired,
  // BeforeAfterMindMap: PropTypes.object.isRequired,
  // roleData: PropTypes.object.isRequired,
  // languageData: PropTypes.object.isRequired,
  // handleForm: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientStory);