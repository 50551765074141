import React, { Component } from 'react';
import { LocalApiBaseUrl } from '../Shared/Constant';
import { Line} from 'react-chartjs-2';
import DatePicker from "react-datepicker";
import Moment from 'moment';

import Chart from 'chart.js';
import 'chartjs-plugin-datalabels'
import "react-datepicker/dist/react-datepicker.css";
import { Modal, Button } from 'react-bootstrap';
import axiosInstance from '../Shared/interceptor';
import { months } from 'moment';
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class DayWiseGraph extends Component {
    constructor(props) {
        super(props);
    }


    handleSave() {
        
        if (this.validateForm()) {
            this.GetWeekData();
        }
    }
    
    componentDidMount() {
      
    }

    render() {
        
        let kpiMUnit = this.props.kpiUnit;
       
        return (                     
            <div className="whiteBgGraph mt-2 mb-4">
            <Line data={this.props.dsKpiData} 
                            options={{  maintainAspectRatio: true, 
                            responsive: true  
                            ,scales: {
                                yAxes: [{
                                    ticks: {
                                        precision:1
                                    },
                                    gridLines: {
                                        drawOnChartArea: false
                                    },
                                    
                                    scaleLabel: {
                                        display: true,                                       
                                        labelString:"Number of Visitor"
                                      },
                                      datalabels: {
                                        display: false,
                                      }
                                     
                                }],
                                xAxes: [{
                                    gridLines: {
                                        drawOnChartArea: false
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Time'
                                      },
                                      datalabels: {
                                        display: false,
                                      },
                                      stripLines:[
                                        {                
                                            startValue:1935,
                                            endValue:1945,                
                                            color:"#d8d8d8"                      
                                        }
                                        ],
                                }]
                            }                            
                            , legend: { display: false } }} />

                </div>
           
        )
    }
}