import React, { Component } from "react";
import _ from "lodash";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { withRouter, Redirect, NavLink } from "react-router-dom";
import { ROUTE_CONSTANTS, LocalApiBaseUrl } from "../Shared/Constant";
import SynopsLoader from "../Shared/SynopsLoader";
import ViewAssessmentDetails from "./ViewAssessmentDetails";
import LoadingOverlay from "react-loading-overlay";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";

class ViewAssessment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAPILoading: false,
      search: "",
      filteredAssessments: [],
      filteredSharedAssessments: [],
      totalAssessments: [],
      totalSharedAssessments: [],
      clientNameFromTakeAssess: _.get(props, "location.state.currentlySelectedClientName", ""),
      parentFlowInitiationScreen: _.get(props, "location.state.viewAssessmentScreenData.flowInitiatedScreen", ""),
      key: "myAssessment",
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const viewAssessmentScreenData = _.get(location, "state.viewAssessmentScreenData", {});
    if (!_.isEmpty(viewAssessmentScreenData)) {
      this.getAssessmentData();
      this.getSharedAssessmentData();
    }
  }
  handleCallbackShareModalSuccClose = () => {
    const { key } = this.state;
    if (key === "myAssessment") {
      this.getAssessmentData();
    } else {
      this.getSharedAssessmentData();
    }
  };

  handleCallbackBulkShareModalSuccClose = () => {
    const { key } = this.state;
    if (key === "myAssessment") {
      this.getAssessmentData();
    } else {
      this.getSharedAssessmentData();
    }
  };

  //api call to get shared assesment data
  getSharedAssessmentData() {
    let filteredAssessments = this.state.totalAssessments;

    this.setState({ isAPILoading: true });
    axiosInstance
      .get(`${LocalApiBaseUrl}BATAssessment/GetSharedBATAssessmentsForCollaboration`)
      .then((response) => {
        let responseData = response.data;
        
        if (responseData) {
          filteredAssessments = responseData.filter((client) => {
            if (client.BATClientName.toLowerCase().indexOf(this.state.search.toLowerCase().trim()) !== -1) {
              return client;
            }
            return null;
          });

          this.setState({
            isAPILoading: false,
            totalSharedAssessments: responseData,
            filteredSharedAssessments: filteredAssessments,
          });
        }
      })
      .catch((error) => {
        this.setState({ isAPILoading: false }, () => {
          trycatchAlertPopup(error);
        });
      });
  }

  // api call to get the Total View Assessment Data
  getAssessmentData() {
    let filteredAssessments = this.state.totalAssessments;

    this.setState({ isAPILoading: true });
    axiosInstance
      .get(`${LocalApiBaseUrl}BATAssessment/GetClientAssessmentViewDetails`)
      .then((response) => {
        const responseData = response.data;
        
        filteredAssessments = responseData.filter((client) => {
          if (client.BATClientName.toLowerCase().indexOf(this.state.search.toLowerCase().trim()) !== -1) {
            return client;
          }
          return null;
        });

        this.setState({
          isAPILoading: false,
          totalAssessments: responseData,
          filteredAssessments: filteredAssessments,
        });
      })
      .catch((error) => {
        this.setState({ isAPILoading: false }, () => {
          trycatchAlertPopup(error);
        });
      });
  }

  // Filtering the view assessments details based on searched input
  onSearch = (e) => {
    if (this.state.key === "collaboratedAssessment") {
      let filteredSharedAssessments = this.state.totalSharedAssessments;
      filteredSharedAssessments = filteredSharedAssessments.filter((client) => {
        if (client.BATClientName.toLowerCase().indexOf(e.target.value.toLowerCase().trim()) !== -1) {
          return client;
        }
        return null;
      });

      this.setState({
        search: e.target.value,
        filteredSharedAssessments: filteredSharedAssessments,
      });
    } else {
      let filteredAssessments = this.state.totalAssessments;
      filteredAssessments = filteredAssessments.filter((client) => {
        if (client.BATClientName.toLowerCase().indexOf(e.target.value.toLowerCase().trim()) !== -1) {
          return client;
        }
        return null;
      });

      this.setState({
        search: e.target.value,
        filteredAssessments: filteredAssessments,
      });
    }
  };

  // Deleting Client or Assessment record
  onDelete = (e, Details, type) => {
    e.stopPropagation();

    if (window.confirm("Are you sure you want to delete the item?")) {
      if (this.state.key === "collaboratedAssessment") {
        if (type === "Client") {
          axiosInstance
            .delete(
              `${LocalApiBaseUrl}BATAssessment/DisableSharedAssessmentClientLevel?BatAsessClientId=${Details.BATAssessClientID}`
            )
            .then((response) => {
              window.alert(response.data.StatusMessage);
              this.setState(
                {
                  filteredSharedAssessments: [],
                  clientNameFromTakeAssess: "",
                },
                () => this.getSharedAssessmentData()
              );
            })
            .catch((error) => {
              trycatchAlertPopup(error);
            });
          // window.alert("Client deleted successfully");
        } else {
          axiosInstance
            .delete(
              `${LocalApiBaseUrl}BATAssessment/DisableSharedAssessmentSubOfferingLevel?BATAssessSubOfferingID=${Details.BATAssessSubOfferingID}`
            )
            .then((response) => {
              window.alert(response.data.StatusMessage);
              this.getSharedAssessmentData();
            })
            .catch((error) => {
              trycatchAlertPopup(error);
            });
          // window.alert("Assessment deleted successfully");
        }
      } else {
        if (type === "Client") {
          axiosInstance
            .delete(`${LocalApiBaseUrl}BATAssessment/DisableAssessmentClientLevel`, {
              params: { BatAsessClientId: Details.BATAssessClientID },
            })
            .then((response) => {
              window.alert(response.data.StatusMessage);
              this.setState(
                {
                  filteredAssessments: [],
                  clientNameFromTakeAssess: "",
                },
                () => this.getAssessmentData()
              );
            })
            .catch((error) => {
              trycatchAlertPopup(error);
            });    
          // window.alert("Client deleted successfully");
        } else {
          axiosInstance
            .delete(`${LocalApiBaseUrl}BATAssessment/DisableAssessmentSubOfferingLevel`, {
              params: {
                BatAssessSubOfferingId: Details.BATAssessSubOfferingID,
              },
            })
            .then((response) => {
              window.alert(response.data.StatusMessage);
              this.getAssessmentData();
            })
            .catch((error) => {
              trycatchAlertPopup(error);
            });
          // window.alert("Assessment deleted successfully");
        }
      }
    }
  };

  // EVENT HANDLER FOR SUB-OFFERING CLICK
  handleClickSubOffering = (clientDetails, offeringDetails, subOfferingDetails,createdBy) => {
    const {
      history,
      location: {
        state: { viewAssessmentScreenData},
      },
    } = this.props;
    let currentUserData = subOfferingDetails.SharedUsers.filter(
      (eachval) => eachval.UserName === sessionStorage.getItem("EnterpriseID")
    ).length
      ? subOfferingDetails.SharedUsers.filter(
          (eachval) => eachval.UserName === sessionStorage.getItem("EnterpriseID")
        )[0]
      : {};


    const subOfferingDataCollectionPercentage = subOfferingDetails.CollectionStatus;

    // BATAssesmentClientOfferingMappingID
    const clientName = clientDetails.BATClientName;
    const clientIndustry = clientDetails.BATIndustryName;
    const clientMarketUnit = clientDetails.BATGeographyName;
    const offeringName = offeringDetails.BATOfferingName;
    const offeringType = offeringDetails.BATOfferingType;
    const subOfferingId = subOfferingDetails.BATSubOfferingID;
    const subOfferingName = subOfferingDetails.BATSubOfferingName;
    const BATAssessSubOfferingID = subOfferingDetails.BATAssessSubOfferingID;
    const isSubOfferingTVEEnabled = subOfferingDetails.IsTVEEnabled;
    // const isSubOfferingTVEEnabled = false;
    const BATAssessClientID = clientDetails.BATAssessClientID;
    const startDate = subOfferingDetails.StartDate; // to be passed dynamically
    const endDate = subOfferingDetails.EndDate; // to be passed dynamically
    const clientCount = viewAssessmentScreenData.clientCount;
    const ClientLogo = clientDetails.ClientLogo ? clientDetails.ClientLogo : null;

    let redirectLocationData = {};
    let redirectScreenData = {
      clientName,
      clientIndustry,
      clientMarketUnit,
      offeringName,
      offeringType,
      subOfferingId,
      subOfferingName,
      isSubOfferingTVEEnabled,
      BATAssessSubOfferingID,
      BATAssessClientID,
      startDate,
      endDate,
      clientCount,
      ClientLogo,
      createdBy
    };

    // IF THERE HAS BEEN NO METRIC DATA COLLECTED FOR THE SUB-OFFERING THEN REDIRECT TO "PROVIDE METRIC DETAILS FOR ASSESSMENT" SCREEN, ELSE REDIRECT TO "CLIENT PERFORMANCE VIEW" SCREEN
    if (subOfferingDataCollectionPercentage === 0) {
      if (this.state.key === "myAssessment") {
        redirectLocationData = {
          pathname: `/${ROUTE_CONSTANTS.BAT_PROVIDE_METRIC_DETAILS_FOR_ASSESSMENT}`,
          state: { assessmentMetricDetailsScreenData: redirectScreenData },
        };
      } else {
        redirectLocationData = {
          pathname: `/${ROUTE_CONSTANTS.BAT_PROVIDE_METRIC_DETAILS_FOR_ASSESSMENT}`,
          state: {
            assessmentMetricDetailsScreenData: redirectScreenData,
            sharedAccessDetail: currentUserData,
          },
        };
      }
    } else {
      if (this.state.key === "myAssessment") {
        redirectLocationData = {
          pathname: `/${ROUTE_CONSTANTS.BAT_CLIENT_PERFORMANCE_VIEW}`,
          state: { clientPerformanceViewScreenData: redirectScreenData },
        };
      } else {
        redirectLocationData = {
          pathname: `/${ROUTE_CONSTANTS.BAT_CLIENT_PERFORMANCE_VIEW}`,
          state: {
            clientPerformanceViewScreenData: redirectScreenData,
            sharedAccessDetail: currentUserData,
          },
        };
      }
    }

    history.push(redirectLocationData);
  };

  setKey(k) {
    this.setState({
      key: k,
    });
  }

  handleAssessmentOptionClick = (selectedScreenName) => {
    const { history } = this.props;

    let location = {};
    if (selectedScreenName === "takeAssessment") {
      this.redirectToTakeAssessmentScreen();
    } else if (selectedScreenName === "accessManagement") {
      location = {
        pathname: `/${ROUTE_CONSTANTS.BAT_ACCESS_MANAGEMENT}`,
      };
      history.push(location);
    }
  };

  redirectToTakeAssessmentScreen = (screenData = {}) => {
    const { history } = this.props;
    const { parentFlowInitiationScreen } = this.state;

    const viewAssessmentScreenData = _.get(this.props, "location.state.viewAssessmentScreenData", {});
    const renderedFromScreenData = _.get(this.props, "location.state.renderedFromScreenData", {});

    const clientCount = viewAssessmentScreenData.clientCount;
    const flowInitiatedScreen = "viewAssessment";

    const location = {
      pathname: `/${ROUTE_CONSTANTS.BAT_TAKE_ASSESSMENT}`,
      state: {
        ...screenData,
        flowInitiatedScreen,
        clientCount,
        parentFlowInitiationScreen,
        renderedFromScreenData,
      },
    };

    history.push(location);
  };

  render() {
    const { location } = this.props;
    const {
      isAPILoading,
      search,
      filteredAssessments,
      filteredSharedAssessments,
      clientNameFromTakeAssess,
      key,
    } = this.state;

    const viewAssessmentScreenData = _.get(location, "state.viewAssessmentScreenData", {});
    // IF THE SCREEN DATA IS EMPTY, THEN REDIRECT TO LANDING SCREEN
    if (_.isEmpty(viewAssessmentScreenData)) {
      return <Redirect to={`/${ROUTE_CONSTANTS.BAT_LANDING_PAGE}`} />;
    }

    return (
      <div className="view-assessment">
        <LoadingOverlay
          className="custom-loader"
          fadeSpeed={0}
          spinner={<SynopsLoader />}
          active={isAPILoading}
          // text="Loading SynOps …"
        />

        <div className="view-assessment-header">
          <div className="header-list">
            <NavLink to={`/${ROUTE_CONSTANTS.BAT_LANDING_PAGE}`}>
              <span className="backBtn">
                <i className="fas fa-chevron-left"></i> Back
              </span>
            </NavLink>
            <span className="assessText">Assessments</span>
          </div>

          <div className="header-list-right">
            <div className="searchFilter">
              <input type="search" placeholder="Search" value={search} onChange={this.onSearch} />
            </div>
            
            <div className="assessment-actiondd assessment-dropdown-btn">
              <Dropdown>
                <Dropdown.Toggle id="assessment-dropdown">Actions</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey="takeAssessment"
                    onSelect={() => this.handleAssessmentOptionClick("takeAssessment")}
                  >
                    Take Assessment
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="accessManagement"
                    onSelect={() => this.handleAssessmentOptionClick("accessManagement")}
                  >
                    Access Management
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="assessment-tabs-section">
          <Tabs id="controlled-assessment-tabs" activeKey={key} onSelect={(k) => this.setKey(k)}>
            <Tab eventKey="myAssessment" title="My Assessments">
              {_.isEmpty(filteredAssessments) ? (
                <div className="outcome-highlight">
                  <h6>You haven't taken any Assessment yet</h6>
                </div>
              ) : (
                <div className="view-assessment-accordion">
                  <div id="accordion">
                    {filteredAssessments.map((each, index) => {
                      return (
                        <ViewAssessmentDetails
                          defaultclientName={clientNameFromTakeAssess}
                          sharingMode="share"
                          tabName={key}
                          onShareModalSuccClose={this.handleCallbackShareModalSuccClose}
                          onBulkShareModalSuccClose={this.handleCallbackBulkShareModalSuccClose}
                          ClientInfo={each}
                          index={index}
                          onEdit={(clientDetails) => this.redirectToTakeAssessmentScreen({clientDetails})}
                          onDelete={this.onDelete}
                          onAssessmentAdd={(clientDetails) => this.redirectToTakeAssessmentScreen({clientDetails})}
                          onClickSubOffering={(clientDetails, offeringDetails, subOfferingDetails,createdBy) =>
                            this.handleClickSubOffering(clientDetails, offeringDetails, subOfferingDetails,createdBy)
                          }
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </Tab>
            <Tab eventKey="collaboratedAssessment" title="Collaborated Assessments">
              {_.isEmpty(filteredSharedAssessments) ? (
                <div className="outcome-highlight">
                  <h6>No Assessment has been shared to you yet</h6>
                </div>
              ) : (
                <div className="view-assessment-accordion">
                  <div id="accordion">
                    {filteredSharedAssessments.map((each, index) => {
                      return (
                        <ViewAssessmentDetails
                          defaultclientName={clientNameFromTakeAssess}
                          onShareModalSuccClose={this.handleCallbackShareModalSuccClose}
                          onBulkShareModalSuccClose={this.handleCallbackBulkShareModalSuccClose}
                          sharingMode="reshare"
                          ClientInfo={each}
                          index={index}
                          tabName={key}
                          onEdit={(clientDetails) => this.redirectToTakeAssessmentScreen({clientDetails})}
                          onDelete={this.onDelete}
                          onAssessmentAdd={(clientDetails) => this.redirectToTakeAssessmentScreen({clientDetails})}
                          onClickSubOffering={(clientDetails, offeringDetails, subOfferingDetails,createdBy) =>
                            this.handleClickSubOffering(clientDetails, offeringDetails, subOfferingDetails,createdBy)
                          }
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default withRouter(ViewAssessment);
