import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import Dropdown from "react-bootstrap/Dropdown";
import { Modal, Popover, OverlayTrigger } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import LoadingOverlay from "react-loading-overlay";
import classNames from "classnames";
import moment from "moment";
import $ from "jquery";

// IMPORT CUSTOM COMPONENTS/FUNCTIONS
import BatCommonHeader from "./BatCommonHeader";
import MetricParametersModal from "./MetricParametersModal";
import MetricInfoModal from "./MetricInfoModal";
import ConfirmDeleteModalPopup from "../Shared/ConfirmDeleteModalPopup";
import SynopsLoader from "../Shared/SynopsLoader";
import {
  LocalApiBaseUrl,
  ROUTE_CONSTANTS,
  isParamMappingAvailableForBATOfferingSubOffering,
  BATFormattedDefaultStartDate,
  BATFormattedDefaultEndDate,
  BATDateFormat,
  validateBATFieldValue,
  getFormattedUnitNameBAT as getFormattedUnitName,
} from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";

// IMPORT IMAGES
import Refresh from "../Images/bat_icons/Refresh.svg";
import Info from "../Images/tve_Icons/Info.svg";
import Info_icon_hover from "../Images/tve_Icons/Info.svg";
import Info_icon_normal from "../Images/tve_Icons/Card_Info.svg";
import Calculator_icon_normal from "../Images/tve_Icons/Calculator_Normal.svg";
import Calculator_icon_hover from "../Images/tve_Icons/Calculator_Hover.svg";
import Calculator_icon_incomplete_normal from "../Images/tve_Icons/Calculator_Incompleted_Normal.svg";
import Calculator_icon_incomplete_hover from "../Images/tve_Icons/Calculator_Incompleted_Hover.svg";
import Calculator_icon_completed_normal from "../Images/tve_Icons/Calculator_Completed_Normal.svg";
import Calculator_icon_completed_hover from "../Images/tve_Icons/Calculator_Completed_Hover.svg";
import TVE_icon_normal from "../Images/tve_Icons/VM_Normal.svg";
import TVE_icon_hover from "../Images/tve_Icons/VM_Hover.svg";
import TVE_icon_incomplete_normal from "../Images/tve_Icons/VM_Incompleted_Normal.svg";
import TVE_icon_incomplete_hover from "../Images/tve_Icons/VM_Incompleted_Hover.svg";
import TVE_icon_completed_normal from "../Images/tve_Icons/VM_Completed_Normal.svg";
import TVE_icon_completed_hover from "../Images/tve_Icons/VM_Completed_Hover.svg";

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


// FROM THE INPUT METRIC PARAMETERS, RETURN THE NO. OF TOTAL FILLED AND EMPTY PARAMETERS AND PERCENTAGE OF DATA COLLECTION
function getMetricParametersStatusData(metricParameters) {
  const totalParameters = metricParameters.length;
  let parametersDataCollectionPercentage = 0;
  let totalFilled = 0;
  let totalEmpty = totalParameters;
  if (!_.isEmpty(metricParameters)) {
    const parametersWithFilledValue = _.filter(metricParameters, (eachParameter) => {
      const enteredValue = _.trim(String(eachParameter.enteredValue));
      return !_.isEmpty(enteredValue);
    });

    totalFilled = parametersWithFilledValue.length;
    totalEmpty = totalParameters - totalFilled;
    parametersDataCollectionPercentage = _.ceil((totalFilled * 100) / totalParameters);
  }

  return {
    totalParameters,
    totalFilled,
    totalEmpty,
    collectionPercentage: parametersDataCollectionPercentage,
  };
}

// FROM THE INPUT METRIC VOLUMETRIC, RETURN THE NO. OF TOTAL FILLED AND EMPTY VOLUMETRIC AND PERCENTAGE OF DATA COLLECTION
function getMetricVolumetricsStatusData(metricVolumetrics) {
  const totalVolumetrics = metricVolumetrics.length;
  let volumetricDataCollectionPercentage = 0;
  let totalFilled = 0;
  let totalEmpty = totalVolumetrics;
  if (!_.isEmpty(metricVolumetrics)) {
    const volumetricWithFilledValue = _.filter(metricVolumetrics, (eachVolumetric) => {
      const enteredValue = _.trim(String(eachVolumetric.enteredValue));
      return !_.isEmpty(enteredValue);
    });

    totalFilled = volumetricWithFilledValue.length;
    totalEmpty = totalVolumetrics - totalFilled;
    volumetricDataCollectionPercentage = _.ceil((totalFilled * 100) / totalVolumetrics);
  }

  return {
    totalVolumetrics,
    totalFilled,
    totalEmpty,
    collectionPercentage: volumetricDataCollectionPercentage,
  };
}

const volumetricModalSubHeaderText = "Additional inputs needed to calculate the size of the opportunity";
const contractPeriodDefaultValue = 5; // contract period default value for TVE Enabled sub-offerings
class ProvideMetricDetailsForAssessment extends Component {
  constructor(props) {
    super(props);
    const assessmentMetricDetailsScreenData = _.get(props, "location.state.assessmentMetricDetailsScreenData", {});
    const startDateFromProps = _.get(assessmentMetricDetailsScreenData, "startDate", "");
    const endDateFromProps = _.get(assessmentMetricDetailsScreenData, "endDate", "");

    const offeringName = _.get(assessmentMetricDetailsScreenData, "offeringName", "");
    const offeringType = _.get(assessmentMetricDetailsScreenData, "offeringType", "");
    const subOfferingId = _.get(assessmentMetricDetailsScreenData, "subOfferingId", "");
    const subOfferingName = _.get(assessmentMetricDetailsScreenData, "subOfferingName", "");
    const startDate = !_.isEmpty(startDateFromProps) ? startDateFromProps : BATFormattedDefaultStartDate;
    const endDate = !_.isEmpty(endDateFromProps) ? endDateFromProps : BATFormattedDefaultEndDate;
    const clientCount = _.get(assessmentMetricDetailsScreenData, "clientCount", "");
    const clientName = _.get(assessmentMetricDetailsScreenData, "clientName", "");
    const ClientLogo = _.get(assessmentMetricDetailsScreenData, "ClientLogo", "");
    const clientIndustry = _.get(assessmentMetricDetailsScreenData, "clientIndustry", "");
    const clientMarketUnit = _.get(assessmentMetricDetailsScreenData, "clientMarketUnit", "");
    const BATAssessSubOfferingID = _.get(assessmentMetricDetailsScreenData, "BATAssessSubOfferingID", "");
    const BATAssessClientID = _.get(assessmentMetricDetailsScreenData, "BATAssessClientID", "");
    const isSubOfferingTVEEnabled = _.get(assessmentMetricDetailsScreenData, "isSubOfferingTVEEnabled", false);

    const isParametersMappingAvailable = isParamMappingAvailableForBATOfferingSubOffering(
      offeringName,
      subOfferingName
    );
    this.state = {
      // IF THE MAPPING IS NOT AVAILABLE THEN IN THE "PARAMETER VIEW" TAB, A MESSAGE WOULD BE SHOWN ACCORDINGLY AND FOR SUCH METRICS ONLY MANUALLY ENTERED VALUES WOULD BE ACCEPTED AS THE CALCULATED VALUES CANNOT BE FETCHED IN ABSENCE OF PARAMETERS MAPPING

      isAPILoading: false,
      isParametersMappingAvailable,
      offeringName,
      offeringType,
      subOfferingId,
      subOfferingName,
      startDate,
      endDate,
      clientCount,
      clientName,
      ClientLogo,
      clientIndustry,
      clientMarketUnit,
      BATAssessSubOfferingID,
      BATAssessClientID,
      isSubOfferingTVEEnabled,
      overallDataCollectionPercentage: 0,
      isVisibleSaveParametersConfirmationModal: false,
      isVisibleMetricParametersModal: false,
      renderMetricParametersModalFor: "", // Values: metricParameters - renders modal for metric parameters, metricVolumetrics - renders modal for metric volumetrics
      areFormFieldsMandatoryForMetricParametersModal: false,
      subHeaderTextForMetricParametersModal: "",
      metricDataFromBAT: {},
      parameterDataFromSynOps: [],
      dataByMetricCategories: [],
      filteredSynOpsParameters: [],
      assessedSubOfferingAndMetricDetailsFromSynOps: {},
      currentView: isParametersMappingAvailable ? "parameterView" : "metricView", // WHEN PARAMETERS MAPPING IS NOT AVAILABLE FOR THE METRICS, THEN DISABLE THE "PARAMETER VIEW" TAB AND DIRECTLY DISPLAY THE "METRIC VIEW" TAB
      currentlySelectedMetricData: {},
      isVisibleInfoModal: false,
      infoModalTitle: "",
      infoModalDescription: "",
      expandedAccordionsKeysArray: [],
      volumetricDataFromSynOps: [],
      allVolumetricFromSynOps: [],
      contractPeriod: isSubOfferingTVEEnabled ? contractPeriodDefaultValue : "",
      hasUserAcknowledgedInstructionsInfoPopup: false, // FLAG FOR MAINTAINING WHETHER THE USER HAS ACKNOWLEDGED THE INFO POPUP CONTAINING THE INSTRUCTIONS FOR METRIC PARAMETERS AND VOLUMETRICS
      isVisibleInstructionsInfoTooltip: false, // FLAG FOR DISPLAYING THE INFO POPUP CONTAINING THE INSTRUCTIONS FOR METRIC PARAMETERS AND VOLUMETRICS
      areInstructionsToBeDisplayedForAssessment: false, // FLAG WHETHER THE INFO POPUP CONTAINING THE INSTRUCTIONS IS TO BE DISPLAYED FOR THE CURRENT ASSESSMENT OR NOT
      selectedOptionInInstructionsInfoPopup: "",
      isVisibleConfirmDeleteModal: false,
      isCheckedDontShowInstructionsInfoPopup: false,
    };
  }

  componentDidMount() {
    $("html, body").animate({ scrollTop: 0 }, "medium");
    // FETCH METRICS DATA FROM THE API ON SCREEN LOAD
    this.fetchMetricDataFromBAT();
  }

  componentDidUpdate(prevProps, prevState) {
    // IF THE SELECTED CLIENT, OFFERING OR SUB-OFFERING IS CHANGED THEN
    const {
      offeringName,
      subOfferingName,
      clientName,
      isSubOfferingTVEEnabled,
      areInstructionsToBeDisplayedForAssessment,
      hasUserAcknowledgedInstructionsInfoPopup,
      expandedAccordionsKeysArray,
    } = this.state;
    if (
      prevState.offeringName !== offeringName ||
      prevState.subOfferingName !== subOfferingName ||
      prevState.clientName !== clientName
    ) {
      const isParametersMappingAvailable = isParamMappingAvailableForBATOfferingSubOffering(
        offeringName,
        subOfferingName
      );
      this.setState(
        {
          expandedAccordionsKeysArray: [], // COLLAPSE ALL THE ACCORDIONS AND
          currentView: isParametersMappingAvailable ? "parameterView" : "metricView", // WHEN PARAMETERS MAPPING IS NOT AVAILABLE FOR THE METRICS, THEN DISABLE THE "PARAMETER VIEW" TAB AND DIRECTLY DISPLAY THE "METRIC VIEW" TAB,
          isVisibleInstructionsInfoTooltip: false,
          areInstructionsToBeDisplayedForAssessment: false,
        },
        () => {
          this.hideInstructionsInfoPopupForAllMetrics();
        }
      );
    }

    // IF THE STATE VALUE FOR `isSubOfferingTVEEnabled` CHANGES THEN SET/UNSET STATE VALUE FOR `contractPeriod`
    if (prevState.isSubOfferingTVEEnabled !== isSubOfferingTVEEnabled) {
      this.setState({
        contractPeriod: isSubOfferingTVEEnabled ? contractPeriodDefaultValue : "",
      });
    }

    if (
      (prevState.areInstructionsToBeDisplayedForAssessment !== areInstructionsToBeDisplayedForAssessment &&
        !areInstructionsToBeDisplayedForAssessment) ||
      (prevState.hasUserAcknowledgedInstructionsInfoPopup !== hasUserAcknowledgedInstructionsInfoPopup &&
        hasUserAcknowledgedInstructionsInfoPopup) ||
      prevState.expandedAccordionsKeysArray !== expandedAccordionsKeysArray
    ) {
      this.setState(
        {
          isVisibleInstructionsInfoTooltip: false,
        },
        () => {
          this.hideInstructionsInfoPopupForAllMetrics();
        }
      );
    }
  }

  componentWillUnmount() {
    const locationState = this.props.location.state;
    axiosInstance
      .get(
        `${LocalApiBaseUrl}BATAssessment/AddEditLockOnBATAssessment?BATAssessSubOfferingID=${locationState.assessmentMetricDetailsScreenData.BATAssessSubOfferingID}&Status=Save`
      )
      .then((response) => { })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  // EXECUTES THE API REQUEST TO FETCH THE METRICS DATA FOR THE OFFERING AND SUB-OFFERING
  fetchMetricDataFromBAT = () => {
    const { offeringName, subOfferingName, startDate, endDate, clientCount, isSubOfferingTVEEnabled } = this.state;

    if (!_.isEmpty(offeringName)) {
      const requestData = {
        offeringName,
        towerName: subOfferingName,
        timeFrameStartDate: startDate,
        timeFrameEndDate: endDate,
      };
      let APIEndPoint = "GetSubOfferingTVEBenchmarkDetails";

      // IF SUB-OFFERING IS NOT "TVE" ENABLED THEN, CALL A DIFFERENT API ENDPOINT AND ADD `clientCount` IN REQUEST PARAMETERS
      if (!isSubOfferingTVEEnabled) {
        _.set(requestData, "clientCount", _.toNumber(clientCount));
        APIEndPoint = "GetOfferingBenchmarkDetails";
      }

      this.setState({ isAPILoading: true });
      // FETCH THE DATA FROM THE API
      return axiosInstance
        .post(`${LocalApiBaseUrl}BAT/${APIEndPoint}`, requestData)
        .then((response) => {
          let metricDataFromBAT = {};
          if (!_.isEmpty(response.data)) {
            metricDataFromBAT = response.data;

            this.setState(
              {
                isAPILoading: false,
                metricDataFromBAT,
              },
              async () => {
                if (isSubOfferingTVEEnabled) {
                  await this.fetchVolumetricDataFromSynOps();
                }
                this.fetchAssessedSubOfferingAndMetricDetailsFromSynOps();
              }
            );
          } else {
            this.setState({ isAPILoading: false });
            const errMsg = "METRICS DATA IS EMPTY FOR THE SELECTED TIME FRAME";
            
            window.alert(errMsg);
          }
        })
        .catch((error) => {
          this.setState({ isAPILoading: false });
          trycatchAlertPopup(error);
        });
    }
  };

  // EXECUTES THE API REQUEST TO FETCH THE SAVED DATA FOR THE SUB-OFFERING AND METRICS FROM SynOps
  fetchAssessedSubOfferingAndMetricDetailsFromSynOps = () => {
    const { isParametersMappingAvailable, BATAssessSubOfferingID } = this.state;
    this.setState({ isAPILoading: true });

    // FETCH THE DATA FROM THE API
    return axiosInstance
      .get(
        `${LocalApiBaseUrl}BATAssessment/GetAssessedMetricsClientDetailsBasedOnSubOffering?BATAssessSubOfferingID=${BATAssessSubOfferingID}`
      )
      .then((response) => {
        let assessedSubOfferingAndMetricDetailsFromSynOps = {};
        if (!_.isEmpty(response.data)) {
          assessedSubOfferingAndMetricDetailsFromSynOps = response.data[0];
        }
        this.setState(
          {
            isAPILoading: false,
            assessedSubOfferingAndMetricDetailsFromSynOps,
          },
          () => {
            if (isParametersMappingAvailable) {
              this.fetchParametersDataFromSynOps();
            } else {
              this.prepareData();
            }
          }
        );
      })
      .catch((error) => {
        this.setState({ isAPILoading: false });
        trycatchAlertPopup(error);
      });
  };

  // EXECUTES THE API REQUEST TO FETCH THE MAPPED PARAMETERS INTO SynOps FROM THE REQUESTED BAT PARAMETERS
  fetchParametersDataFromSynOps = () => {
    const { BATAssessSubOfferingID, metricDataFromBAT } = this.state;
    let allMetricParametersFromBAT = [];
    const metricDetails = metricDataFromBAT.metricDetails;
    _.forEach(metricDetails, (eachMetric) => {
      const metricParameters = eachMetric.requiredParameterDetails;
      allMetricParametersFromBAT = _.concat(allMetricParametersFromBAT, metricParameters);
    });

    const uniqueMetricParametersFromBAT = _.uniqBy(allMetricParametersFromBAT, "batParameterID");

    const requestData = {
      BATAssessSubOfferingID,
      BATParameterID: _.map(uniqueMetricParametersFromBAT, "batParameterID"),
    };

    this.setState({ isAPILoading: true });

    // FETCH THE DATA FROM THE API
    return axiosInstance
      .post(
        `${LocalApiBaseUrl}BATAssessment/GetAssessedClientMetricSynOpsParameterMappingDetailsBasedOnSubOffering`,
        requestData
      )
      .then((response) => {
        let parametersDataFromSynOps = {};
        if (!_.isEmpty(response.data)) {
          parametersDataFromSynOps = response.data;
          this.setState(
            {
              isAPILoading: false,
              parametersDataFromSynOps,
            },
            () => {
              this.prepareData();
            }
          );
        } else {
          this.setState({ isAPILoading: false });
          const errMsg = "PARAMETERS DATA IS EMPTY FOR PARAMTER VIEW";
          window.alert(errMsg);
        }
      })
      .catch((error) => {
        this.setState({ isAPILoading: false });
        trycatchAlertPopup(error);
      });
  };

  // EXECUTES THE API REQUEST TO FETCH THE VOLUMETRICS THAT ARE CONFIGURED IN SynOps FOR THE METRICS
  fetchVolumetricDataFromSynOps = () => {
    return new Promise((resolve, reject) => {
      const { BATAssessSubOfferingID, metricDataFromBAT } = this.state;

      const requestData = {
        BATAssessSubOfferingID,
        BATMetricID: _.map(metricDataFromBAT.metricDetails, "metricID"),
      };

      this.setState({ isAPILoading: true });

      // FETCH THE DATA FROM THE API
      axiosInstance
        .post(`${LocalApiBaseUrl}BATAssessment/GetSynOpsVoluMetricsMappingDetailsBasedMetrices`, requestData)
        .then((response) => {
          let volumetricDataFromSynOps = {};
          if (!_.isEmpty(response.data)) {
            volumetricDataFromSynOps = response.data;
            this.setState(
              {
                isAPILoading: false,
                volumetricDataFromSynOps,
              },
              () => {
                resolve();
              }
            );
          } else {
            this.setState({ isAPILoading: false });
            const errMsg = "VOLUMETRIC DATA IS EMPTY";
            window.alert(errMsg);
            reject(errMsg);
          }
        })
        .catch((error) => {
          this.setState({ isAPILoading: false });
          trycatchAlertPopup(error);
          reject(error);
        });
    });
  };

  // EXECUTES THE API REQUEST TO SAVE THE ENTERED VALUES OF THE PARAMETERS TO DATABASE
  saveParameterValuesToDB = () => {
    const { subOfferingName, filteredSynOpsParameters, BATAssessSubOfferingID } = this.state;

    const SynOpsParameterDetails = _.map(
      // ONLY SAVE THOSE PARAMETERS TO DB WHOSE VALUE IS NOT EMPTY AND VALID
      _.filter(
        filteredSynOpsParameters,
        (eachItem) => !_.isEmpty(_.trim(String(eachItem.enteredValue))) && eachItem.isValid
      ),
      (eachMappedParameter) => ({
        SynOpsParameterID: eachMappedParameter.SynOpsParameterID,
        UOM: eachMappedParameter.parameterUnit,
        UserEnteredValue: parseFloat(eachMappedParameter.enteredValue),
      })
    );
    const requestData = {
      BATAssessmentSubOfferingMappingID: BATAssessSubOfferingID,
      BATSubOfferingName: subOfferingName,
      SynOpsParameterDetails,
    };

    if (!_.isEmpty(SynOpsParameterDetails)) {
      this.setState({ isAPILoading: true });
      axiosInstance
        .post(`${LocalApiBaseUrl}BATAssessment/AddEditMetricsClientAssessmentSynOpsParameterDetails`, requestData)
        .then((response) => {
          this.setState(
            {
              isAPILoading: false,
              currentView: "metricView",
            },
            () => {
              this.fetchCalculatedMetricValuesFromBAT();
            }
          );
        })
        .catch((error) => {
          this.setState({ isAPILoading: false });
          trycatchAlertPopup(error);
        });
    }
  };

  // EXECUTES THE API REQUEST TO RETRIEVE THE CALCULATED VALUES FOR THE METRICS FROM ENTERED PARAMETERS VALUES
  fetchCalculatedMetricValuesFromBAT = () => {
    const { offeringName, subOfferingName, dataByMetricCategories } = this.state;
    let metricDataForRequest = [];

    // ONLY SEND THOSE METRICS FOR RETRIEVING CALCULATED VALUES FOR WHICH ALL THE PARAMTER VALUES ARE FILLED
    _.forEach(dataByMetricCategories, (eachItem) => {
      const metrics = eachItem.metrics;
      const filteredMetrics = _.map(
        _.filter(metrics, (eachMetric) => {
          let parameterDetails = eachMetric.parameterDetails;
          const parametersWithFilledValue = _.filter(parameterDetails, (eachParameter) => {
            const enteredValue = _.trim(String(eachParameter.enteredValue));
            return !_.isEmpty(enteredValue);
          });

          const totalFilled = parametersWithFilledValue.length;
          const totalParameters = parameterDetails.length;

          return totalParameters > 0 && totalFilled === totalParameters;
        }),
        (eachMappedMetric) => ({
          metricId: eachMappedMetric.metricId,
          metricName: eachMappedMetric.metricName,
          inputParameters: _.map(eachMappedMetric.parameterDetails, (eachMappedParameter) => ({
            BATParameterID: eachMappedParameter.BATParameterID,
            parameterName: eachMappedParameter.BATParameterName,
            parameterValue: parseFloat(eachMappedParameter.enteredValue),
            parameterUOM: eachMappedParameter.parameterUnit,
          })),
        })
      );
      metricDataForRequest = _.concat(filteredMetrics, metricDataForRequest);
    });

    if (!_.isEmpty(metricDataForRequest)) {
      const requestData = {
        offeringName: offeringName,
        towerName: subOfferingName,
        metricDetails: metricDataForRequest,
      };

      this.setState({ isAPILoading: true });

      // FETCH THE DATA FROM THE API
      return axiosInstance
        .post(`${LocalApiBaseUrl}BAT/GetOfferingBenchmarkValues`, requestData)
        .then((response) => {
          let clonedDataByMetricCategories;
          if (!_.isEmpty(response.data)) {
            const calculatedMetricDetails = response.data.metricDetails;

            // SET CALCULATED VALUES FOR METRICS RETURNED FROM BAT INTO THE CORRESPONDING METRICS AND EMPTY THE "MANUALLY" ENTERED VALUE FOR THE METRICS
            clonedDataByMetricCategories = _.cloneDeep(dataByMetricCategories);
            _.forEach(clonedDataByMetricCategories, (eachItem) => {
              _.forEach(eachItem.metrics, (eachMetric) => {
                const foundCalculatedMetricData = _.find(calculatedMetricDetails, ["metricID", eachMetric.metricId]);
                if (!_.isEmpty(foundCalculatedMetricData)) {
                  const calculatedValueOfMetric = foundCalculatedMetricData.metricValue;
                  const isEmptyCalculatedValueOfMetric = _.isNil(calculatedValueOfMetric);
                  const calculatedMetricRemark = foundCalculatedMetricData.remarks;
                  eachMetric.manuallyEnteredValue = "";

                  // IF THE CALCULATED VALUE OF THE METRIC IS EMPTY OR THE `calculatedMetricRemark` (ERROR MESSAGE) IS NOT EMPTY THEN MARK THE METRIC AS INVALID
                  if (isEmptyCalculatedValueOfMetric || !_.isEmpty(calculatedMetricRemark)) {
                    eachMetric.isValid = false;
                    eachMetric.errMsg = !_.isEmpty(calculatedMetricRemark)
                      ? calculatedMetricRemark
                      : "Calculation error";
                    eachMetric.calculatedValue = "";
                    eachMetric.isDisabledMetricInput = false;
                  } else {
                    eachMetric.isValid = true;
                    eachMetric.errMsg = "";
                    eachMetric.calculatedValue = String(calculatedValueOfMetric);
                    eachMetric.isDisabledMetricInput = true;
                  }
                }
              });
            });
          }
          this.setState(
            {
              isAPILoading: false,
              dataByMetricCategories: clonedDataByMetricCategories,
            },
            () => {
              this.updateMetricCategoryDataCompletionPercentage();
            }
          );
        })
        .catch((error) => {
          this.setState({ isAPILoading: false });
          trycatchAlertPopup(error);
        });
    }
  };

  // EXECUTES THE API REQUEST TO SAVE THE ENTERED VALUES OF THE METRICS TO DATABASE
  saveMetricValuesToDB = () => {
    const {
      subOfferingName,
      dataByMetricCategories,
      overallDataCollectionPercentage,
      BATAssessSubOfferingID,
      startDate,
      endDate,
      contractPeriod,
      isSubOfferingTVEEnabled,
    } = this.state;

    let metricDataForRequest = [];

    // ONLY SAVE THOSE METRICS WHOSE EITHER `calculatedValue` OR `manuallyEnteredValue` IS NOT EMPTY AND IS VALID
    _.forEach(dataByMetricCategories, (eachItem) => {
      const metrics = eachItem.metrics;
      const filteredMetrics = _.map(
        _.filter(metrics, (eachMetric) => {
          const ret =
            (!_.isEmpty(_.trim(String(eachMetric.calculatedValue))) ||
              !_.isEmpty(_.trim(String(eachMetric.manuallyEnteredValue)))) &&
            eachMetric.isValid;

          return ret;
        }),
        (eachMappedMetric) => {
          const calculatedValue = eachMappedMetric.calculatedValue;
          const isEmptyCalculatedValue = _.isEmpty(String(calculatedValue));
          const manuallyEnteredValue = eachMappedMetric.manuallyEnteredValue;
          return {
            BATMetricGroup: eachMappedMetric.metricCategory,
            BATMetricID: eachMappedMetric.metricId,
            BATMetricName: eachMappedMetric.metricName,
            BATMetricValueUnit: eachMappedMetric.metricUnit,
            UserEnteredValue: parseFloat(!isEmptyCalculatedValue ? calculatedValue : manuallyEnteredValue),
            IsValueCalculated: !isEmptyCalculatedValue,
            UserEnteredBenchmarkValue: !_.isEmpty(String(eachMappedMetric.userEnteredBenchmarkValue))
              ? parseFloat(eachMappedMetric.userEnteredBenchmarkValue)
              : "",
            BatParameterDetails: _.map(eachMappedMetric.parameterDetails, (eachMappedParameter) => ({
              BATParameterID: eachMappedParameter.BATParameterID,
              BATParameterName: eachMappedParameter.BATParameterName,
              UOM: eachMappedParameter.parameterUnit,
            })),
          };
        }
      );
      metricDataForRequest = _.concat(filteredMetrics, metricDataForRequest);
    });

    if (!_.isEmpty(metricDataForRequest)) {
      const requestData = {
        BATAssessmentSubOfferingMappingID: BATAssessSubOfferingID,
        BATSubOfferingName: subOfferingName,
        StartDate: moment(startDate, BATDateFormat).format("MM/YYYY"),
        EndDate: moment(endDate, BATDateFormat).format("MM/YYYY"),
        DataCollection: overallDataCollectionPercentage,
        MetricDetails: metricDataForRequest,
        ContractPeriod: isSubOfferingTVEEnabled ? _.toNumber(contractPeriod) : "",
      };

      this.setState({ isAPILoading: true });
      // FETCH THE DATA FROM THE API
      return axiosInstance
        .post(`${LocalApiBaseUrl}BATAssessment/AddEditMetricsClientAssessmentDetails`, requestData)
        .then((response) => {
          this.setState(
            {
              isAPILoading: false,
            },
            () => {
              this.redirectToClientPerformanceViewScreen();
            }
          );
        })
        .catch((error) => {
          this.setState({ isAPILoading: false });
          trycatchAlertPopup(error);
        });
    }
  };

  prepareData = () => {
    const {
      isParametersMappingAvailable,
      metricDataFromBAT,
      parametersDataFromSynOps,
      assessedSubOfferingAndMetricDetailsFromSynOps,
      volumetricDataFromSynOps,
      isSubOfferingTVEEnabled,
    } = this.state;
    let allParametersFromSynOps = [];
    let allVolumetricFromSynOps = [];
    let contractPeriod = "";

    // IF SUB-OFFERING IS "TVE" ENABLED THEN GET THE CONTRACT PERIOD VALUE FROM THE API (IF ASSESSMENT DETAILS WERE PREVIOUSLY SAVED). IF THE VALUE IS EMPTY THEN SET THE VALUE TO THE DEFAULT CONTRACT PERIOD VALUE. FOR NON-TVE SUB-OFFERINGS SET THE VALUE TO EMPTY
    if (isSubOfferingTVEEnabled) {
      contractPeriod = _.get(assessedSubOfferingAndMetricDetailsFromSynOps, "ContractPeriod");
      if (_.isEmpty(String(contractPeriod))) {
        contractPeriod = contractPeriodDefaultValue;
      }
    }

    const areInstructionsToBeDisplayedForAssessment = _.get(
      assessedSubOfferingAndMetricDetailsFromSynOps,
      "ShowUserHelpStatus",
      true
    );
    const hasUserAcknowledgedInstructionsInfoPopup = !areInstructionsToBeDisplayedForAssessment;
    const assessedMetricDetails = _.get(assessedSubOfferingAndMetricDetailsFromSynOps, "MetricDetails", []);

    // LOOP OVER METRICS FROM BAT
    let metricDetails = metricDataFromBAT.metricDetails;
    metricDetails = _.map(metricDataFromBAT.metricDetails, (eachMetric) => {
      const metricId = eachMetric.metricID;
      let manuallyEnteredValue = "";
      let calculatedValue = "";
      let isDisabledMetricInput = false;

      let userEnteredBenchmarkValue = "";
      // FOR EACH BAT METRIC, FIND THE ASSESSED METRIC (SAVED METRIC RETRIEVED FROM DB) TO SET THE USER ENTERED VALUE
      const foundAssessedMetric = _.find(assessedMetricDetails, ["BATMetricID", metricId]);
      if (!_.isEmpty(foundAssessedMetric)) {
        const isMetricValueCalculated = foundAssessedMetric.IsValueCalculated;
        const enteredMetricValue = String(foundAssessedMetric.UserEnteredValue);
        userEnteredBenchmarkValue = _.get(foundAssessedMetric, "UserEnteredBenchmarkValue", "");

        if (isMetricValueCalculated) {
          calculatedValue = enteredMetricValue;
          isDisabledMetricInput = true;
        } else {
          manuallyEnteredValue = enteredMetricValue;
        }
      }

      let calculationFormulaParsed = eachMetric.calculationFormula;
      // LOOP OVER EACH BAT PARAMETER AND FIND THE CORRESPONDING SynOps PARAMETER TO CREAT AN ARRAY
      const parameterDetails = _.map(eachMetric.requiredParameterDetails, (eachBATParameter) => {
        let foundSynOpsParameter = {};

        if (isParametersMappingAvailable) {
          foundSynOpsParameter = _.find(
            parametersDataFromSynOps,
            (eachSynOpsParameter) =>
              !_.isEmpty(
                _.find(eachSynOpsParameter.BATParameterDetails, ["BATParameterID", eachBATParameter.batParameterID])
              )
          );

          if (!_.isEmpty(foundSynOpsParameter)) {
            // IN THE METRIC CALCULATION FORMULA STRING WHICH WOULD BE LIKE for eg: (PS194PE/PS1581PE)*100, REPLACE EACH BAT PARAMETER ID WITH THE CORRESPONDING SynOps PARAMETER NAME TO CREATE A PARSED STRING OF CALCULATION FORMULA
            calculationFormulaParsed = _.replace(
              calculationFormulaParsed,
              `PS${eachBATParameter.batParameterID}PE`,
              foundSynOpsParameter.SynOpsParameterName
            );

            const parameterUnit = _.toLower(foundSynOpsParameter.SynOpsUOM);
            // STRUCTURE FOR EACH (SynOps) PARAMETER
            const modifiedFoundSynOpsParameter = {
              SynOpsParameterID: foundSynOpsParameter.SynOpsParameterID,
              parameterName: foundSynOpsParameter.SynOpsParameterName,
              parameterUnit,
              parameterUnitFormatted: getFormattedUnitName(parameterUnit),
              enteredValue: String(foundSynOpsParameter.UserEnteredValue),
              isValid: true,
              isInputFocused: false,
              description: _.replace(foundSynOpsParameter.Description, /- /g, "<br/>- "),
              sequence: foundSynOpsParameter.DisplayOrder,
            };
            allParametersFromSynOps.push(modifiedFoundSynOpsParameter);
          }
        }

        // STRUCTURE FOR EACH PARAMETER INSIDE EACH METRIC
        return {
          BATParameterID: eachBATParameter.batParameterID,
          BATParameterName: eachBATParameter.parameterName,
          SynOpsParameterID: _.get(foundSynOpsParameter, "SynOpsParameterID", ""),
          SynOpsParameterName: _.get(foundSynOpsParameter, "SynOpsParameterName", ""),
          parameterUnit: eachBATParameter.unitOfMeasurement,
          parameterUnitFormatted: getFormattedUnitName(eachBATParameter.unitOfMeasurement),
          enteredValue: String(_.get(foundSynOpsParameter, "UserEnteredValue", "")),
        };
      });

      let volumetricDetails = [];
      if (!_.isEmpty(volumetricDataFromSynOps)) {
        const foundMetric = _.find(volumetricDataFromSynOps, ["BATMetricID", metricId]);

        if (!_.isEmpty(foundMetric)) {
          // loop over volumetric array
          volumetricDetails = _.map(foundMetric.Volumetrics, (eachVolumetric) => {
            const volumetricUnit = _.toLower(eachVolumetric.UOM);

            // STRUCTURE FOR EACH (SynOps) VOLUMETRIC
            const modifiedFoundSynOpsVolumetric = {
              volumetricId: eachVolumetric.VolumetricID,
              volumetricName: eachVolumetric.VolumetricName,
              volumetricSymbol: eachVolumetric.Symbol,
              volumetricUnit,
              volumetricUnitFormatted: getFormattedUnitName(volumetricUnit),
              enteredValue: String(eachVolumetric.Value),
              isValid: true,
              isInputFocused: false,
            };
            allVolumetricFromSynOps.push(modifiedFoundSynOpsVolumetric);

            return modifiedFoundSynOpsVolumetric;
          });
        }
      }
      if (!_.isEmpty(allVolumetricFromSynOps)) {
        allVolumetricFromSynOps = _.uniqBy(allVolumetricFromSynOps, "volumetricId");
      }

      // OMIT UNWANTED PROPERTIES FROM THE METRIC OBJECT
      const metricWithOmittedAttributes = _.omit(eachMetric, ["metricID", "requiredParameterDetails"]);

      // SPLIT THE METRIC CATEGORY NAME (BUSINESS OUTCOME) AND THE VALUE DRIVER NAME WHICH ARE SEPARATED BY COLON (:) AND ADD THE ATTRIBUTE `valueDriver` FOR EACH METRIC
      const arrayAfterSplit = _.split(eachMetric.metricCategory, ":", 2);
      const metricCategory = _.trim(arrayAfterSplit[0]);
      const valueDriver = !_.isEmpty(arrayAfterSplit[1]) ? _.trim(arrayAfterSplit[1]) : "";

      // STRUCTURE FOR EACH METRIC
      return {
        ...metricWithOmittedAttributes,
        metricId,
        metricCategory,
        valueDriver,
        parameterDetails,
        volumetricDetails,
        calculationFormulaParsed,
        manuallyEnteredValue,
        calculatedValue,
        userEnteredBenchmarkValue,
        isDisabledMetricInput,
        isFocusedMetricInput: false,
        isValid: true,
        errMsg: "",
        metricUnitFormatted: getFormattedUnitName(eachMetric.metricUnit),
        isHoveredTVEIcon: false,
        isHoveredCalculatorIcon: false,
        isHoveredInfoIcon: false,
        isVisibleInstructionsInfoPopup: false,
      };
    });

    const filteredSynOpsParameters = _.sortBy(_.uniqBy(allParametersFromSynOps, "SynOpsParameterID"), ["sequence"]);

    // SORT THE METRICS BY `metricSequence` ATTRIBUTE
    metricDetails = _.sortBy(metricDetails, ["metricSequence"]);

    // FILTER OUT UNIQUE METRIC CATEGORIES
    const uniqueMetricCategories = _.uniq(_.map(metricDetails, "metricCategory"));

    // GROUP THE METRICS BY THE UNIQUE CATEGORIES
    const dataByMetricCategories = _.map(uniqueMetricCategories, (eachUniqueMetricCategory) => {
      return {
        metricCategory: eachUniqueMetricCategory,
        metrics: _.filter(metricDetails, (eachMetric) => eachMetric.metricCategory === eachUniqueMetricCategory),
        dataCollectionPercentage: 0,
      };
    });

    this.setState(
      {
        filteredSynOpsParameters,
        dataByMetricCategories,
        allVolumetricFromSynOps,
        contractPeriod,
        areInstructionsToBeDisplayedForAssessment,
        hasUserAcknowledgedInstructionsInfoPopup,
        expandedAccordionsKeysArray: [], // COLLAPSE ALL THE ACCORDIONS
        currentView: isParametersMappingAvailable ? "parameterView" : "metricView", // WHEN PARAMETERS MAPPING IS NOT AVAILABLE FOR THE METRICS, THEN DISABLE THE "PARAMETER VIEW" TAB AND DIRECTLY DISPLAY THE "METRIC VIEW" TAB,
      },
      () => {
        this.updateMetricCategoryDataCompletionPercentage();
      }
    );
  };

  redirectToClientPerformanceViewScreen = () => {
    const { history } = this.props;
    const {
      offeringName,
      offeringType,
      subOfferingId,
      subOfferingName,
      startDate,
      endDate,
      clientCount,
      clientName,
      clientIndustry,
      clientMarketUnit,
      BATAssessSubOfferingID,
      BATAssessClientID,
      ClientLogo,
      isSubOfferingTVEEnabled,
    } = this.state;
    const clientPerformanceViewScreenData = {
      offeringName,
      offeringType,
      subOfferingId,
      subOfferingName,
      startDate,
      endDate,
      clientCount,
      clientName,
      clientIndustry,
      clientMarketUnit,
      BATAssessSubOfferingID,
      BATAssessClientID,
      ClientLogo,
      isSubOfferingTVEEnabled,
    };

    const location = {
      pathname: `/${ROUTE_CONSTANTS.BAT_CLIENT_PERFORMANCE_VIEW}`,
      state: { clientPerformanceViewScreenData },
    };

    history.push(location);
  };

  // EVENT HANDLER FOR THE "CHANGE" EVENT OF THE METRIC PARAMETER INPUT
  handleChangeMetricParameterValue = ({ target: { value } }, parameterId) => {
    const { filteredSynOpsParameters } = this.state;
    let clonedFilteredSynOpsParameters = _.cloneDeep(filteredSynOpsParameters);
    const foundParameter = _.find(clonedFilteredSynOpsParameters, ["SynOpsParameterID", parameterId]);
    const fieldValue = _.trim(String(value));
    foundParameter.enteredValue = fieldValue;

    this.setState({ filteredSynOpsParameters: clonedFilteredSynOpsParameters });
  };

  // EVENT HANDLER FOR THE "FOCUS" EVENT OF THE METRIC PARAMETER INPUT
  handleFocusMetricParameterInput = ({ target: { value } }, parameterId) => {
    const { filteredSynOpsParameters } = this.state;
    let clonedFilteredSynOpsParameters = _.cloneDeep(filteredSynOpsParameters);
    const foundParameter = _.find(clonedFilteredSynOpsParameters, ["SynOpsParameterID", parameterId]);
    foundParameter.isInputFocused = true;

    this.setState({ filteredSynOpsParameters: clonedFilteredSynOpsParameters });
  };

  // EVENT HANDLER FOR THE "BLUR" EVENT OF THE METRIC PARAMETER INPUT
  handleBlurMetricParameterInput = ({ target: { value } }, parameterId) => {
    const { filteredSynOpsParameters } = this.state;
    let clonedFilteredSynOpsParameters = _.cloneDeep(filteredSynOpsParameters);
    const foundParameter = _.find(clonedFilteredSynOpsParameters, ["SynOpsParameterID", parameterId]);

    const isValueValid = validateBATFieldValue(value);
    foundParameter.isValid = isValueValid;
    foundParameter.isInputFocused = false;

    this.setState({ filteredSynOpsParameters: clonedFilteredSynOpsParameters });
  };

  // EVENT HANDLER FOR "CALCULATE" BUTTON IN "PARAMETER VIEW"
  handleClickCalculateBtnInParameterView = () => {
    this.setState({
      isVisibleSaveParametersConfirmationModal: true,
    });
  };

  hideSaveParametersConfirmationModal = () => {
    this.setState({
      isVisibleSaveParametersConfirmationModal: false,
    });
  };

  // EVENT HANDLER FOR THE "SAVE" BUTTON IN THE SaveParametersConfirmation MODAL
  // EXECUTES THE API TO SAVE THE PARAMETER VALUES TO DB AND THEN REQUEST TO FETCH THE CALCULATED VALUES FOR THE METRICS
  setParameterValuesInMetricsAndCalculateMetricValues = () => {
    const { filteredSynOpsParameters, dataByMetricCategories } = this.state;
    this.hideSaveParametersConfirmationModal();

    let clonedDataByMetricCategories = _.cloneDeep(dataByMetricCategories);

    // SAVE ENTERED VALUE FOR THE SynOps PARAMETERS IN THE CORRESPONDING PARAMETERS FOR EACH METRIC
    _.forEach(clonedDataByMetricCategories, (eachDataByMetricCategory) => {
      const metrics = eachDataByMetricCategory.metrics;
      // LOOP OVER EACH METRIC
      _.forEach(metrics, (eachMetric) => {
        const parameterDetails = eachMetric.parameterDetails;
        let makeDisabledMetricInput = false;

        // LOOP OVER EACH METRIC PARAMETER
        _.forEach(parameterDetails, (eachParameter) => {
          const SynOpsParameterID = eachParameter.SynOpsParameterID;
          // FOR THE CURRENT METRIC PARAMETER, FIND THE CORRESPONDING SynOps PARAMETER SO THAT THE ENTERED VALUE FOR THE METRIC PARAMETER CAN BE SET FROM THE VALUE ENTERED FOR THE SynOps PARAMETER
          const foundSynOpsParameter = _.find(filteredSynOpsParameters, ["SynOpsParameterID", SynOpsParameterID]);
          if (!_.isEmpty(foundSynOpsParameter)) {
            const enteredValueForSynOpsParameter = foundSynOpsParameter.enteredValue;
            eachParameter.enteredValue = enteredValueForSynOpsParameter;
          }
        });

        eachMetric.isDisabledMetricInput = makeDisabledMetricInput;
        // BEFORE EXECUTING THE REQUEST TO FETCH THE CALCULATED VALUES FOR THE METRIC, EMPTY OUT THE CALCULATED VALUE SO THAT IF THE PARAMETER VALUES ARE MADE EMPTY THEN THE PREVIOUSLY CALCULATED VALUE GETS WIPED OUT
        eachMetric.calculatedValue = "";
      });
    });

    this.setState(
      {
        dataByMetricCategories: clonedDataByMetricCategories,
      },
      () => {
        this.updateMetricCategoryDataCompletionPercentage();
        this.saveParameterValuesToDB();
      }
    );
  };

  // EXECUTES THE API REQUEST TO SAVE THE ENTERED VALUES OF THE VOLUMETRICS TO DATABASE
  saveVolumetricValuesToDB = () => {
    const { allVolumetricFromSynOps, BATAssessSubOfferingID } = this.state;

    // LOOP OVER ALL THE VOLUMETRICS, TO CREATE THE DATA FOR REQUEST
    const volumetricsForRequest = _.map(allVolumetricFromSynOps, (eachVolumetric) => ({
      BATVolumetricID: eachVolumetric.volumetricId,
      // IF THE VALUE IS EMPTY THEN SET THE VALUE AS EMPTY STRING SO THAT ITS VALUE WILL GET CLEARED IN DATABASE FOR THIS AS THIS IS NON-MANDATORY AND USER CAN EMPTY OUT THE VOLUMETRIC VALUE
      Value: !_.isEmpty(String(eachVolumetric.enteredValue)) ? parseFloat(eachVolumetric.enteredValue) : "",
    }));

    const requestData = {
      BATAssessSubOfferingID,
      Volumetrics: volumetricsForRequest,
    };

    if (!_.isEmpty(volumetricsForRequest)) {
      this.setState({ isAPILoading: true });
      axiosInstance
        .post(`${LocalApiBaseUrl}BATAssessment/AddEdit_BATAssessmentClientVolumetricValue`, requestData)
        .then((response) => {
          this.setState({
            isAPILoading: false,
            currentView: "metricView",
          });
        })
        .catch((error) => {
          this.setState({ isAPILoading: false });
          trycatchAlertPopup(error);
        });
    }
  };

  // FROM THE VALUES SAVED IN `allVolumetricFromSynOps` FOR ALL VOLUMETRICS, LOOP OVER AND SET THE VALUES IN THE CORRESPONDING METRICS VOLUMETRIC
  // AND THEN EXECUTE THE API TO SAVE THE VOLUMETRICS VALUES TO DB
  setVolumetricValuesInMetrics = () => {
    const { allVolumetricFromSynOps, dataByMetricCategories } = this.state;

    let clonedDataByMetricCategories = _.cloneDeep(dataByMetricCategories);

    // SAVE ENTERED VALUE FOR THE SynOps PARAMETERS IN THE CORRESPONDING PARAMETERS FOR EACH METRIC
    _.forEach(clonedDataByMetricCategories, (eachDataByMetricCategory) => {
      const metrics = eachDataByMetricCategory.metrics;
      // LOOP OVER EACH METRIC
      _.forEach(metrics, (eachMetric) => {
        const volumetricDetails = eachMetric.volumetricDetails;

        // LOOP OVER EACH METRIC VOLUMETRIC
        _.forEach(volumetricDetails, (eachVolumetric) => {
          const volumetricId = eachVolumetric.volumetricId;
          // FOR THE CURRENT METRIC VOLUMETRIC, FIND THE CORRESPONDING SynOps VOLUMETRIC SO THAT THE ENTERED VALUE FOR THE METRIC VOLUMETRIC CAN BE SET FROM THE VALUE ENTERED FOR THE SynOps VOLUMETRIC
          const foundVolumetric = _.find(allVolumetricFromSynOps, ["volumetricId", volumetricId]);
          if (!_.isEmpty(foundVolumetric)) {
            eachVolumetric.enteredValue = foundVolumetric.enteredValue;
          }
        });
      });
    });

    this.setState(
      {
        dataByMetricCategories: clonedDataByMetricCategories,
      },
      () => {
        this.saveVolumetricValuesToDB();
      }
    );
  };

  // EVENT HANDLER FOR THE METRIC INPUT VALUE CHANGE
  handleChangeMetricValue = ({ target: { value } }, metricId, metricCategory) => {
    const { dataByMetricCategories } = this.state;
    let clonedDataByMetricCategories = _.cloneDeep(dataByMetricCategories);

    const foundMetricCategoryObj = _.find(clonedDataByMetricCategories, ["metricCategory", metricCategory]);
    const foundMetric = _.find(foundMetricCategoryObj.metrics, ["metricId", metricId]);
    const fieldValue = _.trim(String(value));
    foundMetric.calculatedValue = "";
    foundMetric.manuallyEnteredValue = fieldValue;

    this.setState({ dataByMetricCategories: clonedDataByMetricCategories });
  };

  // CALCULATES AND UPDATES THE DATA COMPLETION PERCENTAGE FOR METRIC CATEGORIES
  updateMetricCategoryDataCompletionPercentage = () => {
    const { dataByMetricCategories } = this.state;

    let totalDataCollectionValue = 0;
    let clonedDataByMetricCategories = _.cloneDeep(dataByMetricCategories);
    let totalMetrics = 0;
    let totalFilledMetrics = 0;
    // LOOP OVER EACH METRIC CATEGORY TO CALCULATE THE DATA COLLECTION PERCENTAGE VALUE AND OVERALL DATA COLLECTION
    clonedDataByMetricCategories = _.map(clonedDataByMetricCategories, (eachItem) => {
      const categoryMetrics = eachItem.metrics;
      totalMetrics = totalMetrics + categoryMetrics.length;

      const metricsWithFilledValue = _.filter(categoryMetrics, (eachMetric) => {
        const manuallyEnteredValue = _.trim(String(eachMetric.manuallyEnteredValue));
        const calculatedValue = _.trim(String(eachMetric.calculatedValue));
        return (!_.isEmpty(manuallyEnteredValue) || !_.isEmpty(calculatedValue)) && eachMetric.isValid;
      });

      let dataCollectionPercentage = 0;

      if (!_.isEmpty(categoryMetrics)) {
        totalFilledMetrics = totalFilledMetrics + metricsWithFilledValue.length;
        dataCollectionPercentage = _.ceil((metricsWithFilledValue.length * 100) / categoryMetrics.length);
      }
      eachItem.dataCollectionPercentage = dataCollectionPercentage;

      totalDataCollectionValue = totalDataCollectionValue + dataCollectionPercentage;
      return eachItem;
    });

    const overallDataCollectionPercentage = _.ceil((totalFilledMetrics * 100) / totalMetrics);

    this.setState({
      dataByMetricCategories: clonedDataByMetricCategories,
      overallDataCollectionPercentage,
    });
  };

  // EVENT HANDLER FOR THE METRIC INPUT "FOCUS" EVENT. SETS THE `isFocusedMetricInput` TO TRUE
  handleFocusMetricValueInput = ({ target: { value } }, metricId, metricCategory) => {
    const { dataByMetricCategories } = this.state;

    let clonedDataByMetricCategories = _.cloneDeep(dataByMetricCategories);

    const foundMetricCategoryObj = _.find(clonedDataByMetricCategories, ["metricCategory", metricCategory]);
    const foundMetric = _.find(foundMetricCategoryObj.metrics, ["metricId", metricId]);
    foundMetric.isFocusedMetricInput = true;

    this.setState({
      dataByMetricCategories: clonedDataByMetricCategories,
    });
  };

  // EVENT HANDLER FOR THE METRIC INPUT "BLUR" EVENT. SETS THE `isFocusedMetricInput` TO TRUE
  handleBlurMetricValueInput = ({ target: { value } }, metricId, metricCategory) => {
    const { dataByMetricCategories, hasUserAcknowledgedInstructionsInfoPopup } = this.state;
    let clonedDataByMetricCategories = _.cloneDeep(dataByMetricCategories);

    const foundMetricCategoryObj = _.find(clonedDataByMetricCategories, ["metricCategory", metricCategory]);
    const foundMetric = _.find(foundMetricCategoryObj.metrics, ["metricId", metricId]);

    let isDisabledMetricInput = false;
    // IF THE METRIC VALUE IS DERIVED FROM CALCULATION AND ON BLUR THERE WAS NO CHANGE MADE IN THE METRIC VALUE, THEN DISABLE THE INPUT
    // THIS WOULD BE THE CASE TO RESTORE THE CALCULATED VALUE IN THE INPUT IF THE METRIC VALUE IS DERIVED FROM CALCULATION AND CLICKED THE RESET ICON AND METRIC INPUT WOULD BLURRED WITHOUT INPUTTING ANY VALUE
    if (!_.isEmpty(String(foundMetric.calculatedValue)) && String(foundMetric.calculatedValue) === String(value)) {
      isDisabledMetricInput = true;
    }

    let isValueValid = validateBATFieldValue(value);
    // SET FIELD TO "VALID" STATE IF IT IS TO BE DISABLED
    if (isDisabledMetricInput) {
      isValueValid = true;
    }
    foundMetric.isDisabledMetricInput = isDisabledMetricInput;
    foundMetric.isValid = isValueValid;
    foundMetric.isFocusedMetricInput = false;
    if (
      !_.isEmpty(String(value)) &&
      !_.isEmpty(foundMetric.volumetricDetails) &&
      !hasUserAcknowledgedInstructionsInfoPopup
    ) {
      foundMetric.isHoveredTVEIcon = true;
      // SHOW THE INSTRUCTIONS POPUP IF USER HAS NOT ACKNOWLEDGED IT
      foundMetric.isVisibleInstructionsInfoPopup = true;
    }

    this.setState(
      {
        dataByMetricCategories: clonedDataByMetricCategories,
      },
      () => {
        this.updateMetricCategoryDataCompletionPercentage();
      }
    );
  };

  // EVENT HANDLER FOR THE METRIC INPUT "FOCUS" EVENT. SETS THE `isFocusedMetricInput` TO TRUE
  handleMouseOverIcon = (iconName, metricId, metricCategory) => {
    const { dataByMetricCategories } = this.state;
    let clonedDataByMetricCategories = _.cloneDeep(dataByMetricCategories);

    const foundMetricCategoryObj = _.find(clonedDataByMetricCategories, ["metricCategory", metricCategory]);
    const foundMetric = _.find(foundMetricCategoryObj.metrics, ["metricId", metricId]);
    if (iconName === "tve") {
      foundMetric.isHoveredTVEIcon = true;
    } else if (iconName === "calculator") {
      foundMetric.isHoveredCalculatorIcon = true;
    } else if (iconName === "info") {
      foundMetric.isHoveredInfoIcon = true;
    }

    this.setState({ dataByMetricCategories: clonedDataByMetricCategories });
  };

  // EVENT HANDLER FOR THE METRIC INPUT "FOCUS" EVENT. SETS THE `isFocusedMetricInput` TO TRUE
  handleMouseOutIcon = (iconName, metricId, metricCategory) => {
    const { dataByMetricCategories } = this.state;
    let clonedDataByMetricCategories = _.cloneDeep(dataByMetricCategories);

    const foundMetricCategoryObj = _.find(clonedDataByMetricCategories, ["metricCategory", metricCategory]);
    const foundMetric = _.find(foundMetricCategoryObj.metrics, ["metricId", metricId]);
    if (iconName === "tve") {
      foundMetric.isHoveredTVEIcon = false;
    } else if (iconName === "calculator") {
      foundMetric.isHoveredCalculatorIcon = false;
    } else if (iconName === "info") {
      foundMetric.isHoveredInfoIcon = false;
    }

    this.setState({ dataByMetricCategories: clonedDataByMetricCategories });
  };

  // EVENT HANDLER FOR THE "RESET VALUE ICON" FOR THE METRIC
  handleClickResetMetricValue = (metricId, metricCategory) => {
    const { dataByMetricCategories } = this.state;
    let clonedDataByMetricCategories = _.cloneDeep(dataByMetricCategories);

    const foundMetricCategoryObj = _.find(clonedDataByMetricCategories, ["metricCategory", metricCategory]);
    const foundMetric = _.find(foundMetricCategoryObj.metrics, ["metricId", metricId]);
    foundMetric.isDisabledMetricInput = false;
    foundMetric.isFocusedMetricInput = true;

    this.setState({ dataByMetricCategories: clonedDataByMetricCategories }, () => {
      document.getElementById(`assessmentMetricInput-${metricId}`).focus();
    });
  };

  handleChangeTab = (tabId) => {
    this.setState({ currentView: tabId });
  };

  // EVENT HANDLER FOR "RESET ALL" BUTTON. THIS WILL DISPLAY THE DELETE CONFIRMATION MODAL
  handleClickResetAllDataForAssessment = () => {
    this.setState({
      isVisibleConfirmDeleteModal: true,
    });
  };

  // EVENT HANDLER FOR THE "CALCEL" BUTTON IN THE "CONFIRM DELETE ALL ASSESSMENT DATA" MODAL TO HIDE THE MODAL
  handleClickCancelResetAllAssessmentData = () => {
    this.setState({
      isVisibleConfirmDeleteModal: false,
    });
  };

  // EVENT HANDLER FOR THE "DELETE" BUTTON IN THE "CONFIRM DELETE ALL ASSESSMENT DATA" MODAL TO EXECUTE THE API TO DELETE ALL DATA
  handleClickConfirmResetAllAssessmentData = () => {
    this.setState(
      {
        isVisibleConfirmDeleteModal: false,
      },
      () => {
        this.executeAPIResetAllAssessmentData();
      }
    );
  };

  // EXECUTE THE API REQUEST TO DELETE/RESET ALL THE DATA FOR THE ASSESSMENT
  executeAPIResetAllAssessmentData = () => {
    const { BATAssessSubOfferingID } = this.state;

    this.setState({ isAPILoading: true });
    axiosInstance
      .delete(`${LocalApiBaseUrl}BATAssessment/ResetAllDetails?BATAssessSubOfferingID=${BATAssessSubOfferingID}`)
      .then((response) => {
        this.setState(
          {
            isAPILoading: false,
          },
          () => {
            window.alert("Assessment Data has been reset successfully");
            this.fetchMetricDataFromBAT();
          }
        );
      })
      .catch((error) => {
        this.setState({ isAPILoading: false });
        trycatchAlertPopup(error);
      });
  };

  // EVENT HANDLER FOR "SAVE" BUTTON FOR THE OFFERING - SUB-OFFERING. EXECUTES THE API TO SAVE ALL THE METRIC VALUES
  handleClickSaveAllMetrics = () => {
    this.saveMetricValuesToDB();
  };

  // EVENT HANDLER FOR "CANCEL" BUTTON FOR THE OFFERING - SUB-OFFERING. REDIRECTS TO PREVIOUS SCREEN
  handleClickCancelScreen = () => {
    const { history, clientSimulationFlowInitiatedFromScreen } = this.props;
    let location = {};
    if (clientSimulationFlowInitiatedFromScreen.screenName === "BATLandingScreen") {
      location = {
        pathname: `/${ROUTE_CONSTANTS.BAT_LANDING_PAGE}`,
      };
    } else if (clientSimulationFlowInitiatedFromScreen.screenName === "BATOfferingDetailsScreen") {
      location = {
        pathname: `/${ROUTE_CONSTANTS.BAT_OFFERING_BENCHMARK_DETAILS}`,
        state: {
          batViewScreenData: clientSimulationFlowInitiatedFromScreen.screenData,
        },
      };
    } else {
      location = {
        pathname: `/${ROUTE_CONSTANTS.BAT_LANDING_PAGE}`,
      };
    }
    history.push(location);
  };

  showMetricParametersModal = (
    metricData,
    renderMetricParametersModalFor,
    areFormFieldsMandatoryForMetricParametersModal,
    subHeaderTextForMetricParametersModal
  ) => {
    this.setState(
      {
        isVisibleMetricParametersModal: true,
        currentlySelectedMetricData: metricData,
        renderMetricParametersModalFor,
        areFormFieldsMandatoryForMetricParametersModal,
        subHeaderTextForMetricParametersModal,
        isVisibleInstructionsInfoTooltip: false,
      },
      () => {
        this.hideInstructionsInfoPopupForAllMetrics();
      }
    );
  };

  hideMetricParametersModal = () => {
    this.setState({
      isVisibleMetricParametersModal: false,
      currentlySelectedMetricData: {},
      renderMetricParametersModalFor: "",
      areFormFieldsMandatoryForMetricParametersModal: false,
      subHeaderTextForMetricParametersModal: "",
    });
  };

  // EVENT HANDLER FOR THE "CALCULATE" BUTTON IN THE METRIC PARAMETERS MODAL
  handleClickSubmitBtnMetricParametersModal = (metricData, modalRenderedFor) => {
    const { filteredSynOpsParameters, allVolumetricFromSynOps } = this.state;
    this.hideMetricParametersModal();

    // IF THE METRIC PARAMETERS MODAL WAS RENDERED FOR DISPLAYING "METRIC PARAMETERS" AND NOT "METRIC VOLUMETRICS"
    if (modalRenderedFor === "metricParameters") {
      const parameterDetails = metricData.parameterDetails;
      const clonedFilteredSynOpsParameters = _.cloneDeep(filteredSynOpsParameters);

      // SET THE VALUES ENTERED FOR THE PARAMETERS IN THE MODAL INTO THE CORRESPONDING `filteredSynOpsParameters` AND THEN EXECUTE THE API TO SAVE THE PARAMETER VALUES TO DB AND FETCH THE CALCULATED VALUES FOR THE METRICS
      _.forEach(parameterDetails, (eachParameter) => {
        const parameterId = eachParameter.SynOpsParameterID;
        const enteredValue = eachParameter.enteredValue;
        const foundSynOpsParameter = _.find(clonedFilteredSynOpsParameters, ["SynOpsParameterID", parameterId]);
        foundSynOpsParameter.enteredValue = enteredValue;
      });

      this.setState(
        {
          filteredSynOpsParameters: clonedFilteredSynOpsParameters,
        },
        () => {
          this.setParameterValuesInMetricsAndCalculateMetricValues();
        }
      );
    } else {
      const volumetricDetails = metricData.volumetricDetails;
      const clonedAllVolumetricFromSynOps = _.cloneDeep(allVolumetricFromSynOps);

      // SET THE VALUES ENTERED FOR THE VOLUMETRICS IN THE MODAL INTO THE CORRESPONDING `allVolumetricFromSynOps` AND THEN EXECUTE THE API TO SAVE THE PARAMETER VALUES TO DB AND FETCH THE CALCULATED VALUES FOR THE METRICS
      _.forEach(volumetricDetails, (eachVolumetric) => {
        const volumetricId = eachVolumetric.volumetricId;
        const enteredValue = eachVolumetric.enteredValue;

        // FROM ALL THE VOLUMETRICS, FIND THE CURRENT ONE AND SET ITS VALUE
        const foundVolumetric = _.find(clonedAllVolumetricFromSynOps, ["volumetricId", volumetricId]);
        foundVolumetric.enteredValue = enteredValue;
      });

      this.setState(
        {
          allVolumetricFromSynOps: clonedAllVolumetricFromSynOps,
        },
        () => {
          this.setVolumetricValuesInMetrics();
        }
      );
    }
  };

  // EVENT HANDLER FOR THE STATE CHANGES IN THE COMMON HEADER COMPONENT. RELOADS THE SCREEN BASED ON THE DATA PASSED FROM HEADER
  handleCallbackHeaderStateChange = (headerStateObj) => {
    const isParametersMappingAvailable = isParamMappingAvailableForBATOfferingSubOffering(
      headerStateObj.offeringName,
      headerStateObj.subOfferingName
    );

    if (Object.keys(headerStateObj).length < 3) {
      this.setState(
        {
          startDate: headerStateObj.formattedStartDate,
          endDate: headerStateObj.formattedEndDate,
        },
        () => {
          this.fetchMetricDataFromBAT();
        }
      );
    } else {
      this.setState(
        {
          offeringName: headerStateObj.offeringName,
          offeringType: headerStateObj.offeringType,
          subOfferingId: headerStateObj.subOfferingId,
          subOfferingName: headerStateObj.subOfferingName,
          isSubOfferingTVEEnabled: headerStateObj.isSubOfferingTVEEnabled,
          startDate: headerStateObj.startDate,
          endDate: headerStateObj.endDate,
          clientCount: headerStateObj.clientCount,
          clientName: headerStateObj.clientName,
          BATAssessSubOfferingID: headerStateObj.BATAssessSubOfferingID,
          BATAssessClientID: headerStateObj.BATAssessClientID,
          isParametersMappingAvailable,
        },
        () => {
          // THIS WILL CALL THE API WITH THE UPDATED STATE DATA AND THE SCREEN WOULD RELOAD WITH NEW DATA
          this.fetchMetricDataFromBAT();
        }
      );
    }
  };

  handleClickInfoIcon = (modalTitle, modalDescription) => {
    this.setState(
      {
        isVisibleInfoModal: true,
        infoModalTitle: modalTitle,
        infoModalDescription: modalDescription,
        isVisibleInstructionsInfoTooltip: false,
      },
      () => {
        this.hideInstructionsInfoPopupForAllMetrics();
      }
    );
  };

  handleCloseInfoModal = () => {
    this.setState({
      isVisibleInfoModal: false,
      infoModalTitle: "",
      infoModalDescription: "",
    });
  };

  // WOULD COLLAPSE ALL THE ACCORDIONS
  collapseAllAccordions = () => {
    this.setState({
      expandedAccordionsKeysArray: [],
    });
  };

  // WOULD BE EXECUTED WHEN THE ACCORDION HEADER WOULD BE CLICKED. THIS WOULD TOGGLE THE OPEN/CLOSE OF THE ACCORDION BODY SECTION
  toggleAccordionSection = (currentEventKey) => {
    const { expandedAccordionsKeysArray } = this.state;
    let modifiedExpandedAccordionsKeysArray = _.cloneDeep(expandedAccordionsKeysArray);

    // IF THE CURRENT ACCORDION EVENT KEY IS NOT PRESENT IN THE ARRAY THEN ADD IT SO THAT THE CURRENT ACCORDION WOULD BE EXPANDED, ELSE REMOVE IT FROM THE ARRAY SO THAT THE CURRENT ACCORDION WOULD BE COLLAPSED
    if (_.indexOf(modifiedExpandedAccordionsKeysArray, currentEventKey) === -1) {
      modifiedExpandedAccordionsKeysArray.push(currentEventKey);
    } else {
      // REMOVE THE ONES WHICH HAS A MATCH OF STRING (THIS LOGIC WOULD ALSO WORK TO COLLAPSE ALL THE CHILDREN UNDER THE CURRENT PARENT)
      modifiedExpandedAccordionsKeysArray = _.filter(modifiedExpandedAccordionsKeysArray, (eachItem) => {
        const itemFound = eachItem.indexOf(currentEventKey) !== -1;
        return !itemFound;
      });
    }
    this.setState({
      expandedAccordionsKeysArray: modifiedExpandedAccordionsKeysArray,
    });
  };

  handleChangeContractPeriod = (selectedValue) => {
    this.setState({
      contractPeriod: selectedValue,
    });
  };

  // EXECUTE THE API REQUEST TO UPDATE THE "INSTRUCTION INFO POPUP" DISPLAY FLAG FOR THE CURRENT ASSESSMENT
  updateInstructionsInfoInDB = () => {
    const { selectedOptionInInstructionsInfoPopup } = this.state;

    const requestData = {
      Text: selectedOptionInInstructionsInfoPopup,
    };

    this.setState({ isAPILoading: true });
    axiosInstance
      .post(`${LocalApiBaseUrl}BATAssessment/AddBATAssessmentClientShowUserHelp`, requestData)
      .then((response) => {
        this.setState({
          areInstructionsToBeDisplayedForAssessment: false,
          hasUserAcknowledgedInstructionsInfoPopup: true,
          isAPILoading: false,
        });
      })
      .catch((error) => {
        this.setState({ isAPILoading: false });
        trycatchAlertPopup(error);
      });
  };

  // EVENT HANDLER FOR THE "GOT IT" BUTTON IN THE INSTRUCTION INFO POPUP FOR INDIVIDUAL METRIC. CALLS THE METHOD TO EXECUTE THE API TO SAVE THE FLAG SO THAT THE NOT TO DISPLAY THE POPUP ONLY IF THE "DON'T SHOW" CHECKBOX IS CHECKED AND THE PREVIOUSLY THE POPUP WAS TO BE SHOWN
  handleClickGotItButtonInInstructionsInfoPopupForMetric = () => {
    const { selectedOptionInInstructionsInfoPopup, areInstructionsToBeDisplayedForAssessment } = this.state;

    // IF ANY OPTION WAS CHOSEN Y THE USER IN THE DROPDOWN FOR "NOT REMINDING", THEN EXECUTE THE API TO SAVE THE SELECTED CHOICE
    if (!_.isEmpty(selectedOptionInInstructionsInfoPopup) && areInstructionsToBeDisplayedForAssessment) {
      this.updateInstructionsInfoInDB();
    } else {
      this.hideInstructionsInfoPopupForAllMetrics();
    }
  };

  handleClickInstructionsInfoIcon = (event) => {
    const clickedElementId = _.get(event, "target.id", "");

    if (clickedElementId === "instructionsInfoIcon" || clickedElementId === "instructionsInfoIconImage") {
      const { isVisibleInstructionsInfoTooltip } = this.state;
      this.setState(
        {
          isVisibleInstructionsInfoTooltip: !isVisibleInstructionsInfoTooltip,
        },
        () => {
          this.hideInstructionsInfoPopupForAllMetrics();
        }
      );
    }
  };

  handleClickGotItButtonInInstructionsInfoTooltip = () => {
    const { selectedOptionInInstructionsInfoPopup } = this.state;

    this.setState(
      {
        isVisibleInstructionsInfoTooltip: false,
      },
      () => {
        // IF ANY OPTION WAS CHOSEN BY THE USER IN THE DROPDOWN FOR "NOT REMINDING", THEN EXECUTE THE API TO SAVE THE SELECTED CHOICE
        if (!_.isEmpty(selectedOptionInInstructionsInfoPopup)) {
          this.updateInstructionsInfoInDB();
        }
      }
    );
  };

  handleSelectDropdownOptionInInstructionsInfoPopup = (selectedOption) => {
    this.setState({
      selectedOptionInInstructionsInfoPopup: selectedOption,
    });
  };

  hideInstructionsInfoPopupForAllMetrics = () => {
    const { dataByMetricCategories } = this.state;

    let clonedDataByMetricCategories = _.cloneDeep(dataByMetricCategories);

    _.forEach(clonedDataByMetricCategories, (eachDataByMetricCategory) => {
      const metrics = eachDataByMetricCategory.metrics;
      _.forEach(metrics, (eachMetric) => {
        eachMetric.isVisibleInstructionsInfoPopup = false;
        eachMetric.isHoveredTVEIcon = false;
      });
    });

    this.setState({
      dataByMetricCategories: clonedDataByMetricCategories,
      selectedOptionInInstructionsInfoPopup: "",
    });
  };

  render() {
    const {
      clientName,
      clientIndustry,
      clientMarketUnit,
      subOfferingId,
      BATAssessSubOfferingID,
      isParametersMappingAvailable,
      isSubOfferingTVEEnabled,
      currentView,
      isAPILoading,
      offeringName,
      subOfferingName,
      isVisibleSaveParametersConfirmationModal,
      isVisibleMetricParametersModal,
      overallDataCollectionPercentage,
      dataByMetricCategories,
      filteredSynOpsParameters,
      currentlySelectedMetricData,
      renderMetricParametersModalFor,
      areFormFieldsMandatoryForMetricParametersModal,
      subHeaderTextForMetricParametersModal,
      BATAssessClientID,
      ClientLogo,
      clientCount,
      isVisibleInfoModal,
      infoModalTitle,
      infoModalDescription,
      expandedAccordionsKeysArray,
      contractPeriod,
      selectedOptionInInstructionsInfoPopup,
      isVisibleConfirmDeleteModal,
      assessedSubOfferingAndMetricDetailsFromSynOps,
      isVisibleInstructionsInfoTooltip,
      startDate,
      endDate,
    } = this.state;

    const assessedMetricDetails = _.get(assessedSubOfferingAndMetricDetailsFromSynOps, "MetricDetails", []);

    // ONLY ENABLE THE "CALCULATE" BUTTON IN "PARAMETER VIEW" IF THE VALUE FOR AT LEAST ONE PARAMETER IS ENTERED AND THE VALUES ARE VALID
    const areAllParametersEmpty = _.isEmpty(
      _.find(filteredSynOpsParameters, (eachItem) => {
        const enteredValue = _.trim(String(eachItem.enteredValue));
        return !_.isEmpty(enteredValue);
      })
    );
    const areAllParametersValid = _.isEmpty(_.find(filteredSynOpsParameters, { isValid: false }));
    const isEnabledCalculateBtnInParameterView = !areAllParametersEmpty && areAllParametersValid;

    // FOR THE METRICS GROUPED BY "METRIC CATEGORIES" (BUSINESS OUTCOMES), CHECK IF THE METRICS HAVE ASSOCIATED "VALUE DRIVERS" AND IF IT IS SO THEN CHANGE THE DATA HIERARCHY SO THAT THE METRICS ARE GROUPED UNDER "VALUE DRIVERS" AND THEY IN TURN ARE GROUPED UNDER "METRIC CATEGORIES" (BUSINESS OUTCOMES)
    let allMetrics = [];
    const modifiedDataByMetricCategories = _.map(dataByMetricCategories, (eachItem) => {
      let metrics = eachItem.metrics;
      allMetrics = _.concat(allMetrics, eachItem.metrics);

      const uniqueValueDrivers = _.uniq(_.map(metrics, "valueDriver"));
      let dataByValueDrivers = [];
      if (!_.isEmpty(_.first(uniqueValueDrivers))) {
        dataByValueDrivers = _.map(uniqueValueDrivers, (eachValueDriver) => {
          const metricsOfValueDriver = _.filter(metrics, { valueDriver: eachValueDriver });

          return {
            valueDriver: eachValueDriver,
            metrics: metricsOfValueDriver,
          };
        });
        metrics = [];
      }

      return {
        ...eachItem,
        metricCategory: eachItem.metricCategory,
        metrics,
        dataByValueDrivers,
      };
    });

    // ONLY ENABLE THE "SAVE ALL METRIC VALUES" BUTTON IF THE VALUE FOR AT LEAST ONE METRIC IS PRESENT AND THE VALUES ARE VALID
    const areAllMetricsEmpty = _.isEmpty(
      _.find(allMetrics, (eachItem) => {
        const ret =
          !_.isEmpty(_.trim(String(eachItem.calculatedValue))) ||
          !_.isEmpty(_.trim(String(eachItem.manuallyEnteredValue)));
        return ret;
      })
    );
    const areAllMetricsValid = _.isEmpty(_.find(allMetrics, { isValid: false }));
    const isEnabledSaveAllMetricsBtn = !areAllMetricsEmpty && areAllMetricsValid;

    const errMsgInvalidValue = "Invalid Numeric Value";

    const dontRemindAgainTodayDisplayText = "Don't remind again today";
    const dontRemindAgainDisplayText = "Don't remind again";
    let displayTextForSelectedOptionInInstructionsInfoPopup = "Don't remind";
    if (selectedOptionInInstructionsInfoPopup === "dontRemindAgainToday") {
      displayTextForSelectedOptionInInstructionsInfoPopup = dontRemindAgainTodayDisplayText;
    } else if (selectedOptionInInstructionsInfoPopup === "dontRemindAgain") {
      displayTextForSelectedOptionInInstructionsInfoPopup = dontRemindAgainDisplayText;
    }

    let isVisibleInstructionsInfoPopupForAnyMetric = false;
    _.forEach(dataByMetricCategories, (eachDataByMetricCategory) => {
      const metrics = eachDataByMetricCategory.metrics;
      _.forEach(metrics, (eachMetric) => {
        if (!isVisibleInstructionsInfoPopupForAnyMetric && eachMetric.isVisibleInstructionsInfoPopup) {
          isVisibleInstructionsInfoPopupForAnyMetric = true;
        }
      });
    });

    const metricsLayout = (metrics, metricCategory) => {
      return _.map(metrics, (eachMetric) => {
        const metricId = eachMetric.metricId;
        let isDisabledMetricInput = eachMetric.isDisabledMetricInput;
        const isVisibleInstructionsInfoPopup = eachMetric.isVisibleInstructionsInfoPopup;
        if (isVisibleInstructionsInfoPopupForAnyMetric || isVisibleInstructionsInfoTooltip) {
          isDisabledMetricInput = true;
        }
        const isValid = eachMetric.isValid;
        const calculatedValue = eachMetric.calculatedValue;
        const manuallyEnteredValue = eachMetric.manuallyEnteredValue;
        let metricValueToBeDisplayed = "";
        let isMetricValueFilled = false;

        // IF MANUALLY ENTERED VALUE IS NOT EMPTY
        if (!_.isEmpty(_.trim(String(manuallyEnteredValue)))) {
          metricValueToBeDisplayed = manuallyEnteredValue;
          isMetricValueFilled = true;
        }

        // DISPLAYED VALUE OF THE METRIC WOULD BE THE CALCULATED VALUE IF MANUALLY ENTERED IS EMPTY AND CALCULATED VALUE IS NOT EMPTY
        if (_.isEmpty(_.trim(String(manuallyEnteredValue))) && !_.isEmpty(_.trim(String(calculatedValue)))) {
          metricValueToBeDisplayed = calculatedValue;
          isMetricValueFilled = true;
        }

        const parameterDetails = eachMetric.parameterDetails;
        let parametersDataCollectionPercentage = isParametersMappingAvailable && !_.isEmpty(parameterDetails) ? 0 : 100;
        if (isParametersMappingAvailable && !_.isEmpty(parameterDetails)) {
          const metricParametersStatusData = getMetricParametersStatusData(parameterDetails);
          parametersDataCollectionPercentage = metricParametersStatusData.collectionPercentage;
        }

        let calculatorIconForDisplay = eachMetric.isHoveredCalculatorIcon
          ? Calculator_icon_hover
          : Calculator_icon_normal;
        let calculatorTextForDisplay = "Please enter parameter values to compute metric value";
        if (parametersDataCollectionPercentage > 0 && parametersDataCollectionPercentage < 100) {
          calculatorIconForDisplay = eachMetric.isHoveredCalculatorIcon
            ? Calculator_icon_incomplete_hover
            : Calculator_icon_incomplete_normal;
          calculatorTextForDisplay = "Data Captured";
        } else if (parametersDataCollectionPercentage === 100) {
          calculatorIconForDisplay = eachMetric.isHoveredCalculatorIcon
            ? Calculator_icon_completed_hover
            : Calculator_icon_completed_normal;
          calculatorTextForDisplay = "Data is Captured";
        }

        const volumetricDetails = eachMetric.volumetricDetails;
        let volumetricsDataCollectionPercentage = isSubOfferingTVEEnabled && !_.isEmpty(volumetricDetails) ? 0 : 100;
        if (isSubOfferingTVEEnabled && !_.isEmpty(volumetricDetails)) {
          const metricVolumetricsStatusData = getMetricVolumetricsStatusData(volumetricDetails);
          volumetricsDataCollectionPercentage = metricVolumetricsStatusData.collectionPercentage;
        }

        let tveIconForDisplay = eachMetric.isHoveredTVEIcon || isMetricValueFilled ? TVE_icon_hover : TVE_icon_normal;
        let tveTextForDisplay = "Please enter data inputs to help assess the Size of the Opportunity";
        if (volumetricsDataCollectionPercentage > 0 && volumetricsDataCollectionPercentage < 100) {
          tveIconForDisplay =
            eachMetric.isHoveredTVEIcon || isMetricValueFilled ? TVE_icon_incomplete_hover : TVE_icon_incomplete_normal;
          tveTextForDisplay = "Data Captured";
        } else if (volumetricsDataCollectionPercentage === 100) {
          tveIconForDisplay =
            eachMetric.isHoveredTVEIcon || isMetricValueFilled ? TVE_icon_completed_hover : TVE_icon_completed_normal;
          tveTextForDisplay = "Data is Captured";
        }

        let metricTileColor = "";
        if (
          (parametersDataCollectionPercentage > 0 && parametersDataCollectionPercentage < 100) ||
          (volumetricsDataCollectionPercentage > 0 && volumetricsDataCollectionPercentage < 100)
        ) {
          metricTileColor = "red";
        } else if (isMetricValueFilled) {
          metricTileColor = "green";
        }

        return (
          <div
            className={classNames("col-lg-3", "tve-box", "custom-tvebox", {
              "tve-box-green": metricTileColor === "green",
              "tve-box-red": metricTileColor === "red",
            })}
            key={eachMetric.metricName}
          >
            <div className="tve-box-container">
              <div className="tve-box-title">{_.upperFirst(eachMetric.metricName)}</div>
              <div className="tve-box-value">
                <div className="metric-value-input">
                  <div className="value-wrapper">
                    <input
                      type="text"
                      maxLength="15"
                      disabled={isDisabledMetricInput}
                      id={`assessmentMetricInput-${metricId}`}
                      className="value-box"
                      value={metricValueToBeDisplayed}
                      onChange={(e) => this.handleChangeMetricValue(e, metricId, metricCategory)}
                      onFocus={(e) => this.handleFocusMetricValueInput(e, metricId, metricCategory)}
                      onBlur={(e) => this.handleBlurMetricValueInput(e, metricId, metricCategory)}
                    />
                    <div>
                      {eachMetric.isDisabledMetricInput && (
                        <img
                          src={Refresh}
                          alt="Reset Value"
                          onClick={(e) => this.handleClickResetMetricValue(metricId, metricCategory)}
                          className="refreshBtn"
                        />
                      )}
                    </div>
                    {!isValid && (
                      <div className="invalidMsg">
                        {!_.isEmpty(eachMetric.errMsg) ? eachMetric.errMsg : errMsgInvalidValue}
                      </div>
                    )}
                  </div>
                </div>
                <div className="metric-unit">{eachMetric.metricUnitFormatted}</div>
              </div>
            </div>

            <div className="tve-icon-group">
              {!_.isEmpty(eachMetric.volumetricDetails) && (
                <div
                  className="vm_tooltip"
                  onMouseOver={() => {
                    if (!isVisibleInstructionsInfoPopup) {
                      this.handleMouseOverIcon("tve", metricId, metricCategory);
                    }
                  }}
                  onMouseOut={() => {
                    if (!isVisibleInstructionsInfoPopup) {
                      this.handleMouseOutIcon("tve", metricId, metricCategory);
                    }
                  }}
                >
                  {currentView === "metricView" && (
                    <>
                      <OverlayTrigger
                        placement="right"
                        show={isVisibleInstructionsInfoPopup}
                        overlay={
                          <Popover
                            id="Info-Overlay-popover"
                            className="rightendinfo-popover"
                            eventOff="mouseleave scroll mousewheel blur"
                          >
                            <Popover.Content className="">
                              <div className="info-popup-content">
                                <div className="additional-details">
                                  <div>
                                    <img src={TVE_icon_hover} alt="TVE data inputs" />
                                    <span className="info-title">Additional Details</span>
                                  </div>
                                  <div className="info-desc">
                                    Please enter additional inputs needed to calculate the size of the opportunity
                                  </div>
                                </div>

                                <div className="info-popup-btns">
                                  <div className="got-it-btn-wrap">
                                    <button
                                      type="button"
                                      className="got-it-btn"
                                      onClick={() => this.handleClickGotItButtonInInstructionsInfoPopupForMetric()}
                                    >
                                      Got It
                                    </button>
                                  </div>

                                  <div className="warning-check">
                                    <Dropdown
                                      id="metricTypeImpacted"
                                      className="dontremind-dropdown"
                                      name="metricTypeImpacted"
                                      onSelect={(selectedOptionEventKey) =>
                                        this.handleSelectDropdownOptionInInstructionsInfoPopup(selectedOptionEventKey)
                                      }
                                    >
                                      <Dropdown.Toggle id="dropdown-basic">
                                        {displayTextForSelectedOptionInInstructionsInfoPopup}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu align="right">
                                        <Dropdown.Item
                                          eventKey="dontRemindAgainToday"
                                          active={selectedOptionInInstructionsInfoPopup === "dontRemindAgainToday"}
                                        >
                                          {dontRemindAgainTodayDisplayText}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          eventKey="dontRemindAgain"
                                          active={selectedOptionInInstructionsInfoPopup === "dontRemindAgain"}
                                        >
                                          {dontRemindAgainDisplayText}
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                              </div>
                            </Popover.Content>
                          </Popover>
                        }
                      >
                        <img
                          src={tveIconForDisplay}
                          alt="Volumetrics"
                          className={volumetricsDataCollectionPercentage !== 0 ? "enlarged" : ""}
                          onClick={() =>
                            this.showMetricParametersModal(
                              eachMetric,
                              "metricVolumetrics",
                              false,
                              volumetricModalSubHeaderText
                            )
                          }
                        />
                      </OverlayTrigger>

                      <span className="vm_tooltiptext-nodata">
                        <span>TVE data inputs</span>
                        <div className="vm_tooltip-detail">
                          <span>{tveTextForDisplay}</span>
                          {volumetricsDataCollectionPercentage !== 0 && (
                            <span className="react-progress-bar">
                              <ProgressBar
                                className={
                                  volumetricsDataCollectionPercentage === 100 ? "greenGradient" : "redGradient"
                                }
                                now={volumetricsDataCollectionPercentage}
                              />
                            </span>
                          )}
                        </div>
                      </span>
                    </>
                  )}
                </div>
              )}

              {/* ONLY DISPLAY THE "CALCULATOR" ICON IF THE PARAMETERS MAPPING IS AVAILABLE FOR THE OFFERING & SUB-OFFERING AND THE CURRENT METRIC HAS ANY PARAMETERS */}
              {isParametersMappingAvailable && !_.isEmpty(parameterDetails) && (
                <div
                  className="vm_tooltip"
                  onClick={() => this.showMetricParametersModal(eachMetric, "metricParameters", true, "")}
                  onMouseOver={() => this.handleMouseOverIcon("calculator", metricId, metricCategory)}
                  onMouseOut={() => this.handleMouseOutIcon("calculator", metricId, metricCategory)}
                >
                  <img
                    src={calculatorIconForDisplay}
                    alt="Parameters"
                    className={parametersDataCollectionPercentage !== 0 ? "enlarged" : ""}
                  />
                  <span className="vm_tooltiptext-nodata">
                    <span>Calculate Metric Value</span>
                    <div className="vm_tooltip-detail">
                      <span>{calculatorTextForDisplay}</span>
                      {parametersDataCollectionPercentage !== 0 && (
                        <span className="react-progress-bar">
                          <ProgressBar
                            className={parametersDataCollectionPercentage === 100 ? "greenGradient" : "redGradient"}
                            now={parametersDataCollectionPercentage}
                          />
                        </span>
                      )}
                    </div>
                  </span>
                </div>
              )}

              <div
                className="cardInfo_tooltip"
                onClick={() => this.handleClickInfoIcon(eachMetric.metricName, eachMetric.metricDescription)}
                onMouseOver={() => this.handleMouseOverIcon("info", metricId, metricCategory)}
                onMouseOut={() => this.handleMouseOutIcon("info", metricId, metricCategory)}
              >
                <img src={eachMetric.isHoveredInfoIcon ? Info_icon_hover : Info_icon_normal} alt="MetricInfo" />
                <span className="cardInfo_tooltiptext">Metric Description</span>
              </div>
            </div>
          </div>
        );
      });
    };
    return (
      <>
        {/* IF API IS LOADING THEN SHOW THE LOADER */}
        {isAPILoading && <LoadingOverlay spinner={<SynopsLoader />} active />}
        <div className="Metric-level-view pt-40">
          {/* NEW HEADER DESIGN START */}
          <BatCommonHeader
            renderedFromScreen="ProvideMetricDetails"
            clientName={clientName}
            clientCount={clientCount}
            ClientLogo={ClientLogo}
            clientIndustry={clientIndustry}
            clientMarketUnit={clientMarketUnit}
            BATAssessSubOfferingID={BATAssessSubOfferingID}
            BATAssessClientID={BATAssessClientID}
            offeringName={offeringName}
            subOfferingId={subOfferingId}
            subOfferingName={subOfferingName}
            isSubOfferingTVEEnabled={isSubOfferingTVEEnabled}
            startDate={startDate}
            endDate={endDate}
            callbackHeaderStateChange={(headerStateObj) => this.handleCallbackHeaderStateChange(headerStateObj)}
            contractPeriod={contractPeriod}
            onChangeContractPeriod={(selectedValue) => this.handleChangeContractPeriod(selectedValue)}
          />
          {/* NEW HEADER DESIGN END */}

          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="metric-container px-24">
                  <div className="metric-offering-subOffering">
                    <div>
                      <div className="offering-suboffering-name">
                        {offeringName} - {subOfferingName}
                      </div>
                      <div className="suboffering-percent">
                        <span className="percent-label">Data Collection</span>
                        <span className="react-progress-bar">
                          <ProgressBar className="greenGradient" now={overallDataCollectionPercentage} />
                        </span>
                      </div>
                    </div>
                    <div>
                      <button
                        type="btn"
                        className="btn btn-purple mr-3"
                        onClick={() => this.handleClickSaveAllMetrics()}
                        disabled={currentView === "parameterView" || !isEnabledSaveAllMetricsBtn}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="bat-cancel btn-default"
                        disabled={currentView === "parameterView"}
                        onClick={() => this.handleClickCancelScreen()}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>

                  <div className="metric-tab-container newmetric-tab-container">
                    <Tabs
                      defaultTab={currentView}
                      onChange={(tabId) => {
                        this.handleChangeTab(tabId);
                      }}
                    >
                      <div className="tab-header">
                        <TabList>
                          <Tab
                            tabFor="parameterView"
                            disabled={!isParametersMappingAvailable}
                            className={classNames({
                              disabled: !isParametersMappingAvailable,
                            })}
                          >
                            Parameter View
                          </Tab>
                          <Tab tabFor="metricView">Metric View</Tab>
                        </TabList>

                        <div className="tableft-navbtns">
                          {/* START - INFO TOOLTIP */}
                          {currentView === "metricView" && (
                            <div
                              id="instructionsInfoIcon"
                              className="info-popup"
                              onClick={(e) => this.handleClickInstructionsInfoIcon(e)}
                            >
                              <OverlayTrigger
                                placement="bottom-end"
                                show={isVisibleInstructionsInfoTooltip}
                                overlay={
                                  <Popover id="Info-Overlay-popover">
                                    <Popover.Content className="">
                                      <div className="info-popup-content">
                                        {/* IF SUB OFFERING IS TVE ENABLED THEN DISPLAY THE INSTRUCTIONS ABOUT VOLUMETRICS */}
                                        {isSubOfferingTVEEnabled && (
                                          <div className="additional-details">
                                            <div>
                                              <img src={TVE_icon_hover} alt="TVE data inputs" />
                                              <span className="info-title">TVE data inputs</span>
                                            </div>
                                            <div className="info-desc">
                                              Please enter data inputs to help assess the Size of the Opportunity
                                            </div>
                                          </div>
                                        )}

                                        {/* IF SUB OFFERING HAS METRIC PARAMETERS THEN DISPLAY THE INSTRUCTIONS ABOUT VOLUMETRICS */}
                                        {!_.isEmpty(filteredSynOpsParameters) && (
                                          <div className="calculate-metric">
                                            <div>
                                              <img src={Calculator_icon_hover} alt="Calculate Metric" />
                                              <span className="info-title">Calculate Metric</span>
                                            </div>
                                            <div className="info-desc">
                                              You can calculate metric value by adding its parameter values
                                            </div>
                                          </div>
                                        )}

                                        <div className="info-popup-btns">
                                          <div className="got-it-btn-wrap">
                                            <button
                                              type="button"
                                              className="got-it-btn"
                                              onClick={() => this.handleClickGotItButtonInInstructionsInfoTooltip()}
                                            >
                                              Got It
                                            </button>
                                          </div>

                                          <div className="warning-check">
                                            <Dropdown
                                              id="metricTypeImpacted1"
                                              name="metricTypeImpacted1"
                                              className="dontremind-dropdown"
                                              onSelect={(selectedOptionEventKey) =>
                                                this.handleSelectDropdownOptionInInstructionsInfoPopup(
                                                  selectedOptionEventKey
                                                )
                                              }
                                            >
                                              <Dropdown.Toggle id="dropdown-basic1">
                                                {displayTextForSelectedOptionInInstructionsInfoPopup}
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu align="right">
                                                <Dropdown.Item
                                                  eventKey="dontRemindAgainToday"
                                                  active={
                                                    selectedOptionInInstructionsInfoPopup === "dontRemindAgainToday"
                                                  }
                                                >
                                                  {dontRemindAgainTodayDisplayText}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  eventKey="dontRemindAgain"
                                                  active={selectedOptionInInstructionsInfoPopup === "dontRemindAgain"}
                                                >
                                                  {dontRemindAgainDisplayText}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                      </div>
                                    </Popover.Content>
                                  </Popover>
                                }
                              >
                                <img id="instructionsInfoIconImage" className="info-icon" src={Info} alt="Info" />
                              </OverlayTrigger>
                              <br />
                            </div>
                          )}
                          {/* END - INFO TOOLTIP */}
                          {currentView === "metricView" && (
                            <div className="resetAll">
                              <button
                                type="button"
                                className={classNames("resetall-button", "mr-3", {
                                  disabled: _.isEmpty(assessedMetricDetails),
                                })}
                                onClick={() => this.handleClickResetAllDataForAssessment()}
                                disabled={_.isEmpty(assessedMetricDetails)}
                              >
                                Reset All
                              </button>
                            </div>
                          )}

                          {currentView === "parameterView" && !_.isEmpty(filteredSynOpsParameters) && (
                            <div>
                              <button
                                type="button"
                                className="batassessment-btn-purplebg mr-3"
                                onClick={() => this.handleClickCalculateBtnInParameterView()}
                                disabled={!isEnabledCalculateBtnInParameterView}
                              >
                                Calculate
                              </button>

                              <button
                                type="button"
                                className="bat-cancel btn-default"
                                onClick={() => this.handleClickCancelScreen()}
                              >
                                Cancel
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      <div>
                        {/* START - PARAMETER VIEW */}
                        <TabPanel tabId="parameterView">
                          <div className="row">
                            {_.isEmpty(filteredSynOpsParameters) ? (
                              <div className="col">No Parameters Available</div>
                            ) : (
                              _.map(filteredSynOpsParameters, (eachSynOpsParameter) => {
                                const parameterId = eachSynOpsParameter.SynOpsParameterID;
                                const parameterUnitFormatted = eachSynOpsParameter.parameterUnitFormatted;
                                const isValid = eachSynOpsParameter.isValid;
                                const isFocused = eachSynOpsParameter.isInputFocused;
                                return (
                                  <div className="col-lg-3" key={eachSynOpsParameter.SynOpsParameterID}>
                                    <div className="metric-box">
                                      <div className="param-header">
                                        <h4>{_.upperFirst(eachSynOpsParameter.parameterName)}</h4>
                                        <i
                                          onClick={() =>
                                            this.handleClickInfoIcon(
                                              eachSynOpsParameter.parameterName,
                                              eachSynOpsParameter.description
                                            )
                                          }
                                          className="fa fa-info-circle bat-info-icon"
                                          aria-hidden="true"
                                        />
                                      </div>

                                      <div
                                        className={classNames(
                                          "metric-value",
                                          "form-group",
                                          { "highlight-box": isValid && isFocused },
                                          { "active-box": !isValid }
                                        )}
                                      >
                                        <div>
                                          <label className="form-label">
                                            Parameter value in{" "}
                                            {parameterUnitFormatted === "Days"
                                              ? parameterUnitFormatted
                                              : `(${parameterUnitFormatted})`}
                                          </label>
                                        </div>
                                        <div>
                                          <input
                                            type="text"
                                            className="form-cotrol"
                                            value={eachSynOpsParameter.enteredValue}
                                            onChange={(e) => this.handleChangeMetricParameterValue(e, parameterId)}
                                            onFocus={(e) => this.handleFocusMetricParameterInput(e, parameterId)}
                                            onBlur={(e) => this.handleBlurMetricParameterInput(e, parameterId)}
                                          />
                                        </div>
                                      </div>
                                      {!isValid && <div className="invalidMsg">{errMsgInvalidValue}</div>}
                                    </div>
                                  </div>
                                );
                              })
                            )}
                          </div>
                        </TabPanel>
                        {/* END - PARAMETER VIEW */}

                        {/* START - METRIC VIEW */}
                        <TabPanel tabId="metricView">
                          <div className="metric-accordion">
                            {!_.isEmpty(modifiedDataByMetricCategories) &&
                              _.map(modifiedDataByMetricCategories, (eachItem, index) => {
                                const metricCategory = eachItem.metricCategory;
                                const parentAccordionEventKey = String(`parent-${index}-${metricCategory}`);
                                const isAccordionExpanded =
                                  _.indexOf(expandedAccordionsKeysArray, parentAccordionEventKey) !== -1;
                                const accordionActiveKey = isAccordionExpanded ? parentAccordionEventKey : "";
                                return (
                                  <Accordion
                                    activeKey={accordionActiveKey}
                                    key={eachItem.metricCategory}
                                    className="metricView-main-accordion"
                                  >
                                    <Card>
                                      <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey={parentAccordionEventKey}
                                        onClick={() => this.toggleAccordionSection(parentAccordionEventKey)}
                                        className={classNames(
                                          "card-link",
                                          { active: isAccordionExpanded },
                                          { collapsed: !isAccordionExpanded }
                                        )}
                                      >
                                        <div className="header-content">
                                          <div className="header-link">
                                            <h6>{metricCategory}</h6>
                                          </div>
                                        </div>

                                        <div className="suboffering-percent">
                                          <span className="percent-label">Data Collection</span>
                                          <span className="react-progress-bar">
                                            <ProgressBar
                                              className="greenGradient"
                                              now={eachItem.dataCollectionPercentage}
                                            />
                                          </span>
                                        </div>
                                      </Accordion.Toggle>

                                      <Accordion.Collapse eventKey={parentAccordionEventKey}>
                                        <Card.Body>
                                          <>
                                            {/* IF THE METRICS ARE DIRECTLY UNDER THE METRIC CATEGORY THEN GENERATE THE LAYOUT FOR METRICS */}
                                            {!_.isEmpty(eachItem.metrics) &&
                                              metricsLayout(eachItem.metrics, metricCategory)}

                                            {/* IF THE METRIC CATEGORY HAS "VALUE DRIVERS" UNDER IT THEN GENERATE HIERARCHICAL LAYOUT OF METRICS UNDER EACH "VALUE DRIVER" */}
                                            {!_.isEmpty(eachItem.dataByValueDrivers) &&
                                              _.map(eachItem.dataByValueDrivers, (eachData, valDriverIndex) => {
                                                const valueDriverName = eachData.valueDriver;
                                                const accordionEventKey = String(
                                                  `child-${valDriverIndex}-${valueDriverName}-${parentAccordionEventKey}`
                                                );
                                                const isAccordionExpanded =
                                                  _.indexOf(expandedAccordionsKeysArray, accordionEventKey) !== -1;
                                                const accordionActiveKey = isAccordionExpanded ? accordionEventKey : "";
                                                return (
                                                  <Accordion
                                                    activeKey={accordionActiveKey}
                                                    key={accordionEventKey}
                                                    className="metricView-sub-accordion"
                                                  >
                                                    <Card>
                                                      <Accordion.Toggle
                                                        as={Card.Header}
                                                        eventKey={accordionEventKey}
                                                        onClick={() => this.toggleAccordionSection(accordionEventKey)}
                                                        className={classNames("card-link", {
                                                          collapsed: !isAccordionExpanded,
                                                        })}
                                                      >
                                                        <div className="header-content">
                                                          <div className="header-link">
                                                            <h6>{valueDriverName}</h6>
                                                          </div>
                                                        </div>
                                                      </Accordion.Toggle>

                                                      <Accordion.Collapse eventKey={accordionEventKey}>
                                                        <Card.Body>
                                                          {metricsLayout(eachData.metrics, metricCategory)}
                                                        </Card.Body>
                                                      </Accordion.Collapse>
                                                    </Card>
                                                  </Accordion>
                                                );
                                              })}
                                          </>
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  </Accordion>
                                );
                              })}
                          </div>
                        </TabPanel>
                        {/* END - METRIC VIEW */}
                      </div>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* START - SAVE PARAMETERS CONFIRMATION MODAL */}
        {isVisibleSaveParametersConfirmationModal && (
          <Modal show centered className="confirmationModal" dialogClassName="modal-50w" backdrop="static">
            <Modal.Header>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>We would suggest you to save the values entered in the Parameter(s). </p>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="bat-cancel btn-default mr-3"
                onClick={() => this.hideSaveParametersConfirmationModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="batassessment-btn-purplebg"
                onClick={() => this.setParameterValuesInMetricsAndCalculateMetricValues()}
              >
                Save
              </button>
            </Modal.Footer>
          </Modal>
        )}
        {/* END - SAVE PARAMETERS CONFIRMATION MODAL */}

        {/* START - METRIC PARAMETERS MODAL */}
        {isVisibleMetricParametersModal && (
          <MetricParametersModal
            sharedAccessDetail={{}}
            metricData={currentlySelectedMetricData}
            history={this.props.history}
            onCancel={() => this.hideMetricParametersModal()}
            onSubmit={(metricData, renderFor) => this.handleClickSubmitBtnMetricParametersModal(metricData, renderFor)}
            errMsgInvalidValue={errMsgInvalidValue}
            areFormFieldsMandatory={areFormFieldsMandatoryForMetricParametersModal}
            modalSubHeaderText={subHeaderTextForMetricParametersModal}
            renderFor={renderMetricParametersModalFor}
          />
        )}
        {/* END - METRIC PARAMETERS MODAL */}

        {/* MODAL TO SHOW METRIC INFO WHEN CLICKED ON INFO ICON */}
        {isVisibleInfoModal && (
          <MetricInfoModal
            title={infoModalTitle}
            description={infoModalDescription}
            onClose={() => this.handleCloseInfoModal()}
          />
        )}

        {/* MODAL TO CONFIRM WHETHER TO RESET ALL THE DATA CAPTURED FOR THE ASSESSMENT */}
        {isVisibleConfirmDeleteModal && (
          <ConfirmDeleteModalPopup
            isVisible={isVisibleConfirmDeleteModal}
            title={"Confirm Reset Assessment Data"}
            message={
              "This action will delete all saved values for the current assessment. Kindly click on 'OK' if you want to continue"
            }
            cancelButtonLabel={"Cancel"}
            deleteButtonLabel={"OK"}
            onClickCancelButton={this.handleClickCancelResetAllAssessmentData}
            onClickDeleteButton={this.handleClickConfirmResetAllAssessmentData}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientSimulationFlowInitiatedFromScreen: state.BATScreensStates.clientSimulationFlowInitiatedFromScreen,
  };
};

export default withRouter(connect(mapStateToProps)(ProvideMetricDetailsForAssessment));
