import React, { Component } from 'react';
import { LocalApiBaseUrl, getMonthNumber, getPrevMonth } from '../Shared/Constant';
import { AgGridReact, Ag } from 'ag-grid-react';
import ApprovalPopup from './IIPopup';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;



export default class IIApprovalList extends Component {
    constructor(props) {
        super(props)
        

        this.state = {

            Approval: {


                DealMetricGroupID: [],
                ApprovalType: null,
                ApprovalAction: false,

            },

            CanApprove: false, CanReject: false, action: "",

            rowSelected: [],
            userList: [], roleList: [], clientList: [], viewmore: false, prevLength: "", flag: false,
            columnDefs: [

                {

                    headerName: "", resizable: true, suppressFilter: true, headerCheckboxSelectionFilteredOnly: true, headerCheckboxSelection: true, checkboxSelection: params => params.data.Status == "Pending",suppressMovable: true,suppressSizeToFit: true,
                    width: 100
                },
                { headerName: " Deal", field: "DealName", resizable: true, suppressFilter: false ,suppressMovable: true,suppressSizeToFit: true,},
                { headerName: " Offering", field: "Offering", resizable: true, suppressFilter: false ,suppressMovable: true,suppressSizeToFit: true,},
                { headerName: " SubOffering", field: "SubOffering", resizable: true, suppressFilter: false,suppressMovable: true,suppressSizeToFit: true, },
                { headerName: " Category name", field: "SoCategoryName", resizable: true, suppressFilter: false,suppressMovable: true,suppressSizeToFit: true, },
                { headerName: " Metric Group", field: "MetricGroup", resizable: true, suppressFilter: false ,suppressMovable: true,suppressSizeToFit: true,},
                { headerName: "Description", field: "MetricDescription", resizable: true, suppressFilter: false,suppressMovable: true,suppressSizeToFit: true, },
                { headerName: " Requested By", field: "RequestUser", resizable: true, suppressFilter: false,suppressMovable: true,suppressSizeToFit: true, },
                { headerName: "Requested Date", field: "RequestedDate", resizable: true, suppressFilter: true,suppressMovable: true,suppressSizeToFit: true, },
                { headerName: "Metrics Approved/Denied Date", field: "ActionDate", resizable: true, suppressFilter: true,suppressMovable: true,suppressSizeToFit: true, },
                { headerName: "Status", field: "Status", resizable: true, suppressFilter: false,suppressMovable: false,suppressSizeToFit: true, },
                { headerName: " Requestor Remarks", field: "BusinessReason", resizable: true, suppressFilter: false,suppressMovable: true,suppressSizeToFit: true, },
                { headerName: " Approver Remarks", field: "ApproverRemarks", resizable: true, suppressFilter: false,suppressMovable: true,suppressSizeToFit: true },

            ],
            autoGroupColumnDef: {
                headerName: "hello",
                field: "DealName",
                width: 200,
                cellRenderer: "agGroupCellRenderer",
                cellRendererParams: { checkbox: true }
            },

            rowData: [],
            gridOptions: {
                paginationPageSize: 50,
                rowHeight: 40,
                headerHeight: 40,
                animateRows: true,
                rowSelection: 'multiple',


                defaultColDef: {
                    sortable: true
                },
            }
        }
        this.onClose = this.onClose.bind(this);
        this.dealpopup = this.dealpopup.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);

        this.handleSave = this.handleSave.bind(this);
        this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
        this.decline = this.decline.bind(this);
        this.onBtnExport = this.onBtnExport.bind(this);

    }


    onRowSelected(event) {

        if (event.data.Status == "Pending") {

            if (event.node.selected) {
                let rowSelected = this.state.rowSelected
                rowSelected.push(event.data);
                this.setState({ rowSelected: rowSelected });
            }
            else {
                let rowSelected = this.state.rowSelected

                rowSelected = rowSelected.filter((row) => row.DealMetricGroupID !== event.data.DealMetricGroupID)
                this.setState({ rowSelected: rowSelected });
            }
        }
    }



    onCheckboxClicked(e, data) {

        if (e.target.checked) {
            

            this.state.rowSelected.push(data);
        }
        else {
            
            this.state.rowSelected = this.state.rowSelected.filter((row) => row.DealMetricGroupID !== data.DealMetricGroupID)
        }
    }

    onFilterTextBoxChanged(e) {
        this.state.gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
    }

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    dealpopup(e) {

        if (this.props.featureData.filter((data) => data.Submenu == "Metric Group" && data.Operations.includes("Approve")).length > 0) {
            e.preventDefault();
            this.setState({ viewmore: true })
            this.state.action = 'Approve'

        }
        else window.alert("Access Denied !!")


    }

    onClose(e) {

        this.setState({ viewmore: false });
    }

    getApproval() {
        
        axiosInstance.get(`${LocalApiBaseUrl}DealTemplateMetricGroup/GetDealTemplateMetricGroup`, {

            params: {}
        })
            .then((response) => {
                this.setState({
                    rowData: response.data
                })
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
    }

    componentDidMount() {

        if (this.props.featureData.filter((data) => data.Submenu == "Metric Group" && data.Operations.includes("Reject")).length > 0) {

            this.setState({ CanReject: true });
        }
        if (this.props.featureData.filter((data) => data.Submenu == "Metric Group" && data.Operations.includes("Approve")).length > 0) {

            this.setState({ CanApprove: true });
        }

        var a = this.props;
        this.getApproval();
        this.setState({
            rowSelected: []

        })
    }

    componentWillMount() {
        
        this.setState({
            rowSelected: []

        })



        this.getApproval();
    }

    decline(e) {
        

        if (this.props.featureData.filter((data) => data.Submenu == "Metric Group" && data.Operations.includes("Reject")).length > 0) {
            if (this.state.rowSelected.length > 1) {
                window.alert("User is advised to select one row at a time")
            }
            else {
                e.preventDefault();
                this.setState({ viewmore: true })
                this.state.action = 'Reject'
            }
        }
        else window.alert("Access Denied !!")




        // if (this.props.featureData.filter((data) => data.Submenu == "Metric Group" && data.Operations.includes("Reject")).length > 0) {

        //     let data = this.state.rowSelected
        //     const Approval = this.state.Approval;

        //     data.map((row) => {
        //         this.state.Approval.DealMetricGroupID.push(row.DealMetricGroupID)
        //     })
        //     Approval.ApprovalType = null
        //     Approval.ApprovalAction = false;
        //     this.setState({
        //         Approval: Approval
        //     })

        //     axiosInstance.post(`${LocalApiBaseUrl}DealTemplateMetricGroup/PostDealTemplateMetricGroupApproval`, this.state.Approval)
        //         .then((response) => {
        //             window.alert(response.data.StatusMessage)
        //             this.getApproval();
        //         });
        // }
        // else window.alert("Access Denied !!")

    }
    handleSave(data) {
        
        axiosInstance.post(`${LocalApiBaseUrl}DealTemplateMetricGroup/PostDealTemplateMetricGroupApproval  `, data)
            .then((response) => {
                window.alert(response.data.StatusMessage)
                this.getApproval();
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
        this.state.rowSelected = []

    }



    onActiveChange(event) {
        
        const user = this.state.user;
        user.IsActive = event.target.checked;
        this.setState({
            user: user
        })
    }

    onBtnExport() {

        let params = {
            columnKeys: [ 'DealName', 'Offering','SubOffering','SoCategoryName', 'MetricGroup', 'MetricDescription','RequestUser' ,'RequestedDate','ActionDate','Status','BusinessReason','ApproverRemarks'],
            columnGroups: true,
            allColumns: false,
            skipHeader: false,
            fileName: 'Metric Group',
            //   columnSeparator: document.querySelector("#columnSeparator").value
        };
        this.state.gridOptions.api.exportDataAsCsv(params);
    }


    render() {

        return (
            <div className="main-data">
                    {/* <h3 className="grid-heading">Metric Group</h3> */}
                <div className="tableFilter">
                    <div className="form-section">
                        <div className="form-row">
                            <div className="form-group col-lg-3">
                                <input type="text" className="form-control" id="filter-text-box" placeholder="Filter..." onChange={(e) => { this.onFilterTextBoxChanged(e) }} />
                            </div>
                            <div className="ml-auto mr-1">                                
                        {this.props.featureData.filter((data) => data.Submenu == "Metric Group" && data.Operations.includes("Download")).length > 0 ?<span className="btn btn-primary btn-sm py-1" onClick={this.onBtnExport}>
                                    <FontAwesomeIcon icon={faFileDownload} className="mr-1" /> Download
                                </span> : null }
                                {/* <button className="btn btn-primary btn-sm" id="btnsave" onClick={this.onBtnExport} disabled={this.state.active}>Download</button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ag-theme-balham ag-table">
                            <AgGridReact
                                enableSorting={true}
                                enableFilter={true}
                                pagination={true}
                                paginationPageSize={50}
                                floatingFilter={true}
                                onRowSelected={this.onRowSelected.bind(this)}

                                gridOptions={this.state.gridOptions}
                                columnDefs={this.state.columnDefs}
                                rowData={this.state.rowData}
                                onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                            </AgGridReact>
                        </div>
                    </div>
                </div>
                {this.state.viewmore ? <ApprovalPopup action={this.state.action} data={this.state.rowSelected} show={this.state.viewmore} onClose={this.onClose} onSave={this.handleSave} /> : null}
                <div className="text-center mt-3 mb-2">
                    {this.state.rowSelected.length > 0 && this.state.CanApprove ? <button className="btn btn-primary btn-sm mx-2" onClick={(e) => { this.dealpopup(e) }}>Approve</button>
                        : <button className="btn btn-primary btn-sm mx-2" disabled={true} onClick={(e) => { this.dealpopup(e) }}>Approve</button>}
                    {this.state.rowSelected.length > 0 && this.state.CanReject ? <button className="btn btn-primary btn-sm mx-2" onClick={(e) => { this.decline(e) }} >Decline</button>
                        : <button className="btn btn-primary btn-sm mx-2" disabled={true} onClick={(e) => { this.decline(e) }} >Decline</button>}
                </div>
            </div>

        )
    }
}