import React from "react";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import _ from "lodash";
import {
  LocalApiBaseUrl,
  regexForFields,
  regexForTextAreas,
  regexForNumbersOnlyMTC,
  regexForNegativeNumbers,
} from "../Shared/Constant";
import i18n from "i18next";
import { store } from "../reducers/configureStore";
import { Form } from "react-bootstrap";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import KpiPopUp from "../Configurator/KpiPopUp";
import IIMetricGroupPopUp from "./IIMetricGroupPopUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle,faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FormatDate,isValidText,InvalidDescription } from "../Shared/commonFunctions";
import AddToolPopUp from "../CommonComponents/AddToolPopUp";
import { MetricValueThousandFormat, thousandSeparatorFormat } from "../Shared/Common";
import DatePicker from "react-datepicker";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
let samp = {};

export default class InsightIntelligencePopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
      showCategory: false,
      showAddToolPopup: false,
      isApiLoading:true,
      toolsApi: [],
      toolsApiData: [],
      showaddCategory: false,
      apivalue: "",
      iscatypedisable: false,
      isEdit: false,
      showaddCategoryType: false,
      showApproval: false,

      viewmore: false,
      IsBatDealLevelForOffering: false,
      selectDropValue: "",
      selectCattypeValue: "",
      commentsError: "",
      selectValue: this.props.SelectValue,
      isCheck: true,
      Display: false,
      EditIndex: 0,
      categories: [],
      categoriesData: [],
      CategoryDetails: { CategoryType: "", CategoryName: "" },
      SOCat: [],
      fields: {},
      type: "",
      isKpi: "",
      addKpiData: [],
      kpiDetails: [],
      valueConnector: false,
      CatNameID: 0,
      CatTypeID: 0,
      numeratorVal: 0,
      denomVal: 0,
      EditIndex: 0,
      isAddCatPopup: false,
      errors: {},
      formIsValid: false,
      formIsValidMetric: false,
      addNewMetricGroup: false,
      MetricNameOptions: [],
      formHasValidName: true,
      formIsValidInsight: false,
      SOCategoryID: "",
      isMetricGroupNameChanged: false,
      metricCategoryVisible: false,
      BaselineMonthReadOnly: true,
      allCategoryTypesAndNames: [],
      CategoryNameData: [],
      categoriesLimit:30, // DEFAULT,
      iiData: {
        SOCategoryID: "",
        LeadingAndSimilarCheck: false,
        ParentSOCategoryID: "",
        SOCategoryName: "",
        SOCategoryDesp: "",
        SoCategoryImage: "",
        SOCategoryTypeID: "",
        SequenceID: "",
        SOCategorySeq: null,

        kpiData: {
          isRelatorBlock: this.props.isRelatorBlock, //true,
          LeadingIndustry: "",
          SimilarIndustry: "",
          LeadingPractice: "",
          FormulaList: [],
          formulaStr: "",
          BaselineValue:"",
          BaseLineMonth:"",
          TargetValue:"",
          // NumeratorDescription: "",
          // DenominatorDescription: "",
          Comments: "",
          metDataAttr: [
            {
              AttributeName:
                this.props.attributes.filter((z) => z.ApplicableForDD === false).length > 0
                  ? this.props.attributes.filter((z) => z.ApplicableForDD === false)[0].AttributeName1
                  : "",
              AttributeValue: "",
            },

            {
              AttributeName:
                this.props.attributes.filter((z) => z.ApplicableForDD === false).length > 0
                  ? this.props.attributes.filter((z) => z.ApplicableForDD === false)[0].AttributeName2
                  : "",
              AttributeValue: "",
            },
          ],
          MetricDefination: [],
          MetricDefinitionID: "",
          toolID: 0, // new code
          ApplicationMappingID: 0,
          PulseToolsConfigDetailsID:0,
          CustomToolID: 0, // new code
          CategoryDetails: [],
          IsMultiLevel: false,
          IsAggregation: false,
          ApplicationMappingName: "",
          ProjectName: "",
        },
      },
      selectedApplication: 1, // By Default Tool is Selected
      showLoaderonPage:false,
    };

		this.onNameChange = this.onNameChange.bind(this);
		this.onDescriptionChange = this.onDescriptionChange.bind(this);
		this.onSequenceChange = this.onSequenceChange.bind(this);
		this.onMetricGroupChange = this.onMetricGroupChange.bind(this);
		this.onMetricNameChange = this.onMetricNameChange.bind(this);
		this.onMetricSequenceChange = this.onMetricSequenceChange.bind(this);
		this.onMetricDescChange = this.onMetricDescChange.bind(this);
		this.onIconChange = this.onIconChange.bind(this);
		this.onUnitChange = this.onUnitChange.bind(this);
		this.onValueChange = this.onValueChange.bind(this);
		this.onValueDescriptionChange = this.onValueDescriptionChange.bind(this);
		this.onStatusChange = this.onStatusChange.bind(this);
		this.onUpperChange = this.onUpperChange.bind(this);
    this.maxLengthCheckThreshold = this.maxLengthCheckThreshold.bind(this);
		this.handleaddCategoryAdd = this.handleaddCategoryAdd.bind(this);
		this.onLowerChange = this.onLowerChange.bind(this);    
    this.onBaselineValueChange = this.onBaselineValueChange.bind(this);
    this.onBaselineMonthChange = this.onBaselineMonthChange.bind(this);
    this.onTargetValueChange = this.onTargetValueChange.bind(this);
		this.onRadioCheck = this.onRadioCheck.bind(this);
		this.handleCategoryShow = this.handleCategoryShow.bind(this);
		this.handleCategoryClose = this.handleCategoryClose.bind(this);
		this.handleApprovalClose = this.handleApprovalClose.bind(this);
		this.deleteCategoryName = this.deleteCategoryName.bind(this);
		this.handleChangeMetricDefinition = this.handleChangeMetricDefinition.bind(this);
		this.onStatusChange = this.onStatusChange.bind(this);
		this.onSourceChange = this.onSourceChange.bind(this);
		this.onSourceSystemChange = this.onSourceSystemChange.bind(this);
		this.onOtherSystemChange = this.onOtherSystemChange.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleSaveInsight = this.handleSaveInsight.bind(this);
		this.handleaddCategoryNameClose = this.handleaddCategoryNameClose.bind(this);
		this.isMetricGrpNameChanged = this.isMetricGrpNameChanged.bind(this);
		this.getGetConnectorMaster = this.getGetConnectorMaster.bind(this);
		this.onToolOwnerChange = this.onToolOwnerChange.bind(this);
		this.onLeadingIndustryChange = this.onLeadingIndustryChange.bind(this);
		this.handleaddCategoryShow = this.handleaddCategoryShow.bind(this);
		this.handleaddCategoryClose = this.handleaddCategoryClose.bind(this);
		this.onSimilarIndustryChange = this.onSimilarIndustryChange.bind(this);
		this.CatTypeSelect = this.CatTypeSelect.bind(this);
		this.handleCategoryEditShow = this.handleCategoryEditShow.bind(this);
		this.handleCategoryEdit = this.handleCategoryEdit.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.editCategoryName = this.editCategoryName.bind(this);
		this.handleDropdownChange = this.handleDropdownChange.bind(this);
		this.deleteCategoryType = this.deleteCategoryType.bind(this);

    this.handleCategoryAdd = this.handleCategoryAdd.bind(this);
    this.handleCategoryTypeAddShow = this.handleCategoryTypeAddShow.bind(this);
    let isConnectorMetricDataFound = true;
    let isSelectedMetricMappedToConnector = false;
    let selectedMetricConnectorLastUpdated;
  }

  onClose(e) {
    this.setState({ viewmore: false });
    this.props.onClose(e);
  }

  componentWillMount() {
    if (this.props.filterDetails != undefined)
      if (
        this.props.filterDetails.Client != 0 &&
        this.props.filterDetails.Locations != 0 &&
        this.props.filterDetails.Offering != 0 &&
        this.props.filterDetails.Process != 0
      ) {
        this.setState({ Display: true });
      }
    let apivalue = this.props.opsgrp;

    this.setState({ apivalue, showLoaderonPage:false, });
  }
  getTools = () => {
    this.setState({
      isApiLoading:true
    })
    let source = [];
    let param = {
      offeringID: parseInt(this.props.filterDetails.process),
      OrgEntityID: parseInt(this.props.filterDetails.Client),
      LocationID: parseInt(this.props.filterDetails.Locations),
      SOCategoryID: 4,
    };
    axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetMergedCustomTools`, { params: param }).then((response) => {
      source = response.data;
      let sourceValues = source.map((x) => (
        <option key={x.PulseToolsConfigDetailsID} value={x.PulseToolsConfigDetailsID}>
          {x.ApplicationName}
        </option>
      ));
      this.setState({
        toolsApi:sourceValues,
        toolsApiData:source,
        showLoaderonPage:false,
        isApiLoading:false
      });
    })
	.catch((error) => {
    this.setState({showLoaderonPage:false,isApiLoading:false});
		trycatchAlertPopup(error);
	  });

  };

  getGetConnectorMaster() {
    let source = [];
    axiosInstance.get(`${LocalApiBaseUrl}ConnectorMapping/GetConnectorMaster`).then((response) => {
      source = response.data;
      let sourceValues = source.map((x) => (
        <option key={x.ConnectorID} /*selected={index == 0 ? "" : ""}*/ value={x.ConnectorID}>
          {x.ConnectorName}
        </option>
      ));
      this.setState({ sourceOptions: sourceValues });
    })
	.catch((error) => {
		trycatchAlertPopup(error);
	  });

  }
  getCategoriesLimit = () =>{
    let data = store.getState().CurrentFilter.multiLevelCategoriesLimit;
    this.setState({
      categoriesLimit:data
    })
}
  async componentDidMount() {
    this.getGetConnectorMaster();
    this.getCategoriesLimit()
    var data = store.getState();
    this.getTools();

		this.setState({
			IsBatDealLevelForOffering: data.CurrentFilter.IsBatDealLevel
		});
    if(this.state.SOCategoryID!=="")
    {
		this.getKpiMAppingDetails();
    }
    if (this.props.Details) {

      let kpiData = { ...this.props.kpiData };
      if (kpiData.CategoryDetails.length === 0) {
        kpiData.IsMultiLevel = false;
      }
      let CatTypeID =
        kpiData.CategoryDetails && kpiData.CategoryDetails.length > 0
          ? kpiData.CategoryDetails[0].OfferingMultiLevelCategoryID
          : 0;

      let CatType =
        kpiData.CategoryDetails && kpiData.CategoryDetails.length > 0 ? kpiData.CategoryDetails[0].CategoryType : 0;

      let CategoryNameData =
        CatType && CatType != ""
          ? this.state.allCategoryTypesAndNames.filter((eachValue) => eachValue.CategoryType == CatType)
          : []; //FETCHING CATEGORY NAME


      let CategoryDetails = this.state.CategoryDetails;
      CategoryDetails.CategoryType = CatType;
      this.setState({
        CategoryNameData: CategoryNameData,
        CategoryDetails: CategoryDetails,
      });

      //if (
      //	!this.props.Details.kpiData.MetricDefination ||
      //	this.props.Details.kpiData.MetricDefination.length === 0
      //) {
      //	let kpiDat = this.props.Details.kpiData;
      //	kpiDat.MetricDefinitionID = -1;
      //	this.props.Details = kpiDat;
      //}

      if (
        this.props.Details.kpiData.MetricDefination.length > 0 &&
        this.props.Details.kpiData.MetricDefination.filter(
          (x) => String(x.KPIMetricDefinatoinID) === String(this.props.Details.kpiData.MetricDefinitionID)
        ).length === 0
      ) {
        let kpiDat = this.props.Details.kpiData;
        kpiDat.MetricDefinitionID = 0;
        this.props.Details = kpiDat;
      }

      this.setState({
        CatTypeID: CatTypeID,
        iscatypedisable: true,
        iiData: this.props.Details,
        categoriesData: this.props.AllCategoryData,
        errors: {},
        SOCat: SOCatlist1,
        formIsValidMetric: true,
        formIsValidInsight: true,
        BaselineMonthReadOnly: this.props.Details.kpiData.BaselineValue ? false : true
        //type: this.props.Details.ParentSOCategoryID == 19 ? "Analytics Apps & AI Advisors" : "Other Process Insights"
      });
    }
    let SOCatlist1 = [];

    let SOCatList = this.props.AllCategoryData.map((client) => {
      if (
        (client.ParentSOCategoryID == this.props.MetricGroupsID || client.SOCategoryID == this.props.MetricGroupsID) &&
        this.props.type == "Left_Panel"
      ) {
        SOCatlist1.push(<option value={client.SOCategoryName}>{client.SOCategoryName}</option>);
      } else if (
        (client.ParentSOCategoryID == this.props.MetricGroupsID || client.SOCategoryID == this.props.MetricGroupsID) &&
        this.props.type == "Right_Panel"
      ) {
        SOCatlist1.push(<option value={client.SOCategoryName}>{client.SOCategoryName}</option>);
      }
      return SOCatlist1;
    });

    if (this.props.setCategory != "") {
      let iiData = this.state.iiData;

      iiData.SOCategoryName = this.props.setCategory;
      this.getSOCategoryId({ target: { value: this.props.setCategory } });
      this.getKpiMAppingDetails();
      this.setState({
        iiData: iiData,
      });
    }

    this.setState({
      SOCat: SOCatlist1,
      isKpi: this.props.isKpi,
      addKpiData: this.props.Details.kpiData,
    });

    if (!this.props.Details) {
      this.setState({
        type: this.props.type,
      });
    }

    if (this.props.tool) {
      this.setState({
        toolOptions: this.props.tool.map((dropValue, index) => {
          return (
            <option key={dropValue.PulseToolsConfigDetailsID} selected="" value={dropValue.PulseToolsConfigDetailsID}>
              {dropValue.ApplicationName}
            </option>
          );
        }),
      });
    }
  }
  handleClick() {
    const kpiData = this.state.iiData.kpiData;
    kpiData.IsMultiLevel = !kpiData.IsMultiLevel;
    if (!kpiData.IsMultiLevel) {
      kpiData.IsAggregation = false;
    }
    this.setState({
      data: {
        kpiData: kpiData,
      },
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filterDetails != undefined)
      if (
        nextProps.filterDetails.Client != 0 &&
        nextProps.filterDetails.Locations != 0 &&
        nextProps.filterDetails.Offering != 0 &&
        nextProps.filterDetails.Process != 0
      ) {
        this.setState({ Display: true });
      }
  }
  editCategoryName(item) {
    this.setState({
      showCategoryNameEditPopup: true,
    });
  }
  onNameChange(event) {
    ///DOUBT
    let errors = {};
    this.setState({
      errors: errors,
    });

    let iiData = this.state.iiData;
    this.isMetricGrpNameChanged(event);

    iiData.SOCategoryName = event.target.value;
    this.setState({
      iiData: iiData,
    });
  }

  getSOCategoryId(event) {
    let iiData = this.state.iiData;
    let errors = {};
    let formIsValidMetric = true;

    var data = store.getState();
    let iiDetails = data.intelligentInsightReducers["insightandintelligence"];
    this.state.isMetricGroupNameChanged = true;
    if (iiDetails.length > 0) {
      var a = iiDetails.filter((data) => data.SOCategoryName == event.target.value)[0].SOCategoryID;
    }
    this.state.SOCategoryID = a;
  }

  isMetricGrpNameChanged(event) {
    let iiData = this.state.iiData;
    let errors = {};
    let formIsValidMetric = true;

    var data = store.getState();
    let iiDetails = data.intelligentInsightReducers["insightandintelligence"];
    this.state.isMetricGroupNameChanged = true;
    if (iiDetails.length > 0) {
      if (this.state.isMetricGroupNameChanged && this.props.Details) {
        let oldData1 = iiDetails.map(function(el) {
          return el.SOCategoryName;
        });
        if (oldData1.includes(event.target.value)) {
          this.state.formHasValidName = false;
          errors["MetricGroupExists"] = "Name already exists";
        }
      }
    }
  }

  onDescriptionChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    let iiData = this.state.iiData;
    iiData.SOCategoryDesp = event.target.value;
    this.setState({
      iiData: iiData,
    });
  }

  onSequenceChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    let iiData = this.state.iiData;
    iiData.SequenceID = event.target.value;
    this.setState({
      iiData: iiData,
    });
  }

  onMetricGroupChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });

    let iiData = this.state.iiData;
    // iiData.SOCategoryName = this.state.iiData.SOCategoryName;

    if (event.target.value !== "-1") {
      iiData.SOCategoryName = event.target.value;
      //iiData.MetricValue= "";//CHANGED
      this.getSOCategoryId(event);
      this.getKpiMAppingDetails();
      this.setState({
        iiData: iiData,
      });
    } else {
      this.setState({
        addNewMetricGroup: true,
        show: false,
      });
    }
  }

  getKpiMAppingDetails() {
    let samp = {};
    let valueConnectorEdit = false;
    let mappedConnectorName;
    this.setState({
			showLoaderonPage:true,
		})
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${LocalApiBaseUrl}Configurator/GetKpiMapping`, {
          params: {
            OrgEntityID: this.props.filterDetails.Client,
            LocationID: this.props.filterDetails.Locations,
            OfferingID: this.props.filterDetails.process,
            SOCategoryID: this.state.SOCategoryID,
            MonthDT: this.props.filterDetails.Month,
          },
        })
        .then((response) => {
          let mappingData = response.data;
          let iskpiConnector = false;
          let isaccentureOwned = false;
          if (mappingData.length > 0) {

            let kpiData = this.state.iiData.kpiData;
            kpiData.FormulaList = mappingData[0].FormulaList;
            this.setState({
              allCategoryTypesAndNames: mappingData[0].CategoryList,
              kpiData: kpiData,
            });

            //this.setState({ allCategoryTypesAndNames: mappingData[0].CategoryList },);
          }

          if (this.props.kpiData) {
            let mappedConnector = mappingData.filter((x) => x.KpiMappingID == this.props.kpiData.KPIMappingID);

            if (mappedConnector.length > 0) {
              iskpiConnector = mappedConnector[0].KpiMetricConnectorID != 0 ? true : false;
              isaccentureOwned = mappedConnector[0].IsAccentureOwned;

              // this.setState({

              //     KpiMetricConnectorID: mappedConnector[0].KpiMetricConnectorID != 0 ? true : false

              // })
              this.isConnectorMetricDataFound = mappedConnector[0].IsConnectorMetricDataFound;
              this.isSelectedMetricMappedToConnector = mappedConnector[0].IsConnector;
              this.selectedMetricConnectorLastUpdated = mappedConnector[0].ConnectorUpdatedTS;

              if (
                this.props.kpiData &&
                (this.props.kpiData.ConnectorID == undefined ||
                  parseInt(this.props.kpiData.ConnectorID) === 0 ||
                  (this.props.kpiData.ConnectorNames == null || this.props.kpiData.ConnectorNames == undefined))
              ) {
                if (
                  mappedConnector.length > 0 &&
                  mappedConnector[0].ConnectorName != "" &&
                  mappedConnector[0].ConnectorName != null
                ) {
                  mappedConnectorName = mappedConnector[0].ConnectorName;
                  let mappedConnectID = mappedConnector[0].ConnectorID;

                  this.props.kpiData.ConnectorNames = mappedConnectorName;
                  this.props.kpiData.ConnectorID = mappedConnectID;

                  if (this.state.iiData) {
                    let kpiData = this.state.iiData.kpiData;
                    kpiData.ConnectorNames =
                      mappedConnectorName != null && mappedConnectorName != undefined
                        ? mappedConnectorName
                        : kpiData.ConnectorNames;
                    kpiData.ConnectorID = mappedConnectID > 0 ? mappedConnectID : kpiData.ConnectorID;
                  }
                }
              }
            }

            if (mappedConnectorName != null) {
              valueConnectorEdit = true;
            }
          }

          this.setState(
            {
              MetricNameOptions: response.data,
              valueConnectorEdit,
              KpiMetricConnectorID: iskpiConnector,
              IsAccentureOwned: isaccentureOwned,
              showLoaderonPage:false
            },
            () => {
              resolve();
            }
          );
        })
        .catch((error) => {
          this.setState({
						showLoaderonPage:false
					})
          trycatchAlertPopup(error);
          reject(error);
        });


    });
  }

  onMetricNameChange(event) {
    if (!event.target.value) {
      let iiData = this.state.iiData;
      iiData.kpiData.KPIMappingID = "";
      iiData.kpiData.SourceName = "";
      iiData.kpiData.SourceFieldName = "";
      iiData.kpiData.KPIDesp = "";
      iiData.kpiData.MetricUnit = "";
      iiData.kpiData.MetricName = "";
      iiData.kpiData.MetricSeq = "";
      iiData.kpiData.LeadingPractice = "";
      iiData.kpiData.IsMultiLevel = false;
      iiData.kpiData.IsAggregation = false;
      iiData.LeadingAndSimilarCheck = false;
      this.isConnectorMetricDataFound = true;
      this.isSelectedMetricMappedToConnector = false;
      this.selectedMetricConnectorLastUpdated = undefined;
      if (iiData.kpiData.metDataAttr && iiData.kpiData.metDataAttr.length > 0) {
        iiData.kpiData.metDataAttr[0].AttributeValue = "";
        iiData.kpiData.metDataAttr[1].AttributeValue = "";
      }

      // RESET THE METRIC DEFINITION DROPDOWN OPTIONS AND SELECTED METRIC DEFINITION
      iiData.kpiData.MetricDefination = [];
      iiData.kpiData.MetricDefinitionID = "";

      this.setState({
        iiData: iiData,
      });
    } else {
      let errors = {};
      this.setState({
        errors: errors,
      });
      let iiData = this.state.iiData;
      iiData.kpiData.IsMultiLevel = false;
      iiData.kpiData.IsAggregation = false;
      iiData.kpiData.CategoryDetails = [];

      iiData.kpiData.KPIMappingID = event.target.value;
      iiData.kpiData.SourceName = "";
      iiData.kpiData.SourceFieldName = "";
      iiData.kpiData.MetricValue = "";
      if (iiData.kpiData.KPIMappingID !== "-1") {
        let selectedMetric = this.state.MetricNameOptions.filter((data) => data.KpiMappingID == event.target.value)[0];
        this.isConnectorMetricDataFound = selectedMetric.IsConnectorMetricDataFound;
        this.isSelectedMetricMappedToConnector = selectedMetric.IsConnector;
        this.selectedMetricConnectorLastUpdated = selectedMetric.ConnectorUpdatedTS;
		iiData.MetricValue=selectedMetric.IsConnector ?selectedMetric.MetricValue:"";
        iiData.kpiData.KPIDesp = this.state.MetricNameOptions.filter(
          (data) => data.KpiMappingID == event.target.value
        )[0].MetricDescription;
        iiData.kpiData.MetricUnit = this.state.MetricNameOptions.filter(
          (data) => data.KpiMappingID == event.target.value
        )[0].MetricUnit;
        iiData.kpiData.MetricName = this.state.MetricNameOptions.filter(
          (data) => data.KpiMappingID == event.target.value
        )[0].MetricName;
        iiData.kpiData.MetricSeq = this.state.MetricNameOptions.filter(
          (data) => data.KpiMappingID == event.target.value
        )[0].MetricSeq;
        iiData.kpiData.BaselineValue = this.state.MetricNameOptions.filter(
					(data) => data.KpiMappingID == event.target.value
				)[0].BaselineValue;
				iiData.kpiData.BaseLineMonth = this.state.MetricNameOptions.filter(
					(data) => data.KpiMappingID == event.target.value
				)[0].BaseLineMonth;
				let BaselineMonthReadOnly=iiData.kpiData.BaselineValue?false:true;
        		this.setState({BaselineMonthReadOnly:BaselineMonthReadOnly})
				iiData.kpiData.TargetValue = this.state.MetricNameOptions.filter(
					(data) => data.KpiMappingID == event.target.value
				)[0].TargetValue;
        iiData.kpiData.LeadingPractice = this.state.MetricNameOptions.filter(
          (data) => data.KpiMappingID == event.target.value
        )[0].LeadingPractice;
        // SET KPI MULTIPLE DEFINITIONS
        iiData.kpiData.MetricDefination = this.state.MetricNameOptions.filter(
          (data) => data.KpiMappingID == event.target.value
        )[0].MetricDefination;

        // FILTER OUT THE METRIC DEFINITIONS THAT ARE "ENABLED"
        const kpiMetricDefinitions = _.get(this.state, "iiData.kpiData.MetricDefination", []);
        const enabledMetricDefinitions = _.filter(
          kpiMetricDefinitions,
          (eachMetricDefinition) => String(eachMetricDefinition.IsEnabled) === "true"
        );

        // RESET THE SELECTED METRIC DEFINITION
        iiData.kpiData.MetricDefinitionID = "";
        // this.state.iiData.kpiData.MetricDefinitionID
        if (enabledMetricDefinitions.length === 0) {
          const kpiDat = this.state.iiData.kpiData;
          kpiDat.MetricDefinitionID = -1;
          this.setState({
            iiData: kpiDat,
          });
        }

        iiData.LeadingAndSimilarCheck = false;
        if (iiData.kpiData.metDataAttr && iiData.kpiData.metDataAttr.length > 0) {
          iiData.kpiData.metDataAttr[0].AttributeValue = this.state.MetricNameOptions.filter(
            (data) => data.KpiMappingID == event.target.value
          )[0].Attribute1Value;
          iiData.kpiData.metDataAttr[1].AttributeValue = this.state.MetricNameOptions.filter(
            (data) => data.KpiMappingID == event.target.value
          )[0].Attribute2Value;
          if (iiData.kpiData.metDataAttr[0].AttributeValue) {
            iiData.LeadingAndSimilarCheck = true;
          }
        }
      } else {
        this.setState({
          viewmore: true,
          show: false,
        });
      }
      let con = "";
      let valueConnector = "";
      let metriName = this.state.MetricNameOptions.filter((eachValue) => eachValue.KpiMappingID == event.target.value);
      if (metriName.length > 0) {
        let connectorName = metriName[0].ConnectorName;
        let connectorId = metriName[0].ConnectorID;
        let criteria = metriName[0].Criteria;
        let MetricValue = metriName[0].MetricValue;
        let IsAccentureOwned = metriName[0].IsAccentureOwned;
        let KpiMetricConnectorID = metriName[0].KpiMetricConnectorID;

        if (connectorName == null) {
          con = false;
          iiData.kpiData.ConnectorNames = connectorName;
          iiData.kpiData.ConnectorID = connectorId;
          iiData.kpiData.SourceFieldName = criteria;
          iiData.kpiData.MetricValue = "";
          iiData.kpiData.IsAccentureOwned = IsAccentureOwned;
          iiData.kpiData.KpiMetricConnectorID = KpiMetricConnectorID;
        } else {
          con = true;
          iiData.kpiData.ConnectorNames = connectorName;
          iiData.kpiData.ConnectorID = connectorId;
          iiData.kpiData.SourceFieldName = criteria;
          iiData.kpiData.MetricValue = MetricValue;
          iiData.kpiData.IsAccentureOwned = IsAccentureOwned;
          iiData.kpiData.KpiMetricConnectorID = KpiMetricConnectorID;
          if (metriName[0].IsConnector == true && metriName[0].IsTransaction == false) {
            valueConnector = true;
          }
        }
      }
      let connectorOptions = metriName.map((eachProcess) => (
        <option key={eachProcess.ConnectorID} value={eachProcess.ConnectorID}>
          {eachProcess.ConnectorName}
        </option>
      ));
      let criteriaOptions = metriName.map((eachProcess, index) => (
        <option key={index} value={eachProcess.Criteria}>
          {eachProcess.Criteria}
        </option>
      ));

      this.setState({
        iiData: iiData,
        con,
        criteriaOptions,
        connectorOptions,
        valueConnector,
      });
    }
  }

  // onMetricNameChange(event) {
  //     let errors = {};
  //     this.setState({
  //         errors: errors
  //     });
  //     let iiData = this.state.iiData;
  //     iiData.SOCategoryName = this.state.iiData.SOCategoryName;
  //     iiData.kpiData.MetricName = event.target.value;
  //     this.setState({
  //         iiData: iiData
  //     })
  // }

  onMetricSequenceChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    let iiData = this.state.iiData;
    iiData.kpiData.MetricSeq = event.target.value;
    this.setState({
      iiData: iiData,
    });
  }

  onMetricDescChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    let iiData = this.state.iiData;
    iiData.kpiData.KPIDesp = event.target.value;
    this.setState({
      iiData: iiData,
    });
  }

  onIconChange(event) {}
  onValueDescriptionChange(event) {
    let str = event.target.value;
    let err = this.state.commentsError;
    if (event.target.value) {
      if (regexForTextAreas.test(str)) {
        if (str && str.length < 300) {
          let iiData = this.state.iiData;
          iiData.kpiData.Comments = event.target.value;
          err = "";
          this.setState({
            iiData: iiData,
            commentsError: err,
          });
        } else {
          let iiData = this.state.iiData;
          iiData.kpiData.Comments = event.target.value;
          err = i18n.t("commentsErrorM1");
          this.setState({
            iiData: iiData,
            commentsError: err,
          });
        }
      } else {
        let iiData = this.state.iiData;
        iiData.kpiData.Comments = event.target.value;
        err = i18n.t("commentsErrorM2");
        this.setState({
          iiData: iiData,
          commentsError: err,
        });
      }
    } else {
      let iiData = this.state.iiData;
      iiData.kpiData.Comments = event.target.value;
      err = "";
      this.setState({
        iiData: iiData,
        commentsError: err,
      });
    }
  }
  bindAutoAggregationChange = (e) => {
    const kpiData = this.state.iiData.kpiData;

    kpiData.IsAggregation = e.target.checked;

    this.setState({
      data: {
        kpiData: kpiData,
      },
    });

    if (kpiData.IsAggregation && !this.state.valueConnector && !this.state.valueConnectorEdit) {
      this.calculateAggregateValueByFormula();
    }
  };
  calculateAggregateValueByFormula = () => {
    const kpiData = this.state.iiData.kpiData;
    const metricUnit = kpiData.MetricUnit;
    let temp = 0;
    let formulaStr = "";
    if (!kpiData.FormulaList) kpiData.FormulaList = [];
    if (metricUnit.trim() === "%") {
      if (kpiData.FormulaList.length > 0) {
        formulaStr = kpiData.FormulaList.filter((z) => z.IsPercentageUnit === true)[0].Formula;
      }
    } else {
      if (kpiData.FormulaList.length > 0) {
        formulaStr = kpiData.FormulaList.filter((z) => z.IsPercentageUnit === false)[0].Formula;
      }
      kpiData.formulaStr = formulaStr;
      this.setState({
        data: {
          kpiData: kpiData,
        },
      });
    }
    if (kpiData.CategoryDetails && kpiData.CategoryDetails.length > 0) {
      let total = 0;

      let numeratorSum = 0;
      let denominatorSum = 0;

      switch (formulaStr.toUpperCase().trim()) {
        case "SUM":
          kpiData.CategoryDetails.forEach(function(arrayItem) {
            if (!arrayItem.Denominator) {
              temp = 1;

              // arrayItem.Denominator = 1;
              // temp = true;
            } else {
              temp = arrayItem.Denominator;
            }
            total = total + arrayItem.NumeratorValue / temp;
          });

          break;
        case "AVERAGE":
          kpiData.CategoryDetails.forEach(function(arrayItem) {
            if (!arrayItem.Denominator) {
              temp = 1;

              // arrayItem.Denominator = 1;
              // temp = true;
            } else {
              temp = arrayItem.Denominator;
            }
            total = total + arrayItem.NumeratorValue / temp;
          });
          total = total / kpiData.CategoryDetails.length;

          break;
        case "WEIGHTED AVERAGE":
          kpiData.CategoryDetails.forEach(function(arrayItem) {
            if (!arrayItem.Denominator) {
              arrayItem.Denominator = 100;
            }
            // let individualPercentage = (arrayItem.NumeratorValue / arrayItem.Denominator) * 100;
            // total = total + individualPercentage; //75+30

            numeratorSum += parseFloat(arrayItem.NumeratorValue);
            denominatorSum += parseFloat(arrayItem.Denominator);
          });

          total = (numeratorSum / denominatorSum) * 100;
          break;
        default:
          trycatchAlertPopup('Formula not supported currently');
      }
      kpiData.MetricValue = total;

      this.setState({
        data: {
          kpiData: kpiData,
        },
      });
    }
  };
  onUnitChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    let iiData = this.state.iiData;
    iiData.kpiData.MetricUnit = event.target.value;
    this.setState({
      iiData: iiData,
    });
  }

  onValueChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    let iiData = this.state.iiData;
    if (event.target.value === "") {
      iiData.kpiData.MetricValue = event.target.value;
      this.setState({
        iiData: iiData,
      });
    } else {
      if (regexForNegativeNumbers.test(event.target.value)) {
        if (event.target.value === "-.") {
          iiData.kpiData.MetricValue = "-0.";
        } else {
          iiData.kpiData.MetricValue = event.target.value;
        }

        this.setState({
          iiData: iiData,
        });
      }
    }
  }

  onStatusChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    let iiData = this.state.iiData;
    iiData.kpiData.RAGStatus = event.target.value;
    this.setState({
      iiData: iiData,
    });
  }

  onUpperChange(event) {
    let iiData = this.state.iiData;
    if (event.target.value === "") {
      iiData.kpiData.UpperThreshold = event.target.value;
      this.setState({
        iiData: iiData,
      });
    } else {
      if (regexForNumbersOnlyMTC.test(event.target.value)) {
        iiData.kpiData.UpperThreshold = event.target.value;
        this.setState({
          iiData: iiData,
        });
      }
    }
  }

	onLowerChange(event) {
		let iiData = this.state.iiData;
		if (event.target.value === "") {
			iiData.kpiData.LowerThreshold = event.target.value;
			this.setState({
				iiData: iiData,
			});
		} else {
			if (regexForNumbersOnlyMTC.test(event.target.value)) {
				iiData.kpiData.LowerThreshold = event.target.value;
				this.setState({
					iiData: iiData,
				});
			}
		}

	}
  onBaselineValueChange(event){
    const iiData = this.state.iiData;
    if(event.target.value==="")
    {
        iiData.kpiData.BaselineValue = event.target.value;
        iiData.kpiData.BaseLineMonth = "";
        this.setState({
          iiData: iiData,
          BaselineMonthReadOnly:true
        })
    }
    else{
        if(regexForNegativeNumbers.test(event.target.value)){
          if(event.target.value === "-."){
            iiData.kpiData.BaselineValue  = "-0.";  
          }else{
            iiData.kpiData.BaselineValue = event.target.value;
          }
            this.setState({
              iiData: iiData,
              BaselineMonthReadOnly:false
            })
        }
    }
  }
  onBaselineMonthChange = (date) => {
    const iiData = this.state.iiData;
      iiData.kpiData.BaseLineMonth=date;
    this.setState({
      iiData : iiData
    })
  } 
  onTargetValueChange(event){
      const iiData = this.state.iiData;
      if(event.target.value==="")
      {
          iiData.kpiData.TargetValue = event.target.value;
          this.setState({
            iiData: iiData
          })
      }
      else{
          if(regexForNegativeNumbers.test(event.target.value)){
            if(event.target.value === "-."){
              iiData.kpiData.TargetValue  = "-0.";  
            }else{
              iiData.kpiData.TargetValue = event.target.value;
            }
              this.setState({
                iiData: iiData,
              })
          }
      }
  }
  maxLengthCheckThreshold = (object) => {
    if (object.target.value.length > object.target.maxLength) {
     object.target.value = object.target.value.slice(0, object.target.maxLength)
      }
    }
	onLeadingIndustryChange(event) {
		const iidata = this.state.iiData;
		if (iidata.kpiData.metDataAttr && iidata.kpiData.metDataAttr.length > 0) {
			iidata.kpiData.metDataAttr[0].AttributeValue = event.target.value.trim();

      this.setState({
        iiData: iidata,
      });
    }
  }
  onSimilarIndustryChange(event) {
    const iidata = this.state.iiData;
    if (iidata.kpiData.metDataAttr && iidata.kpiData.metDataAttr.length > 0) {
      iidata.kpiData.metDataAttr[1].AttributeValue = event.target.value.trim();
      this.setState({
        iiData: iidata,
      });
    }
  }

  onSourceChange(event) {
    const iiData = this.state.iiData;
    iiData.kpiData.ConnectorID = event.target.value;
    var index = event.nativeEvent.target.selectedIndex;
    iiData.kpiData.ConnectorNames = event.nativeEvent.target[index].text;
    iiData.kpiData.SourceName = "";
    iiData.kpiData.SourceFieldName = "";
    this.setState({
      iiData: iiData,
    });

    // if (iiData.ConnectorNames == "Manual") {
    //     document.getElementById('select02').disabled = true;
    // }
    // else {
    //     document.getElementById('select02').disabled = false;

    // }
  }

  onToolOwnerChange(event) {
    const iiData = this.state.iiData;
    iiData.kpiData.IsAccentureOwned = event.target.checked;
    this.setState({
      data: {
        iiData: iiData,
      },
    });
  }

  onSourceSystemChange(event) {
    const iiData = this.state.iiData;
    iiData.kpiData.SourceFieldName = event.target.value;
    this.setState({
      iiData: iiData,
    });
  }
  onOtherSystemChange(event) {
    const iiData = this.state.iiData;
    iiData.kpiData.SourceName = event.target.value;
    this.setState({
      iiData: iiData,
    });
  }

  handleSave(e) {
    if (this.validateFormMetricGroup()) {
      var iiData = {
        SOCategoryID: this.state.iiData.SOCategoryID,
        ParentSOCategoryID: this.props.MetricGroupsID,
        SOCategoryName: this.state.iiData.SOCategoryName,
        SOCategoryDesp: this.state.iiData.SOCategoryDesp,
        SoCategoryImage: null,
        SOCategoryTypeID: 1,
        SequenceID: null,
        SOCategorySeq: this.state.iiData.SOCategorySeq,
        kpiData: [],
      };

      this.setState({
        iiData: iiData,
      });
      if (String(this.state.type) === "Left_Panel" && this.state.isKpi != "add kpi") {
        store.dispatch({
          type: "Left_Panel",
          payload: iiData,
        });
      } else if (String(this.state.type) === "Right_Panel" && this.state.isKpi != "add kpi") {
        store.dispatch({
          type: "Right_Panel",
          payload: iiData,
        });
      } else if (String(this.state.isKpi) === "add kpi") {
        let addKpiData = this.state.addKpiData;
        this.setState({
          addKpiData: iiData.kpiData,
        });
        // addKpiData.SOCategoryName = this.props.Details.SOCategoryName;
        store.dispatch({
          type: "ADD_EDIT_KPI1",
          payload: addKpiData,
        });
      }

      this.handleHide();
    }
  }

  handleSaveInsight(e) {
    if (this.validateFormInsightDetails()) {
      let iiData = this.state.iiData;
      iiData.kpiData.KPIMappingID = iiData.kpiData.KPIMappingID == 0 ? Math.random() : iiData.kpiData.KPIMappingID;
      iiData.kpiData.ProjectName = iiData.kpiData.ProjectName.trim();
      if(iiData.kpiData.BaseLineMonth!=null && iiData.kpiData.BaseLineMonth!=""){
        var baselinemonth= new Date(iiData.kpiData.BaseLineMonth).getFullYear() +
        "-" +
        (new Date(iiData.kpiData.BaseLineMonth).getMonth() + 1 < 10
          ? "0" + (new Date(iiData.kpiData.BaseLineMonth).getMonth() + 1)
          : new Date(iiData.kpiData.BaseLineMonth).getMonth() + 1);
          iiData.kpiData.BaseLineMonth=baselinemonth;
      }
      this.setState({
        iiData: iiData,
      });
      let currentData = {
        ConfiguratorDataModels: { SOCategoryID: this.props.SOCategoryID, kpiData: iiData.kpiData },
        FiltersIDsVM: {
          OrgEntityID: this.props.filterDetails.Client,
          OfferingID: this.props.filterDetails.process,
          LocationID: this.props.filterDetails.Locations,
          MonthDT: this.props.filterDetails.Month,
        },
      };
      // store.getState().diverseReducers.diverse
      axiosInstance
        .post(`${LocalApiBaseUrl}Configurator/SaveConfiguratorDealMetrics`, currentData)
        .then((response) => {
          if (response.data.ConfiguratorDataModels.kpiData) {
            axiosInstance
              .get(`${LocalApiBaseUrl}Configurator/GetInsightsIntelligenceQualityScore`, {
                params: {
                  LocationID: this.props.filterDetails.Locations,
                  OrgEntityID: this.props.filterDetails.Client,
                  OfferingID: this.props.filterDetails.process,
                  MonthDT: this.props.filterDetails.Month,
                  LanguageID: store.getState().CurrentFilter.languageData.id,
                },
              })
              .then((response) => {
                if (response.data) {
                  store.dispatch({
                    type: "SAVE_QC_SCORE_OF_EACH_TAB",
                    payload: response.data,
                  });
                }

                this.handleHide(e);
              })
              .catch((error) => {
                trycatchAlertPopup(error);
                this.handleHide(e);
              });
            iiData.kpiData = response.data.ConfiguratorDataModels.kpiData;
            store.dispatch({
              type: "ADD_EDIT_KPI1",
              payload: iiData,
            });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          window.alert(this.ssErrMsg + error);
          this.handleHide(e);
        });
    }
  }

  handleHide = (e) => {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
        // this.props.onClose("from x");
      }
    } else {
      this.props.onClose(e);
    }
  };

  onRadioCheck() {
    if (document.getElementById("InsightDetailCheck").checked) {
      this.state.isCheck = false;
      document.getElementById("MetricGroupCheck").checked = false;
    } else {
      this.state.isCheck = true;
    }
    let iiData = this.state.iiData;
    let kpiData = this.state.iiData.kpiData;
    this.setState({
      iiData: iiData,
      kpiData: kpiData,
    });
  }

  validateFormMetricGroup() {
    let iiData = this.state.iiData;
    let errors = {};
    let formIsValidMetric = true;

    if (!iiData["SOCategoryName"]) {
      formIsValidMetric = false;
      errors["SOCategoryName"] = i18n.t("addWoErrorM1");
    }

    var data = store.getState();
    let iiDetails = data.intelligentInsightReducers["insightandintelligence"];

    if (iiDetails.length > 0) {
      let oldData = iiDetails.map(function(el) {
        return el.SOCategoryName;
      });
      if (oldData.includes(iiData.SOCategoryName) && !this.props.Details) {
        formIsValidMetric = false;
        errors["MetricGroupExists"] = i18n.t("Name_already_exists");
      }
    }

    if (formIsValidMetric) {
      this.setState({
        formIsValidMetric: true,
      });
    } else {
      this.setState({
        formIsValidMetric: false,
      });
    }
    if (!this.state.formHasValidName) {
      errors["MetricGroupExists"] = i18n.t("Name_already_exists");
      formIsValidMetric = false;
      this.setState({
        formIsValidMetric: false,
      });
    }
    this.setState({
      errors: errors,
    });

    return formIsValidMetric;
  }

  validateFormInsightDetails() {
    let kpiData = this.state.iiData.kpiData;
    let err="";
    let iiData = this.state.iiData;
    let errordescription=InvalidDescription();
    let errors = {};
    let formIsValidInsight = true;
    if(kpiData.Comments){
      if(kpiData.Comments.length>300){
      err = i18n.t("commentsErrorM1");
      formIsValidInsight = false;
      }
      else{
        if(!isValidText(kpiData.Comments)){
          err = errordescription;
          formIsValidInsight = false;
        }
      }
      this.setState({
         commentsError: err
      });
    }
    if (kpiData.SourceFieldName) 
  {
    if(!isValidText(kpiData.SourceFieldName)){
      formIsValid = false;
      errors["SourceFieldName"] = errordescription;
    }
  }
    let attributeLabelsForBO = [];
    if (this.state.iiData.kpiData.isRelatorBlock) {
      attributeLabelsForBO = this.props.attributes.filter((z) => z.ApplicableForDD === false);
    }

    var data = store.getState();
    let iiDetails = data.intelligentInsightReducers["insightandintelligence"];
    let kpiDetails = this.state.kpiDetails;
    let isLeading =
      this.state.iiData.kpiData.metDataAttr && this.state.iiData.kpiData.metDataAttr.length > 0
        ? this.state.iiData.kpiData.metDataAttr[0].AttributeValue
        : "";
    let isSimilar =
      this.state.iiData.kpiData.metDataAttr && this.state.iiData.kpiData.metDataAttr.length > 0
        ? this.state.iiData.kpiData.metDataAttr[1].AttributeValue
        : "";
    if (!kpiData["MetricName"]) {
      formIsValidInsight = false;
      errors["MetricName"] = i18n.t("addWoErrorM1");
    }
    if (this.isSelectedMetricMappedToConnector && !this.isConnectorMetricDataFound) {
      formIsValidInsight = false;
      errors["MetricConnectorDataNotFound"] = i18n.t("Data_not_available_in_source_system") + ".";
      errors["IntegratedMetricsMessage"] = i18n.t("Integrated_metrics_will_get_auto_populated") + ".";
      if (this.selectedMetricConnectorLastUpdated) {
        errors["MetricConnectorLastPull"] =
          i18n.t("Last_pull_occurred_on") + " " + FormatDate(this.selectedMetricConnectorLastUpdated) + ".";
      } else {
        errors["MetricConnectorLastPull"] = i18n.t("Last_pull_occurred") + ' "' + i18n.t("Date_Unavailable") + "”.";
      }
    }
    if (!iiData["SOCategoryName"] || iiData.SOCategoryName == 0) {
      formIsValidInsight = false;
      errors["select"] = i18n.t("Select_Metric_Group");
    }

    if (!kpiData["MetricUnit"]) {
      formIsValidInsight = false;
      errors["MetricUnit"] = i18n.t("Unit_is_required");
    }

    if (this.state.commentsError) {
      formIsValidInsight = false;
    }
    if (!kpiData["RAGStatus"]) {
      formIsValidInsight = false;
      errors["RAGStatus"] = i18n.t("addBoErrorM3");
    }
    if (!this.isSelectedMetricMappedToConnector) {
      if (!kpiData["MetricValue"] && !(kpiData["MetricValue"] === 0)) {
        formIsValidInsight = false;
        errors["MetricValue"] = i18n.t("addBoErrorM1");
      } else if (!(kpiData["MetricValue"] >= -999999999999 && kpiData["MetricValue"] <= 999999999999)) {
        formIsValidInsight = false;
        errors["MetricValueRange"] = i18n.t("addBoErrorM6");
      }
    }
    if (!(kpiData["BaselineValue"] >= -99999999 && kpiData["BaselineValue"] <= 99999999)) {
      formIsValidInsight = false;
      errors["BaselineValue"] = i18n.t("Baseline_validation");
    }

    if (!(kpiData["TargetValue"] >= -99999999 && kpiData["TargetValue"] <= 99999999)) {
      formIsValidInsight = false;
      errors["TargetValue"] = i18n.t("target_validation");
    }
    if (kpiData["MetricSeq"]) kpiData["MetricSeq"] = parseInt(kpiData["MetricSeq"], 10);
    if (!kpiData["MetricSeq"]) {
      formIsValidInsight = false;
      errors["MetricSeq"] = i18n.t("addWoErrorM5");
    } else if (!(kpiData["MetricSeq"] >= 0 && kpiData["MetricSeq"] <= 999999)) {
      formIsValidInsight = false;
      errors["MetricSeqRange"] = i18n.t("addWoErrorM6");
    }

    if (!kpiData["ConnectorID"]) {
      formIsValidInsight = false;
      errors["ConnectorID"] = i18n.t("Source_is_required");
    }
    if (this.state.iiData.kpiData.metDataAttr != null && this.state.iiData.kpiData.metDataAttr != undefined) {
      if (!isLeading && this.state.iiData.kpiData.isRelatorBlock) {
        formIsValidInsight = false;
        errors["LeadingIndustry"] = attributeLabelsForBO.length>0 ? attributeLabelsForBO[0].AttributeName1 :"" + " " + i18n.t("is_Required");
      }
      if (!isSimilar && this.state.iiData.kpiData.isRelatorBlock) {
        formIsValidInsight = false;
        errors["SimilarIndustry"] = attributeLabelsForBO.length>0 ?attributeLabelsForBO[0].AttributeName2 :"" + " " + i18n.t("is_Required");
      }

      //start validation for benchmark fields
      // validation for attribute1value and attribute2value
      // if (this.props.isRelatorBlockForOffering) {
      if (this.state.iiData.kpiData.isRelatorBlock) {
        if (
          (isLeading && !isLeading.toString().trim() && (isSimilar && !isSimilar.toString().trim())) ||
          (!isLeading && !isSimilar)
        ) {
          // attribute values can never be left empty - fill NA
          formIsValidInsight = false;
          errors["LeadingIndustry"] = i18n.t("AttributeValue_ErrMsg1");
          errors["SimilarIndustry"] = i18n.t("AttributeValue_ErrMsg1");
        }
        if (isLeading && isLeading.trim()) {
          if (Number.isNaN(parseFloat(isLeading.trim()))) {
            // it is a string
            if (isLeading.toLowerCase().trim() !== "na") {
              formIsValidInsight = false;
              // errors["Attribute1Value"] = "NA is the only permitted string value";
              errors["LeadingIndustry"] = i18n.t("AttributeValue_ErrMsg2");
            }
          } else {
            //attribute 2 is a whole number or decimal OR has alphabets and numbers ex. 12efgh3456
            if (!regexForNumbersOnlyMTC.test(isLeading.trim())) {
              formIsValidInsight = false;
              // errors["Attribute1Value"] = "NA is the only permitted string value";
              errors["LeadingIndustry"] = i18n.t("AttributeValue_ErrMsg2");
            }
            if (!(parseFloat(isLeading.trim()) >= 0 && parseFloat(isLeading.trim()) <= 999999)) {
              formIsValidInsight = false;

              // errors["Attribute1Value"] =
              //               `${this.state.attributeLabels.AttributeName1}` +
              //           " value must be in between 0 to 999999";
              errors["LeadingIndustry"] = `${attributeLabelsForBO.length>0 ? attributeLabelsForBO[0].AttributeName1 : ""}` + " " + i18n.t("addBoErrorM2");
            }
          }
        }

        if (isSimilar && isSimilar.trim()) {
          if (Number.isNaN(parseFloat(isSimilar.trim()))) {
            // it is a string
            if (isSimilar.toLowerCase().trim() !== "na") {
              formIsValidInsight = false;
              // errors["Attribute1Value"] = "NA is the only permitted string value";
              errors["SimilarIndustry"] = i18n.t("AttributeValue_ErrMsg2");
            }
          } else {
            //attribute 2 is a whole number or decimal OR has alphabets and numbers ex. 12efgh3456
            if (!regexForNumbersOnlyMTC.test(isSimilar.trim())) {
              formIsValidInsight = false;
              // errors["Attribute1Value"] = "NA is the only permitted string value";
              errors["SimilarIndustry"] = i18n.t("AttributeValue_ErrMsg2");
            }
            if (!(parseFloat(isSimilar.trim()) >= 0 && parseFloat(isSimilar.trim()) <= 999999)) {
              formIsValidInsight = false;

              // errors["Attribute1Value"] =
              //               `${this.state.attributeLabels.AttributeName1}` +
              //           " value must be in between 0 to 999999";
              errors["SimilarIndustry"] = `${attributeLabelsForBO.length>0 ? attributeLabelsForBO[0].AttributeName2 : ""}` + " " + i18n.t("addBoErrorM2");
            }
          }
        }
      }

      //end validation for benchmark fields

      // VALIDATE "METRIC DEFINITION" DROPDOWN FIELD
      if (kpiData.MetricDefination && kpiData.MetricDefination.length > 0 && !kpiData["MetricDefinitionID"]) {
        errors["MetricDefinitionID"] = i18n.t("addBoErrorM5");
        formIsValidInsight = false;
      } else {
      }

      if (!isSimilar && this.state.iiData.kpiData.isRelatorBlock) {
        formIsValidInsight = false;
        errors["SimilarIndustry"] = attributeLabelsForBO.length>0 ? attributeLabelsForBO[0].AttributeName2 : "" + " " + i18n.t("is_Required");
      }
      // else{
      //     var isValid = this.state.iiData.kpiData.metDataAttr[1].AttributeValue.length<=50;

      //     if (!isValid) {
      //         formIsValidInsight = false;
      //         errors["SimilarIndustry"] = "Please enter a maximum of 50 characters";
      //     }
      // }
      else {
        // if (!(isSimilar >= 0 && isSimilar <= 999999)&&this.state.iiData.kpiData.isRelatorBlock) {
        //     formIsValidInsight = false;
        //     errors["SimilarIndustry"] = "Value must be in between 0 to 999999";
        // }
      }
      if (
        parseInt(this.state.selectedApplication, 10)  === 1 &&
        parseInt(kpiData["PulseToolsConfigDetailsID"], 10) === 0 &&
        kpiData["toolID"] === 0
      ) {
        formIsValidInsight = false;
        errors["Tool"] = i18n.t("Tool_is_Required");
      }
      if (parseInt(this.state.selectedApplication, 10)  === 2 && _.isEmpty(String(kpiData["ProjectName"]).trim())) {
        formIsValidInsight = false;
        errors["Project"] = i18n.t("Project_is_Required");
      }

      if (
        this.state.selectedApplication == 2 &&
        !_.isEmpty(String(kpiData["ProjectName"]).trim()) &&
        !regexForFields.test(String(kpiData["ProjectName"]).trim())
      ) {
        formIsValidInsight = false;
        errors["Project"] = i18n.t("Project_Contains_Characters");
      }
      if (kpiData["IsMultiLevel"]) {
        if (kpiData.CategoryDetails.length === 0) {
          errors["MultiLevel"] = i18n.t("noCategories");
          formIsValidInsight = false;
        }
      }
      this.setState({
        errors: errors,
      });
      if (formIsValidInsight) {
        this.setState({
          formIsValidInsight: true,
        });
      } else {
        this.setState({
          formIsValidInsight: false,
        });
      }
      return formIsValidInsight;
    }
  }
  // WOULD BE EXECUTED WHEN THE "METRIC DEFINITION" DROPDOWN WOULD BE CHANGED
  handleChangeMetricDefinition = (e) => {
    const MetricDefinitionID = e.target.value;
    this.setState((prevState) => ({
      iiData: {
        ...prevState.iiData,
        kpiData: {
          ...prevState.iiData.kpiData,
          MetricDefinitionID,
        },
      },
    }));
  };

  removeDuplicates(arr) {
    /*NEW CODE WHICH REMOVES DUPLICATES DATA FROM API */
    const map = new Map();
    arr.forEach((v) => map.set(v.CategoryType, v));
    return [...map.values()];
  }

  CatTypeSelect(e) {
    /* let errors={}
        errors.CategoryType=""
        this.setState({
            errors:errors
        }) */

    let CatTypeID = e.target.value;
    this.setState({ CatTypeID: CatTypeID });

    setTimeout(() => {
      if (CatTypeID == "addnew") {
        CategoryDetails.CategoryType = "";
        CategoryDetails.CategoryName = "";
        this.setState({
          errors: {},
          showCategory: false,
          showaddCategoryType: true,
          CategoryDetails: CategoryDetails,
        });
      } else if (CatTypeID == "0") {
        //CHANGE2
        CategoryDetails.CategoryType = "";
        CategoryDetails.CategoryName = "";
        this.setState({
          CatNameID: 0,
          showaddCategoryType: false,
          showaddCategory: false,
          showCategory: true,
          CategoryDetails: CategoryDetails,
        });
      } else if (CatTypeID == "addnewcatname") {
        this.setState({
          showCategory: false,
          showaddCategory: true,
        });
      } else {
        this.setState({
          showaddCategoryType: false,
          showaddCategory: false,
          showCategory: true,
        });
      }
    }, 500);

    let CatType =
      CatTypeID && CatTypeID != "0" && CatTypeID != "addnew"
        ? this.state.allCategoryTypesAndNames.filter((eachValue) => eachValue.ID == CatTypeID)[0].CategoryType
        : []; //FETCHING individual CATEGORY TYPE

    let CategoryDetails = this.state.CategoryDetails;
    CategoryDetails.CategoryType = CatType;
    this.setState({
      CategoryDetails: CategoryDetails,
    });

    let CategoryNameData =
      CatType && CatType != ""
        ? this.state.allCategoryTypesAndNames.filter((eachValue) => eachValue.CategoryType == CatType)
        : []; //FETCHING CATEGORY NAME

    this.setState({ CategoryNameData: CategoryNameData });
  }

  handleDropdownChange(e) {
    let CatNameID = e.target.value;
    this.setState({ errors: {}, CatNameID: CatNameID });

    setTimeout(() => {
      if (String(CatNameID) === "addnew") {
        this.setState({
          showCategory: false,
          showaddCategoryType: true,
        });
      } else if (String(CatNameID) === "addnewcatname") {
        if (!this.state.CategoryDetails.CategoryType) {
          //category type is empty

          let error = {};
          error["CategoryType"] = i18n.t("catTypeReqd");

          this.setState({ errors: error, CatNameID: 0 });
        } else {
          CategoryDetails.CategoryName = "";
          this.setState({
            showCategory: false,
            showaddCategory: true,
            CategoryDetails: CategoryDetails,
          });
        }
      } else {
        this.setState({
          showaddCategoryType: false,
          showaddCategory: false,
          showCategory: true,
        });
      }
    }, 500);
    let CatName =
      CatNameID && CatNameID != "0" && CatNameID != "addnewcatname"
        ? this.state.allCategoryTypesAndNames.filter((eachValue) => eachValue.ID == CatNameID)[0].CategoryName
        : [];

    let CategoryDetails = this.state.CategoryDetails;
    CategoryDetails.CategoryName = CatName;
    this.setState({
      CategoryDetails: CategoryDetails,
    });
  }

  //called when new category name is added -- on clicking on the add button
  handleaddCategoryAdd() {
    const data = this.state.CategoryDetails;
    let errordescription=InvalidDescription();
    const param = {
      OfferingId: parseInt(this.props.filterDetails.process),
      CatType: data.CategoryType.toString().trim(),
      CatName: data.CategoryName.toString().trim(),
      LocationID: this.props.filterDetails.Locations,
      SOCategoryID: 4,
      OrgEntityID: this.props.filterDetails.Client,
    };
    let error = {};
    if (!data.CategoryType.trim()) {
      error["CategoryType"] = i18n.t("catTypeReqd");
    }
    else if(!isValidText(data.CategoryType.trim())){
      error["CategoryType"] = errordescription;
    }

    if (!data.CategoryName.trim()) {
      error["CategoryName"] = i18n.t("catNameReqd");
    }
    else if(!isValidText(data.CategoryName.trim())){
      error["CategoryName"] = errordescription;
    }

    this.setState({ errors: error });

    if (data.CategoryType.trim() && data.CategoryName.trim()) {
      axiosInstance
        .post(`${LocalApiBaseUrl}MultiLevelApproval/SendCategoryTypeNameForApproval`, param, {})
        .then((response) => {
          //window.alert(response.data.StatusMessage);
          if (response.data.IsSuccess && response.data.StatusMessage == "Sent For Approval") {
            // this.onClose();
            this.setState({
              showaddCategory: false,
              showaddCategoryType: false,
              showApproval: true,
            });
          } else {
            window.alert(response.data.StatusMessage);
            this.setState({ showLoaderOnPage: false });
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          window.alert(this.ssErrMsg + error);
        });
    }
  }

  handleCategoryAdd() {
    let CategoryDetails = [];
    const kpiData = this.state.iiData.kpiData;
    let error = {};

    if (!this.state.numeratorVal) {
      error["NumeratorValue"] = i18n.t("numeratorIsReqd");

      //this.setState({ errors: error });
    }
    // else if (!(kpiData["MetricValue"] >= -99999999 && kpiData["MetricValue"] <= 99999999)
    else if (!(parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999)) {
      error["NumeratorValueRange"] = i18n.t("addBoErrorM6");

      // this.setState({ errors: error });
    }
    if (kpiData.MetricUnit.trim() === "%") {
      if (!this.state.denomVal) {

        error["Denominator"] = i18n.t("denominatorIsReqd");
      } else if (parseFloat(this.state.denomVal) === 0) {
        error["Denominator"] = i18n.t("denomCannotZero");
      } else if (!(parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) {
        error["DenominatorValueRange"] = i18n.t("addBoErrorM6");
      }
    } else {
      if (!Number.isNaN(parseFloat(this.state.denomVal))) {
        if (parseFloat(this.state.denomVal) === 0) {

          error["Denominator"] = i18n.t("denomCannotzero");
        } else if (!(parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) {
          error["DenominatorValueRange"] = i18n.t("addBoErrorM6");
        }
      }
    }

    if (this.state.CatTypeID == "0") {
      error["CategoryType"] = i18n.t("catTypeReqd");
    }

    if (String(this.state.CatNameID) === "0") {
      error["CategoryName"] = i18n.t("catNameReqd");
    }

    this.setState({ errors: error });

    if (
      kpiData.MetricUnit.trim() === "%"
        ? this.state.CategoryDetails.CategoryType &&
          this.state.CatTypeID != "0" &&
          this.state.CategoryDetails.CategoryName &&
          this.state.CatNameID != "0" &&
          this.state.numeratorVal &&
          (parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999) &&
          this.state.denomVal &&
          parseFloat(this.state.denomVal) != 0 &&
          (parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)
        : this.state.CategoryDetails.CategoryType &&
          this.state.CatTypeID != "0" &&
          this.state.CategoryDetails.CategoryName &&
          this.state.CatNameID != "0" &&
          this.state.numeratorVal &&
          (parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999) &&
          (!Number.isNaN(parseFloat(this.state.denomVal))
            ? parseFloat(this.state.denomVal) != 0 &&
              parseFloat(this.state.denomVal) >= -999999999999 &&
              parseFloat(this.state.denomVal) <= 999999999999
            : true)
    ) {
      if (
        kpiData.CategoryDetails.filter((cat) => cat.CategoryName === this.state.CategoryDetails.CategoryName).length ===
        0
      ) {
        let CategoryName = this.state.CategoryDetails.CategoryName;

        let Catid =
          CategoryName && CategoryName !== ""
            ? this.state.CategoryNameData.filter((cat) => cat.CategoryName === CategoryName)[0].ID
            : null;

        if (kpiData.CategoryDetails && kpiData.CategoryDetails.length < this.state.categoriesLimit) {
          kpiData.CategoryDetails.push({
            CategoryType: this.state.CategoryDetails.CategoryType,
            CategoryName: this.state.CategoryDetails.CategoryName,
            NumeratorValue: this.state.numeratorVal,
            Denominator: this.state.denomVal,
            ConfigurationDealMetricID: 8,
            OfferingMultiLevelCategoryID: Catid,
          });

          // kpiData.IsMultiLevel= !kpiData.IsMultiLevel;
          this.setState({
            showCategory: false,
            data: {
              kpiData: kpiData,
            },
          });
        } else {
          let error = {};
          error["CategoryName"] = `${i18n.t("A_maximum_of")} ${this.state.categoriesLimit} ${i18n.t("category_values_are")}` 

          this.setState({ showCategory: true, errors: error });
        }

        if (kpiData.IsAggregation && !this.state.valueConnector && !this.state.valueConnectorEdit) {
          //calculate the metric value on the fly when new values added/ edited/deleted
          this.calculateAggregateValueByFormula();
        }
      } else {
        let error = {};
        error["CategoryName"] = i18n.t("duplicateCatName");

        this.setState({ errors: error });
      }
    } else {
      this.setState({
        showCategory: true,
      });
    }
  }
  handleCategoryEdit() {
    let error = {};
    let CategoryDetails = [];
    const kpiData = this.state.iiData.kpiData;
    if (!this.state.numeratorVal) {
      error["NumeratorValue"] = i18n.t("numeratorIsReqd");

      // this.setState({ errors: error });
    }
    // else if (!(kpiData["MetricValue"] >= -99999999 && kpiData["MetricValue"] <= 99999999)
    else if (!(parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999)) {
      error["NumeratorValueRange"] = i18n.t("addBoErrorM6");
    }
    if (kpiData.MetricUnit.trim() === "%") {
      if (!this.state.denomVal) {

        error["Denominator"] = i18n.t("denominatorIsReqd");
      } else if (parseFloat(this.state.denomVal) === 0) {
        error["Denominator"] = i18n.t("denomCannotZero");
      } else if (!(parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) {
        error["DenominatorValueRange"] = i18n.t("addBoErrorM6");
      }
    } else {
      if (!Number.isNaN(parseFloat(this.state.denomVal))) {
        if (parseFloat(this.state.denomVal) === 0) {

          error["Denominator"] = i18n.t("denomCannotzero");
        } else if (!(parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) {
          error["DenominatorValueRange"] = i18n.t("addBoErrorM6");
        }
      }
    }

    if (!this.state.CategoryDetails.CategoryType) {
      error["CategoryType"] = i18n.t("catTypeReqd");
    }

    if (this.state.CatNameID === "0") {
      error["CategoryName"] = i18n.t("catNameReqd");
    }
    this.setState({ errors: error });

    if (
      kpiData.MetricUnit.trim() === "%"
        ? this.state.CategoryDetails.CategoryType &&
          this.state.CatTypeID != "0" &&
          this.state.CategoryDetails.CategoryName &&
          this.state.CatNameID != "0" &&
          this.state.numeratorVal &&
          (parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999) &&
          this.state.denomVal &&
          parseFloat(this.state.denomVal) != 0 &&
          (parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)
        : this.state.CategoryDetails.CategoryType &&
          this.state.CatTypeID != "0" &&
          this.state.CategoryDetails.CategoryName &&
          this.state.CatNameID != "0" &&
          this.state.numeratorVal &&
          (parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999) &&
          (!Number.isNaN(parseFloat(this.state.denomVal))
            ? parseFloat(this.state.denomVal) != 0 &&
              parseFloat(this.state.denomVal) >= -999999999999 &&
              parseFloat(this.state.denomVal) <= 999999999999
            : true)
    ) {
      let index = this.state.EditIndex;
      if (
        kpiData.CategoryDetails.filter((cat) => cat.CategoryName === this.state.CategoryDetails.CategoryName).length ===
        0
      ) {
        kpiData.CategoryDetails[index].CategoryName = this.state.CategoryDetails.CategoryName;
        kpiData.CategoryDetails[index].NumeratorValue = this.state.numeratorVal;
        kpiData.CategoryDetails[index].Denominator = this.state.denomVal;
        kpiData.CategoryDetails[index].ConfigurationDealMetricID = 8;
        let Catid =
          kpiData.CategoryDetails[index].CategoryName && kpiData.CategoryDetails[index].CategoryName !== ""
            ? this.state.CategoryNameData.filter(
                (cat) => cat.CategoryName === kpiData.CategoryDetails[index].CategoryName
              )[0].ID
            : null;
        // kpiData.CategoryDetails[index].OfferingMultiLevelCategoryID = this.state.CategoryDetails.OfferingMultiLevelCategoryID
        //diverseData.CategoryDetails[index].OfferingMultiLevelCategoryID = diverseData.CategoryDetails[index].OfferingMultiLevelCategoryID
        kpiData.CategoryDetails[index].OfferingMultiLevelCategoryID = Catid;

        this.setState({
          data: {
            kpiData: kpiData,
          },
        });
        this.setState({
          showCategory: false,
        });
      } else if (
        kpiData.CategoryDetails.filter((cat) => cat.CategoryName === kpiData.CategoryDetails[index].CategoryName)
          .length === 1
      ) {
        if (
          kpiData.CategoryDetails[index].CategoryName.trim() === this.state.CategoryDetails.CategoryName.trim()
            ? false
            : kpiData.CategoryDetails.filter((cat) => cat.CategoryName === this.state.CategoryDetails.CategoryName)
                .length === 1
        ) {
          let error = {};
          error["CategoryName"] = i18n.t("duplicateCatName");

          this.setState({ errors: error });
        } else {
          kpiData.CategoryDetails[index].CategoryName = kpiData.CategoryDetails[index].CategoryName;
          kpiData.CategoryDetails[index].NumeratorValue = this.state.numeratorVal;
          kpiData.CategoryDetails[index].Denominator = this.state.denomVal;
          kpiData.CategoryDetails[index].ConfigurationDealMetricID = 8;
          kpiData.CategoryDetails[index].OfferingMultiLevelCategoryID =
            kpiData.CategoryDetails[index].OfferingMultiLevelCategoryID;

          this.setState({
            data: {
              kpiData: kpiData,
            },
          });
          if (kpiData.IsAggregation && !this.state.valueConnectorEdit && !this.state.valueConnector) {
            //calculate the metric value on the fly when new values added/ edited/deleted
            this.calculateAggregateValueByFormula();
          }
          this.setState({
            showCategory: false,
          });
        }
      } else {
        this.setState({
          showCategory: false,
        });
      }
    }
  }
  handleCategoryTypeAddShow() {
    this.setState({ errors: {} });
    let CatTypeID = this.state.CatTypeID;
    let CategoryDetails = this.state.CategoryDetails;
    CategoryDetails.CategoryName = "";
    this.setState({
      CategoryDetails: CategoryDetails,
      showCategory: true,
      isEdit: false,
      iscatypedisable: true,
      CatNameID: 0,
      CatTypeID: CatTypeID,
      numeratorVal: "",
      denomVal: "",
    });
  }

  deleteCategoryType() {
	if(window.confirm(i18n.t("delete_Category_msg")))
	{
    let kpiData = this.state.iiData.kpiData;
    kpiData.CategoryDetails = [];
    if (kpiData.IsAggregation && !this.state.valueConnector && !this.state.valueConnectorEdit) {
      // if there are no categories, clear out the value calculated using the formula
      kpiData.MetricValue = "";
    }

    this.setState({
      CatNameID: 0,
      CatTypeID: 0,
      CategoryNameData: [],
      data: {
        kpiData: kpiData,
      },
    });
}
  }

  handleCategoryEditShow(catNameToEdit) {
    this.setState({ errors: {} });
    var allCategoriesList = this.state.iiData.kpiData.CategoryDetails;
    var index = allCategoriesList.findIndex((item) => item.CategoryName === catNameToEdit);
    let catnameid = this.state.CategoryNameData.filter((row) => row.CategoryName === catNameToEdit)[0].ID;
    let CategoryName = this.state.CategoryNameData.filter((row) => row.ID === catnameid)[0].CategoryName;
    let CategoryDetails = this.state.CategoryDetails;
    CategoryDetails.CategoryName = CategoryName;
    let numeratorVal = allCategoriesList[index].NumeratorValue;
    let denomVal = allCategoriesList[index].Denominator;
    this.setState({
      EditIndex: index,
      isEdit: true,
      showCategory: true,
      CatNameID: catnameid,
      CategoryDetails: CategoryDetails,
      CatTypeID: this.state.CatTypeID,
      numeratorVal: numeratorVal,
      denomVal: denomVal,
    });
  }

  handleaddCategoryNameClose() {
    this.setState({
      showaddCategory: false,
      errors: {},
    });
  }
  handleApprovalClose() {
    this.setState({ showApproval: false });
  }
  handleaddCategoryClose() {
    this.setState({
      showaddCategoryType: false,
      errors: {},
    });
  }
  handleCategoryClose() {
    this.setState({
      showCategory: false,
      errors: {},
    });
  }
  handleaddCategoryShow() {
    this.setState({ showaddCategory: true });
  }

  bindToolFieldChange = (e) => {
    const id = e.target.id;
    let data = this.state.CategoryDetails;
    switch (id) {
      case "categoryType":
        data.CategoryType = e.target.value;
        break;
      case "categoryName":
        data.CategoryName = e.target.value;
        break;
    }

    this.setState({
      CategoryDetails: data,
    });
  };
  onNumeratorChange = (e) => {
    let errors = this.state.errors;
    errors.NumeratorValue = "";
    this.setState({ errors: errors });
    if (e.target.value.length <= 12) {
      this.setState({ numeratorVal: e.target.value });
    }
  };

  onDenomChange = (e) => {
    let errors = this.state.errors;
    errors.Denominator = "";
    this.setState({ errors: errors });
    if (e.target.value.length <= 12) {
      this.setState({ denomVal: e.target.value });
    }
  };

  deleteCategoryName(catNameToDelete) {
    this.setState({ errors: {} });

    let kpiData = this.state.iiData.kpiData;
    let CatTypeID = this.state.CatTypeID;
    var allCategoriesList = this.state.iiData.kpiData.CategoryDetails;
    let CategoryNameData = this.state.CategoryNameData;
    allCategoriesList.splice(allCategoriesList.findIndex((item) => item.CategoryName === catNameToDelete), 1);

    if (allCategoriesList.length === 0) {
      kpiData.MetricValue = "";
    }

    this.setState({
      CatNameID: 0,
      CategoryNameData: allCategoriesList.length === 0 ? [] : CategoryNameData,
      CatTypeID: CatTypeID, //CHANGED
      data: {
        kpiData: kpiData,
      },
    });
    if (kpiData.IsAggregation && !this.state.valueConnector && !this.state.valueConnectorEdit) {
      //calculate the metric value on the fly when new values added/ edited/deleted
      this.calculateAggregateValueByFormula();
    }
  }

  handleCategoryShow() {
    this.setState({ errors: {} });
    let CategoryDetails = this.state.CategoryDetails;
    CategoryDetails.CategoryType = "";
    CategoryDetails.CategoryName = "";
    this.setState({
      showCategory: true,
      iscatypedisable: false,
      CategoryDetails: CategoryDetails,
      isEdit: false,
      CategoryNameData: [],
      CatNameID: 0,
      CatTypeID: 0,
      numeratorVal: "",
      denomVal: "",
    });
  }

  bindMetricDefinationChange = (e) => {
    let id = e.target.id;
    let data = this.state.iiData.kpiData;
    switch (id) {
      case "chckTool":
      case "chckProject":
        /* case "chckNotApplicable": */
        this.setState({
          selectedApplication: e.target.value,
        });

        /* if (e.target.value == 0) {
					data.ApplicationMappingID = 0;
					data.CustomToolID=0;
					data.toolID=0;
					data.ApplicationMappingName = "";
					data.ProjectName = "";

				} */
        break;
      case "ddTool":
        if (e.target.value == "-1") {
          this.setState({
            showAddToolPopup: true,
          });
        } else {
          //data.ApplicationMappingID = e.target.value;
          let statestore = this.state.toolsApiData;
          let customobj =
            e.target.value !== "-1" && e.target.value !== "0"
              ? statestore.filter((row) => row.PulseToolsConfigDetailsID ===parseInt(e.target.value))[0]
              : null;
          if (customobj !== null) {
              data.PulseToolsConfigDetailsID = customobj.PulseToolsConfigDetailsID;
              data.ApplicationMappingID = 0;
            data.toolID = customobj.PulseToolsConfigDetailsID;
          }
          data.ApplicationMappingName = e.target.options[e.target.selectedIndex].text;
          data.ProjectName = "";
        }

        break;
      case "txtProject":
        data.ProjectName = e.target.value;

        data.ApplicationMappingID = 0;
        data.PulseToolsConfigDetailsID = 0;
        data.toolID = 0;
        data.ApplicationMappingName = "";
        break;
        default:
          return;
    }

    this.setState((prevState) => ({
      iiData: {
        ...prevState.iiData,
        kpiData: data,
      },
    }));
  };

  handleAddToolPopupClose = (e) => {
    this.setState({
      showAddToolPopup: false,
    });
    this.handleHide();
  };

  render() {
    //list of all category types and names for this offering - to be displayed in add/edit popup
    const allCatList = this.state.allCategoryTypesAndNames;
    const stateKpiData = this.state.iiData.kpiData;
    const currentDate = new Date();
       let{isApiLoading,toolsApi,errors}=this.state;
    let items = [];
    if (stateKpiData && stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0) {
      items = stateKpiData.CategoryDetails.map((item, key) => (
        <li>
          <span className="contentName">{item.CategoryName}</span>
          {stateKpiData.MetricUnit.trim() !== "%" && (
            <span className="contentValues">
              {/* <span>{item.NumeratorValue}</span> */}
              <span>
              {thousandSeparatorFormat(item.NumeratorValue) % 1 != 0
                  ? MetricValueThousandFormat(stateKpiData.MetricUnit,item.NumeratorValue,2)
                  : MetricValueThousandFormat(stateKpiData.MetricUnit,item.NumeratorValue,2)}
              </span>
              {item.Denominator && <span> | </span>}
              {/* <span>{item.Denominator}</span> {stateKpiData.MetricUnit}</span>} */}
              <span>
              {item.Denominator
                  ? thousandSeparatorFormat(item.Denominator) % 1 != 0
                    ? MetricValueThousandFormat(stateKpiData.MetricUnit,item.Denominator,2)
                    : MetricValueThousandFormat(stateKpiData.MetricUnit,item.Denominator,2)
                  : ""}
              </span>{" "}
              {/* {stateKpiData.MetricUnit} */}
            </span>
          )}

          {stateKpiData.MetricUnit.trim() === "%" && (
            <span className="contentValues">
              <span>
              {thousandSeparatorFormat((item.NumeratorValue * 100) / item.Denominator) % 1 != 0
                  ? thousandSeparatorFormat(((item.NumeratorValue * 100) / item.Denominator),2)
                  : thousandSeparatorFormat(((item.NumeratorValue * 100) / item.Denominator),2)}
              </span>{" "}
              {stateKpiData.MetricUnit}
            </span>
          )}

          <div className="item-list-hover">
            <span>
              <i onClick={() => this.deleteCategoryName(item.CategoryName)} class="far fa-times">
                {" "}
              </i>
              <i onClick={() => this.handleCategoryEditShow(item.CategoryName)} class="fas fa-edit"></i>
            </span>
          </div>
        </li>
      ));
    }

    let isScreenInEditMode = false;
    if (!_.isEmpty(this.props.kpiData)) {
      isScreenInEditMode = true;
    }

    // FILTER OUT THE METRIC DEFINITIONS THAT ARE "ENABLED"
    const kpiMetricDefinitions = _.get(this.state, "iiData.kpiData.MetricDefination", []);
    const enabledMetricDefinitions = _.filter(
      kpiMetricDefinitions,
      (eachMetricDefinition) => String(eachMetricDefinition.IsEnabled) === "true"
    );
    const attributeLabelsForBO = this.props.attributes.filter((z) => z.ApplicableForDD === false);
    const { showLoaderonPage }=this.state
    return (
      <>
        {/* <Header /> */}
        <Modal
          show={this.state.show}
          onHide={this.handleHide}
          className="modal fade bo-configurator-modal enhance"
          size="medium"
          id="dealpopup"
          backdrop="static"
        >
          <LoadingOverlay 
            className="custom-loader" fadeSpeed={0} spinner={<SynopsLoader />} 
            active={showLoaderonPage}></LoadingOverlay>
          <Modal.Header closeButton>
            <h4 className="modal-title" id="dealprofile">
              {i18n.t("Configurator")} - {this.props.setCategory}
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <div
                  className="row"
                  style={{
                    display: this.state.metricCategoryVisible ? "block" : "none",
                  }}
                >
                  <div className="form-group col-lg-12">
                    <label>
                      {i18n.t("Metric_Category")} <span className="required"> *</span>
                    </label>
                    <select className="form-control" onChange={this.onMetricGroupChange}>
                      <option value="0"> </option>
                      {this.state.SOCat}
                      {<option value="-1">&lt;{i18n.t("Add_New")}&gt;</option>}
                    </select>
                    <div className="errorMsg">{this.state.errors.select}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-lg-7">
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label>
                          {i18n.t("Name")} <span className="required"> *</span>
                        </label>
                        {/* <input type="text" maxLength="100" className="form-control" id="name" onChange={this.onNameChange} value={this.state.kpiData.KPIMappingID} /> */}
                        <select
                          id="name"
                          disabled={isScreenInEditMode}
                          className="form-control"
                          ref="MetricNameDropdown"
                          onChange={this.onMetricNameChange}
                          value={this.state.iiData && this.state.iiData.kpiData.KPIMappingID}
                        >
                          <option value="">{i18n.t("Select_Metric_Name")} </option>
                          {this.state.MetricNameOptions.map((row) => {
                            return <option value={row.KpiMappingID}>{row.MetricName}</option>;
                          })}
                          {this.state.SOCategoryID ? <option value="-1">&lt;{i18n.t("Add_New")}&gt;</option> : null}
                        </select>
                        <span className="errorMsg">{this.state.errors.MetricName}</span>
                      </div>

                      <div className="form-group col-lg-6">
                        <label>
                          {i18n.t("Unit_of_Measure")} <span className="required"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          readOnly
                          maxLength="50"
                          onChange={this.onUnitChange}
                          value={this.state.iiData.kpiData.MetricUnit}
                        />
                        <div className="errorMsg">{this.state.errors.MetricUnit}</div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label>
                          {i18n.t("Sequence")} <span className="required"> *</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={this.onMetricSequenceChange}
                          value={this.state.iiData.kpiData.MetricSeq}
                        />
                        <div className="errorMsg">{this.state.errors.MetricSeq}</div>
                        <div className="errorMsg">{this.state.errors.MetricSeqRange}</div>
                      </div>

                      <div className="form-group col-lg-6">
                        <label>
                          {i18n.t("Leading_Practice")}{" "}
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={<Tooltip id="tooltip-top">{i18n.t("addBOMess2")}</Tooltip>}
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </OverlayTrigger>
                        </label>
                        <input
                          type="text"
                          disabled
                          maxLength="50"
                          className="form-control"
                          value={this.state.iiData.kpiData.LeadingPractice}
                        />
                        <div className="errorMsg">{this.state.errors.LeadingPractice}</div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-12">
                        <label>{i18n.t("Description")}</label>
                        <input
                          type="text"
                          className="form-control"
                          readOnly
                          maxLength="255"
                          onChange={this.onMetricDescChange}
                          value={this.state.iiData.kpiData.KPIDesp}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-12">
                        <label>
                          {i18n.t("Metric_Definition")} <span className="required"> *</span>{" "}
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={<Tooltip id="tooltip-top">{i18n.t("addBOMess3")}</Tooltip>}
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </OverlayTrigger>
                        </label>
                        {enabledMetricDefinitions.length > 0 ? (
                          <select
                            value={this.state.iiData.kpiData.MetricDefinitionID}
                            onChange={(e) => this.handleChangeMetricDefinition(e)}
                            className="form-control"
                          >
                            <option value="">{i18n.t("Select_Metric_Definition")}</option>
                            {_.map(enabledMetricDefinitions, (eachMetricDefinition) => {
                              return (
                                <option
                                  value={eachMetricDefinition.KPIMetricDefinatoinID}
                                  key={eachMetricDefinition.KPIMetricDefinatoinID}
                                >
                                  {_.isEmpty(eachMetricDefinition.DenominatorDescription)
                                    ? eachMetricDefinition.NumeratorDescription
                                    : `${eachMetricDefinition.NumeratorDescription} / ${eachMetricDefinition.DenominatorDescription}`}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            value={i18n.t("Definition_Not_Available")}
                            disabled
                          ></input>
                        )}

                        <div className="errorMsg">{this.state.errors.MetricDefinitionID}</div>
                      </div>
                    </div>

                    <div className="row border-box">
                      <div className="form-group col-lg-6">
                        <label>
                          {i18n.t("Value")} <span className="required"> *</span>
                        </label>
                        {/* <input type="number" className="form-control"  maxLength="6" onChange={this.onValueChange} value={this.state.iiData.kpiData.MetricValue} /> */}
                        {this.state.valueConnector ? (
                          <input
                            type="text"
                            className="form-control"
                            maxLength="12"
                            onChange={this.onValueChange}
                            value={this.state.iiData.kpiData.MetricValue}
                            readOnly
                          />
                        ) : (
                          <input
                            disabled={stateKpiData.IsAggregation}
                            type="text"
                            className="form-control"
                            maxLength="12"
                            onChange={this.onValueChange}
                            value={this.state.iiData.kpiData.MetricValue}
                          />
                        )}
                        <div className="errorMsg">{this.state.errors.MetricValue}</div>
                        <div className="errorMsg">
                          {this.state.errors.MetricConnectorDataNotFound}
                          {this.state.errors.MetricConnectorLastPull ? (
                            <div> {this.state.errors.MetricConnectorLastPull} </div>
                          ) : (
                            ""
                          )}
                          {this.state.errors.MetricConnectorDataNotFound ? (
                            <div>{this.state.errors.IntegratedMetricsMessage}</div>
                          ) : (
                            ""
                          )}
                        </div>
                        <span className="errorMsg">{this.state.errors.MetricValueRange}</span>
                      </div>

                      <div className="form-group col-lg-6">
                        <label>
                          {i18n.t("Status")} <span className="required"> *</span>
                        </label>

                        <select class="form-control" onChange={this.onStatusChange}>
                          <option value="">--{i18n.t("Select")}--</option>
                          <option value="Grey">{i18n.t("Grey")}</option>
                          <option value="Green">{i18n.t("Green")}</option>
                          <option value="Red">{i18n.t("Red")}</option>
                          <option value="Amber">{i18n.t("Amber")}</option>
                        </select>
                        <div className="errorMsg">{this.state.errors.RAGStatus}</div>
                      </div>

											<div className="form-group col-lg-6">
												<label>{i18n.t("Upper_Threshold")}</label>
												<input
													type="number"
                          maxLength="12"
                          onInput={this.maxLengthCheckThreshold}
													className="form-control"
													onChange={this.onUpperChange}
												/>
											</div>

											<div className="form-group col-lg-6">
												<label>{i18n.t("Lower_Threshold")}</label>
												<input
													type="number"
													maxLength="12"
                          onInput={this.maxLengthCheckThreshold}
													className="form-control"
													onChange={this.onLowerChange}
												/>
											</div>

                      <div className="form-group col-lg-4">
                        <label for="">{i18n.t("Baseline_Value")}</label>
                        <input
                            type="text"
                            maxLength="12"
                            onInput={this.maxLengthCheckThreshold}
                            className="form-control"
                            id=""
                            onChange={this.onBaselineValueChange}
                            value={this.state.iiData.kpiData.BaselineValue}
                         />
                         <div className="errorMsg">
                          {this.state.errors.BaselineValue}
                        </div>
                      </div>

                      <div className="form-group col-lg-4">
                          <label for="">{i18n.t("Baseline_Month")}</label>
                          <div className="datepicker-label-wrapper">
                            <DatePicker
                              className="form-control"
                              id="BaseLineMonth"
                              maxDate={currentDate}
                              dateFormat="MM/yyyy"
                              showMonthYearPicker
                              selected={
                                Date.parse(this.state.iiData.kpiData.BaseLineMonth)
                                ? new Date(Date.parse(this.state.iiData.kpiData.BaseLineMonth))
                                : null
                              }
                                onSelect={(date) => {
                                  this.onBaselineMonthChange(date);
                                }}
                                readOnly={this.state.BaselineMonthReadOnly}
                            />
                          </div>
                        </div>

                      <div className="form-group col-lg-4">
                        <label for="">{i18n.t("Target_Value")}</label>
                        <input
                            type="text"
                            maxLength="12"
                            onInput={this.maxLengthCheckThreshold}
                            className="form-control"
                            id=""
                            onChange={this.onTargetValueChange}
                            value={this.state.iiData.kpiData.TargetValue}
                         />
                         <div className="errorMsg">
                          {this.state.errors.TargetValue}
                        </div>
                      </div>
                            
                      <div className="form-group col-lg-12">
                        <label>{i18n.t("Comments")}</label>
                        <textarea
                          rows="4"
                          maxlength="300"
                          name="comment"
                          className="form-control"
                          onChange={this.onValueDescriptionChange}
                          value={this.state.iiData.kpiData.Comments}
                        />
                        <span className="errorMsg">{this.state.commentsError}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-lg-12">
                        {/*<label for="">Metric Definition</label>*/}
                        <div class="inline-inputs mt-1">
                          <label className="form-check form-check-inline">
                            <input
                              id="chckTool"
                              className="form-check-input"
                              value="1"
                              type="radio"
                              name="MetricGroup"
                              checked={this.state.selectedApplication == 1}
                              onChange={(e) => {
                                this.bindMetricDefinationChange(e);
                              }}
                            />
                            <span className="checkmark"></span>
                            <span className="form-check-label">{i18n.t("Tool")}</span>
                          </label>
                          <label className="form-check form-check-inline">
                            <input
                              id="chckProject"
                              className="form-check-input"
                              value="2"
                              type="radio"
                              name="MetricGroup"
                              checked={this.state.selectedApplication == 2}
                              onChange={(e) => {
                                this.bindMetricDefinationChange(e);
                              }}
                            />
                            <span className="checkmark"></span>
                            <span className="form-check-label">{i18n.t("Project")}</span>
                          </label>
                        </div>
                        <div class="errorMsg"></div>
                      </div>
                      {this.state.selectedApplication == 1 ? (
                        
                        
                        <div class="form-group col-lg-6">
                          <label for="">
                            {i18n.t("Tool")} <span class="required">*</span>
                            {!isApiLoading && toolsApi.length===0 && 
                            <OverlayTrigger
                              key="top"
                              placement="top"
                              overlay={<Tooltip id="tooltip-top">{i18n.t("Add_Tool_Pulse")}</Tooltip>}
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </OverlayTrigger>
                            }
                          </label>
                          <select
                            class="form-control"
                            id="ddTool"
                            value={this.state.iiData.kpiData.toolID}
                            onChange={(e) => {
                              this.bindMetricDefinationChange(e);
                            }}
                          >
                            <option value="0"></option>
                            {/* {this.state.toolOptions} */}
                            {toolsApi}
                           {/*  <option value="-1">&lt;{i18n.t("Add_New")}&gt;</option> */}
                           {/* Above Not required(Add new) as Tool is flowing from pulse */} 
                          </select>
                          <div class="errorMsg">{this.state.errors.Tool}</div>
                        </div>
                      
                      
                      ) : (
                        ""
                      )}
                      {this.state.selectedApplication == 2 ? (
                        <div className="form-group col-lg-6">
                          <label for="">
                            {i18n.t("Project")} <span class="required">*</span>{" "}
                            <OverlayTrigger
                              key="top"
                              placement="top"
                              overlay={<Tooltip id="tooltip-top">{i18n.t("addBOMess4")}</Tooltip>}
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </OverlayTrigger>
                          </label>
                          <input
                            type="text"
                            id="txtProject"
                            maxLength="100"
                            value={this.state.iiData.kpiData.ProjectName}
                            className="form-control"
                            onChange={(e) => {
                              this.bindMetricDefinationChange(e);
                            }}
                          />
                          <div class="errorMsg">{this.state.errors.Project}</div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {this.state.iiData.kpiData.isRelatorBlock ? (
                      <div className="row border-box">
                        <div className="form-group col-lg-6">
                          <label>
                            {attributeLabelsForBO.length>0 ? attributeLabelsForBO[0].AttributeName1 : ""} <span className="required"> *</span>{" "}
                            {this.state.IsBatDealLevelForOffering && (
                              <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={<Tooltip id="tooltip-top">{i18n.t("addBMtooltip")}</Tooltip>}
                              >
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </OverlayTrigger>
                            )}
                          </label>
                          <input
                            type="text"
                            maxLength="6"
                            className="form-control"
                            onChange={this.onLeadingIndustryChange}
                            disabled={!this.state.IsBatDealLevelForOffering}
                            value={
                              this.state.iiData.kpiData.metDataAttr.length > 0 &&
                              this.state.iiData.kpiData.metDataAttr[0].AttributeValue
                            }
                          />
                          <div className="errorMsg">{this.state.errors.LeadingIndustry}</div>
                        </div>
                        <div className="form-group col-lg-6">
                          <label>
                            {attributeLabelsForBO.length>0 ? attributeLabelsForBO[0].AttributeName2 : ""} <span className="required"> *</span>{" "}
                            {this.state.IsBatDealLevelForOffering && (
                              <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={<Tooltip id="tooltip-top">{i18n.t("addBMtooltip")}</Tooltip>}
                              >
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </OverlayTrigger>
                            )}
                          </label>
                          <input
                            type="text"
                            maxLength="6"
                            className="form-control"
                            disabled={!this.state.IsBatDealLevelForOffering}
                            onChange={this.onSimilarIndustryChange}
                            value={
                              this.state.iiData.kpiData.metDataAttr.length > 0 &&
                              this.state.iiData.kpiData.metDataAttr[1].AttributeValue
                            }
                          />
                          <div className="errorMsg">{this.state.errors.SimilarIndustry}</div>
                        </div>
                      </div>
                    ) : null}
                    <div className="row border-box">
                      <div className="form-group col-lg-4">
                        <label className="source-system-padding">
                          {i18n.t("Source_System")} <span className="required"> *</span>
                        </label>

                        {this.state.con ? (
                          <select
                            className="form-control"
                            readOnly
                            onChange={this.onSourceChange}
                            value={this.state.iiData.kpiData.ConnectorID}
                          >
                            {this.state.connectorOptions}
                          </select>
                        ) : this.state.valueConnectorEdit ? (
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            onChange={this.onSourceChange}
                            value={this.props.kpiData.ConnectorNames}
                          ></input>
                        ) : (
                          <select
                            className="form-control"
                            onChange={this.onSourceChange}
                            value={this.state.iiData.kpiData.ConnectorID}
                          >
                            <option value=""></option>
                            {this.state.sourceOptions}
                            <option value="-1"> {i18n.t("Others")}</option>
                            <option value="-2">{i18n.t("Manual")}</option>
                          </select>
                        )}
                        <div className="errorMsg">{this.state.errors.ConnectorID}</div>
                      </div>
                      <div className="form-group col-lg-4">
                        <label>
                          {i18n.t("Source_System_Fields")} <span className="required"></span>
                        </label>

                        {this.state.con || this.state.iiData.kpiData.ConnectorNames == "Manual" ? (
                          <input
                            onChange={this.onSourceSystemChange}
                            type="text"
                            maxLength="50"
                            className="form-control"
                            readOnly
                            value={this.state.iiData.kpiData.SourceFieldName}
                          />
                        ) : (
                          <input
                            onChange={this.onSourceSystemChange}
                            type="text"
                            maxLength="50"
                            className="form-control"
                            value={this.state.iiData.kpiData.SourceFieldName}
                          />
                        )}
                        <div className="errorMsg">{this.state.errors.SourceFieldName}</div>
                      </div>
                      <div className="form-group col-lg-4">
                        <label>{i18n.t("Other_Source_System")} </label>
                        {this.state.iiData.kpiData.ConnectorNames == "Others" ? (
                          <input
                            onChange={this.onOtherSystemChange}
                            type="text"
                            maxLength="50"
                            className="form-control"
                            value={this.state.iiData.kpiData.SourceName}
                          />
                        ) : (
                          <input
                            onChange={this.onOtherSystemChange}
                            type="text"
                            value=""
                            readOnly
                            maxLength="50"
                            className="form-control"
                            value={this.state.iiData.kpiData.SourceName}
                          />
                        )}
                        <div className="errorMsg">{this.state.errors.KPIDesp}</div>
                      </div>
                      <div className="form-group col-lg-12 mt-2">
                        {/* <input type="checkbox" id="toolowner" defaultChecked={this.state.iiData.kpiData.IsAccentureOwned} onChange ={this.onToolOwnerChange}/> Accenture Owned */}
                        <label className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="toolowner"
                            defaultChecked={this.state.iiData.kpiData.IsAccentureOwned}
                            onChange={this.onToolOwnerChange}
                            checked={this.state.iiData.kpiData.IsAccentureOwned}
                          />
                          <span className="checkmark"></span>
                          <span className="form-check-label">{i18n.t("Accenture_Owned")}</span>
                        </label>
                        <small className="form-text text-muted">
                          <b>{i18n.t("Note")}:</b>
                          {i18n.t("NoteMessage1")}
                        </small>
                      </div>
                    </div>
                  </div>
                  {/* start of multi level UI */}
                  <div className="form-group col-lg-5">
                    <div className="right-wrapper">
                      <div className="catergory-metric-sub">
                        <div className="sub-level">
                          <i className="fas fa-sitemap"></i>
                          {i18n.t("Category_Metric_Sub_Levels")}
                        </div>
                        <div className="toggle-switch">
                          <Form>
                            <Form.Check
                              onClick={this.handleClick}
                              disabled={!stateKpiData.MetricName}
                              checked={stateKpiData.IsMultiLevel}
                              type="switch"
                              id="custom-switch"
                              label=""
                            />
                          </Form>
                        </div>
                      </div>
                      <div className="metric-sub-info">
                        <div className={stateKpiData.IsMultiLevel ? "disable-sub-level-none" : "disable-sub-level"}>
                          <i className="fas fa-sitemap"></i>
                          <p>{i18n.t("Enable_to_add_metric_categories_as_its_sub_level")}</p>
                        </div>
                        <div className={stateKpiData.IsMultiLevel ? "enable-sub-level" : "enable-sub-level-none"}>
                          <div className="check-box-info">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={stateKpiData.IsAggregation}
                                onChange={(e) => {
                                  this.bindAutoAggregationChange(e);
                                }}
                                id="customCheck"
                                name="example1"
                              />
                              <label className="custom-control-label" for="customCheck">
                                {i18n.t("Aggregate_category_values_to_metric_value")}
                              </label>
                            </div>
                            {/* {stateKpiData.IsAggregation && <OverlayTrigger
															key="top"
															placement="top"
															overlay={
																<Tooltip id="tooltip-top">
																	{i18n.t("Aggregation")}: {stateKpiData.formulaStr}
																</Tooltip>
															}
														>
															<FontAwesomeIcon icon={faInfoCircle} />
														</OverlayTrigger>
														} */}
                          </div>
                          <Modal
                            className="addCategoryModal"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={this.state.showCategory}
                            onHide={this.handleCategoryClose}
                            animation={false}
                            backdrop="static"
                          >
                            <Modal.Header>
                              <Modal.Title id="contained-modal-title-vcenter">
                              {this.state.isEdit ? i18n.t("Edit_Category_Details"):i18n.t("Add_Category_Details")}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                {!this.state.iscatypedisable ? (
                                  <div className="form-group col-lg-12">
                                    <label>
                                      {" "}
                                      {i18n.t("Category_Type")}
                                      <span className="required"> *</span>
                                    </label>

                                    <select
                                      id="name"
                                      className="form-control"
                                      disabled={this.state.isEdit}
                                      onChange={this.CatTypeSelect.bind(this)}
                                      value={this.state.CatTypeID}
                                    >
                                      <option value="0">{i18n.t("Select_CategoryType")}</option>
                                      {this.removeDuplicates(this.state.allCategoryTypesAndNames).map((CT) => {
                                        return (
                                          <option value={CT.ID} key={CT.ID}>
                                            {CT.CategoryType}
                                          </option>
                                        );
                                      })}
                                      <option value="addnew">{i18n.t("Add_New")}</option>
                                    </select>
                                    <div className="errorMsg">{this.state.errors.CategoryType}</div>
                                  </div>
                                ) : (
                                  <div className="form-group col-lg-12">
                                    <label>
                                      {i18n.t("Category_Type")}
                                      <span className="required"> *</span>
                                    </label>
                                    <select
                                      id="name"
                                      className="form-control"
                                      disabled={this.state.iscatypedisable}
                                      onChange={this.CatTypeSelect.bind(this)}
                                      value={this.state.CatTypeID}
                                    >
                                      <option value="0">{i18n.t("Select_CategoryType")}</option>
                                      {this.state.allCategoryTypesAndNames.map((CT) => {
                                        return <option value={CT.ID}>{CT.CategoryType}</option>;
                                      })}
                                      <option value="addnew">{i18n.t("Add_New")}</option>
                                    </select>

                                    <div className="errorMsg">{this.state.errors.CategoryType}</div>
                                  </div>
                                )}
                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t("Category_Name")}
                                    <span className="required"> *</span>
                                  </label>
                                  <select
                                    id="name"
                                    value={this.state.CatNameID}
                                    onChange={this.handleDropdownChange}
                                    className="form-control"
                                  >
                                    <option value="0">{i18n.t("Select_CategoryName")}</option>
                                    {this.state.CategoryNameData.map((CN) => {
                                      return <option value={CN.ID}>{CN.CategoryName}</option>;
                                    })}
                                    <option value="addnewcatname">{i18n.t("Add_New")}</option>
                                  </select>
                                  <div className="errorMsg">{this.state.errors.CategoryName}</div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-lg-6">
                                  <label>
                                    {i18n.t("Numerator_Value")}
                                    <span className="required"> *</span>
                                  </label>
                                  <input
                                    type="number"
                                    maxLength="12"
                                    xpattern="\d*"
                                    value={this.state.numeratorVal}
                                    onChange={this.onNumeratorChange}
                                    className="form-control"
                                  />
                                  <span className="errorMsg">{this.state.errors.NumeratorValue}</span>
                                  <span className="errorMsg">{this.state.errors.NumeratorValueRange}</span>
                                </div>
                                <div className="form-group col-lg-6">
                                  <label>
                                    {i18n.t("Denominator_Value")}
                                    {stateKpiData.MetricUnit && stateKpiData.MetricUnit.trim() === "%" && (
                                      <span className="required"> *</span>
                                    )}
                                  </label>
                                  <input
                                    type="number"
                                    maxLength="12"
                                    value={this.state.denomVal}
                                    onChange={this.onDenomChange}
                                    className="form-control"
                                  />
                                  <span className="errorMsg">{this.state.errors.Denominator}</span>
                                  <span className="errorMsg">{this.state.errors.DenominatorValueRange}</span>
                                </div>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <button className="btn btn-default btn-sm mr-2" onClick={this.handleCategoryClose}>
                                {i18n.t("Cancel")}
                              </button>
                              {this.state.isEdit ? (
                                <button className="btn btn-primary" onClick={this.handleCategoryEdit}>
                                  {i18n.t("Update")}
                                </button>
                              ) : (
                                <button className="btn btn-primary" onClick={this.handleCategoryAdd}>
                                  {i18n.t("Add")}
                                </button>
                              )}
                            </Modal.Footer>
                          </Modal>

                          <div className="add-category-infor-here">
                            <div
                              className={
                                stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0
                                  ? "add-metric-head-none"
                                  : "add-metric-head"
                              }
                            >
                              <div type="button" className="btn add-category-btn" onClick={this.handleCategoryShow}>
                                {i18n.t("Add_Category_Details")}
                              </div>
                              <div className="add-sub-level-metrics">
                                <i class="far fa-file-plus"></i>
                                <p>
                                  <span>*</span>
                                  {i18n.t("Add_category_details_as_sub_level_metrics")}
                                </p>
                              </div>
                            </div>
                            {stateKpiData.CategoryDetails &&
                                stateKpiData.CategoryDetails.length > 0 &&
                            <div className="category-header">
                                  <span className="category-headername">
                                    {i18n.t("Category")} -{" "}
                                    {stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0
                                      ? stateKpiData.CategoryDetails[0].CategoryType
                                      : ""}
                                  </span>
                                  <div className="category-actions">
                                  <div className="category-add" onClick={this.handleCategoryTypeAddShow}>
                                  <i class="fal fa-plus-circle mr-3"></i>
                                  </div>
                                  <span onClick={() => this.deleteCategoryType()}>
                                    <i class="fas fa-trash-alt delete-icon-fix"></i>
                                  </span>
                                  </div>
                                </div>
                                }
                            <div
                              className={
                                stateKpiData.CategoryDetails &&
                                stateKpiData.CategoryDetails.length > 0 &&
                                stateKpiData.IsMultiLevel
                                  ? "category-lists"
                                  : "category-lists-none"
                              }
                            >
                              <ul>
                                {items}
                              </ul>
                            </div>
                          </div>

                          {/* {Add Category Type} */}
                          <Modal
                            className="addCategoryModal"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={this.state.showaddCategoryType}
                            onHide={this.handleaddCategoryClose}
                            animation={false}
                            backdrop="static"
                          >
                            <Modal.Header>
                              <Modal.Title id="contained-modal-title-vcenter">
                                {i18n.t("Add_Category_Type")}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t("New_Category")}
                                    <span className="required"> *</span>
                                  </label>
                                  <input
                                    id="categoryType"
                                    type="text"
                                    maxLength="100"
                                    className="form-control"
                                    onChange={(e) => {
                                      this.bindToolFieldChange(e);
                                    }}
                                    value={this.state.CategoryDetails.CategoryType}
                                  />

                                  <span className="errorMsg">{this.state.errors.CategoryType}</span>
                                </div>
                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t("Name")}

                                    <span className="required"> *</span>
                                  </label>
                                  <input
                                    id="categoryName"
                                    type="text"
                                    maxLength="100"
                                    className="form-control"
                                    onChange={(e) => {
                                      this.bindToolFieldChange(e);
                                    }}
                                    value={this.state.CategoryDetails.CategoryName}
                                  />

                                  <span className="errorMsg">{this.state.errors.CategoryName}</span>
                                </div>
                                <p className="text-right newlyadded">
                                  {i18n.t("Newly_added_category_type_will_be_sent_for_approval")}
                                </p>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <button className="btn btn-default btn-sm mr-2" onClick={this.handleaddCategoryClose}>
                                {i18n.t("Cancel")}
                              </button>
                              <button className="btn btn-primary" onClick={this.handleaddCategoryAdd}>
                                {i18n.t("Add")}
                              </button>
                            </Modal.Footer>
                          </Modal>

                          {/* {Add Category Name} */}
                          <Modal
                            className="addCategoryModal"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={this.state.showaddCategory}
                            onHide={this.handleaddCategoryNameClose}
                            animation={false}
                            backdrop="static"
                          >
                            <Modal.Header>
                              <Modal.Title id="contained-modal-title-vcenter">
                                {" "}
                                {i18n.t("Add_Category_Name")}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t("Category_Type")}
                                    <span className="required"> *</span>
                                  </label>

                                  <input
                                    type="text"
                                    maxLength="100"
                                    className="form-control"
                                    id=""
                                    value={this.state.CategoryDetails.CategoryType}
                                    disabled
                                  />
                                </div>

                                <div className="form-group col-lg-12">
                                  <label>
                                    {i18n.t("Name")}

                                    <span className="required"> *</span>
                                  </label>

                                  <input
                                    id="categoryName"
                                    type="text"
                                    maxLength="100"
                                    className="form-control"
                                    onChange={(e) => {
                                      this.bindToolFieldChange(e);
                                    }}
                                    value={this.state.CategoryDetails.CategoryName}
                                  />

                                  <div className="errorMsg">{this.state.errors.CategoryName}</div>
                                </div>

                                <p className="text-right newlyadded">
                                  {" "}
                                  {i18n.t("Newly_added_category_name_will_be_sent_for_approval")}
                                </p>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <button
                                className="btn btn-default btn-sm mr-2"
                                onClick={this.handleaddCategoryNameClose}
                              >
                                {i18n.t("Cancel")}
                              </button>
                              <button className="btn btn-primary" onClick={this.handleaddCategoryAdd}>
                                {i18n.t("Add")}
                              </button>
                            </Modal.Footer>
                          </Modal>

                          {/* Approval Notification */}
                          <Modal
                            className="approvalModal"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={this.state.showApproval}
                            onHide={this.handleApprovalClose}
                            animation={false}
                            backdrop="static"
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>{i18n.t("Approval_Notification")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                {this.state.CatTypeID === "addnew" ? (
                                  <p className="approvalInfo">
                                    {i18n.t("Newly_added_category_type_is_sent_for_approval")}
                                  </p>
                                ) : (
                                  <p className="approvalInfo">
                                    {i18n.t("Newly_added_category_name_is_sent_for_approval")}
                                  </p>
                                )}
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>

                    <div className="errorMsg">{this.state.errors.MultiLevel}</div>
                  </div>
                  {/* end of multi level UI */}
                </div>
              </div>
            </form>
            {/* : <p>NO DATA / Not Selected Client,Offering and Location</p>} */}

            {/* : <p>Please Select Client,Offering and Location </p>} */}
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">
                <button onClick={this.handleHide} className="btn btn-default btn-sm mr-2">
                  {i18n.t("Cancel")}
                </button>

                {!this.props.Details ? (
                  <input
                    id="btn2"
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSaveInsight()}
                    value={i18n.t("Create")}
                  />
                ) : (
                  <input
                    id="btn2"
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSaveInsight()}
                    value={i18n.t("Update")}
                  />
                )}
              </div>
            </Modal.Footer>
          }
        </Modal>
        {this.state.viewmore ? (
          <KpiPopUp
            SOCategoryID={this.state.SOCategoryID}
            show={true}
            onClose={this.onClose.bind(this)}
            filterDetails={this.props.filterDetails}
            tabName="II"
          />
        ) : null}
        {this.state.addNewMetricGroup ? (
          <IIMetricGroupPopUp
            SOCategoryID={this.props.MetricGroupsID}
            ParentSOCategoryID={this.props.MetricGroupsID}
            type={this.state.type}
            show={true}
            onClose={this.onClose.bind(this)}
            filterDetails={this.props.filterDetails}
          />
        ) : null}
        {this.state.showAddToolPopup ? (
          <AddToolPopUp
            show={this.state.showAddToolPopup}
            filter={this.props.filterDetails}
            SOCategoryID={4}
            onClose={this.handleAddToolPopupClose}
            screen={this.props.screen}
            lever={"I&I"}
            getOfferingAPICallback={this.props.getOfferingAPICallback}
            tooldata={this.props.toolDetails}
          />
        ) : null}
      </>
    );
  }
}

// function mapStateToProps(
