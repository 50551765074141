import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { LocalApiBaseUrl, regexForConfMasterFields, regexForTextAreas } from '../Shared/Constant';

import axiosInstance from '../Shared/interceptor';
import { store } from '../reducers/configureStore';
import Header from '../Shared/Header';
axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('msal.idtoken')}`;


export default class OfferingPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      apivalue: '',
      selectValue: this.props.SelectValue,
      Display: false,
      Offering: {
        OfferingId: '0',
        Offering: '',
        RequestorRemarks: '',
        ApproverRemarks: '',
        Process: '',
        ApprovalAction: true,
        ApproveType: '',
        IsActive: false,
        CreatedUser: null,
        CreatedTS: null,
        UpdatedUser: null,
        UpdatedTS: null,
        DealInfoes: null,
      },
      errors: [],
      formIsValid: false,

    };

    this.onOfferingChange = this.onOfferingChange.bind(this);
    this.onApproverRemarkChange = this.onApproverRemarkChange.bind(this);
    this.onRequestorRemarksChange = this.onRequestorRemarksChange.bind(this);
    this.onProcessChange = this.onProcessChange.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
  }

  onOfferingChange(event) {
    const errors = {};
    this.setState({
      errors,
    });
    const { Offering } = this.state;
    Offering.Offering = event.target.value;
    this.setState({
      Offering,
    });
  }

  onApproverRemarkChange(event) {
    if (this.props.type == 'Approve') {
      const soCatAtt = null;
      const errors = {};
      this.setState({
        errors,
      });
      var { Offering } = this.state;
      Offering.ApproverRemarks = event.target.value;
      Offering.ApprovalAction = true;
      // Client.ClientIDs=this.state.Client;
      this.setState({
        Offering,
      });
    } else {
      const soCatAtt = null;
      const errors = {};
      this.setState({
        errors,
      });
      var { Offering } = this.state;
      Offering.ApproverRemarks = event.target.value;
      Offering.ApprovalAction = false;
      // Client.ClientIDs=this.state.Client;
      this.setState({
        Offering,
      });
    }
  }

  onRequestorRemarksChange(event) {
    const errors = {};
    this.setState({
      errors,
    });
    const { Offering } = this.state;
    Offering.RequestorRemarks = event.target.value;
    this.setState({
      Offering,
    });
  }

  onProcessChange(event) {
    const errors = {};
    this.setState({
      errors,
    });
    const { Offering } = this.state;
    Offering.Process = event.target.value;
    this.setState({
      Offering,
    });
  }

  onStatusChange(event) {
    const { Offering } = this.state;
    Offering.IsActive = event.target.checked;
    this.setState({
      Offering,
    });
  }

  handleSave(e) {
    if (this.validateForm()) {
      this.props.onSave(this.state.Offering);

      this.handleHide();
    }
  }

  handleHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if ((e.target.textContent != '×')) {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
        // this.props.onClose("from x");
      }
    } else {
      this.props.onClose(e);
    }
    this.props.onSave();
  }

  handleRejectHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != '×') {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }

  handleApproveHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != '×') {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }

  passToConfirmation(data) {
    this.props.enteredData(data.PoNumber);
  }

  componentDidMount() {
    // this.validateForm();
    this.setState({
      show: this.props.show,
    });
  }

  validateForm() {
    const { Offering } = this.state;
    const errors = {};
    let formIsValid = true;
    const data = this.props.rowData;
    if (Offering.ApproverRemarks == null) {
      formIsValid = false;
      errors.ApproverRemarks = 'Approver Remarks are required';
    } else if (!Offering.ApproverRemarks.trim()) {
      formIsValid = false;
      errors.ApproverRemarks = 'Approver Remarks are required';
    } else {
      const isValid = regexForTextAreas.test(Offering.ApproverRemarks);
      if (!isValid) {
        formIsValid = false;
        errors.ApproverRemarks = 'Approver Remarks contains Special characters';
      }
    }

    this.setState({
      errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  componentWillMount() {
    if (this.props.data) {
      this.setState({
        Offering: this.props.data[0],
      });
    }
  }

  render() {
    return (
      <>
        {/* <Header /> */}
        <Modal show={this.state.show} onHide={this.handleHide.bind(this)} classOffering="modal fade" size="medium" id="dealpopup" backdrop="static">
          <Modal.Header closeButton>
            <h4 className="modal-title" id="dealprofile" flag>Approval</h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">

                <div className="row">
                  <div className="form-group col-lg-6">
                    <label htmlFor="">
                      Offering Name
                      <span className="required">*</span>
                    </label>
                    <input type="text" className="form-control" id="name" readOnly onChange={this.onOfferingChange} value={this.state.Offering.Offering} />
                    <span className="errorMsg">{this.state.errors.Offering}</span>
                  </div>
                  <div className="form-group col-lg-6">
                    <label htmlFor="">
                      Process
                      <span className="required">*</span>
                    </label>
                    <input type="text" className="form-control" id="name" readOnly onChange={this.onProcessChange} value={this.state.Offering.Process} />
                    <span className="errorMsg">{this.state.errors.Process}</span>
                  </div>
                  <div className="form-group col-lg-6">
                    <label htmlFor="">Requestor Remarks</label>
                    <textarea maxLength="200" readOnly className="form-control" id="remark" onChange={this.onRequestorRemarksChange} value={this.state.Offering.RequestorRemarks} />
                    <span className="errorMsg">{this.state.errors.RequestorRemarks}</span>
                  </div>
                  <div className="form-group col-lg-6">
                    <label htmlFor="">
                      Approver Remarks
                      <span className="required">*</span>
                    </label>
                    <textarea maxLength="200" className="form-control" id="remark" onChange={this.onApproverRemarkChange} value={this.state.Offering.ApproverRemarks} />
                    <span className="errorMsg">{this.state.errors.ApproverRemarks}</span>
                  </div>

                </div>

              </div>

            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-btn">
              {!this.props.data
                ? <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value="Create" />
                : <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value="Update" />}
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
