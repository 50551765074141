import React, { Component } from "react";
import i18n from "i18next";
import {
  LocalApiBaseUrl
} from "../Shared/Constant";
import DiversePopUp from "../SuperAdmin/diverse_popup";
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import { store } from "../reducers/configureStore";
import axiosInstance from "../Shared/interceptor";
import {trycatchAlertPopup} from "../Shared/Constant";

export default class DiverseKpiContiner extends Component {
  constructor() {
    super();
    this.state = {
      metricDetails: [],
      viewMore2: false,
      editdiverse: false,
      attributeLabels: [],
      diverse1: [],
      addCategory: false,
      operationArray: []
    };
    this.onClose = this.onClose.bind(this);
    this.setOperations = this.setOperations.bind(this);
  }

  dealpopup(e) {
    e.preventDefault();
    this.setState({ viewMore2: true });
  }
  setOperations() {
    const data = store.getState();
    let operation = this.state.operationArray;
    data.roleReducers["roleReducers"].map(i => {
      if (i.FeatureName === "Master Template Configurator") {
        operation.push(...i.Operations);
        return operation;
      }
    });
    this.setState({
      operationArray: operation
    });
  }

  componentDidMount() {
    setTimeout(() => {
    }, 5000);
  }

  editDiverse(kpiID, e) {
    e.preventDefault();
    this.setState({ editdiverse: true, kpiID: kpiID });
  }

  deleteDiverse = (kpiDetails, e) => {
    if (window.confirm("Are you sure you want to delete metric detail?")) {
      e.preventDefault();
      axiosInstance
        .post(
          `${LocalApiBaseUrl}Master/DeleteKpiMetricMasterTemplate?kpiMappingId=${kpiDetails.KPIMappingID}`
        )

        .then(response => {
          window.alert(response.data.StatusMessage);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });

      store.dispatch({
        type: "REMOVE_DIVERSE_MASTER",
        payload: { kpiDetails: kpiDetails }
      });
    }
    e.preventDefault();
  };
  onClose() {
    this.setState({ viewMore2: false, editdiverse: false });
  }

  displaydiverseDetails() {
    var data = store.getState();
   
    let soDetails = [];
    data.diverseMasterReducers["diverse"].map(ele => {
      if (ele.kpiData.length >= 1) {
        soDetails.push(...ele.kpiData);
      }
    });

    let kpiMapping = [];

    if (soDetails.length >= 1) {
      soDetails.map(ele => {
        kpiMapping.push(ele.KPIMappingID);
      });
    }

    let diverse = this.state.diverseData;

    if (data.diverseMasterReducers["diverse"].length > 0) {
      let leadMetrics = soDetails.map(kpiDetails => {
        let statusColor = "item gray-color";
        if (kpiMapping.includes(kpiDetails.KPIMappingID)) {
          let Description = "";
          let ValueBlock = true;
              Description =
              kpiDetails.KPIDesp
              ? kpiDetails.KPIDesp
              : "";
          return (
            <>
            <div className={`${statusColor}`} data-place="bottom">
            <div className="item-left">   
              {ValueBlock ? (
                  <>
                  <p className="tile-metric-value">                            

                       {kpiDetails.MetricUnit && kpiDetails.MetricUnit.trim() !== "%" ? (
                        <span className="metric-digit-text">{`${kpiDetails.MetricUnit}`}</span>
                      ) : (
                        kpiDetails.MetricUnit
                      )                            
                      }
                  </p>
                  </>
               ) : null}        
                    <p className="tile-metric-desc" data-tip data-for={'customTooltip' + kpiDetails.KPIMappingID}>
                          {kpiDetails.MetricName}
                      </p>
                      </div>
                      <div className="item-right">
                          {Description ? (
                              <p className="tile-metric-name" data-tip data-for={'customTooltip_desc' + kpiDetails.KPIMappingID}>
                              
                              {Description}
                              
                      </p>
                      

            ) : (
              <p className="tile-metric-name">&nbsp;</p>
            )}                          
            </div>                         
            {(this.state.operationArray.includes("Edit") || this.state.operationArray.includes("Delete")) &&
              <div className="dropdown dropleft">
                <span type="button" className="dropdown-toggle" data-toggle="dropdown"></span>
                    <div className="dropdown-menu">
                      {this.state.operationArray != undefined && store.getState().CurrentFilter.languageData.id === 1 &&
                        this.state.operationArray.includes("Delete") && (
                          <a href="javascript:void(0)" className="dropdown-item"
                            onClick={e => {
                              this.deleteDiverse(kpiDetails, e);
                            }}
                          >
                               <span className="delete-icon"></span>{i18n.t("Remove")}
                          </a>
                        )}
                      {this.state.operationArray.includes("Edit") &&
                        <a href="javascript:void(0)" className="dropdown-item"
                          onClick={e => {
                            this.editDiverse(kpiDetails.KPIMappingID, e);
                          }}
                        >
                        <span className="edit-icon"></span>{i18n.t("Edit")}
                      </a>
                      }
                    </div>
                  
           </div>
            }
           </div>
            </>
          );
        }
      });

      this.setState({ List: leadMetrics });
    }
  }

  componentDidMount() {
    this.setOperations();
    var data = store.getState();

    this.state.diverseData = data.diverseMasterReducers["diverse"];

    if (data.diverseMasterReducers["diverse"][0]) {
      this.state.diverse1 = data.diverseMasterReducers["diverse"][0];
    }
    this.displaydiverseDetails();
    store.subscribe(() => {
      var data = store.getState();

      this.setState({ diverseData: data.diverseMasterReducers["diverse"] });
      this.displaydiverseDetails();
    });
    let labelsForDD = data.BOMasterReducers.attributeLabels.filter(z=>z.ApplicableForDD===true);
  
        this.setState({
          attributeLabels: labelsForDD != null && labelsForDD.length > 0 ? labelsForDD[0] : []
        });
        
    
  }
  componentWillReceiveProps(nextProps) {
    
    var data = store.getState();
    if (data.diverseMasterReducers["diverse"][0]) {
      this.state.diverse1 = data.diverseMasterReducers["diverse"][0];
    }
    this.displaydiverseDetails();
  }
  render() {
    return (
      <>
        {this.state.List}
        {this.state.viewMore2 ? (
          <DiversePopUp
            IsRelatorBlockForOffering={this.props.IsRelatorBlockForOffering}
            attributeLabels={this.state.attributeLabels}
            show={this.state.viewMore2}
            onClose={this.onClose}
            type={this.state.type}
            filterDetails={this.props.filterDetails}
            SOCategoryID={
              this.state.diverse1.length > 0
                ? this.state.diverseData[0].SOCategoryID
                : 0
            }
            roledata={this.props.roledata}
          />
        ) : null}
        {this.state.editdiverse ? (
          <DiversePopUp
            show={this.state.editdiverse}
            onClose={this.onClose}
            IsRelatorBlockForOffering={this.props.IsRelatorBlockForOffering}
            attributeLabels={this.state.attributeLabels}
            type={this.state.type}
            editDiverse={this.state.diverseData}
            kpiID={this.state.kpiID}
            edit={this.state.editdiverse}
            filterDetails={this.props.filterDetails}
            SOCategoryID={this.state.diverseData[0].SOCategoryID}
            roledata={this.props.roledata}
          />
        ) : null}
      </>
    );
  }
}
