import React, { Component } from "react";
import {
  LocalApiBaseUrl,
  getMonthNumber,
  getPrevMonth
} from "../Shared/Constant";
import { AgGridReact, Ag } from "ag-grid-react";
import { store } from "../reducers/configureStore";

// import DealConnectorPopup from "../DealConnector/DealConnectorPopup";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import UserAccessDeniedScreen from "../Shared/UserAccessDeniedScreen"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { faPencilAlt, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import ConnectorFieldMasterPopup from "./ConnectorFieldMasterPopup";
axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


class ConnectorFieldMasterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      roleList: [],
      clientList: [],
      viewmore: false,
      userAccessDenied: false,
      errors: {},
      rowSelected: [],
      sourceOptions: [],
      DEIDealOptions: [],
      // Connector: {
      //   DealID: null,
      //   ConnectorID: null
      // },
      columnDefs: [
        {
          headerName: "Edit", suppressMovable: true,
          // width: 60,
          cellRenderer: params => {
            // if(params.data.Status==="Approved"){
            var link = document.createElement('span');
            link.className = 'tbledit';
            link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
            link.addEventListener("click", e => {
              this.dealpopup(e, params.data);
            });
            return link;
            // }
          },
          suppressFilter: true
        },
        { headerName: "Connector", field: "ConnectorName", suppressMovable: true, resizable: true },
        { headerName: "Field Name", field: "FieldName", suppressMovable: true, resizable: true },
        { headerName: "Data Type", field: "DataType", suppressMovable: true, resizable: true },
        {
          headerName: "Brief Description", suppressMovable: true,

          field: "Description",
          resizable: true
        },
        { headerName: "Column Name", field: "ColumnName", suppressMovable: true, resizable: true },

        {
          headerName: "Is Active", suppressMovable: true,
          field: "IsActive",
          resizable: false,
          suppressFilter: true,

          cellRenderer: params => {
            return `<label class="form-check">
        <input class="form-check-input" disabled type="checkbox" ${params.value ? "checked" : ""
              }>
        <span class="checkmark"></span>
         </label>`;
          }/* ,
          cellStyle: function(params) {
            return { "text-align": "left" };
          } */
        },
        {
          headerName: "Has Master", suppressMovable: true,
          field: "HasMaster",
          resizable: false,
          suppressFilter: true,

          cellRenderer: params => {
            return `<label class="form-check">
        <input class="form-check-input" disabled type="checkbox" ${params.value ? "checked" : ""
              }>
        <span class="checkmark"></span>
         </label>`;
          }/* ,
          cellStyle: function(params) {
            return { "text-align": "left" };
          } */
        }


      ],
      rowData: [],
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        rowSelection: "multiple",
        defaultColDef: {
          sortable: true
        }
      }
    };
    this.onClose = this.onClose.bind(this);
    this.dealpopup = this.dealpopup.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    this.handleSave = this.handleSave.bind(this);
    // this.onSourceChange = this.onSourceChange.bind(this);
    // this.onDEIDealChange = this.onDEIDealChange.bind(this);
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged(e) {
    this.state.gridOptions.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  }
  dealpopup(e, data) {
    if (data != undefined) {
      // edit condition
      if (
        this.props.featureData.filter(
          data =>
            data.Submenu == "Connector Field Master Data" &&
            data.Operations.includes("Edit")
        ).length > 0
      ) {
        e.preventDefault();
        this.setState({ viewmore: true, rowSelected: data });
      } else window.alert("Access Denied !!");
    } else {
      // add condition
      if (
        this.props.featureData.filter(
          data =>
            data.Submenu == "Connector Field Master Data" &&
            data.Operations.includes("Add")
        ).length > 0
      ) {
        e.preventDefault();
        this.setState({ viewmore: true, rowSelected: data });
      } else window.alert("Access Denied !!");
    }
  }

  onClose(e) {
    this.setState({ viewmore: false });
  }

  deleteConnectorFieldMasterData(e, data) {
    if (
      this.props.featureData.filter(
        data =>
          data.Submenu == "Connector Field Master Data" &&
          data.Operations.includes("Delete")
      ).length > 0
    ) {
      if (window.confirm("Are you sure you want to delete the item?")) {
        axiosInstance
          .delete(
            `${LocalApiBaseUrl}QueryBuilder/DeleteConnectorFieldMasterData`,
            {
              params: {
                ConnectorFieldMasterDataID: data.ConnectorFieldMasterDataID
              }
            }
          )
          .then(response => {
            window.alert(response.data.StatusMessage);
            this.getConnectorFieldMasterData();
          })
          .catch((error) => {
            trycatchAlertPopup(error);
          });
      }
    } else window.alert("Access Denied !!");
  }

  getConnectorFieldMasterData() {
    axiosInstance
      .get(`${LocalApiBaseUrl}QueryBuilder/GetConnectorFieldMasterData`)
      .then(response => {
        this.setState({
          rowData: response.data
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  componentWillMount() {
    let columnDefs = this.state.columnDefs;

    this.getConnectorFieldMasterData();
  }

  componentDidMount() {
    if (this.props.userAccessDeniedScreen === "Connector Field Master Data") {
      this.setState({
        userAccessDenied: true
      })
    }
    else {
      let columnDefs = this.state.columnDefs;

      if (
        this.props.featureData.filter(
          data =>
            data.Submenu == "Connector Field Master Data" &&
            data.Operations.includes("Edit")
        ).length == 0
      ) {
        columnDefs = columnDefs.filter(data => data.headerName != "Edit");
        this.setState({
          columnDefs: columnDefs
        });
      }

      if (
        this.props.featureData.filter(
          data =>
            data.Submenu == "Connector Field Master Data" &&
            data.Operations.includes("Delete")
        ).length == 0
      ) {
        columnDefs = columnDefs.filter(data => data.headerName != "Delete");
        this.setState({
          columnDefs: columnDefs
        });
      }
    }

  }
  componentDidUpdate(prevProps) {
    if (prevProps.userAccessDeniedScreen !== this.props.userAccessDeniedScreen) {
      if (this.props.userAccessDeniedScreen === "Connector Field Master Data") {
        this.setState({
          userAccessDenied: true
        })
      }
      else {
        this.setState({
          userAccessDenied: false
        })
      }
    }
  }

  handleSave(data) {
    if (data) {
      axiosInstance
        .post(
          `${LocalApiBaseUrl}QueryBuilder/AddEditConnectorFieldMasterData`,
          data
        )
        .then(
          response => {
            window.alert(response.data.StatusMessage);

            this.getConnectorFieldMasterData();
          },
          error => {

          }
        )
        .catch((error) => {
          trycatchAlertPopup(error);
        });

    }
    this.getConnectorFieldMasterData();
    this.setState({ viewmore: false });
  }

  render() {
    return (
      !this.state.userAccessDenied ?
        <div className="main-data">
          {/* <h3 className="grid-heading">Connector Field Master Data Screen</h3> */}
          <div className="tableFilter">
            <div className="form-section">
              <div className="form-row">
                <div className="form-group col-lg-3">
                  <input type="text" className="form-control" id="filter-text-box" placeholder="Filter..." onChange={(e) => { this.onFilterTextBoxChanged(e) }} />
                </div>
                {this.props.featureData.filter((data) => data.Submenu == "Connector Field Master Data" && data.Operations.includes("Add")).length > 0 &&
                  <div className="form-group col-lg-4">
                    <button type="button" className="btn btn-primary btn-sm" onClick={(e) => { this.dealpopup(e) }}><span><FontAwesomeIcon icon={faPlusCircle} /></span> Add Connector Field Master Data</button>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="ag-theme-balham ag-table">
                <AgGridReact
                  enableSorting={true}
                  enableFilter={true}
                  pagination={true}
                  paginationPageSize={50}
                  floatingFilter={true}
                  gridOptions={this.state.gridOptions}
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  onFirstDataRendered={this.onFirstDataRendered.bind(
                    this
                  )}
                ></AgGridReact>
              </div>
            </div>
          </div>
          {this.state.viewmore ? (
            <ConnectorFieldMasterPopup
              rowData={this.state.rowData}
              data={this.state.rowSelected}
              show={this.state.viewmore}
              onClose={this.onClose}
              onSave={this.handleSave}
            />
          ) : null}
        </div> :
        <UserAccessDeniedScreen />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAccessDeniedScreen: state.roleReducers.userDeniedScreen,
  };
};
export default connect(mapStateToProps)(ConnectorFieldMasterList);