import React from "react";
import { Modal, Button } from "react-bootstrap";
import i18n from "i18next";

import axiosInstance from "../../Shared/interceptor";
import { regexForConfMasterFields } from "../../Shared/Constant";

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class HelpFeaturePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      apivalue: "",
      selectValue: this.props.SelectValue,
      Display: false,
      HelpSectionData: {
        ID: 0,
        Name: "",
        Description: "",
        IsActive: false,
        SequenceForHelpSection: "",
        SequenceForFeedback: "",
        ApplicableSection: "",
      },
      errors: [],
      formIsValid: false,
      title: "",
      filterDetails: {},
      rowData: [],
      feedbackRadio: false,
      helpSectionRadio: false,
      bothRadio: false,
      sectionRadio: false,
    };

    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
  }

  onNameChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    let HelpSectionData = this.state.HelpSectionData;
    HelpSectionData.Name = event.target.value;
    this.setState({
      HelpSectionData: HelpSectionData,
    });
  }
  onDescriptionChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    let HelpSectionData = this.state.HelpSectionData;
    HelpSectionData.Description = event.target.value;
    this.setState({
      HelpSectionData: HelpSectionData,
    });
  }

  handleSave(e) {
    if (this.validateForm()) {
      let data = {};
      data.ID = this.state.HelpSectionData.ID;
      data.Name = this.state.HelpSectionData.Name;
      data.ApplicableSection = this.state.feedbackRadio
        ? "Feedback"
        : "" || this.state.bothRadio
          ? "All"
          : "" || this.state.helpSectionRadio
            ? "Help"
            : "";
      data.Description = this.state.HelpSectionData.Description;
      data.CategoryName = this.state.filterDetails.value;
      data.SequenceNumber = !this.state.HelpSectionData.SequenceForHelpSection
        ? null
        : data.ApplicableSection === "Help" || data.ApplicableSection === "All"
          ? parseInt(this.state.HelpSectionData.SequenceForHelpSection)
          : null;
      data.SequenceForFeedback = !this.state.HelpSectionData.SequenceForFeedback
        ? null
        : data.ApplicableSection === "Feedback" ||
          data.ApplicableSection === "All"
          ? parseInt(this.state.HelpSectionData.SequenceForFeedback)
          : null;
      data.IsActive = this.state.HelpSectionData.IsActive;
      this.props.onSave(data);

      this.handleHide();
    }
  }

  handleHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
        // this.props.onClose("from x");
      }
    } else {
      this.props.onClose(e);
    }
    this.props.onSave();
  }
  handleRejectHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }
  passToConfirmation(data) {
    this.props.enteredData(data.PoNumber);
  }

  componentDidMount() {
    this.setState({
      show: this.props.show,
      filterDetails: { ...this.props.filterDetails },
      rowData: [...this.props.rowData],
    });
  }


  validateForm() {
    const HelpSectionData = this.state.HelpSectionData;
    let errors = {};
    let formIsValid = true;
    let data = this.state;

    if (!HelpSectionData["Name"].trim()) {
      formIsValid = false;
      errors["Name"] = "Name is required";
    }


    let rowData = [...this.state.rowData];
    rowData = rowData.map(each => {
      if (each.ID == parseInt(HelpSectionData["ID"])) {
        return { ...HelpSectionData };
      }
      else {
        return each
      }
    })
    if (HelpSectionData["ID"] == 0 && HelpSectionData["IsActive"] == true) {
      rowData.push(HelpSectionData);
    }
    let countActive = rowData.filter(each => each.IsActive == true);
    if (HelpSectionData["SequenceForHelpSection"] && HelpSectionData["IsActive"] == true) {
      if (countActive.filter(each => parseInt(each.SequenceForHelpSection) == parseInt(HelpSectionData["SequenceForHelpSection"])).length >= 2) {
        formIsValid = false;
        errors["SequenceNumber"] = "Sequence already exist";
      }
      if (HelpSectionData["SequenceForHelpSection"] < 0) {
        formIsValid = false;
        errors["SequenceNumber"] = "Sequence cannot be Negative";
      }
      if (HelpSectionData["SequenceForHelpSection"] > 999999 || HelpSectionData["SequenceForHelpSection"] == 0) {
        formIsValid = false;
        errors["SequenceNumber"] = "Sequence Value must be in between 1 to 999999";
      }

      if (HelpSectionData["IsActive"] == true) {
        if ((HelpSectionData["SequenceForFeedback"] > 999999 || HelpSectionData["SequenceForFeedback"] == 0) && (data.feedbackRadio == true || data.bothRadio == true)) {
          formIsValid = false;
          errors["SequenceForFeedback"] = "Sequence Value must be in between 1 to 999999";
        }
        if ((HelpSectionData["SequenceForHelpSection"] > 999999 || HelpSectionData["SequenceForHelpSection"] == 0) && (data.helpSectionRadio == true || data.bothRadio == true)) {
          formIsValid = false;
          errors["SequenceForHelpSection"] = "Sequence Value must be in between 1 to 999999";
        }
      }
      if (HelpSectionData["SequenceForHelpSection"] != null && HelpSectionData["SequenceForHelpSection"] != "" && parseFloat(HelpSectionData["SequenceForHelpSection"]) % 1 != 0) {
        formIsValid = false;
        errors["SequenceNumber"] = "Sequence Value must be an integer";

      }

    }


    //For Feedback

    if (HelpSectionData["SequenceForFeedback"] && HelpSectionData["IsActive"] == true) {
      if (countActive.filter(each => parseInt(each.SequenceForFeedback) == parseInt(HelpSectionData["SequenceForFeedback"])).length >= 2) {
        formIsValid = false;
        errors["SequenceForFeedback"] = "Sequence already exist";
      }
      if (HelpSectionData["SequenceForFeedback"] < 0) {
        formIsValid = false;
        errors["SequenceForFeedback"] = "Sequence cannot be Negative";
      }
      if (HelpSectionData["SequenceForFeedback"] > 999999 || HelpSectionData["SequenceForFeedback"] == 0) {
        formIsValid = false;
        errors["SequenceForFeedback"] = "Sequence Value must be in between 1 to 999999";
      }
      //   if(HelpSectionData["IsActive"] == true )
      //   {
      //     if((HelpSectionData["SequenceForFeedback"]==""||HelpSectionData["SequenceForFeedback"]==null)&&(data.feedbackRadio==true||data.bothRadio==true))
      //     {
      //     formIsValid = false;
      //     errors["SequenceForFeedback"] = "Sequence is required";
      //   }
      //   if((HelpSectionData["SequenceForHelpSection"]==""||HelpSectionData["SequenceForHelpSection"]==null)&&(data.helpSectionRadio==true||data.bothRadio==true))
      //     {
      //     formIsValid = false;
      //     errors["SequenceForFeedback"] = "Sequence is required";
      //   }
      // }

      if (HelpSectionData["SequenceForFeedback"] != null && HelpSectionData["SequenceForFeedback"] != "" && parseFloat(HelpSectionData["SequenceForFeedback"]) % 1 != 0) {
        formIsValid = false;
        errors["SequenceForFeedback"] = "Sequence Value must be an integer";
      }

    }
    //For Help
    if (countActive.filter(each => parseInt(each.SequenceForHelpSection) == parseInt(HelpSectionData["SequenceForHelpSection"])).length >= 2) {
      formIsValid = false;
      errors["SequenceNumber"] = "Sequence already exist";
    }
    //For Feedback
    if (countActive.filter(each => parseInt(each.SequenceForFeedback) == parseInt(HelpSectionData["SequenceForFeedback"])).length >= 2) {
      formIsValid = false;
      errors["SequenceForFeedback"] = "Sequence already exist";
    }
    //For Both
    if (HelpSectionData["IsActive"] == true) {
      if ((HelpSectionData["SequenceForFeedback"] == "" || HelpSectionData["SequenceForFeedback"] == null) && (data.feedbackRadio == true || data.bothRadio == true)) {
        formIsValid = false;
        errors["SequenceForFeedback"] = "Sequence is required";
      }
      if ((HelpSectionData["SequenceForHelpSection"] == "" || HelpSectionData["SequenceForHelpSection"] == null) && (data.helpSectionRadio == true || data.bothRadio == true)) {
        formIsValid = false;
        errors["SequenceNumber"] = "Sequence is required";
      }
    }
    if (!this.state.sectionRadio && !this.state.helpSectionRadio && !this.state.feedbackRadio && !this.state.bothRadio) {
      formIsValid = false;
      errors["SectionRadio"] = "Section is required";
    }

    this.setState({
      errors: errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }


  componentWillMount() {
    if (this.props.data) {
      let data = { ...this.props.data };
      let helpSectionRadio = data.ApplicableSection === "Help" ? true : false;
      let feedbackRadio = data.ApplicableSection === "Feedback" ? true : false;
      let bothRadio = data.ApplicableSection === "All" ? true : false;
      this.setState({
        HelpSectionData: data,
        helpSectionRadio,
        feedbackRadio,
        bothRadio,
      });
    } else {
      const HelpSectionData = this.state.HelpSectionData;
      this.setState({
        HelpSectionData: HelpSectionData,
        show: this.props.show,
      });
    }
  }
  handleSequence = (e) => {
    let { HelpSectionData } = this.state;
    HelpSectionData.SequenceForHelpSection = e.target.value;
    this.setState({
      HelpSectionData,
    });
  };
  onStatusChange = () => {
    let { HelpSectionData } = this.state;
    HelpSectionData.IsActive = !HelpSectionData.IsActive;
    this.setState({
      HelpSectionData,
    });
  };
  onFeedBackFormHandlechange = (e) => {
    let errors = {};
    // let { HelpSectionData } =this.state;
    //HelpSectionData.sectionRadio = e.target.value;
    if (e.target.checked) {
      this.state.HelpSectionData.ApplicableSection = "";
      this.setState({
        feedbackRadio: true,
        helpSectionRadio: false,
        bothRadio: false,
        sectionRadio: true,
        errors: "",
        //HelpSectionData
      });
    } else {
      this.setState({
        feedbackRadio: false,
        sectionRadio: false,
      });
    }
  };
  onHelpSectionHandlechange = (e) => {
    // let { HelpSectionData } =this.state;
    // HelpSectionData.sectionRadio = e.target.value;
    if (e.target.checked) {
      this.state.HelpSectionData.ApplicableSection = "";
      this.setState({
        helpSectionRadio: true,
        feedbackRadio: false,
        bothRadio: false,
        sectionRadio: true,
        errors: "",
        //HelpSectionData
      });
    } else {
      this.setState({
        helpSectionRadio: false,
        sectionRadio: false,
      });
    }
  };
  onBothHandlechange = (e) => {
    // let { HelpSectionData } =this.state;
    // HelpSectionData.sectionRadio = e.target.value;
    if (e.target.checked) {
      this.state.HelpSectionData.ApplicableSection = "";
      this.setState({
        bothRadio: true,
        helpSectionRadio: false,
        feedbackRadio: false,
        sectionRadio: true,
        errors: "",
        // HelpSectionData
      });
    } else {
      this.setState({
        bothRadio: false,
        sectionRadio: false,
      });
    }
  };
  handleFeedBackSequence = (e) => {
    let { HelpSectionData } = this.state;
    HelpSectionData.SequenceForFeedback = e.target.value;
    this.setState({
      HelpSectionData,
    });
  };

  render() {
    const { filterDetails } = this.state;
    return (
      <>
        {/* <Header /> */}
        <Modal
          show={this.state.show}
          onHide={this.handleHide.bind(this)}
          className="modal fade helpfeature-model"
          size="medium"
          id="dealpopup"
          backdrop="static"
        >
          <Modal.Header closeButton>
            {!this.props.data ? (
              <h4 className="modal-title" id="dealprofile">
                Add {filterDetails.Name}
              </h4>
            ) : (
              <h4 className="modal-title" id="dealprofile">
                Edit {filterDetails.Name}
              </h4>
            )}
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <div className="row">
                  <label>
                    Section:<span className="required">*</span>
                  </label>
                  <div className="form-group col-lg-3">
                    <label className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="foo"
                        value="Feedback"
                        checked={this.state.feedbackRadio || this.state.HelpSectionData.ApplicableSection == "Feedback"}
                        //value={this.state.HelpSectionData.sectionRadio}
                        // handleFeedBackSection
                        onChange={this.onFeedBackFormHandlechange}
                      />
                      <span className="checkmark"></span>
                      <span className="form-check-label">FeedBack Form</span>
                    </label>
                  </div>
                  <div className="form-group col-lg-3">
                    <label className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="foo"
                        value="Help"
                        checked={this.state.helpSectionRadio || this.state.HelpSectionData.ApplicableSection == "Help"}
                        //value={this.state.HelpSectionData.sectionRadio}
                        onChange={this.onHelpSectionHandlechange}
                      />
                      <span className="checkmark"></span>
                      <span className="form-check-label">Help Section</span>
                    </label>
                  </div>
                  <div className="form-group col-lg-3">
                    <label className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="foo"
                        value="All"
                        checked={this.state.bothRadio || this.state.HelpSectionData.ApplicableSection == "All"}
                        //value={this.state.HelpSectionData.sectionRadio}
                        onChange={this.onBothHandlechange}
                      />
                      <span className="checkmark"></span>
                      <span className="form-check-label">Both</span>
                    </label>
                  </div>
                </div>
                <span className="errorMsg">
                  {this.state.errors.SectionRadio}
                </span>
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label for="">
                      Name<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      maxLength="50"
                      className="form-control"
                      id="name"
                      onChange={this.onNameChange}
                      value={this.state.HelpSectionData.Name}
                    />
                    <span className="errorMsg">{this.state.errors.Name}</span>
                  </div>
                  <div className="form-group col-lg-6">
                    <label for="">Description</label>
                    <input
                      type="text"
                      maxLength="200"
                      className="form-control"
                      id="name"
                      onChange={this.onDescriptionChange}
                      value={this.state.HelpSectionData.Description}
                    />
                    <span className="errorMsg">{this.state.errors.Description}</span>
                  </div>
                </div>
              </div>

              <div className="form-section">
                <div className="row">
                  {this.state.HelpSectionData.IsActive &&
                    (this.state.helpSectionRadio ||
                      this.state.HelpSectionData.ApplicableSection == "Help") &&
                    !this.state.feedbackRadio ? (
                    <>
                      <div className="form-group col-lg-6">
                        <label for="">
                          Help Section Sequence
                          <span className="required">*</span>
                        </label>
                        <input
                          id="ButtonSequence"
                          type="number"
                          min="1"
                          maxlength="50"
                          className="form-control"
                          onChange={(e) => {
                            this.handleSequence(e);
                          }}
                          value={this.state.HelpSectionData.SequenceForHelpSection}
                          disabled={!this.state.HelpSectionData.IsActive}
                        />
                        <span className="errorMsg">{this.state.errors.SequenceNumber}</span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.HelpSectionData.IsActive &&
                    (this.state.feedbackRadio ||
                      this.state.HelpSectionData.ApplicableSection ==
                      "Feedback") &&
                    !this.state.helpSectionRadio ? (
                    <>
                      <div className="form-group col-lg-6">
                        <label for="">
                          FeedBack Section Sequence
                          <span className="required">*</span>
                        </label>
                        <input
                          id="ButtonSequence"
                          type="number"
                          min="1"
                          maxlength="50"
                          className="form-control"
                          onChange={(e) => {
                            this.handleFeedBackSequence(e);
                          }}
                          value={this.state.HelpSectionData.SequenceForFeedback}
                          disabled={!this.state.HelpSectionData.IsActive}
                        />
                        <span className="errorMsg">{this.state.errors.SequenceForFeedback}</span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.HelpSectionData.IsActive &&
                    (this.state.bothRadio ||
                      this.state.HelpSectionData.ApplicableSection == "All") ? (
                    <>
                      <div className="form-group col-lg-6">
                        <label for="">
                          Help Section Sequence
                          <span className="required">*</span>
                        </label>
                        <input
                          id="ButtonSequence"
                          type="number"
                          min="1"
                          maxlength="50"
                          className="form-control"
                          onChange={(e) => {
                            this.handleSequence(e);
                          }}
                          value={this.state.HelpSectionData.SequenceForHelpSection}
                          disabled={!this.state.HelpSectionData.IsActive}
                        />
                        <span className="errorMsg">{this.state.errors.SequenceNumber}</span>
                      </div>
                      <div className="form-group col-lg-6">
                        <label for="">
                          FeedBack Section Sequence
                          <span className="required">*</span>
                        </label>
                        <input
                          id="ButtonSequence"
                          type="number"
                          min="1"
                          maxlength="50"
                          className="form-control"
                          onChange={(e) => {
                            this.handleFeedBackSequence(e);
                          }}
                          value={this.state.HelpSectionData.SequenceForFeedback}
                          disabled={!this.state.HelpSectionData.IsActive}
                        />
                        <span className="errorMsg">{this.state.errors.SequenceForFeedback}</span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="form-group col-lg-6">
                    <label className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={this.onStatusChange}
                        defaultChecked={this.state.HelpSectionData.IsActive}
                      />
                      <span className="checkmark"></span>
                      <span className="form-check-label">Is Active</span>
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">
                {!this.props.data ? (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave()}
                    value="Create"
                  />
                ) : (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave()}
                    value="Update"
                  />
                )}
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}
