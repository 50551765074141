import React from 'react';
import { LocalApiBaseUrl,LocalApiBaseUrlImage } from '../Shared/Constant';
import link from '../Images/human_inactive.png';
import work from '../Images/work_inactive.png';
import insight from '../Images/ii_inactive.png';
import diverse from '../Images/diverse_inactive.png';
import Axios from 'axios';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;




export default class QuickLinks extends React.Component {
    constructor() {
        super()
        this.GetQuickLinks = this.GetQuickLinks.bind(this);
        this.state = { link: [] ,humanlinks:[] ,worklinks:[], insightlinks:[],diverselinks:[],allLink:[]}
    }

    componentDidMount() {
        this.GetQuickLinks();
    }
    GetQuickLinks() {


        axiosInstance.get(`${LocalApiBaseUrl}SO/GetQuickLinksInfo`)
            .then((quickLinks) => {
                let allLink = [];
                let quicklink = quickLinks.data;
                
                
                let linkGroup = [... new Set(quicklink.map((eachValue) => eachValue.LinkGroup))];
                
                for(let i=0;i<linkGroup.length;i++)
                {
                    
                    let linkValue = quicklink.filter(x =>x.LinkGroup == linkGroup[i])
                   // let linkImage = quicklink.filter(x =>x.linkImage)
                    allLink.push(<div className="outcome-highlight quicklinks_grid"><h2><img src={`${LocalApiBaseUrlImage}${linkValue[0].LinkImage}`} alt="" />{linkGroup[i]} </h2>
                    <div className="radius-tools">                                  
                    {linkValue.map((x) =>{ return <a target="_blank" href={x.LinkUrl} ><span>{x.LinkName}</span></a>})}
                    </div>
                    </div>)                 
               


                }
                this.setState({allLink})
                // let human = quicklink.filter(x => x.LinkGroup == 'Human + Machine Talent');                
                // let humanlinks = human.map((x) =>{ return <a target="_blank" href={x.LinkUrl} ><span>{x.LinkName}</span></a>})
                // let work = quicklink.filter(x => x.LinkGroup == 'Work Orchestration');                
                // let worklinks = work.map((x) =>{ return <a target="_blank" href={x.LinkUrl} ><span>{x.LinkName}</span></a>})
                // let insight = quicklink.filter(x => x.LinkGroup == 'Insights & Intelligence');                
                // let insightlinks = insight.map((x) =>{ return <a target="_blank" href={x.LinkUrl} ><span>{x.LinkName}</span></a>})
                // let diverse = quicklink.filter(x => x.LinkGroup == 'Diverse Data');                
                // let diverselinks = diverse.map((x) =>{ return <a target="_blank" href={x.LinkUrl} ><span>{x.LinkName}</span></a>})               
                
                // this.setState({ link ,humanlinks ,worklinks ,insightlinks,diverselinks})
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
      


    }


    render() {
 
        return (           
            <div className="main-component">
                <div className="container-fluid">
                    <div className="row">
                    
                        <div className="col-lg-12">
                        {/* {this.GetQuickLinks()} */}
                        {this.state.allLink}
							
                        </div>
                    </div>
                </div>
            </div>
            

        )
    }
}