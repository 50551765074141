import React, { Component } from 'react';
import { LocalApiBaseUrl, getMonthNumber, getPrevMonth } from '../Shared/Constant';
import { AgGridReact, Ag } from 'ag-grid-react';
import DetailsPopup from './DetailsPopup';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;



export default class SettingsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userList: [], roleList: [], clientList: [], viewmore: false,rowSelected:[],
            columnDefs: [
                {
                    headerName: "Edit",suppressMovable: true,
                    width: 50,
                    cellRenderer: (params) => {
                        var link = document.createElement('span');
                        link.className = 'tbledit';
                    link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
                        link.addEventListener('click', (e) => {
                            this.dealpopup(e, params.data);
                        });
                        return link;
                    },
                    suppressFilter: true
                },
                { headerName: "Config Key ", field: "ConfigKey"  ,suppressMovable: true, resizable: true},
                { headerName: "Config Value", field: "ConfigValue" ,suppressMovable: true, resizable: true},
                { headerName: "Description", field: "Description" , sort: "asc" ,suppressMovable: true, resizable: true},
                { headerName: "Is Active", field: "IsActive" , suppressMovable: true,resizable: false, suppressFilter: true,suppressSizeToFit: true,
                cellRenderer: params => {
                    return `<label class="form-check">
                    <input class="form-check-input" type="checkbox" disabled ${params.value ? 'checked' : ''}>
                    <span class="checkmark"></span>
                     </label>`;
                }/* ,cellStyle: function(params) {
                   return {  'text-align': "left" };
                  } */
                },
                
            ],
            rowData: [],
            gridOptions: {
                paginationPageSize: 50,
                rowHeight: 40,
                headerHeight: 40,
                animateRows: true,
                rowSelection: 'multiple',
                defaultColDef: {
                    sortable: true
                },
            }
        }
        this.onClose = this.onClose.bind(this);
        this.dealpopup = this.dealpopup.bind(this);        
        this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
        this.handleSave = this.handleSave.bind(this);

    }

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    onFilterTextBoxChanged(e) {
        this.state.gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
    }
    dealpopup(e, data) {

        if (data != undefined) {
            // edit condition
            if (this.props.featureData.filter((data) => data.Submenu == "Settings" && data.Operations.includes("Edit")).length > 0) {
                e.preventDefault();
                this.setState({ viewmore: true, rowSelected: data })
            }
            else   window.alert("Access Denied !!")

        }
        else {
            // add condition
            if (this.props.featureData.filter((data) => data.Submenu == "Settings" && data.Operations.includes("Add")).length > 0) {

                e.preventDefault();
                this.setState({ viewmore: true, rowSelected: data })
            }
            else   window.alert("Access Denied !!")


        }



    }
    onClose(e) {
        this.setState({ viewmore: false });
    }

    getDetails() {
        
        axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetAppConfig`)
            .then((response) => {
                this.setState({
                    rowData: response.data
                })
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
      
    }


    
    // componentDidMount() {
    //     this.getDetails();
    // }
    componentWillMount() {
        this.getDetails();

        if (this.props.featureData.filter((data) => data.Submenu == "Settings" && data.Operations.includes("Edit")).length == 0) {
            var columnDefs = this.state.columnDefs.filter((data) => data.headerName != "Edit");
            this.setState({
                columnDefs: columnDefs
            })

        }

    }


    handleSave(data) {
       
        axiosInstance.post(`${LocalApiBaseUrl}Configurator/EditAppConfig`,data)
            .then((response) => {
                window.alert(response.data.StatusMessage)
                this.getDetails();
                this.state.gridOptions.api.setFilterModel(null);
                this.state.gridOptions.api.onFilterChanged();
            },
            (error)=>{
                
                trycatchAlertPopup(error)
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
            this.getDetails();

    }

    render() {

        return (
            <div className="main-data">
                {/* <h3 className="grid-heading">Settings</h3> */}
                <div className="tableFilter">
                    <div className="form-section">
                        <div className="form-row">
                            <div className="form-group col-lg-3">
                                <input type="text" className="form-control" id="filter-text-box" placeholder="Filter..." onChange={(e) => { this.onFilterTextBoxChanged(e) }} />
                            </div>
                        </div>
                    </div>
                </div>
                        <div className="row">
                            <div className="col-lg-12">
                                            <div className="ag-theme-balham ag-table">
                                                <AgGridReact
                                                    enableSorting={true}
                                                    enableFilter={true}
                                                    pagination={true}
                                                    paginationPageSize={50}
                                                    floatingFilter={true}
                                                    gridOptions={this.state.gridOptions}
                                                    columnDefs={this.state.columnDefs}
                                                    rowData={this.state.rowData}
                                                    onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                                                </AgGridReact>
                                            </div>
                                        </div>
                                    </div>
                        {this.state.viewmore ? <DetailsPopup data={this.state.rowSelected} show={this.state.viewmore} onClose={this.onClose} onSave={this.handleSave} /> : null}
        </div>

        )
    }
}