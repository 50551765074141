import React, { Component } from "react";
import { connect } from "react-redux";
// import alarm from "../Images/alarm@2x.png";
import MetricDetails from "../Dashboard/MetricDetails";
import { Tabs, Tab } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import DiverseInsights from './DiverseInsights';
import { store } from "../reducers/configureStore";
import ReactTooltip from "react-tooltip";

import {
  LocalApiBaseUrl,
  getMonthNumber,
  getPrevMonth
} from "../Shared/Constant";
import DiverseMetricDetails from "./DiverseMetricDetails";
import TrendGraphsDiverse from "../Analytics/TrendGraphsDiverse";
import MetricComments from "./MetricComments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import i18n from "i18next";
import $ from 'jquery';
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { ValueUnit, ValueUnitNoRoundOff, MetricValueUnitFormat, CommonMetriTileFix } from "../Shared/Common";
import DDBatView from "../Configurator/DDBatView";
axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


//import 'react-owl-carousel2/style.css';
class DiverseKpiContiner extends Component {
  constructor() {
    super();
    this.state = {
      metricDetails: [],
      diverse: [],
      viewGraph: false,
      commentPopUp: false,
      isDD: "",
      kpiDetails: [],
      popupDetails: [],
      diverseData: [],
      FetchFromBAT: true,
    };

    this.handleClick = this.handleClick.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  handleClick(name, Id, mUnit, Details, e) {
    let kpiMapping = Id;
    e.preventDefault();
    this.setState({
      viewGraph: true,
      kpiMapping,
      name,
      mUnit,
      popupDetails: Details
    });
  }
  onCommentClick = (kpiDetails, val) => {
    if (val === 1) {
      this.setState({
        kpiDetails: kpiDetails,
        commentPopUp: true,
      });
    } else {
      this.setState({
        commentPopUp: false,
      });
    }
  };

  onClose(e) {
    this.setState({ viewGraph: false });
  }
  getKpiMetrics(
    offeringIdparam = {
      Client: 11,
      process: 2,
      Locations: 5,
      Month: getPrevMonth()
    }
  ) {
    let offeringId =
      this.props.filterDetails != undefined
        ? Number(this.props.filterDetails)
        : 2;
    let filterProcess =
      offeringIdparam.process == undefined ? 3 : offeringIdparam.process;
    let filterLocations =
      offeringIdparam.Locations == undefined ? 5 : offeringIdparam.Locations;
    let filterClient =
      offeringIdparam.Client == undefined ? 11 : offeringIdparam.Client;
    let dateArray =
      offeringIdparam.Month == 0 ? [] : offeringIdparam.Month.split("-");
    let todayDate = "";
    if (dateArray.length == 2) {
      // todayDate = new Date(dateArray[1], getMonthNumber(dateArray[0]) + 1, 1).toISOString().slice(0, 10);
      todayDate = "1-" + dateArray[0] + "-" + dateArray[1];
    } else {
      todayDate = offeringIdparam.Month;
    }

    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetDiverseData`, {
        params: {
          OrgEntityID: filterClient,
          OfferingID: filterProcess,
          LocationID: filterLocations,
          MonthDT: todayDate,
          LanguageID: this.props.languageData.id
        }
      })
      .then(response => {
        let diverseData = response.data;
        //sorting the KPIData by MetricName before displaying in dashboard 

        if (response.data[1] && response.data[1].kpiData.length > 0) {
          response.data[1].kpiData.sort((a, b) =>
            /* a.MetricName > b.MetricName? 1: b.MetricName > a.MetricName? -1: 0 */

            a.MetricName.replace(/[\u200B-\u200D\uFEFF]/g, '').toLowerCase() > b.MetricName.replace(/[\u200B-\u200D\uFEFF]/g, '').toLowerCase() ? 1 : -1

          );

        }






        let leadMetrics = response.data[1].kpiData.map(kpiDetails => {
          let mappingId = kpiDetails.KPIMappingID;
          let statusColor = "gray-border";
          let Description = kpiDetails.KPIDesp ? kpiDetails.KPIDesp : "";


          if (kpiDetails != undefined) {
            if (kpiDetails.RAGStatus != undefined && kpiDetails.RAGStatus != null) {
              switch (kpiDetails.RAGStatus.toLowerCase()) {
                case "grey":
                  statusColor = "item gray-color";

                  break;

                case "red":
                  statusColor = "item red-color";

                  break;

                case "orange":
                  statusColor = "item amber-color";

                  break;

                case "green":
                  statusColor = "item green-color";

                  break;

                case "amber":
                  statusColor = "item amber-color";

                  break;

                default:

                // code block
              }
            }
          }

          const formattertwo = new Intl.NumberFormat('en', {// SUJITH MADHYASTHA
            useGrouping: true
          })
          const options = {
            loop: true,
            margin: 16,
            responsiveClass: "",
            dots: false,
            responsive: {
              0: {
                items: 1,
                nav: true
              },
              600: {
                items: 2,
                nav: false
              },
              1000: {
                items: 3,
                nav: true,
                loop: false
              },
              1200: {
                items: 3,
                nav: true,
                loop: false
              },
              1400: {
                items: 3,
                nav: true,
                loop: false
              },
              1600: {
                items: 4,
                nav: true,
                loop: false
              }
            }
          };

          let MetricValueUnitFormatData = "";
          let MetricValue = "";
          if(kpiDetails.MetricUnit.includes('$') || kpiDetails.MetricUnit.includes('€') || kpiDetails.MetricUnit.includes('£') || kpiDetails.MetricUnit.includes('₹') || kpiDetails.MetricUnit.includes('SGD') || kpiDetails.MetricUnit.includes('R$')) {
            MetricValue = ValueUnitNoRoundOff(kpiDetails.MetricValue);
          } 
          else {
            MetricValue = ValueUnit(kpiDetails.MetricValue);
          }
          MetricValueUnitFormatData = MetricValueUnitFormat(kpiDetails.MetricUnit.trim(), MetricValue);


          return (
            <>

              <div className={`${statusColor}`}>
                <div className="item-left">
                  <p className="tile-metric-value">
                    {MetricValueUnitFormatData}
                  </p>

                  <p className="tile-metric-desc" data-tip data-for={'customTooltip' + kpiDetails.KPIMappingID}>

                    {kpiDetails.MetricName}

                  </p>
                </div>
                <div className="item-right">
                  {Description ? (
                    <p className="tile-metric-name" data-tip data-for={'customTooltip_desc' + kpiDetails.KPIMappingID}>

                      {Description}

                    </p>
                  ) : (
                    <p className="tile-metric-name">&nbsp;</p>
                  )}
                  <div className="metric-lead-lagg">
                    <p>
                      <span className="lead-lagg-label">{kpiDetails.metDataAttr[0].AttributeName == "Cross Industry Top Performers" ? "Cross Ind. Top Performers" : kpiDetails.metDataAttr[0].AttributeName}</span>
                      <span className="lead-lagg-val">{kpiDetails.metDataAttr[0].AttributeValue == 'NA' ? 'NA' : kpiDetails.metDataAttr[0].AttributeValue.toString()}</span>
                    </p>


                    <p>
                      <span className="lead-lagg-label">{kpiDetails.metDataAttr[1].AttributeName == "Cross Industry Median" ? "Cross Ind. Median" : kpiDetails.metDataAttr[1].AttributeName}</span>
                      <span className="lead-lagg-val">{kpiDetails.metDataAttr[1].AttributeValue == 'NA' ? 'NA' : kpiDetails.metDataAttr[1].AttributeValue.toString()}</span>
                    </p>
                  </div>
                </div>


                <div className="dropdown dropleft ondropOpenColor">
                  <span type="button" className="dropdown-toggle" data-toggle="dropdown"></span>
                  <div className="dropdown-menu">
                    <a href="javascript:void(0)" className="dropdown-item"
                      onClick={this.onCommentClick.bind(this, kpiDetails, 1)}
                    >
                      <span className="info-icon"></span>{i18n.t("Info")}
                    </a>

                    <a href="javascript:void(0)" className="dropdown-item"
                      onClick={e =>
                        this.handleClick(
                          kpiDetails.MetricName,
                          mappingId,
                          kpiDetails.MetricUnit,
                          kpiDetails.metDataAttr,
                          e
                        )
                      }
                    >
                      <span className="insights-new-icon"></span>{i18n.t("Insights")}
                    </a>
                  </div>

                </div>
              </div>


            </>
          );
        });

        this.setState({ List: leadMetrics, diverse: response.data[1], diverseData: diverseData });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
       
      });
  }
  componentDidUpdate() {
    CommonMetriTileFix();
  }
  componentDidMount() {
    this.getKpiMetrics(this.props.filterDetails);
    CommonMetriTileFix();
    this.setState({
      OfferingName: store.getState().CurrentFilter.SyncFilter.OfferingName,
    });
  }
  componentWillReceiveProps(nextProps) {
    this.getKpiMetrics(nextProps.filterDetails);
  }
  MetricValueUnitFormat = (Unitvalue, AttributeValueDigit) => {
    Unitvalue = Unitvalue.trim();
    let returnValue = "";
    if (AttributeValueDigit != 0) {
      if ((Unitvalue === "$" || Unitvalue === "₹" || Unitvalue === "R$" || Unitvalue === "€" || Unitvalue === "£" || Unitvalue === "SGD")) {
        returnValue = Unitvalue + (AttributeValueDigit != 0 ? AttributeValueDigit : "")

      } else if (Unitvalue === "$ Mn" || Unitvalue === "₹ Mn" || Unitvalue === "R$ Mn" || Unitvalue === "€$ Mn" || Unitvalue === "SGD Mn") {
        returnValue = Unitvalue.split(" ")[0] + (AttributeValueDigit != 0 ? AttributeValueDigit : "") + "Mn"
      } else {
        returnValue = AttributeValueDigit + " " + Unitvalue
      }

    } else {
      returnValue = AttributeValueDigit + " " + Unitvalue

    }
    return returnValue;

  }


  render() {
    let diverse = [...this.state.diverseData];

    const { FetchFromBAT } = this.props;
    const { OfferingName } = this.state;
    const AllowBATDataFetchOption = store.getState().CurrentFilter.AllowBATDataFetchOption.AllowBATDataFetchOption;

    let info = "";
    if (this.state.kpiMapping) {
      info = this.state.diverse.kpiData && this.state.diverse.kpiData.filter(x => x.KPIMappingID == this.state.kpiMapping)[0];
    }




    //return <> <h2>{this.props.SODetails.SOCategoryName}</h2>
    return (
      <>
        {/* BAT-DiverseData CONFIGURATION FOR FRONT DOOR IS STARTING HERE FOR DD IT IS SHOWING AS CAROUSEL */}

        {FetchFromBAT === true && AllowBATDataFetchOption === true ? (
          <div className="ddcontainer-tabs">
            <Tabs defaultActiveKey="performance">
              <Tab eventKey="performance" title="Performance Against Benchmark">
                {/* <div className="dd-benchmarkslist"> */}
                <div className="custom-tilecard">
                  <div className="tilecard-wrapper">
                    <DDBatView />
                  </div>
                </div>
                {/* </div> */}
              </Tab>

              <Tab eventKey="insights" title="Other Data Insights">
                <DiverseInsights filterDetails={this.props.filterDetails} />
              </Tab>
            </Tabs>
          </div>
        ) : (
          <div className="ddcontainer-tabs">
            <Tabs defaultActiveKey="performance">
              <Tab
                eventKey="performance"
                title={this.state.diverseData.length > 0 && this.state.diverseData[1].SOCategoryName}
              >
                <div className="dd-benchmarkslist">
                  <div className="custom-tilecard ddtile-wrapper">
                    <div className="tilecard-wrapper no-carousel">{this.state.List}</div>
                  </div>
                </div>
              </Tab>

              <Tab eventKey="insights" title="Other Data Insights">
                <DiverseInsights filterDetails={this.props.filterDetails} />
              </Tab>
            </Tabs>
          </div>
        )}

        {this.state.viewGraph ? (
          <TrendGraphsDiverse
            show={true}
            onClose={this.onClose}
            kpiMappingId={this.state.kpiMapping}
            kpiName={this.state.name}
            kpiUnit={this.state.mUnit}
            Details={this.state.popupDetails}
            info={info}
            dealFilter={this.props.filterDetails}
          />
        ) : null}
        {this.state.commentPopUp ? (
          <MetricComments

            isDD={true}
            info={this.state.kpiDetails}
            show={this.state.commentPopUp}
            onClose={this.onCommentClick.bind(this, 2)}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languageData: state.CurrentFilter.languageData,
    FetchFromBAT: state.CurrentFilter.FetchFromBAT,
  };
};
export default connect(mapStateToProps)(DiverseKpiContiner);
