import React, { Component } from "react";
import MetricDetails from "../SuperAdmin/MetricDetails";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import {
  LocalApiBaseUrl,
  getMonthNumber,
  getPrevMonth
} from "../Shared/Constant";
// import DealPopUp from '../BO_Config_Dealprofile/BO_Config_Deal_popup';
import BOPopUp from "../SuperAdmin/BO2_popup";
import { store } from "../reducers/configureStore";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axiosInstance from "../Shared/interceptor";
import {trycatchAlertPopup} from "../Shared/Constant";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default class BOMetricDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { metricDetails: [], viewmore: false, type: "" };
    this.onClose = this.onClose.bind(this);
    this.deleteBOCategory = this.deleteBOCategory.bind(this);
  }

  getKpiMetrics(
    SODetails = { SOCategoryID: 0 },
    offeringIdparam = {
      Client: 0,
      process: 0,
      Locations: 0,
      Month: getPrevMonth()
    }
  ) {
    this.setState({
      metricDetails:
        this.props.SODetails.kpiData != undefined
          ? this.props.SODetails.kpiData
          : null
    });
  }

  componentDidMount() {
    this.getKpiMetrics(this.props.SODetails, this.props.filterDetails);
  }
  componentWillReceiveProps(nextProps) {
    this.getKpiMetrics(nextProps.SODetails, nextProps.filterDetails);
  }

  dealpopup(type, e) {
    this.setState({ viewmore: true, type: type });
    e.preventDefault();
  }

    deleteBOCategory(data, filterDetails) {

    // e.currentTarget.className = "active"
    // e.preventDefault();
    //data.SOCategoryID=5;
    //  axiosInstance.post(`${LocalApiBaseUrl}Master/DeleteSOCategoryMasterTemplate?soCategoryId=5)
    axiosInstance
      .post(
        `${LocalApiBaseUrl}Master/DeleteSOCategoryMasterTemplate?soCategoryId=${data.SOCategoryID}&offeringId=${filterDetails.process}`
      )

      .then(response => {})
      .catch((error) => {
        trycatchAlertPopup(error);
      });

    store.dispatch({
      type: "DELETE_BO_CATEGORY_MASTER",
      payload: data.SOCategoryName
    });
  }

  onClose() {
    this.setState({ viewmore: false });
  }

  render() {
    return (
    <div className="custom-tilecard mtclayout-tilecard">
      <div className="tilecard-wrapper">
        <h2>
        <span>{this.props.SODetails.SOCategoryName}</span>
          <div className="list-icon">
            {this.props.roledata != undefined && store.getState().CurrentFilter.languageData.id==1 &&
            this.props.roledata.includes("Add") ? (
              <span
                id="rock"
                onClick={e => {
                  this.dealpopup(this.props.SODetails.SOCategoryName, e);
                }}
              >
                <i className="far fa-plus plus-icon-fix"></i>
              </span>
            ) : null}
            {this.props.roledata != undefined && store.getState().CurrentFilter.languageData.id === 1  &&
            this.props.roledata.includes("Delete") ? (
              <span
                id="rock"
                onClick={this.deleteBOCategory.bind(
                  this,
                  this.props.SODetails,
                  this.props.filterDetails
                )}
              >
                <i className="fal fa-trash-alt delete-icon-fix"></i>
              </span>
            ) : null}
          </div>
          </h2>
       
            {this.state.metricDetails.length != 0 ? (
              <MetricDetails
                SOCategoryID={this.props.SODetails.SOCategoryID}
                SOCategoryName={this.props.SODetails.SOCategoryName}
                Details={this.state.metricDetails}
                roledata={this.props.roledata}
                filterDetails={this.props.filterDetails}
                roledata={this.props.roledata}
                isRelatorBlockForOffering={this.props.isRelatorBlockForOffering}
                attributeLabels={this.props.attributeLabels}
              />
              
            ) : null}                                
        </div>

        {this.state.viewmore ? (
          <BOPopUp
            isRelatorBlockForOffering={this.props.isRelatorBlockForOffering}
            SOCategoryID={this.props.SODetails.SOCategoryID}
            SOCategoryName={this.props.SODetails.SOCategoryName}
            onClose={this.onClose}
            opsgrp={this.props.opsgrp}
            filterDetails={this.props.filterDetails}
            type={this.state.type}
            ShowPop={
              this.props.roledata != undefined &&
              (this.props.roledata.includes("Edit") ||
                this.props.roledata.includes("Add"))
            }
            roledata={this.props.roledata}
            attributeLabels={this.props.attributeLabels}
          />
        ) : null}
      </div>
    );
  }
}
