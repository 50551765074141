import React, { Component } from 'react';
import { LocalApiBaseUrl, getMonthNumber, getPrevMonth } from '../Shared/Constant';
import { AgGridReact, Ag } from 'ag-grid-react';
import { store } from "../reducers/configureStore"
import DEIPopUp from './DEIPopup';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;



export default class DEIList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rowSelected: [],
            userList: [], roleList: [], clientList: [],offeringList: [], viewmore: false,
            columnDefs: [

                
                { headerName: "Deal name", field: "DealName", sort: "asc", resizable: true, suppressMovable: true,suppressSizeToFit: true },
                { headerName: "Client Names", field: "ClientName", resizable: true,suppressMovable: true, suppressSizeToFit: true },
                { headerName: "Offerings", field: "Offering", resizable: true,suppressMovable: true, suppressSizeToFit: true },
                { headerName: "Sub Offerings", field: "SubProcess", resizable: true,suppressMovable: true, suppressSizeToFit: true },
                { headerName: "Delivery Location", field: "DeliveryLocation", resizable: true,suppressMovable: true, suppressSizeToFit: true },
                { headerName: "DEI Client Names", field: "DEIClientName", resizable: true,suppressMovable: true, suppressSizeToFit: true },
                { headerName: "DEI Tower", field: "Tower", resizable: true,suppressMovable: true, suppressSizeToFit: true },
                { headerName: "DEI Location", field: "DEIDeliveryLocation", resizable: true,suppressMovable: true, suppressSizeToFit: true },
              

            ],
            rowData: [],
            gridOptions: {
                paginationPageSize: 50,
                rowHeight: 40,
                headerHeight: 40,
                animateRows: true,
                rowSelection: 'multiple',
                defaultColDef: {
                    sortable: true
                },

            }

        }
        this.onClose = this.onClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.dealpopup = this.dealpopup.bind(this);
        this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    }

    onFilterTextBoxChanged(e) {
        this.state.gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
    }

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    dealpopup(e, data) {
         
      
         if (data != undefined) {
            // edit condition
            if (this.props.featureData.filter((data) => data.Submenu == "DEI" && data.Operations.includes("Edit")).length > 0) {
                e.preventDefault();
                this.setState({ viewmore: true, rowSelected: data })
            }
            else   window.alert("Access Denied !!")

        }
        else {
            // add condition
            if (this.props.featureData.filter((data) => data.Submenu == "DEI" && data.Operations.includes("Add")).length > 0) {

                e.preventDefault();
                this.setState({ viewmore: true, rowSelected: data })
            }
            else   window.alert("Access Denied !!")


        }



    }
    onClose(e) {

        this.setState({ viewmore: false });
    }


    getDEI() {
        
         axiosInstance.get(`${LocalApiBaseUrl}DEIDataMapping/GetDEIDealInfo`).then((response) => {
            this.setState({
                rowData: response.data
            })
        })
        .catch((error) => {
            trycatchAlertPopup(error);
          });
  
  

    }

      componentDidMount() {
         this.getDEI();
     }

    componentWillMount() {
        this.getDEI();
    }
    
    handleSave(data){
        
        axiosInstance.post(`${LocalApiBaseUrl}DEIDataMapping/PostDEIDataMapping`,data)
        .then((response) => {
            window.alert(response.data.StatusMessage)
            this.getDEI();
        })
        .catch((error) => {
            trycatchAlertPopup(error);
          });
  
    }

    render() {

        return (
            <div className="main-data">
                {/* <h3 className="grid-heading">DEI Mapping List</h3> */}
                <div className="tableFilter">
                    <div className="form-section">
                        <div className="form-row">
                            <div className="form-group col-lg-3">
                                <input type="text" className="form-control" id="filter-text-box" placeholder="Filter..." onChange={(e) => { this.onFilterTextBoxChanged(e) }} />
                            </div>
                            {this.props.featureData.filter((data) => data.Submenu == "DEI" && data.Operations.includes("Add")).length > 0 &&
                            <div className="form-group col-lg-3">
                                <button type="button" className="btn btn-primary btn-sm" onClick={(e) => { this.dealpopup(e) }}><span><FontAwesomeIcon icon={faPlusCircle} /></span> Add DEI Mapping</button>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                        <div className="row">
                            <div className="col-lg-12">
                                            <div className="ag-theme-balham ag-table">
                                                    <AgGridReact
                                                        enableSorting={true}
                                                        enableFilter={true}
                                                        pagination={true}
                                                        paginationPageSize={50}
                                                        floatingFilter={true}

                                                        gridOptions={this.state.gridOptions}
                                                        columnDefs={this.state.columnDefs}
                                                        rowData={this.state.rowData}
                                                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                                                    </AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                            {this.state.viewmore ? <DEIPopUp data={this.state.rowSelected} show={this.state.viewmore} onClose={this.onClose} filterDetails={this.props.filterDetails} onSave={this.handleSave} /> : null}

                        </div>
        )
    }
}