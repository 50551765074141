import React, { Component } from "react";
import { Card } from "react-bootstrap";
import AddWOStep_MTC from "./AddWOStep_MTC";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { connect } from "react-redux";

 class CorouselDiv extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editStepPopupStatus: false,
            editData: {}
        }
    }

    handleClosePopup = () => {
        this.setState({
            editStepPopupStatus: false,
        })
    }

    handleEditStep = () => {
        this.setState({
            editStepPopupStatus: true,
            editData: {
                SOCategoryID: this.props.SOCategoryID,
                SOCategoryName: this.props.metricDesp,
                SOCategorySeq: this.props.SOCategorySeq,
                SOCategoryDesp: this.props.SOCategoryDesp,
                SOCategoryType: this.props.SOCategoryType,
                SOCategoryTypeID:this.props.SOCategoryID,
                ParentSOCategoryID:this.props.ParentSOCategoryID
            }
        })
    }
    render() {

        let { metricSeq, metricDesp, selectedStep, click, metricActive, screen } = this.props;
        const { editStepPopupStatus, editData } = this.state
        return (
            <div className={screen === "Dashboard" ? metricActive == false ? "item metricdisabled" : "item" : "item"} onClick={click}>
                <div className={
                    screen !== "Dashboard" ?
                        (selectedStep == null ? "carousel-content active" :
                            metricActive == false ? "carousel-content disabled" :
                                metricSeq == selectedStep ? "carousel-content active" :
                                    "carousel-content") :
                        (selectedStep == null ? "carousel-content active" :
                            metricActive == false ? "carousel-content fd-stepdetails disabled" :
                                metricSeq == selectedStep ? "carousel-content active" :
                                    "carousel-content"
                        )
                }>
                    <span className="num-tools">
                        <span>{metricSeq}</span>
                    </span>
                    <p>{metricDesp}</p>  
                     {screen === "Configurator" ? 
                                    (this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") || (this.props.RecordStatus && this.props.RecordStatus[1] === "True" && !this.props.isManintencneWindowOpen) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Publish" && !this.props.isManintencneWindowOpen) || (this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Save" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Submit").length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Submit" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Publish").length) { return each } }).length === 0) ? "" :
                                    <span class="edit-step" onClick={this.handleEditStep}><FontAwesomeIcon icon={faPencilAlt} /></span> : null
            } 
                                    </div>
                {editStepPopupStatus && <AddWOStep_MTC
                    show={editStepPopupStatus}
                    Details={editData}
                    modalClose={this.handleClosePopup}
                    mode={"UPDATE"}
                    getOfferingAPICallback={this.props.getOfferingAPICallback}
                    filter={this.props.filterDetails}
                    {...this.props}
                />}
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        roleReducers: state.roleReducers.roleReducers,
    };
};


export default connect(mapStateToProps, "")(CorouselDiv);
