import React from 'react';
import { IOJData } from '../Shared/Constant';

// import alarm from '../Images/alarm@2x.png';
// import human from '../Images/human.png';
import bo from '../Images/Shape.png';
import { Navigation as Navigate } from '../Shared/Constant';




export default function ViewinsightNavigation(props) {
    
    

    let activeLinks = (e) => {

        let activeElements = document.getElementsByClassName("insights insights-4 active")
        let activeArray = [...activeElements];
        let deactivateClass = activeArray.length != 0 ? activeArray.map((eachActive) => {
            eachActive.className = eachActive.className.replace(/\bactive\b/g, "");
        }) : "";

        e.currentTarget.className = e.currentTarget.className + " active"
       
        let selectedTab = ""
        IOJData.filter((each)=>{if(e.currentTarget.getElementsByClassName("insights-title")[0].children[0].innerText === each.IOJNameValue){
            selectedTab = each.IOJName
        }})
        //props.insightSelection(e.currentTarget.getElementsByClassName("insights-title")[0].children[0].innerText)
        props.setMaturityPhase(selectedTab);
        //props.clearDropdown();
        
     

    }

    let GenarateNavi = IOJData.map((IOJMain,index) => {
        var activeclass="insights insights-4"
          activeclass=props.phase === IOJMain.IOJName?  `${activeclass} active`: activeclass
         activeclass=props.IOjSeg[index].length===0?  `${activeclass} menu-disable`: activeclass
        return <li className="col-lg-3 col-md-3 col-sm-6 col-xs-6 col-xxs">
          <div className={activeclass} onClick={(e) => activeLinks(e)}>
                <div className="insights-header">
                    <div className="insights-img">
                        <img src={IOJMain.image} alt="" />
                    </div>
                    <div className="insights-title">
                        <h3 id="insight-title" >{IOJMain.IOJNameValue}</h3>
                        <p>{IOJMain.IOJDesc}</p>
                    </div>
                </div>
            </div>
        </li>
    });
    return (

        <ul className="row">
            {GenarateNavi}
        </ul>



    )
}