import { INITIALISE_MINDMAP, MINDMAP_CLICK, SAVECLICK } from "../actions/mindMapAction";

const initialMindMapData = {
   InitialData : [],
   clickStatus: false,
   saveClick: false,
}

export let mindMapReducer = (state = initialMindMapData,action) => {
    switch(action.type){
        case INITIALISE_MINDMAP : {
            state = {...state}
            state["InitialData"] = action.payload
            return state
        }
        case MINDMAP_CLICK:{
            state = {...state}
            state["clickStatus"] = action.payload
            return state
        }
        case SAVECLICK:{
            state = {...state}
            state["saveClick"] = action.payload
            return state
        }
        default: return state
    }
}