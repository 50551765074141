import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { regexForConfMasterFields } from '../Shared/Constant';
import { regexForTextAreas } from '../Shared/Constant';

export default class DeliveryRegionPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            DeliveryRegion: {
                ApproverRemarks: "",
                DeliveryRegionName: "",
                DeliveryRegionName_En: "",
                ID: 0,
                IsActive: false,
                RequestorRemarks: "",
                Status: ""
            },
            errors: [],
            formIsValid: false,


        }

        this.onDeliveryRegionChange = this.onDeliveryRegionChange.bind(this);
        this.onRemarkChange = this.onRemarkChange.bind(this);
        this.onIsActiveChange = this.onIsActiveChange.bind(this);

    }

    onDeliveryRegionChange(event) {
        let errors = {};
        var DeliveryRegion = this.state.DeliveryRegion;
        DeliveryRegion.DeliveryRegionName = event.target.value;
        this.setState({
            DeliveryRegion: DeliveryRegion,
            errors: errors
        })
    }

    onRemarkChange(event) {
        let errors = {};
        var DeliveryRegion = this.state.DeliveryRegion;
        DeliveryRegion.RequestorRemarks = event.target.value;
        this.setState({
            DeliveryRegion: DeliveryRegion,
            errors: errors
        })
    }

    onIsActiveChange(event) {
        const DeliveryRegion = this.state.DeliveryRegion;
        DeliveryRegion.IsActive = event.target.checked;
        this.setState({
            DeliveryRegion: DeliveryRegion
        })
    }

    handleSave() {
        if (this.validateForm()) {
            this.props.onSave(this.state.DeliveryRegion)
            this.props.onClose();
        }
    }
   

    validateForm() {
        const DeliveryRegion = this.state.DeliveryRegion;
        let errors = {};
        let formIsValid = true;
        var data = this.props.rowData;
    

        if (!DeliveryRegion["DeliveryRegionName"].trim()) {
            formIsValid = false;
            errors["DeliveryRegionName"] = "Delivery Region Name is required";

        }
        else {
            //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
            //Validate TextBox value against the Regex.
            var isValid = regexForConfMasterFields.test(DeliveryRegion["DeliveryRegionName"]);
            if (!isValid) {
                formIsValid = false;
                errors["DeliveryRegionName"] = "DeliveryRegion Name contains Special characters";
            }
        }

        if(DeliveryRegion["RequestorRemarks"]==null)
        {
            formIsValid = false;
            errors["RequestorRemarks"] = "Requestor Remarks are required";

        }

       else if (!DeliveryRegion["RequestorRemarks"].trim()) {
            formIsValid = false;
            errors["RequestorRemarks"] = "Requestor Remarks are required";

        }
        else {
            //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
            // var regex = /^[A-Za-z ]+$/
            //Validate TextBox value against the Regex.
            var isValid = regexForTextAreas.test(DeliveryRegion["RequestorRemarks"]);
            if (!isValid) {
                formIsValid = false;
                errors["RequestorRemarks"] = "Requestor Remarks contains Special characters";
            }
        }
       
        if (formIsValid) {
            this.setState({
                formIsValid: true,
                errors: errors
            })
        }
        else {
            this.setState({
                formIsValid: false,
                errors: errors
            })
        }
        return formIsValid;

    }

    componentWillMount() {
        if (this.props.data) {
            this.setState({
                DeliveryRegion : this.props.data,
            })
        }
    }

    render() {

        return (
            <>
                {/* <Header /> */}
                <Modal show={this.props.show} onHide={()=>this.props.onClose()} class="modal fade" size="medium" id="dealpopup">
                    <Modal.Header closeButton>
                        {!this.props.data ?
                            <h4 className="modal-title" id="dealprofile">Add Delivery Region</h4>
                            : <h4 className="modal-title" id="dealprofile">Edit Delivery Region</h4>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        
                            <form>
                                <div className="form-section">

                                    <div className="row">
                                       
                                        <div className="form-group col-lg-6">
                                        <label for="">Delivery Region Name<span className="required">*</span></label>
                                        <input type="text" readOnly={this.props.data!=null ? true : false} className="form-control" id="name" onChange={this.onDeliveryRegionChange} value={this.state.DeliveryRegion.DeliveryRegionName} />
                                        <span className="errorMsg">{this.state.errors.DeliveryRegionName}</span>
                                        </div>
                                        
                                        <div className="form-group col-lg-6">
                                        <label for="">Requestor Remarks<span className="required">*</span></label>
                                        <textarea maxLength="200" readOnly={this.props.data ? true : false} className="form-control" id="remarks" onChange={this.onRemarkChange} value={this.state.DeliveryRegion.RequestorRemarks} />
                                        <span className="errorMsg">{this.state.errors.RequestorRemarks}</span>
                                        </div>
                                        
                                        {this.props.data ?
                                          this.props.data.Status==="Approved" || this.props.data.Status==="Rejected"?
                                            <div className="form-group col-lg-6">
                                                <label for="">Approver Remarks<span className="required"></span></label>
                                                <textarea maxLength="200"  className="form-control" id="DeliveryContact1" disabled={this.props.data}  value={this.props.data.ApproverRemarks} />

                                            </div> :null
                                          :null
                                        }
                                         {this.props.data ?
                                            <div className="form-group col-lg-6 label-adjust">
                                            <label className="form-check">
                                                <input className="form-check-input" readOnly={ this.props.data.Status==="Approved"? false:true}  type="checkbox" onChange={this.onIsActiveChange} defaultChecked={this.state.DeliveryRegion.IsActive} />
                                                <span className="checkmark"></span>
                                                <span className="form-check-label">Is Active</span>
                                            </label>
                                            </div> 
                                            : 
                                            <div className="form-group col-lg-6">
                                            <label className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={this.onIsActiveChange} defaultChecked={this.state.DeliveryRegion.IsActive} />
                                                <span className="checkmark"></span>
                                                <span className="form-check-label">Is Active</span>
                                            </label>
                                            </div>
                                        }
                                    </div>

                                </div>


                            </form>
                        
                    </Modal.Body>
                    {<Modal.Footer>
                        <div className="modal-btn">
                                {!this.props.data ?
                                    <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value="Create" />
                                    : this.props.data.Status==="Approved" && <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value="Update" /> 
                                }
                        </div>
                    </Modal.Footer>}
                </Modal>
            </>
        )
    }
}