import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { LocalApiBaseUrl, regexForTextAreas,regexForUnit } from '../Shared/Constant';
import i18n from "i18next";
import axiosInstance from '../Shared/interceptor';
import { store } from "../reducers/configureStore"
import Header from '../Shared/Header';
import { regexForConfMasterFields } from '../Shared/Constant';


axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;




export default class UnitPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            unit:"", 
            RequestorRemarks:"",
            errors:{unit:"",RequestorRemarks:""},
            saveError:"",
            apivalue: "", selectValue: this.props.SelectValue, Display: false,
        }

    }

    onUnitChange = (e) =>{
        let value = e.target.value;
        let error = this.state.errors;
        if(!value){
            error.unit = "";
            this.setState({errors:error,unit:value,saveError:""})
        }
        else{
            if(regexForUnit.test(value)){
                error.unit = "";
            this.setState({errors:error,unit:value,saveError:""})
            }else{
                
                error.unit = i18n.t("AddunitErrorM2");
            this.setState({errors:error,unit:value,saveError:""}) 
            }
        }
    }
    onRemarkChange(e) {
        
        let value = e.target.value;
        let error = this.state.errors;
        if(!value){
            error.RequestorRemarks = "";
            this.setState({errors:error,RequestorRemarks:value,saveError:""})
        }
        else{
            if(regexForTextAreas.test(value)){
                error.RequestorRemarks = "";
            this.setState({errors:error,RequestorRemarks:value,saveError:""})
            }else{
                error.RequestorRemarks = i18n.t("addWoErrorM9");
            this.setState({errors:error,RequestorRemarks:value,saveError:""}) 
            }
        }
    }
    validateForm = ()=>{
        let errors = this.state.errors;
        if(errors.RequestorRemarks || errors.unit ){
            return false;
        }
        else{
          if(!this.state.RequestorRemarks.trim() || !this.state.unit.trim() ){
              if(!this.state.RequestorRemarks.trim() ){
                errors.RequestorRemarks = i18n.t("addWoErrorM8")
              }
              if(!this.state.unit.trim() ){
                errors.unit = i18n.t("Unit_is_required")
              }
            this.setState({errors:errors})
            return false;
        }
    }
     
        return true;
    }
    handleSave(e) {
        
        if (this.validateForm()) {
            let data = {
                "MetricUnit":this.state.unit.trim(),
                "RequestorRemarks":this.state.RequestorRemarks.trim()
            }
            // axiosInstance.post(`${LocalApiBaseUrl}SOFilter/AddMetricUnit`,data)
            //     .then((response) => {
            //         window.alert(response.data.StatusMessage)
            //     },
            //     (error)=>{
                    
                   
            //     });
             this.props.onSave(data)

            this.handleHide();
        }
    }
    handleHide(e) {
        
        this.setState({ show: false });
        this.props.onClose(e);

    }
   
    componentDidMount() {
        
        // this.validateForm();
        this.setState({
            show: this.props.show
        })
    }
    render() {
        
        return (
            <>
                {/* <Header /> */}
                <Modal show={this.state.show} onHide={this.handleHide.bind(this)} className="modal fade" size="medium" id="dealpopup">
                    <Modal.Header closeButton>
                            <h4 className="modal-title" id="dealprofile">{i18n.t("Add_Unit")}</h4>
                           
                    </Modal.Header>
                    <Modal.Body>
                        
                            <form>
                                <div className="form-section">

                                    <div className="row">
                      
                                        <div className="form-group col-lg-6">
                                            <label for="">{i18n.t("Unit_of_Measure")}<span className="required">*</span></label>
                                            <input type="text" maxLength="50" className="form-control" id="name" onChange={this.onUnitChange} value={this.state.unit} />
                                            <span className="errorMsg">{this.state.errors.unit}</span>
                                        </div> 
                                        

                                    
                                          <div className="form-group col-lg-6">
                                          <label for="">{i18n.t("Requestor_Remarks")}<span className="required">*</span></label>
                                          <textarea maxLength="200"   className="form-control" id="remarks" onChange={this.onRemarkChange.bind(this)} value={this.state.RequestorRemarks} />
                                          <span className="errorMsg">{this.state.errors.RequestorRemarks}</span>
                                      </div>
                                    </div>

                                </div>


                            </form>
                            <span className="errorMsg">{this.state.saveError}</span>
                    </Modal.Body>
                    {<Modal.Footer>
                        <div className="modal-btn">
                                
                                    <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value={i18n.t("Create")} />
                                    
                        </div>
                    </Modal.Footer>}
                </Modal>
            </>
        )
    }
}