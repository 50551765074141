import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import _ from "lodash";
import classNames from "classnames";

// IMPORT CUSTOM COMPONENTS/FUNCTIONS
import { validateBATFieldValue } from "../Shared/Constant";

class MetricParametersModal extends Component {
  constructor(props) {
    super(props);
    const metricParameters = _.get(props, "metricData.parameterDetails", []);
    const metricVolumetrics = _.get(props, "metricData.volumetricDetails", []);

    let formFields = [];

    if (props.renderFor === "metricParameters") {
      formFields = _.map(metricParameters, (eachParameter) => ({
        id: `formField-${eachParameter.SynOpsParameterID}`,
        label: eachParameter.SynOpsParameterName,
        value: eachParameter.enteredValue,
        unit: eachParameter.parameterUnitFormatted,
        isValid: true,
        isFocused: false,
      }));
    } else {
      formFields = _.map(metricVolumetrics, (eachVolumetric) => ({
        id: `formField-${eachVolumetric.volumetricId}`,
        label: eachVolumetric.volumetricName,
        value: eachVolumetric.enteredValue,
        unit: eachVolumetric.volumetricUnitFormatted,
        isValid: true,
        isFocused: false,
      }));
    }

    this.state = {
      formFields,
    };
  }

  // EVENT HANDLER FOR THE "CHANGE" EVENT OF THE FORM INPUT
  handleChangeFormField = ({ target }) => {
    const { formFields } = this.state;
    const clonedFormFields = _.cloneDeep(formFields);
    const fieldValue = _.trim(String(target.value));
    const fieldId = target.id;
    const foundFormField = _.find(clonedFormFields, ["id", fieldId]);
    foundFormField.value = fieldValue;
    this.setState({
      formFields: clonedFormFields,
    });
  };

  // EVENT HANDLER FOR THE "FOCUS" EVENT OF THE FORM INPUT
  handleFocusFormField = ({ target }) => {
    const { formFields } = this.state;
    const clonedFormFields = _.cloneDeep(formFields);
    const fieldId = target.id;
    const foundFormField = _.find(clonedFormFields, ["id", fieldId]);
    foundFormField.isFocused = true;
    this.setState({
      formFields: clonedFormFields,
    });
  };

  // EVENT HANDLER FOR THE "BLUR" EVENT OF THE FORM INPUT
  handleBlurFormField = ({ target }) => {
    const { formFields } = this.state;
    const clonedFormFields = _.cloneDeep(formFields);
    const fieldId = target.id;
    const foundFormField = _.find(clonedFormFields, ["id", fieldId]);
    const isValueValid = validateBATFieldValue(target.value);
    foundFormField.isValid = isValueValid;
    foundFormField.isFocused = false;
    this.setState({
      formFields: clonedFormFields,
    });
  };

  handleClickCancelBtn = () => {
    const { onCancel } = this.props;
    onCancel();
  };

  validateForm = () => {
    const { areFormFieldsMandatory } = this.props;
    const { formFields } = this.state;
    const clonedFormFields = _.cloneDeep(formFields);
    let isFormValid = true;

    _.forEach(clonedFormFields, (eachFormField) => {
      const fieldValue = String(eachFormField.value);
      const isValid = validateBATFieldValue(fieldValue);
      // IF THE VALUE IS INVALID OR FIELD IS MANDATORY AND VALUE IS EMPTY THEN SET THE FORM TO INVALID
      if (!isValid || (areFormFieldsMandatory && _.isEmpty(fieldValue))) {
        isFormValid = false;
        eachFormField.isValid = false;
      }
    });

    this.setState({
      formFields: clonedFormFields,
    });

    return isFormValid;
  };

  // EVENT HANDLER FOR THE "SAVE" BUTTON. SAVE THE FORM FIELD VALUE AGAINST THE CORRESPONDING METRIC PARAMETER
  handleClickSubmitBtn = () => {
    const { onSubmit, metricData, renderFor } = this.props;
    const { formFields } = this.state;

    // IF THE FORM IS VALID
    if (this.validateForm()) {
      const clonedMetricData = _.cloneDeep(metricData);

      if (renderFor === "metricParameters") {
        _.forEach(clonedMetricData.parameterDetails, (eachParameter) => {
          const formFieldValue = _.get(_.find(formFields, ["label", eachParameter.SynOpsParameterName]), "value");
          eachParameter.enteredValue = formFieldValue;
        });
      } else {
        _.forEach(clonedMetricData.volumetricDetails, (eachVolumetric) => {
          const formFieldValue = _.get(_.find(formFields, ["label", eachVolumetric.volumetricName]), "value");
          eachVolumetric.enteredValue = formFieldValue;
        });
      }

      onSubmit(clonedMetricData, renderFor);
    }
  };

  render() {
    const {
      metricData,
      errMsgInvalidValue,
      renderFor,
      areFormFieldsMandatory,
      modalSubHeaderText,
      sharedAccessDetail = [],
    } = this.props;
    const { formFields } = this.state;

    return (
      <Modal show centered className="parameterPopupModal metric-parameters-modal" backdrop="static">
        <Modal.Header className="formulatext-modalheader">
          <Modal.Title className="mb-2">{metricData.metricName}</Modal.Title>
          {!_.isEmpty(modalSubHeaderText) && <div className="formula-text">{modalSubHeaderText}</div>}
        </Modal.Header>

        <Modal.Body>
          {_.map(formFields, (eachFormField) => {
            const isValid = eachFormField.isValid;
            const isFocused = eachFormField.isFocused;
            return (
              <>
                <div
                  className={classNames(
                    "metric-value",
                    "form-group",
                    { "highlight-box": isValid && isFocused },
                    { "active-box": !eachFormField.isValid }
                  )}
                >
                  <div>
                    <label for={eachFormField.id} className="form-label">
                      {_.upperFirst(eachFormField.label)}&nbsp;(
                      {eachFormField.unit === "" ? "Number" : eachFormField.unit})
                      {areFormFieldsMandatory && <span className="required">*</span>}
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      maxlength="15"
                      id={eachFormField.id}
                      className={
                        sharedAccessDetail.length &&
                        sharedAccessDetail.filter(
                          (user) => user.UserName === sessionStorage.getItem("EnterpriseID") && user.AccessLevelID === 1
                        ).length !== 0
                          ? "form-cotrol disabled"
                          : "form-cotrol"
                      }
                      disabled={
                        sharedAccessDetail.length &&
                        sharedAccessDetail.filter(
                          (user) => user.UserName === sessionStorage.getItem("EnterpriseID") && user.AccessLevelID === 1
                        ).length !== 0
                          ? true
                          : false
                      }
                      value={eachFormField.value}
                      onChange={(e) => this.handleChangeFormField(e)}
                      onFocus={(e) => this.handleFocusFormField(e)}
                      onBlur={(e) => this.handleBlurFormField(e)}
                    />
                  </div>
                </div>
                {!isValid && (
                  <div className="invalidMsg">
                    {_.isEmpty(_.trim(String(eachFormField.value))) ? "Value is required" : errMsgInvalidValue}
                  </div>
                )}
              </>
            );
          })}

          {renderFor === "metricParameters" && !_.isEmpty(metricData.calculationFormulaParsed) && (
            <div className="formula-text">Calculation: {metricData.calculationFormulaParsed}</div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <button type="button" className="btn btn-default" onClick={() => this.handleClickCancelBtn()}>
            Cancel
          </button>
          <button
            type="button"
            disabled={
              sharedAccessDetail.length &&
              sharedAccessDetail.filter(
                (user) => user.UserName === sessionStorage.getItem("EnterpriseID") && user.AccessLevelID === 1
              ).length !== 0
                ? true
                : false
            }
            className={
              sharedAccessDetail.length &&
              sharedAccessDetail.filter(
                (user) => user.UserName === sessionStorage.getItem("EnterpriseID") && user.AccessLevelID === 1
              ).length !== 0
                ? "btn btn-purple-secondary disabled"
                : "btn btn-purple-secondary"
            }
            onClick={() => this.handleClickSubmitBtn()}
          >
            {renderFor === "metricVolumetrics" ? "Save" : "Calculate"}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

MetricParametersModal.propTypes = {
  metricData: PropTypes.object.isRequired,
  sharedAccessDetail: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errMsgInvalidValue: PropTypes.string.isRequired,
  areFormFieldsMandatory: PropTypes.bool,
  modalSubHeaderText: PropTypes.string,
  renderFor: PropTypes.oneOf(["metricParameters", "metricVolumetrics"]).isRequired,
};

export default MetricParametersModal;
