import React, { Component } from "react";
import BOContainer from "./BO";
import { LocalApiBaseUrl } from "../Shared/Constant";
import BOMetricDetails from "./BOMetricDetails";
import DiverseKpiContiner from "./DiverseKpiContainer";
import { store } from "../reducers/configureStore";
import DiverseCategoryPopUp from "../SuperAdmin/DiverseCategoryPopUp";
import DiverseInsights from "./DiverseInsights";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from "i18next";
import {
  faPlus,
  faTrashAlt,
  faPencilAlt,
  faQuestionCircle
} from "@fortawesome/free-solid-svg-icons";
import DiverseMasterMetricDetails from "./DiverseMasterMetricDetails";
import emptycomponent from "../Images/Empty State.svg";
import ReactDOM from "react-dom";
import { reduxUserProfile } from "../Shared/Constant";

export default class DiverseDataContainer extends Component {
  constructor() {
    super();
    this.state = {
      metricRowDetails: [],
      diverseMasterData: [],
      viewmore: false,
      operationArray: [],
      IsRelatorBlock: false
    };
    this.onClose = this.onClose.bind(this);
    this.setOperations = this.setOperations.bind(this);
  }
  onClose() {

    this.setState({ viewmore: false });
  }
  setOperations() {

    const data = store.getState();
    // let arrayOfOperations = reduxUserProfile
    let operation = this.state.operationArray;
    data.roleReducers["roleReducers"].map(i => {
      if (i.FeatureName === "Master Template Configurator") {
        operation.push(...i.Operations);
        return operation;
      }
    });
    //operation.push(...arrayOfOperations)

    this.setState({
      operationArray: operation
    });


  }
  getDiverseData() {
    var data = store.getState();

    this.state.diverseMasterData = data.diverseMasterReducers["diverse"];

    this.setState({ IsRelatorBlock: data.CurrentFilter.IsRelatorBlock });
   
    let allMetricRowDetails = this.state.diverseMasterData.map(rowDetails => {
      return (
        <DiverseKpiContiner
          key={
            rowDetails.SOCategoryID
              ? rowDetails.SOCategoryID != 5
              : rowDetails.SOCategoryID
          }
          SODetails={rowDetails}
          filterDetails={this.props.filterDetails}
          roledata={this.state.operationArray}
        />
      );
    });
    let metricRowDetails = allMetricRowDetails.filter(
      data => data.props.SODetails.SOCategoryID !== 5
    );
    let SOCatlist1 = [];

    this.setState({ metricRowDetails: metricRowDetails });
  }
    componentWillMount() {

    this.getDiverseData(this.props.filterDetails);
  }

    dealpopup(e) {

    var a = this.state.viewmore;
    // e.currentTarget.className = "active"
    e.preventDefault();
    this.setState({ viewmore: true });
  }

    componentWillReceiveProps(nextProps) {

    store.subscribe(() => {


      var data = store.getState();

      this.setState({ diverseData: data.diverseMasterReducers["diverse"] });
    });
  }

  deleteMetric(e) {}

  componentDidMount() {
    
    this.getDiverseData(this.props.filterDetails);
    this.setOperations();
  }
  render() {
    var data = store.getState();
    //In DD tab, we can only add 1 category (usually called performance against benchmarks) so below length condition should not be removed
    return (
      <>
        {this.props.roledata != undefined &&
          this.props.roledata.includes("Add") && store.getState().CurrentFilter.languageData.id==1 &&
          (data.diverseMasterReducers["diverse"].length < 1 && (
            <div className="row">
              <div className="col-lg-12">
                <div className="title-with-icon d-flex align-items-center mb-4">
                  <div className="list-icon mtc-list-icon">
                    <span
                      onClick={e => {
                        this.dealpopup(e);
                      }}
                    >
                      <i className="far fa-plus plus-icon-fix"></i>
                    </span>
                  </div>
                </div>
                <div className="emptymetric-msg">
      <img alt="" src={emptycomponent} width="80px" height="80px" />
    <h6>{i18n.t("Please click on '+' to add category")}</h6>
  </div>
              </div>
            </div>
          ))}

        {this.state.viewmore ? (
          <DiverseCategoryPopUp
            show={
              this.props.roledata != undefined &&
              this.props.roledata.includes("Add")
            }
            onClose={this.onClose}
            filterDetails={this.props.filterDetails}
          />
        ) : null}

        {data.diverseMasterReducers["diverse"].length !== 0 ? (
          <>
            {data.diverseMasterReducers["diverse"].map(SOData => (
              <DiverseMasterMetricDetails
                SODetails={SOData}
                filterDetails={this.props.filterDetails}
                roledata={this.props.roledata}
                IsRelatorBlockForOffering={this.state.IsRelatorBlock}
              />
            ))}
          </>
        ) : null}
      </>
    );
  }
}
