import React, { Component } from "react";
import { LocalApiBaseUrl, getMonthNumber, getPrevMonth } from "../Shared/Constant";

import { Doughnut, Pie, HorizontalBar } from "react-chartjs-2";
import Chart from "chart.js";
import "chartjs-plugin-datalabels";
import i18n from "i18next";
import axiosInstance from "../Shared/interceptor";
import ValueRealization from "./ValueRealization/ValueRealization";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class IOJ_ValueRealization extends Component {
  constructor(props) {
    super();
    this.state = {
      valueRealization: [],
      datalen: false,
      CBVPnL: { labels: [], datasets: [{ data: [] }] },
      CBVNav: { labels: [], datasets: [] },
      gu: { labels: [], datasets: [] },
      og: { labels: [], datasets: [] },
      ogBA: { labels: [], datasets: [] },
      ogNonBA: { labels: [], datasets: [] },
      sdo: { labels: [], datasets: [] },
      sdoClient: { labels: [], datasets: [] },
      sdoProject: { labels: [], datasets: [] },
      offerings: { labels: [], datasets: [] },
      boi: { labels: [], datasets: [] },
      industry: { labels: [], datasets: [] },
      client: { labels: [], datasets: [] },
      yoy: { labels: [], datasets: [] },
      initiativeType: { labels: [], datasets: [] },
      location: { labels: [], datasets: [] },
      gufinal: { labels: [], datasets: [] },
    };
  }
  /* GetAllGraphData(offeringIdparam = { Client: 0, process: 0, Locations: 0, Month: getPrevMonth() }) {
    let filterProcess = offeringIdparam.process == undefined ? 0 : offeringIdparam.process;
    let filterLocations = offeringIdparam.Locations == undefined ? 0 : offeringIdparam.Locations;
    let filterClient = offeringIdparam.Client == undefined ? 0 : offeringIdparam.Client;
    let dateArray = offeringIdparam.Month == 0 ? [] : offeringIdparam.Month.split("-");
    let todayDate = "";
    if (dateArray.length == 2) {
      // todayDate = new Date(dateArray[1], getMonthNumber(dateArray[0]) + 1, 1).toISOString().slice(0, 10);
      todayDate = "1-" + dateArray[0] + "-" + dateArray[1];
    } else {
      todayDate = offeringIdparam.Month;
    }
    if (filterProcess != 0 && filterLocations != 0 && filterClient != 0 && todayDate != 0) {
      axiosInstance
        .get(`${LocalApiBaseUrl}Value/GetAllGraphData`, {
          params: {
            OrgEntityID: filterClient,
            OfferingID: filterProcess,
            LocationID: filterLocations,
            MonthDT: todayDate,
          },
        })
        // //?clientVal=NULL&fyVal=2018&slocVal=NULL&offerVal=NULL&towerVal=NULL")
        // //?clientVal=scrambled.ClientName1&fyVal=2019&slocVal=NULL&offerVal=NULL&towerVal=NULL")
        .then((res) => {


          let datalen = "";
          if (
            res.data.CBVNav.length != 0 &&
            res.data.CBVPnL.length != 0 &&
            res.data.boi.length != 0 &&
            res.data.client.length != 0 &&
            res.data.gu.length != 0 &&
            res.data.industry.length != 0 &&
            res.data.initiativeType.length != 0 &&
            res.data.offering.length != 0 &&
            res.data.og.length != 0 &&
            res.data.portfolioGu.length != 0 &&
            res.data.sdoMetric.length != 0 &&
            res.data.sloc.length != 0 &&
            res.data.yoy.length != 0
          ) {
            datalen = true;
          } else {
            datalen = false;
          }

          const CBVPnL = res.data.CBVPnL;
          let CBVPnLT = [];
          let CBVPnLV = [];
          CBVPnL.forEach((element) => {
            CBVPnLT.push(element.Description);
            CBVPnLV.push(element.Total);
          });
          const CBVNav = res.data.CBVNav;
          let CBVNavT = [];
          let CBVNavV = [];
          CBVNav.forEach((element) => {
            CBVNavT.push(element.Description);
            CBVNavV.push(element.Total);
          });
          const gu = res.data.gu;
          let guT = [];
          let guV = [];
          let guBAT = [];
          let guNonBAT = [];
          gu.forEach((element) => {
            guT.push(element.Description);
            guV.push(element.Total);
            guBAT.push(element.BAAssignedYes);
            guNonBAT.push(element.BAAssignedNo);
          });
          const og = res.data.og;
          let ogT = [];
          let ogV = [];
          let ogBAV = [];
          let ogNonBAV = [];

          og.forEach((element) => {
            ogT.push(element.Description);
            ogV.push(element.Total);
            ogBAV.push(element.BAAssignedYes);
            ogNonBAV.push(element.BAAssignedNo);
          });
          const sdo = res.data.sdoMetric;
          let sdoT = [];
          let sdoV = [];
          let sdoClientV = [];
          let sdoProjectV = [];

          sdo.forEach((element) => {
            sdoT.push(element.Description);
            sdoV.push(element.Total);
            sdoProjectV.push(element.BAAssignedYes);
            sdoClientV.push(element.BAAssignedNo);
          });
          const offering = res.data.offering;
          let offeringT = [];
          let offeringV = [];

          offering.forEach((element) => {
            offeringT.push(element.Description);
            offeringV.push(element.Total);
          });
          const boi = res.data.boi;
          let boiT = [];
          let boiV = [];

          boi.forEach((element) => {
            boiT.push(element.Description);
            boiV.push(element.Total);
          });
          const industry = res.data.industry;
          let industryT = [];
          let industryV = [];

          industry.forEach((element) => {
            industryT.push(element.Description);
            industryV.push(element.Total);
          });
          const client = res.data.client;
          let clientT = [];
          let clientV = [];

          client.forEach((element) => {
            clientT.push(element.Description);
            clientV.push(element.Total);
          });
          const yoy = res.data.yoy;
          let yoyT = [];
          let yoyBAT = [];
          let yoyNonBAT = [];
          yoy.forEach((element) => {
            yoyT.push(element.Description);
            yoyNonBAT.push(element.BAAssignedNo);
            yoyBAT.push(element.BAAssignedYes);
          });
          const initiativeType = res.data.initiativeType;
          let initiativeTypeT = [];
          let initiativeTypeNonBAT = [];
          let initiativeTypeBAT = [];

          initiativeType.forEach((element) => {
            initiativeTypeT.push(element.Description);
            initiativeTypeNonBAT.push(element.BAAssignedNo);
            initiativeTypeBAT.push(element.BAAssignedYes);
          });
          const sloc = res.data.sloc;
          let slocT = [];
          let slocNonBAT = [];
          let slocBAT = [];

          sloc.forEach((element) => {
            slocT.push(element.Description);
            slocNonBAT.push(element.BAAssignedNo);
            slocBAT.push(element.BAAssignedYes);
          });
          this.setState({
            CBVPnL: {
              labels: CBVPnLT,
              datasets: [{ data: CBVPnLV, backgroundColor: ["#01BBEE", "#FE9B00", "#8E6635", "#548235", "#7030A0"] }],
              text: "",
            },
            CBVNav: {
              labels: CBVNavT,
              datasets: [{ data: CBVNavV, backgroundColor: ["#01BBEE", "#FE9B00", "#8E6635", "#548235", "#7030A0"] }],
              text: "",
            },
            gu: {
              labels: guT,
              datasets: [{ data: guV, backgroundColor: ["#C5B480", "#4CB3C6", "#8C6635", "#396e7c"] }],
              text: "",
            },
            og: {
              labels: ogT,
              datasets: [{ data: ogV, backgroundColor: ["#01BBEE", "#FE9B00", "#8E6635", "#548235", "#7030A0"] }],
              text: "",
            },
            ogBA: {
              labels: ogT,
              datasets: [{ data: ogBAV, backgroundColor: ["#01BBEE", "#FE9B00", "#8E6635", "#548235", "#7030A0"] }],
              text: "",
            },
            ogNonBA: {
              labels: ogT,
              datasets: [{ data: ogNonBAV, backgroundColor: ["#01BBEE", "#FE9B00", "#8E6635", "#548235", "#7030A0"] }],
              text: "",
            },
            sdo: {
              labels: sdoT,
              datasets: [{ data: sdoV, backgroundColor: "#A6A6A6", label: "SDO GU" }],
            },
            sdoClient: {
              labels: sdoT,
              datasets: [{ data: sdoClientV, backgroundColor: "rgba(17,17,17)", label: "SDO GU Client" }],
            },
            sdoProject: {
              labels: sdoT,
              datasets: [{ data: sdoProjectV, backgroundColor: "#5598C5", label: "SDO GU Project" }],
            },
            offerings: {
              labels: offeringT,
              datasets: [{ data: offeringV, backgroundColor: "#5598C5" }],
            },
            boi: {
              labels: boiT,
              datasets: [{ data: boiV, backgroundColor: "#5598C5" }],
            },
            industry: {
              labels: industryT,
              datasets: [{ data: industryV, backgroundColor: "#5598C5" }],
            },
            client: {
              labels: clientT,
              datasets: [{ data: clientV, backgroundColor: "#5598C5" }],
            },
            yoy: {
              labels: yoyT,
              datasets: [
                { data: yoyBAT, backgroundColor: "#5598C5", label: "BA Assigned" },
                { data: yoyNonBAT, backgroundColor: "#FE9E48", label: "BA Not Assigned" },
              ],
            },
            initiativeType: {
              labels: initiativeTypeT,
              datasets: [
                { data: initiativeTypeBAT, backgroundColor: "#5598C5", label: "BA Assigned" },
                { data: initiativeTypeNonBAT, backgroundColor: "#FE9E48", label: "BA Not Assigned" },
              ],
            },
            location: {
              labels: slocT,
              datasets: [
                { data: slocBAT, backgroundColor: "#5598C5", label: "BA Assigned" },
                { data: slocNonBAT, backgroundColor: "#FE9E48", label: "BA Not Assigned" },
              ],
            },
            gufinal: {
              labels: guT,
              datasets: [
                { data: guBAT, backgroundColor: "#5598C5", label: "BA Assigned" },
                { data: guNonBAT, backgroundColor: "#FE9E48", label: "BA Not Assigned" },
              ],
            },
            datalen,
          });
        });
    }
  } */

 

  render() {
    return <ValueRealization filterDetails={this.props.filterDetails} />;
  }
}
