

import React, { Component } from 'react';
import i18n from "i18next";
import { LocalApiBaseUrl, getMonthNumber, getPrevMonth, GOCJsonData } from '../Shared/Constant';
import axios from 'axios';
import { Doughnut, Pie, HorizontalBar, Bar } from 'react-chartjs-2';
import Chart from 'chart.js';
import 'chartjs-plugin-datalabels'
export default class SOChartSections extends Component {
    constructor(props) {
        super();
        this.state = {
            dsABVS: [],
            dsProjects: [],
            dsClients: []
        }

    }
    componentDidMount() {
        // const ABVS = GOCJsonData;
        debugger
        var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
        Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
            draw: function () {
                originalDoughnutDraw.apply(this, arguments);

                var chart = this.chart.chart;
                var ctx = chart.ctx;
                var width = chart.width;
                var height = chart.height;

                var fontSize = (height / 250).toFixed(2);
                ctx.font = fontSize + "em Verdana";
                ctx.textBaseline = "middle";

                var text = chart.config.data.text,
                    //  var text = Math.round(chart.config.data.text),
                    textX = Math.round((width - ctx.measureText(text).width) / 2),
                    textY = height / 2;

                ctx.fillText(text, textX, textY);
                ctx.fillStyle = "#000"
            }
        });

        var ABVS = [];
        ABVS = this.props.propsGOCData;
        let ABVSLabel = [];
        let ABVSTotal = [];
        let ABVSProjects = [];
        let ABVSClients = [];
        // ABVS.GOCDashboard.forEach(element => {
        //     ABVSLabel.push(element.Description);
        //     ABVSTotal.push(element.Total);
        //     ABVSProjects.push(element.Projects);
        //     ABVSClients.push(element.Clients);
        // });

        // var GOCJsonDataArr = GOCJsonData;
        var GOCJsonDataArr = this.props.propsGOCData;
        const CBVPnL = GOCJsonDataArr.CBVPnL;

        let CBVPnLT = [];
        let CBVPnLV = [];
        CBVPnL.forEach(element => {
            CBVPnLT.push(element.Description);
            CBVPnLV.push(element.Total);
        });
        const CBVNav = GOCJsonDataArr.CBVNav;
        let CBVNavT = [];
        let CBVNavV = [];
        CBVNav.forEach(element => {
            CBVNavT.push(element.Description);
            CBVNavV.push(element.Total);
        });
        const gu = GOCJsonDataArr.GUData;
        let guT = [];
        let guV = [];
        let guBAT = [];
        let guNonBAT = [];
        gu.forEach(element => {
            guT.push(element.Description);
            guV.push(element.Total);
            guBAT.push(element.BAAssignedYes);
            guNonBAT.push(element.BAAssignedNo);


        });
        const og = GOCJsonDataArr.OGData;
        let ogT = [];
        let ogV = [];
        let ogBAV = [];
        let ogNonBAV = [];

        og.forEach(element => {
            ogT.push(element.Description);
            ogV.push(element.Total);
            ogBAV.push(element.BAAssignedYes);
            ogNonBAV.push(element.BAAssignedNo);
        });
        const yoy = GOCJsonDataArr.YoYData;
        let yoyT = [];
        let yoyBAT = [];
        let yoyNonBAT = [];
        yoy.forEach(element => {
            yoyT.push(element.Description);
            yoyNonBAT.push(element.BAAssignedNo);
            yoyBAT.push(element.BAAssignedYes);
        });
        const offering = GOCJsonDataArr.OfferingData;
        let offeringT = [];
        let offeringV = [];

        offering.forEach(element => {
            offeringT.push(element.Description);
            offeringV.push(element.Total);
        });
        const initiativeType = GOCJsonDataArr.InitiativeTypeData;
        let initiativeTypeT = [];
        let initiativeTypeNonBAT = [];
        let initiativeTypeBAT = [];

        initiativeType.forEach(element => {
            initiativeTypeT.push(element.Description);
            initiativeTypeNonBAT.push(element.BAAssignedNo);
            initiativeTypeBAT.push(element.BAAssignedYes);
        });


        this.setState({
            CBVPnL: {
                labels: CBVPnLT,
                datasets: [{ data: CBVPnLV, backgroundColor: ["#01BBEE", "#FE9B00", "#8E6635", "#548235", "#7030A0"] }],
                text: Math.round(CBVPnLV.reduce((a, b) => a + b) * 100) / 100,

            },

            CBVNav: {
                labels: CBVNavT,
                datasets: [{ data: CBVNavV, backgroundColor: ["#01BBEE", "#FE9B00", "#8E6635", "#548235", "#7030A0"] }],
                text: Math.round(CBVNavV.reduce((a, b) => a + b) * 100) / 100,
            },
            gu: {
                labels: guT,
                datasets: [{ data: guV, backgroundColor: ["#C5B480", "#4CB3C6", "#8C6635", "#396e7c"] }],
                text: " "
            },
            og: {
                labels: ogT,
                datasets: [{ data: ogV, backgroundColor: ["#01BBEE", "#FE9B00", "#8E6635", "#548235", "#7030A0"] }],

                text: Math.round(ogV.reduce((a, b) => a + b) * 100) / 100,
            },
            ogBA: {
                labels: ogT,
                datasets: [{ data: ogBAV, backgroundColor: ["#01BBEE", "#FE9B00", "#8E6635", "#548235", "#7030A0"] }],

                text: Math.round(ogBAV.reduce((a, b) => a + b) * 100) / 100,
            },
            ogNonBA: {
                labels: ogT,
                datasets: [{ data: ogNonBAV, backgroundColor: ["#01BBEE", "#FE9B00", "#8E6635", "#548235", "#7030A0"] }],

                text: Math.round(ogNonBAV.reduce((a, b) => a + b) * 100) / 100,
            },
            yoy: {
                labels: yoyT,
                datasets: [{ data: yoyBAT, backgroundColor: '#5598C5', label: "BA Assigned" }, { data: yoyNonBAT, backgroundColor: '#FE9E48', label: "BA Not Assigned", hidden: true }]
            },
            offerings: {
                labels: offeringT,
                datasets: [{ data: offeringV, backgroundColor: '#5598C5' }]
            },
            initiativeType: {
                labels: initiativeTypeT,
                datasets: [{ data: initiativeTypeBAT, backgroundColor: '#5598C5', label: "BA Assigned" }, { data: initiativeTypeNonBAT, backgroundColor: '#FE9E48', label: "BA Not Assigned", hidden: true }]
            },

            dsABVS: {
                labels: ABVSLabel,
                datasets: [{ data: ABVSTotal, backgroundColor: '#7ABDD5', label: "Annualized Business Value Signoff - $Mn" }]
            },
            dsProjects: {
                labels: ABVSLabel,
                datasets: [{ data: ABVSProjects, backgroundColor: '#407688', label: "No of Projects" }]
            },
            dsClients: {
                labels: ABVSLabel,
                datasets: [{ data: ABVSClients, backgroundColor: '#0F2E3B', label: "No of Clients" }]
            },
        })

    }


    render() {
        return (
            <div className=" outcome-highlight graph-highlight">
                {/* <h2>Business Outcome Delivered - Details</h2>
                <div className="outcome-graph">
                    <h4>Annualized Business Value Signoff - $Mn</h4>
                    <HorizontalBar data={this.state.dsABVS} options={{ maintainAspectRatio: true, responsive: true, legend: { display: false, position: 'bottom', labels: { boxWidth: 20 } }, scales: { xAxes: [{ display: false, }], yAxes: [{ display: true, gridLines: { display: false } }] }, plugins: { datalabels: { display: true, align: "end", anchor: "end" } } }} />
                </div>
                <div className="outcome-graph">
                    <h4>No of Projects</h4>
                    <HorizontalBar data={this.state.dsProjects} options={{ maintainAspectRatio: true, responsive: true, legend: { display: false, position: 'bottom', labels: { boxWidth: 20 } }, scales: { xAxes: [{ display: false, }], yAxes: [{ display: true, gridLines: { display: false } }] }, plugins: { datalabels: { display: true, align: "end", anchor: "end" } } }} />

                </div>
                <div className="outcome-graph">
                    <h4>No of Clients</h4>
                    <HorizontalBar data={this.state.dsClients} options={{ maintainAspectRatio: true, responsive: true, legend: { display: false, position: 'bottom', labels: { boxWidth: 20 } }, scales: { xAxes: [{ display: false, }], yAxes: [{ display: true, gridLines: { display: false } }] }, plugins: { datalabels: { display: true, align: "end", anchor: "end" } } }} />
                </div> */}

                {this.props.propsChartShow == 1 &&
                    <div className="insight-graph-list row">
                        <div className="insight-graph col-lg-2"></div>
                        <div className="insight-graph col-lg-8">
                            <h4> <span>{i18n.t("Business_Outcomes_P_and_L")}</span></h4>

                            <div className="bar-graph"><Bar
                                data={this.state.CBVPnL}
                                redraw="true"
                                type='bar'
                                options={{
                                    maintainAspectRatio: true, responsive: true, legend: {
                                        display: false
                                    },
                                    scales: {
                                        xAxes: [{
                                            stacked: true,
                                            barThickness: 25,

                                            display: true,
                                            gridLines: { display: false }

                                        }],
                                        yAxes: [{
                                            // position: "left",
                                            type: "linear",
                                            ticks: {
                                                suggestedMin: 0
                                            },
                                            display: true,
                                            gridLines: { display: false }
                                        }]
                                    }, plugins: {
                                        datalabels: {
                                            display: true,
                                            align: "end",
                                            anchor: "end"
                                        }
                                    }
                                }} />
                            </div>
                        </div>
                        <div className="insight-graph col-lg-2"></div>
                    </div>
                }
                {this.props.propsChartShow == 2 &&
                    <div className="insight-graph-list row">
                        <div className="insight-graph col-lg-2"></div>
                        <div className="insight-graph col-lg-8">
                            <h4> <span>{i18n.t("Business_Value_NAV")}</span></h4>

                            <div className="bar-graph"><Bar
                                data={this.state.CBVNav}
                                redraw="true"
                                type='bar'
                                options={{
                                    maintainAspectRatio: true, responsive: true, legend: {
                                        display: false
                                    },
                                    scales: {
                                        xAxes: [{
                                            stacked: true,
                                            barThickness: 25,

                                            display: true,
                                            gridLines: { display: false }

                                        }],
                                        yAxes: [{
                                            // position: "left",
                                            type: "linear",
                                            ticks: {
                                                suggestedMin: 0
                                            },
                                            display: true,
                                            gridLines: { display: false }
                                        }]
                                    }, plugins: {
                                        datalabels: {
                                            display: true,
                                            align: "end",
                                            anchor: "end"
                                        }
                                    }
                                }} />
                            </div>                        </div>
                        <div className="insight-graph col-lg-2"></div>
                    </div>
                }
                {this.props.propsChartShow == 3 &&

                    <div className="insight-graph-list row">
                        <div className="insight-graph col-lg-2"></div>
                        <div className="insight-graph col-lg-8">
                            <h4> <span>{i18n.t("Business_Outcomes_by_SDO")}</span></h4>

                            {/* <Pie data={this.state.gu} options={{ maintainAspectRatio: true, responsive: true, legend: { display: true, position: 'bottom', labels: { boxWidth: 20 } } }} /> */}

                            <Bar
                                data={this.state.gu}
                                redraw="true"
                                type='bar'
                                options={{
                                    maintainAspectRatio: true, responsive: true,
                                    legend: { display: false, position: 'bottom', labels: { boxWidth: 20 } },
                                    scales: {
                                        xAxes: [{
                                            stacked: true,
                                            barThickness: 25,

                                            display: true,
                                            gridLines: { display: false }

                                        }],
                                        yAxes: [{
                                            // position: "left",
                                            type: "linear",
                                            ticks: {
                                                suggestedMin: 0
                                            },
                                            display: true,
                                            gridLines: { display: false }
                                        }]
                                    }, plugins: {
                                        datalabels: {
                                            display: true,
                                            align: "end",
                                            anchor: "end"
                                        }
                                    }
                                }} />




                        </div>
                        <div className="insight-graph col-lg-2"></div>
                    </div>
                }
                {this.props.propsChartShow == 4 &&

                    <div className="insight-graph-list row">
                        <div className="insight-graph col-lg-2"></div>
                        <div className="insight-graph col-lg-8">
                            <h4> <span>{i18n.t("OG_P&L_$Mn")}</span></h4>

                            <div className="bar-graph">
                                <Bar
                                    data={this.state.og}
                                    redraw="true"
                                    type='bar'
                                    options={{
                                        maintainAspectRatio: true, responsive: true, legend: {
                                            display: false
                                        },
                                        scales: {
                                            xAxes: [{
                                                stacked: true,
                                                barThickness: 25,

                                                display: true,
                                                gridLines: { display: false }

                                            }],
                                            yAxes: [{
                                                // position: "left",
                                                type: "linear",
                                                ticks: {
                                                    suggestedMin: 0
                                                },
                                                display: true,
                                                gridLines: { display: false }
                                            }]
                                        }, plugins: {
                                            datalabels: {
                                                display: true,
                                                align: "end",
                                                anchor: "end"
                                            }
                                        }
                                    }} />
                            </div>                        </div>
                        <div className="insight-graph col-lg-2"></div>
                    </div>
                }
                {this.props.propsChartShow == 5 &&

                    <div className="insight-graph-list row">
                        <div className="insight-graph col-lg-2"></div>
                        <div className="insight-graph col-lg-8">
                            <h4> <span>{i18n.t("BA_P_and_L")}</span></h4>

                            <div className="bar-graph"><Bar
                                data={this.state.ogBA}
                                redraw="true"
                                type='bar'
                                options={{
                                    maintainAspectRatio: true, responsive: true, legend: {
                                        display: false
                                    },
                                    scales: {
                                        xAxes: [{
                                            stacked: true,
                                            barThickness: 25,

                                            display: true,
                                            gridLines: { display: false }

                                        }],
                                        yAxes: [{
                                            // position: "left",
                                            type: "linear",
                                            ticks: {
                                                suggestedMin: 0
                                            },
                                            display: true,
                                            gridLines: { display: false }
                                        }]
                                    }, plugins: {
                                        datalabels: {
                                            display: true,
                                            align: "end",
                                            anchor: "end"
                                        }
                                    }
                                }} />
                            </div>                        </div>
                        <div className="insight-graph col-lg-2"></div>

                    </div>
                }
                {this.props.propsChartShow == 6 &&
                    <div className="insight-graph-list row">
                        <div className="insight-graph col-lg-2"></div>
                        <div className="insight-graph col-lg-8">
                            <h4> <span>{i18n.t("Non_BA_P_and_L")}</span></h4>

                            <div className="bar-graph"><Bar
                                data={this.state.ogNonBA}
                                redraw="true"
                                type='bar'
                                options={{
                                    maintainAspectRatio: true, responsive: true, legend: {
                                        display: false
                                    },
                                    scales: {
                                        xAxes: [{
                                            stacked: true,
                                            barThickness: 25,

                                            display: true,
                                            gridLines: { display: false }

                                        }],
                                        yAxes: [{
                                            // position: "left",
                                            type: "linear",
                                            ticks: {
                                                suggestedMin: 0
                                            },
                                            display: true,
                                            gridLines: { display: false }
                                        }]
                                    }, plugins: {
                                        datalabels: {
                                            display: true,
                                            align: "end",
                                            anchor: "end"
                                        }
                                    }
                                }} />
                            </div>                        </div>
                        <div className="insight-graph col-lg-2"></div>
                    </div>
                }
                {this.props.propsChartShow == 7 &&

                    <div className="insight-graph-list row">
                        <div className="insight-graph col-lg-2"></div>
                        <div className="insight-graph col-lg-8">
                            <h4> <span>{i18n.t("Offering-P&L_$_Mn")}</span></h4>

                            <div className="bar-graph"><HorizontalBar
                                data={this.state.offerings}
                                options={{
                                    maintainAspectRatio: true, legend: {
                                        display: false
                                    },
                                    scales: {
                                        xAxes: [{
                                            display: false,
                                        }],
                                        yAxes: [{
                                            display: true,
                                            gridLines: { display: false }
                                        }]
                                    }, plugins: {
                                        datalabels: {
                                            display: true,
                                            align: "end",
                                            anchor: "end"
                                        }
                                    }
                                }} />
                            </div>
                            <div className="insight-graph col-lg-2"></div>
                        </div>

                    </div>
                }
                {this.props.propsChartShow == 8 &&

                    <div className="insight-graph-list row">
                        <div className="insight-graph col-lg-2"></div>
                        <div className="insight-graph col-lg-8">
                            <h4> <span>{i18n.t("YoY_Trend_P_and_L")}</span></h4>

                            <div className="bar-graph"><HorizontalBar
                                data={this.state.yoy}
                                options={{
                                    maintainAspectRatio: true, showLines: false, legend: {
                                        position: "bottom"
                                    }, labels: { position: "outside" },
                                    scales: {
                                        xAxes: [{
                                            stacked: true,
                                            display: false,
                                        }],
                                        yAxes: [{
                                            stacked: true,
                                            display: true,
                                            gridLines: { display: false }
                                        }]
                                    }, plugins: {
                                        datalabels: {
                                            display: true,
                                            align: "end",
                                            anchor: "end"
                                        }
                                    }
                                }} />
                            </div>
                        </div>
                        <div className="insight-graph col-lg-2"></div>
                    </div>
                }
                {this.props.propsChartShow == 9 &&

                    <div className="insight-graph-list row">
                        <div className="insight-graph col-lg-2"></div>
                        <div className="insight-graph col-lg-8">
                            <h4> <span>{i18n.t("Intelligent_Operations_P_and_L")}</span></h4>

                            <div className="bar-graph"><HorizontalBar
                                data={this.state.initiativeType}
                                options={{
                                    maintainAspectRatio: true, showLines: false, legend: {
                                        position: "bottom"
                                    }, labels: { position: "outside" },
                                    scales: {
                                        xAxes: [{
                                            stacked: true,
                                            display: false,
                                        }],
                                        yAxes: [{
                                            stacked: true,
                                            display: true,
                                            gridLines: { display: false }
                                        }]
                                    }, plugins: {
                                        datalabels: {
                                            display: true,
                                            align: "end",
                                            anchor: "end"
                                        }
                                    }
                                }} />
                            </div>
                        </div>
                        <div className="insight-graph col-lg-2"></div>
                    </div>
                }

            </div >

        )
    }
}
