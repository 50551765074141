import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import { months } from 'moment';
import DayWiseGraph from './DayWiseGraph';
import "react-datepicker/dist/react-datepicker.css";
import { LocalApiBaseUrl, getMonthNumber, getPrevMonth } from '../Shared/Constant';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;



export default class DayWiseTrends extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dsKpiData: [],
            errors: [],
            shown: false,
            formIsValid: false,
            dayWiseChart:{
                StartMonth: new months()
            },


            downloadUrl: null,
            formIsValid: false,

            viewmore: false,
            process: "", Client: "", MaturityPhase: this.props.MaturityPhase, processOptions: [],

            Report: {
                StartDate:"",
            }
        }

        this.validateForm = this.validateForm.bind(this);
        this.GetDayData = this.GetDayData.bind(this);
    }



    componentDidMount() {
        

    }

    onButtonClick() {
        
        if (this.validateForm()) {
            this.GetDayData();
        }
    }


    onStartDateChange = (event) => {
        
        let errors = {};
        this.setState({
            errors: errors
        });
        const Report = this.state.Report;
        Report.StartDate = event;

        this.setState({ StartDate: event });
        this.setState({
            Report: Report
        })

    }

    GetDayData() {
        
        this.state.shown=true;
        //let date = '06/2019';
        var date = this.state.Report.StartDate;
        axiosInstance.get(`${LocalApiBaseUrl}TrendReports/GetDayWiseVisitor?`, {
            params: { date: date }
        })
            .then(res => {
               
                let UserIDLoginCount = [];
                let DatePeriod = [];
                res.data.forEach(element => {
                    UserIDLoginCount.push(element.UserIDLoginCount);
                    DatePeriod.push(element.TimePeriod);
                });
                this.setState({
                    dsKpiData: {
                        labels: DatePeriod,
                        datasets: [{
                        data: UserIDLoginCount, fill: false, lineTension: 0.1,
                        animationEnabled: true,
                            backgroundColor: 'rgba(75,192,192,0.4)',
                            borderColor: 'rgba(75,192,192,1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(75,192,192,1)',
                            pointBackgroundColor: '#fff',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                            pointHoverBorderColor: 'rgba(220,220,220,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            theme: "light2",
                            vline: "true",
                        }]
                    },
                });
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
      
    }

    validateForm() {
        
        let Report = this.state.Report;
        let errors = {};
        let formIsValid = true;

        if (!Report["StartDate"]) {
            formIsValid = false;

        }

        this.setState({
            errors: errors
        });

        if (formIsValid) {
            this.setState({
                formIsValid: true
            })
        }
        else {
            this.setState({
                formIsValid: false
            })
        }
        return formIsValid;

    }

  


    render() {

       
        return (
            <>
            <div className="main-data">
            <div className="row">
                <div className="form-group col-lg-4 block-picker">
                    <label className="datepicker-label" htmlFor="">Date<span className="required"></span></label>                    
                    <DatePicker className="form-control" selected={this.state.StartDate} autoComplete="off" id="StartDate" onChange={this.onStartDateChange.bind(this)} value={this.state.Report.StartDate} />
                </div>
            </div>
            <div className="row">
                <div className="form-group col">
                    <button className="btn btn-primary btn-sm" id="btn2" onClick={() => this.onButtonClick()}>Show Trends</button>
                </div>
            </div>
        </div>
        {this.state.shown ? <DayWiseGraph shown={this.state.shown} dsKpiData={this.state.dsKpiData}/> : null}
</>
        );
    }
}

