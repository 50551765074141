import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import LoadingOverlay from 'react-loading-overlay';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup, LocalApiBaseUrl } from '../Shared/Constant';

import { store } from '../reducers/configureStore';
import MindMapList from './MindMapList';
import { InitialiseState, MindmapClick, SaveClick } from '../actions/mindMapAction';
import SynopsLoader from '../Shared/SynopsLoader';

class MindMap extends Component {
  constructor() {
    super();
    this.state = {
      configureButton: true,
      DealID: null,
      common: [],
      isApiLoading: false,
      isEnabledSaveButton: false,
    };
  }

  componentDidMount() {
    this.getDealInfo();
  }

  componentDidUpdate(prevProps) {
    const { MindMapData, propsFromParentScreen, MindMapClick } = this.props;

    const clonedPrevPropsFromParentScreen = _.omit(
      _.cloneDeep(prevProps.propsFromParentScreen),
      ['callbackWhenLoadComplete', 'onCallbackAfterSaveMindMap', 'onCallbackEnableDisableSaveMindMapButton'],
    );
    const clonedPropsFromParentScreen = _.omit(
      _.cloneDeep(propsFromParentScreen),
      ['callbackWhenLoadComplete', 'onCallbackAfterSaveMindMap', 'onCallbackEnableDisableSaveMindMapButton'],
    );

    if (MindMapClick) {
      this.handleCancel();
    }

    if (prevProps.MindMapData !== MindMapData) {
      const newData = MindMapData;
      let flag = true;
      let tempValue = false;
      newData.forEach((item) => {
        if (item.addedData.length > 0 || item.updatedData.length > 0 || item.deletedData.length > 0) {
          tempValue = true;
        }
      });
      const temp = MindMapData.filter((item) => item.listData.length === 0);
      if (temp.length > 0 || !tempValue) {
        flag = false;
      }

      this.setState({
        isEnabledSaveButton: flag,
      }, () => {
        if (!_.isEmpty(propsFromParentScreen)) {
          propsFromParentScreen.onCallbackEnableDisableSaveMindMapButton(flag);
        }
      });
    }

    // COMPARE THE PREV PROPS FROM PARENT SCREEN TO THE CURRENT PROPS AND PERFORM SOME ACTIONS ACCORDINGLY
    if (!_.isEqual(clonedPrevPropsFromParentScreen, clonedPropsFromParentScreen)
      && !_.isEmpty(clonedPropsFromParentScreen)
    ) {
      const {
        orgEntityId: prevOrgEntityId,
        offeringId: prevOfferingId,
        locationId: prevLocationId,
        month: prevMonth,
        kpiMappingId: prevKpiMappingId,
        kpiName: prevKpiName,
        isInEditMode: prevIsInEditMode,
        doSave: prevDoSave,
      } = clonedPrevPropsFromParentScreen;

      const {
        orgEntityId,
        offeringId,
        locationId,
        month,
        kpiMappingId,
        kpiName,
        isInEditMode,
        doSave,
      } = clonedPropsFromParentScreen;

      if (prevIsInEditMode !== isInEditMode) {
        if (isInEditMode) {
          this.configureClick();
        } else if (!isInEditMode && !doSave) {
          this.handleCancel();
        }
      }

      if (prevDoSave !== doSave && doSave) {
        this.handleSave();
      }

      if (
        prevOrgEntityId !== orgEntityId
        || prevOfferingId !== offeringId
        || prevLocationId !== locationId
        || prevMonth !== month
        || prevKpiMappingId !== kpiMappingId
        || prevKpiName !== kpiName
      ) {
        this.getDealInfo();
      }
    }
  }

  hasEditAccess = () => {
    const { roleData } = this.props;
    const foundPermissionObj = _.find(roleData, { FeatureName: 'Configurator' });

    let hasAccessPermission = false;

    // IF THE USER WOULD HAVE ANY OF THE PERMISSION FOR 'Configurator' THEN THE USER WILL BE ABLE TO EDIT THE MINDMAP
    if (!_.isEmpty(foundPermissionObj)) {
      hasAccessPermission = true;
    }
    return hasAccessPermission;
  };

  getMindMapData = () => {
    const { CurrentFilter, propsFromParentScreen } = this.props;
    let { kpiMappingId } = this.props;

    let orgEntityId = CurrentFilter.ClientData.id;
    let offeringId = CurrentFilter.SyncFilter.ProcessId;
    let locationId = CurrentFilter.SyncFilter.LocationId;
    let month = CurrentFilter.SyncFilter.Month;

    if (!_.isEmpty(propsFromParentScreen)) {
      orgEntityId = propsFromParentScreen.orgEntityId;
      offeringId = propsFromParentScreen.offeringId;
      locationId = propsFromParentScreen.locationId;
      month = propsFromParentScreen.month;
      kpiMappingId = propsFromParentScreen.kpiMappingId;
    }

    this.setState({
      isApiLoading: true,
    });
    axiosInstance
      .get(`${LocalApiBaseUrl}Analytics/GetMindMapData`, {
        params: {
          OrgEntityID: orgEntityId,
          OfferingId: offeringId,
          LocationId: locationId,
          PeriodDT: month,
          KpimappingId: kpiMappingId,
        },
      })
      .then((response) => {
        if (response.data) {
          const { data } = response;
          const tempData = [];
          tempData.push({
            name: data.SLAName,
            listData: data.SLALD,
            dropdownData: data.SLADD,
            addedData: [],
            updatedData: [],
            deletedData: [],
            sequence: 0,
            active: false,
            addButtonEnable: true,
            saveName: 'saveSLAs',
          });
          tempData.push({
            name: data.IOJName,
            listData: data.IOJLD,
            dropdownData: data.IOJDD,
            addedData: [],
            updatedData: [],
            deletedData: [],
            sequence: 1,
            active: false,
            addButtonEnable: true,
            saveName: 'saveJIs',
          });
          tempData.push({
            name: data.WOName,
            listData: data.WOLD,
            dropdownData: data.WODD,
            addedData: [],
            updatedData: [],
            deletedData: [],
            sequence: 2,
            active: false,
            addButtonEnable: true,
            saveName: 'saveWOs',
          });
          tempData.push({
            name: data.AssetName,
            listData: data.AssetLD,
            dropdownData: data.AssetDD,
            addedData: [],
            updatedData: [],
            deletedData: [],
            sequence: 3,
            active: false,
            addButtonEnable: true,
            saveName: 'saveAssets',
          });
          tempData.push({
            name: data.HMName,
            listData: data.HMLD,
            dropdownData: data.HMDD,
            addedData: [],
            updatedData: [],
            deletedData: [],
            sequence: 4,
            active: false,
            addButtonEnable: true,
            saveName: 'saveHMs',
          });
          const newObj = _.sortBy(tempData, 'sequence');
          this.setState({
            common: newObj,
            isApiLoading: false,
          }, () => {
            if (!_.isEmpty(propsFromParentScreen)) {
              propsFromParentScreen.callbackWhenLoadComplete(true);
            }
            const { common } = this.state;
            store.dispatch(InitialiseState(common));
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        this.setState({
          isApiLoading: false,
        });
      });
  }

  activeDropdown = (sequence) => {
    const { MindMapData } = this.props;
    const tempItem = _.cloneDeep(MindMapData);
    tempItem.forEach((item, index) => {
      tempItem[index].active = false;
      tempItem[index].listData.forEach((a, i) => { tempItem[index].listData[i].temp = false; });
      tempItem[index].addButtonEnable = true;
    });
    tempItem[sequence].active = true;
    tempItem[sequence].addButtonEnable = false;
    store.dispatch(InitialiseState(tempItem));
    this.setState({
      // active: true,
    });
  }

  configureClick = () => {
    const { MindMapData } = this.props;
    const tempItem = _.cloneDeep(MindMapData);
    tempItem.forEach((a, index) => { tempItem[index].addButtonEnable = true; });
    store.dispatch(InitialiseState(tempItem));
    this.setState({
      configureButton: false,
    });
  }

  handleCancel = () => {
    this.getMindMapData();
    store.dispatch(MindmapClick(false));
    this.setState({
      configureButton: true,
    });
  }

  getDealInfo = () => {
    const { CurrentFilter, propsFromParentScreen } = this.props;

    let orgEntityId = CurrentFilter.ClientData.id;
    let offeringId = CurrentFilter.SyncFilter.ProcessId;
    let locationId = CurrentFilter.SyncFilter.LocationId;

    if (!_.isEmpty(propsFromParentScreen)) {
      orgEntityId = propsFromParentScreen.orgEntityId;
      offeringId = propsFromParentScreen.offeringId;
      locationId = propsFromParentScreen.locationId;
    }

    this.setState({
      isApiLoading: true,
    });
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetDealInfo`, {
        params: {
          OrgEntityID: orgEntityId,
          OfferingId: offeringId,
          LocationId: locationId,
          LanguageID: CurrentFilter.languageData.id,
        },
      })
      .then((response) => {
        this.setState({
          DealID: response.data[0].DealID,
          isApiLoading: false,
        }, () => {
          if (!_.isEmpty(propsFromParentScreen)) {
            this.handleCancel();
          }
        });
      })
      .catch((error) => {
        this.setState({
          isApiLoading: false,
        });
        trycatchAlertPopup(error);
      });
  }

  handleSave = () => {
    const {
      CurrentFilter, MindMapData, propsFromParentScreen, SaveClick: SaveClickFromProps,
    } = this.props;
    const { DealID } = this.state;
    let { kpiMappingId } = this.props;
    let month = CurrentFilter.SyncFilter.Month;

    if (!_.isEmpty(propsFromParentScreen)) {
      month = propsFromParentScreen.month;
      kpiMappingId = propsFromParentScreen.kpiMappingId;
    }

    const tempData = {};
    const newData = _.cloneDeep(MindMapData);
    let checkOpenNode = false;
    newData.forEach((item) => {
      if (item.active) {
        checkOpenNode = true;
      }
      item.listData.forEach((a) => {
        if (a.temp) {
          checkOpenNode = true;
        }
      });
    });
    if (checkOpenNode || SaveClickFromProps) {
      store.dispatch(SaveClick(true));
    }
    if (!checkOpenNode && !SaveClickFromProps) {
      newData.forEach((item) => {
        const newObj = {};
        newObj.name = item.name;
        newObj.addedData = item.addedData;
        newObj.updatedData = item.updatedData;
        newObj.deletedData = item.deletedData;
        tempData[item.saveName] = newObj;
      });
      this.setState({
        isApiLoading: true,
      });
      axiosInstance.post(`${LocalApiBaseUrl}Analytics/SaveMindMap`, tempData, {
        params: {
          KPIMappingId: kpiMappingId,
          PeriodDT: month,
          DealId: DealID,
        },
      })
        .then(() => {
          window.alert('Mind Map saved successfully');
          const tempItem = _.cloneDeep(MindMapData);
          tempItem.forEach((item, index) => {
            tempItem[index].addedData.length = 0;
            tempItem[index].updatedData.length = 0;
            tempItem[index].deletedData.length = 0;
            tempItem[index].active = false;
            tempItem[index].listData.forEach((a, i) => { tempItem[index].listData[i].temp = false; });
          });
          store.dispatch(InitialiseState(tempItem));
          this.setState({
            configureButton: true,
            isApiLoading: false,
          });
          store.dispatch(SaveClick(false));

          if (!_.isEmpty(propsFromParentScreen)) {
            propsFromParentScreen.onCallbackAfterSaveMindMap(true);
          }
        });
    } else {
      // eslint-disable-next-line no-lonely-if
      if (!_.isEmpty(propsFromParentScreen)) {
        propsFromParentScreen.onCallbackAfterSaveMindMap(false);
      }
    }
  }

  handleNode = () => {
    const { MindMapData } = this.props;
    const temp = MindMapData.filter((item) => item.listData.length === 0);
    if (temp.length > 0) {
      return false;
    }

    return true;
  }

  render() {
    const { MindMapData, propsFromParentScreen } = this.props;
    let { kpiName } = this.props;
    const { isEnabledSaveButton, configureButton, isApiLoading } = this.state;

    let renderedFromParentScreen = '';
    let hasAccessForEdit = this.hasEditAccess();
    let isVisibleEditButton = hasAccessForEdit && configureButton;
    let isVisibleCancelAndSaveButtons = hasAccessForEdit && !configureButton;
    let isVisibleHeaderSection = true;
    if (!_.isEmpty(propsFromParentScreen)) {
      kpiName = propsFromParentScreen.kpiName;
      hasAccessForEdit = propsFromParentScreen.userHasEditAccess;
      renderedFromParentScreen = propsFromParentScreen.screenName;
      isVisibleCancelAndSaveButtons = renderedFromParentScreen !== 'clientStory';
      isVisibleEditButton = renderedFromParentScreen !== 'clientStory';
      isVisibleHeaderSection = false;
    }

    let newKPI = '';
    if (kpiName.length >= 25) {
      newKPI = kpiName.substring(0, 22);
      newKPI = `${newKPI}...`;
    } else {
      newKPI = kpiName;
    }

    return (
      <>
        {isApiLoading && (
        <LoadingOverlay
          fadeSpeed={0}
          spinner={<SynopsLoader />}
          active
        />
        )}
        {
        MindMapData.length > 0
          ? (
            <>
              <div className="mindmap-section">

                {isVisibleHeaderSection && (
                  <div className="mindmap-header mb-0">
                    <h4>Mind Map</h4>
                    {isVisibleEditButton && hasAccessForEdit && (
                      <div className="edit-mindmap">
                        <button type="button" className="btn btn-primary" onClick={this.configureClick}>
                          <i className="far fa-edit mr-1" />
                          {' '}
                          Edit Mind Map
                        </button>
                      </div>
                    )}

                    {isVisibleCancelAndSaveButtons && hasAccessForEdit && (
                      <div className="save-mindmap">
                        <button type="button" className="btn btn-default mr-2" onClick={this.handleCancel}>Cancel</button>
                        {isEnabledSaveButton ? (
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.handleSave}
                          >
                            Save
                          </button>
                        ) : (
                          <button type="button" className="btn btn-primary" disabled>Save</button>
                        )}
                      </div>
                    )}
                  </div>
                )}

                <div className="mindmap-parentsection">
                  {
              !configureButton && !this.handleNode()
              && <span className="note-msg">Please select atleast one branch for each sub-topic to save Mind Map</span>
            }
                  <div className="mindmap-btnsection">
                    <button className="btn btn-gradient-common" data-tip data-for={`kpiName${kpiName}`} type="button">{newKPI}</button>
                  </div>
                  {
              kpiName.length >= 25
              && (
              <ReactTooltip
                place="bottom"
                type="dark"
                effect="solid"
                id={`kpiName${kpiName}`}
                className="kpi-name"
              >
                {kpiName}
              </ReactTooltip>
              )
            }
                  {
              MindMapData.length === 5
                ? (
                  <ul className="mindmap-links">
                    <li className="mindmap-linkname topleft-child">
                      <span className="mindmap-name">SLA/KPI/KPI+</span>
                      <div className="topleft-childlink mindmap-namelinks" />
                      {/* List for Sub level Mindmap links start */}
                      <MindMapList
                        arr1={MindMapData[MindMapData.findIndex((item) => item.name === 'SLA/KPI/KPI+')]}
                        configure={configureButton}
                        handleSave={() => this.handleSave()}
                      />
                      {
                      MindMapData[MindMapData.findIndex((item) => item.name === 'SLA/KPI/KPI+')].listData.length === 0
                        && MindMapData[MindMapData.findIndex((item) => item.name === 'SLA/KPI/KPI+')].active === false && !configureButton
                        ? (
                          <div className="addmindmap-links">
                            <span><i className="fas fa-plus-circle" onClick={() => this.activeDropdown(MindMapData.findIndex((item) => item.name === 'SLA/KPI/KPI+'))} role="button" tabIndex="0" aria-label="Add Link" /></span>
                          </div>
                        ) : <></>
                    }
                      {/* List for Sub level Mindmap links end */}
                    </li>
                    <li className="mindmap-linkname topright-child">
                      <span className="mindmap-name">Journey Initiatives</span>
                      <div className="topright-childlink mindmap-namelinks" />
                      {/* List for Sub level Mindmap links start */}
                      <MindMapList
                        arr1={MindMapData[MindMapData.findIndex((item) => item.name === 'Journey Initiatives')]}
                        configure={configureButton}
                        handleSave={() => this.handleSave()}
                      />
                      {
                      MindMapData[MindMapData.findIndex((item) => item.name === 'Journey Initiatives')].listData.length === 0
                        && MindMapData[MindMapData.findIndex((item) => item.name === 'Journey Initiatives')].active === false && !configureButton
                        ? (
                          <div className="addmindmap-links">
                            <span><i className="fas fa-plus-circle" onClick={() => this.activeDropdown(MindMapData.findIndex((item) => item.name === 'Journey Initiatives'))} role="button" tabIndex="0" aria-label="Add Link" /></span>
                          </div>
                        ) : <></>
                    }
                      {/* List for Sub level Mindmap links end */}
                    </li>
                    <li className="mindmap-linkname bottomleft-child">
                      <span className="mindmap-name">Work Orchestration</span>
                      <div className="bottomleft-childlink mindmap-namelinks">
                        <div className="doublelinkelement" />
                      </div>
                      {/* List for Sub level Mindmap links start */}
                      <MindMapList
                        arr1={MindMapData[MindMapData.findIndex((item) => item.name === 'Work Orchestration')]}
                        configure={configureButton}
                        handleSave={() => this.handleSave()}
                      />
                      {
                      MindMapData[MindMapData.findIndex((item) => item.name === 'Work Orchestration')].listData.length === 0
                        && MindMapData[MindMapData.findIndex((item) => item.name === 'Work Orchestration')].active === false && !configureButton
                        ? (
                          <div className="addmindmap-links">
                            <span><i className="fas fa-plus-circle" onClick={() => this.activeDropdown(MindMapData.findIndex((item) => item.name === 'Work Orchestration'))} role="button" tabIndex="0" aria-label="Add Link" /></span>
                          </div>
                        ) : <></>
                    }
                      {/* List for Sub level Mindmap links end */}
                    </li>
                    <li className="mindmap-linkname bottom-child">
                      <span className="mindmap-name">Assets</span>

                      <div className="bottom-childlink mindmap-namelinks" />
                      {/* List for Sub level Mindmap links start */}
                      <MindMapList
                        arr1={MindMapData[MindMapData.findIndex((item) => item.name === 'Assets')]}
                        configure={configureButton}
                        handleSave={() => this.handleSave()}
                      />
                      {
                      MindMapData[MindMapData.findIndex((item) => item.name === 'Assets')].listData.length === 0
                        && MindMapData[MindMapData.findIndex((item) => item.name === 'Assets')].active === false && !configureButton
                        ? (
                          <div className="addmindmap-links">
                            <span><i className="fas fa-plus-circle" onClick={() => this.activeDropdown(MindMapData.findIndex((item) => item.name === 'Assets'))} role="button" tabIndex="0" aria-label="Add Link" /></span>
                          </div>
                        ) : <></>
                    }
                      {/* List for Sub level Mindmap links end */}
                    </li>
                    <li className="mindmap-linkname bottomright-child">
                      <span className="mindmap-name">Human + Machine</span>
                      <div className="bottomright-childlink mindmap-namelinks">
                        <div className="doublelinkelement" />
                      </div>
                      {/* List for Sub level Mindmap links start */}
                      <MindMapList
                        arr1={MindMapData[MindMapData.findIndex((item) => item.name === 'Human + Machine')]}
                        configure={configureButton}
                        handleSave={() => this.handleSave()}
                      />
                      {
                      MindMapData[MindMapData.findIndex((item) => item.name === 'Human + Machine')].listData.length === 0
                        && MindMapData[MindMapData.findIndex((item) => item.name === 'Human + Machine')].active === false && !configureButton
                        ? (
                          <div className="addmindmap-links">
                            <span><i className="fas fa-plus-circle" onClick={() => this.activeDropdown(MindMapData.findIndex((item) => item.name === 'Human + Machine'))} role="button" tabIndex="0" aria-label="Add Link" /></span>
                          </div>
                        ) : <></>
                    }
                      {/* List for Sub level Mindmap links end */}
                    </li>
                  </ul>
                ) : ''
            }
                </div>
              </div>
            </>
          ) : ''
      }
      </>
    );
  }
}

MindMap.propTypes = {
  CurrentFilter: PropTypes.object.isRequired,
  kpiMappingId: PropTypes.number,
  MindMapData: PropTypes.array.isRequired,
  kpiName: PropTypes.string,
  roleData: PropTypes.array.isRequired,
  propsFromParentScreen: PropTypes.shape({
    screenName: PropTypes.string.isRequired,
    orgEntityId: PropTypes.number.isRequired,
    offeringId: PropTypes.number.isRequired,
    locationId: PropTypes.number.isRequired,
    month: PropTypes.string.isRequired,
    kpiMappingId: PropTypes.number.isRequired,
    kpiName: PropTypes.string.isRequired,
    userHasEditAccess: PropTypes.bool.isRequired,
    doSave: PropTypes.bool.isRequired,
    callbackWhenLoadComplete: PropTypes.func.isRequired,
    onCallbackAfterSaveMindMap: PropTypes.func.isRequired,
    onCallbackEnableDisableSaveMindMapButton: PropTypes.func.isRequired,
  }),
  SaveClick: PropTypes.bool.isRequired,
  MindMapClick: PropTypes.bool.isRequired,
};

MindMap.defaultProps = {
  kpiMappingId: null,
  kpiName: '',
  propsFromParentScreen: {},
};

const mapStateToProps = (state) => ({
  MindMapData: state.mindMapReducer.InitialData,
  MindMapClick: state.mindMapReducer.clickStatus,
  clientData: state.CurrentFilter.ClientData,
  CurrentFilter: { ...state.CurrentFilter },
  saveClick: state.mindMapReducer.saveClick,
  roleData: state.roleReducers.roleReducers,
});

const connectedFunc = connect(
  mapStateToProps,
);

export default compose(
  withRouter,
  connectedFunc,
)(MindMap);
