import React, { Component } from 'react';
import DashboardCntContainer from '../Configurator/DashboardCntContainer';
import { LocalApiBaseUrl, getMonthNumber, getPrevMonth } from '../Shared/Constant';
import { AgGridReact, Ag } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-blue.css';
import { store } from "../reducers/configureStore"
import Axios from 'axios';
//import MasterContainer from './MasterContainer';

import user from '../Images/user.png';
import security from '../Images/security.png';
import offering from '../Images/offering.png';
import location from '../Images/location.png';
import config from '../Images/config.png';
import { Link, NavLink } from 'react-router-dom';
import mydealprofile from '../Images/mydealprofile.png';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import GOCList from './GOCList';
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;






export default class GOCContainer extends Component {
    constructor() {
        super()
        this.state = {

            roleData: [],
            userList: [], roleList: [], clientList: [], viewmore: false, rowSelected: [], featureData: [],
            isGOCClicked: true,
            //isWeekWiseClicked: false,
        }
        this.onClose = this.onClose.bind(this);
        this.onGOCClick = this.onGOCClick.bind(this);
        this.getRoleData = this.getRoleData.bind(this);
    }

    onClose(e) {

        this.setState({ viewmore: false });
    }

    getRoleData() {
        
        axiosInstance.get(`${LocalApiBaseUrl}User/GetUserFeature`, {
            params: {}
        })
            .then((response) => {


                store.dispatch({
                    type: 'INITIALISEROLE',

                    payload: response.data

                })
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
      

        var data = store.getState();
        let roleData1 = data.roleReducers["roleReducers"];
        store.subscribe(() => {
            var data = store.getState();
            this.setState({ roleData: data.roleReducers["roleReducers"] })
            let featureDetails1 = data.roleReducers["roleReducers"];
            this.setState({ featureData: featureDetails1 })

        })
    }


    componentWillMount() {

            this.getRoleData();

    }

    componentDidMount() {
               
        this.getRoleData();
        
    }

  

    onGOCClick(e) {

        this.setState({
            isGOCClicked: true,
        });
    }

    render() {
        
        return (
            <div className="main-component">
                <div className="ag-theme-blue">
                    <div className="row">
                        <div className="col-lg-12 tab-list">
                            <ul className="nav nav-tabs">
                            {this.state.roleData.filter((data) => data.FeatureName=="GOC Form" && data.Operations.includes("View")).length>0 ? <li selection="[object Object]" className="nav-item"><a  className="active"  data-toggle="tab" onClick={this.onGOCClick}>GOC Form</a></li>:null}
                              {/* <li selection="[object Object]" className="nav-item"><a  data-toggle="tab" onClick={this.onGOCClick}>GOC Form</a></li> */}
                             
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <GOCList filterDetails={this.props.filterDetails} isGOCClicked={this.state.isGOCClicked}></GOCList>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}