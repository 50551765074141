import React, { Component } from 'react';
import { LocalApiBaseUrl, getMonthNumber, getPrevMonth } from '../Shared/Constant';
import { AgGridReact, Ag } from 'ag-grid-react';
import { store } from "../reducers/configureStore"
import Axios from 'axios';
import IndustryPopup from './IndustryPopup';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faPlusCircle,faFileDownload } from '@fortawesome/free-solid-svg-icons'

axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;



export default class IndustryList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userList: [], roleList: [], clientList: [], viewmore: false, rowSelected: [],
            columnDefs: [
                {
                    headerName: "Edit", suppressMovable: true,
                    width: 60,
                    cellRenderer: (params) => {
                        // if(params.data.Status==="Approved"){
                        var link = document.createElement('span');
                        link.className = 'tbledit';
                        link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
                        link.addEventListener('click', (e) => {
                            this.dealpopup(e, params.data);
                        });
                        return link;
                        // }
                    },
                    suppressFilter: true,
                },
                {
                    headerName: "Delete", suppressMovable: true,
                    width: 80,
                    cellRenderer: (params) => {
                        var link = document.createElement('span');
                        link.className = 'tbldel';
                        link.innerHTML = '<i class="fas fa-trash-alt delete-icon-fix"></i>';
                        link.addEventListener('click', (e) => {
                            this.deleteIndustry(e, params.data);
                        });
                        return link;

                    },
                    suppressFilter: true
                },
                { headerName: "Industry", field: "Industry", suppressMovable: true, resizable: false },
                // { headerName: "Operating Group", field: "OperatingGroup", suppressMovable: true, resizable: false },
                { headerName: "Status", field: "Status", suppressMovable: true, resizable: false },
                // { headerName: "Requestor Remarks", field: "RequestorRemarks",  resizable: false },
                // { headerName: "Approver Remarks", field: "ApproverRemarks",  resizable: false },
                {
                    headerName: "Is Active", field: "IsActive", resizable: false, suppressMovable: true, suppressFilter: true,

                    cellRenderer: params => {
                        return `<label class="form-check">
                    <input class="form-check-input" disabled type="checkbox" ${params.value ? 'checked' : ''}>
                    <span class="checkmark"></span>
                     </label>`;
                    }/*, cellStyle: function (params) {
                        return { 'text-align': "left" };
                    }*/
                },

            ],
            rowData: [],
            gridOptions: {
                paginationPageSize: 50,
                rowHeight: 40,
                headerHeight: 40,
                animateRows: true,
                rowSelection: 'multiple',
                defaultColDef: {
                    sortable: true
                },
            }
        }
        this.onClose = this.onClose.bind(this);
        this.dealpopup = this.dealpopup.bind(this);
        this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.onBtnExport = this.onBtnExport.bind(this);

    }

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    onBtnExport() {


     let params = {
         //columnKeys: ['ClientName', 'DeliveryCentre', 'IsActive'],
         columnKeys: ['Industry', 'Status', 'IsActive'],
         columnGroups: true,
         allColumns: false,
         skipHeader: false,
         fileName: 'IndustryList',
         //   columnSeparator: document.querySelector("#columnSeparator").value
     };
     this.state.gridOptions.api.exportDataAsCsv(params);
 }

    onFilterTextBoxChanged(e) {
        this.state.gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
    }
    dealpopup(e, data) {


        if (data != undefined) {
            // edit condition
            if (this.props.featureData.filter((data) => data.Submenu == "Industry" && data.Operations.includes("Edit")).length > 0) {
                e.preventDefault();
                this.setState({ viewmore: true, rowSelected: data })
            }
            else window.alert("Access Denied !!")

        }
        else {
            // add condition
            if (this.props.featureData.filter((data) => data.Submenu == "Industry" && data.Operations.includes("Add")).length > 0) {

                e.preventDefault();
                this.setState({ viewmore: true, rowSelected: data })
            }
            else window.alert("Access Denied !!")

        }



    }
    onClose(e) {

        this.setState({ viewmore: false });
    }

    deleteIndustry(e, data) {


        if (this.props.featureData.filter((data) => data.Submenu == "Industry" && data.Operations.includes("Delete")).length > 0) {

            if (window.confirm('Are you sure you want to delete the item?')) {
                axiosInstance.delete(`${LocalApiBaseUrl}Industry/DeleteIndustry`, {
                    params: {
                        IndustryID: data.IndustryId
                    }
                }).then((response) => {
                    window.alert(response.data.StatusMessage)
                    this.getIndustry();

                })
                .catch((error) => {
                    trycatchAlertPopup(error);
                  });
          
          
            }

        }
        else window.alert("Access Denied !!")

    }

    getIndustry() {

        axiosInstance.get(`${LocalApiBaseUrl}Industry/GetIndustry`)
            .then((response) => {
                this.setState({
                    rowData: response.data
                })
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
      
    }

    componentWillMount() {

        this.getIndustry();
        let columnDefs = this.state.columnDefs;

        if (this.props.featureData.filter((data) => data.Submenu == "Industry" && data.Operations.includes("Edit")).length == 0) {
            columnDefs = columnDefs.filter((data) => data.headerName != "Edit");
            this.setState({
                columnDefs: columnDefs
            })

        }


        if (this.props.featureData.filter((data) => data.Submenu == "Industry" && data.Operations.includes("Delete")).length == 0) {
            columnDefs = columnDefs.filter((data) => data.headerName != "Delete");
            this.setState({
                columnDefs: columnDefs
            })

        }
        // if (this.props.featureData.filter((data) => data.Submenu == "Industry" && data.Operations.includes("Delete","Edit")).length == 0) {
        //     columnDefs = this.state.columnDefs.filter((data) => data.headerName != "Delete"&& data.headerName != "Edit");
        //     this.setState({
        //         columnDefs: columnDefs
        //     })

        // }

    }

    // shouldComponentUpdate() {
    //     this.getIndustry();
    //     return true;
    // }

    handleSave(data) {

        axiosInstance.post(`${LocalApiBaseUrl}Industry/AddEditIndustry`, data)
            .then((response) => {
                window.alert(response.data.StatusMessage)
                this.getIndustry();
            },
                (error) => {
                    trycatchAlertPopup(error)
                })
                .catch((error) => {
                    trycatchAlertPopup(error);
                  });
          
        this.getIndustry();

    }

    render() {

        return (
            <div className="main-data">
                {/* <h3 className="grid-heading">Delivery Industry List</h3> */}
                <div className="tableFilter">
                    <div className="form-section">
                        <div className="form-row">
                            <div className="form-group col-lg-3">
                                <input type="text" className="form-control" id="filter-text-box" placeholder="Filter..." onChange={(e) => { this.onFilterTextBoxChanged(e) }} />
                            </div>
                            {this.props.featureData.filter((data) => data.Submenu == "Industry" && data.Operations.includes("Add")).length > 0 &&
                                <div className="form-group col-lg-3">
                                    <button type="button" className="btn btn-primary btn-sm" onClick={(e) => { this.dealpopup(e) }}><span><FontAwesomeIcon icon={faPlusCircle} /></span> Add Industry</button>
                                </div>
                            }
<div className="ml-auto mr-1">
{this.props.featureData.filter((data) => data.Submenu == "Industry" && data.Operations.includes("Download")).length > 0 ?
                                    <span className="btn btn-primary btn-sm py-1" onClick={this.onBtnExport}>
                                        <FontAwesomeIcon icon={faFileDownload} className="mr-1" /> Download
                                </span> : null}
                                </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ag-theme-balham ag-table">
                            <AgGridReact
                                enableSorting={true}
                                enableFilter={true}
                                pagination={true}
                                paginationPageSize={50}
                                floatingFilter={true}
                                gridOptions={this.state.gridOptions}
                                columnDefs={this.state.columnDefs}
                                rowData={this.state.rowData}
                                onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                            </AgGridReact>
                        </div>
                    </div>
                </div>
                {this.state.viewmore ? <IndustryPopup rowData={this.state.rowData} data={this.state.rowSelected} show={this.state.viewmore} onClose={this.onClose} onSave={this.handleSave} /> : null}
            </div>
        )
    }
}