import React from 'react';
import { Modal, Button } from 'react-bootstrap';


//Tree1
// import DropdownTreeSelect2 from 'react-dropdown-tree-select'
// import 'react-dropdown-tree-select/dist/styles.css'
//Tree2
// import DropdownTreeSelect from 'deni-react-treeview'

//Tree3
import Tree from 'react-checkbox-tree';
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import {
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdChevronRight,
    MdKeyboardArrowDown,
    MdAddBox,
    MdIndeterminateCheckBox,
    MdFolder,
    MdFolderOpen,
    MdInsertDriveFile
} from "react-icons/md";
import { IconContext } from "react-icons";

import { LocalApiBaseUrl, getMonthNumber, getPrevMonth } from '../Shared/Constant';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import { regexForConfMasterFields } from '../Shared/Constant';
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;




const onAction = (node, action) => {
    

}
const onNodeToggle = currentNode => {
    

}


export default class UserProfilePopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false, apivalue: "", selectValue: this.props.SelectValue, Display: false, checked: [], keyword: "", TreeData: [], expanded: [],

            UserProfile: {
                FeatureID: [],
                RoleDescription: "",
                RoleName: "",
                IsActive: false,
            },
            errors: [],
            formIsValid: false,
        }

        this.onProfileNameChange = this.onProfileNameChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onCheck = this.onCheck.bind(this);
        this.onExpand = this.onExpand.bind(this);
        this.GetFeatures = this.GetFeatures.bind(this);

    }


    GetFeatures() {
        
        axiosInstance.get(`${LocalApiBaseUrl}UserProfile/GetFeatureList`, {

            params: {}
        })
            .then((response) => {
                this.setState({
                    TreeData: response.data
                })
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
    }


    onCheck(checked) {
        
        let UserProfile = this.state.UserProfile;
        UserProfile.FeatureID = checked;

        this.setState({
            checked
        });
        this.setState({
            UserProfile: UserProfile
        });

    }

    onExpand(expanded) {
        
        this.setState({
            expanded
        });
    }


    onProfileNameChange(event) {
        
        let errors = {};
        this.setState({
            errors: errors
        });
        var UserProfile = this.state.UserProfile;
        UserProfile.RoleName = event.target.value;
        this.setState({
            UserProfile: UserProfile
        })
        // this.validateForm();
    }
    
    onDescriptionChange(event) {
        
        let errors = {};
        this.setState({
            errors: errors
        });
        var UserProfile = this.state.UserProfile;
        UserProfile.RoleDescription = event.target.value;
        this.setState({
            UserProfile: UserProfile
        })
        // this.validateForm();
    }


    onStatusChange(event) {
        
        const UserProfile = this.state.UserProfile;
        UserProfile.IsActive = event.target.checked;
        this.setState({
            UserProfile: UserProfile
        })
    }


    handleSave(e) {
        
        if (this.validateForm()) {

            this.props.onSave(this.state.UserProfile)

            this.handleHide();
        }
    }
    handleHide(e) {
        
        this.setState({ show: false });
        if (e != undefined) {

            if ((e.target.textContent != "×")) {
                this.props.onClose(e);
            }
            else {
                this.props.onClose(e);
                // this.props.onClose("from x");
            }
        } else {
            this.props.onClose(e);
        }
       // this.props.onSave()

    }
    handleRejectHide(e) {
        

        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onRejectClose();
            }
        } else {
            this.props.onRejectClose();
        }

    }
    handleApproveHide(e) {
        
        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onApproveClose();
            }
        } else {
            this.props.onApproveClose();
        }

    }
    passToConfirmation(data) {
        

        this.props.enteredData(data.PoNumber)
    }


    componentDidMount() {
        
        this.setState({
            show: this.props.show
        })
    }

    validateForm() {
        
        const UserProfile = this.state.UserProfile;
        let errors = {};
        let formIsValid = true;
        var data = this.props.rowData;

         if (data.length > 0) 
         {
         
            let oldData = data.map(function (el) { return el.RoleName });
             if (oldData.includes(UserProfile.RoleName.trim()) && (!this.props.data)) {
                formIsValid = false;
                errors["RoleName"] = "Name already exists";
             }
         }


        if (!UserProfile["RoleName"].trim()) {
            formIsValid = false;
            errors["RoleName"] = "Profile Name is required";

        }
        else {
            var isValid = regexForConfMasterFields.test(UserProfile["RoleName"]);
            if (!isValid) {
                formIsValid = false;
                errors["RoleName"] = "Profile Name contains Special characters";
            }
        }


        if (!UserProfile["RoleDescription"].trim()) {
            formIsValid = false;
            errors["RoleDescription"] = "Description is required";

        }
        else {
            var isValid = regexForConfMasterFields.test(UserProfile["RoleDescription"]);
            if (!isValid) {
                formIsValid = false;
                errors["RoleDescription"] = "Description contains Special characters";
            }
        }



        this.setState({
            errors: errors
        });
        if (formIsValid) {
            this.setState({
                formIsValid: true
            })
        }
        else {
            this.setState({
                formIsValid: false
            })
        }
        return formIsValid;

    }

    componentWillMount() {
        
        this.GetFeatures()
        if (this.props.data) {
            this.state.expanded =this.props.data.FeatureID.concat(this.props.data.ParentFeatureID);
            // this.props.data.FeatureID.concat(this.props.data.ParentFeatureID)
            this.setState({
                UserProfile: this.props.data,
            })
        }

    }


    render() {
        
        const icons = {
            check: <MdCheckBox className="rct-icon rct-icon-check" />,
            uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
            halfCheck: (
                <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />
            ),
            expandClose: (
                <MdChevronRight className="rct-icon rct-icon-expand-close" />
            ),
            expandOpen: (
                <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />
            ),
            expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
            collapseAll: (
                <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
            ),
            parentClose: <MdFolder className="rct-icon rct-icon-parent-close" />,
            parentOpen: <MdFolderOpen className="rct-icon rct-icon-parent-open" />,
            leaf: <MdInsertDriveFile className="rct-icon rct-icon-leaf-close" />
        };



        return (
            <>
                {/* <Header /> */}
                <Modal show={this.state.show} onHide={this.handleHide.bind(this)} className="modal fade" size="medium" id="dealpopup" backdrop = "static">
                    <Modal.Header closeButton>
                        {!this.props.data ?
                            <h4 className="modal-title" id="dealprofile">Add Profile</h4>
                            : <h4 className="modal-title" id="dealprofile">Edit Profile</h4>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        
                            <form>
                                <div className="form-section">

                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label for="">Profile Name<span className="required">*</span></label>
                                            {!this.props.data ?
                                            <input type="text" className="form-control" id="name" onChange={this.onProfileNameChange} value={this.state.UserProfile.RoleName} />
                                            : <input type="text" className="form-control" id="name" disabled onChange={this.onProfileNameChange} value={this.state.UserProfile.RoleName} />
                                            }
                                            <span className="errorMsg">{this.state.errors.RoleName}</span>

                                        </div>

                                        <div className="form-group col-lg-6">
                                            <label for="">Description<span className="required">*</span></label>
                                            <input type="text" className="form-control" id="name" onChange={this.onDescriptionChange} value={this.state.UserProfile.RoleDescription} />
                                                                                  
                                            <span className="errorMsg">{this.state.errors.RoleDescription}</span>
                                        </div>

                                    </div>

                                    <div className="row">

                                        <div className="form-group col-lg-6">
                                             
                                            <label className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={this.onStatusChange} defaultChecked={this.state.UserProfile.IsActive} />
                                                <span className="checkmark"></span>
                                                <span className="form-check-label">Is Active</span>
                                            </label>
                                        </div>

                                        {/*                                             
                                            <DropdownTreeSelect2 data={this.state.data2}     
                                               showCheckbox={false}
                                               onCheck={this.onCheck}
                                                onChange={this.onChange} onAction={onAction} onNodeToggle={onNodeToggle} />
                                        </div> */}


                                    </div>
                                    {/* 
                                    <div className="form-group col-lg-1">
                                        <DropdownTreeSelect
                                            ref="treeview"
                                            items={this.state.data}
                                            showCheckbox={true}
                                            showIcon = {false}
                                            clearSearchOnChange={true}
                                            placeholderText="Select"
                                            showPartiallySelected={true}
                                            onCheckItem={this.onCheck}
                                            onSelectItem={this.onSelectItem}



                                        />
                                    </div> */}

                                
                                    
                                        {/* <IconContext.Provider value={{ color: "blue", className: "global-class-name" }}> */}
                                        <div className="row">
                                        <div className="form-group col-lg-12">
                                            <label for="">Features</label>
                                        <Tree
                                            icons={icons}
                                            nodes={this.state.TreeData}
                                            checked={this.state.UserProfile.FeatureID}
                                            expanded={this.state.expanded}
                                            onCheck={
                                                this.onCheck
                                            }
                                            onExpand={
                                                this.onExpand
                                            }
                                            onlyLeafCheckboxes={true}

                                        />
                                        </div>
                                        {/* </IconContext.Provider> */}
                                    </div>
                                    {/* </div> */}

                                </div>


                            </form>
                        
                    </Modal.Body>
                    {<Modal.Footer>
                        <div className="modal-btn">
                                {!this.props.data ?
                                    <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value="Create" />
                                    : <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value="Update" />
                                }
                        </div>
                    </Modal.Footer>}
                </Modal>
            </>
        )
    }
}