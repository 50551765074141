import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import classNames from "classnames";
import _ from "lodash";
import moment from "moment";

// IMPORT CUSTOM COMPONENTS/FUNCTIONS
import SingleShareAssessment from "./SingleShareAssessment";
import BulkShareAssessment from "./BulkShareAssessment";
import SynopsLoader from "../Shared/SynopsLoader";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { getInitialsFromUserEID } from "../Shared/commonFunctions";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";

const sortASC = function(obj1, obj2) {

  if (obj1.dateObj > obj2.dateObj) return 1;
  if (obj1.dateObj < obj2.dateObj) return -1;
  return 0;
};

const sortDESC = function(obj1, obj2) {
  // This is a comparison function that will result in dates being sorted in DESCENDING order
  if (obj1.dateObj > obj2.dateObj) return -1;
  if (obj1.dateObj < obj2.dateObj) return 1;
  return 0;
};

class NotificationRequestsListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAPILoading: false,
      ownerUserName: sessionStorage.getItem("EnterpriseID"),
      originalListingDataArr: [],
      filteredListingDataArr: [],
      dateColSortOrder: "desc",
      currentlySelectedSingleShareAssessmentDetails: {},
      currentlySelectedBulkShareAssessmentDetails: {},
    };
  }

  componentDidMount() {
    this.fetchListingData();
  }

  componentDidUpdate(prevProps) {
    const { searchFilterTextValue } = this.props;

    if (prevProps.searchFilterTextValue !== searchFilterTextValue) {
      this.filterListingData();
    }
  }

  fetchListingData = () => {
    this.setState({ isAPILoading: true });

    // FETCH THE DATA FROM THE API
    return axiosInstance
      .get(`${LocalApiBaseUrl}BATAssessment/GetBATAssessmentSharingRequests`)
      .then((response) => {
        let originalListingDataArr = [];

        if (!_.isEmpty(response.data)) {
          const responseData = response.data;
          originalListingDataArr = _.map(responseData, (eachRequestDetails) => {
            const dateObj = moment(eachRequestDetails.Date, "DD MMM YYYY").toDate();
            const requestByUserInitials = getInitialsFromUserEID(eachRequestDetails.RequestBy);
            const status = eachRequestDetails.Status;
            const statusTextArr = _.map(_.split(status, ","), (eachItem) => _.trim(eachItem));

            const statusText = !_.isEmpty(status) ? _.upperFirst(status) : "";
            const SharedUsers = _.map(eachRequestDetails.SharedUsers, (eachSharedUserDetails) => {
              const userInitials = getInitialsFromUserEID(eachSharedUserDetails.UserName);
              return {
                ...eachSharedUserDetails,
                userInitials,
              };
            });

            return {
              ...eachRequestDetails,
              dateObj,
              requestByUserInitials,
              statusText,
              statusTextArr,
              SharedUsers,
            };
          });
        }

        this.setState({
          originalListingDataArr,
          filteredListingDataArr: originalListingDataArr,
          isAPILoading: false,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        this.setState({ isAPILoading: false });
      });
  };

  filterListingData = () => {
    const { searchFilterTextValue } = this.props;
    const { originalListingDataArr, dateColSortOrder } = this.state;

    let filteredListingDataArr = originalListingDataArr;

    if (!_.isEmpty(searchFilterTextValue)) {
      filteredListingDataArr = _.filter(originalListingDataArr, (eachItem) => {
        const matchedSharedAssessmentsArr = _.filter(eachItem.AssessmentNames, (eachSharedAssessment) => {
          const assessmentName = _.toLower(
            `${eachSharedAssessment.BATClientName}: ${eachSharedAssessment.BATSubOfferingName}`
          );
          const isAssessmentNameMatches = assessmentName.indexOf(_.toLower(searchFilterTextValue)) !== -1;
          return isAssessmentNameMatches;
        });
        const foundAnyMatchedAssessments = _.size(matchedSharedAssessmentsArr) > 0;
        return foundAnyMatchedAssessments;
      });
    }

    this.setState(
      {
        filteredListingDataArr,
      },
      () => {
        this.sortListingData(dateColSortOrder);
      }
    );
  };

  sortListingData = (sortOrder) => {
    const { filteredListingDataArr } = this.state;
    const sorted =
      sortOrder === "asc"
        ? _.cloneDeep(filteredListingDataArr).sort(sortASC)
        : _.cloneDeep(filteredListingDataArr).sort(sortDESC);

    return sorted;
  };

  handleClickSortColumn = () => {
    const { dateColSortOrder } = this.state;
    const toSortByOrder = dateColSortOrder === "desc" ? "asc" : "desc";
    const sorted = this.sortListingData(toSortByOrder);

    this.setState({
      filteredListingDataArr: sorted,
      dateColSortOrder: toSortByOrder,
    });
  };

  handleClickEditAssessmentPermissions = (selectedAssessment) => {
    const isAssessmentBulkShared = _.size(selectedAssessment.AssessmentNames) > 1;

    this.setState({
      currentlySelectedSingleShareAssessmentDetails: !isAssessmentBulkShared ? selectedAssessment : {},
      currentlySelectedBulkShareAssessmentDetails: isAssessmentBulkShared ? selectedAssessment : {},
    });
  };

  handleCallbackShareAssessmentModalCancelClose = () => {
    this.setState({
      currentlySelectedSingleShareAssessmentDetails: {},
      currentlySelectedBulkShareAssessmentDetails: {},
    });
  };

  handleCallbackShareAssessmentModalSuccClose = () => {
    this.setState(
      {
        currentlySelectedSingleShareAssessmentDetails: {},
        currentlySelectedBulkShareAssessmentDetails: {},
      },
      () => {
        this.fetchListingData();
      }
    );
  };

  render() {
    const {
      isAPILoading,
      filteredListingDataArr,
      dateColSortOrder,
      currentlySelectedSingleShareAssessmentDetails,
      currentlySelectedBulkShareAssessmentDetails,
      ownerUserName,
    } = this.state;

    return (
      <>
        {/* IF API IS LOADING THEN SHOW THE LOADER */}
        {isAPILoading && <LoadingOverlay spinner={<SynopsLoader />} active />}

        <div className="notificationrequest-table access-request-table">
          <table class="table">
            {_.isEmpty(filteredListingDataArr) ? (
              <tr>
                <td>
                  <h5>No records to be displayed!</h5>
                </td>
              </tr>
            ) : (
              <>
                <thead>
                  <tr>
                    <th scope="col">Assessment Name</th>
                    <th scope="col">Request from</th>
                    <th scope="col">User</th>
                    <th scope="col" className="sortable" onClick={() => this.handleClickSortColumn()}>
                      Date
                      <span
                        className={classNames("sort-arrow", {
                          up: dateColSortOrder === "asc",
                        })}
                      />
                    </th>
                    <th scope="col">Access Status</th>
                    <th scope="col">Access Permission</th>
                  </tr>
                </thead>

                <tbody>
                  {_.map(filteredListingDataArr, (eachListingItem) => {
                    const sharedAssessmentsArr = eachListingItem.AssessmentNames;
                    const countSharedUsersToDisplay = 3;
                    const sharedUsersArr = eachListingItem.SharedUsers;
                    const sharedUsersArrForDisplay =
                      _.size(sharedUsersArr) > countSharedUsersToDisplay
                        ? _.take(sharedUsersArr, countSharedUsersToDisplay)
                        : sharedUsersArr;
                    const excessSharedUsersArr = _.drop(sharedUsersArr, countSharedUsersToDisplay);
                    const excessSharedUsersCount = _.size(excessSharedUsersArr);
                    const statusText = eachListingItem.statusText;

                    return (
                      <tr key={eachListingItem.BATAssessReshareRequestID}>
                        <td>
                          {!_.isEmpty(sharedAssessmentsArr) &&
                            `${_.head(sharedAssessmentsArr).BATClientName}: ${
                              _.head(sharedAssessmentsArr).BATSubOfferingName
                            }`}

                          {sharedAssessmentsArr.length > 1 && (
                            <span class="extraSubOffering-tooltip">
                              +{sharedAssessmentsArr.length - 1}
                              <span class="extraSubOffering-tooltiptext">
                                <ul>
                                  {_.times(sharedAssessmentsArr.length - 1, (index) => (
                                    <li key={index}>
                                      {sharedAssessmentsArr[index + 1].BATClientName}:{" "}
                                      {sharedAssessmentsArr[index + 1].BATSubOfferingName}
                                    </li>
                                  ))}
                                </ul>
                              </span>
                            </span>
                          )}
                        </td>

                        <td>
                          <div className="assessment-usericons">
                            <span className="assessment-tags">
                              <span>{eachListingItem.requestByUserInitials}</span>
                            </span>
                            <span>{eachListingItem.RequestBy}</span>
                          </div>
                        </td>

                        <td>
                          <div className="assessment-usericons">
                            <>
                              {_.map(sharedUsersArrForDisplay, (eachSharedUserDetails) => (
                                <span className="bg-user userlist-tooltip assessment-tags">
                                  <span className="userid-span">{eachSharedUserDetails.userInitials}</span>
                                  <span className="userlist-tooltiptext">
                                    <ul>
                                      <li>
                                        <span className="list-user-name">{eachSharedUserDetails.UserName}</span>
                                        <span className="list-user-permission">
                                          {eachSharedUserDetails.AccessLevelName}
                                        </span>
                                      </li>
                                    </ul>
                                  </span>
                                </span>
                              ))}

                              {_.size(sharedUsersArr) > countSharedUsersToDisplay && (
                                <span className="assessment-excess excess-users userlist-tooltip">
                                  +{excessSharedUsersCount}
                                  <span className="userlist-tooltiptext">
                                    <ul>
                                      {_.map(sharedUsersArr, (eachSharedUserDetails) => {
                                        return (
                                          <li>
                                            <span className="list-user-name">{eachSharedUserDetails.UserName}</span>
                                            <span className="list-user-permission">
                                              {eachSharedUserDetails.AccessLevelName}
                                            </span>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </span>
                                </span>
                              )}
                            </>
                          </div>
                        </td>

                        <td>{eachListingItem.Date}</td>

                        <td>
                          {_.map(eachListingItem.statusTextArr, (eachStatus, index) => {
                            const lowerCaseStatusText = _.toLower(eachStatus);
                            return (
                              <>
                                <span
                                  className={classNames(
                                    "assessment-status",
                                    { "approved-status": lowerCaseStatusText.indexOf("approved") !== -1 },
                                    { "pending-status": lowerCaseStatusText.indexOf("pending") !== -1 },
                                    { "decline-status": lowerCaseStatusText.indexOf("declined") !== -1 }
                                  )}
                                >
                                  {_.upperFirst(lowerCaseStatusText)}
                                </span>
                                {index !== _.size(eachListingItem.statusTextArr)-1 && ", "}
                              </>
                            );
                          })}
                        </td>

                        <td>
                          <span
                            className={classNames("assessment-editbtn", {
                              disabled: statusText !== "Pending",
                            })}
                            onClick={() => {
                              if (statusText === "Pending") {
                                this.handleClickEditAssessmentPermissions(eachListingItem);
                              }
                            }}
                          >
                            Edit
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            )}
          </table>
        </div>

        {/* MODAL COMPONENT FOR SINGLE SHARE */}
        {!_.isEmpty(currentlySelectedSingleShareAssessmentDetails) && (
          <SingleShareAssessment
            onShareModalCancelClose={() => this.handleCallbackShareAssessmentModalCancelClose()}
            onShareModalSuccClose={() => this.handleCallbackShareAssessmentModalSuccClose()}
            mode="reshare"
            actionToBePerformed="approve"
            ownerUserName={ownerUserName}
            assessmentDetails={{
              ...currentlySelectedSingleShareAssessmentDetails,
              BATAssessSubOfferingID: _.get(
                currentlySelectedSingleShareAssessmentDetails,
                "AssessmentNames.0.BATAssessSubOfferingID"
              ),
              BATSubOfferingName: _.get(
                currentlySelectedSingleShareAssessmentDetails,
                "AssessmentNames.0.BATSubOfferingName"
              ),
            }}
            clientDetails={{
              CreatedBy: currentlySelectedSingleShareAssessmentDetails.RequestBy,
            }}
          />
        )}

        {/* MODAL COMPONENT FOR BULK SHARE */}
        {!_.isEmpty(currentlySelectedBulkShareAssessmentDetails) && (
          <BulkShareAssessment
            show={!_.isEmpty(currentlySelectedBulkShareAssessmentDetails)}
            onBulkShareModalCancelClose={() => this.handleCallbackShareAssessmentModalCancelClose()}
            onBulkShareModalSuccClose={() => this.handleCallbackShareAssessmentModalSuccClose()}
            mode="reshare"
            actionToBePerformed="approve"
            ownerUserName={ownerUserName}
            sharedUsers={currentlySelectedBulkShareAssessmentDetails.SharedUsers}
            list={_.map(currentlySelectedBulkShareAssessmentDetails.AssessmentNames, (eachSharedAssessment) => ({
              id: eachSharedAssessment.BATAssessSubOfferingID,
              name: eachSharedAssessment.BATSubOfferingName,
            }))}
            clientDetails={{
              CreatedBy: currentlySelectedBulkShareAssessmentDetails.RequestBy,
            }}
          />
        )}
      </>
    );
  }
}

export default NotificationRequestsListing;
