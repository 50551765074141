import ParentContainer from "./ParentContainer";
import React, { useEffect,useState } from "react";
import "babel-polyfill";
import { acquireNewTokenSilent,refreshPBI } from "./Authorization/AcquireTokenApi";
import {idTokenstring, LocalApiBaseUrl, trycatchAlertPopup} from './Shared/Constant';
// MSAL imports
import { MsalProvider,useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import _ from 'lodash';
import {initializeUserAccountData} from "./actions/userLoginAction";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { setSisenseScript } from './actions/appActions';
import axiosInstance from './Shared/interceptor';
import {TRACKING_MODULES, userTracker} from "./Shared/Common";

const sisenseIsLoaded = () => {
  return ((typeof window.Sisense !== "undefined") && (typeof window.prism !== "undefined"));
}

const guid = () => {
  const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
    return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
  }

function App ({storeLoginUserData, setSisenseScript }) {
  const { inProgress } = useMsal();
    const [userData, setData] = useState(null);
    const[islogginsucessfull,setislogginsucessfull]=useState(false);
    const[userRole,setUserRole]=useState(null);
    const isDevEnvironment=process.env.NODE_ENV === 'development';
    const opriaURL = process.env.REACT_APP_OPRIA_URL;
    
    const loadSisense = () => {
      const isLoaded = sisenseIsLoaded();
      if (isLoaded) {
          const Sisense = window.Sisense;
          Sisense.connect(opriaURL, true).then(app => {
            setSisenseScript({app, error: '', fetching: false, hasSisenseAccess: true});
          }).catch((error) => {
            setSisenseScript({app: null, error: 'Error in loading dashboards', fetching: false, hasSisenseAccess: true});
          })
      }
  }
  const handleSisenseError = (error) => {
    setSisenseScript({app: null, error: 'Error in loading dashboards', fetching: false, hasSisenseAccess: true});
  }
    
    const connect = (dataParam) => {
      if (!sisenseIsLoaded()) {
          fetch(`${opriaURL}js/sisense.js`)
          .then((response) => {
              if (response.status === 200) {
                  const script = document.createElement("script");
                  script.type = "text/javascript";
                  script.src =`${opriaURL}js/sisense.js?v=${guid()}`
                  script.async = true;
                  script.onload = loadSisense;
                  script.onerror = handleSisenseError;
                  document.body.appendChild(script);
                  
                } else {
                  setSisenseScript({app: null, error: 'Error in loading dashboards', fetching: false, hasSisenseAccess: true});
                }
          }).catch((error) => {
              setSisenseScript({app: null, error: 'Error in loading dashboards', fetching: false, hasSisenseAccess: true});
          })
      }
  }
    const GetUserAccessDetails = () => {
      axiosInstance
        .get(`${LocalApiBaseUrl}ConnectorMapping/GetUserAccessDetails`)
        .then((res) => {
          const hasSisenseAccess = res.data.HasSisenseAccess;
          if (hasSisenseAccess) {
            setSisenseScript({app: null, error: '', fetching: true, hasSisenseAccess});
            setUserRole(res.data)
          } else {
            setSisenseScript({app: null, error: '', fetching: false, hasSisenseAccess: false});
            setUserRole(res.data)
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }

    useEffect(()=>{
      if(userRole && userRole.HasSisenseAccess){
        connect()
      }
    },[userRole])
    
    useEffect(async () => {
        if (!isDevEnvironment &&!userData && inProgress === InteractionStatus.None) {
            let response=await acquireNewTokenSilent(false);
              setData(response);
              storeLoginUserData(response);
              let token=sessionStorage.getItem(idTokenstring);
              if(!_.isEmpty(token) && !_.isNil(token)){
                setislogginsucessfull(true);
                if(!sessionStorage.getItem('sessionActive')){
                  sessionStorage.setItem('sessionActive', 'yes'); /* For Local below code */                  
                }    
              }   
        }
    }, [inProgress, userData]);
    useEffect(() => {
      if(isDevEnvironment){
        sessionStorage.setItem('EnterpriseID', 'sujith.madhyastha'); /* For Local below code */
        sessionStorage.setItem(
          idTokenstring,
          'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyJ9.eyJhdWQiOiI5MDkwMTE2Yy03MGQ0LTQzMzQtODE2Mi01MzllYmY5YzI1MzgiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vZjMyMTFkMGUtMTI1Yi00MmMzLTg2ZGItMzIyYjE5YTY1YTIyL3YyLjAiLCJpYXQiOjE2ODMxOTQ2OTUsIm5iZiI6MTY4MzE5NDY5NSwiZXhwIjoxNjgzMTk4NTk1LCJuYW1lIjoiS2FzaHlhcCBLYWxhd2FkZSwgU2Fua2V0Iiwibm9uY2UiOiJjZmJkZGJmNi03ZWJlLTQ5ODAtYjUxNS0xZDhiMzkzYjIxOGIiLCJvaWQiOiJjMDJlYjhlNi01ZGYyLTQ3ZTMtYWViNi05NWEwMDAzNWUzZDgiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJzLmthc2h5YXAua2FsYXdhZGVAZHMuZGV2LmFjY2VudHVyZS5jb20iLCJyaCI6IjAuQVNZQURoMGg4MXNTdzBLRzJ6SXJHYVphSW13UmtKRFVjRFJEZ1dKVG5yLWNKVGdtQUQ4LiIsInJvbGVzIjpbIkRlZmF1bHRfQWNjZXNzIl0sInN1YiI6IkRFdWJMd0Y1NDlJVTFHVnd0X1ZEaEN5V2NUUGR2YVdPQjFzNmVUQVZUQW8iLCJ0aWQiOiJmMzIxMWQwZS0xMjViLTQyYzMtODZkYi0zMjJiMTlhNjVhMjIiLCJ1dGkiOiJfN01aeWJvN1JVLURmTkQ1dWZWb0FBIiwidmVyIjoiMi4wIn0.Y5iKx41Y-0Flf2jvU6imNSwTFZIU-SwWEB_rLJi8lYtawHWkgGhYlwJdkbcYn1MhjSqJ43WxrtiWxjEFOBwmYRUV13ke-XGTBO9fzpEX2dL2Hv24P5dDPhCSNdV9NLIHEqQu81dss7HNvZrlz0birUw1UoTLIdAGv9j14FJWvf9oJWmN0_izSWDLEQwQo2Nk1EwChkM-H35zVTK69t7ZPLzOFLVuIB-rxsca6Y4ewVPdEKSo_W4w6Fa5gLLw3OwwAnlmYA_5irBZE3uFNiHHSyQbnCeFrbS41nAI0aednyojooWr-hgM3cw8d_4hus9f73owT6YN5fGwQqCdBslQmg',
        );
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(idTokenstring)}`;
      } else if(islogginsucessfull && sessionStorage.getItem(idTokenstring) != undefined){
        userTracker(TRACKING_MODULES.LOGIN, false)
          const token = sessionStorage.getItem(idTokenstring);
          const base64Url = token.split('.')[1];//Token is a combination of header.payload.signature extracting payload here
          const base64 = base64Url.replace('-', '+').replace('_', '/');
          const tokendetails = JSON.parse(window.atob(base64));
          const { preferred_username } = tokendetails;
          const { peoplekey } = tokendetails;
          const EnterpriseId = preferred_username.split('@')[0];
          sessionStorage.setItem('EnterpriseID', EnterpriseId);
          sessionStorage.setItem('peoplekey', peoplekey);
      }
      if(islogginsucessfull || isDevEnvironment){
        GetUserAccessDetails();
      }
    },[islogginsucessfull, isDevEnvironment])

    useEffect(() => {
      if (!isDevEnvironment &&!userData && inProgress === InteractionStatus.None) {
      refreshPBI();
      }
    }, [inProgress, userData]);

    return (
            <>
              <div id="sisenseApp">
              {userRole && !_.isEmpty(userRole) && <ParentContainer userRole={userRole}/>}
              </div>
            </>
    );
}
const mapDispatchToProps=(dispatch)=>{
  return {
  storeLoginUserData:(payload)=>dispatch(initializeUserAccountData(payload)),
  setSisenseScript : (sisenseData) => dispatch(setSisenseScript(sisenseData))
  };
}
const mapStateToProps=(state)=>{
  return {
    userLoginData:state.userLoginDataReducer
  }
}
let connectFunc=connect(
  mapStateToProps,
  mapDispatchToProps
)
export default compose(connectFunc)(App);