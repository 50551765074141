import React, { Component } from 'react';
import AddWOStep_MTC from "./AddWOStep_MTC";
import { store } from "../reducers/configureStore";
import fill_1 from "../Images/Fill97orc_nr.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faPencilAlt, faCog } from '@fortawesome/free-solid-svg-icons'
import { LocalApiBaseUrlImage } from "../Shared/Constant";
import SubStepPopUp from './SubStepPopUp';
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import i18n from "i18next";
import { EditorState, convertFromHTML, ContentState,
  Editor  } from 'draft-js';
var image = fill_1;


class WO_Details extends Component {
  constructor(props) {
    super(props)
    this.state = { editModal: false, type: "" ,highlightclass :"timeline-box",editSubStepModal:false, toggleDescPopUp: false};
  
    this.onClose = this.onClose.bind(this);
    this.editStep = this.editStep.bind(this);
    this.onSubStepClose = this.onSubStepClose.bind(this);
    this.toggleModalState = this.toggleModalState.bind(this);
  }

  componentDidUpdate(prevProps){
    if(this.props.woConfData !== prevProps.woConfData){
      {this.GetToolCategory();}
    }
  }

  editStep(type, e) {
    e.preventDefault();
    if(type === 'tile'){
        this.setState({ editModal: true, type: type })
    }else {
        this.setState({ editSubStepModal: true, type: type })
    }
  }
    
  onClose(e) {
    this.setState({ editModal: false,highlightclass:"timeline-box hoverOut" });
        
  } 

  onSubStepClose(e) {
    this.setState({ editSubStepModal: false});
  }

 cardMouseOut = (event) => {

   if (event.currentTarget.className != "timeline-box active clicked") {
     event.currentTarget.className = "timeline-box hoverOut";
     this.setState({highlightclass:"timeline-box hoverOut"})
     event.currentTarget.getElementsByTagName("img")[0].src = `${LocalApiBaseUrlImage}${this.props.generateData.SoCategoryImage}`;
   }

 }

 cardHover = (event) => {
   if ( event.currentTarget.className != "timeline-box active clicked") {
     event.currentTarget.className = "timeline-box active hoverIn";
     this.setState({highlightclass:"timeline-box active hoverIn"})
   }
 }

 GetToolCategory = () => {
   let ToolType = ['AA','AI','WF','AT']
   let mappedTools = this.props.generateData.WOStepTools;
   if(mappedTools != null)
   {
     if(mappedTools.length > 0){
       return ToolType.map((tool)=>{
         let classSpan =  mappedTools.filter((toolmapped)=>toolmapped.ToolType === tool).length ? "active" : "disable" 
                
         return <span className={classSpan}>{tool}</span>
       })
     }
   }
   else{
     return ToolType.map((tool)=>{
       return <span className="disable">{tool}</span>
     })
            
   }
 }

 showDescPopUp = (description, stepName) => {
  const blocksFromHTML = convertFromHTML(description)
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
    );
   const editorState = EditorState.createWithContent(
    contentState
    )
  return (
    <div onClick ={(e) => e.stopPropagation()}>
    <Modal
        show={this.state.toggleDescPopUp}
        onHide={this.toggleModalState}
        backdrop="static"
        size="medium"
        className="modal fade v-center bo-dashboard-modal"
        id="dealpopup"
      >
        <Modal.Header className="no-border" closeButton>
          <h5 class="modal-title" id="dealprofile">
            {i18n.t(stepName)}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <>
            <label className="infoModalLabel">{i18n.t("Description")}</label>
            <Editor className="infoModalText" editorState={editorState} readOnly={true} />
          </>
        </Modal.Body>
    </Modal>
    </div>
  )
 }

 toggleModalState = (event) => {
  if(event){
    event.stopPropagation();
  }
  this.setState((prevState) => {
    return {
      toggleDescPopUp: !prevState.toggleDescPopUp
    }
  })
 }

 render() {
   let {screen, index, main , no_per_row, generateData, roledata, card, filter,deleteStep, selectedStep, SubStepContainer,StepName, isScreenInEditMode, selectedSubStepID} = this.props;
   var base = (main + 1) - (no_per_row)
   var count = base + index + 1;
   const { highlightclass } = this.state;
   return ( 
            <div 
               className={screen == 'Dashboard' ? (generateData.IsActive == false ? `${highlightclass} disabled` : highlightclass)
                 : screen == 'Configurator' && generateData.IsActive == false ? `${highlightclass} conf-timeline-box disabled` : highlightclass}  
               onClick={screen !== 'SuperAdmin' ? (e)=>card(generateData.key,generateData.SOCategoryID) : null} 
               onMouseOver={screen !== 'SuperAdmin' ? null : this.cardHover} 
               onMouseOut={screen !== 'SuperAdmin' ? null : this.cardMouseOut}>
                <div className={generateData.SOCategoryID === selectedStep || generateData.SOCategoryID === selectedSubStepID ? "timeline-content timeline-active" : "timeline-content"}>
                <div className="timeline-data">
                    <div className="float-icon">
                        {roledata != undefined  && store.getState()
                          .CurrentFilter.languageData.id === 1 && roledata.includes("Delete") 
                        && <span onClick={()=>deleteStep(generateData.key)}>
                        <FontAwesomeIcon icon={faTrashAlt} /></span> 
                        }
                        {screen === 'SuperAdmin' ?
                        <span  onClick={(e)=>card(generateData.key,e)}><FontAwesomeIcon icon={faCog} /></span>
                          :<span><FontAwesomeIcon icon={faCog} /></span>
                        }
                        {roledata != undefined && roledata.includes("Edit") 
                        && (SubStepContainer ? 
                            <span onClick={(e) => this.editStep("SubSteptile", e)}>
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </span> :
                            <span onClick={(e) => this.editStep("tile", e)}>
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </span>
                            )
                        }
                    </div>
                    <span className="num-tools">{generateData.key}</span>
                    <div className="text-center"><img src={image} alt="" /></div>
                    <div className="metric-content">
                    <h6>{generateData.SOCategoryName}</h6>
                    <div className="rainbow-tools">
                    {screen !== 'SuperAdmin' && this.GetToolCategory()}
                    </div>
                    </div>                    
                  {(screen === 'Configurator' && generateData.ParentSOCategoryID !== 3 ) ? 
                  (this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") || (this.props.RecordStatus && this.props.RecordStatus[1] === "True" && !this.props.isManintencneWindowOpen) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Publish" && !this.props.isManintencneWindowOpen) || (this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Save" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Submit").length) { return each } }).length === 0) || (this.props.RecordStatus && this.props.RecordStatus[0] === "Submit" && this.props.roleReducers.filter((each) => { if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Publish").length) { return each } }).length === 0) ? "" :
                     <span class={generateData.IsActive ? "edit-step" : "edit-step disabled"} onClick={(e) => this.editStep("SubSteptile", e)}><FontAwesomeIcon icon={faPencilAlt} /></span>:null
                  }
                </div>
                {screen !== "SuperAdmin" &&
                <div className="timeline-overlay">
                  <p>{generateData.Comments}
                  </p>
                  {generateData.ParentSOCategoryID === 3 &&
                    <span className="link-button">View Details</span>}
                    {generateData.SOCategoryDesp && generateData.SOCategoryDesp.length ?
              <div className="work-orc-steps-tooltip">
              <div className='work-orc-steps-tooltip-container'>
                <span className='tooltip-icon' onClick={this.toggleModalState}>i</span>
                {this.state.toggleDescPopUp ? this.showDescPopUp(generateData.SOCategoryDesp, generateData.SOCategoryName) : null}
              </div> 
              </div>
               : null}
                </div>}
                </div>
                {this.state.editModal && this.state.type == "tile" 
                  ? <AddWOStep_MTC 
                      mode={"UPDATE"} 
                      Details={generateData} 
                      filter={filter} 
                      show={this.state.editModal} 
                      modalClose={this.onClose} 
                      getOfferingAPICallback={this.props.getOfferingAPICallback}
                      roledata={this.props.roledata} 
                      screen={"SuperAdmin"}

                    /> 
                  : null}
                {this.state.editSubStepModal && this.state.type === 'SubSteptile' ? 
                    <SubStepPopUp
                        Details={generateData}
                        StepName={StepName}
                        show={this.state.editSubStepModal}
                        modalClose={this.onSubStepClose}
                        isScreenInEditMode={isScreenInEditMode}
                        filter={filter}
                        getOfferingAPICallback={this.props.getOfferingAPICallback}
                        screen={screen}
                  />
                :null}
            </div>
   )
 }
}

const mapStateToProps = (state) => {
  return {
      roleReducers: state.roleReducers.roleReducers,      
      woConfData: state.WOtileReducers.tile,
  };
};

export default connect(mapStateToProps, "")(WO_Details);