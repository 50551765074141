import React, { useRef } from "react";
import DatePicker from "react-datepicker";
import _ from "lodash";
import moment from "moment";
import i18n from "i18next";

const TimelinesAndOutcomes = ({
  values,
  handleChange,
  handleBlur,
  errors,
  handleBlurFormField,
  handleChangeDateField,
  successStoryLinkProps,
}) => {
 
  const datepickerFieldsRefs = {
    plannedStartDate: useRef(null),
    actualStartDate: useRef(null),
    plannedEndDate: useRef(null),
    revisedEndDate: useRef(null),
    actualEndDate: useRef(null),
  };

  // OPENS UP THE DATEPICKER WHEN THE CALENDAR ICON IS CLICKED FOR THE INPUT FIELD
  function handleClickDatepickerIcon(fieldName) {
    const datepickerElement = datepickerFieldsRefs[fieldName].current;

    datepickerElement.setFocus(true);
  }

  const plannedStartDateMoment = !_.isEmpty(values.plannedStartDate) && moment(values.plannedStartDate);
  const actualStartDateMoment = !_.isEmpty(values.actualStartDate) && moment(values.actualStartDate);
  const plannedEndDateMoment = !_.isEmpty(values.plannedEndDate) && moment(values.plannedEndDate);
  const revisedEndDateMoment = !_.isEmpty(values.revisedEndDate) && moment(values.revisedEndDate);
  const actualEndDateMoment = !_.isEmpty(values.actualEndDate) && moment(values.actualEndDate);

  const plannedStartDateObj = !_.isEmpty(plannedStartDateMoment) && plannedStartDateMoment.toDate();
  const actualStartDateObj = !_.isEmpty(actualStartDateMoment) && actualStartDateMoment.toDate();
  const plannedEndDateObj = !_.isEmpty(plannedEndDateMoment) && plannedEndDateMoment.toDate();
  const revisedEndDateObj = !_.isEmpty(revisedEndDateMoment) && revisedEndDateMoment.toDate();
  const actualEndDateObj = !_.isEmpty(actualEndDateMoment) && actualEndDateMoment.toDate();

  // SET THE `maxDate` FOR "PLANNED START DATE" DATEPICKER BASED ON THE VALUES OF "PLANNED END DATE" AND "REVISED END DATE" IN SUCH A WAY THAT THE START DATE CANNOT BE SELECTED LATER THAN ANY OF THE END DATES IF ANY OF THE END DATE IS NOT EMPTY
  let maxDateMomentForPlannedStartDate = null;
  if (!_.isEmpty(plannedEndDateMoment) && _.isEmpty(revisedEndDateMoment)) {
    maxDateMomentForPlannedStartDate = plannedEndDateMoment;
  } else if (_.isEmpty(plannedEndDateMoment) && !_.isEmpty(revisedEndDateMoment)) {
    maxDateMomentForPlannedStartDate = revisedEndDateMoment;
  } else if (!_.isEmpty(plannedEndDateMoment) && !_.isEmpty(revisedEndDateMoment)) {
    maxDateMomentForPlannedStartDate = plannedEndDateMoment;
    if (revisedEndDateMoment.isBefore(plannedEndDateMoment)) {
      maxDateMomentForPlannedStartDate = revisedEndDateMoment;
    }
  }

  return (
    <div className="initiative-formtabcontent">
      <div className="initiative-formfields">
        <div className="row">
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="plannedStartDate" className="form-label">
                  {i18n.t("Planned_Start_Date")}&nbsp;<span className="required">*</span>
                </label>
                <div className="initiative-datepicker form-control">
                  <div className="datepicker-label-wrapper">
                    <DatePicker
                      selected={plannedStartDateObj}
                      onChange={(date) => handleChangeDateField("plannedStartDate", date)}
                      onBlur={() => handleBlurFormField(null, "plannedStartDate")}
                      dateFormat="MM/dd/yyyy"
                      strictParsing
                      className="form-control"
                      showMonthDropdown
                      showYearDropdown
                      placeholderText={`${i18n.t("Planned_Start_Date")} (mm/dd/yyyy)`}
                      ref={datepickerFieldsRefs["plannedStartDate"]}
                      maxDate={
                        !_.isEmpty(maxDateMomentForPlannedStartDate) &&
                        _.cloneDeep(maxDateMomentForPlannedStartDate)
                          .subtract(1, "days")
                          .toDate()
                      }
                    />
                    <span className="calender-placment" onClick={() => handleClickDatepickerIcon("plannedStartDate")}>
                      <i className="fal fa-calendar-alt" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {errors.plannedStartDate && <div className="errorMsg">{errors.plannedStartDate}</div>}
          </div>

          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="Remark" className="form-label">
                  {i18n.t("Initiative_Outcome_Remark")}
                  <span className="required">&nbsp;*</span>
                </label>
                <input
                  type="text"
                  id="initiativeOutcomeOrRemark"
                  name="initiativeOutcomeOrRemark"
                  value={values.initiativeOutcomeOrRemark}
                  onChange={handleChange}
                  onBlur={handleBlurFormField}
                  className="form-control"
                  placeholder={i18n.t("Initiative_Outcome_Remark")}
                  maxLength="150"
                />
              </div>
            </div>
            {errors.initiativeOutcomeOrRemark && <div className="errorMsg">{errors.initiativeOutcomeOrRemark}</div>}
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="actualStartDate" className="form-label">
                  {i18n.t("Actual_Start_Date")}
                </label>
                <div className="initiative-datepicker form-control">
                  <div className="datepicker-label-wrapper">
                    <DatePicker
                      selected={actualStartDateObj}
                      onChange={(date) => handleChangeDateField("actualStartDate", date)}
                      onBlur={() => handleBlurFormField(null, "actualStartDate")}
                      dateFormat="MM/dd/yyyy"
                      strictParsing
                      className="form-control"
                      showMonthDropdown
                      showYearDropdown
                      placeholderText={`${i18n.t("Actual_Start_Date")} (mm/dd/yyyy)`}
                      ref={datepickerFieldsRefs["actualStartDate"]}
                      maxDate={
                        !_.isEmpty(actualEndDateMoment) &&
                        _.cloneDeep(actualEndDateMoment)
                          .subtract(1, "days")
                          .toDate()
                      }
                    />
                    <span className="calender-placment" onClick={() => handleClickDatepickerIcon("actualStartDate")}>
                      <i className="fal fa-calendar-alt" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {errors.actualStartDate && <div className="errorMsg">{errors.actualStartDate}</div>}
          </div>

          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="Alert" className="form-label">
                  {i18n.t("Escalation_or_Alert_Email_Id")}
                </label>
                <input
                  type="text"
                  id="escalationEmail"
                  name="escalationEmail"
                  value={values.escalationEmail}
                  onChange={handleChange}
                  onBlur={handleBlurFormField}
                  className="form-control"
                  placeholder={i18n.t("Escalation_or_Alert_Email_Id")}
                  maxLength="100"
                />
                <span className="suffix-text">@accenture.com</span>
              </div>
            </div>
            {errors.escalationEmail && <div className="errorMsg">{errors.escalationEmail}</div>}
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="plannedEndDate" className="form-label">
                  {i18n.t("Planned_End_Date")}
                  <span className="required">&nbsp;*</span>
                </label>
                <div className="initiative-datepicker form-control">
                  <div className="datepicker-label-wrapper">
                    <DatePicker
                      selected={plannedEndDateObj}
                      onChange={(date) => handleChangeDateField("plannedEndDate", date)}
                      onBlur={() => handleBlurFormField(null, "plannedEndDate")}
                      dateFormat="MM/dd/yyyy"
                      strictParsing
                      className="form-control"
                      showMonthDropdown
                      showYearDropdown
                      placeholderText={`${i18n.t("Planned_End_Date")} (mm/dd/yyyy)`}
                      ref={datepickerFieldsRefs["plannedEndDate"]}
                      minDate={
                        !_.isEmpty(plannedStartDateMoment) &&
                        _.cloneDeep(plannedStartDateMoment)
                          .add(1, "days")
                          .toDate()
                      }
                    />
                    <span className="calender-placment" onClick={() => handleClickDatepickerIcon("plannedEndDate")}>
                      <i className="fal fa-calendar-alt" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {errors.plannedEndDate && <div className="errorMsg">{errors.plannedEndDate}</div>}
          </div>

          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="Link" className="form-label">
                  {i18n.t("Link_to_report_out")}
                </label>
                <input
                  type="text"
                  id="reportLink"
                  name="reportLink"
                  value={values.reportLink}
                  onChange={handleChange}
                  onBlur={handleBlurFormField}
                  className="form-control"
                  placeholder={i18n.t("Link_to_report_out")}
                  maxLength="5000"
                />
              </div>
            </div>
            {errors.reportLink && <div className="errorMsg">{errors.reportLink}</div>}
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="revisedEndDate" className="form-label">
                  {i18n.t("Revised_End_Date")}
                </label>
                <div className="initiative-datepicker form-control">
                  <div className="datepicker-label-wrapper">
                    <DatePicker
                      selected={revisedEndDateObj}
                      onChange={(date) => handleChangeDateField("revisedEndDate", date)}
                      onBlur={() => handleBlurFormField(null, "revisedEndDate")}
                      dateFormat="MM/dd/yyyy"
                      strictParsing
                      className="form-control"
                      showMonthDropdown
                      showYearDropdown
                      placeholderText={`${i18n.t("Revised_End_Date")} (mm/dd/yyyy)`}
                      ref={datepickerFieldsRefs["revisedEndDate"]}
                      minDate={
                        !_.isEmpty(plannedStartDateMoment) &&
                        _.cloneDeep(plannedStartDateMoment)
                          .add(1, "days")
                          .toDate()
                      }
                    />
                    <span className="calender-placment" onClick={() => handleClickDatepickerIcon("revisedEndDate")}>
                      <i className="fal fa-calendar-alt" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {errors.revisedEndDate && <div className="errorMsg">{errors.revisedEndDate}</div>}
          </div>

          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="successStoryLink" className="form-label">
                  {i18n.t("Link_to_Success_Story")}
                  {successStoryLinkProps.isRequired && <span className="required">&nbsp;*</span>}
                </label>
                <input
                  type="text"
                  id="successStoryLink"
                  name="successStoryLink"
                  value={values.successStoryLink}
                  onChange={handleChange}
                  onBlur={handleBlurFormField}
                  className="form-control"
                  placeholder={i18n.t("Link_to_Success_Story")}
                  maxLength="5000"
                />
              </div>
            </div>
            {errors.successStoryLink && <div className="errorMsg">{errors.successStoryLink}</div>}
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="actualEndDate" className="form-label">
                  {i18n.t("Actual_End_Date")}
                </label>
                <div className="initiative-datepicker form-control">
                  <div className="datepicker-label-wrapper">
                    <DatePicker
                      selected={actualEndDateObj}
                      onChange={(date) => handleChangeDateField("actualEndDate", date)}
                      onBlur={() => handleBlurFormField(null, "actualEndDate")}
                      dateFormat="MM/dd/yyyy"
                      strictParsing
                      className="form-control"
                      showMonthDropdown
                      showYearDropdown
                      placeholderText={`${i18n.t("Actual_End_Date")} (mm/dd/yyyy)`}
                      ref={datepickerFieldsRefs["actualEndDate"]}
                      minDate={
                        !_.isEmpty(actualStartDateMoment) &&
                        _.cloneDeep(actualStartDateMoment)
                          .add(1, "days")
                          .toDate()
                      }
                    />
                    <span className="calender-placment" onClick={() => handleClickDatepickerIcon("actualEndDate")}>
                      <i className="fal fa-calendar-alt" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {errors.actualEndDate && <div className="errorMsg">{errors.actualEndDate}</div>}
          </div>

          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="fteBenefit" className="form-label">
                  {i18n.t("FTE_Benefit")}
                </label>
                <input
                  type="text"
                  id="fteBenefit"
                  name="fteBenefit"
                  value={values.fteBenefit}
                  onChange={handleChange}
                  onBlur={handleBlurFormField}
                  className="form-control"
                  placeholder={i18n.t("FTE_Benefit")}
                  maxLength="10"
                />
                <span className="suffix-text centerEle">FTE</span>
              </div>
            </div>
            {errors.fteBenefit && <div className="errorMsg">{errors.fteBenefit}</div>}
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-section">
              <div className="form-group border-group">
                <label htmlFor="costBenefit" className="form-label">
                  {i18n.t("Cost_Benefit")}
                </label>
                <input
                  type="text"
                  id="costBenefit"
                  name="costBenefit"
                  value={values.costBenefit}
                  onChange={handleChange}
                  onBlur={handleBlurFormField}
                  className="form-control"
                  placeholder={i18n.t("Cost_Benefit")}
                  maxLength="10"
                />
                <span className="suffix-text centerEle">$</span>
              </div>
            </div>
            {errors.costBenefit && <div className="errorMsg">{errors.costBenefit}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimelinesAndOutcomes;
