import React from "react";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import _ from "lodash";
import { LocalApiBaseUrl, regexForNumbersOnlyMTC,regexForNegativeNumbers } from "../Shared/Constant";
import i18n from "i18next";
import { store } from "../reducers/configureStore";
import { trycatchAlertPopup } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import KpiPopUp from "../Configurator/KpiPopUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { regexForTextAreas } from "../Shared/Constant";
import { FormatDate,isValidText,InvalidDescription } from "../Shared/commonFunctions";
import { MetricValueThousandFormat, thousandSeparatorFormat } from "../Shared/Common";
import DatePicker from "react-datepicker";


export default class DiversePopUp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: this.props.show,
			showCategory: false,
			apivalue: "",
			showaddCategory: false,
			iscatypedisable: false,
			isEdit: false,
			showaddCategoryType: false,
			showApproval: false,
			selectValue: this.props.SelectValue,
			selectDropValue: "",
			selectCattypeValue: "",
			Display: false,
			EditIndex: 0,
			categories: [],
			categoriesData: [],
			CategoryDetails: { CategoryType: "", CategoryName: "" },
			MetricNameOptions: [],
			commentsError: "",
			LeadingAndSimilarCheck: false,
			viewmore: false,
			CatNameID: 0,
			CatTypeID: 0,
			numeratorVal: 0,
			FormulaList: [],
			denomVal: 0,
			EditIndex: 0,
			formIsValid: false,
			isAddCatPopup: false,
			valueConnectorEdit: false,
			valueConnector:false,
			allCategoryTypesAndNames: [],
			CategoryNameData: [],
			categoriesLimit:30, // Default,
			diverseData: {
				formulaStr: '',
				
				KPIMappingID: 0,
				MetricSeq: "",
				MetricID: "",
				MetricName: "",
				LeadingPractice: "",
				KpiMetricConnectorID: false,
				
				FrequencyID: "",
				MetricUnit: "",
				MetricImage: "",
				KPIDesp: "",
				MetricDataID: "",
				MetricDataDesp: "",
				MetricValue: "",
				RAGStatus: "",
				UpperThreshold: "",
				LowerThreshold: "",
				BaselineValue: "",
				BaseLineMonth: "",
				TargetValue: "",
				Month: "",
				ConnectorID: "",
				ConnectorNames: "",
				SourceFieldName: "",
				SourceName: "",
				IsAccentureOwned: false,
				metDataAttr: [
					{
						MetricAttributeID: "",
						MetricDataID: "",
						AttributeName:
							this.props.attributes.length > 0
								? (this.props.attributes.filter(z => z.ApplicableForDD === true).length > 0 ? this.props.attributes.filter(z => z.ApplicableForDD === true)[0].AttributeName1 : "")
								: "",
						AttributeValue: "",
						CreatedUser: null,
						CreatedTS: "",
						UpdatedUser: null,
						UpdatedTS: "",
					},
					{
						MetricAttributeID: 304,
						MetricDataID: 1275,
						AttributeName:
							this.props.attributes.length > 0
								? (this.props.attributes.filter(z => z.ApplicableForDD === true).length > 0 ? this.props.attributes.filter(z => z.ApplicableForDD === true)[0].AttributeName2 : "")
								: "",
						AttributeValue: "",
						CreatedUser: null,
						CreatedTS: "0001-01-01T00:00:00",
						UpdatedUser: null,
						UpdatedTS: "0001-01-01T00:00:00",
					},
				],
				MetricDefination: [],
				MetricDefinitionID: "",
				CategoryDetails: [],
				IsMultiLevel: false,
				IsAggregation: false,
				IndustryName: "",
				IndustryID: "",
				IsGlobal: true,
				Comments: "",
				//  }
				//]
			},


			formIsValid: false,
			errors: {
				
			},
			valueExist: false,
			BaselineMonthReadOnly: true,
		};

		this.onNameChange = this.onNameChange.bind(this);
		this.onDescriptionChange = this.onDescriptionChange.bind(this);
		this.onSimilarChange = this.onSimilarChange.bind(this);
		this.onLeadingChange = this.onLeadingChange.bind(this);
		this.onValueDescriptionChange = this.onValueDescriptionChange.bind(this);
		this.onUnitChange = this.onUnitChange.bind(this);
		this.onActualChange = this.onActualChange.bind(this);
		this.onStatusChange = this.onStatusChange.bind(this);
		this.handleHide = this.handleHide.bind(this);
		this.handleRejectHide = this.handleRejectHide.bind(this);
		this.handleApproveHide = this.handleApproveHide.bind(this);
		this.handleValidation = this.handleValidation.bind(this);
		this.handleDropdownChange = this.handleDropdownChange.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.getGetConnectorMaster = this.getGetConnectorMaster.bind(this);
		this.onToolOwnerChange = this.onToolOwnerChange.bind(this);
		this.onSourceChange = this.onSourceChange.bind(this);
		this.onSourceSystemChange = this.onSourceSystemChange.bind(this);
		this.onOtherSystemChange = this.onOtherSystemChange.bind(this);
		//MULTI LEVEL
		this.handleApprovalClose = this.handleApprovalClose.bind(this);
		this.handleCategoryClose = this.handleCategoryClose.bind(this);
		this.handleCategoryShow = this.handleCategoryShow.bind(this);
		this.handleaddCategoryNameClose = this.handleaddCategoryNameClose.bind(this);
		this.handleaddCategoryClose = this.handleaddCategoryClose.bind(this);
		this.CatTypeSelect = this.CatTypeSelect.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleaddCategoryShow = this.handleaddCategoryShow.bind(this);
		this.handleaddCategoryAdd = this.handleaddCategoryAdd.bind(this);
		this.handleCategoryEditShow = this.handleCategoryEditShow.bind(this);
		this.editCategoryName = this.editCategoryName.bind(this);
		this.handleCategoryEdit = this.handleCategoryEdit.bind(this);
		this.handleDropdownChange = this.handleDropdownChange.bind(this);
		this.deleteCategoryType = this.deleteCategoryType.bind(this);
		this.handleCategoryAdd = this.handleCategoryAdd.bind(this);
		this.handleCategoryTypeAddShow = this.handleCategoryTypeAddShow.bind(this);
		this.deleteCategoryName = this.deleteCategoryName.bind(this);
		this.onBaselineValueChange = this.onBaselineValueChange.bind(this);
		this.onBaseLineMonthChange = this.onBaseLineMonthChange.bind(this);
		this.onTargetValueChange = this.onTargetValueChange.bind(this);
		let offeringValue = "";
		let value1 = [];
		let isConnectorMetricDataFound = true;
		let isSelectedMetricMappedToConnector = false;
		let selectedMetricConnectorLastUpdated;
	}

	onClose(e) {
		this.setState({ viewmore: false });
		this.props.onClose(e);
	}

	

	getCategoriesLimit = () =>{
        let data = store.getState().CurrentFilter.multiLevelCategoriesLimit;
        this.setState({
          categoriesLimit:data
        })
    }

	componentDidMount() {
		this.getGetConnectorMaster();
		this.getKpiMAppingDetails();
		this.getCategoriesLimit();
	}
	onBaselineValueChange(event) {
		const { diverseData } = this.state;
		const kpiData = diverseData;
		if (event.target.value === "") {
		  kpiData.BaselineValue = event.target.value;
		  kpiData.BaseLineMonth = "";
		  this.setState({
			diverseData: kpiData,
			BaselineMonthReadOnly: true
		  });
		} else {
		  if (regexForNegativeNumbers.test(event.target.value)) {
			if (event.target.value === "-.") {
			  kpiData.BaselineValue = "-0.";
			} else {
			  kpiData.BaselineValue = event.target.value;
			}
			this.setState({
			  diverseData: kpiData,
			  BaselineMonthReadOnly: false
			});
		  }
		}
	  }
	  onBaseLineMonthChange = (date) => {
	  const kpiData = this.state.diverseData;
	  kpiData.BaseLineMonth=date;
		this.setState({
		  diverseData: kpiData,
		})
	  }
	  onTargetValueChange(event) {
		const { diverseData } = this.state;
		const kpiData = diverseData;
		if (event.target.value === "") {
		  kpiData.TargetValue = event.target.value;
		  this.setState({
			diverseData: kpiData,
		  });
		} else {
		  if (regexForNumbersOnlyMTC.test(event.target.value)) {
			if (event.target.value === "-.") {
			  kpiData.TargetValue = "-0.";
			} else {
			  kpiData.TargetValue = event.target.value;
			}
			this.setState({
			  diverseData: kpiData,
			});
		  }
		}
	  }
	getGetConnectorMaster() {
		let source = [];
		axiosInstance
			.get(`${LocalApiBaseUrl}ConnectorMapping/GetConnectorMaster`)
			.then((response) => {
				source = response.data;
				let sourceValues = source.map((x) => (
					<option
						key={x.ConnectorID}
					value={x.ConnectorID}
					>
						{x.ConnectorName}
					</option>
				));
				this.setState({ sourceOptions: sourceValues });
			});
	}
	async getKpiMAppingDetails() {
		let samp = {};
		let valueConnectorEdit = false;
		let mappedConnectorName;
		let kpiData = this.props.editDiverse;
		return new Promise((resolve, reject) => {
			axiosInstance
				.get(`${LocalApiBaseUrl}Configurator/GetKpiMapping`, {
					params: {
						OrgEntityID: this.props.filterDetails.Client,
						LocationID: this.props.filterDetails.Locations,
						OfferingID: this.props.filterDetails.process,
						SOCategoryID: this.props.SOCategoryID,
						MonthDT: this.props.filterDetails.Month
					},
				})
				.then((response) => {
					let mappingData = response.data;
					let isaccentureOwned = false;
					if (mappingData.length > 0) {


						let FormulaList = this.state.FormulaList;
						FormulaList = mappingData[0].FormulaList;
						
						this.setState({
							allCategoryTypesAndNames: mappingData[0].CategoryList,
							FormulaList: FormulaList
						});

						//this.setState({ allCategoryTypesAndNames: mappingData[0].CategoryList });

					}
					if (mappingData) {
						if (this.props.kpiData) {

							let mappedConnector = mappingData.filter(
								x => x.KpiMappingID == this.props.kpiData.KPIMappingID
							);
							if (mappedConnector.length > 0) {
								this.setState({
									KpiMetricConnectorID: mappedConnector[0].KpiMetricConnectorID != 0 ? true : false,
									isaccentureOwned:mappedConnector[0].IsAccentureOwned
								})
								this.isConnectorMetricDataFound = mappedConnector[0].IsConnectorMetricDataFound;
								this.isSelectedMetricMappedToConnector = mappedConnector[0].IsConnector;
								this.selectedMetricConnectorLastUpdated = mappedConnector[0].ConnectorUpdatedTS;

								if (this.props.kpiData && ((this.props.kpiData.ConnectorID == undefined || parseInt(this.props.kpiData.ConnectorID) === 0)
									|| (this.props.kpiData.ConnectorNames == null || this.props.kpiData.ConnectorNames == undefined))) {
									if (mappedConnector.length > 0 && mappedConnector[0].ConnectorName != "" && mappedConnector[0].ConnectorName !=null) {
										mappedConnectorName = mappedConnector[0].ConnectorName;
										let mappedConnectID = mappedConnector[0].ConnectorID;

										this.props.kpiData.ConnectorNames = mappedConnectorName;
										this.props.kpiData.ConnectorID = mappedConnectID;
										if (this.state.diverseData) {
											let kpiData = this.state.diverseData;
											kpiData.ConnectorNames = (mappedConnectorName != null && mappedConnectorName != undefined ? mappedConnectorName : kpiData.ConnectorNames);
											kpiData.ConnectorID = (mappedConnectID > 0 ? mappedConnectID : kpiData.ConnectorID);
											this.setState({
												diverseData: kpiData
											})
										}
									}
								}
							}

							if (mappedConnectorName != null) {
								valueConnectorEdit = true;
							}
						}

						if (this.props.editDiverse) {

							let kpiData = { ...this.props.editDiverse };

						

							let editDiverse =  JSON.parse(JSON.stringify(this.props.editDiverse)) // Add this to avoid mutation
							let diverseEdit = editDiverse.filter(
								(x) => x.ParentSOCategoryID == "5"
							)[0].kpiData;
							let diverseData = diverseEdit.filter(
								(x) => x.KPIMappingID == this.props.kpiID
							)[0];

							if(diverseData.CategoryDetails)
							{
								if (diverseData.CategoryDetails.length === 0)
								diverseData.IsMultiLevel = false;
							}
							
							let diversecat = diverseData.CategoryDetails && diverseData.CategoryDetails.length > 0 ? diverseData.CategoryDetails[0] : 0
							let CatTypeID = diversecat ? diversecat.OfferingMultiLevelCategoryID : 0;

							let CatType = diversecat ? diversecat.CategoryType : "";



							let CategoryNameData = (CatType && CatType != "") ? this.state.allCategoryTypesAndNames.filter(
								eachValue => eachValue.CategoryType == CatType
							) : [];//FETCHING CATEGORY NAME


							let CategoryDetails = this.state.CategoryDetails
							CategoryDetails.CategoryType = CatType
							this.setState({
								...this.state,
								CategoryNameData: CategoryNameData,
								CategoryDetails: CategoryDetails,
								BaselineMonthReadOnly: diverseData.BaselineValue ? false : true,
							})



							let LeadingAndSimilarCheck = false;
							
							let mappedConnector = mappingData.filter(
								(x) => {
									if (x.KpiMappingID == diverseData.KPIMappingID) {
										return x
									}
								}
							);
							if (diverseData.ConnectorID == 0) {
								mappedConnectorName = mappedConnector[0].ConnectorName;
								diverseData.ConnectorNames = mappedConnectorName;
								let mappedConnectID = mappedConnector[0].ConnectorID;
								diverseData.ConnectorID = mappedConnectID;

							} else if (mappedConnector.length > 0 && mappedConnector[0].ConnectorName) {
								
								mappedConnectorName = mappedConnector[0].ConnectorName;
								diverseData.ConnectorNames = mappedConnectorName;
								let mappedConnectID = mappedConnector[0].ConnectorID;
								diverseData.ConnectorID = mappedConnectID;
								if (this.state.data) {
									let kpiData = this.state.data.kpiData;
									kpiData.ConnectorNames = mappedConnectorName;
									kpiData.ConnectorID = mappedConnectID
									this.setState({
										kpiData: kpiData,
										KpiMetricConnectorID: mappedConnector[0].KpiMetricConnectorID != 0 ? true : false,
										   isaccentureOwned:mappedConnector[0].IsAccentureOwned
									})
								}
							} else {
								mappedConnectorName = kpiData.ConnectorNames;

								kpiData.ConnectorNames = mappedConnectorName;
							}

						

							if (diverseData.MetricDefination && diverseData.MetricDefination.length > 0 &&
								diverseData.MetricDefination.filter(
									(x) => String(x.KPIMetricDefinatoinID) === String(diverseData.MetricDefinitionID))
									.length === 0) {
								diverseData.MetricDefinitionID = 0;

							}




							if (diverseData && response.data.length) {
								response.data.map((each) => {
									if (
										each.KpiMappingID === diverseData.KPIMappingID &&
										diverseData.metDataAttr && diverseData.metDataAttr.length
									) {
										if (each.Attribute1Value) {
											diverseData.metDataAttr[0].AttributeValue =
												each.Attribute1Value;
											diverseData.metDataAttr[1].AttributeValue =
												each.Attribute2Value;
											LeadingAndSimilarCheck = true;
										}
									}
								});
							}
							this.setState({
								...this.state,
								CatTypeID: CatTypeID,
								iscatypedisable: true,
								diverseData: diverseData,
								LeadingAndSimilarCheck: LeadingAndSimilarCheck,
								KpiMetricConnectorID: mappedConnector[0].KpiMetricConnectorID != 0 ? true : false,
								   isaccentureOwned:mappedConnector[0].IsAccentureOwned
							});

	if (mappedConnectorName != null) {
								valueConnectorEdit = true;
							}
						}

      this.setState({
        ...this.state,
        MetricNameOptions: response.data, //response.data,
        valueConnectorEdit,
      });
    } else {
      if (editDiverse) {
        let editDiverse = [...this.props.editDiverse];
        let diverseEdit = editDiverse.filter((x) => parseInt(x.ParentSOCategoryID) === "5")[0].kpiData;
        let diverseData = diverseEdit.filter((x) => String(x.KPIMappingID) === String(kpiID))[0];
        let valueExist = true;
        this.setState(
          {
            diverseData,
            errors: {},
            formIsValid: true,
            valueExist: valueExist,
							}, () => {
								resolve();
							});
						}
					}
				});
		})
	}
  handleValidation() {

    let diverseData = { ...this.state.diverseData };
    let errordescription=InvalidDescription();
    let errors = {};
    let formIsValid = true;
    let err = "";

    if(this.state.diverseData.Comments){
      if(this.state.diverseData.Comments.length>300){
        err = i18n.t("commentsErrorM1");
      formIsValid  = false;
      }
      else{
        if(!isValidText(this.state.diverseData.Comments)){
          err = errordescription;
          formIsValid  = false;
        }
      }
      this.setState({
         commentsError: err
      });
    }
    if (this.state.diverseData.SourceFieldName) 
    {
      if(!isValidText(this.state.diverseData.SourceFieldName)){
        formIsValid = false;
        errors["SourceFieldName"] = errordescription;
      }
    }

    if (!diverseData["MetricName"]) {
      formIsValid = false;
      errors["MetricName"] = i18n.t("addWoErrorM1");
    }
    if (this.isSelectedMetricMappedToConnector && !this.isConnectorMetricDataFound) {
      formIsValid = false;
      errors["MetricConnectorDataNotFound"] = i18n.t("Data_not_available_in_source_system") + ".";
      errors["IntegratedMetricsMessage"] = i18n.t("Integrated_metrics_will_get_auto_populated") + ".";
      if (this.selectedMetricConnectorLastUpdated) {
        errors["MetricConnectorLastPull"] =
          i18n.t("Last_pull_occurred_on") + " " + FormatDate(this.selectedMetricConnectorLastUpdated) + ".";
      } else {
        errors["MetricConnectorLastPull"] = i18n.t("Last_pull_occurred") + ' "' + i18n.t("Date_Unavailable") + "”.";
      }
    }

	
		if (this.state.commentsError) {
			formIsValid = false;
		}

		if (!diverseData["MetricUnit"]) {
			formIsValid = false;
			errors["MetricUnit"] = i18n.t("Unit_is_required");
		}




		if (!diverseData["RAGStatus"]) {
			formIsValid = false;
			errors["RAGStatus"] = i18n.t("Status_is_Required");
		}

		if (!diverseData["ConnectorID"]) {
			formIsValid = false;
			errors["ConnectorID"] = i18n.t("Source_is_required");
		}
		if (!this.isSelectedMetricMappedToConnector) {
			if (!diverseData["MetricValue"] && diverseData["MetricValue"] !== 0) {
				formIsValid = false;
				errors["MetricValue"] = i18n.t("Actual_Value_is_Required");
			} else if (
				!(diverseData["MetricValue"] >= -999999999999 && diverseData["MetricValue"] <= 999999999999)
			) {
				formIsValid = false;
				errors["MetricValueRange"] = i18n.t("addBoErrorM6");
			}
		}
		
		if (!(diverseData["BaselineValue"] >= -99999999 && diverseData["BaselineValue"] <= 99999999)) {
			formIsValid = false;
			errors["BaselineValue"] = i18n.t("Baseline_validation");
		  }
	  
		  if (!(diverseData["TargetValue"] >= -99999999 && diverseData["TargetValue"] <= 99999999)) {
			formIsValid = false;
			errors["TargetValue"] = i18n.t("target_validation");
		  }

		// VALIDATE "METRIC DEFINITION" DROPDOWN FIELD
		if (diverseData.MetricDefination && diverseData.MetricDefination.length > 0 && !diverseData["MetricDefinitionID"]) {
			errors["MetricDefinitionID"] = "Metric Definition is Required";
			formIsValid = false;
		}
		if (diverseData["IsMultiLevel"]) {
			if (diverseData.CategoryDetails.length === 0) {
				errors["MultiLevel"] = i18n.t("noCategories");
				formIsValid = false;

			}
		}
		this.setState({
			errors: errors,
		});
		if (formIsValid) {
			this.setState({
				formIsValid: true,
			});
		} else {
			this.setState({
				formIsValid: false,
			});
		}
		return formIsValid;
	}

	onStatusChange(event) {
		let errors = {};
		this.setState({
			errors: errors,
		});
		const diverseData = { ...this.state.diverseData };
		diverseData.RAGStatus = event.target.value;
		this.setState({
			diverseData: diverseData,
		});
		// this.validateForm();
	}

	onToolOwnerChange(event) {
		const diverseData = { ...this.state.diverseData };
		diverseData.IsAccentureOwned = event.target.checked;
		this.setState({
			diverseData: diverseData,
		});
	}

	onNameChange(event) {
		if (!event.target.value) {
			let errors = {};
			const diverseData = { ...this.state.diverseData };
			diverseData.KPIMappingID = event.target.value;
			diverseData.SourceName = "";
			diverseData.SourceFieldName = "";
			let LeadingAndSimilarCheck = false;
			diverseData.KPIDesp = "";
			diverseData.MetricUnit = "";
			diverseData.MetricName = "";
			diverseData.MetricValue = "";
			diverseData.LeadingPractice = "";
			diverseData.IsMultiLevel = false;
			diverseData.IsAggregation = false;
	
			this.isConnectorMetricDataFound = true;
			this.isSelectedMetricMappedToConnector = false;
			this.selectedMetricConnectorLastUpdated = undefined;
			if (diverseData.metDataAttr && diverseData.metDataAttr.length > 0) {
				diverseData.metDataAttr[0].AttributeValue = "";
				diverseData.metDataAttr[1].AttributeValue = "";
			}
			// RESET THE METRIC DEFINITION DROPDOWN OPTIONS AND SELECTED METRIC DEFINITION
			diverseData.MetricDefination = [];
			diverseData.MetricDefinitionID = "";

			const kpiMetricDefinitions = _.get(
				this.state,
				"diverseData.MetricDefination",
				[]
			);
			const enabledMetricDefinitions = _.filter(
				kpiMetricDefinitions,
				(eachMetricDefinition) =>
					String(eachMetricDefinition.IsEnabled) === "true"
			);

			if (enabledMetricDefinitions.length === 0) {
				diverseData.MetricDefinitionID = -1;
			}

			this.setState({
				errors: errors,
				diverseData: diverseData,
			});
		} else {
			let errors = {};
			this.setState({
				errors: errors,
			});
			const diverseData = { ...this.state.diverseData };
			diverseData.IsMultiLevel = false;
			diverseData.IsAggregation = false;
			diverseData.CategoryDetails = [];

			diverseData.KPIMappingID = event.target.value;
			diverseData.SourceName = "";
			diverseData.SourceFieldName = "";
			diverseData.MetricValue = "";//CHANGED
			let LeadingAndSimilarCheck = false;
			if (diverseData.KPIMappingID !== "-1") {
				let selectedMetric = this.state.MetricNameOptions.filter(
					data => data.KpiMappingID == event.target.value
				)[0];
				this.isConnectorMetricDataFound = selectedMetric.IsConnectorMetricDataFound;
				this.isSelectedMetricMappedToConnector = selectedMetric.IsConnector;
				this.selectedMetricConnectorLastUpdated = selectedMetric.ConnectorUpdatedTS;
				diverseData.MetricValue=selectedMetric.IsConnector ?selectedMetric.MetricValue:"";
				diverseData.KPIDesp = this.state.MetricNameOptions.filter(
					(data) => data.KpiMappingID == event.target.value
				)[0].MetricDescription;
				diverseData.MetricUnit = this.state.MetricNameOptions.filter(
					(data) => data.KpiMappingID == event.target.value
				)[0].MetricUnit;
				diverseData.MetricName = this.state.MetricNameOptions.filter(
					(data) => data.KpiMappingID == event.target.value
				)[0].MetricName;
				diverseData.LeadingPractice = this.state.MetricNameOptions.filter(
					(data) => data.KpiMappingID == event.target.value
				)[0].LeadingPractice;
				diverseData.IndustryName = this.state.MetricNameOptions.filter(
					(data) => data.KpiMappingID == event.target.value
				)[0].IndustryName; // Diverse Benchmark
				diverseData.IsGlobal = this.state.MetricNameOptions.filter(
					(data) => data.KpiMappingID == event.target.value
				)[0].IsGlobal
				diverseData.BaselineValue = this.state.MetricNameOptions.filter(
					(data) => data.KpiMappingID == event.target.value
				)[0].BaselineValue;
				diverseData.BaseLineMonth = this.state.MetricNameOptions.filter(
					(data) => data.KpiMappingID == event.target.value
				)[0].BaseLineMonth;
				let BaselineMonthReadOnly=diverseData.BaselineValue?false:true;
				this.setState({BaselineMonthReadOnly:BaselineMonthReadOnly})
				diverseData.TargetValue = this.state.MetricNameOptions.filter(
					(data) => data.KpiMappingID == event.target.value
				)[0].TargetValue;


				if (diverseData.metDataAttr && diverseData.metDataAttr.length > 0) {
					diverseData.metDataAttr[0].AttributeValue = this.state.MetricNameOptions.filter(
						(data) => data.KpiMappingID == event.target.value
					)[0].Attribute1Value;
					diverseData.metDataAttr[1].AttributeValue = this.state.MetricNameOptions.filter(
						(data) => data.KpiMappingID == event.target.value
					)[0].Attribute2Value;
					if (diverseData.metDataAttr[0].AttributeValue) {
						LeadingAndSimilarCheck = true;
					}
				}

				// SET KPI MULTIPLE DEFINITIONS
				diverseData.MetricDefination = this.state.MetricNameOptions.filter(
					(data) => data.KpiMappingID == event.target.value
				)[0].MetricDefination;

				const kpiMetricDefinitions = diverseData.MetricDefination;
				const enabledMetricDefinitions = _.filter(
					kpiMetricDefinitions,
					(eachMetricDefinition) =>
						String(eachMetricDefinition.IsEnabled) === "true"
				);

				// RESET THE SELECTED METRIC DEFINITION
				diverseData.MetricDefinitionID = "";

				if (enabledMetricDefinitions.length === 0) {
					diverseData.MetricDefinitionID = -1;
				}
			} else {
				this.setState({
					viewmore: true,
					show: false,
				});
			}
			let con = "";

			let metriName = this.state.MetricNameOptions.filter(
				(eachValue) => eachValue.KpiMappingID == event.target.value
			);
			  let valueConnector = false;
			if (metriName.length > 0) {
				let connectorName = metriName[0].ConnectorName;
				let connectorId = metriName[0].ConnectorID;
				let criteria = metriName[0].Criteria;
				let MetricValue=metriName[0].MetricValue;
                let IsAccentureOwned=metriName[0].IsAccentureOwned;
                let KpiMetricConnectorID=metriName[0].KpiMetricConnectorID;

				if (connectorName == null) {
					con = false;
					diverseData.ConnectorNames = connectorName;
					diverseData.ConnectorID = connectorId;
					diverseData.SourceFieldName = criteria;
					diverseData.MetricValue="";
					diverseData.IsAccentureOwned=IsAccentureOwned;
					diverseData.KpiMetricConnectorID=KpiMetricConnectorID;
				} else {
					con = true;
					diverseData.ConnectorNames = connectorName;
					diverseData.ConnectorID = connectorId;
					diverseData.SourceFieldName = criteria;
					diverseData.MetricValue=MetricValue;
					diverseData.IsAccentureOwned=IsAccentureOwned;
					diverseData.KpiMetricConnectorID = KpiMetricConnectorID;

					if (
						metriName[0].IsConnector == true &&
						metriName[0].IsTransaction == false
					) {
						valueConnector = true;
					}
				}
			}

			let connectorOptions = metriName.map((eachProcess) => (
				<option key={eachProcess.ConnectorID} value={eachProcess.ConnectorID}>
					{eachProcess.ConnectorName}
				</option>
			));
			let criteriaOptions = metriName.map((eachProcess) => (
				<option key={eachProcess.Criteria} value={eachProcess.Criteria}>
					{eachProcess.Criteria}
				</option>
			));

			this.setState({
				diverseData: diverseData,
				con,
				connectorOptions,
				criteriaOptions,
				valueConnector,
				LeadingAndSimilarCheck: LeadingAndSimilarCheck,
				KpiMetricConnectorID: metriName.length ? (metriName[0].KpiMetricConnectorID != 0 ? true : false) :false
			});

			// this.handleValidation();
		}
	}

	onDescriptionChange(event) {
		let errors = {};
		this.setState({
			errors: errors,
		});
		const diverseData = { ...this.state.diverseData };
		//diverseData.kpiData[0].MetricDataDesp = event.target.value;
		diverseData.KPIDesp = event.target.value;
		this.setState({
			diverseData: diverseData,
		});
		//this.handleValidation();
	}

	onUnitChange(event) {
		let errors = {};
		this.setState({
			errors: errors,
		});
		const diverseData = { ...this.state.diverseData };
		//diverseData.kpiData[0].MetricUnit = event.target.value;
		diverseData.MetricUnit = event.target.value;
		this.setState({
			diverseData: diverseData,
		});
		// this.handleValidation();
	}

	onActualChange(event) {
		const diverseData = { ...this.state.diverseData };
		//diverseData.kpiData[0].MetricValue = event.target.value;
		if (event.target.value === "") {
			diverseData.MetricValue = event.target.value;
			this.setState({
				diverseData: diverseData,
			});
		} else {
			if (regexForNegativeNumbers.test(event.target.value)) {
				if(event.target.value === "-."){
                    diverseData.MetricValue  = "-0.";  
                }else{
					diverseData.MetricValue = event.target.value;
                }
				
				this.setState({
					diverseData: diverseData,
				});
			}
		}

		//this.handleValidation();
	}

	onSimilarChange(event) {
		const diverseData = { ...this.state.diverseData };
		if (event.target.value === "") {
			diverseData.metDataAttr[1].AttributeValue = event.target.value;
			this.setState({
				diverseData: diverseData,
			});
		} else {
			if (regexForNumbersOnlyMTC.test(event.target.value)) {
				diverseData.metDataAttr[1].AttributeValue = event.target.value;
				this.setState({
					diverseData: diverseData,
				});
			}
		}
		
	}
	onLeadingChange(event) {
		const diverseData = { ...this.state.diverseData };
		
		if (event.target.value === "") {
			diverseData.metDataAttr[0].AttributeValue = event.target.value;
			this.setState({
				diverseData: diverseData,
			});
		} else {
			if (regexForNumbersOnlyMTC.test(event.target.value)) {
				diverseData.metDataAttr[0].AttributeValue = event.target.value;
				this.setState({
					diverseData: diverseData,
				});
			}
		}

		// this.handleValidation();
	}


	onValueDescriptionChange(event) {
		let str = event.target.value;
		let err = this.state.commentsError;
		if (event.target.value) {
			if (regexForTextAreas.test(str)) {

				if (str && str.length < 300) {
					const diverseData = { ...this.state.diverseData };
					diverseData.Comments = event.target.value;
					err = "";
					this.setState({
						diverseData: diverseData,
						commentsError: err,
					});
				} else {
					const diverseData = { ...this.state.diverseData };
					diverseData.Comments = event.target.value;
					err = i18n.t("commentsErrorM1");
					this.setState({
						commentsError: err,
						diverseData: diverseData,
					});
				}
			} else {
				const diverseData = { ...this.state.diverseData };
				diverseData.Comments = event.target.value;
				err = i18n.t("commentsErrorM2");
				this.setState({
					diverseData: diverseData,
					commentsError: err,
				});
			}
		} else {
			const diverseData = { ...this.state.diverseData };
			diverseData.Comments = event.target.value;
			err = "";
			this.setState({
				diverseData: diverseData,
				commentsError: err,
			});
		}
	}

	onSourceChange(event) {
		const diverseData = { ...this.state.diverseData };
		diverseData.ConnectorID = event.target.value;
		var index = event.nativeEvent.target.selectedIndex;
		diverseData.ConnectorNames = event.nativeEvent.target[index].text;
		diverseData.SourceName = "";
		diverseData.SourceFieldName = "";
		this.setState({
			diverseData: diverseData,
		});

		// if (diverseData.ConnectorNames == "Manual") {
		//     document.getElementById('select02').disabled = true;
		// }
		// else {
		//     document.getElementById('select02').disabled = false;

		// }
	}

	onSourceSystemChange(event) {
		const diverseData = { ...this.state.diverseData };
		diverseData.SourceFieldName = event.target.value;
		this.setState({
			diverseData: diverseData,
		});
	}
	onOtherSystemChange(event) {
		const diverseData = { ...this.state.diverseData };
		diverseData.SourceName = event.target.value;
		this.setState({
			diverseData: diverseData,
		});
	}
	handleSave(e) {
		if (this.handleValidation()) {
			
			// var iojData = {
			//     //     SOCategoryID: 22,
			//     // ParentSOCategoryID: 5,
			//     // SOCategoryName: "Performance Against Benchmarks",
			//     // SOCategoryDesp: null,
			//     // SoCategoryImage: null,
			//     // SOCategoryTypeID: 1,
			//     // SequenceID: null,
			//     // SOCategorySeq: null,
			//     // kpiData: [
			//     //{
			//     KPIMappingID: 161,
			//     MetricSeq: 1,
			//     MetricID: 133,
			//     MetricName: "Test",
			//     FrequencyID: 3,
			//     MetricUnit: "%",
			//     MetricImage: "",
			//     KPIDesp: "Below Industry Leaders",
			//     MetricDataID: 1275,
			//     MetricDataDesp: "Below Industry Leaders",
			//     MetricValue: "5",
			//     RAGStatus: "Red",
			//     UpperThreshold: "",
			//     LowerThreshold: "",
			//     Month: "2018-10-01T00:00:00",
			//     metDataAttr: [
			//         {
			//             MetricAttributeID: 297,
			//             MetricDataID: 1275,
			//             AttributeName: "Leading",
			//             AttributeValue: "100",
			//             CreatedUser: null,
			//             CreatedTS: "0001-01-01T00:00:00",
			//             UpdatedUser: null,
			//             UpdatedTS: "0001-01-01T00:00:00"
			//         },
			//         {
			//             MetricAttributeID: 304,
			//             MetricDataID: 1275,
			//             AttributeName: "Similar Industry",
			//             AttributeValue: "100",
			//             CreatedUser: null,
			//             CreatedTS: "0001-01-01T00:00:00",
			//             UpdatedUser: null,
			//             UpdatedTS: "0001-01-01T00:00:00"
			//         }
			//     ]
			//     //}]
			// }

			const diverseData = { ...this.state.diverseData };

			
			diverseData.KPIMappingID = parseInt(diverseData.KPIMappingID) === 0 ? Math.random() : diverseData.KPIMappingID;
			if(diverseData.BaseLineMonth!=null && diverseData.BaseLineMonth!=""){
			  var baselinemonth= new Date(diverseData.BaseLineMonth).getFullYear() +
			  "-" +
			  (new Date(diverseData.BaseLineMonth).getMonth() + 1 < 10
				? "0" + (new Date(diverseData.BaseLineMonth).getMonth() + 1)
				: new Date(diverseData.BaseLineMonth).getMonth() + 1);
				diverseData.BaseLineMonth=baselinemonth;
				this.setState({
				  diverseData:diverseData
				});
			}			 
			diverseData.KPIMappingID = diverseData.KPIMappingID == 0 ? Math.random() : diverseData.KPIMappingID;
			let currentData = {
			"ConfiguratorDataModels": { SOCategoryID: this.props.SOCategoryID, kpiData: diverseData }, "FiltersIDsVM": {
			"OrgEntityID": this.props.filterDetails.Client,
			"OfferingID": this.props.filterDetails.process,
			"LocationID": this.props.filterDetails.Locations,
			"MonthDT": this.props.filterDetails.Month
		}
	}
			// store.getState().diverseReducers.diverse
			axiosInstance.post(`${LocalApiBaseUrl}Configurator/SaveConfiguratorDealMetrics`, currentData).then((response) => {
				if(response.data.ConfiguratorDataModels.kpiData){
					store.dispatch({
						type: "ADD_DIVERSE",
						payload: response.data.ConfiguratorDataModels.kpiData,
					});
					axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetDiverseDataQualityScore`, {
					params: {
						LocationID: this.props.filterDetails.Locations,
						OrgEntityID : this.props.filterDetails.Client,
						OfferingID : this.props.filterDetails.process,
						MonthDT : this.props.filterDetails.Month,
						LanguageID: store.getState().CurrentFilter.languageData.id
					}}
				) 
				.then((response) => {
				 if(response.data){
					store.dispatch({
						type: "SAVE_QC_SCORE_OF_EACH_TAB",
						payload: response.data
					});
				 }
				 
				 this.handleHide(e); 
				}).catch(error => {
					this.handleHide(e); 
					trycatchAlertPopup(error)
				});
					
				}
			}).catch(error => {
				trycatchAlertPopup(error)
				this.handleHide(e); 
			});
		
		}
	}

	handleHide(e) {
		this.setState({ show: false });
		if (e != undefined) {
			if (e.target.textContent != "×") {
				this.props.onClose(e);
			} else {
				this.props.onClose(e);
				// this.props.onClose("from x");
			}
		} else {
			this.props.onClose(e);
		}
	}
	handleRejectHide(e) {
		this.setState({ show: false });
		if (e != undefined) {
			if (e.target.textContent != "×") {
				this.props.onRejectClose();
			}
		} else {
			this.props.onRejectClose();
		}
	}
	handleApproveHide(e) {
		this.setState({ show: false });
		if (e != undefined) {
			if (e.target.textContent != "×") {
				this.props.onApproveClose();
			}
		} else {
			this.props.onApproveClose();
		}
	}
	passToConfirmation(data) {
		this.props.enteredData(data.PoNumber);
	}

	// WOULD BE EXECUTED WHEN THE "METRIC DEFINITION" DROPDOWN WOULD BE CHANGED
	handleChangeMetricDefinition = (e) => {
		const MetricDefinitionID = e.target.value;
		this.setState((prevState) => ({
			diverseData: {
				...prevState.diverseData,
				MetricDefinitionID,
			},
		}));
	};

	//MULTILEVEL
	editCategoryName(item) {

		this.setState({
			showCategoryNameEditPopup: true
		});

	}
	removeDuplicates(arr) {/*NEW CODE WHICH REMOVES DUPLICATES DATA FROM API */
		const map = new Map();
		arr.forEach(v => map.set(v.CategoryType, v))
		return [...map.values()];
	}

	CatTypeSelect(e) {
       

		let CatTypeID = e.target.value
		this.setState({ CatTypeID: CatTypeID })

		setTimeout(() => {
			if (CatTypeID == 'addnew') {
				CategoryDetails.CategoryType = ""
				CategoryDetails.CategoryName = ""
				this.setState({
					errors: {},
					showCategory: false,
					showaddCategoryType: true, CategoryDetails: CategoryDetails
				});
			}
			else if (CatTypeID == "0") {//CHANGE2
				CategoryDetails.CategoryType = ""
				CategoryDetails.CategoryName = ""
				this.setState({
					CatNameID: 0,
					showaddCategoryType: false,
					showaddCategory: false,
					showCategory: true,
					CategoryDetails: CategoryDetails
				});
			}
			else if (CatTypeID == 'addnewcatname') {
				this.setState({
					showCategory: false,
					showaddCategory: true
				});
			}
			else {
				this.setState({
					showaddCategoryType: false,
					showaddCategory: false,
					showCategory: true
				});
			}
		}, 500);


		let CatType = (CatTypeID && CatTypeID != "0" && CatTypeID != "addnew") ? this.state.allCategoryTypesAndNames.filter(
			eachValue => eachValue.ID == CatTypeID
		)[0].CategoryType : [];//FETCHING individual CATEGORY TYPE

		let CategoryDetails = this.state.CategoryDetails
		CategoryDetails.CategoryType = CatType
		this.setState({
			CategoryDetails: CategoryDetails
		});


		let CategoryNameData = (CatType && CatType != "") ? this.state.allCategoryTypesAndNames.filter(
			eachValue => eachValue.CategoryType == CatType
		) : [];//FETCHING CATEGORY NAME

		this.setState({ CategoryNameData: CategoryNameData })


	}

	handleDropdownChange(e) {

		let CatNameID = e.target.value
		this.setState({ errors: {}, CatNameID: CatNameID })

		setTimeout(() => {
			if (CatNameID == 'addnew') {
				this.setState({
					showCategory: false,
					showaddCategoryType: true
				});
			}
			else if (CatNameID == 'addnewcatname') {
				if (!this.state.CategoryDetails.CategoryType) {
					//category type is empty

					let error = {};
					error["CategoryType"] = i18n.t("catTypeReqd");

					this.setState({ errors: error, CatNameID: 0 });
				}


				else {

					CategoryDetails.CategoryName = ""
					this.setState({
						showCategory: false,
						showaddCategory: true, CategoryDetails: CategoryDetails
					});
				}
			}
			else {
				this.setState({
					showaddCategoryType: false,
					showaddCategory: false,
					showCategory: true
				});
			}
		}, 500);
		let CatName = (CatNameID && CatNameID != "0" && CatNameID != "addnewcatname") ? this.state.allCategoryTypesAndNames.filter(
			eachValue => eachValue.ID == CatNameID
		)[0].CategoryName : [];


		let CategoryDetails = this.state.CategoryDetails
		CategoryDetails.CategoryName = CatName
		this.setState({
			CategoryDetails: CategoryDetails
		});
	}

  //called when new category name is added -- on clicking on the add button
  handleaddCategoryAdd() {
    const data = this.state.CategoryDetails;
    const { filterDetails } = this.props;
    const param = {
      OfferingId: parseInt(this.props.filterDetails.process),
      CatType: data.CategoryType.toString().trim(),
      CatName: data.CategoryName.toString().trim(),
      LocationID: filterDetails.Locations,
      SOCategoryID: 5,
      OrgEntityID: filterDetails.Client,
    };
    let error = {};
    if (!data.CategoryType.trim()) {
      error["CategoryType"] = i18n.t("catTypeReqd");
    }
    else if(!isValidText(data.CategoryType.trim())){
      error["CategoryType"] = InvalidDescription();
    }

    if (!data.CategoryName.trim()) {
      error["CategoryName"] = i18n.t("catNameReqd");
    }
    else if(!isValidText(data.CategoryName.trim())){
      error["CategoryName"] = InvalidDescription();
    }

		this.setState({ errors: error });




		if (data.CategoryType.trim() && data.CategoryName.trim()) {
			axiosInstance.post(`${LocalApiBaseUrl}MultiLevelApproval/SendCategoryTypeNameForApproval`, param,
				{
				}
			).then((response) => {
				//window.alert(response.data.StatusMessage);
				if (response.data.IsSuccess && response.data.StatusMessage == "Sent For Approval") {
					// this.onClose();
					this.setState({
						showaddCategory: false,
						showaddCategoryType: false,
						showApproval: true
					});
				}
				else {
					window.alert(response.data.StatusMessage);
					this.setState({ showLoaderOnPage: false });
				}
			}).catch(error => {
				trycatchAlertPopup(error)
			});
		}

	}


	handleCategoryAdd() {


		let CategoryDetails = [];
		const diverseData = this.state.diverseData

		let error = {};

		diverseData.CategoryDetails = diverseData.CategoryDetails === null ? [] : diverseData.CategoryDetails

		if (!this.state.numeratorVal) {

			error["NumeratorValue"] = i18n.t("numeratorIsReqd");


		}
		// else if (!(kpiData["MetricValue"] >= -99999999 && kpiData["MetricValue"] <= 99999999)
		else if (!(parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999)) {
			error["NumeratorValueRange"] = i18n.t("addBoErrorM6");


		}
		if (diverseData.MetricUnit.trim() === '%') {

			if (!this.state.denomVal) {
				error["Denominator"] = i18n.t("denominatorIsReqd");
			}
			else if (parseFloat(this.state.denomVal) === 0) {
				error["Denominator"] = i18n.t("denomCannotZero");
			}


			else if (!(parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) {
				error["DenominatorValueRange"] = i18n.t("addBoErrorM6");


			}
		}
		else {
			if (!Number.isNaN(parseFloat(this.state.denomVal))) {
				if (parseFloat(this.state.denomVal) === 0) {

					error["Denominator"] = i18n.t("denomCannotZero");


				}
				else if (!(parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) {
					error["DenominatorValueRange"] = i18n.t("addBoErrorM6");


				}
			}
		}

		if (this.state.CatTypeID == "0") {
			error["CategoryType"] = i18n.t("catTypeReqd");

		}

		if (this.state.CatNameID == "0") {
			error["CategoryName"] = i18n.t("catNameReqd");

		}

		this.setState({ errors: error });

		if (diverseData.MetricUnit.trim() === '%' ? (this.state.CategoryDetails.CategoryType && this.state.CatTypeID != "0" && this.state.CategoryDetails.CategoryName && this.state.CatNameID != "0" && this.state.numeratorVal && (parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999) && this.state.denomVal && parseFloat(this.state.denomVal) != 0 && (parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) :
			this.state.CategoryDetails.CategoryType && this.state.CatTypeID != "0" && this.state.CategoryDetails.CategoryName && this.state.CatNameID != "0" && this.state.numeratorVal && (parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999) && (!Number.isNaN(parseFloat(this.state.denomVal)) ? (parseFloat(this.state.denomVal) != 0 && parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999) : true)) {

			if (diverseData.CategoryDetails.filter(cat => cat.CategoryName === this.state.CategoryDetails.CategoryName).length === 0) {

				let CategoryName = this.state.CategoryDetails.CategoryName;

				let Catid = CategoryName && CategoryName !== "" ? this.state.CategoryNameData.filter(cat => cat.CategoryName === CategoryName)[0].ID : null;


					if (diverseData.CategoryDetails && diverseData.CategoryDetails.length < this.state.categoriesLimit) {
				diverseData.CategoryDetails.push({
					'CategoryType': this.state.CategoryDetails.CategoryType,
					'CategoryName': this.state.CategoryDetails.CategoryName,
					'NumeratorValue': this.state.numeratorVal,
					'Denominator': this.state.denomVal,
					'ConfigurationDealMetricID': 8,
					'OfferingMultiLevelCategoryID': Catid
				});


				this.setState({ errors: error });
				// kpiData.IsMultiLevel= !kpiData.IsMultiLevel;
				this.setState({
					showCategory: false,
					diverseData: diverseData
				})
					}
				else {
					let error = {};
					error["CategoryName"] = `${i18n.t("A_maximum_of")} ${this.state.categoriesLimit} ${i18n.t("category_values_are")}` 

					this.setState({ showCategory: true, errors: error });


				}

				if (diverseData.IsAggregation && !this.state.valueConnectorEdit && !this.state.valueConnector) {
					//calculate the metric value on the fly when new values added/ edited/deleted
					this.calculateAggregateValueByFormula();
				}

			}
			else {
				let error = {};
				error["CategoryName"] = i18n.t("duplicateCatName");

				this.setState({ errors: error });
			}
		}
		else {
			this.setState({
				showCategory: true
			})
		}
	}

	handleCategoryEdit() {
		let error = {};
		let CategoryDetails = [];
		const diverseData = this.state.diverseData
		if (!this.state.numeratorVal) {

			error["NumeratorValue"] = i18n.t("numeratorIsReqd");

		}
		// else if (!(kpiData["MetricValue"] >= -99999999 && kpiData["MetricValue"] <= 99999999)
		else if (!(parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999)) {
			error["NumeratorValueRange"] = i18n.t("addBoErrorM6");


		}
		if (diverseData.MetricUnit.trim() === '%') {

			if (!this.state.denomVal) {

				error["Denominator"] = i18n.t("denominatorIsReqd");
			}
			else if (parseFloat(this.state.denomVal) === 0) {
				error["Denominator"] = i18n.t("denomCannotZero");
			}
			else if (!(parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) {
				error["DenominatorValueRange"] = i18n.t("addBoErrorM6");


			}
		}
		else {
			if (!Number.isNaN(parseFloat(this.state.denomVal))) {
				if (parseFloat(this.state.denomVal) === 0) {

					error["Denominator"] = i18n.t("denomCannotZero");


				}
				else if (!(parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) {
					error["DenominatorValueRange"] = i18n.t("addBoErrorM6");


				}
			}
		}
		if (!this.state.CategoryDetails.CategoryType) {
			error["CategoryType"] = i18n.t("catTypeReqd");

		}

		if (this.state.CatNameID === "0") {
			error["CategoryName"] = i18n.t("catNameReqd");

		}

		this.setState({ errors: error });

		if (diverseData.MetricUnit.trim() === '%' ? (this.state.CategoryDetails.CategoryType && this.state.CatTypeID != "0" && this.state.CategoryDetails.CategoryName && this.state.CatNameID != "0" && this.state.numeratorVal && (parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999) && this.state.denomVal && parseFloat(this.state.denomVal) != 0 && (parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999)) :
			this.state.CategoryDetails.CategoryType && this.state.CatTypeID != "0" && this.state.CategoryDetails.CategoryName && this.state.CatNameID != "0" && this.state.numeratorVal && (parseFloat(this.state.numeratorVal) >= -999999999999 && parseFloat(this.state.numeratorVal) <= 999999999999) && (!Number.isNaN(parseFloat(this.state.denomVal)) ? (parseFloat(this.state.denomVal) != 0 && parseFloat(this.state.denomVal) >= -999999999999 && parseFloat(this.state.denomVal) <= 999999999999) : true)) {

			let index = this.state.EditIndex
			if (diverseData.CategoryDetails.filter(cat => cat.CategoryName === this.state.CategoryDetails.CategoryName).length === 0) {


				diverseData.CategoryDetails[index].CategoryName = this.state.CategoryDetails.CategoryName
				diverseData.CategoryDetails[index].NumeratorValue = this.state.numeratorVal
				diverseData.CategoryDetails[index].Denominator = this.state.denomVal
				diverseData.CategoryDetails[index].ConfigurationDealMetricID = 8
				let Catid = diverseData.CategoryDetails[index].CategoryName && diverseData.CategoryDetails[index].CategoryName !== "" ? this.state.CategoryNameData.filter(cat => cat.CategoryName === diverseData.CategoryDetails[index].CategoryName)[0].ID : null;
				// kpiData.CategoryDetails[index].OfferingMultiLevelCategoryID = this.state.CategoryDetails.OfferingMultiLevelCategoryID
				//diverseData.CategoryDetails[index].OfferingMultiLevelCategoryID = diverseData.CategoryDetails[index].OfferingMultiLevelCategoryID
				diverseData.CategoryDetails[index].OfferingMultiLevelCategoryID = Catid
				this.setState({
					diverseData: diverseData
				});
				this.setState({
					showCategory: false
				});
			}
			else if (diverseData.CategoryDetails.filter(cat => cat.CategoryName === diverseData.CategoryDetails[index].CategoryName).length === 1) {
				if (diverseData.CategoryDetails[index].CategoryName.trim() === this.state.CategoryDetails.CategoryName.trim() ? false : diverseData.CategoryDetails.filter(cat => cat.CategoryName === this.state.CategoryDetails.CategoryName).length === 1) {
					let error = {};
					error["CategoryName"] = i18n.t("duplicateCatName");

					this.setState({ errors: error });
				}
				else {


					diverseData.CategoryDetails[index].CategoryName = diverseData.CategoryDetails[index].CategoryName
					diverseData.CategoryDetails[index].NumeratorValue = this.state.numeratorVal
					diverseData.CategoryDetails[index].Denominator = this.state.denomVal
					diverseData.CategoryDetails[index].ConfigurationDealMetricID = 8
					diverseData.CategoryDetails[index].OfferingMultiLevelCategoryID = diverseData.CategoryDetails[index].OfferingMultiLevelCategoryID

					this.setState({
						diverseData: diverseData
					});
					if (diverseData.IsAggregation && !this.state.valueConnectorEdit && !this.state.valueConnector) {
						//calculate the metric value on the fly when new values added/ edited/deleted
						this.calculateAggregateValueByFormula();
					}
					this.setState({
						showCategory: false
					});
				}
			}
			else {
				this.setState({
					showCategory: false
				});
			}
		}
	}
	handleCategoryTypeAddShow() {

		this.setState({ errors: {} })

		let CatTypeID = this.state.CatTypeID
		let CategoryDetails = this.state.CategoryDetails
		CategoryDetails.CategoryName = ""
		this.setState({
			CategoryDetails: CategoryDetails,
			showCategory: true,
			isEdit: false,
			iscatypedisable: true,
			CatNameID: 0,
			CatTypeID: CatTypeID,
			numeratorVal: '',
			denomVal: ''
		});

	}

	deleteCategoryType() {

		if(window.confirm(i18n.t("delete_Category_msg")))
        {
		let diverseData = this.state.diverseData;
		diverseData.CategoryDetails = [];
		if (diverseData.IsAggregation && !this.state.valueConnectorEdit && !this.state.valueConnector) {
			// if there are no categories, clear out the value calculated using the formula
			diverseData.MetricValue = '';
		}

		this.setState({
			CatNameID: 0,
			CatTypeID: 0,
			CategoryNameData: [],
			diverseData: diverseData
		});
	}
	}

	handleCategoryEditShow(catNameToEdit) {
		
		this.setState({ errors: {} })
		var allCategoriesList = this.state.diverseData.CategoryDetails;
		var index = allCategoriesList.findIndex(item => item.CategoryName === catNameToEdit)

		let catnameid = this.state.CategoryNameData.filter(row => row.CategoryName === catNameToEdit)[0].ID
		let CategoryName = this.state.CategoryNameData.filter(row => row.ID === catnameid)[0].CategoryName
		let CategoryDetails = this.state.CategoryDetails
		CategoryDetails.CategoryName = CategoryName
		/*     let numeratorVal=allCategoriesList.filter(item => item.CategoryName ===catNameToEdit)[0].NumeratorValue
			let denomVal=allCategoriesList.filter(item => item.CategoryName ===catNameToEdit)[0].Denominator */
		let numeratorVal = allCategoriesList[index].NumeratorValue
		let denomVal = allCategoriesList[index].Denominator
	
		this.setState({
			EditIndex: index,
			isEdit: true,
			showCategory: true,
			CatNameID: catnameid,
			CategoryDetails: CategoryDetails,
			CatTypeID: this.state.CatTypeID,
			numeratorVal: numeratorVal,
			denomVal: denomVal
		});
	}


	handleaddCategoryNameClose() {
		this.setState({
			showaddCategory: false,
			errors: {}
		});
	}
	handleaddCategoryClose() {
		this.setState({
			showaddCategoryType: false,
			errors: {}
		});
	}
	handleApprovalClose() {
		this.setState({ showApproval: false });
	}

	handleCategoryClose() {
		this.setState({
			showCategory: false,
			errors: {}
		});
	}
	handleaddCategoryShow() {
		this.setState({ showaddCategory: true });
	}


	onNumeratorChange = (e) => {
        let errors = this.state.errors
        errors.NumeratorValue = ''
        this.setState({ errors: errors })
        if(e.target.value.length <=12)
        {
                this.setState({ numeratorVal: e.target.value });
            }
        }


		onDenomChange = (e) => {
			let errors = this.state.errors
			errors.Denominator = ''
			this.setState({ errors: errors })
			if(e.target.value.length <=12)
			{
					this.setState({ denomVal: e.target.value });
				}
			}



	deleteCategoryName(catNameToDelete) {
		this.setState({ errors: {} })
		let CatTypeID = this.state.CatTypeID
		let diverseData = this.state.diverseData;
		var allCategoriesList = this.state.diverseData.CategoryDetails;
		let CategoryNameData = this.state.CategoryNameData
		allCategoriesList.splice(allCategoriesList.findIndex(item => item.CategoryName === catNameToDelete), 1)

		if (allCategoriesList.length === 0) {
			diverseData.MetricValue = '';
		}

		this.setState({
			CatNameID: 0,
			CategoryNameData: allCategoriesList.length === 0 ? [] : CategoryNameData,
			CatTypeID: CatTypeID,//CHANGED
			diverseData: diverseData
		});

		if (diverseData.IsAggregation && !this.state.valueConnectorEdit && !this.state.valueConnector) {
			//calculate the metric value on the fly when new values added/ edited/deleted
			this.calculateAggregateValueByFormula();
		}


	}

	handleCategoryShow() {
		this.setState({ errors: {} })
		let CategoryDetails = this.state.CategoryDetails
		CategoryDetails.CategoryType = ""
		CategoryDetails.CategoryName = ""
		this.setState({
			showCategory: true,
			iscatypedisable: false,
			CategoryDetails: CategoryDetails,
			isEdit: false,
			CategoryNameData:[],
			CatNameID: 0,
			CatTypeID: 0,
			numeratorVal: '',
			denomVal: ''
		});
	}



	calculateAggregateValueByFormula = () => {
		const FormulaList = this.state.FormulaList
		const diverseData = this.state.diverseData;
		const metricUnit = diverseData.MetricUnit;

		let temp = 0;
		let formulaStr = '';
		if (!FormulaList)
			FormulaList = [];
		if (metricUnit.trim() === '%') {
			if (FormulaList.length > 0) {
				formulaStr = FormulaList.filter(z => z.IsPercentageUnit === true)[0].Formula;


			}

		}
		else {

			if (FormulaList.length > 0) {
				formulaStr = FormulaList.filter(z => z.IsPercentageUnit === false)[0].Formula;


			}
			diverseData.formulaStr = formulaStr;
			this.setState({
				diverseData: diverseData
			});
		}
		if (diverseData.CategoryDetails && diverseData.CategoryDetails.length > 0) {
			let total = 0;
			let numeratorSum = 0;
			let denominatorSum = 0;
			switch (formulaStr.toUpperCase().trim()) {
				case 'SUM':

					diverseData.CategoryDetails.forEach(function (arrayItem) {
						if (!arrayItem.Denominator) {
							temp = 1;

							// arrayItem.Denominator = 1;
							// temp = true;
						}
						else {
							temp = arrayItem.Denominator;

						}
						total = total + (arrayItem.NumeratorValue / temp);
					});

					break;
				case 'AVERAGE':

					diverseData.CategoryDetails.forEach(function (arrayItem) {
						if (!arrayItem.Denominator) {
							temp = 1;

							// arrayItem.Denominator = 1;
							// temp = true;
						}
						else {
							temp = arrayItem.Denominator;

						}
						total = total + (arrayItem.NumeratorValue / temp);
					});

					total = total / (diverseData.CategoryDetails.length);
					break;
				case 'WEIGHTED AVERAGE':
					diverseData.CategoryDetails.forEach(function (arrayItem) {
						if (!arrayItem.Denominator) {
							arrayItem.Denominator = 100;
						}
					    // let individualPercentage = (arrayItem.NumeratorValue / arrayItem.Denominator) * 100;
          // total = total + individualPercentage; //75+30
        
           numeratorSum +=  parseFloat(arrayItem.NumeratorValue);
           denominatorSum += parseFloat(arrayItem.Denominator);
           


      });

      total = numeratorSum/denominatorSum *100;
      break;
				default:

			}
			if (formulaStr) {
				diverseData.MetricValue = total;
			}

			this.setState({
				diverseData: diverseData
			});

		}

	}

	bindAutoAggregationChange = (e) => {
		const diverseData = this.state.diverseData;

		diverseData.IsAggregation = e.target.checked;

		this.setState({
			diverseData: diverseData
		});
		if (diverseData.IsAggregation && !this.state.valueConnectorEdit && !this.state.valueConnector) {

			this.calculateAggregateValueByFormula();
		}
	}

	bindToolFieldChange = (e) => {
		const id = e.target.id;
		let data = this.state.CategoryDetails;
		switch (id) {
			case "categoryType":
				data.CategoryType = e.target.value;
				break;
			case "categoryName":
				data.CategoryName = e.target.value;
				break;
		}

		this.setState({
			CategoryDetails: data
		});
	}



	handleClick() {


		const diverseData = this.state.diverseData;
		diverseData.IsMultiLevel = !diverseData.IsMultiLevel;
		if (!diverseData.IsMultiLevel) {
			diverseData.IsAggregation = false;

		}

		this.setState({
			diverseData: diverseData
		});

	}

	render() {
		const currentDate = new Date();
		//list of all category types and names for this offering - to be displayed in add/edit popup 
		const allCatList = this.state.allCategoryTypesAndNames;
	
		const stateKpiData = this.state.diverseData;

    let items = [];
    if (stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0) {
      items = stateKpiData.CategoryDetails.map((item, key) => (
        <li>
          <span className="contentName">{item.CategoryName}</span>
          {stateKpiData.MetricUnit.trim() !== "%" && (
            <span className="contentValues">
              {/* <span>{item.NumeratorValue}</span> */}
              <span>
              {thousandSeparatorFormat(item.NumeratorValue) % 1 != 0
                  ? MetricValueThousandFormat(stateKpiData.MetricUnit,item.NumeratorValue,2)
                  : MetricValueThousandFormat(stateKpiData.MetricUnit,item.NumeratorValue,2)}
              </span>
              {item.Denominator && <span> | </span>}
              {/* <span>{item.Denominator}</span> {stateKpiData.MetricUnit}</span>} */}
              <span>
              {item.Denominator
                  ? thousandSeparatorFormat(item.Denominator) % 1 != 0
                    ? MetricValueThousandFormat(stateKpiData.MetricUnit,item.Denominator,2)
                    : MetricValueThousandFormat(stateKpiData.MetricUnit,item.Denominator,2)
                  : ""}
              </span>{" "}
              {/* {stateKpiData.MetricUnit} */}
            </span>
          )}

          {stateKpiData.MetricUnit.trim() === "%" && (
            <span className="contentValues">
              <span>
              {thousandSeparatorFormat((item.NumeratorValue * 100) / item.Denominator) % 1 != 0
                  ? thousandSeparatorFormat(((item.NumeratorValue * 100) / item.Denominator),2)
                  : thousandSeparatorFormat(((item.NumeratorValue * 100) / item.Denominator),2)}
              </span>{" "}
              {stateKpiData.MetricUnit}
            </span>
          )}

					<div className="item-list-hover"><span><i
						onClick={() => this.deleteCategoryName(item.CategoryName)}
						class="far fa-times"> </i>
						<i onClick={() => this.handleCategoryEditShow(item.CategoryName)}
							class="fas fa-edit"></i>
					</span>
					</div>
				</li>
	 		 ));
		}
		let isScreenInEditMode = false;
		if (this.props.editDiverse != null) {
			if (!_.isEmpty(this.props.editDiverse[1])) {
				isScreenInEditMode = true;
			}
		}

		// FILTER OUT THE METRIC DEFINITIONS THAT ARE "ENABLED"
		const kpiMetricDefinitions = _.get(
			this.state,
			"diverseData.MetricDefination",
			[]
		);
		const enabledMetricDefinitions = _.filter(
			kpiMetricDefinitions,
			(eachMetricDefinition) =>
				String(eachMetricDefinition.IsEnabled) === "true"
		);
		const attributeLabelsForBO = this.props.attributes.filter(z => z.ApplicableForDD === true);


		return (
			<>
				{/* <Header /> */}

				<Modal
					show={this.state.show}
					onHide={this.handleHide}
					className="modal fade bo-configurator-modal enhance"
					size="medium"
					id="dealpopup"
					backdrop="static"
				>
					<Modal.Header closeButton>
						<h4 className="modal-title" id="dealprofile">
							{/* {i18n.t("ddPopupM1")} */}

							{i18n.t("ddPopupM1")} - {this.props.SOCategoryName}

							{/* If this heading needs to be changed to Japanese, update the uiliterlsmaster record for ddPopupM1 later */}
						</h4>
					</Modal.Header>
					<Modal.Body>
						<form>
							<div className="form-section">
								{/* <h3>Insight Data</h3> */}
								<div className="row">
									<div className="form-group col-lg-7">
										<div className="row">
											<div className="form-group col-lg-6">
												<label for="">
													{i18n.t("Name")} <span className="required"> *</span>
												</label>
												{/* <select className="form-control" value={this.state.diverseData.MetricName} onChange={this.onNameChange}>
                                                    <option value="0">--Select--</option>
                                                    {this.state.MetricNameOptions}
                                                </select> */}
												<select
													id="name"
													//disabled={this.props.kpiData}
													disabled={isScreenInEditMode}
													className="form-control"
													ref="MetricNameDropdown"
													onChange={this.onNameChange}
													value={this.state.diverseData.KPIMappingID}
												>
													<option value=""> {i18n.t("Select_Metric_Name")} </option>
													{this.state.MetricNameOptions.map((row) => {
														return (
															<option value={row.KpiMappingID}>
																{row.MetricName}
															</option>
														);
													})}
													<option value="-1">&lt;{i18n.t("Add_New")}&gt;</option>
												</select>
												{/* <input type="text" className="form-control" id="name" onChange={this.onNameChange} value={this.state.diverseData.MetricName} /> */}
												<div className="errorMsg">
													{this.state.errors.MetricName}
												</div>
											</div>

											<div className="form-group col-lg-6">
												<label for="">{i18n.t("Unit_of_Measure")}<span className="required"> *</span></label>
												<input
													type="text"
													className="form-control"
													maxLength="50"
													id=""
													value={this.state.diverseData.MetricUnit}
													readOnly
												/>
												{/* <div className="errorMsg">{this.state.errors.MetricUnit}</div> */}
											</div>
										</div>

										<div className="row">
											<div className="form-group col-lg-12">
												<label for="">
													{i18n.t("Actual")} <span className="required"> *</span>
												</label>
												<input
													type="text"
													readOnly={stateKpiData.IsAggregation}
													className="form-control"
													maxLength="12"
													id=""
													onChange={this.onActualChange}
													value={this.state.diverseData.MetricValue}
													disabled={this.state.KpiMetricConnectorID}
												/>
												<div className="errorMsg">
													{this.state.errors.MetricValue}
												</div>
												<div className="errorMsg">
													{this.state.errors.MetricConnectorDataNotFound}
													{this.state.errors.MetricConnectorLastPull ? <div> {this.state.errors.MetricConnectorLastPull} </div> : ""}
													{this.state.errors.MetricConnectorDataNotFound ? <div>{this.state.errors.IntegratedMetricsMessage}</div> : ""}
												</div>
												<div className="errorMsg">
													{this.state.errors.MetricValueRange}
												</div>
											</div>
										</div>
											{this.state.diverseData.KPIMappingID != "" &&  (this.state.diverseData.IsGlobal ||  (this.state.diverseData.IndustryName !== "" && this.state.diverseData.IndustryName != undefined && this.state.diverseData.IndustryName != null))  &&
											<div className="row"> {/* new code */}
												<div className="form-group col-lg-6">
													<label for="">
														{i18n.t("Industry")}

													</label>
													<input
														type="text"
														className="form-control"
														maxLength="50"
														id=""
														disabled={true}
														value={this.state.diverseData.IndustryName}
													/>
												</div>
											</div>
										}

										<div className="row">
											<div className="form-group col-lg-6">
												<label for="">
													{attributeLabelsForBO.length > 0 ? attributeLabelsForBO[0].AttributeName1 : ""}{" "}

												</label>
												<input
													type="text"
													className="form-control"
													maxLength="50"
													id=""
													onChange={this.onLeadingChange}
													disabled={true}
													value={
														this.state.diverseData.metDataAttr && this.state.diverseData.metDataAttr[0].AttributeValue
													}
												/>
												<div className="errorMsg">{this.state.errors.Leading}</div>
												<div className="errorMsg">
													{this.state.errors.LeadingValueRange}
												</div>
											</div>
											<div className="form-group col-lg-6">
												{this.props.filtername === "Health & Public Services" ? (
													<label for="">
														{i18n.t("External_Benchmark")}
														<span className="required"> *</span>
													</label>
												) : (
														<label for="">
															{attributeLabelsForBO.length > 0 ? attributeLabelsForBO[0].AttributeName2 : ""}{" "}
															{/* <span className="required">*</span> */}

														</label>
													)}
												<input
													type="text"
													className="form-control"
													maxLength="50"
													id=""
													onChange={this.onSimilarChange}
													disabled={true}
													value={
														this.state.diverseData.metDataAttr && this.state.diverseData.metDataAttr[1].AttributeValue
													}
												/>
												<div className="errorMsg">{this.state.errors.Similar}</div>
												<div className="errorMsg">
													{this.state.errors.SimilarValueRange}
												</div>
											</div>
										</div>

										<div className="row">
											<div className="form-group col-lg-12">
												<label for="">{i18n.t("Description")}</label>
												{/* {!this.props.kpiData ? 
                                            <input type="text"  maxLength="50" className="form-control" id="" onChange={this.onDescriptionChange} value={this.state.diverseData.KPIDesp} readOnly/>
                                          : <input type="text" disabled maxLength="50" className="form-control" id="" onChange={this.onDescriptionChange} value={this.state.diverseData.KPIDesp} />
                                            } */}
												<input
													type="text"
													maxLength="255"
													className="form-control"
													id=""
													value={this.state.diverseData.KPIDesp}
													readOnly
												/>

												{/* <div className="errorMsg">{this.state.errors.MetricDataDesp}</div> */}
											</div>
										</div>

										<div className="row">
											<div className="form-group col-lg-12">
												<label>
													{i18n.t("Metric_Definition")}{" "}
													<span className="required"> *</span>

													{" "}
													<OverlayTrigger
														key="top"
														placement="top"
														overlay={
															<Tooltip id="tooltip-top">
																{i18n.t("addBOMess3")}
															</Tooltip>
														}
													>
														<FontAwesomeIcon icon={faInfoCircle} />
													</OverlayTrigger>

												</label>
												{enabledMetricDefinitions.length > 0 ? (
													<select
														value={this.state.diverseData.MetricDefinitionID}
														onChange={(e) => this.handleChangeMetricDefinition(e)}
														className="form-control"
													>
														<option value="">
															{i18n.t("Select_Metric_Definition")}{" "}
														</option>
														{_.map(
															enabledMetricDefinitions,
															(eachMetricDefinition) => {
																return (
																	<option
																		value={
																			eachMetricDefinition.KPIMetricDefinatoinID
																		}
																		key={eachMetricDefinition.KPIMetricDefinatoinID}
																	>
																		{_.isEmpty(
																			eachMetricDefinition.DenominatorDescription
																		)
																			? eachMetricDefinition.NumeratorDescription
																			: `${eachMetricDefinition.NumeratorDescription} / ${eachMetricDefinition.DenominatorDescription}`}
																	</option>
																);
															}
														)}
													</select>
												) : (
														<input
															type="text"
															className="form-control"
															value={i18n.t("Definition_Not_Available")}
															disabled
														></input>
													)}
												<div className="errorMsg">
													{this.state.errors.MetricDefinitionID}
												</div>
											</div>
										</div>

										<div className="row">
											<div className="form-group col-lg-6">
												<label>
													{i18n.t("Leading_Practice")}{" "}
													<OverlayTrigger
														key="top"
														placement="top"
														overlay={
															<Tooltip id="tooltip-top">
																{i18n.t("addBOMess2")}
															</Tooltip>
														}
													>
														<FontAwesomeIcon icon={faInfoCircle} />
													</OverlayTrigger>
												</label>
												<input
													type="text"
													disabled
													maxLength="50"
													className="form-control"
													value={this.state.diverseData.LeadingPractice}
												/>
												<div className="errorMsg">
													{this.state.errors.LeadingPractice}
												</div>
											</div>

											<div className="form-group col-lg-6">
												<label for="">
													{i18n.t("Status")} <span className="required"> *</span>
												</label>
												<select
													className="form-control"
													value={this.state.diverseData.RAGStatus}
													onChange={this.onStatusChange}
												>
													<option value="">--{i18n.t("Select")}--</option>
													<option value="Grey">{i18n.t("Grey")}</option>
													<option value="Green">{i18n.t("Green")}</option>
													<option value="Red">{i18n.t("Red")}</option>
													{/* <option value="Orange">Orange</option> */}
													<option value="Amber">{i18n.t("Amber")}</option>
												</select>
												<div className="errorMsg">
													{this.state.errors.RAGStatus}
												</div>
											</div>

												<div className="form-group col-lg-4">
													<label>{i18n.t("Baseline_Value")}</label>
													<input
														type="text"
														maxLength="12"
														onInput={this.maxLengthCheckThreshold}
														className="form-control"
														onChange={this.onBaselineValueChange}
														value={this.state.diverseData.BaselineValue}
													/>
													<span className="errorMsg">
														{this.state.errors.BaselineValue}
													</span>
												</div>

												<div className="form-group col-lg-4">
													<label for="">{i18n.t("Baseline_Month")}</label>
													<div className="datepicker-label-wrapper">
														<DatePicker
														className="form-control"
														id="BaseLineMonth"
														maxDate={currentDate}
														dateFormat="MM/yyyy"
														showMonthYearPicker
														selected={
															Date.parse(this.state.diverseData.BaseLineMonth)
																? new Date(Date.parse(this.state.diverseData.BaseLineMonth))
																: null
														}
														onSelect={(date) => {
														this.onBaseLineMonthChange(date);
														}}
														readOnly={this.state.BaselineMonthReadOnly}
														/>
													</div>
												</div>

												<div className="form-group col-lg-4">
													<label>{i18n.t("Target_Value")}</label>
													<input
														type="text"
														maxLength="12"
														onInput={this.maxLengthCheckThreshold}
														className="form-control"
														onChange={this.onTargetValueChange}
														value={this.state.diverseData.TargetValue}
													/>
													<span className="errorMsg">
														{this.state.errors.TargetValue}
													</span>
													</div>
											<div className="form-group col-lg-12">
												<label for="">{i18n.t("Comments")}</label>
												<textarea
													rows="4"
                          							maxlength="300"
													name="comment"
													className="form-control"
													onChange={this.onValueDescriptionChange}
													value={this.state.diverseData.Comments}
												/>
												<span className="errorMsg">
													{this.state.commentsError}
												</span>
											</div>
										</div>

										<div className="row border-box">
											<div className="form-group col-lg-4">
												<label for="" className="source-system-padding">
													{i18n.t("Source_System")}{" "}
													<span className="required "> *</span>
												</label>
												{this.state.con ? (
													<select
														className="form-control"
														disabled
														onChange={this.onSourceChange}
														value={this.state.diverseData.ConnectorID}
													>
														{this.state.connectorOptions}
													</select>
												) : this.state.KpiMetricConnectorID ? (
													<input
														type="text"
														className="form-control"
														readOnly
														onChange={this.onSourceChange}
														value={
															this.state.diverseData.ConnectorNames
														}
														
													></input>
												) : (
															<select
																className="form-control"
																onChange={this.onSourceChange}
																value={this.state.diverseData.ConnectorID}
																disabled={this.state.KpiMetricConnectorID}
															>
																<option value=""></option>
																{this.state.sourceOptions}
																<option value="-1">{i18n.t("Others")}</option>
																<option value="-2">{i18n.t("Manual")}</option>
															</select>
														)}
												<div className="errorMsg">
													{this.state.errors.ConnectorID}
												</div>
											</div>
											<div className="form-group col-lg-4">
												<label for="">
													{i18n.t("Source_System_Fields")}{" "}
													<span className="required"></span>
												</label>
												{this.state.con ||
													this.state.diverseData.ConnectorNames == "Manual" ? (
														<input
															onChange={this.onSourceSystemChange}
															type="text"
															maxLength="50"
															className="form-control"
															id=""
															readOnly
															value={this.state.diverseData.SourceFieldName}
														/>
													) : this.state.KpiMetricConnectorID ? (
														<input
															type="text"
															className="form-control"
															readOnly
															onChange={this.onSourceChange}
															value={
																this.state.diverseData.ConnectorNames
															}
														></input>
													) : (
															<input
																onChange={this.onSourceSystemChange}
																type="text"
																maxLength="50"
																className="form-control"
																id=""
																value={this.state.diverseData.SourceFieldName}
															/>
														)}
											</div>
											<div className="form-group col-lg-4">
												<label for=""> {i18n.t("Other_Source_System")}</label>
												{this.state.diverseData.ConnectorNames == "Others" ? (
													<input
														onChange={this.onOtherSystemChange}
														type="text"
														maxLength="50"
														className="form-control"
														id=""
														value={this.state.diverseData.SourceName}
													/>
												) : (
														<input
															onChange={this.onOtherSystemChange}
															type="text"
															value=""
															readOnly
															maxLength="50"
															className="form-control"
															id=""
															value={this.state.diverseData.SourceName}
														/>
													)}
												<div className="errorMsg">{this.state.errors.KPIDesp}</div>
											</div>
											<div className="form-group col-lg-12 mt-2">
												{/* <input type="checkbox" id="toolowner" defaultChecked={this.state.diverseData.IsAccentureOwned} onChange={this.onToolOwnerChange} /> Accenture Owned */}
												<label className="form-check">
																								
														<input
															className="form-check-input"
															type="checkbox"
															id="toolowner"
															defaultChecked={
																this.state.diverseData.IsAccentureOwned
															}
															onChange={this.onToolOwnerChange}
															checked={
																this.state.diverseData.IsAccentureOwned
															}
														/>
													<span className="checkmark"></span>
													<span className="form-check-label">
														{i18n.t("Accenture_Owned")}
													</span>
												</label>
												<small className="form-text text-muted">
													<b>{i18n.t("Note")}:</b>
													{i18n.t("NoteMessage1")}
												</small>
											</div>
										</div>
									</div>
									{/* start of multi level UI */}
									<div className="form-group col-lg-5">
										<div className="right-wrapper">
											<div className="catergory-metric-sub">
												<div className="sub-level"><i className="fas fa-sitemap"></i>{i18n.t("Category_Metric_Sub_Levels")}</div>
												<div className="toggle-switch">
													<Form>
														<Form.Check
															onClick={this.handleClick}
															disabled={!stateKpiData.MetricName}
															checked={stateKpiData.IsMultiLevel}
															type="switch"
															id="custom-switch"
															label=""
														/>
													</Form>
												</div>
											</div>
											<div className="metric-sub-info">
												<div className={stateKpiData.IsMultiLevel ? 'disable-sub-level-none' : 'disable-sub-level'}>
													<i className="fas fa-sitemap"></i>
													<p>
														{i18n.t("Enable_to_add_metric_categories_as_its_sub_level")}</p>
												</div>
												<div className={stateKpiData.IsMultiLevel ? 'enable-sub-level' : 'enable-sub-level-none'}>
													<div className="check-box-info">
														<div className="custom-control custom-checkbox">
															<input type="checkbox" className="custom-control-input" checked={stateKpiData.IsAggregation}
																onChange={(e) => { this.bindAutoAggregationChange(e) }}
																id="customCheck" name="example1" />
															<label className="custom-control-label" for="customCheck">
																{i18n.t("Aggregate_category_values_to_metric_value")}
															</label>
														</div>

													</div>
													<Modal className="addCategoryModal" aria-labelledby="contained-modal-title-vcenter" centered show={this.state.showCategory} onHide={this.handleCategoryClose} animation={false} backdrop="static">
														<Modal.Header>
															<Modal.Title id="contained-modal-title-vcenter">
															{this.state.isEdit ? i18n.t("Edit_Category_Details"):i18n.t("Add_Category_Details")}
															</Modal.Title>
														</Modal.Header>
														<Modal.Body>
															<div className="row">

																{!this.state.iscatypedisable ?

																	<div className="form-group col-lg-12">
																		<label> {i18n.t("Category_Type")}
																			<span className="required"> *</span>

																		</label>
																		
																		<select id="name" className="form-control" disabled={this.state.isEdit} onChange={this.CatTypeSelect.bind(this)} value={this.state.CatTypeID}>
																			<option value="0">{i18n.t("Select_CategoryType")}</option>
																			{this.removeDuplicates(this.state.allCategoryTypesAndNames).map((CT) => {
																				return <option value={CT.ID} key={CT.ID}>{CT.CategoryType}</option>
																			})}
																			<option value="addnew">{i18n.t("Add_New")}</option>
																		</select>
																		<div className="errorMsg">
																			{this.state.errors.CategoryType}
																		</div>
																	</div>
																	:
																	<div className="form-group col-lg-12">
																		<label>{i18n.t("Category_Type")}
																			<span className="required"> *</span>

																		</label>
																		<select id="name" className="form-control" disabled={this.state.iscatypedisable} onChange={this.CatTypeSelect.bind(this)} value={this.state.CatTypeID}>
																			<option value="0">{i18n.t("Select_CategoryType")}</option>
																			{this.state.allCategoryTypesAndNames.map((CT) => {
																				return <option value={CT.ID} >{CT.CategoryType}</option>
																			})}
																			<option value="addnew">{i18n.t("Add_New")}</option>
																		</select>

																		<div className="errorMsg">
																			{this.state.errors.CategoryType}
																		</div>
																	</div>
																}
																<div className="form-group col-lg-12">
																	<label>{i18n.t("Category_Name")}
																		<span className="required"> *</span>

																	</label>
																	<select id="name" value={this.state.CatNameID} onChange={this.handleDropdownChange} className="form-control">
																		<option value="0">{i18n.t("Select_CategoryName")}</option>
																		{this.state.CategoryNameData.map((CN) => {
																			return <option value={CN.ID} >{CN.CategoryName}</option>
																		})
																		}
																		<option value="addnewcatname">{i18n.t("Add_New")}</option>
																	</select>
																	<div className="errorMsg">
																		{this.state.errors.CategoryName}
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="form-group col-lg-6">
																	<label>{i18n.t("Numerator_Value")}<span className="required"> *</span></label>
																	<input type="number"
																	maxLength="12"
																	 value={this.state.numeratorVal}
																		onChange={this.onNumeratorChange}
																		className="form-control" />
																	<span className="errorMsg">
																		{this.state.errors.NumeratorValue}
																	</span>
																	<span className="errorMsg">
																		{this.state.errors.NumeratorValueRange}
																	</span>
																</div>
																<div className="form-group col-lg-6">
																	<label>{i18n.t("Denominator_Value")}
																		{stateKpiData.MetricUnit && stateKpiData.MetricUnit.trim() === '%' && <span className="required"> *</span>}
																	</label>
																	<input type="number" 
																	maxLength="12"
																	value={this.state.denomVal}
																		onChange={this.onDenomChange}
																		className="form-control" />
																	<span className="errorMsg">
																		{this.state.errors.Denominator}
																	</span>
																	<span className="errorMsg">
																		{this.state.errors.DenominatorValueRange}
																	</span>


																</div>
															</div>
														</Modal.Body>
														<Modal.Footer>
															<button className="btn btn-default btn-sm mr-2" onClick={this.handleCategoryClose}>
																{i18n.t("Cancel")}
															</button>
															{this.state.isEdit ?
																<button
																	className="btn btn-primary" onClick={this.handleCategoryEdit}>
																	{i18n.t("Update")}
																</button>
																:
																<button
																	className="btn btn-primary" onClick={this.handleCategoryAdd}>
																	{i18n.t("Add")}
																</button>
															}

														</Modal.Footer>
													</Modal>



													<div className="add-category-infor-here">
														<div className={stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0 ? 'add-metric-head-none' : 'add-metric-head'}>
															<div type="button" className="btn add-category-btn" onClick={this.handleCategoryShow}>{i18n.t("Add_Category_Details")}</div>
															<div className="add-sub-level-metrics">
																<i class="far fa-file-plus"></i>
																<p><span>*</span>
																	{i18n.t("Add_category_details_as_sub_level_metrics")}
																</p>
															</div>
														</div>
														{stateKpiData.CategoryDetails &&
                                stateKpiData.CategoryDetails.length > 0 &&
                            <div className="category-header">
                                  <span>
                                    {i18n.t("Category")} -{" "}
                                    {stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0
                                      ? stateKpiData.CategoryDetails[0].CategoryType
                                      : ""}
                                  </span>
                                  <div className="category-actions">
                                  <div className="category-add" onClick={this.handleCategoryTypeAddShow}>
                                  <i class="fal fa-plus-circle mr-3"></i>
                                  </div>
                                  <span onClick={() => this.deleteCategoryType()}>
                                    <i class="fas fa-trash-alt delete-icon-fix"></i>
                                  </span>
                                  </div>
                                </div>
                                }

														<div className={stateKpiData.CategoryDetails && stateKpiData.CategoryDetails.length > 0 && stateKpiData.IsMultiLevel ? 'category-lists' : 'category-lists-none'}>
															<ul>
																{items}
															</ul>
														</div>
													</div>

													{/* {Add Category Type} */}
													<Modal className="addCategoryModal" aria-labelledby="contained-modal-title-vcenter" centered show={this.state.showaddCategoryType} onHide={this.handleaddCategoryClose} animation={false} backdrop="static">
														<Modal.Header>
															<Modal.Title id="contained-modal-title-vcenter">{i18n.t("Add_Category_Type")}</Modal.Title>
														</Modal.Header>
														<Modal.Body>
															<div className="row">
																<div className="form-group col-lg-12">
																	<label>{i18n.t("New_Category")}
																		<span className="required"> *</span>

																	</label>
																	<input id="categoryType" type="text" maxLength="100" className="form-control" onChange={(e) => { this.bindToolFieldChange(e) }} value={this.state.CategoryDetails.CategoryType} />

																	<span className="errorMsg">
																		{this.state.errors.CategoryType}
																	</span>

																</div>
																<div className="form-group col-lg-12">
																	<label>{i18n.t("Name")}

																		<span className="required"> *</span>

																	</label>
																	<input id="categoryName" type="text" maxLength="100" className="form-control" onChange={(e) => { this.bindToolFieldChange(e) }} value={this.state.CategoryDetails.CategoryName} />


																	<span className="errorMsg">
																		{this.state.errors.CategoryName}
																	</span>

																</div>
																<p className="text-right newlyadded">
																{i18n.t("Newly_added_category_type_will_be_sent_for_approval")}
																</p>
															</div>
														</Modal.Body>
														<Modal.Footer>
															<button className="btn btn-default btn-sm mr-2" onClick={this.handleaddCategoryClose}>
																{i18n.t("Cancel")}
															</button>
															<button className="btn btn-primary" onClick={this.handleaddCategoryAdd}>
																{i18n.t("Add")}
															</button>
														</Modal.Footer>
													</Modal>

													{/* {Add Category Name} */}
													<Modal className="addCategoryModal" aria-labelledby="contained-modal-title-vcenter" centered show={this.state.showaddCategory} onHide={this.handleaddCategoryNameClose} animation={false} backdrop="static">
														<Modal.Header>
															<Modal.Title id="contained-modal-title-vcenter"> {i18n.t("Add_Category_Name")}</Modal.Title>
														</Modal.Header>
														<Modal.Body>
															<div className="row">
																<div className="form-group col-lg-12">
																	<label>{i18n.t("Category_Type")}
																		<span className="required"> *</span>

																	</label>

																	<input type="text" maxLength="100" className="form-control" id="" value={this.state.CategoryDetails.CategoryType} disabled />

																</div>

																<div className="form-group col-lg-12">
																	<label>{i18n.t("Name")}

																		<span className="required"> *</span>
																	</label>

																	<input id="categoryName" type="text" maxLength="100" className="form-control" onChange={(e) => { this.bindToolFieldChange(e) }} value={this.state.CategoryDetails.CategoryName} />

																	<div className="errorMsg">
																		{this.state.errors.CategoryName}
																	</div>


																</div>

																<p className="text-right newlyadded">{i18n.t("Newly_added_category_name_will_be_sent_for_approval")}</p>
															</div>
														</Modal.Body>
														<Modal.Footer>
															<button className="btn btn-default btn-sm mr-2" onClick={this.handleaddCategoryNameClose}>
																{i18n.t("Cancel")}
															</button>
															<button className="btn btn-primary" onClick={this.handleaddCategoryAdd}>
																{i18n.t("Add")}
															</button>
														</Modal.Footer>
													</Modal>


													{/* Approval Notification */}
													<Modal className="approvalModal" aria-labelledby="contained-modal-title-vcenter" centered show={this.state.showApproval} onHide={this.handleApprovalClose} animation={false} backdrop="static">
														<Modal.Header closeButton>
															<Modal.Title>
																{i18n.t("Approval_Notification")}
															</Modal.Title>
														</Modal.Header>
														<Modal.Body>
														<div className="row">
														{  this.state.CatTypeID==="addnew" ?
                                                             <p className="approvalInfo">
                                                                    {i18n.t("Newly_added_category_type_is_sent_for_approval")}
                                                                </p>:
                                                                    <p className="approvalInfo">
                                                                    {i18n.t("Newly_added_category_name_is_sent_for_approval")}
                                                                </p>
                                                                  }
                                                            </div>
														</Modal.Body>
													</Modal>
												</div>
											</div>
										</div>

										<div className="errorMsg">
											{this.state.errors.MultiLevel}
										</div>
									</div>
									{/* end of multi level UI */}

								</div>
							</div>
						</form>
						{/* : <p>NO DATA / Not Selected Client,Offering and Location</p>} */}

						{/* : <p>Please Select Client,Offering and Location </p>} */}
					</Modal.Body>
					{
						<Modal.Footer>
							<div className="modal-btn">
								{/* <input type="Button" className="btn btn-primary btn-sm"   onClick={() => this.handleSave()} value="Save" /> */}
								<button
									onClick={this.handleHide}
									className="btn btn-default btn-sm mr-2"
								>
									{i18n.t("Cancel")}
								</button>

								{!this.props.edit ? (
									<input
										type="Button"
										className="btn btn-primary btn-sm"
										onClick={() => this.handleSave()}
										value={i18n.t("Create")}
									/>
								) : (
										<input
											type="Button"
											className="btn btn-primary btn-sm"
											onClick={() => this.handleSave()}
											value={i18n.t("Update")}
										/>
									)}
							</div>
						</Modal.Footer>
					}
				</Modal>
				{this.state.viewmore ? (
					<KpiPopUp
						SOCategoryID={this.props.SOCategoryID}
						show={true}
						onClose={this.onClose.bind(this)}
						filterDetails={this.props.filterDetails}
						tabName="DD"
					/>
				) : null}
			</>
		);
	}
}

// function mapStateToProps(state){
//     return{
//         kpiDatas:state.kpiDatas
//     }
// }
// //converts actions from redux to props in view
// function mapDispatchToProps(dispatch){
//     return{
//         addHuman:()=>dispatch(humanActions.addHuman())
//     }
// }

// export default connect(mapStateToProps,mapDispatchToProps)(HumanPopUp);
