import React, { Component } from "react";
//import $ from 'jquery';

import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import jquery from "jquery";
import { store } from "../reducers/configureStore";
window.$ = window.jQuery = jquery;

export default class DiverseMetricDetails extends Component {
  state = { filtername: null };

  componentDidMount() {
    this.setState({
      filtername: store.getState().CurrentFilter.Filter.offeringName,
    });
  }
  //{}
  // currentFilterName = store.getState().CurrentFilter.Filter.offeringName;


  render() {
    const options = {
      loop: true,
      margin: 16,
      responsiveClass: "",
      dots: false,
      responsive: {
        0: {
          items: 1,
          nav: true
        },
        600: {
          items: 2,
          nav: false
        },
        1000: {
          items: 3,
          nav: true,
          loop: false
        },
        1440: {
          items: 4,
          nav: true,
          loop: false
        },
        1600: {
          items: 4,
          nav: true,
          loop: false
        },
        1800: {
          items: 5,
          nav: true,
          loop: false
        }
      }
    };
    return this.props.Details.map((eachDetails) => {
      //if (eachDetails.KpiDatas != undefined) {
      let metricName = eachDetails.AttributeName;
      let metricData = eachDetails.AttributeValue;
      let unit = this.props.kpiDetailsUnit;
      unit = unit.trim();
      let unit_en = this.props.unit_en;
      unit_en = unit_en.trim();
      //}
      //   const formatter=new Intl.NumberFormat('en',{ CODE FOR ALL TYPE OF CURRENCY FORMAT
      //     style:'currency',
      //     currency:'USD',
      //     notation:'standard'
      // })
      const formattertwo = new Intl.NumberFormat('en', {//SUJITH MADHYASTHA
        useGrouping: true
      })
      return (
        <OwlCarousel options={options} className={`owl-carousel`}>
          <div className="item benchmark-insights">
          
          </div>
        </OwlCarousel>
        // <div className="item outcome-divider no-whiteShadow gray-clr">
        //    <div className="outcome-indicate">
        //      <div className={`rd-progress ${
        //         unit_en === "%" && metricData
        //           ? `${
        //               Number.isInteger(Number(metricData))
        //                 ? "p" + metricData
        //                 : "p" + Math.round(metricData)
        //             }`
        //           : ""
        //       }`}
        //     >
        //       <span>
        //         {metricData && !isNaN(metricData)
        //           ?`${
        //             Number.isInteger(Number(metricData))
        //             ? unit==="$"?unit+formattertwo.format(metricData):formattertwo.format(metricData)
        //             : unit==="$"?unit+formattertwo.format(Number(metricData).toFixed(2)):formattertwo.format(Number(metricData).toFixed(2))
        //             }`
        //           : ""}
        //         {metricData && !isNaN(metricData) && (
        //           <>
        //                           {unit && unit.trim() !== "$" && (unit_en.trim() != "%" ? (
        //               <>
        //                 &nbsp;<span className="metric-digit-text">{unit}</span>
        //               </>
        //             ) : (
        //               unit
        //             ))}
        //           </>
        //         )}
        //       </span>
        //       <div className="rd-slice">
        //         <div className="rd-bar"></div>
        //         <div className="rd-fill"></div>
        //       </div>
        //     </div> 
        //     <p>{metricName}</p>
        //   </div> 
        // </div>
      );
    });
  }
}
