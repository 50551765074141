import React, { useEffect, useState, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import "./homepage.css";
import { store } from "../reducers/configureStore";
import ReactTooltip from "react-tooltip";
import Dropdown from "react-bootstrap/Dropdown";
import i18n from "i18next";
import SynopsLoader from "../Shared/SynopsLoader";
import { initReactI18next } from "react-i18next";
import accentureLogo from "../Images/AccentureLogoPurple.svg";
import { NavLink } from "react-router-dom";
import ClientClose from "../Images/close-clientcard.svg";
import Client from "../Images/client-0.svg";
//import Client from "../images/DefaultClientCard.svg"
import defaultofferingImage from "../Images/DefaultClientCard.svg";
import { connect } from "react-redux";
import moment from "moment";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import _ from "lodash";
import { screeNamesForUserTracking, logUserTrackingData } from "../Shared/commonFunctions";
import axiosInstance from "../Shared/interceptor";
import EnglishLiterals from "../EnglishLiterals.json";
import JapaneseLiterals from "../JapaneseLiterals.json";
import { LocalApiBaseUrl, LocalApiBaseUrlImage } from "../Shared/Constant";
import LoadingOverlay from "react-loading-overlay";
import $ from "jquery";

function ClientSelectPopup(props) {
  let OrgEntityImage = "";
  let literals2 = {
    eng: { translation: EnglishLiterals },
    jpn: { translation: JapaneseLiterals },
  };
  let style = {
    paddingTop: "10px",
  };
  const [viewPopup, setviewPopup] = useState(false);
  const [isAllClientsClicked, setisAllClientsClicked] = useState(false);
  const [isFavouritesClicked, setisFavouritesClicked] = useState(false);
  const [filterClient, setfilterClient] = useState([]);
  const [OfferingGroups, setOfferingGroups] = useState([]);
  const [NofilterClient, setNofilterClient] = useState([]);
  const [search, setsearch] = useState("");
  const [OfferingData, setOfferingData] = useState([]);
  const [Nooffering, setNooffering] = useState(false);
  const [clientDataGrid, setclientDataGrid] = useState(props.OperatingGropus.filter((f) => f.IsEverPublished === true));
  const [tooltipclientid, settooltipclientid] = useState("");
  const [OfferingType, setOfferingType] = useState("See All");
  const [SortByName, setSortByName] = useState("Sort");
  const [OfferingName, setOfferingName] = useState("");
  const [SortBy, setSortBy] = useState("");
  const [CurrentId, setCurrentId] = useState(null);
  const [SortByID, setSortByID] = useState(null);
  const [FilteredDataCount, setFilteredDataCount] = useState(null);
  const [OfferingHtml, setOfferingHtml] = useState([])

  useEffect(() => {
    let { OperatingGropus, FunctionalIndustryOffering } = props;
    if (OperatingGropus && OperatingGropus !== undefined && OperatingGropus.length > 0) {
      $(".offering-dropdownsubmenu .dropdown-toggle").on("click", function (e) {  
        if(document.getElementById("#Indusoff").classList.contains("hover-active")){          
          document.getElementById("#Indusoff").classList.remove("hover-active");
        }    
        if(document.getElementById("#funcoff").classList.contains("hover-active")){          
          document.getElementById("#funcoff").classList.remove("hover-active");
        }   
        $(this)
          .next()
          .find(".dropdown-menu")
          .hide();
      });
      $(".offering-dropdownsubmenu .dropdown-submenu a.test").on("click", function (e) {
        if(e.target.id.includes("#funcoff")){
          this.classList.add("hover-active")
          if(document.getElementById("#Indusoff").classList.contains("hover-active")){
            document.getElementById("#Indusoff").classList.remove("hover-active");
          }
        }
        if(e.target.id.includes("#Indusoff")){
          this.classList.add("hover-active")
          if(document.getElementById("#funcoff").classList.contains("hover-active")){
            document.getElementById("#funcoff").classList.remove("hover-active");
          }
        }
        $(this)
          .parent()
          .siblings("li")
          .find("ul")
          .hide();
        $(this)
          .next("ul")
          .toggle();
        e.stopPropagation();
        e.preventDefault();
      });
      document.getElementById("#seall").classList.add("active");
      let offerings = [];
      let OfferingGroups = [];
      let industries = [];
      let clientRegions = [];
      ReactTooltip.hide();
      // Reset the Synops Sync Filters
      store.dispatch({
        type: "SYNC_CURRENTFILTER",
        payload: {},
      });

      let visited = localStorage["alreadyVisited"];
      if (visited) {
        setviewPopup(false);
        //do not view Popup
      } else {
        //this is the first time
        localStorage["alreadyVisited"] = true;
        setviewPopup(true);
      }

      let FavouriteClients = OperatingGropus.filter((client) => {
        if (client.IsFavourite === true && client.IsEverPublished === true) {
          return client;
        }

        return null;
      });
      if (FavouriteClients.length === 0) {
        setisAllClientsClicked(true);
        setisFavouritesClicked(false);
      }
      if (FavouriteClients.length !== 0) {
        setisAllClientsClicked(false);
        setisFavouritesClicked(true);
      }

      let filterClient = OperatingGropus.filter((client) => {
        if (
          client.OrgEntityName.toLowerCase().indexOf(search.toLowerCase().trim()) !== -1 &&
          client.IsEverPublished === true
        ) {
          return client;
        }
        return null;
      });
      // To pull all the Industries/Offerings/Region values for each client
      filterClient.forEach(function (item) {
        offerings = offerings.concat(item.Offerings);
        industries = industries.concat(item.Industries);
        clientRegions = clientRegions.concat(item.ClientRegions);
        //OfferingGroups = OfferingGroups.concat(item.OfferingGroup);
      });

      // To find the distinct value for  Industries/Offerings/Region and sort them
      industries = [...new Set(industries)];
      offerings = [...new Set(offerings)];
      clientRegions = [...new Set(clientRegions)];
      //OfferingGroups = [...new Set(OfferingGroups)];

      //OfferingGroups=JSON.parse(JSON.stringify(FunctionalIndustryOffering));

      //let FunctionalOfferings = [],IndustryOfferings = [];

      FunctionalIndustryOffering.forEach((a) => {
        if (offerings.includes(a.OfferingName)) {
          OfferingGroups.push(a);
        }
      });


      OfferingGroups = [
        {
          name: "Functional Offerings",
          offering: OfferingGroups.filter((a) => a.OfferingGroupName === "Functional Offerings").map((a) => {
            return {
              Name: a.OfferingName,
              Icon: a.OfferingIcon,
            };
          }),
        },
        {
          name: "Industry Offerings",
          offering: OfferingGroups.filter((a) => a.OfferingGroupName === "Industry Offerings").map((a) => {
            return {
              Name: a.OfferingName,
              Icon: a.OfferingIcon,
            };
          }),
        },
      ];


      setfilterClient(filterClient);
      setNofilterClient(filterClient);
      setOfferingGroups(OfferingGroups);
      //Setting Favourites By Default
      if (filterClient.some((a) => a.IsFavourite)) {
        setSortByData({}, "Favourite", "Favourites");
        if (document.getElementById("#favour") !== undefined && document.getElementById("#favour") !== null) {
          document.getElementById("#favour").classList.add("active");
        }

        setSortByID("#favour");
      }
    }
  }, [props.OperatingGropus]);

  const removeDuplicates = (arr) => {
    /*NEW CODE WHICH REMOVES DUPLICATES DATA FROM API */
    const map = new Map();
    arr.forEach((v) => map.set(v.Name, v));
    return [...map.values()];
  };
  const handleUserNavigation = (client) => {
    const { languageData } = props;
    let lang = languageData.langCode == "eng" ? "en" : languageData.langCode;
    const data = {
      PageName: screeNamesForUserTracking.ClientSelect,
      Client: client,
      Language: lang,
    };
    logUserTrackingData(data);
  };
  const onSelectHandle = (val) => {
    props.saveCommentHistoryId({
      SOCategoryID: "",
      MetricDataID: "",
      showHistory: false,
    });

    let clientData = {
      name: val.OrgEntityName,
      id: val.OrgEntityID,
      abbr: val.OrgEntityName.substr(0, 2),
      imageName: val.OrgEntityImage,
    };
    props.saveClientData(clientData);

    // let clientsData = props.OperatingGropus.filter(
    //   (f) => f.IsEverPublished === true
    // );
    // let languageDropDown = [];
    // let LanguageID = 1;
    // let LanguageCode = "en";
    sessionStorage.setItem("clientSelection", JSON.stringify(clientData));
    // clientsData.filter((each) => {
    //   if (each.OrgEntityID === parseInt(val.OrgEntityID)) {
    //     languageDropDown = each.LanguageMasterModels;
    //     LanguageID = each.DefaultLanguage;
    //     languageDropDown.filter((e) => {
    //       if (e.LanguageID === LanguageID) {
    //         LanguageCode = e.LanguageCode;
    //       }
    //     });
    //   }
    // });
    handleUserNavigation(val.OrgEntityName_En);
    // let literalsApiStatus = false;
    // axiosInstance
    //   .get(`${LocalApiBaseUrl}TranslatedLiterals/GetTranslatedText`, {
    //     params: {
    //       LanguageID: LanguageID,
    //       OrgEntityID: parseInt(val.OrgEntityID),
    //       setDefault: false,
    //     },
    //   })
    //   .then((response) => {
    //     if (response.data) {
    //       let data = {
    //         id: parseInt(LanguageID),
    //         langCode: LanguageCode,
    //         messages: response.data,
    //         languageDropDown: languageDropDown,
    //       };
    //       let literals = {};
    //       literals[LanguageCode] = { translation: response.data };
    //       i18n.use(initReactI18next).init({
    //         resources: literals,
    //         lng: LanguageCode,
    //         fallbackLng: "en",

    //         interpolation: {
    //           escapeValue: false,
    //         },
    //       });

    //       props.saveLangData(data);
    //       continueHandle(clientData, val);
    //     }
    //   })
    //   .catch((err) => {
    //     let data = {
    //       id: parseInt(LanguageID),
    //       langCode: LanguageCode,
    //       messages: {},
    //       languageDropDown: languageDropDown,
    //     };
    //     i18n.use(initReactI18next).init({
    //       resources: literals2,
    //       lng: "en",
    //       fallbackLng: "en",

    //       interpolation: {
    //         escapeValue: false,
    //       },
    //     });
    //     props.saveLangData(data);
    //     continueHandle(clientData, val);
    //   });
    continueHandle(clientData, val);
  };
  const continueHandle = async (clientData, val) => {
    props.selectedFilter(clientData);

    await getSnapshot(clientData)

  };

 const getSnapshot = (clientData) => {

    const isPulsePageAccessAllowed = checkIfPulsePageAccessAllowed();
    const client = clientData.id;
    
    return new Promise((resolve,reject)=>{
      if (client !== undefined && client > 0 && client !== '') {
        axiosInstance
          .get(`${LocalApiBaseUrl}Snapshot/GetSnapshotsByUserID`, {
            params: { OrgEntityID: client, LanguageID: props.languageData.id },
          })
          .then((response) => {
            store.dispatch({
              type: 'SNAPSHOT',
              payload: response.data,
            });
            if (response.data != null && response.data.length > 0) {
              const defaultSS = response.data.filter((f) => f.IsDefault === true);
              if (defaultSS.length > 0) {
                store.dispatch({
                  type: 'SYNC_CURRENTFILTER',
                  payload: {
                    OfferingId: defaultSS[0].OfferingID,
                    ProcessId: defaultSS[0].OfferingID,
                    LocationId: defaultSS[0].LocationID,
                    Month: '',
                    LocationOMID: defaultSS[0].PageName === "Journey Initiatives" ?  ["All"]:[],
                    IsDataPublishForLocation: false,
                    isOMIDAvailable: false,
                    OfferingName: defaultSS[0].Offering_En,
                  },
                });
                  if(defaultSS[0].PageName !== "Pulse")
                  {
                    props.history.push({
                      pathname: "/Dashboard",
                      state: { Tab: defaultSS[0].PageName,
                        ClientID: client,
                        pulseMenu: false },
                    });
                    resolve()
                    //onTabChange(defaultSS[0].PageName) 
                  }
                  else
                  {
                    if(isPulsePageAccessAllowed){
                      props.history.push({
                        pathname: "/landing",
                        state: { pulseMenu: true },
                      });
                    }
                    else
                    {
                      props.history.push({
                        pathname: "/Dashboard",
                        state: { pulseMenu: false },
                      });
                    }
                  
                  }
              }
              else
              {
                if(isPulsePageAccessAllowed){
                  props.history.push({
                    pathname: "/landing",
                    state: { pulseMenu: true },
                  });
                }
                else
                {
                  props.history.push({
                    pathname: "/Dashboard",
                    state: { pulseMenu: false },
                  });
                }
              }
              
            }
            else
            {
              if(isPulsePageAccessAllowed){
                props.history.push({
                  pathname: "/landing",
                  state: { pulseMenu: true },
                });
              }
              else
              {
                props.history.push({
                  pathname: "/Dashboard",
                  state: { pulseMenu: false },
                });
              }
            }
            resolve()
          })
          .catch((err) => {
            if(isPulsePageAccessAllowed){
              props.history.push({
                pathname: "/landing",
                state: { pulseMenu: true },
              });
            }
            else
            {
              props.history.push({
                pathname: "/Dashboard",
                state: { pulseMenu: false },
              });
            }
            reject()
           });
      } else {
        reject()
      }

    })
  
  };

  const checkIfPulsePageAccessAllowed = () => {
    const { roleData } = props;
    const foundPermissionObj = _.find(roleData, { FeatureName: "PulsePage" });

    let hasAccessPermission = false;
    if (!_.isEmpty(foundPermissionObj)) {
      const allowedOperations = foundPermissionObj.Operations;

      // ACCESS WOULD ONLY BE ALLOWED IF THE USER HAS EITHER "VIEW" OR "EDIT" PERMISSION
      if (allowedOperations.indexOf("View") || allowedOperations.indexOf("Edit")) {
        hasAccessPermission = true;
      }
    }

    return hasAccessPermission;
  };
  const handleImageError = (e, status) => {
    if (status === "Client") {
      e.target.src = Client;
    } else {
      e.target.src = defaultofferingImage;
    }
  };
  const getofferingData = (OrgEntityID) => {
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
        params: { OrgEntityID: OrgEntityID, LanguageID: props.languageData.id },
      })
      .then((response) => {
        setNooffering(false);
        setOfferingData(response.data);
        let data = response.data.map((b, index) => {
          return (
              <li>
                  <img
                      src={`${LocalApiBaseUrlImage}${b.ChildOfferings[0].OfferingImage}`}
                      alt=""
                      width="20"
                      height="20"
                      onError={(e) => {
                          handleImageError(e, "Offering");
                      }}
                      loading="lazy"
                  />
                  <div>
                      <p>{b.OfferingName}</p>
                      <span>
                          <b>{b.ChildOfferings.length}</b> Sub-Offerings
</span>
                  </div>
              </li>
          );
      })
      setOfferingHtml(data)
       
      });
  };

  useEffect(() => {
    ReactTooltip.rebuild();
    if (tooltipclientid !== "") {
      getofferingData(tooltipclientid);
    }
  }, [tooltipclientid]);

  const onSelectHandleOffering = (e, val) => {
    e.stopPropagation();
    ReactTooltip.hide();
    if (val.Offeringcount > 0) {
      if (val.OrgEntityID !== tooltipclientid) {
        ///TO PREVENT EVENTBUBBLING
        settooltipclientid(val.OrgEntityID);
        setOfferingData([]);
      }
    } else {
      settooltipclientid(val.OrgEntityID);
      setOfferingData([]);
      setNooffering(false);
    }
  };

  const temp = (val, e) => {
    let filterclientforfavourite = filterClient;
    if (val.IsFavourite === false) {
      //if (e.target.className == "fas fa-star inactive") {
      let value = val;
      value.IsFavourite = true;

      filterclientforfavourite = filterclientforfavourite.map((client) => {
        if (client.OrgEntityID === val.OrgEntityID) {
          client.IsFavourite = true;
        }
        return client;
      });
      axiosInstance.post(`${LocalApiBaseUrl}User/SetClientAsFavourite`, value).then((response) => { });
    } else {
      filterclientforfavourite = filterclientforfavourite.map((client) => {
        if (client.OrgEntityID === val.OrgEntityID) {
          client.IsFavourite = false;
          axiosInstance.post(`${LocalApiBaseUrl}User/SetClientAsFavourite`, val).then((response) => { });
        }
        return client;
      });
      if (SortBy === "Favourite") {
        filterclientforfavourite = filterclientforfavourite.filter((client) => {
          if (client.OrgEntityID === val.OrgEntityID) {
            let value = val;
            value.IsFavourite = false;

            axiosInstance.post(`${LocalApiBaseUrl}User/SetClientAsFavourite`, value).then((response) => { });
            return null;
          }

          return client;
        });
      }
      setFilteredDataCount(filterclientforfavourite.length);
    }

    setfilterClient(filterclientforfavourite);
    /* this.setState(
    {
      // FavouriteClients: FavouriteClients,
      clientDataGrid: ClientDataGrid,
    },
    () => {
      this.groupByClient(this.state.groupFilterType, this.state.groupSelectedFilterValues);
    }
  ); */

    e.stopPropagation();
  };

  useEffect(() => {
    ReactTooltip.rebuild();
    if (search !== "" || OfferingName !== "" || SortBy !== "") {
      commonsearchFilter(SortBy, OfferingName, search);
    }
  }, [search, OfferingName, SortBy]);

  const onScrollDiv = useRef(null);
  const handleScroll = () => {
    ReactTooltip.hide();
  };

  const handleSearchQuery = (e) => {
    /*  if (
      props.OperatingGropus !== undefined &&
      props.OperatingGropus !== null &&
      props.OperatingGropus.length > 0
    ) {
      commonsearchFilter(OfferingType, OfferingName, e.target.value);
    } */

    setsearch(e.target.value);
  };
  const setSortByData = (e, value, Name) => {
    setSortBy(value);
    setSortByName(Name);
    if (Object.entries(e).length > 0) {
      if (SortByID != null && document.getElementById(SortByID).classList.contains("active")) {
        document.getElementById(SortByID).classList.remove("active");
      }
      setSortByID(e.target.id);
      document.getElementById(e.target.id).classList.add("active");
    }
  };
  const setOfferingTypeName = (e, Type, Value) => {
    if (Object.entries(e).length > 0) {
      if (e.target.id.includes("#funcoff")) {
        if (document.getElementById("#seall").classList.contains("active")) {
          document.getElementById("#seall").classList.remove("active");
        }

        if (document.getElementById("#Indusoff").classList.contains("active")) {
          document.getElementById("#Indusoff").classList.remove("active");
        }
        document.getElementById("#funcoff").classList.add("active");
        if (CurrentId != null) {
          document.getElementById(CurrentId).classList.remove("active");
        }
        document.getElementById(e.target.id).classList.add("active");
        setCurrentId(e.target.id);
      } else if (e.target.id.includes("#Indusoff")) {
        if (document.getElementById("#seall").classList.contains("active")) {
          document.getElementById("#seall").classList.remove("active");
        }

        if (document.getElementById("#funcoff").classList.contains("active")) {
          document.getElementById("#funcoff").classList.remove("active");
        }
        document.getElementById("#Indusoff").classList.add("active");
        if (CurrentId != null) {
          document.getElementById(CurrentId).classList.remove("active");
        }
        document.getElementById(e.target.id).classList.add("active");
        setCurrentId(e.target.id);
      } else {
        if (document.getElementById("#funcoff").classList.contains("active")) {
          document.getElementById("#funcoff").classList.remove("active");
        }
        if (document.getElementById("#Indusoff").classList.contains("active")) {
          document.getElementById("#Indusoff").classList.remove("active");
        }
        if (CurrentId != null) {
          document.getElementById(CurrentId).classList.remove("active");
        }
        document.getElementById("#seall").classList.add("active");
        setCurrentId(null);
      }
      setOfferingType(Type);
      setOfferingName(Value);
    } else {
      setSortBy("See All");
      setSortByName("See All");
      if (SortByID != null && document.getElementById(SortByID).classList.contains("active")) {
        document.getElementById(SortByID).classList.remove("active");
      }
    }
  };
  const commonsearchFilter = (SortBy, OfferingName, query) => {
    let TempData = [];
    NofilterClient.filter((obj) => {
      Object.keys(obj).forEach((key) => {
        if (
          key === "OrgEntityName" &&
          obj[key] !== null &&
          obj[key]
            .toString()
            .toUpperCase()
            .includes(query.trim().toUpperCase())
        ) {
          if (!TempData.includes(obj)) {
            TempData.push(obj);
          }
        }
      });
    });
    if (OfferingName !== "" && OfferingName !== "See All") {
      TempData = TempData.filter((c) => c.Offerings.length > 0 && c.Offerings.includes(OfferingName));
    }

    if (SortBy !== "" && SortBy !== "See All") {
      if (SortBy === "ASC") {
        TempData = TempData = _.orderBy(
          TempData,
          function (o) {
            return new moment(o.ProfileCreatedDate);
          },
          ["asc"]
        );
      } else if (SortBy === "DSC") {
        TempData = _.orderBy(
          TempData,
          function (o) {
            return new moment(o.ProfileCreatedDate);
          },
          ["desc"]
        );
      } else {
        TempData = TempData.filter((a) => a.IsFavourite);
        //TempData = TempData.sort((a,b) => b.IsFavourite-a.IsFavourite);
      }
    }
    TempData = TempData.filter((a) => a.IsEverPublished);

    setFilteredDataCount(TempData.length);
    setfilterClient(TempData);
  };

  return (
    <>
      <LoadingOverlay active={props.isorgapiloading} spinner={<SynopsLoader />} />
      <Modal
        show={props.clientStoriesModalStatus}
        onHide={() => {
          props.clientStoriesModalClose();
        }}
        className="clientstories-modal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="header-searchbar mb-1">
            <h4>
              My Client Stories{" "}
              <span>
                (
                  { 
                    FilteredDataCount !== null ? FilteredDataCount : (props.userclientCount > 9
                    ? props.userclientCount
                    : "0" + props.userclientCount)
                  }
                )             
              </span>
            </h4>
            <div className="ml-auto d-flex align-items-center">
              <div className="searchFilter mr-3">
                <input type="search" placeholder="Search..." value={search} onChange={handleSearchQuery} />
              </div>
              <div className="dropdown clientprojects-dropdown custom-headerdropdown mr-4">
                <button id="dropdown-clientprojects" className="btn dropdown-toggle" type="button" data-toggle="dropdown">
                  <i class="fal fa-plus-circle mr-2"></i>
                  {i18n.t("Client Profiles")}
                </button>
                <ul className="dropdown-menu">
                  <li>
                  <NavLink className="addClient" to="#" onClick={() => props.addClientProjectShow()}>
                    {i18n.t("Add_Client_Profile")}
                  </NavLink></li>
                 
                  {(props.isProfileExist || props.userRole.IsAdmin) && (
                     <li>
                    <NavLink className="viewClient" to="/viewclientproject">
                      {i18n.t("View_Client_Profile")}
                    </NavLink></li>
                  )}
                </ul>
              </div>
              <button
                type="button"
                class="close"
                onClick={() => {
                  props.clientStoriesModalClose();
                }}
              >
                {/* <span aria-hidden="true">×</span> */}
                <img src={ClientClose} aria-hidden="true" />
                <span class="sr-only">CLose</span>
              </button>
            </div>
          </div>
          {props.OperatingGropus !== undefined && props.OperatingGropus !== null && props.OperatingGropus.length > 0 ? (
            <>
              <div className="clientfilters-section mb-3">
                <div className="clientfilters-dropdownlist d-flex align-items-center">
                  <div
                    className={`clientfilters-dropdownname all-offering-dropdown mr-3 pr-3 ${OfferingType !== "" ? "active" : ""
                      }`}
                  >
                    <span className="settings-icon">Offering</span>
                    <div className="dropdown clientfilters-dropdown offering-dropdownsubmenu">
                      <button className="btn dropdown-toggle" type="button" data-toggle="dropdown">
                        {OfferingType !== "See All" ? OfferingName : OfferingType}
                      </button>
                      <ul className="dropdown-menu parent-menu">
                        <li className="dropdown-submenu seeall-menuitem">
                          <span
                            id="#seall"
                            className="See-all"
                            onClick={(e) => {
                              setOfferingTypeName(e, "See All", "See All");
                            }}
                          >
                            See All
                          </span>
                        </li>
                        {/* {OfferingGroups.map((off,index)=>
                        <li className="dropdown-submenu">
                          <a tabindex="-1" href="#" className="test">
                            {off.name}
                          </a>
                          <ul className="dropdown-menu">
                            {off.offering.map((each)=>
                              <li>
                              <a
                                tabindex="-1"
                                href="#"
                                onClick={(e) => {
                                  setOfferingTypeName(off.name,each.Name);
                                }}
                              >
                                <span className="network-icon"></span>{each.Name}
                              </a>
                            </li>
                              
                              )}
                          </ul>
                        </li>)
                        } */}
                        <li className="dropdown-submenu">
                          <a tabindex="-1" className="test" id="#funcoff">
                            Functional Offerings
                          </a>
                          <ul className="dropdown-menu">
                            {OfferingGroups.length > 0 &&
                              OfferingGroups[0].offering.map((each, index) => (
                                <li>
                                  <a
                                    id={`#funcoff${index}`}
                                    tabindex="-1"
                                    onClick={(e) => {
                                      setOfferingTypeName(e, OfferingGroups[0].name, each.Name);
                                    }}
                                  >
                                    <span style={style}>
                                      <img
                                        src={`${LocalApiBaseUrlImage}${each.Icon}`}
                                        alt=""
                                        width="15"
                                        height="15"
                                        loading="lazy"
                                        onError={(e) => {
                                          handleImageError(e, "Offering");
                                        }}
                                      />
                                    </span>
                                    {each.Name}
                                  </a>
                                </li>
                              ))}
                          </ul>
                        </li>
                        <li className="dropdown-submenu">
                          <a tabindex="-1" className="test" id="#Indusoff">
                            Industrial Offerings
                          </a>
                          <ul className="dropdown-menu">
                            {OfferingGroups.length > 0 &&
                              OfferingGroups[1].offering.map((each, index) => (
                                <li>
                                  <a
                                    id={`#Indusoff${index}`}
                                    tabindex="-1"
                                    onClick={(e) => {
                                      setOfferingTypeName(e, OfferingGroups[1].name, each.Name);
                                    }}
                                  >
                                    <span style={style}>
                                      <img
                                        src={`${LocalApiBaseUrlImage}${each.Icon}`}
                                        alt=""
                                        width="15"
                                        height="15"
                                        loading="lazy"
                                        onError={(e) => {
                                          handleImageError(e, "Offering");
                                        }}
                                      />
                                    </span>
                                    {each.Name}
                                  </a>
                                </li>
                              ))}
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="clientfilters-dropdownname ml-3"></div> */}
                   <div className={`clientfilters-dropdown custom-headerdropdown sort-icondropdown ml-3 ${
                      SortBy !== "" ? "active" : ""
                    }`}>
                   <div className="dropdown">
                      <button className="btn dropdown-toggle" type="button" data-toggle="dropdown" id="sort-clientprojects">
                        {SortByName}
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            id="#seeAllf"
                            href="javascript:void(0);"
                            onClick={(e) => setOfferingTypeName({}, "See All", "See All")}
                            active={SortBy === "See All"}
                            className={SortBy === "See All" ? "active": ""}
                          >
                            See All
                          </a>
                        </li>
                        <li>
                          <a
                            id="#favour"
                            href="javascript:void(0);"
                            onClick={(e) => setSortByData(e, "Favourite", "Favourites")}
                            active={SortBy === "Favourite"}
                          >
                            Favourites
                          </a>
                        </li>
                        <li>
                          <a
                            id="#asc"
                            href="javascript:void(0);"
                            onClick={(e) => setSortByData(e, "ASC", "Oldest")}
                          >
                            Oldest
                          </a>
                        </li>
                        <li>
                          <a
                            id="#dsc"
                            href="javascript:void(0);"
                            onClick={(e) => setSortByData(e, "DSC", "Newest")}
                          >
                            Newest
                          </a>
                        </li>
                      </ul>
                      </div>
                  </div> 
                   {/* <Dropdown
                    className={`clientfilters-dropdown custom-headerdropdown sort-icondropdown ml-3 ${
                      SortBy !== "" ? "active" : ""
                    }`}
                  >
                    <Dropdown.Toggle id="sort-clientprojects">{SortByName} </Dropdown.Toggle>
                    <Dropdown.Menu menuAlign="bottom">
                      <Dropdown.Item
                        id="#seeAllf"
                        href="javascript:void(0);"
                        onClick={(e) => setOfferingTypeName({}, "See All", "See All")}
                        active={SortBy === "See All"}
                      >
                        See All
                      </Dropdown.Item>
                      <Dropdown.Item
                        id="#favour"
                        href="javascript:void(0);"
                        onClick={(e) => setSortByData(e, "Favourite", "Favourites")}
                        active={SortBy === "Favourite"}
                      >
                        Favourites
                      </Dropdown.Item>
                      <Dropdown.Item
                        id="#asc"
                        href="javascript:void(0);"
                        onClick={(e) => setSortByData(e, "ASC", "Oldest")}
                      >
                        Oldest
                      </Dropdown.Item>
                      <Dropdown.Item
                        id="#dsc"
                        href="javascript:void(0);"
                        onClick={(e) => setSortByData(e, "DSC", "Newest")}
                      >
                        Newest
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>  */}
                </div>
                {props.editAndPublishAccess() && (props.isProfileExist || props.userRole.IsAdmin) && (
                  <div className="config-navlik">
                    <NavLink to="admin/Configurator">{i18n.t("Go_to_Configurator")}</NavLink>
                  </div>
                )}
              </div>
              <div className="client-card pl-4 pr-4">
                <div
                  className="clientfilter-cards card-row client-listing client-list-offering"
                  ref={onScrollDiv}
                  onScroll={handleScroll}
                >
                  {filterClient && filterClient.length > 0 ? (
                    filterClient.map((clientval, index) => {
                      return (
                        <div key={index} className="card" onClick={(e) => onSelectHandle(clientval)}>
                          <LazyLoadComponent>
                            <div className="card-body">
                              <a href="javascript:void(0);">
                                <div className="card-text">
                                  {clientval.OrgEntityImage !== "" && clientval.OrgEntityImage !== null ? (
                                    <img
                                      src={`${LocalApiBaseUrlImage}${clientval.OrgEntityImage}`}
                                      width="72"
                                      height="72"
                                      alt=""
                                      loading="lazy"
                                      onError={(e) => {
                                        handleImageError(e, "Client");
                                      }}
                                    />
                                  ) : (
                                    <img src={Client} alt="client title" width="74" loading="lazy" height="74" />
                                  )}
                                  <span className="card-title">
                                    {/* {clientval.OrgEntityName} */}
                                    {clientval.OrgEntityName.length > 25 ? clientval.OrgEntityName.slice(0, 25) + "..." : clientval.OrgEntityName}
                                    {clientval.OrgEntityName.length > 25 && (
                                      <div className="clientname-customtooltip">
                                        <span>{clientval.OrgEntityName}</span>
                                      </div>
                                    )}
                                  </span>
                                </div>
                                <div className="card-footer">
                                  <div className="total-offerings">
                                    <p>
                                      Offerings:{" "}
                                      <span
                                        className="offering-count"
                                        data-tip
                                        data-for="offering-tooltip"
                                        data-event={"click"}
                                        onMouseEnter={(e) =>
                                          onSelectHandleOffering(e, clientval)
                                        } /* data-event-off='mouseout' */
                                      >
                                        <span>{clientval.Offeringcount}</span>
                                      </span>
                                    </p>
                                  </div>
                                  <button
                                    className="button favorite-button"
                                    data-action="favoriteToggle"
                                    onClick={(e) => {
                                      temp(clientval, e);
                                    }}
                                  >
                                    {clientval.IsFavourite === true ? (
                                      <i className="fas fa-star active star-icon-fix" aria-hidden="true"></i>
                                    ) : (
                                      <i className="fas fa-star inactive star-icon-fix" aria-hidden="true"></i>
                                    )}
                                  </button>
                                </div>
                              </a>
                            </div>
                          </LazyLoadComponent>
                        </div>
                      );
                    })
                  ) : filterClient.length === 0 && SortBy === "Favourite"  && !search? (
                    <div className="emptyFavourite">
                      <span>No Favourites Selected. Please add Favourites to display.</span>
                    </div>
                  ) : (
                    ""
                  )}

                  <ReactTooltip
                    delayShow={1000}
                    id="offering-tooltip"
                    type="light"
                    effect="solid"
                    globalEventOff="click"
                    clickable={true}
                    place="right"
                  // eventOff="mouseleave scroll mousewheel blur"
                  >
                    {/* <LoadingOverlay active={state.OfferingData.length===0} spinner text="Loading SynOps..."> */}
                    <div className="offering-tooltip">
                      <div className="offeringtooltip-heading">
                        <h6>Business</h6>
                      </div>
                      <div className="offeringtooltip-body">
                      {!Nooffering && OfferingHtml.length >0 ? (
                          <ul>
                      {OfferingHtml}
                          </ul>
                        ) : (
                          "No Offering"
                        )}
                      </div>
                    </div>
                    {/* </LoadingOverlay> */}
                  </ReactTooltip>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="main-component">
                <div className="container-fluid">
                  <div className="valueRealization-container">
                    <div className="novalueContainer">
                      <div className="novalue-component-fd">
                        <div className="novalue-fd"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ClientSelectPopup;
