import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import {regexForTextAreas} from '../Shared/Constant';

export default class DeliveryRegionApprovalPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            DeliveryRegion:{},
            errors: [],
            formIsValid: false,
        }
        this.onApproverRemarkChange = this.onApproverRemarkChange.bind(this);
        }

    componentWillMount() {
        if (this.props.data) {
            this.setState({
                DeliveryRegion: this.props.data[0],
            })
        }
    }    

    onApproverRemarkChange(event) {
        
     var DeliveryRegion = this.state.DeliveryRegion;
     DeliveryRegion.ApproverRemarks = event.target.value;
     this.setState({
         ...this.state,
         DeliveryRegion: DeliveryRegion,
         errors:{}
     });
  
    }
        
    handleSave(e) {
        if (this.validateForm()) {
            this.props.onSave(this.state.DeliveryRegion)
            this.props.onClose();
        }
    }


    validateForm() {
        const DeliveryRegion = this.state.DeliveryRegion;
        let errors = {};
        let formIsValid = true;    
        if(DeliveryRegion["ApproverRemarks"] == null)
        {
            formIsValid = false;
            errors["ApproverRemarks"] = "Approver Remarks are required";
    
        }
        else if (!DeliveryRegion["ApproverRemarks"].trim()) {
            formIsValid = false;
            errors["ApproverRemarks"] = "Approver Remarks are required";

        }
        else {
            var isValid = regexForTextAreas.test(DeliveryRegion["ApproverRemarks"]);
            if (!isValid) {
                formIsValid = false;
                errors["ApproverRemarks"] = "Approver Remarks contains Special characters";
            }
        }
        if (formIsValid) {
            this.setState({
                formIsValid: true,
                errors: errors
            })
        }
        else {
            this.setState({
                formIsValid: false,
                errors: errors
            })
        }
        return formIsValid;

    }

    render() {
        
        return (
            <>
                {/* <Header /> */}
                <Modal show={this.props.show} onHide={()=>this.props.onClose()} class="modal fade" size="medium" id="dealpopup">
                    <Modal.Header closeButton>
                     {this.props.type == "Approve" ? <h4 className="modal-title" id="dealprofile" flag >Approval</h4>:
                     <h4 className="modal-title" id="dealprofile" flag >Decline</h4>}
                    </Modal.Header>
                    <Modal.Body>
                            <form>
                                <div className="form-section">
                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label for="">Delivery Region Name<span className="required">*</span></label>
                                            <input type="text" className="form-control" id="name" readOnly  value={this.state.DeliveryRegion.DeliveryRegionName} />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label for="">Requestor Remarks</label>
                                            <textarea maxLength="200" readOnly className="form-control" id="remark"  value={this.state.DeliveryRegion.RequestorRemarks} />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label for="">Approver Remarks<span className="required">*</span></label>
                                            <textarea  maxLength="200" className="form-control" id="remark" onChange={this.onApproverRemarkChange} value={this.state.DeliveryRegion.ApproverRemarks} />
                                            <span className="errorMsg">{this.state.errors.ApproverRemarks}</span>
                                        </div>
                                       
                                    </div>

                                </div>


                            </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="modal-btn">
                                {!this.props.data ?
                                    <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value="Create" />
                                    : <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value="Update" />
                                }
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}