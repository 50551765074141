import axios from 'axios';
import promise from 'promise';
import { acquireNewTokenSilent } from "../Authorization/AcquireTokenApi";
import {idTokenstring} from '../Shared/Constant';
var axiosInstance = axios.create();
const isDevEnvironment=process.env.NODE_ENV === 'development';
// for dev server
axiosInstance.interceptors.request.use(async function (config) {
    var oldToken = sessionStorage.getItem(idTokenstring);
    let forceRefresh=false;
    var oldBase64Url = oldToken.split('.')[1];
    var oldBase64 = oldBase64Url.replace('-', '+').replace('_', '/');
    var tokendetails = JSON.parse(window.atob(oldBase64));
    var expiry = tokendetails["exp"];
    var expTime = new Date(expiry*1000);
    var currentTime = new Date();
    if (currentTime > expTime) {
        forceRefresh=true;
        if(isDevEnvironment){
        alert("Token Expired!! please get the new token")//this message will throw only in case of Local Dev Environment
        }
        else{
        await acquireNewTokenSilent(forceRefresh);
        }//Acquiring new token and setting to the authorization headers 
        // authContext.acquireToken(adalConfig.clientId, (error, token) => {
        //     if (token) {
        //         sessionStorage.setItem("msal.idtoken", token);
        //         var base64Url = token.split('.')[1];
        //         var base64 = base64Url.replace('-', '+').replace('_', '/');
        //         var tokendetails = JSON.parse(window.atob(base64));

        //         var unique_name = tokendetails["unique_name"];
        //         var peoplekey = tokendetails["peoplekey"];
        //         var EnterpriseId = unique_name.split("@")[0];
        //         sessionStorage.setItem("EnterpriseID", EnterpriseId);
        //         sessionStorage.setItem("peoplekey", peoplekey);

        //     }
        // })
    }
    return config;
    }, function (error) {
        console.log("INTERCEPT ERROR:")
        return promise.reject(error);
    });


//for local server
// axiosInstance.interceptors.response.use(function (config) {
//     return config;

// }, function (error) {
//     return promise.reject(error);
// });

export default axiosInstance;