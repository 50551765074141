import { object } from "prop-types";
import React, { PureComponent } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";
import ParentView from "./ParentView";
import ChildView from "./ChildView";

export default class Example extends PureComponent {
  constructor(props) {
    debugger;
    super(props);
    this.state = {
      getConfiguratorapicall: false,
      DonutchildData: [],
      activeIndex: null,
      labelName: "",
      XaxisAngle:"50%",
      YaxisAngle:"50%"
    };
  }
  onPieEnter = (IOJStageID, a) => {
    let { DonutchildData, activeIndex, labelName } = this.state;
    if (labelName.trim().toUpperCase() !== a.StageName.trim().toUpperCase()) {
      let childData = [];
      DonutchildData = this.props.DonutchildData.filter(
        (a) => a.IOJStageID === parseInt(IOJStageID)
      )[0];

      labelName = a.StageName;

      Object.keys(DonutchildData).map((key, index) => {
        switch (key) {
          case "InConceptCount":
            childData = childData.concat({
              ID: 1,
              name: "In Concept",
              value: 25,
              count: DonutchildData[key],
              fill: "#9c6ade",
              DimID: "1",
            });
            break;
          case "InFlightCount":
            childData = childData.concat({
              ID: 2,
              name: "In Flight",
              value: 25,
              count: DonutchildData[key],
              fill: "#00baff",
              DimID: "1",
            });
            break;
          case "DeliveredCount":
            childData = childData.concat({
              ID: 3,
              name: "Delivered",
              value: 25,
              count: DonutchildData[key],
              fill: "#26c1c9",
              DimID: "2",
            });
            break;
          case "OnHoldCount":
            childData = childData.concat({
              ID: 4,
              name: "On Hold",
              value: 25,
              count: DonutchildData[key],
              fill: "#919eab",
              DimID: "2",
            });
            break;
          default:
            return;
        }
      });


      childData= childData.length > 0 ? childData.filter(a => a.value!== 0) : childData;
      //activeIndex=index
      this.setState({
        DonutchildData: childData,
        labelName,
        activeIndex,
        XaxisAngle:"30%"
       
      });
    }
  };
  emptyChild = () => {
    this.setState({
      DonutchildData: [],
      labelName: "",
      XaxisAngle:"50%"
    });
  };
  render() {
    let { DonutchildData, activeIndex, labelName, XaxisAngle, YaxisAngle } = this.state;
    const { DonutparentData } = this.props;

    return (
      <div onMouseLeave={this.emptyChild}>
        <ResponsiveContainer  width="100%" height="100%">
          <PieChart>
            <Pie
            isAnimationActive={true} 
            animationDuration={1000}
              data={DonutparentData}
              dataKey="Count"
              nameKey="StageName"
              cx={XaxisAngle}
              cy={YaxisAngle}
              startAngle={120}
              endAngle={-240}
              innerRadius={55}
              outerRadius={80}
              onMouseOver={(a) => this.onPieEnter(a.ID, a)}
              label={
                DonutchildData.length > 0
                  ? ""
                  : (a) => ParentView(a, DonutparentData)
              }
              labelLine={false}
            ></Pie>
            {DonutchildData.length > 0 && (
              <Pie
                //activeIndex={activeIndex}
                isAnimationActive={true} 
            animationDuration={1000}
                renderActiveShape={{}}
                startAngle={60}
                endAngle={-60}
                data={DonutchildData}
                cx={XaxisAngle}
                cy={YaxisAngle}
                outerRadius={140}
                innerRadius={110}
                label={(a) => ChildView(a, DonutchildData)}
                labelLine={false}
                stroke =""
              >
                <Label width={30} position="center">
                  {`${labelName}`}
                </Label>
              </Pie>
            )}
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
