/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import Chart from 'chart.js';
import 'chartjs-plugin-datalabels';
import 'chartjs-plugin-annotation';
import moment from 'moment';
import i18n from 'i18next';
import _ from 'lodash';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { formatDate, BATDateFormat } from '../Shared/Constant';

let peerComparisonBarChart = null;
let peerComparisonLineChart = null;

function PeerToPeerComparisonGraph(props) {
  const {
    displayClientNames,
    selectedTrendViewBenchmarkOption,
    isClientExisting,
    processedAPIData,
    parentScreen,
    existingSynOpsClientName,
    currentlySelectedClientDetails,
    selectedTrendViewBenchmarkMarketUnitOption,
    metricData,
    formattedEndDate,
    formattedStartDate,
  } = props;
  const peerChart = React.createRef();
  const [peerChatLoaded, setPeerChatLoaded] = useState(false);
  const barChart = React.createRef();
  const [barChatLoaded, setBarChatLoaded] = useState(false);
  const [selectedPeerIndex, setSelectedPeerIndex] = useState(0);
  const [filteredPeerData, setFilteredPeerData] = useState([]);
  const [lineChatData, setLineChatData] = useState({ labels: [], datasets: [] });
  const [paginationData, setPaginationData] = useState({ pageNumber: 1, perPageCount: 10, filteredPaginationData: [] });

  function monthDiff(dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()));
  }
  function addMonthToDate(monts, date) {
    return new Date(date.setMonth(date.getMonth() + monts)).toISOString();
  }
  function convertDateToIST(date) {
    const breakdownDate = date.split('T');
    if (breakdownDate.length) {
      return `${breakdownDate[0].slice(0, -2)}01T00:00:00`;
    }
    return date;
  }

  function setMissingMontsData() {
    const peerToPeerApiData = _.cloneDeep(processedAPIData);
    const monthDifference = monthDiff(new Date(formattedStartDate), new Date(formattedEndDate));
    const formmatedMonthData = [];
    for (let i = 1; i <= monthDifference + 1; i += 1) {
      const addedMontdate = addMonthToDate(i, new Date(formattedStartDate));
      const convertDateToISTValue = convertDateToIST(addedMontdate);
      formmatedMonthData.push({ month: convertDateToISTValue, medianMetricValue: '' });
    }
    if (peerToPeerApiData.clientDetails && peerToPeerApiData.clientDetails.length) {
      const clientMonthData = [...peerToPeerApiData.clientDetails];
      let MontValuesArray = _.cloneDeep(formmatedMonthData);
      MontValuesArray = MontValuesArray.map((eachMonth) => {
        const filteredMontValue = clientMonthData.filter((eachClient) => eachClient.month === eachMonth.month);
        if (filteredMontValue && filteredMontValue.length) {
          eachMonth.medianMetricValue = filteredMontValue[0].medianMetricValue;
        }
        return eachMonth;
      });
      // clientMonthData.forEach((each) => {
      // 	MontValuesArray = MontValuesArray.map((eachMonth) => {
      // 		if(eachMonth.month === each.month){
      // 			eachMonth.medianMetricValue = each.medianMetricValue;
      // 		}
      // 		return each;
      // 	});
      // 	if(new Date(formattedStartDate) < new Date(each.month)  )
      // })
      peerToPeerApiData.clientDetails = MontValuesArray;
    }
    if (peerToPeerApiData.peers && peerToPeerApiData.peers.length) {
      peerToPeerApiData.peers.map((eachPeer) => {
        const peerMonthValues = [...eachPeer.peerDetails];
        let MontValuesArray = _.cloneDeep(formmatedMonthData);
        MontValuesArray = MontValuesArray.map((eachMonth) => {
          const filteredMontValue = peerMonthValues.filter((eachPeerMonth) => eachPeerMonth.month === eachMonth.month);
          if (filteredMontValue && filteredMontValue.length) {
            eachMonth.medianMetricValue = filteredMontValue[0].medianMetricValue;
          }
          return eachMonth;
        });
        eachPeer.peerDetails = MontValuesArray;
      });
    }
    return peerToPeerApiData;
  }

  function createDataSetsForLineGraph(data, dataSetType, label) {
    const dataPoints = [];
    let hasStartingValue = true;
    data.forEach((each, index) => {
      if (each.medianMetricValue && parseFloat(each.medianMetricValue)) {
        dataPoints.push(parseFloat(each.medianMetricValue));
        if (!hasStartingValue && index > 0 && !dataPoints[0]) {
          dataPoints[0] = parseFloat(each.medianMetricValue);
        }
      } else if (index === 0) {
        hasStartingValue = false;
        dataPoints.push(null);
      } else if ((index === (data.length - 1)) && !each.medianMetricValue && !parseFloat(each.medianMetricValue)) {
        const reveArr = [...dataPoints].reverse();
        for (let i = 0; i < reveArr.length; i += 1) {
          if (reveArr[i]) {
            dataPoints.push(reveArr[i]);
            break;
          }
        }
      } else {
        dataPoints.push(null);
      }
    });
    const normalizedDataPoints = dataPoints.map((eachDatapoint) => {
      if (eachDatapoint) {
        eachDatapoint = (eachDatapoint / dataPoints[0]).toFixed(2);
      }
      return eachDatapoint;
    });
    let dataSet = {};
    if (dataSetType === 'Client') {
      dataSet = {
        label,
        data: normalizedDataPoints,
        fill: false,
        borderWidth: 3,
        borderColor: '#a100ff',
        backgroundColor: '#fff',
        pointRadius: 5,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderWidth: 2,
        lineTension: 0,
      };
    } else if (dataSetType === 'selectedPeer') {
      dataSet = {
        label,
        data: normalizedDataPoints,
        fill: false,
        borderWidth: 2,
        borderColor: '#004dff',
        backgroundColor: '#004dff',
        pointRadius: 4,
        pointBorderWidth: 2,
        lineTension: 0,
        pointHitRadius: 4,
        pointHoverRadius: 4,
        pointHoverBorderColor: '#004dff',
        pointHoverBackgroundColor: '#004dff',
        pointHoverBorderWidth: 2,
      };
    } else if (dataSetType === 'peerMedian') {
      dataSet = {
        label,
        data: normalizedDataPoints,
        fill: false,
        borderWidth: 3,
        borderColor: '#7c8a95',
        backgroundColor: '#fff',
        pointRadius: 0,
        borderDash: [5, 5],
        // pointHoverBorderColor:'#018eff',
        borderJoinStyle: 'miter',
        lineTension: 0,
      };
    } else {
      dataSet = {
        label,
        data: normalizedDataPoints,
        fill: false,
        borderWidth: 1,
        borderColor: '#9fe5ff',
        backgroundColor: '#fff',
        hoverBorderColor: '#9fe5ff',
        pointRadius: 0,
        pointBorderWidth: 0,
        pointHoverBackgroundColor: '#9fe5ff',
        pointHoverRadius: 5,
        hoverBorderWidth: 0,
        pointHoverBorderColor: '#9fe5ff',
        pointHitColor: '#9fe5ff',
        borderJoinStyle: 'miter',
        lineTension: 0,
      };
    }

    return dataSet;
  }

  function createNormalizedDataForBarGraph(data) {
    let normalizedValue = 0;
    if (data.length) {
      let firstReportedValue = 0;
      let lastReportedValue = 0;

      const reverseReportedData = [...data].reverse();
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].medianMetricValue && parseFloat((data[i].medianMetricValue))) {
          firstReportedValue = parseFloat((data[i].medianMetricValue));
          break;
        }
      }
      for (let j = 0; j < reverseReportedData.length; j += 1) {
        if (reverseReportedData[j].medianMetricValue && parseFloat((reverseReportedData[j].medianMetricValue))) {
          lastReportedValue = parseFloat((reverseReportedData[j].medianMetricValue));
          break;
        }
      }
      normalizedValue = ((lastReportedValue - firstReportedValue) / firstReportedValue) * 100;
    }
    return normalizedValue.toFixed(2);
  }

  useEffect(() => {
    showpeerChart();
    showbarChart(processedAPIData);
  }, [processedAPIData, selectedPeerIndex]);

  useEffect(() => {
    setPaginationData({ pageNumber: 1, perPageCount: 10, filteredPaginationData: [] });
    showbarChart(processedAPIData);
    showpeerChart();
  }, [selectedTrendViewBenchmarkOption, isClientExisting, parentScreen, existingSynOpsClientName, currentlySelectedClientDetails, selectedTrendViewBenchmarkMarketUnitOption, metricData, displayClientNames]);

  useEffect(() => {
    showbarChart(processedAPIData);
    showpeerChart();
  }, [paginationData.pageNumber]);

  // useEffect(() => {
  //   if (!barChatLoaded) {
  //     showbarChart(processedAPIData);
  //   }
  //   if (barChatLoaded && !peerChatLoaded) {
  //     showpeerChart();
  //   }
  // }, [peerChatLoaded, barChatLoaded]);

  function filterPeers(peerToPeerApiData) {
    if (peerToPeerApiData.peers && peerToPeerApiData.peers.length) {
      let filteredPeers = [];
      let medianPeer = {};
      const thresholdType = selectedTrendViewBenchmarkOption.benchmarkBy;
      let thresholdUnit;
      peerToPeerApiData.peers.forEach((eachPeer) => {
        if (thresholdType === 'IndustryWise') {
          thresholdUnit = currentlySelectedClientDetails.industry;
          if (eachPeer.peerIndustry === thresholdUnit && eachPeer.peerName !== 'Peer Median') {
            const normalizedIndustryValue = createNormalizedDataForBarGraph(eachPeer.peerDetails);
            eachPeer.normalizedValue = normalizedIndustryValue;
            filteredPeers.push(eachPeer);
          } else if (eachPeer.peerName === 'Peer Median') {
            medianPeer = eachPeer;
          }
        } else if (thresholdType === 'MarketUnit') {
          thresholdUnit = selectedTrendViewBenchmarkMarketUnitOption.label;
          if (eachPeer.peerMarketUnit === thresholdUnit && eachPeer.peerName !== 'Peer Median') {
            const normalizedMarkedValue = createNormalizedDataForBarGraph(eachPeer.peerDetails);
            eachPeer.normalizedValue = normalizedMarkedValue;
            filteredPeers.push(eachPeer);
          } else if (eachPeer.peerName === 'Peer Median') {
            medianPeer = eachPeer;
          }
        } else if (thresholdType === 'CrossIndustry') {
          thresholdUnit = selectedTrendViewBenchmarkMarketUnitOption.label;
          if (eachPeer.peerName !== 'Peer Median' && !filteredPeers.filter((eachEle) => eachEle.peerName === eachPeer.peerName).length) {
            const normalizedMarkedValue = createNormalizedDataForBarGraph(eachPeer.peerDetails);
            eachPeer.normalizedValue = normalizedMarkedValue;
            filteredPeers.push(eachPeer);
          } else if (eachPeer.peerName === 'Peer Median') {
            medianPeer = eachPeer;
          }
        }
        else if (eachPeer.peerName === 'Peer Median' && !eachPeer.peerMarketUnit && !eachPeer.peerIndustry) {
          medianPeer = eachPeer;
        } else if (eachPeer.peerName !== 'Peer Median') {
          const normalizedCrossIndustryValue = createNormalizedDataForBarGraph(eachPeer.peerDetails);
          eachPeer.normalizedValue = normalizedCrossIndustryValue;
          filteredPeers.push(eachPeer);
        }
      });
      const paginationDataArray = [];
      if (filteredPeers.length > paginationData.perPageCount) {
        let siglePageData = [];
        filteredPeers = filteredPeers.sort((a, b) => b.normalizedValue - a.normalizedValue);
        filteredPeers.forEach((eachFilterdPeer, filPeerInd) => {
          siglePageData.push(eachFilterdPeer);
          if (siglePageData.length === paginationData.perPageCount) {
            paginationDataArray.push(siglePageData);
            siglePageData = [];
          } else if (siglePageData.length && filPeerInd === filteredPeers.length - 1) {
            paginationDataArray.push(siglePageData);
          }
        });
      } else {
        filteredPeers = filteredPeers.sort((a, b) => b.normalizedValue - a.normalizedValue);
        paginationDataArray.push(filteredPeers);
      }
      setPaginationData((prevPaginationData) => ({ ...prevPaginationData, filteredPaginationData: paginationDataArray }));
      //
      setFilteredPeerData(() => [...filteredPeers]);
      return { medianPeer, filteredPeers: paginationDataArray.length >= paginationData.pageNumber ? paginationDataArray[paginationData.pageNumber - 1] : [] };
    } return { medianPeer: {}, filteredPeers: [] };
  }
  function showpeerChart() {
    const labels = [];
    const dataSets = [];
    const peerToPeerApiData = setMissingMontsData();
    if (peerToPeerApiData.clientDetails) {
      peerToPeerApiData.clientDetails.forEach((each) => {
        const graphLables = moment(new Date(each.month)).format('MMM YY');
        labels.push(graphLables);
      });
      const clientNamevalue = displayClientNames ? currentlySelectedClientDetails.clientName : currentlySelectedClientDetails.aliasName;
      const clientDataset = createDataSetsForLineGraph(peerToPeerApiData.clientDetails, 'Client', clientNamevalue);
      if (clientDataset.data[0] !== 'NaN') {
        dataSets.push(clientDataset);
      }

      if (peerToPeerApiData.peers && peerToPeerApiData.peers.length) {
        const { filteredPeers, medianPeer } = filterPeers(peerToPeerApiData);
        // if (medianPeer.peerName === "Peer Median") {
        // 	const medianDataset = createDataSetsForLineGraph(medianPeer.peerDetails, "peerMedian", medianPeer.peerAliasName)
        // 	dataSets.push(medianDataset);
        // }
        if (filteredPeers.length) {
          filteredPeers.forEach((eachFilteredPeer, ind) => {
            const peerNamevalue = displayClientNames ? eachFilteredPeer.peerName : eachFilteredPeer.peerAliasName;
            if (selectedPeerIndex && ind === selectedPeerIndex - 1) {
              const selectedPeerDataset = createDataSetsForLineGraph(eachFilteredPeer.peerDetails, 'selectedPeer', peerNamevalue);
              if (selectedPeerDataset.data[0] !== 'NaN') {
                dataSets.push(selectedPeerDataset);
              }
            } else {
              const peerDataset = createDataSetsForLineGraph(eachFilteredPeer.peerDetails, 'peer', peerNamevalue);
              if (peerDataset.data[0] !== 'NaN') {
                dataSets.push(peerDataset);
              }
            }
          });
        }
      }
    }
    setLineChatData((prevState) => ({ ...prevState, labels, datasets: dataSets }));
    if (!_.isNil(peerComparisonLineChart)) {
      peerComparisonLineChart.destroy();
    }
    const PeerLineChart = peerChart.current.getContext('2d');
    const peerLineGraphOptions =  {
      type: 'line',
      data: {
        labels,
        datasets: _.cloneDeep(dataSets),
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false,
          },
        },
        spanGaps: true,
        responsive: true,
        title: {
          display: false,
          text: 'Chart.js Line Chart',
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        // events: ['hover'],
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Month',
              padding: 20,
              fontColor: 'rgb(99, 115, 129)',
              // fontFamily: 'Graphik',
              fontStyle: 'bold',
              fontSize: 14,
            },
            gridLines: {
              display: true,
              drawBorder: true,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              padding: 20,
              fontColor: 'rgb(99, 115, 129)',
              // fontFamily: 'Graphik',
              fontSize: 11,
            },
          }, {
            position: 'top',
            ticks: {
              display: false,
            },
            gridLines: {
              display: true,
              drawOnChartArea: false,
              drawTicks: false,
            },
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Normalized Value',
              padding: 20,
              fontColor: 'rgb(99, 115, 129)',
              // fontFamily: 'Graphik',
              fontStyle: 'bold',
              fontSize: 14,
            },
            gridLines: {
              display: true,
              drawBorder: true,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              padding: 20,
              // fontColor: 'rgb(99, 115, 129)',
              // fontFamily: 'Graphik',
              fontSize: 11,
            },
            // ticks: {
            // padding: 15,
            // min: -5.0,
            // max: 5.0,
            // sampleSize: 1.0,
            // fontColor: 'rgb(99, 115, 129)',
            // fontFamily: 'Graphik',
            // fontSize: 13
            // }
          }, {
            position: 'right',
            ticks: {
              display: false,
            },
            gridLines: {
              display: true,
              drawOnChartArea: false,
              drawTicks: false,
            },
          },
          ],
        },
        
        // tooltips: {
        //   enabled: false,
        // },
        tooltips: {
          enabled: false,
          mode: 'nearest',
          intersect: true,
          // filter(tooltipItem) {
          //   const tooltipDatasetIndex = tooltipItem.datasetIndex;
          //   const currentDataset = _.get(dataSets, `${tooltipDatasetIndex}`);

          //   // HIDE THE TOOLTIP FOR THE DASHED LINE (FOR DISPLAYING BENCHMARKS) DATASET
          //   return _.get(currentDataset, 'type', '') !== 'line';
          // },
          custom(tooltip) {
            const dataPointsArr = _.get(tooltip, 'dataPoints', []);
            // IF THERE ARE NO DATA POINTS OR THE OPACITY OF THE `tooltip` IS "0" THEN REMOVE THE TOOLTIP ELEMENT
            // if (_.isEmpty(dataPointsArr) || tooltip.opacity === 0) {
            //   $('#chartjs-tooltip').remove();
            //   return;
            // }

            // Tooltip Element
            let tooltipEl = document.getElementById('chartjs-tooltip');
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.className = 'peer-to-peer-tooltip';
              tooltipEl.innerHTML = "<div class='tooltip-body'></div>";
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltip.yAlign) {
              tooltipEl.classList.add(tooltip.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            // GENERATE DYNAMIC CONTENT FOR TOOLTIP BODY
            if (tooltip.body) {
              let innerHtml = '';

              if (!_.isEmpty(dataPointsArr)) {
                _.forEach(dataPointsArr, (eachDataPoint, pointindex) => {
                  const { datasetIndex, index } = eachDataPoint;
                  const datasetMeta =  dataSets.filter((each,currentIndex) => {if(currentIndex === datasetIndex )return each});
                  const datasetMetatest = peerComparisonLineChart.getDatasetMeta(datasetIndex); 
                  if(datasetMeta && datasetMeta.length && datasetMeta[0].data[index]){
                    
                  innerHtml += `
                  <div class=${`tooltip-content ${(dataPointsArr.length === 1 ||  (pointindex === dataPointsArr.length -1) ) ? "" : "border"}`}>
                    <div class="tooltip-title">
                      ${datasetMeta[0].label === "Peer Median" ? `Peer Median - ${selectedTrendViewBenchmarkOption.label}` : datasetMeta[0].label}
                    </div>
                    <div>
                      <span class="day-count">${datasetMeta[0].data[index]}</span>
                    </div>
                    <div>
                    <span class="day-label">${eachDataPoint.label}</span>
                  </div>
                  </div>
                `;
                  }
                  // let tooltipTitle = '';
                  // if (datasetMeta.type === 'bubble') {
                  //   const currentDataset = dataSets[datasetIndex];
                  //   tooltipTitle = currentDataset.label;
                  // } else {
                  //   tooltipTitle = eachDataPoint.xLabel;
                  // }
                  // const tooltipValue = eachDataPoint.value;
                });
                innerHtml = `<div class="peer-tooltip-body-wrapper">${innerHtml}</div>`;

                // WHEN THE NUMBER OF DATAPOINTS IS GREATER THAN 2 THEN APPLY A CSS CLASS TO THE TOOLTIP ELEMENT SO THAT THE LAYOUT OF THE TOOLTIP DOES NOT BREAK
                if (_.size(dataPointsArr) > 2) {
                  tooltipEl.classList.add('more-than-two-points');
                }
              }

              const tooltipBody = tooltipEl.querySelector('.tooltip-body');
              tooltipBody.innerHTML = innerHtml;
            }

            const positionY = peerComparisonLineChart.canvas.offsetTop;
            const positionX = peerComparisonLineChart.canvas.offsetLeft;
            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
            tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
            tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
            tooltipEl.style.fontSize = `${tooltip.bodyFontSize}px`;
            tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
            tooltipEl.style.padding = `${tooltip.yPadding}px ${tooltip.xPadding}px`;

            // APPEND THE DYNAMICALLY CREATED TOOLTIP ELEMENT TO THE CHART CANVAS
            if(document.getElementsByClassName('peerToPeer-line-graph')){
              document.getElementsByClassName('peerToPeer-line-graph')[0].appendChild(tooltipEl);
            } 
            //  peerComparisonLineChart.canvas.parentNode.appendChild(tooltipEl);
            // peerComparisonLineChart.update();
          },
        },
        legend: {
          display: false,
        },
        animation: {
          duration: 0,
        },
      },
    };

    peerComparisonLineChart = new Chart(PeerLineChart,peerLineGraphOptions);
    peerComparisonLineChart.destroy();
    peerComparisonLineChart = new Chart(PeerLineChart,peerLineGraphOptions);
    setPeerChatLoaded(true);
  }
  function onBarClick(event, data) {
    if (data.length) {
      const currentIndex = data[0]._index;
      if (selectedPeerIndex === currentIndex) {
        setSelectedPeerIndex(0);
      } else {
        setSelectedPeerIndex(currentIndex);
      }
    }
  }
  function onPaginationChange(val) {
    if (val === 'left') {
      if (paginationData.pageNumber !== 1) {
        setPaginationData((pageData) => ({ ...pageData, pageNumber: paginationData.pageNumber - 1 }));
      }
    } else if (paginationData.pageNumber !== paginationData.filteredPaginationData.length) {
      setPaginationData((pageData) => ({ ...pageData, pageNumber: paginationData.pageNumber + 1 }));
    }
  }
  function showbarChart(peerToPeerApiData) {
    const labels = [];
    const dataSets = [];
    const medianLineData = [];
    const backgroundColors = ['#a100ff'];
    if (peerToPeerApiData.clientDetails) {
      const clientDatasetvalue = createNormalizedDataForBarGraph(peerToPeerApiData.clientDetails);

      if (!isClientExisting && !parentScreen) {
        if (displayClientNames) {
          labels.push(currentlySelectedClientDetails.clientName);
        } else {
          labels.push(currentlySelectedClientDetails.aliasName);
        }
      } else {
        labels.push(existingSynOpsClientName);
      }

      dataSets.push(clientDatasetvalue);
      if (peerToPeerApiData.peers && peerToPeerApiData.peers.length) {
        const { filteredPeers, medianPeer } = filterPeers(_.cloneDeep(peerToPeerApiData));

        if (medianPeer && medianPeer.peerName === 'Peer Median') {
          const medianDatasetValue = createNormalizedDataForBarGraph(medianPeer.peerDetails);
          medianLineData.push(medianDatasetValue);
        }
        if (filteredPeers.length) {
          filteredPeers.forEach((eachFilteredPeer, ind) => {
            if (selectedPeerIndex && ind === selectedPeerIndex - 1) {
              backgroundColors.push('#0043ff');
            } else {
              backgroundColors.push('#9fe5ff');
            }
            const selectedPeerDatasetValue = createNormalizedDataForBarGraph(eachFilteredPeer.peerDetails);
            labels.push(displayClientNames ? eachFilteredPeer.peerName : eachFilteredPeer.peerAliasName);
            dataSets.push(selectedPeerDatasetValue);
          });
        }
      }
    }
    const removedNanDatasets = [];
    dataSets.forEach((eachdata, index) => {
      if (index <= dataSets.length - 2) {
        medianLineData.push(medianLineData[0]);
      }
      if (eachdata !== 'Infinity' && eachdata !== 'NaN') {
        removedNanDatasets.push(eachdata);
      }
    });
    let maxValue = Math.max(...removedNanDatasets);
    const minValue = Math.min(...removedNanDatasets);
    if (parseFloat(maxValue) < 0) {
      maxValue = 60;
    }
    const horizontalBarChartData = {
      labels,
      datasets: [{
        type: 'horizontalBar',
        label: 'Dataset 1',
        backgroundColor: backgroundColors,
        // hoverBackgroundColor: '#0043ff',
        borderWidth: 0,
        data: dataSets,
        barThickness: 20,
        barPercentage: 0.5,
        categoryPercentage: 0.8,
      },

      ],

    };
    if (!_.isNil(peerComparisonBarChart)) {
      peerComparisonBarChart.destroy();
    }
    const PeerBarChart = barChart.current.getContext('2d');
    peerComparisonBarChart = new Chart(PeerBarChart, {
      type: 'horizontalBar',
      data: _.cloneDeep(horizontalBarChartData),
      options: {
        maintainAspectRatio: false,
        onClick(event, data) {
          if (data.length) {
            // const currentIndex = data[0]._index;
            // const currentBackgroundColor = peerComparisonBarChart.data.datasets[0].backgroundColor;
            // if (currentIndex) {
            //   peerComparisonBarChart.data.datasets[0].backgroundColor = currentBackgroundColor.map((bgCol, bgIndex) => {
            //     if (bgIndex === currentIndex) {
            //       return bgCol === '#0043ff' ? '#9fe5ff' : '#0043ff';
            //     }
            //     if (bgIndex) {
            //       return '#9fe5ff';
            //     }
            //     return bgCol;
            //   });
            // } else {
            //   peerComparisonBarChart.data.datasets[0].backgroundColor = currentBackgroundColor.map((bgCol, bgIndex) => {
            //     if (bgIndex === 0) {
            //       return bgCol;
            //     }
            //     return '#9fe5ff';
            //   });
            // }
            // peerComparisonBarChart.update();
            onBarClick(event, data);
          }
        },
        customVerticalLinesData: {
          type: 'line',
          label: 'Peer Median',
          data: medianLineData,
          fill: false,
          borderWidth: 3,
          borderColor: '#7c8a95',
          backgroundColor: '#fff',
          pointRadius: 0,
          borderDash: [5, 5],
          borderJoinStyle: 'miter',
          lineTension: 0,
        },
        elements: {
          rectangle: {
            borderWidth: 2,
          },
        },
        lineAtIndex: 100,
        responsive: true,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            display: false,
            scaleLabel: {
              display: false,
            },
            gridLines: {
              display: true,
              drawBorder: true,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              padding: 15,
              min: minValue,
              max: maxValue,
              sampleSize: 1.0,
              fontColor: 'rgb(99, 115, 129)',
              // fontFamily: 'Graphik',
              fontSize: 13,
            },
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: false,
            },
            gridLines: {
              display: true,
              drawBorder: true,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              padding: 5,
              fontColor: 'rgb(33, 43, 54)',
              // fontFamily: 'Graphik',
              fontSize: 10,
              callback(val, index) {
                let currentLabel = val;
                if (val.length > 20) {
                  const totalItems = val.length / 20;
                  const name = [];

                  for (let i = 0; i < totalItems; i++) {
                    name.push((val.substr((i * 20), 20)) + (i === parseInt(totalItems) ? '' : '-'));
                  }
                  currentLabel = name;
                }
                return currentLabel;
              },
            },
          },
          ],
        },
        title: {
          display: false,
          text: 'Days Sales Outstanding - % of Change',
        },
        tooltips: {
          enabled: false,
        },
        events: ['click'],
        plugins: {
          datalabels: {
            display: false,
          },
        },

        animation: {
          duration: 1,
          // onComplete: function () {
          // 	var chartInstance = this.chart,
          // 		ctx = chartInstance.ctx;
          // 	ctx.textAlign = 'center';
          // 	ctx.fillStyle = "#212b36";
          // 	ctx.textBaseline = 'bottom';

          // 	this.data.datasets.forEach(function (dataset, i) {
          // 		if (dataset.type === "bar" || dataset.type === "horizontalBar") {
          // 			var meta = chartInstance.controller.getDatasetMeta(i);
          // 			meta.data.forEach(function (bar, index) {
          // 				var data = dataset.data[index] + '%';
          // 				let xAxispadding = 30;
          // 				if (dataset.data[index] !== "Infinity" && dataset.data[index] !== "NaN" && parseFloat(dataset.data[index]) < 0) {

          // 					xAxispadding = (-(parseFloat(dataset.data[index]))) + 30;
          // 				}
          // 				ctx.fillText(data, bar._model.x + xAxispadding, bar._model.y + 7);

          // 			});
          // 		}

          // 	});
          // },
          title: {
            display: false,
            text: '',
          },
        },
        // layout: {
        //   padding: {
        //     left: 10,
        //     right: 70,
        //   },
        // },
      },
      plugins: [
        {
          destroy: true,
          afterDraw(chart) {
            const datasets = _.cloneDeep(chart.chart.config.data.datasets);

            const clickableLabelsArr = [];
            _.forEach(datasets, (eachDataset) => {
              const datasetMetaDataObj = chart.chart.getDatasetMeta(0).data;
              const datasetLabel = eachDataset.label;
              // DON'T ADD THE DATA OF THE TREND FOR "ACROSS ALL DEVELIVERY CENTERS" TO THE CLICKABLE LABELS
              if (datasetMetaDataObj) {
                _.forEach(datasetMetaDataObj, (eachMeta, index) => {
                  clickableLabelsArr.push({
                    datasetLabel,
                    lastPointPosition: {
                      x: eachMeta._model.x,
                      y: eachMeta._model.y,
                      value: eachDataset.data[index],
                      base: eachMeta._model.base,
                    },
                  });
                });
              }
            });
            window.onbarLableClick = function (val) {
              const currentIndex = val;
              const currentBackgroundColor = peerComparisonBarChart.data.datasets[0].backgroundColor;
              if (currentIndex) {
                peerComparisonBarChart.data.datasets[0].backgroundColor = currentBackgroundColor.map((bgCol, bgIndex) => {
                  if (bgIndex === currentIndex) {
                    return bgCol === '#0043ff' ? '#9fe5ff' : '#0043ff';
                  }
                  if (bgIndex) {
                    return '#9fe5ff';
                  }
                  return bgCol;
                });
              } else {
                peerComparisonBarChart.data.datasets[0].backgroundColor = currentBackgroundColor.map((bgCol, bgIndex) => {
                  if (bgIndex === 0) {
                    return bgCol;
                  }
                  return '#9fe5ff';
                });
              }
              peerComparisonBarChart.update();

              onBarClick('', [{ _index: val }]);
            };
            function getClickableLabelHTML(labelName, width) {
              const labelId = `clickable-label-${labelName}`;
              const labelHTML = `
								<span
									id="${labelId}"
									class="peer-graph-tooltip"
									onclick="window.onbarLableClick(${labelName})"
								>${(width !== 'Infinity' && width !== 'NaN') ? (`${width}%`) : `Undefined <span class="tooltiptext">${i18n.t('peer_graph_undefined_tooltip')}</span>`}</span>
								`;

              return labelHTML;
            }

            window.createClickableLabel = function (
              name,
              xPos,
              yPos,
              width,
            ) {
              const linkId = `clickable-label-${name}`;
              let labelsHTML = '';
              if (!document.getElementById(linkId)) {
                labelsHTML = getClickableLabelHTML(name, width);
              } else {
                document.getElementById(linkId).remove();
                labelsHTML = getClickableLabelHTML(name, width);
              }
              if (clickableLabelsArr.length < 11) {
                for (let i = clickableLabelsArr.length; i <= 10; i++) {
                  const RemovableLinkId = `clickable-label-${i}`;
                  if (document.getElementById(RemovableLinkId)) {
                    document.getElementById(RemovableLinkId).remove();
                  }
                }
              }

              const labelsReducePaddingTop = 8;
              let labelsExtraPaddingLeft = 10;
              // if (parseFloat(width) < 0) {
              //   labelsExtraPaddingLeft = -parseFloat(width) + 10;
              // }
              const clickableLabelHTML = `<div
												class="dc-clickable-label-container"
												style="
												position: absolute;
												margin-top: ${yPos - labelsReducePaddingTop}px;
												margin-left: ${(xPos ? xPos : 120) + labelsExtraPaddingLeft}px;
											"
										>
											${labelsHTML}
										</div>`;
              $('#peerToPeer-bar-graph').prepend(
                $(clickableLabelHTML),
              );
            };
            // LOOP OVER UNIQUE CLICKABLE LABEL ARRAY TO DISPLAY THEM ON THE CHART
            _.forEach(clickableLabelsArr, (eachItem, eachin) => {
              const pointVal = eachItem.lastPointPosition.value;
              const posX = (parseFloat(pointVal) < 0 || !pointVal || Number.isNaN(pointVal) || pointVal === 'NaN') ? eachItem.lastPointPosition.base : eachItem.lastPointPosition.x;
              window.createClickableLabel(
                eachin,
                posX,
                eachItem.lastPointPosition.y,
                pointVal,
              );
            });
          },
        },
        {
          datalabels: {
            display: false,
          },
        },
      ],
      // afterDraw: function (chart) {
      // 	// DRAW VERTICAL DASHED LINES AND RECTANGLES FOR CROSS INDUSTRY BENCHMARKS
      // 	const ctx = chart.chart.ctx;
      // 	// ctx.globalCompositeOperation='source-over';

      // 	const xAxis = chart.scales["x-axis-0"];
      // 	const yAxis = chart.scales["y-axis-0"];

      // 	// let rectangleWidth = 35;
      // 	// let rectangleHeight = 16;
      // 	const verticalLinesDataset = chart.config.options.customVerticalLinesData;

      // 	if (
      // 		!_.isEmpty(verticalLinesDataset) && verticalLinesDataset.data && verticalLinesDataset.data[0]

      // 	) {
      // 		ctx.font = "10px 'Helvetica Neue', Helvetica, Arial, sans-serif";
      // 		ctx.fillStyle = chart.options.defaultFontColor;
      // 		ctx.textAlign = "center";

      // 		const datasetValue = verticalLinesDataset.data[0];
      // 		const color = verticalLinesDataset.borderColor;

      // 		const x1 = xAxis.getPixelForValue(datasetValue); //xAxis.getPixelForValue(undefined, datasetValue);//xAxis.getPixelForValue(datasetValue);
      // 		const y1 = yAxis.top;
      // 		const x2 = x1;
      // 		const y2 = yAxis.bottom //yAxis.height;

      // 		// if (String(datasetValue).length > 7) {
      // 		//   rectangleWidth = 50;
      // 		// } else if (String(datasetValue).length > 6) {
      // 		//   rectangleWidth = 46;
      // 		// }

      // 		ctx.save();
      // 		ctx.lineWidth = "1";
      // 		ctx.beginPath();
      // 		// VERTICAL DASHED LINE STARTING FROM TOP TO BOTTOM
      // 		ctx.moveTo(x1, y1);
      // 		ctx.lineTo(x2, y2 - 5);
      // 		ctx.strokeStyle = color;
      // 		ctx.setLineDash([5, 5]);
      // 		ctx.stroke();
      // 		ctx.restore();

      // 		// LABEL TEXT INSIDE THE RECTANGLE DRAWN ON BOTTOM X-AXIS
      // 		ctx.textAlign = "center";
      // 		ctx.fillText(verticalLinesDataset.label + " : " + datasetValue + "%", x2, y2 - 5);

      // 		ctx.restore();

      // 	}
      // },
      // },

    });
    // peerComparisonBarChart.update();
    setBarChatLoaded(true);
  }
  return (
    <div className="row">
      <div className="col-lg-8">
        <h5 className="peerToPeer-graph-title">
          {metricData.metricName}
          {' '}
          -
          {' '}
          {i18n.t('Trends')}
        </h5>
        <div className="peerToPeer-legends-list">
          <ul>
            <li>
              <span className="hr-solid-line purple-border" />
              <span>{(!isClientExisting && !parentScreen) ? (displayClientNames ? currentlySelectedClientDetails.clientName : currentlySelectedClientDetails.aliasName) : existingSynOpsClientName}</span>
            </li>
            <li>
              <span className="hr-solid-line lightBlue-border" />
              <span>{i18n.t('Peers')}</span>
            </li>
            {/* <li>
							<span className="hr-dashed-line gray-dashed-border"></span>
							<span>{i18n.t("Peer_Median")}</span>
						</li> */}
          </ul>
        </div>
        <div className="peerToPeer-line-graph" style={{ height: 450 }}>
          
          <canvas
            // id="canvas"
            // width="400"
            // height="250"
            ref={peerChart}
            // className="chartjs-render-monitor"
          />
          { }
        </div>
      </div>

      <div className="col-lg-4">
        <h5 className="peerToPeer-graph-title">
          {metricData.metricName}
          {' '}
          - %
          {' '}
          {i18n.t('of_Change')}
        </h5>
        {paginationData.filteredPaginationData.length > 1 && (
        <div className="graph-pagination">
          {`Showing ${paginationData.perPageCount} out of ${filteredPeerData.length} entries`}
          <span
            className={`page-arrow-left ${paginationData.pageNumber === 1 ? 'disabled' : ''}`}
            onClick={() => { onPaginationChange('left'); }}
          />
          <span
            className={`page-arrow-right ${paginationData.pageNumber === paginationData.filteredPaginationData.length ? 'disabled' : ''}`}
            onClick={() => { onPaginationChange('right'); }}
          />
        </div>
        )}

        <div
          id="peerToPeer-bar-graph"
          className="peerToPeer-bar-graph"
          style={{ position: 'relative', width: '90%', height: 450 }}
        >
          <canvas
            // id="canvas"
            // width="300"
            // height="400"
            ref={barChart}
            // style={{border: '1px solid red'}}
            // className="chartjs-render-monitor"
          />
        </div>
      </div>
    </div>
  );
}
PeerToPeerComparisonGraph.propTypes = {
  displayClientNames: PropTypes.bool,
  selectedTrendViewBenchmarkOption: PropTypes.object.isRequired,
  isClientExisting: PropTypes.bool,
  processedAPIData: PropTypes.object.isRequired,
  parentScreen: PropTypes.string.isRequired,
  existingSynOpsClientName: PropTypes.string,
  currentlySelectedClientDetails: PropTypes.object,
  selectedTrendViewBenchmarkMarketUnitOption: PropTypes.object,
  metricData: PropTypes.object,
  formattedEndDate: PropTypes.string,
  formattedStartDate: PropTypes.string,
};

export default PeerToPeerComparisonGraph;
