import React from "react";
import _ from 'lodash';
import classNames from 'classnames';
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import { connect } from "react-redux";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import i18n from "i18next";
import LoadingOverlay from "react-loading-overlay";
import { DisplayMessage } from "../Shared/commonFunctions";
import SynopsLoader from "../Shared/SynopsLoader";
import { store } from "../reducers/configureStore";
import cloneDeep from "lodash/cloneDeep";
import axiosInstance from "../Shared/interceptor";
import {trycatchAlertPopup} from "../Shared/Constant";
import { regexForNumbersOnlyMTC, regexForFields } from "../Shared/Constant";
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


const ERR_MSG_METRIC_DEF_INVALID_VALUE = i18n.t("MultiDefErrMsg1") + " "+ `@ $ % & () \n \- _ , . ? ‘ ” \' \"/`+ " "+i18n.t("MultiDefErrMsg2")  ;



const maxLengthMetricDefinitionFields = 500;

function validateMetricDefinitionFieldValue(str) {
  return regexForFields.test(str);
}

// THE FIELDS FOR A SINGLE METRIC DEFINITION
const singleMetricDefinition = {
  isDisabledNumeratorRadioField: false,
  selectedDefinition: 'numeratorDefinition',
  numeratorDefinition: '',
  isRequiredNumeratorDefinitionField: false,
  errMsgNumeratorDefinitionField: '',
  denominatorDefinition: '',
  isRequiredDenominatorDefinitionField: false,
  errMsgDenominatorDefinitionField: '',
  isEnabled: 'yes',
};

class InsightsDataPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.ERR_MSG_METRIC_DEF_INVALID_VALUE = i18n.t("MultiDefErrMsg1") + " "+ `@ $ % & () \n \- _ , . ? ‘ ” \' \"/`+ " "+i18n.t("MultiDefErrMsg2")  ;

    this.state = {
      show: this.props.show,
      apivalue: "",
      unitsData:[],
      isNameChanged: false,
      selectValue: this.props.SelectValue,
      Display: false,
      SODetails: [],
      checkFlag : false,
      flag: true,
      seqValue:"",
      kpiData: {
        KPIMappingID: 0,
        MetricSeq: null,
        MetricID: 0,
        MetricName: "",
        FrequencyID: 0,
        MetricUnit: "",
        MetricImage: "",
        KPIDesp: null,
        MetricDataID: 0,
        MetricDataDesp: null,
        MetricValue: null,
        RAGStatus: "",
        UpperThreshold: null,
        LowerThreshold: null,
        Attribute1Value: "",
        Attribute2Value: "",
        Month: "",
        metDataAttr: null,
        SOCategoryID: 0,
        LeadingPractice: "",
      },
      iiData: {
        kpiData: []
      },
      cloneDetails: [],
      cloneData: {},
      MetricNameOptions: [],
      SOCategoryID: "",

      errors: {},

      formIsValid: false,
      // MULTIPLE METRIC DEFINITIONS WHICH ARE DYNAMICALLY ADDED
      metricDefinitions: [
        {
          ..._.cloneDeep(singleMetricDefinition),
          isRequiredNumeratorDefinitionField: props.defaultIsRequiredMetricDefinitionFields,
          isRequiredDenominatorDefinitionField: props.defaultIsRequiredMetricDefinitionFields,
        },
      ],
      areAnyDuplicateMetricDefinitions: false,
      isAPILoading: false,
    };
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onSequenceChange = this.onSequenceChange.bind(this);
    this.onUnitChange = this.onUnitChange.bind(this);

    this.handleRejectHide = this.handleRejectHide.bind(this);
    this.handleApproveHide = this.handleApproveHide.bind(this);
    this.getSOCategoryId = this.getSOCategoryId.bind(this);
    this.getKpiMAppingDetails = this.getKpiMAppingDetails.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onAttribute1ValueChange = this.onAttribute1ValueChange.bind(this);
    this.onAttribute2ValueChange = this.onAttribute2ValueChange.bind(this);
    this.handleHide = this.handleHide.bind(this);
    let offeringValue = "";
    let value1 = [];
  }

  onAttribute1ValueChange(event) {
    let errors = {};
    this.setState({
      errors: errors
    });
    let cloneData = this.state.cloneData;
    cloneData.Attribute1Value = event.target.value;

    this.setState({
      cloneData: cloneData
    });
  }

  onAttribute2ValueChange(event) {
    let errors = {};
    this.setState({
      errors: errors
    });
    let cloneData = this.state.cloneData;
    cloneData.Attribute2Value = event.target.value;

    this.setState({
      cloneData: cloneData
    });
  }

  componentDidMount() {
    const { defaultIsRequiredMetricDefinitionFields, kpiData } = this.props;

    let metricDefinitions = [];
    const metricDefinitionsFromProps = _.get(this.props, 'kpiData.MetricDefination', []);
    if (this.props.kpiData) {
      let cloneDetails = new Array();
      cloneDetails[0] = {
        MetricID:this.props.kpiData.MetricID,
        KPIMappingID: this.props.kpiData.KPIMappingID,
        MetricSeq: this.props.kpiData.MetricSeq,
        MetricName: this.props.kpiData.MetricName,
        MetricUnit: this.props.kpiData.MetricUnit,
        MetricValue: this.props.kpiData.MetricValue,
        RAGStatus: this.props.kpiData.RAGStatus,
        UpperThreshold: this.props.kpiData.UpperThreshold,
        LowerThreshold: this.props.kpiData.LowerThreshold,
        Month: this.props.kpiData.Month,
        KPIDesp: this.props.kpiData.KPIDesp,
        MetricDataDesp: this.props.kpiData.MetricDataDesp,
        Attribute1Value: this.props.kpiData.Attribute1Value,
        Attribute2Value: this.props.kpiData.Attribute2Value,
        LeadingPractice: this.props.kpiData.LeadingPractice,
      };

      let cloneData = cloneDeep(cloneDetails);

      if (!_.isEmpty(metricDefinitionsFromProps)) {
        metricDefinitions = _.map(metricDefinitionsFromProps, eachItem => {
          const metricDef = {
            ..._.cloneDeep(singleMetricDefinition),
            isDisabledNumeratorRadioField: kpiData.MetricUnit.trim() === '%',
            selectedDefinition: !_.isEmpty(eachItem.DenominatorDescription) ? 'numeratorAndDenominatorDefinition' : 'numeratorDefinition',
            numeratorDefinition: eachItem.NumeratorDescription,
            isRequiredNumeratorDefinitionField: defaultIsRequiredMetricDefinitionFields,
            denominatorDefinition: eachItem.DenominatorDescription,
            isRequiredDenominatorDefinitionField: defaultIsRequiredMetricDefinitionFields,
            isEnabled: eachItem.IsEnabled ? 'yes' : '',
            KPIMetricDefinatoinID: _.get(eachItem, 'KPIMetricDefinatoinID', null),
          };
          return metricDef;
        });
      } else {
        metricDefinitions = [{ ..._.cloneDeep(this.state.metricDefinitions[0]) }];
      }

      this.setState({
        cloneData: cloneData[0],
        errors: {},
        formIsValid: true,
        metricDefinitions,
      }, () => {
				if (kpiData.MetricUnit.trim() === "%") {
					this.toggleRequiredMetricDefinitionFields();
				}
			});
    }

    this.getSOCategoryId();
    this.getKpiMAppingDetails();
    let unitsData = this.props.unitsData;
		this.setState({unitsData:unitsData})
  }
  componentDidUpdate(prevProps){
		if(prevProps.unitsData !== this.props.unitsData){
			this.setState({unitsData:this.props.unitsData})
		}
	}
  getSOCategoryId() {
    let iiData = this.state.iiData;
    let errors = {};
    let formIsValidMetric = true;

    var data = store.getState();
    let iiDetails =
      data.intelligentInsightMasterReducers["insightandintelligence"];
    this.state.isMetricGroupNameChanged = true;

    if (iiDetails.length > 0) {
      var a = iiDetails.filter(
        data => data.SOCategoryName == this.props.SODetails.SOCategoryName
      )[0].SOCategoryID;
    }
    this.state.SOCategoryID = a;
  }

  getKpiMAppingDetails() {
    let samp = {};
    axiosInstance
      .get(`${LocalApiBaseUrl}Configurator/GetKpiMapping`, {
        params: {
          OrgEntityID: this.props.filterDetails.Client,
          LocationID: this.props.filterDetails.Locations,
          OfferingID: this.props.filterDetails.process,
          SOCategoryID: this.state.SOCategoryID
        }
      })
      .then(response => {
        this.setState({ MetricNameOptions: response.data });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });


  }

  onNameChange(event) {
    let cloneData = this.state.cloneData;

    cloneData.MetricName = event.target.value;

    this.setState({
      cloneData: cloneData
    });
  }

  onDescriptionChange(event) {
    let cloneData = this.state.cloneData;

    cloneData.KPIDesp = event.target.value;

    this.setState({
      cloneData: cloneData
    });
  }

  onSequenceChange(event) {
    let errors = {};
    if(this.state.flag){
			this.setState({
			  seqValue:this.state.kpiData.MetricSeq,
			  flag : false,
        checkFlag : true
			});
		  }
    this.setState({
      errors: errors
    });
    let cloneData = this.state.cloneData;

    cloneData.MetricSeq = event.target.value;
    this.setState({
      cloneData: cloneData
    });
  }

  onUnitChange(event) {
    let errors = {};

    this.setState({
      errors: errors
    });
    const cloneData = this.state.cloneData;
    cloneData.MetricUnit = event.target.options[event.target.selectedIndex].value;
    this.setState({
      cloneData: cloneData
    }, () => {
      this.toggleRequiredMetricDefinitionFields();
      this.clearMetricDefinitionFieldsErrors();
    });
  }

  handleSave(e) {
    const { metricDefinitions } = this.state;

    if (this.validateForm()) {
      let filterProcess = this.props.filterDetails.process;
      let cloneData = this.state.cloneData;
      cloneData.KPIMappingID =
        cloneData.KPIMappingID == 0 ? Math.random() : cloneData.KPIMappingID;
      if (this.props.SODetails) {
        this.state.iiData.SOCategoryName = this.props.SODetails.SOCategoryName;
      } else if (this.props.Details) {
        // cloneData.SOCategoryName = this.props.Details.SOCategoryName
      }

      this.state.iiData.kpiData = this.state.cloneData;

      this.state.iiData.kpiData.SOCategoryID = this.state.SOCategoryID;


      let SUCC_MSG = i18n.t("SUCC_MSG2");
      let ERR_MSG = i18n.t("ERR_MSG2");


      // ADD MetricDefinitions to the request parameters
      const metricDefinitionsForRequest = _.map(metricDefinitions, eachItem => {
        const metricDef = {
          NumeratorDescription: eachItem.numeratorDefinition,
          DenominatorDescription: eachItem.denominatorDefinition,
          IsEnabled: eachItem.isEnabled === 'yes' ? 'true' : 'false',
        };
        if (!_.isEmpty(String(_.get(eachItem, 'KPIMetricDefinatoinID', '')))) { // IF THE METRIC DEFINITION IS BEING EDITED
          metricDef.KPIMetricDefinatoinID = eachItem.KPIMetricDefinatoinID;
        }
        return metricDef;
      });
      this.state.iiData.kpiData.MetricDefination = metricDefinitionsForRequest;

      this.setState({ isAPILoading: true });
      axiosInstance
        .post(
          `${LocalApiBaseUrl}Master/PostKpiMetricMasterTemplate`,
          this.state.iiData.kpiData,
          {
            params: { OfferingID: filterProcess, LanguageID: store.getState().CurrentFilter.languageData.id }
          }
        )
        .then(response => {
          this.setState({ isAPILoading: false }, () => {
            store.dispatch({
              type: "ADD_EDIT_KPI_MASTER",
              payload: response.data.kPIdata
            });
            window.alert(SUCC_MSG);
            this.handleHide();
          });
        })
        .catch((error)=>{
trycatchAlertPopup(error);
          this.setState({ isAPILoading: false }, () => {
           
            DisplayMessage();
          });
        });
    }
  }
  handleHide(e) {
    this.setState({ 
      show: false,
      flag : true
     });
     if(this.state.checkFlag){
      this.state.kpiData.MetricSeq = this.state.seqValue
      this.setState({
        checkFlag : false
      })
     }
    
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
  }
  handleRejectHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }

  passToConfirmation(data) {
    this.props.enteredData(data.PoNumber);
  }

  validateForm() {
    const { metricDefinitions } = this.state;
    const { defaultIsRequiredLeadingPractice } = this.props;

    let kpiData = this.state.cloneData;

    const cloneData = this.state.cloneData;
    let errors = {};
    let formIsValid = true;
    if (cloneData["MetricName"])
      cloneData["MetricName"] = cloneData["MetricName"].trim();
    if (!cloneData["MetricName"]) {
      formIsValid = false;
     
      errors["MetricName"] = i18n.t("addWoErrorM1");

    }
    if (cloneData["MetricUnit"])
      cloneData["MetricUnit"] = cloneData["MetricUnit"];
    if (!cloneData["MetricUnit"]) {
      formIsValid = false;
      
      errors["MetricUnit"] = i18n.t("Unit_is_required");

    }
    if (cloneData["MetricSeq"]) {

      cloneData["MetricSeq"] = parseInt(cloneData["MetricSeq"], 10);
    }
    if (!cloneData["MetricSeq"]) {
      formIsValid = false;
      
      errors["MetricSeq"] = i18n.t("addWoErrorM5");

    } else if (
      !(cloneData["MetricSeq"] >= 0 && cloneData["MetricSeq"] <= 999999)
    ) {
      formIsValid = false;
      
      errors["MetricSeqRange"] =	i18n.t("addWoErrorM6");

    }

    //Validation for Attribute1value and Attribute2value
    
    if (
      !cloneData["Attribute1Value"].trim() &&
      !cloneData["Attribute2Value"].trim()
    ) {
      // attribute values can never be left empty - fill NA
      formIsValid = false;
      

      errors["Attribute1Value"] =	i18n.t("AttributeValue_ErrMsg1");
			errors["Attribute2Value"] =	i18n.t("AttributeValue_ErrMsg1");


    }

    if (cloneData["Attribute1Value"].trim()) {
      if (Number.isNaN(parseFloat(cloneData["Attribute1Value"].trim()))) {
        // it is a string

        if (cloneData["Attribute1Value"].toLowerCase().trim() !== "na") {
          formIsValid = false;
          errors["Attribute1Value"] = "NA is the only permitted string value";
        }
      } else {
        //attribute 1 is a whole number or decimal OR has alphabets and numbers ex. 12efgh3456
        //if the string contains anything except nos,-- alphabets or spl chars, not allowed
        if (!regexForNumbersOnlyMTC.test(cloneData["Attribute1Value"].trim())) {
          formIsValid = false;
          // errors["Attribute1Value"] = "NA is the only permitted string value";
          errors["Attribute1Value"] = i18n.t("AttributeValue_ErrMsg2");

        }

        if (
          !(
            parseFloat(cloneData["Attribute1Value"].trim()) >= 0 &&
            parseFloat(cloneData["Attribute1Value"].trim()) <= 999999
          )
        ) {
          formIsValid = false;

          // errors["Attribute1Value"] = `${this.props.attributeLabels.AttributeName1}` + " value must be in between 0 to 999999";
          errors["Attribute1Value"] = `${this.props.attributeLabels.AttributeName1}`+ " " + i18n.t("addBoErrorM2");
         
        }

        //0 to 999999 validation for attribute1value
      }
      if (!cloneData["Attribute2Value"].trim()) {
        // errors["Attribute2Value"] = "Please enter a value for " + `${this.props.attributeLabels.AttributeName2}`;
        errors["Attribute2Value"] = i18n.t("AttributeValue_ErrMsg3") + " " + `${this.props.attributeLabels.AttributeName2}`;

        formIsValid = false;
      }
      // TODO: needs to be chnaged later when attribute labels are dynamic
    }

    if (cloneData["Attribute2Value"].trim()) {
      if (Number.isNaN(parseFloat(cloneData["Attribute2Value"].trim()))) {
        // it is a string

        if (cloneData["Attribute2Value"].toLowerCase().trim() !== "na") {
          formIsValid = false;
          // errors["Attribute2Value"] = "NA is the only permitted string value";
          errors["Attribute2Value"] = i18n.t("AttributeValue_ErrMsg2");

        }
      } else {
        //attribute 2 is a whole number or decimal OR has alphabets and numbers ex. 12efgh3456
        if (
          !regexForNumbersOnlyMTC.test(cloneData["Attribute2Value"].trim())

        ) {
          formIsValid = false;
          // errors["Attribute2Value"] = "NA is the only permitted string value";
          errors["Attribute2Value"] = i18n.t("AttributeValue_ErrMsg2");

        }

        //0 to 999999 validation for attribute2value
        if (
          !(
            parseFloat(cloneData["Attribute2Value"].trim()) >= 0 &&
            parseFloat(cloneData["Attribute2Value"].trim()) <= 999999
          )
        ) {
          formIsValid = false;

            errors["Attribute2Value"] =
            `${this.props.attributeLabels.AttributeName2}` +" "+ i18n.t("addBoErrorM2");
        }
      }
      if (!cloneData["Attribute1Value"].trim()) {
        errors["Attribute1Value"] = i18n.t("AttributeValue_ErrMsg3") + " " +`${this.props.attributeLabels.AttributeName1}`;

        formIsValid = false;
      }
      // TODO: needs to be chnaged later when attribute labels are dynamic
    }
    // }

    // VALIDATE "LEADING PRACTICE" FIELD
    const LeadingPractice = kpiData.LeadingPractice;
    if (defaultIsRequiredLeadingPractice && _.isEmpty(LeadingPractice)) {
      formIsValid = false;
      // errors["LeadingPractice"] = "Leading Practice is required";
			errors["LeadingPractice"] = i18n.t("Leading_Practice_ErrMsg");

    }

    // VALIDATE "METRIC DEFINITIONS"
    const modifiedMetricDefinitions = _.map(metricDefinitions, eachItem => {
      const selectedDefinition = eachItem.selectedDefinition;
      let errMsgNumeratorDefinitionField = '';
      let errMsgDenominatorDefinitionField = '';

      const numeratorDefinition = String(eachItem.numeratorDefinition).trim();
      const denominatorDefinition = String(eachItem.denominatorDefinition).trim();

      // FOR `numeratorDefinitionField` CHECK IF THE FIELD IS REQUIRED AND ITS VALUE IS NOT EMPTY
      if (eachItem.isRequiredNumeratorDefinitionField && _.isEmpty(numeratorDefinition)) {
        // errMsgNumeratorDefinitionField = 'Numerator Definition is required';
        errMsgNumeratorDefinitionField = i18n.t("NumeratorDefReqd");


      } else if (!_.isEmpty(numeratorDefinition) && !validateMetricDefinitionFieldValue(numeratorDefinition)) {
        errMsgNumeratorDefinitionField = this.ERR_MSG_METRIC_DEF_INVALID_VALUE;
      }

      if (selectedDefinition === 'numeratorAndDenominatorDefinition') {
        // IF (FIELD IS REQUIRED AND VALUE IS EMPTY) OR (`numeratorDefinition` IS NOT EMPTY BUT `denominatorDefinition` IS EMPTY)
        if (
          (eachItem.isRequiredDenominatorDefinitionField && _.isEmpty(denominatorDefinition)) ||
          (!_.isEmpty(numeratorDefinition) && _.isEmpty(denominatorDefinition))
        ) {
          // errMsgDenominatorDefinitionField = 'Denominator Definition is required';
          errMsgDenominatorDefinitionField = i18n.t("DenominatorDefReqd");

        }
        // `denominatorDefinition` IS NOT EMPTY BUT `numeratorDefinition` IS EMPTY
        if (!_.isEmpty(denominatorDefinition) && _.isEmpty(numeratorDefinition)) {
          // errMsgNumeratorDefinitionField = 'Numerator Definition is required';
          errMsgNumeratorDefinitionField = i18n.t("NumeratorDefReqd");

        }
      }

      if (!_.isEmpty(denominatorDefinition) && !validateMetricDefinitionFieldValue(denominatorDefinition)) {
        errMsgDenominatorDefinitionField = this.ERR_MSG_METRIC_DEF_INVALID_VALUE;
      }
      eachItem.errMsgNumeratorDefinitionField = errMsgNumeratorDefinitionField;
      eachItem.errMsgDenominatorDefinitionField = errMsgDenominatorDefinitionField;

      // IF THERE WAS SOME ERROR IN DYNAMICALLY ADDED METRIC DEFINITIONS THEN SET THE FORM TO INVALID
      if (!_.isEmpty(errMsgNumeratorDefinitionField) || !_.isEmpty(errMsgDenominatorDefinitionField)) {
        formIsValid = false;
      }

      eachItem.numeratorDefinition = numeratorDefinition;
      eachItem.denominatorDefinition = denominatorDefinition;
      return eachItem;
    });

    // CHECK FOR DUPLICATE METRIC DEFINITIONS
    let areAnyDuplicateMetricDefinitions = false;
    _.forEach(modifiedMetricDefinitions, function (eachMetDef, index) {
      const modifiedNumeratorDefinition = String(eachMetDef.numeratorDefinition).trim();
      const modifiedDenominatorDefinition = String(eachMetDef.denominatorDefinition).trim();

      // FOR ALL THE SELECTED RADIO OPTIONS "Numerator Definition", CHECK FOR DUPLICATE VALUES
      const eqNumField = _.find(modifiedMetricDefinitions, function (eachItem, indx) {
        const foundNumeratorDefinition = String(eachItem.numeratorDefinition).trim();

        if (indx > index && eachMetDef.selectedDefinition === 'numeratorDefinition' && eachItem.selectedDefinition === 'numeratorDefinition' && !_.isEmpty(modifiedNumeratorDefinition) && !_.isEmpty(foundNumeratorDefinition) && modifiedNumeratorDefinition === foundNumeratorDefinition) {
          return true;
        }
      });
      if (eqNumField) {
        areAnyDuplicateMetricDefinitions = true;
      }

      // FOR ALL THE SELECTED RADIO OPTIONS "Numerator & Denominator Definition", CHECK FOR DUPLICATE VALUES
      const eqNumDenoField = _.find(modifiedMetricDefinitions, function (eachItem, indx) {
        const foundNumeratorDefinition = String(eachItem.numeratorDefinition).trim();
        const foundDenominatorDefinition = String(eachItem.denominatorDefinition).trim();

        if (indx > index && eachMetDef.selectedDefinition === 'numeratorAndDenominatorDefinition' && eachItem.selectedDefinition === 'numeratorAndDenominatorDefinition' && !_.isEmpty(modifiedNumeratorDefinition) && !_.isEmpty(foundNumeratorDefinition) && modifiedNumeratorDefinition === foundNumeratorDefinition && !_.isEmpty(modifiedDenominatorDefinition) && !_.isEmpty(foundDenominatorDefinition) && modifiedDenominatorDefinition === foundDenominatorDefinition) {
          return true;
        }
      });
      if (eqNumDenoField) {
        areAnyDuplicateMetricDefinitions = true;
      }
    });
    // IF THERE WERE ANY DUPLICATE METRIC DEFINITIONS THEN SET THE FORM VALID STATUS TO FALSE
    if (areAnyDuplicateMetricDefinitions) {
      formIsValid = false;
    }

    this.setState({
      errors: errors,
      metricDefinitions: modifiedMetricDefinitions,
      areAnyDuplicateMetricDefinitions,
    });

    if (formIsValid) {
      this.setState({
        formIsValid: true
      });
    } else {
      this.setState({
        formIsValid: false
      });
    }
    return formIsValid;
  }

  // EXECUTED WHEN THE "LeadingPractice" FIELD VALUE WOULD BE CHANGED
  handleChangeLeadingPractice = (e) => {
    const LeadingPractice = e.target.value;
    this.setState((prevState) => ({
      cloneData: {
        ...prevState.cloneData,
        LeadingPractice,
      },
    }));
  }

  /**
   * When the unit of metric is "%" definition of both numerator & denominator should be mandatory.
   * Numerator and Denominator fields are mandatory fields for BO, II & DD page. For  H&M & WO page keep them optional.
   * So Disable "numerator" (radio) selection based on above condition.
   */
  toggleRequiredMetricDefinitionFields = () => {
    const { defaultIsRequiredMetricDefinitionFields } = this.props;
    const { metricDefinitions } = this.state;
    const kpiData = this.state.cloneData;
    const MetricUnit = kpiData.MetricUnit;

    let areRequiredMetricDefinitionFields = defaultIsRequiredMetricDefinitionFields;

    let isDisabledNumeratorDefinitionField = false;
    if (MetricUnit === _.trim('%')) {
      areRequiredMetricDefinitionFields = true;
      isDisabledNumeratorDefinitionField = true;
    }

    // FOR ALL THE DYNAMICALLY ADDED METRIC DEFINITIONS, TOGGLE DISABLE OF THE "NUMERATOR RADIO" FIELD
    const modifiedMetricDefinitions = _.map(metricDefinitions, (eachItem, index) => {
      eachItem.isDisabledNumeratorRadioField = isDisabledNumeratorDefinitionField;
      // IF THE "NUMERATOR RADIO" FIELD IS TO BE DISABLED, THEN SET SELECTED "NUMERATOR AND DENOMINATOR RADIO" FIELD
      if (isDisabledNumeratorDefinitionField) {
        eachItem.selectedDefinition = 'numeratorAndDenominatorDefinition';
      }
      eachItem.isRequiredNumeratorDefinitionField = areRequiredMetricDefinitionFields;
      eachItem.isRequiredDenominatorDefinitionField = areRequiredMetricDefinitionFields;
      return {
        ...eachItem,
      };
    });

    this.setState({
      metricDefinitions: modifiedMetricDefinitions,
    });
  }

  // DYNAMICALLY ADDS A NEW ROW FOR ADDING METRIC DEFINITION 
  addMetricDefinitionRow = () => {
    const { defaultIsRequiredMetricDefinitionFields } = this.props;
    const { metricDefinitions } = this.state;
    const clonedSingleMetricDefinition = _.cloneDeep(singleMetricDefinition);

    clonedSingleMetricDefinition.isRequiredNumeratorDefinitionField = defaultIsRequiredMetricDefinitionFields;
    clonedSingleMetricDefinition.isRequiredDenominatorDefinitionField = defaultIsRequiredMetricDefinitionFields;

    const currentMetricDefinitions = _.cloneDeep(metricDefinitions);
    currentMetricDefinitions.push(clonedSingleMetricDefinition);
    this.setState({
      metricDefinitions: currentMetricDefinitions,
    }, () => {
      this.toggleRequiredMetricDefinitionFields();
    });
  }

  // REMOVES THE SELECTED DYNAMICALLY ADDED METRIC DEFINITION SECTION
  removeMetricDefinitionRow = (rowIndex) => {
    const { metricDefinitions } = this.state;
    const remainingMetricDefinitions = _.filter(metricDefinitions, (eachItem, index) => {
      return index !== rowIndex;
    });
    this.setState({
      metricDefinitions: remainingMetricDefinitions,
    });
  }

  // EXECUTES WHEN THE ANY OF THE FIELD'S VALUE OF DYNAMICALLY ADDED METRIC DEFINITION WOULD BE CHANGED
  handleChangeMetricDefinitionField = (e, rowIndex, fieldName) => {
    const { metricDefinitions } = this.state;
    let fieldValue = e.target.value;
    if (fieldName === 'isEnabled') {
      fieldValue = e.target.checked ? 'yes' : '';
    }
    const modifiedMetricDefinitions = _.map(metricDefinitions, (eachItem, index) => {
      if (index === rowIndex) {
        eachItem[fieldName] = fieldValue;
      }
      return {
        ...eachItem,
      };
    });
    this.setState({
      metricDefinitions: modifiedMetricDefinitions,
    });
  }

  // REMOVES THE ERRORS OF DYNAMICALLY ADDED METRIC DEFINITIONS
  clearMetricDefinitionFieldsErrors = () => {
    const { metricDefinitions } = this.state;
    const modifiedMetricDefinitions = _.map(metricDefinitions, (eachItem) => {
      return {
        ...eachItem,
        errMsgNumeratorDefinitionField: '',
        errMsgDenominatorDefinitionField: '',
      };
    });

    this.setState({
      metricDefinitions: modifiedMetricDefinitions,
    });
  }

  render() {
    const { defaultIsRequiredLeadingPractice } = this.props;
    const { metricDefinitions, areAnyDuplicateMetricDefinitions, isAPILoading } = this.state;

    const kpiData = this.state.cloneData;

    if (Object.entries(this.state.cloneData).length !== 0) {
      return (
        <>
          {/* <Header /> */}
          {/* IF API IS LOADING THEN SHOW THE LOADER */}
     
          <Modal
            show={this.state.show}
            onHide={this.handleHide}
            className="modal fade master-template-configurator"
            size="medium"
            id="dealpopup"
            backdrop="static"
          >
            <Modal.Header closeButton>
              <h4 className="modal-title" id="dealprofile">
                <span>{i18n.t("Metric_Template")} - {i18n.t("Insights_Details")} {" - "}{this.props.kpiData.MetricName ? this.props.kpiData.MetricName : ""}</span>
              </h4>
            </Modal.Header>
            <Modal.Body>

            {isAPILoading && (
            <LoadingOverlay
              fadeSpeed={0}
              spinner={<SynopsLoader />}
              active
            />
          )}

              <div className="form-section">
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label>
                      {i18n.t("Name")} <span className="required">*</span>
                    </label>
                    <input
                      disabled={this.props.kpiData && store.getState().CurrentFilter.languageData.id === 1}
                      type="text"
                      maxLength="100"
                      className="form-control"
                      id="name"
                      onChange={this.onNameChange}
                      value={this.state.cloneData.MetricName}
                    />
                    <span className="errorMsg">
                      {this.state.errors.MetricName}
                    </span>
                  </div>

                  <div className="form-group col-lg-6">
                    <label>{i18n.t("Unit_of_Measure")} <span className="required">*</span></label>
                    {this.props.kpiData ? <input
										type="text"
										maxLength="100"
										className="form-control"
										
                    value={this.state.cloneData.MetricUnit}
                    disabled={this.props.kpiData}
									/> :
                    <select className="form-control" value={this.state.cloneData.MetricUnit} onChange={this.onUnitChange} disabled={this.props.kpiData}>
										<option value="">{i18n.t("Please_Select_Unit")}</option>
										{this.state.unitsData.map((each)=>{
											return <option value={each.MetricUnit_En}>{each.MetricUnit}</option>
										})}
									</select>}
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-lg-6">
                    <label>
                      {i18n.t("Sequence")} <span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id=""
                      onChange={this.onSequenceChange}
                      value={this.state.cloneData.MetricSeq}
                    />
                    <span className="errorMsg">
                      {this.state.errors.MetricSeq}
                    </span>
                  </div>

                  <div className="form-group col-lg-6">
                    <label>
                      {i18n.t("Leading_Practice")}
                      {defaultIsRequiredLeadingPractice && (
                        <span className="required">{' '}*</span>
                      )}
                      &nbsp;
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            {i18n.t("addBOMess2")}
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </OverlayTrigger>
                    </label>
                    <select
                      value={kpiData.LeadingPractice}
                      disabled={store.getState().CurrentFilter.languageData.id !== 1}
                      onChange={(e) => this.handleChangeLeadingPractice(e)}
                      className='form-control'
                    >
                      <option value="">{i18n.t("Select_Leading_Practice")}</option>
                      <option value={i18n.t("Maximum")}>{i18n.t("Maximum")}</option>
                      <option value={i18n.t("Minimum")}>{i18n.t("Minimum")}</option>
                    </select>
                    <div className="errorMsg">{this.state.errors.LeadingPractice}</div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-lg-12">
                    <label>{i18n.t("Description")} </label>
                    <input
                      maxLength="255"
                      type="text"
                      className="form-control"
                      id=""
                      onChange={this.onDescriptionChange}
                      value={this.state.cloneData.KPIDesp}
                    />
                  </div>
                </div>

                {/* New row for 2 new fields */}

                <div className="row">
                  <div className="form-group col-lg-6">
                    <label>
                      {this.props.attributeLabels.AttributeName1}{" "}
                      <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      disabled={store.getState().CurrentFilter.languageData.id !== 1}
                      onChange={this.onAttribute1ValueChange}
                      value={
                        this.state.cloneData
                          ? this.state.cloneData.Attribute1Value.trim()
                          : ""
                      }
                    />

                    <div className="errorMsg">
                      {this.state.errors.Attribute1Value}
                    </div>
                  </div>

                  <div className="form-group col-lg-6">
                    <label>
                      {this.props.attributeLabels.AttributeName2}{" "}
                      <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      disabled={store.getState().CurrentFilter.languageData.id !== 1}
                      onChange={this.onAttribute2ValueChange}
                      value={
                        this.state.cloneData
                          ? this.state.cloneData.Attribute2Value.trim()
                          : ""
                      }
                    />

                    <div className="errorMsg">
                      {this.state.errors.Attribute2Value}
                    </div>
                  </div>
                </div>

                {/* START - METRIC DEFINITION */}
                {areAnyDuplicateMetricDefinitions && (
                  <div className="row">
                    <div className="col">
                      <Alert variant="danger">
                        {i18n.t("DuplicateMetricDefinitionAlert")}
                      </Alert>
                    </div>
                  </div>
                )}

                <div className="title-with-icon mt-3 mb-1">
                  <h3 className="border-0">{i18n.t("Metric_Definition")}
                  {" "}
                                            <OverlayTrigger
                                                key="top"
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip-top">
                                                        {i18n.t("addBOMess3")}
                                                    </Tooltip>
                                                }
                                            >
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                            </OverlayTrigger>
                  </h3>
                  <div className="list-icon">
                    {store.getState().CurrentFilter.languageData.id === 1 ? <span
                      id="rock"
                      onClick={() => this.addMetricDefinitionRow()}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </span> : ""}
                  </div>
                </div>

                <div>
                  {_.map(metricDefinitions, (eachMetricDefinition, index) => {
                    const selectedDefinition = eachMetricDefinition.selectedDefinition;
                    const isVisibleDenominatorDefinition = selectedDefinition === 'numeratorAndDenominatorDefinition';
                    return (
                      <div className="mb-3" key={`definition_${index}`}>
                        <div className="row">
                          <div className="form-group col-lg-12 mb-1">
                            <label className="form-check mr-5">
                              <input
                                type="radio"
                                name={`definition_${index}`}
                                value="numeratorDefinition"
                                onChange={(e) => this.handleChangeMetricDefinitionField(e, index, 'selectedDefinition')}
                                checked={selectedDefinition === 'numeratorDefinition'}
                                disabled={ eachMetricDefinition.isDisabledNumeratorRadioField || store.getState().CurrentFilter.languageData.id !== 1}
                                className="form-check-input"
                              />
                              <span className="checkmark" />
                              <span className="form-check-label">{i18n.t("Numerator_Definition")}</span>
                            </label>

                            <label className="form-check">
                              <input
                                type="radio"
                                name={`definition_${index}`}
                                value="numeratorAndDenominatorDefinition"
                                onChange={(e) => this.handleChangeMetricDefinitionField(e, index, 'selectedDefinition')}
                                checked={selectedDefinition === 'numeratorAndDenominatorDefinition'}
                                disabled={store.getState().CurrentFilter.languageData.id !== 1	}

                                className="form-check-input"
                              />
                              <span className="checkmark" />
                              <span className="form-check-label">{i18n.t("Numerator&Denominator_Definition")}</span>
                            </label>

                            {index !== 0 && (
                              <>
                                {_.isNil(_.get(eachMetricDefinition, 'KPIMetricDefinatoinID', null)) && (
                                  store.getState().CurrentFilter.languageData.id === 1 ? <span
                                    className="del-row-icon list-icon"
                                    onClick={() => this.removeMetricDefinitionRow(index)}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </span> : ""
                                )}

                              { store.getState().CurrentFilter.languageData.id === 1 && (

                                <div className="toggle-group">
                                  <input
                                    type="checkbox"
                                    value="yes"
                                    checked={String(eachMetricDefinition.isEnabled) === 'yes'}
                                    onChange={(e) => this.handleChangeMetricDefinitionField(e, index, 'isEnabled')}
                                    id={`isEnabled_${index}`}
                                  />
                                  <label htmlFor={`isEnabled_${index}`} />
                                  <div className="onoffswitch" aria-hidden="true">
                                    <div className="onoffswitch-label">
                                      <div className="onoffswitch-inner" />
                                      <div className="onoffswitch-switch" />
                                    </div>
                                  </div>
                                </div>
                       				)}

                              </>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className={classNames(
                            'form-group',
                            { 'col-lg-6': isVisibleDenominatorDefinition },
                            { 'col': !isVisibleDenominatorDefinition },
                          )}>
                            <label>
                              {i18n.t("Numerator_Definition")}
                              {eachMetricDefinition.isRequiredNumeratorDefinitionField && (
                                <span className="required">{' '}*</span>
                              )}
                            </label>
                            <input
                              type="text"
                              value={eachMetricDefinition.numeratorDefinition}
                              onChange={(e) => this.handleChangeMetricDefinitionField(e, index, 'numeratorDefinition')}
                              className="form-control"
                              maxLength={maxLengthMetricDefinitionFields}
                            />
                            <div className="errorMsg">
                              {eachMetricDefinition.errMsgNumeratorDefinitionField}
                            </div>
                          </div>

                          {isVisibleDenominatorDefinition && (
                            <div className="form-group col-lg-6">
                              <label>
                                {i18n.t("Denominator_Definition")}
                                {eachMetricDefinition.isRequiredDenominatorDefinitionField && (
                                  <span className="required">{' '}*</span>
                                )}
                              </label>
                              <input
                                type="text"
                                value={eachMetricDefinition.denominatorDefinition}
                                onChange={(e) => this.handleChangeMetricDefinitionField(e, index, 'denominatorDefinition')}
                                className="form-control"
                                maxLength={maxLengthMetricDefinitionFields}
                              />
                              <div className="errorMsg">
                                {eachMetricDefinition.errMsgDenominatorDefinitionField}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/* END - METRIC DEFINITION */}

              </div>
            </Modal.Body>
            {
              <Modal.Footer>
                <div className="modal-btn">
                  <button
                    onClick={this.handleHide}
                    className="btn btn-default btn-sm mr-2"
                  >
                    {i18n.t("Cancel")}
                  </button>

                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave()}
                    value={i18n.t("Update")}
                  />
                </div>
              </Modal.Footer>
            }
          </Modal>
        </>
      );
    } else {
      return null;
    }
  }
}

InsightsDataPopUp.defaultProps = {
  screenName: 'InsightIntelligence',
  // Keep "Leading Practice" as non-mandatory for H&M and WO, mandatory for BO, II & DD
  defaultIsRequiredLeadingPractice: true,
  defaultIsRequiredMetricDefinitionFields: true,
};
const mapStateToProps = (state) => {
  return ({
  languageData: state.CurrentFilter.languageData,
  unitsData: state.BOMasterReducers.unitsData
  })
}
export default connect(mapStateToProps)(InsightsDataPopUp);

