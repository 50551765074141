import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import i18n from "i18next";
import {
    LocalApiBaseUrl,
    DomainBaseUrl,
    ProfilePic,
    LocalApiBaseUrlImage,
    PeoplePage,
    commonToolRegex,
    regexForToolURL,
    regexForVideoDocURL,
} from "../Shared/Constant";
import '../CSS/helpsection.css';


 class AddFunctionality extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            addFunctionalityStatus: this.props.addFunctionalityStatus,
            ShowButton:false,
            TopicDetails: {
                ID:0,
                TopicName: "",
                TopicDescription: "",
                VideoLink: "",
                DocLink: "",
                IsActive:false 
            }
        };
    }
componentDidMount(){
    let TopicDetails=this.state.TopicDetails
    if(this.props.EditData){
        TopicDetails={...this.props.EditData}
    }
this.setState({ 
    TopicDetails:TopicDetails
})

}


    handleHide(e) {
        debugger
        this.setState({ addFunctionalityStatus: false });
        this.props.childToParent("Add");
    }
    bindTextFieldChange = (e) => {
        const id = e.target.id;
        let data = this.state.TopicDetails;
        switch (id) {
            case "Title":
                data.TopicName = e.target.value;
                break;
            case "TopicDescription":
                data.TopicDescription = e.target.value;
                break;
            case "videolink":
                data.VideoLink = e.target.value;
                break;
            case "DocLink":
                data.DocLink = e.target.value;
                break;
        }

        this.setState({
            TopicDetails: data
        });
    }
    validateForm() {
        debugger
        let data = this.state.TopicDetails
        let errors = this.state.errors;
        let isValid = true;
        Object.keys(data).map((key) => {
            switch (key) {
                case "TopicName":
                    data[key] ? (errors[key] = "") : (errors[key] = i18n.t("Functionality_Title_is_Required"))
                    if (errors[key])
                        isValid = false;
                    break;
                case "TopicDescription":
                    data[key] ? (errors[key] = "") : (errors[key] = i18n.t("Topic_Description_is_Required"))
                    if (errors[key])
                        isValid = false;
                    break;
                case "VideoLink":
                    data[key] ?
                    !commonToolRegex.test(data[key].trim().toLowerCase())?
                    regexForToolURL.test(data[key].trim().toLowerCase()) ?
                            (errors[key] = "") :
                            (errors[key] = i18n.t("Invalid_URL"))
                            :(errors[key] = i18n.t("commonToolRegex_validatemessage"))
                        : (errors[key] = "")/* (errors[key] = "VideoLink is Required") */
                    if (errors[key])
                        isValid = false;
                    break;
                case "DocLink":
                    data[key] ?
                    !commonToolRegex.test(data[key].trim().toLowerCase())?
                            regexForToolURL.test(data[key].trim().toLowerCase()) ?
                            (errors[key] = "") :
                            (errors[key] = i18n.t("Invalid_URL"))
                            :(errors[key] = i18n.t("commonToolRegex_validatemessage"))
                        : (errors[key] = "")/* (errors[key] = "DocLink is Required") */
                    if (errors[key])
                        isValid = false;
                    break;
            }
        });
        this.setState({
            errors: errors
        })
        return isValid
    }
    handleSave() {
        let data = this.state.TopicDetails
        if (this.validateForm()) {

            this.setState({ShowButton:true})

            axiosInstance.post(`${LocalApiBaseUrl}UserGuide/AddEditFunctionalityDetails`, data,
            {
                params:{
                    languageId:store.getState().CurrentFilter.languageData.id
                   }
            }).then(
                (response) => {
                    window.alert(response.data.StatusMessage);
                    if(response.data.IsSuccess)
                    {
                    this.handleHide();
                    }
                    this.setState({ShowButton:false})
                },
                (error) => {
                    trycatchAlertPopup(error)
                    
                }
            )
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
        }
    }

    render() {
        const { TopicName, TopicDescription, VideoLink, DocLink } = this.state.TopicDetails
        return (
            <>
                <Modal
                    show={this.state.addFunctionalityStatus}
                    onHide={this.handleHide.bind(this)}
                    className="tooldata-pop"
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.isEdit?i18n.t("Edit_Functionality"):i18n.t("Add_New_Functionality")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="form-section">
                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <label for="Title">{i18n.t("Functionality_Title")}<span className="required">*</span></label>
                                        <input id="Title" value={TopicName} onChange={(e) => { this.bindTextFieldChange(e) }} maxLength="30" type="text" className="form-control" />
                                    </div>
                                </div>
                                <span className="errorMsg">
                                    {this.state.errors.TopicName}
                                </span>
                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <label for="TopicDescription">{i18n.t("Topic_Description")}<span className="required">*</span></label>
                                        <textarea id="TopicDescription" maxLength="200" value={TopicDescription} rows="3" onChange={(e) => { this.bindTextFieldChange(e) }} className="form-control" />
                                    </div>
                                </div>
                                <span className="errorMsg">
                                    {this.state.errors.TopicDescription}
                                </span>
                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <label for="videolink">{i18n.t("Video_Link")}</label>
                                        <input id="videolink" value={VideoLink} maxLength="5000" onChange={(e) => { this.bindTextFieldChange(e) }} type="text" className="form-control" />
                                    </div>
                                </div>
                                <span className="errorMsg">
                                    {this.state.errors.VideoLink}
                                </span>
                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <label for="DocLink">{i18n.t("Add_document_link")}</label>
                                        <input id="DocLink" value={DocLink} maxLength="5000" onChange={(e) => { this.bindTextFieldChange(e) }} type="text" className="form-control" />
                                    </div>
                                </div>
                                <span className="errorMsg">
                                    {this.state.errors.DocLink}
                                </span>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="modal-btn">
                            <button
                                onClick={this.handleHide.bind(this)}
                                className="btn btn-default btn-sm mr-3"
                            >{i18n.t("Cancel")}</button>
                            <button
                            disabled={this.state.ShowButton}
                                onClick={() => this.handleSave()}
                                className="btn btn-primary btn-sm"
                            >{this.props.isEdit?i18n.t("Update"):i18n.t("Add")}</button>
                        </div>
                    </Modal.Footer>
                </Modal>

            </>
        )
    }
}


export default AddFunctionality;