//REACT IMPORTS
import React, { Component } from "react";

export default class LayoutContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AttributeCount: this.props.editActive? this.props.widgetEditData.Attributes.length:3,
    };
  }

  componentWillReceiveProps(nextProps){
    if(this.props.editActive){
      if(this.props.widgetEditData.IsDonutDisplay != nextProps.widgetEditData.IsDonutDisplay) {
        this.setState({AttributeCount:nextProps.widgetEditData.AttributeCount})
      }
      else if(this.props.widgetEditData.AttributeCount !== nextProps.widgetEditData.AttributeCount){
      this.setState({AttributeCount:nextProps.widgetEditData.AttributeCount})
      } 
    }
    else{
      if (this.props.widgetData.Attributes.length !== nextProps.widgetData.Attributes.length){
      this.setState({AttributeCount: nextProps.widgetData.Attributes.length})
      }
    }
  }
  



  //HANDLING ON CHANGE OF ALL FIELDS 
  handleChange(event,index){
    event.preventDefault();
    let {name,value} = event.target;
    if(name == 'TileHeader'){
      this.props.formData(name,value);
    }
    else {
      this.props.formData(name, value,index);
    }      
  }
  //JSX
  render() {
    let {widgetData,widgetErrors,fieldData,dataTypeData,widgetEditData,editActive} = this.props;
    return (
      <>
        <div className="col-sm-9 form-widget">
          <form>
            <div className="row">
              <div className="form-group col-lg-12">
                <input
                  className="form-control animated-input"
                  id="field1"
                  value={editActive ? widgetEditData.TileHeader : widgetData.TileHeader}
                  name="TileHeader"
                  placeholder="Widget Header"
                  maxLength="100"
                  onChange={(e) => this.handleChange(e)}
                />
                <span
                  className={
                    widgetErrors.TileHeader === "" ? "" : "errorMsg" 
                  }
                >
                  {widgetErrors.TileHeader}
                </span>
              </div>
            </div>
            {new Array(this.state.AttributeCount)
              .fill(true)
              .map((arr, index) => {
                return (
                  <>
                    <div className="row">
                      <div className="col-lg-12 field2">
                        <label htmlFor={`Field + {this.prop.id}`}>
                          Field {index + 1} (F{index + 1})
                        </label>
                      </div>
                      <div className="form-group col-lg-6">
                        <input
                          type="text"
                          className="form-control animated-input"
                          id={`Fieldlabel+ ${index + 1}`}
                          placeholder="Field Label"
                          maxLength="50"
                          value={editActive ? widgetEditData.Attributes[index].AttributeName
                          :widgetData.Attributes[index].AttributeName}
                          name="AttributeName"
                          onChange={(e) => this.handleChange(e,index)}
                        />
                        <span
                          className={
                            widgetErrors.Attributes[index].AttributeName === ""
                              ? ""
                              : "errorMsg"
                          }
                        >
                          {widgetErrors.Attributes[index].AttributeName}
                        </span>
                      </div>
                      <div className="form-group col-lg-3">
                        <div className="select-custom label-animation">
                          <select
                            className="form-control"
                            disabled={this.props.editActive}
                            value={
                              this.props.editActive
                                ? widgetEditData.Attributes[index].AttributeDataType
                                : widgetData.Attributes[index].AttributeDataType
                            }
                            id={`DataType + ${index + 1}`}
                            name="AttributeDataType"
                            onChange={(e) => this.handleChange(e,index)}
                          >
                            <option value="" className="select-field">
                              Data Type
                            </option>
                            {dataTypeData.map((dataOptions, index) => {
                              return (
                                <option
                                  key={index}
                                  value={dataOptions.ID}
                                  className="select-field"
                                >
                                  {dataOptions.DataTypeName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <span
                          className={
                            widgetErrors.Attributes[index].AttributeDataType === ""
                              ? ""
                              : "errorMsg"
                          }
                        >
                          {widgetErrors.Attributes[index].AttributeDataType}
                        </span>
                        <div className="input-information">
                          { this.props.editActive ? null
                          :widgetData.Attributes[index].AttributeDataType !== ""
                            ? widgetData.Attributes[index].AttributeDataType == "1"
                              ? `Max Character ${dataTypeData.length > 0 && dataTypeData[0].MaxLength}`
                              : `Min Max Value: (“${dataTypeData.length > 0 && dataTypeData[1].MinValue} to ${dataTypeData[1].MaxValue}”)`
                            : null}
                          {this.props.editActive
                            ? dataTypeData.length > 0
                              ? widgetEditData.Attributes[index].AttributeDataType == 1
                                ? `Max Character ${dataTypeData.length > 0 && dataTypeData[0].MaxLength}`
                                : `Min Max Value: (“${dataTypeData.length > 0 && dataTypeData[1].MinValue} to ${dataTypeData[1].MaxValue}”)`
                              : null
                            : null}
                        </div>
                      </div>
                      <div className="form-group col-lg-3">
                        <div className="select-custom label-animation">
                          <select
                            disabled={this.props.editActive}
                            className="form-control"
                            id={`FieldType + ${this.props.id}`}
                            name="AttributeFieldType"
                            value={
                              this.props.editActive
                                ? widgetEditData.Attributes[index].AttributeFieldType
                                : widgetData.Attributes[index].AttributeFieldType
                            }
                            onChange={(e) => this.handleChange(e,index)}
                          >
                            <option value="" className="select-field">
                              Field Type
                            </option>
                            {fieldData.map((fieldTypeOptions, index) => {
                              return (
                                <option
                                  key={index}
                                  value={fieldTypeOptions.ID}
                                  className="select-field"
                                >
                                  {fieldTypeOptions.FieldTypeName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <span
                          className={
                            widgetErrors.Attributes[index].AttributeFieldType === ""
                              ? ""
                              : "errorMsg"
                          }
                        >
                          {widgetErrors.Attributes[index].AttributeFieldType}
                        </span>
                      </div>
                    </div>
                    {editActive ? 
                    widgetEditData.Attributes[index].AttributeFieldType === 2 &&
                    <div className="listSourceSec">
                        <div className="row">
                          <div style={{ display: "none" }} className="form-group col-lg-3">
                            <div className="select-custom label-animation">
                              <select
                                className="form-control"
                                defaultValue="Manual"
                                id="DtaType4"
                              >
                                <option className="select-field">
                                  List Source
                                </option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                            </div>
                          </div>
                          <div className="form-group col-lg-12">
                            <textarea
                              id="listData"
                              name="ListItems"
                              placeholder={"Add List Items"}
                              className="form-control animated-input animated-textarea"
                              rows={
                                widgetEditData.Attributes[index].ListItems && widgetEditData.Attributes[index].ListItems.length >= 100 ? "3" : "1"
                              }
                              value={widgetEditData.Attributes[index].ListItems}
                              onChange={(e) => this.handleChange(e,index)}
                            />
                            <span
                              className={
                                widgetErrors.Attributes[index].ListItems === ""
                                  ? ""
                                  : "errorMsg"
                              }
                            >
                              {widgetErrors.Attributes[index].ListItems}
                            </span>
                          </div>
                          <div className="form-group col-lg-12">
                            <label for="Field1">Existing List Items</label>
                            <textarea
                              id="listData"
                              rows="3"
                              value={widgetEditData.Attributes[index].ListItems_En}
                              disabled={this.props.editActive}
                              className="form-control animated-input animated-textarea disabled"
                            >
                            </textarea>
                          </div>
                        </div>
                      </div>
                    : widgetData.Attributes[index].AttributeFieldType === "2" 
                    && 
                    
                      <div className="listSourceSec">
                        <div className="row">
                          <div
                            style={{ display: "none" }}
                            className="form-group col-lg-3"
                          >
                            <div className="select-custom label-animation">
                              <select
                                className="form-control"
                                defaultValue="Manual"
                                id="DtaType4"
                              >
                                <option className="select-field">
                                  List Source
                                </option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                            </div>
                          </div>
                          <div className="form-group col-lg-12">
                            <textarea
                              id="listData"
                              name="ListItems"
                              placeholder={ "Create List Items"}
                              className="form-control animated-input animated-textarea"
                              rows={
                              widgetData.Attributes[index].ListItems.length >= 100 ? "3" : "1"
                              }
                              value={widgetData.Attributes[index].ListItems}
                              onChange={(e) => this.handleChange(e,index)}
                            />
                            <span
                              className={
                                widgetErrors.Attributes[index].ListItems === ""
                                  ? ""
                                  : "errorMsg"
                              }
                            >
                              {widgetErrors.Attributes[index].ListItems}
                            </span>
                          </div>
                        </div>
                      </div>
                    }
                  </>
                );
              })}
            
          </form>
        </div>
      </>
    );
  }
}
