import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import i18n from 'i18next';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { LocalApiBaseUrl, trycatchAlertPopup, regexForFields } from '../Shared/Constant';
import { isValidText,InvalidDescription } from "../Shared/commonFunctions"; 
import { store } from '../reducers/configureStore';
import axiosInstance from '../Shared/interceptor';
import SynopsLoader from '../Shared/SynopsLoader';
import { EditorState, convertFromHTML, convertFromRaw, ContentState, convertToRaw, createFromBlockArray  } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';


const MAX_LENGTH = 1500;


class SubStepPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subStepData: {
        SOCategoryID: props.Details ? props.Details.SOCategoryTypeID : 0,
        ParentSOCategoryID: props.Details ? props.Details.ParentSOCategoryID : props.SOCategoryID,
        SOCategoryName: props.Details ? props.Details.SOCategoryName : '',
        SOCategoryDesp: props.Details ? props.Details.SOCategoryDesp : '',
        SoCategoryImage: props.Details ? props.Details.SoCategoryImage : '',
        SOCategoryTypeID: props.Details ? props.Details.SOCategoryTypeID : 3,
        SequenceID: 0,
        SOCategorySeq: props.Details ? props.Details.SOCategorySeq : '',
        SoCatDataAttr: [],
        kpiData: [],
        SOCategoryType: props.SOCategoryType,
      },
      milestonesEditorStates:[{
        "index":0,
        "value":'',
        "editorState":EditorState.createEmpty(),
      }],
      editorState: EditorState.createEmpty(),

      value: "",
      countDesp:0,
      stepName: props.StepName,
      isFormValid: true,
      errors: {},
      isAPILoading: false,
      isDescDisabled: false,
    };
    this.onSubStepNameChange = this.onSubStepNameChange.bind(this);
    this.onSequenceChange = this.onSequenceChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.checkSubStepName = this.checkSubStepName.bind(this);
  }


  componentDidMount() {
    if(this.props.Details && this.props.Details.SOCategoryDesp){
    const blocksFromHTML = convertFromHTML(this.props.Details.SOCategoryDesp);
    const content = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    this.setState
    ({
      
        editorState: EditorState.createWithContent(content),
    })
}

}

  onSubStepNameChange = (event) => {
    const { subStepData } = this.state;
    subStepData.SOCategoryName = event.target.value;
    this.setState({
      subStepData,
    });
  }

  checkSubStepName = () => {    
    const { subStepData } = this.state;    
    let woConfData = [...this.props.woConfData];    
    let subStepDataofStep = woConfData.filter((data) => data.ParentSOCategoryID === subStepData.ParentSOCategoryID);
    const existingDataLength = subStepDataofStep
    .filter((data) => (data.SOCategoryName.toLowerCase().trim() === subStepData.SOCategoryName.toLowerCase().trim()) && data.IsDeleted === true).length;
    
    if(existingDataLength){
      subStepData.SOCategoryDesp = data.SOCategoryDesp;
      this.setState({
        subStepData,
        isDescDisabled: true
      });
    } else {
      subStepData.SOCategoryDesp = subStepData.SOCategoryID === 0 ? subStepData.SOCategoryDesp : '';
      this.setState({
        subStepData,
        isDescDisabled: false
      });
    }
  }

  onSequenceChange = (event) => {
    const { subStepData } = this.state;
    subStepData.SOCategorySeq = event.target.value;
    this.setState({
      subStepData,
    });
  }

  onDescriptionChange = (event) => {
    const { subStepData } = this.state;
    subStepData.SOCategoryDesp = event.target.value;
    this.setState({
      subStepData: subStepData,
    });
  }

  validateForm = () => {
    const { subStepData } = this.state;
    let isFormValid = true;
    const errors = {};
    if(subStepData.SOCategoryName) {
      subStepData.SOCategoryName = subStepData.SOCategoryName.trim();
    }
    if (!subStepData.SOCategoryName.trim().length) {
      errors.SOCategoryName = i18n.t('SubStep Name is required');
      isFormValid = false;
    } else {
      const isValid = regexForFields.test(subStepData.SOCategoryName);
      if (!isValid) {
        isFormValid = false;
        errors.SOCategoryName = i18n.t('Sub-stepname value contains Special characters');
      }
    }

    // if (woData["SOCategoryDesp"].length > 1500)
    // errors["SOCategoryDesp"] = i18n.t("TMore than 1500 chars not allowed");

    if (subStepData.SOCategorySeq) {
      subStepData.SOCategorySeq = parseInt(subStepData.SOCategorySeq, 10);
    } else {
      subStepData.SOCategorySeq = null;
    }

    if (subStepData.SOCategorySeq == null) {
      isFormValid = false;
      errors.SOCategorySeq = i18n.t('addWoErrorM5');
    } else if (!(subStepData.SOCategorySeq > 0 && subStepData.SOCategorySeq <= 999999)) {
      isFormValid = false;
      errors.SOCategorySeq = i18n.t('addWoErrorM6');
    }
    if (!isValidText(subStepData.SOCategoryDesp.trim())) {
      isFormValid = false;
      errors.SOCategoryDesp = InvalidDescription();
  }

    this.setState({
      errors,
    });

    this.setState({
      isFormValid,
    });
    return isFormValid;
  }

  handleHide = () => {
    const { SOCategoryID, modalClose } = this.props;
    const subStepData = {
      SOCategoryID: 0,
      ParentSOCategoryID: SOCategoryID,
      SOCategoryName: '',
      SOCategoryDesp: '',
      SoCategoryImage: '',
      SOCategoryTypeID: 3,
      SequenceID: 0,
      SOCategorySeq: '',
      SoCatDataAttr: [],
      kpiData: [],
    };
    this.setState({
      subStepData,
      editorState: null,

      errors: {},
    });
    modalClose();
  }

  handleSave = () => {
    const { subStepData } = this.state;
    const {
      screen, filter, isScreenInEditMode, getOfferingAPICallback, selectedSubStepID,
    } = this.props;
    if (this.validateForm()) {
      this.setState({
        subStepData: subStepData,
          //showLoaderOnPage: true,
      });
      if (screen === 'Configurator') {
        const ReqObj = {
          DealTemplateApprovalModel: {
            StepName: subStepData.SOCategoryName,
            StepDescription: subStepData.SOCategoryDesp,
            Sequence: subStepData.SOCategorySeq,
            ParentSOCategoryID: subStepData.ParentSOCategoryID,
          },
          FiltersIDsVM: {
            OrgEntityID: filter.Client,
            OfferingID: filter.process,
            LocationID: filter.Locations,
            MonthDT: filter.Month,
          },
        };
        this.setState({ isAPILoading: true });
        let woConfData = [...this.props.woConfData];
        let subStepDataofStep = woConfData.filter((data) => data.ParentSOCategoryID === subStepData.ParentSOCategoryID)
        subStepDataofStep.map((data) => {
          if((data.SOCategoryName.toLowerCase().trim() === subStepData.SOCategoryName.toLowerCase().trim()) 
              && data.ParentSOCategoryID === subStepData.ParentSOCategoryID
              && data.IsDeleted === true){
            let duplicateSubStepData= {
              SOCategoryID: data.SOCategoryID,
              SOCategorySeq: subStepData.SOCategorySeq,
            }       
            store.dispatch({
              type: 'Adding_Deleted_SubStep',
              payload: duplicateSubStepData,
            });
          }
        })

        const apiCallLink = isScreenInEditMode ? `${LocalApiBaseUrl}DealTemplateWOSteps/PostDealTemplateWOSteps?IsCallingForEdit=1` : `${LocalApiBaseUrl}DealTemplateWOSteps/PostDealTemplateWOSteps`;

          axiosInstance
          .post(apiCallLink,
            ReqObj)
          .then((response) => {
            this.setState({
              isAPILoading: false,
            }, () => {
              window.alert(i18n.t(response.data.StatusMessage));
              this.handleHide();
            });
            if (isScreenInEditMode) {
              subStepData.SOCategoryID = this.props.Details.SOCategoryID;
              store.dispatch({
                type: 'UPDATE_WOSTEP_SEQUENCE',
                payload: subStepData,
              });
            }
          })
          .catch((error) => {
            trycatchAlertPopup(error);
            this.setState({ isAPILoading: false }, () => {
              this.handleHide();
            });
          });
      } else {
        const filterProcess = filter.process;
        subStepData.SOCategoryDescription = subStepData.SOCategoryDesp;
        let duplicate = 0;
        let WOMasterStepData = [...this.props.woTileData];
        WOMasterStepData = WOMasterStepData.filter((data) => data.SOCategoryName_En !== 'Work Orchestration' && data.ParentSOCategoryID !== 3 && data.ParentSOCategoryID === subStepData.ParentSOCategoryID);
        WOMasterStepData.forEach((data) => {
          if (data.SOCategoryName.toLowerCase().trim() === subStepData.SOCategoryName.toLowerCase().trim()) {
            duplicate += 1;
          }
        });
        if (duplicate === 0 || isScreenInEditMode) {
          this.setState({ isAPILoading: true });
          axiosInstance
            .post(`${LocalApiBaseUrl}Master/PostSOCategoryMasterTemplate`, subStepData, {
              params: {
                offeringId: filterProcess,
                LanguageID: store.getState().CurrentFilter.languageData.id,
              },
            })
            .then((response) => {
              if (response.data.IsSuccess) {
                if (!isScreenInEditMode) {
                  window.alert(i18n.t('New SynOps WO sub-step has been added'));
                } else {
                  window.alert(i18n.t('SynOps WO sub-step has been updated successfully'));
                }
              }
              this.setState({ isAPILoading: false , subStepData: subStepData}, () => {
                getOfferingAPICallback(true);
                this.handleHide();
              });
            })
            .catch((error) => {
              trycatchAlertPopup(error);
              this.setState({ isAPILoading: false }, () => {
                this.handleHide();
              });
            });
        } else {
          this.setState({ isAPILoading: false });
          window.alert(i18n.t('This Work Orchestration sub-step already exist.'));
          this.handleHide();
        }
      }
    }
  }

  onEditorStateChange = (editorState) => {
    const editorValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const plainValue = editorState.getCurrentContent().getPlainText();
    if (plainValue.length <= 1500) {

      this.setState({
        subStepData: { ...this.state.subStepData, SOCategoryDesp: editorValue },
        countDesp: plainValue.length,
        editorState,
      });
    } else {
        const fText = String(plainValue).slice(0, MAX_LENGTH);
        this.setState({
          subStepData: { ...this.state.subStepData, SOCategoryDesp: editorValue },
            editorState: EditorState.createWithContent(ContentState.createFromText(fText)),
        })
    }
  };
    
  handleBeforeInput = (editorState) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const MAX_LENGTH = 1500;
    let errors = this.state.errors;
    if (currentContentLength > MAX_LENGTH - 1) {
      errors["SOCategoryDesp"] = i18n.t("More than 1500 chars not allowed");
      this.setState({
        errors,
      });
      return "handled";
    } else {
      errors["SOCategoryDesp"] = "";
      this.setState({
        errors,
      });
    }
  };




  render() {
    const {
      addSubStepStatus, addSubStepConfStatus, show, isScreenInEditMode, modalClose, screen,
    } = this.props;


    const { subStepData, errors, stepName,editorState, isDescDisabled } = this.state;

    return (
      <>
        <LoadingOverlay className="custom-loader" fadeSpeed={0} spinner={<SynopsLoader />} active={this.state.isAPILoading} />
        <Modal
          show={show}
          onHide={modalClose}
          className="tooldata-pop"
          id="dealpopup"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {isScreenInEditMode ? `${i18n.t('Edit Sub Step')} ` : `${i18n.t('Add Sub Step')} `}
              -
              {` ${i18n.t('Work Orchestration')}`}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form>
              <div className="form-section">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-section">
                      <div className="form-group border-group disabled">
                        <label>
                          {i18n.t('Step Name')}
                          <span className="required"> *</span>
                        </label>
                        <input
                          type="text"
                          maxLength="100"
                          className="form-control"
                          id="stepname"
                          value={stepName}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-8">
                    <div className="form-section">
                      <div className={isScreenInEditMode
                        ? 'form-group border-group disabled'
                        : 'form-group border-group'}
                      >
                        <label>
                          {i18n.t('Sub Step Name')}
                          <span className="required"> *</span>
                        </label>
                        <input
                          type="text"
                          maxLength="100"
                          className="form-control"
                          id="substepname"
                          onChange={this.onSubStepNameChange}
                          value={subStepData.SOCategoryName}
                          onBlur={screen === 'Configurator' && this.checkSubStepName}
                        />
                      </div>
                      <span className="errorMsg">
                        {errors.SOCategoryName}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-section">
                      <div className="form-group border-group">
                        <label>
                          {i18n.t('Sequence')}
                          <span className="required"> *</span>
                        </label>
                        <input
                          type="number"
                          min="1"
                          max="999999"
                          className="form-control"
                          id="sequence"
                          onChange={this.onSequenceChange}
                          value={subStepData.SOCategorySeq}
                        />
                      </div>
                      <span className="errorMsg">
                        {errors.SOCategorySeq}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-section">
                      <div className={(screen === 'Configurator' && isScreenInEditMode) || isDescDisabled ? 'form-group border-group disabled' : 'form-group border-group'}>
                        <label>
                          {i18n.t('Description')}
                        </label>
                        {/* <input
                          type="text"
                          maxLength="100"
                          className="form-control"
                          id="description"
                          onChange={this.onDescriptionChange}
                          value={subStepData.SOCategoryDesp}
                        /> */}
                         {/* <label for="description">Description
                                       
                                       </label> */}
                                         <div className="description-editor" style={{ position: 'relative' }}>
                        <Editor
                          readOnly={this.props.mode == "UPDATE" ? true : false}
                          editorState={editorState}
                          stripPastedStyles={true}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName={
                            this.props.mode == "UPDATE" ? "EditWoStepDescription" : ""
                          }
                          handleBeforeInput={(e) => this.handleBeforeInput(editorState)}
                          onEditorStateChange={(e) => {
                            this.onEditorStateChange(e);
                          }}
                          onTab={() => true}
                          toolbar={{
                            options: ["list"],
                            list: { inDropdown: false, options: ["unordered"] },
                          }}
                        />
                          {this.props.mode != "UPDATE"  ? (
                        <span className="suffix-text">
                          {this.state.countDesp}
                          /1500 Characters
                        </span>
                      ) : null}{" "}
                      </div>
                      </div>
                      <span className="errorMsg">
                        {errors.SOCategoryDesp}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>

          <Modal.Footer>
            <div className="modal-btn">
              <button
                onClick={modalClose}
                className="btn btn-default brder-2 btn-sm mr-2"
              >
                {i18n.t('Cancel')}
              </button>

              <input
                type="Button"
                className="btn btn-primary btn-sm"
                onClick={() => this.handleSave()}
                value={isScreenInEditMode ? i18n.t('Update') : i18n.t('Add')}
              />
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  woTileData: state.WOtileMasterReducers.tile,
  woConfData: state.WOtileReducers.tile,
});

export default connect(mapStateToProps)(SubStepPopUp);
