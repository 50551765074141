import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import MindMapListDropdown from './MindMapListDropdown';
import { store } from '../reducers/configureStore';
import ellipseIcon from '../Images/ellipsis-v.svg';
import ellipseSelectedIcon from '../Images/ellipsis-v-selected-icon.svg';
import EditSelected from '../Images/editSelected-icon.svg';
import DeleteSelected from '../Images/deleteSelected-icon.svg';
import Delete from '../Images/delete-comment.svg';
import Edit from '../Images/edit-comment.svg';
import { InitialiseState } from '../actions/mindMapAction';

class MindMapList extends Component {
  constructor() {
    super();
    this.state = {
      // active: null,      // unused state var
      // editMode: false,     // unused state var
      // addEnable: true,     // unused state var
    };
  }

  editSelected = (index, sequence) => {
    const { MindMapData } = this.props;
    const tempItem = _.cloneDeep(MindMapData);
    tempItem.forEach((item, ind) => {
      tempItem[ind].active = false;
      tempItem[ind].listData.forEach((a, i) => { tempItem[ind].listData[i].temp = false; });
    });
    tempItem[sequence].listData[index].temp = true;

    store.dispatch(InitialiseState(tempItem));
    this.setState({
      // active: false,                       // unused state var
    });
  }

  deleteSelected = (index, sequence) => {
    const { MindMapData } = this.props;
    const tempItem = _.cloneDeep(MindMapData);
    const item = tempItem[sequence].listData.splice(index, 1);
    const indexOfAdded = tempItem[sequence].addedData.findIndex((a) => a.metricID === item[0].metricID);
    if (indexOfAdded === -1) {
      const updatedIndex = tempItem[sequence].updatedData.findIndex((a) => a.ID === item[0].ID);
      if (updatedIndex > -1) {
        tempItem[sequence].updatedData.splice(updatedIndex, 1);
      }
      tempItem[sequence].deletedData.push({
        ID: item[0].ID,
        metricID: item[0].metricID,
      });
    } else {
      tempItem[sequence].addedData.splice(indexOfAdded, 1);
    }
    store.dispatch(InitialiseState(tempItem));
  }

  activeDropdown = () => {
    const { MindMapData, arr1 } = this.props;
    const tempItem = _.cloneDeep(MindMapData);
    if (arr1.listData.length <= 5) {
      tempItem.forEach((item, index) => {
        tempItem[index].active = false;
        item.listData.forEach((a, i) => { tempItem[index].listData[i].temp = false; });
        tempItem[index].addButtonEnable = true;
      });
      tempItem[arr1.sequence].active = true;
      tempItem[arr1.sequence].addButtonEnable = false;
      store.dispatch(InitialiseState(tempItem));
      this.setState({
        // active: true,    //unused state var
      });
    } else {
      tempItem[arr1.sequence].active = false;
      store.dispatch(InitialiseState(tempItem));
      this.setState({
        // active: false,     //unused state var
      });
    }
  }

  buttonClick = () => {
    const { MindMapData } = this.props;
    const tempItem = _.cloneDeep(MindMapData);
    tempItem.forEach((item, index) => {
      tempItem[index].active = false;
      tempItem[index].addButtonEnable = true;
      item.listData.forEach((a, i) => { tempItem[index].listData[i].temp = false; });
    });
    store.dispatch(InitialiseState(tempItem));
  }

  render() {
    const { arr1, configure, handleSave } = this.props;
    return (
      <>
        {
        (arr1.listData.length > 0 || arr1.active)
        && (
        <ul className="submenu-treelinks">
          {arr1.listData.slice(0, 5).map((item, index) => {
            let spanTag;

            if (configure) {
              if (item.metricName.length < 50) {
                spanTag = (
                  <span>
                    {item.metricName}
                  </span>
                );
              } else {
                spanTag = (
                  <span data-tip data-for={`metricName${item.metricName}`}>
                    {item.metricName.substring(0, 48)}
                    ...
                  </span>
                );
              }
            } else if (item.metricName.length < 45) {
              spanTag = (
                <span>
                  {item.metricName}
                </span>
              );
            } else {
              spanTag = (
                <span data-tip data-for={`metricName${item.metricName}`}>
                  {item.metricName.substring(0, 43)}
                  ...
                </span>
              );
            }
            return (
              <>
                {
                item.temp ? (
                  <li>
                    <MindMapListDropdown
                      dropdownData={arr1}
                      listIndex={index}
                      metricName={item.metricName}
                      Sequence={arr1.sequence}
                      handleSave={handleSave}
                    />
                  </li>
                ) : (
                  <li>
                    <div className="mindmaplevel-dropdownvalueadd">
                      <div className={classNames(
                        'mindmaplevel-dropdownvalue',
                        { 'ragstatus-amber': _.toLower(item.ragStatus) === 'amber' },
                        { 'ragstatus-green': _.toLower(item.ragStatus) === 'green' },
                        { 'ragstatus-red': _.toLower(item.ragStatus) === 'red' },
                        { 'ragstatus-grey': _.toLower(item.ragStatus) === 'grey' || _.toLower(item.ragStatus) === 'gray' },
                      )}
                      >
                        {
                          spanTag
                          // configure ?
                          // item.metricName.length < 50 ?
                          // <span> { item.metricName } </span> :
                          // <span data-tip data-for={`metricName${item.metricName}`}> {
                          //   item.metricName.substring(0,48)
                          //   }...</span> : item.metricName.length < 45 ?
                          //   <span> {item.metricName} </span> :
                          //   <span data-tip data-for={`metricName${item.metricName}`}>{ item.metricName.substring(0,43)
                          //   }...</span>
                        }
                        {
                      !configure
                      && (
                      <DropdownButton
                        id="edit-delete-dropdown"
                        className="edit-delete-dropdown d-flex float-right"
                        drop="end"
                        title={(
                          <span onClick={this.buttonClick} role="button" tabIndex="0">
                            <img className="default-ellipsis" src={ellipseIcon} alt="" />
                            {' '}
                            <img className="ellpsis-selected" src={ellipseSelectedIcon} alt="" />
                          </span>
)}
                      >
                        <Dropdown.Item
                          key={item.metricID}
                          value={item.metricName}
                          onClick={() => this.editSelected(index, arr1.sequence)}
                        >
                          <img className="edit-comment-selected" src={EditSelected} alt="Edit" />
                          <img className="edit-comment" src={Edit} alt="Edit" />
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          key={item.metricID}
                          value={item.metricName}
                          onClick={() => { this.deleteSelected(index, arr1.sequence); }}
                        >
                          <img className="delete-comment-selected" src={DeleteSelected} alt="Delete" />
                          <img className="delete-comment" src={Delete} alt="Delete" />
                          Delete
                        </Dropdown.Item>
                      </DropdownButton>
                      )
                    }
                        {
                      configure
                        ? item.metricName.length >= 50 && (
                        <ReactTooltip
                          place="bottom"
                          type="dark"
                          effect="solid"
                          id={`metricName${item.metricName}`}
                          className="metric-name"
                        >
                          {item.metricName}
                        </ReactTooltip>
                        )
                        : item.metricName.length >= 45 && (
                        <ReactTooltip
                          place="bottom"
                          type="dark"
                          effect="solid"
                          id={`metricName${item.metricName}`}
                          className="metric-name"
                        >
                          {item.metricName}
                        </ReactTooltip>
                        )
}
                      </div>
                      {
                    !configure
                    && arr1.addButtonEnable && arr1.listData.length - 1 === index && arr1.listData.length <= 4 && arr1.listData.length < arr1.dropdownData.length
                      ? (
                        <div className="addmindmap-links">
                          <span><i className="fas fa-plus-circle" onClick={this.activeDropdown} aria-label="Add link" role="button" tabIndex="0" /></span>
                        </div>
                      )
                      : <></>
                  }
                    </div>
                  </li>
                )
              }

              </>
            );
          })}
          {
            arr1.active
              ? (
                <li>
                  <MindMapListDropdown
                    dropdownData={arr1}
                    listIndex="New"
                    metricName="Select"
                    Sequence={arr1.sequence}
                    handleSave={handleSave}
                  />
                </li>
              ) : <></>
          }
        </ul>
        )
      }
      </>

    );
  }
}

const mapStateToProps = (state) => ({
  MindMapData: state.mindMapReducer.InitialData,
});

const conntedFunc = connect(
  mapStateToProps,
);
MindMapList.propTypes = {
  MindMapData: PropTypes.object.isRequired,
  arr1: PropTypes.array.isRequired,
  configure: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
};
export default compose(
  withRouter,
  conntedFunc,
)(MindMapList);
