import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import i18n from "i18next";
import axiosInstance from "../Shared/interceptor";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { FormatDateWithoutUTC } from "../Shared/commonFunctions";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";
import SynopsLoader from "../Shared/SynopsLoader"

class MORCommentsHistory extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      SOCategories: [],
      SOCategoriy: "",
      metricDetails: [],
      KPIMappingID: "",
      comments: "All",
      apiCallStatus: false,
      filteredCommentsData: [],
      commentsData: [],
      SOCategoriyRow: null,
      selectedMetricRow: null,
      sortby: false,
    };
  }

  onCommentsChange = (event) => {
    let selectedValue = event.target.options[event.target.selectedIndex].value;
    let filteredData = [...this.props.morCommentsData];
    let UpdatedData = [];
    if (selectedValue === "Edited") {
      filteredData.map((each) => {
        if (each.IsEdited === true) {
          UpdatedData.push(each);
        }
      });
    } else if (selectedValue === "Deleted") {
      filteredData.map((each) => {
        if (each.IsDeleted === true) {
          UpdatedData.push(each);
        }
      });
    } else {
      UpdatedData = filteredData;
    }
    this.setState({ comments: selectedValue, filteredCommentsData: UpdatedData });
  };

  sortChangeEvent = () => {
    let { sortby, filteredCommentsData } = this.state;
    this.setState({ sortby: !sortby, filteredCommentsData: this.sortBy(filteredCommentsData, !sortby) });
  };

  sortBy = (commentsData, sortby) => {
    debugger;
    let filteredCommentsData = [...commentsData];
    if (!sortby) {
      filteredCommentsData.sort(function(a, b) {
        return new Date(a.UpdatedTS) > new Date(b.UpdatedTS)
          ? 1
          : new Date(b.UpdatedTS) > new Date(a.UpdatedTS)
          ? -1
          : 0;
      });
    } else {
      filteredCommentsData.sort(function(a, b) {
        return new Date(a.UpdatedTS) < new Date(b.UpdatedTS)
          ? 1
          : new Date(b.UpdatedTS) < new Date(a.UpdatedTS)
          ? -1
          : 0;
      });
    }
    return filteredCommentsData;
  };
  componentDidMount() {
    const { morCommentsData } = this.props;

    this.setState({
      filteredCommentsData: this.sortBy(morCommentsData, this.state.sortby),
    });
  }

  render() {
    const { Offering, SubofferingName,selectedMonth } = this.props.filterProps;
    let SOCategoriesList = [];
    this.state.SOCategories.map((each) => {
      SOCategoriesList.push({ label: each.SOCategoryName, value: each.SOCategoryID });
    });
    let metricDetailsList = [];
    this.state.metricDetails.map((each) => {
      metricDetailsList.push({ label: each.MetricName, value: each.KPIMappingID });
    });

    return (
      <>
      <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active={this.state.apiCallStatus} >
      </LoadingOverlay>
        <div className="commentry morCommentry">
          <div className="row">
            <div className="col-sm-12">
              <button
                className="backButton inline"
                name="back"
                value="back"
                onClick={() => {
                  this.props.morcommentHistoryClose();
                }}
              >
                <span className="p-r-10">
                  <i className="fas fa-chevron-left"></i>
                </span>
                {i18n.t("Back")}
              </button>
              <h1 className="history-header inline p-l-20">{i18n.t("View_Comments_History")}</h1>
            </div>
          </div>

          <div className="row p-t-20">
            <div className="col-sm-10">
            <ul className="selectdropdown-list">
               {Offering!=="Select Offering"?
                <li className="mor-selectedclient mr-3">
                  <span className="Active">{Offering === "All"?"All Offerings":Offering ===null?"Blank": Offering}</span>
                </li>
                  :""}
                  {SubofferingName!=="Select Sub Offering" ?
                <li className="mor-selectedclient mr-3">
                  <span className="Active">{SubofferingName === "All"?"All Sub Offerings":SubofferingName === null?"Blank":SubofferingName}</span>
                </li>
                  :""}
                <li className="mor-selectedclient mr-3">
                  <span className="Active">{selectedMonth}</span>
                </li>
                <li>

                  <div className="select-custom">
                    <select
                      className="dropdown"
                      name="dropDownOne"
                      value={this.state.comments}
                      onChange={this.onCommentsChange}
                    >
                      <option value={"All"}>{i18n.t("All_Comments")}</option>
                      <option value={"Edited"}>{i18n.t("Edited")}</option>
                      <option value={"Deleted"}>{i18n.t("Deleted")}</option>
                    </select>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-sm-2">
              <p
                onClick={this.sortChangeEvent}
                className={`float-right sortby m-t-15 p-r-20 ${
                  this.state.sortby ? "sortby-icon-up" : "sortby-icon-down"
                }`}
              >
                {i18n.t("Sort_By")}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              {this.state.filteredCommentsData.map((each) => {
                let date = new Date(each.UpdatedTS);
                return (
                  <div className="user-specific-comment">
                    <div className="user-info">
                      <div className="user-profile">
                        <span>
                          <FontAwesomeIcon icon={faUser} />
                        </span>
                      </div>
                      <div className="profile-info">
                        <h6>{each.CreatedBy}</h6>
                        <span className="time-info">
                          <ul>
                            {each.IsDeleted ? (
                              <li className={"red"}>{i18n.t("Deleted") + " "}</li>
                            ) : each.IsEdited ? (
                              <li className={"edited-label"}>{i18n.t("Edited") + " "}</li>
                            ) : (
                              ""
                            )}
                            <li>{FormatDateWithoutUTC(each.UpdatedTS)} </li>
                          </ul>
                        </span>
                      </div>
                    </div>
                    <div className={each.IsDeleted ? "user-comment deleted" : "user-comment"}>
                      <p>{each.CommentText}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        </>
    );
  }
}

export default MORCommentsHistory;
