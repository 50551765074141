import _ from 'lodash';
import * as ActionTypes from '../actions/BeforAfterMindMapActions';

const initialState = {
  viewData: {},
  configrationData: { MindMapData: {}, MindMapJourney: [] },
  postData: { MindMapData: [], MindMapJourney: [] },
  isConfiguratorMode: false,
  BeforeAfterUsers: [],
  CommentsData: [],
  CommentsImagesData: {},
  isFormOpen: [
    {
      name: 'QA',
      state: false,
      FormData: [],
    },
    {
      name: "IO",
      state: false,
      FormData: [],
    },
    {
      name: "NPS",
      state: false,
      FormData: [],
    },

    {
      name: "COMP",
      state: false,
      FormData: [],
    },
    {
      name: 'BO',
      state: false,
      FormData: [],
    },
    {
      name: 'DSP',
      state: false,
      FormData: [],
    },
    {
      name: 'WC',
      state: false,
      FormData: [],
    },
    {
      name: 'HnM',
      state: false,
      FormData: [],
    },
  ],
};

const BeforeAfterMindMapReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_BEFORE_AND_AFTER_MINDMAP_VIEW_DETAILS:
      return {
        ...state,
        viewData: action.payload,
      };
    case ActionTypes.SET_BEFORE_AND_AFTER_MINDMAP_CONFIG_DETAILS:
      return {
        ...state,
        configrationData: { MindMapData: action.payload, MindMapJourney: [] },
        postData: { MindMapData: [], MindMapJourney: [] },
      };
    case ActionTypes.SAVE_BEFORE_AND_AFTER_MINDMAP_USER_DETAILS:
      return {
        ...state,
        BeforeAfterUsers: action.payload,
      };
    case ActionTypes.SET_BEFORE_AND_AFTER_MINDMAP_CONFIGURATION_STATUS: {
      const clearState = {};
      if (!action.payload) {
        clearState.configrationData = { MindMapData: {}, MindMapJourney: [] };
        clearState.postData = { MindMapData: [], MindMapJourney: [] };
      }
      return {
        ...state,
        ...clearState,
        isConfiguratorMode: action.payload,
      };
    }
    case ActionTypes.SAVE_UPDATED_BEFORE_AND_AFTER_MINDMAP_STEP_CONFIGURATION_DATA: {
      const tempPostData = _.cloneDeep(state.postData);
      const tempConfigData = _.cloneDeep(state.configrationData);
      tempConfigData.MindMapData[action.payload.name] = { ...action.payload.currentSectionData };
      if (
        tempPostData.MindMapData.filter((eachSec) => eachSec.SectionName === action.payload.name)
          .length
      ) {
        tempPostData.MindMapData = tempPostData.MindMapData.map((eachItem) => {
          let tempStepData = { ...eachItem };
          if (eachItem.SectionName === action.payload.name) {
            tempStepData = { ...action.payload.currentSectionData };
          }
          return tempStepData;
        });
      } else {
        tempPostData.MindMapData.push({ ...action.payload.currentSectionData });
      }
      return {
        ...state,
        postData: { ...tempPostData },
        configrationData: { ...tempConfigData },
      };
    }
    case ActionTypes.DELETE_BEFORE_AND_AFTER_MINDMAP_STEP_CONFIGURATION_DATA: {
      const tempPostData = _.cloneDeep(state.postData);
      const tempConfigData = _.cloneDeep(state.configrationData);
      const { payload } = action;

      tempConfigData.MindMapData[action.payload.name] = { ...payload.currentSectionData };
      if (
        tempPostData.MindMapData.filter((eachSec) => eachSec.SectionName === payload.name)
          .length
      ) {
        tempPostData.MindMapData = tempPostData.MindMapData.filter((eachItem) => {
          let tempStepData = { ...eachItem };
          if (eachItem.SectionName === payload.name) {
            if (payload.isExist) {
              tempStepData = { ...payload.currentSectionData, isDeleted: true };
              return tempStepData;
            }
          } else {
            return tempStepData;
          }
        });
      } else if (payload.currentSectionData.isExist) {
        tempPostData.MindMapData.push({ ...payload.currentSectionData, isDeleted: true });
      }

      // REMOVING MINDMAP JOURNEY DATA
      tempConfigData.MindMapJourney = tempConfigData.MindMapJourney.filter((node) => {
        parseInt(node.SectionId) !== parseInt(payload.currentSectionData.SectionId);
      });
      tempPostData.MindMapJourney = tempPostData.MindMapJourney.filter((node) => {
        parseInt(node.SectionId) !== parseInt(payload.currentSectionData.SectionId);
      });
      return {
        ...state,
        postData: { ...tempPostData },
        configrationData: { ...tempConfigData },
      };
    }
    case ActionTypes.DELETE_BEFORE_AND_AFTER_MINDMAP_METRIC_CONFIGURATION_DATA: {
      const tempMetricPostData = { ...state.postData };
      const tempMetricConfigData = _.cloneDeep(state.configrationData);
      const tempConfigStepData = _.cloneDeep(
        state.configrationData.MindMapData[action.payload.name],
      );
      const payLoadMetric = action.payload.MetricData;
      payLoadMetric.isDeleted = true;
      const isMetricExist = !!tempConfigStepData.Metrics.filter(
        (eachMetric) => (payLoadMetric.Id && eachMetric.Id === payLoadMetric.Id)
          || (!payLoadMetric.Id && eachMetric.MetricName === payLoadMetric.MetricName),
      ).length;
      if (isMetricExist) {
        tempConfigStepData.Metrics = tempConfigStepData.Metrics.filter((eachMetric) => {
          if (
            (payLoadMetric.Id && eachMetric.Id !== payLoadMetric.Id)
            || (!payLoadMetric.Id && eachMetric.MetricName !== payLoadMetric.MetricName)
          ) {
            return eachMetric;
          }
        });
      }
      tempMetricConfigData.MindMapData[action.payload.name] = tempConfigStepData;
      if (
        tempMetricPostData.MindMapData.filter(
          (eachSec) => eachSec.SectionName === action.payload.name,
        ).length
      ) {
        tempMetricPostData.MindMapData.map((eachSec) => {
          const tempSec = { ...eachSec };
          if (eachSec.SectionName === action.payload.name) {
            const isPostMetricExist = !!tempSec.Metrics.filter(
              (eachMetric) => (payLoadMetric.Id && eachMetric.Id === payLoadMetric.Id)
                || (!payLoadMetric.Id && eachMetric.MetricName === payLoadMetric.MetricName),
            ).length;
            if (isPostMetricExist) {
              const TempMetrics = [];
              tempSec.Metrics.forEach((eachMetric) => {
                if (payLoadMetric.Id && eachMetric.Id === payLoadMetric.Id) {
                  TempMetrics.push(payLoadMetric);
                } else if (
                  (!payLoadMetric.Id && eachMetric.MetricName !== payLoadMetric.MetricName)
                  || (payLoadMetric.Id && eachMetric.Id === payLoadMetric.Id)
                ) {
                  TempMetrics.push(eachMetric);
                }
              });
              tempSec.Metrics = TempMetrics;
            } else {
              tempSec.Metrics.push(payLoadMetric);
            }
          }
          return tempSec;
        });
      } else {
        const clonedStepData = { ...tempConfigStepData };
        clonedStepData.Metrics = [payLoadMetric];
        tempMetricPostData.MindMapData.push(clonedStepData);
      }
      return {
        ...state,
        postData: _.cloneDeep(tempMetricPostData),
        configrationData: _.cloneDeep(tempMetricConfigData),
      };
    }
    case ActionTypes.SAVE_UPDATED_BEFORE_AND_AFTER_MINDMAP_METRIC_CONFIGURATION_DATA: {
      const tempMetricPostData = { ...state.postData };
      const tempMetricConfigData = _.cloneDeep(state.configrationData);
      const tempConfigStepData = _.cloneDeep(
        state.configrationData.MindMapData[action.payload.name],
      );
      const payLoadMetric = action.payload.MetricData;
      const isMetricExist = !!tempConfigStepData.Metrics.filter(
        (eachMetric) => (payLoadMetric.Id && eachMetric.Id === payLoadMetric.Id)
          || (!payLoadMetric.Id && eachMetric.MetricName === payLoadMetric.MetricName)
          || (!payLoadMetric.Id && eachMetric.KPIMappingID && eachMetric.KPIMappingID === payLoadMetric.KPIMappingID),
      ).length;
      if (isMetricExist) {
        tempConfigStepData.Metrics = tempConfigStepData.Metrics.map((eachMetric) => {
          let tempMetric = { ...eachMetric };
          if (
            (payLoadMetric.Id && eachMetric.Id === payLoadMetric.Id)
            || (!payLoadMetric.Id && eachMetric.MetricName === payLoadMetric.MetricName)
            || (!payLoadMetric.Id && eachMetric.KPIMappingID && eachMetric.KPIMappingID === payLoadMetric.KPIMappingID)
          ) {
            payLoadMetric.Id = tempMetric.Id;
            tempMetric = _.cloneDeep(payLoadMetric);
          }
          return tempMetric;
        });
      } else {
        tempConfigStepData.Metrics.push(payLoadMetric);
      }
      tempMetricConfigData.MindMapData[action.payload.name] = tempConfigStepData;
      if (
        tempMetricPostData.MindMapData.filter(
          (eachSec) => eachSec.SectionName === action.payload.name,
        ).length
      ) {
        tempMetricPostData.MindMapData.filter((eachSec) => {
          const tempSec = { ...eachSec };
          if (eachSec.SectionName === action.payload.name) {
            const isPostMetricExist = !!tempSec.Metrics.filter(
              (eachMetric) => (payLoadMetric.Id && eachMetric.Id === payLoadMetric.Id)
                || (!payLoadMetric.Id && eachMetric.MetricName === payLoadMetric.MetricName)
                || (!payLoadMetric.Id && eachMetric.KPIMappingID && eachMetric.KPIMappingID === payLoadMetric.KPIMappingID),
            ).length;
            if (isPostMetricExist) {
              tempSec.Metrics.map((eachMetric) => {
                let tempMetric = { ...eachMetric };
                if (
                  (payLoadMetric.Id && eachMetric.Id === payLoadMetric.Id)
                  || (!payLoadMetric.Id && eachMetric.MetricName === payLoadMetric.MetricName)
                  || (!payLoadMetric.Id && eachMetric.KPIMappingID && eachMetric.KPIMappingID === payLoadMetric.KPIMappingID)
                ) {
                  payLoadMetric.Id = tempMetric.Id;
                  tempMetric = payLoadMetric;
                }
                return tempMetric;
              });
            } else {
              tempSec.Metrics.push(payLoadMetric);
            }
          }
          return tempSec;
        });
      } else {
        const clonedStepData = { ...tempConfigStepData };
        clonedStepData.Metrics = [payLoadMetric];
        tempMetricPostData.MindMapData.push(clonedStepData);
      }
      return {
        ...state,
        postData: _.cloneDeep(tempMetricPostData),
        configrationData: _.cloneDeep(tempMetricConfigData),
      };
    }
    case ActionTypes.SET_FORM_STATE: {
      const index = initialState.isFormOpen.findIndex((a) => a.name === action.payload.name);
      const tempArray = [...initialState.isFormOpen];
      if (tempArray[index] !== undefined) {
        tempArray.forEach((a) => (a.state = false));
        tempArray[index].state = action.payload.data;
      }
      return {
        ...state,
        isFormOpen: tempArray,
      };
    }

    case ActionTypes.SET_MINDMAP_FORM_DATA: {
      const tempIndex = initialState.isFormOpen.findIndex((a) => a.name === action.payload.name);
      const newArray = [...initialState.isFormOpen];
      if (newArray[tempIndex] !== undefined) {
        newArray[tempIndex].FormData = action.payload.data;
      }
      return {
        ...state,
        isFormOpen: newArray,
      };
    }

    // DRILL DOWN PAGE START
    case ActionTypes.SET_BEFORE_AND_AFTER_DRILLDOWN_SECTION_DATA: {
      state = { ...state };
      state.configrationData.MindMapJourney.push(action.payload);
      state.postData.MindMapJourney = state.configrationData.MindMapJourney;
      return { ...state };
    }
    case ActionTypes.SET_BEFORE_AND_AFTER_DRILLDOWN_JOURNEY_DATA: {
      state = { ...state };
      const data = state.configrationData.MindMapJourney;
      state.configrationData.MindMapJourney = [...data].map((node) => {
        if (parseInt(node.SectionId) === parseInt(action.payload.SectionId)) {
          node.SectionJourney.push(action.payload.Data);
          return node;
        }
        return node;
      });
      state.postData.MindMapJourney = state.configrationData.MindMapJourney;
      return { ...state };
    }
    case ActionTypes.SET_BEFORE_AND_AFTER_DRILLDOWN_METRICS_JOURNEY_DATA: {
      state = { ...state };
      const data = state.configrationData.MindMapJourney;
      state.configrationData.MindMapJourney = [...data].map((node) => {
        if (parseInt(node.SectionId) === parseInt(action.payload.SectionId)) {
          node.MetricsData.push(action.payload.Data);
          return node;
        }
        return node;
      });
      state.postData.MindMapJourney = state.configrationData.MindMapJourney;
      return { ...state };
    }

    case ActionTypes.SET_BEFORE_AND_AFTER_DRILLDOWN_REASONFORGROWTH: {
      state = { ...state };
      const { payload } = action; // .SectionId;  //, FromMilestone, ToMilestone , ReasonforGrowth,Data,CustomMetricId,KpiMappingID,Journey
      const data = state.configrationData.MindMapJourney;
      state.configrationData.MindMapJourney = [...data].map((node) => {
        if (parseInt(node.SectionId) === parseInt(payload.SectionId)) {
          // IN CASE OF METRIC
          if (payload.Journey === 'MetricsData') {
            node.MetricsData = node.MetricsData.map((met) => {
              if (
                met.KpiMappingID === payload.KpiMappingID
                && met.CustomMetricId === payload.CustomMetricId
              ) {
                met.MetricJourney = met.MetricJourney.map((jour) => {
                  if (
                    jour.FromMilestone === payload.FromMilestone
                    && jour.ToMilestone === jour.ToMilestone
                  ) {
                    jour[payload.ReasonforGrowth] = payload.Data;
                  }
                  return jour;
                });
              }
              return met;
            });
          } else {
            node.SectionJourney = node.SectionJourney.map((jour) => {
              if (
                jour.FromMilestone === payload.FromMilestone
                && jour.ToMilestone === payload.ToMilestone
              ) {
                jour[payload.ReasonforGrowth] = payload.Data;
                return jour;
              }
              return jour;
            });
          }

          return node;
        }
        return node;
      });
      state.postData.MindMapJourney = state.configrationData.MindMapJourney;
      return { ...state };
    }
    // ADDING TIME SERIES DATA TO THE SELECTED METRIC FOR THE GIVEN FROM AND TO
    case ActionTypes.SET_BEFORE_AND_AFTER_DRILLDOWN_METRICS_SERIES_JOURNEY_DATA: {
      state = { ...state };
      const { payload } = action;
      const {
        SectionId, CustomMetricId, KpiMappingID, Data,
      } = payload;
      const data = state.configrationData.MindMapJourney;
      state.configrationData.MindMapJourney = [...data].map((node) => {
        if (parseInt(node.SectionId) === parseInt(SectionId)) {
          if (
            node.MetricsData.filter(
              (a) => parseInt(a.KpiMappingID) === parseInt(KpiMappingID)
                && parseInt(a.CustomMetricId) === parseInt(CustomMetricId),
            ).length > 0
          ) {
            node.MetricsData = [...node.MetricsData].map((a) => {
              if (
                parseInt(a.KpiMappingID) === parseInt(KpiMappingID)
                && parseInt(a.CustomMetricId) === parseInt(CustomMetricId)
              ) {
                a.MetricJourney.push(Data);
              }
              return a;
            });
          } else return node;
        }
        return node;
      });
      state.postData.MindMapJourney = state.configrationData.MindMapJourney;

      return { ...state };
    }
    case ActionTypes.REMOVE_BEFORE_AND_AFTER_DRILLDOWN_SECTION_DATA: {
      state = { ...state };
      const { payload } = action; // SectionId,DeleteIndex
      const { SectionId } = payload;
      const data = state.configrationData.MindMapJourney;
      state.configrationData.MindMapJourney = [...data].filter(
        (node) => !(parseInt(node.SectionId) === parseInt(SectionId)),
      );
      state.postData.MindMapJourney = state.configrationData.MindMapJourney;

      return { ...state };
    }
    case ActionTypes.REMOVE_BEFORE_AND_AFTER_DRILLDOWN_JOURNEY_DATA_TIMESERIES: {
      state = { ...state };
      const { payload } = action; // SectionId,DeleteIndex
      const data = state.configrationData.MindMapJourney;
      state.configrationData.MindMapJourney = [...data].map((node) => {
        if (parseInt(node.SectionId) === parseInt(action.payload.SectionId)) {
          // REMOVING ALL THE TIMESERIES WITH GIVEN INDEX AND CHANING THE INDEX
          // 0 1 2
          // IF 0 IS DELETED -> THEN 0 IS REMOVED AND INDEX CHANGE 1->0 AND 2->1
          let sectionJourney = [...node.SectionJourney];
          sectionJourney = sectionJourney.filter(
            (a) => !(
              a.FromMilestone.includes(`Timeseries${action.payload.DeleteIndex}`)
                || a.ToMilestone.includes(`Timeseries${action.payload.DeleteIndex}`)
            ),
          );
          sectionJourney = sectionJourney.map((a) => {
            if (a.FromMilestone.includes('Timeseries')) {
              const currentIndex = parseInt(a.FromMilestone.split('Timeseries')[1]);
              if (currentIndex > action.payload.DeleteIndex) {
                a.FromMilestone = `Timeseries${(currentIndex - 1).toString()}`;
                a.FromTimeseriesID = currentIndex - 1;
              }

              return a;
            }
            if (a.ToMilestone.includes('Timeseries')) {
              const currentIndex = parseInt(a.ToMilestone.split('Timeseries')[1]);
              if (currentIndex > action.payload.DeleteIndex) {
                a.ToMilestone = `Timeseries${(currentIndex - 1).toString()}`;
                a.ToTimeseriesID = currentIndex - 1;
              }
              return a;
            }
            return a;
          });
          node.SectionJourney = sectionJourney;
          return node;
        }
        return node;
      });
      state.postData.MindMapJourney = state.configrationData.MindMapJourney;

      return { ...state };
    }

    // NOT COMPLETED
    case ActionTypes.REMOVE_BEFORE_AND_AFTER_DRILLDOWN_JOURNEY_DATA_METRIC_TIMESERIES: {
      state = { ...state };
      // SectionId,DeleteIndex,MetricID,KPIMappingID,MetricName
      const {
        SectionId, MetricID, KPIMappingID, MetricName,
      } = action.payload;

      const data = state.configrationData.MindMapJourney;
      state.configrationData.MindMapJourney = [...data].map((node) => {
        if (parseInt(node.SectionId) === parseInt(SectionId)) {
          const { MetricsData } = node;
          node.MetricsData = [...MetricsData].map((metr) => {
            if (parseInt(metr.KpiMappingID) === parseInt(KPIMappingID) && metr.CustomMetricId === MetricID && metr.MetricName.toLowerCase() === MetricName.toLowerCase()) {
              const metricJourney = metr.MetricJourney;
              metr.MetricJourney = [...metricJourney].filter((jour) => !(
                jour.FromMilestone.includes(`Timeseries${action.payload.DeleteIndex}`)
                || jour.ToMilestone.includes(`Timeseries${action.payload.DeleteIndex}`)
              ));
            }
            return metr;
          });
        }
        return node;
      });
      state.postData.MindMapJourney = state.configrationData.MindMapJourney;
      return { ...state };
    }

    case ActionTypes.SET_BEFORE_AND_AFTER_DRILLDOWN_MINDMAP_JOURNEY: {
      state = { ...state };
      const { payload } = action;
      state.configrationData.MindMapJourney = [...payload];
      state.postData.MindMapJourney = state.configrationData.MindMapJourney;
      return { ...state };
    }

    // REMOVING THE JOURNEY DATA ASSOCIATED WITH DELETED METRIC
    case ActionTypes.REMOVE_BEFORE_AND_AFTER_DRILLDOWN_JOURNEY_DATA_METRIC: {
      state = { ...state };
      const { payload } = action;
      const {
        SectionId, MetricID, KPIMappingID, MetricName,
      } = payload;

      const data = state.configrationData.MindMapJourney;
      state.configrationData.MindMapJourney = [...data].map((node) => {
        if (parseInt(node.SectionId) === parseInt(SectionId)) {
          const metricsData = node.MetricsData;
          node.MetricsData = [...metricsData].filter(
            (metric) => !(
              parseInt(metric.KpiMappingID) === parseInt(KPIMappingID)
                && parseInt(metric.CustomMetricId) === parseInt(MetricID)
                && metric.MetricName.toLowerCase() === MetricName.toLowerCase()
            ),
          );
        }
        return node;
      });
      state.postData.MindMapJourney = state.configrationData.MindMapJourney;
    }
    case ActionTypes.SAVE_BEFORE_AND_AFTER_MINDMAP_COMMENTS_USER_IMAGES: {
      const { payload } = action;
      return { ...state, CommentsImagesData: { ...state.CommentsImagesData, ...payload } };
    }
    case ActionTypes.SAVE_BEFORE_AND_AFTER_MINDMAP_COMMENTS_DATA: {
      const { payload } = action;
      return { ...state, CommentsData: [...payload] };
    }
    // DRILL DOWN PAGE END
    default:
      return state;
  }
};

export default BeforeAfterMindMapReducer;
