import React, { Component } from "react";
import { LocalApiBaseUrl, getMonthNumber, getPrevMonth } from "../Shared/Constant";
import { AgGridReact, Ag } from "ag-grid-react";
import OneTimeSetupApprovalPopup from "./OneTimeSetupApprovalPopup"
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";

import OneTimeSetupViewPopup from "./OneTimeSetupViewPopup";

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;



export default class OneTimeSetupApprovalList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      SelectAll: null,
      Approval: {
        ID: [],
        ApprovalType: null,
        ApprovalAction: false,
      },

      CanApprove: false,
      CanReject: false,
      action: "",
      rowSelected: [],
      userList: [],
      roleList: [],
      clientList: [],
      viewmore: false,
      viewpopupStatus: false,
      viewData: {},
      prevLength: "",
      flag: false,
      columnDefs: [
        {
          headerName: "",
          resizable: true,
          suppressFilter: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
          checkboxSelection: (params) => params.data.Status == "Pending Approval",
          suppressMovable: true,
          suppressSizeToFit: true,
          width: 100,
        },
        {
          headerName: "View",
          suppressMovable: true,
          width: 80,
          cellRenderer: (params) => {
            var link = document.createElement("span");
            link.className = "tblview";
            link.innerHTML = '<i class="fas fa-eye"></i>';
            link.id = "viewOneTimeSetUp";
            link.addEventListener('click', (e) => {
              this.viewpopup(e, params.data);
            });
            return link;
          },
          suppressFilter: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Source System",
          field: "ConnectorName",
          colId: "SourceSystem",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Client",
          colId: "offering",
          field: "DestinationClientName",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Offering",
          colId: "suboffering",
          field: "DestinationOfferingName",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Sub-Offering",
          colId: "sub-offering",
          field: "DestinationSubOfferingName",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Location",
          colId: "sub-offering",
          field: "DestinationLocationName",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Client Region/Market Unit",
          colId: "clientRegion",
          field: "ClientRegion",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },

        {
          headerName: " Requested By",
          colId: "requestedby",
          field: "RequestedBy",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: "Status",
          colId: "status",
          field: "Status",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: " Approver Remarks",
          colId: "approverremarks",
          field: "Remarks",
          resizable: true,
          suppressFilter: false,
          suppressMovable: true,
          suppressSizeToFit: true,
        },
      ],
      autoGroupColumnDef: {
        headerName: "hello",
        field: "DealName",
        width: 200,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },

      rowData: [],
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        rowSelection: "multiple",

        defaultColDef: {
          sortable: true,
        },
      },
    };
    this.onClose = this.onClose.bind(this);
    this.onRowSelected = this.onRowSelected.bind(this);
    this.dealpopup = this.dealpopup.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    this.decline = this.decline.bind(this);
    this.onBtnExport = this.onBtnExport.bind(this);
  }
  viewpopup = (e, data) => {
    if (data != undefined) {
      let viewData = data;

      this.setState({ viewpopupStatus: true, viewData, })
    }

  }

  handleViewPopupClose = () => {
    this.setState({
      viewpopupStatus: false
    })
  }

  onRowSelected(event) {

    if (event.data.Status == "Pending Approval") {
      if (event.node.selected) {
        let rowSelected = this.state.rowSelected;
        rowSelected.push(event.data);
        this.setState({ rowSelected: rowSelected });
      } else {
        let rowSelected = this.state.rowSelected;
        rowSelected = rowSelected.filter((row) => row.ID !== event.data.ID);
        this.setState({ rowSelected: rowSelected });
      }
    }
  }


  onFilterTextBoxChanged(e) {
    this.state.gridOptions.api.setQuickFilter(document.getElementById("filter-text-box").value);
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  dealpopup(e) {
    if (
      this.props.featureData.filter((data) => data.Submenu == "OneTimeSetup Approval" && data.Operations.includes("Approve")).length >
      0
    ) {
      e.preventDefault();
      this.setState({ viewmore: true });
      this.state.action = "Approve";
    } else window.alert("Access Denied !!");
  }

  onClose(e) {
    this.setState({ viewmore: false });
  }

  getApproval() {

    axiosInstance.get(`${LocalApiBaseUrl}ConnectorMapping/GetOneTimeSetupForApproval`, {

      params: {
      }
    })
      .then((response) => {
        this.setState({
          rowData: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

  }

  componentDidMount() {
    if (
      this.props.featureData.filter((data) => data.Submenu == "OneTimeSetup Approval" && data.Operations.includes("Reject")).length > 0
    ) {
      this.setState({ CanReject: true });
    }
    if (
      this.props.featureData.filter((data) => data.Submenu == "OneTimeSetup Approval" && data.Operations.includes("Approve")).length >
      0
    ) {
      this.setState({ CanApprove: true });
    }

    var a = this.props;
    this.getApproval();
    this.setState({
      rowSelected: [],
    });
  }

  componentWillMount() {
    this.setState({
      rowSelected: [],
    });
    if (this.props.featureData.filter((data) => data.Submenu == "OneTimeSetup Approval" && (data.Operations.includes("Reject") || data.Operations.includes("Approve"))).length == 0) {
      let data = this.state;
      data.columnDefs[0].checkboxSelection = false;
      this.setState({
        columnDefs: data.columnDefs,
      })
    }

    this.getApproval();
  }

  decline(e) {
    if (
      this.props.featureData.filter((data) => data.Submenu == "OneTimeSetup Approval" && data.Operations.includes("Reject")).length > 0
    ) {
      if (this.state.rowSelected.length > 1) {
        window.alert("User is advised to select one row at a time");
      } else {
        e.preventDefault();
        this.setState({ viewmore: true });
        this.state.action = "Reject";
      }
    } else window.alert("Access Denied !!");
  }
  handleSave(data) {
    axiosInstance.post(`${LocalApiBaseUrl}ConnectorMapping/PostOneTimeSetupApproval`, data).then((response) => {
      window.alert(response.data.StatusMessage);
      this.getApproval();
    })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
    this.state.rowSelected = [];
  }
  onBtnExport() {
    let params = {
      columnKeys: [
        "SOPageName",
        "ClientName",
        "Location",
        "offering",
        "suboffering",
        "categoryname",
        "metricname",
        "description",
        "unit",
        "requestedby",
        "requesteddate",
        "approvedate",
        "status",
        "requestorremarks",
        "approverremarks",
      ],
      columnGroups: true,
      allColumns: false,
      skipHeader: false,
      fileName: "Metric",
      //   columnSeparator: document.querySelector("#columnSeparator").value
    };
    this.state.gridOptions.api.exportDataAsCsv(params);
  }
  onGridReady = (params) => {
    this.gridApi = params.api;

    this.gridColumnApi = params.columnApi;
  };
  render() {
    return (
      <>
        <div className="main-data">
          {/* <h3 className="grid-heading">One Time Setup</h3> */}
          <div className="tableFilter">
            <div className="form-section">
              <div className="form-row">
                <div className="form-group col-lg-3">
                  <input
                    type="text"
                    className="form-control"
                    id="filter-text-box"
                    placeholder="Filter..."
                    onChange={(e) => {
                      this.onFilterTextBoxChanged(e);
                    }}
                  />
                </div>
                <div className="ml-auto mr-1">
                  {this.props.featureData.filter(
                    (data) => data.Submenu == "OneTimeSetup Approval" && data.Operations.includes("Download")
                  ).length > 0 ? (
                    // <span className="btn btn-primary btn-sm py-1" onClick={this.onBtnExport}>
                    //   <FontAwesomeIcon icon={faFileDownload} className="mr-1" /> Download
                    // </span>
                    <button class="setup-Btn">
                      <i class="fa fa-download"></i>Download
                    </button>
                  ) : null}
                  {/* <button className="btn btn-primary btn-sm" id="btnsave" onClick={this.onBtnExport} disabled={this.state.active}>Download</button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="ag-theme-balham ag-table">
                <AgGridReact
                  enableSorting={true}
                  enableFilter={true}
                  pagination={true}
                  paginationPageSize={50}
                  floatingFilter={true}
                  autoGroupColumnDef={this.state.autoGroupColumnDef}
                  onRowSelected={this.onRowSelected.bind(this)}
                  gridOptions={this.state.gridOptions}
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  onGridReady={this.onGridReady}
                  onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                ></AgGridReact>
              </div>
            </div>
          </div>
          {this.state.viewmore ? (
            <OneTimeSetupApprovalPopup
              action={this.state.action}
              data={this.state.rowSelected}
              show={this.state.viewmore}
              onClose={this.onClose}
              onSave={this.handleSave}
            />
          ) : null}

          <div className="text-center mt-3 mb-2">
            {this.state.rowSelected.length > 0 && this.state.CanApprove ? (
              <button
                className="btn btn-primary btn-sm mx-2"
                onClick={(e) => {
                  this.dealpopup(e);
                }}
              >
                Approve
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm mx-2"
                disabled={true}
                onClick={(e) => {
                  this.dealpopup(e);
                }}
              >
                Approve
              </button>
            )}
            {this.state.rowSelected.length > 0 && this.state.CanReject ? (
              <button
                className="btn btn-primary btn-sm mx-2"
                onClick={(e) => {
                  this.decline(e);
                }}
              >
                Decline
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm mx-2"
                disabled={true}
                onClick={(e) => {
                  this.decline(e);
                }}
              >
                Decline
              </button>
            )}
          </div>
        </div>
        {
          this.state.viewpopupStatus ?
            <OneTimeSetupViewPopup
              data={this.state.viewData}
              OfferingId={5}
              show={this.state.viewpopupStatus}
              close={this.handleViewPopupClose}
              dataoperation={true}
              filterDetails={this.props.filterDetails} /> : null
        }
      </>
    );
  }
}
