import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { Bar } from 'react-chartjs-2';
import LoadingOverlay from 'react-loading-overlay';
import { addMonths } from 'date-fns';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import Moment from 'moment';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import MultiSelect from 'react-multi-select-component';
import {
  trycatchAlertPopup,
  LocalApiBaseUrl,
} from '../Shared/Constant';
import axiosInstance from '../Shared/interceptor';

import SynopsLoader from '../Shared/SynopsLoader';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('msal.idtoken')}`;

function formatDate(momentObj) {
  const dateFormat = 'MMM YYYY';
  return momentObj.format(dateFormat);
}

const sortDataAsc = (data, property) => [...data].sort((a, b) => a[property].toLowerCase().localeCompare(b[property].toLowerCase(), undefined, {
  numeric: true,
  sensitivity: 'base',
}));
class MetricDeepDive extends Component {
  constructor(props) {
    super(props);
    const { CategoryInfo } = this.props;
    this.state = {
      showLoader: false,
      disableCategorydropdown: true,
      category: CategoryInfo.length > 0 ? CategoryInfo[0].CategoryType : '',
      CategoryDropdownData: [],
      modifiedPeriodGraphData: [],
      PeriodicGraphResponseData: [],
      PeriodicGraphAPIResponseRecd: false,
      isPeriodSelected: false,
      currentMonthFormattedStr: '',
      IsPeriodmoreThanSixMonths: false,
      currentMonthCategoriesData: {},
      period: { from: '', to: '' },
      selectedcategoryNames: [],
      categoryNames: [],

      // show: show,                     // unused state var
      // lengthOfPeriodicDataToBePlotted: 0,        // unused state var
      // categoryTypeSpecificData:[],               // unused state var
      // numCatNames: 0,                            // unused state var
      // labels: [],                                // unused state var
      // showCanvas: false,                         // unused state var
      // showErrorMsg: false,                       // unused state var
      // showCanvas: false,                         // unused state var
      // showErrorMsg: false,                       // unused state var
      // categoriesLimit: 30,                       // unused state var
    };

    this.datepickerDropdownPopoverTarget = React.createRef();

    this.startDatepickerRef = React.createRef();
    this.endDatepickerRef = React.createRef();
    this.handleHide = this.handleHide.bind(this);
    this.calculateDataPointsForGraph = this.calculateDataPointsForGraph.bind(
      this,
    );
    this.selectCatType = React.createRef();
  }

  componentDidMount() {
    const { period } = this.state;
    const { CategoryInfo } = this.props;
    if (!period.to && !period.from) {
      const dropdownData = [];
      if (CategoryInfo.length > 0) {
        dropdownData.push({
          CategoryType: CategoryInfo[0].CategoryType,
        });
      }

      this.setState({ CategoryDropdownData: dropdownData });
    }
    this.initiatlizeCategoryData();

    // this.calculateDataPointsForGraph();
  }

  handleHide() {
    const { onClose } = this.props;
    // this.setState({ show: false });
    onClose();
  }

  onPeriodChangeFrom = (val, e) => {
    const currentState = this.state;
    currentState.period.from = e;
    currentState.period.to = '';
    const currentMonthGraphData = _.cloneDeep(
      currentState.currentMonthCategoriesData,
    );

    _.set(currentMonthGraphData, 'labels', []);
    _.set(currentMonthGraphData, 'datasets', []);
    if (e) {
      this.setState({
        period: currentState.period,
        currentMonthCategoriesData: currentMonthGraphData,
        categoryNames: [],
        selectedcategoryNames: [],
        disableCategorydropdown: true,
      });
    }
  }

  onPeriodChangeTo = (val, e) => {
    const currentState = this.state;
    if (e) {
      this.setState({ isPeriodSelected: true, PeriodicGraphAPIResponseRecd: false });
      currentState.period.to = e;
      const d1 = currentState.period.from.getMonth(); // April 2020 // April 2019 //jan 2020
      const d2 = currentState.period.to.getMonth(); // May 2020 // May 2020 // june 2020
      const d3 = currentState.period.from.getFullYear()
        === currentState.period.to.getFullYear()
        ? 12 - d1 - (12 - d2) + 1
        : 12 - d1 + d2 + 1;

      if (currentState.period.from > currentState.period.to) {
        const currentMonthGraphData = _.cloneDeep(
          currentState.currentMonthCategoriesData,
        );

        _.set(currentMonthGraphData, 'labels', []);
        _.set(currentMonthGraphData, 'datasets', []);

        this.setState({
          currentMonthCategoriesData: currentMonthGraphData,
          CategoryDropdownData: [],
          disableCategorydropdown: true,
        });
      } else if (d3 > 1 && d3 <= 6) {
        // make API call here
        // var fromDateForAPPI = currentState.period

        const fromDateForAPPI = `${new Date(currentState.period.from).getFullYear()
        }-${new Date(currentState.period.from).getMonth() + 1 < 10
          ? `0${new Date(currentState.period.from).getMonth() + 1}`
          : new Date(currentState.period.from).getMonth() + 1}`;

        const toDateForAPPI = `${new Date(currentState.period.to).getFullYear()
        }-${new Date(currentState.period.to).getMonth() + 1 < 10
          ? `0${new Date(currentState.period.to).getMonth() + 1}`
          : new Date(currentState.period.to).getMonth() + 1}`;

        // clearing out the graph data displaed below to avoid wrong datasets being plotted issue
        this.setState({ showLoader: true, period: currentState.period, disableCategorydropdown: true });
        const { metricID, dealFilter } = this.props;
        axiosInstance
          .get(`${LocalApiBaseUrl}Analytics/GetMetricDeepDiveData`, {
            params: {
              metricID,
              OrgEntityID: parseInt(dealFilter.Client, 10),
              LocationID: parseInt(dealFilter.Locations, 10),
              OfferingID: parseInt(dealFilter.process, 10),
              startDate: fromDateForAPPI,
              endDate: toDateForAPPI,
              kpiMappingId:this.props.kpiMappingId
            },
          })
          .then((res) => {
            let responseData = res.data;
            // SORTING THE DATA BY CategoryName
            responseData = responseData.map((each, index) => {
              responseData[index].multilevelData = sortDataAsc(each.multilevelData, 'CategoryName');
              return each;
            });

            this.setState({
              PeriodicGraphResponseData: responseData,
              PeriodicGraphAPIResponseRecd: true,
              showLoader: false,
            }, () => {
              this.handleReinitaitalzeCategories();
            });
          })
          .catch((error) => {
            const { currentMonthCategoriesData } = this.state;
            const currentMonthGraphData = _.cloneDeep(currentMonthCategoriesData);
            //   currentMonthGraphData.labels= [];
            // currentMonthGraphData.datasets= [];
            _.set(currentMonthGraphData, 'labels', []);
            _.set(currentMonthGraphData, 'datasets', []);

            this.setState({
              currentMonthCategoriesData: currentMonthGraphData,
              showLoader: false,
            });
            trycatchAlertPopup(error);
          });
      } else {
        if (d3 <= 1) {
          alert(i18n.t('Please-_select_minimum_one_month_to_view_the_graph')); // this condition will never be executed- will always show the message as start date should be less than end date
        }
        if (d3 > 6) {
          this.setState({ IsPeriodmoreThanSixMonths: true });
          alert(i18n.t('Data can be plotted for a maximum of 6 months'));
        }

        const currentMonthGraphData = _.cloneDeep(
          currentState.currentMonthCategoriesData,
        );
        //   currentMonthGraphData.labels= [];
        // currentMonthGraphData.datasets= [];
        _.set(currentMonthGraphData, 'labels', []);
        _.set(currentMonthGraphData, 'datasets', []);

        this.setState({
          currentMonthCategoriesData: currentMonthGraphData,
          category: '',
          CategoryDropdownData: [],
        });
      }
    }
  }

  changeCategoryDropdownOnCategoryChange = () => {
    const { PeriodicGraphResponseData, category } = this.state;
    let Data = _.cloneDeep(PeriodicGraphResponseData);
    let categoryNames = [];
    const temp = Data.filter((e) => e.multilevelData[0].CategoryType === category).length > 0
      ? Data.filter((e) => e.multilevelData[0].CategoryType === category) : [];
    temp.forEach((e) => {
      categoryNames.push(...e.multilevelData);
    });
    categoryNames = sortDataAsc(_.uniqBy(categoryNames, (e) => e.CategoryName).map((a) => ({
      ...a,
      label: a.CategoryName,
      value: a.CategoryName,
    })), 'label');
    const selectedcategoryNames = categoryNames.slice(0, 10);
    Data = Data.map((e) => {
      if (e.multilevelData[0].CategoryType === category) {
        e.multilevelData = selectedcategoryNames;
      }
      return e;
    });
    this.setState({
      categoryNames,
      selectedcategoryNames,
      modifiedPeriodGraphData: Data,
    }, () => {
      this.calculateDataPointsForGraph();
    });
  }

  onCategoryChange = (e) => {
    const currentState = this.state;
    const { modifiedPeriodGraphData } = this.state;
    const { currentMonth } = this.props;
    const allCategoryNamesArr = [];
    // currentState.error.showComparisonError="";
    currentState.category = e.target.options[e.target.selectedIndex].value;

    this.setState(currentState, () => {
      this.changeCategoryDropdownOnCategoryChange();
    });
    const { kpiUnit } = this.props;
    const labels = [];
    let obj1 = {};
    const AllBarColors = [
      '#018eff',
      '#f49342',
      '#7ec344',
      '#26c1c9',
      '#9c6ade',
      '#078ccd',
      '#d5002f',
      '#7500c0',
      '#ffc82c',
      '#1a868c',
    ];
    if (
      modifiedPeriodGraphData
      && modifiedPeriodGraphData.length === 0
    ) {
      const monthFirstThreeLetters = currentMonth.substring(0, 3);
      const yearPart = currentMonth.split('-')[1];
      const currentMonthFormattedLabel = `${monthFirstThreeLetters}-${yearPart}`;
      labels.push(currentMonthFormattedLabel);
      this.setState({
        currentMonthFormattedStr: currentMonthFormattedLabel,
      });
    }
    const datasets = [];

    // filter the api response based on thecategory chosen
    const apiResp = modifiedPeriodGraphData; // for the months selected,
    const filteredAPIResp = apiResp.map((element) => ({
      ...element,
      multilevelData: element.multilevelData.filter(
        (item) => item.CategoryType === currentState.category,
      ),
    }));

    const filteredWithCategoryTypeData = filteredAPIResp.filter((event) => event.multilevelData.length > 0);

    filteredWithCategoryTypeData.forEach((arrayItem, index) => {
      if (arrayItem.multilevelData.length > 0) {
        // this will always be true
        labels.push(arrayItem.PeriodDTStr);
        // loop over each object --CategoryName, CategoryType,Denominator, NumeratorValue
        arrayItem.multilevelData.forEach((mlData, i) => {
          let eachDatapoint = 0;
          if (kpiUnit === '%') {
            eachDatapoint = (mlData.NumeratorValue / mlData.Denominator) * 100;
            if (!Number.isInteger(eachDatapoint)) eachDatapoint = eachDatapoint.toFixed(2);
          } else {
            if (!mlData.Denominator) filteredWithCategoryTypeData[index].multilevelData[i].Denominator = 1;
            eachDatapoint = mlData.NumeratorValue / mlData.Denominator;
            if (!Number.isInteger(eachDatapoint)) {
              // only if calculated value is decimal round off to 2 places
              eachDatapoint = eachDatapoint.toFixed(2);
            }
          }
          filteredWithCategoryTypeData[index].multilevelData[i].CalculatedDataPoint = eachDatapoint;
          if (allCategoryNamesArr.indexOf(mlData.CategoryName) === -1) {
            allCategoryNamesArr.push(mlData.CategoryName);
          }

          // now construct multiple objects of type obj1 and push into datasets array.
        });

        // number of datasets = no of category names in allCategoryNamesArr
      } // end of arrayItem.multilevelData.length>0 if block
    }); // end of filteredWithCategoryTypeData array loop

    // this.setState({ numCatNames: allCategoryNamesArr.length }); // unused state var
    // now for all these category names, collect the datapoints from all the months and construct the data array
    // length of data array = no of months in filteredWithCategoryTypeData

    // then construct obj1.  category name is allCategoryNamesArr[i], colour also [i]
    let dataPointsForAllMonthsForCatName = [];
    allCategoryNamesArr.forEach((element, i) => {
      // loop over filteredWithCategoryTypeData and look for an object with categoryname===element
      dataPointsForAllMonthsForCatName = []; // clear out for each catname
      filteredWithCategoryTypeData.forEach((arItem) => {
        const reqdObj = arItem.multilevelData.filter(
          (each) => each.CategoryName === element,
        );
        if (reqdObj.length > 0) {
          dataPointsForAllMonthsForCatName.push(reqdObj[0].CalculatedDataPoint);
        } else {
          dataPointsForAllMonthsForCatName.push('');
        }
      });

      obj1 = {
        label: element,
        backgroundColor: AllBarColors[i],
        borderColor: AllBarColors[i],
        borderWidth: 1,
        data: dataPointsForAllMonthsForCatName, // from filteredWithCategoryTypeData, all months data for this category name
      };

      datasets.push(obj1);
    }); // end of allCategoryNamesArr foreach

    this.setState({
      // categoryTypeSpecificData:filteredWithCategoryTypeData,
      currentMonthCategoriesData: {
        labels, // we will get months from the api
        // datasets:[]
        datasets,
      },
    });
    this.selectCatType.current.blur();
  };

  handleClearDate = () => {
    // this.setState({                                                // unused state vars
    //   tempStartDate: startDate,                         // unused state vars
    //   tempEndDate: endDate,                             // unused state vars
    //   formattedTempStartDate: formattedStartDate,       // unused state vars
    //   formattedTempEndDate: formattedEndDate,           // unused state vars
    // });                                                            // unused state vars
  };

  z = () => {
    const { isVisibleDatepickerDropdownPopover } = this.state;
    this.setState({
      isVisibleDatepickerDropdownPopover: !isVisibleDatepickerDropdownPopover,
      // isStartEndDateSelected: true,
    });
    if (!isVisibleDatepickerDropdownPopover) {
      this.setState({
        // tempStartDate: startDate,
        // tempEndDate: endDate,
        // formattedTempStartDate: formattedStartDate,
        // formattedTempEndDate: formattedEndDate,
      });
    }
  };

  handleChangeDateField = (date, isStartEndDateSelected) => {
    this.setState({
      [isStartEndDateSelected ? 'tempStartDate' : 'tempEndDate']: date,
      [isStartEndDateSelected
        ? 'formattedTempStartDate'
        : 'formattedTempEndDate']: formatDate(Moment(date)),
    });
  };

  calculateDataPointsForGraph = () => {
    const { kpiUnit, currentMonth } = this.props;
    const {
      modifiedPeriodGraphData, isPeriodSelected, selectedcategoryNames,
    } = this.state;
    let labels = [];
    let obj1 = {};
    const AllBarColors = [
      '#018eff',
      '#f49342',
      '#7ec344',
      '#26c1c9',
      '#9c6ade',
      '#078ccd',
      '#d5002f',
      '#7500c0',
      '#ffc82c',
      '#1a868c',
    ];
    if (
      !isPeriodSelected
      && modifiedPeriodGraphData
      && modifiedPeriodGraphData.length === 0
    ) {
      const monthFirstThreeLetters = currentMonth.substring(0, 3);
      const yearPart = currentMonth.split('-')[1];
      const currentMonthFormattedLabel = `${monthFirstThreeLetters}-${yearPart}`;
      labels.push(currentMonthFormattedLabel);
      this.setState({
        currentMonthFormattedStr: currentMonthFormattedLabel,
      });
      // labels.push(currentMonth);
    }
    const datasets = [];
    let dataPointsArr = [];
    // IF PERIOD API IS NOT CALLED-------
    if (modifiedPeriodGraphData.length === 0) {
      if (!isPeriodSelected) {
        // should not go inot else condition unnecessarily
        selectedcategoryNames.forEach((element, i) => {
          let eachDatapoint = 0;

          if (kpiUnit === '%') {
            eachDatapoint = (element.NumeratorValue / element.Denominator) * 100;
            if (!Number.isInteger(eachDatapoint)) eachDatapoint = eachDatapoint.toFixed(2);
          } else {
            if (!element.Denominator) selectedcategoryNames[i].Denominator = 1;
            eachDatapoint = element.NumeratorValue / element.Denominator;
            if (!Number.isInteger(eachDatapoint)) {
              // only if calculated value is decimal round off to 2 places
              eachDatapoint = eachDatapoint.toFixed(2);
            }
          }
          dataPointsArr.push(eachDatapoint);
          obj1 = {
            label: element.CategoryName,
            backgroundColor: AllBarColors[i],
            borderColor: AllBarColors[i],
            borderWidth: 1,
            data: [dataPointsArr[i]],
          };

          datasets.push(obj1);
        });

        this.setState({
          currentMonthCategoriesData: {
            labels, // we will get months from the api
            datasets,
          },
        });
      } else {
        // load the tab, change the period control- there is no data to be plotted case
        const { currentMonthCategoriesData } = this.state;
        const currentGraphData = _.cloneDeep(
          currentMonthCategoriesData,
        );

        _.set(currentGraphData, 'labels', []);
        _.set(currentGraphData, 'datasets', []);

        this.setState({
          currentMonthCategoriesData: currentGraphData,
          CategoryDropdownData: [],
          category: '',
        });
      }
    } else if (modifiedPeriodGraphData.length) {
      const { category } = this.state;
      // IF PERIOD API IS CALLED
      // logic for calculating data points from api response and set datasets and labels
      // const currentState = this.state;
      // currentState.error.showComparisonError="";

      const apiResp = modifiedPeriodGraphData;
      const allCategoryNamesArr = [];
      // Take all the category names rom filtered api resp into a huge aray
      // Length of data array in each obj = no of months in the response (length of filtered api resp array)
      // length of datasets (how many objs will you push into the datasets array?) = no of catnames

      labels = [];
      const currentCategoryType = category;
      const filteredAPIResp = apiResp.map((element) => ({
        ...element,
        multilevelData: element.multilevelData.filter(
          (item) => item.CategoryType === currentCategoryType,
        ),
      }));
      if (filteredAPIResp.length > 0) {
        const filteredWithCategoryTypeData = filteredAPIResp.filter((
          event,
        ) => event.multilevelData.length > 0);

        // this.setState({
        //   lengthOfPeriodicDataToBePlotted: filteredWithCategoryTypeData.length,
        // });

        filteredWithCategoryTypeData.forEach((arrayItem, index) => {
          dataPointsArr = [];

          if (arrayItem.multilevelData.length > 0) {
            // this will always be true
            labels.push(arrayItem.PeriodDTStr);
            arrayItem.multilevelData.forEach((mlData, i) => {
              let eachDatapoint = 0;
              if (kpiUnit === '%') {
                eachDatapoint = (mlData.NumeratorValue / mlData.Denominator) * 100;
                if (!Number.isInteger(eachDatapoint)) eachDatapoint = eachDatapoint.toFixed(2);
              } else {
                if (!mlData.Denominator) filteredWithCategoryTypeData[index].multilevelData[i].Denominator = 1;
                eachDatapoint = mlData.NumeratorValue / mlData.Denominator;
                if (!Number.isInteger(eachDatapoint)) {
                  // only if calculated value is decimal round off to 2 places
                  eachDatapoint = eachDatapoint.toFixed(2);
                }
              }
              filteredWithCategoryTypeData[index].multilevelData[i].CalculatedDataPoint = eachDatapoint;
              if (allCategoryNamesArr.indexOf(mlData.CategoryName) === -1) {
                allCategoryNamesArr.push(mlData.CategoryName);
              }
            });

            // number of datasets = no of category names in allCategoryNamesArr
          } // end of arrayItem.multilevelData.length>0 if block
        }); // end of filteredWithCategoryTypeData array loop

        // this.setState({ numCatNames: allCategoryNamesArr.length });
        // now for all these category names, collect the datapoints from all the months and construct the data array
        // length of data array = no of months in filteredWithCategoryTypeData

        // then construct obj1.  category name is allCategoryNamesArr[i], colour also [i]
        let dataPointsForAllMonthsForCatName = [];
        allCategoryNamesArr.forEach((element, i) => {
          // loop over filteredWithCategoryTypeData and look for an object with categoryname===element
          dataPointsForAllMonthsForCatName = []; // clear out for each catname
          filteredWithCategoryTypeData.forEach((arItem) => {
            const reqdObj = arItem.multilevelData.filter(
              (e) => e.CategoryName === element,
            );
            if (reqdObj.length > 0) {
              dataPointsForAllMonthsForCatName.push(
                reqdObj[0].CalculatedDataPoint,
              );
            } else {
              dataPointsForAllMonthsForCatName.push('');
            }
          });

          obj1 = {
            label: element,
            backgroundColor: AllBarColors[i],
            borderColor: AllBarColors[i],
            borderWidth: 1,
            data: dataPointsForAllMonthsForCatName, // from filteredWithCategoryTypeData, all months data for this category name
          };

          datasets.push(obj1);
        }); // end of allCategoryNamesArr foreach

        this.setState({
          // categoryTypeSpecificData:filteredWithCategoryTypeData,
          currentMonthCategoriesData: {
            labels, // we will get months from the api
            // datasets:[]
            datasets,
          },
        });
      }
    } else {
      // IF NOTHING IS SELECTED IN CATEGORY NAME
      this.setState({
        currentMonthCategoriesData: {
          labels: [],
          datasets: [],
        },
      });
    }
  };

  initiatlizeCategoryData = () => {
    const { CategoryInfo } = this.props;
    let categoryNames = CategoryInfo;
    categoryNames = sortDataAsc(categoryNames.map((e) => ({
      value: e.CategoryName,
      label: e.CategoryName,
      ...e,
    })), 'label');
    const selectedcategoryNames = categoryNames.slice(0, 10);
    const category = categoryNames.length > 0 ? categoryNames[0].CategoryType : '';
    const CategoryDropdownData = [{ CategoryType: category }];

    this.setState({
      categoryNames,
      selectedcategoryNames,
      category,
      CategoryDropdownData,
    }, () => {
      this.calculateDataPointsForGraph();
    });
  }

  handleReinitaitalzeCategories = () => {
    const { PeriodicGraphResponseData, category } = this.state;
    let data = _.cloneDeep(PeriodicGraphResponseData);
    let currentCategoryType = category;
    let CategoryDropdownData = [];
    let categoryNames = [];
    let selectedcategoryNames = [];

    data.forEach((e) => {
      if (e.multilevelData.length > 0) {
        CategoryDropdownData.push(e.multilevelData[0]);
        categoryNames.push(...e.multilevelData);
      }
    });
    // UNIQUE CATEGORIES TYPE
    CategoryDropdownData = _.uniqBy(CategoryDropdownData, (e) => e.CategoryType).map((a) => ({ CategoryType: a.CategoryType }));

    // CHECKING IF CURRENT CATEGORY TYPE EXISTS OR NOT
    // currentCategoryType = CategoryDropdownData.filter((a) => a.CategoryType.toLowerCase() === currentCategoryType.toLowerCase()).length > 0
    //   ? currentCategoryType
    //   : CategoryDropdownData.length > 0
    //     ? CategoryDropdownData[0].CategoryType : '';

    if (CategoryDropdownData.filter((a) => a.CategoryType.toLowerCase() === currentCategoryType.toLowerCase()).length > 0) {
      // no change required
    } else if (CategoryDropdownData.length > 0) {
      currentCategoryType = CategoryDropdownData[0].CategoryType;
    } else {
      currentCategoryType = '';
    }

    // UNIQUE CATEGORIES NAMES
    categoryNames = categoryNames.filter((a) => a.CategoryType === currentCategoryType).map((a) => ({
      ...a,
      label: a.CategoryName,
      value: a.CategoryName,
    }));

    // POPULATING CATEGORIES MULTI-SELECT DROPDOWN
    categoryNames = sortDataAsc(_.uniqBy(categoryNames, (e) => e.CategoryName), 'label');
    selectedcategoryNames = categoryNames.slice(0, 10);

    // MODIFYING PERIODGRAPHDATA
    data = data.map((e) => {
      const d = e;
      if (d.multilevelData[0].CategoryType === currentCategoryType) {
        d.multilevelData = sortDataAsc(e.multilevelData.filter((s) => selectedcategoryNames.some((a) => a.CategoryName === s.CategoryName)), 'CategoryName');
      }
      return d;
    });

    this.setState({
      CategoryDropdownData,
      categoryNames,
      selectedcategoryNames,
      modifiedPeriodGraphData: data,
      category: currentCategoryType,
    }, () => {
      this.calculateDataPointsForGraph();
    });
  }

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  }

  handleChangeSelectedCategoryPeriod = () => {
    const { PeriodicGraphResponseData, category, selectedcategoryNames } = this.state;
    let data = _.cloneDeep(PeriodicGraphResponseData);
    const currentCategoryType = category;
    const selected = selectedcategoryNames;
    data = data.map((e) => {
      const d = e;
      if (d.multilevelData[0].CategoryType === currentCategoryType) {
        d.multilevelData = e.multilevelData.filter((s) => selected.some((a) => a.CategoryName === s.CategoryName));
      }
      return d;
    });
    this.setState({
      modifiedPeriodGraphData: data,
    }, () => {
      this.calculateDataPointsForGraph();
    });
  }

  // handleLableClick = (data) =>{
  //   const { selectedcategoryNames, PeriodicGraphResponseData } = this.state;
  //   let res;
  //   if(selectedcategoryNames.filter((item) => item.CategoryName === data.CategoryName).length>0){
  //     res = selectedcategoryNames.filter((item) => item.CategoryName !== data.CategoryName)
  //   }else{
  //     data.label = data.CategoryName;
  //     data.value = data.CategoryName
  //     selectedcategoryNames.push(data)
  //     res = selectedcategoryNames;
  //   }
  //   if (res.length <= 10) {
  //     this.setState({
  //       selectedcategoryNames: res,
  //     }, () => {
  //       // IF PERIOD API IS NOT CALLED
  //       if (PeriodicGraphResponseData.length === 0) {
  //         this.calculateDataPointsForGraph();
  //       } else {
  //         // IF PERIOD API IS CALLED
  //         this.handleChangeSelectedCategoryPeriod();
  //       }
  //     });
  //   } else {
  //     const message = i18n.t('Category_Message_ten');
  //     window.alert(message);
  //   }
  // }

  handleCategoryNameChange = (argumentData) => {
    const { PeriodicGraphResponseData } = this.state;
    if (argumentData.length <= 10) {
      const data = sortDataAsc(argumentData, 'label');
      this.setState({
        selectedcategoryNames: data,
      }, () => {
        // IF PERIOD API IS NOT CALLED
        if (PeriodicGraphResponseData.length === 0) {
          this.calculateDataPointsForGraph();
        } else {
          // IF PERIOD API IS CALLED
          this.handleChangeSelectedCategoryPeriod();
        }
      });
    } else {
      const message = i18n.t('Category_Message_ten');
      window.alert(message);
    }
  }

  clearPeriodFrom = () => {
    const currentState = this.state;
    const clonedPeriod = _.cloneDeep(currentState.period);
    clonedPeriod.from = '';
    clonedPeriod.to = '';
    // currentState.period.from = '';
    // currentState.period.to = '';
    const currentMonthGraphData = _.cloneDeep(currentState.currentMonthCategoriesData);

    _.set(currentMonthGraphData, 'labels', []);
    _.set(currentMonthGraphData, 'datasets', []);
    this.setState(
      {
        period: clonedPeriod,
        currentMonthCategoriesData: currentMonthGraphData,
        PeriodicGraphResponseData: [],
        modifiedPeriodGraphData: [],
        isPeriodSelected: false,
        PeriodicGraphAPIResponseRecd: false,
        disableCategorydropdown: true,
      },
      () => {
        this.initiatlizeCategoryData();
      },
    );
  };

  clearPeriodTo = () => {
    const currentState = this.state;
    const clonedPeriod = _.cloneDeep(currentState.period);
    clonedPeriod.to = '';
    const currentMonthGraphData = _.cloneDeep(currentState.currentMonthCategoriesData);

    _.set(currentMonthGraphData, 'labels', []);
    _.set(currentMonthGraphData, 'datasets', []);

    this.setState({
      period: clonedPeriod,
      currentMonthCategoriesData: currentMonthGraphData,
      isPeriodSelected: false,
      PeriodicGraphAPIResponseRecd: false,
      disableCategorydropdown: true,
    });
  };

  customValueRenderer = (selected, options) => {
    let returnValue;
    if (selected.length === 0) {
      returnValue = `${i18n.t('Select')}....`;
    } if (selected.length > 0 && selected.length === options.length) {
      returnValue = `${i18n.t('All_Selected')}`;
    } if (selected.length <= 2) {
      returnValue = selected.map((ob) => ob.label).join(', ');
    } if (selected.length > 2) {
      returnValue = `${selected
        .map((ob) => ob.label)
        .slice(0, 2)
        .join(', ')}, +${selected.length - 2}`;
    }
    return returnValue;
  };

  render() {
    const {
      t, IsMultiLevel, kpiUnit, CategoryInfo,
    } = this.props;
    const {
      currentMonthFormattedStr,
      showLoader,
      categoryNames,
      selectedcategoryNames,
      period,
      category,
      CategoryDropdownData,
      disableCategorydropdown,
      PeriodicGraphAPIResponseRecd,
      modifiedPeriodGraphData,
      currentMonthCategoriesData,
      isPeriodSelected,
      IsPeriodmoreThanSixMonths,
    } = this.state;
    const categoriesNamecomponent = (
      <>
        <MultiSelect
          options={categoryNames}
          value={selectedcategoryNames}
          onChange={this.handleCategoryNameChange}
          labelledBy="categorynames"
          shouldToggleOnHover={false}
          hasSelectAll={false}
          disabled={period.from !== '' && period.to === ''}
          valueRenderer={this.customValueRenderer}
        />
      </>
    );

    return (
      <>
        <div className="row mt-3">
          <div className="form-group col-lg-4">
            <label htmlFor="category">
              {t('Category_Type')}
              <span className="required">*</span>
            </label>
            <select
              className="form-control"
              name="category"
              value={category}
              onChange={this.onCategoryChange}
              disabled={(CategoryDropdownData.length <= 1 && disableCategorydropdown) || (period.from !== '' && period.to === '')}
              ref={this.selectCatType}
            >
              {CategoryDropdownData.length > 0
                && CategoryDropdownData.map((cat) => (
                  <option value={cat.CategoryType}>{cat.CategoryType}</option>
                ))}
            </select>
          </div>
          <div className="form-group col-lg-4">
            <label htmlFor="categoryname">
              {t('Category_Name')}
              <span className="required">*</span>
            </label>
            <div className={`custom-multi-select metric-deep-dive ${(period.from !== '' && period.to === '') || categoryNames.length === 0 ? 'disabled' : ''}`}>{categoriesNamecomponent}</div>
          </div>
          <div className="form-group col-lg-4">
            <label id="period" htmlFor="period">
              {t('Period')}
              <span className="required">*</span>
            </label>
            <div className="form-row">
              <div className="col block-picker">
                <DatePicker
                  className="form-control"
                  selected={period.from}
                  id="StartDate"
                  onSelect={(e) => this.onPeriodChangeFrom('From', e)}
                  value={period.from}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  autoComplete="off"
                  onChangeRaw={this.handleDateChangeRaw}
                >
                  <div className="period-date-controls">
                    <span
                      onClick={this.clearPeriodFrom}
                      role="button"
                      tabIndex="0"
                    >
                      {i18n.t('Clear')}
                    </span>
                  </div>
                </DatePicker>
              </div>
              <div className="col block-picker">
                <DatePicker
                  className="form-control"
                  selected={period.to && period.to >= period.from ? period.to : ''}
                  id="EndDate"
                  minDate={addMonths(period.from, 1)}
                  onSelect={(e) => { this.onPeriodChangeTo('To', e); }}
                  value={period.to}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  autoComplete="off"
                  maxDate={addMonths(period.from, 5)}
                  disabled={period.from === ''}
                  onChangeRaw={this.handleDateChangeRaw}
                >
                  <div className="period-date-controls">
                    <span
                      onClick={this.clearPeriodTo}
                      role="button"
                      tabIndex="0"
                    >
                      {i18n.t('Clear')}

                    </span>
                  </div>
                </DatePicker>
              </div>
            </div>
          </div>
        </div>

        {PeriodicGraphAPIResponseRecd
          && modifiedPeriodGraphData.length === 0 ? (
            <span className="errorMsg">{i18n.t('no_periodic_data')}</span>
          ) : null}

        {!IsMultiLevel
          && (!period.from || !period.to) ? (
            <p className="height-130">
              {i18n.t('No_Multilevel_Msg')}
              {' '}
              {currentMonthFormattedStr}
              {' '}
            </p>
          ) : null}
        {(CategoryInfo && CategoryInfo.length > 0)
          || (period.from
            && period.to
            && PeriodicGraphAPIResponseRecd
            && modifiedPeriodGraphData.length > 0) ? (
              <>
                {/* <div className="linechart-commentry">
                  <div className={'linechart-division nocommentary-section'}>
              <div className="custom-chartlegend">
              <ul>
              {CategoryInfo.length>0 &&
                CategoryInfo.map((each) => {
                  return(
                    <li aria-hidden="true" onClick={(e)=>this.handleLableClick(each)}>
                    <span className="metric-legendvalue">
                      <img  alt="" />
                    </span>
                    {this.state.selectedcategoryNames.filter((item) => each.CategoryName === item.CategoryName ).length>0?
                    each.CategoryName : <s className="legend-strike-through">{each.CategoryName}</s>
                    }

                  </li>
                  )}
                )}

                </ul>
              </div>
               */}
                <div>
                  <Bar
                    data={currentMonthCategoriesData}
                    redraw="true"
                    height="100vh"
                    type="bar"
                    options={{
                      layout: {
                        padding: {
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 20,
                        },
                      },
                      responsive: true,
                      scaleBeginAtZero: false, // so that -ve values are displayed
                      title: {
                        display: false,
                        text:
                    !isPeriodSelected
                      && CategoryInfo.length > 0
                      ? CategoryInfo[0].CategoryType
                      : category,
                      },
                      scales: {
                        xAxes: [
                          {
                            maxBarThickness: 50, // reduced bar thickness to half based on Monika's feedback
                            stacked: false,
                            ticks: {
                              beginAtZero: true,
                              //  precision: 0.1,
                              suggestedMax: 999,
                              fontColor: 'black',
                            },

                            scaleLabel: {
                              display: true,
                              // labelString: "Deals",
                              // labelString: currentMonth,

                              crosshair: true,
                              fontColor: 'black',
                            },
                            marker: {
                              enabled: false,
                            },

                            gridLines: {
                              display: true,
                            },
                          },
                        ],

                        yAxes: [
                          {
                            // type: "linear",
                            gridLines: {
                              display: true,
                            },
                            afterDataLimits(scale) {
                              // eslint-disable-next-line no-param-reassign
                              scale.max += 1; // this dynamically sets the y axis min and max values
                              // eslint-disable-next-line no-param-reassign
                              scale.min -= 1;
                            },

                            // stacked: true,
                            ticks: {
                              //  min: 0,
                              beginAtZero: false,
                              fontColor: 'black',
                            },
                            scaleLabel: {
                              display: true,
                              // labelString: "Unit(%)",
                              labelString:
                          (!period.to && !period.from)
                            || (isPeriodSelected
                              && PeriodicGraphAPIResponseRecd
                              && modifiedPeriodGraphData.length > 0)
                            ? `Unit(${kpiUnit})`
                            : '',
                              fontColor: 'black',
                            },
                            marker: {
                              enabled: false,
                            },
                          },
                        ],
                      },
                      plugins: {
                        datalabels: {
                          display: false, // true if you want to see the numbers inside the bars
                        },
                      },
                      legend: {
                        fontSize: 20,
                        display: true,
                        itemWidth: 50,
                        position: 'top',
                        align: 'start',
                        fullWidth: false,
                        labels: {
                          fontColor: 'rgb(0,0,0)',
                          boxWidth: 30,
                        },
                      },
                    }}
                    // plugins={{
                    //   beforeInit: (chart) => {
                    //     const fitValue = chart.legend.fit;
                    //     // eslint-disable-next-line no-param-reassign
                    //     chart.legend.fit = function fit() {
                    //       fitValue.bind(chart.legend)();
                    //       this.height -= 50;
                    //       return this.height;
                    //     };
                    //   },
                    //   datalabels: {
                    //     display: false, // true if you want to see the numbers inside the bars
                    //   },
                    // }}
                  />
                </div>
                {/* </div>
          </div> */}
              </>
          ) : null}

        {PeriodicGraphAPIResponseRecd
          && modifiedPeriodGraphData.length === 0 ? (
            <p className="height-75"> </p>
          ) : null}
        {(period.from
          && period.to
          && period.from >= period.to)
          || IsPeriodmoreThanSixMonths ? (
            <p className="height-130"> </p>
          ) : null}
        {showLoader ? (
          <LoadingOverlay
            fadeSpeed={0}
            spinner={<SynopsLoader />}
            active
          />
        ) : (
          <></>
        )}
      </>
    );
  }
}
MetricDeepDive.propTypes = {
  onClose: PropTypes.func.isRequired,
  CategoryInfo: PropTypes.array.isRequired,
  kpiUnit: PropTypes.string.isRequired,
  currentMonth: PropTypes.string.isRequired,
  dealFilter: PropTypes.array.isRequired,
  metricID: PropTypes.number.isRequired,
  IsMultiLevel: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};
export default withTranslation()(MetricDeepDive);
