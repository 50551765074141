import React from "react";
import { Modal } from "react-bootstrap";
import { LocalApiBaseUrl } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import _ from "lodash";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class DEIPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DEIDealOptions: [],
      DEIClientOptions: [],
      DEITowerOptions: [],
      DEILocationOptions: [],
      show: this.props.show,
      apivalue: "",
      selectValue: this.props.SelectValue,
      Display: false,
      errors: [],
      formIsValid: false,

      DEI: {
        dEIDataMappingModel: {
          DealID: null,
          DEIClientName: null,
          Tower: null,
          DEIDeliveryLocation: null,
        },
      },
    };

    this.onDEIDealChange = this.onDEIDealChange.bind(this);
    this.onDEIClientChange = this.onDEIClientChange.bind(this);
    this.onDEITowerChange = this.onDEITowerChange.bind(this);
    this.onDEILocationChange = this.onDEILocationChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  validateForm() {
    let DEI = this.state.DEI;
    let errors = {};
    let formIsValid = true;

    if (!DEI.dEIDataMappingModel["DealID"]) {
      formIsValid = false;
      errors["DealID"] = "Deal is required";
    }
    if (!DEI.dEIDataMappingModel["DEIClientName"]) {
      formIsValid = false;
      errors["DEIClientName"] = "Client is required";
    }
    if (!DEI.dEIDataMappingModel["DEIDeliveryLocation"]) {
      formIsValid = false;
      errors["DEIDeliveryLocation"] = "Location required";
    }
    if (!DEI.dEIDataMappingModel["Tower"]) {
      formIsValid = false;
      errors["Tower"] = "Tower required";
    }

    this.setState({
      errors: errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  handleSave() {
    if (this.validateForm()) {
      this.props.onSave(this.state.DEI);
      this.handleHide();
    }
  }
  handleHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
  }
  handleRejectHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }
  passToConfirmation(data) {
    this.props.enteredData(data.PoNumber);
  }

  onDEIDealChange(event) {
    this.setState({ errors: {} });
    const DEI = this.state.DEI;
    DEI.dEIDataMappingModel.DealID = event.target.value;
    this.setState({
      DEI: DEI,
    });
  }

  GetDEIDeal() {
    let EnterpriseID = sessionStorage.getItem("EnterpriseID");
    if (!_.isNil(EnterpriseID)) {
      axiosInstance
        .get(`${LocalApiBaseUrl}Deal/GetDeals`, {
          params: { FeatureName: "Build Query" },
        })

        .then((response) => {
          this.setState({ DEIDealOptions: response.data.filter((f) => f.IsActive === true) });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  }

  onDEIClientChange(event) {
    this.setState({ errors: {} });
    const DEI = this.state.DEI;
    DEI.dEIDataMappingModel.DEIClientName = event.target.value;
    this.setState({
      DEI: DEI,
    });
  }

  GetDEIClient() {
    let EnterpriseID = sessionStorage.getItem("EnterpriseID");
    if (!_.isNil(EnterpriseID)) {
      axiosInstance
        .get(`${LocalApiBaseUrl}DEIDataMapping/GetDEIClient `, {
          params: {},
        })

        .then((response) => {
          this.setState({ DEIClientOptions: response.data });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  }

  onDEITowerChange(event) {
    this.setState({ errors: {} });
    const DEI = this.state.DEI;
    DEI.dEIDataMappingModel.Tower = event.target.value;
    this.setState({
      DEI: DEI,
    });
  }

  GetDEITower() {
    let EnterpriseID = sessionStorage.getItem("EnterpriseID");
    if (!_.isNil(EnterpriseID)) {
      axiosInstance
        .get(`${LocalApiBaseUrl}DEIDataMapping/GetDEITower `, {
          params: {},
        })

        .then((response) => {
          this.setState({ DEITowerOptions: response.data });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  }

  onDEILocationChange(event) {
    this.setState({ errors: {} });
    const DEI = this.state.DEI;
    DEI.dEIDataMappingModel.DEIDeliveryLocation = event.target.value;
    this.setState({
      DEI: DEI,
    });
  }

  GetDEILocation() {
    let EnterpriseID = sessionStorage.getItem("EnterpriseID");
    if (!_.isNil(EnterpriseID)) {
      axiosInstance
        .get(`${LocalApiBaseUrl}DEIDataMapping/GetDEIDeliveryLocation `, {
          params: {},
        })

        .then((response) => {
          this.setState({ DEILocationOptions: response.data });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  }

  componentDidMount() {
    this.GetDEIDeal();
    this.GetDEIClient();
    this.GetDEITower();
    this.GetDEILocation();
  }

  componentWillMount() {
    if (this.props.data) {
      this.setState({
        DEI: this.props.data,
      });
    }
  }

  render() {
    const { data } = this.props;
    const { show, DEI, DEIDealOptions, errors, DEIClientOptions, DEITowerOptions, DEILocationOptions } = this.state;
    return (
      <>
        <Modal
          show={show}
          onHide={this.handleHide.bind(this)}
          backdrop="static"
          className="modal fade"
          size="medium"
          id="dealpopup"
        >
          <Modal.Header closeButton>
            {!data ? (
              <h4 className="modal-title" id="dealprofile" flag>
                Add DEI
              </h4>
            ) : (
              <h4 className="modal-title" id="dealprofile" flag>
                Edit DEI
              </h4>
            )}
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label for="">
                      Selection <span className="required">*</span>
                    </label>
                    <select
                      id="OperatingGroup"
                      className="form-control"
                      disabled={data}
                      ref="OperatingGroupDropDown"
                      onChange={this.onDEIDealChange.bind(this)}
                      value={DEI.dEIDataMappingModel.DealID}
                    >
                      <option value=""></option>
                      {DEIDealOptions.map((row) => {
                        return (
                          <option value={row.DealID}>
                            {row.ClientName + "  -  " + row.Process + "  -  " + row.City}
                          </option>
                        );
                      })}
                    </select>
                    <span className="errorMsg">{errors.DealID}</span>
                  </div>

                  <div className="form-group col-lg-6">
                    <label for="">
                      DEI Client<span className="required">*</span>
                    </label>
                    <select
                      id="OperatingGroup"
                      className="form-control"
                      disabled={data}
                      ref="OperatingGroupDropDown"
                      onChange={this.onDEIClientChange.bind(this)}
                      value={DEI.dEIDataMappingModel.DEIClientName}
                    >
                      <option value=""></option>
                      {DEIClientOptions.map((row) => {
                        return <option value={row.DEIClientName}>{row.DEIClientName}</option>;
                      })}
                    </select>
                    <span className="errorMsg">{errors.DEIClientName}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-lg-6">
                    <label for="">
                      DEI Tower<span className="required">*</span>
                    </label>
                    <select
                      id="OperatingGroup"
                      className="form-control"
                      disabled={data}
                      ref="OperatingGroupDropDown"
                      onChange={this.onDEITowerChange.bind(this)}
                      value={DEI.dEIDataMappingModel.Tower}
                    >
                      <option value=""></option>
                      {DEITowerOptions.map((row) => {
                        return <option value={row.DEITower}>{row.DEITower}</option>;
                      })}
                    </select>
                    <span className="errorMsg">{errors.Tower}</span>
                  </div>

                  <div className="form-group col-lg-6">
                    <label for="">
                      DEI Location<span className="required">*</span>
                    </label>
                    <select
                      id="OperatingGroup"
                      className="form-control"
                      disabled={data}
                      ref="OperatingGroupDropDown"
                      onChange={this.onDEILocationChange.bind(this)}
                      value={DEI.dEIDataMappingModel.DEIDeliveryLocation}
                    >
                      <option value=""></option>
                      {DEILocationOptions.map((row) => {
                        return <option value={row.DEIDeliveryLocation}>{row.DEIDeliveryLocation}</option>;
                      })}
                    </select>
                    <span className="errorMsg">{errors.DEIDeliveryLocation}</span>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">
                {!data ? (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave()}
                    value="Create"
                  />
                ) : (
                  <input
                    type="Button"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.handleSave()}
                    value="Update"
                  />
                )}
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}
