import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { Line, Chart } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import LoadingOverlay from 'react-loading-overlay';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { store } from '../reducers/configureStore';
import { LocalApiBaseUrl, trycatchAlertPopup } from '../Shared/Constant';
import axiosInstance from '../Shared/interceptor';
import SynopsLoader from '../Shared/SynopsLoader';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(
  'msal.idtoken',
)}`;

class CrossMetricAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: 'Business Outcomes',
      baseMonthApiResponse: [],
      baseMontApiStatus: false,
      dropDownForBo: [],
      dropDownForII: [],
      period: { from: '', to: '' },
      baseMonth: '',
      error: {
        from: '',
        to: '',
        boiSelection: '',
        baseMonthError: '',
        showComparisonError: '',
      },
      actualValues: [],
      numberOfCategories: [{
        boi: '',
        baseMonth: '',
        id: '',
        MetricID: '',
      }],
      showGraph: false,
      showComparison: true,
      graphData: {
        labels: [],
        datasets: [],
      },
    };
  }

  componentDidMount() {
    Chart.Legend.prototype.afterFit = function () {
      this.height += 50;
    };
    const response1 = [
      { boiName: 'Payment on Time' },
      { boiName: 'Test1' },
      { boiName: 'Test2' },
      { boiName: 'Test3' },
      { boiName: 'Test4' },
      { boiName: 'Test5' },
    ];
    const response2 = [
      { boiName: 'II1' },
      { boiName: 'II2' },
      { boiName: 'II3' },
      { boiName: 'II4' },
      { boiName: 'II5' },
      { boiName: 'II6' },
    ];
    const dropdown1 = [];
    const dropdown2 = [];
    response1.forEach((each) => {
      dropdown1.push(each.boiName);
    });
    response2.forEach((each) => {
      dropdown2.push(each.boiName);
    });
    this.setState({
      dropDownForBo: dropdown1,
      dropDownForII: dropdown2,
    });
  }

  onCategoryChange = (e) => {
    const {
      period,
      numberOfCategories,
      baseMonth,
      error,
      category,
    } = this.state;

    const clonedPeriod = _.cloneDeep(period);
    let clonedNumberOfCategories = _.cloneDeep(numberOfCategories);
    let clonedBaseMonth = _.cloneDeep(baseMonth);
    const clonedError = _.cloneDeep(error);
    let clonedcategory = _.cloneDeep(category);

    clonedError.showComparisonError = '';
    clonedcategory = e.target.options[e.target.selectedIndex].value;
    clonedPeriod.from = '';
    clonedPeriod.to = '';
    clonedNumberOfCategories = [{ boi: '', baseMonth: '', id: '' }];
    clonedBaseMonth = '';
    this.setState({
      period: clonedPeriod,
      numberOfCategories: clonedNumberOfCategories,
      baseMonth: clonedBaseMonth,
      error: clonedError,
      category: clonedcategory,
    });
  };

  onPeriodChange = (val, e) => {
    const {
      period,
      numberOfCategories,
      baseMonth,
      error,
    } = this.state;

    const clonedPeriod = _.cloneDeep(period);
    let clonedNumberOfCategories = _.cloneDeep(numberOfCategories);
    let clonedBaseMonth = _.cloneDeep(baseMonth);
    const clonedError = _.cloneDeep(error);

    if (val === 'From' && e) {
      const d1 = new Date(e);
      const d2 = d1.setMonth(d1.getMonth() + 5);
      clonedPeriod.from = e;
      clonedPeriod.to = d2;
      clonedNumberOfCategories = [{ boi: '', baseMonth: '', id: '' }];
      clonedBaseMonth = '';
      clonedError.showComparisonError = '';
      this.setState({
        period: clonedPeriod,
        numberOfCategories: clonedNumberOfCategories,
        baseMonth: clonedBaseMonth,
        error: clonedError,
      });
    } else if (e) {
      const d2 = new Date(e);
      const d1 = d2.setMonth(d2.getMonth() - 5);
      clonedPeriod.to = e;
      clonedPeriod.from = d1;
      clonedNumberOfCategories = [{ boi: '', baseMonth: '', id: '' }];
      clonedBaseMonth = '';
      clonedError.showComparisonError = '';
      this.setState({
        period: clonedPeriod,
        numberOfCategories: clonedNumberOfCategories,
        baseMonth: clonedBaseMonth,
        error: clonedError,
      });
    }
  };

  addBOI = () => {
    const { numberOfCategories: numberOfCategoriesFromState } = this.state;
    const numberOfCategories = [...numberOfCategoriesFromState];
    if (numberOfCategories.length < 5) {
      numberOfCategories.push({
        boi: '', baseMonth: '', id: '', MetricID: '',
      });
      this.setState({ numberOfCategories });
    }
  };

  deleteBOI = (val) => {
    const { numberOfCategories: numberOfCategoriesFromState, error } = this.state;
    const numberOfCategories = [...numberOfCategoriesFromState];
    numberOfCategories.splice(val, 1);
    this.setState({
      numberOfCategories,
      error: { ...error, showComparisonError: '', boiSelection: '' },
    });
  };

  onBoiOptionChange = (i, e) => {
    const { t } = this.props;
    const { numberOfCategories: numberOfCategoriesFromState, error, baseMonthApiResponse } = this.state;
    const numberOfCategories = [...numberOfCategoriesFromState];
    const duplicateSelection = numberOfCategories.filter((each) => {
      let returnValue = false;
      if (String(each.id) === String(e.target.options[e.target.selectedIndex].value)) {
        returnValue = true;
        return each;
      }
      return returnValue;
    });
    if (duplicateSelection.length === 0) {
      const currenterror = error;
      currenterror.boiSelection = '';
      currenterror.showComparisonError = '';
      numberOfCategories[i].id = e.target.options[e.target.selectedIndex].value;
      const apiresponse = [...baseMonthApiResponse];
      if (e.target.options[e.target.selectedIndex].value && apiresponse.length) {
        const flitered = apiresponse.filter((each) => {
          let returnValue = false;
          if (String(each.id) === String(e.target.options[e.target.selectedIndex].value)) {
            returnValue = true;
            return each;
          }
          return returnValue;
        });
        if (parseFloat(flitered[0].baseValue) || parseFloat(flitered[0].baseValue) > 0) {
          numberOfCategories[i].baseMonth = flitered[0].baseValue;
          numberOfCategories[i].MetricID = parseInt(flitered[0].MetricID, 10);
          numberOfCategories[i].boi = flitered[0].metricName;
          this.setState({ numberOfCategories, error: currenterror });
        } else {
          currenterror.boiSelection = t('Cross_M_A_Error_M8');
          currenterror.showComparisonError = '';
          numberOfCategories[i].id = '';
          this.setState({ numberOfCategories, error: currenterror });
        }
      } else {
        numberOfCategories[i].baseMonth = '';
        this.setState({ numberOfCategories, error: currenterror });
      }
    } else {
      const currentState = this.state;
      currentState.error.showComparisonError = '';
      currentState.error.boiSelection = t('Cross_M_A_Error_M7');
      this.setState(currentState);
    }
  };

  onBaseMonthChange = (e) => {
    const { t, dealFilter, kpiMappingId } = this.props;
    const currentState = this.state;
    currentState.baseMontApiStatus = true;
    this.setState(currentState);
    if (currentState.period.to && currentState.period.from) {
      const d2 = new Date(currentState.period.to);
      const d1 = new Date(currentState.period.from);
      const d3 = new Date(e);
      const { category } = this.state;
      if (d3 <= d2 && d3 >= d1) {
        if (category !== 'Business Outcomes') {
          const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ];
          const selectedDate = new Date(e);
          const apiMonth = `${months[selectedDate.getMonth()]}-${selectedDate.getFullYear()}`;
          const IIoption = [];
          const IIValues = [];
          axiosInstance
            .get(`${LocalApiBaseUrl}SOFilter/GetInsightsAndIntelligence`, {
              params: {
                OrgEntityID: dealFilter.Client,
                LocationID: dealFilter.Locations,
                MonthDT: apiMonth,
                OfferingID: dealFilter.process,
                LanguageID: store.getState().CurrentFilter.languageData.id,
              },
            })
            .then((response) => {
              response.data.forEach((each) => {
                if (each.kpiData.length) {
                  each.kpiData.forEach((kpi) => {
                    IIValues.push({
                      metricName: kpi.MetricName,
                      baseValue: kpi.MetricValue,
                      id: kpi.KPIMappingID,
                      MetricID: kpi.MetricID,
                    });
                    IIoption.push(kpi.MetricName);
                  });
                }
              });
              if (IIoption.length) {
                currentState.baseMonth = e;
                currentState.error.baseMonthError = '';
                currentState.error.showComparisonError = '';
                currentState.baseMonthApiResponse = IIValues;
                currentState.dropDownForII = IIoption;
                currentState.numberOfCategories = [
                  {
                    boi: '', baseMonth: '', id: '', MetricID: '',
                  },
                ];
                currentState.baseMontApiStatus = false;
                this.setState(currentState);
              } else {
                currentState.baseMonth = '';
                currentState.error.baseMonthError = t('Cross_M_A_Error_M6', {
                  apiMonth,
                });
                currentState.error.showComparisonError = '';
                currentState.baseMonthApiResponse = IIValues;
                currentState.dropDownForII = IIoption;
                currentState.baseMontApiStatus = false;
                this.setState(currentState);
              }
              axiosInstance
                .get(`${LocalApiBaseUrl}SOFilter/GetBusinessOutcomes`, {
                  params: {
                    OrgEntityID: dealFilter.Client,
                    LocationID: dealFilter.Locations,
                    MonthDT: apiMonth,
                    OfferingID: dealFilter.process,
                    LanguageID: store.getState().CurrentFilter.languageData.id,
                  },
                })
                .then((Innerresponse) => {
                  const innerIIoption = currentState.dropDownForII;
                  const innerIIValues = currentState.baseMonthApiResponse;
                  Innerresponse.data.forEach((each) => {
                    if (each.kpiData.length) {
                      each.kpiData.forEach((kpi) => {
                        if (
                          kpi.KPIMappingID === parseInt(kpiMappingId, 10)
                          && kpi.MetricValue
                        ) {
                          currentState.numberOfCategories = [
                            {
                              boi: kpi.MetricName,
                              baseMonth: kpi.MetricValue,
                              id: kpi.KPIMappingID,
                              MetricID: kpi.MetricID,
                            },
                            {
                              boi: '', baseMonth: '', id: '', MetricID: '',
                            },
                          ];
                          innerIIValues.push({
                            metricName: kpi.MetricName,
                            baseValue: kpi.MetricValue,
                            id: kpi.KPIMappingID,
                            MetricID: kpi.MetricID,
                          });
                          innerIIoption.push(kpi.MetricName);
                        }
                      });
                    }
                  });
                  if (innerIIoption.length) {
                    currentState.baseMonthApiResponse = innerIIValues;
                    currentState.dropDownForBo = innerIIoption;
                    currentState.baseMontApiStatus = false;

                    this.setState(currentState);
                  } else {
                    currentState.baseMonth = '';
                    currentState.error.baseMonthError = t('Cross_M_A_Error_M6', {
                      apiMonth,
                    });
                    currentState.error.showComparisonError = '';
                    currentState.baseMonthApiResponse = innerIIValues;
                    currentState.dropDownForBo = innerIIoption;
                    currentState.baseMontApiStatus = false;
                    this.setState(currentState);
                  }
                })
                .catch((error) => {
                  currentState.baseMonth = '';
                  currentState.error.baseMonthError = `${error.message}`;
                  currentState.error.showComparisonError = '';
                  currentState.baseMonthApiResponse = [];
                  currentState.dropDownForBo = [];
                  currentState.baseMontApiStatus = false;
                  this.setState(currentState);
                  trycatchAlertPopup(error);
                });
            })
            .catch((error) => {
              currentState.baseMonth = '';
              currentState.error.baseMonthError = `${error.message}`;
              currentState.error.showComparisonError = '';
              currentState.baseMonthApiResponse = [];
              currentState.dropDownForII = [];
              currentState.baseMontApiStatus = false;
              this.setState(currentState);
              trycatchAlertPopup(error);
            });
        } else {
          const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ];
          const selectedDate = new Date(e);
          const apiMonth = `${months[selectedDate.getMonth()]}-${selectedDate.getFullYear()}`;

          axiosInstance
            .get(`${LocalApiBaseUrl}SOFilter/GetBusinessOutcomes`, {
              params: {
                OrgEntityID: dealFilter.Client,
                LocationID: dealFilter.Locations,
                MonthDT: apiMonth,
                OfferingID: dealFilter.process,
                LanguageID: store.getState().CurrentFilter.languageData.id,
              },
            })
            .then((response) => {
              const BOoption = [];
              const BOValues = [];
              response.data.forEach((each) => {
                if (each.kpiData.length) {
                  each.kpiData.forEach((kpi) => {
                    if (kpi.KPIMappingID === parseInt(kpiMappingId, 10) && kpi.MetricValue) {
                      currentState.numberOfCategories = [
                        {
                          boi: kpi.MetricName,
                          baseMonth: kpi.MetricValue,
                          id: kpi.KPIMappingID,
                          MetricID: kpi.MetricID,
                        },
                        {
                          boi: '', baseMonth: '', id: '', MetricID: '',
                        },
                      ];
                      BOValues.push({
                        metricName: kpi.MetricName,
                        baseValue: kpi.MetricValue,
                        id: kpi.KPIMappingID,
                        MetricID: kpi.MetricID,
                      });
                      BOoption.push(kpi.MetricName);
                    } else {
                      BOValues.push({
                        metricName: kpi.MetricName,
                        baseValue: kpi.MetricValue,
                        id: kpi.KPIMappingID,
                        MetricID: kpi.MetricID,
                      });
                      BOoption.push(kpi.MetricName);
                    }
                  });
                }
              });
              if (BOoption.length) {
                currentState.baseMonth = e;
                currentState.error.baseMonthError = '';
                currentState.error.showComparisonError = '';
                currentState.baseMonthApiResponse = BOValues;
                currentState.dropDownForBo = BOoption;
                currentState.baseMontApiStatus = false;

                this.setState(currentState);
              } else {
                currentState.baseMonth = '';
                currentState.error.baseMonthError = t('Cross_M_A_Error_M6', {
                  apiMonth,
                });
                currentState.error.showComparisonError = '';
                currentState.baseMonthApiResponse = BOValues;
                currentState.dropDownForBo = BOoption;
                currentState.baseMontApiStatus = false;
                this.setState(currentState);
              }
            })
            .catch((error) => {
              currentState.baseMonth = '';
              currentState.error.baseMonthError = `${error.message}`;
              currentState.error.showComparisonError = '';
              currentState.baseMonthApiResponse = [];
              currentState.dropDownForBo = [];
              currentState.baseMontApiStatus = false;
              this.setState(currentState);
              trycatchAlertPopup(error);
            });
        }
      } else {
        currentState.baseMonth = '';
        currentState.error.baseMonthError = t('Cross_M_A_Error_M5');
        currentState.error.showComparisonError = '';
        currentState.baseMontApiStatus = false;
        this.setState(currentState);
      }
    } else {
      currentState.baseMonth = '';
      currentState.baseMontApiStatus = false;
      currentState.error.baseMonthError = t('Cross_M_A_Error_M4');
      currentState.error.showComparisonError = '';
      this.setState(currentState);
    }
  };

  validateForm = () => {
    const { period, baseMonth, numberOfCategories } = this.state;
    if (period.from && period.to && baseMonth) {
      const numberOfCategoriesCheck = numberOfCategories.filter((each) => {
        let returnValue = false;
        if (!each.id) {
          returnValue = true;
          return each;
        }
        return returnValue;
      });
      if (numberOfCategoriesCheck.length === 0) {
        return true;
      } return false;
    }
    return false;
  };

  onShowComparison = () => {
    const { t, dealFilter } = this.props;
    const { numberOfCategories, period } = this.state;
    if (this.validateForm()) {
      const currentState = this.state;
      currentState.baseMontApiStatus = true;
      this.setState(currentState);
      const selectedBOIS = [];
      numberOfCategories.forEach((each) => {
        selectedBOIS.push({
          KPIMappingID: each.id,
          MetricValue: each.baseMonth,
          MetricID: each.MetricID,
        });
      });
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      let fromMonth = new Date(period.from);
      const toMonth = new Date(period.to);
      const apiFormMonth = `${months[fromMonth.getMonth()]}-${fromMonth.getFullYear()}`;
      const apiToMonth = `${months[toMonth.getMonth()]}-${toMonth.getFullYear()}`;
      const data = {
        FiltersIDsVM: {
          OrgEntityID: dealFilter.Client,
          LocationID: dealFilter.Locations,
          MonthDT: dealFilter.Month,
          OfferingID: dealFilter.process,
        },
        From: apiFormMonth,
        To: apiToMonth,
        KpiData: selectedBOIS,
      };
      const mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const graphLables = [];
      for (let i = 0; i <= 5; i += 1) {
        const d1 = new Date(period.from);
        fromMonth = new Date(d1.setMonth(d1.getMonth() + i));

        graphLables.push(
          `${mS[fromMonth.getMonth()]} ${fromMonth.getFullYear().toString().substr(2, 2)}`,
        );
      }
      axiosInstance
        .post(`${LocalApiBaseUrl}Analytics/GetBusinessOutcomesData`, data, {
          params: { LanguageID: store.getState().CurrentFilter.languageData.id },
        })
        .then((response) => {
          if (response.data.length) {
            const graphDatasets = [];
            const actualValues = [];
            const colors = ['#ff8888', '#f5a365', '#eae116', '#c886d5', '#3bbaba'];
            const uniqueMetricID = [...new Set(response.data.map((item) => item.MetricID))];
            uniqueMetricID.forEach((val, index) => {
              actualValues.push({ data: [null, null, null, null, null, null, null] });
              graphDatasets.push({
                label: '',
                data: [null, null, null, null, null, null, null],
                fill: false,
                lineTension: 0.1,
                backgroundColor: colors[index],
                borderColor: colors[index],
                borderCapStyle: colors[index],
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                // eslint-disable-next-line no-dupe-keys
                lineTension: 0,
                datalabels: {
                  color: colors[index],
                  align: 'end',
                  anchor: 'end',
                  offset: -6,
                },
                lineThickness: 5,
              });
              response.data.forEach((each) => {
                if (each.MetricID === val) {
                  const currentIndex = uniqueMetricID.indexOf(val);
                  const d1 = new Date(each.Month);
                  const dataIndex = graphLables.indexOf(
                    `${mS[d1.getMonth()]} ${d1.getFullYear().toString().substr(2, 2)}`,
                  );
                  graphDatasets[currentIndex].data[dataIndex] = each.CalculatedValue;
                  actualValues[currentIndex].data[dataIndex] = each.MetricValue;
                  graphDatasets[currentIndex].label = each.MetricName;
                }
              });
            });
            this.setState({
              graphData: { labels: graphLables, datasets: graphDatasets },
              baseMontApiStatus: false,
              showComparison: false,
              showGraph: true,
              actualValues,
            });
          } else {
            const { error } = this.state;
            const currenterror = error;
            currenterror.showComparisonError = t('Cross_M_A_Error_M3');
            this.setState({ error: currenterror, baseMontApiStatus: false });
          }
        })
        .catch((error1) => {
          const { error } = this.state;
          const currenterror = error;
          currenterror.showComparisonError = t('Cross_M_A_Error_M2');
          this.setState({ error: currenterror, baseMontApiStatus: false });
          trycatchAlertPopup(error1);
        });
    } else {
      const { error } = this.state;
      const currenterror = error;
      currenterror.showComparisonError = t('Cross_M_A_Error_M1');
      this.setState({ error: currenterror });
    }
  };

  onManageSelection = () => {
    this.setState({ showComparison: true, showGraph: false });
  };

  render() {
    const { t, kpiName, kpiMappingId } = this.props;
    const {
      category,
      period,
      baseMonth,
      error,
      showComparison,
      baseMontApiStatus,
      numberOfCategories,
      graphData,
      showGraph,
      baseMonthApiResponse,
      actualValues: actualValuesFromState,
    } = this.state;
    return (
      <>
        {showComparison ? (
          <LoadingOverlay
            fadeSpeed={0}
            spinner={<SynopsLoader />}
            active={baseMontApiStatus}
            // spinner
            //  text={this.props.t("Please_wait")}
          >
            <div className="form-section">
              <small className="form-text text-muted">
                {t('Cross_metric_analysis_m1', { metricname: kpiName })}
                .
              </small>
              <small className="form-text text-muted">
                {t('Cross_metric_analysis_m2')}
              </small>
              <div className="row mt-3">
                <div className="form-group col-lg-4">
                  <label htmlFor="category">
                    {t('Category')}
                    <span className="required">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="category"
                    value={category}
                    onChange={this.onCategoryChange}
                  >
                    <option value="Business Outcomes">{t('Business_Outcomes')}</option>
                    <option value="Insights & Intelligence">
                      {t('Insights')}
                      {' '}
                      &
                      {' '}
                      {t('Intelligence')}
                    </option>
                  </select>
                </div>
                <div className="form-group col-lg-4">
                  <label id="period" htmlFor="period">
                    {t('Period')}
                    <span className="required">*</span>
                  </label>
                  <div className="form-row">
                    <div className="col block-picker">
                      <DatePicker
                        className="form-control"
                        selected={period.from}
                        id="StartDate"
                        onSelect={(e) => { this.onPeriodChange('From', e); }}
                        value={period.from}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                      />
                    </div>
                    <div className="col block-picker">
                      <DatePicker
                        className="form-control"
                        selected={period.to}
                        id="EndDate"
                        onSelect={(e) => this.onPeriodChange('To', e)}
                        value={period.to}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-lg-4 block-picker">
                  <label htmlFor="basemonth" id="Base_Month">
                    {t('Base_Month')}
                    <span className="required">*</span>
                    {' '}
                    <span className="baseHover">
                      <FontAwesomeIcon icon={faInfoCircle} />
                      <span>Base month is required to normalize the metric values</span>
                    </span>
                  </label>
                  <DatePicker
                    className="form-control"
                    selected={baseMonth}
                    value={baseMonth}
                    id="baseMonth"
                    onSelect={this.onBaseMonthChange}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                  />
                </div>
              </div>
              {error.from || error.to || error.baseMonthError ? (
                <>
                  {error.from ? (
                    <div className="errorMsg">{error.from}</div>
                  ) : null}
                  {error.to ? (
                    <div className="errorMsg">{error.to}</div>
                  ) : null}
                  {error.baseMonthError ? (
                    <div className="errorMsg">{error.baseMonthError}</div>
                  ) : null}
                </>
              ) : null}
              <div className="bo-outcome-value-wrapper">
                {numberOfCategories.map((val, index) => (
                  <div className="row">
                    <div className="form-group col-lg-5">
                      <label htmlFor="BusinessOutcomeIndicator">
                        {t('Business_Outcome_Indicator')}
                        <span className="required">*</span>
                      </label>
                      {kpiMappingId === val.id ? (
                        <select
                          className="form-control"
                          onChange={this.onBoiOptionChange.bind(this, index)}
                          disabled
                          value={val.id}
                        >
                          <option value="">{t('Select_BOI')}</option>
                          {baseMonthApiResponse.length
                            ? baseMonthApiResponse.map((each) => <option value={each.id}>{each.metricName}</option>)
                            : null}
                        </select>
                      ) : (
                        <select
                          className="form-control"
                          onChange={this.onBoiOptionChange.bind(this, index)}
                          value={val.id}
                        >
                          <option value="">{t('Select_BOI')}</option>
                          {baseMonthApiResponse.length
                            ? baseMonthApiResponse.map((each) => <option value={each.id}>{each.metricName}</option>)
                            : null}
                        </select>
                      )}
                    </div>
                    <div className="form-group col-lg-5">
                      <label htmlFor="baseMonthValue" id="Base_Month_value">
                        {t('Base_Month_Value')}
                        <span className="required">*</span>
                        {' '}
                        <span className="baseHover">
                          <FontAwesomeIcon icon={faInfoCircle} />
                          <span>Metric value for selected base month</span>
                        </span>
                      </label>
                      <input type="text" className="form-control" value={val.baseMonth} disabled />
                    </div>

                    <div className="form-group col-lg-2">
                      <label className="label-adjust margin-labeladjust">
                        {index >= 1 ? (
                          <span className="text-danger mr-3">
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              onClick={() => this.deleteBOI(index)}
                            />
                          </span>
                        ) : null}
                        {numberOfCategories.length === index + 1 && index + 1 !== 5 ? (
                          <span>
                            <FontAwesomeIcon icon={faPlus} onClick={() => this.addBOI(this, 1)} />
                          </span>
                        ) : null}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              {error.boiSelection ? (
                <div className="errorMsg">{error.boiSelection}</div>
              ) : null}

              <div className="row">
                <div className="col-md-12">
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm"
                    onClick={this.onShowComparison}
                  >
                    {t('Show_Comparison')}
                  </button>
                  {error.showComparisonError ? (
                    <span className="errorMsg">{error.showComparisonError}</span>
                  ) : null}
                </div>
              </div>
            </div>
          </LoadingOverlay>
        ) : null}
        {showGraph ? (
          <LoadingOverlay
            fadeSpeed={0}
            spinner={<SynopsLoader />}
            active={baseMontApiStatus}
            // spinner
            // text={this.props.t("Please_wait")}
          >
            <div className="row">
              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mb-3"
                  onClick={this.onManageSelection}
                >
                  {t('Manage Selection')}
                </button>
                <Line
                  data={graphData}
                  redraw="true"
                  height="100vh"
                  options={{
                    spanGaps: true,
                    layout: {
                      padding: {
                        top: 0,
                        left: 40,
                        right: 40,
                        bottom: 10,
                      },
                    },
                    plugins: {
                      datalabels: {
                        formatter(value, context) {
                          const actualValues = [...actualValuesFromState];
                          return actualValues[context.datasetIndex].data[context.dataIndex];
                        },
                      },
                    },

                    // maintainAspectRatio: false,
                    responsive: true,
                    tooltips: {
                      mode: 'index',
                      intersect: true,
                    },
                    hover: {
                      mode: 'index',
                      intersect: true,
                    },
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            precision: 1,
                            display: false,
                          },
                          gridLines: {
                            drawBorder: false,
                            drawOnChartArea: false,
                          },

                          scaleLabel: {
                            display: false,
                          },
                        },
                      ],
                      xAxes: [
                        {
                          gridLines: {
                            drawOnChartArea: false,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: t('Period'),
                          },
                        },
                      ],
                    },
                    legend: {
                      display: true,
                      align: 'start',
                      // position:"right",
                      // align:"center"
                    },
                    // eslint-disable-next-line no-dupe-keys
                    tooltips: {
                      enabled: true,

                      callbacks: {
                        label(tooltipItems, data) {
                          const currentLable = data.datasets[tooltipItems.datasetIndex].label;
                          const actualValues = [...actualValuesFromState];
                          return (
                            `${currentLable
                            }:${
                              actualValues[tooltipItems.datasetIndex].data[tooltipItems.index]}`
                          );
                        },
                      },
                    },
                  }}
                  plugins={{
                    beforeInit: (chart) => {
                      const fitValue = chart.legend.fit;
                      // eslint-disable-next-line no-param-reassign
                      chart.legend.fit = function fit() {
                        fitValue.bind(chart.legend)();
                        this.height -= 40;
                        return this.height;
                      };
                    },
                  }}
                />
              </div>
            </div>
            {' '}
          </LoadingOverlay>
        ) : null}
      </>
    );
  }
}
CrossMetricAnalysis.propTypes = {
  kpiName: PropTypes.string.isRequired,
  t: PropTypes.string.isRequired,
  dealFilter: PropTypes.object.isRequired,
  kpiMappingId: PropTypes.number.isRequired,
};
export default withTranslation()(CrossMetricAnalysis);
