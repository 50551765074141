import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import classNames from "classnames";
import { Modal } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import i18n from "i18next";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import $ from "jquery";
import parse from 'react-html-parser';

// IMPORT CUSTOM COMPONENTS/FUNCTIONS
import { createLinksFromText } from "../Shared/commonFunctions";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { LocalApiBaseUrl } from "../Shared/Constant";

axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


function processStringForComparison(str) {
  // REPLACE APOSTROPHE CHARACTER `’` WITH SINGLE QUOTE `'`
  let replacedStr = str.replace(/[’]/g, "'");
  return replacedStr;
}

class FAQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      searchText: "",
      uniqueCategories: [],
      currentlySelectedCategory: "",
      filteredResults: [],
      accordionActiveKey: "",
      isAPILoading: ""
    };
    this.accordionRef = React.createRef();
  }

  componentDidMount() {
    const { onClose, languageId, intl } = this.props;

    this.setState({ isAPILoading: true });

    // FETCH THE DATA FROM THE API
    axiosInstance
      .get(`${LocalApiBaseUrl}Bot/GetFAQAnswer`, {
        params: { LanguageID: languageId }
      })
      .then(response => {
        const responseData = response.data;
        const uniqueCategories = _.map(responseData, eachRecord =>
          _.upperFirst(eachRecord.Category)
        ).filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

        const currentlySelectedCategory = _.first(uniqueCategories);
        this.setState(
          {
            isAPILoading: false,
            data: _.map(responseData, eachRecord => {
              return {
                ...eachRecord,
                //answer: createLinksFromText(eachRecord.Response)
                answer: eachRecord.Response
              };
            }),
            uniqueCategories,
            currentlySelectedCategory
          },
          () => {
            this.handleClickCategory(currentlySelectedCategory);
          }
        );
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        const errMsg = i18n.t("FAQ_API_Error_Msg");

        this.setState({ isAPILoading: false }, () => {
          alert(errMsg + error);
          onClose();
        });
      });
  }

  handleChangeSearchText = e => {
    e.preventDefault();
    const searchTextVal = e.target.value;
    this.setState({ searchText: searchTextVal }, () => {
      this.filterResults();
    });
  };

  // WILL BE EXECUTED WHEN THE CATEGORY (IN LEFT SECTION) WOULD BE CLICKED
  // WOULD SET THE VALUE OF THE `currentlySelectedCategory` IN THE STATE AND FILTER THE FAQs THAT BELONGS TO THE SELECTED CATEGORY
  handleClickCategory = category => {
    this.setState(
      {
        currentlySelectedCategory: _.toLower(category)
      },
      () => {
        this.filterResults();
      }
    );
  };

  // FILTER THE RECORDS. WILL BE EXECUTED WHEN THE CATEGORY WOULD BE SELECTED OR SEARCH TEXT WOULD BE ENTERED
  filterResults = () => {
    const { data, searchText, currentlySelectedCategory } = this.state;
    let filteredResults = _.cloneDeep(data);
    let accordionActiveKey = "";

    // IF `searchText` IS NOT EMPTY, THEN FILTER THE DATA BASED ON THE SEARCHED PHRASE
    if (!_.isEmpty(searchText)) {
      accordionActiveKey = "";
      filteredResults = _.filter(
        data,
        eachItem =>
          _.toLower(
            processStringForComparison(eachItem.MasterQuestions)
          ).indexOf(_.toLower(processStringForComparison(searchText))) !== -1 ||
          _.toLower(processStringForComparison(eachItem.Response)).indexOf(
            _.toLower(processStringForComparison(searchText))
          ) !== -1
      );
    } else if (!_.isEmpty(currentlySelectedCategory)) {
      // IF `currentlySelectedCategory` IS NOT EMPTY, THEN FILTER THE DATA BASED ON THE SELECTED CATEGORY
      filteredResults = _.filter(
        data,
        eachItem =>
          _.toLower(eachItem.Category) === _.toLower(currentlySelectedCategory)
      );
    }

    this.setState({
      filteredResults,
      accordionActiveKey
    }, () => {
      // WHEN THE FAQs ARE FILTERED, RESET THE SCROLL BAR POSITION OF THE SEARCH RESULTS SECTION 
      $(this.accordionRef.current).animate({ scrollTop: 0 }, "slow");
    });
  };

  // WOULD BE EXECUTED WHEN THE MODAL WOULD HIDE
  handleHide = () => {
    const { onClose } = this.props;
    onClose();
  };

  // WOULD BE EXECUTED WHEN THE ACCORDION HEADER WOULD BE CLICKED. THIS WOULD TOGGLE THE OPEN/CLOSE OF "ANSWER" SECTION
  toggleAccordionActiveKey = currentEventKey => {
    const { accordionActiveKey } = this.state;
    const newAccordionActiveKey =
      currentEventKey === accordionActiveKey ? "" : currentEventKey;
    this.setState({
      accordionActiveKey: newAccordionActiveKey
    });
  };

  render() {
    const { intl } = this.props;
    const {
      data,
      searchText,
      uniqueCategories,
      currentlySelectedCategory,
      filteredResults,
      accordionActiveKey,
      isAPILoading
    } = this.state;
    let faqs = !_.isEmpty(filteredResults) ? filteredResults : data;
    if (!_.isEmpty(searchText)) {
      faqs = filteredResults;
    }

    // IF API IS LOADING THEN SHOW THE LOADER
    if (isAPILoading) {
      return (
        <LoadingOverlay
          fadeSpeed={0}
          spinner={<SynopsLoader />}
          active
        // text={i18n.t("Please_wait") + '...'}
        />
      );
    }

    return (
      <Modal
        show
        size="lg"
        centered
        aria-labelledby="faqPopCenterTitle"
        onHide={() => this.handleHide()}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {i18n.t("FAQ_Title")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="form-section">
            <div className="row">
              <div className="form-group col-lg-12 pr-2">
                <input
                  type="search"
                  name="searchtext"
                  value={searchText}
                  onChange={e => this.handleChangeSearchText(e)}
                  maxLength="108"
                  className="form-control"
                  placeholder={i18n.t("FAQ_Search_Input_Placeholder")}
                />
              </div>
              {/* START - TAGS */}
              {/* <div className="col-lg-12">
                  <div className="radius-btn mb-5">
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                    >
                      Order to Cash
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                    >
                      Procurement
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                    >
                      Maturity Phase
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                    >
                      Boi Metric
                    </button>
                  </div>
                </div> */}
              {/* END - TAGS */}
            </div>
          </div>

          <div className="faqContainer">
            <div className="row mt-4">
              {/* START - FAQ CATEGORIES */}
              {_.isEmpty(searchText) && (
                <div className="col-lg-4">
                  <div className="main-sidebar">
                    <ul>
                      {_.map(uniqueCategories, (eachCategory, index) => {
                        return (
                          <li key={index}>
                            <a
                              href="javascript:void(0)"
                              onClick={() =>
                                this.handleClickCategory(eachCategory)
                              }
                              className={classNames("tab-link", {
                                active:
                                  _.toLower(eachCategory) ===
                                  _.toLower(currentlySelectedCategory)
                              })}
                            >
                              <span>{eachCategory}</span>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
              {/* END - FAQ CATEGORIES */}

              <div className="col pr-2">
                {!_.isEmpty(searchText) && _.isEmpty(faqs) && (
                  <h2 className="ht-title mt-2">{i18n.t("FAQ_No_Results_Found")}
                  </h2>
                )}
                {!_.isEmpty(searchText) && !_.isEmpty(faqs) && (
                  <h2 className="ht-title mt-2">{i18n.t("FAQ_Search_Results")}
                  </h2>
                )}

                {/* START - FAQ ACCORDION */}
                {!_.isEmpty(faqs) && (
                  <Accordion
                    id="accordion"
                    className="faqs-accordion"
                    activeKey={accordionActiveKey}
                    ref={this.accordionRef}
                  >
                    {_.map(faqs, (eachFaq, index) => {
                      const accEventKey = String(index);
                      return (
                        <Card key={eachFaq.FAQId}>
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey={accEventKey}
                            onClick={() =>
                              this.toggleAccordionActiveKey(accEventKey)
                            }
                          >
                            <h5 className="mb-0">
                              {_.upperFirst(eachFaq.MasterQuestions)}
                            </h5>
                            <button
                              className={classNames("btn", "collapseTab", {
                                collapsed: accEventKey !== accordionActiveKey
                              })}
                            />
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={accEventKey}>
                            <Card.Body
                              className="pt-1"
                            >{eachFaq.answer && typeof eachFaq.answer === 'string' ? parse(eachFaq.answer) : null}</Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      );
                    })}
                  </Accordion>
                )}
                {/* END - FAQ ACCORDION */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

FAQ.propTypes = {
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    languageId: state.CurrentFilter.languageData.id
  };
};

export default connect(mapStateToProps)(FAQ);
