import React from "react";
import ReactTooltip from "react-tooltip";
import TrendGraphs from "../Analytics/TrendGraphs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import i18n from "i18next";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { store } from "../reducers/configureStore";
import BO2_popup from "../Configurator/BO2_popup";
import { LocalApiBaseUrl, regexForNumbersOnlyMTC ,regexForNegativeNumbers} from "../Shared/Constant";
import MetricComments from "../Dashboard/MetricComments";
import { connect } from "react-redux";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant"; 
import { ValueUnit, ValueUnitNoRoundOff, MetricValueUnitFormat, toolThreshold, CommonMetriTileFix } from "../Shared/Common";
import $ from 'jquery';
//import BOMetricDetails from "../Dashboard/BOMetricDetails";
import BOMetricDetails from "../Configurator/BOMetricDetails";
class MetricView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			viewGraph: false,
			eachDetails: this.props.data,
			boData: {
				SOCategoryID: 0,
				kpiData: [
					//metDataAttr:{},
				],
			},
			commentPopUp: false,
		};
	}

	componentDidMount() {
    // ADDED THIS TO RESOLVE "THRESHOLD TOOLTIP" HOVER ISSUE AFTER ADDING NEW METRIC IN CONFIGURATOR
    CommonMetriTileFix();
    ReactTooltip.rebuild();
  }

	onClose = (e) => {
		this.setState({ viewmore: false });
	};

	deleteKpi(data, e) {
    e.preventDefault();
		if (window.confirm("Are you sure you want to delete metric detail?")) {
			const boData = this.state.boData;
      
			boData.SOCategoryID = this.props.SOCategoryID;
			// e.stopPropagation();

			boData.kpiData = data;
			this.setState({
				boData: boData,
			});
			var ConfigurationDealMetricID =boData.kpiData.ConfigurationDealMetricID

			axiosInstance.delete(`${LocalApiBaseUrl}Configurator/DeleteDealMetrics`,{
				params :{
					DealMetricsId:ConfigurationDealMetricID
				}
		}) 
            .then((response) => {
             if(response.data.IsSuccess){
				axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetBusinessOutcomesQualityScore`,{params:{LocationID : this.props.filterDetails.Locations,
                    OrgEntityID : this.props.filterDetails.Client,
                    OfferingID : this.props.filterDetails.process,
                    MonthDT : this.props.filterDetails.Month,
                    LanguageID: store.getState().CurrentFilter.languageData.id
                }}
            ) 
            .then((response) => {
             if(response.data){
                store.dispatch({
                    type: "SAVE_QC_SCORE_OF_EACH_TAB",
                    payload: response.data
                });
             }
             
            
            }).catch((error)=>{
trycatchAlertPopup(error);
                
            });
				store.dispatch({
					type: "DELETE_BO",
					payload: this.state.boData,
				});
             }
           
            }).catch((error)=>{
trycatchAlertPopup(error);
                
            });







			// store.dispatch({
			// 	type: "DELETE_BO",
			// 	payload: this.state.boData,
			// });
			// }
		}
    
	}
	onCommentClick = (val) => {
		if (val === 1) {
			this.setState({
				commentPopUp: true,
			});
		} else {
			this.setState({
				commentPopUp: false,
			});
		}
	};
	dealpopup = (data, e) => {
		e.preventDefault();

		this.setState({
			data: data,
		});

		this.setState({
			viewmore: true,
		});

		e.preventDefault();
	};
//   MetricValueUnitFormat =(Unitvalue,AttributeValueDigit)=>{
//     Unitvalue=Unitvalue.trim();
//     let returnValue="";
//     if(AttributeValueDigit!=null || AttributeValueDigit<=0){
//       if((Unitvalue === "$" || Unitvalue === "₹" || Unitvalue === "R$" || Unitvalue === "€" ||Unitvalue === "£" || Unitvalue === "SGD")){
//         returnValue= <> <span>  {Unitvalue}</span> {(AttributeValueDigit!=null ? AttributeValueDigit:"")}</>

//       }else if(Unitvalue === "$ Mn" || Unitvalue === "₹ Mn"||Unitvalue === "R$ Mn" || Unitvalue === "€$ Mn"||Unitvalue === "SGD Mn")
//       {
//         returnValue=<> <span>{Unitvalue.split(" ")[0]}</span>  {(AttributeValueDigit!=null ? AttributeValueDigit:"")} <span>{" Mn"}</span></>
//       }else{
//         returnValue=<>{AttributeValueDigit} <span>{Unitvalue}</span></>
//       }

//     }else {
//       returnValue = (
//         Unitvalue === "$" ? 
//         <><span>{Unitvalue + "&nbsp;"}</span><>{AttributeValueDigit != null ? {AttributeValueDigit}:null}</></> : 
//         <>{AttributeValueDigit!=null ? <>{AttributeValueDigit}</>:null}<span>{Unitvalue + "&nbsp;"}</span></>)
//     }
//     return returnValue;
    
// }


	render() {

    let eachDetails = this.props.data;
		const options = {
      loop: true,
      margin: 24,
      responsiveClass: "",
      dots: false,
      responsive: {
        0: {
          items: 1,
          nav: true
        },
        600: {
          items: 2,
          nav: false
        },
        1000: {
          items: 3,
          nav: true,
          loop: false
        },
        1440: {
          items: 4,
          nav: true,
          loop: false
        },
        1600: {
          items: 4,
          nav: true,
          loop: false
        },
        1800: {
          items: 5,
          nav: true,
          loop: false
        }
      }
    };
    
    
		let AttrValue = "";
		let metricName = "";
		let unit = "";
		let metricData = "";
		let Description = "";
		let leadingIndustryValue = "";
		let similarIndustryValue = "";
		let ValueBlock = true;
		let RelatorBlock = this.props.isRelatorBlock;
    let middleAlign = "";
		//let RelatorBlock = this.props.selectedOfferingName==="Procurement" ? false:true;
		//let ToolTip = "";
    let tilecardtooltip = "";
    let MetricValueUnitFormatData = "";

		metricName = eachDetails.MetricName;
		unit = eachDetails.MetricUnit;
		unit=unit.trim();
   
		if (eachDetails.metDataAttr != null) {
			if (eachDetails.metDataAttr && eachDetails.metDataAttr.length > 0) {
				leadingIndustryValue = eachDetails.metDataAttr[0] ? eachDetails.metDataAttr[0].AttributeValue : "";
				similarIndustryValue = eachDetails.metDataAttr[1] ? eachDetails.metDataAttr[1].AttributeValue : "";

				//For old data which has Attribute name and attribute value as empty strings/ null
        if(eachDetails.metDataAttr[0]){
				eachDetails.metDataAttr[0].AttributeName =
					this.props.attributes && this.props.attributes.length > 0
						?  this.props.attributes.filter(z => z.ApplicableForDD === false).length>0 ?
               this.props.attributes.filter(z => z.ApplicableForDD === false)[0].AttributeName1
               :""
						: eachDetails.metDataAttr[0].AttributeName;
        }

        if(eachDetails.metDataAttr[1]){
				eachDetails.metDataAttr[1].AttributeName =
					this.props.attributes && this.props.attributes.length > 0
						?  this.props.attributes.filter(z => z.ApplicableForDD === false).length > 0 ?
              this.props.attributes.filter(z => z.ApplicableForDD === false)[0].AttributeName2
              :""
						: eachDetails.metDataAttr[1].AttributeName;
        }
			}

			let mappingId = eachDetails.KPIMappingID;
		}
		//ToolTip='Lower Threshold:'+(eachDetails.KpiMetrics.LowerThreshold||'NA')+' , Upper Threshold:'+(eachDetails.KpiMetrics.UpperThreshold||'NA')
		//}
		let statusColor = "item gray-color";
		//let info = "";

		/* if (eachDetails != undefined) {
       ToolTip =
       i18n.t("Lower_Threshold")+":" +
         (eachDetails.LowerThreshold || "NA") +
         " ,"+i18n.t("Upper_Threshold")+ ":" +
         (eachDetails.UpperThreshold || "NA"); */
		if (eachDetails != undefined) {
      // if(eachDetails.LowerThreshold!=null && eachDetails.UpperThreshold !=null ){
        
      //   ToolTip =i18n.t("Lower Threshold") +	":" + (eachDetails.LowerThreshold) +	", " + i18n.t("Upper Threshold") +	":" +(eachDetails.UpperThreshold);
      // }else if(eachDetails.LowerThreshold!=null && eachDetails.UpperThreshold ===null)
      // {
      //   ToolTip =i18n.t("Lower Threshold") +	":" + (eachDetails.LowerThreshold || i18n.t("NA"))
      // }else if(eachDetails.LowerThreshold ===null && eachDetails.UpperThreshold !=null){
      //   ToolTip = i18n.t("Upper Threshold") +	":" +(eachDetails.UpperThreshold || i18n.t("NA"))
      // }

			// ToolTip =
			// 	i18n.t("Lower Threshold") +
			// 	":" +
			// 	(eachDetails.LowerThreshold || i18n.t("NA")) +
			// 	" ," +
			// 	i18n.t("Upper Threshold") +
			// 	":" +
			// 	(eachDetails.UpperThreshold || i18n.t("NA"));
      if(unit.includes('$') || unit.includes('€') || unit.includes('£') || unit.includes('₹') || unit.includes('SGD') || unit.includes('R$')) {
        metricData=ValueUnitNoRoundOff(eachDetails.MetricValue);
      } 
      else {
        metricData=ValueUnit(eachDetails.MetricValue);
      }
      
      MetricValueUnitFormatData = MetricValueUnitFormat(unit,metricData);
      middleAlign =(!leadingIndustryValue || leadingIndustryValue.toUpperCase() == "NA") && (!similarIndustryValue || similarIndustryValue.toUpperCase() == "NA") || !RelatorBlock?
            ' middle-align':'';
			// Description =
			//   eachDetails.MetricDataDesp === ""
			//     ? eachDetails.KPIDesp
			//     : eachDetails.MetricDataDesp;

			Description = eachDetails.KPIDesp ? eachDetails.KPIDesp : "";
			//Anup - 15May2020 - Instead of just comments send the complete data
			//info = eachDetails.Comments ? eachDetails.Comments : "NA";

      if (eachDetails.RAGStatus != undefined && eachDetails.RAGStatus != null) {
        switch (eachDetails.RAGStatus.toLowerCase()) {
          case "grey":
            statusColor = "item gray-color";
            break;
          case "red":
            statusColor = "item red-color";
            break;
          case "orange":
            statusColor = "item amber-color";
            break;
          case "green":
            statusColor = "item green-color";
            break;
          case "amber":
            statusColor = "item amber-color";
            break;
          default:
          // code block
        }
      }
    }
    // const formatter=new Intl.NumberFormat('en',{ //CODE FOR THE CURRENCY FORMAT
    //     style:'currency',
    //     currency:'USD',
    //     notation:'standard'
    // })
    const formattertwo=new Intl.NumberFormat('en',{
      useGrouping:true
    })
    return (
      <div className={`${statusColor}`} data-place="bottom" data-tip={toolThreshold(eachDetails)}>
        {/* <div className="custom-tilecard">
        <div className="tilecard-wrapper"> */}

        {/* <div className={`${statusColor}`} data-place="bottom"> */}
          <div className={"item-left"} >
            {ValueBlock ? (
              <>
                <p className="tile-metric-value">
                {/* {this.MetricValueUnitFormat(unit,metricData) }  */}
                 {MetricValueUnitFormatData}
                </p>
              </>
            ) : null}
            <p className="tile-metric-desc">
              <span data-tip={tilecardtooltip} data-for={'customTooltip' + eachDetails.KPIMappingID}>
                {/* {metricName.length > 91 ? metricName.slice(0, 91) + "..." : metricName} */}
                {metricName}
                </span>
              {/* {metricName.length  > 91 &&
              <ReactTooltip place="right" effect="solid" id={'customTooltip' + eachDetails.KPIMappingID} className="tilecard-tooltip">
                                <span>{metricName}</span>
                            </ReactTooltip>
         } */}
            </p>
          </div>
          
          <div className={RelatorBlock?'item-right isRelatorBlock'+middleAlign:'item-right'+middleAlign}>
          
            {Description ? (
              <p className="tile-metric-name" data-tip data-for={'customTooltip_desc' + eachDetails.KPIMappingID}>
              {/* {Description.length > 25 ? Description.slice(0, 25) + "..." : Description}   */}
                  {Description}
              {/* {Description.length > 25 && 
              <ReactTooltip place="bottom" effect="solid" id={'customTooltip_desc' + eachDetails.KPIMappingID} className="tilecard-tooltip tilecardname-tooltip">
                <span>{Description}</span>
              </ReactTooltip>}                         */}
            </p>
            ) : (
              <p className="tile-metric-name">&nbsp;</p>
            )}
            
            {/* <div className="metric-lead-lagg">
              <p>
                <span>{BenchmarkName[0]}</span>
                <span className="lead-lagg-val">{BenchmarkValue[0]}</span>
              </p>
              <p>
                <span>{BenchmarkName[1]}</span>
                <span className="lead-lagg-val">{BenchmarkValue[1]}</span>
              </p>
            </div>*/}
            
                {RelatorBlock && (
              <div className="metric-lead-lagg">
                

                {leadingIndustryValue &&
                  leadingIndustryValue.toUpperCase() !== "NA" && (
                    <p>
                        <span className="lead-lagg-label">{`${this.props.attributes.filter(z => z.ApplicableForDD === false).length>0 ?
                        this.props.attributes.filter(z => z.ApplicableForDD === false)[0].AttributeName1 :""}`}</span>
                        <span className="lead-lagg-val">{`${leadingIndustryValue}`}</span>
                    </p>
                  )}

                {similarIndustryValue &&
                  similarIndustryValue.toUpperCase() !== "NA" && (
                    <p>
                      <span className="lead-lagg-label">{`${this.props.attributes.filter(z => z.ApplicableForDD === false).length>0 ?
                                                        this.props.attributes.filter(z => z.ApplicableForDD === false)[0].AttributeName2 : ""}`}</span>
                      <span className="lead-lagg-val">{`${similarIndustryValue}`}</span>
                    </p>
                  )}
              </div>
            )}
          </div>
          
          {this.state.commentPopUp ? (
            <MetricComments
              //kpiName={metricName}
              isRelatorBlock={this.props.isRelatorBlock}
              info={eachDetails}
              show={this.state.commentPopUp}
              onClose={this.onCommentClick.bind(this, 2)}
            />
          ) : null}
          <div className="dropdown dropleft ondropOpenColor" >
            
            <span type="button" className="dropdown-toggle" data-toggle="dropdown"></span>
            <div className="dropdown-menu">
              <a href="javascript:void(0)" className="dropdown-item" onClick={this.onCommentClick.bind(this, 1)}>
                <span className="info-icon"></span>{i18n.t("Info")}
              </a>
              {(this.props.RecordStatus && this.props.RecordStatus[0] === "Publish") ||
              (this.props.RecordStatus &&
                this.props.RecordStatus[1] === "True" &&
                !this.props.isManintencneWindowOpen) ||
              (this.props.RecordStatus &&
                this.props.RecordStatus[0] === "Publish" &&
                !this.props.isManintencneWindowOpen) ||
              this.props.roleReducers.filter((each) => {
                if (each.FeatureName === "Configurator" && each.Operations.length) {
                  return each;
                }
              }).length === 0 ||
              (this.props.RecordStatus &&
                this.props.RecordStatus[0] === "Save" &&
                this.props.roleReducers.filter((each) => {
                  if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Submit").length) {
                    return each;
                  }
                }).length === 0) ||
              (this.props.RecordStatus &&
                this.props.RecordStatus[0] === "Submit" &&
                this.props.roleReducers.filter((each) => {
                  if (each.FeatureName === "Configurator" && each.Operations.filter((op) => op === "Publish").length) {
                    return each;
                  }
                }).length === 0) ? (
                ""
              ) : (
                <>
                  <a
                    href="javascript:void(0)"
                    className="dropdown-item"
                    onClick={this.deleteKpi.bind(this, eachDetails)}
                  >
                    <span className="delete-icon"></span>{i18n.t("Remove")}
                  </a>
                  <a
                    href="javascript:void(0)"
                    className="dropdown-item"
                    onClick={this.dealpopup.bind(this, eachDetails)}
                  >
                    <span className="edit-icon"></span>{i18n.t("Edit")}
                  </a>
                </>
              )}
            </div>
          </div>
        {/* </div> */}

        {this.state.viewmore ? (
          <BO2_popup
            SOCategoryID={this.props.SOCategoryID}
            isRelatorBlock={this.props.isRelatorBlock}
            selectedOfferingName={this.props.selectedOfferingName}
            type={this.props.SOCategoryName}
            kpiData={this.props.data}
            show={this.state.viewmore}
            onClose={this.onClose.bind(this)}
            opsgrp={this.props.opsgrp}
            filterDetails={this.props.filterDetails}
            attributes={this.props.attributes}
          />
        ) : null}
      </div>
    );
	}
}
const mapStateToProps = (state) => {
	return { 
	  roleReducers: state.roleReducers.roleReducers
	};
  };
  
  
  export default connect(mapStateToProps,"")(MetricView);