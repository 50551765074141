import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import backspace from "../Images/backspace-light.svg";

//Build For UAT Deployment
//QB Changes
export default class KeyPadPanelComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isCalculatorDropdownStatus: true,
            selectedValue: '',
        }
    }
    
    isCalculatorDropdown = (e) => {
        e.stopPropagation();
        this.state.isCalculatorDropdownStatus ?
        this.setState({ isCalculatorDropdownStatus: false}): this.setState({ isCalculatorDropdownStatus: true})
    }


    handleBtnClick = (btn) => {
        this.props.onKeyPadClick(btn);
        this.setState({
            selectedValue: btn
        })

    }

    render() {


        let { result } = this.props;
        return (
            <div className="form-section">
                {/* <div className="form-group border-group">
                <label htmlFor="" className='form-label'></label>
                <div className="calcResult">
                <input type='text' className='form-control' disabled = {true} value={result} ></input>
                <i class="far fa-times-circle calcClose" onClick={e => this.props.backspace(e.target.name)}></i>
                </div>
                 </div>   */}
                <div className={this.state.isCalculatorDropdownStatus?'calculator-dropdown open':'calculator-dropdown'}>
                    <div className="calculator-input">{result} <div>
                    <i class="fas fa-chevron-down" onClick={(e)=> this.isCalculatorDropdown(e)}></i></div></div>
                    {this.state.isCalculatorDropdownStatus ?
                        <div className="calculator-select-list">
                            <div className="calculator-btnlist">
                                
                                    <Button className="calculator-value" variant="outline-secondary"
                                        name={this.props.testOperandArr.length == 0 ? " ": this.props.testOperandArr[0]}
                                        disabled={this.props.testOperandArr.length == 0 ? true : this.props.isDisabledA}
                                        onClick={e => this.props.testOperandArr.length > 0 && this.handleBtnClick(e.target.name)}>
                                        {this.props.testOperandArr.length == 0 ? " " :this.props.testOperandArr[0]}
                                    </Button>
                                    

                                <Button className="calculator-value" variant="outline-secondary" 
                                name="(" onClick={e => this.handleBtnClick(e.target.name)}>(</Button>
                                <Button className="calculator-value" variant="outline-secondary" 
                                name=")" onClick={e => this.handleBtnClick(e.target.name)}>)</Button>
                                <Button className="calculator-value" variant="outline-secondary" 
                                name=""  onClick={e => this.props.backspace(e.target.name)}>
                                    <img src= {backspace}/>
                                </Button>
                                <Button className="calculator-value" variant="outline-secondary"
                                    name="7" disabled={this.props.isDisabledNumber } onClick={e => this.handleBtnClick(e.target.name)}>7</Button>
                                <Button className="calculator-value" variant="outline-secondary"
                                    name="8" disabled={this.props.isDisabledNumber} onClick={e => this.handleBtnClick(e.target.name)}>8</Button>
                                <Button className="calculator-value" variant="outline-secondary"
                                    name="9" disabled={this.props.isDisabledNumber} onClick={e => this.handleBtnClick(e.target.name)}>9</Button>
                                <Button className="calculator-value" variant="outline-secondary"
                                    name="/" disabled={this.props.isDisabledOp} onClick={e => this.handleBtnClick(e.target.name)}>/</Button>
                                <Button className="calculator-value" variant="outline-secondary"
                                    name="4" disabled={this.props.isDisabledNumber} onClick={e => this.handleBtnClick(e.target.name)}>4</Button>
                                <Button className="calculator-value" variant="outline-secondary"
                                    name="5" disabled={this.props.isDisabledNumber} onClick={e => this.handleBtnClick(e.target.name)}>5</Button>
                                <Button className="calculator-value" variant="outline-secondary"
                                    name="6" disabled={this.props.isDisabledNumber} onClick={e => this.handleBtnClick(e.target.name)}>6</Button>
                                <Button className="calculator-value" variant="outline-secondary"
                                    name="*" disabled={this.props.isDisabledOp} onClick={e => this.handleBtnClick(e.target.name)}>x</Button>
                                <Button className="calculator-value" variant="outline-secondary"
                                    name="1" disabled={this.props.isDisabledNumber} onClick={e => this.handleBtnClick(e.target.name)}>1</Button>
                                <Button className="calculator-value" variant="outline-secondary"
                                    name="2" disabled={this.props.isDisabledNumber} onClick={e => this.handleBtnClick(e.target.name)}>2</Button>
                                <Button className="calculator-value" variant="outline-secondary"
                                    name="3" disabled={this.props.isDisabledNumber} onClick={e => this.handleBtnClick(e.target.name)}>3</Button>
                                <Button className="calculator-value" variant="outline-secondary"
                                    name="-" disabled={this.props.isDisabledOp} onClick={e => this.handleBtnClick(e.target.name)}>-</Button>
                                <Button className="calculator-value large-button" variant="outline-secondary"
                                    name="0" disabled={this.props.isDisabledNumber} onClick={e => this.handleBtnClick(e.target.name)}>0</Button>
                                <Button className="calculator-value" variant="outline-secondary"
                                    name="+" disabled={this.props.isDisabledOp} onClick={e => this.handleBtnClick(e.target.name)}>+</Button>
                            </div>
                        </div> :
                        ''}
                </div>
            </div>
        )
    }
}
