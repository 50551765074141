export const INITIALISE_MINDMAP = 'INITIALISE_MINDMAP';
export const MINDMAP_CLICK = 'MINDMAP_CLICK';
export const SAVECLICK = 'SAVECLICK';

export function InitialiseState(data) {
  return {
    type: INITIALISE_MINDMAP,
    payload: data,
  };
}

export function MindmapClick(data) {
  return {
    type: MINDMAP_CLICK,
    payload: data,
  };
}

export function SaveClick(data) {
  return {
    type: SAVECLICK,
    payload: data,
  };
}
