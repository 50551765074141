import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import classNames from "classnames";
import i18n from "i18next";
export default class OpenPowerBIReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view : 'minimized',
            show: this.props.flag,
            isAPILoading: false,
        }
    }

    setView = (data) =>{
        this.setState({
            view : data
        })
    }

    handleHide = () => {
        this.setState({ show: false })
        this.props.close();
    }

    render() {
        return (
            <>
                <Modal
                    show={this.state.show}
                    onHide={this.handleHide}
                    className="powerBI-modal"
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <button type="button" className="btn btn-primary mr-5" onClick={() => this.setView('maximized')}>
                            {i18n.t("Maximize")}
                            <i class="fas fa-compress-arrows-alt"></i>
                        </button>
 
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.view === "maximized" && (
                            <div className="casestudy-modalimg">
                                <div title="Minimize" class="close-excel" onClick={() => this.setView('minimized')}>
                                    <i class="far fa-window-minimize"></i>
                                </div>
                            </div>
                        )}
                        <div className="casestudy-modalimg">
                        <iframe
                            width="100%"
                            height= '500px'
                            src= {this.props.source}
                            frameborder="0"
                            allowFullScreen="true"
                            className={classNames({ fullScreen: this.state.view === "maximized" })}
                        >
                        </iframe>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
