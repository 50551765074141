export let MaturityReducers = (state = {
    Maturity: []
}, action) => {
    switch (action.type) {
        case "INITIALISEMaturity": {
            state = { ...state }
            state["Maturity"] = action.payload
            return state
        }
        case "ADD_Maturity": {

            state = { ...state }

            if (state["Maturity"].filter((data) => data.ID == action.payload.ID && data.ProjectInitiativeName == action.payload.ProjectInitiativeName).length > 0) {
                state["Maturity"].filter((data) => data.ID == action.payload.ID)[0] = action.payload;
            }
            else {
                state["Maturity"].push(action.payload)
            }

            state["Maturity"].sort((a, b) => (a.BOSeqNumber > b.BOSeqNumber) ? 1 : -1)

            return state
        }

        case "REMOVE_IOJMaturity": {


            state = { ...state }


            let MaturityRemove = state["Maturity"].filter((x) => x.ServiceComponentID == action.payload.id)[0]
            var index = state["Maturity"].indexOf(MaturityRemove)
            state["Maturity"].splice(index, 1)
            //state["IOJ"].(iojRemove)
            return state

        }

        case "UPDATE_IOJMaturity": {


            state = { ...state }


            let iojRemove = state["Maturity"].filter((x) => x.ID == action.payload.id && x.ProjectInitiativeName == action.payload.type && x.MaturityPhase == action.payload.maturity)[0]
            var index = state["Maturity"].indexOf(iojRemove)
            state["Maturity"].splice(index, 1)
            //state["IOJ"].(iojRemove)
            return state

        }

        default: return state
    }
}
