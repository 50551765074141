import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import {
  LocalApiBaseUrl, trycatchAlertPopup,
} from '../Shared/Constant';
import ClickableToolPopup from './ClickableToolPopup';
import axiosInstance from '../Shared/interceptor';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('msal.idtoken')}`;

export const onFirstDataRendered = (params) => {
  params.api.sizeColumnsToFit();
};
export default class ClickableToolList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewmore: false,
      rowSelected: [],
      columnDefs: [
        {
          headerName: 'Edit',
          suppressMovable: true,
          width: 60,
          cellRenderer: (params) => {
            const link = document.createElement('span');
            link.className = 'tbledit';
            link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
            link.addEventListener('click', (e) => {
              this.dealpopup(e, 'Edit', params.data);
            });
            return link;
          },
          suppressFilter: true,
          resizable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: 'Client Name', field: 'OrgEntityName', suppressMovable: true, resizable: true, suppressSizeToFit: true,
        },
        {
          headerName: 'Delivery Center', field: 'City', suppressMovable: true, resizable: true, suppressSizeToFit: true,
        },
        {
          headerName: 'Offering', field: 'Offering', suppressMovable: true, resizable: true, suppressSizeToFit: true,
        },
        {
          headerName: 'Sub Offering', field: 'Process', suppressMovable: true, resizable: true, suppressSizeToFit: true,
        },
        {
          headerName: 'SynOps Lever', field: 'SOCategoryName', suppressMovable: true, resizable: true, suppressSizeToFit: true,
        },
        {
          headerName: 'Tool Name', field: 'ApplicationName', suppressMovable: true, resizable: true, suppressSizeToFit: true,
        },
        {
          headerName: 'Description', field: 'ApplicationDescription', suppressMovable: true, resizable: true, suppressSizeToFit: true,
        },
        {
          headerName: 'Category', field: 'ApplicationCategory', suppressMovable: true, resizable: true, suppressSizeToFit: true,
        },
        {
          headerName: 'URL', field: 'URL', suppressMovable: true, resizable: true, suppressSizeToFit: true,
        },
        {
          headerName: 'Is Active',
          field: 'IsActive',
          resizable: true,
          suppressMovable: true,
          suppressFilter: true,

          cellRenderer: (params) => `<label class="form-check">
                    <input class="form-check-input" disabled type="checkbox" ${params.value ? 'checked' : ''}>
                    <span class="checkmark"></span>
                     </label>`,
          suppressSizeToFit: true,
        },
      ],
      rowData: [],
      type: '',
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        rowSelection: 'multiple',
        defaultColDef: {
          sortable: true,
        },
      },
    };
    this.onClose = this.onClose.bind(this);
    this.dealpopup = this.dealpopup.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getTools();
    let { columnDefs } = this.state;
    const { featureData } = this.props;

    if (featureData.filter((data) => data.Submenu === 'ClickableTool' && data.Operations.includes('Edit')).length === 0) {
      columnDefs = columnDefs.filter((data) => data.headerName !== 'Edit');
      this.setState({
        columnDefs,
      });
    }

    if (featureData.filter((data) => data.Submenu === 'ClickableTool' && data.Operations.includes('Delete')).length === 0) {
      columnDefs = columnDefs.filter((data) => data.headerName !== 'Delete');
      this.setState({
        columnDefs,
      });
    }
  }

  handleSave(data) {
    if (data != null) {
      axiosInstance.post(`${LocalApiBaseUrl}ClickableTools/postToolsDealMapping`, data)
        .then((response) => {
          window.alert(response.data.StatusMessage);
          this.getTools();
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  }

  onFilterTextBoxChanged() {
    const { gridOptions } = this.state;
    gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
  }

  onClose() {
    this.setState({ viewmore: false });// CLOSES CLICKABLETOOL POPUP
  }

  getTools() {
    axiosInstance.get(`${LocalApiBaseUrl}ClickableTools/GetMappedData`)
      .then((response) => {
        this.setState({
          rowData: response.data,

        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  dealpopup(e, type, data) {
    e.preventDefault();
    this.setState({ viewmore: true, type, rowSelected: (type === 'Edit' ? data : {}) });
  }

  render() {
    const { featureData, filterDetails } = this.props;
    const {
      gridOptions, columnDefs, rowData, viewmore, type, rowSelected,
    } = this.state;
    return (
      <div className="main-data">
        <div className="tableFilter">
          <div className="form-section">
            <div className="form-row">
              <div className="form-group col-lg-3">
                <input type="text" className="form-control" id="filter-text-box" placeholder="Filter..." onChange={(e) => { this.onFilterTextBoxChanged(e); }} />
              </div>
              {featureData.filter((data) => data.Submenu === 'ClickableTool' && data.Operations.includes('Add')).length > 0
                            && (
                            <div className="form-group col-lg-3">
                              <button type="button" className="btn btn-primary btn-sm" onClick={(e) => { this.dealpopup(e); }}>
                                <span><FontAwesomeIcon icon={faPlusCircle} /></span>
                                {' '}
                                Add Mapping
                              </button>
                            </div>
                            )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ag-theme-balham ag-table">
              <AgGridReact
                enableSorting
                enableFilter
                pagination
                paginationPageSize={50}
                floatingFilter
                gridOptions={gridOptions}
                columnDefs={columnDefs}
                rowData={rowData}
                onFirstDataRendered={onFirstDataRendered(this.params)}
              />
            </div>
          </div>
        </div>
        {viewmore ? <ClickableToolPopup filterDetails={filterDetails} tooldatatype={type} rowData={rowData} data={rowSelected} show={viewmore} onClose={this.onClose} onSave={this.handleSave} /> : null}
      </div>
    );
  }
}
ClickableToolList.defaultProps = {
  featureData: [],
  filterDetails: [],
};
ClickableToolList.propTypes = {
  featureData: PropTypes.array,
  filterDetails: PropTypes.array,
};
