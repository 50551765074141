import React, { Component } from 'react';
import { LocalApiBaseUrl} from '../Shared/Constant';
import { AgGridReact } from 'ag-grid-react';
import CategoryApprovalPopup from './CategoryApprovalPopup';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";

axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;



export default class CategoryApprovalList extends Component {
    constructor() {
        super()
        this.state = {
            Approval: {
                DealWOStepID: [],
                ApprovalType: null,
                ApprovalAction: false,
            },
            allDataList:[],
            CanApprove: false, 
            CanReject: false, 
            action: "",
            rowSelected: [],
            userList: [], 
            roleList: [], 
            clientList: [], 
            viewmore: false, 
            prevLength: "", 
            flag: false,
            columnDefs: [
                {
                    headerName: "", 
                    resizable: true, 
                    suppressFilter: true, 
                    headerCheckboxSelection: false, 
                    headerCheckboxSelectionFilteredOnly: true, 
                    checkboxSelection: params => params.data.Status == "Pending", 
                    suppressMovable: true, suppressSizeToFit: true,
                    width: 100
                },
                { headerName: "SOPage Name" , field: "SOPageName", resizable: true, suppressFilter: false, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Client Name", field: "ClientName", resizable: true, suppressFilter: false, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Location", field: "Location", resizable: true, suppressFilter: false, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Offering", field: "Offering", resizable: true, suppressFilter: false, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Sub Offering", field: "Process", resizable: true, suppressFilter: false, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Category Type", field: "CategoryType", resizable: true, suppressFilter: false, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Category Name", field: "CategoryName", resizable: true, suppressFilter: false, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Status", field: "Status", resizable: true, suppressFilter: false, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Requested By ", field: "CreatedUser", resizable: true, suppressFilter: false, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Requested Date", field: "CreatedTSStr", resizable: true, suppressFilter: false, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Approval/Denied Date", field: "Approval_Denied_Date", resizable: true, suppressFilter: false, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Approver Remarks", field: "ApproverRemarks", resizable: true, suppressFilter: false, suppressMovable: true, suppressSizeToFit: true, },
                { headerName: "Approved By", field: "ApprovedBy", resizable: true, suppressFilter: false, suppressMovable: true, suppressSizeToFit: true, },

            
            ],
            autoGroupColumnDef: {
                headerName: "hello",
                field: "DealName",
                width: 200,
                cellRenderer: "agGroupCellRenderer",
                cellRendererParams: { checkbox: true }
            },
            rowData: [],
            gridOptions: {
                paginationPageSize: 50,
                rowHeight: 40,
                headerHeight: 40,
                animateRows: true,
                rowSelection: 'single',
                defaultColDef: {
                    sortable: true
                },
            },
            categoriesLimit:30
        }
        this.onClose = this.onClose.bind(this);
        
        this.dealpopup = this.dealpopup.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    }



    onRowSelected =(event) => {
        this.getSelectedRowData();
   
    }


    onCheckboxClicked(e, data) {
        if (e.target.checked) {
            let rowSelected = [];
            rowSelected.push(data);
            this.setState({ rowSelected });
        }
        else {
            this.setState({ rowSelected: [] });
        }
    }

    onFilterTextBoxChanged(e) {
        this.state.gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
    }

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    dealpopup(e) {
        if (this.props.featureData.filter((data) => data.Submenu == "Category Approval" && data.Operations.includes("Approve")).length > 0) {
            e.preventDefault();
            this.setState({ viewmore: true })
            this.state.action = 'approve'
        }
        else window.alert("Access Denied !!")
    }

    onClose(e) {
        this.setState({ viewmore: false });
    }

    getApproval() {
        axiosInstance.get(`${LocalApiBaseUrl}MultiLevelApproval/GetAllCategoryTypesAndNamesForApproval`, {
            params: {}
        })
            .then((response) => {
                this.setState({
                    rowData: response.data.filter(a=>a.IsCustomCategory == true),
                    allDataList:response.data
                })
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
    }
    getCategoriesLimit = () =>{
        axiosInstance
        .get(`${LocalApiBaseUrl}Configurator/GetAppConfig`)
        .then((response) => {
          let data = response.data;
          let categoriesLimit = parseInt(
            data.filter((a) => a.ConfigKey === "MultiLevelMetricsCount")[0]
              .ConfigValue
          );
          this.setState({categoriesLimit})
        }).catch(error=>{
            trycatchAlertPopup(error);
        })
    }

    componentDidMount() {
        if (this.props.featureData.filter((data) => data.Submenu == "Category Approval" && data.Operations.includes("Reject")).length > 0) {
            this.setState({ CanReject: true });
        }
        if (this.props.featureData.filter((data) => data.Submenu == "Category Approval" && data.Operations.includes("Approve")).length > 0) {
            this.setState({ CanApprove: true });
        }
        this.getApproval();
        this.getCategoriesLimit()
    }

    componentWillMount() {
        //this.getApproval();
    }



    decline(e) {
        if (this.props.featureData.filter((data) => data.Submenu == "Category Approval" && data.Operations.includes("Reject")).length > 0) {
            e.preventDefault();
            this.setState({ viewmore: true })
            this.state.action = 'reject'
        }
        else window.alert("Access Denied !!")
    }
    handleSave(data) {
        const params = {
            "CategoryId": data.CategoryId,
            "ApprovalAction": data.ApprovalAction ? "approve" : "reject",
            "ApprovalComments": data.ApproverRemarks,
            "ApprovalType":data.ApprovalType
        }
        axiosInstance.post(`${LocalApiBaseUrl}MultiLevelApproval/PostApprovalRejectCategory`, null,
            {params: params})
            .then((response) => {
                window.alert(response.data.StatusMessage)
                this.getApproval();
                this.state.gridOptions.api.setFilterModel(null);
                this.state.gridOptions.api.onFilterChanged();
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
        this.setState({ rowSelected: [] })
    }



    onActiveChange(event) {
        const user = this.state.user;
        user.IsActive = event.target.checked;
        this.setState({
            user: user
        })
    }
    onSelectionChanged = (event)=>{
       
    }
    getSelectedRowData =() => {
        let selectedNodes = this.gridApi.getSelectedNodes();
        let selectedData = selectedNodes.map(node => node.data).filter(each=>each.Status == "Pending");
        this.setState({
            rowSelected:[...selectedData],
        })
      
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
    
        this.gridColumnApi = params.columnApi;
      };



    render() {
   
        return (
            <div className="main-data">
                {/* <h3 className="grid-heading">Category Approval List</h3> */}
                <div className="tableFilter">
                    <div className="form-section">
                        <div className="form-row">
                            <div className="form-group col-lg-3">
                                <input type="text" className="form-control" id="filter-text-box" placeholder="Filter..." onChange={(e) => { this.onFilterTextBoxChanged(e) }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ag-theme-balham ag-table">
                            <AgGridReact
                                enableSorting={true}
                                enableFilter={true}
                                pagination={true}
                                paginationPageSize={50}
                                floatingFilter={true}
                                onRowSelected={(event)=>{this.onRowSelected(event)}}
                               
                                onGridReady={this.onGridReady}

                                gridOptions={this.state.gridOptions}
                                columnDefs={this.state.columnDefs}
                                rowData={this.state.rowData}
                                onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                            </AgGridReact>
                        </div>
                    </div>
                </div>
                {this.state.viewmore ? <CategoryApprovalPopup categoriesLimit={this.state.categoriesLimit} action={this.state.action} data={this.state.rowSelected.length>0? this.state.rowSelected[0]:{}} show={this.state.viewmore} onClose={this.onClose} onSave={this.handleSave} allDataList = {this.state.allDataList} /> : null}
                <div className="text-center mt-3 mb-2">
                    {this.state.rowSelected.length > 0 && this.state.CanApprove ? <button className="btn btn-primary btn-sm mx-2" onClick={(e) => { this.dealpopup(e) }}>Approve</button>
                        : <button className="btn btn-primary btn-sm mx-2" disabled={true} onClick={(e) => { this.dealpopup(e) }}>Approve</button>}
                    {this.state.rowSelected.length > 0 && this.state.CanReject ? <button className="btn btn-primary btn-sm mx-2" onClick={(e) => { this.decline(e) }} >Decline</button>
                        : <button className="btn btn-primary btn-sm mx-2" disabled={true} onClick={(e) => { this.decline(e) }} >Decline</button>}
                </div>
            </div>

        )
    }
}