import React, { Component } from "react";
import axiosInstance from "../Shared/interceptor";
import { store } from "../reducers/configureStore";
import UnitList from "../UnitOfMeasurment/UnitList";
import DealList from "../Deal/dealList";
import LocationList from "../Location/locationList";
import OfferingList from "../Offering/offeringList";
import UserList from "../User/userList";
import DEIList from "../DEImapping/DEIList";
import IojList from "../IojCategory/IojList";
import ClickableToolList from "../ClickableTool/ClickableToolList"
import ConnectorMasterList from "../ConnectorMaster/ConnectorMasterList";
import SettingsList from "../Details/DetailsList";
import UserProfileList from "../UserProfile/UserProfileList";
import AttributeMasterList from "../Attribute/AttributeMasterList";
import ClientList from "../Client/ClientList";
import IndustryList from "../Industry/IndustryList";
import DealConnectorList from "../DealConnector/DealConnectorList";
import DealFilterList from "../QueryBuilder/DealFilterList";
import ClientRegionList from "../ClientRegion/ClientRegionList";
import DeliveryRegionList from "../DeliveryRegion/DeliveryRegionList";
import URLWhiteList from "../URLWhiteList/URLWhiteList";
import NotificationList from "../Notification/NotificationList";
import MessageBoard from "../MessageBoard/MessageBoard";
import LandingList from "../LandingHome/LandingList";
import LandingQuickLink from "../LandingHome/LandingQuickLink"
import HelpFeatureMasterList from "../Configurator/HelpFeatures/HelpFeatureMasterList";
import GetDataList from "../GetData/GetDataList";


import ConnectorFieldMasterList from "../ConnectorFieldMaster/ConnectorFieldMasterList";

axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class MasterContainer extends Component {
  constructor() {
    super();
    this.state = {
      roleData: [],
      featureData: [],
    };

    this.getRoleData = this.getRoleData.bind(this);
  }

  getRoleData() {

    var data = store.getState();
    let roleData1 = data.roleReducers["roleReducers"];
    this.setState({ featureData: roleData1 });

    store.subscribe(() => {
      var data = store.getState();
      this.setState({ roleData: data.roleReducers["roleReducers"] });
      let roleData1 = data.roleReducers["roleReducers"];
      this.setState({ featureData: roleData1 });
    });
  }

  componentWillMount() {
    this.getRoleData();
  }

  componentDidMount() {
    this.getRoleData();
  }
  componentDidUpdate(prevProps){
   const {
    ConnectorClicked,
    dealClicked,
    offeringClicked,
    locationClicked,
    userClicked,
    DEIClicked,
    IojClicked,
    DetailsClicked,
    UserProfileClicked,
    WhiteListURLClicked,
    AttributeClicked,
    ClientClicked,
    IndustryClicked,
    DealFilterClicked,
    DealConnectorClicked,
    ConnectorFieldMasterDataClicked,
    ClickableToolClicked,
    ClientRegionClicked,
    DeliveryRegionClicked,
    NotificationClicked,
    MessageBoardClicked,
    LandingPageClicked,
    HelpFeaturesClicked,
    LandingPageQuicklinkClicked,
    GetDataClicked,
    UnitOfMeasurementClicked,
   } = this.props;
   if(
    ConnectorClicked !== prevProps.ConnectorClicked ||
    dealClicked !== prevProps.dealClicked ||
    offeringClicked !== prevProps.offeringClicked ||
    locationClicked !== prevProps.locationClicked ||
    userClicked !== prevProps.userClicked ||
    DEIClicked !== prevProps.DEIClicked ||
    IojClicked !== prevProps.IojClicked ||
    DetailsClicked !== prevProps.DetailsClicked ||
    UserProfileClicked !== prevProps.UserProfileClicked ||
    WhiteListURLClicked !== prevProps.WhiteListURLClicked ||
    AttributeClicked !== prevProps.AttributeClicked ||
    ClientClicked !== prevProps.ClientClicked ||
    IndustryClicked !== prevProps.IndustryClicked ||
    DealFilterClicked !== prevProps.DealFilterClicked ||
    DealConnectorClicked !== prevProps.DealConnectorClicked ||
    ConnectorFieldMasterDataClicked !== prevProps.ConnectorFieldMasterDataClicked ||
    ClickableToolClicked !== prevProps.ClickableToolClicked ||
    ClientRegionClicked !== prevProps.ClientRegionClicked ||
    DeliveryRegionClicked !== prevProps.DeliveryRegionClicked ||
    NotificationClicked !== prevProps.NotificationClicked ||
    MessageBoardClicked !== prevProps.MessageBoardClicked ||
    LandingPageClicked !== prevProps.LandingPageClicked ||
    HelpFeaturesClicked !== prevProps.HelpFeaturesClicked ||
    LandingPageQuicklinkClicked !== prevProps.LandingPageQuicklinkClicked ||
    GetDataClicked !== prevProps.GetDataClicked ||
    UnitOfMeasurementClicked !== prevProps.UnitOfMeasurementClicked 
   )
      {
        window.scrollTo(0, 0);
      }
  }

  render() {
    return (
      <>
        {
          
          <>
            {this.props.dealClicked &&
              this.state.featureData.filter((data) => data.Submenu == "Deals" && data.Operations.length != 0).length >
              0 ? (
              <DealList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.offeringClicked &&
              this.state.featureData.filter((data) => data.Submenu == "Offerings" && data.Operations.length != 0)
                .length > 0 ? (
              <OfferingList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.locationClicked &&
              this.state.featureData.filter(
                (data) => data.Submenu == "Delivery Center" && data.Operations.length != 0
              ).length > 0 ? (
              <LocationList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.userClicked &&
              this.state.featureData.filter((data) => data.Submenu == "Users" && data.Operations.length != 0).length >
              0 ? (
              <UserList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.DEIClicked &&
              this.state.featureData.filter((data) => data.Submenu == "DEI" && data.Operations.length != 0).length >
              0 ? (
              <DEIList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.IojClicked &&
              this.state.featureData.filter((data) => data.Submenu == "IOJ Category" && data.Operations.length != 0)
                .length > 0 ? (
              <IojList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.DetailsClicked &&
              this.state.featureData.filter((data) => data.Submenu == "Settings" && data.Operations.length != 0)
                .length > 0 ? (
              <SettingsList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.UserProfileClicked &&
              this.state.featureData.filter((data) => data.Submenu == "User Profile" && data.Operations.length != 0)
                .length > 0 ? (
              <UserProfileList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.ConnectorClicked &&
              this.state.featureData.filter(
                (data) => data.Submenu == "Connector Master" && data.Operations.length != 0
              ).length > 0 ? (
              <ConnectorMasterList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}

            {this.props.AttributeClicked &&
              this.state.featureData.filter(
                (data) => data.Submenu == "Attribute Master" && data.Operations.length != 0
              ).length > 0 ? (
              <AttributeMasterList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.HelpFeaturesClicked && this.state.featureData.filter(
              data =>
                data.Submenu == "Help and Feedback" &&
                data.Operations.length != 0
            ).length > 0 ? (
              <HelpFeatureMasterList
                filterDetails={this.props.filterDetails}
                featureData={this.state.featureData}
              />
            ) : null

            }

            {this.props.ClientClicked &&
              this.state.featureData.filter((data) => data.Submenu == "Clients" && data.Operations.length != 0)
                .length > 0 ? (
              <ClientList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.IndustryClicked &&
              this.state.featureData.filter((data) => data.Submenu == "Industry" && data.Operations.length != 0)
                .length > 0 ? (
              <IndustryList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.ClickableToolClicked &&
              this.state.featureData.filter((data) => data.Submenu == "ClickableTool" && data.Operations.length != 0)
                .length > 0 ? (
              <ClickableToolList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.DealFilterClicked &&
              this.state.featureData.filter((data) => data.Submenu == "Deal Filter" && data.Operations.length != 0)
                .length > 0 ? (
              <DealFilterList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}

            {this.props.DealConnectorClicked &&
              this.state.featureData.filter(
                (data) => data.Submenu == "Deal Metric Configuration" && data.Operations.length != 0
              ).length > 0 ? (
              <DealConnectorList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}

            {this.props.ConnectorFieldMasterDataClicked &&
              this.state.featureData.filter(
                (data) => data.Submenu == "Connector Field Master Data" && data.Operations.length != 0
              ).length > 0 ? (
              <ConnectorFieldMasterList
                filterDetails={this.props.filterDetails}
                featureData={this.state.featureData}
              />
            ) : null}
            {this.props.UnitOfMeasurementClicked &&
              this.state.featureData.filter(
                (data) => data.Submenu == "Unit of Measurement" && data.Operations.length != 0
              ).length > 0 ? (
              <UnitList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}

            {this.props.ClientRegionClicked &&
              this.state.featureData.filter((data) => data.Submenu == "Client Region" && data.Operations.length != 0)
                .length > 0 ? (
              <ClientRegionList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.WhiteListURLClicked && 
              <URLWhiteList />
            }
            {this.props.DeliveryRegionClicked &&
              this.state.featureData.filter(
                (data) => data.Submenu == "Delivery Region" && data.Operations.length != 0
              ).length > 0 ? (
              <DeliveryRegionList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.NotificationClicked ? (
              <NotificationList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.MessageBoardClicked ? (
              <MessageBoard filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.LandingPageClicked &&
              this.state.featureData.filter((data) => data.Submenu == "Landing Page" && data.Operations.length != 0)
                .length > 0 ? (
              <LandingList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.LandingPageQuicklinkClicked &&
              this.state.featureData.filter(
                (data) => data.Submenu == "Landing Page Quick Links" && data.Operations.length != 0
              ).length > 0 ? (
              <LandingQuickLink filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
            {this.props.GetDataClicked &&
              this.state.featureData.filter((data) => data.Submenu == "Get Data" && data.Operations.length != 0).length > 0 ? (
              <GetDataList filterDetails={this.props.filterDetails} featureData={this.state.featureData} />
            ) : null}
          </>
        }
      </>
    );
  }
}
