import React, { Component } from 'react';
import { LocalApiBaseUrl, getMonthNumber, getPrevMonth } from '../Shared/Constant';
import { AgGridReact, Ag } from 'ag-grid-react';
import UserProfilePopUp from './UserProfilePopup';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;



export default class UserList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rowSelected: [],
            userList: [], roleList: [], clientList: [], offeringList: [], viewmore: false,
            columnDefs: [

                {
                    headerName: "Edit",suppressMovable: true,
                    width: 80,
                    cellRenderer: (params) => {
                        var link = document.createElement('span');
                        link.className = 'tbledit';
                    link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
                        link.addEventListener('click', (e) => {
                            this.dealpopup(e, params.data);
                        });
                        return link;
                    },
                    suppressFilter: true,
                    suppressSizeToFit: true
                },
                {
                    headerName: "Delete",suppressMovable: true,
                    width: 60,
                    cellRenderer: (params) => {
                        var link = document.createElement('span');
                    link.className = 'tbldel';
                        link.innerHTML = '<i class="fas fa-trash-alt delete-icon-fix"></i>';
                        link.addEventListener('click', (e) => {
                            this.deleteProfile(e, params.data);
                        });
                        return link;
                    },
                    suppressFilter: true
                },
                { headerName: "Profile name", width: 250, field: "RoleName",suppressMovable: true, sort: "asc", resizable: true, suppressSizeToFit: true },
                { headerName: "Profile Description", width: 350, field: "RoleDescription",suppressMovable: true, resizable: true, suppressSizeToFit: false },
                // { headerName: "Operations", width: 350, field: "FeatureName", resizable: true, suppressSizeToFit: true },
                {
                    headerName: "Is Active", field: "IsActive", resizable: false,suppressMovable: true, suppressSizeToFit: true, suppressFilter: true,
                    cellRenderer: params => {
                        return `<label class="form-check">
                        <input class="form-check-input" type="checkbox" disabled ${params.value ? 'checked' : ''}>
                        <span class="checkmark"></span>
                         </label>`;
                    }/* , cellStyle: function (params) {
                        return { 'text-align': "left" };
                    } */
                },

            ],
            rowData: [],
            gridOptions: {
                paginationPageSize: 50,
                rowHeight: 40,
                headerHeight: 40,
                animateRows: true,
                rowSelection: 'multiple',
                defaultColDef: {
                    sortable: true
                },

            }

        }
        this.onClose = this.onClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.dealpopup = this.dealpopup.bind(this);
        this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    }

    onFilterTextBoxChanged(e) {
        this.state.gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
    }

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    dealpopup(e, data) {
        
        if (data != undefined) {
            // edit condition
            if (this.props.featureData.filter((data) => data.Submenu == "User Profile" && data.Operations.includes("Edit")).length > 0) {
                e.preventDefault();
                this.setState({ viewmore: true, rowSelected: data })
            }
            else   window.alert("Access Denied !!")
        }
        else {
            // add condition
            if (this.props.featureData.filter((data) => data.Submenu == "User Profile" && data.Operations.includes("Add")).length > 0) {

                e.preventDefault();
                this.setState({ viewmore: true, rowSelected: data })
            }
            else   window.alert("Access Denied !!")

        }

    }
    onClose(e) {

        this.setState({ viewmore: false });
    }

    deleteProfile(e, data) {
        
        if (this.props.featureData.filter((data) => data.Submenu == "User Profile" && data.Operations.includes("Delete")).length > 0) {

            if (window.confirm('Are you sure you want to delete the item?')) {
                axiosInstance.delete(`${LocalApiBaseUrl}UserProfile/DeleteUserProfile`, {
                    params: {
                        RoleID: data.RoleID
                    }
                }).then((response) => {
                    window.alert(response.data.StatusMessage)
                    this.getUsersProfile();

                })
                .catch((error) => {
                    trycatchAlertPopup(error);
                  });          
            }
        }
        else   window.alert(" UnAuthorised Access Denied !!")
    }

    getUsersProfile() {
        
        axiosInstance.get(`${LocalApiBaseUrl}UserProfile/GetUserProfileList`, {

            params: {}
        })
            .then((response) => {
                this.setState({
                    rowData: response.data.sort((a, b) => (a.UserName > b.UserName) ? 1 : -1)
                })
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      
    }

    //   componentDidMount() {
    //  }

    componentWillMount() {
        this.getUsersProfile();
        let columnDefs = this.state.columnDefs;

        if (this.props.featureData.filter((data) => data.Submenu == "User Profile" && data.Operations.includes("Edit")).length == 0) {
             columnDefs = columnDefs.filter((data) => data.headerName != "Edit");
            this.setState({
                columnDefs: columnDefs
            })

        }


        if (this.props.featureData.filter((data) => data.Submenu == "User Profile" && data.Operations.includes("Delete")).length == 0) {
             columnDefs = columnDefs.filter((data) => data.headerName != "Delete");
            this.setState({
                columnDefs: columnDefs
            })

        }
        // if (this.props.featureData.filter((data) => data.Submenu == "User Profile" && data.Operations.includes("Delete","Edit")).length == 0) {
        //     columnDefs = this.state.columnDefs.filter((data) => data.headerName != "Delete"&& data.headerName != "Edit");
        //     this.setState({
        //         columnDefs: columnDefs
        //     })

        // }

    }

    handleSave(data) {
        
        axiosInstance.post(`${LocalApiBaseUrl}UserProfile/AddEditUserProfile`, data)
            .then((response) => {
                window.alert(response.data.StatusMessage)
                this.getUsersProfile();
                this.state.gridOptions.api.setFilterModel(null);
                this.state.gridOptions.api.onFilterChanged();
            },
                (error) => {
                    
                    trycatchAlertPopup(error)
                })
                .catch((error) => {
                    trycatchAlertPopup(error);
                  });
          
        this.getUsersProfile();

    }

    render() {
        
        return (
            <div className="main-data">
                {/* <h3 className="grid-heading">User Profile List</h3>                 */}
                <div className="tableFilter">
                    <div className="form-section">
                        <div className="form-row">
                            <div className="form-group col-lg-3">
                                <input type="text" className="form-control" id="filter-text-box" placeholder="Filter..." onChange={(e) => { this.onFilterTextBoxChanged(e) }} />
                            </div>
                            {this.props.featureData.filter((data) => data.Submenu == "User Profile" && data.Operations.includes("Add")).length > 0 &&
                            <div className="form-group col-lg-3">
                                <button type="button" className="btn btn-primary btn-sm" onClick={(e) => { this.dealpopup(e) }}><span><FontAwesomeIcon icon={faPlusCircle} /></span> Add Profile</button>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                        <div className="row">
                            <div className="col-lg-12">
                                            <div className="ag-theme-balham ag-table">
                                                    <AgGridReact
                                                        enableSorting={true}
                                                        enableFilter={true}
                                                        pagination={true}
                                                        paginationPageSize={50}
                                                        floatingFilter={true}
                                                        gridOptions={this.state.gridOptions}
                                                        columnDefs={this.state.columnDefs}
                                                        rowData={this.state.rowData}
                                                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}>
                                                    </AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                            {this.state.viewmore ? <UserProfilePopUp rowData={this.state.rowData} data={this.state.rowSelected} show={this.state.viewmore} onClose={this.onClose} filterDetails={this.props.filterDetails} onSave={this.handleSave} /> : null}
            </div>

        )
    }
}