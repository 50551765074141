import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from 'i18next';
import {
  LocalApiBaseUrl, regexForFields, regexForToolURL, commonToolRegex,
  trycatchAlertPopup,
} from '../Shared/Constant';
import axiosInstance from '../Shared/interceptor';

class ToolsPopUP extends React.Component {
  constructor(props) {
    super(props);
    const { eachShow } = this.props;
    this.state = {
      show: eachShow,
      buttdis: false,
      popupHeader: i18n.t('Add_Tool'),
      popupSaveButton: i18n.t('Create'),
      processValue: {
        proID: 0,
      },
      toolDetails: {
        toolName: '', OfferingID: 0, OrgEntityID: 0, LocationID: 0, toolCategory: 0, synopsLevers: 0, url: '', description: '', DefaultUserID: '', BusinessBenefit: '', isActive: true, isClickableTool: true, isFieldDisable: false, toolType: '',
      },
      toolErrors: {
        toolName: '', toolCategory: '', synopsLevers: '', url: '', description: '', BusinessReason: '', toolType: '',
      },
      toolCategoryList: [],
    };
  }

  componentDidMount() {
    const { tooldatatype, tooldata } = this.props;
    this.getToolMasterData();
    if (tooldata !== undefined && tooldatatype === 'Edit') {
      const data = tooldata;

      this.setState({
        popupHeader: i18n.t('Edit_Tool'),
        popupSaveButton: i18n.t('Update'),
        toolDetails: {
          id: data.ApplicationMappingID,
          toolName: data.ApplicationName,
          toolCategory: data.ApplicationCategoryID,
          synopsLevers: data.SOCategoryID,
          url: data.URL,
          description: data.ApplicationDescription,
          isActive: data.IsActive,
          isClickableTool: data.isClickableTool,
          isFieldDisable: true,
          toolType: data.Tooltype,
        },
      });
    } else {
      this.setState({
        popupHeader: i18n.t('Add_Tool'),
        popupSaveButton: i18n.t('Create'),
        toolDetails: {
          id: 0,
          toolName: '',
          toolCategory: 0,
          synopsLevers: 0,
          url: '',
          description: '',
          isActive: true,
          isClickableTool: true,
          isFieldDisable: false,
          toolType: '',
        },
      });
    }
  }

    getToolMasterData = () => {
      const { languageData } = this.props;
      axiosInstance.get(`${LocalApiBaseUrl}ApplicationMapping/GetMasterDataForApplicationMapping`, {
        params: { languageID: languageData.id },
      })
        .then((response) => {
          const categoryList = response.data.CategoryList.map((dropValue) => <option key={dropValue.ID} selected="" value={dropValue.ID}>{dropValue.Value}</option>);

          this.setState({
            toolCategoryList: categoryList,
          });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }

    onClose = () => {
      const { onClose } = this.props;
      onClose();
    }

    bindToolFieldChange = (e) => {
      const { id } = e.target;
      const { toolDetails, processValue } = this.state;
      const data = toolDetails;
      switch (id) {
        case 'txtToolName':
          data.toolName = e.target.value;
          break;
        case 'ddToolCategrory':
          data.toolCategory = e.target.value;
          break;
        case 'SubOffering':
          processValue.proID = e.target.value;
          break;
        case 'ddSynopsLevers':
          data.synopsLevers = e.target.value;
          break;
        case 'txtToolUrl':
          data.url = e.target.value;
          break;
        case 'txtToolDescription':
          data.description = e.target.value;
          break;
        case 'txtToolbusinessreason':
          data.BusinessBenefit = e.target.value;
          break;
        case 'txtTooldefaultuserid':
          data.DefaultUserID = e.target.value;
          break;
        case 'ddToolType':
          data.toolType = e.target.value;
          break;
        default:
          break;
      }

      this.setState({
        toolDetails: data,
      });
    }

    validateToolField = () => {
      const { toolDetails } = this.state;
      const { SOCategoryName } = this.props;
      let isValid = false;
      const errorJSON = [];
      const data = toolDetails;

      if (data.toolName === undefined || data.toolName.trim() === '') {
        errorJSON[0] = i18n.t('ToolName_Required');
      } else if (regexForFields.test(data.toolName.trim())) {
        errorJSON[0] = '';
      } else {
        errorJSON[0] = i18n.t('ToolName_SpecialChar');
      }

      if (data.toolCategory === undefined || parseInt(data.toolCategory, 10) === 0) {
        errorJSON[1] = i18n.t('ToolCategory_Required');
      } else {
        errorJSON[1] = '';
      }

      if (data.description === undefined || data.description.trim() === '') {
        errorJSON[2] = i18n.t('ToolDescription_Required');
      } else if (regexForFields.test(data.description.trim())) {
        errorJSON[2] = '';
      } else {
        errorJSON[2] = i18n.t('ToolDescription_SpecialChar');
      }
      if (!commonToolRegex.test(data.url.trim().toLowerCase())) {
        if (data.url !== undefined && data.url.trim() !== '' && !regexForToolURL.test(data.url.trim().toLowerCase())) {
          errorJSON[3] = i18n.t('Invalid_URL');
        } else {
          errorJSON[3] = '';
        }
      } else {
        errorJSON[3] = i18n.t('commonToolRegex_validatemessage');
      }
      // code for toolType
      if (SOCategoryName === 'Work Orchestration') {
        if (data.toolType === undefined || data.toolType === '' || data.toolType === '0') {
          errorJSON[4] = i18n.t('Tool Type is required');
        } else {
          errorJSON[4] = '';
        }
      } else {
        errorJSON[4] = '';
      }

      this.setState({
        toolErrors: {
          toolName: errorJSON[0], toolCategory: errorJSON[1], description: errorJSON[2], url: errorJSON[3], toolType: errorJSON[4],
        },
      });

      if (errorJSON[0] === '' && errorJSON[1] === '' && errorJSON[2] === '' && errorJSON[3] === '' && errorJSON[4] === '') { isValid = true; }

      return isValid;
    }

    saveTool = () => {
      const { toolDetails } = this.state;
      const {
        offeringID, SOCategoryID, clientID, LocationID,
      } = this.props;
      if (this.validateToolField()) {
        const data = toolDetails;
        const param = {
          // "ApplicationMappingID": data.id,
          OfferingID: parseInt(offeringID, 10),
          ApplicationName: data.toolName.trim(),
          ApplicationCategoryID: parseInt(data.toolCategory, 8),
          SOCategoryID: parseInt(SOCategoryID, 6),
          OrgEntityID: parseInt(clientID, 4),
          LocationID: parseInt(LocationID, 2),
          URL: data.url.trim(),
          ApplicationDescription: data.description.trim(),
          DefaultuserID: toolDetails.DefaultUserID,
          BusinessBenefit: toolDetails.BusinessBenefit,
          ToolType: toolDetails.toolType,
        };
        this.setState({ buttdis: true });
        axiosInstance.post(`${LocalApiBaseUrl}ClickableTools/SavenewClickableTool`, param).then((response) => {
          window.alert(response.data.StatusMessage);
          if (response.data.IsSuccess) {
            this.setState({ buttdis: false });
            this.onClose();
          } else {
            this.setState({ buttdis: false });
          }
        }).catch((error) => {
          trycatchAlertPopup(error);
          window.alert(this.ssErrMsg + error);
        });
      }
    }

    render() {
      const {
        show, popupHeader, toolErrors, toolCategoryList, toolDetails, buttdis, popupSaveButton,
      } = this.state;
      const {
        clientname, offeringName, subofferingname, LocationName, SOCategoryName,
      } = this.props;
      return (
        <>
          {/* <Header /> */}
          <Modal show={show} className="modal fade" size="medium" id="dealpopup" data-backdrop="static" tabIndex="-1" role="dialog" aria-hidden="true">

            <Modal.Header>
              {' '}
              {/* closeButton */}
              <h4 className="modal-title">
                {popupHeader}
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>

            <Modal.Body>
              <form>
                <div className="form-section">

                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label htmlFor="">
                        {i18n.t('Tool Name')}
                        {' '}
                        <span className="required">*</span>
                      </label>
                      <input id="txtToolName" type="text" maxLength="45" className="form-control" onChange={(e) => { this.bindToolFieldChange(e); }} value={toolDetails.toolName} />
                      <span className="errorMsg">
                        {toolErrors.toolName}
                      </span>
                    </div>

                    <div className="form-group col-lg-6">
                      <label htmlFor="">Client Name</label>
                      <input type="text" maxLength="200" className="form-control" id="" value={clientname} disabled />
                      {/* <div className="errorMsg">{this.state.errors.Location}</div> */}
                    </div>

                    <div className="form-group col-lg-6">
                      <label htmlFor="">Offering</label>
                      <input type="text" maxLength="200" className="form-control" id="" value={offeringName} disabled />
                    </div>

                    <div className="form-group col-lg-6">
                      <label htmlFor="">Sub Offering</label>
                      <input type="text" maxLength="200" className="form-control" id="" value={subofferingname} disabled />
                    </div>

                    <div className="form-group col-lg-6">
                      <label htmlFor="">Delivery Center</label>
                      <input type="text" maxLength="200" className="form-control" id="" value={LocationName} disabled />
                    </div>

                    <div className="form-group col-lg-6">
                      <label htmlFor="">
                        {i18n.t('Tool Category')}
                        {' '}
                        <span className="required">*</span>
                      </label>
                      <select id="ddToolCategrory" name="toolCategory" className="form-control" disabled={toolDetails.isFieldDisable} onChange={(e) => { this.bindToolFieldChange(e); }} value={toolDetails.toolCategory}>
                        <option value="0">{i18n.t('Select Tool Category')}</option>
                        {toolCategoryList}
                      </select>
                      <span className="errorMsg">
                        {toolErrors.toolCategory}
                      </span>
                    </div>

                    <div className="form-group col-lg-6">
                      <label htmlFor="">Synops Lever</label>
                      <input type="text" maxLength="200" className="form-control" id="" value={SOCategoryName} disabled />
                    </div>
                    {SOCategoryName === 'Work Orchestration'
                                        && (
                                        <div className="form-group col-lg-6">
                                          <label htmlFor="">
                                            {i18n.t('Tool_Type')}
                                            {' '}
                                            <span className="required">*</span>
                                          </label>
                                          <select id="ddToolType" name="tooltype" className="form-control" disabled={toolDetails.isFieldDisable} onChange={(e) => { this.bindToolFieldChange(e); }} value={toolDetails.toolType}>
                                            <option value="0">{i18n.t('Select_Tool_Type')}</option>
                                            <option value="AA">Advanced Analytics</option>
                                            <option value="AI">Artificial Intelligence</option>
                                            <option value="AT">Automation</option>
                                            <option value="WF">Workflow</option>
                                          </select>
                                          <span className="errorMsg">
                                            {toolErrors.toolType}
                                          </span>
                                        </div>
                                        )}

                    <div className="form-group col-lg-6">
                      <label htmlFor="">Business Benefit</label>
                      <input id="txtToolbusinessreason" type="text" maxLength="255" className="form-control" onChange={(e) => { this.bindToolFieldChange(e); }} value={toolDetails.BusinessBenefit} />
                    </div>

                    <div className="form-group col-lg-12">
                      <label htmlFor="">
                        Default User ID
                        <span className="required" />
                      </label>
                      <div className="input-group">
                        <input id="txtTooldefaultuserid" type="text" maxLength="100" className="form-control" onChange={(e) => { this.bindToolFieldChange(e); }} value={toolDetails.DefaultUserID} />
                      </div>
                    </div>

                    <div className="form-group col-lg-12">
                      <label htmlFor="">
                        URL
                      </label>
                      <input id="txtToolUrl" type="url" maxLength="5000" className="form-control" onChange={(e) => { this.bindToolFieldChange(e); }} value={toolDetails.url} />
                      <span className="errorMsg">
                        {toolErrors.url}
                      </span>
                    </div>

                    <div className="form-group col-lg-12">
                      <label htmlFor="">
                        {i18n.t('Tool Description')}
                        {' '}
                        <span className="required">*</span>
                      </label>
                      <textarea id="txtToolDescription" rows="2" name="Description" maxLength="70" className="form-control" onChange={(e) => { this.bindToolFieldChange(e); }} value={toolDetails.description} />
                      <span className="errorMsg">
                        {toolErrors.description}
                      </span>
                    </div>

                  </div>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <div className="modal-btn">
                <input type="Button" disabled={buttdis} className="btn btn-primary btn-sm" onClick={() => { this.saveTool(); }} value={popupSaveButton} />
              </div>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
}
ToolsPopUP.defaultProps = {
  eachShow: false,
  languageData: 0,
  offeringID: 0,
  SOCategoryID: 0,
  clientID: 0,
  LocationID: 0,
  tooldatatype: '',
  SOCategoryName: '',
  clientname: '',
  offeringName: '',
  subofferingname: '',
  LocationName: '',
  tooldata: [],
  onClose: () => {},
};
ToolsPopUP.propTypes = {
  eachShow: PropTypes.bool,
  languageData: PropTypes.number,
  offeringID: PropTypes.number,
  SOCategoryID: PropTypes.number,
  clientID: PropTypes.number,
  LocationID: PropTypes.number,
  tooldatatype: PropTypes.string,
  SOCategoryName: PropTypes.string,
  clientname: PropTypes.string,
  offeringName: PropTypes.string,
  subofferingname: PropTypes.string,
  LocationName: PropTypes.string,
  tooldata: PropTypes.array,
  onClose: PropTypes.func,
};
const mapStateToProps = (state) => ({
  languageData: state.CurrentFilter.languageData,
});
export default connect(mapStateToProps)(ToolsPopUP);
