import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import _, { parseInt } from 'lodash';
import i18n from 'i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { noop } from '../../Shared/helpers';
import addIcon from '../../Images/Add01.svg';
import deleteIcon from '../../Images/Delete01.svg';

const AdoptionMetricsLabel = [
  {
    label: 'No. of Users / Contracts / New Bookings',
    placeholder: 'Enter the number here...',
  },
  {
    label: 'No. of Users / Contracts / New Bookings',
    placeholder: 'Enter the number here...',
  },
  {
    label: 'No. of Users / Contracts / New Bookings',
    placeholder: 'Enter the number here...',
  },
  {
    label: 'No. of Users / Contracts / New Bookings',
    placeholder: 'Enter the number here...',
  },
  {
    label: 'No. of Users / Contracts / New Bookings',
    placeholder: 'Enter the number here...',
  },
];

const adoptionComment = [
  {
    label: 'Comments',
    placeholder: 'Reasons for change, any initiatives undertaken/planned etc.',
  },
];


const AdoptionMetricsLabelTarget = [
  {
    label: 'No. of Target Users / Contracts / New Bookings',
    placeholder: 'Enter the number here...',
  },
  {
    label: 'No. of Target Users / Contracts / New Bookings',
    placeholder: 'Enter the number here...',
  },
  {
    label: 'No. of Target Users / Contracts / New Bookings',
    placeholder: 'Enter the number here...',
  },
  {
    label: 'No. of Target Users / Contracts / New Bookings',
    placeholder: 'Enter the number here...',
  },
  {
    label: 'No. of Target Users / Contracts / New Bookings',
    placeholder: 'Enter the number here...',
  },
];

const storyLink = 'Dashboard Link';
const Definition = 'Definition of Target User Base';

export const AdoptionMetricsModel = ({
  errors,
  o,
  i,
  handleAdd = noop,
  handleChange = noop,
  handleChangeMonth = noop,
  months,
  selectedMonth,
  adoptionFormValues,
  currentMonthYear,
  adoptionLink,
  handleChangeAdoptionLink,
  definition,
  handleDefinitionChange,

}) => (
  <>
    <div className="row Adoption-Row">
      <div className="col-sm-5">
        <div className="form-section Adoption-FormSection">
          <label htmlFor="currentuser" className="form-label Adoption-FormLabel">
            {AdoptionMetricsLabel[i].label}
          </label>
          <input
            onChange={(evnt) => handleChange(i, evnt)}
            value={o.CurrentUser}
            type="number"
            name="CurrentUser"
            pattern="[0-9]*"
            min="0"
            // onBlur={customBlur}
            className="form-control Adoption-FormControl"
            placeholder={AdoptionMetricsLabel[i].placeholder}
            maxLength="50"
            // readOnly={formMode === 'edit'}
            autoComplete="off"
          />
        </div>
      </div>

      <div className="col-sm-5">
        <div className="form-section Adoption-FormSection">
          <label htmlFor="currentuser" className="form-label Adoption-FormLabel">
            {AdoptionMetricsLabelTarget[i].label}
          </label>
          <input
            type="number"
            pattern="[0-9]*"
            name="TargetUser"
            min="0"

            value={o.TargetUser}
            onChange={(evnt) => handleChange(i, evnt)}
            // onBlur={customBlur}
            className="form-control Adoption-FormControl"
            placeholder={AdoptionMetricsLabelTarget[i].placeholder}
            maxLength="50"
            // readOnly={formMode === 'edit'}
            autoComplete="off"
          />
        </div>
      </div>
      <div className="col-sm-5">
        <div className="form-section Adoption-FormSection">
          <label htmlFor="currentuser" className="form-label Adoption-FormLabel">
            {adoptionComment[i].label}
          </label>
          <input
            type="text"
            pattern="[0-9]*"
            name="Comment"
            value={o.Comment}
            onChange={(evnt) => handleChange(i, evnt)}
            // onBlur={customBlur}
            className="form-control Adoption-FormControl"
            placeholder={adoptionComment[i].placeholder}
            maxLength="300"
            // readOnly={formMode === 'edit'}
            autoComplete="off"
          />
        </div>
      </div>

      <div className="col-sm-5">
        <div className="form-section Adoption-FormSection">
          <label htmlFor="currentuser" className="form-label Adoption-FormLabel">
            {Definition}
          </label>
          <input
            type="text"
            pattern="[0-9]*"
            name="Definition"
            value={definition}
            onChange={(evnt) => handleDefinitionChange(evnt)}
            className="form-control Adoption-FormControl"
            placeholder="Enter the text in max 300 char..."
            maxLength="300"
            autoComplete="off"
          />
        </div>
      </div>
      <div className="col-sm-10">
        <div className="form-section Adoption-FormSection">
          <label htmlFor="currentuser" className="form-label Adoption-FormLabel">
            {storyLink}
          </label>
          <input
            type="text"
            pattern="[0-9]*"
            name="AdoptionLink"
            value={adoptionLink}
            onChange={(evnt) => handleChangeAdoptionLink(evnt)}
            className="form-control Adoption-FormControl"
            placeholder="Enter Link Here..."
            maxLength="5000"
            autoComplete="off"
          />
        </div>
      </div>
      {/* {i < 4 ? (
        <button
          className="addIconCircle ml-0 mb-0 mr-3 mt-2 Adoption-Icon"
          type="button"
          onClick={() => {
            handleAdd(i + 1);
          }}
        >
          {i == adoptionFormValues.length - 1 && <img src={addIcon} alt="" />}
        </button>
      ) : null} */}

      {/* {i > 0 ? (
    <button
      className="addIconCircle ml-0 mb-0 mr-3 mt-2 Adoption-Icon"
      type="button"
      onClick={() => handleDelete(i)}
    >
     <img src={deleteIcon}  alt="" />
    </button>
  ) : null} */}
    </div>
  </>
);

export default AdoptionMetricsModel;
