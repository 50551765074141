import React, { Component } from "react";
import {
  LocalApiBaseUrl,
  getMonthNumber,
  getPrevMonth,
  LocalApiBaseUrlImage
} from "../Shared/Constant";
import i18n from "i18next";
import HumanMetricDetails from "./HumanMetricDetails";
import InsightIntelligenceDetails from "./InsightIntelligenceDetails";
import Project from "../Images/project.png";
import { store } from "../reducers/configureStore";
import InsightIntelligencePopUp from "../SuperAdmin/InsightIntelligencePopUp";
import InsightsDataPopUp from "../SuperAdmin/InsightsDataPopUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import ExportCsv from './ExportCsv';
import emptycomponent from "../Images/Empty State.svg";
import {
  faPlus,
  faTrashAlt,
  faPencilAlt,
  faQuestionCircle
} from "@fortawesome/free-solid-svg-icons";
import IIMetricGroupPopUp from "./IIMetricGroupPopUp";
import { reduxUserProfile } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import {trycatchAlertPopup} from "../Shared/Constant";


import _ from 'lodash';
const options = {
  loop: true,
  margin: 24,
  responsiveClass: "",
  dots: false,
  responsive: {
    0: {
      items: 1,
      nav: true
    },
    600: {
      items: 2,
      nav: false
    },
    1000: {
      items: 3,
      nav: true,
      loop: false
    },
    1440: {
      items: 4,
      nav: true,
      loop: false
    }
  }
};
let statusColor = "gray-clr";
export default class InsightsIntelligence extends Component {
  constructor(props) {
    super(props);
    this.OtherProcess = [];
    this.Analytics = [];
    this.OtherProcessWithCategory = [];
    this.AnalyticsWithCategory = [];

    this.state = {
      viewmore: false,
      type: "",
      AnalyticsAppsAIAdvisorsData: [],
      OtherProcessInsightsData: [],
      AnalyticsmetricDetails: [],
      AnalyticsmetricDetailsCategory: "",
      otherMetricDetailsCategory: "",
      metricDetails: "",
      machineMetricDetails: "",
      new: "",
      insightintelligenceData: [],
      operationArray: [],
      IsRelatorBlock: false,
      attributeLabels: [],
      setCategory: "",
      roledata: [],
      pageHeader: ""
    };
    this.onClose = this.onClose.bind(this);
    this.dealpopup = this.dealpopup.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.setOperations = this.setOperations.bind(this);
  }

  deleteCategory(data, filterDetails, e, categoryID) {
    
    // e.currentTarget.className = "active"
    //e.preventDefault();
    //data.SOCategoryID=5;
    //  axiosInstance.post(`${LocalApiBaseUrl}Master/DeleteSOCategoryMasterTemplate?soCategoryId=5)
    //this, this.props.SODetails,this.props.filterDetails
    axiosInstance
      .post(
        `${LocalApiBaseUrl}Master/DeleteSOCategoryMasterTemplate?soCategoryId=${categoryID}&offeringId=${filterDetails.process}`
      )

      .then(response => {
        
        store.dispatch({
          type: "Remove_APPADVISORS_MASTER",
          payload: data
        });
        
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }
  setOperations() {
    const data = store.getState();

    // let arrayOfOperations = reduxUserProfile
    let operation = this.state.operationArray;
    data.roleReducers["roleReducers"].map(i => {
      if (i.FeatureName === "Master Template Configurator") {
        operation.push(...i.Operations);
        return operation;
      }
    });
  }
  dealpopup(type, e, data, category) {
    // e.currentTarget.className = "active"
    e.preventDefault();
    this.setState({ viewmore: true, type: type, data, setCategory: category });
  }
  onClose(e) {
    this.setState({ viewmore: false });
  }

  getKpiMetrics = (
    status,
    SODetails = { SOCategoryID: 0 },
    offeringIdparam = {
      Client: 0,
      process: 0,
      Locations: 0,
      Month: getPrevMonth()
    }
  ) => {
    let list = [];
    let rowTheList = [];

    if(SODetails.SOCategoryID > 0){
      list.push(<div className="title-with-icon"><h2 className="mb-3">{SODetails.SOCategoryName}</h2><div className="list-icon">
      {this.state.roledata != undefined && store.getState().CurrentFilter.languageData.id==1 &&
        this.state.roledata.includes("Add") && (
          <span
            onClick={e => {
              this.dealpopup("0", e, "", SODetails.SOCategoryName);
            }}
          >
            <i className="far fa-plus plus-icon-fix"></i>
          </span>
        )}
      {this.state.roledata != undefined &&  store.getState().CurrentFilter.languageData.id === 1 &&
        this.state.roledata.includes("Delete") && (
          <span
            onClick={e => {
              this.deleteCategory(
                SODetails.SOCategoryName,
                this.props.filterDetails,
                e,
                SODetails.SOCategoryID
              );
            }}
          >
             <i className="fal fa-trash-alt delete-icon-fix"></i>
          </span>
        )}</div></div>);
     {/* list.push(
        <React.Fragment>
          <h4 className="title-icon">
            <img src={Project} alt="" />
            {SODetails.SOCategoryName}
          </h4>
        </React.Fragment>
     );*/}
    if (SODetails.kpiData != undefined && SODetails.kpiData.length > 0) {
      SODetails.kpiData.map((data, index) => {
        //rowTheList = index % 2 == 0 ? [] : rowTheList;
        rowTheList.push(data);
        
        if (rowTheList.length == SODetails.kpiData.length) {
          // || SODetails.kpiData.length - 1 == index) {
          return list.push(
            <div className="custom-tilecard mtclayout-tilecard">
              <div className="tilecard-wrapper">
              
                <InsightIntelligenceDetails
                  Details={rowTheList}
                  SODetails={SODetails}
                  filterDetails={this.props.filterDetails}
                  AllCategoryData={this.state.insightintelligenceData}
                  roledata={this.state.operationArray}
                  isRelatorBlockForOffering={this.state.IsRelatorBlock}
                  attributeLabels={this.state.attributeLabels}
                />                
                
              </div>
            </div>
          );
        } //{rowTheList.map((row) => { return  })}
      });
    }
    else{
      list.push(
        <div className="outcome-table">
          <div className="outcome-row">      
          </div>
        </div>
      );
    }
  }

    return list;
  };

  getInsightsIntelligenceDetails() {
    let parsedResponse = this.props.SODetails;

    let filterClient = this.props.filterDetails.Client;
    let filterProcess = this.props.filterDetails.process;
    let filterLocations = this.props.filterDetails.Locations;
    let filterDate = this.props.filterDetails.Month;
  }

  displayInIBasedOnCategory = () => {
    var data = store.getState();

    
    this.setState({      
    attributeLabels : data.BOMasterReducers.attributeLabels.filter(z => z.ApplicableForDD === false).length > 0 ? data.BOMasterReducers.attributeLabels.filter(z => z.ApplicableForDD === false)[0] : {},
    // this.state.attributeLabels= data.BOMasterReducers.attributeLabels.filter(z=>z.ApplicableForDD===false)[0];
    insightintelligenceData : data.intelligentInsightMasterReducers["insightandintelligence"],
    IsRelatorBlock : data.CurrentFilter.IsRelatorBlock
  },()=>{
    let categoryID = 4;
    let AnalyticsGroup = [];
    let AnalyticsAppsAIAdvisorsCategory = this.state.insightintelligenceData.filter(
      IIDetails =>
        IIDetails.ParentSOCategoryID === categoryID ||
        IIDetails.SOCategoryID === categoryID
    );
    if(AnalyticsAppsAIAdvisorsCategory != null && AnalyticsAppsAIAdvisorsCategory.length > 0){
      AnalyticsAppsAIAdvisorsCategory.map((row, index) => {
        AnalyticsGroup.push(
          this.getKpiMetrics("Category", row, this.props.filterDetails)
        );
        this.setState({ AnalyticsmetricDetailsCategory: AnalyticsGroup });
      });
    }
    else {
      AnalyticsGroup.push(
        this.getKpiMetrics("Category", {}, {})
      );
      this.setState({ AnalyticsmetricDetailsCategory: AnalyticsGroup });
    }
  });
  }
  displayInsightsIntelligenceDetails() {
    
    let AnalyticsGroup = [];
    let OtherGroup = [];
    var data = store.getState();

    // this.setState({
    //   attributeLabels: data.BOMasterReducers.attributeLabels
    // });
      //some delay happening in above statement so empty object being sent to the child components
    this.state.attributeLabels= data.BOMasterReducers.attributeLabels;
    this.state.insightintelligenceData =
      data.intelligentInsightMasterReducers["insightandintelligence"];
    this.state.IsRelatorBlock = data.CurrentFilter.IsRelatorBlock;

    // if (this.state.insightintelligenceData.lengh > 0) {
    let allData = this.state.insightintelligenceData.filter(
      IIDetails => IIDetails.ParentSOCategoryID === 4
    );

    let AnalyticsAppsAIAdvisors =
      allData != undefined ? (allData.length > 0 ? {} : {}) : {};//allData[0]
    //response.data.map((obj) => { return obj.ChildSOCategories.filter((child) => child.SOCategoryName === "Analytics Apps & AI Advisors") })
    let OtherProcessInsights =
      allData != undefined ? (allData.length > 1 ? {} : {}) : {};//allData[1]

    // IISegregation.map((obj) => { return obj.ChildSOCategories.filter((child) => child.SOCategoryName === "Other Process Insights") })
    this.setState({
      AnalyticsAppsAIAdvisorsData: AnalyticsAppsAIAdvisors,
      OtherProcessInsightsData: OtherProcessInsights
    });
    
    if (AnalyticsAppsAIAdvisors != undefined) {
      this.setState({
        AnalyticsmetricDetails: this.getKpiMetrics(
          "",
          AnalyticsAppsAIAdvisors,
          this.props.filterDetails
        )
      });
      
      let AnalyticsAppsAIAdvisorsID = AnalyticsAppsAIAdvisors.SOCategoryID;

      let AnalyticsAppsAIAdvisorsChilds = this.state.insightintelligenceData.filter(
        IIDetails =>
          IIDetails.ParentSOCategoryID === AnalyticsAppsAIAdvisorsID ||
          IIDetails.SOCategoryID === AnalyticsAppsAIAdvisorsID
      );
      AnalyticsAppsAIAdvisorsChilds.map((row, index) => {
        AnalyticsGroup.push(
          this.getKpiMetrics("Category", row, this.props.filterDetails)
        );
        this.setState({ AnalyticsmetricDetailsCategory: AnalyticsGroup });
      });
    }
    if (OtherProcessInsights != undefined) {
      this.setState({
        otherMetricDetails: this.getKpiMetrics(
          "",
          OtherProcessInsights,
          this.props.filterDetails
        )
      });

      let OtherProcessInsightsID = OtherProcessInsights.SOCategoryID;
      let OtherProcessInsightsChilds = this.state.insightintelligenceData.filter(
        IIDetails =>
          IIDetails.ParentSOCategoryID === OtherProcessInsightsID ||
          IIDetails.SOCategoryID === OtherProcessInsightsID
      );

      OtherProcessInsightsChilds.map((row, index) => {
        OtherGroup.push(
          this.getKpiMetrics("Category", row, this.props.filterDetails)
        );
        this.setState({ otherMetricDetailsCategory: OtherGroup });
      });
    }
    // }
  }

  componentWillMount() {
    this.getInsightsIntelligenceDetails();
  }

  getFeatureDetails = async () => {
    let operation = [];
    let featureDetails1 = this.props.fullroledata;
    let operationArray;
    
    return new Promise((resolve, reject) => {
      featureDetails1.map(i => {
        if (i.FeatureName === "Master Template Configurator") {
            operationArray = i.Operations
            return operationArray
        }
      });
      
      this.setState({ roledata: operationArray }, () => {
        resolve();
      });
    });
  }
  loadFirst = async () => {
    await this.getFeatureDetails();
    this.setOperations();
    this.getInsightsIntelligenceDetails();
    //this.displayInsightsIntelligenceDetails();
    this.displayInIBasedOnCategory();

  }
  componentDidMount() {
    this.loadFirst();
    store.subscribe(() => {
      var data = store.getState();
      this.setState({
        insightintelligenceData:
          data.intelligentInsightMasterReducers["insightandintelligence"]
      });

      //this.displayInsightsIntelligenceDetails();
      this.displayInIBasedOnCategory();
    });
  }
  componentDidUpdate(prevProps){     
        if(!_.isEmpty(this.props.headerLabel) && !_.isNil(prevProps.headerLabel) && this.props.headerLabel != prevProps.headerLabel) {
            let pageHeader = this.props.headerLabel;
            this.setState({ pageHeader });
        }
    }
  deleteEntry = data => {
    store.dispatch({
      type: "Remove_APPADVISORS_MASTER",
      payload: data
    });
  };

  render() {
    return (
      <>
      {store.getState().CurrentFilter.languageData.id==1 ?
        <div className="row">
          <ExportCsv csvProp = {this.props.filterDetails.process}/>
          <div className="col-lg-12">
          {this.props.roledata != undefined && store.getState().CurrentFilter.languageData.id==1 &&
                        this.props.roledata.includes("Add") ? (
            <div className="title-with-icon d-flex align-items-center mb-4">
            
              <div className="list-icon mtc-list-icon">
                <span
                  onClick={e => {
                    this.dealpopup("Metric Category Pop", e, "", "");
                  }}
                >
                   <i className="far fa-plus plus-icon-fix"></i>
                </span>
              </div>
            </div>
            ) : null}
          </div>
        </div>
        :null}

        <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="outcome-highlight insightsNew no-whiteShadow no-table-layout tilecard-border">
                <>
                  <div className="title-with-icon">
                    <h1>
                      
                      { this.props.headerLabel }
                    </h1>                    
                  </div>
                </>
            </div>
            <>
                  {this.state.AnalyticsmetricDetailsCategory.length != 0
                    ? this.state.AnalyticsmetricDetailsCategory.map(data => {
                        
                        return <div className="outcome-highlight insightsNew tilecard-border no-whiteShadow no-table-layout">{data}</div>;
                      })
                    : <div className="emptymetric-msg">
                    <img alt="" src={emptycomponent} width="80px" height="80px" />
                  <h6>{i18n.t("Please click on '+' to add category")}</h6>
                </div>}
            </>
            
            
            <div className="outcome-highlight insightsNew tilecard-border no-whiteShadow no-table-layout mb-3">
              {this.state.OtherProcessInsightsData.SOCategoryName ? (
                <>
                  {/*<div className="title-with-icon">
                    <h1>
                      {this.state.OtherProcessInsightsData.SOCategoryName}
                    </h1>
                    <div className="list-icon">
                      {this.props.roledata != undefined &&
                        this.props.roledata.includes("Add") && (
                          <span
                            onClick={e => {
                              this.dealpopup("1", e, "");
                            }}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </span>
                        )}
                      {this.props.roledata != undefined &&
                        this.props.roledata.includes("Delete") && (
                          <span
                            onClick={e => {
                              this.deleteCategory(
                                this.state.OtherProcessInsightsData,
                                this.props.filterDetails,
                                e
                              );
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </span>
                        )}
                    </div>
                  </div>*/}
                  {/* {this.state.otherMetricDetails} */}
                  {this.state.otherMetricDetails != null &&
                  this.state.otherMetricDetailsCategory.length != 0
                    ? this.state.otherMetricDetailsCategory.map(data => {
                        return data;
                      })
                    : this.state.otherMetricDetailsCategory}
                </>
              ) : null}
            </div>
          </div>

          {this.state.viewmore &&
          (this.state.type === "0" || this.state.type === "1") ? (
            <InsightIntelligencePopUp
              show={true}
              onClose={this.onClose}
              isRelatorBlockForOffering={this.state.IsRelatorBlock}
              Details={this.state.data}
              AllCategoryData={this.state.insightintelligenceData}
              filterDetails={this.props.filterDetails}
              type={this.state.type}
              attributeLabels={this.state.attributeLabels}
              setCategory={this.state.setCategory}
            />
          ) : null}
          {/* {this.state.viewmore && this.state.type === "1" ? <InsightIntelligencePopUp show={true} onClose={this.onClose} Details={this.state.data} AllCategoryData={this.state.insightintelligenceData} filterDetails={this.props.filterDetails} type={this.state.type} /> : null} */}
          {this.state.viewmore && this.state.type === "Metric Category Pop" ? (
            <IIMetricGroupPopUp
              showPop={
                this.props.roledata != undefined &&
                this.props.roledata.includes("Add")
              }
              onClose={this.onClose}
              Details={this.state.data}
              AllCategoryData={this.state.insightintelligenceData}
              filterDetails={this.props.filterDetails}
              type={this.state.type}
              roledata={this.props.roledata}
            />
          ) : null}
          {this.state.viewmore && this.state.type === "add kpi" ? (
            <InsightsDataPopUp
              show={
                this.props.roledata != undefined &&
                this.props.roledata.includes("Edit")
              }
              onClose={this.onClose}
              Details={this.state.data}
              attributes={this.props.attributes}
              isRelatorBlockForOffering={this.state.IsRelatorBlock}
              attributeLabels={this.state.attributeLabels}
              AllCategoryData={this.state.insightintelligenceData}
              filterDetails={this.props.filterDetails}
              isKpi={this.state.type}
              roledata={this.props.roledata}
            />
          ) : null}
        </div>
      </>
    );
  }
}
