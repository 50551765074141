import React from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import { LocalApiBaseUrl } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import MultiSelect from "react-multi-select-component";
import { isEqual } from "date-fns";

const overrideStrings = {
  selectSomeItems: "Select",
  allItemsAreSelected: "All items selected.",
  selectAll: "Select All ",
  search: "Search",
};

export default class OneTimeSetupViewPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.show,
      apivalue: "",
      Display: false,
      viewmore: false,
      formIsValid: false,
      SourceDetails: [],
      isPropsLoaded: false,

      SourceClientOptions: [],
      selectedClientOption: [],
      SourceOfferingOptions: [],
      selectedOfferingOption: [],
      SourceSubOfferingOptions: [],
      selectedSubOfferingOption: [],
      SourceLocationOptions: [],
      selectedLocationsOption: [],

      filterDisplay: { cl: false, off: false, sub: false, loc: false },
      friendlyNames: { cl: "", off: "", sub: "", loc: "" },
      multiselect: { cl: false, off: false, sub: false, loc: false },
      isApiCalled: { cl: false, off: false, sub: false, loc: false },

      OfferingOptions: [],
      LocationOptions: [],
      processOptions: [],
      DestinationOfferingValueID: 0,

      OneTimeSetupModel: {
        isActive: true,
        destinationClientId: 0,
        destinationClientName: "",

        destinationOfferingId: 0,
        destinationOfferingName: "",

        destinationSubOfferingId: 0,
        destinationSubOfferingName: "",

        destinationLocationId: 0,
        destinationLocationName: "",

        sourceConnectorID: 0,
        sourceConnectorName: "",

        sourceDealID: 0,
        sourceDealName: "",

        sourceClientId: 0,
        sourceClientName: [],

        sourceOfferingId: 0,
        sourceOfferingName: [],

        sourceSubOfferingId: 0,
        sourceSubOfferingName: [],

        sourceLocationId: 0,
        sourceLocationName: [],
      },
    };

    this.onSourceChange = this.onSourceChange.bind(this);

    this.handleClose = this.handleClose.bind(this);
    this.getGetConnectorMaster = this.getGetConnectorMaster.bind(this);
  }

  ////////---- Stand alone Methods

  componentDidMount() {
    this.getGetConnectorMaster();
    if (this.props.data) {
      let OneTimeSetupModel = this.props.data;
      this.setState({
        show: this.props.show,
        OneTimeSetupModel,

        formIsValid: true,
      });
      this.getFilterDetails(this.props.data.ConnectorID);
    }
  }
  handleReset() {}
  handleClose(e) {
    this.props.close();
    this.handleHide();
  }
  handleHide(e) {
    this.setState({ show: false });

    this.props.close();
    //this.props.onSave()
  }
  handleRejectHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onRejectClose();
      }
    } else {
      this.props.onRejectClose();
    }
  }
  handleApproveHide(e) {
    this.setState({ show: false });
    if (!_.isNil(e)) {
      if (e.target.textContent !== "×") {
        this.props.onApproveClose();
      }
    } else {
      this.props.onApproveClose();
    }
  }
  passToConfirmation(data) {
    this.props.enteredData(data.PoNumber);
  }

  ///------------ON Deal side Get Methods
  getLocation(processValueID) {
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetLocation`, {
        params: { OrgEntityID: this.state.OneTimeSetupModel.DestinationClientID, OfferingID: processValueID },
      })
      .then((response) => {
        if (this.props.data != null) {
        } else {
          this.setState({
            LocationOptions: response.data,
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }
  getOffering(OrgEntityID) {
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
        params: { OrgEntityID: OrgEntityID, featureName: "OneTimeSetup Approval" },
      })
      .then((response) => {
        this.setState({
          OfferingOptions: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  ///------------ON Deal side Change Methods
  onDestinationOfferingsChange(event) {
    let DestinationOfferingValueID = event.target.value;
    let processOptions = event.target.value
      ? this.state.OfferingOptions.filter(
          (data) => String(data.OfferingID) === String(event.target.value)[0].ChildOfferings
        )
      : [];
    this.setState({
      DestinationOfferingValueID: DestinationOfferingValueID,
      processOptions: processOptions,
    });
  }
  onDestinationProcessChange() {
    let OneTimeSetupModel = this.state.OneTimeSetupModel;
    var event = document.getElementById("Process");
    OneTimeSetupModel.destinationSubOfferingId = event.options[event.selectedIndex].value;
    OneTimeSetupModel.destinationSubOfferingName = event.options[event.selectedIndex].label;
    this.getLocation(OneTimeSetupModel.destinationSubOfferingId);
    this.setState({
      OneTimeSetupModel: OneTimeSetupModel,
    });
  }
  onDestinationLocationChange(event) {
    let OneTimeSetupModel = this.state.OneTimeSetupModel;
    event = document.getElementById("Location");
    OneTimeSetupModel.destinationLocationId = event.options[event.selectedIndex].value;
    OneTimeSetupModel.destinationLocationName = event.options[event.selectedIndex].label;
    this.setState({
      OneTimeSetupModel: OneTimeSetupModel,
    });
  }

  ///------------ON Source side Get Methods
  getGetConnectorMaster() {
    let source = [];
    axiosInstance
      .get(`${LocalApiBaseUrl}ConnectorMapping/GetConnectorMaster`)
      .then((response) => {
        source = response.data;
        let sourceValues = source.map((x) => (
          <option key={x.ConnectorID} value={x.ConnectorID}>
            {x.ConnectorName}
          </option>
        ));
        this.setState({ sourceOptions: sourceValues });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }
  getFilterDetails(ConnectorId) {
    const { filterDisplay, friendlyNames, multiselect } = this.state;

    axiosInstance
      .get(`${LocalApiBaseUrl}ConnectorMapping/SourceSystemFilter`, {
        params: { sourceId: ConnectorId },
      })
      .then((row) => {
        let cell = [];
        if (row.data.response[0].FieldName === "Client") {
          cell = row.data.response[0];
          filterDisplay.cl = cell.isVisible;
          friendlyNames.cl = cell.friendlyName;
          multiselect.cl = cell.multiSelection;
        }
        if (row.data.response[1].FieldName === "Offering") {
          cell = row.data.response[1];
          filterDisplay.off = cell.isVisible;
          friendlyNames.off = cell.friendlyName;
          multiselect.off = cell.multiSelection;
        }
        if (row.data.response[2].FieldName === "SubOffering") {
          cell = row.data.response[2];
          filterDisplay.sub = cell.isVisible;
          friendlyNames.sub = cell.friendlyName;
          multiselect.sub = cell.multiSelection;
        }
        if (row.data.response[3].FieldName === "Location") {
          cell = row.data.response[3];
          filterDisplay.loc = cell.isVisible;
          friendlyNames.loc = cell.friendlyName;
          multiselect.loc = cell.multiSelection;
        }

        this.setState({
          filterDisplay: filterDisplay,
          friendlyNames: friendlyNames,
          multiselect: multiselect,
        });

        this.filterSelectionControl();
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }
  filterSelectionControl() {
    const { isApiCalled, filterDisplay } = this.state;
    let isVisible = filterDisplay;

    if (isVisible.cl && !isApiCalled.cl) {
      this.getSourceClients();
      isApiCalled.cl = true;
      this.setState({
        isApiCalled: isApiCalled,
      });
    } else if (isVisible.off && !isApiCalled.off) {
      this.getSourceOfferings();
      isApiCalled.off = true;
      this.setState({
        isApiCalled: isApiCalled,
      });
    } else if (isVisible.sub && !isApiCalled.sub) {
      this.getSourceSubOfferings();
      isApiCalled.sub = true;
      this.setState({
        isApiCalled: isApiCalled,
      });
    } else if (isVisible.loc && !isApiCalled.loc) {
      this.getSourceLocations();
      isApiCalled.loc = true;
      this.setState({
        isApiCalled: isApiCalled,
      });
    }
  }
  getSourceClients() {
    const { OneTimeSetupModel, isPropsLoaded } = this.state;
    let sourceId = OneTimeSetupModel.ConnectorID;

    if(OneTimeSetupModel.ConnectorName === "SVM"){
      this.setState({ SourceClientOptions: [], selectedClientOption: [{label: OneTimeSetupModel.SourceClientName, value:OneTimeSetupModel.SourceClientName}] });
      return;
    }

    axiosInstance
      .get(`${LocalApiBaseUrl}ConnectorMapping/GetSourceClient?sourceId=${sourceId}`)

      .then((response) => {
        let SourceClientOptions = [];
        let selectedClientOption = [];
        let sortedClients = response.data.clientList.sort((a, b) => (a.name > b.name ? 1 : -1));

        sortedClients.forEach((row, i) => {
          if (String(row.name) !== "") {
            let dummy = [];
            dummy.label = row.name;
            dummy.value = row.name;
            SourceClientOptions.push(dummy);

            if (this.props.data && isPropsLoaded === false) {
              if (OneTimeSetupModel.SourceClientName.toLowerCase() === row.name.toLowerCase()) {
                selectedClientOption.push(dummy);
              }
            }
          }
        });
        this.setState({ SourceClientOptions: SourceClientOptions, selectedClientOption: selectedClientOption });
        if (this.props.data && isPropsLoaded === false) {
          this.filterSelectionControl();
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }
  getSourceOfferings() {
    const { OneTimeSetupModel, isPropsLoaded } = this.state;
    let sourceId = OneTimeSetupModel.ConnectorID;
    let clientName = OneTimeSetupModel.SourceClientName.toString();
    clientName = encodeURIComponent(clientName);
    axiosInstance
      .get(`${LocalApiBaseUrl}ConnectorMapping/GetSourceOffering?sourceId=${sourceId}&clientName=${clientName}`)
      .then((response) => {
        let SourceOfferingOptions = [];
        let selectedOfferingOption = [];
        let sortedOffering = response.data.offeringList.sort((a, b) => (a.name > b.name ? 1 : -1));

        sortedOffering.forEach((row, i) => {
          if (String(row.name) !== "") {
            let dummy = [];
            dummy.label = row.name;
            dummy.value = row.name;
            dummy.id = row.OrgEntityID;
            SourceOfferingOptions.push(dummy);

            if (this.props.data && isPropsLoaded === false) {
              if (OneTimeSetupModel.SourceOfferingName.toLowerCase() === row.name.toLowerCase()) {
                selectedOfferingOption.push(dummy);
              }
            }
          }
        });
        this.setState({ SourceOfferingOptions: SourceOfferingOptions, selectedOfferingOption: selectedOfferingOption });
        if (this.props.data && isPropsLoaded === false) {
          this.filterSelectionControl();
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }
  getSourceSubOfferings() {
    const { OneTimeSetupModel } = this.state;
    let sourceId = OneTimeSetupModel.ConnectorID;
    let clientName = OneTimeSetupModel.SourceClientName.toString();
    clientName = encodeURIComponent(clientName);
    let offeringName = OneTimeSetupModel.SourceOfferingName.toString();
    offeringName = encodeURIComponent(offeringName);
   
    axiosInstance
      .get(
        `${LocalApiBaseUrl}ConnectorMapping/GetSourceSubOffering?sourceId=${sourceId}&clientName=${clientName}&offeringName=${offeringName}`
      )

      .then((response) => {
        const { OneTimeSetupModel, isPropsLoaded } = this.state;
        let SourceSubOfferingOptions = [];
        let selectedSubOfferingOption = [];
        let sortedSubOffering = response.data.subOfferingList.sort((a, b) => (a.name > b.name ? 1 : -1));

        sortedSubOffering.forEach((row, i) => {
        
          if (String(row.name) !== "") {
            let dummy = [];
          
            dummy.label = row.name;
            dummy.value = row.name;
            SourceSubOfferingOptions.push(dummy);

          
            if (this.props.data && isPropsLoaded === false) {
            const matchIndex =   OneTimeSetupModel.SourceSubOfferingName.split('|').findIndex(( o) => o.toLowerCase() === row.name.toLowerCase() )
            if(matchIndex > -1)
             selectedSubOfferingOption.push(dummy);
             
            }
          }
        });
        this.setState({
          SourceSubOfferingOptions: SourceSubOfferingOptions,
          selectedSubOfferingOption: selectedSubOfferingOption,
        });
        if (this.props.data && isPropsLoaded === false) {
          this.filterSelectionControl();
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }
  getSourceLocations() {
  const { OneTimeSetupModel, isPropsLoaded } = this.state;
  let sourceId = OneTimeSetupModel.ConnectorID;
  let clientName = OneTimeSetupModel.SourceClientName.toString();

  let offeringName = OneTimeSetupModel.SourceOfferingName.toString();

  let subOfferingname = OneTimeSetupModel.SourceSubOfferingName.toString();

    // new method
    let data = {};
    data.sourceId = sourceId;
    data.clientName = clientName;
    data.offeringName = offeringName;
    data.subOfferingname = subOfferingname;
    axiosInstance
      .post(`${LocalApiBaseUrl}ConnectorMapping/GetSourceLocation`, data)
      .then((response) => {
        let SourceLocationOptions = [];
        let selectedLocationsOption = [];
        let sortedlocation = response.data.sort((a, b) => (a.name > b.name ? 1 : -1));
        sortedlocation.forEach((row, i) => {
          if (String(row.name) !== "") {
            let dummy = [];
            dummy.label = row.name;
            dummy.value = row.name;
            SourceLocationOptions.push(dummy);
            if (this.props.data && isPropsLoaded === false) {
              const matchIndex =   OneTimeSetupModel.SourceLocationName.split('|').findIndex(( o) => o.toLowerCase() === row.name.toLowerCase() )
            if(matchIndex > -1)
            selectedLocationsOption.push(dummy);
            }
          }
        });
        this.setState({
          SourceLocationOptions: SourceLocationOptions,
          selectedLocationsOption: selectedLocationsOption,
        });
        if (this.props.data && isPropsLoaded === false) {
          this.filterSelectionControl();
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  ///------------ ON Source side Change Methods
  onSourceChange(event) {
    const { OneTimeSetupModel, isApiCalled, filterDisplay } = this.state;

    OneTimeSetupModel.ConnectorID = event.target.value;
    var index = event.nativeEvent.target.selectedIndex;
    OneTimeSetupModel.ConnectorName = event.nativeEvent.target[index].text;

    filterDisplay.loc = false;
    filterDisplay.sub = false;
    filterDisplay.off = false;
    filterDisplay.cl = false;
    isApiCalled.loc = false;
    isApiCalled.sub = false;
    isApiCalled.off = false;
    isApiCalled.cl = false;
    OneTimeSetupModel.SourceLocationName = [];
    OneTimeSetupModel.SourceSubOfferingName = [];
    OneTimeSetupModel.SourceOfferingName = [];
    OneTimeSetupModel.sourceClientName = [];

    this.setState({
      OneTimeSetupModel: OneTimeSetupModel,
      isPropsLoaded: true,
      isApiCalled: isApiCalled,
      filterDisplay: filterDisplay,
      SourceLocationOptions: [],
      selectedLocationsOption: [],
      SourceSubOfferingOptions: [],
      selectedSubOfferingOption: [],
      SourceOfferingOptions: [],
      selectedOfferingOption: [],
      SourceClientOptions: [],
      selectedClientOption: [],
    });

    this.setState({
      OneTimeSetupModel: OneTimeSetupModel,
    });

    // callingFilterDescription function
    this.getFilterDetails(event.target.value);

    // this.filterSelectionControl();
  }
  onSourceClientSelect(selectedList) {
    if (!this.props.dataoperation) {
      let OneTimeSetupModel = this.state.OneTimeSetupModel;
      let isApiCalled = this.state.isApiCalled;
      isApiCalled.loc = false;
      isApiCalled.sub = false;
      isApiCalled.off = false;
      OneTimeSetupModel.SourceLocationName = [];
      OneTimeSetupModel.SourceSubOfferingName = [];
      OneTimeSetupModel.SourceOfferingName = [];

      this.setState({
        OneTimeSetupModel: OneTimeSetupModel,

        isApiCalled: isApiCalled,
        SourceLocationOptions: [],
        selectedLocationsOption: [],
        SourceSubOfferingOptions: [],
        selectedSubOfferingOption: [],
        SourceOfferingOptions: [],
        selectedOfferingOption: [],
        isPropsLoaded: true,
      });

      let selectedClientOption = this.state.selectedClientOption;
      if (this.state.multiselect.cl) {
        let tempClient = [];
        let tempClientValue = [];

        var len = selectedList.length;
        for (var i = 0; i < len; i++) {
          tempClient.push(selectedList[i]);
          tempClientValue.push(selectedList[i].value);
        }
        selectedClientOption = tempClient;
        OneTimeSetupModel.SourceClientName = tempClientValue;
      } else {
        let tempClient = [];
        let tempClientValue = [];
        len = selectedList.length;
        tempClient.push(selectedList[len - 1]);
        tempClientValue.push(selectedList[len - 1].value);

        selectedClientOption = tempClient;
        OneTimeSetupModel.SourceClientName = tempClientValue;
      }
      this.setState({
        selectedClientOption: selectedClientOption,
        OneTimeSetupModel: OneTimeSetupModel,
      });

      this.filterSelectionControl();
    }
  }
  onSourceOfferingSelect(selectedList) {
    if (!this.props.dataoperation) {
      let OneTimeSetupModel = this.state.OneTimeSetupModel;
      let isApiCalled = this.state.isApiCalled;
      isApiCalled.loc = false;
      isApiCalled.sub = false;
      OneTimeSetupModel.SourceLocationName = [];
      OneTimeSetupModel.SourceSubOfferingName = [];

      this.setState({
        OneTimeSetupModel: OneTimeSetupModel,
        isPropsLoaded: true,

        isApiCalled: isApiCalled,
        SourceLocationOptions: [],
        selectedLocationsOption: [],
        SourceSubOfferingOptions: [],
        selectedSubOfferingOption: [],
      });
      let selectedOfferingOption = this.state.selectedOfferingOption;
      if (this.state.multiselect.off) {
        let tempOffering = [];
        let tempOfferingValue = [];

        var len = selectedList.length;
        for (var i = 0; i < len; i++) {
          tempOffering.push(selectedList[i]);
          tempOfferingValue.push(selectedList[i].value);
        }
        selectedOfferingOption = tempOffering;
        OneTimeSetupModel.SourceOfferingName = tempOfferingValue;
      } else {
        let tempOffering = [];
        let tempOfferingValue = [];
        len = selectedList.length;
        tempOffering.push(selectedList[len - 1]);
        tempOfferingValue.push(selectedList[len - 1].value);

        selectedOfferingOption = tempOffering;
        OneTimeSetupModel.SourceOfferingName = tempOfferingValue;
      }
      this.setState({
        selectedOfferingOption: selectedOfferingOption,
        OneTimeSetupModel: OneTimeSetupModel,
      });
      this.filterSelectionControl();
    }
  }
  onSourceProcessSelect(selectedList) {
    if (!this.props.dataoperation) {
      let OneTimeSetupModel = this.state.OneTimeSetupModel;
      let isApiCalled = this.state.isApiCalled;
      isApiCalled.loc = false;
      OneTimeSetupModel.SourceLocationName = [];

      this.setState({
        OneTimeSetupModel: OneTimeSetupModel,
        isPropsLoaded: true,
        isApiCalled: isApiCalled,
        SourceLocationOptions: [],
        selectedLocationsOption: [],
      });

      let selectedSubOfferingOption = this.state.selectedSubOfferingOption;
      if (this.state.multiselect.sub) {
        let tempProcess = [];
        let tempProcessValue = [];
        var len = selectedList.length;
        for (var i = 0; i < len; i++) {
          tempProcess.push(selectedList[i]);
          tempProcessValue.push(selectedList[i].value);
        }
        selectedSubOfferingOption = tempProcess;
        OneTimeSetupModel.SourceSubOfferingName = tempProcessValue;
      } else {
        let tempProcess = [];
        let tempProcessValue = [];

        len = selectedList.length;
        tempProcess.push(selectedList[len - 1]);
        tempProcessValue.push(selectedList[len - 1].value);

        selectedSubOfferingOption = tempProcess;
        OneTimeSetupModel.SourceSubOfferingName = tempProcessValue;
      }
      this.setState({
        selectedSubOfferingOption: selectedSubOfferingOption,
        OneTimeSetupModel: OneTimeSetupModel,
      });
      this.filterSelectionControl();
    }
  }
  onSourceLocationSelect(selectedList) {
    if (!this.props.dataoperation) {
      let OneTimeSetupModel = this.state.OneTimeSetupModel;
      let selectedLocationsOption = this.state.selectedLocationsOption;
      if (this.state.multiselect.loc) {
        let tempLocation = [];
        let tempLocationValue = [];
        var len = selectedList.length;
        for (var i = 0; i < len; i++) {
          tempLocation.push(selectedList[i]);
          tempLocationValue.push(selectedList[i].value);
        }
        selectedLocationsOption = tempLocation;
        OneTimeSetupModel.SourceLocationName = tempLocationValue;
      } else {
        let tempLocation = [];
        let tempLocationValue = [];
        len = selectedList.length;
        tempLocation.push(selectedList[len - 1]);
        tempLocationValue.push(selectedList[len - 1].value);
        selectedLocationsOption = tempLocation;
        OneTimeSetupModel.SourceLocationName = tempLocationValue;
      }
      this.setState({
        selectedLocationsOption: selectedLocationsOption,
        isPropsLoaded: true,
        OneTimeSetupModel: OneTimeSetupModel,
      });
      this.filterSelectionControl();
    }
  }

  render() {
    const { data } = this.props;
    const {
      SourceClientOptions,
      selectedLocationsOption,
      SourceLocationOptions,
      multiselect,
      show,
      OneTimeSetupModel,
      sourceOptions,
      filterDisplay,
      friendlyNames,
      selectedClientOption,
      SourceOfferingOptions,
      selectedOfferingOption,
      SourceSubOfferingOptions,
      selectedSubOfferingOption,
    } = this.state;
    console.log("qq" ,OneTimeSetupModel.ProgramName,OneTimeSetupModel.ProgramID)
    const programMulteSelectBar = (
      <>
        <MultiSelect
          options={[]}
          value={[{label:OneTimeSetupModel.ProgramName,value:OneTimeSetupModel.ProgramId}]}
          labelledBy="Program Name"
          disabled={true}
          shouldToggleOnHover={false}
          hasSelectAll={false}
        />
      </>
    );
    const clientMultipleselectbar = (
      <React.Fragment>
        <MultiSelect
          options={SourceClientOptions}
          value={selectedClientOption}
          labelledBy="OrgEntityName"
          disabled={true}
          shouldToggleOnHover={false}
          overrideStrings={overrideStrings}
          hasSelectAll={multiselect.cl}
        />
      </React.Fragment>
    );
    const offeringMultipleselectbar = (
      <React.Fragment>
        <MultiSelect
          options={SourceOfferingOptions}
          value={selectedOfferingOption}
          disabled={true}
          labelledBy="OrgEntityName"
          shouldToggleOnHover={false}
          overrideStrings={overrideStrings}
          hasSelectAll={multiselect.off}
        />
      </React.Fragment>
    );
    const subOfferingMultipleselectbar = (
      <React.Fragment>
        <MultiSelect
          options={SourceSubOfferingOptions}
          value={selectedSubOfferingOption}
          disabled={true}
          labelledBy="OrgEntityName"
          shouldToggleOnHover={false}
          overrideStrings={overrideStrings}
          hasSelectAll={multiselect.sub}
        />
      </React.Fragment>
    );
    const locationMultipleselectbar = (
      <React.Fragment>
        <MultiSelect
          options={SourceLocationOptions}
          value={selectedLocationsOption}
          disabled={true}
          labelledBy="OrgEntityName"
          shouldToggleOnHover={false}
          overrideStrings={overrideStrings}
          hasSelectAll={multiselect.loc}
        />
      </React.Fragment>
    );
    return (
      <>
        {/* IF API IS LOADING THEN SHOW THE LOADER */}

        <Modal
          show={show}
          onHide={this.handleHide.bind(this)}
          scrollable={true}
          className="modal fade"
          size="medium"
          backdrop="static"
          id="dealpopup"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title" id="dealprofile">
              One Time Setup
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-section">
                <p className="fs-medium">
                  Mapping source connector fields with its corresponding fields in SynOps for fetching metric data
                </p>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-12">
                        <h3 className="grid-heading border-0 mt-2 mb-3">SynOps Details</h3>
                      </div>
                      <div className="form-group col-lg-12">
                        <label for="">Destination System</label>
                        <input type="text" maxlength="200" className="form-control" id="" value="SynOps" disabled />
                      </div>
                      <div className="form-group col-lg-12">
                        <label for="">Client Name</label>
                        <input
                          type="text"
                          maxlength="200"
                          className="form-control"
                          id=""
                          value={OneTimeSetupModel.DestinationClientName}
                          disabled
                        />
                      </div>
                      {
                        <div className="form-group col-lg-12">
                          <label for="">Offering</label>
                          <input
                            id="Offering"
                            className="form-control"
                            ref="OfferingDropDown"
                            disabled={true}
                            value={OneTimeSetupModel.DestinationOfferingName}
                          ></input>
                        </div>
                      }
                      {
                        <div className="form-group col-lg-12">
                          <label for="">Sub Offering</label>
                          <input
                            id="Process"
                            className="form-control"
                            ref="ProcessDropDown"
                            disabled={true}
                            value={OneTimeSetupModel.DestinationSubOfferingName}
                          ></input>
                        </div>
                      }
                      {
                        <div className="form-group col-lg-12">
                          <label for="">Location </label>
                          <input
                            id="Location"
                            className="form-control"
                            ref="ProcessDropDown"
                            disabled={true}
                            value={OneTimeSetupModel.DestinationLocationName}
                          ></input>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-12">
                        <h3 className="grid-heading border-0 mt-2 mb-3">Source Details</h3>
                      </div>
                      <div className="form-group col-lg-12">
                        {" "}
                        {/* copy Paste field: vignesh */}
                        <label for="" className="source-system-padding">
                          Source System
                        </label>
                        <select
                          className="form-control"
                          disabled={data}
                          onChange={this.onSourceChange}
                          value={OneTimeSetupModel.ConnectorID}
                        >
                          <option value="0">Select Source</option>
                          {sourceOptions}
                          <option value="-1">Others</option>
                          <option value="-2">Manual</option>
                        </select>
                      </div>
                      {filterDisplay.cl ? (
                        <div className="form-group col-lg-12">
                          <label for="">{friendlyNames.cl}</label>
                          <div className="custom-multi-select">{clientMultipleselectbar}</div>
                        </div>
                      ) : null}
                       {filterDisplay.cl && OneTimeSetupModel.ConnectorName ==="SVM"? (
                        <div className="form-group col-lg-12">
                          <label for="">Program Name</label>
                          <div className="custom-multi-select">{programMulteSelectBar}</div>
                        </div>
                      ) : null}
                      {filterDisplay.off ? (
                        <div className="form-group col-lg-12">
                          <label for="">{friendlyNames.off}</label>
                          <div className="custom-multi-select">{offeringMultipleselectbar}</div>
                        </div>
                      ) : null}

                      {filterDisplay.sub ? (
                        <div className="form-group col-lg-12">
                          <label for="">{friendlyNames.sub}</label>
                          <div className="custom-multi-select">{subOfferingMultipleselectbar}</div>
                        </div>
                      ) : null}

                      {filterDisplay.loc ? (
                        <div className="form-group col-lg-12">
                          <label for="">{friendlyNames.loc}</label>
                          <div className="custom-multi-select">{locationMultipleselectbar}</div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          {
            <Modal.Footer>
              <div className="modal-btn">
                <input
                  type="Button"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.handleClose()}
                  value="Close"
                />
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}
