/* WO CODE FLOW:
This is the common file for all 3 screens(MTC/FD/Configurator) of WO Category, 
there is a 'screen' prop we are using to identify the screen.
Here in this file we are calling StepDetails,Step Summary & Metric Migration Components.
Step Summary Component calling : 
 1. Step Summary Component : to display all steps
 1. WODetails Component - setting active & inactive steps,
   setting data for Details tab for each step.
 2. AddMoreToolPopup is called here in Tool Catalog area- Displaying All Tools

Step Details Component calling:
  1. ToolListContainer Component
  2. CorouselDiv Component
  3. MetricListContainer Component
*/

import React, { Component } from "react";

import { Tabs, Tab } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import "./WorkOrchestration.css";
import $ from "jquery";
import _ from "lodash";

import i18n from "i18next";
import { connect } from "react-redux";
import StepSummary from "./StepSummary";
import StepDetails from "./StepDetails";
import AddWOStep_MTC from "./AddWOStep_MTC";
// import MetricMigration from "./MetricMigration";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import { LocalApiBaseUrl, getPrevMonth } from "../Shared/Constant";
import References from "../Shared/References"
import ExportCsv from "../SuperAdmin/ExportCsv";
import SynopsLoader from "../Shared/SynopsLoader";


axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


class WorkOrchestration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "",
            DispVal: 1,
            SOCategoryID: 0,
            cardSelectedSoCatID: 0,
            isFoundationToolsLoaded: false,
            showAllTools: false,
            showLoaderOnPage: false,
            //Setting the tab Names on the basis of Screen(MTC/Conf/FD)
            selectedTab: this.props.screen == "SuperAdmin" ? "Step Summary" : "Summary",
            //viewMigrationTabLocal: this.props.viewMigrationTab,
            exploreLinkList: [],
            exploreDrop: "none",
            metricRowDetails: [],
            metricSUbRowDetails: [],
            foundationAttr: [],
            FoundationToolsApi: [],
            ToolsCategory: [],
            ToolsUsed: [],
            metricRowDetails1: [],
            FTools_disable: [],
            exploreDrop: "none",
            original: [],
            toolCatalog: [],
            // GetMigrationSteps: [],
            // GetMigrationMetrics: [],
            addReferenceStatus: false,
            viewReferenceStatus: false,
            adminReferences: false,
            referencesExist: false,
            SoCatDataAttr: {
                AttributeID: 0,
                OrgEntityID: 0,
                OrgEntityName: null,
                OfferingID: 0,
                OfferingName: null,
                LocationID: 0,
                LocationName: null,
                SOCategoryID: 3,
                SOCategoryName: null,
                AttributeGroup: "Foundation Tools",
                AttributeName: "",
                AttributeValue: "Yes",
                CreatedUser: null,
                CreatedTS: "",
                UpdatedUser: null,
                UpdatedTS: "",
            },
            exploreToolsList: [],
        };
        this.selectFT = this.selectFT.bind(this);
        this.deleteFT = this.deleteFT.bind(this);
        this.cardClick = this.cardClick.bind(this);
        this.deleteStep = this.deleteStep.bind(this);
        this.getKPIMetric = this.getKPIMetric.bind(this);
        this.submitModalShow = this.submitModalShow.bind(this);
        this.submitModalClose = this.submitModalClose.bind(this);
        this.addStepModalShow = this.addStepModalShow.bind(this);
        this.addStepModalClose = this.addStepModalClose.bind(this);
        this.stepToggleHandler = this.stepToggleHandler.bind(this);
        this.addMetricModalShow = this.addMetricModalShow.bind(this);
        this.addMetricModalClose = this.addMetricModalClose.bind(this);
        // this.handleMigrationView = this.handleMigrationView.bind(this);
        this.exploreMoreToolsToggler = this.exploreMoreToolsToggler.bind(this);
        // this.setActiveTabAfterMigration = this.setActiveTabAfterMigration.bind(this);
        this.addReferenceModalShow = this.addReferenceModalShow.bind(this);

    }
    addReferenceModalShow() {
        this.setState({ addReferenceStatus: true });
    }
    viewReferenceModalShow = () => {
        this.setState({ viewReferenceStatus: true });
    };
    handleAddReferenceClose = () => {
        this.setState({ addReferenceStatus: false });
    }
    handleViewReferenceClose = () => {
        this.setState({ viewReferenceStatus: false });
    }
    handleDisplayReferencesWindow = () => {
        let roleData = store.getState().roleReducers.roleReducers;
        let referencesExist = roleData.filter(data => data.FeatureName === "References" && data.Operations.includes("View")).length > 0 ? true : false;
        let admin = false;
        let reference = roleData.filter(data => data.FeatureName === "References");
        if (reference.length > 0) {
            admin = roleData.filter(data => data.FeatureName === "References")[0].Operations.includes("Post") ? true : false;

        }
        this.setState({
            referencesExist: referencesExist,
            adminReferences: admin,
        })
    }

    //get steps for WO for Configurator & MTC
    componentWillReceiveProps() {
        this.getWODetails();
    }

    //Setting Data for different screens  
    componentWillMount() {
        let { screen } = this.props;
        switch (screen) {
            case "Configurator":
                this.getFoundationTools();
                break;
            case "Dashboard":
                this.getFoundationTools();
                break;
            case "SuperAdmin":
                this.getWODetails();
                store.subscribe(() => this.getWODetails());
                break;
            default:
                return null;
        }
    }

    //Setting Migration Tab Conditionally on Configurator Screen 
    componentDidMount() {
        //     if (this.props.screen === "Configurator") {
        //         this.setState({
        //             GetMigrationMetrics: this.props.GetMigrationMetrics,
        //             GetMigrationSteps: this.props.GetMigrationSteps,
        //         });
        //     }
        this.handleDisplayReferencesWindow();

    }
    //Setting Migration Metrics on Configurator Screen 
    // componentDidUpdate(prevProps) {

    //     if (this.props.screen === "Configurator") {
    //         if (
    //             prevProps.GetMigrationMetrics !== this.props.GetMigrationMetrics ||
    //             prevProps.GetMigrationSteps !== this.props.GetMigrationSteps
    //         ) {
    //             this.setState({
    //                 GetMigrationMetrics: this.props.GetMigrationMetrics,
    //                 GetMigrationSteps: this.props.GetMigrationSteps,
    //             });
    //         }
    //     }
    // }

    //Get Functions for Metrics/Steps/Foundation Tools/Tools Catalog Start
    getKPIMetric(
        offeringIdparam = {
            Client: 0,
            process: 0,
            Locations: 0,
            Month: getPrevMonth(),
        }
    ) {
        let filterProcess =
            offeringIdparam.process == undefined ? 0 : offeringIdparam.process;
        let filterLocations =
            offeringIdparam.Locations == undefined ? 0 : offeringIdparam.Locations;
        let filterClient =
            offeringIdparam.Client == undefined ? 0 : offeringIdparam.Client;
        let dateArray =
            offeringIdparam.Month == 0 ? [] : offeringIdparam.Month.split("-");
        let todayDate = "";
        if (dateArray.length == 2) {
            todayDate = "1-" + dateArray[0] + "-" + dateArray[1];
        } else {
            todayDate = offeringIdparam.Month;
        }
        this.setState({ showLoaderOnPage: true });
        if (
            filterProcess != 0 &&
            filterLocations != 0 &&
            filterClient != 0 &&
            todayDate != 0
        ) {
            axiosInstance
                .get(`${LocalApiBaseUrl}SOFilter/GetWorkOrchestration`, {
                    params: {
                        OrgEntityID: filterClient,
                        OfferingID: filterProcess,
                        LocationID: filterLocations,
                        MonthDT: todayDate,
                        LanguageID: this.props.languageId,
                    },
                })
                .then((response) => {
                    this.setState({
                        metricRowDetails1: response.data,
                        showLoaderOnPage: false,
                        SOCategoryID: response.data
                            .filter((data) => data.SOCategoryName_En != "Work Orchestration" && data.ParentSOCategoryID === 3)
                            .sort((a, b) =>
                                a.SOCategorySeq !== b.SOCategorySeq
                                    ? a.SOCategorySeq > b.SOCategorySeq
                                        ? 1
                                        : -1
                                    : a.SOCategoryName.toLowerCase() >
                                        b.SOCategoryName.toLowerCase()
                                        ? 1
                                        : -1
                            )[0].SOCategoryID,
                    });
                    this.setState({ original: response.data });
                    this.setState({
                        metricSUbRowDetails: response.data
                            .filter((data) => data.SOCategoryName_En != "Work Orchestration")
                            .sort((a, b) =>
                                a.SOCategorySeq !== b.SOCategorySeq
                                    ? a.SOCategorySeq > b.SOCategorySeq
                                        ? 1
                                        : -1
                                    : a.SOCategoryName.toLowerCase() >
                                        b.SOCategoryName.toLowerCase()
                                        ? 1
                                        : -1
                            )[0].kpiData,
                    });
                    this.setState({
                        metricRowDetails: response.data
                            .filter((data) => data.SOCategoryName_En != "Work Orchestration" && data.ParentSOCategoryID === 3)
                            .sort((a, b) =>
                                a.SOCategorySeq !== b.SOCategorySeq
                                    ? a.SOCategorySeq > b.SOCategorySeq
                                        ? 1
                                        : -1
                                    : a.SOCategoryName.toLowerCase() >
                                        b.SOCategoryName.toLowerCase()
                                        ? 1
                                        : -1
                            ),
                    }, () => this.getToolTypeforStep());

                    let FSData = response.data.filter(
                        (data) => data.SOCategoryName_En == "Work Orchestration"
                    )[0].SoCatDataAttr;
                    let FTSegregation = FSData.filter(
                        (toolsDetails) =>
                            toolsDetails.AttributeGroup_En === "Foundation Tools"
                    );
                    let foundAttr = [
                        ...new Set(
                            FTSegregation.map((eachvalue) => {
                                return eachvalue.AttributeName;
                            })
                        ),
                    ];
                    let foundAttrupperCase = foundAttr.map((x) => x.toUpperCase());
                    let difference = this.state.FoundationToolsApi.filter(
                        (x) => !foundAttrupperCase.includes(x.toUpperCase())
                    );

                    this.setState({
                        foundationAttr: foundAttr,
                        FTools_disable: difference,
                    });
                    let TUSegregation = response.data
                        .filter((data) => data.SOCategoryName_En != "Work Orchestration")
                        .sort((a, b) =>
                            a.SOCategorySeq !== b.SOCategorySeq
                                ? a.SOCategorySeq > b.SOCategorySeq
                                    ? 1
                                    : -1
                                : a.SOCategoryName.toLowerCase() >
                                    b.SOCategoryName.toLowerCase()
                                    ? 1
                                    : -1
                        )[0]
                        .SoCatDataAttr.filter(
                            (toolsDetails) => toolsDetails.AttributeGroup_En === "Tools Used"
                        );
                    //.map((data)=>data.filter((toolsDetails) => toolsDetails.AttributeGroup === "Tools Used")).filter((data)=> data.length>0);
                    let ToolsUsed = [
                        ...new Set(
                            TUSegregation.map((eachvalue) => {
                                return eachvalue.AttributeName;
                            })
                        ),
                    ];

                    //Tools mapping
                    this.setState({ ToolsUsed: ToolsUsed });
                })
                .catch((error) => {
                    // alert(error);

                    this.setState({ showLoaderOnPage: false });
                });
        }
    }

    getAttrData(SODetails = { SOCategoryID: 0 }) {
        let { screen } = this.props;
        switch (screen) {
            case "Configurator":
                let ToolsUsed = [
                    ...new Set(
                        this.state.metricRowDetails1
                            .filter((data) => data.SOCategoryID == SODetails.SOCategoryID)[0]
                            .SoCatDataAttr.filter(
                                (data) => data.AttributeGroup == "Tools Used"
                            )
                            .map((eachvalue) => eachvalue.AttributeName)
                    ),
                ];
                this.setState({ ToolsUsed: ToolsUsed });
                break;
            case "Dashboard":
                let soData = this.state.metricRowDetails1;
                if (this.state.SOCategoryID !== 0) {
                    soData = soData.filter(
                        (data) => data.SOCategoryID == this.state.SOCategoryID
                    );
                    let data = soData.filter(
                        (data) => data.AttributeGroup_En == "Tools Used"
                    );
                    ToolsUsed = [
                        ...new Set(data.map((eachvalue) => eachvalue.AttributeName)),
                    ];
                    this.setState({ ToolsUsed: ToolsUsed });
                } else {
                    soData = soData.filter(
                        (data) => data.key == this.state.SOCategoryID + 1
                    );
                    let data = soData.filter(
                        (data) => data.AttributeGroup_En == "Tools Used"
                    );
                    ToolsUsed = [
                        ...new Set(data.map((eachvalue) => eachvalue.AttributeName)),
                    ];
                    this.setState({ ToolsUsed: ToolsUsed });
                }
                break;
            case "SuperAdmin":
                return null;
                break;
            default:
                return null;
        }
    }


    getFoundationTools() {
        axiosInstance
            .get(`${LocalApiBaseUrl}SOFilter/GetDealInfo`, {
                params: {
                    OrgEntityID: this.props.filterDetails.Client,
                    LocationId: this.props.filterDetails.Locations,
                    OfferingId: this.props.filterDetails.process,
                    LanguageID: store.getState().CurrentFilter.languageData.id,
                },
            })
            .then((response) => {
                let DealID = response.data[0].DealID;

                axiosInstance
                    .get(`${LocalApiBaseUrl}IntelOpsCategory/GetFoundationTool`, {
                        params: {
                            LanguageID: this.props.languageId,
                            DealID: DealID,
                        },
                    })
                    .then((res) => {
                        this.setState((prev) => ({
                            ...prev,
                            isFoundationToolsLoaded: true,
                            FoundationToolsApi: res.data.map((d) => d.Toolname),
                        }));
                        this.props.screen == "Configurator"
                            ? this.getWODetails()
                            : this.getKPIMetric(this.props.filterDetails);
                        this.getToolsCatalog();
                        store.subscribe(() => this.getWODetails());
                    })
                    .catch((error) => {
                        trycatchAlertPopup(error);
                    });
            }).catch((error) => {
                trycatchAlertPopup(error);
            })
    };

    getToolsCatalog() {
        //toolscatalog-change required
        let params = {
            OfferingID: this.props.filterDetails.process,
            languageID: this.props.languageId,
            OrgEntityID: this.props.filterDetails.Client,
            MonthDT: this.props.filterDetails.Month,
            LocationID: this.props.filterDetails.Locations,
        };
        axiosInstance
            .get(`${LocalApiBaseUrl}Configurator/GetToolsCatalog`, {
                params: params,
            })
            .then((res) => this.setState({ toolCatalog: res.data }))
            .catch((error) => {
                trycatchAlertPopup(error);
            });
    }

    getWODetails() {
        let { screen } = this.props;
        switch (screen) {
            case "Configurator":
                let woData = [...this.props.woConfData];

                if (woData.length >= 1) {
                    this.setState({ metricRowDetails1: woData });
                    if (
                        this.state.cardSelectedSoCatID !== 0 &&
                        woData.filter(
                            (data) => data.SOCategoryID == this.state.cardSelectedSoCatID
                        ).length > 0
                    ) {
                        this.setState({
                            metricSUbRowDetails: woData.filter(
                                (data) => data.SOCategoryID == this.state.cardSelectedSoCatID
                            )[0].kpiData,
                            SOCategoryID: this.state.cardSelectedSoCatID,
                        });
                    } else {
                        let woDataTemp = woData.filter(
                            (step) => step.SOCategoryName != "Work Orchestration"
                        );
                        if (woDataTemp.length > 1) {
                            //set the SOCategory of the first step (highlighted in blue)
                            this.setState({
                                metricSUbRowDetails: woDataTemp[0].kpiData,
                                // SOCategoryID: woDataTemp[0].SOCategoryID,
                            });
                        } else {
                            //If no steps in WO negative scenario - set the SOCategoryID of WorkOrchestration parent SO category itself
                            this.setState({
                                metricSUbRowDetails: woData[0].kpiData,
                                // SOCategoryID: woData[0].SOCategoryID,
                            });
                        }
                    }
                    //  this.setState({ metricSUbRowDetails: this.state.cardSelectedSoCatID == 0 ? woData[0].kpiData : woData.filter((data) => data.SOCategoryID == this.state.cardSelectedSoCatID)[0].kpiData, SOCategoryID: this.state.cardSelectedSoCatID == 0 ? woData[0].SOCategoryID : this.state.cardSelectedSoCatID });
                    this.setState({
                        metricRowDetails: woData.filter(
                            (data) => data.SOCategoryName != "Work Orchestration"
                        ).length
                            ? woData.filter(
                                (data) => data.SOCategoryName != "Work Orchestration" && data.ParentSOCategoryID === 3
                            )
                            : [],
                        SOCategoryID: woData.filter(
                            (data) => data.SOCategoryName != "Work Orchestration"
                        ).length
                            ? woData.filter(
                                (data) => data.SOCategoryName != "Work Orchestration" && data.ParentSOCategoryID === 3
                            )[0].SOCategoryID
                            : 0,
                    });

                    //FoundationTools
                    if (
                        woData.filter((data) => data.SOCategoryName == "Work Orchestration")
                            .length > 0
                    ) {
                        if (
                            woData.filter(
                                (data) => data.SOCategoryName == "Work Orchestration"
                            )[0].SoCatDataAttr.length > 0
                        ) {
                            let FTSegregation = woData
                                .filter(
                                    (data) => data.SOCategoryName == "Work Orchestration"
                                )[0]
                                .SoCatDataAttr.filter(
                                    (data) => data.AttributeGroup == "Foundation Tools"
                                );

                            let foundAttr = [
                                ...new Set(
                                    FTSegregation.map((eachvalue) => eachvalue.AttributeName)
                                ),
                            ];
                            let difference =
                                this.state.FoundationToolsApi === undefined
                                    ? null
                                    : foundAttr.length > 0
                                        ? this.state.FoundationToolsApi.filter(
                                            (x) => !foundAttr.includes(x)
                                        )
                                        : this.state.FoundationToolsApi;

                            this.setState({
                                foundationAttr: foundAttr,
                                FTools_disable: difference,
                            });
                        } else {
                            this.setState({
                                FTools_disable: this.state.FoundationToolsApi,
                            });
                        }
                    } else {
                        this.setState({
                            FTools_disable: this.state.FoundationToolsApi,
                        });
                    }

                    //   let TUSegregation = [];
                    //   let ToolsUsed = [];
                    //   if (
                    //     this.state.cardSelectedSoCatID !== 0 &&
                    //     woData.filter(
                    //       (data) => data.SOCategoryID == this.state.cardSelectedSoCatID
                    //     ).length > 0
                    //   ) {
                    //     TUSegregation = woData
                    //       .filter(
                    //         (data) => data.SOCategoryID == this.state.cardSelectedSoCatID
                    //       )[0]
                    //       .SoCatDataAttr.filter(
                    //         (data) => data.AttributeGroup == "Tools Used"
                    //       );
                    //   } else {
                    //     /* TUSegregation = woData[0].SoCatDataAttr.filter((data) => data.AttributeGroup == "Tools Used"); */
                    //     if (woData.length > 1) {
                    //       TUSegregation = woData[1].SoCatDataAttr.filter(
                    //         (data) => data.AttributeGroup == "Tools Used"
                    //       );
                    //     }
                    //   }

                    //   ToolsUsed = [
                    //     ...new Set(
                    //       TUSegregation.map((eachvalue) => eachvalue.AttributeName)
                    //     ),
                    //   ];
                    //   this.setState({ ToolsUsed: ToolsUsed });
                    // } else {
                    //   if (
                    //     woData.filter((data) => data.SOCategoryName == "Work Orchestration")
                    //       .length > 0
                    //   ) {
                    //     let FTSegregation = woData
                    //       .filter((data) => data.SOCategoryName == "Work Orchestration")[0]
                    //       .SoCatDataAttr.filter(
                    //         (data) => data.AttributeGroup == "Foundation Tools"
                    //       );
                    //     let foundAttr = [
                    //       ...new Set(
                    //         FTSegregation.map((eachvalue) => eachvalue.AttributeName)
                    //       ),
                    //     ];
                    //     let difference = this.state.FoundationToolsApi.filter((x) => !foundAttr.includes(x));
                    //     //let difference=this.state.FoundationToolsApi;

                    //     this.setState({
                    //       metricRowDetails: [],
                    //       metricRowDetails1: [],
                    //       metricSUbRowDetails: [],
                    //       ToolsUsed: [],
                    //       foundationAttr: foundAttr,
                    //       FTools_disable: difference,
                    //     });
                    // }
                }
                this.getToolTypeforStep();
                break;
            case "SuperAdmin":
                woData = [...this.props.woTileData];
                if (woData.length > 0) {
                    this.setState({ metricRowDetails1: woData });
                    this.setState({
                        metricSUbRowDetails:
                            this.state.cardSelectedSoCatID == 0
                                ? woData[0].kpiData
                                : woData.filter(
                                    (data) =>
                                        data.SOCategoryID == this.state.cardSelectedSoCatID
                                ).length > 0
                                    ? woData.filter(
                                        (data) =>
                                            data.SOCategoryID == this.state.cardSelectedSoCatID
                                    )[0].kpiData
                                    : woData[0].kpiData,
                        SOCategoryID: this.state.cardSelectedSoCatID == 0
                            ? woData.filter((data) => data.SOCategoryName != "Work Orchestration").length
                                ? woData.filter((data) => data.SOCategoryName != "Work Orchestration" && data.ParentSOCategoryID === 3)[0].SOCategoryID
                                : 0
                            : this.state.cardSelectedSoCatID,
                    });
                    this.setState({
                        metricRowDetails: woData.filter(
                            (data) => data.SOCategoryName_En != "Work Orchestration" && data.ParentSOCategoryID === 3
                        ),
                    });
                } else {
                    this.setState({
                        metricRowDetails: [],
                        metricRowDetails1: [],
                        metricSUbRowDetails: [],
                        ToolsUsed: [],
                    });
                }
                break;
            default:
                return null;
        }
    }
    //Get Functions for Metrics/Steps/Foundation Tools/Tools Catalog Ends

    //Tab Views in different Screen Start

    // handleMigrationView(flag) {
    //     this.setState({ viewMigrationTabLocal: flag });
    //     this.props.handleMigrationView(flag);
    // }

    handleTabChange(index) {
        this.setState({
            selectedTab: index,
        });
        $("html, body").animate({ scrollTop: 0 }, "slow");
    }

    // setActiveTabAfterMigration() {
    //     let activeTab =
    //         this.props.screen === "SuperAdmin" ? "Step Summary" : "Summary";
    //     this.setState({
    //         selectedTab: activeTab,
    //     });
    // }

    //Tab Views in different Screen Ends  

    //Popup togglers Start
    exploreMoreToolsToggler() {
        this.state.showAllTools
            ? this.setState({ showAllTools: false })
            : this.setState({ showAllTools: true });
    }

    addStepModalShow() {
        this.setState({ addStepStatus: true });
    }

    addStepModalClose() {
        this.setState({ addStepStatus: false });
    }

    submitModalShow() {
        this.setState({ submitStatus: true });
    }

    submitModalClose() {
        this.setState({ submitStatus: false });
    }
    addMetricModalShow() {
        this.setState({ addMetricStatus: true });
    }

    addMetricModalClose() {
        this.setState({ addMetricStatus: false });
    }

    stepToggleHandler(key) {
        let metricDataClone = [...this.state.metricRowDetails1];
        let step = metricDataClone.find((ele) => ele.SOCategoryID === key);
        let substepdata = metricDataClone.filter((data) => data.ParentSOCategoryID === step.SOCategoryID)


        axiosInstance.delete(`${LocalApiBaseUrl}Configurator/DeleteWOStep?OrgEntityId=${this.props.filterDetails.Client}&OfferingId=${this.props.filterDetails.process}&LocationId=${this.props.filterDetails.Locations}&MonthDT=${this.props.filterDetails.Month}&SOCategoryId=${step.SOCategoryID}`).then((response) => {
            if (response.data.IsSuccess) {

                step.IsActive === true ? (step.IsActive = false) : (step.IsActive = true);
                if (step.IsActive) {
                    substepdata.map((each) => each.IsActive = true)
                } else {
                    substepdata.map((each) => each.IsActive = false)
                }
                axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetWorkOrchestrationQualityScore`, {
                    params: {
                        LocationID: this.props.filterDetails.Locations,
                        OrgEntityID: this.props.filterDetails.Client,
                        OfferingID: this.props.filterDetails.process,
                        MonthDT: this.props.filterDetails.Month,
                        LanguageID: store.getState().CurrentFilter.languageData.id
                    }
                }
                )
                    .then((response) => {
                        if (response.data) {
                            store.dispatch({
                                type: "SAVE_QC_SCORE_OF_EACH_TAB",
                                payload: response.data
                            });
                        }


                    }).catch(error => {
                        trycatchAlertPopup(error);
                    });
                this.setState({
                    ...this.state,
                    metricRowDetails1: metricDataClone,
                });
            }
        })

    }
    // Popup togglers End


    //Foundation Tools Operations on configurator Screen Start
    selectFT(val) {
        let FToolstemp = _.cloneDeep(this.state.SoCatDataAttr);
        FToolstemp.AttributeName = val;
        let currentData = {
            "SOCategoryAttr": FToolstemp, "FilterParams": {
                "OrgEntityID": this.props.filterDetails.Client,
                "OfferingID": this.props.filterDetails.process,
                "LocationID": this.props.filterDetails.Locations,
                "MonthDT": this.props.filterDetails.Month
            }
        }
        axiosInstance.post(`${LocalApiBaseUrl}Configurator/InsertUpdateWOFoundationTools`, currentData).then((response) => {
            if (response.data.woattrdata) {
                axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetWorkOrchestrationQualityScore`, {
                    params: {
                        LocationID: this.props.filterDetails.Locations,
                        OrgEntityID: this.props.filterDetails.Client,
                        OfferingID: this.props.filterDetails.process,
                        MonthDT: this.props.filterDetails.Month,
                        LanguageID: store.getState().CurrentFilter.languageData.id
                    }
                }
                )
                    .then((response) => {
                        if (response.data) {
                            store.dispatch({
                                type: "SAVE_QC_SCORE_OF_EACH_TAB",
                                payload: response.data
                            });
                        }


                    }).catch(error => {
                        trycatchAlertPopup(error);
                    });
                store.dispatch({
                    type: "ADD_FT",
                    payload: response.data.woattrdata,
                });
                let dataFt = store.getState();
                let FTSegregation = dataFt.WOtileReducers["tile"]
                    .filter((data) => data.SOCategoryName == "Work Orchestration")[0]
                    .SoCatDataAttr.filter(
                        (data) => data.AttributeGroup == "Foundation Tools"
                    );
                let foundAttr = [
                    ...new Set(FTSegregation.map((eachvalue) => eachvalue.AttributeName)),
                ];
                let difference = this.state.FoundationToolsApi.filter(
                    (x) => !foundAttr.includes(x)
                );
                this.setState({
                    FTools_disable: difference,
                    foundationAttr: foundAttr,
                });
            }
        }).catch(error => {
            window.alert(this.ssErrMsg + error);
            trycatchAlertPopup(error);

        });



    }

    deleteFT(row) {
        if (window.confirm("Are you sure you want to delete foundation tool?")) {

            axiosInstance.delete(`${LocalApiBaseUrl}Configurator/DeleteWOFoundationTools?tool=${row}&OrgEntityID=${this.props.filterDetails.Client}&OfferingID=${this.props.filterDetails.process}&LocationID=${this.props.filterDetails.Locations}&MonthDT=${this.props.filterDetails.Month}&SOCategoryId=${3}`).then((response) => {
                if (response.data) {
                    axiosInstance.get(`${LocalApiBaseUrl}Configurator/GetWorkOrchestrationQualityScore`, {
                        params: {
                            LocationID: this.props.filterDetails.Locations,
                            OrgEntityID: this.props.filterDetails.Client,
                            OfferingID: this.props.filterDetails.process,
                            MonthDT: this.props.filterDetails.Month,
                            LanguageID: store.getState().CurrentFilter.languageData.id
                        }
                    }
                    )
                        .then((response) => {
                            if (response.data) {
                                store.dispatch({
                                    type: "SAVE_QC_SCORE_OF_EACH_TAB",
                                    payload: response.data
                                });
                            }


                        }).catch(error => {
                            trycatchAlertPopup(error);
                        });
                    store.dispatch({
                        type: "REMOVE_FT",
                        payload: row,
                    });
                    let dataFt = store.getState();
                    let FTSegregation = dataFt.WOtileReducers["tile"]
                        .filter((data) => data.SOCategoryName == "Work Orchestration")[0]
                        .SoCatDataAttr.filter(
                            (data) => data.AttributeGroup == "Foundation Tools"
                        );
                    let foundAttr = [
                        ...new Set(FTSegregation.map((eachvalue) => eachvalue.AttributeName)),
                    ];
                    let difference = this.state.FoundationToolsApi.filter(
                        (x) => !foundAttr.includes(x)
                    );
                    this.setState({
                        FTools_disable: difference,
                        foundationAttr: foundAttr,
                    });
                }
            }).catch(error => {
                trycatchAlertPopup(error)
            });



        }

    }
    //Foundation Tools Operations on configurator Screen Ends


    //Step Deletion on MTC   
    deleteStep(key) {
        if (window.confirm("Are you sure you want to delete step?")) {
            let filterProcess = this.props.filterDetails.process;
            let data = this.state.metricRowDetails.find((ele) => ele.key === key);
            let sOCategoryID = data.SOCategoryID;
            this.props.screen === "SuperAdmin" &&
                axiosInstance
                    .post(
                        `${LocalApiBaseUrl}Master/DeleteSOCategoryMasterTemplate?soCategoryId=${sOCategoryID}&offeringId=${filterProcess}`
                    )
                    .then((response) => {
                        this.props.getOfferingAPICallback(true);
                        this.state.message = response.data.StatusMessage;
                        window.alert(response.data.StatusMessage);
                    });
            let woData = Object.assign({}, [...this.props.woTileData]);
            let deleteStep = [...this.props.woTileData].filter(
                (data) => data.key == key
            )[0];
            store.dispatch({
                type: "Remove_TILE_MASTER",
                payload: deleteStep,
            });

            //If last step is deleted, set active to first step
            if (woData.length > 0) {
                if (woData.length === key - 1) {
                    this.setState({
                        SOCategoryID: [...this.props.woTileData].filter(
                            (data) => data.key == 1
                        )[0].SOCategoryID,
                        cardSelectedSoCatID: [...this.props.woTileData].filter(
                            (data) => data.key == 1
                        )[0].SOCategoryID,
                    });
                } else {
                    //if any step deleted, set active to its next step
                    this.setState({
                        SOCategoryID: [...this.props.woTileData].filter(
                            (data) => data.key == key + 1
                        )[0].SOCategoryID,
                        cardSelectedSoCatID: [...this.props.woTileData].filter(
                            (data) => data.key == key + 1
                        )[0].SOCategoryID,
                    });
                }
            }
        }
    }


    // on CardClick/Step Click Setting data of details page
    cardClick = (count, SOCatID) => {
        let { screen } = this.props;
        let storeData = store.getState();
        let state = this.state;
        switch (screen) {
            case "Configurator":
                state.SOCategoryID = storeData.WOtileReducers["tile"].filter(
                    (data) => data.SOCategoryName !== "Work Orchestration" && data.ParentSOCategoryID === 3
                )[count - 1].SOCategoryID;
                state.cardSelectedSoCatID = storeData.WOtileReducers["tile"].filter(
                    (data) => data.SOCategoryName !== "Work Orchestration" && data.ParentSOCategoryID === 3
                )[count - 1].SOCategoryID;
                this.setState({
                    SOCategoryID: state.SOCategoryID,
                    metricRowDetails1: [...this.props.woConfData],
                });
                state.metricSUbRowDetails = this.state.metricRowDetails1
                    .filter((data) => data.SOCategoryID == state.SOCategoryID)[0]
                    .kpiData.sort((a, b) =>
                        a.MetricSeq !== b.MetricSeq
                            ? a.MetricSeq > b.MetricSeq
                                ? 1
                                : -1
                            : a.MetricName.toLowerCase() > b.MetricName.toLowerCase()
                                ? 1
                                : -1
                    );
                this.setState({
                    metricSUbRowDetails: state.metricSUbRowDetails,
                    DispVal: count,
                    cardSelectedSoCatID: state.cardSelectedSoCatID,
                });
                this.getAttrData({ SOCategoryID: state.SOCategoryID });
                this.handleTabChange("Details");
                break;

            case "SuperAdmin":
                state.SOCategoryID = this.state.metricRowDetails.filter(
                    (data) => data.SOCategoryName !== "Work Orchestration"
                )[count - 1].SOCategoryID;
                state.cardSelectedSoCatID = this.state.metricRowDetails.filter(
                    (data) => data.SOCategoryName !== "Work Orchestration")[
                    count - 1
                ].SOCategoryID;
                this.setState({
                    SOCategoryID: state.SOCategoryID,
                    metricRowDetails1: [...this.props.woTileData],
                });
                state.metricSUbRowDetails = this.state.metricRowDetails1.filter(
                    (data) => data.SOCategoryID == state.SOCategoryID
                )[0].kpiData;
                this.setState({
                    metricSUbRowDetails: state.metricSUbRowDetails,
                });
                this.setState({
                    DispVal: count,
                    cardSelectedSoCatID: state.cardSelectedSoCatID,
                });
                this.getAttrData({ SOCategoryID: state.SOCategoryID });
                this.handleTabChange("Step Details");
                break;

            case "Dashboard":
                this.setState({ metricRowDetails1: [...this.state.original] });
                state.SOCategoryID = SOCatID;
                if (state.SOCategoryID !== 0) {
                    let kpidata = this.state.metricRowDetails1.filter(
                        (data) => data.SOCategoryID == state.SOCategoryID
                    );
                    kpidata.sort((a, b) =>
                        a.MetricSeq !== b.MetricSeq
                            ? a.MetricSeq > b.MetricSeq
                                ? 1
                                : -1
                            : a.MetricName.toLowerCase() > b.MetricName.toLowerCase()
                                ? 1
                                : -1
                    );
                    this.setState({
                        metricSUbRowDetails: kpidata,
                    });
                    this.setState({ DispVal: count });
                    this.getAttrData({ SOCategoryID: state.SOCategoryID });
                    this.handleTabChange("Details");
                } else {
                    let kpidata = this.state.metricRowDetails1.filter(
                        (data) => data.key == state.SOCategoryID + 1
                    );
                    kpidata = kpidata.sort((a, b) =>
                        a.MetricSeq !== b.MetricSeq
                            ? a.MetricSeq > b.MetricSeq
                                ? 1
                                : -1
                            : a.MetricName.toLowerCase() > b.MetricName.toLowerCase()
                                ? 1
                                : -1
                    );
                    this.setState({
                        metricSUbRowDetails: kpidata,
                    });
                    this.setState({ DispVal: count });
                    this.getAttrData({ SOCategoryID: state.SOCategoryID });
                    this.handleTabChange("Details");
                }

                break;
            default:
                return null;
        }
    };

    //ExploreTools Advisor Code

    handleToggle = (index) => {
        let filterClient = this.props.filterDetails.Client;
        let filterProcess = this.props.filterDetails.process;
        let filterLocations = this.props.filterDetails.Locations;
        //let SOCategoryID = this.props.SODetails[0].SOCategoryID;
        let SOCategoryID = 3
        let appID = this.state.exploreToolsList[index].ApplicationMappingID;
        axiosInstance.get(`${LocalApiBaseUrl}ClickableTools/IncludeExcludeTool`, {
            params: {
                OrgEntityID: filterClient,
                OfferingID: filterProcess,
                LocationID: filterLocations,
                AppId: appID,
                SOCatId: SOCategoryID
            }
        }).then(response => {
            if (response.data.IsSuccess === true) {
                let data = this.state.exploreToolsList;
                data[index].IsEnable = !data[index].IsEnable;
                this.setState({
                    exploreToolsList: data,
                })
            }
        })

    }
    componentDidUpdate(prevProps) {
        $(document).on('click', '.explore_tools_root', function (e) {
            e.stopPropagation();
        });
        if ((this.props.filterDetails.offering !== prevProps.filterDetails.offering) || (this.props.filterDetails.process !== prevProps.filterDetails.process)) {
            this.setState({ cardSelectedSoCatID: 0 })
        }
    }
    viewMoreDashboard() {
        let filterClient = this.props.filterDetails.Client;
        let filterProcess = this.props.filterDetails.process;
        let filterLocations = this.props.filterDetails.Locations;
        //let SOCategoryID = this.props.SODetails[0].SOCategoryID;
        let SOCategoryID = 3



        let displayStatus = this.state.exploreDrop;
        if (displayStatus == "none") {

            axiosInstance
                .get(`${LocalApiBaseUrl}ClickableTools/GetExploreTools`, {
                    params: {
                        OrgEntityID: filterClient,
                        OfferingID: filterProcess,
                        LocationID: filterLocations,
                        SOCategoryID: SOCategoryID,
                        IsAllTool: false,
                    }
                })
                .then(res => {


                    let exploreLinkList = res.data.map(eachLinks => {
                        return (
                            <a className={eachLinks.URL ? "dropdown-item" : "dropdown-item explore_tools_noCursor"} href={eachLinks.URL ? (eachLinks.URL !== "NA" && eachLinks.URL !== "https://" ? eachLinks.URL : eachLinks.URL.substr) : "javascript:void(0)"} target={eachLinks.URL && "_blank"}>
                                {eachLinks.ApplicationName}
                            </a>
                        );

                    });

                    this.setState({
                        exploreLinkList,
                        exploreDrop2: res.data.length == 0 ? "No tools available" : ""
                    });
                    this.onClose();
                })
                .catch((error) => {
                    trycatchAlertPopup(error);
                });
        }
        else {
            this.setState({ exploreDrop: "none" });
        }
    }
    viewMore() {
        //this.setState({viewmore: !this.state.viewmore});

        let filterClient = this.props.filterDetails.Client;
        let filterProcess = this.props.filterDetails.process;
        let filterLocations = this.props.filterDetails.Locations;
        //let SOCategoryID = this.props.SODetails[0].SOCategoryID;
        let SOCategoryID = 3


        let displayStatus = this.state.exploreDrop;
        if (displayStatus == "none") {
            axiosInstance
                .get(`${LocalApiBaseUrl}ClickableTools/GetExploreTools`, {
                    params: {
                        OrgEntityID: filterClient,
                        OfferingID: filterProcess,
                        LocationID: filterLocations,
                        SOCategoryID: SOCategoryID,
                        IsAllTool: true
                    }
                })
                .then(res => {

                    this.setState({
                        exploreToolsList: res.data,
                        exploreDrop1: res.data.length == 0 ? i18n.t("Please add tools on Pulse Page") : "",
                    })


                    this.onClose();
                })
                .catch(() => { 
                    trycatchAlertPopup(error);
                });
        }
        else {
            this.setState({ exploreDrop: "none" });
        }
    }

    getToolTypeforStep() {
        let { screen } = this.props;
        let toolData = [];
        switch (screen) {
            case "Configurator":
                let woConfigData = [...this.props.woConfData];
                let stepDataConfig = [...this.state.metricRowDetails];
                stepDataConfig.forEach((eachStep) => {
                    woConfigData.forEach((each) => {
                        if (eachStep.SOCategoryID === each.ParentSOCategoryID) {
                            if (each.WOStepTools && each.WOStepTools.length) {
                                each.WOStepTools.forEach((eachTool) => {
                                    toolData.push({ ...eachTool });
                                })
                            }
                        }
                    })
                    eachStep.WOStepTools = toolData;
                    toolData = [];
                })
                this.setState({ metricRowDetails: stepDataConfig })
                break;
            case 'Dashboard':
                let woFDData = [...this.state.original];
                let stepDataFD = [...this.state.metricRowDetails];
                stepDataFD.forEach((eachStep) => {
                    woFDData.forEach((each) => {
                        if (eachStep.SOCategoryID === each.ParentSOCategoryID) {
                            if (each.WOStepTools && each.WOStepTools.length) {
                                each.WOStepTools.forEach((eachTool) => {
                                    toolData.push({ ...eachTool });
                                })
                            }
                        }
                    })
                    eachStep.WOStepTools = toolData;
                    toolData = [];
                })
                this.setState({ metricRowDetails: stepDataFD })
                break;
            default:
                return null;
        }
    }

    render() {
        let { screen, filterDetails, roledata } = this.props;
        const { exploreToolsList, exploreLinkList } = this.state;
        return (
            <>
                <LoadingOverlay
                    className="custom-loader"
                    fadeSpeed={0}
                    spinner={<SynopsLoader />}
                    active={this.state.showLoaderOnPage}
                ></LoadingOverlay>
                <div className="work-orchestration">
                    {this.props.screen === "Dashboard" && (
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-title clearfix references-dropdown dashboard-references-dropdown">
                                    <div className="dropdown">
                                        <a className="dropdown-toggle"
                                            data-toggle="dropdown"
                                            onClick={() => { this.viewMoreDashboard() }}>
                                            <span className="exploreTools">
                                                {/* <i class="fal fa-info-circle"></i> */}{i18n.t("Explore_Tools_Advisor")}
                                                <span>{i18n.t("Click on Explore Tools/Advisor to view Tools")}</span>
                                            </span>
                                            <span className="caret"></span>
                                        </a>

                                        <ul className="dropdown-menu explore_tools_root explore-tools-menu dropdown-menu-right custom-dropdown-menu">
                                            {exploreLinkList.length > 0 ?
                                                this.state.exploreLinkList : (<div><p>
                                                    {this.state.exploreDrop2}
                                                </p></div>)}


                                        </ul>
                                    </div>
                                    <div className="category-references dashboard-category-references">
                                        {<References
                                            {...this.props}
                                            SOCatID={3}
                                            leverName="Work Orchestration"
                                            referenceScreen="WO"
                                        />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>)}
                    {this.props.screen === "Configurator" &&
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-title clearfix configurator-references-dropdown references-dropdown dashboard-references-dropdown">

                                    <div className="dropdown">
                                        <a className="dropdown-toggle"
                                            data-toggle="dropdown"
                                            onClick={() => { this.viewMore() }}>
                                            <span className="exploreTools">
                                                {/* <i class="fal fa-info-circle"></i> */}{i18n.t("Explore_Tools_Advisor")}
                                                <span>{i18n.t("Click on Explore Tools/Advisor to view Tools")}</span>
                                            </span>
                                            <span className="caret"></span>
                                        </a>

                                        <ul className="dropdown-menu explore_tools_root explore-tools-menu dropdown-menu-right custom-dropdown-menu">

                                            {/* {this.state.exploreLinkList} */}
                                            {exploreToolsList.length > 0 ? exploreToolsList.map((eachLinks, index) => {
                                                return (
                                                    <div className="explore_tools">
                                                        <a className={eachLinks.IsEnable ? (eachLinks.URL ? "dropdown-item" : "dropdown-item explore_tools_noCursor") : "dropdown-item disabled"} href={eachLinks.URL ? (eachLinks.URL !== "NA" && eachLinks.URL !== "https://" ? eachLinks.URL : eachLinks.URL.substr) : "javascript:void(0)"} target={eachLinks.URL && "_blank"}>
                                                            {eachLinks.ApplicationName}

                                                        </a>
                                                        <div className="toggle-group toggle-tick">
                                                            <input
                                                                type="checkbox"
                                                                name={"datatypechange" + index}
                                                                id={"datatypechange" + index}
                                                                defaultChecked={true}
                                                                onChange={(e) => { this.handleToggle(index) }}
                                                                checked={eachLinks.IsEnable}
                                                            />
                                                            <label htmlFor={"datatypechange" + index}>Connection Status</label>
                                                            <div className="onoffswitch" aria-hidden="true">
                                                                <div className="onoffswitch-label">
                                                                    <div className="onoffswitch-inner"></div>
                                                                    <div className="onoffswitch-switch"></div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                );

                                            }) : (<div><p>
                                                {this.state.exploreDrop1}
                                            </p></div>)
                                            }

                                        </ul>

                                    </div>
                                    <div className="category-references dashboard-category-references">
                                        {<References
                                            {...this.props}
                                            SOCatID={3}
                                            leverName="Work Orchestration"
                                            isConfig={true}
                                            referenceScreen="WO"
                                        />}
                                    </div>
                                </div>
                            </div>
                        </div>}
                    {screen === "SuperAdmin" ? <ExportCsv csvProp={this.props.filterDetails.process} /> : ''}
                    <Tabs defaultActiveKey={screen === "SuperAdmin" ? "Step Summary" : "Summary"}
                        onSelect={(index) => this.handleTabChange(index)}
                        activeKey={this.state.selectedTab}
                    >
                        <Tab
                            eventKey={screen === "SuperAdmin" ? "Step Summary" : "Summary"}
                            title={screen === "SuperAdmin" ? "Step Summary" : "Summary"}
                        >
                            <StepSummary
                                isFoundationToolsLoaded={this.state.isFoundationToolsLoaded}
                                RecordStatus={this.props.RecordStatus} isManintencneWindowOpen={this.props.isManintencneWindowOpen}
                                screen={screen}
                                roledata={roledata}
                                card={this.cardClick}
                                getOfferingAPICallback={this.props.getOfferingAPICallback}
                                selectedStep={
                                    this.state.cardSelectedSoCatID === 0
                                        ? this.state.SOCategoryID
                                        : this.state.cardSelectedSoCatID
                                }
                                showAllTools={this.state.showAllTools}
                                exploreMoreToolsToggler={this.exploreMoreToolsToggler}
                                toolCat={this.state.ToolsCategory}
                                toolList={this.state.toolCatalog}
                                filter={filterDetails}
                                selectedFT={this.state.selectedFT}
                                selectFT={this.selectFT}
                                deleteFT={this.deleteFT}
                                deleteStep={this.deleteStep}
                                addStepModalShow={this.addStepModalShow}
                                foundationAttr={this.state.foundationAttr}
                                FTools_disable={this.state.FTools_disable}
                                FoundationToolsApi={this.state.FoundationToolsApi}
                                metricRowDetails={this.state.metricRowDetails}
                                woData={screen === 'Configurator' ? this.props.woConfData : screen === 'Dashboard' ? this.state.original : this.props.woTileData}
                            />
                        </Tab>
                        <Tab
                            eventKey={screen === "SuperAdmin" ? "Step Details" : "Details"}
                            title={screen === "SuperAdmin" ? "Step Details" : "Details"}
                        >
                            {this.state.metricRowDetails.length > 0 && (
                                <StepDetails
                                    screen={screen}
                                    // GetMigrationSteps={this.state.GetMigrationSteps}
                                    // GetMigrationMetrics={this.state.GetMigrationMetrics}
                                    filter={this.props.filterDetails}
                                    selectedStep={
                                        this.state.cardSelectedSoCatID === 0
                                            ? this.state.SOCategoryID
                                            : this.state.cardSelectedSoCatID
                                    }
                                    stepToggleHandler={this.stepToggleHandler}
                                    deleteStep={this.deleteStep}
                                    FoundationTools={this.state.foundationAttr}
                                    getOfferingAPICallback={this.props.getOfferingAPICallback}
                                    LanguageID={this.props.languageId}
                                    carouselData={this.state.metricRowDetails}
                                    metricRowDetails={screen === 'Configurator' ? this.props.woConfData : screen === 'Dashboard' ? this.state.original : this.props.woTileData}
                                    card={this.cardClick}
                                    addStepModalShow={this.addStepModalShow}
                                    RecordStatus={this.props.RecordStatus} isManintencneWindowOpen={this.props.isManintencneWindowOpen}
                                    roledata={roledata}
                                />
                            )}
                        </Tab>
                        {/* {this.state.GetMigrationMetrics.length &&
                            this.state.GetMigrationSteps.length ? (
                                this.state.viewMigrationTabLocal &&
                                    this.props.screen == "Configurator" ? (
                                        <Tab eventKey="MetricMigration" title="Metric Migration">
                                            <MetricMigration
                                                GetMigrationSteps={this.state.GetMigrationSteps}
                                                GetMigrationMetrics={this.state.GetMigrationMetrics}
                                                filterDetails={this.props.filterDetails}
                                                handleMigrationView={this.handleMigrationView}
                                                isMetricDataMigrated={this.props.isMetricDataMigrated}
                                                setActiveTabAfterMigration={this.setActiveTabAfterMigration}
                                            />
                                        </Tab>
                                    ) : (
                                        ""
                                    )
                            ) : (
                                ""
                            )} */}
                    </Tabs>
                </div>
                <AddWOStep_MTC
                    show={this.state.addStepStatus}
                    modalClose={this.addStepModalClose}
                    mode={"CREATE"}
                    getOfferingAPICallback={this.props.getOfferingAPICallback}
                    filter={this.props.filterDetails}
                    {...this.props}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        storeState: state,
        woTileData: state.WOtileMasterReducers.tile,
        woConfData: state.WOtileReducers.tile,
        languageId: state.CurrentFilter.languageData.id,
        clientData: state.CurrentFilter.ClientData,
    };
};
// const mapDispatchToProps = (dispatch) => {
//   return ({
//       saveLanguageData: (data) => { dispatch({ type: 'LANGUAGE_DATA', payload: data }) },
//       saveUserFetauresData: (data) =>{dispatch({ type: 'INITIALISEROLE', payload: data })}
//   })
// }

export default connect(mapStateToProps)(WorkOrchestration);
