import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { LocalApiBaseUrl } from '../Shared/Constant';
import i18n from "i18next";
import { connect } from "react-redux";

import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup } from "../Shared/Constant";
import { store } from "../reducers/configureStore"
import Header from '../Shared/Header';
import { regexForConfMasterFields } from '../Shared/Constant';
import { regexForTextAreas } from '../Shared/Constant';

axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;




class LocationPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false, apivalue: "", selectValue: this.props.SelectValue, Display: false,
            LanguageDropDown: [],
            disableSave: true,
            disableApprove: false,
            Language: "",
            LocationData: [],
            Location: {
                LocationId: "0",
                City: "",
                RequestorRemarks: "",
                ApproverRemarks: "",
                ApprovalAction: true,
                ApproveType: '',
                IsActive: false,
                CreatedUser: null,
                CreatedTS: null,
                UpdatedUser: null,
                UpdatedTS: null,
                DealInfoes: null
            },
            errors: [],
            formIsValid: false,


        }

        this.onLocationChange = this.onLocationChange.bind(this);
        this.onApproverRemarkChange = this.onApproverRemarkChange.bind(this);
        this.onRequestorRemarksChange = this.onRequestorRemarksChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);

    }

    onLocationChange(event) {

        let errors = {};
        this.setState({
            errors: errors
        });
        var Location = { ...this.state.Location };
        Location.City = event.target.value;
        let loc = this.state.LocationData.translatedLocationApproval.filter((e) => { if (e.languageId === parseInt(this.state.Language)) return e })


        this.setState({
            Location: Location,
            disableApprove: loc[0].City === event.target.value && loc[0].RequestorRemarks === Location.RequestorRemarks ? false : true,
            disableSave: loc[0].City !== event.target.value || loc[0].RequestorRemarks !== Location.RequestorRemarks ? false : true
        })

    }



    onApproverRemarkChange(event) {

        if (this.props.type == 'Approve') {
            let soCatAtt = null;
            let errors = {};
            this.setState({
                errors: errors
            });
            var Location = this.state.Location;
            Location.ApproverRemarks = event.target.value;
            Location.ApprovalAction = true;
            // Client.ClientIDs=this.state.Client;
            this.setState({
                Location: Location
            });
        }
        else {
            let soCatAtt = null;
            let errors = {};
            this.setState({
                errors: errors
            });
            var Location = this.state.Location;
            Location.ApproverRemarks = event.target.value;
            Location.ApprovalAction = false;
            // Client.ClientIDs=this.state.Client;
            this.setState({
                Location: Location
            });

        }
    }


    onRequestorRemarksChange(event) {

        let errors = {};
        this.setState({
            errors: errors
        });
        var Location = { ...this.state.Location };
        Location.RequestorRemarks = event.target.value;
        let loc = this.state.LocationData.translatedLocationApproval.filter((e) => { if (e.languageId === parseInt(this.state.Language)) return e })


        this.setState({
            Location: Location,
            disableApprove: loc[0].City === Location.City && loc[0].RequestorRemarks === event.target.value ? false : true,
            disableSave: loc[0].City !== Location.City || loc[0].RequestorRemarks !== event.target.value ? false : true
        })

    }

    onStatusChange(event) {

        const Location = this.state.Location;
        Location.IsActive = event.target.checked;
        this.setState({
            Location: Location
        })
    }

    handleSave(e) {

        if (this.validateForm()) {

            this.props.onSave(this.state.Location)

            this.handleHide();
        }
    }
    handleHide(e) {

        this.setState({ show: false });
        if (e != undefined) {

            if ((e.target.textContent != "×")) {
                this.props.onClose(e);
            }
            else {
                this.props.onClose(e);
                // this.props.onClose("from x");
            }
        } else {
            this.props.onClose(e);
        }
        this.props.onSave()

    }
    handleRejectHide(e) {


        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onRejectClose();
            }
        } else {
            this.props.onRejectClose();
        }


    }
    handleApproveHide(e) {

        this.setState({ show: false });
        if (e != undefined) {
            if (e.target.textContent != "×") {
                this.props.onApproveClose();
            }
        } else {
            this.props.onApproveClose();
        }

    }
    passToConfirmation(data) {


        this.props.enteredData(data.PoNumber)
    }


    componentDidMount() {

        // this.validateForm();
        this.setState({
            show: this.props.show
        })
    }

    validateForm() {
        const Location = this.state.Location;
        let errors = {};
        let formIsValid = true;
        var data = this.props.rowData;


        if (Location["ApproverRemarks"] == null) {
            formIsValid = false;
            errors["ApproverRemarks"] = i18n.t("Approver_Remarks_are_required");

        }
        else if (!Location["ApproverRemarks"].trim()) {
            formIsValid = false;
            errors["ApproverRemarks"] = i18n.t("Approver_Remarks_are_required");

        }
        else {

            var isValid = regexForTextAreas.test(Location["ApproverRemarks"]);
            if (!isValid) {
                formIsValid = false;
                errors["ApproverRemarks"] = i18n.t("Approver_Remarks_contains_Special_characters");
            }
        }





        this.setState({
            errors: errors
        });
        if (formIsValid) {
            this.setState({
                formIsValid: true
            })
        }
        else {
            this.setState({
                formIsValid: false
            })
        }
        return formIsValid;

    }

    componentWillMount() {
        let langID = this.props.languageData.id;
        if (this.props.data) {
            let data = this.props.data[0];
            let loc = data.translatedLocationApproval.filter((e) => { if (e.languageId === langID) return e })
            if (loc.length === 0) {
                loc = data.translatedLocationApproval.filter((e) => { if (e.languageId === 1) return e })
                langID = 1;
            }
            let languageData = data.languageMaster;
            this.setState({
                LanguageDropDown: languageData,
                LocationData: data,
                Language: langID,
                Location: loc[0],
            })
        }
    }
    onLanguageChange = (event) => {
        let value = event.target.options[event.target.selectedIndex].value;
        let data = this.state.LocationData;
        let loc = data.translatedLocationApproval.filter((e) => { if (e.languageId === parseInt(value)) return e })
        this.setState({ Language: value, Location: loc[0], disableApprove: false, disableSave: true })
    }
    saveData = () => {
        let data = this.state.Location;
        axiosInstance.post(`${LocalApiBaseUrl}LocationApproval/PostLocationAfterApproval?languageId=${this.state.Language}&status=Save`, data)
            .then((response) => {
                if (response.data.IsSuccess) {
                    window.alert(i18n.t("Data_updated_successfully"));
                    this.props.savePopupData(data)
                    this.setState({ disableApprove: false, disableSave: true })
                }
            })
            .catch((error) => {
                trycatchAlertPopup(error);
              });
      

    }
    render() {

        return (
            <>
                {/* <Header /> */}
                <Modal show={this.state.show} onHide={this.handleHide.bind(this)} classLocation="modal fade" size="medium" id="dealpopup">
                    <Modal.Header closeButton>
                        <h4 className="modal-title" id="dealprofile" flag >{i18n.t("Approval")}</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="form-section">

                                <div className="row">
                                    <div className="form-group col-lg-6">
                                        <label for="">{i18n.t("Location_Name")}<span className="required">*</span></label>
                                        <input type="text" className="form-control" id="name" onChange={this.onLocationChange} value={this.state.Location.City} />
                                        <span className="errorMsg">{this.state.errors.City}</span>
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label for="">{i18n.t("Language")}<span className="required">*</span></label>
                                        <select name="language" className="form-control" value={this.state.Language} onChange={this.onLanguageChange}>
                                            {this.state.LanguageDropDown.length ? this.state.LanguageDropDown.map((each) => {
                                                return <option key={each.languageId} value={each.languageId}>{each.languageDescription}</option>
                                            }) : ""}
                                        </select>
                                        <span className="errorMsg">{this.state.errors.City}</span>
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label for="">{i18n.t("Requestor_Remarks")}</label>
                                        <textarea maxLength="200" className="form-control" id="remark" onChange={this.onRequestorRemarksChange} value={this.state.Location.RequestorRemarks} />
                                        <span className="errorMsg">{this.state.errors.RequestorRemarks}</span>
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label for="">{i18n.t("Approver_Remarks")}<span className="required">*</span></label>
                                        <textarea maxLength="200" className="form-control" id="remark" onChange={this.onApproverRemarkChange} value={this.state.Location.ApproverRemarks} />
                                        <span className="errorMsg">{this.state.errors.ApproverRemarks}</span>
                                    </div>

                                </div>

                            </div>


                        </form>
                    </Modal.Body>
                    {<Modal.Footer>

                        <div className="modal-btn">
                            {this.props.data ?

                                <input type="Button" disabled={this.state.disableSave} className="btn btn-primary btn-sm" onClick={() => this.saveData()} value={i18n.t("Save")} /> : ""
                            }
                            <span className="pr-3"></span>
                            {!this.props.data ?
                                <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value={i18n.t("Create")} />
                                : <input type="Button" disabled={this.state.disableApprove} className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value={this.props.type === "Approve" ? i18n.t("Approve") : i18n.t("Decline")} />
                            }
                        </div>
                    </Modal.Footer>}
                </Modal>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return ({
        languageData: state.CurrentFilter.languageData
    })
}
export default connect(mapStateToProps)(LocationPopup);