import React, { Component } from "react";
import { LocalApiBaseUrl, getMonthNumber, getPrevMonth } from "../../Shared/Constant";
import { AgGridReact, Ag } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-blue.css";
import HelpFeaturePopup from "./HelpFeaturePopup";
import axiosInstance from "../../Shared/interceptor";
import { trycatchAlertPopup } from "../../Shared/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import MORMasterPopup from "../../MonthlyOperationalReview/MORMasterPopup";
import i18n from "i18next";
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


export default class HelpFeatureMasterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowSelected: [],
      selectedData: "0",
      applicableForDD: false,
      viewmore: false,
      AttributeOptions: [],
      Attribute: {
        OfferingId: null,
      },
      filterDetails: {},

      columnDefs: [
        {
          headerName: "Edit",
          suppressMovable: true,
          width: 50,
          cellRenderer: (params) => {
            var link = document.createElement("span");
            link.className = "tbledit";
            link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
            link.addEventListener("click", (e) => {
              this.helpFeaturepopup(e, params.data);
            });

            return link;
          },
          suppressFilter: true,
        },
        {
          headerName: "Delete",
          suppressMovable: true,
          width: 80,
          cellRenderer: (params) => {
            var link = document.createElement("span");
            link.className = "tbldel";
            link.innerHTML = '<i class="fas fa-trash-alt delete-icon-fix"></i>';
            link.addEventListener("click", (e) => {
              this.deleteHelpSectionData(e, params.data);
            });
            return link;
          },
          suppressFilter: true,
        },

        {
          headerName: "Name",
          field: "Name",
          suppressMovable: true,
          resizable: true,
        },
        {
          headerName: "Description",
          field: "Description",
          suppressMovable: true,
          resizable: true,
        },
        {
          headerName: "Help Section Sequence",
          field: "SequenceForHelpSection",
          suppressMovable: true,
          resizable: true,
        },
        {
          headerName: "FeedBack Sequence",
          field: "SequenceForFeedback",
          suppressMovable: true,
          resizable: true,
        },
        {
          headerName: "Is Active",
          field: "IsActive",
          resizable: false,
          suppressMovable: true,
          suppressFilter: true,
          suppressSizeToFit: true,
          cellRenderer: (params) => {
            return `<label class="form-check">
            <input class="form-check-input" disabled type="checkbox" ${params.value ? "checked" : ""
              }>
            <span class="checkmark"></span>
             </label>`;
          },
          cellStyle: function (params) {
            return { "text-align": "left" };
          },
        },
      ],
      columnDefsMOR: [
        {
          headerName: "Edit",
          suppressMovable: true,
          width: 50,
          cellRenderer: (params) => {
            var link = document.createElement("span");
            link.className = "tbledit";
            link.innerHTML = '<i class="fas fa-pencil-alt edit-icon-fix"></i>';
            link.addEventListener("click", (e) => {
              this.helpFeaturepopup(e, params.data);
            });

            return link;
          },
          suppressFilter: true,
        },
        {
          headerName: "Delete",
          suppressMovable: true,
          width: 80,
          cellRenderer: (params) => {
            var link = document.createElement("span");
            link.className = "tbldel";
            link.innerHTML = '<i class="fas fa-trash-alt delete-icon-fix"></i>';
            link.addEventListener("click", (e) => {
              this.deleteHelpSectionData(e, params.data);
            });
            return link;
          },
          suppressFilter: true,
        },

        {
          headerName: "Name",
          field: "Name",
          suppressMovable: true,
          resizable: true,
        },
        {
          headerName: "Description",
          field: "Description",
          suppressMovable: true,
          resizable: true,
        },
        {
          headerName: "Sequence",
          field: "SequenceForHelpSection",
          suppressMovable: true,
          resizable: true,
        },
        {
          headerName: "Is Active",
          field: "IsActive",
          resizable: false,
          suppressMovable: true,
          suppressFilter: true,
          suppressSizeToFit: true,
          cellRenderer: (params) => {
            return `<label class="form-check">
            <input class="form-check-input" disabled type="checkbox" ${params.value ? "checked" : ""
              }>
            <span class="checkmark"></span>
             </label>`;
          },
          cellStyle: function (params) {
            return { "text-align": "left" };
          },
        },
      ],
      rowData: [],
      gridOptions: {
        paginationPageSize: 50,
        rowHeight: 40,
        headerHeight: 40,
        animateRows: true,
        rowSelection: "multiple",
        defaultColDef: {
          sortable: true,
        },
      },
    };
    this.onClose = this.onClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.helpFeaturepopup = this.helpFeaturepopup.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
  }

  onFilterTextBoxChanged(e) {
    this.state.gridOptions.api.setQuickFilter(document.getElementById("filter-text-box").value);
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  deleteHelpSectionData(e, data) {
    if (
      this.props.featureData.filter((data) => data.Submenu == "Help and Feedback" && data.Operations.includes("Delete"))
        .length > 0
    ) {
      if (this.state.filterDetails.value !== "OperationalCategory") {
        if (window.confirm("Are you sure you want to delete the item?")) {
          axiosInstance
            .delete(`${LocalApiBaseUrl}UserGuide/DeleteMasterScreenHelpTopic`, {
              params: {
                ID: data.ID,
                CategoryName: this.state.filterDetails.value,
              },
            })
            .then((response) => {
              window.alert(response.data.StatusMessage);
              this.getHelpFeatureData();
            });
        }
      }
      else {
        if (window.confirm(i18n.t("Delete_MOR_Category"))) {
          axiosInstance
            .delete(`${LocalApiBaseUrl}UserGuide/DeleteMasterScreenHelpTopic`, {
              params: {
                ID: data.ID,
                CategoryName: this.state.filterDetails.value,
              },
            })
            .then((response) => {
              window.alert(response.data.StatusMessage);
              this.getHelpFeatureData();
            }).catch((error) => {
              trycatchAlertPopup(error);
            });
        }
      }


    } else window.alert(" UnAuthorised Access Denied !!");
  }

  helpFeaturepopup(e, data) {
    if (data != undefined) {
      // edit condition
      if (
        this.props.featureData.filter((data) => data.Submenu == "Help and Feedback" && data.Operations.includes("Edit"))
          .length > 0
      ) {
        e.preventDefault();
        this.setState({ viewmore: true, rowSelected: data });
      } else window.alert(" UnAuthorised Access Denied !!");
    } else {
      // add condition
      if (
        this.props.featureData.filter((data) => data.Submenu == "Help and Feedback" && data.Operations.includes("Add"))
          .length > 0
      ) {
        e.preventDefault();
        this.setState({ viewmore: true, rowSelected: data });
      } else window.alert(" UnAuthorised Access Denied !!");
    }
  }

  onClose(e) {
    this.setState({ viewmore: false });
  }

  ModifyColumnsdef = () => {
    let columnDefs = this.state.columnDefs;
    // let columnDefsAdd = [];
    // let columnDefsDelete = [];

    if (
      this.props.featureData.filter((data) => data.Submenu == "Help and Feedback" && data.Operations.includes("Edit"))
        .length == 0
    ) {
      columnDefs = columnDefs.filter((data) => data.headerName != "Edit");
    }
    if (
      this.props.featureData.filter((data) => data.Submenu == "Help and Feedback" && data.Operations.includes("Delete"))
        .length == 0
    ) {
      columnDefs = columnDefs.filter((data) => data.headerName != "Delete");
    }
    this.setState({
      columnDefs: columnDefs,
    });
  };

  handleDataChange = (event) => {
    this.setState({ errors: {} });
    let selectedData = event.target.value;
    let filterDetails = {};
    switch (selectedData) {
      case "SynopsFDSection":
        filterDetails.Name = "Synops Front Door Section";
        filterDetails.value = "SynopsFD";
        break;
      case "Benefits":
        filterDetails.Name = "Benefit Area";
        filterDetails.value = "Benefits";
        break;
      case "Beneficiaries":
        filterDetails.Name = "Beneficiaries";
        filterDetails.value = "Benificiaries";
        break;
      case "OperationalCategory":
        filterDetails.Name = "Operational Review File Category";
        filterDetails.value = "OperationalCategory"
        break;
      default:
        break;
    }

    this.setState(
      {
        selectedData,
        filterDetails,
      },
      () => {
        this.getHelpFeatureData();
      }
    );
  };

  getHelpFeatureData = () => {
    axiosInstance
      .get(`${LocalApiBaseUrl}UserGuide/GetMasterScreenHelpTopic`, {
        params: {
          CategoryName: this.state.filterDetails.value,
        },
      })
      .then((response) => {
        let filteredResults = response.data;
        this.setState({
          filteredResults,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });


  };

  componentWillMount() { }
  componentDidMount() {
    this.ModifyColumnsdef();
  }

  handleSave(data) {
    if (data) {
      axiosInstance.post(`${LocalApiBaseUrl}UserGuide/AddEditMasterScreenHelpTopic`, data).then((response) => {
        window.alert(response.data.StatusMessage);
        if (response.data.IsSuccess) {
          this.getHelpFeatureData();
          return true;
        } else {
          return false;
        }
      })
        .catch((error) => {
          trycatchAlertPopup(error);
        });

    } else {
      return false;
    }
  }

  render() {
    const { filterDetails, columnDefsMOR } = this.state;
    return (
      this.props.featureData.filter((data) => data.Submenu == "Help and Feedback" && data.Operations.includes("View"))
        .length > 0 && (
        <div className="main-data">
          {/* <h3 className="grid-heading">Field Details</h3> */}
          <div className="tableFilter">
            <div className="form-section">
              <div className="form-row attribute-formrow">
                <div className="form-group col-lg-3 mt-10">
                  <select
                    id="OperatingGroup"
                    className="form-control"
                    onChange={this.handleDataChange}
                    value={this.state.selectedData}
                  >
                    <option value="0">Select Data</option>
                    <option value="SynopsFDSection">SynOps FrontDoor Section</option>
                    <option value="Benefits">Benefit Area</option>
                    <option value="Beneficiaries">Beneficiaries</option>
                    <option value="OperationalCategory">Operational Review File Category</option>
                  </select>
                </div>
                {this.props.featureData.filter(
                  (data) => data.Submenu == "Help and Feedback" && data.Operations.includes("Add")
                ).length > 0 && (
                    <div className="form-group col-lg-3 mt-10">
                      {this.state.selectedData != "0" && (
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={(e) => {
                            this.helpFeaturepopup(e);
                          }}
                        >
                          <span>
                            <FontAwesomeIcon icon={faPlusCircle} />
                          </span>{" "}
                          Add
                        </button>
                      )}
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {this.state.selectedData != "0" && filterDetails.value !== "OperationalCategory" ? (
                <div className="ag-theme-balham ag-table">
                  <AgGridReact
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    paginationPageSize={50}
                    floatingFilter={true}
                    gridOptions={this.state.gridOptions}
                    columnDefs={this.state.columnDefs}
                    rowData={this.state.filteredResults}
                    onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                  ></AgGridReact>
                </div>
              ) : null}
              {this.state.selectedData != "0" && filterDetails.value === "OperationalCategory" ? (
                <div className="ag-theme-balham ag-table">
                  <AgGridReact
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    paginationPageSize={50}
                    floatingFilter={true}
                    gridOptions={this.state.gridOptions}
                    columnDefs={this.state.columnDefsMOR}
                    rowData={this.state.filteredResults}
                    onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                  ></AgGridReact>
                </div>
              ) : null}

            </div>
          </div>
          {this.state.viewmore && filterDetails.value !== "OperationalCategory" ? (
            <HelpFeaturePopup
              data={this.state.rowSelected}
              show={this.state.viewmore}
              onClose={this.onClose}
              filterDetails={this.state.filterDetails}
              onSave={this.handleSave}
              rowData={this.state.filteredResults}
            />
          ) : null}

          {this.state.viewmore && filterDetails.value === "OperationalCategory" ? (
            <MORMasterPopup
              data={this.state.rowSelected}
              show={this.state.viewmore}
              onClose={this.onClose}
              filterDetails={this.state.filterDetails}
              onSave={this.handleSave}
              rowData={this.state.filteredResults}
            />
          ) : null}
        </div>
      )
    );
  }
}
