import React, { Component } from "react";
import CaseStudyLinkPoup from "../Shared/CaseStudyLinkPopup";
import MORAliasContainer from "../MonthlyOperationalReview/MORAliasContainer";
import MORDocx from "../Images/MORdocx.svg";
import { NavLink } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { LocalApiBaseUrl } from "../Shared/Constant";
import PulseMorCaseStudyDocViewer from "./PulseMorCaseStudyDocViewer";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { MorDocInfo } from "../Shared/Constant";
import { ExtractSrcLink } from "../Shared/commonFunctions";
import i18n from "i18next";
import ReactTooltip from "react-tooltip";
class MORCaseStudy extends Component {
  constructor(props) {
    super(props);


    this.state = {
      ToolTipdata: "",
      isloading: false,
      isCaseStudy: false,
      pulsemorModalStatus: false,
      ishowDocviewer: false,
      caseStudylinkData: {},
      SelectedItem: {},
      MorItemsData: [],
    };
  }
  GenerateExtensionIcon = (link) => {
    var matches = MorDocInfo.filter((a, index) => {
      if (
        link
          .trim()
          .toUpperCase()
          .includes(a.key.trim().toUpperCase())
      ) {
        return a;
      } else {
        return;
      }
    });

    if (matches.length > 0) {
      return matches[0];
    } else {
      if (
        link
          .trim()
          .toUpperCase()
          .includes("Doc.aspx".trim().toUpperCase()) &&
        !MorDocInfo.map((a) => a.key.toUpperCase()).includes(
          link.trim().toUpperCase()
        )
      ) {
        return MorDocInfo.filter((a) => a.key === "Word")[0];
      }
      return MorDocInfo.filter((a) => a.key === "Invalid")[0];
    }
  };
  GetAllPulseMORItemData() {
    const {
      PulseOffering,
      PulseSubOffering,
      PulseofferingId,
      clientData,
      languageData,
    } = this.props;

    let data = {
      OrgEntityID: clientData.id,
      Offering: PulseOffering,
      SubOfferingID: PulseofferingId,
      LanguageID: languageData.id,
      PageNo: 0,
      PageSize: 0,
      Searchquery: "",
    };
    this.setState({
      isloading: true,
    });

    axiosInstance
 
    .post(`${LocalApiBaseUrl}MOR/GetAllPulseMORItemData`, data)
      .then((response) => {
        let MORItemsModel = response.data.MORItemsModel;
        MORItemsModel = MORItemsModel.map((a, index) => {
          return {
            ...a,
            ExtensionAndIcon:
              a.ItemLink !== "" && a.ItemLink != null
                ? this.GenerateExtensionIcon(a.ItemLink)
                : "",
          };
        });
        this.setState({
          MorItemsData: MORItemsModel,
          isloading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isloading: false,
        });
      });
  }
  componentDidUpdate(prevProps) {
    const { MorItemsData, CaseStudyData } = this.props;
    if (prevProps.MorItemsData !== MorItemsData) {
      this.setState({
        MorItemsData,
      });
    }
    if (prevProps.CaseStudyData !== CaseStudyData) {
      this.setState({
        caseStudylinkData: CaseStudyData,
      });
    }
  }
  componentDidMount() {
    if (this.props.clientData.id > 0) {
 
      const { MorItemsData, CaseStudyData } = this.props;
      this.setState({
        MorItemsData,
        caseStudylinkData: CaseStudyData,
      });
    }
  }

  getCaseStudyLink = () => {
    const { InitializeCaseStudyData } = this.props;
    if (this.props.clientData.id) {
      axiosInstance
        .get(`${LocalApiBaseUrl}CaseStudy/GetClientCaseStudy`, {
          params: {
            OrgEntityID: this.props.clientData.id,
          },
        })
        .then((response) => {
          let responseData = response.data;
          InitializeCaseStudyData(responseData);
          this.setState({
            caseStudylinkData: responseData,
          });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };
  handleDelete = () => {
    let valid = window.confirm(
      "Are you sure you want to delete this Case Study Link?"
    );

    if (valid) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .delete(`${LocalApiBaseUrl}CaseStudy/DeleteClientCaseStudy`, {
            params: {
              CaseID: this.state.caseStudylinkData.ID,
            },
          })
          .then((response) => {
            alert(response.data.StatusMessage);
            this.getCaseStudyLink();
            this.setState(
              {
                show: false,
              },
              () => {
                resolve(response);
              }
            );
          })
          .catch((error) => {
            trycatchAlertPopup(error);
          });
      });
    }
  };
  saveCaseStudy = (data) => {
    if (Object.entries(data).length > 0) {

      //data.URL =ExtractSrcLink(data.URL);
    }
    axiosInstance
      .post(`${LocalApiBaseUrl}CaseStudy/AddEditClientCaseStudy`, data)
      .then((response) => {
        this.setState(
          {
            caseStudylinkData: data,
            addcsModalStatus: false,
          },
          () => {
            this.setState({
              caseStudypopupStatus: "",
            });
            this.getCaseStudyLink();
          }
        );
        alert(response.data.StatusMessage);
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };
  closeCaseStudy = () => {

    this.setState({
      caseStudypopupStatus: "",
      addcsModalStatus: false,
    });
  };
  addEditCaseStudyLinkModalShow = (status) => {
    this.setState(
      {
        caseStudypopupStatus: status,
        addcsModalStatus: true,
      } 
    );
  };
  pulsemorModalShow = () => {
    this.setState({ pulsemorModalStatus: true });
  };
  pulsemorModalClose = () => {
    this.setState({ pulsemorModalStatus: false });
  };
  deleteCaseStudyLink = () => {
    this.closeCaseStudy();
    this.getCaseStudyLink();
  };
  handledata = (isCaseStudy, ItemData) => {
    let { caseStudylinkData } = this.state;
    if (isCaseStudy) {
      if (
        (!["<iframe","?csf"].some((a) =>
          caseStudylinkData.URL.trim()
            .toUpperCase()
            .includes(a.trim().toUpperCase()))
            )
      ) {
       let url=caseStudylinkData.URL;
       let testurl=caseStudylinkData.URL.toLowerCase()

       if(testurl.startsWith("www")){
        url="https://" + url
       }
       else if(!testurl.startsWith("www") && !testurl.startsWith("https://") && !testurl.startsWith("http://")){
        url="https://" + url
       }
        window.open(url, "_blank");
     
        this.setState({
          isCaseStudy,
          ishowDocviewer: false,
        });
      } else {
        this.setState({
          isCaseStudy,
          ishowDocviewer: true,
        });
      }
    } else {
      this.setState({
        isCaseStudy,
        SelectedItem: {
          ItemId: ItemData.MORItemID,
          ...ItemData,
        },
        ishowDocviewer: true,
      });
    }
  };
  closeViewer = () => {
    this.setState({
      isCaseStudy: false,
      ishowDocviewer: false,
      SelectedItem: {},
    });
  };
  handleclick = (e, param) => {
    if (param === "Edit") {
      this.addEditCaseStudyLinkModalShow("Edit");
    } else if (param === "Delete") {
      this.handleDelete();
    } else {
      if (
        e.target.className !== "dropdown-toggle" &&
        e.target.className !== "dropdown-item" &&
        param === "parent"
      )
        this.handledata(true, {});
    }
  
  };

  disablenavigation = (Currentindex) => {
    const { MorItemsData } = this.state;
    let Totallength = 0;
    if (document.getElementsByClassName("carousel-indicators").length > 0) {
      if (Currentindex > 0) {
        document
          .getElementsByClassName("carousel-control-next-icon")[0]
          .classList.add("active");

        document
          .getElementsByClassName("carousel-control-prev-icon")[0]
          .classList.add("active");
      }

      if (Currentindex === 0) {
        document
          .getElementsByClassName("carousel-control-next-icon")[0]
          .classList.add("active");

        if (
          document
            .getElementsByClassName("carousel-control-prev-icon")[0]
            .classList.contains("active")
        ) {
          document
            .getElementsByClassName("carousel-control-prev-icon")[0]
            .classList.remove("active");
        }
      }
      if (Currentindex === MorItemsData.length - 1) {
        if (
          document
            .getElementsByClassName("carousel-control-next-icon")[0]
            .classList.contains("active")
        ) {
          document
            .getElementsByClassName("carousel-control-next-icon")[0]
            .classList.remove("active");
        }
      }
    }
  };
  dataforTooltip = (Tipdata) => {
    this.setState({
      ToolTipdata: Tipdata.ItemName+Tipdata.ExtensionAndIcon.Extension,
    });
    ReactTooltip.rebuild();
  };
  render() {
    const { configureStatus, MorItemsData,ismorpulseapiloading } = this.props;
    const {
      caseStudylinkData,
      addcsModalStatus,
      caseStudypopupStatus,
      ishowDocviewer,
      isCaseStudy,
      isloading,
      //MorItemsData,
      SelectedItem,
      ToolTipdata,
    } = this.state;

    if (
      document.getElementsByClassName("carousel-control-next-icon").length > 0
    ) {
      document
        .getElementsByClassName("carousel-control-next-icon")[0]
        .classList.add("active");
    }
    if (MorItemsData.length === 1) {
      if (
        document.getElementsByClassName("carousel-control-prev-icon").length > 0
      ) {
        document.getElementsByClassName(
          "carousel-control-prev-icon"
        )[0].style.visibility = "hidden";
      }
      if (
        document.getElementsByClassName("carousel-control-next-icon").length > 0
      ) {
        document.getElementsByClassName(
          "carousel-control-next-icon"
        )[0].style.visibility = "hidden";
      }
      if (document.getElementsByClassName("carousel-indicators").length > 0) {
        document.getElementsByClassName(
          "carousel-indicators"
        )[0].style.visibility = "hidden";
      }
    } else {
      if (
        document.getElementsByClassName("carousel-control-prev-icon").length > 0
      ) {
        document.getElementsByClassName(
          "carousel-control-prev-icon"
        )[0].style.visibility = "visible";
      }
      if (
        document.getElementsByClassName("carousel-control-next-icon").length > 0
      ) {
        document.getElementsByClassName(
          "carousel-control-next-icon"
        )[0].style.visibility = "visible";
      }
      if (document.getElementsByClassName("carousel-indicators").length > 0) {
        document.getElementsByClassName(
          "carousel-indicators"
        )[0].style.visibility = "visible";
      }
    }

    return (
      <div>
        <LoadingOverlay
          spinner={<SynopsLoader />}
          active={isloading}
        ></LoadingOverlay>
        {MorItemsData.length > 0 ? (
        <div className="opereationreview-wrapper mt-3">
            <h3>{i18n.t("Operational_Review")}</h3>
            <div className="or-carousel-container mt-3">
              <Carousel onSelect={(index) => this.disablenavigation(index)}>
                {MorItemsData.map((each, index) => {
                  return (
                    <Carousel.Item interval={7000} key={index}>
                      <div
                        className="item morcard-grid"
                        onClick={() => this.handledata(false, each)}
                      >
                        <div
                          className={`morcard-image ${each.ExtensionAndIcon.Style}`}
                          href="javascript:void(0)"
                        >
                          <img
                            src={each.ExtensionAndIcon.TileIcon}
                            alt={each.ItemName}
                          />
                        </div>
                        <div className="morcard-gridfooter">
                          <img
                            alt={each.ItemName}
                            src={each.ExtensionAndIcon.SmallIcon}
                            className="mr-2"
                          />
                          <p
                            data-tip
                            data-for="caseStudy-tooltip"
                            data-place="bottom"
                            onMouseEnter={() => {
                              each.ItemName.length > 20 &&
                              this.dataforTooltip(each)
                            }}
                            onMouseLeave={() => {
                              ReactTooltip.hide();
                            }}
                          >
                            {each.ItemName.length > 20
                              ? each.ItemName.slice(0, 17) + "..."
                              : each.ItemName}

                            {each.ExtensionAndIcon.Extension}
                          </p>
                        </div>
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
              {ToolTipdata!=="" &&(
              <ReactTooltip
                id="caseStudy-tooltip"
              
                type="dark"
                effect="solid"
                
              >
                    <span>{ToolTipdata}</span>
              </ReactTooltip>
              )}

              <div
                className="oritems-seeall"
                onClick={() => this.pulsemorModalShow()}
              >
                See All
              </div>
             
            </div>
        </div>
        )
        :(
          !ismorpulseapiloading && configureStatus && (
          <div className="casestudy-pulsewrapper addcasestudy-pulsewrapper mt-3">
          <NavLink
                                                    to="/OperationalReviews"
                                                >
                <span
                >
                  {i18n.t("MOR_ON_Pulse")}
                </span>
</NavLink>
              </div>
          )
        )
        }
        {/* Operation Reviews Section End */}
        {/* CaseStudy Section Start */}
        <div className="casestudy-pulsewrapper mt-3">
          {Object.entries(caseStudylinkData).length > 0 &&
          caseStudylinkData.URL ? (
            <div
              className="viewcasestudy-pulsewrapper"
              onClick={
                (e) => this.handleclick(e, "parent")
               
              }
            >
              <div className="cscard-image" href="javascript:void(0)">
                <img
                  src={MORDocx}
                  alt="MORcard"
                  style={{ paddingTop: "37px" }}
                />
              </div>
              <div
                className="cscard-heading common-ellipsis-dropdown"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <h3>{i18n.t("View_Case_Study")}</h3>
                {configureStatus && (
                  <div className="dropdown dropleft">
                    <span
                      type="button"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                    ></span>
                    <div className="dropdown-menu">
                      <a
                        className="dropdown-item"
                        id="child"
                        onClick={() => {
                          this.handleclick({}, "Edit");
                        
                        }}
                      >
                        <span className="edit-icon"></span>
                        Edit
                      </a>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          this.handleclick({}, "Delete");
                        }}
                      >
                        <span className="delete-icon"></span>Delete
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            configureStatus && (
              <div className="addcasestudy-pulsewrapper">
              <h2>{i18n.t("Tell_Your_Client_Story")}</h2>
                <span
                  onClick={(e) => {
                    this.addEditCaseStudyLinkModalShow(e, "Add");
                  }}
                >
                  {i18n.t("Add_Case_Study")}
                </span>
              </div>
            )
          )}
        </div>

        <Modal
          show={this.state.pulsemorModalStatus}
          onHide={() => this.pulsemorModalClose()}
          className="pulsemoritem-modal"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MORAliasContainer
              clientData={this.props.clientData}
              userRole={this.props.userRole}
              history={this.props.historyval}
              filterDetails={this.props.filterValues}
              ispulse={true}
              {...this.props}
            />
          </Modal.Body>
        </Modal>

        {addcsModalStatus && (
          <CaseStudyLinkPoup
            ispulse={true}
            data={caseStudylinkData}
            status={caseStudypopupStatus}
            show={addcsModalStatus}
            saveCaseStudy={this.saveCaseStudy}
            deleteCaseStudyLink={this.deleteCaseStudyLink}
            OrgEntityID={this.props.clientData.id}
            closeCaseStudy={this.closeCaseStudy}
          />
        )}
        {ishowDocviewer && (
          <PulseMorCaseStudyDocViewer
            isShowDocViewer={ishowDocviewer}
            ViewerItemDeatils={SelectedItem}
            DocDetails={caseStudylinkData}
            closeViewer={() => this.closeViewer()}
            isCaseStudy={isCaseStudy}
            AllViewerItemDeatils={MorItemsData}
   
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    languageData: state.CurrentFilter.languageData,
    MorItemsData: state.PulseMORCaseStudyReducers.MORItemData,
    CaseStudyData: state.PulseMORCaseStudyReducers.CaseStudyData,
    ismorpulseapiloading:state.PulseMORCaseStudyReducers.ItemDataLevel.ismorpulseapiloading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    InitializeCaseStudyData: (data) => {
      dispatch({ type: "PULSE_CASESTUDY_DATA_INITIALIZE", payload: data });
    },
    InitializeMORData: (data) => {
      dispatch({ type: "PULSE_MOR_DATA_INITIALIZE", payload: data });
    },
  };
};
let conntedFunc = connect(
  mapStateToProps,
  mapDispatchToProps
);
export default compose(
  withRouter,
  conntedFunc
)(MORCaseStudy);
