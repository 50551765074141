import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import LoadingOverlay from "react-loading-overlay";
import Autocomplete from "react-autocomplete";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { Modal } from "react-bootstrap";

// IMPORT CUSTOM COMPONENTS/FUNCTIONS
import SynopsLoader from "../Shared/SynopsLoader";
import {
  LocalApiBaseUrl,
  ROUTE_CONSTANTS,
  BATOfferingIcons,
  enabledOfferingsBATForAssessment,
} from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";

// IMPORT IMAGES
import uploadPlaceholderImage from "../Images/bat_icons/UploadLogo.svg";
import checkCircle from "../Images/bat_icons/check-circle.svg";
import AssessmentSelectionModal from "./AssessmentSelectionModal";

// FOR PROVIDED OFFERINGS AND SUB-OFFERINGS, CHECK IF AT LEAST ONE SUB-OFFERING IS SELECTED FOR EACH SELECTED OFFERING
function checkIfAtLeastOneSubOfferingSelectedForEachSelectedOffering(offerings, subOfferings) {
  let isAtLeastOneSubOfferingSelectedForEachSelectedOffering = false;

  if (!_.isEmpty(offerings) && !_.isEmpty(subOfferings)) {
    const mappedIdsOfSelectedOfferings = _.map(offerings, "offeringId").sort();
    const mappedOfferingsIdsOfSelectedSubOfferings = _.uniq(_.map(subOfferings, "offeringId")).sort();
    if (_.isEqual(mappedIdsOfSelectedOfferings, mappedOfferingsIdsOfSelectedSubOfferings)) {
      isAtLeastOneSubOfferingSelectedForEachSelectedOffering = true;
    }
  }
  return isAtLeastOneSubOfferingSelectedForEachSelectedOffering;
}
class TakeAssessment extends Component {
  constructor(props) {
    super(props);

    const clientDetails = _.get(props, "location.state.clientDetails", {});
    const currentlySelectedClientName = _.get(clientDetails, "BATClientName", "");
    const currentlySelectedIndustryId = _.get(clientDetails, "BATIndustryID", "");
    const currentlySelectedGeographyId = _.get(clientDetails, "BATGeographyID", "");

    this.state = {
      isAPILoading: false,
      showGeo: !_.isEmpty(clientDetails),
      showIndustry: _.get(clientDetails, "IsCustomClient", false),
      clientDetails,
      offerings: [],
      industries: [],
      geographies: [],
      clients: [],
      filteredClientsForAutocomplete: [],
      uploadedClientLogo: _.get(clientDetails, "ClientLogo", null),
      currentlySelectedClientName,
      currentlySelectedIndustryId,
      currentlySelectedGeographyId,
      currentlySelectedTab: "clientDetailsTab", // OR "offeringsTab", "subOfferingsTab"
      selectedOfferings: [],
      selectedSubOfferings: [],
      favoriteOfferings: [],
      otherOfferings: [],
      isExistAssessmentForClient: false,
      isVisibleModalAssessmentExistsForClient: false,
      configValues: {
        clientLogoFileMaxSizeInKB: 0,
      },
      isVisibleAssessmentSelectionModal: false,
      isCompletedClientDetailsStep: false,
      isCompletedOfferingsStep: false,
      isCompletedSubOfferingsStep: false,
      isVisitedOfferingsStep: false,
      isVisitedSubOfferingsStep: false,
      completedStepsDetails: {
        clientName: currentlySelectedClientName,
        industryId: currentlySelectedIndustryId,
        geographyId: currentlySelectedGeographyId,
        offerings: [],
        subOfferings: [],
      },
      createdOrUpdatedAssessmentObj: {},
      isDisabledCreateAssessmentButton: true,
    };
    // DEBOUNCED FUNCTION FOR THE API CALL TO IMPROVE PERFORMANCE
    this.debouncedCheckClientExist = _.debounce(this.checkClientExist, 500);
  }

  componentDidMount() {
    this.setState({ isAPILoading: true });
    Promise.all([this.fetchAssessmentOfferingList(), this.fetchAssessmentMasterData(), this.fetchConfigData()])
      .then(() => {
        this.setState({ isAPILoading: false });
      })
      .catch(() => {
        this.setState({ isAPILoading: false });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      currentlySelectedClientName,
      currentlySelectedIndustryId,
      currentlySelectedGeographyId,
      currentlySelectedTab,
      completedStepsDetails,
      selectedOfferings,
      selectedSubOfferings,
      isCompletedClientDetailsStep,
      isCompletedOfferingsStep,
      isCompletedSubOfferingsStep,
      isVisitedOfferingsStep,
      isVisitedSubOfferingsStep,
    } = this.state;

    // FOR THE "SUB-OFFERINGS" STEP, CHECK IF AT LEAST ONE SUB-OFFERING IS SELECTED FOR EACH SELECTED OFFERING
    const isAtLeastOneSubOfferingSelectedForEachSelectedOffering = checkIfAtLeastOneSubOfferingSelectedForEachSelectedOffering(
      selectedOfferings,
      selectedSubOfferings
    );

    // LOGIC TO ENABLE/DISABLE "CREATE ASSESSMENT" BUTTON
    if (
      prevState.selectedOfferings !== selectedOfferings ||
      prevState.selectedSubOfferings !== selectedSubOfferings ||
      prevState.isCompletedClientDetailsStep !== isCompletedClientDetailsStep ||
      prevState.isCompletedOfferingsStep !== isCompletedOfferingsStep
    ) {
      let isDisabledCreateAssessmentButton = true;
      if (
        !_.isEmpty(selectedOfferings) &&
        !_.isEmpty(selectedSubOfferings) &&
        isCompletedClientDetailsStep &&
        isCompletedOfferingsStep &&
        isAtLeastOneSubOfferingSelectedForEachSelectedOffering
      ) {
        isDisabledCreateAssessmentButton = false;
      }
      this.setState({
        isDisabledCreateAssessmentButton,
      });
    }

    // WHEN THE TAB SELECTION WOULD CHANGE
    if (prevState.currentlySelectedTab !== currentlySelectedTab) {
      if (currentlySelectedTab === "offeringsTab") {
        this.setState({
          isVisitedOfferingsStep: true,
        });
      } else if (currentlySelectedTab === "subOfferingsTab") {
        this.setState({
          isVisitedSubOfferingsStep: true,
        });
      }
    }

    // SET THE COMPLETED DETAILS FOR "CLIENT DETAILS" STEP
    if (
      (prevState.currentlySelectedTab !== currentlySelectedTab ||
        prevState.isVisitedOfferingsStep !== isVisitedOfferingsStep) &&
      currentlySelectedTab !== "clientDetailsTab" &&
      isVisitedOfferingsStep
    ) {
      this.setState((previousState) => ({
        completedStepsDetails: {
          ..._.cloneDeep(previousState.completedStepsDetails),
          clientName: currentlySelectedClientName,
          industryId: currentlySelectedIndustryId,
          geographyId: currentlySelectedGeographyId,
        },
      }));
    }

    // SET THE COMPLETED DETAILS FOR "OFFERINGS" STEP
    if (
      (prevState.currentlySelectedTab !== currentlySelectedTab ||
        prevState.isVisitedSubOfferingsStep !== isVisitedSubOfferingsStep) &&
      currentlySelectedTab !== "offeringsTab" &&
      isVisitedSubOfferingsStep
    ) {
      this.setState((previousState) => ({
        completedStepsDetails: {
          ..._.cloneDeep(previousState.completedStepsDetails),
          offerings: selectedOfferings,
        },
      }));
    }

    // SET THE COMPLETED DETAILS FOR "SUB-OFFERINGS" STEP
    if (prevState.isCompletedSubOfferingsStep !== isCompletedSubOfferingsStep && isCompletedSubOfferingsStep) {
      this.setState((previousState) => ({
        completedStepsDetails: {
          ..._.cloneDeep(previousState.completedStepsDetails),
          subOfferings: selectedSubOfferings,
        },
      }));
    }

    // WHEN ALL THE FIELDS IN THE "CLIENT DETAILS" STEP ARE FILLED THEN SET IT TO "COMPLETE"
    if (
      prevState.currentlySelectedClientName !== currentlySelectedClientName ||
      prevState.currentlySelectedIndustryId !== currentlySelectedIndustryId ||
      prevState.currentlySelectedGeographyId !== currentlySelectedGeographyId ||
      prevState.completedStepsDetails !== completedStepsDetails
    ) {
      let isCompletedClientDetailsStep = false;
      if (
        !_.isEmpty(currentlySelectedClientName) &&
        currentlySelectedClientName === completedStepsDetails.clientName &&
        !_.isEmpty(String(currentlySelectedIndustryId)) &&
        String(currentlySelectedIndustryId) === String(completedStepsDetails.industryId) &&
        !_.isEmpty(String(currentlySelectedGeographyId)) &&
        String(currentlySelectedGeographyId) === String(completedStepsDetails.geographyId)
      ) {
        isCompletedClientDetailsStep = true;
      }
      this.setState({
        isCompletedClientDetailsStep,
      });
    }

    // WHEN AT LEAST ONE OFFERING IS SELECTED AND "SUB-OFFERINGS" STEP IS VISITED THEN SET THE "OFFERINGS" STEP TO "COMPLETE"
    if (
      prevState.selectedOfferings !== selectedOfferings ||
      prevState.completedStepsDetails.offerings !== completedStepsDetails.offerings ||
      prevState.isVisitedSubOfferingsStep !== isVisitedSubOfferingsStep
    ) {
      let isCompletedOfferingsStepToSet = false;

      if (
        !_.isEmpty(selectedOfferings) &&
        !_.isEmpty(completedStepsDetails.offerings) &&
        _.isEqual(_.sortBy(selectedOfferings, "offeringId"), _.sortBy(completedStepsDetails.offerings, "offeringId")) &&
        isVisitedSubOfferingsStep
      ) {
        isCompletedOfferingsStepToSet = true;
      }
      this.setState({
        isCompletedOfferingsStep: isCompletedOfferingsStepToSet,
      });
    }

    // WHEN "SUB-OFFERINGS" STEP IS BOTH COMPLETED AND VISITED AND THE SELECTION OF SUB-OFFERINGS CHANGES OR BECOMES EMPTY, THEN SET THE STEP COMPLETION TO `false`
    if (
      isCompletedSubOfferingsStep && 
      isVisitedSubOfferingsStep
      && (
      _.isEmpty(selectedSubOfferings) ||
      _.isEmpty(completedStepsDetails.subOfferings) ||
      !_.isEqual(_.sortBy(selectedSubOfferings, "id"), _.sortBy(completedStepsDetails.subOfferings, "id"))
      )
    ) {
      this.setState({
        isCompletedSubOfferingsStep: false,
      });
    }

    // IF THE "CLIENT DETAILS" STEP IS NOT COMPLETED THEN SET ALL THE OTHER STEPS TO INCOMPLETE
    if (prevState.isCompletedClientDetailsStep !== isCompletedClientDetailsStep && !isCompletedClientDetailsStep) {
      this.setState((previousState) => ({
        isCompletedOfferingsStep: false,
        isCompletedSubOfferingsStep: false,
        isVisitedOfferingsStep: false,
        isVisitedSubOfferingsStep: false,
        completedStepsDetails: {
          ..._.cloneDeep(previousState.completedStepsDetails),
          offerings: [],
          subOfferings: [],
        },
      }));
    }

    // IF THE "OFFERINGS" STEP IS NOT COMPLETED THEN SET THE OTHER "SUB-OFFERINGS" STEP TO INCOMPLETE
    if (prevState.isCompletedOfferingsStep !== isCompletedOfferingsStep && !isCompletedOfferingsStep) {
      this.setState((previousState) => ({
        isCompletedSubOfferingsStep: false,
        isVisitedSubOfferingsStep: false,
        completedStepsDetails: {
          ..._.cloneDeep(previousState.completedStepsDetails),
          subOfferings: [],
        },
      }));
    }
  }

  // GET ALL THE OFFERINGS AND CHECK IF ANYTHING IS THERE TO SAVE IN SELECTED OFFERINGS/SUB-OFFERINGS
  fetchAssessmentOfferingList = () => {
    const { clientDetails } = this.state;
    const clientCount = _.get(this.props, "location.state.clientCount", 5);

    const requestData = {
      clientCount,
      includeAllForTVE: true,
    };

    // FETCH THE DATA FROM THE API
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`${LocalApiBaseUrl}BAT/GetAssessmentOfferingList`, requestData)
        .then((response) => {
          resolve();
          const responseData = response.data;
          const modifiedOfferingSummaryData = _.map(responseData, (eachOffering) => {
            let logo = eachOffering.logo;
            let subOfferings = eachOffering.towerNames;
            if (!_.isEmpty(subOfferings)) {
              subOfferings = _.map(subOfferings, (eachSubOffering, index) => {
                const subOfferingName = eachSubOffering.tower;
                return {
                  id: eachSubOffering.towerID,
                  name: subOfferingName,
                  isSubOfferingTVEEnabled: eachSubOffering.hasTVEEnabledMetrics,
                  offeringId: eachOffering.offeringId,
                };
              });
            }

            return {
              ...eachOffering,
              logo,
              subOfferings,
            };
          });

          // FILTER OUT UNIQUE OFFERINGS
          const offerings = _.map(modifiedOfferingSummaryData, (eachRecord) => ({
            ...eachRecord,
            id: eachRecord.offeringId,
            name: eachRecord.offering,
          })).filter((value, index, self) => {
            return self.indexOf(value) === index;
          });

          const clientOfferings = _.get(clientDetails, "OfferingDetails", []);
          const mappedIdsOfClientOfferings = _.map(clientOfferings, (eachClientOffering) =>
            String(eachClientOffering.BATOfferingID)
          );
          let selectedOfferingsToSet = [];
          let selectedSubOfferingsToSet = [];

          // WHEN IN "EDIT" MODE AND THE SELECTED CLIENT OFFERINGS ARE NOT EMPTY THEN SET THEM TO `selectedOfferings`
          if (!_.isEmpty(clientOfferings)) {
            selectedOfferingsToSet = _.filter(offerings, (eachOffering) => {
              const offeringId = String(eachOffering.offeringId);

              const isOfferingSelected = _.indexOf(mappedIdsOfClientOfferings, offeringId) !== -1;
              if (isOfferingSelected) {
                const subOfferingsOfCurrentOffering = eachOffering.subOfferings;
                const foundClientOffering = _.find(
                  clientOfferings,
                  (eachClientOffering) => String(eachClientOffering.BATOfferingID) === offeringId
                );
                const clientSubOfferings = _.get(foundClientOffering, "SubOfferingDetails", []);

                // IF THE SUB-OFFERINGS ARE NOT EMPTY, THEN SET THEM TO `selectedSubOfferings`
                if (!_.isEmpty(clientSubOfferings)) {
                  const mappedIdsOfClientSubOfferings = _.map(clientSubOfferings, (eachClientSubOffering) =>
                    String(eachClientSubOffering.BATSubOfferingID)
                  );
                  const selectedSubOfferingsOfClient = _.filter(subOfferingsOfCurrentOffering, (eachSubOffering) => {
                    const subOfferingId = String(eachSubOffering.id);
                    const isSubOfferingSelected = _.indexOf(mappedIdsOfClientSubOfferings, subOfferingId) !== -1;
                    return isSubOfferingSelected;
                  });

                  if (!_.isEmpty(selectedSubOfferingsOfClient)) {
                    selectedSubOfferingsToSet = _.concat(selectedSubOfferingsToSet, selectedSubOfferingsOfClient);
                  }
                }
              }
              return isOfferingSelected;
            });
          }

          // FOR THE "SUB-OFFERINGS" STEP, CHECK IF AT LEAST ONE SUB-OFFERING IS SELECTED FOR EACH SELECTED OFFERING
          const isAtLeastOneSubOfferingSelectedForEachSelectedOffering = checkIfAtLeastOneSubOfferingSelectedForEachSelectedOffering(
            selectedOfferingsToSet,
            selectedSubOfferingsToSet
          );

          this.setState(
            (prevState) => ({
              offeringSummaryData: modifiedOfferingSummaryData,
              offerings,
              otherOfferings: offerings,
              selectedOfferings: selectedOfferingsToSet,
              selectedSubOfferings: selectedSubOfferingsToSet,
              completedStepsDetails: {
                ..._.cloneDeep(prevState.completedStepsDetails),
                offerings: selectedOfferingsToSet,
                subOfferings: isAtLeastOneSubOfferingSelectedForEachSelectedOffering ? selectedSubOfferingsToSet : [],
              },
              isVisitedOfferingsStep: !_.isEmpty(selectedOfferingsToSet),
              isVisitedSubOfferingsStep: !_.isEmpty(selectedOfferingsToSet) || !_.isEmpty(selectedSubOfferingsToSet),
              isCompletedSubOfferingsStep: isAtLeastOneSubOfferingSelectedForEachSelectedOffering,
            }),
            () => {
              this.fetchFavoriteOfferingsForUser();
              this.addRemoveSubOfferingsToSelection();
            }
          );
        })
        .catch((error) => {
          reject();
          trycatchAlertPopup(error);
        });
    });
  };

  // GET THE INITIAL DATA FOR CLIENTS, INDUSTRY AND GEOGRAPHY
  fetchAssessmentMasterData = () => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${LocalApiBaseUrl}BAT/GetAssessmentMasterData`)
        .then((response) => {
          resolve();
          const responseData = response.data;
          this.setState({
            clients: _.sortBy(responseData.arrayOfClients, ["clientName"]),
            industries: _.sortBy(responseData.arrayOfIndustries, ["industryName"]),
            geographies: _.sortBy(responseData.arrayOfGeography, ["geographyName"]),
          });
        })
        .catch((error) => {
          reject();
          trycatchAlertPopup(error);
        });
    });
  };

  // FETCH CONFIG VALUE FOR MAX FILE SIZE FOR CLIENT LOGO
  fetchConfigData = () => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${LocalApiBaseUrl}DealOnboarding/ReturnMaxFileSizeConfigKeys`)
        .then((response) => {
          resolve();
          this.setState({
            configValues: {
              clientLogoFileMaxSizeInKB: response.data.clientLogoFileMaxSizeInKB,
            },
          });
        })
        .catch((error) => {
          reject();
          trycatchAlertPopup(error);
        });
    });
  };

  // CALL TO GET USER SPECIFIC FAVORITE OFFERING DATA
  fetchFavoriteOfferingsForUser = () => {
    const { offerings, clientDetails } = this.state;
    const flowInitiatedScreen = _.get(this.props, "location.state.flowInitiatedScreen", "");

    axiosInstance
      .get(`${LocalApiBaseUrl}BATAssessment/GetFavouriteOfferingsBasedOnUser`)
      .then((response) => {
        const responseData = response.data;
        const favOfferingIds = _.map(responseData[0].FavoriteOfferings, "BATOfferingID");

        let filteredFavoriteOfferings = [];
        if (!_.isEmpty(offerings)) {
          filteredFavoriteOfferings = offerings.filter(function(eachItem) {
            return favOfferingIds.indexOf(eachItem.id) !== -1;
          });
        }

        if (!_.isEmpty(filteredFavoriteOfferings)) {
          if (flowInitiatedScreen === "viewAssessment" && !_.isEmpty(clientDetails)) {
            this.setState(
              {
                favoriteOfferings: filteredFavoriteOfferings,
              },
              () => this.getOtherOffering()
            );
          } else {
            const result1 = filteredFavoriteOfferings.slice();

            this.setState(
              {
                favoriteOfferings: filteredFavoriteOfferings,
                selectedOfferings: result1,
              },
              () => this.getOtherOffering()
            );
            this.addRemoveSubOfferingsToSelection();
          }
        }
      })
      .catch((error) => {
        this.setState({ isAPILoading: false });
        trycatchAlertPopup(error);
      });
  };

  // EVENT HANDLER FOR THE CHANGE EVENT OF THE CLIENT NAME AUTOCOMPLETE
  handleChangeClientAutocompleteInput = (e, searchText) => {
    const { clients } = this.state;

    let filteredClientList = [];
    // WHEN THE TEXT LENGTH IS GREATER THEN 2 THEN ONLY DISPLAY THE AUTOCOMPLETE OPTIONS
    if (searchText.length > 2) {
      filteredClientList = _.filter(clients, (eachClient) =>
        _.startsWith(_.toLower(eachClient.clientName), _.toLower(searchText))
      );
    }

    let selectedIndustryId = "";
    let isIndustryPreFilled = false;
    if (filteredClientList.length === 1 && _.toLower(filteredClientList[0].clientName) === _.toLower(searchText)) {
      selectedIndustryId = filteredClientList[0].industryID;
      isIndustryPreFilled = true;
    }

    this.setState(
      {
        currentlySelectedClientName: searchText,
        filteredClientsForAutocomplete: filteredClientList,
        currentlySelectedIndustryId: isIndustryPreFilled ? selectedIndustryId : "",
        currentlySelectedGeographyId: "",
        showIndustry: isIndustryPreFilled ? false : true,
        showGeo: false,
      },
      () => {
        this.debouncedCheckClientExist();
      }
    );
  };

  // ONCE USER IS CLICKED ON SOME CLIENT FROM THE LIST, SAVE IT IN THE STATE
  handleSelectClientAutocompleteItem = (selectedClientName) => {
    const { clients } = this.state;

    const foundClient = _.find(clients, { clientName: selectedClientName });

    this.setState(
      {
        currentlySelectedClientName: selectedClientName,
        currentlySelectedIndustryId: _.get(foundClient, "industryID", ""),
        showIndustry: false,
      },
      () => {
        this.debouncedCheckClientExist();
      }
    );
  };

  // HANDLER FOR CHANGE OF INDUSTRY
  handleChangeIndustrySelection = (e) => {
    this.setState({
      currentlySelectedIndustryId: e.target.value,
    });
  };

  // EVENT HANDLE FOR GEOGRAPHY CHANGE
  handleChangeGeographySelection = (e) => {
    this.setState({
      currentlySelectedGeographyId: e.target.value,
    });
  };

  goToClientDetailsTab = () => {
    this.setState({
      currentlySelectedTab: "clientDetailsTab",
    });
  };

  goToOfferingsTab = () => {
    this.setState({
      currentlySelectedTab: "offeringsTab",
    });
  };

  goToSubOfferingsTab = () => {
    this.setState({
      currentlySelectedTab: "subOfferingsTab",
    });
  };

  //EVENT HANDLER FOR CLICK OF OFFERINGS
  addRemoveOfferingToSelection = (currentlySelectedOffering, flowFor) => {
    const { selectedOfferings } = this.state;

    const currentlySelectedOfferingId = currentlySelectedOffering.id;
    const mappedIdsOfSelectedOfferings = _.map(selectedOfferings, "offeringId");

    if (flowFor === "add" && !mappedIdsOfSelectedOfferings.includes(currentlySelectedOfferingId)) {
      this.setState(
        {
          selectedOfferings: [...selectedOfferings, currentlySelectedOffering],
        },
        () => this.addRemoveSubOfferingsToSelection()
      );
    } else if (flowFor === "remove" && mappedIdsOfSelectedOfferings.includes(currentlySelectedOfferingId)) {
      const newSelectedOfferings = selectedOfferings.filter(function(eachOffering) {
        return eachOffering.offeringId !== currentlySelectedOfferingId;
      });

      this.setState(
        {
          selectedOfferings: newSelectedOfferings,
        },
        () => this.addRemoveSubOfferingsToSelection()
      );
    } else {
      if (mappedIdsOfSelectedOfferings.includes(currentlySelectedOfferingId)) {
        const newSelectedOfferings = selectedOfferings.filter(function(eachOffering) {
          return eachOffering.offeringId !== currentlySelectedOfferingId;
        });

        this.setState(
          {
            selectedOfferings: newSelectedOfferings,
          },
          () => this.addRemoveSubOfferingsToSelection()
        );
      } else {
        this.setState(
          {
            selectedOfferings: [...selectedOfferings, currentlySelectedOffering],
          },
          () => this.addRemoveSubOfferingsToSelection()
        );
      }
    }
  };

  //IF SELECTED OFFERING IS HAVING ONE SUB-OFFERING, THEN MAKE IT SELECTED BY DEFAULT
  addRemoveSubOfferingsToSelection = () => {
    const { selectedOfferings, selectedSubOfferings } = this.state;

    var that = this;
    const mappedIdsOfSelectedOfferings = _.map(selectedOfferings, (eachItem) => {
      const id = eachItem.BATOfferingID || eachItem.offeringId;
      return id;
    });

    const mappedIdsOfSelectedSubOfferings = _.map(selectedSubOfferings, "offeringId");

    selectedOfferings.forEach(function(Item, index, self) {
      if (
        selectedOfferings[index].subOfferingCount === 1 &&
        !mappedIdsOfSelectedSubOfferings.includes(selectedOfferings[index].offeringId)
      ) {
        selectedOfferings[index].subOfferings[0]["offeringId"] = selectedOfferings[index].offeringId;
        that.setState({
          selectedSubOfferings: [...that.state.selectedSubOfferings, selectedOfferings[index].subOfferings[0]],
        });
      }
    });

    if (mappedIdsOfSelectedOfferings.length === 0) {
      this.setState({
        selectedSubOfferings: [],
      });
    } else {
      selectedSubOfferings.forEach(function(Item, index, self) {
        if (!mappedIdsOfSelectedOfferings.includes(selectedSubOfferings[index].offeringId)) {
          //update state with suboffering other than this offering id
          const newSelectedsubOfferings = selectedSubOfferings.filter(function(eachOffering) {
            return eachOffering.offeringId !== selectedSubOfferings[index].offeringId;
          });
          that.setState({
            selectedSubOfferings: newSelectedsubOfferings,
          });
        }
      });
    }
  };

  //EVENT HANDLER FOR CLICK OF SUB-OFFERINGS
  getSelectedSubofferings = (offers, subOffering) => {
    const { selectedSubOfferings } = this.state;

    var that = this;
    let currentlySelectedSubOffering = subOffering;
    let idSelected = currentlySelectedSubOffering.id;
    let OfferIdselected = offers.offeringId;
    let subOfferingAdded = selectedSubOfferings;
    let selSubId;
    if (subOfferingAdded.length > 0) {
      selSubId = _.map(subOfferingAdded, (eachItem, index) => {
        return eachItem.id || eachItem.BATSubOfferingID;
      });
    }
    if (
      subOfferingAdded != undefined &&
      subOfferingAdded.length > 0 &&
      selSubId != undefined &&
      selSubId.includes(currentlySelectedSubOffering.id)
    ) {
      subOfferingAdded.forEach(function(Item, index, self) {
        if (OfferIdselected == subOfferingAdded[index].offeringId) {
          delete subOffering.offeringId;
          let updatedSubofferings = subOfferingAdded.filter(function(eachItem) {
            return (
              (eachItem.id || eachItem.BATSubOfferingID) !== idSelected &&
              (eachItem.offeringId || eachItem.BATAssesmentClientOfferingMappingID !== OfferIdselected)
            );
          });
          that.setState({
            selectedSubOfferings: updatedSubofferings,
          });
        }
      });
    } else {
      currentlySelectedSubOffering["offeringId"] = OfferIdselected;
      this.setState({
        selectedSubOfferings: [...this.state.selectedSubOfferings, currentlySelectedSubOffering],
      });
    }
  };

  //EVENT HANDLER FOR CLICK OF CREATE ASSESSMENT
  executeAPIToCreateAssessment = (save) => {
    this.setState({ isAPILoading: true });

    const buttonClicked = save;
    const {
      selectedOfferings,
      selectedSubOfferings,
      clientDetails,
      currentlySelectedClientName,
      currentlySelectedIndustryId,
      currentlySelectedGeographyId,
      uploadedClientLogo,
      industries,
      geographies,
    } = this.state;

    const offeringSend = _.map(selectedOfferings, (eachItem) => {
      return {
        BATOfferingID: eachItem.offeringId,
        BATOfferingName: eachItem.name,
        BATOfferingType: eachItem.offeringType,
      };
    });

    const subOfferingSend = _.map(selectedSubOfferings, (eachItem) => {
      return {
        BATSubOfferingID: eachItem.id || eachItem.BATSubOfferingID,
        BATSubOfferingName: eachItem.name || eachItem.BATSubOfferingName,
        IsTVEEnabled: eachItem.isSubOfferingTVEEnabled || eachItem.IsTVEEnabled,
        BATAssesmentClientOfferingMappingID: eachItem.offeringId,
      };
    });

    const filteredClientObj = _.find(clientDetails, { clientName: currentlySelectedClientName });
    const filteredIndustryObj = _.find(industries, { industryID: _.toNumber(currentlySelectedIndustryId) });
    const filteredGeographyObj = _.find(geographies, { geographyID: _.toNumber(currentlySelectedGeographyId) });

    const requestData = {
      BATAssessClientID: clientDetails.BATAssessClientID ? clientDetails.BATAssessClientID : 0,
      BATClientID: _.get(filteredClientObj, "BATClientID", ""),
      BATClientName: currentlySelectedClientName,
      BATIndustryID: currentlySelectedIndustryId ? currentlySelectedIndustryId : "",
      BATIndustryName: _.get(filteredIndustryObj, "industryName", ""),
      BATGeographyID: currentlySelectedGeographyId ? currentlySelectedGeographyId : "",
      BATGeographyName: _.get(filteredGeographyObj, "geographyName", ""),
      ClientLogobase64: uploadedClientLogo ? uploadedClientLogo : null,
      OfferingDetails: offeringSend,
      SubOfferingDetails: subOfferingSend,
    };

    axiosInstance
      .post(`${LocalApiBaseUrl}BATAssessment/AddEditBATClientAssessment`, requestData)
      .then((response) => {
        // EXECUTE THE API REQUEST TO SAVE FAVORITE OFFERINGS
        this.executeAPIAddEditFavoriteOfferings();

        const responseData = response.data;
        this.setState(
          {
            isAPILoading: false,
            isCompletedSubOfferingsStep: true,
          },
          () => {
            if (responseData.IsSuccess && buttonClicked !== "saveButton") {
              this.executeAPIFetchClientAssessmentDetails();
            } else {
              this.closeAssessment();
            }
          }
        );
      })
      .catch((error) => {
        this.setState({ isAPILoading: false });
        trycatchAlertPopup(error);
      });
  };

  executeAPIAddEditFavoriteOfferings = () => {
    const { favoriteOfferings } = this.state;

    const requestData = {
      FavoriteOfferings: _.map(favoriteOfferings, (eachItem) => {
        return {
          ID: 0,
          BATOfferingID: eachItem.offeringId,
          BATOfferingName: eachItem.name,
        };
      }),
    };

    axiosInstance
      .post(`${LocalApiBaseUrl}BATAssessment/AddEditFavouriteOfferings`, requestData)
      .then((response) => {
        return response.data.IsSuccess;
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  // GET DATA FOR VIEW ASSESSMENT IN CASE USER IS CREATING DUPLICATE ASSESSMENT
  redirectToViewAssessmentScreen = () => {
    const { history } = this.props;
    const { currentlySelectedClientName } = this.state;

    let location = {};
    const clientCount = _.get(this.props, "location.state.clientCount", 5);
    const viewAssessmentScreenData = {
      clientCount,
    };
    this.setState({
      isExistAssessmentForClient: false,
    });
    location = {
      pathname: `/${ROUTE_CONSTANTS.BAT_VIEW_ASSESSMENT}`,
      state: { viewAssessmentScreenData, currentlySelectedClientName },
    };
    history.push(location);
  };

  //FETCH ASSESSMENT DATA AFTER CREATING ASSESSMENT
  executeAPIFetchClientAssessmentDetails = () => {
    const { currentlySelectedClientName } = this.state;

    this.setState({ isAPILoading: true });
    axiosInstance
      .get(`${LocalApiBaseUrl}BATAssessment/GetClientAssessmentViewDetails`)
      .then((response) => {
        const responseData = response.data;

        const foundAssessmentForCurrentClient = _.find(responseData, { BATClientName: currentlySelectedClientName });
        let countOfAssessments = 0;
        _.forEach(foundAssessmentForCurrentClient.OfferingDetails, (eachOffering) => {
          countOfAssessments = countOfAssessments + eachOffering.SubOfferingDetails.length;
        });
        this.setState(
          {
            isAPILoading: false,
            createdOrUpdatedAssessmentObj: foundAssessmentForCurrentClient,
          },
          () => {
            countOfAssessments > 1 ? this.openOfferingSelectionModal() : this.redirectScreenData();
          }
        );
      })
      .catch((error) => {
        this.setState({ isAPILoading: false }, () => {
          trycatchAlertPopup(error);
        });
      });
  };

  //AFTER CREATE ASSESSMENT, SCREEN REDIRECTS TO PROVIDE METRIC DETAILS SCREEN
  redirectScreenData = () => {
    const { history } = this.props;
    const { createdOrUpdatedAssessmentObj } = this.state;
    const clientName = createdOrUpdatedAssessmentObj.BATClientName;
    const clientIndustry = createdOrUpdatedAssessmentObj.BATIndustryName;
    const clientMarketUnit = createdOrUpdatedAssessmentObj.BATGeographyName;
    const offeringName = createdOrUpdatedAssessmentObj.OfferingDetails
      ? createdOrUpdatedAssessmentObj.OfferingDetails[0].BATOfferingName
      : "";
    const offeringType = createdOrUpdatedAssessmentObj.OfferingDetails
      ? createdOrUpdatedAssessmentObj.OfferingDetails[0].BATOfferingType
      : "";
    const subOfferingId = createdOrUpdatedAssessmentObj.OfferingDetails
      ? createdOrUpdatedAssessmentObj.OfferingDetails[0].SubOfferingDetails.length > 0
        ? createdOrUpdatedAssessmentObj.OfferingDetails[0].SubOfferingDetails[0].BATSubOfferingID
        : ""
      : "";
    const subOfferingName = createdOrUpdatedAssessmentObj.OfferingDetails
      ? createdOrUpdatedAssessmentObj.OfferingDetails[0].SubOfferingDetails.length > 0
        ? createdOrUpdatedAssessmentObj.OfferingDetails[0].SubOfferingDetails[0].BATSubOfferingName
        : ""
      : "";
    const BATAssessSubOfferingID = createdOrUpdatedAssessmentObj.OfferingDetails[0].SubOfferingDetails
      ? createdOrUpdatedAssessmentObj.OfferingDetails[0].SubOfferingDetails[0].BATAssessSubOfferingID
      : "";

    const isSubOfferingTVEEnabled = createdOrUpdatedAssessmentObj.OfferingDetails[0].SubOfferingDetails[0].IsTVEEnabled;
    const startDate = createdOrUpdatedAssessmentObj.OfferingDetails[0].SubOfferingDetails[0].StartDate;
    const endDate = createdOrUpdatedAssessmentObj.OfferingDetails[0].SubOfferingDetails[0].EndDate;
    const clientCount = _.get(this.props, "location.state.clientCount", 5);
    const BATAssessClientID = createdOrUpdatedAssessmentObj.BATAssessClientID;
    const ClientLogo = createdOrUpdatedAssessmentObj.ClientLogo ? createdOrUpdatedAssessmentObj.ClientLogo : null;

    const redirectScreenData = {
      clientName,
      clientIndustry,
      clientMarketUnit,
      offeringName,
      offeringType,
      subOfferingId,
      subOfferingName,
      BATAssessSubOfferingID,
      BATAssessClientID,
      startDate,
      endDate,
      clientCount,
      ClientLogo,
      isSubOfferingTVEEnabled,
    };
    const redirectLocationData = {
      pathname: `/${ROUTE_CONSTANTS.BAT_PROVIDE_METRIC_DETAILS_FOR_ASSESSMENT}`,
      state: { assessmentMetricDetailsScreenData: redirectScreenData },
    };
    history.push(redirectLocationData);
  };

  handleCallbackHeaderStateChange = (headerStateObj) => {
    const { history } = this.props;
    const clientName = headerStateObj.clientDetails.name;
    const clientIndustry = headerStateObj.clientDetails.BATIndustryName;
    const clientMarketUnit = headerStateObj.clientDetails.BATMarketUnitName;
    const offeringName = headerStateObj.offeringDetails.name;
    const offeringType = headerStateObj.offeringDetails.type;
    const subOfferingId = headerStateObj.subOfferingDetails.id;
    const subOfferingName = headerStateObj.subOfferingDetails.name;
    const isSubOfferingTVEEnabled = headerStateObj.subOfferingDetails.isSubOfferingTVEEnabled;
    const BATAssessSubOfferingID = headerStateObj.BATAssessSubOfferingID;
    const BATAssessClientID = headerStateObj.clientDetails.id;
    const startDate = headerStateObj.startDate;
    const endDate = headerStateObj.endDate;
    const clientCount = _.get(this.props, "location.state.clientCount", 5);
    const ClientLogo = headerStateObj.clientDetails.logo;

    const redirectScreenData = {
      clientName,
      clientIndustry,
      clientMarketUnit,
      offeringName,
      offeringType,
      subOfferingId,
      subOfferingName,
      BATAssessSubOfferingID,
      BATAssessClientID,
      startDate,
      endDate,
      clientCount,
      ClientLogo,
      isSubOfferingTVEEnabled,
    };
    const redirectLocationData = {
      pathname: `/${ROUTE_CONSTANTS.BAT_PROVIDE_METRIC_DETAILS_FOR_ASSESSMENT}`,
      state: { assessmentMetricDetailsScreenData: redirectScreenData },
    };
    history.push(redirectLocationData);
  };

  openOfferingSelectionModal = () => {
    this.setState({
      isVisibleAssessmentSelectionModal: true,
    });
  };

  //EVENT HANDLE FOR CLICK OF CANCEL BUTTON
  closeAssessment = () => {
    const { history } = this.props;

    const flowInitiatedScreen = _.get(this.props, "location.state.flowInitiatedScreen", "");
    const parentFlowInitiationScreen = _.get(this.props, "location.state.parentFlowInitiationScreen", "");
    const renderedFromScreenData = _.get(this.props, "location.state.renderedFromScreenData", {});

    let location;
    if (flowInitiatedScreen === "BatCommonHeader" || parentFlowInitiationScreen === "BatCommonHeader") {
      location = {
        pathname: `/${ROUTE_CONSTANTS.BAT_OFFERING_BENCHMARK_DETAILS}`,
        state: { batViewScreenData: renderedFromScreenData },
      };
    } else {
      location = {
        pathname: `/${ROUTE_CONSTANTS.BAT_LANDING_PAGE}`,
      };
    }
    history.push(location);
  };

  //CHECK IF ENTERED CLIENT DOES NOT HAVE ALREADY CREATED ASSESSMENT
  checkClientExist = () => {
    const { currentlySelectedClientName, clients, clientDetails } = this.state;

    const flowInitiatedScreen = _.get(this.props, "location.state.flowInitiatedScreen", "");
    const requestData = {
      BATClientName: currentlySelectedClientName,
    };

    const result = _.find(clients, function(eachItem) {
      return _.toLower(eachItem.clientName) === _.toLower(currentlySelectedClientName);
    });

    if (
      flowInitiatedScreen === "viewAssessment" &&
      _.toLower(currentlySelectedClientName) === _.toLower(_.get(clientDetails, "BATClientName", ""))
    ) {
      this.setState({
        currentlySelectedClientName: clientDetails.BATClientName,
        currentlySelectedIndustryId: clientDetails.BATIndustryID,
        currentlySelectedGeographyId: clientDetails.BATGeographyID,
        showGeo: true,
        showIndustry: result != undefined ? false : true,
      });
    } else if (currentlySelectedClientName.length > 0) {
      axiosInstance
        .post(`${LocalApiBaseUrl}BATAssessment/CheckIfClientUserAlreadyExist`, requestData)
        .then((response) => {
          const responseData = response.data;
          if (responseData.IsSuccess) {
            this.setState({
              isAPILoading: false,
              showGeo: true,
              showIndustry: result != undefined ? false : true,
              isExistAssessmentForClient: false,
            });
          } else {
            this.setState({
              isAPILoading: false,
              showGeo: false,
              showIndustry: false,
              isExistAssessmentForClient: true,
              isVisibleModalAssessmentExistsForClient: true,
            });
          }
        })
        .catch((error) => {
          this.setState({ isAPILoading: false });
          trycatchAlertPopup(error);
        });
    } else {
      this.setState({
        showGeo: false,
        showIndustry: false,
        currentlySelectedGeographyId: "",
      });
    }
  };

  // EVENT HANDLER FOR THE CLIENT LOGO FILE INPUT
  handleChangeFileUpload = (e) => {
    const { configValues } = this.state;

    const file = e.target.files[0];
    const maxImageSize = configValues.clientLogoFileMaxSizeInKB;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const fileInfo = {
        name: file.name,
        type: file.type,
        size: Math.round(file.size / 1000) + " kB",
        base64: reader.result,
        file: file,
      };

      const idxDot = file.name.lastIndexOf(".") + 1;
      const fileExtension = file.name.substr(idxDot, file.name.length).toLowerCase();
      if (fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "png") {
        if (Math.round(file.size / 1000) <= maxImageSize) {
          this.setState({
            uploadedClientLogo: fileInfo.base64.substring(fileInfo.base64.indexOf(",") + 1, fileInfo.base64.length),
          });
        } else {
          alert("File Size should be less than " + maxImageSize + " KB");
        }
      } else {
        alert("Only jpg/jpeg and png files are allowed!");
      }
    };
  };

  handleHideModalAssessmentExistsForClient = () => {
    this.setState({
      isVisibleModalAssessmentExistsForClient: false,
    });
  };

  // HANDLER FOR FAVORITE CLICK
  handleClickFavoriteIconForOffering = (e, currentlySelectedOffering) => {
    e.stopPropagation();
    const { favoriteOfferings } = this.state;

    const mappedIdsOfFavoriteOfferings = _.map(favoriteOfferings, "offeringId");
    const currentlySelectedOfferingId = currentlySelectedOffering.id;

    if (mappedIdsOfFavoriteOfferings.includes(currentlySelectedOfferingId)) {
      const newSelectedFavOfferings = favoriteOfferings.filter(function(eachOffering) {
        return eachOffering.offeringId !== currentlySelectedOfferingId;
      });

      this.setState(
        {
          favoriteOfferings: newSelectedFavOfferings,
        },
        () => this.getOtherOffering(currentlySelectedOffering, "remove")
      );
    } else {
      this.setState(
        {
          favoriteOfferings: [...favoriteOfferings, currentlySelectedOffering],
        },
        () => this.getOtherOffering(currentlySelectedOffering, "add")
      );
    }
  };

  // GET OFFERING OTHER THAN FAVORITES
  getOtherOffering = (offering, flowName) => {
    const { offerings, favoriteOfferings } = this.state;

    const mappedIdsOfFavoriteOfferings = _.map(favoriteOfferings, "offeringId");

    const otherOfferings = offerings.filter(function(eachItem) {
      return mappedIdsOfFavoriteOfferings.indexOf(eachItem.id) === -1;
    });

    if (!_.isEmpty(flowName)) {
      this.setState(
        {
          otherOfferings: otherOfferings,
        },
        () => this.addRemoveOfferingToSelection(offering, flowName)
      );
    } else {
      this.setState({
        otherOfferings: otherOfferings,
      });
    }
  };

  render() {
    const {
      isAPILoading,
      showGeo,
      showIndustry,
      currentlySelectedTab,
      currentlySelectedClientName,
      currentlySelectedIndustryId,
      currentlySelectedGeographyId,
      uploadedClientLogo,
      selectedOfferings,
      selectedSubOfferings,
      filteredClientsForAutocomplete,
      favoriteOfferings,
      otherOfferings,
      isExistAssessmentForClient,
      isVisibleModalAssessmentExistsForClient,
      isVisibleAssessmentSelectionModal,
      geographies,
      industries,
      createdOrUpdatedAssessmentObj,
      isCompletedClientDetailsStep,
      isCompletedOfferingsStep,
      isCompletedSubOfferingsStep,
      isDisabledCreateAssessmentButton,
    } = this.state;

    const clientCount = _.get(this.props, "location.state.clientCount", 5);
    const clientDetails = _.get(this.props, "location.state.clientDetails", {});

    let isScreenInEditMode = false;
    // IF THE SCREEN IS RENDERED WITH DATA TO EDIT FOR THE CLIENT THEN IT IS IN EDIT MODE
    if (!_.isEmpty(_.get(clientDetails, "BATClientName", ""))) {
      isScreenInEditMode = true;
    }

    let isDisabledSaveAndExitButton = true;
    let isDisabledSelectOfferingsButton = true;
    if (
      !_.isEmpty(currentlySelectedClientName) &&
      !_.isEmpty(String(currentlySelectedIndustryId)) &&
      !_.isEmpty(String(currentlySelectedGeographyId))
    ) {
      isDisabledSaveAndExitButton = false;
      isDisabledSelectOfferingsButton = false;
    }

    let isDisabledSelectSubOfferingsButton = true;
    if (!_.isEmpty(selectedOfferings)) {
      isDisabledSelectSubOfferingsButton = false;
    }

    const renderLayoutForOfferingsList = (offerings) => {
      const layout = _.map(offerings, (eachOffering, index) => {
        const offeringName = eachOffering.offering;
        const logo = _.get(_.find(BATOfferingIcons, (eachLogo) => eachLogo.key === eachOffering.logo), "iconBlue", "");
        const subOfferingCountText = eachOffering.subOfferingCount < 2 ? "Sub Offering" : "Sub Offerings";

        // CHECK IF THE OFFERING IS ENABLED FOR ASSESSMENT
        const isEnabledForAssessment = _.indexOf(enabledOfferingsBATForAssessment, offeringName) !== -1;

        const isSelected = !_.isEmpty(_.find(selectedOfferings, { offering: offeringName }));
        const isFavorite = !_.isEmpty(_.find(favoriteOfferings, { offering: offeringName }));

        return (
          <div
            className={classNames("col-lg-3", "rightMargin", {
              disabled: !isEnabledForAssessment,
            })}
            key={index}
            onClick={() => {
              if (isEnabledForAssessment) {
                this.addRemoveOfferingToSelection(eachOffering);
              }
            }}
          >
            <div className={classNames("analysisFull-wrap", { active: isSelected })}>
              <div className="analysisFull">
                <div className="analysisIcon">
                  {isSelected && <img src={checkCircle} height="40px" width="40px" alt={offeringName} />}
                  {!isSelected && !_.isEmpty(logo) && <img src={logo} height="40px" width="40px" alt={offeringName} />}
                  {!isSelected && _.isEmpty(logo) && <i className={classNames("far")} />}
                </div>

                <div className="analysisTitle">
                  <h4>{offeringName}</h4>
                  <p>
                    {eachOffering.subOfferingCount} {subOfferingCountText}
                  </p>
                </div>

                <button
                  className="button favorite-btn"
                  onClick={(e) => this.handleClickFavoriteIconForOffering(e, eachOffering)}
                >
                  <i
                    className={classNames("fas", "fa-star", { active: isFavorite }, { inactive: !isFavorite })}
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        );
      });

      return layout;
    };

    return (
      <>
        {isAPILoading && <LoadingOverlay spinner={<SynopsLoader />} active />}

        <div className="takeAssessment-view">
          <div className="assessment-header">
            <div className="title">{isScreenInEditMode ? "Edit Assessment" : "Take Assessment"}</div>
            <div>
              <Button
                className="btn-purple mr-3"
                disabled={isDisabledSaveAndExitButton}
                onClick={() => this.executeAPIToCreateAssessment("saveButton")}
              >
                Save &amp; Exit
              </Button>
              <Button className="bat-cancel btn-default" onClick={() => this.closeAssessment()}>
                Cancel
              </Button>
            </div>
          </div>

          <div className="assessment-body assessment-counttabs">
            <Tabs
              defaultTab={currentlySelectedTab}
              onChange={(tabId) => {
                this.setState({ currentlySelectedTab: tabId });
              }}
            >
              <TabList>
                <Tab
                  tabFor="clientDetailsTab"
                  className={classNames(
                    "assessment-tab",
                    "tabBar",
                    { active: currentlySelectedTab === "clientDetailsTab" },
                    { visited: isCompletedClientDetailsStep }
                  )}
                >
                  <span className="assessment-tabcount tab-one" />
                  Client Details
                </Tab>

                <Tab
                  tabFor="offeringsTab"
                  className={classNames(
                    "assessment-tab",
                    "tabBar",
                    {
                      active: currentlySelectedTab === "offeringsTab",
                    },
                    { visited: isCompletedOfferingsStep }
                  )}
                  disabled={!isCompletedOfferingsStep}
                >
                  <span className="assessment-tabcount tab-two" />
                  Offerings
                </Tab>

                <Tab
                  tabFor="subOfferingsTab"
                  className={classNames(
                    "assessment-tab",
                    {
                      active: currentlySelectedTab === "subOfferingsTab",
                    },
                    { visited: isCompletedSubOfferingsStep }
                  )}
                  disabled={!isCompletedSubOfferingsStep}
                >
                  <span className="assessment-tabcount tab-three" />
                  Sub Offerings
                </Tab>
              </TabList>

              {/* START - CLIENT DETAILS TAB SECTION */}
              <TabPanel tabId="clientDetailsTab">
                <>
                  <div className="client-details row">
                    <div className="col-2"></div>
                    <div className="col-4">
                      <div className="assessment-counttab-navigation text-right">
                        <button
                          className="btn btn-purple-secondary"
                          onClick={() => this.goToOfferingsTab()}
                          disabled={isDisabledSelectOfferingsButton}
                        >
                          {" "}
                          Select Offerings&nbsp;
                          <FontAwesomeIcon className="ml-2" icon={faChevronRight} />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="client-details row">
                    <div className="col-2">
                      <div className="clientlogo-wrapper">
                        <button className="btn">
                          <img
                            src={
                              uploadedClientLogo
                                ? "data:image/png;base64," + uploadedClientLogo
                                : uploadPlaceholderImage
                            }
                            width="40px"
                            height="40px"
                            alt="Upload_Logo"
                          />
                          {_.isEmpty(uploadedClientLogo) && "Upload Client Logo"}
                        </button>

                        <input
                          type="file"
                          name="myfile"
                          id="customFile"
                          accept="image/*"
                          onChange={this.handleChangeFileUpload}
                        />
                      </div>
                    </div>

                    <div className="client-data col-4">
                      <div className="form-group box-group">
                        <label htmlFor="clientName" className="form-label">
                          Client Name
                        </label>
                        <div className="autocomplete-client">
                          <Autocomplete
                            getItemValue={(item) => item.clientName}
                            items={filteredClientsForAutocomplete}
                            renderItem={(item, isHighlighted) => (
                              <div
                                className="autocomplete-clientname"
                                style={{ background: isHighlighted ? "lightgray" : "white" }}
                                key={item.batClientID}
                              >
                                {item.clientName}
                              </div>
                            )}
                            value={currentlySelectedClientName}
                            onChange={(event, value) => this.handleChangeClientAutocompleteInput(event, value)}
                            onSelect={(val) => this.handleSelectClientAutocompleteItem(val)}
                            menuStyle={{
                              position: "absolute",
                              left: 14,
                              top: 60,
                              overflow: "auto",
                              width: "100%",
                              maxWidth: "376px",
                              maxHeight: "185px",
                              borderRadius: "3px",
                              boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                              background: "rgba(255, 255, 255, 0.9)",
                            }}
                          />
                        </div>

                        {isExistAssessmentForClient && (
                          <span className="errorMsg">
                            Assessment for this client is already created, please change the name to go ahead
                          </span>
                        )}
                      </div>

                      <div className={"form-group box-group " + (showIndustry ? "" : "disabled")}>
                        <label htmlFor="industry" className="form-label">
                          Industry
                        </label>
                        <select
                          value={currentlySelectedIndustryId}
                          onChange={(e) => this.handleChangeIndustrySelection(e)}
                          className="form-control"
                          id="industrySelect"
                        >
                          <option value="">Select Industry</option>
                          {industries.map((option) => {
                            return (
                              <option value={option.industryID} key={option.industryName}>
                                {option.industryName}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div className={"form-group box-group " + (showGeo ? "" : "disabled")}>
                        <label htmlFor="marketUnit" className="form-label">
                          Geography
                        </label>
                        <select
                          value={currentlySelectedGeographyId}
                          onChange={(e) => this.handleChangeGeographySelection(e)}
                          className="form-control"
                          id="marketSelect"
                        >
                          <option value="">Select Geography</option>
                          {geographies.map((option) => {
                            return (
                              <option value={option.geographyID} key={option.geographyName}>
                                {option.geographyName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </>
              </TabPanel>
              {/* END - CLIENT DETAILS TAB SECTION */}

              {/* START - OFFERINGS TAB SECTION */}
              <TabPanel tabId="offeringsTab">
                <div className="client-details row">
                  <div className="assessment-counttab-navigation counttab-navbtns">
                    <button className="btn btn-purple-secondaryoutline" onClick={() => this.goToClientDetailsTab()}>
                      <FontAwesomeIcon icon={faChevronLeft} />
                      <span>Previous</span>
                    </button>

                    <p>Please select the offerings applicable</p>

                    <button
                      className="btn btn-purple-secondaryoutline"
                      onClick={() => this.goToSubOfferingsTab()}
                      disabled={isDisabledSelectSubOfferingsButton}
                    >
                      {" "}
                      <span>Next</span>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className={"favourite-section " + (favoriteOfferings.length > 0 ? "" : "hover-delete-icon")}>
                      <h6 className="favourite-title">Favorites</h6>
                      <div className="row">{renderLayoutForOfferingsList(favoriteOfferings)}</div>
                    </div>

                    {favoriteOfferings.length > 0 ? (
                      <Accordion className="otherOfferingAccordion">
                        <Card>
                          <Accordion.Toggle as={Card.Header} eventKey="0" className="card-link collapsed">
                            Other Offerings
                          </Accordion.Toggle>

                          <Accordion.Collapse eventKey="0">
                            <Card.Body>
                              <div className="row">{renderLayoutForOfferingsList(otherOfferings)}</div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    ) : (
                      <div className="row">{renderLayoutForOfferingsList(otherOfferings)}</div>
                    )}
                  </div>
                </div>
              </TabPanel>
              {/* START - OFFERINGS TAB SECTION */}

              {/* START - SUB-OFFERINGS TAB SECTION */}
              <TabPanel tabId="subOfferingsTab">
                <div className="client-details row">
                  <div className="assessment-counttab-navigation counttab-navbtns">
                    <button className="btn btn-purple-secondaryoutline" onClick={() => this.goToOfferingsTab()}>
                      <FontAwesomeIcon icon={faChevronLeft} />
                      <span>Previous</span>
                    </button>

                    <p>Select Sub Offerings</p>

                    <button
                      className="btn btn-purple-secondaryoutline"
                      onClick={() => this.executeAPIToCreateAssessment()}
                      disabled={isDisabledCreateAssessmentButton}
                    >
                      {" "}
                      <span>{isScreenInEditMode ? "Update Assessment" : "Create Assessment"}</span>
                      <FontAwesomeIcon className="ml-2" icon={faChevronRight} />
                    </button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="offering-list assessmenttab-offeringlist">
                      {/* BASED ON THE SELECTED OFFERINGS IN PREVIOUS STEP, DISPLAY THE SUB-OFFERINGS UNDER EACH OFFERING */}
                      {_.map(selectedOfferings, (eachSelectedOffering) => {
                        const offeringName = eachSelectedOffering.offering;
                        const logo = _.get(
                          _.find(BATOfferingIcons, (eachLogo) => eachLogo.key === eachSelectedOffering.logo),
                          "iconBlue",
                          ""
                        );
                        const subOfferingCountText =
                          eachSelectedOffering.subOfferingCount < 2 ? "Sub Offering" : "Sub Offerings";
                        const subOfferingCount = eachSelectedOffering.subOfferingCount;

                        return (
                          <div className="col-lg-3 rightMargin" key={offeringName}>
                            <div className="analysisFull-wrap">
                              <div className="analysisFull ">
                                <div>
                                  <div className="analysisFull-header">
                                    <div className="analysisIcon">
                                      {!_.isEmpty(logo) ? (
                                        <img src={logo} height="48px" width="48px" alt={offeringName} />
                                      ) : (
                                        <i className={classNames("far")}></i>
                                      )}
                                    </div>
                                    <div className="analysisTitle">
                                      <h4>{offeringName}</h4>
                                      <p>
                                        {subOfferingCount} {subOfferingCountText}
                                      </p>
                                    </div>
                                  </div>

                                  {/* DISPLAY SUB-OFFERINGS OF THE CURRENT OFFERING */}
                                  <div className="subOffering-list">
                                    {_.map(eachSelectedOffering.subOfferings, (eachSubOffering) => {
                                      const subOfferingId = eachSubOffering.id;
                                      const isSelected = !_.isEmpty(
                                        _.find(selectedSubOfferings, { id: subOfferingId })
                                      );
                                      return (
                                        <div key={subOfferingId}>
                                          <div
                                            className={classNames("check-group", {
                                              disabled: subOfferingCount === 1,
                                            })}
                                          >
                                            <label className="form-check">
                                              <input
                                                type="checkbox"
                                                name="check1"
                                                value=""
                                                checked={isSelected}
                                                onChange={() => {
                                                  this.getSelectedSubofferings(eachSelectedOffering, eachSubOffering);
                                                }}
                                                className="form-check-input"
                                              />
                                              <span className="checkmark" />
                                              <span>{eachSubOffering.name}</span>
                                            </label>
                                          </div>
                                          <br />
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </TabPanel>
              {/* END - SUB-OFFERINGS TAB SECTION */}
            </Tabs>

            {/* MODAL TO DISPLAY THAT THE ASSESSMENT FOR THE CLIENT ALREADY EXISTS */}
            {isVisibleModalAssessmentExistsForClient && (
              <Modal
                show
                centered
                backdrop="static"
                dialogClassName="modal-50w"
                onHide={() => this.handleHideModalAssessmentExistsForClient()}
                className="duplicateAssessmentModal"
              >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="justify-content-center">
                  <div className="duplicateAssessment">
                    Assessment for {currentlySelectedClientName} is already created, Please navigate to
                    <span onClick={() => this.redirectToViewAssessmentScreen()}>View Assessment</span>
                    to view/edit client assessment.
                  </div>
                </Modal.Body>
              </Modal>
            )}

            {/* ASSESSMENT SELECTION MODAL THAT IS DISPLAYED AFTER CREATING/UPDATING ASSESSMENT */}
            {isVisibleAssessmentSelectionModal && (
              <AssessmentSelectionModal
                clientCount={clientCount}
                BATAssessClientID={createdOrUpdatedAssessmentObj.BATAssessClientID}
                clientName={createdOrUpdatedAssessmentObj.BATClientName}
                BATAssessSubOfferingID={
                  createdOrUpdatedAssessmentObj.OfferingDetails[0].SubOfferingDetails[0].BATAssessSubOfferingID
                }
                offeringName={createdOrUpdatedAssessmentObj.OfferingDetails[0].BATOfferingName}
                screenfrom="takeAssessment"
                callbackHeaderStateChange={(headerStateObj) => this.handleCallbackHeaderStateChange(headerStateObj)}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(TakeAssessment);
