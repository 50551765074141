import React from "react";
import { Modal, Button } from "react-bootstrap";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { regexForFields } from "../Shared/Constant";
import LoadingOverlay from "react-loading-overlay";
import i18n from "i18next";
import Moment from "moment";
import MultiSelect from "react-multi-select-component";
import axiosInstance from "../Shared/interceptor";
import {trycatchAlertPopup} from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import { AddEditInitiative, InitializeIOJData, saveJourneyData } from "../actions/JourneyActions";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import SynopsLoader from "../Shared/SynopsLoader";
import NotificationForAddEdit from "./NotificationForAddEdit";

const overrideStrings = {
  selectSomeItems: "Select",
  allItemsAreSelected: "All items selected.",
  selectAll: "Select All ",
  search: "Search",
};
class IOJInitiativePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isApiLoading: false,
      InitiativeOutcomeOptionlabval: [],
      isMetricGroupNameChanged: false,
      apivalue: "",
      intelOpsDetails: "",
      selectValue: this.props.SelectValue,
      Display: false,
      id: this.props.id,
      maturity: this.props.maturity,
      type: this.props.type,
      StartDate: null,
      EndDate: "",
      BOI: "",
      WOtiles: "",
      WotileId: "",
      EstimatedUnitState: [],
      soCategories: [],
      InitiativeOutcomeOptions: [],
      intelOpsDetails: this.props.InitiativeCategory
        ? this.props.InitiativeCategory.filter((el) => {
            return el.IsActive === true && el.IntelOpsStageName === this.props.maturity;
          })
        : [],
      IImetrics: [],
      BusinessOutcomeIndicator: this.props.BoiList,
      iojData: {
        ID: 0,
        ConfigurationIntelOpsID: 0,
        ConfigurationIntelOpsInitiativesID: 0,
        ConfigurationIntelOpsJourneyID: 0,
        IOJStageID: this.props.IOJData.filter((e) => e.IOJStageName === this.props.maturity)[0].IOJStageID,
        OrgEntityName: "",
        OfferingID: "",
        OfferingName: "",
        LocationID: "",
        LocationName: "",
        SOCategoryID: "",
        SOCategoryName: "",
        ProjectInitiativeName: "",
        ProjectDescription: "",
        InitiativeOutcomeOption: [],
        //MaturityPhase: "Foundation",
        MaturityPhase: "",
        InitiativeStatus: "",
        TargetValue: "",
        TargetValueWoUnit: "",
        DeliveredValue: "",
        DeliveredValueWoUnit: "",
        ValueUnit: "",
        BOImpacted: "",
        BOFrom: "NA",
        BOTo: "NA",
        RAGStatus: "",
        BOSeqNumber: "",
        IntelOpsCategoryId: "",
        IntelOpsCategoryName: "",
        InitiativeType: "",
        ImpactType: "",
        KPIImpacted: "",
        ImpactStartDate: new Date(),
        ImpactPeakDate: new Date(),
        ThroughputAndManpower: "",
        WOStep: "",
        VolumetricInfluence: "",
        RolesTransitioned: "",
        WOMetricSOcatID: "",
        WOMetricKPIMappingID: "",
        EstimatedUnit: "",
        ValueDeliveredUnit: "",
        BOIKpiID: "",
        IntelOpscatID: "",
        IntelOpsCateName: "",
      },
      formIsValid: false,
      errors: {},

      rows: 5,
      minRows: 5,
      maxRows: 10,
      rowsCount: 5,

      message: "",
    };
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onSequenceChange = this.onSequenceChange.bind(this);
    this.onEstimatedValuesChange = this.onEstimatedValuesChange.bind(this);
    this.onInitiativesStatusChange = this.onInitiativesStatusChange.bind(this);

    this.onFromChange = this.onFromChange.bind(this);
    this.onToChange = this.onToChange.bind(this);
    this.onStageNameChange = this.onStageNameChange.bind(this);
    this.onStageDescriptionChange = this.onStageDescriptionChange.bind(this);
    this.onSOCategoryChange = this.onSOCategoryChange.bind(this);
    this.onValueDeliveredChange = this.onValueDeliveredChange.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.getKpiMAppingDetails = this.getKpiMAppingDetails.bind(this);

    this.onInitiativesStatusChanged = this.onInitiativesStatusChanged.bind(this);
    this.onBOIChange = this.onBOIChange.bind(this);
    this.onImpactChange = this.onImpactChange.bind(this);
    this.KPIImpactedChange = this.KPIImpactedChange.bind(this);
    this.onThroughPutDdlChange = this.onThroughPutDdlChange.bind(this);
    this.onWotilesChanged = this.onWotilesChanged.bind(this);
    this.onWoMetricChanged = this.onWoMetricChanged.bind(this);
    this.onHumanMetricChanged = this.onHumanMetricChanged.bind(this);
    this.onImpactStartDateChanged = this.onImpactStartDateChanged.bind(this);
    this.onImpactPeakDateChanged = this.onImpactPeakDateChanged.bind(this);
    this.onEstimatedUnitChanged = this.onEstimatedUnitChanged.bind(this);
    this.onValueUnitChanged = this.onValueUnitChanged.bind(this);

    this.onInitiativeTypeChanged = this.onInitiativeTypeChanged.bind(this);

    //this.handleRejectHide = this.handleRejectHide.bind(this);
    //this.handleApproveHide = this.handleApproveHide.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.getSOCategories = this.getSOCategories.bind(this);
    let offeringValue = "";
    let value1 = [];
  }

  getSOCategories() {
    let categories = [];
    axiosInstance
      .get(`${LocalApiBaseUrl}SO/GetSOCategories`, {
        params: { LanguageID: store.getState().CurrentFilter.languageData.id },
      })
      .then((response) => {
        categories = response.data;

        categories = categories.filter((data) => data.SOCategoryID != 1);
        let catValues = categories.map((x) => (
          <option key={x.SOCategoryID} /*selected={index == 0 ? "" : ""}*/ value={x.SOCategoryID}>
            {x.SOCategoryName}
          </option>
        ));
        this.setState({ catOptions: catValues });
        this.setState({ soCategories: categories });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  componentWillMount() {
    if (this.props.filterDetails != undefined)
      if (
        this.props.filterDetails.Client != 0 &&
        this.props.filterDetails.Locations != 0 &&
        this.props.filterDetails.Offering != 0 &&
        this.props.filterDetails.Process != 0
      ) {
        this.setState({ Display: true });
      }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.filterDetails != undefined)
      if (
        nextProps.filterDetails.Client != 0 &&
        nextProps.filterDetails.Locations != 0 &&
        nextProps.filterDetails.Offering != 0 &&
        nextProps.filterDetails.Process != 0
      ) {
        this.setState({ Display: true });
      }
  }

  getJourneyInitiativeContainerData = () => {
    const { currentFilter, languageData, clientData, history } = this.props;
    this.setState({ isApiLoading: true });
    let data = {
      OrgEntityID: clientData.id,
      OfferingID: currentFilter.ProcessId === "All" ? 0 : currentFilter.ProcessId,
      LocationID: currentFilter.LocationId === "All" ? 0 : currentFilter.LocationId,
      OMID: currentFilter.LocationOMID[0] === "All" ? 0 : currentFilter.LocationOMID[0],
      LanguageID: languageData.id,
      OfferingName: !currentFilter.OfferingName ? "All" : currentFilter.OfferingName,
      IsConfigurator: history.location.pathname === "/admin/Configurator" ? true : false,
    };
    store.dispatch(saveJourneyData({ ConfiguratorID: "", IOJGlidePathAllModels: [], IsIOJCategory: false }));
    axiosInstance
      .post(`${LocalApiBaseUrl}IOJJourneyInitiative/GetNewAllGlidePathData`, data)
      .then((response) => {
        if (response.data) {
          store.dispatch(saveJourneyData(response.data));
          store.dispatch(InitializeIOJData(response.data.IOJGlidePathAllModels));
        }
        this.setState({ isApiLoading: false });
      })
      .catch((error) => {
        this.setState({ isApiLoading: false });
        trycatchAlertPopup(error);
      });
  };

  componentDidMount() {
    const { initiativeDataSet } = this.props;
    this.getKpiMAppingDetails();
    this.getSOCategories();
    if (this.state.id != undefined && this.state.type != undefined) {
      let initalState = store.getState();

      let modifiedInitiativeDataSet = JSON.parse(JSON.stringify(initiativeDataSet));

      let editData = modifiedInitiativeDataSet.filter(
        (data) =>
          //   data.ID == this.state.id &&
          data.ConfigurationIntelOpsInitiativesID == this.state.id &&
          data.MaturityPhase == this.state.maturity &&
          data.ProjectInitiativeName == this.state.type
      )[0];

      let InitiativeOutcomeOption = [];
      let iosdata = editData.InitiativeOutcomeOption ? editData.InitiativeOutcomeOption.toString().split(",") : [];
      iosdata.map((row) => {
        let dummy = [];
        dummy.label = row.trim();
        dummy.value = row.trim();
        InitiativeOutcomeOption.push(dummy);
      });
      //this.state.InitiativeOutcomeOptionlabval=InitiativeOutcomeOption;

      if (editData.ImpactType === null || editData.ImpactType === "" || editData.ImpactType === undefined) {
        editData.ImpactType = "Select";
        editData.KPIImpacted = "";
        editData.BOImpacted = "";
        editData.ThroughputAndManpower = "";
        editData.WOStep = "";
        editData.RolesTransitioned = "";
        editData.VolumetricInfluence = "";
        editData.WOMetricKPIMappingID = "";
        editData.WOMetricSOcatID = "";
        editData.TargetValue = "";
        editData.EstimatedUnit = "";
        editData.DeliveredValue = "";
        editData.ValueUnit = "";
        editData.SOCategoryID = "";
        editData.SOCategoryName = "";
        editData.ImpactPeakDate = new Date();
        editData.ImpactStartDate = new Date();
      }
      let unit = [];

      if (
        (editData.ImpactType === "Direct" || editData.ImpactType === "InDirect") &&
        (editData.SOCategoryID === 2 || editData.SOCategoryID === "" || editData.SOCategoryID === 3)
      ) {
        editData.SOCategoryID = "";
        editData.SOCategoryName = "";
      }
      if (
        editData.InitiativeStatus === "Delivered" &&
        (editData.DeliveredValue !== "" || editData.DeliveredValue !== null)
      ) {
        unit.push(editData.ValueUnit);
      } else if (
        editData.InitiativeStatus !== "Delivered" &&
        (editData.TargetValue !== "" || editData.TargetValue !== null)
      ) {
        unit.push(editData.EstimatedUnit);
      }

      this.setState(
        {
          iojData: editData,
          InitiativeOutcomeOptionlabval: InitiativeOutcomeOption,
          errors: {},
          formIsValid: true,
          EstimatedUnitState: unit,
        },
        () => {}
      );
    }
  }

  handleValidation(event) {
    const { initiativeDataSet } = this.props;

    let iojData = this.state.iojData;
    let errors = {};
    let formIsValid = true;

    if (!iojData["ProjectInitiativeName"].trim()) {
      formIsValid = false;
      errors["ProjectInitiativeName"] = i18n.t("addWoErrorM1");
    } else {
      //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
      // var regex = /^[A-Za-z0-9 @ $ % & () - _ , . ? â€™ â€ ]+$/

      //Validate TextBox value against the Regex.
      var isValid = regexForFields.test(iojData["ProjectInitiativeName"]);

      if (!isValid) {
        formIsValid = false;
        errors["ProjectInitiativeName"] = i18n.t("Name_contains_Special_characters");
      }
    }

    // var data = store.getState();
    // var intelOpsDetails = data.intelligentReducers["IOJ"];
    var intelOpsDetails = [...initiativeDataSet];

    if (intelOpsDetails.length > 0) {
      let oldData = intelOpsDetails.map(function(el) {
        return el.ProjectInitiativeName.toLowerCase().trim();
      });
      if (oldData.includes(iojData.ProjectInitiativeName.toLowerCase().trim()) && !this.props.edit) {
        formIsValid = false;
        errors["ProjectInitiativeName"] = i18n.t("Name_already_exists");
      }
    }

    if (!iojData["InitiativeStatus"]) {
      formIsValid = false;
      errors["InitiativeStatus"] = i18n.t("Initiative_Phase_is_required");
    }
    let regexforNum = /^\d+(.\d+)?$/;
    if (this.state.iojData.InitiativeStatus !== "Delivered" && iojData["ImpactType"] !== "NA") {
      if (iojData["TargetValue"] !== null && !iojData["TargetValue"]) {
        formIsValid = false;
        errors["TargetValue"] = i18n.t("addBoErrorM1");
      } else {
        var isValid = regexforNum.test(iojData["TargetValue"]);
        if (!isValid) {
          formIsValid = false;
          errors["TargetValue"] = i18n.t("Value_contains_Special_characters");
        }
      }
    } else {
      if (iojData["TargetValue"]) {
        var isValid = regexforNum.test(iojData["TargetValue"]);
        if (!isValid) {
          formIsValid = false;
          errors["TargetValue"] = i18n.t("Value_contains_Special_characters");
        }
      }
    }
    /////////////////////////////////////////////////////new code////////////////////////////////////////
    if (
      iojData["DeliveredValue"] !== null &&
      !iojData["DeliveredValue"] &&
      this.state.iojData.InitiativeStatus === "Delivered" &&
      !iojData["ImpactType"] === "NA"
    ) {
      formIsValid = false;
      errors["DeliveredValue"] = i18n.t("addBoErrorM1");
    } else if (this.state.iojData.InitiativeStatus !== "Delivered") {
    } else {
      if (iojData["DeliveredValue"] === "" && iojData["ImpactType"] !== "NA") {
        formIsValid = false;
        errors["DeliveredValue"] = i18n.t("addBoErrorM1");
      }
      if (iojData["DeliveredValue"] !== "") {
        var isValid = regexforNum.test(iojData["DeliveredValue"]);
        if (!isValid) {
          formIsValid = false;
          errors["DeliveredValue"] = i18n.t("Value_contains_Special_characters");
        }
      }
    }
    if (!iojData["ProjectDescription"].trim()) {
      formIsValid = false;
      errors["ProjectDescription"] = i18n.t("ToolDescription_Required");
    } else {
      //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
      //  var regex = /^[A-Za-z0-9  @ $ % & () - _ , . ? ’ ” ]+$/

      //Validate TextBox value against the Regex.
      var isValid = regexForFields.test(iojData["ProjectDescription"]);
      if (!isValid) {
        formIsValid = false;
        errors["ProjectDescription"] = i18n.t("projectDiscErrorM1");
      }
    }

    if (!iojData["BOImpacted"].trim() && iojData["ImpactType"] !== "NA") {
      formIsValid = false;
      errors["BOImpacted"] = i18n.t("indicatorErrorM1");
    } else {
      //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
      //  var regex = /^[A-Za-z0-9  @ $ % & () - _ , . ? ’ ”]+$/

      //Validate TextBox value against the Regex.
      var isValid = regexForFields.test(iojData["BOImpacted"]);
      // if (!isValid) {

      //     formIsValid = false;
      //     errors["BOImpacted"] = "BOImpacted value contains Special characters";
      // }
    }

    if (iojData["KPIImpacted"] !== null && !iojData["KPIImpacted"].trim() && iojData["ImpactType"] === "Indirect") {
      formIsValid = false;
      errors["KPIImpacted"] = i18n.t("KPI/SLA impacted is required");
    }

    if ((iojData["ImpactType"] !== null && !iojData["ImpactType"].trim()) || iojData["ImpactType"] === "Select") {
      formIsValid = false;
      errors["ImpactType"] = i18n.t("Impact Type is required");
    }

    if (
      iojData["ThroughputAndManpower"] !== null &&
      !iojData["ThroughputAndManpower"].trim() &&
      iojData["BOImpacted"] === "Productivity"
    ) {
      formIsValid = false;
      errors["ThroughputAndManpower"] = i18n.t("Please select the Throughput/Manpower");
    }
    if (
      (iojData["WOStep"] === null ||
        iojData["WOStep"].trim() === "" ||
        iojData["WOStep"] === undefined ||
        iojData["WOStep"] === "--Select--") &&
      iojData["ThroughputAndManpower"] === i18n.t("Throughput Increase")
    ) {
      formIsValid = false;
      errors["WOStep"] = i18n.t("Please select the WO step");
    }
    if (
      iojData["VolumetricInfluence"] !== null &&
      !iojData["VolumetricInfluence"].trim() &&
      iojData["ThroughputAndManpower"] === i18n.t("Throughput Increase")
    ) {
      formIsValid = false;
      errors["VolumetricInfluence"] = i18n.t("Please select the WO metrics(Volumetric Influence)");
    }
    if (
      iojData["RolesTransitioned"] !== null &&
      (iojData["RolesTransitioned"] === "--Select--" || iojData["RolesTransitioned"] === "") &&
      //!iojData["RolesTransitioned"].trim() &&
      iojData["ThroughputAndManpower"] === i18n.t("Less Manpower")
    ) {
      formIsValid = false;
      errors["RolesTransitioned"] = i18n.t("Please select the Human metrics(Role Transitioned)");
    }

    if (!iojData["BOFrom"].trim()) {
      formIsValid = false;
      errors["BOFrom"] = i18n.t("BoFormErrorM1");
    } else {
      //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
      // var regex = /^[A-Za-z0-9  @ $ % & () - _ , . ? â€™ â€ ]+$/

      //Validate TextBox value against the Regex.
      var isValid = regexForFields.test(iojData["BOFrom"]);
      if (!isValid) {
        formIsValid = false;
        errors["BOFrom"] = i18n.t("BOFrom value Description contains Special characters");
      }
    }

    if (!iojData["BOTo"].trim()) {
      formIsValid = false;
      errors["BOTo"] = i18n.t("BO_To_is_required");
    } else {
      //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
      // var regex = /^[A-Za-z0-9  @ $ % & () - _ , . ? â€™ â€]+$/

      //Validate TextBox value against the Regex.
      var isValid = regexForFields.test(iojData["BOTo"]);
      if (!isValid) {
        formIsValid = false;
        errors["BOTo"] = i18n.t("BOTo value Description contains Special characters");
      }
    }
    if (iojData["InitiativeOutcomeOption"] == undefined || !iojData["InitiativeOutcomeOption"].length > 0) {
      formIsValid = false;
      errors["InitiativeOutcomeOption"] = i18n.t("Initiative Outcome is required");
    }

    if (
      !iojData["SOCategoryName"] ||
      iojData["SOCategoryName"] == "Select" ||
      ((iojData["ImpactType"] === "Direct" || iojData["ImpactType"] === "Indirect") &&
        (iojData["SOCategoryID"].toString() === "2" ||
          iojData["SOCategoryID"] === "" ||
          iojData["SOCategoryID"].toString() === "3"))
    ) {
      formIsValid = false;
      errors["SOCategoryName"] = i18n.t("SynOps Lever is required");
    }

    if (!iojData["RAGStatus"]) {
      formIsValid = false;
      errors["RAGStatus"] = "Status is required";
    }
    if (iojData["BOSeqNumber"]) {
      iojData["BOSeqNumber"] = parseInt(iojData["BOSeqNumber"], 10);
    }
    if (!(iojData["BOSeqNumber"] >= 0 && iojData["BOSeqNumber"] <= 999999)) {
      formIsValid = false;
      errors["SeqRange"] = "Sequence Value must be in between 1 to 999999";
    }

    if (!iojData["BOSeqNumber"]) {
      formIsValid = false;
      errors["SeqRange"] = i18n.t("Sequence Value is Required");
    }

    this.setState({
      errors: errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({
        formIsValid: false,
      });
    }
    return formIsValid;
  }

  onStatusChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const iojData = this.state.iojData;
    iojData.RAGStatus = event.target.value;
    this.setState({
      iojData: iojData,
    });
  }

  onNameChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const iojData = this.state.iojData;

    iojData.ProjectInitiativeName = event.target.value;
    this.setState({
      iojData: iojData,
    });
  }

  onSequenceChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const iojData = this.state.iojData;

    iojData.BOSeqNumber = event.target.value;
    this.setState({
      iojData: iojData,
    });
  }
  onDescriptionChange(event) {
    let data = event.target.value;
    if (data.length > 250) {
      this.setState({
        rowsCount: 10,
      });
    } else {
      this.setState({
        rowsCount: 5,
      });
    }

    let errors = {};
    this.setState({
      errors: errors,
    });
    const iojData = this.state.iojData;
    iojData.ProjectDescription = event.target.value;
    this.setState({
      iojData: iojData,
      // rows: currentRows < maxRows ? currentRows : maxRows
    });
  }

  onEstimatedValuesChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const iojData = this.state.iojData;

    iojData.TargetValue = event.target.value.trim();
    iojData.TargetValueWoUnit = event.target.value.trim();

    if (event.target.value === "") {
      iojData.EstimatedUnit = "N.A";
    } else if (event.target.value.length === 1 && event.target.value.trim().length === 0) {
      iojData.EstimatedUnit = "";
    } else {
      // iojData: iojData, EstimatedUnitState: unit
      if (this.state.EstimatedUnitState.length > 0) {
        iojData.EstimatedUnit = this.state.EstimatedUnitState[0];
      } else {
        iojData.EstimatedUnit = "$";
      }
    }

    this.setState({
      iojData: iojData,
    });
  }
  onEstimatedUnitChanged(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const iojData = this.state.iojData;
    iojData.EstimatedUnit = event.target.value;

    if (event.target.value === "N.A") {
      iojData.TargetValue = "";
      iojData.TargetValueWoUnit = "";
    }

    this.setState({
      iojData: iojData,
    });
  }

  onValueDeliveredChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const iojData = this.state.iojData;
    iojData.DeliveredValue = event.target.value.trim();
    iojData.DeliveredValueWoUnit = event.target.value.trim();

    // if (event.target.value === "") {
    //     iojData.ValueUnit = ""

    // }

    if (event.target.value === "") {
      iojData.ValueUnit = "N.A";
    } else if (event.target.value.length === 1 && event.target.value.trim().length === 0) {
      iojData.ValueUnit = "";
    } else {
      // iojData: iojData, EstimatedUnitState: unit
      if (this.state.EstimatedUnitState.length > 0) {
        iojData.ValueUnit = this.state.EstimatedUnitState[0];
      } else {
        iojData.ValueUnit = "$";
      }
    }

    this.setState({
      iojData: iojData,
    });
  }

  onValueUnitChanged(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const iojData = this.state.iojData;
    iojData.ValueUnit = event.target.value;

    if (event.target.value === "N.A") {
      iojData.DeliveredValue = "";
      iojData.DeliveredValueWoUnit = "";
    }

    this.setState({
      iojData: iojData,
    });
  }
  onInitiativesStatusChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const iojData = this.state.iojData;
    iojData.InitiativeStatus = event.target.value;

    if (event.target.value !== "Delivered") {
      iojData.DeliveredValueWoUnit = "";
      iojData.ValueUnit = "";
      iojData.DeliveredValue = "";
    }
    this.setState({
      iojData: iojData,
    });
  }
  onBOIChange(event) {
    var index = event.target.selectedIndex;
    var optionElement = event.target.childNodes[index];
    var option = optionElement.getAttribute("id");
    let errors = {};
    this.setState({
      errors: errors,
    });

    const iojData = this.state.iojData;
    iojData.BOImpacted = event.target.options[event.target.selectedIndex].text;

    if (iojData.BOImpacted !== "Productivity") {
      iojData.ThroughputAndManpower = "";
      iojData.WOStep = "";
      iojData.RolesTransitioned = "";
      iojData.VolumetricInfluence = "";
    }

    //Extract Units
    let CalIndex;

    if (iojData.ImpactType === "Direct") {
      CalIndex = index - 2;
    } else if (iojData.ImpactType === "Indirect") {
      CalIndex = index - 1;
    }
    let BOImetric = this.state.BOI[CalIndex];

    let unit = [];

    if (BOImetric !== undefined) {
      unit.push(BOImetric.MetricUnit);
    }

    if (event.target.options[event.target.selectedIndex].text === "") {
      if (iojData.TargetValue !== "") {
        iojData.EstimatedUnit = "$";
      } else {
        iojData.EstimatedUnit = "N.A";
      }

      if (iojData.DeliveredValue !== "") {
        iojData.ValueUnit = "$";
      } else {
        iojData.ValueUnit = "N.A";
      }
    } else {
      if (unit.length > 0) {
        if (iojData.TargetValue === "") {
          iojData.EstimatedUnit = "N.A";
        } else {
          iojData.EstimatedUnit = unit[0];
        }

        if (iojData.DeliveredValue === "") {
          iojData.ValueUnit = "N.A";
        } else {
          iojData.ValueUnit = unit[0];
        }
      }
    }

    this.setState({
      iojData: iojData,
      EstimatedUnitState: unit,
    });
  }

  onInitiativeTypeChanged(event) {
    var index = event.target.selectedIndex;
    var optionElement = event.target.childNodes[index];
    let errors = {};
    this.setState({
      errors: errors,
    });
    const iojData = this.state.iojData;
    iojData.IntelOpsCategoryName = event.target.value;

    let IntelOps = this.state.intelOpsDetails[index - 1];
    if (IntelOps !== undefined) {
      iojData.IntelOpsCategoryId = IntelOps.IntelOpsCategoryId;
    }

    this.setState({
      iojData: iojData,
    });
  }
  onInitiativesStatusChanged(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const iojData = this.state.iojData;
    iojData.InitiativeType = event.target.value;
    this.setState({
      iojData: iojData,
    });
  }

  onImpactChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const iojData = this.state.iojData;
    iojData.ImpactType = event.target.value;

    if (iojData.ImpactType === "Select") {
      iojData.BOImpacted = "";
      iojData.KPIImpacted = "";

      iojData.ThroughputAndManpower = "";
      iojData.WOStep = "";
      iojData.RolesTransitioned = "";
      iojData.VolumetricInfluence = "";
      iojData.WOMetricKPIMappingID = "";
      iojData.WOMetricSOcatID = "";

      iojData.TargetValue = "";
      iojData.EstimatedUnit = "";

      iojData.DeliveredValue = "";
      iojData.ValueUnit = "";

      this.setState({
        EstimatedUnitState: [],
      });
    } else if (iojData.ImpactType === "Direct" || iojData.ImpactType === "InDirect") {
      iojData.KPIImpacted = "";

      iojData.BOImpacted = "";
      iojData.ThroughputAndManpower = "";
      iojData.WOStep = "";
      iojData.RolesTransitioned = "";
      iojData.VolumetricInfluence = "";
      iojData.WOMetricKPIMappingID = "";
      iojData.WOMetricSOcatID = "";
      iojData.TargetValue = "";
      iojData.EstimatedUnit = "";
      iojData.SOCategoryID = "";
      iojData.SOCategoryName = "";

      iojData.DeliveredValue = "";
      iojData.ValueUnit = "";
      this.setState({
        EstimatedUnitState: [],
      });
    } else {
      iojData.KPIImpacted = "";
      iojData.BOImpacted = "";

      iojData.ThroughputAndManpower = "";
      iojData.WOStep = "";
      iojData.RolesTransitioned = "";
      iojData.VolumetricInfluence = "";
      iojData.WOMetricKPIMappingID = "";
      iojData.WOMetricSOcatID = "";
      iojData.TargetValue = "";
      iojData.EstimatedUnit = "";

      iojData.DeliveredValue = "";
      iojData.ValueUnit = "";
      this.setState({
        EstimatedUnitState: [],
      });
    }

    this.setState({
      iojData: iojData,
    });
  }
  KPIImpactedChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const iojData = this.state.iojData;
    iojData.KPIImpacted = event.target.options[event.target.selectedIndex].text;
    this.setState({
      iojData: iojData,
    });
  }

  onThroughPutDdlChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const iojData = this.state.iojData;
    iojData.ThroughputAndManpower = event.target.value;

    iojData.WOStep = "";
    iojData.RolesTransitioned = "";
    iojData.VolumetricInfluence = "";
    iojData.WOMetricKPIMappingID = "";
    iojData.WOMetricSOcatID = "";

    //Setting state for Synpos lever

    let cat = this.state.soCategories;
    let categories = [];

    if (this.state.iojData.ThroughputAndManpower === "Less Manpower") {
      categories = cat.filter((data) => data.SOCategoryID === 2);
    } else if (this.state.iojData.ThroughputAndManpower === "Throughput Increase") {
      categories = cat.filter((data) => data.SOCategoryID === 3);
    }
    let synopsLever = categories.map((x) => {
      iojData.SOCategoryID = x.SOCategoryID;
      iojData.SOCategoryName = x.SOCategoryName;
    });

    this.setState({
      iojData: iojData,
    });
  }

  onWotilesChanged(event) {
    let errors = {};
    var index = event.target.selectedIndex;
    var optionElement = event.target.childNodes[index];
    var option = optionElement.getAttribute("id");

    this.setState({
      errors: errors,
    });
    const iojData = this.state.iojData;
    iojData.WOStep = event.target.options[event.target.selectedIndex].text;
    iojData.WOMetricSOcatID = option;

    this.setState({
      iojData: iojData,
      WotileId: option,
    });
  }
  onWoMetricChanged(event) {
    let errors = {};
    var index = event.target.selectedIndex;
    var optionElement = event.target.childNodes[index];
    var option = optionElement.getAttribute("id");

    this.setState({
      errors: errors,
    });
    const iojData = this.state.iojData;
    iojData.VolumetricInfluence = event.target.options[event.target.selectedIndex].text;
    iojData.WOMetricKPIMappingID = option;
    this.setState({
      iojData: iojData,
    });
  }
  onHumanMetricChanged(event) {
    let errors = {};

    this.setState({
      errors: errors,
    });
    const iojData = this.state.iojData;
    iojData.RolesTransitioned = event.target.options[event.target.selectedIndex].text;
    this.setState({
      iojData: iojData,
    });
  }

  onImpactStartDateChanged(event) {
    let errors = {};

    this.setState({
      errors: errors,
    });
    const iojData = this.state.iojData;
    iojData.ImpactStartDate = event;
    this.setState({
      iojData: iojData,
    });
  }
  onImpactPeakDateChanged(event) {
    let errors = {};

    this.setState({
      errors: errors,
    });
    const iojData = this.state.iojData;
    iojData.ImpactPeakDate = event;
    this.setState({
      iojData: iojData,
    });
  }

  onFromChange(event) {
    const iojData = this.state.iojData;
    iojData.BOFrom = event.target.value;
    this.setState({
      iojData: iojData,
    });
  }
  onToChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const iojData = this.state.iojData;
    iojData.BOTo = event.target.value;
    this.setState({
      iojData: iojData,
    });
  }
  onStageNameChange(event) {
    const iojData = this.state.iojData;
    iojData.MaturityPhase = event.target.value;
    this.setState({
      iojData: iojData,
    });
  }
  onStageDescriptionChange(event) {
    const iojData = this.state.iojData;
    iojData.ProjectDescription = event.target.value;
    this.setState({
      iojData: iojData,
    });
  }
  onSOCategoryChange(event) {
    let errors = {};
    this.setState({
      errors: errors,
    });
    const iojData = this.state.iojData;
    iojData.SOCategoryID = event.target.value;
    var index = event.nativeEvent.target.selectedIndex;
    iojData.SOCategoryName = event.nativeEvent.target[index].text;
    //iojData.SOCategoryName = event.target[iojData.SOCategoryID].text;
    this.setState({
      iojData: iojData,
    });
  }

  handleSave(e) {
    if (this.handleValidation()) {
      const iojData = this.state.iojData;
      iojData.MaturityPhase = this.props.maturity;

      iojData.BOSeqNumber = iojData.BOSeqNumber == "" ? 0 : iojData.BOSeqNumber;
      iojData.InitiativeOutcomeOption = iojData.InitiativeOutcomeOption.toString();
      this.setState({
        iojData: iojData,
        isApiLoading: true,
      });

      iojData.OrgEntityID = this.props.filterDetails.Client;
      iojData.LocationID = this.props.filterDetails.Locations;
      iojData.OfferingID = this.props.filterDetails.process;
      iojData.PeriodDT = this.props.filterDetails.Month;
      axiosInstance
        .post(`${LocalApiBaseUrl}IOJJourneyInitiative/InsertUpdateIntelOpsData`, iojData)
        .then((response) => {
          if (response.data.IsSuccess) {
            axiosInstance
              .get(`${LocalApiBaseUrl}Configurator/GetIntelligentOperationsJourneyQualityScore`, {
                params: {
                  LocationID: this.props.filterDetails.Locations,
                  OrgEntityID: this.props.filterDetails.Client,
                  OfferingID: this.props.filterDetails.process,
                  OMID: this.props.filterDetails.LocationOMID.length === 0 || this.props.filterDetails.LocationOMID[0] === "All" ? 0  : this.props.filterDetails.LocationOMID[0],
                  LanguageID: store.getState().CurrentFilter.languageData.id,
                },
              })
              .then((response) => {
                if (response.data) {
                  store.dispatch({
                    type: "SAVE_QC_SCORE_OF_EACH_TAB",
                    payload: response.data,
                  });
                }
              })
              .catch((error) => {
                trycatchAlertPopup(error);
              });
            this.getJourneyInitiativeContainerData();
            let dataObj = response.data.IntelopsData;
            dataObj = {
              ...dataObj,
              InitiativeOutcomeOption_En: dataObj.InitiativeOutcomeOption,
              IntelOpsCategoryName_En: dataObj.IntelOpsCategoryName,
              MaturityPhase_En: dataObj.MaturityPhase,
              InitiativeStatus_En: dataObj.InitiativeStatus,
            };
            this.props.addEditInitiative(dataObj);
            this.showNotificationPopup(response.data.StatusMessage);
            //this.handleHide();
            // alert(response.data.StatusMessage);
            //alert(i18n.t(response.data.StatusMessage));
          }
          this.setState({
            isApiLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoading: false,
          });
        });
    }
  }

  handleHide(e) {
    if (this.props.iojCategoryID !== undefined) {
      this.props.onCloseParentShow(e);
    }

    if (e != undefined) {
      if (e.target.textContent != "×") {
        this.props.onClose(e);
      } else {
        this.props.onClose(e);
      }
    } else {
      this.props.onClose(e);
    }
  }

  getKpiMAppingDetails() {
    //this.getinitativeoutcome();
    let InitiativeOutcomeOptions = [];
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetStageInitativeoutcome`, {
        params: { LanguageID: store.getState().CurrentFilter.languageData.id },
      })
      .then((response) => {
        let iosdata = response.data.filter((row) => row.StageName_En === this.props.maturity);
        iosdata.map((row) => {
          if (row.name != "") {
            let dummy = [];
            dummy.label = row.InitiativeOutcome_En;
            dummy.value = row.InitiativeOutcome_En;
            InitiativeOutcomeOptions.push(dummy);
          }
        });
        this.setState({ InitiativeOutcomeOptions: InitiativeOutcomeOptions });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

    this.setState({ BOI: this.props.BoiList });

    //Getting all II metrics for SLA
    var data = store.getState();
    let iiDetails = data.intelligentInsightReducers["insightandintelligence"];

    let SocatAnalytics = [];
    let SocatProcess = [];
    iiDetails.map((el) => {
      if (el.SOCategoryName === "Analytics Apps & AI Advisors") {
        SocatAnalytics.push(el.SOCategoryID);
      }
    });
    iiDetails.map((el) => {
      if (el.SOCategoryName === "Other Process Insights") {
        SocatProcess.push(el.SOCategoryID);
      }
    });

    let IImetrics = [];
    if (iiDetails.length !== 0) {
      iiDetails.map((row) => {
        IImetrics.push(...row.kpiData);
      });
    }

    this.setState({ IImetrics: IImetrics });

    const iojData = this.state.iojData;

    iojData.ImpactPeakDate = new Date();
    iojData.ImpactStartDate = new Date();
    iojData.BOFrom = "NA";
    iojData.BOTo = "NA";
    // iojData.InitiativeOutcomeOptions=InitiativeOutcomeOptions;
    if (this.props.iojCategoryID !== undefined) {
      iojData.IntelOpsCategoryName = this.props.iojCategory;
      iojData.IntelOpsCategoryId = this.props.iojCategoryID;
    }
    this.setState({
      iojData: iojData,
    });

    //Adding WO details

    let WoSteps = [];
    let WoReducers = store.getState().WOtileReducers["tile"];

    if (WoReducers.length !== 0) {
      WoReducers.map((row) => {
        if (row.ParentSOCategoryID !== null && row.ParentSOCategoryID > 0) {
          WoSteps.push(row);
        }
      });
    }

    this.setState({ WOtiles: WoSteps }, () => {});
  }

  onStartDateChange = (event) => {
    this.setState({ StartDate: event });
  };
  filterOptions = (options, filter) => {
    //let special=['*','(',')']
    let arr = [];
    const sp = new RegExp("[*)()]");
    if (!filter) {
      return options;
    }

    return options.filter(({ value }) => value && value.toLowerCase().includes(filter.toLowerCase()));
  };
  onInitiativeOutcomeSelect(selectedList) {
    const iojData = this.state.iojData;
    let InitiativeOutcomeOptionlabval = this.state.InitiativeOutcomeOptionlabval;
    let tempProcess = [];
    let tempProcessValue = [];
    var len = selectedList.length;
    for (var i = 0; i < len; i++) {
      tempProcess.push(selectedList[i]);
      tempProcessValue.push(selectedList[i].value);
    }
    InitiativeOutcomeOptionlabval = tempProcess;
    iojData.InitiativeOutcomeOption = tempProcessValue;
    this.setState({ iojData: iojData, InitiativeOutcomeOptionlabval: InitiativeOutcomeOptionlabval });
  }

  showNotificationPopup = (message) => {
    this.handleHide();
    this.props.handleAddEditPopup(message);
  };
  render() {
    // Populating Wo metric based on WO steps
    let WoSteps = store.getState().WOtileReducers["tile"];
    let WoKpi = [];

    var WoMetrics = WoSteps.filter((row) => {
      if (row.SOCategoryID == this.state.WotileId) {
        return WoKpi.push(...row.kpiData);
      } else if (row.SOCategoryID == this.state.iojData.WOMetricSOcatID) {
        return WoKpi.push(...row.kpiData);
      }
    });

    //Populating Human/machine metric

    let HumanMachineReducer = store.getState().humanReducers["humanandmachine"];
    let HumanMachinekpi = [];
    var humankpi = HumanMachineReducer.map((row) => {
      if (row.SOCategoryName === "Human") {
        HumanMachinekpi.push(...row.kpiData);
      }
    });

    //Synops lever
    let cat = this.state.soCategories;
    let categories = [];

    if (
      this.state.iojData.ThroughputAndManpower === "Less Manpower" &&
      this.state.iojData.ImpactType !== "Direct" &&
      this.state.iojData.ImpactType !== "InDirect"
    ) {
      categories = cat.filter((data) => data.SOCategoryID === 2);
    } else if (
      this.state.iojData.ThroughputAndManpower === "Throughput Increase" &&
      this.state.iojData.ImpactType !== "Direct" &&
      this.state.iojData.ImpactType !== "InDirect"
    ) {
      categories = cat.filter((data) => data.SOCategoryID === 3);
    } else if (this.state.iojData.ImpactType === "NA") {
      categories = cat.filter((data) => data.SOCategoryID !== 1);
    } else {
      categories = cat.filter((data) => data.SOCategoryID !== 1 && data.SOCategoryID !== 2 && data.SOCategoryID !== 3);
    }
    let synopsLever = categories.map((x) => (
      <>
        <option key={x.SOCategoryID} selected={true} value={x.SOCategoryID}>
          {x.SOCategoryName}
        </option>
      </>
    ));

    let units = [];
    let fixed = this.state.iojData.BOImpacted == "Productivity" ? ["N.A", "$", "%", "FTE"] : ["N.A", "$"];
    let boi = [];
    boi.push(...this.state.BOI);

    boi.map((row) => {
      units.push(row.MetricUnit);
    });

    units.push(...fixed);

    let BoiUnit = [];
    if (this.state.EstimatedUnitState.length > 0) {
      if (this.state.iojData.BOImpacted != "Productivity") fixed.push(...this.state.EstimatedUnitState);
    } else if (this.state.iojData.EstimatedUnit !== "" || this.state.iojData.EstimatedUnit !== undefined) {
      fixed.push(this.state.iojData.EstimatedUnit);
    }

    let uniqueUnits = [];
    if (fixed != null) {
      uniqueUnits = fixed.filter(function(item, pos) {
        return fixed.indexOf(item) == pos;
      });
    }

    var filtered = uniqueUnits.filter(function(el) {
      return el !== "";
    });
    let filteredunits = [];
    filteredunits.push(...filtered);

    const InitiativeOutcomeMultipleselectbar = (
      <React.Fragment>
        <MultiSelect
          options={this.state.InitiativeOutcomeOptions}
          value={this.state.InitiativeOutcomeOptionlabval}
          onChange={this.onInitiativeOutcomeSelect.bind(this)}
          labelledBy={"Select"}
          shouldToggleOnHover={false}
          overrideStrings={overrideStrings}
          hasSelectAll={true} //{this.state.multiselect.off}
          filterOptions={this.filterOptions}
        />
      </React.Fragment>
    );
    return (
      <>
        {/* <Header /> */}
        <LoadingOverlay 
        active={this.state.isApiLoading} 
        spinner={<SynopsLoader />}
        // text="Please Wait..."
        >
          <Modal
            show={true}
            backdrop="static"
            onHide={this.handleHide}
            className="modal fade custom-intelligent"
            size="medium"
            id="dealpopup"
          >
            <Modal.Header closeButton>
              <h4 className="modal-title" id="dealprofile">
                {i18n.t("Intelligent_Operations_Journey")}
              </h4>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div className="form-section">
                  <h3>{i18n.t("Initiatives")}</h3>
                  <div className="border-box">
                    <div className="row">
                      {this.props.isIOJCategory && (
                        <div className="form-group col-lg-6">
                          <label for="">{i18n.t("Initiative_Type")}</label>

                          <select
                            className="form-control"
                            onChange={this.onInitiativeTypeChanged}
                            value={this.state.iojData.IntelOpsCategoryName}
                            disabled={this.props.editedit}
                          >
                            <option value=""></option>
                            {this.state.intelOpsDetails.map((row) => {
                              return (
                                <option id={row.IntelOpsCategoryId} value={row.IntelOpsCategoryName}>
                                  {row.IntelOpsCategoryName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                      <div className="form-group col-lg-6">
                        <label for="">
                          {i18n.t("Initiative_Name")}
                          <span className="required">*</span>
                        </label>
                        {!this.props.edit ? (
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            onChange={this.onNameChange}
                            maxlength="50"
                            value={this.state.iojData.ProjectInitiativeName}
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            onChange={this.onNameChange}
                            maxlength="50"
                            value={this.state.iojData.ProjectInitiativeName}
                            readOnly
                          />
                        )}
                        <div className="errorMsg">{this.state.errors.ProjectInitiativeName}</div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label for="">
                          {i18n.t("Initiative_Description")}
                          <span className="required">*</span>
                        </label>
                        <textarea
                          class="form-control"
                          rows={this.state.rowsCount}
                          name="text"
                          value={this.state.iojData.ProjectDescription}
                          onChange={this.onDescriptionChange}
                          maxlength="500"
                        ></textarea>
                        <div className="errorMsg">{this.state.errors.ProjectDescription}</div>
                      </div>
                      <div className="form-group col-lg-6">
                        <label for="">
                          {i18n.t("Sequence")} <span className="required">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          min="1"
                          id=""
                          onChange={this.onSequenceChange}
                          value={this.state.iojData.BOSeqNumber}
                          placeholder="0"
                        />
                        <div className="errorMsg">{this.state.errors.SeqRange}</div>
                      </div>
                    </div>
                  </div>

                  <div className="border-box">
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label for="">
                          {i18n.t("Impact_Type")}
                          <span className="required">*</span>
                        </label>
                        <select
                          className="form-control "
                          onChange={this.onImpactChange}
                          value={this.state.iojData.ImpactType}
                        >
                          <option value="Select" selected={true}>
                            {i18n.t("Select")}
                          </option>
                          <option value="Direct">{i18n.t("Direct")}</option>
                          <option value="Indirect">{i18n.t("Indirect")}</option>
                          <option value="NA">{i18n.t("NA")}</option>
                        </select>

                        <div className="errorMsg">{this.state.errors.ImpactType}</div>
                      </div>

                      {/* start of {Initiative Outcome} */}
                      <div className="form-group col-lg-6">
                        <label for="">
                          {i18n.t("Initiative_Outcome")}
                          <span className="required">*</span>
                        </label>
                        <div className="custom-multi-select">
                          {InitiativeOutcomeMultipleselectbar}
                          <span className="errorMsg">{this.state.errors.InitiativeOutcomeOption}</span>
                        </div>
                      </div>
                    </div>
                    {this.state.iojData.ImpactType === "NA" ? null : (
                      <div className="row">
                        <div className="form-group col-lg-6">
                          <label for="">
                            {i18n.t("Business_Outcome_Indicator")}

                            <span className="required">*</span>
                          </label>
                          {(this.state.iojData.ImpactType === "Direct" ||
                            this.state.iojData.ImpactType === "Indirect") &&
                          this.state.BOI ? (
                            <select
                              className="form-control"
                              onChange={this.onBOIChange}
                              value={this.state.iojData.BOImpacted}
                            >
                              <option value=""></option>
                              {this.state.iojData.ImpactType === "Direct" ? (
                                <option value="Productivity">{i18n.t("Productivity")}</option>
                              ) : null}

                              {this.state.BOI.map((row) => {
                                return (
                                  <option id={row.KPIMappingID} value={row.MetricName}>
                                    {row.MetricName}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <select className="form-control">
                              <option value=""></option>
                            </select>
                          )}
                          <div className="errorMsg">{this.state.errors.BOImpacted}</div>
                        </div>
                        <div className="form-group col-lg-6">
                          {this.state.iojData.ImpactType !== null &&
                          this.state.iojData.ImpactType === "Indirect" &&
                          this.state.iojData.ImpactType !== "Select" ? (
                            <>
                              <label for="">
                                {i18n.t("KPI/SLA Impacted")}
                                <span className="required">*</span>
                              </label>
                              <select
                                className="form-control"
                                disabled={false}
                                onChange={this.KPIImpactedChange}
                                value={this.state.iojData.KPIImpacted}
                              >
                                <option value=""></option>
                                {this.state.IImetrics.map((row) => {
                                  return (
                                    <option id={row.KPIMappingID} value={row.MetricName}>
                                      {row.MetricName}
                                    </option>
                                  );
                                })}
                              </select>
                              <div className="errorMsg">{this.state.errors.KPIImpacted}</div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label className="ioj-popup-margin" for="">
                          {i18n.t("Initiative_Phase")}
                          <span className="required">*</span>
                        </label>
                        <select
                          className="form-control"
                          value={this.state.iojData.InitiativeStatus}
                          onChange={this.onInitiativesStatusChange}
                        >
                          <option value=""></option>
                          <option value="Concept">{i18n.t("In-Concept")}</option>
                          <option value="InFlight">{i18n.t("In-Flight")}</option>
                          <option value="Delivered">{i18n.t("Delivered")}</option>
                          <option value="OnHold">{i18n.t("On-Hold")}</option>
                        </select>
                        <div className="errorMsg">{this.state.errors.InitiativeStatus}</div>
                      </div>
                      <div className="form-group col-lg-6">
                        <div className="row">
                          <div className="form-group col-lg-6">
                            {this.state.iojData.InitiativeStatus !== "Delivered" ? (
                              <label for="">
                                {i18n.t("Estimated_Value")}
                                {this.state.iojData.ImpactType === "NA" ? null : <span className="required">*</span>}
                              </label>
                            ) : (
                              <label for=""> {i18n.t("Estimated_Value")}</label>
                            )}

                            <input
                              type="text"
                              className="form-control"
                              maxlength="50"
                              id=""
                              onChange={this.onEstimatedValuesChange}
                              value={this.state.iojData.TargetValue}
                            />
                            <div className="errorMsg">{this.state.errors.TargetValue}</div>
                          </div>
                          <div className="form-group col-lg-6">
                            {this.state.iojData.InitiativeStatus !== "Delivered" ? (
                              <label for="" className="ioj-popup-margin">
                                {i18n.t("Unit")}
                              </label>
                            ) : (
                              <label className="ioj-popup-margin" for="">
                                {i18n.t("Unit")}
                              </label>
                            )}

                            {filteredunits.length > 0 && this.state.iojData.TargetValue !== undefined ? (
                              <select
                                className="form-control"
                                value={this.state.iojData.EstimatedUnit}
                                onChange={this.onEstimatedUnitChanged}
                                disabled={this.state.iojData.TargetValue.length === 0}
                              >
                                {filteredunits.map((row) => {
                                  return <option value={row}>{row}</option>;
                                })}
                              </select>
                            ) : (
                              <select
                                className="form-control"
                                value={this.state.iojData.EstimatedUnit}
                                onChange={this.onEstimatedUnitChanged}
                                disabled={this.state.iojData.TargetValue.length === 0}
                              >
                                <option value="N.A"> {i18n.t("N.A")}</option>
                                <option value="$">{i18n.t("$")}</option>
                              </select>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6 block-picker">
                        <label className="datepicker-label" for="">
                          {i18n.t("Impact_Start_Date")}
                          <span className="required"></span>
                        </label>

                        {this.state.iojData.InitiativeStatus === "Delivered" ? (
                          <DatePicker
                            selected={this.state.StartDate}
                            className="form-control"
                            autoComplete="Off"
                            id="ImpactStartDate"
                            maxDate={new Date()}
                            onChange={this.onImpactStartDateChanged.bind(this)}
                            value={Moment(this.state.iojData.ImpactStartDate).format("MM/YYYY")}
                          />
                        ) : (
                          <DatePicker
                            className="form-control"
                            selected={this.state.StartDate}
                            autoComplete="Off"
                            id="ImpactStartDate"
                            value={Moment(this.state.iojData.ImpactStartDate).format("MM/YYYY")}
                            minDate={new Date()}
                            onChange={this.onImpactStartDateChanged.bind(this)}
                          />
                        )}
                      </div>

                      <div className="form-group col-lg-6 block-picker">
                        <label className="datepicker-label" for="">
                          {i18n.t("Impact_Peak_Date")}
                          <span className="required"></span>
                        </label>

                        {this.state.iojData.InitiativeStatus === "Delivered" ? (
                          <DatePicker
                            className="form-control"
                            selected={this.state.StartDate}
                            autoComplete="Off"
                            id="ImpactPeakDate"
                            maxDate={new Date()}
                            onChange={this.onImpactPeakDateChanged.bind(this)}
                            value={Moment(this.state.iojData.ImpactPeakDate).format("MM/YYYY")}
                          />
                        ) : (
                          <DatePicker
                            className="form-control"
                            selected={this.state.StartDate}
                            autoComplete="Off"
                            id="ImpactPeakDate"
                            value={Moment(this.state.iojData.ImpactPeakDate).format("MM/YYYY")}
                            minDate={new Date()}
                            onChange={this.onImpactPeakDateChanged.bind(this)}
                          />
                        )}
                      </div>
                    </div>

                    {this.state.iojData.BOImpacted === "Productivity" && this.state.iojData.ImpactType === "Direct" ? (
                      <div className="row">
                        <div className="form-group col-lg-6">
                          <label for="">
                            {i18n.t("Throughput Increase / Less Manpower")}
                            <span className="required">*</span>
                          </label>
                          <select
                            className="form-control"
                            onChange={this.onThroughPutDdlChange}
                            value={this.state.iojData.ThroughputAndManpower}
                          >
                            <option value=""></option>
                            <option value="Less Manpower">{i18n.t("Less Manpower")}</option>
                            <option value="Throughput Increase">{i18n.t("Throughput Increase")}</option>
                          </select>
                          <div className="errorMsg">{this.state.errors.ThroughputAndManpower}</div>
                        </div>
                      </div>
                    ) : null}

                    <div className="row">
                      {this.state.iojData.ThroughputAndManpower === "Throughput Increase" &&
                      this.state.iojData.ImpactType === "Direct" ? (
                        <>
                          <div className="form-group col-lg-6">
                            <label for="">
                              {i18n.t("Wo Steps")} <span className="required">*</span>
                            </label>
                            {this.state.WOtiles.length > 0 ? (
                              <select
                                className="form-control"
                                value={this.state.iojData.WOStep}
                                onChange={this.onWotilesChanged}
                              >
                                <option value="Select">--{i18n.t("Select")}--</option>
                                {this.state.WOtiles.map((row) => {
                                  return (
                                    <option id={row.SOCategoryID} value={row.SOCategoryName}>
                                      {row.SOCategoryName}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : (
                              <select className="form-control">
                                <option value="Select">--{i18n.t("Select")}--</option>
                              </select>
                            )}
                            <div className="errorMsg">{this.state.errors.WOStep}</div>
                          </div>
                          <div className="form-group col-lg-6">
                            <label for="">
                              {i18n.t("Volumetric_Influence")}
                              <span className="required">*</span>{" "}
                            </label>
                            {this.state.WOtiles.length > 0 && WoKpi.length > 0 ? (
                              <select
                                className="form-control"
                                value={this.state.iojData.VolumetricInfluence}
                                onChange={this.onWoMetricChanged}
                                key={this.state.iojData.WOMetricSOcatID}
                              >
                                <option value="-1"></option>
                                {WoKpi.map((row) => {
                                  return (
                                    <option id={row.KPIMappingID} value={row.MetricName}>
                                      {row.MetricName}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : (
                              <select className="form-control">
                                <option value=""></option>
                              </select>
                            )}
                            <div className="errorMsg">{this.state.errors.VolumetricInfluence}</div>
                          </div>
                        </>
                      ) : (
                        <>
                          {this.state.iojData.ThroughputAndManpower === "Less Manpower" &&
                          this.state.iojData.ImpactType === "Direct" ? (
                            <div className="form-group col-lg-6">
                              <label for="">
                                {i18n.t("Roles_Transitioned")}
                                <span className="required">*</span>{" "}
                              </label>
                              {HumanMachinekpi.length > 0 ? (
                                <select
                                  className="form-control"
                                  value={this.state.iojData.RolesTransitioned}
                                  onChange={this.onHumanMetricChanged}
                                >
                                  <option value="Select">--{i18n.t("Select")}--</option>
                                  {HumanMachinekpi.map((row) => {
                                    return <option value={row.KpiMappingID}>{row.MetricName}</option>;
                                  })}
                                </select>
                              ) : (
                                <select className="form-control">
                                  <option value="Select">--{i18n.t("Select")}--</option>
                                </select>
                              )}
                              <div className="errorMsg">{this.state.errors.RolesTransitioned}</div>
                            </div>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="border-box">
                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label for="">{i18n.t("Initiative_Stage")}</label>
                        {/* <input type="text" className="form-control" maxLength="50" id="" onChange={this.onStageNameChange} /> */}
                        <input
                          type="text"
                          className="form-control"
                          maxLength="50"
                          id=""
                          value={this.props.maturity}
                          disabled={true}
                        />

                        {/* <div className="errorMsg">{this.state.errors.StageName}</div> */}
                      </div>
                      <div className="form-group col-lg-6">
                        <label for="">{i18n.t("Stage_Description")}</label>
                        <input
                          type="text"
                          className="form-control"
                          maxLength="50"
                          id=""
                          value={this.props.stageDesc}
                          disabled={true}
                        />
                        {/* <div className="errorMsg">{this.props.stageDesc} </div> */}
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label className="ioj-popup-margin" for="">
                          {i18n.t("SynOps_Lever")}
                          <span className="required">*</span>
                        </label>
                        {this.state.iojData.ThroughputAndManpower === "Less Manpower" ? (
                          <select
                            className="form-control"
                            value={this.state.iojData.SOCategoryID}
                            readonly={true}
                            onChange={this.onSOCategoryChange}
                          >
                            <option value="Select">{i18n.t("Select")}</option>
                            {synopsLever}
                          </select>
                        ) : (
                          <>
                            {this.state.iojData.ThroughputAndManpower === "Throughput Increase" ? (
                              <select
                                className="form-control"
                                value={this.state.iojData.SOCategoryID}
                                readonly={true}
                                onChange={this.onSOCategoryChange}
                              >
                                <option value="Select">{i18n.t("Select")}</option>
                                {synopsLever}
                              </select>
                            ) : (
                              <select
                                className="form-control"
                                value={this.state.iojData.SOCategoryID}
                                onChange={this.onSOCategoryChange}
                              >
                                <option value="" selected={true}>
                                  {i18n.t("Select")}
                                </option>
                                {synopsLever}
                              </select>
                            )}
                          </>
                        )}
                        {/* <select className="form-control" value={this.state.iojData.SOCategoryID} readonly={false} onChange={this.onSOCategoryChange}>
                                                   
                                                    {synopsLever}

                                                </select> */}
                        <div className="errorMsg">{this.state.errors.SOCategoryName}</div>
                      </div>
                      <div className="form-group col-lg-6">
                        {this.state.iojData.InitiativeStatus === "Delivered" ? (
                          <div className="row">
                            <div className="form-group col-lg-6">
                              <label for="">
                                {i18n.t("Value_Delivered")}
                                {this.state.iojData.ImpactType === "NA" ? null : <span className="required">*</span>}
                              </label>
                              <input
                                type="text"
                                pattern="[0-9]*"
                                maxLength="50"
                                className="form-control"
                                id=""
                                onChange={this.onValueDeliveredChange}
                                value={this.state.iojData.DeliveredValue}
                              />
                              <div className="errorMsg">{this.state.errors.DeliveredValue}</div>
                            </div>
                            <div className="form-group col-lg-6">
                              <label for="" className="ioj-popup-margin">
                                {i18n.t("Unit")}
                              </label>

                              {filteredunits.length > 0 && this.state.iojData.DeliveredValue !== undefined ? (
                                <select
                                  className="form-control"
                                  value={this.state.iojData.ValueUnit}
                                  onChange={this.onValueUnitChanged}
                                  disabled={this.state.iojData.DeliveredValue.length === 0}
                                >
                                  {filteredunits.map((row) => {
                                    return <option value={row}>{row}</option>;
                                  })}
                                </select>
                              ) : (
                                <select
                                  className="form-control"
                                  value={this.state.iojData.ValueUnit}
                                  onChange={this.onValueUnitChanged}
                                  disabled={this.state.iojData.DeliveredValue.length === 0}
                                >
                                  <option value="N.A">{i18n.t("N.A")}</option>
                                  <option value="$">{i18n.t("$")}</option>
                                </select>
                              )}
                            </div>{" "}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-lg-6">
                        <label for="">
                          {i18n.t("Status")} <span className="required">*</span>
                        </label>
                        {/* <div className="errorMsg">{this.state.errors.RAGStatus}</div> */}
                        <select
                          className="form-control"
                          value={this.state.iojData.RAGStatus}
                          onChange={this.onStatusChange}
                        >
                          <option value=""></option>
                          <option value="Grey">{i18n.t("Grey")}</option>
                          <option value="Green">{i18n.t("Green")}</option>
                          <option value="Red">{i18n.t("Red")}</option>
                          {/* <option value="Orange">Orange</option> */}
                          <option value="Amber">{i18n.t("Amber")}</option>
                        </select>
                        <div className="errorMsg">{this.state.errors.RAGStatus}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              {/* : <p>NO DATA / Not Selected Client,Offering and Location</p>} */}

              {/* : <p>Please Select Client,Offering and Location </p>} */}
            </Modal.Body>
            {
              <Modal.Footer>
                <div className="modal-btn">
                  {/* <input type="Button" className="btn btn-primary btn-sm" onClick={() => this.handleSave()} value="Save" /> */}
                  {!this.props.edit ? (
                    <input
                      type="Button"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.handleSave()}
                      value={i18n.t("Create")}
                    />
                  ) : (
                    <input
                      type="Button"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.handleSave()}
                      value={i18n.t("Update")}
                    />
                  )}
                </div>
              </Modal.Footer>
            }
          </Modal>
        </LoadingOverlay>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    IOJData: state.JourneyReducer.IOJData,
    languageData: state.CurrentFilter.languageData,
    currentFilter: state.CurrentFilter.SyncFilter,
    clientData: state.CurrentFilter.ClientData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addEditInitiative: (payload) => dispatch(AddEditInitiative(payload)),
  };
};

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(IOJInitiativePopup);

let conntedFunc = connect(
  mapStateToProps,
  mapDispatchToProps
);
export default compose(
  withRouter,
  conntedFunc
)(IOJInitiativePopup);
