import React from "react";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import jquery from "jquery";
import { store } from "../reducers/configureStore";
import { LocalApiBaseUrl } from "../Shared/Constant";
import axiosInstance from "../Shared/interceptor";
import {trycatchAlertPopup} from "../Shared/Constant";
import InsightsDataPopUp from "../SuperAdmin/InsightsDataPopUp";
window.$ = window.jQuery = jquery;

export default class InsightIntelligenceDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      viewmore: false,
      kpiData: {
        KPIMappingID: 0
      },
      SODetails: [],
      AllCategoryData: []
    };
  }

  dealpopup = (data, e) => {
    e.preventDefault();
    this.setState({
      viewmore: true,
      kpiData: data
    });
  };

  onClose = e => {
    this.setState({ viewmore: false });
  };

  deleteKpi(data, e) {
      if (window.confirm("Are you sure you want to delete metric detail?")) {

      data.SOCategoryName = this.props.SODetails.SOCategoryName;

      axiosInstance
        .post(
          `${LocalApiBaseUrl}Master/DeleteKpiMetricMasterTemplate?kpiMappingId=${data.KPIMappingID}`
        )
        .then(response => {
          window.alert(response.data.StatusMessage);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
      store.dispatch({
        type: "REMOVE_KPI_MASTER",
        payload: data
      });
    }
  }

  componentDidMount() {
    this.setState({
      SODetails: this.props.SODetails
    });
  }

  render() {
    const options = {
      loop: true,
      margin: 24,
      responsiveClass: "",
      dots: false,
      autoWidth:true,
      responsive: {
        0: {
          items: 1,
          nav: true
        },
        600: {
          items: 2,
          nav: false
        },
        1000: {
            items: 3,
            nav: true,
            loop: false
        },
        1200: {
          items: 3,
          nav: true,
          loop: false
      },
        1440: {
            items: 4,
            nav: true,
            loop: false
          },
          1600: {
              items: 4,
              nav: true,
              loop: false
          }
      }
    };
    return (
      <>
      <div className="custom-carousel-padding">
         <OwlCarousel options={options} className={`owl-carousel`}>
          {this.props.Details.map(eachDetails => {
            let AttrValue = "";
            let metricName = "";
            let unit = "";
            let metricData = "";
            let Description = "";
            let viewmore = false;
            let ToolTip = "";
            let ValueBlock = true;
            let attributeLabelsForIni = this.props.attributeLabels;
            let BenchmarkName=[];

             if(Object.keys(this.props.attributeLabels).length>0)
               BenchmarkName=[this.props.attributeLabels.AttributeName1,attributeLabelsForIni.AttributeName2];
              
           let BenchmarkValue =[eachDetails.Attribute1Value_En,eachDetails.Attribute2Value_En];
            metricName = eachDetails.MetricName;
            unit = eachDetails.MetricUnit;
            unit = unit.trim();
  
            let statusColor = "item gray-color";
            if (eachDetails != undefined) {
              ToolTip =
                "Lower Threshold:" +
                (eachDetails.LowerThreshold || "NA") +
                " , Upper Threshold:" +
                (eachDetails.UpperThreshold || "NA");

              metricData = eachDetails.MetricValue;

                  Description =
                  eachDetails.KPIDesp
                    ? eachDetails.KPIDesp
                    : ""; 
                  
                    if (eachDetails.RAGStatus != undefined && eachDetails.RAGStatus != null) {
                      switch (eachDetails.RAGStatus.toLowerCase()) {
                        case "grey":
                          statusColor = "item gray-color";
                          break;
                        case "red":
                          statusColor = "item red-color";
                          break;
                        case "orange":
                          statusColor = "item amber-color";
                          break;
                        case "green":
                          statusColor = "item green-color";
                          break;
                        case "amber":
                          statusColor = "item amber-color";
                          break;
                        default:
                        // code block
                      }
                    }
            }

            return (
              <div className={`${statusColor}`} data-place="bottom" data-tip={ToolTip}>
                
                      <div className="item-left">   
                      {ValueBlock ? (
                        <>
                          <p className="tile-metric-value">                            
                            {
                              (unit)                              
                            }
                        </p>
              </>
            ) : null}        
                        <p className="tile-metric-desc" data-tip data-for={'customTooltip' + eachDetails.KPIMappingID}>
                            {metricName}
                        </p>
                      </div>
                      <div className="item-right">
                          {Description ? (
                            <p className="tile-metric-name" data-tip data-for={'customTooltip_desc' + eachDetails.KPIMappingID}>
                              {Description}
                            </p>
                          ) : (
                            <p className="tile-metric-name">&nbsp;</p>
                          )}
                  </div>
                  {this.props.roledata != undefined &&
                  (this.props.roledata.includes("Edit") ||
                    this.props.roledata.includes("Delete")) ? (
                      <div className="dropdown dropleft">                        
                      <span type="button" className="dropdown-toggle" data-toggle="dropdown"></span>
                        <div className="dropdown-menu">
                        {this.props.roledata.includes("Delete") &&
                        <a
                          href="javascript:void(0)"
                          className="dropdown-item"
                          onClick={this.deleteKpi.bind(this, eachDetails)}
                        >
                          <span className="delete-icon"></span>Remove
                        </a>
                        }
                        {this.props.roledata.includes("Edit") &&
                        <a
                          href="javascript:void(0)"
                          className="dropdown-item"
                          onClick={this.dealpopup.bind(this, eachDetails)}
                        >
                          <span className="edit-icon"></span>Edit
                        </a>
                        }
                        </div>
                      </div>
                      ) : (
                    <>
                    </>
                  )}
                
              </div>
            );
          })}

      </OwlCarousel>
      </div>
        {this.state.viewmore ? (
          <InsightsDataPopUp
            type={this.props.Name}
            isRelatorBlockForOffering={this.props.isRelatorBlockForOffering}
            kpiData={this.state.kpiData}
            SODetails={this.state.SODetails}
            show={
              this.props.roledata != undefined &&
              this.props.roledata.includes("Edit")
            }
            onClose={this.onClose.bind(this)}
            opsgrp={this.props.opsgrp}
            filterDetails={this.props.filterDetails}
            attributeLabels={this.props.attributeLabels}
          />
        ) : null}
      </>
    );
  }
}
