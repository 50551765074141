import React from "react";

const RADIAN = Math.PI / 180;

const ParentView = (props,parentdata) => {

    const {
        cx,
        cy,
        midAngle,
        startAngle,
        endAngle,
        innerRadius,
        outerRadius,
        fill,
        index
      } = props;
    const radius = innerRadius + (outerRadius - innerRadius) * 1.4;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 0) * cos;
    const sy = cy + (outerRadius + 0) * sin;
    const mx = cx + (outerRadius + 0) * cos;
    const my = cy + (outerRadius + 0) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 30;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
      /*Dimnension*/
      <g>
         <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={parentdata[index].fill} fill="none" />
        <circle cx={ex} cy={ey} r={3} fill={parentdata[index].fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey}  textAnchor={textAnchor} fill="#212b36" fontSize={12} fontWeight="bold">{parentdata[index].StageName}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#212b36" fontSize={12} fontWeight="normal">
          {`Total Initiatives:${parentdata[index].Count}`}
        </text>
      </g>
    );
  };
export default ParentView;
