import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import axiosInstance from "../../Shared/interceptor";
import { trycatchAlertPopup } from "../../Shared/Constant";
import Workbook from "react-excel-workbook";
import _ from "lodash";
import moment from "moment";
import i18n from "i18next";
import "./HelpFeatures.css";
import { LocalApiBaseUrl } from "../../Shared/Constant";
import CloseIcon from "../../Images/close-icon.svg";
import EllipsesWhite from "../../Images/ellipses-white.svg";
import EllipsesGrey from "../../Images/ellipses-grey.svg";
import ViewBy from "../../Images/ViewBy.svg";
import FilterCategory from "../../Images/FilterCategory.svg";
import EmptyFeature from "../../Images/EmptyFeature.svg";
import HelpFeatureCardViewer from "./HelpFeatureCardViewer";
import helpfeature from "../../Images/Synops-logo-transparent.7f109c15.svg";
import ReferenceLink from "../../Images/EmptyFeatureList.svg";

import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../../Shared/SynopsLoader";
import { store } from "../../reducers/configureStore";
import HelpSectionMessageNotification from "./HelpSectionMessageNotification";

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;

//import  "./CaseStudy.css"

class HelpFeatures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addcaseStudyStatus: false,
      viewHelpFeatureStatus: false,
      isViewbyDropdownStatus: false,
      isCategoryFilterDropdownStatus: false,
      helpfeatureNotificationStatus: false,
      listGridLayoutStatus: false,
      gridLayoutStatus: true,
      selectedViewBy: "All",
      selectedCategoryFilter: [],
      tempSelectedCategoryFilter: [],
      cardList: [],
      tempCardList: [],
      viewBy: [],
      categories: [],
      viewHelpFeatureData: {},
      selectedCount: 0,
      selectAllStatus: false,
      selectedCategoryFilterDATA: [],
      tempSelectedCategoryFilterDATA: [],
      defaultAllCheck: true,
      tempdefaultAllCheck: true,
      disabledApplyBtn: false,
      categorySelectAll: true,
      mainSearchValue: "",
      UNALTEREDselectedCategoryFilterDATA: [],
      notificationMessage: "",
      isAPILoading: false,
      TabName: "",
      buttonDisable: false,
      cardIDList: [],
      excelData: [],
      featureData: [],
      editAccess: false,
      addAccess: false,
      deleteAccess: false,
      viewAccess: true,
      onSearchEmpty: false,
      viewByCount: 0,
      selectedCategoryIDs: [],
      appConfigList: [],
    };

    this.inputRef = React.createRef();
    this.getExcelData = this.getExcelData.bind(this);
    this.viewHelpFeatureModalShow = this.viewHelpFeatureModalShow.bind(this);
    this.viewHelpFeatureModalClose = this.viewHelpFeatureModalClose.bind(this);
    this.listGridLayout = this.listGridLayout.bind(this);
    this.gridLayout = this.gridLayout.bind(this);
  }
  addHelpFeatures = () => {
    const { history } = this.props;
    const location = {
      pathname: `/addfeature`,
    };
    history.push(location);
  };
  getRoleData() {
    var data = store.getState();
    let roleData1 = data.roleReducers["roleReducers"];
    this.setState({ featureData: roleData1 }, () => {

    });

    store.subscribe(() => {
      var data = store.getState();
      this.setState({ roleData: data.roleReducers["roleReducers"] });
      let roleData1 = data.roleReducers["roleReducers"];
      this.setState({ featureData: roleData1 }, () => {

      });
    });
  }
  handleEditFeature = (data) => {
    const { history } = this.props;
    const location = {
      pathname: `/addfeature`,
      state: { detail: data },
    };
    history.push(location);
  };
  handleMultiSelect = (index, Category = null) => {
    let data = this.state;
    //Combined View
    if (Category == null) {
      data.tempCardList[index].checked = !data.tempCardList[index].checked;
      data.selectedCount = [...data.tempCardList].filter((each) => each.checked == true).length;
      if (data.selectedCount == [...data.tempCardList].length) {
        data.selectAllStatus = true;
      } else {
        data.selectAllStatus = false;
      }
      this.setState({
        tempCardList: [...data.tempCardList],
        selectedCount: data.selectedCount,
        selectAllStatus: data.selectAllStatus,
        onSearchEmpty: false,
      });
    } else {
      //Category View
      let d = [...data.selectedCategoryFilterDATA].filter((each) => each.CategoryName == Category)[0].CategoryData;
      d[index].checked = !d[index].checked;
      data.selectedCategoryFilterDATA = [...data.selectedCategoryFilterDATA].map((each) => {
        if (each.CategoryName == Category) {
          each.CategoryData = d;
          return each;
        } else {
          return each;
        }
      });
      let allDATA = [...data.selectedCategoryFilterDATA].map((each) => each.CategoryData).flat(1);
      let selectedCount = allDATA.filter((each) => each.checked == true).length;
      let selectAllStatus = false;
      if (selectedCount == allDATA.length) {
        selectAllStatus = true;
      }

      this.setState({
        selectedCount: selectedCount,
        selectAllStatus: selectAllStatus,
        selectedCategoryFilterDATA: data.selectedCategoryFilterDATA,
        onSearchEmpty: false,
      });
    }
  };
  handleSelectALL = () => {
    let data = this.state;
    data.selectAllStatus = !data.selectAllStatus;
    const { selectedCategoryFilter, selectedCategoryFilterDATA, tempCardList } = this.state;
    let categoryData = [...selectedCategoryFilterDATA];
    let cardListData = [...tempCardList];
    if (data.selectAllStatus) {
      if (selectedCategoryFilter.length != 0) {
        categoryData = [...categoryData].map((each) => {
          each.CategoryData = [...each.CategoryData].map((d) => ({ ...d, checked: true }));
          return each;
        });
        let temp = [...categoryData].map((each) => each.CategoryData);
        temp = temp.flat(1);

        data.selectedCount = [...temp].filter((each) => each.checked == true).length;
      } else {
        cardListData = [...cardListData].map((each) => ({ ...each, checked: true }));
        data.selectedCount = [...cardListData].filter((each) => each.checked == true).length;
      }
    } else {
      if (selectedCategoryFilter.length != 0) {
        categoryData = [...categoryData].map((each) => {
          each.CategoryData = [...each.CategoryData].map((d) => ({ ...d, checked: false }));
          return each;
        });
        let temp = [...categoryData].map((each) => each.CategoryData);
        temp = temp.flat(1);

        data.selectedCount = [...temp].filter((each) => each.checked == true).length;
      } else {
        cardListData = [...cardListData].map((each) => ({ ...each, checked: false }));
        data.selectedCount = [...cardListData].filter((each) => each.checked == true).length;
      }
    }

    this.setState({
      tempCardList: cardListData,
      selectAllStatus: data.selectAllStatus,
      selectedCount: data.selectedCount,
      selectedCategoryFilterDATA: categoryData,
      onSearchEmpty: false,
    });
  };

  viewHelpFeatureModalShow(data) {
    this.setState({ viewHelpFeatureStatus: true, viewHelpFeatureData: data });
  }
  viewHelpFeatureModalClose() {
    this.setState({ viewHelpFeatureStatus: false, viewHelpFeatureModalClose: "" });
  }
  helpfeatureNotificationModalShow = (message) => {
    this.setState({ helpfeatureNotificationStatus: true, notificationMessage: message });
  };
  handleNotificationClose = () => {
    this.setState({ helpfeatureNotificationStatus: false, notificationMessage: "" });
  };
  isViewbyDropdown = () => {
    this.setState({ isViewbyDropdownStatus: true });
  };
  isViewbyDropdownOverlay = () => {
    this.setState({ isViewbyDropdownStatus: false });
  };
  onClickFetchViewby = (selectedData, event) => {
    const { selectedCategoryFilter, cardList } = this.state;
    let cardListtemp = [];
    cardListtemp =
      selectedData === "All" ? [...cardList] : [...cardList].filter((x) => x.DisplayCategory == selectedData);
    cardListtemp = cardListtemp.map((each) => ({ ...each, checked: false }));
    let alldata = [...cardListtemp].filter((each) => each.DisplayCategory == "All");
    alldata = alldata.sort((a, b) => a.TopicName.toLowerCase() > b.TopicName.toLowerCase() ? 1 : -1);
    let upcomingData = [...cardListtemp].filter((each) => each.DisplayCategory == "Upcoming");
    upcomingData = upcomingData.sort((a, b) => a.TopicName.toLowerCase() > b.TopicName.toLowerCase() ? 1 : -1);
    let newData = [...cardListtemp].filter((each) => each.DisplayCategory == "New");
    newData = newData.sort((a, b) => a.TopicName.toLowerCase() > b.TopicName.toLowerCase() ? 1 : -1);
    let result = [...newData, ...upcomingData, ...alldata];

    event.stopPropagation();
    this.setState(
      {
        selectedViewBy: selectedData,
        tempCardList: [...result],
        isViewbyDropdownStatus: false,
        mainSearchValue: "",
        selectedCount: 0,
        onSearchEmpty: false,
      },
      () => {
        this.fetchViewByCount();
        if (selectedCategoryFilter.length != 0) {
          this.handleCategoryApplyAgain();
        }
      }
    );
  };

  handleApply = (e) => {
    const { tempSelectedCategoryFilter, tempCardList, tempdefaultAllCheck } = this.state;
    let data = [];
    let selectedData = [];
    if (tempdefaultAllCheck) {
      data = [];
      selectedData = [];
    } else {
      [...tempSelectedCategoryFilter].map((each) => {
        let obj = {};
        obj.CategoryName = each.Name;
        obj.CategoryData = [...tempCardList]
          .filter((x) => x.SynopsFDSection.includes(each.Name))
          .map((i) => ({ ...i, checked: false }));
        data.push(obj);
      });
      data.map((i) => {
        i.CategoryData.map((item) => {
          item.helpSectionHighlights && item.helpSectionHighlights.map(e => {
            if (e.SynOpsFuncSectionName == i.CategoryName && e.isHighlight == true) {
              item.isNeworUpdated = true
            }
          })
        })
      })
      selectedData = [...tempSelectedCategoryFilter];
    }
    this.setState({
      selectedCategoryFilter: selectedData,
      selectedCategoryFilterDATA: [...data],
      UNALTEREDselectedCategoryFilterDATA: [...data],
      defaultAllCheck: tempdefaultAllCheck,
      
      mainSearchValue: "",
      selectedCount: 0,
      onSearchEmpty: false,
    }, () => {
      this.fetchViewByCount();
    });
    //this.isCategoryFilterDropdownOverlay();
    //e.stopPropagation();
  };

  gridLayout() {
    this.setState({ gridLayoutStatus: true, listGridLayoutStatus: false });
  }
  listGridLayout() {
    this.setState({ gridLayoutStatus: false, listGridLayoutStatus: true });
  }

  isCategoryFilterDropdown = () => {
    let { categories, selectedCategoryFilter, defaultAllCheck, UNALTEREDcategories } = this.state;

    if (selectedCategoryFilter.length == 0 && defaultAllCheck) {
      categories = [...UNALTEREDcategories].map((each) => ({ ...each, checked: true }));
    } else {
      let categoriestemp = [];
      [...UNALTEREDcategories].map((each) => {
        if ([...selectedCategoryFilter].filter((d) => d.ID == each.ID).length > 0) {
          each.checked = true;
        } else {
          each.checked = false;
        }
        categoriestemp.push(each);
      });
      categories = [...categoriestemp];
    }

    this.setState({
      isCategoryFilterDropdownStatus: true,
      categories,
      tempdefaultAllCheck: defaultAllCheck,
      categorySearch: "",
      onSearchEmpty: false,
    });
  };
  isCategoryFilterDropdownOverlay = () => {
    this.setState({ isCategoryFilterDropdownStatus: false, tempSelectedCategoryFilter: [] });
  };

  onChangeCategory = (index) => {
    let { tempSelectedCategoryFilter, categories, tempdefaultAllCheck, UNALTEREDcategories } = this.state;
    var selected = [];
    var selectedIds = [];
    categories[index].checked = !categories[index].checked;
    categories.map((each) => {
      if (each.checked) {
        selected.push(each);
        selectedIds.push(each.ID);
      }
    });
    tempSelectedCategoryFilter = selected;
    let disabled = false;
    let selectAllFlag = false;
    if (tempSelectedCategoryFilter.length == 0 && tempdefaultAllCheck == false) {
      disabled = true;
      
    }
    if (tempSelectedCategoryFilter.length == 0 ) {
      categories = categories.map((each) => ({ ...each, checked: false }));
    }
    if (UNALTEREDcategories.length == tempSelectedCategoryFilter.length ) {
      
      selectAllFlag = true;
    }
    this.setState(
      { tempSelectedCategoryFilter, categories, tempdefaultAllCheck: selectAllFlag, disabledApplyBtn: disabled, selectedCategoryIDs: selectedIds },
      () => {
        this.handleApply();
        this.sendTimeStamp();
      }
    );
  };

  onChangeAllCategory = () => {
    let { tempdefaultAllCheck, categories, tempCardList } = this.state;
    tempdefaultAllCheck = !tempdefaultAllCheck;
    let selectedIDs = [];
    if (tempdefaultAllCheck) {
      categories = categories.map((each) => ({ ...each, checked: true }));
      categories.map(each => {
        if (each.checked)
          selectedIDs.push(each.ID)
      });
      [...tempCardList].map(each => {
        if (each.helpSectionHighlights.length > 0) {
          each.isNeworUpdated = true
        }
      })
      this.setState({ tempdefaultAllCheck, categories, disabledApplyBtn: false, selectedCategoryIDs: selectedIDs, tempCardList }, () => {
        this.handleApply();
        this.sendTimeStamp();
      });
    } else {
      categories = categories.map((each) => ({ ...each, checked: false }));
      this.setState({ tempdefaultAllCheck, categories, disabledApplyBtn: true });
    }
  };

  fetchViewByCount = () => {
    const { selectedCategoryFilterDATA, selectedCategoryFilter, tempCardList, defaultAllCheck } = this.state;
    let viewByCount = 0;
    let request = {};
    if ([...selectedCategoryFilter].length != 0) {
      request.IDs = [];
      let temp = [];
      [...selectedCategoryFilterDATA].map((each) => {
        temp.push([...each.CategoryData].map((i) => i.ID));
      });
      temp = [...new Set([...temp].flat(1))];
      request.IDs = temp;
    } else {
      request.IDs = [];
      [...tempCardList].map((each) => {
        request.IDs.push(each.ID);
      });
    }
    viewByCount = request.IDs.length;
    this.setState({
      viewByCount,
    })

  }

  fetchInitialData = () => {
    return new Promise((resolve, reject) => {
      this.setState({ isAPILoading: true });
      let languageid = store.getState().CurrentFilter.languageData.id;
      axiosInstance
        .get(`${LocalApiBaseUrl}UserGuide/GetConfiguratorHelpTopicsData`, {
          params: {
            LanguageId: languageid,
          },
        })
        .then((response) => {
          let data = response.data.CardsData;
          data = data.map((each) => ({ ...each, checked: false }));
          let alldata = [...data].filter((each) => each.DisplayCategory == "All");
          alldata = alldata.sort((a, b) => a.TopicName.toLowerCase() > b.TopicName.toLowerCase() ? 1 : -1);
          let upcomingdata = [...data].filter((each) => each.DisplayCategory == "Upcoming");
          upcomingdata = upcomingdata.sort((a, b) => a.TopicName.toLowerCase() > b.TopicName.toLowerCase() ? 1 : -1);
          let newData = [...data].filter((each) => each.DisplayCategory == "New");
          newData = newData.sort((a, b) => (a.TopicName.toLowerCase() > b.TopicName.toLowerCase() ? 1 : -1));
          let result = [...newData, ...upcomingdata, ...alldata].map((each) => ({ ...each, isNeworUpdated: false }));


          let CategoryData = [];
          let selectedIDs = [];
          let selectedCategory = [];
          let data1 = [];
          let CategorySelected = [];
          CategoryData = response.data.Categories.sort((a, b) =>
            parseInt(a.SequenceForHelpSection) > parseInt(b.SequenceForHelpSection) ? 1 : -1
          ).map((each) => ({ ...each, checked: true }));

          if (this.props.location.state) {

            CategorySelected = this.state.appConfigList.filter((a) =>
              a.ConfigKey === this.props.location.state.name)[0].ConfigValue;

            CategoryData.map((each) => {
              if (each.Name === CategorySelected) {
                each.checked = true
                selectedIDs.push(each.ID)
              } else {
                each.checked = false
              }
            });

            if (selectedIDs.length != 0) {
              CategoryData.map((each) => {
                if (each.checked) {
                  selectedCategory.push(each);
                }
              });

              [...selectedCategory].map((each) => {
                let obj = {};
                obj.CategoryName = each.Name;
                obj.CategoryData = [...result]
                  .filter((x) => x.SynopsFDSection.includes(each.Name))
                  .map((i) => ({ ...i, checked: false }));
                data1.push(obj);
              });

              data1.map((i) => {
                i.CategoryData.map((item) => {
                  item.helpSectionHighlights && item.helpSectionHighlights.map(e => {
                    if (e.SynOpsFuncSectionName == i.CategoryName && e.isHighlight == true) {
                      item.isNeworUpdated = true
                    }
                  })
                })
              })

              this.setState({
                defaultAllCheck: false,
                selectedCategoryFilterDATA: [...data1],
                selectedCategoryFilter: selectedCategory,
              })
            }
            else {
              CategoryData.map(each => {
                each.checked = true;
                selectedIDs.push(each.ID)
              });
              [...result].map(each => {
                if (each.helpSectionHighlights.length > 0) {
                  each.isNeworUpdated = true
                }
              })
            }
          }
          else {
            CategoryData.map(each => {
              if (each.checked)
                selectedIDs.push(each.ID)
            });
            [...result].map(each => {
              if (each.helpSectionHighlights.length > 0) {
                each.isNeworUpdated = true
              }
            })
          }

          this.setState(
            {
              cardList: result,
              tempCardList: [...result],
              viewBy: response.data.ViewBy,
              categories: CategoryData,
              UNALTEREDcategories: response.data.Categories.sort((a, b) =>
                parseInt(a.SequenceForHelpSection) > parseInt(b.SequenceForHelpSection) ? 1 : -1
              ).map((each) => ({ ...each, checked: false })),
              isAPILoading: false,
              selectedCategoryIDs: selectedIDs,
            },
            () => {
              this.sendTimeStamp();
              let data = this.state.featureData;
              this.setState({
                editAccess:
                  data.filter((data) => data.FeatureName == "Help" && data.Operations.includes("Edit")).length !== 0
                    ? true
                    : false,
                deleteAccess:
                  data.filter((data) => data.FeatureName == "Help" && data.Operations.includes("Delete")).length !== 0
                    ? true
                    : false,
                viewAccess:
                  data.filter((data) => data.FeatureName == "Help" && data.Operations.includes("View")).length !== 0
                    ? true
                    : false,
                addAccess:
                  data.filter((data) => data.FeatureName == "Help" && data.Operations.includes("Add")).length !== 0
                    ? true
                    : false,
              });
              this.fetchViewByCount();
              resolve();
            }
          );
        })
        .catch((error) => {
          this.setState({ isAPILoading: false });
          trycatchAlertPopup(error);
          reject(error);
        });
    });
  };

  getAppConfig = () => {
    axiosInstance
      .get(`${LocalApiBaseUrl}Configurator/GetAppConfig`)
      .then((response) => {
        this.setState({
          appConfigList: response.data
        })
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  sendTimeStamp = () => {
    var data = {
      synFuncIDs: this.state.selectedCategoryIDs
    }
    axiosInstance
      .post(`${LocalApiBaseUrl}UserGuide/AddEditHelpTopicVisit`, data)
      .then((response) => {
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  componentDidMount() {
    this.getRoleData();
    this.getAppConfig();
    this.fetchInitialData();
    window.scrollTo(0, 0)
  }
  sortDataCategory = (data) => {
    let alldata = [...data].filter((each) => each.DisplayCategory == "All");
    alldata = alldata.sort((a, b) => a.TopicName.toLowerCase() > b.TopicName.toLowerCase() ? 1 : -1);
    let upcomingData = [...data].filter((each) => each.DisplayCategory == "Upcoming");
    upcomingData = upcomingData.sort((a, b) => a.TopicName.toLowerCase() > b.TopicName.toLowerCase() ? 1 : -1);
    let newData = [...data].filter((each) => each.DisplayCategory == "New");
    newData = newData.sort((a, b) => a.TopicName.toLowerCase() > b.TopicName.toLowerCase() ? 1 : -1);
    const result = [...newData, ...upcomingData, ...alldata];
    return result;
  }

  onSearchChange = (event) => {
    let value = event.target.value;
    value = value.trim().toLowerCase();
    const {
      selectedCategoryFilter,
      defaultAllCheck,
      UNALTEREDselectedCategoryFilterDATA,
      selectedCategoryFilterDATA,
    } = this.state;
    let dataTemp = [...selectedCategoryFilterDATA];
    if (selectedCategoryFilter.length != 0 && defaultAllCheck == false) {
      let tempData = [];
      if (value != "") {
        for (let i = 0; i < dataTemp.length; i++) {
          if (dataTemp[i].CategoryData.filter((d) => d.TopicName.toLowerCase().includes(value)).length > 0) {
            let obj = {};
            obj = { ...dataTemp[i] };
            obj.CategoryData = [...obj.CategoryData.filter((d) => d.TopicName.toLowerCase().includes(value))];
            tempData.push(obj);
          }
        }
        this.setState(
          {
            selectedCategoryFilterDATA: tempData,
            mainSearchValue: event.target.value,
          },
          () => {
            this.fetchViewByCount();
          }
        );
      } else {
        let tempData2 = [...UNALTEREDselectedCategoryFilterDATA];
        this.setState(
          {
            selectedCategoryFilterDATA: tempData2,
            mainSearchValue: event.target.value,
          },
          () => {
            this.handleCategoryApplyAgain();
            this.fetchViewByCount();
          }
        );
      }
    } else {
      let data = [];
      if (value != "") {
        data = this.state.tempCardList.filter((each) => each.TopicName.toLowerCase().includes(value));
      } else {
        if (this.state.selectedViewBy == "All") {
          data = this.state.cardList.map((each) => ({
            ...each,
            checked: false,
          }));
        } else {
          data = this.state.cardList
            .filter((each) => each.DisplayCategory == this.state.selectedViewBy)
            .map((each) => ({
              ...each,
              checked: false,
            }));
        }
      }
      let onSearchEmpty = false;
      onSearchEmpty = data.length == 0 ? true : false;
      data = this.sortDataCategory(data)
      this.setState({
        tempCardList: data,
        mainSearchValue: event.target.value,
        onSearchEmpty,
      }, () => {
        this.fetchViewByCount();
      });
    }
  };
  handleCategorySearch = (event) => {
    let value = event.target.value;
    value = value.toLowerCase();
    let data = [];
    let {
      categories,
      UNALTEREDcategories,
      tempdefaultAllCheck,
      disabledApplyBtn,
      tempSelectedCategoryFilter,
    } = this.state;

    if (value != "") {
      data = categories.filter((each) => each.Name.toLowerCase().includes(value));
    } else {
      data = UNALTEREDcategories;
      if (tempdefaultAllCheck) {
        data = data.map((each) => ({ ...each, checked: true }));
      } else {
        data = [...data].map((each) => ({ ...each, checked: false }));
      }
    }
    if (!tempdefaultAllCheck) {
      disabledApplyBtn = true;
    } else {
      disabledApplyBtn = false;
    }

    this.setState({
      categories: data,
      categorySearch: event.target.value,
      disabledApplyBtn,
      tempSelectedCategoryFilter: [],
    });
  };
  handleSingleMoveState = (Category, data) => {
    let request = [];
    let obj = {};
    obj.ID = data.ID;
    obj.DisplayCategory = Category;
    request.push(obj);
    this.setState({ isAPILoading: true });
    axiosInstance.post(`${LocalApiBaseUrl}UserGuide/EditConfiguratorHelpTopicsState`, request).then((response) => {
      if (response.data.IsSuccess) {
        let message = `Feature ${data.TopicName} moved to ${Category} successfully`;
        this.helpfeatureNotificationModalShow(message);
        this.handleMoveFeatureCardMain(request);
        this.handleViewByChangeAgain();
        this.setState({ isAPILoading: false });
      }
    })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };
  handleMoveFeatureCardMain = (request) => {
    request.map((each) => {
      return new Promise((resolve, reject) => {
        this.handleMoveFeatureCardList(each);
        resolve();
      });
    });
  };

  handleMoveFeatureCardList = (data) => {
    let { cardList } = this.state;
    return new Promise((resolve, reject) => {
      let cardListTemp2 = [...cardList];
      cardListTemp2 = cardListTemp2.map((each) => {
        if (each.ID == data.ID) {
          each.DisplayCategory = data.DisplayCategory;
        }
        return each;
      });
      this.setState(
        {
          cardList: [...cardListTemp2].map((each) => ({ ...each, checked: false })),
          selectedCount: 0,
          mainSearchValue: "",
        },
        () => {
          if (this.state.selectedCategoryFilter.length != 0) {
            this.handleCategoryApplyAgain();
          } else {
            this.handleViewByChangeAgain();
          }
          resolve();
        }
      );
    });
  };

  handleCategoryApplyAgain = () => {
    return new Promise((resolve, reject) => {
      const { selectedViewBy, selectedCategoryFilter } = this.state;
      let newtempcardList =
        selectedViewBy === "All"
          ? this.state.cardList
          : this.state.cardList.filter((x) => x.DisplayCategory == selectedViewBy);

      let Data = [];
      selectedCategoryFilter.map((each) => {
        let obj = {};
        obj.CategoryName = each.Name;
        let alldata = [...newtempcardList].filter((each) => each.DisplayCategory == "All");
        alldata = alldata.sort((a, b) => a.TopicName.toLowerCase() > b.TopicName.toLowerCase() ? 1 : -1);
        let upcomingData = [...newtempcardList].filter((each) => each.DisplayCategory == "Upcoming");
        upcomingData = upcomingData.sort((a, b) => a.TopicName.toLowerCase() > b.TopicName.toLowerCase() ? 1 : -1);
        let newData = [...newtempcardList].filter((each) => each.DisplayCategory == "New");
        newData = newData.sort((a, b) => a.TopicName.toLowerCase() > b.TopicName.toLowerCase() ? 1 : -1);
        let result = [...newData, ...upcomingData, ...alldata];
        obj.CategoryData = [...result].filter((x) => x.SynopsFDSection.includes(each.Name));
        Data.push(obj);
      });
      this.setState(
        {
          selectedCategoryFilterDATA: Data,
          onSearchEmpty: false,
        },
        () => {
          this.fetchViewByCount();
          resolve();
        }
      );
    });
  };

  handleViewByChangeAgain = () => {
    const { selectedViewBy } = this.state;
    return new Promise((resolve, reject) => {
      let cardList = [];
      cardList =
        selectedViewBy === "All"
          ? this.state.cardList
          : this.state.cardList.filter((x) => x.DisplayCategory == selectedViewBy);
      cardList = cardList.map((each) => ({ ...each, checked: false }));
      let alldata = [...cardList].filter((each) => each.DisplayCategory == "All");
      alldata = alldata.sort((a, b) => a.TopicName.toLowerCase() > b.TopicName.toLowerCase() ? 1 : -1);
      let upcomingData = [...cardList].filter((each) => each.DisplayCategory == "Upcoming");
      upcomingData = upcomingData.sort((a, b) => a.TopicName.toLowerCase() > b.TopicName.toLowerCase() ? 1 : -1);
      let newData = [...cardList].filter((each) => each.DisplayCategory == "New");
      newData = newData.sort((a, b) => a.TopicName.toLowerCase() > b.TopicName.toLowerCase() ? 1 : -1);
      let result = [...newData, ...upcomingData, ...alldata];

      this.setState(
        {
          tempCardList: result,
          selectedCount: 0,
          mainSearchValue: "",
          onSearchEmpty: false,
        },
        () => {
          this.fetchViewByCount();
          resolve();
        }
      );
    });
  };
  handleMultiMoveState = (Category) => {
    let { selectedCategoryFilter, tempCardList, cardList, selectedCategoryFilterDATA } = this.state;
    // Preparing Request Start
    let request = [];
    if (selectedCategoryFilter.length != 0) {
      let temp = [];
      [...selectedCategoryFilterDATA].map((each) => {
        let x = [...each.CategoryData].filter((d) => d.checked == true);
        temp.push(x);
      });
      temp = [...temp].flat(1).map((d) => d.ID);
      temp = [...new Set(temp)];
      temp.map((each) => {
        let obj = {};
        obj.ID = each;
        obj.DisplayCategory = Category;
        request.push(obj);
      });
    } else {
      const { tempCardList } = this.state;
      let data = [...tempCardList];
      data = [...data].filter((each) => each.checked == true);
      data.map((each) => {
        let obj = {};
        obj.ID = each.ID;
        obj.DisplayCategory = Category;
        request.push(obj);
      });
    }
    // Preparing Request End

    axiosInstance.post(`${LocalApiBaseUrl}UserGuide/EditConfiguratorHelpTopicsState`, request).then((response) => {
      if (response.data.IsSuccess) {
        let message = `Features moved to ${Category} successfully`;
        this.helpfeatureNotificationModalShow(message);
        this.handleMultiMoveCardList(request);
      }
    })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  handleMultiMoveCardList = (request) => {
    new Promise((resolve, reject) => {
      const { cardList } = this.state;
      let newCardList = [...cardList];
      newCardList = [...newCardList].map((each) => {
        if (request.filter((d) => d.ID == each.ID).length > 0) {
          each.DisplayCategory = [...request].filter((d) => d.ID == each.ID)[0].DisplayCategory;
        }
        return each;
      });
      newCardList = [...newCardList].map((each) => ({ ...each, checked: false }));
      this.setState(
        {
          cardList: newCardList,
          selectedCount: 0,
          mainSearchValue: "",
          onSearchEmpty: false,
        },
        () => {
          if (this.state.selectedCategoryFilter.length != 0) {
            this.handleCategoryApplyAgain();
          } else {
            this.handleViewByChangeAgain();
          }
          resolve();
        }
      );
    });
  };
  handleSingleDelete = (data) => {
    if (window.confirm("Are you sure you want to delete this feature")) {
      let request = {};
      request.IDs = [data.ID];

      axiosInstance.post(`${LocalApiBaseUrl}UserGuide/DeleteTopicCard`, request).then((response) => {
        if (response.data.IsSuccess) {
          let message = `Feature ${data.TopicName} deleted successfully`;
          this.helpfeatureNotificationModalShow(message);
          this.handleDataRemovedCardList(data);
        }
      })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };
  handleMultiDelete = () => {
    let { selectedCategoryFilter, tempCardList, cardList, selectedCategoryFilterDATA } = this.state;
    if (window.confirm("Are you sure you want to delete these features")) {
      let request = {};
      this.setState({ isAPILoading: true });
      if (selectedCategoryFilter.length != 0) {
        request.IDs = [];
        let temp = [];
        [...selectedCategoryFilterDATA].map((each) => {
          temp.push([...each.CategoryData].filter((d) => d.checked == true).map((i) => i.ID));
        });
        temp = [...new Set([...temp].flat(1))];
        request.IDs = temp;
      } else {
        request.IDs = [];
        [...tempCardList].map((each) => {
          if (each.checked) {
            request.IDs.push(each.ID);
          }
        });
      }

      axiosInstance.post(`${LocalApiBaseUrl}UserGuide/DeleteTopicCard`, request).then((response) => {
        if (response.data.IsSuccess) {
          this.setState({ isAPILoading: false });
          let message = `Features deleted successfully`;
          this.helpfeatureNotificationModalShow(message);
          this.handleMultiDeleteCardList(request.IDs);
        }
      })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };

  handleMultiDeleteCardList = (request) => {
    const { cardList, selectedCategoryFilter } = this.state;
    let newCardList = [...cardList];
    let temp = [];
    newCardList = newCardList.map((each) => {
      if (request.filter((d) => d == each.ID).length == 0) {
        temp.push(each);
      }
    });
    newCardList = [...temp];
    this.setState(
      {
        cardList: newCardList,
        selectedCount: 0,
        mainSearchValue: "",
        onSearchEmpty: false,
      },
      () => {
        if (selectedCategoryFilter.length != 0) {
          this.handleCategoryApplyAgain();
        } else {
          this.handleViewByChangeAgain();
        }
      }
    );
  };

  handleDataRemovedCardList = (data) => {
    const { cardList, selectedCategoryFilter } = this.state;
    let newCardList = [...cardList];
    newCardList = [...newCardList].filter((each) => each.ID != data.ID);

    this.setState(
      {
        cardList: newCardList,
        selectedCount: 0,
        mainSearchValue: "",
        onSearchEmpty: false,
      },
      () => {
        if (selectedCategoryFilter.length != 0) {
          this.handleCategoryApplyAgain();
        } else {
          this.handleViewByChangeAgain();
        }
      }
    );
  };
  getExcelData = () => {
    const { selectedCount, selectedCategoryFilter, selectedCategoryFilterDATA, tempCardList } = this.state;
    this.setState({
      buttonDisable: true,
    });
    if (selectedCount == 0) {
      // Preparing Request of Cards Available on Page
      let request = {};
      if ([...selectedCategoryFilter].length != 0) {
        request.IDs = [];
        let temp = [];
        [...selectedCategoryFilterDATA].map((each) => {
          temp.push([...each.CategoryData].map((i) => i.ID));
        });
        temp = [...new Set([...temp].flat(1))];
        request.IDs = temp;
      } else {
        request.IDs = [];
        [...tempCardList].map((each) => {
          request.IDs.push(each.ID);
        });
      }

      axiosInstance.post(`${LocalApiBaseUrl}UserGuide/GetExcelDataSelectedFeatureCards`, request).then((response) => {
        let data = response.data;
        let Data = [];
        if (data.Data == null) {
          let defObj = {
            ["Feature Name"]: "",
            Description: "",
            ["View By Section"]: "",
            ["SynOps Frontdoor Section"]: "",
            Beneficiary: "",
            Benefits: "",
            ["Userguide link"]: "",
            ["Video link"]: "",
          };
          Data.push(defObj);
        } else {
          let temp = [...data.Data];
          temp = temp.map(each => {
            let obj = {};
            obj["Feature Name"] = each.FeatureName;
            obj["Description"] = each.Description;
            obj["View By Section"] = each.ViewBySection;
            obj["SynOps Frontdoor Section"] = each.SynOpsFrontdoorSection;
            obj["Beneficiary"] = each.Beneficiary;
            obj["Benefits"] = each.Benefits;
            obj["Userguide link"] = each.UserguideLink;
            obj["Video link"] = each.VideoLink;
            return obj;
          })
          Data = temp;

        }
        this.setState(
          {
            excelData: Data,
            TabName: data.TabName,
            onSearchEmpty: false,
          },
          () => {
            this.inputRef.current.click();
            this.setState({
              buttonDisable: false,
              selectedCount: 0,
            });
          }
        );
      })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    } else {
      // Preparing Request of Selected Cards Start
      let request = {};
      if (selectedCategoryFilter.length != 0) {
        request.IDs = [];
        let temp = [];
        [...selectedCategoryFilterDATA].map((each) => {
          temp.push([...each.CategoryData].filter((d) => d.checked == true).map((i) => i.ID));
        });
        temp = [...new Set([...temp].flat(1))];
        request.IDs = temp;
      } else {
        request.IDs = [];
        [...tempCardList].map((each) => {
          if (each.checked) {
            request.IDs.push(each.ID);
          }
        });
      }
      // Preparing Request End
      axiosInstance.post(`${LocalApiBaseUrl}UserGuide/GetExcelDataSelectedFeatureCards`, request).then((response) => {
        let data = response.data;
        let Data = [];
        if (data.Data == null) {
          let defObj = {
            ["Feature Name"]: "",
            Description: "",
            ["View By Section"]: "",
            ["SynOps Frontdoor Section"]: "",
            Beneficiary: "",
            Benefits: "",
            ["Userguide link"]: "",
            ["Video link"]: "",
          };
          Data.push(defObj);
        } else {
          let temp = [...data.Data];
          temp = temp.map(each => {
            let obj = {};
            obj["Feature Name"] = each.FeatureName;
            obj["Description"] = each.Description;
            obj["View By Section"] = each.ViewBySection;
            obj["SynOps Frontdoor Section"] = each.SynOpsFrontdoorSection;
            obj["Beneficiary"] = each.Beneficiary;
            obj["Benefits"] = each.Benefits;
            obj["Userguide link"] = each.UserguideLink;
            obj["Video link"] = each.VideoLink;
            return obj;
          })
          Data = temp;
        }
        this.setState(
          {
            excelData: Data,
            TabName: data.TabName,
            onSearchEmpty: false,
          },
          () => {
            this.inputRef.current.click();
            this.setState({
              buttonDisable: false,
              selectedCount: 0,
            });
            if (selectedCategoryFilter.length != 0) {
              this.handleCategoryApplyAgain();
            } else {
              this.handleViewByChangeAgain();
            }
          }
        );
      })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };

  render() {
    const {
      viewHelpFeatureData,
      selectedCount,
      selectAllStatus,
      mainSearchValue,
      selectedCategoryFilterDATA,
      selectedViewBy,
      
      categorySearch,
      defaultAllCheck,
      notificationMessage,
      isAPILoading,
      TabName,
      excelData,
      buttonDisable,
      editAccess,
      addAccess,
      deleteAccess,
      viewAccess,
      onSearchEmpty,
      viewByCount,
    } = this.state;

    let currentdate = new Date();
    let formatDate = moment(currentdate).format("YYYYMMDD");
    let formatTime = moment(currentdate).format("HHmmss");
    let formatSheetName = `HelpSection_${formatDate}_${formatTime}`;
    return (
      <>
        {viewAccess || editAccess || deleteAccess || addAccess ? (
          <div className="helpfeatures-section">
            <div className="container-fluid">
              <LoadingOverlay
                className="custom-loader"
                fadeSpeed={0}
                spinner={<SynopsLoader />}
                active={isAPILoading}
              // text="Loading SynOps …"
              ></LoadingOverlay>
              <div className="row">
                <div className="col-lg-12">

                  <div className="helpfeatures-heading">
                    <h5>{i18n.t("Features")}</h5>
                    <div className="helpfeature-addclose">
                      {this.state.featureData.filter(
                        (data) => data.FeatureName == "Help" && data.Operations.includes("Add")
                      ).length !== 0 && (
                          <div className="addhelpfeature-btn mr-3">
                            <button type="button" className="btn btn-doublegradient" onClick={this.addHelpFeatures}>
                              {i18n.t("Add_Feature")}
                            </button>
                          </div>
                        )}
                      <div className="helpfeatures-close">
                        <NavLink to="/admin/Configurator" className="helpfeaturesclose-icon">
                          <img src={CloseIcon} width="16px" height="16px" />
                        </NavLink>
                      </div>
                    </div>
                  </div>

                  <div className="helpfeature-filters">
                    <div className="leftfeature-filters">
                      <div
                        className={selectedCount > 0 ? "viewby-filter disabled" : "viewby-filter"}
                        onClick={this.isViewbyDropdown}
                      >
                        <span className="viewby-label">
                          <img src={ViewBy} title="EyeIcon" />
                          <span>{i18n.t("View_By")}</span>
                        </span>
                        <div className="viewby-filtervalue">
                          <span>
                            {this.state.selectedViewBy}(
                            {/*  {selectedViewBy == "All"
                              ? this.state.cardList.length
                              : this.state.cardList.filter((d) => d.DisplayCategory == selectedViewBy).length} */}
                            {viewByCount}
                            )<i class="fas fa-chevron-down"></i>
                          </span>
                          {this.state.isViewbyDropdownStatus ? (
                            <div className="viewby-dropdownlist">
                              <ul>
                                {this.state.viewBy.map((i) => {
                                  return (
                                    <li
                                      onClick={(e) => {
                                        this.onClickFetchViewby(i, e);
                                      }}
                                    >
                                      {i}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {this.state.isViewbyDropdownStatus ? (
                        <div className="viewby-dropdown-overlay" onClick={this.isViewbyDropdownOverlay}></div>
                      ) : (
                        ""
                      )}
                      <div
                        className={
                          selectedCount > 0 || this.state.tempCardList.length == 0
                            ? "categoryfeature-filter disabled"
                            : "categoryfeature-filter"
                        }
                      >
                        {defaultAllCheck && <img src={FilterCategory} title="Filter Category" />}
                        <span className="category-dropdown" onClick={this.isCategoryFilterDropdown}>
                          {defaultAllCheck ? null : <span> {this.state.selectedCategoryFilter.length} </span>}
                          {i18n.t("Category")} <i class="fas fa-chevron-down"></i>
                        </span>
                        {this.state.isCategoryFilterDropdownStatus ? (
                          <div className="categoryfilter-dropdownlist">
                            <div className="searchFilter">
                              <input
                                type="search"
                                placeholder="Search ..."
                                onChange={(e) => {
                                  this.handleCategorySearch(e);
                                }}
                                value={categorySearch}
                              />
                            </div>
                            <ul>
                              <li>
                                <label className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="allCategory"
                                    onChange={() => {
                                      this.onChangeAllCategory();
                                    }}
                                    value="All"
                                    checked={this.state.tempdefaultAllCheck}
                                  />
                                  <span className="checkmark"></span>
                                  <span className="form-check-label">{i18n.t("Select_All")}</span>
                                </label>
                              </li>
                              {this.state.categories.map((i, index) => {
                                return (
                                  <li>
                                    <label className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="categoryBy"
                                        onChange={(e) => this.onChangeCategory(index)}
                                        value={i.Name}
                                        checked={i.checked}
                                      />
                                      <span className="checkmark"></span>
                                      <span className="form-check-label">{i.Name}</span>
                                    </label>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {this.state.isCategoryFilterDropdownStatus ? (
                        <div className="viewby-dropdown-overlay" onClick={this.isCategoryFilterDropdownOverlay}></div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="rightfeature-filters">
                      {this.state.cardList.filter(each => each.DisplayCategory == selectedViewBy).length != 0 &&
                        <div className="searchFilter">
                          <input
                            type="search"
                            placeholder="Search ..."
                            value={mainSearchValue}
                            onChange={(e) => {
                              this.onSearchChange(e);
                            }}
                            disabled={selectedCount > 0 ? true : false}
                          />
                        </div>
                      }

                      {/* Download Feature Button Condition Start*/}
                      {/* <div className="featurelist-btn">
                                                <span><svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="https://www.w3.org/2000/svg" xmlns="https://www.w3.org/1999/xlink">
                                                    <title>Download</title>
                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <g id="01-Admin-Feature-1.21" transform="translate(-1197.000000, -197.000000)" fill="#A100FF">
                                                            <g id="01-Buttons/01-Main/Small/Purple-01/04-Icon,-Text-&amp;-Arrow/02-Default-Copy" transform="translate(1181.000000, 189.000000)">
                                                                <g id="Left-Icon" transform="translate(16.000000, 8.000000)">
                                                                    <path d="M14.6666667,8.88888887 L12.1083333,8.88888887 L13.3888889,7.60833333 C14.225,6.77222222 13.6333333,5.33333333 12.4472222,5.33333333 L10.6694445,5.33333333 L10.6694445,2.22222221 C10.6694445,1.48611109 10.0722222,0.888888871 9.33611111,0.888888871 L6.66944444,0.888888871 C5.93333333,0.888888871 5.3361111,1.48611109 5.3361111,2.22222221 L5.3361111,5.33333333 L3.55833332,5.33333333 C2.37499999,5.33333333 1.77499998,6.76944444 2.61666665,7.60833333 L3.89722221,8.88888887 L1.33333332,8.88888887 C0.597222204,8.88888887 -2.00000002e-08,9.48611111 -2.00000002e-08,10.2222222 L-2.00000002e-08,13.7777778 C-2.00000002e-08,14.5138889 0.597222204,15.1111111 1.33333332,15.1111111 L14.6666667,15.1111111 C15.4027778,15.1111111 16,14.5138889 16,13.7777778 L16,10.2222222 C16,9.48611111 15.4027778,8.88888887 14.6666667,8.88888887 Z M3.55555554,6.66666666 L6.66666666,6.66666666 L6.66666666,2.22222221 L9.33333334,2.22222221 L9.33333334,6.66666666 L12.4444445,6.66666666 L8,11.1111111 L3.55555554,6.66666666 Z M14.6666667,13.7777778 L1.33333332,13.7777778 L1.33333332,10.2222222 L5.22499999,10.2222222 L7.05555555,12.0527778 C7.57777778,12.575 8.41944445,12.5722222 8.94166667,12.0527778 L10.7722222,10.2222222 L14.6666667,10.2222222 L14.6666667,13.7777778 Z M12.2222222,12 C12.2222222,11.6305556 12.5194445,11.3333333 12.8888889,11.3333333 C13.2583333,11.3333333 13.5555556,11.6305556 13.5555556,12 C13.5555556,12.3694445 13.2583333,12.6666667 12.8888889,12.6666667 C12.5194445,12.6666667 12.2222222,12.3694445 12.2222222,12 Z" id="Worfklow,-Status-&amp;-UI/02-Regular/download"></path>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg> Download Feature List</span>
                                            </div> */}
                      {/* Download Feature Button Condition End*/}
                      {/* <HelpFeatureCsvExport /> */}
                      <div className="download-dropdown dropdown">
                        <a
                          className={!buttonDisable ? "download-dropdownbtn" : "download-dropdownbtn disabled"}
                          href="#"
                          onClick={this.getExcelData}
                        >
                          <span>
                            <svg
                              width="16px"
                              height="16px"
                              viewBox="0 0 16 16"
                              version="1.1"
                              xmlns="https://www.w3.org/2000/svg"
                              xmlns="https://www.w3.org/1999/xlink"
                            >
                              <title>{i18n.t("Download_FL")}</title>
                              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g
                                  id="01-Admin-Feature-1.21"
                                  transform="translate(-1197.000000, -197.000000)"
                                  fill="#A100FF"
                                >
                                  <g
                                    id="01-Buttons/01-Main/Small/Purple-01/04-Icon,-Text-&amp;-Arrow/02-Default-Copy"
                                    transform="translate(1181.000000, 189.000000)"
                                  >
                                    <g id="Left-Icon" transform="translate(16.000000, 8.000000)">
                                      <path
                                        d="M14.6666667,8.88888887 L12.1083333,8.88888887 L13.3888889,7.60833333 C14.225,6.77222222 13.6333333,5.33333333 12.4472222,5.33333333 L10.6694445,5.33333333 L10.6694445,2.22222221 C10.6694445,1.48611109 10.0722222,0.888888871 9.33611111,0.888888871 L6.66944444,0.888888871 C5.93333333,0.888888871 5.3361111,1.48611109 5.3361111,2.22222221 L5.3361111,5.33333333 L3.55833332,5.33333333 C2.37499999,5.33333333 1.77499998,6.76944444 2.61666665,7.60833333 L3.89722221,8.88888887 L1.33333332,8.88888887 C0.597222204,8.88888887 -2.00000002e-08,9.48611111 -2.00000002e-08,10.2222222 L-2.00000002e-08,13.7777778 C-2.00000002e-08,14.5138889 0.597222204,15.1111111 1.33333332,15.1111111 L14.6666667,15.1111111 C15.4027778,15.1111111 16,14.5138889 16,13.7777778 L16,10.2222222 C16,9.48611111 15.4027778,8.88888887 14.6666667,8.88888887 Z M3.55555554,6.66666666 L6.66666666,6.66666666 L6.66666666,2.22222221 L9.33333334,2.22222221 L9.33333334,6.66666666 L12.4444445,6.66666666 L8,11.1111111 L3.55555554,6.66666666 Z M14.6666667,13.7777778 L1.33333332,13.7777778 L1.33333332,10.2222222 L5.22499999,10.2222222 L7.05555555,12.0527778 C7.57777778,12.575 8.41944445,12.5722222 8.94166667,12.0527778 L10.7722222,10.2222222 L14.6666667,10.2222222 L14.6666667,13.7777778 Z M12.2222222,12 C12.2222222,11.6305556 12.5194445,11.3333333 12.8888889,11.3333333 C13.2583333,11.3333333 13.5555556,11.6305556 13.5555556,12 C13.5555556,12.3694445 13.2583333,12.6666667 12.8888889,12.6666667 C12.5194445,12.6666667 12.2222222,12.3694445 12.2222222,12 Z"
                                        id="Worfklow,-Status-&amp;-UI/02-Regular/download"
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                            {i18n.t("Download_FL")}{" "}
                            {/*  <span className="download-chevron">
                            <i class="fas fa-chevron-down"></i>
                          </span> */}
                          </span>
                        </a>
                        <Workbook
                          filename={`${formatSheetName}.xlsx`}
                          element={<button style={{ display: "none" }} ref={this.inputRef}></button>}
                        >
                          {excelData.length > 0 && (
                            <Workbook.Sheet data={excelData} name={TabName}>
                              {Object.keys(excelData[0]).map((column) => (
                                <Workbook.Column label={column} value={column} />
                              ))}
                            </Workbook.Sheet>
                          )}
                        </Workbook>
                      </div>
                      {/* <div className="download-dropdown dropdown">
                                            <a
                                                className="download-dropdownbtn"
                                                href="#"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                onClick
                                            >
                                                <span>
                                                    <svg
                                                        width="16px"
                                                        height="16px"
                                                        viewBox="0 0 16 16"
                                                        version="1.1"
                                                        xmlns="https://www.w3.org/2000/svg"
                                                        xmlns="https://www.w3.org/1999/xlink"
                                                    >
                                                        <title>Download</title>
                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <g
                                                                id="01-Admin-Feature-1.21"
                                                                transform="translate(-1197.000000, -197.000000)"
                                                                fill="#A100FF"
                                                            >
                                                                <g
                                                                    id="01-Buttons/01-Main/Small/Purple-01/04-Icon,-Text-&amp;-Arrow/02-Default-Copy"
                                                                    transform="translate(1181.000000, 189.000000)"
                                                                >
                                                                    <g id="Left-Icon" transform="translate(16.000000, 8.000000)">
                                                                        <path
                                                                            d="M14.6666667,8.88888887 L12.1083333,8.88888887 L13.3888889,7.60833333 C14.225,6.77222222 13.6333333,5.33333333 12.4472222,5.33333333 L10.6694445,5.33333333 L10.6694445,2.22222221 C10.6694445,1.48611109 10.0722222,0.888888871 9.33611111,0.888888871 L6.66944444,0.888888871 C5.93333333,0.888888871 5.3361111,1.48611109 5.3361111,2.22222221 L5.3361111,5.33333333 L3.55833332,5.33333333 C2.37499999,5.33333333 1.77499998,6.76944444 2.61666665,7.60833333 L3.89722221,8.88888887 L1.33333332,8.88888887 C0.597222204,8.88888887 -2.00000002e-08,9.48611111 -2.00000002e-08,10.2222222 L-2.00000002e-08,13.7777778 C-2.00000002e-08,14.5138889 0.597222204,15.1111111 1.33333332,15.1111111 L14.6666667,15.1111111 C15.4027778,15.1111111 16,14.5138889 16,13.7777778 L16,10.2222222 C16,9.48611111 15.4027778,8.88888887 14.6666667,8.88888887 Z M3.55555554,6.66666666 L6.66666666,6.66666666 L6.66666666,2.22222221 L9.33333334,2.22222221 L9.33333334,6.66666666 L12.4444445,6.66666666 L8,11.1111111 L3.55555554,6.66666666 Z M14.6666667,13.7777778 L1.33333332,13.7777778 L1.33333332,10.2222222 L5.22499999,10.2222222 L7.05555555,12.0527778 C7.57777778,12.575 8.41944445,12.5722222 8.94166667,12.0527778 L10.7722222,10.2222222 L14.6666667,10.2222222 L14.6666667,13.7777778 Z M12.2222222,12 C12.2222222,11.6305556 12.5194445,11.3333333 12.8888889,11.3333333 C13.2583333,11.3333333 13.5555556,11.6305556 13.5555556,12 C13.5555556,12.3694445 13.2583333,12.6666667 12.8888889,12.6666667 C12.5194445,12.6666667 12.2222222,12.3694445 12.2222222,12 Z"
                                                                            id="Worfklow,-Status-&amp;-UI/02-Regular/download"
                                                                        ></path>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                          Download{" "}
                                                    <span className="download-chevron">
                                                        <i class="fas fa-chevron-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                <a class="dropdown-item">
                                                    <svg
                                                        width="12px"
                                                        height="16px"
                                                        viewBox="0 0 12 16"
                                                        version="1.1"
                                                        xmlns="https://www.w3.org/2000/svg"
                                                        xmlns="https://www.w3.org/1999/xlink"
                                                    >
                                                        <title>DownloadFeatureList</title>
                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <g
                                                                id="01-Admin-Feature-1.56"
                                                                transform="translate(-1120.000000, -241.000000)"
                                                                fill="#637381"
                                                            >
                                                                <g id="Group-54" transform="translate(1102.000000, 225.000000)">
                                                                    <g id="Icon" transform="translate(16.000000, 16.000000)">
                                                                        <path
                                                                            d="M13.559375,3.06187499 L10.938125,0.440624981 C10.656875,0.15937498 10.275625,-2.00000002e-08 9.8790625,-2.00000002e-08 L3.49968749,-2.00000002e-08 C2.67187499,0.00312498001 1.99999998,0.674999982 1.99999998,1.50281248 L1.99999998,14.5003125 C1.99999998,15.328125 2.67187499,16 3.49968749,16 L12.5003125,16 C13.328125,16 14,15.328125 14,14.5003125 L14,4.12406249 C14,3.72749999 13.840625,3.34312499 13.559375,3.06187499 Z M10.0009375,1.01843748 C10.0884375,1.04031248 10.1665625,1.08406248 10.2321875,1.14968748 L12.8534375,3.77093749 C12.9190625,3.83656249 12.9628125,3.91468749 12.9846875,4.00218749 L10.0009375,4.00218749 L10.0009375,1.01843748 L10.0009375,1.01843748 Z M13.0003125,14.5003125 C13.0003125,14.7753125 12.7753125,15.0003125 12.5003125,15.0003125 L3.49968749,15.0003125 C3.22468749,15.0003125 2.99968749,14.7753125 2.99968749,14.5003125 L2.99968749,1.50281248 C2.99968749,1.22781248 3.22468749,0.999999982 3.49968749,0.999999982 L9.0009375,0.999999982 L9.0009375,4.25218749 C9.0009375,4.66781249 9.3353125,4.99999998 9.7509375,4.99999998 L13.0003125,4.99999998 L13.0003125,14.5003125 Z M3.99999999,6.49999999 L3.99999999,13.5 C3.99999999,13.77625 4.22374999,14 4.49999999,14 L11.5,14 C11.77625,14 12,13.77625 12,13.5 L12,6.49999999 C12,6.22375 11.77625,5.99999999 11.5,5.99999999 L4.49999999,5.99999999 C4.22374999,5.99999999 3.99999999,6.22375 3.99999999,6.49999999 Z M11,13 L8.5,13 L8.5,11.5 L11,11.5 L11,13 Z M11,10.5 L8.5,10.5 L8.5,9 L11,9 L11,10.5 Z M4.99999999,7 L11,7 L11,8 L4.99999999,8 L4.99999999,7 Z M4.99999999,9 L7.49999999,9 L7.49999999,10.5 L4.99999999,10.5 L4.99999999,9 Z M4.99999999,11.5 L7.49999999,11.5 L7.49999999,13 L4.99999999,13 L4.99999999,11.5 Z"
                                                                            id="Files,-Folders-&amp;-Lists/01-Light/file-spreadsheet"
                                                                        ></path>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                          Download Feature List
                        </a>
                                                <a class="dropdown-item" onClick={this.helpfeatureNotificationModalShow}>
                                                    <svg
                                                        width="14px"
                                                        height="16px"
                                                        viewBox="0 0 14 16"
                                                        version="1.1"
                                                        xmlns="https://www.w3.org/2000/svg"
                                                        xmln="https://www.w3.org/1999/xlink"
                                                    >
                                                        <title>DownloadUserGuide</title>
                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <g
                                                                id="01-Admin-Feature-1.56"
                                                                transform="translate(-1119.000000, -273.000000)"
                                                                fill="#637381"
                                                            >
                                                                <g id="Group-54" transform="translate(1102.000000, 225.000000)">
                                                                    <g id="Icon-Copy" transform="translate(16.000000, 48.000000)">
                                                                        <path
                                                                            d="M15,12.25 L15,0.749999982 C15,0.334374981 14.665625,-2.00000002e-08 14.25,-2.00000002e-08 L3.49999998,-2.00000002e-08 C2.11874999,-2.00000002e-08 0.999999983,1.11874998 0.999999983,2.49999998 L0.999999983,14 C0.999999983,15.1046875 1.89531248,16 2.99999998,16 L14.625,16 C14.83125,16 15,15.83125 15,15.625 L15,15.375 C15,15.16875 14.83125,15 14.625,15 L14.521875,15 C14.396875,14.36875 14.421875,13.446875 14.534375,12.94375 C14.80625,12.83125 15,12.5625 15,12.25 Z M13.634375,15 L2.99999999,15 C2.44781249,15 1.99999999,14.5521875 1.99999999,14 C1.99999999,13.4478125 2.44781249,13 2.99999999,13 L13.634375,13 C13.54375,13.5875 13.5375,14.3625 13.634375,15 Z M14,12 L2.99999999,12 C2.63374999,12 2.29531249,12.105625 1.99999999,12.2775 L1.99999999,2.49999998 C1.99999999,1.67187498 2.67187499,0.999999982 3.49999999,0.999999982 L14,0.999999982 L14,12 Z"
                                                                            id="Education-&amp;-Learning/01-Light/book-alt"
                                                                        ></path>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                          Download User Guide
                        </a>
                                            </div>
                                        </div> */}
                      {(editAccess || deleteAccess) && (
                        <div
                          className={
                            selectedCount > 0
                              ? "addcollection-dropdown dropdown"
                              : "addcollection-dropdown-hide dropdown"
                          }
                        >
                          <a
                            className="addcollection-dropdownbtn"
                            href="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <svg
                              width="14px"
                              height="4px"
                              viewBox="0 0 14 4"
                              version="1.1"
                              xmlns="https://www.w3.org/2000/svg"
                              xmlns="https://www.w3.org/1999/xlink"
                            >
                              <title>Elipses</title>
                              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g
                                  id="01-Admin-Feature-1.53"
                                  transform="translate(-1337.000000, -203.000000)"
                                  fill="#A100FF"
                                >
                                  <g id="Elipses" transform="translate(1328.000000, 189.000000)">
                                    <g id="Left-Icon" transform="translate(8.000000, 8.000000)">
                                      <path
                                        d="M9.5,8 C9.5,8.828125 8.828125,9.5 8,9.5 C7.171875,9.5 6.5,8.828125 6.5,8 C6.5,7.171875 7.171875,6.5 8,6.5 C8.828125,6.5 9.5,7.171875 9.5,8 Z M13.25,6.5 C12.421875,6.5 11.75,7.171875 11.75,8 C11.75,8.828125 12.421875,9.5 13.25,9.5 C14.078125,9.5 14.75,8.828125 14.75,8 C14.75,7.171875 14.078125,6.5 13.25,6.5 Z M2.74999998,6.5 C1.92187498,6.5 1.24999998,7.171875 1.24999998,8 C1.24999998,8.828125 1.92187498,9.5 2.74999998,9.5 C3.57812499,9.5 4.24999998,8.828125 4.24999998,8 C4.24999998,7.171875 3.57812499,6.5 2.74999998,6.5 Z"
                                        id="Worfklow,-Status-&amp;-UI/02-Regular/ellipsis-h"
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </a>
                          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                            <a
                              class="dropdown-item"
                              onClick={() => {
                                this.handleMultiMoveState("All");
                              }}
                            >
                              <i class="fal fa-arrow-to-right"></i> {i18n.t("Move_to_All")}
                            </a>
                            <a
                              class="dropdown-item"
                              onClick={() => {
                                this.handleMultiMoveState("New");
                              }}
                            >
                              <i class="fal fa-arrow-to-left"></i> {i18n.t("Move_to_New")}
                            </a>
                            <a
                              class="dropdown-item"
                              onClick={() => {
                                this.handleMultiMoveState("Upcoming");
                              }}
                            >
                              <i class="fal fa-arrow-to-right"></i> {i18n.t("Move_to_Upcoming")}
                            </a>
                            {deleteAccess && (
                              <a
                                class="dropdown-item"
                                onClick={() => {
                                  this.handleMultiDelete();
                                }}
                              >
                                <i class="fal fa-trash-alt delete-icon-fix"></i> {i18n.t("Delete")}
                              </a>
                            )}

                            {/*  <a class="dropdown-item disabled-item">
                          <i class="fal fa-star"></i> Add to Collections
                        </a> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Empty Case Study Condition Start */}
              {/* <div className="welcome-casestudiesmsg">
                                    <span><img src={EmptyFeature} alt="EmptyFeature title" /> </span>
                                    <h6>Add Feature</h6>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </p>
                                </div>  */}

              {/* Empty Case Study Condition End*/}
              {/* Features Sort Start */}
              <div className="row">
                <div className="col-sm-12">
                  <div className="helpfeatures-sortby">
                    <span className="sortby-text">{i18n.t("Sort_By")}:</span>
                    <span
                      className={this.state.gridLayoutStatus ? "sortby-listicon active" : "sortby-listicon"}
                      onClick={this.gridLayout}
                    >
                      <i class="fal fa-th"></i>
                    </span>
                    <span
                      className={this.state.listGridLayoutStatus ? "sortby-listicon active" : "sortby-listicon"}
                      onClick={this.listGridLayout}
                    >
                      <i class="fal fa-th-list"></i>
                    </span>
                  </div>
                </div>
              </div>
              {/* Features Sort End */}
              {/* Feature Grid Item Select Start */}
              {selectedCount > 0 && (
                <div className="selectfeature-item">
                  <label className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={selectAllStatus}
                      onChange={this.handleSelectALL}
                    />
                    <span className="checkmark"></span>
                    <span className="form-check-label">{i18n.t("Select_All")}</span>
                  </label>
                  {selectedCount > 0 && (
                    <span className="selectedfeature-count">
                      {selectedCount} {i18n.t("features_selected")}
                    </span>
                  )}
                </div>
              )}

              {/* Feature Grid Item Select End */}
              {/* Features Grid Start */}

              {this.state.selectedCategoryFilter.length != 0 ? (
                this.state.gridLayoutStatus ? (
                  selectedCategoryFilterDATA.map((i, index) => {
                    return (
                      <div class="helpfeature-accordion" id={"accordion" + index}>
                        <div class="card">
                          <div class="card-header">
                            <div
                              class="card-link"
                              data-toggle="collapse"
                              data-target={"#collapse" + index}
                              aria-expanded="true"
                              aria-controls={"#collapse" + index}
                            >
                              {i.CategoryName} <span>({i.CategoryData.length})</span>
                            </div>
                          </div>
                          <div
                            id={"collapse" + index}
                            class="collapse show"
                            aria-labelledby="headingOne"
                            data-parent={"#accordion" + index}
                          >
                            <div class="card-body">
                              {!isAPILoading && !this.props.isorgapiloading &&
                                <div className="features-cardlist">
                                  <div className="row">
                                    {i.CategoryData.map((item, index1) => {
                                      return (
                                        <div className="col-sm-3">
                                          <div
                                            className={item.isNeworUpdated
                                              ? item.checked ? "featuresview-card newly-updated active" : "featuresview-card newly-updated"
                                              : item.checked ? "featuresview-card active" : "featuresview-card"}
                                          >
                                            <a
                                              onClick={() => {
                                                this.viewHelpFeatureModalShow(item);
                                              }}
                                              href="javascript:void(0)"
                                            >
                                              {item.DisplayImage ? (
                                                <img src={"data:image/png;base64," + item.DisplayImage} />
                                              ) : (
                                                <img src={helpfeature} className="noLogoImage" alt="client title" />
                                              )}

                                              {/*  <img src={LocalApiBaseUrlImage + "/SOImages/812161_SynOps_Gradient-Clover.jpg"} alt="client title" /> */}
                                            </a>

                                            <div
                                              className={
                                                item.checked ? "featureview-select active" : "featureview-select"
                                              }
                                            >
                                              <label className="form-check">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  onChange={() => {
                                                    this.handleMultiSelect(index1, i.CategoryName);
                                                  }}
                                                  checked={item.checked}
                                                />
                                                <span className="checkmark"></span>
                                              </label>
                                            </div>

                                            <div className="featurecard-status">
                                              {selectedViewBy == "All" && item.DisplayCategory != "All" && (
                                                <span
                                                  className={
                                                    item.DisplayCategory == "New"
                                                      ? "featurestatus-new"
                                                      : "featurestatus-upcoming"
                                                  }
                                                >
                                                  <span>{item.DisplayCategory}</span>
                                                </span>
                                              )}
                                            </div>
                                            <div className="featuresview-cardcharacter">
                                              <p>
                                                <span>{item.TopicName}</span>
                                                {/* {item.TopicName.length < 70 ? (
                                                <span>{item.TopicName}</span>
                                              ) : (
                                                <span>{item.TopicName.substring(0, 70) + "..."}</span>
                                              )} */}
                                              </p>
                                              {(editAccess || deleteAccess) && (
                                                <div className="featuresview-morelink dropdown">
                                                  <a
                                                    className="morelink-icon"
                                                    href="#"
                                                    role="button"
                                                    id="dropdownMenuLink"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                  >
                                                    <span>
                                                      {" "}
                                                      <img src={EllipsesWhite} width="30px" height="30px" />
                                                    </span>
                                                  </a>
                                                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                    {/*  <a class="dropdown-item">
                                                <i class="far fa-download download-icon-fix"></i> Download User Guide
                                              </a> */}
                                                    {editAccess && (
                                                      <a
                                                        class="dropdown-item"
                                                        onClick={() => {
                                                          this.handleEditFeature(item);
                                                        }}
                                                      >
                                                        <i class="far fa-edit"></i> {i18n.t("Edit")}
                                                      </a>
                                                    )}
                                                    {deleteAccess && (
                                                      <a
                                                        class="dropdown-item"
                                                        onClick={() => {
                                                          this.handleSingleDelete(item);
                                                        }}
                                                      >
                                                        <i class="fal fa-trash-alt delete-icon-fix"></i> {i18n.t("Delete")}
                                                      </a>
                                                    )}
                                                    {item.DisplayCategory == "All" ? (
                                                      <>
                                                        <a
                                                          class="dropdown-item"
                                                          onClick={() => {
                                                            this.handleSingleMoveState("New", item);
                                                          }}
                                                        >
                                                          <i class="fal fa-arrow-to-right"></i> {i18n.t("Move_to_New")}
                                                        </a>
                                                        <a
                                                          class="dropdown-item"
                                                          onClick={() => {
                                                            this.handleSingleMoveState("Upcoming", item);
                                                          }}
                                                        >
                                                          <i class="fal fa-arrow-to-right"></i>{" "}
                                                          {i18n.t("Move_to_Upcoming")}
                                                        </a>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <a
                                                          class="dropdown-item"
                                                          onClick={() => {
                                                            this.handleSingleMoveState("All", item);
                                                          }}
                                                        >
                                                          <i class="fal fa-arrow-to-left"></i> {i18n.t("Move_to_All")}
                                                        </a>
                                                        <a
                                                          class="dropdown-item"
                                                          onClick={() => {
                                                            this.handleSingleMoveState(
                                                              item.DisplayCategory == "New" ? "Upcoming" : "New",
                                                              item
                                                            );
                                                          }}
                                                        >
                                                          <i class="fal fa-arrow-to-right"></i> {i18n.t("Move_to")}{" "}
                                                          {item.DisplayCategory == "New" ? "Upcoming" : "New"}
                                                        </a>
                                                      </>
                                                    )}
                                                    {/* <a class="dropdown-item disabled-item">
                                                <i class="far fa-star"></i> Add to Collection
                                              </a> */}
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  selectedCategoryFilterDATA.map((i, index) => {
                    return (
                      <div class="helpfeature-accordion" id={"accordion" + index}>
                        <div class="card">
                          <div class="card-header">
                            <div
                              class="card-link"
                              data-toggle="collapse"
                              data-target={"#collapse" + index}
                              aria-expanded="true"
                              aria-controls={"#collapse" + index}
                            >
                              {i.CategoryName} <span>({i.CategoryData.length})</span>
                            </div>
                          </div>
                          <div
                            id={"collapse" + index}
                            class="collapse show"
                            aria-labelledby="headingOne"
                            data-parent={"#accordion" + index}
                          >
                            <div class="card-body">
                              <div className="features-listgrid">
                                {i.CategoryData.map((item, index1) => {
                                  return (
                                    <div className={item.isNeworUpdated
                                      ? item.checked ? "listgrids newly-updated active" : "listgrids newly-updated"
                                      : item.checked ? "listgrids active" : "listgrids"}
                                    >
                                      <div className="left-items">
                                        <div className="radio-btn">
                                          <label className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              onChange={() => {
                                                this.handleMultiSelect(index1, i.CategoryName);
                                              }}
                                              checked={item.checked}
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                        </div>
                                        <div className="featureName-description" onClick={() => {
                                          this.viewHelpFeatureModalShow(item);
                                        }}>
                                          <div className="featureName">{item.TopicName}</div>
                                          <p>{item.TopicDescription}</p>
                                        </div>
                                      </div>
                                      <div className="right-items">
                                        {selectedViewBy == "All" && item.DisplayCategory != "All" && (
                                          <span
                                            className={
                                              item.DisplayCategory === "New" ? "new-status" : "upcoming-status"
                                            }
                                          >
                                            {item.DisplayCategory}
                                          </span>
                                        )}
                                        {(editAccess || deleteAccess) && (
                                          <div className="featuresview-morelink dropdown">
                                            <a
                                              className="morelink-icon"
                                              href="#"
                                              role="button"
                                              id="dropdownMenuLink"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <span>
                                                {" "}
                                                <img src={EllipsesGrey} width="16px" />
                                              </span>
                                            </a>
                                            <div
                                              class="dropdown-menu dropdown-menu-right"
                                              aria-labelledby="dropdownMenuLink"
                                            >
                                              {/* <a class="dropdown-item">
                                            <i class="far fa-download download-icon-fix"></i> Download User Guide
                                          </a> */}
                                              {editAccess && (
                                                <a
                                                  class="dropdown-item"
                                                  onClick={() => {
                                                    this.handleEditFeature(item);
                                                  }}
                                                >
                                                  <i class="far fa-edit"></i> {i18n.t("Edit")}
                                                </a>
                                              )}
                                              {deleteAccess && (
                                                <a
                                                  class="dropdown-item"
                                                  onClick={() => {
                                                    this.handleSingleDelete(item);
                                                  }}
                                                >
                                                  <i class="fal fa-trash-alt delete-icon-fix"></i> {i18n.t("Delete")}
                                                </a>
                                              )}
                                              {item.DisplayCategory == "All" ? (
                                                <>
                                                  <a
                                                    class="dropdown-item"
                                                    onClick={() => {
                                                      this.handleSingleMoveState("New", item);
                                                    }}
                                                  >
                                                    <i class="fal fa-arrow-to-right"></i> {i18n.t("Move_to_New")}
                                                  </a>
                                                  <a
                                                    class="dropdown-item"
                                                    onClick={() => {
                                                      this.handleSingleMoveState("Upcoming", item);
                                                    }}
                                                  >
                                                    <i class="fal fa-arrow-to-right"></i> {i18n.t("Move_to_Upcoming")}
                                                  </a>
                                                </>
                                              ) : (
                                                <>
                                                  <a
                                                    class="dropdown-item"
                                                    onClick={() => {
                                                      this.handleSingleMoveState("All", item);
                                                    }}
                                                  >
                                                    <i class="fal fa-arrow-to-left"></i> {i18n.t("Move_to_All")}
                                                  </a>
                                                  <a
                                                    class="dropdown-item"
                                                    onClick={() => {
                                                      this.handleSingleMoveState(
                                                        item.DisplayCategory == "New" ? "Upcoming" : "New",
                                                        item
                                                      );
                                                    }}
                                                  >
                                                    <i class="fal fa-arrow-to-right"></i> {i18n.t("Move_to")}{" "}
                                                    {item.DisplayCategory == "New" ? "Upcoming" : "New"}
                                                  </a>
                                                </>
                                              )}
                                              {/* <a class="dropdown-item disabled-item">
                                            <i class="far fa-star"></i> Add to Collection
                                          </a> */}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )
              ) : this.state.gridLayoutStatus ? (
                <>
                  {this.state.tempCardList.length == 0 && !isAPILoading && !this.props.isorgapiloading && (
                    <div className="welcome-casestudiesmsg">
                      {addAccess && !onSearchEmpty ? (
                        <>
                          <span>
                            <img src={EmptyFeature} alt="EmptyFeature title" />{" "}
                          </span>
                          <h6>{i18n.t("Add_Feature")}</h6>
                          {/*  <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt{" "}
                        </p> */}
                        </>
                      ) : (
                        !onSearchEmpty &&
                        <>
                          <img src={ReferenceLink} alt="client title" width="66" height="76" />
                          <p>
                            {i18n.t("Watch_this_space")} {selectedViewBy == "All" ? "New" : selectedViewBy} Features
                          </p>
                        </>
                      )}
                    </div>
                  )}
                  {!isAPILoading && !this.props.isorgapiloading &&
                    <div className="features-cardlist">
                      <div className="row">
                        {this.state.tempCardList.length != 0 &&
                          this.state.tempCardList.map((item, index) => {
                            return (
                              <div className="col-sm-3">
                                <div className={item.isNeworUpdated
                                  ? item.checked ? "featuresview-card newly-updated active" : "featuresview-card newly-updated"
                                  : item.checked ? "featuresview-card active" : "featuresview-card"}>
                                  <a
                                    onClick={() => {
                                      this.viewHelpFeatureModalShow(item);
                                    }}
                                    href="javascript:void(0)"
                                  >
                                    {item.DisplayImage ? (
                                      <img src={"data:image/png;base64," + item.DisplayImage} />
                                    ) : (
                                      <img src={helpfeature} className="noLogoImage" alt="client title" />
                                    )}
                                    {/* <img src={LocalApiBaseUrlImage + "/SOImages/812161_SynOps_Gradient-Clover.jpg"} alt="client title" /> */}
                                  </a>

                                  <div className={item.checked ? "featureview-select active" : "featureview-select"}>
                                    <label className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        onChange={() => {
                                          this.handleMultiSelect(index);
                                        }}
                                        checked={item.checked}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>

                                  <div className="featurecard-status">
                                    {selectedViewBy == "All" && item.DisplayCategory != "All" && (
                                      <span
                                        className={
                                          item.DisplayCategory === "New" ? "featurestatus-new" : "featurestatus-upcoming"
                                        }
                                      >
                                        <span>{item.DisplayCategory}</span>
                                      </span>
                                    )}
                                  </div>
                                  <div className="featuresview-cardcharacter">
                                    <p>
                                      {item.TopicName.length < 70 ? (
                                        <span>{item.TopicName}</span>
                                      ) : (
                                        <span>{item.TopicName.substring(0, 70) + "..."}</span>
                                      )}
                                    </p>

                                    {(editAccess || deleteAccess) && (selectedCount < 2) && (
                                      <div className="featuresview-morelink dropdown">
                                        <a
                                          className="morelink-icon"
                                          href="#"
                                          role="button"
                                          id="dropdownMenuLink"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <span>
                                            {" "}
                                            <img src={EllipsesWhite} width="30px" height="30px" />

                                          </span>
                                        </a>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                          {/* <a class="dropdown-item">
                                  <i class="far fa-download download-icon-fix"></i> Download User Guide
                                </a> */}
                                          {editAccess && (
                                            <a
                                              class="dropdown-item"
                                              onClick={() => {
                                                this.handleEditFeature(item);
                                              }}
                                            >
                                              <i class="far fa-edit"></i> {i18n.t("Edit")}
                                            </a>
                                          )}
                                          {deleteAccess && (
                                            <a
                                              class="dropdown-item"
                                              onClick={() => {
                                                this.handleSingleDelete(item);
                                              }}
                                            >
                                              <i class="fal fa-trash-alt delete-icon-fix"></i> {i18n.t("Delete")}
                                            </a>
                                          )}
                                          {item.DisplayCategory == "All" ? (
                                            <>
                                              <a
                                                class="dropdown-item"
                                                onClick={() => {
                                                  this.handleSingleMoveState("New", item);
                                                }}
                                              >
                                                <i class="fal fa-arrow-to-right"></i> {i18n.t("Move_to_New")}
                                              </a>
                                              <a
                                                class="dropdown-item"
                                                onClick={() => {
                                                  this.handleSingleMoveState("Upcoming", item);
                                                }}
                                              >
                                                <i class="fal fa-arrow-to-right"></i> {i18n.t("Move_to_Upcoming")}
                                              </a>
                                            </>
                                          ) : (
                                            <>
                                              <a
                                                class="dropdown-item"
                                                onClick={() => {
                                                  this.handleSingleMoveState("All", item);
                                                }}
                                              >
                                                <i class="fal fa-arrow-to-left"></i> {i18n.t("Move_to_All")}
                                              </a>
                                              <a
                                                class="dropdown-item"
                                                onClick={() => {
                                                  this.handleSingleMoveState(
                                                    item.DisplayCategory == "New" ? "Upcoming" : "New",
                                                    item
                                                  );
                                                }}
                                              >
                                                <i class="fal fa-arrow-to-right"></i> {i18n.t("Move_to")}{" "}
                                                {item.DisplayCategory == "New" ? "Upcoming" : "New"}
                                              </a>
                                            </>
                                          )}
                                          {/* <a class="dropdown-item disabled-item">
                                  <i class="far fa-star"></i> Add to Collection
                                </a> */}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  }
                </>
              ) : (
                <>
                  {this.state.tempCardList.length == 0 && !isAPILoading && !this.props.isorgapiloading && (
                    <div className="welcome-casestudiesmsg">
                      {addAccess && !onSearchEmpty ? (
                        <>
                          <span>
                            <img src={EmptyFeature} alt="EmptyFeature title" />{" "}
                          </span>
                          <h6>{i18n.t("Add_Feature")}</h6>
                          {/* <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt{" "}
                        </p> */}
                        </>
                      ) : (
                        !onSearchEmpty &&
                        <>
                          <img src={ReferenceLink} alt="client title" width="66" height="76" />
                          <p>
                            {i18n.t("Watch_this_space")} {selectedViewBy == "All" ? "New" : selectedViewBy}{" "}
                            {i18n.t("Features")}
                          </p>
                        </>
                      )}
                    </div>
                  )}
                  <div className="features-listgrid">
                    {this.state.tempCardList.map((item, index) => {
                      return (
                        <div className={item.isNeworUpdated
                          ? item.checked ? "listgrids newly-updated active" : "listgrids newly-updated"
                          : item.checked ? "listgrids active" : "listgrids"}
                        >
                          <div className="left-items">
                            <div className="radio-btn">
                              <label className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  onChange={() => {
                                    this.handleMultiSelect(index);
                                  }}
                                  checked={item.checked}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="featureName-description" onClick={() => {
                              this.viewHelpFeatureModalShow(item);
                            }}>
                              <div className="featureName">{item.TopicName}</div>
                              <p>{item.TopicDescription}</p>
                            </div>
                          </div>
                          <div className="right-items">
                            {selectedViewBy == "All" && item.DisplayCategory != "All" && (
                              <span className={item.DisplayCategory === "New" ? "new-status" : "upcoming-status"}>
                                {item.DisplayCategory}
                              </span>
                            )}
                            {(editAccess || deleteAccess) && (selectedCount < 2) && (
                              <div className="featuresview-morelink dropdown">
                                <a
                                  className="morelink-icon"
                                  href="#"
                                  role="button"
                                  id="dropdownMenuLink"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <span>
                                    {" "}
                                    <img src={EllipsesGrey} width="16px" />
                                  </span>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                  {/*  <a class="dropdown-item">
                              <i class="far fa-download download-icon-fix"></i> Download User Guide
                            </a> */}
                                  {editAccess && (
                                    <a
                                      class="dropdown-item"
                                      onClick={() => {
                                        this.handleEditFeature(item);
                                      }}
                                    >
                                      <i class="far fa-edit"></i> {i18n.t("Edit")}
                                    </a>
                                  )}
                                  {deleteAccess && (
                                    <a
                                      class="dropdown-item"
                                      onClick={() => {
                                        this.handleSingleDelete(item);
                                      }}
                                    >
                                      <i class="fal fa-trash-alt delete-icon-fix"></i> {i18n.t("Delete")}
                                    </a>
                                  )}
                                  {item.DisplayCategory == "All" ? (
                                    <>
                                      <a
                                        class="dropdown-item"
                                        onClick={() => {
                                          this.handleSingleMoveState("New", item);
                                        }}
                                      >
                                        <i class="fal fa-arrow-to-right"></i> {i18n.t("Move_to_New")}
                                      </a>
                                      <a
                                        class="dropdown-item"
                                        onClick={() => {
                                          this.handleSingleMoveState("Upcoming", item);
                                        }}
                                      >
                                        <i class="fal fa-arrow-to-right"></i> {i18n.t("Move_to_Upcoming")}
                                      </a>
                                    </>
                                  ) : (
                                    <>
                                      <a
                                        class="dropdown-item"
                                        onClick={() => {
                                          this.handleSingleMoveState("All", item);
                                        }}
                                      >
                                        <i class="fal fa-arrow-to-left"></i> {i18n.t("Move_to_All")}
                                      </a>
                                      <a
                                        class="dropdown-item"
                                        onClick={() => {
                                          this.handleSingleMoveState(
                                            item.DisplayCategory == "New" ? "Upcoming" : "New",
                                            item
                                          );
                                        }}
                                      >
                                        <i class="fal fa-arrow-to-right"></i> {i18n.t("Move_to")}{" "}
                                        {item.DisplayCategory == "New" ? "Upcoming" : "New"}
                                      </a>
                                    </>
                                  )}
                                  {/*  <a class="dropdown-item disabled-item">
                              <i class="far fa-star"></i> Add to Collection
                            </a> */}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        ) : null}
        {this.state.viewHelpFeatureStatus && (
          <HelpFeatureCardViewer
            show={this.state.viewHelpFeatureStatus}
            onClose={this.viewHelpFeatureModalClose}
            Data={viewHelpFeatureData}
          />
        )}
        {this.state.helpfeatureNotificationStatus && (
          <HelpSectionMessageNotification
            show={this.state.helpfeatureNotificationStatus}
            message={notificationMessage}
            onClose={this.handleNotificationClose}
          />
        )}
        {/* <Modal
          show={this.state.helpfeatureNotificationStatus}
          onHide={this.helpfeatureNotificationModalClose}
          className="helpfeatureNotification-modal centered"
        >
          <Modal.Header closeButton>
            <Modal.Title>Notification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="helpfeaturenotification-body">
              <div className="helpfeaturenotification-icon">
                <span>
                  <i class="far fa-exclamation-triangle"></i>
                </span>
              </div>
              <div className="helpfeaturenotification-data">
                <p>Its exceeds the download limit. You can download user guide for only upto 10 features at a time.</p>
              </div>
            </div>
          </Modal.Body>
        </Modal> */}
        {/* View HelpFeature Modal End */}
      </>
    );
  }
}
export default HelpFeatures;
