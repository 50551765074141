import React, { Component } from 'react';
// import { compDetails, LocalApiBaseUrl } from '../Shared/Constant';
import DashboardCntContainer from './DashboardCntContainer';
import Header from '../Shared/Header';
import { connect } from "react-redux";
import noFilter from "../Images/funnel-icon.svg";
import i18n from "i18next";

class BI extends Component {
    constructor() {
        super()
    }

    render() {
        const {CurrentFilter} = this.props;
      
      if(CurrentFilter && CurrentFilter.ProcessId && CurrentFilter.OfferingId && CurrentFilter.LocationId && CurrentFilter.Month){
        let keyValue = CurrentFilter.ProcessId+" " + CurrentFilter.OfferingId+" " + CurrentFilter.LocationId+" " + CurrentFilter.Month;
        return (
                <div className="main-component dashboard-component">
                    <div className="container-fluid">
                        {/* {this.props.headerText && this.props.menu==="Dashboard" ?<Header headerText={this.props.headerText} menu={this.props.menu} headers={this.props.headerText}/> :null} */}
                        <DashboardCntContainer key ={keyValue} compDetails={this.props.compDetails} isRelatorBlock={this.props.isRelatorBlock} SODetails ={this.props.SODetails} filterDetails={this.props.filterDetails}  roledata={this.props.roledata}/>
                    </div>
                </div>
        )
      }else{
        return  <div className="pulse-dashboard">
        <div className="select-filter-msg">
          <img src={noFilter} alt={i18n.t("Use_the_filters_message")} />
          <p className="mb-0">{i18n.t("Use_the_filters_message")}</p>
        </div>
      </div>
      }
    }
}
const mapStateToProps = (state) => {
    return {
      CurrentFilter: state.CurrentFilter.SyncFilter,
    };
  };
  
  export default connect(
    mapStateToProps,
    ""
  )(BI);
